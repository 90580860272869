import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text-gray-60" }
const _hoisted_4 = { class: "text-gray-80" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "text-gray-60" }
const _hoisted_7 = { class: "text-gray-80" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "text-gray-60" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_label_content = _resolveComponent("category-label-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-xs font-medium", _ctx.$style.wrapper])
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createVNode(_component_category_label_content, {
        label: _ctx.category.name,
        category: _ctx.category,
        active: _ctx.category.archivedAt === null,
        critical: _ctx.category.critical,
        "text-size": "medium",
        "show-workspace-name": _ctx.showWorkspaceName
      }, null, 8 /* PROPS */, ["label", "category", "active", "critical", "show-workspace-name"])
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      (_ctx.category.critical)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.critical)
            }, "!! ", 2 /* CLASS */),
            _createTextVNode(_toDisplayString(_ctx.$t('dashboard.category_tooltip.critical_category')), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.category.archivedAt !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('dashboard.category_tooltip.archived')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.archivedDate), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.category.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('dashboard.category_tooltip.description')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.category.description), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.category.scale)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('dashboard.category_tooltip.rating_scale')), 1 /* TEXT */),
            _createElementVNode("div", {
              class: _normalizeClass(["text-xl", _ctx.$style.ratingScale])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratingScale, ({ emoji, label, emojiLabel, id: _id }) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: _id }, [
                  (_ctx.displayAsEmoji)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: emoji,
                        alt: emojiLabel
                      }, null, 8 /* PROPS */, _hoisted_10))
                    : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(label), 1 /* TEXT */))
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 2 /* CLASS */)
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}