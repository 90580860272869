import { ref, computed, watch, type Ref } from 'vue'
import type { Instance } from '@popperjs/core/lib/types'
import * as d3 from 'd3'
import { createPopper } from '@popperjs/core'
import { debounce } from 'lodash-es'

import type { DisputesByCategoryRow as Row, BarRow, StackedBarRow } from '@/modules/dashboard/disputes/types'
import { DISPUTE_COLORS, generateGetBoundingClientRect } from '@/modules/dashboard/disputes/utils'

const stackKeys = ['acceptedDisputes', 'partiallyAcceptedDisputes', 'rejectedDisputes', 'newDisputes']
const stack = d3.stack().keys(stackKeys).order(d3.stackOrderNone).offset(d3.stackOffsetNone)
const color = d3
  .scaleOrdinal()
  .domain(stackKeys)
  .range([
    DISPUTE_COLORS.acceptedDisputes,
    DISPUTE_COLORS.partiallyAcceptedDisputes,
    DISPUTE_COLORS.rejectedDisputes,
    DISPUTE_COLORS.newDisputes,
  ])

const root = ref<HTMLDivElement | null>(null)
const chart = ref<any | null>()
const height = ref<number>(0)
const width = ref<number>(0)
const styles = ref<Record<string, string> | null>(null)
const popper = ref<Instance | null>(null)
const selectedBarData = ref<Row | null>(null)

const dataState = ref<Row[]>([])

const xScale = computed(() => {
  const xDomain = [0, d3.max(dataState.value, (d) => d.totalDisputes) || 0]
  return d3.scaleLinear().domain(xDomain).range([0, width.value])
})
const yScale = computed(() => {
  const yDomain = dataState.value.map((d) => d.categoryName.toString())
  return d3.scaleBand().domain(yDomain).range([0, height.value])
})
const stackedData = computed(() => stack(dataState.value as Iterable<Record<string, number>>))
const disputesData = computed(() =>
  dataState.value.map((data) => ({
    key: data.categoryName,
    value: data.totalDisputes,
    data,
  })),
)

const initVariables = () => {
  chart.value = null
  height.value = root.value?.clientHeight || 0
  width.value = root.value?.clientWidth || 0
}

const drawBars = () => {
  yScale.value.padding(0.6)
  const barHeight = Math.min(yScale.value.bandwidth(), 25)
  const barOffset = (yScale.value.bandwidth() - barHeight) / 2
  const barRadius = Math.min(yScale.value.bandwidth() / 2, 2)
  const y = (key: string) => (yScale?.value(key) || 0) + barOffset

  const bars = chart.value
    .selectAll('.bars-by-category')
    .data(stackedData.value)
    .enter()
    .append('g')
    .attr('class', (_d: StackedBarRow<Row>, i: number) => `bars-by-category-${i}`)
    .style('fill', (_d: StackedBarRow<Row>, i: number) => color(i.toString()))

  bars
    .selectAll('rect')
    .data((d: StackedBarRow<Row>) => d)
    .enter()
    .append('rect')
    .attr('class', styles.value?.bar)
    .attr('clip-path', (_d: StackedBarRow<Row>, i: number) => `url(#bar-category-clip-${i})`)
    .attr('x', (d: StackedBarRow<Row>) => xScale.value(d[0]))
    .attr('y', (d: StackedBarRow<Row>) => y(d.data.categoryName))
    .attr('height', barHeight)
    .attr('width', (d: StackedBarRow<Row>) => xScale.value(d[1]) - xScale.value(d[0]))

  bars
    .selectAll()
    .data((d: StackedBarRow<Row>) => d)
    .enter()
    .append('text')
    .text((d: StackedBarRow<Row>) => d.data.categoryName)
    .attr('class', styles.value?.barLabel)
    .attr('x', 0)
    .attr('y', (d: StackedBarRow<Row>) => y(d.data.categoryName) - 4)
    .attr('width', width.value)
    .attr('height', 12)

  bars
    .selectAll('clipPath')
    .data(disputesData.value)
    .enter()
    .append('clipPath')
    .attr('id', (_d: BarRow<Row>, i: number) => `bar-category-clip-${i}`)
    .append('rect')
    .attr('x', 0)
    .attr('y', (d: StackedBarRow<Row>) => y(d.data.categoryName))
    .attr('height', barHeight)
    .attr('rx', barRadius)
    .attr('width', 0)
    .transition()
    .duration(800)
    .attr('width', (d: BarRow<Row>) => xScale.value(d.value))
}

const addHoverListener = () => {
  const virtualReferenceElement = { getBoundingClientRect: generateGetBoundingClientRect() }
  yScale.value.padding(0)

  // add hover area
  chart.value
    .selectAll()
    .data(disputesData.value)
    .enter()
    .append('rect')
    .attr('x', 0)
    .attr('y', (d: BarRow<Row>) => yScale.value(d.key.toString()))
    .attr('width', width.value)
    .attr('height', yScale.value.bandwidth())
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .on('mouseover', (e, { key, data: stackData }) => {
      selectedBarData.value = stackData

      for (let i = 0; i < dataState.value.length; i++) {
        d3
          .selectAll(`.bars-by-category-${i} > rect`)
          ?.style('opacity', (d: any) => (key === d.data.categoryName ? '100%' : '50%'))
      }

      virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(e.pageX, e.pageY)
      const tooltipContainer = document.querySelector(`.${styles.value?.tooltip}`) as HTMLElement
      popper.value = createPopper(virtualReferenceElement as any, tooltipContainer, {
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [15, 20] } }],
      })
    })
    .on('mousemove', (e) => {
      virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(e.pageX, e.pageY)
      popper.value?.update()
    })
    .on('mouseleave', () => {
      d3.selectAll('.bars-by-category-0 > rect')?.style('opacity', '100%')
      d3.selectAll('.bars-by-category-1 > rect')?.style('opacity', '100%')
      d3.selectAll('.bars-by-category-2 > rect')?.style('opacity', '100%')
      d3.selectAll('.bars-by-category-3 > rect')?.style('opacity', '100%')

      popper.value?.destroy()
      selectedBarData.value = null
    })
}

const drawChart = () => {
  d3.select(root.value).select('svg').remove()
  chart.value = d3.select(root.value).append('svg').attr('width', width.value).attr('height', height.value).append('g')

  drawBars()
  addHoverListener()
}

const draw = debounce(() => {
  initVariables()
  drawChart()
}, 250)

// TODO: This should be done on the backend, remove when it's ready [1]
const filterNoDisputes = (data: Row[]) => {
  const filteredCategories = data.filter(
    ({ acceptedDisputes, rejectedDisputes, newDisputes }) => acceptedDisputes || rejectedDisputes || newDisputes,
  )
  return filteredCategories
}

export default (data: Ref<Row[]>, style: Record<string, string>) => {
  styles.value = style
  watch(
    data,
    (newData) => {
      // TODO: This should be done on the backend, remove when it's ready [3]
      const filteredData = filterNoDisputes(newData)
      const sortedData = filteredData ? [...filteredData].sort((a, b) => b.totalDisputes - a.totalDisputes) : []
      dataState.value = sortedData
      drawChart()
    },
    { deep: true, immediate: true },
  )

  return { root, draw, selectedBarData }
}
