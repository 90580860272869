interface ListItem {
  uuid: string
}

interface Params {
  routes: ListItem[]
  filters: ListItem[]
  active: string
}

export const pickPrevious = ({ routes, filters, active }: Params) => {
  const routeIdx = routes.findIndex((r) => r.uuid === active)
  const filterIdx = filters.findIndex((r) => r.uuid === active)

  if (routeIdx > 0) return routes[routeIdx - 1].uuid
  else if (filterIdx !== -1) {
    if (filterIdx > 0) return filters[filterIdx - 1].uuid
    else if (filterIdx === 0 && routes.length) return routes[routes.length - 1].uuid
  }
}

export const pickNext = ({ routes, filters, active }: Params) => {
  const routeIdx = routes.findIndex((r) => r.uuid === active)
  const filterIdx = filters.findIndex((r) => r.uuid === active)

  if (routeIdx !== -1) {
    if (routeIdx < routes.length - 1) return routes[routeIdx + 1].uuid
    if (routeIdx === routes.length - 1 && filters.length) return filters[0].uuid
  } else if (filterIdx !== -1 && filterIdx < filters.length - 1) return filters[filterIdx + 1].uuid
}
