import { type Ref, ref } from 'vue'
import * as d3 from 'd3'
import { createPopper, type Instance } from '@popperjs/core'

import type { BarRow, StackedBarRow } from '@/modules/dashboard/disputes/types'
import { topRoundedCorners, generateGetBoundingClientRect } from '@/modules/dashboard/disputes/utils'

interface Params {
  root: Ref<HTMLDivElement | null>
  opts: {
    height: Ref<number>
    width: Ref<number>
    styles: Ref<Record<string, string>>
    xAxis: Ref<d3.Axis<string>>
    yAxis: Ref<d3.Axis<d3.NumberValue>>
    xScale: Ref<d3.ScaleBand<string>>
    yScale: Ref<d3.ScaleLinear<number, number, never>>
    color: d3.ScaleOrdinal<string, unknown, never>
  }
  data: {
    data: StackedRow[]
    stackedData: Ref<d3.Series<Record<string, number>, string>[]>
    selectedBarData: Ref<StackedRow>
  }
}

export interface StackedRow {
  date: string
  stacks: Record<string, number>
  total: number
}

export const margin = {
  top: 16,
  right: 30,
  bottom: 30,
  left: 15,
}

const popper = ref<Instance | null>(null)
const chart = ref<any | null>()

const convertToBarRow = (data: StackedRow[]) => {
  return data.map((d) => ({
    key: d.date,
    value: d.total,
    data: { date: d.date, total: d.total, ...d.stacks },
  }))
}

const drawBars = ({ styles, xScale, yScale, height, color }: Params['opts'], { data, stackedData }: Params['data']) => {
  const barWidth = Math.min(xScale.value.bandwidth(), 12)
  const barOffset = (xScale.value.bandwidth() - barWidth) / 2
  const barRadius = Math.min(xScale.value.bandwidth() / 2, 4)
  const x = (key: string) => (xScale.value(key) as number) + barOffset

  const bars = chart.value
    .selectAll('.bars')
    .data(stackedData.value)
    .enter()
    .append('g')
    .attr('class', (_d: StackedBarRow<StackedRow>, i: number) => `bars-${i}`)
    .style('fill', (_d: StackedBarRow<StackedRow>, i: number) => color(i.toString()))

  bars
    .selectAll('rect')
    .data((d: StackedBarRow<StackedRow>) => d)
    .enter()
    .append('rect')
    .attr('class', styles.value?.bar)
    .attr('clip-path', (_d: StackedBarRow<StackedRow>, i: number) => `url(#circle-clip-${i})`)
    .attr('x', (d: StackedBarRow<StackedRow>) => x(d.data.date))
    .attr('y', (d: StackedBarRow<StackedRow>) => yScale.value(d[1]))
    .attr('width', barWidth)
    .attr('height', (d: StackedBarRow<StackedRow>) => yScale.value(d[0]) - yScale.value(d[1]))

  // draw top rounded corners mask for data bars
  bars
    .selectAll('clipPath')
    .data(convertToBarRow(data))
    .enter()
    .append('clipPath')
    .attr('id', (_d: BarRow<StackedRow>, i: number) => `circle-clip-${i}`)
    .append('path')
    .attr('d', (d: BarRow<StackedRow>) => topRoundedCorners(x(d.key), height.value + barRadius, barWidth, 0, barRadius))
    .transition()
    .duration(800)
    .attr('d', (d: BarRow<StackedRow>) =>
      topRoundedCorners(x(d.key), height.value, barWidth, height.value - yScale.value(d.value), barRadius),
    )
}

const addHoverListener = ({ styles, height, xScale }: Params['opts'], { data, selectedBarData }: Params['data']) => {
  const virtualReferenceElement = { getBoundingClientRect: generateGetBoundingClientRect() }

  // add hover area
  chart.value
    .selectAll()
    .data(convertToBarRow(data))
    .enter()
    .append('rect')
    .attr('x', (d: BarRow<StackedRow>) => xScale.value(d.key))
    .attr('y', 0)
    .attr('width', xScale.value.bandwidth())
    .attr('height', height.value)
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .on('mouseover', (e, { key, data: stackData }) => {
      if (stackData.total) {
        const { date, total, ...stacks } = stackData
        selectedBarData.value = { date: key, total, stacks }
      }

      for (let i = 0; i < data.length; i++) {
        d3.selectAll(`.bars-${i} > rect`)?.style('opacity', (d: any) => (key === d.data.date ? '100%' : '50%'))
      }

      virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(e.pageX, e.pageY)

      const tooltipContainer = document.querySelector(`.${styles.value?.tooltip}`) as HTMLElement

      popper.value = createPopper(virtualReferenceElement as any, tooltipContainer, {
        placement: 'top-start',
        modifiers: [{ name: 'offset', options: { offset: [10, 10] } }],
      })
    })
    .on('mousemove', (e) => {
      virtualReferenceElement.getBoundingClientRect = generateGetBoundingClientRect(e.pageX, e.pageY)
      popper.value?.update()
    })
    .on('mouseleave', () => {
      for (let i = 0; i < data.length; i++) {
        d3.selectAll(`.bars-${i} > rect`)?.style('opacity', '100%')
      }
      popper.value?.destroy()
      selectedBarData.value = null
    })
}

const drawXYAxes = (root: Params['root'], { styles, height, xAxis, yAxis }: Params['opts']) => {
  chart.value
    .append('g')
    .attr('class', styles.value?.xAxis)
    .attr('transform', `translate(0, ${height.value})`)
    .call(xAxis.value)

  chart.value
    .append('g')
    .attr('class', styles.value?.yAxis)
    .attr('transform', `translate(${root.value?.clientWidth ? root.value?.clientWidth - 25 : 0}, 0)`)
    .call(yAxis.value)
}

export const drawChart = (params: Params) => {
  const { root, opts, data } = params

  d3.select(root.value).select('svg').remove()
  chart.value = d3
    .select(root.value)
    .append('svg')
    .attr('width', opts.width.value + margin.left + margin.right)
    .attr('height', opts.height.value + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  drawXYAxes(root, opts)
  drawBars(opts, data)
  addHoverListener(opts, data)
}
