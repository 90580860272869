import { ref, computed } from 'vue'
import { v4 as uuid } from 'uuid'

import { formatDateByYearIntl } from '@/utils/date'
import { sanitize } from '@/utils/sanitize'
import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import analytics from '@/utils/analytics'
import type ActionItem from '../components/ActionItem.vue'
import type { Session, Action, ActionItemsSession } from '../types'
import { addActionItem, updateActionItem, removeActionItem } from '../api'

interface Props {
  session: Session | ActionItemsSession
  addingDisabled?: boolean
}

const mapCompleted = (action: Action) => ({ ...action, completed: !!action.completedAt })

type Emit = ((event: 'update', session: Partial<Session>) => void) & ((event: 'delete', id: string) => void)

export default function (props: Props, { emit }: { emit: Emit }) {
  const items = ref<Action[]>(props.session.actionItems ? props.session.actionItems.map(mapCompleted) : [])
  const editorRefs = ref<InstanceType<typeof ActionItem>[]>([])
  const completedActionItems = computed(() => items.value.filter((i) => !!i.completedAt))

  function addActionRow() {
    items.value.push({ id: '', tempId: uuid(), title: '', completed: false, editable: true })
    setTimeout(() => editorRefs.value[editorRefs.value.length - 1].focus(), 0)
  }

  async function addAction(title: string, idx: number) {
    const res = await addActionItem(props.session.id, title)
    items.value[idx].id = res.actionItem.id
    items.value[idx].title = res.actionItem.title
    emit('update', res.session)
    displaySavedIndicator(items.value[idx])
    analytics.coachingAddActionItem()
  }

  async function updateAction(title: string, idx: number, appendEmpty = false) {
    const action = items.value[idx]
    if (!action) return
    if (!title) return removeAction(action.id, idx)
    if (appendEmpty && idx === items.value.length - 1) addActionRow()
    if (!action.id) return addAction(title, idx)

    items.value[idx].title = title
    const res = await updateActionItem(props.session.id, items.value[idx])
    emit('update', res.session)
    displaySavedIndicator(action)
    analytics.coachingEditActionItem()
  }

  function displaySavedIndicator(action: Action) {
    action.showSavedIndicator = true
    setTimeout(() => (action.showSavedIndicator = false), 800)
  }

  function actionEnterHandler(idx: number) {
    if (idx === items.value.length - 1 && !props.addingDisabled) addActionRow()
    editorRefs.value[idx].blur()
  }

  async function toggleAction(action: Action, value: boolean) {
    action.completed = value
    const res = await updateActionItem(
      props.session.id,
      action.editable ? action : { id: action.id, completed: action.completed },
    )

    if (action.completed) {
      analytics.coachingMarkActionItemDone()
    } else {
      analytics.coachingMarkActionItemUndone()
    }

    items.value = items.value.map((a) => {
      if (a.id !== action.id) return a
      res.actionItem.completed = a.completed
      return res.actionItem
    })

    emit('update', {
      ...res.session,
      actionItems: items.value,
    })
  }

  function removeAction(id: string, idx: number) {
    id && removeActionItem(props.session.id, id)
    items.value.splice(idx, 1)
    analytics.coachingRemoveActionItem()
  }

  function getActionCompletedTooltip(action: Action) {
    if (!action.completedAt) return ''

    const date = formatDateByYearIntl(action.completedAt, { month: 'short', day: 'numeric' })
    const user =
      action.completedBy?.id === session.user.id.toString()
        ? i18n.t('coaching.sessions.thread.actions.current_user')
        : action.completedBy?.name

    return i18n.t('coaching.sessions.thread.actions.completed', { user, date })
  }

  return {
    actionItems: items,
    completedActionItems,
    editorRefs,

    addAction,
    addActionRow,
    updateAction,
    toggleAction,
    removeAction,
    sanitize,
    getActionCompletedTooltip,
    actionEnterHandler,
  }
}
