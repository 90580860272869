import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-base" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-base" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "ml-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_support_desk_icon = _resolveComponent("support-desk-icon")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_external_link_icon = _resolveComponent("external-link-icon")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    "data-testid": "extension-settings-view",
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(["text-base", _ctx.$style.header])
    }, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('universal.settings')), 1 /* TEXT */),
      _createVNode(_component_q_avatar, {
        src: _ctx.session.user.avatar,
        username: _ctx.session.user.name,
        size: 32
      }, null, 8 /* PROPS */, ["src", "username"])
    ], 2 /* CLASS */),
    _createElementVNode("section", {
      class: _normalizeClass(["text-xs", _ctx.$style.section])
    }, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('extension.settings.connections')), 1 /* TEXT */),
      (_ctx.integrations.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('extension.settings.connections_connected_description')), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('extension.settings.connections_empty_description')), 1 /* TEXT */)),
      _createElementVNode("ul", {
        class: _normalizeClass(_ctx.$style.integrationList)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations, (connection) => {
          return (_openBlock(), _createElementBlock("li", {
            key: connection.tokenId,
            class: _normalizeClass(["text-xs font-medium", _ctx.$style.connection])
          }, [
            _createVNode(_component_support_desk_icon, {
              "no-tooltip": "",
              source: connection.sourceType
            }, null, 8 /* PROPS */, ["source"]),
            _createTextVNode(" " + _toDisplayString(connection.tokenName), 1 /* TEXT */)
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('extension.settings.domains')), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('extension.settings.domains_connected_description')), 1 /* TEXT */),
      _createElementVNode("ul", {
        "data-testid": "integration-list",
        class: _normalizeClass(_ctx.$style.integrationList)
      }, [
        _createCommentVNode(" TODO: Add collapse/expand and show settings "),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.domains, (connection) => {
          return (_openBlock(), _createElementBlock("li", {
            key: connection.tokenId,
            class: _normalizeClass(["text-xs font-medium", [_ctx.$style.connection, _ctx.$style.connectionCard]])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.connectionName)
            }, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass([_ctx.$style.trigger, _ctx.expandedConnections.includes(connection.tokenId) && _ctx.$style.rotated]),
                onClick: ($event: any) => (_ctx.toggleConnectionSettings(connection))
              }, [
                _createVNode(_component_chevron_right_icon, { class: "icon-s text-gray-50" })
              ], 10 /* CLASS, PROPS */, _hoisted_5),
              _createVNode(_component_support_desk_icon, {
                "no-tooltip": "",
                source: connection.sourceType
              }, null, 8 /* PROPS */, ["source"]),
              _createTextVNode(" " + _toDisplayString(connection.tokenName.replace('Extension: ', '')) + " ", 1 /* TEXT */),
              _createElementVNode("a", {
                href: `https://${connection.subdomain}`,
                target: "_blank",
                rel: "noopener noreferrer",
                class: "ml-auto"
              }, [
                _createVNode(_component_external_link_icon, { class: "icon-m mr-1 text-gray-50" })
              ], 8 /* PROPS */, _hoisted_6)
            ], 2 /* CLASS */),
            _createCommentVNode(" TODO: Implement shortly after release "),
            false
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('universal.settings')), 1 /* TEXT */),
                  _createVNode(_component_ui_checkbox, {
                    checked: connection.appendHash,
                    label: _ctx.$t('extension.settings.hash_setting_label'),
                    help: _ctx.$t('extension.settings.hash_setting_help'),
                    onChange: ($event: any) => (_ctx.toggleHashSetting(connection))
                  }, null, 8 /* PROPS */, ["checked", "label", "help", "onChange"])
                ], 512 /* NEED_PATCH */)), [
                  [_vShow, _ctx.expandedConnections.includes(connection.tokenId)]
                ])
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}