import { renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_help_circle_icon = _resolveComponent("help-circle-icon")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(_ctx.$style.root)
  }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["text-xs font-semibold", _ctx.$style.tooltip])
    }, [
      _renderSlot(_ctx.$slots, "tooltip", { hovered: _ctx.hoveredIdx })
    ], 2 /* CLASS */), [
      [_vShow, _ctx.popper && _ctx.data[_ctx.hoveredIdx] && !_ctx.data[_ctx.hoveredIdx].notApplicable]
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle({ '--nameTop': `${_ctx.height - 36}px` }),
      class: _normalizeClass(["text-gray-60 text-xs font-medium", _ctx.$style.nameRowWrapper])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("span", {
          key: item.name,
          class: "truncate pr-1"
        }, _toDisplayString(item.name), 1 /* TEXT */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 6 /* CLASS, STYLE */),
    _createElementVNode("div", {
      style: _normalizeStyle({ '--countTop': `${_ctx.height - 17}px` }),
      class: _normalizeClass(["text-base font-semibold", _ctx.$style.countRowWRapper])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("span", {
          key: item.name,
          class: _normalizeClass(_ctx.$style.cell)
        }, [
          _createTextVNode(_toDisplayString(item.notApplicableText ? _ctx.$t('funnelgraph.na') : item.value) + " ", 1 /* TEXT */),
          (item.notApplicableText)
            ? _withDirectives((_openBlock(), _createBlock(_component_help_circle_icon, {
                key: 0,
                class: "icon-m text-gray-60 ml-2"
              }, null, 512 /* NEED_PATCH */)), [
                [_directive_tippy, { content: item.notApplicableText }]
              ])
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 6 /* CLASS, STYLE */)
  ], 2 /* CLASS */))
}