import { uniqBy } from 'lodash-es'
import i18n from '@/i18n'
import { getLanguageDisplayName } from '@/utils/languageNames'

export const grammarLanguages = [
  { name: getLanguageDisplayName({ locale: 'en' }), id: 'en' },
  { name: getLanguageDisplayName({ locale: 'fr' }), id: 'fr' },
  { name: getLanguageDisplayName({ locale: 'de' }), id: 'de' },
  { name: getLanguageDisplayName({ locale: 'pl' }), id: 'pl' },
]

export const greetingClosingLanguages = [
  { name: getLanguageDisplayName({ locale: 'ar' }), id: 'ar' },
  { name: getLanguageDisplayName({ locale: 'zh' }), id: 'zh' },
  { name: getLanguageDisplayName({ locale: 'da' }), id: 'da' },
  { name: getLanguageDisplayName({ locale: 'nl' }), id: 'nl' },
  { name: getLanguageDisplayName({ locale: 'en' }), id: 'en' },
  { name: getLanguageDisplayName({ locale: 'et' }), id: 'et' },
  { name: getLanguageDisplayName({ locale: 'fi' }), id: 'fi' },
  { name: getLanguageDisplayName({ locale: 'fr' }), id: 'fr' },
  { name: getLanguageDisplayName({ locale: 'de' }), id: 'de' },
  { name: getLanguageDisplayName({ locale: 'el' }), id: 'el' },
  { name: getLanguageDisplayName({ locale: 'id' }), id: 'id' },
  { name: getLanguageDisplayName({ locale: 'it' }), id: 'it' },
  { name: getLanguageDisplayName({ locale: 'ja' }), id: 'ja' },
  { name: getLanguageDisplayName({ locale: 'ko' }), id: 'ko' },
  { name: getLanguageDisplayName({ locale: 'no' }), id: 'no' },
  { name: getLanguageDisplayName({ locale: 'pl' }), id: 'pl' },
  { name: getLanguageDisplayName({ locale: 'pt' }), id: 'pt' },
  { name: getLanguageDisplayName({ locale: 'ro' }), id: 'ro' },
  { name: getLanguageDisplayName({ locale: 'ru' }), id: 'ru' },
  { name: getLanguageDisplayName({ locale: 'es' }), id: 'es' },
  { name: getLanguageDisplayName({ locale: 'sv' }), id: 'sv' },
  { name: getLanguageDisplayName({ locale: 'tr' }), id: 'tr' },
  { name: getLanguageDisplayName({ locale: 'uk' }), id: 'uk' },
  { name: getLanguageDisplayName({ locale: 'ur' }), id: 'ur' },
  { name: getLanguageDisplayName({ locale: 'vi' }), id: 'vi' },
]

const languageOptions = uniqBy([...grammarLanguages, ...greetingClosingLanguages], 'id')

export const allLabel = i18n.t('settings.categories.ignore_list.language.all')

export const returnLanguageName = (languageId: string) => {
  if (languageId === 'all') return allLabel
  return languageOptions.find((l) => l.id === languageId)?.name || languageId
}

export const allSelected = (languages: string[]) => languages[0] === 'all'

export const languageLabel = (languages: string[]) => {
  if (allSelected(languages)) return allLabel
  if (languages.length > 1) return returnLanguageName(languages[0]) + ' +' + (languages.length - 1)

  return returnLanguageName(languages[0])
}

export const SNIPPET = {
  placeholder: {
    label: '{··}',
    content: `<span data-id="placeholder" data-type="mention" data-label="{··}" class="snippet text-gray-80">{··}</span>`,
  },
}
