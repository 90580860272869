import { v4 as uuid } from 'uuid'
import type { AgentsData, ConversationsData, Subcategory } from '../../types'

const innerFn = (
  originalNode: AgentsData | ConversationsData | undefined,
  unCheckedSlices: Subcategory[],
  parentUnchecked = false,
) => {
  if (!originalNode) return

  const node = { ...originalNode }

  const sliceUnchecked = !!unCheckedSlices.find(
    ({ category, value }) => category === node.category && value === node.value,
  )
  node.disabled = parentUnchecked || sliceUnchecked
  node.id = uuid()
  node.children = node.children.map((child) => innerFn(child, unCheckedSlices, node.disabled)) as
    | AgentsData[]
    | ConversationsData[]
  if (!node.disabled) node.disabled = !!(node.children.length && node.children.every((c) => c.disabled))
  return node
}

export default (rootNode: AgentsData | ConversationsData | undefined, disabledSlices: Subcategory[]) => {
  if (!rootNode) return rootNode
  return innerFn(rootNode, disabledSlices)
}
