import { reactive, ref, computed, watch, type Ref } from 'vue'
import * as d3 from 'd3'
import { debounce } from 'lodash-es'

import { getDataLabel as getDataLabelUtils } from '@/modules/dashboard/utils/labels'
import { FilterTimeStep } from '@/modules/dashboard/types'
import { drawChart, type StackedRow, margin } from '@/modules/dashboard/utils/stacked-bar-chart'

export const colors = ['#6FCC76', '#F6AD55', '#FA8383'] /* green-40, orange-40, red-40 */

interface DataState {
  data: StackedRow[]
  timeStep: FilterTimeStep
}

const stackKeys = ['topStack', 'middleStack', 'bottomStack']
const stack = d3.stack().keys(stackKeys).order(d3.stackOrderNone).offset(d3.stackOffsetNone)
const color = d3.scaleOrdinal().domain(stackKeys).range(colors)

const root = ref<HTMLDivElement | null>(null)
const height = ref<number>(0)
const width = ref<number>(0)
const styles = ref<Record<string, string> | null>(null)
const selectedBarData = ref<StackedRow | null>(null)

const dataState = reactive<DataState>({ data: [], timeStep: FilterTimeStep.Month })
const stackedData = computed(() =>
  stack(dataState.data.map((d) => ({ date: d.date, total: d.total, ...d.stacks })) as Iterable<Record<string, number>>),
)

const getDataLabel = (date: string) => getDataLabelUtils(dataState.timeStep as FilterTimeStep)(date)

const formatXLabel = (d: string, i: number) => {
  const labelMaxWidth = 50
  const labelsLimit = Math.floor(width.value / labelMaxWidth) // how many labels will fit in a given width

  if (labelsLimit >= xDomain.value.length) return getDataLabel(d)

  const labelInterval = Math.ceil(xDomain.value.length / labelsLimit)
  return i % labelInterval ? '' : getDataLabel(d)
}

const xDomain = computed(() => dataState.data?.map((d) => d.date))
const xScale = computed(() => {
  return d3.scaleBand().domain(xDomain.value).range([0, width.value]).padding(0)
})
const yScale = computed(() => {
  const yDomain = [0, d3.max(dataState.data, (d) => d.total) || 10]
  return d3.scaleLinear().domain(yDomain).range([height.value, 0])
})

const xAxis = computed(() => d3.axisBottom(xScale.value).tickSize(0).tickFormat(formatXLabel))
const yAxis = computed(() => {
  const yMaxValue = d3.max(dataState.data, (d) => d.total) || 10
  const tickValues = [0, Math.round(yMaxValue / 2), yMaxValue]

  return d3
    .axisLeft(yScale.value)
    .tickValues(tickValues)
    .tickFormat((d: d3.NumberValue) => Math.round(d as number).toString())
    .tickSize(width.value + margin.right / 3)
})

const initVariables = () => {
  height.value = (root.value?.clientHeight || 0) - margin.top - margin.bottom
  width.value = (root.value?.clientWidth || 0) - margin.left - margin.right
}

export default (data: Ref<StackedRow[]>, timeStep: Ref<FilterTimeStep>, style: Record<string, string>) => {
  styles.value = style

  const draw = debounce(() => {
    initVariables()
    drawChart({
      root,
      opts: { height, width, styles, xAxis, yAxis, xScale, yScale, color },
      data: { data: dataState.data, stackedData, selectedBarData },
    })
  }, 250)

  watch(
    [data, timeStep],
    (values) => {
      const [data, timeStep] = values

      dataState.timeStep = timeStep as FilterTimeStep
      dataState.data = data as StackedRow[]

      drawChart({
        root,
        opts: { height, width, styles, xAxis, yAxis, xScale, yScale, color },
        data: { data: dataState.data, stackedData, selectedBarData },
      })
    },
    { deep: true, immediate: true },
  )

  return { root, draw, selectedBarData }
}
