import { onBeforeUnmount } from 'vue'
import tippyJs from 'tippy.js'
import i18n from '@/i18n'

export default () => {
  const tooltips = []
  const tippyCleanup = () => tooltips.forEach((t) => t.destroy)
  onBeforeUnmount(tippyCleanup)

  return {
    formatHashtags: (commentEl: Element, tags) => {
      const mentions = commentEl.querySelectorAll<HTMLSpanElement>('span.mention')
      if (mentions.length === 0) return

      mentions.forEach((m) => {
        if (!m.innerText.startsWith('#')) return
        if (!tags) return
        const hasMatch = tags.some((t) => t.tag === m.innerText)
        if (hasMatch) return

        m.classList.add('inactive')

        tooltips.push(
          tippyJs(m, { content: i18n.t('conversations.sidebar.comment_list.hashtag_not_found'), arrow: true }),
        )
      })
    },
  }
}
