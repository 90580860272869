import type { RouteRecordName } from 'vue-router'
import autoQa from './auto-qa'
import overview from './overview'
import assignments from './assignments'
import surveys from './surveys'
import disputes from './disputes'
import calibration from './calibration'
import category from './category'
import type { SortedDashboardFilter } from './types'

export const filtersByRoute: Record<RouteRecordName, SortedDashboardFilter> = {
  'dashboard.auto-qa': autoQa,
  dashboard: overview,
  'dashboard.assignments': assignments,
  'dashboard.surveys': surveys,
  'dashboard.disputes': disputes,
  'dashboard.calibration': calibration,
  'dashboard.category': category,
}
