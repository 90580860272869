import { ref } from 'vue'

import { useEvent } from '@/composables/useEvent'

export const showLightbox = ref(false)
export const lightboxSrc = ref('')

export default function () {
  useEvent('show-lightbox', (src: string) => {
    lightboxSrc.value = src
    showLightbox.value = true
  })

  const closeLightbox = () => {
    showLightbox.value = false
  }

  return {
    closeLightbox,
  }
}
