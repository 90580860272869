import type { RouteRecordRaw } from 'vue-router'

import config from '@/config'
import { extensionSidebarRoutes } from '@/utils/defineSidebarRoutes'
import { TICKET_PATH } from '@/composables/useTicketRouteParams'

const routes: RouteRecordRaw[] = [
  {
    path: '/extension',
    name: 'extension',
    component: () => import('./ExtensionIndexView.vue'),
    meta: { requiresPayment: true, hideNav: true, allowEmbedding: true },
    props({ query }) {
      return { url: (query.url || query['?url'] || '').toString() }
    },
    children: [
      {
        path: 'create',
        name: 'extension.create',
        component: () => import('./CreateView/CreateView.vue'),
      },
      {
        path: 'sso-callback',
        name: 'extension.sso-callback',
        // Session established, close window
        redirect() {
          window.close()
          return {}
        },
      },
      {
        path: 'sso-logout',
        name: 'extension.sso-logout',
        // Redirect back to extension primary domain
        redirect() {
          location.replace(`https://app.${config.domainRoot}/login`)
          return {}
        },
        meta: { skipAuth: true },
      },
      {
        path: 'connect',
        name: 'extension.connect',
        component: () => import('./ConnectView.vue'),
      },
      {
        path: 'settings',
        name: 'extension.settings',
        component: () => import('./SettingsView.vue'),
      },
      {
        path: 'activity',
        name: 'extension.activity',
        component: () => import('./ActivityView.vue'),
      },
      {
        path: 'reload',
        name: 'extension.reload',
        component: () => import('./messages/ReloadView.vue'),
      },
      {
        path: 'reconnect',
        name: 'extension.reconnect',
        component: () => import('./messages/ReconnectingView.vue'),
      },
      {
        path: 'subscription',
        name: 'extension.subscription',
        component: () => import('./messages/SubscriptionMissingView.vue'),
      },
      {
        path: 'connection-error',
        name: 'extension.connection_error',
        component: () => import('./messages/ConnectionErrorView.vue'),
      },
      {
        path: 'base-connection-error',
        name: 'extension.base_connection_error',
        component: () => import('./messages/BaseConnectionErrorView.vue'),
      },
      {
        path: 'access-error',
        name: 'extension.no_access',
        component: () => import('./messages/AccessErrorView.vue'),
      },
      {
        path: 'not-found',
        name: 'extension.conversation_not_found',
        component: () => import('./messages/NotFoundView.vue'),
      },
      {
        path: 'invalid-url',
        name: 'extension.invalid_url',
        component: () => import('./messages/InvalidUrlView.vue'),
      },
      {
        path: 'conversations/' + TICKET_PATH,
        name: 'extension.conversations',
        redirect: { name: 'extension.conversations.review' },
        component: () => import('./ReviewView.vue'),
        props: ({ query }) => ({
          collapseForm: !!query.collapseForm,
        }),
        children: [
          {
            path: '/extension/conversations/details/' + TICKET_PATH,
            name: 'extension.conversations.details',
            component: () => import('./DetailsView.vue'),
          },
          ...extensionSidebarRoutes(),
        ],
      },
      {
        path: 'tasks/:assignmentId?',
        name: 'extension.tasks',
        component: () => import('./TasksView.vue'),
        meta: {
          requiresPayment: true,
          requiredHighestWorkspaceRole: ['MANAGER', 'LEAD', 'REVIEWER'],
          requiredFlag: 'assignments',
        },
      },
    ],
  },
]

export default routes
