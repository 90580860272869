import type {
  TemplateFilter,
  TemplateFilterValue,
  TemplateFilter_TemplateFilterTypeCode as TemplateFilterTypeCode,
  TemplateFilterCondition_TemplateFilterConditionCode as TemplateFilterConditionCode,
  AddCustomSpotlightRequest,
} from '@zendesk/zqa-services/autoqa'
import { AutoQaCategoryType } from '@/types/category'
import { AutoQaSpotlightType } from '@/types/spotlight'
import { type DefaultFilterOption } from '@/modules/conversations/api/filters'

export const ENABLED_AUTO_QA_CATEGORY_TYPES = [
  AutoQaCategoryType.Grammar,
  AutoQaCategoryType.Greeting,
  AutoQaCategoryType.Closing,
]

export const isPresetCategory = (id: string | AutoQaCategoryType) =>
  ENABLED_AUTO_QA_CATEGORY_TYPES.includes(id as AutoQaCategoryType)

export interface AutoQaCategory {
  id: AutoQaCategoryType
  name: string
}

export const ENABLED_AUTO_QA_SPOTLIGHT_TYPES = [AutoQaSpotlightType.DeadAir, AutoQaSpotlightType.RecordingDisclosure]

export interface SpotlightType {
  id: AutoQaSpotlightType
  name: string
  tags?: string[]
}

export enum AutoQaTag {
  Spotlight = 'spotlight',
  Voice = 'voice',
  Scorecard = 'scorecard',
  Chat = 'chat',
  Bot = 'bot',
}
export interface Filter {
  id: TemplateFilter['type']
  label: string
  showAddSimilarButton: boolean
  description: string
  conditions: {
    name: string
    label: string
    valueRequired: boolean
  }[]
  values: TemplateFilter['values']
}

export interface ConversationFilterType extends DefaultFilterOption {
  type: TemplateFilterTypeCode
  values: TemplateFilterValue[]
}

export interface CustomRatingCategoryFormErrors {
  name?: string
  description?: string
  filter?: string
  ratingFilters: Partial<
    Record<
      TemplateFilter['type'],
      Partial<Record<TemplateFilterConditionCode, Record<number, { error?: string; type?: string }>>>
    >
  >
  empty?: boolean
  conflict?: boolean
}

export interface CustomSpotlightTemplate extends AddCustomSpotlightRequest {
  id: string
}
