import { type MaybeRefOrGetter, toRef, ref, watch, onUnmounted } from 'vue'

export default function isElementIntersecting(element: MaybeRefOrGetter<HTMLElement>) {
  const elementRef = toRef(element)
  const intersecting = ref<boolean | undefined>(undefined)

  const observer = new IntersectionObserver(
    ([e]) => {
      intersecting.value = e.intersectionRatio < 1
    },
    { threshold: [1] },
  )

  watch(
    elementRef,
    (el) => {
      if (!el) return

      observer.observe(el)
    },
    { immediate: true },
  )

  onUnmounted(() => observer.disconnect())

  return { intersecting }
}
