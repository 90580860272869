/**
 * Load image File, crop it to square and return JPG Blob
 */
export const getCroppedJpg = (file: File, size = 256, quality = 0.9): Promise<Blob> => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Failed to crop - no canvas')
  ctx.imageSmoothingEnabled = false

  ctx.canvas.width = size
  ctx.canvas.height = size
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      if (!e.target) return reject(new Error('Failed to crop - reading failed'))
      const image = new Image()

      image.onload = () => {
        const imgSize = Math.min(image.width, image.height)
        const minSize = size / 2
        if (imgSize < minSize) return reject(new Error(`Picked image must be at least ${minSize}x${minSize}px big`))

        const left = Math.round((image.width - imgSize) / 2)
        const top = Math.round((image.height - imgSize) / 2)
        ctx.drawImage(image, left, top, imgSize, imgSize, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(
          (blob) => {
            if (!blob) return reject(new Error('Failed to crop - no blob'))
            resolve(blob)
          },
          'image/jpeg',
          quality,
        )
      }

      image.onerror = () => reject(new Error('Invalid or broken image picked'))

      image.src = e.target.result as string
    }

    reader.readAsDataURL(file)
  })
}
