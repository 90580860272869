import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-xs" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-xs" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_ui_tag = _resolveComponent("ui-tag")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "root",
      class: _normalizeClass(_ctx.$style.root)
    }, null, 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.legendContainer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.legend)
      }, [
        _createVNode(_component_ui_checkbox, {
          checked: _ctx.elementsVisible.iqs,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.elementsVisible.iqs = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["text-gray-80 text-xs font-medium", _ctx.$style.legendLineContainer])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.iqsLegend)
              }, null, 2 /* CLASS */),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.legend.iqs')), 1 /* TEXT */)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["checked"]),
        _createVNode(_component_ui_checkbox, {
          checked: _ctx.elementsVisible.csat,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.elementsVisible.csat = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["text-gray-80 text-xs font-medium", _ctx.$style.legendLineContainer])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.csatLegend)
              }, null, 2 /* CLASS */),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.legend.csat')), 1 /* TEXT */)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["checked"]),
        _createVNode(_component_ui_checkbox, {
          checked: _ctx.elementsVisible.reviews,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.elementsVisible.reviews = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ui_tag, { theme: "brand-light" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.legend.reviews')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["checked"])
      ], 2 /* CLASS */)
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      ref: "tooltipContainer",
      class: _normalizeClass(_ctx.$style.tooltipContainer)
    }, [
      (_ctx.overviewTooltipData)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.overviewTooltip)
          }, [
            _createElementVNode("ul", null, [
              (_ctx.elementsVisible.reviews)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.tooltip.reviews_label')), 1 /* TEXT */),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.pillContainer)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["text-xs font-medium", [_ctx.$style.pill, _ctx.$style.reviewsReceived]])
                      }, _toDisplayString(_ctx.overviewTooltipData.reviewsReceived), 3 /* TEXT, CLASS */),
                      _createElementVNode("div", {
                        class: _normalizeClass(["text-xs font-medium", [_ctx.$style.pill, _ctx.$style.reviewsSeen]])
                      }, _toDisplayString(_ctx.overviewTooltipData.reviewsSeen), 3 /* TEXT, CLASS */)
                    ], 2 /* CLASS */)
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.elementsVisible.iqs && _ctx.overviewTooltipData.iqs !== null)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.tooltip.iqs_label')), 1 /* TEXT */),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.pillContainer)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["text-xs font-medium", [_ctx.$style.pill, _ctx.$style.iqs]])
                      }, _toDisplayString(_ctx.overviewTooltipData.iqs) + "%", 3 /* TEXT, CLASS */)
                    ], 2 /* CLASS */)
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.elementsVisible.csat && _ctx.overviewTooltipData.csat !== null)
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('coaching.sessions.insights.progress_card.tooltip.csat_label')), 1 /* TEXT */),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.pillContainer)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["text-xs font-medium", [_ctx.$style.pill, _ctx.$style.csat]])
                      }, _toDisplayString(_ctx.overviewTooltipData.csat) + "%", 3 /* TEXT, CLASS */)
                    ], 2 /* CLASS */)
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.sessionsTooltipData)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["text-xs font-normal", _ctx.$style.sessionTooltip])
          }, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessionsTooltipData, (session) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: session.threadID
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style.date)
                  }, _toDisplayString(_ctx.formatDateIntl(session.scheduled, { month: 'short', day: 'numeric' })), 3 /* TEXT, CLASS */),
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style.names)
                  }, _toDisplayString(session.coach && session.coach.name) + " — " + _toDisplayString(session.coachee && session.coachee.name), 3 /* TEXT, CLASS */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ]))
}