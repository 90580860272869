import { getLocale } from '@/i18n'

export function formatNumber(n: number | string, options: Intl.NumberFormatOptions = {}) {
  if (!n) return n

  const parsed = parseInt(n.toString(), 10)

  if (isNaN(parsed)) return n

  n = n.toString()
  const isPercentage = n.endsWith('%')
  const usePlusSign = n.startsWith('+')
  n = parseFloat(n)

  if (isNaN(n)) return n

  const locale = getLocale()
  let formatted = new Intl.NumberFormat(locale, options).format(n)

  if (isPercentage) formatted = `${formatted}%`
  if (usePlusSign) formatted = `+${formatted}`

  return formatted
}

const roundingStops = [5, 10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000]

export const roundToScale = (count: number, scale = roundingStops) => {
  if (count === 0) return 0

  const nearestIdx = scale.findIndex((s) => s > count)
  let nearest = scale[nearestIdx]
  if (nearestIdx !== 0 && nearest / 2 > count) nearest = scale[nearestIdx - 1] || scale[0]

  return nearest || count
}
