import { watch, reactive, toRefs } from 'vue'
import { get } from 'lodash-es'

interface State {
  data: any[]
  list: any[]
  loading: boolean
  query: string
  sort: string
  sortDescending: boolean
}

export type SortMap = Record<
  string,
  {
    asc: (a: any, b: any) => number
    desc: (a: any, b: any) => number
  }
>

export default (searchProperties: string[], sortMap: SortMap) => {
  const state = reactive<State>({ data: [], list: [], loading: true, query: '', sort: '', sortDescending: true })
  const setData = (data: any[]) => (state.data = data)
  const setQuery = (query = '') => (state.query = query)
  const setSort = (sort: any) => {
    if (!sort) return

    if (sort === state.sort) {
      state.sortDescending = !state.sortDescending
      return
    }

    state.sortDescending = true
    state.sort = sort
  }

  watch(
    () => [state.sort, state.query, state.data, state.sortDescending],
    () => {
      const { data = [], query, sort, sortDescending } = state

      state.loading = true
      const q = query.toLowerCase()
      let list = data.filter((i) => searchProperties.some((prop) => get(i, prop)?.toLowerCase().includes(q)))
      const sortMethod = sortMap[sort][sortDescending ? 'desc' : 'asc']
      list = list.sort(sortMethod)
      state.list = list
      state.loading = false
    },
  )

  return { ...toRefs(state), setData, setSort, setQuery }
}
