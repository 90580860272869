import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  "data-testid": "pin-actions",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_more_vertical_icon = _resolveComponent("more-vertical-icon")!
  const _component_message_circle_icon = _resolveComponent("message-circle-icon")!
  const _component_edit2_icon = _resolveComponent("edit2-icon")!
  const _component_trash2_icon = _resolveComponent("trash2-icon")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["ml-auto", _ctx.$style.container])
  }, [
    _createVNode(_component_tippy, {
      ref: "tippyRef",
      interactive: "",
      trigger: "click",
      theme: "light pin-theme"
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("button", _hoisted_1, [
          _createVNode(_component_more_vertical_icon, {
            class: _normalizeClass(["icon-m", _ctx.$style.openButtonIcon])
          }, null, 8 /* PROPS */, ["class"])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.session.features.pins)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              "data-testid": "reply-pin",
              type: "button",
              class: _normalizeClass([_ctx.$style.button, _ctx.$style.buttonWithIcon]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reply && _ctx.reply(...args)))
            }, [
              _createVNode(_component_message_circle_icon, {
                class: _normalizeClass(_ctx.$style.icon)
              }, null, 8 /* PROPS */, ["class"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('pins.folder_content.reply')), 1 /* TEXT */)
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        (_ctx.canModify && _ctx.folderUserId && _ctx.session.features.pins)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              "data-testid": "edit-pin",
              class: _normalizeClass([_ctx.$style.button, _ctx.$style.buttonWithIcon]),
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editPin && _ctx.editPin(...args)))
            }, [
              _createVNode(_component_edit2_icon, {
                class: _normalizeClass(_ctx.$style.icon)
              }, null, 8 /* PROPS */, ["class"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('universal.edit')), 1 /* TEXT */)
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        (_ctx.canModify)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              "data-testid": "delete-pin",
              type: "button",
              class: _normalizeClass([_ctx.$style.button, _ctx.$style.buttonWithIcon]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deletePin && _ctx.deletePin(...args)))
            }, [
              _createVNode(_component_trash2_icon, {
                class: _normalizeClass(_ctx.$style.icon)
              }, null, 8 /* PROPS */, ["class"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('universal.delete')), 1 /* TEXT */)
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}