import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_quiz_question_result = _resolveComponent("quiz-question-result")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ui_spinner, {
          key: 0,
          class: _normalizeClass(_ctx.$style.spinner)
        }, null, 8 /* PROPS */, ["class"]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data.fields, (question) => {
          return (_openBlock(), _createElementBlock("div", {
            key: question.id
          }, [
            _createVNode(_component_quiz_question_result, {
              options: question.options,
              text: question.text,
              images: question.images,
              properties: question.properties
            }, null, 8 /* PROPS */, ["options", "text", "images", "properties"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}