import qs from 'query-string'
import type {
  CustomFieldValueRequest,
  CustomFieldValuesResponse,
  CustomFieldsResponse,
} from '@zendesk/zqa-services/filters'
import { api } from '@/api'

const getRequestHeaders = (workspaceIds: number[]) => ({ 'X-Klaus-Workspace': workspaceIds.toString() })

interface CustomFieldsOptions {
  page?: number
  pageSize?: number
  search?: string
  ids?: string[]
}

export function getHelpdesksCustomFields(workspaceIds: number[], options?: CustomFieldsOptions) {
  return api
    .get('filter/helpdesks-custom-fields', {
      headers: getRequestHeaders(workspaceIds),
      searchParams: qs.stringify(options),
    })
    .json<CustomFieldsResponse>()
}

export function getHelpdeskCustomFieldValues(
  workspaceIds: number[],
  { customFieldId, ...searchParams }: CustomFieldValueRequest,
) {
  return api
    .get(`filter/helpdesk-custom-field/${customFieldId}/values`, {
      headers: getRequestHeaders(workspaceIds),
      searchParams: qs.stringify(searchParams, { skipNull: true }),
    })
    .json<CustomFieldValuesResponse>()
}
