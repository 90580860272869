import { type ComputedRef, type Ref } from 'vue'

export enum UiMenuItemTheme {
  Danger = 'danger',
  Default = 'default',
}

export enum UiMenuTriggerTheme {
  White = 'white',
  WhiteBordered = 'whiteBordered',
  Default = 'default',
  Light = 'light',
  Transparent = 'transparent',
  Blue = 'blue',
}

export enum UiMenuSimpleTriggerTheme {
  Default = 'default',
  Light = 'light',
  Blue = 'blue',
}

export enum UiMenuGroupTheme {
  Default = 'default',
  Uppercase = 'uppercase',
}

export interface MenuState {
  focusPrevious: () => void
  focusNext: () => void
  activeItemId: ComputedRef<string | null>
  resetScroll: () => void
  setItemIds: (ids: string[]) => void
  selectFocusedItem: () => void
  itemIdToSelect: Ref<string | null>
  resetSelectedItem: () => void
  openMenu: () => void
  closeMenu: () => void
  isVisible: () => boolean
  shouldFocusTrigger: Ref<boolean>
  resetTriggerFocus: () => void
}
