import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid_header = _resolveComponent("data-grid-header")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_data_grid_cell = _resolveComponent("data-grid-cell")!
  const _component_data_grid_pagination_cell = _resolveComponent("data-grid-pagination-cell")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container),
    style: _normalizeStyle({ minWidth: _ctx.minWidth || _ctx.width, width: _ctx.width }),
    "data-testid": "datagrid-column"
  }, [
    _createVNode(_component_data_grid_header, {
      id: _ctx.id,
      label: _ctx.label,
      centered: _ctx.align === 'center',
      "not-sortable": _ctx.notSortable,
      sort: _ctx.gridCtx.data.sort,
      onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gridCtx.sortBy?.($event)))
    }, _createSlots({ _: 2 /* DYNAMIC */ }, [
      (_ctx.$slots.header)
        ? {
            name: "default",
            fn: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({ ...slotProps, ..._ctx.gridCtx.data })))
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "label", "centered", "not-sortable", "sort"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridCtx.data.rows, (row, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
        (!row.hidden)
          ? (_openBlock(), _createBlock(_component_data_grid_cell, {
              key: 0,
              "data-testid": `review-${row[_ctx.id]}`,
              class: _normalizeClass({ [_ctx.$style.clickable]: _ctx.rowToggleable(row) }),
              tall: _ctx.getIsTall ? _ctx.getIsTall(row) : false,
              onClick: ($event: any) => (_ctx.toggleRow(idx))
            }, {
              default: _withCtx(() => [
                (_ctx.rowToggleable(row))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([_ctx.$style.expandContainer, _ctx.rowExpanded(idx) && _ctx.$style.expanded])
                    }, [
                      _createVNode(_component_chevron_right_icon, { class: "icon-s" })
                    ], 2 /* CLASS */))
                  : _createCommentVNode("v-if", true),
                _renderSlot(_ctx.$slots, "default", {
                  value: row[_ctx.id],
                  row: row,
                  hasSubrows: row.rows && row.rows.length > 0,
                  index: idx
                }, () => [
                  _createElementVNode("div", null, _toDisplayString(row[_ctx.id]), 1 /* TEXT */)
                ]),
                (_ctx.rowToggleable(row))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["text-xs leading-4", _ctx.$style.rowCount])
                    }, _toDisplayString(row.rows?.filter((subRow) => !subRow.hidden).length), 3 /* TEXT, CLASS */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data-testid", "class", "tall", "onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.rowExpanded(idx) && !row.hidden)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row.rows?.filter((subRow) => !subRow.hidden), (subRow, srIdx) => {
              return (_openBlock(), _createBlock(_component_data_grid_cell, {
                key: `${idx}-${srIdx}`,
                class: _normalizeClass({ [_ctx.$style.subrowCell]: _ctx.rowToggleable(row) })
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {
                    value: subRow[_ctx.id],
                    row: subRow
                  }, () => [
                    _createTextVNode(_toDisplayString(subRow[_ctx.id]), 1 /* TEXT */)
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
            }), 128 /* KEYED_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */)),
    _createVNode(_component_data_grid_pagination_cell, { "with-label": _ctx.identityColumn }, null, 8 /* PROPS */, ["with-label"]),
    (!_ctx.gridCtx.hideTotal)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$slots['custom-total'])
            ? (_openBlock(), _createBlock(_component_data_grid_cell, {
                key: 0,
                total: ""
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "custom-total", {
                    value: _ctx.gridCtx.data
                  })
                ]),
                _: 3 /* FORWARDED */
              }))
            : (_ctx.gridCtx.data[_ctx.id])
              ? (_openBlock(), _createBlock(_component_data_grid_cell, {
                  key: 1,
                  total: ""
                }, {
                  default: _withCtx(() => [
                    _createCommentVNode(" Cannot use boolean shorthand for scoped slots (isTotal) "),
                    _renderSlot(_ctx.$slots, "default", {
                      value: _ctx.gridCtx.data[_ctx.id],
                      isTotal: true
                    })
                  ]),
                  _: 3 /* FORWARDED */
                }))
              : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}