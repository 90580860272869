import i18n from '@/i18n'
import { FilterSelfReview } from '../../../types'

export const selfReviewPresets = [
  {
    label: i18n.t('dashboard.self_reviews_exclude'),
    value: FilterSelfReview.Exclude,
  },
  {
    label: i18n.t('dashboard.self_reviews_include'),
    value: FilterSelfReview.Include,
  },
  {
    label: i18n.t('dashboard.self_reviews_only'),
    value: FilterSelfReview.Only,
  },
]
