import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'

import { getConsent, setConsent } from '@/modules/settings/api'

export default function useConsents() {
  const queryClient = useQueryClient()

  const { data: consentsData, isFetchedAfterMount: consentsFetched } = useQuery({
    queryKey: ['consents'],
    queryFn: getConsent,
  })

  const { mutate: editConsents, isSuccess: consentsEditSuccess } = useMutation({
    mutationFn: setConsent,
    onSuccess: (data) => {
      queryClient.setQueryData(['consents'], data)
    },
  })

  return { consentsData, consentsFetched, editConsents, consentsEditSuccess }
}
