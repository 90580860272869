import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import type { AdvancedScorecard_Category as Category } from '@zendesk/zqa-services/scorecards'
import { AutoQaCategoryType } from '@/types/category'

export default createSharedComposable(() => {
  const isCategoryDrawerOpen = ref(false)
  const isPredefinedDrawerOpen = ref(false)
  const editedCategory = ref<Category>(null)
  const isCreate = ref(false)

  function isPredefinedCategory(autoQaCategory: Category['autoQaCategory']) {
    return autoQaCategory && autoQaCategory !== AutoQaCategoryType.Custom
  }

  function openCategoryDrawer(category?: Category) {
    isCreate.value = !category
    editedCategory.value = category

    if (category && isPredefinedCategory(category.autoQaCategory)) {
      isPredefinedDrawerOpen.value = true
    } else {
      isCategoryDrawerOpen.value = true
    }
  }

  return {
    isPredefinedCategory,
    isCategoryDrawerOpen,
    isPredefinedDrawerOpen,
    openCategoryDrawer,
    isCreate,
    editedCategory,
  }
})
