import { getPersistentParams, defaultSerializer, storeParams } from '@/utils/persistence'
import { FilterTimePeriod, type FilterParams } from '../types'
import type { CsatFilterParams } from '../csat/types'

const VERSION = 'v7'

const SHARED_FILTER_STORAGE_KEY = `sharedDashboardRequestParams.${VERSION}`
const SHARED_LOCALSTORAGE_WHITELIST = ['timePeriod', 'fromDate', 'toDate']

const getFilterStorageKey = (routeName: string) => {
  if (['dashboard', 'dashboard.category'].includes(routeName)) return `mainDashboardRequestParams.${VERSION}`
  if (['dashboard.surveys'].includes(routeName)) return `csatDashboardRequestParams.${VERSION}`
}

const getLocalstorageWhitelist = (routeName: string) => {
  if (['dashboard', 'dashboard.category'].includes(routeName)) return ['decimals', 'direction']
  if (['dashboard.surveys'].includes(routeName)) return ['target', 'questionTypeId']
}

/**
 * Our duplicate key query array style is ambigious
 */
const ensureArray = <T extends Record<string, unknown>, K extends keyof T>(object: T, keys: K[]) => {
  keys.forEach((k) => {
    if (!Array.isArray(object[k]) && typeof object[k] !== 'undefined') (object[k] as T[K] | T[K][]) = [object[k]]
  })
}

const withExcludeKeys = (keys: string[]) => {
  const excludedKeys = keys.map((key) => `-${key}`)

  return [...keys, ...excludedKeys]
}

const serializer = (
  defaults: FilterParams | CsatFilterParams,
  filterParams: Partial<FilterParams>,
  whitelist: string[] = [],
) => {
  const { step, fromDate, toDate, categoryId, helpdesksCustomFilters, ...params } = filterParams
  const customTimePeriod = params.timePeriod === FilterTimePeriod.Custom

  const customFilters = helpdesksCustomFilters?.filter((filter) => filter.values.length)

  const paramsToClean = {
    fromDate: customTimePeriod && fromDate ? fromDate.split('T')[0] : undefined,
    toDate: customTimePeriod && toDate ? toDate.split('T')[0] : undefined,
    helpdesksCustomFilters: customFilters?.length ? encodeURIComponent(JSON.stringify(customFilters)) : [],
    ...params,
  }

  return defaultSerializer(defaults, paramsToClean, whitelist)
}

/**
 * Write filter params to query string and subset to localStorage
 */
export const serializeToStorage = (
  activeRouteName: string,
  defaults: FilterParams | CsatFilterParams,
  params: Partial<FilterParams>,
) => {
  const query = serializer(defaults, params)
  history.replaceState(history.state, '', `${location.pathname}${query ? '?' : ''}${query}`)

  storeParams({
    defaults,
    params,
    storageKey: SHARED_FILTER_STORAGE_KEY,
    whitelist: SHARED_LOCALSTORAGE_WHITELIST,
    serializer,
  })

  const storageKey = getFilterStorageKey(activeRouteName)
  const whitelist = getLocalstorageWhitelist(activeRouteName)
  if (!storageKey || !whitelist) return
  storeParams({
    defaults,
    params,
    storageKey,
    whitelist,
    serializer,
  })
}

/**
 * Read filter params from localStorage and querystring, latter overrides stored ones
 */
export const deserializeFromStorage = (activeRouteName: string): FilterParams & CsatFilterParams => {
  const sharedQuery = getPersistentParams(SHARED_FILTER_STORAGE_KEY)
  const filterStorageKey = getFilterStorageKey(activeRouteName)
  const routeSpecificQuery = filterStorageKey ? getPersistentParams(filterStorageKey) : {}
  const query = { ...sharedQuery, ...routeSpecificQuery }

  ensureArray(
    query,
    withExcludeKeys([
      'workspaceIds',
      'userIds',
      'userGroups',
      'connectionIds',
      'ratingCategoryTags',
      'commentTags',
      'ticketTags',
      'rawCsatScores',
      'predictedDimensions',
      'predictedTags',
      'wordCloudWords',
      'csatReasons',
      'sentiment',
      'surveyIds',
      'spotlight',
      'sourceType',
      'languages',
      'rootCause',
      'channels',
    ]),
  )

  let { fromDate, toDate, helpdesksCustomFilters } = query
  if (fromDate) fromDate = `${fromDate}T00:00:00.000Z`
  if (toDate) toDate = `${toDate}T00:00:00.000Z`
  helpdesksCustomFilters = helpdesksCustomFilters ? JSON.parse(decodeURIComponent(helpdesksCustomFilters)) : []
  // Handle sunset periods
  if (['year', 'quarter'].includes(query.timePeriod)) query.timePeriod = 'month'

  return { ...query, fromDate, toDate, helpdesksCustomFilters }
}
