import type { RouteRecordRaw } from 'vue-router'

import { session } from '@/composables/useSession'
import { hasWorkspaceRole } from '@/utils/roleUtils'
import { sidebarRoutes } from '@/utils/defineSidebarRoutes'
import { TICKET_PATH } from '@/composables/useTicketRouteParams'

const hasPermission = () => !hasWorkspaceRole('AGENT') || session.workspace?.settings?.selfReviews

export default (): RouteRecordRaw[] => [
  {
    path: '/activity',
    name: 'activity',
    meta: { requiresPayment: true, includeChildrenOnlyInCommandPalette: true, requiresSufficientConversations: true },
    redirect: { name: 'activity.reviewsReceived' },
    component: () => import('./ReviewsRoutes.vue'),
    children: [
      {
        path: 'reviews-received/' + TICKET_PATH,
        name: 'activity.reviewsReceived',
        redirect: { name: 'activity.reviewsReceived.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.reviewsReceived', '/activity/reviews-received'),
      },
      {
        path: 'reviews-given/' + TICKET_PATH,
        name: 'activity.reviewsGiven',
        redirect: { name: 'activity.reviewsGiven.review' },
        component: () => import('./ActivityIndexView.vue'),
        beforeEnter: (to, _from, next) => {
          if (!hasPermission()) {
            next({ name: 'activity.reviewsReceived', params: to.params, query: to.query, hash: to.hash })
          } else {
            next()
          }
        },
        children: sidebarRoutes('activity.reviewsGiven', '/activity/reviews-given'),
      },
      {
        path: 'comments-received/' + TICKET_PATH,
        name: 'activity.commentsReceived',
        redirect: { name: 'activity.commentsReceived.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.commentsReceived', '/activity/comments-received'),
      },
      {
        path: 'comments-given/' + TICKET_PATH,
        name: 'activity.commentsGiven',
        redirect: { name: 'activity.commentsGiven.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.commentsGiven', '/activity/comments-given'),
      },
      {
        path: 'reactions-received/' + TICKET_PATH,
        name: 'activity.reactionsReceived',
        redirect: { name: 'activity.reactionsReceived.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.reactionsReceived', '/activity/reactions-received'),
      },
      {
        path: 'feedback-received/' + TICKET_PATH,
        name: 'activity.feedbackReceived',
        redirect: { name: 'activity.feedbackReceived.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.feedbackReceived', '/activity/feedback-received'),
      },
      {
        path: 'disputes-all/' + TICKET_PATH,
        name: 'activity.disputesAll',
        redirect: { name: 'activity.disputesAll.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.disputesAll', '/activity/disputes-all'),
      },
      {
        path: 'disputes-started/' + TICKET_PATH,
        name: 'activity.disputesStarted',
        redirect: { name: 'activity.disputesStarted.review' },
        component: () => import('./ActivityIndexView.vue'),
        children: sidebarRoutes('activity.disputesStarted', '/activity/disputes-started'),
      },
      {
        path: 'disputes-received/' + TICKET_PATH,
        name: 'activity.disputesReceived',
        redirect: { name: 'activity.disputesReceived.review' },
        component: () => import('./ActivityIndexView.vue'),
        beforeEnter: (to, _from, next) => {
          if (hasWorkspaceRole('AGENT')) {
            next({ name: 'activity.disputesAll', params: to.params, query: to.query, hash: to.hash })
          } else {
            next()
          }
        },
        children: sidebarRoutes('activity.disputesReceived', '/activity/disputes-received'),
      },
    ],
  },
]
