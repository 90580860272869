import type { SearchTenorDataRequest, SearchTenorDataResponse } from '@zendesk/zqa-services/reviews'
import qs from 'query-string'
import { api } from '@/api'

export const fetchGifs = (accountId: string, json: SearchTenorDataRequest) => {
  return api
    .get(`tenor/search`, {
      headers: { 'X-Klaus-Account': accountId },
      searchParams: qs.stringify(json),
    })
    .json<SearchTenorDataResponse>()
}
