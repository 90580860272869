import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'
import type { Connection } from '@zendesk/zqa-services/connections'
import { type CoreParams, getWorkspaceConnections } from '@/modules/dashboard/api'

export function useWorkspaceConnections(filters: ComputedRef<CoreParams>) {
  const workspaceIds = computed(() => filters.value.workspaceIds)

  const { data: connections, isPending: isLoading } = useQuery({
    queryKey: ['workspace-connections', workspaceIds],
    queryFn: () => getWorkspaceConnections(filters.value),
    select: (connections) => connections.map(withoutDisabled).map((conn) => ({ id: Number(conn.id), name: conn.name })),
  })

  return { connections, isLoading }
}

function withoutDisabled(connection: Connection) {
  const { disabled, ...connectionWithoutDisabled } = connection
  return connectionWithoutDisabled
}
