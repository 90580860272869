import type {
  CreateSurveyConfigurationRequest,
  ParseGettextToValuesRequest,
  ParseGettextToValuesResponse,
  SurveyTranslation,
  GetUnsavedSurveyTranslationRequest,
  QuestionTypesResponse,
  SurveyTriggerConfigurationOptionsResponse,
  SurveyTriggerConfigurationFieldOptionsRequest,
  SurveyTriggerConfigurationOptionValues,
  GetEmbeddedSnippetRequest,
  GetEmbeddedSnippetResponse,
} from '@zendesk/zqa-services/surveys'
import qs from 'query-string'
import { api } from '@/api'

import type { Survey, SurveyConfig, Tag, TriggerCondition, TriggerConditionValidationError } from '../types'

export const getSurveys = () => api.get(`surveys`).json<{ surveys: Survey[] }>()

export const createSurvey = (request?: CreateSurveyConfigurationRequest) =>
  api.post(`surveys`, { json: request }).json<{ configurationId: string }>()

export const getSurvey = (id: string) =>
  api
    .extend({ hooks: { afterResponse: undefined } }) /* disable QErrorCat */
    .extend({ hooks: { afterResponse: [] } })
    .get(`surveys/${id}`)
    .json<SurveyConfig>()

export const updateSurvey = (id: string, data: SurveyConfig) =>
  api.put(`surveys/${id}`, { json: data }).json<SurveyConfig>()

export const duplicateSurvey = (id: string) => api.post(`surveys/duplicate/${id}`).json<{ configurationId: string }>()

export const deleteSurvey = (id: string) => api.delete(`surveys/${id}`).json()

export const getEmbeddedSnippet = ({
  language,
  connectionId,
  configurationId,
  signal,
}: GetEmbeddedSnippetRequest & { signal: AbortSignal }) => {
  const searchParams = new URLSearchParams({ language, connectionId })

  return api
    .get(`surveys/${configurationId}/embedded-snippet`, { searchParams, signal })
    .json<GetEmbeddedSnippetResponse>()
}

export const getConnectionFilters = (connectionId: string, deliveryMethod?: string) => {
  const query = new URLSearchParams()
  if (deliveryMethod) query.append('deliveryMethod', deliveryMethod)

  return api
    .get(`surveys/trigger-configuration-options/${connectionId}?${query.toString()}`)
    .json<SurveyTriggerConfigurationOptionsResponse>()
}

export const sendPreviewEmail = (id: string, email: string, language: string) =>
  api.post(`surveys/${id}/preview`, { json: { email, language } }).json()

export const updateSurveyStatus = (id: string, status: string) =>
  api.post(`surveys/${id}/status`, { json: { status } }).json()

export const getTags = (connectionId: string, q: { tag: string; limit: string }) => {
  const query = new URLSearchParams()
  if (q.tag) query.append('tag', q.tag)
  if (q.limit) query.append('limit', q.limit)

  return api.get(`surveys/trigger-configuration-options/${connectionId}/tags?${query.toString()}`).json<{
    values: Tag[]
    total: number
  }>()
}

export const getFieldValues = (
  params: SurveyTriggerConfigurationFieldOptionsRequest,
): Promise<SurveyTriggerConfigurationOptionValues> =>
  api
    .get(`surveys/trigger-configuration-options/${params.connectionId}/fields`, {
      searchParams: qs.stringify(params),
    })
    .json()

export const validateTriggerConditions = (id: string, connectionId: string, triggerConditions: TriggerCondition[]) =>
  api
    .post(`surveys/${id}/trigger-configuration/${connectionId}/validate`, { json: { triggerConditions } })
    .json<{ error: TriggerConditionValidationError }>()

export const gettextToValues = (id: string, json: ParseGettextToValuesRequest) =>
  api.post(`surveys/${id}/gettext-to-values`, { json }).json<ParseGettextToValuesResponse>()

export const getUnsavedSurveyTranslation = (languageCode: string, json: GetUnsavedSurveyTranslationRequest) =>
  api.post(`surveys/unsaved-translation/${languageCode}`, { json }).json<SurveyTranslation>()

export const getQuestionTypes = () => api.get(`surveys/question-types`).json<QuestionTypesResponse>()

export const createQuestionType = (request: { name: string }) =>
  api.post(`surveys/question-types`, { json: request }).json<QuestionTypesResponse>()

export const updateQuestionType = (id: string, request: { name: string }) =>
  api.put(`surveys/question-types/${id}`, { json: request }).json<QuestionTypesResponse>()
