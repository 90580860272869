import { useRoute } from 'vue-router'
import { getFirstParam } from '@/utils/routes'
import type { MessageRouteParams } from '@/utils/defineSidebarRoutes'

export const getParentRouteName = (name: string) => {
  if (name.startsWith('assignments')) return 'assignments'
  if (name.startsWith('tasks.assignments')) return 'tasks.assignments'
  if (name.startsWith('extension')) return 'extension.conversations'
  if (name.startsWith('activity')) return name.split('.').slice(0, 2).join('.')

  return 'conversations'
}

export default function () {
  const route = useRoute()
  const getRoute = (
    childName = '',
    newParams = {},
  ): { name: string; params: Record<string, string>; query: Record<string, string | string[]> } => {
    const { name, params, query } = route
    const parentName = getParentRouteName(name.toString())

    return {
      name: `${parentName}${childName}`,
      params: {
        ...(params.connectionId ? { connectionId: getFirstParam(params.connectionId) } : {}),
        ...(params.conversationId ? { conversationId: getFirstParam(params.conversationId) } : {}),
        ...newParams,
        ...((parentName.startsWith('assignments') || parentName.startsWith('tasks.assignments')) && {
          assignmentId: getFirstParam(params.assignmentId),
        }),
      },
      query,
    }
  }

  const getFeedbackRoute = () => getRoute()

  const getMessageRoute = ({ messageId, transcriptionId, transcriptionMessageId }: MessageRouteParams) =>
    getRoute('.message', {
      messageId: encodeURIComponent(messageId),
      transcriptionId: transcriptionId ? encodeURIComponent(transcriptionId) : undefined,
      transcriptionMessageId: transcriptionMessageId ? encodeURIComponent(transcriptionMessageId) : undefined,
    })

  const getDisputeRoute = (disputeId: string) => getRoute('.dispute', { disputeId })

  return { getFeedbackRoute, getMessageRoute, getDisputeRoute }
}
