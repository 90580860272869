import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_score_graph = _resolveComponent("score-graph")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_percentage_cell = _resolveComponent("percentage-cell")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_data_grid_data = _resolveComponent("data-grid-data")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "dashboard-category-details-card",
    title: _ctx.$t('dashboard.card_labels.category_ratings', { categoryName: _ctx.category ? _ctx.category.name : '' }),
    class: "mx-6 mb-6",
    "timestep-toggle": "",
    params: _ctx.filterParams,
    "api-method": _ctx.getCategoryDetails,
    "csv-api-method": _ctx.getCategoryDetailsCsv
  }, {
    default: _withCtx(({ data, timeStep, sortBy, displayGraph }) => [
      displayGraph
        ? (_openBlock(), _createBlock(_component_score_graph, {
            key: 0,
            "time-step": timeStep,
            data: data,
            "render-label": (row) => row.label,
            "select-all-label": _ctx.$t('dashboard.category')
          }, null, 8 /* PROPS */, ["time-step", "data", "render-label", "select-all-label"]))
        : (_openBlock(), _createBlock(_component_data_grid, {
            key: 1,
            "sort-by": sortBy,
            data: data
          }, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_data_grid_column, {
                    id: "label",
                    "identity-column": "",
                    width: "192px",
                    align: "left"
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_data_grid_data, {
                "format-label": _ctx.formatDataLabel(timeStep),
                width: 70
              }, {
                default: _withCtx(({ value, row }) => [
                  (row.isScore)
                    ? (_openBlock(), _createBlock(_component_percentage_cell, {
                        key: 0,
                        value: value
                      }, null, 8 /* PROPS */, ["value"]))
                    : (_openBlock(), _createBlock(_component_number_cell, {
                        key: 1,
                        value: value
                      }, null, 8 /* PROPS */, ["value"]))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["format-label"]),
              _createVNode(_component_data_grid_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_data_grid_column, {
                    id: "total",
                    label: _ctx.$t('dashboard.score_total'),
                    width: "140px"
                  }, {
                    default: _withCtx(({ value, row }) => [
                      (row.isScore)
                        ? (_openBlock(), _createBlock(_component_percentage_cell, {
                            key: 0,
                            bold: "",
                            value: value
                          }, null, 8 /* PROPS */, ["value"]))
                        : (_openBlock(), _createBlock(_component_number_cell, {
                            key: 1,
                            value: value
                          }, null, 8 /* PROPS */, ["value"]))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["sort-by", "data"]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "params", "api-method", "csv-api-method"]))
}