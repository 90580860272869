import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { hasAccountRole } from '@/utils/roleUtils'
import type { WorkspaceRole } from '@/data/roles'
import { hasZendeskProxy } from '@/config'

const isProxied = hasZendeskProxy || session.features.zendeskProxyDev

export const getWorkspaceSettingLinks = (role: WorkspaceRole | undefined) => {
  return [
    ...(hasAccountRole('ADMIN', 'MANAGER') || role === 'MANAGER'
      ? [
          {
            label: i18n.t('settings.workspaces.settings_titles.members'),
            to: { name: 'workspace.members' },
          },
          {
            label: i18n.t('settings.workspaces.settings_titles.general'),
            to: { name: 'workspace.settings' },
          },
          ...(session.features.advancedScorecardsDev
            ? [
                {
                  label: i18n.t('settings.workspaces.settings_titles.scorecard'),
                  to: { name: 'workspace.scorecard.moved' },
                },
              ]
            : [
                {
                  label: i18n.t('settings.workspaces.settings_titles.scorecard'),
                  to: { name: 'workspace.scorecard' },
                },
              ]),
          {
            label: i18n.t('settings.workspaces.settings_titles.hashtags'),
            to: { name: 'workspace.hashtags' },
          },
          {
            label: i18n.t('settings.workspaces.settings_titles.threshold'),
            to: { name: 'workspace.threshold' },
          },
        ]
      : []),
    ...(session.features.assignments
      ? [
          {
            label: i18n.t('settings.workspaces.settings_titles.assignments'),
            to: { name: 'workspace.assignments' },
          },
        ]
      : []),
    ...((hasAccountRole('ADMIN', 'MANAGER') || role === 'MANAGER') &&
    (!isProxied || (isProxied && session.features.allowAdditionalIntegrations))
      ? [
          {
            label: i18n.t('settings.workspaces.settings_titles.connections'),
            to: { name: 'workspace.connections' },
          },
        ]
      : []),
    ...(hasAccountRole('ADMIN', 'MANAGER') || role === 'MANAGER'
      ? [
          {
            label: i18n.t('settings.workspaces.settings_titles.calibration'),
            to: { name: 'workspace.calibration' },
          },
        ]
      : []),
  ]
}
