import type { Speaker } from '@zendesk/zqa-services/transcriptions'
import type { Member } from '@/modules/dashboard/types'
import i18n from '@/i18n'

export interface UserOption extends Member {
  value?: string
}

export const CUSTOMER_SPEAKER_ID = 0

export const getSpeakersSeparator = (): UserOption => ({
  id: -1,
  name: i18n.t('conversations.call_transcript.speakers'),
  value: 'separator',
  email: '',
})

export const getWsUsersSeparator = (): UserOption => ({
  id: -1,
  name: i18n.t('conversations.call_transcript.ws_users'),
  value: 'separator',
  email: '',
})

export const getCustomerOption = (): UserOption => ({
  id: CUSTOMER_SPEAKER_ID,
  name: i18n.t('conversations.call_transcript.customer'),
  email: '',
})

export const speakerToOption = (speaker: Speaker): UserOption => {
  if (speaker.alias === 'customer') return getCustomerOption()

  return {
    id: Number(speaker.internalId),
    email: speaker.address,
    name: speaker.name,
    avatar: speaker.pictureUrl,
  }
}

export const optionToSpeaker = (user: UserOption, aliasOriginal: string): Speaker => {
  if (user.id === CUSTOMER_SPEAKER_ID) return { alias: 'customer', aliasOriginal, internalId: undefined }

  return {
    address: user.email,
    alias: 'agent',
    aliasOriginal,
    externalId: null,
    internalId: String(user.id),
    name: user.name,
    pictureUrl: user.avatar,
  }
}

export function getTranscriptionSpeakerId({ transcriptionId, speaker }: { transcriptionId: string; speaker: Speaker }) {
  return speaker?.externalId ?? speaker?.internalId ?? `${transcriptionId}_${speaker?.aliasOriginal}`
}

export function getTranscriptionSpeakerName(speaker: Speaker) {
  return (
    speaker.name ??
    (speaker.alias && i18n.t(`conversations.call_transcript.${speaker.alias}`)) ??
    i18n.t('conversations.call_transcript.speaker_alias', [speaker.aliasOriginal])
  )
}
