import i18n from '@/i18n'

export const authProviders = {
  email: i18n.t('settings.logins.auth_providers.magic_link'),
  auth0: i18n.t('settings.logins.auth_providers.password'),
  slack: i18n.t('settings.logins.auth_providers.slack'),
  'oauth2|slack': i18n.t('settings.logins.auth_providers.slack'),
  'okta-klaus-staging': i18n.t('settings.logins.auth_providers.okta_sso'),
  'okta-klaus-production': i18n.t('settings.logins.auth_providers.okta_sso'),
  'google-oauth2': i18n.t('settings.logins.auth_providers.google'),
}
