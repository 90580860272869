import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_disputes_overview_card = _resolveComponent("disputes-overview-card")!
  const _component_disputes_over_time_card = _resolveComponent("disputes-over-time-card")!
  const _component_disputes_by_category_card = _resolveComponent("disputes-by-category-card")!
  const _component_disputers_card = _resolveComponent("disputers-card")!
  const _component_disputed_reviewers_card = _resolveComponent("disputed-reviewers-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.root)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.dashboard)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.dashboardContent)
      }, [
        _createVNode(_component_disputes_overview_card, {
          class: _normalizeClass(_ctx.$style.disputesOverview)
        }, null, 8 /* PROPS */, ["class"]),
        _createVNode(_component_disputes_over_time_card, {
          "filter-params": _ctx.filterParams,
          class: _normalizeClass(_ctx.$style.disputesOverTime)
        }, null, 8 /* PROPS */, ["filter-params", "class"]),
        _createVNode(_component_disputes_by_category_card, {
          "filter-params": _ctx.filterParams,
          class: _normalizeClass(_ctx.$style.disputesByCategory)
        }, null, 8 /* PROPS */, ["filter-params", "class"]),
        _createVNode(_component_disputers_card, {
          "filter-params": _ctx.filterParams,
          class: _normalizeClass(_ctx.$style.disputers)
        }, null, 8 /* PROPS */, ["filter-params", "class"]),
        _createVNode(_component_disputed_reviewers_card, {
          "filter-params": _ctx.filterParams,
          class: _normalizeClass(_ctx.$style.disputedReviewers)
        }, null, 8 /* PROPS */, ["filter-params", "class"])
      ], 2 /* CLASS */)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}