import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'
import dayjs from 'dayjs'

import { getCategories } from '@/modules/workspace/api/categories'
import i18n from '@/i18n'
import { formatDateIntl } from '@/utils/date'

interface Props {
  workspaceId: number
  dateRange: ComputedRef<dayjs.Dayjs>
  usedAutoQaCategories: ComputedRef<string[]>
}

export default function useAutoQaDateWarning({ workspaceId, dateRange, usedAutoQaCategories }: Props) {
  const { data } = useQuery({
    queryKey: [`rating-categories-${workspaceId}`],
    queryFn: () => getCategories(workspaceId),
    refetchOnMount: false,
  })

  const autoQaCategories = computed(() => data.value?.data?.filter((category) => category.autoQaCategory) || [])

  const autoQaCategoriesCreatedAt = computed(() =>
    autoQaCategories.value
      .filter(({ autoQaCategory }) => usedAutoQaCategories.value.includes(autoQaCategory ?? ''))
      .map(({ created }) => created),
  )

  const warningDate = computed(() => {
    if (!dateRange.value) return null

    const result = autoQaCategoriesCreatedAt.value
      .filter((createdAt) => dayjs(createdAt).utc().isAfter(dateRange.value.utc()))
      .sort((a, b) => (dayjs(a).isAfter(b) ? 1 : -1))
    return !!result?.length && result[0]
  })

  const warningMessage = computed(() => {
    if (!warningDate.value) return null

    return i18n.t('auto_qa.time_period_warning', {
      date: formatDateIntl(warningDate.value, { dateStyle: 'medium' }),
    })
  })

  return { warningMessage }
}
