import { layoutState, setLayoutState } from '@/composables/usePageLayout'
import { RootViews, type LayoutState } from '@/types/pageLayout'
import { track } from '@/utils/analytics'

export const collapsableLeftSidebar = [RootViews.Conversations, RootViews.Tasks, RootViews.Activity]

export async function handleSidebar(view: RootViews, side: 'left' | 'right', state?: string) {
  const key = side === 'left' ? 'leftSidebar' : 'rightSidebar'
  const newState: Partial<LayoutState> = {}

  if (layoutState.value[key] === state) newState[key] = undefined
  else newState[key] = state

  if (side === 'left' && state === undefined && view in collapsableLeftSidebar) {
    newState[key] = 'collapsed'
  }

  if (side === 'left' && state === 'list' && layoutState.value.rightSidebar === 'review') {
    newState.rightSidebar = undefined
  }

  await setLayoutState(view, newState, false)
  track('Sidebar toggled', { view, state, side })
}

export async function handlePageNav(view: RootViews, state: boolean) {
  await setLayoutState(view, { showPageNav: state })
  track('Subnav toggled', { view, state })
}
