import i18n, { FALLBACK_LOCALE, getLocale } from '@/i18n'

// returns an array of translation strings for a given key
export const getTranslations = (key: string) => {
  if (!key) return []

  const translationsObject = i18n.tm(key)
  return Object.keys(translationsObject).map((k: string) => i18n.rt(translationsObject[k]))
}

// check if translation exists for a given key both in global OR fallback locale messages
export const translationExists = (key: string) => {
  return i18n.te(key) || i18n.te(key, FALLBACK_LOCALE)
}

/**
 * Given a keymap of pluralization keys, return the correct key based on the count.
 */
export const getPluralizationKey = (
  keyMap: Partial<Record<Intl.LDMLPluralRule, string>>,
  count: number,
  options?: Intl.PluralRulesOptions,
) => {
  const pr = new Intl.PluralRules(getLocale(), options)
  const pluralizationKey = pr.select(count)

  return keyMap[pluralizationKey]
}
