// sometimes scroll from top ends up being -0.5 short of scrollHeight even though content is scrolled all the way to the bottom :shrug:
const DELTA = 1

export function getVisibleShadows(el: HTMLElement) {
  const { clientWidth, clientHeight, scrollWidth, scrollHeight, scrollLeft, scrollTop, offsetWidth, offsetHeight } = el
  const hasHorizontalScrollbar = clientWidth < scrollWidth
  const hasVerticalScrollbar = clientHeight < scrollHeight

  const scrolledFromLeft = offsetWidth + scrollLeft
  const scrolledFromTop = offsetHeight + scrollTop

  const scrolledToTop = scrollTop === 0
  const scrolledToRight = scrolledFromLeft >= scrollWidth
  const scrolledToBottom = scrolledFromTop >= scrollHeight - DELTA
  const scrolledToLeft = scrollLeft === 0

  return {
    top: hasVerticalScrollbar && !scrolledToTop,
    right: hasHorizontalScrollbar && !scrolledToRight,
    bottom: hasVerticalScrollbar && !scrolledToBottom,
    left: hasHorizontalScrollbar && !scrolledToLeft,
  }
}
