import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { sortBy } from 'lodash-es'
import i18n from '@/i18n'

import { getAutoQaCustomCategoryTemplates } from '../api'
import { ENABLED_AUTO_QA_CATEGORY_TYPES } from '../types'

export const CUSTOM_CATEGORIES_LIMIT = 20

export default createSharedComposable(() => {
  const presetCategories = ENABLED_AUTO_QA_CATEGORY_TYPES.map((category) => ({
    id: category,
    name: i18n.t(`settings.workspaces.rating_categories.edit.auto_categories.${category}.name`),
  }))

  const { data, refetch } = useQuery({
    queryKey: ['auto-qa-custom-category-templates'],
    queryFn: () => getAutoQaCustomCategoryTemplates(),
  })

  const customCategories = computed(() => sortBy(data.value?.templates || [], 'name'))
  const customCategoriesLimitReached = computed(() => customCategories.value.length >= CUSTOM_CATEGORIES_LIMIT)

  return { presetCategories, customCategories, customCategoriesLimitReached, refetch }
})
