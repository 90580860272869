import Mention from '@tiptap/extension-mention'
import { mergeAttributes } from '@tiptap/core'

export const DraggableSnippet = Mention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) return {}
          return { 'data-id': attributes.id }
        },
      },
      label: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) return {}
          return { 'data-label': attributes.label }
        },
      },
    }
  },
  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), node.attrs.label]
  },
  renderText({ node }) {
    return `{{${node.attrs.id}}}`
  },
  addKeyboardShortcuts() {
    return {}
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  addCommands() {
    return {
      addMention: (props) => (editor) => {
        return editor
          .chain()
          .focus()
          .insertContent([
            { type: 'mention', attrs: props },
            { type: 'text', text: ' ' },
          ])
          .run()
      },
    }
  },
})
