import analytics from '@/utils/analytics'
import type { DisputeAction } from '../../TicketContent/composables/useDispute'
import type { Dispute } from '../../TicketContent/types/dispute'

export function sendSubmitAnalytics({
  action,
  type,
  status,
  disputesCount,
  ratingsChanged,
}: {
  action: DisputeAction
  type?: Dispute['type']
  status: Dispute['status']
  disputesCount: number
  ratingsChanged: boolean
}) {
  if (action === 'APPROVE' && type === 'RATINGS') {
    status === 'ACCEPTED' ? analytics.disputeSubmitApproveReview() : analytics.disputeSubmitPartiallyApproveReview()
  } else if (action === 'APPROVE' && type === 'REVIEWEE') {
    analytics.disputeSubmitApproveReviewee()
  } else if (action === 'REJECT') {
    type === 'REVIEWEE' ? analytics.disputeSubmitRejectReviewee() : analytics.disputeSubmitRejectReview()
  } else if (action === 'EDIT') {
    analytics.disputeSubmitEdit()
  } else {
    analytics.disputeSubmit({ count: disputesCount, ratingsChanged })
  }
}
