import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-1 p-8 text-sm"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-1 p-8 text-sm"
}
const _hoisted_3 = { class: "mt-12" }
const _hoisted_4 = {
  key: 2,
  class: "flex-1 p-8 text-sm"
}
const _hoisted_5 = {
  href: "https://kibbles.klausapp.com/settings/connections#chat",
  target: "_blank",
  rel: "noreferrer noopener nofollow",
  class: "content-link"
}
const _hoisted_6 = {
  key: 3,
  class: "flex-1 p-8 text-sm"
}
const _hoisted_7 = { class: "mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_transition_fade = _resolveComponent("transition-fade")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass([_ctx.$style.wrapper, 'rounded-xl'])
  }, [
    (_ctx.zdSubdomain)
      ? (_openBlock(), _createBlock(_component_ui_spinner, { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_transition_fade, {
      appear: "",
      origin: "center",
      mode: "out-in",
      duration: 250
    }, {
      default: _withCtx(() => [
        (_ctx.showMissingCodeError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('settings.integrate.invalid.title')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.integrate.invalid.description')), 1 /* TEXT */),
              _createVNode(_component_i18n_t, {
                tag: "div",
                keypath: "settings.integrate.invalid.action_template"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: { name: 'settings.connections' },
                    class: "content-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('settings.integrate.invalid.connections')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h2", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('settings.integrate.loading.title')) + " ", 1 /* TEXT */),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.dotLoader)
                  }, null, 2 /* CLASS */)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.integrate.loading.description')), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('settings.integrate.callback_redirect_message')), 1 /* TEXT */)
              ]))
            : (_ctx.showError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('settings.integrate.error.title')), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.integrate.error.description')), 1 /* TEXT */),
                  _createVNode(_component_i18n_t, { keypath: "settings.integrate.error.retry_template" }, {
                    retry: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: { name: 'settings.connections' },
                        class: "content-link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('settings.integrate.error.retry')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    contact: _withCtx(() => [
                      _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('settings.integrate.error.contact')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('settings.integrate.callback_success_title', [_ctx.integration.label])), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.integrate.callback_redirect_message')), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('settings.integrate.callback_waiting_message')), 1 /* TEXT */)
                ]))
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}