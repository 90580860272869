import { reactive, computed } from 'vue'
import { debounce } from 'lodash-es'
import { updateUserSetting } from '@/api/user-settings'
import { type LayoutState, RootViews } from '@/types/pageLayout'
import { screens } from './useBreakpoints'
import { session } from './useSession'

export interface State extends LayoutState {
  view: RootViews
  resizing: boolean
}

const SIDEBAR_WIDTH = 320
const MIN_SIDEBAR_WIDTH = 280
const MIN_CONTENT_WIDTH = 360
const TRANSITION_DURATION = 160

const initialState: State = {
  view: undefined,
  leftSidebar: undefined,
  leftSidebarSize: SIDEBAR_WIDTH,
  rightSidebar: undefined,
  rightSidebarSize: SIDEBAR_WIDTH,
  showPageNav: true,
  resizing: false,
  contentSidebarSize: SIDEBAR_WIDTH,
}

const defaultState = Object.assign({}, initialState)

// Prevent direct updates from outside the composable
const _internalState = reactive(defaultState)
export const layoutState = computed(() => _internalState)

export const resetLayout = (view: RootViews, defaults?: Partial<LayoutState>) => {
  let persistedState = {}
  let filteredDefaults = {}
  const { pageLayout } = session.user.settings

  if (pageLayout && view in pageLayout) {
    persistedState = pageLayout[view]
  }

  if (defaults) {
    // Apply defaults only for keys that exist in persistedState
    filteredDefaults = Object.keys(defaults).reduce((result, key) => {
      if (key in persistedState && key in defaults) result[key] = defaults[key]
      return result
    }, {})
  }

  Object.assign(_internalState, { ...defaultState, view }, filteredDefaults, persistedState)
}

interface ResizeHandler {
  ref?: HTMLElement
  sizeStateKey: string
  styleProp?: string
  initialPageX: number
  isFromRight: boolean
}

export const sidebarResizer = {
  startResizing: (ref: HTMLElement) => {
    _internalState.resizing = true
    if (ref?.parentElement) ref.parentElement.style.userSelect = 'none'
    document.body.style.cursor = 'col-resize'
    document.body.style.userSelect = document.body.style.webkitUserSelect = 'none'
  },
  stopResizing: (ref: HTMLElement) => {
    _internalState.resizing = false
    if (ref?.parentElement) ref.parentElement.style.userSelect = ''
    document.body.style.cursor = 'unset'
    document.body.style.userSelect = document.body.style.webkitUserSelect = 'unset'
    persistState()
  },
  hasContentSidebar(view: RootViews) {
    return [RootViews.Conversations, RootViews.Tasks, RootViews.Activity].includes(view)
  },
  resizingAllowed() {
    const layoutSections = document.querySelector('[data-testid="page-layout-sections"]')

    return layoutSections?.scrollWidth === layoutSections?.clientWidth
  },
  getMaxAdditionalWidth() {
    const layoutSections = document.querySelector('[data-testid="page-layout-sections"]')
    let sum = 0
    if (sidebarResizer.hasContentSidebar(_internalState.view)) {
      sum += _internalState.contentSidebarSize
    }
    if (_internalState.rightSidebar) sum += _internalState.rightSidebarSize
    if (_internalState.leftSidebar) {
      sum += _internalState.leftSidebar === 'collapsed' ? 64 : _internalState.leftSidebarSize
    }

    return layoutSections.clientWidth - MIN_CONTENT_WIDTH - sum
  },
  createResizeHandler: ({ ref, sizeStateKey, styleProp, initialPageX, isFromRight }: ResizeHandler) => {
    sidebarResizer.startResizing(ref)
    const initialWidth = _internalState[sizeStateKey] || 320
    let oldPageX = 0

    const onMouseMove = ({ pageX }) => {
      const resizingAllowed = sidebarResizer.resizingAllowed()
      if (
        (!resizingAllowed && isFromRight && pageX > oldPageX) ||
        (!resizingAllowed && !isFromRight && pageX < oldPageX) ||
        resizingAllowed ||
        oldPageX === 0
      ) {
        oldPageX = pageX
      }

      if (
        sizeStateKey === 'rightSidebarSize' &&
        sidebarResizer.getMaxAdditionalWidth() === 0 &&
        initialPageX - pageX > 0 &&
        _internalState.contentSidebarSize > MIN_SIDEBAR_WIDTH
      ) {
        _internalState.contentSidebarSize = Math.max(
          _internalState.contentSidebarSize + pageX - initialPageX,
          MIN_SIDEBAR_WIDTH,
        )
        document.documentElement.style.setProperty('--content-sidebar-width', `${_internalState.contentSidebarSize}px`)
      }

      if (
        sizeStateKey === 'contentSidebarSize' &&
        _internalState.contentSidebarSize === MIN_SIDEBAR_WIDTH &&
        initialPageX - pageX < 0 &&
        _internalState.rightSidebarSize > MIN_SIDEBAR_WIDTH &&
        _internalState.rightSidebar
      ) {
        _internalState.rightSidebarSize = Math.max(
          _internalState.rightSidebarSize + initialPageX - pageX,
          MIN_SIDEBAR_WIDTH,
        )
        document.documentElement.style.setProperty('--right-sidebar-width', `${_internalState.rightSidebarSize}px`)
      }

      const maxWidth = _internalState[sizeStateKey] + sidebarResizer.getMaxAdditionalWidth()
      const sizeChange = isFromRight ? initialPageX - oldPageX : oldPageX - initialPageX
      _internalState[sizeStateKey] = Math.min(Math.max(initialWidth + sizeChange, MIN_SIDEBAR_WIDTH), maxWidth)
      if (styleProp) document.documentElement.style.setProperty(styleProp, `${_internalState[sizeStateKey]}px`)
    }

    const onMouseUp = () => {
      sidebarResizer.stopResizing(ref)
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)

    const onResizeDebounced = debounce(sidebarResizer.onWindowResize.bind(null, sizeStateKey, styleProp), 300)

    window.addEventListener('resize', onResizeDebounced)
  },
  onWindowResize: (sizeStateKey: string, styleProp: string) => {
    _internalState[sizeStateKey] = initialState[sizeStateKey]
    if (styleProp) document.documentElement.style.setProperty(styleProp, `${_internalState[sizeStateKey]}px`)
  },
}

export const handleContentSidebarResize = ({ pageX: initialPageX }, ref: HTMLElement) => {
  sidebarResizer.createResizeHandler({
    ref,
    sizeStateKey: 'contentSidebarSize',
    styleProp: '--content-sidebar-width',
    initialPageX,
    isFromRight: true,
  })
}

export const handleLeftSidebarResize = ({ pageX: initialPageX }, ref: HTMLElement) => {
  sidebarResizer.createResizeHandler({
    ref,
    sizeStateKey: 'leftSidebarSize',
    styleProp: '--left-sidebar-width',
    initialPageX,
    isFromRight: false,
  })
}

export const handleRightSidebarResize = ({ pageX: initialPageX }, ref: HTMLElement) => {
  sidebarResizer.createResizeHandler({
    ref,
    sizeStateKey: 'rightSidebarSize',
    styleProp: '--right-sidebar-width',
    initialPageX,
    isFromRight: true,
  })
}

const setSidebarSizes = (state: Partial<State>) => {
  if (sidebarResizer.resizingAllowed()) return

  if (state.leftSidebar === 'collapsed') {
    _internalState.leftSidebarSize = initialState.leftSidebarSize
  }

  if (
    state.leftSidebar === 'list' &&
    _internalState.contentSidebarSize > initialState.contentSidebarSize &&
    !state.rightSidebar
  ) {
    _internalState.contentSidebarSize = initialState.contentSidebarSize
  }

  if (state.rightSidebar === 'review' && _internalState.contentSidebarSize > initialState.contentSidebarSize) {
    _internalState.contentSidebarSize = initialState.contentSidebarSize

    if (!sidebarResizer.resizingAllowed() && _internalState.rightSidebarSize > initialState.rightSidebarSize) {
      _internalState.rightSidebarSize = initialState.rightSidebarSize
    }
  }
}

/**
 * @param view Required if persist is true, to prevent overwriting other views' state
 * @param persist Whether to persist the new state to the user's settings
 */
export const setLayoutState = async (view: RootViews, state: Partial<State>, persist = true) => {
  if (_internalState.view && _internalState.view !== view) {
    resetLayout(view)
  }

  Object.assign(_internalState, { view }, state)
  if (persist) await persistState()

  setTimeout(() => {
    if (sidebarResizer.hasContentSidebar(view)) setSidebarSizes(state)
  }, TRANSITION_DURATION)
}

const persistState = async () => {
  const newPageLayout = {
    ...(session.user?.settings.pageLayout || {}),
    [_internalState.view]: {
      leftSidebar: _internalState.leftSidebar,
      leftSidebarSize: _internalState.leftSidebarSize,
      rightSidebar: _internalState.rightSidebar,
      rightSidebarSize: _internalState.rightSidebarSize,
      contentSidebarSize: _internalState.contentSidebarSize,
      showPageNav: Boolean(_internalState.showPageNav),
    },
  }
  await updateUserSetting('pageLayout', newPageLayout)
  session.user.settings.pageLayout = newPageLayout
}

export const togglePageNav = (state: boolean) => {
  if (window.innerWidth < screens.lg) return

  _internalState.showPageNav = state
}
