import i18n from '@/i18n'
import { copy } from '@/utils/clipboard'
import type { SortMap } from '@/composables/useListState'
import config from '@/config'
import type { Quiz, QuizForm, QuizResponseOverview, QuizRespondentResponse, QuizPayload } from './types'

export const getSelectableQuizForm = (data: QuizForm | Quiz): QuizForm => {
  const { fields = [] } = data

  const quizResponse = {
    ...data,
    fields: fields.map((field) => ({
      ...field,
      options: field.options.map((option) => ({
        ...option,
        selected: false,
      })),
    })),
  }
  return quizResponse
}

export function sortQuizByPosition(quiz: Quiz): Quiz
export function sortQuizByPosition(quiz: QuizForm): QuizForm
export function sortQuizByPosition(quiz: QuizResponseOverview): QuizResponseOverview
export function sortQuizByPosition(quiz: QuizRespondentResponse): QuizRespondentResponse
export function sortQuizByPosition(quiz: Quiz | QuizForm | QuizResponseOverview | QuizRespondentResponse) {
  quiz.fields?.sort((a, b) => parseInt(a.position) - parseInt(b.position))
  quiz.fields?.forEach(({ options }) => {
    options.sort((a, b) => parseInt(a.position) - parseInt(b.position))
  })
  return quiz
}

export const getQuizPayload = (form: Quiz): QuizPayload => {
  return {
    name: form.name,
    description: form.description,
    status: form.status || 'DRAFT',
    workspaceIds: form.workspaceIds,
    fields:
      form.fields?.map(({ text, images, options }, i) => {
        return {
          text,
          images,
          type: 'MULTIPLE_CHOICE',
          position: `${i + 1}`,
          options: options.map(({ label, correct }, i) => {
            return {
              label,
              correct,
              position: `${i + 1}`,
            }
          }),
        }
      }) || [],
  }
}

export const getShareLink = (id: string) => {
  const url = new URL(window.location.origin)
  url.pathname = `${config.baseUrl}/quizzes/${id}/answer`
  return url.toString()
}

export const copyShareLink = async (id: string) => {
  const url = getShareLink(id)
  await copy(url, i18n.t('quizzes.copy_link_success'))
  return url
}

export const sortMap: SortMap = {
  name: {
    asc: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    desc: (a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
  },
  ranking: {
    asc: (a, b) => a.ranking - b.ranking,
    desc: (a, b) => b.ranking - a.ranking,
  },
  quizzesTaken: {
    asc: (a, b) => a.quizzesTaken - b.quizzesTaken,
    desc: (a, b) => b.quizzesTaken - a.quizzesTaken,
  },
  workspaceIds: {
    asc: (a, b) => (a.workspaceIds || []).length - (b.workspaceIds || []).length,
    desc: (a, b) => (b.workspaceIds || []).length - (a.workspaceIds || []).length,
  },
  publishedDate: {
    asc: (a, b) => {
      if (a.status === 'DRAFT') return -1
      if (b.status === 'DRAFT') return 1
      return new Date(a.publishedDate).getTime() - new Date(b.publishedDate).getTime()
    },
    desc: (a, b) => {
      if (a.status === 'DRAFT') return -1
      if (b.status === 'DRAFT') return 1
      return new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime()
    },
  },
  completedDate: {
    asc: (a, b) => new Date(a.completedDate).getTime() - new Date(b.completedDate).getTime(),
    desc: (a, b) => new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime(),
  },
  numberOfParticipants: {
    asc: (a, b) => a.numberOfParticipants - b.numberOfParticipants,
    desc: (a, b) => b.numberOfParticipants - a.numberOfParticipants,
  },
  averageScorePercentage: {
    asc: (a, b) => {
      if (a.status === 'DRAFT') return -1
      if (b.status === 'DRAFT') return 1
      if (a.averageScorePercentage === null) return -1
      if (b.averageScorePercentage === null) return 1

      return a.averageScorePercentage - b.averageScorePercentage
    },
    desc: (a, b) => {
      if (a.status === 'DRAFT') return 1
      if (b.status === 'DRAFT') return -1
      if (a.averageScorePercentage === null) return 1
      if (b.averageScorePercentage === null) return -1

      return b.averageScorePercentage - a.averageScorePercentage
    },
  },
  score: {
    asc: (a, b) => a.score - b.score,
    desc: (a, b) => b.score - a.score,
  },
  userScorePercentage: {
    asc: (a, b) => a.userScorePercentage - b.userScorePercentage,
    desc: (a, b) => b.userScorePercentage - a.userScorePercentage,
  },
}
