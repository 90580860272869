import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_name = _resolveComponent("workspace-name")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-xs", [_ctx.$style.label, _ctx.showWorkspaceName && _ctx.$style.column]]),
    style: _normalizeStyle({
      textAlign: _ctx.align,
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([!_ctx.active && _ctx.$style.inactive, _ctx.textSize === 'medium' && 'text-sm'])
    }, [
      (_ctx.critical)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["font-medium", _ctx.$style.critical])
          }, "!!", 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1 /* TEXT */)
    ], 2 /* CLASS */),
    (_ctx.showWorkspaceName)
      ? (_openBlock(), _createBlock(_component_workspace_name, {
          key: 0,
          "workspace-id": _ctx.category ? +_ctx.category.workspaceId : undefined
        }, null, 8 /* PROPS */, ["workspace-id"]))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}