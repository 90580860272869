import type { CSVURLResponse } from '@zendesk/zqa-services/dashboard'
import { api } from '@/api'
import type { FilterParams, RatingCategoryDetails, PredefinedCategoryReasons, OtherCategoryReasons } from '../types'
import { apiCallGenerator, exportTimeout } from './index'

export interface CategoryFilterParams extends FilterParams {
  categoryId: string
}

const createApiCall = apiCallGenerator<CategoryFilterParams>(({ categoryId }) => `category-insights/${categoryId}`)

export const getAllExportsCsv = createApiCall<CSVURLResponse>('export.csv', {
  timeout: exportTimeout,
})

export const getCategoryDetails = async (filterParams: CategoryFilterParams, signal?: AbortSignal) => {
  const apiCall = createApiCall<RatingCategoryDetails>('ratings')
  const response = await apiCall(filterParams, signal)
  const noData =
    (!response.scores.length || !response.scores.filter((score) => score != undefined).length) &&
    (!response.counts.length || !response.counts.filter(Boolean).length)

  response.rows = noData
    ? []
    : [
        {
          label: 'Category score',
          isScore: true,
          data: response.scores,
          total: response.score,
        },
        {
          label: 'Root cause count',
          isScore: false,
          data: response.counts,
          total: response.count,
        },
      ]

  return response
}

export const getCategoryDetailsCsv = createApiCall<CSVURLResponse>('ratings.csv')

export const getPredefinedCategoryReasons = createApiCall<PredefinedCategoryReasons>('predefined-root-causes')

export const getPredefinedCategoryReasonsCsv = createApiCall<CSVURLResponse>('predefined-root-causes.csv')

export const getOtherCategoryReasons = createApiCall<OtherCategoryReasons>('other-root-causes')

export const getOtherCategoryReasonsCsv = createApiCall<CSVURLResponse>('other-root-causes.csv')

export interface RatingCategory {
  active: boolean
  critical: boolean
  description: string
  id: string
  maxRating: number
  minRating: number
  name: string
  scale: string
  weight: number
}
export const getRatingCategory = ({
  workspaceId,
  categoryId,
}: {
  workspaceId: number
  categoryId: string
}): Promise<RatingCategory> =>
  api.get(`categories/${categoryId}`, { headers: { 'X-Klaus-Workspace': workspaceId.toString() } }).json()
