import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'

import config, { embeddedRuntime, hasZendeskProxy } from './config'
import { session, signupState } from './composables/useSession'
import { routeMetavalue } from './utils/routes'
import { getLoginReturnPath, setLoginReturnPath, logout } from './modules/auth/api'
import { hasAccountRole, hasHighestWorkspaceRole, hasWorkspaceRole } from './utils/roleUtils'
import { bus } from './utils/bus'

import activityFeedRoutes from './modules/activity/routes'
import authRoutes from './modules/auth/routes'
import coachingRoutes from './modules/coaching/routes'
import conversationsRoutes from './modules/conversations/routes'
import extensionRoutes from './modules/extension/routes'
import dashboardRoutes from './modules/dashboard/routes'
import onboardingRoutes from './modules/onboarding/routes'
import pinsRoutes from './modules/pins/routes'
import quizzesRoutes from './modules/quizzes/routes'
import settingsRoutes from './modules/settings/routes'
import tasksRoutes from './modules/tasks/routes'

import surveyRoutes from './modules/survey/routes'
import userManagementRoutes from './modules/user-management/routes'
import workspaceRoutes from './modules/workspace/routes'
import { getDocumentTitle } from './modules/application/components/CommandPalette/utils/useRoutes'
import i18n from './i18n'
import { hasSufficientTickets } from './composables/useTicketsExist'
import type { Features } from './types/session'

const isProxied = hasZendeskProxy || session.features.zendeskProxyDev
const accountHasNoSufficientTickets = !hasSufficientTickets.value

export const generateRouter = () => {
  const router = createRouter({
    history: createWebHistory(config.baseUrl || '/'),
    routes: [
      // Redirect agents to feedback
      {
        path: '/',
        name: 'landing',
        redirect: () => {
          if (embeddedRuntime()) return { name: 'extension.reconnect' }
          return { name: hasWorkspaceRole('AGENT') ? 'activity' : 'conversations' }
        },
      },
      {
        /**
         * Old context switching route
         * TODO: Get rid of this eventually - needs checking of email templates, slack bot etc
         * Account switching is already handled on load
         */
        path: '/switch',
        name: 'switch',
        redirect({ query }) {
          const path = query.redirectTo ? decodeURIComponent(query.redirectTo as string) : '/'
          const route = router.resolve(path)
          if (query.team) route.query.workspace = query.team
          return route
        },
      },
      {
        path: '/account-not-found/:reason?',
        name: 'account-not-found',
        meta: { skipAuth: 'optional', hideNav: true },
        component: () => import('./modules/application/AccountNotFoundView.vue'),
      },
      ...activityFeedRoutes(),
      ...coachingRoutes,
      ...conversationsRoutes(),
      ...dashboardRoutes,
      ...extensionRoutes,
      ...onboardingRoutes,
      ...pinsRoutes,
      ...quizzesRoutes,
      ...settingsRoutes,
      ...surveyRoutes,
      ...tasksRoutes(),
      ...userManagementRoutes,
      ...workspaceRoutes,
      ...authRoutes,
      // 404 page catch all
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('./modules/application/NotFoundView.vue'),
      },
    ],
  })

  router.beforeEach((to, _from, next) => {
    const skipAuth = routeMetavalue(to, 'skipAuth')

    if (!session.isAuthenticated) {
      // Show login page when trying to view authenticated pages
      if (!skipAuth) {
        if (to.name !== 'not-found') setLoginReturnPath(to.fullPath)
        return next({ name: 'login' })
      }

      return next()
    }

    // User is not part of any account & is not using invite
    const omitRoutes = ['account-exists', 'onboarding', 'invite', 'account-not-found']
    if (!session.account && !omitRoutes.includes(to.name?.toString() ?? '')) {
      if (signupState.value?.hasInvites && to.name === 'pending-invites') return next()
      else if (signupState.value?.hasInvites) return next({ name: 'pending-invites' })

      // If email domain matches, redirect to prompt page
      if (session.domainAccountMatch) return next({ name: 'account-exists' })

      if (session.features.disableAccountSignup) {
        logout({ accountNotFound: 'true' })
        return
      }

      return next({ name: 'onboarding' })
    }

    // Don't allow access to onboarding when user has account set up
    if (session.account && session.account.connectionCount > 0 && to.name === 'onboarding') {
      return next({ name: 'landing' })
    }

    // Lock down demo mode if Meowdesk is the only connection
    const routeDisabledForDemo = to.matched.some(
      (r) => r.name === 'settings.connections' || r.name === 'workspace.connections',
    )
    if (session.account?.hasRegularDemo && routeDisabledForDemo) {
      bus.$emit('show-connect-helpdesk-modal')
      return next(false)
    }

    // User does not belong to workspace, redirect to unconfigured account view
    if (
      session.account &&
      !session.workspace &&
      !['unconfigured-account', 'settings.users'].includes(to.name?.toString() ?? '')
    ) {
      return next({ name: 'unconfigured-account' })
    }

    // Feature flag check
    const requiredFlag = routeMetavalue(to, 'requiredFlag')
    if (requiredFlag) {
      let isAccessGranted = false

      if (typeof requiredFlag === 'string') {
        isAccessGranted = session.features[requiredFlag]
      } else if (typeof requiredFlag === 'object' && requiredFlag.flags) {
        const { condition, flags } = requiredFlag
        const flagExists = (flag: keyof Features) => session.features[flag]

        if (condition === 'AND') isAccessGranted = flags.every(flagExists)
        if (condition === 'OR') isAccessGranted = flags.some(flagExists)
      }

      if (!isAccessGranted) {
        return next({
          name: 'not-found',
          // preserve url + query + hash
          params: { pathMatch: to.path.substring(1).split('/') },
          query: to.query,
          hash: to.hash,
        })
      }
    }

    // Check if is Zendesk account
    const hideProxied = routeMetavalue(to, 'hideProxied')
    if (hideProxied) {
      return next({
        name: 'not-found',
        // preserve url + query + hash
        params: { pathMatch: to.path.substring(1).split('/') },
        query: to.query,
        hash: to.hash,
      })
    }

    // Disallow non-extension routes if app is embedded
    const allowEmbedding = routeMetavalue(to, 'allowEmbedding')
    if (embeddedRuntime() && !allowEmbedding) {
      console.error('ZQA | Blocked routing to', to.fullPath)
      return next({ name: 'extension.reload' })
    }

    // Role access check
    const requiredHighestWorkspaceRole = routeMetavalue(to, 'requiredHighestWorkspaceRole')
    const requiredAccountRole = routeMetavalue(to, 'requiredAccountRole')

    if (
      (requiredAccountRole && !hasAccountRole(...requiredAccountRole)) ||
      (requiredHighestWorkspaceRole &&
        !hasAccountRole('ADMIN', 'MANAGER') &&
        !hasHighestWorkspaceRole(...requiredHighestWorkspaceRole))
    ) {
      bus.$emit('universal-error', { error: i18n.t('universal.errors.access_denied'), ref: 'access-denied' })
      return next({ name: 'landing' })
    }

    // Check if account needs to pay - either due to finished trial or just overdue
    const routeRequiresPayment = routeMetavalue(to, 'requiresPayment')
    if (routeRequiresPayment && session.account.requiresPayment) {
      if (embeddedRuntime()) return next({ name: 'extension.subscription' })
      return next({ name: session.account.status === 'trial_ended' ? 'trial-ended' : 'blocked' })
    }

    // Check if ZD account has conversations - demo tickets does not count
    const trialEnded = routeRequiresPayment && session.account.requiresPayment
    const routeRequiresSufficientConversations = routeMetavalue(to, 'requiresSufficientConversations')
    if (isProxied && routeRequiresSufficientConversations && accountHasNoSufficientTickets && !trialEnded) {
      return next({ name: 'no-conversations-found' })
    }

    // Look up post login redirect url if any
    const loginReturnPath = getLoginReturnPath()
    if (loginReturnPath) return next(loginReturnPath)

    // Show landing page when trying to view authenticated pages
    if (skipAuth === true) {
      if (to.name === 'invite') {
        // Show accept invite flow to users who have user with us
        // TODO: Maybe autoprovision internal_user with authorization in session API to skip the check
        if (session.user) {
          return next({ name: 'invite-existing', query: to.query })
        } else if (signupState.value?.hasInvites) return next({ name: 'pending-invites' })
      } else {
        return next({ name: 'landing' })
      }
    }

    next()
  })

  router.afterEach((to, _from, failure) => {
    if (isNavigationFailure(failure)) return

    document.title = getDocumentTitle(to)
  })

  return router
}
