import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { UiTagTheme } from '@klausapp/ui-kit/types'
import { AssignmentV2_Assignment_Status, type AssignmentV2_UserAvatar } from '@zendesk/zqa-services/assignments_v2'
import type { UserGroup as UserGroupV2 } from '@zendesk/zqa-services/user-groups'
import type { Bot } from '@zendesk/zqa-services/users'
import type {
  AssignmentV2_Assignment,
  AssignmentV2_CreateAssignmentRequest,
  AssignmentV2_UpdateAssignmentRequest,
} from '@/modules/workspace/views/assignments/types/assignments'

import { type AssignmentV2_Assignment_Participants } from '../types/assignments'

dayjs.extend(customParseFormat)

export const getRequiredFiltersV2 = () => [
  {
    condition: 'DATE_30_DAYS',
    option: 'TICKET_CREATED_DATE',
    type: 'DEFAULT',
    values: [] as any[],
  },
]

const getUserIds = (users: AssignmentV2_UserAvatar[]) => users.map((u) => u.id)
const getGroupIds = (groups: UserGroupV2[]) => groups.map((g) => g.id)
const getBotIds = (bots: Bot[]) => bots.map((b) => b.id)

export const generateParticipantsV2 = (participants: AssignmentV2_Assignment_Participants) => {
  return {
    reviewers: getUserIds(participants.reviewers),
    excludeReviewers: getUserIds(participants.excludeReviewers),
    reviewees: getUserIds(participants.reviewees),
    excludeReviewees: getUserIds(participants.excludeReviewees),
    botReviewees: getBotIds(participants.botReviewees),
    excludeBotReviewees: getBotIds(participants.excludeBotReviewees),
    reviewerGroups: getGroupIds(participants.reviewerGroups),
    revieweeGroups: getGroupIds(participants.revieweeGroups),
    excludeRevieweeGroups: getGroupIds(participants.excludeRevieweeGroups || []),
    excludeReviewerGroups: getGroupIds(participants.excludeReviewerGroups || []),
  }
}

interface ParamsV2 {
  workspaceId: number
  assignmentId: string
  data: AssignmentV2_CreateAssignmentRequest | AssignmentV2_UpdateAssignmentRequest
}

export const getAssignmentPayloadV2 = (
  workspaceId: number,
  assignment: AssignmentV2_Assignment,
  isCreate?: boolean,
): ParamsV2 => {
  return {
    workspaceId: parseInt(workspaceId.toString(), 10),
    assignmentId: isCreate ? `${assignment.id}1` : assignment.id,
    data: {
      ...assignment,
      participants: generateParticipantsV2(assignment.participants),
    },
  }
}

export const statusColorMap = {
  [AssignmentV2_Assignment_Status.ACTIVE]: UiTagTheme.GreenLight,
  [AssignmentV2_Assignment_Status.DRAFT]: UiTagTheme.Default,
  [AssignmentV2_Assignment_Status.INACTIVE]: UiTagTheme.Default,
  [AssignmentV2_Assignment_Status.PENDING]: UiTagTheme.BrandLight,
  [AssignmentV2_Assignment_Status.UNDEFINED]: UiTagTheme.Default,
  [AssignmentV2_Assignment_Status.RESET]: UiTagTheme.Default,
}
