import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pin_icon = _resolveComponent("pin-icon")!
  const _component_tippy = _resolveComponent("tippy")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    trigger: _ctx.hasPins ? 'mouseenter focus' : 'manual',
    interactive: "",
    theme: "light"
  }, {
    trigger: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        "data-testid": "pin-conversation",
        type: "button",
        class: _normalizeClass({ '!bg-active-secondary !text-active-icon': _ctx.hasPins }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
      }, [
        _createVNode(_component_pin_icon, { class: "icon-m" })
      ], 2 /* CLASS */)), [
        [_directive_tippy, { delay: _ctx.delay, content: !_ctx.hasPins && _ctx.$t('conversations.ticket.pin_it') }]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass([_ctx.$style.button]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
      }, _toDisplayString(_ctx.$t('conversations.ticket.pin_it')), 3 /* TEXT, CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["trigger"]))
}