import { AwardIcon } from '@klausapp/ui-kit/icons'
import i18n from '@/i18n'
import AcademyHatIcon from '@/modules/application/assets/academy-hat.svg'
import PinIcon from '@/assets/pin-icon.svg'

export const getCoachingRoutes = () => [
  {
    value: 'coaching.sessions',
    icon: AcademyHatIcon,
    iconBgColorClass: 'bg-pink-50',
    label: i18n.t('routes.coaching.label.sessions'),
  },
  {
    value: 'pins',
    icon: PinIcon,
    iconBgColorClass: 'bg-green-40',
    label: i18n.t('routes.coaching.label.pins'),
  },
  {
    value: 'quizzes',
    icon: AwardIcon,
    iconBgColorClass: 'bg-active-primary',
    label: i18n.t('routes.coaching.label.quizzes'),
  },
]

export const getCoachingTitle = (view: string) => {
  const routes = getCoachingRoutes()

  return (routes.find((route) => route.value === view) || routes[0]).label
}
