export const TICKET_CONTENT_ID = 'ticket-content'
export const TICKET_RESPONSES_ID = 'ticket-responses'
export const MESSAGE_ID_PREFIX = 'message-'
export const TRANSCRIPTION_HEADER_ID_PREFIX = 'transcription-header'

export const AUDIO_PLAYER_IDS = {
  PLAY_PAUSE_BUTTON: 'play-pause-button',
  REWIND_BUTTON: 'rewind-button',
  FORWARD_BUTTON: 'forward-button',
  TIME_STAMP: 'time-stamp',
  PLAYBACK_RATE_SELECT: 'playback-rate-select',
  INVALID_RECORDING_ERROR: 'invalid-recording-error',
  LOADING_SPINNER: 'audio-loading-spinner',
  AUDIO_WAVEFORM: 'audio-waveform',
} as const

export function getMessageElement(id: string) {
  return document.getElementById(MESSAGE_ID_PREFIX + id)
}

export function getDeadAirElement(id: string) {
  return document.getElementById(`${MESSAGE_ID_PREFIX + id}-dead-air`)
}

export function getTicketResponsesElement() {
  return document.getElementById(TICKET_RESPONSES_ID)
}

export function getTicketContentElement() {
  return document.getElementById(TICKET_CONTENT_ID)
}

export function getTranscriptionHeaderElement(transcriptionId: string) {
  return document.getElementById(TRANSCRIPTION_HEADER_ID_PREFIX + transcriptionId)
}
