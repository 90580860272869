import { ref } from 'vue'

import { layoutState, setLayoutState } from '@/composables/usePageLayout'
import { RootViews } from '@/types/pageLayout'
import type { TicketListFilters } from '../components/visualFilters/types'
import { StatisticalDistributionPeriod } from '../types'

export const showVisualFilters = ref(false)
export const visualFiltersInitialized = ref(false)
export const visualFilterParams = ref<TicketListFilters | null>(null)
export const timePeriod = ref(StatisticalDistributionPeriod.Last30Days)

export const resetVisualFilters = () => {
  showVisualFilters.value = false
  visualFilterParams.value = null
  if (layoutState.value.leftSidebar !== 'list') setLayoutState(RootViews.Conversations, { leftSidebar: 'list' })
}

export const handleVisualFilterPane = () => {
  visualFiltersInitialized.value = true
  showVisualFilters.value = !showVisualFilters.value
  setLayoutState(RootViews.Conversations, { leftSidebar: undefined, rightSidebar: 'list' })
}
