import { UiDropdownTypes } from '@klausapp/ui-kit'
import TimePeriodPicker from '../TimePeriodPickerV2.vue'
import WorkspacePicker from '../WorkspacePickerV2.vue'
import { filterKey } from './keys'

import type { FilterBarFilter } from './types'
import { toSortedFilters } from './util'

const filters: FilterBarFilter[] = [
  {
    component: TimePeriodPicker,
    key: filterKey.timePicker,
  },
  {
    component: WorkspacePicker,
    key: filterKey.workspacePicker,
    mode: UiDropdownTypes.Modes.Multiple,
  },
]

export default toSortedFilters(filters)
