import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'
import { uniq } from 'lodash-es'
import { getCsatWordCloudValues } from '@/modules/dashboard/csat/api'

type Props = ComputedRef<{
  searchQuery: string
  workspaceIds: number[]
  questionTypeId: number
  selectedWords: string[]
}>

export function useCsatWordcloudWords(props: Props) {
  const searchQuery = computed(() => props.value.searchQuery)
  const workspaceIds = computed(() => props.value.workspaceIds)
  const questionTypeId = computed(() => props.value.questionTypeId)
  const selectedWords = computed(() => props.value.selectedWords)

  const { data: wordOptionsData, isPending: isLoading } = useQuery({
    queryKey: ['wordcloud-words', workspaceIds, searchQuery],
    queryFn: ({ signal }) =>
      getCsatWordCloudValues({
        workspaceIds: workspaceIds.value,
        questionTypeId: questionTypeId.value ? String(questionTypeId.value) : undefined,
        searchString: searchQuery.value || undefined,
        signal,
      }),
    select: (data) => ({
      words: uniq(data.values.concat(selectedWords.value)).map((word) => ({ id: word, name: word })),
      total: Number(data.total),
    }),
  })

  return { wordOptionsData, isLoading }
}
