import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { capitalize, isString } from 'lodash-es'

import i18n from '@/i18n'
import type { Condition } from '@/components/filters/types'
import type { FilterOptions, Options, FilterValue } from '../api/filters'

export interface CustomCategoryFilterOption {
  name: string
  value: string
}

export interface DropdownOption {
  [x: string]: any
  allowMultiple: boolean
  name: string
  label: string
  isCustom: boolean
  isAutoQa: boolean
  isSpotlight: boolean
  isCustomCategory: boolean
  id?: string
  customPhrases?: string[]
  positiveCustomPhrases?: string[]
  negativeCustomPhrases?: string[]
  options?: CustomCategoryFilterOption[]
}

const stringConditions = ['STRING_CONTAINS', 'STRING_NOT_CONTAINS', 'STRING_EQUALS', 'STRING_NOT_EQUALS']
export const missingValuesMap = {
  1: i18n.t('filter.value.current_user'),
  2: i18n.t('filter.value.workspace_users'),
  3: i18n.t('filter.value.removed_workspace_users'),
} as const
export const dateConditions = [
  'TICKET_CLOSED_DATE',
  'COMMENTED_DATE',
  'TICKET_CREATED_DATE',
  'TICKET_UPDATED_DATE',
  'REVIEWED_DATE',
  'CSAT_ANSWERED_DATE',
  'TICKET_CSAT_CREATED',
  'LAST_EXTERNAL_COMMENT_DATE',
]
export const userConditions = ['ASSIGNEE', 'PUBLIC_PARTICIPANT', 'RELATED', 'REVIEWED_BY', 'REVIEWEE']
export const botConditions = ['RELATED', 'REVIEWEE']
export const convertValueToLabel = (str: string) => (str ? capitalize(str.replace(/_/g, ' ')) : '')

export type FilterOptionValue = string | string[] | Date | Value[]

export interface ConstructedFilterOption {
  type?: string
  option?: string
  condition?: string
  ticketFieldName?: string
  ticketFieldId?: string
  values?: FilterOptionValue
  externalTicketFieldId?: any
  customSpotlightId?: string
  customCategoryId?: string
  customPhrases?: string[]
  spotlightValues?: {
    customPhrases: string
    id: string
    name: string
  }[]
  customCategoryValues?: {
    label: string
    type: string | null
    value: string
  }[]
}

const _isValueArray = (arr: unknown): arr is Value[] => {
  return Array.isArray(arr) && arr.every((v) => typeof v === 'object' && v !== null && 'label' in v && 'value' in v)
}

export const constructFilterOptions = (options: DeconstructedOption[], isAssignments?: boolean) =>
  options.map((opt) => {
    const option: ConstructedFilterOption = {
      type: 'DEFAULT',
      option: opt.option.name,
      condition: opt.condition.name,
    }

    if (opt.option.isSpotlight) {
      option.type = 'SPOTLIGHT'
      option.customSpotlightId = opt.option.id
      option.option = 'SPOTLIGHT_CUSTOM_PHRASE_SEARCH'
      option.customPhrases = opt.option.customPhrases
    }

    if (opt.option.isCustomCategory) {
      option.type = 'CUSTOM_CATEGORY'
      option.customCategoryId = opt.option.id
      option.option = 'CUSTOM_CATEGORY_CUSTOM_PHRASE_SEARCH'
    }

    if (opt.condition.valueRequired) {
      // Custom field
      if (opt.option.isCustom) {
        option.externalTicketFieldId = opt.option.name
        option.ticketFieldName = opt.option.label
        option.type = 'TICKET_FIELD'
        option.option = 'TICKET_FIELD'
      }

      if (opt.condition.name?.startsWith('LONG_LIST') || Array.isArray(opt.values)) {
        const customCategoryValues =
          _isValueArray(opt.values) && isAssignments
            ? opt.values.map((v) => ({ label: opt.option.id, value: v.value }))
            : (_isValueArray(opt.values) && opt.values.map((v) => v.label)) || []

        option.values = opt.option.isCustomCategory ? customCategoryValues : opt.values
      } else if (opt.condition.name?.startsWith('DATE_')) {
        const d = dayjs(opt.values).format('YYYY-MM-DD')
        option.values = [
          {
            value: d,
            label: d,
          },
        ]
      } else {
        const stringVal = isString(opt.values)
        option.values = [
          {
            // some .value and .label doesn't seem to exist in opt, not sure if safe to remove 🤷
            value: stringVal ? opt.values : (opt.values as any)?.value,
            label: stringVal ? null : (opt.values as any)?.label || convertValueToLabel((opt as any).value.value),
          },
        ]
      }
    }

    return option
  })

interface DynamicFilterOption {
  type: string
  option: { name: string } | string
  condition: { name: string } | string
  values: { value: number; label: null }[]
}

export const constructDynamicFilterOptions = ({
  options,
  conditions,
  values,
  forFilter,
}: {
  options: string[]
  conditions: string[]
  values: number[]
  forFilter: boolean
}): DynamicFilterOption[] => {
  return options.map((opt, i) => ({
    type: 'DEFAULT',
    option: forFilter ? { name: opt } : opt,
    condition: forFilter ? { name: conditions[i] } : conditions[i],
    values: [
      {
        value: values[i],
        label: null,
      },
    ],
  }))
}

export interface Value {
  label: string
  value?: string
  name?: string
  isCustom?: boolean
  fieldId?: string
  type?: string
}

export interface DeconstructedOption {
  id: string
  option: {
    id?: string
    name?: string
    label?: string
    title?: string
    isCustom?: boolean
    isAutoQa?: boolean
    isSpotlight?: boolean
    isCustomCategory?: boolean
    intelligenceIcon?: boolean
    filterOption?: string
    customPhrases?: string[]
    options?: CustomCategoryFilterOption[]
  }
  condition: Condition
  type?: string
  values?: FilterOptionValue
}

const _isCustomSpotlightOption = (obj: unknown): obj is ConstructedFilterOption => {
  return typeof obj === 'object' && obj !== null && ('spotlightValues' in obj || 'customSpotlightId' in obj)
}

const _isCustomCategoryOption = (obj: unknown): obj is ConstructedFilterOption => {
  return typeof obj === 'object' && obj !== null && 'customCategoryValues' in obj
}

export const deconstructFilterOptions = (
  options: FilterOptions['options'],
  autoQaOptions: FilterOptions['autoQaOptions'],
  spotlightOptions: FilterOptions['spotlightOptions'],
  customCategoryOptions: FilterOptions['customCategoryOptions'],
  defaultOptions?: Options['defaultFilterOptions'],
  defaultAutoQaOptions?: Options['autoQaFilterOptions'],
  defaultSpotlightOptions?: Options['spotlightFilterOptions'],
  defaultCustomCategoryOptions?: Options['customCategoryFilterOptions'],
  displayAsEmoji?: boolean,
  isAssignments?: boolean,
) => {
  return [
    ...options.map((o) => ({ ...o, isAutoQa: false, isSpotlight: false, isCustomCategory: false })),
    ...autoQaOptions.map((o) => ({ ...o, isAutoQa: true, isSpotlight: false, isCustomCategory: false })),
    ...spotlightOptions.map((o) => ({
      ...o,
      isAutoQa: false,
      isSpotlight: true,
      isCustomCategory: false,
      type: 'SPOTLIGHT',
    })),
    ...customCategoryOptions.map((o) => ({ ...o, isAutoQa: false, isSpotlight: false, isCustomCategory: true })),
  ].map((opt) => {
    const optionsMap = [
      ...defaultOptions,
      ...defaultAutoQaOptions,
      ...defaultSpotlightOptions,
      ...defaultCustomCategoryOptions,
    ]
    const defaultOption = optionsMap.find((o) => {
      if (opt.isCustomCategory)
        return isAssignments
          ? opt.values?.[0].label === o.id
          : _isCustomCategoryOption(opt) && opt.customCategoryValues?.[0].label === o.id
      if (opt.isSpotlight && _isCustomSpotlightOption(opt))
        return opt.customSpotlightId ? opt.customSpotlightId === o.id : opt.spotlightValues?.[0].id === o.id
      return o.filterOption === opt.option
    })
    const defaultCondition = defaultOption?.conditions.find((c) => c.condition === opt.condition)

    const option: DeconstructedOption = {
      id: defaultOption?.id ?? uuid(),
      option: {
        name: opt.option,
        label: defaultOption?.labelCode,
        isAutoQa: opt.isAutoQa,
        isSpotlight: opt.isSpotlight,
        isCustomCategory: opt.isCustomCategory,
      },
      condition: {
        name: opt.condition,
        label: defaultCondition?.labelCode,
        valueRequired: defaultCondition?.valueRequired,
      },
      type: opt.type,
    }

    if (opt.option === 'TICKET_EXTERNAL_ID') {
      option.values = opt.values?.[0].value
      return option
    }

    if (option.type === 'TICKET_FIELD') {
      option.option.name = opt.ticketFieldId || opt.externalTicketFieldId
      option.option.label = opt.ticketFieldName || opt.externalTicketFieldId
      option.option.title = opt.ticketFieldName || opt.externalTicketFieldId
      option.option.isCustom = true
    }

    if (option.type === 'AUTO_QA') option.option.isAutoQa = true
    if (option.type === 'SPOTLIGHT') {
      option.option.isSpotlight = true
      option.option.id = defaultOption?.id
      option.option.customPhrases = defaultOption.customPhrases
      option.option.label = defaultOption?.name
      option.option.name = defaultOption?.name
    }

    if (option.type === 'CUSTOM_CATEGORY') {
      option.option.id = defaultOption?.id
      option.option.name = defaultOption?.name
      option.option.label = defaultOption?.name
      option.option.options = defaultOption?.options
    }

    if (option.condition.valueRequired) {
      if (option.option.isCustom) {
        option.values = (Array.isArray(opt.values) ? opt.values : [])?.map((v) => ({
          ...v,
          name: v.label,
          isCustom: true,
          fieldId: 'ticketFieldId' in opt ? opt.ticketFieldId : undefined,
        }))
      } else if (option.option.isAutoQa || option.option.isCustomCategory) {
        const customCategoryOptions = _isCustomCategoryOption(opt) ? opt.customCategoryValues : opt.values
        const valuesToMap = option.option.isCustomCategory ? customCategoryOptions : opt.values
        option.values = (Array.isArray(valuesToMap) ? valuesToMap : [])?.map((v) => ({
          ...v,
          label: isAssignments ? v.value : v.label,
          name: option.option.name,
          emoji: displayAsEmoji && !!Number(v.value),
        }))
      } else if (opt.condition?.startsWith('DATE_')) {
        option.values = dayjs(opt.values?.[0].value).toDate()
      } else if (opt.condition?.startsWith('INTEGER_') || (opt.condition && stringConditions.includes(opt.condition))) {
        option.values = opt.values?.[0].value
      } else if (opt.condition?.startsWith('LONG_LIST') || Array.isArray(opt.values)) {
        // Remap values where the label is missing and construct one
        option.values = (Array.isArray(opt.values) ? opt.values : [])?.map((v) =>
          v.label
            ? v
            : {
                ...v,
                ...{
                  label: missingValuesMap[opt.values?.[0].value || ''] || opt.values?.[0].label,
                },
              },
        )
      } else {
        const firstOption = opt.values?.[0] as FilterValue | undefined
        option.values = [
          {
            value: firstOption?.value,
            label: missingValuesMap[firstOption?.value || ''] || firstOption?.label,
          },
        ]
      }
    } else {
      option.values = []
    }

    return option
  })
}

export const generateFilterLabels = (
  options: DynamicFilterOption[],
  defaultOptions?: Options['defaultFilterOptions'],
) => {
  return options.map((opt) => {
    if ((opt.option as any).label && (opt.condition as any).label) return opt

    const option = defaultOptions?.find((o) => typeof opt.option !== 'string' && o.filterOption === opt.option.name)
    const condition = option?.conditions.find(
      (c) => typeof opt.condition !== 'string' && c.condition === opt.condition.name,
    )
    const values = opt.values.map((i) => {
      if (!(condition as any)?.valueOptions) return { label: i.label, value: String(i.value) }
      const value = (condition as any)?.valueOptions.find((v) => v.value === i.value || v.value === +i.value)

      return { label: value.label, value: value.value }
    })

    return {
      option: {
        name: option?.filterOption,
        label: i18n.t(option?.labelCode || ''),
      },
      condition: {
        name: condition?.condition,
        label: i18n.t(condition?.labelCode || ''),
        valueRequired: condition?.valueRequired,
      },
      values,
    }
  })
}

export const isValidOption = (opt) => {
  const { option, condition, values } = opt
  const hasOption = option && option.name
  const hasCondition = condition && condition.name
  let hasValues

  if (option.isCustom) hasValues = values[0]?.value !== undefined && values[0]?.value !== ''
  else if (values) hasValues = !!(Array.isArray(values) ? values : Object.values(values)).length

  if (!hasValues && !option.isCustom) hasValues = values instanceof Date || !!values.length

  return hasOption && hasCondition && (!condition.valueRequired || hasValues)
}

export const translateUserConditionSpecialValueLabel = (option: any): string => {
  return Object.keys(missingValuesMap).includes(option.value) ? missingValuesMap[option.value] : option.label
}

export const getDayjsRangeFromOption = (option: DeconstructedOption): dayjs.Dayjs => {
  switch (option.condition?.name) {
    case 'DATE_LAST_24':
      return dayjs().subtract(24, 'hour')

    case 'DATE_TODAY':
      return dayjs().startOf('day')

    case 'DATE_YESTERDAY':
      return dayjs().subtract(1, 'day').startOf('day')

    case 'DATE_LAST_7_DAYS':
      return dayjs().subtract(7, 'day').startOf('day')

    case 'DATE_THIS_WEEK':
      return dayjs().startOf('week')

    case 'DATE_LAST_WEEK':
      return dayjs().startOf('week').subtract(7, 'day')

    case 'DATE_30_DAYS':
      return dayjs().subtract(30, 'day')

    case 'DATE_THIS_MONTH':
      return dayjs().startOf('month')

    case 'DATE_LAST_MONTH':
      return dayjs().subtract(1, 'month').startOf('month')

    case 'DATE_FROM':
      return dayjs(option.values.toString())

    case 'DATE_TO':
      return dayjs('1970-00-00')

    default:
      return dayjs('1970-00-00')
  }
}
