import i18n from '@/i18n'

export const titles = {
  dashboard: i18n.t('routes.dashboard.label.reviews'),
  'dashboard.overview': i18n.t('routes.dashboard.label.overview'),
  'dashboard.overview.looker': i18n.t('routes.dashboard.label.overview'),
  'dashboard.assignments': i18n.t('routes.dashboard.label.assignments'),
  'dashboard.surveys': i18n.t('routes.dashboard.label.surveys'),
  'dashboard.disputes': i18n.t('routes.dashboard.label.disputes'),
  'dashboard.calibration': i18n.t('routes.dashboard.label.calibration'),
  'dashboard.category': i18n.t('routes.dashboard.label.categories'),
  'dashboard.auto-qa': i18n.t('routes.dashboard.label.auto_qa'),
  'dashboard.auto-qa.looker': i18n.t('routes.dashboard.label.auto_qa'),
  'dashboard.reviews': i18n.t('routes.dashboard.label.reviews'),
  'dashboard.bot-qa': i18n.t('routes.dashboard.label.bot_qa'),
}
