/**
 * This module returns the locale-based start of the week
 *
 * https://github.com/gamtiq/weekstart
 * Also see https://stackoverflow.com/a/57102881/2803743
 */

const regionSat = ['AE', 'AF', 'BH', 'DJ', 'DZ', 'EG', 'IQ', 'IR', 'JO', 'KW', 'LY', 'OM', 'QA', 'SD', 'SY']
// prettier-ignore
const regionSun = [
  'AG', 'AR', 'AS', 'AU', 'BD', 'BR', 'BS', 'BT', 'BW', 'BZ', 'CA', 'CN',
  'CO', 'DM', 'DO', 'ET', 'GT', 'GU', 'HK', 'HN', 'ID', 'IL', 'IN', 'JM',
  'JP', 'KE', 'KH', 'KR', 'LA', 'MH', 'MM', 'MO', 'MT', 'MX', 'MZ', 'NI',
  'NP', 'PA', 'PE', 'PH', 'PK', 'PR', 'PT', 'PY', 'SA', 'SG', 'SV', 'TH',
  'TT', 'TW', 'UM', 'US', 'VE', 'VI', 'WS', 'YE', 'ZA', 'ZW',
]
const languageSat = ['ar', 'arq', 'arz', 'fa']
// prettier-ignore
const languageSun = [
  'am', 'as', 'bn', 'dz', 'en', 'gn', 'gu', 'he', 'hi', 'id', 'ja', 'jv',
  'km', 'kn', 'ko', 'lo', 'mh', 'ml', 'mr', 'mt', 'my', 'ne', 'om', 'or',
  'pa', 'ps', 'sd', 'sm', 'sn', 'su', 'ta', 'te', 'th', 'tn', 'ur', 'zh', 'zu',
]

const weekStart = (region: string | undefined, language: string): string => {
  if (region) {
    if (regionSun.includes(region)) return 'SUNDAY'
    return regionSat.includes(region) ? 'SATURDAY' : 'MONDAY'
  }

  if (languageSun.includes(language)) return 'SUNDAY'
  return languageSat.includes(language) ? 'SATURDAY' : 'MONDAY'
}

export const weekStartLocale = (locale: string): string => {
  const parts = locale.match(
    /^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i,
  )
  if (!parts || !parts[1] || !parts[4]) return 'MONDAY'
  return weekStart(parts[4], parts[1])
}
