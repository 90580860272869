import type { MessageMistake, ReadabilityIssue } from '@zendesk/zqa-services/autoqa'
import { escapeHTMLEntities } from '@/utils/sanitize'

import getMistakeClass from './getMistakeClass'
import splitMessage from './splitMessage'

interface Params {
  rawInput: string
  mistakes: MessageMistake[] | ReadabilityIssue[]
  plaintextSource?: boolean
}

// Overlapping mistakes are not possible. See https://klausapp.slack.com/archives/C04NTD4DG4B/p1679480036072109
export default ({ rawInput, mistakes, plaintextSource }: Params) => {
  if (!mistakes.length) return plaintextSource ? escapeHTMLEntities(rawInput) : rawInput

  const splittedInput = splitMessage(
    rawInput,
    mistakes.map((mistake) => ({ startIdx: Number(mistake.htmlStartIdx), endIdx: Number(mistake.htmlEndIdx) })),
  )

  return splittedInput
    .map(({ s, isMistake, mistakeId }) => {
      if (isMistake) {
        const tagStart = `<span class="${getMistakeClass(mistakes[mistakeId])}" data-mistake-id="${mistakeId}">`
        return `${tagStart}${s}</span>`
      } else return plaintextSource ? escapeHTMLEntities(s) : s
    })
    .join('')
}
