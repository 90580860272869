import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-gray-60 mt-6 text-xs font-medium" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_more_vertical_icon = _resolveComponent("more-vertical-icon")!
  const _component_workspace_name = _resolveComponent("workspace-name")!
  const _component_chevron_up_icon = _resolveComponent("chevron-up-icon")!
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_scroll_shadow = _resolveComponent("scroll-shadow")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_q_modal = _resolveComponent("q-modal")!

  return (_openBlock(), _createBlock(_component_q_modal, {
    title: _ctx.$t('dashboard.scores_by_category.settings_modal.title'),
    show: _ctx.visible,
    "close-button": "",
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createVNode(_component_q_select, {
          modelValue: _ctx.selectedGroupBy,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGroupBy) = $event)),
          options: _ctx.groupingOptions,
          class: "groupingOption",
          theme: "dark",
          label: "label",
          "value-prop": "key",
          object: "",
          searchable: false,
          "field-label": _ctx.$t('dashboard.scores_by_category.settings_modal.group_by_label')
        }, null, 8 /* PROPS */, ["modelValue", "options", "field-label"]),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('dashboard.scores_by_category.settings_modal.title')), 1 /* TEXT */),
        _createVNode(_component_scroll_shadow, {
          class: _normalizeClass(_ctx.$style.scroll),
          "redraw-when": _ctx.data,
          style: {"--shadow-size":"32px","--shadow-start-color":"var(--gray-20)","--shadow-end-color":"rgba(237, 242, 247, 0)","--border-radius":"8px"}
        }, {
          default: _withCtx(() => [
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("ul", {
                  key: 0,
                  class: _normalizeClass(["text-sm font-medium", _ctx.$style.options])
                }, [
                  _createElementVNode("li", {
                    class: _normalizeClass(_ctx.$style.categoryContainer)
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.category)
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.placeholder)
                      }, null, 2 /* CLASS */),
                      _createVNode(_component_ui_checkbox, {
                        checked: _ctx.allSelected,
                        label: _ctx.$t('dashboard.scores_by_category.settings_modal.select_all'),
                        onChange: _ctx.onToggleAll,
                        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                      }, null, 8 /* PROPS */, ["checked", "label", "onChange"])
                    ], 2 /* CLASS */)
                  ], 2 /* CLASS */),
                  _createVNode(_component_draggable, {
                    list: _ctx.dataMap,
                    "item-key": "id"
                  }, {
                    item: _withCtx(({ element: row }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.$style.categoryContainer),
                        onClick: ($event: any) => (row.expanded = !row.expanded)
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.$style.category)
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style.dragHandle)
                          }, [
                            _createVNode(_component_more_vertical_icon, { class: "icon-m" })
                          ], 2 /* CLASS */),
                          _createVNode(_component_ui_checkbox, {
                            checked: _ctx.isRowSelected(row),
                            label: _ctx.data.rows[row.id].name,
                            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                            onChange: ($event: any) => (_ctx.onToggleRow(row))
                          }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                            (!row.rows.length)
                              ? {
                                  name: "secondary",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_workspace_name, {
                                      "workspace-id": +_ctx.data.rows[row.id].category.workspaceId
                                    }, null, 8 /* PROPS */, ["workspace-id"])
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "label", "onChange"]),
                          (row.rows.length)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(["ml-auto", _ctx.$style.categoryChevron])
                              }, [
                                (row.expanded)
                                  ? (_openBlock(), _createBlock(_component_chevron_up_icon, {
                                      key: 0,
                                      class: "icon-m"
                                    }))
                                  : (_openBlock(), _createBlock(_component_chevron_down_icon, {
                                      key: 1,
                                      class: "icon-m"
                                    }))
                              ], 2 /* CLASS */))
                            : _createCommentVNode("v-if", true)
                        ], 2 /* CLASS */),
                        (row.expanded && row.rows && row.rows.length)
                          ? (_openBlock(), _createElementBlock("ul", {
                              key: 0,
                              class: _normalizeClass(_ctx.$style.categorySlices),
                              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                            }, [
                              _createVNode(_component_draggable, {
                                list: row.rows,
                                "item-key": "id"
                              }, {
                                item: _withCtx(({ element: subRow }) => [
                                  _createElementVNode("li", {
                                    class: _normalizeClass(_ctx.$style.categorySlice)
                                  }, [
                                    _createVNode(_component_ui_checkbox, {
                                      checked: _ctx.isSubRowSelected(subRow, row),
                                      label: _ctx.data.rows[row.id].rows[subRow.id].name,
                                      onChange: ($event: any) => (_ctx.onToggleSubRow(subRow, row))
                                    }, {
                                      secondary: _withCtx(() => [
                                        _createVNode(_component_workspace_name, {
                                          "workspace-id": +_ctx.data.rows[row.id].rows[subRow.id].category.workspaceId
                                        }, null, 8 /* PROPS */, ["workspace-id"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "label", "onChange"])
                                  ], 2 /* CLASS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["list"])
                            ], 2 /* CLASS */))
                          : _createCommentVNode("v-if", true)
                      ], 10 /* CLASS, PROPS */, _hoisted_2)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["list"])
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "redraw-when"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.buttons)
        }, [
          _createVNode(_component_ui_button, {
            secondary: "",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeModal()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('universal.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ui_button, { onClick: _ctx.onSave }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('universal.save')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "show", "onClose"]))
}