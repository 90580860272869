import { nextTick, onMounted, ref, watch } from 'vue'
import imagePlaceholder from '@/modules/shared/TicketContent/utils/imagePlaceholder'
import { getImageDownloadUrl } from '@/api/image-download-url'
import config from '@/config'
import { redirectedImagesStore } from '@/composables/useRedirectedImageSource'

export const useBaseApiForImages = (watchFn?: () => any) => {
  const imageContainerRef = ref<HTMLPreElement | null>()

  const swapImageSources = async () => {
    await nextTick()

    if (!imageContainerRef.value) return

    const links = imageContainerRef.value.querySelectorAll('a')

    links.forEach(async (link) => {
      const image = link.querySelector('img') as HTMLImageElement

      if (!image?.src || !image.src.includes(`uploads.${config.domainRoot}`)) return

      const fileName = image.src.split('/').pop()

      if (!redirectedImagesStore[fileName]) {
        image.src = imagePlaceholder
        image.classList.add('cursor-wait')

        const { downloadUrl } = await getImageDownloadUrl(fileName)
        const response = await fetch(downloadUrl)
        image.onload = () => image.classList.remove('cursor-wait')

        redirectedImagesStore[fileName] = URL.createObjectURL(await response.blob())
      }

      image.src = redirectedImagesStore[fileName]
      link.href = redirectedImagesStore[fileName]
    })
  }

  // For editing
  if (watchFn) watch(watchFn, swapImageSources)
  onMounted(swapImageSources)

  return { imageContainerRef }
}
