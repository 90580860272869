import { weekStartsOnMonday } from '@/utils/date'

export const dragAttributes: any = {
  highlight: {
    animated: false,
    start: {
      style: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    end: {
      style: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
}

export const attributes = [
  {
    key: 'today',
    highlight: {
      style: { backgroundColor: '#CEE2F2' },
    },
    dates: new Date(),
  },
]

export const attributesV2 = [dragAttributes, ...attributes]

export const getSelectAttributes = (isSingleDaySelected: boolean) => {
  return {
    highlight: {
      animated: false,
      start: {
        style: isSingleDaySelected && {
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        },
      },
      end: {
        style: {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
        },
      },
    },
  } as any
}

export const firstDayOfWeek = weekStartsOnMonday() ? (2 as const) : (1 as const)
