import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["q-card-slider", _ctx.wrapperStyles])
  }, [
    _createVNode(_Transition, {
      name: _ctx.fade ? 'card-fade' : 'card-slider',
      mode: "out-in",
      onBeforeLeave: _ctx.beforeLeave,
      onEnter: _ctx.enter,
      onAfterEnter: _ctx.afterEnter
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["name", "onBeforeLeave", "onEnter", "onAfterEnter"])
  ], 2 /* CLASS */))
}