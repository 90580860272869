import type { RouteRecordRaw } from 'vue-router'

import { hasAccountRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'

const routes: RouteRecordRaw[] = [
  {
    path: '/quizzes',
    name: 'quizzes',
    component: () => import('./QuizzesIndexView.vue'),
    meta: {
      requiresPayment: true,
      requiredFlag: 'quizzes',
      includeInCommandPalette: true,
      requiresSufficientConversations: true,
    },
    props: (route) => ({ agentId: route.query.agentId }),
  },
  {
    path: '/quizzes/who-what-where-why',
    name: 'quizzes.error',
    component: () => import('./ErrorView.vue'),
    meta: { requiresPayment: true, requiredFlag: 'quizzes', requiresSufficientConversations: true },
    props: (route) => ({ noAccess: !!route.params.noAccess }),
  },
  {
    path: '/quizzes/:id/answer',
    component: () => import('./AnswerView.vue'),
    meta: { requiresPayment: true, requiredFlag: 'quizzes', requiresSufficientConversations: true },
    props: (route) => ({ ...route.params }),
    children: [
      {
        path: 'response',
        name: 'quizzes.answer.response',
        component: () => import('./components/Answer/AnswerResponse.vue'),
      },
      {
        path: '',
        name: 'quizzes.answer',
        component: () => import('./components/Answer/AnswerForm.vue'),
      },
    ],
  },
  {
    path: '/quizzes/:id?',
    name: 'quizzes.edit',
    component: () => import('./QuizView.vue'),
    meta: { requiresPayment: true, requiredFlag: 'quizzes', requiresSufficientConversations: true },
    beforeEnter(_to, _from, next) {
      if (hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER', 'LEAD')) return next()
      next({ name: 'quizzes' })
    },
    redirect(to) {
      if (!to.params.id) return { name: 'quizzes' }

      return hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER', 'LEAD')
        ? { name: 'quizzes.edit.preview', params: to.params }
        : { name: 'quizzes.answer', params: to.params }
    },
    props: (route) => ({ ...route.params, template: route.query.template }),
    children: [
      {
        path: 'questions',
        name: 'quizzes.edit.questions',
        component: () => import('./QuestionsView.vue'),
      },
      {
        path: 'preview',
        name: 'quizzes.edit.preview',
        component: () => import('./PreviewView.vue'),
      },
      {
        path: 'responses',
        name: 'quizzes.edit.responses',
        component: () => import('./ResponsesView.vue'),
        props: true,
        redirect: (to) => ({ name: 'quizzes.edit.responses.overview', params: to.params }),
        children: [
          {
            path: 'overview',
            name: 'quizzes.edit.responses.overview',
            component: () => import('./components/Responses/ResponsesOverview.vue'),
          },
          {
            path: 'leaderboard',
            name: 'quizzes.edit.responses.leaderboard',
            component: () => import('./components/Responses/ResponsesLeaderboard.vue'),
            props: (route) => {
              const params = route.params
              if (route.query.responseId) params.responseId = route.query.responseId.toString()
              return params
            },
          },
          {
            path: 'individual',
            name: 'quizzes.edit.responses.individual',
            component: () => import('./components/Responses/ResponsesIndividual.vue'),
            props: (route) => {
              const params = route.params
              if (route.query.responseId) params.responseId = route.query.responseId.toString()
              return params
            },
          },
        ],
      },
    ],
  },
]

export default routes
