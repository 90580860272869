import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "csat-donut-tooltip" }
const _hoisted_2 = { class: "text-gray-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(_ctx.$style.root)
  }, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({ '--change-color': _ctx.getCsatChangeColor(_ctx.data.csatChange) }),
          class: _normalizeClass(["text-3xl font-semibold", _ctx.$style.centerText])
        }, [
          (_ctx.data.csat !== null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ 'font-size': _ctx.radius / 3 + 'px' }),
                class: "font-semibold"
              }, _toDisplayString(_ctx.data.csat) + "% ", 5 /* TEXT, STYLE */))
            : _createCommentVNode("v-if", true),
          (_ctx.data.csatChange !== null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["text-xs font-semibold", _ctx.$style.changeRow])
              }, _toDisplayString(_ctx.data.csatChange > 0 ? '+' : '') + _toDisplayString(_ctx.data.csatChange) + "% ", 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
        ], 6 /* CLASS, STYLE */))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_ctx.hoveredIdx || _ctx.hoveredIdx === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["text-xs font-semibold", _ctx.$style.tooltip])
          }, [
            _createTextVNode(_toDisplayString(_ctx.data.csatValues[_ctx.hoveredIdx].label) + " ", 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('csat_dashboard.n_of_total', { n: Number(_ctx.data.csatValues[_ctx.hoveredIdx].count), total: _ctx.data.totalCount })), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.csatValues[_ctx.hoveredIdx].value) + "% (" + _toDisplayString(_ctx.data.csatValues[_ctx.hoveredIdx].change === null
              ? _ctx.$t('csat_dashboard.csat_value_na')
              : _ctx.data.csatValues[_ctx.hoveredIdx].change > 0
                ? `+${_ctx.data.csatValues[_ctx.hoveredIdx].change}%`
                : `${_ctx.data.csatValues[_ctx.hoveredIdx].change}%`) + ") ", 1 /* TEXT */)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.popper]
    ])
  ], 2 /* CLASS */))
}