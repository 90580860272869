import { createSharedComposable } from '@vueuse/core'

import {
  useTicketContent,
  type TicketInsightExtended,
} from '@/modules/shared/TicketContent/composables/useTicketContent'
import { postInsightFeedback, updateInsightFeedback } from '../api'

const useTicketInsightFeedbackFunction = () => {
  const { state } = useTicketContent()

  const saveChanges = async (
    insight: TicketInsightExtended,
    externalId: string,
    connectionId: number,
    feedbackToggle: boolean,
    feedbackComment: string,
    customSpotlightId?: string,
  ) => {
    await updateFeedbackChanges(insight, externalId, connectionId, feedbackToggle, feedbackComment, customSpotlightId).then((response) => {
      if (state.ticket.autoInsights && response)
        state.ticket.autoInsights.find((i) => {
          if (i.type === response.type) {
            const keys = Object.keys(i)
            keys.forEach((key) => {
              i[key] = response[key]
            })
          }
        })
    })
  }

  const postFeedbackChanges = async (
    insight: TicketInsightExtended,
    externalId: string,
    connectionId: number,
    feedbackToggle: boolean,
    feedbackComment: string,
    customSpotlightId?: string
  ) => {
    const response = await postInsightFeedback(connectionId, externalId, insight.type, feedbackComment, feedbackToggle, customSpotlightId)
    return response
  }

  const updateFeedbackChanges = async (
    insight: TicketInsightExtended,
    externalId: string,
    connectionId: number,
    feedbackToggle: boolean,
    feedbackComment: string,
    customSpotlightId?: string,
  ) => {
    let response: any = {}
    if (insight.rejected && insight.explanation) feedbackComment = insight.explanation
    if (!feedbackToggle) feedbackComment = ''
    if (insight.rejected === null)
      response = await postFeedbackChanges(insight, externalId, connectionId, feedbackToggle, feedbackComment, customSpotlightId)
    else response = await updateInsightFeedback(connectionId, externalId, insight.type, feedbackComment, feedbackToggle, customSpotlightId)

    return response
  }

  return { state, saveChanges }
}

export const useTicketInsightFeedback = createSharedComposable(useTicketInsightFeedbackFunction)
