import { computed, ref } from 'vue'
import type { User } from '@/composables/useReviewSidebar'
import { removeDiacritics } from '@/utils/diacritics'
import type { WorkspaceUser } from '@/modules/user-management/types'
import useDispute from '../../TicketContent/composables/useDispute'

const userIdMapper = (u: WorkspaceUser) => ({ ...u, id: Number(u.id) })

export default function useDisputeUsers() {
  const { state: disputeState } = useDispute()

  const userQuery = ref('')

  const userList = computed(() => {
    if (!requireUser.value || disputeState.action === 'APPROVE') return disputeState.users.map(userIdMapper) as User[]
    return disputeState.assignees.map(userIdMapper) as User[]
  })

  const userOptions = computed(() => {
    let users = userList.value

    if (userQuery.value) {
      users = users.filter((u) =>
        removeDiacritics(u.name).toLocaleLowerCase().includes(userQuery.value.toLowerCase()),
      ) as User[]
    }

    return users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).slice(0, 150)
  })

  const remaining = computed(() => userList.value.length - userOptions.value.length)

  const requireUser = computed(() => {
    if (!disputeState.action) return true
    if (disputeState.action === 'REJECT') return false
    if (disputeState.dispute?.type === 'REVIEWEE' && disputeState.action === 'APPROVE') return true

    return disputeState.action === 'EDIT'
  })

  return {
    userList,
    userOptions,
    remaining,
    requireUser,
    userQuery,
  }
}
