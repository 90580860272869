import { UiDropdownTypes } from '@klausapp/ui-kit'

import type { DashboardFilterParams } from '@/modules/dashboard/types'
import WorkspacePicker from '../WorkspacePickerV2.vue'
import CalibrationSessionPicker from '../CalibrationSessionPicker.vue'
import type { FilterBarFilter } from './types'
import { toSortedFilters } from './util'
import { filterKey } from './keys'

const filters: FilterBarFilter[] = [
  {
    component: WorkspacePicker,
    key: filterKey.workspacePicker,
    mode: UiDropdownTypes.Modes.Single,
    roles: ['MANAGER', 'LEAD', 'REVIEWER'],
    withSettings: { calibrationEnabled: true },
    updateStateOnChanges: (filters): DashboardFilterParams => ({
      ...filters,
      calibrationSessionId: undefined,
    }),
  },
  {
    component: CalibrationSessionPicker,
    key: filterKey.calibrationSessionPicker,
    mode: UiDropdownTypes.Modes.Single,
  },
]

export default toSortedFilters(filters)
