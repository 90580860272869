import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { getFirstParam } from '@/utils/routes'

const pathProps = {
  connectionId: 'connectionId',
  conversationId: 'conversationId',
  messageId: 'messageId',
  transcriptionId: 'transcriptionId',
  transcriptionMessageId: 'transcriptionMessageId',
  disputeId: 'disputeId',
  assignmentId: 'assignmentId',
  calibrationSessionId: 'calibrationSessionId',
} as const

export const TICKET_PATH = `:${pathProps.connectionId}?/:${pathProps.conversationId}?`
export const MESSAGE_PATH = `messages/:${pathProps.messageId}?/:${pathProps.transcriptionId}?/:${pathProps.transcriptionMessageId}?`

export default createSharedComposable(() => {
  const route = useRoute()

  const connectionId = computed(() => {
    const param = route.params[pathProps.connectionId]
    return param ? Number(param) : 0
  })

  const conversationId = computed(() =>
    route.params[pathProps.conversationId]
      ? decodeURIComponent(getFirstParam(route.params[pathProps.conversationId]))
      : '',
  )

  const messageId = computed(() =>
    route.params[pathProps.messageId] ? decodeURIComponent(getFirstParam(route.params[pathProps.messageId])) : '',
  )

  const transcriptionId = computed(() =>
    route.params[pathProps.transcriptionId]
      ? decodeURIComponent(getFirstParam(route.params[pathProps.transcriptionId]))
      : '',
  )

  const transcriptionMessageId = computed(() =>
    route.params[pathProps.transcriptionMessageId]
      ? decodeURIComponent(getFirstParam(route.params[pathProps.transcriptionMessageId]))
      : '',
  )

  const disputeId = computed(() =>
    route.params[pathProps.disputeId] ? decodeURIComponent(getFirstParam(route.params[pathProps.disputeId])) : '',
  )

  const assignmentId = computed(() =>
    route.params[pathProps.assignmentId] ? decodeURIComponent(getFirstParam(route.params[pathProps.assignmentId])) : '',
  )

  const calibrationSessionId = computed(() =>
    route.params[pathProps.calibrationSessionId]
      ? decodeURIComponent(getFirstParam(route.params[pathProps.calibrationSessionId]))
      : '',
  )

  return {
    connectionId,
    conversationId,
    messageId,
    transcriptionId,
    transcriptionMessageId,
    disputeId,
    assignmentId,
    calibrationSessionId,
  }
})
