import { scaleOrdinal } from 'd3-scale'
import { TicketDistributionCategory } from '@/modules/conversations/types'
import { sortMap } from '@/modules/conversations/components/visualFilters/sortSubCategories'

export default {
  [TicketDistributionCategory.Connection]: {
    color: 'brand',
    scale: scaleOrdinal(['60', '50', '40', '30', '20', '10']),
  },
  [TicketDistributionCategory.Language]: {
    color: 'purple',
    scale: scaleOrdinal(['60', '50', '40', '30', '20', '10']),
  },
  [TicketDistributionCategory.Channel]: {
    color: 'orange',
    scale: scaleOrdinal(['60', '50', '40', '30', '20', '10']),
  },
  [TicketDistributionCategory.Csat]: {
    color: 'teal',
    scale: scaleOrdinal(
      sortMap[TicketDistributionCategory.Csat].filter((c) => !['1', 'bad', 'rated_bad'].includes(c)),
      ['60', '50', '40', '30', '20', '10'],
    ),
    forcedSliceColors: {
      '1': 'red-70',
      bad: 'red-70',
      rated_bad: 'red-70',
    },
  },
  [TicketDistributionCategory.Complexity]: {
    color: 'green',
    scale: scaleOrdinal(['60', '50', '40', '30', '20', '10']),
    forcedSliceColors: {
      false: 'gray-50',
    },
  },
  [TicketDistributionCategory.Sentiment]: {
    color: 'yellow',
    scale: scaleOrdinal(
      sortMap[TicketDistributionCategory.Sentiment].filter((c) => !['NEGATIVE', 'POSITIVE'].includes(c)),
      ['40'],
    ),
    forcedSliceColors: {
      POSITIVE: 'green-60',
      NEGATIVE: 'red-60',
    },
  },
  [TicketDistributionCategory.Reviewed]: {
    color: 'blue',
    scale: scaleOrdinal(['30', '20', '10']),
    forcedSliceColors: {
      false: 'red-50',
    },
  },
}
