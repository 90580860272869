<template>
  <div class="flex w-full items-center gap-6">
    <div class="flex gap-2">
      <slot name="left-triggers" />
    </div>

    <div class="flex w-full min-w-[0] items-center gap-1">
      <slot />
    </div>

    <div class="ml-auto flex items-center gap-6">
      <slot name="right-triggers" />
    </div>
  </div>
</template>
