export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw Zendesk-subdomein op"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden bij Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bedrijf heeft een bestaande account."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid worden van bedrijf"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u lid wilt worden van een bestaand bedrijf, vraagt u uw admin om u een uitnodiging te sturen."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag uw admin u uit te nodigen"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben van gedachten veranderd."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie annuleren"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bedrijf heeft een bestaande account. Wilt u zich aanmelden bij de account van uw bedrijf of een nieuwe account maken?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructies kopiëren"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe account maken"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een bestaande account"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen toegang tot uw Zendesk QA-account tot uw openstaande abonnementsfacturen zijn betaald."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw abonnement is gepauzeerd"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account niet gevonden. Probeer het opnieuw."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vraag een nieuw wachtwoord aan."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Probeer het opnieuw of ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onjuiste combinatie van e-mailadres en wachtwoord."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag een nieuwe aan bij uw admin."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze uitnodigingslink is ongeldig of is al gebruikt."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aanmeldingsmethode ", _interpolate(_named("method")), " is gedeactiveerd in uw account."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze uitnodiging is ongeldig of in gebruik. Vraag een nieuwe aan bij uw admin."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan uitnodigingsinfo niet laden."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren mislukt. Gebruiker met dit e-mailadres bestaat mogelijk al."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account is geblokkeerd na meerdere opeenvolgende aanmeldingspogingen. Controleer uw Inbox op een e-mail met instructies om de blokkering op te heffen."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifieer eerst uw e-mailadres"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldige e-mailaccount op"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is vereist"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is vereist"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingscode"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De magische link verzenden"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakelijk e-mailadres"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw zakelijke e-mailadres"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.nl/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofddienstverleningsovereenkomst"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord komt niet overeen met de vereisten"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacyverklaring"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.nl/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wacht even"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("invitee_name")), ","])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Word lid van de organisatie ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres is geverifieerd. U kunt zich nu hieronder aanmelden met uw nieuwe account."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige uitnodigingslink"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met magische link"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar aanmelden"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres is geverifieerd. U kunt zich nu hieronder aanmelden met uw nieuwe account:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via magische link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden bij Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account is verwijderd"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u geen account?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige code in"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is vereist"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige e-mailaccount in"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is vereist"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingscode"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aanmeldingsopties bekijken"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " via traditionele methoden"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met magische link"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt een e-mail met uw aanmeldingscode ontvangen."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt een e-mail ontvangen die u kunt volgen om u aan te melden."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het verzenden van de magische link. Probeer het opnieuw."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden via e-mail"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord maken"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén kleine letter"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén hoofdletter"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord moet bevatten:"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale wachtwoordlengte is 128 tekens"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemakkelijk te onthouden, moeilijk te raden"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal 8 tekens"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak uw wachtwoord"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén kleine letter"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén cijfer"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén hoofdletter"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u uw wachtwoord opnieuw wilt instellen, geeft u uw e-mailadres op. Wij sturen u dan een e-mail met instructies."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldige e-mailaccount op"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is vereist"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er is een e-mail verzonden naar ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als het e-mailbericht niet in uw inbox of map met ongewenste berichten verschijnt, controleer dan het e-mailadres dat u hebt opgegeven."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar aanmelden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw e-mail"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", ontvangt u een e-mail om uw wachtwoord opnieuw in te stellen."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als uw account een bestaand wachtwoord heeft"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als dat ", _interpolate(_named("bold")), ", dan hebben wij u een link gestuurd om uw wachtwoord opnieuw in te stellen."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mailadres is gekoppeld aan een account"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u uw wachtwoord nog?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het verzenden van de link voor opnieuw instellen. Probeer het opnieuw."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord opnieuw instellen"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met magische link"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["het Privacybeleid"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Door u te registreren, gaat u akkoord met ", _interpolate(_named("terms_of_service")), " en ", _interpolate(_named("privacy_policy")), " van Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Servicevoorwaarden"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u een account?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren via magische link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren met zakelijk e-mailadres"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren met Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren voor Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren met Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb een actieve Zendesk-account"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Registreer u of meld u aan om uw configuratie voor ", _interpolate(_named("direct_install_name")), " te voltooien"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent u het echt? Controleer uw Inbox op een bevestigingsmail."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent klaar"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA werkt het best op een desktop. Meld u daar aan om gesprekken te beoordelen."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een demo boeken"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of boek een demo en we laten u zien wat u kunt doen in Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw aanwezigheid wordt verzocht"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuleren"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ik ben van gedachten veranderd en wil ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " nodigt u uit om lid te worden van diens account."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid worden van de account"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe account maken"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer u opnieuw aan te melden"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De reactie die u op uw feedback hebt ontvangen: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen reacties"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " heeft gereageerd op uw opmerking"]), _normalize(["Meerdere personen hebben gereageerd op uw opmerking"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " heeft gereageerd op uw reactie"]), _normalize(["Meerdere personen hebben gereageerd op uw reactie"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " heeft gereageerd op uw beoordeling"]), _normalize(["Meerdere personen hebben gereageerd op uw beoordeling"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opmerking"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reactie"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordeling"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESCHILLEN"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEGEVEN"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONTVANGEN"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtefeedback"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacties"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begonnen"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opmerking markeren als gelezen?"]), _normalize(["Alle opmerkingen markeren als gelezen?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Geschil markeren als gelezen?"]), _normalize(["Alle geschillen markeren als gelezen?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enquêtefeedback markeren als gelezen?"]), _normalize(["Alle enquêtefeedback markeren als gelezen?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beoordeling markeren als gelezen?"]), _normalize(["Alle beoordelingen markeren als gelezen?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opmerking gemarkeerd als gelezen"]), _normalize(["Alle opmerkingen gemarkeerd als gelezen"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Geschil gemarkeerd als gelezen"]), _normalize(["Alle geschillen gemarkeerd als gelezen"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enquêtefeedback gemarkeerd als gelezen"]), _normalize(["Alle enquêtefeedback gemarkeerd als gelezen"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beoordeling gemarkeerd als gelezen"]), _normalize(["Alle beoordelingen gemarkeerd als gelezen"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles markeren als gelezen"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als gelezen"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordelingen"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als iemand een beoordeling betwist, wordt dit hier weergegeven."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen geschillen"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u bent toegewezen om een geschil op te lossen, wordt dit hier weergegeven."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen geschillen"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een beoordeling betwist, wordt dit hier weergegeven."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen begonnen geschillen"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle feedback die aan u wordt gegeven, verschijnt hier. Nog niets te zien."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen feedback"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle reacties op uw beoordelingen en antwoorden worden hier weergegeven. Nog niets te zien."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen reacties"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open een gesprek"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle feedback die u geeft, komt hier terecht. Laat een beoordeling achter in de weergave Gesprekken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegeven beoordelingen"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle feedback die aan u wordt gegeven, verschijnt hier. Nog niets te zien."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen beoordelingen"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opmerkingen gevonden"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle feedback die u als opmerkingen achterlaat, wordt hier weergegeven."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegeven opmerkingen"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opmerkingen gevonden"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle opmerkingen onder een beoordeling waar u eigenaar van bent, ", "@", "-vermeldingen of reacties op uw opmerkingen verschijnen hier."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen opmerkingen"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen geaccepteerde geschillen"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen open geschillen"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen afgewezen geschillen"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opgeloste geschillen"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het opnieuw door een andere optie uit de bovenstaande filter te kiezen."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het opnieuw door een andere gebruiker uit de bovenstaande filter te kiezen."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven opmerkingen"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen opmerkingen"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geschillen"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen geschillen"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begonnen geschillen"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen enquêtefeedback"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven beoordelingen"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen beoordelingen"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteitenlijst in-/uitschakelen"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " beoordeeld"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " beoordeeld"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " beoordeeld"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start opnieuw binnen ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["nog ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een opdracht instellen"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachtenlijst in-/uitschakelen"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Het is niet mogelijk om een coachingsessie tussen ", _interpolate(_list(0)), " en ", _interpolate(_list(1)), " te maken, omdat ze zich momenteel niet in dezelfde workspace bevinden."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Coachingsessies tussen ", _interpolate(_list(0)), " en ", _interpolate(_list(1)), " bestaan al. Neem contact op met ", _interpolate(_list(0)), " om toegang te krijgen tot de sessie."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgesteld"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Er is een nieuwe sessie tussen ", _interpolate(_list(0)), " en ", _interpolate(_list(1)), " gemaakt."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer coach"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainee"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer trainee"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie maken"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een sessie maken"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie toevoegen"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessienaam"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgesteld"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterstallig"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorig"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomend"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie maken"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies gevonden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde beoordelingstijd"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens weer te geven voor de geselecteerde periode"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin toegevoegd aan sessie. ", _interpolate(_named("buttonStart")), "Laat het me zien", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegevoegd"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent aan het einde van de pinnen voor de geselecteerde periode"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zie in dit ", _interpolate(_named("link")), " hoe pinnen werken."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikel"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zo te zien hebt u niets vastgemaakt onder ", _interpolate(_list(0)), ". U kunt pinnen gebruiken om individuele sessies voor te bereiden of om iets op te slaan voor een andere keer."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de trainee"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen pinnen toegevoegd"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hier zijn alle pinnen die u hebt gemaakt onder ", _interpolate(_named("name")), ". Pinnen die aan sessies worden toegevoegd, worden ", _interpolate(_named("bold")), " met de andere deelnemer."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onmiddellijk gedeeld"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zo te zien heeft ", _interpolate(_list(0)), " tijdens de geselecteerde periode geen feedback ontvangen voor gespreksbeoordelingen of CSAT."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen feedback beschikbaar"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent aan het einde van de opmerkingen voor de geselecteerde periode"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nummers van ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw actie-item"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voltooid door ", _interpolate(_named("user")), " op ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["door ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["u"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ om een nieuw actie-item toe te voegen"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is er uit deze vergadering gebleken? Wat zijn uw volgende stappen?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie-items"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gemaakt door ", _interpolate(_named("name")), " op ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de sessie verwijderen"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiermee gaat u voorgoed ", _interpolate(_named("deleteSession")), ". Deelnemers verliezen de toegang tot de sessie, waaronder ook de inhoud en privéopmerkingen ervan."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sessie gemaakt op ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laatst bewerkt door ", _interpolate(_named("name")), " op ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekspunten en notities"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie bijgewerkt"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verlaten"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delen"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde gebruikers krijgen vanaf dit punt toegang tot sessiedetails."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruikers"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeeld"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie delen"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["U maakt geen deel meer uit van de sessie ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " maakt geen deel meer uit van deze sessie."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " heeft nu toegang tot deze sessie."]), _normalize([_interpolate(_named("n")), " nieuwe gebruikers hebben nu toegang tot deze sessie"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verwijderen"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sessies verwijderen"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verwijderen"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Notities toegevoegd door ", _interpolate(_list(0)), " verschijnen hier."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg vastgemaakte items toe als discussiepunten vanuit het rechterzijvenster"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notities die alleen u kunt zien..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke notities"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privénotitie bijgewerkt"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dagen"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainee"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie-items"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste sessie"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopend"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterstallig"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van sessies"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles bekijken"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik: Trainee"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sessies"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeelde sessies"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer een andere filter te gebruiken om actie-items weer te geven."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actie-items"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie-items"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen ontvangen en bekeken"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen · Gezien"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vooruitgang na sessies"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo te zien hebben de agenten geen feedback ontvangen."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer dat u de juiste naam voor de gebruiker hebt"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan die gebruiker niet vinden"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens beschikbaar"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK van de afgelopen 30 dagen"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiermee gaat u voorgoed <b>alle sessies verwijderen</b> (2) met <b>", _interpolate(_named("name")), "</b>. Deelnemers verliezen de toegang tot alle sessies, waaronder ook de inhoud en privéopmerkingen."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt het einde van alle sessies bereikt"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een coachingsessie is een groepsactiviteit tussen een mentor en een trainee om prestaties, kansen en doelen op de korte en lange termijn te bespreken."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt in sessies zoeken op zowel de naam van de coach als de persoon die wordt gecoacht."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies gevonden"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen sessies gemaakt"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachinggeschiedenis verwijderen"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachinggeschiedenis verwijderen "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste sessie"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle sessies"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainee"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als voltooid"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als niet voltooid"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op naam"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam wijzigen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessielijst in-/uitschakelen"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-gepland"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op dit bericht reageren"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlopen kalibratiesessie. Er kunnen geen aanvullende beoordelingen worden achtergelaten."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt al een beoordeling voor dit gesprek achtergelaten."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is niet meer mogelijk om dit gesprek te beoordelen omdat de vervaldatum van de sessie is verstreken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek heeft geen beoordelingen"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venster sluiten"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een kalibratiebeoordeling achterlaten"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAMENVATTING"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIPTIE"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Spreker ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s."])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doodse stilte"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreker bewerken"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht beoordelen"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar bericht dat wordt afgespeeld"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprekers"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriptie"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacegebruikers"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg eerst verbinding toe om filters te maken"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gemaakt om ", _interpolate(_named("time")), " door ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laatst bijgewerkt op ", _interpolate(_named("time")), " door ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zo te zien hebt u geen filters. U moet eerst een filter maken om toegang te krijgen tot deze pagina. Begin met ", _interpolate(_named("recommendedFilter")), " om uw eigen gesprekken te zien. Maak hieronder deze filter om door te gaan."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in de afgelopen 30 dagen"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De voorwaarde 'gemaakt in de afgelopen 30 dagen' wordt toegevoegd aan filters die zonder datumbereik zijn gemaakt"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter is niet beschikbaar in dit plan en er wordt geen rekening mee gehouden bij het weergeven van resultaten"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out bij ophalen van filteropties. Probeer het opnieuw."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over filteren"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker = aangemelde persoon"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Gesprekken gevonden: ", _interpolate(_named("count"))]), _normalize(["Gesprek gevonden: ", _interpolate(_named("count"))]), _normalize(["Gesprekken gevonden: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beschikbaar in uw huidige plan. Voer een upgrade uit om deze functie te gebruiken."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarde toevoegen"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door AI van Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarde"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzetten in opdracht"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter maken"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter maken"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste velden"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarden"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We laden de filteropties op basis van uw specifieke configuratie. Bij de eerste keer laden kan dit enkele minuten duren."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een beschrijvende naam aan uw filter"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filternaam"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een optie"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarde"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbaar"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter bewerken"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen waarden beschikbaar"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gevonden"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een waarde"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaarheid"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen actieve sessies ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere sessies weergeven ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratiesessie bewerken"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie maken"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratiesessie maken"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibratiebeoordelingen die tijdens de sessie worden gegeven, worden definitief verwijderd."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verwijderen"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibratiesessie ", _interpolate(_named("sessionName")), " verwijderen"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen sessies gemaakt"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actieve sessies"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies gemaakt"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actieve sessies"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter toevoegen"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een privéfilter maken"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een openbare filter maken"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter toegevoegd"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De filter wordt definitief verwijderd."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter verwijderen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter ", _interpolate(_named("filterName")), " verwijderen"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geschillen"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen voor mij"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen door mij"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open geschillen"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepten"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen filters toegevoegd"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen privéfilters gemaakt"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen openbare filters gemaakt"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privéfilters"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbare filters"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wilt u ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dit opslaan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is de weergave van een tijdelijke filter."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw filter wordt samengesteld..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter bijgewerkt"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste AutoQA-categorie"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laatst bijgewerkt ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complexe woorden"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische beoordelingen worden dynamisch bijgewerkt op basis van ticketwijzigingen"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren in gesprek"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange zinnen"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen automatische score voorspeld"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen fouten detecteren"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complexe woorden weergeven"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange zinnen weergeven"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgend gesprek"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA kon deze categorie niet waarderen vanwege onvoldoende context. Handmatige beoordeling aanbevolen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek automatische gespreksinzichten om uw beoordelingsproces te stroomlijnen. Breng cruciale gesprekken onder de aandacht en laat opvallend positieve voorbeelden zien aan uw team."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Oproep]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecorrigeerd"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " heeft het geschil geaccepteerd"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geschil verzonden naar ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg uit waarom u denkt dat deze beoordeelde de verkeerde ontvanger is voor deze feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een opmerking toe en stel een nieuwe beoordeelde in"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een opmerking toe"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg uit waarom u van mening bent dat dit onterechte scores zijn (verplicht)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een opmerking toe (verplicht)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg uit waarom u dit geschil afwijst"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een opmerking toe"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verzenden naar"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verzenden naar"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verzenden naar"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer nieuwe beoordeelde"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback over geschil"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback over geschil"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde wijzigen"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verzenden naar"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze beoordeling betwisten"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga niet akkoord met de scores"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze beoordeling is niet voor mij bedoeld"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een gebruiker"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " heeft het geschil gedeeltelijk geaccepteerd"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " heeft het geschil afgewezen"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerd"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeeltelijk geaccepteerd"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgewezen"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " is een geschil begonnen: beoordelen"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " is een geschil begonnen: verkeerde beoordeelde"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een open geschil moet worden opgelost om andere acties voor de beoordeling te kunnen ondernemen."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteren"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer nieuwe scores"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verwijderen"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u dit geschil ongedaan wilt maken?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil verwijderen"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossingstijd van geschil"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afwijzen"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer nieuwe eerlijke scores (optioneel)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling van geschil"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkeerde beoordeelde"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil accepteren"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil accepteren"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback over geschil"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkeerde beoordeelde"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback over geschil"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil afwijzen"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil afwijzen"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het geschil weergeven"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerkt"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " door ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["aan ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen aan:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat ook een opmerking achter"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over voorwaardelijke scorekaarten"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorheen toegewezen aan:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies er een"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen gif's vinden met die naam"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat als eerste een beoordeling achter."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw abonnement is momenteel gepauzeerd. Activeer het opnieuw om feedback geven in te schakelen."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepost door:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ziet u hoeveel tijd is besteed aan het beoordelen van dit gesprek"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een gebruiker"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hoofdoorzaak ontbreekt voor categorie: '", _interpolate(_list(0)), "'"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardeer een categorie of voeg een opmerking toe"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji toevoegen"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvouwen"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingenveld uitvouwen"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingenveld samenvouwen"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmaak verbergen"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venster verbergen"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmaak weergeven"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venster tonen"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling verzenden"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardeer alle categorieën"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeel minstens één categorie"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek opslaan voor later"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan voor later"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende opdracht"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek vervangen in deze opdrachtenscyclus"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken beoordelen"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basislijnbeoordeling"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking verwijderen"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking verwijderd"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze opmerking verwijderen?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur van opmerking"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze hashtag is verwijderd of heeft een nieuwe naam gekregen."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deel wat u goed vond of wat beter had gekund..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking geven"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaardelijk"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden alle opmerkingen definitief verwijderd."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking verwijderen"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Bericht]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " heeft deze feedback nog niet gezien"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " heeft deze feedback gezien"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voormalig lid"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek gif's..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op Enter om een nieuwe hashtag te maken."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste hashtags zijn vergrendeld door admins."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik dit om bladwijzers toe te voegen aan cases en later uit te filteren"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als basislijn"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback op berichten"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgend gesprek"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordelingen gedaan"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat hieronder een beoordeling achter om aan de slag te gaan."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen andere beoordelingen"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gewaardeerd"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige medewerker"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorig gesprek"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen als basislijn"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reageren"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reageer op deze beoordeling"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelen"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijbalk beoordelingen verbergen"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden de beoordeling en alle toegevoegde opmerkingen definitief verwijderd."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling verwijderen"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling verwijderen"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling verwijderd"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Beoordeling verwijderd door ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijs deze taak opnieuw toe aan uzelf om een beoordeling achter te laten"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt al een beoordeling voor deze gebruiker achtergelaten."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["U hebt een beoordeling achtergelaten voor ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen categorieën weer te geven voor dit gesprek."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen zijn niet beschikbaar voor deze workspace."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet opslaan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze beoordeling opslaan als concept?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit concept ongedaan maken"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongedaan maken"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijbalk beoordelingen weergeven"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geslaagd"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd met kleine fouten"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog bezig met de cijfers..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking achterlaten"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bericht beoordelen"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzelf beoordelen"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek beoordelen"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers om te beoordelen"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen uitgeschakeld"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker gevonden"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacegebruikers"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruiker"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 beoordeling"]), _normalize([_interpolate(_named("count")), " beoordelingen"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer nieuwe waarderingen"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw workspacemanager heeft zelfbeoordelingen niet toegestaan."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk op Enter om deze pin te taggen"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibraties"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgelopen 30 dagen"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ster"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een kalibratiesessie maken"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepten"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten geven u een visuele representatie van uw helpdeskgegevens. Gebruik de interactieve grafieken om te begrijpen waar u uw QA-inspanningen op moet richten, om blinde vlekken te vinden en om de agenten te vinden die u op basis van hun prestaties moet beoordelen."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), "-id"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), "-webadres"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vertalen naar ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-toegewezen"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momenteel bekeken door"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aan het beoordelen"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek is verwijderd en is niet meer beschikbaar."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het gesprek dat u zoekt, bestaat niet in Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle pinnen met dit gesprek"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In sessie"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een gesprek uit een sessie verwijdert, worden alle beoordelingen ervan verwijderd."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesprek verwijderen uit ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen kalibratiesessies gemaakt."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek is aan de sessie toegevoegd"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek is uit de sessie verwijderd"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan/verwijderen uit een kalibratiesessie"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link naar dit gesprek kopiëren"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprek weer te geven"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail van klant"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id van klant"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van klant"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam extern formulier"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd tot eerste reactie"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioriteit"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêteopmerking"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevredenheidsscore"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd tot eerste oplossing"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origineel"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bericht bestaat niet onder dit gesprek of het is verwijderd"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen onderwerp"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprek weer te geven"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprek gevonden"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oorspronkelijk gesprek openen"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet linken naar gesprek - webadres ontbreekt"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen deelnemers"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek vastmaken"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om beoordelingen te lezen"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om te beoordelen"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek gemaakt via browserextensie"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanttevredenheidsenquête weergeven"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek weergeven"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht met een negatief sentiment"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht met een positief sentiment"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertalen naar het Engels"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origineel tonen"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het verwijderen van een gesprek is definitief. Alle gerelateerde gegevens worden ook verwijderd, bijvoorbeeld enquêtes, transcripties en beoordelingen uit Zendesk QA, waaronder dashboards.", _interpolate(_named("lineBreak")), "U hebt nog steeds toegang tot dit gesprek in Zendesk Support of een andere helpdesksoftware die u gebruikt."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het verwijderen van een gesprek is definitief. Ook worden alle gerelateerde gegevens, bijvoorbeeld enquêtes, transcripties en beoordelingen, uit Zendesk QA verwijderd, waaronder dashboards. Als het gesprek tot meerdere workspaces behoort, wordt het uit alle workspaces verwijderd.", _interpolate(_named("lineBreak")), "U hebt nog steeds toegang tot dit gesprek in Zendesk Support of in andere helpdesk-software die u gebruikt."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen uit Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit gesprek is onvolledig vanwege escalatie. Ga naar ", _interpolate(_named("optionsUrl")), " en valideer uw tags. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit gesprek is onvolledig vanwege escalatie. Neem contact op met uw Administrator om uw tags te laten valideren. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen > Geavanceerde opties"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over tags"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In afwachting"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelost"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Medewerker gewijzigd in ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-toegewezen"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " toegepast door ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesprek heeft een waardering ", _interpolate(_named("target")), " ontvangen"])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruiker ", _interpolate(_named("target")), " heeft het gesprek verborgen"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status gewijzigd in ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Getagd met ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team gewijzigd in ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " heeft het gesprek op snooze gezet voor ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " heeft de snooze van het gesprek ongedaan gemaakt"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot zit vast in een lus en blijft een bericht herhalen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botherhaling"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botherhaling, waarbij hetzelfde bericht een of meerdere keren wordt herhaald"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenkomende zinnen gevonden"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een gemiddelde agent handelt het gesprek ", _interpolate(_named("percent")), " efficiënter af dan de bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiëntie van botcommunicatie"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot heeft het gesprek ", _interpolate(_named("percent")), " efficiënter afgehandeld dan een gemiddelde agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiëntie van botcommunicatie"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het gesprek ontbreekt de noodzakelijke verklaring om deelnemers te informeren over de opname van het gesprek, wat vereist is voor naleving en transparantie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatieverschaffing over opname ontbreekt"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Er is een SLA (Serviceniveauovereenkomst) geschonden voor ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA geschonden"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric voor de SLA-regel heeft de doeltijd overschreden"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekenen van verloop van klanten. Klant overwoog over te stappen of heeft beloofd weg te gaan."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risico op verloop geïdentificeerd"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gesprek heeft momenten van doodse stilte die langer duren dan de ingestelde drempel van ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instantie"]), _normalize([_interpolate(_named("count")), " instanties"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s langste"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doodse stilte"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant heeft assistentie van een hoger niveau gevraagd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalatie aangevraagd"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant uitte grote dankbaarheid of was zeer tevreden met de ontvangen support"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitzonderlijke service"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De klant of de agent heeft expliciet om een follow-up gevraagd"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvraag follow-up"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant was ontevreden"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief sentiment"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek was ongewoon of atypisch en het kostte wat meer heen en weer om tot een oplossing te komen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschieter gedetecteerd"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant was tevreden"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief sentiment"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van toepassing"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellen als niet van toepassing"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laatst bewerkt door ", _interpolate(_named("author")), " op ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet van toepassing"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Door ", _interpolate(_named("author")), " op ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een reden toe (optioneel)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen inzichten gedetecteerd"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding toevoegen"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel uw helpdesk aan uw account en zorg dat er minimaal één filter is gemaakt om de lijst met gesprekken weer te geven."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken weer te geven"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace-instellingen weergeven"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst bijgewerkt"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellen"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(In een dag)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Deze maand)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Deze week)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op id"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zoekresultaten voor ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld door anderen"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met opmerking"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwste"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudste"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willekeurig"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlangs bijgewerkt"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlangs geantwoord"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteren op"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongelezen"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door u beoordeeld"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het importproces kan enige tijd in beslag nemen. We sturen u een e-mail wanneer dit is voltooid."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA is uw gesprekken aan het importeren"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limiet: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe door op het kalibratiepictogram in de kop van de gespreksweergave te klikken."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier is niets te melden. Probeer het opnieuw met een andere filter- of sorteeroptie."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen uitschieters weer te geven."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind een relevant gesprek en klik op het sterretje in de kop om er hier een bladwijzer van te maken."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer de filters te wijzigen of nodig andere helpdeskagenten uit om meer gesprekken toe te voegen."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen limiet"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne opmerking"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er worden momenteel gesprekken geladen. Mogelijk ontbreekt er nog inhoud."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende gebruiker"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker gevonden"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen ontvangen"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden van workspace"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige gebruiker"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken toegevoegd"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen gesprekken toegevoegd"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onvoltooide beoordelingen komen hier terecht. Lees het opnieuw, pas het aan en dien het in de komende 30 dagen in."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen conceptbeoordelingen"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken met ster"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken weer te geven"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomst"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar geselecteerd bericht"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minste reacties"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeste reacties"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekslijst in-/uitschakelen"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oproep beantwoord om ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oproep geëindigd om ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oproep geplaatst om ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door u beoordeeld"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Laden van audiobestand mislukt. Zorg ervoor dat u bent aangemeld bij ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking op klanttevredenheidsenquête"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout tijdens laden van audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur van opmerking"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen oproep plaatsgevonden"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeluisteren in helpdesk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luisteren via Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afspeelsnelheid"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek beoordelen"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeem"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriptie"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoog"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laag"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaal"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker van Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangschikt agenten automatisch op basis van hun maandelijkse gemiddelde CSAT. Vind met één klik de gesprekken van agenten van wie de CSAT lager is dan die van hun collega's."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie op CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaal"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoog"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laag"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagste"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogste"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker van Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangschikt agenten automatisch in prestatiegroepen op basis van het aantal gesprekken dat ze per maand afhandelen. Vind met één klik gesprekken van agenten die een lager volume dan hun collega's hebben."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positie op volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beoordeeld"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsstatus"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laag"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoog"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutraal"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruiker van Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rangschikt agenten automatisch op basis van het gemiddelde sentiment in de gesprekken. Vind met één klik interacties waarbij agenten te maken hebben met meer gefrustreerde klanten dan hun collega's."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangschikking op sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gedetecteerd"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetecteerd"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschieters"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken die uw aandacht vereisen. Ons zelflerend systeem identificeert complexe of atypische interacties voor verdere beoordeling."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschieters"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gesprekken om te beoordelen waarin de klant blijk gaf van blijdschap of frustratie. Gebruik de vervolgkeuzelijst om het type sentiment te selecteren dat u in uw gesprekken zoekt. Sleep het filterkenmerk naar boven om de verhouding van het gekozen sentiment in al uw interacties te zien."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsstatus"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beoordeeld"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA detecteert automatisch talen in uw gesprekken. Gebruik de vervolgkeuzelijst om gesprekken in specifieke talen te selecteren ter beoordeling. Sleep het filterkenmerk naar boven om een overzicht te krijgen van alle talen die in uw helpdesk worden gebruikt."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksinzichten"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U kunt ", _interpolate(_named("paragraph_2_item")), " met Inzichten dan met gewone gespreksfilters. Krijg een visueel overzicht van alle gegevens van uw helpdesks en verken inzichten als:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over het gebruik van gespreksinzichten"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het relatieve belang begrijpen van specifieke gesprekskenmerken ten opzichte van andere"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw best en slechtst presterende agenten vinden met de ingebouwde rangschikking van agentprestaties"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " is een krachtige, interactieve tool voor datamining waarmee u de gesprekken kunt vinden die het meest moeten worden beoordeeld."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoveel meer doen"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De grafieken Gesprek en Agent zijn ", _interpolate(_named("paragraph_3_item_1")), " – als u filters bijwerkt of op de gesegmenteerde grafiekschijven klikt, wordt de gesprekslijst automatisch bijgewerkt. ", _interpolate(_named("paragraph_3_item_2")), " om gedetailleerde uitsplitsingen of verhoudingen van de verschillende elementen te bekijken."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onderling verbonden"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangschik de filterkenmerken opnieuw"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gespreksinzichten geven alleen ", _interpolate(_named("paragraph_4_item")), " weer. Interacties die niet veel waarde voor beoordelingen bevatten, zoals automatisch gegenereerde berichten, spam, botreacties of berichten zonder echte dialoog met uw supportmedewerker, verwijderen we automatisch."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schone en gesloten gesprekken"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zie ik hier?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " van ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " van ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " van ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " van ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophalen mislukt"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " n.v.t."])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenten"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " van totaal)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw laden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzichten"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maar we zijn er zeker van dat de score uitmuntend zal zijn."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste enquête"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen enquêtefeedback meer"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen enquêtefeedbackscore beschikbaar..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardering"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ontvangen"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedbacktag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags voor feedbackopmerkingen"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumbereikfilter toegepast"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " reactie"]), _normalize([_interpolate(_named("count")), " reacties"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Graaf dieper in de reden waarom een ", _interpolate(_list(0)), "-score is gegeven door onder enquête-instellingen redenen in te stellen."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nog geen ", _interpolate(_list(0)), "-redenen"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over enquêteredenen"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken met tags:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " op"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenen"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingencategorie"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), "-redenen"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voorspelde ", _interpolate(_named("surveyType")), "-drijfveren"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksbron"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktags"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Enquêteresponsen (", _interpolate(_named("n")), ")"]), _normalize(["Enquêterespons (", _interpolate(_named("n")), ")"]), _normalize(["Enquêteresponsen (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen onderwerp"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gesprekken met ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " op gebruikers"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responspercentage"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-score is de som van alle responsen gedeeld door de som van de maximumscores"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel bewerken"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel verwijderen"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een doel instellen"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel toevoegen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kort"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erg lang"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde lengte"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarrend"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet nuttig"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slechte support"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goede support"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoties in overvloed"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback voor agent"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klacht"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruimels"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem niet opgelost"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem opgelost"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief sentiment"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slechte afloop"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief sentiment"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lof"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slecht product"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugbetaling"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle support"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trage support"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA kent uw ", _interpolate(_list(0)), "-gegevens automatisch toe aan vooraf gedefinieerde categorieën. Zo kunt u complexe inzichten begrijpen en oorzaken onderzoeken zonder de schriftelijke feedback handmatig te moeten doorzoeken."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scores"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle enquêtes"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer taal van respons"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaat van opmerking"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer formaat van opmerking"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien 25% van de opmerkingen uit slechts één woord bestaat, dienen langere opmerkingen zeker te worden gelezen. Vind meer verhelderende feedback zonder het werk handmatig te moeten uitvoeren, want wij doen het automatisch voor u."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden van feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer reden voor feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomende scores"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorspelde drijfveren"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer opmerkingencategorie"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woorden van woordenwolk"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer woorden van woordenwolk"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sinds vorige periode"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gesprekken"]), _normalize([_interpolate(_named("n")), " gesprek"]), _normalize([_interpolate(_named("n")), " gesprekken"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekenen van ratio van enquête verzonden niet mogelijk vanwege een gebrek aan gegevens."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsen"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes verzonden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtetrechter"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " van ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " van ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " van ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voorspelde ", _interpolate(_named("surveyType")), "-drijfveren"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA kent uw ", _interpolate(_list(0)), "-gegevens automatisch toe aan vooraf gedefinieerde categorieën. Zo kunt u complexe inzichten begrijpen en oorzaken onderzoeken zonder de schriftelijke feedback handmatig te moeten doorzoeken."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaat van opmerking"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien 25% van de opmerkingen uit slechts één woord bestaat, dienen langere opmerkingen zeker te worden gelezen. Vind meer verhelderende feedback zonder het werk handmatig te moeten uitvoeren, want wij doen het automatisch voor u."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêteresponsen"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gemiddeld)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal responsen"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK (gemiddeld)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-trends op"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal responsen"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenen"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drijfveren"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Verzenddatum ", _interpolate(_list(0)), "-enquête"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reactiedatum ", _interpolate(_list(0)), "-enquête"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-score toewijzen aan"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens weergeven gebaseerd op"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum van gesprek"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigenaar van het ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agent met meeste reacties"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (gemiddeld)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " in de loop van de tijd"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal responsen"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " en SIK"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal responsen"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken waarin deze woorden voorkomen:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woordenwolk"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT op gebruikers"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-overzicht"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtetrechter"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêteresponsen"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktags"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT in de loop van de tijd"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woordenwolk"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kanaal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bron"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen taal"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen redenen"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tags"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opmerkingencategorie"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspace"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens beschikbaar omdat er geen opdrachten zijn gemaakt of omdat er geen cycli zijn gestart. Kom later terug of pas de filters aan."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek wat er nieuw is"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " item"]), _normalize([_interpolate(_named("count")), " items"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " gesprek"]), _normalize([_interpolate(_named("count")), " gesprekken"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geslaagd"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd met kleine fouten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd/Niet geslaagd"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde (u.)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde (min.)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Waarderingen voor ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde CSAT = [som van feedbackscores ÷ (totaalaantal responsen × max. mogelijke score)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De CSAT-score van Zendesk QA normaliseert uw feedbackscore binnen een schaal van 0-100% en neemt het gemiddelde van de gestandaardiseerde scores."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De CSAT (Customer Satisfaction Score, klanttevredenheidsscore) kwantificeert hoe tevreden uw klanten zijn met uw diensten."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Elke beoordeling staat voor de beoordeling door één persoon voor één gesprek. Een gesprek kan meer dan één beoordeling hebben (als twee of meer beoordelaars hetzelfde gesprek beoordelen)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volg de evolutie van de scores van de beoordelaar in de loop van de tijd."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volg de evolutie van de scores van agenten in de loop van de tijd."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " responsen"]), _normalize([_interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " responsen"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " beoordelingen"]), _normalize([_interpolate(_named("n")), " beoordeling"]), _normalize([_interpolate(_named("n")), " beoordelingen"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK (Score Interne Kwaliteit) is het resultaat van uw gespreksbeoordelingen."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK = som van beoordelingsscores ÷ aantal beoordelingen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wordt berekend als een gemiddelde, waarbij rekening wordt gehouden met alle beoordelingsscores (gewogen gemiddelde van de categoriescore) die overeenkomen met de geselecteerde filtervoorwaarden."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan kaart niet laden."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig uw filters om het opnieuw te proberen."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het slagingspercentage geeft de uitkomst van uw gespreksbeoordelingen aan."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niet geslaagd als de beoordelingsscore ", _interpolate(_named("failScore")), " of lager is"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geslaagd als de beoordelingsscore ", _interpolate(_named("passScore")), " of hoger is"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wordt bepaald door een vooraf gedefinieerde basislijn, wat betekent dat u:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slagingspercentage = som van geslaagde beoordelingen ÷ som van totale beoordelingen"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het slagingspercentage geeft een percentage aan van het aantal beoordelingen dat slaagt zonder fouten in kritieke of niet-kritieke categorieën."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een voorbeeld: als de beoordelaar 3 beoordelingen geeft op deze dag, is het weergegeven cijfer het gemiddelde van de 3 scores. Bekijk ", _interpolate(_named("link")), " hoe elke cel wordt berekend."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een voorbeeld: als de agent 3 beoordelingen ontvangt op deze dag ontvangt, is het weergegeven cijfer het gemiddelde van de 3 scores. Bekijk ", _interpolate(_named("link")), " hoe elke cel wordt berekend."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt geen rekening mee gehouden: categoriegewicht, kritieke status (in tegenstelling tot scores in de loop van de tijd)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien categorieën in beoordelingen als n.v.t. kunnen worden gemarkeerd, kan het aantal beoordelingen per categorie lager zijn dan het totale aantal beoordelingen door de beoordelaar. Zie hoe elke cel wordt berekend"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt geen rekening mee gehouden: categoriegewicht, kritieke status (in tegenstelling tot scores in de loop van de tijd)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangezien categorieën in beoordelingen als n.v.t. kunnen worden gemarkeerd, kan het aantal beoordelingen per categorie lager zijn dan het totale aantal beoordelingen per agent. Zie hoe elke cel wordt berekend"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt geen rekening mee gehouden: categoriegewicht, kritieke status (in tegenstelling tot scores in de loop van de tijd)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Categoriegroepen worden afzonderlijk weergegeven. De score voor elke groep wordt berekend op basis van de gemiddelde categoriescore voor die groep in dezelfde periode. Bekijk ", _interpolate(_named("link")), " hoe elke cel wordt berekend."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt geen rekening mee gehouden: categoriegewicht, kritieke status (in tegenstelling tot scores in de loop van de tijd)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Categoriegroepen worden afzonderlijk weergegeven. De score voor elke groep wordt berekend op basis van de gemiddelde categoriescore voor die groep in dezelfde periode. Bekijk ", _interpolate(_named("link")), " hoe elke cel wordt berekend."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Basislijn ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder kritieke fouten"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het percentage zonder kritieke fouten geeft een percentage aan van het aantal beoordelingen dat slaagt zonder fouten in kritieke categorieën."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Interne Kwaliteit"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Overige' hoofdoorzaken"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodieke wijziging"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijkse wijziging"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijkse wijziging"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijkse wijziging"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijkse wijziging"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores in de loop van de tijd"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef weer in dagen of weken."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekening = totale score voor dag of week gedeeld door totaalaantal gegeven beoordelingen"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef weer in dagen of weken."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekening = totale score voor dag of week gedeeld door totaalaantal ontvangen beoordelingen"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores op categorie"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk het gecombineerde gemiddelde van alle categoriewaarderingen die de beoordelaar in deze periode heeft gegeven."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk het gecombineerde gemiddelde van alle categoriewaarderingen die in deze periode per agent zijn ontvangen."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken voor lage waarderingen"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken voor waarderingen"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriescores in de loop van de tijd"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseer hoe categorieën zich in de loop van de tijd verhouden."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk de gemiddelde scores voor elke categorie voor een bepaalde dag of week."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseer hoe categorieën zich in de loop van de tijd verhouden."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk de gemiddelde scores ontvangen voor elke categorie voor een bepaalde dag of week."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores op beoordelingen"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritieke categorie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingsschaal"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde/totaal"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen uitsluiten"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteert alle kaarten"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["gesprek"]), _normalize(["gesprekken"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["item"]), _normalize(["items"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " responsen"]), _normalize([_interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " responsen"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " beoordelingen"]), _normalize([_interpolate(_named("n")), " beoordeling"]), _normalize([_interpolate(_named("n")), " beoordelingen"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nog een weergeven"]), _normalize(["Nog ", _interpolate(_named("n")), " weergeven"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgelopen 30 dagen"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgelopen 7 dagen"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige maand"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige week"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze maand"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze week"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste periode"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagen"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maanden"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwartalen"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weken"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan kaart niet laden. Wijzig uw filters om het opnieuw te proberen."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens weer te geven."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijkse wijziging"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw actieve filters bevatten gegevens van verschillende waarderingsschalen."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kaartpinnen met deze filters"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle dashboardpinnen met deze filters"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde beoordelingstijd"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv downloaden"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen categorieën"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie-inzichten"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafiek"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opmerking"]), _normalize(["Opmerkingen"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrix"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voormalig lid"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Interne Kwaliteit"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK (gemiddeld)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagde beoordelingen"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaart vastmaken"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard vastmaken"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingen"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hoofdoorzaak"]), _normalize(["Hoofdoorzaken"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id van beoordeling"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingstijd"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken instellen"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met hoofdoorzaken komt u te weten waarom gesprekken lage scores krijgen"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hoofdoorzaken ingesteld voor deze categorie"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score/totaal"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorieën"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen waarderingscategorieën"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoriegroep"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde aanpassen"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën groeperen op"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles selecteren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpassen"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen uitsluiten"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen opnemen"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel zelfbeoordelingen weergeven"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters wissen"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hashtags gevonden"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags voor opmerkingen"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer hashtags voor opmerkingen"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tags gevonden"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktags"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken in helpdesktags"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen scorekaarten gevonden"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer scorekaarten"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomende verbindingen"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksbron"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer bron"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer filters"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomende workspaces"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalaantal keer gegeven"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iedereen"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle groepen"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomende groepen."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle groepen"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaarten weergeven"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens weergeven gebaseerd op"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum beoordeling"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum van gesprek"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twee cijfers na de komma weergeven"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen weergeven"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergave"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspaces"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehele account"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van workspace"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspaces"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlaggen"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eten en drinken"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieren en natuur"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objecten"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensen en lichaam"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reizen en plaatsen"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgebruikt"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekresultaten"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys en emoticons"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbolen"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen emoji gevonden"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open een gesprek"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar takenlijst"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " of blader door uw lijst met ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U gebruikt momenteel de ", _interpolate(_named("workspace")), "-workspace die niet is verbonden met dit domein. Klik hier om deze nu te verbinden."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beschikbare verbindingen"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U kunt nog geen feedback op ", _interpolate(_named("host")), " geven."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel uw helpdesk in als het actieve tabblad."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit domein kan nog niet worden beoordeeld. Breng uw admin op de hoogte als dat wel zou moeten kunnen."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit domein toevoegen"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbinding maken met ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ogenblik geduld"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan het webadres niet ophalen"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools die al zijn gekoppeld aan de Zendesk QA-account van de organisatie."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tools gekoppeld aan Zendesk QA-account van organisatie."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee kunt u websites beoordelen waarvan het webadres altijd hetzelfde is, door een unieke hash toe te voegen aan het einde alvorens een beoordeling achter te laten"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van account wisselen"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw domeinen"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen die al aan deze workspace zijn gekoppeld."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt elke pagina van de volgende domeinen beoordelen"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen beschikbaar voor beoordeling"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensie"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniek webadres"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nog geen helpdesk verbonden - ga naar ", _interpolate(_named("link")), " om er een toe te voegen."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA-browserextensie kan alleen worden gebruikt\nmet de plannen Professional en Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overschakelen van workspace"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een naam op"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een waarde op"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste velden"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is de eerste keer dat we dit gesprek tegenkomen. Geef enkele details op."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie van gesprek:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iets om het ticket later aan te herkennen"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugbetaling"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef zowel de medewerker als een onderwerp op"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken..."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek beoordelen"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-toegewezen"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neem contact op met uw manager of schakel over naar een andere workspace via de ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen zijn uitgeschakeld voor deze workspace."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ziens"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit webadres kan niet worden beoordeeld. Navigeer naar een gesprek."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld u eerst aan bij Zendesk QA via uw SSO-provider en probeer het dan opnieuw."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop-up voor aanmelding wordt geopend."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden met uw Zendesk QA-account"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachten..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatie..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u nog geen account?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen toegang tot dit gesprek. Moet dat wel kunnen, neem dan contact op met uw manager."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toegang"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer dat uw verbinding is geactiveerd in Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verbindingen beheren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingsfout"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeer naar een gesprek in uw helpdesk om door te gaan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig webadres"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijna klaar met opnieuw verbinden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een moment"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laad de pagina opnieuw om weer in de flow te komen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlos uzelf van de virtuele grenzen van uw helpdesk en beoordeel gesprekken om het even waar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade uw abonnement om de Extensie te gebruiken"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw abonnement beheren"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De helpdeskverbinding is niet gekoppeld aan deze workspace"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw helpdesk herkent dit ticket niet, of Zendesk QA heeft er geen toegang toe"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan gesprek niet vinden"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe activiteit"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt feedback gekregen"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden geslaagd"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de app nu gebruiken"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle categorieën waarderen"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik dit om bladwijzers toe te voegen aan cases en later uit te filteren"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen feedback voor dit gesprek"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback lezen"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openen in Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier worden alle feedback en meldingen weergegeven. Nog niets…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zie meer in Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen beoordelingen"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelen"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksdetails"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat alle"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is alles van"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat niet alle"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is niet alles van"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beter dan die van de agent"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slechter dan die van de agent"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onwaar"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is meer dan"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat een van"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is een van"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat geen van"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is geen van"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waar"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschonden"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met opmerking"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["met opmerking van mij"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zonder opmerking"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen opmerking van mij"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moet zeker worden beoordeeld"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgelopen 30 dagen"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["binnen"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgelopen 14 dagen"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgelopen 24 uur"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgelopen 30 dagen"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgelopen 7 dagen"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorige maand"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorige week"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze maand"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze week"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tot"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vandaag"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gisteren"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedetecteerd"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestaat"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ster toegevoegd door mij"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen ster toegevoegd door mij"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omvat"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meer dan"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vanaf"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleiner dan"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tot"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn niet"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn niet"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negatief"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet geschonden"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet complex"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet gedetecteerd"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestaat niet"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omvat niet"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet ontvangen"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet weergegeven"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positief"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet beoordeeld"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet beoordeeld door mij"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordeeld"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["door mij beoordeeld"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ontvangen"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moet zeker worden beoordeeld"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meer dan"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vanaf"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kleiner dan"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tot"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn niet"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevat niet"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn niet"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bekeken"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mijn opmerking heeft reacties"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nee"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste categorieën"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste spotlight"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek voorwaarde"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling en feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle voorwaarden weergeven"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijkt de afhandeling van gesprekken door uw bot met de afhandeling door gemiddelde agenten"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot zit vast in een lus en herhaalt hetzelfde bericht achtereenvolgens"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal botreacties in gesprekken"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een type bot betrokken bij het gesprek"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een bot betrokken bij het gesprek"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gespreksinhoud bevat de zin ", _interpolate(_list(0)), " in bericht(en) van agenten"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeer automatisch gesprekken waarin de verplichte informatieverschaffing, zoals 'Dit gesprek wordt opgenomen' en dergelijke, ontbreekt."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een persoon of bot betrokken bij het gesprek"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent of bot die wordt beoordeeld in het gesprek"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant heeft assistentie van een hoger niveau gevraagd"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief en negatief sentiment gedetecteerd in het gesprek"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeert via AI-analyse de meest actieve agent in gesprekken"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent het gesprek beleefd heeft afgerond"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent de gevoelens van de klant begrijpt en erkent"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert grammatica-, spelling- en stijlfouten van agenten"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op typische begroetingen"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent de vraag of het probleem van de klant begrijpt"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert hoe gemakkelijk een tekst kan worden begrepen, rekening houdend met de woordcomplexiteit en de lengte van de zinnen"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op een aangeboden oplossing"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert de toon van de agent tijdens het gesprek"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekenen van verloop van klanten. Klant overwoog over te stappen of heeft beloofd weg te gaan."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep waartoe het gesprek behoort."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De inhoud of tekst van het agentbericht"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties van agenten in gesprekken"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent die is toegewezen om het gesprek te beoordelen"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoelang de oproep duurde, in seconden"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De inhoud of tekst van het gesprek"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het communicatiekanaal gebruikt voor het gesprek (bijv. e-mail, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen toegevoegd aan het gesprek"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop een opmerking aan het gesprek is toegevoegd"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief en negatief sentiment gedetecteerd in het gesprek"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop de klant op de klanttevredenheidsenquête heeft gereageerd"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of de gespreksbeoordeling wordt betwist"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het kanaal dat de klant gebruikt om het gesprek te starten"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal responsen van klanten in gesprekken"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum van het laatste bericht in het gesprek"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rol of het type gebruiker dat bij het gesprek is betrokken"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stilte tussen de agent en de klant overschreed de drempel"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant heeft assistentie van een hoger niveau gevraagd"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant uitte grote dankbaarheid of was zeer tevreden met de ontvangen support"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De klant of de agent heeft expliciet om een follow-up gevraagd"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst of opname van het gehele gesprek via spraak"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of het gesprek is gemarkeerd met een ster"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags gebruikt in opmerkingen om inhoud te categoriseren"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal van het gesprek gedetecteerd door AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum van het laatste bericht in het gesprek"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal privéopmerkingen in gesprekken"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld aantal tekens per bericht in gesprekken"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal klantgerichte reacties in gesprekken"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een deelnemer wiens informatie openbaar is"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld aantal woorden per bericht in gesprekken"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën gebruikt om het gesprek te beoordelen of te evalueren"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De status van het beoordelingsproces van het gesprek"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een deelnemer of persoon betrokken bij het gesprek"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een e-mailadres gekoppeld aan het gesprek"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of de beoordeling is bekeken"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De beoordelaar die het gesprek heeft beoordeeld"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek voor het laatst is beoordeeld"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent die wordt beoordeeld in het gesprek"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifieke opmerkingen of feedback van de klant aangaande de tevredenheid over het gesprek"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De klanttevredenheidsscore voor het gesprek"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanttevredenheidsscore per bericht in het gesprek"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De scorekaart die wordt gebruikt voor het beoordelen van het gesprek"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven SLA's zijn geschonden"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het type bron of verbinding waarvan het gesprek afkomstig is"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek was ongewoon of atypisch en het kostte wat meer heen en weer om tot een oplossing te komen"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De enquêtevraag die betrekking heeft op de inhoud van het gesprek"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De titel of naam van de enquête die aan het gesprek is gekoppeld"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent die is toegewezen om het gesprek te beoordelen"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het merk of het specifieke product of de specifieke service gekoppeld aan het gesprek"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het communicatiekanaal gebruikt voor het gesprek (bijv. e-mail, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek werd gesloten of opgelost"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek voor het eerst is gemaakt"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek voor het eerst is gemaakt"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop de klant op de klanttevredenheidsenquête heeft gereageerd"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een korte beschrijving of samenvatting van het gesprek"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een externe referentie-id gekoppeld aan het gesprek"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De map of specifieke groepering waarin het gesprek is georganiseerd"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het formulier of de sjabloon gebruikt om het gesprek te structureren"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een externe referentie-id gekoppeld aan het gesprek"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De inbox of de e-mailaccount waarmee het gesprek is ontvangen"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het telefoonnummer dat aan het gesprek is gekoppeld"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het prioriteitsniveau toegewezen aan het gesprek"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het totale aantal reacties in het gesprek"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De staat of huidige status van het gesprek"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De huidige status van het gesprek"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het onderwerp of subject van het gesprek"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties van agenten in gesprekken"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags toegewezen om het gesprek te categoriseren"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het type of de categorie van het gesprek"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek voor het laatst is bijgewerkt"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De datum waarop het gesprek voor het laatst is bijgewerkt"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het medium of kanaal waarop het gesprek heeft plaatsgevonden"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De inhoud van een transcriptie in een gesprek"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken die niet in aanmerking komen voor beoordeling via AI-analyse"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal deelnemende agenten aan gesprekken"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of het gesprek is bekeken"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elk bericht"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiëntie van botcommunicatie"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botherhaling"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal botreacties"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottype"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oproeprichting"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht van klant"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doodse stilte"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatieverschaffing over opname ontbreekt"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalatie"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van gesprekstranscriptie"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ster"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt niet in aanmerking voor beoordeling"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld door"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek via"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling en grammatica"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentbericht"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meest actieve agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties van agent"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur van oproep (sec.)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van gesprek"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtenkanaal"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingentag"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van opmerking"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum reactie op CSAT-enquête"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschil"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantkanaal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties van klanten"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van laatste reactie"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type gebruiker"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risico op verloop"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doodse stilte"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalatie"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitzonderlijke service"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcriptie"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag van opmerking"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschieter"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrip"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van laatste reactie"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal privénotities in gesprek"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld aantal tekens"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld aantal woorden"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal openbare reacties"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbare deelnemer"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsdatum"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waardering en opmerking"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsstatus"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leesbaarheid"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemer"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerde e-mail"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerelateerd"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling ontvangen"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde scorekaart"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeling weergegeven"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van beoordeling"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêteopmerking"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per bericht"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevredenheidsscore (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossing aangeboden"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brontype"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerp"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtevraag"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtetitel"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk van gesprek"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkdomein"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekskanaal"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van sluiting"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum reactie op CSAT-enquête"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenddatum CSAT-enquête"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving van gesprek"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe id"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veld van gesprek"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map van gesprek"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier van gesprek"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-id"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksgroep"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout-id"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe id"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-id"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox van gesprek"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioriteit van gesprek"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties op gesprek"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat van gesprek"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van gesprek"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerp van gesprek"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal reacties van agent"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktag"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktag"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type gesprek"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum bijgewerkt"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum bijgewerkt"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-id"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van transcriptie"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal deelnemende agenten"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status bekeken"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwetsbaarheid i.v.m. capaciteiten"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwetsbaarheid i.v.m. gezondheid"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwetsbaarheid i.v.m. levensgebeurtenis"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-categorie"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een datumgerelateerde voorwaarde"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een naam toe aan uw filter"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een filternaam in"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemelde persoon"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voormalige leden van workspace"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden van workspace"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " toevoegen"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter toepassen"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet minimaal 1 datumgerelateerde filter hebben"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer opties"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een optie"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt binnen ", _interpolate(_named("n")), " dag. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>binnen ", _interpolate(_named("n")), " dag</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt binnen ", _interpolate(_named("n")), " dagen. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>binnen ", _interpolate(_named("n")), " dagen</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt vandaag. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw proefperiode eindigt <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>vandaag</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel nu een abonnement in"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dagen"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt nog ", _interpolate(_named("days")), " in uw proefperiode"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt de gebruikerslimiet voor uw plan bereikt. ", _interpolate(_named("link")), " voor meer informatie of om uw plan te upgraden."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koppel uw helpdesk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee kunnen wij uw tickets ophalen zodat u ze kunt beoordelen."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel uw helpdesk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U gebruikt Zendesk QA met voorbeeldgegevens. Als u voorbeeldgegevens wilt verwijderen en uw eigen gesprekken wilt importeren, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeldgegevens"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer nuttige informatie hieronder"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een paginagrote schermafbeelding maken om context te bieden"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met ons klantenserviceteam om dit probleem op te lossen:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets fout gegaan"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutreferentie:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuttige informatie kopiëren"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers, bots en workspaces"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers en workspaces"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vandaag"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze maand"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deze week"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " beoordeeld ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " beoordeeld ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " beoordeeld ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is er nieuw?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelkoppelingen voor het toetsenbord"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat met ons"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan onze chat niet laden (Gebruikt u misschien advertentieblokkering?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven beoordelingen"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen beoordelingen"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennisbank"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van account wisselen"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toepassing is bijgewerkt. ", _interpolate(_named("buttonStart")), "Laad opnieuw", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u Zendesk QA als onderdeel van uw workspace wilt gebruiken:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opgelet, u hebt al een uitnodiging ontvangen om Zendesk QA te gebruiken als onderdeel van een workspace. U kunt uiteraard doorgaan als u dat wilt, maar hou er rekening mee dat er dan een ", _interpolate(_named("bold")), " voor u wordt gemaakt."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afzonderlijke organisatie"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja, ik wil doorgaan met ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een nieuwe account"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u dit zeker?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " vraagt u om lid te worden van ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid worden"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen internetverbinding"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe pin toevoegen"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle navigatie"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focussen op de opmerkingeneditor en uitvouwen"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijvenster verbergen of weergeven"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar het volgende gesprek navigeren"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oorspronkelijk gesprek openen"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar het vorige gesprek navigeren"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle categorieën de hoogst mogelijke waardering geven"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle categorieën de laagst mogelijke waardering geven"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken schudden (willekeurig opnieuw sorteren)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertel ons"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelkoppelingen voor het toetsenbord"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modaal snelkoppelingen in-/uitschakelen"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt de limiet van ", _interpolate(_named("charLimit")), " tekens bereikt"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afbeelding ", _interpolate(_named("name")), " type (", _interpolate(_named("type")), ") is niet toegestaan"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afbeelding ", _interpolate(_named("name")), " is te groot (5 MB toegestaan)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingeneditor kan niet worden geladen"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet neer om te uploaden"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen beheren"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sjablonen toegevoegd"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaak toevoegen"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere, waaronder verwijderde redenen"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden voor waardering"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal medewerkers"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We nemen contact met u op om u te helpen uw account in te stellen"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactnummer van bedrijf"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe hebt u voor het eerst over Zendesk QA gehoord?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een kanaal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een aantal agenten"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alle verplichte velden in"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet onze voorwaarden en ons privacybeleid hebben gelezen en ermee akkoord gaan."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door support aangestuurd Slack-kanaal"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclame"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenis"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriend of collega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdeskmarktplaats of accountmanager"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijzende site"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekmachine (Google, Bing enz.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertel ons iets over uzelf en uw bedrijf."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountdetails"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben er klaar voor."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snuffel rond, leer de app kennen en verwijder en voeg dingen toe."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account gemaakt. Wat nu?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar om uw echte gesprekken in te voeren?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw eigen gegevens toevoegen"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin te verkennen"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het uit met voorbeeldgegevens"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dan kunnen wij beginnen met het binnenhalen van uw tickets, zodat u ze kunt beoordelen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbind uw helpdeskaccount"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben van gedachten veranderd."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie annuleren"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin gemaakt. ", _interpolate(_named("buttonStart")), "Ga naar pins", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingpinnen zijn beschikbaar in het Professional-plan."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan upgraden"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle plannen bekijken"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla uw notities op om u voor te bereiden op de volgende individuele sessie of een coachingsessie."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen voor coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een opmerking plaatsen of anderen toevoegen via ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek is verwijderd"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een nieuwe pin maken"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin maken"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin bewerken"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe pinnen werken"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet gemachtigd om dit gesprek te bekijken"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaart vastmaken"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek vastmaken"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard vastmaken"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin opgeslagen"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze kaart vastmaken"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gesprek vastmaken"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit dashboard vastmaken"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastmaken onder een gebruiker"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een gebruiker"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen u kunt zien wat u vastmaakt. Om met anderen te delen, noemt u ze met een ", "@", " in de opmerkingen."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " is nu uitgenodigd voor dit gesprek"]), _normalize([_interpolate(_named("agentsNames")), " zijn nu uitgenodigd voor dit gesprek"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 opmerking"]), _normalize([_interpolate(_named("n")), " opmerkingen"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["U hebt ", _interpolate(_named("participants")), " genoemd maar deze persoon neemt niet deel aan dit gesprek."]), _normalize(["U hebt ", _interpolate(_named("participants")), " genoemd maar deze personen nemen niet deel aan dit gesprek."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " deelnemers"]), _normalize([_interpolate(_named("n")), " deelnemer"]), _normalize([_interpolate(_named("n")), " deelnemers"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wilt u ", _interpolate(_named("agentName")), " de toegang tot deze pin ontzeggen?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets doen"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig ze niet uit voor dit gesprek"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg opmerking toe"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hen uitnodigen"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig ze uit voor dit gesprek"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker verwijderen"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussie"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit kan niet ongedaan worden gemaakt."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden ook alle opmerkingen onder deze pin verwijderd."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nieuwe berichten"]), _normalize([_interpolate(_named("n")), " nieuw bericht"]), _normalize([_interpolate(_named("n")), " nieuwe berichten"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen u kunt uw pinnen zien. Als u pinnen wilt delen, noemt u iemand via ", "@", " in de opmerkingen."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " heeft ", _interpolate(_named("pinType")), " vastgemaakt"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een dashboard"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een gesprek"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een notitie"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een upgrade uit om coachingpinnen te gebruiken"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingpinnen zijn beschikbaar in het Professional-plan."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingpinnen zijn beschikbaar in het Professional-plan"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een upgrade uit om deze functie te gebruiken"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereid u voor op uw individuele sessie of bewaar iets voor een ander keer."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden de pin en alle toegevoegde opmerkingen definitief verwijderd."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin verwijderen"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin verwijderen"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapnaam"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dingen vastmaken om later op terug te komen"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reageren"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwste eerst"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudste eerst"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla een interessante dashboardtrend of interessant gesprek op ter voorbereiding op uw individuele sessies, of noteer ze voor een ander keer."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een pin door te klikken op de knop bovenaan of op het pinpictogram in de weergave Gesprek of Dashboard."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastmaken"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen pinnen gemaakt"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Geen resultaten voor ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik andere sleutelwoorden of controleer op typefouten."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een nieuwe pin maken"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op gebruiker, sleutelwoord..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetisch"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlangs gemaakt"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlangs bewerkt"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeste pinnen"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ik wil ", _interpolate(_named("schedule")), " ", _interpolate(_named("n")), " gesprek beoordelen"]), _normalize(["Ik wil ", _interpolate(_named("schedule")), " ", _interpolate(_named("n")), " gesprekken beoordelen"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel bijgewerkt"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel instellen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt een persoonlijk doel instellen voor het aantal gesprekken dat u wilt beoordelen."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elke dag"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elke maand"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elke week"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsdoel"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSOONLIJK"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelding"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen laden..."])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer kanaal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel enquêtefeedback met opmerkingen delen"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstrek uw collega's door hun uitzonderlijke servicekwaliteit en uitstekende feedback van klanten te benadrukken."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positieve enquêtefeedback delen met Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kanalen gevonden"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback automatisch delen met scores boven"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot-integraties"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang een herinnering aan het begin van de week als u nog geen beoordelingen hebt uitgevoerd."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herinneringen"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijkse herinnering om beoordelingen uit te voeren"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang meldingen over nieuwe beoordelingen, reacties, vermeldingen en geschillen in Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeldingen"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingen"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussiereacties"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integraties"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krijg meldingen over nieuwe beoordelingen, reacties, vermeldingen en geschillen."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen gerelateerd aan mij"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeldingen in opmerkingen"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe ontvangen beoordelingen"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacties in discussies"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport met statistieken"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang rapporten over gegeven en ontvangen beoordelingen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporten"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dagelijks"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maandelijks"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wekelijks"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke 3 uur"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke 6 uur"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onmiddellijk"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uit"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijks"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw accountadmin heeft meldingsinstellingen vergrendeld."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer periode"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke meldingen"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (actief)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een nieuwe aanmelding via social media toe aan deze account:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een actieve aanmeldingsmethode verwijdert, wordt u afgemeld. U kunt opnieuw aanmelden met uw alternatief e-mailadres."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingsmethode verwijderen"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google toegevoegd als aanmeldingsoptie"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingsopties"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack toegevoegd als aanmeldingsoptie"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelding"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google toevoegen"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack toevoegen"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord maken"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u dit zeker?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er wordt een e-mail verzonden naar ", _interpolate(_named("email")), " om uw wachtwoord opnieuw in te stellen."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primair e-mailadres"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw Inbox op onze bevestigingsmail"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magische link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om dit te wijzigen, wijzigt u uw ", _interpolate(_named("link")), " op voorhand."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het e-mailadres ", _interpolate(_named("email")), " wordt aan dit wachtwoord gekoppeld."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel bijgewerkt"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profielafbeelding"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload voor de beste resultaten een vierkante foto (256x256 pixels of meer)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primair e-mailadres"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin van de week"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke instellingen"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer minimaal één optie"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablooninhoud"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloonnaam"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon weergeven in"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablooninhoud toevoegen"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een sjabloonnaam toe"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon maken"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon maken"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak sjablonen voor opmerkingen om beoordelen, items vastmaken en coaching sneller te laten verlopen."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikt in"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon verwijderen"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sjabloon definitief verwijderen?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen bewerken"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak sjablonen voor opmerkingen om beoordelen, items vastmaken en coaching sneller te laten verlopen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sjablonen gemaakt"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon voor opmerkingen gemaakt"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon voor opmerkingen verwijderd"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon voor opmerkingen opgeslagen"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen en geschillen"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neem voor toegang contact op met ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klantensupport"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen account gevonden"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruik voorlopig ", _interpolate(_named("link")), " om toegang te krijgen tot Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account niet gemigreerd"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sik"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrics"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scores"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistieken"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibratie"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesprekken"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordeling"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mijn opmerkingen"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mijn geschillen"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mijn beoordelingen"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reacties"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begonnen"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtefeedback"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteit"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijk"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord maken"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanmeldingsgegevens"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanmelding"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opnieuw instellen"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordelingsdoel"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sjablonen"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruiker"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon maken"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelding"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturering"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedrijf"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraties"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisatie"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaling"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenties"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instellingen"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonnement"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticaties"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe categorie"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflicterend bericht gevonden"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig of verwijder een van de conflicterende berichten"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruik het pictogram ", _interpolate(_named("placeholder")), " om gevoelige gegevens uit te sluiten van AutoQA. In plaats van 'Tot ziens, Jan' te gebruiken, kunt u bijvoorbeeld 'Tot ziens, ", _interpolate(_named("placeholder")), "' gebruiken om gelijk welke naam te vertegenwoordigen."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximaal ", _interpolate(_named("limit")), " rijen"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas regel toe op"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de limiet van 10 aangepaste categorieën bereikt"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt de limiet van ", _interpolate(_named("limit")), "aangepaste categorieën bereikt"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarde toevoegen"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rij toevoegen"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarde verwijderen"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als agentbericht"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anders-score"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dan-score"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaartcategorie maken"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik '/' om een variabele toe te voegen"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle richtingen"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentbericht mag niet leeg zijn"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht mag niet uit meer dan 300 tekens bestaan"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bronnen"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanalen"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tags"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gesprekken"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfijn waar uw regel van toepassing is."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifieke gesprekken"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-inzicht maken"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentbericht mag niet leeg zijn"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de limiet van 10 aangepaste inzichten bereikt"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt de limiet van ", _interpolate(_named("limit")), " aangepaste inzichten bereikt"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul naam van inzicht in"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van inzicht"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie maken"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type inzicht"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutraal"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positief"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar workspace-instellingen"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet nu"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u deze waarderingscategorie wilt beginnen gebruiken, gaat u naar de workspacescorekaartinstellingen en voegt u deze toe aan een scorekaart naar keuze."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie gemaakt"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef automatisch een score aan gesloten gesprekken door specifieke sleutelwoorden of zinsdelen te identificeren."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkt niet voor spraaktranscripties."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik snap het"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["High-five! ✋ U hebt de limiet van ", _interpolate(_named("limit")), " voor uw automatische waarderingscategorieën bereikt. U bent een categorisatietovenaar! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorielimiet bereikt"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat alle velden juist zijn ingevuld"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw waarderingscategorie een naam"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorienaam"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe waarderingscategorie"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe waarderingscategorie"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef automatisch een score aan gesloten gesprekken door specifieke sleutelwoorden of zinsdelen te identificeren. Werkt niet voor spraaktranscripties."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haal gesloten gesprekken automatisch op door specifieke sleutelwoorden of zinsdelen te identificeren. Werkt alleen bij transcripties."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef gesprekken automatisch een score door specifieke sleutelwoorden of zinsdelen te identificeren in zowel agent- als botberichten."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u deze waarderingscategorie wilt gebruiken, gaat u naar de workspacescorekaartinstellingen en voegt u deze toe aan een scorekaart."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef gesprekken automatisch een score op basis van specifieke sleutelwoorden of zinsdelen in berichten."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbaar voor"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inzicht instellen"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit inzicht wordt in Spotlight weergegeven op basis van gedefinieerde criteria."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaartconfiguratie"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze categorie kan worden gebruikt voor scorekaarten op basis van gedefinieerde criteria."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["binnenkort"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aangepast"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vooraf gedefinieerd"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scorekaart"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spraak"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opgeslagen"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik op het pictogram ", "{··}", " om namen, bedrijfsgegevens of andere gevoelige informatie uit te sluiten tijdens AutoQA-controles van afsluitingen."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluiting toevoegen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluiting bewerken"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluiting"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstellingen toevoegen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling bewerken"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroeting toevoegen"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroeting bewerken"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroeting"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak hier woorden gescheiden door regeleinden"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk na elk woord op Enter voor een nieuwe invoer"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepasselijke taal"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt bijvoorbeeld in plaats van 'Tot ziens, Jan' 'Tot ziens, ", "{··}", "' gebruiken om gelijk welke naam te vertegenwoordigen."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaatsaanduiding voor dynamische inhoud"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstellingen"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 vrijstelling toegevoegd"]), _normalize([_interpolate(_named("count")), " vrijstellingen toegevoegd"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vrijstelling al toegevoegd"]), _normalize(["Vrijstellingen al toegevoegd: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling toevoegen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vrijstellingen gevonden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vrijstellingen toegevoegd"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling (hoofdlettergevoelig)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vrijstellingen toevoegen"]), _normalize(["1 vrijstelling toevoegen"]), _normalize([_interpolate(_named("count")), " vrijstellingen toevoegen"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een woord of zin"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling opgeslagen"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taal"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle talen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar taal"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Afsluiting al toegevoegd"]), _normalize(["Afsluitingen al toegevoegd: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting toevoegen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen goedgekeurde afsluitingen gevonden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen goedgekeurde afsluitingen"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting toevoegen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een afsluiting"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting toegevoegd"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting opgeslagen"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formulier heeft dubbele vermeldingen: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Begroeting al toegevoegd"]), _normalize(["Begroetingen al toegevoegd: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting toevoegen"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen goedgekeurde begroetingen gevonden"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen goedgekeurde begroetingen"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting toevoegen"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een begroeting"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting toegevoegd"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting opgeslagen"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling toevoegen"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vrijstelling verwijderen"]), _normalize(["Vrijstellingen verwijderen"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Deze vrijstelling definitief verwijderen?"]), _normalize(["Deze vrijstellingen definitief verwijderen?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstelling bewerken"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Een of meer vrijstellingen zijn meer dan ", _interpolate(_list(0)), " tekens lang"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " tekens"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt door"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabele"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer uw eigen afsluitingen die passen bij uw merk en servicenormen. Indien toegevoegd, detecteert AutoQA alleen goedgekeurde afsluitingen. Doorgevoerde wijzigingen zijn van toepassing op toekomstige gesprekken."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de bovenlimiet van 100 goedgekeurde afsluitingen bereikt. Als u er meer wilt toevoegen, is het misschien tijd om uw bestaande afsluitingen opnieuw te bekijken en te verfijnen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiet voor goedgekeurde afsluitingen bereikt"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluitingen"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woorden en zinsdelen die u door AutoQA wilt laten negeren als spel- of grammaticafouten. Doorgevoerde wijzigingen zijn van toepassing op toekomstige gesprekken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negeerlijst"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw eigen begroetingen op die passen bij uw merk en servicenormen. Indien toegevoegd, detecteert AutoQA alleen goedgekeurde begroetingen. Doorgevoerde wijzigingen zijn van toepassing op toekomstige gesprekken."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de bovenlimiet van 100 goedgekeurde begroetingen bereikt. Als u er meer wilt toevoegen, is het misschien tijd om uw bestaande begroetingen opnieuw te bekijken en te verfijnen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiet voor goedgekeurde begroetingen bereikt"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroetingen"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet nu"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie verwijderen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze waarderingscategorie definitief verwijderen?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieënlijst in-/uitschakelen"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderde categorie wordt niet weergegeven in nieuwe gesprekken en filters. Historische gegevens voor dashboards en gesprekken blijven intact."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De categorie wordt verwijderd uit alle scorekaarten waaraan deze werd toegevoegd."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen van scorekaartcategorie mislukt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaartcategorie verwijderd"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaartcategorie verwijderen"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-categorieën"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " mogelijk gemaakt door het AI-model blijft op de scorekaart staan maar krijgt niet langer automatisch een score"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u LLM-integratie uitschakelt, wordt de AutoQA-functie niet volledig uitgeschakeld. Dit zal er gebeuren:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische gegevens"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " blijft op het AutoQA-dashboard staan"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig toegevoegde hoofdoorzaken"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " blijven staan, maar alle op LLM gebaseerde hoofdoorzaken worden verwijderd"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op LLM gebaseerde AutoQA uitschakelen"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschakelen"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA maakt gebruik van AI en LLM's (Large Language Models) om specifieke categorieën automatisch te scoren. Uw gegevens worden verwerkt in overeenkomst met een strikte Gegevensverwerkingsovereenkomst (DPA) en worden nooit gebruikt om modellen te trainen."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over Generatieve AI"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA gebruikt Microsoft Azure OpenAI Service voor het ", _interpolate(_named("automatically_scoring_categories")), ". Uw gegevens worden verwerkt in het kader van een strikte gegevensbeschermingsovereenkomst, enkel om AutoQA aan u te kunnen verlenen en ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatisch scoren van bepaalde categorieën"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worden nooit gebruikt om OpenAI-modellen te trainen"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op LLM gebaseerde AutoQA ingeschakeld"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op LLM gebaseerde AutoQA uitgeschakeld"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op LLM gebaseerde AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische scores met AI in AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensverwerking van op MS Azure gebaseerde AutoQA-categorieën"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure-toestemming ingetrokken"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure-toestemming gegeven. Gesprekken krijgen binnenkort automatische waarderingen."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt AutoQA nog steeds gebruiken, maar dit is wat er gebeurt:"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de MS Azure OpenAI-service uitschakelt, wordt AutoQA niet uitgeschakeld als functionaliteit."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt dat doen in de scorekaartcategorieweergave."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door het model mogelijk gemaakte AutoQA-categorieën blijven op de scorekaart staan maar krijgen niet automatisch een score."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De hoofdoorzaken die u handmatig hebt toegevoegd, blijven behouden. Alle hoofdoorzaken die door het model zijn gegenereerd, worden verwijderd."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische gegevens blijven op het AutoQA-dashboard staan."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensverwerking door MS Azure OpenAI-service uitschakelen"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breder bereik van AutoQA-categorieën."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kant-en-klare meertalige support."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelle toevoeging van nieuwe categorieën."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeterde nauwkeurigheid van evaluatie. GPT-3.5 heeft een contextueel bewustzijn op menselijk niveau."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiteer van de nieuwste ontwikkelingen op het gebied van AI-technologie."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat u krijgt:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensverwerking door MS Azure OpenAI-service inschakelen"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming gegeven"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen toestemming gegeven"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle nieuwe gebruikers worden aan deze workspace toegewezen"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardworkspace"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-urige tijdaanduiding (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-urige tijdaanduiding (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdnotatie"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zo"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsprofiel bijgewerkt"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin van de week"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste integratie"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De toegangssleutel-id en geheime sleutel vindt u doorgaans onder Toegangsbeheer > Gebruikers"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id van AWS-toegangssleutel"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect toevoegen"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen van geheime toegangssleutel annuleren"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde van geheime toegangssleutel wijzigen"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met de verbinding kan één regio worden gedefinieerd die u doorgaans vindt onder Locatie"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-regio"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een geheime sleutel toe"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geheime AWS-toegangssleutel"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt alle relevante informatie in Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaans te vinden op de pagina Amazon Connect-instantie-instellingen en Gegevensopslag"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaglocatie van gespreksopnames"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het toevoegen van Amazon Connect. Controleer of alle velden juist zijn ingevuld en probeer het opnieuw."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaglocatie moet de indeling bucket/map hebben, gescheiden door een schuine streep."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om een verbinding toe te voegen, neem contact op met ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantensupport"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al uw Zendesk-tickets worden automatisch gesynchroniseerd. U kunt ervoor kiezen geselecteerde tickets uit te sluiten."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azië-Pacific (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azië-Pacific (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azië-Pacific (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azië-Pacific (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azië-Pacific (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Centraal)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Ierland)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londen)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverregio"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midden-Oosten (VAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer serverregio"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuid-Amerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VS - Oost (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VS - Oost 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VS - West (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding al toegevoegd"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding starten"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniseer alleen gesprekken die"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanaal een, kanaal twee"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De unieke id van deze verbinding is ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 maand"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 jaar"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 maanden"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 maanden"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-actieve gesprekken worden na de geselecteerde bewaarperiode verwijderd. Ingediende beoordelingen blijven zichtbaar in de Activiteiten- en Dashboardweergaven."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaarperiode"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer bewaarperiode"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelet, deze actie kan niet ongedaan worden gemaakt."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik begrijp dat hiermee alle helpdeskgerelateerde gegevens voor deze verbinding worden verwijderd uit Zendesk QA, waaronder gesprekken, velden en tags."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit heeft geen gevolgen voor ingediende beoordelingen en deze blijven zichtbaar in specifieke weergaven."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groep een, groep twee"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevatten:"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actieve ontwikkeling"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze integratie is nog steeds in ", _interpolate(_named("activeDevelopment")), ". Mogelijk ontbreken er details (zoals bijlagen of aangepaste velden)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postvak een, postvak twee"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag een, tag twee"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-tokengeheim"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt 'Namen van ticketvelden' in Freshdesk onder Instellingen → Ticketvelden"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt 'Custom Field Names' voor Help Scout onder Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt 'Klass-gespreksnamen' voor Kustomer onder Instellingen → Klasses → Gesprek"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt 'Naam aangepast veld' voor Live Agent onder Instellingen → Aangepaste velden"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt 'Naam aangepast veld' voor Wix onder Instellingen → Aangepaste velden"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheid veld-id's van elkaar met komma's. U vindt deze id's in Zendesk onder Admin → Ticketvelden."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbind enkele van uw helpdeskgebruikers om uw eerste gesprekken te beoordelen. Maakt u zich geen zorgen. Wij brengen hen pas op de hoogte als u hen uitnodigt om lid te worden van een workspace."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geïntegreerd"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden toevoegen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We beginnen nu met het ophalen van uw gesprekken."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domein: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Helpdesk-id: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan uw verbinding niet authenticeren"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar. Dit is uw nieuwe verbinding."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepaalde verbindingen zijn niet bijgewerkt of zijn niet-actief geworden. Probeer deze verbindingen bij te werken of verwijder ze uit uw lijst."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-client-id"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-clientgeheim"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maak een nieuw API-token via Instellingen → Account → API en SDK's. Het te gebruiken omleidingswebadres is ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account-id"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer uw helpdesk om aan de slag te gaan"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als uw Intercom-account is ingesteld om te worden bediend vanuit de EU of Australië, werk dit dan bij."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-mailadres dat u gebruikt om aan te melden bij ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg de naam, het e-mailadres en het telefoonnummer van de eindgebruiker. Bankgegevens zijn al gemaskeerd voor alle helpdeskverbindingen."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen integraties. Nu een toevoegen."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt deze rollen nodig:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding maken"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een nieuwe verbinding toevoegen"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u dit selectievakje uitschakelt, kunt u deze verbinding handmatig alleen aan specifieke workspaces toevoegen"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding maken met alle bestaande workspaces"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspaces"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-sleutel"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-sleutelgeheim"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Verbinding ", _interpolate(_list(0)), " verwijderd"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingsnaam"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt op"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U vindt uw API-token hieronder."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-drempel"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbinding ", _interpolate(_named("connectionName")), " verwijderen"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ 'verbinding verwijderen' om deze verbinding te verwijderen"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding verwijderen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer en monitor uw verbonden gegevensbronnen."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding bewerken"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitsluiten"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheid veldnamen van elkaar met komma's"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken mislukt"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een unieke naam aan uw integratie"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde opties verbergen"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velden met gevoelige gegevens verbergen"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla nooit webadressen van bijlagen op"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijlagen negeren"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar nooit inhoud van gesprekken (u kunt ze later on-demand ophalen in uw helpdesk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van gesprek negeren"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaar nooit het onderwerp van gesprekken (u kunt dit later on-demand ophalen in uw helpdesk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerpregel negeren"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-actief"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uw subdomein ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-regio"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overeenstemming met id van inbox-team"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerieke id van uw Intercom-team"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In uitvoering…"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postvakken"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding handmatig bijwerken"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantendata maskeren"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspaces"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van gevoelig veld"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id van gevoelig veld"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding vernieuwen"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding vernieuwen"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding maken met de Salesforce-sandbox"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Een verbinding instellen met ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde opties weergeven"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen gesprekken ophalen met een score die lager is dan of gelijk is aan de ingestelde drempel"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een CSAT-drempel toepassen"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen gesprekken synchroniseren die..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwste gesprekken ophalen"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding bijwerken"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding bijgewerkt"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw helpdesk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u uw abonnement wilt annuleren en uw account wilt verwijderen, neemt u contact op via ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantensupport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verwijderen"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van een ticket in Zendesk Support (of een andere helpdesk) heeft geen gevolgen voor de bijbehorende beoordelingen. U kunt ze nog steeds weergeven en beheren."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u een ticket verwijdert in Zendesk QA , worden gerelateerde gespreksbeoordelingen ook verwijderd.", _interpolate(_named("line_break")), "Het verwijderen van een ticket in Zendesk Support (of een andere helpdesk) heeft geen gevolgen voor de bijbehorende gespreksbeoordelingen. U kunt ze nog steeds weergeven en beheren."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen beheren"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van beoordelingen is definitief. Dit heeft ook gevolgen voor gegevens op dashboards."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordelingen verwijderen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles verwijderen"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordelingen verwijderd"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles verwijderen"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als er beoordelingen zijn die zijn gekoppeld aan verwijderde tickets, ziet u deze hier."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen beoordelingen"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek ticket-id"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket-id: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderde gesprekken"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Standaardworkspaces kunnen niet worden verwijderd. Als u deze workspace wilt verwijderen, selecteert u een andere standaardworkspace in ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountinstellingen"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie kan niet ongedaan worden gemaakt."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle gegevens worden verwijderd"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ik begrijp dat hiermee ", _interpolate(_named("removeAllData")), " die zijn gerelateerd aan deze workspace, waaronder workspacegebruikers en -verbindingen."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace verwijderen"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ 'workspace verwijderen' om deze workspace te verwijderen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Workspace ", _interpolate(_named("workspaceName")), " verwijderen"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workspace verwijderen"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u weet wat u doet of raadpleeg eerst een professional."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer of de standaardbeoordeelde wordt geselecteerd op basis van de meest frequente agent of op basis van de huidige medewerker."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meest actieve agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardbeoordeelde"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sta toe dat mensen hun eigen gesprekken beoordelen en stel agenten in staat om beoordelingen uit te voeren"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace is verwijderd"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace is bijgewerkt"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breng een neutrale waardering tot stand door beoordelingen te verbergen die eerder zijn gegeven door andere beoordelaars"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectieve waardering"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie kan niet ongedaan worden gemaakt."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelet, hiermee worden alle gegevens verwijderd die zijn gerelateerd aan deze workspace, waaronder workspacegebruikers en -verbindingen."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace verwijderen"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace verwijderen..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magische link"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magische link"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan uw huidige aanmeldingsmethode niet deactiveren"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatiemethoden zijn bijgewerkt"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sta bepaalde typen authenticatiemethoden toe voor alle gebruikers."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticaties"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers en workspaces"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak en beheer workspaces, en nodig gebruikers uit of koppel ze."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op pagina blijven"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina verlaten"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze pagina verlaten en alle wijzigingen verliezen?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticaties"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen gesprekken om te importeren. Begin met het voeren van enkele gesprekken met uw helpdesk en keer hier terug om deze te analyseren."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken gevonden"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt deze systeemcategorie niet verwijderen"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie verwijderen"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van categorieën is definitief. Alle categoriegegevens uit de rapporten worden gewist."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u deze categorie wilt herstellen, moet u deze opnieuw maken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie verwijderen"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar categorie"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische categorieën vullen de scorekaart automatisch met voorgestelde waarderingen om tijd te besparen bij het beoordelen. Handmatige categorieën worden van nul gevuld door beoordelaars."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst bijgewerkt"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling en grammatica"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrip"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leesbaarheid"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossing aangeboden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen categorieën gevonden"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hoofdoorzaken gevonden"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen scorekaarten gevonden"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer een andere zoekopdracht of wijzig de filters"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorieën"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorieën"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorieën"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle categorieën"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorieën"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorekaarten"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorekaarten"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorekaarten"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scorekaarten"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " scorekaarten"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statussen"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statussen"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statussen"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statussen"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statussen"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typen"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typen"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typen"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle typen"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typen"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspaces gevonden"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspaces"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " workspaces"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gewicht van ", _interpolate(_named("weight")), " draagt ", _interpolate(_named("percentage")), "% bij aan totaalscore"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart verwijderen"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verwijderen van scorekaarten is definitief. Alle scorekaartgegevens worden uit de rapporten verwijderd."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u deze scorekaart wilt herstellen, moet u deze opnieuw maken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart verwijderen"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst bijgewerkt"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-actief"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Waarderingsschaal bijgewerkt. ", _interpolate(_named("buttonStart")), "Begin met beoordelen", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA stelt standaard een binaire schaal in, maar u kunt ook kiezen voor een meer gedetailleerde schaal:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u verschillende opties voor waarderingsschalen, geconfigureerd per categorie, wilt zien, gaat u naar uw ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie/toon"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productkennis"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door de waarderingsschalen bij te werken:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw vorige waarderingsschaal en de bijbehorende categorieën worden gearchiveerd (niet verwijderd)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit heeft invloed op bepaalde dashboardgegevens omdat uw oude en nieuwe schaal mogelijk niet vergelijkbaar zijn."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn bestaande instellingen voor waarderingscategorieën klonen naar de nieuwe schaal"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de waarderingsschalen wilt bijwerken?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de weergave-indeling van de scorekaart wijzigen (cijfers of emoji's) en categorieën verplicht of optioneel maken."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji's gebruiken in plaats van cijfers"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestaan dat personen categorieën overslaan"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossing"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type waarderingsschaal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Categorieën voor 1 workspace zijn gekloond"]), _normalize(["Categorieën voor ", _interpolate(_named("workspacesAmount")), " workspaces zijn gekloond"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace-instellingen"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breng automatisch oproepen naar boven waarin de agent de klant niet heeft laten weten een stilte in het gesprek te verwachten."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek heeft momenten van doodse stilte die langer duren dan de ingestelde drempel."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer maximale drempel voor doodse stilte in oproepen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doodse stilte"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderde inzichten worden niet weergegeven in nieuwe gesprekken en filters. Historische gegevens voor dashboards en gesprekken blijven intact."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen van Spotlight-inzichten mislukt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-inzichten verwijderd"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-inzichten verwijderen"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef gesprekken weer die specifieke woorden of zinsdelen in transcripties en berichten bevatten."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeer automatisch gesprekken waarin de verplichte informatieverschaffing, zoals 'Dit gesprek wordt opgenomen' en dergelijke, ontbreekt."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft als doel het identificeren, in het gesprek, van een informatieverschaffing over opname aan de hand van een Large Language Model (LLM) dat context dynamisch begrijpt, waardoor vooraf ingestelde zinnen niet meer nodig zijn."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatieverschaffing over opname ontbreekt"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breng automatisch oproepen naar boven waarin de agent de klant niet heeft laten weten een stilte in het gesprek te verwachten."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doodse stilte"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momenten van doodse stilte in een gesprek aangeven"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wilt u wijzigingen aanbrengen in uw abonnement? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") en we helpen u graag verder."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseer automatisch 100% van de gesprekken, voor agenten, BPO's, kanalen en talen. AI detecteert problemen, gaten in de kennis en kansen voor coaching om de service te verbeteren."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% dekking door AutoQA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback en coaching van agenten"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight AI-inzichten"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde rapportages en dashboards"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentanalyse en filtering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand vertalingen"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsopdrachten en instelling van doelen"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching en kwissen"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Kwaliteitsborging (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in Professional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (oude versie)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks voor onmiddellijke melding van kritieke fouten"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datawarehouse-integratie"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud-integratie"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeling van aangepaste integratie"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewijde manager voor klantbegeleiding"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directe toegang tot onze product- en engineeringteams via Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in Growth Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA voor 100% dekking"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-gedreven gespreksinzichten om gegevens te begrijpen"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door AI verbeterde verrijking en filtering van helpdeskgegevens"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automatische detectie van interacties die zeker moeten worden beoordeeld"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentanalyse en filtering"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-gedreven rapportage over en inzichten in prestaties"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand vertalen van gesprekken"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingstijd registreren"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van slagingspercentages"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in AI Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-implementatiepakket inclusief Salesforce- en Genesys-integratie"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeling van aangepaste integratie"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datawarehouse-integratie"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks voor onmiddellijke melding van kritieke fouten"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewijde manager voor klantbegeleiding"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directe toegang tot onze product- en engineeringteams"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vroegtijdige toegang tot en invloed op onze roadmap"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische beoordelingsopdrachten en doelen stellen"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerichte coaching en analyses"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen voor leerbeheer"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van gebruikersrollen en machtigingen"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskering van gevoelige gegevens"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde filteropties en zoeken in gesprekken"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanpasbare scorekaarten met meerdere waarderingen, aanpasbare gewichten en opties voor automatisch niet slagen"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserextensie om gesprekken overal te beoordelen"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van hoofdoorzaken"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestatierapporten om agenten, teams en BPO's te vergelijken"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-enquêtes en analyses van klantenfeedback"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenmerken"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (oude versie)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meerdere waarderingsschalen per gesprek"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integratie"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-gedreven interactieve gespreksinzichten"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsopdrachten"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinnen voor coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingsessies"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentanalyse en filtering"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteren op basis van gesprekscomplexiteit"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespreksinzichten"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaardelijke scorekaarten"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse van hoofdoorzaken"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde AI-gedreven CSAT-analyse"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cijferkalibratie"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserextensie om gesprekken overal te beoordelen"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight om gesprekken te ontdekken die zeker moeten worden beoordeeld"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-demand vertalen van gesprekken"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingstijd registreren"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (oude versie)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbeperkt aantal gespreksbeoordelingen"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv-export"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer-to-peer-beoordelingen en zelfbeoordelingen"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten met aanpasbare gewichten en opties voor automatisch niet slagen"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robuuste filteropties"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijkse, wekelijkse of maandelijkse beoordelingsdoelen"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-enquêtes en basisanalyses"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporten om agenten, teams en BPO's te vergelijken"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van gebruikersrollen en -rechten"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenmerken"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil een wijziging aanbrengen in mijn Zendesk QA-abonnement.\n\nMarkeer één optie of duid deze aan en geef aan hoeveel licenties u wilt wijzigen:\nIk wil graag [X licenties toevoegen] [X licenties verwijderen] [upgraden naar het Zendesk QA-plan]\n\nDeel uw bedrijfsnaam en de naam die is gekoppeld aan uw Zendesk QA-account, evenals andere gegevens die wij moeten kennen om deze wijziging te verwerken."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging van abonnement op Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil een Zendesk QA-abonnement kopen.\n\nDeel uw bedrijfsnaam en de naam die is gekoppeld aan uw Zendesk QA-account, evenals andere gegevens die wij moeten kennen om dit verzoek te verwerken:\n\nDeel ook eventuele specifieke vragen die u beantwoord wilt zien voordat u overgaat tot de aankoop:\n\nHartelijk dank!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankoop van Zendesk QA-abonnement"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw abonnement eindigt op ", _interpolate(_named("date")), " (nog ", _interpolate(_named("days")), " over)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw proefperiode eindigt op ", _interpolate(_named("date")), " (nog ", _interpolate(_named("days")), " over)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dag"]), _normalize([_interpolate(_named("n")), " dagen"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op dag ", _interpolate(_named("dayOfMonth")), " van elke maand."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefactureerd aan ", _interpolate(_named("card")), " (vervalt ", _interpolate(_named("expires")), ") op ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eindigend op"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vervallen"]), _normalize(["Vervalt over 1 dag"]), _normalize(["Vervalt over ", _interpolate(_named("expiresIn")), " dagen"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startprijs omvat ", _interpolate(_named("n")), " gebruikerslicenties"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["begint bij ", _interpolate(_named("sum")), "/maand"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maand"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per gebruiker/maand"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per jaar"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per maand"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kwartaal"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de geplande annulering verwijderen"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u Zendesk QA wilt blijven gebruiken, moet u ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw abonnement werd geannuleerd op ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw abonnement zal vervallen op ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kosteloze proefperiode van ", _interpolate(_named("days")), " dagen!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikt"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt ", _interpolate(_named("n")), " gebruikers. Het aantal licenties kan niet lager zijn dan dat."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnement wordt hervat op ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaar"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw huidige Zendesk QA-plan (", _interpolate(_named("plan")), ") bevat geen AI-gedreven functies. Om de mogelijkheden van spraakintelligentie volledig te benutten, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neem als u uw abonnement wilt annuleren ", _interpolate(_named("link")), " contact op met ons of chat met ons."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan voor kwaliteitsbeheer"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwaliteitsbeheer"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement bijgewerkt"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een creditcard toevoegen"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle plannen"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturering"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factureringsgegevens bekijken"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factureringsinterval"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement annuleren"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw abonnement wordt geannuleerd aan het einde van de factureringsperiode."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op om te bewerken"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste prijzen"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgraden"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks (bespaar 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks betalen"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement bewerken"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannen verkennen"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis proefperiode"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig gefactureerd"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt het finale orderoverzicht controleren en bevestigen in de volgende stap."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populairst"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen actieve betalingsmethode."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op proef"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hervat uw huidige abonnement om het plan te wijzigen"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement pauzeren"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per kwartaal"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement opnieuw activeren"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement hervatten"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenties"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proefperiode nu starten"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-vernieuwend"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepauzeerd"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van abonnement"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proefversie"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraden"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement upgraden"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem een minuut de tijd om feedback over uw ervaring met de klantensupport te delen. Alvast bedankt — ", "{", "{", "agentName", "}", "}", " stelt uw feedback op prijs!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo ", "{", "{", "customerName", "}", "}", " !"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-enquête van ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe beoordeelt u de support die u hebt ontvangen van ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We stellen dit zeer op prijs. We gebruiken uw feedback om ", _interpolate(_list(0)), " te verbeteren voor iedereen."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over enquêtes"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het enquêtetype selecteren"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit kan later worden gewijzigd."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet en verbeter klanttevredenheid met automatische CSAT-enquêtes in meerdere talen."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen enquêtes gemaakt"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête maken"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtenaam"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is gemaakt"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Probeer het sleutelwoord te wijzigen of ", _interpolate(_named("contact_us")), " als u het hier vermeld wilt zien"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laat het ons weten"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen taal gevonden"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecteer een of meer talen uit de onderstaande lijst. ", _interpolate(_named("contact_us")), " als u een taal die u wilt gebruiken niet ziet staan."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen toevoegen"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat het ons weten"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal toevoegen"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Laatst gepubliceerd ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Laatst opgeslagen ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-vraag"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductievraag"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label schaal links"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailonderwerp"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenen"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label schaal rechts"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als er in een enquête vertalingen ontbreken, worden de teksten in de basistaal weergegeven"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertalingen toepassen"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbrekende vertalingen"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Controleer al uw vertalingen voordat u ze toepast. Breng wijzigingen aan in het gedownloade bestand, sla het op en upload het indien nodig opnieuw. Als een token ", _interpolate(_named("token_example")), " niet als tag wordt weergegeven, heeft deze een onjuiste indeling of staat deze in een veld dat geen ondersteuning biedt voor tokens."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertalingen controleren"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaald"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vat het gesprek samen en toont suggesties voor opmerkingen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI assist van Zendesk QA (in het Engels)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt typen bewerken nadat ze zijn gemaakt"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit type bestaat al. Selecteer een andere naam."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla het aangepaste type op als een selecteerbare optie in de vervolgkeuzelijst Enquêtetype en groepeer alle gemaakte enquêtes op het dashboard."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ woord(en) en druk op Enter om op te slaan"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste typen bewerken"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typenaam mag niet leeg zijn"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type definiëren"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepast type is niet opgeslagen. Druk op Enter om het op te slaan."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres van afzender"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van afzender"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In welke mate bent u het eens met de stelling: '", _interpolate(_named("agentName")), " heeft het gemakkelijk gemaakt om mijn probleem af te handelen'?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoe gemakkelijk was het tot nu toe om hulp krijgen van ", _interpolate(_named("brandName")), "?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe gemakkelijk was het over het algemeen om uw probleem op te lossen vandaag?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe gemakkelijk was het om met ons team te communiceren?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webadres voor privacybeleid (optioneel)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webadres voor servicevoorwaarden (optioneel)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoe beoordeelt u de support die u hebt ontvangen van ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe was onze service voor deze aanvraag?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe tevreden was u over de supportmedewerker?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoe tevreden bent u met de service die u vandaag hebt ontvangen van ", _interpolate(_named("agentName")), "?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste vraag"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definieer uw eigen aangepaste ", _interpolate(_named("type")), "-vraag..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ uw aangepaste vraag"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webadressen aanpassen"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel uw e-mail in"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de naam van een type aangepaste vraag bewerkt, wordt dit bijgewerkt in alle enquêtes die er gebruik van maken, zonder dat dit gevolgen heeft voor de verzamelde gegevens."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste typen bewerken"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailonderwerp"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductievraag"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-vraag"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw aangepast type maken"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Personalisatietoken"]), _normalize(["Personalisatietokens"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sleep het personalisatietoken naar het onderstaande veld."]), _normalize(["Sleep de personalisatietokens naar de onderstaande velden."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gesprek ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momenteel is het alleen mogelijk om CSAT-enquêtes via e-mail te verzenden. We werken eraan u meer mogelijkheden aan te bieden, zoals CSAT-enquêtes van de helpdesk, sms-enquêtes en meer."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["De verbinding synchroniseren of vernieuwen"]), _normalize(["De verbindingen synchroniseren of vernieuwen"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De verbinding synchroniseren of vernieuwen ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Verbinding is niet juist gesynchroniseerd"]), _normalize(["Verbindingen zijn niet juist gesynchroniseerd."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["het relevante selectievakje uitschakelen ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu authenticeren ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg minstens één verzendregel voor enquêtes toe om de verbinding te activeren"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze verbinding is momenteel uitgeschakeld. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze verbinding maskeert gevoelige gegevens van klanten. Als u het verzenden van enquêtes wilt inschakelen, moet u\n", _interpolate(_named("link")), " in de instellingen."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze verbinding vereist aanvullende machtigingen om enquêtes te verzenden.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu authenticeren"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA vereist meer machtigingen voor Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " en ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 uur"]), _normalize([_interpolate(_named("n")), " uur"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minuten"]), _normalize(["1 minuut"]), _normalize([_interpolate(_named("n")), " minuten"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["uur"]), _normalize(["uur"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klik op ", _interpolate(_named("link")), " om regels voor het verzenden van enquêtes te configureren."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dag"]), _normalize(["dagen"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtelinks vervallen"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links vervallen over"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkom het invullen van enquêtes als er te veel tijd is verstreken"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enquête ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheid de tags met komma's of druk na het schrijven op Enter. Zorg ervoor dat elke tag precies zoals in Intercom wordt vermeld."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel de voorwaarden in op basis waarvan de enquête wordt verzonden."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendregels voor enquêtes"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendregels voor follow-up van enquêtes"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een enquête '", _interpolate(_named("name")), "' ", _interpolate(_named("rules")), " bestaat al.\nLos het conflict op door de regels hier of in de ", _interpolate(_named("link")), " aan te passen."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U hebt al een verbinding met overeenkomende regels ingesteld voor enquête ", _interpolate(_named("name")), ". Los het conflict op door de regels hier of in de ", _interpolate(_named("link")), " aan te passen"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog een regel toevoegen"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg merk toe"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer voorwaarde"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een waarde"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag toevoegen"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die overeenkomt met dezelfde regels"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een waarde"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail wordt verzonden binnen"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up e-mail wordt verzonden binnen"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingebouwd fragment en follow-up e-mail"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code kopiëren"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg Zendesk QA-enquêtes toe aan uw e-mails door het codefragment aan uw Zendesk-configuratie toe te voegen."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installatiegids"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk openen"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld van fragment"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codefragment gekopieerd naar klembord"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiëren van fragment mislukt"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingebouwd fragment"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze enquête heeft wijzigingen die niet zijn opgeslagen. Sla opnieuw op om ervoor te zorgen dat de fragmentcode de nieuwste updates weerspiegelt."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding toevoegen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen verbindingen toegevoegd"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breng e-mails, live chat, telefoongesprekken en meer naar Zendesk QA met uw verbindingen"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over het leveren van enquêtes"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendregels voor enquêtes"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendregels voor follow-up van enquêtes"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkregel toevoegen"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel toevoegen"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticeren"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + follow-up via e-mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel uw Intercom-account aan CSAT Survey van Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezorgen via"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie ontvangt de enquête en wanneer"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regels bewerken"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met in-app enquêtes kunnen uw klanten een enquête invullen zonder de chat te verlaten."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom starten"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsregels per verbinding"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een enquête '", _interpolate(_named("name")), "' ", _interpolate(_named("rules")), " bestaat al.\nLos het conflict op door de verbinding hier of in de ", _interpolate(_named("link")), " uit te schakelen of de regels aan te passen"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De voorwaarden instellen op basis waarvan de enquête wordt verzonden"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gemaakt door ", _interpolate(_named("name")), " op ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), " verwijderen"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u deze taal verwijdert, is deze niet meer zichtbaar voor respondenten en worden alle vertaalde teksten verwijderd."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal verwijderen"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie kan niet ongedaan worden gemaakt."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of u kunt de taal ook uitschakelen door deze in de enquête te verbergen. Zo kunt u al uw vertalingen behouden zonder deze aan respondenten te laten zien."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laatst bewerkt door ", _interpolate(_named("name")), " op ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over enquêtetalen"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voordat u andere talen toevoegt, moet u ervoor zorgen dat u de laatste wijzigingen hebt aangebracht in de stappen Opmaak en Inhoud. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal toevoegen"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaalde velden"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaar voor respondenten"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaalbestand downloaden"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal verwijderen"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" een vertaalbestand downloaden"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vertalingen controleren"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vertaling voltooid. Klik op ", _interpolate(_named("review_link")), " of ", _interpolate(_named("download_link")), " om wijzigingen aan te brengen."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" een vertaalbestand downloaden"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bekijken wat er ontbreekt"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bepaalde inhoud moet nog worden vertaald en wordt vervangen door Engels. Klik op ", _interpolate(_named("review_link")), " of ", _interpolate(_named("download_link")), " om ontbrekende vertalingen toe te voegen."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla de enquête op"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De enquête bevat wijzigingen die nog niet zijn opgeslagen. ", _interpolate(_named("save")), " om het vertaalbestand met de laatste wijzigingen te downloaden."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaalbestand uploaden"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gewenste waarderingsschaal selecteren"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geeft klanten een vervolgkeuzelijst om een reden voor de waardering te selecteren"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji's"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst schaal links (optioneel)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cijfers"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg reden toe"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden mag niet leeg zijn"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze reden bestaat al"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervolgkeuzelijst weergeven met redenen voor uw score"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een reden verwijdert en opnieuw toevoegt met dezelfde naam of als u de naam van een reden wijzigt, wordt er altijd een nieuwe invoer gemaakt. Dit heeft dus gevolgen voor de gegevens die op uw dashboard worden weergegeven."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden verwijderen"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een verwijderde reden blijft aanwezig in de dashboardrapportage maar is niet zichtbaar voor nieuwe respondenten van enquêtes. Dit geldt ook voor de gerelateerde vertalingen."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een reden verwijderen"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Verwijder een reden '", _interpolate(_list(0)), "'"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst schaal rechts (optioneel)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op een 5-puntsschaal worden 1 en 2 als negatieve scores beschouwd; 3 is neutraal; 4 en 5 zijn positief"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst voor schaal toevoegen"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schaal van type ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingsschaal"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA is uw gesprekken aan het importeren. Dit proces kan 24 uur in beslag nemen."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit wordt voor de gehele enquête gebruikt als personalisatietoken."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merknaam"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knoppen en links"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas het uiterlijk van uw enquête aan"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo-achtergrond"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De indelingen jpg, png en gif worden ondersteund"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmaak"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo uploaden"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden in stijl"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een bericht op dat mensen zien nadat ze hun feedback hebben verzonden"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankbericht"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onjuiste hex-kleurcode"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onjuist e-mailadres"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld kan niet leeg zijn"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onjuist webadres"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet zichtbaar:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaar:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum waarop gemaakt"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes verzonden"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes die in de afgelopen 30 dagen zijn verzonden"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is verwijderd"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is gedupliceerd"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld-e-mail is verzonden"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is gepubliceerd"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is bijgewerkt"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête is onderbroken"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pauzeren"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête onderbreken"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het e-mailadres opgeven om het enquêtevoorbeeld te verzenden"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uw", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal selecteren voor voorbeeld"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een e-mailvoorbeeld verzenden"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur een voorbeeldenquête naar uw e-mailadres. De waarderingen van het enquêtevoorbeeld worden niet bewaard."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voorbeeld weergeven van de ", _interpolate(_named("type")), "-enquête"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld van e-mailenquête op het web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceren"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hervatten"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan als concept"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merknaam"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkspecifieke naam kan worden toegevoegd in de stap Opmaak"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van klant"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-id"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportvertegenwoordiger"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepauzeerd"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["CSAT-enquête van ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-opgeslagen wijzigingen aangebracht"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête bijwerken en hervatten"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u deze e-mail niet juist ontvangt: ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik was niet tevreden met de support die ik heb gekregen, omdat..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogelijk gemaakt door Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer reden"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekssamenvatting"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld: Klant ondervond problemen bij het bijwerken van de betalingsmethode. De agent heeft een artikel gedeeld dat mogelijk nuttig kan zijn. Het is gelukt."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogelijk gemaakt door AI van Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicevoorwaarden"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor uw feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is de belangrijkste reden voor uw score?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertel ons waarom u deze waardering hebt gegeven..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veel inspanning"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weinig inspanning"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klik hier om de enquête te openen"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw aangepaste vraag verschijnt hier"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["U hebt uw ervaring '", _interpolate(_list(0)), "' een score gegeven van"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback verzenden"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschrijven"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeer ontevreden"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeer tevreden"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelet, de huidige enquêtestatus en leveringsregels worden niet opgenomen in de duplicaatenquête"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Enquête '", _interpolate(_list(0)), "' dupliceren"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u het ingebouwde e-mailfragment gebruikt, verwijder het dan uit Zendesk voordat u de enquête onderbreekt"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er worden geen responsen verzameld als de enquête is onderbroken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête onderbreken"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u het fragment uit Zendesk verwijdert voordat u de enquête onderbreekt."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête verwijderen"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u het ingebouwde e-mailfragment gebruikt, verwijder het dan uit Zendesk voordat u de enquête verwijdert."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met deze actie wordt de enquête definitief verwijderd. Gebruikers die de enquête hebben ontvangen, kunnen deze nog steeds invullen en alle gegevens met betrekking tot de enquête blijven behouden in Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enquête ", _interpolate(_named("surveyName")), " verwijderen"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u het fragment uit Zendesk verwijdert voordat u de enquête verwijdert."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw aangebrachte wijzigingen zullen worden gezien door nieuwe respondenten."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête publiceren"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u de nieuwste versie van het ingebouwde fragment kopieert nadat u de enquête hebt gepubliceerd."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u het ingebouwde e-mailfragment gebruikt, kopieer het dan naar Zendesk nadat u de enquête hebt hervat."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga door met het verzenden van de enquête en het verzamelen van responsen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête bijwerken en hervatten"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u het ingebouwde fragment aan Zendesk toevoegt nadat u de enquête hebt hervat."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête bijwerken"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u de nieuwste versie van het ingebouwde fragment kopieert nadat u de enquête hebt bijgewerkt."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze enquête wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquête verwijderen?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw proefperiode is verlopen, maar u kunt blijven genieten van Zendesk QA door een upgrade uit te voeren naar een volledige account."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw proefperiode is verlopen, maar u kunt blijven genieten van Zendesk QA door een upgrade uit te voeren naar een volledige account. Neem contact op met uw lokale Administrator om dit in te stellen."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw proefperiode is geëindigd"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu upgraden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gratis proefperiode is geëindigd."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw betalingsgegevens op om de volledige versie van Zendesk QA te blijven gebruiken."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de volledige versie van Zendesk QA wilt blijven gebruiken, moet uw accountbeheerder betalingsgegevens opgeven."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling beheren"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een gebruiker"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gemaakt op ", _interpolate(_named("date")), " door ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " opdracht"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo te zien werkt u hier nog aan. Als u dit verlaat zonder de wijzigingen op te slaan, gaat alle informatie die u tot nu toe hebt toegevoegd, verloren."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze opdracht wordt om de andere ", _interpolate(_named("weekDay")), " herhaald"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze opdracht wordt elke ", _interpolate(_named("weekDay")), " herhaald"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alle beoordeelden in één cyclus"]), _normalize(["Alle beoordeelden in ", _interpolate(_named("nr")), " cycli"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gesprekken"]), _normalize([_interpolate(_named("n")), " gesprek"]), _normalize([_interpolate(_named("n")), " gesprekken"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["van ", _interpolate(_named("nr")), " agenten"]), _normalize(["van ", _interpolate(_named("nr")), " agent"]), _normalize(["van ", _interpolate(_named("nr")), " agenten"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["deelnemer"]), _normalize(["deelnemers"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gesprekken per cyclus"]), _normalize([_interpolate(_named("n")), " gesprek per cyclus"]), _normalize([_interpolate(_named("n")), " gesprekken per cyclus"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per beoordeelde op een rij"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["beoordeelde"]), _normalize(["beoordeelden"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["beoordelaar"]), _normalize(["beoordelaars"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelden"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspacegebruikers"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspacegebruikers behalve agenten"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cyclus ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["herhaalt niet"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Opdracht ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelen"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over opdrachten"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht maken"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met deze actie worden de opdracht en de gegevens ervan definitief verwijderd uit het dashboard. Alle gerelateerde beoordelingen blijven behouden in Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opdracht ", _interpolate(_named("assignmentName")), " verwijderen"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht verwijderd"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht gedupliceerd"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen opdrachten toegevoegd"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht maken mislukt"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van opdracht"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyclus wordt vernieuwd en er wordt een nieuwe takenlijst gemaakt. Reeds gegeven beoordelingen tellen nog mee voor het doel."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onmiddellijk"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceren"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aan het begin van de volgende cyclus op ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer wijzigingen publiceren?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen die in deze opdracht worden aangebracht, worden samen met deze opdracht gepubliceerd"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht instellen als actief?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaalt op de begindag van de opdracht"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste vrijdag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede vrijdag van elke maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde vrijdag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde vrijdag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste vrijdag van elke maand"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste maandag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede maandag van elke maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde maandag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde maandag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste maandag van elke maand"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste zaterdag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede zaterdag van elke maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde zaterdag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde zaterdag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste zaterdag van elke maand"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste zondag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede zondag van elke maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde zondag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde zondag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste zondag van elke maand"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste donderdag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede donderdag van de maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde donderdag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde donderdag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste donderdag van elke maand"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste dinsdag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede dinsdag van de maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde dinsdag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde dinsdag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste dinsdag van elke maand"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste woensdag van elke maand"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede woensdag van elke maand"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde woensdag van elke maand"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde woensdag van elke maand"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste woensdag van elke maand"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste herhaling"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1e van elke maand"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derde"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierde"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaalt"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkdag"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdzone"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyclusinstellingen"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van opdracht"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformatie"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan het begin van elke opdrachtencyclus wordt er een vooraf bepaalde lijst van gesprekken gegenereerd ter evaluatie."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde instellingen"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch nieuwe gesprekken ophalen wanneer het beoordelingsdoel niet is bereikt"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch ophalen is niet mogelijk bij beoordelingsdoelen in procenten (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moet een geheel getal zijn, geen decimaal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervanging van gesprekken toestaan (rol Beoordelaar en hoger)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken vervangen"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestaan dat beoordelaars hun eigen gesprekken ter beoordeling worden toegewezen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordelingen"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumaantal beoordelingen moet minimaal 1 zijn"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumaantal beoordelingen opgeven voor elke beoordeelde"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesprekken"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordelingen"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beoordelingen in totaal"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke beoordelaar moet het ingestelde aantal beoordelingen uitvoeren"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars moeten samen een bepaald aantal gesprekken beoordelen"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumaantal beoordelingen moet minimaal 1 zijn"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsdoel moet tussen 1 en 100% zijn"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingsdoel"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor elke beoordeelde moet een minimumaantal van hun totaalaantal gesprekken zijn beoordeeld"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars kunnen gesprekken kiezen uit de gedeelde pool"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars krijgen gesprekken toegewezen uit de gedeelde pool"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benadering aangaande opdrachten"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle deelnemers aan de workspace"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspacegebruikers"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelden toevoegen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie wordt beoordeeld?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer beoordeelden"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer wie u wilt beoordelen"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitsluitingen zijn alleen toegestaan wanneer alle workspacegebruikers of een groep zijn geselecteerd"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars en beoordeelden"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Huidig: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardinstelling van workspace"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welk type gebruikers moet als beoordeelden worden geselecteerd?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meest actieve agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemer"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelden uitsluiten (optioneel)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruikers om uit te sluiten"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars uitsluiten (optioneel)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruikers om uit te sluiten"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspacegebruikers behalve agenten"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars toevoegen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie doet de beoordelingen?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer beoordelaars"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen zijn niet toegestaan in deze workspace"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen toestaan"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen zijn niet toegestaan in deze workspace."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Overeenkomend gesprek vandaag"]), _normalize(["Overeenkomende gesprekken vandaag"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt weergegeven alsof de opdracht vandaag is begonnen."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstige cijfers kunnen variëren."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordeelden geselecteerd"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordelaars geselecteerd"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beoordeelde"]), _normalize(["Beoordeelden"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beoordelaar"]), _normalize(["Beoordelaars"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch ophalen: toegestaan indien niet genoeg gesprekken"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op vrijdag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op maandag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op zaterdag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op zondag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op donderdag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op dinsdag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " op woensdag vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dagelijks vanaf ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Begint op ", _interpolate(_named("startDateTime")), " en eindigt op ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " datumvoorwaarden"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " gesprek en helpdeskvoorwaarden"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen gesprek en ", _interpolate(_named("count")), " helpdeskvoorwaarde"]), _normalize(["Geen gesprek en ", _interpolate(_named("count")), " helpdeskvoorwaarden"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " gesprek en geen helpdeskvoorwaarden"]), _normalize([_interpolate(_named("count")), " gesprekken en geen helpdeskvoorwaarden"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benadering: beoordelaars kiezen gesprekken"]), _normalize(["Aanpak: beoordelaars krijgen gesprekken toegewezen"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los om door te gaan de fouten op die zijn gemarkeerd in de samenvatting"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel: beoordelaars moeten alle gesprekken beoordelen"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Doel: beoordelaars moeten één gesprek beoordelen"]), _normalize(["Doel: beoordelaars moeten ", _interpolate(_named("count")), " gesprekken beoordelen"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doel: beoordelaars moeten ", _interpolate(_named("count")), "% gesprekken beoordelen"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel: geen beoordelingsdoel ingesteld"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel: van elke beoordeelde moet elk gesprek worden beoordeeld"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doel: van elke beoordeelde moet(en) ", _interpolate(_named("count")), " van het totaalaantal gesprekken van de beoordeelde worden beoordeeld"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doel: van elke beoordeelde moet ", _interpolate(_named("count")), "% van het totaalaantal gesprekken van de beoordeelde worden beoordeeld"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Doel: elke beoordelaar moet één beoordeling uitvoeren"]), _normalize(["Doel: elke beoordelaar moet ", _interpolate(_named("count")), " beoordelingen uitvoeren"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vervangen: beoordelaars kunnen gesprekken niet vervangen"]), _normalize(["Vervangen: beoordelaars kunnen gesprekken vervangen"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfbeoordelingen: aan beoordelaars kunnen hun eigen gesprekken worden toegewezen"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen datumvoorwaarden ingesteld"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen naam van opdracht ingesteld"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaarden"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " vanaf ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweewekelijks"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagelijks"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wekelijks"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengte van cyclus"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellen als Actief"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan als concept"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht opgeslagen als concept"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht ingesteld als niet-actief"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellen als Niet-actief"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht maken"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht gemaakt"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als actief"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als niet-actief"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De updates voor de opdracht die in afwachting zijn, worden van kracht in de volgende cyclus, die begint op ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen publiceren"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-actief"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In afwachting"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht bijwerken"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht bijgewerkt"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent of bot het gesprek heeft afgerond."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent het gesprek beleefd heeft afgerond"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of het gesprek is afgerond"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluiting"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert grammatica-, spel- en stijlfouten in zowel agent- als botinteracties."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert grammatica-, spelling- en stijlfouten van agenten"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert grammatica-, spelling- en stijlfouten van agenten"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling en grammatica"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gesprek op begroetingen van de agent en bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op typische begroetingen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op typische begroetingen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begroeting"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent de gevoelens van de klant begrijpt en erkent"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificeert gevallen waarin de agent het sentiment van de klant overnam of zorgzame woorden zoals 'erkennen' en 'herkennen' heeft gebruikt"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent de vraag of het probleem van de klant begrijpt"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert of de agent de vraag of het probleem van de klant begrijpt"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrip"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert hoe gemakkelijk een tekst kan worden begrepen, rekening houdend met de woordcomplexiteit en de lengte van de zinnen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert hoe gemakkelijk een tekst kan worden begrepen, rekening houdend met de woordcomplexiteit en de lengte van de zinnen"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bericht bevat 1 erg lange zin, waardoor lezen een uitdaging is"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dit bericht bevat ", _interpolate(_list(0)), " erg lange zinnen, waardoor lezen een uitdaging is"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leesbaarheid"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op een aangeboden oplossing"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert het gehele gesprek op een aangeboden oplossing"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossing aangeboden"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert de toon van de agent tijdens het gesprek"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseert de toon van de agent tijdens het gesprek"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen fouten detecteren in berichten van agent"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen grammaticale fouten detecteren"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen zin voor begroeting gedetecteerd. Voeg indien nodig nieuwe toe via uw admin in Instellingen."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft het gesprek beleefd afgerond"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen afsluitende zin gedetecteerd. Voeg indien nodig nieuwe toe via uw admin in Instellingen"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft het sentiment van de klant overgenomen of heeft zorgzame woorden gebruikt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet detecteren of agent het sentiment van de klant heeft overgenomen of zorgzame woorden heeft gebruikt"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft lange zinnen en complexe woorden gebruikt, waardoor begrijpen voor sommigen mogelijk een uitdaging is"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft complexe woorden gebruikt, waardoor begrijpen voor sommigen mogelijk een uitdaging is"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft lange zinnen gebruikt, waardoor begrijpen voor sommigen mogelijk een uitdaging is"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent gebruikte taal die gemakkelijk te begrijpen is"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onvoldoende gegevens om deze categorie te waarderen"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent had moeite om de vraag/aanvraag van de klant te begrijpen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De agent heeft de vraag/aanvraag van de klant goed begrepen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet detecteren of de agent de vraag/aanvraag van de klant begreep"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon van agent tijdens gesprek"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon van agent tijdens gesprek"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon van agent tijdens gesprek"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon van agent tijdens gesprek"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon van agent tijdens gesprek"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan de toon van de agent niet bepalen"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek werd niet afgerond"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek werd afgerond"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet detecteren of gesprek werd afgerond"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent had geen empathie voor de klant en diens problemen"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent had empathie voor de klant en diens problemen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet detecteren of de agent empathie had voor de klant en diens problemen "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant is niet begroet tijdens het gesprek"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant is begroet tijdens het gesprek"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen typische begroeting detecteren"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft geen oplossing geboden aan de klant"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent heeft oplossing aangeboden aan klant"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet detecteren of agent een oplossing heeft aangeboden aan de klant"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complexe woorden"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange zinnen"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatica"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelfout"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatief"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuses"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalm"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrolijk"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwsgierig"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professioneel"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteunend"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stijl"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 afsluiting"]), _normalize([_interpolate(_named("n")), " afsluitingen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA controleert alleen op goedgekeurde afsluitingen. Als deze categorie is ingesteld als kritiek, zal de beoordeling niet geslaagd zijn als de goedgekeurde afsluitingen niet zijn gebruikt."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluitingen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde afsluitingen"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 vrijstelling"]), _normalize([_interpolate(_named("n")), " vrijstellingen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woorden en zinsdelen die AutoQA negeert als spel- of grammaticafouten."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijstellingen voor spelling en grammatica"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-vrijstellingen"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 begroeting"]), _normalize([_interpolate(_named("n")), " begroetingen"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA controleert alleen op goedgekeurde begroetingen. Als deze categorie is ingesteld als kritiek, zal de beoordeling niet geslaagd zijn als de goedgekeurde begroetingen niet zijn gebruikt."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroetingen"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurde begroetingen"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-categorie"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een AutoQA-categorie"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch toegewezen hoofdoorzaken:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account → Instellingen"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sommige categorieën maken gebruik van MS Azure, dat een uitgebreidere reeks functionaliteiten en ondersteunde talen biedt. Controleer uw ", _interpolate(_named("link")), " voor meer informatie, of neem hiervoor contact op met een admin."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krijg toegang tot een uitgebreide lijst categorieën en ondersteunde talen door gebruik te maken van MS Azure. Controleer uw ", _interpolate(_named("link")), " of neem hiervoor contact op met een admin."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA gebruiken"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aan AutoQA-categorieën wordt automatisch een score toegewezen, met behulp van een vaste waarderingsschaal. U kunt de waardering later handmatig aanpassen. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een AutoQA-categorie"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Een automatisch toegewezen score is afhankelijk van de ernst van de fouten en het aantal fouten. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen relevante waarderingscategorieën worden aan beoordelaars weergegeven. Waarderingscategorieën worden weergegeven op basis van de voorwaarden die u hebt ingesteld bij het maken van een categorie."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onboarding"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefoon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecteer het type waarderingscategorie voor workspace '", _interpolate(_named("workspace")), "'."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars moeten handmatig de juiste scorekaart selecteren om relevante waarderingscategorieën voor elk gesprek te zien."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u het type waarderingscategorie wilt wijzigen?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën wijzigen"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type waarderingscategorie wijzigen?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorwaardelijke waarderingscategorie"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statische waarderingscategorie"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type waarderingscategorie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen voor waarderingscategorie"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie kan niet ongedaan worden gemaakt."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle waarderingen worden verwijderd"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of u kunt uw statistieken ook bewaren zonder gegevens te verwijderen door een categorie te archiveren."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ik begrijp dat hiermee ", _interpolate(_named("deleteAllRatings")), " die zijn gegeven voor deze categorie."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. toon is perfect"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie alleen weergeven als"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Altijd' wordt genegeerd. Het kan niet worden gecombineerd met andere voorwaarden."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktags"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer voorwaarde"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevredenheidsscore (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brontype"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekskanaal"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een categorie heeft een naam nodig"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de categorie toe aan een scorekaart"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie maken"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie maken"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveren"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie toegevoegd"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gearchiveerde categorie is niet zichtbaar op de scorekaart, maar zo kunt u uw statistieken behouden zonder gegevens te verwijderen."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie archiveren"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Categorie ", _interpolate(_list(0)), " archiveren"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie gearchiveerd"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie verwijderen"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ 'categorie verwijderen' om deze categorie te verwijderen"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categorie verwijderen"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Categorie ", _interpolate(_list(0)), " verwijderen"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie verwijderd"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie gedupliceerd"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategoriegroep toegevoegd"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategoriegroep verwijderd"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgorde waarderingscategoriegroepen gewijzigd"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategoriegroep bijgewerkt"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie uit het archief gehaald"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingscategorie bijgewerkt"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als een agent niet is geslaagd voor een kritieke categorie, zullen automatisch alle categorieën in die beoordeling niet geslaagd zijn."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritieke waarderingscategorie"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen categorieën in deze groep"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe waarderingscategorie maken"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie bewerken"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle categorieën verwijderen en gegevens onder deze groep beoordelen"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de categoriegroep verwijdert, blijven alle categorieën die eraan zijn gekoppeld niet-gegroepeerd."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Categoriegroep ", _interpolate(_named("categoryName")), " verwijderen"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u ervoor kiest om de categorieën onder de groep te verwijderen, gaan alle beoordelingsgegevens verloren die aan deze categorieën zijn gekoppeld."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnaam"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars kunnen meer dan één reden als hoofdoorzaak selecteren"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars kunnen meer dan één reden als hoofdoorzaak selecteren"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meerdere redenen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijv. toon"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een nieuwe categoriegroep toevoegen"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe groep"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Overige' toevoegen"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaak toevoegen"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze hoofdoorzaak bestaat al"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaak toevoegen"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaken toevoegen om waardering uit te leggen"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarderingsschaal"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg scorekaarten toe aan deze waarderingscategorie"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveren ongedaan maken"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie bijwerken"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle waarderingen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergegeven voor"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatieve waarderingen"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatieve en neutrale waarderingen"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, bijwerken"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewicht"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën die belangrijker voor u zijn, kunnen meer gewicht hebben bij de berekening van de ticketscore."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gewicht (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over kalibratie"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over kalibratie"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geavanceerd"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door regelmatig kalibratiesessies te houden, kunnen beoordelaars hun waarderingstechnieken op elkaar afstemmen en ervoor zorgen dat agenten hetzelfde niveau van consistente en objectieve feedback ontvangen."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plannen weergegeven"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " is een proces dat uw team helpt op één lijn te blijven over de manier waarop interacties met klanten moeten worden afgehandeld en geëvalueerd."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibratie is beschikbaar voor onze plannen ", _interpolate(_list(0)), " en ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerder gegeven beoordelingen worden niet weergegeven wanneer een gesprek aan de sessie wordt toegevoegd."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet zichtbaar"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerder gegeven beoordelingen worden beschouwd als kalibratiebeoordelingen. Er wordt slechts één gespreksbeoordeling per beoordelaar aan de sessie toegevoegd. Berichtspecifieke beoordelingen worden niet weergegeven."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaar"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaarheid van eerder gegeven beoordelingen"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibratie is een proces dat uw team helpt op één lijn te blijven over de manier waarop interacties met klanten moeten worden afgehandeld en geëvalueerd. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers hebben onbeperkt toegang tot alle beoordelingen die tijdens een kalibratiesessie zijn gegeven."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd alle beoordelingen weergeven"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het indienen van een beoordeling zien gebruikers de evaluaties die andere beoordelaars hebben gegeven tijdens een kalibratiesessie."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibratiebeoordelingen bekijken na verzending van een beoordeling"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers hebben onbeperkt toegang tot alle beoordelingen die tijdens een kalibratiesessie zijn gegeven."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altijd alle beoordelingen weergeven"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het indienen van een beoordeling zien gebruikers de evaluaties die andere beoordelaars hebben gegeven tijdens een kalibratiesessie."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibratiebeoordelingen bekijken na verzending van een beoordeling"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het indienen van een beoordeling zien gebruikers de evaluaties die andere beoordelaars hebben gegeven tijdens een kalibratiesessie."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kalibratiebeoordelingen bekijken na verzending van een beoordeling"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers zien alleen hun eigen beoordelingen die tijdens een kalibratiesessie zijn gegeven."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen eigen beoordelingen weergeven"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie-instellingen bijgewerkt"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zichtbaarheid van kalibratiebeoordelingen"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel de drempel voor dashboardmetrics in door de gaten te vullen in overeenkomst met het interne metrics-systeem van uw teams. Controleer de drempel op het dashboard."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdrachten"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratie"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindingen"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drempel"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drempel bijgewerkt"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ogenblik geduld..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zorg ervoor dat u adminmachtigingen hebt en ", _interpolate(_named("retry")), ", of ", _interpolate(_named("contact")), " voor verdere hulp."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neem contact met ons op"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo te zien krijgen we geen toegang tot de API van uw helpdesk."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["probeer het opnieuw"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij koppelen uw helpdesk. U wordt dadelijk doorgestuurd."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " geautoriseerd"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ga terug naar uw ", _interpolate(_list(0)), " om een nieuwe helpdeskverbinding toe te voegen."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo te zien hebt u een stap of twee hebt gemist."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er bestaat al een organisatie met hetzelfde e-maildomein"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbindingenpagina"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de tussentijd..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een ogenblik geduld. Wij controleren uw machtigingen."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de tussentijd..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integreren..."])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een workspace maken"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u Zendesk QA wilt blijven gebruiken, moet u ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijna klaar voor gebruik"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspace gevonden"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van account wisselen"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u Zendesk QA wilt blijven gebruiken, moet uw manager u toewijzen aan een workspace."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u uw account verwijdert, worden alle gegevens die wij over u hebben definitief verwijderd - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle gegeven waarderingen, gespreksinhoud, workspaces enz."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loskoppelen"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de integratie loskoppelt, worden alle Zendesk QA-meldingen via Slack uitgeschakeld"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack loskoppelen"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integratie is losgekoppeld van Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer standaardinstellingen voor meldingen voor alle nieuwe accountgebruikers."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen voor alle gebruikers overschrijven"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U staat op het punt de meldingsinstellingen voor alle accountgebruikers te overschrijven"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers zullen hun eigen meldingsinstellingen niet kunnen definiëren."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingsinstellingen bijgewerkt"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwen wanneer er in een kritieke categorie niet wordt geslaagd"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwen wanneer CSAT een respons ontvangt"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggers"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webadres"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demoworkspace en -gegevens maken"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demogegevens opnieuw maken en demoworkspace behouden."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demoworkspace en -gegevens verwijderen"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demogegevens vernieuwen"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens vernieuwen"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Account '", _interpolate(_named("accountName")), "' beheren demomodus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demomodus"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op pagina blijven"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina verlaten"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze pagina verlaten en alle wijzigingen verliezen?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de opdracht voltooid"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht maken"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht voltooid"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opdrachten gevonden"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn momenteel geen taken die u kunt beoordelen."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de opdracht aan of wacht tot de volgende cyclus begint."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer de filters aan te passen"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht bewerken"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houd deze pagina in de gaten voor updates"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen taken voor deze cyclus"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken weer te geven"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taak markeren als voltooid"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt deze gebruiker beoordeeld."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen zelfbeoordelingen in deze opdracht"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe door op het kalibratiepictogram in de kop van de gespreksweergave te klikken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gesprekken toegevoegd"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht maken"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibratiesessie maken"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw opdrachten"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere opdrachten"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actieve opdrachten"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen opdrachten"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordeelden"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordeelden behalve ik"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordeelden gevonden"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde zoeken"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle statussen"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordelaars"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle beoordelaars behalve ik"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen aan mij"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordelaars gevonden"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar zoeken"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagen"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagen"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dag"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagen"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagen"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dagen"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " uur"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuten"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuten"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuut"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuten"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuten"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuten"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.v.t."])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw toewijzen"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspacegebruikers"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige beoordelaar"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw doel voor deze cyclus"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe beoordelaar"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer beoordelaar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken opnieuw toewijzen"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek opnieuw toewijzen"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken die moeten worden verplaatst"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige cyclus"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht bewerken"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordelaars gevonden"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek beoordelaar"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken opnieuw aan mij toewijzen"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken opnieuw toewijzen aan andere gebruiker"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaars"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dagen"]), _normalize([_interpolate(_named("n")), " dag"]), _normalize([_interpolate(_named("n")), " dagen"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uur"]), _normalize([_interpolate(_named("n")), " uur"]), _normalize([_interpolate(_named("n")), " uur"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide cycli verbergen"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide sessies verbergen"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw doel"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een reden toe om dit gesprek te verwijderen"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg uit waarom u dit gesprek verwijdert"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u de wijzigingen behouden?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een reden toe om dit gesprek te vervangen"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen ongedaan maken"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behouden"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen behouden"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervanging niet gevonden"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg uit waarom u dit gesprek vervangt"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u dit gesprek toch verwijderen uit de opdracht? Hiermee wordt het doel met 1 verlaagd."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervangen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek vervangen"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept verwijderen"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept bewerken"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw toewijzen aan mij"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw toewijzen aan andere gebruiker"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw toewijzen en beoordelen"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek verwijderen"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat een beoordeling achter"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder weergeven"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer weergeven"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doel: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " beoordeeld"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide beoordelingen"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide cycli weergeven"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide sessies weergeven"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterende tijd"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taken"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek is vervangen"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takenlijst in-/uitschakelen"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " later"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " seconde"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " seconden"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "u."])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "u. ", _interpolate(_named("minutes")), "m."])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dagen"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("minutes")), "m."])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "u."])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "u. ", _interpolate(_named("minutes")), "m."])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "u. ", _interpolate(_named("minutes")), "m. ", _interpolate(_named("seconds")), "s."])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "u. ", _interpolate(_named("seconds")), "s."])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m."])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m. ", _interpolate(_named("seconds")), "s."])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " maand(en)"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s."])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "j"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zojuist"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U beschikt niet over de juiste machtigingen. Neem contact op met uw administrator voor hulp."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die u zoekt, bestaat niet."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onverwachte fout opgetreden. Neem contact op met support voor hulp."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geweigerd. Deze actie uitvoeren is niet toegestaan."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die u zoekt, bestaat niet of u hebt er geen toegang toe."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U gaat iets te snel en we hebben wat moeite om u bij te houden."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opgeslagen"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-opgeslagen wijzigingen aangebracht"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " toegepast"]), _normalize([_interpolate(_named("n")), " toegepast"]), _normalize([_interpolate(_named("n")), " toegepast"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt op"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt door"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritiek"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Voormalig lid"]), _normalize(["een voormalig lid"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " geselecteerd"]), _normalize([_interpolate(_named("n")), " geselecteerd"]), _normalize([_interpolate(_named("n")), " geselecteerd"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst bijgewerkt"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... en nog 1"]), _normalize(["... en nog ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+ nog 1"]), _normalize(["+ nog ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieuw"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is/zijn niet"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " reacties"]), _normalize([_interpolate(_named("n")), " reactie"]), _normalize([_interpolate(_named("n")), " reacties"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vandaag"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruiker"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vrijdag"]), _normalize(["Vrijdagen"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Maandag"]), _normalize(["Maandagen"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Zaterdag"]), _normalize(["Zaterdagen"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Zondag"]), _normalize(["Zondagen"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Donderdag"]), _normalize(["Donderdagen"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dinsdag"]), _normalize(["Dinsdagen"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Woensdag"]), _normalize(["Woensdagen"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gisteren"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["een admin"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Accountmanager"]), _normalize(["een accountmanager"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker"]), _normalize(["een gebruiker"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw sessie is verlopen."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingen"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld kan niet leeg zijn."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen items gevonden."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen overeenkomende personen."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eigenaar"]), _normalize(["een eigenaar"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees voordat we beginnen onze voorwaarden en ga ermee akkoord."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzonden"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeker?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen voltooid in het algemeen"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["een agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["een lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager"]), _normalize(["een manager"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Beoordelaar"]), _normalize(["een beoordelaar"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonieme gebruiker"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveren"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnenkort"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om te kopiëren"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopiëren"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gevarenzone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weigeren"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving (optioneel)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestiging vereist"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschakelen"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-opgeslagen wijzigingen ongedaan maken?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschakelen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteren"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequentie"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doel"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik snap het"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigen"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIK"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slagingspercentage"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke instellingen"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder lezen"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw verzenden"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstellen"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeelde"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen ontvangen/gezien"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen uitgevoerd door lid van workspace"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binaire schaal"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-puntsschaal"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-puntsschaal"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-puntsschaal"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaart"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer frequentie"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder weergeven"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer weergeven"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overslaan"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stap"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtefeedback"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdbereik"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-opgeslagen wijzigingen aangebracht"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewicht"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["U bent uitgenodigd door ", _interpolate(_list(0)), " om lid te worden van Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruikers worden automatisch verbonden vanuit het Beheercentrum. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers beheren"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over gebruikersbeheer"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sommige gebruikers worden automatisch ingericht en gesynchroniseerd vanuit uw identiteitsprovider. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Accountmachtiging van '", _interpolate(_list(0)), "' gewijzigd"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over gebruikersmachtigingen"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet opnieuw vragen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle wijzigingen die op de gebruikerspagina worden aangebracht, worden weerspiegeld in de workspaces. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit betekent dat de toegang van de gebruiker tot bepaalde onderdelen van Zendesk QA kan veranderen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtigingen op accountniveau wijzigen"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker verwijderd"]), _normalize([_interpolate(_named("n")), " gebruikers verwijderd"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wanneer u een gebruiker verwijdert, raakt deze de toegang tot uw account kwijt. Hun beoordelingen, opmerkingen en waarderingen blijven behouden in Zendesk QA. ", _interpolate(_named("linebreak")), "Het verwijderen van een gebruiker heeft geen gevolgen voor het aantal licenties in uw abonnement."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Deze gebruiker wordt verwijderd uit alle workspaces die u beheert. ", _interpolate(_named("linebreak")), " De beoordelingen, opmerkingen en waarderingen van de gebruiker blijven behouden."]), _normalize(["Ze worden verwijderd uit alle workspaces die u beheert. ", _interpolate(_named("linebreak")), " Al hun beoordelingen, opmerkingen en waarderingen blijven behouden."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " verwijderen"]), _normalize([_interpolate(_named("name")), " gebruikers verwijderen"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan alles zien en alle instellingen en facturering beheren"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan alles zien en persoonlijke instellingen en workspace-instellingen beheren, behalve facturering"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountmachtigingen"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met workspace-specifieke machtigingen kunnen gebruikers gegevens bekijken (en) bewerken in workspaces waarin ze zich bevinden."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bots verschijnen hier wanneer gesprekken waaraan ze hebben deelgenomen, worden gesynchroniseerd"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bots gesynchroniseerd"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een bot als gebruiker markeert, krijgt deze toegang tot aanmelden. Hun historische botgegevens blijven beschikbaar op dashboards."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dergelijke bots zijn niet gevonden"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer opnieuw te zoeken met een ander sleutelwoord of een andere spelling."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek bots"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op botnaam"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als gebruiker"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen admins en accountmanagers kunnen bots als gebruikers markeren"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botnaam"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig gemarkeerd"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan worden beoordeeld"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De bot wordt verwijderd uit automatische analyse en toewijzingen. Nieuwe gegevens worden niet weergegeven in dashboards, voor nauwkeurige rapportage en eenvoudiger botbeheer."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generatieve bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflowbot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan groep(en)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle groepen"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groep"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen uit groep(en)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer groep"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountmachtiging"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan workspace(s)"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volledige naam"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst actief"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol op accountniveau"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke gebruiker krijgt een machtiging op account- en/of workspaceniveau toegewezen. Adminmachtigingen zijn nu op accountniveau, terwijl andere machtigingen op het workspaceniveau zitten."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen uit workspace(s)"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacemachtiging"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtigingen beheerd in groep"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details bewerken"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeren als bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een gebruiker als bot markeert, wordt de toegang tot aanmelden ingetrokken. Hun historische gebruikersgegevens blijven beschikbaar op dashboards."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder de bot uit automatische analyse en toewijzingen. Nieuwe gegevens worden niet weergegeven in dashboards, voor nauwkeurige rapportage en eenvoudiger botbeheer."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot uitsluiten van beoordelingen"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker gemarkeerd als bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen machtigingstype Gebruiker kan worden gemarkeerd als bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers met meerdere accounts kunnen niet als bot worden gemarkeerd"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op gebruiker"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers, bots en workspaces"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WORKSPACES"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers en workspaces"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen machtigingen"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatig toegevoegd"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In afwachting"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging in afwachting"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-gebruikers"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie vereist"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...en nog een"]), _normalize(["...en nog ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtiging wijzigen in"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Verwijder en nodig opnieuw uit om rol te wijzigen van ", _interpolate(_named("n")), " gebruiker met uitnodiging in afwachting."]), _normalize(["Verwijder en nodig opnieuw uit om rol te wijzigen van ", _interpolate(_named("n")), " gebruikers met uitnodiging in afwachting"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Details bewerken voor ", _interpolate(_named("name"))]), _normalize(["Details bewerken voor ", _interpolate(_named("n")), " geselecteerde gebruikers"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker bijgewerkt."]), _normalize([_interpolate(_named("n")), " gebruikers bijgewerkt."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Deze groep bevat 1 gebruiker uit een andere workspace."]), _normalize(["Deze groep bevat ", _interpolate(_named("n")), " gebruikers uit een andere workspace."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeren"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' gearchiveerd"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerde groep"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep maken"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' gemaakt"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naamloze groep"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze groep definitief verwijderen?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep verwijderen"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep verwijderen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep bewerken"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsleider"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsrol"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' verborgen"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze groep is nergens anders in Zendesk QA zichtbaar"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen machtigingen voor workspaces beheren"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep niet zichtbaar in Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naamloze groep"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een andere groep heeft deze naam al"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gearchiveerde groep heeft deze naam al"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met uw rol kunt u alleen leden van deze groep beheren. Neem contact op met een gebruiker met meer machtigingen voor verdere wijzigingen."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen als groepsleider"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' hersteld"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' opgeslagen"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellen als groepsleider"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' uit het archief gehaald"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-opgeslagen wijzigingen"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg uw bestaande gebruikers toe aan deze groep"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op naam of e-mailadres"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden toevoegen"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groep '", _interpolate(_named("name")), "' zichtbaar"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elk lid is ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over groepen"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepaalde kenmerken van groepen worden automatisch ingericht en gesynchroniseerd vanuit uw identiteitsprovider."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie over groepen"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep maken"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiseer eenvoudig uw teamleden en boots uw organisatiestructuur na."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg minstens 1 lid toe"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een groepsnaam toe"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er bestaat al een groep met deze naam"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsleden"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnaam"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organiseer uw teamleden en boots uw organisatiestructuur na."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen groepen"]), _normalize(["1 groep"]), _normalize([_interpolate(_named("count")), " groepen"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe groep of activeer in plaats daarvan een gearchiveerde groep."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen actieve groepen beschikbaar"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gearchiveerde groepen"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dergelijke groepen zijn niet gevonden"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer opnieuw te zoeken met een ander sleutelwoord of een andere spelling."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen toegevoegd"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["in deze"]), _normalize(["in"]), _normalize(["in"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op groepsnaam"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Opnieuw verzenden mogelijk ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licenties over"]), _normalize([_interpolate(_named("n")), " licentie over"]), _normalize([_interpolate(_named("n")), " licenties over"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het aantal licenties is momenteel beperkt, omdat uw abonnement is gepauzeerd. Activeer het opnieuw om meer gebruikers toe te voegen."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de limiet van uw accountlicenties bereikt. U kunt gebruikers vervangen door een actieve gebruiker te deactiveren of meer licenties toe te voegen."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens weergeven"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " geselecteerd"]), _normalize([_interpolate(_named("n")), " geselecteerd"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker geselecteerd"]), _normalize([_interpolate(_named("n")), " gebruikers geselecteerd"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " rijen"]), _normalize([_interpolate(_named("n")), " rij"]), _normalize([_interpolate(_named("n")), " rijen"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek gebruikers bulksgewijs op naam of e-mailadres."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak hier gegevens gescheiden door komma's of nieuwe regels"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers bulksgewijs zoeken"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Workspacemachtigingen bewerken voor ", _interpolate(_named("name"))]), _normalize(["Workspacemachtigingen bewerken voor ", _interpolate(_named("n")), " geselecteerde leden"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machtigingen bewerken"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacemachtiging selecteren"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " verwijderen uit workspace"]), _normalize([_interpolate(_named("name")), " leden verwijderen uit workspace"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " lid verwijderd"]), _normalize([_interpolate(_named("n")), " leden verwijderd"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lid verwijderen"]), _normalize(["Leden verwijderen"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Deze gebruiker wordt verwijderd uit de workspace."]), _normalize(["Deze gebruikers worden verwijderd uit de workspace."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Workspacemachtiging van '", _interpolate(_list(0)), "' gewijzigd."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden toevoegen"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken en gerelateerde beoordelingen bekijken"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles bekijken in de workspace, maar enkel kwissen, opdrachten, geschillen en kalibratiesessies beheren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacemachtigingen"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in de workspace bekijken en beheren"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles bekijken in de workspace, behalve de workspace-instellingen"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikers koppelen"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers van de workspaces die u beheert, zijn aan deze workspace toegevoegd."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag een admin om dit te doen."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig nieuwe gebruikers zelf uit of vraag een admin om dit te doen."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " of ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers weer te geven"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " gebruiker toegevoegd"]), _normalize([_interpolate(_named("n")), " gebruikers toegevoegd"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Leden toevoegen"]), _normalize(["Lid toevoegen"]), _normalize([_interpolate(_named("n")), " leden toevoegen"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspacemachtiging selecteren"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruikers voor de workspace"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Leden toevoegen aan ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak e-mailadressen hier, gescheiden door nieuwe regels of komma's"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Overeenkomst met ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" met al verbonden gebruikers:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Geen overeenkomst voor ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de spelling of verbind ze later afzonderlijk"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" met helpdeskgebruikers:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker verbonden"]), _normalize([_interpolate(_named("n")), " gebruikers verbonden"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker uitgenodigd"]), _normalize([_interpolate(_named("n")), " gebruikers uitgenodigd"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulksgewijs verbinden"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulksgewijs uitnodigen"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijvoorbeeld:\n\nkatrien", "@", "voorbeeld.com, jan", "@", "voorbeeld.com\n\nkatrien", "@", "voorbeeld.com\nkatrien", "@", "voorbeeld.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak verbinding vanuit uw helpdesk(s) om hun interacties te beoordelen"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker koppelen"]), _normalize(["Gebruikers koppelen"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel koppelen"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u wilt dat uw verbonden gebruikers toegang hebben tot Zendesk QA, kunt u ze hier uitnodigen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden gebruikers uitnodigen"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker uitnodigen"]), _normalize(["Gebruikers uitnodigen"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigen overslaan"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruikers om te verbinden vanuit uw helpdesk(s) om hun interacties te beoordelen"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw abonnement beheren"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdeskgebruikers koppelen"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef uw workspace een unieke naam"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien ingeschakeld, worden alle bestaande accountverbindingen automatisch aan de nieuwe workspace toegevoegd."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbindingen aan deze workspace toevoegen"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace maken"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van workspace"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace maken"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers die rechtstreeks aan de account zijn toegevoegd (zonder een workspace toe te wijzen) krijgen een rol op accountniveau toegewezen. U kunt ze later aan workspaces toevoegen."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan uitgenodigde gebruikers wordt automatisch een gebruikersrol op accountniveau toegewezen. U kunt ze later upgraden naar een adminrol."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze werken allemaal"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plak e-mails hier, gescheiden door nieuwe regels of komma's."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gebruiker uitgenodigd via e-mail"]), _normalize([_interpolate(_named("n")), " gebruikers uitgenodigd via e-mail"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers die aan een workspace worden toegevoegd, krijgen automatisch een gebruikersrol op het accountniveau toegewezen. U kunt ze later upgraden naar een adminrol."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog een toevoegen"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig gebruikers alleen uit voor de account zonder ze toe te voegen aan een workspace"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan uitgenodigde gebruikers wordt alleen een accountmachtiging toegewezen en zij worden aan geen enkele workspace toegevoegd."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodig gebruikers rechtstreeks uit in een workspace en wijs workspacemachtigingen toe"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers toevoegen aan workspace"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulksgewijs uitnodigen"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel uitnodigen"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel voor de account uitnodigen"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor een workspace uitnodigen"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Gebruikers uitnodigen"]), _normalize(["Gebruiker uitnodigen"]), _normalize([_interpolate(_named("n")), " gebruikers uitnodigen"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers bulksgewijs uitnodigen via e-mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker verwijderen"]), _normalize(["Gebruikers verwijderen"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectie opheffen"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers uitnodigen via e-mail"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspaces"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer machtiging"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodiging opnieuw verzenden"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden gebruiker"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker met uitnodiging per e-mail in afwachting"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-gebruiker"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer workspace"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI agenten"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheercentrum"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwaliteitsborging"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personeelsbeheer"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Voltooid ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Voltooid ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Voltooid ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cyclus ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelaar"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdracht bewerken"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespaar tijd en stel automatische beoordelingsopdrachten in, in plaats van gesprekken handmatig te zoeken en toe te wijzen ter beoordeling."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deadline: ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies kunnen worden toegevoegd in het gedeelte 'Kalibratie' in het zijmenu van de gespreksweergave."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen sessies toegevoegd"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basislijn"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprekken kunnen aan een sessie worden toegevoegd door op het kalibratiepictogram in de kop van de gespreksweergave te klikken."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen gesprekken toegevoegd"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er zijn geen beoordelingen gegeven. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een kalibratiebeoordeling achterlaten"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoofdoorzaak"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden van command palette mislukt."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets gevonden."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gevonden op id"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command palette laden…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina’s, filters, gesprekken, instellingen…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesprek"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privéfilter"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openbare filter"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Van ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van uw API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Weergegeven: ", _interpolate(_named("rangeStart")), " tot ", _interpolate(_named("rangeEnd")), " van ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Weergegeven: ", _interpolate(_named("rangeStart")), " tot ", _interpolate(_named("rangeEnd")), " van ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijvenster verbergen"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijvenster weergeven"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijbalk in-/uitschakelen"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerde geschillen"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgewezen geschillen"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open geschillen"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeeltelijk geaccepteerde geschillen"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooide beoordelingen"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen beoordelingen"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekeken beoordelingen"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geaccepteerd"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geopend"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gedeeltelijk geaccepteerd"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " van totaal"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgewezen"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerd"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeeltelijk geaccepteerd"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgewezen"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["beoordeling"]), _normalize(["beoordelingen"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal van hun beoordelingen dat is gezien door beoordeelden"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal van hun beoordelingen dat is gezien"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betwiste beoordelaars"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betwisters"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen per categorie"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschillen in de loop van de tijd"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde oplossingstijd"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde oplossingstijd (u.)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde oplossingstijd (min.)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelost"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalaantal geschillen"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geaccepteerd"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal van respons"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen talen gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle talen"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorspelde drijfveren"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen drijfveren gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle drijfveren"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woorden van woordenwolk"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen woorden gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle woorden"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeldingen"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwste"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudste"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedeeltelijk geaccepteerd"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " heeft gereageerd met ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgewezen"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgelost"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordelingen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidskleur selecteren"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies gevonden"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen categorieën gevonden"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaat van opmerking"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen formaten gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle formaten"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen filters gevonden"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle groepen"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hashtags gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen waarden gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle waarden"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen aangepaste velden gevonden"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpdesktags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen helpdesktags gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle helpdesktags"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten laden..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraagtypen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen vraagtypen gevonden"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactie toevoegen"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorekaarten"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen scorekaarten gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scorekaarten"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen scores gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle scores"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronnen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bronnen gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bronnen"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden van feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen redenen gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle redenen"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen enquêtes gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle enquêtes"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen kanalen gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kanalen"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen workspaces gevonden"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle workspaces"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar Kwissen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kwis is verwijderd of nog niet gepubliceerd. Controleer de link of ga terug naar de kwislijst."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze kwis is niet gedeeld met een workspace waartoe u behoort. Vraag een admin u toe te voegen aan een workspace waartoe deze kwis toegang heeft."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis niet beschikbaar"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwistoegang verleend aan workspace(s) waar u geen deel van uitmaakt. Kan kwis niet opslaan en publiceren."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag een admin om de kwis op te slaan en te publiceren."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet ten minste één van de antwoorden als de juiste markeren."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld kan niet leeg zijn."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ziet momenteel een voorbeeld van de kwis."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer welke workspaces toegang hebben tot de kwis. Gebruikers die tot meerdere workspaces behoren, kunnen de kwis slechts één keer beantwoorden. Als u 'Alle workspaces' selecteert, worden nieuwe workspaces niet opgenomen nadat de kwis is gepubliceerd."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwistoegang"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveren"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeldmodus afsluiten"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vragen"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsen"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe de kwis"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis publiceren"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen van kwis"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan als concept"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe kwis maken"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiveren ongedaan maken"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opties"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optie toevoegen"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deel de kwis"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziet er mooi uit, nietwaar?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw antwoord is verzonden"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw kwis is opgeslagen"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw kwis is gepubliceerd"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw kwis is verwijderd"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag toevoegen"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis archiveren"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gearchiveerde kwis en de resultaten ervan worden niet meer weergegeven in de lijst met kwissen. U hebt nog steeds toegang tot de resultaten van een gearchiveerde kwis en u kunt het archiveren op elk moment ongedaan maken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis archiveren"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiceren"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenmaal gepubliceerd kan een kwis niet meer worden gewijzigd of bewerkt. Alle gepubliceerde kwissen zijn toegankelijk voor elke gebruiker van uw account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis publiceren?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze kwis wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis verwijderen?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenmaal verzonden, kunnen uw antwoorden niet meer worden gewijzigd."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis verzenden?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis uit het archief halen"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u de kwis uit het archief haalt, is deze beschikbaar in het kwisoverzicht. Hiermee krijgen eerdere deelnemers toegang tot de resultaten en kunnen nieuwe deelnemers de kwis beantwoorden."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis uit het archief halen"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag dupliceren"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving (optioneel)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag naar beneden verplaatsen"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag omhoog verplaatsen"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen vragen met antwoorden worden in het voorbeeld weergegeven."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag verwijderen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis verzenden"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naamloze kwis"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel van kwis"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden de kwis en de resultaten ervan definitief verwijderd."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of behoud de toegang tot de resultaten door de kwis te archiveren."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantwoord"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis verwijderen"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee worden de kwis en de resultaten ervan definitief verwijderd."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kwis ", _interpolate(_list(0)), " verwijderen"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliceren"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen helpen het vertrouwen en de vaardigheden van het team te vergroten, consistente ervaringen te leveren en nieuwe teamleden te onboarden."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt kwissen zoeken op kwisnaam of op naam van de auteur."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen kwissen gemaakt"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst met kwissen exporteren"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem. score"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van voltooiing"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis/auteur"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis / Auteur / Workspaces"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deelnemers"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepubliceerd"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op kwis, auteur"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gearchiveerd"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle kwissen"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis is gearchiveerd."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis is uit het archief gehaald."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze kwis heeft geen responsen ontvangen van gebruikers in de geselecteerde workspace(s)."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze kwis heeft geen responsen ontvangen voordat deze werd gearchiveerd."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen responsen"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ziet u een overzicht van de antwoorden van de kwis.\nEr heeft nog niemand uw kwis ingevuld. Het zou zeker helpen als u de kwis zou delen."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen responsen"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsen:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde score:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Kopie"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link gekopieerd"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten gevonden"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kwissen van ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt op het leaderboard zoeken naar de naam van de deelnemer."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard exporteren"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwis"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op deelnemer"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwissen"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard in-/uitschakelen"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit betekent dat u niet meer op deze hashtag kunt filteren op het dashboard en dat deze niet meer automatisch wordt aangevuld bij opmerkingen. Deze actie kan niet ongedaan worden gemaakt."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags maken"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags maken"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags maken"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestaan dat leden nieuwe hashtags maken"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikt"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag verwijderen"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag ", _interpolate(_list(0)), " verwijderen"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken van hashtags is vergrendeld"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maken van hashtags is ontgrendeld"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag '", _interpolate(_list(0)), "' verwijderd"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Naam hashtag '", _interpolate(_list(0)), "' gewijzigd in '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag toegevoegd"]), _normalize([_interpolate(_named("count")), " hashtags toegevoegd"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe naam"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hashtags gemaakt"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam wijzigen"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naam van hashtag '", _interpolate(_named("tagRenamed")), "' wijzigen"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheid hashtags met komma's of regeleinden"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geen zorgen. Dat kan ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding is gekoppeld aan workspace"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding bijvoegen"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een verbinding toe aan deze workspace"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met uw admin om hier wijzigingen aan te brengen"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met Zendesk QA om hier wijzigingen aan te brengen"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen verbindingen onder account"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo te zien hebt u nog niet geïntegreerd met een klantenserviceplatform."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u een verbinding verwijdert, worden gesprekken uit de workspace ", _interpolate(_named("workspaceName")), " verwijderd, alsook alle daaraan gerelateerde gegevens, waaronder beoordelingen en geschillen."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding verwijderen"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verbinding ", _interpolate(_named("connectionName")), " verwijderen"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een verbinding"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Binnen de gekozen tijdperiode waren bepaalde actieve AutoQA-categorieën niet aanwezig voor uw scorekaart. Gesprekken voorafgaand aan uw laatste wijziging op ", _interpolate(_named("date")), ", zijn mogelijk niet geanalyseerd voor deze categorieën."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier verschijnt alle feedback die aan u wordt gegeven. Nog niets te zien."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontvangen beoordelingen."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen activiteit met opmerkingen weergeven"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier verschijnt alle feedback die u geeft. Ga aan de slag in de weergave Gesprekken."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beoordelingen gegeven."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegeven beoordelingen"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangen beoordelingen"])}
  }
}