import { ref, computed, watch, reactive, markRaw } from 'vue'
import { useRoute, useRouter, type RouteLocationRaw } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import { type StateChanger } from 'v3-infinite-loading'

import type {
  QuestionType,
  GetFeedbackReceivedResponse_Feedback as SurveyFeedbackResponse,
  GetFeedbackReceivedRequest as SurveyFeedbackParams,
} from '@zendesk/zqa-services/surveys'
import type { ReceivedReaction } from '@zendesk/zqa-services/reviews'

import {
  AlertCircleIcon,
  InboxIcon,
  ListIcon,
  MessageCircleIcon,
  SmileIcon,
  ThumbsUpIcon,
  UsersIcon,
} from '@klausapp/ui-kit/icons'
import i18n from '@/i18n'

import { toast } from '@/components/toast'
import alertDialog from '@/components/alertDialog'
import SendIcon from '@/assets/send-icon.svg'
import type { Member } from '@/modules/dashboard/types'
import { bus } from '@/utils/bus'
import { reloadActivitiesCount } from '@/composables/useActivitiesCount'
import useQuestionTypes from '@/composables/useQuestionTypes'
import { track } from '@/utils/analytics'

import {
  getReviews,
  getComments,
  getReactions,
  getSurveyFeedback,
  getDisputes,
  markAllDisputesRead,
  markAllReviewsRead,
  markAllCommentsRead,
} from '@/modules/activity/api'
import type {
  Review,
  Comment,
  Dispute,
  CommentListParams,
  ReviewListParams,
  DisputeListParams,
  ActivityType,
  ReactionListParams,
} from '@/modules/activity/types'
import { type statuses, SortValues } from '@/modules/activity/components/utils'
import ReviewItem from '@/modules/activity/components/ReviewItem.vue'
import CommentItem from '@/modules/activity/components/CommentItem.vue'
import FeedbackItem from '@/modules/activity/components/FeedbackItem.vue'
import DisputeItem from '@/modules/activity/components/DisputeItem.vue'
import ReactionItem from '@/modules/activity/components/ReactionItem.vue'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { useEvent } from './useEvent'

type CommentStatusKeys = keyof typeof statuses.comments
type DisputeStatusKeys = keyof typeof statuses.disputes
interface List {
  isLoading: boolean
  items: Comment[] | Review[] | Dispute[] | SurveyFeedbackResponse[] | ReceivedReaction[]
  total?: string
  type: ActivityType
}

export const LIMIT = 50
const defaultPayload = { page: 0, limit: LIMIT, sort: '-createdAt' }

export const iconMap = {
  commentsReceived: MessageCircleIcon,
  commentsGiven: MessageCircleIcon,
  reviewsReceived: ListIcon,
  reviewsGiven: ListIcon,
  reactionsReceived: SmileIcon,
  feedbackReceived: ThumbsUpIcon,
  disputesAll: AlertCircleIcon,
  disputesReceived: InboxIcon,
  disputesStarted: SendIcon,
}

export const getHash = (item: Review | Comment | Dispute | SurveyFeedbackResponse | ReceivedReaction, view: string) => {
  if (['commentsReceived', 'commentsGiven'].includes(view)) return `#comment-${(item as Comment).commentId}`
  if (['reviewsReceived', 'reviewsGiven'].includes(view)) return `#review-${(item as Review).reviewId}`
  if (view === 'feedbackReceived') return `#survey-${(item as SurveyFeedbackResponse).answerId}`
  if (view === 'reactionsReceived')
    return `#${(item as ReceivedReaction).entityType === 'review' ? 'review' : 'comment'}-${
      (item as ReceivedReaction).entityId
    }`
}

const all = { id: 0, name: i18n.t('pickers.users.select_all'), icon: markRaw(UsersIcon), email: '' }
const allQuestionType: QuestionType = { id: null, name: i18n.t('pickers.all'), isCustom: false }

export const getActivitiesTitle = (view: string) =>
  ({
    reviewsReceived: i18n.t('activity.reviews.received'),
    reviewsGiven: i18n.t('activity.reviews.given'),
    commentsReceived: i18n.t('activity.comments.received'),
    commentsGiven: i18n.t('activity.comments.given'),
    reactionsReceived: i18n.t('activity.reactions.received'),
    feedbackReceived: i18n.t('activity.feedback.received'),
    disputesAll: i18n.t('activity.disputes.all'),
    disputesReceived: i18n.t('activity.disputes.received'),
    disputesStarted: i18n.t('activity.disputes.started'),
  })[view] || i18n.t('activity.title')

/**
 * @param listOnly dictates whether we should communicate with the router for certain list states,
 * for example automatically routing to the first item in the list. `listOnly` mode is used in the extension,
 * where no TicketContent is rendered.
 */
export default createSharedComposable((listOnly = false) => {
  const route = useRoute()
  const router = useRouter()
  const { questionTypes } = useQuestionTypes()
  const { conversationId } = useTicketRouteParams()

  const view = ref('')
  const list = reactive<List>({ isLoading: false, items: [], type: 'comments', total: undefined })
  const page = ref<number>(0)
  const infiniteLoader = ref<StateChanger | null>(null)
  const selectedUser = ref<Member>(all)
  const surveyQuestionTypes = ref<QuestionType[]>([allQuestionType])
  const selectedQuestionType = ref<QuestionType>(allQuestionType)
  const sort = ref<SortValues>(SortValues.NEWEST)
  const status = ref<CommentStatusKeys | DisputeStatusKeys>('ALL')

  const title = computed(() => getActivitiesTitle(view.value))

  const listItem = computed(() => {
    if (view.value.startsWith('disputes')) return DisputeItem
    if (view.value.startsWith('reviews')) return ReviewItem
    if (view.value.startsWith('comments')) return CommentItem
    if (view.value.startsWith('feedback')) return FeedbackItem
    if (view.value.startsWith('reactions')) return ReactionItem

    return ''
  })

  const lazyLoadingKey = computed(
    () => `${view.value}-${sort.value}-${status.value}-${selectedUser.value}-${selectedQuestionType.value?.id}`,
  )

  const hasFilter = computed(
    () =>
      (list.type === 'feedback' && selectedQuestionType.value.id !== allQuestionType.id) ||
      (list.type === 'reviews' && selectedUser.value.id !== all.id) ||
      (list.type !== 'reviews' && status.value !== 'ALL'),
  )

  const hasUnreadItems = computed((): boolean => {
    if (['reviewsGiven', 'commentsGiven', 'feedbackReceived', 'reactionsReceived'].includes(view.value)) return false

    return list.items.some((i: Comment | Dispute | Review | SurveyFeedbackResponse | ReceivedReaction) => !i['seen'])
  })

  const reviewPayload: ReviewListParams = {
    ...defaultPayload,
    userId: null,
    commentsOnly: false,
    outbound: false,
  }
  const commentPayload: CommentListParams = {
    ...defaultPayload,
    type: 'ALL',
    outbound: false,
  }
  const surveyFeedbackPayload: SurveyFeedbackParams = {
    limit: LIMIT.toString(),
    offset: '0',
    questionTypeId: allQuestionType.id,
    sort: '-createdAt',
  }
  const disputePayload: DisputeListParams = {
    ...defaultPayload,
    endpoint: 'all',
    status: 'ALL',
  }

  const idKey = computed(() => {
    if (list.type === 'comments') return 'commentId' as PropertyKey
    if (list.type === 'reviews') return 'reviewId' as PropertyKey
    if (list.type === 'feedback') return 'questionTypeId' as PropertyKey
    if (list.type === 'reactions') return 'entityId' as PropertyKey

    return 'disputeId' as PropertyKey
  })

  const onCountChange = ({ id, type }: { id: string; type: string }) => {
    reloadActivitiesCount()

    if (
      type !== list.type ||
      (id && !list.items.some((item) => item[idKey.value] === id && !item[idKey.value]['seen']))
    )
      return

    const index = list.items.findIndex(
      (item: Comment | Review | Dispute | SurveyFeedbackResponse | ReceivedReaction) => item[idKey.value] === id,
    )

    if (list.items[index] && Object.hasOwn(list.items[index], 'seen')) list.items[index]['seen'] = true
  }

  const onActivitiesChange = (type: ActivityType) => {
    if (type !== list.type) return

    getFreshList()
  }

  useEvent('refresh-activities-count', onCountChange)
  useEvent('refresh-activities-items', onActivitiesChange)

  const getItems = () => {
    if (view.value.startsWith('reviews')) {
      return getReviews({
        ...reviewPayload,
        page: page.value,
        outbound: view.value === 'reviewsGiven',
        userId: selectedUser.value.id,
        sort: sort.value,
      }).then((r) => ({ items: r.reviews, total: r.total }))
    }

    if (view.value.startsWith('comments')) {
      return getComments({
        ...commentPayload,
        page: page.value,
        outbound: view.value === 'commentsGiven',
        type: status.value as CommentStatusKeys,
        sort: sort.value,
      }).then((r) => ({ items: r.comments, total: r.total }))
    }
    if (view.value.startsWith('reactions')) {
      return getReactions({
        limit: LIMIT,
        page: page.value,
      } as ReactionListParams).then((r) => ({ items: r.reactions, total: r.total }))
    }
    if (view.value.startsWith('feedback')) {
      return getSurveyFeedback({
        ...surveyFeedbackPayload,
        page: page.value,
        sort: sort.value,
        questionTypeId: selectedQuestionType.value.id || null,
      }).then((r) => ({ items: r.feedback, total: r.total }))
    }

    if (view.value.startsWith('disputes')) {
      let endpoint: DisputeListParams['endpoint'] = disputePayload.endpoint

      if (view.value === 'disputesStarted') endpoint = 'started'
      if (view.value === 'disputesReceived') endpoint = 'received'

      // TODO: Put results to useDispute composable. see loadDisputes()
      return getDisputes({
        ...disputePayload,
        page: page.value,
        endpoint,
        status: status.value as DisputeStatusKeys,
        sort: sort.value,
      }).then((r) => ({ items: r.disputes, total: r.total }))
    }

    return { items: [], total: undefined }
  }

  const getFreshList = async (setLoadingState = true) => {
    page.value = 0
    list.total = undefined
    if (setLoadingState) list.isLoading = true

    const { items, total } = await getItems()
    list.total = total
    list.items = items

    if (view.value.startsWith('feedback')) {
      surveyQuestionTypes.value = [allQuestionType, ...(questionTypes.value || [])]
    }

    list.isLoading = false
  }

  watch([selectedUser, sort, status, view, selectedQuestionType], async () => {
    await getFreshList()

    if (listOnly) return

    if (list.items.length && !conversationId.value) router.replace(getRoute(list.items[0]))
    if (!list.items.length) bus.$emit('ticket-hide-loader')
  })

  watch(page, async () => {
    if (page.value === 0) return

    const res = await getItems()

    if (!res.items.length) {
      infiniteLoader.value?.complete()
      return
    }

    infiniteLoader.value?.loaded()
    list.items.push(...(res.items as any))
  })

  watch(
    view,
    (v) => {
      bus.$emit('ticket-show-loader')

      if (v.startsWith('reviews')) setState('reviews')
      else if (v.startsWith('comments')) setState('comments')
      else if (v.startsWith('disputes')) setState('disputes')
      else if (v.startsWith('feedback')) setState('feedback')
      else if (v.startsWith('reactions')) setState('reactions')
    },
    { immediate: true },
  )

  function setState(type: ActivityType) {
    list.type = type
    sort.value = SortValues.NEWEST

    if (type !== 'reviews') status.value = 'ALL'
  }

  function setFilter(type: 'user' | 'status' | 'feedback', value) {
    if (type === 'user') selectedUser.value = value
    if (type === 'status') status.value = value
    if (type === 'feedback') selectedQuestionType.value = surveyQuestionTypes.value?.find((type) => type.name === value)

    track('List filtered', { type })
  }

  function sortItems(value) {
    sort.value = value

    track('List sorted', { value, source: view.value })
  }

  /* Item is bordered if it's not the last one, not highlighted nor previous to highlighted */
  function isBorderedItem(item: Comment | Review | Dispute | SurveyFeedbackResponse | ReceivedReaction, index: number) {
    if (!list.items.length || index === list.items.length - 1) return false

    const key = idKey.value
    const excludedIds: number[] = []
    const highlighted = [...list.items].find((listItem) => {
      if (list.type === 'disputes') return route.path.endsWith(listItem[key])

      return route.hash.endsWith(listItem[key])
    })

    if (highlighted) {
      excludedIds.push(highlighted[key])

      const i = [...list.items].indexOf(highlighted)
      const previousToHighlighted = list.items[i - 1]

      if (previousToHighlighted) excludedIds.push(previousToHighlighted[key])
    }

    return !excludedIds.includes(item[key])
  }

  async function markListRead() {
    const notSeenItemsCount = list.items.filter(
      (i: Comment | Dispute | Review | SurveyFeedbackResponse | ReceivedReaction) => !i['seen'],
    ).length

    const { isConfirmed } = await alertDialog('', {
      title: i18n.t(`activity.mark_all_read.prompt_title.${list.type}`, notSeenItemsCount),
      confirmButtonText: i18n.t('activity.mark_all_read.prompt_submit'),
    })

    if (!isConfirmed) return

    if (list.type === 'comments') await markAllCommentsRead()
    else if (list.type === 'reviews') await markAllReviewsRead()
    else if (list.type === 'disputes') {
      let type = disputePayload.endpoint

      if (view.value === 'disputesStarted') type = 'started'
      if (view.value === 'disputesReceived') type = 'received'

      await markAllDisputesRead(type)
    }

    getFreshList(false)
    toast({
      status: 'success',
      message: i18n.t(`activity.mark_all_read.success.${list.type}`, notSeenItemsCount),
    })
    reloadActivitiesCount()
  }

  function loadNextPage($loader: StateChanger) {
    infiniteLoader.value = $loader
    page.value += 1
  }

  function getRouteName(useDisputeRoute?: boolean, useMessageRoute?: boolean) {
    const parentRoute = route.name?.toString().split('.').slice(0, 2).join('.')

    if (
      ['activity.disputesAll', 'activity.disputesReceived', 'activity.disputesStarted'].includes(parentRoute ?? '') ||
      useDisputeRoute
    ) {
      return `${parentRoute}.dispute`
    }

    if (useMessageRoute) {
      return `${parentRoute}.message`
    }

    return parentRoute
  }

  function getRoute(item: Review | Comment | Dispute | SurveyFeedbackResponse | ReceivedReaction): RouteLocationRaw {
    const getParam = (param: string) => (param in item && item[param] ? { [param]: item[param] } : {})

    return {
      name: getRouteName(item['disputeId'] && item['commentId'], item['messageId']),
      params: {
        conversationId: item.conversationId,
        connectionId: String(item.connectionId),
        ...getParam('messageId'),
        ...getParam('disputeId'),
      },
      hash: getHash(item, view.value),
    }
  }

  return {
    view,
    title,
    list,
    listItem,
    selectedUser,
    selectedQuestionType,
    sort,
    status,
    surveyQuestionTypes,
    hasFilter,
    hasUnreadItems,
    idKey,
    setFilter,
    sortItems,
    isBorderedItem,
    markListRead,
    loadNextPage,
    getRoute,
    lazyLoadingKey,
  }
})
