import type {
  AdvancedScorecard_Scorecard as Scorecard,
  AdvancedScorecard_GetScorecardsResponse as GetScorecardsResponse,
  AdvancedScorecard_GetScorecardCategoriesResponse as GetScorecardCategoriesResponse,
  AdvancedScorecard_CreateCategoryRequest as CreateCategoryRequest,
  AdvancedScorecard_CategoryItem as CategoryItem,
  AdvancedScorecard_UpdateCategoryRequest as UpdateCategoryRequest,
  AdvancedScorecard_GetCategoryRequest as GetCategoryRequest,
  AdvancedScorecard_AccountCategory as AccountCategory,
  AdvancedScorecard_CreateScorecardRequest as CreateScorecardRequest,
  AdvancedScorecard_GetScorecardRequest as GetScorecardRequest,
  AdvancedScorecard_UpdateScorecardStatusRequest as UpdateScorecardStatusRequest,
  AdvancedScorecard_GetRootCausesResponse as GetRootCausesResponse,
  AdvancedScorecard_CreateRootCauseRequest as CreateRootCauseRequest,
  AdvancedScorecard_UpdateRootCauseRequest as UpdateRootCauseRequest,
  AdvancedScorecard_RootCause as RootCause,
  AdvancedScorecard_UpdateScorecardRequest as UpdateScorecardRequest,
  AdvancedScorecard_RootCauseRequest as DeleteRootCauseRequiest,
} from '@zendesk/zqa-services/scorecards'
import { api } from '@/api'
import { SCALE_VALUES } from '@/data/scales'

export const getScorecards = (): Promise<GetScorecardsResponse> => api.get('settings/reviews/scorecards').json()

export const getCategories = (): Promise<GetScorecardCategoriesResponse> =>
  api.get('settings/reviews/categories').json()

export const addCategory = (json: CreateCategoryRequest): Promise<CategoryItem> => {
  return api.post('settings/reviews/categories', { json }).json()
}

export const editCategory = (json: UpdateCategoryRequest): Promise<CategoryItem> => {
  return api.put(`settings/reviews/categories/${json.id}`, { json }).json()
}

export const getCategory = (id: GetCategoryRequest['categoryId']): Promise<AccountCategory> => {
  return api.get(`settings/reviews/categories/${id}`).json()
}

export const createScorecard = (scorecard: CreateScorecardRequest) => {
  return api.post('settings/reviews/scorecards', { json: scorecard }).json()
}

export const updateScorecard = (
  id: UpdateScorecardRequest['scorecardId'],
  scorecard: Omit<UpdateScorecardRequest, 'scorecardId'>,
): Promise<Scorecard> => {
  return api.put(`settings/reviews/scorecards/${id}`, { json: scorecard }).json()
}

export const updateScorecardStatus = (req: UpdateScorecardStatusRequest): Promise<void> => {
  return api.put(`settings/reviews/scorecards/${req.scorecardId}/status`, { json: { status: req.status } }).json()
}

export const getRootCauses = (): Promise<GetRootCausesResponse> => api.get('settings/reviews/root-causes').json()

export const createRootCause = (json: CreateRootCauseRequest): Promise<RootCause> => {
  return api.post('settings/reviews/root-causes', { json }).json()
}

export const updateRootCause = (json: UpdateRootCauseRequest): Promise<RootCause> => {
  return api.put(`settings/reviews/root-causes/${json.rootCauseId}`, { json }).json()
}

export const deleteRootCause = (json: DeleteRootCauseRequiest): Promise<void> => {
  return api.delete(`settings/reviews/root-causes/${json.rootCauseId}`).json()
}

const appendCategory = (category: Scorecard['categories'][0]) => {
  return {
    ...category,
    conditions: [],
    rootCauses: {
      multipleRequireReasons: false,
      freeTextAllowed: false,
      ratings: SCALE_VALUES[category.scale].map((scaleValue) => ({
        rating: scaleValue.label,
        rootCauses: [],
      })),
    },
  }
}

export const getScorecard = (request: GetScorecardRequest): Promise<Scorecard> => {
  return api
    .get(`settings/reviews/scorecards/${request.scorecardId}`)
    .json<Scorecard>()
    .then((data) => ({
      ...data,
      categories: data.categories.map(appendCategory),
      groups: data.groups.map((group) => ({
        ...group,
        categories: group.categories.map(appendCategory),
      })),
    }))
}
