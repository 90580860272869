import qs from 'query-string'

import type {
  AssignmentV2_AssignmentDashboardRequest,
  AssignmentV2_AssignmentDashboardResponse,
} from '@zendesk/zqa-services/assignments_v2'
import { api } from '@/api'

import type {
  AssignmentV2_Assignment,
  AssignmentV2_CreateAssignmentRequest,
  AssignmentV2_UpdateAssignmentRequest,
} from '../views/assignments/types/assignments'

interface Params {
  workspaceId: number
  assignmentId?: string
  data?: AssignmentV2_CreateAssignmentRequest | AssignmentV2_UpdateAssignmentRequest
}

export const getAssignments = ({ workspaceId }: Params): Promise<{ assignments: AssignmentV2_Assignment[] }> =>
  api
    .get(`assignments/v2/management`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      searchParams: qs.stringify({ limit: 5000 }),
    })
    .json()

export const getAssignment = ({ workspaceId, assignmentId }: Params): Promise<AssignmentV2_Assignment> =>
  api
    .get(`assignments/v2/management/${assignmentId}`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json()

export const updateAssignment = ({ workspaceId, assignmentId, data }: Params): Promise<AssignmentV2_Assignment> =>
  api
    .put(`assignments/v2/management/${assignmentId}`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      json: data,
    })
    .json()

export const addAssignment = ({ workspaceId, data }: Params): Promise<void> =>
  api.post(`assignments/v2/management`, { headers: { 'X-Klaus-Workspace': workspaceId.toString() }, json: data }).json()

export const deleteAssignment = ({ workspaceId, assignmentId }: Params): Promise<void> =>
  api
    .delete(`assignments/v2/management/${assignmentId}`, { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json()

export const duplicateAssignment = ({ workspaceId, assignmentId }: Params): Promise<void> =>
  api
    .post(`assignments/v2/management/${assignmentId}/duplicate`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json()

export const toggleAssignmentStatus = ({ workspaceId, assignmentId }: Params): Promise<void> =>
  api
    .put(`assignments/v2/management/${assignmentId}/toggle-status`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json()

interface DashboardParams extends AssignmentV2_AssignmentDashboardRequest {
  workspaceIds: number[]
}

export const getAssignmentDashboard = ({
  assignmentId,
  fromDate,
  toDate,
  pageSize,
  page,
  workspaceIds,
}: DashboardParams): Promise<AssignmentV2_AssignmentDashboardResponse> => {
  return api
    .get(`assignments/v2/${assignmentId}/dashboard`, {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ fromDate, toDate, pageSize, page }, { skipNull: true }),
    })
    .json()
}
