import type {
  GetDeletedConversationsWithOrphanedEntitiesRequest,
  GetDeletedConversationsWithOrphanedEntitiesResponse,
  DeleteOrphanedEntitiesResponse,
} from '@zendesk/zqa-services/reviews'
import qs from 'query-string'
import { api } from '@/api'

export const DELETED_CONVERSATIONS_LIMIT = 50

export const getDeletedConversationsWithReviews = (
  page: number,
  conversationExternalId: string,
): Promise<GetDeletedConversationsWithOrphanedEntitiesResponse> => {
  const searchParams: GetDeletedConversationsWithOrphanedEntitiesRequest = {
    conversationExternalId,
    offset: String(page * DELETED_CONVERSATIONS_LIMIT),
    limit: String(DELETED_CONVERSATIONS_LIMIT),
  }

  return api
    .get('reviews/orphaned', {
      searchParams: qs.stringify(searchParams),
    })
    .json<GetDeletedConversationsWithOrphanedEntitiesResponse>()
}

export const deleteTickets = () => api.delete('reviews/orphaned').json<DeleteOrphanedEntitiesResponse>()
