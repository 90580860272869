import { type QuestionType } from '@zendesk/zqa-services/surveys'
import i18n from '@/i18n'

import { DeliveryOptions, type DeliverySettings, type SurveyConfig } from '../types'

export const CUSTOM_QUESTION = 'CUSTOM'

export const mapQuestionFields = (response: SurveyConfig, questionType: QuestionType) => {
  const question = getQuestions(questionType.name)
    .map((group) => group.options)
    .flat()
    .find(({ value }) => value === response.question)

  if (!response.questionTypeId) {
    response.questionTypeId = questionType.id
  }
  return question ? response : { ...response, customQuestion: response.question, question: CUSTOM_QUESTION }
}

export const TEXT_SNIPPETS = {
  brandName: {
    label: i18n.t('settings.survey.edit.snippets.brand_name.label'),
    content: `<span data-id="brandName" data-type="mention" data-label="${i18n.t(
      'settings.survey.edit.snippets.brand_name.label',
    )}" class="snippet"></span>`,
    tooltipText: i18n.t('settings.survey.edit.snippets.brand_name.tooltip_text'),
  },
  customerName: {
    label: i18n.t('settings.survey.edit.snippets.customer_name'),
    content: `<span data-id="customerName" data-type="mention" data-label="${i18n.t(
      'settings.survey.edit.snippets.customer_name',
    )}" class="snippet"></span>`,
  },
  agentName: {
    label: i18n.t('settings.survey.edit.snippets.support_rep'),
    content: `<span data-id="agentName" data-type="mention" data-label="${i18n.t(
      'settings.survey.edit.snippets.support_rep',
    )}" class="snippet"></span>`,
  },
  ticketExternalId: {
    label: i18n.t('settings.survey.edit.snippets.support_id'),
    content: `<span data-id="ticketExternalId" data-type="mention" data-label="${i18n.t(
      'settings.survey.edit.snippets.support_id',
    )}" class="snippet"></span>`,
  },
}

export const replaceSnippetWithHTML = (text: string | undefined) => {
  let htmlContent = text || ''
  Object.keys(TEXT_SNIPPETS).forEach((key) => {
    htmlContent = htmlContent.replaceAll(`{{${key}}}`, `<span class="snippet">${TEXT_SNIPPETS[key].label}</span>`)
  })
  return htmlContent
}

const findSnippets = (questionTranslation: string) => {
  const usedSnippets = Object.keys(TEXT_SNIPPETS).filter((snippet) => questionTranslation?.includes(`{${snippet}}`))
  return usedSnippets
}

export const getQuestions = (surveyType: string) => [
  {
    options: [...Array(4)].map((_, i) => {
      const placeholderValues = Object.keys(TEXT_SNIPPETS).reduce((value, snippet) => {
        value[snippet] = `{${snippet}}`
        return value
      }, {})

      const questionTranslation = i18n.t(
        `settings.survey.edit.sections.content.${surveyType.toLowerCase()}.questions.${i + 1}`,
        placeholderValues,
      )
      const questionValue = questionTranslation.replaceAll('{', '{{').replaceAll('}', '}}')
      return {
        label: questionTranslation,
        name: `settings.survey.edit.sections.content.${surveyType.toLowerCase()}.questions.${i + 1}`,
        value: questionValue,
        usedSnippets: findSnippets(questionTranslation),
      }
    }),
  },
  {
    options: [
      {
        label: i18n.t(`settings.survey.edit.sections.content.custom_question_option`, { type: surveyType }),
        name: 'settings.survey.edit.sections.content.custom_question_option',
        value: CUSTOM_QUESTION,
        usedSnippets: [],
      },
    ],
  },
]

export const connectionRequiresAction = (config: DeliverySettings) =>
  config.connection.disabled ||
  config.connection.maskClientData ||
  (config.connection.needsAuth && config.deliveryMethod === DeliveryOptions.Chat)

export function isSnippetDeliveryEnabledForSurvey(surveyConfig: SurveyConfig) {
  const deliverySettings = surveyConfig.deliverySettings

  return deliverySettings
    .filter((deliverySetting) => deliverySetting.enabled)
    .some((deliverySetting) => deliverySetting.deliveryMethod === 'SNIPPET')
}
