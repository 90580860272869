import { capitalize } from 'lodash-es'
import i18n from '@/i18n'

const ticketStatuses = ['NEW', 'OPEN', 'PENDING', 'SOLVED', 'CLOSED']

export const getTicketStatusLabel = (status: string) => {
  if (ticketStatuses.includes(status.toUpperCase()))
    return i18n.t(`conversations.ticket_event.statuses.${status.toLowerCase()}`)
  return capitalize(status)
}
