import { computed } from 'vue'
import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'
import { defaultViewSettings } from '@/utils/constants/default-view-settings'

export function useViewSettings(route: string) {
  const updateViewSettings = async (viewSettings) => {
    await updateUserSetting(route, viewSettings)
    session.user.settings[route] = viewSettings
  }

  const viewSettings = computed(() => ({
    ...defaultViewSettings[route],
    ...(session.user.settings[route] || {}),
  }))

  return { viewSettings, updateViewSettings }
}
