import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/settings/survey/edit/:id',
    name: 'settings.survey.edit',
    component: () => import('./SurveyEditView.vue'),
    props: ({ params, query }) => ({
      id: params.id,
      brand: params.brand,
      authData: query.connectionId && { connectionId: query.connectionId },
    }),
    meta: { requiredAccountRole: ['ADMIN', 'MANAGER'], requiredFlag: 'surveys' },
  },
]

export default routes
