import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quiz_question_form = _resolveComponent("quiz-question-form")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_ctx.state.formData)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: _normalizeClass(_ctx.$style.container),
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitRequest && _ctx.handleSubmitRequest(...args)), ["prevent"]))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.formData.fields, (question) => {
          return (_openBlock(), _createElementBlock("div", {
            key: question.id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.questionContainer, !_ctx.hasSelectedAnswer(question.options) && 'question-error'])
            }, [
              _createVNode(_component_quiz_question_form, {
                id: question.id,
                modelValue: question.options,
                "onUpdate:modelValue": ($event: any) => ((question.options) = $event),
                text: question.text,
                images: question.images,
                properties: question.properties,
                error: _ctx.validateOption(question.options)
              }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "text", "images", "properties", "error"])
            ], 2 /* CLASS */)
          ]))
        }), 128 /* KEYED_FRAGMENT */)),
        _createVNode(_component_ui_button, {
          small: "",
          "data-testid": "submit-quiz-answers",
          class: _normalizeClass(_ctx.$style.submit),
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('quizzes.form.submit')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ], 34 /* CLASS, NEED_HYDRATION */))
    : _createCommentVNode("v-if", true)
}