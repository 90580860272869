import { v4 as uuid } from 'uuid'

export interface UnsavedImageMeta {
  unsavedImageKey: string
  src: string
}

export const unsavedImages: Record<string, { file: File; bucketSrc?: string; uploading?: boolean }> = {}

export const readFileAsObjectURL = (file: File): UnsavedImageMeta => {
  const unsavedImageKey = uuid()
  const src = URL.createObjectURL(file)
  unsavedImages[unsavedImageKey] = { file }

  return { unsavedImageKey, src }
}
