import dayjs from 'dayjs'

import i18n from '@/i18n'
import { formatDateIntl } from '@/utils/date'
import { hasAccountRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'

export const getDefaultTitle = (createdAt: string) =>
  i18n.t('coaching.sessions.thread.session_title', { date: formatDateIntl(createdAt, { dateStyle: 'medium' }) })

export const canCreateSession = () =>
  hasAccountRole('ADMIN', 'MANAGER') || hasHighestWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER')

// parses date to miliseconds without timestamp
export const parseDate = (date?: string) => Date.parse(dayjs(date).format('YYYY-MM-DD'))
