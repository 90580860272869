import type { NavigationGuard, RouteRecordRaw } from 'vue-router'

import { sidebarRoutes } from '@/utils/defineSidebarRoutes'
import { session } from '@/composables/useSession'
import { hasWorkspaceRole } from '@/utils/roleUtils'
import { TICKET_PATH } from '@/composables/useTicketRouteParams'

const checkCalibrationEnabled: NavigationGuard = (_to, _from, next) =>
  session.workspace.settings.calibrationEnabled ? next() : next({ name: 'tasks.assignments' })

export default (): RouteRecordRaw[] => [
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      requiredFlag: 'assignments',
      requiresPayment: true,
      includeInCommandPalette: true,
      requiresSufficientConversations: true,
    },
    redirect: { name: 'tasks.assignments.review' },
    beforeEnter: (_to, _from, next) => {
      if (hasWorkspaceRole('AGENT')) return next({ name: 'landing' })
      next()
    },
    component: () => import('./TasksRoutes.vue'),
    props: ({ params, query }) => ({ params, query }),
    children: [
      {
        path: 'assignments/:assignmentId?/' + TICKET_PATH,
        name: 'tasks.assignments',
        redirect: { name: 'tasks.assignments.review' },
        props: ({ params }) => {
          return {
            assignmentId: params.assignmentId,
            conversationId: params.conversationId,
            connectionId: params.connectionId,
          }
        },
        component: () => import('./TasksIndexView.vue'),
        children: sidebarRoutes('tasks.assignments', '/tasks/assignments/:assignmentId?'),
      },
      {
        path: 'calibrations/:calibrationSessionId?/' + TICKET_PATH,
        name: 'tasks.calibrations',
        redirect: { name: 'tasks.calibrations.review' },
        beforeEnter: checkCalibrationEnabled,
        meta: { requiredFlag: 'calibrationPro' },
        props: ({ params }) => ({
          calibrationSessionId: params.calibrationSessionId,
          conversationId: params.conversationId,
          connectionId: params.connectionId,
        }),
        component: () => import('./TasksIndexView.vue'),
        children: [
          {
            path: '',
            name: 'tasks.calibrations.review',
            beforeEnter: checkCalibrationEnabled,
            component: () => import('@/modules/shared/TicketContent/CalibrationSidebar.vue'),
            meta: { requiredFlag: 'calibrationPro' },
            props: ({ params }) => ({
              calibrationSessionId: params.calibrationSessionId,
              conversationId: params.conversationId,
              connectionId: params.connectionId,
            }),
          },
          {
            path: '/csat',
            name: 'tasks.calibrations.csat',
            beforeEnter: checkCalibrationEnabled,
            component: () => import('@/modules/shared/TicketContent/SurveySidebar.vue'),
            meta: { requiredFlag: 'calibrationPro' },
            props: ({ params }) => ({
              calibrationSessionId: params.calibrationSessionId,
              conversationId: params.conversationId,
              connectionId: params.connectionId,
            }),
          },
        ],
      },
    ],
  },
]
