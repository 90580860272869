import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reviewer_item = _resolveComponent("reviewer-item")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_info_icon = _resolveComponent("info-icon")!
  const _component_tippy = _resolveComponent("tippy")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_data_grid_data = _resolveComponent("data-grid-data")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    data: _ctx.data,
    "sort-by": _ctx.sortBy,
    "inline-pagination": "",
    "set-page": _ctx.setPage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_data_grid_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_column, {
            id: "user",
            "identity-column": "",
            label: _ctx.$t('disputes_dashboard.data_column_label.user'),
            width: "200px",
            align: "left"
          }, {
            default: _withCtx(({ value }) => [
              _createVNode(_component_reviewer_item, { reviewer: value }, null, 8 /* PROPS */, ["reviewer"])
            ]),
            "custom-total": _withCtx(() => [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('disputes_dashboard.total')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_data_grid_data, null, {
        header: _withCtx(({ label: key }) => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t(`disputes_dashboard.data_column_label.${_ctx.snakeCase(key)}`)), 1 /* TEXT */),
          (key === 'reviewsSeen')
            ? (_openBlock(), _createBlock(_component_tippy, {
                key: 0,
                placement: "top",
                theme: "dark",
                "max-width": "unset",
                class: _normalizeClass(_ctx.$style.tippy)
              }, {
                trigger: _withCtx(() => [
                  _createVNode(_component_info_icon, {
                    class: _normalizeClass(_ctx.$style.infoIcon)
                  }, null, 8 /* PROPS */, ["class"])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`disputes_dashboard.tooltip.${_ctx.snakeCase(key)}.${_ctx.parentKey}`)), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
            : _createCommentVNode("v-if", true)
        ]),
        default: _withCtx(({ value, isTotal }) => [
          isTotal
            ? (_openBlock(), _createBlock(_component_number_cell, {
                key: 0,
                bold: "",
                "bg-color": "active-secondary",
                color: "active-primary",
                value: value
              }, null, 8 /* PROPS */, ["value"]))
            : (_openBlock(), _createBlock(_component_number_cell, {
                key: 1,
                value: value
              }, null, 8 /* PROPS */, ["value"]))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data", "sort-by", "set-page"]))
}