export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira seu subdomínio do Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar no Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua empresa já tem uma conta."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar de uma empresa"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para participar de uma empresa existente, peça ao administrador para enviar um convite a você."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir ao administrador para convidar você"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudei de ideia."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar inscrição"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua empresa já tem uma conta. Deseja participar da conta da sua empresa ou criar uma nova?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruções de cópia"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma nova conta"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já existe uma conta"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não pode acessar sua conta de Zendesk QA até que suas faturas de assinatura pendentes sejam pagas."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua assinatura está em espera"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não encontrada. Tente novamente."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defina uma nova senha."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tente novamente ou ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinação incorreta de e-mail e senha."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicite um novo convite para o seu administrador."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O link de convite é inválido ou já está em uso."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O método de entrada ", _interpolate(_named("method")), " está desativado na sua conta."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este convite é inválido ou já foi usado. Solicite um novo convite para o seu administrador."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação de falha ao carregar o convite."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falha ao entrar. Talvez já exista um usuário com esse e-mail."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta foi bloqueada após várias tentativas consecutivas de entrada. Verifique o e-mail enviado a você com instruções sobre como desbloquar a conta."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique seu e-mail primeiro"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma conta de e-mail válida"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de entrada"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar link mágico"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail comercial"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu e-mail comercial"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com.br/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrato Principal de Serviços"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha não corresponde aos requisitos"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de privacidade"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com.br/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em espera"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oi, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Junte-se à organização ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu e-mail foi verificado. Agora, você pode entrar com sua nova conta abaixo."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar com o Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link de convite inválido"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar usando o link mágico"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para entrar"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar com o Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar com o Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu e-mail foi verificado. Agora, você pode entrar com sua nova conta abaixo:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com o Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar via link mágico"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar no Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta foi apagada"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira um código válido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma conta de e-mail válida"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de entrada"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas as opções de entrada"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " por métodos tradicionais"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar usando o link mágico"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recebeu um e-mail com seu código de entrada."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recebeu um e-mail para entrar."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houve um erro ao enviar o link mágico. Tente novamente."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar via e-mail"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar senha"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um caractere minúsculo"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um caractere maiúsculo"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nova senha deve conter"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A senha pode ter, no máximo, 128 caracteres"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segura e fácil de lembrar"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo de 8 caracteres"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sua senha"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a senha?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um caractere minúsculo"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um número"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um caractere maiúsculo"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para redefinir sua senha, insira seu endereço de e-mail e enviaremos um e-mail com instruções."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma conta de e-mail válida"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um e-mail foi enviado para ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o e-mail não aparecer em sua inbox ou pasta de spam, verifique o e-mail inserido."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para entrar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique seu e-mail"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", você receberá um e-mail de redefinição de senha."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sua conta já tiver uma senha"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ", _interpolate(_named("bold")), ", enviaremos um link de redefinição de senha."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o e-mail está associado a uma conta"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrou da sua senha?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houve um erro ao enviar o link de redefinição. Tente novamente."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir sua senha"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se usando o link mágico"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Política de privacidade)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ao se cadastrar, você concorda com os ", _interpolate(_named("terms_of_service")), " e a ", _interpolate(_named("privacy_policy")), " do Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos de serviço"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem uma conta?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se via link mágico"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se com seu e-mail comercial"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se com o Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se no Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se com o Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenho uma conta do Zendesk ativa"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cadastre-se ou entre para concluir a configuração de ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É mesmo você? Verifique se você recebeu o e-mail de confirmação."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo certo"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA funciona melhor no computador. Entre em um computador para começar a avaliar as conversas."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agende uma demonstração"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou agende uma demonstração conosco e mostraremos o que você pode fazer no Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua presença é solicitada"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelar"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mudei de ideia e quero ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " convida você para participar dessa conta."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar da conta"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma nova conta"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente entrar novamente"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A reação recebida pelo seu feedback: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reações recebidas"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagiu ao seu comentário"]), _normalize(["Várias pessoas reagiram ao seu comentário"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagiu à sua resposta"]), _normalize(["Várias pessoas reagiram à sua resposta"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagiu à sua avaliação"]), _normalize(["Várias pessoas reagiram à sua avaliação"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentário"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resposta"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliação"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTESTAÇÕES"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONCEDIDO"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEBIDO"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da pesquisa"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reações"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciado"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcar comentário como lido?"]), _normalize(["Marcar todos os comentários como lidos?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcar contestação como lida?"]), _normalize(["Marcar todas as contestações como lidas?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcar o feedback da pesquisa como lido?"]), _normalize(["Marcar todos os feedbacks da pesquisa como lidos?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Marcar avaliação como lida?"]), _normalize(["Marcar todas as avaliações como lidas?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentário marcado como lido"]), _normalize(["Todos os comentários foram marcados como lidos"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contestação marcada como lida"]), _normalize(["Todas as contestações foram marcadas como lidas"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Feedback de pesquisa marcado como lido"]), _normalize(["Todos os feedbacks da pesquisa foram marcados como lidos"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Avaliação marcada como lida"]), _normalize(["Todas as avaliações foram marcadas como lidas"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar tudo como lido"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como lido"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliações"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando alguém contesta uma avaliação, ela é exibida aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando você é atribuído para resolver uma contestação, ela é exibida aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação recebida"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando você contesta uma avaliação, ela é exibida aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação iniciada"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo feedback dado a você é exibido aqui. Ainda não há nada para ver."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum feedback recebido"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as reações dadas às suas avaliações e respostas são exibidas aqui. Ainda não há nada para ver."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma reação recebida"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir uma conversa"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo feedback fornecido por você termina aqui. Comece deixando uma avaliação na visualização de conversas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação fornecida"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo feedback dado a você é exibido aqui. Ainda não há nada para ver."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação recebida"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum comentário encontrado"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo feedback deixado como comentários aparece aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum comentário fornecido"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum comentário encontrado"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os comentários em uma avaliação feita por você, menções a ", "@", " ou respostas aos seus comentários aparecem aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum comentário recebido"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação aceita"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação aberta"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação rejeitada"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma contestação resolvida"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente novamente escolhendo outra opção no filtro acima."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente novamente escolhendo outro usuário no filtro acima."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários fornecidos"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários recebidos"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as contestações"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações recebidas"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações iniciadas"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da pesquisa recebida"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações fornecidas"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de atividades"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " avaliada(s)"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " avaliada(s)"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " avaliada(s)"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reinicia em ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tempo restante: ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar uma atribuição"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de atribuições"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Não é possível criar uma sessão de instrução entre ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), " já que não estão no mesmo espaço de trabalho."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Já existem sessões de instrução entre ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), ". Entre em contato com ", _interpolate(_list(0)), " para obter acesso à sessão."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerido"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Foi criada uma nova sessão entre ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrutor"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar instrutor"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruído"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar instruído"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessão"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sessão"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma sessão"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar sessão"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da sessão"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerido"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em atraso"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passado"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em breve"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sessão"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão encontrada"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio da avaliação"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para o período selecionado"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Item fixado adicionado à sessão. ", _interpolate(_named("buttonStart")), "Mostrar", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar a..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você chegou ao fim dos itens fixados do período selecionado"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veja como os itens fixados funcionam neste ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artigo"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Parece que você não fixou nenhum item em ", _interpolate(_list(0)), ". Você pode usar itens fixados ao se preparar para reuniões individuais ou para salvar alguma informação que será usada depois."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o instruído"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item fixado adicionado ainda"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estes são todos os itens fixados que você criou em ", _interpolate(_named("name")), ". Os itens fixados adicionados às sessões serão ", _interpolate(_named("bold")), " com o outro participante."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compartilhado imediatamente"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Parece que ", _interpolate(_list(0)), " não recebeu nenhum feedback para avaliação da conversa ou CSAT durante o período selecionado."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum feedback disponível"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você chegou ao fim dos comentários do período selecionado"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métricas"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Números de ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo item de ação"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Concluído por ", _interpolate(_named("user")), " em ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["por ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["você"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite para adicionar um novo item de ação"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual foi o resultado dessa reunião? Quais são as próximas etapas?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens de ação"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Criado por ", _interpolate(_named("name")), " em ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apagar a sessão"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Isso vai permanentemente ", _interpolate(_named("deleteSession")), ". Os participantes perderão o acesso à sessão, incluindo o conteúdo e as observações privadas."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sessão criada em ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data da última edição por ", _interpolate(_named("name")), " em ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontos de conversa e observações"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observação atualizada"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair da sessão"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários selecionados ganham acesso aos detalhes da sessão a partir desse momento."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar usuários"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhado"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar sessão"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Você não fará mais parte da sessão ", _interpolate(_list(0)), " – ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " não fará mais parte desta sessão."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " tem acesso a essa sessão agora."]), _normalize([_interpolate(_named("n")), " novos usuários têm acesso a esta sessão agora"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar sessão"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar todas as sessões"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar sessão"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o instrutor"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Observações adicionadas por ", _interpolate(_list(0)), " serão exibidas aqui."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar itens fixados como pontos de discussão no painel à direita"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações que só você pode ver..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações pessoais"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observação privada atualizada"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dias"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instrutor"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruído"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens de ação"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sessão"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em andamento"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em atraso"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral das sessões"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu: instrutor"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu: instruído"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as sessões"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões compartilhadas"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente trocar de filtro para ver os itens de ação."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item de ação"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens de ação"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas e vistas"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido · Visto"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso após as sessões"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que os agentes não receberam feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se o nome do usuário está correto"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível encontrar esse usuário"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado disponível"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI dos últimos 30 dias"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Isso <b>apagará permanentemente todas as sessões</b> (2) com <b>", _interpolate(_named("name")), "</b>. Os participantes perderão o acesso a todas as sessões, incluindo o conteúdo e as observações privadas."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você chegou ao fim de todas as sessões"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma sessão de instrução é uma atividade em grupo entre um mentor e um instruído para discutir desempenho, oportunidades e metas de curto e longo prazo."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É possível pesquisar sessões pelo nome do instrutor e do instruído."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão encontrada"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão criada ainda"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar histórico de instrução"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar histórico de instrução "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sessão"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessão"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as sessões"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrutor"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruído"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como concluído"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como não concluído"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por nome"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de sessões"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem programação"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responda a esta mensagem"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessão de calibração expirada. Nenhuma avaliação adicional pode ser deixada."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você já deixou uma avaliação para essa conversa."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é mais possível avaliar esta conversa porque a data de vencimento da sessão já passou."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa conversa não tem avaliações"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar painel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar uma avaliação da calibração"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETALHES"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUMO"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSCRIÇÃO"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pessoa ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perda de comunicação"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar palestrante"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar mensagem"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar à reprodução da mensagem"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestrantes"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcrição"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários do espaço de trabalho"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione a conexão primeiro para criar filtros"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem bots"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Criado em ", _interpolate(_named("time")), " por ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última atualização em ", _interpolate(_named("time")), " por ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Parece que você não tem filtros. Para acessar essa página, primeiro crie um filtro. Comece com ", _interpolate(_named("recommendedFilter")), " para ver suas próprias conversas. Crie este filtro abaixo para continuar."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nos últimos 30 dias"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A condição \"Criado nos últimos 30 dias\" será adicionada aos filtros criados sem um intervalo de datas"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O filtro não está disponível neste plano e não será considerado ao exibir os resultados"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A busca por opções de filtro expirou. Tente novamente."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre a filtragem"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído = pessoa conectada"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversas encontradas: ", _interpolate(_named("count"))]), _normalize(["Conversa encontrada: ", _interpolate(_named("count"))]), _normalize(["Conversas encontradas: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não disponível em seu plano atual. Atualize para usar esse recurso."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar condição"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pela IA do Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condição"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformar em atribuição"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar filtro"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar filtro"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condições"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos carregando as opções de filtro de acordo com sua configuração específica. Isso pode demorar alguns minutos no carregamento inicial."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribua um nome descritivo ao filtro"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do filtro"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar uma opção"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condição"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar filtro"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar alterações"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum valor disponível"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não encontrado"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um valor"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somente sessões ativas ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar sessões mais antigas ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de vencimento"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar sessão de calibração"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sessão"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sessão de calibração"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as avaliações de calibração fornecidas durante a sessão serão apagadas permanentemente."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar sessão"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar sessão de calibração ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão foi criada"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão ativa"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão criada"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão ativa"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar filtro"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um filtro privado"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um filtro público"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro adicionado"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O filtro será apagado permanentemente."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar filtro"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar filtro ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as contestações"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações para mim"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas contestações"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações abertas"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunhos"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum filtro adicionado"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum filtro privado criado"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum filtro público criado"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros privados"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros públicos"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quer ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvar"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa é uma visualização temporária do filtro."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construindo seu filtro..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro atualizado"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria personalizada do AutoQA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última atualização ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras complexas"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As avaliações automáticas são atualizadas dinamicamente com base nas alterações do ticket"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacar na conversa"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frases longas"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum score automático previsto"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar erros"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar palavras complexas"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar frases longas"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima conversa"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O AutoQA não pôde avaliar essa categoria devido a contexto insuficiente. Revisão manual recomendada."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra insights automatizados de conversas para otimizar seu processo de avaliação. Destaque as conversas cruciais e dê exemplos positivos impressionantes para a sua equipe."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Chamada]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrigido"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagado"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " aceitou a contestação"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contestação enviada para ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por que você acha que esse avaliado é o destinatário errado do feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione um comentário e defina um novo avaliado"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar comentário"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por que você acredita que esses scores são classificados de forma inadequada (obrigatório)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um comentário (obrigatório)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por que você está rejeitando essa contestação"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar comentário"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar contestação para"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar contestação para"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar contestação para"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar novo avaliado"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da contestação"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da contestação"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar avaliado"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar contestação para"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestação"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestar essa avaliação"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não concordo com os scores"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa avaliação não era destinada a mim"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um usuário"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " aceitou parcialmente a contestação"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " rejeitou a contestação"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito parcialmente"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitada"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abertas"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " iniciou uma contestação: avaliação"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " iniciou uma contestação: avaliado errado"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma contestação aberta deve ser resolvida para que outras ações relacionadas à avaliação sejam tomadas."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar novos scores"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar contestação"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja descartar esta contestação?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar contestação"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestação"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de resolução da contestação"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar novos scores justos (opcional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestar avaliação"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado errado"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar contestação"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar contestação"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da contestação"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado errado"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da contestação"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar contestação"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitar contestação"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar a contestação"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " por ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["para ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído para:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixe também um comentário"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre tabelas de desempenho condicionais"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteriormente atribuído para:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione apenas uma opção"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível encontrar GIFs com esse nome"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seja o primeiro a deixar uma avaliação."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento, sua assinatura está pausada. Reative-a para poder dar feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado por:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso mostra o tempo que levou a avaliação desta conversa"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um usuário"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Falta a causa principal para a categoria: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifique uma categoria ou adicione um comentário"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir campo de comentário"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher campo de comentário"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar formatação"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar painel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar formatação"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar painel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar avaliação"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifique todas as categorias"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifique ao menos uma categoria"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar essa conversa para mais tarde"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar para mais tarde"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima atribuição"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir conversa neste ciclo de atribuição"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar conversa"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação da linha de base"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar comentário"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário apagado"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar este comentário?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor do comentário"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa hashtag foi apagada ou renomeada."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhe o que você mais gostou ou o que poderia ter sido melhor..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicional"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação apagará permanentemente todos os comentários."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar comentário"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Mensagem]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ainda não viu esse feedback"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " viu esse feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex-membro"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar GIFs..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione Enter para criar uma nova hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As hashtags personalizadas são bloqueadas pelo administrador."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use isso para marcar casos e filtrar depois"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como linha de base"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback de mensagem"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima conversa"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação concluída"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixe uma avaliação abaixo para começar."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há mais avaliações"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem classificação"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído anterior"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa anterior"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover como linha de base"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder a esta avaliação"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar barra lateral de avaliações"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação apagará permanentemente a avaliação e todos os comentários adicionados."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar avaliação"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar avaliação"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação apagada"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Avaliação apagada por ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribua essa tarefa a si mesmo para deixar uma avaliação"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você já deixou uma avaliação para esse usuário."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Você deixou uma avaliação para ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma categoria para essa conversa."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As autoavaliações não estão disponíveis para esse espaço de trabalho."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não salvar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar esta avaliação como rascunho?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar este rascunho"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar barra lateral de avaliações"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprovado"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovado"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovado com algumas ressalvas"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda analisando os números..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar um comentário"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar essa mensagem"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar-se"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar esta conversa"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário para avaliar"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações desativadas"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário encontrado"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários do espaço de trabalho"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar usuário"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 avaliação"]), _normalize([_interpolate(_named("count")), " avaliações"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar novas classificações"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O gerente do espaço de trabalho não permitiu a autoavaliação."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione Enter para marcar com tag este item fixado"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrações"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 30 dias"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com estrela"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma sessão de calibração"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunhos"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os insights fornecem uma representação visual dos dados de sua central de suporte. Use os gráficos interativos para saber onde concentrar seus esforços de controle de qualidade, descobrir pontos fracos e encontrar os agentes certos para avaliar com base no desempenho deles."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID de ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL de ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduzir para ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar a"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não atribuído"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizando no momento"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em avaliação agora"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa conversa foi removida e não está mais disponível."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa que você procura não existe no Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os itens fixados com esta conversa"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em sessão"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover conversa"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover uma conversa de uma sessão apagará todas as avaliações dadas a ela."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remover conversa de ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão de calibração criada ainda."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversa foi adicionada à sessão"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta conversa foi removida da sessão"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar/remover de uma sessão de calibração"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar link para esta conversa"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa para exibir"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail do cliente"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do cliente"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do cliente"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do formulário externo"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPR"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo da primeira resposta"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridade"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário da pesquisa"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de satisfação"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAPR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo até a primeira resolução"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa mensagem não existe nessa conversa ou ela foi apagada"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem assunto"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa para exibir"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa encontrada"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir conversa original"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível vincular à conversa – URL ausente"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem participantes"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar conversa"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para ler as avaliações"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para avaliar"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa criada pela extensão do navegador"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar pesquisa de satisfação do cliente"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir conversa"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem com um sentimento negativo"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem com um sentimento positivo"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzir para o inglês"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar original"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conversa"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A exclusão de uma conversa é permanente. Isso também apagará todos os dados relacionados, por exemplo, pesquisas, transcrições e avaliações do Zendesk QA, incluindo painéis.", _interpolate(_named("lineBreak")), "Você ainda poderá acessar essa conversa no Zendesk Support ou em outro software de central de central de suporte que estiver usando."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A exclusão de uma conversa é permanente. Ela também apaga todos os dados relacionados, por exemplo, pesquisas, transcrições e avaliações do Zendesk QA, incluindo painéis. Se a conversa pertencer a vários espaços de trabalho, ela será apagada de todos eles.", _interpolate(_named("lineBreak")), "Você ainda poderá acessar essa conversa no Zendesk Support ou em outro software de central de suporte que estiver usando."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conversa"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conversa do Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conversa está incompleta devido a uma transferência. Vá para ", _interpolate(_named("optionsUrl")), " e valide suas tags. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta conversa está incompleta devido a uma transferência. Fale com seu administrador sobre a validação de suas tags. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões > Opções avançadas"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre as tags"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechado"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em aberto"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolvido"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atribuído alterado para ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não atribuído"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " aplicada por ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A conversa recebeu uma classificação ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O usuário ", _interpolate(_named("target")), " suprimiu a conversa"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status alterado para ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marcado com ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Equipe alterada para ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " suspendeu a conversa para ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " retomou a conversa"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O bot está preso em um loop, repetindo uma mensagem consecutivamente"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetição do bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetição do bot, em que a mesma mensagem é repetida uma ou mais vezes seguidas"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frases correspondentes encontradas"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um agente regular conduz a conversa de maneira ", _interpolate(_named("percent")), " mais eficiente do que o bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiência da comunicação do bot"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O bot conduz a conversa de maneira ", _interpolate(_named("percent")), " mais eficiente do que um agente regular"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiência da comunicação do bot"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa não tem a declaração necessária informando os participantes sobre a gravação, o que é obrigatório para fins de conformidade e transparência"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgação de gravação ausente"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Um contrato de nível de serviço (SLA) foi violado por ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA violado"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A métrica da regra de SLA excedeu o tempo da meta"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinais de desgaste do cliente. O cliente estava considerando uma troca ou prometeu não fazer mais negócio."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco de perda de clientes identificado"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A conversa tem momentos de perda de comunicação que duram mais do que o limite definido de ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instância"]), _normalize([_interpolate(_named("count")), " instâncias"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s mais longo"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de comunicação"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente procurou ajuda de um nível superior"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encaminhamento solicitado"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente se mostrou extremamente agradecido ou satisfeito com o suporte recebido"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atendimento excepcional"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente ou o agente solicitou explicitamente um acompanhamento"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação de acompanhamento"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente mostrou insatisfação"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento negativo"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa foi incomum ou atípica e houve mais trocas de mensagens para chegar a uma resolução"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caso atípico detectado"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente se mostrou satisfeito"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento positivo"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicável"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir como não aplicável"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data da última edição por ", _interpolate(_named("author")), " em ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não aplicável"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por ", _interpolate(_named("author")), " em ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um motivo (opcional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum insight detectado"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar conexão"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte sua central de suporte à sua conta e crie pelo menos um filtro para ver a lista de conversas."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa a exibir"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar configurações do espaço de trabalho"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da última atualização"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Em um dia)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Este mês)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Esta semana)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar por ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pesquisar resultados para ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisado por outras pessoas"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com comentário"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais recentes"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais antigas"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleatório"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado recentemente"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com resposta recente"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificar por"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não lidos"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado por você"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O processo de importação pode levar algum tempo. Enviaremos um e-mail quando ele for concluído."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA está importando suas conversas"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limite: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para adicionar, clique no ícone de calibração no cabeçalho da visualização de conversas."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada para relatar aqui. Tente novamente usando um filtro diferente ou outra opção de ordenação."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum valor atípico para exibir."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre uma conversa relevante e clique no ícone de estrela no cabeçalho para marcá-la como favorita."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente alterar os filtros ou convide outros agentes da central de suporte para adicionar mais conversas."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem limite"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observação interna"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos carregando as conversas no momento. Ainda pode estar faltando algum conteúdo."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo usuário"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário encontrado"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros do espaço de trabalho"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário anterior"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa adicionada"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa foi adicionada"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As avaliações inacabadas vão parar aqui. Releia, avalie e envie a solicitação nos próximos 30 dias."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação em rascunho"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa marcada com estrela"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa a exibir"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem correspondência"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornar à mensagem selecionada"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos respostas"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais respostas"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de conversas"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chamada atendida às ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A chamada terminou às ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chamada feita às ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado por você"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Erro ao carregar o arquivo de áudio. Certifique-se de ter entrado em ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário da pesquisa de satisfação do cliente"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao carregar o áudio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor do comentário"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma chamada ocorreu"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvir na central de suporte"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvir no Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidade de reprodução"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisar chamada"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcrição"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é um usuário do Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA classifica os agentes automaticamente com base no CSAT médio mensal deles. Encontre com um clique as conversas atendidas por agentes cujo CSAT é menor do que dos colegas."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação por CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais baixo"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais alto"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é um usuário do Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA classifica automaticamente os agentes em grupos de desempenho com base no número de conversas por mês que eles gerenciam. Encontre com um clique as conversas de agentes com um volume menor do que dos colegas."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação por volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não revisados"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da avaliação"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é um usuário do Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA classifica os agentes automaticamente com base na opinião média das conversas. Encontre com um clique interações nas quais os agentes lidam com mais clientes frustrados do que os colegas."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação por sentimento"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não detectado"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectada"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casos atípicos"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas que exigem sua atenção. Nosso modelo de aprendizado de máquina identifica interações complexas ou atípicas para uma avaliação mais detalhada."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casos atípicos"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione conversas para avaliação em que o cliente tenha demonstrado satisfação ou frustração. Use a lista suspensa para selecionar o tipo de sentimento que você quer incluir em suas conversas. Arraste o atributo de filtro para o topo para ver a proporção do sentimento escolhido em todas as suas interações."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da avaliação"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não revisados"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA detecta automaticamente os idiomas nas suas conversas. Use a lista suspensa para selecionar conversas em idiomas específicos para avaliação. Arraste o atributo de filtro para a parte superior a fim de obter uma visão geral de todos os idiomas usados em sua central de suporte."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights de conversa"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você pode ", _interpolate(_named("paragraph_2_item")), " com os insights do que com os filtros de conversas regulares. Tenha uma visão geral de todos os dados das suas centrais de suporte e explore insights como:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre como usar insights de conversa"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entender a importância relativa de atributos específicos de uma conversa em relação a outros"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre seus agentes de melhor e pior desempenho com a classificação incorporada de desempenho dos agentes"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " é uma poderosa ferramenta interativa de mineração de dados que ajuda você a encontrar as conversas que mais precisam de avaliação."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faça muito mais"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Os gráficos Conversa e Agente são ", _interpolate(_named("paragraph_3_item_1")), " – atualizar os filtros ou clicar nas fatias do gráfico em qualquer um deles atualizará automaticamente a lista de conversas. ", _interpolate(_named("paragraph_3_item_2")), " para explorar detalhamentos ou proporções dos diferentes elementos."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconectado"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorganizar os atributos do filtro"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Os Insights de conversa exibem apenas ", _interpolate(_named("paragraph_4_item")), ". Removemos automaticamente as interações sem valor real de avaliação, como mensagens geradas automaticamente, spam, respostas do bot ou mensagens que não dialogam adequadamente com o representante de suporte."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversas claras e fechadas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que vejo aqui?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " de ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falha ao buscar"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/D"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentes"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " do total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mas temos certeza de que o resultado será ótimo."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa personalizada"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum feedback da pesquisa"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum score de feedback da pesquisa disponível..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recebido"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag de feedback"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags de comentários de feedback"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro de intervalo de datas aplicado"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " resposta"]), _normalize([_interpolate(_named("count")), " respostas"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de suporte"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aprofunde-se no motivo pelo qual um score de ", _interpolate(_list(0)), " foi dado ao configurar os motivos nas configurações da pesquisa."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ainda não há motivos de ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre os motivos da pesquisa"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas com tags:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " por"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivos"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria do comentário"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Motivos de ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impulsionadores previstos de ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem da conversa"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags da central de suporte"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Respostas da pesquisa (", _interpolate(_named("n")), ")"]), _normalize(["Resposta da pesquisa (", _interpolate(_named("n")), ")"]), _normalize(["Respostas da pesquisa (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem assunto"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversas com ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " por usuários"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de resposta"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O score de ", _interpolate(_list(0)), " é a soma de todas as respostas dividida pela soma dos scores máximos"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar meta"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover meta"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir uma meta"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar meta"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curto"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muito longo"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longo"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confuso"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é útil"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte ruim"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte bom"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversos emojis"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback para o agente"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamação"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas incompletas"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema não resolvido"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema resolvido"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento negativo"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado ruim"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento positivo"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elogio"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produto ruim"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte rápido"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte lento"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O Zendesk QA aloca automaticamente seus dados de ", _interpolate(_list(0)), " em categorias predefinidas. Assim, você pode entender insights complexos e explorar as causas sem precisar filtrar manualmente o feedback por escrito."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os scores"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as pesquisas"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar idioma da resposta"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do comentário"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o tamanho do comentário"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com 25% dos comentários consistindo de apenas uma palavra, comentários mais longos são leitura obrigatória. Encontre feedback mais esclarecedor sem precisar trabalhar manualmente, pois fazemos isso automaticamente para você."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo do feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o motivo do feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum score correspondente"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impulsionadores previstos"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar categoria do comentário"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras da nuvem de palavras"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar palavras da nuvem de palavras"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " desde o último período"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversa"]), _normalize([_interpolate(_named("n")), " conversa"]), _normalize([_interpolate(_named("n")), " conversas"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível calcular a taxa de envio da pesquisa de todas as conversas devido à falta de dados."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas enviadas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funil de pesquisa"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " de ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impulsionadores previstos de ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O Zendesk QA aloca automaticamente seus dados de ", _interpolate(_list(0)), " em categorias predefinidas. Assim, você pode entender insights complexos e explorar as causas sem precisar filtrar manualmente o feedback por escrito."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do comentário"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com 25% dos comentários consistindo de apenas uma palavra, comentários mais longos são leitura obrigatória. Encontre feedback mais esclarecedor sem precisar trabalhar manualmente, pois fazemos isso automaticamente para você."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas da pesquisa"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (média)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI (médio)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tendências de ", _interpolate(_list(0)), " por"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivos"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impulsionadores"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data de envio da pesquisa de ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data da resposta da pesquisa de ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atribuir score de ", _interpolate(_list(0)), " para"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir dados com base em"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação da conversa"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responsável pelo ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agente com mais respostas"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (média)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ao longo do tempo"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " e IQI"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas que incluem estas palavras:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuvem de palavras"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT por usuários"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral de CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funil de pesquisa"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas da pesquisa"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags da central de suporte"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT ao longo do tempo"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuvem de palavras"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem canal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem origem"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem idioma"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum motivo"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem tags"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria sem comentários"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem espaço de trabalho"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum dado disponível porque nenhuma atribuição foi criada ou nenhum ciclo foi iniciado. Verifique novamente mais tarde ou ajuste os filtros."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore as novidades"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " item"]), _normalize([_interpolate(_named("count")), " itens"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversa"]), _normalize([_interpolate(_named("count")), " conversas"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprovado"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovado"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovado com algumas ressalvas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovado/Reprovado"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Classificações de ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT médio = [soma dos scores de feedback ÷ (contagem de todas as respostas × score máximo possível)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O score CSAT do Zendesk QA normaliza seu score de feedback em uma escala de 0 a 100% e calcula a média dos scores padronizados."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O CSAT (score de satisfação do cliente) quantifica a satisfação dos clientes com relação aos serviços."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Cada avaliação representa a avaliação feita por uma pessoa em uma conversa. Uma conversa pode ter mais de uma avaliação (se dois ou mais avaliadores estiverem avaliando a mesma conversa)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhe a evolução dos scores dos avaliadores ao longo do tempo."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhe a evolução dos scores dos agentes ao longo do tempo."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " respostas"]), _normalize([_interpolate(_named("n")), " resposta"]), _normalize([_interpolate(_named("n")), " respostas"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " avaliações"]), _normalize([_interpolate(_named("n")), " avaliação"]), _normalize([_interpolate(_named("n")), " avaliações"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O IQI (Índice de qualidade interno) é o resultado das avaliações da sua conversa."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI = soma dos scores das avaliações ÷ número de avaliações"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ele é calculado como uma média, levando em consideração todos os scores de avaliação (média ponderada do score da categoria) que correspondem às condições do filtro selecionado."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao carregar o cartão."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altere seus filtros para tentar de novo."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A taxa de aprovação indica o resultado das avaliações das suas conversas."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reprovação se o score da avaliação for ", _interpolate(_named("failScore")), " ou inferior"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aprovação se o score da avaliação for ", _interpolate(_named("passScore")), " ou superior"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso é determinado por uma linha de base predefinida, o que significa o seguinte:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de aprovação = Soma das avaliações aprovadas ÷ Soma do total de avaliações"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A taxa de aprovação indica a porcentagem de quantas avaliações foram aprovadas sem reprovação nas categorias críticas ou não críticas."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por exemplo, se o avaliador fizer 3 avaliações no dia, o número exibido será a média dos 3 scores. Veja como cada célula é calculada ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por exemplo, se o agente receber 3 avaliações no dia, o número exibido será a média dos 3 scores. Veja como cada célula é calculada ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens não considerados: peso da categoria, status crítico (diferentemente dos scores ao longo do tempo)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como é possível marcar as categorias como \"N/D\" em avaliações, a quantidade de avaliações por categoria pode ser mais baixa que o total de avaliações do avaliador. Veja como cada célula é calculada"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens não considerados: peso da categoria, status crítico (diferentemente dos scores ao longo do tempo)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como é possível marcar as categorias como \"N/D\" em avaliações, a quantidade de avaliações por categoria pode ser mais baixa que o total de avaliações por agente. Veja como cada célula é calculada"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens não considerados: peso da categoria, status crítico (diferentemente dos scores ao longo do tempo)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " os grupos de categorias são exibidos separadamente. O score para cada grupo é calculado pelo score médio da categoria para o grupo durante o mesmo período. Veja como cada célula é calculada ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observação:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens não considerados: peso da categoria, status crítico (diferentemente dos scores ao longo do tempo)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " os grupos de categorias são exibidos separadamente. O score para cada grupo é calculado pelo score médio da categoria para o grupo durante o mesmo período. Veja como cada célula é calculada ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Linha de base ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem erros críticos"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A taxa livre de erros críticos indica uma porcentagem de quantas avaliações foram aprovadas sem reprovação em nenhuma das categorias críticas."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de qualidade interno"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Outras\" causas principais"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração periódica"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração diária"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração mensal"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração semanal"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração anual"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores ao longo do tempo"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização em dias ou semanas."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo = score total do dia ou da semana dividido pelo total de avaliações fornecidas"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização em dias ou semanas."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo = score total do dia ou da semana dividido pelo total de avaliações recebidas"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores por categoria"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja a média combinada de todas as classificações de categoria fornecidos pelo avaliador durante esse período."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja a média combinada de todas as classificações de categoria recebidos por agente durante esse período."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais das classificações baixas"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais das classificações"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores da categoria ao longo do tempo"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analise a comparação entre as categorias ao longo do tempo."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja os scores médios de cada categoria em um determinado dia ou semana."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analise a comparação entre as categorias ao longo do tempo."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja os scores médios recebidos para cada categoria em um determinado dia ou semana."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores por avaliações"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivado"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria crítica"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de classificação"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média/total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir autoavaliações"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporta todos os cartões"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecido"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversa"]), _normalize(["conversas"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["item"]), _normalize(["itens"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " respostas"]), _normalize([_interpolate(_named("n")), " resposta"]), _normalize([_interpolate(_named("n")), " respostas"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " avaliações"]), _normalize([_interpolate(_named("n")), " avaliação"]), _normalize([_interpolate(_named("n")), " avaliações"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mostrar mais um"]), _normalize(["Mostrar mais ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 30 dias"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 dias"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mês"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última semana"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse mês"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa semana"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período personalizado"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dias"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meses"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestres"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanas"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anos"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao carregar o cartão. Altere seus filtros para tentar de novo."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para exibição."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração mensal"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painéis"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais informações."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seus filtros ativos incluem dados de diferentes escalas de classificação."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os itens fixados do cartão com estes filtros"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os itens fixados do painel com estes filtros"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio da avaliação"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem categorias"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights da categoria"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Comentário"]), _normalize(["Comentários"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex-membro"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice de qualidade interno"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI (médio)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações aprovadas"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar cartão"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar painel"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Causa raiz"]), _normalize(["Causas principais"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da avaliação"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo da avaliação"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir as causas principais"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As causas principais informam os motivos pelos quais as conversas estão recebendo scores baixos"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma causa principal definida para essa categoria"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score/total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias de classificação"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de categorias de classificação"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo da categoria"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaço de trabalho"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar ordem"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupar categorias por"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir autoavaliações"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluir autoavaliações"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar apenas autoavaliações"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar filtros"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma hashtag encontrada"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags de comentários"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar hashtags de comentários"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tag encontrada"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags da central de suporte"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar tags da central de suporte"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tabela de desempenho encontrada"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tabelas de desempenho"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conexão correspondente"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem da conversa"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar origem"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais filtros"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho correspondente"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de vezes fornecido"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os grupos"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo correspondente."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os grupos"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir cartões"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir dados com base em"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação da avaliação"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação da conversa"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar duas casas decimais"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da visualização"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os espaços de trabalho"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta completa"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do espaço de trabalho"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandeiras"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comida e bebida"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animais e natureza"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetos"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas e corpo"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viagem e lugares"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado com frequência"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da pesquisa"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys e emoticons"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolos"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum emoji encontrado"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir uma conversa"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para a lista de tarefas"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " ou navegue pela lista de ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No momento, você está usando o espaço de trabalho ", _interpolate(_named("workspace")), ", que não está conectado a este domínio. Clique aqui para conectá-lo agora."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conexões disponíveis"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você ainda não pode dar feedback sobre ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenha sua central de suporte como a aba ativa."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse domínio ainda não pode ser avaliado. Caso precise avaliá-lo, notifique seu administrador."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar este domínio"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conectar com ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde um momento – Estamos concluindo suas configurações"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível obter o URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferramentas que já estão conectadas à conta do Zendesk QA da organização."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma ferramenta conectada à conta do Zendesk QA da organização."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite que você analise sites onde a URL permanece sempre a mesma, anexando um hash exclusivo no final, antes de deixar uma avaliação"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de conta"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seus domínios"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domínios que já estão conectados a esse espaço de trabalho."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode avaliar qualquer página nos domínios a seguir"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domínios disponíveis para avaliação"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensão"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL exclusiva"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nenhuma central de suporte conectada ainda – acesse ", _interpolate(_named("link")), " para adicionar uma."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A extensão do navegador do Zendesk QA só pode ser usada\nnos planos Professional e Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de espaço de trabalho"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído para:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forneça um nome"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forneça um valor"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos personalizados"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É a primeira vez que nos deparamos com este conversa. Dê alguns detalhes."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência para a conversa:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo para identificar o ticket posteriormente"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe o atribuído e insira um assunto"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criando"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar esta conversa"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não atribuído"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Entre em contato com seu gerente ou mude para outro espaço de trabalho em ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As autoavaliações estão desativadas neste espaço de trabalho."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchau"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaço de trabalho"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível avaliar essa URL. Navegue para uma conversa."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro, entre no Zendesk QA pelo seu provedor SSO e tente novamente."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrindo pop-up de entrada."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com sua conta do Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticando..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não tem uma conta?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar-se"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem acesso a essa conversa. Caso precise de acesso, entre em contato com seu gerente."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem acesso"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se a sua conexão está ativada no Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar suas conexões"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro de conexão"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegue até uma conversa em sua central de suporte para continuar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL inválida"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconexão quase concluída."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um momento"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente recarregar a página para voltar ao fluxo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fique livre dos limites virtuais da sua central de suporte e avalie conversas em qualquer lugar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualize sua assinatura para usar a extensão"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar sua assinatura"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conexão da central de suporte não está vinculada a esse espaço de trabalho"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua central de suporte não reconhece esse ticket ou o Zendesk QA não tem acesso a ele"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível encontrar a conversa"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova atividade"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recebeu um feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êxito na entrada"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode usar o aplicativo agora"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifique todas as categorias"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use isso para marcar casos e filtrar depois"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem feedback para esta conversa"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler o feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir no Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É aqui que todos os feedbacks e notificações serão exibidos. Nada ainda..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja mais no Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da conversa"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contém todos de"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é tudo de"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não contém todos de"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é tudo de"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["melhor que a de um agente"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pior que a de um agente"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falso"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é maior que"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contém qualquer um de"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é qualquer um de"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não contém nenhum de"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é nenhum de"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verdadeiro"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["com violação"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["com comentário"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentado por mim"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sem comentário"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não comentado por mim"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crítico para avaliação"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contém"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 30 dias"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dentro de"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 14 dias"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimas 24 horas"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 30 dias"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["últimos 7 dias"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["último mês"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["última semana"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esse mês"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["essa semana"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["até"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoje"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ontem"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detectado"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["existe"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marcado com estrela por mim"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não marcado com estrela por mim"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inclui"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é maior que"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partir de"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menor que"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["até"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativo"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sem violação"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sem complexidade"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não detectado"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não existe"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não inclui"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não recebido"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não visualizado"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positivo"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não avaliado"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não avaliado por mim"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliado"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliado por mim"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recebido"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crítico para avaliação"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contém"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é maior que"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partir de"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menor que"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["até"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não contém"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visualizado"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meu comentário tem respostas"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sim"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias personalizadas"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque personalizado"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por condição"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de suporte"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métricas"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação e feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todas as condições"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compara o gerenciamento de conversas do seu bot com agentes regulares"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O bot está parado em um loop, repetindo a mesma mensagem consecutivamente"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respostas do bot em conversas"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um tipo de bot envolvido na conversa"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um bot envolvido na conversa"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O conteúdo da conversa contém a frase ", _interpolate(_list(0)), " nas mensagens dos agentes"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifique automaticamente as chamadas que não têm a declaração de divulgação obrigatória, como \"Esta chamada será gravada\" e similares."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma pessoa ou bot envolvido na conversa"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente ou bot que está sendo avaliado na conversa"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente procurou assistência de nível superior"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentos positivos e negativos detectados na conversa"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica o agente mais ativo em conversas por análise de IA"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente encerrou a conversa com educação"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente compreende e reconhece os sentimentos dos clientes"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa os erros de gramática, ortografia e de estilo do agente"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa toda a conversa em busca de frases de saudação típicas"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente compreendeu a dúvida ou preocupação do cliente"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa a facilidade de compreensão de um texto, considerando a complexidade da palavra e o comprimento da frase"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa toda a conversa em busca de uma solução oferecida"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa o tom do agente ao longo da conversa"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinais de desgaste do cliente. O cliente estava considerando uma troca ou prometeu não fazer mais negócio."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo ao qual pertence a conversa."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O conteúdo ou texto da mensagem do agente"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respostas do agente em conversas"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente atribuído para avaliar a conversa"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A duração da conversa na chamada, em segundos"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O conteúdo ou texto da conversa"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O canal de comunicação usado para a conversa (por exemplo, e-mail, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários adicionados à conversa"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que um comentário foi adicionado à conversa"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimentos positivos e negativos detectados na conversa"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que o cliente respondeu à pesquisa de satisfação"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a avaliação da conversa é contestada"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O canal usado pelo cliente para iniciar a conversa"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respostas de clientes em conversas"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data da última mensagem na conversa"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A função ou o tipo de usuário envolvido na conversa"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O silêncio entre o agente e o cliente excedeu o limite"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente procurou ajuda de um nível superior"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente se mostrou extremamente agradecido ou satisfeito com o suporte recebido"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente ou o agente solicitou explicitamente um acompanhamento"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem de texto ou gravação de toda a conversa conduzida por voz"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a conversa está marcada com estrela"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags usadas em comentários para categorizar o conteúdo"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma da conversa detectado pela IA"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data da última mensagem na conversa"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de observações privadas nas conversas"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número médio de caracteres por mensagem em conversas"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respostas voltadas para os clientes em conversas"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um participante cujas informações são visíveis publicamente"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número médio de palavras por mensagem em conversas"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias usadas para classificar ou avaliar a conversa"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O status do processo de avaliação da conversa"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um participante ou uma pessoa envolvida na conversa"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um e-mail associado à conversa"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a avaliação foi visualizada"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O avaliador que avaliou a conversa"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi avaliada pela última vez"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente que está sendo avaliado na conversa"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualquer comentário ou feedback específico fornecido pelo cliente em relação à satisfação dele com a conversa"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O score de satisfação do cliente para a conversa"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de satisfação do cliente por mensagem na conversa"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tabela de desempenho usada para avaliar a conversa"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determinados SLAs que foram violados"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de origem ou conexão que originou a conversa"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa foi incomum ou atípica e houve mais trocas de mensagens para chegar a uma resolução"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pergunta da pesquisa relacionada ao conteúdo da conversa"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O título ou nome da pesquisa associada à conversa"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente atribuído para avaliar a conversa"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A marca ou o produto/serviço específico associado à conversa"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O canal de comunicação usado para a conversa (por exemplo, e-mail, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi fechada ou resolvida"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi inicialmente criada"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi inicialmente criada"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que o cliente respondeu à pesquisa de satisfação"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma breve descrição ou resumo da conversa"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma ID de referência externa vinculada à conversa"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pasta ou agrupamento específico no qual a conversa é organizada"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O formulário ou modelo usado para estruturar a conversa"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma ID de referência externa vinculada à conversa"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A caixa de correio ou conta de e-mail por meio da qual a conversa foi recebida"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de telefone associado à conversa"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nível de prioridade atribuído à conversa"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número total de respostas na conversa"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O estado ou o status atual da conversa"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O status atual da conversa"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O assunto ou tópico da conversa"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de respostas do agente em conversas"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags atribuídas para categorizar a conversa"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo ou a categoria da conversa"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi atualizada pela última vez"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data em que a conversa foi atualizada pela última vez"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O meio ou o canal pelo qual a conversa ocorreu"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O conteúdo de uma transcrição em uma conversa"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas que não estão qualificadas para avaliação por meio da análise de IA"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de agentes participantes nas conversas"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a conversa foi visualizada"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualquer mensagem"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eficiência da comunicação do bot"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetição do bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas do bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção da chamada"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem do cliente"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de comunicação"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgação de gravação ausente"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encaminhamento"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo da transcrição da chamada"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com estrela"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não qualificado para avaliação"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado por"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa por"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e gramática"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem do agente"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente mais ativo"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas do agente"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração da chamada (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo de conversa"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal da mensagem"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag de comentário"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do comentário"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimento"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de resposta da pesquisa de CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestação"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal do cliente"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas do cliente"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da última resposta"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de usuário"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risco de perda de cliente"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de comunicação"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encaminhamento"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atendimento excepcional"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhamento"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcrição"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag do comentário"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caso atípico"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compreensão"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da última resposta"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de observações privadas da conversa"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem média de caracteres"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem média de palavras"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas públicas"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante público"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da classificação"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificar e comentar"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da avaliação"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidade"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail relacionado"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionado"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação recebida"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho avaliada"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação visualizada"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da avaliação"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário da pesquisa"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT por mensagem"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de satisfação (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solução oferecida"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de origem"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assunto"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta da pesquisa"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título da pesquisa"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca de conversa"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domínio da marca"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversa"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do fechamento"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de resposta da pesquisa de CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de envio da pesquisa de CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição da conversa"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externa"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo de conversa"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta da conversa"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulário da conversa"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de conversa"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externa"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa de correio de conversa"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridade da conversa"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas da conversa"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado da conversa"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da conversa"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assunto da conversa"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contagem de respostas do agente"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag da central de suporte"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag da central de suporte"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de conversa"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da atualização"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da atualização"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo da transcrição"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de agentes participantes"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status visualizado"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurso de vulnerabilidade"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integridade da vulnerabilidade"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento de vida útil de vulnerabilidade"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria do AutoQA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione uma condição relacionada a data"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione um nome ao seu filtro"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira um nome para o filtro"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoa conectada"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex-membros do espaço de trabalho"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros do espaço de trabalho"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adicionar ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar filtro"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deve ter pelo menos um filtro relacionado a datas"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar opções"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar uma opção"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina em ", _interpolate(_named("n")), " dia. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>em ", _interpolate(_named("n")), " dia</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina em ", _interpolate(_named("n")), " dias. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>em ", _interpolate(_named("n")), " dias</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina hoje. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua avaliação termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>hoje</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure uma assinatura agora"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dias"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você tem ", _interpolate(_named("days")), " restantes na sua avaliação"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o limite de usuários para o seu plano. ", _interpolate(_named("link")), " para saber mais ou atualize o plano."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique aqui"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectar sua central de suporte"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso nos permitirá extrair seus tickets para que você os avalie."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte sua central de suporte"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você está usando o Zendesk QA com dados de exemplo. Para apagar dados de exemplo e importar suas próprias conversas, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados de exemplo"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar informações úteis abaixo"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça uma captura de tela de página inteira para fornecer contexto"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com nossa equipe de suporte ao cliente para resolver esse problema:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência do erro:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar informações úteis"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários, bots e espaços de trabalho"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários e espaços de trabalho"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoje"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esse mês"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["essa semana"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " avaliado(s) ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " avaliado(s) ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " avaliado(s) ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quais são as novidades?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atalhos de teclado"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Converse conosco"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível carregar nosso chat (Você está usando um bloqueador de anúncios?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidade"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página inicial"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações fornecidas"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de conhecimento"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de conta"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefas"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O aplicativo foi atualizado. ", _interpolate(_named("buttonStart")), "Recarregar", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você quiser começar a usar o Zendesk QA como parte do seu espaço de trabalho:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Queremos avisar que você já recebeu um convite para usar o Zendesk QA como parte de um espaço de trabalho. Se quiser, você pode continuar. No entanto, observe que essa ação criará um ", _interpolate(_named("bold")), " para você."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organização separada"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sim, quero continuar com ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uma nova conta"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " pede para você participar de ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem conexão com a internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar novo item fixado"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegação rápida"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focar e expandir o editor de comentários"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar ou exibir painel lateral"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegar para a próxima conversa"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir conversa original"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegar para a conversa anterior"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuir a todas as categorias a classificação mais alta possível"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuir a todas as categorias a classificação mais baixa possível"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embaralhar as conversas (reclassificar aleatoriamente)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diga-nos"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atalhos de teclado"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modal de alternância dos atalhos"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o limite de ", _interpolate(_named("charLimit")), " caracteres"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O tipo da imagem ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") não é permitido"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O tamanho da imagem ", _interpolate(_named("name")), " é muito grande (5 MB permitidos)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao carregar o editor de comentários"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solte para carregar"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagem"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar modelos"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum modelo adicionado"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar causa principal"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros, incluindo motivos de exclusão"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo da classificação"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de funcionários"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraremos em contato com você para ajudar a configurar sua conta"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da empresa"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de contato da empresa"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você conheceu o Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar um canal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar um número de agentes"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha todos os campos obrigatórios"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deve concordar em ter lido e consentir com nossos termos e nossa política de privacidade."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal do Slack direcionado ao Support"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicidade"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigo ou colega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace da central de suporte ou gerente de conta"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site de referência"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecanismo de pesquisa (Google, Bing, etc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rede social"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale um pouco sobre você e sua empresa."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da conta"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore recursos, conheça o aplicativo, apague e adicione itens."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta criada. E agora?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto para ter conversas de verdade?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar seus próprios dados"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar a explorar"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça um teste com dados de exemplo"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já podemos exibir tickets para você avaliar."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar sua conta da central de suporte"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudei de ideia."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar inscrição"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Item fixado criado. ", _interpolate(_named("buttonStart")), "Ir para itens fixados", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os itens fixados de instrução estão disponíveis no plano Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar plano"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos os planos"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve suas observações para se preparar para a próxima reunião individual ou sessão de instrução."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados para instrução"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentar ou adicionar outros com ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa conversa foi apagada"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um novo item fixado"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar item fixado"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar item fixado"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como os itens fixados funcionam"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem permissão para visualizar essa conversa"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar cartão"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar conversa"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar painel"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item fixado salvo"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar este cartão"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar esta conversa"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar este painel"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar em um usuário"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um usuário"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Só você pode ver o que é fixado. Para compartilhar com outras pessoas, ", "@", "mencione-as nos comentários."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Um convite para esta conversa foi enviado a ", _interpolate(_named("agentsNames"))]), _normalize(["Um convite para esta conversa foi enviado a ", _interpolate(_named("agentsNames"))])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 comentário"]), _normalize([_interpolate(_named("n")), " comentários"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Você mencionou ", _interpolate(_named("participants")), ", mas essa pessoa não está na conversa."]), _normalize(["Você mencionou ", _interpolate(_named("participants")), ", mas essa pessoa não está na conversa."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " participantes"]), _normalize([_interpolate(_named("n")), " participante"]), _normalize([_interpolate(_named("n")), " participantes"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deseja remover o acesso de ", _interpolate(_named("agentName")), " a esse item fixado?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não fazer nada"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não convidar para essa conversa"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar comentário"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar para esta conversa"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover usuário"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso não pode ser desfeito."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso também apagará todos os comentários nesse item fixado."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " novas mensagens"]), _normalize([_interpolate(_named("n")), " nova mensagem"]), _normalize([_interpolate(_named("n")), " novas mensagens"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somente você pode ver seus itens fixados. Para compartilhar itens fixados, ", "@", " alguém nos comentários."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " fixou ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um painel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uma conversa"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uma observação"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualize para obter itens fixados de instrução"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os itens fixados de instrução estão disponíveis no plano Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os itens fixados de instrução estão disponíveis no plano Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualize para obter o recurso"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare-se para sua reunião individual ou salve informações para mais tarde."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso apagará permanentemente o item fixado e todos os comentários adicionados."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar item fixado"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar item fixado"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da pasta"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar itens para conferir mais tarde"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro os recentes"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro os antigos"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve uma conversa ou tendência interessante do painel na preparação para suas reuniões individuais ou anote os dados para usar depois."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um item fixado clicando no botão acima ou no ícone de item fixado na visualização do painel ou da conversa."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixar"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item fixado criado"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nenhum resultado para ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente usar palavras-chave diferentes ou verifique se há erros de digitação."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar um novo item fixado"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagens"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por usuário, palavra-chave..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em ordem alfabética"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado recentemente"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado recentemente"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais itens fixados"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pretendo avaliar ", _interpolate(_named("n")), " conversa ", _interpolate(_named("schedule"))]), _normalize(["Pretendo avaliar ", _interpolate(_named("n")), " conversas ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta atualizada"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir meta"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode definir uma meta pessoal para o número de conversas que deseja avaliar."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diariamente"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensalmente"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semanalmente"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta de avaliação"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PESSOAL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando canais"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar canal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar apenas feedback da pesquisa com comentários"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacite seus colegas destacando a qualidade excepcional do serviço e o excelente feedback dos clientes."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar feedback positivo da pesquisa com o Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum canal encontrado"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar automaticamente feedback com scores acima"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot do Praise integrations"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você ainda não tiver feito nenhuma avaliação, receba um lembrete no início da semana."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembretes"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrete semanal para fazer avaliações"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receba notificações sobre novas avaliações, respostas, menções e contestações no Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menções"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas da conversa"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrações com o Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre novas avaliações, respostas, menções e contestações."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações relacionadas a mim"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menções nos comentários"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novas avaliações recebidas"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas nas conversas"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório com estatísticas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber relatórios sobre avaliações dadas e recebidas."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatórios"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diariamente"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensalmente"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semanalmente"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diariamente"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cada 3 horas"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cada 6 horas"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantaneamente"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensalmente"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativado"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanalmente"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O administrador da sua conta bloqueou as configurações de notificação."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar período"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações pessoais"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (ativo)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione uma nova entrada de rede social a esta conta:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A remoção de um método ativo de entrada irá desconectar você. Você pode entrar novamente com seu e-mail alternativo."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover método de entrada"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Google foi adicionado como uma opção de entrada"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de entrada"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Slack foi adicionado como uma opção de entrada"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar senha"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir senha"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um e-mail será enviado a ", _interpolate(_named("email")), " para você redefinir a senha."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail principal"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se você recebeu o e-mail de confirmação"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link mágico"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para mudar isso, altere seu ", _interpolate(_named("link")), " com antecedência."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O e-mail ", _interpolate(_named("email")), " será associado a essa senha."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil atualizado"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagem do perfil"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obter os melhores resultados, carregue uma foto quadrada (256x256 pixels ou mais)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail principal"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início da semana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações pessoais"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione pelo menos uma opção"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo do modelo"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar modelo em"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar conteúdo do modelo"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um nome de modelo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar modelo"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar modelo"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie modelos de comentários para acelerar a avaliação, fixação de itens e instrução."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado em"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar modelo"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar permanentemente este modelo?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar modelo"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie modelos de comentários para acelerar a avaliação, fixação de itens e instrução."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum modelo criado"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de comentário criado"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de comentário apagado"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo de comentário salvo"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações e contestações"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para obter acesso, entre em contato com ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suporte ao cliente"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta encontrada"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por enquanto, use ", _interpolate(_named("link")), " para acessar o Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para o Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não migrada"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["métricas"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scores"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estatísticas"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calibração"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversas"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtros"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliação"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecido"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meus comentários"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minhas contestações"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minhas avaliações"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reações"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciado"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da pesquisa"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividade"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["criar senha"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credenciais"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entrar"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redefinir"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meta da avaliação"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelos"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuário"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar modelo"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelos"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conta"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faturamento"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empresa"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de suporte"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrações"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organização"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenças"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configurações"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assinatura"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticações"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova categoria"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem conflitante encontrada"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar ou remover uma das mensagens conflitantes"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use o ícone ", _interpolate(_named("placeholder")), " para excluir dados confidenciais do AutoQA. Por exemplo, em vez de \"Atenciosamente, John\", use \"Atenciosamente, ", _interpolate(_named("placeholder")), "\" para representar qualquer nome."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Máximo ", _interpolate(_named("limit")), " linhas"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar regra a"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o limite de 10 categorias personalizadas"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o limite de ", _interpolate(_named("limit")), " categorias personalizadas"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar condição"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar linha"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar condição"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a mensagem do agente"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caso contrário, score"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Então, score"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar categoria da tabela de desempenho"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use \"/\" para adicionar uma variável"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as direções"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem do agente não pode ficar vazia"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem não pode ter mais de 300 caracteres"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as origens"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os canais"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as tags"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as conversas"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrinja onde sua regra se aplica."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas específicas"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar insight do Destaque"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mensagem do agente não pode ficar vazia"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o limite de 10 insights personalizados"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você atingiu o limite de ", _interpolate(_named("limit")), " insights personalizados"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha o nome do insight"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do insight"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar categoria"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de insight"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para as configurações do espaço de trabalho"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora não"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para começar a usar essa categoria de classificação, acesse as configurações de tabela de desempenho do espaço de trabalho e adicione-a a um tabela de desempenho de sua escolha."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação criada"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontue automaticamente as conversas fechadas identificando palavras-chave ou frases específicas."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não funciona em transcrições de voz."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um forte abraço! ✋ Você atingiu o máximo de suas categorias de classificação automatizada em ", _interpolate(_named("limit")), ". Você é o mago da categorização! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de categoria atingido"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar se todos os campos foram preenchidos corretamente"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribua um nome à categoria de classificação"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da categoria"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova categoria de classificação"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova categoria de classificação"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontue automaticamente as conversas fechadas identificando palavras-chave ou frases específicas. Não funciona em transcrições de voz."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exiba automaticamente conversas fechadas identificando palavras-chave ou frases específicas. Funciona apenas com transcrições."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifique as conversas automaticamente identificando palavras-chave ou frases específicas nas mensagens do agente e do bot."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usar essa categoria de classificação, acesse as configurações da tabela de desempenho do seu espaço de trabalho e adicione-a a uma tabela de desempenho."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontue as conversas automaticamente com base em palavras-chave ou frases específicas nas mensagens."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível para"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração do Insight"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse insight é exibido no Spotlight com base em critérios definidos."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuração da tabela de desempenho"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa categoria pode ser usada em tabelas de desempenho com base em critérios definidos."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em breve"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personalizado"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predefinido"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tabela de desempenho"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destaque"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voz"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar alterações"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações salvas"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dica:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique no ícone ", "{··}", " para excluir nomes, detalhes da empresa ou outras informações confidenciais durante as verificações de fechamento do AutoQA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar fechamento aprovado"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar fechamento aprovado"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento aprovado"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar isenções"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar isenção"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenção"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar saudação aprovada"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar saudação aprovada"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação aprovada"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cole palavras aqui, separadas por quebras de linha"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione Enter após cada palavra para uma nova entrada"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma aplicável"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo, em vez de \"Atenciosamente, John\", você pode usar \"Atenciosamente, ", "{··}", "\" para representar qualquer nome."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholder de conteúdo dinâmico"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenções"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 isenção adicionada"]), _normalize([_interpolate(_named("count")), " isenções adicionadas"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Isenção já adicionada"]), _normalize(["Isenções já adicionadas: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar isenção"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma isenção encontrada"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma isenção adicionada"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenção (diferenciação de maiúscula e minúscula)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adicionar isenções"]), _normalize(["Adicionar 1 isenção"]), _normalize(["Adicionar ", _interpolate(_named("count")), " isenções"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por uma palavra ou frase"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenção salva"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os idiomas"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar pelo idioma"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Fechamento já adicionado"]), _normalize(["Fechamentos já adicionados: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar fechamento"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum fechamento aprovado encontrado"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum fechamento aprovado"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar fechamento"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por um fechamento"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento adicionado"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento salvo"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O formulário tem entradas duplicadas: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Saudação já adicionada"]), _normalize(["Saudações já adicionadas: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar saudação"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma saudação aprovada encontrada"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma saudação aprovada"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar saudação"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar uma saudação"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação adicionada"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação salva"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar isenção"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Apagar isenção"]), _normalize(["Apagar isenções"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Apagar permanentemente essa isenção?"]), _normalize(["Apagar permanentemente essas isenções?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar isenção"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uma ou mais isenções acima de ", _interpolate(_list(0)), " caracteres"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " caracteres"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado por"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variável"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina suas próprias saídas, de modo que se alinhem aos padrões da sua marca e do seu serviço. Quando isso for adicionado, o AutoQA só detectará fechamentos aprovados. As alterações feitas serão aplicadas a conversas futuras."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o limite máximo de 100 fechamentos aprovados. Se você quiser adicionar mais, talvez seja hora de rever e refinar seus fechamentos existentes."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de fechamento aprovado atingido"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamentos aprovados"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras e frases que você deseja que sejam ignoradas pelo AutoQA como erros de ortografia ou gramática. As alterações feitas serão aplicadas a conversas futuras."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar lista"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especifique suas próprias saudações, de modo que se alinhem aos padrões da sua marca e do seu serviço. Quando isso for adicionado, o AutoQA só detectará saudações aprovadas. As alterações feitas serão aplicadas a conversas futuras."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o limite máximo de 100 saudações aprovadas. Se você quiser adicionar mais, talvez seja hora de rever e refinar as saudações existentes."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de saudações aprovadas atingido"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudações aprovadas"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora não"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar permanentemente esta categoria de classificação?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de categorias"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A categoria apagada não será exibida em novas conversas e filtros. Os dados históricos em painéis e conversas permanecem intactos."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A categoria será removida de todas as tabelas de desempenho aos quais foi adicionada."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao apagar a categoria da tabela de desempenho"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria da tabela de desempenho apagada"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria da tabela de desempenho"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias do AutoQA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " viabilizadas pelo modelo de IA permanecerão na tabela de desempenho, mas não serão mais pontuadas automaticamente"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar a integração do LLM não desativa completamente o recurso AutoQA . Eis o que acontecerá:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados históricos"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " permanecerão no painel do AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais adicionadas manualmente"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " permanecerão, mas todas as causas principais baseadas no LLM serão removidas"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar o AutoQA baseado em LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O AutoQA usa IA e modelos de linguagem grande (LLMs) para pontuar automaticamente categorias específicas. Seus dados são processados sob um contrato de processamento de dados (DPA) estrito e nunca são usados para treinar modelos."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre IA generativa"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O AutoQA usa o Serviço OpenAI do Microsoft Azure para ", _interpolate(_named("automatically_scoring_categories")), ". Seus dados são processados em um DPA restrito para fornecer o AutoQA apenas para você e ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a pontuação automática de algumas categorias"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nunca é usado para treinar modelos OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA baseado em LLM ativado"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA baseado em LLM desativado"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA baseado em LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontuação automatizada com IA no AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processamento de dados do MS Azure com base em categorias do AutoQA"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimento do Microsoft Azure removido"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimento do Microsoft Azure fornecido. As conversas receberão classificações automáticas em breve."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda pode usar o AutoQA, mas veja o que vai acontecer"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desabilitar o serviço MS Azure OpenAI não desativará o AutoQA como uma funcionalidade."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode fazer isso na visualização da categoria da tabela de desempenho."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As categorias do AutoQA impulsionadas pelo modelo permanecerão na tabela de desempenho, mas não receberão uma pontuação automática."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As causas principais que você adicionou manualmente permanecerão. Todas as causas principais geradas pelo modelo serão removidas."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados históricos permanecem no painel da AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desabilitar o processamento de dados pelo serviço MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gama mais ampla de categorias do AutoQA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte multilíngue pronto para uso."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adição rápida de novas categorias."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisão da avaliação aprimorada. A GPT-3.5 mostra o reconhecimento contextual a nível humano."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aproveite os últimos avanços na tecnologia de IA."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja o que você terá"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar o processamento de dados pelo serviço MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimento fornecido"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem consentimento fornecido"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os novos usuários foram atribuídos a este espaço de trabalho"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaço de trabalho padrão"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Horário de 12 horas (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Horário de 24 horas (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de hora"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seg"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil da empresa atualizado"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início da semana"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração personalizada"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ID da chave de acesso e a chave secreta são normalmente encontradas em Gerenciamento de acesso > Usuários"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da chave de acesso da AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por exemplo, AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar alteração da chave de acesso secreta"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar valor da chave de acesso secreta"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conexão permite definir apenas uma região, que normalmente é encontrada em Localização"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região da AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por exemplo, eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma chave secreta"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave de acesso secreta da AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por exemplo, wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode encontrar todas as informações relevantes no Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalmente encontrado nas configurações da instância do Amazon Connect e na página Armazenamento de dados"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de armazenamento de gravações de chamada"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por exemplo, amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao adicionar o Amazon Connect. Verifique se todos os campos foram preenchidos corretamente e tente novamente."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O local de armazenamento deve estar no formato de intervalo/pasta separado por uma barra."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para adicionar uma conexão, entre em contato com ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte ao cliente"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os seus tickets do Zendesk serão sincronizados automaticamente. Você pode optar por excluir tickets selecionados."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ásia-Pacífico (Tóquio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ásia-Pacífico (Seul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ásia-Pacífico (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ásia-Pacífico (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ásia-Pacífico (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadá (Central)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zurique)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irlanda)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londres)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região do servidor"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oriente Médio (EAU)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a região do servidor"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["América do Sul (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leste dos EUA (Virgínia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leste dos EUA 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeste dos EUA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão já adicionada"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar conexão"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar apenas as conversas que"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canal um, canal dois"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A ID exclusiva dessa conexão é ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mês"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ano"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 meses"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 meses"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As conversas inativas serão apagadas após o período de retenção selecionado. As avaliações enviadas permanecem visíveis nas visualizações de atividade e de painel."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de retenção"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar período de retenção"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observe que essa ação é irreversível."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendo que isso removerá todos os dados relacionados à central de suporte para essa conexão do Zendesk QA, incluindo conversas, campos e tags."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As avaliações enviadas não serão afetadas e permanecerão visíveis em visualizações específicas."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupo um, grupo dois"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluem"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desenvolvimento ativo"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa integração ainda está em ", _interpolate(_named("activeDevelopment")), ". Alguns detalhes podem estar faltando (como anexos ou campos personalizados)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caixa de correio um, caixa de correio dois"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag um, tag dois"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token da API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segredo do token da API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre \"Nomes do campo de ticket\" no Freshdesk em Configurações → Campos de ticket"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre \"Custom Field Names\" no Help Scout em Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre \"Nomes de conversa de Klass\" no Kustomer em Configurações → Klasses → Conversa"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre \"Nome do campo personalizado\" no Live Agent em Configurações → Campos personalizados"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre \"Nome do campo personalizado\" no Wix em Configurações → Campos personalizados"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe os IDs dos campos com vírgulas. Veja esses IDs no Zendesk em Administração → Campos de ticket."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte alguns usuários da sua central de suporte para avaliar suas primeiras conversas. Não se preocupe, não vamos notificá-los a menos que você os convide a se tornarem membros do espaço de trabalho."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração feita com êxito"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar membros"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começaremos agora a extrair suas conversas."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domínio: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identificador da central de suporte: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível autenticar sua conexão"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto. Esta é sua nova conexão."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algumas conexões não foram atualizadas ou ficaram inativas. Tente atualizar essas conexões ou remova-as da sua lista."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do cliente da API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segredo do cliente da API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crie um novo token de API em Configurações → Conta → API e SDKs. A URL de redirecionamento a ser usada é ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da conta"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione sua central de suporte para começar"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sua conta da Intercom estiver definida para exibição na União Europeia ou Austrália, atualize essa informação."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O e-mail que você usa para entrar em ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascare o nome, o e-mail e o número de telefone do usuário final. As credenciais bancárias já estão mascaradas para todas as conexões com a central de suporte."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há integrações. Adicione uma agora."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você precisa dessas funções:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar conexão"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma nova conexão"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desmarcar essa opção permite que você adicione essa conexão manualmente apenas a espaços de trabalho específicos"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar-se a todos os espaços de trabalho existentes"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os espaços de trabalho"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave da API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segredo da chave da API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canais"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conexão ", _interpolate(_list(0)), " apagada"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da conexão"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode obter seu token da API abaixo."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar conexão ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite \"apagar conexão\" para remover essa conexão"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conexão"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerencie e monitore suas origens de dados conectadas."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar conexão"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluem"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe os nomes dos campos com vírgulas"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao atualizar"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribua um nome exclusivo à sua integração"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar opções avançadas"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar campos de dados confidenciais"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca armazenar URLs de anexos"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar anexos"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca armazenar o conteúdo da conversa (você pode extrair mais tarde de sua central de suporte sob demanda)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar conteúdo da conversa"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca armazenar o assunto da conversa (você pode extrair mais tarde de sua central de suporte sob demanda)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar linha de assunto"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Seu subdomínio ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região da Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondência da ID da equipe da caixa de entrada"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID numérica de sua equipe da Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em andamento..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixas de correio"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar conexão manualmente"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascarar dados do cliente"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do campo confidencial"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do campo confidencial"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovar conexão"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovar conexão"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar-se à sandbox do Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Configuração de uma conexão com ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar opções avançadas"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coletar apenas conversas com score menor ou igual ao limite definido"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar um limite de CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar apenas as conversas que..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver conversas mais recentes"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar conexão"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão atualizada"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome de usuário"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua central de suporte"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para cancelar a assinatura e apagar a conta, entre em contato com ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte ao cliente"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conta"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar um ticket no Zendesk Support (ou em outra central de suporte) não afeta as avaliações de conversas associadas. Você ainda pode visualizá-las e gerenciá-las."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ao apagar um ticket no Zendesk QA, você também apagará as avaliações de conversas associadas.", _interpolate(_named("line_break")), "Apagar um ticket no Zendesk Support (ou outra central de central de suporte) não afetará as avaliações de conversas associadas. Você ainda poderá visualizá-las e gerenciá-las."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar avaliações"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A exclusão de avaliações é permanente. Ela também afeta os dados nos painéis."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar todas as avaliações"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tudo"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as avaliações apagadas"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tudo"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando houver avaliações associadas aos tickets apagados, você as verá aqui."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há avaliações"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por ID do ticket"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID do ticket: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas apagadas"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Os espaços de trabalho padrão não podem ser apagados. Para apagar este espaço de trabalho, selecione outro espaço de trabalho padrão em ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da conta"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação não pode ser desfeita."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remover e apagar todos os dados"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Entendo que isso vai ", _interpolate(_named("removeAllData")), " relacionados a esse espaço de trabalho, incluindo usuários e conexões."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar espaço de trabalho"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite \"apagar espaço de trabalho\" para apagar esse espaço de trabalho"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar espaço de trabalho ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apagar espaço de trabalho"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se do que está fazendo ou consulte um profissional com antecedência."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha se o avaliado padrão é selecionado com base no agente mais frequente ou no atribuído atual."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente mais ativo"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado padrão"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permita que as pessoas avaliem as próprias conversas e que os agentes façam avaliações"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O espaço de trabalho foi apagado"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O espaço de trabalho foi atualizado"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estabeleça uma classificação neutra ocultando avaliações fornecidas anteriormente por outros avaliadores"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação imparcial"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cor"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação não pode ser desfeita."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observe que isso removerá e apagará todos os dados relacionados a esse espaço de trabalho, incluindo usuários e conexões do espaço de trabalho."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar espaço de trabalho"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagando espaço de trabalho..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link mágico"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO do Okta"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link mágico"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível desativar seu método atual de entrada"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os métodos de autenticação foram atualizados"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir determinados tipos de métodos de autenticação para todos os usuários."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticações"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTA"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários e espaços de trabalho"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie e gerencie espaços de trabalho e convide ou conecte usuários."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanecer na página"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair da página"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair desta página e perder todas as alterações?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticações"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que não há conversas para importar. Comece conversando com sua central de suporte e volte aqui para analisá-las."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa encontrada"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível apagar esta categoria do sistema"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A exclusão de categorias é permanente. Isso apagará todos os dados de categoria dos relatórios."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar essa categoria, você precisará criá-la novamente."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por categoria"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As categorias automáticas preenchem automaticamente a tabela de desempenho com índices sugeridos para economizar tempo na avaliação. As categorias manuais são preenchidas pelos avaliadores desde o início."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última atualização"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automático"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e gramática"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compreensão"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidade"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solução oferecida"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma categoria encontrada"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma causa raiz encontrada"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tabela de desempenho encontrada"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente outra pesquisa ou altere os filtros"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorias"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorias"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorias"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as categorias"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorias"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tabelas de desempenho"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tabelas de desempenho"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tabelas de desempenho"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as tabelas de desempenho"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tabelas de desempenho"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os status"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os tipos"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipos"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaços de trabalho"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaços de trabalho"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho encontrado"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaços de trabalho"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os espaços de trabalho"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " espaços de trabalho"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O peso de ", _interpolate(_named("weight")), " contribui com ", _interpolate(_named("percentage")), "% do score geral"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tabela de desempenho"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A exclusão de tabelas de desempenho é permanente. Isso apagará todos os dados da tabela de desempenho dos relatórios."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar essa tabela de desempenho, você precisará criá-la novamente."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tabela de desempenho"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última atualização"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunho"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Escala de classificação atualizada. ", _interpolate(_named("buttonStart")), "Comece a avaliar", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA define uma escala binária por padrão, mas você também pode escolher uma escala mais granular:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se quiser ver outras opções de escala de classificação, configuradas por categoria, acesse as ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia/Tom"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conhecimento sobre o produto"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao atualizar as escalas de classificação:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua escala de classificação anterior e as categorias correspondentes serão arquivadas (não apagadas)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguns dados do painel serão afetados, pois suas escalas antiga e nova podem não ser comparáveis."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clonar minha configuração atual de categorias de classificação para a nova escala"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja atualizar as escalas de classificação?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É possível alterar o formato da exibição da tabela de desempenho (números ou emojis) e tornar as categorias obrigatórias ou opcionais."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar emojis em vez de números"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que as pessoas ignorem categorias"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solução"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de escala de classificação"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Categorias para 1 espaço de trabalho foram clonadas"]), _normalize(["Categorias para ", _interpolate(_named("workspacesAmount")), " espaços de trabalho foram clonadas"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações do espaço de trabalho"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exiba automaticamente chamadas em que o agente não avisa o cliente para esperar um silêncio na conversa."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa tem momentos de perda de comunicação que duram mais do que o limite definido."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir o limite máximo de perda de comunicação em chamadas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de comunicação"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O insight apagado não será exibido em novos filtros e conversas. Os dados históricos em painéis e conversas permanecem intactos."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao apagar o insight do Destaque"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight do Destaque apagado"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar insight do Destaque"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualize conversas contendo palavras ou frases específicas em transcrições e mensagens."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifique automaticamente as chamadas que não têm a declaração de divulgação obrigatória, como \"Esta chamada será gravada\" e similares."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem como objetivo identificar uma declaração de divulgação de gravação na chamada usando um modelo de linguagem grande (LLM) que entende dinamicamente o contexto, eliminando a necessidade de frases predefinidas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgação de gravação ausente"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exiba automaticamente chamadas em que o agente não avisa o cliente para esperar um silêncio na conversa."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda de comunicação"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificar momentos de perda de comunicação em uma chamada"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Precisa fazer alterações em sua assinatura? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), "), e vamos ajudar você."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analise automaticamente 100% das conversas dos agentes, BPOs, canais e idiomas. A IA detecta problemas, lacunas de conhecimento e oportunidades de instrução para melhorar o serviço."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% de cobertura do AutoQA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback e instrução dos agentes"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights de IA do Destaque"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatórios e painéis avançados"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise e filtragem de sentimento"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduções sob demanda"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar as atribuições e a definição de metas"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruções e quizzes"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle de qualidade (QA) do Zendesk"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo do Professional, mais"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (legado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks para notificação imediata de falhas críticas"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração do data warehouse"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração do Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvimento de integrações personalizadas"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente de sucesso do cliente dedicado"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso direto às nossas equipes de produto e engenharia pelo Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo do Growth Suite, mais"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA para 100% de cobertura"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights de conversa com IA para entender os dados"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtragem e enriquecimento de dados da central de suporte com IA aprimorada"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em destaque: detecção automatizada de interações de avaliação obrigatória"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise e filtragem de sentimento"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights e relatórios de desempenho viabilizados por IA"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradução sob demanda da conversa"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar o controle de horas"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise da taxa de aprovação"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo do IA Suite, mais"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacote de implementação Enterprise, incluindo integração Salesforce e Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvimento de integrações personalizadas"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração do data warehouse"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks para notificação imediata de falhas críticas"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente de sucesso do cliente dedicado"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso direto às nossas equipes de produto e engenharia"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso antecipado e influência em nosso plano estratégico"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições da avaliação automática e configuração de metas"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrução e análise direcionada"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes para gerenciamento de aprendizado"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funções de usuário e gerenciamento de permissões"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascaramento de dados confidenciais"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções avançadas de filtro e pesquisa de conversa"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho personalizáveis com várias classificações, pesos ajustáveis e opções de reprovação automática"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensão do navegador para avaliar conversas em qualquer lugar"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de causa principal"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatórios de desempenho para comparação de agentes, equipes e BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa de CSAT e análise de feedback do cliente"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo do Iniciante, mais"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (legado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várias escalas de classificação por conversa"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração com o Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights de conversa interativos e orientados por IA"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar atribuições"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itens fixados para instrução"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões de instrução"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise e filtragem de sentimento"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtragem com base na complexidade da conversa"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights de conversa"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho condicionais"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de causa principal"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análises de CSAT viabilizadas por IA avançada"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração de classificação"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensão do navegador para avaliar conversas em qualquer lugar"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque para as conversas de avaliação obrigatória"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradução sob demanda da conversa"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar o controle de horas"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plano personalizado"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciante (legado)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações de conversas ilimitadas"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportação CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações e avaliações entre pares"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho com pesos ajustáveis e opções de reprovação automática"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções robustas de filtros"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metas de avaliação diária, semanal ou mensal"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas de CSAT e análises básicas"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painéis"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatórios de comparação de agentes, equipes e BPOs"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funções de usuário e gerenciamento de privilégios"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quero fazer uma alteração na minha assinatura do Zendesk QA.\n\nDestaque ou indique uma opção e indique o número de licenças que você gostaria de alterar:\nGostaria de [adicionar X licenças] [remover X licenças] [atualizar para o plano Zendesk QA].\n\nCompartilhe o nome da sua empresa e o nome associado à sua conta do Zendesk QA, além de quaisquer outros detalhes que devemos saber para processar essa alteração."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração da assinatura do Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quero comprar uma assinatura do Zendesk QA.\n\nCompartilhe o nome da sua empresa e o nome associado à sua conta do Zendesk QA, além de quaisquer outros detalhes que devemos saber para processar essa solicitação:\n\nCompartilhe também perguntas específicas que você gostaria que fossem respondidas antes de comprar:\n\nObrigado!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquisição da assinatura do Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua assinatura termina em ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " restante(s))"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua avaliação termina em ", _interpolate(_named("date")), " (tempo restante: ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dia"]), _normalize([_interpolate(_named("n")), " dias"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cobrança no ", _interpolate(_named("card")), " (com data de validade em ", _interpolate(_named("expires")), ") no dia ", _interpolate(_named("dayOfMonth")), " de cada mês."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faturado para ", _interpolate(_named("card")), " (expira ", _interpolate(_named("expires")), ") em ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["que termina com"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Expirado"]), _normalize(["expira em 1 dia"]), _normalize(["Expira em ", _interpolate(_named("expiresIn")), " dias"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O preço inicial inclui ", _interpolate(_named("n")), " licenças de usuário"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["começa em ", _interpolate(_named("sum")), "/mês"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mês"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por usuário/mês"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por ano"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por mês"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestre"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remova o cancelamento programado"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se quiser continuar usando o Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua assinatura foi cancelada em ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sua assinatura está programada para expirar em ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avaliação de ", _interpolate(_named("days")), " dias sem custos adicionais!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usada(s)"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você tem ", _interpolate(_named("n")), " usuários. A contagem de licenças não pode ser menor que isso."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A assinatura será retomada em ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ano"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seu plano atual do Zendesk QA (", _interpolate(_named("plan")), ") não inclui recursos viabilizados por IA. Para liberar todo o poder dos recursos de inteligência do Voz, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para cancelar sua assinatura, entre em contato ", _interpolate(_named("link")), " ou converse conosco."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plano de gerenciamento de qualidade"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de qualidade"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura atualizada"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um cartão de crédito"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os planos"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturamento"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultar as informações de faturamento"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de faturamento"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar assinatura"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua assinatura será cancelada no fim do período de faturamento."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato conosco"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato conosco para editar"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plano atual"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço personalizado"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer downgrade"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual (economia de 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague anualmente"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar assinatura"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conhecer planos"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação grátis"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturado manualmente"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode avaliar e confirmar o resumo final do pedido na próxima etapa."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais popular"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem um método de pagamento ativo."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em avaliação"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retome sua assinatura atual para alterar o plano"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar assinatura"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestral"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reativar assinatura"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retomar assinatura"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenças"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começar avaliação agora"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem renovação"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausado"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da assinatura"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar assinatura"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve alguns instantes para dar feedback sobre a sua experiência de suporte ao cliente. Agradecemos desde já — ", "{", "{", "agentName", "}", "}", " valorize seu feedback!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pesquisa de CSAT de ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você classificaria o suporte recebido de ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Agradecemos a sua participação. Usaremos seu feedback para tornar o ", _interpolate(_list(0)), " melhor para todos."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre as pesquisas"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o tipo de pesquisa"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso pode ser alterado posteriormente."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meça e melhore a satisfação do cliente com pesquisas automatizadas de CSAT em vários idiomas."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma pesquisa criada"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar pesquisa"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da pesquisa"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi criada"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tente alterar a palavra-chave ou ", _interpolate(_named("contact_us")), " se quiser ver o idioma listado aqui"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fale conosco"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum idioma encontrado"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecione um ou mais idiomas na lista abaixo. ", _interpolate(_named("contact_us")), " se não vir um idioma que gostaria de usar listado aqui."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar idiomas"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fale conosco"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar idioma"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Última publicação: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Último salvamento: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pergunta de ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta introdutória"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo de escala à esquerda"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assunto do e-mail"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivos"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rótulo de escala à direita"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se uma pesquisa estiver com traduções ausentes, os textos do idioma de base serão exibidos"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar traduções"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduções ausentes"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lembre-se de avaliar todas as traduções antes de aplicá-las. Faça as alterações no arquivo baixado, salve-o e carregue-o novamente, se necessário. Se um token ", _interpolate(_named("token_example")), " não for exibido como tag, ele está no formato incorreto ou em um campo que não tem suporte para tokens."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar traduções"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradução"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume a conversa e mostra sugestões de comentários."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistente de IA do Zendesk QA (em inglês)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode editar os tipos depois de criá-los"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse tipo já existe. Selecione um nome diferente."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve o tipo personalizado como uma opção selecionável no menu suspenso do tipo de pesquisa e agrupe todas as pesquisas criadas no painel."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite as palavras e pressione Enter para salvar"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tipos personalizados"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome do tipo não pode ficar vazio"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir tipo"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo personalizado não foi salvo. Pressione Enter para salvar."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail do remetente"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do remetente"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Até que ponto você concorda com a declaração: \"O ", _interpolate(_named("agentName")), " facilitou a forma como gerencio meu problema\"?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Foi fácil obter ajuda de ", _interpolate(_named("brandName")), " até agora?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No geral, foi fácil resolver seu problema hoje?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi fácil interagir com nossa equipe?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL da Política de privacidade (opcional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dos Termos de serviço (opcional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Qual a sua avaliação sobre o suporte recebido de ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como foi nosso atendimento para essa solicitação?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual é seu nível de satisfação com o representante de suporte?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Qual é seu nível de satisfação com o atendimento recebido de ", _interpolate(_named("agentName")), " hoje?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta personalizada"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Defina suas próprias pergunta ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite a pergunta personalizada"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar URLs"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir sua mensagem de e-mail"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar o nome de um tipo de pergunta personalizado o atualizará em todas as pesquisas que o usam, sem afetar os dados coletados."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tipos personalizados"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assunto do e-mail"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta introdutória"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pergunta ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo tipo personalizado"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token de personalização"]), _normalize(["Tokens de personalização"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arraste e solte o token de personalização no campo abaixo."]), _normalize(["Arraste e solte os tokens de personalização nos campos abaixo."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversa ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento, só é possível enviar pesquisas de CSAT por e-mail. Estamos trabalhando para oferecer mais oportunidades, como pesquisas de CSAT da central de suporte, pesquisas de SMS e muito mais."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sincronize ou renove a conexão"]), _normalize(["Sincronize ou renove as conexões"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronize ou renove a conexão ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A conexão não está sincronizada corretamente"]), _normalize(["As conexões não estão sincronizadas corretamente."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desmarque a caixa de seleção relevante ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentique agora ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione pelo menos uma regra de envio de pesquisa para ativar a conexão"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa conexão está desativada no momento. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa conexão mascara dados confidenciais do cliente. Para ativar o envio de pesquisa,\n", _interpolate(_named("link")), " nas configurações."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa conexão requer permissões adicionais para enviar pesquisas.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar agora"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA requer mais permissões para a Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " e ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hora"]), _normalize([_interpolate(_named("n")), " horas"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minutos"]), _normalize(["1 minuto"]), _normalize([_interpolate(_named("n")), " minutos"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hora"]), _normalize(["horas"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " para configurar regras de envio de pesquisa."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dia"]), _normalize(["dias"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os links da pesquisa expiram"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os links expiram em"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite responder a pesquisas se tiver passado muito tempo"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " pesquisa ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe as tags com vírgulas ou pressione Enter após a escrita. Certifique-se de que cada tag seja listada com precisão como no Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina as condições com base nas quais a pesquisa é enviada."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de envio da pesquisa"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de envio de acompanhamento da pesquisa"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uma pesquisa ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " já existe.\nResolva o conflito modificando as regras aqui ou em ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você já definiu uma conexão com regras de correspondência para a pesquisa ", _interpolate(_named("name")), ". Resolva o conflito modificando as regras aqui ou em ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outra regra"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar marca"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar condição"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar um valor"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correspondência com as mesmas regras"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um valor"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail enviado em"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhamento por e-mail enviado em"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecho integrado e acompanhamento por e-mail"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar código"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexe as pesquisas do Zendesk QA aos seus e-mails adicionando o código de trecho em sua configuração do Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guia de instalação"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização de trechos"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código do trecho copiado para a área de transferência"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao copiar o trecho"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trecho integrado"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta pesquisa tem alterações não salvas. Salve-a novamente para garantir que o código de trecho reflita as atualizações mais recentes."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar conexão"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conexão adicionada ainda"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira e-mails, chats em tempo real, chamadas telefônicas e muito mais no Zendesk QA com suas conexões"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre a entrega da pesquisa"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de envio da pesquisa"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de envio de acompanhamento da pesquisa"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar regra de marc"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar regra"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + acompanhamento por e-mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte sua conta da Intercom com a pesquisa de CSAT do Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar por"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem recebe a pesquisa e quando"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar regras"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As pesquisas no aplicativo permitem que os clientes respondam a pesquisas sem sair do chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar a Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras de entrega por conexão"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uma pesquisa ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " já existe.\nResolva o conflito desativando a conexão ou modificando as regras aqui ou em ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir as condições com base nas quais a pesquisa é enviada"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Criado por ", _interpolate(_named("name")), " em ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remover ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao remover esse idioma, ele não ficará mais visível para os entrevistados e todos os textos traduzidos serão apagados."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover idioma"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação não pode ser desfeita."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, você pode desativar o idioma ocultando-o da pesquisa. Isso permitirá que você mantenha todas as suas traduções no lugar certo, sem exibi-las para os entrevistados."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Última edição por ", _interpolate(_named("name")), " em ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre idiomas da pesquisa"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Antes de adicionar outros idiomas, conclua as alterações nas etapas de Estilo e conteúdo. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar idioma"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos traduzidos"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visível para os entrevistados"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar o arquivo de tradução"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover idioma"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" baixe um arquivo de tradução"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliar traduções"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tradução concluída. ", _interpolate(_named("review_link")), " ou ", _interpolate(_named("download_link")), " para fazer alterações."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" baixe um arquivo de tradução"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalie o que está faltando"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Algum conteúdo ainda precisa ser traduzido e será substituído por inglês. ", _interpolate(_named("review_link")), " ou ", _interpolate(_named("download_link")), " para adicionar as traduções ausentes."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar a pesquisa"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A pesquisa contém alterações não salvas. ", _interpolate(_named("save")), " para baixar o arquivo de tradução com as últimas alterações."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar arquivo de tradução"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione a escala de classificação da sua preferência"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dá aos clientes um menu suspenso para seleção do motivo da classificação"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de escala à esquerda (opcional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar motivo"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O motivo não pode ficar vazio"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esse motivo já existe"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar lista suspensa por motivos do seu score"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar um motivo e adicioná-lo novamente com o mesmo nome ou renomear um motivo sempre cria uma nova entrada. Como resultado, isso afeta os dados exibidos em seu painel."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar motivo"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um motivo apagado permanece nos relatórios do painel, mas não fica visível para novos participantes da pesquisa. Isso também se aplica às traduções relacionadas."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar um motivo"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Apagar um motivo \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto de dimensionamento à direita (opcional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em uma escala de 5 pontos, 1 e 2 são considerados scores negativos; 3 é neutro; 4 a 5 são positivos"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar texto de escala"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Escala ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de classificação"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Zendesk QA está importando suas conversas. Esse processo pode levar até 24 horas."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso será usado como token de personalização por toda a pesquisa."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da marca"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botões e links"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizar a aparência da sua pesquisa"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plano de fundo do logotipo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte para os formatos JPG, PNG e GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estilo"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar logotipo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair em grande estilo"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira uma mensagem que as pessoas verão após enviarem o feedback"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagem de agradecimento"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de cor hex incorreto"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de e-mail incorreto"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo não pode ficar vazio"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorreta"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não visível:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visível:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas enviadas"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas enviadas nos últimos 30 dias"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi apagada"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi duplicada"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um e-mail de visualização foi enviado"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi publicada"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi atualizada"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa foi pausada"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar pesquisa"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insira o e-mail para enviar a visualização da pesquisa"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seu", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar idioma para visualização"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar um e-mail de visualização"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie uma pesquisa de exemplo para o seu e-mail. As classificações da visualização da pesquisa não serão armazenados."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visualizar a pesquisa ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar visualização de pesquisa por e-mail na web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retomar"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como rascunho"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da marca"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome específico da marca pode ser adicionado na etapa de Estilo"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do cliente"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID do Support"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representante de suporte"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagado"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunho"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausado"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pesquisa de CSAT de ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações não salvas feitas"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar e retomar a pesquisa"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se você não está vendo esse e-mail corretamente, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu não fiquei satisfeito com o suporte que recebi porque..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar motivo"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recapitulação da conversa"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo: o cliente teve problemas ao atualizar o método de pagamento. O agente compartilhou um artigo que pode ser útil. Funcionou."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viabilizado pela IA do Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos de serviço"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agradecemos o feedback!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual é o principal motivo do seu score?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diga por que você atribuiu essa classificação..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto esforço"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo esforço"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clique aqui para abrir a pesquisa"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidade"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua pergunta personalizada é exibida aqui"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Você atribuiu à experiência ", _interpolate(_list(0)), " um score de"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar assinatura"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muito insatisfeito"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muito satisfeito"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observe que as regras de entrega e o status da pesquisa atual não serão incluídos na pesquisa duplicada"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplicar pesquisa \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você usar o trecho de e-mail integrado, certifique-se de removê-lo do Zendesk antes de pausar a pesquisa"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma resposta é coletada quando a pesquisa é pausada."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar pesquisa"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de remover o trecho do Zendesk antes de pausar a pesquisa."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar pesquisa"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você usar o trecho de e-mail integrado, certifique-se de removê-lo do Zendesk antes de apagar a pesquisa."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação apagará permanentemente a pesquisa. Os usuários que receberam a pesquisa ainda poderão preenchê-la e todos os dados relacionados à pesquisa permanecerão no Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar pesquisa ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de remover o trecho do Zendesk antes de apagar a pesquisa."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suas alterações aplicadas serão vistas pelos novos entrevistados."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar pesquisa"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie a versão mais recente do trecho integrado após a publicação da pesquisa."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você usar o trecho de e-mail integrado, certifique-se de copiá-lo para o Zendesk após retomar a pesquisa."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue enviando a pesquisa e coletando respostas."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar e retomar a pesquisa"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de adicionar o trecho integrado ao Zendesk após retomar a pesquisa."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar pesquisa"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie a versão mais recente do trecho integrado após atualizar a pesquisa."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja apagar esta pesquisa? Essa ação não pode ser desfeita."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar pesquisa?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua avaliação expirou, mas você pode continuar aproveitando o Zendesk QA atualizando para uma conta completa."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua avaliação expirou, mas você pode continuar aproveitando o Zendesk QA atualizando para uma conta completa. Fale com seu administrador local para configurar isso."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua avaliação expirou"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar agora"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua avaliação grátis terminou."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar usando a versão completa do Zendesk QA, insira seus detalhes de pagamento."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar usando a versão completa do Zendesk QA, o administrador da sua conta precisa inserir os detalhes de pagamento."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar pagamento"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um usuário"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Criado em ", _interpolate(_named("date")), " por ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atribuição ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que você ainda está trabalhando nisso. Você perderá todas as informações adicionadas até o momento se sair sem salvar as alterações."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa atribuição se repetirá a cada duas ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Essa atribuição se repetirá a cada ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Todos os avaliados em um ciclo"]), _normalize(["Todos os avaliados em ", _interpolate(_named("nr")), " ciclos"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversas"]), _normalize([_interpolate(_named("n")), " conversa"]), _normalize([_interpolate(_named("n")), " conversas"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["de ", _interpolate(_named("nr")), " agente"]), _normalize(["de ", _interpolate(_named("nr")), " agente"]), _normalize(["de ", _interpolate(_named("nr")), " agentes"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participante"]), _normalize(["participantes"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversas por ciclo"]), _normalize([_interpolate(_named("n")), " conversa por ciclo"]), _normalize([_interpolate(_named("n")), " conversas por ciclo"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " por avaliado em sequência"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["avaliado"]), _normalize(["avaliados"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["avaliador"]), _normalize(["avaliadores"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliados"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliadores"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários do espaço de trabalho"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários do espaço de trabalho, exceto agentes"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não recorrente"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atribuição ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em avaliação"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre atribuições"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar atribuição"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação apagará permanentemente a atribuição e removerá os dados dela no painel. Todas as avaliações relacionadas a ela permanecerão no Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar atribuição ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição apagada"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição duplicada"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma atribuição adicionada ainda"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao criar a atribuição"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da atribuição"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ciclo é atualizado e uma nova lista de tarefas é criada. As avaliações já recebidas ainda contam para a meta."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imediatamente"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No início do próximo ciclo em ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando publicar as alterações?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As alterações feitas a esta atribuição serão publicadas com ela"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir atribuição como ativa?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorrente no dia de início da atribuição"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira sexta-feira de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda sexta-feira de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira sexta-feira de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta sexta-feira de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sexta-feira de cada mês"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira segunda-feira de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda segunda-feira de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira segunda-feira de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta segunda-feira de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última segunda-feira de cada mês"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro sábado de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo sábado de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceiro sábado de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto sábado de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último sábado de cada mês"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro domingo de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo domingo de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceiro domingo de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto domingo de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último domingo de cada mês"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira quinta-feira de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda quinta-feira de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira quinta-feira de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta quinta-feira de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última quinta-feira de cada mês"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira terça-feira de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda terça-feira de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira terça-feira de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta terça-feira de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última terça-feira de cada mês"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira quarta-feira de cada mês"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda quarta-feira de cada mês"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira quarta-feira de cada mês"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta quarta-feira de cada mês"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última quarta-feira de cada mês"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recorrência personalizada"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1º de cada mês"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terceira"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetições"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia útil"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Término"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuso horário"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de ciclo"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da atribuição"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações básicas"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No início de cada ciclo de atribuição, uma lista predeterminada de conversas é gerada para avaliação."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações avançadas"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar automaticamente novas conversas quando a meta de avaliação não for atingida"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A busca automática não é possível com metas de avaliação de porcentagem (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve ser um número inteiro, não um decimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir a substituição de conversas (função de avaliador e superiores)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituição de conversas"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que os avaliadores recebam suas próprias conversas para avaliação"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliações"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número mínimo de avaliações deve ser igual ou superior a 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especifique o mínimo de avaliações para cada avaliado"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversas"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentual (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliações"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliações no total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada avaliador deve fazer um número definido de avaliações"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores precisam avaliar coletivamente um número definido de conversas"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número mínimo de avaliações deve ser igual ou superior a 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meta da avaliação deve estar entre 1 e 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta de avaliação"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada avaliado deve ter um número mínimo do total de suas conversas avaliado"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores podem escolher conversas no pool compartilhado"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores são atribuídos às conversas do pool compartilhado"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abordagem de atribuição"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os bots"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os participantes do espaço de trabalho"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários do espaço de trabalho"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar avaliados"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem será avaliado?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar avaliados"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione quem avaliar"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As exclusões só são permitidas quando um grupo ou todos os usuários do espaço de trabalho são selecionados"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliadores e avaliados"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atual: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaço de trabalho padrão"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que tipo de usuários devem ser avaliados?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente mais ativo"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participante"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir avaliados (opcional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione os usuários a serem excluídos"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir avaliadores (opcional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione os usuários a serem excluídos"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários do espaço de trabalho, exceto agentes"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar avaliadores"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem fará as avaliações?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar avaliadores"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As autoavaliações não são permitidas no espaço de trabalho"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir autoavaliações"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As autoavaliações não são permitidas neste espaço de trabalho."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conversa correspondente hoje"]), _normalize(["Conversas correspondentes hoje"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibindo como se a atribuição tivesse começado hoje."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os números futuros podem variar."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum avaliado selecionado"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum avaliador selecionado"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Avaliado"]), _normalize(["Avaliados"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Avaliador"]), _normalize(["Avaliadores"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca automática: permitida se não houver conversas suficientes"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", às sextas-feiras, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", às segundas-feiras, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", aos sábados, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", aos domingos, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", às quintas-feiras, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", às terças-feiras, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", às quartas-feiras, a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diariamente a partir de ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inicia em ", _interpolate(_named("startDateTime")), " e termina em ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condições de data"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condições das conversas e da central de suporte"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sem conversa e ", _interpolate(_named("count")), " condição da central de suporte"]), _normalize(["Sem conversa e ", _interpolate(_named("count")), " condições da central de suporte"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversa e sem condição da central de suporte"]), _normalize([_interpolate(_named("count")), " conversas e sem condições da central de suporte"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Abordagem: os avaliadores escolhem as conversas"]), _normalize(["Abordagem: os avaliadores são atribuídos a conversas"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrija os erros destacados no resumo para continuar"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta: os avaliadores devem avaliar todas as conversas"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Meta: os avaliadores devem avaliar uma conversa"]), _normalize(["Meta: os avaliadores devem avaliar ", _interpolate(_named("count")), " conversas"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meta: os avaliadores devem avaliar ", _interpolate(_named("count")), "% das conversas"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta: nenhuma meta de avaliação foi definida"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta: cada avaliado deve ter todas as conversas avaliadas"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meta: cada avaliado deve ter ", _interpolate(_named("count")), " do total de conversas avaliadas"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meta: cada avaliado deve ter ", _interpolate(_named("count")), "% do total de conversas avaliadas"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Meta: cada avaliador deve fazer uma avaliação"]), _normalize(["Meta: cada avaliador deve fazer ", _interpolate(_named("count")), " avaliações"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Substituição: os avaliadores não podem substituir conversas"]), _normalize(["Substituição: os avaliadores podem substituir conversas"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoavaliações: os avaliadores podem ser atribuídos às próprias conversas"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma condição de data definida"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum nome de atribuição definido"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condições"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " a partir de ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinzenal"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diariamente"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanalmente"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração do ciclo"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir como ativo"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como rascunho"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição salva como rascunho"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição definida como inativa"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir como inativa"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar atribuição"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição criada"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como ativo"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como inativo"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["As atualizações pendentes na atribuição entrarão em vigor no próximo ciclo, começando em ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar alterações"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunho"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar atribuição"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição atualizada"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente ou o bot encerrou a conversa."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente encerrou a conversa com educação"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se a conversa foi encerrada"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamento"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa erros de gramática, ortografia e estilo nas interações do agente e do bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa os erros de gramática, ortografia e de estilo do agente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa os erros de gramática, ortografia e de estilo do agente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e gramática"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa as saudações do agente e do bot na conversa."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa toda a conversa em busca de frases de saudação típicas"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa as saudações típicas de toda a conversa"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudação"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente compreende e reconhece os sentimentos dos clientes"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica casos em que o agente refletiu o sentimento do cliente ou usou palavras cuidadosas como \"reconhecer\""])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente compreendeu a dúvida ou preocupação do cliente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa se o agente compreendeu a dúvida ou preocupação do cliente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compreensão"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa a facilidade de compreensão de um texto, considerando a complexidade da palavra e o comprimento da frase"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa a facilidade de compreensão de um texto, considerando a complexidade da palavra e o comprimento da frase"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa mensagem contém uma frase muito longa, dificultando a leitura"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Essa mensagem contém ", _interpolate(_list(0)), " frases muito longas, dificultando a leitura"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legibilidade"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa toda a conversa em busca de uma solução oferecida"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa toda a conversa em busca de uma solução oferecida"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solução oferecida"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa o tom do agente ao longo da conversa"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisa o tom do agente ao longo da conversa"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar erros nas mensagens do agente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível determinar os erros gramaticais"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma frase de saudação detectada. Adicione novas frases, se necessário, pelo seu administrador nas Configurações."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente encerrou a conversa com educação"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma frase de fechamento detectada. Adicione novas frases, se necessário, pelo seu administrador nas Configurações"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente refletiu o sentimento do cliente ou usou palavras afetuosas"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar se o agente refletiu o sentimento do cliente ou usou palavras de afeto"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente usou frases longas e palavras complexas, o que pode dificultar a compreensão de algumas pessoas"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente usou palavras complexas, o que pode dificultar a compreensão de algumas pessoas"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente usou frases longas, o que pode dificultar a compreensão de algumas pessoas"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente usou uma linguagem fácil de entender"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados suficientes para classificar essa categoria"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente teve dificuldade para entender a pergunta/solicitação do cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente entendeu bem a pergunta/solicitação do cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar se o agente entendeu a pergunta/solicitação do cliente"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom do agente durante a conversa"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom do agente durante a conversa"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom do agente durante a conversa"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom do agente durante a conversa"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom do agente durante a conversa"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível determinar o tom do agente"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa não foi encerrada"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conversa foi encerrada"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar se a conversa foi encerrada"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente não teve empatia com o cliente e os problemas dele"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente mostrou empatia pelo cliente e pelos problemas dele"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar se o agente mostrou empatia pelo cliente e pelos problemas dele "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente não foi cumprimentado durante a conversa"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cliente foi cumprimentado durante a conversa"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar a saudação típica"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente não forneceu uma solução para o cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O agente ofereceu a solução ao cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível detectar se o agente ofereceu uma solução para o cliente"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras complexas"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frases longas"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramática"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro de ortografia"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpas"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calmo"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alegre"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curioso"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profissional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atencioso"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estilo"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 fechamento"]), _normalize([_interpolate(_named("n")), " fechamentos"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O AutoQA só verifica fechamentos aprovados. Se essa categoria for definida como crítica, o não uso dos encerramentos aprovados causará falha na avaliação."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamentos aprovados"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechamentos aprovados"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 isenção"]), _normalize([_interpolate(_named("n")), " isenções"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras e frases ignoradas pelo AutoQA como erros de ortografia ou gramática."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenções de ortografia e gramática"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isenções do AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 saudação"]), _normalize([_interpolate(_named("n")), " saudações"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O AutoQA só verifica saudações aprovadas. Se essa categoria for definida como crítica, o não uso das saudações aprovadas causará falha na avaliação."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudações aprovadas"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudações aprovadas"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria do AutoQA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione uma categoria do AutoQA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais atribuídas automaticamente:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta → Configurações"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Algumas categorias aproveitam o MS Azure, oferecendo uma ampla gama de funcionalidades e idiomas com suporte. Avalie ", _interpolate(_named("link")), " para obter mais informações ou entre em contato com um administrador para fazer isso."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Acesse uma lista extensa de categorias e idiomas com suporte aproveitando o MS Azure. Avalie ", _interpolate(_named("link")), " ou entre em contato com um administrador para fazer isso."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar o AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um score é atribuído automaticamente às categorias do AutoQA, usando um escala de classificação fixa. Você pode ajustar a classificação manualmente depois. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione uma categoria do AutoQA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um score atribuído automaticamente depende da gravidade e do número dos erros. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causas principais"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas as categorias de classificação relevantes são exibidas para os avaliadores. As categorias de classificação são mostradas com base nas condições definidas ao criar uma categoria."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["integração"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefone"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecione o tipo de categoria de classificação para o espaço de trabalho \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores precisam selecionar manualmente a tabela de desempenho correta para ver as categorias de classificação relevantes para cada conversa."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja alterar o tipo de categoria de classificação?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar categorias"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar tipo de categoria de classificação?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação condicional"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação estática"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de categoria de classificação"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações da categoria de classificação"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essa ação não pode ser desfeita."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apagar todas as classificações"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, você pode manter suas estatísticas sem apagar nenhum dado arquivando uma categoria."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Entendo que isso vai ", _interpolate(_named("deleteAllRatings")), " concedidos para essa categoria."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por exemplo, o tom é perfeito"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar categoria apenas se"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Sempre\" será ignorado. Não é possível combinar com outras condições."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags da central de suporte"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar condição"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score de satisfação (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de origem"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal de conversa"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A categoria precisa ter um nome"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione a categoria a uma tabela de desempenho"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar categoria"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar categoria"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação adicionada"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma categoria arquivada não é visível na tabela de desempenho, mas ela permite que você mantenha estatísticas sem apagar nenhum dado."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar categoria"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arquivar categoria ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação arquivada"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite \"apagar categoria\" para apagar essa categoria"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar categoria"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Apagar categoria ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação apagada"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação duplicada"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categorias de classificação adicionado"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo da categoria de classificação apagado"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ordem do grupo de categorias de classificação foi alterada"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de categorias de classificação atualizado"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação desarquivada"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação atualizada"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando um agente é reprovado em uma categoria crítica, todas as categorias da avaliação são reprovadas automaticamente."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria de classificação crítica"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma categoria no grupo"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar nova categoria de classificação"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar categoria"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar todas as categorias e avaliar os dados neste grupo"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao apagar o grupo de categorias, você deixará todas as categorias vinculadas a ele desagrupadas."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar grupo de categoria ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher apagar as categorias do grupo resultará na perda de todos os dados de avaliação associados a essas categorias."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do grupo"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores podem selecionar mais de um motivo como causa principal"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os avaliadores podem selecionar mais de um motivo como causa principal"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversos motivos"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo: tom"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um novo grupo de categorias"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo grupo"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar \"Outro\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar causa principal"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A causa principal já existe"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar causa principal"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione as causas principais para explicar a classificação"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de classificação"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivado"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar tabelas de desempenho a essa categoria de classificação"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarquivar"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar categoria"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as classificações"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibido para"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações negativas"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações negativas e neutras"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim, atualizar"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As categorias mais importantes para você podem ter mais peso no cálculo do score do ticket."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Peso (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre calibração"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre calibração"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avançado"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar sessões regulares de calibração permite que os avaliadores mantenham suas técnicas de classificação alinhadas e garantam que os agentes recebam o mesmo nível de feedback consistente e imparcial."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar planos"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " é um processo que ajuda sua equipe a se manter informada sobre o gerenciamento e a avaliação de todas as interações com os clientes."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profissional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A calibração está disponível para nossos planos ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As avaliações anteriores não são exibidas quando uma conversa é adicionada à sessão."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não visível"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações realizadas anteriormente são consideradas avaliações de calibração. Apenas uma avaliação de conversa por avaliador será adicionada à sessão. As avaliações específicas de mensagens não são exibidas."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visível"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade de avaliações anteriores"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A calibração é um processo que ajuda sua equipe a se manter atualizada sobre o gerenciamento e a avaliação de todas as interações com o cliente. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários têm acesso ilimitado a todas as avaliações fornecidas durante a sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre ver todas as avaliações"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depois de enviar uma avaliação, os usuários verão avaliações feitas por outros avaliadores durante uma sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas as avaliações de calibração após enviar uma avaliação"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários têm acesso ilimitado a todas as avaliações fornecidas durante a sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre ver todas as avaliações"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depois de enviar uma avaliação, os usuários verão avaliações feitas por outros avaliadores durante uma sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas as avaliações de calibração após enviar uma avaliação"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depois de enviar uma avaliação, os usuários verão avaliações feitas por outros avaliadores durante uma sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas as avaliações de calibração após enviar uma avaliação"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários verão apenas as próprias avaliações fornecidas durante uma sessão de calibração."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver apenas avaliações próprias"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As configurações de calibração foram atualizadas"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade das avaliações de calibração"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina o limite para métricas do painel preenchendo as lacunas de acordo com o sistema de métricas internas da sua equipe. Avalie o limite no painel."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuições"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibração"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexões"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geral"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite atualizado"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde um momento..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verifique se você tem permissões de Administrador e ", _interpolate(_named("retry")), " ou ", _interpolate(_named("contact")), " para obter mais ajuda."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entre em contato conosco"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que não conseguimos acessar a API da sua central de suporte."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tente novamente"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos conectando seu suporte técnico. Você será redirecionado em breve."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorizado"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Volte para ", _interpolate(_list(0)), " a fim de adicionar uma nova conexão com a central de suporte."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que você pulou algumas etapas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já existe uma organização com o mesmo domínio de e-mail"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["página de conexões"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquanto isso..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde um momento enquanto verificamos suas permissões."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquanto isso..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integração"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crie um espaço de trabalho"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para continuar usando o Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quase pronto para o uso"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho encontrado"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de conta"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar usando o Zendesk QA, seu gerente precisa atribuir você a um espaço de trabalho."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ao apagar sua conta, todos os dados que temos sobre você serão permanentemente apagados – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todas as classificações, conteúdos das conversas, espaços de trabalho etc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar a integração desativará todas as notificações do Zendesk QA pelo Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar o Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A integração do Slack foi desconectada do Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defina as configurações de notificação padrão para todos os novos usuários da conta."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir configurações para todos os usuários"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está prestes a substituir as configurações de notificação de todos os usuários da conta"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários não poderão definir suas próprias configurações de notificação."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As configurações de notificação foram atualizadas"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertar quando uma categoria crítica falhar"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertar quando o CSAT tiver uma resposta"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gatilhos"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar dados e espaço de trabalho da demonstração"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recriar dados da demonstração e manter o espaço de trabalho da demonstração."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dados e espaço de trabalho da demonstração"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar dados de demonstração"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar os dados"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gerenciar modo de demonstração da conta \"", _interpolate(_named("accountName")), "\""])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de demonstração"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/6059285322522-Sobre-os-recursos-de-IA-generativa-no-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043712839450-Defini%C3%A7%C3%A3o-de-configura%C3%A7%C3%B5es-de-seguran%C3%A7a-avan%C3%A7ada-para-conex%C3%B5es-da-central-de-suporte"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043747123354-Configura%C3%A7%C3%A3o-da-pontua%C3%A7%C3%A3o-autom%C3%A1tica-no-Zendesk-QA-usando-o-AutoQA"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7418648293018-Avalia%C3%A7%C3%A3o-do-desempenho-de-agentes-de-IA-usando-o-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043724530842-Configura%C3%A7%C3%A3o-da-calibra%C3%A7%C3%A3o-no-Zendesk-QA"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043759436954-An%C3%A1lise-de-scores-de-categoria-ao-longo-do-tempo"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043747231642-Usar-a-visualiza%C3%A7%C3%A3o-Insights-de-conversa"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043747649690-Cria%C3%A7%C3%A3o-de-v%C3%A1rias-tabelas-de-desempenho"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043701093786-No%C3%A7%C3%B5es-b%C3%A1sicas-sobre-os-c%C3%A1lculos-do-painel-de-avalia%C3%A7%C3%B5es-do-Zendesk-QA"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043759449114-Encontrar-conversas-para-avaliar-usando-filtros-personalizados"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043760162074-Gerenciamento-de-usu%C3%A1rios-e-espa%C3%A7os-de-trabalho"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043759543194-Usar-observa%C3%A7%C3%B5es-afixadas-para-instruir-agentes"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043747142938-Gerenciamento-de-grupos-no-Zendesk-QA"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043759586074-Uso-de-insights-do-Destaque-no-Zendesk-QA-para-filtrar-conversas"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/articles/7043759396250-Monitoramento-do-tempo-de-avalia%C3%A7%C3%A3o-no-Zendesk-QA"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/pt-br/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanecer na página"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair da página"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair desta página e perder todas as alterações?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você concluiu a atribuição"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar atribuição"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuição concluída"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma atribuição encontrada"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento, não há tarefas para você avaliar."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuste a atribuição ou espere pelo início do próximo ciclo."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente ajustar os filtros"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar atribuição"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhe as atualizações nesta página"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tarefa para este ciclo"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa a exibir"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar tarefa como concluída"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você avaliou este usuário."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há autoavaliação nesta atribuição"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para adicionar, clique no ícone de calibração no cabeçalho da visualização de conversas."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa adicionada"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de vencimento"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar atribuição"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar sessão de calibração"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suas atribuições"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outras atribuições"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma atribuição ativa"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma atribuição"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os avaliados"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os avaliados, exceto eu"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum avaliado encontrado"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por avaliado"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunho"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removido"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os status"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendências"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os avaliadores"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os avaliadores, exceto eu"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuídos a mim"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum avaliador encontrado"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por avaliador"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dias"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dias"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dia"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dias"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dias"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dias"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hora"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " horas"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuto"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minutos"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/D"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários do espaço de trabalho"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador atual"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova meta para este ciclo"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo avaliador"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar avaliador"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir conversas"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir conversa"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversas a serem movidas"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclo atual"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar atribuição"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum avaliador encontrado"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar avaliador"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir conversas a mim"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir conversas a outro usuário"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliadores"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dias"]), _normalize([_interpolate(_named("n")), " dia"]), _normalize([_interpolate(_named("n")), " dias"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " horas"]), _normalize([_interpolate(_named("n")), " hora"]), _normalize([_interpolate(_named("n")), " horas"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar ciclos concluídos"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar sessões concluídas"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua meta"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione um motivo para remover essa conversa"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por que você está removendo essa conversa"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover conversa"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja manter as alterações?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione um motivo para substituir essa conversa"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar alterações"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manter"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manter alterações"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituição não encontrada"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explique por que você está substituindo essa conversa"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda deseja remover essa conversa da atribuição? Isso reduzirá a meta em 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substituir conversa"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar rascunho"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar rascunho"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir a mim"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir a outro usuário"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reatribuir e avaliar"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover conversa"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar uma avaliação"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mais"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meta: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " avaliado"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações concluídas"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar ciclos concluídos"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar sessões concluídas"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo restante"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefas"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa substituída"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar lista de tarefas"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " mais tarde"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " segundo"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " segundos"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dias"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d ", _interpolate(_named("minutes")), "m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h ", _interpolate(_named("seconds")), "s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "mês/meses"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "ano(s)"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora mesmo"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem permissões. Entre em contato com seu administrador para obter ajuda."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A página que você está procurando não existe."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro inesperado. Entre em contato com o suporte para obter auxílio."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso negado. Não é permitido executar essa ação."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A página que você está procurando não existe ou você não tem acesso a ela."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não estamos conseguindo acompanhar seu ritmo."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações salvas"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações feitas e não salvas"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " aplicado(s)"]), _normalize([_interpolate(_named("n")), " aplicado(s)"]), _normalize([_interpolate(_named("n")), " aplicado(s)"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da criação"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado por"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crítico"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ex-membro"]), _normalize(["um Ex-membro"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aqui"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selecionado(s)"]), _normalize([_interpolate(_named("n")), " selecionado(s)"]), _normalize([_interpolate(_named("n")), " selecionado(s)"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da última atualização"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... e mais +1"]), _normalize(["... e mais +", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mais +1"]), _normalize(["Mais +", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novo"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["não é"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removido"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " respostas"]), _normalize([_interpolate(_named("n")), " resposta"]), _normalize([_interpolate(_named("n")), " respostas"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoje"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuário"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sexta-feira"]), _normalize(["Sextas-feiras"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Segunda-feira"]), _normalize(["Segundas-feiras"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sábado"]), _normalize(["Sábados"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Domingo"]), _normalize(["Domingos"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Quinta-feira"]), _normalize(["Quintas-feiras"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Terça-feira"]), _normalize(["Terças-feiras"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Quarta-feira"]), _normalize(["Quartas-feiras"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ontem"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrador"]), _normalize(["um Administrador"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gerente de conta"]), _normalize(["um Gerente de conta"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuário"]), _normalize(["um Usuário"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua sessão expirou."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acessos"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo não pode ficar vazio."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item encontrado."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma pessoa correspondente."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Responsável"]), _normalize(["um Responsável"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de começarmos, você precisar ler e concordar com nossos termos."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral das avaliações concluídas"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agente"]), _normalize(["um Agente"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["um Lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gerente"]), _normalize(["um Gerente"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Avaliador"]), _normalize(["um Avaliador"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário anônimo"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuído"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em breve"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para copiar"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona de risco"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição (opcional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação obrigatória"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativar"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar alterações não salvas?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequência"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de suporte"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQI"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomas"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avançar"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de aprovação"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações pessoais"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler menos"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurar"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliado"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas/vistas"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações feitas por membro do espaço de trabalho"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar alterações"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala binária"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 3 pontos"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 4 pontos"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala de 5 pontos"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de desempenho"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar frequência"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar menos"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar mais"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaque"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback da pesquisa"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de tempo"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações feitas e não salvas"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Você recebeu um convite de ", _interpolate(_list(0)), " para participar do Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Os usuários são conectados automaticamente pela Central de administração. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar usuários"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre o gerenciamento de usuários"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alguns usuários são automaticamente provisionados e sincronizados com seu provedor de identidade. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A permissão da conta de \"", _interpolate(_list(0)), "\" foi alterada"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre as permissões do usuário"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não perguntar novamente"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Todas as alterações feitas na página do usuário são refletidas nos espaços de trabalho. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso significa que o acesso do usuário a determinadas partes do Zendesk QA pode mudar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar permissão no nível da conta"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário removido"]), _normalize([_interpolate(_named("n")), " usuários removidos"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quando você remove um usuário, ele perde o acesso à sua conta. As avaliações, os comentários e as classificações permanecerão no Zendesk QA. ", _interpolate(_named("linebreak")), "Remover um usuário não afetará o número de licenças em sua assinatura."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Esse usuário será removido de todos os espaços de trabalho que você gerencia. ", _interpolate(_named("linebreak")), " As avaliações, os comentários e as classificações permanecerão intactos."]), _normalize(["A remoção será feita em todos os espaços de trabalho que você gerencia. ", _interpolate(_named("linebreak")), " Todas as avaliações, comentários e classificações permanecerão intactos."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remover ", _interpolate(_named("name"))]), _normalize(["Remover usuários ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode ver tudo e gerenciar todas as configurações e o faturamento"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode ver tudo e gerenciar configurações pessoais e do espaço de trabalho, exceto faturamento"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões da conta"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As permissões específicas de espaços de trabalho permitem que o usuário veja (e) edite dados nos espaços de trabalho em que estiver."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre o BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seus bots são exibidos aqui, pois as conversas das quais eles participaram são sincronizadas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum bot sincronizado"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar um bot como usuário concederá a ele acesso para entrar. Os dados históricos do bot permanecerão disponíveis nos painéis."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum bot encontrado"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente pesquisar novamente usando uma palavra-chave ou grafia diferente."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar bots"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar pelo nome do bot"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como usuário"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas administradores e gerentes de conta podem marcar bots como usuários"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do bot"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcado manualmente"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliável"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O bot é removido da análise automática e de quaisquer atribuições. Novos dados não serão exibidos nos painéis, garantindo relatórios precisos e gerenciamento mais fácil do bot."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generativo"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconhecido"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot de fluxo de trabalho"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar a grupos"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os grupos"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem grupo"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dos grupos"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar grupo"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissão da conta"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar aos espaços de trabalho"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome completo"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última atividade"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função no nível da conta"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada usuário é atribuído a uma permissão de nível de conta e/ou espaço de trabalho. As permissões de administrador agora são no nível da conta, enquanto as outras permissões são no nível do espaço de trabalho."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover dos espaços de trabalho"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissão do espaço de trabalho"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões gerenciadas no grupo"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar um usuário como bot removerá o acesso dele para entrar. Os dados históricos do usuário permanecerão disponíveis nos painéis."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remova o bot da análise automática e de quaisquer atribuições. Novos dados não serão exibidos nos painéis, garantindo relatórios precisos e gerenciamento mais fácil do bot."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluir bot das avaliações"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário marcado como bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas o tipo de permissão Usuário pode ser marcado como bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários com várias contas não podem ser marcados como bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por usuário"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários, bots e espaços de trabalho"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPAÇOS DE TRABALHO"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários e espaços de trabalho"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem permissões"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado manualmente"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convite pendente"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ação necessária"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...e mais um"]), _normalize(["...e mais ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar permissão para"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remova e convide novamente para alterar a função de ", _interpolate(_named("n")), " usuário com convite pendente."]), _normalize(["Remova e convide novamente para alterar a função de ", _interpolate(_named("n")), " usuários com convites pendentes"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editar detalhes de ", _interpolate(_named("name"))]), _normalize(["Editar detalhes de ", _interpolate(_named("n")), " usuários selecionados"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário atualizado."]), _normalize([_interpolate(_named("n")), " usuários atualizados."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Esse grupo contém 1 usuário de outro espaço de trabalho."]), _normalize(["Esse grupo contém ", _interpolate(_named("n")), " usuários de outro espaço de trabalho."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativar"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" arquivado"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo arquivado"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar grupo"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" criado"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo sem título"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar permanentemente este grupo?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar grupo"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar grupo"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Líder do grupo"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Função do grupo"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" oculto"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este grupo não estará visível em mais nenhuma parte do Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar apenas permissões do espaço de trabalho"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo não visível no Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membro"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo sem título"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outro grupo já tem esse nome"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um grupo arquivado já tem esse nome"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua função permite apenas o gerenciamento de membros desse grupo. Entre em contato com um usuário com permissões mais altas para fazer mais alterações."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover como líder do grupo"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" restaurado"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" salvo"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir como líder do grupo"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" desarquivado"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterações não salvas"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar seus usuários existentes a esse grupo"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por nome ou e-mail"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar membros"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo \"", _interpolate(_named("name")), "\" visível"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cada membro é ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre grupos"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguns atributos de grupos são automaticamente provisionados e sincronizados com seu provedor de identidade."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saiba mais sobre grupos"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar grupo"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize facilmente os membros da sua equipe e imite sua estrutura organizacional."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione, no mínimo, 1 membro"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um nome de grupo"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já existe um grupo com esse nome"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros do grupo"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do grupo"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organize os membros da sua equipe e imite sua estrutura organizacional."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membros"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sem grupos"]), _normalize(["1 grupo"]), _normalize([_interpolate(_named("count")), " grupos"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie um novo grupo ou ative um grupo arquivado."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo ativo disponível"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo arquivado"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum desses grupos foi encontrado"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente pesquisar novamente usando uma palavra-chave ou grafia diferente."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo adicionado"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["nestes"]), _normalize(["em"]), _normalize(["em"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por nome do grupo"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivado"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["É possível reenviar ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licenças restantes"]), _normalize([_interpolate(_named("n")), " licença restante"]), _normalize([_interpolate(_named("n")), " licenças restantes"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No momento, sua contagem de licenças é limitada porque sua assinatura está pausada. Reative-a para adicionar mais usuários."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você atingiu o limite de licenças. É possível substituir usuários desativando um usuário ativo ou adicionando mais licenças."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exibir detalhes de pagamento"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selecionado(s)"]), _normalize([_interpolate(_named("n")), " selecionado(s)"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário selecionado"]), _normalize([_interpolate(_named("n")), " usuários selecionados"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " linhas"]), _normalize([_interpolate(_named("n")), " linha"]), _normalize([_interpolate(_named("n")), " linhas"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquise usuários em massa pelos nomes ou endereços de e-mail."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cole os dados aqui, separados por vírgulas ou novas linhas"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa em massa de usuários"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Editar permissões do espaço de trabalho para ", _interpolate(_named("name"))]), _normalize(["Editar permissões do espaço de trabalho para ", _interpolate(_named("n")), " membros selecionados"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar permissões"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar permissão do espaço de trabalho"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remover ", _interpolate(_named("name")), " do espaço de trabalho"]), _normalize(["Remover ", _interpolate(_named("name")), " membros do espaço de trabalho"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " membro removido"]), _normalize([_interpolate(_named("n")), " membros removidos"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Remover membro"]), _normalize(["Remover membros"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Esse usuário será removido do espaço de trabalho."]), _normalize(["Esses usuários serão removidos do espaço de trabalho."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A permissão do espaço de trabalho \"", _interpolate(_list(0)), "\" foi alterada."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar membros"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver conversas e avaliações relacionadas"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo no espaço de trabalho, mas gerenciar apenas quizzes, atribuições, contestações e sessões de calibração"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões do espaço de trabalho"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver e gerenciar tudo no espaço de trabalho"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo o que está no espaço de trabalho, exceto as configurações"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conectar usuários"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários dos espaços de trabalho que você gerencia foram adicionados a esse espaço de trabalho."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça para um administrador fazer isso."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convide novos usuários diretamente ou peça para um administrador fazer isso."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " ou ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário para exibir"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " usuário adicionado"]), _normalize([_interpolate(_named("n")), " usuários adicionados"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Adicionar membros"]), _normalize(["Adicionar membro"]), _normalize(["Adicionar ", _interpolate(_named("n")), " membros"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar permissão do espaço de trabalho"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar usuários para o espaço de trabalho"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Adicionar membros a ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cole os e-mails aqui, separados por novas linhas ou vírgulas"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails com correspondência"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" com usuários já conectados:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mails sem correspondência"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique a ortografia ou conecte depois individualmente"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" com usuários da central de suporte:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário conectado"]), _normalize([_interpolate(_named("n")), " usuários conectados"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário convidado"]), _normalize([_interpolate(_named("n")), " usuários convidados"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão em massa"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convite em massa"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por exemplo:\n\nkate", "@", "exemplo.com, john", "@", "exemplo.com\n\nkate", "@", "exemplo.com\nkate", "@", "exemplo.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte-se de suas centrais de suporte para avaliar as interações"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conectar usuário"]), _normalize(["Conectar usuários"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar individualmente"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se quiser que os usuários conectados acessem o Zendesk QA, você pode convidá-los aqui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuários conectados"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Convidar usuário"]), _normalize(["Convidar usuários"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar convite"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione usuários de suas centrais de suporte para se conectar e avaliar as interações"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar sua assinatura"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar usuários da central de suporte"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dê um nome exclusivo ao seu espaço de trabalho"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se o recurso estiver ativado, todas as conexões de conta existentes serão adicionadas automaticamente ao novo espaço de trabalho."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar todas as conexões a este espaço de trabalho"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar espaço de trabalho"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do espaço de trabalho"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar espaço de trabalho"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários adicionados diretamente à conta (sem atribuição de um espaço de trabalho) são atribuídos a uma função no nível da conta. É possível adicioná-los aos espaços de trabalho posteriormente."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários convidados recebem uma atribuição automática a uma função de usuário no nível da conta. É possível atualizá-los com uma função de administrador posteriormente."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo funciona corretamente"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cole os e-mails aqui, separados por novas linhas ou vírgulas."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " usuário convidado por e-mail"]), _normalize([_interpolate(_named("n")), " usuários convidados por e-mail"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários adicionados a um espaço de trabalho recebem uma atribuição automática a uma função de usuário no nível da conta. É possível atualizá-los com uma função de administrador posteriormente."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outro"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuários apenas para a conta, sem adicioná-los ao espaço de trabalho"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários convidados são atribuídos apenas com a permissão Conta e não são adicionados a espaços de trabalho."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuários diretamente para um espaço de trabalho e atribuir permissões para o espaço de trabalho"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar usuários ao espaço de trabalho"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convite em massa"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar individualmente"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar apenas para a conta"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar para um espaço de trabalho"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Convidar usuários"]), _normalize(["Convidar usuário"]), _normalize(["Convidar ", _interpolate(_named("n")), " usuários"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuários em massa por e-mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Apagar usuário"]), _normalize(["Apagar usuários"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desmarcar"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convidar usuários por e-mail"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar permissão"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar convite"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário conectado"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário com convite pendente por e-mail"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar espaço de trabalho"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentes de IA"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central de administração"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle de qualidade"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento da força de trabalho"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " concluída(s)"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " concluída(s)"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " concluída(s)"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliador"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar atribuição"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economize tempo e configure atribuições de avaliação automáticas em vez de encontrar e alocar manualmente as conversas para avaliação."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentário"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vencimento: ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As sessões podem ser adicionadas na seção \"Calibração\" no menu lateral da visualização de conversas."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão adicionada ainda"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linha de base"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há sessões"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para adicionar as conversas a uma sessão, clique no ícone de calibração no cabeçalho da visualização de conversas."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conversa adicionada ainda"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nenhuma avaliação foi feita. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar uma avaliação da calibração"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa principal"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao carregar a paleta de comandos."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada encontrado."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrado por ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando paleta de comandos…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["páginas, filtros, conversas, configurações..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversa"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro privado"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro público"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["De ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da sua API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mostrando ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " de ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Mostrando ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " de ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar painel lateral"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar painel lateral"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar barra lateral"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações aceitas"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações rejeitadas"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações abertas"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações parcialmente aceitas"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações concluídas"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações visualizadas"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceita(s)"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aberta(s)"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceita(s) parcialmente"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " do total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejeitada(s)"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abertas"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito parcialmente"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitado"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["avaliação"]), _normalize(["avaliações"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de avaliações vistas pelos avaliados"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de avaliações vistas"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliadores contestados"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestadores"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações por categoria"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestações ao longo do tempo"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abertas"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio de resolução"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio de resolução (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio de resolução (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolvidas"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de contestações"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceita"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma da resposta"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum idioma encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os idiomas"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impulsionadores previstos"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum impulsionador encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os impulsionadores"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavras da nuvem de palavras"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma palavra encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as palavras"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menções"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais recentes"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais antigos"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em aberto"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceita parcialmente"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " reagiu com ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeitada"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolvida"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o tom de pele"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessões"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma sessão encontrada"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma categoria encontrada"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do comentário"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum tamanho encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os tamanhos"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum filtro encontrado"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum grupo encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os grupos"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma hashtag encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as hashtags"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum valor encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os valores"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum campo personalizado encontrado"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags da central de suporte"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tag da central de suporte encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as tags da central de suporte"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando resultados..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de pergunta"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum tipo de pergunta encontrado"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar reação"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelas de desempenho"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma tabela de desempenho encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as tabelas de desempenho"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum score encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os scores"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origens"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma origem encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as origens"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo do feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum motivo encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os motivos"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma pesquisa encontrada"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas as pesquisas"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canais"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum canal encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os canais"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuários"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum usuário encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os usuários"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum espaço de trabalho encontrado"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os espaços de trabalho"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para os quizzes"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O quiz foi apagado ou ainda não foi publicado. Verifique novamente o link ou volte para a lista de quizzes."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este quiz não foi compartilhado com um espaço de trabalho ao qual você pertence. Peça para um administrador adicionar você a um espaço de trabalho ao qual este quiz tem acesso."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O quiz está indisponível"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O acesso ao quiz foi concedido a espaços de trabalho de que você não faz parte. Não foi possível salvar e publicar o quiz."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça que um administrador salve e publique o quiz."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deve marcar pelo menos uma das respostas como a correta."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O campo não pode ficar vazio."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta é uma visualização do quiz."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione quais espaços de trabalho podem acessar o quiz. Os usuários que fazem parte de vários espaços de trabalho podem responder ao quiz apenas uma vez. A seleção de \"Todos os espaços de trabalho\" não vai incluir espaços de trabalho recém-criados após a publicação do quiz."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso ao quiz"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair do modo de pré-visualização"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perguntas"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça o quiz"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações do quiz"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar como rascunho"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar novo quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarquivar"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opções"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar opção"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartilhar"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece ótimo, não é?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua resposta foi enviada"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu quiz foi salvo"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu quiz foi publicado"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu quiz foi removido"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar pergunta"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um quiz arquivado e seus resultados não serão mais exibidos na lista de quizzes. Você ainda pode acessar os resultados de um quiz arquivado e desarquivá-lo sempre que necessário."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivar quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma vez publicado, o quiz não pode ser alterado ou editado. Todos os quizzes publicados serão acessíveis a todos os usuários da sua conta."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar quiz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza que deseja apagar este quiz? Essa ação não pode ser desfeita."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar quiz?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma vez enviadas, suas respostas não podem ser alteradas."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar quiz?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarquivar quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O desarquivamento do quiz o disponibiliza na visão geral do teste. Isso permitirá que os participantes anteriores acessem seus resultados e que os novos façam o quiz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarquivar quiz"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar pergunta"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição (opcional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover pergunta para baixo"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover pergunta para cima"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergunta"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas as perguntas com respostas são exibidas na visualização."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover pergunta"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz sem título"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título do quiz"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso apagará permanentemente o quiz e seus resultados."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como alternativa, você ainda pode acessar os resultados arquivando o quiz."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respondido"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar quiz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso apagará permanentemente o quiz e seus resultados."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Apagar quiz ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os quizzes ajudam a aumentar a confiança e as habilidades da equipe, fornecer experiências consistentes e integrar novos membros da equipe."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode pesquisar os quizzes pelo nome do teste ou do autor."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum quiz criado ainda"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar lista de quizzes"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média de score"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da conclusão"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autor/Espaços de trabalho"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participantes"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços de trabalho"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por quiz, autor"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivado"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rascunho"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os quizzes"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O quiz foi arquivado."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O quiz foi desarquivado."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este quiz não recebeu respostas de usuários nos espaços de trabalho selecionados."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este quiz não recebeu respostas antes de ser arquivado."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem respostas"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui, você verá um detalhamento das respostas do quiz.\nNinguém respondeu ao seu quiz ainda. Compartilhar o quiz ajudaria nesse sentido."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem respostas ainda"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respostas:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score médio:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Cópia"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copiado"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quizzes de ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode pesquisar na tabela de classificação o nome do participante."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar tabela de classificação"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por participante"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de classificação"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar tabela de classificação"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso significa que você não poderá filtrar por essa hashtag no painel e ela não será mais preenchida automaticamente em comentários. Essa ação é irreversível."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar hashtags"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar hashtags"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar hashtags"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir que os membros criem novas hashtags"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usado"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Apagar hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A criação da hashtag está bloqueada"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A criação de hashtag está desbloqueada"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag \"", _interpolate(_list(0)), "\" apagada"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag \"", _interpolate(_list(0)), "\" renomeada para \"", _interpolate(_list(1)), "\""])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag adicionada"]), _normalize([_interpolate(_named("count")), " hashtags adicionadas"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo nome"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma hashtag criada"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renomear a hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separe hashtags com vírgulas ou quebras de linha"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar por uma hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sem problemas. Para fazer isso, ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aqui"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão vinculada ao espaço de trabalho"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexar conexão"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexar uma conexão a este espaço de trabalho"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com seu administrador para fazer alterações aqui"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre em contato com o Zendesk QA para fazer alterações aqui"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conexão na conta"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que você ainda não fez a integração com uma plataforma de atendimento ao cliente."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar uma conexão removerá as conversas do espaço de trabalho ", _interpolate(_named("workspaceName")), " e apagará todos os dados relacionados a elas, incluindo avaliações e contestações."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar conexão"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apagar conexão ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar uma conexão"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No período de tempo escolhido, determinadas categorias ativas do AutoQA não estavam presentes na sua tabela de desempenho. Conversas anteriores à sua última alteração em ", _interpolate(_named("date")), ", talvez não tenham sido analisadas para essas categorias."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É aqui que todo o feedback fornecido para você aparecerá. Ainda não há nada para mostrar."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação recebida."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar apenas atividade de comentários"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É aqui que todo o feedback fornecido por você aparecerá. Comece a usar a visualização de conversas."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma avaliação fornecida."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações fornecidas"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações recebidas"])}
  }
}