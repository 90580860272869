import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { FilterTimeStep } from '../types'

dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)

export const DISPUTE_COLORS = {
  acceptedDisputes: '#6FCC76',
  partiallyAcceptedDisputes: '#51CFC4',
  rejectedDisputes: '#FA8383',
  newDisputes: '#CBD5E0',
} as const

export const topRoundedCorners = (x: number, y: number, width: number, height: number, radius: number) => {
  const heightBeforeArc = height - radius < 0 ? 0 : height - radius
  const widthBeforeArc = width - radius * 2 < 0 ? 0 : width - radius * 2
  return (
    `M${x},${y} ` +
    `v-${heightBeforeArc} ` +
    `a ${radius},${radius} 0 0 1 ${radius},-${radius} ` +
    `h+${widthBeforeArc}` +
    `a ${radius},${radius} 0 0 1 ${radius},${radius} ` +
    `v${heightBeforeArc} ` +
    `z`
  )
}

export const generateGetBoundingClientRect =
  (x = 0, y = 0) =>
  () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  })

export const getTooltipDateLabel = (step: FilterTimeStep) => {
  let format: string

  switch (step) {
    case FilterTimeStep.Day:
      format = 'MMM D YYYY'
      break
    case FilterTimeStep.Week:
      format = '[W]W YYYY'
      break
    case FilterTimeStep.Month:
      format = 'MMM YYYY'
      break
    case FilterTimeStep.Quarter:
      format = '[Q]Q YYYY'
      break
    case FilterTimeStep.Year:
      format = 'YYYY'
  }

  return (label: string) => dayjs.utc(label).format(format)
}
