export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź poddomenę Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się na koncie Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja firma ma już konto."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do firmy"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby dołączyć do istniejącej firmy, poproś administratora o wysłanie zaproszenia."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś administratora o zaproszenie"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmieniam zdanie."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj rejestrację"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja firma ma już konto. Chcesz dołączyć do konta firmowego czy utworzyć nowe?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj instrukcje"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konto"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istnieje już konto"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do czasu uregulowania zaległych faktur za subskrypcję nie możesz uzyskać dostępu do konta Zendesk QA."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja została zawieszona"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono konta. Spróbuj ponownie."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ustaw nowe hasło."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spróbuj ponownie lub ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowa kombinacja adresu e-mail i hasła."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś administratora o nowy."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten link z zaproszeniem jest nieprawidłowy lub został już użyty."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metoda logowania ", _interpolate(_named("method")), " na Twoim koncie jest dezaktywowana."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To zaproszenie jest nieprawidłowe lub zostało użyte. Poproś administratora o nowe."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować informacji o zaproszeniach."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepowodzenie rejestracji. Użytkownik o tym adresie e-mail już istnieje."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto zostało zablokowane po wielu kolejnych próbach zalogowania się. Sprawdź, czy w skrzynce odbiorczej masz e-mail z instrukcją jego odblokowania."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najpierw zweryfikuj swój adres e-mail"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowe konto e-mail"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole jest wymagane"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole jest wymagane"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod logowania"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij magiczne łącze"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Służbowy adres e-mail"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój służbowy adres e-mail"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Główna umowa o świadczenie usług)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło jest niezgodne z wymaganiami"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacja o ochronie danych osobowych"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poczekaj"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Witaj, ", _interpolate(_named("invitee_name")), "!"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dołącz do organizacji ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój adres e-mail został zweryfikowany. Teraz możesz się zalogować przy użyciu swojego nowego konta poniżej."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj poprzez konto Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy link z zaproszeniem"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się za pomocą magicznego łącza"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć, aby się zalogować"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj w Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj pracę z Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój adres e-mail został zweryfikowany. Teraz możesz się zalogować przy użyciu swojego nowego konta poniżej:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się przez Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się przez magiczny link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj przez Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się na koncie Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostało usunięte"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz konta?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowy kod"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole jest wymagane"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowe konto e-mail"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole jest wymagane"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod logowania"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie opcje logowania się"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " za pomocą tradycyjnych metod"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się za pomocą magicznego łącza"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz e-mail z kodem logowania."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz e-mail, z którym możesz się zalogować."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas wysyłania magicznego linku wystąpił błąd. Spróbuj ponownie."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się przez e-mail"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hasło"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna mała litera"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna wielka litera"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło musi zawierać"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna długość hasła to 128 znaków"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łatwe do zapamiętania, trudne do odgadnięcia"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej 8 znaków"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hasło"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pamiętasz hasła?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna mała litera"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna cyfra"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna wielka litera"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby zresetować hasło, wprowadź adres e-mail, a wyślemy Ci e-mail z instrukcjami."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź prawidłowe konto e-mail"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole jest wymagane"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wysłano e-mail na adres ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli e-mail nie pojawi się w skrzynce odbiorczej lub w folderze ze spamem, sprawdź wprowadzony adres e-mail."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć, aby się zalogować"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź pocztę e-mail"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", po czym otrzymasz e-mail umożliwiający zresetowanie hasła."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli Twoje konto ma już hasło"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli ten ", _interpolate(_named("bold")), ", to wyślemy Ci łącze umożliwiające zresetowanie hasła."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail jest powiązany z kontem"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy pamiętasz hasło?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas wysyłania łącza resetowania wystąpił błąd. Spróbuj ponownie."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj hasło"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się poprzez magiczny link"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Zasady prywatności)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rejestrując się, wyrażasz zgodę na Zendesk QA ", _interpolate(_named("terms_of_service")), " oraz ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunki świadczenia usługi"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masz konto?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się przez magiczny link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się przy użyciu służbowego adresu e-mail"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się przez Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się w Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się w Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam aktywne konto Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zarejestruj się lub zaloguj, aby skonfigurować ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy to naprawdę Ty? Sprawdź w skrzynce odbiorczej e-mail z potwierdzeniem."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko gotowe"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA działa najlepiej na komputerze. Zaloguj się tam, aby zacząć przeglądać konwersacje."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarezerwuj prezentację"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albo zarezerwuj z nami prezentację, a pokażemy Ci, co możesz robić w ramach Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja obecność jest wymagana"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anuluj"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zmieniłem(-am) zdanie i moje działanie to ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " zaprasza Cię do swojego konta."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do konta"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konto"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj się zalogować jeszcze raz"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otrzymana reakcja na Twoją opinię: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane reakcje"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " zareagował(a) na Twój komentarz"]), _normalize(["Kilka osób zareagowało na Twój komentarz"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " zareagował(a) na Twoją odpowiedź"]), _normalize(["Kilka osób zareagowało na Twoją odpowiedź"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " zareagował(a) na Twoją recenzję"]), _normalize(["Kilka osób zareagowało na Twoją recenzję"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentarz"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odpowiedź"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzja"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPORY"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPORZĄDZONE"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODEBRANE"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinie o ankiecie"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakcje"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęte"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczyć komentarz jako przeczytany?"]), _normalize(["Oznaczyć wszystkie komentarze jako przeczytane?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczyć spór jako przeczytany?"]), _normalize(["Oznaczyć wszystkie spory jako przeczytane?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczyć opinie o ankiecie jako przeczytane?"]), _normalize(["Oznaczyć wszystkie opinie o ankiecie jako przeczytane?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczyć recenzję jako przeczytaną?"]), _normalize(["Oznaczyć wszystkie recenzje jako przeczytane?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczono komentarz jako przeczytany"]), _normalize(["Oznaczono wszystkie komentarze jako przeczytane"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczono spór jako przeczytany"]), _normalize(["Oznaczono wszystkie spory jako przeczytane"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczono opinie o ankiecie jako przeczytane"]), _normalize(["Oznaczono wszystkie opinie o ankiecie jako przeczytane"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Oznaczono recenzję jako przeczytaną"]), _normalize(["Oznaczono wszystkie recenzje jako przeczytane"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz wszystkie jako przeczytane"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako przeczytane"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzje"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdy ktoś zakwestionuje recenzję, będzie to widoczne w tym miejscu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak sporów"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiedy zostaniesz przydzielony(-na) do rozwiązania sporu, będzie on widoczny tutaj."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otrzymanych sporów"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdy zakwestionujesz recenzję, będzie to widoczne tutaj."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak rozpoczętych sporów"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj są wyświetlane wszystkie opinie. Na razie nie ma nic do pokazania."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie otrzymano opinii"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj są wyświetlane wszystkie reakcje na Twoje recenzje i odpowiedzi. Na razie nie ma nic do pokazania."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otrzymanych reakcji"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz konwersację"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj trafiają wszystkie opinie. Zacznij od dodania opinii w widoku konwersacji."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak recenzji"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj są wyświetlane wszystkie opinie. Na razie nie ma nic do pokazania."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otrzymanych recenzji"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono komentarzy"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj są wyświetlane wszystkie opinie wystawione jako komentarze."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie podano komentarzy"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono komentarzy"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie komentarze pod Twoją opinią, ", "@", "wzmianki lub odpowiedzi na Twoje komentarze są wyświetlane w tym miejscu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otrzymanych komentarzy"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zaakceptowanych sporów"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otwartych sporów"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak odrzuconych sporów"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak rozwiązanych sporów"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj ponownie, wybierając inną opcję z powyższego filtru."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj ponownie, wybierając innego użytkownika z powyższego filtru."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone komentarze"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane komentarze"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie spory"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane spory"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęte spory"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzja"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba recenzowana"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane opinie o ankiecie"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone recenzje"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę aktywności"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " sprawdzono"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " sprawdzono"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " sprawdzono"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uruchamia się ponownie za ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pozostało ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonfiguruj zadanie"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę zadań"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie można utworzyć sesji coachingowej między ", _interpolate(_list(0)), " oraz ", _interpolate(_list(1)), " ponieważ te osoby obecnie nie znajdują się w tym samym środowisku pracy."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sesje coachingowe między ", _interpolate(_list(0)), " oraz ", _interpolate(_list(1)), " już istnieją. Skontaktuj się z ", _interpolate(_list(0)), ", aby uzyskać dostęp do sesji."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propozycja"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Utworzono nową sesję między ", _interpolate(_list(0)), " oraz ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz coacha"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba coachowana"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz osobę coachowaną"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj sesję"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa sesji"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propozycja"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaległa"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednia"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadchodząca"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sesji"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas sprawdzania"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych do pokazania za wybrany okres"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria oceny"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dodano pinezkę do sesji. ", _interpolate(_named("buttonStart")), "Pokaż", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto koniec pinezek z wybranego okresu"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zobacz, jak działają pinezki, które zawiera ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artykuł"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wygląda na to, że nie masz przypiętych żadnych elementów dla użytkownika ", _interpolate(_list(0)), ". Pinezki mogą służyć do przygotowywania się do spotkań 1:1 lub zapisywania czegoś na inny czas."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["osoba coachowana"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano jeszcze żadnych pinezek"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oto wszystkie pinezki utworzone przez Ciebie dla użytkownika ", _interpolate(_named("name")), ". Pinezki dodawane do sesji zostaną ", _interpolate(_named("bold")), " z drugim uczestnikiem."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natychmiast udostępnione"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wygląda na to, że ", _interpolate(_list(0)), " nie otrzymał(a) w wybranym okresie żadnej opinii do przejrzenia konwersacji lub CSAT."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak opinii"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto koniec komentarzy z wybranego okresu"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskaźniki"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Liczby użytkownika ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa czynność"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ukończone przez ", _interpolate(_named("user")), " w terminie ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["przez ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ty"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz, aby dodać nowy element czynności"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co wyniknęło z tego spotkania? Jakie są następne kroki?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozycje czynności"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utworzone przez ", _interpolate(_named("name")), " w dniu ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usunięcie sesji"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spowoduje to nieodwracalne ", _interpolate(_named("deleteSession")), ". Uczestnicy utracą dostęp do sesji, w tym jej zawartości i prywatnych notatek."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sesja została utworzona ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostatnia edycja: ", _interpolate(_named("name")), " w dniu ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkty i notatki do omówienia"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano notatkę"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuść sesję"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od tego momentu wybrani użytkownicy będą mieć dostęp do szczegółów sesji."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkowników"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępniona"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij sesję"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie będziesz już uczestniczyć w ", _interpolate(_list(0)), " – ", _interpolate(_list(1)), " ."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nie będzie już częścią tej sesji."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " ma teraz dostęp do tej sesji."]), _normalize([_interpolate(_named("n")), " nowych użytkowników ma teraz dostęp do tej sesji"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sesję"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie sesje"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sesję"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tutaj pojawią się notatki, które doda ", _interpolate(_list(0)), "."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie przypiętych elementów jako punktów dyskusji z poziomu prawego panelu bocznego"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notatki widoczne tylko dla Ciebie..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notatki osobiste"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notatka prywatna została zaktualizowana"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dni"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba coachowana"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozycje czynności"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia sesja"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaległe"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny sesji"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl wszystkie"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja: Osoba coachowana"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie sesje"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnione sesje"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj przełączyć filtr, aby zobaczyć czynności do wykonania."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak czynności"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozycje czynności"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane i wyświetlone recenzje"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrane · Przeczytane"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postęp po sesjach"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że agenci nie otrzymali żadnej informacji zwrotnej."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, czy masz prawidłową nazwę użytkownika"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się znaleźć tego użytkownika"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ z ostatnich 30 dni"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spowoduje to nieodwracalne <b>usunięcie wszystkich sesji</b> (2) z <b>", _interpolate(_named("name")), "</b>. Uczestnicy stracą dostęp do wszystkich sesji, w tym do zawartości i prywatnych notatek."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto koniec wszystkich sesji"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja coachingowa to grupowe działanie mentora i osoby coachowanej mająca na celu omówienie wyników, szans oraz celów krótko- i długoterminowych."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje można wyszukiwać zarówno na podstawie nazwiska coacha, jak i osoby coachowanej."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sesji"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono jeszcze sesji"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń historię coachingu"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń historię coachingu "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia sesja"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie sesje"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba coachowana"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako zakończone"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako cofnięte"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj wg nazwy"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę sesji"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezaplanowana"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedz na tę wiadomość"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja kalibracyjna wygasła. Nie można zostawić więcej recenzji."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Już wystawiono recenzję tej konwersacji."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzenie tej konwersacji nie jest już możliwe, ponieważ termin sesji minął."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta konwersacja nie ma recenzji"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostaw recenzję kalibracji"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZCZEGÓŁY"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PODSUMOWANIE"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAPIS"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Głośnik ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezczynny"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj głośnik"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj wiadomość"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do odtwarzania wiadomości"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Głośniki"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapis"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy środowiska pracy"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najpierw dodaj połączenie, aby utworzyć filtry"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak botów"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utworzono: ", _interpolate(_named("time")), " autor: ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostatnia aktualizacja: ", _interpolate(_named("time")), " autor: ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wygląda na to, że nie masz żadnych filtrów. Aby uzyskać dostęp do tej strony, musisz najpierw utworzyć filtr. Rozpocznij od ", _interpolate(_named("recommendedFilter")), ", aby zobaczyć własne konwersacje. Utwórz poniższy filtr, aby kontynuować."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w ciągu ostatnich 30 dni"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunek „Utworzone w ciągu ostatnich 30 dni” zostanie dodany do filtrów utworzonych bez zakresu dat"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr jest niedostępny w tym planie i nie będzie brany pod uwagę przy wyświetlaniu wyników"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upłynął limit czasu pobierania opcji filtru. Spróbuj ponownie."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o filtrowaniu"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun = zalogowana osoba"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Znalezione konwersacje: ", _interpolate(_named("count"))]), _normalize(["Znalezione konwersacje: ", _interpolate(_named("count"))]), _normalize(["Znalezione konwersacje: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedostępne w Twoim bieżącym planie. Aby korzystać z tej funkcji, przejdź na wyższą wersję planu."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj warunek"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor: SI Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunek"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamień w zadanie"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz filtr"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz filtr"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowane pola"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunki"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładujemy opcje filtrowania zgodnie z określoną konfiguracją. Wstępne ładowanie może potrwać kilka minut."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaj filtrowi opisową nazwę"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa filtru"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcję"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunek"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prywatne"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiczne"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj filtr"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz zmiany"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępnych wartości"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wartość"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczność"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko aktywne sesje ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż starsze sesje ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj sesję kalibracji"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję kalibracji"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie recenzje kalibracji dodane podczas sesji zostaną nieodwracalnie usunięte."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sesję"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń sesję kalibracyjną ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnych sesji"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych sesji"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnych sesji"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych sesji"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj filtr"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz filtr prywatny"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz filtr publiczny"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano filtr"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr zostanie nieodwracalnie usunięty."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń filtr"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń filtr ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie spory"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory dla mnie"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje spory"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte spory"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersje robocze"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych filtrów"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono filtrów prywatnych"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono filtrów publicznych"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry prywatne"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry publiczne"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcesz ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zapisać"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To jest widok filtra tymczasowego."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruowanie filtru..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano filtr"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowa kategoria Automatycznej QA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostatnia aktualizacja: ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożone wyrazy"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne recenzje są aktualizowane dynamicznie na podstawie zmian w zgłoszeniach"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaznacz w konwersacji"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długie zdania"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie przewidziano automatycznej oceny"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych błędów"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż złożone wyrazy"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż długie zdania"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna konwersacja"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA nie mogła ocenić tej kategorii z powodu niewystarczającego kontekstu. Zalecane ręczne sprawdzenie."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkryj automatyczny wgląd w konwersacje, aby usprawnić proces recenzowania. Podkreślanie kluczowych konwersacji i wyróżnianie pozytywnych przykładów dla zespołu."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Zadzwoń]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprawione"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięte"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " zaakceptował(a) spór"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wysłano spór do ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjaśnij, dlaczego uważasz, że ta osoba recenzowana jest niewłaściwym odbiorcą tej opinii"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komentarz i ustaw nową osobę recenzowaną"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komentarz"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjaśnij, dlaczego Twoim zdaniem te oceny są nienależycie oceniane (obowiązkowe)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komentarz (obowiązkowe)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjaśnij, dlaczego odrzucasz ten spór"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komentarz"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij spór do"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij spór do"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij spór do"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz nową osobę recenzowaną"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne na temat sporu"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne na temat sporu"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień osobę recenzowaną"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij spór do"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spór"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakwestionuj tę recenzję"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zgadzam się z punktacją"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta recenzja nie jest przeznaczona dla mnie"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkownika"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " częściowo zaakceptował(a) spór"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " odrzucił(a) spór"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptowany"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zaakceptowane"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzucony"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Użytkownik ", _interpolate(_named("userName")), " rozpoczął spór: recenzja"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Użytkownik ", _interpolate(_named("userName")), " rozpoczął spór: niewłaściwa osoba recenzowana"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby można było podjąć inne czynności związane z recenzją, należy rozwiązać otwarty spór."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptuj"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz nowe wyniki"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń spór"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz odrzucić ten spór?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń spór"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spór"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas załatwienia sporu"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz nowe rzetelne wyniki (opcjonalnie)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd sporu"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewłaściwa osoba recenzowana"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptuj spór"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptuj spór"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne na temat sporu"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewłaściwa osoba recenzowana"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne na temat sporu"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć spór"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć spór"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl spór"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytowano"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " do ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["do ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzeni"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko gotowe"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydzielono do:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Również zostaw komentarz"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o warunkowych kartach wyników"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednio przydzielony do:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po prostu wybierz jedną opcję"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnego GIF-a o takiej nazwie"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz recenzję jako pierwsza osoba."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja subskrypcja jest obecnie wstrzymana. Aby umożliwić przekazywanie opinii, aktywuj je ponownie."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokazuje, ile czasu poświęcono na recenzowanie konwersacji"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkownika"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Brak głównej przyczyny dla kategorii: „", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń kategorię lub dodaj komentarz"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiń"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiń pole komentarza"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiń pole komentarza"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj formatowanie"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż formatowanie"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij recenzję"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń wszystkie kategorie"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń co najmniej jedną kategorię"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz konwersację na później"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz na później"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następne zadanie"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastąp konwersację w tym cyklu zadań"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj konwersację"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzja punktu wyjścia"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń komentarz"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz usunięty"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć ten komentarz?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor komentarza"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten hashtag został usunięty lub jego nazwa została zmieniona."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij to, co Ci się podobało lub mogło być lepsze..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skomentuj"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunkowe"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to nieodwracalne usunięcie wszystkich komentarzy."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń komentarz"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Wiadomość]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nie widział(a) jeszcze tej opinii"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " widział(a) tę opinię"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Były członek"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj GIF-ów..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naciśnij klawisz Enter, aby utworzyć nowy hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowe hashtagi zostały zablokowane przez administratora."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwia to tworzenie zakładek do zgłoszeń i ich późniejsze filtrowanie"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako plan bazowy"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość zwrotna"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna konwersacja"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak informacji zwrotnej"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak recenzji"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby rozpocząć, zostaw recenzję poniżej."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma innych recenzji"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieoceniona"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzedni opiekun"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednia konwersacja"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń jako plan bazowy"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedz na tę recenzję"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzja"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj menu boczne recenzji"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to nieodwracalne usunięcie recenzji i wszystkich dodanych komentarzy."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń recenzję"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń recenzję"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzja została usunięta"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Recenzja została usunięta przez użytkownika ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz zostawić recenzję, przydziel to zadanie sobie"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Już wystawiono recenzję dla tego użytkownika."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wystawiasz recenzję dla użytkownika ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kategorii do pokazania w tej konwersacji."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje własne nie są dostępne w tym środowisku pracy."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zapisuj"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisać tę recenzję jako wersję roboczą?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć tę wersję roboczą"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż menu boczne recenzji"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieudane"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaliczone"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaliczone z niewielkimi błędami"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadal analizuję dane..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostaw komentarz"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj tę wiadomość"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrecenzuj siebie"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj tę konwersację"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak użytkowników do przejrzenia"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje wewnętrzne wyłączone"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnego użytkownika"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnicy"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy środowiska pracy"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkownika"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 recenzja"]), _normalize([_interpolate(_named("count")), " recenzji(-e)"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz nowe oceny"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój menedżer środowiska pracy nie zezwolił na samooceny."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naciśnij klawisz Enter, aby otagować tę pinezkę"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracje"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 30 dni"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaczone gwiazdką"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję kalibracji"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersje robocze"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd to wizualna reprezentacja danych centrum pomocy. Korzystając z interaktywnych wykresów, zorientuj się, na czym skupić się na etapie QA, odkrywania słabych punktów i znajdowania odpowiednich agentów do przejrzenia na podstawie ich wyników."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ADRES URL ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przetłumacz na ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzydzielone"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie wyświetlane przez"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzam teraz"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta konwersacja została usunięta i nie jest już dostępna."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja, której szukasz, nie istnieje w Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie pinezki z tą konwersacją"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W sesji"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie konwersacji z sesji spowoduje usunięcie wszystkich dodanych do niej recenzji."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń konwersację z ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono jeszcze sesji kalibracji."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta konwersacja została dodana do sesji"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta konwersacja została usunięta z sesji"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do sesji kalibracji lub usuń z niej"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj łącze do tej konwersacji"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji do wyświetlenia"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail klienta"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klienta"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko klienta"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa formularza zewnętrznego"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas pierwszego odzewu"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorytet"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do ankiety"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik oceny zadowolenia"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas do pierwszego rozwiązania"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oryginał"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta wiadomość nie istnieje w tej konwersacji lub została usunięta"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak tematu"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji do wyświetlenia"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnej konwersacji"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz oryginalną konwersację"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można utworzyć linku do konwersacji – brak adresu URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnicy"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak uczestników"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij konwersację"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby przeczytać recenzje"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby przejrzeć"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja utworzona przy użyciu rozszerzenia do przeglądarki"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl ankietę dotyczącą zadowolenia klienta"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz konwersację"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość o negatywnym wydźwięku"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość o pozytywnym wydźwięku"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetłumacz na angielski"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż oryginał"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usunięcie konwersacji jest nieodwracalne. Usuwa również wszystkie powiązane dane, np. ankiety, zapisy i recenzje z Zendesk QA, w tym panele.", _interpolate(_named("lineBreak")), "Nadal będziesz mieć dostęp do tej konwersacji w Zendesk Support lub innym oprogramowaniu centrum pomocy."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usunięcie konwersacji jest nieodwracalne. Usuwa również wszystkie powiązane dane, np. ankiety, zapisy i recenzje z Zendesk QA, w tym panele. Jeśli konwersacja należy do wielu środowisk pracy, zostanie ona usunięta ze wszystkich.", _interpolate(_named("lineBreak")), " Nadal będziesz mieć dostęp do tej konwersacji w Zendesk Support lub innym oprogramowaniu centrum pomocy, którego używasz."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację z Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konwersacja nie została ukończona z powodu eskalacji. Przejdź do ", _interpolate(_named("optionsUrl")), " i sprawdź poprawność tagów. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konwersacja nie została ukończona z powodu eskalacji. Porozmawiaj z administratorem w sprawie weryfikacji tagów. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia > Opcje zaawansowane"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o tagach"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknięte"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujące"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiązane"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opiekun zmieniony na ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzydzielone"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " makro zastosowane przez ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konwersacja z oceną ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Użytkownik ", _interpolate(_named("target")), " ukrył(a) konwersację"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status został zmieniony na ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Z tagiem ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zespół zmieniony na ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " odłożył(a) konwersację przez: ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " odłożył(a) konwersację"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot utknął w pętli, kolejno powtarzając wiadomość"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórka bota"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórka bota – ta sama wiadomość powtarzana jest co najmniej raz z rzędu"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znaleziono pasujące frazy"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przeciętny agent obsługuje konwersację ", _interpolate(_named("percent")), " wydajniej niż bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydajność komunikacji z botem"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot obsłużył konwersację ", _interpolate(_named("percent")), " efektywniej niż przeciętny agent"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydajność komunikacji z botem"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W konwersacji brakuje oświadczenia informującego uczestników o nagraniu, które jest wymagane w celu zachowania zgodności i zachowania przejrzystości"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ujawnienia nagrania"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Naruszono umowę o poziomie usług (SLA) z: ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naruszona umowa SLA"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskaźnik reguły SLA przekroczył swój docelowy czas"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaki zanikania klientów. Klient rozważał zmianę lub obiecał rezygnację."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoznano ryzyko odejścia"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["W konwersacji są chwile bez wyrazu trwające dłużej niż ustawiony próg wynoszący ", _interpolate(_list(0)), " s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instancja"]), _normalize([_interpolate(_named("count")), " instancji"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Najdłużej ", _interpolate(_list(0)), " s"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezczynny"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient szukał pomocy na wyższym poziomie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prośba o eskalację"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient wyraził ogromną wdzięczność lub był bardzo zadowolony z otrzymanego wsparcia"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątkowa obsługa"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient lub agent wyraźnie poprosił o kontynuację"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prośba o kontynuację"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient wyraził niezadowolenie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk negatywny"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja była nietypowa lub niezwykła, osiągnięcie rozwiązania wymagało wymiany większej ilości informacji"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykryto przypadek odstający"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient wykazał zadowolenie"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk pozytywny"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma zastosowanie"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw na status „Nie dotyczy”"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostatnia edycja: ", _interpolate(_named("author")), " w dniu ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dotyczy"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przez ", _interpolate(_named("author")), " w dniu ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj przyczynę (opcjonalnie)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto żadnych wglądów"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj połączenie"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz centrum pomocy ze swoim kontem i utwórz co najmniej jeden filtr, aby wyświetlić listę konwersacji."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji do wyświetlenia"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl ustawienia środowiska pracy"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba sprawdzana"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(za 1 dzień)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(w tym miesiącu)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(w tym tygodniu)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź wg ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyniki wyszukiwania dla: ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzone przez innych"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodał(a) komentarz"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnowsze"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najstarsze"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Losowe"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio zaktualizowane"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio udzielono odpowiedzi"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortuj wg"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzeczytane"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzona przez Ciebie"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces importowania może trochę potrwać. Gdy się zakończy, wyślemy Ci e-mail."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importuje Twoje konwersacje"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limit: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj, klikając ikonę kalibracji w nagłówku widoku konwersacji."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma tutaj nic do raportowania. Spróbuj ponownie, używając innego filtru lub innej opcji sortowania."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak przypadków odstających do wyświetlenia."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź odpowiednią konwersację i kliknij ikonę gwiazdki w nagłówku, aby dodać ją do zakładek."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj zmienić filtry lub zaproś innych agentów centrum pomocy, aby dodać więcej konwersacji."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak limitu"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notatka wewnętrzna"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwa ładowanie konwersacji. Wciąż może brakować części zawartości."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następny użytkownik"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnego użytkownika"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie środowiska pracy"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzedni użytkownik"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych konwersacji"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych konwersacji"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj trafiają niedokończone recenzje. Przeczytaj je ponownie, popraw i wyślij w ciągu 30 dni."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak recenzji w wersji roboczej"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji oznaczonych gwiazdką"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji do wyświetlenia"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dopasowania"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do wybranej wiadomości"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najmniej odpowiedzi"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najwięcej odpowiedzi"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę konwersacji"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozmowa odebrana o ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozmowa zakończona o ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Połączenie nawiązane o ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzona"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzone przez Ciebie"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie udało się załadować pliku audio. Zaloguj się do ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do ankiety dotyczącej zadowolenia klienta"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd podczas ładowania dźwięku"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor komentarza"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie nawiązano połączenia"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posłuchaj w centrum pomocy"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słuchaj przez Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybkość odtwarzania"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź rozmowę"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapis"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To nie jest użytkownik Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatycznie klasyfikuje agentów na podstawie ich średniej miesięcznej CSAT. Jednym kliknięciem znajduj konwersacje obsługiwane przez agentów, których wskaźnik CSAT jest niższy niż u agentów równorzędnych."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking wg CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoka"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niska"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najniższa"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najwyższa"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To nie jest użytkownik Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno zgłoszenie"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Głośność"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatycznie klasyfikuje agentów w grupy wydajności na podstawie liczby obsługiwanych przez nich konwersacji w miesiącu. Jednym kliknięciem znajduj konwersacje agentów o niższym wolumenie niż ich współpracownicy."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking według wolumenu"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzona"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niesprawdzona"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status sprawdzania"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutralny"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To nie jest użytkownik Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatycznie klasyfikuje agentów na podstawie średniego wydźwięku konwersacji. Znajdź interakcje, w których agenci jednym kliknięciem kontaktują się z bardziej sfrustrowanymi klientami niż ich współpracownicy."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking według wydźwięku"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykryto"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypadki odstające"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje wymagające Twojej uwagi. Nasz model uczenia maszynowego identyfikuje złożone lub nietypowe interakcje do dalszego recenzowania."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypadki odstające"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatywny"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozytywny"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybieraj do przejrzenia konwersacje, w których klient wyrażał zadowolenie lub frustrację. Z listy rozwijanej wybierz typ wydźwięku, którego chcesz szukać w konwersacjach. Przeciągnij atrybut filtru na górę, aby zobaczyć stosunek wybranego wydźwięku do wszystkich interakcji."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status sprawdzania"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niesprawdzone"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzone"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA automatycznie wykrywa języki w konwersacjach. Użyj menu rozwijanego, aby wybrać konwersacje w określonych językach do przejrzenia. Przeciągnij atrybut filtru na górę, aby uzyskać widok ogólny wszystkich języków używanych w centrum pomocy."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd w konwersacje"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Możesz ", _interpolate(_named("paragraph_2_item")), " dzięki wglądowi niż w przypadku zwykłych filtrów konwersacji. Uzyskaj widok ogólny wszystkich danych z centrum pomocy i korzystaj z takich wglądów jak:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o używaniu wglądu w konwersacje"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj względną ważność określonych atrybutów konwersacji w odniesieniu do innych"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź agentów najskuteczniejszych i najsłabszych dzięki wbudowanemu rankingowi wydajności agentów"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " to zaawansowane interaktywne narzędzie do eksploracji danych, które pomaga znaleźć konwersacje do przejrzenia."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrobić o wiele więcej"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wykresy Konwersacja i Agent są ", _interpolate(_named("paragraph_3_item_1")), " – aktualizacja filtrów lub klikanie fragmentów wykresu podzielonych na segmenty na którymkolwiek z nich spowoduje automatyczną aktualizację listy konwersacji. ", _interpolate(_named("paragraph_3_item_2")), " aby poznać szczegółowe podziały lub wskaźniki różnych elementów."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["połączone"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień kolejność atrybutów filtru"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyświetlany jest tylko wgląd w konwersacje ", _interpolate(_named("paragraph_4_item")), ". Automatycznie i bez sprawdzania usuwamy interakcje takie jak wiadomości generowane automatycznie, spam, odpowiedzi botów lub wiadomości, które nie są odpowiednio komunikowane z przedstawicielem wsparcia i bez sprawdzania ich prawdziwej wartości."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["czyste i zamknięte konwersacje"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co tu widzę?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " z ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się pobrać"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ND."])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenci"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " ze wszystkich)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ale jesteśmy pewni, że gdy tylko dotrze do nas, będzie świetnie."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowana ankieta"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz jeszcze żadnych opinii o ankietach"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma jeszcze dostępnej opinii o ankiecie..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyna"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odebrano"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag opinii"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi komentarza w ramach opinii"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosowano filtr zakresów dat"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " odpowiedź"]), _normalize([_interpolate(_named("count")), " odpowiedzi"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dowiedz się więcej, dlaczego ta ocena ", _interpolate(_list(0)), " została przyznana na podstawie skonfigurowania przyczyn w ustawieniach ankiety."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie ma jeszcze przyczyn ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj przyczyny ankiety"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje z tagami:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autor:"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powody"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria komentarza"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Powody ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przewidywane schematy ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło konwersacji"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi centrum pomocy"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Odpowiedzi na ankietę (", _interpolate(_named("n")), ")"]), _normalize(["Odpowiedź na ankietę (", _interpolate(_named("n")), ")"]), _normalize(["Odpowiedzi na ankietę (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak tematu"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konwersacje z ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " wg użytkowników"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik odpowiedzi"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Wynik ", _interpolate(_list(0)), " to suma wszystkich odpowiedzi podzielona przez sumę maksymalnych ocen"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj cel"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń cel"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw cel"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj cel"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krótki"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bardzo długi"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długi"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mylący"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzydatny"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowe wsparcie"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobre wsparcie"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnóstwo emocji"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne dla agenta"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklamacja"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragmentaryczny"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem nie został rozwiązany"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem rozwiązany"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk negatywny"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zły wynik"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk pozytywny"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pochwała"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot kosztów"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybkie wsparcie"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolne wsparcie"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękuję"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA automatycznie przydziela Twoje dane ", _interpolate(_list(0)), " do predefiniowanych kategorii. Dzięki temu można zrozumieć złożone analizy i zbadać przyczyny bez ręcznego przeszukiwania pisemnych opinii."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie oceny"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie ankiety"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz język odpowiedzi"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar komentarza"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz rozmiar komentarza"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponieważ 25% komentarzy zawiera tylko jedno słowo, dłuższe komentarze są obowiązkową lekturą. Uzyskuj bardziej wnikliwe informacje zwrotne bez narażania się na pracę ręczną, ponieważ robimy to za Ciebie automatycznie."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyna opinii"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz przyczynę wysłania opinii"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak pasujących ocen"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przewidywane schematy"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kategorię komentarza"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrazy z Wordcloud"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wyrazy z funkcji Wordcloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " od ostatniego okresu"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konwersacji"]), _normalize([_interpolate(_named("n")), " konwersacja"]), _normalize([_interpolate(_named("n")), " konwersacji(-e)"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można obliczyć współczynnika wysłanych ankiet ze wszystkich konwersacji z powodu braku danych."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane ankiety"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejek ankiety"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przewidywane schematy ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA automatycznie przydziela Twoje dane ", _interpolate(_list(0)), " do predefiniowanych kategorii. Dzięki temu można zrozumieć złożone analizy i zbadać przyczyny bez ręcznego przeszukiwania pisemnych opinii."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar komentarza"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponieważ 25% komentarzy zawiera tylko jedno słowo, dłuższe komentarze są obowiązkową lekturą. Uzyskuj bardziej wnikliwe informacje zwrotne bez narażania się na pracę ręczną, ponieważ robimy to za Ciebie automatycznie."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi ankiety"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (średnio)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ (średnio)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnio"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Trendy ", _interpolate(_list(0)), " wg"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powody"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schematy"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data wysłania ankiety ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data odpowiedzi na ankietę ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Przydziel ocenę ", _interpolate(_list(0)), " do"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl dane na podstawie"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia konwersacji"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["właściciel zgłoszenia"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agent z największą liczbą odpowiedzi"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (średnio)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " w czasie"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " i WOJ"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje zawierające następujące słowa:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chmura wyrazów"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT wg użytkowników"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejek ankiety"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi ankiety"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi centrum pomocy"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT w czasie"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chmura wyrazów"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kanału"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak źródła"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak języka"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak przyczyn"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak tagów"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kategorii komentarza"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak środowiska pracy"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych, ponieważ nie utworzono przydziałów lub nie rozpoczęto cykli. Sprawdź później lub dostosuj filtry."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz, co nowego"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " element"]), _normalize([_interpolate(_named("count")), " elementy(-ów)"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konwersacja"]), _normalize([_interpolate(_named("count")), " konwersacje(-i)"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieudane"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaliczone"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaliczone z niewielkimi błędami"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaliczone/Niezaliczone"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnio"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnio (godz.)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnio (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oceny ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni CSAT = [suma ocen opinii ÷ (liczba wszystkich odpowiedzi × maksymalna możliwa ocena)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena CSAT Zendesk QA normalizuje wynik opinii w skali od 0 do 100% i stanowi średnią ocen standaryzowanych."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Poziom zadowolenie klientów) określa ilościowo poziom zadowolenia klientów z oferowanych usług."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Każda recenzja odzwierciedla opinię jednej osoby w ramach jednej konwersacji. Konwersacja może mieć więcej niż jedną recenzję (jeśli co najmniej dwóch recenzentów recenzuje tę samą konwersację)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoruj ewolucję ocen recenzentów w czasie."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoruj ewolucję ocen agentów w czasie."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odpowiedzi"]), _normalize([_interpolate(_named("n")), " odpowiedź"]), _normalize([_interpolate(_named("n")), " odpowiedzi"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " recenzji"]), _normalize([_interpolate(_named("n")), " recenzja"]), _normalize([_interpolate(_named("n")), " recenzji(-e)"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ (Wewnętrzna ocena jakości) powstaje na podstawie recenzji konwersacji."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ = suma ocen recenzji ÷ liczba recenzji"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jest ona obliczana jako średnia uwzględniająca wszystkie oceny z recenzji (średnia ważona ocen kategorii) pasujące do warunków wybranego filtru."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować karty."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień filtry i spróbuj ponownie."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik zaliczenia wskazuje wyniki obejmujące recenzje konwersacji."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niezaliczona, jeśli ocena z recenzji to ", _interpolate(_named("failScore")), " lub gorsza"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zaliczona, jeśli ocena z recenzji to ", _interpolate(_named("passScore")), " lub lepsza"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jest on określany przez określony wstępnie plan bazowy, co oznacza, że:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskaźnik ocen pozytywnych = suma recenzji pozytywnych ÷ suma wszystkich recenzji"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik ocen pozytywnych wskazuje procent liczby ocen pozytywnych, w których nie zaliczono kategorii krytycznych ani niekrytycznych."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli na przykład recenzent wystawi 3 recenzje w tym dniu, wyświetlona liczba będzie średnią z 3 ocen. Zobacz, jak są obliczane poszczególne komórki ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["np. jeśli na przykład agent otrzyma 3 recenzje w tym dniu, wyświetlona liczba jest średnią 3 ocen. Zobacz, jak są obliczane poszczególne komórki ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie są brane pod uwagę: waga kategorii, status krytyczny (w odróżnieniu od ocen w czasie)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponieważ kategorie mogą być oznaczane jako niedostępne w opiniach, liczba recenzji na kategorię może być niższa od łącznej liczby recenzji napisanych przez recenzenta. Zobacz, jak są obliczane poszczególne komórki"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie są brane pod uwagę: waga kategorii, status krytyczny (w odróżnieniu od ocen w czasie)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponieważ kategorie mogą być oznaczane w recenzjach jako niedostępne, liczba recenzji w danej kategorii może być niższa od łącznej liczby recenzji na agenta. Zobacz, jak są obliczane poszczególne komórki"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie są brane pod uwagę: waga kategorii, status krytyczny (w odróżnieniu od ocen w czasie)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grupy kategorii są wyświetlane osobno. Ocena w każdej grupie jest obliczana na podstawie średniej ocen w danej grupie w tym samym okresie. Zobacz, jak są obliczane poszczególne komórki ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie są brane pod uwagę: waga kategorii, status krytyczny (w odróżnieniu od ocen w czasie)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grupy kategorii są wyświetlane osobno. Ocena w każdej grupie jest obliczana na podstawie średniej ocen w danej grupie w tym samym okresie. Zobacz, jak są obliczane poszczególne komórki ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plan bazowy ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak krytycznych błędów"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik braku błędów krytycznych wskazuje procent liczby recenzji zaliczonych bez negatywnych kategorii krytycznych."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzna ocena jakości"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Inne” główne przyczyny"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana okresowa"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana dzienna"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana miesięczna"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana tygodniowa"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana roczna"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki w czasie"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl w dniach lub tygodniach."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczenie = łączna ocena za dzień lub tydzień podzielona przez łączną liczbę sporządzonych recenzji"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl w dniach lub tygodniach."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczenie = łączna ocena za dzień lub tydzień podzielona przez łączną liczbę otrzymanych recenzji"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny według kategorii"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl łączną średnią wszystkich ocen kategorii przyznanych przez recenzenta w tym okresie."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl łączną średnią wszystkich ocen w kategoriach uzyskanych przez agenta w tym okresie."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne przyczyny niskich ocen"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne przyczyny ocen"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny kategorii w czasie"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuj porównywanie kategorii w czasie."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl średnie wyniki w każdej kategorii w wybranym dniu lub tygodniu."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuj porównywanie kategorii w czasie."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl średnie wyniki uzyskane w każdej kategorii w wybranym dniu lub tygodniu."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny według opinii"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowane"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria krytyczna"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala ocen"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia/Suma"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz samooceny"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuje wszystkie karty"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["konwersacja"]), _normalize(["konwersacje"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["element"]), _normalize(["elementy"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " odpowiedzi"]), _normalize([_interpolate(_named("n")), " odpowiedź"]), _normalize([_interpolate(_named("n")), " odpowiedzi"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " recenzji"]), _normalize([_interpolate(_named("n")), " recenzja"]), _normalize([_interpolate(_named("n")), " recenzji(-e)"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pokaż jeszcze jeden"]), _normalize(["Pokaż jeszcze ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 30 dni"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 7 dni"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni miesiąc"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni tydzień"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten miesiąc"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten tydzień"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres niestandardowy"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dni"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiące"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwartały"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tygodnie"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lata"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować karty. Zmień filtry i spróbuj ponownie."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych do wyświetlenia."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana miesięczna"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panele"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne filtry obejmują dane z różnych skal ocen."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie pinezki kart z tymi filtrami"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie pinezki panelu z tymi filtrami"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas sprawdzania"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz plik CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kategorii"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd w kategorie"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Komentarz"]), _normalize(["Komentarze"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macierz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Były członek"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzna ocena jakości"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ (średnio)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekazane recenzje"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij kartę"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij panel"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Przyczyna główna"]), _normalize(["Przyczyny główne"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID opinii"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas sprawdzenia"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba recenzowana"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonfiguruj główne przyczyny"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne przyczyny wskazują, dlaczego konwersacje otrzymują niskie wyniki"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ustawiono głównych przyczyn dla tej kategorii"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena/Suma"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie ocen"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy kategorii ocen"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa kategorii"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowisko pracy"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj kolejność"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupuj kategorie według"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wszystko"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz samooceny"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwzględnij samooceny"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż tylko samooceny"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść filtry"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych hashtagów"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagi komentarzy"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz hashtagi komentarzy"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono tagów"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi centrum pomocy"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj tagi centrum pomocy"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych kart wyników"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz karty wyników"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak pasujących połączeń"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło konwersacji"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz źródło"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej filtrów"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak pasujących środowisk pracy"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba podanych czasów"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie grupy"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak pasujących grup."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie grupy"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl karty"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl dane na podstawie"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia recenzji"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia konwersacji"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż dwa miejsca po przecinku"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl ustawienia"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie środowiska pracy"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całe konto"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa środowiska pracy"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak środowisk pracy"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowany"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flagi"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żywność i napoje"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwierzęta i przyroda"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiekty"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludzie i treść"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podróże i miejsca"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Często używane"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki wyszukiwania"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buźki i emotikony"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz konwersację"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót do listy zadań"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " lub przejrzyj listę ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obecnie korzystasz ze środowiska pracy ", _interpolate(_named("workspace")), ", które nie jest połączone z tą domeną. Kliknij tutaj, aby je teraz połączyć."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dostępne połączenia"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie możesz jeszcze wyrazić opinii na temat ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw jako aktywną kartę centrum pomocy."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można jeszcze sprawdzić tej domeny. Jeśli powinna być, powiadom administratora."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj tę domenę"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Połącz za pomocą ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chwileczkę – trwa konfiguracja"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się uzyskać adresu URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narzędzia, które są już powiązane z kontem Zendesk QA organizacji."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak narzędzi powiązanych z kontem Zendesk QA organizacji."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwia recenzowanie witryn, których adresy URL są zawsze takie same, przez dodanie na końcu przed pozostawieniem recenzji unikatowej wartości skrótu"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz konto"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje domeny"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeny, które są już połączone z tym środowiskiem pracy."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz przejrzeć każdą stronę w następujących domenach"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeny dostępne do przejrzenia"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozszerzenie"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikatowy adres URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Brak połączonego centrum pomocy – przejdź do ", _interpolate(_named("link")), " aby je dodać."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można używać tylko rozszerzenia do przeglądarki Zendesk QA\nw planach Professional i Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień środowisko pracy"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj nazwę"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj wartość"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowane pola"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po raz pierwszy spotykamy się z tą konwersacją. Podaj trochę szczegółów."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer referencyjny konwersacji:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś, dzięki czemu pozwoli na późniejszą identyfikację zgłoszenia"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot kosztów"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj zarówno opiekuna, jak i temat"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj tę konwersację"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzydzielone"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skontaktuj się z kierownikiem lub zmień środowisko pracy na inne niż ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny w tym środowisku pracy są wyłączone."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pa"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowisko pracy"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można sprawdzić tego adresu URL. Przejdź do konwersacji."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najpierw zaloguj się do Zendesk QA korzystając z dostawcy logowania jednokrotnego, a następnie spróbuj ponownie."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się, korzystając z logowania jednokrotnego"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwieranie wyskakującego okna logowania."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się na konto Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czekaj..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnianie..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz jeszcze konta?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz dostępu do tej konwersacji. W razie potrzeby skontaktuj się z przełożonym."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępu"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, czy połączenie jest aktywowane w Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj połączeniami"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd połączenia"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby kontynuować, przejdź do konwersacji w centrum pomocy"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponowne łączenie jest już prawie gotowe."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daj nam chwilkę"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj odświeżyć stronę, aby wrócić do przebiegu pracy"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił jakiś problem"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwolnij się od wirtualnych granic swojego centrum pomocy i przeglądaj konwersacje w dowolnym miejscu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby korzystać z rozszerzenia, przejdź na wyższą wersję planu"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj subskrypcją"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie z centrum pomocy nie jest powiązane z tym środowiskiem pracy"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy nie rozpoznaje tego zgłoszenia lub Zendesk QA nie ma do niego dostępu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się znaleźć konwersacji"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa aktywność"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masz jakieś informacje zwrotne"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomyślne logowanie"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz korzystać z aplikacji"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń wszystkie kategorie"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwia to tworzenie zakładek do zgłoszeń i ich późniejsze filtrowanie"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak opinii dla tej konwersacji"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeczytaj opinie"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz w Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj będą wyświetlane wszystkie informacje zwrotne i powiadomienia. Na razie nic…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz więcej w Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzja"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły konwersacji"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zawiera wszystko z"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest wszystkim z"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie zawiera wszystkiego z"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest wszystkim z"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lepsza niż agenta"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gorsza niż agenta"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fałsz"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to więcej niż"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zawiera dowolne z"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest dowolnym z"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie zawiera żadnego z"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest dowolnym z"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prawda"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naruszone"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dodał(a) komentarz"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skomentowane przeze mnie"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie dodał(a) komentarza"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie skomentowane przeze mnie"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["krytyczne do zrecenzowania"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zawiera"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatnie 30 dni"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w ciągu"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatnie 14 dni"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatnie 24 godz."])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatnie 30 dni"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ostatnie 7 dni"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeszły miesiąc"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeszły tydzień"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ten miesiąc"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ten tydzień"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dzisiaj"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wczoraj"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wykryto"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["istnieje"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oznaczone przeze mnie gwiazdką"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieoznaczone przeze mnie gwiazdką"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zawiera"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["więcej niż"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mniej niż"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negatywna"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie naruszono"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest skomplikowane"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie wykryto"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie istnieje"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie zawiera"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie otrzymano"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie wyświetlono"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozytywna"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez recenzji"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez mojej recenzji"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z recenzją"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z moją recenzją"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odebrane"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["krytyczne do zrecenzowania"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zawiera"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["więcej niż"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mniej niż"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie zawiera"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyświetlone"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mój komentarz ma odpowiedzi"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie niestandardowe"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnienie niestandardowe"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj warunek"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskaźniki"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnicy"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd i informacje zwrotne"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż wszystkie warunki"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównuje obsługę konwersacji Twojego bota z przeciętnymi agentami"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot utknął w pętli, powtarzając tę samą wiadomość w kolejnych turach"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi bota w konwersacjach"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ bota uczestniczącego w konwersacji"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot uczestniczący w konwersacji"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Treść konwersacji zawiera frazę ", _interpolate(_list(0)), " w wiadomości(ach) agentów"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie identyfikuj rozmowy, w których nie ma obowiązkowego oświadczenia, np. „Ta rozmowa zostanie nagrana” i tym podobnych."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba lub bot uczestniczący w konwersacji"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent lub bot sprawdzany w ramach konwersacji"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient szukał pomocy na wyższym poziomie"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W konwersacji wykryto wydźwięk pozytywny i negatywny"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikuje agentów najaktywniejszych w konwersacjach na podstawie analizy sztucznej inteligencji"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent grzecznie zakończył konwersację"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent rozumie i docenia odczucia klientów"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje błędy gramatyczne, ortograficzne i stylistyczne agenta"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem typowych fraz powitalnych"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent rozumie pytanie lub problem klienta"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje łatwość zrozumienia tekstu, biorąc pod uwagę złożoność wyrazów i długość zdań"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem oferowanego rozwiązania"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje ton głosu agenta w trakcie konwersacji"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaki zanikania klientów. Klient rozważał zmianę lub obiecał rezygnację."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa, do której należy konwersacja."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treść lub tekst wiadomości agenta"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi agentów w konwersacjach"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent przydzielony do przeglądania konwersacji"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas trwania konwersacji telefonicznej w sekundach"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość lub tekst konwersacji"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał komunikacji użyty w konwersacji (np. e-mail, czat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze dodane do konwersacji"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dodania komentarza do konwersacji"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W konwersacji wykryto wydźwięk pozytywny i negatywny"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data udzielenia przez klienta odpowiedzi na ankietę dotyczącą zadowolenia klientów"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy recenzja konwersacji jest kwestionowana"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał używany przez klienta do inicjowania konwersacji"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi klientów w konwersacjach"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej wiadomości w konwersacji"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rola lub typ użytkownika uczestniczącego w konwersacji"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekroczono próg ciszy między agentem a klientem"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient szukał pomocy na wyższym poziomie"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient wyraził ogromną wdzięczność lub był bardzo zadowolony z otrzymanego wsparcia"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient lub agent wyraźnie poprosił o kontynuację"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst lub rekord całej konwersacji prowadzonej przez połączenie głosowe"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy konwersacja jest oznaczona gwiazdką"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagi używane w komentarzach do kategoryzacji zawartości"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język konwersacji wykrytej przez sztuczną inteligencję"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej wiadomości w konwersacji"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba notatek prywatnych w konwersacjach"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia liczba znaków na wiadomość w konwersacjach"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi udzielanych klientom w konwersacjach"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnik, którego informacje są publicznie widoczne"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia liczba słów na wiadomość w konwersacjach"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie używane do oceniania konwersacji"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status procesu oceny konwersacji"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnik lub osoba zaangażowana w konwersację"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail powiązany z konwersacją"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy opinia została wyświetlona"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent, który ocenił konwersację"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniego sprawdzenia konwersacji"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent sprawdzany w ramach konwersacji"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszelkie konkretne komentarze lub informacje zwrotne przekazane przez klienta dotyczące jego zadowolenia z konwersacji"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziom zadowolenie klientów z konwersacji"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziom zadowolenie klientów na jedną wiadomość w konwersacji"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników używana dla recenzji konwersacji"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naruszono podane umowy SLA"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ źródła lub połączenia, z którego pochodzi konwersacja"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja była nietypowa lub niezwykła, osiągnięcie rozwiązania wymagało wymiany większej ilości informacji"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie w ankiecie związane z treścią konwersacji"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł lub nazwa ankiety powiązanej z konwersacją"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent przydzielony do przeglądania konwersacji"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka lub określony produkt/usługa powiązane z konwersacją"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał komunikacji użyty w konwersacji (np. e-mail, czat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zamknięcia lub rozwiązania konwersacji"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data początkowego utworzenia konwersacji"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data początkowego utworzenia konwersacji"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data udzielenia przez klienta odpowiedzi na ankietę dotyczącą zadowolenia klientów"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krótki opis lub podsumowanie konwersacji"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzny, referencyjny ID powiązany z konwersacją"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder lub określone grupowanie, w którym jest zorganizowana konwersacja"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz lub szablon użyty do określenia struktury konwersacji"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzny, referencyjny ID powiązany z konwersacją"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrzynka pocztowa lub konto e-mail, przez które odebrano konwersację"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu powiązany z konwersacją"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poziom priorytetu przydzielony do konwersacji"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba odpowiedzi w konwersacji"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stan lub bieżący status konwersacji"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżący status konwersacji"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat lub tytuł konwersacji"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi agentów w konwersacjach"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi przydzielone w celu kategoryzacji konwersacji"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ lub kategoria konwersacji"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej aktualizacji konwersacji"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej aktualizacji konwersacji"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środek przekazu lub kanał za pośrednictwem którego odbyła się konwersacja"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treść zapisu konwersacji"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje, które nie kwalifikują się do zrecenzowania w ramach analizy SI"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba agentów uczestniczących w konwersacjach"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy konwersacja została wyświetlona"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowolna wiadomość"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydajność komunikacji z botem"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórka bota"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi bota"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ bota"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek połączenia"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość od klienta"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezczynny"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ujawnienia nagrania"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalacja"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość zapisu rozmów"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknięcie"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaczona gwiazdką"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieodpowiednie do zrecenzowania"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor recenzji:"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja przez"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisownia i gramatyka"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość od agenta"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najaktywniejszy agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi agentów"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas trwania połączenia (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość konwersacji"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał wiadomości"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag komentarza"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data skomentowania"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydźwięk"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data odpowiedzi na ankietę CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spór"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał klienta"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi klienta"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej odpowiedzi"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ użytkownika"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powitanie"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryzyko odejścia"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezczynny"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalacja"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątkowa obsługa"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuacja"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapis"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag komentarza"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypadek odstający"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumienie"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ostatniej odpowiedzi"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba notatek prywatnych w konwersacji"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia liczba znaków"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia liczba słów"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi publicznych"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnik publiczny"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data oceny"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena i komentarz"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria oceny"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status recenzji"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czytelność"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnik"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązany e-mail"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązane"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano recenzję"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrecenzowana karta wyników"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlono recenzję"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data recenzji"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba recenzowana"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do ankiety"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT na jedną wiadomość"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena zadowolenia (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferowane rozwiązanie"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ źródła"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie w ankiecie"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł ankiety"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka konwersacji"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domena marki"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał konwersacji"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zamknięcia"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia:"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data odpowiedzi na ankietę CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wysłania ankiety CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis konwersacji"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zewnętrzny"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole konwersacji"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder konwersacji"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz konwersacji"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa konwersacji"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Scout w pomocy"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zewnętrzny"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrzynka pocztowa konwersacji"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorytet konwersacji"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi w konwersacji"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stan konwersacji"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status konwersacji"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat konwersacji"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba odpowiedzi agentów"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag centrum pomocy"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag centrum pomocy"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ konwersacji"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aktualizacji"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aktualizacji"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość transkrypcji"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba agentów uczestniczących w programie"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlony status"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcja wykrywania luk"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stan luki w zabezpieczeniach"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdarzenie życiowe w zakresie luki w zabezpieczeniach"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria Automatycznej QA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz warunek związany z datą"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nazwę do filtru"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj nazwę filtru"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba zalogowana"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byli członkowie środowiska pracy"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie środowiska pracy"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dodaj ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj filtr"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Należy mieć co najmniej 1 filtr związany z datą"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcje"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz opcję"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się za ", _interpolate(_named("n")), " dzień. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>za ", _interpolate(_named("n")), " dzień</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się za ", _interpolate(_named("n")), " dni. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>za ", _interpolate(_named("n")), " dni</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się dzisiaj. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój okres próbny kończy się <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dzisiaj</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonfiguruj subskrypcję już teraz"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " dni"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pozostało ", _interpolate(_named("days")), " dni okresu próbnego"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Osiągnięto limit użytkowników w ramach Twojego planu. ", _interpolate(_named("link")), ", aby dowiedzieć się więcej lub przejść na wyższą wersję planu."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij tutaj"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["połącz swoje centrum pomocy"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwi nam to pobranie zgłoszeń do przejrzenia."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz swoje centrum pomocy"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Korzystasz z funkcji Zendesk QA z przykładowymi danymi. Aby usunąć przykładowe dane i zaimportować własne konwersacje, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykładowe dane"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj poniżej pomocne informacje"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrób zrzut ekranu całej strony, aby podać kontekst"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby rozwiązać ten problem, skontaktuj się z naszym zespołem wsparcia dla klienta:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił jakiś problem"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odwołanie do błędu:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj pomocne informacje"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy, boty i środowiska pracy"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy i środowiska pracy"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dzisiaj"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ten miesiąc"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ten tydzień"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " recenzji ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " recenzja ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " recenzji ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co nowego"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skróty klawiaturowe"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porozmawiaj z nami na czacie"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować naszego czatu (może używasz programu do blokowania reklam?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Społeczność"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone recenzje"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baza wiedzy"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz konto"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aplikacja została zaktualizowana. ", _interpolate(_named("buttonStart")), "Odśwież", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz zacząć korzystać z Zendesk QA w ramach swojego środowiska pracy:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Po prostu informujemy, że masz już zaproszenie do korzystania z Zendesk QA w ramach środowiska pracy. Jeśli chcesz, możesz oczywiście kontynuować, ale pamiętaj, że w ten sposób zostanie utworzona dla Ciebie ", _interpolate(_named("bold")), "."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oddzielna organizacja"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tak, chcę kontynuować ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nowe konto"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " prosi o dołączenie ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak połączenia z Internetem"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nowy kod PIN"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybka nawigacja"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaznacz i rozwiń edytor komentarzy"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj lub pokaż panel boczny"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do następnej konwersacji"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz oryginalną konwersację"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do poprzedniej konwersacji"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daj wszystkim kategoriom najwyższą możliwą ocenę"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daj wszystkim kategoriom najniższą możliwą ocenę"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszaj konwersacje (losowe sortowanie)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poinformuj nas"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skróty klawiaturowe"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz okno skrótów"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Osiągnięto limit ", _interpolate(_named("charLimit")), " znaków"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Typ obrazu ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") jest niedozwolony"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozmiar obrazu ", _interpolate(_named("name")), " jest zbyt duży (dozwolone 5 MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować edytora komentarzy"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeciągnij, aby przesłać"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obraz"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącze"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącze"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj szablonami"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych szablonów"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj główną przyczynę"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inna"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inna, wraz z przyczynami usunięcia"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód oceny"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba pracowników"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer telefonu"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postaramy się pomóc Ci założyć konto"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa firmy"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer kontaktowy firmy"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak usłyszałeś(-aś) po raz pierwszy o Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kanał"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz liczbę agentów"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij wszystkie wymagane pola"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musisz potwierdzić, że przeczytałeś(-aś) sekcję Privacy Policy (Zasady prywatności) i wyrażasz zgodę na jej warunki."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał Slack oparty na Support"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdarzenie"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajomy lub współpracownik"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace Centrum pomocy lub kierownik konta"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inny"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witryna przekierowująca"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukiwarka (Google, Bing itp.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media społecznościowe"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opowiedz nam o sobie i swojej firmie."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły konta"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko gotowe."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapoznaj się z aplikacją, usuwaj i dodawaj elementy."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto zostało utworzone. Co dalej?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz rozpocząć prawdziwe konwersacje?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj własne dane"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zacznij przeglądać"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypróbuj na przykładowych danych"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możemy więc pobierać Twoje zgłoszenia do sprawdzenia."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz swoje konto pomocy technicznej"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmieniam zdanie."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj rejestrację"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utworzono pinezkę. ", _interpolate(_named("buttonStart")), " do pinezek", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki coachingowe są dostępne w planie Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję planu"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie plany"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz notatki, aby przygotować się do następnej sesji 1:1 lub sesji szkoleniowej."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki szkoleniowe"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skomentuj lub dodaj innych za pomocą ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja została usunięta"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nową pinezkę"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz pinezkę"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj pinezki"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak działają pinezki"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz uprawnień do wyświetlania tej konwersacji"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij kartę"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij konwersację"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij panel"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano pinezkę"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij tę kartę"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij tę konwersację"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij ten panel"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij pod użytkownikiem"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkownika"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko Ty widzisz to, co przypinasz. Aby udostępnić innym użytkownikom, ", "@", "wspomnij o nich w komentarzach."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " ma zaproszenie do tej konwersacji"]), _normalize([_interpolate(_named("agentsNames")), " mają teraz zaproszenia do tej konwersacji"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 komentarz"]), _normalize([_interpolate(_named("n")), " komentarze(-y)"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Wspomniano ", _interpolate(_named("participants")), ", ale nie ma tej osoby w tej konwersacji."]), _normalize(["Wspomniano ", _interpolate(_named("participants")), ", ale nie ma tych osób w tej konwersacji"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uczestników"]), _normalize([_interpolate(_named("n")), " uczestnik"]), _normalize([_interpolate(_named("n")), " uczestników"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Czy chcesz usunąć ", _interpolate(_named("agentName")), " przed uzyskaniem dostępu do tej pinezki?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nic nie rób"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zapraszaj do tej konwersacji"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj komentarz"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś do tej konwersacji"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń użytkownika"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wątek"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej operacji nie można cofnąć."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to również usunięcie wszystkich komentarzy z tą pinezką."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nowych wiadomości"]), _normalize([_interpolate(_named("n")), " nowa wiadomość"]), _normalize([_interpolate(_named("n")), " nowe(-ych) wiadomości"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko Ty widzisz swoje pinezki. Aby udostępnić pinezki, ", "@", " kogoś w komentarzach."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " przypina ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["panel"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konwersacja"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notatka"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ty"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję planu, aby uzyskać pinezki coachingowe"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki coachingowe są dostępne w planie Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki coachingowe są dostępne w planie Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję, aby uzyskać dostęp do funkcji"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przygotuj się na rozwiązywanie problemów 1:1 lub zachowaj coś na inny czas."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to nieodwracalne usunięcie pinezki i wszystkich dodanych komentarzy."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń pinezkę"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń pinezkę"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa folderu"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij rzeczy, aby wrócić później"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od najnowszych"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od najstarszych"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz interesujący trend lub konwersację, które przygotowujesz do sesji 1:1 lub zanotuj to, gdyby była Ci potrzebna inna wiedza."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz pinezkę, klikając powyższy przycisk albo ikonę pinezki w widoku konwersacji lub panelu."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypnij"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono pinezek"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Brak wyników dla „", _interpolate(_list(0)), "”"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj użyć innych słów kluczowych lub sprawdź, czy nie ma literówek."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nową pinezkę"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomości"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoby"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według użytkownika, słowa kluczowego..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetycznie"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio utworzone"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio edytowane"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najwięcej pinezek"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mam zrecenzować ", _interpolate(_named("n")), " konwersacji ", _interpolate(_named("schedule"))]), _normalize(["Mam zrecenzować ", _interpolate(_named("n")), " konwersacji ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel został zaktualizowany"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw cel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz ustawić osobisty cel obejmujący liczbę konwersacji do sprawdzenia."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codziennie"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["co miesiąc"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["co tydzień"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel recenzji"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSOBISTE"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie kanałów"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kanał"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne z ankiety udostępniaj tylko z komentarzami"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiększ możliwości swoich współpracowników, podkreślając ich wyjątkową jakość usług i doskonałe opinie klientów."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij na Slack pozytywne opinie o ankietach"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych kanałów"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie udostępniaj informacje zwrotne z powyższymi ocenami"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracje z Chwalibotem"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nie masz jeszcze żadnych recenzji, na początku tygodnia otrzymasz przypomnienie."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypomnienia"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotygodniowe przypomnienie o konieczności napisania recenzji"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuj powiadomienia o nowych recenzjach, odpowiedziach, wzmiankach i sporach w Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wzmianki"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi na wątki"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja z usługą Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadamiaj o nowych recenzjach, odpowiedziach, wzmiankach i sporach."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory dotyczące mnie"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wzmianki w komentarzach"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe otrzymane recenzje"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi w wątkach"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raport ze statystyką"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuj raporty na temat wystawianych i otrzymywanych recenzji."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporty"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codziennie"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["co miesiąc"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tygodniowo"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codziennie"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co 3 godziny"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co 6 godzin"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od razu"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wył."])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tygodniowo"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator konta zablokował ustawienia powiadomień."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz okres"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia osobiste"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktywny)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nowe logowanie przez media społecznościowe do tego konta:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie aktywnej metody logowania spowoduje wylogowanie. Możesz się zalogować ponownie przy użyciu alternatywnego adresu e-mail."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń metodę logowania"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano Google jako opcję logowania"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje logowania"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano Slack jako opcję logowania"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hasło"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj hasło"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zostanie wysłany e-mail do ", _interpolate(_named("email")), " w celu zresetowania hasła."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["główny adres e-mail"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź skrzynkę odbiorczą, aby uzyskać e-mail z potwierdzeniem"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczny link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby to zmienić, zmień najpierw swój ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adres e-mail ", _interpolate(_named("email")), " zostanie powiązany z tym hasłem."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil został zaktualizowany"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcie profilowe"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby uzyskać najlepsze wyniki, prześlij kwadratowe zdjęcie (256 x 256 pikseli lub więcej)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główny adres e-mail"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek tygodnia"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia osobiste"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz co najmniej jedną opcję"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość szablonu"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż szablon w"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zawartość szablonu"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nazwę szablonu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz szablon"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz szablon"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twórz szablony komentarzy, aby przyspieszyć recenzowanie, przypinanie elementów i coaching."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używany w"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń szablon"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć nieodwracalnie ten szablon?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szablon"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twórz szablony komentarzy, aby przyspieszyć recenzowanie, przypinanie elementów i coaching."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono żadnych szablonów"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono szablon komentarza"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto szablon komentarza"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano szablon komentarza"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje i spory"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby uzyskać dostęp, skontaktuj się z: ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wsparcie dla klienta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie znaleziono konta"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Na razie użyj ", _interpolate(_named("link")), ", aby uzyskać dostęp do Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie zostało zmigrowane"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA bota"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["woj"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wskaźniki"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oceny"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statystyka"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibracja"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konwersacje"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtry"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzja"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje komentarze"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje spory"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje recenzje"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakcje"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęte"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinie o ankiecie"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobiste"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utwórz hasło"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poświadczenia"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adres e-mail"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logowanie"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resetuj"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cel weryfikacji"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szablony"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["użytkownik"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz szablon"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konto"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozliczenia"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["firma"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracje"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizacja"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatność"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stanowiska"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ustawienia"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subskrypcja"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnienia"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa kategoria"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znaleziono sprzeczną wiadomość"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień lub usuń jedną ze sprzecznych wiadomości"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Użyj ikony ", _interpolate(_named("placeholder")), ", aby wykluczyć poufne dane z Automatycznej QA. Na przykład, zamiast „Trzymaj się, Janie” możesz użyć „Trzymaj się, ", _interpolate(_named("placeholder")), "“, aby wstawić dowolne imię."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksymalna liczba wierszy: ", _interpolate(_named("limit"))])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj regułę do"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit 10 dostosowanych kategorii"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Osiągnięto limit ", _interpolate(_named("limit")), " dostosowanych kategorii"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj warunek"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wiersz"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń warunek"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli wiadomość agenta"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przeciwnym razie oceń"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następnie oceń"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kategorię karty wyników"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj „/”, aby dodać zmienną"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie wskazówki"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość agenta nie może być pusta"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość nie może zawierać więcej niż 300 znaków"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie źródła"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie kanały"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie tagi"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie konwersacje"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawęź obszary stosowania reguły."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określone konwersacje"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz wgląd w wyróżnione"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość agenta nie może być pusta"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit 10 dostosowanych informacji"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Osiągnięto limit ", _interpolate(_named("limit")), " dostosowanych analiz"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj nazwę analizy"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa analizy"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kategorię"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ analizy"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatywna"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutralna"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozytywna"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do ustawień środowiska pracy"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie teraz"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby zacząć korzystać z tej kategorii ocen, przejdź do ustawień karty wyników środowiska pracy i dodaj ją do wybranej karty wyników."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono kategorię ocen"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie oceniaj zamknięte konwersacje przez identyfikację określonych słów kluczowych lub fraz."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie działa z zapisami rozmów głosowych."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przybij piątkę! ✋ Osiągnięto maksymalną liczbę kategorii ocen automatycznych w ", _interpolate(_named("limit")), ". Jesteś czarodziejem kategoryzacji! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit kategorii"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopilnuj, aby wszystkie pola zostały poprawnie wypełnione"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaj nazwę kategorii ocen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa kategorii"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa kategoria ocen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa kategoria ocen"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie oceniaj zamknięte konwersacje przez identyfikację określonych słów kluczowych lub fraz. Nie działa z zapisami rozmów głosowych."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie wyświetlaj zamknięte konwersacje przez identyfikowanie określonych słów kluczowych lub fraz. Działa tylko z zapisami."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie oceniaj konwersacje przez identyfikowanie określonych słów kluczowych lub fraz zarówno w wiadomościach agenta i bota."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby użyć tej kategorii ocen, przejdź do ustawień karty wyników środowiska pracy i dodaj ją do karty wyników."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie oceniaj konwersacje na podstawie określonych słów kluczowych lub fraz w wiadomościach."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne dla"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja analizy"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te informacje są wyświetlane w Wyróżnionych na podstawie zdefiniowanych kryteriów."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja karty wyników"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej kategorii można używać na kartach wyników na podstawie zdefiniowanych kryteriów."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["czat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["już wkrótce"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dostosowane"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zdefiniowane wstępnie"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["karta wyników"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyróżnienie"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usługi głosowe"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz zmiany"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano zmiany"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wskazówka:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij ", "{··}", ", aby wykluczyć nazwiska, dane firmy lub wszelkie inne poufne informacje podczas kontroli zamknięć Automatycznego QA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zatwierdzone zamknięcie"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zatwierdzone zamknięcie"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone zamknięcie"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wykluczenia"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj wyjątek"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątek"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zatwierdzone powitanie"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zatwierdzone powitanie"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone powitanie"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wklej tutaj wyrazy, oddzielając je od siebie"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naciśnij Enter po każdym słowie, aby utworzyć nowy wpis"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedni język"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład, zamiast „Trzymaj się, Janie” możesz użyć „Trzymaj się, ", "{··}", "”, aby wstawić dowolne imię."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol zastępczy treści dynamicznej"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątki"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dodano 1 wyjątek"]), _normalize(["Dodano ", _interpolate(_named("count")), " wyjątki(-ów)"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Już dodany wyjątek"]), _normalize(["Już dodane wyjątki: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wyjątek"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono wyjątków"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano wyjątków"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątek (uwzględniana wielkość liter)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dodaj wyjątki"]), _normalize(["Dodaj 1 wyjątek"]), _normalize(["Dodaj ", _interpolate(_named("count")), " wyjątki(-ów)"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj słowo lub frazę"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano wyjątek"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["język"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie języki"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj język"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Już dodane zamknięcie"]), _normalize(["Już dodane zamknięcia: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zamknięcie"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono zatwierdzonych zamknięć"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zatwierdzonych zamknięć"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknięcie"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zamknięcie"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj zamknięcia"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano zamknięcie"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano zamknięcie"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formularz zawiera zduplikowane wpisy: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dodano już powitanie"]), _normalize(["Dodano już powitania: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj powitanie"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono zatwierdzonych powitań"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zatwierdzonych powitań"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powitanie"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj powitanie"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj powitanie"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano powitanie"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano powitanie"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wyjątek"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuń wyjątek"]), _normalize(["Usuń wyjątki"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usunąć nieodwracalnie ten wyjątek?"]), _normalize(["Usunąć nieodwracalnie te wyjątki?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj wyjątek"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Co najmniej jeden wyjątek ma ponad ", _interpolate(_list(0)), " znaków"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " znaków"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowane przez"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienna"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdefiniuj własne zatwierdzenia, które są zgodne ze standardami marki i usług. Po dodaniu funkcja Automatyczne QA będzie wykrywać tylko zatwierdzone zamknięcia. Wprowadzone zmiany będą obowiązywać w przyszłych konwersacjach."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit 100 zatwierdzonych zamknięć. Jeśli chcesz dodać więcej, być może nadszedł czas, aby ponownie odwiedzić i doprecyzować istniejące zamknięcia."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto zatwierdzony limit zamknięcia"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone zamknięcia"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrazy i wyrażenia, które mają być ignorowane przez Automatyczne QA jako błędy ortograficzne lub gramatyczne. Wprowadzone zmiany będą obowiązywać w przyszłych konwersacjach."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ignorowanych"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określ własne powitania, które są zgodne z Twoją marką i standardami usług. Po dodaniu funkcja Automatyczne QA będzie wykrywać tylko zatwierdzone powitania. Wprowadzone zmiany będą obowiązywać w przyszłych konwersacjach."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto górny limit 100 zatwierdzonych powitań. Jeśli chcesz dodać więcej, być może najwyższy czas zmodyfikować istniejące powitania i udoskonalić je."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit zatwierdzonych powitań"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone powitania"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie teraz"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kategorię"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy usunąć nieodwracalnie tę kategorię oceny?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę kategorii"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięta kategoria nie będzie widoczna w żadnych nowych konwersacjach ani filtrach. Dane historyczne paneli i konwersacji pozostaną nienaruszone."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria zostanie usunięta ze wszystkich kart wyników, do których została dodana."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się usunąć kategorii karty wyników"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto kategorię karty wyników"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kategorię karty wyników"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie Automatycznej QA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " obsługiwane przez model SI pozostaną na karcie wyników, ale nie będą już oceniane automatycznie"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłączenie integracji z programem LLM nie powoduje całkowitego wyłączenia funkcji Automatyczne QA . Oto, co się stanie:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane historyczne"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " pozostaną w panelu Automatyczne QA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ręcznie dodane główne przyczyny"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " pozostaną, ale wszystkie główne przyczyny związane z uczeniem się przez program LLM zostaną usunięte"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz funkcję Automatyczne QA opartą na LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne QA używa SI i dużych modeli językowych (LLM) do automatycznego oceniania określonych kategorii. Twoje dane są przetwarzane na podstawie ścisłej umowy o przetwarzaniu danych (DPA) i nigdy nie są używane do uczenia jakichkolwiek modeli."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o generatywnej sztucznej inteligencji"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatyczne QA używa usługi Microsoft Azure OpenAI dla ", _interpolate(_named("automatically_scoring_categories")), ". Twoje dane są przetwarzane na zasadach ściśle określonych zasad DPA wyłącznie w celu zapewnienia Ci Automatycznego QA, które ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatyczne ocenianie niektórych kategorii"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nigdy nie jest używany do uczenia jakichkolwiek modeli OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne QA oparta na LLM jest włączone"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne QA oparte na LLM jest wyłączone"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne QA oparte na LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczny scoring ze SI w funkcji Automatyczne QA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetwarzanie danych kategorii Automatycznej QA opartych na platformie MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycofana zgoda na platformę Microsoft Azure"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażono zgodę na platformę Microsoft Azure. Niedługo konwersacje będą otrzymywać automatyczne oceny."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadal możesz korzystać z Automatycznej QA, ale oto, co się stanie"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłączenie usługi MS Azure OpenAI nie spowoduje wyłączenia funkcji Automatycznej QA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można to zrobić w widoku kategorii karty wyników."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie Automatycznej QA obsługiwane przez model pozostaną na karcie wyników, ale nie będą oceniane automatycznie."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyny główne dodane ręcznie pozostaną niezmienione. Wszystkie przyczyny główne wygenerowane przez model zostaną usunięte."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane historyczne pozostają w panelu Automatycznej QA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz przetwarzanie danych przez usługę MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerszy zakres kategorii Automatycznej QA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe do użycia wsparcie w wielu językach."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybkie dodawanie nowych kategorii."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Większa dokładność ocen. GPT-3.5 wykazuje świadomość kontekstową na poziomie człowieka."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korzystaj z najnowszych osiągnięć technologii SI."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oto, co uzyskasz"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz przetwarzanie danych przez usługę MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażono zgodę"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wyrażono zgody"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy nowi użytkownicy są przydzielani do tego środowiska pracy"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domyślne środowisko pracy"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Czas 12-godzinny (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Czas 24-godzinny (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format godziny"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pn"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil firmy został zaktualizowany"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek tygodnia"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja niestandardowa"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klucza dostępu i klucz tajny są zwykle dostępne w sekcji Zarządzanie dostępem > Użytkownicy"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klucza dostępu AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj zmianę klucza tajnego dostępu"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień wartość klucza tajnego dostępu"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie umożliwia zdefiniowanie tylko jednego regionu, który jest zwykle dostępny w sekcji Lokalizacja"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj klucz tajny"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny dostępu AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie istotne informacje można znaleźć w Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zazwyczaj można znaleźć w sekcji ustawień instancji Amazon Connect i na stronie Przechowywanie danych"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja przechowywania nagrań rozmów"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas dodawania Amazon Connect wystąpił błąd. Sprawdź, czy wszystkie pola zostały poprawnie wypełnione, i spróbuj ponownie."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja miejsca na dane powinna mieć format zasobnika/foldera oddzielonego ukośnikiem."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby dodać połączenie, skontaktuj się z nami poprzez ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie dla klienta"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zgłoszenia w Zendesk będą synchronizowane automatycznie. Możesz wykluczyć wybrane zgłoszenia."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azja i Pacyfik (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azja i Pacyfik (Seul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azja i Pacyfik (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azja i Pacyfik (Mumbaj)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azja i Pacyfik (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (Środkowa)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zurych)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irlandia)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londyn)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region serwera"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bliski Wschód (ZEA)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz region serwera"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ameryka Południowa (Sao Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA Wschód (Wirginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA Wschód 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA Zachód (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie już zostało dodane"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocznij połączenie"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj tylko te konwersacje, które"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanał pierwszy, kanał drugi"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unikatowy ID tego połączenia to ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 miesiąc"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 rok"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 miesiące"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 miesięcy"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po wybranym okresie przechowywania nieaktywne konwersacje zostaną usunięte. Przesłane recenzje pozostają widoczne w widokach aktywności i panelu."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres przechowywania"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz okres przechowywania"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Należy pamiętać, że tej czynności nie można cofnąć."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem, że spowoduje to usunięcie z Zendesk QA wszystkich danych związanych z centrum pomocy dla tego połączenia, w tym konwersacji, pól i tagów."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesłane recenzje pozostaną bez zmian i pozostaną widoczne w określonych widokach."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupa pierwsza, grupa druga"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktywnym opracowywaniu"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta integracja jest wciąż w ", _interpolate(_named("activeDevelopment")), ". Być może brakuje niektórych informacji (np. załączników lub dostosowanych pól)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skrzynka 1, skrzynka 2"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag 1, tag 2"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token interfejsu API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny tokenu interfejsu API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź „Nazwy pól zgłoszenia” we Freshdesk w sekcji Ustawienia → Pola zgłoszenia"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź „Custom Field Names” w aplikacji Help Scout w sekcji Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź „Nazwy konwersacji Klass” na platformie Kustomer w sekcji Ustawienia → Klasses → Konwersacja"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź „Nazwa dostosowanego pola” w aktywnym agencie w sekcji Ustawienia → Dostosowane pola"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź „Nazwę dostosowanego pola” w aplikacji Wix w sekcji Ustawienia → Dostosowane pola"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID pól należy rozdzielać przecinkami. Te ID można znaleźć w Zendesk w sekcji Administracja → Pola zgłoszeń."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nawiąż kontakt z kilkoma użytkownikami centrum pomocy, aby przejrzeć swoje pierwsze konwersacje. Bez obaw, nie powiadomimy ich, chyba że zaprosisz ich do członkostwa w środowisku pracy."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zintegrowano pomyślnie"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj członków"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaczniemy przeciągać Twoje konwersacje."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domena: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identyfikator centrum pomocy: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się uwierzytelnić połączenia"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko gotowe. To jest Twoje nowe połączenie."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niektóre połączenia nie zostały zaktualizowane lub stały się nieaktywne. Spróbuj zaktualizować te połączenia lub usunąć je z listy."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klienta interfejsu API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny klienta interfejsu API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utwórz nowy token interfejsu API w sekcji Ustawienia → Konto → API i zestawy SDK. Przekierowujący adres URL, który ma zostać użyty, to ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID konta"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby rozpocząć, wybierz centrum pomocy"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli Twoje konto Intercom jest skonfigurowane do obsługi z UE lub Australii, zaktualizuj je."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Adres e-mail używany do logowania się w ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamaskuj imię i nazwisko, adres e-mail i numer telefonu użytkownika. Poświadczenia bankowe są już zamaskowane dla wszystkich połączeń z centrum pomocy."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma jeszcze integracji. Dodaj je teraz."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrzebujesz tych ról:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz połączenie"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nowe połączenie"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie zaznaczenia tej opcji umożliwi ręczne dodanie tego połączenia tylko do określonych środowisk pracy"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz się ze wszystkimi istniejącymi środowiskami pracy"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie środowiska pracy"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz interfejsu API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny interfejsu API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usunięto połączenie ", _interpolate(_list(0))])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa połączenia"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz pobrać token interfejsu API poniżej."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próg CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń połączenie ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz „usuń połączenie”, aby je usunąć"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń połączenie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj połączonymi źródłami danych i monitoruj je."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj połączenie"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozdziel nazwy pól przecinkami"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepowodzenie aktualizacji"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaj integracji unikatową nazwę"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj opcje zaawansowane"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj poufne pola danych"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy nie przechowuj adresów URL załączników"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignoruj załączniki"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy nie przechowuj zawartości konwersacji (możesz ją później pobrać na żądanie ze swojego centrum pomocy)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignoruj zawartość konwersacji"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy nie przechowuj tematu konwersacji (możesz go później pobrać na żądanie ze swojego centrum pomocy)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignoruj linię tematu"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywna"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Twój poddomena ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region usługi Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopasuj ID zespołu w skrzynce odbiorczej"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeryczny ID zespołu usługi Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W toku..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrzynki pocztowe"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ręcznie zaktualizuj połączenie"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamaskuj dane klienta"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak środowisk pracy"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa poufnego pola"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID pola poufnego"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odnów połączenie"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odnów połączenie"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz ze środowiskiem testowym Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nawiązywanie połączenia z ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż opcje zaawansowane"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobieraj tylko konwersacje z wynikiem niższym lub równym ustawionemu progowi"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj próg CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj tylko te konwersacje, które..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz najnowsze konwersacje"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj połączenie"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie zostało zaktualizowane"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa użytkownika"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje centrum pomocy"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby anulować subskrypcję i usunąć konto, napisz na adres ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie dla klienta"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konto"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie zgłoszenia z Zendesk Support (lub innego centrum pomocy) nie ma wpływu na powiązane recenzje konwersacji. Nadal możesz je wyświetlać i nimi zarządzać."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usunięcie zgłoszenia w Zendesk QA powoduje również usunięcie powiązanych recenzji konwersacji.", _interpolate(_named("line_break")), "Usunięcie zgłoszenia w Zendesk Support (lub innym centrum pomocy) nie wpływa na powiązane recenzje konwersacji. Nadal możesz je wyświetlać i nimi zarządzać."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj opiniami"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie recenzji jest nieodwracalne. Wpływa również na dane w panelach."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie recenzje"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie recenzje zostały usunięte"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli z usuniętymi zgłoszeniami są powiązane jakieś recenzje, zobaczysz je tutaj."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma jeszcze recenzji"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj ID zgłoszenia"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID zgłoszenia: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięte konwersacje"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie można usunąć domyślnych środowisk pracy. Aby usunąć to środowisko pracy, wybierz inne domyślne środowisko pracy w ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia konta"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej czynności nie można cofnąć."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usunięcie wszystkich danych"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozumiem, że spowoduje to ", _interpolate(_named("removeAllData")), " powiązanych z tym środowiskiem pracy, w tym użytkowników i połączeń środowiska pracy."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń środowisko pracy"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz „usuń środowisko pracy”, aby usunąć to środowisko pracy"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń środowisko pracy ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuń środowisko pracy"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upewnij się, że to co robisz jest właściwe lub wcześniej skonsultuj się ze specjalistą."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz, czy domyślna osoba recenzowana ma być wybierana na podstawie najczęstszego agenta, czy na podstawie bieżącego opiekuna."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najaktywniejszy agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domyślna osoba recenzowana"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól użytkownikom na przeglądanie własnych konwersacji, a agentów na sporządzanie recenzji"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowisko pracy zostało usunięte"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowisko pracy zostało zaktualizowane"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapewnij neutralne ocenianie, ukrywając wcześniej wystawione recenzje od innych recenzentów"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezstronne ocenianie"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolor"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej czynności nie można cofnąć."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to usunięcie wszystkich danych związanych z tym środowiskiem pracy, w tym użytkowników i połączeń środowiska pracy."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń środowisko pracy"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwanie środowiska pracy..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczny link"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczny link"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można dezaktywować bieżącej metody logowania"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody uwierzytelniania zostały zaktualizowane"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na określone typy metod uwierzytelniania w przypadku wszystkich użytkowników."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOWANIE JEDNOKROTNE / SAML"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnienia"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy i środowiska pracy"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twórz środowiska pracy i zarządzaj nimi, a także zapraszaj użytkowników lub łącz je."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostań na stronie"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuść stronę"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuścić tę stronę i utracić wszystkie zmiany?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnienia"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że nie ma żadnych konwersacji do zaimportowania. Zacznij od konwersacji z centrum pomocy, a następnie wróć tutaj, aby je przeanalizować."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych konwersacji"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można usunąć tej kategorii systemu"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kategorię"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie kategorii jest nieodwracalne. Spowoduje to usunięcie wszystkich danych dotyczących kategorii z raportów."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby przywrócić tę kategorię, musisz ją ponownie utworzyć."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kategorię"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj kategorię"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne kategorie automatycznie wypełniają kartę wyników sugerowanymi ocenami, aby zaoszczędzić czas na sprawdzanie. Kategorie wprowadzone ręcznie są wypełniane przez osoby recenzujące od podstaw."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ręcznie"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknięcie"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisownia i gramatyka"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powitanie"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumienie"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czytelność"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferowane rozwiązanie"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono kategorii"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono głównych przyczyn"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych kart wyników"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj innego wyszukiwania lub zmień filtry"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorii"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie(-i)"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie kategorie"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " karty wyników"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kart wyników"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kart(y) wyników"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie karty wyników"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " karty wyników"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusy"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusów"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusy(-ów)"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie statusy"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " statusy"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typy"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typów"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typy(-ów)"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie typy"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typy"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " środowiska pracy"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " środowisk pracy"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono środowisk pracy"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " środowisk(a) pracy"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie środowiska pracy"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " środowiska pracy"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Waga ", _interpolate(_named("weight")), " składa się z ", _interpolate(_named("percentage")), "% ogólnej oceny"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kartę wyników"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie kart wyników jest nieodwracalne. Spowoduje to usunięcie wszystkich danych karty wyników z raportów."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby przywrócić tę kartę wyników, musisz ją ponownie utworzyć."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kartę wyników"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja robocza"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyny główne"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skala ocen została zaktualizowana. ", _interpolate(_named("buttonStart")), "Rozpocznij sprawdzanie", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA domyślnie ustawia skalę binarną, ale można też wybrać skalę bardziej szczegółową:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli chcesz wyświetlić różne opcje skali ocen skonfigurowane według kategorii, przejdź do sekcji ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia/Ton"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedza o produktach"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po zaktualizowaniu skal ocen:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednia skala ocen i odpowiednie kategorie zostaną zarchiwizowane (nie zostaną usunięte)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpłynie to na niektóre dane panelu, ponieważ stara i nowa skala mogą być nieporównywalne."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklonuj moje istniejące konfiguracje kategorii ocen do nowej skali"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno chcesz zaktualizować skale ocen?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz zmienić format wyświetlania karty wyników (liczby lub emoji) oraz ustawić kategorie jako obowiązkowe lub opcjonalne."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj emoji zamiast cyfr"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na pomijanie kategorii"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiązanie"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ skali ocen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sklonowano kategorie dla 1 środowiska pracy"]), _normalize(["Sklonowano kategorie dla ", _interpolate(_named("workspacesAmount")), " środowisk pracy"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia środowiska pracy"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie nawiązuj połączenia w przypadku, gdy agent nie powiadamia klienta o spodziewanej ciszy w trakcie konwersacji."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W konwersacji są chwile bezczynności trwające dłużej niż ustawiony próg."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdefiniuj maksymalny próg bezczynności dla użytkownika w połączeniach"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezczynny"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięte informacje nie będą widoczne w żadnych nowych konwersacjach ani filtrach. Dane historyczne paneli i konwersacji pozostaną nienaruszone."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się usunąć analizy Wyróżnione"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto informacje o Wyróżnionych"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń informacje o Wyróżnionych"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlaj konwersacje zawierające określone wyrazy lub wyrażenia w zapisach i wiadomościach."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie identyfikuj rozmowy, w których nie ma obowiązkowego oświadczenia, np. „Ta rozmowa zostanie nagrana” i tym podobnych."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma na celu identyfikowanie w trakcie rozmowy oświadczenia o ujawnieniu nagrania przy użyciu dużego modelu językowego (LLM), który dynamicznie rozpoznaje kontekst, eliminując potrzebę stosowania gotowych fraz."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ujawnienia nagrania"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie nawiązuj połączenia w przypadku, gdy agent nie powiadamia klienta o spodziewanej ciszy w trakcie konwersacji."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezczynny"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określ momenty bezczynności w trakcie połączenia"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcesz wprowadzić zmiany w swojej subskrypcji? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") – chętnie pomożemy."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie analizuj 100% konwersacji między agentami, obiektami BPO, kanałami i językami. Sztuczna inteligencja wykrywa problemy, luki w wiedzy i możliwości coachingu, aby doskonalić usługi."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% zasięg automatycznej QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching oraz informacje zwrotne agentów"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione informacje o sztucznej inteligencji"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane raportowanie i panele"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrowanie i analiza wydźwięku"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tłumaczenia na żądanie"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj przydziały i ustalanie celów"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching i testy"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapewnienie jakości (QA) Zendesk"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko w planie Professional oraz"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (nieaktualny)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOWANIE JEDNOKROTNE SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooki do natychmiastowego powiadamiania o awariach krytycznych"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja z magazynem danych"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja z chmurą usług Salesforce"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opracowanie dostosowanej integracji"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedykowany kierownik ds. doradztwa wdrożeniowego dla klienta"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpośredni dostęp do naszych produktów i zespołów inżynierskich przez Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko to, co oferuje Growth Suite oraz"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA dla 100% pokrycia"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oparty na sztucznej inteligencji wgląd w konwersacje, który pomaga nadać danym sens"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnianie i filtrowanie danych centrum pomocy wspierane sztuczną inteligencją"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione: Automatyczne wykrywanie interakcji obowiązkowych do przeglądu"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza i filtrowanie wydźwięku"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporty wydajności i wgląd wykorzystujący sztuczną inteligencję"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tłumaczenie konwersacji na żądanie"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj monitorowanie czasu"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analityka częstotliwości zaliczeń"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko to, co oferuje SI Suite oraz"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakiet wdrożeniowy Enterprise obejmujący integrację Salesforce i Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opracowanie dostosowanej integracji"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOWANIE JEDNOKROTNE SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja z magazynem danych"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooki do natychmiastowego powiadamiania o awariach krytycznych"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedykowany kierownik ds. doradztwa wdrożeniowego dla klienta"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpośredni dostęp do naszych produktów i zespołów inżynierskich"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczesny dostęp i wpływ na nasz plan"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne sprawdzanie przydziałów i ustawianie celów"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukierunkowany coaching i analityka"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy dotyczące zarządzania procesem uczenia"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie rolami użytkowników i uprawnieniami"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskowanie poufnych danych"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane opcje filtrowania i wyszukiwanie konwersacji"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowywane karty wyników z wieloma ocenami, regulowanymi wagami i opcjami automatycznego wykrywania błędów"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozszerzenie przeglądarki do przeglądania konwersacji w dowolnym miejscu"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza głównej przyczyny"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporty wydajności na potrzeby porównywania agentów, zespołów i usług BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta CSAT i analizy opinii klientów"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko w dodatku Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (nieaktualny)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiele skal ocen na konwersację"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja z usługą Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktywny wgląd w konwersacje oparte na sztucznej inteligencji"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj zadania"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinezki coachingowe"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje coachingowe"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza i filtrowanie wydźwięku"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrowanie na podstawie złożoności konwersacji"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgląd w konwersacje"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunkowe karty wyników"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza głównej przyczyny"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane funkcje analiz CSAT wykorzystujące sztuczną inteligencję"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja oceniania"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozszerzenie przeglądarki umożliwiające przeglądanie konwersacji w dowolnym miejscu"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnij, aby odkrywać konwersacje, które trzeba przejrzeć"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tłumaczenie konwersacji na żądanie"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj monitorowanie czasu"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowany plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (nieaktualny)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieograniczone recenzje konwersacji"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport w formacie CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny i oceny wzajemne"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników z regulowanymi wagami i opcjami automatycznych błędów"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozbudowane opcje filtrowania"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cele dotyczące przeglądów dziennych, tygodniowych lub miesięcznych"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety CSAT i podstawowa analityka"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panele"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporty do porównywania agentów, zespołów, usług BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie rolami i uprawnieniami użytkowników"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcje"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcę wprowadzić zmianę w mojej subskrypcji Zendesk QA.\n\nWyróżnij lub wskaż jedną opcję i wskaż liczbę stanowisk, które chcesz zmienić:\nChcę [dodać X stanowisk] [usunąć X stanowisk] [przejść na wyższą wersję planu Zendesk QA]\n\nPodaj nazwę swojej firmy oraz imię i nazwisko powiązane z Twoim kontem Zendesk QA, a także wszelkie inne dane, które powinniśmy znać, aby wprowadzić tę zmianę."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana subskrypcji Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcę wykupić subskrypcję Zendesk QA.\n\nPodaj nazwę swojej firmy oraz imię i nazwisko powiązane z Twoim kontem Zendesk QA, a także wszelkie inne dane, które powinniśmy znać, aby przetworzyć to zlecenie:\n\nPrzed zakupem podaj również wszelkie konkretne pytania:\n\nDziękuję!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakup subskrypcji Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twoja subskrypcja kończy się w dniu ", _interpolate(_named("date")), " (Pozostało: ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twój okres próbny kończy się ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " do końca)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 dzień"]), _normalize([_interpolate(_named("n")), " dni"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Płatność dla ", _interpolate(_named("card")), " (wygasa ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), ". dnia każdego miesiąca."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obciążono kartę ", _interpolate(_named("card")), " (ważną do ", _interpolate(_named("expires")), ") w dniu ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kończy się za"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Wygasła"]), _normalize(["Wygasa za 1 dzień"]), _normalize(["Wygasa za ", _interpolate(_named("expiresIn")), " dni"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cena wywoławcza obejmuje ", _interpolate(_named("n")), " licencji(e) użytkowników"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zaczyna się od ", _interpolate(_named("sum")), "/miesiąc"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miesiąc"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za użytkownika miesięcznie"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rocznie"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miesięcznie"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kwartał"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuń zaplanowane anulowanie"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli chcesz w dalszym ciągu korzystać z Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twoja subskrypcja została anulowana ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Twoja subskrypcja wygaśnie ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "-dniowy okres próbny bez dodatkowych kosztów!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["użyto"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Masz ", _interpolate(_named("n")), " użytkowników. Liczba stanowisk nie może być niższa od tej."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subskrypcja zostanie wznowiona ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rok"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bieżący plan Zendesk QA (", _interpolate(_named("plan")), ") nie zawiera funkcji wykorzystujących sztuczną inteligencję. Aby w pełni wykorzystać z możliwości analiz głosowych, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby anulować subskrypcję, skontaktuj się z nami ", _interpolate(_named("link")), " lub porozmawiaj z nami na czacie."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan zarządzania jakością"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie jakością"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja została zaktualizowana"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kartę kredytową"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie plany"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozliczenia"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz informacje rozliczeniowe"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przedział rozliczeniowy"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj subskrypcję"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja zostanie anulowana z końcem okresu rozliczeniowego."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt z nami"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontaktuj się z nami, aby edytować"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżący plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny niestandardowe"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kup niższy plan"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co rok (zaoszczędź 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocznie"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płać co rok"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj subskrypcję"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj plany"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpłatny okres próbny"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zafakturowano ręcznie"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W następnym kroku możesz przejrzeć i potwierdzić ostateczne podsumowanie zamówienia."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najpopularniejsze"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie masz aktywnej metody płatności."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W okresie próbnym"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wznów bieżącą subskrypcję, aby zmienić plan"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymaj subskrypcję"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwartalnie"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocznie"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj ponownie subskrypcję"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wznów subskrypcję"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanowiska"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocznij okres próbny"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywna"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowana"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez odnowienia"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymana"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status subskrypcji"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres próbny"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję subskrypcji"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowany"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poświęć chwilę i podziel się opinią na temat wsparcia dla klienta. Z góry dziękuję – ", "{", "{", "agentName", "}", "}", " ceni sobie Twoją opinię!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cześć ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ankieta zadowolenia klienta użytkownika ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak oceniasz wsparcie uzyskane od ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Naprawdę to doceniamy. Na podstawie Twojej opinii, ulepszymy ", _interpolate(_list(0)), " dla wszystkich."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o ankietach"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz typ ankiety"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Można to później zmienić."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mierz i zwiększaj zadowolenie klientów dzięki automatycznym ankietom zadowolenia klientów w wielu językach."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono ankiet"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz ankietę"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa ankiety"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono ankietę"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spróbuj zmienić słowo kluczowe lub ", _interpolate(_named("contact_us")), ", jeśli chcesz widzieć ten element na liście"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daj nam znać"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono języka"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wybierz co najmniej jeden język z poniższej listy. ", _interpolate(_named("contact_us")), ", jeśli nie widzisz tutaj języka, którego chcesz używać."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj języki"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daj nam znać"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj język"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ostatnio opublikowano ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ostatnio zapisano ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pytanie ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie wprowadzające"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etykieta skali z lewej"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat e-maila"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powody"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etykieta z prawej strony"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli brakuje tłumaczenia ankiety, w zamian zostanie wyświetlona treść w języku oryginału"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj tłumaczenia"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak tłumaczeń"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sprawdź wszystkie tłumaczenia przed ich zastosowaniem. Wprowadź zmiany w pobranym pliku, zapisz go i w razie potrzeby prześlij ponownie. Jeśli token ", _interpolate(_named("token_example")), " nie jest wyświetlany jako tag, oznacza to, że ma nieprawidłowy format lub jest w polu, które nie obsługuje tokenów."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj tłumaczenia"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetłumaczone"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowuje konwersację i pokazuje propozycje komentarzy."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc sztucznej inteligencji Zendesk QA (w języku angielskim)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy można edytować po ich utworzeniu"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten typ już istnieje. Wybierz inną nazwę."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz typ niestandardowy jako opcję do wybrania na liście rozwijanej Typ ankiety i pogrupuj wszystkie utworzone ankiety na panelu."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz wyrazy i naciśnij Enter, aby je zapisać"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj typy niestandardowe"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa typu nie może być pusta"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdefiniuj typ"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zapisano typu niestandardowego. Naciśnij klawisz Enter, aby go zapisać."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail nadawcy"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko nadawcy"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["W jakim stopniu zgadzasz się ze stwierdzeniem: „", _interpolate(_named("agentName")), " ułatwił(a) rozwiązać mój problem”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak łatwo było uzyskać pomoc od ", _interpolate(_named("brandName")), " do tej pory?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak łatwe było dzisiejsze rozwiązanie Twojego problemu?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak łatwa była komunikacja z naszym zespołem?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL (opcjonalny) Privacy Policy (Zasady prywatności)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL (opcjonalny) Warunków świadczenia usługi"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak oceniasz wsparcie uzyskane od ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak oceniasz naszą obsługę tego zlecenia?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W jakim stopniu jesteś zadowolony(-a) z kontaktu z przedstawicielem wsparcia?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak oceniasz usługę ", _interpolate(_named("agentName")), " otrzymaną dzisiaj?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie niestandardowe"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zdefiniuj własne pytanie niestandardowe ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz pytanie niestandardowe"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj adresy URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw wiadomość e-mail"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja nazwy typu pytania niestandardowego spowoduje jego aktualizację we wszystkich ankietach, w których jest on używany, bez wpływu na zbierane dane."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj typy niestandardowe"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat e-maila"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie wprowadzające"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pytanie ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowy typ niestandardowy"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawartość"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token personalizacji"]), _normalize(["Tokeny personalizacji"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Przeciągnij token personalizacji i upuść go w poniższym polu."]), _normalize(["Przeciągnij i upuść tokeny personalizacji na poniższe pola."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konwersacja ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie ankiety zadowolenia klientów są wysyłane pocztą e-mail. Pracujemy nad udostępnieniem Ci nowych możliwości, takich jak ankiety CSAT dla centrum pomocy, ankiety SMS i nie tylko."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synchronizuj lub odnów połączenie"]), _normalize(["Synchronizuj lub odnów połączenia"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj lub odnów połączenie ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Połączenie nie jest prawidłowo zsynchronizowane"]), _normalize(["Połączenia nie są prawidłowo synchronizowane."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuń zaznaczenie odpowiedniego pola wyboru ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnij teraz ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj co najmniej jedną regułę wysyłania ankiety, aby aktywować połączenie"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To połączenie jest obecnie wyłączone. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To połączenie maskuje poufne dane klienta. Aby włączyć wysyłanie ankiety,\n", _interpolate(_named("link")), " w ustawieniach."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To połączenie wymaga dodatkowych uprawnień do wysyłania ankiet.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnij teraz"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA wymaga większych uprawnień dla Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " i ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 godz."]), _normalize([_interpolate(_named("n")), " godz."])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " min"]), _normalize(["1 minuta"]), _normalize([_interpolate(_named("n")), " min"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["godzina"]), _normalize(["godz."])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), ", aby skonfigurować reguły wysyłania ankiet."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" dzień"]), _normalize(["dni"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącza do ankiet wygasają"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącza wygasają za"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zablokuj możliwość odpowiadania na ankiety, jeśli minęło zbyt wiele czasu"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ankieta ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozdziel tagi przecinkami lub po wpisaniu naciśnij klawisz Enter. Dopilnuj, aby każdy z nich został wymieniony dokładnie tak, jak w polu Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw warunki, na podstawie których ma zostać wysyłana ankieta."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguły wysyłania ankiet"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguły wysłania kontynuacji ankiety"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ankieta ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " już istnieje.\nRozwiąż konflikt, modyfikując reguły tutaj lub w ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Połączenie z regułami dopasowywania dla ankiety ", _interpolate(_named("name")), ". Rozwiąż konflikt, modyfikując reguły tutaj lub w ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolejną regułę"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj markę"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz warunek"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj wartość"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pasująca do tych samych reguł"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wartość"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail został wysłany w"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wKontynuacja e-maila została wysłana z"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstawiony fragment kodu i kontynuacja e-maila"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiuj kod"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety dotyczące Zendesk QA możesz załączać do e-maili, dodając fragment kodu do konfiguracji Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukcja instalacji"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwórz Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd fragmentu kodu"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano fragment kodu do schowka"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiowanie fragmentu kodu nie powiodło się"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstawiony fragment kodu"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta ankieta ma niezapisane zmiany. Zapisz ją ponownie, aby mieć pewność, że ten fragment kodu odzwierciedla najnowsze aktualizacje."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj połączenie"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano jeszcze żadnych połączeń"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś e-maile, czat na żywo, rozmowy telefoniczne i inne funkcje do Zendesk QA wraz z Twoimi kontaktami"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o dostarczaniu ankiet"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguły wysyłania ankiet"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguły wysłania kontynuacji ankiety"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj regułę marki"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj regułę"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwierzytelnianie"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + kontynuacja e-maila"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz konto Intercom z ankietą zadowolenia klientów Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostarcz przez"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto i kiedy otrzyma ankietę"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj reguły"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włączono"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety w aplikacji umożliwiają klientom wypełnianie ankiet bez wychodzenia z czatu."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruchom Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguły dostarczania według połączenia"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ankieta ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " już istnieje.\nRozwiąż konflikt przez wyłączenie połączenia lub zmodyfikowanie reguł tutaj albo w ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw warunki, na podstawie których ankieta ma być wysyłana"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utworzone przez ", _interpolate(_named("name")), " w dniu ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie tego języka oznacza, że nie będzie on już widoczny dla respondentów, a wszystkie przetłumaczone teksty zostaną usunięte."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń język"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej czynności nie można cofnąć."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język możesz też wyłączyć, ukrywając go w ankiecie. Umożliwi to zachowanie wszystkich tłumaczeń bez pokazywania ich respondentom."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostatnia edycja: ", _interpolate(_named("name")), " w dniu ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj języki ankiet"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przed dodaniem kolejnych języków upewnij się, że zostały wprowadzone zmiany w krokach Styl i Zawartość. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj język"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetłumaczone pola"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczne dla respondentów"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domyślny"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz plik tłumaczenia"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń język"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pobierz plik tłumaczenia"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejrzyj tłumaczenia"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tłumaczenie ukończone. ", _interpolate(_named("review_link")), " lub ", _interpolate(_named("download_link")), ", aby wprowadzić zmiany."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pobierz plik tłumaczenia"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, czego brakuje"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Część zawartości wymaga jeszcze tłumaczenia i zostanie zastąpiona językiem angielskim. ", _interpolate(_named("review_link")), " lub ", _interpolate(_named("download_link")), " w celu dodania brakujących tłumaczeń."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz ankietę"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ankieta zawiera niezapisane zmiany. ", _interpolate(_named("save")), ", aby pobrać plik tłumaczenia z najnowszymi zmianami."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij plik tłumaczenia"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz preferowaną skalę ocen"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnia klientom listę rozwijaną umożliwiającą wybranie przyczyny oceny"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst skali z lewej (opcjonalnie)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numery"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj przyczynę"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyna nie może być pusta"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta przyczyna już istnieje"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż listę rozwijaną z uzasadnieniem dla Twojego wyniku"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie przyczyny i ponowne dodanie jej z taką samą nazwą lub zmiana nazwy przyczyny zawsze powoduje utworzenie nowego wpisu. W efekcie wpływa to na dane wyświetlane w panelu."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń przyczynę"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięta przyczyna pozostaje w raportowaniu panelu, ale nie będzie widoczna dla nowych respondentów. Dotyczy to również powiązanych tłumaczeń."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń przyczynę"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usuń przyczynę „", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst w skali do prawej (opcjonalnie)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W 5-stopniowej skali 1 i 2 są uznawane za wyniki negatywne; 3 jest neutralne; 4 i 5 to wartości pozytywne"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj tekst skali"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skala ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala ocen"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA importuje Twoje konwersacje. Ten proces może potrwać do 24 godzin."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostanie ona użyta jako token personalizacji w ankiecie."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa marki"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyciski i łącza"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj wygląd ankiety"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tło loga"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwane są formaty JPG, PNG i GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styl"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpisz się ze stylem"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź wiadomość, którą zobaczą użytkownicy po przesłaniu opinii"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość z podziękowaniem"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy szesnastkowy kod koloru"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres e-mail"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole nie może pozostać puste"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewidoczne:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczne:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane ankiety"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety wysłane w ciągu ostatnich 30 dni"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta została usunięta"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta została zduplikowana"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano e-mail z podglądem"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta została opublikowana"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta została zaktualizowana"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankieta została wstrzymana"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymaj"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymaj ankietę"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres e-mail, aby wysłać podgląd ankiety"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twój", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz język podglądu"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij e-mail z podglądem"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij przykładową ankietę na swój e-mail. Oceny z podglądu ankiety nie zostaną zapisane."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wyświetl podgląd ankiety ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij podgląd ankiety e-mail w przeglądarce"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wznów"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz jako wersję roboczą"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa marki"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwę marki można dodać w kroku Styl"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię i nazwisko klienta"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Support"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przedstawiciel Support"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywna"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięta"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja robocza"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymana"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ankieta zadowolenia klienta użytkownika ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzono niezapisane zmiany"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj i wznów ankietę"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeśli ten e-mail nie wyświetla się prawidłowo ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udzielone mi wsparcie, nie podobało mi się, ponieważ..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oparte na technologii Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz przyczynę"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie konwersacji"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykład: Klient miał problem ze zaktualizowaniem metody płatności. Agent udostępnił artykuł, który może pomóc. Zadziałało."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oparte na sztucznej inteligencji Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunki świadczenia usługi"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy za opinię!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaki jest główny powód Twojej oceny?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiedz nam, dlaczego wystawiasz taką ocenę..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki nakład pracy"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski nakład pracy"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kliknij tutaj, aby otworzyć ankietę"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prywatność"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj jest wyświetlane Twoje pytanie niestandardowe"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Podany przez Ciebie ", _interpolate(_list(0)), " przynosi wynik na poziomie"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij opinię"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj subskrypcję"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bardzo niezadowolająca"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bardzo zadowalająca"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga: bieżący status ankiety i reguły jej dostarczania nie zostaną uwzględnione w duplikacie ankiety"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplikuj ankietę „", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz z wbudowanego fragmentu kodu e-maila, przed wstrzymaniem ankiety usuń go z Zendesk"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku wstrzymania ankiety nie są zbierane żadne odpowiedzi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymaj ankietę"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zanim wstrzymasz ankietę, koniecznie usuń fragment kodu z Zendesk."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń ankietę"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz z wstawionego fragmentu kodu e-mail, przed usunięciem ankiety usuń go z konta Zendesk."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta czynność spowoduje nieodwracalne usunięcie ankiety. Użytkownicy, którzy otrzymali ankietę, nadal będą mogli ją wypełnić, a wszystkie związane z nią dane pozostaną w Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń ankietę ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przed usunięciem ankiety koniecznie usuń fragment kodu z Zendesk."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosowane zmiany będą widoczne dla nowych respondentów."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj ankietę"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po opublikowaniu ankiety skopiuj najnowszą wersję wstawionego kodu."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz z wstawionego fragmentu kodu e-maila, po wznowieniu ankiety skopiuj go do Zendesk."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj wysyłanie ankiety i zbieranie odpowiedzi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj i wznów ankietę"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po wznowieniu ankiety koniecznie dodaj wstawiony fragment kodu do Zendesk."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj ankietę"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po aktualizacji ankiety skopiuj najnowszą wersję wstawionego kodu."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć tę ankietę? Tej czynności nie można cofnąć."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć ankietę?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój okres próbny się skończył, ale możesz nadal korzystać z funkcji Zendesk QA, po przejściu na pełne konto."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój okres próbny się skończył, ale możesz nadal korzystać z funkcji Zendesk QA, po przejściu na pełne konto. W celu skonfigurowania porozmawiaj z lokalnym administratorem."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres próbny się skończył"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na wyższą wersję"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój bezpłatny okres próbny się skończył."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby nadal korzystać z pełnej wersji Zendesk QA, wprowadź dane dotyczące płatności."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby w dalszym ciągu korzystać z pełnej wersji Zendesk QA, administrator konta musi wprowadzić szczegóły płatności."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj płatnością"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["użytkownik"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data utworzenia ", _interpolate(_named("date")), " autor: ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zadanie ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że wciąż nad tym pracujesz. Jeśli wyjdziesz bez zapisywania zmian, utracisz wszystkie dodane dotąd informacje."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To zadanie będzie powtarzane co drugi dzień ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To zadanie będzie powtarzane co ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Wszystkie osoby recenzowane w jednym cyklu"]), _normalize(["Wszystkie osoby recenzowane w ", _interpolate(_named("nr")), " cyklach"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konwersacji"]), _normalize([_interpolate(_named("n")), " konwersacja"]), _normalize([_interpolate(_named("n")), " konwersacji(-e)"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["z ", _interpolate(_named("nr")), " agenta"]), _normalize(["z ", _interpolate(_named("nr")), " agentów"]), _normalize(["z ", _interpolate(_named("nr")), " agentów"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["uczestnik"]), _normalize(["uczestników"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konwersacji na cykl"]), _normalize([_interpolate(_named("n")), " konwersacja na cykl"]), _normalize([_interpolate(_named("n")), " konwersacji(-e) na cykl"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " za osobę recenzowaną z rzędu"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["osoba recenzowana"]), _normalize(["osoby recenzowane"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["recenzent"]), _normalize(["recenzenci"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoby recenzowane"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy środowiska pracy"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy środowiska pracy z wyjątkiem agentów"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cykl ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "–", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niecykliczne"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zadanie ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzanie"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o zadaniach"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zadanie"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta czynność spowoduje nieodwracalne usunięcie zadania i jego danych z panelu. Wszystkie powiązane z nim recenzje pozostaną w Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń zadanie ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie usunięte"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie zduplikowane"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano jeszcze żadnych zadań"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się utworzyć zadania"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa zadania"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cykl zostanie odświeżony i powstanie nowa lista zadań. Recenzje, które już dodano, nadal są wliczane do osiągnięcia celu."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natychmiast"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przy rozpoczęciu następnego cyklu w dniu ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiedy publikować zmiany?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany wprowadzone w tym zadaniu zostaną opublikowane razem z tym zadaniem"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy ustawić zadanie jako aktywne?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyklicznie w dniu rozpoczęcia zadania"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy piątek każdego miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugi piątek każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzeci piątek każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarty piątek każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni piątek każdego miesiąca"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy poniedziałek każdego miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugi poniedziałek każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzeci poniedziałek każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarty poniedziałek każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni poniedziałek każdego miesiąca"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwsza sobota każdego miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druga sobota każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzecia sobota każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarta sobota każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia sobota miesiąca"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwsza niedziela miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druga niedziela miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzecia niedziela każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarta niedziela miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia niedziela miesiąca"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy czwartek każdego miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugi czwartek każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzeci czwartek każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarty czwartek każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni czwartek każdego miesiąca"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy wtorek miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugi wtorek każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzeci wtorek każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarty wtorek każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni wtorek każdego miesiąca"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwsza środa miesiąca"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druga środa każdego miesiąca"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzecia środa każdego miesiąca"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarta środa każdego miesiąca"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia środa miesiąca"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niestandardowa cykliczność"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzień"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. dzień każdego miesiąca"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugi"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzeci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwarty"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórzenia"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dni robocze"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak wyników"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strefa czasowa"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia cyklu"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa zadania"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na początku każdego cyklu przydziału generowana jest wstępnie określona lista konwersacji do oceny."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia zaawansowane"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie pobieraj nowe konwersacje, gdy cel oceny nie został osiągnięty"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne pobieranie nie jest możliwe przy procentowym (%) celu oceny"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musi być liczbą całkowitą, nie ułamkiem dziesiętnym"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na zastępowanie konwersacji (rola sprawdzającego lub wyższa)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastępowanie konwersacji"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól recenzentom na przydzielanie własnych konwersacji do przejrzenia"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzje"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna liczba recenzji wynosi 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określ minimalną liczbę recenzji dla każdej osoby recenzowanej"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konwersacje"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procent (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzje"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recenzje łącznie"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Każdy recenzent musi sporządzić określoną liczbę recenzji"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci muszą przejrzeć zbiorczo określoną liczbę konwersacji"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna liczba recenzji wynosi 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel recenzji musi mieścić się w zakresie od 1% do 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel recenzji"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Każda osoba recenzowana musi mieć minimalną liczbę ocenionych konwersacji"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci mogą wybierać konwersacje ze wspólnej puli"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzentom są przydzielane konwersacje ze wspólnej puli"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podejście do zadania"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie boty"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy uczestnicy środowiska pracy"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy środowiska pracy"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj osoby recenzowane"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto zostanie sprawdzony?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz osoby recenzowane"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz, kogo chcesz zrecenzować"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykluczenia są dozwolone tylko wtedy, gdy zaznaczono wszystkich użytkowników środowiska pracy lub grupę"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci i osoby recenzowane"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bieżący: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domyślne środowisko pracy"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaki typ użytkowników należy wybrać jako osoby recenzowane?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najaktywniejszy agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnik"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz osoby recenzowane (opcjonalnie)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkowników do wykluczenia"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz recenzentów (opcjonalnie)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkowników do wykluczenia"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy środowiska pracy z wyjątkiem agentów"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj recenzentów"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto będzie przeprowadzać recenzje?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz recenzentów"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tym środowisku pracy nie można dokonywać samodzielnych ocen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na samooceny"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tym środowisku pracy nie można dokonywać samooceny."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pasująca konwersacja na dzisiaj"]), _normalize(["Pasujące konwersacje na dzisiaj"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie wygląda tak, jakby się rozpoczęło dzisiaj."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartości w przyszłości mogą się różnić."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wybrano osób recenzowanych"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wybrano recenzentów"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Osoba recenzowana"]), _normalize(["Osoby recenzowane"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Recenzent"]), _normalize(["Recenzenci"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczne pobieranie: dozwolone, jeśli konwersacji jest za mało"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w piątki od ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w poniedziałki od ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w soboty od ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w niedziele od ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w czwartki od ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " we wtorki od ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " w środy od ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Codziennie od ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozpoczyna się ", _interpolate(_named("startDateTime")), " i kończy się ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warunki dotyczące dat ", _interpolate(_named("count"))])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " konwersacji i warunki centrum pomocy"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Brak konwersacji oraz ", _interpolate(_named("count")), " warunku pomocy technicznej"]), _normalize(["Brak konwersacji oraz ", _interpolate(_named("count")), " warunków centrum pomocy"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konwersacji i brak warunku centrum pomocy"]), _normalize([_interpolate(_named("count")), " konwersacji i braku warunku centrum pomocy"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Podejście: wybieranie konwersacji przez recenzentów"]), _normalize(["Podejście: przydzielanie konwersacji recenzentom"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby kontynuować, popraw błędy wyróżnione w podsumowaniu"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel: recenzenci muszą przeglądać wszystkie konwersacje"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cel: recenzenci muszą sprawdzić jedną konwersację"]), _normalize(["Cel: recenzenci muszą sprawdzić ", _interpolate(_named("count")), " konwersacje"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cel: recenzenci muszą sprawdzić ", _interpolate(_named("count")), "% konwersacji"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel: nie ustawiono celu obejmującego recenzje"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel: każda osoba recenzowana musi mieć sprawdzane wszystkie konwersacje"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cel: każda osoba recenzowana musi mieć sprawdzonych ", _interpolate(_named("count")), " z wszystkich swoich konwersacji"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cel: każda osoba recenzowana musi mieć ", _interpolate(_named("count")), "% sprawdzonych konwersacji"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cel: każdy recenzent musi sporządzić jedną recenzję"]), _normalize(["Cel: każdy recenzent musi sporządzić ", _interpolate(_named("count")), " recenzje(-i)"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Zastępowanie: recenzenci nie mogą zastępować konwersacji"]), _normalize(["Zastępowanie: recenzenci mogą zastępować konwersacje"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samooceny: recenzentom można przydzielać własne konwersacje"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ustawiono warunków dotyczących dat"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ustawiono nazwy zadania"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warunki"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " z ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co 2 tygodnie"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codziennie"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesięcznie"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tygodniowo"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długość cyklu"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw jako aktywne"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz jako wersję roboczą"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt zapisany jako wersja robocza"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie zostało ustawione jako nieaktywne"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw jako nieaktywne"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zadanie"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono zadanie"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako aktywne"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako nieaktywne"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Oczekujące aktualizacje zadania zaczną obowiązywać w następnym cyklu, począwszy od ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj zmiany"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja robocza"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywne"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujące"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj zadanie"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie zostało zaktualizowane"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent lub bot zakończył konwersację."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent grzecznie zakończył konwersację"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy konwersacja została zamknięta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknięcie"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje błędy gramatyczne, ortograficzne i stylistyczne zarówno w interakcjach agenta i bota."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje błędy gramatyczne, ortograficzne i stylistyczne agenta"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje błędy gramatyczne, ortograficzne i stylistyczne agenta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisownia i gramatyka"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje konwersację pod kątem powitania agenta i bota."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem typowych fraz powitalnych"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem typowych powitań"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powitanie"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent rozumie i docenia odczucia klientów"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikuje przypadki, w których agent odzwierciedlał wydźwięk klientów lub używał opiekuńczych słów, takich jak „potwierdzę” czy „rozpoznaję”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent rozumie pytanie lub problem klienta"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje, czy agent rozumie pytanie lub problem klienta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumienie"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje łatwość zrozumienia tekstu, biorąc pod uwagę złożoność wyrazów i długość zdań"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje łatwość zrozumienia tekstu, biorąc pod uwagę złożoność wyrazów i długość zdań"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta wiadomość zawiera 1 zbyt długie zdanie, które utrudnia czytanie"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ta wiadomość zawiera ", _interpolate(_list(0)), " zdania są zbyt długie, co utrudnia czytanie"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czytelność"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem oferowanego rozwiązania"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje całą konwersację pod kątem oferowanego rozwiązania"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferowane rozwiązanie"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje ton głosu agenta w trakcie konwersacji"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizuje ton głosu agenta w trakcie konwersacji"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć żadnych błędów w wiadomościach agenta"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć błędów gramatycznych"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto frazy powitalnej. W razie potrzeby dodaj nową przez administratora w sekcji Ustawienia."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent grzecznie zakończył konwersację"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto frazy zamykającej. W razie potrzeby dodaj nowe poprzez administratora w sekcji Ustawienia"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent odzwierciedlił wydźwięk klienta lub używał opiekuńczych słów"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć, czy agent odzwierciedlił wydźwięk klienta, czy użył słów opiekuńczych"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent używał długich zdań i złożonych słów, co mogło utrudniać niektórym zrozumienie"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent używał skomplikowanych słów, co może utrudniać niektórym zrozumienie"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent używał długich zdań, co może utrudniać niektórym zrozumienie"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent używał języka, który jest łatwy do zrozumienia"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za mało danych, aby ocenić tę kategorię"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent miał trudności z rozpoznaniem pytania lub prośby klienta"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent dobrze zrozumiał pytanie/prośbę klienta"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się ustalić, czy agent zrozumiał pytanie lub prośbę klienta"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton agenta podczas konwersacji"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton agenta podczas konwersacji"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton agenta podczas konwersacji"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton agenta podczas konwersacji"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton agenta podczas konwersacji"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się ustalić tonu agenta"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dokończono konwersacji"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończono konwersację"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć, czy konwersacja została zakończona"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentowi brakowało empatii wobec klienta i jego problemów"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent wykazywał empatię wobec klienta i jego problemów"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć, czy agent wykazywał empatię wobec klienta i jego problemów "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient nie został przywitany podczas konwersacji"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient został przywitany podczas konwersacji"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć typowego powitania"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent nie udostępnił klientowi rozwiązania"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent zaoferował klientowi rozwiązanie"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się wykryć, czy agent zaoferował klientowi rozwiązanie"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożone wyrazy"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długie zdania"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramatyka"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd pisowni"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatywna"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeprosiny"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inna"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spokojny"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wesoły"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciekawy"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesjonalny"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wspierający"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styl"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 zamknięcie"]), _normalize([_interpolate(_named("n")), " zamknięć(-cia)"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA sprawdza tylko zatwierdzone zamknięcia. Jeśli ta kategoria jest ustawiona jako krytyczna, nieskorzystanie z zatwierdzonych zamknięć spowoduje niezaliczenie oceny."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone zamknięcia"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone zamknięcia"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 wyjątek"]), _normalize([_interpolate(_named("n")), " wyjątki(-ów)"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrazy i frazy są ignorowane przez Automatyczną QA jako błędy ortograficzne lub gramatyczne."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątki dotyczące pisowni i gramatyki"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykluczenia z tytułu Automatycznej QA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 powitanie"]), _normalize([_interpolate(_named("n")), " powitań(-nia)"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA sprawdza tylko, czy nie zostały zatwierdzone powitania. Jeśli ta kategoria jest ustawiona jako krytyczna, niewykorzystanie zatwierdzonych powitań spowoduje niezaliczenie oceny."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone powitania"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatwierdzone powitania"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria Automatycznej QA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kategorię Automatycznej QA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatycznie przydzielane przyczyny główne:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto → Ustawienia"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niektóre kategorie wykorzystują platformę MS Azure, oferując szerszy zakres funkcji i obsługiwanych języków. Przejrzyj ", _interpolate(_named("link")), ", aby uzyskać więcej informacji lub skontaktuj się z administratorem, by to zrobić."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uzyskaj dostęp do rozszerzonej listy kategorii i obsługiwanych języków dzięki MS Azure. Przejrzyj ", _interpolate(_named("link")), " lub skontaktuj się z administratorem, by to zrobić."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj Automatycznej QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kategorie Automatycznej QA otrzymują ocenę automatycznie na podstawie ustalonej skali ocen. Ocenę można później dostosować ręcznie. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kategorię Automatycznej QA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatycznie przydzielana ocena zależy od wagi błędów i ich liczby. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyny główne"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzentom są pokazywane tylko odpowiednie kategorie ocen. Kategorie ocen są wyświetlane na podstawie warunków ustawionych podczas tworzenia kategorii."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["czat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wprowadzenie"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wybierz typ kategorii ocen dla środowiska pracy „", _interpolate(_named("workspace")), "”."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci muszą ręcznie wybrać odpowiednią kartę wyników, aby wyświetlić odpowiednie kategorie ocen dla każdej konwersacji."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz zmienić typ kategorii ocen?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień kategorie"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmienić typ kategorii ocen?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria oceny warunkowej"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria oceny statycznej"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kategorii ocen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia kategorii ocen"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tej czynności nie można cofnąć."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuń wszystkie oceny"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statystykę można też zachować bez usuwania danych, archiwizując kategorię."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozumiem, że nastąpi to poprzez czynność ", _interpolate(_named("deleteAllRatings")), " dla tej kategorii."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. idealny ton"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż kategorię tylko wtedy, gdy"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Zawsze” zostanie zignorowane. Nie można go łączyć z innymi warunkami."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawsze"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi centrum pomocy"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz warunek"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena zadowolenia (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ źródła"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał konwersacji"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria potrzebuje nazwy"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kategorię do karty wyników"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kategorię"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kategorię"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwizuj"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano kategorię ocen"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowana kategoria nie jest widoczna na tablicy wyników, ale umożliwia zachowanie statystyk bez usuwania jakichkolwiek danych."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwizuj kategorię"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archiwizuj kategorię ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowano kategorię oceny"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń kategorię"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz „usuń kategorię”, aby usunąć tę kategorię"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuń kategorię"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usuń kategorię ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto kategorię ocen"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zduplikowano kategorię ocen"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano grupę kategorii ocen"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto grupę kategorii ocen"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmieniono kolejność grup kategorii ocen"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano grupę kategorii ocen"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywrócono kategorię oceny z archiwum"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano kategorię oceny"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdy agent nie wykryje żadnej kategorii krytycznej, wszystkie kategorie w tej recenzji zostaną automatycznie odrzucone."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krytyczna kategoria oceny"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej grupie nie ma kategorii"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nową kategorię ocen"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kategorię"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wszystkie kategorie i przejrzyj dane w ramach tej grupy"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie grupy kategorii spowoduje niepogrupowanie wszystkich powiązanych z nią kategorii."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń ", _interpolate(_named("categoryName")), " grupa kategorii"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie kategorii w ramach grupy spowoduje utratę wszystkich danych o opiniach powiązanych z tymi kategoriami."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa grupy"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci mogą wybrać więcej niż jedną przyczynę jako przyczynę główną"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci mogą wybrać więcej niż jedną przyczynę jako przyczynę główną"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiele przyczyn"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["np. ton"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nową grupę kategorii"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa grupa"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj „Inne”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj główną przyczynę"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta główna przyczyna już istnieje"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj główną przyczynę"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj przyczyny, aby wyjaśnić ocenę"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala ocen"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowane"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj karty wyników do tej kategorii ocen"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofnij archiwizację"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj kategorię"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie oceny"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlane dla"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny negatywne"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceny negatywne i neutralne"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak, zaktualizuj"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie, które są dla Ciebie ważniejsze, mogą mieć większą wagę w obliczaniu punktacji zgłoszeń."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Waga (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o kalibracji"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o kalibracji"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaawansowane"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regularne sesje kalibracyjne pozwalają recenzentom na dostosowywanie technik oceniania i zapewniają agentom ten sam poziom spójnych i bezstronnych informacji zwrotnych."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl plany"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " to proces, który pomaga Twojemu zespołowi monitorować sposób obsługi i oceny wszystkich interakcji z klientami."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profesjonalna"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibracja jest dostępna dla naszych planów ", _interpolate(_list(0)), " i ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po dodaniu konwersacji do sesji poprzednio dodane recenzje nie są wyświetlane."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewidoczne"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przygotowane wcześniej recenzje są traktowane jak recenzje kalibracji. Do sesji zostanie dodana tylko jedna recenzja konwersacji na każdego recenzenta. Recenzje wiadomości nie są wyświetlane."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczne"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczność wcześniej wystawionych recenzji"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibracja to proces, który pomaga Twojemu zespołowi na bieżąco informować o tym, jak wszystkie interakcje z klientami powinny być obsługiwane i oceniane. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy mają nieograniczony dostęp do wszystkich opinii wystawionych podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawsze wyświetlaj wszystkie recenzje"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po wysłaniu recenzji użytkownicy zobaczą oceny wystawione przez innych recenzentów podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie recenzje kalibracji po wysłaniu recenzji"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy mają nieograniczony dostęp do wszystkich opinii wystawionych podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zawsze wyświetlaj wszystkie recenzje"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierownik"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po wysłaniu recenzji użytkownicy zobaczą oceny wystawione przez innych recenzentów podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie recenzje kalibracji po wysłaniu recenzji"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po wysłaniu recenzji użytkownicy zobaczą oceny wystawione przez innych recenzentów podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie recenzje kalibracji po wysłaniu recenzji"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy będą widzieć tylko ich własne recenzje dodane podczas sesji kalibracyjnej."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczne są tylko własne recenzje"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia kalibracji zostały zaktualizowane"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widoczność przeglądów kalibracji"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw próg wskaźników dla wskaźników paneli, wypełniając luki zgodnie z wewnętrznym systemem wskaźników zespołów. Sprawdź próg na panelu."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibracja"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenia"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólne"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagi"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próg"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano próg"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chwileczkę..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sprawdź, czy masz uprawnienia administratora oraz ", _interpolate(_named("retry")), " lub ", _interpolate(_named("contact")), ", aby uzyskać dalszą pomoc."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skontaktuj się z nami"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że nie udało nam się uzyskać dostępu do interfejsu API Twojego centrum pomocy."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spróbuj ponownie"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączymy Twoje centrum pomocy. Za chwilę nastąpi przekierowanie."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autoryzowano ", _interpolate(_list(0))])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Otwórz ponownie ", _interpolate(_list(0)), ", aby dodać nowe połączenie z centrum pomocy."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że pominięto jeden lub dwa kroki."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istnieje już organizacja z taką samą domeną e-mail"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stronę połączeń"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tymczasem..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daj nam chwilę, a my sprawdzimy Twoje uprawnienia."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tymczasem..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrowanie"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utwórz środowisko pracy"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aby w dalszym ciągu korzystać z Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawie gotowe"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnego środowiska pracy"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz konto"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby w dalszym ciągu korzystać z Zendesk QA, Twój kierownik musi przydzielić Cię do środowiska pracy."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usunięcie konta spowoduje nieodwracalne usunięcie wszystkich danych, które mamy na Twój temat, wliczając ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wszystkie wystawione oceny, zawartość konwersacji, środowiska pracy itp."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozłącz"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozłączenie integracji spowoduje wyłączenie wszystkich powiadomień Zendesk QA przesyłanych przez Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozłącz Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracja ze Slack została rozłączona z Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdefiniuj domyślne ustawienia powiadomień dla wszystkich nowych użytkowników konta."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastąp ustawienia dla wszystkich użytkowników"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamierzasz zastąpić ustawienia powiadomień dla wszystkich użytkowników konta"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy nie będą mogli definiować własnych ustawień powiadomień."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia powiadomień zostały zaktualizowane"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooki"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostrzegaj w przypadku niepowodzenia kategorii krytycznej"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostrzegaj alertem, gdy CSAT otrzyma odpowiedź"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyzwalacze"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz demonstracyjne środowisko pracy i dane"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odtwórz dane demonstracyjne i zachowaj demonstracyjne środowisko pracy."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń demonstracyjne środowisko pracy i dane"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież dane demonstracyjne"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież dane"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zarządzaj kontem „", _interpolate(_named("accountName")), "” w trybie demonstracyjnym"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb demonstracyjny"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostań na stronie"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opuść stronę"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz opuścić tę stronę i utracić wszystkie zmiany?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie ukończone"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zadanie"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie ukończone"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych zadań"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie nie ma żadnych zadań do przejrzenia."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosuj przydział lub poczekaj na rozpoczęcie następnego cyklu."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj dostosować filtry"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zadanie"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obserwuj tę stronę, aby otrzymywać aktualizacje"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zadań w tym cyklu"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konwersacji do wyświetlenia"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz zadanie jako zakończone"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik został przez Ciebie oceniony."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak samooceny w tym zadaniu"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj, klikając ikonę kalibracji w nagłówku widoku konwersacji."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych konwersacji"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zadanie"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz sesję kalibracji"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje zadania"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne zadania"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych zadań"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zadań"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie osoby recenzowane"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie osoby recenzowane oprócz mnie"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono osób recenzowanych"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj osoby recenzowanej"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja robocza"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie statusy"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do zrobienia"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy recenzenci"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy recenzenci oprócz mnie"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydzielone do mnie"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono recenzentów"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj recenzenta"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dni"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dni"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dzień"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dni"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dni"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dni"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " godz."])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nd."])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień przydział"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy środowiska pracy"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżący recenzent"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy cel w tym cyklu"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowy weryfikator"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz recenzenta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel ponownie konwersacje"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel ponownie konwersację"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje do przeniesienia"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bieżący cykl"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zadanie"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono recenzentów"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj recenzenta"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel mi ponownie konwersacje"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel ponownie konwersacje innemu użytkownikowi"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dni"]), _normalize([_interpolate(_named("n")), " dzień"]), _normalize([_interpolate(_named("n")), " dni"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " godz."]), _normalize([_interpolate(_named("n")), " godzina"]), _normalize([_interpolate(_named("n")), " godz."])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj ukończone cykle"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj ukończone sesje"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój cel"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj przyczynę, aby usunąć tę konwersację"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjaśnij, dlaczego usuwasz tę konwersację"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz zachować zmiany?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj przyczynę, aby zastąpić tę konwersację"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć zmiany"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachowaj"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachowaj zmiany"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono zamiennika"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjaśnij, dlaczego zastępujesz tę konwersację"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadal chcesz usunąć tę konwersację z zadania? Spowoduje to zmniejszenie celu o 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamień"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamień konwersację"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wersję roboczą"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj wersję roboczą"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel mi ponownie"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydziel je innemu użytkownikowi"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień przydział i przejrzyj"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń konwersację"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostaw recenzję"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż mniej"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż więcej"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cel: sprawdzono ", _interpolate(_named("count")), "/", _interpolate(_named("goal"))])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe recenzje"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż ukończone cykle"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż ukończone sesje"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozostały czas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadania"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastąpiono konwersację"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz listę zadań"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " później"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " s"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " s"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d."])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d."])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " dni"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d. ", _interpolate(_named("minutes")), " m."])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " g."])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " g. ", _interpolate(_named("minutes")), " m."])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " g. ", _interpolate(_named("minutes")), " m. ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " g. ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m."])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m. ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " m-c"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " lat(a)"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przed chwilą"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak uprawnień. Aby uzyskać pomoc, skontaktuj się z administratorem."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona, której szukasz, nie istnieje."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił nieoczekiwany błąd. Aby uzyskać pomoc, skontaktuj się z zespołem wsparcia."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmowa dostępu. Nie możesz wykonać tej czynności."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poszukiwana strona nie istnieje lub nie masz do niej dostępu."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trochę za szybko działasz i mamy problem z nadrobieniem zaległości."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisano zmiany"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzono niezapisane zmiany"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["zastosowano ", _interpolate(_named("n"))]), _normalize(["zastosowano ", _interpolate(_named("n"))]), _normalize(["zastosowano ", _interpolate(_named("n"))])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączono"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzone przez"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krytyczne"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Były członek"]), _normalize(["Były członek"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " zaznaczonych"]), _normalize([_interpolate(_named("n")), " zaznaczony"]), _normalize([_interpolate(_named("n")), " zaznaczone(-ych)"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...i jeszcze +1"]), _normalize(["...i jeszcze +", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Jeszcze +1"]), _normalize(["jeszcze +", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nowa"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nie jest"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lub"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięto"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odpowiedzi"]), _normalize([_interpolate(_named("n")), " odpowiedź"]), _normalize([_interpolate(_named("n")), " odpowiedzi"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dzisiaj"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["użytkownik"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Piątek"]), _normalize(["Piątki"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Poniedziałek"]), _normalize(["Poniedziałki"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sobota"]), _normalize(["Soboty"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Niedziela"]), _normalize(["Niedziele"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Czwartek"]), _normalize(["Czwartki"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Wtorek"]), _normalize(["Wtorki"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Środa"]), _normalize(["Środy"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wczoraj"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrator"]), _normalize(["administrator"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opiekun klienta"]), _normalize(["Opiekun klienta"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Użytkownik"]), _normalize(["Użytkownik"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatyczna QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesja wygasła."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowania"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole nie może pozostać puste."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych pozycji."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak pasujących osób."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Właściciel"]), _normalize(["właściciel"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej informacji."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zanim zaczniemy, zapoznaj się z naszymi warunkami i zaakceptuj je."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje ukończone ogółem"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowano"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["Agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["Lead"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Menedżer"]), _normalize(["Menedżer"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Recenzent"]), _normalize(["Recenzent"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik anonimowy"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwizuj"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiekun"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wkrótce"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby skopiować"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj łącze"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niebezpieczna strefa"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis (opcjonalnie)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane potwierdzenie"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzucić niezapisane zmiany?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje zwrotne"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częstość"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumiem"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomocy"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WOJ"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inna"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współczynnik zaliczania"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia osobiste"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednia"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż mniej"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba recenzowana"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje otrzymane/przeczytane"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje napisane przez członka środowiska pracy"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rola"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz zmiany"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala binarna"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala 3-punktowa"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala 4-punktowa"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala 5-stopniowa"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta wyników"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz częstotliwość"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż mniej"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż więcej"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiń"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyróżnione"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinie o ankiecie"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakres czasu"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzono niezapisane zmiany"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waga"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zaprosił(a) Cię do zespołu Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Użytkownicy są automatycznie podłączani z poziomu centrum administracji. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj użytkownikami"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o zarządzaniu użytkownikami"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niektórzy użytkownicy są automatycznie przydzielani i synchronizowani przez dostawcę tożsamości. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zmieniono uprawnienie konta „", _interpolate(_list(0)), "”"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o uprawnieniach użytkowników"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pytaj ponownie"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wszystkie zmiany wprowadzane na stronie użytkowników są odzwierciedlane w środowiskach pracy. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacza to, że dostęp użytkownika do pewnych części Zendesk QA może się zmienić"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień uprawnienie na poziomie konta"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usunięto ", _interpolate(_named("n")), " użytkownika"]), _normalize(["Usunięto ", _interpolate(_named("n")), " użytkowników"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gdy usuniesz użytkownika, utraci on dostęp do Twojego konta. Ich recenzje, komentarze i oceny pozostaną w Zendesk QA. ", _interpolate(_named("linebreak")), "Usunięcie użytkownika nie wpłynie na liczbę stanowisk w ramach subskrypcji."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ten użytkownik zostanie usunięty ze wszystkich zarządzanych środowisk pracy. ", _interpolate(_named("linebreak")), " Recenzje, komentarze i oceny użytkowników pozostaną nienaruszone."]), _normalize(["Zostaną usunięte ze wszystkich zarządzanych środowisk pracy. ", _interpolate(_named("linebreak")), " Wszystkie dodane przez nich recenzje, komentarze i oceny pozostaną nienaruszone."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usuń ", _interpolate(_named("name"))]), _normalize(["Usuń ", _interpolate(_named("name")), " użytkowników"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Może wszystko widzieć i zarządzać wszystkimi ustawieniami i rozliczeniami"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Może widzieć wszystko i zarządzać ustawieniami osobistymi i środowiska pracy, z wyjątkiem rozliczeń"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia do konta"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia dotyczące środowiska pracy umożliwiają użytkownikowi wgląd i edytowanie danych w środowiskach pracy, w których się znajduje."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej o QA bota"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje boty są tutaj wyświetlane jako, ponieważ konwersacje, w których uczestniczyły są synchronizowane"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zsynchronizowanych botów"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaczenie bota jako użytkownika zapewni mu dostęp do logowania. Ich historyczne dane botów pozostaną dostępne na panelach."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono takich botów"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj wyszukać jeszcze raz, używając innego słowa kluczowego lub innej pisowni."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj botów"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według nazwy bota"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako użytkownika"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko administratorzy i menedżerowie kont mogą oznaczać boty jako użytkowników"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa bota"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatni czat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaczono ręcznie"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do sprawdzenia"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot zostanie usunięty z automatycznej analizy i wszelkich przydziałów. Nowe dane nie będą pojawiać się na panelach, zapewniając dokładne raportowanie i łatwiejsze zarządzanie botami."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generatywny"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot przebiegu pracy"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do grup(y)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie grupy"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak grupy"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń z grup(y)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz grupę"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienie do konta"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj do środowisk(a) pracy"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pełna nazwa"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio aktywny"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rola na poziomie konta"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Każdy użytkownik otrzymuje uprawnienie na poziomie konta i/lub środowiska pracy. Uprawnienia administracyjne są teraz na poziomie konta, a inne na poziomie środowiska pracy."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń ze środowisk pracy"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienie do środowiska pracy"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia zarządzane w grupie"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako bota"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznaczenie użytkownika jako bota spowoduje usunięcie jego dostępu do funkcji logowania. Ich historyczne dane o użytkownikach pozostaną dostępne na panelach."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń bota z automatycznej analizy i wszelkich przydziałów. Nowe dane nie będą pojawiać się na panelach, zapewniając dokładne raportowanie i łatwiejsze zarządzanie botami."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyklucz bota z opinii"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik oznaczony jako bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko użytkownik z typem uprawnienia może być oznaczony jako bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkowników mających wiele kont nie można oznaczyć jako bota"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według użytkownika"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boty"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy, boty i środowiska pracy"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŚRODOWISKA PRACY"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy i środowiska pracy"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak uprawnień"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywni"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączeni"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano ręcznie"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujący"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujące zaproszenie"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy standardu SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagana czynność"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...i jeszcze 1"]), _normalize(["...i jeszcze ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień uprawnienie na"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usuń i zaproś ponownie do zmiany roli ", _interpolate(_named("n")), " użytkownika oczekującego na zaproszenie."]), _normalize(["Usuń i zaproś ponownie do zmiany roli ", _interpolate(_named("n")), " użytkowników oczekujących na zaproszenie"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edytuj szczegóły dotyczące ", _interpolate(_named("name"))]), _normalize(["Edytuj szczegóły dotyczące ", _interpolate(_named("n")), " wybranych użytkowników"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zaktualizowano ", _interpolate(_named("n")), " użytkownika."]), _normalize(["Zaktualizowano ", _interpolate(_named("n")), " użytkowników."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ta grupa zawiera 1 użytkownika z innego środowiska pracy."]), _normalize(["Ta grupa zawiera ", _interpolate(_named("n")), " użytkowników z innego środowiska pracy."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zarchiwizowano grupę „", _interpolate(_named("name")), "”"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowana grupa"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz grupę"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utworzono grupę „", _interpolate(_named("name")), "”"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa bez tytułu"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć nieodwracalnie tę grupę?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń grupę"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń grupę"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj grupę"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead grupy"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rola grupowa"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ukryta grupa „", _interpolate(_named("name")), "”"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta grupa nie będzie widoczna nigdzie indziej w Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj tylko uprawnieniami do środowiska pracy"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa niewidoczna w Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członek"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa bez tytułu"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inna grupa ma już tę nazwę"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowana grupa ma już tę nazwę"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja rola umożliwia zarządzanie tylko członkami tej grupy. W sprawie dalszych zmian skontaktuj się z użytkownikiem z wyższymi uprawnieniami."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń jako leada grupy"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupa „", _interpolate(_named("name")), "” została przywrócona"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupa „", _interpolate(_named("name")), "” została zapisana"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustaw jako leada grupy"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przywrócono grupę „", _interpolate(_named("name")), "” z archiwum"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niezapisane zmiany"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj istniejących użytkowników do tej grupy"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według nazwiska lub adresu e-mail"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj członków"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupa „", _interpolate(_named("name")), "” jest widoczna"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Każdy członek ma status ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o grupach"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niektóre atrybuty grup są udostępniane i synchronizowane automatycznie przez dostawcę tożsamości."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o grupach"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz grupę"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz łatwo porządkować członków zespołu i naśladować strukturę organizacyjną."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj co najmniej 1 członka"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj nazwę grupy"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istnieje już grupa o tej nazwie"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie grupy"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa grupy"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porządkuj członków zespołu i naśladuj strukturę organizacyjną."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Brak grup"]), _normalize(["1 grupa"]), _normalize([_interpolate(_named("count")), " grup(y)"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nową grupę lub aktywuj grupę zarchiwizowaną."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych grup"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zarchiwizowanych grup"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono takich grup"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj wyszukać jeszcze raz, używając innego słowa kluczowego lub innej pisowni."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano żadnych grup"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["w sekcji"]), _normalize(["w"]), _normalize(["w"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według nazwy grupy"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowane"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Możliwe ponowne wysłanie ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pozostałych stanowisk"]), _normalize([_interpolate(_named("n")), " pozostałe stanowisko"]), _normalize([_interpolate(_named("n")), " pozostałych(-e) stanowisk(a)"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba stanowisk jest obecnie ograniczona, ponieważ subskrypcja jest wstrzymana. Aby dodać więcej użytkowników, aktywuj je ponownie."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osiągnięto limit stanowisk na kontach. Użytkowników można zastępować, dezaktywując jednego aktywnego użytkownika lub dodając kolejne stanowiska."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetl szczegóły płatności"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " zaznaczone"]), _normalize([_interpolate(_named("n")), " zaznaczone(-ych)"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " wybrany użytkownik"]), _normalize([_interpolate(_named("n")), " wybranych użytkowników"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " wierszy"]), _normalize([_interpolate(_named("n")), " wiersz"]), _normalize([_interpolate(_named("n")), " wiersze(-y)"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukuj zbiorczo użytkowników na podstawie ich nazwisk lub adresów e-mail."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wklej tutaj dane, rozdzielając je przecinkami lub znakami nowej linii"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukiwanie zbiorcze użytkowników"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edytuj uprawnienia do środowiska pracy ", _interpolate(_named("name"))]), _normalize(["Edytuj uprawnienia do środowiska pracy ", _interpolate(_named("n")), " wybranych członków"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj uprawnienia"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz uprawnienie do środowiska pracy"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usuń ", _interpolate(_named("name")), " ze środowiska pracy"]), _normalize(["Usuń ", _interpolate(_named("name")), " członków ze środowiska pracy"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Usunięto ", _interpolate(_named("n")), " członka"]), _normalize(["Usunięto ", _interpolate(_named("n")), " członków"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuń członka"]), _normalize(["Usuń członków"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ten użytkownik zostanie usunięty ze środowiska pracy."]), _normalize(["Ci użytkownicy zostaną usunięci ze środowiska pracy."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zmieniono uprawnienia do środowiska pracy „", _interpolate(_list(0)), "”."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj członków"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz konwersacje i powiązane recenzje"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystko w środowisku pracy, ale zarządzaj tylko testami, zadaniami, sporami i sesjami kalibracyjnymi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia do środowisk pracy"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlaj wszystkie elementy w środowisku pracy i zarządzaj nimi"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystko w środowisku pracy, z wyjątkiem ustawień środowiska pracy"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["połącz użytkowników"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy z zarządzanych środowisk pracy zostali dodani do tego środowiska pracy."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś o to administratora."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś nowych użytkowników bezpośrednio lub poproś, aby to zrobił administrator."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " lub ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak użytkowników do wyświetlenia"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" Dodano ", _interpolate(_named("n")), " użytkownika"]), _normalize(["Dodano ", _interpolate(_named("n")), " użytkowników"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dodaj członków"]), _normalize(["Dodaj członka"]), _normalize(["Dodaj ", _interpolate(_named("n")), " członków"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz uprawnienie do środowiska pracy"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkowników do środowiska pracy"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dodaj członków do grupy ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wklej tutaj e-maile, rozdzielając je znakami nowej linii lub przecinkami"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dopasowane ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-maile"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" z już połączonymi użytkownikami:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nie można dopasować ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-maili"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź pisownię lub połącz je później osobno"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" z użytkownikami centrum pomocy:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " połączony użytkownik"]), _normalize([_interpolate(_named("n")), " połączonych użytkowników"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " zaproszony użytkownik"]), _normalize([_interpolate(_named("n")), " zaproszonych użytkowników"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie zbiorcze"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproszenie zbiorcze"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład:\n\nkasia", "@", "przyklad.com, jan", "@", "przyklad.com\n\nkasia", "@", "przyklad.com\nkasia", "@", "przyklad.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nawiąż połączenie ze swoimi centrami pomocy, aby przejrzeć ich interakcje"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Połącz użytkownika"]), _normalize(["Połącz użytkowników"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maile"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz indywidualnie"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli chcesz, aby Twoi użytkownicy mieli dostęp do Zendesk QA, możesz ich zaprosić tutaj."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś połączonych użytkowników"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Zaproś użytkownika"]), _normalize(["Zaproś użytkowników"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomiń zaproszenie"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz użytkowników, którzy chcą się połączyć z Twoimi centrami pomocy w celu przejrzenia ich interakcji"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzaj subskrypcją"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połącz użytkowników centrum pomocy"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaj swojemu środowisku pracy unikatową nazwę"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli ta opcja jest włączona, wszystkie istniejące połączenia z kontami są automatycznie dodawane do nowego środowiska pracy."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wszystkie połączenia do tego środowiska pracy"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz środowisko pracy"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa środowiska pracy"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz środowisko pracy"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownikom dodawanym bezpośrednio do konta (bez przydzielania środowiska pracy) jest przypisywana rola na poziomie konta. Możesz ich później dodać do środowisk pracy."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproszeni użytkownicy automatycznie otrzymują rolę Użytkownika na poziomie konta. Możesz później przejść na wyższą wersję do roli administratora."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie działają"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wklej tutaj e-maile, rozdzielając je znakami końca wiersza lub przecinkami."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " użytkownik zaproszony przez e-mail"]), _normalize([_interpolate(_named("n")), " użytkowników zaproszonych przez e-mail"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownikom dodawanym do środowiska pracy automatycznie przypisywana jest rola użytkownika na poziomie konta. Możesz później przejść na wyższą wersję do roli administratora."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj następny"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapraszanie użytkowników tylko do konta bez dodawania ich do środowiska pracy"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproszeni użytkownicy otrzymują tylko uprawnienie Konta i nie są dodawani do żadnego środowiska pracy."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapraszaj użytkowników bezpośrednio do środowiska pracy i przydzielaj uprawnienia do środowiska pracy"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj użytkowników do środowiska pracy"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproszenie zbiorcze"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś indywidualnie"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś tylko do konta"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś do środowiska pracy"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zaproś użytkowników"]), _normalize(["Zaproś użytkownika"]), _normalize(["Zaproś ", _interpolate(_named("n")), " użytkowników"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbiorcze zaproszenie użytkowników przez e-mail"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuń użytkownika"]), _normalize(["Usuń użytkowników"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zaznaczenie"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaproś użytkowników przez e-mail"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak środowisk pracy"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz uprawnienie"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ponownie zaproszenie"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączony użytkownik"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujący e-mail użytkownika z zaproszeniem"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik standardu SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz środowisko pracy"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenci SI"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum administracji"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapewnienie jakości"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie pracownikami"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ukończone ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ukończone ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ukończone ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cykl ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "–", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzent"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zadanie"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oszczędzaj czas i skonfiguruj zadania automatycznych recenzji, zamiast ręcznie znajdować i przydzielać konwersacje do sprawdzenia."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Termin: ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje można dodawać w sekcji „Kalibracja” w menu bocznym widoku konwersacji."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano jeszcze żadnych sesji"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baseline"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak sesji"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacje można dodać do sesji, klikając ikonę kalibracji w nagłówku widoku konwersacji."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie dodano jeszcze konwersacji"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie wystawiono żadnych recenzji. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostaw recenzję kalibracji"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główna przyczyna"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie udało się załadować palety poleceń."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nic nie znaleziono."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalezione według ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie palety poleceń…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strony, filtry, konwersacje, ustawienia…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konwersacja"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr prywatny"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr publiczny"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Od ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z interfejsu API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pokazano ", _interpolate(_named("rangeStart")), " do ", _interpolate(_named("rangeEnd")), " z ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Pokazano ", _interpolate(_named("rangeStart")), " do ", _interpolate(_named("rangeEnd")), " z ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukryj panel boczny"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż panel boczny"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz menu boczne"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptowane spory"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory odrzucone"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte spory"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zaakceptowane spory"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe recenzje"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlone recenzje"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaakceptowane"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otwarte"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["częściowo zaakceptowane"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sumy łącznej"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odrzucone"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptowane"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zaakceptowane"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzucone"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["recenzja"]), _normalize(["recenzje"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba recenzji wyświetlonych przez osoby recenzowane"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba wyświetlonych recenzji"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzenci ze sporem"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoby ze sporem"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory według kategorii"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory w czasie"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas rozwiązania"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas rozwiązania (godz.)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni czas rozwiązania (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiązane"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spory łącznie"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaakceptowane"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język odpowiedzi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych języków"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie języki"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przewidywane schematy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych schematów"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie schematy"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrazy z Wordcloud"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych wyrazów"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie wyrazy"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wzmianki"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnowsze"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najstarsze"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zaakceptowane"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zareagował(a) przesyłając ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzucone"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiązane"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzje"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz odcień skórki"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesje"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sesji"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono kategorii"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarze"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmiar komentarza"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych rozmiarów"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie rozmiary"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych filtrów"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych grup"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie grupy"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych hashtagów"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie hashtagi"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych wartości"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie wartości"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych dostosowanych pól"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagi centrum pomocy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono tagów centrum pomocy"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie tagi centrum pomocy"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie wyników..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy pytań"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono typów pytań"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj reakcję"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty wyników"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych kart wyników"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie karty wyników"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono ocen"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie oceny"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródła"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono źródeł"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie źródła"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyczyna opinii"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono przyczyn"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie przyczyny"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankiety"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych ankiet"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie ankiety"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanały"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych kanałów"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie kanały"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono żadnych użytkowników"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszyscy użytkownicy"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono środowisk pracy"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie środowiska pracy"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do testów"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test został usunięty lub jeszcze nie został opublikowany. Dokładnie sprawdź link lub wróć do listy testów."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten test nie został udostępniony w środowisku pracy, do którego należysz. Poproś administratora o dodanie Cię do środowiska pracy, do którego ten test ma dostęp."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test jest niedostępny"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do testu jest przyznawany środowiskom pracy, do których nie należysz. Nie można zapisać i opublikować testu."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś administratora o zapisanie i opublikowanie testu."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Należy oznaczyć co najmniej jedną odpowiedź jako prawidłową."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole nie może pozostać puste."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecnie widzisz podgląd testu."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz środowiska pracy, które mogą uzyskać dostęp do testu. Użytkownicy należący do wielu środowisk pracy mogą odpowiedzieć na test tylko raz. Wybranie opcji „Wszystkie środowiska pracy” nie spowoduje uwzględnienia nowo utworzonych środowisk pracy po opublikowaniu testu."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp do testu"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwizuj"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij tryb podglądu"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytania"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwiąż test"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj test"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia testu"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz jako wersję roboczą"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowy test"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofnij archiwizację"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcje"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj opcję"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udostępnij"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieźle wygląda, prawda?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja odpowiedź została wysłana"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test został zapisany"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test został opublikowany"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test został usunięty"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj pytanie"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizuj test"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowany test i jego wyniki nie będą już wyświetlane na liście testów. Nadal możesz uzyskać dostęp do wyników zarchiwizowanych testów i w razie potrzeby je cofnąć z archiwum."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizuj test"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po opublikowaniu testu nie można go zmieniać ani edytować. Wszystkie opublikowane testy będą dostępne dla każdego użytkownika konta."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikować test?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pewno chcesz usunąć ten test? Tej czynności nie można cofnąć."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć test?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można zmienić wysłanych odpowiedzi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłać test?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć test z archiwum"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofnięcie archiwizacji testu spowoduje jego udostępnienie w widoku ogólnym testu. Zapewni to poprzednim uczestnikom dostęp do swoich wyników i umożliwieniu nowym uczestnikom wzięcie udziału w teście."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć test z archiwum"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj pytanie"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis (opcjonalnie)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś pytanie w dół"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś pytanie w górę"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W podglądzie są wyświetlane tylko pytania z odpowiedziami."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń pytanie"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij test"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test bez tytułu"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł testu"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to nieodwracalne usunięcie testu i jego wyników."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz też zachować dostęp do wyników, archiwizując test."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z odpowiedziami"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń test"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spowoduje to nieodwracalne usunięcie testu i jego wyników."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usuń test ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikuj"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy pomagają zwiększyć pewność siebie i umiejętności zespołu, zapewniają spójne doświadczenia i pozwalają na zatrudnienie nowych członków zespołu."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy można wyszukiwać na podstawie ich nazw lub autorów."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono jeszcze żadnych testów"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj listę testów"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śr. ocena"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ukończenia"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test / Autor / Środowiska pracy"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uczestnicy"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikowano"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środowiska pracy"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według testu, autora"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarchiwizowane"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wersja robocza"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie testy"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test został zarchiwizowany."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowano test z archiwum."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na ten test nie otrzymano żadnych odpowiedzi od użytkowników w wybranych środowiskach pracy."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten test nie otrzymał żadnych odpowiedzi przed jego archiwizacją."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak odpowiedzi"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj zobaczysz zestawienie odpowiedzi testu.\nNikt jeszcze nie wypełnił Twojego testu – udostępnienie go z pewnością byłoby pomocne."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ma jeszcze odpowiedzi"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedzi:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia ocena:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indywidualne"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widok ogólny"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – kopia"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano łącze"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono wyników"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Testy użytkownika ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz wyszukać nazwisko uczestnika na tablicy wyników."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj tablicę wyników"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według uczestnika"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablica wyników"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testy"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przełącz tablicę wyników"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacza to, że nie będzie można filtrować tego hashtagu w panelu i nie będzie on już uzupełniany automatycznie w komentarzach. Tej czynności nie można cofnąć."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hashtagi"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hashtagi"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz hashtagi"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól członkom na tworzenie nowych hashtagów"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyty"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usuń hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie hashtagów jest zablokowane"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcja tworzenia hashtagów jest odblokowana"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Usunięto hashtag '", _interpolate(_list(0)), "'"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zmieniono nazwę hashtagu '", _interpolate(_list(0)), "' na '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dodano 1 hashtag"]), _normalize(["Dodano ", _interpolate(_named("count")), " hashtagi(-ów)"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa nazwa"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono hashtagów"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zmień nazwę hashtagu „", _interpolate(_named("tagRenamed")), "”"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagi należy rozdzielać przecinkami lub podziałami wierszy"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nie ma sprawy. Możesz to zrobić ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie powiązane ze środowiskiem pracy"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz połączenie"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz połączenie do tego środowiska pracy"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontaktuj się z administratorem, aby wprowadzić tutaj zmiany"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby wprowadzić tutaj zmiany, skontaktuj się z Zendesk QA"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak połączeń na koncie"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygląda na to, że nie masz jeszcze integracji z platformą obsługi klienta."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usunięcie połączenia spowoduje usunięcie konwersacji ze środowiska pracy ", _interpolate(_named("workspaceName")), ", a także wszelkich powiązanych z nim danych, wliczając recenzje i spory."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń połączenie"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usuń połączenie ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz połączenie"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["W wybranym okresie na karcie wyników nie było niektórych aktywnych kategorii Automatycznej QA. Konwersacje przed ostatnią zmianą dnia ", _interpolate(_named("date")), ", mogły nie zostać przeanalizowane w tych kategoriach."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj będą wyświetlane wszystkie informacje zwrotne. Na razie nie ma nic do pokazania."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak otrzymanych recenzji."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż tylko aktywność w komentarzach"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutaj będą wyświetlane wszystkie przesyłane przez Ciebie opinie. Rozpocznij w widoku konwersacji."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak recenzji."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporządzone recenzje"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymane recenzje"])}
  }
}