import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/account-exists',
    name: 'account-exists',
    component: () => import('./AccountExistsView.vue'),
    meta: { lightBackground: true, hideNav: true },
  },
  {
    path: '/invite-existing',
    name: 'invite-existing',
    component: () => import('./InviteExistingView.vue'),
    props: ({ query }) => ({ tokenPayload: query.inviteToken }),
    meta: { lightBackground: true, hideNav: true },
  },
  {
    path: '/pending-invites',
    name: 'pending-invites',
    component: () => import('./PendingInvitesView.vue'),
    meta: { lightBackground: true, hideNav: true },
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () => import('./BlockedLoginView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { lightBackground: true, skipAuth: true, hideNav: true, allowEmbedding: true },
    children: [
      {
        path: 'login',
        name: 'login',
        props: ({ query }) => ({
          expired: 'expired' in query,
          deleted: 'deleted' in query,
          verified: 'verified' in query,
          mustVerify: 'unverified_email' in query,
          disabledAuthMethod: query.disabledAuthMethod,
          prefillEmail: query.email || '',
          accountNotFound: 'accountNotFound' in query,
        }),
        component: () => import('./LoginView.vue'),
      },
      {
        path: 'invite',
        name: 'invite',
        component: () => import('./InviteView.vue'),
        props: ({ query }) => ({ tokenPayload: query.inviteToken }),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('./SignupView.vue'),
      },

      {
        path: 'password-reset',
        name: 'password-reset',
        component: () => import('./password-reset/PasswordResetView.vue'),
      },
      {
        path: 'magic-link',
        name: 'magic-link',
        component: () => import('./magic-link/MagicLinkView.vue'),
      },
      {
        path: 'zendesk-sso',
        name: 'zendesk-sso',
        component: () => import('./ZendeskSSOView.vue'),
      },
    ],
  },
]

export default routes
