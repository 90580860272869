import { computed, type ComputedRef } from 'vue'

import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import autoInsightsFilters from '@/components/filters/autoInsightsFilters'

import type { DefaultFilterOption } from '../api/filters'
import type { DropdownOption, DeconstructedOption } from '../utils/filters'
import { hasIntelligenceIcon, hasBotIcon } from '../components/filters/filterGrouping'
import { addLabel, getFilterConditionsByOptionName } from '../components/filters/utils'

const vulnerabilityFilters = ['VULNERABILITY_HEALTH', 'VULNERABILITY_CAPABILITY', 'VULNERABILITY_LIFE_EVENT']
// We can't assume that the label of the filtername coming from the backend is the same
// as the i18n key, so we need to map it here.
// This is because every time you want to change a string, you have to create a new i18n key
// (rather than just changing the value of the existing key) & it doesn't make sense to change
// the BE key every time we want to edit the wording in the UI.
const filterOptionNameToI18nKey = {
  TRANSCRIPTION_CONTENT: 'filter.option.label.transcription_content_v2',
  SLA_BREACH: 'filter.option.label.sla_breach_v2',
}

export default function useFilterOptions(
  options: DefaultFilterOption[],
  autoqaFields: DefaultFilterOption[],
  spotlightFields: DefaultFilterOption[],
  customCategoryFields: DefaultFilterOption[],
  selectedOptionNames: ComputedRef<string[]>,
) {
  const flatOptions = computed(() => {
    const defaultOptions: DropdownOption[] = options
      .filter(
        (opt) =>
          opt.filterOption !== 'TICKET_FIELD' &&
          (session.features.sentiment || !opt.filterOption.includes('_SENTIMENT')) &&
          (session.features.disputes || opt.filterOption !== 'DISPUTE_STATUS') &&
          (session.features.vulnerabilityFilters || !vulnerabilityFilters.includes(opt.filterOption)) &&
          (session.features.callTranscriptions ||
            session.features.callTranscriptionsBilling ||
            session.features.talkTranscriptionsEnabled ||
            opt.filterOption !== 'TRANSCRIPTION_CONTENT') &&
          (session.features.autoInsights || !autoInsightsFilters.includes(opt.filterOption)),
      )
      .map((opt) => ({
        allowMultiple: opt.allowedMultiple,
        name: opt.filterOption,
        label: i18n.t(filterOptionNameToI18nKey[opt.filterOption] || opt.labelCode),
        isCustom: false,
        isAutoQa: false,
        isSpotlight: false,
        isCustomCategory: false,
        intelligenceIcon: hasIntelligenceIcon.includes(opt.filterOption),
        botIcon: hasBotIcon.includes(opt.filterOption),
      }))

    const autoQaOptions: DropdownOption[] = (session.features.autoQa ? autoqaFields : []).map((opt) => {
      return {
        allowMultiple: opt.allowedMultiple,
        name: opt.filterOption,
        label: i18n.t(opt.labelCode),
        isCustom: false,
        isAutoQa: true,
        isSpotlight: false,
        isCustomCategory: false,
        intelligenceIcon: true,
      }
    })
    const spotlightOptions: DropdownOption[] = spotlightFields.map((opt) => {
      return {
        allowMultiple: opt.allowedMultiple,
        customPhrases: opt.customPhrases,
        label: opt.name,
        name: opt.name,
        id: opt.id,
        intelligenceIcon: true,
        isAutoQa: false,
        isCustom: false,
        isSpotlight: true,
        isCustomCategory: false,
      }
    })
    const customCategoryOptions: DropdownOption[] = customCategoryFields.map((opt) => {
      return {
        allowMultiple: opt.allowedMultiple,
        positiveCustomPhrases: opt.positiveCustomPhrases,
        negativeCustomPhrases: opt.negativeCustomPhrases,
        label: opt.name,
        name: opt.name,
        id: opt.id,
        isCustom: false,
        isCustomCategory: true,
        isAutoQa: false,
        isSpotlight: false,
        options: opt.options,
        intelligenceIcon: true,
      }
    })

    const allOptions = [...defaultOptions, ...autoQaOptions, ...spotlightOptions, ...customCategoryOptions]

    return allOptions.sort((a, b) => a.label.localeCompare(b.label))
  })

  const filterOptions = computed(() => {
    const optionCanBeSelected = (opt: DropdownOption) =>
      !selectedOptionNames.value.includes(opt.name) || opt.allowMultiple || opt.isCustom || opt.isSpotlight

    return flatOptions.value.filter(optionCanBeSelected).map((o) => addLabel(o))
  })

  const getRelevantOptionsArray = (option: DropdownOption) => {
    if (option?.isAutoQa) return autoqaFields
    if (option?.isSpotlight) return spotlightFields
    if (option?.isCustomCategory) return customCategoryFields
    return options
  }

  const getFilterConditions = (option: DeconstructedOption['option']) => {
    const name = option ? option.name : ''
    if (!name) return []

    const flatOption = flatOptions.value.find((opt) => opt?.name === name)

    const relevantOptionsArray = getRelevantOptionsArray(flatOption)
    const filterConditions = getFilterConditionsByOptionName(name, relevantOptionsArray)

    return filterConditions.map((c) => addLabel(c))
  }

  return { filterOptions, getFilterConditions }
}
