import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import useReviewSidebar from '@/composables/useReviewSidebar'
import useBots from '@/modules/user-management/composables/useBots'
import { session } from '@/composables/useSession'
import { sortByParticipation } from '../utils/sortParticipants'
import { getFirstMatchingMember } from '../utils/defaultReviewee'
import { useTicketContent } from './useTicketContent'
import useTicketTranscripts from './useTicketTranscripts'

export default createSharedComposable(() => {
  const { state } = useTicketContent()
  const { transcripts } = useTicketTranscripts()
  const { state: sidebarState } = useReviewSidebar()

  const workspaceMembers = computed(() => sidebarState.workspaceUsers)

  const messageParticipants = computed(
    () =>
      state.ticket?.externalComments
        ?.filter(({ madeByInternalUser, isPublic }) => madeByInternalUser && isPublic)
        .map(({ fromInternalId }) => fromInternalId) ?? [],
  )

  const botMessageParticipants = computed(
    () =>
      state.ticket?.externalComments
        ?.filter(({ fromBot, fromId }) => fromBot && fromId !== '-1')
        .map(({ fromId }) => fromId) ?? [],
  )

  const transcriptParticipants = computed(
    () =>
      transcripts.value
        ?.flatMap((transcript) =>
          transcript.messages
            ?.filter((message) => !!message.speaker.internalId)
            .map((message) => message.speaker.internalId),
        )
        .filter(Boolean) ?? [],
  )

  const sortedParticipantIds = computed(() =>
    sortByParticipation([
      ...messageParticipants.value,
      ...botMessageParticipants.value,
      ...transcriptParticipants.value,
    ]),
  )

  const { botsData } = useBots({ workspaceIds: [session.workspace.id] })
  const bots = computed(() => botsData.value?.bots || [])

  const mostFrequentWorkspaceAgent = computed(() =>
    getFirstMatchingMember(sortedParticipantIds.value, [...workspaceMembers.value, ...bots.value]),
  )

  return mostFrequentWorkspaceAgent
})
