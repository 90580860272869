import { getTicketUrl } from '@/modules/shared/TicketContent/api'
import type { AccountToken } from '../types'

export default async function (token: Partial<AccountToken>, signal: AbortSignal): Promise<string> {
  if (!token.subdomain || !token.sourceType || !token.tokenId) return Promise.resolve('')

  const { host } = await getTicketUrl({ connectionId: token.tokenId }, signal)

  return host?.startsWith(token.subdomain) ? host : ''
}
