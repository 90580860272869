// The keys are same for every dashboard filters so that v-for directive would not rerender the components
// when switching between dashboards. The props that are passed into the filters in different dashboards
// may differ, thats why all of the components have to be reactive to property changes.
// What we win: no rerendering, much less api requests.

export type FilterKey = (typeof filterKey)[keyof typeof filterKey]

export const filterKey = {
  timePicker: 'timepicker',
  workspacePicker: 'workspaces',
  receivedGivenToggle: 'received-given',
  groupPicker: 'groups',
  userPicker: 'users',
  sourcePicker: 'connections',
  scorecardPicker: 'scorecards',
  hashtagPicker: 'hashtags',
  ticketTagPicker: 'ticket-tags',
  selfReviewsRadio: 'self-reviews',
  calibrationSessionPicker: 'calibration-sessions',
  categoryPicker: 'categories',
  questionTypePicker: 'question-types',
  surveyPicker: 'surveys',
  scorePicker: 'scores',
  csatLanguagePicker: 'csat-languages',
  csatWordcloudPicker: 'csat-wordcloud',
  csatPredictedDimentionsPicker: 'csat-predicted-dimentions',
  csatPredictedDriversPicker: 'csat-predicted-drivers',
  customHelpdeskFilters: 'customHelpdeskFilters',
  surveyReasonPicker: 'survey-reasons',
  conversationChannelPicker: 'channels',
} as const
