import { computed, type ComputedRef } from 'vue'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
import { SelectAllModes } from '@klausapp/ui-kit/components/UiDropdownV2/types'
import { searchUsers, type SearchUsersReturn } from '@/modules/dashboard/api'
import { getReviewerAccessibleWorkspaces, workspacesLoading } from '@/composables/useWorkspaceList'
import type { Member } from '@/modules/dashboard/types'

type Props = ComputedRef<{
  searchQuery: string
  groupIds: string[]
  workspaceIds: number[]
  includedUserids: number[]
  workspacesSelectAllMode: SelectAllModes
  view?: string
  selectedUserIds: number[]
  singleSelect?: boolean
  isFiltered?: boolean
  enabled?: boolean
}>

export function useUserList(props: Props) {
  const searchQuery = computed(() => props.value.searchQuery)
  const groupIds = computed(() => props.value.groupIds)
  const workspaceIds = computed(() => props.value.workspaceIds)
  const includedUserids = computed(() => props.value.includedUserids)
  const view = computed(() => props.value.view)
  const selectedUserIds = computed(() => props.value.selectedUserIds)
  const singleSelect = computed(() => props.value.singleSelect)

  /**
   * TODO: Remove 'wholeAccountSelected', 'workspacesWithAccess', queryFn if condition & 'getWorkspacesByAccess' method
   * when https://linear.app/klausapp/issue/K-1500/be-filter-returned-user-list-by-access-for-userssearch-endpoint implemented
   */
  const wholeAccountSelected = computed(
    () => props.value.workspacesSelectAllMode === SelectAllModes.None && !workspaceIds.value?.length,
  )
  const workspacesWithAccess = computed(() => getReviewerAccessibleWorkspaces(workspaceIds.value))
  const hasWorkspacesWithAccess = computed(() => wholeAccountSelected.value || !!workspacesWithAccess.value.length)
  const enabled = computed(() => !workspacesLoading.value && props.value.enabled)

  const {
    data: userData,
    isPending: isLoading,
    isFetching,
    isFetched,
  } = useQuery({
    queryKey: ['users-list', workspacesWithAccess, groupIds, searchQuery, includedUserids, selectedUserIds],
    queryFn: ({ signal }) => {
      if (!workspacesWithAccess.value.length && !wholeAccountSelected.value) {
        return Promise.resolve({ users: [] as Member[], total: 0 })
      }

      if ((view.value === 'conversations' || props.value.isFiltered) && includedUserids.value.length) {
        return getFilteredUsersOnly(
          workspacesWithAccess.value,
          groupIds.value,
          includedUserids.value,
          signal,
          selectedUserIds.value,
        )
      }

      return getUsersWithAlreadySelectedUsers(
        workspacesWithAccess.value,
        groupIds.value,
        selectedUserIds.value,
        searchQuery.value,
        signal,
        singleSelect.value,
      )
    },
    placeholderData: keepPreviousData,
    gcTime: 0,
    retry: 1,
    enabled,
  })

  return { userData, isLoading, isFetching, isFetched, hasWorkspacesWithAccess }
}

const getUsersWithAlreadySelectedUsers = async (
  workspaceIds: number[],
  groupIds: string[],
  selectedUserIds: number[],
  searchQuery: string,
  signal: AbortSignal,
  singleSelect: boolean,
): Promise<Awaited<ReturnType<typeof searchUsers>>> => {
  const usersResp = await searchUsers({
    workspaceIds,
    groupIds,
    name: searchQuery || undefined,
    signal,
  })

  if (selectedUserIds.length || searchQuery) {
    const selectedUsersResp = await searchUsers({
      workspaceIds,
      groupIds,
      ids: selectedUserIds,
      signal,
    })

    let uniqueUsers: Member[] = []
    if (searchQuery && singleSelect) uniqueUsers = uniqBy(usersResp.users, 'id')
    else uniqueUsers = uniqBy([...usersResp.users, ...selectedUsersResp.users], 'id')
    const totalDiff = uniqueUsers.length - usersResp.users.length

    return {
      users: uniqueUsers,
      total: usersResp.total - totalDiff,
    }
  }

  return usersResp
}

const getFilteredUsersOnly = async (
  workspaceIds: number[],
  groupIds: string[],
  includedUserids: number[],
  signal: AbortSignal,
  selectedUserIds: number[],
): Promise<Awaited<ReturnType<typeof searchUsers>>> => {
  const queries: Promise<SearchUsersReturn>[] = []

  // Filtering by groups and filtering by IDs need to be in separate requests, otherwise API filters out the users who don't belong to the groups
  if (includedUserids.length) {
    queries.push(
      searchUsers({
        workspaceIds,
        ids: includedUserids,
        signal,
      }),
    )
  }

  if (groupIds.length) {
    queries.push(
      searchUsers({
        workspaceIds,
        groupIds,
        signal,
      }),
    )
  }

  if (queries.length === 0) queries.push(searchUsers({ workspaceIds, signal }))

  const results = await Promise.all(queries)
  const includedUsersResp = results.reduce(
    (acc, curr) => {
      acc.users.push(...curr.users)
      acc.total += curr.total
      return acc
    },
    { users: [], total: 0 },
  )

  if (selectedUserIds.length) {
    const selectedUsersResp = await searchUsers({
      workspaceIds,
      groupIds,
      ids: selectedUserIds,
      signal,
    })

    const uniqueUsers = uniqBy([...includedUsersResp.users, ...selectedUsersResp.users], 'id')

    return {
      users: uniqueUsers,
      total: includedUsersResp.total,
    }
  }

  return includedUsersResp
}
