import { type Ref, computed } from 'vue'
import {
  useRouter,
  type RouteRecordNormalized,
  type RouteRecordRaw,
  type RouteRecordName,
  type RouteParams,
  type RouteLocationNormalized,
} from 'vue-router'
import { capitalize } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { matchSorter } from 'match-sorter'
import { hasAccountRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'
import { session } from '@/composables/useSession'
import useNavbarRoutes from '@/composables/useNavbarRoutes'
import type { Features } from '@/types/session'

import { getRouteTranslations } from './routeTranslationsMap'

type RouteRecordUnion = RouteRecordNormalized | RouteRecordRaw
export type RouteRecord = RouteRecordUnion & { parent?: RouteRecordName; type?: string }

const getNavbarRouteName = (r: RouteRecord) => {
  let navbarRouteName = (r.parent || r.name)?.toString()

  if (navbarRouteName === 'dashboard.layout') navbarRouteName = 'dashboard'
  if (navbarRouteName === 'pins') navbarRouteName = 'coaching.sessions'
  if (navbarRouteName === 'quizzes') navbarRouteName = 'coaching.sessions'

  return navbarRouteName
}

const toRouteTranslations = (r: RouteRecordNormalized, params: RouteParams) => {
  const routeConfig = getRouteTranslations(r, params)
  const label = routeConfig?.label || ''
  const tags = routeConfig?.keywords || []
  const breadcrumbs = routeConfig?.breadcrumbs?.map((t) => capitalize(t)) || []
  const type = routeConfig?.type || ''

  return { label, tags, type, breadcrumbs }
}

const filterRoute = (r: RouteRecordNormalized | RouteRecordRaw) => {
  const requiredFlag = r.meta?.requiredFlag

  if (r.meta?.hideProxied) return false
  if (requiredFlag) {
    let isAccessGranted = false

    if (typeof requiredFlag === 'string') {
      isAccessGranted = session.features[requiredFlag]
    } else if (typeof requiredFlag === 'object' && requiredFlag.flags) {
      const { condition, flags } = requiredFlag
      const flagExists = (flag: keyof Features) => session.features[flag]

      if (condition === 'AND') isAccessGranted = flags.every(flagExists)
      if (condition === 'OR') isAccessGranted = flags.some(flagExists)
    }

    if (!isAccessGranted) return false
  }

  const requiredAccountRole = r.meta?.requiredAccountRole
  const requiredHighestWorkspaceRole = r.meta?.requiredHighestWorkspaceRole
  if (requiredAccountRole && !hasAccountRole(...requiredAccountRole)) return false
  if (
    requiredHighestWorkspaceRole &&
    !hasAccountRole('ADMIN', 'MANAGER') &&
    !hasHighestWorkspaceRole(...requiredHighestWorkspaceRole)
  )
    return false

  const routeParamsCount = r.path.match(/\/:/g)?.length || 0
  const optionalParamsCount = r.path.match(/\?/g)?.length || 0
  const allParamsOptional = !routeParamsCount || routeParamsCount === optionalParamsCount

  const endsWithOptionalParams = !r.path.match(/\?\/[^:]/g)?.length

  return allParamsOptional && endsWithOptionalParams
}

const findChildren = (r: RouteRecord) => {
  const childrenRoutes = (r.children || [])
    .map((c) => ({
      ...c,
      path: c.path.startsWith('/') ? c.path : `${r.path}/${c.path}`,
      parent: r.parent || r.name,
    }))
    .filter((c) => r.path !== c.path && filterRoute(c))
    .flatMap(findChildren) as RouteRecord[]

  return r.meta?.includeChildrenOnlyInCommandPalette ? childrenRoutes : [r, ...childrenRoutes]
}

export function getDocumentTitle(to: RouteLocationNormalized): string {
  const sortedTranslatedRoutes = [...to.matched]
    .map((m) => toRouteTranslations(m, to.params))
    .filter((translations) => translations.label)
    .map((translations) => translations.label)
    .slice(0, 1)

  return sortedTranslatedRoutes.length ? `${sortedTranslatedRoutes.join(' - ')} - Zendesk QA` : 'Zendesk QA'
}

const includeInCommandPalette = (r: RouteRecord) =>
  r.meta?.includeInCommandPalette || r.meta?.includeChildrenOnlyInCommandPalette

export default function (query: Ref<string>) {
  const router = useRouter()
  const { navbarRoutesMap } = useNavbarRoutes()

  const formatRoute = (r: RouteRecord) => {
    const routeTranslations = getRouteTranslations(r)
    const label = routeTranslations?.label || ''
    const breadcrumbs = routeTranslations?.breadcrumbs?.map((t) => capitalize(t)) || []
    const joinedBreadcrumbs = breadcrumbs.join(' > ')
    const navbarRouteName = getNavbarRouteName(r)
    const icon = navbarRouteName ? navbarRoutesMap.value[navbarRouteName]?.icon : null

    return {
      ...r,
      formattedName: `${joinedBreadcrumbs.length ? `${joinedBreadcrumbs} > ` : ''}${
        label || capitalize(r.name?.toString() || '')
      }`,
      type: routeTranslations?.type || '',
      uuid: uuid(),
      icon,
      label,
      keywords: routeTranslations?.keywords || [],
    }
  }

  const allRoutes = computed(() =>
    router
      .getRoutes()
      .filter((r) => includeInCommandPalette(r) && filterRoute(r))
      .flatMap(findChildren)
      .map(formatRoute),
  )

  return computed(() => {
    if (!query.value) return []

    return matchSorter(allRoutes.value, query.value, { keys: ['formattedName', 'label', 'keywords', 'type'] })
  })
}
