import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex w-full justify-between" }
const _hoisted_2 = { class: "text-lg font-semibold" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_ticket_list_loader = _resolveComponent("ticket-list-loader")!
  const _component_empty_state_img = _resolveComponent("empty-state-img")!
  const _component_arrow_down_icon = _resolveComponent("arrow-down-icon")!
  const _component_arrow_up_icon = _resolveComponent("arrow-up-icon")!
  const _component_leaderboard_row = _resolveComponent("leaderboard-row")!
  const _component_transition_slide = _resolveComponent("transition-slide")!
  const _component_recycle_scroller = _resolveComponent("recycle-scroller")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style.header)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _renderSlot(_ctx.$slots, "headerActions")
        ]),
        (!_ctx.emptyState)
          ? (_openBlock(), _createBlock(_component_search_input, {
              key: 0,
              modelValue: _ctx.query,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
              small: "",
              class: _normalizeClass(_ctx.$style.search),
              placeholder: _ctx.searchPlaceholder
            }, null, 8 /* PROPS */, ["modelValue", "class", "placeholder"]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ticket_list_loader, { key: 0 }))
      : (_ctx.emptyState)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["prose-p:text-sm", _ctx.$style.empty])
          }, [
            _renderSlot(_ctx.$slots, "emptyState", {}, () => [
              _createVNode(_component_empty_state_img)
            ])
          ], 2 /* CLASS */))
        : (_ctx.query.length && !_ctx.list.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["prose-p:text-sm", _ctx.$style.empty])
            }, [
              _renderSlot(_ctx.$slots, "noResults", {}, () => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('quizzes.not_found')), 1 /* TEXT */)
              ])
            ], 2 /* CLASS */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createElementVNode("div", {
                class: _normalizeClass(["text-gray-60 text-xs font-medium", [_ctx.$style.headings, _ctx.$style.row]])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headings, (heading, idx) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: heading.value,
                    class: _normalizeClass([
            _ctx.$style.heading,
            idx > 1 && _ctx.$style.alignedRight,
            _ctx.sort === heading.value && 'text-active-primary font-medium',
          ]),
                    style: _normalizeStyle({ gridArea: heading.value }),
                    onClick: ($event: any) => (_ctx.setSort(heading.value))
                  }, [
                    _createTextVNode(_toDisplayString(heading.label) + " ", 1 /* TEXT */),
                    (_ctx.sort === heading.value)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_ctx.sortDescending)
                            ? (_openBlock(), _createBlock(_component_arrow_down_icon, {
                                key: 0,
                                class: "icon-s ml-1",
                                "stroke-width": 3
                              }))
                            : (_openBlock(), _createBlock(_component_arrow_up_icon, {
                                key: 1,
                                class: "icon-s ml-1",
                                "stroke-width": 3
                              }))
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_3))
                }), 128 /* KEYED_FRAGMENT */))
              ], 2 /* CLASS */),
              _createVNode(_component_recycle_scroller, {
                class: _normalizeClass(_ctx.$style.list),
                items: _ctx.list,
                "item-size": _ctx.SCROLLER_ITEM_HEIGHT,
                buffer: _ctx.SCROLLER_BUFFER_HEIGHT,
                "emit-update": "",
                onUpdate: _ctx.handleScrollerUpdate
              }, {
                default: _withCtx(({ item }) => [
                  _renderSlot(_ctx.$slots, "row", { item: item }, () => [
                    _createVNode(_component_leaderboard_row, {
                      row: item,
                      "can-interact": _ctx.canInteract,
                      "show-avatars": "",
                      onClick: _ctx.handleRowClick
                    }, null, 8 /* PROPS */, ["row", "can-interact", "onClick"])
                  ])
                ]),
                after: _withCtx(() => [
                  (_ctx.currentRow)
                    ? _withDirectives((_openBlock(), _createBlock(_component_transition_slide, {
                        key: 0,
                        offset: [0, 16],
                        duration: 200
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_leaderboard_row, {
                              row: _ctx.currentRow,
                              "can-interact": _ctx.canInteract,
                              class: _normalizeClass(_ctx.$style.stickyRow),
                              "show-avatars": "",
                              onClick: _ctx.handleRowClick
                            }, null, 8 /* PROPS */, ["row", "can-interact", "class", "onClick"])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 512 /* NEED_PATCH */)), [
                        [_vShow, !_ctx.rowVisibleInList]
                      ])
                    : _createCommentVNode("v-if", true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["class", "items", "item-size", "buffer", "onUpdate"])
            ], 64 /* STABLE_FRAGMENT */))
  ], 2 /* CLASS */))
}