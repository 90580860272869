import { reactive } from 'vue'

export const overviewState = reactive<{
  disputesTotal: number
  openDisputesTotal: number
  disputesResolved: number
  averageResolutionTime?: string
  loading: boolean
}>({
  disputesTotal: 0,
  openDisputesTotal: 0,
  disputesResolved: 0,
  averageResolutionTime: null,
  loading: true,
})
