import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/getting-started',
    name: 'onboarding',
    component: () => import('./OnboardingIndexView.vue'),
    meta: { hideNav: true, lightBackground: true, allowEmbedding: true },
  },
]

export default routes
