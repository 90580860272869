import type { OptionGroup } from '@klausapp/ui-kit/components/UiDropdownV2/types'

export const getFlatOptions = <T>(groups: OptionGroup<T>[]) => groups.map(({ options }) => options).flat()

export const getVisibleOptions = <T>(options: OptionGroup<T>[], maxItems = 150) => {
  let itemsLeft = maxItems

  return options.map((group) => {
    const options = group.options.slice(0, itemsLeft)
    itemsLeft = itemsLeft - options.length

    return {
      ...group,
      options,
    }
  })
}
