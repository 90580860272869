import { type ComputedRef, ref, computed } from 'vue'
import { debounce } from 'lodash-es'
import type { CustomField } from '@zendesk/zqa-services/filters'
import { useQuery, useInfiniteQuery } from '@tanstack/vue-query'
import { createSharedComposable } from '@vueuse/core'

import { getHelpdesksCustomFields } from './api'

export default createSharedComposable(
  (workspaceIds: ComputedRef<number[]>, options?: ComputedRef<{ ids: string[] }>) => {
    const searchQuery = ref('')

    const ids = computed(() => options?.value?.ids)
    const selectedQueryEnabled = computed(() => !!ids.value && ids.value.length > 0)

    const { data: selectedData, isFetched: selectedFieldsFetched } = useQuery({
      queryKey: ['helpdesk-custom-fields-selected', workspaceIds, ids],
      queryFn: () => getHelpdesksCustomFields(workspaceIds.value, { ids: ids.value }),
      select: ({ customFields, total }) => {
        return { fieldsMap: toFieldsMap(customFields), total }
      },
      enabled: selectedQueryEnabled,
    })

    const PAGE_SIZE = 50

    const { data, isFetched, fetchNextPage } = useInfiniteQuery({
      queryKey: ['helpdesk-custom-fields', workspaceIds, searchQuery],
      queryFn: ({ pageParam }) =>
        getHelpdesksCustomFields(workspaceIds.value, {
          search: searchQuery.value,
          page: pageParam,
          pageSize: PAGE_SIZE,
        }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages) => {
        const total = Number(lastPage.total)
        const fetched = pages.flatMap((page) => page.customFields).length
        return fetched >= total ? undefined : pages.length + 1
      },
      select: (data) => {
        const customFields = data.pages.flatMap((page) => page.customFields)
        const total = data.pages[0].total
        return { fieldsMap: toFieldsMap(customFields), total }
      },
    })

    const search = debounce((query: string) => {
      searchQuery.value = query
    }, 300)

    const total = computed(() => Number(data.value?.total) || 0)

    const fieldsMapWithSelected = computed(() => {
      const fieldsMap = data.value?.fieldsMap || {}

      if (searchQuery.value) return fieldsMap

      return { ...fieldsMap, ...selectedData.value?.fieldsMap }
    })

    return {
      fieldsMap: fieldsMapWithSelected,
      total,
      helpdeskCustomFieldsFetched: isFetched,
      selectedFieldsFetched,
      search,
      fetchNextPage,
    }
  },
)

function toFieldsMap(value: CustomField[]) {
  return value.reduce<Record<string, CustomField>>((acc, cur) => ({ ...acc, [cur.externalId]: cur }), {})
}
