import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_circle_icon = _resolveComponent("alert-circle-icon")!
  const _component_quiz_title = _resolveComponent("quiz-title")!
  const _component_quiz_question_form = _resolveComponent("quiz-question-form")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass([_ctx.$style.container, "h-full"])
  }, [
    _createElementVNode("form", {
      class: _normalizeClass(_ctx.$style.form)
    }, [
      (_ctx.data.showWarning)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["text-xs", _ctx.$style.message])
          }, [
            _createVNode(_component_alert_circle_icon, { class: "icon-m alert-circle-icon" }),
            _createTextVNode(_toDisplayString(_ctx.$t('quizzes.form.questions_preview_warning')), 1 /* TEXT */)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_quiz_title, { "read-only": "" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.preview.fields, (question, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: question.id,
          class: _normalizeClass(_ctx.$style.questionContainer)
        }, [
          _createVNode(_component_quiz_question_form, {
            id: question.id,
            modelValue: question.options,
            "onUpdate:modelValue": ($event: any) => ((question.options) = $event),
            text: `${i + 1}. ${question.text}`,
            images: question.images,
            properties: question.properties
          }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "text", "images", "properties"])
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}