import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.session.features.granularScale && _ctx.changes.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["text-sm leading-snug", [_ctx.$style.container, 'mx-6 mb-6']])
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('dashboard.rating_scale_change.message')) + " ", 1 /* TEXT */),
        _createElementVNode("a", {
          class: "content-link",
          href: _ctx.$t('support_links.rating_scale_changes'),
          target: "_blank",
          rel: "noopener noreferrer"
        }, _toDisplayString(_ctx.$t('dashboard.rating_scale_change.info_link')), 9 /* TEXT, PROPS */, _hoisted_1)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}