import { v4 as uuid } from 'uuid'
import { ref, watch, computed } from 'vue'
import { z } from 'zod'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import type {
  AdvancedScorecard_ScorecardRatingCategoryGroup as ScorecardGroup,
  AdvancedScorecard_Scorecard as Scorecard,
} from '@zendesk/zqa-services/scorecards'
import { cloneDeep } from 'lodash-es'

import { useWorkspaceList, workspacesLoading } from '@/composables/useWorkspaceList'
import { SCALE_VALUES } from '@/data/scales'
import { getScorecardSettings } from '@/modules/settings/api'

import { scorecardSchema, scorecardCategorySchema } from './validation'
import { createScorecard, updateScorecard, getScorecard } from './api'
import { type AdvancedScorecard_ScorecardRatingCategory as ScorecardCategory, ScorecardStatus } from './types'

export default createSharedComposable((id?: string) => {
  const form = ref<Scorecard | null>(null)

  const [workspaces] = useWorkspaceList()
  const workspacesLoaded = computed(() => !workspacesLoading.value && workspaces.value.length)

  const isEdit = computed(() => !!id)

  const { data: scorecardSettings } = useQuery({
    queryKey: ['scorecard-settings'],
    queryFn: getScorecardSettings,
  })

  const { data } = useQuery({
    queryKey: ['scorecard', id],
    queryFn: () => getScorecard({ scorecardId: id }),
    enabled: isEdit,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const DEFAULT_SCALE = 'SCALE_2'

  const getEmptyCategory = (position: number): ScorecardCategory => ({
    name: '',
    description: '',
    type: 'MANUAL',
    ratingCategoryId: null,
    scale: DEFAULT_SCALE,
    weight: 10,
    position,
    critical: false, // TODO: remove when removed from api
    reviewCritical: false,
    groupCritical: false,
    conditions: [],
    skippable: scorecardSettings.value?.displayNA || false,
    rootCauses: {
      multipleRequireReasons: false,
      freeTextAllowed: false,
      ratings: SCALE_VALUES[DEFAULT_SCALE].map((scaleValue) => ({
        rating: scaleValue.label,
        rootCauses: [],
      })),
    },
  })

  const getEmptyGroup = (position: number): ScorecardGroup => ({
    id: uuid(),
    name: '',
    categories: [],
    position,
  })

  const getEmptyScorecard = (): Scorecard => ({
    id: null,
    status: ScorecardStatus.DRAFT,
    name: '',
    groups: [],
    categories: [],
    // TODO: remnove when removed from service types
    workspaceIds: [],
    workspaces: workspaces.value.map((w) => ({ workspaceId: String(w.id), autoQaEnabled: true })),
    createdAt: null,
    updatedAt: null,
  })

  watch(
    [workspacesLoaded, data],
    () => {
      if (data.value) {
        form.value = cloneDeep(data.value)
      } else if (workspacesLoaded.value && !form.value && !isEdit.value) {
        form.value = getEmptyScorecard()
      }
    },
    { immediate: true },
  )

  const getCategoryIndex = (categoryId: string) =>
    form.value.categories.findIndex((category) => category.ratingCategoryId === categoryId)

  const getGroupIndex = (groupId: string) => form.value.groups.findIndex((g) => g.id === groupId)

  const getGroup = (groupId: string) => {
    const groupIndex = getGroupIndex(groupId)
    if (groupIndex === -1) throw new Error('group not found')

    return form.value.groups[groupIndex]
  }

  const getGroupByCategoryId = (categoryId: string) => {
    return form.value.groups.find((group) =>
      group.categories.some((category) => category.ratingCategoryId === categoryId),
    )
  }

  const getGroupIndexByCategoryId = (categoryId: string) => {
    return form.value.groups.findIndex((group) =>
      group.categories.some((category) => category.ratingCategoryId === categoryId),
    )
  }

  const recalculatePositions = () => {
    form.value.categories.forEach((category, categoryIndex) => {
      category.position = categoryIndex
    })

    form.value.groups.forEach((group, groupIndex) => {
      group.position = groupIndex
      group.categories.forEach((category, categoryIndex) => {
        category.position = categoryIndex
      })
    })
  }

  const addCategory = (groupId?: string) => {
    let category: ScorecardCategory

    if (groupId) {
      const group = getGroup(groupId)
      category = getEmptyCategory(group.categories.length)
      group.categories.push(category)
    } else {
      category = getEmptyCategory(form.value.categories.length)
      form.value.categories.push(category)
    }

    return category.ratingCategoryId
  }

  const mapErrors = (error: z.ZodError): Record<string, string> => {
    return error.issues.reduce((acc, issue) => {
      issue.path.forEach((path) => {
        acc[path] = issue.message
      })
      return acc
    }, {})
  }

  const getCategoryIndexByRatingCategoryId = (ratingCategoryId: string) => {
    const groupIndex = getGroupIndexByCategoryId(ratingCategoryId)
    if (groupIndex !== -1) {
      const group = form.value.groups[groupIndex]
      const categoryIndex = group.categories.findIndex((category) => category.ratingCategoryId === ratingCategoryId)
      return [categoryIndex, groupIndex]
    } else {
      const categoryIndex = getCategoryIndex(ratingCategoryId)
      if (categoryIndex === -1) throw new Error('Category not found')
      return [categoryIndex, null]
    }
  }

  const updateCategory = (updatedCategory: ScorecardCategory, previousRatingCategoryId: string) => {
    const [categoryIndex, groupIndex] = getCategoryIndexByRatingCategoryId(previousRatingCategoryId)

    if (groupIndex !== null) {
      const group = form.value.groups[groupIndex]
      group.categories[categoryIndex] = updatedCategory
    } else {
      form.value.categories[categoryIndex] = updatedCategory
    }
  }

  const validateCategory = (categoryId: string) => {
    const [categoryIndex, groupIndex] = getCategoryIndexByRatingCategoryId(categoryId)

    const category =
      groupIndex !== null
        ? form.value.groups[groupIndex].categories[categoryIndex]
        : form.value.categories[categoryIndex]

    try {
      scorecardCategorySchema.parse(category)
      return {}
    } catch (error) {
      if (error instanceof z.ZodError) {
        return mapErrors(error)
      } else {
        console.error('Unexpected error: ', error)
      }
    }
  }

  const deleteCategory = (categoryId: string) => {
    const categoryGroup = getGroupByCategoryId(categoryId)
    if (categoryGroup) {
      categoryGroup.categories = categoryGroup.categories.filter((category) => category.ratingCategoryId !== categoryId)
    } else {
      form.value.categories = form.value.categories.filter((category) => category.ratingCategoryId !== categoryId)
    }
    recalculatePositions()
  }

  const addGroup = () => {
    const group = getEmptyGroup(form.value.groups.length)
    form.value.groups.push(group)
    return group.id
  }

  const updateGroup = (group: ScorecardGroup) => {
    const index = getGroupIndex(group.id)
    if (index === -1) throw new Error('Group not found')
    form.value.groups[index] = group
  }

  const deleteGroup = (groupId: string) => {
    const index = getGroupIndex(groupId)
    if (index === -1) throw new Error('Group not found')
    form.value.groups.splice(index, 1)
    recalculatePositions()
  }

  const allCategories = computed(() => {
    if (!form.value) return []

    const categories = form.value.categories
    const groupCategories = form.value.groups.flatMap((group) => group.categories)
    return [...categories, ...groupCategories]
  })

  const usedCategoryIds = computed(() => allCategories.value.map((category) => category.ratingCategoryId))

  const totalWeight = computed(() => {
    if (!form.value) return 0

    return allCategories.value.reduce((total, category) => total + category.weight, 0)
  })

  const hasCategories = computed(() => !!allCategories.value.length)

  const publish = (scorecard: Scorecard): Record<string, string> => {
    try {
      scorecardSchema.parse(scorecard)
      const scorecardPayload = {
        ...scorecard,
        status: ScorecardStatus.ACTIVE,
      }
      if (isEdit.value) {
        updateScorecard(scorecard.id, scorecardPayload)
      } else {
        createScorecard(scorecardPayload)
      }
      return {}
    } catch (error) {
      if (error instanceof z.ZodError) {
        return mapErrors(error)
      } else {
        console.error('Unexpected error: ', error)
      }
    }
  }

  return {
    form,
    addCategory,
    updateCategory,
    deleteCategory,
    addGroup,
    updateGroup,
    deleteGroup,
    totalWeight,
    hasCategories,
    publish,
    recalculatePositions,
    usedCategoryIds,
    validateCategory,
  }
})
