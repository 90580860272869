import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'
import type { CustomFieldValueRequest } from '@zendesk/zqa-services/filters'

import { getHelpdeskCustomFieldValues } from './api'

export default function useHelpdeskCustomFieldValues(
  workspaceIds: ComputedRef<number[]>,
  props: ComputedRef<CustomFieldValueRequest>,
) {
  const { data, isPending: isLoading } = useQuery({
    queryKey: ['helpdesk-custom-field-values', workspaceIds, props],
    queryFn: () => getHelpdeskCustomFieldValues(workspaceIds.value, props.value),
    placeholderData: keepPreviousData,
  })

  return {
    options: computed(() => data.value?.values ?? []),
    total: computed(() => (data.value?.total ? Number(data.value.total) : undefined)),
    isLoading,
  }
}
