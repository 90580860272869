export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的 Zendesk 子域名"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录 Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的公司现有一个帐户。"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入公司"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要加入现有公司，请让您的管理员向您发送邀请。"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让您的管理员邀请您"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我改变了主意。"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消注册"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贵公司有一个现有帐户。您要加入贵公司的帐户还是创建一个新帐户？"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制说明"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新帐户"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有一个现有帐户"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在支付未付的订阅发票之前，您无法访问 Zendesk 质量保证帐户。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅被暂停"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到帐户。再试一次。"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取新密码。"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["再试一次或", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误的电邮和密码组合。"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请向您的管理员请求新邀请链接。"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀请链接无效或已被使用。"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已在您的帐户中取消激活 ", _interpolate(_named("method")), " 登录方法。"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此邀请无效或已使用。请向您的管理员索取新邀请。"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载邀请信息失败。"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册失败。可能已存在拥有此电邮的用户。"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连续尝试多次登录后，您的帐户已被阻止。查看您的收件箱，查找其中包含如何取消阻止的说明的电邮。"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先验证电邮"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的电邮帐户"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段必填"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段必填"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录码"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送魔术链接"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作电邮"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的工作电邮"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (主要服务协议)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不符合要求"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私声明"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等一等"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["你好 ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["加入 ", _interpolate(_named("organization")), " 组织"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电邮已验证。现在您可以在下方用新帐户登录。"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Google 继续"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效邀请链接"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔术链接登录"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回到登录页"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Slack 继续"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 Zendesk 继续"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电邮已验证。现在您可以用以下新帐户登录："])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用 Google 登录"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过魔术链接登录"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用 Slack 登录"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录 Zendesk 质量保证"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帐户已删除"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有帐户？"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的代码"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段必填"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的电邮帐户"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段必填"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录码"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有登录选项"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " 通过传统方法"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔术链接登录"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到了一封包含登录码的电邮。"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到了一封电邮，可按照其中的说明登录。"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送魔术链接时出错。再试一次。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过电邮登录"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个小写字符"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个大写字符"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码必须包含"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码最大长度为 128 个字符"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["易记难猜"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最少 8 个字符"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个小写字符"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个数字"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个大写字符"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要重置您的密码，请输入您的电邮地址，我们会向您发送一封带有说明的电邮。"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的电邮帐户"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段必填"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["电邮已发送至 ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您的收件箱或垃圾邮件文件夹中没有该电邮，请检查您输入的电邮。"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回以登录"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查您的电邮"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), "，您将收到一封密码重置电邮。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您的帐户已有密码"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果是这样", _interpolate(_named("bold")), "，我们已发送给您一个密码重置链接。"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮已与一个帐户关联"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记着密码？"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送重置链接时出错。再试一次。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用魔术链接注册"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注册即表示您同意使用 Zendesk 质量保证", _interpolate(_named("terms_of_service")), "和", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我有一个帐户"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过魔术链接注册"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用工作电邮注册"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 Slack 注册"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册 Zendesk 质量保证"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 Google 注册"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我有一个活跃的 Zendesk 帐户"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注册或登录，以完成 ", _interpolate(_named("direct_install_name")), " 设置"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是您本人吗？检查您的收件箱中是否有确认电邮。"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已完成设置"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证在桌面版中运行效果最佳。在此登录以开始考核对话。"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订演示"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者，预订我们的演示，我们将向您展示在 Zendesk 质量保证中可以执行哪些操作。"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敬请加入"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我改变了主意，想要 ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " 邀请您加入其帐户。"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入帐户"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新帐户"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次尝试登录"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您收到的反馈回应：", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的回应"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " 回应了您的评论"]), _normalize(["有几个人回应了您的评论"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " 回应了您的回复"]), _normalize(["有几个人回应了您的回复"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " 回应了您的考核"]), _normalize(["有几个人回应了您的考核"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查反馈"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回应"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发起"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将评论标为已读？"]), _normalize(["将所有评论标为已读？"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将争议标为已读？"]), _normalize(["将所有争议标为已读？"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将调查反馈标为已读？"]), _normalize(["将所有调查反馈标为已读？"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将考核标为已读？"]), _normalize(["将所有考核标为已读？"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已将评论标为已读"]), _normalize(["已将所有评论标为已读"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已将争议标为已读"]), _normalize(["已将所有争议标为已读"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已将调查反馈标为已读"]), _normalize(["已将所有调查反馈标为已读"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["已将考核标为已读"]), _normalize(["已将所有考核标为已读"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所有标为已读"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为已读"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有人对考核有争议时，相关争议将显示在这里。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有争议"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当指派您来解决争议时，相关争议将显示在这里。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到的争议"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当您对考核发起争议时，相关争议将显示在这里。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有发起的争议"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供给您的所有反馈都将显示在这里。尚无可显示的内容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到的反馈"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对您的考核和回复的所有回应都将显示在这里。尚无可显示的内容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到的回应"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开一段对话"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您提供的所有反馈都将显示在这里。首先在对话视图中提供考核。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有提供的考核"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供给您的所有反馈都将显示在这里。尚无可显示的内容。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到的考核"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到评论"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您作为评论提供的所有反馈都将显示在这里。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有给出评论"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到评论"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您拥有的一条考核下的所有评论、", "@", " 提及或对您的评论的回复都将显示在这里。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到评论"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有已接受的争议"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有未解决的争议"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有拒绝的争议"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有解决的争议"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从上面的筛选中选择另一个选项重试一次。"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从上面的筛选中选择另一个用户重试一次。"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的评论"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的评论"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有争议"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的争议"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发起的争议"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的调查反馈"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的考核"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换活动列表"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize(["已考核 ", _interpolate(_list(0)), " 条（共 ", _interpolate(_list(1)), " 条）"]), _normalize(["已考核 ", _interpolate(_list(0)), " 条（共 ", _interpolate(_list(1)), " 条）"]), _normalize(["已考核 ", _interpolate(_list(0)), " 条（共 ", _interpolate(_list(1)), " 条）"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 后重新开始"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["剩余 ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布置任务"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换任务列表"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["无法在 ", _interpolate(_list(0)), " 和 ", _interpolate(_list(1)), " 之间创建辅导课程，因为它们现在不在同一个工作区中。"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 和 ", _interpolate(_list(1)), " 之间的辅导课程已存在。请联系 ", _interpolate(_list(0)), " 以获取课程。"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已在 ", _interpolate(_list(0)), " 和 ", _interpolate(_list(1)), " 之间创建新会话。"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导者"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择辅导者"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被辅导者"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择被辅导者"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建会话"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建会话"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加会话"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话名称"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去的"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将开始"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建会话"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到会话"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均考核时间"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选时段无可显示的数据"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已将固定项目添加到时间。", _interpolate(_named("buttonStart")), "展示给我看", _interpolate(_named("buttonEnd")), "。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选时段的固定项目已结束"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在此", _interpolate(_named("link")), "中查看固定项目如何工作。"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您似乎尚未在 ", _interpolate(_list(0)), " 下固定任何内容。您可以使用固定项目来准备一对一辅导，或保存项目供下次使用。"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被辅导者"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未添加固定项目"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["以下是您在 ", _interpolate(_named("name")), " 中创建的所有固定项目。已添加到会话中的固定项目将与另一个参与者", _interpolate(_named("bold")), "。"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即共享"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["在所选时段内 ", _interpolate(_list(0)), " 似乎尚未收到任何关于对话考核或 CSAT（客户满意度调查）的反馈。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无反馈"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选时段的评论已结束"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指标"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 的数据"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建操作项目"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("user")), " 于 ", _interpolate(_named("date")), "完成"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["创建者：", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入以添加新操作项"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这次会议取得了什么成果？后续步骤是什么？"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作项"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 于 ", _interpolate(_named("createdDate")), "　创建"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除会话"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["这将永久", _interpolate(_named("deleteSession")), "。参与者无法获取会话，包括其内容和私密注释。"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["会话创建于 ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最后由 ", _interpolate(_named("name")), " 于 ", _interpolate(_named("updatedDate")), "编辑"])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交谈要点和注释"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新注释"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开会话"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选定用户此后可以访问会话详情。"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享会话"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您将不再参加 ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " 会话。"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 将不再参加此会话。"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " 现在可访问此会话。"]), _normalize([_interpolate(_named("n")), "现在新用户可访问此会话"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除会话"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有会话"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除会话"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导者"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 添加的注释将显示在此处。"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从右侧面板将固定项目添加为讨论点"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有您可以查看的注释..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人注释"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新私密注释"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 天"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导者"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被辅导者"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作项"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次会话"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过期"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话概览"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我：辅导者"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我：被辅导者"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有会话"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享会话"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尝试切换筛选器以查看操作项。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有操作项"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作项"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到并查看的考核"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到 · 已查看"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话后的进度"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员似乎尚未收到任何反馈。"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证用户名称是否正确"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到该用户"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用数据"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天的国际质量评分 (IQS)"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["这将永久<b>删除名为<b>", _interpolate(_named("name")), "</b>的所有会话</b> (2)。参与者将无法访问所有会话，包括内容和私密注释。"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已完成所有会话"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导课程是导师和被辅导者之间的团体活动，用于讨论业绩、机会、短期和长期目标。"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以通过辅导者的姓名和被辅导者搜索会话。"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到会话"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未创建会话"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除辅导记录"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除辅导记录 "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次会话"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有会话"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导者"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被辅导者"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为完成"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为撤消"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名称搜索"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换会话列表"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未计划"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复此消息"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准会话已过期。无法提供其他考核。"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已为此对话提供考核。"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法考核此对话，因为会话的截止日期已过。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话没有考核"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭面板"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供一条校准考核"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记录副本"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["说话人 ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 秒"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑扬声器"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核消息"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回以播放消息"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扬声器"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记录副本"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区用户"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先添加连接以创建筛选"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有智能机器人"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("agentName")), " 于 ", _interpolate(_named("time")), " 创建"])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("agentName")), " 于 ", _interpolate(_named("time")), " 最后更新"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您似乎没有筛选器。要访问此页面，首先需要创建一个筛选器。以 ", _interpolate(_named("recommendedFilter")), " 开始，查看您自己的对话。创建此筛选器以继续。"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“最近 30 天创建”的条件将添加到创建时不带日期范围的筛选器中"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此服务模式中不提供筛选器，显示结果时不会考虑筛选器"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抓取筛选选项超时。再试一次。"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于筛选的信息"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人 = 登录人员"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["找到的对话数量：", _interpolate(_named("count"))]), _normalize(["找到的对话数量：", _interpolate(_named("count"))]), _normalize(["找到的对话数量：", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用于当前服务模式。升级以使用此功能。"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加条件"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者：Zendesk 质量保证人工智能"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转为任务"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建筛选器"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建筛选器"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义字段"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在根据您的特定设置加载筛选选项。初始加载可能需要几分钟。"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您的筛选器提供一个描述性名称"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选器名称"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个选项"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私密"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑筛选器"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用值"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载…"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择值"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可见度"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅活跃的会话 ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示更早的会话 ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["截止日期"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑校准会话"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建会话"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建校准会话"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话期间进行的所有校准考核都将被永久删除。"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除会话"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除 ", _interpolate(_named("sessionName")), " 校准会话"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建会话"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活跃的会话"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建会话"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活跃的会话"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加筛选器"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建私有筛选器"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建公共筛选器"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加筛选器"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将永久删除筛选器。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除筛选器"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除 ", _interpolate(_named("filterName")), " 筛选器"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有争议"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于我的争议"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我提出的争议"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决的争议"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加筛选器"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建私密筛选器"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建公共筛选器"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选器"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私密筛选器"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共筛选器"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["想要", _interpolate(_named("cta")), "吗？"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是一个临时筛选器视图。"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在构建您的筛选器…"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新筛选器"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义自动质量保证类别"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最后更新于 ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复杂的语言"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动考核会根据工单更改动态更新"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对话中突出显示"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长句子"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未预测自动评分"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测任何错误"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示复杂的词语"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示长句子"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个对话"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于背景信息不足，AutoQA 无法评价此类别。建议进行手动审阅。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索自动对话见解，以简化您的考核流程。为您的团队突出显示关键对话，展示杰出的正面范例。"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[通话]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更正"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 已接受此争议"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["争议已发送至 ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解释为什么您认为此被考核者不应是此反馈的接收者"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论并设置新被的考核者"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解释为什么您认为这些评分不公平（必填）"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论（必填）"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解释为什么要拒绝此争议"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将争议发送给"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将争议发送给"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将争议发送给"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新的被考核者"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议反馈"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议反馈"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改被考核者"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将争议发送给"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对此考核有争议"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我不同意评分"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这条考核与我无关"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个用户"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 已部分接受争议"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 已拒绝争议"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 发起了一项争议：考核"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " 发起了一项争议：被考核者错误"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应解决未解决的争议，以便对考核执行其他操作。"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新评分"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除争议"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要放弃此争议吗？"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除争议"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议解决时间"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新的公正评分（可选）"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对考核提出争议"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者错误"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受争议"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受争议"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议反馈"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者错误"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议反馈"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝争议"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝争议"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看争议"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已编辑"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " 的执行者：", _interpolate(_named("author")), "，日期：", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["接收者：", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考核"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部完成"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配给："])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同时写一条评论"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于条件记分卡的信息"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["之前已分配给："])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任选一个"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到该名称的 GIF"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一个提供考核。"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅目前处于暂停状态。重新激活以提供反馈。"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布者："])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这显示了考核此对话花费的时间"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个用户"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["缺少以下类别的根本原因：“", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡："])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价一个类别或添加一条评论"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加…"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加表情符号"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开评论字段"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠评论字段"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏格式"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏面板"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示格式"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示面板"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价所有类别"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价至少一个类别"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存此对话供日后使用"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存以供日后使用"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个任务"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替换此任务周期中的对话"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核对话"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基准考核"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除评论"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除评论"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除该评论？"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论作者"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除或重命名此 ＃ 标签。"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享您喜欢的内容或可以改进的内容…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将永久删除所有评论。"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除评论"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[消息]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 尚未看到此反馈"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 已看到此反馈"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前成员"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索 GIF…"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按回车以创建新 ＃ 标签。"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义 # 标签已被管理员锁定。"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用它将个案加入书签，稍后筛选排除"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为基准"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息反馈"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个对话"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有反馈"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有完成的考核"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在下面提供考核以开始。"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有其他考核"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未评分"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一个受托人"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一个对话"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为基准移除"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复此考核"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏考核侧栏"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将永久删除考核和所有添加的评论。"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除考核"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除考核"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除考核"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 已删除考核"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部完成！"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将此任务重新分配给您，以便提供考核"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已经为此用户提供考核。"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您已为 ", _interpolate(_list(0)), " 提供考核"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话未显示任何类别。"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核不适用于该工作区。"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请勿保存"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否将此考核保存为草稿？"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放弃此草稿"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放弃"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示考核侧栏"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过，但包含小错误"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仍在计算中…"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写评论"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核此消息"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核自己"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核此对话"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可考核的用户"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核已禁用"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到用户"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区用户"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载…"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 条考核"]), _normalize([_interpolate(_named("count")), " 条考核"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择新考核"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的工作区经理不允许自我考核。"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按回车以标记此固定项目"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加星标"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建校准会话"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“见解”向您直观展示您的服务台数据。使用交互式图表了解应将质量保证工作的重点放在何处，发现盲区，并根据专员的业绩找到合适的专员进行考核。"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["翻译成", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在查看"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即考核"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话已移除，不再可用。"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您查找的此对话不在 Zendesk 质量保证中。"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含此对话的所有固定项目"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话中"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除对话"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从会话中移除对话将删除对其进行的所有考核。"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("sessionName")), " 中移除对话"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未创建校准会话。"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话已添加到会话中"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话已从会话中移除"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到校准会话/从校准会话中移除"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将链接复制到此对话"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的对话"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户的电邮"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户 ID"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户名称"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部表格名称"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一响应时间"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优先级"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查评论"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满意度评分"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次解决时间"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原始"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载…"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此消息不在此对话下或已删除"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无标题"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的对话"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到对话"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开原始对话"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法链接到对话 - 缺失 URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有参与者"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定对话"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击以阅读考核"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击以考核"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已通过浏览器扩展创建对话"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看客户满意度调查"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看对话"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带有负面情绪的消息"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带有正面情绪的消息"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻译为英语"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示原文"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除对话"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除对话是永久性操作。这个操作还删除了所有相关数据，例如来自 Zendesk 质量保证的调查、记录副本和考核，包括面板在内。", _interpolate(_named("lineBreak")), "您仍然可以在 Zendesk Support 或正在使用的其他服务台软件中访问此对话。"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除对话是永久性操作。它还将删除所有相关数据，例如来自Zendesk 质量保证的调查、记录副本和考核，包括面板。如果对话属于多个工作区，它将从所有工作区中删除。", _interpolate(_named("lineBreak")), "您仍然可以在 Zendesk Support 或正在使用的其他服务台软件中访问此对话。"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除对话"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 Zendesk 质量保证删除对话"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此对话因上报而未完成。前往", _interpolate(_named("optionsUrl")), "并验证工单标签。", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此对话因上报而未完成。和您的管理员讨论如何验证您的工单标签。", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接 > 高级选项"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解工单标签"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已关闭"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已开启"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待回应"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解决"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["受托人已更改为 ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " 宏被 ", _interpolate(_named("author")), " 应用"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["对话收到评价 ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["用户 ", _interpolate(_named("target")), " 已编辑对话"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["状态已更改为 ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["标记为 ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["团队已更改为 ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " 将对话暂停了 ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " 取消暂停对话"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人陷入循环，连续重复一条消息"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人重复"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人重复，即同一消息连续重复一次或多次"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到匹配的短语"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["普通专员处理该对话的效率比智能机器人高 ", _interpolate(_named("percent"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人通讯效率"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["智能机器人处理该对话的效率比普通专员高 ", _interpolate(_named("percent"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人通讯效率"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话缺少通知参与者将录音的必要声明，而这是实现合规性和透明度所必需的"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少录音披露"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["违反了 ", _interpolate(_list(0)), " 的服务级别协议 (SLA)。"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已违反 SLA"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA 规则的指标已超出目标时间"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户流失的迹象。客户正在考虑离开或明确表示离开。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发现客户流失风险"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["对话出现停顿，持续时间超过 ", _interpolate(_list(0)), " 秒的设置阈值"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 个实例"]), _normalize([_interpolate(_named("count")), " 个实例"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最长 ", _interpolate(_list(0)), " 秒"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户寻求更高级别的协助"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求上报"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户对得到的支持表示非常感谢或非常满意"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓越的服务"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户或专员明确请求跟进"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟进请求"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户表示不满"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面情绪"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话异常或非典型，反复讨论多次才得以解决"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检测到异常值"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户表示满意"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面情绪"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置为不适用"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最后由 ", _interpolate(_named("author")), " 于 ", _interpolate(_named("date")), " 编辑"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["操作者：", _interpolate(_named("author")), "，日期：", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加原因（可选）"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未检测到见解"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加连接"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将您的服务台连接到您的账户，并创建至少一个筛选来查看对话列表。"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的对话"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看工作区设置"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新于"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（一天后）"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（本月）"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（本周）"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按 ID 查找"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("searchQuery")), " 的搜索结果"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由其他人考核"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已评论"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最早"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随机"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近更新"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近回复"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未读"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由您考核"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入过程可能需要一些时间。完成后我们将向您发送电邮。"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质量保证正在导入您的对话"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["限制：", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击对话视图标题中的校准图标以添加。"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可报告的内容。请使用不同的筛选或排序选项重试。"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的异常值。"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找相关对话，单击标题中的星形图标，将其添加到书签中。"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试更改筛选器或邀请其他服务台专员以添加更多对话。"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有限制"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部注释"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在加载对话。可能仍缺少某些内容。"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个用户"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到用户"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区成员"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一个用户"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加对话"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加任何对话"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成的考核将显示在这里。请在 30 天内重新阅读、修改并提交。"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有考核草稿"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有已添加星标的对话"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的对话"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配项"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回到选择的消息"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的回复最少"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的回复最多"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换对话列表"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有用户"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 接听电话"])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 通话结束"])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 拨打电话"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考核"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由您考核"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["无法加载音频文件。确保您已登录 ", _interpolate(_list(0)), "。"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户满意度调查评论"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载音频时出错"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论作者"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未进行通话"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在服务台中侦听"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Aircall 中接听"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回放速度"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核通话"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记录副本"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是 Zendesk 质量保证用户"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证根据专员的每月平均 CSAT 自动对其进行排名。一键查找其 CSAT 低于同事的专员所处理的对话。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据 CSAT 排名"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是 Zendesk 质量保证用户"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一张工单"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理量"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证会根据专员每月处理的对话数量，自动将专员分为几个业绩组。一键查找其业务数量低于同事的专员所处理的对话。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按处理量排名"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已审阅"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核状态"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中性"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是 Zendesk 质量保证用户"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证会根据对话中的平均情绪自动对专员进行排序。一键查找专员处理的不满意客户数量比同事多的沟通。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按情绪排序"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未检测到"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已检测到"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["异常值"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要您注意的对话。我们的机器学习模型可识别复杂或反常的沟通，以供进一步审查。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["异常值"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择客户表示愉快或不满的对话进行考核。使用下拉菜单选择您要在对话中查找的情绪类型。将筛选属性拖到顶部，以查看所选情绪在您的所有沟通中所占的比率。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核状态"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考核"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考核"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证会自动检测对话中的语言。使用下拉菜单选择特定语言的对话进行考核。将筛选属性拖到顶部，获得您的服务台使用的所有语言概览。"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话见解"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与使用常规对话筛选相比，使用“见解”您可以", _interpolate(_named("paragraph_2_item")), "。获取来自服务台的所有数据的可视化概览，并浏览以下见解："])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解如何使用对话见解"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解特定对话属性相对于其他属性的相对重要性"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用内置专员业绩排名查找业绩最佳和业绩欠佳的专员"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " 是一个功能强大的交互式数据挖掘工具，可帮助您查找最需要考核的对话。"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行更多操作"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["对话图表和专员图表", _interpolate(_named("paragraph_3_item_1")), " – 更新筛选器或单击任一筛选器上的细分图表切片将自动更新对话列表。", _interpolate(_named("paragraph_3_item_2")), " 以探索不同元素的详细细目或比率。"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已互联"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新排列筛选器属性"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["对话见解仅显示", _interpolate(_named("paragraph_4_item")), "。我们会自动移除没有实际考核价值的沟通，例如自动生成的消息、垃圾信息、智能机器人回复，或与您的支持代表没有合适对话的消息。"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清洁和完结的对话"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我在这里能看到什么？"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("count")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("count")), " 个（共 ", _interpolate(_named("total")), " 个）"])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " 的 ", _interpolate(_named("percentage"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法抓取"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 不适用"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["（占总数的 ", _interpolate(_named("percentage")), "）"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新加载"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["但我们相信一定能获得理想的分数。"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义调查"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有留下的调查反馈"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有可用的调查反馈评分…"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈标签"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈评论标签"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已应用日期范围筛选器"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 条回复"]), _normalize([_interpolate(_named("count")), " 条回复"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["通过在调查设置下设置原因，深入了解给出 ", _interpolate(_list(0)), " 评分的原因。"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["尚无 ", _interpolate(_list(0)), " 原因"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解调查原因"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带有工单标签的对话："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 分类"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论类别"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " 原因"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["预测的 ", _interpolate(_named("surveyType")), " 推动因素"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话来源"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["调查回复 (", _interpolate(_named("n")), ")"]), _normalize(["调查回复 (", _interpolate(_named("n")), ")"]), _normalize(["调查回复 (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无标题"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["类型为 ", _interpolate(_list(0)), " 的对话"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（按用户分类）"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["响应率"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 评分是所有回复总值除以最高评分总值"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑目标"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除目标"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设定目标"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加目标"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很长"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中等长度"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令人困惑"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有帮助"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持欠佳"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持良好"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有表情符号"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给专员的反馈"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投诉"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["零碎"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题未解决"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题已解决"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面情绪"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["效果不好"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面情绪"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赞扬"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品不佳"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持迅速"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持不及时"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk 质量保证会自动将您的 ", _interpolate(_list(0)), " 数据分配到预定义的类别。这样您就可以理解复杂的见解并探究原因，而无需手动筛选书面反馈。"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有评分"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有调查"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择回复语言"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论长度"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择评论长度"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中 25% 的评论只包含一个词，较长的评论为必读内容。无需手动操作即可找到更有见地的反馈，因为我们会自动为您完成。"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈原因"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择反馈原因"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的评分"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预测的推动因素"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择评论类别"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字云词语"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文字云词语"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["从上一个时段以来 ", _interpolate(_list(0))])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 个对话"]), _normalize([_interpolate(_named("n")), " 个对话"]), _normalize([_interpolate(_named("n")), " 个对话"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于缺少数据，无法计算所有对话中的调查发出比率。"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发出的调查"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查漏斗图"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["预测的 ", _interpolate(_named("surveyType")), " 推动因素"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk 质量保证会自动将您的 ", _interpolate(_list(0)), " 数据分配到预定义的类别。这样您就可以理解复杂的见解并探究原因，而无需手动筛选书面反馈。"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论长度"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中 25% 的评论只包含一个词，较长的评论为必读内容。无需手动操作即可找到更有见地的反馈，因为我们会自动为您完成。"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查回复"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复数量"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均值"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 趋势分类"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复数量"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推动因素"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 调查发送日期"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 调查回复日期"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["将 ", _interpolate(_list(0)), " 评分分配给"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据显示依据"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话创建日期"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单所有者"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复最多的专员"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "（平均）"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 一段时间内的变化"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复数量"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 和 IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复数量"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含这些词语的对话："])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字云"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（按用户分类）"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 概览"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查漏斗图"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查回复"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一段时间内的 CSAT"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字云"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有渠道"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有来源"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有语言"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有原因"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工单标签"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有评论类别"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工作区"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无可用数据，因为未创建分配或未开始周期。稍后再查看或调整筛选器。"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索新功能"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个项目"]), _normalize([_interpolate(_named("count")), " 个项目"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个对话"]), _normalize([_interpolate(_named("count")), " 个对话"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过，但包含小错误"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过/未通过"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均值"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（小时）"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均（分钟）"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " 评价"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均 CSAT = [反馈评分总值 ÷（所有回复数 × 可能的最高评分）] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证 CSAT 评分将您的反馈评分按 0-100% 进行标准化计算，取标准化评分的平均值。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度评分）对客户对于您的服务的满意度进行量化。"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "每条考核代表一个人考核一段对话。一段对话可以有多条考核（如果有两个或更多考核者考核同一段对话）。"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟踪一段时间内考核者评分的变化。"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟踪专员的评分随时间的变化情况。"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 条响应"]), _normalize([_interpolate(_named("n")), " 条响应"]), _normalize([_interpolate(_named("n")), " 条响应"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 条考核"]), _normalize([_interpolate(_named("n")), " 条考核"]), _normalize([_interpolate(_named("n")), " 条考核"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核："])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（内部质量评分）是对话考核的结果。"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = 考核评分总值 ÷ 考核数量"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以平均值计算，将考虑所有与选定筛选条件匹配的考核评分（类别评分的加权平均值）。"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载卡片。"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改筛选以重试。"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过率反映了对话考核的结果。"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果考核评分为 ", _interpolate(_named("failScore")), " 或更低分，则失败"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果考核评分为 ", _interpolate(_named("passScore")), " 或更高分，则通过"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["它由预定义的基准确定，这意味着您："])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过率 = 通过的考核总数 ÷ 考核总数"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过率是指通过且不在严重或不严重类别中的考核数量所占的百分比。"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["例如：如果考核者在这一天提供了 3 条考核，则显示的数字是 3 个评分的平均值。查看每个单元格的计算方法 ", _interpolate(_named("link")), "。"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["例如：如果专员在这一天收到 3 条考核，则显示的数字是这 3 次评分的平均值。查看每个单元格的计算方法 ", _interpolate(_named("link")), "。"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考虑：类别权重、重要状态（与一段时间内的评分不同）。"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于可在考核中将类别标为“不适用”，因此每个类别的考核数量可能低于考核者提供的考核总数。查看每个单元格的计算方法"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考虑：类别权重、重要状态（与一段时间内的评分不同）。"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于可在考核中将类别标为“不适用”，因此每个类别的考核数量可能低于每个专员的考核总数。查看每个单元格的计算方法"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考虑：类别权重、重要状态（与一段时间内的评分不同）。"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " 类别组单独显示。每个组的评分是根据该组在同一时段内的平均类别评分计算得出的。查看每个单元格的计算方法 ", _interpolate(_named("link")), "。"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注释："])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考虑：类别权重、重要状态（与一段时间内的评分不同）。"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " 类别组单独显示。每个组的评分是根据该组在同一时段内的平均类别评分计算得出的。查看每个单元格的计算方法 ", _interpolate(_named("link")), "。"])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["基准 ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无严重错误"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无严重错误率表示通过且不在严重类别的考核数量所占的百分比。"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部质量评分"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“其他”根本原因"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期更改"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日更改"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月更改"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周更改"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年更改"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一段时间内的评分"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以某天或某周为单位查看。"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算方法：一天或一周的总评分除以考核总数"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以某天或某周为单位查看。"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算方法 = 某天或某周的总评分除以收到的考核总数"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按类别评分"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看在此期间内考核者给出的所有类别评分的综合平均值。"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看每个专员在此期间收到的所有类别评价的综合平均值。"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价低的根本原因"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价的根本原因"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一段时间内的类别评分"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析各个类别在一段时间内的比较情况。"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所选日期或星期每个类别的平均评分。"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析各个类别在一段时间内的比较情况。"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所选日期或星期每个类别收到的平均评分。"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按考核评分"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["严重类别"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价量表"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均/总计"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除自我考核"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出所有卡片"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["个对话"]), _normalize(["个对话"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["个项目"]), _normalize(["个项目"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " 条响应"]), _normalize([_interpolate(_named("n")), " 条响应"]), _normalize([_interpolate(_named("n")), " 条响应"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 条考核"]), _normalize([_interpolate(_named("n")), " 条考核"]), _normalize([_interpolate(_named("n")), " 条评论"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["再显示一个"]), _normalize(["再显示 ", _interpolate(_named("n")), " 个"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 7 天"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上个月"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上周"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义时段"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季度"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载卡片。更改筛选以重试。"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的数据。"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月更改"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多信息。"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的活跃筛选包括不同评价量表的数据。"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用这些筛选器的所有卡片固定项目"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用这些筛选器的所有面板固定项目"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均考核时间"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载 CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有类别"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别见解"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图形"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["评论"]), _normalize(["评论"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["矩阵"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前成员"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部质量评分"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS（平均）"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过的考核"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定卡片"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定面板"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["个根本原因"]), _normalize(["个根本原因"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核 ID"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核时间"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定根本原因"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因可让您了解对话评分较低的原因"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此类别未确定根本原因"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分/总计"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别组"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别组"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义顺序"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组类别划分依据"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择所有"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除自我考核"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括自我考核"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅显示自我考核"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选条件"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到 # 标签"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论 # 标签"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择评论 # 标签"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工单标签"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索服务台工单标签"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到记分卡"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择记分卡"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的连接"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话来源"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择来源"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多筛选器"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的工作区"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总次数"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有用户"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个人"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有组"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的组。"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有组"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示卡片"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据显示依据"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核创建日期"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话创建日期"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示两位小数"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看设置"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整个帐户"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区名称"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工作区"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旗帜"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食品和饮料"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动物和自然"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对象"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人员和身体"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅游和地点"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常用"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索结果"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["笑脸和表情符号"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符号"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到表情符号"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开一段对话"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回任务列表"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " 或浏览您的 ", _interpolate(_named("link")), " 列表"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您目前正在使用未连接到此域名的 ", _interpolate(_named("workspace")), " 工作区。单击此处立即连接。"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用连接"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您现在无法在 ", _interpolate(_named("host")), " 上提供反馈。"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将您的服务台设置为活跃标签。"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还无法考核此域名。如果应该可执行此操作，请通知您的管理员。"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加此域名"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["连接 ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍等，我们正在为您设置"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法获取 URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经与组织的 Zendesk 质量保证帐户连接的工具。"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工具与组织的 Zendesk 质量保证帐户连接。"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过在提供考核之前在末尾附加一个唯一哈希值，允许您考核 URL 始终保持不变的网站"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换帐户"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的域名"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经与此工作区连接的域名。"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可考核以下域名的任意页面"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可供考核的域名"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扩展"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["唯一 URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["尚未连接服务台，请转至 ", _interpolate(_named("link")), " 添加一个。"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证浏览器扩展程序仅适用于\nProfessional 和 Advanced 服务模式。"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换工作区"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人："])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供一个名称"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供一个值"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义字段"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是我们第一次遇到此对话。请提供一些详情。"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话参考："])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["便于稍后识别工单的资料"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供受托人和标题"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在创建"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核此对话"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未分配"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["联系您的经理或从", _interpolate(_named("settings")), "中切换到另一工作区。"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作区已禁用自我考核。"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再见"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法考核此 URL。导航到对话。"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先通过您的 SSO 提供程序登录 Zendesk 质量保证，然后再试一次。"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 SSO 登录"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开登录弹出窗口。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过您的 Zendesk 质量保证帐户登录"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍等..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在进行身份验证…"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有帐户？"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权访问此对话。请联系您的经理。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法访问"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk 质量保证中检查您的连接是否已激活"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理您的连接"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接错误"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导航到服务台中的对话以继续"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的 URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新连接即将完成。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍等片刻"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试重新加载页面，以恢复正常流程"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出错"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打破服务台的边界，随时随地考核对话。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级您的订阅以使用此扩展"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理您的订阅"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台连接未链接到此工作区"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的服务台无法识别此工单，或 Zendesk 质量保证无权访问"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到对话"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新活动"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到了一些反馈"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录成功"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您现在可以使用该应用"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价所有类别"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用它将个案加入书签，稍后筛选排除"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载…"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此对话没有反馈"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读反馈"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk 质量保证中打开"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里将显示所有反馈和通知。还没有内容……"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk 质量保证中查看更多"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话详情"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含所有"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是所有"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含所有"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是所有"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高于专员"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低于专员"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大于"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含任意"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是任意"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含任意"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不属于任意"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["违反"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已评论"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我评论"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未评论"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我并未评论"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对考核至关重要"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在范围内"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自从"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 14 天"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 24 小时"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 7 天"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上个月"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上周"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直到"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已检测到"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存在"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我添加星标"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我没有添加星标"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大于"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始于"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小于"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直到"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未违反"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不复杂"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未检测到"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不存在"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未查看"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未考核"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非由我考核"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考核"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由我考核"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对考核至关重要"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大于"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始于"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小于"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直到"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包含"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已查看"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的评论收到回复"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义类别"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义突出显示"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索条件"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指标"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核和反馈"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示所有条件"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将智能机器人的对话处理效率与普通专员进行比较"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人陷入循环，连续几轮重复相同的消息"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中的智能机器人回复次数"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与对话的智能机器人类型"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与对话的智能机器人"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["对话内容在专员消息中包含短语 ", _interpolate(_list(0))])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动识别缺少强制披露声明的通话，例如“此通话将被录音”等类似声明。"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与对话的人员或智能机器人"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中接受考核的专员或智能机器人"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户寻求更高级别的协助"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对话中检测到正面和负面情绪"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过人工智能分析识别对话中最活跃的专员"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否礼貌地结束了对话"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否理解并回应客户的感受"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员的语法、拼写和风格错误"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中的典型问候语"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否理解客户的询问或疑虑"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析理解文本的难易程度，考虑词语复杂度和句子长度"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中提供的解决方案"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员在整个对话过程中的语气"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户流失的迹象。客户正在考虑离开或明确表示离开。"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话所属的组。"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员消息的内容或文本"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员在对话中回复的数量"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配的考核对话的专员"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通话对话持续的时长（以秒为单位）"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的内容或文字"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话所用的通讯渠道（例如电邮、在线交谈）"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加到对话的评论"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将评论添加到对话的日期"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对话中检测到正面和负面情绪"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户回复客户满意度调查的日期"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话考核是否有争议"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户发起对话所用的渠道"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中的客户回复数量"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中最后一条消息的日期"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中涉及的用户角色或类型"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员和客户之间的停顿已超出阈值"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户寻求更高级别的协助"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户对得到的支持表示非常感谢或非常满意"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户或专员明确请求跟进"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整个语音对话的文本或录音"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话是否标为星标"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论中使用的 # 标签用于对内容进行分类"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能检测到的对话语言"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中最后一条消息的日期"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中的私密注释数量"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中每条消息的平均字符数"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中面向客户的回复数量"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其信息公开显示的参与者"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中每条消息的平均字数"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于评价或评估对话的类别"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话考核流程的状态"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的参与者或相关人员"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的电邮"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核是否已被查看"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估对话的考核者"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次考核对话的日期"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中接受考核的专员"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户提供的关于其对于对话满意度的任何特定评论或反馈"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户对于对话的满意度评分"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中每条消息的客户满意度评分"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于考核对话的记分卡"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["违反了给定 SLA"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发起对话的来源或连接的类型"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话异常或非典型，反复讨论多次才得以解决"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话内容相关的调查问题"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的调查的标题或名称"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配的考核对话的专员"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的品牌或特定产品/服务"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话所用的通讯渠道（例如电邮、在线交谈）"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完结或解决对话的日期"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话首次创建日期"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话首次创建日期"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户回复客户满意度调查的日期"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的简要描述或概要"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的外部参考 ID"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整理对话的文件夹或特定分组"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于构建对话的表格或模板"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的外部参考 ID"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收对话所用的邮箱或电邮帐户"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与对话关联的电话号码"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配给对话的优先级"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中回复的总数"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的状态或当前状态"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的当前状态"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的标题或主题"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中专员回复的数量"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配的用于进行对话分类的标签"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话的类型或类别"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话最后更新的日期"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话最后更新的日期"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行对话的媒介或渠道"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话中的转录内容"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不符合通过人工智能分析进行考核的条件的对话"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与对话的专员数量"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否已查看对话"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任意消息"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人通讯效率"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人重复"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人回复计数"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人类型"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通话方向"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户端消息"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少录音披露"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上报"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通话转录内容"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结语"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已加星标"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不符合考核的条件"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话渠道"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼写和语法"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员消息"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活跃的专员"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员回复数量"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通话持续时间（秒）"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话内容"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息渠道"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论工单标签"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论日期"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 调查回复日期"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户渠道"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户回复数量"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后回复日期"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户类型"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问候语"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户流失风险"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上报"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓越的服务"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟进"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记录副本"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论 # 标签"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["异常值"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后回复日期"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话私密注释数量"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均字符数"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均字数"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开回复数量"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开参与者"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价日期"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价和评论"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核状态"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可读性"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关电邮"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复数"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考核的记分卡"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核查看状态"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核日期"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查评论"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每条消息的 CSAT"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满意度评分 (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的解决方案"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源类型"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查问题"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查标题"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话品牌"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌域名"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话渠道"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完结日期"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 调查回复日期"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 调查发送日期"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话描述"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部 ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话字段"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话文件夹"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话表格"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话组"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部 ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话邮箱"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话优先级"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话回复数量"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话状态"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话状态"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话标题"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员回复数量"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话类型"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日期"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语气"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转录内容"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与专员的数量"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已查看状态"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漏洞功能"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漏洞状况"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漏洞生命事件"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证类别"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个与日期相关的条件"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一个名称到您的筛选器"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写筛选器名称"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已登录人员"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前工作区成员"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区成员"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["添加 ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用筛选器"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您应至少有 1 个与日期相关的筛选器"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择选项"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个选项"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于 ", _interpolate(_named("n")), " 天后结束。", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于 <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " 后结束</strong></a>。"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于 ", _interpolate(_named("n")), " 天后结束。", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于 <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " 后结束</strong></a>。"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于今天结束。", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的试用将于<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>今天</strong></a>结束。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即设置订阅"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " 天"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用期还剩 ", _interpolate(_named("days")), " 天"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到服务模式的用户限制。", _interpolate(_named("link")), " 了解更多信息或升级服务模式。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击此处"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接服务台"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这使我们能提取工单供您考核。"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接服务台"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您正在通过示例数据使用 Zendesk 质量保证。要删除示例数据并导入您自己的对话，请", _interpolate(_named("link")), "。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["示例数据"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制下面的实用信息"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行整个页面的屏幕截图以提供背景信息"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请联系我们的客户支持团队以解决此问题："])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出错"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误参考："])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制实用信息"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户、智能机器人和工作区"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户和工作区"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("period")), " 已考核 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("period")), " 已考核 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize([_interpolate(_named("period")), " 已考核 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增功能"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["键盘快捷方式"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和我们在线交谈"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载我们的在线交谈（您是否使用了广告拦截程序？）"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳转到…"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的考核"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知识库"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换帐户"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已更新应用程序。", _interpolate(_named("buttonStart")), "重新加载", _interpolate(_named("buttonEnd")), "。"])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您想在工作区中开始使用 Zendesk 质量保证："])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["谨以此通知您，您已收到在一个工作区中使用 Zendesk 质量保证的邀请。如果您愿意，当然可以继续，但请注意，这将为您创建一个", _interpolate(_named("bold")), "。"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单独的组织"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["是，我要通过", _interpolate(_named("link")), "继续。"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新帐户"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定吗？"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), "邀请您加入", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无网络连接"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新固定项目"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速导航"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聚焦并展开评论编辑器"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏或显示侧面板"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导航到下一个对话"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开原始对话"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导航到上一个对话"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为所有类别提供尽可能高的评价"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为所有类别提供尽可能低的评价"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新排列对话（随机重新排序）"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告诉我们"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["键盘快捷方式"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换快捷方式模式"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到 ", _interpolate(_named("charLimit")), " 个字符数的限制"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不支持图像 ", _interpolate(_named("name")), " 类型 (", _interpolate(_named("type")), ")"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["图像 ", _interpolate(_named("name")), " 大小过大（允许 5MB）"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载评论编辑器失败"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖放上传"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图像"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文本"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理模板"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加模板"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加根本原因"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他，包括删除的原因"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价原因"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["员工数"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将与您联系，帮助您设置帐户"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司联系号码"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您第一次从哪里了解到 Zendesk 质量保证？"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择频道"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择多个专员"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写所有必填字段"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须同意阅读并同意我们的条款和隐私政策。"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持推动的 Slack 频道"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动日志"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友或同事"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台市场或客户经理"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引用网站"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索引擎（Google、Bing 等）"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交媒体"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向我们介绍一下您自己和您的公司。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户详情"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已准备就绪。"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解应用，删除和添加内容。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建帐户。接下来做什么？"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备好导入实际对话了吗？"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加自己的数据"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始探索"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用示例数据尝试"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这样我们就可以开始提取工单供您考核。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接服务台帐户"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我改变了主意。"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消注册"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已创建固定项目。", _interpolate(_named("buttonStart")), "转至固定项目", _interpolate(_named("buttonEnd")), "。"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导固定项目适用于 Professional 服务模式。"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级服务模式"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有服务模式"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存注释，为下次一对一对话或辅导会话做准备。"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导的固定项目"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论或通过 ", "@", " 添加其他人"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除此对话"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新固定项目"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建固定项目"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑固定项目"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目的工作方式"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有查看此对话的权限"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定卡片"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定对话"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定面板"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存的固定项目"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定此卡片"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定此对话"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定此面板"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定在用户下面"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择一个用户"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有您可以查看您的固定项目。要与他人共享，请在评论中 ", "@", "提及他们。"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " 已受邀加入此对话"]), _normalize([_interpolate(_named("agentsNames")), " 已受邀加入此对话"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 条评论"]), _normalize([_interpolate(_named("n")), " 条评论"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["您提到了 ", _interpolate(_named("participants")), "，但他们不在此对话中。"]), _normalize(["您提到了 ", _interpolate(_named("participants")), "，但他们不在此对话中。"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 位参与者"]), _normalize([_interpolate(_named("n")), " 位参与者"]), _normalize([_interpolate(_named("n")), " 位参与者"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您要移除 ", _interpolate(_named("agentName")), " 以阻止访问此固定项目吗？"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不进行任何操作"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不邀请他们加入此对话"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加评论"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请他们"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请他们加入此对话"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除用户"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讨论串"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可撤消。"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这还将删除此固定项目下的所有评论。"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 条新消息"]), _normalize([_interpolate(_named("n")), " 条新消息"]), _normalize([_interpolate(_named("n")), " 条新消息"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有您可以查看您的固定项目。要共享固定项目，请在评论中 ", "@", " 某人。"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " 已固定 ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注释"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级以获得辅导固定项目"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导固定项目适用于 Professional 服务模式。"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导固定项目适用于 Professional 服务模式"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级以获取功能"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为一对一对话做准备，或保存项目供日后使用。"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将永久删除固定项目和所有添加的评论。"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除固定项目"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除固定项目"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件夹名称"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定稍后使用的内容"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新的在前"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最旧的在前"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在一对一对话准备过程中保存您感兴趣的面板趋势或对话，也可以记录下来供日后使用。"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击上面的按钮或单击对话或面板视图中的固定项目图标，创建一个固定项目。"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建固定项目"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["没有“", _interpolate(_list(0)), "”的结果"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试使用不同的关键字或检查有无拼写错误。"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新固定项目"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人员"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按用户、关键字搜索…"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按字母顺序"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近创建"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近编辑"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多固定项目"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["我的目标是 ", _interpolate(_named("schedule")), " 考核 ", _interpolate(_named("n")), " 个对话"]), _normalize(["我的目标是 ", _interpolate(_named("schedule")), " 考核 ", _interpolate(_named("n")), " 个对话"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新目标"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置目标"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以针对要考核的对话数量设置个人目标。"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个月"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核目标"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载频道"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择渠道"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅共享带评论的调查反馈"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强调同事卓越的服务质量和良好的客户反馈，为同事赋能。"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将正面调查反馈共享到 Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到渠道"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动分享评分较高的反馈"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praise 智能机器人整合"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您未进行任何考核，将在每周开始时收到提醒。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提醒"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周考核提醒"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收关于 Slack 中的新考核、回复、提及和争议的通知。"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提及"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讨论串回复"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就新的考核、回复、提及和争议进行通知。"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我相关的争议"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论中的提及"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的新考核"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讨论串中的回复"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含有统计数据的报告"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收关于提供的和收到的考核的报告。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每 3 小时"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每 6 小时"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帐户管理员已锁定通知设置。"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择时段"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人通知"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "（活跃的）"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此帐户中添加新的社交登录："])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除活跃的登录方式将导致您退出。您可用备用电邮重新登录。"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除登录方式"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功将 Google 添加为登录选项"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录选项"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功将 Slack 添加为登录选项"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定吗？"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方法"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将向 ", _interpolate(_named("email")), " 发送一封电邮，以重置密码"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要电邮"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查您的收件箱中有无确认电邮"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔术链接"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要更改此设置，请事先更改您的 ", _interpolate(_named("link")), "。"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["电邮 ", _interpolate(_named("email")), " 将与此密码关联。"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新个人资料"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料图片"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了获得最佳效果，请上传一张方形照片（256x256 像素或更大）"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要电邮"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一周的开始"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人设置"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择至少一个选项"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板内容"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示模板的位置"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加模板内容"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加模板名称"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建模板"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建模板"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建评论模板以快速考核、固定项目和辅导。"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除模板"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否永久删除此模板？"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑模板"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建评论模板以快速考核、固定项目和辅导。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建模板"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建评论模板"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除评论模板"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存评论模板"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核和争议"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要获得访问权限，请联系", _interpolate(_named("link")), "。"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支持"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到帐户"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["现在，使用 ", _interpolate(_named("link")), " 访问 Zendesk 质量保证。"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回到 Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户未迁移"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人质量保证"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指标"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计数据"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选器"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提供"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的评论"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的争议"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的考核"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回应"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已开始"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查反馈"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定项目"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凭证"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核目标"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建模板"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐单结算"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["席位"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新类别"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到冲突的消息"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改或移除一条冲突的消息"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["使用 ", _interpolate(_named("placeholder")), " 图标从自动质量保证中排除敏感数据。例如，不使用“请多保重，John”，而是使用“请多保重，", _interpolate(_named("placeholder")), "”来表示任意名称。"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["最大值 ", _interpolate(_named("limit")), " 行"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用规则至"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到 10 个自定义类别的限制"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到 ", _interpolate(_named("limit")), " 个自定义类别的限制"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加条件"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加行"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["且"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除条件"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果专员消息"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否则评分"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["则评分"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建记分卡类别"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用“/”添加变量"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有方向"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员消息不能为空"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息不得超过 300 个字符"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文本"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有来源"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有渠道"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工单标签"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有对话"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缩小规则的适用范围。"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定对话"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 Spotlight 见解"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员消息不能为空"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到 10 个自定义见解的限制"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已达到 ", _interpolate(_named("limit")), " 个自定义见解的限制"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写见解名称"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解名称"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建类别"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解类型"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中性"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转到工作区设置"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在不要"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要开始使用此评价类别，请转至工作区记分卡设置，并将其添加到所选的记分卡中。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建评价类别"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过识别特定的关键字或短语，自动为已关闭的对话评分。"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用于语音转录。"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知道了"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["恭喜！ ✋ 您已超出 ", _interpolate(_named("limit")), " 的自动考核类别。您真是分类高手！ 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已达到类别上限"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保所有字段填写正确"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为评价类别命名"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别名称"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新评价类别"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新评价类别"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过识别特定的关键字或短语，自动为已关闭的对话评分。不适用于语音转录。"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过识别特定的关键字或短语，自动显示已关闭的对话。仅适用于记录副本。"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过识别专员和智能机器人消息中的特定关键字或短语，自动为对话评分。"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要使用此评价类别，请转至工作区记分卡设置，并将其添加到记分卡中。"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据消息中的特定关键字或短语自动为对话评分。"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用于"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见解设置"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此见解根据已定义的标准显示在 Spotlight 中。"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡设置"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可根据定义的标准在记分卡中使用此类别。"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线交谈"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将推出"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定义"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语音"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改已保存"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示："])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击 ", "{··}", " 图标以在自动质量保证的结尾检查过程中排除姓名、公司详情或任何其他敏感信息。"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加批准的结语"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑批准的结语"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的结语"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加豁免"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑豁免"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豁免"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加批准的问候语"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑批准的问候语"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的问候语"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将词语粘贴在此处，用换行符隔开"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入每个字词后按回车以创建新条目"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用语言"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如，无需使用“请多保重，John”，您可以使用“请多保重，", "{··}", "”来表示任意名称。"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["动态内容占位符"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豁免"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已添加 1 项豁免"]), _normalize(["已添加 ", _interpolate(_named("count")), " 项豁免"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已添加豁免"]), _normalize(["已添加豁免：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加豁免"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到豁免"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加豁免"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豁免（区分大小写）"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["添加豁免"]), _normalize(["添加 1 项豁免"]), _normalize(["添加 ", _interpolate(_named("count")), " 项豁免"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索字词或短语"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豁免已保存"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有语言"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索语言"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已添加结语"]), _normalize(["已添加结语：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加结语"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到批准的结语"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有批准的结语"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结语"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加结语"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索结语"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加结语"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存结语"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["表格存在重复条目：", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已添加问候语"]), _normalize(["已添加问候语：", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加问候语"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到批准的问候语"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有批准的问候语"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问候语"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加问候语"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索问候语"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加问候语"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已保存问候语"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加豁免"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["删除豁免"]), _normalize(["删除豁免"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["是否永久删除此豁免？"]), _normalize(["是否永久删除这些豁免？"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑豁免"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["一项或多项豁免的长度超过 ", _interpolate(_list(0)), " 个字符"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), " 个字符（最多 ", _interpolate(_named("maxPhraseLength")), " 个）"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新于"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变量"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据您的品牌和服务标准，确定您的总结用语。添加后，自动质量保证将仅检测已批准的结语。所做的更改将应用于以后的对话。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到 100 个批准结语的上限。如果您想添加更多，可能需要重新审视并优化现有的结语。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已达到批准的结语上限"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的结语"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您希望自动质量保证忽略为拼写或语法错误的字词和短语。所做的更改将应用于以后的对话。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略列表"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定与您的品牌和服务标准保持一致的问候语。添加后，自动质量保证将仅检测批准的问候语。所做的更改将应用于以后的对话。"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到 100 条批准问候语的上限。如果想添加更多问候语，可能需要重新审视并优化现有的问候语。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已达到批准的问候语限制"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的问候语"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在不要"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久删除此评价类别？"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换类别列表"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除类别不会显示在任何新对话和筛选中。面板和对话中的历史数据将保持不变。"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该类别将从添加了它的所有记分卡中移除。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除记分卡类别失败"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡类别已删除"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除记分卡类别"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证类别"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "由人工智能模型提供技术支持，将保留在记分卡上，但不再自动评分"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭 LLM 整合不会完全关闭自动质量保证功能。以下是将会发生的情况："])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史数据"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "将保留在自动质量保证面板上"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动添加的根本原因"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), "将保留，但将移除所有基于 LLM 的根本原因"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭基于 LLM 的自动质量保证"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证使用人工智能和大语言模型 (LLM) 对特定类别自动评分。您的数据将根据严格的数据处理协议 (DPA) 进行处理，绝不会用于训练任何模型。"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于生成式人工智能的信息"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自动质量保证使用 Microsoft Azure OpenAI 服务", _interpolate(_named("automatically_scoring_categories")), "。按照严格的 DPA 处理您的数据，仅为了向您提供自动质量保证服务，而", _interpolate(_named("never_used_for_training_models")), "。"])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动为某些类别评分"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绝不用于训练任何 OpenAI 模型"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于 LLM 的自动质量保证已开启"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于 LLM 的自动质量保证已关闭"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于 LLM 的自动质量保证"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在自动质量保证中使用人工智能自动评分"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于 MS Azure 的自动质量保证类别的数据处理"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已撤回 Microsoft Azure 同意"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得 Microsoft Azure 同意。对话将很快收到自动评价。"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您仍然可以使用自动质量保证，但可能发生以下情况"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁用 MS Azure OpenAI 服务不会关闭自动质量保证功能。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在记分卡类别视图中进行此操作。"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由模型提供技术支持的自动质量保证类别将保留在记分卡上，但不会自动评分。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您手动添加的根本原因将保留。所有由模型生成的根本原因都将被删除。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史数据保留在自动质量保证面板上。"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 MS Azure OpenAI 服务禁用数据处理"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证类别范围更广。"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开箱即用的多语言支持。"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速添加新类别。"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提高了评估的准确性。GPT-3.5 显示了人类级上下文感知。"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益于人工智能技术的最新进展。"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将获得："])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 MS Azure OpenAI 服务启用数据处理"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未同意"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有新用户都已分配到此工作区"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认工作区"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 小时制 (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 小时制 (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间格式"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期一"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期日"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新公司资料"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一周的开始"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义整合"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问密钥 ID 和机密密钥通常可在“访问管理 > 用户”中找到"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 访问密钥 ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如 AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消更改秘密访问密钥"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改秘密访问密钥值"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该连接允许仅定义一个区域，该区域通常可在“位置”下找到"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 区域"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如 eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加秘密密钥"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 秘密访问密钥值"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如 wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在 Amazon Connect 中找到所有相关信息。"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常可在 Amazon Connect 实例设置和数据存储空间页面找到"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通话录音的存储位置"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如 amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 Amazon Connect 时出现错误。检查是否正确填写所有字段，并重试。"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存储位置应采用存储段/文件夹格式，用斜杠隔开。"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要添加连接，请联系 ", _interpolate(_named("link")), "。"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支持"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所有的 Zendesk 工单都将自动同步。您可以选择排除选定的工单。"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚太地区（东京）"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚太地区（首尔）"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚太地区（大阪）"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚太地区（孟买）"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚太地区（悉尼）"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加拿大（中部）"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧洲（法兰克福）"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧洲（苏黎世）"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧洲（爱尔兰）"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧洲（伦敦）"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器地区"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中东（阿联酋）"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择服务器地区"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["南美洲（圣保罗）"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美国东部（弗吉尼亚）"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美国东部 2（俄亥俄州）"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美国西部（俄勒冈）"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接已添加"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始连接"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅同步满足以下条件的对话："])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道一、渠道二"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此连接的唯一 ID 是 ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 个月"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 年"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 个月"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 个月"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活跃的对话将在选定的保留期之后被删除。已提交的考核仍会显示在“活动”视图和“面板”视图中。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留期"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择保留期"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意，此操作不可撤消。"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我理解这将从 Zendesk 质量保证中移除此连接的所有服务台相关数据，包括对话、字段和工单标签。"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提交评论不受影响，将继续显示在特定视图中。"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一组、第二组"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃开发"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此整合仍处于", _interpolate(_named("activeDevelopment")), "中。某些详情可能缺失（如附件或自定义字段）"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱一、邮箱二"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签一、工单标签二"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 令牌"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 令牌密钥"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Freshdesk 上的“设置”→“工单字段”下查找“工单字段名称”"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“Settings”→“Custom fields”的 Help Scout 中查找“Custom Field Names”"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“设置”→ Klasses →“对话”下查找“Klass 对话名称”"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“设置”→“自定义字段”下的在线专员中查找“自定义字段名称”"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“设置”→“自定义字段”下的 Wix 中查找“自定义字段名称”"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用逗号分隔字段 ID。您可以在 Zendesk 中的“管理员”→“工单字段”下查找这些 ID。"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接您的几个服务台用户，以考核您的首批对话。不用担心，我们不会通知他们，除非您邀请他们成为工作区成员。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已成功整合"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加成员"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们现在开始提取您的对话。"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["域名：", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["服务台标识符：", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法对您的连接进行身份验证"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已全部设置。这是新的连接。"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一些连接尚未更新或已变为非活跃状态。尝试更新这些连接或将其从列表中移除。"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 客户端 ID"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 客户端密钥"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["通过“设置”→“帐户”→“API 和 SDK”创建新的 API 令牌。要使用的重定向 URL 是 ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户 ID"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您的服务台以开始"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您的 Intercom 帐户设置为从欧盟或澳大利亚提供服务，请更新此项。"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您用于登录 ", _interpolate(_list(0)), " 的电邮"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏蔽终端用户的姓名、电邮和电话号码。已对所有服务台连接屏蔽银行凭证。"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有整合。立即添加一个。"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要这些角色："])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建连接"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新连接"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消勾选此项允许您手动将此连接仅添加到特定工作区"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接到所有现有工作区"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 密钥"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API 密钥"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已删除连接 ", _interpolate(_list(0))])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接名称"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建于"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在下方获取 API 令牌。"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 阈值"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除连接 ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入“删除连接”以删除此连接"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除连接"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理并监测连接的数据源。"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑连接"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用逗号分隔字段名"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新失败"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您的整合指定唯一名称"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏高级选项"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏敏感数据字段"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从不存储附件 URL"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略附件"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从不存储对话内容（您可以稍后按需从服务台提取）"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略对话内容"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从不存储对话主题（您可以稍后按需从服务台提取）"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忽略标题行"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活跃的"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您的 ", _interpolate(_list(0)), " 子域名"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom 地区"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配收件箱团队 ID"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 Intercom 团队的数字 ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中…"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动更新连接"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏蔽客户数据"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工作区"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感字段名称"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感字段 ID"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["续订连接"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["续订连接"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接到 Salesforce 沙盒环境"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["建立与 ", _interpolate(_list(0)), " 的连接"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示高级选项"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅提取评分低于或等于设置阈值的对话"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用 CSAT 阈值"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅同步满足以下条件的对话…"])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取最新对话"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新连接"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新连接"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的服务台"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要取消订阅并删除帐户，请联系", _interpolate(_named("link")), "。"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支持"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除帐户"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Zendesk Support（或其他服务台）中删除工单不会影响关联对话的考核。您仍可以查看和管理它们。"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在 Zendesk 质量保证中删除工单也会删除关联对话的考核。", _interpolate(_named("line_break")), "在 Zendesk Support（或其他服务台）中删除工单不会影响关联对话的考核。您仍可以进行查看和管理。"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理考核"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除考核是永久性操作。它还会影响面板上的数据。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有考核"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有考核都已删除"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当有与已删除工单关联的考核时，您将在这里看到。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有考核"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索工单 ID"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["工单 ID：", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除对话"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["默认工作区无法删除。要删除此工作区，在 ", _interpolate(_named("link")), " 中选择其他默认工作区。"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户设置"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可撤消。"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除并删除所有数据"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我理解，这会将与此工作区相关的内容", _interpolate(_named("removeAllData")), "，包括工作区用户和连接。"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除工作区"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入“删除工作区”以删除此工作区"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除工作区 ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除工作区"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请事先确定您正在执行的操作，或咨询专业人士。"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择是根据频率最高的专员还是根据当前受托人来选择默认被考核者。"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活跃的专员"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认被考核者"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许人员考核自己的对话，并允许专员进行考核"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除工作区"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新工作区"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过隐藏其他考核者以前提供的考核来给出中立评分"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不带偏见的评分"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可撤消。"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意，这将移除并删除与此工作区相关的所有数据，包括工作区用户和连接。"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除工作区"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在删除工作区…"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔术链接"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔术链接"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法取消激活当前的登录方法"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新身份验证方法"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许所有用户使用某些类型的身份验证方法。"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户和工作区"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建和管理工作区，邀请或联系用户。"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留在页面上"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开页面"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开此页面并丢失所有更改？"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["似乎没有可导入的对话。首先与您的服务台进行一些对话，然后返回这里进行分析。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到对话"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无法删除此系统类别"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别是永久性操作。它将清除报告中的所有类别数据。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要恢复此类别，您需要再次创建它。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索类别"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动类别会自动使用建议评价填充记分卡，以节省考核时间。手动类别由考核者从头开始填写。"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新于"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结尾"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼写和语法"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问候"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可读性"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供了解决方案"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语气"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到类别"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到根本原因"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到记分卡"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试不同的搜索或更改筛选器"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类别"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类别"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类别"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有类别"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类别"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个记分卡"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个记分卡"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个记分卡"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有记分卡"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个记分卡"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个状态"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个状态"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个状态"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有状态"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个状态"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类型"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类型"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类型"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有类型"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个类型"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个工作区"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个工作区"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作区"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个工作区"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 个工作区"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), " 的权重占总体评分的 ", _interpolate(_named("percentage")), "%"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除记分卡"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除记分卡是永久性操作。它将清除报告中的所有记分卡数据。"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要恢复此记分卡，您需要再次创建它。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除记分卡"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新于"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活跃的"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已更新评价量表。", _interpolate(_named("buttonStart")), "开始考核", _interpolate(_named("buttonEnd")), "。"])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证默认设置二进制标度，但您也可以选择更细化的标度："])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果您想查看按类别配置的不同评价量表选项，请转至", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心/语气"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品知识"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过更新评价量表："])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将存档以前的评价量表和相应类别（而不是删除）。"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一些面板数据将受到影响，因为新旧量表可能不具有可比性。"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将现有的评价类别设置复制到新量表"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要更新评价量表吗？"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以更改记分卡的显示格式（数字或表情符号），并将类别设置为必填或可选。"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用表情符号，而不是数字"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许人员跳过类别"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解决方案"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价量表类型"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已复制 1 个工作区的类别"]), _normalize(["已复制 ", _interpolate(_named("workspacesAmount")), " 个工作区的类别"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区设置"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果 ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果专员没有提示客户对话中会出现一段停顿时间，则自动显示来电。"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话出现停顿时间，持续时间超过设置的阈值。"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定义通话中的最大停顿阈值"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除的见解不会显示在任何新对话和筛选中。面板和对话中的历史数据将保持不变。"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法删除 Spotlight 见解"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight 见解已删除"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除 Spotlight 见解"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看记录副本和消息中包含特定字词或短语的对话。"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动识别缺少强制披露声明的通话，例如“此通话将被录音”等类似声明。"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旨在使用可动态理解上下文的大型语言模型 (LLM) 来识别通话中的录音披露语句，而无需预设短语。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少录音披露"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果专员没有提示客户对话中会出现一段停顿时间，则自动显示来电。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停顿"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定通话中的停顿时间"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["需要更改您的订阅？", _interpolate(_named("link")), " (", _interpolate(_named("email")), ")，我们将很乐于为您提供帮助。"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跨专员、BPO、渠道和语言自动分析 100% 的对话。人工智能可检测问题、知识差距和辅导机会，以改进服务。"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证 100% 覆盖率"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员反馈和辅导"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight 人工智能见解"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级报告和面板"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪分析和筛选"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按需翻译"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核任务和目标设定"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导和测验"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证 (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional 上的所有内容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级（旧版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于重大失败的即时通知的 Webhook"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据仓库整合"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud 整合"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义整合开发"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专属客户成功经理"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 Slack 直接联系我们的产品和工程团队"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite 中的所有内容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能 Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证可实现 100% 的覆盖率"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由人工智能驱动的对话见解，用于解读数据"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由人工智能增强的服务台数据扩充和筛选"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight：自动检测必须考核的交互操作"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪分析和筛选"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能驱动的业绩报告和见解"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按需对话翻译"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核时间记录"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过率分析"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite 中的所有内容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业实施订阅组合，包括 Salesforce 和 Genesis 整合"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义整合开发"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据仓库整合"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于重大失败的即时通知的 Webhook"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专属客户成功经理"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接联系我们的产品和工程团队"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早期试用及其对路线图的影响"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动考核任务和目标设置"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有针对性的辅导和分析"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于学习管理的测验"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户角色和权限管理"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["敏感数据屏蔽"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级筛选选项和对话搜索"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可自定义的记分卡，包含多个评价、可调整权重和自动失败选项"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可随时随地考核对话的浏览器扩展程序"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因分析"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于比较专员、团队、BPO 的业绩报告"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 调查和客户反馈分析"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter 上的所有内容，以及"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional（旧版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个对话有多个评价量表"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能驱动的交互式对话见解"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核任务"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导的固定项目"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["辅导课程"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情绪分析和筛选"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据对话复杂程度筛选"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话见解"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件记分卡"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因分析"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能驱动的高级 CSAT 分析"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分级校准"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随时随地考核对话的浏览器扩展"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight 可发现必须考核的对话"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按需对话翻译"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核时间记录"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义服务模式"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter（旧版）"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不限数量的对话考核"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 导出"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同行考核和自我考核"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含可调整权重和自动失败选项的记分卡"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可靠的筛选选项"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日、每周或每月考核目标"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT 调查和基本分析"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面板"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于比较专员、团队、BPO 的报告"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户角色和权限管理"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我想更改我的 Zendesk 质量保证订阅。\n\n突出显示或指明一个选项，并指明您想要更换的坐席数量：\n我想[添加 X 个席位][移除 X 个席位][升级到 Zendesk 质量保证服务模式]\n\n请告知我们贵公司的名称，与您的 Zendesk 质量保证帐户相关的名称，以及我们处理此更改时应该知道的任何其他详细信息。"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改 Zendesk 质量保证订阅"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我要购买 Zendesk 质量保证订阅。\n\n请告知我们贵公司的名称，与您的 Zendesk 质量保证帐户相关的名称，以及我们处理此请求时应该知道的任何其他详细信息：\n\n另请告知您在购买前要解决的任何具体问题：\n\n谢谢！"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买 Zendesk 质量保证订阅"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的订阅将于 ", _interpolate(_named("date")), " 结束（还剩 ", _interpolate(_named("days")), "）"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的试用将于 ", _interpolate(_named("date")), " 结束（还剩 ", _interpolate(_named("days")), "）"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 天"]), _normalize([_interpolate(_named("n")), " 天"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于每月 ", _interpolate(_named("dayOfMonth")), " 日向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["于 ", _interpolate(_named("billingDate")), " 向 ", _interpolate(_named("card")), " 收费（于 ", _interpolate(_named("expires")), " 过期）。"])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尾号为"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已过期"]), _normalize(["1 天后过期"]), _normalize([_interpolate(_named("expiresIn")), " 天后过期"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["起始价格包含 ", _interpolate(_named("n")), " 个用户许可证"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每月 ", _interpolate(_named("sum")), " 个起"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每名用户每月"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季度"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除计划的取消"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果您想继续使用 Zendesk 质量保证，", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的订阅已于 ", _interpolate(_named("date")), " 取消。"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的订阅计划于 ", _interpolate(_named("date")), " 到期。"])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天免费试用！"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您有 ", _interpolate(_named("n")), " 个用户。席位数不能小于该数字。"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["订阅将于 ", _interpolate(_named("time")), " 恢复"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您当前的 Zendesk 质量保证服务模式 (", _interpolate(_named("plan")), ") 不包含由人工智能提供技术支持的功能。要充分利用语音智能功能，", _interpolate(_named("link")), "。"])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要取消订阅，", _interpolate(_named("link")), "联系我们或和我们在线交谈。"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质量管理计划"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质量管理"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新订阅"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一张信用卡"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有服务模式"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐单结算"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看帐单结算信息"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐单结算间隔"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订阅"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅将在帐单结算期结束时取消。"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们以编辑"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前服务模式"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义定价"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降级"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年（节省 15%）"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按年"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按年付费"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑订阅"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索服务模式"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费试用"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已手动开具发票"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在下一步中审阅并确认最终订单概要。"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最受欢迎的"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有有效的付款方式。"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["试用中"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复当前的订阅以更改服务模式"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停订阅"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按季度"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按年"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新激活订阅"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复订阅"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["席位"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即开始试用"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不续订"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暂停"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅状态"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["试用"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级订阅"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请花一点时间，就您的客户支持体验提供反馈。提前感谢 — ", "{", "{", "agentName", "}", "}", " 非常重视您的反馈！"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您好 ", "{", "{", "customerName", "}", "}", " 👋！"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 的 CSAT 调查"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您如何评价从 ", "{", "{", "agentName", "}", "}", " 获得的支持？"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["非常感谢。我们将利用您的反馈改进 ", _interpolate(_list(0)), "，让所有人从中受益。"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解调查"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择调查类型"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以稍后更改。"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过多种语言的自动 CSAT 调查评估并提高客户满意度。"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建调查"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建调查"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查名称"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建调查"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果您希望将其列在此处，请尝试更改关键字或", _interpolate(_named("contact_us"))])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告诉我们"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到语言"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从下面的列表中选择一种或多种语言。如果此处没有列出您要使用的语言，请", _interpolate(_named("contact_us")), "。"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加语言"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告诉我们"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加语言"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最后发布于 ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["最后保存于 ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 问题"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍性问题"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左侧量表标签"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮主题"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右侧量表标签"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果调查缺少任何翻译，将显示基本语言的文本"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用翻译"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少翻译"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["应用之前，请务必先考核所有翻译。对已下载的文件进行更改，保存并根据需要重新上传。如果令牌 ", _interpolate(_named("token_example")), " 不显示为标签，要么是格式不正确，要么是所在的字段不支持令牌。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核翻译"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已翻译"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总结对话并显示评论建议。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证人工智能助手（英语）"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型创建后，您可以对其进行编辑"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此类型已存在。选择一个不同的名称。"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在调查类型下拉菜单中将自定义类型另存为可选选项，并在面板上对所有创建的调查进行分组。"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入词语并按回车键保存"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑自定义类型"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型名称不能为空"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定义类型"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未保存自定义类型。按回车以保存。"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件人电邮"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件人姓名"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您在多大程度上同意以下表述：“", _interpolate(_named("agentName")), " 让我能够轻松地处理问题”？"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["到目前为止，从 ", _interpolate(_named("brandName")), " 获取帮助的难易程度如何？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总体而言，现在解决问题的难易程度如何？"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们团队互动的难易程度如何？"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策 URL（可选）"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款 URL（可选）"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您如何评价从 ", _interpolate(_named("agentName")), " 获得的支持？"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们针对此请求的服务质量如何？"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您对于支持代表的满意度如何？"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目前对于从 ", _interpolate(_named("agentName")), " 获得的服务，您的满意度如何？"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义问题"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["定义自定义 ", _interpolate(_named("type")), " 问题…"])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入自定义问题"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义 URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置电邮消息"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑自定义问题类型的名称会在所有使用该问题的调查中进行更新，而不会影响已收集的数据。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑自定义类型"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮主题"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍性问题"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 问题"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新自定义类型"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["个性化令牌"]), _normalize(["个性化令牌"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将个性化令牌拖放到以下字段中。"]), _normalize(["将个性化令牌拖放到以下字段中。"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["对话 ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前只能通过电邮发送 CSAT 调查。我们正在努力为您提供更多机会，例如服务台 CSAT 调查、短信调查等等。"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["同步或续订连接"]), _normalize(["同步或续订连接"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步或续订连接 ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["连接未正确同步"]), _normalize(["连接未正确同步。"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消勾选相关复选框 ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即身份验证 ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加至少一个调查发送规则以激活连接"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此连接现已禁用。", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此连接会屏蔽敏感的客户端数据。要启用调查发送，\n在设置中", _interpolate(_named("link")), "。"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此连接需要额外权限才能发送调查。\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即身份验证"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证需要更多权限才能使用 Intercom。"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["（", _interpolate(_named("hours")), " 和 ", _interpolate(_named("minutes")), "）"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 小时"]), _normalize([_interpolate(_named("n")), " 小时"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 分钟"]), _normalize(["1 分钟"]), _normalize([_interpolate(_named("n")), " 分钟"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["小时"]), _normalize(["小时"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), "以配置调查发送规则。"])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" 天"]), _normalize(["天"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查链接过期"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接将于以下时间后过期"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果已过去太长时间，阻止回应调查"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["且"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 调查 ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工单标签之间用逗号隔开，或在编写后按回车。确保列出的每项与 Intercom 中一样准确。"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置发送调查所依据的条件。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查发送规则"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查跟进发送规则"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["调查 ", _interpolate(_named("name")), _interpolate(_named("rules")), " 已存在。\n在此处或 ", _interpolate(_named("link")), " 中修改规则，以解决冲突。"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您已为 ", _interpolate(_named("name")), " 调查设置了与匹配规则的连接。在此处或 ", _interpolate(_named("link")), " 中修改规则，以解决冲突"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一条规则"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加品牌"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择条件"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索值"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加工单标签"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配相同的规则"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择值"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮在以下时间后发出"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮跟进在以下时间后发出"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嵌入的代码段和电邮跟进"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制代码"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过将代码段添加到 Zendesk 设置中，将 Zendesk 质量保证调查附加到您的电邮中。"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装指南"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开 Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码段预览"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码段代码已复制到剪贴板中"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制代码段失败"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["嵌入的代码段"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此调查有未保存的更改。再次保存，以确保代码段代码反映了最新的更新。"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加连接"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未添加连接"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过连接将电邮、实时在线交谈、电话通话以及更多内容提取到 Zendesk 质量保证中"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于调查发送的信息"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查发送规则"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查跟进发送规则"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加品牌规则"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加规则"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + 电邮跟进"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将您的 Intercom 帐户与 Zendesk 质量保证CSAT 调查连接"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送方式"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查对象和时间"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑规则"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已启用"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用内调查使您的客户无需离开在线交谈即可完成调查。"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启动 Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个连接的发送规则"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["调查 ", _interpolate(_named("name")), _interpolate(_named("rules")), " 已存在。\n在此处或 ", _interpolate(_named("link")), " 中禁用连接或修改规则，以解决冲突"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置发送调查所依据的条件"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 于 ", _interpolate(_named("createdDate")), " 创建"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["移除 ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除此语言意味着它将不再对任何调查对象可见，并且所有翻译文本都将被删除。"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除语言"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可撤消。"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您也可以通过在调查中隐藏该语言来禁用它。这样您可以保留所有翻译，而不会向调查对象显示。"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("name")), " 于 ", _interpolate(_named("createdDate")), "最后编辑"])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解调查语言"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在添加其他语言之前，请确保您已在样式和内容步骤中完成更改。", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加语言"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已翻译字段"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对调查对象可见"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载翻译文件"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除语言"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 下载翻译文件"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核翻译"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["翻译完成。", _interpolate(_named("review_link")), "或", _interpolate(_named("download_link")), "以进行更改。"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 下载翻译文件"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审阅缺少的内容"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["部分内容尚未翻译，将替换为英语。", _interpolate(_named("review_link")), "或", _interpolate(_named("download_link")), "以添加缺少的翻译。"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存调查"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["调查含有未保存的更改。", _interpolate(_named("save")), "以下载包含最新更改的翻译文件。"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传翻译文件"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择首选评价量表"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为客户提供一个下拉菜单以选择评价原因"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表情符号"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左侧量表文本（可选）"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加原因"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因不能为空"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此原因已存在"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示评分原因的下拉菜单"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除一个原因并重新添加一个相同名称的原因，或重命名一个原因时，总是会创建一个新条目。因此，会影响面板上显示的数据。"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除原因"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除的原因将保留在面板报告中，但对新的调查对象不可见。这也适用于相关翻译。"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除一个原因"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["删除一个原因“", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右侧量表文本（可选）"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满分为 5 分时，1 和 2 被视为负分；3 为中位分；4 至 5 分为正分"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加量表文本"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " 量表"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价量表"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk 质量保证正在导入对话。此过程可能需要 24 小时。"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将在整个调查过程中用作个性化令牌。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌名称"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按钮和链接"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义调查外观"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徽标背景"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持 JPG、PNG 和 GIF 格式"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徽标"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["样式"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传徽标"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风格化总结"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入在提交反馈后显示的消息"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢消息"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十六进制颜色代码不正确"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮地址不正确"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段不能为空"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL 不正确"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可见："])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可见"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发出的调查"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近 30 天内发出的调查"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除调查"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制调查"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送预览电邮"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发布调查"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新调查"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暂停调查"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停调查"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入发出调查预览的电邮"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的 ", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择预览语言"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送预览电邮"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将示例调查发至您的邮箱。将不会保存调查预览中的评价。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["预览 ", _interpolate(_named("type")), " 调查"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络中的电邮调查预览"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存为草稿"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌名称"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在“样式设置”步骤中添加品牌特定名称"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户名称"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持 ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持代表"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已暂停"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 的 CSAT 调查"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改未保存"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新并恢复调查"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["如果您没有正确看到此电邮 ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我对获得的支持不满意，因为…"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Zendesk 质量保证提供技术支持"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择原因"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话回顾"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["示例：客户在更新付款方式时遇到问题。专员分享了一篇可能有所帮助的文章。有效。"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Zendesk 质量保证人工智能提供技术支持"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务条款"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感谢反馈"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您评分的主要原因是什么？"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["告诉我们您给出此评价的原因…"])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常努力"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不够努力"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击此处开启调查"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处将显示您的自定义问题"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您对 ", _interpolate(_list(0)), " 体验给出了一个评分"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交反馈"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢谢"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订阅"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常不满意"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常满意"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意，当前调查状态和发送规则将不会包含在调查副本中。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["复制调查“", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您使用嵌入式电邮代码段，请确保在暂停调查之前将其从 Zendesk 中移除"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查暂停时，不会收集回复。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停调查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停调查之前，请确保您已从 Zendesk 中移除代码段。"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除调查"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您使用了嵌入的电邮代码段，请务必在删除调查之前将其从 Zendesk 中移除。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将永久删除调查。收到调查的用户仍然可以填写调查，且所有与调查相关的数据都将保留在 Zendesk 质量保证中。"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除调查 ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在删除调查之前，请确保从 Zendesk 中移除代码段。"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您应用的更改将对新调查对象可见。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布调查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您在发布调查后复制了最新版本的嵌入式代码段。"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您使用了嵌入式电邮代码段，请务必在恢复调查后将其复制到 Zendesk 中。"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续发送调查并收集回复。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新并恢复调查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请务必在恢复调查后将嵌入式代码段添加到 Zendesk 中。"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新调查"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请务必在更新调查后复制最新版本的嵌入式代码段。"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定要删除此调查吗？此操作不可撤消。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否删除调查？"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的试用已过期，升级为完整帐户可以继续使用 Zendesk QA。"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的试用已过期，升级为完整帐户可以继续使用 Zendesk QA。联系您的本地管理员进行设置。"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的试用已结束"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即升级"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的免费试用已结束。"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要继续使用完整版 Zendesk 质量保证，请输入您的付款详情。"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要继续使用完整版 Zendesk 质量保证，您的帐户管理员需要输入付款详情。"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理支付"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["由 ", _interpolate(_named("date")), " 于 ", _interpolate(_named("authorName")), "创建"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 任务"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎仍在处理此项目。如果您不保存更改就离开，将丢失到目前为止添加的所有信息。"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此任务将每隔一个", _interpolate(_named("weekDay")), "重复一次"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此任务将每个", _interpolate(_named("weekDay")), "重复一次"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["一个周期涵盖的所有被考核者"]), _normalize([_interpolate(_named("nr")), " 个周期涵盖的所有被考核者"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 个对话"]), _normalize([_interpolate(_named("n")), " 个对话"]), _normalize([_interpolate(_named("n")), " 个对话"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nr")), " 个专员"]), _normalize([_interpolate(_named("nr")), " 个专员"]), _normalize([_interpolate(_named("nr")), " 个专员"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["个参与者"]), _normalize(["个参与者"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["每个周期 ", _interpolate(_named("n")), " 个对话"]), _normalize(["每个周期 ", _interpolate(_named("n")), " 个对话"]), _normalize(["每个周期 ", _interpolate(_named("n")), " 个对话"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每个被考核者连续 ", _interpolate(_named("reviewsCount")), " 个"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["被考核者"]), _normalize(["被考核者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["考核者"]), _normalize(["考核者"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区用户"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除专员之外的所有工作区用户"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["周期 ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非经常性"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 任务"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["审阅"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解关于任务的信息"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建任务"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将永久删除该任务，并从面板移除其数据。与之相关的所有考核都将保留在 Zendesk 质量保证中。"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除任务 ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除任务"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制任务"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未添加任务"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法创建任务"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务名称"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刷新周期，并创建新的任务列表。已经提供的考核仍然计入目标。"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " 下一个周期开始时"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何时发布更改？"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对此任务所做的更改将连同此任务一起发布"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将任务设置为活跃？"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在任务开始日期重复"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期五"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期五"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期五"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期五"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期五"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期一"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期一"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期一"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期一"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期一"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期六"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期六"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期六"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期六"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期六"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期日"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期日"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期日"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期日"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期日"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期四"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期四"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期四"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期四"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期四"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期二"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期二"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期二"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期二"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期二"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一个星期三"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第二个星期三"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第三个星期三"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第四个星期三"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月最后一个星期三"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义重复"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月第一天"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一个"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二个"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第三个"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第四个"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后一个"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作日"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有结果"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时区"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周期设置"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务名称"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在每个任务周期开始时，系统会生成一个预定的对话列表，以供评估。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级设置"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未达到考核目标时自动抓取新对话"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核目标为百分比 (%) 时无法进行自动抓取"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须是整数，不能是小数"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许替换对话（考核者用户角色及更高权限）"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替换对话"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许将考核者自己的对话分配给考核者以供考核"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小考核数必须至少为 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定每个被考核者的最少考核数"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["段对话"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百分比 (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条考核"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条考核（总计）"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个考核者必须完成所设数量的考核"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者必须共同考核一定数量的对话"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小考核数必须至少为 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核目标必须为 1% 至 100% 之间"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核目标"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个被考核者的所有对话中，经过考核的对话数量必须达到最小考核数"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者可以从共享池中选择对话"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从共享池中为考核者分配对话"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配方式"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有智能机器人"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区参与者"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区用户"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加被考核者"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哪些人将被考核？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择被考核者"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要考核的人"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅当选择了所有工作区用户或一个组时才允许排除"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者和被考核者"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["当前：", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区默认"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应将哪种类型的用户选为被考核者？"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最活跃的专员"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除被考核者（可选）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要排除的用户"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除考核者（可选）"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要排除的用户"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除专员之外的所有工作区用户"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加考核者"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谁将进行考核？"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择考核者"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作区不允许自我考核"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许自我考核"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此工作区不允许自我考核。"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["今天的匹配对话"]), _normalize(["今天的匹配对话"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["像任务从今天开始一样显示"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来的数字可能会有所变化。"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择被考核者"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择考核者"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["被考核者"]), _normalize(["被考核者"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["考核者"]), _normalize(["考核者"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动抓取：如果对话数量不足，则允许"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周五起，", _interpolate(_named("cycleLength"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周一起，", _interpolate(_named("cycleLength"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周六起，", _interpolate(_named("cycleLength"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周日起，", _interpolate(_named("cycleLength"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周四起，", _interpolate(_named("cycleLength"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周二起，", _interpolate(_named("cycleLength"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 的周三起，", _interpolate(_named("cycleLength"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["从 ", _interpolate(_named("startDateTime")), " 起每天"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["开始于 ", _interpolate(_named("startDateTime")), "，结束于 ", _interpolate(_named("endDateTime")), "。"])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 日期条件"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 对话和服务台条件"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["没有对话，", _interpolate(_named("count")), " 个服务台条件"]), _normalize(["没有对话，", _interpolate(_named("count")), " 个服务台条件"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 个对话，没有服务台条件"]), _normalize([_interpolate(_named("count")), " 个对话，没有服务台条件"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["方法：考核者选择对话"]), _normalize(["方法：为考核者分配对话"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修复概要中突出显示的错误，然后继续"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标：考核者必须考核所有对话"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目标：考核者必须考核一个对话"]), _normalize(["目标：考核者必须考核 ", _interpolate(_named("count")), " 个对话"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目标：考核者必须考核 ", _interpolate(_named("count")), "% 的对话"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标：未设定考核目标"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标：每个被考核者的所有对话都必须经过考核"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目标：每个被考核者的所有对话中，必须有 ", _interpolate(_named("count")), " 个对话经过考核"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目标：每个被考核者 ", _interpolate(_named("count")), "% 的对话必须经过考核"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["目标：每个考核者必须执行一条考核"]), _normalize(["目标：每个考核者必须执行 ", _interpolate(_named("count")), " 条考核"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["替换：考核者无法替换对话"]), _normalize(["替换：考核者可以替换对话"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自我考核：可以为考核者分配自己的对话"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未设置日期条件"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未设置任务名称"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), "，从 ", _interpolate(_named("dateTime")), " 开始"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每两周"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永不"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周期长度"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置为活跃的"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存为草稿"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务已另存为草稿"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务设置为非活跃"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置为非活跃"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建任务"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建任务"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为活跃"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为非活跃"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["对任务的待处理更新将在下一个周期生效，从 ", _interpolate(_list(0)), "开始。"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布更改"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活跃的"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待回应"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新任务"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新任务"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员或智能机器人是否就对话进行了收尾。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否礼貌地结束了对话"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析对话是否已结束"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结语"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员和智能机器人互动中的语法、拼写和风格错误。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员的语法、拼写和风格错误"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员的语法、拼写和风格错误"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼写和语法"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员和智能机器人在对话中使用的问候语。"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中的典型问候语"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中的典型问候语"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问候语"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否理解并回应客户的感受"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["识别专员反映客户情绪或使用“确认”和“认可”等关怀用语的情况"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同理心"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否理解客户的询问或疑虑"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员是否理解客户的询问或疑虑"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理解"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析理解文本的难易程度，考虑词语复杂度和句子长度"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考虑词语复杂度和句子长度，分析理解文字的难易程度"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此消息包含 1 个过长的句子，让人难以阅读"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["此消息包含 ", _interpolate(_list(0)), " 个过长的句子，让人难以阅读"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可读性"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中提供的解决方案"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析整个对话中提供的解决方案"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的解决方案"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员在整个对话过程中的语气"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析专员在整个对话过程中的语气"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语气"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法从专员消息中检测到任何错误"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确定语法错误"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未检测到问候短语。如有必要，请通过设置中的管理员添加新问候语。"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员礼貌地结束了对话"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未检测到结语。如有必要，请通过设置中的管理添加新结语"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员反映了客户的情绪或使用了关怀语"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测专员是否反映了客户的情绪或使用了关怀语"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员使用了长句子和复杂的词语，对某些人来说可能难以理解"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员使用了复杂的词语，对某些人来说可能难以理解"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员使用了长句子，对某些人来说可能难以理解"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员使用的语言易于理解"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有足够的数据评价此类别"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员难以理解客户的问题/请求"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员很好地理解了客户的问题/请求"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测专员是否理解客户的问题/请求"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话期间专员的语气"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话期间专员的语气"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话期间专员的语气"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话期间专员的语气"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话期间专员的语气"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法确定专员的语气"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话未结束"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话已结束"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测对话是否结束"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员对客户及其问题缺乏同理心"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员对客户及其问题表达出同理心"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测专员是否对客户及其问题表达出同理心 "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对话期间客户未收到问候"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在对话期间客户收到问候"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测到典型问候语"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员没有为客户提供解决方案"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专员已为客户提供解决方案"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法检测专员是否已为客户提供解决方案"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复杂的语言"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长句子"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语法"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼写错误"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平静"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["愉快"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好奇"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["样式"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 个结语"]), _normalize([_interpolate(_named("n")), " 个结语"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证仅检查批准的结语。如果此类别设置为严重，则不使用批准的结语将导致考核失败。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的结语"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的结语"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 项豁免"]), _normalize([_interpolate(_named("n")), " 项豁免"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被自动质量保证视为拼写或语法错误而忽略的词语和短语。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼写和语法豁免"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证豁免"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 条问候语"]), _normalize([_interpolate(_named("n")), " 条问候语"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证仅检查批准的问候语。如果此类别设置为“严重”，则不使用批准的问候语将导致考核失败。"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的问候语"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准的问候语"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证类别"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择自动质量保证类别"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动分配的根本原因："])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户 → 设置"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["一些类别利用了 MS Azure，提供更广泛的功能和支持的语言。查看您的 ", _interpolate(_named("link")), " 了解更多信息，或联系管理员。"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["利用 MS Azure 访问扩展的类别和支持语言列表。查看您的 ", _interpolate(_named("link")), " 或联系管理员。"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用自动质量保证"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["使用固定的评价量表自动为自动质量保证类别分配一个评分。您可以稍后手动调整评价。", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择自动质量保证类别"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自动分配的评分取决于错误的严重性及其数量。", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅向考核者显示相关的评价类别。将根据您在创建类别时设置的条件显示评价类别。"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线交谈"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入门培训"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["选择工作区“", _interpolate(_named("workspace")), "”的评价类别类型。"])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者必须手动选择正确的记分卡以查看每个对话的相关评价类别。"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要更改评价类别类型吗？"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改类别"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改评价类别类型？"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件评价类别"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静态评价类别"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别类型"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别设置"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作不可撤消。"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除所有评价"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者，您可以通过存档类别在不删除任何数据的情况下保留统计数据。"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["我理解这将针对该类别", _interpolate(_named("deleteAllRatings")), "。"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：完美的语气"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅在以下情况下显示类别"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“始终”将被忽略。不能与其他条件结合使用。"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始终"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择条件"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满意度评分 (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源类型"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话渠道"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别需要一个名称"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将类别添加到记分卡"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建类别"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建类别"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加评价类别"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档类别不显示在记分卡上，但允许您在不删除任何数据的情况下保留统计数据。"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档类别"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["存档 ", _interpolate(_list(0)), " 类别"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别已存档"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入“删除类别”以删除此类别"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除类别"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["删除类别 ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除评价类别"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制评价类别"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加评价类别组"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已删除评价类别组"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更改评价类别组顺序"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新评价类别组顺序"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价类别已解档"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新评价类别"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当专员出现重要类别错误时，该考核中的所有类别都自动失败。"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要评价类别"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这个组中没有类别"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新评价类别"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑类别"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除该组下所有的类别和考核数据"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除该类别组将使所有链接到它的类别变为未分组状态。"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除 ", _interpolate(_named("categoryName")), " 类别组"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择删除该组下的类别将导致丢失与这些类别关联的所有考核数据。"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组名"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者可以选择多个原因作为根本原因"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者可以选择多个原因作为根本原因"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多个原因"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如语气"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新的类别组"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建组"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加“其它”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加根本原因"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此根本原因已存在"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加根本原因"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加根本原因以解释评价"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价量表"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加记分卡到此评价类别"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解档"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新类别"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有评价"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为哪些评价显示"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面评价"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负面和中性评价"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，更新"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对您而言更重要的类别在工单评分计算中可以拥有更大的权重。"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["权重（", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解校准"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解校准"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期举行校准会议可以使考核者保持一致的评价技术，并确保专员获得相同水平的一致且不带偏见的反馈。"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看服务模式"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 流程可帮助您的团队在处理和评估所有客户交互方面保持一致。"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["校准可用于 ", _interpolate(_list(0)), " 和 ", _interpolate(_list(1)), " 服务模式。"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当对话添加到会话中时，不会显示以前提供的考核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可见"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前进行的考核将被视为校准考核。每个考核者只能将一段对话考核添加到会话中。不会显示针对特定消息的考核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可见"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前提供的考核的可见度"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["校准是一个过程，可帮助您的团队在处理和评估所有客户交互方面达成一致。", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户可不受限制地访问校准会话期间提供的所有考核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始终查看所有考核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潜在客户"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，用户将在校准会话期间看到其他考核者给出的评估。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，查看所有校准考核"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户可不受限制地访问校准会话期间提供的所有考核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始终查看所有考核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，用户将在校准会话期间看到其他考核者给出的评估。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，查看所有校准考核"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，用户将在校准会话期间看到其他考核者给出的评估。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交考核后，查看所有校准考核"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户在校准会话期间将只能看到自己的考核。"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅可查看自己的评论"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新校准设置"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准考核的可见度"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据团队的内部指标系统填补空白，设置面板指标的阈值。在面板上查看阈值。"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["校准"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通用"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成员"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阈值"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新阈值"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍等片刻..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["确认您有管理员权限，并", _interpolate(_named("retry")), "，或", _interpolate(_named("contact")), "以获得进一步的帮助。"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们似乎无法访问您服务台的 API。"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再试一次。"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在连接您的服务台。即将重定向。"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 已授权"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["返回", _interpolate(_list(0)), "以添加新服务台连接。"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您似乎缺少了一两步。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经存在具有相同电邮域名的组织"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接页面"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同时…"])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在检查您的权限，请稍等。"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同时…"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在整合"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建工作区"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["要继续使用 Zendesk 质量保证，请", _interpolate(_named("link")), "。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马上可以使用"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作区"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换帐户"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要继续使用 Zendesk 质量保证，您的管理者需要为您分配一个工作区。"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除您的帐户将永久删除我们拥有的您的所有相关数据 - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有提供的评价、对话内容、工作区等"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到 Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开连接"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开整合将禁用所有通过 Slack 发送的 Zendesk 质量保证通知"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断开 Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack 整合已断开与 Zendesk 质量保证的连接。"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为所有新帐户用户定义默认通知设置。"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖所有用户的设置"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将覆盖所有帐户用户的通知设置"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户将无法定义自己的通知设置。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新通知设置"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当严重类别出现错误时发出警报"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 CSAT 得到回复时提醒"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发器"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建演示工作区和数据"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新创建演示数据并保留演示工作区。"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除演示工作区和数据"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新演示数据"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新数据"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["管理帐户“", _interpolate(_named("accountName")), "”演示模式"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["演示模式"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/6059285322522-%E5%85%B3%E4%BA%8E-Zendesk-%E4%B8%AD%E7%9A%84%E7%94%9F%E6%88%90%E5%BC%8F%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD%E5%8A%9F%E8%83%BD"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043712839450-%E9%85%8D%E7%BD%AE%E6%9C%8D%E5%8A%A1%E5%8F%B0%E8%BF%9E%E6%8E%A5%E7%9A%84%E9%AB%98%E7%BA%A7%E5%AE%89%E5%85%A8%E8%AE%BE%E7%BD%AE"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043747123354-%E5%9C%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B8%AD%E4%BD%BF%E7%94%A8%E8%87%AA%E5%8A%A8%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E8%AE%BE%E7%BD%AE%E8%87%AA%E5%8A%A8%E8%AF%84%E5%88%86"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7418648293018-%E4%BD%BF%E7%94%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E8%AF%84%E4%BC%B0%E4%BA%BA%E5%B7%A5%E6%99%BA%E8%83%BD%E4%B8%93%E5%91%98%E7%9A%84%E4%B8%9A%E7%BB%A9"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043724530842-%E5%9C%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B8%AD%E8%AE%BE%E7%BD%AE%E6%A0%A1%E5%87%86"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043759436954-%E5%88%86%E6%9E%90%E4%B8%80%E6%AE%B5%E6%97%B6%E9%97%B4%E5%86%85%E7%9A%84%E7%B1%BB%E5%88%AB%E8%AF%84%E5%88%86"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043747231642-%E4%BD%BF%E7%94%A8%E5%AF%B9%E8%AF%9D%E8%A7%81%E8%A7%A3%E8%A7%86%E5%9B%BE"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043747649690-%E5%88%9B%E5%BB%BA%E5%A4%9A%E4%B8%AA%E8%AE%B0%E5%88%86%E5%8D%A1"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043701093786-%E4%BA%86%E8%A7%A3Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E9%9D%A2%E6%9D%BF%E8%AE%A1%E7%AE%97"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043759449114-%E4%BD%BF%E7%94%A8%E8%87%AA%E5%AE%9A%E4%B9%89%E7%AD%9B%E9%80%89%E6%9F%A5%E6%89%BE%E5%AF%B9%E8%AF%9D%E4%BB%A5%E5%AE%A1%E9%98%85"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043760162074-%E5%9C%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B8%AD%E7%AE%A1%E7%90%86%E7%94%A8%E6%88%B7%E5%92%8C%E5%B7%A5%E4%BD%9C%E5%8C%BA"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043759543194-%E4%BD%BF%E7%94%A8%E7%BD%AE%E9%A1%B6%E7%9A%84%E6%B3%A8%E9%87%8A%E8%BE%85%E5%AF%BC%E8%80%85%E4%B8%93%E5%91%98"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043747142938-%E5%9C%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B8%AD%E7%AE%A1%E7%90%86%E7%BB%84"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043759586074-%E4%BD%BF%E7%94%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E7%AA%81%E5%87%BA%E6%98%BE%E7%A4%BA%E7%AD%9B%E9%80%89%E5%AF%B9%E8%AF%9D"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/articles/7043759396250-%E5%9C%A8Zendesk-%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81%E4%B8%AD%E8%B7%9F%E8%B8%AA%E5%AE%A1%E9%98%85%E6%97%B6%E9%97%B4"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/zh-cn/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留在页面"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开页面"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["离开此页面并丢失所有更改？"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已完成任务"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建任务"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务已完成"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到任务"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前没有任务供您考核。"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调整任务或等待下一个周期再开始。"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尝试调整筛选器"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑任务"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请关注此页面以获取更新"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此周期没有任务"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的对话"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将任务标为已完成"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已审阅此用户。"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此任务中没有自我考核"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击对话视图标题中的校准图标以添加。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加对话"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["截止日期"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建任务"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建校准时间"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的任务"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其它任务"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活跃的任务"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无任务"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有被考核者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除我之外的所有被考核者"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到被考核者"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索被考核者"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有状态"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待办事项"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有考核者"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除我之外的所有考核者"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已分配给我"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到考核者"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索考核者"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 天"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 小时"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " 分钟"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不适用"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区用户"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前考核者"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此周期的新目标"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新考核者"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择考核者"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配对话"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配对话"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要移动的对话"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前周期"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑任务"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到考核者"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索考核者"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将对话重新分配给我"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将对话重新分配给其他用户"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 天"]), _normalize([_interpolate(_named("n")), " 天"]), _normalize([_interpolate(_named("n")), " 天"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 小时"]), _normalize([_interpolate(_named("n")), " 小时"]), _normalize([_interpolate(_named("n")), " 小时"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏已完成周期"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏已完成会话"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的目标"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一个原因以移除此对话"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请说明为什么要移除此对话"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除对话"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您要保留更改吗？"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加一个原因以替换此对话"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放弃更改"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留更改"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到替换"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明要替换此对话的原因"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否仍要从任务中移除此对话？这将使目标减少 1。"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替换"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替换对话"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除草稿"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑草稿"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配给我"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配给其他用户"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新分配并考核"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除对话"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更少"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["目标：已考核 ", _interpolate(_named("count")), " 个（共 ", _interpolate(_named("goal")), " 个）"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成的考核"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示完成的周期"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示完成的会话"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩余时间"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已替换对话"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换任务列表"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " 之后"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " 秒"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " 秒"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("hours")), " 小时"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("hours")), " 小时 ", _interpolate(_named("minutes")), " 分"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " 天 ", _interpolate(_named("minutes")), " 分"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小时"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小时 ", _interpolate(_named("minutes")), " 分"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小时 ", _interpolate(_named("minutes")), " 分 ", _interpolate(_named("seconds")), " 秒"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " 小时 ", _interpolate(_named("seconds")), " 秒"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " 分钟"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " 分 ", _interpolate(_named("seconds")), " 秒"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " 月"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " 秒"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " 年"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刚才"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您缺少权限。请联系您的管理员以获得帮助。"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您查找的页面不存在。"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发生意外错误。联系支持以获得帮助。"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝访问。不允许执行该操作。"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您在查找的页面不存在或者您没有访问权限。"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的速度太快了，我们跟不上。"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改已保存"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改未保存"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已应用 ", _interpolate(_named("n")), " 个"]), _normalize(["已应用 ", _interpolate(_named("n")), " 个"]), _normalize(["已应用 ", _interpolate(_named("n")), " 个"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已联系"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建于"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建者"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["严重"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["前成员"]), _normalize(["一个前成员"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已选择 ", _interpolate(_named("n")), " 个"]), _normalize(["已选择 ", _interpolate(_named("n")), " 个"]), _normalize(["已选择 ", _interpolate(_named("n")), " 个"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新于"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... 和另外 1 个"]), _normalize(["... 和另外 ", _interpolate(_named("n")), " 个"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["另外 1 个"]), _normalize(["另外 ", _interpolate(_named("n")), " 个"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新建"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 条回复"]), _normalize([_interpolate(_named("n")), " 条回复"]), _normalize([_interpolate(_named("n")), " 条回复"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期五"]), _normalize(["星期五"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期一"]), _normalize(["星期一"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期六"]), _normalize(["星期六"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期日"]), _normalize(["星期日"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期四"]), _normalize(["星期四"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期二"]), _normalize(["星期二"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["星期三"]), _normalize(["星期三"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["管理员"]), _normalize(["一个管理员"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["客户经理"]), _normalize(["一个客户经理"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["用户"]), _normalize(["一个用户"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动质量保证"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的会话已过期。"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段不能为空"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到项目。"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的人员。"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["负责人"]), _normalize(["一个负责人"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多。"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在开始之前，请阅读并同意我们的条款，然后开始操作。"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定吗？"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成考核总数"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["专员"]), _normalize(["一位专员"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["潜在客户"]), _normalize(["一位潜在客户"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["经理"]), _normalize(["一位经理"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["考核者"]), _normalize(["考核者"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名用户"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受托人"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很快"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击以复制"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT（客户满意度调查）"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["危险区域"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述（可选）"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要确认"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁用"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否放弃未保存的更改？"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选条件"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["频率"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知道了"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际质量评分 (IQS)"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从未"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过率"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人设置"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读更少"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到/看到的考核"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区成员完成的考核"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户角色"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存更改"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二进制量表"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三分制量表"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["四分制量表"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五分制量表"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择频率"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示更少"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示更多"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查反馈"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间范围"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改未保存"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您受 ", _interpolate(_list(0)), " 邀请使用 Zendesk 质量保证。"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将自动从管理中心连接用户。", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理用户"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解用户管理"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将在您的身份提供程序中自动设置和同步部分用户。", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["“", _interpolate(_list(0)), "”的帐户权限已更改"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解用户权限"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不再询问"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在用户页面上进行的所有更改都将反映在工作区中。", _interpolate(_named("help2")), "。", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这意味着用户对 Zendesk 质量保证某些部分的访问权限可能会改变"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改帐户级别权限"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已移除 ", _interpolate(_named("n")), " 个用户"]), _normalize(["已移除 ", _interpolate(_named("n")), " 个用户"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["当您移除用户时，该用户将失去对您帐户的访问权限。他们的考核、评论和评价将保留在 Zendesk 质量保证中。", _interpolate(_named("linebreak")), "移除用户不会影响您订阅中的席位数量。"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["将从您管理的所有工作区中移除此用户。", _interpolate(_named("linebreak")), "其考核、评论和评价将保持不变。"]), _normalize(["将从您管理的所有工作区中移除他们。", _interpolate(_named("linebreak")), "他们所有的考核、评论和评价都将保持不变。"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["移除 ", _interpolate(_named("name"))]), _normalize(["移除 ", _interpolate(_named("name")), " 个用户"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可查看所有内容，管理所有设置及帐单结算"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可查看所有内容并管理个人和工作区设置（帐单结算除外）"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户权限"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区特定的权限允许用户查看（和）编辑其所在工作区中的数据。"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解智能机器人质量保证"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当同步您的智能机器人参与的对话时，此处将显示该智能机器人"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有同步的智能机器人"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将智能机器人标为用户将授予其登录权限。其历史智能机器人数据将继续显示在面板上。"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到此类智能机器人"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尝试使用不同的关键字或拼写重新搜索。"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索智能机器人"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按智能机器人名称搜索"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为用户"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有管理员和帐户管理者可以将智能机器人标为用户"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人名称"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次在线交谈"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已手动标记"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可考核"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从自动分析和所有任务中排除智能机器人。面板中不会显示新数据，从而确保报告准确并简化智能机器人管理。"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成式智能机器人"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作流程智能机器人"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到组"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有组"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有组"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从组中移除"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择组"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户权限"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到工作区"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全名"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后活跃于"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户级别用户角色"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将为每个用户分配一个帐户和/或工作区级别权限。管理员权限现在是帐户级别的，而其他权限是工作区级别的。"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从工作区移除"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区权限"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在组中管理的权限"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑详情"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标为智能机器人"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将用户标为智能机器人将移除其登录权限。其历史用户数据将继续显示在面板上。"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从自动分析和所有任务中排除智能机器人。面板中不会显示新数据，从而确保报告准确并简化智能机器人管理。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将智能机器人从考核中排除"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户已标为智能机器人"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只有用户权限类型可被标为智能机器人"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有多个帐户的用户无法被标为智能机器人"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按用户搜索"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能机器人"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户、智能机器人和工作区"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户和工作区"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理员"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有权限"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已连接"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已手动添加"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待回应"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待回应邀请"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 用户"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要操作"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...和另外一个"]), _normalize(["...和另外 ", _interpolate(_named("n")), " 个"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将权限更改为"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["移除并重新邀请以更改 ", _interpolate(_named("n")), " 个待回应邀请用户的角色。"]), _normalize(["移除并重新邀请以更改 ", _interpolate(_named("n")), " 个待回应邀请用户的角色"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["编辑 ", _interpolate(_named("name")), " 的详情"]), _normalize(["编辑 ", _interpolate(_named("n")), " 个选定用户的详情"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已更新 ", _interpolate(_named("n")), " 个用户。"]), _normalize(["已更新 ", _interpolate(_named("n")), " 个用户。"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["此组包含 1 个来自其他工作区的用户。"]), _normalize(["此组包含 ", _interpolate(_named("n")), " 个来自其他工作区的用户。"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已存档“", _interpolate(_named("name")), "”组"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档组"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建组"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已创建“", _interpolate(_named("name")), "”组"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无标题组"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否永久删除此组？"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除组"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除组"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑组"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组潜在客户"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组角色"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已隐藏“", _interpolate(_named("name")), "”组"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此组将不会显示在 Zendesk 质量保证中的其他位置"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅管理工作区权限"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组在 Zendesk 质量保证中不可见"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潜在客户"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成员"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无标题组"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另一个组已经使用此名称"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有一个已存档的组已使用此名称"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的用户角色仅允许管理此组的成员。联系具有更高权限的用户以进行进一步更改。"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为组潜在客户移除"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已恢复“", _interpolate(_named("name")), "”组"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已保存“", _interpolate(_named("name")), "”"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置为组潜在客户"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已解档“", _interpolate(_named("name")), "”"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未保存的更改"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将您现有的用户添加到该组"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按名称或电邮搜索"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加成员"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["“", _interpolate(_named("name")), "”组可见"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["每个成员是 ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解组"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会从您的身份提供程序中自动设置和同步组的某些属性。"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解组"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建组"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轻松组织团队成员，并模仿您的组织结构。"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加至少 1 名成员"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加组名"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此名称的组已存在"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组成员"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组名"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织您的团队成员，并模仿您的组织结构。"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成员"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["没有组"]), _normalize(["1 个组"]), _normalize([_interpolate(_named("count")), " 个组"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个新组或激活一个存档组。"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的活跃组"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有存档组"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到此类组"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尝试使用不同的关键字或拼写重新搜索。"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加组"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["位于"]), _normalize(["位于"]), _normalize(["位于"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按组名搜索"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "可能重新发送"])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 个剩余席位"]), _normalize([_interpolate(_named("n")), " 个剩余席位"]), _normalize([_interpolate(_named("n")), " 个剩余席位"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前您的席位数有限，因为您的订阅已暂停。重新激活以添加更多用户。"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到帐户席位的上限。您可以通过取消激活活跃用户或添加更多席位来替换用户。"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看支付详情"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已选择 ", _interpolate(_named("n")), " 个"]), _normalize(["已选择 ", _interpolate(_named("n")), " 个"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已选择 ", _interpolate(_named("n")), " 个用户"]), _normalize(["已选择 ", _interpolate(_named("n")), " 个用户"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " 行"]), _normalize([_interpolate(_named("n")), " 行"]), _normalize([_interpolate(_named("n")), " 行"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据用户的姓名或电邮地址批量搜索用户。"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将数据粘贴到此处，用逗号或换行符分隔"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量搜索用户"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["编辑 ", _interpolate(_named("name")), " 的工作区权限"]), _normalize(["编辑 ", _interpolate(_named("n")), " 个选定成员的工作区权限"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑权限"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择工作区权限"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["从工作区中移除 ", _interpolate(_named("name"))]), _normalize(["从工作区中移除 ", _interpolate(_named("name")), " 个成员"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已移除 ", _interpolate(_named("n")), " 个成员"]), _normalize(["已移除 ", _interpolate(_named("n")), " 个成员"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["移除成员"]), _normalize(["移除成员"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["将从工作区中移除此用户。"]), _normalize(["将从工作区中移除这些用户。"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["“", _interpolate(_list(0)), "”的工作区权限已更改。"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加成员"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看对话和相关考核"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看工作区中的所有内容，但仅管理测验、任务、争议和校准会话"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区权限"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看并管理工作区中的所有内容"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看工作区中的所有内容，工作区设置除外"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系用户"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自您管理的工作区的所有用户都已添加到此工作区。"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请管理员执行此操作。"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您自己直接邀请新用户或让管理员邀请。"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " 或 ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可显示的用户"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" 已添加 ", _interpolate(_named("n")), " 个用户"]), _normalize(["已添加 ", _interpolate(_named("n")), " 个用户"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["添加成员"]), _normalize(["添加成员"]), _normalize(["添加 ", _interpolate(_named("n")), " 个成员"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择工作区权限"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户到工作区"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["添加成员到 ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将电邮粘贴到此处，用换行符或逗号隔开"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已匹配 ", _interpolate(_list(0)), " 封电邮/（共 ", _interpolate(_list(1)), " 封电邮）"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 与已连接的用户："])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["无法匹配 ", _interpolate(_list(0)), " 封电邮/（共 ", _interpolate(_list(1)), " 封电邮）"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查拼写或稍后单独连接"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 与服务台用户："])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已连接 ", _interpolate(_named("n")), " 个用户"]), _normalize(["已连接 ", _interpolate(_named("n")), " 个用户"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已邀请 ", _interpolate(_named("n")), " 个用户"]), _normalize(["已邀请 ", _interpolate(_named("n")), " 个用户"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量连接"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量邀请"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从您的服务台连接以考核其沟通情况"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["连接用户"]), _normalize(["连接用户"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电邮"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单独连接"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您希望连接的用户访问 Zendesk 质量保证，可以在这里邀请他们。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请连接的用户"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["邀请用户"]), _normalize(["邀请用户"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳过邀请"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要从您的服务台连接的用户以考核其沟通情况"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理您的订阅"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接服务台用户"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您的工作区指定一个独特的名称"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果启用，所有现有的帐户连接都将自动添加到新的工作区。"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所有连接添加到此工作区"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建工作区"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区名称"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建工作区"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将为直接添加到帐户（不分配工作区）的用户分配一个帐户级用户角色。您可以稍后将其添加到工作区。"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将为受邀用户自动分配一个帐户级别的用户角色。您可以稍后将其升级到管理员用户角色。"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下格式均有效"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将电邮粘贴到此处，用换行符或逗号隔开。"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["通过电邮邀请 ", _interpolate(_named("n")), " 个用户"]), _normalize(["通过电邮邀请 ", _interpolate(_named("n")), " 个用户"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将为添加到工作区的用户自动分配一个帐户级用户角色。您可以稍后将其升级为管理员用户角色。"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅邀请用户加入帐户，而不将其添加到工作区"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为受邀用户仅分配一个帐户权限，而不将其添加到任何工作区。"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接邀请用户到工作区并分配工作区权限"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加用户到工作区"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量邀请"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单独邀请"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只邀请到帐户中"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请到工作区中"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["邀请用户"]), _normalize(["邀请用户"]), _normalize(["邀请 ", _interpolate(_named("n")), " 个用户"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过电邮批量邀请用户"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["删除用户"]), _normalize(["删除用户"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消选择"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过电邮邀请用户"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有工作区"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择权限"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送邀请"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接的用户"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待处理电邮邀请用户"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 用户"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择工作区"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能专员"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理中心"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质量保证"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["劳动力管理"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已完成 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize(["已完成 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"]), _normalize(["已完成 ", _interpolate(_named("n")), " 个（共 ", _interpolate(_named("total")), " 个）"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["周期 ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核者"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑任务"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节省时间并布置自动考核任务，而不是手动查找和分配对话进行考核。"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 到期"])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可在对话视图侧菜单的“校准”部分添加会话。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未添加会话"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基准"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有会话"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过单击对话视图标题中的校准图标，可以将对话添加到会话中。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未添加对话"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["尚未进行考核。", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供一条校准考核"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根本原因"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跳转到"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法加载命令面板。"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到任何内容。"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 ID 查找"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载命令面板..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面、筛选器、对话、设置..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对话"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私密筛选器"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开筛选器"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["来自 ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自 API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["显示 ", _interpolate(_named("rangeStart")), " 到 ", _interpolate(_named("rangeEnd")), "（共 ", _interpolate(_named("total")), " ", _interpolate(_named("label")), "）"]), _normalize(["显示 ", _interpolate(_named("rangeStart")), " 到 ", _interpolate(_named("rangeEnd")), "（共 ", _interpolate(_named("total")), " ", _interpolate(_named("label")), "）"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏侧面板"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示侧面板"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换侧栏"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受的争议"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝的争议"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决的争议"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受的争议"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成的考核"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看的考核"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已开启"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["占总数的 ", _interpolate(_list(0))])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["考核"]), _normalize(["考核"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被考核者已查看的考核数量"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已查看的考核数量"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有争议的考核者"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议者"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按类别划分的争议"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一段时间内的争议"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解决时间"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解决时间（小时）"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均解决时间（分钟）"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解决"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["争议总数"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择 # 标签"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已接受"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复语言"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到语言"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有语言"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预测的推动因素"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到推动因素"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有推动因素"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字云词语"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到词语"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有词语"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提及"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最早"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未解决"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分接受"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 用 ", _interpolate(_list(1)), " 回应"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已解决"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["考核"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择肤色"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会话"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到会话"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到类别"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论长度"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到匹配长度"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有长度"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到筛选器"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到组"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有组"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到 # 标签"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有 # 标签"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到值"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有值"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到自定义字段"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务台工单标签"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到服务台工单标签"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有服务台工单标签"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加载结果…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题类型"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到问题类型"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加回应"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记分卡"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到记分卡"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有记分卡"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到评分"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有评分"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到来源"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有来源"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈原因"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到原因"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有原因"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调查"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到调查"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有调查"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到渠道"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有渠道"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到用户"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有用户"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到工作区"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有工作区"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回测验"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验已删除或尚未发布。双击链接或导航回测验列表。"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此测验尚未共享到您所属的工作区。请求管理员将您添加到此测验可以访问的工作区中。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验不可用"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授予不属于您的工作区的测验访问权限。无法保存并发布测验。"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让管理员保存并发布测验。"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您应将至少一个答案标为正确答案。"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段不能为空"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您目前看到的是测验的预览版。"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择可以访问测验的工作区。属于多个工作区的用户只能参加一次测验。选择“所有工作区”将不包括发布测验后新建的工作区。"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验访问"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出预览模式"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加测验"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布测验"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验设置"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存为草稿"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新测验"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解档"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选项"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加选项"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看起来正常，是吗？"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的答案已提交"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的测验已保存"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的测验已发布"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的测验已移除"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加问题"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档测验"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档的测验及其结果将不再显示在测验列表中。您仍可访问存档测验的结果，并在需要时将其解档。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档测验"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验一经发布就无法更改或编辑。您帐户中的每个用户都可以访问所有发布的测验。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否发布测验？"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定要删除此测验吗？此操作不可撤消。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否删除测验？"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案一经提交即无法更改。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否提交测验？"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解档测验"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解档测验可将其应用于测验概览中。它允许以前的参与者访问结果，允许新的参与者参加测验。"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解档测验"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制问题"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述（可选）"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下移问题"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上移问题"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览中只会显示有答案的问题。"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除问题"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交测验"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无标题测验"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验标题"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将永久删除测验及结果。"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者，您也可以存档测验，如此仍可以访问结果。"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已回答"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除测验"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这将永久删除测验及结果。"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["删除测验 ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制项目"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验有助于增强团队的信心并提升技能，提供一致的体验，并对新团队成员进行入门培训。"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以根据测验名称或作者姓名搜索测验。"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未创建测验"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出测验列表"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均评分"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成日期"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验/作者"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验/作者/工作区"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与者"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发布"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作区"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按测验、作者搜索"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存档"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃的"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有测验"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验已存档。"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验已解档。"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此测验没有收到所选工作区中用户的回复。"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此测验在存档之前未收到回复。"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有回复"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里您将看到测验答案的细目。\n还没有人填写您的测验，共享将会有所帮助。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有回复"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回复："])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均评分："])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概览"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - 复制"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制的链接"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到结果"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 的测验"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在排行榜中搜索参与者的姓名。"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出排行榜"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按参与者搜索"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排行榜"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换排行榜"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这意味着您将无法在面板上筛选此 # 标签，它也不会再在评论中自动补全。此操作不可撤消。"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签, ＃ 标签, ＃ 标签…"])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 # 标签"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 # 标签"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 # 标签"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许成员创建新的 # 标签"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已使用"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除 # 标签"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["删除 # 标签 ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签创建已锁定"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["＃ 标签创建已解锁"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已删除 ＃ 标签‘", _interpolate(_list(0)), "’"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["＃ 标签“", _interpolate(_list(0)), "”已重命名为‘", _interpolate(_list(1)), "’"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["已添加 1 个 # 标签"]), _normalize(["已添加 ", _interpolate(_named("count")), " 个 # 标签"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新名称"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建 # 标签"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["重命名 # 标签“", _interpolate(_named("tagRenamed")), "”"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用逗号或换行符分隔 # 标签"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索 # 标签"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["不用担心。您可以这样做 ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["就在这里"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接到工作区的连接"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加连接"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加一个连接到此工作区"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在此联系您的管理员进行更改"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在此联系 Zendesk 质量保证进行更改"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户下没有连接"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["似乎您尚未整合客户服务平台。"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除连接将从工作区 ", _interpolate(_named("workspaceName")), " 中移除对话，并删除所有相关数据，包括考核和争议。"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除连接"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["删除连接 ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择连接"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["在所选时间段内，某些活跃的自动质量保证类别没有显示在您的记分卡上。对于这些类别，可能尚未分析您在 ", _interpolate(_named("date")), "最后一次更改之前的对话。"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里将显示给您的所有反馈。尚无可显示的内容。"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到的考核。"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅显示评论活动"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处将显示您提供的所有反馈。开始使用对话视图。"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有提供的考核。"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供的考核"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到的考核"])}
  }
}