import dayjs from 'dayjs'
import { ref, watch } from 'vue'

import { firstDayOfWeek } from '@/styles/v-calendar'
import { session } from '@/composables/useSession'
import analytics from '@/utils/analytics'

import type { Session } from '../types'
import { updateSession } from '../api'

interface Props {
  session: Session
}

type Emit = ((event: 'update', session: Partial<Session>) => void) & ((event: 'delete', id: string) => void)

export default function (props: Props, { emit }: { emit: Emit }) {
  const showDatepicker = ref(false)
  const scheduledAt = ref(props.session.scheduledAt || '')

  async function updateSchedule() {
    const res = await updateSession(props.session.id, {
      notes: props.session.notes,
      title: props.session.title,
      scheduledAt: scheduledAt.value,
    })
    emit('update', res.session)
    analytics.coachingScheduleSession()
  }

  watch(scheduledAt, updateSchedule)

  watch(showDatepicker, () => {
    if (!scheduledAt.value) {
      scheduledAt.value = dayjs().toISOString()
    }
  })

  return {
    scheduledAt,
    showDatepicker,
    userLocale: session.user.locale.split('-')[0],
    firstDayOfWeek,
  }
}
