import {
  AssignmentV2_Settings_CYCLE,
  type AssignmentV2_UserAvatar,
  AssignmentV2_Settings_Goal_TYPE,
  AssignmentV2_Settings_Goal_Config_UNIT,
  AssignmentV2_Assignment_Status,
  AssignmentV2_Settings_RevieweeClass as RevieweeClass,
} from '@zendesk/zqa-services/assignments_v2'
import { type AssignmentV2_Assignment } from '@/modules/workspace/views/assignments/types/assignments'
import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { ALL_USERS_ID } from './constants'
import { getRequiredFiltersV2 } from './utils'

export const generateDefaultAssignmentFormV2 = (
  workspaceSelfReviewsEnabled: boolean,
): Partial<AssignmentV2_Assignment> => ({
  created: String(new Date()),
  name: '',
  status: AssignmentV2_Assignment_Status.UNDEFINED,
  participants: {
    reviewers: [
      {
        id: ALL_USERS_ID,
        name: i18n.t('settings.workspaces.assignments_v_2.steps.participants.reviewers.all'),
      } as AssignmentV2_UserAvatar,
    ],
    excludeReviewers: [],
    reviewees: [
      {
        id: ALL_USERS_ID,
        name: session.features.botQa
          ? i18n.t('settings.workspaces.assignments_v_2.steps.participants.reviewees.all_participants')
          : i18n.t('settings.workspaces.assignments_v_2.steps.participants.reviewees.all'),
      } as AssignmentV2_UserAvatar,
    ],
    excludeReviewees: [],
    reviewerGroups: [],
    revieweeGroups: [],
    excludeRevieweeGroups: [],
    excludeReviewerGroups: [],
    botReviewees: [],
    excludeBotReviewees: [],
  },
  filter: {
    autoQaOptions: [],
    options: getRequiredFiltersV2(),
    paymentId: '0',
    teamId: '0',
    spotlightOptions: [],
    customCategoryOptions: [],
  },
  settings: {
    revieweeClass: RevieweeClass.USER,
    cycleLength: AssignmentV2_Settings_CYCLE.WEEKLY,
    goal: {
      type: AssignmentV2_Settings_Goal_TYPE.CONVERSATION,
      config: {
        revieweeMinimum: 0,
        unit: AssignmentV2_Settings_Goal_Config_UNIT.PERCENTAGE,
        value: 100,
        assigned: false,
        allowSelfAssignment: workspaceSelfReviewsEnabled,
        allowConversationReplacement: true,
      },
      periodicUpdateEnabled: false,
    },
    startDate: new Date().toISOString(),
    endDate: undefined,
    timeZone: null,
    cycleConfig: undefined,
  },
  updated: String(new Date()),
})
