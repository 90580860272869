import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "wrapperRef",
    class: _normalizeClass(_ctx.$style.wrapper),
    onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMove && _ctx.handleMove(...args))),
    onMouseout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetHover && _ctx.resetHover(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34 /* CLASS, NEED_HYDRATION */))
}