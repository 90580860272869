export const NR_OF_WIDE_CIRCLES = 2

export default (
  yIndex: number,
  yIndexMax: number,
  { nrOfWideCircles = NR_OF_WIDE_CIRCLES, wideProportion = 0.3 } = {},
): number => {
  const lastWideCircleInnerYIndex = nrOfWideCircles + 1
  const lastWideCircleOuterYIndex = nrOfWideCircles + 2

  // divide the range [0, 1] proportionally as we don't have enough circles
  if (yIndexMax < lastWideCircleOuterYIndex) return yIndex / yIndexMax

  const y1 = 1 / yIndexMax
  if (yIndex === 0) return 0
  if (yIndex === yIndexMax) return 1
  if (yIndex >= 1 && yIndex <= lastWideCircleInnerYIndex) return y1 + (yIndex - 1) * wideProportion

  const sumOfWideProportions = y1 + nrOfWideCircles * wideProportion
  const sumOfNarrowProportions = 1 - sumOfWideProportions
  const nrOfNarrowSlices = yIndexMax - lastWideCircleInnerYIndex
  const narrowProportion = sumOfNarrowProportions / nrOfNarrowSlices

  return sumOfWideProportions + (yIndex - lastWideCircleOuterYIndex + 1) * narrowProportion
}
