import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-semibold", _ctx.$style.informationSunburst])
  }, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.errorTitle)
          }, _toDisplayString(_ctx.$t('conversations.visual_filters.sunburst_loading_error')), 3 /* TEXT, CLASS */),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass([_ctx.$style.retry, "content-link"]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload')))
          }, _toDisplayString(_ctx.$t('conversations.visual_filters.sunburst_reload')), 3 /* TEXT, CLASS */)
        ], 64 /* STABLE_FRAGMENT */))
      : (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_spinner, { key: 1 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.showOutOf)
              ? (_openBlock(), _createBlock(_component_i18n_t, {
                  key: 0,
                  keypath: "conversations.visual_filters.out_of",
                  tag: "div",
                  class: _normalizeClass(_ctx.$style.description),
                  plural: _ctx.count
                }, {
                  count: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.count),
                      "data-testid": "sunburst-total"
                    }, _toDisplayString(_ctx.formatNumber(_ctx.count)), 3 /* TEXT, CLASS */)
                  ]),
                  total: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.$style.secondRowTemplate)
                    }, _toDisplayString(_ctx.root.value), 3 /* TEXT, CLASS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["class", "plural"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.count),
                    "data-testid": "sunburst-total"
                  }, _toDisplayString(_ctx.formatNumber(_ctx.count)), 3 /* TEXT, CLASS */),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.description)
                  }, _toDisplayString(_ctx.description), 3 /* TEXT, CLASS */)
                ], 64 /* STABLE_FRAGMENT */))
          ]))
  ], 2 /* CLASS */))
}