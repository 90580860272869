import { SurveyStatus } from '../../types'
import type { LazySurveyConfigErrors, SurveyState } from './survey.types'

export const defaultSurveyState: SurveyState = {
  data: {
    aiAssistEnabled: false,
    id: '',
    brand: '',
    mailFromName: '',
    mailFromAddress: '',
    mailSubject: '',
    introMessage: '',
    question: '',
    customQuestion: '',
    bgColor: '',
    btnColor: '',
    logo: '',
    thankYouMessage: '',
    expiryDays: '',
    reasonList: [],
    showReasons: false,
    questionScale: 'SCALE_5',
    questionScaleDisplay: 'EMOJI',
    draftParentId: '2',
    status: SurveyStatus.DRAFT,
    deliverySettings: [],
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    translations: [],
    questionTypeId: '',
    leftRatingScaleLabel: '',
    rightRatingScaleLabel: '',
    privacyLink: '',
    termsLink: '',
  },
  isLoading: false,
  notFound: false,
}

export const defaultLazyErrors: LazySurveyConfigErrors = {
  customTypeNotSavedError: false,
  noCustomQuestion: false,
}
