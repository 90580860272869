import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { createSharedComposable } from '@vueuse/core'
import type { QuestionType, QuestionTypesResponse } from '@zendesk/zqa-services/surveys'
import { sortBy } from 'lodash-es'
import i18n from '@/i18n'
import {
  getQuestionTypes,
  createQuestionType as createQuestionTypeApi,
  updateQuestionType as updateQuestionTypeApi,
} from '@/modules/survey/api'

export default createSharedComposable(() => {
  const customQuestionTypes = computed(() => questionTypes.value?.filter((questionType) => questionType.isCustom))

  const {
    data: questionTypes,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['question-types'],
    queryFn: () => getQuestionTypes(),
    select: (resp) => sortQuestionTypes(resp.types),
    refetchOnMount: false,
  })

  function getQuestionTypeById(id: QuestionType['id']) {
    return questionTypes.value?.find((questionType) => questionType.id === id)
  }

  function getQuestionTypeByName(name: QuestionType['name']) {
    return questionTypes.value?.find((questionType) => questionType.name === name)
  }

  function getQuestionTypeLabel(questionTypeId: string) {
    const questionType = getQuestionTypeById(questionTypeId)

    if (questionType?.name === 'CSAT' && !questionType.isCustom) return i18n.t('csat_dashboard.tags.sources.csat')
    if (questionType?.name === 'CES' && !questionType.isCustom) return i18n.t('csat_dashboard.tags.sources.ces')

    return questionType?.name || i18n.t('csat_dashboard.donut.title')
  }

  function validateQuestionType(name: string): string | null {
    if (!name) return i18n.t('settings.survey.edit.sections.content.define_type.empty_error')

    const existingQuestionType = getQuestionTypeByName(name)
    if (existingQuestionType) return i18n.t('settings.survey.edit.sections.content.define_type.duplicate_error')

    return null
  }

  async function createQuestionType(name: string): Promise<QuestionTypesResponse | Error> {
    const error = validateQuestionType(name)
    if (error) return Promise.resolve(new Error(error))

    const response = await createQuestionTypeApi({ name })
    await refetch()

    return response
  }

  async function updateQuestionType(id: string, name: string): Promise<QuestionTypesResponse | Error> {
    const error = validateQuestionType(name)
    if (error) return Promise.resolve(new Error(error))

    const response = await updateQuestionTypeApi(id, { name })
    await refetch()

    return response
  }

  return {
    questionTypes,
    customQuestionTypes,
    getQuestionTypeById,
    getQuestionTypeLabel,
    createQuestionType,
    updateQuestionType,
    loadQuestionTypes: refetch,
    isFetching,
  }
})

function sortQuestionTypes(types: QuestionType[]) {
  const presetTypes = types.filter((type) => !type.isCustom)
  const customTypes = types.filter((type) => type.isCustom)
  const orderedCustomTypes = sortBy(customTypes, 'name')
  return [...presetTypes, ...orderedCustomTypes]
}
