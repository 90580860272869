import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_step_form = _resolveComponent("multi-step-form")!

  return (_openBlock(), _createBlock(_component_multi_step_form, {
    sections: _ctx.breadcrumbsSections,
    loading: _ctx.state.isLoading,
    "active-section-id": _ctx.activeSectionId,
    onSelected: _ctx.handleToggleSection
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeSection.formComponent), {
        ref: "sectionComponentRef",
        "auth-data": _ctx.authData,
        class: _normalizeClass(_ctx.$slots.default && 'w-1/2'),
        onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('save'))),
        onPublish: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('publish')))
      }, null, 40 /* PROPS, NEED_HYDRATION */, ["auth-data", "class"])),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["sections", "loading", "active-section-id", "onSelected"]))
}