import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-90 mb-4 text-sm font-medium" }
const _hoisted_2 = { class: "text-gray-60 text-sm" }
const _hoisted_3 = { class: "my-6 grid grid-cols-2 gap-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "mx-7 mt-6 flex justify-between gap-1 overflow-x-auto text-xs font-medium leading-loose" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "text-gray-60 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_q_modal = _resolveComponent("q-modal")!

  return (_openBlock(), _createBlock(_component_q_modal, {
    dismissable: "",
    show: _ctx.show,
    title: _ctx.$t('settings.workspaces.rating_categories.categories_type_modal.title'),
    class: _normalizeClass(_ctx.$style.modal),
    onClose: _ctx.close
  }, {
    actions: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.actions)
      }, [
        _createVNode(_component_ui_button, {
          secondary: "",
          class: "mr-3",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('universal.cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_ui_button, {
          disabled: _ctx.savedScorecardType === _ctx.selectedScorecardType,
          onClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('universal.save')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.subtitle')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.cta', { workspace: _ctx.workspaceName })), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          class: _normalizeClass(_ctx.selectedScorecardType === _ctx.ScorecardType.Dynamic ? _ctx.$style.labelSelected : _ctx.$style.label)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.dynamicCard)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect1)
                }, null, 2 /* CLASS */),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect2)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect3)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect4)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect5)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */)
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.inputContainer)
          }, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedScorecardType) = $event)),
              value: _ctx.ScorecardType.Dynamic,
              type: "radio",
              class: _normalizeClass(_ctx.$style.input)
            }, null, 10 /* CLASS, PROPS */, _hoisted_4), [
              [_vModelRadio, _ctx.selectedScorecardType]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["text-sm font-medium", _ctx.$style.radioText])
            }, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.radio_label_dynamic')), 3 /* TEXT, CLASS */)
          ], 2 /* CLASS */)
        ], 2 /* CLASS */),
        _createElementVNode("label", {
          class: _normalizeClass(_ctx.selectedScorecardType === _ctx.ScorecardType.Static ? _ctx.$style.labelSelected : _ctx.$style.label)
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["text-gray-60 bg-gray-20 h-6 whitespace-nowrap rounded-sm px-3 opacity-50", { '!bg-active-primary text-white opacity-100': _ctx.selectedScorecardType === _ctx.ScorecardType.Static }])
            }, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.scorecard.chat')), 3 /* TEXT, CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["text-gray-60 bg-gray-20 h-6 whitespace-nowrap rounded-sm px-3 opacity-50", { 'opacity-100': _ctx.selectedScorecardType === _ctx.ScorecardType.Static }])
            }, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.scorecard.phone')), 3 /* TEXT, CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["text-gray-60 bg-gray-20 h-6 truncate whitespace-nowrap rounded-sm px-3 opacity-50", { 'opacity-100': _ctx.selectedScorecardType === _ctx.ScorecardType.Static }])
            }, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.scorecard.onboarding')), 3 /* TEXT, CLASS */)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.staticCard)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect1)
                }, null, 2 /* CLASS */),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect2)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect3)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.cardRow)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.rectContainer)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.rect4)
                }, null, 2 /* CLASS */)
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.emojis)
              }, "👎 👍", 2 /* CLASS */)
            ], 2 /* CLASS */)
          ], 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.inputContainer)
          }, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedScorecardType) = $event)),
              value: _ctx.ScorecardType.Static,
              type: "radio",
              class: _normalizeClass(_ctx.$style.input)
            }, null, 10 /* CLASS, PROPS */, _hoisted_6), [
              [_vModelRadio, _ctx.selectedScorecardType]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["text-sm font-medium", _ctx.$style.radioText])
            }, _toDisplayString(_ctx.$t('settings.workspaces.rating_categories.categories_type_modal.radio_label_static')), 3 /* TEXT, CLASS */)
          ], 2 /* CLASS */)
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedScorecardType === _ctx.ScorecardType.Dynamic
          ? _ctx.$t('settings.workspaces.rating_categories.categories_type_modal.explanation_dynamic')
          : _ctx.$t('settings.workspaces.rating_categories.categories_type_modal.explanation_static')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show", "title", "class", "onClose"]))
}