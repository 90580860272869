import { toTypedSchema } from '@vee-validate/zod'
import z from 'zod'
import i18n from '@/i18n'

export const rootCausesSchema = toTypedSchema(
  z.object({
    draggableItems: zUniqueArray(
      (obj) => obj.value,
      z.array(
        z.object({
          id: z.string().optional(),
          value: z
            .string()
            .trim()
            .min(1, { message: i18n.t('settings.workspaces.rating_categories.reason_empty_error') }),
        }),
      ),
    ).optional(),
  }),
)

function zUniqueArray<ArrSchema extends z.ZodArray<z.ZodTypeAny, 'many'>, UniqueVal>(
  uniqueBy: (item: z.infer<ArrSchema>[number]) => UniqueVal,
  schema: ArrSchema,
) {
  return schema.superRefine((items, ctx) => {
    const uniqueValues = new Set<UniqueVal>()

    for (const [i, item] of items.entries()) {
      const value = uniqueBy(item)
      if (uniqueValues.has(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t('settings.workspaces.rating_categories.reason_duplicate_error'),
          path: [i, 'value'],
        })
      } else {
        uniqueValues.add(value)
      }
    }
  })
}
