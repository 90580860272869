import Mention from '@tiptap/extension-mention'
import Image from '@tiptap/extension-image'
import { DOMParser } from '@tiptap/pm/model'
import { PluginKey } from '@tiptap/pm/state'
import { mergeAttributes, textblockTypeInputRule } from '@tiptap/core'
import Code from '@tiptap/extension-code'
import CodeBlock, { tildeInputRegex } from '@tiptap/extension-code-block'
import { EmojiIndex } from 'emoji-mart-vue-fast/src'
import emojiData from 'emoji-mart-vue-fast/data/all.json'
import { type Editor } from '@tiptap/vue-3'
import { klausmojis } from './emoji'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mention: {
      /**
       * Set mention label and id
       */
      setMention: (options: { label: string; id: string }) => ReturnType
    }
  }
}

export const CustomMention = Mention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-mention'),
        renderHTML: (attributes) => {
          if (!attributes.id) return {}
          return { 'data-mention': attributes.id }
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.textContent?.replace(String(this.options.suggestion.char), ''),
      },
    }
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      `${this.options.suggestion.char}${node.attrs.label}`,
    ]
  },
  renderText({ node }) {
    return `${this.options.suggestion.char}${node.attrs.label}`
  },
  addKeyboardShortcuts() {
    return {}
  },
  addCommands() {
    return {
      setMention: (props) => (editor) => {
        return editor
          .chain()
          .focus()
          .insertContent([
            { type: 'mention', attrs: props },
            { type: 'text', text: ' ' },
          ])
          .run()
      },
    }
  },
  parseHTML() {
    return [{ tag: 'span[data-mention]' }]
  },
})

export const Hashtag = CustomMention.extend({
  name: 'hashtag',
  addOptions() {
    return {
      ...this.parent?.(),
      suggestion: {
        pluginKey: new PluginKey('hashtag'),
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: 'hashtag', attrs: props },
              { type: 'text', text: ' ' },
            ])
            .run()
        },
      },
    }
  },
  addAttributes() {
    return {
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-hashtag'),
        renderHTML: (attributes) => {
          if (!attributes.label) return {}

          return { 'data-hashtag': attributes.label }
        },
      },
    }
  },
  parseHTML() {
    return [{ tag: 'span[data-hashtag]' }]
  },
})

export const EmojiSearch = Mention.extend({
  name: 'emoji-search',
  addOptions() {
    return {
      ...this.parent?.(),
      suggestion: {
        pluginKey: new PluginKey('emojisearch'),
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: 'emoji-search', attrs: props },
              { type: 'text', text: ' ' },
            ])
            .run()
        },
      },
    }
  },
  addAttributes() {
    return {
      label: { default: null },
    }
  },
  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), node.attrs.label]
  },
  renderText({ node }) {
    return `${node.attrs.label}`
  },
  addKeyboardShortcuts() {
    return {}
  },
})

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    klausmoji: {
      /**
       * Set emoji with url
       */
      setKlausmoji: (options: { src: string }) => ReturnType
    }
  }
}

export const Klausmoji = Image.extend({
  name: 'klausmoji',
  inline: true,
  group: 'inline',
  draggable: false,
  addAttributes() {
    return {
      src: {},
      alt: { default: null },
      title: { default: null },
      height: { default: 18 },
      width: { default: 18 },
    }
  },
  parseHTML() {
    return [{ tag: 'img.klausmoji[src]' }]
  },
  addCommands() {
    return {
      setKlausmoji:
        (options) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create(options)
          if (dispatch) tr.replaceRangeWith(selection.from, selection.to, node)

          return true
        },
    }
  },
})

const elementFromString = (value: string) => {
  const element = document.createElement('span')
  element.innerHTML = value.trim()

  return element
}

export const insertHTML = ({ state, view }: Editor, value: string) => {
  const { selection } = state
  const element = elementFromString(value)
  const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
  const transaction = state.tr.insert(selection.anchor, slice.content)

  view.dispatch(transaction)
}

export const CustomCode = Code.extend({
  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      ArrowRight: () => {
        const state = this.editor.state
        const { from, to } = state.selection

        if (from > 1 && from === to) {
          let codeOnLeft = false
          state.doc.nodesBetween(from - 1, to - 1, (node) => {
            const code = node.marks.find((markItem) => markItem.type.name === 'code')
            if (code) codeOnLeft = true
          })

          let noCodeUnderCursor = true
          state.doc.nodesBetween(from, to, (node) => {
            const code = node.marks.find((markItem) => markItem.type.name === 'code')
            if (code) noCodeUnderCursor = false
          })

          let nothingOnRight = true
          state.doc.nodesBetween(from + 1, to + 1, (node) => {
            if (node) nothingOnRight = false
          })

          if (codeOnLeft && noCodeUnderCursor && nothingOnRight) {
            return this.editor.chain().unsetCode().insertContent(' ').run()
          }
        }

        return false
      },
    }
  },
})

export const CustomCodeBlock = CodeBlock.extend({
  addInputRules() {
    return [
      textblockTypeInputRule({
        find: /^```$/,
        type: this.type,
        getAttributes: (match) => ({
          language: match[1],
        }),
      }),
      textblockTypeInputRule({
        find: tildeInputRegex,
        type: this.type,
        getAttributes: (match) => ({
          language: match[1],
        }),
      }),
    ]
  },
})

export const emojiIndex = new EmojiIndex(emojiData, { custom: klausmojis })
