import { session } from '@/composables/useSession'

export default [
  'HAS_CHURN_RISK',
  'HAS_ESCALATION',
  'HAS_FOLLOW_UP',
  'HAS_EXTRA_MILE',
  'HAS_DEADAIR_EXCEEDED',
  'HAS_RECORDING_DISCLOSURE_MISSING',
  'SLA_BREACH',
  ...(session.features.botQa ? ['BOT_COMMUNICATION_EFFICIENCY', 'BOT_REPETITION'] : []),
]
