import type { SingleQuizLeaderboardResponse } from '@zendesk/zqa-services/quizzes'
import type { CSVURLResponse } from '@zendesk/zqa-services/dashboard'
import { api } from '@/api'

import type {
  Quiz,
  Participant,
  QuizPayload,
  QuizResponseOverview,
  QuizRespondents,
  QuizRespondentResponse,
  AgentQuiz,
  QuizForm,
  QuizFormPayload,
} from '../types'

const getWorkspaceHeader = (idList: (number | string)[]) => ({ 'X-Klaus-Workspace': idList.toString() })

export const getQuiz = (id: string, workspaceIds: number[]) =>
  api
    .get(`quizzes/${id}`, {
      headers: getWorkspaceHeader(workspaceIds),
    })
    .json<Quiz>()

export const addQuiz = (data: QuizPayload) =>
  api.post(`quizzes`, { json: data, headers: getWorkspaceHeader(data.workspaceIds) }).json<Quiz>()

export const updateQuiz = (id: string, data: QuizPayload) =>
  api.put(`quizzes/${id}`, { json: data, headers: getWorkspaceHeader(data.workspaceIds) }).json<Quiz>()

export const removeQuiz = (id: string) => api.delete(`quizzes/${id}`).json()

export const getQuizList = (workspaceIds: number[]) =>
  api
    .get('quizzes', {
      headers: getWorkspaceHeader(workspaceIds),
    })
    .json<{ quizzes: Quiz[] }>()

export const getQuizListCsv = (workspaceIds: number[]) =>
  api.get('quizzes/quizzes.csv', { headers: getWorkspaceHeader(workspaceIds) }).json<CSVURLResponse>()

export const getLeaderboard = (workspaceIds: number[]) =>
  api
    .get('quizzes/leaderboard', {
      headers: getWorkspaceHeader(workspaceIds),
    })
    .json<{ leaderboardUsers: Participant[] }>()

export const getLeaderboardCsv = (workspaceIds: number[]) =>
  api
    .get('quizzes/leaderboard.csv', {
      headers: getWorkspaceHeader(workspaceIds),
    })
    .json<CSVURLResponse>()

export const getQuizResponseOverview = (id: string, workspaceIds: number[]) =>
  api.get(`quizzes/${id}/overview`, { headers: getWorkspaceHeader(workspaceIds) }).json<QuizResponseOverview>()

export const getQuizResponseOverviewCsv = (id: string, workspaceIds: number[]) =>
  api.get(`quizzes/${id}/overview.csv`, { headers: getWorkspaceHeader(workspaceIds) }).json<CSVURLResponse>()

export const getQuizRespondents = (id: string, workspaceIds: number[]) =>
  api.get(`quizzes/${id}/responses`, { headers: getWorkspaceHeader(workspaceIds) }).json<QuizRespondents>()

export const getQuizResponse = (id: string, responseId: string, workspaceIds: number[]) =>
  api
    .get(`quizzes/${id}/responses/${responseId}`, { headers: getWorkspaceHeader(workspaceIds) })
    .json<QuizRespondentResponse>()

export const getAgentQuizList = (
  agentId: string,
  workspaceIds: number[],
  params?: { fromDate?: string; toDate?: string },
) => {
  const query = new URLSearchParams()
  if (params?.fromDate) query.append('fromDate', params.fromDate)
  if (params?.toDate) query.append('toDate', params.toDate)
  return api
    .get(`quizzes/agent/${agentId}?${query}`, { headers: getWorkspaceHeader(workspaceIds) })
    .json<{ quizzes: AgentQuiz[] }>()
}
export const getQuizForm = (id: string, workspaceIds: number[]) =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`quizzes/${id}/for-response`, { headers: getWorkspaceHeader(workspaceIds) })
    .json<QuizForm>()

export const submitQuizAnswer = (id: string, data: QuizFormPayload, workspaceIds: number[]) =>
  api.post(`quizzes/${id}/response`, { json: data, headers: getWorkspaceHeader(workspaceIds) }).json<{ id: string }>()

export const getQuizLeaderboard = (quizId: string, workspaceIds: number[]) =>
  api
    .get(`quizzes/${quizId}/leaderboard`, { headers: getWorkspaceHeader(workspaceIds) })
    .json<SingleQuizLeaderboardResponse>()
