import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.wrapper, _ctx.isGroup && _ctx.$style.group])
  }, [
    (!_ctx.isGroup)
      ? (_openBlock(), _createBlock(_component_q_avatar, {
          key: 0,
          class: _normalizeClass(_ctx.$style.avatar),
          src: _ctx.reviewer.avatar,
          size: 24,
          username: _ctx.reviewer.name
        }, null, 8 /* PROPS */, ["class", "src", "username"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.name)
    }, _toDisplayString(_ctx.reviewer.name), 3 /* TEXT, CLASS */)
  ], 2 /* CLASS */))
}