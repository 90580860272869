import { reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

import { FilterTimePeriod } from '@/modules/dashboard/types'

import type { TimeParams } from '../../../types'

export const timeParams = reactive<TimeParams>({
  timePeriod: FilterTimePeriod.Month,
  fromDate: dayjs().utc().subtract(30, 'day').startOf('day').format(),
  toDate: dayjs().utc().endOf('day').format(),
})

const setTimeParams = (time?: TimeParams) => {
  if (!time) return

  const { timePeriod, fromDate, toDate } = time
  if (timePeriod) timeParams.timePeriod = timePeriod
  if (fromDate) timeParams.fromDate = fromDate
  if (toDate) timeParams.toDate = toDate
}

export default (time?: TimeParams) => {
  const router = useRouter()
  setTimeParams(time)
  watch(timeParams, (params) => router.replace({ query: params }))
  return { timeParams, setTimeParams }
}
