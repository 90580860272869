import { computed, reactive } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { type AssignmentV2_GetUserAssignmentConversationsResponse_TicketListItem as AssignmentTaskItem } from '@zendesk/zqa-services/assignments_v2'
import type { TicketListItemExt } from '@/modules/conversations/types'

interface List {
  isLoading: boolean
  items: AssignmentTaskItem[] | TicketListItemExt[]
  total?: string
  type: ListType
}

export enum ListType {
  Assignment = 'assignment',
  Calibration = 'calibration',
}

export default createSharedComposable(() => {
  const state = reactive<List>({
    isLoading: true,
    items: [],
    total: '0',
    type: ListType.Assignment,
  })

  const isAssignmentsList = computed(() => state.type === ListType.Assignment)
  const isCalibrationsList = computed(() => state.type === ListType.Calibration)

  return {
    state,
    isAssignmentsList,
    isCalibrationsList,
  }
})
