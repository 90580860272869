import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import {
  type Pin,
  type PinUser,
  type PinFolder,
  type SearchPinsMatch,
  ListPinFoldersPayload_SortField as PinFoldersSortOption,
} from '@zendesk/zqa-services/pins'
import { bus } from '@/utils/bus'
import analytics from '@/utils/analytics'

import { listPinFolders, searchPins } from '../api'

export default createSharedComposable(() => {
  const route = useRoute()
  const currentSortValue = ref(PinFoldersSortOption.ALPHABETICALLY)
  const pins = ref([] as Pin[])
  const pinnedItems = ref(null)
  const folders = ref<PinFolder[]>([])
  const messages = ref<SearchPinsMatch[]>([])

  const folderUser = ref<PinUser | null | undefined>()
  const hasBeenInViewport = ref<string[]>([])
  const loading = ref(false)

  const hasSearched = ref(false)
  const query = ref('')

  const selectedPin = computed(() => {
    return route.params.pinId && pins.value.find(({ id }) => id === route.params.pinId)
  })

  const unreadPinId = computed(() => {
    return pins.value.find((pin) => pin.unread)?.id
  })
  const unreadPinCount = computed(() => {
    return pins.value.filter((pin) => pin.unread && !hasBeenInViewport.value.includes(pin.id)).length
  })

  const getPins = async (sortValue?: PinFoldersSortOption) => {
    if (sortValue !== undefined) currentSortValue.value = sortValue
    query.value = ''
    const res = await listPinFolders(currentSortValue.value)
    hasSearched.value = false
    folders.value = res.folders
    messages.value = []
  }

  const filterPins = async () => {
    const res = await searchPins(query.value)
    hasSearched.value = true
    folders.value = res.folders
    messages.value = res.messages
  }

  const refreshPinFolderList = async () => {
    if (!query.value) {
      await getPins()
    } else if (query.value.length > 2) {
      await filterPins()
    }
    bus.$emit('refresh-pins-count')
  }

  const search = async (q: string) => {
    query.value = q
    await refreshPinFolderList()
    if (query.value) analytics.pinsSearched()
  }

  return {
    currentSortValue,
    pins,
    pinnedItems,
    selectedPin,
    folderUser,
    folders,
    loading,
    messages,
    hasBeenInViewport,
    unreadPinId,
    unreadPinCount,

    getPins,
    refreshPinFolderList,
    search,

    query,
    hasSearched,
  }
})
