import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quiz_title = _resolveComponent("quiz-title")!
  const _component_question_builder = _resolveComponent("question-builder")!
  const _component_question_editor_footer = _resolveComponent("question-editor-footer")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("main", {
    ref: "rootRef",
    class: _normalizeClass([_ctx.$style.container, "h-full"])
  }, [
    _createElementVNode("form", {
      class: _normalizeClass(_ctx.$style.form),
      "data-testid": "quiz-questions-list"
    }, [
      _createVNode(_component_quiz_title, { "show-validation": _ctx.showValidation }, null, 8 /* PROPS */, ["show-validation"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.quiz.fields, (question) => {
        return (_openBlock(), _createElementBlock("div", {
          key: question.id,
          "data-testid": `question-${question.id}`
        }, [
          _createElementVNode("div", {
            id: 'quiz-question-' + question.id,
            class: _normalizeClass(_ctx.$style.questionContainer)
          }, [
            _createVNode(_component_question_builder, {
              id: question.id,
              "show-validation": _ctx.showValidation
            }, null, 8 /* PROPS */, ["id", "show-validation"]),
            _createVNode(_component_question_editor_footer, {
              id: question.id,
              "single-question": _ctx.state.quiz.fields.length < 2,
              onMoveup: _ctx.handleMoveQuestionUp,
              onMovedown: _ctx.handleMoveQuestionDown,
              onClone: _ctx.handleCloneQuestion,
              onRemove: _ctx.handleRemoveQuestion
            }, null, 8 /* PROPS */, ["id", "single-question", "onMoveup", "onMovedown", "onClone", "onRemove"])
          ], 10 /* CLASS, PROPS */, _hoisted_2)
        ], 8 /* PROPS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */)),
      _createVNode(_component_ui_button, {
        "data-testid": "add-question-button",
        class: _normalizeClass(_ctx.$style.addQuestionButton),
        secondary: "",
        small: "",
        onClick: _ctx.handleAddQuestion
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('quizzes.form.add_question')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "onClick"])
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}