import { v4 as uuid } from 'uuid'
import type { CustomField } from '@zendesk/zqa-services/filters'
import { session } from '@/composables/useSession'

import i18n from '@/i18n'
import { translationExists } from '@/i18n/utils'
import type { DeconstructedOption } from '@/modules/conversations/utils/filters.ts'
import type { DefaultFilterOption } from '@/modules/conversations/api/filters'
import type { FilterOption } from '@/components/filters/types'
import autoInsightsFilters from '@/components/filters/autoInsightsFilters'
import { type DropdownOption } from '../../utils/filters'

const translateLabel = (opt: DeconstructedOption['option']) => {
  let label = ''
  if (opt?.label) label = translationExists(opt.label) ? i18n.t(opt.label) : opt.label
  return label
}

export const getDescriptionKey = (opt: DeconstructedOption['option']) => {
  const optName = opt.name?.toLocaleLowerCase()
  const botQaFilters = ['related', 'reviewee']

  if (opt.isSpotlight) return 'filter.option.description.custom_spotlight'
  if (session.features.botQa && botQaFilters.includes(optName)) {
    return `filter.option.description.${optName}_with_bots`
  }

  return `filter.option.description.${optName}`
}

export const getDescriptionOptions = (opt: DeconstructedOption['option']) => {
  return opt.isSpotlight ? [opt.customPhrases?.join(', ')] : null
}

export const addLabel = (opt: DeconstructedOption['option']): FilterOption => {
  // @ts-expect-error not sure if opt can be undefined
  if (!opt) return opt

  const descriptionKey = getDescriptionKey(opt)

  return {
    ...opt,
    name: opt.name || '',
    label: translateLabel(opt),
    description: translationExists(descriptionKey) ? i18n.t(descriptionKey, getDescriptionOptions(opt)) : undefined,
  }
}

export const fieldsMapToFieldItems = (fieldsMap: Record<string, CustomField>): DropdownOption[] => {
  const customFields = Object.values(fieldsMap || {}).map((opt) => ({
    name: opt.externalId,
    label: opt.name,
    isCustom: true,
    isAutoQa: false,
    isSpotlight: false,
    allowMultiple: false,
    isCustomCategory: false,
  }))
  const uniqueCustomFields = [...new Map(customFields.map((item) => [item.name, item])).values()]

  return uniqueCustomFields
}

export const getEmptyFilterOption = (): DeconstructedOption => ({
  id: uuid(),
  option: {
    name: '',
    label: i18n.t('conversations.filters.form.option_picker'),
  },
  condition: {},
  values: [],
})

interface FilterCondition {
  name: string
  label: string
  valueRequired: boolean
}

export const getFilterConditionsByOptionName = (name: string, options: DefaultFilterOption[]): FilterCondition[] => {
  const option = options.find((opt) => opt.filterOption === name || opt.name === name)

  if (!option) return []

  return option.conditions.map((c) => ({
    name: c.condition,
    label: i18n.t(c.labelCode),
    valueRequired: c.valueRequired,
  }))
}

export const isDowngradedFilter = (option: DeconstructedOption['option']) => {
  return (
    (option.name.includes('_SENTIMENT') && !session.features.sentiment) ||
    (option.name === 'DISPUTE_STATUS' && !session.features.disputes) ||
    (autoInsightsFilters.includes(option.name) && !session.features.autoInsights) ||
    (option.isAutoQa && !session.features.autoQa)
  )
}

export const translateLabels = (values: DeconstructedOption[]) => {
  return values.map((value) => ({
    ...value,
    option: addLabel(value.option),
    condition: addLabel(value.condition),
  }))
}
