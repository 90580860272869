import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { ref } from 'vue'
import type { BotsResponse, Bot } from '@zendesk/zqa-services/users'
import { session } from '@/composables/useSession'
import { getBots, editBot, setBotAsUser } from '../bots/api'

interface Props {
  workspaceIds: number[]
}

const filter = ref('')

export default function useBots(props: Props = { workspaceIds: [] }) {
  filter.value = ''

  const queryClient = useQueryClient()

  // Fetching bots data
  const { data: botsData, isFetching } = useQuery({
    queryKey: ['bots', props.workspaceIds],
    enabled: session.features.botQa,
    queryFn: () => getBots(props?.workspaceIds || []),
  })

  // Mutation for editing a bot
  const { mutate: editBotData } = useMutation({
    mutationFn: ({ botId, changes }: { botId: string; changes: Partial<Bot> }) => editBot(botId, changes),
    onMutate: async ({ botId, changes }) => {
      await queryClient.cancelQueries({ queryKey: ['bots', props.workspaceIds] })

      const previousBotsResponse = queryClient.getQueryData<BotsResponse>(['bots', props.workspaceIds])

      if (previousBotsResponse) {
        const updatedBotsResponse = {
          ...previousBotsResponse,
          bots: previousBotsResponse.bots.map((bot) => (bot.id === botId ? { ...bot, ...changes } : bot)),
        }

        queryClient.setQueryData(['bots', props.workspaceIds], updatedBotsResponse)
      }

      return { previousBotsResponse }
    },
    onError: (_err, _variables, context) => {
      if (context?.previousBotsResponse) {
        queryClient.setQueryData(['bots', props.workspaceIds], context.previousBotsResponse)
      }
    },
  })

  // Mutation for setting a bot as user
  const { mutate: markBotAsUser } = useMutation({
    mutationFn: ({ botId, workspaceIds }: { botId: string; workspaceIds: number[] }) =>
      setBotAsUser(botId, workspaceIds),
    onMutate: async ({ botId }) => {
      await queryClient.cancelQueries({ queryKey: ['bots', props.workspaceIds] })

      const previousBotsResponse = queryClient.getQueryData<BotsResponse>(['bots', props.workspaceIds])

      if (previousBotsResponse) {
        const updatedBotsResponse = {
          ...previousBotsResponse,
          bots: previousBotsResponse.bots.map((bot) => (bot.id === botId ? null : bot)).filter(Boolean),
        }

        queryClient.setQueryData(['bots', props.workspaceIds], updatedBotsResponse)
      }

      return { previousBotsResponse }
    },
    onError: (_err, _variables, context) => {
      if (context?.previousBotsResponse) {
        queryClient.setQueryData(['bots', props.workspaceIds], context.previousBotsResponse)
      }
    },
  })

  return { botsData, isFetching, filter, editBotData, markBotAsUser }
}
