import { useQuery } from '@tanstack/vue-query'
import { uniq } from 'lodash-es'
import { computed, type ComputedRef } from 'vue'
import { getCsatReasons } from '@/modules/dashboard/csat/api'

type Props = ComputedRef<{
  searchQuery: string
  workspaceIds: number[]
  questionTypeId: number
  surveyIds: string[]
  selectedSurveyReasons: string[]
}>

export function useSurveyReasons(props: Props) {
  const searchQuery = computed(() => props.value.searchQuery)
  const workspaceIds = computed(() => props.value.workspaceIds)
  const questionTypeId = computed(() => props.value.questionTypeId)
  const surveyIds = computed(() => props.value.surveyIds)
  const selectedSurveyReasons = computed(() => props.value.selectedSurveyReasons)

  const { data: surveyReasonData, isPending: isLoading } = useQuery({
    queryKey: ['survey-reasons', workspaceIds, surveyIds, searchQuery],
    queryFn: ({ signal }) =>
      getCsatReasons({
        workspaceIds: workspaceIds.value,
        questionTypeId: questionTypeId.value ? String(questionTypeId.value) : undefined,
        searchString: searchQuery.value || undefined,
        surveyIds: surveyIds.value,
        signal,
      }),
    select: (data) => ({
      reasons: uniq(data.values.concat(selectedSurveyReasons.value)).map((reason) => ({ id: reason, name: reason })),
      total: Number(data.total),
    }),
  })

  return { surveyReasonData, isLoading }
}
