import type {
  AutoQaCategoryCustomPhrase,
  AddAutoQaCategoryCustomPhrasesRequest,
  DeleteAutoQaCategoryCustomPhrasesRequest,
  ChangeAutoQaCategoryCustomPhrasesRequest,
  AutoQaCategorySettingsResponse,
  AutoQaCategoryCustomPhraseResponse,
  CustomCategoryTemplatesResponse,
  AddCustomCategoryTemplateRequest,
  CustomCategoryTemplateFiltersResponse,
  SpotlightSettingsListResponse,
  UpdateSpotlightSettingsRequest,
  SpotlightConditionResponse,
  SpotlightSettingsResponse,
  AddCustomSpotlightRequest,
  UpdateCustomSpotlightRequest,
} from '@zendesk/zqa-services/autoqa'
import qs from 'query-string'
import { api } from '@/api'
import type { AutoQaSpotlightType } from '@/types/spotlight'

export const getAutoQaCategorySettings = (categoryType: string): Promise<AutoQaCategoryCustomPhrase[]> => {
  return api
    .get(`autoqa/category/${categoryType}/settings`)
    .json<AutoQaCategorySettingsResponse>()
    .then((data) => data.customPhrases)
}

export const deleteCustomAutoQaCategory = (id: string) => {
  return api.delete(`autoqa/custom-category-templates/${id}`).json()
}

export const addAutoQaCategoryCustomPhrases = (json: AddAutoQaCategoryCustomPhrasesRequest) => {
  return api
    .post(`autoqa/category/${json.categoryType}/custom-phrases`, { json })
    .json<AutoQaCategoryCustomPhraseResponse>()
}

export const deleteAutoQaCategoryCustomPhrases = (json: DeleteAutoQaCategoryCustomPhrasesRequest) => {
  return api
    .delete(`autoqa/category/${json.categoryType}/custom-phrases`, {
      searchParams: qs.stringify({ customPhraseIds: json.customPhraseIds }),
    })
    .json()
}

export const editAutoQaCategoryCustomPhrases = (json: ChangeAutoQaCategoryCustomPhrasesRequest) => {
  return api
    .put(`autoqa/category/${json.categoryType}/custom-phrases`, { json })
    .json<AutoQaCategoryCustomPhraseResponse>()
}

export const getCustomCategoryTemplateFilters = () =>
  api.get('autoqa/custom-category-template-filters').json<CustomCategoryTemplateFiltersResponse>()

export const getAutoQaCustomCategoryTemplates = () =>
  api.get('autoqa/custom-category-templates').json<CustomCategoryTemplatesResponse>()

export const createAutoQaCustomCategoryTemplate = (json: AddCustomCategoryTemplateRequest) =>
  api.post('autoqa/custom-category-templates', { json }).json<CustomCategoryTemplatesResponse>()

export const editAutoQaCustomCategoryTemplate = (json: AddCustomCategoryTemplateRequest) =>
  api.put('autoqa/custom-category-templates', { json })

export const getAutoQaSpotlightConditions = () =>
  api.get('autoqa/spotlight/conditions').json<SpotlightConditionResponse>()

export const getAutoQaSpotlightSettings = () =>
  api.get('autoqa/spotlight/settings').json<SpotlightSettingsListResponse>()

export const editAutoQaSpotlightSettings = (spotlight: AutoQaSpotlightType, json: UpdateSpotlightSettingsRequest) =>
  api.put(`autoqa/spotlight/${spotlight}/settings`, { json })

export const deleteAutoQASpotlight = (id: string) => api.delete(`autoqa/spotlight/custom/${id}`).json()

export const createAutoQaCustomSpotlight = (json: AddCustomSpotlightRequest) =>
  api.post('autoqa/spotlight/custom', { json }).json<SpotlightSettingsResponse>()

export const editAutoQaCustomSpotlight = (id: string, json: UpdateCustomSpotlightRequest) =>
  api.put(`autoqa/spotlight/custom/${id}`, { json }).json<SpotlightSettingsResponse>()
