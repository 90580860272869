import { ref } from 'vue'

import type { CommentTemplate } from '@zendesk/zqa-services/users'
import { toast } from '@/components/toast'
import alertDialog from '@/components/alertDialog'
import i18n from '@/i18n'

import { createCommentTemplate, deleteCommentTemplate, updateCommentTemplate, getCommentTemplates } from './api'

export const templates = ref<CommentTemplate[]>([])

const sortTemplates = (a: CommentTemplate, b: CommentTemplate) =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())

export const getTemplates = async () => {
  const { commentTemplates } = await getCommentTemplates()
  templates.value = commentTemplates.sort(sortTemplates)
}

export const splitTypes = (template: CommentTemplate) => {
  return template.type.split('|')
}

export const translateTypeLabel = (label: string) => i18n.t(`profile.templates.types.${label}`)

export const createTemplate = async (template: CommentTemplate) => {
  const newTemplate = await createCommentTemplate(template)
  templates.value.push(newTemplate)
  templates.value.sort(sortTemplates)
  toast({ status: 'success', message: i18n.t('profile.templates.toast.created') })
}

export const editTemplate = async (template: CommentTemplate) => {
  const editedTemplate = await updateCommentTemplate(template)

  templates.value = templates.value.map((t) => (t.id === template.id ? editedTemplate : t))
  templates.value.sort(sortTemplates)
  toast({ status: 'success', message: i18n.t('profile.templates.toast.saved') })
}

export const deleteTemplate = async (id: string) => {
  const { isConfirmed } = await alertDialog('', {
    title: i18n.t('profile.templates.delete_template.delete_confirmation_title'),
    confirmButtonText: i18n.t('profile.templates.delete_template.button_label'),
    redConfirmButton: true,
    allowHtml: true,
  })
  if (!isConfirmed) return
  await deleteCommentTemplate(id)
  templates.value = templates.value.filter((t) => t.id !== id)
  toast({ status: 'success', message: i18n.t('profile.templates.toast.deleted') })
}
