import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-active-primary flex cursor-pointer items-center text-sm" }
const _hoisted_2 = { class: "text-active-primary pr-1" }
const _hoisted_3 = { class: "min-w-44 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_q_radio = _resolveComponent("q-radio")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    trigger: "click",
    theme: "light actions-dropdown",
    placement: "bottom",
    arrow: false,
    interactive: ""
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createTextVNode("   "),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.chosenLabel), 1 /* TEXT */),
        _createVNode(_component_chevron_down_icon, { class: "icon-m" })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_radio, {
          "model-value": _ctx.modelValue,
          options: _ctx.options,
          "onUpdate:modelValue": _ctx.timelineSelected,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tippyRef?.tip.hide()))
        }, null, 8 /* PROPS */, ["model-value", "options", "onUpdate:modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}