import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_color = _resolveComponent("ui-color")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    theme: "light",
    trigger: "click",
    arrow: false,
    interactive: ""
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(_ctx.$style.trigger),
        style: _normalizeStyle({ '--workspace-color': _ctx.modelValue })
      }, null, 6 /* CLASS, STYLE */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", {
        class: _normalizeClass(_ctx.$style.pallette)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
          return (_openBlock(), _createElementBlock("li", { key: color }, [
            _createVNode(_component_ui_color, {
              color: color,
              onClick: ($event: any) => (_ctx.pickColor(color))
            }, null, 8 /* PROPS */, ["color", "onClick"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}