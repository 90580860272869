import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_label_content = _resolveComponent("category-label-content")!
  const _component_category_overview_tooltip = _resolveComponent("category-overview-tooltip")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    placement: "top",
    theme: "light paddingless",
    class: _normalizeClass(_ctx.$style.wrapper)
  }, {
    trigger: _withCtx(() => [
      _createVNode(_component_category_label_content, _normalizeProps(_guardReactiveProps(_ctx.$props)), null, 16 /* FULL_PROPS */)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_category_overview_tooltip, {
        category: _ctx.category,
        "show-workspace-name": _ctx.showWorkspaceName
      }, null, 8 /* PROPS */, ["category", "show-workspace-name"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}