import { ref, watch } from 'vue'
import { getImageDownloadUrl } from '@/api/image-download-url'
import config from '@/config'

export const redirectedImagesStore = {}

export const useRedirectedImageSource = (watchFn?: () => any) => {
  const src = ref('')

  watch(
    watchFn,
    async (newSrc) => {
      if (!newSrc?.includes(`uploads.${config.domainRoot}`)) {
        src.value = newSrc || ''
        return
      }

      const fileName = newSrc.split('/').pop()
      if (!redirectedImagesStore[fileName]) {
        const { downloadUrl } = await getImageDownloadUrl(fileName)
        const response = await fetch(downloadUrl)
        redirectedImagesStore[fileName] = URL.createObjectURL(await response.blob())
      }
      src.value = redirectedImagesStore[fileName]
    },
    { immediate: true },
  )

  return { src }
}
