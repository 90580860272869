import { v4 as uuid } from 'uuid'
import i18n from '@/i18n'
import type { RootCause } from '@/types/category'

export const OTHER_REASON = { id: 'OTHER', cause: i18n.t('universal.other') }

export function populateReasonsWithIds(selectedReasons: string[], allReasons: RootCause[]) {
  const reasons: RootCause[] = []
  const archivedReasons: RootCause[] = []

  selectedReasons?.forEach((r) => {
    const reason = allReasons.find((rsn) => rsn.cause === r)

    reason ? reasons.push(reason) : archivedReasons.push({ id: uuid(), cause: r })
  })

  return { reasons, archivedReasons }
}
