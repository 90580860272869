export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte svou subdoménu Zendesku"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlaste se do Zendesku"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše společnost má existující účet."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit se ke společnosti"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se chcete připojit k existující společnosti, požádejte administrátora, aby vám poslal pozvánku."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádejte administrátora, aby vás pozval"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmyslel/a jsem si to."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit registraci"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše společnost má existující účet. Chcete se připojit k firemnímu účtu, nebo si vytvořit nový?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírovat pokyny"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový účet"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nějaký účet už existuje"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ke svému účtu Zendesk ZK nebudete mít přístup, dokud nebudou uhrazeny neuhrazené faktury za odběr."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš odběr byl pozastaven"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet nebyl nalezen. Zkuste to znovu."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["získejte nové heslo."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zkuste to znovu nebo ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatná kombinace e-mailu a hesla."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžádejte administrátora o novou."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento odkaz na pozvánku je neplatný nebo už byl použit."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Způsob přihlášení ", _interpolate(_named("method")), " je ve vašem účtu deaktivován."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato pozvánka je neplatná nebo už byla použita. Vyžádejte administrátora o novou."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načtení informací o pozvánce se nezdařilo."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace se nezdařila. Uživatel s tímto e-mailem už možná existuje."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet byl zablokován po několika po sobě jdoucích neúspěšných pokusech o přihlášení. Zkontrolujte svou doručenou poštu, zda vám nepřišel e-mail s pokyny, jak jej odblokovat."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejprve ověřte svůj e-mail"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný e-mailový účet"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací kód"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat kouzelný odkaz"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní e-mail"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš pracovní e-mail"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Hlavní smlouva o poskytování služeb)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo neodpovídá požadavkům"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení o ochraně osobních údajů"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vydržte"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zdravíme, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Připojte se k organizaci ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš e-mail byl ověřen. Nyní se můžete přihlásit pomocí svého nového účtu níže."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat s Googlem"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný odkaz na pozvánku"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí kouzelného odkazu"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat k přihlášení"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat se Slackem"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat se Zendeskem"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš e-mail byl ověřen. Nyní se můžete přihlásit pomocí svého nového účtu níže:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí účtu pro Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí kouzelného odkazu"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se přes Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se k Zendesku ZK"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš účet byl smazán"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte účet?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný kód."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné."])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný e-mailový účet."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné."])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlašovací kód"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny možnosti přihlášení"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " pomocí tradičních metod"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí kouzelného odkazu"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdrželi jste e-mail s přihlašovacím kódem."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdrželi jste e-mail s pokyny, podle kterých se můžete přihlásit."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při odesílání kouzelného odkazu došlo k chybě. Zkuste to znovu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlaste se e-mailem"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit heslo"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno malé písmeno"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno velké písmeno"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo musí obsahovat"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální délka hesla je 128 znaků."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snadno zapamatovatelné, těžko uhodnutelné"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálně 8 znaků"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte si heslo"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomněli jste heslo?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno malé písmeno"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno číslo"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedno velké písmeno"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud chcete obnovit heslo, zadejte svou e-mailovou adresu, na kterou vám odešleme pokyny."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte platný e-mailový účet"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Byl odeslán e-mail na adresu ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud e-mail nenajdete ve své doručené poště nebo ve složce se spamem, zkontrolujte zadaný e-mail."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat k přihlášení"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte svůj e-mail"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", pak vám bude zaslán e-mail pro obnovení hesla."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud má váš účet existující heslo"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pokud je tento ", _interpolate(_named("bold")), ", poslali jsme vám odkaz pro obnovení hesla."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail přidružen k účtu"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzpomněli jste si na heslo?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při odesílání odkazu pro obnovení došlo k chybě. Zkuste to znovu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte si heslo"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se pomocí kouzelného odkazu"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Zásady ochrany osobních údajů)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Registrací souhlasíte s následujícími podmínkami pro Zendesk ZK: ", _interpolate(_named("terms_of_service")), " a ", _interpolate(_named("privacy_policy")), "."])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínky služby"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máte účet?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se pomocí kouzelného odkazu"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se pomocí pracovního e-mailu"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se přes Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrujte se k Zendesku ZK"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se pomocí účtu pro Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mám aktivní účet Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zaregistrujte se nebo se přihlaste a dokončete nastavení ", _interpolate(_named("direct_install_name")), "."])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste to opravdu vy? Zkontrolujte svou doručenou poštu, zda vám nepřišel potvrzovací e-mail."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše je připraveno"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK funguje nejlépe na stolních počítačích. Přihlaste se na nich a začněte kontrolovat konverzace."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarezervovat ukázku"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebo si u nás zarezervujte ukázku a my vám předvedeme, co všechno můžete v Zendesku ZK dělat."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádáme vás o účast"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušit"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rozmyslel/a jsem si to a chci akci ", _interpolate(_named("cancel")), "."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " vás zve, abyste se připojili k jejich účtu."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit se k účtu"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový účet"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste se přihlásit znovu"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reakce, kterou jste obdrželi ke zpětné vazbě: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté reakce"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaguje na váš komentář"]), _normalize(["Několik lidí reagovalo na váš komentář"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaguje na vaši odpověď"]), _normalize(["Několik lidí reagovalo na vaši odpověď"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reaguje na vaši kontrolu"]), _normalize(["Několik lidí reagovalo na vaši kontrolu"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentář"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odpověď"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontrola"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÁMITKY"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROVEDENÉ"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PŘIJATÉ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k průzkumu"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakce"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájené"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Označit komentář jako přečtený?"]), _normalize(["Označit všechny komentáře jako přečtené?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Označit námitku jako přečtenou?"]), _normalize(["Označit všechny námitky jako přečtené?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Označit zpětnou vazbu k průzkumu jako přečtenou?"]), _normalize(["Označit všechny zpětné vazby k průzkumu jako přečtené?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Označit kontrolu jako přečtenou?"]), _normalize(["Označit všechny kontroly jako přečtené?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Komentář označen jako přečtený"]), _normalize(["Všechny komentáře označeny jako přečtené"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Námitka označena jako přečtená"]), _normalize(["Všechny námitky označeny jako přečtené"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Zpětná vazba k průzkumu označena jako přečtená"]), _normalize(["Veškerá zpětná vazba k průzkumu označena jako přečtená"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontrola označena jako přečtená"]), _normalize(["Všechny kontroly označeny jako přečtené"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit vše jako přečtené"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako přečtené"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroly"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když někdo zpochybní kontrolu, zobrazí se námitka zde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné námitky"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když jste pověřeni řešením námitky, zobrazí se zde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté námitky"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když zpochybníte kontrolu, zobrazí se zde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné zahájené námitky"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veškerá zpětná vazba, která vám byla poskytnuta, se zobrazí zde. Zatím není co ukázat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná přijatá zpětná vazba"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se zobrazí všechny reakce na vaše kontroly a odpovědi. Zatím není co ukázat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté reakce"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájit konverzaci"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veškerá zpětná vazba, kterou poskytnete, se zobrazí zde. Začněte tím, že zanecháte recenzi v zobrazení konverzace."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné provedené kontroly"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veškerá zpětná vazba, která vám byla poskytnuta, se zobrazí zde. Zatím není co ukázat."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté kontroly"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné komentáře"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veškerá zpětná vazba, kterou zanecháte jako komentáře, se zobrazí zde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné zadané komentáře"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné komentáře"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se zobrazí všechny komentáře ke kontrole, kterou vlastníte, ", "@", "zmínky nebo odpovědi na vaše komentáře."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté komentáře"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté námitky"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné otevřené námitky"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné zamítnuté námitky"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné vyřešené námitky"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste to znovu výběrem jiné možnosti z výše uvedeného filtru."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste to znovu výběrem jiného uživatele z výše uvedeného filtru."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytnuté komentáře"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté komentáře"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny námitky"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté námitky"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájené námitky"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolovaný subjekt"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijata zpětná vazba k průzkumu"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené kontroly"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam aktivit"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " zkontrolováno"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " zkontrolována"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " zkontrolováno"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Restartuje se za ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zbývá: ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit úkol"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam úkolů"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Není možné vytvořit koučinkovou relaci mezi ", _interpolate(_list(0)), " a ", _interpolate(_list(1)), ", protože momentálně nejsou ve stejném pracovním prostoru."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Koučinkové relace od ", _interpolate(_list(0)), " do ", _interpolate(_list(1)), " již existují. Chcete-li získat přístup k relaci, kontaktujte uživatele ", _interpolate(_list(0)), "."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrženo"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Byla vytvořena nová relace mezi ", _interpolate(_list(0)), " a ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouč"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kouče"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučovaný subjekt"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte koučovaný subjekt"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit relaci"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření relace"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat relaci"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název relace"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrženo"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po termínu"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minulá"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadcházející"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit relaci"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné relace"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba kontroly"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládací panely"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro vybrané období nejsou k dispozici žádná data k zobrazení."])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin přidán do relace. ", _interpolate(_named("buttonStart")), "Ukázat", _interpolate(_named("buttonEnd"))])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do…"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidáno"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dospěli jste na konec pinů z vybraného období."])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tento ", _interpolate(_named("link")), " obsahuje informace o tom, jak fungují piny."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["článek"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zdá se, že jste nepřipnuli nic pod uživatelem ", _interpolate(_list(0)), ". Piny můžete použít pro přípravu na soukromé schůzky nebo pro uložení něčeho na vhodnější dobu."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koučovaný subjekt"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly přidány žádné piny"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zde najdete všechny piny, které jste vytvořili pod uživatelem ", _interpolate(_named("name")), ". Piny přidané do relací budou ", _interpolate(_named("bold")), " s druhým účastníkem."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okamžitě sdíleny"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zdá se, že během vybraného období ", _interpolate(_list(0)), " neobdržel/a žádnou zpětnou vazbu ke kontrole konverzace nebo CSAT."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není k dispozici žádná zpětná vazba"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dospěli jste na konec komentářů z vybraného období."])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriky"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Čísla uživatele ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová položka akcí"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dokončil uživatel ", _interpolate(_named("user")), " dne ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["uživatelem ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vámi"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte novou položku akce, kterou chcete přidat"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co z tohoto setkání vzešlo? Jaký bude váš další postup?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky akcí"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vytvořil/a ", _interpolate(_named("name")), " dne ", _interpolate(_named("createdDate")), "."])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smaže relaci"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tento krok trvale ", _interpolate(_named("deleteSession")), ". Účastníci ztratí přístup k relaci, včetně jejího obsahu a soukromých poznámek."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Relace vytvořena ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naposledy upravil/a ", _interpolate(_named("name")), " dne ", _interpolate(_named("updatedDate")), "."])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Témata a poznámky k rozhovoru"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka aktualizována"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit relaci"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílet"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybraní uživatelé od tohoto bodu získají přístup k podrobnostem relace."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdíleno"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílení relace"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Již nebudete součástí relace ", _interpolate(_list(0)), " – ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " již nebude součástí této relace."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " má nyní přístup k této relaci."]), _normalize(["Noví uživatelé (celkem ", _interpolate(_named("n")), ") mají nyní přístup k této relaci."])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat relaci"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat všechny relace"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání relace"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koučem"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zde se zobrazí poznámky přidané uživatelem ", _interpolate(_list(0)), "."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte připnuté položky jako body k diskuzi z pravého postranního panelu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámky vidíte pouze vy…"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní poznámky"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromá poznámka aktualizována"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dní"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouč"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučovaný subjekt"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky akcí"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední relace"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhající"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po termínu"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled relací"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vše"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já: Kouč"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já: Koučovaný subjekt"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny relace"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílené relace"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste přepnout filtr, aby se zobrazily položky akcí."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné položky akcí"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Položky akcí"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté a zobrazené kontroly"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijato · Viděno"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postup po relacích"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že agenti neobdrželi žádnou zpětnou vazbu."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřte, zda máte správné jméno uživatele."])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tohoto uživatele nelze najít"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná dostupná data"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK za posledních 30 dní"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Touto akcí trvale <b>smažete všechny relace</b> (2) s uživatelem <b>", _interpolate(_named("name")), "</b>. Účastníci ztratí přístup ke všem relacím, včetně obsahu a soukromých poznámek."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dospěli jste na konec všech relací."])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučinková relace je skupinová aktivita mezi mentorem a koučovaným subjektem, při které se rozebírá výkon, příležitosti, krátkodobé a dlouhodobé cíle."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace můžete vyhledávat jak podle jména kouče, tak podle koučovaného subjektu."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné relace"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly vytvořeny žádné relace"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat historii koučinku"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat historii koučinku "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední relace"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny relace"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouč"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučovaný subjekt"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako hotovo"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako neprovedené"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte podle jména"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam relací"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenaplánováno"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovězte na tuto zprávu"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost relace kalibrace vypršela. Nelze provést žádné další kontroly."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto konverzaci jste již provedli kontrolu."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola této konverzace již není možná, protože uplynul její termín."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace nemá žádné kontroly"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveďte kontrolu kalibrace"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILY"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOUHRN"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PŘEPIS"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Přednášející ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["období ticha"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit přednášejícího"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat zprávu"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na přehrávanou zprávu"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přednášející"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepis"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé pracovního prostoru"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li vytvořit filtry, nejprve přidejte propojení."])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádní roboti"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " vytvořil/a ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naposledy aktualizováno dne ", _interpolate(_named("time")), " uživatelem ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zdá se, že nemáte žádné filtry. Chcete-li získat přístup k této stránce, musíte nejprve vytvořit filtr. Začněte s filtrem ", _interpolate(_named("recommendedFilter")), ", chcete-li zobrazit své vlastní konverzace. Chcete-li pokračovat, vytvořte tento filtr níže."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za posledních 30 dní"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínka „Vytvořeno za posledních 30 dní“ bude přidána k filtrům vytvořeným bez rozsahu dat."])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr není v tomto tarifu k dispozici a nebude brán v úvahu při zobrazení výsledků."])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypršel časový limit načítání možností filtru. Zkuste to znovu."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o filtrování"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent = přihlášená osoba"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nalezené konverzace: ", _interpolate(_named("count"))]), _normalize(["Nalezená konverzace: ", _interpolate(_named("count"))]), _normalize(["Nalezené konverzace: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není k dispozici ve vašem aktuálním tarifu. Chcete-li tuto funkci používat, proveďte upgrade."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat podmínku"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od Zendesk ZK AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínka"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proměnit na úkol"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte filtr"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit filtr"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní pole"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínky"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítáme možnosti filtru podle vašeho konkrétního nastavení. Při počátečním načítání to může trvat několik minut."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte popisný název filtru."])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název filtru"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat možnost"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínka"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromý"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veřejný"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte filtr"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou k dispozici žádné hodnoty."])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání…"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenalezeno"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte hodnotu"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditelnost"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze aktivní relace ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit starší relace ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava relace kalibrace"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit relaci"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření relace kalibrace"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kontroly kalibrace provedené během relace budou trvale smazány."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat relaci"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání ", _interpolate(_named("sessionName")), " relace kalibrace"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné relace."])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné aktivní relace"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné relace."])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné aktivní relace"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat filtr"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit soukromý filtr"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit veřejný filtr"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr přidán"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr bude trvale smazán."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat filtr"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání filtrů (celkem ", _interpolate(_named("filterName")), ")"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny námitky"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky pro mě"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky ode mě"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené námitky"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncepty"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné filtry."])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné soukromé filtry."])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné veřejné filtry."])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromé filtry"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veřejné filtry"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to uložit"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto je dočasné zobrazení filtru."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sestavujeme váš filtr…"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr aktualizován"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kategorie AutoZK"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naposledy aktualizováno ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složitá slova"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatické kontroly se aktualizují dynamicky na základě změn tiketů."])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznit v konverzaci"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlouhá souvětí"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebylo předvídáno žádné automatické ohodnocení."])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se zjistit žádné chyby."])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit složitá slova"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit dlouhá souvětí"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další konverzace"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK nemohlo tuto kategorii ohodnotit kvůli nedostatečnému kontextu. Doporučuje se ruční kontrola."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznění"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objevte automatizované poznatky z konverzace, které vám pomohou zefektivnit proces kontroly. Zdůraznění zásadních konverzací a prezentace výjimečných pozitivních příkladů pro váš tým"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Hovor]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opraveno"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazáno"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " přijal/a námitku."])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Námitka zaslána uživateli ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysvětlete, proč si myslíte, že je tento kontrolovaný subjekt nesprávným příjemcem této zpětné vazby."])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte komentář a nastavte nový kontrolovaný subjekt."])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat komentář"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysvětlete, proč si myslíte, že jsou tato ohodnocení nespravedlivá (povinné)."])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte komentář (povinné)."])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysvětlete, proč tuto námitku odmítáte"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte komentář"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat námitku uživateli"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat námitku uživateli"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat námitku uživateli"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat nový kontrolovaný subjekt"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k námitce"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k námitce"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit kontrolovaný subjekt"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat námitku uživateli"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitka"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzneste námitku k této kontrole"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesouhlasím s ohodnocením"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato kontrola nebyla určena pro mě"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " částečně přijal/a námitku"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " zamítl/a námitku"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijatá"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně přijatá"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnutá"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " zahájil/a námitku: kontrola"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " zahájil/a námitku: nesprávný kontrolovaný subjekt"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřená námitka by měla být vyřešena, aby bylo možné podniknout další kroky týkající se kontroly."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijmout"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nová ohodnocení."])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat námitku"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu si přejete tuto námitku zrušit?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání námitky"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitka"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba vyřešení námitky"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnout"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nová spravedlivá ohodnocení (nepovinné)."])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vznést námitku ke kontrole"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávný kontrolovaný subjekt"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijměte námitku"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijměte námitku"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k námitce"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávný kontrolovaný subjekt"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k námitce"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítněte námitku"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítněte námitku"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit námitku"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraveno"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " od uživatele ", _interpolate(_named("author")), " dne ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["pro uživatele ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolováno"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřazeno uživateli:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Také přidejte komentář."])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o podmíněných výsledkových listinách"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dříve přiřazeno uživateli:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stačí si vybrat jednu"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se najít žádné GIFy s tímto názvem."])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte první, kdo provede kontrolu."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš odběr je momentálně pozastaven. Znovu jej aktivujte, chcete-li povolit poskytování zpětné vazby."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejnil/a:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukazuje, kolik času jste strávili kontrolou této konverzace."])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr uživatele"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Chybějící hlavní příčina pro kategorii: „", _interpolate(_list(0)), "“"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte kategorii nebo přidejte komentář."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat…"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbalit"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozbalit pole komentáře"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sbalit pole komentáře"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt formátování"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit formátování"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat kontrolu"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte všechny kategorie."])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte alespoň jednu kategorii."])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si tuto konverzaci na později."])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit na později"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další úkol"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahradit konverzaci v tomto cyklu přiřazení"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat konverzaci"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola výchozího bodu"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat komentář"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář smazán"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat tento komentář?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor komentáře"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento hashtag byl smazán nebo přejmenován."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podělte se o to, co se vám líbilo nebo co mohlo být lepší…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okomentovat"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmíněná"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato akce trvale smaže všechny komentáře."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat komentář"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Zpráva]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tuto zpětnou vazbu ještě neviděl/a."])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tuto zpětnou vazbu viděl/a."])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bývalý člen"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte GIFy…"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím klávesy Enter vytvořte nový hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní hashtagy jsou uzamčeny administrátorem."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slouží k ukládání případů do záložek a pozdějšímu filtrování."])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako výchozí bod"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba ke zprávě"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další konverzace"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná zpětná vazba"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly provedeny žádné kontroly."])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li začít, proveďte kontrolu níže."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezbývají žádné další kontroly."])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nehodnoceno"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předchozí pověřený agent"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předchozí konverzace"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat jako výchozí bod"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědět"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovězte na tuto kontrolu"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt postranní panel kontroly"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touto akcí trvale smažete kontrolu a všechny přidané komentáře."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kontrolu"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání kontroly"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola smazána"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kontrola smazána uživatelem ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřaďte tento úkol sami sobě, abyste mohli provést kontrolu."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tohoto uživatele jste již provedli kontrolu."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Provedli jste kontrolu pro uživatele ", _interpolate(_list(0)), "."])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U této konverzace se nezobrazují žádné kategorie."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly nejsou pro tento pracovní prostor k dispozici."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neukládat"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit tuto kontrolu jako koncept?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodit tento koncept"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodit"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit postranní panel kontroly"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neúspěch"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch s výhradami"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stále zpracováváme čísla…"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nechte tady komentář"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečtěte si tuto zprávu"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte sami sebe"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte tuto konverzaci"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádní uživatelé ke kontrole"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly zakázány"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl nalezen žádný uživatel"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastníci"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé pracovního prostoru"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání…"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kontrola"]), _normalize(["Počet kontrol: ", _interpolate(_named("count"))])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nová hodnocení."])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správce pracovního prostoru nepovolil vlastní kontroly."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím klávesy Enter označte tento pin."])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledních 30 dní"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S hvězdičkou"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte relaci kalibrace."])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncepty"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky poskytují vizuální znázornění dat technické podpory. Pomocí interaktivních grafů pochopíte, kam zaměřit úsilí v oblasti zajišťování kvality, odhalíte slepá místa a najdete ty správné agenty, které můžete kontrolovat na základě jejich výkonu."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Identifikátor ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Přeložit do ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřiřazený"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právě si prohlíží"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právě kontroluje"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace byla odstraněna a již není k dispozici."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace, kterou hledáte, v Zendesku ZK neexistuje."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny piny s touto konverzací"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V relaci"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit konverzaci"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraněním konverzace z relace smažete všechny kontroly, které jí byly uděleny."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odstranit konverzaci z ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly vytvořeny žádné relace kalibrace."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace byla přidána do relace."])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace byla odstraněna z relace."])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do relace kalibrace / Odebrat z relace kalibrace"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat odkaz na tuto konverzaci"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná konverzace k zobrazení"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail zákazníka"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zákazníka"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno zákazníka"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název externího formuláře"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPO"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba první odezvy"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorita"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k průzkumu"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení spokojenosti"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPŘ"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba do prvního řešení"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originál"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání…"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato zpráva v rámci této konverzace neexistuje nebo byla smazána."])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez předmětu"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná konverzace k zobrazení"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla nalezena žádná konverzace."])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít původní konverzaci"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze odkazovat na konverzaci – chybí adresa URL."])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastníci"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádní účastníci"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout konverzaci"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím přečíst kontroly"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím zkontrolovat"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla vytvořena prostřednictvím rozšíření prohlížeče."])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit průzkum spokojenosti zákazníků"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit konverzaci"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva s negativním vyzněním"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva s pozitivním vyzněním"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeložit do angličtiny"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit originál"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat konverzaci"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání konverzace je trvalé. Také budou smazána všechna související data, např. průzkumy, přepisy a kontroly ze Zendesk ZK, včetně ovládacích panelů.", _interpolate(_named("lineBreak")), "Stále budete mít přístup k této konverzaci v Zendesk Supportu nebo jiném softwaru pro technickou podporu, který používáte."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání konverzace je trvalé. Smaže také všechna související data, např. průzkumy, přepisy a recenze ze služby Zendesk ZK, včetně ovládacích panelů. Pokud konverzace patří do několika pracovních prostorů, bude smazána ze všech.", _interpolate(_named("lineBreak")), "K této konverzaci budete mít stále přístup ve službě Zendesk Support nebo jiném softwaru technické podpory, který používáte."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat konverzaci"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat konverzaci ze Zendesk ZK"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tato konverzace je kvůli eskalaci neúplná. Přejděte na ", _interpolate(_named("optionsUrl")), " a ověřte své štítky. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tato konverzace je kvůli eskalaci neúplná. Promluvte si s administrátorem ohledně ověřování štítků. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení > Rozšířené volby"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o štítcích"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavřený"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novinka"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřený"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekající"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřešený"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pověřený agent změněn na ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřiřazený"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Makro ", _interpolate(_named("target")), " použito uživatelem ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konverzace získala hodnocení ", _interpolate(_named("target")), "."])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uživatel ", _interpolate(_named("target")), " zredigoval konverzaci."])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stav změněn na ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Označeno štítkem ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tým změněn na ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " odložil/a konverzaci na ", _interpolate(_named("target")), "."])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " zrušil/a odložení konverzace."])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot uvízl ve smyčce a opakuje stále jednu zprávu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakování robota"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakování robota, kdy se stejná zpráva opakuje jednou nebo vícekrát za sebou"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nalezené shodné fráze"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Průměrný agent vyřizuje konverzaci o ", _interpolate(_named("percent")), " efektivněji než robot."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektivita komunikace robota"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Robot vyřídil konverzaci o ", _interpolate(_named("percent")), " efektivněji než průměrný agent."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektivita komunikace robota"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace postrádá nezbytné prohlášení informující účastníky o nahrávání, které je nutné pro zajištění souladu s předpisy a transparentnosti."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejnění nahrávky chybí"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Byla porušena smlouva SLA (smlouva o úrovni služeb) na ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porušení SLA (smlouvy o úrovni služeb)"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrika pro pravidlo SLA překročila cílový čas."])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Známky úbytku zákazníků. Zákazník zvažoval přechod nebo slíbil, že se rozloučí."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zjištěno riziko odchodu"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konverzace zahrnuje období ticha, která trvají déle, než je nastavená prahová hodnota ", _interpolate(_list(0)), " s."])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 instance"]), _normalize(["Počet instancí: ", _interpolate(_named("count"))])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nejdelší ", _interpolate(_list(0)), " s"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období ticha"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník vyhledal pomoc na vyšší úrovni."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o eskalaci"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník vyjádřil extrémní vděčnost nebo byl s obdrženou podporou velmi spokojen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mimořádné služby"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník nebo agent výslovně požádal o navazující kontrolu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádost o navazující tiket"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník projevil nespokojenost."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní vyznění"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla neobvyklá nebo atypická a k vyřešení vedlo několik výměn názorů."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zjištěna odlehlá hodnota"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník projevil spokojenost."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní vyznění"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použitelné"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit jako nepoužitelné"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naposledy upravil/a ", _interpolate(_named("author")), " dne ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepoužitelné"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uživatelem ", _interpolate(_named("author")), " dne ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte důvod (nepovinné)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly zjištěny žádné poznatky."])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat propojení"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte technickou podporu se svým účtem a vytvořte alespoň jeden filtr, abyste viděli seznam konverzací."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné konverzace k zobrazení"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit nastavení pracovního prostoru"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolovaný subjekt"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořena"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naposledy aktualizováno"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Za den)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tento měsíc)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tento týden)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najít podle ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Výsledky hledání pro ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolována ostatními"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okomentovány"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejnovější"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejstarší"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhodné"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávno aktualizované"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávno zodpovězené"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seřadit podle"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřečtené"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolována vámi"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces importu může nějakou dobu trvat. Až bude hotovo, pošleme vám e-mail."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK importuje vaše konverzace"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limit: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kliknutím na ikonu kalibrace v záhlaví zobrazení konverzace."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není tady nic k vykazování. Zkuste to znovu s jiným filtrem nebo možností řazení."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné odlehlé hodnoty k zobrazení."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte relevantní konverzaci a kliknutím na ikonu hvězdičky v záhlaví si ji přidejte do záložek."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste změnit filtry nebo pozvat další agenty technické podpory, chcete-li přidat další konverzace."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez omezení"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní poznámka"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právě teď načítáme konverzace. Některý obsah může stále chybět."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další uživatel"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl nalezen žádný uživatel"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové pracovního prostoru"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předchozí uživatel"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné konverzace"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné konverzace"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedokončené kontroly končí zde. Znovu si je přečtěte, zrevidujte a odešlete během následujících 30 dní."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné koncepty kontrol"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné konverzace označené hvězdičkou"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné konverzace k zobrazení"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná shoda"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na vybranou zprávu"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejméně odpovědí"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejvíce odpovědí"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyznění"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam konverzací"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROBOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hovor přijat v ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hovor ukončen v ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hovor uskutečněn v ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolována"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolována vámi"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Načtení zvukového souboru se nezdařilo. Ujistěte se, že jste přihlášeni k ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k průzkumu spokojenosti zákazníků"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba při načítání zvuku"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor komentáře"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný hovor se neuskutečnil"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslouchat v technické podpoře"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslouchat v Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlost přehrávání"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolní hovor"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systém"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepis"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysoké"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nízké"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Střední"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejste uživatelem Zendesku ZK."])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK automaticky řadí agenty na základě jejich měsíční průměrné CSAT. Jedním kliknutím najděte konverzace agentů, jejichž CSAT je nižší než CSAT jejich kolegů."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí podle CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Střední"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysoký"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nízký"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejnižší"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejvyšší"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejste uživatelem Zendesku ZK."])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeden tiket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objem"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK automaticky řadí agenty do výkonnostních skupin podle toho, kolik konverzací měsíčně zvládnou vyřídit. Jedním kliknutím najděte konverzace agentů, jejichž objem je nižší než objem jejich kolegů."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí podle objemu"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolováno"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezkontrolováno"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav kontroly"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nízké"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysoké"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutrální"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejste uživatelem Zendesku ZK."])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyznění"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK automaticky řadí agenty na základě průměrného sentimentu v konverzacích. Jedním kliknutím najděte interakce, kde agenti jednají s frustrovanějšími zákazníky než jejich kolegové."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí podle vyznění"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezjištěna"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zjištěna"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odlehlé hodnoty"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace, které vyžadují vaši pozornost. Náš model strojového učení identifikuje složité nebo atypické interakce pro další kontrolu."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odlehlé hodnoty"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte ke kontrole konverzace, ve kterých zákazník projevil potěšení nebo frustraci. Pomocí rozbalovací nabídky vyberte typ vyznění, který ve svých konverzacích hledáte. Přetažením atributu filtru nahoru zobrazíte poměr zvoleného vyznění ve všech interakcích."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyznění"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyznění"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav kontroly"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezkontrolováno"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolováno"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK automaticky rozpoznává jazyky ve vašich konverzacích. Pomocí rozbalovací nabídky vyberte konverzace v konkrétních jazycích, které chcete zkontrolovat. Přetažením atributu filtru nahoru získáte přehled o všech jazycích používaných v technické podpoře."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky z konverzace"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["S Poznatky můžete ", _interpolate(_named("paragraph_2_item")), " než s běžnými filtry konverzací. Získejte vizuální přehled o všech datech z technické podpory a prozkoumejte například následující poznatky:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o používání poznatků z konverzace"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pochopte relativní důležitost konkrétních atributů konverzace ve vztahu k ostatním."])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte své nejvýkonnější a nejméně výkonné agenty pomocí integrovaného hodnocení výkonu agentů."])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " je výkonný interaktivní nástroj pro získávání dat, který vám pomůže najít konverzace, které kontrolu nejvíce potřebují."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zvládnout mnohem víc"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grafy Konverzace a Agent jsou ", _interpolate(_named("paragraph_3_item_1")), " – aktualizace filtrů nebo kliknutí na segmentované výseče grafu na kterémkoli z nich automaticky aktualizuje seznam konverzací. ", _interpolate(_named("paragraph_3_item_2")), ", chcete-li prozkoumat podrobné rozpisy nebo poměry různých prvků."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propojeny"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte uspořádání atributů filtru"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poznatky z konverzace zobrazují pouze ", _interpolate(_named("paragraph_4_item")), ". Automaticky odstraňujeme interakce bez skutečné kontrolní hodnoty, jako jsou automaticky generované zprávy, spam, odpovědi robotů nebo zprávy, které neobsahují řádný dialog s vaším zástupcem podpory."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čisté a uzavřené konverzace"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co to vidím?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " z ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " z ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načtení se nezdařilo."])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " –"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenti"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " z celkového počtu)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst znovu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ale jsme si jisti, že jakmile dorazí, bude to skvělé."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní průzkum"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla zadána žádná zpětná vazba k průzkumu."])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím není k dispozici žádné ohodnocení zpětné vazby k průzkumu…"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přijata"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítek zpětné vazby"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky komentářů zpětné vazby"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr rozsahu dat použit"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " odpověď"]), _normalize(["Počet odpovědí: ", _interpolate(_named("count"))])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ponořte se hlouběji do toho, proč bylo uděleno ohodnocení ", _interpolate(_list(0)), ", díky stanovení důvodů v nastavení průzkumu."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zatím žádné důvody ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o důvodech průzkumu"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace se štítky:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " podle"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvody"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie komentářů"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Důvody ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Předpokládané motivace ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj konverzace"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky technické podpory"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Odpovědi v průzkumu (", _interpolate(_named("n")), ")"]), _normalize(["Odpověď v průzkumu (", _interpolate(_named("n")), ")"]), _normalize(["Odpovědi v průzkumu (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez předmětu"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konverzace s ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " podle uživatelů"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra odpovědí"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ohodnocení ", _interpolate(_list(0)), " je součet všech odpovědí vydělený součtem maximálních ohodnocení."])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit cíl"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat cíl"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit cíl"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat cíl"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krátký"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velmi dlouhý"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlouhý"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se střední délkou"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matoucí"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neužitečný"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatná podpora"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrá podpora"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spousta emocí"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba pro agenta"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stížnost"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drobnosti"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problém nebyl vyřešen"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problém vyřešen"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní vyznění"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatný výsledek"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní vyznění"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pochvala"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatný produkt"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení peněz"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlá podpora"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomalá podpora"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poděkování"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk ZK automaticky přiděluje vaše data ", _interpolate(_list(0)), " do předem definovaných kategorií. Můžete tak porozumět složitým poznatkům a prozkoumat příčiny, aniž byste museli ručně procházet písemnou zpětnou vazbu."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechna ohodnocení"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny průzkumy"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte jazyk odpovědi"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost komentáře"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte velikost komentáře"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhledem k tomu, že 25 % komentářů obsahuje pouze jedno slovo, delší komentáře je nezbytné přečíst. Získejte podnětnější zpětnou vazbu, aniž byste museli pracovat ručně, protože to uděláme automaticky za vás."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod zpětné vazby"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte důvod zpětné vazby"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné odpovídající ohodnocení"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpokládané motivace"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kategorii komentářů"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slova ve shlucích slov"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte slova ve shlucích slov"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " od minulého období"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konverzace"]), _normalize([_interpolate(_named("n")), " konverzace"]), _normalize(["Počet konverzací: ", _interpolate(_named("n"))])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvůli nedostatku dat nelze vypočítat poměr odeslaných průzkumů ze všech konverzací."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslané průzkumy"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trychtýř průzkumu"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " z ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Předpokládané motivace ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk ZK automaticky přiděluje vaše data ", _interpolate(_list(0)), " do předem definovaných kategorií. Můžete tak porozumět složitým poznatkům a prozkoumat příčiny, aniž byste museli ručně procházet písemnou zpětnou vazbu."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost komentáře"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhledem k tomu, že 25 % komentářů obsahuje pouze jedno slovo, delší komentáře je nezbytné přečíst. Získejte podnětnější zpětnou vazbu, aniž byste museli pracovat ručně, protože to uděláme automaticky za vás."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi v průzkumu"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (průměrně)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK (průměrně)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Trendy ", _interpolate(_list(0)), " podle"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvody"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivační faktory"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum odeslání průzkumu ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Datum odpovědi na průzkum ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Přiřaďte ohodnocení ", _interpolate(_list(0))])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit data na základě"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření konverzace"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vlastníkovi tiketu"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agentovi s největším počtem odpovědí"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (průměrně)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " v průběhu času"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a ISK"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace, které obsahují tato slova:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shluk slov"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT podle uživatelů"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trychtýř průzkumu"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi v průzkumu"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky technické podpory"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT v průběhu času"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shluk slov"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný kanál"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný zdroj"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný jazyk"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné důvody"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné štítky"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná kategorie komentářů"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný pracovní prostor"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou k dispozici žádná data, protože nebyla vytvořena žádná přiřazení nebo nebyly spuštěny žádné cykly. Vraťte se později nebo upravte filtry."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozkoumejte, co je nového"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " položka"]), _normalize(["Počet položek: ", _interpolate(_named("count"))])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konverzace"]), _normalize(["Počet konverzací: ", _interpolate(_named("count"))])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neúspěch"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch s výhradami"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěch/Neúspěch"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hodnocení ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná CSAT = [součet ohodnocení zpětné vazby ÷ (počet všech odpovědí × maximální možné ohodnocení)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení CSAT v Zendesku ZK normalizuje vaše ohodnocení zpětné vazby na stupnici od 0 do 100 % a bere průměr standardizovaného ohodnocení."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Customer Satisfaction Score = Ohodnocení spokojenosti zákazníků) kvantifikuje, jak jsou vaši zákazníci spokojeni s vašimi službami."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Každá kontrola představuje kontrolu jedné konverzace jednou osobou. Konverzace může mít více než jednu kontrolu (pokud stejnou konverzaci kontrolují více než dva kontroloři)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte vývoj hodnocení kontrolorů v průběhu času."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte vývoj ohodnocení agentů v průběhu času."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odpovědí"]), _normalize([_interpolate(_named("n")), " odpověď"]), _normalize(["Počet odpovědí: ", _interpolate(_named("n"))])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kontrol"]), _normalize([_interpolate(_named("n")), " kontrola"]), _normalize(["Počet kontrol: ", _interpolate(_named("n"))])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK (Interní skóre kvality) je výsledkem vašich kontrol konverzace."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK = součet ohodnocení kontroly ÷ počet kontrol"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypočítá se jako průměr, který bere v úvahu všechna ohodnocení kontroly (vážený průměr ohodnocení kategorie), která odpovídají vybraným podmínkám filtru."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu se nepodařilo načíst."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte filtry a zkuste to znovu."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra úspěšnosti udává výsledek vašich kontrol konverzace."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["neuspějete, pokud je ohodnocení kontroly ", _interpolate(_named("failScore")), " nebo nižší;"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["uspějete, pokud je ohodnocení kontroly ", _interpolate(_named("passScore")), " nebo vyšší."])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je určena předem definovaným výchozím bodem, což znamená, že:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra úspěšnosti = součet kontrol, které prošly ÷ celkový součet kontrol"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra úspěšnosti udává procento toho, kolik kontrol projde, aniž by došlo k neúspěchu v kritické nebo nekritické kategorii."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Např. pokud kontrolor v tento den provede 3 kontroly, zobrazené číslo je průměrem 3 ohodnocení. Podívejte se, jak se ", _interpolate(_named("link")), " počítají jednotlivé buňky."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Např. pokud kontrolor v tento den obdrží 3 kontroly, zobrazené číslo je průměrem 3 ohodnocení. Podívejte se, jak se ", _interpolate(_named("link")), " počítají jednotlivé buňky."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V úvahu se nebere: váha kategorie, kritický stav (na rozdíl od Ohodnocení v průběhu času)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhledem k tomu, že kategorie mohou být v kontrolách označeny jako –, může být počet kontrol na kategorii nižší než celkový počet kontrol kontrolora. Podívejte se, jak se počítají jednotlivé buňky."])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V úvahu se nebere: váha kategorie, kritický stav (na rozdíl od Ohodnocení v průběhu času)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhledem k tomu, že kategorie mohou být v kontrolách označeny jako –, může být počet kontrol na kategorii nižší než celkový počet kontrol na agenta. Podívejte se, jak se počítají jednotlivé buňky."])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V úvahu se nebere: váha kategorie, kritický stav (na rozdíl od Ohodnocení v průběhu času)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Skupiny kategorií se zobrazují samostatně. Ohodnocení pro každou skupinu se vypočítá podle průměrného ohodnocení kategorie pro danou skupinu za stejné období. Podívejte se, jak se ", _interpolate(_named("link")), " počítají jednotlivé buňky."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V úvahu se nebere: váha kategorie, kritický stav (na rozdíl od Ohodnocení v průběhu času)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Skupiny kategorií se zobrazují samostatně. Ohodnocení pro každou skupinu se vypočítá podle průměrného ohodnocení kategorie pro danou skupinu za stejné období. Podívejte se, jak se ", _interpolate(_named("link")), " počítají jednotlivé buňky."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Výchozí bod ", _interpolate(_named("count")), " %"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez kritických chyb"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra kritické bezchybnosti udává procento toho, kolik kontrol projde, aniž by došlo k neúspěchu v některé z kritických kategorií."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní skóre kvality"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Jiné“ základní příčiny"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodická změna"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denní změna"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční změna"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdenní změna"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční změna"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení v průběhu času"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lze je zobrazit ve dnech nebo týdnech."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet = celkové ohodnocení za den nebo týden vydělené celkovým počtem provedených kontrol"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lze je zobrazit ve dnech nebo týdnech."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpočet = celkové ohodnocení za den nebo týden vydělené celkovým počtem přijatých kontrol"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení podle kategorií"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podívejte se na kombinovaný průměr všech hodnocení kategorie udělených kontrolorem během tohoto období."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podívejte se na kombinovaný průměr všech hodnocení kategorie přijatých na agenta během tohoto období."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny nízkého hodnocení"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny hodnocení"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení kategorie v průběhu času"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzujte porovnání kategorií v průběhu času."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlédněte si průměrná ohodnocení udělená pro každou kategorii ve zvolený den nebo týden."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzujte porovnání kategorií v průběhu času."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlédněte si průměrná ohodnocení získaná pro každou kategorii ve zvolený den nebo týden."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení podle kontrol"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivované"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritická kategorie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stupnice hodnocení"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr/Celkem"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit vlastní kontroly"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportuje všechny karty."])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytnuté"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["konverzace"]), _normalize(["konverzace"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["položka"]), _normalize(["položky"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " odpovědí"]), _normalize([_interpolate(_named("n")), " odpověď"]), _normalize(["Počet odpovědí: ", _interpolate(_named("n"))])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kontrol"]), _normalize([_interpolate(_named("n")), " kontrola"]), _normalize(["Počet kontrol: ", _interpolate(_named("n"))])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zobrazit jednu další"]), _normalize(["Zobrazit další (", _interpolate(_named("n")), ")"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledních 30 dní"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledních 7 dní"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minulý měsíc"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minulý týden"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento měsíc"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento týden"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní období"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dny"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíce"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtletí"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdny"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roky"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu se nepodařilo načíst. Změňte filtry a zkuste to znovu."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná data k zobrazení."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční změna"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládací panely"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše aktivní filtry zahrnují data napříč různými stupnicemi hodnocení."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny piny karet s těmito filtry"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny piny ovládacích panelů s těmito filtry"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba kontroly"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobit"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné kategorie"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky o kategoriích"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Komentář"]), _normalize(["Komentáře"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matice"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bývalý člen"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interní skóre kvality"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK (průměrně)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšné kontroly"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout kartu"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout ovládací panely"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Základní příčina"]), _normalize(["Základní příčiny"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kontroly"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba kontroly"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolovaný subjekt"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit základní příčiny"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny ukazují, proč konverzace získávají nízké hodnocení."])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto kategorii nejsou nastaveny žádné základní příčiny"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení/Celkem"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny kategorií hodnocení"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina kategorií"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostor"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobit pořadí"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seskupit kategorie podle"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat vše"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobit"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit vlastní kontroly"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahrnout vlastní kontroly"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit pouze vlastní kontroly"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat filtry"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné hashtagy."])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagy komentářů"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte hashtagy komentářů"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné štítky."])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky technické podpory"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávejte ve štítcích technické podpory"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výsledkové listiny."])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte výsledkové listiny"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná vyhovující propojení"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj konverzace"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte zdroj"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další filtry"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné vyhovující pracovní prostory"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet provedení"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každý"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny skupiny"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné vyhovující skupiny."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny skupiny"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit karty"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit data na základě"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření kontroly"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření konverzace"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit dvě desetinná místa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit nastavení"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny pracovní prostory"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celý účet"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název pracovního prostoru"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné pracovní prostory"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jídlo a pití"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvířata a příroda"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekty"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lidé a tělo"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestování a místa"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Často používané"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledky hledání"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smajlíky a emoji"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboly"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné emoji"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájit konverzaci"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na seznam úkolů"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " nebo procházejte seznam ", _interpolate(_named("link")), "."])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Momentálně používáte pracovní prostor ", _interpolate(_named("workspace")), ", který není propojen s touto doménou. Kliknutím na tento odkaz ho propojíte."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dostupnými propojeními"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pro ", _interpolate(_named("host")), " zatím nemůžete poskytnout zpětnou vazbu."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako aktivní kartu nastavte svou technickou podporu."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto doménu zatím nelze zkontrolovat. Pokud by to mělo být možné, informujte administrátora."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat tuto doménu"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Propojte ho s ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jen okamžik – probíhá příprava"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze získat adresu URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nástroje, které jsou již propojeny s účtem organizace v Zendesku ZK."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S účtem organizace v Zendesku ZK nejsou propojeny žádné nástroje."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umožňuje kontrolovat webové stránky, jejichž adresa URL zůstává vždy stejná, a to tak, že před provedením kontroly připojíte na konec jedinečný parametr hash."])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout účet"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše domény"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domény, které jsou již propojeny s tímto pracovním prostorem."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na následujících doménách můžete zkontrolovat kteroukoli stránku."])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domény dostupné ke kontrole"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšíření"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečná adresa URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zatím není připojena žádná technická podpora – přejděte do ", _interpolate(_named("link")), " a přidejte ji."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšíření prohlížeče Zendesk ZK lze použít pouze\nv tarifech Professional a Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout pracovní prostor"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte název"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte hodnotu"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní pole"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnota"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na tuto konverzaci jsme narazili poprvé. Uveďte nějaké podrobnosti."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na konverzaci:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco, podle čeho bude tiket později identifikován"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení peněz"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte pověřeného agenta i předmět."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváří se"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte tuto konverzaci"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřiřazený"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Obraťte se na svého nadřízeného, nebo přepněte na jiný pracovní prostor z ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly jsou pro tento pracovní prostor zakázány."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na shledanou"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostor"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto adresu URL nelze zkontrolovat. Přejděte na konverzaci."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejprve se přihlaste do Zendesku ZK prostřednictvím svého poskytovatele SSO a poté to zkuste znovu."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se pomocí jednotného přihlašování"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevře se v místním okně pro přihlášení."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlaste se pomocí svého účtu Zendesk ZK"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počkejte…"])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřování…"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ještě nemáte účet?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovat se"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K této konverzaci nemáte přístup. Pokud byste ho měli mít, obraťte se na svého nadřízeného."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný přístup"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte, zda je vaše propojení aktivováno v Zendesku ZK."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravujte svá propojení."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba propojení"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li pokračovat, přejděte na konverzaci v technické podpoře."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatná adresa URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opětovné propojení je téměř hotovo."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chviličku"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste stránku načíst znovu, chcete-li znovu získat přehled."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se nepovedlo"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osvoboďte se od virtuálních hranic technické podpory a kontrolujte konverzace kdekoli."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradujte svůj odběr, abyste mohli používat rozšíření"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa odběru"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení technické podpory není propojeno s tímto pracovním prostorem."])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše technická podpora tento tiket nerozpozná nebo k němu Zendesk ZK nemá přístup."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzaci nelze najít"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová aktivita"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máte nějakou zpětnou vazbu"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení úspěšné"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikaci nyní můžete používat."])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnotit všechny kategorie"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slouží k ukládání případů do záložek a pozdějšímu filtrování."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání…"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná zpětná vazba k této konverzaci"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečíst si zpětnou vazbu"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít v Zendesku ZK"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se zobrazí veškerá zpětná vazba a oznámení. Zatím nic…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více v Zendesku ZK"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovat"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti konverzace"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obsahuje všechny"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je všechno"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neobsahuje všechny"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není všechno"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lepší než agentova"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horší než agentova"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je více než"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obsahuje některý z"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je některý z"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neobsahuje žádné"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není žádný z"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["narušeno"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okomentováno"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okomentováno mnou"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neokomentoval"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nekomentováno mnou"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritické pro kontrolu"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obsahuje"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posledních 30 dní"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v rámci"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posledních 14 dní"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posledních 24 hod"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posledních 30 dní"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posledních 7 dní"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minulý měsíc"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minulý týden"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tento měsíc"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tento týden"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnes"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["včera"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zjištěno"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["existuje"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mnou označeno hvězdičkou"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mnou neoznačeno hvězdičkou"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obsahuje"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["větší než"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začíná od"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menší než"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["až do"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativní"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nenarušeno"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není složité"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nezjištěno"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neexistuje"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neobsahuje"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nepřijato"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nezobrazeno"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozitivní"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nezkontrolováno"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mnou nekontrolováno"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zkontrolováno"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zkontrolováno mnou"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přijato"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritické pro kontrolu"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obsahuje"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["větší než"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začíná od"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menší než"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["až do"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neobsahuje"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazeno"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["můj komentář má odpovědi"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ano"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kategorie"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní zvýraznění"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat podmínku"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriky"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastníci"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola a zpětná vazba"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny podmínky"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznění"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnává vyřízení konverzací robotem s průměrnými agenty."])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot uvízl ve smyčce a opakuje stále jednu zprávu."])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí robota v konverzacích"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ robota zapojeného do konverzace"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot zapojený do konverzace"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Obsah konverzace obsahuje frázi ", _interpolate(_list(0)), " ve zprávách agentů."])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky identifikujte hovory, které postrádají povinné prohlášení, například „Tento hovor bude nahráván“ a podobně."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba nebo robot zapojený do konverzace"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent nebo robot, který byl v konverzaci kontrolován"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník vyhledal pomoc na vyšší úrovni"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní a negativní vyznění zjištěné v konverzaci"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikuje nejaktivnějšího agenta v konverzacích na základě analýzy AI."])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent ukončil konverzaci zdvořile."])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent chápe a uznává pocity zákazníků."])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje gramatické, pravopisné a stylistické chyby agenta."])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá typické uvítací fráze."])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent rozumí dotazu nebo obavám zákazníka."])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, jak snadno lze textu porozumět, s ohledem na složitost slov a délku věty."])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá nabízené řešení."])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje tón agenta v průběhu konverzace."])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Známky úbytku zákazníků. Zákazník zvažoval přechod nebo slíbil, že se rozloučí."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina, do které konverzace patří."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah nebo text zprávy agenta"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí agenta v konverzacích"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent přiřazený ke kontrole konverzace"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba, po kterou hovor trval, v sekundách"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah nebo text konverzace"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikační kanál použitý pro konverzaci (např. e-mail, chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře přidané do konverzace"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byl do konverzace přidán komentář"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní a negativní vyznění zjištěné v konverzaci"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy zákazník odpověděl na průzkum spokojenosti zákazníků"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zda byla ke kontrole konverzace vznesena námitka"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál, který zákazník používá k zahájení konverzace"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí zákazníků v konverzacích"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum poslední zprávy v konverzaci"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role nebo typ uživatele zapojeného do konverzace"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticho mezi agentem a zákazníkem překročilo prahovou hodnotu."])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník vyhledal pomoc na vyšší úrovni."])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník vyjádřil extrémní vděčnost nebo byl s obdrženou podporou velmi spokojen."])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník nebo agent výslovně požádal o navazující kontrolu."])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text nebo záznam celého rozhovoru v podobě volání"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Určuje, zda byla konverzace označena hvězdičkou."])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagy používané v komentářích ke kategorizaci obsahu"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk konverzace rozpoznaný pomocí AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum poslední zprávy v konverzaci"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet soukromých poznámek v konverzacích"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrný počet znaků na zprávu v konverzacích"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí v konverzacích zaměřených na zákazníky"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastník, jehož informace jsou veřejně viditelné"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrný počet slov na zprávu v konverzacích"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie používané k hodnocení nebo posouzení konverzace"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav procesu kontroly konverzace"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastník nebo osoba zapojená do konverzace"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail přidružený ke konverzaci"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zda byla kontrola zobrazena"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor, který konverzaci posuzoval"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace naposledy zkontrolována"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent, který byl v konverzaci kontrolován"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakékoli konkrétní komentáře nebo zpětná vazba poskytnutá zákazníkem ohledně jeho spokojenosti s konverzací"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení spokojenosti zákazníka s konverzací"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení spokojenosti zákazníka na zprávu v konverzaci"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina použitá ke kontrole konverzace"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzhledem k tomu, že došlo k porušení SLA (smlouvy o úrovni služeb)"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zdroje nebo propojení, ze kterého konverzace pochází"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla neobvyklá nebo atypická a k vyřešení vedlo několik výměn názorů."])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otázka průzkumu související s obsahem konverzace"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadpis nebo název průzkumu spojeného s konverzací"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent přiřazený ke kontrole konverzace"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka nebo konkrétní produkt/služba související s konverzací"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikační kanál použitý pro konverzaci (např. e-mail, chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace uzavřena nebo vyřešena"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace původně vytvořena"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace původně vytvořena"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy zákazník odpověděl na průzkum spokojenosti zákazníků"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručný popis nebo shrnutí konverzace"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externího odkazu propojené s konverzací"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složka nebo konkrétní seskupení, ve kterém je konverzace uspořádána"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář nebo šablona použitá pro strukturování konverzace"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID externího odkazu propojené s konverzací"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní schránka nebo e-mailový účet, přes který byla konverzace přijata"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo přidružené ke konverzaci"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úroveň priority přiřazená konverzaci"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet odpovědí v konverzaci"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav nebo aktuální stav konverzace"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální stav konverzace"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět nebo téma konverzace"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí agenta v konverzacích"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky přiřazené ke kategorizaci konverzace"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ nebo kategorie konverzace"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace naposledy aktualizována"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, kdy byla konverzace naposledy aktualizována"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médium nebo kanál, přes který konverzace probíhala"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah přepisu v konverzaci"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace, které nejsou způsobilé ke kontrole prostřednictvím analýzy AI"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet agentů, kteří se účastní konverzací"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zda byla konverzace zobrazena"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libovolná zpráva"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektivita komunikace robota"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakování robota"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí robota"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ robota"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr hovoru"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva klienta"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období ticha"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejnění nahrávky chybí"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (smlouva o úrovni služeb)"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalace"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah přepisu hovoru"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S hvězdičkou"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezpůsobilé ke kontrole"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloval/a"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace přes"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravopis a gramatika"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva agenta"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejaktivnější agent"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí agenta"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba trvání hovoru (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah konverzace"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál zpráv"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítek komentáře"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum přidání komentáře"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyznění"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum odpovědi na průzkum CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitka"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál zákazníka"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí zákazníka"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum poslední odpovědi"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ uživatelů"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riziko odchodu"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období ticha"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalace"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mimořádné služby"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navazující tiket"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepis"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag komentáře"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odlehlá hodnota"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porozumění"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum poslední odpovědi"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet soukromých poznámek ke konverzaci"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrný počet znaků"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrný počet slov"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet veřejných odpovědí"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veřejný účastník"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum hodnocení"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení a komentář"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav kontroly"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čitelnost"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastník"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Související e-mail"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Související"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola přijata"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovaná výsledková listina"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola zobrazena"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum kontroly"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolovaný subjekt"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář k průzkumu"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT na zprávu"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení spokojenosti (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízené řešení"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zdroje"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otázka v průzkumu"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název průzkumu"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka konverzace"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doména značky"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál konverzace"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum uzavření"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum odpovědi na průzkum CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum odeslání průzkumu CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis konverzace"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole konverzace"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složka konverzace"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář konverzace"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina konverzace"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externí ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní schránka konverzace"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorita konverzace"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí v konverzaci"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav konverzace"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status konverzace"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět konverzace"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet odpovědí agenta"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítek technické podpory"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítek technické podpory"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ konverzace"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum aktualizace"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum aktualizace"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah přepisu"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet zúčastněných agentů"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav zobrazení"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohrožení – schopnost"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohrožení – zdraví"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohrožení – životní situace"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie AutoZK"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr podmínky související s datem"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání názvu k filtru"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte název filtru."])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášená osoba"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bývalí členové pracovního prostoru"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové pracovního prostoru"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Přidat ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít filtr"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měli byste mít alespoň 1 filtr související s datem."])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte možnosti"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte možnost"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí za ", _interpolate(_named("n")), " den. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong> za ", _interpolate(_named("n")), " den</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí za několik dní (celkem ", _interpolate(_named("n")), "). ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>za několik dní (celkem ", _interpolate(_named("n")), ")</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí dnes. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše zkušební období končí <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dnes</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte odběr nyní"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " d"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ve zkušebním období zbývá ", _interpolate(_named("days")), "."])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dosáhli jste limitu uživatelů pro svůj tarif. ", _interpolate(_named("link")), ", chcete-li získat další informace nebo upgradovat svůj tarif."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikněte sem"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propojte svou technickou podporu"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díky tomu budeme moci načíst vaše tikety, abyste je mohli zkontrolovat."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte svou technickou podporu"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Používáte Zendesk ZK s ukázkovými daty. Chcete-li smazat ukázková data a importovat vlastní konverzace, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukázková data"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Níže zkopírujte užitečné informace."])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořiďte snímek obrazovky celé stránky, abyste mohli poskytnout kontext."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li tento problém vyřešit, obraťte se na náš tým zákaznické podpory:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se nepovedlo"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na chybu:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírovat užitečné informace"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé, roboti a pracovní prostory"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé a pracovní prostory"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnes"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tento měsíc"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tento týden"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " zkontrolováno ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " zkontrolováno ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " zkontrolováno ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co je nového?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klávesové zkratky"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatujte s námi"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš chat nelze načíst – nepoužíváte blokování reklam?"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na…"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunita"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládací panely"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domovská stránka"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené kontroly"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalostní báze"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout účet"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aplikace byla aktualizována. ", _interpolate(_named("buttonStart")), "Načíst znovu", _interpolate(_named("buttonEnd"))])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud byste chtěli začít používat Zendesk ZK jako součást svého pracovního prostoru:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jen vás informujeme, že jste již obdrželi pozvánku k používání Zendesku ZK coby součásti pracovního prostoru. Pokud chcete, můžete samozřejmě pokračovat, ale mějte na paměti, že se tím pro vás vytvoří ", _interpolate(_named("bold")), "."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samostatná organizace"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ano, dovolte mi pokračovat s ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novým účtem"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste si jisti?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " vás žádá, abyste se připojili k ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné internetové připojení"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat nový pin"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlá navigace"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zacílit a rozšířit editor komentářů"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt nebo zobrazit postranní panel"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na další konverzaci"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít původní konverzaci"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na předchozí konverzaci"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udělit všem kategoriím nejvyšší možné hodnocení"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udělit všem kategoriím nejnižší možné hodnocení"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamíchat konverzace (znovu náhodně seřadit)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řekněte nám"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klávesové zkratky"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout modální okno zkratek"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dosáhli jste limitu počtu znaků ", _interpolate(_named("charLimit")), "."])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Typ obrázku ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") není povolen."])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velikost obrázku ", _interpolate(_named("name")), " je příliš velká (povoleno 5 MB)."])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor komentářů se nepodařilo načíst."])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploadovat přetažením"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázek"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovat šablony"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné šablony."])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat základní příčinu"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného včetně smazaných důvodů"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod hodnocení"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křestní jméno"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet zaměstnanců"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budeme vás kontaktovat, abychom vám pomohli se založením účtu."])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název společnosti"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní číslo společnosti"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak jste se poprvé dozvěděli o Zendesku ZK?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kanál"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte počet agentů"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte všechna povinná pole."])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musíte potvrdit, že jste si přečetli naše podmínky a zásady ochrany osobních údajů a že s nimi souhlasíte."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podporou řízený kanál Slack"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Událost"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přítel nebo kolega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace technické podpory nebo správce účtu"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkazující web"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávač (Google, Bing atd.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociální média"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řekněte nám něco o sobě a své společnosti."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily účtu"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jsem připraven/a."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procházejte se kolem, seznamte se s aplikací, mažte a přidávejte věci."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet byl vytvořen. Co bude dál?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste připraveni vést skutečné konverzace?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte vlastní data"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začít objevovat"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzkoušejte si to s ukázkovými daty"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžeme začít načítat vaše tikety, abyste je mohli zkontrolovat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte svůj účet technické podpory"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmyslel/a jsem si to."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit registraci"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin vytvořen. ", _interpolate(_named("buttonStart")), "Přejít na piny", _interpolate(_named("buttonEnd"))])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny koučinku jsou k dispozici v tarifu Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradovat tarif"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny tarify"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si poznámky a připravte se na další osobní schůzku nebo koučinkovou relaci."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny pro koučink"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentujte nebo přidávejte další pomocí ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato konverzace byla smazána"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový pin"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit pin"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit pin"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak fungují piny"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte oprávnění k zobrazení této konverzace."])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout kartu"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout konverzaci"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout ovládací panely"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin uložen"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout tuto kartu"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout tuto konverzaci"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout tyto ovládací panely"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout pod uživatele"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr uživatele"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To, co připnete, vidíte jen vy. Chcete-li to sdílet s ostatními, ", "@", "zmiňte je v komentářích."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " je nyní pozván/a do této konverzace"]), _normalize([_interpolate(_named("agentsNames")), " jsou nyní pozváni/y do této konverzace"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 komentář"]), _normalize(["Počet komentářů: ", _interpolate(_named("n"))])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zmínili jste uživatele ", _interpolate(_named("participants")), ", který ale není v této konverzaci."]), _normalize(["Zmínili jste uživatele ", _interpolate(_named("participants")), ", kteří ale nejsou v této konverzaci."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " účastníků"]), _normalize([_interpolate(_named("n")), " účastník"]), _normalize(["Počet účastníků: ", _interpolate(_named("n"))])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete uživateli ", _interpolate(_named("agentName")), " odebrat přístup k tomuto pinu?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedělat nic"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezvat je do této konverzace"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte komentář"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvěte je"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvěte je do této konverzace."])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit uživatele"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlákno"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto akci nelze vzít zpět."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tím se také smažou všechny komentáře pod tímto pinem."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nových zpráv"]), _normalize([_interpolate(_named("n")), " nová zpráva"]), _normalize(["Počet nových zpráv: ", _interpolate(_named("n"))])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Své piny vidíte pouze vy. Chcete-li sdílet piny, ", "@", " někoho v komentářích."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " jste připnuli ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ovládací panely"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konverzaci"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poznámku"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vy"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradujte a získejte piny koučinku"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny koučinku jsou k dispozici v tarifu Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny koučinku jsou k dispozici v tarifu Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradujte a získejte funkci"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připravte se na své osobní schůzky, nebo si něco uložte na jindy."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tím trvale smažete pin a všechny přidané komentáře."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat pin"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání pinu"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název složky"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připněte věci, ke kterým se chcete vrátit později"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědět"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od nejnovějšího"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od nejstaršího"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si zajímavý trend nebo konverzaci na ovládacích panelech jako přípravu na osobní schůzku, nebo si je poznamenejte na jindy."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte pin kliknutím na tlačítko výše nebo na ikonu špendlíku v zobrazení Konverzace nebo Ovládací panely."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připnout"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné piny"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pro dotaz „", _interpolate(_list(0)), "“ nejsou k dispozici žádné výsledky."])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste použít jiná klíčová slova nebo zkontrolovat překlepy."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový pin"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zprávy"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lidé"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávejte podle uživatele, klíčového slova…"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abecedně"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávno vytvořené"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávno upravené"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejvíce pinů"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mým cílem je zkontrolovat ", _interpolate(_named("n")), " konverzaci ", _interpolate(_named("schedule"))]), _normalize(["Mým cílem je zkontrolovat ", _interpolate(_named("n")), " konverzací ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl aktualizován"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit cíl"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete si nastavit osobní cíl pro počet konverzací, které chcete zkontrolovat."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["každý den"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["každý měsíc"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["každý týden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl kontroly"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSOBNÍ"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání kanálů"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kanál"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílet pouze zpětnou vazbu k průzkumu s komentáři"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpořte své kolegy tím, že zdůrazníte výjimečnou kvalitu jejich služeb a vynikající zpětnou vazbu od zákazníků."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílet pozitivní zpětnou vazbu k průzkumu ve Slacku"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné kanály"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky sdílet zpětnou vazbu s ohodnocením vyšším než"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace Pochvalného robota"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na začátku týdne obdržíte připomenutí, pokud jste neprovedli žádné kontroly."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připomenutí"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdenní připomenutí provedení kontrol"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávejte oznámení o nových kontrolách, odpovědích, zmínkách a námitkách ve Slacku."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmínky"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi ve vláknech"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace Slacku"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informujte o nových recenzích, odpovědích, zmínkách a námitkách."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky související se mnou"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmínky v komentářích"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové přijaté kontroly"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi ve vláknech"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkaz se statistikami"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávejte výkazy o provedených a přijatých kontrolách."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkazy"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["denně"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["měsíčně"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["týdně"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denně"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každé 3 hodiny"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každých 6 hodin"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okamžitě"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíčně"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnout"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdně"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrátor vašeho účtu uzamkl nastavení oznámení."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte období"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní oznámení"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktivní)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte k tomuto účtu nové přihlášení prostřednictvím sociálních sítí:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraněním aktivního způsobu přihlašování se odhlásíte. Můžete se znovu přihlásit pomocí alternativního e-mailu."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranění způsobu přihlašování"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google byl úspěšně přidán jako možnost přihlášení."])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti přihlášení"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack byl úspěšně přidán jako možnost přihlášení."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit heslo"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste si jisti?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit heslo"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Na adresu ", _interpolate(_named("email")), " vám bude zaslán e-mail pro obnovení hesla."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primární e-mail"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte svou doručenou poštu, zda vám od nás nepřišel potvrzovací e-mail."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelný odkaz"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li to změnit, změňte svůj ", _interpolate(_named("link")), " předem."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-mail ", _interpolate(_named("email")), " bude přidružen k tomuto heslu."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil byl aktualizován."])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrázek v profilu"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejlepších výsledků dosáhnete, když uploadujete čtvercovou fotografii (256 × 256 pixelů nebo více)."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primární e-mail"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek týdne"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní nastavení"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte alespoň jednu možnost."])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah šablony"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název šablony"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit šablonu v"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte obsah šablony."])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte název šablony."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte šablonu"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit šablonu"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvářejte šablony komentářů, které urychlí kontrolu, připínání položek a koučink."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá se v"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat šablonu"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvale smazat tuto šablonu?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit šablonu"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvářejte šablony komentářů, které urychlí kontrolu, připínání položek a koučink."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné šablony"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona komentáře byla vytvořena."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona komentáře byla smazána."])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablona komentáře byla uložena."])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly a námitky"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li získat přístup, obraťte se na ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zákaznickou podporu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl nalezen žádný účet"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prozatím pro přístup k Zendesku ZK použijte ", _interpolate(_named("link")), "."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet nebyl migrován"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZK robota"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isk"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metriky"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohodnocení"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistika"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládací panely"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrace"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konverzace"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtry"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontrola"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje komentáře"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje námitky"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moje kontroly"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakce"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájené"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k průzkumu"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivita"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vytvořit heslo"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přihlašovací údaje"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přihlášení"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obnovit"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cíl kontroly"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["šablony"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uživatel"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit šablonu"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šablony"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["účet"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fakturace"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["společnost"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizace"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platba"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["místa"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nastavení"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odběr"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřování"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová kategorie"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznění"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byla nalezena konfliktní zpráva"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte nebo odstraňte jednu z konfliktních zpráv."])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pomocí tlačítka ", _interpolate(_named("placeholder")), " vyloučíte citlivá data z AutoZK. Například místo „Opatrujte se, Honzo“ můžete použít „Opatrujte se, ", _interpolate(_named("placeholder")), "“, aby bylo možné použít libovolné jméno."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximální počet řádků ", _interpolate(_named("limit"))])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít pravidlo na následující:"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste limitu 10 vlastních kategorií."])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dosáhli jste limitu ", _interpolate(_named("limit")), " vlastních kategorií."])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat podmínku"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat řádek"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat podmínku"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud zpráva agenta"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V opačném případě – ohodnocení"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poté – ohodnocení"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit kategorii výsledkové listiny"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použijte „/“ pro přidání proměnné."])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny směry"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva agenta nemůže být prázdná."])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva nesmí být delší než 300 znaků."])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny zdroje"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kanály"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny štítky"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny konverzace"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upřesněte, kde se vaše pravidlo použije."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkrétní konverzace"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření přehledu Zvýraznění"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva agenta nemůže být prázdná."])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste limitu 10 vlastních přehledů."])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dosáhli jste limitu ", _interpolate(_named("limit")), " vlastních přehledů."])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte název přehledu"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název přehledu"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit kategorii"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ přehledu"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutrální"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitivní"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít do nastavení pracovního prostoru"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní ne"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud chcete začít používat tuto kategorii hodnocení, přejděte do nastavení výsledkové listiny pracovního prostoru a přidejte ji do vybrané výsledkové listiny."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla vytvořena"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky ohodnoťte uzavřené konverzace identifikací konkrétních klíčových slov nebo frází."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nefunguje to u přepisů volání."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumím"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plácneme si! ✋ Vyčerpali jste své kategorie automatického hodnocení v ", _interpolate(_named("limit")), ". Jste kouzelník kategorizace! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit kategorie byl dosažen"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ujistěte se, že jsou všechna pole vyplněna správně."])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojmenujte svou kategorii hodnocení."])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název kategorie"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová kategorie hodnocení"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová kategorie hodnocení"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky ohodnoťte uzavřené konverzace identifikací konkrétních klíčových slov nebo frází. Nefunguje to u přepisů volání."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky zobrazujte uzavřené konverzace identifikací konkrétních klíčových slov nebo frází. Funguje pouze na přepisech."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky ohodnoťte konverzace určením konkrétních klíčových slov nebo frází ve zprávách agentů i robotů."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud chcete použít tuto kategorii hodnocení, přejděte do nastavení výsledkové listiny pracovního prostoru a přidejte ji do výsledkové listiny."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnoťte konverzace automaticky na základě konkrétních klíčových slov nebo frází ve zprávách."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné pro"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení přehledu"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento přehled se zobrazí ve Zvýraznění na základě definovaných kritérií."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení výsledkové listiny"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto kategorii lze použít ve výsledkových listinách na základě definovaných kritérií."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["robot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připravuje se"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vlastní"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["předdefinovaný"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["výsledková listina"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volání"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny uloženy"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím na ikonu ", "{··}", " vyloučíte jména, údaje o společnosti nebo jakékoli jiné citlivé informace během závěrečných kontrol pomocí funkce AutoZK."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání schváleného uzavření"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava schváleného uzavření"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválené uzavření"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání výjimek"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava výjimky"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjimka"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání schváleného pozdravu"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava schváleného pozdravu"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválený pozdrav"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem vložte slova oddělená zalomením řádků"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stisknutím klávesy Enter po každém slově přidáte nový záznam."])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použitelný jazyk"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například místo „Opatrujte se, Honza“ můžete použít „Opatrujte se, ", "{··}", "“, aby bylo možné použít libovolné jméno."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zástupný text dynamického obsahu"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjimky"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 výjimka přidána"]), _normalize(["Počet přidaných výjimek: ", _interpolate(_named("count"))])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Výjimka již přidána"]), _normalize(["Výjimky již přidány: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat výjimku"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výjimky"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné výjimky"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjimka (rozlišují se malá a velká písmena)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Přidat výjimku"]), _normalize(["Přidat 1 výjimku"]), _normalize(["Přidat ", _interpolate(_named("count")), " výjimek"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte slovo nebo frázi"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjimka uložena"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jazyk"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny jazyky"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte jazyk"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Uzavření již přidáno"]), _normalize(["Uzavření již přidána: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat závěr"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla nalezena žádná schválená uzavření"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná schválená uzavření"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat závěr"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte uzavření"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření přidáno"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření uloženo"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formulář obsahuje duplicitní položky: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pozdrav již přidán"]), _normalize(["Pozdravy již přidány: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat pozdrav"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné schválené pozdravy"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné schválené pozdravy"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat pozdrav"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte pozdrav"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav přidán"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav uložen"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat výjimku"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Smazat výjimku"]), _normalize(["Smazat výjimky"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Trvale smazat tuto výjimku?"]), _normalize(["Trvale smazat tyto výjimky?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit výjimku"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Jedna nebo více výjimek překračuje počet znaků: ", _interpolate(_list(0)), "."])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " znaků"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizován"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizoval/a"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proměnná"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definujte si vlastní schválení, která jsou v souladu se standardy vaší značky a služby. Po přidání bude AutoZK rozpoznávat pouze schválená uzavření. Provedené změny se projeví v budoucích konverzacích."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste nejvyššího limitu 100 schválených uzavření. Pokud chcete přidat další, možná je čas přehodnotit a upřesnit stávající uzavření."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit schválených uzavření byl dosažen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválená uzavření"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slova a fráze, které má AutoZK ignorovat jako pravopisné nebo gramatické chyby. Provedené změny se projeví v budoucích konverzacích."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznam ignorovaných"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte vlastní pozdravy, které jsou v souladu se standardy vaší značky a služby. Po přidání bude funkce AutoZK rozpoznávat pouze schválené pozdravy. Provedené změny se projeví v budoucích konverzacích."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste nejvyššího limitu 100 schválených pozdravů. Pokud chcete přidat další, možná je čas přehodnotit a upřesnit stávající pozdravy."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit schválených pozdravů byl dosažen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválené pozdravy"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní ne"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kategorii"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvale smazat tuto kategorii hodnocení?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam kategorií"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraněná kategorie se nezobrazí v žádných nových konverzacích a filtrech. Historická data na ovládacích panelech a v konverzacích zůstávají nedotčena."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie bude odebrána ze všech výsledkových listin, do kterých byla přidána."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se odstranit kategorii výsledkové listiny"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byla odstraněna kategorie výsledkové listiny"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit kategorii výsledkové listiny"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie AutoZK"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " založené na modelu AI zůstanou na výsledkové listině, ale již nebudou automaticky hodnoceny."])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnutím integrace LLM se funkce AutoZK zcela nevypne. Co se stane:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historická data"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " zůstanou na ovládacím panelu AutoZK."])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručně přidané základní příčiny"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " zůstanou, ale všechny hlavní příčiny založené na LLM budou odstraněny."])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnutí funkce AutoZK založené na LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnout"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK používá AI a velké jazykové modely (LLM) k automatickému hodnocení konkrétních kategorií. Vaše data jsou zpracovávána na základě přísné smlouvy o zpracování dat (DPA) a nikdy se nepoužívají k trénování modelů."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o generativní umělé inteligenci"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoZK používá službu Microsoft Azure OpenAI Service pro ", _interpolate(_named("automatically_scoring_categories")), ". Vaše data jsou zpracovávána v rámci přísného zákona DPA pouze za účelem poskytování funkce AutoZK pro vás a ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["automatické hodnocení některých kategorií"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nikdy se nepoužívají k trénování modelů OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK založené na LLM zapnuto"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK založené na LLM vypnuto"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK založené na LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatizované hodnocení s AI ve službě AutoZK"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpracování dat kategorií AutoZK založených na službě MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlas se službou Microsoft Azure byl odvolán."])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byl udělen souhlas se službou Microsoft Azure. Konverzace bude brzy možné automaticky hodnotit."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkci AutoZK můžete používat i nadále, ale stane se následující:"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakázáním služby MS Azure OpenAI se AutoZK jako funkce nevypne."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete to provést v zobrazení kategorie výsledkové listiny."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie AutoZK založené na modelu zůstanou na výsledkové listině, ale nebudou automaticky hodnoceny."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny, které jste přidali ručně, zůstanou. Všechny základní příčiny vygenerované modelem budou odstraněny."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historická data zůstávají na ovládacích panelech AutoZK."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakažte zpracování dat prostřednictvím služby MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Širší řada kategorií AutoZK"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihned dostupná vícejazyčná podpora"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rychlé přidávání nových kategorií"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšená přesnost hodnocení GPT-3.5 ukazuje kontextové vnímání na úrovni člověka."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Využijte nejnovější pokroky v technologii umělé inteligence."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co získáte?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolte zpracování dat prostřednictvím služby MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlas udělen"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl udělen žádný souhlas"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K tomuto pracovnímu prostoru jsou přiřazeni všichni noví uživatelé."])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výchozí pracovní prostor"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12hodinový čas (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24hodinový čas (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát času"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil společnosti aktualizován"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek týdne"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní integrace"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikátor přístupového klíče a tajný klíč se obvykle nacházejí v nabídce Správa přístupu > Uživatelé."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikátor přístupového klíče AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit změnu tajného přístupového klíče"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit hodnotu tajného přístupového klíče"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení umožňuje definovat pouze jednu oblast, která se obvykle nachází v nabídce Lokalita."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte tajný klíč"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný přístupový klíč AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny relevantní informace naleznete ve službě Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obvykle se nachází v nastavení instance Amazon Connect a na stránce Úložiště dat."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění úložiště záznamů hovorů"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. amazon-connect-123456/connect/instance-name/CallRecordings\n"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo k chybě při přidávání Amazon Connect. Zkontrolujte, zda jsou všechna pole vyplněna správně, a zkuste to znovu."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění úložiště by mělo být ve formátu oddíl/složka oddělené lomítkem."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li přidat propojení, kontaktujte ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákaznická podpora"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny vaše tikety Zendesku budou automaticky synchronizovány. Vybrané tikety můžete vyloučit."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie a Tichomoří (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie a Tichomoří (Soul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie a Tichomoří (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie a Tichomoří (Bombaj)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asie a Tichomoří (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (střední)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evropa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evropa (Curych)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evropa (Irsko)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evropa (Londýn)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast serveru"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blízký východ (SAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte oblast serveru"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jižní Amerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA – východ (Virginie)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA – východ 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA – západ (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojení již bylo přidáno"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spustit propojení"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizujte pouze konverzace, které"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanál jedna, kanál dva"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jedinečné ID tohoto propojení je ", _interpolate(_named("tokenId")), "."])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 měsíc"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 rok"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 měsíce"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 měsíců"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní konverzace budou po vybrané době uchovávání smazány. Odeslané kontroly zůstávají viditelné v zobrazení Aktivita a Ovládací panely."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba uchování"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dobu uchovávání"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato akce je nevratná."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chápu, že tento krok odstraní všechna data související s technickou podporou pro toto propojení ze Zendesku ZK, včetně konverzací, polí a štítků."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslaných kontrol se to netýká a zůstanou viditelné v konkrétních zobrazeních."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skupina jedna, skupina dvě"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahrnout"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktivní vývoj"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tato integrace je stále v ", _interpolate(_named("activeDevelopment")), ". Některé detaily mohou chybět (například přílohy nebo vlastní pole)."])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poštovní schránka jedna, poštovní schránka dvě"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["štítek jedna, štítek dvě"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč tokenu API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte „Názvy polí tiketu“ ve službě Freshdesk v části Nastavení → Pole tiketu."])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte „Custom Field Names“ ve službě Help Scout v části Settings → Custom fields."])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte „Názvy konverzací Klass“ ve službě Kustomer v části Nastavení → Klasses → Konverzace."])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte „Název vlastního pole“ ve službě Live Agent v části Nastavení → Vlastní pole."])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najděte „Název vlastního pole“ ve službě Wix v části Nastavení → Vlastní pole."])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID polí oddělte čárkami. Tato ID najdete v Zendesku v nabídce Administrátor → Pole tiketu."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte několik uživatelů technické podpory, abyste mohli zkontrolovat své první konverzace. Nebojte se, nebudeme je informovat, pokud je nepozvete, aby se stali členy pracovního prostoru."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně integrováno"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat členy"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní začneme načítat vaše konverzace."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Doména: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identifikátor technické podpory: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení se nepodařilo ověřit."])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše připraveno. Toto je vaše nové propojení."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Některá propojení nebyla aktualizována nebo jsou již neaktivní. Zkuste tato propojení aktualizovat, nebo je odstraňte ze seznamu."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klienta API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč klienta API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vytvořte nový token rozhraní API v nabídce Nastavení → Účet → API a SDK. Adresa URL pro přesměrování, která se má použít, je ", _interpolate(_named("redirectUrl")), "."])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID účtu"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte výběrem technické podpory"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je váš účet Intercom nastaven tak, aby byl provozován z EU nebo Austrálie, aktualizujte jej."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["E-mail, který používáte k přihlášení k ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamaskujte jméno, e-mail a telefonní číslo koncového uživatele. Bankovní přihlašovací údaje jsou již maskovány pro všechna připojení technické podpory."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím žádné integrace. Nějakou teď přidejte."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potřebujete tyto role:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit propojení"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat nové propojení"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušením zaškrtnutí této možnosti můžete toto propojení ručně přidat pouze do konkrétních pracovních prostorů."])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojit se všemi existujícími pracovními prostory"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny pracovní prostory"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klíč API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajný klíč API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanály"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Propojení ", _interpolate(_list(0)), " smazáno"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název propojení"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno v"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svůj token API můžete získat níže."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prahová hodnota CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání propojení ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li toto propojení odstranit, zadejte příkaz „smazat propojení“."])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat propojení"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravujte a monitorujte připojené zdroje dat."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit propojení"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názvy polí oddělujte čárkami."])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba při aktualizaci"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte jedinečný název integrace"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt pokročilé možnosti"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt citlivá datová pole"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy neukládat adresy URL příloh"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorovat přílohy"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy neukládat obsah konverzace (později si ho můžete stáhnout na vyžádání z technické podpory)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorovat obsah konverzace"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy neukládat předmět konverzace (později si ho můžete stáhnout na vyžádání z technické podpory)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorovat řádek předmětu"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vaše subdoména ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblast Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnat ID týmu v doručené poště"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číselné ID vašeho týmu Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá…"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní schránky"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručně aktualizovat propojení"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskovat údaje o zákazníkovi"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné pracovní prostory"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název citlivého pole"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID citlivého pole"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení propojení"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte propojení."])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit se k sandboxu Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nastavení propojení s ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit pokročilé možnosti"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst pouze v konverzacích s ohodnocením nižším nebo rovným nastavené prahové hodnotě"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít prahovou hodnotu CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizovat pouze konverzace, které…"])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte nejnovější konverzace."])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat propojení"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení aktualizováno"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelské jméno"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše technická podpora"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li zrušit odběr a smazat svůj účet, kontaktujte ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zákaznickou podporu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání účtu"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání tiketu ve službě Zendesk Support (nebo jiné technické podpoře) nemá vliv na související kontroly konverzací. Stále je můžete prohlížet a spravovat."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazáním tiketu v Zendesk ZK se smažou také související kontroly konverzací.", _interpolate(_named("line_break")), "Smazání tiketu v Zendesk Supportu (nebo jiném softwaru pro technickou podporu) nemá vliv na související kontroly konverzací. Stále je můžete prohlížet a spravovat."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovat kontroly"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání kontrol je trvalé. Ovlivňuje také data na ovládacích panelech."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat všechny kontroly"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat vše"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kontroly smazány"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat vše"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud jsou kontroly spojené se smazanými tikety, uvidíte je zde."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím žádné kontroly"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledání ID tiketu"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Identifikátor tiketu: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazané konverzace"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Výchozí pracovní prostory nelze smazat. Chcete-li tento pracovní prostor smazat, vyberte jiný výchozí pracovní prostor v nabídce ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení účtu"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto akci nelze vzít zpět."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odstraní a smaže všechna data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beru na vědomí, že tento krok ", _interpolate(_named("removeAllData")), " související s tímto pracovním prostorem, včetně uživatelů a propojení pracovního prostoru."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat pracovní prostor"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li tento pracovní prostor smazat, zadejte příkaz „smazat pracovní prostor“."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání pracovního prostoru ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smazat pracovní prostor"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte si jistí tím, co děláte, nebo se nejprve poraďte s odborníkem."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte, zda je výchozí kontrolor vybrán na základě nejvyužívanějšího agenta nebo aktuálního pověřeného agenta."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejaktivnější agent"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výchozí kontrolovaný subjekt"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umožněte lidem kontrolovat jejich vlastní konverzace a agentům umožněte provádět kontroly."])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostor byl smazán."])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostor byl aktualizován."])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanovte neutrální hodnocení tím, že skryjete kontroly, které dříve provedli jiní kontroloři."])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezaujaté hodnocení"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barva"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto akci nelze vzít zpět."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento krok odstraní a smaže všechna data související s tímto pracovním prostorem, včetně uživatelů a propojení pracovního prostoru."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat pracovní prostor"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mazání pracovního prostoru…"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelný odkaz"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouzelný odkaz"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální způsob přihlášení nelze deaktivovat."])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody ověřování byly aktualizovány."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolte určité typy metod ověřování pro všechny uživatele."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřování"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÚČET"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé a pracovní prostory"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvářejte a spravujte pracovní prostory a pozvěte nebo propojte uživatele."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstat na stránce"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit stránku"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit tuto stránku, i když přijdete o všechny změny?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřování"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že nejsou k dispozici žádné konverzace k importu. Začněte tím, že si promluvíte s technickou podporou, a pak se vraťte sem a proveďte analýzu konverzací."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné konverzace"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto systémovou kategorii nelze smazat."])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kategorii"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání kategorií je trvalé. Vymaže všechna data kategorií z výkazů."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li tuto kategorii obnovit, budete ji muset vytvořit znovu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kategorii"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte kategorii"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatické kategorie automaticky vyplní výsledkovou listinu doporučenými hodnoceními, což šetří čas při kontrole. Ruční kategorie jsou vyplněny kontrolory od začátku."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední aktualizace"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatický"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruční"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravopis a gramatika"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porozumění"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čitelnost"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízené řešení"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné kategorie"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné základní příčiny"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výsledkové listiny"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyzkoušejte jiné vyhledávání nebo změňte filtry."])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorií"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kategorie"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategorie"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " výsledkové listiny"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " výsledkové listiny"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " výsledkových listin"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny výsledkové listiny"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " výsledkové listiny"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stavy"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stavu"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stavů"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny stavy"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stavy"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typy"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typu"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typů"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny typy"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " typy"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " pracovní prostory"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " pracovního prostoru"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné pracovní prostory"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " pracovních prostorů"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny pracovní prostory"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " pracovní prostory"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Váha ", _interpolate(_named("weight")), " přispívá ", _interpolate(_named("percentage")), " % k celkovému skóre."])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat výsledkovou listinu"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání výsledkových listin je trvalé. Vymaže všechna data výsledkové listiny z výkazů."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li tuto výsledkovou listinu obnovit, budete ji muset vytvořit znovu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat výsledkovou listinu"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední aktualizace"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stupnice hodnocení byla aktualizována. ", _interpolate(_named("buttonStart")), "Začít kontrolovat", _interpolate(_named("buttonEnd"))])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK ve výchozím nastavení stanovuje binární stupnici, ale můžete se také rozhodnout pro podrobnější stupnici:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li zobrazit různé možnosti stupnice hodnocení nakonfigurované podle kategorie, přejděte do nabídky ", _interpolate(_named("link")), "."])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie/tón"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znalost produktů"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizací stupnic hodnocení:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše předchozí stupnice hodnocení a odpovídající kategorie budou archivovány (nebudou smazány)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Některá data ovládacích panelů budou dotčena, protože vaše stará a nová stupnice nemusí být srovnatelné."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klonovat mé stávající nastavení kategorií hodnocení na novou stupnici"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete aktualizovat stupnice hodnocení?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát zobrazení výsledkové listiny (číslo nebo emoji) můžete změnit a nastavit kategorie jako povinné nebo nepovinné."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo čísel používat emoji"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit lidem přeskakovat kategorie"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řešení"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ stupnice hodnocení"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategorie pro 1 pracovní prostor byly naklonovány."]), _normalize(["Kategorie pro několik pracovních prostorů (celkem ", _interpolate(_named("workspacesAmount")), ") byly naklonovány."])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení pracovního prostoru"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pokud ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky zobrazujte hovory, ve kterých agent nenabízí zákazníkovi výzvy, aby bylo možné očekávat ticho v konverzaci."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace zahrnuje období ticha, která trvají déle, než je nastavená prahová hodnota."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definujte maximální prahovou hodnotu období ticha v hovorech."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období ticha"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazané poznatky se nezobrazí v žádných nových konverzacích a filtrech. Historická data na ovládacích panelech a v konverzacích zůstávají nedotčena."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se smazat poznatky Spotlight"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky Spotlight byly smazány"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mazání poznatků Spotlight"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlížejte si konverzace obsahující konkrétní slova nebo fráze v přepisech a zprávách."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky identifikujte hovory, které postrádají povinné prohlášení, například „Tento hovor bude nahráván“ a podobně."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cílem je identifikovat prohlášení o zveřejnění nahrávky v hovoru modelu LLM (Large Language Model), který dynamicky chápe kontext a eliminuje potřebu přednastavených frází."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejnění nahrávky chybí"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky zobrazujte hovory, ve kterých agent nenabízí zákazníkovi výzvy, aby bylo možné očekávat ticho v konverzaci."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Období ticha"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte okamžiky období ticha v hovoru."])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Potřebujete provést změny ve svém předplatném? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") a my vám rádi pomůžeme."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky analyzujte 100 % konverzací od různých agentů, BPO, kanálů a jazyků. Umělá inteligence odhaluje problémy, mezery ve znalostech a příležitosti ke koučinku v zájmu zlepšení služeb."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% pokrytí AutoZK"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba a koučink agenta"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky Spotlight AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilé vykazování a ovládací panely"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza a filtrování vyznění"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Překlady na vyžádání"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola úkolů a stanovování cílů"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučink a kvízy"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajišťování kvality (ZK) Zendesku"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše, co je v tarifu Professional, a navíc"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (starší verze)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooky pro okamžité oznámení kritických selhání"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace datového skladu"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace služby Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývoj vlastní integrace"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhrazený manažer pro úspěšnost zákazníka"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přímý přístup k našim produktovým a inženýrským týmům prostřednictvím Slacku"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše, co obsahuje sada Growth Suite, a navíc:"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK pro 100% pokrytí"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky z konverzace řízené umělou inteligencí, které dávají datům smysl"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obohacení a filtrování dat technické podpory s umělou inteligencí"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Automatické rozpoznání interakcí, které je nutné zkontrolovat"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza a filtrování vyznění"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkazy a poznatky o výkonu založené na umělé inteligenci"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Překlad konverzací na vyžádání"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledování času kontroly"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza míry úspěšnosti"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše, co obsahuje sada AI Suite, a navíc:"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balíček implementace Enterprise včetně integrace Salesforce a Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývoj vlastní integrace"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace datového skladu"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooky pro okamžité oznámení kritických selhání"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhrazený manažer pro úspěšnost zákazníka"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přímý přístup k našim produktovým a inženýrským týmům"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předběžný přístup a vliv na náš plán"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatická stanovení úkolů a cílů kontroly"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cílený koučink a analytika"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy pro řízení výuky"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa uživatelských rolí a oprávnění"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskování citlivých dat"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilé možnosti filtrování a vyhledávání konverzací"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobitelné výsledkové listiny s několika hodnoceními, nastavitelnými váhami a možnostmi automatického řešení selhání"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšíření prohlížeče pro kontrolu konverzací kdekoli"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza základní příčiny"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykazování výkonu pro porovnávání agentů, týmů, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum CSAT a analýza zpětné vazby od zákazníků"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkce"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše, co zahrnuje tarif Starter, a navíc:"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (starší verze)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Několik stupnic hodnocení na konverzaci"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace služby Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktivní poznatky z konverzace řízené umělou inteligencí"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly kontroly"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piny pro koučink"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koučinkové relace"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza a filtrování vyznění"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrování na základě složitosti konverzace"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznatky z konverzace"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmíněné výsledkové listiny"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analýza základní příčiny"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá analytika CSAT s podporou umělé inteligence"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace hodnocení"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšíření prohlížeče pro kontrolu konverzace kdekoli"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznění pro zjišťování konverzací, které je nutné zkontrolovat"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Překlad konverzací na vyžádání"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledování času kontroly"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní tarif"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (starší verze)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neomezený počet kontrol konverzací"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzájemné a vlastní kontroly"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny s nastavitelnou váhou a možnostmi automatického řešení selhání"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robustní možnosti filtrování"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denní, týdenní nebo měsíční cíle kontroly"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy CSAT a základní analytika"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovládací panely"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkazy pro porovnávání agentů, týmů, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa rolí a oprávnění uživatele"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkce"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chci provést změnu ve svém předplatném Zendesk ZK.\n\nZvýrazněte nebo označte jednu možnost a uveďte počet míst, která chcete změnit:\nChci [přidat X míst] [odstranit X míst] [upgradovat na plán Zendesk ZK]\n\nSdělte nám název své společnosti a jméno přidružené k vašemu účtu Zendesk ZK a také jakékoli další podrobnosti, které bychom měli kvůli zpracování této změny znát."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna předplatného Zendesk ZK"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chci si koupit předplatné Zendesk ZK.\n\nSdělte nám název své společnosti a jméno přidružené k vašemu účtu Zendesk ZK a také jakékoli další podrobnosti, které bychom měli kvůli zpracování tohoto požadavku znát.\n\nPodělte se také o jakékoli konkrétní otázky, na které byste chtěli před nákupem odpovědět:\n\nDěkujeme!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup předplatného Zendesk ZK"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše předplatné končí dne ", _interpolate(_named("date")), " (zbývá: ", _interpolate(_named("days")), ")."])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vaše zkušební období končí ", _interpolate(_named("date")), " (zbývá ", _interpolate(_named("days")), ")."])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 den"]), _normalize(["počet dní: ", _interpolate(_named("n"))])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") k ", _interpolate(_named("dayOfMonth")), ". dni každého měsíce."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Účtováno na kartu ", _interpolate(_named("card")), " (konec platnosti ", _interpolate(_named("expires")), ") dne ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["končící na"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Platnost vypršela"]), _normalize(["Platnost vyprší za 1 den"]), _normalize(["Platnost vyprší za několik dní (celkem ", _interpolate(_named("expiresIn")), ")"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Počet uživatelských licencí, které zahrnuje startovní cena, je ", _interpolate(_named("n")), "."])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["začíná od ", _interpolate(_named("sum")), "/měsíc"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["měsíc"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za uživatele/měsíc"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za rok"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za měsíc"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čtvrtletí"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušte plánované zrušení"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pokud chcete i nadále používat Zendesk ZK, ", _interpolate(_named("link")), "."])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Váš odběr byl zrušen dne ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Platnost vašeho odběru by měla skončit dne ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "denní zkušební období bez příplatku!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["využito"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Máte několik uživatelů (celkem ", _interpolate(_named("n")), "). Počet míst nemůže být nižší než daná hodnota."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odběr bude obnoven dne ", _interpolate(_named("time")), "."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rok"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Váš současný tarif Zendesku ZK (", _interpolate(_named("plan")), ") neobsahuje funkce s podporou umělé inteligence. Chcete-li využívat funkce hlasové inteligence na maximum, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li odběr zrušit, kontaktujte nás ", _interpolate(_named("link")), ", nebo s námi chatujte."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plán řízení kvality"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řízení kvality"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odběr byl aktualizován."])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat kreditní kartu"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny tarify"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturace"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit fakturační údaje"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval fakturace"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit odběr"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš odběr bude na konci fakturačního období zrušen."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktujte nás"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktujte nás ohledně úpravy"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální tarif"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní ceny"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgradovat"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční (ušetříte 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíčně"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platit ročně"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíčně"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava odběru"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozkoumat tarify"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezplatné zkušební období"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturováno ručně"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V dalším kroku můžete zkontrolovat a potvrdit konečný souhrn objednávky."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejpopulárnější"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte aktivní způsob platby."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá zkušební období"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte svůj aktuální odběr, abyste mohli tarif změnit."])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavit odběr"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíčně"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtletně"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu aktivovat odběr"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit odběr"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místa"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahájit zkušební období"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušeno"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez obnovení"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavený"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav odběru"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkušební období"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradovat"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradovat odběr"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Věnujte minutu svého času tomu, abyste poskytli zpětnou vazbu ke svým zkušenostem se zákaznickou podporou. Předem děkujeme – ", "{", "{", "agentName", "}", "}", " si váží vaší zpětné vazby!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdravíme, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Průzkum CSAT uživatele ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak hodnotíte podporu, kterou jste obdrželi od agenta ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Velmi si toho vážíme. Vaši zpětnou vazbu využijeme k tomu, abychom službu ", _interpolate(_list(0)), " dále vylepšili pro všechny."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o průzkumech"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte typ průzkumu"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Později ho lze změnit."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měřte a zlepšete spokojenost zákazníků díky automatizovaným průzkumům CSAT ve více jazycích."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné průzkumy"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit dotazník"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název průzkumu"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl vytvořen."])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zkuste klíčové slovo změnit, nebo ", _interpolate(_named("contact_us")), ", pokud chcete, aby se zde zobrazilo."])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dejte nám vědět"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl nalezen žádný jazyk"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vyberte jeden nebo více jazyků ze seznamu níže. ", _interpolate(_named("contact_us")), ", pokud zde nevidíte jazyk, který chcete použít."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat jazyky"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejte nám vědět"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte jazyk"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Naposledy publikováno ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Naposledy uloženo ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otázka ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úvodní otázka"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popisek levé stupnice"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět e-mailu"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvody"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popisek pravé stupnice"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud v průzkumu chybí nějaké překlady, zobrazí se místo nich texty v základním jazyce."])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít překlady"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybějící překlady"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nezapomeňte všechny své překlady před použitím zkontrolovat. Proveďte změny ve staženém souboru, uložte jej a v případě potřeby ho znovu uploadujte. Pokud se token ", _interpolate(_named("token_example")), " nezobrazí jako štítek, je buď v nesprávném formátu, nebo v poli, které tokeny nepodporuje."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte překlady"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeloženo"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrnuje konverzaci a zobrazuje návrhy komentářů."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI pomocník pro Zendesk ZK (v angličtině)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvoření můžete typy upravovat."])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento typ již existuje. Vyberte jiný název."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte vlastní typ jako volitelnou možnost v rozbalovacím seznamu Typ průzkumu a seskupte všechny vytvořené průzkumy na ovládacích panelech."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte slova a stisknutím klávesy Enter je uložte."])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit vlastní typy"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název typu nesmí být prázdný."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definovat typ"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní typ nebyl uložen. Stisknutím klávesy Enter jej uložte."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail odesílatele"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno odesílatele"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do jaké míry souhlasíte s následujícím tvrzením: „Díky ", _interpolate(_named("agentName")), " bylo řešení mého problému snazší“?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak snadné zatím bylo získat pomoc od ", _interpolate(_named("brandName")), "?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak snadné dnes obecně bylo řešení vašeho problému?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak snadné bylo komunikovat s naším týmem?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa URL pro Privacy Policy (Zásady ochrany osobních údajů) (nepovinné)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa URL podmínek služby (nepovinné)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak byste ohodnotili podporu, kterou jste obdrželi od ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaká byla naše služba pro tento požadavek?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak jste byli spokojeni se zástupcem podpory?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jak jste spokojeni se službou, kterou jste dnes od ", _interpolate(_named("agentName")), " obdrželi?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní otázka"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definujte si vlastní otázku ", _interpolate(_named("type")), "…"])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte vlastní otázku"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobit adresy URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení e-mailové zprávy"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava názvu vlastního typu otázky jej aktualizuje ve všech průzkumech, které ho používají, aniž by to ovlivnilo shromážděná data."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úprava vlastních typů"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předmět e-mailu"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úvodní otázka"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otázka ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový vlastní typ"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token personalizace"]), _normalize(["Tokeny personalizace"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Přetáhněte token personalizace do pole níže."]), _normalize(["Přetáhněte tokeny personalizace do polí níže."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konverzace ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálně je možné zasílat průzkumy CSAT pouze e-mailem. Pracujeme na tom, abychom vám nabídli další možnosti, jako jsou průzkumy CSAT prostřednictvím technické podpory, SMS průzkumy a další."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synchronizovat nebo obnovit propojení"]), _normalize(["Synchronizovat nebo obnovit propojení"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizovat nebo obnovit propojení ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Propojení není správně synchronizováno"]), _normalize(["Propojení nejsou správně synchronizována."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušte zaškrtnutí příslušného políčka ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit hned ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte alespoň jedno pravidlo odeslání průzkumu, aby bylo možné aktivovat propojení."])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toto propojení je momentálně vypnuto. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toto propojení maskuje citlivá data klienta. Chcete-li povolit odesílání průzkumu,\n", _interpolate(_named("link")), " v nastavení."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toto propojení vyžaduje další oprávnění k rozesílání průzkumů.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit hned"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK vyžaduje další oprávnění pro Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " a ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hodina"]), _normalize([_interpolate(_named("n")), " hod"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minut"]), _normalize(["1 minuta"]), _normalize([_interpolate(_named("n")), " min"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hodina"]), _normalize(["hodin/y"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), ", chcete-li konfigurovat pravidla odesílání průzkumů."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" den"]), _normalize(["dny"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost odkazů na průzkum vyprší"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost odkazů vyprší za"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabraňte odpovídání na průzkumy, pokud uplynulo příliš mnoho času."])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Průzkum ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky oddělte čárkami, nebo po zadání stiskněte Enter. Ujistěte se, že každý z nich je uveden přesně jako v Intercomu."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte podmínky, na základě kterých bude průzkum rozeslán."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla rozesílání průzkumů"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla rozesílání navazujících průzkumů"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Průzkum ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " již existuje.\nVyřešte konflikt tím, že upravíte pravidla zde nebo v ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Již jste nastavili propojení s odpovídajícími pravidly pro průzkum ", _interpolate(_named("name")), ". Vyřešte konflikt tím, že upravíte pravidla zde nebo v ", _interpolate(_named("link")), "."])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání dalšího pravidla"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte značku"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte podmínku"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdit"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte hodnotu"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte štítek"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odpovídající stejným pravidlům"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte hodnotu"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail odeslán za"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navazující e-mail odeslán za"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložený výstřižek a navazující e-mail"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírovat kód"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojte ke svým e-mailům průzkumy Zendesku ZK tak, že přidáte kód výstřižku do nastavení Zendesku."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalační příručka"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřít Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled výstřižku"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód výstřižku zkopírován do schránky"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstřižek se nepodařilo zkopírovat."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložený výstřižek"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento průzkum obsahuje neuložené změny. Uložte ho znovu, abyste se ujistili, že kód výstřižku bude odrážet nejnovější změny."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat propojení"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyla přidána žádná propojení"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díky propojení přeneste e-maily, živý chat, telefonní hovory a další do Zendesku ZK."])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o doručování průzkumů"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla rozesílání průzkumů"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla rozesílání navazujících průzkumů"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat pravidlo značky"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat pravidlo"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřit"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Následné monitorování přes Messenger + e-mail"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte svůj účet Intercom s průzkumem CSAT v Zendesku ZK"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručit prostřednictvím"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdo a kdy obdrží průzkum"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit pravidla"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnuto"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy v aplikaci umožňují vašim zákazníkům vyplnit průzkum, aniž by opustili chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spustit Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla doručování na propojení"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Průzkum ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " již existuje.\nVyřešte konflikt tím, že zakážete propojení nebo upravíte pravidla zde nebo v průzkumu ", _interpolate(_named("link")), "."])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručení"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte podmínky, na základě kterých bude dotazník odeslán."])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vytvořil/a ", _interpolate(_named("name")), " dne ", _interpolate(_named("createdDate")), "."])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Odstranění ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranění tohoto jazyka znamená, že již nebude viditelný pro žádné respondenty a všechny přeložené texty budou smazány."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit jazyk"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto akci nelze vzít zpět."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Případně můžete jazyk zakázat tím, že jej v průzkumu skryjete. Díky tomu si budete moci ponechat všechny vaše překlady, aniž byste je ukazovali respondentům."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naposledy upravil/a ", _interpolate(_named("name")), " dne ", _interpolate(_named("createdDate")), "."])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o jazycích průzkumů"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Před přidáním dalších jazyků se ujistěte, že jste dokončili provádění změn v krocích Styly a Obsah. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat jazyk"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeložená pole"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditelné pro respondenty"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výchozí"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout soubor překladu"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit jazyk"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" si stáhněte soubor překladu"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte překlady"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Překlad dokončen. ", _interpolate(_named("review_link")), " nebo ", _interpolate(_named("download_link")), ", chcete-li provést jakékoli změny."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" si stáhněte soubor překladu"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte, co chybí"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Část obsahu ještě nebyla přeložena a bude nahrazena angličtinou. ", _interpolate(_named("review_link")), " nebo ", _interpolate(_named("download_link")), ", chcete-li přidat chybějící překlady."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte průzkum"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Průzkum obsahuje neuložené změny. ", _interpolate(_named("save")), ", chcete-li si stáhnout soubor překladu s nejnovějšími změnami."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploadovat soubor překladu"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte preferovanou stupnici hodnocení."])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazníkům nabízí rozbalovací seznam pro výběr důvodu hodnocení."])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text levé stupnice (nepovinné)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čísla"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte důvod"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod nemůže být prázdný."])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento důvod již existuje."])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit rozbalovací nabídku s důvody vašeho ohodnocení"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazáním důvodu a jeho opětovným přidáním se stejným nebo jiným názvem se vždy vytvoří nová položka. V důsledku toho to má vliv na data zobrazená na ovládacích panelech."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat důvod"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazaný důvod zůstane ve výkazech ovládacích panelů, ale nebude viditelný pro nové respondenty průzkumu. Totéž platí i pro související překlady."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazání důvodu"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Smazat důvod „", _interpolate(_list(0)), "“"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text pravé stupnice (nepovinné)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na pětibodové stupnici jsou hodnoty 1 a 2 považovány za negativní ohodnocení; 3 je neutrální; 4 a 5 jsou pozitivní."])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte text stupnice"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stupnice ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stupnice hodnocení"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ZK importuje vaše konverzace. Tento proces může trvat až 24 hodin."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V průběhu průzkumu to bude použito jako token personalizace."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název značky"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlačítka a odkazy"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobte si vzhled svého průzkumu."])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozadí loga"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podporovány jsou formáty JPG, PNG a GIF."])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styling"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploadovat logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stylové rozloučení"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte zprávu, která se lidem zobrazí po odeslání zpětné vazby."])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poděkování"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávný kód barvy HEX"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná e-mailová adresa"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole nemůže být prázdné."])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná adresa URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není vidět:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je vidět:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslané průzkumy"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy odeslané za posledních 30 dní"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl smazán."])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl duplikován."])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled e-mailu byl odeslán."])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl publikován."])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl aktualizován."])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkum byl pozastaven."])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavit"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavit průzkum"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte e-mail pro odeslání náhledu průzkumu"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vas", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte jazyk pro náhled"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat e-mail s náhledem"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlete si vzorový průzkum na svůj e-mail. Hodnocení z náhledu průzkumu se neuloží."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Náhled průzkumu ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit náhled e-mailového průzkumu na webu"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restartovat"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit jako koncept"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název značky"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název specifický pro značku lze přidat v kroku Styling."])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno zákazníka"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID podpory"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zástupce podpory"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazaný"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavený"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Průzkum CSAT uživatele ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuložené změny"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace a obnovení průzkumu"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pokud se vám tento e-mail nezobrazuje správně, ", _interpolate(_named("link")), "."])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl/a jsem spokojený/á s podporou, které se mi dostalo, protože…"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá technologie Zendesk ZK"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte důvod"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekapitulace konverzace"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příklad: Zákazník měl problémy s aktualizací způsobu platby. Agent sdílel článek, který by mohl pomoci. Povedlo se."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá technologie Zendesk ZK AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínky služby"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za vaši zpětnou vazbu!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaký je hlavní důvod vašeho ohodnocení?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řekněte nám, proč jste udělili toto hodnocení…"])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velké úsilí"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malé úsilí"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kliknutím na tento odkaz otevřete průzkum"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromí"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se zobrazí vaše vlastní otázka"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Své zkušenosti s ", _interpolate(_list(0)), " jste udělili ohodnocení"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat zpětnou vazbu"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit odběr"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velmi nespokojen/a"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velmi spokojen/a"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální stav průzkumu a pravidla doručení nebudou zahrnuta do duplikátu průzkumu."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplikujte průzkum „", _interpolate(_list(0)), "“"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud používáte vložený výstřižek e-mailu, nezapomeňte jej před pozastavením průzkumu ze Zendesku odstranit."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když je průzkum pozastaven, neshromažďují se žádné odpovědi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavení průzkumu"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Před pozastavením průzkumu nezapomeňte odstranit výstřižek ze Zendesku."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat průzkum"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud používáte vložený výstřižek e-mailu, nezapomeňte jej před smazáním průzkumu ze Zendesku odstranit."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato akce průzkum trvale smaže. Uživatelé, kteří obdrželi průzkum, jej stále mohou vyplnit a všechna data související s průzkumem zůstanou v Zendesku ZK."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání průzkumu ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Před smazáním průzkumu nezapomeňte odstranit výstřižek ze Zendesku."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vámi použité změny uvidí noví respondenti."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikujte průzkum"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po publikování průzkumu nezapomeňte zkopírovat nejnovější verzi vloženého výstřižku."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud používáte vložený výstřižek e-mailu, nezapomeňte ho po obnovení průzkumu zkopírovat do Zendesku."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračujte v rozesílání průzkumu a shromažďování odpovědí."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace a obnovení průzkumu"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po obnovení průzkumu nezapomeňte vložený výstřižek přidat do Zendesku."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizace průzkumu"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po aktualizaci průzkumu nezapomeňte zkopírovat nejnovější verzi vloženého výstřižku."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat tento průzkum? Tuto akci nelze vzít zpět."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat průzkum?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost vašeho zkušebního období vypršela, ale upgradem na plnohodnotný účet si můžete Zendesk ZK nadále užívat."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost vašeho zkušebního období vypršela, ale upgradem na plnohodnotný účet si můžete Zendesk ZK nadále užívat. Ohledně nastavení této funkce se obraťte na místního administrátora."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše zkušební období skončilo"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgradovat hned"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bezplatné zkušební období skončilo."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li i nadále používat plnou verzi Zendesku ZK, zadejte své platební údaje."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li i nadále používat plnou verzi Zendesku ZK, administrátor vašeho účtu musí zadat platební údaje."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovat platby"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uživatel"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vytvořil/a ", _interpolate(_named("date")), " dne ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " úkol"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že na tom stále pracujete. Pokud odejdete bez uložení změn, přijdete o všechny informace, které jste až dosud přidali."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tento úkol se bude opakovat každý druhý týden v daný den: ", _interpolate(_named("weekDay")), "."])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tento úkol se bude opakovat vždy v daný den: ", _interpolate(_named("weekDay")), "."])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Všechny kontrolované subjekty zahrnuté v jednom cyklu"]), _normalize(["Všechny kontrolované subjekty zahrnuté v ", _interpolate(_named("nr")), " cyklech"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konverzací"]), _normalize([_interpolate(_named("n")), " konverzace"]), _normalize(["Počet konverzací: ", _interpolate(_named("n"))])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["z ", _interpolate(_named("nr")), " agentů"]), _normalize(["z ", _interpolate(_named("nr")), " agenta"]), _normalize(["z ", _interpolate(_named("nr")), " agentů"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["účastník"]), _normalize(["účastníci"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " konverzace na cyklus"]), _normalize([_interpolate(_named("n")), " konverzace na cyklus"]), _normalize(["Počet konverzací na cyklus: ", _interpolate(_named("n"))])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " na kontrolovaný subjekt v řadě"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kontrolovaný subjekt"]), _normalize(["kontrolované subjekty"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kontrolor"]), _normalize(["kontroloři"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé pracovního prostoru"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé pracovního prostoru kromě agentů"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cyklus ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neopakovaný"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Úkol ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhá kontrola"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o úkolech"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit úkol"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato akce trvale smaže úkol a odstraní jeho data z ovládacích panelů. Všechny související kontroly zůstanou v Zendesku ZK."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání úkolu ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol smazán"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol duplikován"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly přidány žádné úkoly"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření úkolu se nezdařilo."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název úkolu"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyklus se obnoví a vytvoří se nový seznam úkolů. Již provedená kontrola se stále započítává do cíle."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okamžitě"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Na začátku příštího cyklu dne ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdy publikovat změny?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny provedené v tomto úkolu budou publikovány s tímto úkolem."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit úkol jako aktivní?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakuje se v den zahájení úkolu"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První pátek v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhý pátek v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí pátek v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtý pátek v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední pátek v měsíci"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První pondělí v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhé pondělí v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí pondělí v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrté pondělí v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední pondělí v měsíci"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První sobota v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhá sobota v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí sobota v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtá sobota v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední sobota v měsíci"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První neděle v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhá neděle v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí neděle v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtá neděle v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední neděle v měsíci"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První čtvrtek v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhý čtvrtek v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí čtvrtek v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtý čtvrtek v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední čtvrtek v měsíci"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První úterý v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhé úterý v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí úterý v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrté úterý v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední úterý v měsíci"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První středa v měsíci"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhá středa v měsíci"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí středa v měsíci"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtá středa v měsíci"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední středa v měsíci"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní opakování"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. den v měsíci"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["První"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druhý"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třetí"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtvrtý"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakuje se"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní den"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné výsledky"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Časové pásmo"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení cyklu"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název úkolu"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní informace"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na začátku každého cyklu úkolu se vygeneruje předem stanovený seznam konverzací k vyhodnocení."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá nastavení"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatické načítání nových konverzací, když není splněn cíl kontroly"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatické načítání není možné s cíli kontroly v procentech (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musí to být celé číslo, ne desetinné číslo."])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit nahrazování konverzací (role kontrolora a vyšší)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrazení konverzací"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit kontrolorům přiřazení vlastních konverzací ke kontrole"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroly"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální počet kontrol musí být alespoň 1."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Určete minimální počet kontrol pro každý kontrolovaný subjekt."])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konverzace"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procento (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroly"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroly celkem"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každý kontrolor musí provést stanovený počet kontrol."])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři musí souhrnně zkontrolovat stanovený počet konverzací"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální počet kontrol musí být alespoň 1."])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl kontroly musí být mezi 1 a 100 %."])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl kontroly"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každý kontrolovaný subjekt musí mít zkontrolovaný minimální počet svých konverzací."])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři si mohou vybrat konverzace ze sdíleného fondu."])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolorům jsou přiřazeny konverzace ze sdíleného fondu."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup k úkolu"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni roboti"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni účastníci pracovního prostoru"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé pracovního prostoru"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kontrolované subjekty."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdo bude kontrolován?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kontrolované subjekty"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte, kdo se má kontrolovat"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučení jsou povolena jenom v případě, že jsou vybráni všichni uživatelé pracovního prostoru nebo skupina."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři a kontrolované subjekty"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aktuální: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výchozí pracovní prostor"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaký typ uživatelů by měl být vybrán jako kontrolovaný subjekt?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejaktivnější agent"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastník"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit kontrolované subjekty (nepovinné)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele, které chcete vyloučit"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit kontrolory (nepovinné)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele, které chcete vyloučit"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé pracovního prostoru kromě agentů"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kontrolory."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kdo bude provádět kontroly?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kontrolory"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly nejsou v tomto pracovním prostoru povoleny"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit vlastní kontroly"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní kontroly nejsou v tomto pracovním prostoru povoleny."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Odpovídající dnešní konverzace"]), _normalize(["Odpovídající dnešní konverzace"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazuje se, jako by úkol začal dnes."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budoucí čísla se mohou lišit."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vybrány žádné kontrolované subjekty."])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli vybráni žádní kontroloři."])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontrolovaný subjekt"]), _normalize(["Kontrolované subjekty"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontrolor"]), _normalize(["Kontroloři"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatické načítání: povoleno, pokud není dostatek konverzací"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " v pátek od ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " v pondělí od ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " v sobotu od ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " v neděli od ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ve čtvrtek od ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " v úterý od ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ve středu od ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denně od ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Začíná ", _interpolate(_named("startDateTime")), " a končí ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Podmínky data ", _interpolate(_named("count"))])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konverzace a podmínky technické podpory (", _interpolate(_named("count")), ")"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Žádná konverzace a ", _interpolate(_named("count")), " podmínka technické podpory"]), _normalize(["Žádná konverzace a několik podmínek (", _interpolate(_named("count")), ") technické podpory"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " konverzace a žádná podmínka technické podpory"]), _normalize(["Několik (", _interpolate(_named("count")), ") konverzací a žádné podmínky technické podpory"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Přístup: Kontroloři vybírají konverzace."]), _normalize(["Přístup: Kontrolorům jsou přiřazeny konverzace."])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li pokračovat, opravte chyby zvýrazněné v souhrnu."])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl: Kontroloři musí zkontrolovat všechny konverzace."])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cíl: Kontroloři musí zkontrolovat jednu konverzaci."]), _normalize(["Cíl: Kontroloři musí zkontrolovat několik konverzací, celkem ", _interpolate(_named("count")), "."])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cíl: Kontroloři musí zkontrolovat ", _interpolate(_named("count")), " % konverzací."])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl: Nebyl stanoven žádný cíl kontroly."])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl: Každý kontrolovaný subjekt musí mít zkontrolovány všechny své konverzace."])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cíl: Každý kontrolovaný subjekt musí mít zkontrolováno několik (", _interpolate(_named("count")), ") z celkového počtu konverzací."])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cíl: Každý kontrolovaný subjekt musí mít zkontrolováno ", _interpolate(_named("count")), " % z celkového počtu konverzací."])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cíl: Každý kontrolor musí provést jednu kontrolu."]), _normalize(["Cíl: Každý kontrolor musí provést několik kontrol, celkem ", _interpolate(_named("count")), "."])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nahrazení: Kontroloři nemohou nahrazovat konverzace."]), _normalize(["Nahrazení: Kontroloři mohou nahrazovat konverzace."])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastní hodnocení: Kontrolorům je možné přiřadit vlastní konverzace."])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou stanoveny žádné podmínky data."])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není nastaven žádný název úkolu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhrn"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmínky"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " z ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každé dva týdny"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denně"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíčně"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdně"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délka cyklu"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit jako aktivní"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit jako koncept"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol uložen jako koncept"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol nastaven jako neaktivní"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit jako neaktivní"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit úkol"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol vytvořen"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako aktivní"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako neaktivní"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Čekající aktualizace úkolu se projeví v dalším cyklu, počínaje ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat změny"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekající"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat úkol"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol byl aktualizován."])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent nebo robot ukončil konverzaci."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent ukončil konverzaci zdvořile."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda byla konverzace ukončena."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavření"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje gramatické, pravopisné a stylistické chyby v interakcích agentů i robotů."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje gramatické, pravopisné a stylistické chyby agenta."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje gramatické, pravopisné a stylistické chyby agenta."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravopis a gramatika"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje konverzaci a hledá pozdravy agentů a robotů."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá typické uvítací fráze."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá typické pozdravy."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrav"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent chápe a uznává pocity zákazníků."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikuje případy, ve kterých agent zrcadlil vyznění zákazníka nebo použil laskavá slova jako „chápu“ a „rozumím“."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatie"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent rozumí dotazu nebo obavám zákazníka."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, zda agent rozumí dotazu nebo obavám zákazníka."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porozumění"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, jak snadno lze textu porozumět, s ohledem na složitost slov a délku věty."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje, jak snadno lze textu porozumět, s ohledem na složitost slov a délku věty."])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato zpráva obsahuje 1 příliš dlouhou větu, kvůli které je obtížně pochopitelná."])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tato zpráva obsahuje několik příliš dlouhých vět (celkem ", _interpolate(_list(0)), "), kvůli kterým je obtížně pochopitelná."])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čitelnost"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá nabízené řešení."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje celou konverzaci a hledá nabízené řešení."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízené řešení"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje tón agenta v průběhu konverzace."])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzuje tón agenta v průběhu konverzace."])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se zjistit žádné chyby ze zpráv agenta."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se určit gramatické chyby."])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla zjištěna žádná fráze pozdravu. V případě potřeby přidejte nové prostřednictvím administrátora v nabídce Nastavení."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent konverzaci ukončil zdvořile."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla zjištěna žádná uzavírací fráze. V případě potřeby přidejte nové prostřednictvím administrátora v nabídce Nastavení."])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent zrcadlil vyznění zákazníka nebo používal laskavá slova."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze zjistit, zda agent zrcadlil vyznění zákazníka nebo používal laskavá slova."])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent používal dlouhé věty a složitá slova, kvůli kterým mohlo být pro někoho potenciálně obtížné ho pochopit."])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent používal složitá slova, kvůli kterým mohlo být pro někoho potenciálně obtížné ho pochopit."])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent používal dlouhé věty, kvůli kterým mohlo být pro někoho potenciálně obtížné ho pochopit."])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent používal jazyk, který je snadno srozumitelný."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedostatek dat pro hodnocení této kategorie"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent se snažil pochopit otázku/požadavek zákazníka."])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent dobře pochopil otázku/požadavek zákazníka."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se zjistit, zda agent pochopil otázku/požadavek zákazníka."])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón agenta během konverzace"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón agenta během konverzace"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón agenta během konverzace"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón agenta během konverzace"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tón agenta během konverzace"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze určit tón agenta."])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace nebyla ukončena."])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace byla ukončena."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze zjistit, zda byla konverzace ukončena."])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentovi chyběla empatie vůči zákazníkovi a jeho problémům."])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent projevil empatii vůči zákazníkovi a jeho problémům."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se zjistit, zda agent projevil empatii vůči zákazníkovi a jeho problémům. "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník nebyl během konverzace přivítán."])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákazník byl během konverzace přivítán."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze zjistit typický pozdrav."])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent neposkytl zákazníkovi řešení."])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent nabídl zákazníkovi řešení."])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodařilo se zjistit, zda agent nabídl zákazníkovi řešení."])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složitá slova"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlouhá souvětí"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramatika"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravopisná chyba"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omluvný"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klidný"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šťastný"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvídavý"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpůrný"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styl"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 uzavření"]), _normalize([_interpolate(_named("n")), " uzavření"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK kontroluje pouze schválená uzavření. Pokud je tato kategorie nastavena jako kritická, nepoužití schválených uzavření způsobí neúspěch kontroly."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválená uzavření"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválená uzavření"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 výjimka"]), _normalize(["Počet výjimek: ", _interpolate(_named("n"))])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slova a fráze, které AutoZK ignoruje jako pravopisné nebo gramatické chyby"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravopisné a gramatické výjimky"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjimky z AutoZK"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 pozdrav"]), _normalize(["Počet pozdravů: ", _interpolate(_named("n"))])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK kontroluje pouze schválené pozdravy. Pokud je tato kategorie nastavena jako kritická, nepoužití schválených pozdravů způsobí neúspěch kontroly."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválené pozdravy"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schválené pozdravy"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie AutoZK"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kategorii AutoZK"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaticky přiřazené základní příčiny:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení → Účet"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Některé kategorie využívají službu MS Azure, která nabízí rozšířenou řadu funkcí a podporovaných jazyků. Další informace najdete v nabídce ", _interpolate(_named("link")), ", nebo se obraťte na administrátora."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Získejte přístup k rozšířenému seznamu kategorií a podporovaných jazyků ve službě MS Azure. Přejděte do nabídky ", _interpolate(_named("link")), ", nebo se obraťte na administrátora."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít AutoZK"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kategoriím AutoZK je ohodnocení přiřazeno automaticky pomocí pevné stupnice hodnocení. Hodnocení můžete později upravit ručně. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kategorii AutoZK"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaticky přidělené ohodnocení závisí na závažnosti chyb a jejich počtu. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základní příčiny"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolorům se zobrazují pouze relevantní kategorie hodnocení. Kategorie hodnocení se zobrazují na základě podmínek, které jste nastavili při vytváření kategorie."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaškolení"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vyberte typ kategorie hodnocení pro pracovní prostor „", _interpolate(_named("workspace")), "“."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři musí ručně vybrat správnou výsledkovou listinu, aby viděli relevantní kategorie hodnocení pro každou konverzaci."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete změnit typ kategorie hodnocení?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit kategorie"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit typ kategorie hodnocení?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmíněná kategorie hodnocení"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statická kategorie hodnocení"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kategorie hodnocení"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení kategorie hodnocení"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto akci nelze vzít zpět."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smaže všechna hodnocení"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Případně si své statistiky ponecháte, aniž byste smazali jakákoli data, pokud kategorii archivujete."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chápu, že tento krok ", _interpolate(_named("deleteAllRatings")), " provedená pro tuto kategorii."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. Tón je dokonalý"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit kategorii, pouze pokud"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Vždy“ bude ignorováno. Nelze kombinovat s jinými podmínkami."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vždy"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky technické podpory"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte podmínku"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení spokojenosti (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zdroje"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanál konverzace"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie vyžaduje název."])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kategorii na výsledkovou listinu."])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit kategorii"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit kategorii"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovat"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla přidána."])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovaná kategorie není viditelná ve výsledkové listině, ale umožňuje uchovávat statistiky, aniž by bylo nutné mazat jakákoli data."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovat kategorii"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archivovat kategorii ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla archivována."])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kategorii"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li tuto kategorii smazat, zadejte příkaz „smazat kategorii“."])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smazat kategorii"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Smazat kategorii ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla smazána."])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla duplikována."])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina kategorií hodnocení byla přidána."])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina kategorií hodnocení byla smazána."])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořadí skupin kategorií hodnocení bylo změněno."])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina kategorií hodnocení byla aktualizována."])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivace kategorie hodnocení byla zrušena."])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hodnocení byla aktualizována."])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když agent selže v kritické kategorii, všechny kategorie v dané kontrole budou automaticky neúspěšné."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritická kategorie hodnocení"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V této skupině nejsou žádné kategorie."])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte novou kategorii hodnocení"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit kategorii"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat všechny kategorie a data kontroly v této skupině"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud smažete skupinu kategorií, všechny kategorie, které jsou s ní propojeny, zůstanou neseskupené."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazat skupinu kategorií ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud se rozhodnete smazat kategorie ve skupině, dojde ke ztrátě všech dat kontroly přidružených k těmto kategoriím."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název skupiny"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři mohou jako základní příčinu vybrat více než jeden důvod"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři mohou jako základní příčinu vybrat více než jeden důvod"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Několik důvodů"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["např. Tón"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat novou skupinu kategorií"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová skupina"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat „Něco jiného“"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat základní příčinu"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato základní příčina již existuje."])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte základní příčinu"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat základní příčiny k vysvětlení hodnocení"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného…"])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stupnice hodnocení"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovány"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte výsledkové listiny k této kategorii hodnocení"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit archivaci"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat kategorii"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechna hodnocení"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazeno pro"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní hodnocení"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativní a neutrální hodnocení"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano, aktualizovat"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váha"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie, které jsou pro vás důležitější, mohou mít při výpočtu ohodnocení tiketu větší váhu."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Váha (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o kalibraci"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o kalibraci"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořádání pravidelných kalibračních relací umožňuje kontrolorům udržet soulad svých technik hodnocení a zajistit, aby agenti obdrželi stejnou úroveň konzistentní a nezaujaté zpětné vazby."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit tarify"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " je proces, který pomáhá vašemu týmu zůstat na stejné vlně ohledně toho, jak by měly být řešeny a hodnoceny všechny interakce se zákazníky."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrace je k dispozici pro naše tarify ", _interpolate(_list(0)), " a ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dříve provedené kontroly se při přidání konverzace do relace nezobrazí."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není vidět"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dříve provedené kontroly jsou považovány za kalibrační kontroly. Do relace bude přidána pouze jedna kontrola konverzace pro každého kontrolora. Kontroly specifické pro zprávy se nezobrazují."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditelné"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditelnost dříve provedených kontrol"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrace je proces, který pomáhá vašemu týmu zůstat na stejné vlně ohledně toho, jak by měly být řešeny a hodnoceny všechny interakce se zákazníky. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé mají neomezený přístup ke všem kontrolám provedeným během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vždy zobrazit všechny kontroly"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potenciální kontakt"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odeslání kontroly se uživatelům zobrazí hodnocení poskytnutá ostatními kontrolory během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny kalibrační kontroly po odeslání kontroly"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé mají neomezený přístup ke všem kontrolám provedeným během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vždy zobrazit všechny kontroly"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadřízený"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odeslání kontroly se uživatelům zobrazí hodnocení poskytnutá ostatními kontrolory během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny kalibrační kontroly po odeslání kontroly"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odeslání kontroly se uživatelům zobrazí hodnocení poskytnutá ostatními kontrolory během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit všechny kalibrační kontroly po odeslání kontroly"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé uvidí pouze své vlastní kontroly provedené během kalibrační relace."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit pouze vlastní kontroly"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení kalibrace aktualizována"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditelnost kalibračních kontrol"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte prahovou hodnotu pro metriky ovládacích panelů tím, že vyplníte mezery podle interního systému metrik vašeho týmu. Zkontrolujte prahovou hodnotu na ovládacích panelech."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrace"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagy"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prahová hodnota"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prahová hodnota byla aktualizována."])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chvilku strpení…"])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ujistěte se, že máte oprávnění administrátora a ", _interpolate(_named("retry")), ", nebo ", _interpolate(_named("contact")), ", pokud potřebujete další nápovědu."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nás kontaktujte"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že se nám nepodařilo získat přístup k rozhraní API vaší technické podpory."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zkuste to znovu"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojujeme vaši technickou podporu. Za chvíli budete přesměrováni."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorizováno"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vraťte se na svou ", _interpolate(_list(0)), " a přidejte nové propojení technické podpory."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že jste jeden nebo dva kroky vynechali."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizace se stejnou e-mailovou doménou již existuje"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stránku propojení"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezitím…"])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejte nám chvilku, než zkontrolujeme vaše oprávnění."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezitím…"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vytvořte pracovní prostor"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chcete-li pokračovat v používání Zendesku ZK, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téměř připraveno k použití"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyl nalezen žádný pracovní prostor."])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout účet"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li i nadále používat Zendesk ZK, musí vás váš nadřízený přiřadit k pracovnímu prostoru."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazáním účtu trvale smažete všechna data, která o vás máme – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["všechna poskytnutá hodnocení, obsah konverzace, pracovní prostory atd."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do Slacku"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpojit"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpojením integrace deaktivujete všechna oznámení Zendesku ZK prostřednictvím Slacku."])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpojit Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrace Slacku byla odpojena od Zendesku ZK."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definujte výchozí nastavení oznámení pro všechny nové uživatele účtu."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepsat nastavení pro všechny uživatele"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chystáte se přepsat nastavení oznámení pro všechny uživatele účtu."])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé nebudou moci definovat vlastní nastavení oznámení."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení oznámení bylo aktualizováno."])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooky"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornění při selhání kritické kategorie"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornění, když CSAT obdrží odpověď"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivace"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit ukázkový pracovní prostoru a data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu vytvořte ukázková data a zachovejte ukázkový pracovní prostor."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit ukázkový pracovní prostor a data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit ukázková data"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spravovat ukázkový režim účtu „", _interpolate(_named("accountName")), "“"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukázkový režim"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zůstat na stránce"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit stránku"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opustit tuto stránku, i když přijdete o všechny změny?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončili jste úkol."])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte úkol."])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkol splněn"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné úkoly"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálně nejsou k dispozici žádné úkoly, které byste mohli zkontrolovat."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte úkol nebo počkejte na zahájení dalšího cyklu."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste upravit filtry."])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte úkol."])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledujte tuto stránku, zda se neobjeví nové informace pro aktualizace."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné úkoly pro tento cyklus"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné konverzace k zobrazení"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit úkol jako dokončený"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolovali jste tohoto uživatele."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tomto úkolu nejsou žádné vlastní kontroly."])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte kliknutím na ikonu kalibrace v záhlaví zobrazení konverzace."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné konverzace"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit úkol"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit relaci kalibrace"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše úkoly"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další úkoly"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné aktivní úkoly"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné úkoly"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kontrolované subjekty"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kontrolované subjekty kromě mě"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné kontrolované subjekty."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte kontrolovaný subjekt"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraněno"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny stavy"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K provedení"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni kontroloři"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni kontroloři kromě mě"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přiřazeno mně"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli nalezeni žádní kontroloři."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte kontrolora"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " d"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " d"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " den"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " d"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " d"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " d"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hod"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hod"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hodina"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hod"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hod"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hod"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuta"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " min"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["–"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit přiřazení"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé pracovního prostoru"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální kontrolor"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový cíl pro tento cyklus"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový kontrolor"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte kontrolora"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte přiřazení konverzací"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte přiřazení konverzace"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace, které mají být přesunuty"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální cyklus"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit úkol"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli nalezeni žádní kontroloři."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte kontrolora"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit přiřazení konverzací pro mě"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit přiřazení konverzací pro jiného uživatele"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dní"]), _normalize([_interpolate(_named("n")), " den"]), _normalize([_interpolate(_named("n")), " d."])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " hodin"]), _normalize([_interpolate(_named("n")), " hodina"]), _normalize([_interpolate(_named("n")), " hod"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt dokončené cykly"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt dokončené relace"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš cíl"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte důvod, proč chcete tuto konverzaci odstranit."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysvětlete, proč tuto konverzaci odstraňujete"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit konverzaci"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete zachovat změny?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte důvod, aby bylo možné tuto konverzaci nahradit."])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodit změny"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachovat"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachovat změny"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhrada nenalezena"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vysvětlete, proč tuto konverzaci nahrazujete"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete přesto tuto konverzaci z úkolu odstranit? Tento krok sníží cíl o 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahradit"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrazení konverzace"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat koncept"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit koncept"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu přiřadit mně"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu přiřadit jinému uživateli"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu přiřadit a zkontrolovat"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit konverzaci"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provést kontrolu"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit méně"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit více"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cíl: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " zkontrolováno"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené kontroly"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit dokončené cykly"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit dokončené relace"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbývající čas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úkoly"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace nahrazena"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout seznam úkolů"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " později"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " s"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " s"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " d ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " měs."])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " r."])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právě teď"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybí vám oprávnění. Požádejte o pomoc administrátora."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka, kterou hledáte, neexistuje."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo k neočekávané chybě. Požádejte o pomoc podporu."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup byl odmítnut. Není dovoleno tuto akci provést."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka, kterou hledáte, buď neexistuje, nebo k ní nemáte přístup."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postupujete trochu moc rychle a my s vámi nedokážeme držet krok."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změny uloženy"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuložené změny"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " použito"]), _normalize([_interpolate(_named("n")), " použito"]), _normalize([_interpolate(_named("n")), " použito"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojeno"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořeno v"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořil/a"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritický"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotovo"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bývalý člen"]), _normalize(["Bývalý člen"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zde"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " vybráno"]), _normalize([_interpolate(_named("n")), " vybráno"]), _normalize(["Počet vybraných: ", _interpolate(_named("n"))])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naposledy aktualizováno"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["… a ještě 1 "]), _normalize(["… a ještě ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+ ještě 1 "]), _normalize(["+ ještě ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nový"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["není"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstraněno"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odpovědí"]), _normalize([_interpolate(_named("n")), " odpověď"]), _normalize(["Počet odpovědí: ", _interpolate(_named("n"))])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dnes"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uživatel"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pátek"]), _normalize(["Pátky"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pondělí"]), _normalize(["Pondělky"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sobota"]), _normalize(["Soboty"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Neděle"]), _normalize(["Neděle"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Čtvrtek"]), _normalize(["Čtvrtky"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Úterý"]), _normalize(["Úterky"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Středa"]), _normalize(["Středy"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["včera"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrátor"]), _normalize(["Administrátor"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Správce účtu"]), _normalize(["Správce účtu"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Uživatel"]), _normalize(["Uživatel"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoZK"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírováno"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost vaší relace skončila."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlášení"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole nemůže být prázdné."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné položky."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádní vyhovující lidé."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Majitel"]), _normalize(["Majitel"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Než začneme, přečtěte si naše podmínky, potvrďte, že s nimi souhlasíte, a můžeme začít."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesláno"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jste si jisti?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet provedených kontrol"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizováno"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agent"]), _normalize(["Agent"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Potenciální kontakt"]), _normalize(["Potenciální kontakt"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nadřízený"]), _normalize(["Nadřízený"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kontrolor"]), _normalize(["Kontrolor"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymní uživatel"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovat"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pověřený agent"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brzy"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknutím zkopírovat"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat odkaz"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebezpečná zóna"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnout"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis (nepovinný)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je vyžadováno potvrzení"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnout"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodit neuložené změny?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnout"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekvence"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cíl"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozumím"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická podpora"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISK"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikdy"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco jiného"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Míra úspěšnosti"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní nastavení"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předchozí"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méně informací"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat znovu"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolovaný subjekt"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté/zobrazené kontroly"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly provedené členem pracovního prostoru"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit změny"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binární stupnice"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3bodová stupnice"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4bodová stupnice"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5bodová stupnice"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledková listina"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte frekvenci"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit méně"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit více"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeskočit"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvýraznění"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpětná vazba k průzkumu"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Časový rozsah"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuložené změny"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizovat"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váha"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uživatel ", _interpolate(_list(0)), " vás pozval, abyste se připojili k Zendesku ZK."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uživatelé se automaticky připojují z Centra pro správu. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovat uživatele"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o správě uživatelů"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Někteří uživatelé budou automaticky zřízeni a synchronizováni ze strany vašeho poskytovatele identity. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Oprávnění účtu „", _interpolate(_list(0)), "“ se změnila."])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o oprávněních uživatele"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už se mě neptat"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Všechny změny provedené na stránce uživatelů se projeví v pracovních prostorech. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To znamená, že přístup uživatele k určitým částem Zendesku ZK se může změnit."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna oprávnění na úrovni účtu"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel odstraněn"]), _normalize(["Počet odstraněných uživatelů: ", _interpolate(_named("n"))])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Když uživatele odstraníte, ztratí přístup k vašemu účtu. Jeho kontroly, komentáře a hodnocení zůstanou v Zendesku ZK. ", _interpolate(_named("linebreak")), "Odstranění uživatele nebude mít vliv na počet míst ve vašem odběru."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tento uživatel bude odstraněn ze všech pracovních prostorů, které spravujete. ", _interpolate(_named("linebreak")), " Jejich kontroly, komentáře a hodnocení zůstanou beze změny."]), _normalize(["Budou odstraněni ze všech pracovních prostorů, které spravujete. ", _interpolate(_named("linebreak")), " Všechny jejich kontroly, komentáře a hodnocení zůstanou beze změny."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Odstranit uživatele ", _interpolate(_named("name"))]), _normalize(["Odstranit uživatele (celkem ", _interpolate(_named("name")), ")"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Může zobrazit vše a spravovat všechna nastavení a fakturaci."])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Může zobrazit vše a spravovat osobní nastavení a nastavení pracovního prostoru, kromě fakturace."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění účtu"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění specifická pro pracovní prostor umožňují uživateli zobrazit a upravovat data v pracovních prostorech, jichž jsou součástí."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o ZK robota"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaši roboti se zde zobrazí, jakmile se synchronizují konverzace, kterých se zúčastnili."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli synchronizováni žádní roboti"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když robota označíte jako uživatele, udělíte mu přístup k přihlášení. Jejich historická data robotů zůstanou k dispozici na ovládacích panelech."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli nalezeni žádní takoví roboti"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste hledat znovu pomocí jiného klíčového slova nebo pravopisu."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledejte roboty"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání podle názvu robota"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako uživatele"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboty mohou jako uživatele označovat pouze administrátoři a správci účtů"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno robota"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručně označeno"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnost kontroly"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot je odebrán z automatické analýzy a všech přiřazení. Nová data se nebudou zobrazovat na ovládacích panelech, což zajistí přesné vytváření výkazů a snadnější správu robotů."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generativní robot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámý"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot pracovního postupu"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do skupiny/y"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny skupiny"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná skupina"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit ze skupin/y"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte skupinu"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění k účtu"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do pracovních prostorů"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celé jméno"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naposledy aktivní"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role na úrovni účtu"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Každému uživateli je přiřazeno oprávnění na úrovni účtu a/nebo pracovního prostoru. Oprávnění administrátora jsou nyní na úrovni účtu, zatímco ostatní oprávnění jsou na úrovni pracovního prostoru."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit z pracovních prostorů"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění k pracovnímu prostoru"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění spravovaná ve skupině"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit podrobnosti"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označit jako robota"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když uživatele označíte jako robota, odebere se mu přístup k přihlášení. Jejich historická uživatelská data zůstanou k dispozici na ovládacích panelech."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeberte robota z automatické analýzy a všech přiřazení. Nová data se nebudou zobrazovat na ovládacích panelech, což zajistí přesné vytváření výkazů a snadnější správu robotů."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyloučit robota z kontrol"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel označený jako robot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako robota lze označit pouze typ oprávnění Uživatel"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé s více účty nemohou být označeni jako robot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat podle uživatele"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roboti"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé, roboti a pracovní prostory"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRACOVNÍ PROSTORY"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé a pracovní prostory"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrátor"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná oprávnění"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručně přidaný"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekající"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekající na pozvání"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutná akce"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["… a ještě 1"]), _normalize(["… a ještě ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změnit oprávnění na"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Chcete-li změnit roli ", _interpolate(_named("n")), " uživatele, který čeká na pozvání, odstraňte ho a pozvěte ho znovu."]), _normalize(["Chcete-li změnit roli ", _interpolate(_named("n")), " uživatelů, kteří čekají na pozvání, odstraňte je a pozvěte je znovu."])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Upravit podrobnosti pro uživatele ", _interpolate(_named("name"))]), _normalize(["Upravit podrobnosti pro vybrané uživatele (celkem ", _interpolate(_named("n")), ")"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel byl aktualizován."]), _normalize(["Počet uživatelů, kteří byli aktualizováni: ", _interpolate(_named("n")), "."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tato skupina obsahuje 1 uživatele z jiného pracovního prostoru."]), _normalize(["Tato skupina obsahuje několik uživatelů (celkem ", _interpolate(_named("n")), ") z jiného pracovního prostoru."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovat"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ byla archivována."])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovaná skupina"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit skupinu"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ byla vytvořena."])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina bez názvu"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete tuto skupinu trvale smazat?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat skupinu"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat skupinu"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit skupinu"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedoucí skupiny"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role skupiny"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ byla skryta."])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato skupina nebude viditelná nikde jinde v Zendesku ZK."])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovat pouze oprávnění pracovního prostoru"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina není viditelná v Zendesku ZK"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedoucí"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Člen"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina bez názvu"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento název již má jiná skupina."])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento název již má archivovaná skupina."])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše role umožňuje pouze správu členů této skupiny. Pokud chcete provádět další změny, kontaktujte uživatele s vyššími oprávněními."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit jako vedoucího skupiny"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ byla obnovena."])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ byla uložena."])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavit jako vedoucího skupiny"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Archivace skupiny „", _interpolate(_named("name")), "“ byla zrušena."])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuložené změny"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte stávající uživatele do této skupiny."])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávejte podle jména nebo e-mailu"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat členy"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skupina „", _interpolate(_named("name")), "“ je viditelná."])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Každý člen je ", _interpolate(_named("workspace_role")), "."])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o skupinách"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Některé atributy skupin budou automaticky zřízeny a synchronizovány ze strany vašeho poskytovatele identity."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další informace o skupinách"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit skupinu"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednoduše organizujte členy svého týmu a napodobte svou organizační strukturu."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidejte alespoň 1 člena."])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte název skupiny."])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupina s tímto názvem již existuje."])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové skupiny"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název skupiny"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizujte členy svého týmu a napodobte svou organizační strukturu."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Členové"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Žádné skupiny"]), _normalize(["1 skupina"]), _normalize(["Počet skupin: ", _interpolate(_named("count"))])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte novou skupinu, nebo aktivujte archivovanou skupinu."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou k dispozici žádné aktivní skupiny"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné archivované skupiny"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné takové skupiny."])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkuste hledat znovu pomocí jiného klíčového slova nebo pravopisu."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly přidány žádné skupiny"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["v těchto"]), _normalize(["v"]), _normalize(["v"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávejte podle názvu skupiny"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovány"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Možnost znovu odeslat ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zbývá ", _interpolate(_named("n")), " míst"]), _normalize(["Zbývá ", _interpolate(_named("n")), " místo"]), _normalize(["Počet zbývajících míst: ", _interpolate(_named("n"))])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet míst je momentálně omezený, protože váš odběr byl pozastaven. Znovu jej aktivujte, chcete-li přidat další uživatele."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste limitu počtu míst svého účtu. Uživatele můžete nahradit deaktivací aktivního uživatele nebo přidáním dalších míst."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit platební údaje"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Počet vybraných: ", _interpolate(_named("n"))]), _normalize(["Počet vybraných: ", _interpolate(_named("n"))])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel vybrán"]), _normalize(["Počet vybraných uživatelů: ", _interpolate(_named("n"))])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " řádků"]), _normalize([_interpolate(_named("n")), " řádek"]), _normalize(["Počet řádků: ", _interpolate(_named("n"))])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné vyhledávání uživatelů podle jejich jmen nebo e-mailových adres"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem vložte data oddělená čárkami nebo novými řádky"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné vyhledávání uživatelů"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Upravit oprávnění k pracovnímu prostoru pro uživatele ", _interpolate(_named("name"))]), _normalize(["Upravit oprávnění k pracovnímu prostoru pro vybrané členy (celkem ", _interpolate(_named("n")), ")"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění pro úpravy"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat oprávnění k pracovnímu prostoru"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Odebrat uživatele ", _interpolate(_named("name")), " z pracovního prostoru"]), _normalize(["Odstranit členy (celkem ", _interpolate(_named("name")), ") z pracovního prostoru"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " člen odstraněn"]), _normalize(["Počet odstraněných členů: ", _interpolate(_named("n"))])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Odstranit člena"]), _normalize(["Odstranit členy"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tento uživatel bude odstraněn z pracovního prostoru."]), _normalize(["Tito uživatelé budou odstraněni z pracovního prostoru."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Oprávnění k pracovnímu prostoru „", _interpolate(_list(0)), "“ se změnilo."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat členy"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení konverzací a souvisejících kontrol"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení všeho v pracovním prostoru, ale možnost spravovat pouze kvízy, úkoly, námitky a kalibrační relace"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnění k pracovnímu prostoru"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení a správa všeho v pracovním prostoru"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení všeho v pracovním prostoru kromě nastavení pracovního prostoru"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propojit uživatele"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohoto pracovního prostoru byli přidáni všichni uživatelé z pracovních prostorů, které spravujete."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádejte o to administrátora."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvěte nové uživatele přímo, nebo požádejte administrátora, aby to udělal za vás."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " nebo ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádní uživatelé k zobrazení"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " uživatel přidán"]), _normalize(["Počet přidaných uživatelů: ", _interpolate(_named("n"))])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Přidat členy"]), _normalize(["Přidat člena"]), _normalize(["Přidat členy (celkem ", _interpolate(_named("n")), ")"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat oprávnění k pracovnímu prostoru"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele pro pracovní prostor."])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Přidejte členy do ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem vložte e-maily oddělené novými řádky nebo čárkami."])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Spárováno: ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mailů"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" s již propojenými uživateli:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nelze spárovat ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mailů"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte pravopis, nebo je propojte později po jednom."])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" s uživateli technické podpory:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel propojen"]), _normalize(["Počet propojených uživatelů: ", _interpolate(_named("n"))])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel pozván"]), _normalize(["Počet pozvaných uživatelů: ", _interpolate(_named("n"))])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadně propojit"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadně pozvat"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Například:\n\nkatka", "@", "priklad.com, jan", "@", "priklad.com\n\nkatka", "@", "priklad.com\nkatka", "@", "priklad.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte je ze své technické podpory, aby bylo možné zkontrolovat jejich interakce."])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Propojit uživatele"]), _normalize(["Propojit uživatele"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maily"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojit po jednom"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud chcete, aby vaši propojení uživatelé měli přístup k Zendesku ZK, můžete je pozvat zde."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvěte propojené uživatele"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pozvat uživatele"]), _normalize(["Pozvat uživatele"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeskočit pozvání"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte uživatele, které chcete připojit ze své technické podpory, aby bylo možné zkontrolovat jejich interakce"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravujte svůj odběr"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojte uživatele technické podpory"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte jedinečný název svého pracovního prostoru."])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud je tato možnost povolena, všechna existující propojení účte budou automaticky přidána do nového pracovního prostoru."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat všechna propojení do tohoto pracovního prostoru"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit pracovní prostor"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název pracovního prostoru"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte pracovní prostor"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelům přidaným přímo do účtu (bez přiřazení pracovního prostoru) bude přiřazena role na úrovni účtu. Do pracovních prostorů je můžete přidat později."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvaným uživatelům je automaticky přiřazena role uživatele na úrovni účtu. Později je můžete upgradovat na roli administrátora."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše funguje"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem vložte e-maily oddělené novými řádky nebo čárkami."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uživatel pozván e-mailem"]), _normalize(["Počet uživatelů pozvaných e-mailem: ", _interpolate(_named("n"))])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelům přidaným do pracovního prostoru je automaticky přiřazena role uživatele na úrovni účtu. Později je můžete upgradovat na roli administrátora."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat další"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze uživatele pozvěte do účtu, aniž byste je museli přidávat do pracovního prostoru."])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvaní uživatelé mají přiřazeno pouze oprávnění k účtu a nejsou přidáni do žádných pracovních prostorů."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat uživatele přímo do pracovního prostoru a přiřadit jim oprávnění k pracovnímu prostoru"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat uživatele do pracovního prostoru"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadně pozvat"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat po jednom"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat pouze do účtu"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvat do pracovního prostoru"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pozvat uživatele"]), _normalize(["Pozvat uživatele"]), _normalize(["Pozvat několik uživatelů (celkem ", _interpolate(_named("n")), ")"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hromadné pozvání uživatelů e-mailem"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Smazat uživatele"]), _normalize(["Smazat uživatele"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit výběr"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozvání uživatelů e-mailem"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné pracovní prostory"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte oprávnění"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu odeslat pozvánku"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojený uživatel"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel čekající na pozvání e-mailem"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte pracovní prostor"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI agenti"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum správy"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajišťování kvality"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa personálu"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Dokončena ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Dokončena ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Dokončeno ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cyklus ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolor"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit úkol"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ušetřete čas a nastavte si automatické úkoly kontroly místo ručního vyhledávání a přidělování konverzací ke kontrole."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Termín ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace lze přidat v části „Kalibrace“ v postranní nabídce zobrazení konverzace."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly přidány žádné relace"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["výchozí bod"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné relace"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace lze přidat do relace kliknutím na ikonu kalibrace v záhlaví zobrazení konverzace."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly přidány žádné konverzace"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zatím nebyly provedeny žádné kontroly. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provést kontrolu kalibrace"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příčina"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paletu příkazů se nepodařilo načíst."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nic nebylo nalezeno."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nalezeno podle ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání palety příkazů…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stránky, filtry, konverzace, nastavení…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverzace"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromý filtr"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veřejný filtr"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Z ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z vašeho rozhraní API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Zobrazení ", _interpolate(_named("rangeStart")), " až ", _interpolate(_named("rangeEnd")), " z ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Zobrazení ", _interpolate(_named("rangeStart")), " až ", _interpolate(_named("rangeEnd")), " z ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrýt postranní panel"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit postranní panel"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout postranní panel"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté námitky"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnuté námitky"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené námitky"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně přijaté námitky"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené kontroly"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazené kontroly"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["přijaté"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otevřené"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["částečně přijaté"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " z celkového počtu"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zamítnuté"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně přijaté"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnuté"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kontrola"]), _normalize(["kontroly"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet jejich kontrol, které kontrolované subjekty viděly"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet jejich kontrol, které byly zobrazeny"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroloři s námitkou"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoři námitky"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky podle kategorie"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Námitky v průběhu času"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba vyřešení"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba vyřešení (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba vyřešení (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřešené"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet námitek"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrat hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk odpovědi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné jazyky"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny jazyky"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předpokládané motivace"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné motivace"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny motivace"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slova ve shlucích slov"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla nalezena žádná slova"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechna slova"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmínky"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejnovější"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejstarší"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevřené"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částečně přijaté"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zareagoval/a s ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamítnuté"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyřešené"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroly"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledat"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte tón pleti"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vše"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relace"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné relace"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné kategorie"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentáře"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost komentáře"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné velikosti"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny velikosti"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné filtry"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné skupiny"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny skupiny"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné hashtagy"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny hashtagy"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné hodnoty"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny hodnoty"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla nalezena žádná vlastní pole"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štítky technické podpory"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné štítky technické podpory"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny štítky technické podpory"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítání výsledků…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy otázek"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné typy otázek"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat reakci"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledkové listiny"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výsledkové listiny"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny výsledkové listiny"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyla nalezena žádná ohodnocení"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechna ohodnocení"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroje"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné zdroje"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny zdroje"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod zpětné vazby"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné důvody"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny důvody"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průzkumy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné průzkumy"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny průzkumy"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanály"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné kanály"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kanály"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatelé"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyli nalezeni žádní uživatelé"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všichni uživatelé"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné pracovní prostory"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny pracovní prostory"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na Kvízy"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz byl smazán nebo ještě nebyl publikován. Znovu zkontrolujte odkaz, nebo přejděte zpět na seznam kvízů."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento kvíz nebyl sdílen s pracovním prostorem, do kterého patříte. Požádejte administrátora, aby vás přidal do pracovního prostoru, ke kterému má tento kvíz přístup."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz není k dispozici"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup ke kvízu byl udělen pracovním prostorům, do kterých nepatříte. Kvíz nelze uložit a publikovat."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požádejte administrátora, aby kvíz uložil a publikoval."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alespoň jednu z odpovědí byste měli označit jako správnou."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole nemůže být prázdné."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právě se vám zobrazuje náhled kvízu."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte, které pracovní prostory mají přístup ke kvízu. Uživatelé, kteří patří do více pracovních prostorů, mohou na kvíz odpovědět pouze jednou. Výběrem možnosti „Všechny pracovní prostory“ nebudou po publikování kvízu zahrnuty nově vytvořené pracovní prostory."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup ke kvízu"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovat"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukončit režim náhledu"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhled"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otázky"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolvovat kvíz"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat kvíz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení kvízu"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit jako koncept"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nový kvíz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušte archivaci"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["možnosti"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat možnost"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdílet"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypadá dobře, že?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše odpověď byla odeslána."])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš kvíz byl uložen."])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš kvíz byl publikován."])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš kvíz byl odstraněn."])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat otázku"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovat kvíz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivovaný kvíz a jeho výsledky se již nebudou zobrazovat v seznamu kvízů. Stále můžete mít přístup k výsledkům archivovaného kvízu a v případě potřeby můžete jeho archivaci zrušit."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivujte kvíz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakmile je kvíz publikován, nelze jej měnit ani upravovat. Všechny publikované kvízy budou přístupné každému uživateli ve vašem účtu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikovat kvíz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete smazat tento kvíz? Tuto akci nelze vzít zpět."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kvíz?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po odeslání nelze vaše odpovědi změnit."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat kvíz?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit archivaci kvízu"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušením archivace kvízu jej zpřístupníte v přehledu kvízů. Umožní předchozím účastníkům přístup k jejich výsledkům a novým účastníkům umožní účast v kvízu."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušte archivaci kvízu"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat otázku"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis (nepovinný)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesunout otázku dolů"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesunout otázku nahoru"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otázka"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V náhledu se zobrazují pouze otázky s odpověďmi."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranit otázku"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat kvíz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz bez názvu"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název kvízu"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tímto krokem trvale smažete kvíz a jeho výsledky."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Případně můžete k výsledkům stále získat přístup archivací kvízu."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zodpovězen"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat kvíz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tímto krokem trvale smažete kvíz a jeho výsledky."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Smazání kvízu ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikovat"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy pomáhají zvyšovat sebevědomí a dovednosti týmu, poskytovat konzistentní zkušenosti a zaškolit nové členy týmu."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy můžete vyhledávat podle názvu kvízu nebo jména autora."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nebyly vytvořeny žádné kvízy."])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat seznam kvízů"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prům. ohodnocení"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum dokončení"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz/Autor"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz / Autor / Pracovní prostory"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účastníci"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikován"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovní prostory"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání podle kvízu, autora"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivován"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny kvízy"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz byl archivován."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivace kvízu byla zrušena."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento kvíz neobdržel žádné odpovědi od uživatelů ve vybraných pracovních prostorech."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento kvíz před archivací neobdržel žádné odpovědi."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné odpovědi"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde uvidíte rozpis odpovědí kvízu.\nVáš kvíz zatím nikdo nevyplnil – mohlo by pomoct jeho sdílení."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím žádné odpovědi"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpovědi:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrné ohodnocení:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuální"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Kopie"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz zkopírován"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výsledky"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kvízy uživatele ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V žebříčku můžete vyhledat jméno účastníka."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportovat žebříček"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohodnocení"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání podle účastníka"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žebříček"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízy"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout žebříček"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To znamená, že tento hashtag nebudete moci filtrovat na ovládacích panelech a komentáře se již nebudou automaticky vyplňovat. Tato akce je nevratná."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, …"])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit hashtagy"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření hashtagů"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření hashtagů"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolit členům vytvářet nové hashtagy"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používá se"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Smazání hashtagu ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření hashtagů je uzamčeno."])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytváření hashtagů je odemčeno."])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag „", _interpolate(_list(0)), "“ byl smazán."])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag „", _interpolate(_list(0)), "“ byl přejmenován na „", _interpolate(_list(1)), "“."])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag přidán"]), _normalize(["Počet přidaných hashtagů: ", _interpolate(_named("count"))])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový název"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly vytvořeny žádné hashtagy."])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenovat"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Přejmenování hashtagu „", _interpolate(_named("tagRenamed")), "“"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagy oddělujte čárkami nebo zalomením řádků."])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledejte hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bez obav. Můžete tak učinit ", _interpolate(_named("link_copy")), "."])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rovnou zde"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení propojeno s pracovním prostorem"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit propojení"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojit propojení k tomuto pracovnímu prostoru"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propojení"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obraťte se na administrátora a proveďte případné změny."])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktujte Zendesk ZK a proveďte případné změny."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná propojení v rámci účtu"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdá se, že jste ještě neprovedli integraci s platformou služeb zákazníkům."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazáním propojení odstraníte konverzace z pracovního prostoru ", _interpolate(_named("workspaceName")), " a smažete všechna data, která s nimi souvisejí, včetně kontrol a námitek."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat propojení"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Smazání propojení ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte propojení"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ve zvoleném časovém období nebyly na vaší výsledkové listině uvedeny určité aktivní kategorie AutoZK. Konverzace před poslední změnou dne ", _interpolate(_named("date")), " nemusí být pro tyto kategorie analyzovány."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se zobrazí veškerá zpětná vazba, kterou obdržíte. Zatím tu není nic k zobrazení."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné přijaté kontroly."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit pouze aktivitu u komentářů"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde se objeví veškerá zpětná vazba, kterou poskytnete. Začínáme v zobrazení Konverzace."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné provedené kontroly."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené kontroly"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté kontroly"])}
  }
}