import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_star_icon = _resolveComponent("star-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_reviewer_item = _resolveComponent("reviewer-item")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_data_grid_data = _resolveComponent("data-grid-data")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: 
      _ctx.visibility === _ctx.RequireReasonVisibility.All
        ? 'dashboard-reasons-for-category-card-all'
        : 'dashboard-reasons-for-category-card'
    ,
    title: 
      _ctx.visibility === _ctx.RequireReasonVisibility.All
        ? _ctx.$t('dashboard.card_labels.root_causes_all')
        : _ctx.$t('dashboard.card_labels.root_causes')
    ,
    class: "m-6",
    "timestep-toggle": "",
    params: _ctx.filterParams,
    "api-method": _ctx.getPredefinedCategoryReasons,
    "csv-api-method": _ctx.getPredefinedCategoryReasonsCsv,
    onDataupdate: _ctx.setVisibility
  }, {
    empty: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["text-xs", _ctx.$style.empty])
      }, [
        _createVNode(_component_star_icon, {
          class: _normalizeClass(_ctx.$style.icon)
        }, null, 8 /* PROPS */, ["class"]),
        _createElementVNode("p", {
          class: _normalizeClass(["text-sm", _ctx.$style.title])
        }, _toDisplayString(_ctx.$t('dashboard.root_causes.no_causes_title')), 3 /* TEXT, CLASS */),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.subtitle)
        }, _toDisplayString(_ctx.$t('dashboard.root_causes.no_causes_subtitle')), 3 /* TEXT, CLASS */),
        (_ctx.session.features.rootCauses)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: 'workspace.scorecard', params: { id: _ctx.filterParams.workspaceIds[0] } },
              class: "content-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('dashboard.root_causes.no_causes_link')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(({ data, timeStep, sortBy }) => [
      _createVNode(_component_data_grid, {
        "sort-by": sortBy,
        data: data
      }, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "cause",
                "identity-column": "",
                label: _ctx.$t('dashboard.reason', 2),
                width: "192px",
                align: "left"
              }, {
                default: _withCtx(({ value, row }) => [
                  (row.user)
                    ? (_openBlock(), _createBlock(_component_reviewer_item, {
                        key: 0,
                        reviewer: row.user
                      }, null, 8 /* PROPS */, ["reviewer"]))
                    : _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass([_ctx.$style.label, { [_ctx.$style.categoryInactive]: !row.active }]),
                        title: value
                      }, [
                        _createTextVNode(_toDisplayString(value), 1 /* TEXT */)
                      ], 10 /* CLASS, PROPS */, _hoisted_1)), [
                        [_directive_tippy, { content: value }]
                      ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_data_grid_data, {
            "format-label": _ctx.formatDataLabel(timeStep),
            width: 70
          }, {
            default: _withCtx(({ value }) => [
              _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["format-label"]),
          _createVNode(_component_data_grid_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "count",
                label: _ctx.$t('dashboard.total_given'),
                width: "140px"
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, {
                    bold: "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["sort-by", "data"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title", "params", "api-method", "csv-api-method", "onDataupdate"]))
}