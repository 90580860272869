import qs from 'query-string'
import type {
  GetTranscriptionsForTicketRequest,
  ChangeTranscriptionSpeakerRequest,
  Speaker as ServicesSpeaker,
  TranscriptionMessage as ServicesTranscriptionMessage,
  Transcription as ServicesTranscription,
} from '@zendesk/zqa-services/transcriptions'
import { api } from '@/api'
import type { KlausSentiment } from '@/types/sentiment'
import { getRequestHeaders } from './index'

export interface Speaker extends ServicesSpeaker {
  alias?: 'agent' | 'customer' | undefined
}
export interface TranscriptionMessage extends ServicesTranscriptionMessage {
  speaker: Speaker
  klausSentiment?: KlausSentiment | undefined
}
export interface Transcription extends ServicesTranscription {
  messages: TranscriptionMessage[]
}
export interface TranscriptionsList {
  transcriptions: Transcription[]
}

export function getTranscriptionsForTicket(
  { connectionId, ticketId, translationLanguage }: GetTranscriptionsForTicketRequest & { translationLanguage: string },
  signal: AbortSignal,
) {
  return api
    .get(`transcriptions/connection/${connectionId}/ticket/${ticketId}`, {
      headers: getRequestHeaders(),
      signal,
      searchParams: qs.stringify({ translationLanguage }),
    })
    .json<TranscriptionsList>()
}

export const updateTranscriptionSpeaker = ({
  transcriptionId,
  connectionId,
  aliasOriginal,
  internalUserId,
}: ChangeTranscriptionSpeakerRequest) =>
  api
    .post(`transcriptions/${transcriptionId}/speaker`, {
      json: { connectionId, aliasOriginal, internalUserId },
      headers: getRequestHeaders(),
    })
    .json<TranscriptionsList>()
