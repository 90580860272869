export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk alt etki alanınızı girin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk’te oturum açın"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin mevcut bir hesabı var."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir şirkete katılın"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut bir şirkete katılmak için yöneticinizden size bir davet göndermesini isteyin."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöneticinizden sizi davet etmesini isteyin"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikrimi değiştirdim."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı iptal et"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin mevcut bir hesabı var. Şirketinizin hesabına katılmak mı, yoksa yeni bir tane oluşturmak mı istiyorsunuz?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talimatları kopyala"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir hesap oluştur"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut bir hesap var"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenmemiş abonelik faturalarınız ödenene kadar Zendesk kalite güvencesi hesabınıza erişemezsiniz."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliğiniz askıda"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap bulunamadı. Yeniden deneyin."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir parola belirle."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yeniden deneyin veya ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış e-posta ve parola kombinasyonu."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöneticinizden yeni bir tane talep edin."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu davet bağlantısı geçersiz veya zaten kullanılmış."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " oturum açma yöntemi hesabınızda devre dışı."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu davet geçersiz veya kullanılıyor. Yöneticinizden yeni bir tane talep edin."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet bilgileri yüklenemedi."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt işlemi yapılamadı. Bu e-posta adresine sahip bir kullanıcı zaten olabilir."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız arka arkaya birden fazla oturum açma denemesinden sonra engellendi. Engelinin nasıl kaldırılacağına ilişkin talimatların bulunduğu e-posta için gelen kutunuzu kontrol edin."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önce e-posta adresinizi doğrulayın"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir e-posta hesabı girin"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma kodu"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantıyı gönder"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt ol"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş e-posta adresi"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş e-postanız"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Hizmetler Sözleşmesi"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola gereksinimlerle eşleşmiyor"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Bildirimi"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleyin"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Merhaba, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("organization")), " organizasyon birimine katılın"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresiniz doğrulandı. Artık yeni hesabınızla aşağıda oturum açabilirsiniz:"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ile devam et"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet bağlantısı geçersiz"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı kullanarak oturum aç"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açılışına dön"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack ile devam et"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ile devam et"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresiniz doğrulandı. Artık yeni hesabınızla aşağıda oturum açabilirsiniz:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ile oturum aç"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantıyla oturum açın"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack ile oturum aç"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA’da oturum açın"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız silindi"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız yok mu?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir kod girin"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir e-posta hesabı girin"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma kodu"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm oturum açma seçeneklerini görün"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geleneksel yöntemlerle ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı kullanarak oturum aç"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma kodunuzu içeren bir e-posta aldınız."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açmak için takip edebileceğiniz bir e-posta aldınız."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı gönderilirken bir hata oluştu. Yeniden deneyin."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ile oturum aç"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola oluştur"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir küçük harf"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir büyük harf"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni parola şunları içermelidir:"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum parola uzunluğu 128 karakterdir"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatırlaması kolay, tahmin etmesi zor"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 8 karakter"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı oluşturun"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı mı unuttunuz?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir küçük harf karakter"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir numara"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir büyük harf karakter"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı sıfırlamak için, size talimatları göndereceğimiz e-posta adresinizi girin."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli bir e-posta hesabı girin"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan gereklidir"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı sıfırla"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " adresine bir e-posta gönderildi"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğer e-posta gelen kutunuzda veya istenmeyen e-posta klasörünüzde görünmüyorsa, lütfen girdiğiniz e-postayı kontrol edin."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açılışına dön"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postanızı kontrol edin"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı sıfırla"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", size bir parola sıfırlama e-postası gönderilecektir."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızda mevcut bir parola varsa"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eğer öyle ise", _interpolate(_named("bold")), ", size bir parola sıfırlama bağlantısı gönderdik."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-posta bir hesapla ilişkili"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı hatırladınız mı?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırlama bağlantısı gönderilirken bir hata oluştu. Yeniden deneyin."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı sıfırlayın"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı kullanarak kaydolun"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Gizlilik Politikası)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kaydolarak, Zendesk QA ", _interpolate(_named("terms_of_service")), " ve ", _interpolate(_named("privacy_policy")), " koşullarını kabul etmiş sayılırsınız"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet Koşulları"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız var mı?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantıyla kaydolun"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş e-postanız ile kaydolun"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack ile kaydolun"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA'ya kaydolun"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ile kaydolun"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin bir Zendesk hesabım var"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kayıt olun veya ", _interpolate(_named("direct_install_name")), " kurulumunuzu tamamlamak için oturum açın"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerçekten siz misiniz? Onay e-postası için gelen kutunuzu kontrol edin."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazırsınız"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA en iyi masaüstünde çalışır. Görüşmeleri değerlendirmeye başlamak için orada oturum açın."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım rezervasyonu yap"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veya bir demo rezervasyonu yapın, size Zendesk QA hizmetiyle neler yapabileceğinizi gösterelim."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır bulunmanız talep ediliyor"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iptal"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fikrimi değiştirdim ve ", _interpolate(_named("cancel")), " istiyorum"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " sizi hesabına katılmaya davet ediyor."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba katılın"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir hesap oluştur"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden oturum açmayı deneyin"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geri bildiriminiz için aldığınız yanıt: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan tepkiler"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " yorumunuza yanıt verdi"]), _normalize(["Birkaç kişi yorumunuza karşılık verdi"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " yanıtınıza yanıt verdi"]), _normalize(["Birkaç kişi yanıtınıza karşılık verdi"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " değerlendirmenize karşılık verdi"]), _normalize(["Birkaç kişi değerlendirmenize karşılık verdi"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yorum"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yanıt"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revizyon"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İHTİLAFLAR"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERİLDİ"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALINDI"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimi"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tepkiler"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatıldı"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yorum okundu olarak işaretlensin mi?"]), _normalize(["Tüm yorumlar okundu olarak işaretlensin mi?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["İhtilaf okundu olarak işaretlensin mi?"]), _normalize(["Tüm ihtilaflar okundu olarak işaretlensin mi?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Anket geri bildirimi okundu olarak işaretlensin mi?"]), _normalize(["Tüm anket geri bildirimleri okundu olarak işaretlensin mi?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değerlendirme okundu olarak işaretlensin mi?"]), _normalize(["Tüm değerlendirmeler okundu olarak işaretlensin mi?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yorum okundu olarak işaretlendi"]), _normalize(["Tüm yorumları okundu olarak işaretledi"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["İhtilaf okundu olarak işaretlendi"]), _normalize(["Tüm ihtilaflar okundu olarak işaretlendi"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Anket geri bildirimi okundu olarak işaretlendi"]), _normalize(["Anket geri bildirimlerinin tümü okundu olarak işaretlendi"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değerlendirme okundu olarak işaretlendi"]), _normalize(["Tüm değerlendirmeler okundu olarak işaretlendi"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü okundu olarak işaretle"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okundu olarak işaretle"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirmeler"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirmeye itiraz edildiğinde, burada görünür."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf yok"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir ihtilafı çözmekle görevlendirildiğiniz zaman, burada görünür."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan ihtilaf yok"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirmeye itiraz ettiğinizde burada görünür."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatılmış ihtilaf yok"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size gönderilen tüm geri bildirimler burada görünür. Henüz gösterilecek bir şey yok."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan geri bildirim yok"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmenize ve yanıtlarınıza verilen tüm tepkiler burada görünür. Henüz gösterilecek bir şey yok."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan tepki yok"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir görüşme aç"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdiğiniz tüm geri bildirimler buraya gelir. Görüşmeler görünümünde bir değerlendirme bırakarak başlayın."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen değerlendirme yok"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size gönderilen tüm geri bildirimler burada görünür. Henüz gösterilecek bir şey yok."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirme yok"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum bulunamadı"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum olarak gönderdiğiniz tüm geri bildirimler burada görünür."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirtilen yorum yok"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum bulunamadı"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahibi olduğunuz bir değerlendirme altındaki tüm yorumlar, ", "@", " bahsetmeler veya yorumlarınıza verilen yanıtlar burada görünür."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan yorum yok"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul edilen ihtilaf yok"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık ihtilaf yok"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedilen ihtilaf yok"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çözülmüş ihtilaf yok"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki filtreden başka bir seçenek belirleyerek yeniden deneyin."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki filtreden başka bir kullanıcı seçerek yeniden deneyin."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen yorumlar"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan yorumlar"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm ihtilaflar"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan ihtilaflar"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatılan ihtilaflar"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimi alındı"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen değerlendirmeler"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite listesini aç/kapat"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " değerlendirildi"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " değerlendirildi"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " değerlendirildi"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " içinde başlat"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kaldı"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir atama ayarlayın"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama listesini aç/kapat"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve", _interpolate(_list(1)), " arasında bir koçluk oturumu oluşturulamaz, çünkü şu anda aynı çalışma alanında değiller."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve ", _interpolate(_list(1)), " arasında koçluk oturumları zaten var. Oturuma erişmek için ", _interpolate(_list(0)), " ile iletişime geçin."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önerilen"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve ", _interpolate(_list(1)), " arasında yeni bir oturum oluşturuldu."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koç"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koç seçin"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti alan"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti alan seçin"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum oluştur"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir oturum oluşturun"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgiler"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum ekle"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum adı"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önerilen"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecikmiş"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakında"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum oluştur"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum bulunamadı"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama değerlendirme süresi"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pano"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen dönem için gösterilecek veri yok"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oturuma pin eklendi. ", _interpolate(_named("buttonStart")), "Bana göster", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buna ekle:"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eklendi"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili döneme ait pin sonuna ulaştınız"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Buradan ", _interpolate(_named("link")), " pinlerin nasıl çalıştığını görün."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["makale"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " altına hiçbir şey yerleştirmediğiniz görülüyor. Pinleri 1'e 1'lere hazırlanmak veya bir şeyi başka bir zamana kaydetmek için kullanabilirsiniz."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koçluk hizmeti alan"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz pin eklenmedi"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " altında oluşturduğunuz tüm pinler. Oturumlara eklenen pinler diğer katılımcı ile birlikte ", _interpolate(_named("bold")), " olacaktır."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hemen paylaşıldı"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", seçilen dönem boyunca görüşme değerlendirmesi veya müşteri memnuniyeti skoru için herhangi bir geri bildirim almamış görünüyor."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim yok"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili dönem içinde yorumun sonuna geldiniz"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Bildirim"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçümler"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinler"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " numaraları"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni eylem öğesi"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("user")), " tarafından ", _interpolate(_named("date")), " tarihinde tamamlandı"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tarafından"])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siz"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir eylem öğesi eklemek için yazın"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu toplantıdan ne çıktı? Sonraki adımlarınız neler?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem öğeleri"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), " tarihinde ", _interpolate(_named("name")), " tarafından oluşturuldu"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oturumu sil"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu kalıcı olarak ", _interpolate(_named("deleteSession")), ". Katılımcılar, içeriği ve özel notlarla birlikte oturuma erişimi kaybeder."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oturum ", _interpolate(_named("date")), " tarihinde oluşturuldu"])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Son düzenleme ", _interpolate(_named("name")), " tarafından ", _interpolate(_named("updatedDate")), " tarihinde gerçekleştirildi"])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konuşulan konular ve notlar"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not güncellendi"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumdan çık"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen kullanıcılar bu noktadan sonra oturum ayrıntılarına erişir."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı seç"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaşılan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu paylaş"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Artık bunun bir parçası olmayacaksınız: ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " oturum."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " artık bu oturumun bir parçası olmayacak."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " adlı kişinin artık bu oturuma erişimi var."]), _normalize([_interpolate(_named("n")), " yeni kullanıcı artık bu oturuma erişebiliyor"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu sil"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm oturumları sil"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu sil"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koç"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tarafından eklenen notlar burada görünür."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalıcı olarak yerleştirilen öğeleri, sağ taraftaki panelden tartışma noktaları olarak ekleyin"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinler"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notları yalnızca siz görebilirsiniz..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel notlar"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel not güncellendi"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 gün"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koç"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti alan"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem öğeleri"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son oturum"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam ediyor"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecikmiş"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlara genel bakış"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü görüntüle"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben: Koç"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben: Koçluk hizmeti alan"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm oturumlar"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaşılan oturumlar"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem öğelerini görmek için bir filtreyi değiştirmeyi deneyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem öğesi yok"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem öğeleri"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan ve görülen değerlendirmeler"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan · Görüldü"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlardan sonra ilerleme"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilciler herhangi bir geri bildirim almamış gibi görünüyor."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı adının doğru olduğunu doğrulayın"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcı bulunamadı"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri yok"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 30 gün IQS"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu kalıcı olarak <b></b><b>", _interpolate(_named("name")), " ile birlikte tüm oturumları</b> (2) siler. Katılımcılar, içerikler ve özel notlar dahil olmak üzere tüm oturumlara erişimi kaybeder."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm oturumların sonuna geldiniz"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk oturumu, bir mentör ve koçluk alan arasında performans, fırsatlar, kısa ve uzun vadeli hedeflerin tartışıldığı bir grup etkinliğidir."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seansları hem koçun adına hem de koçluk yapılan kişiye göre arayabilirsiniz."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum bulunamadı"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz oturum oluşturulmadı"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk geçmişini sil"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk geçmişini sil "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son oturum"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm oturumlar"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koç"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti alan"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı olarak işaretle"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanmadı olarak işaretle"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada göre ara"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden adlandır"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum listesini aç/kapat"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamanlanmamış"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu mesaja yanıt verin"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süresi dolmuş kalibrasyon oturumu. Ek değerlendirme bırakılamaz."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme için zaten bir değerlendirme bıraktınız."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumun bitiş tarihi geçmiş olduğu için bu görüşmenin değerlendirilmesi artık mümkün değil."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmede hiç değerlendirme yok"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneli kapat"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kalibrasyon değerlendirmesi bırakın"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AYRINTILAR"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖZET"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YAZILI DÖKÜM"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hoparlör ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " saniye"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hareketsiz"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konuşmacıyı düzenle"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajı değerlendir"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalınan mesaja dön"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konuşmacılar"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazılı döküm"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı kullanıcıları"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre oluşturmak için önce bağlantı ekleyin"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot yok"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " saatinde ", _interpolate(_named("agentName")), " tarafından oluşturuldu"])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Son güncelleme ", _interpolate(_named("time")), "saatinde ", _interpolate(_named("agentName")), " tarafından yapıldı"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiç filtreniz yok. Bu sayfaya erişmek için önce bir filtre oluşturmanız gerekir. Kendi görüşmelerinizi görmek için ", _interpolate(_named("recommendedFilter")), " ile başlayın. Devam etmek için aşağıdan bu filtreyi oluşturun."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son 30 gün içinde"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih aralığı olmadan oluşturulan filtrelere \"Son 30 günde oluşturuldu\" koşulu eklenir"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre bu planda kullanılamaz ve sonuçlar görüntülenirken dikkate alınmayacaktır"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alma filtresi seçenekleri zaman aşımına uğradı. Yeniden deneyin."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleme hakkında bilgi edinin"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan = oturum açan kişi"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bulunan görüşmeler: ", _interpolate(_named("count"))]), _normalize(["Bulunan görüşme: ", _interpolate(_named("count"))]), _normalize(["Bulunan görüşmeler: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut planınızda kullanılamaz. Bu özelliği kullanmak için yükseltin."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul ekle"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA Yapay Zekası tarafından"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamaya dönüştür"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre oluştur"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre oluştur"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel alanlar"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullar"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre seçeneklerini özel kurulumunuza göre yüklüyoruz. İlk yüklemede bu işlem birkaç dakika sürebilir."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrenize açıklayıcı bir ad verin"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre adı"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir seçim yapın"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreyi düzenle"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri kaydet"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer yok"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulunamadı"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değer seçin"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünürlük"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca etkin oturumlar ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha eski oturumları göster ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon oturumunu düzenle"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum oluştur"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon oturumu oluştur"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum sırasında yapılan tüm kalibrasyon değerlendirmeleri kalıcı olarak silinir."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu sil"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " kalibrasyon oturumunu sil"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç oturum oluşturulmadı"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin oturum yok"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum oluşturulmadı"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin oturum yok"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre ekle"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel bir filtre oluşturun"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık bir filtre oluşturun"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre eklendi"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre kalıcı olarak silinecek."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreyi sil"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " filtresini sil"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm ihtilaflar"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benim için ihtilaflar"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafımdan ihtilaflar"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık ihtilaflar"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslaklar"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre eklenmedi"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel filtre oluşturulmadı"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık filtre oluşturulmadı"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreler"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel filtreler"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık filtreler"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cta")), " ister misiniz?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaydet"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu geçici bir filtrenin görünümüdür."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreniz oluşturuluyor..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre güncellendi"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörüler"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Otomatik Kalite Kontrolü kategorisi"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Son güncelleme ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karmaşık kelimeler"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik değerlendirmeler, kayıt değişikliklerine göre dinamik olarak güncellenir"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede vurgula"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzun cümleler"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik puan öngörülmedi"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir hata saptanamadı"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karmaşık kelimeleri göster"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzun cümleleri göster"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki görüşme"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA, yetersiz bağlam nedeniyle bu kategoriyi değerlendiremedi. Manuel inceleme tavsiye edilir."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme sürecinizi hızlandırmak için otomatikleştirilmiş görüşme içgörülerini keşfedin. Önemli görüşmeleri vurgulama ve ekibiniz için göze çarpan olumlu örnekler sunma."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Ara]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzeltildi"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ihtilafı kabul etti"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["İhtilaflar, ", _interpolate(_named("agentName")), " adlı temsilciye gönderildi"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirilenin bu geri bildirim için neden yanlış alıcı olduğunu düşündüğünüzü açıklayın"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum ekleyin ve yeni bir değerlendirilen belirleyin"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir yorum ekle"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu puanların neden adil olmayan bir şekilde değerlendirildiğini düşündüğünüzü açıklayın (zorunlu)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum ekleyin (zorunlu)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ihtilafı neden reddettiğinizi açıklayın"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir yorum ekle"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı şuraya gönder:"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı şuraya gönder:"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı şuraya gönder:"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendirilen seçin"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf geri bildirimi"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf geri bildirimi"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirileni değiştir"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı şuraya gönder:"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirmeye itiraz et"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanlara katılmıyorum"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme benim için değildi"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kullanıcı seçin"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ihtilafı kısmen kabul etti"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ihtilafı reddetti"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul edildi"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısmen kabul edildi"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedildi"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " bir ihtilaf başlattı: değerlendirme"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " bir ihtilaf başlattı: yanlış değerlendirilen"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmede başka eylemlerin yapılabilmesi için açık bir ihtilafın çözülmesi gerekir."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul et"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni puanlar seçin"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı sil"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ihtilafı kaldırmak istediğinizden emin misiniz?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı sil"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihtilaf"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf çözüm süresi"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddet"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni adil puanları seçin (isteğe bağlı)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf değerlendirmesi"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış değerlendirilen"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı kabul et"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı kabul et"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf geri bildirimi"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış değerlendirilen"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf geri bildirimi"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı reddet"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı reddet"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilafı görüntüle"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenlendi"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " tarafından, ", _interpolate(_named("date")), " tarihinde ", _interpolate(_named("action"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kime: ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirildi"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm işlemler tamam"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buna atandı:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz de bir yorum yazın"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullu puan kartları hakkında bilgi edinin"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden atanan:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece bir tane seçin"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu adı taşıyan GIF bulunamadı"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk yorum yazan siz olun."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliğiniz şu anda duraklatılıyor. Geri bildirim vermeyi etkinleştirmek için özelliği yeniden etkinleştirin."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmenin değerlendirilmesi için ne kadar zaman harcandığını gösterir"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kullanıcı seçin"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Şu kategori için temel neden eksik: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kategoriyi değerlendirin veya bir yorum ekleyin"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji ekle"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daralt"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum alanını genişlet"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum alanını daralt"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biçimlendirmeyi gizle"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneli gizle"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biçimlendirmeyi göster"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paneli göster"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme gönder"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kategorileri değerlendirin"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir kategoriyi değerlendirin"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi sonrası için kaydet"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonrası için kaydet"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki atama"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi bu atama döngüsünde değiştir"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri değerlendir"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut durum değerlendirmesi"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumu sil"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum silindi"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu yorumu silinsin mi?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yazarı"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hashtag silinmiş veya yeniden adlandırılmış."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beğendiğiniz veya daha iyi olabilecek bir öğeyi paylaşın..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullu"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu adım, tüm yorumları kalıcı olarak silecek."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumu sil"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Mesaj]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bu geri bildirimi henüz görmedi"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bu geri bildirimi gördü"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski üye"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF'leri ara..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir hashtag oluşturmak için Enter tuşuna basın."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel hashtag'ler yönetici tarafından kilitlenir."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakalara yer işareti koymak ve daha sonra filtrelemek için bunu kullanın"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel olarak işaretle"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj geri bildirimi"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki görüşme"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim yok"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme yapılmadı"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlamak için aşağıya bir yorum yazın."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka yorum kalmadı"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme yapılmadı"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki atanan"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki görüşme"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel olarak kaldır"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtla"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirmeye yanıt verin"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme yan çubuğunu gizle"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, değerlendirmeyi ve eklenen tüm yorumları kalıcı olarak silecek."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeyi sil"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeyi sil"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme silindi"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yorum ", _interpolate(_list(0)), " tarafından silindi"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm işlemler tamam!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme bırakmak için bu görevi kendinize yeniden atayın"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcı için zaten bir değerlendirme bıraktınız."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " için bir değerlendirme bıraktınız"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme için gösterilecek kategori yok."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirme bu çalışma alanı için kullanılamaz."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydetme"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme taslak olarak kaydedilsin mi?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu taslağı kaldır"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme yan çubuğunu göster"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reşit olmayanlar ile pas"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hala sayılarla uğraşıyorum..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yazın"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu mesajı değerlendirin"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendinizi gözden geçirin"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi değerlendirin"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazar"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilecek kullanıcı yok"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirme devre dışı bırakıldı"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bulunamadı"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcılar"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı kullanıcıları"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı seç"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 değerlendirme"]), _normalize([_interpolate(_named("count")), " değerlendirme"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendirmeler seçin"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı yöneticiniz kendi kendine değerlendirmelere izin vermiyor."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu pini etiketlemek için Enter'a basın"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyonlar"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 30 gün"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıldız işaretli"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kalibrasyon oturumu oluşturun"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslaklar"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörüler"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörüler yardım masası verilerinizin görsel bir sunumunu sağlar. Kalite güvencesi çalışmalarınızı nerelere odaklayacağınızı anlamak, kör noktaları ortaya çıkarmak ve performanslarına göre inceleyecek doğru temsilcileri bulmak için etkileşimli çizelgeleri kullanın."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("language")), " diline tercüme et"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buna ekle:"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanmamış"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda görüntülenen:"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda değerlendirilenler"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme kaldırıldı ve artık kullanılamıyor."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız bu görüşme, Zendesk QA'da mevcut değil."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmenin bulunduğu tüm pinler"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumda"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi kaldır"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir oturumdan bir görüşme kaldırıldığında, bu görüşmeye yapılan tüm değerlendirmeler silinir."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu oturumdan görüşmeyi kaldır: ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz kalibrasyon oturumu oluşturulmadı."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme şu oturuma eklendi"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeden kaldırıldı"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kalibrasyon oturumuna ekle/kaldır"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmenin bağlantısını kopyala"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek görüşme yok"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin e-posta adresi"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin kimliği"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin adı"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harici form adı"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk yanıt verme süresi"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öncelik"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket yorumu"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memnuniyet puanı"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk çözüme kadar geçen süre"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orijinal"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu mesaj bu görüşme altında mevcut değil veya silindi"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu yok"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek görüşme yok"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme bulunamadı"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orijinal görüşmeyi aç"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye bağlantı kurulamıyor - URL eksik"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcılar"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcı yok"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi tuttur"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumları okumak için tıklayın"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmek için tıklayın"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme, tarayıcı uzantısıyla oluşturuldu"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti anketini görüntüleyin"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi görün"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif duygulu mesaj"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumlu duygulu mesaj"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizceye çevir"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orijinali göster"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi sil"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görüşme silme kalıcı bir işlemdir. Zendesk QA'dan anket, yazılı döküm, inceleme gibi tüm ilgili verileri de (panolar dahil) siler.", _interpolate(_named("lineBreak")), "Zendesk Support veya kullandığınız başka bir yardım masası yazılımından bu görüşmeye erişmeye devam edebileceksiniz."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görüşme silme kalıcı bir işlemdir. Zendesk QA' dan anket, yazılı döküm, inceleme gibi tüm ilgili verileri de (panolar dahil) siler. Görüşme birden fazla çalışma alanına aitse, tüm çalışma alanlarından silinir.", _interpolate(_named("lineBreak")), "Zendesk Support veya kullandığınız başka bir yardım masası yazılımından bu görüşmeye erişmeye devam edebileceksiniz."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi sil"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi Zendesk QA'dan sil"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu görüşme, yönlendirme nedeniyle tamamlanmadı. ", _interpolate(_named("optionsUrl")), " sayfasına gidin ve etiketlerinizi doğrulayın. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu görüşme, yönlendirme nedeniyle tamamlanmadı. Yöneticinizle etiketlerinizi doğrulama hakkında konuşun. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar > Gelişmiş seçenekler"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler hakkında bilgi edinin"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapandı"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemede"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çözüldü"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atanan ", _interpolate(_named("target")), " olarak değişti"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanmamış"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " tarafından ", _interpolate(_named("target")), " makro uygulandı"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görüşme değerlendirme aldı ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kullanıcı ", _interpolate(_named("target")), " görüşmeyi düzenledi"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Durum ", _interpolate(_named("target")), " olarak değişti"])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " ile etiketlendi"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ekip ", _interpolate(_named("target")), " olarak değişti"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " görüşmeyi ", _interpolate(_named("target")), " için erteledi"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " görüşmenin ertelemesini kaldırdı"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bir döngüde takılıp kaldı ve bir mesajı arka arkaya yineliyor"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot yinelemesi"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aynı mesajın arka arkaya bir veya daha fazla kez yinelendiği bot yineleme"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen ifadeler bulundu"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ortalama bir temsilci, görüşmeyi bottan ", _interpolate(_named("percent")), " daha etkili yürütür "])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot iletişim verimliliği"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot görüşmeyi ortalama bir temsilciden ", _interpolate(_named("percent")), " daha verimli bir şekilde yürüttü"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot iletişim verimliliği"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede, uyumluluk ve şeffaflık için gerekli olan kayıt hakkında katılımcıları bilgilendiren gerekli bildirim eksik"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt açıklaması eksik"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " için bir hizmet düzeyi sözleşmesi (HDS) ihlal edildi."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HDS ihlali"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HDS kuralı için ölçüm hedef süresini aştı"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri kaybı belirtileri. Müşteri bir geçiş yapmayı düşünüyor veya yolları ayırma sözü veriyor."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıp riski belirlendi"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Görüşmede ayarlanan ", _interpolate(_list(0)), " saniye eşikten daha uzun süren durağan dönemler var"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 örnek"]), _normalize([_interpolate(_named("count")), " örnek"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " en uzun"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsizlik"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri daha üst düzey yardım talep etti"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme talep edildi"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri alınan destekten çok memnun olduğunu veya çok memnun olduğunu belirtti"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olağanüstü hizmet"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri veya temsilci açık bir şekilde takip kaydı talep etti"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip talebi"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnun değil"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif duygu"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme olağandışı veya tipik değildi ve çözüme ulaşılması için daha fazla ileri geri görüşme yapıldı"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uç değer algılandı"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnun oldu"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitif duygu"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygun değil olarak ayarla"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Son düzenleme ", _interpolate(_named("author")), " tarafından ", _interpolate(_named("date")), " tarihinde gerçekleştirildi"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yok"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " tarihinde ", _interpolate(_named("author")), " tarafından"])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden ekleyin (isteğe bağlı)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörü algılanmadı"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı ekle"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerin listesini görmek için yardım masanızı hesabınıza bağlayın ve en az bir filtrenin oluşturulmasını sağlayın."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek görüşme yok"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı ayarlarını görüntüle"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son güncelleme"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarla"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(bir gün içinde)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Bu ay)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Bu hafta)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimliğe göre bul"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("searchQuery")), " için arama sonuçları"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başkaları tarafından değerlendirildi"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yapıldı"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En yeni"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En eski"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastgele"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son güncellenen"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakın zamanda yanıtlanmış"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralama ölçütü"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okunmadı"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafınızdan değerlendirildi"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçe aktarma işlemi biraz zaman alabilir. İşlem tamamlanınca size e-postayla göndereceğiz."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA görüşmelerinizi içe aktarıyor"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sınır: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme görünümünün başlık kısmındaki kalibrasyon simgesine tıklayarak ekleyin."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada rapor edilecek bir şey yok. Farklı bir filtre veya sıralama seçeneği kullanmayı yeniden deneyin."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek uç değer yok."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili bir görüşmeyi bulun ve başlıktaki yıldız simgesine tıklayarak buraya yer işareti koyun."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri değiştirmeyi deneyin veya diğer yardım masası temsilcilerini daha fazla görüşme eklemeye davet edin."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınırsız"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili not"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri şu anda yüklüyoruz. Bazı içerikler hâlâ eksik olabilir."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki kullanıcı"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bulunamadı"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı üyeleri"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki kullanıcı"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme eklenmedi"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç görüşme eklenmedi"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanmamış değerlendirmeler burada sona erer. Bu belgeyi 30 gün içinde yeniden okuyup gözden geçirin ve gönderin."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak değerlendirme yok"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıldız işaretli görüşme yok"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek görüşme yok"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşme yok"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen mesaja dön"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az yanıt verenler"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En çok yanıt verenler"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme listesini aç/kapat"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kullanıcılar"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arama yanıtlanma zamanı: ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arama sona erme zamanı: ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arama gerçekleşme zamanı: ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirildi"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafınızdan değerlendirildi"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ses dosyası yüklenemedi. ", _interpolate(_list(0)), " hesabında oturum açtığınızdan emin olun."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti anketi yorumu"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ses yüklenirken hata oluştu"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yazarı"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama yapılmadı"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masasında dinle"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aircall'da dinle"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oynatma hızı"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramayı değerlendir"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazılı döküm"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksek"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA Kullanıcısı değil"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA, temsilcileri aylık ortalama CSAT'larına (müşteri memnuniyeti) göre otomatik olarak sıralar. Müşteri memnuniyeti emsallerinden daha düşük olan temsilcilerin gerçekleştirdiği görüşmeleri tek tıklamayla bulun."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyetine göre sıralama"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksek"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En düşük"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En yüksek"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA Kullanıcısı değil"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kayıt"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA, temsilcileri bir ayda yönettikleri görüşme sayısına göre otomatik olarak performans gruplarına ayırır. Temsilcilere göre görüşme hacmini emsallerine göre daha düşük olan görüşmeleri tek tıklamayla bulun."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacme göre sıralama"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirildi"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilmedi"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme durumu"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksek"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafsız"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA Kullanıcısı değil"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA, temsilcileri görüşmelerdeki ortalama duyguya göre otomatik olarak sıralar. Temsilcilerin tek tıklamayla emsallerinden daha fazla hayal kırıklığı yaşadığı etkileşimleri bulun."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duyguya göre sıralama"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algılanmadı"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algılandı"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınır Değerler"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgilenmeniz gereken görüşmeler. Makine öğrenimi modelimiz karmaşık veya tipik olmayan etkileşimleri daha ayrıntılı değerlendirmek üzere belirler."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınır Değerler"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumsuz"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumlu"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin memnuniyet veya memnuniyetsizlik gösterdiği yerlerde değerlendirmek üzere görüşmeleri seçin. Görüşmelerinizde aradığınız duygu türünü seçmek için açılır menüyü kullanın. Tüm etkileşimlerinizde seçilen duygunun oranını görmek için filtre niteliğini üste sürükleyin."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme durumu"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilmedi"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirildi"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk kalite güvencesi görüşmelerinizdeki dilleri otomatik olarak algılar. Belirli dillerde yazılmış görüşmeleri değerlendirmek üzere seçmek için açılır menüyü kullanın. Yardım masanızda kullanılan tüm dillerin genel görünümünü görmek için filtre niteliğini yukarıya sürükleyin."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içgörüleri"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Şunu yapabilirsiniz: Normal görüşme filtrelerine göre İçgörüler ile ", _interpolate(_named("paragraph_2_item")), " yapabilirsiniz. Yardım masalarınızdan tüm verilere görsel bir genel bakış elde edin ve şunlar gibi içgörüleri keşfedin:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içgörülerini kullanma hakkında bilgi edinin"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğerlerine kıyasla belirli görüşme niteliklerinin göreceli önemini anlayın"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En yüksek ve en düşük performans sergileyen temsilcilerinizi yerleşik temsilci performans sıralamasıyla bulun"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " güçlü bir etkileşimli veri madenciliği aracıdır ve değerlendirmeye en çok ihtiyaç duyduğunuz görüşmeleri bulmanıza yardımcı olur."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["çok daha fazlasını yapın"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görüşme ve Temsilci çizelgeleri ", _interpolate(_named("paragraph_3_item_1")), " – filtreleri güncellemek veya herhangi biri üzerinde segmentlere ayrılmış grafik dilimlerine tıklamak görüşme listesini otomatik olarak güncelleyecektir. ", _interpolate(_named("paragraph_3_item_2")), " Farklı öğelerin ayrıntılı dökümlerini veya oranlarını keşfetmek için ."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["birbirine bağlı"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre niteliklerini yeniden düzenleyin"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görüşme Bilgileri yalnızca ", _interpolate(_named("paragraph_4_item")), " görüntüler. Otomatik oluşturulan mesajlar, istenmeyen gönderiler, bot yanıtları veya destek temsilcinizle doğru bir iletişim kurulamayan mesajlar gibi gerçek değerlendirme değeri olmayan etkileşimleri otomatik olarak kaldırırız."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["net ve kapalı görüşmeler"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada ne görüyorum?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " / ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " / ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " / ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " / ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınamadı"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " G/D"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilciler"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Toplamda ", _interpolate(_named("percentage")), ")"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden yükle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörüler"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancak geldiğinde harika olacağına eminiz."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel anket"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimi kalmadı"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz anket geri bildirim puanı yok..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedeni"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alındı"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim etiketi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim yorum etiketleri"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih aralığı filtresi uygulandı"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "yanıt"]), _normalize([_interpolate(_named("count")), " yanıt"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anket ayarları altında nedenleri ayarlayarak bir ", _interpolate(_list(0)), " puanının neden verildiğini daha ayrıntılı olarak inceleyin."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Henüz ", _interpolate(_list(0)), " nedeni yok"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket nedenleri hakkında bilgi edinin"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketleri içeren görüşmeler:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tarafından"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedenler"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum kategorisi"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " neden"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Öngörülen ", _interpolate(_named("surveyType")), " sürücü"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme kaynağı"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketleri"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Anket yanıtları (", _interpolate(_named("n")), ")"]), _normalize(["Anket yanıtı (", _interpolate(_named("n")), ")"]), _normalize(["Anket yanıtları (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu yok"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ile görüşmeler"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kullanıcı başına ", _interpolate(_list(0))])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt oranı"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " puanı, tüm yanıtların toplamının maksimum puanların toplamına bölümüdür"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefi düzenle"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefi kaldır"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hedef belirleyin"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef ekle"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısa"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok uzun"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzun"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta uzunlukta"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kafa karıştırıcı"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yararsız"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötü destek"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İyi destek"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Duygular"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci için geri bildirim"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şikayet"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kırıntılar"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorun çözülmedi"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorun çözüldü"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif duygu"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötü sonuç"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitif duygu"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övgü"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötü ürün"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı destek"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yavaş destek"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA, ", _interpolate(_list(0)), " verilerinizi önceden tanımlanmış kategorilere ayırır. Böylece, yazılı geri bildirimleri manuel olarak değerlendirmeye gerek kalmadan karmaşık bilgileri anlayabilir ve nedenlerini keşfedebilirsiniz."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm puanlar"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm anketler"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt dilini seçin"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum boyutu"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum boyutu seçin"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumların %25'inin yalnızca bir kelimeden oluşmasıyla, daha uzun yorumlar mutlaka okunmalıdır. Biz sizin için otomatik olarak yaptığımız için, manuel işlemi yapmanıza gerek kalmadan daha ayrıntılı geri bildirimler bulun."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim nedeni"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim nedenini seçin"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanlar"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen puan yok"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öngörülen sürücüler"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum kategorisi seçin"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime Bulutu kelimeler"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime bulutu kelimelerini seçin"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Son dönemden beri ", _interpolate(_list(0))])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " görüşme"]), _normalize([_interpolate(_named("n")), " görüşme"]), _normalize([_interpolate(_named("n")), " görüşme"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri eksikliği nedeniyle, tüm görüşmelerden anket gönderildi oranı hesaplanamıyor."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlar"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderilen anketler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket konisi"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " / ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " / ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " / ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Öngörülen ", _interpolate(_named("surveyType")), " sürücü"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA, ", _interpolate(_list(0)), " verilerinizi önceden tanımlanmış kategorilere ayırır. Böylece, yazılı geri bildirimleri manuel olarak değerlendirmeye gerek kalmadan karmaşık bilgileri anlayabilir ve nedenlerini keşfedebilirsiniz."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum boyutu"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumların %25'inin yalnızca bir kelimeden oluşmasıyla, daha uzun yorumlar mutlaka okunmalıdır. Biz sizin için otomatik olarak yaptığımız için, manuel işlemi yapmanıza gerek kalmadan daha ayrıntılı geri bildirimler bulun."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket yanıtları"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (ortalama)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt sayısı"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (ortalama)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " trend ölçütü:"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt sayısı"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedenler"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürücüler"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " anketi gönderilme tarihi"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " anketi yanıt tarihi"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buna bir ", _interpolate(_list(0)), " puanı ata:"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verileri şuna göre görüntüle:"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme oluşturma tarihi"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaydın sahibi"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En çok yanıt veren temsilci"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (ortalama)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zaman içinde"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt sayısı"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt sayısı"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu kelimeleri içeren görüşmeler:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime bulutu"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bazında müşteri memnuniyeti"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyetine (CSAT) genel bakış"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket konisi"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket yanıtları"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketleri"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman içindeki Müşteri memnuniyeti"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime bulutu"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal yok"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak yok"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil yok"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden yok"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket yok"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum kategorisi yok"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı yok"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri bulunmuyor, çünkü atama oluşturulmamış veya hiçbir döngü başlatılmamış. Daha sonra tekrar kontrol edin veya filtreleri ayarlayın."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenilikleri keşfedin"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " öğe"]), _normalize([_interpolate(_named("count")), " öğe"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " görüşme"]), _normalize([_interpolate(_named("count")), " görüşme"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reşit olmayanlar ile pas"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı/Başarısız"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama (saat)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama (dk.)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " değerlendirme"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama Müşteri memnuniyeti Skoru = [geri bildirim puanlarının toplamı ÷ (tüm yanıtların sayısı x olası maksimum puan)] × %100"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA, müşteri memnuniyeti puanı, geri bildirim puanınızı %0-100 arasında normalleştirir ve standart puanların ortalamasını alır."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Müşteri Memnuniyeti Skoru), müşterilerinizin hizmetlerinizden ne kadar memnun olduklarını ölçer."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Her değerlendirme, bir kişinin bir görüşme üzerine değerlendirmesini temsil eder. Bir görüşmenin birden fazla değerlendirmesi olabilir (aynı görüşme için iki veya daha fazla değerlendiren varsa)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenin puanlarının zaman içindeki değişimini takip edin."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcilerin puanlarının zaman içindeki değişimini izleyin."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " yanıtlar"]), _normalize([_interpolate(_named("n")), " yanıt"]), _normalize([_interpolate(_named("n")), " yanıtlar"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " değerlendirme"]), _normalize([_interpolate(_named("n")), " değerlendirme"]), _normalize([_interpolate(_named("n")), " değerlendirme"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Dahili Kalite Skoru), görüşme değerlendirmelerinizin sonuçlarıdır."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = Değerlendirme puanlarının toplamı ÷ değerlendirme sayısı"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen filtre koşullarıyla eşleşen tüm değerlendirme puanları (kategori puanının ağırlıklı ortalaması) dikkate alınarak bir ortalama olarak hesaplanır."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart yüklenemedi."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden denemek için filtrelerinizi değiştirin."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçme oranı, görüşme değerlendirmelerinizin sonucunu gösterir."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Değerlendirme skoru ", _interpolate(_named("failScore")), " veya daha düşük ise başarısızdır"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Değerlendirme puanı ", _interpolate(_named("passScore")), " veya daha yüksek ise başarılıdır"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden tanımlanmış bir taban çizgisi ile belirlenir, yani:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçme oranı = Geçilen değerlendirmelerin toplamı ÷ Toplam değerlendirme"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçme oranı, kritik veya kritik olmayan kategorilerde başarısız olmadan başarılı olan yorum sayısının yüzdesini belirtir."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Örneğin, değerlendiren kişi o gün 3 yorum verirse, görüntülenen sayı 3 puanın ortalamasıdır. Her bir hücrenin nasıl hesaplandığını görün ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Örneğin, temsilci o gün içinde 3 yorum alırsa, görüntülenen sayı 3 puanın ortalamasıdır. Her bir hücrenin nasıl hesaplandığını görün ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkate alınmaz: kategori ağırlığı, kritik durum (Zaman içinde oluşan puanlardan farklı olarak)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlarda kategoriler yok olarak işaretlenebildiğinden, kategori başına yorum sayısı, değerlendirenin toplam değerlendirmesinden daha az olabilir. Her bir hücrenin nasıl hesaplandığını görün"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkate alınmaz: kategori ağırlığı, kritik durum (Zaman içinde oluşan puanlardan farklı olarak)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler yorumlarda yok olarak işaretlenebildiği için kategori başına yorum sayısı temsilci başına toplam yorumdan daha düşük olabilir. Her bir hücrenin nasıl hesaplandığını görün"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkate alınmaz: kategori ağırlığı, kritik durum (Zaman içinde oluşan puanlardan farklı olarak)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategori grupları ayrı olarak görüntülenir. Her bir grubun puanı, aynı dönemde o grubun ortalama kategori puanıyla hesaplanır. Her bir hücrenin nasıl hesaplandığını görün ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkate alınmaz: Kategori ağırlığı, kritik durum (Zaman içinde oluşan puanlardan farklı olarak)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategori grupları ayrı olarak görüntülenir. Her bir grubun puanı, aynı dönemde o grubun ortalama kategori puanıyla hesaplanır. Her bir hücrenin nasıl hesaplandığını görün ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Satır taban çizgisi ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik hatasız"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik hatasızlık oranı, herhangi bir kritik kategoriden başarısız olmadan geçen değerlendirmenin yüzdesini belirtir."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Kalite Skoru"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Diğer\" temel nedenler"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periyodik değişiklik"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük değişiklik"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık değişiklik"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftalık değişiklik"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık değişiklik"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman içindeki puanlar"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün veya hafta sonra görüntüle."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama = günün veya haftanın toplam puanının, verilen toplam yoruma bölümüyle elde edilir"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün veya hafta sonra görüntüle."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama = günün veya haftanın toplam puanının alınan yorumların toplamına bölümüyle elde edilir"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriye göre puanlar"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu dönemde değerlendirenin verdiği tüm kategori değerlendirmelerinin birleşik ortalamasını görüntüleyin."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu dönemde temsilci başına alınan tüm kategori değerlendirmelerinin birleşik ortalamasını görüntüleyin."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük değerlendirmelerin temel nedenleri"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmelerin temel nedenleri"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman içinde kategori puanları"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorilerin zaman içinde nasıl karşılaştırdığını analiz edin."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen bir gün veya haftada her bir kategori için verilen ortalama puanları görüntüleyin."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorilerin zaman içinde nasıl karşılaştırdığını analiz edin."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen bir gün veya haftada her bir kategori için alınan ortalama puanları görüntüleyin."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmelere göre puanlar"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlendi"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik kategori"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeği"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama/Toplam"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirmeleri hariç tut"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kartları dışa aktar"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verildi"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alındı"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["görüşme"]), _normalize(["görüşmeler"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["öğe"]), _normalize(["öğe"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " yanıtlar"]), _normalize([_interpolate(_named("n")), " yanıt"]), _normalize([_interpolate(_named("n")), " yanıtlar"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " değerlendirme"]), _normalize([_interpolate(_named("n")), " değerlendirme"]), _normalize([_interpolate(_named("n")), " değerlendirme"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bir tane daha göster"]), _normalize([_interpolate(_named("n")), " tane daha göster"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 30 gün"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 7 gün"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son ay"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son hafta"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ay"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hafta"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel dönem"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üç ay"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kart yüklenemedi. Yeniden denemek için filtrelerinizi değiştirin."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek veri yok."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık değişiklik"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panolar"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin filtreleriniz farklı değerlendirme ölçeklerinden veriler içeriyor."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu filtrelerle tüm kart pinleri"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu filtrelere sahip tüm pano pinleri"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama değerlendirme süresi"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özelleştir"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV’yi İndir"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori yok"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori bilgileri"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yorum"]), _normalize(["Yorumlar"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matris"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski üye"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Kalite Skoru"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (ortalama)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçilen değerlendirmeler"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin kartı"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoyu tuttur"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Temel neden"]), _normalize(["Temel nedenler"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme no."])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme zamanı"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel nedenleri ayarlayın"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel nedenler, görüşmelerinizin neden düşük puan aldığını bilmenizi sağlar"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategori için ayarlanan temel neden yok"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan / Toplam"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorileri"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi grupları"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori grubu"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sırayı özelleştir"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorileri gruplandırma ölçütü"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü seç"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özelleştir"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirmeleri hariç tut"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirmeleri dahil et"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca kendi kendine değerlendirmeleri göster"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri kaldır"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç hashtag bulunamadı"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum hashtagleri"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum hashtaglerini seçin"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket bulunamadı"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketleri"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketlerini ara"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı bulunamadı"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartlarını seçin"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self revizyon"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen bağlantı yok"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme kaynağı"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak seçin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla filtre"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen çalışma alanı yok"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam verilen süre"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kullanıcılar"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkes"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm gruplar"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen grup yok."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm gruplar"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme kartları"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verileri şuna göre görüntüle:"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme oluşturma tarihi"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme oluşturma tarihi"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki ondalık basamak göster"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarları görüntüle"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanları"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm hesap"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı adı"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı yok"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayraklar"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiyecek ve içecek"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayvanlar ve doğa"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesneler"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişiler ve gövde"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seyahat ve gezilecek yerler"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sık kullanılanlar"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama sonuçları"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suratlar ve ifade"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simgeler"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji bulunamadı"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir görüşme aç"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görev listesine dön"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " veya ", _interpolate(_named("link")), " listenize göz atın"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Şu anda bu etki alanına bağlı olmayan ", _interpolate(_named("workspace")), " çalışma alanı kullanıyorsunuz. Şimdi bağlamak için buraya tıklayın."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanılabilir bağlantı"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Henüz ", _interpolate(_named("host")), " için geri bildirimde bulunamazsınız."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin sekmeniz yardım masanız olsun."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan henüz değerlendirilemiyor Gerekiyorsa yöneticinize bildirin."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alanı ekle"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("host")), " ile bağlanın"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biraz beklemenizi rica ederiz – hazırlıyoruz"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL alınamadı"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyon biriminin Zendesk QA hesabına zaten bağlı olan araçlar."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyon biriminin Zendesk QA hesabıyla bağlantılı araç yok."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumdan ayrılmadan önce benzersiz bir karma ekleyerek, URL'lerinin aynı kaldığı web sitelerini değerlendirmenizi sağlar"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap değiştir"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlarınız"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanıyla zaten bağlantılı olan etki alanları."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki alanlarda herhangi bir sayfayı inceleyebilirsiniz"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme için hazır alanlar"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili numara"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzersiz URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Henüz yardım masası bağlanmadı. Eklemek için ", _interpolate(_named("link")), " bağlantısına gidin."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca Zendesk QA tarayıcı uzantısı kullanılabilir\nProfessional ve Advanced planlarda."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanını değiştir"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir ad girin"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değer girin"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel alanlar"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu tür bir sohbetle ilk kez karşılaşıyoruz. Bazı ayrıntılar verin."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme referansı:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı daha sonra tanımlayacak bir şey"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İade"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hem atanan hem de bir konu girin"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuluyor"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi değerlendirin"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanmamış"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yöneticinizle iletişime geçin veya ", _interpolate(_named("settings")), " dışında bir çalışma alanına geçin."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanı için kendi kendine değerlendirme devre dışı bırakıldı."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoşça kalın"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu URL değerlendirilemez. Bir görüşmeye gidin."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önce SSO sağlayıcınız aracılığıyla Zendesk QA oturum açın ve yeniden deneyin."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO ile oturum aç"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma açılır penceresi açılıyor."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA hesabınızla oturum açın"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleyin..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulanıyor..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz bir hesabınız yok mu?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt ol"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeye erişiminiz yok. Gerekirse yöneticinizle iletişime geçin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erişim yok"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA'da bağlantınızın etkinleştirilip etkinleştirilmediğini kontrol edin"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılarınızı yönetin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı hatası"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam etmek için yardım masanızda bir görüşmeye gidin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden bağlanma işlemi neredeyse tamamlandı."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biraz bekleyin"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlerin akışına geri dönmek için sayfayı yeniden yüklemeyi deneyin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sorun oluştu"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendinizi yardım masanızın sanal kenarlıklarından kurtarın ve görüşmeleri dilediğiniz yerden değerlendirin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzantıyı kullanmak için aboneliğinizi yükseltin"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliğinizi yönetin"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası bağlantısı bu çalışma alanına bağlı değil"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masanız bu kaydı tanımıyor veya Zendesk QA'nın bu kayda erişimi yok"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme bulunamadı"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni aktivite"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazı geri bildirimler aldınız"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma başarılı"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamayı şimdi kullanabilirsiniz"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kategorileri değerlendirin"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakalara yer işareti koymak ve daha sonra filtrelemek için bunu kullanın"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme için geri bildirim yok"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirimleri okuyun"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA'da aç"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm geri bildirim ve bildirimler burada görünecektir. Henüz bir şey yok…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA'da daha fazlasını görün"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme ayrıntıları"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tümünü içerir"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şunun tümü:"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tümünü içermez"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tümü değil"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temsilcininkinden daha iyi"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temsilcininkinden daha kötü"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan büyük:"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şunlardan herhangi birini içerir"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şundan herhangi biridir:"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hiçbirini içermez"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şunlardan biri değil:"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğru"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhlal edilen"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yorum yapılan"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benim tarafımdan yorum yapılan"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yorum eklemedi"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benim tarafımdan yorum yapılmadı"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirme için kritik"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu içerir:"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son 30 gün"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu süre içinde"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu süreden itibaren"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son 14 gün"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son 24 saat"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son 30 gün"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 7 gün"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geçen ay"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geçen hafta"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu ay"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu hafta"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu tarihe kadar:"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bugün"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dün"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algılanan"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mevcut"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarafımdan yıldız konulan"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarafımdan yıldız konulmayan"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şunu içerir:"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şudur"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan fazla:"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan itibaren:"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan küçük:"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuna kadar:"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu değildir:"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şudur:"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu değildir:"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["olumsuz"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihlal edilmedi"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["karmaşık değil"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algılanmadı"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mevcut değil"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şunu içermiyor:"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alınmadı"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görüntülenmedi"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["olumlu"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirilmedi"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benim tarafımdan değerlendirilmedi"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirildi"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarafımda değerlendirildi"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alındı"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirme için kritik"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu içerir:"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şudur:"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan fazla:"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan itibaren:"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şundan küçük:"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuna kadar:"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şudur:"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu değildir:"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şunu içermez:"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu değildir:"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görüntülendi"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yorumuma yanıtlar var"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hayır"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evet"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel kategoriler"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel ilgi alanları"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşulu ara"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçümler"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcılar"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ve geri bildirim"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm koşulları göster"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botunuzun görüşme işlemesini ortalama temsilcilerle karşılaştırır"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bir döngüde kaldı ve aynı mesajı ardışık sırayla yineliyor"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki bot yanıtı sayısı"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye dahil olan bir bot türü"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye dahil olan bir bot"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Görüşme içeriğinde, ", _interpolate(_list(0)), " temsilcilerin mesajındaki (mesajlarındaki) cümle yer alıyor"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Bu arama kaydedilecek\" vb. zorunlu açıklama bildirimi içermeyen aramaları otomatik olarak belirleyin."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye dahil olan bir kişi veya bot"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede değerlendirilen temsilci veya bot"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri daha üst düzey yardım talep etti"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede pozitif ve negatif duygu algılandı"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka analiziyle görüşmelerdeki en aktif temsilciyi belirler"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin görüşmeyi kibarca sonuçlandırıp sonuçlandırmadığını analiz eder"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterilerin duygularını anlayıp anlamadığını analiz eder"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin dil bilgisi, imla ve stil hatalarını analiz eder"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipik selamlama ifadeleri için tüm görüşmeyi analiz eder"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterinin sorgusunu veya endişesini anlayıp anlamadığını analiz eder"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir metnin ne kadar kolay anlaşılabileceğini, kelime karmaşıklığını ve cümle uzunluğunu dikkate alarak analiz eder"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan bir çözüm için tüm görüşmeyi analiz eder"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme boyunca temsilcinin ses tonunu analiz eder"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri kaybı belirtileri. Müşteri bir geçiş yapmayı düşünüyor veya yolları ayırma sözü veriyor."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin ait olduğu grup."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci mesajının içeriği ya da metni"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki temsilci yanıtlarının sayısı"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değerlendirmek üzere atanan temsilci"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramanın saniye cinsinden sürdüğü süre"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin içeriği veya metni"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme için kullanılan iletişim kanalı (örneğin e-posta, canlı destek)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye eklenen yorumlar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye bir yorum eklendiği tarih"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede pozitif ve negatif duygu algılandı"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin müşteri memnuniyeti anketini yanıtladığı tarih"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme değerlendirmesine itiraz edilip edilmeyeceği"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin görüşmeyi başlatmak için kullandığı kanal"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki müşteri yanıtlarının sayısı"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmedeki son mesajın tarihi"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye dahil olan kullanıcının rolü veya türü"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci ve müşteri arasındaki sessiz kalma süresi eşiği aştı"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri daha üst düzey yardım talep etti"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri alınan destekten çok memnun olduğunu veya çok memnun olduğunu belirtti"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri veya temsilci açık bir şekilde takip kaydı talep etti"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm görüşmenin ses kalitesiyle gerçekleştirilen metni veya kaydı"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin yıldızlı olarak işaretlenip işaretlenmediği"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçeriği kategorilendirmek için yorumlarda kullanılan hashtag'ler"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin dili yapay zeka tarafından algılandı"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmedeki son mesajın tarihi"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki özel notların sayısı"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki mesaj başına ortalama karakter sayısı"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki müşteriye özel yanıtların sayısı"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgileri herkes tarafından görülebilen bir katılımcı"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki mesaj başına ortalama kelime sayısı"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değerlendirmek veya değerlendirmek için kullanılan kategoriler"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin değerlendirme sürecinin durumu"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir katılımcı veya görüşmeye dahil olan kişi"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyle ilişkili bir e-posta"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumun görüntülenip görüntülenmediği"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değerlendiren"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin en son değerlendirildiği tarih"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede değerlendirilen temsilci"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin görüşmeyle ilgili memnuniyeti hakkında yaptığı tüm yorumlar veya geri bildirimler"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme için müşterinin memnuniyet puanı"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmedeki mesaj başına müşteri memnuniyet puanı"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değerlendirmek için kullanılan puan kartı"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirtilen SLA (HDS) ihlali"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin başlatıldığı kaynağın veya bağlantının türü"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme olağandışı veya tipik değildi ve çözüme ulaşılması için daha fazla ileri geri görüşme yapıldı"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içeriğiyle ilgili anket sorusu"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyle ilişkili anketin başlığı veya adı"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değerlendirmek üzere atanan temsilci"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyle ilişkili marka veya belirli ürün/hizmet"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme için kullanılan iletişim kanalı (örneğin e-posta, canlı destek)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin kapatıldığı veya çözüldüğü tarih"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin ilk oluşturulduğu tarih"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin ilk oluşturulduğu tarih"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin müşteri memnuniyeti anketini yanıtladığı tarih"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin kısa bir açıklaması veya özeti"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye bağlı harici bir referans kimliği"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin organize edildiği klasör veya belirli gruplama"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi yapılandırmak için kullanılan form ya da şablon"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye bağlı harici bir referans kimliği"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin alındığı posta kutusu veya e-posta hesabı"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyle ilişkili telefon numarası"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeye atanan öncelik düzeyi"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmedeki toplam yanıt sayısı"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin durumu ya da mevcut durumu"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin mevcut durumu"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin konusu veya konusu"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerdeki temsilci yanıtlarının sayısı"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi kategorize etmek için atanan etiketler"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin türü veya kategorisi"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin en son güncellendiği tarih"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin en son güncellendiği tarih"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin gerçekleştiği araç veya kanal"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmedeki yazılı dökümün içeriği"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka analizi yoluyla değerlendirme için uygun olmayan görüşmeler"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelere katılan temsilci sayısı"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin görüntülenip görüntülenmediği"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir mesaj"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot iletişim verimliliği"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot yinelemesi"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot yanıtı sayısı"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot türü"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama yönü"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri mesajı"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsizlik"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt açıklaması eksik"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama yazılı döküm içeriği"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıldız işaretli"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilemiyor"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kişi tarafından değerlendirildi:"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şunun üzerinden görüşme:"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım ve dil bilgisi"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci mesajı"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En aktif temsilci"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci yanıt sayısı"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama süresi (saniye)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içeriği"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj kanalı"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum etiketi"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum tarihi"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti anketi yanıt tarihi"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri kanalı"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri yanıtı sayısı"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son yanıt tarihi"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı türü"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıp riski"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsizlik"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olağanüstü hizmet"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip et"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazılı döküm"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum hashtag"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınır değer"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlayış"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son yanıt tarihi"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme özel not sayısı"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama karakter sayısı"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama kelime sayısı"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık yanıt sayısı"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık katılımcı"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme tarihi"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ve yorum"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme durumu"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okunabilirlik"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcı"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili e-posta"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt sayısı"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme alındı"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen puan kartı"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme görüntülendi"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme tarihi"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket yorumu"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj başına CSAT"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memnuniyet puanı (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan çözüm"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak türü"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket sorusu"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket başlığı"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme markası"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka etki alanı"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme kanalı"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanma tarihi"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma tarihi"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma tarihi"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti anketi yanıt tarihi"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti anketi gönderilme tarihi"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme açıklaması"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harici Kimlik"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme alanı"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme klasörü"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme formu"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk kimliği"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme grubu"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım Scout Kimliği"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harici Kimlik"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Telefon Numarası"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme posta kutusu"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numarası"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme önceliği"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme yanıtı sayısı"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme durumu"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme durumu"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme konusu"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci yanıt sayısı"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketi"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketi"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme türü"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme tarihi"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme tarihi"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk kimliği"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazılı döküm içeriği"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılan temsilci sayısı"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenme durumu"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenlik açığı özelliği"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenlik açığı durumu"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenlik açığı yaşam olayı"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü kategorisi"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarihle ilgili bir koşul seçin"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrenize bir ad ekleyin"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir filtre adı girin"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açan kişi"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski çalışma alanı üyeleri"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı üyeleri"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " ekle"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri uygula"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarihle ilgili en az 1 filtreniz olmalıdır"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekleri belirleyin"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir seçim yapın"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme süreniz ", _interpolate(_named("n")), " gün içinde sona eriyor. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme süreniz <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " gün içinde sona eriyor</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme süreniz ", _interpolate(_named("n")), " gün içinde sona eriyor. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme süreniz <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>", _interpolate(_named("n")), " gün içinde sona eriyor</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denemeniz bugün sona eriyor. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemeniz <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>bugün</strong></a> sona eriyor."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi bir abonelik oluşturun"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " gün"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme sürenizde ", _interpolate(_named("days")), " kaldı"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Planınız için kullanıcı limitine ulaştınız. Daha fazla bilgi edinmek veya planınızı yükseltmek için ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buraya tıklayın"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yardım masanızı bağlayın"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, kayıtlarınızı değerlendirmeniz için çekmemize olanak sağlar."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masanızı bağlayın"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Örnek verilerle Zendesk QA kullanıyorsunuz. Örnek verileri silmek ve kendi görüşmelerinizi içe aktarmak için ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek veriler"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki yardımcı bilgileri kopyalayın"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlam sağlamak için tam sayfa ekran görüntüsü alın"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sorunu çözmek için müşteri destek ekibimizle iletişime geçin:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir sorun oluştu"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata referansı:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardımcı olacak bilgileri kopyala"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar, botlar ve çalışma alanları"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar ve Çalışma Alanları"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bugün"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu ay"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bu hafta"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " değerlendirildi ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), "değerlendirildi ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " değerlendirildi ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni neler var?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klavye kısa-yolları"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimle sohbet edin"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canlı desteğimiz yüklenemedi (Bir reklam engelleyici kullanıyor olabilir misiniz?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuraya atla..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pano"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana sayfa"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen değerlendirmeler"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi bankası"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap değiştir"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görevler"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uygulama güncellendi. ", _interpolate(_named("buttonStart")), "Yeniden yükle", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA’yı çalışma alanınızın bir parçası olarak kullanmaya başlamak isterseniz:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bir çalışma alanının parçası olarak Zendesk QA'yı kullanmak için bir davet aldınız. İsterseniz devam edebilirsiniz, ancak bu sizin için bir ", _interpolate(_named("bold")), " yaratacak."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayrı organizasyon birimi"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Evet, ", _interpolate(_named("link")), " ile devam edeyim."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yeni bir hesap"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " sizden ", _interpolate(_named("account")), " katılmanızı istiyor"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katıl"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnternet bağlantısı yok"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni PIN ekle"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı gezinme"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum editörüne odaklan ve onu genişlet"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan paneli gizle veya göster"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki görüşmeye git"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orijinal görüşmeyi aç"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki görüşmeye git"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kategorilere mümkün olan en yüksek puanı verin"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kategorilere mümkün olan en düşük puanı verin"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri karıştır (rastgele yeniden sıralanır)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize bildirin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klavye kısa-yolları"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısa yol modunu değiştir"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("charLimit")), "- karakter limitine ulaştınız"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görsel ", _interpolate(_named("name")), " tür (", _interpolate(_named("type")), ") izin verilmiyor"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Görsel ", _interpolate(_named("name")), " boyut çok büyük (5 MB'a izin verilir)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum editörü yüklenemedi"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karşıya yüklemek için bırakın"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görsel"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonları yönet"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon eklenmedi"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden ekle"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer, silinme nedenleriyle birlikte"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme nedeni"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyadı"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan sayısı"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numarası"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızı oluşturmanıza yardımcı olmak için sizinle iletişime geçeceğiz"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket adı"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket iletişim numarası"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA hizmetini ilk kez nasıl duydunuz?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kanal seçin"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birkaç temsilci seçin"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli tüm alanları doldurun"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullarımızı ve gizlilik politikamızı okuyup onayladığınızı kabul etmeniz gerekir."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek odaklı Slack kanalı"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklamcılık"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olay"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşınız veya iş arkadaşınız"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası ticaret sitesi veya hesap yöneticisi"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendiren site"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama motoru (Google, Bing vb.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal medya"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize kendinizden ve şirketinizden bahsedin."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap ayrıntıları"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazırım."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mosey, uygulamayı tanıyın, bir şeyler silin ve ekleyin."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap oluşturuldu. Sırada ne var?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerçek görüşmelerinizi getirmeye hazır mısınız?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi verilerinizi ekleyin"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEŞFETMEYE BAŞLAYIN"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek verilerle deneyin"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böylece, değerlendirmek için kayıtlarınızı almaya başlayabiliriz."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası hesabınızı bağlayın"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikrimi değiştirdim."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı iptal et"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pin oluşturuldu. ", _interpolate(_named("buttonStart")), "Pinlere git", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme pinleri Professional planda kullanılabilir."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planı yükselt"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm planları görün"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki 1:1 veya koçluk oturumuna hazırlanmak için notlarınızı kaydedin."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk Pinleri"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yapın veya ", "@", " ile başkalarını ekleyin"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşme silindi"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir PIN oluşturun"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN oluştur"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN'i düzenle"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinler nasıl çalışır?"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi görme izniniz yok"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin kartı"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi tuttur"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoyu tuttur"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin kaydedildi"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kartı tuttur"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi sabitle"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu panoyu tuttur"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kullanıcının altına tuttur"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kullanıcı seçin"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabitlediklerinizi yalnızca siz görebilirsiniz. Başkalarıyla paylaşmak için, yorumlarda onlardan ", "@", " bahsedin."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " artık bu görüşmeye davetli"]), _normalize([_interpolate(_named("agentsNames")), " artık bu görüşmeye davetli"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 yorum"]), _normalize([_interpolate(_named("n")), " yorum"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("participants")), " adlı kişiden bahsettiniz ama o bu görüşmede değil."]), _normalize([_interpolate(_named("participants")), " adlı kişiden bahsettiniz ama o bu görüşmede değil."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " katılımcı"]), _normalize([_interpolate(_named("n")), " katılımcı"]), _normalize([_interpolate(_named("n")), " katılımcı"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " adlı temsilcinin bu pine erişimi kaldırılsın mı?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbir şey yapma"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onu bu görüşmeye davet etme"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum ekle"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onları davet edin"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcıyı bu görüşmeye davet edin"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı kaldır"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazışma dizisi"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem, bu pin altındaki tüm yorumları da siler."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " yeni mesaj"]), _normalize([_interpolate(_named("n")), " yeni mesaj"]), _normalize([_interpolate(_named("n")), " yeni mesaj"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinlerinizi yalnızca siz görebilirsiniz. Pinleri paylaşmak için ", "@", " , yorum ekledi."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), ", ", _interpolate(_named("pinType")), " pinini yerleştirildi"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir pano"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir görüşme"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir not"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk pinleri almak için yükseltin"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme pinleri Professional planda kullanılabilir."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme pinleri Professional planda kullanılabilir"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu özellikten yararlanmak için yükseltin"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1'e 1'lerinize hazırlanın veya başka bir zaman için bir şeyler kaydedin."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem, pini ve eklenen tüm yorumları kalıcı olarak silecek."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN'i sil"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN'i sil"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasör adı"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğeleri daha sonra geri gelmek üzere sabitleyin"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtla"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden eskiye"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskiden yeniye"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlginizi çeken bir pano trendini veya görüşmeyi 1-1’lik görüşmelerinize hazırlamak üzere kaydedin veya başka bir zaman not edin."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukarıdaki düğmeye veya Görüşme ya da Pano görünümünde raptiye simgesine tıklayarak bir PIN oluşturun."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin oluşturulmadı"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " için hiçbir sonuç bulunamadı"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı anahtar kelimeler kullanmayı veya yazım hatası olup olmadığını kontrol etmeyi deneyin."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir PIN oluşturun"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajlar"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişiler"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı, anahtar kelime ile ara..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetik olarak"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni oluşturulan"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni düzenlenen"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En çok pin"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " görüşme ", _interpolate(_named("schedule")), " İncelemeyi amaçlıyorum"]), _normalize([_interpolate(_named("n")), " görüşme ", _interpolate(_named("schedule")), " İncelemeyi amaçlıyorum"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef güncellendi"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef belirleyin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmek istediğiniz görüşme sayısı için kişisel bir hedef belirleyebilirsiniz."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her gün"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayda bir"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["her haftada bir"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefi değerlendir"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KİŞİSEL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonlar"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanallar yükleniyor"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal seçin"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimlerini yalnızca yorumlarla paylaş"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olağanüstü hizmet kalitelerini ve mükemmel müşteri geri bildirimlerini ön plana çıkararak çalışma arkadaşlarınızı güçlendirin."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumlu anket geri bildirimlerini Slack'e paylaşın"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal bulunamadı"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirimleri yukarıdaki puanlarla otomatik olarak paylaş"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot tümleştirmeleri"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç değerlendirme yapmadıysanız haftanın başında bir hatırlatma alın."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatırlatmalar"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftalık değerlendirme yapmak için hatırlatma"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack'teki yeni değerlendirmeler, yanıtlar, bahsetmeler ve ihtilaflarla ilgili bildirimler alın."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahsedilenler"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazışma dizisi yanıtları"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack tümleştirmeleri"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendirmeler, yanıtlar, bahsetmeler ve ihtilaflar hakkında bildirim gönder."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benimle ilgili ihtilaflar"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlardaki bahsetmeler"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni alınan değerlendirmeler"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazışma dizisindeki yanıtlar"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikleri içeren rapor"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen ve alınan yorumlar hakkında raporlar alın."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlar"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["günlük"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayda bir"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["haftalık"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 saatte bir"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 saatte bir"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemen"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapalı"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftalık"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap yöneticiniz kilitli bildirim ayarlarına sahip."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dönem Seçin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel bildirimler"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (etkin)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesaba yeni bir sosyal medya hesabı oturumu açma adı ekleyin:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir etkin oturum açma yöntemi kaldırılınca oturumunuz kapatılır. Alternatif e-posta adresinizle yeniden oturum açabilirsiniz."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma yöntemini kaldır"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google, oturum açma seçeneği olarak eklendi"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma seçenekleri"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack oturum açma seçeneği olarak eklendi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google'ı ekle"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Ekleyin"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola oluştur"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöntem"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı sıfırla"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["adlı alıcıya bir e-posta ", _interpolate(_named("email")), " parolanızı sıfırlamak için gönderilecek."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birincil e-posta"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay e-postamız için gelen kutunuzu kontrol edin"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bunu değiştirmek için, ", _interpolate(_named("link")), " ."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-posta ", _interpolate(_named("email")), " bu parolayla ilişkilendirilecek."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil güncellendi"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil resmi"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En iyi sonuçları elde etmek için kare şeklinde bir fotoğraf yükleyin (256x256 piksel veya daha uzun)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birincil e-posta"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta başı"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel ayarlar"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir seçim yapın"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon içeriği"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonun adı"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonu şunlarda göster"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon içerik ekle"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon adı ekleyin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon oluştur"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon oluştur"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeyi, öğeleri sabitlemeyi ve koçluk hizmetini hızlandırmak için yorum şablonları oluşturun."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonu sil"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu şablon kalıcı olarak silinsin mi?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonu düzenle"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeyi, öğeleri sabitlemeyi ve koçluk hizmetini hızlandırmak için yorum şablonları oluşturun."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon oluşturulmadı"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum şablonu oluşturuldu"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum şablonu silindi"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum şablonu kaydedildi"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinler"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler ve ihtilaflar"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erişim sağlamak için ", _interpolate(_named("link")), " ile iletişime geçin."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["müşteri destek"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap bulunamadı"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Şimdilik, Zendesk QA’ya erişebilmek için ", _interpolate(_named("link")), " bağlantısını kullanın."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk'e dön"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap taşınmadı"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Kalite Kontrolü"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ölçümler"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanlar"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["istatistikler"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pano"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrasyon"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görüşmeler"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtreler"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revizyon"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verildi"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alındı"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benim yorumlarım"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihtilaflarım"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirmelerim"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tepkiler"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alındı"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlatıldı"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimi"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivite"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinler"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonlar"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola oluştur"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik bilgileri"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-posta"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-posta"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oturum aç"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sıfırla"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirme hedefi"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonlar"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanıcı"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon oluştur"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum aç"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonlar"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonelik"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hesap"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faturalama"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["şirket"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümleştirmeler"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyon birimi"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödeme işlemi"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lisanslar"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayarlar"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abonelik"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulama İşlemleri"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kategori"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonelik"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çakışan mesaj bulundu"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çakışan mesajlardan birini değiştirin veya kaldırın"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hassas verileri Otomatik Kalite Kontrolü hariç tutmak için ", _interpolate(_named("placeholder")), "simgesini kullanın. Örneğin, \"Kendinize iyi bakın, Can\" yerine, herhangi bir ismi temsil edecek şekilde \"Kendinize iyi bakın ", _interpolate(_named("placeholder")), "“ kullanabilirsiniz."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimum ", _interpolate(_named("limit")), " satır"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuralı şu kişiye uygula:"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 özel kategori limitine ulaştınız"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("limit")), " özel kategori limitine ulaştınız"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul ekle"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satır ekle"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşulu sil"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eğer temsilci mesajı"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksi takdirde puan"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ardından puan verin"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı kategorisi oluştur"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişken eklemek için \"/\" kullanın"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm yönler"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci mesajı boş bırakılamaz"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj 300 karakterden uzun olamaz"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kaynaklar"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kanallar"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm etiketler"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm görüşmeler"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuralınızın uygulanacağı yeri daraltın."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli görüşmeler"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight İçgörüleri oluştur"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci mesajı boş bırakılamaz"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 özel içgörü limitine ulaştınız"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("limit")), " özel içgörü limitine ulaştınız"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörü adını girin"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörü adı"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluştur"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörü türü"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumsuz"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafsız"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumlu"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı ayarlarına git"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi değil"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme kategorisini kullanmaya başlamak için çalışma alanı puan kartı ayarlarınıza gidin ve bunu seçtiğiniz bir puan kartına ekleyin."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi oluşturuldu"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli anahtar kelimeleri veya ifadeleri belirleyerek kapanan görüşmeleri otomatik olarak puanlayın."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ses yazılı dökümlerinde çalışmaz."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tebrikler! ✋ düzeyindeki otomatik değerlendirme kategorilerinizi maksimuma çıkardınız ", _interpolate(_named("limit")), ". Kategorizasyon sihirbazı sizsiniz! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori limitine ulaşıldı"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm alanların doğru bir şekilde doldurulduğundan emin olun"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorinize bir ad verin"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori adı"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendirme kategorisi"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendirme kategorisi"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli anahtar kelimeleri veya ifadeleri belirleyerek kapanan görüşmeleri otomatik olarak puanlayın. Ses yazılı dökümlerinde çalışmaz."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli anahtar kelimeleri veya ifadeleri belirleyerek kapanan görüşmeleri otomatik olarak puanlayın. Yalnızca yazılı dökümler üzerinde çalışır."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hem temsilci hem de bot mesajlarında belirli anahtar kelimeleri veya ifadeleri tanımlayarak görüşmeleri otomatik olarak puanlayın."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme kategorisini kullanmak için çalışma alanı puan kartı ayarlarınıza gidin ve bunu bir puan kartına ekleyin."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri, mesajlardaki belirli anahtar kelimelere veya ifadelere göre otomatik olarak puanlayın."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimler kullanabilir?"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçgörü kurulumu"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu içgörü, Spotlight’da tanımlanmış ölçütlere göre görüntülenir."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı kurulumu"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategori, tanımlanmış ölçütlere göre puan kartlarında kullanılabilir."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canlı destek"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakında"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["özel"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ön tanımlı"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puan kartı"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spotlight"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ses"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri kaydet"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedildi"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İpucu:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolünün kapanış kontrolleri sırasında adları, şirket bilgilerini veya diğer hassas bilgileri hariç tutması için, ", "{··}", " simgesine tıklayın."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış kapanış ekle"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan kapanışı düzenleyin"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış onaylandı"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyetler ekle"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyeti düzenle"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["istisna"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlama ekle"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlamayı düzenle"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlama"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelimeleri buraya satır sonlarıyla ayırarak yapıştırın"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir giriş için her kelimeden sonra Enter tuşuna basın"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli dil"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örneğin, \"Kendinize iyi bakın, John\" yerine, herhangi bir ismi temsil edecek şekilde \"Kendinize iyi bakın ", "{··}", "“ kullanabilirsiniz."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamik içerik yer tutucusu"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstisnalar"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 istisna eklendi"]), _normalize([_interpolate(_named("count")), " istisnalar eklendi"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Muafiyet zaten eklendi"]), _normalize(["Zaten eklenmiş istisnalar: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyet ekle"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyet bulunamadı"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç istisna eklenmedi"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyet (büyük-küçük harf duyarlı)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["İstisnalar ekle"]), _normalize(["1 muafiyet ekle"]), _normalize(["Ekle ", _interpolate(_named("count")), " istisnalar"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kelime veya ifadeyi arayın"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyet kaydedildi"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm diller"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ara"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kapanış zaten eklendi"]), _normalize(["Zaten eklenmiş kapanışlar: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış ekle"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış sonuçlandırma bulunamadı"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış kapanış yok"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış ekle"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kapanış arayın"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış eklendi"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış kaydedildi"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formda yinelenen giriş var: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Selamlama zaten eklendi"]), _normalize(["Selamlamalar zaten eklendi: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama ekle"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlama bulunamadı"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlama yok"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama ekle"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama arayın"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama eklendi"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama kaydedildi"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyet ekle"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Muafiyeti sil"]), _normalize(["İstisnaları sil"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bu muafiyet kalıcı olarak silinsin mi?"]), _normalize(["Bu muafiyetler kalıcı olarak silinsin mi?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muafiyeti düzenle"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bir veya daha fazla istisna, ", _interpolate(_list(0)), " karakter uzunluğunda"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " karakter"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellendi"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleyen:"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişken"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markanıza ve hizmet standartlarınıza uygun kendi imzalarınızı tanımlayın. Eklendiğinde, Otomatik Kalite Kontrolü yalnızca onaylanan kapanışları algılar. Yapılan değişiklikler gelecekteki görüşmelere uygulanır."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 onaylanan sonuçlandırma üst limitine ulaştınız. Daha fazla eklemek istiyorsanız, mevcut kapanışlarınızı yeniden gözden geçirme ve daraltma zamanı gelmiş olabilir."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış kapanış limitine ulaşıldı"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan kapanış sayısı"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım veya dil bilgisi hataları nedeniyle Otomatik Kalite Kontrolünün yok saymasını istediğiniz kelimeler ve ifadeler. Yapılan değişiklikler gelecekteki görüşmelere uygulanır."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yok say listesi"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka ve hizmet standartlarınızla uyumlu kendi selamlamalarınızı belirtin. Eklendiğinde, Otomatik Kalite Kontrolü yalnızca onaylanan selamlamaları algılar. Yapılan değişiklikler gelecekteki görüşmelere uygulanır."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış 100 selamlama üst limitine ulaştınız. Daha fazla eklemek isterseniz, mevcut selamlamalarınızı yeniden ziyaret edip daraltmanın zamanı gelmiş olabilir."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanmış selamlama limitine ulaşıldı"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan selamlamalar"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi değil"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi sil"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme kategorisi kalıcı olarak silinsin mi?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler listesini aç/kapat"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinen kategori hiçbir yeni görüşmede ve filtrede görünmez. Panolardaki ve görüşmelerdeki geçmiş verilere zarar verilmez."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori, eklendiği tüm puan kartlarından kaldırılır."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı kategorisi silinemedi"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı kategorisi silindi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı kategorisini sil"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü kategorileri"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " Yapay Zeka modelinin kullandığı model puan kartında kalacak ama artık otomatik olarak puanlanmayacak"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM tümleştirmesinin kapatılması, Otomatik Kalite Kontrolü özelliğini tamamen kapatmaz. Olabilecekler:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş verileri"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " Otomatik Kalite Kontrolü panosunda kalacak"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel olarak eklenen kök nedenler"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " kalır, ancak LLM'ye dayalı tüm temel nedenler kaldırılır"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM tabanlı Otomatik Kalite Kontrolü"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü , belirli kategorileri otomatik olarak puanlamak için Yapay Zeka ve büyük dil modellerini (LLM'ler) kullanır. Verileriniz katı bir Data Processing Agreement (DPA) kapsamında işlenir ve asla modellerin eğitilmesi için kullanılmaz."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üretken yapay zeka hakkında bilgi edinin"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otomatik Kalite Kontrolü, ", _interpolate(_named("automatically_scoring_categories")), " için Microsoft Azure OpenAl Service'i kullanır. Verileriniz, yalnızca size ve ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bazı kategorileri otomatik olarak puanlamak"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenAl modellerini eğitmek için asla kullanılmaz"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM tabanlı Otomatik Kalite Kontrolü açıldı"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM tabanlı Otomatik Kalite Kontrolü kapatıldı"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM tabanlı Otomatik Kalite Kontrolü"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü Yapay Zeka ile otomatik puanlama"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure tabanlı Otomatik Kalite Kontrolü kategorilerinin veri işlemesi"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure izni geri alındı"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure'un onayı alındı. Görüşmeler yakında otomatik değerlendirmeler alacak."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolünü kullanmaya devam edebilirsiniz ama olacak şey şu:"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAl hizmeti devre dışı bırakılırsa Otomatik Kalite Kontrolü bir işlev olarak kapatılmaz."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunu puan kartı kategori görünümünde yapabilirsiniz."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelin desteklediği Otomatik Kalite Kontrolü kategorileri puan kartında kalacak ama otomatik olarak puanlanmayacak."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel olarak eklediğiniz temel nedenler kalır. Model tarafından oluşturulan tüm temel nedenler kaldırılacak."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş verileri Otomatik Kalite Kontrolü panosunda kalır."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAl hizmetiyle veri işlemeyi devre dışı bırak"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha geniş Otomatik Kalite Kontrolü kategorileri."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıma hazır çok dilli destek."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı bir şekilde yeni kategoriler ekleme."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme doğruluğu artırıldı. GPT-3,5, insan düzeyinde bağlamsal duyarlılığı gösterir."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka teknolojisindeki en son gelişmelerden yararlanın."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazanacaklarınız"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAl hizmetiyle veri işlemeyi etkinleştir"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin verildi"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin verilmedi"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm yeni kullanıcılar bu çalışma alanına atanır"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan çalışma alanı"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 saat biçimi (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 saat biçimi (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman formatı"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pzt"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paz"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket profili güncellendi"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta başı"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Tümleştirme"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erişim anahtarı numarası ve gizli anahtar genellikle Erişim yönetimi > Kullanıcılar altında bulunur"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS erişim anahtarı kimliği"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ör., AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect'i ekleyin"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizli erişim anahtarı değiştirmeyi iptal et"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre erişim anahtarı değerini değiştirin"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı, genellikle Konum altında bulunan yalnızca bir bölgenin tanımlanmasına izin verir"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS bölgesi"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["örneğin: eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir gizli anahtar ekleyin"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS gizli erişim anahtarı"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ör., wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili tüm bilgileri Amazon Connect'te bulabilirsiniz."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genellikle Amazon Connect bulut sunucusu ayarları ve Veri depolama sayfası altında bulunur"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama kayıtlarının depolanma konumu"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ör., amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect eklenirken bir hata oluştu. Tüm alanların doğru bir şekilde doldurulup doldurulmadığını kontrol edin ve yeniden deneyin."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depolama konumu, eğik çizgiyle ayrılmış bölüm/klasör biçiminde olmalıdır."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bir bağlantı eklemek için ", _interpolate(_named("link")), " ile iletişime geçin."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Desteği"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Zendesk kayıtlarınız otomatik olarak eşitlenecek. Seçilen kayıtları hariç tutmayı seçebilirsiniz."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asya Pasifik (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asya Pasifik (Seul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asya Pasifik (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asya Pasifik (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asya Pasifik (Sidney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (Orta)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avrupa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avrupa (Zürih)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avrupa (İrlanda)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avrupa (Londra)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu bölgesi"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orta Doğu (BAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu bölgesi seçin"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güney Amerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABD Doğu (Virginya)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABD Doğu 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABD Batı (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı zaten eklendi"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı başlat"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca şu özelliklere sahip görüşmeleri senkronize edin:"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanal bir, kanal iki"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu bağlantının benzersiz kimliği ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ay"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 yıl"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 ay"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 ay"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin olmayan görüşmeler, seçilen tutma süresi dolduktan sonra silinir. Gönderilen yorumlar Aktivite ve Pano görünümlerinde görünmeye devam eder."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutma süresi"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutma dönemi seçin"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlemin geri alınamayacağını unutmayın."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunun, Zendesk QA'dan bu bağlantı için tüm yardım masası verilerini kaldıracağını (görüşmeler, alanlar ve etiketler dahil) anlıyorum."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderilen değerlendirmeler etkilenmeyecek ve belirli görünümlerde görünmeye devam edecek."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["birinci grup, ikinci grup"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahil et"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etkin geliştirme"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu tümleştirme hâlâ ", _interpolate(_named("activeDevelopment")), ". Bazı ayrıntılar eksik olabilir (örneğin ekler veya özel alanlar)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posta kutusu bir, posta kutusu iki"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etiket bir, etiket iki"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API belirteci"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API belirteci şifresi"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk’te “Kayıt Alanı Adları”nı Ayarlar → Kayıt alanları altında bulun"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout'ta Settings → Custom fields altında \"Custom Field Names\"nı bulun"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomer'da Ayarlar → Klasses → Görüşme altında \"Klass Görüşme Adları\"nı bulun"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canlı Temsilci üzerinde Ayarlar → Özel alanlar altında \"Özel Alan Adı\"nı bulun"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wix'te \"Özel Alan Adı\"nı Ayarlar → Özel alanlar altında bulun"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Numaralarını virgüllerle ayırın. Bu ID'leri Zendesk'te Yönetici → Kayıt alanları altında bulabilirsiniz."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk görüşmenizi değerlendirmek için yardım masası kullanıcılarınızdan birkaçını bağlayın. Endişelenmeyin, siz onları çalışma alanı üyesi olmaya davet etmediğiniz sürece onlara bildirim göndermeyeceğiz."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarıyla tümleştirildi"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye ekleyin"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmelerinizi almaya başlayacağız."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alan: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yardım masası tanımlayıcı: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantınız doğrulanamadı"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazır. Bu sizin yeni bağlantınız."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazı bağlantılar güncellenmedi veya devre dışı bırakıldı. Bu bağlantıları güncellemeyi veya listenizden kaldırmayı deneyin."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API istemci kimliği"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API istemci şifresi"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ayarlar → Hesap → API ve SDK'ler altından yeni bir API belirteci oluşturun. Kullanılacak yeniden yönlendirme URL'si ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap no"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlamak için yardım masanızı seçin"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili telefon hesabınız AB'den veya Avustralya'dan hizmet verecek şekilde ayarlandıysa, bunu güncelleyin."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Oturum açmak için kullandığınız e-posta ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son kullanıcının adını, e-posta adresini ve telefon numarasını gizleyin. Banka kimlik bilgileri tüm yardım masası bağlantıları için zaten maskelenmiş."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz tümleştirme yok. Şimdi bir tane ekleyin."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu rollere gereksiniminiz var:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı oluştur"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir bağlantı ekleyin"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu seçeneğin işaretini kaldırmak, bu bağlantıyı manuel olarak yalnızca belirli çalışma alanlarına eklemenize olanak sağlar"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm mevcut çalışma alanlarına bağlan"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanları"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtarı"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API anahtar şifresi"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanallar"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bağlantısı silindi"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı adı"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma zamanı:"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API belirtecinizi aşağıdan alabilirsiniz."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT eşiği"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " bağlantısını sil"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bağlantıyı kaldırmak için \"bağlantıyı sil\" yazın"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı sil"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı veri kaynaklarınızı yönetin ve izleyin."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı düzenle"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hariç"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan adlarını virgüllerle ayırın"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenemedi"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümleştirmenize benzersiz bir ad verin"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş seçenekleri gizle"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassas veri alanlarını gizle"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ek URL'lerini hiçbir zaman saklama"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekleri yok say"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içeriğini asla saklamayın (daha sonra istendiğinde yardım masanızdan alabilirsiniz)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme içeriğini yok say"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme konusunu hiçbir zaman kaydetme (daha sonra istendiğinde yardım masanızdan alabilirsiniz)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu satırını yok say"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin değil"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sizin ", _interpolate(_list(0)), " alt alan"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili telefon bölgesi"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen gelen kutusu ekip kimliği"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Arama ekibinizin sayısal kimliği"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam ediyor..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta Kutuları"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı manuel olarak güncelle"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri verilerini maskele"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı yok"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassas alan adı"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassas alan no"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı yenile"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı yenile"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce deneme tahtasına bağlan"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Şununla bağlantı kuruluyor ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş seçenekleri göster"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca puanı ayarlanan eşiğe eşit veya daha düşük puanı olan görüşmeleri kabul edin"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir CSAT eşiği uygula"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca şu görüşmeleri eşitleyin..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En son görüşmeleri alın"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı güncelle"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı güncellendi"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı adı"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masanız"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğinizi iptal etmek ve hesabınızı silmek için ", _interpolate(_named("link")), " ile iletişime geçin."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Desteği"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı sil"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Support (veya başka bir yardım masasında) bir kaydın silinmesi ilgili görüşme incelemelerini etkilemez. Bunları görüntülemeye ve yönetmeye devam edebilirsiniz."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA bir kayıt silindiğinde, ilişkili görüşme incelemeleri de silinir.", _interpolate(_named("line_break")), "Zendesk Support (veya başka bir yardım masası) bir kaydın silinmesi ilişkili görüşme incelemelerini etkilemez. Bunları görüntülemeye ve yönetmeye devam edebilirsiniz."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İncelemeleri yönetin"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumların silinmesi kalıcı bir işlemdir. Panolardaki verileri de etkiler."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm incelemeleri sil"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü sil"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm incelemeler silindi"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü sil"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinmiş kayıtlarla ilişkili incelemeler olduğunda, onları burada göreceksiniz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz yorum yok"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt numarasını ara"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kayıt Numarası: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinen görüşmeler"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varsayılan çalışma alanları silinemez. Bu çalışma alanını silmek için, ", _interpolate(_named("link")), " içinde başka bir varsayılan çalışma alanı seçin."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap ayarları"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tüm verileri kaldıracağını ve sileceğini"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu işlemin, çalışma alanı kullanıcıları ve bağlantıları da dahil bu çalışma alanıyla ilgili ", _interpolate(_named("removeAllData")), " anlıyorum.\n"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanını sil"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanını silmek için \"çalışma alanını sil\" yazın"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("workspaceName")), " çalışma alanını sil"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanını sil"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne yaptığınızdan emin olun veya önceden bir uzmana danışın."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan gözden geçirilecek kişinin, en sık rastlanan temsilciye veya mevcut atanan kişiye göre seçilip seçilmeyeceğini seçin."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En aktif temsilci"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan değerlendirilen"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıların kendi görüşmelerini değerlendirmelerine ve temsilcilerin değerlendirme yapmalarına izin verin"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı silindi"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı güncellendi"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha önce diğer değerlendirenler tarafından verilen değerlendirmeleri gizleyerek tarafsız not verin"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarafsız not verme"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunun, çalışma alanı kullanıcıları ve bağlantıları da dahil olmak üzere bu çalışma alanıyla ilgili tüm verileri kaldıracağını ve sileceğini unutmayın."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanını sil"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı siliniyor..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self revizyon"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihirli bağlantı"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut oturum açma yönteminiz devre dışı bırakılamıyor"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrulama yöntemleri güncellendi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli türde kimlik doğrulama yöntemlerine tüm kullanıcılar için izin verilir."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulama İşlemleri"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAP"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar ve çalışma alanları"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları oluşturup yönetin, kullanıcıları davet edin veya bağlayın."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfada kal"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfadan çık"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfadan çıkılsın mı ve tüm değişiklikler kaybedilsin mi?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik Doğrulama İşlemleri"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonelik"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçe aktarılacak görüşme yok. Yardım masanız ile birkaç görüşme yaparak başlayın, ardından onları analiz etmek için buraya dönün."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme bulunamadı"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sistem kategorisini silemezsiniz"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi sil"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorilerin silinmesi kalıcı bir işlemdir. Bu işlem, tüm kategori verilerini raporlamadan siler."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoriyi geri yüklemek için yeniden oluşturmanız gerekir."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi sil"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori ara"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik kategoriler, incelemede zaman kazanmak için puan kartına önerilen değerlendirmeleri otomatik olarak doldurun. Manuel kategoriler, gözden geçirenler tarafından sıfırdan doldurulur."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son güncelleme"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manüel"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım ve dil bilgisi"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlama"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okunabilirlik"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan çözüm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori bulunamadı"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden bulunamadı"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı bulunamadı"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir arama yapmayı deneyin veya filtreleri değiştirin"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kategoriler"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " puan kartı"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " puan kartı"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " puan kartı"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm puan kartları"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " puan kartı"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " durum"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " durum"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " durum"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm durumlar"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " durum"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tür"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tür"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tür"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm türler"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tür"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " çalışma alanı"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " çalışma alanı"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı bulunamadı"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " çalışma alanı"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanları"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " çalışma alanı"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), " ağırlığı, genel skora % ", _interpolate(_named("percentage")), " oranında katkıda bulunur"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartını sil"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartlarının silinmesi kalıcı bir işlemdir. Bu işlem, tüm puan kartı verilerini raporlamadan siler."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu puan kartını geri yüklemek için yeniden oluşturmanız gerekir."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartını sil"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son güncelleme"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin değil"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel nedenler"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Değerlendirme ölçeği güncellendi. ", _interpolate(_named("buttonStart")), "Değerlendirmeye başla", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA varsayılan olarak bir ikili ölçek belirler ama daha ayrıntılı bir ölçek de seçebilirsiniz:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kategoriye göre yapılandırılmış farklı değerlendirme ölçeği seçenekleri görmek istiyorsanız ", _interpolate(_named("link")), "na gidin"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati/Ton"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün bilgisi"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeklerini güncelleyerek:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki değerlendirme ölçeğiniz ve ilgili kategoriler arşivlenecek (silinmeyecek)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski ve yeni ölçeğiniz karşılaştırılabilir olmayabileceği için bazı pano verileri etkilenecek."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni ölçeğe mevcut değerlendirme kategorileri kurulumumu kopyala"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeklerini güncellemek istediğinizden emin misiniz?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı görüntüleme biçimini (sayı veya emoji) değiştirebilir, kategorileri zorunlu veya isteğe bağlı hale getirebilirsiniz."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayılar yerine emoji kullanın"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıların kategori atlamasına izin ver"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çözüm"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeği türü"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 çalışma alanı için kategoriler kopyalandı"]), _normalize(["için kategoriler ", _interpolate(_named("workspacesAmount")), " çalışma alanı kopyalandı"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı ayarları"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eğer ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteriden görüşmede bir sessizlik beklemesini istemediği durumlarda aramaları otomatik olarak etkinleştirin."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmede ayarlanan eşikten daha uzun süreliğine kesintiye uğrayan anlar var."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramalarda maksimum bağlantı eşiğini tanımlayın"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsizlik"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinen bilgiler yeni görüşmelerde ve filtrelerde görünmez. Panolardaki ve görüşmelerdeki geçmiş verilere zarar verilmez."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight içgörüsü silinemedi"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight içgörüsü silindi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight içgörüsünü sil"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli kelimeleri veya ifadeleri içeren görüşmeleri yazılı dökümlerde ve mesajlarda görüntüleyin."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Bu arama kaydedilecek\" vb. zorunlu açıklama bildirimi içermeyen aramaları otomatik olarak belirleyin."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu amaç, önceden belirlenmiş ifadelere olan gereksinimi ortadan kaldırarak, bağlamı dinamik olarak anlayan bir Büyük Dil Modeli (LLM) kullanarak aramada ses kaydı açıklama bildirimi belirlemeyi amaçlar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt açıklaması eksik"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteriden görüşmede bir sessizlik beklemesini istemediği durumlarda aramaları otomatik olarak etkinleştirin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hareketsizlik"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir aramada kesilen anları belirtin"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğinizde değişiklik yapmanız mı gerekiyor? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") Size yardımcı olmaktan mutluluk duyacağız."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilciler, BPO'lar, kanallar ve dillerde görüşmelerin %100'ünü otomatik olarak analiz edin. Yapay zeka, hizmeti geliştirmek için sorunları, bilgi boşluklarını ve koçluk fırsatlarını algılar."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA %100 kapsamı"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci geri bildirimi ve koçluk"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight yapay zeka içgörüleri"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş raporlama ve panolar"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu analizi ve filtreleme"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğe bağlı çeviriler"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamaları ve hedef ayarını inceleyin"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme ve testler"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Kalite Kontrolü"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional'da her şeyin yanı sıra"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş (eski)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik başarısızlıkların anında bildirilmesi için webhook'lar"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri ambarı tümleştirmesi"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Hizmet Bulutu tümleştirmesi"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel tümleştirme geliştirme"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel müşteri başarı yöneticisi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün ve mühendislik ekiplerimize Slack üzerinden doğrudan erişim"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite'teki her şeyin yanı sıra"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay Zeka Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%100 kapsam için Otomatik Kalite Kontrolü"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilerden anlam çıkarmak için yapay zeka tabanlı Görüşme Bilgileri"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka destekli yardım masası veri zenginleştirme ve filtreleme"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight: Değerlendirilmesi gereken etkileşimler için otomatik algılama"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu analizi ve filtreleme"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka tabanlı performans raporlama ve içgörü"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğe bağlı görüşme çevirisi"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman izlemeyi gözden geçir"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçme oranı analizleri"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka Suite'teki her şeyin yanı sıra"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce ve Genesys tümleştirmesini içeren Enterprise uygulama paketi"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel tümleştirme geliştirme"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri ambarı tümleştirmesi"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik başarısızlıkların anında bildirilmesi için webhook'lar"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel müşteri başarı yöneticisi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün ve mühendislik ekiplerimize doğrudan erişim"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erken erişim ve yol haritamız üzerinde etki"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik değerlendirme atamaları ve hedef ayarı"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefe yönelik koçluk ve analiz"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğrenim yönetimi için testler"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı rolü ve izin yönetimi"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hassas veri maskeleme"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş filtreleme seçenekleri ve görüşme araması"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden fazla değerlendirme, ağırlıkları ayarlanabilir ve otomatik başarısız seçeneklerine sahip özelleştirilebilir puan kartları"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir yerde görüşmeleri değerlendirmek için tarayıcı uzantısı"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden analizi"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcileri, ekipleri, BPO'ları karşılaştırmak için performans raporlama"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT anketi ve müşteri geri bildirim analizleri"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter'daki her şeyin yanı sıra"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (eski)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme başına birden fazla değerlendirme ölçeği"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack tümleştirmesi"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka tabanlı etkileşimli Görüşme Bilgileri"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamaları değerlendir"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk pinleri"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koçluk hizmeti oturumu"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duygu analizi ve filtreleme"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme karmaşıklığına göre filtreleme"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görüşme içgörüleri"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullu puan kartları"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden analizi"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş yapay zeka tabanlı CSAT analizleri"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verme kalibrasyonu"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir yerde görüşmeyi değerlendirmek için tarayıcı uzantısı"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilmesi gereken görüşmeleri keşfetmeye odaklanın"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğe bağlı görüşme çevirisi"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman izlemeyi gözden geçir"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel plan"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç (eski)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınırsız sayıda görüşme değerlendirmesi"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV dışa aktarma"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşler arası ve kendi kendine değerlendirmeler"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağırlığı ayarlanabilir ve otomatik başarısız seçeneklerine sahip puan kartları"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güçlü filtreleme seçenekleri"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük, haftalık veya aylık değerlendirme hedefleri"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT anketleri ve temel analizler"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panolar"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcileri, ekipleri, BPO'ları karşılaştırmak için raporlar"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı rolü ve ayrıcalık yönetimi"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA aboneliğimde bir değişiklik yapmak istiyorum.\n\nBir seçeneği vurgulayın veya belirtin ve değiştirmek istediğiniz lisans sayısını belirtin:\n[X lisansı eklemek] [X lisansını kaldırmak] [Zendesk KG planına yükseltmek] istiyorum\n\nLütfen şirket adınızı ve Zendesk QA hesabınızla ilişkili adı ve bu değişikliği işleme alabilmemiz için bilmemiz gereken diğer bilgileri bizimle paylaşın."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA abonelik değişikliği"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Zendesk QA aboneliği satın almak istiyorum.\n\nLütfen şirket adınızı, Zendesk QA hesabınızla ilişkili adı ve bu talebi işleme alabilmemiz için bilmemiz gereken diğer bilgileri bizimle paylaşın:\n\nLütfen satın almadan önce yanıtlanmasını istediğiniz belirli soruları da bizimle paylaşın:\n\nTeşekkürler!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA aboneliği satın alımı"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğiniz ", _interpolate(_named("date")), " tarihinde sona eriyor (", _interpolate(_named("days")), " kaldı)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deneme süreniz ", _interpolate(_named("date")), " tarihinde sona eriyor (", _interpolate(_named("days")), " kaldı)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 gün"]), _normalize([_interpolate(_named("n")), " gün"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her ayın", _interpolate(_named("dayOfMonth")), ". gününde, ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("billingDate")), " tarihinde ", _interpolate(_named("card")), " üzerinden (son kullanma tarihi ", _interpolate(_named("expires")), ") fatura ediliyor."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ile biten"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Süresi doldu"]), _normalize(["1 gün sonra sona eriyor"]), _normalize([_interpolate(_named("expiresIn")), " gün sonra sona eriyor"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Başlangıç fiyatına ", _interpolate(_named("n")), " kullanıcı lisansı dahildir"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Başlangıç: ", _interpolate(_named("sum")), "/ay"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ay"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanıcı / ay başına"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ YIL"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ ay"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["üç ay"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zamanlanmış iptali kaldır"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA'yı kullanmaya devam etmek istiyorsanız, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğiniz ", _interpolate(_named("date")), " tarihinde iptal edildi."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğiniz ", _interpolate(_named("date")), " tarihinde sona erecek."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " günlük deneme için ek bir ücret ödemeden!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanılıyor"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " kullanıcınız bulunuyor. Lisans sayısı bundan düşük olamaz."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonelik ", _interpolate(_named("time")), " tarihinde devam edecek"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yıl"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mevcut Zendesk QA planınız (", _interpolate(_named("plan")), ") yapay zeka tabanlı özellikleri içermiyor. Ses zekası özelliklerinin tüm gücünden yararlanmak için ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aboneliğinizi iptal etmek için bizimle iletişime geçin ", _interpolate(_named("link")), " veya bizimle sohbet edin."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalite yönetim planı"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalite yönetimi"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonelik güncellendi"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi kartı ekle"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm planlar"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalandırma"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalama bilgilerini görün"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalama aralığı"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği iptal et"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalama döneminin sonunda aboneliğiniz iptal edilecektir."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimle iletişime geçin"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenlemek için bizimle iletişime geçin"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli plan"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel fiyatlandırma"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seviyeyi Düşür"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık (%15 İndirimli)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık ödeme yap"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği düzenleyin"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planları keşfet"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz deneme"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel olarak faturalandı"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son sipariş özetini inceleyip sonraki adımda onaylayabilirsiniz."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En popüler"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin bir ödeme yönteminiz yok."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemede"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planını değiştirmek için mevcut aboneliğinizi devam ettirin"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği duraklat"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üç ayda bir"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücret"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği yeniden etkinleştir"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği devam ettir"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisanslar"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denemeyi şimdi başlat"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal edildi"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenilenmeyen"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duraklatıldı"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonelik durumu"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneme"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükselt"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği yükselt"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru (CSAT)"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bir dakikanızı ayırıp müşteri destek deneyiminiz hakkında geri bildirim gönderin. Şimdiden teşekkürler — ", "{", "{", "agentName", "}", "}", " geri bildiriminize değer veriyor!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merhaba ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "kullanıcısının CSAT anketi"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["{", "{", "agentName", "}", "}", " adlı temsilciden gördüğünüz desteği nasıl değerlendirirsiniz?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bu bizim için çok önemli. ", _interpolate(_list(0)), " herkes için daha iyi hale getirmek amacıyla, geri bildiriminizi kullanacağız."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler hakkında bilgi edinin"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket türünü seçin"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu daha sonra değiştirilebilir."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden fazla dilde sunulan otomatik CSAT anketleriyle müşteri memnuniyetini ölçün ve artırın."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket oluşturulmadı"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket oluştur"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket adı"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket oluşturuldu"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anahtar kelimeyi değiştirmeyi deneyin veya burada listelenmesini isterseniz ", _interpolate(_named("contact_us"))])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize bildirin"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil bulunamadı"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aşağıdaki listeden bir veya daha fazla dil seçin. Kullanmak istediğiniz dili burada listede görmüyorsanız ", _interpolate(_named("contact_us")), "."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ekle"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize bildirin"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ekle"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Son yayınlama: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Son kaydedilme: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " soru"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş sorusu"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol ölçek etiketi"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta konusu"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedenler"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağa ölçeklenen etiket"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir ankette eksik çeviri varsa, bunun yerine temel dilin metinleri gösterilir"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevirileri uygula"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksik Çeviriler"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tüm çevirilerinizi uygulamadan önce mutlaka inceleyin. İndirilen dosyada değişiklikleri yapın, kaydedin ve gerekirse yeniden karşıya yükleyin. Bir belirteç ise ", _interpolate(_named("token_example")), " bir etiket olarak görünmüyorsa, yanlış bir formattadır ya da belirteçleri desteklemeyen bir alandadır."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevirileri incele"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercüme edilmiş"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi özetler ve yorumlar için önerileri gösterir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA yapay zeka yardımı (İngilizce)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türleri oluşturulduktan sonra düzenleyebilirsiniz"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu tür zaten mevcut. Farklı bir ad seçin."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel türü Anket türü açılır menüsünde seçilebilir bir seçenek olarak kaydedin ve oluşturulan tüm anketleri panoda gruplandırın."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelimeyi/kelimeleri yazın ve kaydetmek için Enter'a basın"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel türleri düzenle"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür adı boş bırakılamaz"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türü tanımlayın"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel tür kaydedilmedi. Kaydetmek için Enter tuşuna basın."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderenin e-posta adresi"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderenin adı"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Şu ifadeye ne ölçüde katılıyorsunuz: “", _interpolate(_named("agentName")), " sorunumu çözmeyi kolaylaştırdı” mı?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " tarafından bu zamana dek nasıl kolaylıkta yardım aldınız?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel olarak, bugün sorununuzu çözmeniz ne kadar kolay oldu?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibimizle etkileşim kurmak ne kadar kolay oldu?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Gizlilik Politikası) URL'si (isteğe bağlı)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet Koşulları URL'si (isteğe bağlı)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " tarafından aldığınız desteği nasıl değerlendirirsiniz?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu talep için hizmetimiz nasıldı?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek temsilcisinden ne kadar memnun kaldınız?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bugün ", _interpolate(_named("agentName")), " tarafından aldığınız hizmetten ne kadar memnunsunuz? "])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel soru"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kendi özel ", _interpolate(_named("type")), " sorunuzu tanımlayın..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel soru yazın"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL'leri özelleştirin"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta mesajınızı ayarlayın"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir özel soru türünün adı düzenlenirse, bu soru türü kullanılan tüm anketlerde güncellenir ve toplanan veriler etkilenmez."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel türleri düzenle"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta konusu"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş sorusu"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " soru"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni özel tür oluştur"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerik"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kişiselleştirme belirteci"]), _normalize(["Kişiselleştirme belirteçleri"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kişiselleştirme belirtecini sürükleyip aşağıdaki alana bırakın."]), _normalize(["Kişiselleştirme belirteçlerini sürükleyip aşağıdaki alanlara bırakın."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Görüşme ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda CSAT anketleri yalnızca e-postayla gönderilebiliyor. Size yardım masası CSAT anketleri, SMS anketleri vb. daha fazla fırsat sunmak için çalışıyoruz."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bağlantıyı eşitleyin veya yenileyin"]), _normalize(["Bağlantıları eşitleyin veya yenileyin"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı eşitleyin veya yenileyin ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bağlantı doğru bir şekilde eşitlenmedi"]), _normalize(["Bağlantılar doğru bir şekilde eşitlenmemiş."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ilgili onay kutusunun işaretini kaldırın ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi doğrula ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı etkinleştirmek için en az bir anketi dışa döndürme kuralı ekleyin"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu bağlantı şu anda devre dışı. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu bağlantı hassas istemci verilerini maskeler. Anket göndermeyi etkinleştirmek için,\nAyarlar içinde ", _interpolate(_named("link")), "."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu bağlantı için anket göndermek için ek izinler gerekir.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi kimliği doğrula"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA, dahili telefon için daha fazla izin gerektiriyor."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " ve ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 saat"]), _normalize([_interpolate(_named("n")), " saat"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dakika"]), _normalize(["1 dakika"]), _normalize([_interpolate(_named("n")), " dakika"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["saat"]), _normalize(["saat"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anket gönderme kurallarını yapılandırmak için ", _interpolate(_named("link")), "."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" gün"]), _normalize(["gün"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket bağlantılarının süresi doldu"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıların süresi içinde"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradan çok zaman geçtiyse anketlerin yanıtlanmasını engelle"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " anket ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketleri virgüllerle ayırın veya yazdıktan sonra Enter tuşuna basın. Her birinin dahili telefondaki gibi tam olarak listelendiğinden emin olun."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketin gönderileceği koşulları belirleyin."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket gönderme kuralları"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket takip gönderme kuralları"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anket ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " zaten var.\nÇakışmayı, buradaki veya ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["için eşleştirme kurallarına sahip bir bağlantı oluşturdunuz ", _interpolate(_named("name")), " anket. Çakışmayı, kuralları değiştirerek veya ", _interpolate(_named("link")), " içinde çözümleyin"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka bir kural ekle"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka ekle"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul seçin"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer arayın"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket ekle"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aynı kurallarla eşleşen"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değer seçin"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta içinde gönderildi"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip e-postası içinde gönderildi"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü kod parçası ve e-posta takibi"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodu kopyala"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA anketlerini Zendesk kurulumunuza ekleyerek e-postalarınıza ekleyin."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulum kılavuzu"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk'i aç"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod parçası önizlemesi"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod parçası kodu panoya kopyalandı"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod parçası kopyalanamadı"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü kod parçası"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ankette kaydedilmemiş değişiklikler var. Kod parçasının en son güncellemeleri yansıttığından emin olmak için kodu yeniden kaydedin."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı ekle"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz bağlantı eklenmedi"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılarınızla e-posta, canlı destek, telefon aramaları ve daha fazlasını Zendesk QA aktarın"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket teslimi hakkında bilgi edinin"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket gönderme kuralları"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket takip gönderme kuralları"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka kuralı ekle"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kural ekle"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik doğrula"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + e-posta takibi"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Telefon hesabınızı Zendesk QA müşteri memnuniyeti anketi ile bağlayın"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu yolla teslim et"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi kim ve ne zaman alacak"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuralları düzenle"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirildi"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama içi anketler müşterilerinizin canlı destekten ayrılmadan anket doldurmalarına olanak sağlar."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili Telefonu Başlat"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı başına teslim kuralı"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anket ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " zaten var.\nÇakışmayı, bağlantıyı devre dışı bırakarak veya buradaki ya da ", _interpolate(_named("link")), " bağlantısından çözümleyin"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketin gönderileceği koşulları belirleyin"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("createdDate")), " tarihinde ", _interpolate(_named("name")), " tarafından oluşturuldu"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bunu kaldır: ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu dilin kaldırılması, artık hiçbir yanıtlayan tarafından görülemeyeceği ve çevrilen tüm metinlerin silineceği anlamına gelir."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dili kaldır"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatif olarak, dili anketten gizleyebilirsiniz. Bu, tüm çevirilerinizi yanıtlayanlara göstermeden yerinde tutmanızı sağlar."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Son düzenleme ", _interpolate(_named("name")), " tarafından ", _interpolate(_named("createdDate")), " tarihinde gerçekleştirildi"])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket dilleri hakkında bilgi edinin"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diğer dilleri eklemeden önce, Tarz ve İçerik adımlarında değişiklikleri tamamladığınızdan emin olun. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ekle"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrilen alanlar"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlayanlar görebilir"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri dosyasını indir"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dili kaldır"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" bir çeviri dosyası indir"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevirileri incele"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Çeviri tamamlandı. Herhangi bir değişiklik yapmak için ", _interpolate(_named("review_link")), " veya ", _interpolate(_named("download_link")), "."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" bir çeviri dosyası indir"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksikleri inceleyin"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bazı içerikler henüz çevrilmedi ve İngilizce ile değiştirilecek. Eksik çevirileri eklemek için ", _interpolate(_named("review_link")), " veya ", _interpolate(_named("download_link")), "."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi kaydet"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anket kaydedilmemiş değişiklikler içeriyor. Çeviri dosyasını en son değişikliklerle birlikte indirmek için ", _interpolate(_named("save")), "."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri dosyasını karşıya yükleyin"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercih edilen değerlendirme ölçeğini seçin"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterilere değerlendirme nedeni seçebilecekleri bir açılır liste verir"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojiler"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metni sola ölçeklendir (isteğe bağlı)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numaralar"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden ekle"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden boş bırakılamaz"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu neden zaten var"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanınızın nedenlerini içeren açılır menüyü göster"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir neden silindiğinde ve aynı adla yeniden eklenince veya bir neden yeniden adlandırılınca her zaman yeni bir giriş oluşturulur. Sonuç olarak, panonuzda görüntülenen verileri etkiler."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedeni sil"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pano raporlamasında silinme nedeni kalır ancak yeni anket katılımcıları bunu göremez. Bu durum ilgili çeviriler için de geçerlidir."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden sil"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bir nedeni silin \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metni sağa ölçeklendir (isteğe bağlı)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5'li bir ölçekte, 1 ve 2 olumsuz puanlar olarak değerlendirilir; 3, nötr; 4-5 olumlu"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçek metni ekle"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " ölçek"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeği"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA görüşmelerinizi içe aktarıyor. Bu işlem 24 saat sürebilir."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, anket boyunca kişiselleştirme belirteci olarak kullanılacak."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka adı"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düğmeler ve bağlantılar"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketinizin görünümünü özelleştirin"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo arka planı"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, PNG ve GIF biçimleri desteklenir"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarz"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logoyu karşıya yükle"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu tarz bir şekilde oturumu kapatın:"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıların geri bildirimlerini gönderdikten sonra görecekleri bir mesaj girin"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkür mesajı"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış onaltılık renk kodu"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresi yanlış"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan boş bırakılamaz"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanlış url"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünmez:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görülebilir:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma tarihi"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderilen anketler"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 30 gün içinde gönderilen anketler"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket silindi"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket çoğaltıldı"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme e-postası gönderildi"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket yayınlandı"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket güncellendi"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket duraklatıldı"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duraklat"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi duraklat"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket önizlemesinin gönderileceği e-postayı girin"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sizin", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizlenecek dili seçin"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme e-postası gönder"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresinize örnek bir anket gönderin. Anket önizlemesinden gelen değerlendirmeler depolanmayacak."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " anketi önizle"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web'de e-postayla anket önizlemesi"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yayınla"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam et"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak olarak kaydet"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marka adı"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markaya özel ad, Stil oluşturma adımında eklenebilir"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri adı"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support temsilcisi"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duraklatıldı"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " için CSAT anketi"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedilmedi"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi güncelle ve devam ettir"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu e-postayı doğru görmüyorsanız ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldığım destekten memnun değildim, çünkü..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tarafından desteklenmektedir"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden seçin"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme özeti"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örneğin: Müşteri ödeme yöntemini güncellerken sorunlar yaşadı. Temsilci yardımcı olabilecek bir makale paylaştı. İşe yaradı."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA Yapay Zekası tarafından desteklenmektedir"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmet Koşulları"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildiriminiz için teşekkür ederiz!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanınızın ana nedeni nedir?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu puanı neden verdiğinizi bize bildirin..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüksek efor"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük efor"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi açmak için buraya tıklayın"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel sorunuz burada görünür"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " deneyiminize verdiğiniz puan:"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geribildirim gönder"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliği kaldır"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç memnun değilim"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çok memnun"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut anket durumunun ve yayınlanma kurallarının, anket kopyasına dahil edilmeyeceğini unutmayın"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anketi kopyala “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü e-posta kod parçasını kullanıyorsanız, anketi duraklatmadan önce e-posta kodunu Zendesk'ten kaldırdığınızdan emin olun"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket duraklatıldığında hiçbir yanıt alınmaz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi duraklat"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi duraklatmadan önce Zendesk'ten kod parçasını kaldırdığınızdan emin olun."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi sil"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü e-posta kod parçasını kullanıyorsanız, anketi silmeden önce e-posta kodunu Zendesk'ten kaldırdığınızdan emin olun."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem anketi kalıcı olarak silecek. Anketi alan kullanıcılar hala formu doldurabilir ve anketle ilgili tüm veriler Zendesk QA'da kalır."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anketi sil ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi silmeden önce Zendesk'ten kod parçasını kaldırdığınızdan emin olun."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyguladığınız değişiklikler yeni katılımcılar tarafından görülecek."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi yayınla"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi yayınladıktan sonra gömülü kod parçasının en son sürümünü kopyaladığınızdan emin olun."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gömülü e-posta kod parçasını kullanıyorsanız, anketi devam ettirdikten sonra e-posta adresini Zendesk'e kopyalamayı unutmayın."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi göndermeye ve yanıtları toplamaya devam edin."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi güncelle ve devam ettir"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi devam ettirdikten sonra gömülü kod parçasını Zendesk'e eklediğinizden emin olun."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi Güncelle"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketi güncelledikten sonra gömülü kod parçasının en son sürümünü kopyaladığınızdan emin olun."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu anketi silmek istediğinizden emin misiniz? Bu eylem geri alınamaz."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket silinsin mi?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneme süreniz sona erdi ama tam bir hesaba yükselterek Zendesk kalite güvencesinden yararlanmaya devam edebilirsiniz."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneme süreniz sona erdi ama tam bir hesaba yükselterek Zendesk kalite güvencesinden yararlanmaya devam edebilirsiniz. Bunu yapmak için yerel yöneticinizle konuşun."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneme süreniz sona erdi"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi yükseltin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz denemeniz sona erdi."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA’nın tam sürümünü kullanmaya devam etmek için ödeme ayrıntılarınızı girin."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA’nın tam sürümünü kullanmaya devam etmeniz için hesap yöneticinizin ödeme ayrıntılarını girmesi gerekiyor."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi yönetin"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bir kullanıcı"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " tarafından ", _interpolate(_named("date")), " tarihinde oluşturuldu"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " atama"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hâlâ bunun üzerinde çalıştığınız anlaşılıyor. Değişiklikleri kaydetmeden çıkarsanız bu zamana kadar eklediğiniz tüm bilgileri kaybedersiniz."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu atama her ", _interpolate(_named("weekDay")), " günü tekrar edilecek"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu atama her ", _interpolate(_named("weekDay")), " günü tekrar edilecek"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tüm değerlendirilenler bir döngüde ele alınır"]), _normalize(["Tüm değerlendirilenler ", _interpolate(_named("nr")), " döngüde yer alır"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " görüşme"]), _normalize([_interpolate(_named("n")), " görüşme"]), _normalize([_interpolate(_named("n")), " görüşme"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["/ ", _interpolate(_named("nr")), " temsilci"]), _normalize(["/ ", _interpolate(_named("nr")), " temsilci"]), _normalize(["/ ", _interpolate(_named("nr")), " temsilci"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["katılımcı"]), _normalize(["katılımcı"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " döngü başına görüşme"]), _normalize([_interpolate(_named("n")), " döngü başına görüşme"]), _normalize([_interpolate(_named("n")), " döngü başına görüşme"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üst üste değerlendirilen başına", _interpolate(_named("reviewsCount"))])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["değerlendirilen"]), _normalize(["değerlendirilenler"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["değerlendiren"]), _normalize(["değerlendirenler"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenler"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenler"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanı kullanıcıları"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilciler hariç tüm çalışma alanı kullanıcıları"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Döngü ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yinelenmeyen"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " atama"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiriliyor"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar hakkında bilgi edinin"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödev oluştur"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem atamayı kalıcı olarak silecek ve verilerini panodan kaldıracak. Bununla ilgili tüm değerlendirmeler Zendesk QA'da kalacak."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Atamayı sil ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama silindi"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama çoğaltıldı"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz atama eklenmedi"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama oluşturulamadı"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama adı"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Döngü yenilenir ve yeni bir görev listesi oluşturulur. Zaten eklenmiş olan yorumlar, hedef için hala hesaba katılıyor."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hemen"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yayınla"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " tarihinde sonraki döngünün başında"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler ne zaman yayınlanır?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu atamada yapılan değişiklikler bu atamayla yayınlanacak"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama etkin olarak ayarlansın mı?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama başlangıç gününde yinelenen"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Cuma günü"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Cuma günü"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Cuma günü"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Cuma günü"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Cuma günü"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Pazartesi günü"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Pazartesi günü"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Pazartesi günü"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Pazartesi günü"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Pazartesi günü"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Cumartesi günü"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Cumartesi günü"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Cumartesi günü"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Cumartesi günü"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Cumartesi günü"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Pazar günü"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Pazar günü"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Pazar günü"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Pazar günü"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Pazar günü"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Perşembe günü"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Perşembesi"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Perşembesi"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Perşembesi"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Perşembe günü"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Salı"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Salı"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Salı"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Salı"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Salı"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ilk Çarşambası"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın ikinci Çarşambası"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın üçüncü Çarşambası"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın dördüncü Çarşambası"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın son Çarşambası"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel yineleme"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her ayın 1'i"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saniye"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üçüncü"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dördüncü"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yinelemeler"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta içi"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç yok"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat Dilimi"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Döngü ayarları"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama adı"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel bilgiler"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her atama döngüsünün başında, değerlendirme için önceden belirlenmiş bir görüşmeler listesi oluşturulur."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş Ayarlar"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnceleme hedefi karşılanmadığında yeni görüşmeleri otomatik olarak getir"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüzde (%) inceleme hedefleriyle otomatik alma mümkün değildir"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir tam sayı olmalı, ondalık sayı değil"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni görüşmelere izin ver (İnceleyici rolü ve üzeri)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler değiştiriliyor"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnceleyenlerin kendi görüşmelerinin incelemeye atanmasına izin ver"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirme"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirmeler"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum değerlendirme sayısı en az 1 olmalıdır"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her değerlendirilen için minimum değerlendirme belirtin"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["görüşmeler"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yüzde (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["değerlendirmeler"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toplam değerlendirme"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her değerlendirenin belirli sayıda değerlendirme yapması gerekir"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenlerin belirli sayıda görüşmeyi değerlendirmesi gerekir"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum değerlendirme sayısı en az 1 olmalıdır"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme hedefi %1 – %100 arasında olmalıdır"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefi değerlendir"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her değerlendirilenin, toplamda minimum sayıdaki görüşmesi değerlendirilmiş olmalıdır"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenler paylaşılan havuzdan görüşmeleri seçebilir"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenlere paylaşılan havuzdan görüşme atanır"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama yaklaşımı"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm botlar"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanı katılımcıları"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanı kullanıcıları"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenleri ekle"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimler değerlendirilecek?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenleri seç"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İncelenecek kişileri seçin"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hariç tutmalara yalnızca tüm çalışma alanı kullanıcıları veya bir grup seçildiğinde izin verilir"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenler ve değerlendirenler"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mevcut: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı varsayılanı"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangi tür kullanıcılar değerlendirilecek kişiler olarak seçilmelidir?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En aktif temsilci"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcı"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenleri hariç tut (isteğe bağlı)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hariç tutulacak kullanıcıları seçin"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenleri hariç tut (isteğe bağlı)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hariç tutulacak kullanıcıları seçin"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilciler hariç tüm çalışma alanı kullanıcıları"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenleri ekle"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeleri kim yapacak?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenleri seçin"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanında kendi kendine değerlendirmelere izin verilmiyor"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirmelere izin ver"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanında kendi kendine değerlendirmelere izin verilmiyor."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bugün eşleşen görüşme"]), _normalize(["Bugünün eşleşen görüşmeleri"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödev bugün başlamış gibi gösteriliyor."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelecek sayılar değişiklik gösterebilir."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilecek kişi seçilmedi"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirecek kişi seçilmedi"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değerlendirilen"]), _normalize(["Değerlendirilenler"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değerlendiren"]), _normalize(["Değerlendirenler"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik getirme: yeterli görüşme değilse izin verilir"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren cuma günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren pazartesi günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren cumartesi günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren pazar günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren perşembe günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren salı günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDateTime")), " tarihinden itibaren çarşamba günleri ", _interpolate(_named("cycleLength"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Günlük olarak saat ", _interpolate(_named("startDateTime")), " itibariyle"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Başlangıç ", _interpolate(_named("startDateTime")), " ve bitiş ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tarih koşulu"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " görüşme ve yardım masası koşulları"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Görüşme yok ve ", _interpolate(_named("count")), " yardım masası koşulu"]), _normalize(["Görüşme yok ve ", _interpolate(_named("count")), " yardım masası koşulları"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " görüşme ve yardım masası yok koşulu"]), _normalize([_interpolate(_named("count")), " görüşme ve yardım masası yok koşulu"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yaklaşım: Görüşmeleri seçen değerlendiren kişiler"]), _normalize(["Yaklaşım: Değerlendirenlere atanan görüşmeler"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam etmek için özette vurgulanan hataları düzeltin"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef: Değerlendirenlerin tüm görüşmeleri değerlendirmeleri gerekiyor"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hedef: Değerlendirenlerin bir görüşme değerlendirmeleri gerekiyor"]), _normalize(["Hedef: Değerlendirenlerin ", _interpolate(_named("count")), " görüşme değerlendirmesi gerekiyor"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hedef: Değerlendirenlerin ", _interpolate(_named("count")), "% görüşme değerlendirmeleri gerekiyor"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef: Değerlendirme hedefi belirlenmedi"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef: Her değerlendirilenin tüm görüşmelerinin değerlendirmesi gerekir"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hedef: Her değerlendirilenin toplam görüşmeleri üzerinden ", _interpolate(_named("count")), " görüşmesinin değerlendirilmiş olması gerekir"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hedef: Her değerlendirilenin toplam görüşmeleri üzerinden ", _interpolate(_named("count")), "% görüşmesinin değerlendirilmiş olması gerekir"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hedef: Her değerlendiren bir değerlendirme yapmalıdır"]), _normalize(["Hedef: Her değerlendiren ", _interpolate(_named("count")), " değerlendirme yapmalıdır"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değiştirme: Değerlendirenler görüşmeleri değiştiremez"]), _normalize(["Değiştirme: Değerlendirenler görüşmeleri değiştirebilir"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendi kendine değerlendirmeler: Değerlendirenlere kendi görüşmeleri atanabilir"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirlenmiş tarih koşulu yok"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama adı belirtilmedi"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özet"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullar"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " günü ", _interpolate(_named("dateTime")), " saatinden itibaren"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki Haftada Bir"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asla"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftalık"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Döngü uzunluğu"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin olarak ayarla"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak olarak kaydet"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama Taslak olarak kaydedildi"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama Etkin Değil olarak ayarlandı"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin Değil olarak ayarla"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödev oluştur"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama oluşturuldu"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin olarak işaretle"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin değil olarak işaretle"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Atama için bekleyen güncellemeler sonraki döngüde geçerlilik kazanacak ve ", _interpolate(_list(0)), " tarihinden itibaren geçerli olacaktır."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri yayınla"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin değil"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemede"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı güncelle"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama güncellendi"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin mi yoksa botun mu görüşmeyi sonlandırdığını analiz eder."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin görüşmeyi kibarca sonuçlandırıp sonuçlandırmadığını analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin sonlandırılıp sonlandırılmadığını analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hem temsilci hem de bot etkileşimlerindeki dil bilgisi, imla ve stil hatalarını analiz eder."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin dil bilgisi, imla ve stil hatalarını analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin dil bilgisi, imla ve stil hatalarını analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım ve dil bilgisi"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi temsilci ve bot selamlamaları için analiz eder."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipik selamlama ifadeleri için tüm görüşmeyi analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipik selamlamalar için tüm görüşmeyi analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterilerin duygularını anlayıp anlamadığını analiz eder"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteri duygularını yansıttığı veya \"kabul et\", \"tanı\" gibi anlamlı kelimeler kullandığı durumları belirler"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterinin sorgusunu veya endişesini anlayıp anlamadığını analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterinin sorgusunu veya endişesini anlayıp anlamadığını analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlayış"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir metnin ne kadar kolay anlaşılabileceğini, kelime karmaşıklığını ve cümle uzunluğunu dikkate alarak analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir metnin ne kadar kolay anlaşılabileceğini, kelime karmaşıklığını ve cümle uzunluğunu dikkate alarak analiz eder"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu mesaj, 1 aşırı uzun cümle içeriyor ve bu da okumayı zorlaştırıyor"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bu mesaj, ", _interpolate(_list(0)), " aşırı uzun cümle içeriyor ve bu da okumayı zorlaştırıyor"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okunabilirlik"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan bir çözüm için tüm görüşmeyi analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan bir çözüm için tüm görüşmeyi analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunulan çözüm"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme boyunca temsilcinin ses tonunu analiz eder"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme boyunca temsilcinin ses tonunu analiz eder"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin mesajlarında herhangi bir hata saptanamadı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil bilgisi hataları belirlenemedi"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamlama ifadesi algılanmadı. Gerekirse yöneticiniz aracılığıyla Ayarlar içinden yenilerini ekleyin."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci görüşmeyi kibarca sonlandırdı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış ifadesi algılanmadı. Gerekirse yöneticiniz aracılığıyla Ayarlar içinden yenilerini ekleyin"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşterinin duygularını yansıttı veya sevecen kelimeler kullandı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterinin hissini yansıtıp yansıtmadığı veya sevecen kelimeler kullanıp kullanmadığı saptanamadı"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci uzun cümleler ve karmaşık kelimeler kullandığı için bazı kişiler tarafından anlaşılmama olasılığı var"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci karmaşık kelimeler kullandığı için bazı kişiler tarafından anlaşılmama olasılığı var"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci uzun cümleler kullandığı için bazı kişiler tarafından anlaşılmama olasılığı var"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci, kolay anlaşılır bir dil kullandı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoriyi değerlendirmek için yeterli veri yok"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşterinin sorusunu/talebini anlamakta zorlandı"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşterinin sorusunu/talebini iyi anladı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşterinin sorusunu/talebini anlayıp anlamadığı saptanamadı"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında temsilcinin tonu"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında temsilcinin tonu"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında temsilcinin tonu"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında temsilcinin tonu"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında temsilcinin tonu"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin tonu belirlenemedi"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sonlandırılmadı"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sonlandırıldı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmenin sonlandırılıp sonlandırılmadığı saptanamadı"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşteriye ve sorunlarına karşı anlayışlı değildi"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşteriye ve sorunlarına anlayış gösterdi"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteriyle ve sorunlarıyla ilgili duygudaşlık gösterip göstermediği saptanamadı "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri görüşme sırasında karşılanmadı"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme sırasında müşteri selamlandı"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipik selamlama algılanamadı"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci müşteriye bir çözüm sunmadı"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteriye çözüm sunduğu"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilcinin müşteriye bir çözüm teklif edip etmediği saptanamadı"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karmaşık kelimeler"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzun cümleler"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dilbilgisi"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım hatası"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumsuz"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özür Dileyen"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sakin"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neşeli"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meraklı"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesyonel"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destekleyici"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stil"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kapanış"]), _normalize([_interpolate(_named("n")), " kapanış"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü yalnızca onaylanan kapanışları kontrol eder. Bu kategori kritik olarak ayarlanırsa, onaylanan sonuçlandırmaların kullanılmaması değerlendirmenin başarısız olmasına sebep olur."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan kapanışlar"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan kapanışlar"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 istisna"]), _normalize([_interpolate(_named("n")), " istisna"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolünün yok saydığı kelimeleri ve ifadeleri yazım veya dil bilgisi hataları olarak adlandırın."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım ve dil bilgisi istisnaları"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü istisnaları"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 selamlama"]), _normalize([_interpolate(_named("n")), " selamlama"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü yalnızca onaylı selamlamalar olup olmadığını kontrol eder. Bu kategori kritik olarak ayarlanırsa, onaylanan selamlamaların kullanılmaması değerlendirmenin başarısız olmasına sebep olur."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan selamlamalar"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan selamlamalar"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü kategorisi"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Otomatik Kalite Kontrolü kategorisi seçin"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik olarak atanan temel neden:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap → Ayarlar"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bazı kategoriler MS Azure’dan yararlanarak daha geniş bir işlevsellik ve desteklenen dil yelpazesi sunar. Daha fazla bilgi edinmek için ", _interpolate(_named("link")), " bağlantısını inceleyin veya bir yöneticiyle iletişime geçin."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MS Azure’dan yararlanarak genişletilmiş kategoriler ve desteklenen diller listesine erişin. ", _interpolate(_named("link")), " bağlantısını gözden geçirin veya bir yöneticiyle iletişime geçin."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü kullanın"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otomatik Kalite Kontrolü kategorilerine, sabit bir değerlendirme ölçeği kullanılarak otomatik olarak bir puan atanır. Değerlendirmeyi daha sonra manuel olarak ayarlayabilirsiniz. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Otomatik Kalite Kontrolü kategorisi seçin"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otomatik olarak atanacak bir puan, hataların önem derecesine ve sayısına bağlıdır. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel nedenler"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenlere yalnızca ilgili değerlendirme kategorileri gösterilir. Değerlendirme kategorileri, bir kategori oluştururken belirlediğiniz koşullara göre gösterilir."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canlı destek"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oryantasyon"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["“", _interpolate(_named("workspace")), "” çalışma alanı için bir değerlendirme kategorisi türü seçin."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenlerin her bir görüşmenin ilgili değerlendirme kategorilerini görmek üzere doğru puan kartını manuel olarak seçmeleri gerekir."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi türünü değiştirmek istediğinizden emin misiniz?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorileri değiştir"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi türü değiştirilsin mi?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşullu değerlendirme kategorisi"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik değerlendirme kategorisi"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi türü"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi ayarları"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem geri alınamaz."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tüm değerlendirmeleri sil"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatif olarak, bir kategoriyi arşivleyerek, hiçbir veriyi silmeden istatistiklerinizi koruyabilirsiniz."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bunun, bu kategori için ", _interpolate(_named("deleteAllRatings")), " ile sonuçlandığını anladım."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örneğin, Ton mükemmel"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi yalnızca şu durumlarda göster:"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Her Zaman\" ifadesi yok sayılır. Başka koşullarla birleştirilemez."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her Zaman"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketleri"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koşul seçin"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memnuniyet puanı (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak türü"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme kanalı"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriye bir ad verilmesi gerekiyor"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi bir puan kartına ekleyin"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluştur"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluştur"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivle"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi eklendi"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlenmiş bir kategori puan kartında görünmez ancak bu kategori herhangi bir veri silmeden istatistiklerinizi korumanıza olanak sağlar."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi arşivle"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kategorisini arşivle"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi arşivlendi"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi sil"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoriyi silmek için \"kategoriyi sil\" yazın"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kategoriyi sil"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kategoriyi sil ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi silindi"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi çoğaltıldı"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi grubu eklendi"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi grubu silindi"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi grup sırası değiştirildi"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi grubu güncellendi"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi arşivden kaldırıldı"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme kategorisi güncellendi"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir temsilci kritik bir kategoride başarısız olduğunda, o değerlendirmedeki tüm kategoriler otomatik olarak başarısız olur."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik değerlendirme kategorisi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu grupta kategori yok"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir değerlendirme kategorisi oluştur"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi düzenle"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu grup altındaki tüm kategorileri sil ve verileri değerlendir"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori grubu silinirse, bu gruba bağlı tüm kategoriler gruplandırılmamış halde kalır."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " kategori grubunu sil"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grubu altındaki kategorilerin silinmesinin seçilmesi, bu kategorilerle ilişkili tüm yorum verilerinin kaybedilmesine neden olacaktır."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup adı"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenler temel neden olarak birden fazla neden seçebilirler"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenler temel neden olarak birden fazla neden seçebilirler"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden fazla neden"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["örneğin: Ton"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir kategori grubu ekleyin"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni grup"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Diğer\" ekle"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden ekle"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu temel neden zaten var"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden ekle"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeyi açıklamak için temel neden ekleyin"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirme ölçeği"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlendi"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu değerlendirme kategorisine puan kartları ekleyin"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivden çıkar"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriyi güncelle"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm değerlendirmeler"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şunun için gösterildi:"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumsuz değerlendirmeler"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olumsuz ve nötr değerlendirmeler"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet, güncelle"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağırlık"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizin için daha önemli olan kategorilerin kayıt puanı hesaplamasında daha fazla ağırlığı olabilir."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ağırlık (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon hakkında bilgi edinin"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon hakkında bilgi edinin"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gelişmiş"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenli kalibrasyon oturumları düzenlemek, değerlendirenlerin değerlendirme tekniklerini uyumlu tutmalarını ve temsilcilerin aynı düzeyde, tutarlı ve tarafsız geri bildirim almalarını sağlar."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planları görüntüleyin"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ekibinizin tüm müşteri etkileşimlerinin nasıl ele alınması ve değerlendirilmesi gerektiği konusunda hemfikir olmasına yardımcı olan bir işlemdir."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profesyonel"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ve ", _interpolate(_list(1)), " planları için kalibrasyon mevcut."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturuma bir görüşme eklendiğinde daha önce verilen yorumlar görüntülenmez."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünmez"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha önce verilen yorumlar, kalibrasyon değerlendirmeleri olarak kabul edilir. Her değerlendiren kişi için yalnızca bir görüşme değerlendirmesi oturuma eklenir. Mesaja özel değerlendirmeler görüntülenmez."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görülebilir"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha önce verilen yorumların görünürlüğü"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrasyon, ekibinizin tüm müşteri etkileşimlerinin nasıl ele alınması ve değerlendirilmesi gerektiği konusunda aynı sayfada kalmasına yardımcı olan bir işlemdir. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar bir kalibrasyon oturumu sırasında verilen tüm değerlendirmelere sınırsız erişime sahiptir."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daima tüm değerlendirmeleri göster"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potansiyel müşteri"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar, değerlendirmeyi gönderdikten sonra bir kalibrasyon oturumu sırasında diğer değerlendirenler tarafından yapılan değerlendirmeleri görür."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirme gönderdikten sonra tüm kalibrasyon değerlendirmelerini görün"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar bir kalibrasyon oturumu sırasında verilen tüm değerlendirmelere sınırsız erişime sahiptir."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daima tüm değerlendirmeleri göster"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar, değerlendirmeyi gönderdikten sonra bir kalibrasyon oturumu sırasında diğer değerlendirenler tarafından yapılan değerlendirmeleri görür."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirme gönderdikten sonra tüm kalibrasyon değerlendirmelerini görün"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar, değerlendirmeyi gönderdikten sonra bir kalibrasyon oturumu sırasında diğer değerlendirenler tarafından yapılan değerlendirmeleri görür."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir değerlendirme gönderdikten sonra tüm kalibrasyon değerlendirmelerini görün"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar bir kalibrasyon oturumu sırasında yalnızca kendi değerlendirmelerini görürler."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca kendi değerlendirmelerini göster"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon ayarları güncellendi"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon değerlendirmelerinin görünürlüğü"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pano ölçümleri eşiğini, boşlukları ekiplerinizin dahili ölçümler sistemine göre doldurarak ayarlayın. Panoda üzerinden eşiği inceleyin."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantılar"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'ler"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üyeler"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşik"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşik güncellendi"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyin..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yönetici izinlerine sahip olduğunuzdan emin olun ve ", _interpolate(_named("retry")), ", veya ", _interpolate(_named("contact")), " adresine gidin."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bizimle iletişime geçin"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masanızın API'sine erişim sağlayamadık."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yeniden deneyin"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masanızı bağlıyoruz. Biraz sonra yönlendirileceksiniz."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " yetkili"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yeni bir yardım masası bağlantısı eklemek için buraya geri dönün: ", _interpolate(_list(0)), "."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir veya iki adımı atladığınız anlaşılıyor."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aynı e-posta alanına sahip bir organizasyon birimi zaten var"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bağlantı sayfası"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu arada..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz izinlerinizi kontrol ederken bize bir saniye izin verin."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu arada..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümleştiriliyor"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["çalışma alanı oluştur"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zendesk QA'yı kullanmaya devam etmek için, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıma neredeyse hazır"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı bulunamadı"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap değiştir"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA'ya hangi tümleştirme dahil edilmelidir?"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hesabınızı silmeniz, sizinle ilgili sahip olduğumuz tüm verileri kalıcı olarak siler - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tüm değerlendirmeleri, görüşme içeriklerini, çalışma alanlarını vb."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack'e Ekle"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı kes"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümleştirme bağlantısı kesilirse, Slack üzerinden tüm Zendesk QA bildirimleri devre dışı bırakılır"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack'in bağlantısını kes"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack tümleştirmesi Zendesk kalite güvencesiyle kesildi."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm yeni hesap kullanıcıları için varsayılan bildirim ayarlarını tanımlayın."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kullanıcılar için ayarları geçersiz kıl"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim ayarlarını tüm hesap kullanıcıları için geçersiz kılmak üzeresiniz"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar kendi bildirim ayarlarını tanımlayamayacak."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim ayarları güncellendi"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook'lar"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik kategorilerden biri başarısız olduğunda uyar"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT yanıt alınca uyar"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetikleyiciler"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım çalışma alanı ve veri oluşturun"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo verilerini yeniden oluşturun ve demo çalışma alanını koruyun."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım çalışma alanını ve verilerini kaldır"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım verilerini yenile"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verileri yenile"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hesabı yönet \"", _interpolate(_named("accountName")), "\" demo modu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanıtım modu"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfada kal"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfadan çık"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfadan çıkılsın mı ve tüm değişiklikler kaybedilsin mi?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı tamamladınız"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödev oluştur"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama tamamlandı"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama bulunamadı"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda değerlendireceğiniz bir görev bulunmuyor."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı ayarlayın veya sonraki döngünün başlamasını bekleyin."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrelerde değişiklik yapmayı deneyin"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı düzenle"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellemeler için bu sayfayı takip edin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu döngü için görev yok"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek görüşme yok"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görevi tamamlandı olarak işaretle"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kullanıcıyı incelediniz."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu atamada kendi kendine değerlendirme bulunmuyor"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme görünümünün başlık kısmındaki kalibrasyon simgesine tıklayarak ekleyin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme eklenmedi"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödev oluştur"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasyon oturumu oluştur"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamanız"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer atamalar"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin atama yok"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atama yok"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm değerlendirilenler"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben hariç tüm değerlendirilenler"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen yok"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen ara"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldırıldı"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm durumlar"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılacak iş"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm değerlendirenler"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben hariç tüm değerlendirenler"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bana atanan"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren bulunamadı"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren ara"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " gün"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " saat"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " dakika"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G/D"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden ata"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanı kullanıcıları"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut değerlendiren"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu döngü için yeni hedef"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni değerlendiren"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendireni seçin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri ata"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi yeniden ata"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taşınacak görüşmeler"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut döngü"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı düzenle"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren bulunamadı"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramayı değerlendiren"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri bana yeniden ata"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri başka bir kullanıcıya yeniden ata"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirenler"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gün"]), _normalize([_interpolate(_named("n")), " gün"]), _normalize([_interpolate(_named("n")), " gün"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " saat"]), _normalize([_interpolate(_named("n")), " saat"]), _normalize([_interpolate(_named("n")), " saat"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanan döngüleri gizle"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanan oturumları gizle"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedefiniz"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi kaldırmak için bir neden ekleyin"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi neden kaldırdığınızı açıklayın"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi kaldır"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri korumak istiyor musunuz?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi değiştirmek için bir neden ekleyin"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri kaldır"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tut"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri koru"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişim bulunamadı"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden bu görüşmeyi değiştirdiğinizi açıklayın"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu görüşmeyi atamadan kaldırmak istiyor musunuz? Bu, hedefi 1 azaltır."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi değiştir"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslağı sil"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslağı düzenle"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bana yeniden ata"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka bir kullanıcıya yeniden ata"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden ata ve gözden geçir"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeyi kaldır"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum yazın"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha az görüntüle"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla görüntüle"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hedef: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " değerlendirildi"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılan değerlendirmeler"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanan döngüleri göster"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanan oturumları göster"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan süre"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görevler"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme değiştirildi"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görev listesini aç/kapat"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " sonra"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " saniye"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " saniye"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gün"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gün ", _interpolate(_named("hours")), " saat"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "g ", _interpolate(_named("hours")), "s ", _interpolate(_named("minutes")), "d"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gün"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gün"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "g ", _interpolate(_named("minutes")), "d"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " saat"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " saat ", _interpolate(_named("minutes")), " dak"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "sa ", _interpolate(_named("minutes")), "dk ", _interpolate(_named("seconds")), "sn"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "sa ", _interpolate(_named("seconds")), " sn"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " dk"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "dk ", _interpolate(_named("seconds")), "sn"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " ay"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " sn"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " yıl"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemen şimdi"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzinleriniz yok. Yardım için yöneticinizle iletişime geçin."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa mevcut değil."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklenmeyen bir hata oluştu. Yardım için destek ekibiyle iletişime geçin."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erişim reddedildi. Bu işlemi gerçekleştirme izni yok."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa yok veya sizin sayfaya erişim izniniz yok."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biraz fazla hızlı gidiyorsunuz ve size yetişmekte zorlanıyoruz."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbiri"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedildi"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedilmedi"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ve"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uygulandı"]), _normalize([_interpolate(_named("n")), " uygulandı"]), _normalize([_interpolate(_named("n")), " uygulandı"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlandı"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma zamanı:"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturan"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritik"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şudur:"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Eski üye"]), _normalize(["a Eski üye"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buradan"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " seçildi"]), _normalize([_interpolate(_named("n")), " seçildi"]), _normalize([_interpolate(_named("n")), " seçildi"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son güncelleme"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... ve +1 daha"]), _normalize(["... ve +", _interpolate(_named("n")), " daha"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 daha"]), _normalize(["+", _interpolate(_named("n")), " daha"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yeni"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbiri"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu değildir:"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veya"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldırıldı"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " yanıtlar"]), _normalize([_interpolate(_named("n")), " yanıt"]), _normalize([_interpolate(_named("n")), " yanıtlar"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bugün"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kullanıcı"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cuma"]), _normalize(["Cuma"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pazartesi"]), _normalize(["Pazartesi"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cumartesi"]), _normalize(["Cumartesi"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pazar"]), _normalize(["Pazar"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Perşembe"]), _normalize(["Perşembe"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Salı"]), _normalize(["Salı"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Çarşamba"]), _normalize(["Çarşamba"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dün"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yönetici"]), _normalize(["bir Yönetici"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hesap Yöneticisi"]), _normalize(["Hesap Yöneticisi"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcı"]), _normalize(["bir Kullanıcı"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik Kalite Kontrolü"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyalandı"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi edinin"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumunuz sona erdi."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açma işlemleri"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan boş bırakılamaz."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğe bulunamadı."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen kişi yok."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sahip"]), _normalize(["bir Sahip"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi edinin"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi edinin"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi edinin."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlamadan önce okuyup koşullarımızı kabul edin."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderildi"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel olarak yapılan değerlendirmeler"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellendi"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Temsilci"]), _normalize(["bir Temsilci"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Potansiyel Müşteri"]), _normalize(["a Potansiyel Müşteri"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yönetici"]), _normalize(["a Yönetici"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Değerlendiren"]), _normalize(["bir Değerlendiren"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonim kullanıcı"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivle"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanan"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal et"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değiştir"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakında"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlan"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyalamak için tıklayın"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı kopyala"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri memnuniyeti skoru"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehlikeli bölge"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddet"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama (isteğe bağlı)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay gerekiyor"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devre dışı bırak"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilmemiş değişiklikler silinsin mi?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E‑posta"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştir"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışa aktar"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıklık"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet et"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asla"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçme oranı"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişisel ayarlar"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha az oku"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden gönder"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri yükle"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilen"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan/görülen değerlendirmeler"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı üyesinin gerçekleştirdiği değerlendirmeler"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri kaydet"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkili ölçek"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3'lü ölçek"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4'lü ölçek"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5'li ölçek"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıklık seçin"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha az göster"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla göster"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atla"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adım"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket geri bildirimi"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman aralığı"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedilmedi"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağırlık"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tarafından, Zendesk QA'ya katılmaya davet edildiniz."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kullanıcılar Yönetim Merkezi'nden otomatik olarak bağlanır. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları yönetin"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı yönetimi hakkında bilgi edinin"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bazı kullanıcıların temel hazırlığı kimlik sağlayıcınızdan otomatik olarak yapılır ve eşitlenir. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "” hesap izni değiştirildi"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı izinleri hakkında bilgi edinin"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bana bir daha sorma"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kullanıcılar sayfasında yapılan tüm değişiklikler çalışma alanlarına yansıtılır. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, kullanıcının Zendesk QA'nın bazı bölümlerine erişiminin değişebileceği anlamına gelir"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap düzeyi iznini değiştir"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı kaldırıldı"]), _normalize([_interpolate(_named("n")), " kullanıcı kaldırıldı"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bir kullanıcıyı kaldırdığınızda, hesabınıza erişimini kaybeder. Değerlendirmeleri, yorumları ve puanlamaları Zendesk QA'da kalacak. ", _interpolate(_named("linebreak")), "Bir kullanıcının kaldırılması, aboneliğinizdeki lisans sayısını etkilemez."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bu kullanıcı, yönettiğiniz tüm çalışma alanlarından kaldırılacak. ", _interpolate(_named("linebreak")), " Bu kişilere ait değerlendirme, yorum ve puanlamalar değişmeden kalır."]), _normalize(["Bunlar, yönettiğiniz tüm çalışma alanlarından kaldırılır. ", _interpolate(_named("linebreak")), " Değerlendirmelerinin, yorumlarının ve puanlamalarının tümü değişmeden kalır."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kaldır ", _interpolate(_named("name"))]), _normalize(["Kaldır ", _interpolate(_named("name")), " kullanıcı"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her şeyi görebilir, tüm ayarları ve faturalamayı yönetebilir"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalama hariç her şeyi görebilir, kişisel ve çalışma alanı ayarlarını yönetebilir"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap izinleri"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanına özel izinler, kullanıcının bulunduğu çalışma alanlarındaki verileri görmesine (ve) izin verir."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Kalite Kontrolü hakkında bilgi edinin"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlarınız, katıldıkları görüşmeler eşitlendikçe burada görünür"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşitlenen bot yok"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot kullanıcı olarak işaretlenirse, bota oturum açma erişimi verilir. Bot geçmiş verilerine panolarda erişilebilir."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böyle bir bot bulunamadı"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir anahtar kelime veya yazım kullanarak yeniden arama yapmayı deneyin."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot ara"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot adıyla ara"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı olarak işaretle"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca yöneticiler ve hesap yöneticileri botları kullanıcı olarak işaretleyebilir"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot adı"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son canlı destek"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel olarak işaretlendi"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İncelenebilir"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot, otomatik analizden ve varsa atamalardan kaldırılır. Yeni veriler panolarda görünmeyecek, bu sayede doğru raporlama ve daha kolay bot yönetimi sağlanacak."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üretken bot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinmiyor"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş akışı botu"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruba/gruplara ekle"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm gruplar"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup yok"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruptan/gruplardan kaldır"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup seçin"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap izni"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanına/alanlarına ekle"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı soyadı"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son etkin"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap düzeyinde rol"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kullanıcıya Hesap ve/veya Çalışma alanı düzeyinde bir izin atanır. Yönetici izinleri artık hesap düzeyinde, diğer izinler ise Çalışma Alanı düzeyinde."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanından/alanlarından kaldır"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı izni"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupta yönetilen izinler"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntıları Düzenle"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot olarak işaretle"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kullanıcı bot olarak işaretlenirse oturum açma erişimi kaldırılır. Geçmiş kullanıcı verilerine panolarda erişilebilir."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botu otomatik analizden ve varsa atamalardan kaldırın. Yeni veriler panolarda görünmeyecek, bu sayede doğru raporlama ve daha kolay bot yönetimi sağlanacak."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botu incelemelerden hariç tut"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bot olarak işaretlendi"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca izin türünde Kullanıcı bot olarak işaretlenebilir"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden fazla hesabı olan kullanıcılar bot olarak işaretlenemez"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyla ara"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botlar"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar, botlar ve çalışma alanları"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÇALIŞMA ALANLARI"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar ve çalışma alanları"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin yok"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlandı"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel olarak eklendi"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemede"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemedeki davet"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM kullanıcıları"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem gerekiyor"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... ve bir tane daha"]), _normalize(["...ve ", _interpolate(_named("n")), " daha"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzni olarak değiştir"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bekleyen ", _interpolate(_named("n")), " davet kullanıcısının rolünü değiştirmek için kaldırın ve yeniden davet edin."]), _normalize(["Bekleyen ", _interpolate(_named("n")), " davet kullanıcısının rolünü değiştirmek için kaldırın ve yeniden davet edin"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " için ayrıntıları düzenleyin"]), _normalize([_interpolate(_named("n")), " seçili kullanıcı için ayrıntıları düzenleyin"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı güncellendi."]), _normalize([_interpolate(_named("n")), " kullanıcı güncellendi."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bu grup başka bir çalışma alanından 1 kullanıcı içeriyor."]), _normalize(["Bu grup, başka bir çalışma alanından ", _interpolate(_named("n")), " kullanıcı içeriyor."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştir"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu arşivlendi"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlenmiş grup"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup oluştur"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["“", _interpolate(_named("name")), "” grubu oluşturuldu"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlıksız grup"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu grup kalıcı olarak silinsin mi?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grubu sil"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grubu sil"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grubu düzenle"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup potansiyel müşteri"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Rolü"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu gizlendi"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu grup Zendesk QA içinde başka hiçbir yerde görünmeyecek"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca çalışma alanı izinlerini yönetin"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Zendesk QA’da görünmez"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potansiyel müşteri"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlıksız grup"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu adı taşıyan başka bir grup var"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu adı taşıyan arşivlenmiş bir grup zaten var"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolünüz yalnızca bu grubun üyelerinin yönetilmesine izin veriyor. Daha fazla değişiklik için daha yüksek izinlere sahip bir kullanıcıyla iletişime geçin."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potansiyel müşteri olarak kaldır"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu geri yüklendi"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu kaydedildi"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potansiyel müşteri olarak ayarla"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu arşivden çıkarıldı"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilmemiş değişiklikler"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut kullanıcılarınızı bu gruba ekleyin"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad veya e-posta adresiyle arayın"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye ekleyin"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grubu görünür"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Her bir üye, ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar hakkında bilgi edinin"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupların bazı nitelikleri kimlik sağlayıcınızdan otomatik olarak sağlanır ve eşitlenir."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar hakkında bilgi edinin"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup oluştur"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekip üyelerinizi kolayca organize edin ve organizasyon biriminizin yapısını taklit edin."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az 1 üye ekleyin"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup adı ekleyin"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu adı taşıyan bir grup zaten var"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup üyeleri"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup adı"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekip üyelerinizi organize edin ve organizasyon yapınızı taklit edin."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üyeler"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Grup yok"]), _normalize(["1 grup"]), _normalize([_interpolate(_named("count")), " grup"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunun yerine yeni bir grup oluşturun veya arşivlenmiş bir grubu etkinleştirin."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir etkin grup yok"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlenmiş grup yok"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böyle bir grup bulunamadı"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farklı bir anahtar kelime veya yazım kullanarak yeniden arama yapmayı deneyin."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup eklenmedi"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["bunun içinde"]), _normalize(["içinde"]), _normalize(["içinde"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup adıyla ara"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlendi"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yeniden gönderilebilir ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " lisans kaldı"]), _normalize([_interpolate(_named("n")), " lisans kaldı"]), _normalize([_interpolate(_named("n")), " lisans kaldı"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliğiniz duraklatıldığı için lisans sayınız şu anda sınırlı. Daha fazla kullanıcı eklemek için hesabınızı yeniden etkinleştirin."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap lisansınız için limite ulaştınız. Etkin bir kullanıcıyı devre dışı bırakarak veya daha fazla lisans ekleyerek kullanıcıları değiştirebilirsiniz."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını görüntüle"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " seçildi"]), _normalize([_interpolate(_named("n")), " seçildi"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı seçildi"]), _normalize([_interpolate(_named("n")), " kullanıcı seçildi"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " satır"]), _normalize([_interpolate(_named("n")), " satır"]), _normalize([_interpolate(_named("n")), " satır"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları adlarıyla veya e-posta adresleriyle toplu halde arayın."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verileri virgül veya yeni satırlarla ayırarak buraya yapıştırın"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları toplu ara"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " için çalışma alanı izinlerini düzenle"]), _normalize([_interpolate(_named("n")), " seçili üye için çalışma alanı izinlerini düzenle"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenleme izinleri"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı iznini seçin"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Çalışma alanından ", _interpolate(_named("name")), " kaldır"]), _normalize(["Çalışma alanından ", _interpolate(_named("name")), " üye kaldır"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " üye kaldırıldı"]), _normalize([_interpolate(_named("n")), " üye kaldırıldı"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Üyeyi kaldır"]), _normalize(["Üyeleri kaldır"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bu kullanıcı çalışma alanından kaldırılacak."]), _normalize(["Bu kullanıcılar çalışma alanından kaldırılacak."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["“", _interpolate(_list(0)), "” çalışma alanı izni değiştirildi."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye ekleyin"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri ve ilgili değerlendirmeleri görün"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanındaki her şeyi görün ama yalnızca kısa testleri, atamaları, ihtilafları ve kalibrasyon oturumlarını yönetin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı izinleri"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanındaki her şeyi görün ve yönetin"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı ayarları hariç, çalışma alanındaki her şeyi görün"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları Bağla"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönettiğiniz çalışma alanlarındaki tüm kullanıcılar bu çalışma alanına eklendi."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir yöneticiden bunu yapmasını isteyin."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kullanıcıları doğrudan kendiniz davet edin veya bir yöneticiden davet isteyin."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " veya ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet et"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek kullanıcı yok"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " kullanıcı eklendi"]), _normalize([_interpolate(_named("n")), " kullanıcı eklendi"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Üye ekleyin"]), _normalize(["Üye ekle"]), _normalize([_interpolate(_named("n")), " üye ekle"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı iznini seçin"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı kullanıcılarını seçin"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Şuraya üye ekle: ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postaları yeni satır veya virgülle ayırarak yapıştırın"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Eşleşen ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-posta"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" zaten bağlı kullanıcılarla:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " e-posta eşleştirilemedi"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazım denetimi yapın veya daha sonra tek tek bağlayın"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" yardım masası kullanıcıları ile:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı bağlandı"]), _normalize([_interpolate(_named("n")), " kullanıcı bağlandı"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı davet edildi"]), _normalize([_interpolate(_named("n")), " kullanıcı davet edildi"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu bağlantı"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu davet"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örneğin:\n\nkate", "@", "example.com, can", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkileşimlerini değerlendirmek için yardım masanızdan/masalarınızdan bağlanın"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcıyı bağla"]), _normalize(["Kullanıcıları bağla"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postalar"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek tek bağlan"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı kullanıcılarınızın Zendesk QA'ya erişmelerini istiyorsanız onları buraya davet edebilirsiniz."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı kullanıcıları davet edin"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcı davet et"]), _normalize(["Kullanıcı davet et"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet etmeyi atla"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkileşimlerini değerlendirmek için yardım masanızdan/masalarınızdan bağlanacak kullanıcıları seçin"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aboneliğinizi yönetin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası kullanıcılarını bağlayın"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanınıza benzersiz bir ad verin"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinleştirilirse, mevcut tüm hesap bağlantıları yeni çalışma alanına otomatik olarak eklenir."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm bağlantıları bu çalışma alanına ekle"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı oluştur"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı adı"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı oluştur"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba doğrudan eklenen (çalışma alanı atanmadan) kullanıcılara Hesap düzeyinde bir rol atanır. Bunları çalışma alanlarına daha sonra ekleyebilirsiniz."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet edilen kullanıcılara otomatik olarak Hesap düzeyinde bir Kullanıcı rolü atanır. Bunları daha sonra bir Yönetici rolüne yükseltebilirsiniz."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunların hepsi"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postaları yeni satırlar veya virgüllerle ayırarak yapıştırın."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kullanıcı e-postayla davet edildi"]), _normalize([_interpolate(_named("n")), " kullanıcı e-postayla davet edildi"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir çalışma alanına eklenen kullanıcılara otomatik olarak Hesap düzeyinde bir Kullanıcı rolü atanır. Bunları daha sonra bir Yönetici rolüne yükseltebilirsiniz."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir tane daha ekle"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları bir çalışma alanına eklemeden yalnızca hesaba davet edin"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davet edilen kullanıcılara yalnızca Hesap izni atanır ve çalışma alanına eklenmez."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları doğrudan bir çalışma alanına davet edin ve çalışma alanı izinlerini atayın"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları çalışma alanına ekleyin"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu davet"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek tek davet et"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca hesabına davet et"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir çalışma alanına davet edin"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcı davet edin"]), _normalize(["Kullanıcı davet et"]), _normalize([_interpolate(_named("n")), " kullanıcı davet et"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları e-postayla toplu olarak davet et"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcıyı sil"]), _normalize(["Kullanıcıları sil"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçimi kaldır"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıları e-posta ile davet edin"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı yok"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin seç"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daveti yeniden gönder"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı kullanıcı"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemedeki e-posta davet kullanıcısı"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM kullanıcısı"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı seçin"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapay zeka temsilcileri"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetim Merkezi"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalite kontrolü"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşgücü yönetimi"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tamamlandı ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Tamamlandı ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Tamamlandı ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Çevrim", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendiren"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı düzenle"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeleri manuel olarak bulup değerlendirmeye atamak yerine, otomatik değerlendirme atamalarını ayarlayın ve zamandan tasarruf edin."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitiş tarihi ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar, görüşme görünümünün yan menüsündeki \"Kalibrasyon\" bölümü altında eklenebilir."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz oturum eklenmedi"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taban çizgisi"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum yok"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşmeler, görüşme görünümünün üst bilgi kısmındaki kalibrasyon simgesine tıklayarak bir oturuma eklenebilir."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz görüşme eklenmedi"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hiç değerlendirme yapılmadı. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir kalibrasyon değerlendirmesi bırakın"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel neden"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şuraya atla:"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komut paleti yüklenemedi."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbir şey bulunamadı."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimlik ile bulundu"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komut paleti yükleniyor…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sayfalar, filtreler, görüşmeler, ayarlar…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşme"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel filtre"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese açık filtre"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buradan: ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API'nizden"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Gösteriliyor ", _interpolate(_named("rangeStart")), " ila ", _interpolate(_named("rangeEnd")), " / ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Gösteriliyor ", _interpolate(_named("rangeStart")), " ila ", _interpolate(_named("rangeEnd")), " / ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan paneli gizle"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan paneli göster"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan çubuğu aç / kapat"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul edilen ihtilaflar"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar reddedildi"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık ihtilaflar"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar kısmen kabul edildi"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılan değerlendirmeler"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görülen değerlendirmeler"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kabul edilen"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["açıldı"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kısmen kabul edildi"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Toplamda ", _interpolate(_list(0))])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reddedildi"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaflar"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul edildi"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısmen kabul edildi"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedildi"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["değerlendirme"]), _normalize(["değerlendirmeler"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirilenler tarafından görülen değerlendirme sayısı"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görülen yorumlarının sayısı"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf konusu değerlendirenler"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtilaf oluşturanlar"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriye göre ihtilaflar"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamanla oluşan ihtilaflar"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama çözüm süresi"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama çözüm süresi (sa.)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama çözüm süresi (dk.)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çözümlendi"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam ihtilaf"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag seçin"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul edildi"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt dili"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm diller"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öngörülen sürücüler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç sürücü bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm sürücüler"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime Bulutu kelimeler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelime bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kelimeler"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahsedilenler"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En yeni"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En eski"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısmen kabul edildi"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " ile tepki verdi"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddedildi"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çözümlendi"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cilt tonu seçin"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum bulunamadı"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori bulunamadı"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorumlar"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yorum boyutu"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beden bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm bedenler"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre bulunamadı"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm gruplar"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'ler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç hashtag bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm hashtag'ler"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm değerler"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel alan bulunamadı"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketleri"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım masası etiketi bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm yardım masası etiketleri"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuçlar yükleniyor..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru türleri"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru türü bulunamadı"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tepki ekle"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan Kartları"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan kartı bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm puan kartları"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puanlar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm puanlar"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynaklar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kaynaklar"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim nedeni"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neden bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm nedenler"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anketler"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anket bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm anketler"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanallar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kanallar"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm kullanıcılar"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı bulunamadı"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm çalışma alanları"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testlere Dön"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test silinmiş veya henüz yayınlanmadı. Bağlantıyı bir kez daha kontrol edin veya test listesine geri dönün."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu test, ait olduğunuz çalışma alanıyla paylaşılmadı. Bir yöneticiden sizi bu testin erişiminin olduğu bir çalışma alanına eklemesini isteyin."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test kullanılamıyor"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ait olmadığınız çalışma alanlarına test erişimi verildi. Test kaydedilemedi ve yayınlanamadı."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir yöneticiden testi kaydedip yayınlamasını isteyin."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlardan en az birini doğru olarak işaretlemelisiniz."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan boş bırakılamaz."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu anda testin bir önizlemesini görüyorsunuz."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teste erişebilecek çalışma alanlarını seçin. Birden fazla çalışma alanında yer alan kullanıcılar testi yalnızca bir kez yanıtlayabilir. Test yayınlandıktan sonra \"Tüm çalışma alanları\" seçilirse yeni oluşturulan çalışma alanları dahil edilmez."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test erişimi"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivle"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme modundan çık"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışa aktar"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorular"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlar"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi yapın"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi yayınla"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test ayarları"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak olarak kaydet"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni test oluştur"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivden çıkar"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seçenekler"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenek ekle"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoş görünüyor, değil mi?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtınız gönderildi"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testiniz kaydedildi"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testiniz yayınlandı"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testiniz kaldırıldı"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru ekle"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşiv testi"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlenmiş bir test ve sonuçları artık kısa testler listesinde görünmeyecek. Arşivlenmiş bir testin sonuçlarına erişmeye ve gerektiğinde testi arşivden çıkarmaya devam edebilirsiniz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşiv testi"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yayınla"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler yayınlandıktan sonra değiştirilemez ve düzenlenemez. Hesabınızdaki tüm kullanıcılar yayınlanan testlerin tümüne erişebilecek."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test yayınlansın mı?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu testi silmek istediğinizden emin misiniz? Bu eylem geri alınamaz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test silinsin mi?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlarınız gönderildikten sonra değiştirilemez."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test gönderilsin mi?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi arşivden çıkar"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test arşivden çıkarılınca test genel görünümünde kullanılabilir hale gelir. Önceki katılımcıların sonuçlarına erişmelerine ve yenilerinin teste girmesine izin verir."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi arşivden çıkar"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yinelenen soru"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama (isteğe bağlı)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soruyu aşağı taşı"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soruyu yukarıya taşı"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizlemede yalnızca yanıtları olan sorular görüntülenir."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soruyu kaldır"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi gönder"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlıksız test"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test başlığı"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem, testi ve sonuçlarını kalıcı olarak silecek."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatif olarak, testi arşivleyerek sonuçlara erişmeye devam edebilirsiniz."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlandı"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi sil"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem, testi ve sonuçlarını kalıcı olarak silecek."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Testi sil ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğalt"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler ekibin kendine güvenini ve becerilerini artırmaya, tutarlı deneyimler sunmaya ve yeni ekip üyeleri dahil etmeye yardımcı olur."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısa sınavları test adıyla veya yazarın adıyla arayabilirsiniz."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz test oluşturulmadı"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısa sınavlar listesini dışa aktar"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort. puan"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanma tarihi"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test/Yazar"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test / Yazar / Çalışma Alanları"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcılar"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yayınlandı"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanları"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test ve yazar bazında ara"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arşivlendi"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taslak"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkin"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm testler"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test arşivlendi."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test arşivden kaldırıldı."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu test için seçilen çalışma alanındaki/alanlarındaki kullanıcılardan yanıt alınmadı."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu test arşivlenmeden önce yanıtlanmadı."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt yok"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada testin yanıtlarının bir dökümünü göreceksiniz.\nTestinizi henüz kimse doldurmadı - paylaşmanız kesinlikle yardımcı olacaktır."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz yanıt yok"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlar:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama puan:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bireysel"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Kopyala"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı kopyalandı"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç bulunamadı"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mini test"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "adlı testin soruları"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lider panosunda katılımcının adını arayabilirsiniz."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lider panosunu dışa aktar"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mini test"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puan"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katılımcıya göre ara"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lider panosu"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testler"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lider panosunu aç/kapat"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, artık panoda bu hashtag için filtre uygulayamayacağınız ve etiket artık yorumlar üzerinde otomatik olarak tamamlanmayacağı anlamına geliyor. Bu işlem geri alınamaz."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'ler oluşturun"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'ler oluşturun"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'ler oluşturun"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üyelerin yeni hashtag'ler oluşturmasına izin ver"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılan"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'i sil"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hashtag'ini sil"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag oluşturma kilitli"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag oluşturma kilidi kaldırıldı"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "' hashtag'i silindi"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "' hashtag'i '", _interpolate(_list(1)), "' olarak yeniden adlandırıldı"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag eklendi"]), _normalize([_interpolate(_named("count")), " hashtag eklendi"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni ad"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiç hashtag oluşturulmadı"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden adlandır"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("tagRenamed")), "\" hashtag’ini yeniden adlandır"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag'leri virgül veya satır sonlarıyla ayırın"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hashtag arayın"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endişeye gerek yok. Bunu yapabilirsiniz: ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tam burada"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı çalışma alanına bağlandı"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı iliştir"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu çalışma alanına bir bağlantı ekleyin"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklik yapmak için burada yöneticinizle iletişime geçin"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklik yapmak için Zendesk QA ile buradan iletişime geçin"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap altında bağlantı yok"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz bir müşteri hizmetleri platformunu tümleştirmemişsiniz."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bağlantı silinirse görüşmeler ", _interpolate(_named("workspaceName")), " çalışma alanından kaldırılır ve kendisiyle ilgili tüm verileri (yorumlar ve ihtilaflar dahil) siler."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı sil"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " bağlantısını sil"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir bağlantı seçin"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seçilen zaman aralığında, belirli etkin Otomatik Kalite Kontrolü kategorileri puan kartınızda yoktu. ", _interpolate(_named("date")), " tarihinde yapılan son değişiklikten önceki görüşme sayısı, bu kategoriler için analiz edilmemiş olabilir."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size gönderilen tüm geri bildirimler burada görünecektir. Henüz gösterilecek bir şey yok."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirme yok."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yalnızca yorum aktivitesini göster"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdiğiniz tüm geri bildirimlerin görüneceği yer burasıdır. Görüşmeler görünümünde başlayın."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen değerlendirme yok."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilen değerlendirmeler"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan değerlendirmeler"])}
  }
}