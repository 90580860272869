import { computed, ref, watchEffect } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import i18n from '@/i18n'
import { copy } from '@/utils/clipboard'
import { toast } from '@/components/toast'
import { getTranslationById } from '../supportedLanguages'
import type { SurveyConfig, SurveyConnection } from '../types'
import { getEmbeddedSnippet } from '../api'
import useSurvey from './survey/useSurvey'

const SNIPPET_AFFECTED_BY: readonly (keyof SurveyConfig)[] = [
  'question',
  'brand',
  'customQuestion',
  'questionScale',
  'questionScaleDisplay',
  'translations',
]

const snippetSuccessToast = i18n.t('settings.survey.edit.sections.delivery.embedded_snippet.snippet_copied_toast')
const snippetErrorToast = i18n.t('settings.survey.edit.sections.delivery.embedded_snippet.snippet_copy_error_toast')

const selectedLanguage = ref<string | undefined>(undefined)

export default function useEmbeddedSnippet(connectionId: string) {
  const { surveyConfigChanges, savedSurveyConfig } = useSurvey()

  const surveyChangesAffectSnippet = computed(
    () => !!surveyConfigChanges.value && SNIPPET_AFFECTED_BY.some((key) => !!surveyConfigChanges.value[key]),
  )

  const defaultLanguage = computed(() => savedSurveyConfig.value?.translations?.find((t) => t.default).language)
  const activeLanguages = computed(() =>
    savedSurveyConfig.value?.translations?.filter((t) => t.enabled).map((t) => t.language),
  )
  const activeLanguageOptions = computed(() =>
    activeLanguages.value?.map((lang) => ({ id: lang, name: getTranslationById(lang) })),
  )
  const zendeskTriggersLink = computed(() =>
    getZendeskTriggersLink(
      savedSurveyConfig.value?.deliverySettings?.find((del) => del.connection?.id === connectionId)?.connection,
    ),
  )

  const {
    data: embeddedSnippetResponse,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['embedded-snippet', selectedLanguage, computed(() => savedSurveyConfig.value?.id)],
    queryFn: ({ signal }) =>
      getEmbeddedSnippet({
        language: selectedLanguage.value,
        connectionId,
        configurationId: savedSurveyConfig.value.id,
        signal,
      }),
    enabled: computed(() => !!selectedLanguage.value && !!savedSurveyConfig.value?.id),
  })

  watchEffect(() => {
    if (!activeLanguages.value?.includes(selectedLanguage.value)) {
      selectedLanguage.value = defaultLanguage.value
    }
  })

  function copySnippet() {
    if (isError.value) {
      toast({ status: 'error', message: snippetErrorToast })
      return
    }

    copy(embeddedSnippetResponse.value?.snippet, snippetSuccessToast)
  }

  return {
    surveyChangesAffectSnippet,
    selectedLanguage,
    activeLanguageOptions,
    embeddedSnippetResponse,
    isFetching,
    zendeskTriggersLink,
    copySnippet,
  }
}

function getZendeskTriggersLink(connection: SurveyConnection) {
  return `https://${connection.subdomain}.zendesk.com/admin/objects-rules/rules/triggers`
}
