import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_shadow = _resolveComponent("side-shadow")!
  const _component_data_grid_header = _resolveComponent("data-grid-header")!
  const _component_data_grid_cell = _resolveComponent("data-grid-cell")!
  const _component_data_grid_pagination_cell = _resolveComponent("data-grid-pagination-cell")!
  const _component_recycle_scroller = _resolveComponent("recycle-scroller")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_side_shadow, {
      position: "right",
      active: _ctx.shadow.right,
      class: _normalizeClass(_ctx.$style.shadow)
    }, null, 8 /* PROPS */, ["active", "class"]),
    _createVNode(_component_side_shadow, {
      position: "left",
      active: _ctx.shadow.left,
      class: _normalizeClass(_ctx.$style.shadow)
    }, null, 8 /* PROPS */, ["active", "class"]),
    _createVNode(_component_recycle_scroller, {
      ref: "scrollContainer",
      class: _normalizeClass(_ctx.$style.scroller),
      items: _ctx.dataColumns,
      "item-size": _ctx.itemWidth + _ctx.columnPadding,
      direction: "horizontal"
    }, {
      default: _withCtx(({ item: { id, label, idx } }) => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.column),
          style: _normalizeStyle({ minWidth: _ctx.itemWidth + 'px', width: _ctx.itemWidth + 'px' }),
          "data-testid": "datagrid-data-column"
        }, [
          _createVNode(_component_data_grid_header, {
            id: id,
            centered: "",
            label: label,
            "label-tooltip": _ctx.labelTooltip,
            sort: _ctx.gridCtx.data.sort,
            "not-sortable": _ctx.notSortable,
            onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gridCtx.sortBy?.($event)))
          }, _createSlots({ _: 2 /* DYNAMIC */ }, [
            (_ctx.$slots.header)
              ? {
                  name: "default",
                  fn: _withCtx((slotProps) => [
                    _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({ ...slotProps, idx, ..._ctx.gridCtx.data })))
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "label", "label-tooltip", "sort", "not-sortable"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridCtx.data.rows, (row, rIdx) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: rIdx }, [
              (!row.hidden)
                ? (_openBlock(), _createBlock(_component_data_grid_cell, {
                    key: 0,
                    tall: _ctx.getIsTall ? _ctx.getIsTall(row) : false
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {
                        value: _ctx.getRowValue ? _ctx.getRowValue(row, idx) : row.data?.[idx],
                        row: row,
                        hasSubrows: row.rows && row.rows.length > 0,
                        index: rIdx,
                        label: label
                      }, () => [
                        _createTextVNode(_toDisplayString(_ctx.getRowValue ? _ctx.getRowValue(row, idx) : row.data?.[idx]), 1 /* TEXT */)
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["tall"]))
                : _createCommentVNode("v-if", true),
              ((_ctx.gridCtx.expandedRows.has(rIdx) && !row.hidden) || _ctx.gridCtx.alwaysExpand)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row.rows?.filter((subRow) => !subRow.hidden), (subRow, srIdx) => {
                    return (_openBlock(), _createBlock(_component_data_grid_cell, {
                      key: `${rIdx}-${srIdx}`
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "default", {
                          value: _ctx.getRowValue ? _ctx.getRowValue(subRow, idx) : subRow.data?.[idx],
                          row: subRow,
                          label: label
                        }, () => [
                          _createTextVNode(_toDisplayString(_ctx.getRowValue ? _ctx.getRowValue(subRow, idx) : subRow.data?.[idx]), 1 /* TEXT */)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  }), 128 /* KEYED_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */)),
          _createVNode(_component_data_grid_pagination_cell),
          (_ctx.gridCtx.data.dataTotals)
            ? (_openBlock(), _createBlock(_component_data_grid_cell, {
                key: 0,
                total: ""
              }, {
                default: _withCtx(() => [
                  _createCommentVNode(" Cannot use boolean shorthand for scoped slots (isTotal) "),
                  _renderSlot(_ctx.$slots, "default", {
                    value: _ctx.gridCtx.data.dataTotals[idx],
                    isTotal: true
                  }, () => [
                    _createTextVNode(_toDisplayString(_ctx.gridCtx.data.dataTotals[idx]), 1 /* TEXT */)
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            : _createCommentVNode("v-if", true)
        ], 6 /* CLASS, STYLE */)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["class", "items", "item-size"])
  ], 2 /* CLASS */))
}