import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stacked_bar_chart = _resolveComponent("stacked-bar-chart")!
  const _component_simple_legend = _resolveComponent("simple-legend")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "disputes-over-time-card",
    "api-method": _ctx.getDisputesOverTime,
    title: _ctx.$t('disputes_dashboard.card.disputes_over_time'),
    params: _ctx.filterParams,
    "data-rows": ['rejectedDisputes', 'acceptedDisputes', 'reviews'],
    "timestep-toggle": "",
    "get-has-data": _ctx.getHasData,
    class: _normalizeClass(_ctx.$style.card),
    onDataupdate: _ctx.updateOverviewState,
    onLoading: _ctx.updateOverviewStateLoading
  }, {
    rawData: _withCtx(({ data, timeStep, loading }) => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.chart)
      }, [
        (!loading)
          ? (_openBlock(), _createBlock(_component_stacked_bar_chart, {
              key: 0,
              data: _ctx.getStackedData(data),
              "time-step": timeStep
            }, null, 8 /* PROPS */, ["data", "time-step"]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_simple_legend, {
          data: _ctx.getLegendData(data)
        }, null, 8 /* PROPS */, ["data"])
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "title", "params", "get-has-data", "class", "onDataupdate", "onLoading"]))
}