import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_user_icon = _resolveComponent("user-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    class: _normalizeClass(_ctx.$style.userPicker),
    "model-value": _ctx.selectedUserIds,
    mode: "multiple",
    label: "name",
    "data-testid": "dashboard-user-filter",
    placeholder: _ctx.placeholder || _ctx.$t('dashboard.user_filter_placeholder'),
    disabled: _ctx.disabled,
    options: _ctx.usersList,
    "close-on-select": false,
    "no-results-text": _ctx.$t('universal.no_users_found'),
    "input-id": _ctx.inputId,
    "is-loading": _ctx.isLoading,
    "filter-results": false,
    condensed: _ctx.condensed,
    borderless: _ctx.borderless,
    "onUpdate:modelValue": _ctx.usersInputted,
    onSearchChange: _ctx.getUsers
  }, {
    option: _withCtx(({ option }) => [
      (option.id === 'separator')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.separator)
          }, null, 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    icon: _withCtx(({ open }) => [
      open
        ? (_openBlock(), _createBlock(_component_search_icon, {
            key: 0,
            class: "icon-m"
          }))
        : (_openBlock(), _createBlock(_component_user_icon, {
            key: 1,
            class: "icon-m"
          }))
    ]),
    multiplelabel: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.customLabel), 1 /* TEXT */)
    ]),
    afterlist: _withCtx(() => [
      (_ctx.remainingUserCount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('universal.n_more', { n: _ctx.$n(_ctx.remainingUserCount) }, _ctx.remainingUserCount)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "model-value", "placeholder", "disabled", "options", "no-results-text", "input-id", "is-loading", "condensed", "borderless", "onUpdate:modelValue", "onSearchChange"]))
}