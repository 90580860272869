const _arrayMinMax = (arr: number[]) =>
  arr.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
  )

export const bufferPeaks = (height: number, width: number, buffer: AudioBuffer) => {
  const peaks: number[][] = []
  if (!buffer.length || width <= 0) return peaks

  let min = 0
  let max = 0
  let top = 0
  let bottom = 0
  const segSize = Math.ceil(buffer.length / width)

  // TODO: Optimise! can we remove the nested loops?
  for (let c = 0; c < buffer.numberOfChannels; c++) {
    const data = buffer.getChannelData(c)
    if (data.length <= 0) {
      continue
    }

    for (let s = 0; s < width; s++) {
      const start = ~~(s * segSize)
      const end = ~~(start + segSize)
      if (start >= data.length) {
        peaks[s] = [0, 0]
        continue
      }
      const slice = Array.from(data.slice(start, end))

      ;[min, max] = _arrayMinMax(slice)

      // merge multi channel data
      if (peaks[s]) {
        peaks[s][0] = Math.max(peaks[s][0], max)
        peaks[s][1] = Math.min(peaks[s][1], min)
      } else {
        peaks[s] = [max, min]
      }
    }
  }

  // set peaks relative to canvas dimensions
  for (let i = 0; i < peaks.length; i++) {
    max = peaks[i][0]
    min = peaks[i][1]
    top = (height - max * height) / 2
    bottom = (height - min * height) / 2

    const treshold = bottom - top < 4
    peaks[i] = [treshold ? height / 2 + 2 : top, treshold ? height / 2 - 2 : bottom]
  }

  return peaks
}
