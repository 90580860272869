import { createSharedComposable } from '@vueuse/core'
import type { DashboardEventDetail, LookerEmbedDashboard } from '@looker/embed-sdk'
import { ref } from 'vue'

export default createSharedComposable(() => {
  const dashboardEventDetail = ref<DashboardEventDetail>()
  const lookerEmbedDashboard = ref<LookerEmbedDashboard>()

  return {
    dashboardEventDetail,
    lookerEmbedDashboard,
  }
})
