import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breakdown_source_picker = _resolveComponent("breakdown-source-picker")!
  const _component_support_desk_icon = _resolveComponent("support-desk-icon")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_number_cell = _resolveComponent("number-cell")!
  const _component_percentage_cell = _resolveComponent("percentage-cell")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "csat-breakdown-card",
    "data-rows-prop": "breakdownStatistics",
    "api-method": _ctx.getBreakdown,
    title: _ctx.$t('csat_dashboard.breakdown_card.title', [_ctx.questionTypeLabel]),
    params: _ctx.params,
    onSortChanged: _ctx.handleSortChange
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('csat_dashboard.breakdown_card.title', [_ctx.questionTypeLabel])) + " ", 1 /* TEXT */),
      _createVNode(_component_breakdown_source_picker, {
        modelValue: _ctx.breakdownSource,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.breakdownSource) = $event)),
          _ctx.handleBreakdownSourceChange
        ],
        "question-type-label": _ctx.questionTypeLabel
      }, null, 8 /* PROPS */, ["modelValue", "question-type-label", "onUpdate:modelValue"])
    ]),
    default: _withCtx(({ data, sortBy, setPage }) => [
      _createVNode(_component_data_grid, {
        data: _ctx.processData(data),
        "sort-by": sortBy,
        "inline-pagination": "",
        "set-page": setPage,
        "hide-total": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "label",
                "identity-column": "",
                label: _ctx.firstColumnTitle,
                width: "200px",
                align: "left"
              }, {
                default: _withCtx(({ value, row }) => [
                  _createElementVNode("span", _hoisted_1, [
                    (_ctx.breakdownSource === _ctx.BreakdownSource.source)
                      ? (_openBlock(), _createBlock(_component_support_desk_icon, {
                          key: 0,
                          source: value,
                          class: "mr-1"
                        }, null, 8 /* PROPS */, ["source"]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("span", {
                      class: _normalizeClass(row.naValue && _ctx.$style.naLabel)
                    }, _toDisplayString(value), 3 /* TEXT, CLASS */)
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_data_grid_section, {
            class: _normalizeClass(_ctx.$style.dataSection)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "ticketCount",
                "not-sortable": "",
                label: _ctx.COLUMN_LABELS_BY_ID.ticketCount
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, {
                    value: value,
                    color: value === 'N/A' ? 'gray-50' : undefined
                  }, null, 8 /* PROPS */, ["value", "color"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csatTicketCount",
                label: _ctx.$t('csat_dashboard.csat_by_users.csat_ticket_count', [_ctx.questionTypeLabel])
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_number_cell, { value: value }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "responseRate",
                label: _ctx.COLUMN_LABELS_BY_ID.responseRate
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "responseRateChange",
                label: _ctx.COLUMN_LABELS_BY_ID.responseRateChange
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    colorful: "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csat",
                label: _ctx.questionTypeLabel
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    threshold: _ctx.csatTarget,
                    value: value
                  }, null, 8 /* PROPS */, ["threshold", "value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "csatChange",
                label: _ctx.COLUMN_LABELS_BY_ID.csatChange
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_percentage_cell, {
                    "no-background": "",
                    colorful: "",
                    "show-sign": "",
                    value: value
                  }, null, 8 /* PROPS */, ["value"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "sort-by", "set-page"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["api-method", "title", "params", "onSortChanged"]))
}