export interface LookerDashboard {
  id: string
  name: string
}

interface FilterValue {
  name: string
  value: string
  assignmentId: string
}

export interface InitialAssignmentFilter {
  filter: FilterValue
}

export enum DashboardTypes {
  Assignments = 'assignments',
}
