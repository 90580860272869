import { z } from 'zod'

import i18n from '@/i18n'

export const CATEGORY_MIN_WEIGHT = 0
export const CATEGORY_MAX_WEIGHT = 100

export const scorecardCategorySchema = z.object({
  ratingCategoryId: z.string(),
  weight: z
    .number()
    .int()
    .min(CATEGORY_MIN_WEIGHT, {
      message: i18n.t('settings.reviews.scorecards.create.errors.bad_weight', {
        min: CATEGORY_MIN_WEIGHT,
        max: CATEGORY_MAX_WEIGHT,
      }),
    })
    .max(CATEGORY_MAX_WEIGHT, {
      message: i18n.t('settings.reviews.scorecards.create.errors.bad_weight', {
        min: CATEGORY_MIN_WEIGHT,
        max: CATEGORY_MAX_WEIGHT,
      }),
    }),
})

export const scorecardSchema = z
  .object({
    name: z
      .string({ required_error: i18n.t('settings.reviews.scorecards.create.errors.name_required') })
      .min(1, { message: i18n.t('settings.reviews.scorecards.create.errors.name_required') }),
    workspaces: z
      .array(z.object({ workspaceId: z.string(), autoQaEnabled: z.boolean() }))
      .nonempty({ message: i18n.t('settings.reviews.scorecards.create.errors.no_workspaces') }),
    categories: z.array(scorecardCategorySchema),
    groups: z.array(
      z.object({
        categories: z.array(
          z.object({
            ratingCategoryId: z.string(),
          }),
        ),
      }),
    ),
  })
  .refine(
    (scorecard) => {
      const categoryIds = scorecard.categories.map((category) => category.ratingCategoryId)
      const groupCategoryIds = scorecard.groups.flatMap((group) =>
        group.categories.map((category) => category.ratingCategoryId),
      )
      const allCategoryIds = [...categoryIds, ...groupCategoryIds]

      return !!allCategoryIds.length
    },
    { message: i18n.t('settings.reviews.scorecards.create.errors.no_categories'), path: ['categories'] },
  )
