import { UiDropdownTypes } from '@klausapp/ui-kit'
import analytics from '@/utils/analytics'
import { session } from '@/composables/useSession'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import i18n from '@/i18n'
import GroupPicker from '../GroupPicker.vue'
import SurveyPicker from '../SurveyPicker.vue'
import ScorePicker from '../ScorePicker.vue'
import QuestionTypePicker from '../QuestionTypePicker.vue'
import CsatLanguagePicker from '../CsatLanguagePicker.vue'
import SourcePicker from '../SourcePicker.vue'
import TicketTagPicker from '../TicketTagPicker.vue'
import CsatWordCloudPicker from '../CsatWordCloudPicker.vue'
import CsatPredictedDimentionsPicker from '../CsatPredictedDimentionsPicker.vue'
import CsatPredictedDriversPicker from '../CsatPredictedDriversPicker.vue'
import SurveyReasonPicker from '../SurveyReasonPicker.vue'
import TimePeriodPicker from '../TimePeriodPickerV2.vue'
import WorkspacePicker from '../WorkspacePickerV2.vue'
import UserPicker from '../UserPickerV2.vue'
import ChannelPicker from '../ChannelPicker.vue'
import HelpdeskCustomFieldsControl from '../HelpdeskCustomFieldsControl.vue'
import type { FilterBarFilter } from './types'
import { toSortedFilters } from './util'
import { filterKey } from './keys'

const filters: FilterBarFilter[] = [
  {
    component: TimePeriodPicker,
    key: filterKey.timePicker,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardTimePeriodFilterChanged()
      return filters
    },
  },
  {
    component: WorkspacePicker,
    key: filterKey.workspacePicker,
    mode: UiDropdownTypes.Modes.Multiple,
    selectAllMode: session.features.csatIncludeExternal
      ? UiDropdownTypes.SelectAllModes.None
      : UiDropdownTypes.SelectAllModes.All,
    customSelectAllLabel:
      session.features.csatIncludeExternal && hasAccountRole('ADMIN', 'MANAGER')
        ? i18n.t('dashboard.workspace_filter_whole_account')
        : i18n.t('pickers.workspaces.select_all'),
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardWorkspaceFilterChanged()

      return {
        ...filters,
        connectionIds: [],
        ticketTags: [],
        csatReasons: [],
        wordCloudWords: [],
        predictedDimensions: [],
        predictedTags: [],
        languages: [],
      }
    },
  },
  {
    component: GroupPicker,
    key: filterKey.groupPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    hideFilter: hasWorkspaceRole('AGENT'),
    workspacesSelectAllMode: UiDropdownTypes.SelectAllModes.None,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardUserGroupFilterChanged()
      return filters
    },
  },
  {
    component: UserPicker,
    key: filterKey.userPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    hideFilter: hasWorkspaceRole('AGENT'),
    workspacesSelectAllMode: UiDropdownTypes.SelectAllModes.None,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardUserFilterChanged()
      return filters
    },
  },
  {
    component: QuestionTypePicker,
    key: filterKey.questionTypePicker,
    hideFilter: !session.features.surveys,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardQuestionTypeChanged()
      return filters
    },
  },
  {
    component: SurveyPicker,
    key: filterKey.surveyPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    hideFilter: !session.features.surveys,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardSurveyFilterChanged()

      return {
        ...filters,
        csatReasons: [],
      }
    },
  },
  {
    component: ScorePicker,
    key: filterKey.scorePicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'rawCsatScores',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardScoreFilterChanged()
      return filters
    },
  },
  {
    component: CsatLanguagePicker,
    key: filterKey.csatLanguagePicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    hideFilter: !session.features.surveys,
    connectedParameter: 'languages',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardCsatLanguagesChanged()
      return filters
    },
  },
  {
    component: SourcePicker,
    key: filterKey.sourcePicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'connectionIds',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardConversationSourceChanged()
      return filters
    },
  },
  {
    component: TicketTagPicker,
    key: filterKey.ticketTagPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'ticketTags',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardTicketTagsChanged()
      return filters
    },
  },
  {
    component: CsatWordCloudPicker,
    key: filterKey.csatWordcloudPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'wordCloudWords',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardWordCloudWordsChanged()
      return filters
    },
  },
  {
    component: CsatPredictedDimentionsPicker,
    key: filterKey.csatPredictedDimentionsPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'predictedDimensions',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardPredictedDimensionsChanged()
      return filters
    },
  },
  {
    component: CsatPredictedDriversPicker,
    key: filterKey.csatPredictedDriversPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'predictedTags',
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardPredictedTagsChanged()
      return filters
    },
  },
  {
    component: SurveyReasonPicker,
    key: filterKey.surveyReasonPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'csatReasons',
    hideFilter: !session.features.surveys,
    updateStateOnChanges: (filters) => {
      analytics.csatDashboardCsatReasonsChanged()
      return filters
    },
  },
  {
    component: ChannelPicker,
    key: filterKey.conversationChannelPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'channels',
  },
  {
    component: HelpdeskCustomFieldsControl,
    key: filterKey.customHelpdeskFilters,
    connectedParameter: 'helpdesksCustomFilters',
    isSidebarFilter: true,
  },
]

export default toSortedFilters(filters)
