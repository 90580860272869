import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_form_field = _resolveComponent("ui-form-field")!
  const _component_image_uploader = _resolveComponent("image-uploader")!
  const _component_drag_handler_icon = _resolveComponent("drag-handler-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_ctx.question)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.$style.content, { [_ctx.$style.contentError]: _ctx.hasErrors }])
      }, [
        _createVNode(_component_image_uploader, {
          modelValue: _ctx.question.images,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.question.images) = $event)),
          "onUpdate:unsavedImages": _cache[3] || (_cache[3] = ($event: any) => (_ctx.question.unsavedImages = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ui_form_field, {
              class: _normalizeClass(_ctx.$style.titleFormField),
              error: _ctx.showValidation && _ctx.questionErrors.empty_title && _ctx.$t('quizzes.form.errors.no_empty_field')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ui_input, {
                  modelValue: _ctx.question.text,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question.text) = $event)),
                  "data-testid": "question-field",
                  placeholder: _ctx.$t('quizzes.form.question_placeholder'),
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class", "error"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("span", {
          class: _normalizeClass(["text-xs font-semibold", _ctx.$style.optionsTitle])
        }, _toDisplayString(_ctx.$t('quizzes.form.options.title')), 3 /* TEXT, CLASS */),
        _createVNode(_component_draggable, {
          list: _ctx.options,
          tag: "ul",
          class: _normalizeClass(_ctx.$style.options),
          handle: ".drag-handler",
          draggable: ".draggable",
          "item-key": "id",
          onEnd: _ctx.handleOrderChange
        }, {
          item: _withCtx(({ element: option }) => [
            _createElementVNode("li", {
              "data-testid": "add-option-field",
              class: _normalizeClass([_ctx.$style.option, { draggable: option.type !== _ctx.ADD_OPTION_INPUT }])
            }, [
              (option.type !== _ctx.ADD_OPTION_INPUT)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass([_ctx.$style.dragHandler, "drag-handler"])
                  }, [
                    _createVNode(_component_drag_handler_icon, { class: "icon-m" })
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.optionCheckbox)
              }, [
                (option.type === _ctx.ADD_OPTION_INPUT)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.optionAddButton)
                    }, [
                      _createVNode(_component_plus_icon)
                    ], 2 /* CLASS */))
                  : (_openBlock(), _createBlock(_component_ui_checkbox, {
                      key: 1,
                      checked: option.correct,
                      "preserve-check-icon": "",
                      tabindex: "-1",
                      class: _normalizeClass([_ctx.$style.checkbox, !option.correct && _ctx.$style.notChecked]),
                      onChange: (value) => (option.correct = value) && _ctx.updateFields(_ctx.options),
                      onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                    }, null, 8 /* PROPS */, ["checked", "class", "onChange"])),
                _createVNode(_component_ui_input, {
                  modelValue: option.label,
                  "onUpdate:modelValue": [($event: any) => ((option.label) = $event), (value) => _ctx.handleInputChange(option, value as string)],
                  class: _normalizeClass([_ctx.$style.title, option.type !== _ctx.ADD_OPTION_INPUT && _ctx.$style.savedOption]),
                  placeholder: _ctx.$t('quizzes.form.options.add_option'),
                  onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "placeholder"])
              ], 2 /* CLASS */),
              _withDirectives(_createElementVNode("button", {
                "data-testid": "remove-option",
                class: _normalizeClass(_ctx.$style.optionRemoveButton),
                tabindex: "-1",
                type: "button",
                onClick: _withModifiers(() => _ctx.handleRemoveOption(option.id), ["prevent"])
              }, [
                _createVNode(_component_x_icon)
              ], 10 /* CLASS, PROPS */, _hoisted_1), [
                [_vShow, option.type !== _ctx.ADD_OPTION_INPUT]
              ])
            ], 2 /* CLASS */)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["text-xs font-medium", _ctx.$style.messageError])
            }, _toDisplayString(_ctx.showValidation && (_ctx.questionErrors.no_options || _ctx.questionErrors.no_correct_option)
              ? _ctx.$t('quizzes.form.errors.no_correct_option')
              : ''), 3 /* TEXT, CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["list", "class", "onEnd"])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}