import { computed, nextTick, ref, type Ref } from 'vue'
import { v4 as uuid } from 'uuid'
import { useRouter } from 'vue-router'
import i18n from '@/i18n'

import {
  FilterType,
  allFilters,
  filtersInitialized,
  getFilters,
  setActiveFilterId,
} from '@/modules/conversations/composables/useConversationFilters'

export default function (query: Ref<string> = ref('')) {
  const router = useRouter()

  if (!filtersInitialized.value) getFilters()

  const filters = computed(() => {
    if (!query.value) return []

    return [
      ...allFilters.value,
      { name: i18n.t('conversations.sub_nav.starred'), filterId: FilterType.Starred, isPublic: true },
      { name: i18n.t('conversations.sub_nav.drafts'), filterId: FilterType.Drafts, isPublic: true },
    ]
      .filter((filter) => filter.name.toLowerCase().includes(query.value.toLowerCase()))
      .map((f) => ({
        ...f,
        uuid: uuid(),
      }))
  })

  const selectFilter = async (id: number | string) => {
    setActiveFilterId(id)
    await nextTick()
    router.push({ name: 'conversations' })
  }

  return { filters, selectFilter }
}
