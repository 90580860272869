import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!

  return (_openBlock(), _createBlock(_component_ui_input, {
    "model-value": _ctx.val.toString(),
    type: "number",
    min: _ctx.minValue,
    max: _ctx.MAX,
    step: "0.1",
    onBlur: _ctx.limitValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.val = Number($event)))
  }, null, 8 /* PROPS */, ["model-value", "min", "max", "onBlur"]))
}