export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo sottodominio Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi a Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la tua azienda esiste già un account."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a un’azienda"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per unirti a un’azienda esistente, chiedi al tuo amministratore di inviarti un invito."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiedi al tuo amministratore di invitarti"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho cambiato idea."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla iscrizione"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la tua azienda esiste già un account. Vuoi unirti all’account della tua azienda o crearne uno nuovo?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia le istruzioni"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo account"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già un account"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non potrai accedere al tuo account Zendesk QA fino all’avvenuto pagamento delle fatture di abbonamento in arretrato."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’abbonamento è in sospeso"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account non trovato. Riprova."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imposta una nuova password."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Riprova oppure ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinazione email e password errata."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedine uno nuovo al tuo amministratore."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo link di invito non è valido o è già stato usato."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il metodo di accesso ", _interpolate(_named("method")), " è disattivato nel tuo account."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo invito non è valido o è già stato usato. Richiedine uno nuovo al tuo amministratore."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare le informazioni sull’invito."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscrizione non riuscita. Un utente con questo indirizzo email potrebbe esistere già."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato bloccato in seguito a tentativi di accesso consecutivi. Cerca nella casella di posta in arrivo un’email con le istruzioni su come sbloccarlo."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica prima l’indirizzo email"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un account email valido"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di accesso"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia link magico"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email lavoro"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo email di lavoro"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/it/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accordo sui servizi principali"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non corrisponde ai requisiti"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/it/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspetta"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Salve, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unisciti all’organizzazione ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo email è stato verificato. Ora puoi accedere con il tuo nuovo account qui sotto."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link di invito non valido"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con link magico"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna per accedere"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo indirizzo email è stato verificato. Ora puoi accedere con il tuo nuovo account qui sotto:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi tramite magic link"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi a Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato eliminato"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai un account?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un codice valido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un account email valido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di accesso"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutte le opzioni di accesso"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " tramite metodi tradizionali"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con link magico"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ricevuto un’email contenente il tuo codice di accesso."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ricevuto un’email che puoi utilizzare per l’accesso."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l’invio del magic link. Riprova."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi via email"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea password"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carattere minuscolo"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carattere maiuscolo"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nuova password deve contenere:"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lunghezza massima della password è di 128 caratteri"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facile da ricordare, difficile da indovinare"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo 8 caratteri"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea la tua password"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carattere minuscolo"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numero"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un carattere maiuscolo"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per reimpostare la password, inserisci il tuo indirizzo email e ti invieremo un’email con le istruzioni."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un account email valido"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["È stata inviata un’email a ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se l’email non appare nella casella della posta in arrivo o dello spam, verifica l’indirizzo email inserito."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna per accedere"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la posta in arrivo"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", ti verrà inviata un’email di reimpostazione della password."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se per il tuo account esiste già una password"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ", _interpolate(_named("bold")), ", ti abbiamo inviato un link per la reimpostazione della password."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l’indirizzo email è associato a un account"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l’invio del link di reimpostazione. Riprova."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta la password"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti mediante link magico"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Iscrivendoti, accetti i documenti ", _interpolate(_named("terms_of_service")), " e ", _interpolate(_named("privacy_policy")), " di Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni del servizio"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già un account?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti tramite magic link"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti con l’indirizzo email di lavoro"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti con Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti a Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti con Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho un account Zendesk attivo"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Iscriviti o accedi per completare la configurazione di ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei davvero tu? Controlla se nella tua casella di posta è presente un’email di conferma."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora è tutto pronto"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA funziona al meglio su desktop. Accedi per iniziare a rivedere le conversazioni."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota una demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppure, prenota una demo con noi e ti mostreremo cosa puoi fare in Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua presenza è richiesta"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annullare"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ho cambiato idea e voglio ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " ti invita a entrare nel proprio account."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti all’account"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo account"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova a effettuare l’accesso"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La reazione che hai ricevuto per il tuo feedback: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reazioni ricevute"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ha risposto al tuo commento"]), _normalize(["Diverse persone hanno risposto al tuo commento"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ha risposto alla tua risposta"]), _normalize(["Diverse persone hanno risposto alla tua risposta"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ha risposto alla tua revisione"]), _normalize(["Diverse persone hanno risposto alla tua revisione"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commento"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["risposta"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisione"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTESTAZIONI"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORNITE"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICEVUTE"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaggio"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reazioni"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniziate"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contrassegnare il commento come letto?"]), _normalize(["Contrassegnare tutti i commenti come letti?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contrassegnare la contestazione come letta?"]), _normalize(["Contrassegnare tutte le contestazioni come lette?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contrassegnare il feedback sul sondaggio come già letto?"]), _normalize(["Contrassegnare tutti i feedback sui sondaggi come letti?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contrassegnare la revisione come letta?"]), _normalize(["Contrassegnare tutte le revisioni come lette?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Commento contrassegnato come già letto"]), _normalize(["Tutti i commenti contrassegnati come letti"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contestazione contrassegnata come letta"]), _normalize(["Tutte le contestazioni contrassegnate come lette"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Feedback sul sondaggio contrassegnato come già letto"]), _normalize(["Tutti i feedback sui sondaggi sono stati contrassegnati come letti"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Revisione contrassegnata come letta"]), _normalize(["Tutte le revisioni contrassegnate come lette"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna tutti gli elementi come letti"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come letta"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisioni"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni contestate sono visualizzate qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando ti viene assegnata una contestazione da risolvere, questa viene visualizzata qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione ricevuta"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni da te contestate sono visualizzate qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione avviata"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i feedback a te forniti vengono visualizzati qui. Nessun elemento da mostrare."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun feedback ricevuto"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le reazioni alle revisioni e alle risposte vengono visualizzate qui. Nessun elemento da mostrare."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna reazione ricevuta"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri una conversazione"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i feedback forniti da te vengono visualizzati qui. Inizia lasciando una revisione nella vista Conversazioni."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna revisione fornita"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i feedback a te forniti vengono visualizzati qui. Nessun elemento da mostrare."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna revisione ricevuta"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun commento trovato"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i feedback che lasci sotto forma di commenti vengono visualizzati qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun commento fornito"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun commento trovato"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i commenti in una revisione di tua proprietà, le menzioni ", "@", " o le risposte ai tuoi commenti vengono visualizzati qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun commento ricevuto"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione accettata"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione aperta"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione rifiutata"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna contestazione risolta"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova scegliendo un’altra opzione dal filtro qui sopra."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova scegliendo un altro utente dal filtro qui sopra."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti forniti"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti ricevuti"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le contestazioni"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni ricevute"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni avviate"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sul sondaggio ricevuto"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni fornite"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco attività"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " riviste"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " rivista"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " riviste"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verrà riavviato tra ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tempo rimasto: ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta un’assegnazione"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco assegnazioni"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Non è possibile creare una sessione di coaching tra ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), " poiché attualmente non si trovano nello stesso spazio di lavoro."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delle sessioni di coaching tra ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), " esistono già. Contatta ", _interpolate(_list(0)), " per accedere alla sessione."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerito"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Una nuova sessione tra ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), " è stata creata."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona coach"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a coaching"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona soggetto sottoposto a coaching"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sessione"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una sessione"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi sessione"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome sessione"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerito"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In arretrato"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossima"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sessione"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione trovata"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo medio di revisione"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato da mostrare per il periodo selezionato"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elemento fissato aggiunto alla sessione. ", _interpolate(_named("buttonStart")), "Mostra", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi a..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunti"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto la fine degli elementi fissati nel periodo selezionato"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scopri come funzionano gli elementi fissati in questo ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["articolo"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sembra che tu non abbia fissato nessun elemento sotto ", _interpolate(_list(0)), ". Puoi usare gli elementi fissati per prepararti per sessioni individuali o salvare qualcosa per un altro momento."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il soggetto sottoposto a coaching"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun elemento fissato aggiunto"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ecco tutti gli elementi fissati che hai creato sotto ", _interpolate(_named("name")), ". Gli elementi fissati aggiunti alle sessioni saranno ", _interpolate(_named("bold")), " con l’altro partecipante."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immediatamente condivisi"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sembra che ", _interpolate(_list(0)), " non abbia ricevuto alcun feedback per la revisione della conversazione o CSAT durante il periodo selezionato."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun feedback disponibile"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto la fine dei commenti nel periodo selezionato"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriche"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Numeri di ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo elemento azione"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Completato da ", _interpolate(_named("user")), " il ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["da ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["te"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita per aggiungere un nuovo elemento azione"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che cosa è emerso da questa riunione? Quali sono i prossimi passi?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi azione"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creato da ", _interpolate(_named("name")), " il ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminerà la sessione"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’operazione ", _interpolate(_named("deleteSession")), " in modo permanente. I partecipanti perdono l’accesso alla sessione, inclusi i contenuti e le note private."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sessione creata in data ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultima modifica apportata da ", _interpolate(_named("name")), " il ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di discussione e note"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota aggiornata"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi sessione"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da questo momento in poi, gli utenti selezionati avranno accesso ai dettagli della sessione."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utenti"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condivisa"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi sessione"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Non farai più parte della sessione ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " non farà più parte di questa sessione."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " ora ha accesso a questa sessione."]), _normalize([_interpolate(_named("n")), " nuovi utenti ora hanno accesso a questa sessione"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina sessione"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina tutte le sessioni"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina sessione"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le note aggiunte dal ", _interpolate(_list(0)), " saranno visualizzate qui."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi gli elementi fissati come punti di discussione nel pannello di destra"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tu puoi vedere queste note..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note personali"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota privata aggiornata"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 giorni"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a coaching"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi azione"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima sessione"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In arretrato"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica delle sessioni"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutte"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Io: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Io: Soggetto sottoposto a coaching"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le sessioni"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni condivise"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a cambiare filtro per visualizzare gli elementi azione."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun elemento azione"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi azione"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute e visualizzate"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevute · Visualizzate"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzamento dopo le sessioni"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che gli agenti non abbiano ricevuto alcun feedback."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica di disporre del nome corretto dell’utente"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile trovare l’utente"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato disponibile"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS degli ultimi 30 giorni"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’operazione <b>eliminerà tutte le sessioni</b> (2) con <b>", _interpolate(_named("name")), "</b> in maniera permanente. I partecipanti perdono l’accesso a tutte le sessioni, inclusi i contenuti e le note private."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto la fine di tutte le sessioni"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una sessione di coaching è un’attività di gruppo tra un mentore e un soggetto sottoposto a coaching per discutere di prestazioni, opportunità e traguardi a breve e lungo termine."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi cercare le sessioni in base al nome del coach e alla persona che riceve il coaching."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione trovata"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna sessione creata"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina cronologia coaching"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina cronologia coaching "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima sessione"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le sessioni"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a coaching"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come completata"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come non completata"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per nome"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco sessioni"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non pianificata"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi a questo messaggio"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessione di calibrazione scaduta. Non è possibile lasciare ulteriori revisioni."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già lasciato una revisione per questa conversazione."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revisione di questa conversazione non è più possibile poiché la data di scadenza della sessione è trascorsa."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa conversazione non ha revisioni"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi pannello"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia una revisione della calibrazione"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETTAGLI"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIEPILOGO"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRASCRIZIONE"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Soggetto parlante ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " s"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["momento di silenzio"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica soggetto parlante"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi messaggio"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al messaggio in riproduzione"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetti parlanti"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascrizione"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti spazio di lavoro"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi prima la connessione per creare filtri"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun bot"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creato il ", _interpolate(_named("time")), " da parte di ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultimo aggiornamento il ", _interpolate(_named("time")), " da parte di ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sembra che tu non abbia filtri. Per accedere a questa pagina, devi prima creare un filtro. Inizia con ", _interpolate(_named("recommendedFilter")), " per vedere le tue conversazioni. Crea il filtro qui sotto per continuare."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negli ultimi 30 giorni"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La condizione \"Creata negli ultimi 30 giorni\" verrà aggiunta ai filtri creati senza un periodo di tempo"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il filtro non è disponibile in questo piano e non verrà preso in considerazione durante la visualizzazione dei risultati"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero opzioni filtro scaduto. Riprova."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui filtri"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario = persona che ha effettuato l’accesso"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Conversazioni trovate: ", _interpolate(_named("count"))]), _normalize(["Conversazione trovata: ", _interpolate(_named("count"))]), _normalize(["Conversazioni trovate: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile nel piano corrente. Passa a un piano superiore per usare questa funzione."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi condizione"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cura di Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizione"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasforma in assegnazione"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea filtro"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea filtro"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi personalizzati"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo caricando le opzioni di filtro in base alla tua specifica configurazione. Il caricamento iniziale potrebbe richiedere qualche minuto."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna un nome descrittivo al filtro"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome filtro"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un’opzione"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizione"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privato"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblico"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica filtro"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modifiche"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun valore disponibile"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non trovati"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un valore"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo sessioni attive ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra sessioni precedenti ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica sessione di calibrazione"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sessione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sessione di calibrazione"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le revisioni della calibrazione fornite durante la sessione verranno eliminate definitivamente."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina sessione"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina sessione di calibrazione ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata creata alcuna sessione"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione attiva"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione creata"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione attiva"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi filtro"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un filtro privato"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un filtro pubblico"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro aggiunto"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il filtro verrà eliminato definitivamente."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina filtro"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina filtro ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le contestazioni"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni per me"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni da parte mia"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni aperte"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozze"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro aggiunto"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro privato creato"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro pubblico creato"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri privati"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri pubblici"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvarlo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa è la vista di un filtro temporaneo."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione del filtro in corso..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro aggiornato"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria AutoQA personalizzata"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultimo aggiornamento: ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole complesse"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autovalutazioni si aggiornano dinamicamente in base alle modifiche ai ticket"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidenzia nella conversazione"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frasi lunghe"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun punteggio automatico previsto"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono stati rilevati errori"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra parole complesse"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra frasi lunghe"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione successiva"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA non ha potuto valutare questa categoria perché il contesto è insufficiente. Si consiglia la revisione manuale."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri insight sulle conversazioni automatizzate per semplificare il processo di revisione. Evidenzia le conversazioni di importanza cruciale e mostra esempi positivi di spicco per il tuo team."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Chiamata]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corretti"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminati"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ha accettato la contestazione"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contestazione inviata a ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiega perché ritieni che questo soggetto sottoposto a revisione sia il destinatario sbagliato per questo feedback"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un commento e imposta un nuovo soggetto sottoposto a revisione"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un commento"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiega perché ritieni che questi punteggi siano valutati in modo errato (obbligatorio)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un commento (obbligatorio)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiega perché stai rifiutando questa contestazione"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un commento"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia contestazione a"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia contestazione a"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia contestazione a"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona nuovo soggetto sottoposto a revisione"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sulla contestazione"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sulla contestazione"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia soggetto sottoposto a revisione"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia contestazione a"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contesta questa revisione"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono d'accordo con i punteggi"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa revisione non era indirizzata a me"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ha accettato parzialmente la contestazione"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ha respinto la contestazione"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettata"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettata parzialmente"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutata"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperta"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ha avviato una contestazione: rivedi"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ha avviato una contestazione: soggetto sottoposto a revisione errato"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una contestazione aperta deve essere risolta per poter intraprendere altre azioni in merito alla revisione."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona nuovi punteggi"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina contestazione"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi ignorare questa contestazione?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina contestazione"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazione"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di soluzione delle contestazioni"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona nuovi punteggi equi (facoltativo)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione contestazione"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione errato"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta contestazione"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta contestazione"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sulla contestazione"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione errato"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sulla contestazione"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta contestazione"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta contestazione"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza la contestazione"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificati"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " da ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["a ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivisti"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione completata"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnata a:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia anche un commento"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulle schede punteggi condizionali"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedentemente assegnato a:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegline uno"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile trovare GIF con quel nome"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sii il primo a lasciare una revisione."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’abbonamento è attualmente in pausa. Riattivalo per abilitare l’invio di feedback."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserito da:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra quanto tempo è stato dedicato alla revisione di questa conversazione"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Causa principale mancante per la categoria: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda punteggi:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta una categoria o aggiungi un commento"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimi"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espandi campo commenti"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimi campo commenti"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi formattazione"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi pannello"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra formattazione"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra pannello"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia revisione"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta tutte le categorie"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta almeno una categoria"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva questa conversazione per dopo"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva per dopo"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione successiva"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituisci conversazione in questo ciclo di assegnazione"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi conversazione"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione di base"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina commento"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento eliminato"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare questo commento?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore commento"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo hashtag è stato eliminato o rinominato."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi ciò che ti è piaciuto o cosa avrebbe potuto essere migliore..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenta"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizionale"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verranno eliminati definitivamente tutti i commenti."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina commento"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Messaggio]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " non ha ancora visto questo feedback"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ha visto questo feedback"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex membro"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premi Invio per creare un nuovo hashtag."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli hashtag personalizzati sono bloccati dall’amministratore."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa per aggiungere segnalibri ai casi e filtrarli in un secondo momento"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come baseline"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sui messaggi"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione successiva"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun feedback"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna revisione fornita"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per iniziare, lascia una revisione qui sotto."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono rimaste altre revisioni"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non valutata"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario precedente"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione precedente"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi come baseline"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi a questa revisione"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi la barra laterale delle revisioni"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione eliminerà definitivamente la revisione e tutti i commenti aggiunti."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina revisione"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina revisione"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione eliminata"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisione eliminata da ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione completata."])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna questa attività a te stesso per lasciare una revisione"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già lasciato una revisione per questo utente."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hai lasciato una revisione per ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria da mostrare per questa conversazione."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autovalutazioni non sono disponibili per questo spazio di lavoro."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non salvare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi salvare questa revisione come bozza?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta questa bozza"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra la barra laterale delle revisioni"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non superato"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superato"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superato con errori minori"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto ancora elaborando i numeri..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia un commento"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi questo messaggio"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi te stesso"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi questa conversazione"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente da rivedere"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazioni disabilitate"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti spazio di lavoro"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utente"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 revisione"]), _normalize([_interpolate(_named("count")), " revisioni"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona nuove valutazioni"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manager dello spazio di lavoro non ha consentito l’autovalutazione."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premi Invio per contrassegnare questo elemento fissato"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazioni"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 30 giorni"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciali"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una sessione di calibrazione"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozze"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli insight forniscono una rappresentazione visiva dei dati dell’help desk. Usa i grafici interattivi per capire dove concentrare le tue attività di QA, scoprire i punti ciechi e trovare gli agenti giusti da rivedere in base alle loro prestazioni."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduci in ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi a"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assegnato"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attualmente in visualizzazione"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione in corso"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa conversazione è stata rimossa e non è più disponibile."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione che stai cercando non esiste in Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli elementi fissati con questa conversazione"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In sessione"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi conversazione"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La rimozione di una conversazione da una sessione eliminerà tutte le revisioni a essa assegnate."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rimuovi conversazione da ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna sessione di calibrazione creata."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa conversazione è stata aggiunta alla sessione"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa conversazione è stata rimossa dalla sessione"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi a/rimuovi da una sessione di calibrazione"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia link a questa conversazione"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione da visualizzare"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email del cliente"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cliente"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome cliente"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome modulo esterno"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di prima replica"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al sondaggio"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio soddisfazione"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo prima soluzione"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originale"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo messaggio non esiste in questa conversazione oppure è stato eliminato"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun oggetto"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione da visualizzare"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione trovata"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri conversazione originale"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile collegare alla conversazione: URL mancante"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun partecipante"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa conversazione in alto"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic per leggere le revisioni"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic per rivedere"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione creata tramite l’estensione del browser"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza sondaggio sulla soddisfazione dei clienti"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza conversazione"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio con sentiment negativo"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio con sentiment positivo"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduci in inglese"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra originale"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina conversazione"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’eliminazione di una conversazione è irreversibile. Inoltre, vengono eliminati tutti i dati correlati, ad esempio sondaggi, trascrizioni e revisioni da Zendesk QA, incluse le dashboard.", _interpolate(_named("lineBreak")), "Potrai comunque accedere a questa conversazione in Zendesk Support o in un altro software di help desk in uso."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’eliminazione di una conversazione è irreversibile. Inoltre, vengono eliminati tutti i dati correlati, ad esempio sondaggi, trascrizioni e revisioni da Zendesk QA, incluse le dashboard. Se la conversazione appartiene a più spazi di lavoro, verrà eliminata da tutti.", _interpolate(_named("lineBreak")), "Potrai comunque accedere a questa conversazione in Zendesk Support o in un altro software di help desk in uso."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina conversazione"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina conversazione da Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa conversazione è incompleta a causa dell’escalation. Vai a ", _interpolate(_named("optionsUrl")), " e convalida i tag. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa conversazione è incompleta a causa dell’escalation. Chiedi al tuo amministratore di convalidare i tag. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni > Opzioni avanzate"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui tag"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiuso"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risolto"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assegnatario cambiato con ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assegnato"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Macro ", _interpolate(_named("target")), " applicata da ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La conversazione ha ricevuto una valutazione ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’utente ", _interpolate(_named("target")), " ha mascherato il testo della conversazione"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stato cambiato in ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contrassegnato con ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team cambiato in ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ha posticipato la conversazione per ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ha annullato il posticipo della conversazione"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot bloccato in un ciclo: ripete lo stesso messaggio a turni consecutivi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetizione bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetizione bot, in cui lo stesso messaggio viene ripetuto una o più volte in successione"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trovate frasi corrispondenti"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un agente medio gestisce la conversazione in modo ", _interpolate(_named("percent")), " più efficiente del bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficienza delle comunicazioni dei bot"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il bot ha gestito la conversazione in modo ", _interpolate(_named("percent")), " più efficiente di un agente medio"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficienza delle comunicazioni dei bot"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella conversazione manca la dichiarazione necessaria per informare i partecipanti della registrazione, che è obbligatoria per conformità e trasparenza"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgazione registrazione mancante"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si è verificata una violazione di un accordo sul livello di servizio (SLA) per ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violazione SLA"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La metrica per la regola SLA ha superato il tempo previsto"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnali di possibile abbandono del cliente. Il cliente stava valutando un cambiamento o ha preannunciato di andarsene."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato identificato il rischio di abbandono"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La conversazione ha momenti di silenzio che durano più a lungo della soglia impostata di ", _interpolate(_list(0)), "s"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 istanza"]), _normalize([_interpolate(_named("count")), " istanze"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s più lungo"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento di silenzio"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha richiesto assistenza di livello superiore"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation richiesta"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha espresso estrema gratitudine o è rimasto molto soddisfatto del supporto ricevuto"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio eccezionale"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente o l’agente ha richiesto esplicitamente un follow-up"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di follow-up"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha mostrato insoddisfazione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment negativo"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione è stata insolita o atipica e ci sono stati diversi botta e risposta per trovare una soluzione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalia rilevata"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha mostrato soddisfazione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positivo"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicabile"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta come non applicabile"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultima modifica apportata da ", _interpolate(_named("author")), " il ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicabile"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Di ", _interpolate(_named("author")), " il ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un motivo (facoltativo)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun insight rilevato"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi connessione"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega l’help desk al tuo account e crea almeno un filtro per visualizzare l’elenco delle conversazioni."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione da visualizzare"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza le impostazioni dello spazio di lavoro"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tra un giorno)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Questo mese)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Questa settimana)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova per ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Risultati della ricerca di ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivista da altri"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentate"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più recenti"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno recenti"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casuali"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornate di recente"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con risposte recenti"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lette"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivista da te"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il processo di importazione potrebbe richiedere del tempo. Ti invieremo un’email al termine dell’operazione."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA sta importando le conversazioni"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Limite: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi facendo clic sull’icona di calibrazione nell’intestazione della vista conversazione."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niente da segnalare qui. Riprova usando un filtro o un’opzione di ordinamento diversi."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna anomalia da visualizzare."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova una conversazione pertinente e fai clic sull’icona a forma di stella nell’intestazione per aggiungerla ai segnalibri qui."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a cambiare i filtri o invita altri agenti dell’help desk ad aggiungere altre conversazioni."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun limite"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota interna"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo caricando le conversazioni in questo momento. Alcuni contenuti potrebbero ancora essere mancanti."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente successivo"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri spazio di lavoro"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente precedente"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione aggiunta"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione è stata aggiunta"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni non completate finiscono qui. Rileggi, rivedi e invia nei prossimi 30 giorni."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna bozza di revisione"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione speciale"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione da visualizzare"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna corrispondenza"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al messaggio selezionato"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor numero di risposte"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggior numero di risposte"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco conversazioni"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Risposta alla chiamata alle ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chiamata terminata alle ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chiamata effettuata alle ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivista"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivista da te"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Impossibile caricare il file audio. Assicurati di aver effettuato l’accesso a ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al sondaggio sulla soddisfazione dei clienti"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante il caricamento dell’audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore commento"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ha avuto luogo alcuna chiamata"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascolta nell’help desk"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascolta su Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità di riproduzione"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi chiamata"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascrizione"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basso"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non un utente Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA classifica automaticamente gli agenti in base al CSAT medio mensile. Trova con un semplice clic le conversazioni gestite dagli agenti il cui CSAT è inferiore ai colleghi."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifica per CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medie"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basse"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minime"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massime"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non un utente Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un solo ticket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA classifica automaticamente gli agenti in gruppi di prestazioni in base al numero di conversazioni che gestiscono al mese. Trova con un semplice clic le conversazioni gestite dagli agenti il cui volume è inferiore ai colleghi."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifica per volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivisti"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non rivisti"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato revisione"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basso"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non un utente Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA classifica automaticamente gli agenti in base al sentiment medio nelle conversazioni. Trova con un semplice clic le interazioni in cui gli agenti trattano con clienti più frustrati rispetto ai loro colleghi."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifica per sentiment"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non rilevata"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevata"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalie"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni che richiedono la tua attenzione. Il nostro modello di apprendimento automatico identifica le interazioni complesse o atipiche per una revisione ulteriore."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalie"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona le conversazioni da rivedere in cui il cliente ha mostrato soddisfazione o frustrazione. Usa il menu a discesa per selezionare il tipo di sentiment che cerchi nelle conversazioni. Trascina l’attributo filter in alto per vedere la proporzione del sentiment scelto in tutte le interazioni."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato revisione"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non riviste"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riviste"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA rileva automaticamente le lingue nelle conversazioni. Usa il menu a discesa per selezionare le conversazioni in lingue specifiche per la revisione. Trascina l’attributo filter in alto per avere una panoramica di tutte le lingue usate nel tuo help desk."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sulle conversazioni"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Con gli insight puoi ", _interpolate(_named("paragraph_2_item")), " rispetto ai normali filtri di conversazione. Ottieni una panoramica visiva di tutti i dati dal tuo help desk ed esplora insight come:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sull’uso degli insight sulle conversazioni"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendi l’importanza relativa di specifici attributi di conversazione in relazione agli altri"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova gli agenti con le prestazioni migliori e peggiori con la classifica delle prestazioni degli agenti incorporata"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " è un potente strumento di data mining interattivo che ti aiuta a trovare le conversazioni che necessitano di maggiore revisione."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fare molto di più"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I grafici Conversazione e Agente sono ", _interpolate(_named("paragraph_3_item_1")), ": l’aggiornamento dei filtri o il clic sulle sezioni del grafico segmentato in uno di essi aggiornerà automaticamente l’elenco delle conversazioni. ", _interpolate(_named("paragraph_3_item_2")), " per esplorare le ripartizioni o i rapporti dettagliati dei diversi elementi."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconnessi"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riordina gli attributi del filtro"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gli insight sulle conversazioni visualizzano solo ", _interpolate(_named("paragraph_4_item")), ". Rimuoviamo automaticamente le interazioni senza un reale valore di revisione, come messaggi generati automaticamente, spam, risposte di bot o messaggi privi di un vero dialogo con il rappresentante dell’assistenza."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversazioni pulite e concluse"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa è visualizzato qui?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " su ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " su ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " su ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " di ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile recuperare"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Non disp."])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenti"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " del totale)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma siamo sicuri che sarà fantastico una volta arrivato."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggio personalizzato"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun feedback sui sondaggi rimanente"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun punteggio di feedback sui sondaggi disponibile..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevuta"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag di feedback"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag di commento di feedback"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro periodo di tempo applicato"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " risposta"]), _normalize([_interpolate(_named("count")), " risposte"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Approfondisci il motivo per cui è stato fornito un punteggio ", _interpolate(_list(0)), " impostando i motivi nelle impostazioni del sondaggio."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ancora nessun motivo ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui motivi del sondaggio"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni con tag:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " per"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivi"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria commento"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " motivi"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fattori ", _interpolate(_named("surveyType")), " previsti"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine conversazione"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag dell’help desk"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Risposte al sondaggio (", _interpolate(_named("n")), ")"]), _normalize(["Risposta al sondaggio (", _interpolate(_named("n")), ")"]), _normalize(["Risposte al sondaggio (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun oggetto"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversazioni con ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " per utenti"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di risposta"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il punteggio ", _interpolate(_list(0)), " è la somma di tutte le risposte divise per la somma dei punteggi massimi"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica obiettivo"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi obiettivo"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta un obiettivo"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiettivo"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi obiettivo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corto"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molto lungo"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lungo"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di media lunghezza"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confuso"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non utile"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza scarsa"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza buona"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emozioni in abbondanza"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback per l’agente"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclamo"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Briciole"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema non risolto"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema risolto"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment negativo"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esito negativo"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positivo"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lode"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto scadente"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza rapida"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza lenta"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA assegna automaticamente i tuoi dati ", _interpolate(_list(0)), " in categorie predefinite. In questo modo puoi comprendere insight complessi ed esplorare le cause senza dover esaminare manualmente il feedback scritto."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i punteggi"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i sondaggi"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la lingua di risposta"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione commento"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la dimensione del commento"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con il 25% dei commenti composto da una sola parola, i commenti più lunghi sono assolutamente da leggere. Trova feedback più approfonditi senza alcun lavoro manuale, poiché lo svolgiamo noi automaticamente per te."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del feedback"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il motivo del feedback"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun punteggio corrispondente"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori previsti"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la categoria dei commenti"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole Wordcloud"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona parole wordcloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dall’ultimo periodo"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversazioni"]), _normalize([_interpolate(_named("n")), " conversazione"]), _normalize([_interpolate(_named("n")), " conversazioni"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile calcolare il rapporto dei sondaggi inviati da tutte le conversazioni per mancanza di dati."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi inviati"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbuto sondaggio"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " di ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " di ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " di ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fattori ", _interpolate(_named("surveyType")), " previsti"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA assegna automaticamente i tuoi dati ", _interpolate(_list(0)), " in categorie predefinite. In questo modo puoi comprendere insight complessi ed esplorare le cause senza dover esaminare manualmente il feedback scritto."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione commento"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con il 25% dei commenti composto da una sola parola, i commenti più lunghi sono assolutamente da leggere. Trova feedback più approfonditi senza alcun lavoro manuale, poiché lo svolgiamo noi automaticamente per te."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data/ora"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte al sondaggio"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (media)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (media)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiettivo"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tendenze ", _interpolate(_list(0)), " per"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivi"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data di invio sondaggio ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Data di risposta al sondaggio ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Assegna punteggio ", _interpolate(_list(0)), " a"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dati in base a"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione conversazione"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proprietario del ticket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agente con il maggior numero di risposte"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (media)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nel tempo"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " e IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni che includono queste parole:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag cloud"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per utenti"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbuto sondaggio"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte al sondaggio"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag dell’help desk"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT nel tempo"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun canale"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna origine"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna lingua"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun motivo"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun tag"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria di commento"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato disponibile perché non sono state create assegnazioni o non sono stati avviati cicli. Ricontrolla più tardi o modifica i filtri."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri le novità"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " elemento"]), _normalize([_interpolate(_named("count")), " elementi"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversazione"]), _normalize([_interpolate(_named("count")), " conversazioni"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non superato"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superato"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superato con errori minori"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superata/Non superata"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media (ore)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valutazioni ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT medio = [somma dei punteggi di feedback ÷ (numero di tutte le risposte × punteggio massimo possibile)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio CSAT di Zendesk QA normalizza il punteggio del feedback in una scala da 0 a 100% e calcola la media dei punteggi standardizzati."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio CSAT (Customer Satisfaction Score) quantifica la soddisfazione dei clienti per i servizi offerti."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Ogni revisione rappresenta la revisione da parte di una persona in una conversazione. Una conversazione può avere più di una revisione (se due o più revisori esaminano la stessa conversazione)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni traccia dell’evoluzione dei punteggi del revisore nel tempo."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni traccia dell’evoluzione dei punteggi degli agenti nel tempo."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " risposte"]), _normalize([_interpolate(_named("n")), " risposta"]), _normalize([_interpolate(_named("n")), " risposte"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " revisioni"]), _normalize([_interpolate(_named("n")), " revisione"]), _normalize([_interpolate(_named("n")), " revisioni"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Internal Quality Score) è il risultato delle revisioni delle conversazioni."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = somma dei punteggi delle revisioni ÷ numero di revisioni"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene calcolata come una media, tenendo conto di tutti i punteggi delle revisioni (media ponderata del punteggio della categoria) corrispondenti alle condizioni di filtro selezionate."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la scheda."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i filtri per riprovare."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tasso di superamento indica il risultato delle revisioni delle conversazioni."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non superato, se il punteggio della revisione è ", _interpolate(_named("failScore")), " o inferiore"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Superato, se il punteggio della revisione è ", _interpolate(_named("passScore")), " o superiore"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È determinato da una linea di base predefinita, ossia è:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di approvazione = Somma delle revisioni superate ÷ Somma del totale delle revisioni"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tasso di superamento indica una percentuale del numero di revisioni superate senza superare le categorie critiche o non critiche."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ad esempio, se il revisore fornisce 3 revisioni in questo giorno, il numero visualizzato è la media dei 3 punteggi. Scopri come viene calcolata ogni cella ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ad esempio, se l'agente riceve 3 revisioni in questo giorno, il numero visualizzato è la media dei 3 punteggi. Scopri come viene calcolata ogni cella ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori non presi in considerazione: peso della categoria, stato critico (a differenza dei punteggi nel tempo)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poiché le categorie possono essere contrassegnate come n/d nelle revisioni, il numero di revisioni per categoria potrebbe essere inferiore al totale delle revisioni da parte del revisore. Scopri come viene calcolata ogni cella"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori non presi in considerazione: peso della categoria, stato critico (a differenza dei punteggi nel tempo)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poiché le categorie possono essere contrassegnate come n/d nelle revisioni, il numero di revisioni per categoria potrebbe essere inferiore al totale delle revisioni per agente. Scopri come viene calcolata ogni cella"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori non presi in considerazione: peso della categoria, stato critico (a differenza dei punteggi nel tempo)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " i gruppi di categorie vengono visualizzati separatamente. Il punteggio di ciascun gruppo viene calcolato in base al punteggio medio della categoria per quel gruppo nello stesso periodo. Scopri come viene calcolata ogni cella ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori non presi in considerazione: peso della categoria, stato critico (a differenza dei punteggi nel tempo)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " i gruppi di categorie vengono visualizzati separatamente. Il punteggio di ciascun gruppo viene calcolato in base al punteggio medio della categoria per quel gruppo nello stesso periodo. Scopri come viene calcolata ogni cella ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "% baseline"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza errori critici"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tasso di assenza di errori critici indica una percentuale del numero di revisioni superate senza escludere alcuna categoria critica."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio IQS (Internal Quality Score)"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Altre” cause principali"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica periodica"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica giornaliera"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica mensile"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica settimanale"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica annuale"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi nel tempo"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza in giorni o settimane."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo = punteggio totale per giorno o settimana diviso per il totale delle revisioni fornite"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza in giorni o settimane."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcolo = punteggio totale per giorno o settimana diviso per il totale delle revisioni ricevute"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi per categoria"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza la media combinata di tutte le valutazioni delle categorie fornite dal revisore durante questo periodo."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza la media combinata di tutte le valutazioni delle categorie ricevute da ciascun agente durante questo periodo."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause principali delle valutazioni basse"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause principali delle valutazioni"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi delle categorie nel tempo"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza confronto tra categorie nel tempo."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i punteggi medi assegnati per ciascuna categoria in un giorno o una settimana prescelti."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza confronto tra categorie nel tempo."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i punteggi medi ricevuti per ciascuna categoria in un giorno o una settimana prescelti."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi in base alle revisioni"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviata"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria critica"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala di valutazione"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media/Totale"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi autovalutazioni"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta tutte le schede"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forniti"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuti"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["conversazione"]), _normalize(["conversazioni"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["elemento"]), _normalize(["elementi"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " risposte"]), _normalize([_interpolate(_named("n")), " risposta"]), _normalize([_interpolate(_named("n")), " risposte"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " revisioni"]), _normalize([_interpolate(_named("n")), " revisione"]), _normalize([_interpolate(_named("n")), " revisioni"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mostrane un altro"]), _normalize(["Mostra altri ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 30 giorni"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 7 giorni"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo mese"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima settimana"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese corrente"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana corrente"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo personalizzato"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesi"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestri"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimane"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anni"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la scheda. Modifica i filtri per riprovare."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato da visualizzare."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica mensile"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I filtri attivi includono dati in diverse scale di valutazione."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli elementi fissati nelle schede con questi filtri"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli elementi fissati nelle dashboard con questi filtri"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo medio di revisione"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sulle categorie"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Commento"]), _normalize(["Commenti"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrice"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex membro"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio IQS (Internal Quality Score)"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (media)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni superate"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa scheda in alto"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa dashboard in alto"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Causa principale"]), _normalize(["Cause principali"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID revisione"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di revisione"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta cause principali"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cause principali ti permettono di sapere perché le conversazioni stanno ottenendo punteggi bassi"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna causa principale impostata per questa categoria"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio/Totale"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie di valutazione"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi di categorie di valutazione"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo di categorie"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio di lavoro"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza ordine"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggruppa categorie per"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutto"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi autovalutazioni"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includi autovalutazioni"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra solo autovalutazioni"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtri"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun hashtag trovato"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag dei commenti"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona hashtag dei commenti"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun tag trovato"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag dell’help desk"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca tag dell’help desk"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna scheda punteggi trovata"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona schede punteggi"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazioni"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna connessione corrispondente"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine conversazione"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona origine"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri filtri"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro corrispondente"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale tempi indicati"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i gruppi"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo corrispondente."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i gruppi"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza schede"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dati in base a"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione revisione"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione conversazione"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra due cifre decimali"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza impostazioni"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli spazi di lavoro"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intero account"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome spazio di lavoro"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzate"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandiere"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo e bevande"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animali e natura"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetti"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone e parti del corpo"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi e luoghi"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usate di frequente"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati ricerca"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faccine ed emoticon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simboli"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna emoji trovata"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri una conversazione"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna all’elenco attività"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " oppure sfoglia l’elenco di ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai usando lo spazio di lavoro ", _interpolate(_named("workspace")), ", che non è connesso a questo dominio. Fai clic qui per connetterlo ora."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connessioni disponibili"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non puoi ancora inviare feedback per ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta l’help desk come scheda attiva."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo dominio non può essere ancora verificato. Se necessario, avvisa l’amministratore."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi questo dominio"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connetti con ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi un attimo. Impostazione in corso"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile ottenere l’URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strumenti già collegati all’account Zendesk QA dell’organizzazione."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno strumento collegato all’account Zendesk QA dell’organizzazione."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consente di rivedere i siti web in cui l’URL rimane sempre lo stesso, aggiungendo un hash univoco alla fine prima di lasciare una revisione"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia account"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi domini"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domini già connessi a questo spazio di lavoro."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi rivedere qualsiasi pagina nei seguenti domini"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domini disponibili per la revisione"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estensione"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL univoco"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nessun help desk ancora connesso. Vai a ", _interpolate(_named("link")), " per aggiungerne uno."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’estensione del browser Zendesk QA può essere usata solo\ncon i piani Professional e Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia spazio di lavoro"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisci un nome"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisci un valore"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi personalizzati"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa è la prima volta che incontriamo questa conversazione. Fornisci alcuni dettagli."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento conversazione:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa con cui identificare il ticket in un secondo momento"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisci sia l’assegnatario che un oggetto"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In fase di creazione"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi questa conversazione"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assegnato"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contatta il tuo manager o passa a uno spazio di lavoro diverso in ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autovalutazioni sono disabilitate per questo spazio di lavoro."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivederci"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio di lavoro"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo URL non può essere verificato. Vai a una conversazione."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi a Zendesk QA tramite il provider SSO, quindi riprova."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apertura popup di accesso."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con il tuo account Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione in corso..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora un account?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai accesso a questa conversazione. Se necessario, contatta il tuo manager."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun accesso"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica che la connessione sia attivata in Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci le connessioni"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di connessione"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai a una conversazione nell’help desk per continuare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL non valido"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riconnessione quasi completata."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un momento"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a ricaricare la pagina per riprendere il flusso delle cose"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberati dai confini virtuali del tuo help desk e rivedi le conversazioni ovunque."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano di abbonamento superiore per usare l’estensione"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci il tuo abbonamento"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connessione all’help desk non è legata a questo spazio di lavoro"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’help desk non riconosce questo ticket oppure Zendesk QA non può accedervi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile trovare la conversazione"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova attività"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ricevuto del feedback"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso riuscito"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi usare l’app"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta tutte le categorie"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa per aggiungere segnalibri ai casi e filtrarli in un secondo momento"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun feedback per questa conversazione"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi feedback"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto in Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui verranno visualizzati tutti i feedback e le notifiche. Ancora niente..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri di più in Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli conversazione"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene tutto di"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è tutto di"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non contiene tutto di"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è tutto di"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["migliore di quella dell’agente"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peggiore di quella dell’agente"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falso"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è maggiore di"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene uno qualsiasi di"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è uno qualsiasi di"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non contiene nessuno di"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è nessuno di"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vero"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["violati"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha commenti"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha commenti miei"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ha commenti"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ha commenti miei"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critico per la revisione"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 30 giorni"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entro"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dal"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 14 giorni"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultime 24 ore"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 30 giorni"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 7 giorni"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimo mese"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultima settimana"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese corrente"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settimana corrente"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fino a"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rilevato"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esiste"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contrassegnato da me"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non contrassegnato da me"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["include"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["superiore a"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partire da"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inferiore a"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fino a"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negativo"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non violato"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non complesso"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non rilevato"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non esiste"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non include"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ricevuto"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non visualizzato"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positivo"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non rivisto"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non rivisto da me"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rivisto"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rivisto da me"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevuto"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["critico per la revisione"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contiene"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["superiore a"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partire da"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inferiore a"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fino a"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non contiene"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visualizzata"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il mio commento ha risposte"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sì"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie personalizzate"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza personalizzato"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca condizione"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metriche"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione e feedback"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutte le condizioni"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronta la gestione delle conversazioni da parte del bot con gli agenti medi"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il bot è bloccato in un ciclo e ripete lo stesso messaggio a turni consecutivi"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di risposte del bot nelle conversazioni"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tipo di bot coinvolto nella conversazione"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bot coinvolto nella conversazione"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il contenuto della conversazione contiene la frase ", _interpolate(_list(0)), " nei messaggi degli agenti"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica automaticamente le chiamate prive della dichiarazione di divulgazione obbligatoria, come \"Questa chiamata verrà registrata\" e simili."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una persona o un bot coinvolto nella conversazione"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente o il bot sottoposto a revisione nella conversazione"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha richiesto assistenza di livello superiore"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positivo e negativo rilevato nella conversazione"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica l’agente più attivo nelle conversazioni in base all’analisi svolta dall’intelligenza artificiale"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente ha concluso la conversazione in modo educato"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente comprende e riconosce lo stato d’animo dei clienti"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza gli errori grammaticali, ortografici e di stile dell’agente"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di frasi di saluto iniziale tipiche"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente capisce la domanda o il dubbio del cliente"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza la facilità di comprensione di un testo, considerando la complessità delle parole e la lunghezza delle frasi"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di una soluzione offerta"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza il tono dell’agente durante la conversazione"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnali di possibile abbandono del cliente. Il cliente stava valutando un cambiamento o ha preannunciato di andarsene."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo a cui appartiene la conversazione"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto o il testo del messaggio agente"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di risposte degli agenti nelle conversazioni"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente a cui è stata assegnata la revisione della conversazione"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tempo in secondi della conversazione di chiamata"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto o il testo della conversazione"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il canale di comunicazione usato per la conversazione (ad esempio, email o chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti aggiunti alla conversazione"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data in cui un commento è stato aggiunto alla conversazione"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment positivo e negativo rilevato nella conversazione"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data in cui il cliente ha risposto al sondaggio sulla soddisfazione del cliente"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la revisione della conversazione viene contestata"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il canale usato dal cliente per avviare la conversazione"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di risposte dei clienti nelle conversazioni"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data dell’ultimo messaggio nella conversazione"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ruolo o il tipo di utente coinvolto nella conversazione"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il silenzio tra agente e cliente ha superato la soglia"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha richiesto assistenza di livello superiore"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha espresso estrema gratitudine o è rimasto molto soddisfatto del supporto ricevuto"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente o l’agente ha richiesto esplicitamente un follow-up"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il testo o il record dell’intera conversazione condotta a voce"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la conversazione è contrassegnata come speciale"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag usati nei commenti per classificare i contenuti"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua della conversazione rilevata dall’intelligenza artificiale"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data dell’ultimo messaggio nella conversazione"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di note private nelle conversazioni"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio di caratteri per messaggio nelle conversazioni"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di risposte rivolte ai clienti nelle conversazioni"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un partecipante le cui informazioni sono visibili pubblicamente"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio di parole per messaggio nelle conversazioni"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie usate per valutare la conversazione"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo stato del processo di revisione della conversazione"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un partecipante o una persona coinvolta nella conversazione"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un indirizzo email associato alla conversazione"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la revisione è stata visualizzata"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il revisore che ha valutato la conversazione"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data dell’ultima revisione della conversazione"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente sottoposto a revisione nella conversazione"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualsiasi commento o feedback specifico fornito dal cliente in merito alla sua soddisfazione per la conversazione"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punteggio di soddisfazione del cliente per la conversazione"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di soddisfazione del cliente per messaggio nella conversazione"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scheda punteggi usata per la revisione della conversazione"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcuni SLA sono stati violati"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di origine o connessione da cui ha avuto inizio la conversazione"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione è stata insolita o atipica e ci sono stati diversi botta e risposta per trovare una soluzione"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La domanda del sondaggio correlata al contenuto della conversazione"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il titolo o il nome del sondaggio associato alla conversazione"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente a cui è stata assegnata la revisione della conversazione"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il brand o il prodotto/servizio specifico associato alla conversazione"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il canale di comunicazione usato per la conversazione (ad esempio, email o chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data in cui la conversazione è stata chiusa o risolta"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di creazione iniziale della conversazione"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data di creazione iniziale della conversazione"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data in cui il cliente ha risposto al sondaggio sulla soddisfazione del cliente"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una breve descrizione o un riepilogo della conversazione"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID di riferimento esterno collegato alla conversazione"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cartella o il gruppo specifico in cui è organizzata la conversazione"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modulo o il modello usato per strutturare la conversazione"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ID di riferimento esterno collegato alla conversazione"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La casella di posta o l’account email tramite il quale è stata ricevuta la conversazione"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di telefono associato alla conversazione"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il livello di priorità assegnato alla conversazione"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero totale di risposte nella conversazione"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La condizione o lo stato corrente della conversazione"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo stato attuale della conversazione"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’oggetto o l’argomento della conversazione"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di risposte degli agenti nelle conversazioni"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag assegnati per classificare la conversazione"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo o la categoria della conversazione"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data dell’ultimo aggiornamento della conversazione"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data dell’ultimo aggiornamento della conversazione"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il medium o il canale attraverso il quale ha avuto luogo la conversazione"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto di una trascrizione in una conversazione"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni non idonee per la revisione tramite l’analisi dell’intelligenza artificiale"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di agenti partecipanti alle conversazioni"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la conversazione è stata visualizzata"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualsiasi messaggio"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficienza delle comunicazioni dei bot"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetizione bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteggio risposte bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione chiamata"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio cliente"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento di silenzio"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgazione registrazione mancante"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto trascrizione chiamata"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciale"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non idonea per revisione"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore revisione"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione tramite"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e grammatica"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio agente"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente più attivo"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte agenti"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata chiamata (s)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto conversazione"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale messaggi"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag di commento"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data commento"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiment"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data risposta al sondaggio CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazione"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale cliente"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte clienti"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultima risposta"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di utenti"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischio di abbandono"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento di silenzio"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escalation"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio eccezionale"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascrizione"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag commenti"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalia"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensione"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultima risposta"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero note private conversazione"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio caratteri"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero medio parole"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte pubbliche"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipante pubblico"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data valutazione"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione e commento"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato revisione"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggibilità"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipante"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email correlato"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correlato"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione ricevuta"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda punteggi rivista"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione visualizzata"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data revisione"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al sondaggio"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per messaggio"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di soddisfazione (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione offerta"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo origine"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda sondaggio"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo sondaggio"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand conversazione"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominio brand"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale conversazione"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data chiusura"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data risposta al sondaggio CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data invio sondaggio CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione conversazione"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID esterno"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo conversazione"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartella conversazione"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo conversazione"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo conversazione"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID esterno"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casella di posta conversazione"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero telefono"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità conversazione"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte conversazione"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizione conversazione"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato conversazione"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto conversazione"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero risposte agenti"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag help desk"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag help desk"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo conversazione"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aggiornamento"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aggiornamento"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto trascrizione"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero agenti partecipanti"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato visualizzazione"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacità vulnerabilità"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrità vulnerabilità"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento vitale vulnerabilità"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria AutoQA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una condizione correlata alla data"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nome al filtro"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome di un filtro"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona autenticata"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex membri spazio di lavoro"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri spazio di lavoro"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aggiungi ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica filtro"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi avere almeno 1 filtro correlato alla data"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona opzioni"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un’opzione"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tuo periodo di prova termina tra ", _interpolate(_named("n")), " giorno. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tuo periodo di prova termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>tra ", _interpolate(_named("n")), " giorno</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tuo periodo di prova termina tra ", _interpolate(_named("n")), " giorni. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tuo periodo di prova termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>tra ", _interpolate(_named("n")), " giorni</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il periodo di prova termina oggi. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo periodo di prova termina <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>oggi</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configura subito un abbonamento"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " giorni"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " rimanenti nel periodo di prova"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai raggiunto il limite di utenti per il tuo piano. ", _interpolate(_named("link")), " per ulteriori informazioni o per passare a un piano superiore."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic qui"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connetti il tuo help desk"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questo modo potremo ritirare i ticket affinché tu possa esaminarli."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti il tuo help desk"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stai usando Zendesk QA con dei dati di esempio. Per eliminare i dati di esempio e importare le tue conversazioni, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati di esempio"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia le informazioni utili qui sotto"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai uno screenshot a pagina intera per fornire il contesto"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatta il nostro team di assistenza clienti per risolvere questo problema:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento errore:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia informazioni utili"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti, bot e spazi di lavoro"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti e spazi di lavoro"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese corrente"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settimana corrente"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " rivisti ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " rivisto ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " rivisti ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novità"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorciatoie da tastiera"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatta con noi"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la chat (Stai forse usando un blocco degli annunci?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai a..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuto"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni fornite"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge base"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia account"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applicazione aggiornata. ", _interpolate(_named("buttonStart")), "Ricarica", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vuoi iniziare a usare Zendesk QA come parte del tuo spazio di lavoro:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Volevamo informarti che hai già ricevuto un invito a usare Zendesk QA come parte di uno spazio di lavoro. Se lo desideri, puoi ovviamente continuare, ma tieni presente che verrà creata una ", _interpolate(_named("bold")), " per te."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizzazione separata"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sì, voglio continuare con ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un nuovo account"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi continuare?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " ti chiede di unirti a ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna connessione a Internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo elemento fissato"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigazione rapida"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva ed espandi il redattore dei commenti"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi o mostra il pannello laterale"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla conversazione successiva"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri conversazione originale"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla conversazione precedente"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna a tutte le categorie la valutazione più alta possibile"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna a tutte le categorie la valutazione più bassa possibile"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riordina le conversazioni (in modo casuale)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facci sapere"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorciatoie da tastiera"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva scorciatoie finestre di dialogo"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai raggiunto il limite di ", _interpolate(_named("charLimit")), " caratteri"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tipo dell’immagine ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") non è consentito"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La dimensione dell’immagine ", _interpolate(_named("name")), " è eccessiva (5 MB consentiti)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento redattore commenti non riuscito"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascina per caricare"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci modelli"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun modello aggiunto"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi causa principale"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro, inclusi i motivi eliminati"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo della valutazione"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di impiegati"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero telefono"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti contatteremo per aiutarti a configurare il tuo account"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome azienda"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di contatto azienda"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In che modo hai sentito parlare per la prima volta di Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un canale"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un numero di agenti"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila tutti i campi obbligatori"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi accettare di aver letto e di acconsentire alle nostre condizioni e alla nostra Informativa sulla privacy."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale Slack basato su Support"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicità"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amico o collega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace dell’help desk o account manager"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito referente"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motore di ricerca (Google, Bing, ecc.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social media"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parlaci di te e della tua azienda."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli account"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È tutto pronto."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girovaga un po’, impara a conoscere l’app, elimina e aggiungi elementi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account creato. Quali sono i passaggi successivi?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È tutto pronto per introdurre le tue conversazioni reali?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi i tuoi dati"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia a esplorare"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provala con dati di esempio"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questo modo possiamo iniziare a caricare i ticket affinché tu possa rivederli."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega il tuo account dell’help desk"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho cambiato idea."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla iscrizione"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elemento fissato creato. ", _interpolate(_named("buttonStart")), "Vai agli elementi fissati", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli elementi fissati di coaching sono disponibili con il piano Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano superiore"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutti i piani"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva le note per prepararti per la prossima sessione individuale o di coaching."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati di coaching"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenta o aggiungine altri con ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa conversazione è stata eliminata"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo elemento fissato"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea elemento fissato"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica elemento fissato"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come funzionano gli elementi fissati"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai l’autorizzazione per visualizzare questa conversazione"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa scheda in alto"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa conversazione in alto"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa dashboard in alto"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento fissato salvato"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa questa scheda in alto"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa questa conversazione in alto"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa questa dashboard in alto"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa in alto sotto a un utente"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tu puoi vedere ciò che hai fissato in alto. Per condividere con altri ", "@", "menzionali nei commenti."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " ora è invitato a questa conversazione"]), _normalize([_interpolate(_named("agentsNames")), " ora sono invitati a questa conversazione"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 commento"]), _normalize([_interpolate(_named("n")), " commenti"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hai menzionato ", _interpolate(_named("participants")), " ma non fa parte di questa conversazione."]), _normalize(["Hai menzionato ", _interpolate(_named("participants")), " ma non fanno parte di questa conversazione."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " partecipanti"]), _normalize([_interpolate(_named("n")), " partecipante"]), _normalize([_interpolate(_named("n")), " partecipanti"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi rimuovere ", _interpolate(_named("agentName")), " dall’accesso a questo elemento fissato?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non fare niente"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non invitarli a questa conversazione"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi commento"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitali"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitali a questa conversazione"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi utente"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussione"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’operazione non può essere annullata."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verranno eliminati anche tutti i commenti sotto questo elemento fissato."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nuovi messaggi"]), _normalize([_interpolate(_named("n")), " nuovo messaggio"]), _normalize([_interpolate(_named("n")), " nuovi messaggi"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tu puoi vedere gli elementi fissati. Per condividere gli elementi fissati, ", "@", " qualcuno nei commenti."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ha fissato in alto ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una dashboard"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una conversazione"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["una nota"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano superiore per ottenere elementi fissati di coaching"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli elementi fissati di coaching sono disponibili con il piano Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli elementi fissati di coaching sono disponibili con il piano Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano superiore per ottenere la funzione"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparati per le sessioni individuali o salva qualcosa per un altro momento."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione eliminerà definitivamente gli elementi fissati e tutti i commenti aggiunti."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina elemento fissato"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina elemento fissato"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome cartella"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi elementi su cui tornare in un secondo momento"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più recenti in alto"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno recenti in alto"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva una conversazione o una tendenza interessante della dashboard in preparazione per le tue sessioni individuali oppure annotala per un altro momento."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un elemento fissato facendo clic sul pulsante qui sopra o sull’icona a forma di puntina nella vista Conversazione o Dashboard."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento fissato"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun elemento fissato creato"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nessun risultato per ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a usare parole chiave diverse o a verificare la presenza di errori di digitazione."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo elemento fissato"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggi"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per utente, parola chiave..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In ordine alfabetico"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creati di recente"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificati di recente"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La maggior parte degli elementi fissati"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Intendo rivedere ", _interpolate(_named("n")), " conversazione ", _interpolate(_named("schedule"))]), _normalize(["Intendo rivedere ", _interpolate(_named("n")), " conversazioni ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo aggiornato"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisci traguardo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi impostare un traguardo personale per il numero di conversazioni da rivedere."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni giorno"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni mese"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ogni settimana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo revisione"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONALI"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generali"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento canali"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona canale"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi solo il feedback del sondaggio con i commenti"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuta i tuoi colleghi mettendo in evidenza l’eccezionale qualità del loro servizio e l’eccellente feedback dei clienti."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi il feedback positivo del sondaggio su Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun canale trovato"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi automaticamente il feedback con i punteggi indicati sopra"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazioni Congratulabot"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi un promemoria all’inizio della settimana se non hai fornito alcuna revisione."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promemoria"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promemoria settimanale per le revisioni"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi notifiche su nuove revisioni, risposte, menzioni e contestazioni in Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menzioni"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte alle discussioni"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazioni Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica di nuove revisioni, risposte, menzioni e contestazioni."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni che mi riguardano"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menzioni nei commenti"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuove revisioni ricevute"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte nelle conversazioni"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report con statistiche"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi report sulle revisioni fornite e ricevute."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giornalieri"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensili"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settimanali"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giornalmente"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni 3 ore"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni 6 ore"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediatamente"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensilmente"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattivate"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimanalmente"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’amministratore del tuo account ha bloccato le impostazioni di notifica."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona periodo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche personali"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (attivo)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo accesso social a questo account:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La rimozione di un metodo di accesso attivo comporta la disconnessione. Puoi accedere di nuovo con il tuo indirizzo email alternativo."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi metodo di accesso"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google aggiunto come opzione di accesso"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di accesso"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack aggiunto come opzione di accesso"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea password"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi continuare?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verrà inviata un’email a ", _interpolate(_named("email")), " per reimpostare la password."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo email principale"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla se nella tua casella di posta è presente la nostra email di conferma"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic link"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per modificarlo, cambia prima il tuo ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’indirizzo email ", _interpolate(_named("email")), " sarà associato a questa password."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo aggiornato"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto profilo"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per risultati ottimali, carica una foto quadrata (256 x 256 pixel o più)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email principale"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio della settimana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni personali"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona almeno un’opzione"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto modello"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome modello"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra modello in"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi contenuto modello"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nome di un modello"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modello"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modello"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modelli di commenti per velocizzare la revisione, il fissaggio di elementi e il coaching."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usato in"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina modello"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare definitivamente questo modello?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica modello"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modelli di commenti per velocizzare la revisione, il fissaggio di elementi e il coaching."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun modello creato"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello commento creato"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello commento eliminato"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello commento salvato"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni e contestazioni"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per ottenere l’accesso, contatta l’", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistenza clienti"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun account trovato"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per ora, usa ", _interpolate(_named("link")), " per accedere a Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account non migrato"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metriche"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punteggi"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistiche"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calibrazione"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversazioni"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtri"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisione"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornite"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevute"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i miei commenti"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le mie contestazioni"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le mie revisioni"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reazioni"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevute"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniziate"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaggio"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personali"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crea password"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credenziali"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accesso"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reimpostazione"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["traguardo revisione"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelli"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utente"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea modello"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generali"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fatturazione"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azienda"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazioni"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizzazione"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenze"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impostazioni"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abbonamento"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazioni"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generali"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova categoria"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trovato messaggio in conflitto"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia o rimuovi uno dei messaggi in conflitto"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usa l’icona ", _interpolate(_named("placeholder")), " per escludere i dati sensibili da AutoQA. Ad esempio, anziché “Stammi bene, Paolo”, usa “Stammi bene, ", _interpolate(_named("placeholder")), "” per rappresentare qualsiasi nome."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Massimo ", _interpolate(_named("limit")), " righe"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica regola a"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite di 10 categorie personalizzate"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai raggiunto il limite di ", _interpolate(_named("limit")), " categorie personalizzate"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi condizione"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi riga"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina condizione"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il messaggio dell’agente"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altrimenti punteggio"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allora punteggio"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea categoria scheda punteggi"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa ‘/’ per aggiungere una variabile"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le direzioni"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il messaggio agente non può essere vuoto"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il messaggio non può contenere più di 300 caratteri"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le origini"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i canali"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i tag"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le conversazioni"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delimita l’ambito di applicazione della regola."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni specifiche"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea insight di In evidenza"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il messaggio agente non può essere vuoto"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite di 10 insight personalizzati"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai raggiunto il limite di ", _interpolate(_named("limit")), " insight personalizzati"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome dell’insight"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome insight"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea categoria"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di insight"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutro"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positivo"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alle impostazioni dello spazio di lavoro"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ora"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per iniziare a usare questa categoria di valutazione, vai alle impostazioni della scheda punteggi dello spazio di lavoro e aggiungila a una scheda punteggi a tua scelta."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione creata"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna automaticamente un punteggio alle conversazioni chiuse identificando parole chiave o frasi specifiche."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non funziona con le trascrizioni vocali."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dai il cinque! ✋ Hai raggiunto il limite massimo delle categorie di valutazione automatizzate ", _interpolate(_named("limit")), ". Per te la classificazione non ha segreti! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggiunto il limite categorie"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati che tutti i campi siano compilati correttamente"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna un nome alla categoria di valutazione"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome categoria"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova categoria di valutazione"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova categoria di valutazione"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna automaticamente un punteggio alle conversazioni chiuse identificando parole chiave o frasi specifiche. Non funziona con le trascrizioni vocali."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza automaticamente le conversazioni chiuse identificando parole chiave o frasi specifiche. Funziona solo con le trascrizioni."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna automaticamente un punteggio alle conversazioni identificando parole chiave o frasi specifiche nei messaggi degli agenti e dei bot."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per usare questa categoria di valutazione, vai alle impostazioni della scheda punteggi dello spazio di lavoro e aggiungila a una scheda punteggi."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta automaticamente le conversazioni in base a parole chiave o frasi specifiche nei messaggi."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile per"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione insight"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questi insight vengono visualizzati in In evidenza in base ai criteri definiti."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione scheda punteggi"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa categoria può essere usata nelle schede punteggi in base a criteri definiti."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibile a breve"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personalizzato"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predefinito"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punteggi"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in evidenza"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voce"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modifiche"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche salvate"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimento:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic sull’icona ", "{··}", " per escludere nomi, dettagli aziendali o qualsiasi altra informazione sensibile durante i controlli di chiusura di AutoQA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi chiusura approvata"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica chiusura approvata"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura approvata"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi esenzioni"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica esenzione"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzione"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi saluto iniziale approvato"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica saluto iniziale approvato"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale approvato"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incolla qui le parole separate da interruzioni di riga"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premi Invio dopo ogni parola per una nuova voce"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua applicabile"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio, anziché \"Stammi bene, Paolo\" puoi usare \"Stammi bene, ", "{··}", "\" per rappresentare qualsiasi nome."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnaposto contenuto dinamico"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzioni"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 esenzione aggiunta"]), _normalize([_interpolate(_named("count")), " esenzioni aggiunte"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Esenzione già aggiunta"]), _normalize(["Esenzioni già aggiunte: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi esenzione"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna esenzione trovata"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna esenzione aggiunta"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzione (con distinzione tra maiuscole e minuscole)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aggiungi esenzioni"]), _normalize(["Aggiungi 1 esenzione"]), _normalize(["Aggiungi ", _interpolate(_named("count")), " esenzioni"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca una parola o una frase"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzione salvata"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le lingue"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca lingua"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Chiusura già aggiunta"]), _normalize(["Chiusure già aggiunte: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi chiusura"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna chiusura approvata trovata"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna chiusura approvata"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi chiusura"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca una chiusura"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura aggiunta"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura salvata"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Il modulo contiene voci duplicate: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Saluto iniziale già aggiunto"]), _normalize(["Saluto iniziali già aggiunti: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi saluto iniziale"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun saluto iniziale approvato trovato"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun saluto iniziale approvato"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi saluto iniziale"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un saluto iniziale"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale aggiunto"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale salvato"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi esenzione"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Elimina esenzione"]), _normalize(["Elimina esenzioni"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vuoi eliminare definitivamente questa esenzione?"]), _normalize(["Vuoi eliminare definitivamente queste esenzioni?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica esenzione"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Una o più esenzioni hanno una lunghezza superiore a ", _interpolate(_list(0)), " caratteri"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " caratteri"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato da"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabile"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisci i tuoi consensi in linea con il tuo brand e gli standard di servizio. Quando viene aggiunto, AutoQA rileva solo le chiusure approvate. Le modifiche apportate si applicano alle conversazioni future."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite massimo di 100 chiusure approvate. Se vuoi aggiungerne altre, potrebbe essere il momento di rivedere e perfezionare le chiusure esistenti."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggiunto il limite di chiusura approvato"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusure approvate"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole e frasi che vorresti non venissero considerati errori di ortografia o grammatica da AutoQA. Le modifiche apportate si applicano alle conversazioni future."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignora elenco"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica i tuoi saluti iniziali in linea con il tuo brand e gli standard di servizio. Quando viene aggiunto, AutoQA rileva solo i saluti iniziali approvati. Le modifiche apportate si applicano alle conversazioni future."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite massimo di 100 saluti iniziali approvati. Se vuoi aggiungerne altri, potrebbe essere il momento di rivedere e perfezionare i messaggi di segreteria esistenti."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggiunto il limite di saluti iniziali approvati"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluti iniziali approvati"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ora"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina categoria"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare definitivamente questa categoria di valutazione?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco categorie"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La categoria eliminata non apparirà in nessuna nuova conversazione o nuovo filtro. I dati storici su dashboard e conversazioni rimangono intatti."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La categoria verrà rimossa da tutte le schede punteggi a cui è stata aggiunta."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare la categoria della scheda punteggi"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria scheda punteggi eliminata"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina categoria scheda punteggi"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le categorie AutoQA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " basate sul modello AI rimarranno nella scheda punteggi ma non riceveranno automaticamente un punteggio"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disattivazione dell’integrazione LLM non disattiva completamente la funzione AutoQA. Ecco cosa succederà:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati storici"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " rimarranno nella dashboard AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cause principali aggiunte manualmente"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " rimarranno, ma tutte le cause principali basate su LLM verranno rimosse"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva AutoQA basata su LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA usa l’AI e i Large Language Model (LLM) per assegnare automaticamente un punteggio a categorie specifiche. I tuoi dati vengono elaborati in base a un rigoroso DPA (Data Processing Agreement) e non vengono mai usati per addestrare alcun modello."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sull’intelligenza artificiale generativa"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA usa il servizio OpenAI di Microsoft Azure per ", _interpolate(_named("automatically_scoring_categories")), ". I tuoi dati vengono elaborati in base a un rigoroso DPA solo per fornirti AutoQA e ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assegnare automaticamente un punteggio ad alcune categorie"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non vengono mai usati per addestrare modelli OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basata su LLM attivata"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basata su LLM disattivata"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA basata su LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio automatizzato con l’AI in AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione dei dati delle categorie AutoQA basate su MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenso Microsoft Azure ritirato"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenso Microsoft Azure fornito. Le conversazioni riceveranno a breve delle valutazioni automatiche."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi ancora usare AutoQA, ma ecco cosa succederà"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disabilitazione del servizio MS Azure OpenAI non disattiva AutoQA come funzionalità."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi farlo nella vista delle categorie della scheda punteggi."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le categorie AutoQA basate sul modello rimarranno nella scheda punteggi ma non riceveranno automaticamente un punteggio."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cause principali che hai aggiunto manualmente rimarranno. Tutte le cause principali generate dal modello verranno rimosse."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati storici rimangono nella dashboard AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita l’elaborazione dei dati da parte del servizio MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamma più ampia di categorie AutoQA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza multilingue pronta all’uso."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta rapida di nuove categorie."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione di valutazione migliorata. GPT-3.5 dimostra una consapevolezza contestuale di livello umano."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approfitta dei più recenti progressi nella tecnologia dell’intelligenza artificiale."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecco cosa otterrai"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita l’elaborazione dei dati da parte del servizio MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenso fornito"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun consenso fornito"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i nuovi utenti vengono assegnati a questo spazio di lavoro"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio di lavoro predefinito"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Orario a 12 ore (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Orario a 24 ore (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato orario"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo azienda aggiornato"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio della settimana"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione personalizzata"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’ID della chiave di accesso e la chiave segreta si trovano in genere in Gestione accessi > Utenti"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID chiave di accesso AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla la modifica della chiave di accesso segreta"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica il valore della chiave di accesso segreta"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connessione consente di definire una sola regione, che in genere si trova sotto Posizione"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una chiave segreta"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave di accesso segreta AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi trovare tutte le informazioni pertinenti in Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In genere si trova nelle impostazioni delle istanze Amazon Connect e nella pagina Archiviazione dati"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione di archiviazione delle registrazioni delle chiamate"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l’aggiunta di Amazon Connect. Controlla se tutti i campi sono compilati correttamente e riprova."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La posizione di archiviazione deve essere nel formato di bucket/cartella separati da una barra."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per aggiungere una connessione, contatta l’", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistenza clienti"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i ticket Zendesk verranno sincronizzati automaticamente. Puoi scegliere di escludere i ticket selezionati."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacifico (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacifico (Seul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacifico (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacifico (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pacifico (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada (Centrale)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Francoforte)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Zurigo)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Irlanda)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa (Londra)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione del server"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio Oriente (Emirati Arabi Uniti)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la regione del server"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sud America (San Paolo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stati Uniti orientali (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stati Uniti orientali 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stati Uniti occidentali (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione già aggiunta"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia connessione"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza solo le conversazioni che"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canale uno, canale due"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’ID univoco di questa connessione è ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mese"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 anno"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 mesi"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 mesi"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le conversazioni non attive verranno eliminate dopo il periodo di conservazione selezionato. Le revisioni inviate rimangono visibili nelle viste Attività e Dashboard."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di conservazione"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona periodo di conservazione"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni presente che questa azione è irreversibile."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendo che questa operazione rimuoverà tutti i dati relativi all’help desk per questa connessione da Zendesk QA, inclusi conversazioni, campi e tag."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni inviate non saranno interessate e rimarranno visibili in viste specifiche."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gruppo uno, gruppo due"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includono"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sviluppo attivo"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa integrazione è ancora in fase di ", _interpolate(_named("activeDevelopment")), ". Potrebbero mancare alcuni dettagli (come allegati o campi personalizzati)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casella di posta uno, casella di posta due"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag uno, tag due"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token API segreto"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova \"Ticket Field Names\" (Nomi campi ticket) su Freshdesk in Settings → Ticket fields (Impostazioni → Campi ticket)"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova \"Custom Field Names\" su Help Scout in Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova \"Klass Conversation Names\" (Nomi conversazioni Klass)su Kustomer in Settings → Klasses → Conversation (Impostazioni → Klasses → Conversazione)"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova \"Custom Field Name\" (Nome campo personalizzato) in Live Agent in Settings → Custom fields (Impostazioni → Campi personalizzati)"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova \"Nome campo personalizzato\" su Wix in Impostazioni → Campi personalizzati"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separa gli ID campo mediante virgole. Puoi trovare questi ID in Zendesk in Amministratore → Campi ticket."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in contatto alcuni utenti dell’help desk per rivedere le tue prime conversazioni. Non preoccuparti, non li avviseremo a meno che non li inviti a diventare membri dello spazio di lavoro."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione riuscita"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi membri"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora inizieremo a richiamare le conversazioni."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dominio: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identificatore help desk: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile autenticare la connessione"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto pronto. Questa è la tua nuova connessione."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcune connessioni non sono state aggiornate o sono diventate inattive. Prova ad aggiornare queste connessioni o a rimuoverle dall’elenco."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID client API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segreto client API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crea un nuovo token API selezionando Impostazioni → Account → API e SDK. L’URL di reindirizzamento da usare è ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID account"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona l’help desk per iniziare"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il tuo account Intercom è configurato per essere servito dall’UE o dall’Australia, aggiornalo."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Indirizzo email che usi per accedere a ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi il nome, l’indirizzo email e il numero di telefono dell’utente finale. Le credenziali bancarie sono già mascherate per tutte le connessioni all’help desk."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna integrazione. Aggiungine una."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai bisogno di questi ruoli:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea connessione"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova connessione"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselezionando questa opzione, puoi aggiungere manualmente questa connessione solo a spazi di lavoro specifici"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti a tutti gli spazi di lavoro esistenti"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli spazi di lavoro"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave API segreta"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canali"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Connessione ", _interpolate(_list(0)), " eliminata"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome connessione"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi prendere il token API qui sotto."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina connessione ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita \"elimina connessione\" per rimuovere questa connessione"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina connessione"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci e monitora le origini dati connesse."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica connessione"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludono"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separa i nomi dei campi mediante virgole"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento non riuscito"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna un nome univoco all’integrazione"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi opzioni avanzate"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi campi dati sensibili"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non memorizzare mai gli URL degli allegati"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignora allegati"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non memorizzare mai i contenuti delle conversazioni (puoi recuperarli in seguito on demand dall’help desk)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignora il contenuto della conversazione"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non memorizzare mai l’oggetto della conversazione (puoi recuperarlo in seguito on demand dall’help desk)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignora la riga dell’oggetto"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attiva"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tuo sottodominio ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbina ID team casella di posta"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID numerico del team Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette di posta"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna manualmente la connessione"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi i dati dei clienti"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome campo sensibile"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID campo sensibile"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnova connessione"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnova connessione"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettiti alla sandbox Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Configurazione di una connessione con ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra opzioni avanzate"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci solo le conversazioni con un punteggio inferiore o uguale alla soglia impostata"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica una soglia CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza solo le conversazioni che..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni le conversazioni più recenti"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna connessione"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione aggiornata"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo help desk"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per annullare l’abbonamento ed eliminare l’account, contatta l’", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assistenza clienti"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina account"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’eliminazione di un ticket in Zendesk Support (o in un altro help desk) non influisce sulle revisioni delle conversazioni associate. Puoi ancora visualizzarle e gestirle."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’eliminazione di un ticket in Zendesk QA elimina anche le revisioni delle conversazioni associate.", _interpolate(_named("line_break")), "L’eliminazione di un ticket in Zendesk Support (o in un altro help desk) non influisce sulle revisioni delle conversazioni associate. Puoi ancora visualizzarle e gestirle."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci revisioni"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’eliminazione delle revisioni è irreversibile. Influisce anche sui dati nelle dashboard."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina tutte le revisioni"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina tutto"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le revisioni sono state eliminate"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina tutto"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni associate ai ticket eliminati saranno visibili qui."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna revisione"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca ID ticket"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ticket: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni eliminate"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gli spazi di lavoro predefiniti non possono essere eliminati. Per eliminare questo spazio di lavoro, seleziona un altro spazio di lavoro predefinito in ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni account"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azione non può essere annullata."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rimuoverà ed eliminerà tutti i dati"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comprendo che l’operazione ", _interpolate(_named("removeAllData")), " correlati a questo spazio di lavoro, inclusi gli utenti e le connessioni dello spazio di lavoro."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina spazio di lavoro"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita \"elimina spazio di lavoro\" per eliminare questo spazio di lavoro"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina spazio di lavoro ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elimina spazio di lavoro"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di sapere quello che stai facendo o consulta in anticipo un professionista."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se selezionare il soggetto sottoposto a revisione predefinito in base all’agente più frequente o all’assegnatario corrente."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente più attivo"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione predefinito"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti alle persone di rivedere le proprie conversazioni e agli agenti di eseguire revisioni"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spazio di lavoro è stato eliminato"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo spazio di lavoro è stato aggiornato"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabilisci una valutazione neutra nascondendo le revisioni fornite in precedenza da altri revisori"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione imparziale"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azione non può essere annullata."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni presente che verranno rimossi ed eliminati tutti i dati correlati a questo spazio di lavoro, inclusi gli utenti e le connessioni dello spazio di lavoro."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina spazio di lavoro"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminazione spazio di lavoro in corso..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazioni"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link magico"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO Okta"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link magico"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile disattivare il metodo di accesso corrente"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I metodi di autenticazione sono stati aggiornati"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti determinati tipi di metodi di autenticazione per tutti gli utenti."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazioni"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti e spazi di lavoro"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea e gestisci spazi di lavoro e invita o connetti gli utenti."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimani nella pagina"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci dalla pagina"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscire da questa pagina e perdere tutte le modifiche?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazioni"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che non ci siano conversazioni da importare. Inizia effettuando delle conversazioni con il tuo help desk, quindi torna qui per analizzarle."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione trovata"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare questa categoria di sistema"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina categoria"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’eliminazione delle categorie è irreversibile. Tutti i dati delle categorie verranno cancellati dalla generazione di report."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ripristinare questa categoria, dovrai crearla di nuovo."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina categoria"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca categoria"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le categorie automatiche compilano automaticamente la scheda punteggi con valutazioni suggerite per risparmiare tempo nella revisione. Le categorie manuali vengono compilate dai revisori da zero."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuale"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e grammatica"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensione"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggibilità"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione offerta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria trovata"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna causa principale trovata"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna scheda punteggi trovata"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova una ricerca diversa o cambia i filtri"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorie"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorie"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorie"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le categorie"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " categorie"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " schede punteggi"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " schede punteggi"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " schede punteggi"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le schede punteggi"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " schede punteggi"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stati"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stati"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stati"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli stati"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " stati"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipi"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipi"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipi"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i tipi"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tipi"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spazi di lavoro"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spazi di lavoro"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro trovato"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spazi di lavoro"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli spazi di lavoro"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spazi di lavoro"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un peso di ", _interpolate(_named("weight")), " contribuisce per ", _interpolate(_named("percentage")), "% al punteggio complessivo"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina scheda punteggi"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’eliminazione delle schede punteggi è irreversibile. Tutti i dati delle schede punteggi verranno cancellati dalla generazione di report."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ripristinare questa scheda punteggi, dovrai crearla di nuovo."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina scheda punteggi"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda punteggi"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozza"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause principali"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scala di valutazione aggiornata. ", _interpolate(_named("buttonStart")), "Inizia revisione", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA imposta una scalabilità binaria per impostazione predefinita, ma puoi anche optare per una scalabilità più granulare:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se vuoi visualizzare diverse opzioni di scala di valutazione, configurate per categoria, vai a ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia/Tono"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoscenza del prodotto"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornando le scale di valutazione:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scala di valutazione precedente e le categorie corrispondenti verranno archiviate (non eliminate)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcuni dati della dashboard saranno interessati poiché la vecchia e la nuova scala potrebbero non essere comparabili."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clona la configurazione delle categorie di valutazione esistenti nella nuova scala"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi aggiornare le scale di valutazione?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi modificare il formato di visualizzazione della scheda punteggi (numero o emoji) e rendere le categorie obbligatorie o facoltative."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa le emoji anziché i numeri"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti alle persone di saltare le categorie"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di scala di valutazione"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Le categorie per 1 spazio di lavoro sono state clonate"]), _normalize(["Le categorie per ", _interpolate(_named("workspacesAmount")), " spazi di lavoro sono state clonate"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni spazio di lavoro"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza automaticamente le chiamate in cui l’agente non informa il cliente di aspettarsi la presenza di silenzio nella conversazione."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione ha momenti di silenzio che durano più a lungo della soglia impostata."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisce la soglia massima di momenti di silenzio nelle chiamate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento di silenzio"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli insight eliminati non appariranno in nessuna nuova conversazione e filtro. I dati storici su dashboard e conversazioni rimangono intatti."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare l’insight di Spotlight"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight di Spotlight eliminato"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina insight di Spotlight"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza le conversazioni contenenti parole o frasi specifiche nelle trascrizioni e nei messaggi."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica automaticamente le chiamate prive della dichiarazione di divulgazione obbligatoria, come \"Questa chiamata verrà registrata\" e simili."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mira a identificare una dichiarazione di divulgazione della registrazione nella chiamata usando un Large Language Model (LLM) che comprenda dinamicamente il contesto, eliminando la necessità di frasi preimpostate."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgazione registrazione mancante"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza automaticamente le chiamate in cui l’agente non informa il cliente di aspettarsi la presenza di silenzio nella conversazione."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento di silenzio"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica i momenti di silenzio in una chiamata"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Devi apportare modifiche al tuo abbonamento? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") e saremo lieti di aiutarti."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza automaticamente il 100% delle conversazioni tra agenti, BPO, canali e lingue. L’intelligenza artificiale rileva i problemi, le lacune della Knowledge e le opportunità di coaching per migliorare il servizio."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertura AutoQA del 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback e coaching degli agenti"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sull’intelligenza artificiale di In evidenza"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report e dashboard avanzati"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi e filtraggio del sentiment"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzioni on-demand"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi l’impostazione di assegnazioni e traguardi"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching e quiz"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Quality Assurance (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto su Professional e in più"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanzato (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook per la notifica immediata di errori critici"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione data warehouse"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sviluppo di integrazioni personalizzate"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile di Relazione clienti dedicato"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso diretto ai team tecnici e di prodotto tramite Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le funzionalità di Growth Suite e in più"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA per una copertura del 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sulle conversazioni basati sull’intelligenza artificiale per comprendere al meglio i dati"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtraggio e arricchimento dei dati dell’help desk tramite intelligenza artificiale"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza: Rilevamento automatico delle interazioni da verificare"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi e filtraggio del sentiment"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report e insight sulle prestazioni basati sull’intelligenza artificiale"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzione on-demand delle conversazioni"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoraggio del tempo di revisione"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi del tasso di superamento"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le funzionalità di AI Suite e in più"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto di implementazione aziendale che include l’integrazione Salesforce e Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sviluppo di integrazioni personalizzate"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione data warehouse"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook per la notifica immediata di errori critici"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile di Relazione clienti dedicato"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso diretto ai team tecnici e di prodotto"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso anticipato e coinvolgimento nella nostra roadmap"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione automatica delle revisioni e impostazione dei traguardi"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching e analitica mirati"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz per la gestione dell’apprendimento"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei ruoli utente e delle autorizzazioni"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mascheramento dei dati sensibili"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni avanzate di filtraggio e ricerca nelle conversazioni"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi personalizzabili con valutazioni multiple, pesi regolabili e opzioni di non superamento automatiche"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estensione del browser per rivedere le conversazioni ovunque"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi delle cause principali"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report sulle prestazioni per confrontare agenti, team e BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggio CSAT e analisi del feedback dei clienti"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzionalità"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le funzionalità di Starter e in più"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale di valutazione multiple per conversazione"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sulle conversazioni interattivi basati sull’intelligenza artificiale"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni delle revisioni"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi fissati di coaching"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni di coaching"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi e filtraggio del sentiment"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtraggio in base alla complessità della conversazione"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight sulle conversazioni"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi condizionali"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi delle cause principali"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analitica CSAT avanzata basata sull’intelligenza artificiale"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione della valutazione"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estensione del browser per rivedere la conversazione ovunque"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzione In evidenza per individuare le conversazioni da rivedere"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzione on-demand delle conversazioni"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoraggio del tempo di revisione"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano personalizzato"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (legacy)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni delle conversazioni illimitate"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportazione CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni reciproche (peer-to-peer) e autovalutazioni"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi con pesi regolabili e opzioni di non superamento automatiche"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di filtraggio avanzate"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardi di revisione giornalieri, settimanali o mensili"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi CSAT e analitica di base"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report per confrontare agenti, team e BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei ruoli utente e dei privilegi"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzionalità"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto cercando di apportare una modifica al mio abbonamento Zendesk QA.\n\nEvidenzia o indica un’opzione e indica il numero di licenze da modificare:\nVorrei [aggiungere X licenze] [rimuovere X licenze] [passare al piano Zendesk QA]\n\nCondividi il nome della tua azienda e il nome associato al tuo account Zendesk QA, oltre a qualsiasi altro dettaglio che dovremmo conoscere per elaborare questa modifica."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dell’abbonamento Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto cercando di acquistare un abbonamento Zendesk QA.\n\nCondividi il nome della tua azienda e il nome associato al tuo account Zendesk QA, oltre a qualsiasi altro dettaglio che dovremmo conoscere per elaborare questa richiesta:\n\nCondividi anche eventuali domande specifiche a cui vorresti rispondere prima dell’acquisto:\n\nGrazie!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto di un abbonamento Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’abbonamento scade il ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " mancanti)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il periodo di prova termina il ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " rimasti)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 giorno"]), _normalize([_interpolate(_named("n")), " giorni"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("dayOfMonth")), " di ogni mese."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Addebito su ", _interpolate(_named("card")), " (scadenza ", _interpolate(_named("expires")), ") il ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["che termina con"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Scaduto"]), _normalize(["Scade tra 1 giorno"]), _normalize(["Scade tra ", _interpolate(_named("expiresIn")), " giorni"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il prezzo iniziale include ", _interpolate(_named("n")), " licenze utente"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["a partire da ", _interpolate(_named("sum")), "/mese"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per utente/mese"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all’anno"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al mese"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestre"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rimuovi l’annullamento pianificato"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se vuoi continuare a usare Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’abbonamento è stato annullato il ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La scadenza dell’abbonamento è prevista per il ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Periodo di prova di ", _interpolate(_named("days")), " giorno senza costi aggiuntivi!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usate"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai ", _interpolate(_named("n")), " utenti. Il numero di licenze non può essere inferiore."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’abbonamento riprenderà il ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anno"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il tuo piano corrente (", _interpolate(_named("plan")), ") di Zendesk QA non include le funzioni basate sull’intelligenza artificiale. Per sfruttare al massimo le funzioni di intelligence vocale, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per annullare l’abbonamento, contattaci ", _interpolate(_named("link")), " o chatta con noi."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano di gestione della qualità"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione della qualità"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento aggiornato"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una carta di credito"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i piani"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturazione"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi i dati di fatturazione"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervallo di fatturazione"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla abbonamento"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’abbonamento verrà annullato al termine del periodo di fatturazione."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci per apportare modifiche"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano corrente"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi personalizzati"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa al livello inferiore"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale (risparmia il 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga annualmente"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica abbonamento"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplora piani"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di prova gratuito"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturazione manuale"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi rivedere e confermare il riepilogo finale dell’ordine nel passaggio successivo."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più popolare"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai un metodo di pagamento attivo."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In prova"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina l’abbonamento corrente per cambiare il piano"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in pausa l'abbonamento"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrale"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riattiva abbonamento"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprendi abbonamento"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenze"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia subito il periodo di prova"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza rinnovo"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In pausa"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato abbonamento"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di prova"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano superiore"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passa a un piano di abbonamento superiore"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizzato"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedica un minuto a fornire un feedback sulla tua esperienza con l’assistenza clienti. Grazie in anticipo: ", "{", "{", "agentName", "}", "}", " apprezza il tuo feedback."])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondaggio CSAT di ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come valuteresti l’assistenza che hai ricevuto da ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ci sarebbe molto utile saperlo. Useremo il tuo feedback per rendere ", _interpolate(_list(0)), " migliore per tutti."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui sondaggi"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tipo di sondaggio"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può essere modificato in un secondo momento."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura e migliora la soddisfazione dei clienti con sondaggi CSAT automatizzati in più lingue."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun sondaggio creato"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sondaggio"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome sondaggio"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato creato"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prova a modificare la parola chiave o ", _interpolate(_named("contact_us")), " se vuoi che sia visualizzata qui"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facci sapere"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna lingua trovata"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleziona una o più lingue dall’elenco seguente. ", _interpolate(_named("contact_us")), " se non vedi elencata una lingua che vorresti usare."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi lingue"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facci sapere"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi lingua"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ultima pubblicazione: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ultimo salvataggio: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Domanda ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda introduttiva"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta scala sinistra"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto email"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivi"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta scala destra"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se in un sondaggio mancano delle traduzioni, i testi verranno mostrati nella lingua di base"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica traduzioni"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzioni mancanti"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assicurati di rivedere tutte le traduzioni prima di applicarle. Apporta le modifiche al file scaricato, salvalo e, se necessario, ricaricalo. Se un token ", _interpolate(_named("token_example")), " non viene visualizzato sotto forma di tag, è in un formato errato o in un campo che non supporta i token."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi traduzioni"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tradotto"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepiloga la conversazione e mostra i suggerimenti per i commenti."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI assist (in inglese) di Zendesk QA"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi modificare i tipi dopo averli creati"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo tipo esiste già. Seleziona un nome diverso."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva il tipo personalizzato come opzione selezionabile nel menu a discesa Tipo di sondaggio e raggruppa tutti i sondaggi creati nella dashboard."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita le parole e premi Invio per salvare"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica tipi personalizzati"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome del tipo non può essere vuoto"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisci tipo"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo personalizzato non è stato salvato. Premi Invio per salvarlo."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email mittente"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome mittente"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quanto sei d’accordo con l’affermazione seguente: “", _interpolate(_named("agentName")), " ha semplificato la gestione del mio problema”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["È stato facile ottenere aiuto da ", _interpolate(_named("brandName")), " finora?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel complesso, quanto è stato facile risolvere il problema oggi?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato facile interagire con il nostro team?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Informativa sulla privacy (facoltativo)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Condizioni del servizio (facoltativo)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Come valuteresti l’assistenza che hai ricevuto da ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come è stato il nostro servizio per questa richiesta?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual è il tuo livello di soddisfazione rispetto al rappresentante dell’assistenza?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Qual è il tuo livello di soddisfazione rispetto al servizio che hai ricevuto da ", _interpolate(_named("agentName")), " oggi?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda personalizzata"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Definisci la tua domanda ", _interpolate(_named("type")), " personalizzata..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita domanda personalizzata"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza gli URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta il tuo messaggio email"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La modifica del nome di un tipo di domanda personalizzato lo aggiornerà in tutti i sondaggi che lo utilizzano, senza influire sui dati raccolti."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica tipi personalizzati"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto email"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda introduttiva"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Domanda ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo tipo personalizzato"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuto"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token di personalizzazione"]), _normalize(["Token di personalizzazione"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Trascina il token di personalizzazione nel campo sottostante."]), _normalize(["Trascina i token di personalizzazione nei campi seguenti."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Conversazione ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento è possibile inviare sondaggi CSAT solo tramite email. Stiamo lavorando per offrirti ulteriori opportunità, come sondaggi CSAT dell’help desk, sondaggi SMS e altro ancora."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sincronizza o rinnova la connessione"]), _normalize(["Sincronizza o rinnova le connessioni"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza o rinnova la connessione ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["La connessione non è sincronizzata correttamente"]), _normalize(["Le connessioni non sono sincronizzate correttamente."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deseleziona la casella di spunta pertinente ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentica adesso ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi almeno una regola di invio sondaggio per attivare la connessione"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa connessione è al momento disabilitata. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa connessione maschera i dati sensibili del client. Per abilitare l’invio del sondaggio,\n", _interpolate(_named("link")), " nelle impostazioni."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa connessione richiede autorizzazioni aggiuntive per l’invio dei sondaggi.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentica adesso"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA richiede più autorizzazioni per Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " e ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ora"]), _normalize([_interpolate(_named("n")), " ore"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minuti"]), _normalize(["1 minuto"]), _normalize([_interpolate(_named("n")), " minuti"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ora"]), _normalize(["ore"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " per configurare le regole di invio dei sondaggi."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" giorno"]), _normalize(["giorni"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I link ai sondaggi scadono"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I link scadono tra"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impedisci di rispondere ai sondaggi se è trascorso troppo tempo"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sondaggio ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separa i tag mediante virgole o premi Invio dopo aver digitato. Assicurati che ognuno di essi sia riprodotto esattamente come in Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta le condizioni in base alle quali inviare il sondaggio."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di invio dei sondaggi"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di invio dei follow-up ai sondaggi"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un sondaggio ", _interpolate(_named("rules")), " ", _interpolate(_named("name")), " esiste già.\nRisolvi il conflitto modificando le regole qui o in ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai già impostato una connessione con regole corrispondenti per il sondaggio ", _interpolate(_named("name")), ". Risolvi il conflitto modificando le regole qui o in ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un’altra regola"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi brand"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona condizione"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un valore"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corrispondente alle stesse regole di"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un valore"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email inviata tra"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email di follow-up inviata tra"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frammento di codice incorporato e follow-up via email"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia codice"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega i sondaggi di Zendesk QA alle tue email aggiungendo il frammento di codice alla configurazione di Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guida all’installazione"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima frammento di codice"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frammento di codice copiato negli appunti"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia frammento di codice non riuscita"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frammento di codice incorporato"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo sondaggio contiene modifiche non salvate. Salvalo di nuovo per assicurarti che il frammento di codice rispecchi gli aggiornamenti più recenti."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi connessione"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna connessione aggiunta"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integra email, chat, telefonate e altro ancora in Zendesk QA con le tue connessioni"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulla consegna dei sondaggi"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di invio dei sondaggi"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di invio dei follow-up ai sondaggi"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi regola per il brand"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi regola"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentica"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + email di follow-up"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega il tuo account Intercom a Sondaggio CSAT di Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasmetti tramite"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi riceve il sondaggio e quando"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica regole"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilitata"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I sondaggi in-app consentono ai clienti di completare un sondaggio senza uscire dalla chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di consegna per connessione"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un sondaggio ", _interpolate(_named("rules")), " ", _interpolate(_named("name")), " esiste già.\nRisolvi il conflitto disabilitando la connessione o modificando le regole qui o in ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta le condizioni in base alle quali inviare il sondaggio"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creato da ", _interpolate(_named("name")), " il ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rimuovi ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la si rimuove, questa lingua non sarà più visibile agli intervistati e tutti i testi tradotti verranno eliminati."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi lingua"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azione non può essere annullata."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In alternativa, puoi disabilitare la lingua nascondendola dal sondaggio. Ciò ti consentirà di mantenere tutte le traduzioni senza mostrarle agli intervistati."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultima modifica apportata da ", _interpolate(_named("name")), " il ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulle lingue dei sondaggi"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prima di aggiungere altre lingue, assicurati di aver completato le modifiche nei passaggi relativi allo stile e ai contenuti. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi lingua"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi tradotti"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibili agli intervistati"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefinita"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il file di traduzione"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi lingua"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" scarica un file di traduzione"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi traduzioni"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traduzione completata. ", _interpolate(_named("review_link")), " o ", _interpolate(_named("download_link")), " per apportare modifiche."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" scarica un file di traduzione"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica cosa manca"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alcuni contenuti devono ancora essere tradotti e saranno sostituiti con l’inglese. ", _interpolate(_named("review_link")), " o ", _interpolate(_named("download_link")), " per aggiungere le traduzioni mancanti."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva il sondaggio"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il sondaggio contiene modifiche non salvate. ", _interpolate(_named("save")), " per scaricare il file di traduzione con le modifiche più recenti."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica file di traduzione"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la scala di valutazione preferita"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisce ai clienti un menu a discesa per selezionare il motivo della valutazione"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo scala a sinistra (facoltativo)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeri"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi motivo"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il motivo non può essere vuoto"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo motivo esiste già"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra menu a discesa per i motivi del punteggio"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminando un motivo e aggiungendolo di nuovo con lo stesso nome oppure rinominando un motivo, viene sempre creata una nuova voce. Ciò influisce sui dati visualizzati nella dashboard."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina motivo"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un motivo eliminato rimane nei report della dashboard, ma non sarà visibile ai nuovi intervistati. Ciò vale anche per le traduzioni correlate."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina un motivo"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Elimina un motivo \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo scala a destra (facoltativo)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su una scala a 5 punti, 1 e 2 sono considerati punteggi negativi; 3 è neutro; 4 e 5 sono positivi"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi testo scala"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scala ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala di valutazione"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA sta importando le conversazioni. Questo processo potrebbe richiedere fino a 24 ore."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrà usato come token di personalizzazione durante il sondaggio."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome brand"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulsanti e link"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza l’aspetto del sondaggio"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfondo logo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono supportati i formati JPG, PNG e GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stile"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto con stile"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un messaggio che le persone vedranno dopo aver inviato il feedback"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di ringraziamento"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice colore esadecimale errato"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email errato"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo non può essere vuoto"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL errato"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non visibile:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibile:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi inviati"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi inviati negli ultimi 30 giorni"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato eliminato"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato duplicato"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’email di anteprima è stata inviata"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato pubblicato"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato aggiornato"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il sondaggio è stato messo in pausa"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in pausa"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in pausa il sondaggio"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l’indirizzo email per inviare l’anteprima del sondaggio"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuo", "@", "indirizzoemail.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la lingua dell’anteprima"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia un’email di anteprima"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia un sondaggio di esempio al tuo indirizzo email. Le valutazioni dell’anteprima del sondaggio non verranno memorizzate."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visualizza in anteprima il sondaggio ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima web del sondaggio email"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprendi"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva come bozza"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome brand"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome specifico del brand può essere aggiunto nel passaggio Stile"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome cliente"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID assistenza"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappresentante assistenza"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminato"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozza"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In pausa"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sondaggio CSAT di ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apportate modifiche non salvate"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna e riprendi il sondaggio"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se non visualizzi correttamente questa email, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’assistenza che ho ricevuto non è stata di mio gradimento perché..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona motivo"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo conversazione"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio: Il cliente ha riscontrato problemi nell’aggiornamento del metodo di pagamento. L’agente ha condiviso un articolo che potrebbe essere di aiuto. Ha funzionato."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basato sull’intelligenza artificiale di Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni del servizio"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie del feedback."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual è il motivo principale del punteggio?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facci sapere perché hai assegnato questa valutazione..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sforzo elevato"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sforzo ridotto"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fai clic qui per aprire il sondaggio"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La domanda personalizzata viene visualizzata qui"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hai dato alla tua esperienza ", _interpolate(_list(0)), " un punteggio di"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia feedback"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla iscrizione"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molto insoddisfatto"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molto soddisfatto"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni presente che lo stato corrente del sondaggio e le regole di consegna non saranno inclusi nel duplicato del sondaggio"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Duplica sondaggio “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usi il frammento email incorporato, assicurati di rimuoverlo da Zendesk prima di mettere in pausa il sondaggio"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vengono raccolte risposte quando il sondaggio è in pausa."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in pausa il sondaggio"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di rimuovere il frammento di codice da Zendesk prima di mettere in pausa il sondaggio."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina sondaggio"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usi il frammento email incorporato, assicurati di rimuoverlo da Zendesk prima di eliminare il sondaggio."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa azione eliminerà definitivamente il sondaggio. Gli utenti che hanno ricevuto il sondaggio possono ancora completarlo e tutti i dati relativi al sondaggio rimarranno in Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina sondaggio ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di rimuovere il frammento di codice da Zendesk prima di eliminare il sondaggio."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modifiche applicate saranno visibili ai nuovi intervistati."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica sondaggio"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver pubblicato il sondaggio, assicurati di copiare la versione più recente del frammento di codice incorporato."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usi il frammento email incorporato, assicurati di copiarlo in Zendesk dopo aver ripreso il sondaggio."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua a inviare il sondaggio e a raccogliere le risposte."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna e riprendi il sondaggio"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurati di aggiungere il frammento di codice incorporato a Zendesk dopo aver ripreso il sondaggio."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna sondaggio"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver aggiornato il sondaggio, assicurati di copiare la versione più recente del frammento di codice incorporato."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare questo sondaggio? L'azione non può essere annullata."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare il sondaggio?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di prova è scaduto, ma puoi continuare a usare Zendesk QA passando a un account completo."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di prova è scaduto, ma puoi continuare a usare Zendesk QA passando a un account completo. Contatta il tuo amministratore locale per configurare questa opzione."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di prova è scaduto"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna ora"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il periodo di prova gratuito è terminato."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare a usare la versione completa di Zendesk QA, inserisci i dettagli di pagamento."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare a usare la versione completa di Zendesk QA, l’amministratore dell’account deve inserire i dettagli di pagamento."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci pagamento"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un utente"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Creata il ", _interpolate(_named("date")), " di ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Assegnazione ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quanto pare stai ancora svolgendo delle operazioni. Se esci senza salvare le modifiche, perderai tutte le informazioni aggiunte fino a questo momento."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa assegnazione si ripeterà una volta ogni due ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questa assegnazione si ripeterà ogni ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tutti i soggetti sottoposti a revisione sono stati trattati in un ciclo"]), _normalize(["Tutti i soggetti sottoposti a revisione inclusi in ", _interpolate(_named("nr")), " cicli"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversazioni"]), _normalize([_interpolate(_named("n")), " conversazione"]), _normalize([_interpolate(_named("n")), " conversazioni"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["di ", _interpolate(_named("nr")), " agenti"]), _normalize(["di ", _interpolate(_named("nr")), " agente"]), _normalize(["di ", _interpolate(_named("nr")), " agenti"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["partecipante"]), _normalize(["partecipanti"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " conversazioni per ciclo"]), _normalize([_interpolate(_named("n")), " conversazione per ciclo"]), _normalize([_interpolate(_named("n")), " conversazioni per ciclo"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " di fila per ogni soggetto sottoposto a revisione"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["soggetto sottoposto a revisione"]), _normalize(["soggetti sottoposti a revisione"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["revisore"]), _normalize(["revisori"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetti sottoposti a revisione"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisori"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti dello spazio di lavoro"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti dello spazio di lavoro tranne gli agenti"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ricorrente"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Assegnazione ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisione"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulle assegnazioni"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegnazione"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa azione eliminerà definitivamente l’assegnazione e rimuoverà i relativi dati dalla dashboard. Tutte le revisioni correlate rimarranno in Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina assegnazione ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione eliminata"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione duplicata"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna assegnazione aggiunta"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile creare l’assegnazione"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome assegnazione"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ciclo viene aggiornato e viene creato un nuovo elenco attività. Le revisioni già fornite vengono comunque conteggiate ai fini del traguardo."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immediatamente"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All’inizio del prossimo ciclo, il ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando pubblicare le modifiche?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modifiche apportate a questa assegnazione saranno pubblicate insieme all’assegnazione"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi impostare l’assegnazione come attiva?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorrente nel giorno di inizio dell’assegnazione"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo venerdì di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo venerdì di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo venerdì di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto venerdì di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo venerdì di ogni mese"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo lunedì di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo lunedì di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo lunedì di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto lunedì di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo lunedì di ogni mese"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo sabato di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo sabato di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo sabato di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto sabato di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo sabato di ogni mese"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima domenica di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconda domenica di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terza domenica di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta domenica di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima domenica di ogni mese"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo giovedì di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo giovedì di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo giovedì di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto giovedì di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo giovedì di ogni mese"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo martedì di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo martedì di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo martedì di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto martedì di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo martedì di ogni mese"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo mercoledì di ogni mese"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo mercoledì di ogni mese"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo mercoledì di ogni mese"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto mercoledì di ogni mese"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo mercoledì di ogni mese"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorrenza personalizzata"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo giorno di ogni mese"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terzo"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarto"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetizioni"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno feriale"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuso orario"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni ciclo"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome assegnazione"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di base"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'inizio di ogni ciclo di assegnazione, viene generato un elenco predeterminato di conversazioni da valutare."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generali"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni avanzate"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera automaticamente le nuove conversazioni quando il traguardo revisione non viene raggiunto"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il recupero dati automatico non è possibile con i traguardi revisione percentuali (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere un numero intero, non un decimale"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti la sostituzione delle conversazioni (ruolo di revisore e superiore)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituzione delle conversazioni"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti ai revisori di venire assegnare alle proprie conversazioni per la revisione"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazioni"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisioni"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero minimo di revisioni deve essere almeno 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica le revisioni minime per ciascun soggetto sottoposto a revisione"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conversazioni"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per cento (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisioni"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisioni in totale"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni revisore deve eseguire un determinato numero di revisioni"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I revisori devono esaminare collettivamente un determinato numero di conversazioni"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero minimo di revisioni deve essere almeno 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il traguardo della revisione deve essere compreso tra 1 e 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo revisione"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni soggetto sottoposto a revisione deve avere un numero minimo di conversazioni totali esaminate"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I revisori possono scegliere le conversazioni dal pool condiviso"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai revisori vengono assegnate conversazioni dal pool condiviso"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approccio di assegnazione"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i bot"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i partecipanti allo spazio di lavoro"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti dello spazio di lavoro"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi soggetti sottoposti a revisione"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi verrà rivisto?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona soggetti sottoposti a revisione"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona chi revisionare"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le esclusioni sono consentite solo quando sono selezionati tutti gli utenti dello spazio di lavoro o un gruppo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisori e soggetti sottoposti a revisione"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Attuale: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio di lavoro predefinito"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quale tipo di utente deve essere selezionato come soggetto sottoposto a revisione?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente più attivo"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipante"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi soggetti sottoposti a revisione (facoltativo)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utenti da escludere"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi revisori (facoltativo)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utenti da escludere"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti dello spazio di lavoro tranne gli agenti"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi revisori"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi effettuerà le revisioni?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona revisori"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autovalutazioni non sono consentite in questo spazio di lavoro"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti autovalutazioni"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autovalutazioni non sono consentite in questo spazio di lavoro."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Conversazione corrispondente oggi"]), _normalize(["Conversazioni corrispondenti oggi"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viene visualizzata come se l’assegnazione fosse iniziata oggi."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I numeri futuri potrebbero variare."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun soggetto sottoposto a revisione selezionato"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun revisore selezionato"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Soggetto sottoposto a revisione"]), _normalize(["Soggetti sottoposti a revisione"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Revisore"]), _normalize(["Revisori"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero automatico: consentito in caso di conversazioni insufficienti"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni venerdì a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni lunedì a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni sabato a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni domenica a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni giovedì a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni martedì a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ogni mercoledì a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ogni giorno a partire dal ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inizia il ", _interpolate(_named("startDateTime")), " e termina il ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condizioni di data"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " condizioni di conversazione e help desk"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nessuna conversazione e ", _interpolate(_named("count")), " condizione di help desk"]), _normalize(["Nessuna conversazione e ", _interpolate(_named("count")), " condizioni di help desk"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " conversazione e nessuna condizione di help desk"]), _normalize([_interpolate(_named("count")), " conversazioni e nessuna condizione di help desk"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Approccio: i revisori possono scegliere le conversazioni"]), _normalize(["Approccio: ai revisori vengono assegnate le conversazioni"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare, correggi gli errori evidenziati nel riepilogo"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo: i revisori devono esaminare tutte le conversazioni"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Traguardo: i revisori devono esaminare una conversazione"]), _normalize(["Traguardo: i revisori devono esaminare ", _interpolate(_named("count")), " conversazioni"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traguardo: i revisori devono esaminare il ", _interpolate(_named("count")), "% conversazioni"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo: nessun traguardo di revisione impostato"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo: ogni soggetto sottoposto a revisione deve sottoporre a revisione tutte le proprie conversazioni"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traguardo: ogni soggetto sottoposto a revisione deve avere ", _interpolate(_named("count")), " delle sue conversazioni totale riviste"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traguardo: ogni soggetto sottoposto a revisione deve avere il ", _interpolate(_named("count")), "% delle sue conversazioni totale riviste"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Traguardo: ogni revisore deve fornire una revisione"]), _normalize(["Traguardo: ogni revisore deve fornire ", _interpolate(_named("count")), " revisioni"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sostituzione: i revisori non possono sostituire le conversazioni"]), _normalize(["Sostituzione: i revisori possono sostituire le conversazioni"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autovalutazioni: ai revisori possono essere assegnate le proprie conversazioni"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna condizione di data impostata"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nome di assegnazione impostato"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " a partire dal ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quindicinale"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giornaliero"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimanalmente"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata ciclo"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta come Attivo"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva come bozza"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione salvata come bozza"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione impostata come Non attiva"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta come non attiva"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegnazione"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione creata"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come attiva"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come non attiva"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gli aggiornamenti in attesa per l’assegnazione avranno effetto nel ciclo successivo, a partire dal ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica modifiche"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozza"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attiva"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna assegnazione"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione aggiornata"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente o il bot ha concluso la conversazione."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente ha concluso la conversazione in modo educato"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se la conversazione è stata conclusa"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusura"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza gli errori di grammatica, ortografia e stile nelle interazioni tra agenti e bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza gli errori grammaticali, ortografici e di stile dell’agente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza gli errori grammaticali, ortografici e di stile dell’agente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortografia e grammatica"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza la conversazione per individuare i saluti iniziali di agenti e bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di frasi di saluto iniziale tipiche"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di saluti iniziali tipici"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluto iniziale"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente comprende e riconosce lo stato d’animo dei clienti"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifica i casi in cui l’agente rispecchiava il sentiment del cliente o usava parole di attenzione come “conferma” e “riconoscimento”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente capisce la domanda o il dubbio del cliente"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza se l’agente capisce la domanda o il dubbio del cliente"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprensione"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza la facilità di comprensione di un testo, considerando la complessità delle parole e la lunghezza delle frasi"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza la facilità di comprensione di un testo, considerando la complessità delle parole e la lunghezza delle frasi"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo messaggio contiene 1 frase troppo lunga, che ne rende difficile la lettura"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Questo messaggio contiene ", _interpolate(_list(0)), " frasi troppo lunghe, che ne rendono difficile la lettura"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggibilità"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di una soluzione offerta"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza l’intera conversazione alla ricerca di una soluzione offerta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione offerta"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza il tono dell’agente durante la conversazione"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza il tono dell’agente durante la conversazione"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare errori nei messaggi dell’agente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile determinare gli errori grammaticali"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna frase di saluto iniziale rilevata. Se necessario, aggiungine di nuove tramite l’amministratore in Impostazioni."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha concluso la conversazione in modo educato"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna frase di chiusura rilevata. Se necessario, aggiungine di nuove tramite l’amministratore in Impostazioni"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha rispecchiato il sentiment del cliente o ha usato parole di attenzione"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare se l’agente ha rispecchiato lo stato d’animo del cliente o ha usato parole di attenzione"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha usato frasi lunghe e parole complesse, rendendole potenzialmente difficili da capire per alcuni interlocutori"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha usato parole complesse, rendendole potenzialmente difficili da capire per alcuni interlocutori"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha usato frasi lunghe, rendendole potenzialmente difficili da capire per alcuni interlocutori"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha usato un linguaggio facile da capire"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati insufficienti per valutare questa categoria"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha faticato a comprendere la domanda/richiesta del cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha compreso bene la domanda/richiesta del cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare se l’agente ha compreso la domanda/richiesta del cliente"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono dell’agente durante la conversazione"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono dell’agente durante la conversazione"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono dell’agente durante la conversazione"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono dell’agente durante la conversazione"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tono dell’agente durante la conversazione"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile determinare il tono dell’agente"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione non è stata conclusa"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conversazione è stata conclusa"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare se la conversazione è stata conclusa"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente non ha mostrato empatia nei confronti del cliente e dei suoi problemi"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha mostrato empatia nei confronti del cliente e dei suoi problemi"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare se l’agente ha mostrato empatia nei confronti del cliente e dei suoi problemi "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente non ha ricevuto un saluto durante la conversazione"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente ha ricevuto un saluto durante la conversazione"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare il saluto iniziale tipico"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente non ha fornito una soluzione al cliente"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’agente ha offerto la soluzione al cliente"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile rilevare se l’agente ha offerto una soluzione al cliente"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole complesse"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frasi lunghe"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grammatica"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di ortografia"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativo"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispiaciuto"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calmo"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegro"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curioso"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionale"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solidale"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stile"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 chiusura"]), _normalize([_interpolate(_named("n")), " chiusure"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA controlla solo le chiusure approvate. Se questa categoria è impostata come critica, il mancato utilizzo delle chiusure approvate non consentirà la revisione."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusure approvate"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiusure approvate"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 esenzione"]), _normalize([_interpolate(_named("n")), " esenzioni"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole e frasi che AutoQA non considera errori di ortografia o grammatica."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzioni ortografiche e grammaticali"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esenzioni AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 saluto iniziale"]), _normalize([_interpolate(_named("n")), " saluti iniziali"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA controlla solo gli annunci approvati. Se questa categoria è impostata come critica, il mancato utilizzo dei saluti iniziali approvati non consentirà la revisione."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluti iniziali approvati"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saluti iniziali approvati"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria AutoQA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una categoria AutoQA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause principali assegnate automaticamente:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account → Impostazioni"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alcune categorie sfruttano MS Azure, offrendo una gamma estesa di funzionalità e lingue supportate. Seleziona ", _interpolate(_named("link")), " per ulteriori informazioni oppure contatta un amministratore."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accedi a un elenco esteso di categorie e lingue supportate sfruttando MS Azure. Per farlo, seleziona ", _interpolate(_named("link")), " oppure contatta un amministratore."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle categorie AutoQA viene automaticamente assegnato un punteggio mediante una scala di valutazione fissa. Puoi modificare la valutazione manualmente in un secondo momento. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una categoria AutoQA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un punteggio assegnato automaticamente dipende dalla gravità degli errori e dal loro numero. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause principali"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai revisori vengono mostrate solo le categorie di valutazione pertinenti. Le categorie di valutazione vengono visualizzate in base alle condizioni impostate durante la creazione di una categoria."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onboarding"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefono"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleziona il tipo di categoria di valutazione per lo spazio di lavoro \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I revisori devono selezionare manualmente la scheda punteggi corretta per visualizzare le categorie di valutazione pertinenti per ciascuna conversazione."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi modificare il tipo di categoria di valutazione?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica categorie"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare il tipo di categoria di valutazione?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione condizionale"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione statica"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di categoria di valutazione"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni categorie di valutazione"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azione non può essere annullata."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminerà tutte le valutazioni"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In alternativa, puoi conservare le statistiche senza eliminare alcun dato archiviando una categoria."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Capisco che ", _interpolate(_named("deleteAllRatings")), " fornite per questa categoria."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es., Il tono è perfetto"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra categoria solo se"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Sempre\" verrà ignorato. Non può essere combinato con altre condizioni."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag dell’help desk"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona condizione"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio di soddisfazione (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo origine"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale conversazione"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per una categoria è necessario un nome"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi la categoria a una scheda punteggi"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea categoria"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea categoria"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione aggiunta"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una categoria archiviata non è visibile nella scheda punteggi, ma ti consente di conservare le statistiche senza eliminare alcun dato."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia categoria"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Archivia categoria ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione archiviata"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina categoria"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita \"elimina categoria\" per eliminare questa categoria"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elimina categoria"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Elimina categoria ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione eliminata"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione duplicata"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo di categorie di valutazione aggiunto"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo di categorie di valutazione eliminato"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine gruppo di categorie di valutazione modificato"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo di categorie di valutazione aggiornato"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione annullata"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione aggiornata"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando un agente non supera una categoria critica, tutte le categorie in quella revisione hanno esito negativo automaticamente."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di valutazione critica"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria in questo gruppo"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuova categoria di valutazione"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica categoria"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina tutte le categorie e verifica i dati in questo gruppo"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se elimini il gruppo di categorie, tutte le categorie a esso collegate saranno separate."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina gruppo di categorie ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La scelta di eliminare le categorie nel gruppo comporterà la perdita di tutti i dati delle revisioni associati a queste categorie."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome gruppo"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I revisori possono selezionare più di un motivo come causa principale"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I revisori possono selezionare più di un motivo come causa principale"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più motivi"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad es., Tono"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo gruppo di categorie"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo gruppo"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi “Altro”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi causa principale"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa causa principale esiste già"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi causa principale"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi le cause principali per spiegare la valutazione"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala di valutazione"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attive"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviate"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi scheda punteggi a questa categoria di valutazione"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla archiviazione"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna categoria"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le valutazioni"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrato per"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni negative"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni negative e neutre"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, aggiorna"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le categorie più importanti per te possono avere più peso nel calcolo del punteggio del ticket."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Peso (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulla calibrazione"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulla calibrazione"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo svolgimento di sessioni di calibrazione regolari consente ai revisori di mantenere allineate le proprie tecniche di valutazione e garantire agli agenti lo stesso livello di feedback coerente e imparziale."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza piani"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": è un processo che aiuta il team a rimanere aggiornato su come gestire e valutare tutte le interazioni con i clienti."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La calibrazione è disponibile per i nostri piani ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni fornite in precedenza non vengono visualizzate quando una conversazione viene aggiunta alla sessione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non visibili"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revisioni fornite in precedenza sono considerate revisioni di calibrazione. Verrà aggiunta alla sessione una sola revisione di conversazione per revisore. Le revisioni specifiche dei messaggi non vengono visualizzate."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibili"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità delle revisioni fornite in precedenza"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La calibrazione è un processo che aiuta il team a rimanere aggiornato su come gestire e valutare tutte le interazioni con i clienti. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti hanno accesso illimitato a tutte le revisioni fornite durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza sempre tutte le revisioni"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver inviato una revisione, gli utenti vedranno le valutazioni fornite da altri revisori durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutte le revisioni delle calibrazioni dopo aver inviato una revisione"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti hanno accesso illimitato a tutte le revisioni fornite durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza sempre tutte le revisioni"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver inviato una revisione, gli utenti vedranno le valutazioni fornite da altri revisori durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutte le revisioni delle calibrazioni dopo aver inviato una revisione"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver inviato una revisione, gli utenti vedranno le valutazioni fornite da altri revisori durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutte le revisioni delle calibrazioni dopo aver inviato una revisione"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti vedranno solo le proprie revisioni durante una sessione di calibrazione."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza solo le proprie revisioni"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni di calibrazione aggiornate"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità delle revisioni delle calibrazioni"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la soglia per le metriche della dashboard colmando le lacune in base al sistema di metriche interne del team. Verifica la soglia nella dashboard."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazioni"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibrazione"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessioni"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generali"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia aggiornata"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo un momento."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assicurati di disporre delle autorizzazioni di amministratore e ", _interpolate(_named("retry")), " oppure ", _interpolate(_named("contact")), " per ulteriore assistenza."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contattaci"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che non siamo riusciti ad accedere all’API dell’help desk."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riprova"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo collegando il tuo help desk. Verrai reindirizzato momentaneamente."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " autorizzato"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Torna alla tua ", _interpolate(_list(0)), " per aggiungere una nuova connessione all’help desk."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che tu abbia saltato uno o due passaggi."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già un’organizzazione con lo stesso dominio email"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagina delle connessioni"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel frattempo..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi un attimo mentre controlliamo le tue autorizzazioni."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel frattempo..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crea uno spazio di lavoro"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per continuare a usare Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quasi pronto per l’uso"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro trovato"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia account"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per continuare a usare Zendesk QA, il tuo manager deve assegnarti a uno spazio di lavoro."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’eliminazione dell’account comporta l’eliminazione definitiva di tutti i dati in nostro possesso: ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutte le valutazioni, i contenuti delle conversazioni, gli spazi di lavoro, ecc."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi a Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnetti"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disconnessione dell’integrazione disabiliterà tutte le notifiche QA di Zendesk tramite Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnetti Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’integrazione Slack è stata disconnessa da Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisci le impostazioni di notifica predefinite per tutti i nuovi utenti dell’account."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituisci le impostazioni per tutti gli utenti"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai per sovrascrivere le impostazioni delle notifiche per tutti gli utenti dell’account"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti non potranno definire le proprie impostazioni di notifica."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni di notifica aggiornate"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisa quando una categoria critica non funziona"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisa quando CSAT riceve una risposta"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea spazio di lavoro e dati demo"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricrea i dati demo e conserva lo spazio di lavoro demo."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi spazio di lavoro e i dati demo"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna i dati demo"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna dati"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gestisci account \"", _interpolate(_named("accountName")), "\" in modalità demo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità demo"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/6059285322522-Informazioni-sulle-funzioni-di-intelligenza-artificiale-generativa-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043712839450-Configurazione-delle-impostazioni-di-sicurezza-avanzate-per-le-connessioni-all-help-desk"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043747123354-Impostazione-del-punteggio-automatico-in-Zendesk-QA-usando-AutoQA"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7418648293018-Valutazione-delle-prestazioni-degli-agenti-di-AI-usando-il-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043724530842-Configurazione-della-calibrazione-in-Zendesk-QA"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043759436954-Analisi-dei-punteggi-delle-categorie-nel-tempo"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043747231642-Uso-della-vista-Conversation-Insights"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043747649690-Creazione-di-pi%C3%B9-scorecard"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043701093786-Informazioni-sui-calcoli-del-dashboard-Zendesk-QA-Reviews"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043759449114-Ricerca-di-conversazioni-da-rivedere-usando-filtri-personalizzati"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043760162074-Gestione-di-utenti-e-spazi-di-lavoro-in-Zendesk-QA"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043759543194-Uso-di-note-appuntate-per-coach-gli-agenti"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043747142938-Gestione-dei-gruppi-nel-Zendesk-QA"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043759586074-Uso-degli-insight-Zendesk-QA-In-evidenza-per-filtrare-le-conversazioni"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/articles/7043759396250-Monitoraggio-del-tempo-di-revisione-nel-Zendesk-QA"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/it/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimani nella pagina"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci dalla pagina"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscire da questa pagina e perdere tutte le modifiche?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai completato l’assegnazione"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegnazione"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnazione completata"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna assegnazione trovata"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non ci sono attività da rivedere."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica l’assegnazione o attendi l’inizio del ciclo successivo."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova ad affinare i filtri"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica assegnazione"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieni d’occhio questa pagina per eventuali aggiornamenti"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna attività per questo ciclo"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione da visualizzare"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna attività come completata"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai rivisto questo utente."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna autovalutazione in questa assegnazione"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi facendo clic sull’icona di calibrazione nell’intestazione della vista conversazione."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna conversazione aggiunta"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegnazione"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea sessione di calibrazione"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue assegnazioni"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre assegnazioni"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna assegnazione attiva"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna assegnazione"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i soggetti sottoposti a revisione"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i soggetti sottoposti a revisione tranne me"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun soggetto sottoposto a revisione trovato"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca soggetto sottoposto a revisione"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozze"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimossi"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli stati"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da gestire"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i revisori"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i revisori tranne me"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnate a me"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun revisore trovato"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca revisore"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorni"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorni"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorno"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorni"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorni"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " giorni"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ora"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ore"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuti"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuti"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuto"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuti"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuti"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuti"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disp."])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti dello spazio di lavoro"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore corrente"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo traguardo per questo ciclo"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo revisore"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona revisore"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna conversazioni"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna conversazione"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazioni da spostare"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciclo corrente"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica assegnazione"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun revisore trovato"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca revisore"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna conversazioni a me"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna conversazioni a un altro utente"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisori"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " giorni"]), _normalize([_interpolate(_named("n")), " giorno"]), _normalize([_interpolate(_named("n")), " giorni"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ore"]), _normalize([_interpolate(_named("n")), " ora"]), _normalize([_interpolate(_named("n")), " ore"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi cicli completati"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi sessioni completate"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo traguardo"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un motivo per rimuovere questa conversazione"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiega perché stai rimuovendo questa conversazione"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi conversazione"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi mantenere le modifiche?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un motivo per sostituire questa conversazione"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta modifiche"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni le modifiche"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituzione non trovata"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiega perché stai sostituendo questa conversazione"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi ancora rimuovere questa conversazione dall’assegnazione? Il traguardo verrà ridotto di 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituisci"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituisci conversazione"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina bozza"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica bozza"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna a me"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna a un altro utente"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassegna e rivedi"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi conversazione"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia una revisione"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra meno"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra altro"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Traguardo: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " rivisti"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni fornite"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra cicli completati"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra sessioni completate"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo rimasto"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione sostituita"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva elenco attività"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " dopo"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " secondo"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " secondi"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gg"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gg ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gg ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gg"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " giorni"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " gg ", _interpolate(_named("minutes")), " m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " m ", _interpolate(_named("seconds")), " s"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " mese"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " s"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " anni"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adesso"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponi delle autorizzazioni. Contatta l’amministratore per assistenza."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pagina che cerchi non esiste."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore inaspettato. Contatta l’assistenza per assistenza."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso negato. Non è consentito eseguire tale azione."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pagina che cerchi non esiste oppure non puoi accedervi."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai andando un po’ troppo veloce e abbiamo difficoltà a stare al passo."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche salvate"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apportate modifiche non salvate"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " applicate"]), _normalize([_interpolate(_named("n")), " applicata"]), _normalize([_interpolate(_named("n")), " applicate"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connesso"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata da"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critica"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatto"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ex membro"]), _normalize(["un ex membro"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selezionate"]), _normalize([_interpolate(_named("n")), " selezionato"]), _normalize([_interpolate(_named("n")), " selezionati"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...+ 1 altro"]), _normalize(["...+ altri ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+ 1 altro"]), _normalize(["+ altri ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novità"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oppure"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimosso"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " risposte"]), _normalize([_interpolate(_named("n")), " risposta"]), _normalize([_interpolate(_named("n")), " risposte"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utente"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Venerdì"]), _normalize(["Venerdì"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lunedì"]), _normalize(["Lunedì"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sabato"]), _normalize(["Sabati"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Domenica"]), _normalize(["Domeniche"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Giovedì"]), _normalize(["Giovedì"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Martedì"]), _normalize(["Martedì"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mercoledì"]), _normalize(["Mercoledì"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Amministratore"]), _normalize(["un amministratore"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Account Manager"]), _normalize(["un Account Manager"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utente"]), _normalize(["un utente"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiato"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sessione è scaduta."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo non può essere vuoto."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun elemento trovato."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna persona corrispondente."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Titolare"]), _normalize(["un titolare"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima di iniziare, leggi e accetta le nostre condizioni."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviato"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proseguire?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni effettuate complessive"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agente"]), _normalize(["un agente"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Responsabile"]), _normalize(["un responsabile"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manager"]), _normalize(["un manager"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Revisore"]), _normalize(["un revisore"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente anonimo"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnatario"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A breve"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic per copiare"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia link"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona di pericolo"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione (facoltativa)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma obbligatoria"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scartare le modifiche non salvate?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequenza"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traguardo"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help desk"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingue"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso di superamento"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni personali"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra di meno"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia di nuovo"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soggetto sottoposto a revisione"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute/visualizzate"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni effettuate da un membro dello spazio di lavoro"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modifiche"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala binaria"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala a 3 punti"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala a 4 punti"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scala a 5 punti"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona frequenza"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra meno"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra altro"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salta"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In evidenza"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaggio"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sondaggio"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di tempo"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apportate modifiche non salvate"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sei stato invitato da ", _interpolate(_list(0)), " a entrare a far parte di Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gli utenti vengono connessi automaticamente dal Centro amministrativo. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci utenti"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulla gestione degli utenti"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Per alcuni utenti vengono effettuati il provisioning e la sincronizzazione automatica dal provider di identità. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autorizzazione account di “", _interpolate(_list(0)), "” modificata"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sulle autorizzazioni degli utenti"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non chiedermelo più"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tutte le modifiche apportate alla pagina degli utenti si riflettono negli spazi di lavoro. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciò significa che l’accesso degli utenti ad alcune parti di Zendesk QA potrebbe cambiare"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica autorizzazione a livello di account"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente rimosso"]), _normalize([_interpolate(_named("n")), " utenti rimossi"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quando rimuovi un utente, questi perderà l’accesso al tuo account. Le revisioni, i commenti e le valutazioni rimarranno in Zendesk QA. ", _interpolate(_named("linebreak")), "La rimozione di un utente non influirà sul numero di licenze nell’abbonamento."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["L’utente verrà rimosso da tutti gli spazi di lavoro che gestisci. ", _interpolate(_named("linebreak")), " Le revisioni, i commenti e le valutazioni rimarranno intatti."]), _normalize(["Verranno rimossi da tutti gli spazi di lavoro che gestisci. ", _interpolate(_named("linebreak")), " Tutte le revisioni, i commenti e le valutazioni rimarranno intatti."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rimuovi ", _interpolate(_named("name"))]), _normalize(["Rimuovi gli utenti ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può visualizzare tutto e gestire tutte le impostazioni e la fatturazione"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può visualizzare tutto e gestire le impostazioni personali e dello spazio di lavoro, tranne la fatturazione"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni account"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le autorizzazioni specifiche per lo spazio di lavoro consentono agli utenti di visualizzare (e) modificare i dati negli spazi di lavoro in cui si trovano."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni su BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I bot vengono visualizzati qui quando le conversazioni a cui hanno partecipato sono sincronizzate"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun bot sincronizzato"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegnando un bot come utente, gli verrà concesso di effettuare l’accesso. I dati storici dei bot rimarranno disponibili nelle dashboard."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun bot trovato"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a cercare di nuovo usando una parola chiave o un’ortografia diversa."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca bot"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca in base al nome del bot"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come utente"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo gli amministratori e i gestori degli account possono contrassegnare i bot come utenti"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome bot"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima chat"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegnato manualmente"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisionabile"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il bot viene rimosso dall’analisi automatica e da qualsiasi assegnazione. I nuovi dati non verranno visualizzati nelle dashboard, garantendo report accurati e una gestione più semplice dei bot."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generativo"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot flusso di lavoro"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi ai gruppi"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i gruppi"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi dai gruppi"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gruppo"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione account"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi a spazi di lavoro"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività più recente"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo a livello di account"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ogni utente viene assegnata un’autorizzazione a livello di account e/o spazio di lavoro. Le autorizzazioni di amministratore ora sono a livello di account, mentre le altre autorizzazioni sono a livello di spazio di lavoro."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi da spazi di lavoro"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione spazio di lavoro"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni gestite nel gruppo"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dettagli"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegna come bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrassegnando un utente come bot, il suo accesso verrà rimosso. I dati utente cronologici rimarranno disponibili nelle dashboard."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi il bot dall’analisi automatica e da eventuali assegnazioni. I nuovi dati non verranno visualizzati nelle dashboard, garantendo report accurati e una gestione più semplice dei bot."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escludi il bot dalle revisioni"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente contrassegnato come bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo il tipo di autorizzazione Utente può essere contrassegnato come bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli utenti con più account non possono essere contrassegnati come bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per utente"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti, bot e spazi di lavoro"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAZI DI LAVORO"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti e spazi di lavoro"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna autorizzazione"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivi"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessi"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunto manualmente"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invito in attesa"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervento necessario"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...e un altro"]), _normalize(["...e altri ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia autorizzazione in"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rimuovi e invita nuovamente a modificare il ruolo di ", _interpolate(_named("n")), " utente in attesa di invito."]), _normalize(["Rimuovi e invita nuovamente a modificare il ruolo di ", _interpolate(_named("n")), " utenti in attesa di invito"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Modifica dettagli per ", _interpolate(_named("name"))]), _normalize(["Modifica dettagli per ", _interpolate(_named("n")), " utenti selezionati"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente aggiornato."]), _normalize([_interpolate(_named("n")), " utenti aggiornati."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Questo gruppo contiene 1 utente di un altro spazio di lavoro."]), _normalize(["Questo gruppo contiene ", _interpolate(_named("n")), " utenti di un altro spazio di lavoro."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" archiviato"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo archiviato"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea gruppo"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" creato"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo senza titolo"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare definitivamente questo gruppo?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina gruppo"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina gruppo"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica gruppo"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile gruppo"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo gruppo"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" nascosto"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo gruppo non sarà visibile altrove in Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci solo le autorizzazioni dello spazio di lavoro"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo non visibile in Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabile"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membro"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo senza titolo"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un altro gruppo ha già questo nome"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un gruppo archiviato ha già questo nome"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo ruolo consente solo la gestione dei membri di questo gruppo. Contatta un utente con autorizzazioni superiori per apportare ulteriori modifiche."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi come responsabile del gruppo"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" ripristinato"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" salvato"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta come responsabile del gruppo"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Archiviazione gruppo \"", _interpolate(_named("name")), "\" annullata"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche non salvate"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi gli utenti esistenti a questo gruppo"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per nome o indirizzo email"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi membri"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppo \"", _interpolate(_named("name")), "\" visibile"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ogni membro è ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui gruppi"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il provisioning e la sincronizzazione di alcuni attributi dei gruppi vengono effettuati automaticamente dal provider di identità."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sui gruppi"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea gruppo"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza facilmente i membri del team e imita la struttura organizzativa."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi almeno 1 membro"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi il nome di un gruppo"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già un gruppo con questo nome"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri del gruppo"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome gruppo"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza i membri del team e imita la struttura organizzativa."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nessun gruppo"]), _normalize(["1 gruppo"]), _normalize([_interpolate(_named("count")), " gruppi"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo gruppo o attivane uno archiviato."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo attivo disponibile"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo archiviato"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo trovato"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a cercare di nuovo usando una parola chiave o un’ortografia diversa."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo aggiunto"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["in questi"]), _normalize(["in"]), _normalize(["in"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per nome gruppo"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivi"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviati"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nuovo invio possibile ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " licenze rimaste"]), _normalize([_interpolate(_named("n")), " licenza rimasta"]), _normalize([_interpolate(_named("n")), " licenze rimaste"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di licenze al momento è limitato, in quanto l’abbonamento è in pausa. Riattivalo per aggiungere altri utenti."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite di licenze per l’account. Puoi sostituire gli utenti disattivando un utente attivo o aggiungendo altre licenze."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dettagli pagamento"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " selezionato"]), _normalize([_interpolate(_named("n")), " selezionati"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente selezionato"]), _normalize([_interpolate(_named("n")), " utenti selezionati"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " righe"]), _normalize([_interpolate(_named("n")), " riga"]), _normalize([_interpolate(_named("n")), " righe"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca gli utenti in blocco in base al nome o all’indirizzo email."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incolla qui i dati separati da virgole o a capo"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca utenti in blocco"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Modifica autorizzazioni spazio di lavoro per ", _interpolate(_named("name"))]), _normalize(["Modifica autorizzazioni spazio di lavoro per ", _interpolate(_named("n")), " membri selezionati"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica autorizzazioni"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona autorizzazione spazio di lavoro"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rimuovi ", _interpolate(_named("name")), " dallo spazio di lavoro"]), _normalize(["Rimuovi ", _interpolate(_named("name")), " membri dallo spazio di lavoro"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " membro rimosso"]), _normalize([_interpolate(_named("n")), " membri rimossi"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rimuovi membro"]), _normalize(["Rimuovi membri"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Questo utente verrà rimosso dallo spazio di lavoro."]), _normalize(["Questi utenti verranno rimossi dallo spazio di lavoro."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autorizzazione spazio di lavoro di “", _interpolate(_list(0)), "” modificato."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi membri"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza le conversazioni e le relative revisioni"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto nello spazio di lavoro, ma gestisci solo quiz, assegnazioni, contestazioni e sessioni di calibrazione"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazioni spazio di lavoro"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza e gestisci tutto all’interno dello spazio di lavoro"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto nello spazio di lavoro, a eccezione delle impostazioni dello spazio di lavoro"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connetti gli utenti"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti degli spazi di lavoro che gestisci sono stati aggiunti a questo spazio di lavoro."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiedi a un amministratore di farlo."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita direttamente i nuovi utenti o chiedi a un amministratore di farlo."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " oppure ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente da visualizzare"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " utente aggiunto"]), _normalize([_interpolate(_named("n")), " utenti aggiunti"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aggiungi membri"]), _normalize(["Aggiungi membro"]), _normalize(["Aggiungi ", _interpolate(_named("n")), " membri"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona autorizzazione spazio di lavoro"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utenti spazio di lavoro"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aggiungi membri a ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incolla qui gli indirizzi email, separati da a capo o virgole"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Abbinati ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " indirizzi email"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con utenti già connessi:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Impossibile abbinare ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " indirizzi email"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla l’ortografia o connettili singolarmente in un secondo momento"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con gli utenti dell’help desk:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente connesso"]), _normalize([_interpolate(_named("n")), " utenti connessi"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente invitato"]), _normalize([_interpolate(_named("n")), " utenti invitati"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti in blocco"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita in blocco"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio:\n\nkate", "@", "esempio.com, john", "@", "esempio.com\n\nkate", "@", "esempio.com\nkate", "@", "esempio.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti dal tuo help desk per rivederne le interazioni"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Connetti utente"]), _normalize(["Connetti utenti"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi email"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti singolarmente"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vuoi che gli utenti connessi accedano a Zendesk QA, puoi invitarli qui."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita utenti connessi"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Invita utente"]), _normalize(["Invita utenti"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salta l’invito"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli utenti da connettere dall’help desk per rivederne le interazioni"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci il tuo abbonamento"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connetti utenti help desk"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna un nome univoco allo spazio di lavoro"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se l’opzione è abilitata, tutte le connessioni dell’account esistenti vengono aggiunte automaticamente al nuovo spazio di lavoro."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi tutte le connessioni a questo spazio di lavoro"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea spazio di lavoro"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome spazio di lavoro"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea spazio di lavoro"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agli utenti aggiunti direttamente all’account (senza assegnare uno spazio di lavoro) viene assegnato un ruolo a livello di account. Puoi aggiungerli agli spazi di lavoro in un secondo momento."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agli utenti invitati viene assegnato automaticamente un ruolo utente a livello di account. Puoi farli passare al ruolo superiore di amministratore in un secondo momento."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzionano tutti"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incolla qui le email separate da a capo o virgole."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " utente invitato via email"]), _normalize([_interpolate(_named("n")), " utenti invitati via email"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agli utenti aggiunti a uno spazio di lavoro viene assegnato automaticamente un ruolo utente a livello di account. Puoi farli passare al ruolo superiore di amministratore in un secondo momento."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un altro"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita gli utenti solo nell’account senza aggiungerli a uno spazio di lavoro"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agli utenti invitati viene assegnata solo un’autorizzazione account ed essi non vengono aggiunti ad alcuno spazio di lavoro."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita gli utenti direttamente in uno spazio di lavoro e assegna le autorizzazioni allo spazio di lavoro"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi utenti allo spazio di lavoro"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita in blocco"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita singolarmente"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita solo nell’account"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita in uno spazio di lavoro"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Invita utenti"]), _normalize(["Invita utente"]), _normalize(["Invita ", _interpolate(_named("n")), " utenti"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita gli utenti in blocco via email"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Elimina utente"]), _normalize(["Elimina utenti"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseleziona"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invita utenti via email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona autorizzazione"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia nuovamente l’invito"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente connesso"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente con invito email in attesa"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona spazio di lavoro"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenti AI"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro amministrativo"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance (QA)"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione del personale (WFM)"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Completate ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completata ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Completate ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciclo ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisore"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica assegnazione"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risparmia tempo e imposta le assegnazioni di revisione automatiche anziché cercare e allocare manualmente le conversazioni da rivedere."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scadenza: ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sessioni possono essere aggiunte nella sezione “Calibrazione” nel menu laterale della vista conversazione."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna sessione aggiunta"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baseline"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le conversazioni possono essere aggiunte a una sessione facendo clic sull’icona di calibrazione nell’intestazione della vista conversazione."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna conversazione aggiunta"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non è stata fornita alcuna revisione. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia una revisione della calibrazione"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Causa principale"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai a"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la tavolozza comandi."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trovati per ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento tavolozza comandi in corso..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagine, filtri, conversazioni, impostazioni..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversazione"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro privato"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro pubblico"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Da ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalla tua API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Visualizzazione da ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " di ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Visualizzazione da ", _interpolate(_named("rangeStart")), " a ", _interpolate(_named("rangeEnd")), " di ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi pannello laterale"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra pannello laterale"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva barra laterale"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni accettate"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni rifiutate"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni aperte"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni accettate parzialmente"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni fornite"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni visualizzate"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accettate"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aperte"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parzialmente accettate"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " del totale"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rifiutate"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettate"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperte"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettate parzialmente"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutate"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["revisione"]), _normalize(["revisioni"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di revisioni visualizzate dai soggetti sottoposti a revisione"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di revisioni visualizzate"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisori contestati"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autori contestazioni"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni per categoria"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contestazioni nel tempo"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperte"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di soluzione medio"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di soluzione medio (ore)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di soluzione medio (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risolte"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale contestazioni"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona hashtag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettate"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua di risposta"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna lingua trovata"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le lingue"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattori previsti"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun fattore trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i fattori"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole Wordcloud"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna parola trovata"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le parole"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menzioni"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più recenti"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno recenti"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperte"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettate parzialmente"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ha reagito con ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutate"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risolte"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tonalità pelle"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioni"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna sessione trovata"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna categoria trovata"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione commento"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna dimensione trovata"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le dimensioni"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro trovato"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i gruppi"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun hashtag trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli hashtag"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun valore trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i valori"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun campo personalizzato trovato"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag dell’help desk"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun tag dell’help desk trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i tag dell’help desk"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento risultati in corso..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi di domanda"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun tipo di domanda trovato"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi reazione"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede punteggi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna scheda punteggi trovata"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le schede punteggi"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun punteggio trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i punteggi"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origini"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna origine trovata"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le origini"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del feedback"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun motivo trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i motivi"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sondaggi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun sondaggio trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i sondaggi"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canali"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun canale trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i canali"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno spazio di lavoro trovato"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli spazi di lavoro"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna ai quiz"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il quiz è stato eliminato o non è stato ancora pubblicato. Ricontrolla il link o torna all’elenco dei quiz."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo quiz non è stato condiviso in uno spazio di lavoro a cui appartieni. Chiedi a un amministratore di aggiungerti a uno spazio di lavoro a cui ha accesso questo quiz."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il quiz non è disponibile"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso al quiz concesso a spazi di lavoro a cui non appartieni. Impossibile salvare e pubblicare il quiz."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiedi a un amministratore di salvare e pubblicare il quiz."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi contrassegnare almeno una delle risposte come corretta."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo non può essere vuoto."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai visualizzando un’anteprima del quiz."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli spazi di lavoro che possono accedere al quiz. Gli utenti appartenenti a più spazi di lavoro possono rispondere al quiz una sola volta. Selezionando \"Tutti gli spazi di lavoro\" non verranno inclusi gli spazi di lavoro creati dopo la pubblicazione del quiz."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso al quiz"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci dalla modalità di anteprima"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai il quiz"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica quiz"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni quiz"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva come bozza"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo quiz"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla archiviazione"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opzioni"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi opzione"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividilo"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niente male, vero?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La risposta è stata inviata"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il quiz è stato salvato"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo quiz è stato pubblicato"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il quiz è stato rimosso"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi domanda"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I quiz archiviati e i relativi risultati non appariranno più nell’elenco dei quiz. Puoi comunque accedere ai risultati di un quiz archiviato e annullare l’archiviazione quando necessario."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivia quiz"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta pubblicato, un quiz non può essere cambiato o modificato. Tutti i quiz pubblicati saranno accessibili a tutti gli utenti del tuo account."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi pubblicare il quiz?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare questo quiz? L'azione non può essere annullata."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare il quiz?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta inviate, le risposte non possono essere modificate."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare il quiz?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla archiviazione quiz"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’annullamento dell’archiviazione rende il quiz disponibile nella panoramica dei quiz. Consente ai partecipanti precedenti di accedere ai propri risultati e ai nuovi partecipanti di rispondere al quiz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla archiviazione quiz"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda duplicata"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione (facoltativa)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sposta la domanda in basso"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sposta domanda in alto"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nell’anteprima vengono visualizzate solo le domande con risposte."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi domanda"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia quiz"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz senza titolo"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo quiz"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione eliminerà definitivamente il quiz e i relativi risultati."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In alternativa, puoi comunque accedere ai risultati archiviando il quiz."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con risposta"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina quiz"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa operazione eliminerà definitivamente il quiz e i relativi risultati."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Elimina quiz ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplica"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I quiz aiutano ad aumentare la fiducia e le competenze del team, a fornire esperienze coerenti e a integrare i nuovi membri del team."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi cercare i quiz in base al nome del quiz o al nome dell’autore."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun quiz creato"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta elenco quiz"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio medio"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data completamento"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autore"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz/Autore/Spazi di lavoro"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pubblicazione"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazi di lavoro"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per quiz, autore"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviato"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozza"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i quiz"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il quiz è stato archiviato."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’archiviazione del quiz è stata annullata."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo quiz non ha ricevuto risposte dagli utenti negli spazi di lavoro selezionati."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo quiz non ha ricevuto risposte prima di essere archiviato."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna risposta"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui vedrai un dettaglio delle risposte del quiz.\nNessuno ha ancora compilato il quiz: condividerlo sarebbe sicuramente di aiuto."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessuna risposta"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposte:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio medio:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuali"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Copia"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copiato"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quiz di ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi cercare il nome del partecipante nella classifica."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta classifica"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punteggio"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca per partecipante"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classifica"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva classifica"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciò significa che non potrai filtrare questo hashtag nella dashboard e non verrà più completato automaticamente nei commenti. Questa azione è irreversibile."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea hashtag"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea hashtag"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea hashtag"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti ai membri di creare nuovi hashtag"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzati"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina hashtag"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Elimina hashtag ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creazione di hashtag è bloccata"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La creazione di hashtag è sbloccata"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag “", _interpolate(_list(0)), "” eliminato"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hashtag “", _interpolate(_list(0)), "” rinominato in “", _interpolate(_list(1)), "”"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag aggiunto"]), _normalize([_interpolate(_named("count")), " hashtag aggiunti"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo nome"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun hashtag creato"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rinomina hashtag \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separa gli hashtag mediante virgole o interruzioni di riga"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un hashtag"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nessun problema. Puoi farlo ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione collegata allo spazio di lavoro"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega connessione"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega una connessione a questo spazio di lavoro"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatta l’amministratore per apportare modifiche qui"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatta Zendesk QA per apportare modifiche qui"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna connessione nell’account"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che tu non abbia ancora effettuato l’integrazione con una piattaforma del servizio clienti."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’eliminazione di una connessione rimuoverà le conversazioni dallo spazio di lavoro ", _interpolate(_named("workspaceName")), " ed eliminerà tutti i dati a esse correlati, incluse revisioni e contestazioni."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina connessione"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elimina connessione ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una connessione"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nel periodo di tempo prescelto, alcune categorie di controllo qualità automatico attive non erano presenti nella scheda punteggi. Le conversazioni precedenti all’ultima modifica del ", _interpolate(_named("date")), " potrebbero non essere state analizzate per queste categorie."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui verranno visualizzati tutti i feedback forniti a te. Ancora nessun contenuto da visualizzare."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna revisione ricevuta."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra solo attività commenti"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui verranno visualizzati tutti i feedback forniti da te. Inizia nella vista Conversazioni."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna revisione fornita."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni fornite"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisioni ricevute"])}
  }
}