import { scaleOrdinal } from 'd3-scale'

const defaultColorGroup = scaleOrdinal(['purple', 'pink', 'yellow', 'blue', 'teal', 'orange', 'green'])
const defaultColorShades = {}

export default ({ category, value }, colorMap) => {
  if (!colorMap[category]) {
    if (!defaultColorShades[category]) {
      defaultColorShades[category] = scaleOrdinal(['70', '60', '50', '40', '30', '20', '10'])
    }

    return `var(--${defaultColorGroup(category)}-${defaultColorShades[category](value)})`
  }

  if (colorMap[category].forcedSliceColors?.[value]) return `var(--${colorMap[category].forcedSliceColors[value]})`

  return `var(--${colorMap[category].color}-${colorMap[category].scale(value)})`
}
