import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_up_icon = _resolveComponent("arrow-up-icon")!
  const _component_arrow_down_icon = _resolveComponent("arrow-down-icon")!
  const _component_copy_icon = _resolveComponent("copy-icon")!
  const _component_trash2_icon = _resolveComponent("trash2-icon")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(_ctx.$style.footer)
  }, [
    (!_ctx.singleQuestion)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.buttonGroup)
        }, [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            "data-testid": "move-question-up",
            class: _normalizeClass(_ctx.$style.footerButton),
            type: "button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.handleQuestionAction('moveup'), ["prevent"]))
          }, [
            _createVNode(_component_arrow_up_icon, {
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8 /* PROPS */, ["class"])
          ], 2 /* CLASS */)), [
            [_directive_tippy, { content: _ctx.$t('quizzes.form.move_question_up') }]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(_ctx.$style.footerButton),
            type: "button",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.handleQuestionAction('movedown'), ["prevent"]))
          }, [
            _createVNode(_component_arrow_down_icon, {
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8 /* PROPS */, ["class"])
          ], 2 /* CLASS */)), [
            [_directive_tippy, { content: _ctx.$t('quizzes.form.move_question_down') }]
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.buttonGroup, 'ml-auto'])
    }, [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(_ctx.$style.footerButton),
        type: "button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => _ctx.handleQuestionAction('clone'), ["prevent"]))
      }, [
        _createVNode(_component_copy_icon, {
          class: _normalizeClass(_ctx.$style.icon)
        }, null, 8 /* PROPS */, ["class"])
      ], 2 /* CLASS */)), [
        [_directive_tippy, { content: _ctx.$t('quizzes.form.clone_question') }]
      ]),
      (!_ctx.singleQuestion)
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            "data-testid": "remove-question-btn",
            class: _normalizeClass(_ctx.$style.footerButton),
            type: "button",
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => _ctx.handleQuestionAction('remove'), ["prevent"]))
          }, [
            _createVNode(_component_trash2_icon, {
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8 /* PROPS */, ["class"])
          ], 2 /* CLASS */)), [
            [_directive_tippy, { content: _ctx.$t('quizzes.form.remove_question') }]
          ])
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}