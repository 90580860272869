import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'

export default createSharedComposable((defaultSortField: string) => {
  const sortData = ref({ field: defaultSortField, asc: false })

  function setSort(field: string) {
    if (field === sortData.value.field) {
      sortData.value.asc = !sortData.value.asc
    } else {
      sortData.value = {
        field,
        asc: field !== defaultSortField,
      }
    }
  }

  return { sortData, setSort }
})
