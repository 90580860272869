import { computed, ref } from 'vue'

const state = ref({ windowLocationSearch: '' })

function saveDashboardPinSearch() {
  state.value = { windowLocationSearch: window.location.search }
}

const dashboardPinSearch = computed(() => state.value)

export { saveDashboardPinSearch, dashboardPinSearch }
