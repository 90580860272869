import type { Snippet } from './types'

export default (content: string | undefined, snippetsObject: Record<string, Snippet>) => {
  let htmlContent = content || ''
  Object.keys(snippetsObject).forEach((key) => {
    htmlContent = htmlContent.replaceAll(`{{${key}}}`, snippetsObject[key].content)
  })

  return htmlContent
}
