import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_connect_helpdesk_form = _resolveComponent("connect-helpdesk-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _createVNode(_component_connect_helpdesk_form, {
      class: _normalizeClass(_ctx.$style.container),
      connection: _ctx.connection,
      onCancel: _ctx.cancel,
      onSubmit: _ctx.submit
    }, null, 8 /* PROPS */, ["class", "connection", "onCancel", "onSubmit"])
  ], 2 /* CLASS */))
}