import { ref, watch, type ComputedRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { capitalize, debounce } from 'lodash-es'
import { session } from '@/composables/useSession'
import { useViewSettings } from '@/composables/useViewSettings'
import { saveDashboardPinSearch } from '@/composables/useDashboardPinSearch'
import analytics from '@/utils/analytics'
import type { DashboardFilterParams } from '../types'
import { getDefaultParams } from '../utils/default-params'
import { deserializeFromStorage, serializeToStorage } from '../utils/persistence'

const getPersistedParams = (activeRouteName: string): any => {
  const deserializedParams = deserializeFromStorage(activeRouteName)

  if (deserializedParams.workspaceId) {
    deserializedParams.workspaceIds = [deserializedParams.workspaceId]
    delete deserializedParams.workspaceId
  }

  if (activeRouteName === 'dashboard.surveys') {
    const { viewSettings } = useViewSettings('csatDashboard')
    return {
      ...deserializedParams,
      csatOwnerSetting: viewSettings.value.csatOwnerSetting,
      displayDateSetting: viewSettings.value.displayDateSetting,
    }
  }

  return deserializedParams
}

export const useFilterParams = (computedCategoryId: ComputedRef<string>) => {
  const route = useRoute()
  const router = useRouter()
  const persistedParams = getPersistedParams(route.name.toString())

  const defaultWorkspaceId = { workspaceIds: [session.workspace.id] }
  const filterParams = ref<DashboardFilterParams>({
    ...getDefaultParams(route.name.toString(), defaultWorkspaceId),
    ...persistedParams,
    categoryId: computedCategoryId.value,
  })

  watch(
    () => route.name,
    (activeRouteName) => {
      filterParams.value = {
        ...getDefaultParams(activeRouteName.toString(), defaultWorkspaceId),
        ...getPersistedParams(activeRouteName.toString()),
        categoryId: computedCategoryId.value,
      }
    },
  )

  watch(
    filterParams,
    (params) => {
      const isLookerDashboard = route.name?.toString().endsWith('.looker') ?? false
      // looker dashboard handles its own params
      if (isLookerDashboard) return

      const defaults = getDefaultParams(route.name.toString())

      serializeToStorage(route.name.toString(), defaults, params)
      saveDashboardPinSearch()
    },
    { immediate: true },
  )

  watch(
    filterParams,
    debounce((params) => {
      analytics.dashboardFilterChanged(capitalize(route.name.toString()), params)
    }, 1000),
  )

  watch(
    computedCategoryId,
    (categoryId) => {
      filterParams.value = {
        ...filterParams.value,
        ...getPersistedParams(route.name.toString()),
        categoryId,
      }
    },
    { immediate: true },
  )

  watch(
    () => filterParams.value.categoryId,
    (newCategoryId) => {
      if (computedCategoryId.value !== newCategoryId) {
        const query = new URLSearchParams(location.search)
        const { workspaceIds } = filterParams.value

        router.push({
          name: route.name,
          params: { id: newCategoryId },
          query: {
            ...Object.fromEntries(query),
            ...(workspaceIds.length > 0 ? { workspaceIds: String(workspaceIds[0]) } : undefined),
          },
        })
      }
    },
  )

  return filterParams
}
