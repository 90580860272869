import { createSharedComposable } from '@vueuse/core'
import type { DashboardLayoutComponent } from '@looker/embed-sdk'
import { computed, ref } from 'vue'
import { camelCase } from 'lodash-es'

import useLookerDashboard from '@/modules/dashboard/looker/composables/useLookerDashboard'
import { session } from '@/composables/useSession'

// Looker code seems to assume that first row always has component with column: 0 value
// NB! First row doesn't mean row: 0. Can be row: 1, row: 2, etc. if all the other components have been hidden
// More context in https://github.com/klausapp/mono-ui/pull/5357
const fixColumnValues = (dashboardLayoutComponents: DashboardLayoutComponent[]) => {
  const minRow = Math.min(...dashboardLayoutComponents.map((c) => c.row))
  const minColumn = Math.min(...dashboardLayoutComponents.filter((c) => c.row === minRow).map((c) => c.column))

  return dashboardLayoutComponents.map((c) => {
    if (c.row === minRow) {
      return {
        ...c,
        column: c.column - minColumn,
      }
    }

    return c
  })
}

export default createSharedComposable(() => {
  const { lookerEmbedDashboard, dashboardEventDetail } = useLookerDashboard()

  const userSettingsKey = computed(() => {
    if (!dashboardEventDetail.value) return ''

    return camelCase(`lookerDashboard ${String(dashboardEventDetail.value?.id || '')}`)
  })

  const originalComponents = ref<DashboardLayoutComponent[]>()
  const setOriginalComponents = (components: DashboardLayoutComponent[]) => {
    originalComponents.value = JSON.parse(JSON.stringify(components))
  }

  const hideComponents = (ids: string[]) => {
    const newOptions = dashboardEventDetail.value.options

    newOptions.layouts[0].dashboard_layout_components = newOptions.layouts[0].dashboard_layout_components.filter(
      (c) => !ids.includes(c.id),
    )

    newOptions.layouts[0].dashboard_layout_components = fixColumnValues(
      newOptions.layouts[0].dashboard_layout_components,
    )

    lookerEmbedDashboard.value.setOptions(JSON.parse(JSON.stringify(newOptions)))
  }

  const showComponent = (id: string) => {
    const newOptions = dashboardEventDetail.value.options

    newOptions.layouts[0].dashboard_layout_components = originalComponents.value.filter(
      (c) =>
        c.id === id ||
        newOptions.layouts[0].dashboard_layout_components.some((currentComponent) => currentComponent.id === c.id),
    )

    newOptions.layouts[0].dashboard_layout_components = fixColumnValues(
      newOptions.layouts[0].dashboard_layout_components,
    )

    lookerEmbedDashboard.value.setOptions(JSON.parse(JSON.stringify(newOptions)))
  }

  const hidePreviouslyHiddenComponents = () => {
    hideComponents(
      Object.entries(session.user.settings[userSettingsKey.value]?.cardsVisible || {})
        .filter(([_id, visible]) => !visible)
        .map(([id]) => id),
    )
  }

  return {
    userSettingsKey,
    originalComponents,
    setOriginalComponents,
    hideComponents,
    showComponent,
    hidePreviouslyHiddenComponents,
  }
})
