import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["data-testid"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crosshair_icon = _resolveComponent("crosshair-icon")!
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_tippy = _resolveComponent("tippy")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    interactive: "",
    trigger: "click",
    theme: "light",
    onShow: _ctx.showTooltip,
    onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isActive = false))
  }, {
    trigger: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        class: _normalizeClass([{ '!bg-active-secondary !text-active-icon': _ctx.isActive || _ctx.belongsToSessionList.length }]),
        type: "button"
      }, [
        _createVNode(_component_crosshair_icon, { class: "icon-m" })
      ], 2 /* CLASS */)), [
        [_directive_tippy, { delay: _ctx.delay, content: _ctx.$t('conversations.ticket.calibration_tooltip') }]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        "data-testid": "calibration-sessions-list-select",
        class: _normalizeClass(_ctx.$style.sessionOptionsWrapper)
      }, [
        _withDirectives(_createVNode(_component_ui_spinner, null, null, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.loading]
        ]),
        (!_ctx.belongsToSessionList.length && !_ctx.canBeAddedToSessionList.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["text-sm", _ctx.$style.empty])
            }, _toDisplayString(_ctx.$t('conversations.ticket.calibration_no_sessions')), 3 /* TEXT, CLASS */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.belongsToSessionList.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-xs font-medium", _ctx.$style.listHeaderLabel])
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('conversations.ticket.calibration_in_session')), 1 /* TEXT */),
                      _createElementVNode("hr", {
                        class: _normalizeClass(_ctx.$style.separator)
                      }, null, 2 /* CLASS */)
                    ], 2 /* CLASS */),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.belongsToSessionList, (option) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `option-remove-from-${option.sessionId}`,
                        "data-testid": `calibration-session-remove-${option.sessionId}`,
                        class: _normalizeClass(["font-medium", _ctx.$style.sessionOption])
                      }, [
                        _createElementVNode("span", null, _toDisplayString(option.title), 1 /* TEXT */),
                        _createElementVNode("button", {
                          type: "button",
                          onClick: ($event: any) => (_ctx.handleRemoveConversationFromSession(option.sessionId, option.title))
                        }, [
                          _createVNode(_component_x_icon, { class: "icon-m" })
                        ], 8 /* PROPS */, _hoisted_3)
                      ], 10 /* CLASS, PROPS */, _hoisted_2))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.canBeAddedToSessionList.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-xs font-medium", _ctx.$style.listHeaderLabel])
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('conversations.ticket.calibration_add_to')), 1 /* TEXT */),
                      _createElementVNode("hr", {
                        class: _normalizeClass(_ctx.$style.separator)
                      }, null, 2 /* CLASS */)
                    ], 2 /* CLASS */),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.canBeAddedToSessionList, (option) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `option-add-to-${option.sessionId}`,
                        "data-testid": `calibration-session-add-${option.sessionId}`,
                        class: _normalizeClass(_ctx.$style.sessionOption)
                      }, [
                        _createElementVNode("button", {
                          type: "button",
                          onClick: ($event: any) => (_ctx.handleAddConversationToSession(option.sessionId))
                        }, [
                          _createElementVNode("span", null, _toDisplayString(option.title), 1 /* TEXT */)
                        ], 8 /* PROPS */, _hoisted_6)
                      ], 10 /* CLASS, PROPS */, _hoisted_5))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onShow"]))
}