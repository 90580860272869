import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-50" }
const _hoisted_2 = { class: "text-gray-80 ml-1 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tippy = _resolveComponent("tippy")!
  const _component_csat_donut = _resolveComponent("csat-donut")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_check_icon = _resolveComponent("check-icon")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_form_field = _resolveComponent("ui-form-field")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_data_card = _resolveComponent("data-card")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "csat-donut",
    title: _ctx.questionTypeLabel,
    params: _ctx.filterParamsWithoutScores,
    "api-method": _ctx.getOverview,
    "data-rows-prop": "csatValues"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_tippy, {
        theme: "light",
        interactive: "",
        placement: "bottom"
      }, {
        trigger: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.title)
          }, _toDisplayString(_ctx.questionTypeLabel), 3 /* TEXT, CLASS */)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.$t('csat_dashboard.donut.help', [_ctx.questionTypeLabel])), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    rawData: _withCtx(({ data }) => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.grid)
      }, [
        _createVNode(_component_csat_donut, {
          data: _ctx.sortData(data),
          target: _ctx.filterParams.target,
          "raw-csat-scores": _ctx.filterParams.rawCsatScores
        }, null, 8 /* PROPS */, ["data", "target", "raw-csat-scores"]),
        _createElementVNode("ul", {
          class: _normalizeClass(_ctx.$style.legend)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortData(data).csatValues, (csatValue) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${csatValue.label}-${csatValue.normalisedScore}-label-container`,
              style: _normalizeStyle({ opacity: _ctx.isDisabledScore(csatValue.label, _ctx.filterParams.rawCsatScores) ? 0.3 : 1 })
            }, [
              _createVNode(_component_tippy, {
                placement: "right",
                theme: "paddingless"
              }, {
                trigger: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["text-gray-90 text-sm font-medium", _ctx.$style.legendItem])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.dot),
                      style: _normalizeStyle({ backgroundColor: _ctx.colorGetter(csatValue) })
                    }, null, 6 /* CLASS, STYLE */),
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.$style.legendLabel)
                    }, _toDisplayString(_ctx.formatCsatScore(csatValue.label)), 3 /* TEXT, CLASS */),
                    (_openBlock(), _createElementBlock("span", {
                      key: `${csatValue.label}-${csatValue.normalisedScore}-csat`,
                      class: "text-gray-60 ml-auto"
                    }, _toDisplayString(_ctx.getPercentage(csatValue, data)) + "% ", 1 /* TEXT */))
                  ], 2 /* CLASS */)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["bg-black text-xs font-semibold text-white", _ctx.$style.tooltip])
                  }, [
                    _createTextVNode(_toDisplayString(csatValue.label) + " ", 1 /* TEXT */),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('csat_dashboard.n_of_total', { n: Number(csatValue.count), total: data.totalCount })), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_1, _toDisplayString(csatValue.value) + "% (" + _toDisplayString(csatValue.change === null
                      ? _ctx.$t('csat_dashboard.csat_value_na')
                      : csatValue.change > 0
                        ? `+${csatValue.change}%`
                        : `${csatValue.change}%`) + ") ", 1 /* TEXT */)
                  ], 2 /* CLASS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ], 4 /* STYLE */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */),
        _createVNode(_component_tippy, {
          ref: "targetTippyRef",
          trigger: "click",
          theme: "light",
          interactive: "",
          placement: "top"
        }, {
          trigger: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["text-xs font-semibold", [_ctx.$style.targetButton, _ctx.csatTarget ? 'text-gray-60' : 'content-link']])
            }, [
              (_ctx.csatTarget)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('csat_dashboard.donut.target')) + " ", 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.filterParams.target) + "%", 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('csat_dashboard.donut.set_target')) + " ", 1 /* TEXT */),
                    _createVNode(_component_chevron_right_icon, {
                      class: _normalizeClass([_ctx.$style.chevronIcon, "icon-m"])
                    }, null, 8 /* PROPS */, ["class"])
                  ], 64 /* STABLE_FRAGMENT */))
            ], 2 /* CLASS */)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_ui_form_field, {
              label: _ctx.$t('csat_dashboard.donut.target'),
              class: "p-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.targetField)
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.inputContainer)
                  }, [
                    _createVNode(_component_ui_input, {
                      type: "number",
                      "model-value": _ctx.csatTarget?.toString(),
                      class: _normalizeClass(_ctx.$style.targetInput),
                      min: "1",
                      max: "100",
                      small: "",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.csatTarget = Number($event)))
                    }, null, 8 /* PROPS */, ["model-value", "class"]),
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.$style.inputSuffix, "text-sm leading-6"])
                    }, "%", 2 /* CLASS */)
                  ], 2 /* CLASS */),
                  _createVNode(_component_ui_button, {
                    disabled: _ctx.disabled,
                    small: "",
                    onClick: _ctx.setTarget
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_check_icon, { class: "icon-m" })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["disabled", "onClick"])
                ], 2 /* CLASS */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["text-xs font-semibold", _ctx.$style.removeTargetButton]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeTarget && _ctx.removeTarget(...args)))
            }, [
              _createVNode(_component_x_icon, { class: "icon-m" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('csat_dashboard.donut.remove_target')), 1 /* TEXT */)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "params", "api-method"]))
}