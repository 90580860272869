import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5856ca4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph" }
const _hoisted_2 = {
  ref: "viewport",
  class: "viewport"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_tooltip = _resolveComponent("node-tooltip")!
  const _component_breadcrumb_trail = _resolveComponent("breadcrumb-trail")!
  const _component_node_info_displayer = _resolveComponent("node-info-displayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_node_tooltip, {
      "tooltip-location": _ctx.tooltipLocation,
      "color-getter": _ctx.colorGetter,
      hovered: _ctx.graphNodes.hovered
    }, null, 8 /* PROPS */, ["tooltip-location", "color-getter", "hovered"]),
    _createVNode(_component_breadcrumb_trail, {
      "color-getter": _ctx.colorGetter,
      "graph-nodes": _ctx.graphNodes,
      onZoomOut: _ctx.zoomOut
    }, null, 8 /* PROPS */, ["color-getter", "graph-nodes", "onZoomOut"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_node_info_displayer, {
        loading: _ctx.loading,
        error: _ctx.error,
        hovered: _ctx.graphNodes.hovered,
        zoomed: _ctx.graphNodes.zoomed,
        root: _ctx.graphNodes.root,
        description: _ctx.description,
        style: _normalizeStyle({ top: _ctx.height / 2 + 'px' }),
        onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload')))
      }, null, 8 /* PROPS */, ["loading", "error", "hovered", "zoomed", "root", "description", "style"])
    ], 512 /* NEED_PATCH */)
  ]))
}