import {
  getMessageElement,
  getDeadAirElement,
  getTicketResponsesElement,
  getTranscriptionHeaderElement,
} from './ticket-dom.util'

type Options = ScrollIntoViewOptions & { firstLoad?: boolean }

const AUDIO_HEADER_PREDICTED_HEIGHT = 250
const FADE_OVERLAY_HEIGHT = 56

export function scrollInteractionIntoView(
  {
    messageId,
    transcriptionMessageId,
    transcriptionId,
    scrollToDeadAir = false,
  }: {
    messageId?: string
    transcriptionMessageId?: string
    transcriptionId?: string
    scrollToDeadAir?: boolean
  },
  options?: Options,
) {
  const withDefaultOptions: Options = { behavior: 'smooth', block: 'start', firstLoad: false, ...options }

  if (transcriptionId && transcriptionMessageId) {
    scrollTranscriptionMessageIntoView(
      { transcriptionId, transcriptionMessageId, scrollToDeadAir },
      { firstLoad: withDefaultOptions.firstLoad },
    )
    return
  }

  scrollMessageIntoView(messageId, withDefaultOptions)
}

function scrollMessageIntoView(id, options: Options) {
  // block: 'start' causes the whole page to shift up on Firefox
  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
  const block = isFirefox ? 'end' : options.block || 'start'
  getMessageElement(id)?.scrollIntoView({ ...options, block })
}

function scrollTranscriptionMessageIntoView(
  {
    transcriptionMessageId,
    transcriptionId,
    scrollToDeadAir,
  }: {
    transcriptionMessageId: string
    transcriptionId: string
    scrollToDeadAir: boolean
  },
  { firstLoad }: { firstLoad: boolean },
) {
  const top = getTranscriptionMessageScrollTop(
    { transcriptionMessageId, transcriptionId, scrollToDeadAir },
    { firstLoad },
  )

  getTicketResponsesElement()?.scrollTo({ top, behavior: 'smooth' })
}

function getTranscriptionMessageScrollTop(
  {
    transcriptionMessageId,
    transcriptionId,
    scrollToDeadAir,
  }: {
    transcriptionMessageId: string
    transcriptionId: string
    scrollToDeadAir: boolean
  },
  { firstLoad }: { firstLoad: boolean },
) {
  const ticketResponsesElement = getTicketResponsesElement()
  const transcriptionMessageElement = scrollToDeadAir
    ? getDeadAirElement(transcriptionMessageId)
    : getMessageElement(transcriptionMessageId)
  const transcriptionHeaderElement = getTranscriptionHeaderElement(transcriptionId)

  const ticketResponsesDistanceFromDoc = ticketResponsesElement?.getBoundingClientRect().top
  const messageDistanceFromContainer =
    ticketResponsesElement?.scrollTop +
    transcriptionMessageElement?.getBoundingClientRect().top -
    ticketResponsesDistanceFromDoc

  const stickyHeaderHeight = firstLoad ? AUDIO_HEADER_PREDICTED_HEIGHT : transcriptionHeaderElement?.offsetHeight
  const top = messageDistanceFromContainer - stickyHeaderHeight - FADE_OVERLAY_HEIGHT

  return top
}
