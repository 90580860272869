import i18n from '@/i18n'

import type { Auth0Error } from '../api'

export const getErrorMessage = (err: Auth0Error) => {
  const errorMessages: Record<string, string> = {
    access_denied: i18n.t('auth.errors.bad_credentials_v2'),
    too_many_attempts: i18n.t('auth.errors.max_attempts'),
    unauthorized: i18n.t('auth.errors.unverified_email'),
    invalid_signup: i18n.t('auth.errors.invalid_signup'),
  }

  return errorMessages[err.code || ''] || `${err.code}: ${err.errorDescription || err.description || err.original}`
}
