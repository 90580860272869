import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    interactive: "",
    trigger: "click",
    theme: "light actions-dropdown",
    class: _normalizeClass(_ctx.$style.container)
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("button", {
        class: _normalizeClass(["text-sm font-medium", [_ctx.$style.selectedOption, _ctx.open && _ctx.$style.open]]),
        type: "button"
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.modelValue?.label), 1 /* TEXT */),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.roundIcon)
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.open ? 'chevron-up-icon' : 'chevron-down-icon')))
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          key: `option-${option.value}`,
          type: "button",
          onClick: ($event: any) => (_ctx.handleSelectOption(option))
        }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}