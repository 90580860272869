import { useQuery } from '@tanstack/vue-query'
import { createSharedComposable } from '@vueuse/core'
import { getAccountSubscription } from '@/modules/settings/api'

export default createSharedComposable(() => {
  const planQuery = useQuery({
    queryKey: ['subscription-plan'],
    queryFn: getAccountSubscription,
    refetchOnMount: false,
  })

  const refetch = () => {
    planQuery.refetch()
  }

  return { planQuery, refetch }
})
