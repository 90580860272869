import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import useSurvey from '../../composables/survey/useSurvey'

export default createSharedComposable(() => {
  const { state, savedSurveyConfig } = useSurvey()

  const baseLanguage = computed(() => state.data.translations.find((t) => t.default)?.values)
  const showReasons = computed(() => savedSurveyConfig.value?.showReasons)
  const reasonKeys = computed(() => Object.keys(baseLanguage.value).filter((k) => k.startsWith('reason')))

  return { baseLanguage, showReasons, reasonKeys }
})
