import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment_cell = _resolveComponent("comment-cell")!
  const _component_data_grid_column = _resolveComponent("data-grid-column")!
  const _component_review_link = _resolveComponent("review-link")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_data_grid_section = _resolveComponent("data-grid-section")!
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_data_card = _resolveComponent("data-card")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_data_card, {
    id: "dashboard-other-reasons-for-category-card",
    title: _ctx.$t('dashboard.card_labels.other_reasons'),
    class: "m-6",
    params: _ctx.filterParams,
    "api-method": _ctx.getOtherCategoryReasons,
    "csv-api-method": _ctx.getOtherCategoryReasonsCsv
  }, {
    default: _withCtx(({ data, sortBy }) => [
      _createVNode(_component_data_grid, {
        "sort-by": sortBy,
        data: data
      }, {
        default: _withCtx(() => [
          _createVNode(_component_data_grid_section, {
            class: _normalizeClass(_ctx.$style.section),
            style: {"--label-horizontal-padding":"12px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_data_grid_column, {
                id: "cause",
                "identity-column": "",
                label: _ctx.$t('dashboard.reason', 1),
                align: "left",
                style: {"margin-right":"8px"}
              }, {
                default: _withCtx(({ value }) => [
                  _createVNode(_component_comment_cell, {
                    comment: value,
                    class: _normalizeClass(_ctx.$style.reason)
                  }, null, 8 /* PROPS */, ["comment", "class"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_data_grid_column, {
                id: "reviewId",
                width: "260px",
                class: _normalizeClass(_ctx.$style.details),
                label: _ctx.$t('dashboard.review_id'),
                align: "left"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_review_link, {
                    "ticket-id": row.conversationExternalId,
                    "message-id": row.messageExternalId,
                    "connection-id": row.connectionId,
                    "source-type": row.sourceType,
                    "workspace-id": _ctx.filterParams.workspaceIds[0],
                    "search-mode": ""
                  }, null, 8 /* PROPS */, ["ticket-id", "message-id", "connection-id", "source-type", "workspace-id"]),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.reviewers)
                  }, [
                    (row.reviewer)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_avatar, {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.activeParty),
                          size: 24,
                          src: row.reviewer.avatar,
                          username: row.reviewer.name
                        }, null, 8 /* PROPS */, ["class", "src", "username"])), [
                          [_directive_tippy, { content: row.reviewer.name }]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(_ctx.$style.unknownParty)
                        }, [
                          _createTextVNode("?")
                        ], 2 /* CLASS */)), [
                          [_directive_tippy, { content: _ctx.$t('dashboard.former_member') }]
                        ]),
                    _createVNode(_component_chevron_right_icon),
                    (row.reviewee)
                      ? _withDirectives((_openBlock(), _createBlock(_component_q_avatar, {
                          key: 2,
                          size: 24,
                          class: _normalizeClass(_ctx.$style.passiveParty),
                          src: row.reviewee.avatar,
                          username: row.reviewee.name
                        }, null, 8 /* PROPS */, ["class", "src", "username"])), [
                          [_directive_tippy, { content: row.reviewee.name }]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("div", {
                          key: 3,
                          class: _normalizeClass(_ctx.$style.unknownParty)
                        }, [
                          _createTextVNode("?")
                        ], 2 /* CLASS */)), [
                          [_directive_tippy, { content: _ctx.$t('dashboard.former_member') }]
                        ])
                  ], 2 /* CLASS */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["class", "label"]),
              _createVNode(_component_data_grid_column, {
                id: "created",
                label: _ctx.$t('dashboard.date'),
                class: _normalizeClass(_ctx.$style.date)
              }, {
                default: _withCtx(({ value }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style.cell, _ctx.$style.center])
                  }, _toDisplayString(_ctx.formatDate(value)), 3 /* TEXT, CLASS */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label", "class"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["sort-by", "data"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "params", "api-method", "csv-api-method"]))
}