export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan subdomain Zendesk Anda"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk ke Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perusahaan Anda sudah memiliki akun."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabung dengan perusahaan"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk bergabung dengan perusahaan yang sudah ada, mintalah admin Anda untuk mengirimi Anda undangan."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minta admin Anda untuk mengundang Anda"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya berubah pikiran."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan pendaftaran"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perusahaan Anda sudah memiliki akun. Apakah Anda ingin bergabung dengan akun perusahaan Anda atau membuat yang baru?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin instruksi"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat akun baru"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada akun yang sudah ada"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak dapat mengakses akun Zendesk QA hingga faktur langganan Anda yang terutang dilunasi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan Anda ditangguhkan"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun tidak ditemukan. Coba lagi."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dapatkan kata sandi baru."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Coba lagi atau ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinasi email dan kata sandi yang salah."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minta yang baru dari admin Anda."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan undangan ini tidak valid atau sudah pernah digunakan."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), " metode masuk dinonaktifkan di akun Anda."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undangan ini tidak valid atau sudah digunakan. Minta yang baru dari admin Anda."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal memuat info undangan."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendaftaran gagal. Pengguna dengan email ini mungkin sudah ada."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun Anda telah diblokir setelah beberapa kali upaya masuk berturut-turut. Periksa inbox Anda untuk email berisi petunjuk tentang cara membuka blokirnya."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi email Anda dulu"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan akun email yang valid"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petak ini wajib diisi"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petak ini wajib diisi"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setel ulang"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode masuk"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim tautan ajaib"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kantor"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kantor Anda"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Perjanjian Layanan Utama)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi tidak sesuai dengan persyaratan"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan Privasi"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunggu"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Halo, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bergabunglah dengan organisasi ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Anda telah diverifikasi. Sekarang Anda dapat masuk dengan akun baru Anda di bawah ini."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan dengan Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan undangan tidak valid"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk menggunakan tautan ajaib"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali untuk masuk"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan dengan Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan dengan Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Anda telah diverifikasi. Sekarang Anda dapat masuk dengan akun baru Anda di bawah ini:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk melalui tautan ajaib"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk ke Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun Anda telah dihapus"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak memiliki akun?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kode yang valid"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petak ini wajib diisi"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan akun email yang valid"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petak ini wajib diisi"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode masuk"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua opsi masuk"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " melalui metode tradisional"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk menggunakan tautan ajaib"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah menerima email yang berisi kode masuk Anda."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah menerima email yang dapat Anda ikuti untuk masuk."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan saat mengirim tautan ajaib. Coba lagi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk melalui email"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kata sandi"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu karakter huruf kecil"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu karakter huruf besar"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi baru harus berisi"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang kata sandi maksimum adalah 128 karakter"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudah diingat, sulit ditebak"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 8 karakter"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kata sandi Anda"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa kata sandi?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu karakter huruf kecil"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu nomor"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu karakter huruf besar"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengatur ulang kata sandi Anda, masukkan alamat email Anda dan kami akan mengirimi Anda email berisi petunjuk."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan akun email yang valid"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petak ini wajib diisi"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setel ulang kata sandi"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email telah dikirim ke ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika email tidak muncul di kotak masuk atau folder spam Anda, periksa email yang Anda masukkan."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali untuk masuk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periksa email Anda"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setel ulang kata sandi"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", lalu Anda akan dikirimi email setel ulang kata sandi."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika akun Anda memiliki kata sandi yang sudah ada"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jika itu ", _interpolate(_named("bold")), ", maka kami telah mengirimi Anda tautan setel ulang kata sandi."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email dikaitkan dengan akun"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingat kata sandi Anda?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan pengiriman tautan setel ulang. Coba lagi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setel ulang kata sandi Anda"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar menggunakan tautan ajaib"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebijakan Privasi"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dengan mendaftar, Anda menyetujui Zendesk QA ", _interpolate(_named("terms_of_service")), " dan ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syarat Layanan"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punya akun?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar melalui tautan ajaib"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar dengan email kantor Anda"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar dengan Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mendaftar ke Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar dengan Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya memiliki akun Zendesk yang aktif"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daftar atau masuk untuk menyelesaikan penyiapan ", _interpolate(_named("direct_install_name")), " Anda"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benarkah itu kamu? Periksa inbox Anda untuk email konfirmasi."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda sudah siap"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA bekerja paling baik di desktop. Masuk ke sana untuk mulai meninjau percakapan."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan demo"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atau, pesan demo bersama kami dan kami akan menunjukkan kepada Anda apa yang dapat Anda lakukan di Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran Anda diminta"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["batal"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saya berubah pikiran dan ingin ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " mengundang Anda untuk bergabung dengan akunnya."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabung dengan akun"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat akun baru"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba masuk lagi"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reaksi yang Anda terima atas umpan balik Anda: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaksi yang diterima"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " bereaksi terhadap komentar Anda"]), _normalize(["Beberapa pengguna bereaksi terhadap komentar Anda"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " bereaksi terhadap balasan Anda"]), _normalize(["Beberapa pengguna bereaksi terhadap balasan Anda"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " bereaksi terhadap tinjauan Anda"]), _normalize(["Beberapa pengguna bereaksi terhadap tinjauan Anda"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["balas"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERDEBATAN"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIBERIKAN"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DITERIMA"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik survei"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaksi"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tandai komentar sebagai sudah dibaca?"]), _normalize(["Tandai semua komentar sebagai sudah dibaca?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tandai perdebatan sebagai telah dibaca?"]), _normalize(["Tandai semua perdebatan sebagai telah dibaca?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tandai umpan balik survei sebagai telah dibaca?"]), _normalize(["Tandai semua umpan balik survei sebagai telah dibaca?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tandai tinjauan sebagai telah dibaca?"]), _normalize(["Tandai semua tinjauan sebagai sudah dibaca?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Menandai komentar sebagai telah dibaca"]), _normalize(["Menandai semua komentar sebagai telah dibaca"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Menandai perdebatan sebagai telah dibaca"]), _normalize(["Menandai semua perdebatan sebagai telah dibaca"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Menandai umpan balik survei sebagai telah dibaca"]), _normalize(["Menandai semua umpan balik survei sebagai telah dibaca"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tinjauan ditandai sebagai telah dibaca"]), _normalize(["Menandai semua tinjauan sebagai telah dibaca"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai semua sebagai sudah dibaca"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai sudah dibaca"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika ada yang menyengketakan suatu tinjauan, tinjauan tersebut akan muncul di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketika Anda ditugaskan untuk menyelesaikan perdebatan, perdebatan tersebut akan muncul di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan yang diterima"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda menyengketakan suatu tinjauan, tinjauan tersebut akan muncul di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perselisihan yang dimulai"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua umpan balik yang diberikan kepada Anda muncul di sini. Belum ada yang bisa ditampilkan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada umpan balik yang diterima"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua reaksi yang diberikan terhadap tinjauan dan balasan Anda muncul di sini. Belum ada yang bisa ditampilkan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada reaksi yang diterima"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka percakapan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua umpan balik yang Anda berikan berakhir di sini. Mulailah dengan meninggalkan tinjauan di tampilan Percakapan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan yang diberikan"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua umpan balik yang diberikan kepada Anda muncul di sini. Belum ada yang bisa ditampilkan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan yang diterima"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada komentar yang ditemukan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua umpan balik yang Anda tinggalkan sebagai komentar muncul di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada komentar yang diberikan"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada komentar yang ditemukan"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua komentar pada tinjauan yang Anda miliki, ", "@", "sebutan, atau balasan terhadap komentar Anda muncul di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada komentar yang diterima"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan yang diterima"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan terbuka"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan yang ditolak"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perdebatan yang terselesaikan"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba lagi dengan memilih opsi lain dari filter di atas."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba lagi dengan memilih pengguna lain dari filter di atas."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diberikan komentar"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar yang diterima"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua perdebatan"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima perdebatan"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memulai perdebatan"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima umpan balik survei"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diberikan"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar aktivitas"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ditinjau"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ditinjau"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ditinjau"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mulai ulang dalam ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kiri"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengatur penugasan"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar tugas"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sesi pemanduan antara ", _interpolate(_list(0)), " dan ", _interpolate(_list(1)), " tidak dapat dibuat karena keduanya saat ini tidak berada di ruang kerja yang sama."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sesi pemanduan antara ", _interpolate(_list(0)), " dan ", _interpolate(_list(1)), " sudah ada. Hubungi ", _interpolate(_list(0)), " untuk mendapatkan akses ke sesi ini."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disarankan"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sesi baru antara ", _interpolate(_list(0)), " dan ", _interpolate(_list(1)), " telah dibuat."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemandu"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pemandu"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang dipandu"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih yang dipandu"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perincian"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah sesi"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama sesi"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disarankan"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melewati tenggat"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebelumnya"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akan datang"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi yang ditemukan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu tinjauan rata-rata"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data yang ditampilkan untuk periode yang dipilih"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menambahkan pin ke sesi. ", _interpolate(_named("buttonStart")), "Tunjukkan kepada saya", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah ke..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditambahkan"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai akhir sematan dari periode yang dipilih"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lihat cara kerja sematan di ", _interpolate(_named("link")), " ini."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikel"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sepertinya Anda belum menyematkan apa pun pada ", _interpolate(_list(0)), ". Anda dapat menggunakan sematan untuk mempersiapkan 1:1 atau menyimpan sesuatu untuk lain waktu."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yang dipandu"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada sematan yang ditambahkan"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Berikut semua sematan yang Anda buat pada ", _interpolate(_named("name")), ". Sematan yang ditambahkan ke sesi akan menjadi ", _interpolate(_named("bold")), " dengan peserta lain."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segera dibagikan"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sepertinya ", _interpolate(_list(0)), " belum menerima umpan balik apa pun untuk tinjauan percakapan atau CSAT selama periode yang dipilih."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada umpan balik yang tersedia"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai akhir komentar dari periode yang dipilih"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrik"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " nomor"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item tindakan baru"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diselesaikan oleh ", _interpolate(_named("user")), " pada ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["oleh ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik untuk menambahkan item tindakan baru"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa hasil dari pertemuan ini? Apa langkah Anda selanjutnya?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item tindakan"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dibuat oleh ", _interpolate(_named("name")), " pada ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus sesi tersebut"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ini akan menjadi ", _interpolate(_named("deleteSession")), " secara permanen. Peserta kehilangan akses ke sesi tersebut, termasuk konten dan catatan pribadinya."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sesi dibuat pada ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terakhir diedit oleh ", _interpolate(_named("name")), " pada ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poin pembicaraan dan catatan"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan diperbarui"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar dari sesi"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagikan"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna terpilih mendapatkan akses ke detail sesi mulai saat ini dan seterusnya."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Pengguna"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersama"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi berbagi"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anda tidak lagi menjadi bagian dari sesi ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tidak lagi menjadi bagian dari sesi ini."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " sekarang memiliki akses ke sesi ini."]), _normalize([_interpolate(_named("n")), " pengguna baru sekarang memiliki akses ke sesi ini"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus sesi"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus semua sesi"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus sesi"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pemandu"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Catatan yang ditambahkan oleh ", _interpolate(_list(0)), " akan muncul di sini."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah item yang disematkan sebagai poin diskusi dari panel sebelah kanan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya catatan yang dapat Anda lihat..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan personal"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan pribadi diperbarui"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hari"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemandu"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang dipandu"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item tindakan"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi terakhir"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang berlangsung"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melewati tenggat"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar sesi"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan semua"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya: Pemandu"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya: Yang dipandu"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sesi"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi bersama"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba ganti filter untuk melihat item tindakan."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada item tindakan"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item tindakan"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan diterima dan dilihat"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima - Dilihat"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progres setelah sesi"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampaknya agen belum menerima umpan balik apa pun."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi bahwa Anda memiliki nama pengguna yang benar"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menemukan pengguna tersebut"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tidak tersedia"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS 30 hari terakhir"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tindakan ini akan <b>menghapus semua sesi</b> (2) secara permanen dengan <b>", _interpolate(_named("name")), "</b>. Peserta kehilangan akses ke semua sesi, termasuk konten dan catatan pribadi."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai akhir semua sesi"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi pemanduan adalah kegiatan grup antara mentor dan orang yang dipandu untuk mendiskusikan kinerja, peluang, sasaran jangka pendek dan jangka panjang."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mencari sesi berdasarkan nama pemandu dan orang yang dipandu."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi yang ditemukan"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada sesi yang dibuat"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus riwayat pelatihan"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus riwayat pelatihan "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi terakhir"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sesi"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemandu"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang dipandu"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai sudah selesai"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai dibatalkan"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan nama"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah nama"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar sesi"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak terjadwal"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balas pesan ini"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi kalibrasi telah berakhir. Tidak ada tinjauan tambahan yang tersisa."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah meninggalkan tinjauan untuk percakapan ini."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meninjau percakapan ini tidak lagi dapat dilakukan karena batas waktu sesi telah berlalu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan ini tidak memiliki tinjauan"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup panel"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan tinjauan kalibrasi"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERINCIAN"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RINGKASAN"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSKRIP"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pembicara ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "d"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saat hening"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pembicara"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau pesan"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke pesan yang sedang diputar"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembicara"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkrip"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna ruang kerja"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah koneksi terlebih dahulu untuk membuat filter"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bot"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dibuat di ", _interpolate(_named("time")), " oleh ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terakhir diperbarui pada ", _interpolate(_named("time")), " oleh ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sepertinya Anda tidak memiliki filter. Untuk mengakses halaman ini, Anda perlu membuat filter terlebih dahulu. Mulailah dengan ", _interpolate(_named("recommendedFilter")), " untuk melihat percakapan Anda sendiri. Buat filter di bawah ini untuk melanjutkan."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selama 30 hari terakhir"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi \"Dibuat dalam 30 hari terakhir\" akan ditambahkan ke filter yang dibuat tanpa rentang tanggal"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter tidak tersedia dalam paket ini dan tidak akan diperhitungkan saat menampilkan hasil"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu pengambilan opsi filter telah habis. Coba lagi."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang pemfilteran"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas = orang yang masuk"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Percakapan ditemukan: ", _interpolate(_named("count"))]), _normalize(["Percakapan ditemukan: ", _interpolate(_named("count"))]), _normalize(["Percakapan ditemukan: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak tersedia dalam paket Anda saat ini. Upgrade versi untuk menggunakan fitur ini."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan kondisi"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oleh Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berubah menjadi tugas"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat filter"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat filter"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang kustom"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami memuat opsi filter sesuai dengan pengaturan spesifik Anda. Ini mungkin memakan waktu beberapa menit pada pemuatan awal."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan nama deskriptif untuk filter Anda"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama filter"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih opsi"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publik"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit filter"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan perubahan"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada nilai yang tersedia"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih nilai"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilitas"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya sesi aktif ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan sesi yang lebih lama ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas waktu"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit sesi kalibrasi"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi kalibrasi"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tinjauan kalibrasi yang diberikan selama sesi akan dihapus secara permanen."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus sesi"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ", _interpolate(_named("sessionName")), " sesi kalibrasi"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi yang dibuat"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi aktif"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi yang dibuat"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi aktif"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah filter"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membuat filter pribadi"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membuat filter publik"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter ditambahkan"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter akan dihapus secara permanen."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus filter"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ", _interpolate(_named("filterName")), " filter"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua perdebatan"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan untuk saya"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan oleh saya"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan terbuka"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada filter yang ditambahkan"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada filter pribadi yang dibuat"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada filter publik yang dibuat"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter pribadi"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter publik"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ingin ke ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini adalah tampilan filter sementara."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membangun filter Anda..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter diperbarui"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori AutoQA khusus"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terakhir diperbarui ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata-kata yang kompleks"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan otomatis diperbarui secara dinamis berdasarkan perubahan tiket"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorot dalam percakapan"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalimat panjang"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada prediksi skor otomatis"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi kesalahan apa pun"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunjukkan kata-kata yang kompleks"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan kalimat panjang"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan berikutnya"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA tidak dapat menilai kategori ini karena konteks yang tidak memadai. Dianjurkan untuk melakukan tinjauan manual."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan wawasan percakapan otomatis untuk menyederhanakan proses tinjauan Anda. Menyoroti percakapan penting dan menampilkan contoh positif yang menonjol untuk tim Anda."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Panggil]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikoreksi"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dihapus"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " menerima perdebatan tersebut"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perdebatan dikirim ke ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan mengapa menurut Anda orang yang ditinjau ini adalah penerima umpan balik yang salah"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah komentar dan tetapkan yang ditinjau baru"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah komentar"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan mengapa Anda yakin skor ini dinilai secara tidak adil (wajib)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan komentar (wajib)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan mengapa Anda menolak perdebatan ini"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah komentar"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim perdebatan ke"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim perdebatan ke"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim perdebatan ke"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengulas baru"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik sengketa"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik sengketa"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah yang ditinjau"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim perdebatan ke"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singketakan tinjauan ini"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya tidak setuju dengan skornya"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan ini tidak dimaksudkan untuk saya"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " menerima sebagian perdebatan"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " menolak perdebatan tersebut"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima sebagian"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " memulai perdebatan: tinjauan"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " memulai perdebatan: yang ditinjau salah"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan terbuka harus diselesaikan untuk mengambil tindakan lain dalam peninjauan tersebut."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih skor baru"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus perdebatan"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin ingin membuang perdebatan ini?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus perdebatan"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu penyelesaian sengketa"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolak"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih skor wajar baru (opsional)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sengketa"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau yang salah"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima perdebatan"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima perdebatan"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik sengketa"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau yang salah"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik sengketa"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menolak perdebatan"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menolak perdebatan"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melihat perdebatan"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diedit"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " oleh ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["menjadi ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua selesai"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan ke:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan komentar juga"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang kartu skor bersyarat"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebelumnya ditugaskan ke:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih saja satu"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menemukan GIF dengan nama tersebut"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadilah yang pertama memberikan tinjauan."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan Anda saat ini sedang dijeda. Aktifkan kembali untuk memungkinkan pemberian umpan balik."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diposting oleh:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini menunjukkan berapa banyak waktu yang dihabiskan untuk meninjau percakapan ini"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Akar masalah untuk kategori tidak ada: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri nilai kategori atau tambahkan komentar"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciutkan"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perluas bidang komentar"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perkecil bidang komentar"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan pemformatan"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan panel"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan pemformatan"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan panel"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirimkan tinjauan"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri nilai semua kategori"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri nilai setidaknya satu kategori"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan percakapan ini untuk nanti"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan untuk nanti"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas berikutnya"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti percakapan dalam siklus penugasan ini"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau percakapan"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dasar"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus komentar"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar dihapus"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus komentar ini?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penulis komentar"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag ini telah dihapus atau diganti namanya."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagikan apa yang Anda sukai atau apa yang bisa lebih baik..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersyarat"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan menghapus semua komentar secara permanen."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus komentar"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Pesan]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " belum melihat umpan balik ini"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " telah melihat umpan balik ini"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantan anggota"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekan Enter untuk membuat tagar baru."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar khusus dikunci oleh admin."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan ini untuk menandai kasus dan memfilternya nanti"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai dasar"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik pesan"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan berikutnya"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada umpan balik"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan yang dilakukan"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan tinjauan di bawah ini untuk memulai."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan lain yang tersisa"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dinilai"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas sebelumnya"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan sebelumnya"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus sebagai dasar"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balas"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balas tinjauan ini"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan bilah samping tinjauan"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan menghapus tinjauan dan semua komentar yang ditambahkan secara permanen."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus tinjauan"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus tinjauan"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dihapus"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tinjauan dihapus oleh ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua selesai!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan kembali tugas ini untuk diri Anda sendiri agar dapat memberikan tinjauan"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah meninggalkan tinjauan untuk pengguna ini."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anda telah memberikan tinjauan untuk ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori yang ditampilkan untuk percakapan ini."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri tidak tersedia untuk ruang kerja ini."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan simpan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan tinjauan ini sebagai draf?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buang draf ini"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buang"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan bilah samping tinjauan"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulus"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulus dengan anak di bawah umur"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masih menghitung angka..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan komentar"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau pesan ini"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau diri Anda sendiri"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau percakapan ini"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penulis"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengguna untuk ditinjau"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri dinonaktifkan"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna tidak ditemukan"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna ruang kerja"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 tinjauan"]), _normalize([_interpolate(_named("count")), " tinjauan"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih penilaian baru"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajer ruang kerja Anda tidak mengizinkan tinjauan sendiri."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekan Enter untuk tag pin ini"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hari terakhir"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibintangi"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi kalibrasi"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan memberi Anda representasi visual dari data meja bantuan Anda. Gunakan diagram interaktif untuk memahami di mana harus memfokuskan upaya QA Anda, mengungkap titik buta, dan menemukan agen yang tepat untuk ditinjau berdasarkan kinerja mereka."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " ID"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terjemahkan ke ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah ke"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ditetapkan"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat ini melihat"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meninjau sekarang"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan ini telah dihapus dan tidak lagi tersedia."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang Anda cari ini tidak ada di Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sematan dengan percakapan ini"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalam sesi"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus percakapan dari suatu sesi akan menghapus semua tinjauan yang diberikan padanya."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus percakapan dari ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada sesi kalibrasi yang dibuat."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan ini telah ditambahkan ke sesi"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan ini telah dihapus dari sesi"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan ke/hapus dari sesi kalibrasi"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin tautan ke percakapan ini"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditampilkan"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merek"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Pelanggan"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Pelanggan"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pelanggan"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama formulir eksternal"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu respons pertama"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritas"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar survei"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kepuasan"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatnya untuk penyelesaian pertama"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asli"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan ini tidak ada dalam percakapan ini atau telah dihapus"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perihal"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditampilkan"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditemukan"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka percakapan asli"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menautkan ke percakapan - URL tidak ada"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada peserta"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan percakapan"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik untuk membaca tinjauan"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik untuk tinjauan"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan dibuat melalui ekstensi browser"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat survei kepuasan pelanggan"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat percakapan"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan dengan sentimen negatif"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan dengan sentimen positif"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjemahkan ke bahasa Inggris"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan yang asli"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menghapus percakapan bersifat permanen. Tindakan ini juga menghapus semua data terkait, misalnya survei, transkripsi, dan ulasan dari Zendesk QA, termasuk dasbor.", _interpolate(_named("lineBreak")), "Anda masih dapat mengakses percakapan ini di Zendesk Support atau perangkat lunak meja bantuan lain yang Anda gunakan."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menghapus percakapan bersifat permanen. Ini juga menghapus semua data terkait, misalnya survei, transkripsi, dan ulasan dari Zendesk QA, termasuk dasbor. Jika percakapan milik beberapa ruang kerja, percakapan akan dihapus dari semuanya.", _interpolate(_named("lineBreak")), "Anda masih dapat mengakses percakapan ini di Zendesk Support atau perangkat lunak meja bantuan lain yang Anda gunakan."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan dari Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Percakapan ini tidak lengkap karena adanya eskalasi. Buka ", _interpolate(_named("optionsUrl")), " dan validasi tag Anda. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Percakapan ini tidak lengkap karena adanya eskalasi. Bicaralah dengan administrator Anda tentang memvalidasi tag Anda. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi > Opsi lanjutan"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang tag"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditutup"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baru"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertunda"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Petugas diubah menjadi ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ditetapkan"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " makro diterapkan oleh ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Percakapan mendapat penilaian ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pengguna ", _interpolate(_named("target")), " menyunting percakapan tersebut"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Status diubah ke ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditandai dengan ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tim berubah menjadi ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " menunda percakapan selama ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " membatalkan penundaan percakapan"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot terjebak dalam loop, sehingga pesan berulang berturut-turut"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengulangan bot"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengulangan bot, yakni pesan yang sama berulang satu kali atau lebih berturut-turut"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frasa yang cocok ditemukan"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agen rata-rata menangani percakapan ", _interpolate(_named("percent")), " lebih efisien dibandingkan bot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efisiensi komunikasi bot"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bot menangani percakapan ", _interpolate(_named("percent")), " lebih efisien daripada agen rata-rata"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efisiensi komunikasi bot"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan tidak memiliki pernyataan yang diperlukan untuk menginformasikan peserta tentang rekaman, yang diperlukan untuk kepatuhan dan transparansi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekaman pengungkapan hilang"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Perjanjian tingkat layanan (SLA) telah dilanggar karena ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA dilanggar"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrik untuk aturan SLA melebihi waktu targetnya"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanda-tanda berkurangnya pelanggan. Pelanggan sedang mempertimbangkan peralihan atau berjanji untuk berpisah."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiko churn teridentifikasi"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Percakapan memiliki saat hening yang berlangsung lebih lama dari ambang batas yang ditetapkan yaitu ", _interpolate(_list(0)), " detik"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 contoh"]), _normalize([_interpolate(_named("count")), " contoh"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " terpanjang"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat hening"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan mencari bantuan tingkat yang lebih tinggi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalasi diminta"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan mengungkapkan rasa terima kasih yang sebesar-besarnya atau sangat puas dengan dukungan yang diterima"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan luar biasa"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baik pelanggan atau agen secara eksplisit meminta tindak lanjut"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan tindak lanjut"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan menunjukkan ketidakpuasan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen negatif"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan tidak biasa atau tidak lazim dan terjadi bolak-balik untuk mencapai penyelesaian"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencilan terdeteksi"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan menunjukkan kepuasan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen positif"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlaku"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan sebagai tidak berlaku"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terakhir diedit oleh ", _interpolate(_named("author")), " pada ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat diterapkan"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oleh ", _interpolate(_named("author")), " pada ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah alasan (opsional)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada wawasan yang terdeteksi"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah koneksi"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan meja bantuan ke akun Anda, lalu buat setidaknya satu filter untuk melihat daftar percakapan."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan untuk ditampilkan"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat pengaturan ruang kerja"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terakhir diperbarui"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Dalam sehari)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Bulan ini)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Minggu ini)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan berdasarkan ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hasil pencarian untuk ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau oleh orang lain"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikomentari"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbaru"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlama"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acak"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui baru-baru ini"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibalas baru-baru ini"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutkan menurut"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Dibaca"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau oleh Anda"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proses impor mungkin memerlukan waktu. Kami akan mengirimkan email kepada Anda jika sudah selesai."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA sedang mengimpor percakapan Anda"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Batas: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan dengan mengeklik ikon kalibrasi di header tampilan percakapan."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada yang perlu dilaporkan di sini. Coba lagi dengan menggunakan filter atau opsi penyortiran yang berbeda."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pencilan untuk ditampilkan."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan percakapan yang relevan dan klik ikon bintang di header untuk menandainya di sini."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba ubah filter atau undang agen meja bantuan lain untuk tambah lebih banyak percakapan."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada batasan"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan internal"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami sedang memuat percakapan sekarang. Beberapa konten mungkin masih hilang."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna berikutnya"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna tidak ditemukan"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota ruang kerja"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna sebelumnya"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditambahkan"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditambahkan"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang belum selesai berakhir di sini. Baca kembali, revisi dan kirimkan dalam 30 hari ke depan."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada rancangan tinjauan"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan berbintang"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditampilkan"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak cocok"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke pesan yang dipilih"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balasan paling sedikit"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balasan terbanyak"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar percakapan"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Panggilan dijawab pada ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Panggilan berakhir pada ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Panggilan dilakukan pada ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau oleh Anda"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gagal memuat file audio. Pastikan Anda masuk ke ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar survei kepuasan pelanggan"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan saat memuat audio"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penulis komentar"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada panggilan yang dilakukan"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengarkan di meja bantuan"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengarkan di Panggilan Udara"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecepatan pemutaran"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau panggilan"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkrip"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendah"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bukan Pengguna Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA secara otomatis memberi peringkat agen berdasarkan rata-rata CSAT bulanan mereka. Temukan percakapan yang ditangani oleh agen yang CSAT-nya lebih rendah dibandingkan rekan-rekan mereka dalam satu klik."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat berdasarkan CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendah"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terendah"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertinggi"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bukan Pengguna Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu tiket"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA secara otomatis memberi peringkat agen ke dalam grup kinerja berdasarkan berapa banyak percakapan yang mereka tangani per bulan. Temukan percakapan oleh agen dengan volume lebih rendah dibandingkan rekan mereka dalam satu klik."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat berdasarkan volume"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditinjau"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status tinjauan"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendah"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggi"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netral"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bukan Pengguna Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA secara otomatis memberi peringkat agen berdasarkan sentimen rata-rata dalam percakapan. Temukan interaksi di mana agen berurusan dengan lebih banyak pelanggan yang frustrasi daripada rekan-rekan mereka dalam satu klik."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat berdasarkan sentimen"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak terdeteksi"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terdeteksi"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencilan"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang membutuhkan perhatian Anda. Model machine learning kami mengidentifikasi interaksi yang kompleks atau tidak lazim untuk ditinjau lebih lanjut."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencilan"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih percakapan untuk ditinjau di mana pelanggan menunjukkan kegembiraan atau frustrasi. Gunakan menu tarik-turun untuk memilih jenis sentimen yang Anda cari dalam percakapan. Tarik atribut filter ke atas untuk melihat rasio sentimen yang dipilih di semua interaksi Anda."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status tinjauan"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditinjau"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA secara otomatis mendeteksi bahasa dalam percakapan Anda. Gunakan menu tarik-turun untuk memilih percakapan dalam bahasa tertentu untuk ditinjau. Tarik atribut filter ke atas untuk mendapatkan ikhtisar semua bahasa yang digunakan di meja bantuan Anda."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan Percakapan"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda dapat ", _interpolate(_named("paragraph_2_item")), " menggunakan Wawasan dibandingkan dengan filter percakapan biasa. Dapatkan ikhtisar visual tentang semua data dari meja bantuan Anda, dan jelajahi wawasan seperti:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang menggunakan wawasan percakapan"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memahami pentingnya atribut percakapan tertentu dalam kaitannya dengan orang lain"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan agen berkinerja terbaik dan terburuk dengan peringkat kinerja agen bawaan"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " adalah alat penambangan data interaktif canggih yang membantu Anda menemukan percakapan yang paling perlu ditinjau."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["melakukan lebih banyak lagi"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bagan Percakapan dan Agen adalah ", _interpolate(_named("paragraph_3_item_1")), " – memperbarui filter atau mengeklik bagian bagan tersegmentasi di salah satunya akan memperbarui daftar percakapan secara otomatis. ", _interpolate(_named("paragraph_3_item_2")), " untuk menjelajahi pengelompokan atau rasio mendetail dari berbagai elemen."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saling berhubungan"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susun ulang atribut filter"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wawasan Percakapan hanya menampilkan ", _interpolate(_named("paragraph_4_item")), ". Kami secara otomatis menghapus interaksi tanpa nilai peninjauan yang sebenarnya, seperti pesan yang dibuat secara otomatis, spam, balasan bot, atau pesan yang tidak memiliki dialog yang tepat dengan perwakilan dukungan Anda."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percakapan yang bersih dan tertutup"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa yang saya lihat di sini?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " dari ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " dari ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " dari ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " dari ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal mengambil"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " dari total)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muat ulang"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapi kami yakin ini akan menjadi luar biasa setelah tiba."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei khusus"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada umpan balik survei yang tersisa"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada skor umpan balik survei yang tersedia..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diterima"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag umpan balik"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag komentar umpan balik"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter rentang tanggal diterapkan"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " balasan"]), _normalize([_interpolate(_named("count")), " balasan"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gali lebih dalam alasan skor ", _interpolate(_list(0)), " diberikan dengan menyiapkan alasan dalam setelan survei."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Belum ada ", _interpolate(_list(0)), " alasan"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang alasan survei"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan dengan tag:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " oleh"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori komentar"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " alasan"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prediksi ", _interpolate(_named("surveyType")), " pengemudi"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber percakapan"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Respons survei (", _interpolate(_named("n")), ")"]), _normalize(["Respons survei (", _interpolate(_named("n")), ")"]), _normalize(["Respons survei (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada perihal"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Percakapan dengan ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " oleh pengguna"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat respons"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " skor adalah jumlah dari semua respons dibagi dengan jumlah skor maksimum"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit target"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus target"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan target"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah target"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendek"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangat panjang"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang sedang"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membingungkan"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak membantu"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan yang buruk"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan yang baik"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emosi berlimpah"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik untuk agen"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluhan"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remah-remah"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masalah tidak terpecahkan"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masalah terpecahkan"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen negatif"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil yang buruk"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen positif"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pujian"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk yang buruk"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengembalian Uang"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan cepat"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan lambat"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima kasih"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA secara otomatis mengalokasikan data ", _interpolate(_list(0)), " Anda ke dalam kategori yang telah ditentukan sebelumnya. Jadi Anda dapat memahami wawasan yang kompleks dan menelusuri penyebabnya tanpa harus memilah umpan balik tertulis secara manual."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua skor"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua survei"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih bahasa respons"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran komentar"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih ukuran komentar"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan 25% komentar hanya terdiri dari satu kata, komentar yang lebih panjang wajib dibaca. Temukan umpan balik yang lebih mendalam tanpa harus melakukan pekerjaan manual, karena kami melakukannya secara otomatis untuk Anda."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan umpan balik"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih alasan umpan balik"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada skor yang cocok"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi yang diprediksi"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kategori komentar"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata-kata Wordcloud"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kata-kata wordcloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sejak periode terakhir"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " percakapan"]), _normalize([_interpolate(_named("n")), " percakapan"]), _normalize([_interpolate(_named("n")), " percakapan"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menghitung rasio survei yang dikirim dari semua percakapan karena kurangnya data."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei dikirimkan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corong survei"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dari ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " dari ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " dari ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prediksi ", _interpolate(_named("surveyType")), " pengemudi"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA secara otomatis mengalokasikan data ", _interpolate(_list(0)), " Anda ke dalam kategori yang telah ditentukan sebelumnya. Jadi Anda dapat memahami wawasan yang kompleks dan menelusuri penyebabnya tanpa harus memilah umpan balik tertulis secara manual."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran komentar"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan 25% komentar hanya terdiri dari satu kata, komentar yang lebih panjang wajib dibaca. Temukan umpan balik yang lebih mendalam tanpa harus melakukan pekerjaan manual, karena kami melakukannya secara otomatis untuk Anda."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons survei"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (rata-rata)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah respons"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (rata-rata)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tren oleh"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah respons"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tanggal pengiriman survei"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tanggal balasan survei"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tetapkan skor ", _interpolate(_list(0)), " ke"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan data berdasarkan"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pembuatan percakapan"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pemilik tiket"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agen dengan balasan terbanyak"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (rata-rata)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dari waktu ke waktu"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah respons"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dan IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah respons"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang menyertakan kata-kata ini:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Cloud"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT oleh pengguna"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corong survei"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons survei"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT dari waktu ke waktu"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada saluran"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sumber"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bahasa"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada alasan"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tag"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori komentar"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data yang tersedia karena tidak ada tugas yang dibuat atau tidak ada siklus yang dimulai. Periksa kembali nanti atau sesuaikan filter."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelajahi apa yang baru"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " item"]), _normalize([_interpolate(_named("count")), " item"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " percakapan"]), _normalize([_interpolate(_named("count")), " percakapan"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulus"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulus dengan anak di bawah umur"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lulus/Gagal"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata (j)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata (mnt)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " penilaian"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata CSAT = [jumlah skor umpan balik (hitungan semua respons × skor maksimum yang mungkin)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor CSAT Zendesk QA menormalkan skor umpan balik Anda dalam skala 0-100% dan mengambil rata-rata skor standar."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Skor Kepuasan Pelanggan) mengukur seberapa senang pelanggan Anda dengan layanan Anda."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Setiap tinjauan mewakili tinjauan oleh satu orang dalam satu percakapan. Percakapan dapat memiliki lebih dari satu tinjauan (jika dua atau lebih peninjau tinjauan percakapan yang sama)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lacak evolusi skor peninjau dari waktu ke waktu."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lacak evolusi skor agen dari waktu ke waktu."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " respons"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tinjauan"]), _normalize([_interpolate(_named("n")), " tinjauan"]), _normalize([_interpolate(_named("n")), " tinjauan"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (Skor Kualitas Internal) adalah hasil tinjauan percakapan Anda."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = jumlah skor tinjauan jumlah tinjauan"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai ini dihitung sebagai rata-rata, dengan mempertimbangkan semua skor tinjauan (rata-rata tertimbang skor kategori) yang cocok dengan kondisi filter yang dipilih."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal memuat kartu."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah filter Anda untuk mencoba lagi."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat kelulusan menunjukkan hasil tinjauan percakapan Anda."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gagal jika skor tinjauan ", _interpolate(_named("failScore")), " atau lebih rendah"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lulus jika skor tinjauan ", _interpolate(_named("passScore")), " atau lebih tinggi"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hal ini ditentukan oleh garis dasar yang telah ditentukan sebelumnya, artinya Anda:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat kelulusan = Jumlah tinjauan yang lulus ÷ Jumlah total tinjauan"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat kelulusan menunjukkan persentase jumlah tinjauan yang lulus tanpa gagal dalam kategori kritis atau non-kritis."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["misalnya Jika peninjau memberikan 3 tinjauan pada hari ini, maka angka yang ditampilkan adalah rata-rata dari 3 skor tersebut. Lihat bagaimana setiap sel dihitung ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["misalnya Jika agen menerima 3 tinjauan pada hari ini, maka angka yang ditampilkan adalah rata-rata dari 3 skor tersebut. Lihat bagaimana setiap sel dihitung ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperhitungkan: bobot kategori, status kritis (tidak seperti Skor seiring waktu)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karena kategori dapat ditandai n/a dalam tinjauan, jumlah tinjauan per kategori mungkin lebih rendah dari total tinjauan oleh peninjau. Lihat bagaimana setiap sel dihitung"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperhitungkan: bobot kategori, status kritis (tidak seperti Skor seiring waktu)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karena kategori dapat ditandai n/a dalam tinjauan, jumlah tinjauan per kategori mungkin lebih rendah dari total tinjauan per agen. Lihat bagaimana setiap sel dihitung"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperhitungkan: bobot kategori, status kritis (tidak seperti Skor seiring waktu)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grup kategori ditampilkan secara terpisah. Skor setiap grup dihitung berdasarkan rata-rata skor kategori grup tersebut pada periode yang sama. Lihat bagaimana setiap sel dihitung ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catatan:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperhitungkan: bobot kategori, status kritis (tidak seperti Skor seiring waktu)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Grup kategori ditampilkan secara terpisah. Skor setiap grup dihitung berdasarkan rata-rata skor kategori grup tersebut pada periode yang sama. Lihat bagaimana setiap sel dihitung ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dasar ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebas kesalahan kritis"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat bebas kesalahan kritis menunjukkan persentase berapa banyak tinjauan yang lulus tanpa gagal dalam kategori penting apa pun."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Kualitas Internal"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab 'lainnya'"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan berkala"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan harian"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan bulanan"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan mingguan"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan tahunan"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor dari waktu ke waktu"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat dalam hitungan hari atau minggu."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhitungan = total skor hari atau minggu dibagi total tinjauan yang diberikan"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat dalam hitungan hari atau minggu."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhitungan = total skor untuk hari atau minggu dibagi dengan total tinjauan yang diterima"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor berdasarkan kategori"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat rata-rata gabungan seluruh penilaian kategori yang diberikan oleh pengulas selama periode ini."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat rata-rata gabungan dari semua penilaian kategori yang diterima per agen selama periode ini."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab penilaian rendah"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab penilaian"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kategori dari waktu ke waktu"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis bagaimana kategori dibandingkan dari waktu ke waktu."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat skor rata-rata yang diberikan untuk setiap kategori pada hari atau minggu yang dipilih."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis bagaimana kategori dibandingkan dari waktu ke waktu."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat skor rata-rata yang diterima untuk setiap kategori pada hari atau minggu yang dipilih."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor berdasarkan tinjauan"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diarsipkan"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori kritis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala penilaian"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata/Total"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan tinjauan sendiri"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor semua kartu"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diberikan"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["percakapan"]), _normalize(["percakapan"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["item"]), _normalize(["item"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " respons"]), _normalize([_interpolate(_named("n")), " respons"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tinjauan"]), _normalize([_interpolate(_named("n")), " tinjauan"]), _normalize([_interpolate(_named("n")), " tinjauan"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tampilkan satu lagi"]), _normalize(["Tampilkan ", _interpolate(_named("n")), " lainnya"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hari terakhir"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 hari terakhir"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan lalu"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu lalu"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan ini"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu ini"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode khusus"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulan"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triwulan"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal memuat kartu. Ubah filter Anda untuk mencoba lagi."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada data untuk ditampilkan."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan bulanan"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi lebih lanjut."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter aktif Anda mencakup data di berbagai skala penilaian."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sematan kartu dengan filter ini"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sematan dasbor dengan filter ini"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu tinjauan rata-rata"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomisasikan"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan kategori"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Komentar"]), _normalize(["Komentar"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriks"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantan anggota"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor Kualitas Internal"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (rata-rata)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang telah lulus"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu sematan"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan dasbor"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Akar masalah"]), _normalize(["Akar masalah"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Tinjauan"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu Tinjauan"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur akar masalah"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab memberi tahu Anda mengapa percakapan mendapatkan skor rendah"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada akar penyebab yang ditetapkan untuk kategori ini"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor / Total"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori penilaian"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesuaikan urutan"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori berdasarkan"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih semua"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomisasikan"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan tinjauan sendiri"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertakan tinjauan sendiri"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya menampilkan tinjauan sendiri"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus filter"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tagar yang ditemukan"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar komentar"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tagar komentar"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak satu tag pun ditemukan"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari tag meja bantuan"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan kartu skor"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kartu skor"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada koneksi yang cocok"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber percakapan"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih sumber"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebih banyak filter"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja yang cocok"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total waktu yang diberikan"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua orang"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua grup"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup yang cocok."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua grup"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu tampilan"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan data berdasarkan"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pembuatan tinjauan"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pembuatan percakapan"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan dua tempat desimal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat pengaturan"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua ruang kerja"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seluruh akun"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama ruang kerja"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustom"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendera"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makanan dan minuman"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hewan dan alam"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objek"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manusia dan tubuh"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perjalanan dan tempat"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sering digunakan"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil pencarian"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senyum dan emotikon"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simbol"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji tidak ditemukan"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka percakapan"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke daftar tugas"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " atau telusuri daftar Anda tentang ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saat ini Anda menggunakan ruang kerja ", _interpolate(_named("workspace")), " yang tidak terhubung ke domain ini. Klik di sini untuk menghubungkannya sekarang."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koneksi yang tersedia"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda belum dapat memberikan masukan tentang ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadikan meja bantuan Anda sebagai tab yang aktif."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain ini belum dapat ditinjau. Jika perlu, beri tahu admin Anda."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah domain ini"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terhubung dengan ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunggu sebentar - menyiapkan Anda"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak bisa mendapatkan URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat yang sudah terhubung dengan akun Zendesk QA organisasi."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada alat yang terhubung dengan akun Zendesk QA organisasi."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memungkinkan Anda meninjau situs web yang URL-nya selalu sama, dengan menambahkan hash unik di bagian akhir sebelum meninggalkan tinjauan"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindah akun"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Anda"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain yang sudah terhubung dengan ruang kerja ini."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat meninjau halaman mana pun pada domain berikut ini"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain yang tersedia untuk ditinjau"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpanjangan"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL unik"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Belum ada meja bantuan yang tersambung - kunjungi ", _interpolate(_named("link")), " untuk menambahkannya."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstensi browser Zendesk QA hanya dapat digunakan\npada paket Professional dan Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alihkan ruang kerja"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan nama"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan nilai"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang kustom"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini adalah pertama kalinya kami menemukan percakapan ini. Berikan beberapa detail."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referensi percakapan:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesuatu untuk mengidentifikasi tiketnya nanti"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengembalian Uang"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyediakan petugas dan subjek"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membuat"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau percakapan ini"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ditetapkan"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hubungi manajer Anda atau beralih ke ruang kerja lain dari ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri dinonaktifkan untuk ruang kerja ini."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sampai jumpa"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ini tidak dapat ditinjau. Navigasi ke percakapan."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk ke Zendesk QA melalui penyedia SSO Anda terlebih dahulu, lalu coba lagi."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membuka popup masuk."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk dengan akun Zendesk QA Anda"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunggu..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengautentikasi..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum memiliki akun?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak memiliki akses ke percakapan ini. Jika perlu, hubungi manajer Anda."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada akses"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan koneksi Anda telah diaktifkan di Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola koneksi Anda"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesalahan koneksi"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka percakapan di meja bantuan Anda untuk melanjutkan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL tidak valid"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hampir selesai menyambung kembali."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebentar"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba muat ulang halaman untuk kembali ke alur yang benar"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi masalah"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebaskan diri Anda dari batasan virtual meja bantuan dan tinjau percakapan di mana saja."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade langganan Anda untuk menggunakan Ekstensi"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola langganan Anda"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi meja bantuan tidak ditautkan ke ruang kerja ini"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan Anda tidak mengenali tiket ini, atau Zendesk QA tidak memiliki akses ke tiket tersebut"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menemukan percakapan"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas baru"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda mendapat beberapa umpan balik"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk berhasil"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat menggunakan aplikasi ini sekarang"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri nilai semua kategori"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan ini untuk menandai kasus dan memfilternya nanti"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada umpan balik untuk percakapan ini"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baca umpan balik"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka di Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di sinilah semua umpan balik dan pemberitahuan akan muncul. Belum ada…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat selengkapnya di Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail percakapan"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berisi semua"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adalah semua dari"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak mengandung semua dari"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak semuanya dari"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lebih baik dari agen"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lebih buruk dari agen"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salah"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lebih dari"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mengandung salah satu dari"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adalah merupakan dari"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak mengandung salah satu dari"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan salah satu dari"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["benar"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dilanggar"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dikomentari"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dikomentari oleh saya"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belum berkomentar"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak dikomentari oleh saya"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sangat penting untuk ditinjau"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berisi"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hari terakhir"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dalam"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dari"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 hari terakhir"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 jam terakhir"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 hari terakhir"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 hari terakhir"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan lalu"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minggu lalu"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan ini"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minggu ini"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sampai"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hari ini"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kemarin"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terdeteksi"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ada"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dibintangi oleh saya"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak dibintangi oleh saya"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termasuk"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lebih dari"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mulai dari"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kurang dari"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sampai"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan merupakan"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan merupakan"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negatif"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak dilanggar"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak rumit"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak terdeteksi"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak ada"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak termasuk"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak diterima"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak dilihat"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positif"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak ditinjau"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak ditinjau oleh saya"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ditinjau oleh saya"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diterima"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sangat penting untuk ditinjau"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berisi"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lebih dari"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mulai dari"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kurang dari"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sampai"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan merupakan"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak mengandung"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan merupakan"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dilihat"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentar saya memiliki balasan"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ya"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori khusus"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan khusus"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari kondisi"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrik"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dan umpan balik"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan semua kondisi"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membandingkan penanganan percakapan bot dengan agen rata-rata"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot terjebak dalam loop, sehingga pesan yang sama berulang berturut-turut"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan bot dalam percakapan"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis bot yang terlibat dalam percakapan"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot yang terlibat dalam percakapan"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Konten percakapan mengandung frasa ", _interpolate(_list(0)), " dalam pesan agen"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis mengidentifikasi panggilan yang tidak memiliki pernyataan pengungkapan wajib, seperti 'Panggilan ini akan direkam' dan sejenisnya."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seseorang atau bot yang terlibat dalam percakapan"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen atau bot yang sedang ditinjau dalam percakapan"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan mencari bantuan tingkat tinggi"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen positif dan negatif yang terdeteksi dalam percakapan"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikasi agen paling aktif dalam percakapan dengan analisis AI"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen menutup percakapan dengan sopan"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen memahami dan mengakui perasaan pelanggan"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis kesalahan tata bahasa, ejaan, dan gaya agen"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk frasa sambutan yang khas"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen memahami kueri atau kekhawatiran pelanggan"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seberapa mudah sebuah teks dapat dipahami, dengan mempertimbangkan kompleksitas kata dan panjang kalimat"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk mendapatkan solusi yang ditawarkan"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis nada bicara agen sepanjang percakapan"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanda-tanda berkurangnya pelanggan. Pelanggan sedang mempertimbangkan peralihan atau berjanji untuk berpisah."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup tempat percakapan berada."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten atau teks pesan agen"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan agen dalam percakapan"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen yang ditugaskan untuk meninjau percakapan"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamanya waktu percakapan panggilan berlangsung dalam detik"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten atau teks percakapan"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal komunikasi yang digunakan untuk percakapan (misalnya email, obrolan)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar ditambahkan ke percakapan"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika komentar ditambahkan ke percakapan"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen positif dan negatif yang terdeteksi dalam percakapan"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika pelanggan menjawab survei kepuasan pelanggan"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah tinjauan percakapan diperdebatkan"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal yang digunakan oleh pelanggan untuk memulai percakapan"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah respons pelanggan dalam percakapan"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pesan terakhir dalam percakapan"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran atau jenis pengguna yang terlibat dalam percakapan"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keheningan antara agen dan pelanggan melebihi ambang batas"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan mencari bantuan tingkat yang lebih tinggi"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan mengungkapkan rasa terima kasih yang sebesar-besarnya atau sangat puas dengan dukungan yang diterima"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baik pelanggan atau agen secara eksplisit meminta tindak lanjut"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teks atau rekaman seluruh percakapan yang dilakukan dengan suara"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah percakapan ditandai sebagai berbintang"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar yang digunakan dalam komentar untuk mengkategorikan konten"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa percakapan yang terdeteksi oleh AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pesan terakhir dalam percakapan"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah catatan pribadi dalam percakapan"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah rata-rata karakter per pesan dalam percakapan"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan yang ditujukan kepada pelanggan dalam percakapan"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta yang informasinya dapat dilihat oleh publik"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata-rata jumlah kata per pesan dalam percakapan"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori yang digunakan untuk menilai atau mengevaluasi percakapan"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status proses tinjauan percakapan"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta atau orang yang terlibat dalam percakapan"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email yang terkait dengan percakapan"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah tinjauan telah dilihat"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau yang menilai percakapan"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan terakhir kali ditinjau"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen yang sedang ditinjau dalam percakapan"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar atau umpan balik spesifik yang diberikan oleh pelanggan mengenai kepuasan mereka terhadap percakapan"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kepuasan pelanggan untuk percakapan tersebut"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kepuasan pelanggan per pesan dalam percakapan"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor yang digunakan untuk meninjau percakapan"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA yang diberikan telah dilanggar"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis sumber atau koneksi dari mana percakapan berasal"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan tidak biasa atau tidak lazim dan terjadi bolak-balik untuk mencapai penyelesaian"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan survei terkait konten percakapan"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul atau nama survei yang terkait dengan percakapan"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen yang ditugaskan untuk meninjau percakapan"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merek atau produk/layanan tertentu yang terkait dengan percakapan"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal komunikasi yang digunakan untuk percakapan (misalnya email, obrolan)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan ditutup atau diselesaikan"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan pertama kali dibuat"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan pertama kali dibuat"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika pelanggan menjawab survei kepuasan pelanggan"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi singkat atau ringkasan percakapan"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID referensi eksternal yang ditautkan ke percakapan"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder atau pengelompokan spesifik tempat percakapan diatur"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulir atau template yang digunakan untuk menyusun percakapan"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID referensi eksternal yang ditautkan ke percakapan"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak surat atau akun email yang digunakan untuk menerima percakapan"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor telepon yang terkait dengan percakapan"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat prioritas yang ditetapkan untuk percakapan"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah total balasan dalam percakapan"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status atau status percakapan saat ini"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status percakapan saat ini"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjek atau topik pembicaraan"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan agen dalam percakapan"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag yang ditetapkan untuk mengkategorikan percakapan"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis atau kategori percakapan"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan terakhir kali diperbarui"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal ketika percakapan terakhir kali diperbarui"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media atau kanal yang digunakan untuk melakukan percakapan"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten transkripsi dalam percakapan"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang tidak memenuhi syarat untuk ditinjau melalui analisis AI"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah agen yang berpartisipasi dalam percakapan"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah percakapan telah dilihat"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan apa pun"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efisiensi komunikasi bot"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengulangan bot"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan bot"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis bot"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arah panggilan"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan klien"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat hening"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekaman pengungkapan hilang"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalasi"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten transkrip panggilan"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibintangi"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak memenuhi syarat untuk tinjauan"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditinjau oleh"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan melalui"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejaan dan tata bahasa"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan agen"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen paling aktif"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan agen"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durasi panggilan (d)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten percakapan"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal pesan"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag komentar"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal yang dikomentari"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentimen"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal balasan survei CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal pelanggan"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan pelanggan"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal balasan terakhir"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis pengguna"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiko churn"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat hening"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalasi"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan luar biasa"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindak lanjut"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkrip"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar komentar"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencilan"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemahaman"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal balasan terakhir"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah catatan pribadi percakapan"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah karakter rata-rata"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah kata rata-rata"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah jawaban publik"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta umum"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal penilaian"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian dan komentar"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status tinjauan"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterbacaan"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email terkait"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terkait"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor yang ditinjau"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dilihat"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal yang ditinjau"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar survei"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT per pesan"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kepuasan (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi yang ditawarkan"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis sumber"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perihal"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan survei"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul survei"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merek percakapan"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain merek"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal percakapan"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal penutupan"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal dibuat"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal dibuat"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal balasan survei CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal pengiriman survei CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi percakapan"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Eksternal"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang percakapan"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder percakapan"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bentuk percakapan"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup percakapan"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantu ID Pramuka"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Eksternal"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Interkom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak surat percakapan"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor telepon"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritas percakapan"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan percakapan"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status percakapan"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status percakapan"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perihal percakapan"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah balasan agen"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis percakapan"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal diperbarui"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal diperbarui"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten transkripsi"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah agen yang berpartisipasi"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status yang dilihat"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemampuan yang rentan"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesehatan yang rentan"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peristiwa kehidupan yang rentan"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori AutoQA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kondisi terkait tanggal"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah nama ke filter Anda"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi nama filter"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang masuk"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantan anggota ruang kerja"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota ruang kerja"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tambah ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan filter"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda harus memiliki setidaknya 1 filter terkait tanggal"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih opsi"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih opsi"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir dalam ", _interpolate(_named("n")), " hari. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dalam ", _interpolate(_named("n")), " hari</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir dalam ", _interpolate(_named("n")), " hari. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>dalam ", _interpolate(_named("n")), " hari</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir hari ini. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji coba Anda berakhir <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>hari ini</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siapkan langganan sekarang"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " hari"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda memiliki ", _interpolate(_named("days")), " lagi dalam uji coba Anda"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda telah mencapai batas pengguna untuk paket Anda. ", _interpolate(_named("link")), " untuk mempelajari lebih lanjut atau meningkatkan paket Anda."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik di sini"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hubungkan meja bantuan Anda"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan memungkinkan kami untuk menarik tiket Anda untuk Anda tinjau."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan meja bantuan Anda"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda menggunakan Zendesk QA dengan data sampel. Untuk menghapus data sampel dan mengimpor percakapan Anda sendiri, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data sampel"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin informasi bermanfaat di bawah ini"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambil tangkapan layar satu halaman penuh untuk memberikan konteks"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi tim dukungan pelanggan kami untuk menyelesaikan masalah ini:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi masalah"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referensi kesalahan:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyalin informasi yang bermanfaat"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna, bot, dan ruang kerja"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna dan Ruang Kerja"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hari ini"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan ini"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minggu ini"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ditinjau ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ditinjau ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ditinjau ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa yang baru?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pintasan keyboard"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bercakap dengan kami"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrolan kami tidak dapat dimuat (Apakah Anda mungkin menggunakan pemblokir iklan?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsung ke..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunitas"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantuan"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beranda"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diberikan"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis pengetahuan"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindah akun"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aplikasi telah diperbarui. ", _interpolate(_named("buttonStart")), "Muat ulang", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda ingin mulai menggunakan Zendesk QA sebagai bagian dari ruang kerja Anda:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sekadar memberi tahu Anda bahwa Anda telah menerima undangan untuk menggunakan Zendesk QA sebagai bagian dari ruang kerja. Jika mau, tentu saja Anda dapat melanjutkan, namun perhatikan bahwa ini akan membuat ", _interpolate(_named("bold")), " untuk Anda."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisasi yang terpisah"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ya, izinkan saya melanjutkan dengan ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akun baru"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda yakin?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " meminta Anda untuk bergabung ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bergabung"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada koneksi Internet"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pin baru"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigasi cepat"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fokus dan perluas editor komentar"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan atau tampilkan panel samping"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju ke percakapan berikutnya"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka percakapan asli"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuju ke percakapan sebelumnya"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan semua kategori peringkat setinggi mungkin"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan semua kategori peringkat serendah mungkin"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengacak percakapan (mengurutkan ulang secara acak)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beritahu kami"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pintasan keyboard"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengalihkan modal pintasan"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda telah mencapai batas ", _interpolate(_named("charLimit")), "-karakter"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jenis gambar ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") tidak diperbolehkan"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ukuran gambar ", _interpolate(_named("name")), " terlalu besar (diizinkan 5MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor komentar gagal memuat"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letakkan untuk mengunggah"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambar"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teks"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola templat"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada template yang ditambahkan"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah akar masalah"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya termasuk alasan yang dihapus"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan penilaian"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negara"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama depan"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama belakang"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah karyawan"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor telepon"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami akan menghubungi Anda untuk membantu menyiapkan akun Anda"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama perusahaan"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor kontak perusahaan"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagaimana Anda pertama kali mendengar tentang Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kanal"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih sejumlah agen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi semua bidang yang diperlukan"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda harus setuju untuk membaca dan menyetujui persyaratan dan kebijakan privasi kami."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal Slack yang digerakkan oleh dukungan"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periklanan"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acara"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teman atau kolega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan marketplace atau manajer akun"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situs rujukan"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesin pencari (Google, Bing, dll.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media sosial"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceritakan kepada kami tentang diri Anda dan perusahaan Anda."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perincian kontak"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya siap."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berkeliling, mengenal aplikasi, menghapus dan menambahkan sesuatu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun dibuat. Apa selanjutnya?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siap untuk menghadirkan percakapan nyata Anda?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah data Anda sendiri"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai menjelajah"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobalah dengan data sampel"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadi kami dapat mulai menarik tiket Anda untuk Anda tinjau."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan akun meja bantuan Anda"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya berubah pikiran."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan pendaftaran"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sematan telah dibuat. ", _interpolate(_named("buttonStart")), "Buka sematan", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan pemanduan tersedia pada paket Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade paket"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua paket"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan catatan Anda untuk mempersiapkan diri untuk sesi 1:1 atau sesi pemanduan berikutnya."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan untuk Pemanduan"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri komentar atau tambahkan orang lain dengan ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan ini telah dihapus"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sematan baru"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sematan"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pin"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cara kerja sematan"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak memiliki izin untuk melihat percakapan ini"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu sematan"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan percakapan"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan dasbor"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan disimpan"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan kartu ini"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan percakapan ini"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan dasbor ini"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan di bawah pengguna"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya Anda yang dapat melihat apa yang Anda sematkan. Untuk berbagi dengan orang lain, ", "@", "sebutkan mereka di komentar."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " sekarang diundang ke percakapan ini"]), _normalize([_interpolate(_named("agentsNames")), " kini diundang ke percakapan ini"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 komentar"]), _normalize([_interpolate(_named("n")), " komentar"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Anda menyebutkan ", _interpolate(_named("participants")), " namun mereka tidak ada dalam percakapan ini."]), _normalize(["Anda menyebutkan ", _interpolate(_named("participants")), " namun mereka tidak ada dalam percakapan ini."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " peserta"]), _normalize([_interpolate(_named("n")), " peserta"]), _normalize([_interpolate(_named("n")), " peserta"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apakah Anda ingin menghapus ", _interpolate(_named("agentName")), " dari mengakses pin ini?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan lakukan apa pun"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan undang mereka ke percakapan ini"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah komentar"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang mereka"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang mereka ke percakapan ini"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus pengguna"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utas"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini tidak dapat diurungkan."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini juga akan menghapus semua komentar di bawah sematan ini."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pesan baru"]), _normalize([_interpolate(_named("n")), " pesan baru"]), _normalize([_interpolate(_named("n")), " pesan baru"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya Anda yang dapat melihat sematan Anda. Untuk berbagi sematan, ", "@", " seseorang di komentar."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " disematkan ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sebuah dasbor"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sebuah percakapan"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sebuah catatan"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade untuk mendapatkan sematan pemanduan"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan pemanduan tersedia pada paket Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan pemanduan tersedia pada paket Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade untuk mendapatkan fitur"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persiapkan diri Anda untuk 1:1 atau simpan sesuatu untuk lain waktu."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan menghapus pin dan semua komentar yang ditambahkan secara permanen."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus pin"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus pin"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama folder"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyematkan sesuatu untuk kembali lagi nanti"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balas"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbaru dahulu"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlama dahulu"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan tren atau percakapan dasbor yang menarik sebagai persiapan untuk 1-1 Anda, atau catat untuk lain waktu."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat pin dengan mengeklik tombol di atas atau ikon pin di tampilan Percakapan atau Dasbor."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sematan yang dibuat"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tidak ada hasil untuk ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba gunakan kata kunci yang berbeda atau periksa kesalahan ketik."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baru"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sematan baru"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan pengguna, kata kunci..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menurut abjad"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baru dibuat"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baru saja diedit"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan terbanyak"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Saya bermaksud meninjau ", _interpolate(_named("n")), " percakapan ", _interpolate(_named("schedule"))]), _normalize(["Saya bermaksud meninjau ", _interpolate(_named("n")), " percakapan ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran diperbarui"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan sasaran"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat menetapkan sasaran personal untuk jumlah percakapan yang ingin Anda tinjau."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setiap hari"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setiap bulan"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setiap minggu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran tinjauan"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat kanal"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kanal"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya membagikan umpan balik survei dengan komentar"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berdayakan kolega Anda dengan menyoroti kualitas layanan mereka yang luar biasa dan umpan balik pelanggan yang sangat baik."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagikan umpan balik survei positif ke Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kanal yang ditemukan"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis membagikan umpan balik dengan skor di atas"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Praisebot"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan pengingat di awal minggu jika Anda belum melakukan tinjauan."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengingat"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengingat mingguan untuk melakukan tinjauan"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima pemberitahuan tentang tinjauan, balasan, sebutan, dan perdebatan baru di Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebut"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balasan utas"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan tentang tinjauan, balasan, sebutan, dan perselisihan baru."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan yang terkait dengan saya"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebutkan di komentar"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan baru yang diterima"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balasan dalam utas"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan dengan statistik"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima laporan tentang tinjauan yang diberikan dan diterima."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["harian"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulanan"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mingguan"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harian"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap 3 jam"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap 6 jam"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seketika"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulanan"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktif"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mingguan"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin akun Anda telah mengunci pengaturan pemberitahuan."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih periode"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan pribadi"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktif)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan masuk sosial baru ke akun ini:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus metode masuk aktif akan mengeluarkan Anda. Anda dapat masuk lagi dengan email alternatif Anda."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus metode masuk"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil menambahkan Google sebagai opsi masuk"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi masuk"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil menambahkan Slack sebagai opsi masuk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kata sandi"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda yakin?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setel ulang kata sandi"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email ke ", _interpolate(_named("email")), " akan dikirimkan untuk mengatur ulang kata sandi Anda."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email utama"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periksa inbox Anda untuk email konfirmasi kami"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan ajaib"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk mengubahnya, ubah terlebih dahulu ", _interpolate(_named("link")), " Anda."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Email ", _interpolate(_named("email")), " akan dikaitkan dengan kata sandi ini."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil diperbarui"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambar profil"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk hasil terbaik, unggah foto persegi (256x256 piksel atau lebih)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email utama"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awal minggu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan pribadi"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih sedikitnya satu opsi"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten template"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama template"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan template di"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menambahkan konten templat"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah nama template"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat template"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat template"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat template komentar untuk mempercepat peninjauan, penyematan item, dan pemanduan."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digunakan dalam"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus template"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus template ini secara permanen?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit template"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat template komentar untuk mempercepat peninjauan, penyematan item, dan pemanduan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada template yang dibuat"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template komentar dibuat"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template komentar dihapus"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template komentar disimpan"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dan perselisihan"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk mendapatkan akses, hubungi ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dukungan pelanggan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada akun yang ditemukan"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk saat ini, gunakan ", _interpolate(_named("link")), " untuk mengakses Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun tidak dimigrasi"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metrik"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skor"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistik"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrasi"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percakapan"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diberikan"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentar saya"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perdebatan saya"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan saya"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaksi"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik survei"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitas"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buat kata sandi"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kredensial"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masuk"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setel ulang"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sasaran tinjauan"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengguna"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat template"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akun"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["penagihan"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perusahaan"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisasi"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pembayaran"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tempat"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengaturan"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["langganan"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori baru"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan yang bertentangan ditemukan"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah atau menghapus salah satu pesan yang bertentangan"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gunakan metode ", _interpolate(_named("placeholder")), "untuk mengecualikan data sensitif dari AutoQA. Misalnya, alih-alih \"Salam, John\" gunakan \"Salam, ", _interpolate(_named("placeholder")), "\" untuk mewakili nama apa pun."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimum ", _interpolate(_named("limit")), " Baris"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan aturan ke"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai batas 10 kategori khusus"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda telah mencapai batas kategori kustom ", _interpolate(_named("limit"))])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan kondisi"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah baris"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dan"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kondisi"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika pesan agen"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika tidak, skor"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemudian skor"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kategori kartu skor"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan '/' untuk menambahkan variabel"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua arah"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan agen tidak boleh kosong"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan tidak boleh lebih dari 300 karakter"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teks"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sumber"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kanal"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tag"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua percakapan"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persempit di mana aturan Anda berlaku."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan khusus"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat wawasan Sorotan"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan agen tidak boleh kosong"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai batas 10 wawasan khusus"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda telah mencapai batas wawasan kustom ", _interpolate(_named("limit"))])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi nama wawasan"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama wawasan"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kategori"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis wawasan"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netral"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positif"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka pengaturan ruang kerja"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak sekarang"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mulai menggunakan kategori penilaian ini, buka pengaturan kartu skor ruang kerja Anda dan tambahkan ke kartu skor pilihan Anda."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian dibuat"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis menilai percakapan tertutup dengan mengidentifikasi kata kunci atau frasa tertentu."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak berfungsi pada transkrip suara."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengerti"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tos! ✋ Anda telah memaksimalkan kategori penilaian otomatis pada ", _interpolate(_named("limit")), ". Anda adalah ahli kategorisasi! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas kategori tercapai"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan semua kolom diisi dengan benar"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri nama kategori penilaian Anda"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama kategori"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian baru"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian baru"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis menilai percakapan tertutup dengan mengidentifikasi kata kunci atau frasa tertentu. Tidak berfungsi pada transkrip suara."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan percakapan tertutup secara otomatis dengan mengidentifikasi kata kunci atau frasa tertentu. Hanya mengerjakan transkrip."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis menilai percakapan dengan mengidentifikasi kata kunci atau frasa tertentu dalam pesan agen dan bot."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk menggunakan kategori penilaian ini, buka pengaturan kartu skor ruang kerja Anda dan tambahkan ke kartu skor."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor percakapan secara otomatis berdasarkan kata kunci atau frasa tertentu dalam pesan."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tersedia untuk"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan wawasan"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan ini ditampilkan di Sorotan berdasarkan kriteria yang ditentukan."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan kartu skor"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori ini dapat digunakan pada kartu skor berdasarkan kriteria yang ditetapkan."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segera hadir"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kustom"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ditentukan sebelumnya"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kartu skor"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorotan"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suara"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan perubahan"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan disimpan"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik ikon ", "{··}", " untuk mengecualikan nama, detail perusahaan, atau informasi sensitif lainnya selama pemeriksaan penutup AutoQA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah penutup yang disetujui"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit penutup yang disetujui"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup yang disetujui"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pengecualian"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pengecualian"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah sambutan yang disetujui"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit sambutan yang disetujui"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salam yang disetujui"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempelkan kata-kata di sini dengan dipisahkan oleh jeda baris"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekan Enter setelah setiap kata untuk entri baru"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa yang berlaku"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misalnya, alih-alih \"Salam, John\" Anda dapat menggunakan \"Salam, ", "{··}", "\" untuk mewakili nama apa pun."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholder konten dinamis"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 pengecualian ditambahkan"]), _normalize([_interpolate(_named("count")), " pengecualian ditambahkan"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pengecualian sudah ditambahkan"]), _normalize(["Pengecualian sudah ditambahkan: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pengecualian"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan pengecualian"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengecualian yang ditambahkan"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian (peka huruf besar/kecil)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tambahkan pengecualian"]), _normalize(["Tambahkan 1 pengecualian"]), _normalize(["Tambahkan ", _interpolate(_named("count")), " pengecualian"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencari kata atau frasa"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian disimpan"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bahasa"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua bahasa"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari bahasa"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Penutup sudah ditambahkan"]), _normalize(["Penutup sudah ditambahkan: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah penutup"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan penutup yang disetujui"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada penutup yang disetujui"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah penutup"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari penutup"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup ditambahkan"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup disimpan"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Formulir memiliki entri duplikat: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Salam sudah ditambahkan"]), _normalize(["Salam sudah ditambahkan: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah sambutan"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan sambutan yang disetujui"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sambutan yang disetujui"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah sambutan"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari sambutan"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan ditambahkan"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan disimpan"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pengecualian"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hapus pengecualian"]), _normalize(["Hapus pengecualian"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hapus pengecualian ini secara permanen?"]), _normalize(["Hapus pengecualian ini secara permanen?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pengecualian"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Satu atau lebih pengecualian panjangnya lebih dari ", _interpolate(_list(0)), " karakter"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " karakter"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui oleh"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variabel"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan persetujuan Anda sendiri yang selaras dengan standar merek dan layanan Anda. Saat ditambahkan, AutoQA hanya akan mendeteksi penutup yang disetujui. Perubahan yang dilakukan berlaku untuk percakapan mendatang."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai batas atas 100 penutup yang disetujui. Jika Anda ingin menambahkan lebih banyak, mungkin ini saatnya meninjau kembali dan menyempurnakan penutup yang ada."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas penutup yang disetujui tercapai"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup yang disetujui"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata dan frasa yang ingin Anda abaikan oleh AutoQA karena kesalahan ejaan atau tata bahasa. Perubahan yang dilakukan berlaku untuk percakapan mendatang."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan daftar"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan sambutan Anda sendiri yang selaras dengan merek dan standar layanan Anda. Saat ditambahkan, AutoQA hanya akan mendeteksi sambutan yang disetujui. Perubahan yang dilakukan berlaku untuk percakapan mendatang."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai batas atas 100 sambutan yang disetujui. Jika Anda ingin menambahkan lebih banyak, mungkin ini saatnya meninjau kembali dan menyempurnakan sambutan Anda yang sudah ada."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas sambutan yang disetujui tercapai"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan yang disetujui"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak sekarang"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kategori"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kategori penilaian ini secara permanen?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar kategori"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori yang dihapus tidak akan muncul di percakapan dan filter baru. Data historis di dasbor dan percakapan tetap utuh."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori akan dihapus dari semua kartu skor yang telah ditambahkan."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal menghapus kategori kartu skor"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori kartu skor dihapus"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus kategori kartu skor"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori AutoQA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " yang didukung oleh model AI akan tetap ada di kartu skor tetapi tidak akan lagi dinilai secara otomatis"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menonaktifkan integrasi LLM tidak mematikan fitur AutoQA sepenuhnya. Inilah yang akan terjadi:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data historis"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " akan tetap berada di dasbor AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab yang ditambahkan secara manual"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " akan tetap ada, tetapi semua akar penyebab berbasis LLM akan dihapus"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matikan AutoQA berbasis LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktifkan"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA menggunakan AI dan model bahasa besar (LLM) untuk secara otomatis menilai kategori tertentu. Data Anda diproses di bawah Perjanjian Pemrosesan Data (DPA) yang ketat dan tidak pernah digunakan untuk melatih model apa pun."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang AI generatif"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA menggunakan Layanan Microsoft Azure OpenAI untuk ", _interpolate(_named("automatically_scoring_categories")), ". Data Anda diproses berdasarkan DPA yang ketat hanya untuk memberikan AutoQA kepada Anda dan ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secara otomatis menilai beberapa kategori"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak pernah digunakan untuk melatih model OpenAI apa pun"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA berbasis LLM diaktifkan"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA berbasis LLM dimatikan"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA berbasis LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian otomatis dengan AI di AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemrosesan data kategori AutoQA berbasis MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Microsoft Azure ditarik kembali"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan Microsoft Azure diberikan. Percakapan akan segera menerima penilaian otomatis."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda masih dapat menggunakan AutoQA, tapi inilah yang akan terjadi"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menonaktifkan layanan MS Azure OpenAI tidak akan mematikan AutoQA sebagai fungsi."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat melakukannya di tampilan kategori kartu skor."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori AutoQA yang didukung oleh model akan tetap ada di kartu skor tetapi tidak akan dinilai secara otomatis."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar masalah yang Anda tambahkan secara manual akan tetap ada. Semua akar penyebab yang dihasilkan model akan dihapus."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data historis tetap ada di dasbor AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktifkan pemrosesan data oleh layanan MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang kategori AutoQA yang lebih luas."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan multibahasa yang luar biasa."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penambahan kategori baru yang serba cepat."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akurasi evaluasi yang lebih baik. GPT-3.5 menunjukkan kesadaran kontekstual tingkat manusia."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan manfaat dari kemajuan terbaru dalam teknologi AI."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inilah yang akan Anda dapatkan"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pemrosesan data oleh layanan MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persetujuan diberikan"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada persetujuan yang diberikan"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna baru ditugaskan ke ruang kerja ini"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja default"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Waktu 12 jam (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Waktu 24 jam (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format waktu"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil perusahaan diperbarui"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awal minggu"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi kustom"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kunci akses dan kunci rahasia biasanya ditemukan di Manajemen akses > Pengguna"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kunci akses AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan perubahan kunci akses rahasia"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah nilai kunci akses rahasia"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi ini memungkinkan untuk menentukan hanya satu wilayah yang biasanya ditemukan di bawah Lokasi"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah kunci rahasia"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunci akses rahasia AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat menemukan semua informasi relevan di Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biasanya ditemukan di bawah pengaturan instans Amazon Connect dan halaman penyimpanan Data"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi penyimpanan rekaman panggilan"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan saat menambahkan Amazon Connect. Periksa apakah semua kolom sudah diisi dengan benar, lalu coba lagi."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi penyimpanan harus dalam format bucket/folder yang dipisahkan dengan garis miring."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk menambahkan koneksi, hubungi ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan Pelanggan"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tiket Zendesk Anda akan disinkronkan secara otomatis. Anda dapat memilih untuk mengecualikan tiket tertentu."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pasifik (Tokyo)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pasifik (Seoul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pasifik (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pasifik (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Pasifik (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (Tengah)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eropa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eropa (Zurich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eropa (Irlandia)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eropa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah server"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timur Tengah (UEA)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih wilayah server"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika Selatan (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS Timur (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS Timur 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagian Barat AS (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi sudah ditambahkan"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai koneksi"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya sinkronkan percakapan yang"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanal satu, kanal dua"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID unik koneksi ini adalah ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 bulan"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 tahun"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 bulan"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 bulan"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang tidak aktif akan dihapus setelah periode retensi yang dipilih. Tinjauan yang dikirimkan tetap terlihat di tampilan Aktivitas dan Dasbor."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode retensi"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih periode retensi"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perlu diingat bahwa tindakan ini tidak dapat diubah."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya memahami bahwa hal ini akan menghapus semua data terkait meja bantuan untuk koneksi ini dari Zendesk QA, termasuk percakapan, bidang, dan tag."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang dikirimkan tidak akan terpengaruh dan akan tetap terlihat pada tampilan tertentu."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grup satu, grup dua"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertakan"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengembangan aktif"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Integrasi ini masih ada di ", _interpolate(_named("activeDevelopment")), ". Beberapa detail mungkin tidak ada (seperti lampiran atau bidang khusus)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kotak surat satu, kotak surat dua"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag satu, tag dua"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahasia token API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan \"Nama Bidang Tiket\" di Freshdesk pada Pengaturan → Bidang tiket"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan \"Custom Field Names\" di Help Scout di bawah Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan \"Nama Percakapan Klass\" di Kustomer pada Pengaturan → Klasses → Percakapan"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan \"Nama Bidang Khusus\" di Agen Langsung pada Pengaturan → Bidang khusus"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan \"Nama Bidang Khusus\" di Wix pada Pengaturan → Bidang khusus"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisahkan ID Bidang dengan koma. Anda dapat menemukan ID ini di Zendesk pada Admin → Bidang tiket."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan beberapa pengguna meja bantuan Anda untuk meninjau percakapan pertama Anda. Jangan khawatir, kami tidak akan memberi tahu mereka kecuali Anda mengundang mereka menjadi anggota ruang kerja."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhasil diintegrasikan"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah anggota"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami sekarang akan mulai menarik percakapan Anda."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domain: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pengenal meja bantuan: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mengautentikasi koneksi Anda"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua siap. Ini adalah koneksi baru Anda."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beberapa koneksi belum diperbarui atau sudah tidak aktif. Coba perbarui koneksi ini atau hapus koneksi tersebut dari daftar Anda."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID klien API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahasia klien API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Buat token API baru dari Pengaturan → Akun → API & SDK. URL Pengalihan yang digunakan adalah ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Akun"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih meja bantuan Anda untuk memulai"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika akun Interkom Anda diatur untuk dilayani dari Uni Eropa atau Australia, perbarui ini."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email yang Anda gunakan untuk masuk ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan nama pengguna akhir, email, dan nomor telepon. Kredensial bank sudah disamarkan untuk semua koneksi meja bantuan."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada integrasi. Tambah satu sekarang."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda membutuhkan peran-peran ini:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat koneksi"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah koneksi baru"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus centang ini memungkinkan Anda menambahkan koneksi ini secara manual hanya ke ruang kerja tertentu"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan ke semua ruang kerja yang ada"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua ruang kerja"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunci API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahasia kunci API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["koneksi ", _interpolate(_list(0)), " dihapus"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama koneksi"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat pada"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mengambil token API Anda di bawah."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambang batas CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus koneksi ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik \"hapus koneksi\" untuk menghapus koneksi ini"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus koneksi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola dan pantau sumber data Anda yang terhubung."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit koneksi"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memisahkan nama bidang dengan koma"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembaruan gagal"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan nama unik untuk integrasi Anda"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan opsi lanjutan"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan bidang data sensitif"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan pernah menyimpan URL lampiran"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan lampiran"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan pernah menyimpan konten percakapan (Anda dapat menariknya nanti sesuai permintaan dari meja bantuan Anda)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan konten percakapan"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan pernah menyimpan perihal percakapan (Anda dapat menariknya nanti sesuai permintaan dari meja bantuan Anda)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan baris perihal"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak aktif"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Subdomain ", _interpolate(_list(0)), " Anda"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah interkom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencocokkan ID tim inbox"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID numerik tim Interkom Anda"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang dalam progres..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotak surat"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperbarui koneksi secara manual"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyembunyikan data pelanggan"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama bidang yang sensitif"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID bidang sensitif"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui koneksi"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui koneksi"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terhubung ke wahana percobaan Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Menyiapkan koneksi dengan ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan opsi lanjutan"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya menarik percakapan dengan skor lebih rendah atau sama dengan ambang batas yang ditetapkan"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan ambang batas CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinkronkan hanya percakapan yang..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan percakapan terbaru"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui koneksi"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi diperbarui"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama pengguna"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan Anda"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk membatalkan langganan dan menghapus akun Anda, hubungi ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dukungan Pelanggan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus akun"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus tiket di Zendesk Support (atau meja bantuan lainnya) tidak memengaruhi tinjauan percakapan terkait. Anda masih dapat melihat dan mengelolanya."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menghapus tiket di Zendesk QA juga akan menghapus ulasan percakapan yang terkait.", _interpolate(_named("line_break")), "Menghapus tiket di Zendesk Support (atau meja bantuan lainnya) tidak akan memengaruhi ulasan percakapan yang terkait. Anda masih dapat melihat dan mengelolanya."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola tinjauan"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus tinjauan bersifat permanen. Ini juga memengaruhi data pada dasbor."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus semua tinjauan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus semua"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tinjauan dihapus"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus semua"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika ada tinjauan yang terkait dengan tiket yang dihapus, Anda akan melihatnya di sini."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada tinjauan"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari ID tiket"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID Tiket: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang dihapus"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ruang kerja default tidak dapat dihapus. Untuk menghapus ruang kerja ini, pilih ruang kerja default lainnya di ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan akun"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini tidak dapat diurungkan."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buang dan hapus semua data"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saya memahami bahwa hal ini akan ", _interpolate(_named("removeAllData")), " terkait dengan ruang kerja ini, termasuk pengguna dan koneksi ruang kerja."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus ruang kerja"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik \"hapus ruang kerja\" untuk menghapus ruang kerja ini"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ruang kerja ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus ruang kerja"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan apa yang Anda lakukan atau konsultasikan dengan seorang profesional sebelumnya."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih apakah yang ditinjau default dipilih berdasarkan agen yang paling sering atau petugas saat ini."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen paling aktif"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau default"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memungkinkan pengguna untuk meninjau percakapan mereka sendiri dan memungkinkan agen untuk melakukan peninjauan"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja telah dihapus"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja telah diperbarui"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetapkan penilaian netral dengan menyembunyikan tinjauan yang sebelumnya diberikan oleh peninjau lain"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian yang tidak bias"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warna"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini tidak dapat diurungkan."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhatikan bahwa tindakan ini akan membuang dan menghapus semua data yang terkait dengan ruang kerja ini, termasuk pengguna dan koneksi ruang kerja."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus ruang kerja"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus ruang kerja..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan ajaib"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan ajaib"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menonaktifkan metode masuk Anda saat ini"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode otentikasi telah diperbarui"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan jenis metode otentikasi tertentu untuk semua pengguna."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata sandi"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AKUN"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna dan ruang kerja"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat dan kelola ruang kerja, dan undang atau hubungkan pengguna."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetap di halaman"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan halaman"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meninggalkan halaman ini dan kehilangan semua perubahan?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepertinya tidak ada percakapan yang perlu diimpor. Mulailah dengan melakukan beberapa percakapan dengan meja bantuan Anda, lalu kembali ke sini untuk menganalisisnya."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditemukan"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak dapat menghapus kategori sistem ini"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kategori"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus kategori bersifat permanen. Ini akan menghapus semua data kategori dari pelaporan."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengembalikan kategori ini, Anda harus membuatnya lagi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kategori"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari kategori"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori otomatis secara otomatis mengisi kartu skor dengan peringkat yang disarankan untuk menghemat waktu peninjauan. Kategori manual diisi oleh pengulas dari awal."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembaruan terakhir"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatis"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejaan dan tata bahasa"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemahaman"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterbacaan"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi yang ditawarkan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori yang ditemukan"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada akar penyebab yang ditemukan"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan kartu skor"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba pencarian yang berbeda atau ubah filter"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kategori"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategori"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kartu skor"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kartu skor"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kartu skor"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kartu skor"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kartu skor"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua status"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " status"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jenis"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jenis"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jenis"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua jenis"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jenis"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ruang kerja"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ruang kerja"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja yang ditemukan"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ruang kerja"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua ruang kerja"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ruang kerja"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bobot ", _interpolate(_named("weight")), " memberikan kontribusi ", _interpolate(_named("percentage")), "% terhadap skor keseluruhan"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kartu skor"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus kartu skor bersifat permanen. Ini akan menghapus semua data kartu skor dari pelaporan."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengembalikan kartu skor ini, Anda harus membuatnya lagi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kartu skor"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembaruan terakhir"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak aktif"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skala penilaian diperbarui. ", _interpolate(_named("buttonStart")), "Mulai meninjau", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA menetapkan skala biner secara default, namun Anda juga bisa memilih skala yang lebih terperinci:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jika Anda ingin melihat opsi skala penilaian yang berbeda, dikonfigurasi berdasarkan kategori, buka ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati/Nada"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengetahuan produk"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan memperbarui skala penilaian:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala penilaian Anda sebelumnya dan kategori yang sesuai akan diarsipkan (tidak dihapus)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beberapa data dasbor akan terpengaruh karena skala lama dan baru Anda mungkin tidak sebanding."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kloning pengaturan kategori penilaian saya yang ada ke skala baru"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin ingin memperbarui skala penilaian?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mengubah format tampilan kartu skor (angka atau emoji) dan membuat kategori wajib atau opsional."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan emoji sebagai pengganti angka"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan pengguna untuk melewati kategori"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis skala penilaian"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kategori untuk 1 ruang kerja telah dikloning"]), _normalize(["Kategori untuk ", _interpolate(_named("workspacesAmount")), " ruang kerja telah dikloning"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan ruang kerja"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["jika ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis memunculkan panggilan ketika agen tidak memberikan perintah kepada pelanggan untuk mengharapkan keheningan dalam percakapan."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan memiliki saat hening yang berlangsung lebih lama dari ambang batas yang ditetapkan."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetapkan ambang batas ssaat hening maksimum dalam panggilan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat hening"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan yang dihapus tidak akan muncul dalam percakapan dan filter baru. Data historis di dasbor dan percakapan tetap utuh."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal menghapus wawasan Sorotan"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan sorotan dihapus"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus wawasan Sorotan"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat percakapan yang berisi kata atau frasa tertentu dalam transkrip dan pesan."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis mengidentifikasi panggilan yang tidak memiliki pernyataan pengungkapan wajib, seperti 'Panggilan ini akan direkam' dan sejenisnya."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bertujuan untuk mengidentifikasi pernyataan pengungkapan rekaman dalam panggilan menggunakan Model Bahasa Besar (LLM) yang memahami konteks secara dinamis, menghilangkan kebutuhan akan frasa yang telah ditetapkan sebelumnya."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekaman pengungkapan hilang"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis memunculkan panggilan ketika agen tidak memberikan perintah kepada pelanggan untuk mengharapkan keheningan dalam percakapan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat hening"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menentukan saat hening dalam panggilan"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perlu melakukan perubahan pada langganan? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") dan kami siap membantu."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis menganalisis 100% percakapan di seluruh agen, BPO, saluran, dan bahasa. AI mendeteksi masalah, kesenjangan pengetahuan, dan peluang pemanduan untuk meningkatkan layanan."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakupan AutoQA 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik dan pemanduan agen"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan wawasan AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelaporan dan dasbor Advanced"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis dan filter sentimen"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjemahan berdasarkan permintaan"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan tugas dan penetapan sasaran"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan dan kuis"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaminan Kualitas (QA) Zendesk"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ada di Profesional, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutan (lama)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook untuk pemberitahuan langsung tentang kegagalan kritis"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi gudang data"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengembangan integrasi khusus"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajer keberhasilan pelanggan yang berdedikasi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses langsung ke tim produk dan teknisi kami melalui Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ada di Growth Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA untuk cakupan 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan Percakapan berbasis AI untuk memahami data"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengayaan dan pemfilteran data meja bantuan yang disempurnakan dengan AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan: Deteksi otomatis untuk interaksi yang harus ditinjau ulang"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis dan penyaringan sentimen"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelaporan dan wawasan kinerja yang didukung AI"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjemahan percakapan sesuai permintaan"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan pelacakan waktu"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis tingkat kelulusan"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ada di AI Suite, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket implementasi perusahaan termasuk integrasi Salesforce dan Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengembangan integrasi khusus"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi gudang data"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook untuk pemberitahuan langsung tentang kegagalan kritis"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajer keberhasilan pelanggan yang berdedikasi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses langsung ke tim produk dan teknik kami"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses awal dan pengaruh pada peta jalan kami"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas tinjauan otomatis & penetapan sasaran"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemanduan & analisis yang ditargetkan"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis untuk manajemen pembelajaran"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran pengguna & manajemen izin"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyembunyian data sensitif"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi pemfilteran lanjutan dan pencarian percakapan"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor yang dapat disesuaikan dengan beberapa penilaian, bobot yang dapat disesuaikan, dan opsi gagal otomatis"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstensi browser untuk meninjau percakapan di mana saja"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis akar masalah"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelaporan kinerja untuk membandingkan agen, tim, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei CSAT dan analisis umpan balik pelanggan"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ada di Starter, plus"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional (lama)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beberapa skala penilaian per percakapan"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan Percakapan interaktif yang digerakkan oleh AI"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan tugas"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematan untuk pemanduan"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi pemanduan"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis dan penyaringan sentimen"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memfilter berdasarkan kompleksitas percakapan"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wawasan percakapan"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor bersyarat"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis akar masalah"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisis CSAT canggih yang didukung AI"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi penilaian"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstensi browser untuk meninjau percakapan di mana saja"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan untuk menemukan percakapan yang harus ditinjau ulang"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjemahan percakapan sesuai permintaan"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan pelacakan waktu"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket khusus"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (lama)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan percakapan tanpa batas"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sejawat dan sendiri"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor dengan bobot yang dapat disesuaikan dan opsi gagal otomatis"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsi pemfilteran yang kuat"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran tinjauan harian, mingguan, atau bulanan"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei CSAT dan analisis dasar"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laporan untuk membandingkan agen, tim, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran pengguna dan manajemen hak istimewa"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya ingin melakukan perubahan pada langganan Zendesk QA saya.\n\nSorot atau tunjukkan satu opsi, lalu tunjukkan jumlah tempat yang ingin Anda ubah:\nSaya ingin [menambahkan X tempat] [menghapus X tempat] [meningkatkan ke paket Zendesk QA]\n\nSebutkan nama perusahaan Anda dan nama yang terkait dengan akun Zendesk QA Anda, serta detail lainnya yang perlu kami ketahui untuk memproses perubahan ini."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan langganan Zendesk QA."])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya ingin membeli langganan Zendesk QA.\n\nSebutkan nama perusahaan Anda dan nama yang terkait dengan akun Zendesk QA Anda, serta detail lainnya yang perlu kami ketahui untuk memproses permintaan ini:\n\nSekaligus pertanyaan spesifik yang ingin Anda ajukan sebelum membeli:\n\nTerima kasih!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembelian langganan Zendesk QA."])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langganan Anda berakhir di ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " tersisa)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uji coba Anda berakhir pada ", _interpolate(_named("date")), " (Tersisa ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hari"]), _normalize([_interpolate(_named("n")), " hari"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("dayOfMonth")), "setiap bulan."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditagih ke ", _interpolate(_named("card")), " (berakhir pada ", _interpolate(_named("expires")), ") pada ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berakhir di"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kedaluwarsa"]), _normalize(["Kedaluwarsa dalam 1 hari"]), _normalize(["Kedaluwarsa dalam ", _interpolate(_named("expiresIn")), " hari"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Harga awal sudah termasuk ", _interpolate(_named("n")), " lisensi pengguna"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["dimulai pada ", _interpolate(_named("sum")), "/bulan"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulan"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per pengguna / bulan"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per tahun"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per bulan"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiga bulan"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus pembatalan terjadwal"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jika Anda ingin terus menggunakan Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langganan Anda dibatalkan di ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langganan Anda dijadwalkan akan berakhir di ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " hari tanpa biaya tambahan!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digunakan"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda memiliki ", _interpolate(_named("n")), " pengguna. Jumlah kursi tidak bisa lebih rendah dari itu."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Langganan akan dilanjutkan pada ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tahun"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paket Zendesk QA Anda saat ini (", _interpolate(_named("plan")), ") tidak menyertakan fitur yang didukung AI. Untuk mengeluarkan kekuatan penuh dari fitur kecerdasan suara, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk membatalkan langganan Anda, hubungi kami ", _interpolate(_named("link")), " atau hubungi kami melalui chat."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rencana manajemen mutu"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen kualitas"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan diperbarui"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah kartu kredit"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua paket"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penagihan"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat informasi penagihan"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval penagihan"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan langganan"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langganan Anda akan dibatalkan pada akhir periode penagihan."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi kami"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi kami untuk mengedit"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket saat ini"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga khusus"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahunan (Hemat 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahunan"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulanan"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayar setiap tahun"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulanan"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit langganan"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelajahi paket"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji coba gratis"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menagih secara manual"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat meninjau dan mengonfirmasi ringkasan pesanan akhir di langkah berikutnya."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paling populer"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak memiliki metode pembayaran yang aktif."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalam uji coba"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melanjutkan langganan Anda saat ini untuk mengubah paket"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeda langganan"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulanan"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiga bulanan"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahunan"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifkan kembali langganan"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan berlangganan"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempat"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai uji coba sekarang"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibatalkan"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diperpanjang"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijeda"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status langganan"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji Coba"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade langganan"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustom"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohon luangkan waktu satu menit untuk memberikan umpan balik mengenai pengalaman dukungan pelanggan Anda. Terima kasih sebelumnya — ", "{", "{", "agentName", "}", "}", " menghargai umpan balik Anda!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Survei CSAT"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagaimana Anda menilai dukungan yang Anda terima dari ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kami sangat menghargainya. Kami akan menggunakan umpan balik Anda untuk membuat ", _interpolate(_list(0)), " lebih baik untuk semua orang."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang survei"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jenis survei"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini dapat diubah nanti."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukur dan tingkatkan kepuasan pelanggan dengan survei CSAT otomatis dalam berbagai bahasa."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada survei yang dibuat"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat survei"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama survei"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah dibuat"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Coba ubah kata kunci atau ", _interpolate(_named("contact_us")), " jika Anda ingin melihatnya terdaftar di sini"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri tahu kami"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bahasa yang ditemukan"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pilih satu atau lebih bahasa dari daftar di bawah. ", _interpolate(_named("contact_us")), " jika Anda tidak melihat bahasa yang ingin Anda gunakan tercantum di sini."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan bahasa"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri tahu kami"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah bahasa"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Terakhir diterbitkan ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Terakhir disimpan ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " pertanyaan"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan pengantar"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label skala kiri"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perihal email"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label skala kanan"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima kasih"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika survei memiliki terjemahan yang hilang, teks bahasa dasar akan ditampilkan sebagai gantinya"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terapkan terjemahan"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjemahan yang Hilang"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pastikan untuk meninjau semua hasil terjemahan Anda sebelum menerapkannya. Lakukan perubahan pada file yang diunduh, simpan, dan unggah ulang jika diperlukan. Jika token ", _interpolate(_named("token_example")), " tidak muncul sebagai tag, berarti formatnya salah atau berada di bidang yang tidak mendukung token."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau terjemahan"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterjemahkan"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meringkas percakapan dan menampilkan saran untuk komentar."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantuan AI Zendesk QA (dalam bahasa Inggris)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mengedit jenis setelah dibuat"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis ini sudah ada. Pilih nama yang berbeda."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan jenis khusus sebagai opsi yang dapat dipilih di menu tarik-turun Jenis survei, dan kelompokkan semua survei yang telah dibuat di dasbor."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik kata dan tekan Enter untuk menyimpan"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit jenis khusus"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama jenis tidak boleh kosong"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan jenis"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis khusus tidak disimpan. Tekan Enter untuk menyimpannya."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email pengirim"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama pengirim"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sejauh mana Anda setuju dengan pernyataan tersebut: \"", _interpolate(_named("agentName")), " memudahkan saya dalam menangani masalah saya\"?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seberapa mudah mendapatkan bantuan dari ", _interpolate(_named("brandName")), " sejauh ini?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara keseluruhan, seberapa mudahkah menyelesaikan masalah Anda hari ini?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seberapa mudahkah berinteraksi dengan tim kami?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Kebijakan Privasi (opsional)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Syarat layanan (opsional)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bagaimana Anda menilai dukungan yang Anda terima dari ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagaimana layanan kami untuk permintaan ini?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seberapa puaskah Anda dengan perwakilan dukungan?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seberapa puaskah Anda dengan layanan yang Anda terima dari ", _interpolate(_named("agentName")), " hari ini?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan khusus"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tentukan pertanyaan ", _interpolate(_named("type")), " khusus Anda sendiri..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik pertanyaan khusus"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesuaikan URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur pesan email Anda"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit nama jenis pertanyaan khusus akan memperbaruinya di semua survei yang menggunakannya, tanpa memengaruhi data yang dikumpulkan."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit jenis khusus"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perihal email"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan pengantar"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " pertanyaan"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat jenis khusus baru"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Token personalisasi"]), _normalize(["Token personalisasi"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tarik dan jatuhkan token personalisasi ke dalam bidang di bawah ini."]), _normalize(["Tarik dan jatuhkan token personalisasi ke dalam bidang di bawah ini."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Percakapan ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat ini survei CSAT hanya dapat dikirimkan melalui email. Kami berupaya menghadirkan lebih banyak peluang bagi Anda seperti survei CSAT meja bantuan, survei SMS, dan lainnya."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sinkronkan atau perbarui koneksi"]), _normalize(["Sinkronkan atau perbarui koneksi"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinkronkan atau perbarui koneksi ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Koneksi tidak disinkronkan dengan benar"]), _normalize(["Koneksi tidak disinkronkan dengan benar."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus centang pada kotak centang yang relevan ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi sekarang ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah setidaknya satu aturan pengiriman survei untuk mengaktifkan koneksi"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Koneksi ini saat ini dinonaktifkan. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Koneksi ini menyembunyikan data klien yang sensitif. Untuk mengaktifkan pengiriman survei,\n", _interpolate(_named("link")), " dalam pengaturan."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Koneksi ini memerlukan izin tambahan untuk mengirimkan survei.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi sekarang"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA membutuhkan lebih banyak izin untuk Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " dan ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 jam"]), _normalize([_interpolate(_named("n")), " jam"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " menit"]), _normalize(["1 menit"]), _normalize([_interpolate(_named("n")), " menit"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["jam"]), _normalize(["jam"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " untuk mengonfigurasi aturan pengiriman survei."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" hari"]), _normalize(["hari"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan survei kedaluwarsa"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan akan kedaluwarsa dalam"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencegah menjawab survei jika terlalu banyak waktu yang telah berlalu"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dan"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " survei ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisahkan tag dengan koma atau tekan enter setelah menulis. Pastikan masing-masing terdaftar dengan tepat seperti pada Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan kondisi yang mendasari pengiriman survei."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan pengiriman survei"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan pengiriman tindak lanjut survei"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sebuah survei ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " sudah ada.\nSelesaikan konflik dengan memodifikasi aturan di sini atau di ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anda telah menyiapkan koneksi dengan aturan yang cocok untuk survei ", _interpolate(_named("name")), ". Selesaikan konflik dengan memodifikasi aturan di sini atau di bagian ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah aturan lain"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah merek"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kondisi"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari nilai"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah tag"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mencocokkan aturan yang sama"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih nilai"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email dikirim dalam"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindak lanjut email dikirim dalam"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potongan yang disematkan dan tindak lanjut melalui email"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin kode"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lampirkan survei Zendesk QA ke email Anda dengan menambahkan kode potongan ke pengaturan Zendesk Anda."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panduan instalasi"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau potongan"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode potongan disalin ke clipboard"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyalinan potongan gagal"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potongan yang disematkan"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei ini memiliki perubahan yang belum disimpan. Simpan lagi untuk memastikan kode potongan mencerminkan pembaruan terbaru."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah koneksi"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada koneksi yang ditambahkan"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarik email, live chat, panggilan telepon, dan lainnya ke Zendesk QA dengan koneksi Anda"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang pengiriman survei"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan pengiriman survei"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan pengiriman tindak lanjut survei"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah aturan merek"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah aturan"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otentikasi"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + tindak lanjut email"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan akun Intercom Anda dengan Zendesk QA CSAT Survey"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim melalui"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siapa yang mendapatkan survei dan kapan"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit aturan"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diaktifkan"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei dalam aplikasi memungkinkan pelanggan Anda menyelesaikan survei tanpa meninggalkan chat."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meluncurkan Interkom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aturan pengiriman per koneksi"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sebuah survei ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " sudah ada.\nSelesaikan konflik dengan menonaktifkan koneksi atau memodifikasi aturan di sini atau di ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur kondisi yang menjadi dasar pengiriman survei"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dibuat oleh ", _interpolate(_named("name")), " pada ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus bahasa ini berarti bahasa ini tidak akan terlihat lagi oleh responden dan semua teks yang diterjemahkan akan dihapus."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus bahasa"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini tidak dapat diurungkan."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atau, Anda dapat menonaktifkan bahasa dengan menyembunyikannya dari survei. Ini akan memungkinkan Anda untuk menyimpan semua hasil terjemahan Anda tanpa menunjukkannya kepada responden."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terakhir diedit oleh ", _interpolate(_named("name")), " pada ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang bahasa survei"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sebelum menambahkan bahasa lain, pastikan Anda telah menyelesaikan perubahan pada langkah Gaya dan Konten. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah bahasa"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang diterjemahkan"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlihat oleh responden"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unduh file terjemahan"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus bahasa"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" unduh file terjemahan"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau terjemahan"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terjemahan lengkap. ", _interpolate(_named("review_link")), " atau ", _interpolate(_named("download_link")), " untuk melakukan perubahan."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" unduh file terjemahan"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjau apa yang kurang"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beberapa konten belum diterjemahkan dan akan diganti dengan bahasa Inggris. ", _interpolate(_named("review_link")), " atau ", _interpolate(_named("download_link")), " untuk menambahkan terjemahan yang hilang."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan survei"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Survei berisi perubahan yang belum disimpan. ", _interpolate(_named("save")), " untuk mengunduh file terjemahan dengan perubahan terkini."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah file terjemahan"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih skala penilaian yang diinginkan"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memberikan pilihan tarik-turun kepada pelanggan untuk memilih alasan penilaian"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teks skala kiri (opsional)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah alasan"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan tidak boleh kosong"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan ini sudah ada"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan menu tarik-turun untuk alasan skor Anda"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus alasan dan menambahkannya lagi dengan nama yang sama atau mengganti nama alasan selalu membuat entri baru. Akibatnya, hal ini berdampak pada data yang ditampilkan di dasbor Anda."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus alasan"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan yang dihapus tetap ada dalam pelaporan dasbor namun tidak akan terlihat oleh responden survei baru. Hal ini juga berlaku untuk terjemahan terkait."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus alasan"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hapus alasan \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teks skala kanan (opsional)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pada skala 5 poin, 1 dan 2 dianggap sebagai skor negatif; 3 netral; 4-5 positif"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah teks skala"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " skala"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala penilaian"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA sedang mengimpor percakapan Anda. Proses ini mungkin memakan waktu hingga 24 jam."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan digunakan sebagai token personalisasi selama survei berlangsung."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama merek"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tombol dan tautan"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesuaikan tampilan survei Anda"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latar belakang logo"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format JPG, PNG dan GIF didukung"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur Gaya"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unggah logo"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar dengan penuh gaya"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan pesan yang akan dilihat pengguna setelah mengirimkan umpan balik mereka"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan terima kasih"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima kasih"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode warna heksa salah"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat email salah"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang ini tidak boleh kosong"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL salah"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak terlihat:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlihat:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal buat"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei dikirimkan"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei dikirim dalam 30 hari terakhir"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah dihapus"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah diduplikasi"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email pratinjau telah dikirim"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah dipublikasikan"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah diperbarui"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei telah dijeda"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeda"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeda survei"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan email untuk mengirim pratinjau survei"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "email.com Anda"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih bahasa untuk pratinjau"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim email pratinjau"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim contoh survei ke email Anda. Penilaian dari pratinjau survei tidak akan disimpan."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pratinjau survei ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau survei email di web"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melanjutkan"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan sebagai draf"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama merek"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama spesifik merek dapat ditambahkan di langkah Styling"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama pelanggan"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Support"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perwakilan Support"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dihapus"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijeda"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Survei CSAT"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan yang belum disimpan dibuat"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui dan lanjutkan survei"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jika Anda tidak melihat email ini dengan benar ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saya tidak senang dengan dukungan yang saya dapatkan, karena..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didukung oleh Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih alasan"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekap percakapan"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contoh: Pelanggan mengalami masalah dalam memperbarui metode pembayaran. Agen tersebut membagikan sebuah artikel yang dapat membantu. Berhasil."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didukung oleh Zendesk QA AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syarat Layanan"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima kasih atas umpan balik Anda!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa alasan utama dari skor Anda?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beritahu kami mengapa Anda memberikan penilaian ini..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upaya tinggi"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upaya rendah"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klik di sini untuk membuka survei"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privasi"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan khusus Anda muncul di sini"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anda memberi skor pada pengalaman ", _interpolate(_list(0)), " Anda"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim umpan balik"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terima kasih"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berhenti berlangganan"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangat tidak puas"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangat puas"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perhatikan bahwa status survei saat ini dan aturan penyampaiannya tidak akan disertakan dalam duplikat survei"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Survei duplikat “", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda menggunakan potongan email yang disematkan, pastikan untuk menghapusnya dari Zendesk sebelum menjeda survei"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada respons yang dikumpulkan ketika survei dijeda."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeda survei"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan Anda menghapus potongan dari Zendesk sebelum menjeda survei."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus survei"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda menggunakan potongan email yang disematkan, pastikan untuk menghapusnya dari Zendesk sebelum menghapus survei."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini akan menghapus survei secara permanen. Pengguna yang menerima survei masih dapat mengisinya dan semua data terkait survei akan tetap ada di Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus survei ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan Anda menghapus potongan dari Zendesk sebelum menghapus survei."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan yang Anda terapkan akan dilihat oleh responden baru."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan survei"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan Anda menyalin versi terbaru potongan tersemat setelah memublikasikan survei."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda menggunakan potongan email yang disematkan, pastikan untuk menyalinnya ke Zendesk setelah melanjutkan survei."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan mengirimkan survei dan kumpulkan respons."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui dan lanjutkan survei"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan Anda menambahkan potongan tersemat ke Zendesk setelah melanjutkan survei."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui survei"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan Anda menyalin versi terbaru dari potongan yang disematkan setelah memperbarui survei."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin ingin menghapus survei ini? Tindakan ini tidak dapat diurungkan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus survei?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masa uji coba Anda telah kedaluwarsa, tetapi Anda dapat terus menikmati Zendesk QA dengan meningkatkan ke akun penuh."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masa uji coba Anda telah kedaluwarsa, tetapi Anda dapat terus menikmati Zendesk QA dengan meningkatkan ke akun penuh. Bicaralah dengan administrator lokal Anda untuk mengaturnya."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji coba Anda telah berakhir"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade sekarang"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uji coba gratis Anda telah berakhir."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk terus menggunakan versi lengkap Zendesk QA, masukkan detail pembayaran Anda."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk terus menggunakan versi lengkap Zendesk QA, administrator akun Anda perlu memasukkan detail pembayaran."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola pembayaran"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengguna"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dibuat di ", _interpolate(_named("date")), " oleh ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tugas"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepertinya Anda masih mengerjakannya. Anda akan kehilangan semua informasi yang telah Anda tambahkan sejauh ini jika Anda keluar tanpa menyimpan perubahan."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tugas ini akan berulang setiap ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tugas ini akan berulang setiap ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Semua yang ditinjau tercakup dalam satu siklus"]), _normalize(["Semua yang ditinjau tercakup dalam ", _interpolate(_named("nr")), " siklus"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " percakapan"]), _normalize([_interpolate(_named("n")), " percakapan"]), _normalize([_interpolate(_named("n")), " percakapan"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["dari ", _interpolate(_named("nr")), " agen"]), _normalize(["dari ", _interpolate(_named("nr")), " agen"]), _normalize(["dari ", _interpolate(_named("nr")), " agen"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["peserta"]), _normalize(["peserta"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " percakapan per siklus"]), _normalize([_interpolate(_named("n")), " percakapan per siklus"]), _normalize([_interpolate(_named("n")), " percakapan per siklus"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per yang ditinjau secara berurutan"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["yang ditinjau"]), _normalize(["yang ditinjau"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["peninjau"]), _normalize(["peninjau"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna ruang kerja"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna ruang kerja kecuali agen"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siklus ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tidak berulang"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " tugas"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meninjau"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang tugas"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tugas"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini akan menghapus tugas secara permanen dan menghapus datanya dari dasbor. Semua tinjauan yang terkait dengannya akan tetap berada di Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus Tugas ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas dihapus"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas diduplikasi"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada tugas yang ditambahkan"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal membuat tugas"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama tugas"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siklus disegarkan dan daftar tugas baru dibuat. Tinjauan yang sudah diberikan masih diperhitungkan untuk mencapai sasaran."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segera"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pada awal siklus berikutnya pada ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapan mempublikasikan perubahan?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan yang dilakukan pada tugas ini akan dipublikasikan bersama tugas ini"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur tugas sebagai aktif?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berulang pada hari mulai tugas"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumat terakhir setiap bulan"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senin terakhir setiap bulan"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabtu terakhir setiap bulan"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu terakhir setiap bulan"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamis terakhir setiap bulan"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selasa terakhir setiap bulan"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu pertama setiap bulan"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu kedua setiap bulan"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu ketiga setiap bulan"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu keempat setiap bulan"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabu terakhir setiap bulan"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengulangan khusus"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal 1 setiap bulan"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertama"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedua"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketiga"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keempat"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terakhir"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengulang"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari kerja"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulai"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada hasil"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona waktu"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan siklus"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama tugas"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi dasar"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pada awal setiap siklus tugas, daftar percakapan yang telah ditentukan dibuat untuk evaluasi."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan Advanced"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secara otomatis ambil percakapan baru ketika tujuan ulasan tidak terpenuhi"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengambilan otomatis tidak dapat dilakukan dengan sasaran tinjauan persentase (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harus berupa bilangan bulat, bukan desimal"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan penggantian percakapan (Peran Peninjau dan di atasnya)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengganti percakapan"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan pengulas untuk ditugaskan percakapan mereka sendiri untuk ditinjau"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah minimum tinjauan harus minimal 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan tinjauan minimum untuk setiap orang yang ditinjau"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percakapan"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persen (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tinjauan secara total"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap peninjau harus melakukan sejumlah tinjauan yang ditetapkan"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau harus secara kolektif meninjau sejumlah percakapan"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah minimum tinjauan harus minimal 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran peninjauan harus antara 1 – 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran tinjauan"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap orang yang ditinjau harus memiliki jumlah minimum dari total percakapannya yang ditinjau"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau dapat memilih percakapan dari kumpulan bersama"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau ditugaskan percakapan dari kumpulan bersama"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendekatan tugas"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua bot"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua peserta ruang kerja"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna ruang kerja"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah yang ditinjau"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siapa yang akan ditinjau?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih yang ditinjau"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih siapa yang akan ditinjau"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian hanya diperbolehkan jika semua pengguna ruang kerja atau grup dipilih"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau dan yang ditinjau"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Saat ini: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default ruang kerja"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis pengguna mana yang harus dipilih sebagai yang ditinjau?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen paling aktif"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan yang ditinjau (opsional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna yang akan dikecualikan"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan peninjau (opsional)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna yang akan dikecualikan"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna ruang kerja kecuali agen"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah peninjau"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siapa yang akan melakukan tinjauan?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih peninjau"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri tidak diperbolehkan di ruang kerja ini"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan tinjauan sendiri"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri tidak diperbolehkan di ruang kerja ini."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Percakapan yang cocok hari ini"]), _normalize(["Percakapan yang cocok hari ini"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan seolah-olah tugas dimulai hari ini."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angka di masa mendatang mungkin berbeda."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada yang ditinjau dipilih"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada peninjau yang dipilih"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yang ditinjau"]), _normalize(["Yang ditinjau"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Peninjau"]), _normalize(["Peninjau"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengambilan otomatis: diizinkan jika tidak cukup banyak percakapan"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Jumat dari ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Senin dari ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Sabtu dari ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Minggu dari ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Kamis dari ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Selasa dari ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " pada hari Rabu dari ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Setiap hari dari ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dimulai dari ", _interpolate(_named("startDateTime")), " ​​dan berakhir pada ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ketentuan tanggal"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kondisi percakapan dan meja bantuan"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tidak ada percakapan dan ", _interpolate(_named("count")), " kondisi meja bantuan"]), _normalize(["Tidak ada percakapan dan ", _interpolate(_named("count")), " kondisi meja bantuan"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " percakapan dan tidak ada kondisi meja bantuan"]), _normalize([_interpolate(_named("count")), " percakapan dan tidak ada kondisi meja bantuan"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pendekatan: peninjau memilih percakapan"]), _normalize(["Pendekatan: peninjau ditugaskan untuk melakukan percakapan"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbaiki kesalahan yang disorot dalam ringkasan untuk melanjutkan"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran: peninjau harus meninjau semua percakapan"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sasaran: peninjau harus meninjau satu percakapan"]), _normalize(["Sasaran: peninjau harus meninjau ", _interpolate(_named("count")), " percakapan"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sasaran: peninjau harus meninjau ", _interpolate(_named("count")), "% percakapan"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran: tidak ada sasaran tinjauan yang ditetapkan"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran: setiap orang yang ditinjau harus meninjau semua percakapannya"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sasaran: setiap orang yang ditinjau harus meninjau ", _interpolate(_named("count")), " dari total percakapannya"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sasaran: setiap orang yang ditinjau harus meninjau ", _interpolate(_named("count")), "% dari total percakapannya"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sasaran: setiap peninjau harus melakukan satu tinjauan"]), _normalize(["Sasaran: setiap peninjau harus melakukan ", _interpolate(_named("count")), " tinjauan"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mengganti: peninjau tidak dapat mengganti percakapan"]), _normalize(["Mengganti: peninjau dapat mengganti percakapan"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan sendiri: peninjau dapat ditugaskan untuk melakukan percakapannya sendiri"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ketentuan tanggal yang ditetapkan"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada nama tugas yang ditetapkan"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringkasan"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondisi"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " dari ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dua Mingguan"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harian"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulanan"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak pernah"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mingguan"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panjang siklus"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan sebagai Aktif"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan sebagai Draf"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas disimpan sebagai Draf"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penugasan ditetapkan sebagai Tidak Aktif"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan sebagai Tidak Aktif"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tugas"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas dibuat"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai aktif"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai tidak aktif"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pembaruan yang tertunda untuk tugas akan berlaku pada siklus berikutnya, mulai dari ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan perubahan"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak aktif"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertunda"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui tugas"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas diperbarui"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen atau bot menyelesaikan percakapan."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen menutup percakapan dengan sopan"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah percakapan sudah selesai"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis kesalahan tata bahasa, ejaan, dan gaya bahasa dalam interaksi agen dan bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis kesalahan tata bahasa, ejaan, dan gaya agen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis kesalahan tata bahasa, ejaan, dan gaya agen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejaan dan tata bahasa"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis percakapan untuk salam agen dan bot."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk frasa sambutan yang khas"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk mengetahui sambutan yang khas"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen memahami dan mengakui perasaan pelanggan"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengidentifikasi kasus-kasus di mana agen mencerminkan sentimen pelanggan atau menggunakan kata-kata yang menunjukkan kepedulian seperti 'mengakui' dan 'mengenali'"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empati"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen memahami kueri atau kekhawatiran pelanggan"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis apakah agen memahami kueri atau kekhawatiran pelanggan"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemahaman"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seberapa mudah sebuah teks dapat dipahami, dengan mempertimbangkan kompleksitas kata dan panjang kalimat"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seberapa mudah sebuah teks dapat dipahami, dengan mempertimbangkan kompleksitas kata dan panjang kalimat"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesan ini berisi 1 kalimat yang terlalu panjang, sehingga sulit untuk dibaca"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pesan ini berisi ", _interpolate(_list(0)), " kalimat yang terlalu panjang, sehingga sulit untuk dibaca"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keterbacaan"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk mendapatkan solusi yang ditawarkan"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis seluruh percakapan untuk mendapatkan solusi yang ditawarkan"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi yang ditawarkan"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis nada bicara agen sepanjang percakapan"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menganalisis nada bicara agen sepanjang percakapan"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi kesalahan apa pun dari pesan agen"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menentukan kesalahan tata bahasa"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada frasa sambutan yang terdeteksi. Tambah yang baru jika perlu melalui admin Anda di Pengaturan."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menutup percakapan dengan sopan"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada frasa penutup yang terdeteksi. Tambah yang baru jika perlu melalui admin Anda di Pengaturan"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen mencerminkan sentimen pelanggan atau menggunakan kata-kata yang peduli"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi apakah agen mencerminkan sentimen pelanggan atau menggunakan kata-kata yang digunakan untuk peduli"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menggunakan kalimat yang panjang dan kata-kata yang rumit, sehingga berpotensi menyulitkan beberapa orang untuk memahaminya"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menggunakan kata-kata yang rumit, sehingga berpotensi menyulitkan beberapa orang untuk memahaminya"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menggunakan kalimat yang panjang, sehingga berpotensi menyulitkan beberapa orang untuk memahaminya"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menggunakan bahasa yang mudah dimengerti"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak cukup data untuk menilai kategori ini"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen kesulitan memahami pertanyaan/permintaan pelanggan"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen memahami pertanyaan/permintaan pelanggan dengan baik"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi apakah agen memahami pertanyaan/permintaan pelanggan"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada bicara agen selama percakapan"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada bicara agen selama percakapan"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada bicara agen selama percakapan"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada bicara agen selama percakapan"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada bicara agen selama percakapan"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat menentukan nada agen"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan tidak selesai"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan selesai"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi apakah percakapan sudah selesai"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen kurang berempati terhadap pelanggan dan masalah mereka"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menunjukkan empati terhadap pelanggan dan masalah mereka"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi apakah agen menunjukkan empati terhadap pelanggan dan masalah mereka "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan tidak disapa selama percakapan berlangsung"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan disapa selama percakapan berlangsung"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi sambutan khas"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen tidak memberikan solusi kepada pelanggan"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen menawarkan solusi kepada pelanggan"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendeteksi apakah agen menawarkan solusi kepada pelanggan"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata-kata yang kompleks"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalimat panjang"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tata bahasa"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salah eja"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatif"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menunjukkan penyesalan"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenang"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceria"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penasaran"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mendukung"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaya"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 penutup"]), _normalize([_interpolate(_named("n")), " penutup"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA hanya memeriksa penutup yang telah disetujui. Jika kategori ini ditetapkan sebagai kritis, tidak menggunakan penutup yang disetujui akan membuat tinjauan gagal."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup yang disetujui"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penutup yang disetujui"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 pengecualian"]), _normalize([_interpolate(_named("n")), " pengecualian"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata dan frasa yang diabaikan oleh AutoQA sebagai kesalahan ejaan atau tata bahasa."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian ejaan dan tata bahasa"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecualian AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 sambutan"]), _normalize([_interpolate(_named("n")), " sambutan"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA hanya memeriksa sambutan yang telah disetujui. Jika kategori ini ditetapkan sebagai kritis, tidak menggunakan sambutan yang disetujui akan gagal dalam peninjauan."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan yang disetujui"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambutan yang disetujui"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori AutoQA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kategori AutoQA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab yang ditugaskan secara otomatis:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun → Pengaturan"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beberapa kategori memanfaatkan MS Azure, menawarkan berbagai fungsionalitas dan bahasa yang didukung. Tinjau ", _interpolate(_named("link")), " Anda untuk informasi lebih lanjut, atau hubungi admin untuk melakukannya."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Akses daftar kategori yang diperluas dan bahasa yang didukung dengan memanfaatkan MS Azure. Tinjau ", _interpolate(_named("link")), " Anda atau hubungi admin untuk melakukannya."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kategori AutoQA diberi skor secara otomatis, menggunakan skala penilaian tetap. Anda dapat menyesuaikan penilaian secara manual nanti. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kategori AutoQA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skor yang diberikan secara otomatis tergantung pada tingkat keparahan kesalahan dan jumlahnya. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya kategori penilaian relevan yang ditampilkan kepada peninjau. Kategori penilaian ditampilkan berdasarkan kondisi yang telah Anda tetapkan saat membuat kategori."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orientasi"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telepon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pilih jenis kategori penilaian untuk ruang kerja \"", _interpolate(_named("workspace")), "\"."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau harus memilih kartu penilaian yang benar secara manual untuk melihat kategori penilaian yang relevan untuk setiap percakapan."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin ingin mengubah jenis kategori penilaian?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengubah kategori"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengubah jenis kategori penilaian?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian bersyarat"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian statis"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis kategori penilaian"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan kategori penilaian"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan ini tidak dapat diurungkan."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus semua penilaian"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatifnya, Anda menyimpan statistik tanpa menghapus data apa pun dengan mengarsipkan kategori."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saya memahami bahwa ini akan ", _interpolate(_named("deleteAllRatings")), " diberikan untuk kategori ini."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya Nadanya sempurna"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan kategori hanya jika"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Selalu\" akan diabaikan. Ini tidak dapat digabungkan dengan kondisi lain."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selalu"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kondisi"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor kepuasan (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis sumber"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal percakapan"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebuah kategori membutuhkan sebuah nama"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah kategori ke kartu skor"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kategori"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kategori"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian ditambahkan"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori yang diarsipkan tidak terlihat pada kartu skor, tetapi memungkinkan Anda untuk menyimpan statistik tanpa menghapus data apa pun."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori arsip"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arsip ", _interpolate(_list(0)), " kategori"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian diarsipkan"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kategori"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketik \"hapus kategori\" untuk menghapus kategori ini"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hapus kategori"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hapus kategori ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian dihapus"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian diduplikasi"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori penilaian ditambahkan"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori penilaian dihapus"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urutan grup kategori penilaian berubah"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kategori penilaian diperbarui"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian tidak diarsipkan"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian diperbarui"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketika agen gagal dalam kategori kritis, semua kategori dalam tinjauan tersebut gagal secara otomatis."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori penilaian kritis"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori dalam grup ini"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membuat kategori peringkat baru"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit kategori"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus semua kategori dan meninjau data di bawah grup ini"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus grup kategori akan membuat semua kategori yang ditautkan ke grup tersebut tidak dikelompokkan."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus ", _interpolate(_named("categoryName")), " grup kategori"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memilih untuk menghapus kategori di bawah grup akan mengakibatkan hilangnya semua data tinjauan yang terkait dengan kategori tersebut."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama grup"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau dapat memilih lebih dari satu alasan sebagai akar masalah"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau dapat memilih lebih dari satu alasan sebagai akar masalah"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berbagai alasan"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["misalnya Nada"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah grup kategori baru"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup baru"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah \"Lainnya\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah akar masalah"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab ini sudah ada"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah akar masalah"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah akar masalah untuk menjelaskan penilaian"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala penilaian"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diarsipkan"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan kartu skor ke kategori penilaian ini"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diarsipkan"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui kategori"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua penilaian"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditampilkan untuk"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat negatif"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringkat negatif dan netral"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya, perbarui"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori yang lebih penting bagi Anda dapat memiliki bobot lebih dalam penghitungan skor tiket."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Berat (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang kalibrasi"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang kalibrasi"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengadakan sesi kalibrasi rutin memungkinkan peninjau menjaga teknik penilaian mereka tetap selaras dan memastikan agen menerima umpan balik yang konsisten dan tidak memihak pada tingkat yang sama."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat paket"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " adalah proses yang membantu tim Anda tetap memiliki pemahaman yang sama tentang bagaimana semua interaksi pelanggan harus ditangani dan dievaluasi."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profesional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrasi tersedia untuk paket ", _interpolate(_list(0)), " dan ", _interpolate(_list(1)), " kami."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diberikan sebelumnya tidak ditampilkan saat percakapan ditambahkan ke sesi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak terlihat"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diberikan sebelumnya dianggap sebagai tinjauan kalibrasi. Hanya satu tinjauan percakapan per peninjau yang akan ditambahkan ke sesi ini. Tinjauan khusus pesan tidak ditampilkan."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlihat"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilitas tinjauan yang diberikan sebelumnya"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrasi adalah proses yang membantu tim Anda tetap memiliki pemahaman yang sama tentang bagaimana semua interaksi pelanggan harus ditangani dan dievaluasi. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna memiliki akses tak terbatas ke semua tinjauan yang diberikan selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selalu lihat semua tinjauan"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calon Pelanggan"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelah mengirimkan tinjauan, pengguna akan melihat evaluasi yang diberikan oleh peninjau lain selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua tinjauan kalibrasi setelah mengirimkan tinjauan"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna memiliki akses tak terbatas ke semua tinjauan yang diberikan selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selalu lihat semua tinjauan"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajer"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelah mengirimkan tinjauan, pengguna akan melihat evaluasi yang diberikan oleh peninjau lain selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua tinjauan kalibrasi setelah mengirimkan tinjauan"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelah mengirimkan tinjauan, pengguna akan melihat evaluasi yang diberikan oleh peninjau lain selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua tinjauan kalibrasi setelah mengirimkan tinjauan"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna hanya akan melihat tinjauan mereka sendiri yang diberikan selama sesi kalibrasi."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat hanya tinjauan sendiri"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan kalibrasi diperbarui"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilitas tinjauan kalibrasi"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur ambang batas untuk metrik dasbor dengan mengisi celah sesuai dengan sistem metrik internal tim Anda. Tinjau ambang batas pada Dasbor."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrasi"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umum"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambang batas"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambang batas diperbarui"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunggu sebentar..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pastikan Anda memiliki hak akses Admin dan ", _interpolate(_named("retry")), ", atau ", _interpolate(_named("contact")), " untuk bantuan lebih lanjut."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hubungi kami"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepertinya kami tidak dapat memperoleh akses ke API meja bantuan Anda."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coba lagi"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami menghubungkan meja bantuan Anda. Anda akan dialihkan sebentar."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " resmi"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kembali ke ", _interpolate(_list(0)), " Anda untuk tambah koneksi meja bantuan baru."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepertinya Anda melewatkan satu atau dua langkah."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisasi dengan domain email yang sama sudah ada"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halaman koneksi"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sementara itu..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beri kami waktu sejenak untuk memeriksa izin Anda."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sementara itu..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengintegrasikan"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat ruang kerja"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Untuk terus menggunakan Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hampir siap digunakan"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan ruang kerja"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindah akun"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk terus menggunakan Zendesk QA, manajer Anda perlu menugaskan Anda ke ruang kerja."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menghapus akun Anda akan menghapus semua data yang kami miliki tentang Anda secara permanen - ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semua penilaian yang diberikan, konten percakapan, ruang kerja, dll."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah ke Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putuskan koneksi"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memutuskan integrasi akan menonaktifkan semua notifikasi Zendesk QA melalui Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putuskan sambungan Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Slack telah diputuskan dari Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentukan pengaturan pemberitahuan default untuk semua pengguna akun baru."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengganti pengaturan untuk semua pengguna"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda akan mengganti pengaturan pemberitahuan untuk semua pengguna akun"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna tidak akan dapat menentukan pengaturan pemberitahuan mereka sendiri."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan pemberitahuan diperbarui"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan ketika kategori kritis gagal"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peringatan ketika CSAT mendapat respons"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemicu"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat ruang kerja dan data demo"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat ulang data demo dan simpan ruang kerja demo."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus ruang kerja dan data demo"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segarkan data demo"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segarkan data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kelola akun \"", _interpolate(_named("accountName")), "\" mode demo"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode demo"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetap di halaman"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan halaman"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan halaman ini dan kehilangan semua perubahan?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah menyelesaikan tugas"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tugas"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas selesai"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tugas yang ditemukan"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat ini tidak ada tugas yang bisa Anda tinjau."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesuaikan tugas atau tunggu hingga siklus berikutnya dimulai."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobalah menyesuaikan filter"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tugas"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pantau terus halaman ini untuk pembaruan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tugas untuk siklus ini"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditampilkan"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai tugas telah selesai"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah meninjau pengguna ini."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan sendiri dalam tugas ini"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan dengan mengeklik ikon kalibrasi di header tampilan percakapan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada percakapan yang ditambahkan"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batas waktu"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tugas"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat sesi kalibrasi"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas Anda"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas lainnya"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tugas aktif"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tugas"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ditinjau"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua yang ditinjau kecuali saya"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada yang ditinjau ditemukan"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencari yang ditinjau"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dihapus"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua status"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua peninjau"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua peninjau kecuali saya"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditugaskan kepada saya"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada peninjau yang ditemukan"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari peninjau"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " hari"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " jam"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " menit"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna ruang kerja"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau saat ini"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran baru untuk siklus ini"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau baru"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih peninjau"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang percakapan"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang percakapan"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan yang akan dipindahkan"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siklus saat ini"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tugas"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada peninjau yang ditemukan"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari peninjau"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang percakapan kepada saya"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang percakapan ke pengguna lain"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " hari"]), _normalize([_interpolate(_named("n")), " hari"]), _normalize([_interpolate(_named("n")), " hari"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " jam"]), _normalize([_interpolate(_named("n")), " jam"]), _normalize([_interpolate(_named("n")), " jam"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan siklus yang sudah selesai"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan sesi yang sudah selesai"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran Anda"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan alasan untuk menghapus percakapan ini"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan alasan Anda menghapus percakapan ini"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda ingin menyimpan perubahan?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan alasan untuk mengganti percakapan ini"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buang perubahan"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan perubahan"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penggantian tidak ditemukan"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelaskan mengapa Anda mengganti percakapan ini"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda tetap ingin menghapus percakapan ini dari tugas? Ini akan mengurangi sasaran sebanyak 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganti percakapan"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus draf"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit draf"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang kepada saya"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang ke pengguna lain"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugaskan ulang dan meninjau"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus percakapan"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan tinjauan"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan lebih sedikit"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan lebih banyak"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sasaran: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " ditinjau"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan selesai"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan siklus yang telah selesai"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menampilkan sesi yang telah selesai"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu tersisa"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tugas"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan diganti"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beralih daftar tugas"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " kemudian"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " detik"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " detik"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "h"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "h ", _interpolate(_named("hours")), "j"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "h ", _interpolate(_named("hours")), "j ", _interpolate(_named("minutes")), "m"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "h"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " hari"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "h ", _interpolate(_named("minutes")), "m"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "j"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "j ", _interpolate(_named("minutes")), "m"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "j ", _interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "s"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "j ", _interpolate(_named("seconds")), "s"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m ", _interpolate(_named("seconds")), "d"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "bln"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "d"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "t"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baru saja"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda kekurangan izin. Hubungi administrator Anda untuk mendapatkan bantuan."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman yang Anda cari tidak ada."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terjadi kesalahan yang tidak terduga. Hubungi dukungan untuk mendapatkan bantuan."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses ditolak. Tidak diperbolehkan melakukan tindakan itu."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halaman yang Anda cari mungkin tidak ada atau Anda tidak memiliki akses ke halaman tersebut."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda melaju terlalu cepat dan kami kesulitan mengejarnya."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan disimpan"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan yang belum disimpan dibuat"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dan"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " diterapkan"]), _normalize([_interpolate(_named("n")), " diterapkan"]), _normalize([_interpolate(_named("n")), " diterapkan"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terhubung"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat pada"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat oleh"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritis"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selesai"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mantan anggota"]), _normalize(["Mantan anggota"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di sini"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dipilih"]), _normalize([_interpolate(_named("n")), " dipilih"]), _normalize([_interpolate(_named("n")), " dipilih"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terakhir diperbarui"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... dan +1 lagi"]), _normalize(["... dan +", _interpolate(_named("n")), " lagi"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 lagi"]), _normalize(["+", _interpolate(_named("n")), " lagi"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baru"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bukan merupakan"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atau"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dihapus"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " balasan"]), _normalize([_interpolate(_named("n")), " balasan"]), _normalize([_interpolate(_named("n")), " balasan"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hari ini"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pengguna"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jumat"]), _normalize(["Jumat"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Senin"]), _normalize(["Senin"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sabtu"]), _normalize(["Sabtu"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Minggu"]), _normalize(["Minggu"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kamis"]), _normalize(["Kamis"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Selasa"]), _normalize(["Selasa"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rabu"]), _normalize(["Rabu"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kemarin"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Admin"]), _normalize(["Admin"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manajer Akun"]), _normalize(["Manajer Akun"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pengguna"]), _normalize(["Pengguna"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disalin"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari lebih lanjut"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi Anda sudah kedaluwarsa."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang ini tidak boleh kosong."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada item yang ditemukan."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada orang yang cocok."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pemilik"]), _normalize(["Pemilik"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari lebih lanjut"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari lebih lanjut"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari lebih lanjut."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebelum memulai, baca dan setujui persyaratan kami dan kami siap melakukannya."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terkirim"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakin?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dilakukan secara keseluruhan"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diperbarui"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Agen"]), _normalize(["Agen"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Memimpin"]), _normalize(["Memimpin"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Manajer"]), _normalize(["Manajer"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Peninjau"]), _normalize(["Peninjau"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna anonim"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petugas"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segera"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lanjutkan"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik untuk salin"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin tautan"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona bahaya"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tolak"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi (opsional)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi wajib diisi"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonaktifkan"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buang perubahan yang belum disimpan?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifkan"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekuensi"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sasaran"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengerti"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meja bantuan"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak pernah"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikutnya"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemberitahuan"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lainnya"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tingkat lulus"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan pribadi"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebelumnya"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baca lebih sedikit"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim ulang"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulihkan"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang ditinjau"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan diterima/dilihat"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan dilakukan oleh anggota ruang kerja"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan perubahan"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala biner"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skala 3 poin"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala 4 poin"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skala 5 poin"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih frekuensi"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan lebih sedikit"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan lebih banyak"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lewati"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorotan"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langkah"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umpan balik survei"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentang waktu"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan yang belum disimpan dibuat"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbarui"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berat"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anda diundang oleh ", _interpolate(_list(0)), " untuk bergabung dengan Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pengguna secara otomatis terhubung dari Pusat Admin. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola pengguna"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang manajemen pengguna"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beberapa pengguna secara otomatis disediakan dan disinkronkan dari penyedia identitas Anda. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Izin akun “", _interpolate(_list(0)), "” diubah"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang izin pengguna"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jangan tanya saya lagi"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Semua perubahan yang dilakukan pada halaman pengguna direfleksikan ruang kerja. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini berarti akses pengguna ke bagian tertentu dari Zendesk QA dapat berubah"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah izin tingkat akun"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna dihapus"]), _normalize([_interpolate(_named("n")), " pengguna dihapus"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saat Anda menghapus pengguna, mereka akan kehilangan akses ke akun Anda. Tinjauan, komentar, dan penilaian mereka akan tetap ada di Zendesk QA. ", _interpolate(_named("linebreak")), "Menghapus pengguna tidak akan memengaruhi jumlah kursi dalam langganan Anda."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pengguna ini akan dihapus dari semua ruang kerja yang Anda kelola. ", _interpolate(_named("linebreak")), " Tinjauan, komentar, dan penilaian mereka akan tetap utuh."]), _normalize(["Tinjauan, komentar, dan ulasan tersebut akan dihapus dari semua ruang kerja yang Anda kelola. ", _interpolate(_named("linebreak")), " Semua tinjauan, komentar, dan penilaian mereka akan tetap utuh."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hapus ", _interpolate(_named("name"))]), _normalize(["Hapus ", _interpolate(_named("name")), " pengguna"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapat melihat semuanya dan mengelola semua pengaturan dan penagihan"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapat melihat semuanya dan kelola pengaturan personal dan ruang kerja, kecuali penagihan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin akun"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin khusus ruang kerja memungkinkan pengguna melihat (dan) mengedit data dalam ruang kerja tempat mereka berada."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang BotQA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Anda muncul di sini saat percakapan yang mereka ikuti disinkronkan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bot yang disinkronkan"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menandai bot sebagai pengguna akan memberi mereka akses untuk masuk. Data bot historis mereka akan tetap tersedia di dasbor."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bot demikian yang ditemukan"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba cari lagi menggunakan kata kunci atau ejaan lain."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari bot"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan nama bot"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai pengguna"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya admin dan manajer akun yang dapat menandai bot sebagai pengguna"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama bot"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrolan terakhir"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditandai secara manual"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapat ditinjau"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot dihapus dari analisis otomatis dan tugas apa pun. Data baru tidak akan muncul di dasbor, memastikan pelaporan yang akurat dan manajemen bot yang lebih mudah."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot generatif"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diketahui"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot alur kerja"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah ke grup"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua grup"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus dari grup"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih grup"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin akun"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah ruang kerja"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama lengkap"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terakhir aktif"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran tingkat akun"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap pengguna diberi izin tingkat Akun dan/atau Ruang Kerja. Izin admin sekarang berada di tingkat akun, sementara izin lainnya di tingkat Ruang Kerja."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus dari ruang kerja"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin ruang kerja"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin yang dikelola dalam grup"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit detail"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai sebagai bot"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tandai pengguna sebagai bot akan menghapus akses mereka untuk masuk. Data pengguna historis mereka akan tetap tersedia di dasbor."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus bot dari analisis otomatis dan tugas apa pun. Data baru tidak akan muncul di dasbor, memastikan pelaporan yang akurat dan manajemen bot yang lebih mudah."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kecualikan bot dari ulasan"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna ditandai sebagai bot"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya jenis izin Pengguna yang dapat ditandai sebagai bot"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna dengan banyak akun tidak dapat ditandai sebagai bot"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan pengguna"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna, bot, dan ruang kerja"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUANG KERJA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna dan ruang kerja"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada izin"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terhubung"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditambahkan secara manual"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertunda"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undangan tertunda"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tindakan yang diperlukan"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...dan satu lagi"]), _normalize(["...dan ", _interpolate(_named("n")), " lainnya"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah izin ke"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hapus dan undang kembali untuk mengubah peran ", _interpolate(_named("n")), " pengguna undangan yang tertunda."]), _normalize(["Hapus dan undang kembali untuk mengubah peran ", _interpolate(_named("n")), " pengguna undangan yang tertunda"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edit detail untuk ", _interpolate(_named("name"))]), _normalize(["Edit detail untuk ", _interpolate(_named("n")), " pengguna yang dipilih"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna diperbarui."]), _normalize([_interpolate(_named("n")), " pengguna diperbarui."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Grup ini berisi 1 pengguna dari ruang kerja lain."]), _normalize(["Grup ini berisi ", _interpolate(_named("n")), " pengguna dari ruang kerja lain."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktifkan"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grup diarsipkan"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelompok yang diarsipkan"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat grup"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grup \"", _interpolate(_named("name")), "\" dibuat"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup tanpa judul"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus grup ini secara permanen?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus grup"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus grup"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit grup"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemimpin grup"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran Grup"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" kelompok disembunyikan"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup ini tidak akan terlihat di tempat lain di Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya mengelola izin ruang kerja"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup tidak terlihat di Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calon Pelanggan"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup tanpa judul"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup lain sudah memiliki nama ini"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup yang diarsipkan sudah memiliki nama ini"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peran Anda hanya memungkinkan untuk mengelola anggota grup ini. Hubungi pengguna dengan izin yang lebih tinggi untuk perubahan lebih lanjut."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus sebagai pemimpin grup"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grup dipulihkan"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grup disimpan"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditetapkan sebagai pemimpin grup"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grup tidak diarsipkan"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perubahan belum disimpan"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pengguna Anda yang sudah ada ke grup ini"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan nama atau email"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah anggota"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" grup terlihat"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Setiap anggota adalah ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang grup"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beberapa atribut grup secara otomatis disediakan dan disinkronkan dari penyedia identitas Anda."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari tentang Grup"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat Grup"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur anggota tim Anda dengan mudah dan meniru struktur organisasi Anda."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah setidaknya 1 anggota"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah nama grup"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup dengan nama ini sudah ada"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota grup"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama grup"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atur anggota tim Anda dan tiru struktur organisasi Anda."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anggota"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tidak ada grup"]), _normalize(["1 grup"]), _normalize([_interpolate(_named("count")), " grup"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat grup baru atau aktifkan grup yang sudah diarsipkan."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup aktif yang tersedia"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup yang diarsipkan"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup seperti itu yang ditemukan"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coba cari lagi menggunakan kata kunci atau ejaan lain."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada grup yang ditambahkan"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["dalam hal ini"]), _normalize(["dalam"]), _normalize(["dalam"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan nama grup"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diarsipkan"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kirim ulang mungkin ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kursi kiri"]), _normalize([_interpolate(_named("n")), " kursi kiri"]), _normalize([_interpolate(_named("n")), " kursi kiri"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah kursi Anda saat ini terbatas karena langganan Anda sedang dijeda. Aktifkan kembali untuk menambahkan lebih banyak pengguna."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah mencapai batas kursi akun Anda. Anda dapat mengganti pengguna dengan menonaktifkan pengguna aktif atau menambahkan lebih banyak kursi."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat detail pembayaran"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dipilih"]), _normalize([_interpolate(_named("n")), " dipilih"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna dipilih"]), _normalize([_interpolate(_named("n")), " pengguna dipilih"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " baris"]), _normalize([_interpolate(_named("n")), " baris"]), _normalize([_interpolate(_named("n")), " baris"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telusuri pengguna secara massal berdasarkan nama atau alamat email mereka."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempelkan data di sini dengan dipisahkan koma atau baris baru"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna cari massal"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Edit izin ruang kerja untuk ", _interpolate(_named("name"))]), _normalize(["Edit izin ruang kerja untuk ", _interpolate(_named("n")), " anggota yang dipilih"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin edit"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih izin ruang kerja"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hapus ", _interpolate(_named("name")), " dari ruang kerja"]), _normalize(["Hapus ", _interpolate(_named("name")), " anggota dari ruang kerja"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " anggota dihapus"]), _normalize([_interpolate(_named("n")), " anggota dihapus"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hapus anggota"]), _normalize(["Hapus anggota"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pengguna ini akan dikeluarkan dari ruang kerja."]), _normalize(["Pengguna ini akan dikeluarkan dari ruang kerja."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Izin ruang kerja “", _interpolate(_list(0)), "” diubah."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah anggota"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat percakapan dan tinjauan terkait"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semuanya di ruang kerja, namun kelola kuis, tugas, perdebatan, dan sesi kalibrasi saja"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izin ruang kerja"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat dan kelola segala sesuatu di dalam ruang kerja"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua yang ada di ruang kerja, kecuali pengaturan ruang kerja"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hubungkan Pengguna"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna dari ruang kerja yang Anda kelola telah ditambahkan ke ruang kerja ini."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mintalah admin untuk melakukannya."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna baru secara langsung atau minta admin untuk melakukannya."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " atau ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengguna untuk ditampilkan"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " pengguna ditambahkan"]), _normalize([_interpolate(_named("n")), " pengguna ditambahkan"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tambah anggota"]), _normalize(["Tambah anggota"]), _normalize(["Tambah ", _interpolate(_named("n")), " anggota"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih izin ruang kerja"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna ke ruang kerja"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tambah anggota ke ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekatkan email di sini yang dipisahkan dengan baris atau koma baru"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " yang cocok"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dengan pengguna yang sudah terhubung:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tidak dapat mencocokkan ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " email"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periksa ejaan atau sambungkan satu per satu"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dengan pengguna meja bantuan:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna terhubung"]), _normalize([_interpolate(_named("n")), " pengguna terhubung"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna diundang"]), _normalize([_interpolate(_named("n")), " pengguna diundang"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan secara massal"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undangan massal"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misalnya:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terhubung dari meja bantuan Anda untuk meninjau interaksi mereka"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hubungkan pengguna"]), _normalize(["Hubungkan pengguna"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terhubung satu per satu"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika Anda ingin pengguna yang terhubung mengakses Zendesk QA, Anda dapat mengundang mereka di sini."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna yang terhubung"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Undang pengguna"]), _normalize(["Undang pengguna"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lewati undangan"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pengguna yang akan dihubungkan dari meja bantuan Anda untuk meninjau interaksi mereka"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelola langganan Anda"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan pengguna meja bantuan"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berikan nama unik pada ruang kerja Anda"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jika diaktifkan, semua koneksi akun yang ada akan secara otomatis ditambahkan ke ruang kerja yang baru."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah semua koneksi ke ruang kerja ini"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat ruang kerja"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama ruang kerja"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat ruang kerja"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang ditambahkan langsung ke akun (tanpa menetapkan ruang kerja) diberi peran tingkat Akun. Anda dapat menambahkannya ke ruang kerja nanti."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang diundang secara otomatis diberi peran Pengguna di tingkat Akun. Anda dapat upgrade ke peran Admin nanti."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini semua bekerja"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempelkan email di sini dengan dipisahkan dengan baris baru atau koma."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " pengguna diundang melalui email"]), _normalize([_interpolate(_named("n")), " pengguna diundang melalui email"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang ditambahkan ke ruang kerja secara otomatis diberi peran Pengguna di tingkat Akun. Anda dapat upgrade ke peran Admin nanti."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah lainnya"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna hanya ke akun tanpa menambahkan mereka ke ruang kerja"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang diundang hanya diberi izin Akun dan tidak ditambahkan ke ruang kerja mana pun."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna langsung ke ruang kerja dan tetapkan izin ruang kerja"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pengguna ke ruang kerja"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undangan massal"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang satu per satu"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang hanya ke akun"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang ke ruang kerja"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Undang pengguna"]), _normalize(["Undang pengguna"]), _normalize(["Undang ", _interpolate(_named("n")), " pengguna"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna secara massal melalui email"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hapus pengguna"]), _normalize(["Hapus pengguna"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batalkan pilihan"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undang pengguna melalui email"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih izin"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim ulang undangan"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna yang terhubung"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna undangan email tertunda"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih ruang kerja"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agen AI"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusat Administrasi"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaminan kualitas"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen tenaga kerja"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Selesai ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Selesai ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Selesai ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siklus ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tugas"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemat waktu dan siapkan tugas tinjauan otomatis daripada mencari dan mengalokasikan percakapan untuk ditinjau secara manual."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tenggat ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi dapat ditambahkan di bagian 'Kalibrasi' di menu samping tampilan percakapan."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada sesi yang ditambahkan"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dasar"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan dapat ditambahkan ke sesi dengan mengklik ikon kalibrasi di header tampilan percakapan."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada percakapan yang ditambahkan"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tidak ada tinjauan yang diberikan. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinggalkan tinjauan kalibrasi"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akar penyebab"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lompat ke"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagal memuat palet perintah."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan apa pun."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditemukan oleh ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat palet perintah…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halaman, filter, percakapan, pengaturan…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percakapan"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter pribadi"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter publik"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dari ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dari API Anda"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Menampilkan ", _interpolate(_named("rangeStart")), " ke ", _interpolate(_named("rangeEnd")), " dari ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Menampilkan ", _interpolate(_named("rangeStart")), " ke ", _interpolate(_named("rangeEnd")), " dari ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan panel samping"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan panel samping"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alihkan bilah samping"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan diterima"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan ditolak"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan terbuka"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan diterima sebagian"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan selesai"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang dilihat"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diterima"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dibuka"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diterima sebagian"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " dari total"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ditolak"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima sebagian"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tinjauan"]), _normalize(["tinjauan"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah tinjauan mereka yang telah dilihat oleh yang ditinjau"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah tinjauan mereka yang telah dilihat"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peninjau yang disengketakan"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penyengketa"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan berdasarkan kategori"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdebatan dari waktu ke waktu"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu penyelesaian rata-rata"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu penyelesaian rata-rata (jam)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu penyelesaian rata-rata (menit)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diselesaikan"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total perdebatan"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih tagar"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa respons"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bahasa yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua bahasa"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengemudi yang diprediksi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengemudi yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengemudi"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata-kata Wordcloud"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kata yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kata"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebut"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbaru"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlama"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diterima sebagian"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " bereaksi dengan ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditolak"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diselesaikan"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih warna kulit"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesi"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sesi yang ditemukan"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kategori yang ditemukan"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukuran komentar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ukuran yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua ukuran"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada filter yang ditemukan"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup tidak ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua grup"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tagar yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tagar"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada nilai yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua nilai"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada bidang khusus yang ditemukan"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag meja bantuan"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan tag meja bantuan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua tag meja bantuan"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat hasil…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis pertanyaan"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada jenis pertanyaan yang ditemukan"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah reaksi"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu skor"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ditemukan kartu skor"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kartu skor"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada skor yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua skor"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada sumber yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua sumber"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alasan umpan balik"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada alasan yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua alasan"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survei"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada survei yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua survei"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada kanal yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kanal"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada pengguna yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua pengguna"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada ruang kerja yang ditemukan"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua ruang kerja"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Kuis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis telah dihapus atau belum dipublikasikan. Periksa kembali tautan atau navigasikan kembali ke daftar kuis."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis ini belum dibagikan ke ruang kerja Anda. Mintalah admin untuk menambahkan Anda ke ruang kerja yang dapat diakses oleh kuis ini."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis tidak tersedia"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses kuis diberikan ke ruang kerja yang bukan milik Anda. Tidak dapat menyimpan dan mempublikasikan kuis."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minta admin untuk menyimpan dan mempublikasikan kuis."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda harus menandai setidaknya satu jawaban sebagai jawaban yang benar."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidang ini tidak boleh kosong."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat ini Anda sedang melihat pratinjau kuis."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih ruang kerja mana yang dapat mengakses kuis. Pengguna yang tergabung dalam beberapa ruang kerja dapat menjawab kuis hanya satu kali. Memilih \"Semua ruang kerja\" tidak akan menyertakan ruang kerja yang baru dibuat setelah kuis diterbitkan."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akses kuis"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arsipkan"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar dari mode pratinjau"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratinjau"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikuti kuis"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan kuis"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengaturan kuis"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan sebagai draf"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat kuis baru"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak diarsipkan"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opsi"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah opsi"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagikan"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terlihat bagus, bukan?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaban Anda telah dikirimkan"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis Anda telah disimpan"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis Anda telah dipublikasikan"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis Anda telah dihapus"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah pertanyaan"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis arsip"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis yang diarsipkan dan hasilnya tidak akan muncul lagi dalam daftar kuis. Anda masih dapat mengakses hasil kuis yang telah diarsipkan dan membuka arsipnya kapan pun diperlukan."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis arsip"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publikasikan"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelah diterbitkan, kuis tidak dapat diubah atau diedit. Semua kuis yang dipublikasikan akan dapat diakses oleh setiap pengguna di akun Anda."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbitkan kuis?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apakah Anda yakin ingin menghapus kuis ini? Tindakan ini tidak dapat diurungkan."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kuis?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setelah dikirim, jawaban Anda tidak dapat diubah."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim kuis?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis yang tidak diarsipkan"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghapus arsip kuis membuatnya tersedia di ikhtisar kuis. Ini akan memungkinkan peserta sebelumnya untuk mengakses hasil mereka dan peserta baru untuk mengikuti kuis."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis yang tidak diarsipkan"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan duplikat"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi (opsional)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindahkan pertanyaan ke bawah"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindahkan pertanyaan ke atas"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertanyaan"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanya pertanyaan dengan jawaban yang ditampilkan dalam pratinjau."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus pertanyaan"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim kuis"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis tanpa judul"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul kuis"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan menghapus kuis dan hasilnya secara permanen."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atau, Anda masih dapat mengakses hasilnya dengan mengarsipkan kuis."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijawab"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus kuis"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini akan menghapus kuis dan hasilnya secara permanen."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hapus kuis ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikat"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis membantu meningkatkan kepercayaan diri dan keterampilan tim, memberikan pengalaman yang konsisten, dan merekrut anggota tim baru."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mencari kuis berdasarkan nama kuis atau nama penulis."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada kuis yang dibuat"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspor daftar kuis"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor rata-rata"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal selesai"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis/Penulis"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis / Penulis / Ruang Kerja"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peserta"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipublikasikan"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang kerja"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan kuis, penulis"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diarsipkan"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draf"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua kuis"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis telah diarsipkan."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis telah dibatalkan pengarsipannya."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis ini tidak mendapat respons dari pengguna di ruang kerja yang dipilih."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis ini tidak mendapat respons sebelum diarsipkan."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada respons"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di sini Anda akan melihat uraian jawaban kuis.\nBelum ada yang mengisi kuis Anda - membagikannya pasti akan membantu."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum ada respons"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor rata-rata:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milik Anda"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikhtisar"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - Salin"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautan disalin"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada hasil ditemukan"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Kuis"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat mencari nama peserta di papan peringkat."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papan peringkat ekspor"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengguna"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skor"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari berdasarkan peserta"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papan peringkat"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuis"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alihkan papan peringkat"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini berarti Anda tidak akan dapat memfilter tagar ini di dasbor dan tidak lagi melengkapi komentar secara otomatis. Tindakan ini tidak dapat diubah."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar, tagar, tagar, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tagar"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tagar"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat tagar"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izinkan anggota untuk membuat tagar baru"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagar"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digunakan"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus tagar"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hapus tagar ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuatan tagar terkunci"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembuatan tagar tidak terkunci"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tagar '", _interpolate(_list(0)), "' dihapus"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tagar '", _interpolate(_list(0)), "' diubah namanya menjadi '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 tagar ditambahkan"]), _normalize([_interpolate(_named("count")), " tagar ditambahkan"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama baru"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tagar yang dibuat"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah nama"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ganti nama tagar \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisahkan tagar dengan koma atau jeda baris"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari tagar"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jangan khawatir. Anda dapat melakukannya ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di sini"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi yang terhubung ke ruang kerja"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasang koneksi"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lampirkan koneksi ke ruang kerja ini"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koneksi"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi admin Anda untuk melakukan perubahan di sini"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Zendesk QA untuk membuat perubahan di sini"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada koneksi berdasarkan akun"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepertinya Anda belum terintegrasi dengan platform layanan pelanggan."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Menghapus koneksi akan menghapus percakapan dari ruang kerja ", _interpolate(_named("workspaceName")), " dan menghapus semua data yang terkait dengannya, termasuk tinjauan dan perdebatan."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hapus koneksi"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hapus koneksi ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih koneksi"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dalam periode waktu yang dipilih, kategori AutoQA tertentu yang aktif tidak ada di kartu skor Anda. Percakapan sebelum perubahan terakhir Anda pada ", _interpolate(_named("date")), ", mungkin belum dianalisis untuk kategori ini."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di sinilah semua umpan balik yang diberikan kepada Anda akan muncul. Belum ada yang bisa ditampilkan."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan yang diterima."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan hanya aktivitas komentar"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di sinilah semua umpan balik yang Anda berikan akan muncul. Memulai dalam tampilan Percakapan."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak ada tinjauan yang diberikan."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diberikan"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinjauan yang diterima"])}
  }
}