import { capitalize } from 'lodash-es'
import i18n from '@/i18n'
import type { Option } from '@/modules/survey/types'

export const getTypeLabel = (type: string) => {
  return type === 'CONNECTION' ? capitalize(type) : i18n.t(`filter.option.label.ticket_${type.toLowerCase()}`)
}

export const getFieldLabel = (values: Option[]) => {
  const length = values.length
  if (!length) return null
  if (length === 1) return values[0].label
  return i18n.t('universal.items_selected', length)
}
