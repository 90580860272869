export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนโดเมนย่อย Zendesk ของคุณ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริษัทของคุณมีบัญชีอยู่แล้ว"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าร่วมบริษัท"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการเข้าร่วมบริษัทที่มีอยู่ โปรดขอให้ผู้ดูแลระบบส่งคําเชิญถึงคุณ"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอให้ผู้ดูแลระบบเชิญคุณ"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันเปลี่ยนใจ"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการลงทะเบียน"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริษัทของคุณมีบัญชีอยู่แล้ว คุณต้องการเข้าร่วมบัญชีของบริษัทของคุณหรือสร้างบัญชีใหม่"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกคําแนะนํา"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างบัญชีใหม่"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบัญชีอยู่แล้ว"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่สามารถเข้าถึงบัญชี QA ของ Zendesk ได้จนกว่าจะมีการชําระใบแจ้งหนี้การสมัครใช้บริการที่ค้างชําระ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการของคุณถูกระงับ"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบบัญชี ลองอีกครั้ง"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรหัสผ่านใหม่"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลองอีกครั้ง หรือ ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลและรหัสผ่านรวมกันไม่ถูกต้อง"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอใหม่จากผู้ดูแลระบบของคุณ"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์คําเชิญนี้ไม่ถูกต้องหรือถูกใช้ไปแล้ว"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["วิธีการลงชื่อเข้าใช้ ", _interpolate(_named("method")), " ถูกปิดใช้งานในบัญชีของคุณ"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําเชิญนี้ไม่ถูกต้องหรือถูกใช้แล้ว ขอใหม่จากผู้ดูแลระบบของคุณ"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โหลดข้อมูลคําเชิญไม่สําเร็จ"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลงทะเบียนล้มเหลว อาจมีผู้ใช้ที่มีอีเมลนี้อยู่แล้ว"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีของคุณถูกบล็อกหลังจากพยายามลงชื่อเข้าใช้ติดต่อกันหลายครั้ง ตรวจสอบกล่องจดหมายของคุณเพื่อดูอีเมลพร้อมคําแนะนําเกี่ยวกับวิธีปลดบล็อก"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันอีเมลของคุณก่อน"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนบัญชีอีเมลที่ถูกต้อง"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องระบุฟิลด์นี้"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องระบุฟิลด์นี้"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ต"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสลงชื่อเข้าใช้"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งลิงก์มหัศจรรย์"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียน"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่ใช้ในการทำงาน (อีเมลของบริษัท) "])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่ใช้ในการทำงาน (อีเมลของบริษัท) ของคุณ"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/th/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อตกลงของการให้บริการหลัก"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่านไม่ตรงกับข้อกําหนด"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำชี้แจงความเป็นส่วนตัว"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/th/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดี๋ยวก่อน"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สวัสดี ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เข้าร่วมองค์กร ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลของคุณได้รับการยืนยันแล้ว คุณสามารถลงชื่อเข้าใช้ด้วยบัญชีใหม่ของคุณที่ด้านล่างได้แล้ว"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อด้วย Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์คําเชิญไม่ถูกต้อง"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้โดยใช้ลิงก์มหัศจรรย์"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ส่วนลงชื่อเข้าใช้"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดําเนินการต่อด้วย Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อด้วย Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลของคุณได้รับการยืนยันแล้ว ตอนนี้คุณสามารถลงชื่อเข้าใช้ด้วยบัญชีใหม่ของคุณด้านล่าง:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ด้วย Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ผ่านลิงก์มหัศจรรย์"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ด้วย Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ QA ของ Zendesk"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีของคุณถูกลบไปแล้ว"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีบัญชีหรือ"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนรหัสที่ถูกต้อง"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องระบุฟิลด์นี้"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนบัญชีอีเมลที่ถูกต้อง"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องระบุฟิลด์นี้"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสลงชื่อเข้าใช้"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูตัวเลือกการลงชื่อเข้าใช้ทั้งหมด"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " ด้วยวิธีการแบบดั้งเดิม"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้โดยใช้ลิงก์มหัศจรรย์"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รับอีเมลที่มีรหัสลงชื่อเข้าใช้ของคุณ"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รับอีเมลที่คุณสามารถติดตามเพื่อลงชื่อเข้าใช้ได้"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดในการส่งลิงก์มหัศจรรย์ ลองอีกครั้ง"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ผ่านอีเมล"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่าน"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักขระตัวพิมพ์เล็กหนึ่งตัว"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักขระตัวพิมพ์ใหญ่หนึ่งตัว"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่านใหม่ต้องมี"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความยาวรหัสผ่านสูงสุดคือ 128 ตัวอักขระ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จําง่าย เดายาก"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักขระขั้นต่ำ 8 ตัว"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่านของคุณ"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากลืมรหัสผ่าน"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักขระตัวพิมพ์เล็กหนึ่งตัว"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลขหนึ่งตัว"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักขระตัวพิมพ์ใหญ่หนึ่งตัว"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการรีเซ็ตรหัสผ่านของคุณ ให้ป้อนที่อยู่อีเมลของคุณแล้วเราจะส่งอีเมลที่มีคำแนะนำให้คุณ"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนบัญชีอีเมลที่ถูกต้อง"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำเป็นต้องระบุฟิลด์นี้"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ตรหัสผ่าน"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ระบบได้ส่งอีเมลไปที่ ", _interpolate(_named("email")), " แล้ว"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากอีเมลไม่ปรากฏในกล่องจดหมายหรือโฟลเดอร์สแปมของคุณ โปรดตรวจสอบอีเมลที่คุณป้อน"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ลงชื่อเข้าใช้"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบอีเมลของคุณ"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ตรหัสผ่าน"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), " จากนั้นคุณจะได้รับอีเมลรีเซ็ตรหัสผ่าน"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากบัญชีของคุณมีรหัสผ่านอยู่แล้ว"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากเป็นเช่นนั้น ", _interpolate(_named("bold")), " เราได้ส่งลิงก์รีเซ็ตรหัสผ่านให้คุณแล้ว"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่เชื่อมโยงกับบัญชี"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํารหัสผ่านของคุณได้หรือไม่"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดในการส่งลิงก์รีเซ็ต ลองอีกครั้ง"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ตรหัสผ่านของคุณ"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนโดยใช้เมจิกลิงก์"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายความเป็นส่วนตัว"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลงทะเบียนแสดงว่าคุณได้ยอมรับใน ", _interpolate(_named("terms_of_service")), " และ ", _interpolate(_named("privacy_policy")), " ของ QA ของ Zendesk"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดการใช้บริการ"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบัญชีแล้วใช่หรือไม่"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนผ่านลิงก์มหัศจรรย์"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนด้วยอีเมลที่ทํางานของคุณ"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนด้วย Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนกับ QA ของ Zendesk"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนด้วย Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันมีบัญชี Zendesk ที่ใช้งานอยู่"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลงทะเบียนหรือลงชื่อเข้าใช้เพื่อตั้งค่า ", _interpolate(_named("direct_install_name")), " ของคุณให้เสร็จสิ้น"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่คุณจริงๆ หรือไม่ ตรวจสอบกล่องจดหมายของคุณสําหรับอีเมลยืนยัน"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณพร้อมทำงานแล้ว"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk ทํางานได้ดีที่สุดบนเดสก์ท็อป ลงชื่อเข้าใช้ที่นั่นเพื่อเริ่มรีวิวการสนทนา"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จองการสาธิต"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือจองการสาธิตกับเรา แล้วเราจะแสดงให้คุณเห็นว่าคุณสามารถทําอะไรได้บ้างใน QA ของ Zendesk"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีการร้องขอการแสดงตนของคุณ"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ฉันเปลี่ยนใจและต้องการ ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " ได้เชิญคุณเข้าร่วมบัญชีของพวกเขา"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าร่วมบัญชี"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างบัญชีใหม่"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองลงชื่อเข้าใช้อีกครั้ง"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การตอบสนองที่คุณได้รับสําหรับฟีดแบ็คของคุณ: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนองที่ได้รับ"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ได้ตอบสนองต่อข้อคิดเห็นของคุณ"]), _normalize(["หลายคนได้ตอบสนองต่อข้อคิดเห็นของคุณ"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ได้ตอบสนองต่อการตอบกลับของคุณ"]), _normalize(["หลายคนได้ตอบสนองต่อการตอบกลับของคุณ"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ได้ตอบสนองต่อรีวิวของคุณ"]), _normalize(["หลายคนได้ตอบสนองต่อรีวิวของคุณ"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับ"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้ให้"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คแบบสำรวจ"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนอง"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่เริ่มต้น"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายข้อคิดเห็นว่าอ่านแล้วหรือไม่"]), _normalize(["ทําเครื่องหมายข้อคิดเห็นทั้งหมดว่าอ่านแล้วหรือไม่"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายการโต้แย้งว่าอ่านแล้วหรือไม่"]), _normalize(["ทําเครื่องหมายการโต้แย้งทั้งหมดว่าอ่านแล้วหรือไม่"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายฟีดแบ็คแบบสํารวจว่าอ่านแล้วหรือไม่"]), _normalize(["ทําเครื่องหมายฟีดแบ็คแบบสํารวจทั้งหมดว่าอ่านแล้วหรือไม่"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายรีวิวว่าอ่านแล้วหรือไม่"]), _normalize(["ทําเครื่องหมายรีวิวทั้งหมดว่าอ่านแล้วหรือไม่"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายข้อคิดเห็นว่าอ่านแล้ว"]), _normalize(["ทําเครื่องหมายข้อคิดเห็นทั้งหมดว่าอ่านแล้ว"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายการโต้แย้งว่าอ่านแล้ว"]), _normalize(["ทําเครื่องหมายการโต้แย้งทั้งหมดว่าอ่านแล้ว"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายฟีดแบ็คแบบสํารวจว่าอ่านแล้ว"]), _normalize(["ทําเครื่องหมายฟีดแบ็คแบบสํารวจทั้งหมดว่าอ่านแล้ว"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ทําเครื่องหมายรีวิวว่าอ่านแล้ว"]), _normalize(["ทําเครื่องหมายรีวิวทั้งหมดว่าอ่านแล้ว"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำเครื่องหมายทั้งหมดว่าอ่านแล้ว"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำเครื่องหมายว่าอ่านแล้ว"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อมีคนโต้แย้งการรีวิว จะปรากฏที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้ง"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อการโต้แย้งถูกมอบหมายให้กับคุณ จะปรากฏที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่ได้รับ"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อคุณมีการโต้แย้งรีวิว จะแสดงที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่เริ่ม"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คทั้งหมดที่ได้ให้คุณจะแสดงที่นี่ ยังไม่มีอะไรให้แสดง"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีฟีดแบ็คที่ได้รับ"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนองทั้งหมดที่ได้ให้กับรีวิวและการตอบกลับของคุณจะแสดงที่นี่ ยังไม่มีอะไรให้แสดง"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการตอบสนองที่ได้รับ"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดการสนทนา"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คทั้งหมดที่ได้รับจากคุณจบแสดงที่นี่ เริ่มต้นด้วยการให้รีวิวในมุมมองการสนทนา"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวที่ได้รับ"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คทั้งหมดที่ได้ให้คุณจะแสดงที่นี่ ยังไม่มีอะไรให้แสดง"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวที่ได้รับ"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อคิดเห็น"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นทั้งหมดที่คุณได้ให้เป็นข้อคิดเห็นจะแสดงที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อคิดเห็นที่ได้ให้"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อคิดเห็น"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นทั้งหมดภายใต้รีวิวที่คุณเป็นเจ้าของ ", "@", "กล่าวถึง หรือการตอบกลับข้อคิดเห็นของคุณจะแสดงที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไมมีข้อคิดเห็นที่ได้รับ"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่ยอมรับ"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่เปิดอยู่"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่ถูกปฏิเสธ"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโต้แย้งที่แก้ไขแล้ว"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองอีกครั้งโดยเลือกตัวเลือกอื่นจากกรองด้านบน"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองอีกครั้งโดยเลือกผู้ใช้รายอื่นจากกรองด้านบน"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นที่ได้ให้"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นที่ได้รับ"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งทั้งหมด"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่ได้รับ"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่เริ่ม"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้รับฟีดแบ็คแบบสำรวจ"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้ให้"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการกิจกรรม"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ได้รีวิวแล้ว"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ได้รีวิวแล้ว"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ได้รีวิวแล้ว"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รีสตาร์ทใน ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " ที่เหลือ"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าการมอบหมาย"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการการมอบหมาย"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่สามารถสร้างเซสชั่นการฝึกสอนระหว่าง ", _interpolate(_list(0)), " และ ", _interpolate(_list(1)), " เนื่องจากขณะนี้ไม่ได้อยู่ในพื้นที่ทํางานเดียวกัน"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เซสชั่นการฝึกสอนระหว่าง ", _interpolate(_list(0)), " และ ", _interpolate(_list(1)), " มีอยู่แล้ว ติดต่อ ", _interpolate(_list(0)), " เพื่อเข้าถึงเซสชั่น"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนะนำ"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เซสชั่นใหม่ระหว่าง ", _interpolate(_list(0)), " และ ", _interpolate(_list(1)), " ถูกสร้างขึ้น"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ฝึกสอน"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ฝึกสอน"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการฝึกสอน"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้รับการฝึกสอน"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่น"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่น"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเซสชั่น"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเซสชั่น"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนะนำ"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลยกำหนด"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อดีต"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังจะมาถึง"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่น"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเซสชั่น"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาการรีวิวโดยเฉลี่ย"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนแปลง"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลที่จะแสดงสําหรับช่วงเวลาที่เลือก"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนน"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เพิ่มการปักหมุดในเซสชั่นแล้ว ", _interpolate(_named("buttonStart")), "แสดงให้ฉันเห็น", _interpolate(_named("buttonEnd"))])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยัง..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มแล้ว"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมาถึงจุดสิ้นสุดของการปักหมุดจากช่วงเวลาที่เลือกแล้ว"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ดูว่าการปักหมุดทํางานอย่างไรใน ", _interpolate(_named("link")), " นี้"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทความ"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ดูเหมือนว่าคุณไม่ได้ปักหมุดอะไรไว้ภายใต้ ", _interpolate(_list(0)), " คุณสามารถใช้ปักหมุดเพื่อเตรียมพร้อมสําหรับรายการแบบ 1:1 หรือบันทึกบางสิ่งไว้สําหรับเวลาอื่น"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการฝึกสอน"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการปักหมุดถูกเพิ่ม"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["นี่คือการปักหมุดทั้งหมดที่คุณสร้างขึ้นภายใต้ ", _interpolate(_named("name")), " การปักหมุดที่เพิ่มไปยังเซสชันจะเป็น ", _interpolate(_named("bold")), " กับผู้เข้าร่วมคนอื่นๆ"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์ทันทีแล้ว"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ดูเหมือนว่า ", _interpolate(_list(0)), " จะไม่ได้รับฟีดแบ็คใดๆ สําหรับการรีวิวการสนทนาหรือ CSAT ในช่วงเวลาที่เลือก"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีฟีดแบ็ค"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมาถึงจุดสิ้นสุดของข้อคิดเห็นจากช่วงเวลาที่เลือกแล้ว"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็ค"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมตริก"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุด"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["หมายเลขของ ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการการดำเนินการใหม่"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เสร็จสมบูรณ์โดย ", _interpolate(_named("user")), " เมื่อ ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["โดย ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณ"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์เพื่อเพิ่มรายการการดำเนินการใหม่"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีอะไรที่ได้จากการประชุมครั้งนี้บ้าง ขั้นตอนถัดไปของคุณคืออะไร"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการการดําเนินการ"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างโดย ", _interpolate(_named("name")), " ในวันที่ ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเซสชั่น"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การดำเนินการนี้จะ ", _interpolate(_named("deleteSession")), " อย่างถาวร ผู้เข้าร่วมจะไม่สามารถเข้าถึงเซสชั่น รวมถึงเนื้อหาและบันทึกส่วนตัวได้"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เซสชั่นถูกสร้างเมื่อ ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไขล่าสุดโดย ", _interpolate(_named("name")), " เมื่อ ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเด็นการพูดคุยและหมายเหตุ"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับปรุงหมายเหตุแล้ว"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากเซสชั่น"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่เลือกสามารถเข้าถึงรายละเอียดเซสชั่นได้ตั้งแต่จุดนี้เป็นต้นไป"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์แล้ว"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์เซสชั่น"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["คุณจะไม่เป็นส่วนหนึ่งของเซสชั่น ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " อีกต่อไป"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " จะไม่เป็นส่วนหนึ่งของเซสชั่นนี้อีกต่อไป"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " มีสิทธิ์เข้าถึงเซสชั่นนี้ได้แล้ว"]), _normalize([_interpolate(_named("n")), " ผู้ใช้ใหม่มีสิทธิ์เข้าถึงเซสชั่นนี้ได้แล้ว"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเซสชั่น"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเซสชั่นทั้งหมด"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเซสชั่น"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ฝึกสอน"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["หมายเหตุที่เพิ่มโดย ", _interpolate(_list(0)), " จะปรากฏที่นี่"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มรายการที่ปักหมุดเป็นประเด็นการสนทนาจากแผงด้านข้างขวา"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุด"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จดบันทึกเฉพาะสิ่งที่คุณสามารถเห็น..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุส่วนบุคคล"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตหมายเหตุส่วนตัว"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 วัน"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ฝึกสอน"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการฝึกสอน"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการการดําเนินการ"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นล่าสุด"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการอยู่"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลยกำหนด"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวมเซสชั่น"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูทั้งหมด"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉัน: ผู้ฝึกสอน"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉัน: ผู้รับการฝึกสอน"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นทั้งหมด"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นที่แชร์"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองสลับตัวกรองเพื่อดูรายการการดำเนินการ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรายการการดําเนินการ"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการการดําเนินการ"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับและมองเห็น"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้รับ · มองเห็น"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคืบหน้าหลังเซสชั่น"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าเจ้าหน้าที่จะไม่ได้รับฟีดแบ็คใดๆ"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบว่าคุณมีชื่อของผู้ใช้ที่ถูกต้อง"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้ใช้รายนี้"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS ใน30 วันที่ผ่านมา"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การดําเนินการนี้จะ<b>ลบเซสชั่นทั้งหมด</b> (2) อย่างถาวรพร้อม <b>", _interpolate(_named("name")), "</b> ่ผู้เข้าร่วมจะไม่สามารถเข้าถึงเซสชั่นทั้งหมดได้ รวมถึงเนื้อหาและบันทึกส่วนตัว"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมาถึงจุดสิ้นสุดของเซสชั่นทั้งหมดแล้ว"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นการฝึกสอนเป็นกิจกรรมกลุ่มระหว่างที่ปรึกษาและผู้รับการฝึกสอนเพื่อหารือเกี่ยวกับประสิทธิภาพโอกาสเป้าหมายระยะสั้นและระยะยาว"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถค้นหาเซสชันได้ทั้งด้วยชื่อผู้ฝึกสอนและบุคคลที่ได้รับการฝึกสอน"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเซสชั่น"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการสร้างเซสชั่น"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบประวัติการฝึกสอน"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบประวัติการฝึกสอน "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นล่าสุด"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นทั้งหมด"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ฝึกสอน"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการฝึกสอน"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำเครื่องหมายว่าเสร็จสิ้น"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายว่าเลิกทำ"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามชื่อ"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออก"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนชื่อ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังฝึกสอน"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการเซสชั่น"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้กําหนดเวลาไว้"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับข้อความนี้"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังฝึกสอน"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นการปรับเทียบที่หมดอายุ ไม่สามารถให้การรีวิวเพิ่มเติมได้"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้ให้รีวิวสําหรับการสนทนานี้แล้ว"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวการสนทนานี้ไม่สามารถทําได้อีกต่อไปเนื่องจากวันครบกําหนดของเซสชั่นได้ผ่านไปแล้ว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้ไม่มีรีวิว"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดแผงควบคุม"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้รีวิวการปรับเทียบ"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลสรุป"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทรานสคริปต์"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ผู้พูด ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้า"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " วินาที"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขลําโพง"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความรีวิว"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับเพื่อเล่นข้อความ"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้พูด"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทรานสคริปต์"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางาน"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อก่อนที่จะสร้างตัวกรอง"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีบอท"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างเมื่อเวลา ", _interpolate(_named("time")), " โดย ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อัปเดตล่าสุดเมื่อ ", _interpolate(_named("time")), " โดย ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ดูเหมือนว่าคุณไม่มีตัวกรอง ในการเข้าถึงหน้านี้ คุณต้องสร้างตัวกรองก่อน เริ่มต้นด้วย ", _interpolate(_named("recommendedFilter")), " เพื่อดูการสนทนาของคุณเอง สร้างตัวกรองด้านล่างเพื่อดําเนินการต่อ"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในช่วง 30 วันที่ผ่านมา"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข \"สร้างเมื่อ 30 วันที่ผ่านมา\" จะถูกเพิ่มไปยังตัวกรองที่สร้างขึ้นโดยไม่มีช่วงวันที่"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองไม่พร้อมใช้งานในแผนนี้และจะไม่ถูกนํามาพิจารณาเมื่อแสดงผลลัพธ์"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมดเวลาการดึงตัวเลือกตัวกรอง ลองอีกครั้ง"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการกรอง"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย = บุคคลที่ลงชื่อเข้าใช้"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["การสนทนาที่พบ: ", _interpolate(_named("count"))]), _normalize(["การสนทนาที่พบ: ", _interpolate(_named("count"))]), _normalize(["การสนทนาที่พบ: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พร้อมใช้งานในแผนปัจจุบันของคุณ อัปเกรดเพื่อใช้คุณสมบัตินี้"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเงื่อนไข"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดย QA ของ Zendesk AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนเป็นการมอบหมาย"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างตัวกรอง"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างตัวกรอง"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์ที่กำหนดเอง"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรากําลังโหลดตัวเลือกตัวกรองตามการตั้งค่าเฉพาะของคุณ การดําเนินการนี้อาจใช้เวลาสักครู่สำหรับการโหลดครั้งแรก"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งชื่อที่สื่อความหมายให้กับตัวกรองของคุณ"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตัวกรอง"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกตัวเลือก"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนตัว"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาธารณะ"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขตัวกรอง"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการเปลี่ยนแปลง"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีค่าที่ใช้ได้"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลด..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบ"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกค่า"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมองเห็นได้"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะเซสชั่นที่ใช้งานอยู่ ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเซสชั่นที่เก่ากว่า ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันครบกำหนด"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขเซสชั่นการปรับเทียบ"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่น"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่นการปรับเทียบ"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวการปรับเทียบทั้งหมด ที่ให้ไว้ในระหว่างเซสชั่นจะถูกลบอย่างถาวร"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเซสชั่น"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบเซสชั่นการปรับเทียบ ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสร้างเซสชั่น"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเซสชั่นที่ใช้งานอยู่"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเซสชั่นถูกสร้าง"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเซสชั่นที่ใช้งานอยู่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มตัวกรอง"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างตัวกรองส่วนตัว"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างตัวกรองสาธารณะ"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มตัวกรองแล้ว"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองจะถูกลบอย่างถาวร"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบตัวกรอง"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบตัวกรอง ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งทั้งหมด"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งสําหรับฉัน"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งโดยฉัน"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่เปิดอยู่"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีตัวกรองถูกเพิ่ม"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีตัวกรองส่วนตัวถูกสร้าง"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีตัวกรองสาธารณะถูกสร้าง"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองส่วนตัว"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองสาธารณะ"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ต้องการ ", _interpolate(_named("cta")), " หรือไม่"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกไว้"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่คือมุมมองของตัวกรองชั่วคราว"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังสร้างตัวกรองของคุณ..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตตัวกรองแล้ว"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึก"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติที่กําหนดเอง"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อัปเดตล่าสุด ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําที่ซับซ้อน"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวอัตโนมัติจะอัปเดตแบบไดนามิกตามการเปลี่ยนแปลงใบสั่งงาน"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไฮไลต์ในการสนทนา"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประโยคยาว"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการทํานายคะแนนอัตโนมัติ"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจไม่พบข้อผิดพลาดใดๆ"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงคําที่ซับซ้อน"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงประโยคยาว"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาถัดไป"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติไม่สามารถให้คะแนนหมวดหมู่นี้ได้เนื่องจากบริบทไม่เพียงพอ แนะนําให้ตรวจสอบด้วยตนเอง"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นพบข้อมูลเชิงลึกของการสนทนาอัตโนมัติเพื่อปรับปรุงกระบวนการรีวิวของคุณ เน้นการสนทนาที่สําคัญและแสดงตัวอย่างเชิงบวกที่โดดเด่นสําหรับทีมของคุณ"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[โทร]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแล้ว"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแล้ว"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ได้ยอมรับการโต้แย้ง"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การโต้แย้งถูกส่งไปยัง ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อธิบายว่าเหตุใดคุณจึงคิดว่าผู้รับการรีวิวรายนี้เป็นผู้รับของฟีดแบ็คที่ไม่ถูกต้อง"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อคิดเห็นและตั้งค่าผู้รับการรีวิวใหม่"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อคิดเห็น"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อธิบายว่าเหตุใดคุณจึงเชื่อว่าคะแนนเหล่านี้ได้รับการให้คะแนนอย่างไม่เป็นธรรม (บังคับ)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อคิดเห็น (จําเป็น)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อธิบายว่าเหตุใดคุณจึงปฏิเสธการโต้แย้งนี้"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อคิดเห็น"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งการโต้แย้งไปยัง"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งการโต้แย้งไปยัง"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งการโต้แย้งไปยัง"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้รับการรีวิวใหม่"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คการโต้แย้ง"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คการโต้แย้ง"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนผู้รับการรีวิว"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งการโต้แย้งไปยัง"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โต้แย้งรีวิวนี้"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันไม่เห็นด้วยกับคะแนน"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวนี้ไม่ได้ตรงกับฉัน"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ได้ยอมรับการโต้แย้งบางส่วน"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ได้ปฏิเสธการโต้แย้ง"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับแล้ว"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับบางส่วน"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธแล้ว"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ได้เริ่มการโต้แย้ง: รีวิว"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ได้เริ่มการโต้แย้ง: ผู้รับการรีวิวไม่ถูกต้อง"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่เปิดอยู่จะต้องได้รับการแก้ไขเพื่อดําเนินการอื่นๆ ในการรีวิว"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับ"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกคะแนนใหม่"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการโต้แย้ง"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการโต้แย้งนี้"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการโต้แย้ง"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาการระงับการโต้แย้ง"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธ"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกคะแนนที่ยุติธรรมใหม่ (ไม่บังคับ)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โต้แย้งการรีวิว"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวไม่ถูกต้อง"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับการโต้แย้ง"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับการโต้แย้ง"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คการโต้แย้ง"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวไม่ถูกต้อง"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คการโต้แย้ง"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธการโต้แย้ง"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธการโต้แย้ง"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูการโต้แย้ง"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแล้ว"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " โดย ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไปยัง ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวแล้ว"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถูกมอบหมายให้:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อคิดเห็นด้วย"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับแบบฟอร์มลงคะแนนแบบมีเงื่อนไข"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดิมถูกมอบหมายให้:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพียงเลือกหนึ่งรายการ"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบ GIF ใดๆ ที่มีชื่อนั้น"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้รีวิวเป็นคนแรก"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการของคุณถูกหยุดชั่วคราวในขณะนี้ เปิดใช้งานอีกครั้งเพื่อเปิดใช้งานฟีดแบ็ค"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โพสต์โดย:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซึ่งแสดงให้เห็นถึงเวลาที่ต้องใช้ในการรีวิวการสนทนานี้"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่มีสาเหตุที่แท้จริงสําหรับหมวดหมู่: \"", _interpolate(_list(0)), "\""])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนหมวดหมู่หรือเพิ่มข้อคิดเห็น"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มอีโมจิ"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุบ"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขยายฟิลด์ข้อคิดเห็น"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุบฟิลด์ข้อคิดเห็น"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนการจัดรูปแบบ"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนแผงควบคุม"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงการจัดรูปแบบ"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงแผงควบคุม"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งรีวิว"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนหมวดหมู่ทั้งหมด"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนอย่างน้อยหนึ่งหมวดหมู่"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการสนทนานี้ไว้ใช้ในภายหลัง"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกไว้ใช้ภายหลัง"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายถัดไป"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แทนที่การสนทนาในวงจรการมอบหมายนี้"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการสนทนา"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวพื้นฐาน"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อคิดเห็น"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นถูกลบ"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อคิดเห็นนี้หรือไม่"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เขียนข้อคิดเห็น"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กนี้ถูกลบหรือเปลี่ยนชื่อแล้ว"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์สิ่งที่คุณชอบหรือสิ่งที่ควรปรับปรุง..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อคิดเห็น"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบมีเงื่อนไข"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบข้อคิดเห็นทั้งหมดอย่างถาวร"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อคิดเห็น"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ข้อความ]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ยังไม่เห็นฟีดแบ็คนี้"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ได้เห็นฟีดแบ็คนี้"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อดีตสมาชิก"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กด Enter เพื่อสร้างแฮชแท็กใหม่"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กที่กําหนดเองถูกล็อกโดยผู้ดูแลระบบ"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ตัวเลือกนี้เพื่อบุ๊คมาร์กเคสและกรองออกในภายหลัง"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายเป็นพื้นฐาน"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คข้อความ"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาถัดไป"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีฟีดแบ็ค"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวที่ทํา"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้รีวิวด้านล่างเพื่อเริ่มต้น"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวอื่นที่ได้ให้"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้รับคะแนน"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับมอบหมายก่อนหน้า"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาก่อนหน้า"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเป็นพื้นฐาน"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับ"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับรีวิวนี้"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนแถบด้านข้างของรีวิว"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบรีวิวและข้อคิดเห็นที่เพิ่มทั้งหมดอย่างถาวร"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบรีวิว"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบรีวิว"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวถูกลบ"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["รีวิวถูกลบโดย ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้นแล้ว!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายงานนี้ให้กับตัวคุณเองอีกครั้งเพื่อให้รีวิว"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้ให้รีวิวสําหรับผู้ใช้รายนี้แล้ว"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["คุณได้ให้รีวิวสําหรับ ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหมวดหมู่ที่จะแสดงสําหรับการสนทนานี้"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการรีวิวตนเองสําหรับพื้นที่ทํางานนี้"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องบันทึก"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกรีวิวนี้เป็นแบบร่างหรือไม่"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละทิ้งแบบร่างนี้"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละทิ้ง"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงแถบด้านข้างของรีวิว"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้มเหลว"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่าน"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่านโดยมีข้อคิดเห็น"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่ง"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังคงต้องรวบรวมข้อมูลจำนวนมาก..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากข้อคิดเห็น"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวข้อความนี้"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวตนเอง"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการสนทนานี้"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เขียน"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผู้ใช้ที่จะรีวิว"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดใช้งานการรีวิวตนเอง"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้ใช้"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางาน"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลด..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 รีวิว"]), _normalize([_interpolate(_named("count")), " รีวิว"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกการให้คะแนนใหม่"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้จัดการพื้นที่ทํางานของคุณไม่อนุญาตให้มีการรีวิวตนเองได้"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กด Enter เพื่อแท็กการปักหมุดนี้"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 วันที่ผ่านมา"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดดาวแล้ว"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่นการปรับเทียบ"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึก"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกช่วยให้คุณเห็นภาพของข้อมูลฝ่ายสนับสนุนของคุณ ใช้แผนภูมิเชิงโต้ตอบเพื่อทําความเข้าใจตําแหน่งที่จะมุ่งเน้นความพยายามด้าน QA ของคุณ ค้นพบจุดบอดและค้นหาเจ้าหน้าที่ที่เหมาะสมเพื่อตรวจสอบตามประสิทธิภาพของพวกเขา"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL ของ ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แปลเป็น ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยัง"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้มอบหมาย"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังรีวิว"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําลังรีวิวตอนนี้"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้ถูกลบออกและไม่สามารถใช้งานได้อีกต่อไป"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้คุณกําลังมองหาไม่มีอยู่ใน QA ของ Zendesk"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดทั้งหมดที่มีการสนทนานี้"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในเซสชั่น"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนา"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบการสนทนาออกจากเซสชั่นจะลบรีวิวทั้งหมดที่ได้ให้"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบการสนทนาออกจาก ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีเซสชันการปรับเทียบถูกสร้าง"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้ถูกเพิ่มไปยังเซสชั่นแล้ว"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้ลบเอาออกจากเซสชั่นแล้ว"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยัง/ลบออกจากเซสชั่นการปรับเทียบ"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงก์ไปยังการสนทนานี้"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่จะแสดง"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบรนด์"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลของลูกค้า"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ของลูกค้า"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบบฟอร์มภายนอก"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาในการตอบกลับครั้งแรก"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลำดับความสำคัญ"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นของแบบสํารวจ"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความพึงพอใจ"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็ก"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาในการแก้ปัญหาครั้งแรก"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้นฉบับ"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลด..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความนี้ไม่อยู่ภายใต้การสนทนานี้หรือถูกลบไปแล้ว"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหัวข้อ"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่จะแสดง"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการสนทนา"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดการสนทนาต้นฉบับ"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถลิงก์ไปยังการสนทนา - URL ขาดหายไป"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผู้เข้าร่วม"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดการสนทนา"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกเพื่ออ่านรีวิว"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกเพื่อรีวิว"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่ถูกสร้างผ่านส่วนขยายเบราว์เซอร์"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูแบบสํารวจความพึงพอใจของลูกค้า"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูการสนทนา"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความที่มีความคิดเห็นเชิงลบ"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความที่มีความคิดเห็นเชิงบวก"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แปลเป็นภาษาอังกฤษ"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อความต้นฉบับ"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนา"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลบการสนทนาจะเป็นแบบถาวร นอกจากนี้ยังลบข้อมูลที่เกี่ยวข้องทั้งหมด เช่น แบบสำรวจ ทรานสคริปต์ และรีวิวจาก QA ของ Zendesk รวมถึงแดชบอร์ดด้วย", _interpolate(_named("lineBreak")), "คุณจะยังสามารถเข้าถึงการสนทนานี้ได้ใน Zendesk Support หรือซอฟต์แวร์ ฝ่ายสนับสนุนอื่นที่คุณใช้งานอยู่"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลบการสนทนาจะเป็นแบบถาวร รวมถึงยังลบข้อมูลที่เกี่ยวข้องทั้งหมด เช่น แบบสำรวจ ทรานสคริปต์ และการรีวิวจาก QA ของ Zendesk รวมถึงแดชบอร์ด หากการสนทนาเป็นของหลายพื้นที่ทำงาน จะถูกลบออกจากทั้งหมด", _interpolate(_named("lineBreak")), " คุณยังคงสามารถเข้าถึงการสนทนานี้ใน Zendesk Support หรือซอฟต์แวร์ฝ่ายสนับสนุนอื่นๆ ที่คุณใช้งานอยู่"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนา"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนาจาก QA ของ Zendesk"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสนทนานี้ยังไม่สมบูรณ์เนื่องจากมีการเลื่อนระดับ ไปที่ ", _interpolate(_named("optionsUrl")), " และตรวจสอบแท็กของคุณ ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสนทนานี้ยังไม่สมบูรณ์เนื่องจากมีการเลื่อนระดับ พูดคุยกับผู้ดูแลระบบของคุณเกี่ยวกับการตรวจสอบแท็กของคุณ ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ > ตัวเลือกขั้นสูง"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านเกี่ยวกับแท็ก"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดแล้ว"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอดำเนินการ"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแล้ว"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผู้ได้รับมอบหมายถูกเปลี่ยนเป็น ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้มอบหมาย"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["มาโคร ", _interpolate(_named("target")), " ถูกใช้โดย ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสนทนาได้รับการให้คะแนน ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผู้ใช้ ", _interpolate(_named("target")), " ได้แก้ไขการสนทนา"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เปลี่ยนสถานะเป็น ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ติดแท็กด้วย ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เปลี่ยนแปลงทีมเป็น ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ได้เลื่อนการเตือนการสนทนาสําหรับ ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " ได้ยกเลิกการเลื่อนการสนทนา"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทติดอยู่ในลูป โดยทำซ้ำข้อความติดต่อกัน"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทําซ้ำของบอท"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทำซ้ำของบอท โดยที่ข้อความเดิมจะถูกทำซ้ำอย่างน้อยหนึ่งครั้งติดต่อกัน"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบวลีที่ตรงกัน"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เจ้าหน้าที่โดยทั่วไปจัดการการสนทนาได้อย่างมีประสิทธิภาพมากกว่าบอท ", _interpolate(_named("percent")), " "])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประสิทธิภาพการสื่อสารของบอท"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["บอทจัดการการสนทนาได้อย่างมีประสิทธิภาพมากกว่าเจ้าหน้าที่โดยทั่วไป ", _interpolate(_named("percent"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประสิทธิภาพการสื่อสารของบอท"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาขาดข้อความที่จําเป็นเพื่อแจ้งให้ผู้เข้าร่วมทราบถึงการบันทึกซึ่งจําเป็นสําหรับการปฏิบัติตามและความโปร่งใส"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเปิดเผยข้อมูลโดยการบันทึก"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ข้อตกลงระดับการให้บริการ (SLA) ถูกละเมิดสําหรับ ", _interpolate(_list(0))])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA ที่ละเมิด"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมตริกสําหรับกฎ SLA เกินเวลาเป้าหมายแล้ว"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญญาณของการถดถอยของลูกค้า ลูกค้ากําลังพิจารณาเปลี่ยนหรือยืนยันว่าจะแยกทาง"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุความเสี่ยงการเลิกใช้บริการ"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การสนทนามีช่วงเงียบยาวนานกว่าเกณฑ์ที่ตั้งไว้ที่ ", _interpolate(_list(0))])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 อินสแตนซ์"]), _normalize([_interpolate(_named("count")), " อินสแตนซ์"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["นานที่สุด ", _interpolate(_list(0)), " วินาที"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าต้องการความช่วยเหลือระดับสูง"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งคำร้องขอยกระดับแล้ว"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าแสดงความขอบคุณอย่างยิ่งหรือพอใจมากกับการสนับสนุนที่ได้รับ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริการที่เป็นเลิศ"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าหรือเจ้าหน้าที่ได้ร้องขอการติดตามผลอย่างชัดเจน"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําขอการติดตามผล"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าแสดงความไม่พอใจ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็นเชิงลบ"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาไม่เป็นไปตามปกติหรือไม่ใช่ทั่วไปและมีการกลับไปกลับมามากขึ้นเพื่อให้ได้ข้อสรุปของการแก้ปัญหา"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจพบค่าผิดปกติ"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าแสดงความพึงพอใจ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็นเชิงบวก"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ได้"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าเป็นไม่สามารถใช้ได้"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไขล่าสุดโดย ", _interpolate(_named("author")), " เมื่อ ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ไม่ได้"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["โดย ", _interpolate(_named("author")), " เมื่อ ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเหตุผล (ไม่บังคับ)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูลเชิงลึก"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อ"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อแอปฝ่ายสนับสนุนของคุณกับบัญชีของคุณและสร้างตัวกรองอย่างน้อยหนึ่งตัวเพื่อดูรายการการสนทนา"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่จะแสดง"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูการตั้งค่าพื้นที่ทำงาน"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแล้ว"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตครั้งล่าสุด"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่า"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ในหนึ่งวัน)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(เดือนนี้)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(สัปดาห์นี้)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตาม ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผลการค้นหาสําหรับ ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่รีวิวโดยผู้อื่น"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้ให้ข้อคิดเห็น"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่ล่าสุด"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก่าที่สุด"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สุ่ม"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับปรุงเมื่อเร็วๆ นี้"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับเมื่อเร็ว ๆ นี้"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียงตาม"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ได้อ่าน"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวโดยคุณ"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กระบวนการนําเข้าอาจใช้เวลาสักครู่ เราจะส่งอีเมลถึงคุณเมื่อเสร็จสิ้น"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk กําลังนําเข้าการสนทนาของคุณ"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ขีดจำกัด: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มโดยคลิกที่ไอคอนการปรับเทียบในส่วนหัวของมุมมองการสนทนา"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีอะไรจะรายงานที่นี่ ลองอีกครั้งโดยใช้ตัวกรองหรือตัวเลือกการเรียงลำดับอื่น"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีค่าผิดปกติที่จะแสดง"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาการสนทนาที่เกี่ยวข้องและคลิกที่ไอคอนรูปดาวในส่วนหัวเพื่อบุ๊คมาร์กที่นี่"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองเปลี่ยนตัวกรองหรือเชิญเจ้าหน้าที่ฝ่ายสนับสนุนคนอื่นๆ เพื่อเพิ่มการสนทนาเพิ่มเติม"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้า"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีขีดจำกัด"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุภายใน"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรากําลังโหลดการสนทนาอยู่ในขณะนี้ เนื้อหาบางอย่างอาจยังขาดหายไป"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ถัดไป"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้ใช้"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิกพื้นที่ทํางาน"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ก่อนหน้า"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเพิ่มการสนทนา"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการเพิ่มการสนทนา"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ยังไม่เสร็จสิ้นจะแสดงที่นี่ อ่านซ้ำ แก้ไข และส่งในอีก 30 วันข้างหน้า"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวแบบร่าง"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่ติดดาว"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่จะแสดง"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ตรงกัน"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ข้อความที่เลือก"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบกลับน้อยที่สุด"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบกลับมากที่สุด"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการการสนทนา"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ทั้งหมด"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["รับสายที่เวลา ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["วางสายที่เวลา ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["โทรออกที่เวลา ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวแล้ว"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวโดยคุณ"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่สามารถโหลดไฟล์เสียง ตรวจสอบให้แน่ใจว่าคุณลงชื่อเข้าใช้ ", _interpolate(_list(0))])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นแบบสํารวจความพึงพอใจของลูกค้า"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดในการโหลดเสียง"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เขียนข้อคิดเห็น"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการโทร"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับฟังฝ่ายสนับสนุน"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟังบน Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเร็วการเล่น"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการโทร"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบ"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทรานสคริปต์"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูง"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ำ"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปานกลาง"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ผู้ใช้ QA ของ Zendesk"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk จะจัดอันดับเจ้าหน้าที่โดยอัตโนมัติอิงตาม CSAT เฉลี่ยรายเดือน ค้นหาการสนทนาที่จัดการโดยเจ้าหน้าที่ที่มี CSAT ต่ำกว่าเพื่อนร่วมงานได้ในคลิกเดียว"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดอันดับตาม CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปานกลาง"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูง"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ำ"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ำสุด"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูงสุด"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ผู้ใช้ QA ของ Zendesk"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบสั่งงานใบเดียว"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับเสียง"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk จะจัดอันดับเจ้าหน้าที่ลงในกลุ่มประสิทธิภาพโดยอัตโนมัติอิงตามจํานวนการสนทนาที่พวกเขาได้จัดการต่อเดือน ค้นหาการสนทนาตามเจ้าหน้าที่ที่มีปริมาณต่ำกว่าเพื่อนรวมงานได้ในคลิกเดียว"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดอันดับตามปริมาณ"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวแล้ว"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ได้รีวิว"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการรีวิว"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ำ"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูง"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป็นกลาง"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ผู้ใช้ QA ของ Zendesk"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk จะจัดอันดับเจ้าหน้าที่โดยอัตโนมัติอิงตามความคิดเห็นโดยเฉลี่ยในการสนทนา ค้นหาการโต้ตอบที่เจ้าหน้าที่มีปฏิสัมพันธ์กับลูกค้าที่คับข้องใจได้มากกว่าปฏิสัมพันธ์ของเพื่อนร่วมงานได้ในคลิกเดียว"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดอันดับตามความคิดเห็น"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจไม่พบ"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจพบ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความผิดปกติ"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่ต้องการความสนใจจากคุณ โมเดลแมชชีนเลิร์นนิงของเราระบุปฏิสัมพันธ์ที่ซับซ้อนหรือผิดปกติเพื่อการรีวิวเพิ่มเติม"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความผิดปกติ"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงลบ"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงบวก"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกการสนทนาเพื่อการรีวิวว่าลูกค้าแสดงความพอใจหรือไม่พอใจในเรื่องใด ใช้ดรอปดาวน์เพื่อเลือกประเภทของความคิดเห็นที่คุณมองหาในการสนทนาของคุณ ลากแอททริบิวต์ตัวกรองไปที่ด้านบนเพื่อดูอัตราส่วนของความคิดเห็นที่เลือกในปฏิสัมพันธ์ทั้งหมดของคุณ"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการรีวิว"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ได้รีวิว"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวแล้ว"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk จะตรวจจับภาษาในการสนทนาของคุณโดยอัตโนมัติ ใช้ดรอปดาวน์เพื่อเลือกการสนทนาเฉพาะในภาษาที่ต้องการเพื่อรีวิว ลากแอททริบิวต์ตัวกรองไปที่ด้านบนเพื่อดูภาพรวมของภาษาทั้งหมดที่ใช้ในฝ่ายสนับสนุนของคุณ"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกของการสนทนา"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณสามารถ ", _interpolate(_named("paragraph_2_item")), " กับ ข้อมูลเชิงลึกได้มากกว่าตัวกรองการสนทนาทั่วไป รับภาพรวมภาพของข้อมูลทั้งหมดจากฝ่ายสนันสนุนของคุณ และสํารวจข้อมูลเชิงลึก เช่น:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการใช้ข้อมูลเชิงลึกของการสนทนา"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าใจความสําคัญเชิงสัมพัทธ์ของแอททริบิวต์การสนทนาเฉพาะที่เกี่ยวข้องกับผู้อื่น"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเจ้าหน้าที่ที่มีประสิทธิภาพสูงสุดและต่ำสุดของคุณด้วยการจัดอันดับประสิทธิภาพของเจ้าหน้าที่โดยอัตโนมัติ"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " เป็นเครื่องมือขุดข้อมูลเชิงโต้ตอบที่มีประสิทธิภาพช่วยให้คุณค้นหาการสนทนาที่จำเป็นต้องรีวิวมากที่สุด"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําอะไรได้อีกมากมาย"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แผนภูมิการสนทนาและเจ้าหน้าที่เป็นแบบ ", _interpolate(_named("paragraph_3_item_1")), " – การอัปเดตตัวกรองหรือการคลิกที่ส่วนของแผนภูมิที่แบ่งส่วนบนส่วนใดส่วนหนึ่งจะอัปเดตรายการการสนทนาโดยอัตโนมัติ ", _interpolate(_named("paragraph_3_item_2")), " เพื่อสํารวจรายละเอียดหรืออัตราส่วนขององค์ประกอบต่างๆ"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อถึงกัน"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดเรียงแอททริบิวต์ตัวกรองใหม่"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ข้อมูลเชิงลึกของการสนทนาจะแสดง ", _interpolate(_named("paragraph_4_item")), " เท่านั้น เราจะลบปฏิสัมพันธ์ที่ไม่มีค่าการรีวิวจริงโดยอัตโนมัติ เช่น ข้อความที่สร้างขึ้นโดยอัตโนมัติ สแปม การตอบกลับบอท หรือข้อความที่ไม่มีการสนทนาที่เหมาะสมกับตัวแทนฝ่ายสนับสนุนของคุณ"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่สะอาดและปิดแล้ว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันจะเห็นอะไรที่นี่"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " จากทั้งหมด ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " จากทั้งหมด ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " จากทั้งหมด ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " จาก ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถดึงข้อมูล"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่มี ", _interpolate(_list(0))])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " จากทั้งหมด)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โหลดใหม่"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึก"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แต่เรามั่นใจว่าจะยอดเยี่ยมมากเมื่อมาถึง"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจที่กําหนดเอง"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีฟีดแบ็คแบบสํารวจที่ได้ให้"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีคะแนนฟีดแบ็คแบบสํารวจ..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนน"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฟีดแบ็ค"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กข้อคิดเห็นฟีดแบ็ค"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ตัวกรองช่วงวันที่แล้ว"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " การตอบกลับ"]), _normalize([_interpolate(_named("count")), " การตอบกลับ"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่ายสนับสนุน"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เจาะลึกลงไปว่าทําไมจึงให้คะแนน ", _interpolate(_list(0)), "โดยการตั้งค่าเหตุผลภายใต้การตั้งค่าแบบสํารวจ"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ยังไม่มีเหตุผล ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับเหตุผลของแบบสํารวจ"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่มีแท็ก:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็ก"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ตาม"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็ก"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ข้อคิดเห็น"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เหตุผล ", _interpolate(_named("surveyType")), " รายการ"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ปัจจัยขับเคลื่อน ", _interpolate(_named("surveyType")), " ที่คาดการณ์ไว้"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มาของการสนทนา"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["การตอบแบบสํารวจ (", _interpolate(_named("n")), ")"]), _normalize(["การตอบแบบสํารวจ (", _interpolate(_named("n")), ")"]), _normalize(["การตอบแบบสํารวจ (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหัวข้อ"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การสนทนาที่มี ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ตามผู้ใช้"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนแปลง"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราการตอบสนอง"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["คะแนน ", _interpolate(_list(0)), " คือผลรวมของคําตอบทั้งหมดหารด้วยผลรวมของคะแนนสูงสุด"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขเป้าหมาย"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเป้าหมาย"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งเป้าหมาย"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเป้าหมาย"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สั้น"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาวมาก"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาว"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยาวปานกลาง"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สับสน"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เป็นประโยชน์"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนที่ไม่ดี"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนที่ดี"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อารมณ์ล้นหลาม"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชี"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คสําหรับเจ้าหน้าที่"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อร้องเรียน"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้รับการใส่ใจ"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัญหาไม่ได้รับการแก้ไข"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขปัญหาแล้ว"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็นเชิงลบ"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลลัพธ์ที่ไม่ดี"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็นเชิงบวก"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกย่อง"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์ไม่ดี"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคืนเงิน"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนที่รวดเร็ว"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนที่ช้า"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณ"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["QA ของ Zendesk จะจัดสรรข้อมูล ", _interpolate(_list(0)), " ของคุณเป็นหมวดหมู่ที่กําหนดไว้ล่วงหน้าโดยอัตโนมัติ ดังนั้นคุณจึงสามารถเข้าใจข้อมูลเชิงลึกที่ซับซ้อนและสํารวจสาเหตุโดยไม่ต้องกลั่นกรองฟีดแบ็คที่เป็นลายลักษณ์อักษรด้วยตนเอง"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนทั้งหมด"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจทั้งหมด"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกภาษาการตอบสนอง"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดข้อคิดเห็น"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกขนาดข้อคิดเห็น"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื่องจากข้อคิดเห็น 25% ที่ประกอบด้วยคําเพียงคําเดียวข้อคิดเห็นที่ยาวขึ้นจึงเป็นสิ่งที่ต้องอ่าน ค้นหาฟีดแบ็คเชิงลึกยิ่งขึ้นโดยไม่ต้องทํางานด้วยตนเอง เนื่องจากระบบจะทำงานให้คุณโดยอัตโนมัติ"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลของฟีดแบ็ค"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเหตุผลของฟีดแบ็ค"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีคะแนนที่ตรงกัน"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจ"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัจจัยขับเคลื่อนที่คาดการณ์ไว้"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกหมวดหมู่ข้อคิดเห็น"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คํา Word Cloud"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกคํา Word Cloud"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ตั้งแต่ช่วงเวลาที่แล้ว"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " การสนทนา"]), _normalize([_interpolate(_named("n")), " การสนทนา"]), _normalize([_interpolate(_named("n")), " การสนทนา"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถคํานวณอัตราส่วนการส่งแบบสํารวจจากการสนทนาทั้งหมดได้เนื่องจากขาดข้อมูล"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนอง"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจที่ส่งแล้ว"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางแบบสํารวจ"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ของ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " ของ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " ของ ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ปัจจัยขับเคลื่อน ", _interpolate(_named("surveyType")), " ที่คาดการณ์ไว้"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["QA ของ Zendesk จะจัดสรรข้อมูล ", _interpolate(_list(0)), " ของคุณเป็นหมวดหมู่ที่กําหนดไว้ล่วงหน้าโดยอัตโนมัติ ดังนั้นคุณจึงสามารถเข้าใจข้อมูลเชิงลึกที่ซับซ้อนและสํารวจสาเหตุโดยไม่ต้องกลั่นกรองฟีดแบ็คที่เป็นลายลักษณ์อักษรด้วยตนเอง"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดข้อคิดเห็น"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื่องจากข้อคิดเห็น 25% ที่ประกอบด้วยคําเพียงคําเดียวข้อคิดเห็นที่ยาวขึ้นจึงเป็นสิ่งที่ต้องอ่าน ค้นหาฟีดแบ็คเชิงลึกยิ่งขึ้นโดยไม่ต้องทํางานด้วยตนเอง เนื่องจากระบบจะทำงานให้คุณโดยอัตโนมัติ"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลา"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนองแบบสํารวจ"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (เฉลี่ย)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบสนอง"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (เฉลี่ย)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉลี่ย"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แนวโน้ม ", _interpolate(_list(0)), " ตาม"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบสนอง"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็ก"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัจจัยขับเคลื่อน"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["วันที่ส่งแบบสํารวจ ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["วันที่ตอบกลับแบบสํารวจ ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["กําหนดคะแนน ", _interpolate(_list(0)), " ให้กับ"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อมูลอิงตาม"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างการสนทนา"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าของใบสั่งงาน"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่มีการตอบกลับมากที่สุด"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (เฉลี่ย)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " เมื่อเวลาผ่านไป"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบสนอง"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " และ IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบสนอง"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่มีคําเหล่านี้:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Cloud"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT ตามผู้ใช้"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวมของ CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางแบบสํารวจ"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนองแบบสํารวจ"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT เมื่อเวลาผ่านไป"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีช่องทาง"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีแหล่งที่มา"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีภาษา"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเหตุผล"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีแท็ก"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหมวดหมู่ข้อคิดเห็น"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีพื้นที่ทํางาน"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลพร้อมใช้งานเนื่องจากไม่มีการสร้างการมอบหมายหรือไม่มีการเริ่มต้นวงจร กลับมาตรวจสอบในภายหลังหรือปรับตัวกรอง"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สํารวจว่ามีอะไรใหม่"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " รายการ"]), _normalize([_interpolate(_named("count")), " รายการ"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " การสนทนา"]), _normalize([_interpolate(_named("count")), " การสนทนา"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้มเหลว"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่าน"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่านโดยมีข้อคิดเห็น"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่าน/ไม่ผ่าน"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉลี่ย"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉลี่ย (ชม.)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉลี่ย (นาที)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การให้คะแนน ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT เฉลี่ย = [ผลรวมของคะแนนฟีดแบ็ค ÷ (จํานวนการตอบสนองทั้งหมด × คะแนนสูงสุดที่เป็นไปได้)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน CSAT ของ QA ของ Zendesk จะปรับให้คะแนนฟีดแบ็คของคุณเป็นคะแนนมาตรฐานภายในระดับ 0-100% และใช้ค่าเฉลี่ยของคะแนนมาตรฐาน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (คะแนนความพึงพอใจของลูกค้า) จะวัดว่าลูกค้าของคุณมีความสุขกับบริการของคุณมากน้อยเพียงใด"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " รีวิวแต่ละรายการแทนถึงรีวิวจากบุคคลหนึ่งคนในการสนทนาหนึ่งครั้ง การสนทนาสามารถมีจำนวนรีวิวได้มากกว่าหนึ่งรายการ (หากผู้รีวิวสองคนขึ้นไปได้รีวิวการสนทนาเดียวกัน)"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดตามการพัฒนาของคะแนนจากผู้รีวิวเมื่อเวลาผ่านไป"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดตามการพัฒนาของคะแนนของเจ้าหน้าที่เมื่อเวลาผ่านไป"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " การตอบสนอง"]), _normalize([_interpolate(_named("n")), " การตอบสนอง"]), _normalize([_interpolate(_named("n")), " การตอบสนอง"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " รีวิว"]), _normalize([_interpolate(_named("n")), " รีวิว"]), _normalize([_interpolate(_named("n")), " รีวิว"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (คะแนนคุณภาพภายใน) เป็นผลมาจากการรีวิวการสนทนาของคุณ"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = ผลรวมของคะแนนรีวิว ÷ จํานวนรีวิว"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คํานวณเป็นค่าเฉลี่ย โดยคํานึงถึงคะแนนรีวิวทั้งหมด (ค่าเฉลี่ยถ่วงน้ำหนักของคะแนนหมวดหมู่) ที่ตรงกับเงื่อนไขตัวกรองที่เลือก"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โหลดบัตรไม่สําเร็จ"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนตัวกรองเพื่อลองอีกครั้ง"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราการผ่านแสดงถึงผลลัพธ์ของการรีวิวการสนทนาของคุณ"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่ผ่าน หากคะแนนรีวิวอยู่ที่ ", _interpolate(_named("failScore")), " หรือต่ำกว่า"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผ่าน หากคะแนนรีวิวอยู่ที่ ", _interpolate(_named("passScore")), " หรือสูงกว่า"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวณโดยพื้นฐานที่กําหนดไว้ล่วงหน้า ซึ่งหมายความว่าคุณ:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราการผ่าน = ผลรวมของรีวิวที่ผ่าน ÷ ผลรวมของรีวิวทั้งหมด"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราการผ่านระบุเปอร์เซ็นต์ของจํานวนรีวิวที่ผ่านโดยไม่ล้มเหลวในหมวดหมู่ที่สําคัญหรือไม่สําคัญ"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เช่น หากผู้รีวิวให้รีวิว 3 รายการในวันนี้ ตัวเลขที่แสดงคือค่าเฉลี่ยของคะแนน 3 รายการ ดูวิธีการคํานวณแต่ละเซลล์ ", _interpolate(_named("link"))])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เช่น หากเจ้าหน้าที่ได้รับรีวิว 3 รายการในวันนี้ ตัวเลขที่แสดงคือค่าเฉลี่ยของคะแนน 3 รายการ ดูวิธีการคํานวณแต่ละเซลล์ ", _interpolate(_named("link"))])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่นํามาพิจารณา: น้ำหนักหมวดหมู่ สถานะที่สำคัญ (ไม่เหมือนกับคะแนนเมื่อเวลาผ่านไป)"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื่องจากหมวดหมู่สามารถทําเครื่องหมายไม่เกี่ยวข้องในรีวิวได้ จำนวนรีวิวต่อหมวดหมู่อาจต่ำกว่าจำนวนรีวิวทั้งหมดโดยผู้รีวิว ดูวิธีคํานวณแต่ละเซลล์"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่นํามาพิจารณา: น้ำหนักหมวดหมู่ สถานะที่สำคัญ (ไม่เหมือนกับคะแนนเมื่อเวลาผ่านไป)"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื่องจากหมวดหมู่สามารถทําเครื่องหมายไม่เกี่ยวข้องในรีวิวได้ จำนวนรีวิวต่อหมวดหมู่อาจต่ำกว่าจำนวนรีวิวทั้งหมดต่อเจ้าหน้าที่ ดูวิธีคํานวณแต่ละเซลล์"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่นํามาพิจารณา: น้ำหนักหมวดหมู่ สถานะที่สำคัญ (ไม่เหมือนกับคะแนนเมื่อเวลาผ่านไป)"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " กลุ่มหมวดหมู่จะะแสดงโดยแยกกัน คะแนนสําหรับแต่ละกลุ่มคํานวณโดยคะแนนหมวดหมู่โดยเฉลี่ยของกลุ่มนั้นในช่วงเวลาเดียวกัน ดูวิธีการคํานวณแต่ละเซลล์ ", _interpolate(_named("link"))])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่นํามาพิจารณา: น้ำหนักหมวดหมู่ สถานะที่สำคัญ (ไม่เหมือนกับคะแนนเมื่อเวลาผ่านไป)"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " กลุ่มหมวดหมู่จะะแสดงโดยแยกกัน คะแนนสําหรับแต่ละกลุ่มคํานวณโดยคะแนนหมวดหมู่โดยเฉลี่ยของกลุ่มนั้นในช่วงเวลาเดียวกัน ดูวิธีการคํานวณแต่ละเซลล์ ", _interpolate(_named("link"))])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "% ของพื้นฐาน"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปราศจากข้อผิดพลาดสำคัญ"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราปลอดข้อผิดพลาดสำคัญระบุเปอร์เซ็นต์ของจํานวนรีวิวที่ผ่านโดยไม่ล้มเหลวในหมวดหมู่ที่สําคัญใดๆ"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนคุณภาพภายใน"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริง 'อื่นๆ'"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวม"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงเป็นระยะ"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงรายวัน"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงรายเดือน"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงรายสัปดาห์"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงรายปี"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนเมื่อเวลาผ่านไป"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูในวันหรือสัปดาห์"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคํานวณ = คะแนนรวมสําหรับวันหรือสัปดาห์หารด้วยจำนวนรีวิวทั้งหมดที่ได้รับ"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูในวันหรือสัปดาห์"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคํานวณ = คะแนนรวมของวันหรือสัปดาห์หารด้วยจำนวนรีวิวทั้งหมดที่ได้รับ"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนตามหมวดหมู่"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูค่าเฉลี่ยรวมของการให้คะแนนหมวดหมู่ทั้งหมดที่ได้ให้โดยผู้รีวิวในระหว่างช่วงเวลานี้"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูค่าเฉลี่ยรวมของการให้คะแนนหมวดหมู่ทั้งหมดที่ได้รับต่อเจ้าหน้าที่ในช่วงเวลานี้"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริงสำหรับการให้คะแนนต่ำ"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริงสำหรับการให้คะแนน"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนหมวดหมู่เมื่อเวลาผ่านไป"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าหมวดหมู่เปรียบเทียบกันเป็นอย่างไรเมื่อเวลาผ่านไป"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูคะแนนเฉลี่ยที่ให้สําหรับแต่ละหมวดหมู่ในวันหรือสัปดาห์ที่เลือก"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าหมวดหมู่เปรียบเทียบกันเป็นอย่างไรเมื่อเวลาผ่านไป"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูคะแนนเฉลี่ยที่ได้รับสําหรับแต่ละหมวดหมู่ในวันหรือสัปดาห์ที่เลือก"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนตามรีวิว"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแล้ว"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่สำคัญ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลการให้คะแนน"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉลี่ย/ผลรวม"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่รวมการรีวิวตนเอง"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งออกการ์ดทั้งหมด"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้ให้"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["การสนทนา"]), _normalize(["การสนทนา"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["รายการ"]), _normalize(["รายการ"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " การตอบสนอง"]), _normalize([_interpolate(_named("n")), " การตอบสนอง"]), _normalize([_interpolate(_named("n")), " การตอบสนอง"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " รีวิว"]), _normalize([_interpolate(_named("n")), " รีวิว"]), _normalize([_interpolate(_named("n")), " รีวิว"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["แสดงอีกหนึ่ง"]), _normalize(["แสดงอีก ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 วันที่ผ่านมา"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 วันที่ผ่านมา"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนที่แล้ว"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์ที่แล้ว"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนนี้"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์นี้"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาที่กําหนดเอง"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไตรมาส"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โหลดบัตรไม่สําเร็จ เปลี่ยนตัวกรองเพื่อลองอีกครั้ง"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลที่จะแสดง"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงรายเดือน"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวม"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองที่ใช้งานอยู่ของคุณรวมข้อมูลในสเกลการให้คะแนนต่างๆ"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดบัตรทั้งหมดที่มีตัวกรองเหล่านี้"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดแดชบอร์ดทั้งหมดที่มีตัวกรองเหล่านี้"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาการรีวิวโดยเฉลี่ย"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับแต่ง"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลด CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหมวดหมู่"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกของหมวดหมู่"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กราฟ"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ข้อคิดเห็น"]), _normalize(["ข้อคิดเห็น"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมตริกซ์"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อดีตสมาชิก"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนคุณภาพภายใน"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (เฉลี่ย)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ผ่าน"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดบัตร"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดแดชบอร์ด"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนน"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["สาเหตุที่แท้จริง"]), _normalize(["สาเหตุที่แท้จริง"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID การรีวิว"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาการรีวิว"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าสาเหตุที่แท้จริง"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าสาเหตุที่แท้จริงช่วยให้คุณทราบว่าเหตุใดการสนทนาจึงได้คะแนนต่ำ"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีตั้งค่าสาเหตุที่แท้จริงที่กําหนดไว้สําหรับหมวดหมู่นี้"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน / ผลรวม"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนน"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มหมวดหมู่การให้คะแนน"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มหมวดหมู่"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทำงาน"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับแต่งลำดับ"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดกลุ่มหมวดหมู่ตาม"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกทั้งหมด"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับแต่ง"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่รวมการรีวิวตนเอง"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมการรีวิวตนเอง"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเฉพาะการรีวิวตนเอง"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้างตัวกรอง"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแฮชแท็ก"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กข้อคิดเห็น"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกแฮชแท็กข้อคิดเห็น"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแท็ก"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาแท็กฝ่ายสนับสนุน"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแบบฟอร์มลงคะแนน"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกแบบฟอร์มลงคะแนน"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวตนเอง"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเชื่อมต่อที่ตรงกัน"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มาของการสนทนา"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกแหล่งที่มา"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองเพิ่มเติม"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีพื้นที่ทํางานที่ตรงกัน"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาทั้งหมดที่กําหนด"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ทั้งหมด"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกคน"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกกลุ่ม"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่มที่ตรงกัน"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกกลุ่ม"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงการ์ด"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อมูลอิงตาม"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างรีวิว"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างการสนทนา"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงทศนิยมสองตําแหน่ง"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่ามุมมอง"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มุมมอง"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานทั้งหมด"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งบัญชี"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อพื้นที่ทํางาน"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีพื้นที่ทํางาน"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเอง"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ธง"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อาหารและเครื่องดื่ม"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัตว์และธรรมชาติ"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัตถุ"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บุคคลและร่างกาย"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การท่องเที่ยวและสถานที่"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการที่ใช้บ่อย"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการค้นหา"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้ายิ้มและอิโมติคอน"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญลักษณ์"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบอีโมจิ"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดการสนทนา"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่รายการงาน"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " หรือเรียกดูรายการ ", _interpolate(_named("link")), " ของคุณ"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกําลังใช้พื้นที่ทํางาน ", _interpolate(_named("workspace")), " ที่ไม่ได้เชื่อมต่อกับโดเมนนี้ คลิกที่นี่เพื่อเชื่อมต่อทันที"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อที่ใช้ได้"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณยังไม่สามารถให้ฟีดแบ็คบน ", _interpolate(_named("host")), " ได้"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีโปรแกรมฝ่ายสนับสนุนของคุณเป็นแท็บที่ใช้งานอยู่"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนนี้ยังไม่สามารถตรวจสอบได้ หากเป็นเช่นนั้น ให้แจ้งผู้ดูแลระบบของคุณ"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มโดเมนนี้"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เชื่อมต่อกับ ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอสักครู่ - ตั้งค่าก่อน"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถรับ URL"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็ค"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เครื่องมือที่เชื่อมต่อกับบัญชี QA ของ Zendesk ขององค์กรอยู่แล้ว"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเครื่องมือที่เชื่อมต่อกับบัญชี QA ของ Zendesk ขององค์กร"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วยให้คุณสามารถตรวจสอบเว็บไซต์ที่ URL ยังคงเหมือนเดิมเสมอโดยต่อท้ายแฮชที่ไม่ซ้ำกันในตอนท้ายก่อนที่จะให้รีวิว"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับบัญชี"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนของคุณ"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนที่เชื่อมต่อกับพื้นที่ทํางานนี้แล้ว"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถรีวิวหน้าใดก็ได้ในโดเมนต่อไปนี้"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนที่พร้อมสําหรับการรีวิว"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนขยาย"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ที่ไม่ซ้ำ"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ยังไม่มีการเชื่อมต่อโปรแกรมฝ่ายสนับสนุน โปนดไปที่ ", _interpolate(_named("link")), " เพื่อเพิ่ม"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถใช้ส่วนขยายเบราว์เซอร์ QA ของ Zendesk ใน\nแผน Professional และ Advanced เท่านั้น"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับพื้นที่ทำงาน"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับมอบหมาย:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุชื่อ"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุค่า"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์ที่กำหนดเอง"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่า"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่เป็นครั้งแรกที่เราเจอบทสนทนาประเภทนี้ ระบุรายละเอียดบางอย่าง"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การอ้างอิงการสนทนา:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ่งที่จะระบุใบสั่งงานในภายหลัง"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคืนเงิน"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุทั้งผู้รับมอบหมายและหัวข้อ"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้าง"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังสร้าง"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการสนทนานี้"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้มอบหมาย"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ติดต่อผู้จัดการของคุณหรือสลับไปยังพื้นที่ทํางานอื่นจาก ", _interpolate(_named("settings"))])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวตนเองถูกปิดใช้งานสําหรับพื้นที่ทํางานนี้"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อออก"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บ๊ายบาย"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทำงาน"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจสอบ URL นี้ได้ ไปที่การสนทนา"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ QA ของ Zendesk ผ่านผู้ให้บริการ SSO ของคุณก่อน แล้วจึงลองอีกครั้ง"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ด้วย SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดป็อพอัพลงชื่อเข้าใช้"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้ด้วยบัญชี QA ของ Zendesk ของคุณ"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอ..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังรับรองความถูกต้อง..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีบัญชีใช่หรือไม่"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียน"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่สามารถเข้าถึงการสนทนานี้ได้ หากเป็นเช่นนั้น โปรดติดต่อผู้จัดการของคุณ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเข้าถึง"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบว่าการเชื่อมต่อของคุณถูกเปิดใช้งานใน QA ของ Zendesk"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการการเชื่อมต่อของคุณ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อผิดพลาดในการเชื่อมต่อ"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปที่การสนทนาในฝ่ายสนับสนุนของคุณเพื่อดําเนินการต่อ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ไม่ถูกต้อง"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อใหม่เกือบเสร็จสิ้นแล้ว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สักครู่หนึ่ง"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองโหลดหน้าเว็บซ้ำเพื่อกลับเข้าสู่โฟลว์ของสิ่งต่างๆ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบางอย่างผิดพลาด"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปลดปล่อยตัวเองจากเขตแดนเสมือนของโปรแกรมฝ่ายสนับสนุนของคุณและรีวิวการสนทนาได้ทุกที่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดการสมัครใช้บริการของคุณเพื่อใช้ส่วนขยาย"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการการสมัครใช้บริการของคุณ"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อฝ่ายสนันสนุนไม่ได้เชื่อมโยงกับพื้นที่ทํางานนี้"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่ายสนับสนุนของคุณไม่รู้จักใบสั่งงานนี้หรือ QA ของ Zendesk ไม่มีสิทธิ์เข้าถึงใบสั่งงานนี้"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการสนทนา"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรมใหม่"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รับฟีดแบ็ค"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้สําเร็จ"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถใช้แอปได้แล้ว"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนหมวดหมู่ทั้งหมด"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ตัวเลือกนี้เพื่อบุ๊คมาร์กเคสและกรองออกในภายหลัง"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลด..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีฟีดแบ็คสําหรับการสนทนานี้"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านฟีดแบ็ค"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใน QA ของ Zendesk"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่คือที่ที่ฟีดแบ็คและการแจ้งเตือนทั้งหมดจะปรากฏขึ้น ยังไม่มีอะไร..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเพิ่มเติมใน QA ของ Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการสนทนา"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีทั้งหมดของ"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือทั้งหมดของ"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ประกอบด้วยทั้งหมดของ"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ทั้งหมดของ"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดีกว่าของเจ้าหน้าที่"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แย่กว่าของเจ้าหน้าที่"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เท็จ"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากกว่า"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกอบด้วยสิ่งใดๆ ของ"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือสิ่งใดๆ ของ"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ประกอบด้วยสิ่งใดๆ ของ"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่สิ่งใดๆ ของ"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จริง"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถูกละเมิด"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้ให้ข้อคิดเห็น"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้ให้ข้อคิดเห็นโดยฉัน"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ให้ข้อคิดเห็น"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ให้ข้อคิดเห็นโดยฉัน"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สําคัญสําหรับการรีวิว"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มี"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 วันที่ผ่านมา"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภายใน"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จาก"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 วันที่ผ่านมา"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 ชั่วโมงที่ผ่านมา"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 วันที่ผ่านมา"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 วันที่ผ่านมา"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนที่แล้ว"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์ที่แล้ว"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนนี้"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์นี้"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จนถึง"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อวานนี้"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจพบ"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีอยู่"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดดาวโดยฉัน"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ติดดาวโดยฉัน"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือ"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากกว่า"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้นตั้งแต่"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้อยกว่า"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูงสุดจนถึง"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือ"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงลบ"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ละเมิด"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ซับซ้อน"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจไม่พบ"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่รวม"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้รับ"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ได้ดู"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงบวก"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ได้รีวิว"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้รีวิวโดยฉัน"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวแล้ว"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้รีวิวโดยฉัน"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สําคัญสําหรับการรีวิว"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มี"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือ"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มากกว่า"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้นตั้งแต่"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้อยกว่า"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูงสุดจนถึง"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือ"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ได้ดู"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นของฉันมีการตอบกลับ"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่กำหนดเอง"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์ที่กำหนดเอง"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเงื่อนไข"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่ายสนับสนุน"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูล"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมตริก"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวและให้ฟีดแบ็ค"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเงื่อนไขทั้งหมด"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปรียบเทียบการจัดการการสนทนาของบอทของคุณกับเจ้าหน้าที่โดยทั่วไป"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทติดอยู่ในลูป โดยทำซ้ำข้อความเดิมติดต่อกันหลายครั้ง"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของบอทในการสนทนา"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทของบอทที่เกี่ยวข้องในการสนทนา"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทที่เกี่ยวข้องในการสนทนา"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เนื้อหาการสนทนาประกอบด้วยวลี ", _interpolate(_list(0)), " ในข้อความเจ้าหน้าที่"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุการโทรที่ไม่มีคําชี้แจงการเปิดเผยข้อมูลที่จําเป็นโดยอัตโนมัติ เช่น 'การโทรนี้จะถูกบันทึก' และที่คล้ายกัน"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บุคคลหรือบอทที่เกี่ยวข้องกับการสนทนา"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่หรือบอทที่กําลังถูกรีวิวในการสนทนา"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าต้องการความช่วยเหลือในระดับที่สูงขึ้น"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจพบความคิดเห็นเชิงบวกและเชิงลบในการสนทนา"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุเจ้าหน้าที่ที่ทำงานอยู่มากที่สุดในการสนทนาโดยการวิเคราะห์ด้วย AI"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่ได้สรุปการสนทนาอย่างสุภาพหรือไม่"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่เข้าใจและรับทราบความรู้สึกของลูกค้าหรือไม่"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ข้อผิดพลาดทางไวยากรณ์ การสะกดคำ และรูปแบบของเจ้าหน้าที่"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดสําหรับวลีคำทักทายทั่วไป"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่เข้าใจคําถามหรือข้อกังวลของลูกค้าหรือไม่"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าข้อความสามารถเข้าใจได้ง่ายเพียงใดโดยพิจารณาจากความซับซ้อนของคําและความยาวของประโยค"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดเพื่อหาวิธีแก้ปัญหาที่นําเสนอ"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์น้ำเสียงของเจ้าหน้าที่ตลอดการสนทนา"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญญาณของการถดถอยของลูกค้า ลูกค้ากําลังพิจารณาเปลี่ยนหรือยืนยันว่าจะแยกทาง"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่เป็นของการสนทนา"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาหรือข้อความของข้อความเจ้าหน้าที่"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของเจ้าหน้าที่ในการสนทนา"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่ได้รับมอบหมายให้รีวิวการสนทนา"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการสนทนาทางโทรศัพท์ที่ใช้ไปเป็นวินาที"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาหรือข้อความของการสนทนา"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการสื่อสารที่ใช้สําหรับการสนทนา (เช่น อีเมล แชท)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นที่เพิ่มไปยังการสนทนา"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ที่มีการเพิ่มข้อคิดเห็นในการสนทนา"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจพบความคิดเห็นเชิงบวกและเชิงลบในการสนทนา"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ลูกค้าได้ตอบกลับแบบสํารวจความพึงพอใจของลูกค้า"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการสนทนามีการโต้แย้งหรือไม่"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางที่ลูกค้าใช้เพื่อเริ่มการสนทนา"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบสนองของลูกค้าในการสนทนา"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ของข้อความสุดท้ายในการสนทนา"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาทหรือประเภทของผู้ใช้ที่เกี่ยวข้องในการสนทนา"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเงียบระหว่างเจ้าหน้าที่และลูกค้าเกินค่าเกณฑ์"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าต้องการความช่วยเหลือระดับสูง"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าแสดงความขอบคุณอย่างยิ่งหรือพอใจมากกับการสนับสนุนที่ได้รับ"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าหรือเจ้าหน้าที่ได้ร้องขอการติดตามผลอย่างชัดเจน"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความหรือบันทึกการสนทนาทั้งหมดที่ดําเนินการด้วยเสียง"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาถูกทําเครื่องหมายว่าติดดาวหรือไม่"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กที่ใช้ในข้อคิดเห็นเพื่อจัดหมวดหมู่เนื้อหา"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาของการสนทนาที่ตรวจพบโดย AI"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ของข้อความสุดท้ายในการสนทนา"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนหมายเหตุส่วนตัวในการสนทนา"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนอักขระเฉลี่ยต่อข้อความในการสนทนา"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับในการติดต่อกับลูกค้าในการสนทนา"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมที่มีข้อมูลเปิดเผยต่อสาธารณะ"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนคําเฉลี่ยต่อข้อความในการสนทนา"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่ใช้เพื่อให้คะแนนหรือประเมินการสนทนา"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะของกระบวนการรีวิวการสนทนา"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมหรือบุคคลที่เกี่ยวข้องในการสนทนา"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่เกี่ยวข้องกับการสนทนา"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีการดูรีวิวหรือไม่"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวที่ได้ประเมินการสนทนา"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ที่การสนทนาได้รับการรีวิวครั้งล่าสุด"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่กําลังถูกรีวิวในการสนทนา"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นหรือฟีดแบ็คเฉพาะใด ๆ ที่ได้รับจากลูกค้าเกี่ยวกับความพึงพอใจของพวกเขากับการสนทนา"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความพึงพอใจของลูกค้าสําหรับการสนทนา"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความพึงพอใจของลูกค้าต่อข้อความในการสนทนา"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนที่ใช้สําหรับการรีวิวการสนทนา"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA ที่กำหนดถูกละเมิด"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทของแหล่งที่มาหรือการเชื่อมต่อที่เป็นที่มาของการสนทนา"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาไม่เป็นไปตามปกติหรือไม่ใช่ทั่วไปและมีการกลับไปกลับมามากขึ้นเพื่อให้ได้ข้อสรุปของการแก้ปัญหา"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามแบบสํารวจที่เกี่ยวข้องกับเนื้อหาการสนทนา"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหรือชื่อของแบบสํารวจที่เกี่ยวข้องกับการสนทนา"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่ได้รับมอบหมายให้รีวิวการสนทนา"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบรนด์หรือผลิตภัณฑ์/บริการเฉพาะที่เกี่ยวข้องกับการสนทนา"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการสื่อสารที่ใช้สําหรับการสนทนา (เช่น อีเมล แชท)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดหรือแก้ปัญหาการสนทนา"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างการสนทนาครั้งแรก"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้างการสนทนาครั้งแรก"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ลูกค้าได้ตอบกลับแบบสํารวจความพึงพอใจของลูกค้า"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําอธิบายแบบย่อหรือข้อมูลสรุปของการสนทนา"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDอ้างอิงภายนอกที่เชื่อมโยงกับการสนทนา"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โฟลเดอร์หรือการจัดกลุ่มเฉพาะที่มีการจัดระเบียบการสนทนา"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มหรือเท็มเพลตที่ใช้สําหรับจัดโครงสร้างการสนทนา"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDอ้างอิงภายนอกที่เชื่อมโยงกับการสนทนา"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่องจดหมายหรือบัญชีอีเมลที่ได้รับการสนทนา"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขโทรศัพท์ที่เกี่ยวข้องกับการสนทนา"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับความสําคัญที่กําหนดให้กับการสนทนา"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับทั้งหมดในการสนทนา"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะหรือสถานะปัจจุบันของการสนทนา"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะปัจจุบันของการสนทนา"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวเรื่องหรือหัวข้อของการสนทนา"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของเจ้าหน้าที่ในการสนทนา"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กที่ได้กําหนดเพื่อจัดหมวดหมู่การสนทนา"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทหรือหมวดหมู่ของการสนทนา"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ที่อัปเดตการสนทนาครั้งล่าสุด"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ที่อัปเดตการสนทนาครั้งล่าสุด"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สื่อหรือช่องทางที่การสนทนาเกิดขึ้น"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาของทรานสคริปต์ในการสนทนา"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่ไม่มีสิทธิ์สำหรับการรีวิวผ่านการวิเคราะห์ AI"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนเจ้าหน้าที่ที่เข้าร่วมในการสนทนา"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีการดูการสนทนาหรือไม่"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความใดๆ"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประสิทธิภาพการสื่อสารของบอท"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทําซ้ำของบอท"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของบอท"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทบอท"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทิศทางของการโทร"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความไคลเอ็นต์"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเปิดเผยข้อมูลโดยการบันทึก"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกระดับ"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาทรานสคริปต์การโทร"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิด"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดดาวแล้ว"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีสิทธิ์สำหรับการรีวิว"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวโดย"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาผ่าน"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสะกดคำและไวยากรณ์"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความเจ้าหน้าที่"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่ใช้งานมากที่สุด"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของเจ้าหน้าที่"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการโทร (วินาที)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาการสนทนา"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางข้อความ"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กข้อคิดเห็น"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ให้ข้อคิดเห็น"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความคิดเห็น"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ตอบกลับแบบสํารวจ CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นอกเห็นใจ"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางของลูกค้า"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของลูกค้า"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ตอบกลับล่าสุด"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทผู้ใช้"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำทักทาย"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเสี่ยงในการเลิกใช้บริการ"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกระดับ"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริการที่เป็นเลิศ"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การติดตาม"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทรานสคริปต์"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กข้อคิดเห็น"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความผิดปกติ"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเข้าใจ"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ตอบกลับล่าสุด"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนหมายเหตุส่วนตัวของการสนทนา"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนอักขระเฉลี่ย"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนคําเฉลี่ย"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับแบบสาธารณะ"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมสาธารณะ"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ให้คะแนน"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนและข้อคิดเห็น"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนน"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการรีวิว"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสามารถในการอ่าน"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่เกี่ยวข้อง"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวข้อง"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับ"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนที่ได้รีวิวแล้ว"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ดู"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่รีวิว"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นของแบบสํารวจ"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT ต่อข้อความ"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความพึงพอใจ (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีแก้ปัญหาที่นําเสนอ"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทแหล่งที่มา"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อ"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามแบบสํารวจ"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบบสํารวจ"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบรนด์การสนทนา"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนแบรนด์"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการสนทนา"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิด"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ตอบกลับแบบสํารวจ CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ส่งแบบสํารวจ CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําอธิบายการสนทนา"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ภายนอก"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์การสนทนา"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โฟลเดอร์การสนทนา"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มการสนทนา"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มการสนทนา"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ภายนอก"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่องจดหมายการสนทนา"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขโทรศัพท์"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลําดับความสําคัญของการสนทนา"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับการสนทนา"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สภาวะการสนทนา"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการสนทนา"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อการสนทนา"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการตอบกลับของเจ้าหน้าที่"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการสนทนา"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปรับปรุง"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปรับปรุง"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียง"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาทรานสคริปต์"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนเจ้าหน้าที่ที่เข้าร่วม"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะที่ดู"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสามารถในการเกิดช่องโหว่"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สุขภาพของช่องโหว่"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรมชีวิตของช่องโหว่"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติ"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเงื่อนไขที่เกี่ยวข้องกับวันที่"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อไปยังตัวกรองของคุณ"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกชื่อตัวกรอง"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บุคคลที่ลงชื่อเข้าใช้"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อดีตสมาชิกพื้นที่ทํางาน"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิกพื้นที่ทํางาน"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เพิ่ม ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ตัวกรอง"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณจะต้องมีตัวกรองที่เกี่ยวข้องกับวันที่อย่างน้อย 1 รายการ"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกตัวเลือก"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกตัวเลือก"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณจะสิ้นสุดลงในอีก ", _interpolate(_named("n")), " วัน ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณจะสิ้นสุดลง <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ในอีก ", _interpolate(_named("n")), " วัน</strong></a>"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณจะสิ้นสุดในอีก ", _interpolate(_named("n")), " วัน ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณจะสิ้นสุด<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ในอีก ", _interpolate(_named("n")), " วัน</strong></a>"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณสิ้นสุดลงในวันนี้ ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทดลองใช้งานของคุณสิ้นสุดลง<a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ในวันนี้</strong></a>"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าการสมัครใช้บริการตอนนี้"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " วัน"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณเหลืออีก ", _interpolate(_named("days")), " วันในการทดลองใช้งานของคุณ"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณใช้ถึงขีดจำกัดผู้ใช้ในแผนของคุณแล้ว ", _interpolate(_named("link")), " เพื่อเรียนรู้เพิ่มเติมหรืออัปเกรดแผนของคุณ"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกที่นี่"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อโปรแกรมฝ่ายสนับสนุนของคุณ"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซึ่งจะทําให้เราสามารถดึงใบสั่งงานของคุณมาให้รีวิวได้"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อโปรแกรมฝ่ายสนับสนุนของคุณ"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณกําลังใช้ QA ของ Zendesk กับข้อมูลตัวอย่าง หากต้องการลบข้อมูลตัวอย่างและนําเข้าการสนทนาของคุณเอง ", _interpolate(_named("link"))])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลตัวอย่าง"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกข้อมูลที่เป็นประโยชน์ด้านล่าง"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถ่ายภาพหน้าจอแบบเต็มหน้าเพื่อให้บริบท"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อทีมสนับสนุนลูกค้าของเราเพื่อแก้ไขปัญหานี้:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีบางอย่างผิดพลาด"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การอ้างอิงข้อผิดพลาด:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกข้อมูลที่เป็นประโยชน์"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ บอท และพื้นที่ทํางาน"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้และพื้นที่ทํางาน"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือนนี้"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์นี้"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " รีวิวแล้ว ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " รีวิวแล้ว ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " รีวิวแล้ว ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีอะไรใหม่"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อออก"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แป้นพิมพ์ลัด"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชทกับเรา"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถโหลดแชทของเราได้ (คุณอาจใช้ตัวบล็อกโฆษณาอยู่หรือไม่)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังฝึกสอน"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้ามไปที่..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชุมชน"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความช่วยเหลือ"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าแรก"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้ให้"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฐานความรู้"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับบัญชี"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["งาน"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อัปเดตแอปพลิเคชันแล้ว ", _interpolate(_named("buttonStart")), "โหลดใหม่", _interpolate(_named("buttonEnd"))])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากคุณต้องการเริ่มใช้ QA ของ Zendesk เป็นส่วนหนึ่งของพื้นที่ทํางานของคุณ:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ขอแจ้งให้คุณทราบว่าคุณได้รับคําเชิญให้ใช้ QA ของ Zendesk เป็นส่วนหนึ่งของพื้นที่ทํางานแล้ว คุณสามารถดําเนินการต่อได้ตามที่ต้องการ แต่โปรดทราบว่าการดำเนินการนี้จะสร้าง ", _interpolate(_named("bold")), " ให้คุณ"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกองค์กร"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ใช่ ให้ฉันดําเนินการต่อด้วย ", _interpolate(_named("link"))])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีใหม่"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " ขอให้คุณเข้าร่วม ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าร่วม"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเชื่อมต่ออินเทอร์เน็ต"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการปักหมุดใหม่"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การนําทางอย่างรวดเร็ว"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โฟกัสและขยายเครื่องมือแก้ไขข้อคิดเห็น"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนหรือแสดงแผงด้านข้าง"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นําทางไปยังการสนทนาถัดไป"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดการสนทนาต้นฉบับ"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นําทางไปยังการสนทนาก่อนหน้า"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนสูงสุดที่เป็นไปได้แก่ทุกหมวดหมู่"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนต่ำสุดที่เป็นไปได้แก่ทุกหมวดหมู่"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สุ่มการสนทนา (จัดเรียงใหม่แบบสุ่ม)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดบอกให้เราทราบ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แป้นพิมพ์ลัด"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับตัวช่วยข้อความลัด"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณใช้ถึงขีดจํากัด ", _interpolate(_named("charLimit")), "อักขระแล้ว"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่อนุญาตให้ใช้ประเภทรูปภาพ ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ")"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ขนาดภาพ ", _interpolate(_named("name")), " ใหญ่เกินไป (อนุญาต 5MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถโหลดตัวแก้ไขข้อคิดเห็นได้"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางเพื่ออัปโหลด"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพ"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการเท็มเพลต"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเพิ่มเทมเพลต"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสาเหตุที่แท้จริง"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ รวมถึงเหตุผลที่ถูกลบ"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลในการให้คะแนน"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเทศ"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นามสกุล"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนพนักงาน"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขโทรศัพท์"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เราจะติดต่อคุณเพื่อช่วยคุณตั้งค่าบัญชีของคุณ"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อบริษัท"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขติดต่อของบริษัท"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณรู้จัก QA ของ Zendesk ครั้งแรกได้อย่างไร"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกช่องทาง"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกจำนวนเจ้าหน้าที่"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อ"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกข้อมูลในฟิลด์ที่จําเป็นทั้งหมด"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องตกลงว่าได้อ่านและยินยอมตามข้อกําหนดและนโยบายความเป็นส่วนตัวของเรา"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รองรับช่องทาง Slack ที่ขับเคลื่อนด้วย"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโฆษณา"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อนหรือเพื่อนร่วมงาน"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace โปรแกรมฝ่ายสนับสนุนหรือผู้จัดการบัญชี"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เว็บไซต์อ้างอิง"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เครื่องมือค้นหา (Google, Bing เป็นต้น)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โซเชียลมีเดีย"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอกเราเกี่ยวกับตัวคุณและบริษัทของคุณ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดของบัญชี"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันพร้อมแล้ว"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจดูไปมา ทําความรู้จักกับแอป ลบและเพิ่มสิ่งต่างๆ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างบัญชีแล้ว ขั้นถัดไปคืออะไร"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พร้อมที่จะใช้การสนทนาที่แท้จริงของคุณแล้วหรือไหม่"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อมูลของคุณเอง"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มสำรวจ"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองใช้กับข้อมูลตัวอย่าง"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อให้เราจึงสามารถเริ่มดึงใบสั่งงานของคุณมาให้คุณรีวิวได้"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อบัญชีฝ่ายสนับสนุนของคุณ"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันเปลี่ยนใจ"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการลงทะเบียน"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างการปักหมุดแล้ว ", _interpolate(_named("buttonStart")), "ไปที่การปักหมุด", _interpolate(_named("buttonEnd"))])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเด็นปักหมุดเกี่ยวกับการฝึกสอนพร้อมใช้งานในแผน Professional"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดแผน"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูแผนทั้งหมด"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกหมายเหตุของคุณเพื่อเตรียมพร้อมสําหรับเซสชั่นแบบ 1:1 หรือเซสชั่นการฝึกสอนครั้งถัดไป"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดสําหรับการฝึกสอน"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงข้อคิดเห็นหรือเพิ่มผู้อื่นด้วย ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนานี้ถูกลบไปแล้ว"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการปักหมุดใหม่"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการปักหมุด"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการปักหมุด"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดทํางานอย่างไร"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่มีสิทธิ์ดูการสนทนานี้"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดบัตร"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดการสนทนา"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดแดชบอร์ด"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการปักหมุดแล้ว"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดบัตรนี้"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดการสนทนานี้"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดแดชบอร์ดนี้"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดภายใต้ผู้ใช้"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีเพียงคุณเท่านั้นที่สามารถเห็นสิ่งที่คุณปักหมุดได้ หากต้องการแชร์กับอีก ", "@", " คน ให้กล่าวถึงพวกเขาในข้อคิดเห็น"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentsNames")), " ได้รับเชิญให้เข้าร่วมการสนทนานี้แล้ว ", _interpolate(_named("agentsNames")), " ได้รับเชิญให้เข้าร่วมการสนทนานี้แล้ว"])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ข้อคิดเห็น"]), _normalize([_interpolate(_named("n")), " ข้อคิดเห็น"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["คุณได้กล่าวถึง ", _interpolate(_named("participants")), " แต่พวกเขาไม่ได้อยู่ในการสนทนานี้"]), _normalize(["คุณได้กล่าวถึง ", _interpolate(_named("participants")), " แต่พวกเขาไม่ได้อยู่ในการสนทนานี้"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ผู้เข้าร่วม"]), _normalize([_interpolate(_named("n")), " ผู้เข้าร่วม"]), _normalize([_interpolate(_named("n")), " ผู้เข้าร่วม"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณต้องการลบ ", _interpolate(_named("agentName")), " ออกจากการเข้าถึงการปักหมุดนี้หรือไม่"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องดำเนินการใดๆ"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องเชิญพวกเขาเข้าร่วมการสนทนานี้"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อคิดเห็น"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญพวกเขา"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญพวกเขาเข้าร่วมการสนทนานี้"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบผู้ใช้ออก"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เธรด"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการนี้ไม่สามารถยกเลิกได้"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบข้อคิดเห็นทั้งหมดภายใต้การปักหมุดนี้ด้วย"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ข้อความใหม่"]), _normalize([_interpolate(_named("n")), " ข้อความใหม่"]), _normalize([_interpolate(_named("n")), " ข้อความใหม่"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีเพียงคุณเท่านั้นที่สามารถมองเห็นการปักหมุดของคุณ หากต้องการแชร์การปักหมุด ", "@", " บางคนในข้อคิดเห็น"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " ได้ปักหมุด ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณ"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดเพื่อรับประเด็นปักหมุดเกี่ยวกับการฝึกสอน"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเด็นปักหมุดเกี่ยวกับการฝึกสอนพร้อมใช้งานในแผน Professional"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเด็นปักหมุดเกี่ยวกับการฝึกสอนพร้อมใช้งานในแผน Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดเพื่อรับคุณสมบัติ"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เตรียมพร้อมสําหรับเซสชั่นแบบ 1:1 ของคุณหรือบันทึกบางสิ่งไว้ภายหลัง"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบการปักหมุดและข้อคิดเห็นที่เพิ่มทั้งหมดอย่างถาวร"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการปักหมุด"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการปักหมุด"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อโฟลเดอร์"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุดสิ่งต่างๆ เพื่อกลับมาดูในภายหลัง"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบกลับ"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่ที่สุดก่อน"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก่าที่สุดก่อน"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกแนวโน้มแดชบอร์ดหรือการสนทนาที่น่าสนใจเพื่อเตรียมพร้อมสําหรับเซสชั่น 1-1 ของคุณ หรือจดบันทึกไว้อีกครั้ง"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการปักหมุดโดยคลิกที่ปุ่มด้านบนหรือไอคอนปักหมุดในมุมมองการสนทนาหรือแดชบอร์ด"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปักหมุด"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการปักหมุดถูกสร้าง"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่พบผลลัพธ์สำหรับ ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองใช้คีย์เวิร์ดอื่นหรือตรวจสอบการพิมพ์ผิด"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการปักหมุดใหม่"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้คน"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามผู้ใช้ คีย์เวิร์ด..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียงตามตัวอักษร"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างล่าสุด"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขล่าสุด"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดมากที่สุด"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ฉันมุ่งมั่นที่จะรีวิว ", _interpolate(_named("n")), " การสนทนา ", _interpolate(_named("schedule"))]), _normalize(["ฉันมุ่งมั่นที่จะรีวิว ", _interpolate(_named("n")), " สนทนา ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตเป้าหมายแล้ว"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งเป้าหมาย"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถกําหนดเป้าหมายส่วนบุคคลสําหรับจํานวนการสนทนาที่คุณต้องการรีวิวได้"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกวัน"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกเดือน"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกสัปดาห์"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายรีวิว"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนบุคคล"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั่วไป"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เท็มเพลต"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําลังโหลดช่องทาง"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกช่องทาง"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์ฟีดแบ็คแบบสํารวจที่มีข้อคิดเห็นเท่านั้น"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มประสิทธิภาพให้เพื่อนร่วมงานของคุณโดยเน้นคุณภาพการบริการที่ยอดเยี่ยมและฟีดแบ็คของลูกค้าที่ยอดเยี่ยม"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์ฟีดแบ็คแบบสํารวจเชิงบวกกับ Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบช่องทาง"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชร์ฟีดแบ็คโดยอัตโนมัติด้วยคะแนนด้านบน"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบ Praisebot"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับการแจ้งเตือนเมื่อเริ่มต้นสัปดาห์หากคุณยังไม่ได้ทํารีวิว"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเตือน"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เตือนเป็นรายสัปดาห์ให้ทํารีวิว"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับการแจ้งเตือนเกี่ยวกับรีวิว การตอบกลับ การกล่าวถึง และการโต้แย้งใหม่ใน Slack"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การกล่าวถึง"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนน"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบกลับเธรด"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบ Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเกี่ยวกับรีวิว การตอบกลับ การกล่าวถึง และการโต้แย้งใหม่"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่เกี่ยวข้องกับฉัน"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่าวถึงในข้อคิดเห็น"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับใหม่"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบกลับในเธรด"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานพร้อมสถิติ"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรายงานเกี่ยวกับรีวิวที่ได้ให้และได้รับ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงาน"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวัน"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายสัปดาห์"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวัน"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุก 3 ชั่วโมง"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุก 6 ชั่วโมง"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทันที"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายสัปดาห์"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ดูแลบัญชีของคุณได้ล็อกการตั้งค่าการแจ้งเตือน"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกระยะเวลา"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือนส่วนบุคคล"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (ใช้งานอยู่)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการลงชื่อเข้าใช้โซเชียลใหม่ไปยังบัญชีนี้:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบวิธีการลงชื่อเข้าใช้ที่ใช้งานอยู่จะทําให้คุณออกจากระบบ คุณสามารถลงชื่อเข้าใช้อีกครั้งด้วยอีเมลสํารองของคุณ"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอาวิธีการลงชื่อเข้าใช้ออก"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม Google เป็นตัวเลือกการลงชื่อเข้าใช้สําเร็จแล้ว"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกการลงชื่อเข้าใช้"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม Slack เป็นตัวเลือกการลงชื่อเข้าใช้สําเร็จแล้ว"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่าน"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออก"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธี"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ตรหัสผ่าน"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อีเมลรีเซ็ตรหัสผ่านของคุณจะถูกส่งไปที่ ", _interpolate(_named("email"))])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลหลัก"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบอีเมลยืนยันของเราใน Inbox ของคุณ"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์มหัศจรรย์"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการเปลี่ยนแปลง ให้เปลี่ยน ", _interpolate(_named("link")), " ของคุณล่วงหน้า"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อีเมล ", _interpolate(_named("email")), " จะถูกเชื่อมโยงกับรหัสผ่านนี้"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตโปรไฟล์แล้ว"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพโปรไฟล์"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อผลลัพธ์ที่ดีที่สุด ให้อัปโหลดภาพถ่ายสี่เหลี่ยมจัตุรัส (256x256 พิกเซลขึ้นไป)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลหลัก"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้นสัปดาห์"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าส่วนบุคคล"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกอย่างน้อยหนึ่งตัวเลือก"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาเท็มเพลต"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเท็มเพลต"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเท็มเพลตใน"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเนื้อหาเท็มเพลต"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อเท็มเพลต"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลต"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลต"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลตข้อคิดเห็นเพื่อเพิ่มความเร็วในการรีวิว การปักหมุดรายการ และการฝึกสอน"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ใน"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเท็มเพลต"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเท็มเพลตนี้อย่างถาวรหรือไม่"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขเท็มเพลต"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลตข้อคิดเห็นเพื่อเพิ่มความเร็วในการรีวิว การปักหมุดรายการ และการฝึกสอน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเท็มเพลตถูกสร้าง"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลตข้อคิดเห็นแล้ว"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเท็มเพลตข้อคิดเห็นแล้ว"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเท็มเพลตข้อคิดเห็นแล้ว"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังฝึกสอน"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุด"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวและการโต้แย้ง"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการรับสิทธิ์เข้าถึง โปรดติดต่อ ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนลูกค้า"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบบัญชี"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สําหรับตอนนี้ ให้ใช้ ", _interpolate(_named("link")), "เพื่อเข้าถึง QA ของ Zendesk"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่ Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีไม่ได้ย้ายข้อมูล"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท QA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวม"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจ"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมตริก"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถิติ"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้ให้"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นของฉัน"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งของฉัน"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็นของฉัน"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนอง"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ได้รับ"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่เริ่มต้น"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คแบบสำรวจ"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กิจกรรม"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุด"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังฝึกสอน"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนบุคคล"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เท็มเพลต"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างรหัสผ่าน"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลประจำตัว"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเซ็ต"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายรีวิว"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เท็มเพลต"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเท็มเพลต"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั่วไป"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เท็มเพลต"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชี"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการ"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชี"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเรียกเก็บเงิน"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริษัท"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่ายสนับสนุน"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบ"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["องค์กร"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงิน"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นั่ง"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการ"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับรองความถูกต้อง"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั่วไป"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ใหม่"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการ"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจ"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบข้อความที่ขัดแย้ง"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนหรือเอาข้อความที่ขัดแย้งออกหนึ่งข้อความ"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ใช้ไอคอน ", _interpolate(_named("placeholder")), " เพื่อยกเว้นข้อมูลที่ละเอียดอ่อนจาก QA โดยอัตโนมัติ ตัวอย่างเช่น แทนที่จะใช้ \"ดูแลสุขภาพด้วยนะ John\" ให้ใช้ \"ดูแลสุขภาพด้วยนะ ", _interpolate(_named("placeholder")), "\" เพื่อแทนชื่อใดก็ได้"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สูงสุด ", _interpolate(_named("limit")), " แถว"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานกฎกับ"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณใช้ถึงขีดจํากัดหมวดหมู่ที่กําหนดเอง 10 รายการแล้ว"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณใช้ถึงขีดจํากัดหมวดหมู่ที่กําหนดเอง ", _interpolate(_named("limit")), " รายการแล้ว"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเงื่อนไข"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มแถว"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["และ"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเงื่อนไข"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากข้อความเจ้าหน้าที่"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หาก"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนไม่ใช่"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนจะเป็น"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่ดัชนีชี้วัด"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ '/' เพื่อเพิ่มตัวแปร"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกทิศทาง"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความเจ้าหน้าที่ไม่สามารถว่างเปล่าได้"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความต้องมีความยาวไม่เกิน 300 อักขระ"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มาทั้งหมด"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางทั้งหมด"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กทั้งหมด"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาทั้งหมด"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํากัดตําแหน่งที่กฎของคุณมีผลบังคับใช้ให้แคบลง"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่เฉพาะเจาะจง"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างข้อมูลเชิงลึกของสปอตไลท์"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความเจ้าหน้าที่ไม่สามารถว่างเปล่าได้"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีถึงขีดจํากัดข้อมูลเชิงลึกที่กําหนดเอง 10 รายการแล้ว"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณมีถึงขีดจํากัดข้อมูลเชิงลึกที่กําหนดเอง ", _interpolate(_named("limit")), " รายการแล้ว"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกชื่อข้อมูลเชิงลึก"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อข้อมูลเชิงลึก"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทข้อมูลเชิงลึก"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงลบ"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป็นกลาง"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงบวก"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปที่การตั้งค่าพื้นที่ทํางาน"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ตอนนี้"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อต้องการเริ่มใช้หมวดหมู่การให้คะแนนนี้ ให้ไปที่การตั้งค่าแบบฟอร์มลงคะแนนพื้นที่ทํางานของคุณและเพิ่มไปยังแบบฟอร์มลงคะแนนที่คุณเลือก"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่การให้คะแนนแล้ว"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนการสนทนาที่ปิดโดยอัตโนมัติโดยระบุคีย์เวิร์ดหรือวลีเฉพาะ"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ไม่ได้กับทรานสคริปต์เสียง"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าใจแล้ว"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แปะมือ! ✋ คุณมีหมวดหมู่การให้คะแนนโดยอัตโนมัติของคุณสูงสุดที่ ", _interpolate(_named("limit")), " แล้ว คุณคือพ่อมดแห่งการจัดหมวดหมู่! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึงขีดจํากัดหมวดหมู่แล้ว"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่ากรอกข้อมูลในฟิลด์ทั้งหมดอย่างถูกต้อง"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งชื่อหมวดหมู่การให้คะแนนของคุณ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อหมวดหมู่"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนนใหม่"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนนใหม่"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนการสนทนาที่ปิดโดยอัตโนมัติโดยระบุคีย์เวิร์ดหรือวลีเฉพาะ ใช้ไม่ได้กับทรานสคริปต์เสียง"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงการสนทนาที่ปิดโดยอัตโนมัติโดยระบุคีย์เวิร์ดหรือวลีเฉพาะ ใช้งานได้กับทรานสคริปต์เท่านั้น"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนการสนทนาโดยอัตโนมัติโดยระบุคีย์เวิร์ดหรือวลีเฉพาะในข้อความของเจ้าหน้าที่และบอท"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อต้องการใช้หมวดหมู่การให้คะแนนนี้ ให้ไปที่การตั้งค่าแบบฟอร์มลงคะแนนพื้นที่ทํางานของคุณและเพิ่มไปยังแบบฟอร์มลงคะแนน"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนการสนทนาโดยอัตโนมัติอิงตามคีย์เวิร์ดหรือวลีเฉพาะในข้อความ"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ได้สำหรับ"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าข้อมูลเชิงลึก"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกนี้จะแสดงในสปอตไลท์อิงตามเกณฑ์ที่กําหนดไว้"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าแบบฟอร์มลงคะแนน"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่นี้สามารถใช้กับแบบฟอร์มลงคะแนนอิงตามเกณฑ์ที่กําหนด"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชท"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เร็วๆ นี้"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเอง"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่กําหนดไว้ล่วงหน้า"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสียง"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการเปลี่ยนแปลง"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการเปลี่ยนแปลงแล้ว"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำแนะนำ:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกที่ไอคอน ", "{··}", " เพื่อยกเว้นชื่อ รายละเอียดบริษัท หรือข้อมูลที่ละเอียดอ่อนอื่นๆ ในระหว่างการตรวจสอบการปิดของ QA โดยอัตโนมัติ"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการปิดที่ได้รับอนุมัติ"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการปิดที่ได้รับอนุมัติ"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิดที่ได้รับอนุมัติ"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการละเว้น"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการละเว้น"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การละเว้น"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคําทักทายที่ได้รับอนุมัติ"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขคําทักทายที่ได้รับอนุมัติ"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำทักทายที่ได้รับอนุมัติ"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางคําที่นี่โดยคั่นด้วยการขึ้นบรรทัดใหม่"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กด Enter หลังจากแต่ละคําเป็นรายการใหม่"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาที่ใช้ได้"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างเช่น แทนที่จะใช้ \"ดูแลสุขภาพด้วยนะ John\" คุณสามารถใช้ \"ดูแลสุขภาพด้วยนะ ", "{··}", "\" แทนชื่อใดก็ได้"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำแทนเนื้อหาแบบไดนามิก"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การละเว้น"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เพิ่มการละเว้น 1 รายการแล้ว"]), _normalize(["เพิ่มการละเว้น ", _interpolate(_named("count")), " รายการแล้ว"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["การละเว้นถูกเพิ่มแล้ว"]), _normalize(["การละเว้นถูกเพิ่มแล้ว: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการละเว้น"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการละเว้น"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการละเว้นถูกเพิ่ม"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การละเว้น (ตรงตามตัวพิมพ์เล็กและตัวพิมพ์ใหญ่)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เพิ่มการละเว้น"]), _normalize(["เพิ่มการละเว้น 1 รายการ"]), _normalize(["เพิ่มการละเว้น ", _interpolate(_named("count")), " รายการ"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาคําหรือวลี"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการละเว้นแล้ว"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาทั้งหมด"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาภาษา"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["การปิดถูกเพิ่มแล้ว"]), _normalize(["การปิดถูกเพิ่มแล้ว: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการปิด"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการปิดที่ได้รับอนุมัติ"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการปิดที่ได้รับอนุมัติ"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิด"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการปิด"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาการปิด"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการปิดแล้ว"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึการปิดแล้ว"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แบบฟอร์มมีรายการที่ซ้ำกัน: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["คำทักทายถูกเพิ่มแล้ว"]), _normalize(["คำทักทายถูกเพิ่มแล้ว: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำทักทาย"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบคําทักทายที่ได้รับอนุมัติ"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีคําทักทายที่ได้รับอนุมัติ"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำทักทาย"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำทักทาย"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาคําทักทาย"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคําทักทายแล้ว"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกคําทักทายแล้ว"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการละเว้น"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลบการละเว้น"]), _normalize(["ลบการละเว้น"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลบการละเว้นนี้อย่างถาวรหรือไม่"]), _normalize(["ลบการละเว้นนี้อย่างถาวรหรือไม่"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการละเว้น"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["มีการละเว้นอย่างน้อยหนึ่งรายการมีความยาวมากกว่า ", _interpolate(_list(0)), " อักขระ"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " อักขระ"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตแล้ว"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตโดย"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวแปร"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดการลงชื่อออกของคุณเองที่สอดคล้องกับแบรนด์และมาตรฐานการบริการของคุณ เมื่อเพิ่มแล้ว QA โดยอัตโนมัติจะตรวจจับการปิดที่ได้รับอนุมัติเท่านั้น การเปลี่ยนแปลงที่เกิดขึ้นจะนำไปใช้กับการสนทนาในอนาคต"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้ปิดถึงขีดจำกัดสูงสุดที่ได้รับอนุมัติ 100 รายการแล้ว หากคุณต้องการเพิ่มอีก อาจถึงเวลาทบทวนและปรับแต่งการปิดที่มีอยู่ของคุณ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึงขีดจํากัดจำนวนการปิดที่ได้รับอนุมัติแล้ว"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการปิดที่ได้รับอนุมัติ"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําและวลีที่คุณต้องการให้ QA โดยอัตโนมัติละเว้นเป็นข้อผิดพลาดการสะกดผิดหรือไวยากรณ์ การเปลี่ยนแปลงที่เกิดขึ้นจะนำไปใช้กับการสนทนาในอนาคต"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละเว้นรายการ"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุคําทักทายของคุณเองที่สอดคล้องกับแบรนด์และมาตรฐานการบริการของคุณ เมื่อเพิ่มแล้ว QA โดยอัตโนมัติจะตรวจจับเฉพาะคําทักทายที่ได้รับอนุมัติเท่านั้น การเปลี่ยนแปลงที่เกิดขึ้นจะนำไปใช้กับการสนทนาในอนาคต"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีคำทักทายถึงขีดจํากัดสูงสุดที่ได้รับอนุมัติ 100 รายการแล้ว หากคุณต้องการเพิ่มอีก อาจถึงเวลาทบทวนและปรับแต่งคําทักทายที่มีอยู่ของคุณ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถึงขีดจํากัดจำนวนคำทักทายที่ได้รับอนุมัติแล้ว"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนคําทักทายที่ได้รับอนุมัติ"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่ตอนนี้"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่การให้คะแนนนี้อย่างถาวรหรือไม่"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการหมวดหมู่"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่ถูกลบจะไม่ปรากฏในการสนทนาและตัวกรองใหม่ ข้อมูลในอดีตบนแดชบอร์ดและการสนทนายังคงไม่เปลี่ยนแปลง"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่จะถูกลบออกจากแบบฟอร์มลงคะแนนทั้งหมดที่เพิ่มไว้"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถลบหมวดหมู่แบบฟอร์มลงคะแนน"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่แบบฟอร์มลงคะแนนแล้ว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่แบบฟอร์มลงคะแนน"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ของ QA โดยอัตโนมัติ"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " ขับเคลื่อนโดยโมเดล AI จะยังคงอยู่ในแบบฟอร์มลงคะแนนแต่จะไม่ได้รับคะแนนโดยอัตโนมัติอีกต่อไป"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิดการผสานระบบ LLM จะไม่ปิดคุณสมบัติ QA โดยอัตโนมัติโดยสมบูรณ์ นี่คือสิ่งที่จะเกิดขึ้น:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลประวัติ"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " จะยังคงอยู่บนแดชบอร์ด QA โดยอัตโนมัติ"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสาเหตุที่แท้จริงด้วยตนเองแล้ว"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " จะยังคงอยู่แต่สาเหตุที่แท้จริงทั้งหมดอิงตาม LLM จะถูกลบออกไป"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด QA โดยอัตโนมัติอิงตาม LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติใช้ AI และโมเดลภาษาขนาดใหญ่ (LLM) เพื่อให้คะแนนหมวดหมู่เฉพาะโดยอัตโนมัติ ข้อมูลของคุณจะได้รับการประมวลผลภายใต้ข้อตกลงการประมวลผลข้อมูลที่เข้มงวด (DPA) และจะไม่ถูกใช้ในการฝึกโมเดลใดๆ"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับ AI เชิงสร้างใหม่"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["QA โดยอัตโนมัติใช้บริการ Microsoft Azure OpenAI สําหรับ ", _interpolate(_named("automatically_scoring_categories")), " ข้อมูลของคุณได้รับการประมวลผลภายใต้กฎระเบียบ DPA ที่เข้มงวดเท่านั้นเพื่อให้การทำงาน QA โดยอัตโนมัติแก่คุณและ ", _interpolate(_named("never_used_for_training_models"))])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้คะแนนบางหมวดหมู่โดยอัตโนมัติ"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เคยใช้เพื่อฝึกสอนโมเดล OpenAI ใดๆ"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด QA โดยอัตโนมัติอิงตาม LLM แล้ว"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด QA โดยอัตโนมัติอิงตาม LLM แล้ว"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติอิงตาม LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนอัตโนมัติด้วย AI ใน QA โดยอัตโนมัติ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การประมวลผลข้อมูลของหมวดหมู่ QA โดยอัตโนมัติที่ใช้ MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนความยินยอมใน Microsoft Azure แล้ว"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้ความยินยอมใน Microsoft Azure แล้ว การสนทนาจะได้รับการให้คะแนนอัตโนมัติในไม่ช้า"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังสามารถใช้ QA โดยอัตโนมัติได้ แต่จะมีสิ่งต่อไปนี้กกิดขึ้น"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิดใช้งานบริการ MS Azure OpenAI จะไม่ได้ปิดฟังก์ชันการทํางาน QA โดยอัตโนมัติ"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถทําได้ในมุมมองหมวดหมู่แบบฟอร์มลงคะแนน"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติ ที่ขับเคลื่อนโดยโมเดลจะอยู่ในแบบฟอร์มลงคะแนน แต่จะไม่ได้ให้คะแนนโดยอัตโนมัติ"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริงที่คุณเพิ่มด้วยตนเองจะยังคงอยู่ สาเหตุที่แท้จริงทั้งหมดที่สร้างแบบจําลองทั้งหมดจะถูกลบออก"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลในอดีตจะอยู่ในแดชบอร์ด QA โดยอัตโนมัติ"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดใช้งานการประมวลผลข้อมูลโดยบริการ MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติที่มีช่วงหลากหลายขึ้น"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนหลายภาษาที่พร้อมใช้งาน"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเพิ่มหมวดหมู่ใหม่อย่างรวดเร็ว"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับปรุงความแม่นยําในการประเมิน GPT-3.5 แสดงการรับรู้ตามบริบทระดับมนุษย์"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับประโยชน์จากความก้าวหน้าล่าสุดของเทคโนโลยี AI"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่คือสิ่งที่คุณจะได้รับ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งานการประมวลผลข้อมูลโดยบริการ MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้ความยินยอมแล้ว"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้ให้ความยินยอม"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ใหม่ทั้งหมถูกมอบหมายให้กับ พื้นที่ทำงานนี้"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทำงานเริ่มต้น"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ระบบนาฬิกาสิบสองชั่วโมง (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ระบบนาฬิกายี่สิบสี่ชั่วโมง (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบเวลา"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ."])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อา."])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตโปรไฟล์บริษัทแล้ว"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้นสัปดาห์"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบที่กำหนดเอง"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดยทั่วไป ID คีย์และคีย์ข้อความลับจะพบได้ภายใต้การจัดการการเข้าถึง > ผู้ใช้"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID คีย์การเข้าถึง AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการเปลี่ยนแปลงคีย์การเข้าถึงความลับ"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนค่าคีย์การเข้าถึงความลับ"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่ออนุญาตให้กำหนดได้เพียงหนึ่งภูมิภาคซึ่งโดยทั่วไปจะพบภายใต้ตำแหน่งที่ตั้ง"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภูมิภาค AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคีย์ข้อความลับ"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คีย์การเข้าถึงความลับ AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถค้นหาข้อมูลที่เกี่ยวข้องทั้งหมดใน Amazon Connect"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดยทั่วไปจะพบภายใต้การตั้งค่าอินสแตนซ์ Amazon Connect และหน้าการจัดเก็บข้อมูล"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำแหน่งจัดเก็บบันทึกการโทร"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีข้อผิดพลาดในขณะเพิ่ม Amazon Connect ตรวจสอบว่ากรอกข้อมูลในทุกฟิลด์ถูกต้องแล้วลองอีกครั้ง"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตำแหน่งจัดเก็บควรเป็นรูปแบบบัคเก็ต/โฟลเดอร์ที่คั่นด้วยเครื่องหมายทับ"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการเพิ่มการเชื่อมต่อ โปรดติดต่อ ", _interpolate(_named("link"))])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนลูกค้า"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบสั่งงาน Zendesk ทั้งหมดของคุณจะซิงค์โดยอัตโนมัติ คุณสามารถเลือกยกเว้นใบสั่งงานที่เลือก"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเชียแปซิฟิก (โตเกียว)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเชียแปซิฟิก (โซล)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเชียแปซิฟิก (โอซาก้า)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเชียแปซิฟิก (มุมไบ)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอเชียแปซิฟิก (ซิดนีย์)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แคนาดา (ภาคกลาง)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุโรป (แฟรงก์เฟิร์ต)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุโรป (ซูริค)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุโรป (ไอร์แลนด์)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยุโรป (ลอนดอน)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภูมิภาคเซิร์ฟเวอร์"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตะวันออกกลาง (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกภูมิภาคเซิร์ฟเวอร์"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อเมริกาใต้ (เซาเปาลู)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหรัฐอเมริกาฝั่งตะวันออก (เวอร์จิเนีย)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหรัฐอเมริกาฝั่งตะวันออก 2 (โอไฮโอ)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สหรัฐอเมริกาฝั่งตะวันตก (ออริกอน)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อแล้ว"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มการเชื่อมต่อ"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซิงค์เฉพาะการสนทนาที่"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางหนึ่ง, ช่องทางสอง"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ที่ไม่ซ้ำของการเชื่อมต่อนี้คือ ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 เดือน"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ปี"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 เดือน"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 เดือน"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่ไม่ได้ใช้งานจะถูกลบหลังจากพ้นระยะเวลาเก็บรักษาที่เลือก รีวิวที่ส่งแล้วจะยังคงปรากฏในมุมมองกิจกรรมและแดชบอร์ด"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการเก็บรักษา"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกระยะเวลาเก็บรักษา"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดทราบว่าการดําเนินการนี้ไม่สามารถย้อนกลับได้"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันเข้าใจว่าการดำเนินการนี้จะลบข้อมูลทั้งหมดที่เกี่ยวข้องกับฝ่ายสนับสนุนสำหรับการเชื่อมต่อนี้จาก QA ของ Zendesk รวมถึงการสนทนา ฟิลด์ และแท็ก"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ส่งมาจะไม่ได้รับผลกระทบและจะยังคงมองเห็นได้ในมุมมองที่เฉพาะเจาะจง"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่หนึ่ง, กลุ่มที่สอง"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การพัฒนาที่ใช้งานอยู่"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การผสานระบบนี้ยังอยู่ใน ", _interpolate(_named("activeDevelopment")), " รายละเอียดบางอย่างอาจขาดหายไป (เช่น ไฟล์แนบหรือฟิลด์ที่กำหนดเอง)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่องจดหมายหนึ่ง, กล่องจดหมายสอง"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กที่หนึ่ง, แท็กที่สอง"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทเค็น API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความลับของโทเค็น API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา \"ชื่อฟิลด์ใบสั่งงาน\" บน Freshdesk ภายใต้ฟิลด์การตั้งค่า → ฟิลด์ใบสั่งงาน"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา \"Custom Field Names\" ใน Help Scout ภายใต้ Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา \"ชื่อการสนทนา Klass \" บน Kustomer ภายใต้การตั้งค่า → Klasses → การสนทนา"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา \"ชื่อฟิลด์ที่กําหนดเอง\" บน Live Agent ภายใต้การตั้งค่า → ฟิลด์ที่กำหนดเอง"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา \"ชื่อฟิลด์ที่กําหนดเอง\" บน Wix ภายใต้การตั้งค่า → ฟิลด์ที่กำหนดเอง"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยก ID ฟิลด์ ด้วยเครื่องหมายจุลภาค คุณสามารถค้นหา ID เหล่านี้ได้ใน Zendesk ภายใต้ผู้ดูแล → ฟิลด์ใบสั่งงาน"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผู้ใช้โปรแกรมฝ่ายสนับสนุนของคุณสองสามรายเพื่อรีวิวการสนทนาครั้งแรกของคุณ ไม่ต้องกังวล เราจะไม่แจ้งให้พวกเขาทราบเว้นแต่คุณจะเชิญพวกเขาให้เป็นสมาชิกพื้นที่ทํางาน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผสานระบบสําเร็จ"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอนนี้เราจะเริ่มดึงการสนทนาของคุณ"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["โดเมน: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["รหัสระบุฝ่ายสนับสนุน: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถรับรองความถูกต้องการเชื่อมต่อของคุณ"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พร้อมแล้ว นี่คือการเชื่อมต่อใหม่ของคุณ"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อบางรายการยังไม่ได้รับการอัปเดตหรือมีสถานะไม่ใช้งาน ลองอัปเดตการเชื่อมต่อเหล่านี้หรือลบออกจากรายการของคุณ"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ไคลเอ็นต์ API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความลับของไคลเอ็นต์ API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างโทเค็น API ใหม่จากการตั้งค่า → บัญชี → API และ SDK URL เปลี่ยนเส้นทางที่จะใช้คือ ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID บัญชี"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกโปรแกรมฝ่ายสนับสนุนของคุณเพื่อเริ่มต้นใช้งาน"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากบัญชี Intercom ของคุณได้รับการตั้งค่าให้บริการจากสหภาพยุโรปหรือออสเตรเลีย ให้อัปเดตบัญชีนี้"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["อีเมลที่คุณใช้ลงชื่อเข้าใช้ ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดบังชื่อ อีเมล และหมายเลขโทรศัพท์ของผู้ใช้งาน ข้อมูลประจําตัวของธนาคารถูกปิดบังสําหรับการเชื่อมต่อฝ่ายสนับสนุนทั้งหมดแล้ว"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการผสานระบบ เพิ่มตอนนี้เลย"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องมีบทบาทเหล่านี้:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการเชื่อมต่อ"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อใหม่"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกการเลือกจะทําให้คุณสามารถเพิ่มการเชื่อมต่อนี้ไปยังพื้นที่ทํางานเฉพาะได้ด้วยตนเอง"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อกับพื้นที่ทํางานที่มีอยู่ทั้งหมด"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานทั้งหมด"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คีย์ API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความลับของคีย์ API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ลบการเชื่อมต่อ ", _interpolate(_list(0)), " แล้ว"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อการเชื่อมต่อ"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเมื่อ"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถรับโทเค็น API ของคุณได้ด้านล่าง"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเกณฑ์ CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบการเชื่อมต่อ ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์ \"ลบการเชื่อมต่อ\" เพื่อลบการเชื่อมต่อนี้"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการเชื่อมต่อ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการและตรวจสอบแหล่งข้อมูลที่เชื่อมต่อของคุณ"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการเชื่อมต่อ"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่รวม"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกชื่อฟิลด์ด้วยเครื่องหมายจุลภาค"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การอัปเดตล้มเหลว"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งชื่อที่ไม่ซ้ำให้กับการผสานระบบของคุณ"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนตัวเลือกขั้นสูง"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนฟิลด์ข้อมูลที่ละเอียดอ่อน"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จัดเก็บ URL ไฟล์แนบเสมอ"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละเว้นไฟล์แนบ"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จัดเก็บเนื้อหาการสนทนาเสมอ (คุณสามารถดึงเนื้อหานั้นได้ตามต้องการจากโปรแกรมฝ่ายสนับสนุนของคุณ)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละเว้นเนื้อหาการสนทนา"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จัดเก็บหัวข้อการสนทนาเสมอ (คุณสามารถดึงเนื้อหานั้นได้ตามต้องการจากโปรแกรมฝ่ายสนับสนุนของคุณ)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละเว้นบรรทัดหัวข้อ"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช้งาน"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["โดเมนย่อย ", _interpolate(_list(0)), " ของคุณ"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภูมิภาคของ Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จับคู่ ID ทีม Inbox"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ตัวเลขของทีม Intercomของคุณ"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังดำเนินการ..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่องจดหมาย"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการเชื่อมต่อด้วยตนเอง"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดบังข้อมูลลูกค้า"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีพื้นที่ทํางาน"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อฟิลด์ที่ละเอียดอ่อน"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ฟิลด์ที่ละเอียดอ่อน"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ออายุการเชื่อมต่อ"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ออายุการเชื่อมต่อ"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อกับ Sandbox ของ Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การตั้งค่าการเชื่อมต่อกับ ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวเลือกขั้นสูง"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดึงการสนทนาที่มีคะแนนต่ำกว่าหรือเท่ากับค่าเกณฑ์ที่ตั้งไว้เท่านั้น"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ค่าเกณฑ์ CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซิงค์เฉพาะการสนทนาที่..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับการสนทนาล่าสุด"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็ก"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการเชื่อมต่อ"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการเชื่อมต่อแล้ว"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรแกรมฝ่ายสนับสนุนของคุณ"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการยกเลิกการสมัครใช้บริการและลบบัญชี โปรดติดต่อ ", _interpolate(_named("link"))])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนับสนุนลูกค้า"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบบัญชี"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบใบสั่งงานใน Zendesk Support (หรือฝ่ายสนับสนุนอื่น) จะไม่ส่งผลต่อการรีวิวการสนทนาที่เกี่ยวข้อง คุณยังสามารถดูและจัดการได้"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลบใบสั่งงานใน QA ของ Zendesk จะลบรีวิวการสนทนาที่เกี่ยวข้องด้วย", _interpolate(_named("line_break")), "การลบใบสั่งงานใน Zendesk Support (หรือแอป ฝ่ายสนับสนุนอื่น) จะไม่ส่งผลต่อการรีวิวของการสนทนาที่เกี่ยวข้อง คุณยังสามารถดูและจัดการได้"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการรีวิว"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบรีวิวเป็นการลบถาวร นอกจากนี้ยังส่งผลต่อข้อมูลบนแดชบอร์ด"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบรีวิวทั้งหมด"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบทั้งหมด"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบรีวิวทั้งหมดแล้ว"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบทั้งหมด"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อมีรีวิวที่เกี่ยวข้องกับใบสั่งงานที่ถูกลบ คุณจะเห็นได้ที่นี่"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีรีวิว"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา ID ใบสั่งงาน"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ใบสั่งงาน: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่ลบ"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่สามารถลบพื้นที่ทำงานเริ่มต้นได้ หากต้องการลบพื้นที่ทำงานนี้ ให้เลือก พื้นที่ทำงานเริ่มต้นอื่นใน ", _interpolate(_named("link"))])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าบัญชี"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการนี้เลิกทำไม่ได้"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบและลบข้อมูลทั้งหมด"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ฉันเข้าใจว่าการดำเนินการนี้จะ ", _interpolate(_named("removeAllData")), " ที่เกี่ยวข้องกับพื้นที่ทํางานนี้ รวมถึงผู้ใช้พื้นที่ทํางานและการเชื่อมต่อ"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบพื้นที่ทำงาน"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์ \"ลบพื้นที่ทํางาน\" เพื่อลบพื้นที่ทํางานนี้"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบพื้นที่ทำงาน ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบพื้นที่ทำงาน"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจยืนยันสิ่งที่คุณกําลังจะทําหรือปรึกษามืออาชีพล่วงหน้า"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกว่าจะเลือกผู้รับการรีวิวเริ่มต้นอิงตามเจ้าหน้าที่ที่ใช้บ่อยที่สุดหรือผู้รับมอบหมายปัจจุบัน"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่ใช้งานมากที่สุด"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวเริ่มต้น"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้ผู้ใช้รีวิวการสนทนาของตนเองและทำให้เจ้าหน้าที่ดําเนินการรีวิวได้"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานถูกลบไปแล้ว"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานถูกอัปเดตแล้ว"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการให้คะแนนที่เป็นกลางโดยการซ่อนรีวิวที่ได้รับจากผู้รีวิวรายอื่นก่อนหน้านี้"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนที่เป็นกลาง"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สี"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการนี้เลิกทำไม่ได้"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดทราบว่าการดําเนินการนี้จะลบและลบข้อมูลทั้งหมดที่เกี่ยวข้องกับพื้นที่ทํางานนี้ รวมถึงผู้ใช้ของพื้นที่ทํางานและการเชื่อมต่อ"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบพื้นที่ทำงาน"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังลบพื้นที่ทํางาน..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวตนเอง"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์มหัศจรรย์"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์มหัศจรรย์"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถปิดใช้งานวิธีการลงชื่อเข้าใช้ปัจจุบันของคุณได้"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตวิธีการรับรองความถูกต้องแล้ว"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตวิธีการรับรองความถูกต้องที่ต้องการสําหรับผู้ใช้ทั้งหมด"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับรองความถูกต้อง"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชี"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้และพื้นที่ทํางาน"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างและจัดการพื้นที่ทํางาน และส่งคำเชิญหรือเชื่อมต่อผู้ใช้"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ในหน้า"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากหน้า"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากหน้านี้และจะสูญเสียการเปลี่ยนแปลงทั้งหมดหรือไม่"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับรองความถูกต้อง"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการ"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าไม่มีการสนทนาที่จะนำเข้า เริ่มต้นด้วยการสนทนาบางอย่างกับฝ่ายสนับสนุนของคุณ จากนั้นกลับมาที่นี่เพื่อวิเคราะห์"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการสนทนา"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่สามารถลบหมวดหมู่ของระบบนี้ได้"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบหมวดหมู่เป็นการดำเนินการแบบถาวร ระบบจะลบข้อมูลหมวดหมู่ทั้งหมดออกจากการรายงาน"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการคืนค่าหมวดหมู่นี้ คุณจะต้องสร้างหมวดหมู่นี้อีกครั้ง"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาหมวดหมู่"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่อัตโนมัติจะเติมแบบฟอร์มลงคะแนนโดยอัตโนมัติด้วยการให้คะแนนที่แนะนําเพื่อประหยัดเวลาในการรีวิว หมวดหมู่ด้วยตนเองจะต้องเติมข้อมูลโดยผู้รีวิวตั้งแต่เริ่มต้น"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตล่าสุด"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตโนมัติ"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ด้วยตนเอง"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิด"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นอกเห็นใจ"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสะกดคำและไวยากรณ์"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำทักทาย"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเข้าใจ"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสามารถในการอ่าน"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีแก้ปัญหาที่นําเสนอ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียง"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบหมวดหมู่"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบสาเหตุที่แท้จริง"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแบบฟอร์มลงคะแนน"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองใช้การค้นหาอื่นหรือเปลี่ยนตัวกรอง"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " หมวดหมู่"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " หมวดหมู่"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " หมวดหมู่"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ทั้งหมด"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " หมวดหมู่"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " แบบฟอร์มลงคะแนน"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " แบบฟอร์มลงคะแนน"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " แบบฟอร์มลงคะแนน"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนทั้งหมด"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " แบบฟอร์มลงคะแนน"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " สถานะ"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " สถานะ"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " สถานะ"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะทั้งหมด"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " สถานะ"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ประเภท"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ประเภท"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ประเภท"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภททั้งหมด"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ประเภท"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " พื้นที่ทํางาน"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " พื้นที่ทํางาน"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบพื้นที่ทํางาน"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " พื้นที่ทํางาน"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานทั้งหมด"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " พื้นที่ทํางาน"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["น้ำหนักของ ", _interpolate(_named("weight")), " มีส่วน ", _interpolate(_named("percentage")), "% ในคะแนนรวม"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบฟอร์มลงคะแนน"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบแบบฟอร์มลงคะแนนเป็นการดำเนินการแบบถาวร ซึ่งจะลบข้อมูลแบบฟอร์มลงคะแนนทั้งหมดออกจากการรายงาน"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการกู้คืนแบบฟอร์มลงคะแนนนี้ คุณจะต้องสร้างใหม่อีกครั้ง"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบฟอร์มลงคะแนน"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตล่าสุด"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช้งาน"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริง"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อัปเดตสเกลการให้คะแนนแล้ว ", _interpolate(_named("buttonStart")), "เริ่มการรีวิว", _interpolate(_named("buttonEnd"))])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk ตั้งค่าสเกลไบนารีตามค่าเริ่มต้น แต่คุณยังสามารถเลือกใช้สเกลที่ละเอียดกว่าได้:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากคุณต้องการดูตัวเลือกสเกลการให้คะแนนต่างๆ ที่กําหนดค่าตามหมวดหมู่ ให้ไปที่ ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นอกเห็นใจ/น้ำเสียง"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความรู้เกี่ยวกับผลิตภัณฑ์"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่ออัปเดตสเกลการให้คะแนน:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลการให้คะแนนก่อนหน้าของคุณและหมวดหมู่ที่เกี่ยวข้องจะถูกเก็บถาวร (ไม่ถูกลบ)"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลแดชบอร์ดบางอย่างจะได้รับผลกระทบเนื่องจากสเกลเก่าและใหม่ของคุณอาจไม่สามารถเปรียบเทียบกันได้"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลอกแบบการตั้งค่าหมวดหมู่การให้คะแนนที่มีอยู่ของฉันไปยังสเกลใหม่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่ว่าต้องการอัปเดตสเกลการให้คะแนน"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถเปลี่ยนรูปแบบการแสดงแบบฟอร์มลงคะแนน (ตัวเลขหรืออีโมจิ) และทําให้หมวดหมู่เป็นภาคบังคับหรือไม่บังคับก็ได้"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้อีโมจิแทนตัวเลข"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้ผู้ใช้ข้ามหมวดหมู่"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีแก้ปัญหา"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทสเกลการให้คะแนน"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["หมวดหมู่สำหรับพื้นที่ทำงาน 1 รายการถูกลอกแบบ"]), _normalize(["หมวดหมู่สำหรับพื้นที่ทำงาน ", _interpolate(_named("workspacesAmount")), " รายการถูกลอกแบบ"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าพื้นที่ทํางาน"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หาก ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงการโทรโดยอัตโนมัติโดยที่เจ้าหน้าที่ไม่ได้แจ้งให้ลูกค้าทราบว่าการสนทนาจะเงียบลง"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนามีช่วงเงียบยาวนานกว่าเกณฑ์ที่ตั้งไว้ที่"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดค่าเกณฑ์ช่วงเงียบสูงสุดในการโทร"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกที่ถูกลบจะไม่ปรากฏในการสนทนาและตัวกรองใหม่ใดๆ ข้อมูลในอดีตบนแดชบอร์ดและการสนทนายังคงไม่เปลี่ยนแปลง"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถลบข้อมูลเชิงลึกของสปอตไลท์"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อมูลเชิงลึกของสปอตไลท์แล้ว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อมูลเชิงลึกของสปอตไลท์"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูการสนทนาที่มีคำหรือวลีเฉพาะในทรานสคริปต์และข้อความ"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุการโทรที่ไม่มีคําชี้แจงการเปิดเผยข้อมูลที่จําเป็นโดยอัตโนมัติ เช่น \"การโทรนี้จะถูกบันทึก\" และที่คล้ายกัน"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีจุดมุ่งหมายเพื่อระบุคําชี้แจงการเปิดเผยข้อมูลการบันทึกในการโทรโดยใช้โมเดลภาษาขนาดใหญ่ (LLM) ที่เข้าใจบริบทแบบไดนามิก ขจัดความจำเป็นในการใช้วลีที่กำหนดไว้ล่วงหน้า"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเปิดเผยข้อมูลโดยการบันทึก"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงการโทรโดยอัตโนมัติโดยที่เจ้าหน้าที่ไม่ได้แจ้งให้ลูกค้าทราบว่าการสนทนาจะเงียบลง"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเงียบ"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุช่วงเงียบในการโทร"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ต้องการเปลี่ยนแปลงการสมัครใช้บริการของคุณหรือไม่ ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") และเรายินดีที่จะช่วยเหลือ"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนา 100% โดยอัตโนมัติระหว่างเจ้าหน้าที่, BPO, ช่องทาง และภาษา AI จะตรวจจับปัญหา ช่องว่างความรู้ และโอกาสในการฝึกสอนเพื่อปรับปรุงบริการ"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติที่ครอบคลุม 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คและการฝึกสอนเจ้าหน้าที่"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์ข้อมูลเชิงลึกของ AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรายงานขั้นสูงและแดชบอร์ด"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์และการกรองความคิดเห็น"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำแปลแบบออนดีมานด์"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบการมอบหมายและการตั้งเป้าหมาย"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การฝึกสอนและแบบทดสอบ"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การประกันคุณภาพ Zendesk (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกอย่างในแผน Professional รวมถึง"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (แบบเดิม)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook สําหรับการแจ้งเตือนทันทีเกี่ยวกับความล้มเหลวที่สําคัญ"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบคลังข้อมูล"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบ Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การพัฒนาการผสานระบบแบบกําหนดเอง"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้จัดการความสําเร็จของลูกค้าโดยเฉพาะ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าถึงผลิตภัณฑ์และทีมวิศวกรของเราโดยตรงผ่าน Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกอย่างในแผน Growth Suite รวมถึง"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติสําหรับความครอบคลุม 100%"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกการสนทนาที่ขับเคลื่อนด้วย AI เพื่อทําความเข้าใจข้อมูล"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเพิ่มข้อมูลและการกรองข้อมูลโปรแกรมฝ่ายสนับสนุนที่ปรับปรุงด้วย AI"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์: การตรวจจับอัตโนมัติสําหรับปฏิสัมพันธ์ที่ต้องรีวิว"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์และการกรองความคิดเห็น"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรายงานประสิทธิภาพและข้อมูลเชิงลึกที่ขับเคลื่อนด้วย AI"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแปลการสนทนาตามความต้องการ"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการติดตามเวลา"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์อัตราการผ่าน"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกอย่างในแผน AI Suite รวมถึง"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แพ็คเกจการใช้งานระดับองค์กรรวมถึงการผสานระบบ Salesforce และ Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การพัฒนาการผสานระบบแบบกําหนดเอง"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบคลังข้อมูล"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook สําหรับการแจ้งเตือนทันทีเกี่ยวกับความล้มเหลวที่สําคัญ"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้จัดการความสําเร็จของลูกค้าโดยเฉพาะ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าถึงทีมผลิตภัณฑ์และวิศวกรของเราได้โดยตรง"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเข้าถึงก่อนใครและอิทธิพลต่อแผนงานของเรา"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบการมอบหมายและการตั้งเป้าหมายโดยอัตโนมัติ"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การฝึกสอนและการวิเคราะห์ที่กำหนดเป้าหมาย"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบสําหรับการจัดการเรียนรู้"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาทของผู้ใช้และการจัดการสิทธิ์"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิดบังข้อมูลที่ละเอียดอ่อน"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกการกรองขั้นสูงและการค้นหาการสนทนา"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนที่ปรับแต่งได้พร้อมการให้คะแนนที่หลากหลาย น้ำหนักที่ปรับได้ และตัวเลือกความล้มเหลวอัตโนมัติ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนขยายเบราว์เซอร์เพื่อรีวิวกการสนทนาได้ทุกที่"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์สาเหตุที่แท้จริง"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรายงานประสิทธิภาพสําหรับการเปรียบเทียบเจ้าหน้าที่, ทีม, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจ CSAT และการวิเคราะห์ฟีดแบ็คของลูกค้า"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสมบัติ"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกอย่างในแผน Starter รวมถึง"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (แบบเดิม)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลการให้คะแนนหลายรายการต่อการสนทนา"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทำงานร่วมกันด้วย Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกของการสนทนาแบบโต้ตอบที่ขับเคลื่อนด้วย AI"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการมอบหมาย"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปักหมุดสําหรับการฝึกสอน"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่นการฝึกสอน"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์และการกรองความคิดเห็น"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การกรองอิงตามความซับซ้อนของการสนทนา"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเชิงลึกของการสนทนา"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนแบบมีเงื่อนไข"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์สาเหตุที่แท้จริง"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การวิเคราะห์ CSAT ขั้นสูงที่ขับเคลื่อนด้วย AI"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบการให้คะแนน"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนขยายเบราว์เซอร์เพื่อรีวิวการสนทนาได้ทุกที่"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์เพื่อค้นหาการสนทนาที่ต้องรีวิว"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแปลการสนทนาตามความต้องการ"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการติดตามเวลา"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนแบบกําหนดเอง"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (แบบเดิม)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวการสนทนาได้ไม่จํากัด"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การส่งออก CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวระหว่างเพื่อนร่วมงานและการรีวิวตนเอง"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนพร้อมน้ำหนักที่ปรับได้และตัวเลือกความล้มเหลวอัตโนมัติ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกการกรองที่มีประสิทธิภาพ"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายการรีวิวรายวัน รายสัปดาห์ หรือรายเดือน"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจ CSAT และการวิเคราะห์พื้นฐาน"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แดชบอร์ด"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานสําหรับการเปรียบเทียบเจ้าหน้าที่ ทีม และ BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการบทบาทและสิทธิ์ของผู้ใช้"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสมบัติ"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันต้องการเปลี่ยนแปลงการสมัครใช้บริการ QA ของ Zendesk ของฉัน\n\nไฮไลต์หรือระบุหนึ่งตัวเลือกและระบุจํานวนที่นั่งที่คุณต้องการเปลี่ยนแปลง:\nฉันต้องการ [เพิ่ม X ที่นั่ง] [ลบ X ที่นั่ง] [อัปเกรดเป็นแผน QA ของ Zendesk]\n\nโปรดแบ่งปันชื่อบริษัทของคุณและชื่อที่เชื่อมโยงกับบัญชี QA ของ Zendesk ของคุณ รวมถึงรายละเอียดอื่นๆ ที่เราควรทราบเพื่อดําเนินการเปลี่ยนแปลงนี้"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงการสมัครใช้บริการ QA ของ Zendesk"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันต้องการซื้อการสมัครใช้บริการ QA ของ Zendesk\n\nโปรดแบ่งปันชื่อบริษัทของคุณและชื่อที่เชื่อมโยงกับบัญชี QA ของ Zendesk ของคุณ รวมถึงรายละเอียดอื่นๆ ที่เราควรทราบเพื่อดําเนินการตามคําร้องขอนี้:\n\nโปรดแบ่งปันคําถามเฉพาะที่คุณต้องการให้เราจัดการก่อนการซื้อ:\n\nขอบคุณ"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซื้อการสมัครใช้บริการ QA ของ Zendesk"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสมัครใช้บริการของคุณจะสิ้นสุดในวันที่ ", _interpolate(_named("date")), " (เหลือ ", _interpolate(_named("days")), " วัน)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งานของคุณจะสิ้นสุดในวันที่ ", _interpolate(_named("date")), " (เหลือ ", _interpolate(_named("days")), " วัน)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 วัน"]), _normalize([_interpolate(_named("n")), " วัน"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ในวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ทุกวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ทุกวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ทุกวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ทุกวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ทุกวันที่ ", _interpolate(_named("dayOfMonth")), " ของทุกเดือน"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เรียกเก็บเงินไปที่ ", _interpolate(_named("card")), " (หมดอายุ ", _interpolate(_named("expires")), ") ในวันที่ ", _interpolate(_named("billingDate"))])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงท้ายด้วย"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["หมดอายุแล้ว"]), _normalize(["หมดอายุใน 1 วัน"]), _normalize(["หมดอายุใน ", _interpolate(_named("expiresIn")), " วัน"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ราคาเริ่มต้นรวม ", _interpolate(_named("n")), " สิทธิ์การใช้งานของผู้ใช้"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เริ่มต้นที่ ", _interpolate(_named("sum")), "/เดือน"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่อผู้ใช้ / เดือน"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่อปี"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่อเดือน"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไตรมาส"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการยกเลิกตามกําหนดเวลา"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากคุณต้องการใช้ QA ของ Zendesk ต่อไป ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสมัครใช้บริการของคุณถูกยกเลิกเมื่อ ", _interpolate(_named("date"))])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสมัครใช้บริการของคุณมีกําหนดจะหมดอายุในวันที่ ", _interpolate(_named("date"))])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การทดลองใช้งาน ", _interpolate(_named("days")), " วันโดยไม่มีค่าใช้จ่ายเพิ่มเติม!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้แล้ว"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณมีผู้ใช้ ", _interpolate(_named("n")), " ราย จํานวนที่นั่งต้องไม่ต่ำกว่านั้น"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การสมัครใช้บริการจะกลับมาทํางานต่อใน ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แผน QA ของ Zendesk ปัจจุบันของคุณ (", _interpolate(_named("plan")), ") ไม่รวมคุณสมบัติที่ขับเคลื่อนด้วย AI เพื่อปลดปล่อยพลังเต็มรูปแบบของคุณสมบัติระบบเสียงอัจฉริยะ ", _interpolate(_named("link"))])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการยกเลิกการสมัครสมาชิก โปรดติดต่อเรา ", _interpolate(_named("link")), " หรือแชทกับเรา"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนการจัดการคุณภาพ"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการคุณภาพ"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการสมัครใช้บริการแล้ว"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มบัตรเครดิต"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนทั้งหมด"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเรียกเก็บเงิน"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูข้อมูลการเรียกเก็บเงิน"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอบเวลาการเรียกเก็บเงิน"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการสมัครใช้บริการ"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครใช้บริการของคุณจะถูกยกเลิกเมื่อสิ้นสุดรอบเวลาการเรียกเก็บเงิน"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อเรา"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อเราเพื่อแก้ไข"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนปัจจุบัน"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ราคาแบบกำหนดเอง"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดาวน์เกรด"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายปี (ประหยัด 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายปี"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชําระเป็นรายปี"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการสมัครใช้บริการ"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สํารวจแผน"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทดลองใช้งานฟรี"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกใบแจ้งหนี้ด้วยตนเองแล้ว"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถตรวจสอบและยืนยันสรุปคําสั่งซื้อขั้นสุดท้ายได้ในขั้นตอนถัดไป"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดนิยม"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่มีวิธีการชําระเงินที่ใช้งานได้"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในการทดลองใช้งาน"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานการสมัครใช้บริการปัจจุบันของคุณต่อเพื่อเปลี่ยนแผน"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดการสมัครใช้บริการชั่วคราว"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายไตรมาส"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายปี"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ราคา"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งานการสมัครใช้บริการอีกครั้ง"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้การสมัครใช้บริการต่อ"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นั่ง"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มทดลองใช้งานตอนนี้"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกแล้ว"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต่ออายุ"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดชั่วคราวแล้ว"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการสมัครใช้บริการ"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทดลองใช้งาน"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรด"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดการสมัครใช้บริการ"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดเอง"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดสละเวลาหนึ่งนาทีเพื่อให้ฟีดแบ็คเกี่ยวกับประสบการณ์การสนับสนุนลูกค้าของคุณ ขอบคุณล่วงหน้า — ", "{", "{", "agentName", "}", "}", " ให้ความสําคัญกับฟีดแบ็คของคุณ!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวัสดี ", "{", "{", "customerName", "}", "}", " 👋 !"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แบบสำรวจ CSAT ของ ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณจะให้คะแนนการสนับสนุนที่คุณได้รับจาก ", "{", "{", "agentName", "}", "}", " อย่างไร"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เราขอชื่นชมอย่างยิ่ง เราจะใช้ฟีดแบ็คของคุณเพื่อทําให้ ", _interpolate(_list(0)), " ดีขึ้นสําหรับทุกคน"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับแบบสํารวจ"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกประเภทแบบสํารวจ"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซึ่งสามารถเปลี่ยนแปลงได้ในภายหลัง"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัดผลและปรับปรุงความพึงพอใจของลูกค้าด้วยแบบสํารวจ CSAT อัตโนมัติในหลายภาษา"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสร้างแบบสํารวจ"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแบบสํารวจ"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบบสํารวจ"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแบบสํารวจแล้ว"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลองเปลี่ยนคีย์เวิร์ดหรือ ", _interpolate(_named("contact_us")), " หากคุณต้องการดูคีย์เวิร์ดที่แสดงไว้ที่นี่"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งให้เราทราบ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบภาษา"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เลือกอย่างน้อยหนึ่งภาษาจากรายการด้านล่าง ", _interpolate(_named("contact_us")), " หากคุณไม่เห็นภาษาที่คุณต้องการใช้แสดงอยู่ที่นี่"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มภาษา"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งให้เราทราบ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มภาษา"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เผยแพร่ครั้งล่าสุด ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["บันทึกล่าสุด ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำถาม ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามเบื้องต้น"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายกำกับสเกลด้านซ้าย"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้ออีเมล"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายกำกับสเกลด้านขวา"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณ"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากแบบสํารวจมีคําแปลที่ขาดหายไป ระบบจะแสดงข้อความของภาษาพื้นฐานแทน"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้คําแปล"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำแปลที่ขาดหายไป"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["อย่าลืมตรวจสอบคําแปลทั้งหมดของคุณก่อนที่จะนําไปใช้ ทําการเปลี่ยนแปลงในไฟล์ที่ดาวน์โหลด บันทึก และอัปโหลดใหม่หากจําเป็น หากโทเค็น ", _interpolate(_named("token_example")), " ไม่ปรากฏเป็นแท็ก แสดงว่าอยู่ในรูปแบบที่ไม่ถูกต้องหรืออยู่ในฟิลด์ที่ไม่รองรับโทเค็น"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบคําแปล"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉบับแปล"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สรุปการสนทนาและแสดงคําแนะนําสําหรับข้อคิดเห็น"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ช่วย AI ของ QA ของ Zendesk (ในภาษาอังกฤษ)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถแก้ไขประเภทได้เมื่อสร้างแล้ว"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทนี้มีอยู่แล้ว เลือกชื่ออื่น"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกประเภทที่กําหนดเองเป็นตัวเลือกที่เลือกได้ในดรอปดาวน์ประเภทแบบสํารวจ และจัดกลุ่มแบบสํารวจที่สร้างขึ้นทั้งหมดบนแดชบอร์ด"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์คำที่ต้องการแล้วกด Enter เพื่อบันทึก"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขประเภทที่กําหนดเอง"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อประเภทต้องไม่ว่างเปล่า"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดประเภท"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ได้บันทึกประเภทที่กําหนดเอง กด Enter เพื่อบันทึก"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลของผู้ส่ง"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อของผู้ส่ง"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณเห็นด้วยกับข้อความที่ว่า \"", _interpolate(_named("agentName")), " ทําให้ง่ายต่อการจัดการปัญหาของฉัน\" มากน้อยเพียงใด"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การขอความช่วยเหลือจาก ", _interpolate(_named("brandName")), " จนถึงตอนนี้ง่ายมากน้อยเพียงใด"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดยรวมแล้วการแก้ปัญหาของคุณวันนี้ง่ายมากน้อยเพียงใด"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้ตอบกับทีมของเราง่ายมากน้อยเพียงใด"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL นโยบายความเป็นส่วนตัว (ไม่บังคับ)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ข้อกำหนดการใช้บริการ (ไม่บังคับ)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณรู้สึกอย่างไรกับการสนับสนุนที่คุณได้รับจาก ", _interpolate(_named("agentName"))])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริการของเราสําหรับคําขอนี้เป็นอย่างไร"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณพอใจกับตัวแทนฝ่ายสนับสนุนมากน้อยเพียงใด"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณพอใจกับบริการที่คุณได้รับจาก ", _interpolate(_named("agentName")), " ในวันนี้มากน้อยเพียงใด"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามที่กําหนดเอง"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กําหนดคําถาม ", _interpolate(_named("type")), "ที่คุณกําหนดเอง..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์คําถามที่กําหนดเอง"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับแต่ง URL"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าข้อความอีเมลของคุณ"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแก้ไขชื่อของประเภทคําถามที่กําหนดเองจะอัปเดตในแบบสํารวจทั้งหมดที่ใช้โดยไม่มีผลกระทบต่อข้อมูลที่รวบรวม"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขประเภทที่กําหนดเอง"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้ออีเมล"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามเบื้องต้น"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คำถาม ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างประเภทที่กําหนดเองใหม่"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหา"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["โทเค็นข้อมูลส่วนบุคคล"]), _normalize(["โทเค็นข้อมูลส่วนบุคคล"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลากและวางโทเค็นข้อมูลส่วนบุคคลลงในฟิลด์ด้านล่าง"]), _normalize(["ลากและวางโทเค็นข้อมูลส่วนบุคคลลงในฟิลด์ด้านล่าง"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การสนทนา ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขณะนี้คุณสามารถส่งแบบสํารวจ CSAT ทางอีเมลเท่านั้น เรากําลังดําเนินการเพื่อให้คุณมีโอกาสมากขึ้น เช่น แบบสํารวจ CSAT ของโปรแกรมฝ่ายสนับสนุน แบบสำรวจทาง SMS และอื่นๆ"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ซิงค์หรือต่ออายุการเชื่อมต่อ"]), _normalize(["ซิงค์หรือต่ออายุการเชื่อมต่อ"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซิงค์หรือต่ออายุการเชื่อมต่อ ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["การเชื่อมต่อไม่ได้ซิงค์อย่างถูกต้อง"]), _normalize(["การเชื่อมต่อไม่ได้ซิงค์อย่างถูกต้อง"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลิกทำเครื่องหมายช่อง ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรองความถูกต้องตอนนี้ ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกฎการส่งแบบสํารวจอย่างน้อยหนึ่งกฎเพื่อเปิดใช้งานการเชื่อมต่อ"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การเชื่อมต่อนี้ถูกปิดใช้งานในขณะนี้ ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การเชื่อมต่อนี้ปิดบังข้อมูลลูกค้าที่ละเอียดอ่อน หากต้องการเปิดใช้งานการส่งแบบสํารวจ\n", _interpolate(_named("link")), " ในการตั้งค่า"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การเชื่อมต่อนี้ต้องการสิทธิ์เพิ่มเติมเพื่อส่งแบบสํารวจ\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรองความถูกต้องตอนนี้"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk ต้องการสิทธิ์เพิ่มเติมสําหรับ Intercom"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " และ ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ชั่วโมง"]), _normalize([_interpolate(_named("n")), " ชั่วโมง"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " นาที"]), _normalize(["1 นาที"]), _normalize([_interpolate(_named("n")), " นาที"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ชั่วโมง"]), _normalize(["ชั่วโมง"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " เพื่อกําหนดค่ากฎการส่งแบบสํารวจ"])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" วัน"]), _normalize(["วัน"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์แบบสํารวจหมดอายุ"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์หมดอายุใน"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้องกันไม่ให้ตอบแบบสํารวจหากเวลาผ่านไปนานเกินไป"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["และ"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แบบสำรวจ ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกแท็กด้วยเครื่องหมายจุลภาคหรือกด Enter หลังจากเขียน ตรวจสอบให้แน่ใจว่าแต่ละรายการอยู่ในรายการอย่างแม่นยําเช่นเดียวกับใน Intercom"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดเงื่อนไขตามการส่งแบบสํารวจ"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎการส่งแบบสํารวจ"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎการส่งติดตามผลการสำรวจ"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["มีแบบสํารวจ ", _interpolate(_named("name")), _interpolate(_named("rules")), " อยู่แล้ว\nแก้ไขข้อขัดแย้งโดยการแก้ไขกฎที่นี่หรือใน ", _interpolate(_named("link"))])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คุณได้ตั้งค่าการเชื่อมต่อกับกฎการจับคู่สําหรับแบบสํารวจ ", _interpolate(_named("name")), " แล้ว แก้ไขข้อขัดแย้งโดยการแก้ไขกฎที่นี่หรือใน ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกฎอื่น"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มแบรนด์"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเงื่อนไข"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาค่า"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มแท็ก"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จับคู่กฎเดียวกัน"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกค่า"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลที่ส่งออกใน"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การติดตามอีเมลที่ส่งออกใน"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเล็กน้อยแบบฝังและการติดตามอีเมล"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกรหัส"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนบแบบสํารวจ QA ของ Zendesk กับอีเมลของคุณโดยการเพิ่มโค้ดข้อมูลเล็กน้อยลงในการตั้งค่า Zendesk ของคุณ"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คู่มือการติดตั้ง"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวอย่าง"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างข้อมูลเล็กน้อย"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โค้ดข้อมูลเล็กน้อยถูกคัดลอกไปยังคลิปบอร์ด"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การคัดลอกข้อมูลเล็กน้อยล้มเหลว"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเล็กน้อยแบบฝัง"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจนี้มีการเปลี่ยนแปลงที่ไม่ได้บันทึก บันทึกอีกครั้งเพื่อให้แน่ใจว่าข้อมูลเล็กน้อยแสดงการอัปเดตล่าสุด"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อ"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการเพิ่มการเชื่อมต่อ"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดึงอีเมล แชทสด โทรศัพท์ และอื่นๆ เข้าสู่ QA ของ Zendesk ด้วยการเชื่อมต่อของคุณ"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการส่งแบบสํารวจ"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎการส่งแบบสํารวจ"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎการส่งติดตามผลการสำรวจ"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกฎของแบรนด์"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกฎ"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรองความถูกต้อง"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การติดตามผล Messenger + อีเมล"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อบัญชี Intercom ของคุณกับแบบสํารวจ CSAT ของ QA ของ Zendesk"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งมอบผ่าน"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใครได้รับแบบสํารวจและเมื่อใด"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขกฎ"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งานแล้ว"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจในแอปช่วยให้ลูกค้าของคุณทําแบบสํารวจได้โดยไม่ต้องออกจากแชท"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎการจัดส่งต่อการเชื่อมต่อ"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["มีแบบสํารวจ ", _interpolate(_named("name")), _interpolate(_named("rules")), " อยู่แล้ว\nแก้ไขข้อขัดแย้งโดยการปิดใช้งานการเชื่อมต่อหรือแก้ไขกฎที่นี่หรือใน ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดส่ง"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดเงื่อนไขอิงตามแบบสำรวจที่ได้ส่ง"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างโดย ", _interpolate(_named("name")), " ในวันที่ ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบ ", _interpolate(_named("languageName")), " ออก"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบภาษานี้หมายความว่าาจะไม่ปรากฏแก่ผู้ตอบแบบสำรวจอีกต่อไป และข้อความที่แปลแล้วทั้งหมดจะถูกลบออก"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบภาษา"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการนี้เลิกทำไม่ได้"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือคุณสามารถปิดใช้งานภาษาได้โดยการซ่อนจากแบบสํารวจ วิธีนี้จะช่วยให้คุณเก็บคําแปลทั้งหมดของคุณไว้โดยไม่แสดงให้ผู้ตอบแบบสำรวจเห็น"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แก้ไขล่าสุดโดย ", _interpolate(_named("name")), " เมื่อ ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับภาษาแบบสํารวจ"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ก่อนที่จะเพิ่มภาษาอื่นๆ ตรวจสอบให้แน่ใจว่าคุณได้ทําการเปลี่ยนแปลงในขั้นตอนการจัดรูปแบบและเนื้อหาแล้ว ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มภาษา"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์ที่แปล"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ตอบแบบสำรวจมองเห็นได้"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าดีฟอลต์"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลดไฟล์คําแปล"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบภาษา"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ดาวน์โหลดไฟล์คําแปล"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบคําแปล"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การแปลเสร็จสมบูรณ์ ", _interpolate(_named("review_link")), " หรือ ", _interpolate(_named("download_link")), " เพื่อทําการเปลี่ยนแปลงใด ๆ"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ดาวน์โหลดไฟล์คําแปล"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบสิ่งที่ขาดหายไป"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เนื้อหาบางส่วนยังไม่ได้แปลและจะถูกแทนที่ด้วยภาษาอังกฤษ ", _interpolate(_named("review_link")), " หรือ ", _interpolate(_named("download_link")), " เพื่อเพิ่มคําแปลที่ขาดหายไป"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกแบบสํารวจ"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["แบบสํารวจมีการเปลี่ยนแปลงที่ไม่ได้บันทึก ", _interpolate(_named("save")), " เพื่อดาวน์โหลดไฟล์การแปลที่มีการเปลี่ยนแปลงล่าสุด"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลดไฟล์คําแปล"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกสเกลการให้คะแนนที่ต้องการ"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้ดรอปดาวน์เพื่อเลือกเหตุผลในการให้คะแนนแก่ลูกค้า"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีโมจิ"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความสเกลด้านซ้าย (ไม่บังคับ)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลข"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเหตุผล"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลไม่สามารถว่างเปล่า"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลนี้มีอยู่แล้ว"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงดรอปดาวน์ของเหตุผลสําหรับคะแนนของคุณ"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบเหตุผลและเพิ่มอีกครั้งด้วยชื่อเดียวกันหรือการเปลี่ยนชื่อเหตุผลจะสร้างรายการใหม่เสมอ ด้วยเหตุนี้ จึงส่งผลกระทบต่อข้อมูลที่แสดงบนแดชบอร์ดของคุณ"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเหตุผล"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลที่ถูกลบยังคงอยู่ในการรายงานแดชบอร์ด แต่จะไม่แสดงให้กับผู้ตอบแบบสํารวจใหม่ นอกจากนี้ยังใช้กับการแปลที่เกี่ยวข้องด้วย"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบเหตุผล"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ลบเหตุผล \"", _interpolate(_list(0)), "\""])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความสเกลด้านขวา (ไม่บังคับ)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในสเกล 5 คะแนน ค่า 1 และ 2 ถือเป็นคะแนนติดลบ 3 เป็นกลาง 4-5 เป็นบวก"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อความสเกล"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สเกล ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลการให้คะแนน"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA ของ Zendesk กําลังนําเข้าการสนทนาของคุณ กระบวนการนี้อาจใช้เวลาสูงสุดถึง 24 ชั่วโมง"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ่งนี้จะใช้เป็นโทเค็นข้อมูลส่วนบุคคลตลอดแบบสํารวจ"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบรนด์"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปุ่มและลิงก์"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับแต่งรูปลักษณ์ของแบบสํารวจของคุณ"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นหลังโลโก้"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รองรับรูปแบบ JPG, PNG และ GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โลโก้"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สไตล์"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปโหลดโลโก้"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงนามอย่างมีสไตล์"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนข้อความที่ผู้คนจะเห็นหลังจากส่งฟีดแบ็ค"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความขอบคุณ"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณ"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสสีฐานสิบหกไม่ถูกต้อง"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่อีเมลไม่ถูกต้อง"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์นี้ไม่อาจเว้นว่างได้"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ไม่ถูกต้อง"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มองไม่เห็น:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มองเห็นได้"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจที่ส่งแล้ว"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจที่ส่งในช่วง 30 วันที่ผ่านมา"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจถูกลบไปแล้ว"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจถูกทําซ้ํา"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งอีเมลการแสดงตัวอย่างแล้ว"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจถูกเผยแพร่แล้ว"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจได้รับการอัปเดตแล้ว"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจถูกหยุดชั่วคราว"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดชั่วคราว"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดแบบสํารวจชั่วคราว"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวอย่าง"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนอีเมลเพื่อส่งการแสดงตัวอย่างแบบสํารวจ"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "email.com ของคุณ"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกภาษาสําหรับการแสดงตัวอย่าง"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งอีเมลการแสดงตัวอย่าง"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งการแสดงตัวอย่างแบบสํารวจไปยังอีเมลของคุณ การให้คะแนนจากการแสดงตัวอย่างแบบสํารวจจะไม่ถูกจัดเก็บ"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ดูตัวอย่างแบบสํารวจ ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวอย่างแบบสํารวจทางอีเมลในเว็บ"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อ"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเป็นแบบร่าง"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบรนด์"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถเพิ่มชื่อเฉพาะแบรนด์ได้ในขั้นตอนการจัดแต่งสไตล์"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID การสนับสนุน"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวแทนฝ่ายสนับสนุน"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแล้ว"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดชั่วคราวแล้ว"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แบบสำรวจ CSAT ของ ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่ไม่ได้บันทึก"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตและดําเนินการแบบสํารวจต่อ"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากคุณเห็นอีเมลนี้ไม่ถูกต้อง ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฉันไม่พอใจกับการสนับสนุนที่ได้รับ เพราะ..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขับเคลื่อนโดย QA ของ Zendesk"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเหตุผล"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สรุปการสนทนา"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่าง: ลูกค้ามีปัญหาในการอัปเดตวิธีการชําระเงิน เจ้าหน้าที่ได้แชร์บทความที่สามารถช่วยได้ ซึ่งได้ผล"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขับเคลื่อนโดย AI ของ QA ของ Zendesk"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดการใช้บริการ"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอขอบคุณสำหรับฟีดแบ็ค!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็ค"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อะไรคือเหตุผลหลักสําหรับคะแนนของคุณ"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอกเราว่าทําไมคุณถึงให้คะแนนนี้..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความพยายามสูง"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความพยายามต่ำ"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกที่นี่เพื่อเปิดแบบสํารวจ"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเป็นส่วนตัว"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามที่กําหนดเองของคุณจะแสดงที่นี่"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["คุณให้คะแนนประสบการณ์ของคุณเป็น ", _interpolate(_list(0))])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งฟีดแบ็ก"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณ"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการสมัคร"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พอใจมาก"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พอใจมาก"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดทราบว่าสถานะแบบสํารวจปัจจุบันและกฎการจัดส่งจะไม่รวมอยู่ในแบบสํารวจที่ซ้ำกัน"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แบบสํารวจที่ซ้ำกัน \"", _interpolate(_list(0)), "\""])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากคุณใช้ข้อมูลเล็กน้อยของอีเมลแบบฝัง อย่าลืมลบออกจาก Zendesk ก่อนที่จะหยุดแบบสํารวจชั่วคราว"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จะไม่มีการรวบรวมคําตอบเมื่อแบบสํารวจถูกหยุดชั่วคราว"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หยุดแบบสํารวจชั่วคราว"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณลบข้อมูลเล็กน้อยออกจาก Zendesk ก่อนที่จะหยุดแบบสํารวจชั่วคราว"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบสํารวจ"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากคุณใช้ข้อมูลเล็กน้อยของอีเมลแบบฝัง อย่าลืมลบออกจาก Zendesk ก่อนที่จะลบแบบสํารวจ"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบแบบสํารวจอย่างถาวร ผู้ใช้ที่ได้รับแบบสํารวจยังคงสามารถกรอกแบบสํารวจได้ และข้อมูลทั้งหมดที่เกี่ยวข้องกับแบบสํารวจจะยังคงอยู่ใน QA ของ Zendesk"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบแบบสํารวจ ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณลบข้อมูลเล็กน้อยออกจาก Zendesk ก่อนที่จะลบแบบสํารวจ"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่นำไปใช้ของคุณจะมองเห็นโดยผู้ตอบแบบสำรวจรายใหม่"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่แบบสํารวจ"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณคัดลอกข้อมูลเล็กน้อยที่ฝังเวอร์ชันล่าสุดหลังจากเผยแพร่แบบสํารวจ"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากคุณใช้ข้อมูลเล็กน้อยของอีเมลแบบฝัง อย่าลืมคัดลอกไปยัง Zendesk หลังจากดำเนินการแบบสํารวจต่อ"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งแบบสํารวจและรวบรวมคําตอบต่อไป"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตและดําเนินการแบบสํารวจต่อ"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณเพิ่มข้อมูลเล็กน้อยที่ฝังลงใน Zendesk หลังจากดำเนินการแบบสํารวจต่อ"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตแบบสำรวจ"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณคัดลอกข้อมูลเล็กน้อยที่ฝังเวอร์ชันล่าสุดหลังจากอัปเดตแบบสํารวจ"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่ว่าต้องการลบแบบสํารวจนี้ การดำเนินการนี้เลิกทำไม่ได้"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบสํารวจหรือไม่"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทดลองใช้งานของคุณหมดอายุแล้ว แต่คุณสามารถเพลิดเพลินกับ QA ของ Zendesk ต่อไปได้โดยการอัปเกรดเป็นบัญชีแบบเต็ม"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทดลองใช้งานของคุณหมดอายุแล้ว แต่คุณสามารถเพลิดเพลินกับ QA ของ Zendesk ต่อไปได้โดยการอัปเกรดเป็นบัญชีแบบเต็ม พูดคุยกับผู้ดูแลระบบท้องถิ่นของคุณเพื่อตั้งค่าสิ่งนี้"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทดลองใช้งานของคุณได้สิ้นสุดลงแล้ว"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเกรดตอนนี้"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทดลองใช้งานฟรีของคุณสิ้นสุดแล้ว"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการใช้ QA ของ Zendesk เวอร์ชันเต็มต่อไป ให้ป้อนรายละเอียดการชําระเงินของคุณ"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการใช้ QA ของ Zendesk เวอร์ชันเต็มต่อไป ผู้ดูแลระบบบัญชีของคุณจําเป็นต้องป้อนรายละเอียดการชําระเงิน"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการการชําระเงิน"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สร้างเมื่อ ", _interpolate(_named("date")), " โดย ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การมอบหมาย ", _interpolate(_list(0))])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าคุณยังคงทํางานเกี่ยวกับเรื่องนี้ คุณจะสูญเสียข้อมูลทั้งหมดที่คุณเพิ่มไว้จนถึงตอนนี้หากคุณออกไปโดยไม่บันทึกการเปลี่ยนแปลง"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การมอบหมายนี้จะทําซ้ำทุกๆ ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การมอบหมายนี้จะทําซ้ำทุกๆ ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผู้รับการรีวิวทั้งหมดที่ครอบคลุมในวงจรเดียว ผู้รับการรีวิวทั้งหมดครอบคลุมใน ", _interpolate(_named("nr")), " วงจร"])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " การสนทนา"]), _normalize([_interpolate(_named("n")), " การสนทนา"]), _normalize([_interpolate(_named("n")), " การสนทนา"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["จากเจ้าหน้าที่ ", _interpolate(_named("nr")), " คน"]), _normalize(["จากเจ้าหน้าที่ ", _interpolate(_named("nr")), " คน"]), _normalize(["จากเจ้าหน้าที่ ", _interpolate(_named("nr")), " คน"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้เข้าร่วม"]), _normalize(["ผู้เข้าร่วม"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " การสนทนาต่อวงจร ", _interpolate(_named("n")), " การสนทนาต่อวงจร ", _interpolate(_named("n")), " การสนทนาต่อวงจร"])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " ต่อผู้รับการรีวิวติดต่อกัน"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้รับการรีวิว"]), _normalize(["ผู้รับการรีวิว"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้รีวิว"]), _normalize(["ผู้รีวิว"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางานทั้งหมด"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางานทั้งหมดยกเว้นเจ้าหน้าที่"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["วงจร ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการกิดซ้ำ"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การมอบหมาย ", _interpolate(_list(0))])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังรีวิว"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการมอบหมาย"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการมอบหมาย"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบการมอบหมายอย่างถาวรและลบข้อมูลออกจากแดชบอร์ด รีวิวทั้งหมดที่เกี่ยวข้องจะยังคงอยู่ใน QA ของ Zendesk"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบการมอบหมาย ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการมอบหมายแล้ว"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายถูกทำซ้ำ"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการเพิ่มการมอบหมาย"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการมอบหมายไม่สําเร็จ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อการมอบหมาย"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วงจรจะถูกรีเฟรชและสร้างรายการงานใหม่ รีวิวที่ได้ให้แล้วจะยังคงนับรวมในเป้าหมาย"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทันที"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เมื่อเริ่มวงจรถัดไปในวันที่ ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จะเผยแพร่การเปลี่ยนแปลงเมื่อไหร่"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่ทํากับการมอบหมายนี้จะถูกเผยแพร่พร้อมกับการมอบหมายนี้"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าการมอบหมายเป็นใช้งานอยู่ใช่หรือไม่"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดซ้ำในวันเริ่มต้นการมอบหมายงาน"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันศุกร์แรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันศุกร์ที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันศุกร์ที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันศุกร์ที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันศุกร์สุดท้ายของทุกเดือน"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันจันทร์แรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันจันทร์ที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันจันทร์ที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันจันทร์ที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันจันทร์สุดท้ายของทุกเดือน"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเสาร์แรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเสาร์ที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเสาร์ที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเสาร์ที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเสาร์สุดท้ายของทุกเดือน"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอาทิตย์แรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอาทิตย์ที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอาทิตย์ที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอาทิตย์ที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอาทิตย์สุดท้ายของทุกเดือน"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพฤหัสบดีแรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพฤหัสบดีที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพฤหัสบดีที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพฤหัสบดีที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพฤหัสบดีสุดท้ายของทุกเดือน"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอังคารแรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอังคารที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอังคารที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอังคารที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันอังคารสุดท้ายของทุกเดือน"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพุธแรกของทุกเดือน"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพุธที่สองของทุกเดือน"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพุธที่สามของทุกเดือน"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพุธที่สี่ของทุกเดือน"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันพุธสุดท้ายของทุกเดือน"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเกิดซ้ำที่กําหนดเอง"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันแรกของทุกเดือน"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แรกสุด"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่สอง"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่สาม"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่สี่"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สุดท้าย"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันทำงาน"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ้นสุด"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เริ่มต้น"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผลลัพธ์"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เขตเวลา"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าวงจร"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อการมอบหมาย"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลพื้นฐาน"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อเริ่มต้นแต่ละวงจรการมอบหมาย รายการการสนทนาที่กําหนดไว้ล่วงหน้าจะถูกสร้างขึ้นเพื่อการประเมินผล"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั่วไป"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าขั้นสูง"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดึงการสนทนาใหม่โดยอัตโนมัติเมื่อไม่บรรลุเป้าหมายการรีวิว"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดึงข้อมูลอัตโนมัติไม่สามารถทำได้หากเป้าหมายการรีวิวเป็นเปอร์เซ็นต์ (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องเป็นจํานวนเต็ม ไม่ใช่ทศนิยม"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้แทนที่การสนทนา (บทบาทผู้รีวิวและสูงกว่า)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแทนที่การสนทนา"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้ผู้รีวิวได้รับมอบหมายการสนทนาของตนเองเพื่อการรีวิวได้"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวตนเอง"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนรีวิวขั้นต่ำต้องมีอย่างน้อย 1 รายการ"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุจำนวนรีวิวขั้นต่ำสําหรับผู้รับการรีวิวแต่ละคน"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปอร์เซ็นต์ (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวรวมทั้งหมด"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวแต่ละคนต้องทําการรีวิวตามจำนวนที่กำหนดไว้"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวต้องร่วมกันรีวิวการสนทนาตามจํานวนที่กําหนดไว้"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนรีวิวขั้นต่ำต้องมีอย่างน้อย 1 รายการ"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายการรีวิวต้องอยู่ระหว่าง 1 – 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายรีวิว"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวแต่ละคนต้องมีจํานวนการสนทนาขั้นต่ำที่ได้รับการรีวิว"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวสามารถเลือกการสนทนาจากพูลที่แชร์ได้"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวได้รับมอบหมายการสนทนาจากพูลที่แชร์"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการมอบหมายงาน"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอททั้งหมด"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วมในพื้นที่ทํางานทั้งหมด"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางานทั้งหมด"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้รับการรีวิว"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใครจะได้รับการรีวิวบ้าง"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้รับการรีวิว"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกบุคคลที่จะรีวิว"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเว้นจะได้รับอนุญาตเฉพาะเมื่อเลือกผู้ใช้พื้นที่ทํางานทั้งหมดหรือกลุ่มเท่านั้น"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวและผู้รับการรีวิว"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ปัจจุบัน: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเริ่มต้นของพื้นที่ทํางาน"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ควรเลือกผู้ใช้ประเภทใดเป็นผู้รับการรีวิว"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ที่ใช้งานมากที่สุด"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเว้นผู้รับการรีวิว (ไม่บังคับ)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้ที่จะยกเว้น"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเว้นผู้รีวิว (ไม่บังคับ)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้ที่จะยกเว้น"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางานทั้งหมดยกเว้นเจ้าหน้าที่"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้รีวิว"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใครจะต้องทํารีวิวบ้าง"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้รีวิว"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่อนุญาตให้รีวิวตนเองในพื้นที่ทํางานนี้"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้รีวิวตนเองง"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่อนุญาตให้มีการรีวิวตนเองในพื้นที่ทํางานนี้"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["การสนทนาที่จับคู่ในวันนี้"]), _normalize(["การสนทนาที่จับคู่ในวันนี้"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงราวกับว่าการมอบหมายเริ่มขึ้นในวันนี้"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลขในอนาคตอาจแตกต่างกันไป"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผู้รับการรีวิวถูกเลือก"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผู้รีวิวถูกเลือก"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้รับการรีวิว"]), _normalize(["ผู้รับการรีวิว"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้รีวิว"]), _normalize(["ผู้รีวิว"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวอย่าง"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดึงข้อมูลอัตโนมัติ: อนุญาตหากมีจำนวนการสนทนาไม่เพียงพอ"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันศุกร์ตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันจันทร์ตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันเสาร์ตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันอาทิตย์ตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันพฤหัสบดีตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันอังคารตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " ในวันพุธตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ทุกวันตั้งแต่ ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เริ่มต้น ", _interpolate(_named("startDateTime")), " และสิ้นสุด ", _interpolate(_named("endDateTime"))])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เงื่อนไขวันที่ ", _interpolate(_named("count"))])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " เงื่อนไขการสนทนาและโปรแกรมฝ่ายสนับสนุน"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่มีเงื่อนไขการสนทนาและมี ", _interpolate(_named("count")), " โปรแกรมฝ่ายสนับสนุน ไม่มีเงื่อนไขการสนทนาและมี ", _interpolate(_named("count")), " โปรแกรมฝ่ายสนับสนุน"])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " การสนทนาและไม่มีเงื่อนไขของโปรแกรมฝ่ายสนับสนุน"]), _normalize([_interpolate(_named("count")), " การสนทนาและไม่มีเงื่อนไขของโปรแกรมฝ่ายสนับสนุน"])])},
            "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการ: ผู้รีวิวเลือกการสนทนา วิธีการ: ผู้รีวิวจะได้รับการมอบหมายการสนทนา"])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขข้อผิดพลาดที่เน้นในข้อมูลสรุปเพื่อดําเนินการต่อ"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย: ผู้รีวิวต้องรีวิวการสนทนาทั้งหมด"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เป้าหมาย: ผู้รีวิวต้องรีวิวการสนทนา 1 รายการ"]), _normalize(["เป้าหมาย: ผู้รีวิวต้องรีวิวการสนทนา ", _interpolate(_named("count")), " รายการ"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เป้าหมาย: ผู้รีวิวต้องรีวิว ", _interpolate(_named("count")), "% ของจำนวนการสนทนา"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย: ยังไม่ได้ตั้งเป้าหมายรีวิว"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย: ผู้รับการรีวิวแต่ละคนต้องได้รับการรีวิวการสนทนาทั้งหมดของตน"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เป้าหมาย: ผู้รับการรีวิวแต่ละคนต้องมี ", _interpolate(_named("count")), " ของการสนทนาทั้งหมดได้รับการรีวิว"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เป้าหมาย: ผู้รับการรีวิวแต่ละคนต้องมี ", _interpolate(_named("count")), "% ของการสนทนาทั้งหมดได้รับการรีวิว"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เป้าหมาย: ผู้รีวิวแต่ละคนต้องทํารีวิวหนึ่งรายการ"]), _normalize(["เป้าหมาย: ผู้รีวิวแต่ละคนต้องทํารีวิว ", _interpolate(_named("count")), " รายการ"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["การแทนที่: ผู้รีวิวไม่สามารถแทนที่การสนทนาได้"]), _normalize(["การแทนที่: ผู้รีวิวสามารถแทนที่การสนทนาได้"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรีวิวตนเอง: ผู้รีวิวสามารถกําหนดการสนทนาของตนเองได้"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการกําหนดเงื่อนไขวันที่"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการตั้งชื่อการมอบหมาย"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลสรุป"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงื่อนไข"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " จาก ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายสองสัปดาห์"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวัน"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายเดือน"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เสมอ"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายสัปดาห์"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาของวงจร"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าเป็นใช้งานอยู่"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเป็นแบบร่าง"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายถูกบันทึกเป็นแบบร่าง"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายถูกตั้งค่าเป็นไม่ใช้งาน"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าเป็นไม่ใช้งาน"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการมอบหมาย"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายถูกสร้าง"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายว่าใช้งานอยู่"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายว่าไม่ใช้งาน"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การอัปเดตที่รอดําเนินการในการมอบหมายจะมีผลในวงจรถัดไป โดยเริ่มตั้งแต่วันที่ ", _interpolate(_list(0))])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่การเปลี่ยนแปลง"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช้งาน"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอดำเนินการ"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการมอบหมาย"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการมอบหมายแล้ว"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่หรือบอทได้สรุปการสนทนาหรือไม่"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่ได้สรุปการสนทนาอย่างสุภาพหรือไม่"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าการสนทนาได้รับการสรุปหรือไม่"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปิด"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ข้อผิดพลาดทางไวยากรณ์ การสะกดคํา และรูปแบบทั้งในปฏิสัมพันธ์ของเจ้าหน้าที่และบอท"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ข้อผิดพลาดทางไวยากรณ์ การสะกดคำ และรูปแบบของเจ้าหน้าที่"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ข้อผิดพลาดทางไวยากรณ์ การสะกดคำ และรูปแบบของเจ้าหน้าที่"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสะกดคำและไวยากรณ์"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาสําหรับคำทักทายของเจ้าหน้าที่และบอท"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดสําหรับวลีคำทักทายทั่วไป"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดสําหรับการทักทายทั่วไป"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำทักทาย"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่เข้าใจและรับทราบความรู้สึกของลูกค้าหรือไม่"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุกรณีที่เจ้าหน้าที่ได้สะท้อนถึงความคิดเห็นของลูกค้าหรือใช้คําที่ห่วงใย เช่น 'รับทราบ' และ 'รับรู้'"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเห็นอกเห็นใจ"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่เข้าใจคําถามหรือข้อกังวลของลูกค้าหรือไม่"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าเจ้าหน้าที่เข้าใจคําถามหรือข้อกังวลของลูกค้าหรือไม่"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความเข้าใจ"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าข้อความสามารถเข้าใจได้ง่ายเพียงใดโดยพิจารณาจากความซับซ้อนของคําและความยาวของประโยค"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์ว่าข้อความสามารถเข้าใจได้ง่ายเพียงใดโดยพิจารณาจากความซับซ้อนของคําและความยาวของประโยค"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความนี้มี 1 ประโยคที่ยาวเกินไป ทําให้อ่านยาก"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ข้อความนี้มี ", _interpolate(_list(0)), " ประโยคที่ยาวเกินไป ทําให้อ่านยาก"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสามารถในการอ่าน"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดเพื่อหาวิธีแก้ปัญหาที่นําเสนอ"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์การสนทนาทั้งหมดเพื่อหาวิธีแก้ปัญหาที่นําเสนอ"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีแก้ปัญหาที่นําเสนอ"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์น้ำเสียงของเจ้าหน้าที่ตลอดการสนทนา"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิเคราะห์น้ำเสียงของเจ้าหน้าที่ตลอดการสนทนา"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียง"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจไม่พบข้อผิดพลาดใดๆ จากข้อความเจ้าหน้าที่"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถระบุข้อผิดพลาดทางไวยากรณ์"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบวลีคำทักทาย เพิ่มรายการใหม่หากจําเป็นผ่านผู้ดูแลระบบของคุณในการตั้งค่า"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ได้สรุปการสนทนาอย่างสุภาพ"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบวลีปิดการสนทนา เพิ่มรายการใหม่หากจําเป็นผ่านผู้ดูแลระบบของคุณในการตั้งค่า"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ได้สะท้อนถึงความคิดเห็นของลูกค้าหรือใช้คําพูดที่ห่วงใย"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจจับได้ว่าเจ้าหน้าที่ได้สะท้อนถึงความคิดเห็นของลูกค้าหรือใช้คําพูดที่ห่วงใย"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ใช้ประโยคยาวและคําที่ซับซ้อน ทําให้บางคนเข้าใจได้ยาก"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ใช้คําที่ซับซ้อน ทําให้บางคนเข้าใจได้ยาก"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ใช้ประโยคยาวๆ ทําให้บางคนเข้าใจได้ยาก"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ใช้ภาษาที่เข้าใจง่าย"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลไม่เพียงพอที่จะให้คะแนนในหมวดหมู่นี้"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่พยายามทําความเข้าใจคําถาม/คําขอของลูกค้า"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่เข้าใจคําถาม/คําขอของลูกค้าเป็นอย่างดี"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจจับได้ว่าเจ้าหน้าที่เข้าใจคําถาม/คําขอของลูกค้าหรือไม่"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียงของเจ้าหน้าที่ระหว่างการสนทนา"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียงของเจ้าหน้าที่ระหว่างการสนทนา"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียงของเจ้าหน้าที่ระหว่างการสนทนา"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียงของเจ้าหน้าที่ระหว่างการสนทนา"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำเสียงของเจ้าหน้าที่ระหว่างการสนทนา"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถระบุน้ำเสียงของเจ้าหน้าที่ได้"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาไม่ได้มีการสรุป"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนามีการสรุป"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจจับได้ว่าการสนทนามีการสรุปหรือไม่"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ขาดความเห็นอกเห็นใจต่อลูกค้าและปัญหาของพวกเขา"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่แสดงความเห็นอกเห็นใจต่อลูกค้าและปัญหาของพวกเขา"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจจับได้ว่าเจ้าหน้าที่แสดงความเห็นอกเห็นใจต่อลูกค้าและปัญหาของพวกเขาหรือไม่ "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าไม่ได้รับคำทักทาในระหว่างการสนทนา"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าได้รับคำทักทายในระหว่างการสนทนา"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบคําทักทายทั่วไป"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ไม่ได้ให้วิธีแก้ปัญหาแก่ลูกค้า"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่เสนอวิธีแก้ปัญหาให้กับลูกค้า"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถตรวจจับได้ว่าเจ้าหน้าที่เสนอวิธีแก้ปัญหาให้กับลูกค้าหรือไม่"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําที่ซับซ้อน"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประโยคยาว"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไวยากรณ์"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สะกดผิด"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิงลบ"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำขอโทษ"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สงบ"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ร่าเริง"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยากรู้อยากเห็น"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มืออาชีพ"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สนับสนุน"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปแบบ"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 การปิด"]), _normalize([_interpolate(_named("n")), " การปิด"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติจะตรวจสอบการปิดที่ได้รับอนุมัติเท่านั้น หากหมวดหมู่นี้ถูกตั้งค่าเป็นสําคัญ การไม่ใช้การปิดที่ได้รับอนุมัติจะทําให้การรีวิวล้มเหลว"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการปิดที่ได้รับอนุมัติ"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการปิดที่ได้รับอนุมัติ"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 การละเว้น"]), _normalize([_interpolate(_named("n")), " การละเว้น"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําและวลีที่ QA โดยอัตโนมัติจะละเว้นเป็นข้อผิดพลาดการสะกดผิดหรือไวยากรณ์"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การละเว้นการสะกดผิดและไวยากรณ์"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การละเว้นของ QA โดยอัตโนมัติ"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 คําทักทาย"]), _normalize([_interpolate(_named("n")), " คําทักทาย"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติจะตรวจสอบเฉพาะคําทักทายที่ได้รับอนุมัติเท่านั้น หากหมวดหมู่นี้ถูกตั้งค่าเป็นสําคัญ การไม่ใช้คําทักทายที่ได้รับอนุมัติจะทําให้การรีวิวล้มเหลว"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนคําทักทายที่ได้รับอนุมัติ"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนคําทักทายที่ได้รับอนุมัติ"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติ"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกหมวดหมู่ QA โดยอัตโนมัติ"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริงที่มอบหมายโดยอัตโนมัติ:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชี → การตั้งค่า"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["บางหมวดหมู่ใช้ MS Azure ซึ่งนําเสนอฟังก์ชันการทํางานที่หลากหลายและภาษาที่รองรับ ตรวจสอบ ", _interpolate(_named("link")), " ของคุณสำหรับข้อมูลเพิ่มเติม หรือติดต่อผู้ดูแลระบบเพื่อดําเนินการดังกล่าว"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เข้าถึงรายการหมวดหมู่เพิ่มเติมและภาษาที่รองรับโดยใช้ MS Azure ตรวจสอบ ", _interpolate(_named("link")), " ของคุณหรือติดต่อผู้ดูแลระบบเพื่อดําเนินการดังกล่าว"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ QA โดยอัตโนมัติ"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หมวดหมู่ QA โดยอัตโนมัติจะกําหนดคะแนนโดยอัตโนมัติโดยใช้สเกลการให้คะแนนแบบคงที่ คุณสามารถปรับการให้คะแนนด้วยตนเองได้ในภายหลัง ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกหมวดหมู่ QA โดยอัตโนมัติ"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["คะแนนที่มอบหมายโดยอัตโนมัติขึ้นอยู่กับความรุนแรงของข้อผิดพลาดและจํานวน ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริง"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะหมวดหมู่การให้คะแนนที่เกี่ยวข้องเท่านั้นที่จะแสดงต่อผู้รีวิว หมวดหมู่การให้คะแนนจะแสดงอิงตามเงื่อนไขที่คุณตั้งไว้เมื่อสร้างหมวดหมู่"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชท"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเตรียมใช้งาน"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทรศัพท์"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เลือกประเภทหมวดหมู่การให้คะแนนสำหรับพื้นที่ทํางาน \"", _interpolate(_named("workspace")), "\""])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวต้องเลือกแบบฟอร์มลงคะแนนที่ถูกต้องด้วยตนเองเพื่อดูหมวดหมู่การให้คะแนนที่เกี่ยวข้องสําหรับแต่ละการสนทนา"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนประเภทหมวดหมู่การให้คะแนน"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนหมวดหมู่"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนประเภทหมวดหมู่การให้คะแนนหรือไม่"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทการให้คะแนนแบบมีเงื่อนไข"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนนแบบคงที่"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทหมวดหมู่การให้คะแนน"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าหมวดหมู่การให้คะแนน"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการนี้เลิกทำไม่ได้"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการให้คะแนนทั้งหมด"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือคุณสามารถเก็บข้อมูลสถิติของคุณโดยไม่ลบข้อมูลใด ๆ โดยการเก็บถาวรหมวดหมู่"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ฉันเข้าใจว่าสิ่งนี้จะ ", _interpolate(_named("deleteAllRatings")), " ให้กับหมวดหมู่นี้"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น น้ำเสียงสมบูรณ์แบบ"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงหมวดหมู่เฉพาะในกรณีที่"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"เสมอ\" จะถูกละทิ้ง ไม่สามารถใช้ร่วมกับเงื่อนไขอื่นได้"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกครั้ง"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเงื่อนไข"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนความพึงพอใจ (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทแหล่งที่มา"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางการสนทนา"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ต้องมีชื่อ"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหมวดหมู่ไปยังแบบฟอร์มลงคะแนน"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวร"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มหมวดหมู่การให้คะแนน"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่เก็บถาวรจะไม่ปรากฏบนแบบฟอร์มลงคะแนน แต่ช่วยให้คุณสามารถเก็บสถิติของคุณได้โดยไม่ต้องลบข้อมูลใดๆ"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การเก็บถาวร"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["หมวดหมู่การเก็บถาวร ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรหมวดหมู่การให้คะแนนแล้ว"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พิมพ์ \"ลบหมวดหมู่\" เพื่อลบหมวดหมู่นี้"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ลบหมวดหมู่ ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่การให้คะแนนแล้ว"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำหมวดหมู่การให้คะแนนแล้ว"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่มหมวดหมู่การให้คะแนนแล้ว"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบกลุ่มหมวดหมู่การให้คะแนนแล้ว"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนแปลงลําดับกลุ่มหมวดหมู่การให้คะแนนแล้ว"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตกลุ่มหมวดหมู่การให้คะแนนแล้ว"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลิกเก็บถาวรหมวดหมู่การให้คะแนนแล้ว"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตหมวดหมู่การให้คะแนนแล้ว"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อเจ้าหน้าที่ล้มเหลวในหมวดหมู่ที่สําคัญ หมวดหมู่ทั้งหมดในการรีวิวนั้นจะล้มเหลวโดยอัตโนมัติ"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่การให้คะแนนที่สําคัญ"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหมวดหมู่ในกลุ่มนี้"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างหมวดหมู่การให้คะแนนใหม่"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขหมวดหมู่"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบหมวดหมู่ทั้งหมดและตรวจสอบข้อมูลภายใต้กลุ่มนี้"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การลบกลุ่มหมวดหมู่จะทําให้หมวดหมู่ทั้งหมดที่เชื่อมโยงกับกลุ่มนั้นเป็นแบบไม่ได้จัดกลุ่ม"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบกลุ่มหมวดหมู่ ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเลือกลบหมวดหมู่ภายใต้กลุ่มจะทำให้สูญเสียข้อมูลรีวิวทั้งหมดที่เกี่ยวข้องกับหมวดหมู่เหล่านี้"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่ม"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวสามารถเลือกเหตุผลมากกว่าหนึ่งข้อเป็นสาเหตุหลัก"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวสามารถเลือกเหตุผลมากกว่าหนึ่งข้อเป็นสาเหตุหลัก"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลายเหตุผล"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช่น น้ำเสียง"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มกลุ่มหมวดหมู่ใหม่"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มใหม่"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม \"อื่นๆ\""])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสาเหตุที่แท้จริง"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริงนี้มีอยู่แล้ว"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสาเหตุที่แท้จริง"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสาเหตุที่แท้จริงเพื่ออธิบายการให้คะแนน"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลการให้คะแนน"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแล้ว"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มแบบฟอร์มลงคะแนนไปยังหมวดหมู่การให้คะแนนนี้"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการเก็บถาวร"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตหมวดหมู่"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนทั้งหมด"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงสําหรับ"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนเชิงลบ"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนนเชิงลบและเป็นกลาง"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่ อัปเดต"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำหนัก"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่ที่สําคัญกว่าสําหรับคุณอาจมีน้ำหนักมากขึ้นในการคํานวณคะแนนใบสั่งงาน"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["น้ำหนัก (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการปรับเทียบ"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการปรับเทียบ"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดเซสชั่นการปรับเทียบเป็นประจําช่วยให้ผู้รีวิวสามารถรักษาเทคนิคการให้คะแนนของตนให้สอดคล้องกัน และทําให้แน่ใจว่าเจ้าหน้าที่จะได้รับฟีดแบ็คที่สม่ำเสมอและเป็นกลางในระดับเดียวกัน"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูแผน"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " เป็นกระบวนการที่ช่วยให้ทีมของคุณเข้าใจตรงกันว่าควรจัดการและประเมินปฏิสัมพันธ์กับลูกค้าทั้งหมดอย่างไร"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["การปรับเทียบสามารถใช้ได้สําหรับแผน ", _interpolate(_list(0)), " และ ", _interpolate(_list(1)), " ของเรา"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ให้ไว้ก่อนหน้านี้จะไม่แสดงเมื่อมีการเพิ่มการสนทนาไปยังเซสชัาน"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มองไม่เห็น"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้ให้ก่อนหน้านี้ถือเป็นรีวิวการปรับเทียบ ผู้รีวิวการสนทนาหนึ่งคนเท่านั้นที่จะถูกเพิ่มเข้าไปในเซสชั่น รีวิวเฉพาะข้อความจะไม่แสดงขึ้น"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มองเห็นได้"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมองเห็นรีวิวที่ได้ให้ก่อนหน้านี้"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การปรับเทียบเป็นกระบวนการที่ช่วยให้ทีมของคุณเข้าใจตรงกันว่าควรจัดการและประเมินปฏิสัมพันธ์กับลูกค้าทั้งหมดอย่างไร ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้สามารถเข้าถึงรีวิวทั้งหมดที่ได้ให้ไว้ในระหว่างช่วงการปรับเทียบได้ไม่จํากัด"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรีวิวทั้งหมดเสมอ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าเป้าหมาย"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลังจากส่งการรีวิว ผู้ใช้จะเห็นการประเมินที่ได้รับจากผู้รีวิวรายอื่นในระหว่างเซสชั่นการปรับเทียบ"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรีวิวการปรับเทียบทั้งหมดหลังจากส่งรีวิว"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้สามารถเข้าถึงรีวิวทั้งหมดที่ได้ให้ไว้ในระหว่างช่วงการปรับเทียบได้ไม่จํากัด"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรีวิวทั้งหมดเสมอ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้จัดการ"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลังจากส่งการรีวิว ผู้ใช้จะเห็นการประเมินที่ได้รับจากผู้รีวิวรายอื่นในระหว่างเซสชั่นการปรับเทียบ"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรีวิวการปรับเทียบทั้งหมดหลังจากส่งรีวิว"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลังจากส่งการรีวิว ผู้ใช้จะเห็นการประเมินที่ได้รับจากผู้รีวิวรายอื่นในระหว่างเซสชั่นการปรับเทียบ"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรีวิวการปรับเทียบทั้งหมดหลังจากส่งรีวิว"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้จะเห็นเฉพาะรีวิวของตนเองที่ได้รับในระหว่างช่วงการปรับเทียบเท่านั้น"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เห็นรีวิวของตัวเองเท่านั้น"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการตั้งค่าการปรับเทียบแล้ว"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมองเห็นรีวิวการปรับเทียบ"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดค่าเกณฑ์สําหรับเมตริกแดชบอร์ดโดยเติมช่องว่างตามระบบเมตริกภายในทีมของคุณ ตรวจสอบค่าเกณฑ์บนแดชบอร์ด"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมาย"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเทียบ"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั่วไป"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็ก"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิก"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเกณฑ์"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตค่าเกณฑ์แล้ว"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอสักครู่..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ตรวจสอบให้แน่ใจว่าคุณมีสิทธิ์ของผู้ดูแลระบบและ ", _interpolate(_named("retry")), " หรือ ", _interpolate(_named("contact")), " สําหรับความช่วยเหลือเพิ่มเติม"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อเรา"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าเราไม่สามารถเข้าถึง API ของโปรแกรมฝ่ายสนับสนุนของคุณได้"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองอีกครั้ง"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรากําลังติดต่อโปรแกรมฝ่ายสนับสนุนของคุณ คุณจะถูกเปลี่ยนเส้นทางชั่วขณะหนึ่ง"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ที่อนุญาต"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["กลับไปที่ ", _interpolate(_list(0)), " ของคุณเพื่อเพิ่มการเชื่อมต่อโปรแกรมฝ่ายสนับสนุนใหม่"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าคุณพลาดไปหนึ่งหรือสองขั้นตอน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีองค์กรที่มีโดเมนอีเมลเดียวกันอยู่แล้ว"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าการเชื่อมต่อ"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในขณะเดียวกัน..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดรอสักครู่ในขณะที่เราตรวจสอบสิทธิ์ของคุณ"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในขณะเดียวกัน..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังผสานระบบ"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างพื้นที่ทํางาน"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["หากต้องการใช้ QA ของ Zendesk ต่อไป ", _interpolate(_named("link"))])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกือบพร้อมใช้งานแล้ว"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบพื้นที่ทํางาน"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับบัญชี"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากต้องการใช้ QA ของ Zendesk ต่อไป ผู้จัดการของคุณต้องมอบหมายคุณไปยังพื้นที่ทํางาน"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลบบัญชีของคุณจะเป็นการลบข้อมูลทั้งหมดที่เรามีเกี่ยวกับคุณอย่างถาวร ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การให้คะแนน เนื้อหาการสนทนา พื้นที่ทํางาน ฯลฯ ที่มีทั้งหมด"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยัง Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัดการเชื่อมต่อ"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกการเชื่อมต่อการผสานระบบจะปิดใช้งานการแจ้งเตือน QA ของ Zendesk ทั้งหมดผ่าน Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัดการเชื่อมต่อ Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การผสานระบบ Slack ถูกตัดการเชื่อมต่อจาก QA ของ Zendesk"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําหนดการตั้งค่าการแจ้งเตือนเริ่มต้นสําหรับผู้ใช้ใหม่ทั้งหมดของบัญชี"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แทนที่การตั้งค่าสําหรับผู้ใช้ทั้งหมด"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณกําลังจะแทนที่การตั้งค่าการแจ้งเตือนสําหรับผู้ใช้ทั้งหมดของบัญชี"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้จะไม่สามารถกําหนดการตั้งค่าการแจ้งเตือนของตนเองได้"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตการตั้งค่าการแจ้งเตือนแล้ว"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเตือนเมื่อหมวดหมู่ที่สําคัญล้มเหลว"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แจ้งเตือนเมื่อ CSAT ได้รับการตอบสนอง"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทริกเกอร์"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างพื้นที่ทํางานและข้อมูลสาธิต"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างข้อมูลสาธิตใหม่และเก็บพื้นที่ทํางานสาธิตไว้"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบพื้นที่ทํางานและข้อมูลสาธิต"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเฟรชข้อมูลการสาธิต"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีเฟรชข้อมูล"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["จัดการโหมดสาธิต \"", _interpolate(_named("accountName")), "\" ของบัญชี"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โหมดสาธิต"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อยู่ในหน้า"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากหน้า"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากหน้านี้และจะสูญเสียการเปลี่ยนแปลงทั้งหมดหรือไม่"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้ทํางานการมอบหมายเสร็จสิ้นแล้ว"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการมอบหมาย"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายที่เสร็จสมบูรณ์"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการมอบหมาย"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขณะนี้ยังไม่มีงานให้คุณรีวิว"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปรับการมอบหมายหรือรอให้วงจรถัดไปเริ่มต้น"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองปรับตัวกรอง"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการมอบหมาย"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอยดูหน้านี้สําหรับการอัปเดต"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีงานสําหรับวงจรนี้"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสนทนาที่จะแสดง"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายงานว่าเสร็จสิ้น"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณได้รีวิวผู้ใช้รายนี้แล้ว"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการรีวิวตนเองในงานนี้"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มโดยคลิกที่ไอคอนการปรับเทียบในส่วนหัวของมุมมองการสนทนา"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเพิ่มการสนทนา"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันครบกำหนด"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างการมอบหมาย"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเซสชั่นการปรับเทียบ"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายของคุณ"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การมอบหมายอื่นๆ"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการมอบหมายที่ใช้งานอยู่"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการมอบหมาย"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวทั้งหมด"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิวทุกคนยกเว้นฉัน"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้รับการรีวิว"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้รับการรีวิว"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออกแล้ว"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะทั้งหมด"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการที่ต้องทำ"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวทั้งหมด"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวทุกคนยกเว้นฉัน"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถูกมอบหมายให้ฉัน"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้รีวิว"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้รีวิว"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " วัน"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ชั่วโมง"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " นาที"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายอีกครั้ง"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้พื้นที่ทํางานทั้งหมด"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวปัจจุบัน"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายใหม่สําหรับวงจรนี้"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวใหม่"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้รีวิว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายการสนทนาอีกครั้ง"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายการสนทนาอีกครั้ง"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาที่จะย้าย"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วงจรปัจจุบัน"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการมอบหมาย"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้รีวิว"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้รีวิว"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายการสนทนาให้ฉันอีกครั้ง"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายการสนทนาให้กับผู้ใช้รายอื่นอีกครั้ง"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวม"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " วัน"]), _normalize([_interpolate(_named("n")), " วัน"]), _normalize([_interpolate(_named("n")), " วัน"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ชั่วโมง"]), _normalize([_interpolate(_named("n")), " ชั่วโมง"]), _normalize([_interpolate(_named("n")), " ชั่วโมง"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนวงจรที่เสร็จสมบูรณ์"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนเซสชั่นที่เสร็จสมบูรณ์"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมายของคุณ"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเหตุผลเพื่อลบการสนทนานี้"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อธิบายว่าเหตุใดคุณจึงลบการสนทนานี้"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออก"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนา"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณต้องการที่จะเก็บการเปลี่ยนแปลงนี้หรือไม่"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเหตุผลเพื่อแทนที่การสนทนานี้"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละทิ้งการเปลี่ยนแปลง"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บไว้"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนแปลงเลย"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบการแทนที่"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อธิบายว่าเหตุใดคุณจึงแทนที่การสนทนานี้"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออก"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณยังต้องการลบการสนทนานี้ออกจากการมอบหมายหรือไม่ ซึ่งจะลดเป้าหมายลง 1 รายการ"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แทนที่"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แทนที่การสนทนา"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบร่าง"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแบบร่าง"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายให้กับฉันอีกครั้ง"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายให้กับผู้ใช้รายอื่นอีกครั้ง"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอบหมายอีกครั้งและรีวิว"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการสนทนา"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้รีวิว"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูน้อยลง"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเพิ่มเติม"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เป้าหมาย: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " ได้รับรีวิว"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่เสร็จสิ้น"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงจำนวนวงจรที่เสร็จสมบูรณ์"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงจำนวนเซสชั่นที่เสร็จสมบูรณ์"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาที่เหลือ"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["งาน"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนาถูกแทนที่"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับรายการงาน"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " ภายหลัง"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " วินาที"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " วินาที"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน ", _interpolate(_named("hours")), " ชม."])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน ", _interpolate(_named("hours")), " ชม. ", _interpolate(_named("minutes")), "นาที"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " วัน ", _interpolate(_named("minutes")), " นาที"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ชม."])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ชม. ", _interpolate(_named("minutes")), " นาที"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ชม. ", _interpolate(_named("minutes")), " นาที ", _interpolate(_named("seconds")), " วินาที"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ชม. ", _interpolate(_named("seconds")), " วินาที"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " นาที"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " นาที ", _interpolate(_named("seconds")), " วินาที"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " เดือน"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " วินาที"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ปี"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อสักครู่"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณไม่มีสิทธิ์ ติดต่อผู้ดูแลระบบของคุณเพื่อขอความช่วยเหลือ"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีหน้าเว็บที่คุณกําลังค้นหาอยู่"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดที่ไม่คาดคิด ติดต่อฝ่ายสนับสนุนเพื่อขอความช่วยเหลือ"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเข้าถึงถูกปฏิเสธ ไม่ได้รับอนุญาตให้ทำการดําเนินการนั้น"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าที่คุณกำลังหาอาจไม่มีอยู่หรือคุณไม่มีสิทธิ์ในการเข้าถึง"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณกําลังไปเร็วเกินไปและเรามีปัญหาในการตามให้ทันคุณ"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการเปลี่ยนแปลงแล้ว"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่ไม่ได้บันทึก"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["และ"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ใช้งานแล้ว"]), _normalize([_interpolate(_named("n")), " ใช้งานแล้ว"]), _normalize([_interpolate(_named("n")), " ใช้งานแล้ว"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่ออยู่"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างเมื่อ"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างโดย"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำคัญ"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสร็จสิ้น"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คือ"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["อดีตสมาชิก"]), _normalize(["อดีตสมาชิก"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นี่"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " รายการที่เลือก"]), _normalize([_interpolate(_named("n")), " รายการที่เลือก"]), _normalize([_interpolate(_named("n")), " รายการที่เลือก"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตครั้งล่าสุด"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... และอีก +1"]), _normalize(["... และอีก +", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["อีก +1"]), _normalize(["อีก +", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มี"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือ"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออกแล้ว"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " การตอบกลับ"]), _normalize([_interpolate(_named("n")), " การตอบกลับ"]), _normalize([_interpolate(_named("n")), " การตอบกลับ"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันศุกร์"]), _normalize(["วันศุกร์"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันจันทร์"]), _normalize(["วันจันทร์"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันเสาร์"]), _normalize(["วันเสาร์"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันอาทิตย์"]), _normalize(["วันอาทิตย์"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันพฤหัสบดี"]), _normalize(["วันพฤหัสบดี"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันอังคาร"]), _normalize(["วันอังคาร"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["วันพุธ"]), _normalize(["วันพุธ"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อวานนี้"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้ดูแลระบบ"]), _normalize(["ผู้ดูแลระบบ"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้จัดการบัญชี"]), _normalize(["ผู้จัดการบัญชี"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้"]), _normalize(["ผู้ใช้"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA โดยอัตโนมัติ"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกแล้ว"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียด"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชสชั่นของคุณหมดเวลาแล้ว"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์นี้ไม่สามารถเว้นว่างได้"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบรายการ"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีบุคคลที่ตรงกัน"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["เจ้าของ"]), _normalize(["เจ้าของ"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียด"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียด"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียด"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก่อนที่เราจะเริ่ม โปรดอ่านและยินยอมในข้อกําหนดของเรา แล้วเราจะไปได้ด้วยดี"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งแล้ว"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แน่ใจหรือไม่"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ทําโดยรวม"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดตแล้ว"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["เจ้าหน้าที่"]), _normalize(["เจ้าหน้าที่"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลูกค้าเป้าหมาย"]), _normalize(["ลูกค้าเป้าหมาย"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้จัดการ"]), _normalize(["ผู้จัดการ"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้รีวิว"]), _normalize(["ผู้รีวิว"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ไม่ประสงค์ออกนาม"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวร"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ได้รับมอบหมาย"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนแปลง"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เร็วๆ นี้"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อ"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดำเนินการต่อ"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกเพื่อคัดลอก"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงก์"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้าง"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โซนอันตราย"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธ"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย (ไม่บังคับ)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องได้รับการยืนยัน"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดใช้งาน"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ละทิ้งการเปลี่ยนแปลงที่ไม่ได้บันทึกหรือไม่"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งาน"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งออก"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็ค"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรอง"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความถี่"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป้าหมาย"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าใจแล้ว"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝ่ายสนับสนุน"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญ"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่เสมอ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแจ้งเตือน"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อื่นๆ"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราผ่าน"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าส่วนบุคคล"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก่อนหน้า"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรไฟล์"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านแบบย่อ"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออก"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งอีกครั้ง"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กู้คืน"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รับการรีวิว"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ/เห็น"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ทําโดยสมาชิกพื้นที่ทํางาน"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาท"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกการเปลี่ยนแปลง"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกลไบนารี"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกล 3 จุด"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกล 4 จุด"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สเกล 5 จุด"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกความถี่"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่า"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงน้อยลง"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเพิ่มเติม"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้าม"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สปอตไลท์"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขั้นตอน"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่ง"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟีดแบ็คแบบสำรวจ"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจ"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วงเวลา"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่ไม่ได้บันทึก"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัปเดต"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["น้ำหนัก"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["คุณได้รับคำเชิญจาก ", _interpolate(_list(0)), " ให้เข้าร่วม QA ของ Zendesk"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ระบบจะเชื่อมต่อผู้ใช้โดยอัตโนมัติจากผู้ดูแลส่วนกลาง ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการผู้ใช้"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับการจัดการผู้ใช้"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ผู้ใช้บางรายจะได้รับการเตรียมใช้งานและซิงค์โดยอัตโนมัติจากผู้ให้บริการข้อมูลประจําตัวของคุณ ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["สิทธิ์ในบัญชีของ \"", _interpolate(_list(0)), "\" ถูกเปลี่ยนแปลง"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับสิทธิ์ของผู้ใช้"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ต้องถามฉันอีก"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การเปลี่ยนแปลงทั้งหมดที่ทําในหน้าผู้ใช้จะมีผลกับพื้นที่ทํางาน ", _interpolate(_named("help2")), " ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซึ่งหมายความว่าสิทธิ์เข้าถึงบางส่วนของ QA ของ Zendesk ของผู้ใช้อาจเปลี่ยนแปลงได้"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนสิทธิ์ระดับบัญชี"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกลบออก"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกลบออก"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เมื่อคุณลบผู้ใช้ออก ผู้ใช้จะสูญเสียสิทธิ์เข้าถึงบัญชีของคุณ รีวิว ข้อคิดเห็น และการให้คะแนนจะยังคงอยู่ใน QA ของ Zendesk ", _interpolate(_named("linebreak")), "การลบผู้ใช้จะไม่ส่งผลต่อจํานวนที่นั่งในการสมัครใช้บริการของคุณ"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้รายนี้จะถูกลบออกจากพื้นที่ทํางานทั้งหมดที่คุณจัดการ ", _interpolate(_named("linebreak")), " รีวิว ข้อคิดเห็น และการให้คะแนนของพวกเขาจะยังคงไม่เปลี่ยนแปลง"]), _normalize(["พวกเขาจะถูกลบออกจากพื้นที่ทํางานทั้งหมดที่คุณจัดการ ", _interpolate(_named("linebreak")), " รีวิว ข้อคิดเห็น และการให้คะแนนทั้งหมดจะยังคงไม่เปลี่ยนแปลง"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ลบ ", _interpolate(_named("name")), " ออก"]), _normalize(["ลบผู้ใช้ ", _interpolate(_named("name")), " ราย"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถดูทุกอย่างและจัดการการตั้งค่าและการเรียกเก็บเงินทั้งหมด"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถดูทุกอย่างและจัดการการตั้งค่าส่วนบุคคลและพื้นที่ทํางาน ยกเว้นการเรียกเก็บเงิน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ของบัญชี"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ที่เฉพาะพื้นที่ทํางานช่วยให้ผู้ใช้สามารถดู (และ) แก้ไขข้อมูลภายในพื้นที่ทํางานที่พวกเขาเป็นสมาชิกได้"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้เกี่ยวกับบอท QA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทของคุณจะปรากฏที่นี่เมื่อมีการซิงค์การสนทนาที่พวกเขาเข้าร่วม"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีบอทถูกซิงค์"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทําเครื่องหมายบอทเป็นผู้ใช้จะให้สิทธิ์การเข้าถึงเพื่อลงชื่อเข้าใช้ ข้อมูลบอทในอดีตจะยังคงพร้อมใช้งานบนแดชบอร์ด"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบบอทดังกล่าว"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองค้นหาอีกครั้งโดยใช้คีย์เวิร์ดหรือการสะกดคําอื่น"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาบอท"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามชื่อบอท"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายเป็นผู้ใช้"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะผู้ดูแลระบบและผู้จัดการบัญชีเท่านั้นที่สามารถทําเครื่องหมายบอทเป็นผู้ใช้ได้"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อบอท"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แชทล่าสุด"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายด้วยตนเองแล้ว"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบได้"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทจะถูกลบออกจากการวิเคราะห์อัตโนมัติและการมอบหมายใดๆ ข้อมูลใหม่จะไม่ปรากฏในแดชบอร์ด เพื่อให้มั่นใจได้ถึงการรายงานที่แม่นยำและการจัดการบอทที่ง่ายขึ้น"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทเชิงสร้างใหม่"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ทราบ"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอทเวิร์กโฟลว์"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยังกลุ่ม"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกกลุ่ม"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่ม"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออกจากกลุ่ม"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกกลุ่ม"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ในบัญชี"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มไปยังพื้นที่ทํางาน"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่ล่าสุด"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาทระดับบัญชี"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้แต่ละคนจะได้รับสิทธิ์ระดับบัญชีและ/หรือพื้นที่ทํางาน สิทธิ์ของผู้ดูแลระบบเป็นระดับบัญชีแล้ว ในขณะที่สิทธิ์อื่นๆ เป็นระดับพื้นที่ทํางาน"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออกจากพื้นที่ทํางาน"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ในพื้นที่ทํางาน"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ที่จัดการในกลุ่ม"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขรายละเอียด"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําเครื่องหมายเป็นบอท"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การทําเครื่องหมายผู้ใช้เป็นบอทลบการเข้าถึงการลงชื่อเข้าใช้ออก ข้อมูลผู้ใช้ในอดีตจะยังคงพร้อมใช้งานบนแดชบอร์ด"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบบอทออกจากการวิเคราะห์อัตโนมัติและการมอบหมายใดๆ ข้อมูลใหม่จะไม่ปรากฏในแดชบอร์ด เพื่อให้มั่นใจได้ถึงการรายงานที่แม่นยำและการจัดการบอทที่ง่ายขึ้น"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเว้นบอทจากการตรวจสอบ"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ถูกทําเครื่องหมายว่าเป็นบอท"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เฉพาะสิทธิ์ประเภทผู้ใช้เท่านั้นที่สามารถทําเครื่องหมายเป็นบอทได้"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถทำเครื่องหมายผู้ใช้ที่มีหลายบัญชีเป็นบอทได้"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามผู้ใช้"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บอท"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ บอท และพื้นที่ทํางาน"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้และพื้นที่ทํางาน"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ดูแลระบบ"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีสิทธิ์"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่ออยู่"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มด้วยตนเองแล้ว"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รอดำเนินการ"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําเชิญที่รอดําเนินการ"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการที่จําเป็น"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["... และอีกหนึ่ง ... และอีก ", _interpolate(_named("n"))])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนสิทธิ์เป็น"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ลบและเชิญใหม่เพื่อเปลี่ยนบทบาทของผู้ใช้ที่ส่งคำเชิญที่รอดําเนินการ ", _interpolate(_named("n")), " ราย"]), _normalize(["ลบและเชิญอีกครั้งเพื่อเปลี่ยนบทบาทของผู้ใช้ที่ส่งคำเชิญที่รอดําเนินการ ", _interpolate(_named("n")), " ราย"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["แก้ไขรายละเอียดสําหรับ ", _interpolate(_named("name"))]), _normalize(["แก้ไขรายละเอียดสําหรับผู้ใช้ที่เลือก ", _interpolate(_named("n")), " ราย"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["อัปเดตผู้ใช้ ", _interpolate(_named("n")), " รายแล้ว"]), _normalize(["อัปเดตผู้ใช้ ", _interpolate(_named("n")), " รายแล้ว"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["กลุ่มนี้มีผู้ใช้ 1 คนจากพื้นที่ทํางานอื่น"]), _normalize(["กลุ่มนี้มีผู้ใช้ ", _interpolate(_named("n")), " คนจากพื้นที่ทํางานอื่น"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งาน"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกเก็บถาวร"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่เก็บถาวร"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างกลุ่ม"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกสร้างขึ้น"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่ไม่มีชื่อ"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบกลุ่มนี้อย่างถาวรหรือไม่"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบกลุ่ม"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบกลุ่ม"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขกลุ่ม"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวหน้ากลุ่ม"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาทกลุ่ม"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกซ่อนไว้"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มนี้จะไม่ปรากฏให้เห็นที่อื่นใน QA ของ Zendesk"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการสิทธิ์ในพื้นที่ทํางานเท่านั้น"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่ไม่ปรากฎใน QA ของ Zendesk"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวหน้า"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิก"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่ไม่มีชื่อ"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีกลุ่มอื่นมีชื่อนี้อยู่แล้ว"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีกลุ่มที่เก็บถาวรชื่อนี้อยู่แล้ว"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทบาทของคุณอนุญาตเฉพาะการจัดการสมาชิกของกลุ่มนี้เท่านั้น ติดต่อผู้ใช้ที่มีสิทธิ์สูงกว่าสําหรับการเปลี่ยนแปลงเพิ่มเติม"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบออกจากการเป็นหัวหน้ากลุ่ม"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกกู้คืน"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกบันทึก"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งเป็นหัวหน้ากลุ่ม"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ถูกยกเลิกเก็บถาวร"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเปลี่ยนแปลงที่ยังไม่ได้บันทึก"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้ใช้ที่มีอยู่ของคุณไปยังกลุ่มนี้"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามชื่อหรืออีเมล"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["กลุ่ม \"", _interpolate(_named("name")), "\" ที่มองเห็นได้"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["สมาชิกแต่ละคนเป็น ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียดเกี่ยวกับกลุ่ม"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอททริบิวต์บางอย่างของกลุ่มจะได้รับการเตรียมใช้งานและซิงค์โดยอัตโนมัติจากผู้ให้บริการข้อมูลประจําตัวของคุณ"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อ่านรายละเอียดเกี่ยวกับกลุ่ม"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างกลุ่ม"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดระเบียบสมาชิกในทีมของคุณได้อย่างง่ายดายและเลียนแบบโครงสร้างองค์กรของคุณ"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิกอย่างน้อย 1 คน"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มชื่อกลุ่ม"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีกลุ่มที่มีชื่อนี้อยู่แล้ว"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิกกลุ่ม"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่ม"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดระเบียบสมาชิกในทีมของคุณและเลียนแบบโครงสร้างองค์กรของคุณ"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิก"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ไม่มีกลุ่ม"]), _normalize(["1 กลุ่ม"]), _normalize([_interpolate(_named("count")), " กลุ่ม"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างกลุ่มใหม่หรือเปิดใช้งานกลุ่มที่เก็บถาวรแทน"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่มที่ใช้งานอยู่"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่มที่เก็บถาวร"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบกลุ่มที่ค้นหา"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลองค้นหาอีกครั้งโดยใช้คีย์เวิร์ดหรือการสะกดคําอื่น"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีกลุ่มที่เพิ่ม"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ในรายการเหล่านี้"]), _normalize(["ใน"]), _normalize(["ใน"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามชื่อกลุ่ม"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแล้ว"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["สามารถส่งใหม่ได้ ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["คงเหลือ ", _interpolate(_named("n")), " ที่นั่ง"]), _normalize(["คงเหลือ ", _interpolate(_named("n")), " ที่นั่ง"]), _normalize(["คงเหลือ ", _interpolate(_named("n")), " ที่นั่ง"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขณะนี้จํานวนที่นั่งของคุณถูกจํากัด เนื่องจากการสมัครใช้บริการของคุณถูกหยุดชั่วคราว เปิดใช้งานอีกครั้งเพื่อเพิ่มผู้ใช้เพิ่มเติม"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณใช้ที่นั่งในบัญชีของคุณถึงขีดจำกัดแล้ว คุณสามารถแทนที่ผู้ใช้ได้โดยการปิดใช้งานผู้ใช้ที่ใช้งานอยู่หรือเพิ่มที่นั่ง"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรายละเอียดการชําระเงิน"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เลือกเลือก ", _interpolate(_named("n"))]), _normalize(["เลือกแล้ว ", _interpolate(_named("n"))])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเลือก"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเลือก"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " แถว"]), _normalize([_interpolate(_named("n")), " แถว"]), _normalize([_interpolate(_named("n")), " แถว"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้ใช้จํานวนมากตามชื่อหรือที่อยู่อีเมล"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางข้อมูลที่นี่โดยคั่นด้วยเครื่องหมายจุลภาคหรือบรรทัดใหม่"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาผู้ใช้จํานวนมาก"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["แก้ไขสิทธิ์ในพื้นที่ทํางานสําหรับ ", _interpolate(_named("name"))]), _normalize(["แก้ไขสิทธิ์ในพื้นที่ทํางานสําหรับ ", _interpolate(_named("n")), " สมาชิกที่เลือก"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์การแก้ไข"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกสิทธิ์ในพื้นที่ทํางาน"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ลบ ", _interpolate(_named("name")), " ออกจากพื้นที่ทํางาน"]), _normalize(["ลบสมาชิก ", _interpolate(_named("name")), " รายออกจากพื้นที่ทํางาน"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["สมาชิก ", _interpolate(_named("n")), " รายถูกลบออก"]), _normalize(["สมาชิก ", _interpolate(_named("n")), " รายถูกลบออก"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลบสมาชิก"]), _normalize(["ลบสมาชิก"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้รายนี้จะถูกลบออกจากพื้นที่ทํางาน"]), _normalize(["ผู้ใช้เหล่านี้จะถูกลบออกจากพื้นที่ทํางาน"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["สิทธิ์ในพื้นที่ทํางานของ \"", _interpolate(_list(0)), "\" ถูกเปลี่ยนแปลง"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูการสนทนาและรีวิวที่เกี่ยวข้อง"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูทุกอย่างในพื้นที่ทํางาน แต่สามารถจัดการแบบทดสอบ การมอบหมาย ข้อโต้แย้ง และเซสชั่นการปรับเทียบเท่านั้น"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์ในพื้นที่ทํางาน"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูและจัดการทุกอย่างภายในพื้นที่ทํางานได้"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูทุกอย่างในพื้นที่ทํางาน ยกเว้นการตั้งค่าพื้นที่ทํางาน"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผู้ใช้"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ทั้งหมดจากพื้นที่ทํางานที่คุณจัดการถูกเพิ่มไปยังพื้นที่ทํางานนี้แล้ว"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอให้ผู้ดูแลระบบดําเนินการดังกล่าว"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้ใหม่ด้วยตนเองโดยตรงหรือขอให้ผู้ดูแลระบบดําเนินการดังกล่าว"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " หรือ ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีผู้ใช้ที่จะแสดง"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเพิ่ม"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเพิ่ม"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เพิ่มสมาชิก"]), _normalize(["เพิ่มสมาชิก"]), _normalize(["เพิ่มสมาชิก ", _interpolate(_named("n")), " ราย"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกสิทธิ์ในพื้นที่ทํางาน"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้ไปยังพื้นที่ทํางาน"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["เพิ่มสมาชิกไปยัง ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางอีเมลที่นี่โดยคั่นด้วยบรรทัดใหม่หรือเครื่องหมายจุลภาค"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["จับคู่ ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " อีเมล"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" กับผู้ใช้ที่เชื่อมต่อแล้ว:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ไม่สามารถจับคู่ ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " อีเมล"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบการสะกดหรือเชื่อมต่อในภายหลังทีละรายการ"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" กับผู้ใช้โปรแกรมฝ่ายสนับสนุน:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเชื่อมต่อ"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายถูกเชื่อมต่อ"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายที่ได้รับเชิญ"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายที่ได้รับเชิญ"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อจํานวนมาก"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำเชิญจํานวนมาก"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวอย่างเช่น:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อจากฝ่ายสนับสนุนของคุณเพื่อรีวิวปฏิสัมพันธ์ของพวกเขา"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["เชื่อมต่อผู้ใช้"]), _normalize(["เชื่อมต่อผู้ใช้"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อทีละรายการ"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากคุณต้องการให้ผู้ใช้ที่เชื่อมต่อของคุณเข้าถึง QA ของ Zendesk ได้ คุณสามารถเชิญพวกเขาได้ที่นี่"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้ที่เชื่อมต่อ"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["เชิญผู้ใช้"]), _normalize(["เชิญผู้ใช้"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้ามการเชิญ"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกผู้ใช้ที่จะเชื่อมต่อจากฝ่ายสนับสนุนของคุณเพื่อรีวิวปฏิสัมพันธ์ของพวกเขา"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการการสมัครใช้บริการของคุณ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อผู้ใช้โปรแกรมฝ่ายสนับสนุน"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งชื่อที่ไม่ซ้ำให้พื้นที่ทํางานของคุณ"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากเปิดใช้งาน การเชื่อมต่อบัญชีที่มีอยู่ทั้งหมดจะถูกเพิ่มไปยังพื้นที่ทํางานใหม่โดยอัตโนมัติ"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการเชื่อมต่อทั้งหมดไปยังพื้นที่ทํางานนี้"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างพื้นที่ทํางาน"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อพื้นที่ทํางาน"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างพื้นที่ทํางาน"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ถูกเพิ่มไปยังบัญชีโดยตรง (โดยไม่ต้องมอบหมายพื้นที่ทํางาน) จะได้รับมอบหมายบทบาทระดับบัญชี คุณสามารถเพิ่มไปยังพื้นที่ทํางานได้ในภายหลัง"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ได้รับคำเชิญจะได้รับมอบหมายบทบาทผู้ใช้ในระดับบัญชีโดยอัตโนมัติ คุณสามารถอัปเกรดเป็นบทบาทผู้ดูแลระบบได้ในภายหลัง"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการทั้งหมดนี้ใช้ได้"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางอีเมลที่นี่โดยคั่นด้วยบรรทัดใหม่หรือเครื่องหมายจุลภาค"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายที่ได้รับเชิญทางอีเมล"]), _normalize(["ผู้ใช้ ", _interpolate(_named("n")), " รายที่ได้รับเชิญทางอีเมล"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ถูกเพิ่มไปยังพื้นที่ทํางานจะถูกมอบหมายบทบาทผู้ใช้ในระดับบัญชีโดยอัตโนมัติ คุณสามารถอัปเกรดเป็นบทบาทผู้ดูแลระบบได้ในภายหลัง"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มอีกหนึ่ง"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้ไปยังบัญชีโดยไม่ต้องเพิ่มลงในพื้นที่ทํางาน"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ได้รับคำเชิญจะได้รับมอบหมายสิทธิ์เฉพาะบัญชีเท่านั้น และจะไม่ถูกเพิ่มไปยังพื้นที่ทํางานใดๆ"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้ไปยังพื้นที่ทํางานโดยตรงและมอบหมายสิทธิ์ในพื้นที่ทํางาน"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มผู้ใช้ไปยังพื้นที่ทํางาน"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำเชิญจํานวนมาก"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญเป็นรายบุคคล"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญเข้าสู่บัญชีเท่านั้น"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญเข้าสู่พื้นที่ทํางาน"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เชิญผู้ใช้"]), _normalize(["เชิญผู้ใช้"]), _normalize(["เชิญผู้ใช้ ", _interpolate(_named("n")), " ราย"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้จํานวนมากทางอีเมล"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ลบผู้ใช้"]), _normalize(["ลบผู้ใช้"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกเลือก"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชิญผู้ใช้ทางอีเมล"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีพื้นที่ทํางาน"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกสิทธิ์"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งคําเชิญอีกครั้ง"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่เชื่อมต่อ"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ที่ได้รับคำเชิญทางอีเมลที่รอดําเนินการ"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกพื้นที่ทํางาน"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ AI"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ดูแลส่วนกลาง"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การประกันคุณภาพ"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจัดการพนักงาน"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เสร็จสมบูรณ์ ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["เสร็จสมบูรณ์ ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["เสร็จสมบูรณ์ ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["วงจร ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิว"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขการมอบหมาย"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประหยัดเวลาและตั้งค่าการมอบหมายการรีวิวอัตโนมัติแทนการค้นหาและจัดสรรการสนทนาเพื่อรีวิวด้วยตนเอง"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ครบกำหนด ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถเพิ่มเซสชันได้ในส่วน 'การปรับเทียบ' ในเมนูด้านข้างของมุมมองการสนทนา"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการเพิ่มเซสชั่น"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นฐาน"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเซสชัน"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สามารถเพิ่มการสนทนาไปยังเซสชั่นได้โดยคลิกที่ไอคอนการปรับเทียบในส่วนหัวของมุมมองการสนทนา"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการเพิ่มการสนทนา"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่มีรีวิวที่ให้ ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ให้รีวิวการปรับเทียบ"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาเหตุที่แท้จริง"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้ามไปที่"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถโหลดแผ่นคําสั่งได้"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบข้อมูล"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พบตาม ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กําลังโหลดแผ่นคําสั่ง..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้า ตัวกรอง การสนทนา การตั้งค่า..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองส่วนตัว"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวกรองสาธารณะ"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["จาก ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จาก API ของคุณ"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["กำลังแสดง ", _interpolate(_named("rangeStart")), " ถึง ", _interpolate(_named("rangeEnd")), " ของ ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["กำลังแสดง ", _interpolate(_named("rangeStart")), " ถึง ", _interpolate(_named("rangeEnd")), " ของ ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อนแผงด้านข้าง"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงแผงด้านข้าง"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับแถบด้านข้าง"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่ยอมรับ"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งถูกปฏิเสธ"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่เปิดอยู่"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งที่ได้รับการยอมรับบางส่วน"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่เสร็จสิ้น"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่เห็น"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับแล้ว"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับบางส่วน"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " จากทั้งหมด"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธแล้ว"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้ง"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับแล้ว"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับบางส่วน"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธแล้ว"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["รีวิว"]), _normalize(["รีวิว"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนการรีวิวของพวกเขาที่ผู้รับการรีวิวได้เห็น"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จํานวนรีวิวของพวกเขาที่ได้เห็น"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้รีวิวที่ได้รับการโต้แย้ง"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้โต้แย้ง"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งตามหมวดหมู่"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งเมื่อเวลาผ่านไป"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาแก้ไขปัญหาโดยเฉลี่ย"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาแก้ไขปัญหาโดยเฉลี่ย (ชม.)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาแก้ไขปัญหาโดยเฉลี่ย (นาที)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแล้ว"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโต้แย้งทั้งหมด"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกแฮชแท็ก"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวม"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับแล้ว"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาการตอบสนอง"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบภาษา"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาทั้งหมด"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัจจัยขับเคลื่อนที่คาดการณ์ไว้"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบปัจจัยขับเคลื่อน"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัจจัยขับเคลื่อนทั้งหมด"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คํา Word Cloud"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบคํา"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําทั้งหมด"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การกล่าวถึง"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใหม่ล่าสุด"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก่าที่สุด"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดอยู่"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอมรับบางส่วน"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ได้ตอบสนองด้วย ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปฏิเสธแล้ว"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขแล้ว"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิว"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกโทนสกิน"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชั่น"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเซสชั่น"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบหมวดหมู่"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อคิดเห็น"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขนาดข้อคิดเห็น"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบขนาด"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกขนาด"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบตัวกรอง"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบกลุ่ม"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทุกกลุ่ม"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็ก"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแฮชแท็ก"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็กทั้งหมด"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบค่า"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าทั้งหมด"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบฟิลด์ที่กําหนดเอง"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุน"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแท็กฝ่ายสนับสนุน"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แท็กฝ่ายสนับสนุนทั้งหมด"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลดผลลัพธ์..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภทคําถาม"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบประเภทคําถาม"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มการตอบสนอง"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนน"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแบบฟอร์มลงคะแนน"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบฟอร์มลงคะแนนทั้งหมด"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบคะแนน"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนทั้งหมด"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มา"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแหล่งที่มา"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แหล่งที่มาทั้งหมด"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลของฟีดแบ็ค"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบเหตุผล"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผลทั้งหมด"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสำรวจ"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบแบบสํารวจ"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบสํารวจทั้งหมด"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทาง"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบช่องทาง"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่องทางทั้งหมด"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผู้ใช้"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ทั้งหมด"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบพื้นที่ทํางาน"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางานทั้งหมด"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปที่แบบทดสอบ"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบถูกลบหรือยังไม่ได้เผยแพร่ ตรวจสอบลิงก์อีกครั้งหรือกลับไปที่รายการแบบทดสอบ"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบนี้ยังไม่ได้แชร์ไปยังพื้นที่ทํางานที่คุณเป็นสมาชิก ขอให้ผู้ดูแลระบบเพิ่มคุณไปยังพื้นที่ทํางานที่สามารถเข้าถึงแบบทดสอบนี้ได้"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีแบบทดสอบ"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเข้าถึงแบบทดสอบที่อนุญาตให้กับพื้นที่ทํางานที่คุณไม่ได้เป็นสมาชิก ไม่สามารถบันทึกและเผยแพร่แบบทดสอบได้"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอให้ผู้ดูแลระบบบันทึกและเผยแพร่แบบทดสอบ"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณควรทําเครื่องหมายอย่างน้อยหนึ่งคําตอบว่าเป็นคําตอบที่ถูกต้อง"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟิลด์นี้ไม่สามารถเว้นว่างได้"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขณะนี้คุณกําลังดูตัวอย่างแบบทดสอบ"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกพื้นที่ทํางานที่สามารถเข้าถึงแบบทดสอบได้ ผู้ใช้ที่เป็นสมาชิกพื้นที่ทํางานหลายแห่งสามารถตอบแบบทดสอบได้เพียงครั้งเดียว การเลือก \"พื้นที่ทํางานทั้งหมด\" จะไม่รวมพื้นที่ทํางานที่สร้างขึ้นใหม่หลังจากเผยแพร่แบบทดสอบแล้ว"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเข้าถึงแบบทดสอบ"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวร"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากโหมดแสดงตัวอย่าง"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งออก"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงตัวอย่าง"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำถาม"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนอง"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทําแบบทดสอบ"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่แบบทดสอบ"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตั้งค่าแบบทดสอบ"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกเป็นแบบร่าง"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแบบทดสอบใหม่"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการเก็บถาวร"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือก"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มตัวเลือก"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบ่งปัน"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูดีใช่มั้ย"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําตอบของคุณถูกส่งแล้ว"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบของคุณได้รับการบันทึกแล้ว"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบของคุณได้รับการเผยแพร่แล้ว"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบของคุณถูกลบออกแล้ว"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มคำถาม"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแบบทดสอบ"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบที่เก็บถาวรและผลลัพธ์จะไม่ปรากฏในรายการแบบทดสอบอีกต่อไป คุณยังคงสามารถเข้าถึงผลลัพธ์ของแบบทดสอบที่เก็บถาวรและยกเลิกการเก็บถาวรได้ทุกเมื่อที่ต้องการ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแบบทดสอบ"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อเผยแพร่แล้ว จะไม่สามารถเปลี่ยนแปลงหรือแก้ไขแบบทดสอบได้ แบบทดสอบที่เผยแพร่ทั้งหมดจะสามารถเข้าถึงได้สําหรับผู้ใช้ทุกคนในบัญชีของคุณ"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่แบบทดสอบหรือไม่"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณแน่ใจหรือไม่ว่าต้องการลบแบบทดสอบนี้ การดำเนินการนี้เลิกทำไม่ได้"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบทดสอบหรือไม่"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อส่งแล้ว จะไม่สามารถเปลี่ยนแปลงคําตอบของคุณได้"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งแบบทดสอบหรือไม่"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกเก็บถาวรแบบทดสอบ"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกการเก็บถาวรแบบทดสอบจะนำแบบทดสอบไปอยู่ในส่วนภาพรวม ซึ่งจะช่วยให้ผู้เข้าร่วมก่อนหน้านี้สามารถเข้าถึงผลลัพธ์ของตนและผู้เข้าร่วมใหม่สามารถทำแบบทดสอบได้"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกเก็บถาวรแบบทดสอบ"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คําถามที่ซ้ำกัน"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย (ไม่บังคับ)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ายคําถามลง"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ายคําถามขึ้น"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำถาม"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแสดงตัวอย่างจะแสดงเฉพาะคําถามที่มีคําตอบเท่านั้น"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบคําถาม"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งแบบทดสอบ"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบที่ไม่มีชื่อ"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อแบบทดสอบ"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบแบบทดสอบและผลลัพธ์อย่างถาวร"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือคุณยังคงสามารถเข้าถึงผลลัพธ์ได้โดยการเก็บถาวรแบบทดสอบ"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอบแล้ว"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแบบทดสอบ"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดําเนินการนี้จะลบแบบทดสอบและผลลัพธ์อย่างถาวร"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ลบแบบทดสอบ ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทำซ้ำ"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบช่วยเพิ่มความมั่นใจและทักษะของทีม มอบประสบการณ์ที่สอดคล้อง และเตรียมความพร้อมให้สมาชิกในทีมคนใหม่"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถค้นหาแบบทดสอบตามชื่อแบบทดสอบหรือชื่อผู้สร้าง"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการสร้างแบบทดสอบ"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งออกรายการแบบทดสอบ"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนเฉลี่ย"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่เสร็จสมบูรณ์"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ/ผู้เขียน"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ / ผู้เขียน / พื้นที่ทํางาน"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เข้าร่วม"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เผยแพร่แล้ว"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่ทํางาน"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามแบบทดสอบ ผู้เขียน"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บถาวรแล้ว"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบร่าง"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งานอยู่"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบทั้งหมด"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบถูกเก็บถาวรแล้ว"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบถูกยกเลิกเก็บถาวรแล้ว"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบนี้ไม่ได้รับการตอบสนองจากผู้ใช้ในพื้นที่ทํางานที่เลือก"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบนี้ไม่ได้รับการตอบสนองก่อนที่จะถูกเก็บถาวร"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการตอบสนอง"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่นี่คุณจะเห็นรายละเอียดของคําตอบของแบบทดสอบ\nยังไม่มีใครกรอกแบบทดสอบของคุณ การแชร์จะช่วยได้อย่างแน่นอน"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่มีการตอบสนอง"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การตอบสนอง:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนนเฉลี่ย:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายบุคคล"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาพรวม"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " - สำเนา"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงก์แล้ว"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบผลลัพธ์"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แบบทดสอบของ ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถค้นหาตารางอันดับสําหรับชื่อผู้เข้าร่วม"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งออกตารางอันดับ"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คะแนน"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาตามผู้เข้าร่วม"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตารางอันดับ"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบบทดสอบ"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สลับตารางอันดับ"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซึ่งหมายความว่าคุณจะไม่สามารถกรองแฮชแท็กนี้บนแดชบอร์ดและจะไม่มีการเติมข้อความอัตโนมัติในข้อคิดเห็นอีกต่อไป การดำเนินการนี้ไม่สามารถย้อนกลับได้"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็ก, แฮชแท็ก, แฮชแท็ก, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแฮชแท็ก"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแฮชแท็ก"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างแฮชแท็ก"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อนุญาตให้สมาชิกสร้างแฮชแท็กใหม่"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แฮชแท็ก"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้แล้ว"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบแฮชแท็ก"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ลบแฮชแท็ก ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสร้างแฮชแท็กถูกล็อก"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสร้างแฮชแท็กถูกปลดล็อกแล้ว"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แฮชแท็ก '", _interpolate(_list(0)), "' ถูกลบ"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["แฮชแท็ก '", _interpolate(_list(0)), "' ถูกเปลี่ยนชื่อเป็น '", _interpolate(_list(1)), "'"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["เพิ่มแฮชแท็ก 1 รายการแล้ว"]), _normalize(["เพิ่มแฮชแท็ก ", _interpolate(_named("count")), " รายการแล้ว"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อใหม่"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการสร้างแฮชแท็ก"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนชื่อ"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["เปลี่ยนชื่อแฮชแท็ก \"", _interpolate(_named("tagRenamed")), "\""])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แยกแฮชแท็กด้วยเครื่องหมายจุลภาคหรือตัวแบ่งบรรทัด"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาแฮชแท็ก"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ไม่ต้องกังวล คุณสามารถทําได้ ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรงนี้"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อถูกเชื่อมโยงกับพื้นที่ทํางาน"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนบการเชื่อมต่อ"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนบการเชื่อมต่อกับพื้นที่ทํางานนี้"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเชื่อมต่อ"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อผู้ดูแลระบบของคุณเพื่อทําการเปลี่ยนแปลงที่นี่"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อ QA ของ Zendesk เพื่อทําการเปลี่ยนแปลงที่นี่"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีการเชื่อมต่อภายใต้บัญชี"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูเหมือนว่าคุณยังไม่ได้ผสานระบบเข้ากับแพลตฟอร์มการบริการลูกค้า"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["การลบการเชื่อมต่อจะเป็นการลบการสนทนาออกจากพื้นที่ทํางาน ", _interpolate(_named("workspaceName")), " และลบข้อมูลทั้งหมดที่เกี่ยวข้อง รวมถึงรีวิวและการโต้แย้ง"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบการเชื่อมต่อ"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ลบการเชื่อมต่อ ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกการเชื่อมต่อ"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ภายในช่วงเวลาที่เลือก QA โดยอัตโนมัติที่ใช้งานอยู่บางหมวดหมู่จะไม่ปรากฏในแบบฟอร์มลงคะแนนของคุณ การสนทนาก่อนการเปลี่ยนแปลงครั้งล่าสุดของคุณใน ", _interpolate(_named("date")), "อาจไม่ได้รับการวิเคราะห์สําหรับหมวดหมู่เหล่านี้"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่คือที่ที่ข้อเสนอแนะฟีดแบ็คทั้งหมดที่ให้กับคุณจะแสดงขึ้น ยังไม่มีอะไรให้แสดง"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวที่ได้รับ"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดงเฉพาะกิจกรรมข้อคิดเห็น"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นี่คือที่ที่ฟีดแบ็คทั้งหมดที่คุณได้รับจะแสดงขึ้น เริ่มต้นใช้งานในมุมมองการสนทนา"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรีวิวที่ได้ให้"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้ให้"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รีวิวที่ได้รับ"])}
  }
}