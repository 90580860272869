import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-lg" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_ticket_list_loader = _resolveComponent("ticket-list-loader")!
  const _component_arrow_down_icon = _resolveComponent("arrow-down-icon")!
  const _component_arrow_up_icon = _resolveComponent("arrow-up-icon")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_ui_pill = _resolveComponent("ui-pill")!
  const _component_recycle_scroller = _resolveComponent("recycle-scroller")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('quizzes.leaderboard.agent_quizzes_title', [_ctx.agent.name])), 1 /* TEXT */),
      _createElementVNode("button", {
        class: _normalizeClass(['p-2', _ctx.$style.close]),
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseList && _ctx.handleCloseList(...args)))
      }, [
        _createVNode(_component_x_icon, { class: "icon-m" })
      ], 2 /* CLASS */)
    ], 2 /* CLASS */),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ticket_list_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.list)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-xs", [_ctx.$style.headings, _ctx.$style.row]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headings, (heading, idx) => {
              return (_openBlock(), _createElementBlock("span", {
                key: heading.value,
                class: _normalizeClass(["font-medium", [_ctx.$style.heading, idx !== 0 && _ctx.$style.alignedRight, _ctx.sort === heading.value && _ctx.$style.activeHeading]]),
                onClick: ($event: any) => (_ctx.setSort(heading.value))
              }, [
                _createTextVNode(_toDisplayString(_ctx.$t(heading.label)) + " ", 1 /* TEXT */),
                (_ctx.sort === heading.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.sortDescending)
                        ? (_openBlock(), _createBlock(_component_arrow_down_icon, {
                            key: 0,
                            class: "icon-s ml-1",
                            "stroke-width": 3
                          }))
                        : (_openBlock(), _createBlock(_component_arrow_up_icon, {
                            key: 1,
                            class: "icon-s ml-1",
                            "stroke-width": 3
                          }))
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ], 10 /* CLASS, PROPS */, _hoisted_2))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */),
          _createVNode(_component_recycle_scroller, {
            class: _normalizeClass(_ctx.$style.list),
            items: _ctx.list,
            "item-size": 72
          }, {
            default: _withCtx(({ item: quiz }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasAccountRole('ADMIN', 'MANAGER') || _ctx.hasWorkspaceRole('MANAGER', 'LEAD') ? 'router-link' : 'div'), {
                class: _normalizeClass(_ctx.$style.row),
                to: _ctx.getQuizRoute(quiz)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-sm font-medium", _ctx.$style.name])
                    }, _toDisplayString(quiz.name), 3 /* TEXT, CLASS */),
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-xs", _ctx.$style.author])
                    }, [
                      _createVNode(_component_q_avatar, {
                        size: 16,
                        src: quiz.authorAvatar || '',
                        username: quiz.authorName
                      }, null, 8 /* PROPS */, ["src", "username"]),
                      _createTextVNode(" " + _toDisplayString(quiz.authorName), 1 /* TEXT */)
                    ], 2 /* CLASS */)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["text-xs", _ctx.$style.alignedRight])
                  }, _toDisplayString(quiz.completedDate ? _ctx.formatDateIntl(quiz.completedDate, { dateStyle: 'medium' }) : '-'), 3 /* TEXT, CLASS */),
                  _createElementVNode("div", {
                    class: _normalizeClass(["text-xs", _ctx.$style.alignedRight])
                  }, _toDisplayString(quiz.numberOfParticipants || '-'), 3 /* TEXT, CLASS */),
                  _createVNode(_component_ui_pill, {
                    size: _ctx.UiPillSize.Small,
                    value: quiz.averageScorePercentage && `${quiz.averageScorePercentage}%`,
                    class: "!justify-end"
                  }, null, 8 /* PROPS */, ["size", "value"]),
                  _createVNode(_component_ui_pill, {
                    size: _ctx.UiPillSize.Small,
                    value: quiz.userScorePercentage && `${quiz.userScorePercentage}%`,
                    class: "!justify-end"
                  }, null, 8 /* PROPS */, ["size", "value"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "to"]))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class", "items"])
        ], 2 /* CLASS */))
  ], 2 /* CLASS */))
}