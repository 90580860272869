import { computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { type LookerEmbedDashboard } from '@looker/embed-sdk'
import { debounce } from 'lodash'
import { getInitialAssignmentFilterData } from '@/modules/dashboard/api'
import { session } from '@/composables/useSession'

const FilterKeys = {
  AssignmentName: 'Assignment name',
  AssignmentCycle: 'Assignment cycle',
  Workspace: 'Workspace',
}

const debounceRun = debounce((dashboard, filters) => {
  dashboard.updateFilters(filters)
  dashboard.run()
}, 300)

export default createSharedComposable(({ enabled }) => {
  const { data: initialAssignmentsData, isPending } = useQuery({
    queryKey: ['latest-assignment', session.workspace.id],
    queryFn: () => getInitialAssignmentFilterData(),
    select: (data) => data.filter,
    enabled,
  })

  function handleAssignmentFilters(
    dashboardFilters: Record<string, string>,
    previousFilters: Record<string, string>,
    lookerDashboard: LookerEmbedDashboard,
  ) {
    const changedFilterKey = Object.keys(dashboardFilters).find((key) => dashboardFilters[key] !== previousFilters[key])

    if (changedFilterKey) {
      const keys = Object.keys(dashboardFilters)

      const keysToReset = keys
        .slice(keys.indexOf(changedFilterKey) + 1)
        .filter((key) => [FilterKeys.AssignmentName, FilterKeys.AssignmentCycle].includes(key))

      keysToReset.forEach((key) => {
        dashboardFilters[key] = ''
      })

      debounceRun(lookerDashboard, dashboardFilters)
    }
  }

  const initialAssignmentValues = computed(() => {
    return {
      [FilterKeys.AssignmentName]: `"${initialAssignmentsData?.value?.name}"`,
      ...(initialAssignmentsData?.value?.value
        ? { [FilterKeys.AssignmentCycle]: `"${initialAssignmentsData?.value?.value}"` }
        : {}),
    }
  })

  const isInitialAssignmentsDataLoading = computed(() => {
    return enabled.value && isPending.value
  })

  return {
    initialAssignmentValues,
    isInitialAssignmentsDataLoading,
    handleAssignmentFilters,
  }
})
