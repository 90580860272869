import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { AssignmentV2_Assignment_Status, AssignmentV2_Settings_CYCLE } from '@zendesk/zqa-services/assignments_v2'
import { createSharedComposable } from '@vueuse/core'
import { type AssignmentV2_Assignment } from '@/modules/workspace/views/assignments/types/assignments'
import i18n from '@/i18n'
import { toast } from '@/components/toast'
import { addAssignment, updateAssignment } from '@/modules/workspace/api/assignmentsV2'
import { toSubmittableAssignmentFormV2 as toSubmittableForm } from '@/modules/workspace/views/assignments/utils/form'
import { getAssignmentPayloadV2 } from './utils'
import useAssignmentForm from './useAssignmentFormV2'

export default createSharedComposable((props) => {
  const { form, dateConditions, validator, formSubmitted } = useAssignmentForm()
  const editing = computed(() => !!props.assignmentId)
  const skipLeaveCheck = ref(false)
  const router = useRouter()

  const handleInactive = async () => {
    if (!validator.value?.all) {
      formSubmitted.value = true

      toast({
        status: 'error',
        message: i18n.t('settings.workspaces.assignments_v_2.steps.summary.error'),
      })

      return
    }

    const submittableForm = toSubmittableForm(form.value)
    const assignmentPayload = getAssignmentPayloadV2(props.workspaceId, submittableForm as AssignmentV2_Assignment)

    const apiMethod = editing.value ? updateAssignment : addAssignment
    await apiMethod({
      ...assignmentPayload,
      data: {
        ...assignmentPayload.data,
        status: AssignmentV2_Assignment_Status.INACTIVE,
      },
    })

    toast({
      status: 'success',
      message: i18n.t('settings.workspaces.assignments_v_2.inactivated'),
    })

    skipLeaveCheck.value = true
    router.push(props.backTo || { name: 'workspace.assignments' })
  }

  const handleDraft = async () => {
    if (!dateConditions.value?.length) {
      toast({
        status: 'error',
        message: i18n.t('filter.date_condition_toast'),
      })

      return
    }

    const submittableForm = toSubmittableForm(form.value)
    const assignmentPayload = getAssignmentPayloadV2(props.workspaceId, submittableForm as AssignmentV2_Assignment)

    const apiMethod = editing.value ? updateAssignment : addAssignment
    await apiMethod({
      ...assignmentPayload,
      data: {
        ...assignmentPayload.data,
        status: AssignmentV2_Assignment_Status.DRAFT,
      },
    })

    toast({
      status: 'success',
      message: i18n.t('settings.workspaces.assignments_v_2.drafted'),
    })

    skipLeaveCheck.value = true
    router.push(props.backTo || { name: 'workspace.assignments' })
  }

  const statusOnSubmit = {
    [AssignmentV2_Assignment_Status.DRAFT]: AssignmentV2_Assignment_Status.ACTIVE,
    [AssignmentV2_Assignment_Status.ACTIVE]: AssignmentV2_Assignment_Status.PENDING,
    [AssignmentV2_Assignment_Status.INACTIVE]: AssignmentV2_Assignment_Status.INACTIVE,
    [AssignmentV2_Assignment_Status.PENDING]: AssignmentV2_Assignment_Status.PENDING,
    [AssignmentV2_Assignment_Status.RESET]: AssignmentV2_Assignment_Status.RESET,
    [AssignmentV2_Assignment_Status.UNDEFINED]: AssignmentV2_Assignment_Status.ACTIVE,
  }

  const handleSubmit = async () => {
    if (!validator.value?.all) {
      formSubmitted.value = true

      toast({
        status: 'error',
        message: i18n.t('settings.workspaces.assignments_v_2.steps.summary.error'),
      })

      return
    }

    const submittableForm = toSubmittableForm(form.value)
    const updating = !!submittableForm.id
    const assignmentPayload = getAssignmentPayloadV2(props.workspaceId, submittableForm as AssignmentV2_Assignment)

    let status = AssignmentV2_Assignment_Status.ACTIVE
    if (
      assignmentPayload.data.status === AssignmentV2_Assignment_Status.DRAFT &&
      form.value.settings.cycleLength === AssignmentV2_Settings_CYCLE.NOT_RECURRING
    ) {
      status = AssignmentV2_Assignment_Status.RESET
    } else if (assignmentPayload.data.status) {
      status = statusOnSubmit[assignmentPayload.data.status]
    }

    const apiMethod = editing.value ? updateAssignment : addAssignment
    await apiMethod({
      ...assignmentPayload,
      data: {
        ...assignmentPayload.data,
        status,
      },
    })

    toast({
      status: 'success',
      message: updating
        ? i18n.t('settings.workspaces.assignments_v_2.updated')
        : i18n.t('settings.workspaces.assignments_v_2.created'),
    })

    skipLeaveCheck.value = true
    router.push(props.backTo || { name: 'workspace.assignments' })
  }

  const handleCancel = () => {
    router.push(props.backTo || { name: 'workspace.assignments' })
  }

  return {
    editing,
    skipLeaveCheck,
    handleInactive,
    handleCancel,
    handleDraft,
    handleSubmit,
  }
})
