interface Mistake {
  startIdx: number
  endIdx: number
}

interface Processed {
  s: string
  isMistake: boolean
  mistakeId?: number
}

export default (rawInput: string, mistakes: Mistake[]): Processed[] => {
  if (!rawInput.length) return []
  if (!mistakes.length) return [{ s: rawInput, isMistake: false }]

  // Use array to count one emoji as single char
  const input = [...rawInput]

  const ret = []

  if (mistakes[0].startIdx !== 0) {
    ret.push({ s: input.slice(0, mistakes[0].startIdx).join(''), isMistake: false })
  }

  mistakes.forEach((m, i) => {
    ret.push({ s: input.slice(m.startIdx, m.endIdx).join(''), isMistake: true, mistakeId: i })
    if (m.endIdx !== input.length) {
      const endIdx = i === mistakes.length - 1 ? input.length : mistakes[i + 1].startIdx
      if (m.endIdx === endIdx) return
      ret.push({ s: input.slice(m.endIdx, endIdx).join(''), isMistake: false })
    }
  })

  return ret
}
