import {
  type AssignmentV2_Settings,
  AssignmentV2_Settings_CYCLE,
  AssignmentV2_Settings_CycleConfig_DAY,
  AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK,
} from '@zendesk/zqa-services/assignments_v2'
import type {
  TicketSearchFilterRequest,
  TicketSearchFilterRequest_AutoQAFilterOptionRequest,
} from '@zendesk/zqa-services/tickets'
import dayjs from 'dayjs'
import i18n from '@/i18n'
import { dateConditions } from '@/modules/conversations/utils/filters'
import { formatDateIntl } from '@/utils/date'

export const customUSTimeZonesGroup = [
  {
    id: 'US/Alaska',
    tag: 'Alaska',
  },
  {
    id: 'US/Aleutian',
    tag: 'Aleutian',
  },
  {
    id: 'US/Arizona',
    tag: 'Arizona',
  },
  {
    id: 'US/Central',
    tag: 'Central',
  },
  {
    id: 'US/East-Indiana',
    tag: 'East Indiana',
  },
  {
    id: 'US/Eastern',
    tag: 'Eastern',
  },
  {
    id: 'US/Hawaii',
    tag: 'Hawaii',
  },
  {
    id: 'US/Indiana-Starke',
    tag: 'Indiana Starke',
  },
  {
    id: 'US/Michigan',
    tag: 'Michigan',
  },
  {
    id: 'US/Mountain',
    tag: 'Mountain',
  },
  {
    id: 'US/Pacific',
    tag: 'Pacific',
  },
  {
    id: 'US/Samoa',
    tag: 'Samoa',
  },
]

export const cycleLengthTranslationMap = {
  [AssignmentV2_Settings_CYCLE.NOT_RECURRING]: i18n.t('settings.workspaces.assignments_v_2.form.cycle_label_one_off'),
  [AssignmentV2_Settings_CYCLE.DAILY]: i18n.t('settings.workspaces.assignments_v_2.form.cycle_label_daily'),
  [AssignmentV2_Settings_CYCLE.WEEKLY]: i18n.t('settings.workspaces.assignments_v_2.form.cycle_label_weekly'),
  [AssignmentV2_Settings_CYCLE.BI_WEEKLY]: i18n.t('settings.workspaces.assignments_v_2.form.cycle_label_bi_weekly'),
  [AssignmentV2_Settings_CYCLE.MONTHLY]: i18n.t('settings.workspaces.assignments_v_2.form.cycle_label_monthly'),
}

export const cycleLengthOptions = Object.keys(cycleLengthTranslationMap).map((key) => ({
  id: key as AssignmentV2_Settings_CYCLE,
  tag: cycleLengthTranslationMap[key as keyof typeof cycleLengthTranslationMap],
}))

export const weekDayTranslationMap = {
  [AssignmentV2_Settings_CycleConfig_DAY.MONDAY]: i18n.t('universal.weekdays.monday'),
  [AssignmentV2_Settings_CycleConfig_DAY.TUESDAY]: i18n.t('universal.weekdays.tuesday'),
  [AssignmentV2_Settings_CycleConfig_DAY.WEDNESDAY]: i18n.t('universal.weekdays.wednesday'),
  [AssignmentV2_Settings_CycleConfig_DAY.THURSDAY]: i18n.t('universal.weekdays.thursday'),
  [AssignmentV2_Settings_CycleConfig_DAY.FRIDAY]: i18n.t('universal.weekdays.friday'),
  [AssignmentV2_Settings_CycleConfig_DAY.SATURDAY]: i18n.t('universal.weekdays.saturday'),
  [AssignmentV2_Settings_CycleConfig_DAY.SUNDAY]: i18n.t('universal.weekdays.sunday'),
}

export const weekDayOptions = Object.keys(weekDayTranslationMap).map((key) => ({
  id: key as AssignmentV2_Settings_CycleConfig_DAY,
  tag: weekDayTranslationMap[key as keyof typeof weekDayTranslationMap],
}))

export const nthOptions = [
  {
    id: AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK.FIRST,
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.1'),
  },
  {
    id: AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK.SECOND,
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.2'),
  },
  {
    id: AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK.THIRD,
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.3'),
  },
  {
    id: AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK.FOURTH,
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.4'),
  },
  {
    id: AssignmentV2_Settings_CycleConfig_WeekOfMonth_WEEK.LAST,
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.indicator.5'),
  },
]

export const customRecurrenceOptions = [
  {
    id: 'day',
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.day'),
  },
  {
    id: 'weekday',
    tag: i18n.t('settings.workspaces.assignments_v_2.steps.general.cycle_settings.repeats.weekday'),
  },
]

export const getSummaryCycle = (settings: AssignmentV2_Settings) => {
  if (!settings?.cycleLength || !settings?.startDate || !settings?.timeZone) return

  const startDate = new Date(settings.startDate)

  if (settings.cycleLength === AssignmentV2_Settings_CYCLE.NOT_RECURRING) {
    const endDate = settings?.endDate ? new Date(settings.endDate) : null

    return i18n.t('settings.workspaces.assignments_v_2.steps.summary.cycle_never', {
      startDateTime: formatDateIntl(startDate, {
        dateStyle: 'full',
        timeStyle: 'short',
        timeZone: settings.timeZone,
      }),
      endDateTime: formatDateIntl(endDate, {
        dateStyle: 'full',
        timeStyle: 'short',
        timeZone: settings.timeZone,
      }),
    })
  }

  if (settings.cycleLength === AssignmentV2_Settings_CYCLE.DAILY) {
    return i18n.t('settings.workspaces.assignments_v_2.steps.summary.cycle_daily', {
      startDateTime: formatDateIntl(startDate, {
        dateStyle: 'full',
        timeStyle: 'short',
        timeZone: settings.timeZone,
      }),
    })
  }

  const weekday = Intl.DateTimeFormat('en-US', { weekday: 'long', timeZone: settings.timeZone }).format(startDate)

  return i18n.t(`settings.workspaces.assignments_v_2.steps.summary.cycle_${weekday.toLowerCase()}`, {
    cycleLength: cycleLengthOptions.find(({ id }) => id === settings.cycleLength).tag,
    startDateTime: formatDateIntl(startDate, { dateStyle: 'long', timeStyle: 'short', timeZone: settings.timeZone }),
  })
}

export const getAllFilterOptions = (filter?: TicketSearchFilterRequest) => {
  if (!filter) return []
  return [...filter.options, ...filter.autoQaOptions]
}

export const getDateConditions = (filter: TicketSearchFilterRequest) => {
  const allFilterOptions = getAllFilterOptions(filter)

  return allFilterOptions.filter((option) => dateConditions.includes(option.option))
}

export const getOtherConditions = (filter: TicketSearchFilterRequest) => {
  const allFilterOptions = getAllFilterOptions(filter)

  return allFilterOptions.filter((option) => !dateConditions.includes(option.option))
}

export const getDateConditionsDescription = (dateConditions: TicketSearchFilterRequest_AutoQAFilterOptionRequest[]) => {
  if (!dateConditions.length) return i18n.t('settings.workspaces.assignments_v_2.steps.summary.no_date_conditions')
  if (dateConditions.length > 1)
    return i18n.t('settings.workspaces.assignments_v_2.steps.summary.date_conditions', {
      count: i18n.n(dateConditions.length),
    })

  const opt = dateConditions[0]
  const conditionKey = `filter.condition.label.${opt.condition?.toLowerCase()}`
  const optionKey = `filter.option.label.${opt.option?.toLowerCase()}`
  const condition = opt.values?.length
    ? `${i18n.t(conditionKey)} ${dayjs(opt.values[0].label).format('ll')}`
    : i18n.t(conditionKey)
  const option = i18n.t(optionKey)

  return `<p>${option}: ${condition}</p>`
}

const isHelpdeskFilter = (option: TicketSearchFilterRequest_AutoQAFilterOptionRequest) =>
  option.option === 'TICKET_FIELD'

export const getOtherConditionsDescription = (
  otherConditions: TicketSearchFilterRequest_AutoQAFilterOptionRequest[],
) => {
  const conversationConditions = otherConditions.filter((option) => !isHelpdeskFilter(option))
  const helpdeskConditions = otherConditions.filter(isHelpdeskFilter)

  if (conversationConditions.length && !helpdeskConditions.length) {
    return i18n.t(
      'settings.workspaces.assignments_v_2.steps.summary.other_conditions_no_helpdesk_condition',
      {
        count: i18n.n(conversationConditions.length),
      },
      conversationConditions.length,
    )
  }

  if (!conversationConditions.length && helpdeskConditions.length) {
    return i18n.t(
      'settings.workspaces.assignments_v_2.steps.summary.other_conditions_no_conversation',
      {
        count: i18n.n(helpdeskConditions.length),
      },
      helpdeskConditions.length,
    )
  }

  return i18n.t(
    'settings.workspaces.assignments_v_2.steps.summary.other_conditions',
    {
      count: i18n.n(otherConditions.length),
    },
    otherConditions.length,
  )
}
