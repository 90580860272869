import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "internal-comments"
}
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = { class: "given-ratings__header--details" }
const _hoisted_4 = { class: "text-gray-60 flex w-full justify-between" }
const _hoisted_5 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ticket_list_loader = _resolveComponent("ticket-list-loader")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_ui_pill = _resolveComponent("ui-pill")!
  const _component_external_link_icon = _resolveComponent("external-link-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_comment_preview = _resolveComponent("comment-preview")!
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")!
  const _component_empty_state = _resolveComponent("empty-state")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ticket_list_loader, { key: 0 }))
      : (_ctx.data && _ctx.data.feedback && _ctx.data.feedback.length)
        ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.feedback, (comment) => {
              return (_openBlock(), _createElementBlock("li", {
                key: comment.commentId,
                "data-testid": `feedback-item-${comment.commentId}`,
                class: _normalizeClass(_ctx.$style.commentParent)
              }, [
                _createElementVNode("header", {
                  class: _normalizeClass(["text-xs font-medium", _ctx.$style.header])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.avatars)
                  }, [
                    _createVNode(_component_q_avatar, {
                      size: 32,
                      src: comment.reviewer && comment.reviewer.avatar,
                      username: (comment.reviewer && comment.reviewer.name) || _ctx.$t('universal.former_member'),
                      alt: _ctx.$t('conversations.sidebar.comment_list.comment_author'),
                      class: _normalizeClass(_ctx.$style.authorAvatar)
                    }, null, 8 /* PROPS */, ["src", "username", "alt", "class"]),
                    _createVNode(_component_q_avatar, {
                      size: 26,
                      src: comment.reviewee && comment.reviewee.avatar,
                      username: (comment.reviewee && comment.reviewee.name) || _ctx.$t('universal.former_member'),
                      class: _normalizeClass(_ctx.$style.revieweeAvatar)
                    }, null, 8 /* PROPS */, ["src", "username", "class"])
                  ], 2 /* CLASS */),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString((comment.reviewer && comment.reviewer.name) || _ctx.$t('universal.former_member')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('conversations.sidebar.review_recipient', [
                  (comment.reviewee && comment.reviewee.name) || _ctx.$t('universal.former_member'),
                ])), 1 /* TEXT */)
                  ]),
                  (comment.score)
                    ? (_openBlock(), _createBlock(_component_ui_pill, {
                        key: 0,
                        value: `${comment.score}%`,
                        threshold: _ctx.session.workspace.settings.threshold,
                        size: _ctx.UiPillSize.Small
                      }, null, 8 /* PROPS */, ["value", "threshold", "size"]))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_router_link, {
                    to: {
              name: comment.messageId ? 'conversations.message' : 'conversations',
              params: {
                connectionId: comment.connectionId,
                conversationId: comment.conversationExternalId,
                messageId: comment.messageExternalId,
              },
              query: {
                workspace: comment.workspaceId,
              },
            },
                    "data-testid": "external-link",
                    class: _normalizeClass(_ctx.$style.commentLink),
                    target: "_blank",
                    rel: "noopener noreferrer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.analytics.coachingOpenFeedbackConversation()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_external_link_icon)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])
                ], 2 /* CLASS */),
                _createVNode(_component_comment_preview, {
                  comment: comment.comment,
                  bubbled: ""
                }, null, 8 /* PROPS */, ["comment"]),
                (comment.created)
                  ? (_openBlock(), _createElementBlock("time", {
                      key: 0,
                      datetime: comment.created,
                      class: _normalizeClass(["text-xs first-letter:uppercase", _ctx.$style.time])
                    }, _toDisplayString(_ctx.getTimePast(comment.created)), 11 /* TEXT, CLASS, PROPS */, _hoisted_5))
                  : _createCommentVNode("v-if", true)
              ], 10 /* CLASS, PROPS */, _hoisted_2))
            }), 128 /* KEYED_FRAGMENT */)),
            _createElementVNode("div", {
              class: _normalizeClass(["text-xs", _ctx.$style.endOfComments])
            }, _toDisplayString(_ctx.$t('coaching.sessions.sidebar.feedback.end_of_comments')), 3 /* TEXT, CLASS */)
          ]))
        : (_openBlock(), _createBlock(_component_empty_state, {
            key: 2,
            title: _ctx.$t('coaching.sessions.sidebar.feedback.empty_state.title'),
            message: 
        _ctx.$t('coaching.sessions.sidebar.feedback.empty_state.message', [
          _ctx.userName || _ctx.$t('coaching.sessions.sidebar.pins.empty_state.coachee_placeholder'),
        ])
      
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_check_circle_icon, {
                class: _normalizeClass(_ctx.$style.emptyStateIcon)
              }, null, 8 /* PROPS */, ["class"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title", "message"]))
  ], 2 /* CLASS */))
}