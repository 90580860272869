import { computed, reactive, ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { isEqual } from 'lodash-es'
import { EMAIL_REGEX } from '@/utils/email'
import { isUrlValid } from '@/utils/url'
import useQuestionTypes from '@/composables/useQuestionTypes'
import { CUSTOM_QUESTION, connectionRequiresAction, getQuestions } from '../../utils'
import type { SurveyConfig } from '../../types'
import { defaultLazyErrors, defaultSurveyState } from './defaultState'
import type { LazySurveyConfigErrors, SurveyConfigErrors, SurveyState } from './survey.types'

const COLOR_HEX_REGEXP = /^#(?:[0-9a-fA-F]{3}){1,2}$/

export default createSharedComposable(() => {
  const { getQuestionTypeById } = useQuestionTypes()
  const state = reactive<SurveyState>(defaultSurveyState)
  const savedSurveyConfig = ref<SurveyConfig>(defaultSurveyState.data)
  const surveyConfigChanges = computed(() => getSurveyConfigChanges(state, savedSurveyConfig.value))
  const canSave = computed(() => stringifyData(savedSurveyConfig.value) !== stringifyData(state.data))
  const lazyErrors = ref<LazySurveyConfigErrors>(defaultLazyErrors)
  const errors = computed(() => getSurveyErrors(state, connectionsWithErrors.value, lazyErrors.value))
  const connectionsWithErrors = ref<string[]>([])
  const sectionErrors = computed(() => getSectionErrors(errors.value))
  const sectionWarnings = computed(() => getSectionWarnings(state, savedSurveyConfig.value))

  watch(
    () => state.data.questionTypeId,
    (type) => {
      const questionType = getQuestionTypeById(type)

      if (!questionType) return

      if (questionType.isCustom) {
        state.data.question = CUSTOM_QUESTION
      } else {
        if (state.data.question === CUSTOM_QUESTION) return

        const questions = getQuestions(questionType.name)[0].options
        const questionExists = !!questions.find(({ value }) => value === state.data.question)
        if (!questionExists) {
          state.data.question = questions[0].value || ''
        }
      }
    },
  )

  watch(
    () => state.data.customQuestion,
    () => clearLazyError('noCustomQuestion'),
  )

  function setConnectionError(connectionId: string) {
    if (!connectionHasErrors(connectionId)) {
      connectionsWithErrors.value.push(connectionId)
    }
  }

  function removeConnectionError(connectionId: string) {
    if (connectionHasErrors(connectionId)) {
      const idx = connectionsWithErrors.value.findIndex((id) => id === connectionId)
      connectionsWithErrors.value.splice(idx, 1)
    }
  }

  function getSelectedQuestion() {
    return state.data.question === CUSTOM_QUESTION ? state.data.customQuestion : state.data.question
  }

  function connectionHasErrors(connectionId: string) {
    return connectionsWithErrors.value.includes(connectionId)
  }

  function clearLazyError(errorName: keyof LazySurveyConfigErrors) {
    lazyErrors.value[errorName] = false
  }

  function hasErrors() {
    checkForErrors()
    return Object.values(errors.value).some((err) => (Array.isArray(err) ? !!err.length : err))
  }

  function checkForErrors() {
    lazyErrors.value = {
      noCustomQuestion: state.data.question === CUSTOM_QUESTION && !state.data.customQuestion,
      customTypeNotSavedError: state.data.questionTypeId === '-1',
    }
  }

  return {
    state,
    savedSurveyConfig,
    surveyConfigChanges,
    canSave,
    errors,
    connectionsWithErrors,
    sectionErrors,
    sectionWarnings,
    setConnectionError,
    removeConnectionError,
    connectionHasErrors,
    getSelectedQuestion,
    hasErrors,
    clearLazyError,
  }
})

const stringifyData = (data: any) => {
  const { status, ...dataToStringify } = data
  return JSON.stringify(dataToStringify)
}

function getSurveyConfigChanges(surveyState: SurveyState, savedConfig: SurveyConfig) {
  const changes: Partial<SurveyConfig> = {}

  for (const key in savedConfig) {
    const saved = savedConfig[key]
    const changed = surveyState.data[key]

    if (!isEqual(saved, changed)) {
      changes[key] = changed
    }
  }

  return changes
}

function getSurveyErrors(
  surveyState: SurveyState,
  connectionsWithErrors: string[],
  lazyErrors: LazySurveyConfigErrors,
) {
  return {
    noMailFromName: !surveyState.data.mailFromName,
    noMailFromAddress: surveyState.data.mailFromAddress.indexOf('@') === 0,
    noMailSubject: !surveyState.data.mailSubject,
    noBrandName: !surveyState.data.brand,
    noQuestion: !surveyState.data.question,
    incorrectBgColor: !COLOR_HEX_REGEXP.test(surveyState.data.bgColor),
    incorrectBtnColor: !COLOR_HEX_REGEXP.test(surveyState.data.btnColor),
    incorrectMailFromAddress: !EMAIL_REGEX.test(surveyState.data.mailFromAddress),
    connectionsWithErrors,
    duplicateReason:
      surveyState.data.showReasons &&
      new Set(surveyState.data.reasonList.map((r) => r.toLowerCase())).size !== surveyState.data.reasonList.length,
    emptyReason: surveyState.data.showReasons && surveyState.data.reasonList.some((r) => !r),
    incorrectPrivacyLink: !isUrlValid(surveyState.data.privacyLink),
    incorrectTermsLink: !isUrlValid(surveyState.data.termsLink),
    ...lazyErrors,
  }
}

function getSectionErrors(errors: SurveyConfigErrors) {
  const {
    noMailFromName,
    noMailFromAddress,
    noMailSubject,
    noBrandName,
    noQuestion,
    noCustomQuestion,
    incorrectBgColor,
    incorrectBtnColor,
    incorrectMailFromAddress,
    connectionsWithErrors,
    duplicateReason,
    emptyReason,
    incorrectPrivacyLink,
    incorrectTermsLink,
    customTypeNotSavedError,
  } = errors

  const contentEmail =
    noMailFromName ||
    noMailFromAddress ||
    noMailSubject ||
    noQuestion ||
    noCustomQuestion ||
    incorrectMailFromAddress ||
    incorrectPrivacyLink ||
    incorrectTermsLink ||
    customTypeNotSavedError
  const styling = noBrandName || incorrectBgColor || incorrectBtnColor
  const delivery = !!connectionsWithErrors.length

  return {
    styling,
    content: contentEmail || duplicateReason || emptyReason,
    contentEmail,
    contentRatingScores: duplicateReason || emptyReason,
    delivery,
    thankYouMessage: false,
    language: false,
  }
}

function getSectionWarnings(surveyState: SurveyState, savedConfig: SurveyConfig) {
  const { mailSubject, introMessage, thankYouMessage, question, reasonList } = savedConfig
  const hasTranslations = !!surveyState.data.translations.filter((t) => t.language !== 'en').length
  const translationsDiff =
    surveyState.data.mailSubject !== mailSubject ||
    surveyState.data.introMessage !== introMessage ||
    surveyState.data.thankYouMessage !== thankYouMessage ||
    surveyState.data.question !== question ||
    surveyState.data.reasonList.toString() !== reasonList.toString()

  const deliveryWarning = surveyState.data.deliverySettings.some(connectionRequiresAction)

  return {
    delivery: deliveryWarning,
    language: hasTranslations && translationsDiff,
  }
}
