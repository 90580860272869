import type { Consents, PraiseBotSettings } from '@zendesk/zqa-services/account'
import type { ChargebeeHostedPagePayload, AccountSubscription } from '@zendesk/zqa-services/subscriptions'
import type { UserAuthentications } from '@zendesk/zqa-services/users'

import { session } from '@/composables/useSession'
import { api, type APIError, oldApi } from '@/api'
import { DisplayType } from '@/data/scales'
import type {
  AccountToken,
  NotificationSettings,
  Notifications,
  UserProfile,
  CurrentAccountPayload,
  NotificationPayload,
  WebhookPayload,
  UpdateAccountPayload,
  UpdateRatingScalePayload,
  AllowedAuthentications,
  SettingsUpdatePayload,
  SlackSettings,
  SlackChannel,
} from './types'

// PROFILE/GENERAL

export const getUserProfile = async () => {
  const { data } = await oldApi.get(`payments/${session.account.id}/users/profiles`).json<{ data: UserProfile }>()
  return data
}

export const updateUserProfile = (json: Partial<UserProfile>) => oldApi.put(`users`, { json }).json()

export const getUserEmails = async () => {
  const { data } = await oldApi.get('users/emails').json<{ data: string[] }>()
  return data
}

// PROFILE/GOALS

export const getUserGoals = () => api.get(`settings/goals`).json<{ goal: number; goalType: string }>()

export const setUserGoals = (payload: { goal: number; goalType: string }) =>
  api.put(`settings/goals`, { json: payload }).json<{ goal: number; goalType: string }>()

// PROFILE/LOGIN

export const removeAuthMethod = (id: number) => oldApi.delete(`users/user-authorizations/${id}`)

export const addSocialAuth = (payload) => oldApi.put('users/merge/id-token', { json: payload }).json()

// PROFILE/NOTIFICATIONS

export const getNotifications = async () => {
  const res = await oldApi.get(`payments/${session.account.id}/users/notifications`).json<{ data: Notifications }>()
  return res.data
}

export const updateNotifications = (json: NotificationPayload) =>
  oldApi.put(`payments/${session.account.id}/users/notifications`, { json }).json<{ data: Notifications }>()

// ACCOUNT/GENERAL

export const getCurrentAccount = () =>
  oldApi
    .get(`payments/${session.account.id}`)
    .json<CurrentAccountPayload>()
    .then(({ data }) => ({
      name: data.name,
      userCount: data.userCount,
      subscriptionPlanId: data.subscriptionPlanId,
      subscriptionStatus: data.subscriptionStatus,
      firstDayOfWeek: data.firstDayOfWeek,
      ratingCategory: {
        skippable: data.categorySkippable,
        scale: data.categoryScale,
        displayType: data.categoryDisplayType,
      },
      syncTargetWorkspaceId: data.syncTargetWorkspaceId,
      timeFormat: data.timeFormat,
    }))

export const updateCurrentAccount = ({
  name,
  firstDayOfWeek,
  syncTargetWorkspaceId,
  timeFormat,
}: UpdateAccountPayload) =>
  oldApi.put(`payments/${session.account.id}`, {
    json: { paymentPlanName: name, firstDayOfWeek, syncTargetWorkspaceId, timeFormat },
  })

// ACCOUNT/SCORECARD

export const getScorecardSettings = () =>
  oldApi
    .get(`payments/${session.account.id}`)
    .json<CurrentAccountPayload>()
    .then(({ data }) => ({
      scale: data.categoryScale,
      displayAsEmoji: data.categoryDisplayType === DisplayType.Emotes,
      displayNA: data.categorySkippable,
    }))

export const updateRatingScale = ({ scale, ...data }: UpdateRatingScalePayload) =>
  oldApi.put(`payments/${session.account.id}/rating-scales`, { json: { ...data, ratingCategoryScale: scale } }).json()

// ACCOUNT/SUBSCRIPTION

export const getAccountSubscription = () => api.get('subscription').json<AccountSubscription>()

export const createChargebeePortalSession = () => api.post('subscription/chargebee/portal-session').json()

export const createChargebeeHostedPage = (json: ChargebeeHostedPagePayload) =>
  api.post('subscription/chargebee/hosted-page', { json }).json()

export const acknowledgeChargebeeHostedPage = (id: string) =>
  api.post(`subscription/chargebee/hosted-page/${id}/acknowledge`).json()

// ACCOUNT/SLACK

export const getSlackSettings = () => oldApi.get(`payments/${session.account.id}/slack`).json<{ data: SlackSettings }>()

export const getSlackChannels = () =>
  oldApi.get(`payments/${session.account.id}/slack/channels`).json<{ data: SlackChannel[] }>()

export const addSlack = (code: string, redirectUri: string) =>
  oldApi.post(`payments/${session.account.id}/slack`, { json: { code, redirectUri } }).json()

export const removeSlack = () => oldApi.delete(`payments/${session.account.id}/slack`).json()

// ACCOUNT/AUTHENTICATION

export const getAuthenticationMethods = () => api.get(`settings/authentications`).json<UserAuthentications>()

export const updateAuthenticationMethods = (payload: UserAuthentications) =>
  api.put(`settings/authentications`, { json: payload }).json<UserAuthentications>()

export const getAllowedAuthenticationsLegacy = () =>
  oldApi
    .get(`payments/${session.account.id}/authentications`)
    .json<{ data: AllowedAuthentications }>()
    .then((r) => r.data)

export const toggleAllowedAuthentication = (method: string) =>
  oldApi.put(`payments/${session.account.id}/authentications/${method}`)

// ACCOUNT/NOTIFICATIONS

export const getNotificationSettings = () =>
  oldApi
    .get(`payments/${session.account.id}/notifications/ALL/map`)
    .json<{ data: NotificationSettings }>()
    .then((r) => r.data)

export const toggleNotificationsLock = () => oldApi.put(`payments/${session.account.id}/notifications/ALL/allow-change`)

export const updateNotificationSettings = (json: SettingsUpdatePayload) =>
  oldApi.put(`payments/${session.account.id}/notifications/ALL`, { json })

// ACCOUNT/WEBHOOKS

export const getWebhooks = () => oldApi.get(`payments/${session.account.id}/webhooks`).json<{ data: WebhookPayload }>()

export const updateWebhooks = (json: WebhookPayload) =>
  oldApi.put(`payments/${session.account.id}/webhooks`, { json }).json<{ data: WebhookPayload }>()

export const deleteWebhooks = () => oldApi.delete(`payments/${session.account.id}/webhooks`)

// ACCOUNT/CONNECTIONS

export const getConnections = () =>
  oldApi
    .get(`payments/${session.account.id}/tokens`)
    .json<{ data: AccountToken[] }>()
    .then((r) => r.data)

export const updateConnection = (payload) =>
  oldApi
    .put(`payments/${session.account.id}/tokens/${payload.connectionId}`, { json: payload.data })
    .json<{ data: any[] }>()
    .then((r) => r.data)
    .catch((error) => {
      return JSON.parse((error as APIError).body)
    })

export const addConnection = (data) => oldApi.post(`payments/${session.account.id}/tokens`, { json: data }).json()

export const authenticateConnection = (payload) =>
  oldApi.post(`payments/${session.account.id}/tokens/${payload.connectionId}/re-auth`, { json: payload.data }).json()

export const syncConnection = (id: number) =>
  oldApi.post(`payments/${session.account.id}/tokens/${id}/sync`).json<{ data: number }>()

export const deleteConnection = (id: number) => oldApi.delete(`payments/${session.account.id}/tokens/${id}`).json()

// ACCOUNT CONSENT

export const getConsent = () => api.get('settings/consents').json<Consents>()

export const setConsent = (payload: Consents) => api.put(`settings/consents`, { json: payload }).json<Consents>()

// ACCOUNT/PRAISEBOT

export const getPraisebot = () => api.get('settings/praisebot').json<PraiseBotSettings>()

export const setPraisebot = (payload: PraiseBotSettings) =>
  api.put(`settings/praisebot`, { json: payload }).json<PraiseBotSettings>()
