import { type MaybeRefOrGetter, toRef, computed, onUnmounted, watch } from 'vue'
import { capitalize } from 'lodash-es'
import { useQuery } from '@tanstack/vue-query'
import type { TicketReply } from '@/modules/conversations/types'
import { toast } from '@/components/toast'
import i18n from '@/i18n'
import { getCommentRecording, getTicketRecordings } from '../api'
import { useTicketContent } from './useTicketContent'

type Props = MaybeRefOrGetter<{
  connectionId: number
  comment: TicketReply
  source: string
}>

const SOURCES_WITH_EXPIRE_TIME = ['AIRCALL', 'GENESYS', 'CORDLESS_MANUAL_IMPORT']
const DEFAULT_PROXY_SOURCES = ['DIXA', 'TALKDESK']
const PROXY_TICKET_TYPES = ['aws_voice_call', 'TpeVoiceComment_AmazonConnect', 'TpeVoiceComment_Five9']

export default function useCallRecordingUrl(_props: Props) {
  const props = toRef(_props)
  const { state: ticketState } = useTicketContent()

  const ticketExternalId = computed(() => ticketState.ticket.externalId)
  const paymentTokenId = computed(() => ticketState.ticket.paymentTokenId)
  const connectionId = computed(() => props.value.connectionId)
  const commentExternalId = computed(() => props.value.comment.externalId)

  const sourceExpires = computed(() => SOURCES_WITH_EXPIRE_TIME.includes(props.value.source))
  const isProxyNeeded = computed(() => {
    if (DEFAULT_PROXY_SOURCES.includes(props.value?.source)) return true
    if (PROXY_TICKET_TYPES.includes(props.value?.comment?.type)) return true
    // Disable proxy on same origin urls
    if (props.value.comment?.recordingUrl?.startsWith(location.origin)) return false
    if (
      props.value?.source === 'ZENDESK' &&
      (props.value.comment.recordingUrl?.includes('zendesk.com') ||
        props.value.comment.recordingUrl?.includes('zdusercontent.com'))
    )
      return true

    return false
  })

  const {
    data: freshTicketRecordingUrl,
    isFetching: freshUrlFetching,
    isError: freshUrlError,
  } = useQuery({
    queryKey: ['ticket-recordings', ticketExternalId, paymentTokenId],
    queryFn: ({ signal }) => getTicketRecordings(ticketExternalId.value, String(paymentTokenId.value), signal),
    select: ({ data }) => data[commentExternalId.value],
    enabled: computed(
      () => !!(sourceExpires.value && ticketExternalId.value && paymentTokenId.value && commentExternalId.value),
    ),
  })

  const {
    data: proxiedRecording,
    isFetching: proxyFetching,
    isError: proxyError,
  } = useQuery({
    queryKey: ['recording-proxy', connectionId, commentExternalId, ticketExternalId],
    queryFn: ({ signal }) =>
      getCommentRecording(
        {
          ticketId: ticketExternalId.value,
          commentId: commentExternalId.value,
          connectionId: connectionId.value,
        },
        signal,
      ),
    select: (blob) => ({ url: window.URL.createObjectURL(blob), blob }),
    enabled: computed(
      () => !!(isProxyNeeded.value && connectionId.value && commentExternalId.value && ticketExternalId.value != null),
    ),
  })

  const recordingUrl = computed(() => {
    if (isProxyNeeded.value) return proxiedRecording.value?.url
    if (sourceExpires.value) return freshTicketRecordingUrl.value
    return props.value.comment.recordingUrl
  })

  onUnmounted(() => {
    if (isProxyNeeded.value) window.URL.revokeObjectURL(proxiedRecording.value?.url)
  })

  watch(proxyError, (err) => {
    if (!err) return

    toast({
      status: 'error',
      message: i18n.t('conversations.ticket_reply.audio_error', [capitalize(props.value?.source?.replace('_', ' '))]),
    })
  })

  return {
    recordingUrl,
    recordingBlob: computed(() => proxiedRecording.value?.blob),
    isFetching: computed(() => freshUrlFetching.value || proxyFetching.value),
    isError: computed(() => freshUrlError.value || proxyError.value),
  }
}
