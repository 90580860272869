import alertDialog from '@/components/alertDialog'
import i18n from '@/i18n'
import analytics from '@/utils/analytics'
import { deleteFilter } from '../../api/filters'
import { activeFilterId, getFilters } from '../../composables/useConversationFilters'

export default async (id: number, name: string, isPublic: boolean) => {
  const { isConfirmed } = await alertDialog(i18n.t('conversations.filters.delete_modal.body'), {
    title: i18n.t('conversations.filters.delete_modal.title', { filterName: name }),
    confirmButtonText: i18n.t('conversations.filters.delete_modal.confirm_button'),
    redConfirmButton: true,
  })
  if (!isConfirmed) return

  await deleteFilter(id)
  analytics.filterDeleted(id, isPublic)
  getFilters(id === activeFilterId.value)
}
