import { computed, watch, type ComputedRef, ref, onMounted } from 'vue'
import { useEventListener, useScroll } from '@vueuse/core'
import type { TranscriptionMessage } from '../api/transcripts'
import { scrollInteractionIntoView } from '../utils/scroll-to-message.util'
import { getTicketContentElement, getTicketResponsesElement } from '../utils/ticket-dom.util'

type Props = ComputedRef<{
  currentTime: number
  isPlaying: boolean
  transcriptionId: string
  messages: TranscriptionMessage[]
}>

const KEYBOARD_EVENT_KEYS = ['Tab', 'ArrowDown', 'ArrowUp']

export default function useTranscriptionSyncScroll(props: Props) {
  const currentTime = computed(() => props.value.currentTime)
  const isPlaying = computed(() => props.value.isPlaying)
  const transcriptionId = computed(() => props.value.transcriptionId)
  const messages = computed(() => props.value.messages)

  const ticketContentElement = ref<HTMLElement | undefined>(undefined)
  const ticketResponsesElement = ref<HTMLElement | undefined>(undefined)
  const { isScrolling, arrivedState } = useScroll(ticketResponsesElement)

  const paused = ref(false)
  const isDeadAirMessage = ref(false)

  const sortedMessages = computed(sortMessagesByStartTime(messages))
  const currentlyPlayingMessage = computed(getCurrentlyPlayingMessage)

  onMounted(initDomElementRefs)

  useEventListener(ticketResponsesElement, 'wheel', handleWheelEvent)
  useEventListener(ticketContentElement, 'keydown', handleKeydownEvent)
  useEventListener(ticketContentElement, 'touchstart', handleTouchEvent)

  watch(isPlaying, handleAudioPlayingStateChange)
  watch(currentlyPlayingMessage, handlePlayingMessageChange)

  function initDomElementRefs() {
    ticketContentElement.value = getTicketContentElement()
    ticketResponsesElement.value = getTicketResponsesElement()
  }

  function handleWheelEvent(e: WheelEvent) {
    const arrived = arrivedState.top || arrivedState.bottom
    const wheelMovementVertical = Math.abs(e.deltaY) !== 0

    if (arrived || !wheelMovementVertical) return
    paused.value = isScrolling.value
  }

  function handleKeydownEvent(event: KeyboardEvent) {
    if (!KEYBOARD_EVENT_KEYS.includes(event.key)) return
    paused.value = true
  }

  function handleTouchEvent() {
    paused.value = true
  }

  function handleAudioPlayingStateChange(isPlaying: boolean, prevIsPlaying: boolean) {
    if (!isPlaying || prevIsPlaying) return
    continueSyncScroll()
  }

  function handlePlayingMessageChange(message: TranscriptionMessage, prevMessage: TranscriptionMessage) {
    if (message?.transcriptionMessageId === prevMessage?.transcriptionMessageId) return
    scrollToPlayingTranscriptionMessage()
  }

  function scrollToPlayingTranscriptionMessage() {
    if (!currentlyPlayingMessage.value || paused.value) return

    scrollInteractionIntoView({
      transcriptionId: transcriptionId.value,
      transcriptionMessageId: currentlyPlayingMessage.value.transcriptionMessageId,
      scrollToDeadAir: isDeadAirMessage.value,
    })
  }

  function continueSyncScroll() {
    paused.value = false

    scrollToPlayingTranscriptionMessage()
  }

  function getCurrentlyPlayingMessage() {
    const currentTimeMs = currentTime.value * 1000

    return sortedMessages.value?.find((message) => {
      const isAfterStart = currentTimeMs >= Number(message.startTimeRelative)
      const isBeforeEnd = currentTimeMs < Number(message.endTimeRelative)
      const isAfterDeadAir = currentTimeMs >= Number(message.startTimeRelative) - Number(message.deadAirDuration)

      isDeadAirMessage.value = !isAfterStart && isBeforeEnd && isAfterDeadAir

      return (isAfterDeadAir || isAfterStart) && isBeforeEnd
    })
  }

  return {
    currentlyPlayingMessage,
    paused: computed(() => paused.value),
    continueSyncScroll,
  }
}

function sortMessagesByStartTime(messages: ComputedRef<TranscriptionMessage[]>) {
  return () => [...(messages.value ?? [])]?.sort((a, b) => Number(a.startTimeRelative) - Number(b.endTimeRelative))
}
