import type { RouteRecordRaw } from 'vue-router'

import { getFirstParam } from '@/utils/routes'
import { session } from '@/composables/useSession'
import { hasZendeskProxy } from '@/config'

const isProxied = hasZendeskProxy || session.features.zendeskProxyDev

const routes: RouteRecordRaw[] = [
  {
    path: '/settings',
    name: 'settings.workspaces',
    component: () => import('@/modules/user-management/UserManagementRoutes.vue'),
    props: ({ params }) => ({ id: parseFloat(getFirstParam(params.id)) }),
    meta: { requiredHighestWorkspaceRole: ['MANAGER', 'LEAD'] },
    children: [
      {
        path: 'workspaces/:id',
        name: 'workspaces',
        redirect: { name: 'workspace.members' },
        props: ({ params }) => ({ id: parseFloat(getFirstParam(params.id)) }),
        children: [
          {
            path: 'general',
            name: 'workspace.settings',
            components: {
              header: () => import('./components/GeneralViewHeader.vue'),
              default: () => import('./SettingsView.vue'),
            },
            meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
          },
          {
            path: 'scorecard',
            name: 'workspace.scorecard.moved',
            components: {
              header: () => import('./components/ScorecardMovedViewHeader.vue'),
              default: () => import('./ScorecardMovedView.vue'),
            },
            meta: { requiredHighestWorkspaceRole: ['MANAGER'], requiredFlag: 'advancedScorecardsDev' },
          },
          {
            path: 'categories',
            meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
            children: [
              {
                path: '',
                name: 'workspace.scorecard',
                components: {
                  header: () => import('./components/ScorecardViewHeader.vue'),
                  default: () => import('./ScorecardView.vue'),
                },
              },
              {
                path: 'new',
                name: 'workspace.scorecard.new',
                components: {
                  header: () => import('./views/scorecard/CategoryEditViewHeader.vue'),
                  default: () => import('./views/scorecard/CategoryEditView.vue'),
                },
              },
              {
                path: 'edit/:categoryId',
                name: 'workspace.scorecard.edit',
                components: {
                  header: () => import('./views/scorecard/CategoryEditViewHeader.vue'),
                  default: () => import('./views/scorecard/CategoryEditView.vue'),
                },
                props: {
                  default: ({ params }) => {
                    return {
                      workspaceId: Number(params.id),
                      categoryId: Number(params.categoryId),
                    }
                  },
                },
              },
            ],
          },
          {
            path: 'hashtags',
            name: 'workspace.hashtags',
            meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
            components: {
              header: () => import('./components/HashtagsViewHeader.vue'),
              default: () => import('./HashtagsView.vue'),
            },
          },
          {
            path: 'threshold',
            name: 'workspace.threshold',
            meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
            components: {
              header: () => import('./components/GeneralViewHeader.vue'),
              default: () => import('./ThresholdView.vue'),
            },
          },
          {
            path: 'connections',
            name: 'workspace.connections',
            meta: {
              requiredHighestWorkspaceRole: ['MANAGER'],
              hideProxied: isProxied && !session.features.allowAdditionalIntegrations,
            },
            components: {
              header: () => import('./components/ConnectionsViewHeader.vue'),
              default: () => import('./ConnectionsView.vue'),
            },
          },
          {
            path: 'assignments',
            meta: { requiredFlag: 'assignments' },
            children: [
              {
                path: '',
                name: 'workspace.assignments',
                components: {
                  header: () => import('./components/AssignmentsViewHeader.vue'),
                  default: () => import('./AssignmentsViewV2.vue'),
                },
              },
              {
                path: '/settings/workspaces/:id/assignments',
                meta: { requiredHighestWorkspaceRole: ['MANAGER', 'LEAD'] },
                children: [
                  {
                    path: '/settings/workspaces/:id/assignments/edit/:assignmentId',
                    name: 'workspace.assignments.edit',
                    components: {
                      header: () => import(`./views/assignments/AssignmentViewHeaderV2.vue`),
                      default: () => import(`./views/assignments/AssignmentEditViewV2.vue`),
                    },
                    props: {
                      header: ({ params, query }) => {
                        const { backTo, ...restOfQuery } = query
                        return {
                          workspaceId: Number(params.id),
                          assignmentId: params.assignmentId,
                          backTo: backTo && { name: backTo, query: restOfQuery },
                        }
                      },
                      default: ({ params, query }) => {
                        const { backTo, ...restOfQuery } = query
                        return {
                          workspaceId: Number(params.id),
                          assignmentId: params.assignmentId,
                          backTo: backTo && { name: backTo, query: restOfQuery },
                        }
                      },
                    },
                  },
                  {
                    path: 'new',
                    name: 'workspace.assignments.new',
                    components: {
                      header: () => import(`./views/assignments/AssignmentViewHeaderV2.vue`),
                      default: () => import(`./views/assignments/AssignmentEditViewV2.vue`),
                    },
                    props: {
                      header: ({ params, query }) => {
                        const { backTo, ...restOfQuery } = query
                        return {
                          workspaceId: Number(params.id),
                          backTo: backTo && { name: backTo, query: restOfQuery },
                        }
                      },
                      default: ({ params, query }) => {
                        const { backTo, ...restOfQuery } = query
                        return {
                          workspaceId: Number(params.id),
                          backTo: backTo && { name: backTo, query: restOfQuery },
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
          // Assignments settings
          {
            path: 'calibration',
            name: 'workspace.calibration',
            components: {
              header: () => import('./components/GeneralViewHeader.vue'),
              default: () => import('./CalibrationView.vue'),
            },
          },
        ],
      },
    ],
  },
]

export default routes
