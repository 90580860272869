import { orderBy } from 'lodash-es'
import type { MessageMistake, ReadabilityIssue } from '@zendesk/zqa-services/autoqa'
import useReviewSidebar from '@/composables/useReviewSidebar'
import useMessageHighlighting from '@/composables/useMessageHighlighting'

import wrapMistakesWithHTML from '../utils/highlight-mistakes/wrapMistakesWithHTML'

interface Params {
  rawInput: string
  commentExternalId: string
  plaintextSource?: boolean
}

export default () => {
  const { state: sidebarState } = useReviewSidebar()
  const { highlightingMap } = useMessageHighlighting()

  function highlightMistakes({ rawInput, commentExternalId, plaintextSource }: Params) {
    if (!rawInput) return rawInput

    let mistakes: MessageMistake[] | ReadabilityIssue[]

    if (highlightingMap.showGrammarMistakes) {
      mistakes = orderBy(sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.mistakes || [], (m) =>
        Number(m.htmlStartIdx),
      )
    } else if (highlightingMap.showReadabilityComplexWords) {
      mistakes = orderBy(
        sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.readabilityIssues.filter(
          (i: ReadabilityIssue) => i.type === 'complex_words',
        ) || [],
        (m) => Number(m.htmlStartIdx),
      )
    } else if (highlightingMap.showReadabilityLongSentences) {
      mistakes = orderBy(
        sidebarState.autoQaReviews?.metadataPerMessage[commentExternalId]?.readabilityIssues.filter(
          (i: ReadabilityIssue) => i.type === 'long_sentences',
        ) || [],
        (m) => Number(m.htmlStartIdx),
      )
    }

    return wrapMistakesWithHTML({ rawInput, mistakes, plaintextSource })
  }

  return {
    highlightMistakes,
  }
}
