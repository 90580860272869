export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna Zendesk-alitoimialueesi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu Zendeskiin"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritykselläsi on jo tili."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liity yritykseen"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat liittyä olemassa olevaan yritykseen, pyydä järjestelmänvalvojaasi lähettämään sinulle kutsu."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä järjestelmänvalvojaasi kutsumaan sinut"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutin mieleni."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta rekisteröityminen"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritykselläsi on jo tili. Haluatko liittyä yrityksesi tiliin vai luoda uuden tilin?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi ohjeet"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi tili"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tili on jo olemassa"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et voi käyttää Zendesk QA -tiliäsi ennen kuin maksamattomat tilauslaskut on maksettu."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksesi on pidossa"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiliä ei löytynyt. Yritä uudelleen."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hanki uusi salasana."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yritä uudelleen tai ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärä sähköpostiosoitteen ja salasanan yhdistelmä."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä uusi järjestelmänvalvojaltasi."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kutsulinkki ei kelpaa tai sitä on jo käytetty."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("method")), "-kirjautuminen ei ole käytössä tililläsi."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kutsu on virheellinen tai käytetty. Pyydä uusi järjestelmänvalvojaltasi."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsutietojen lataaminen epäonnistui."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröityminen epäonnistui. Käyttäjä, jolla on tämä sähköpostiosoite, saattaa jo olla olemassa."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilisi on estetty useiden peräkkäisten sisäänkirjautumisyritysten jälkeen. Tarkista, onko postilaatikkoosi saapunut sähköposti, jossa on ohjeet tilin eston poistamiseen."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista ensin sähköpostiosoitteesi"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen sähköpostitili"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä on pakollinen"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä on pakollinen"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjautumiskoodi"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä taikalinkki"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työsähköposti"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työsähköpostiosoitteesi"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (pääpalvelusopimus)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana ei täytä vaatimuksia"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosuojailmoitus"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hei, ", _interpolate(_named("invitee_name")), ","])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liity ", _interpolate(_named("organization")), "-organisaatioon"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoitteesi on vahvistettu. Voit nyt kirjautua sisään uudella tililläsi alta."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka Google-tunnuksilla"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen kutsulinkki"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään käyttämällä taikalinkkiä"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa kirjautuaksesi sisään"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka Slack-tunnuksilla"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka Zendeskillä"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoitteesi on vahvistettu. Voit nyt kirjautua sisään uudella tililläsi alta:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään Google-tilillä"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään taikalinkin kautta"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään Slackilla"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään Zendesk QA:han"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilisi on poistettu"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eikö sinulla ole vielä tiliä?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen koodi"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä on pakollinen"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen sähköpostitili"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä on pakollinen"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjautumiskoodi"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki kirjautumisvaihtoehdot"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " perinteisillä menetelmillä"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään käyttämällä taikalinkkiä"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetimme sinulle sähköpostin, joka sisältää kirjautumiskoodisi."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetimme sinulle sähköpostin, jota noudattamalla voit kirjautua sisään."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikalinkkiä lähetettäessä tapahtui virhe. Yritä uudelleen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään sähköpostiosoitteella"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo salasana"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi pieni kirjain"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi iso kirjain"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudessa salasanassa on oltava"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasanan enimmäispituus on 128 merkkiä"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helppo muistaa, vaikea arvata"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähintään 8 merkkiä"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo salasanasi"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unohditko salasanasi?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi pieni kirjain"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi numero"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi iso kirjain"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat nollata salasanasi, anna sähköpostiosoitteesi, niin lähetämme sinulle siihen ohjeet sähköpostissa."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kelvollinen sähköpostitili"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä on pakollinen"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa salasana"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sähköposti on lähetetty osoitteeseen ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos sähköposti ei näy saapuneet- tai roskapostikansiossa, tarkasta antamasi sähköpostiosoite."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa kirjautuaksesi sisään"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta sähköpostisi."])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa salasana"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", sinulle lähetetään ohjeet salasanan nollaamiseen sähköpostitse."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tililläsi on jo salasana"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos kyseinen ", _interpolate(_named("bold")), ", olemme lähettäneet sinulle linkin salasanan nollaamiseen."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sähköposti on liitetty tiliin"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muistitko salasanasi?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe lähetettäessä nollauslinkkiä. Yritä uudelleen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa salasanasi"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy käyttämällä taikalinkkiä"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tietosuojakäytännön"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rekisteröitymällä hyväksyt Zendesk QA:n ", _interpolate(_named("terms_of_service")), " ja ", _interpolate(_named("privacy_policy"))])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["palveluehdot"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko sinulla tili?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy taikalinkin kautta"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy työsähköpostiosoitteella"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy Slackilla"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy Zendesk QA:han"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy Googlella"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minulla on aktiivinen Zendesk-tili"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rekisteröidy tai kirjaudu sisään ja asenna ", _interpolate(_named("direct_install_name")), " loppuun"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko se todella sinä? Tarkista vahvistussähköposti postilaatikostasi."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki valmista"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA toimii parhaiten tietokoneilla. Kirjaudu sisään aloittaaksesi keskusteluiden arvostelun."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaa esittely"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit myös varalta meiltä esittelyn, niin näytämme sinulle, mitä voit tehdä Zendesk QA:n avulla."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läsnäoloasi pyydetään"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peruuta"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Muutin mieleni, ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " kutsuu sinut liittymään tililleen."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liity tiliin"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi tili"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä kirjautua uudelleen"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Palautteellesi annettu reaktio: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut reaktiot"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagoi kommenttiisi"]), _normalize(["Useat ihmiset reagoivat kommenttiisi"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagoi vastaukseesi"]), _normalize(["Useat ihmiset reagoivat vastaukseesi"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagoi arvosteluusi"]), _normalize(["Useat ihmiset reagoivat arvosteluusi"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentti"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vastaus"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelu"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KIISTAT"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNETTU"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VASTAANOTETTU"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselypalaute"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktiot"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitetut"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merkitäänkö kommentti luetuksi?"]), _normalize(["Merkitäänkö kaikki kommentit luetuiksi?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merkitäänkö kiista luetuksi?"]), _normalize(["Merkitäänkö kaikki kiistat luetuiksi?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merkitäänkö kyselypalaute luetuksi?"]), _normalize(["Merkitäänkö kaikki kyselypalautteet luetuiksi?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Merkitäänkö arvostelu luetuksi?"]), _normalize(["Merkitäänkö kaikki arvostelut luetuiksi?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentti merkitty luetuksi"]), _normalize(["Kaikki kommentit merkitty luetuiksi"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kiista merkitty luetuksi"]), _normalize(["Kaikki kiistat merkitty luetuiksi"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kyselypalaute merkitty luetuksi"]), _normalize(["Kaikki kyselypalautteet merkitty luetuiksi"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arvostelu merkitty luetuksi"]), _normalize(["Kaikki arvostelut merkitty luetuiksi"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse kaikki luetuiksi"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse luetuksi"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelua"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun joku lisää arvosteluun kiistan, se näytetään tässä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kiistoja"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun sinut delegoidaan ratkaisemaan kiista, se näytetään tässä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettuja kiistoja"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun lisäät arvosteluun kiistan, se näytetään tässä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aloitettuja kiistoja"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki sinulle annettu palaute näytetään tässä. Ei vielä mitään näytettävää."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettua palautetta"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosteluihisi ja vastauksiisi annetut reaktiot näytetään tässä. Ei vielä mitään näytettävää."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettuja reaktioita"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa keskustelu"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki antamasi palaute päätyy tänne. Aloita jättämällä arvostelu Keskustelut-näkymästä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei annettuja arvosteluja"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki sinulle annettu palaute näytetään tässä. Ei vielä mitään näytettävää."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettuja arvosteluja"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentteja ei löytynyt"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kommentteina jättämäsi palaute näytetään tässä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei annettuja kommentteja"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentteja ei löytynyt"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki omistamasi arvostelun kommentit, ", "@", "-maininnat ja vastaukset kommentteihisi näytetään tässä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettuja kommentteja"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hyväksyttyjä kiistoja"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei avoimia kiistoja"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hylättyjä kiistoja"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei ratkaistuja kiistoja"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä uudelleen valitsemalla toinen vaihtoehto yllä olevasta suodattimesta."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä uudelleen valitsemalla toinen käyttäjä yllä olevasta suodattimesta."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut kommentit"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut kommentit"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kiistat"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut kiistat"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitetut kiistat"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelu"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltava"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotettu kyselypalautetta"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut arvostelut"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota aktiviteettiluettelo"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " arvosteltu"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " arvosteltu"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " arvosteltu"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käynnistyy uudelleen ", _interpolate(_named("time")), " kuluttua"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " jäljellä"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä toimeksianto"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota toimeksiantojen luettelo"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjien ", _interpolate(_list(0)), " ja ", _interpolate(_list(1)), " välille ei voi luoda valmennusistuntoa, koska he eivät ole tällä hetkellä samassa työtilassa."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjien ", _interpolate(_list(0)), " ja ", _interpolate(_list(1)), " välillä on jo valmennusistuntoja. Ota yhteyttä käyttäjään ", _interpolate(_list(0)), " saadaksesi pääsyn istuntoon."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdotetut"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjien ", _interpolate(_list(0)), " ja ", _interpolate(_list(1)), " välille luotiin uusi istunto."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmentaja"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse valmennettava"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennettava"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse valmentaja"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunto"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo istunto"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo istunto"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedot"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää istunto"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnon nimi"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdotetut"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myöhässä"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menneisyydessä"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulevat"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo istunto"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntoja ei löytynyt"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnot"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen arvosteluaika"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallintapaneeli"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettävä tietoja valitulta ajanjaksolta"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lisätty kiinnitys istuntoon. ", _interpolate(_named("buttonStart")), "Näytä minulle", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää istuntoon..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätty"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut merkittyjen kohteiden lopun valitulta ajanjaksolta"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä ", _interpolate(_named("link")), " näyttää, miten kiinnittäminen toimii."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artikkeli"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Et nähtävästi ole kiinnittänyt mitään käyttäjälle ", _interpolate(_list(0)), ". Voit käyttää kiinnitystä valmistautuaksesi henkilökohtaiseen istuntoon tai tallentaaksesi tietoja myöhempää käyttöä varten."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valmennettava"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä kiinnitettyjä kohteita"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tässä ovat kaikki kiinnitykset, jotka olet luonut käyttäjälle ", _interpolate(_named("name")), ". Kiinnitetyt istunnot ", _interpolate(_named("bold")), " toiselle osallistujalle."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaetaan välittömästi"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ei nähtävästi ole saanut palautetta keskustelun arvostelusta tai CSAT:stä valitun ajanjakson aikana."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautetta ei ole saatavilla"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut kommenttien lopun valitulta ajanjaksolta"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittarit"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitykset"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjän ", _interpolate(_list(0)), " numerot"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi toimintokohde"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("user")), " suoritti ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Suorittaja: ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sinä"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita lisätäksesi uuden toimintokohteen"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitä hyötyä tästä tapaamisesta oli? Mitä teet seuraavaksi?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimintokohteet"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " loi ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistaa istunnon"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä ", _interpolate(_named("deleteSession")), " pysyvästi. Osallistujat menettävät pääsyn istuntoon, mukaan lukien sen sisältöön ja yksityisiin huomautuksiin."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Istunto luotu ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " muokkasi viimeksi ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsitellyt aiheet ja muistiinpanot"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomautus päivitetty"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistu istunnosta"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitut käyttäjät saavat pääsyn istunnon tietoihin tästä hetkestä alkaen."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjät"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaettu"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa istunto"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Et ole enää osana käyttäjien ", _interpolate(_list(0)), " ja ", _interpolate(_list(1)), " istuntoa."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ei ole enää osa tätä istuntoa."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " voi nyt käyttää tätä istuntoa"]), _normalize([_interpolate(_named("n")), " uutta käyttäjää voivat nyt käyttää tätä istuntoa"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista istunto"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikki istunnot"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista istunto"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmentajan"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " lisäämät huomautukset näytetään tässä."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kiinnitetyt kohteet keskustelun aiheiksi oikeanpuoleisesta sivupaneelista"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitykset"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomautukset, jotka vain sinä näet..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset huomautukset"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityinen huomautus päivitetty"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 päivää"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmentaja"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennettava"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimintokohteet"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen istunto"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käynnissä"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myöhässä"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntojen yleiskatsaus"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minä: Valmentaja"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minä: Valmennettava"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki istunnot"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaetut istunnot"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile vaihtaa suodatinta nähdäksesi toimintokohteet."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei toimintokohteita"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimintokohteet"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana)"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut ja nähdyt arvostelut"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana)"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut · Nähdyt"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edistyminen istuntojen jälkeen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnot"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilöt eivät ole nähtävästi saaneet palautetta."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista, että sinulla on oikea käyttäjän nimi"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä käyttäjää ei löytynyt"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataa ei ole saatavilla"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana) edelliseltä 30 päivältä"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä <b>poistaa kaikki henkilön <b>", _interpolate(_named("name")), "</b> kanssa käydyt istunnot</b> pysyvästi (2) . Osallistujat menettävät pääsyn kaikkiin istuntoihin, mukaan lukien sisältö ja yksityiset huomautukset."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut kaikkien istuntojen lopun"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusistunto on mentorin ja valmennettavan välillä käyty ryhmäaktiviteetti, jossa keskustellaan suorituskyvystä, mahdollisuuksista sekä lyhyen ja pitkän aikavälin tavoitteista."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit hakea istuntoja sekä valmentajan nimen että valmennettavan henkilön mukaan."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntoja ei löytynyt"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä luotuja istuntoja"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista valmennushistoria"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista valmennushistoria "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen istunto"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunto"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnot"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki istunnot"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmentaja"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennettava"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse valmiiksi"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse keskeneräiseksi"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae nimen mukaan"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimeä uudelleen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota istuntojen luettelo"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoittamattomat"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaa tähän viestiin"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhentunut kalibrointi-istunto. Arvosteluita ei voi jättää enempää."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet jo jättänyt arvostelun tähän keskusteluun."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä keskustelua ei voi enää arvostella, koska istunnon eräpäivä on mennyt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tällä keskustelulla ei ole arvosteluita"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje paneeli"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä kalibrointiarvostelu"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIEDOT"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YHTEENVETO"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSKRIPTIO"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Puhuja ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " sek"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hiljaisuutta"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa puhujaa"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele viesti"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa toistettuun viestiin"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhujat"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkriptio"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan käyttäjät"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo suodattimia lisäämällä ensin yhteys"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei botteja"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " loi ", _interpolate(_named("time"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " päivitti viimeksi ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sinulla ei nähtävästi ole suodattimia. Sinun täytyy luoda suodatin avataksesi tämän sivun. Luo aluksi ", _interpolate(_named("recommendedFilter")), " nähdäksesi omat keskustelusi. Jatka luomalla tämä suodatin alla."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edelliseltä 30 päivältä"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilman ajanjaksoa luotuihin suodattimiin lisätään ehto ”Luotu edellisen 30 päivän aikana”."])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatin ei ole käytettävissä tässä tilauksessa, eikä sitä oteta huomioon tulosten näyttämisessä"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatinvaihtoehtojen nouto aikakatkaistiin. Yritä uudelleen."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää suodatuksesta"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä = sisäänkirjautunut henkilö"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Löydetyt keskustelut: ", _interpolate(_named("count"))]), _normalize(["Löydetyt keskustelut: ", _interpolate(_named("count"))]), _normalize(["Löydetyt keskustelut: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei käytettävissä nykyisessä tilauksessasi. Päivitä käyttääksesi tätä ominaisuutta."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ehto"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA:n tekoälyn luoma"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehto"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta toimeksiannoksi"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo suodatin"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo suodatin"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautetut kentät"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdot"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataamme suodatinvaihtoehtoja määrityksiesi mukaan. Tämä voi kestää muutaman minuutin ensimmäisen latauksen yhteydessä."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna suodattimellesi kuvaava nimi"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodattimen nimi"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vaihtoehto"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehto"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityinen"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkinen"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa suodatinta"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna muutokset"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvoja käytettävissä"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei löytynyt"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvo"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkyvyys"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain aktiiviset istunnot ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vanhemmat istunnot ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eräpäivä"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa kalibrointi-istuntoa"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo istunto"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kalibrointi-istunto"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki istunnon aikana annetut kalibrointiarvostelut poistetaan pysyvästi."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista istunto"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista kalibrointi-istunto ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntoja ei ole luotu"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivisia istuntoja"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei luotuja istuntoja"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivisia istuntoja"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää suodatin"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo yksityinen suodatin"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo julkinen suodatin"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatin lisätty"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatin poistetaan pysyvästi."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista suodatin"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista suodatin ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kiistat"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puolestani tehdyt kiistat"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekemäni kiistat"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet kiistat"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnokset"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisätty suodattimia"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityisiä suodattimia ei ole luotu"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkisia suodattimia ei ole luotu"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodattimet"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityiset suodattimet"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkiset suodattimet"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tallentaa sen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä on väliaikaisen suodattimen näkymä."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakennetaan suodatintasi..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatin päivitetty"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitykselliset tiedot"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu AutoQA-kategoria"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päivitetty viimeksi ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monimutkaiset sanat"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattiset arvostelut päivitetään dynaamisesti tikettien muutosten perusteella"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korosta keskustelussa"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkät lauseet"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattista arvosanaa ei ennustettu"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheitä ei havaittu"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä monimutkaiset sanat"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä pitkät lauseet"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava keskustelu"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA ei voinut arvioida tätä kategoriaa, koska konteksti ei ole riittävä. Manuaalista tarkastusta suositellaan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaitse keskusteluiden merkitykselliset tiedot automaattisesti tehdäksesi arvosteluprosessistasi suoraviivaisemman. Korosta tärkeitä keskusteluja ja esittele positiivisia esimerkkejä tiimillesi."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Puhelu]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korjattu"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hyväksyi kiistan"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kiista lähetetty tukihenkilölle ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selitä, miksi tämä arvosteltava on mielestäsi väärä vastaanottaja tälle palautteelle"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kommentti ja valitse uusi arvosteltava"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kommentti"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selitä, miksi nämä arvosanat ovat mielestäsi epäoikeidenmukaisia (pakollinen)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kommentti (pakollinen)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selitä, miksi olet hylkäämässä tämän kiistan"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kommentti"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kiista käyttäjälle"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kiista käyttäjälle"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kiista käyttäjälle"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse uusi arvosteltava"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistauta palaute"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistauta palaute"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda arvosteltavaa"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kiista käyttäjälle"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiista"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistauta tämä arvostelu"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat ovat mielestäni vääriä"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä arvostelua ei ollut tarkoitettu minulle"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjä"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hyväksyi kiistan osittain"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " hylkäsi kiistan"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osittain hyväksytyt"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätyt"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " aloitti kiistan: arvostelu"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " aloitti kiistan: väärä arvosteltava"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoin kiista tulisi ratkaista, jotta arvostelussa voidaan ryhtyä muihin toimenpiteisiin."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse uudet arvosanat"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kiista"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti hylätä tämän kiistan?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kiista"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiista"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistan ratkaisun kesto"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse uudet reilut arvosanat (valinnainen)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistan arvostelu"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärä arvosteltava"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy kiista"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy kiista"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistauta palaute"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärä arvosteltava"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistauta palaute"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää kiista"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää kiista"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kiista"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokattu"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), ", tekijä ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["käyttäjälle ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostellut"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki valmista"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoitu henkilölle:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä myös kommentti"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää ehdollisista arvosanakorteista"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiemmin delegoitu käyttäjälle:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vain yksi"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF-kuvaa ei löytynyt tällä nimellä"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ole ensimmäinen, joka jättää arvostelun."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksesi on tällä hetkellä tauolla. Aktivoi se uudelleen mahdollistaaksesi palautteen antamisen."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettäjä:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä näyttää, kuinka paljon aikaa tämän keskustelun arvostelemiseen käytettiin."])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjä"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Puuttuva juurisyy kategorialle: ”", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi kategoria tai lisää kommentti"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää emoji"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiivistä"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laajenna kommenttikenttä"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiivistä kommenttikenttä"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota muotoilu"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota paneeli"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä muotoilu"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä paneeli"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä arvostelu"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi kaikki kategoriat"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi vähintään yksi kategoria"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna tämä keskustelu myöhempää käyttöä varten"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna myöhempää käyttöä varten"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava toimeksianto"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korvaa keskustelu tässä delegointijaksossa"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele keskustelu"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perustason arvostelu"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kommentti"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti poistettu"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistaa tämän kommentin?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin kirjoittaja"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä aihetunniste on poistettu tai nimetty uudelleen."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerro, mistä pidit tai mikä olisi voinut mennä paremmin…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentoi"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdollinen"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa kaikki kommentit pysyvästi."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kommentti"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Viesti]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ei ole nähnyt tätä palautetta vielä"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " on nähnyt tämän palautteen"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entinen jäsen"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae GIF-kuvia..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi aihetunniste painamalla Enter."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestelmänvalvoja on lukinnut mukautetut aihetunnisteet."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä tätä lisätäksesi tapauksia kirjanmerkkeihin ja suodattaaksesi niitä myöhemmin"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse perustasoksi"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestin palaute"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava keskustelu"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei palautetta"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteluja"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita kirjoittamalla arvostelu alle."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteluja jäljellä"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvioitu"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen käsittelijä"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen keskustelu"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista perustason merkintä"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaa"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaa tähän arvosteluun"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota arvosteluiden sivupalkki"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa pysyvästi arvostelun ja kaikki lisätyt kommentit."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista arvostelu"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista arvostelu"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelu poistettu"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " poisti arvostelun"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmista tuli!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi tämä tehtävä itsellesi, jotta voit jättää arvostelun"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet jo jättänyt arvostelun tälle käyttäjälle."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Olet jättänyt arvostelun käyttäjälle ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä kategorioita tälle keskustelulle."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit eivät ole käytettävissä tässä työtilassa."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä tallenna"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko tallentaa tämän arvostelun luonnokseksi?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää tämä luonnos"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä arvosteluiden sivupalkki"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätty"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty pienine virheineen"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suoritamme laskutoimituksia..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä kommentti"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele tämä viesti"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele itsesi"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele tämä keskustelu"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekijä"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteltavia käyttäjiä"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit poistettu käytöstä"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjää ei löytynyt"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistujat"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan käyttäjät"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjä"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 arvostelu"]), _normalize([_interpolate(_named("count")), " arvostelua"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse uudet arvioinnit"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan ylläpitäjä ei ole sallinut itsearviointeja."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä painamalla Enter"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibroinnit"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeiset 30 päivää"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tähdellä merkityt"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kalibrointi-istunto"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnokset"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitykselliset tiedot"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitykselliset tiedot esittävät sinulle tukipalvelusi datan visuaalisessa esitysmuodossa. Selvitä interaktiivisten kaavioiden avulla, mihin QA (laadunvalvonta) kannattaa keskittää, paljasta sokeat pisteet ja löydä oikeat tukihenkilöt tekemään arvosteluja heidän suorituksensa perusteella."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " TUNNUS"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL-OSOITE"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käännä kielelle ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää istuntoon"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoimaton"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytetään parhaillaan"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelussa nyt"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä keskustelu on poistettu, eikä se ole enää käytettävissä."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsimääsi keskustelua ei ole Zendesk QA:ssa."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tämän keskustelun kiinnitykset"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnossa"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun poistaminen istunnosta poistaa kaikki sille annetut arvostelut."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista keskustelu istunnosta ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi-istuntoja ei ole vielä luotu."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä keskustelu on lisätty istuntoon"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä keskustelu on poistettu istunnosta"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kalibrointi-istuntoon tai poista siitä"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi linkki tähän keskusteluun"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettävää keskustelua"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brändi"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan sähköpostiosoite"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan tunnus"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan nimi"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoisen lomakkeen nimi"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäisen vastauksen odotusaika"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmä"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioriteetti"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn kommentti"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyytyväisyysarvosana"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteet"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aika ensimmäiseen ratkaisuun"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkuperäinen"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä viestiä ei ole tässä keskustelussa tai se on poistettu"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aihetta"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettävää keskustelua"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelua ei löytynyt"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa alkuperäinen keskustelu"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voi linkittää keskusteluun – URL-osoite puuttuu"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistujat"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei osallistujia"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä keskustelu"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napsauta lukeaksesi arvostelut"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napsauta arvostellaksesi"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu luotu selainlaajennuksen kautta"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä asiakastyytyväisyyskysely"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso keskustelu"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viesti, jossa on negatiivinen asenne"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viesti, jossa on positiivinen asenne"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käännä englanniksi"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä alkuperäinen"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskustelun poistaminen on pysyvää. Se poistaa myös kaiken siihen liittyvän datan, esim. kyselyt, tallenteet ja arvostelut Zendesk QA:sta, mukaan lukien hallintapaneelit.", _interpolate(_named("lineBreak")), "Voit silti käyttää tätä keskustelua Zendesk Supportissa tai muussa käyttämässäsi tukipalveluohjelmistossa."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskustelun poistaminen on pysyvää. Se poistaa myös kaiken liittyvän datan, esim. kyselyt, tallenteet ja arvostelut Zendesk QA:sta, mukaan lukien hallintapaneelit. Jos keskustelu kuuluu useisiin työtiloihin, se poistetaan kaikista niistä.", _interpolate(_named("lineBreak")), "Voit silti käyttää tätä keskustelua Zendesk Supportissa tai muussa käyttämässäsi tukipalveluohjelmistossa."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu Zendesk QA.sta"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä keskustelu on epätäydellinen eskaloinnin vuoksi. Siirry kohteeseen ", _interpolate(_named("optionsUrl")), " ja vahvista tunnisteet. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä keskustelu on epätäydellinen eskaloinnin vuoksi. Keskustele järjestelmänvalvojan kanssa tunnisteiden vahvistamisesta. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet > Lisäasetukset"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoa tunnisteista"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suljettu"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottavat"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratkaistu"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käsittelijäksi muutettiin ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoimaton"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käyttäjän ", _interpolate(_named("author")), " käyttämä ", _interpolate(_named("target")), "-makro"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskustelu sai arvosanan ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käyttäjä ", _interpolate(_named("target")), " peitti keskustelun"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilaksi muutettu ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Merkitty tunnisteella ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiimiksi muutettu ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " torkutti keskustelua ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " kumosi keskustelun torkutuksen"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botti on jumissa silmukassa toistaen peräkkäin viestiä"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botin toisto"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botin toisto, jossa sama viesti toistetaan yhden tai useamman kerran peräkkäin"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löytyi vastaavia lauseita"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskivertotukihenkilö käsittelee keskustelun ", _interpolate(_named("percent")), " tehokkaammin kuin botti"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiviestinnän tehokkuus"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Botti käsitteli keskustelun ", _interpolate(_named("percent")), " tehokkaammin kuin keskivertotukihenkilö"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiviestinnän tehokkuus"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelusta puuttuu tarvittava lausunto, joka ilmoittaa osallistujille keskustelun nauhoittamisesta ja joka edellytetään standardien noudattamiseksi ja avoimuuden ylläpitämiseksi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauhoitusta koskeva ilmoitus puuttuu"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Palvelutason sopimus (SLA) on rikottu kohteelle ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA rikottu"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA-säännön mittari ylitti sen tavoiteajan"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkkejä asiakaskadosta. Asiakas harkitsi vaihtoa tai lupasi lopettaa asiakkuutensa."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihtuvuusriski tunnistettu"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Keskustelussa on hiljaisia hetkiä, jotka kestävät määritettyä ", _interpolate(_list(0)), " sekunnin kynnysarvoa kauemmin"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 esiintymä"]), _normalize([_interpolate(_named("count")), " esiintymää"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pisin ", _interpolate(_list(0)), " sek"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiljaisuutta"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas pyysi apua ylemmältä tasolta"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalointia pyydetty"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas ilmaisi olevansa äärimmäisen kiitollinen tai erittäin tyytyväinen saamaansa tukeen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeuksellinen palvelu"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas tai tukihenkilö pyysi nimenomaisesti jatkotoimenpiteitä"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkotoimenpiteiden pyyntö"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas ilmaisi olevansa tyytymätön"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivinen asenne"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu oli epätavallista tai epätyypillistä, ja ratkaisun löytäminen edellytti tavallista enemmän vuoropuhelua"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeava arvo havaittu"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas ilmaisi olevansa tyytyväinen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiivinen asenne"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soveltuu"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä soveltumattomaksi"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " muokkasi viimeksi ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellu"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tekijä: ", _interpolate(_named("author")), ", pmv: ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää syy (valinnainen)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkityksellisiä tietoja ei havaittu"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää yhteys"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä tukipalvelu tiliisi ja luo vähintään yksi suodatin, jotta näet keskusteluluettelon."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä keskusteluja"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä työtilan asetukset"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltava"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotu"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty viimeksi"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Päivän päästä)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tässä kuussa)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tällä viikolla)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi tunnuksen mukaan"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hakutulokset haulla ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muiden arvostelema"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentoitu"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusin"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhin"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satunnainen"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeksi päivitetty"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastattu äskettäin"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lajitteluehto"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukematon"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun arvostelemasi"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuontiprosessi voi kestää jonkin aikaa. Ilmoitamme sinulle sähköpostitse, kun se on valmis."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tuo keskustelujasi"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Raja: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää niitä napsauttamalla kalibrointikuvaketta keskustelunäkymän otsikosta."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EI mitään raportoitavaa. Yritä uudelleen käyttämällä eri suodatinta tai lajitteluvaihtoehtoa."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä poikkeavia arvoja."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi relevantti keskustelu ja napsauta tähtikuvaketta sen otsikosta lisätäksesi sen kirjanmerkkeihin tänne."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile vaihtaa suodattimia tai kutsu muita tukihenkilöitä lisäämään keskusteluja."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei rajaa"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäinen huomautus"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataamme keskusteluja juuri nyt. Osa sisällöstä saattaa silti puuttua."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava käyttäjä"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjää ei löytynyt"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan jäsenet"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen käyttäjä"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisättyjä keskusteluja"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluja ei ole lisätty"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeneräiset arvostelut päätyvät tänne. Lue se uudelleen, muokkaa sitä ja lähetä se seuraavan 30 päivän kuluessa."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteluiden luonnoksia"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tähdellä merkittyjä keskusteluja"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä keskusteluja"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei osumia"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa valittuun viestiin"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähiten vastauksia"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eniten vastauksia"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenne"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota keskusteluluettelo"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki käyttäjät"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOTTI"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puheluun vastattu klo ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puhelu päättyi klo ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puhelu soitettu klo ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltu"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun arvostelemasi"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Äänitiedoston lataaminen epäonnistui. Varmista, että olet kirjautunut ", _interpolate(_list(0)), "-palveluun."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastyytyväisyyskyselyn kommentti"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe ladattaessa ääntä"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin kirjoittaja"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelua ei tapahtunut"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuuntele tukipalvelussa"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuuntele Aircallissa"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toistonopeus"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele puhelu"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestelmä"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkriptio"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkea"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matala"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskitaso"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei Zendesk QA -käyttäjä"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA luokittelee tukihenkilöt automaattisesti heidän kuukausittaisen keskiarvoisen CSAT-pistemääränsä perusteella. Löydät yhdellä napsautuksella keskustelut, joita käsittelevillä tukihenkilöillä on alhaisempi CSAT kuin heidän työtovereillaan."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestys CSAT:n mukaan"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskitaso"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkea"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matala"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matalin"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkein"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei Zendesk QA -käyttäjä"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi tiketti"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volyymi"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA luokittelee tukihenkilöt automaattisesti suorituskykyryhmiin sen mukaan, kuinka monta keskustelua he käsittelevät kuukaudessa. Löydät yhdellä napsautuksella keskustelut, joiden tukihenkilöillä on pienempi volyymi on kuin heidän työtovereillaan."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestys volyymin mukaan"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltu"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteltu"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun tila"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matala"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkea"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutraali"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei Zendesk QA -käyttäjä"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenne"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA luokittelee tukihenkilöt automaattisesti keskustelujen keskiarvoisen asenteen perusteella. Löydät yhdellä napsautuksella tilanteet, joissa tukihenkilöt käsittelevät turhautuneempia asiakkaita kuin heidän työtoverinsa."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestys asenteen mukaan"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei havaittu"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaittu"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeamat"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut, jotka vaativat huomiotasi. Koneoppimismallimme tunnistaa monimutkaiset tai epätyypilliset vuorovaikutukset, jotta ne voidaan tarkastaa tarkemmin."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeamat"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivinen"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiivinen"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvosteltavia keskusteluja, joissa asiakas ilmaisi olevansa iloinen tai turhautunut. Valitse pudotusvalikosta, minkä tyyppistä asennetta etsit keskusteluistasi. Vedä suodatinattribuutti luettelon alkuun nähdäksesi valitun asenteen osuuden kaikissa vuorovaikutuksissasi."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenne"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenne"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun tila"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteltu"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltu"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tunnistaa keskusteluissasi käytetyt kielet automaattisesti. Valitse pudotusvalikosta tietyillä kielillä käytyjä keskusteluja arvostelua varten. Vedä suodatinattribuutti luettelon alkuun saadaksesi yleiskatsauksen kaikista tukipalvelussasi käytetyistä kielistä."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluiden merkitykselliset tiedot"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Merkityksellisten tietojen avulla ", _interpolate(_named("paragraph_2_item")), " kuin tavallisilla keskusteluiden suodattimilla. Saat visuaalisen yleiskatsauksen kaikista tukipalvelusi tiedoista ja voit tutkia merkityksellisiä tietoja, esimerkiksi seuraavia:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää keskusteluiden merkityksellisten tietojen käyttämisestä"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrä tiettyjen keskusteluattribuuttien merkitys suhteessa muihin"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löydät parhaiten ja huonommin suoriutuneet tukihenkilöt sisäänrakennetun tukihenkilöiden suorituskyvyn luokituksen avulla"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " on tehokas ja vuorovaikutteinen tiedonlouhintatyökalu, joka auttaa sinua löytämään eniten arvostelua vaativat keskustelut."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saat paljon enemmän aikaan"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskustelu- ja Tukihenkilö-kaaviot on ", _interpolate(_named("paragraph_3_item_1")), " – suodattimien päivittäminen tai segmentoitujen kaaviolohkojen napsauttaminen jommastakummasta päivittää keskusteluluettelon automaattisesti. ", _interpolate(_named("paragraph_3_item_2")), " tarkastellaksesi eri elementtien yksityiskohtaisia erittelyjä tai suhteita."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yhdistetty toisiinsa"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta suodatinattribuuttien järjestystä"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keskusteluiden merkitykselliset tiedot näyttävät vain ", _interpolate(_named("paragraph_4_item")), ". Poistamme automaattisesti vuorovaikutukset, joiden arvosteleminen ei toisi lisäarvoa, kuten automaattisesti luodut viestit, roskapostit, bottien vastaukset ja viestit, jotka eivät ole relevantteja tukihenkilöiden kannalta."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puhtaat ja suljetut keskustelut"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitä näen tässä?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), "/", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "/", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouto epäonnistui"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " N/A"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilöt"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " kokonaismäärästä)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa uudelleen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitykselliset tiedot"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemme kuitenkin varmoja, että saat pian positiivista palautetta."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu kysely"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselypalautetta ei ole jätetty"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselypalautteen arvosana ei ole vielä saatavilla..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointi"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syy"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vastaanotettu"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen tunniste"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen kommenttien tunnisteet"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytetty ajanjakson suodatin"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " vastaus"]), _normalize([_interpolate(_named("count")), " vastausta"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelu"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tutustu ", _interpolate(_list(0)), "-arvosanan perusteluihin tarkemmin määrittämällä syitä kyselyiden asetuksista."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ei vielä ", _interpolate(_list(0)), "-arvosanojen syitä"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kyselyiden syistä"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteita sisältävät keskustelut:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteet"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ehtona:"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielet"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syyt"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteet"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin kategoria"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), ": syyt"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ennustetut ", _interpolate(_named("surveyType")), "-osatekijät"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lähde"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteet"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kyselyn vastaukset (", _interpolate(_named("n")), ")"]), _normalize(["Kyselyn vastaus (", _interpolate(_named("n")), ")"]), _normalize(["Kyselyn vastaukset (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aihetta"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-keskustelut"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " käyttäjän mukaan"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutos"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausprosentti"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-arvosana on kaikkien vastausten summa jaettuna enimmäispisteiden summalla"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa tavoitetta"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista tavoite"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta tavoite"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tavoite"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyhyt"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erittäin pitkä"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkä"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskipitkä"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämmentävä"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hyödyllinen"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huono tuki"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvä tuki"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunteikas"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tili"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute tukihenkilölle"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitus"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikkuviestit"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongelmaa ei ratkaistu"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongelma ratkaistu"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivinen asenne"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huono lopputulos"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiivinen asenne"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehu"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huono tuote"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvitys"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nopea tuki"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidas tuki"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA allokoi ", _interpolate(_list(0)), "-tietosi automaattisesti ennalta määritettyihin kategorioihin. Näin voit ymmärtää monimutkaisia merkityksellisiä tietoja ja tutkia niiden syitä ilman, että sinun täytyisi seuloa kirjallista palautetta manuaalisesti."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosanat"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kyselyt"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vastauksen kieli"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin koko"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kommentin koko"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koska 25 % kommenteista koostuu vain yhdestä sanasta, pidemmät kommentit ovat hyödyllisempää luettavaa. Löydät lisää oivaltavaa palautetta ilman manuaalista työtä, sillä me teemme sen automaattisesti puolestasi."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen syy"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse palautteen syy"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaavia arvosanoja"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennustetut osatekijät"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kommentin kategoria"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanapilven sanat"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse sanapilven sanat"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " viime ajanjaksosta lähtien"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " keskustelua"]), _normalize([_interpolate(_named("n")), " keskustelu"]), _normalize([_interpolate(_named("n")), " keskustelua"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyiden lähetyssuhdetta ei voida laskea kaikista keskusteluista datan puutteen vuoksi."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaukset"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetyt kyselyt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn suppilo"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ennustetut ", _interpolate(_named("surveyType")), "-osatekijät"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA allokoi ", _interpolate(_list(0)), "-tietosi automaattisesti ennalta määritettyihin kategorioihin. Näin voit ymmärtää monimutkaisia merkityksellisiä tietoja ja tutkia niiden syitä ilman, että sinun täytyisi seuloa kirjallista palautetta manuaalisesti."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin koko"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koska 25 % kommenteista koostuu vain yhdestä sanasta, pidemmät kommentit ovat hyödyllisempää luettavaa. Löydät lisää oivaltavaa palautetta ilman manuaalista työtä, sillä me teemme sen automaattisesti puolestasi."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aika"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn vastaukset"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (keskiarvo)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausten määrä"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana)"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana) (keskiarvo)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvo"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-trendit, ehtona"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausten määrä"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielet"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syyt"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteet"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osatekijät"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-kyselyn lähetyspäivä"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-kyselyn vastauspäivä"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delegoi ", _interpolate(_list(0)), "-arvosana"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen näyttämisen peruste"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun luontipäivä"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiketin omistajalle"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tukihenkilölle, jolla on eniten vastauksia"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (keskiarvo)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ajan myötä"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausten määrä"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana)"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ja SLA (sisäinen laatuarvosana)"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausten määrä"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut, jotka sisältävät nämä sanat:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanapilvi"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT käyttäjien mukaan"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT:n yleiskatsaus"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn suppilo"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn vastaukset"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteet"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT ajanjaksolla"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanapilvi"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kanavaa"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lähdettä"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kieltä"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei syytä"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tunnisteita"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kommenttikategoriaa"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei työtilaa"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataa ei ole saatavilla, koska delegointeja ei ole luotu tai jaksoja ei ole aloitettu. Palaa takaisin myöhemmin tai säädä suodattimia."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutustu uutuuksiin"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " kohde"]), _normalize([_interpolate(_named("count")), " kohdetta"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " keskustelu"]), _normalize([_interpolate(_named("count")), " keskustelua"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätty"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty pienine virheineen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty/hylätty"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvo"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvo (h)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvo (min)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " arviointia"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen CSAT = [palautteen arvosanojen summa ÷ (kaikkien vastausten määrä × enimmäispistemäärä)] × 100 %"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA:n CSAT-arvosana normalisoi palautteesi arvosanan asteikolla 0–100 % ja laskee standardoitujen arvosanojen keskiarvon."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (Customer Satisfaction Score) ilmaisee, kuinka tyytyväisiä asiakkaasi ovat palveluihisi."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Jokainen arvostelu edustaa yhden henkilön arvostelua koskien yhtä keskustelua. Keskustelulla voi olla useampi kuin yksi arvostelu (jos useampi arvostelija arvostelee saman keskustelun)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraa arvostelijan arvosanojen kehitystä ajan myötä."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraa tukihenkilöiden arvosanojen kehitystä ajan myötä."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " vastausta"]), _normalize([_interpolate(_named("n")), " vastaus"]), _normalize([_interpolate(_named("n")), " vastausta"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " arvostelua"]), _normalize([_interpolate(_named("n")), " arvostelu"]), _normalize([_interpolate(_named("n")), " arvostelua"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana) on keskusteluidesi arvosteluiden tulos."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana) = arvostelujen arvosanojen summa ÷ arvostelujen lukumäärä"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se lasketaan keskiarvona, jossa otetaan huomioon kaikkien valittuja suodatinehtoja vastaavien arvosteluiden arvosanat (kategorian arvosanan painotettu keskiarvo)."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortin lataaminen epäonnistui."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta suodattimiasi ja yritä uudelleen."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läpäisyprosentti osoittaa keskustelujesi arvosteluiden tuloksen."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hylätty, jos arvostelun arvosana on ", _interpolate(_named("failScore")), " tai pienempi"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hyväksytty, jos arvostelun arvosana on ", _interpolate(_named("passScore")), " tai suurempi"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se määräytyy ennalta määritetyn perustason perusteella, mikä tarkoittaa, että:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läpäisyprosentti = läpäisseiden arvostelujen määrä ÷ arvostelujen kokonaismäärä"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läpäisyprosentti osoittaa, kuinka monta prosenttia arvosteluista saa hyväksytyn arvosanan epäonnistumatta kriittisissä tai ei-kriittisissä kategorioissa."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos arvostelija antaa esimerkiksi kolme arvostelua tänään, näytetty numero on näiden kolmen arvosanan keskiarvo. Katso ", _interpolate(_named("link")), ", kuinka kukin solu lasketaan."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos tukihenkilö saa esimerkiksi kolme arvostelua tänään, näytetty numero on näiden kolmen arvosanan keskiarvo. Katso ", _interpolate(_named("link")), ", kuinka kukin solu lasketaan."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei otettu huomioon: kategorian paino, kriittinen tila (toisin kuin arvosanat jollain aikajaksolla)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koska kategoriat voidaan merkitä arvosteluissa soveltumattomiksi, arvostelujen määrä per kategoria voi olla pienempi kuin arvostelijan arvostelujen kokonaismäärä. Katso, kuinka kukin solu lasketaan"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei otettu huomioon: kategorian paino, kriittinen tila (toisin kuin arvosanat jollain aikajaksolla)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koska kategoriat voidaan merkitä arvosteluissa soveltumattomiksi, arvostelujen määrä per kategoria voi olla pienempi kuin arvostelujen kokonaismäärä per tukihenkilö. Katso, kuinka kukin solu lasketaan"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei otettu huomioon: kategorian paino, kriittinen tila (toisin kuin arvosanat jollain aikajaksolla)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategoriaryhmät näytetään erikseen. Kunkin ryhmän arvosana lasketaan kyseisen ryhmän keskiarvoisen kategoria-arvosanan perusteella samalta ajanjaksolta. Katso ", _interpolate(_named("link")), ", kuinka kukin solu lasketaan."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomautus:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei otettu huomioon: kategorian paino, kriittinen tila (toisin kuin arvosanat jollain aikajaksolla)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Kategoriaryhmät näytetään erikseen. Kunkin ryhmän arvosana lasketaan kyseisen ryhmän keskiarvoisen kategoria-arvosanan perusteella samalta ajanjaksolta. Katso ", _interpolate(_named("link")), ", kuinka kukin solu lasketaan."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Perustaso ", _interpolate(_named("count")), " %"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kriittisiä virheitä"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kriittisiä virheitä -arvo ilmaisee, kuinka monta prosenttia arvosteluista saa hyväksytyn arvosanan epäonnistumatta missään kriittisessä kategoriassa."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäinen laatuarvosana"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut juurisyyt"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaksoittainen muutos"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittäinen muutos"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittainen muutos"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikoittainen muutos"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosittainen muutos"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat ajan myötä"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkastele joko päivän tai viikon mukaan."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutoimitus = päivän tai viikon kokonaisarvosana jaettuna annettujen arvostelujen kokonaismäärällä"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkastele joko päivän tai viikon mukaan."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutoimitus = päivän tai viikon kokonaisarvosana jaettuna vastaanotettujen arvostelujen kokonaismäärällä"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat kategorian mukaan"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä arvostelijan kaikkien tämän ajanjakson aikana antamien kategoria-arviointien yhdistetty keskiarvo."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikkien kategoria-arviointien yhdistetty keskiarvo tukihenkilöä kohden tältä ajanjaksolta."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alhaisten arviointien juurisyyt"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointien syyt"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorian arvosanat ajanjaksolla"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, kuinka kategoriat vertautuvat toisiinsa ajan myötä."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkastele kullekin kategorialle annettuja keskiarvosanoja valitun päivän tai viikon ajalta."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, kuinka kategoriat vertautuvat toisiinsa ajan myötä."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kustakin kategoriasta saadut keskiarvosanat valitun päivän tai viikon ajalta."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat arvosteluiden mukaan"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoitu"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriittinen kategoria"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointiasteikko"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvo/yhteensä"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä itsearvioinnit pois"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie kaikki kortit"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["keskustelu"]), _normalize(["keskustelua"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kohde"]), _normalize(["kohdetta"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " vastausta"]), _normalize([_interpolate(_named("n")), " vastaus"]), _normalize([_interpolate(_named("n")), " vastausta"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " arvostelua"]), _normalize([_interpolate(_named("n")), " arvostelu"]), _normalize([_interpolate(_named("n")), " arvostelua"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Näytä yksi muu"]), _normalize(["Näytä ", _interpolate(_named("n")), " muuta"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeiset 30 päivää"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeiset 7 päivää"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viime kuukausi"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viime viikko"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kuukausi"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä viikko"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu ajanjakso"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivät"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukaudet"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosineljännekset"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikot"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuodet"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortin lataaminen epäonnistui. Muuta suodattimiasi ja yritä uudelleen."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä tietoja."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittainen muutos"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallintapaneelit"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätietoja"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset suodattimesi sisältävät tietoja eri arviointiasteikoista."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki korttien kiinnitykset, joissa on nämä suodattimet"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki hallintapaneelien kiinnitykset, joissa on nämä suodattimet"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen arvosteluaika"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukauta"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kategorioita"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioiden merkitykselliset tiedot"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaavio"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kommentti"]), _normalize(["Kommentit"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matriisi"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entinen jäsen"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäinen laatuarvosana"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana) (keskiarvo)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt arvostelut"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä kortti"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä hallintapaneeli"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioinnit"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Juurisyy"]), _normalize(["Juurisyyt"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun tunnus"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun aika"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltava"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä juurisyyt"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyyt kertovat, miksi keskustelut saavat alhaisia arvosanoja"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tälle kategorialle ei ole määritetty juurisyitä"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana/yhteensä"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoriat"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoriaryhmät"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriaryhmä"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtila"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukauta järjestystä"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioiden ryhmitysehto"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kaikki"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukauta"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä itsearvioinnit pois"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisällytä itsearvioinnit mukaan"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vain itsearvioinnit"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyhjennä suodattimet"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteita ei löytynyt"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenttien aihetunnisteet"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kommenttien aihetunnisteet"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteita ei löytynyt"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteet"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae tukipalvelun tunnisteita"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortteja ei löytynyt"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvosanakortit"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaavia yhteyksiä"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lähde"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lähde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enemmän suodattimia"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaavaa työtilaa"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montako kertaa annettu yhteensä"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki käyttäjät"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki ryhmät"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaavia ryhmiä."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki ryhmät"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kortit"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen näyttämisen peruste"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun luontipäivä"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun luontipäivä"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaksi desimaalia"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä asetukset"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilat"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko tili"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan nimi"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei työtiloja"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liput"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruoka ja juoma"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eläimet ja luonto"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektit"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihmiset ja keho"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkailu ja paikat"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usein käytetty"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakutulokset"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hymiöt"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbolit"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojeja ei löytynyt"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa keskustelu"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin tehtäväluetteloon"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " tai selaa ", _interpolate(_named("link")), " luetteloasi"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käytät tällä hetkellä työtilaa ", _interpolate(_named("workspace")), ", jota ei ole yhdistetty tähän toimialueeseen. Yhdistä se nyt napsauttamalla tästä."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["käytettävissä olevien yhteyksien"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Et voi vielä antaa palautetta ", _interpolate(_named("host")), "-palvelussa."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pidä tukipalvelusi aktiivisena välilehtenä."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimialuetta ei voi vielä arvostella. Jos näin ei pitäisi olla, ilmoita asiasta järjestelmänvalvojalle."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tämä toimialue"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kirjaudu ", _interpolate(_named("host")), "-tilillä"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki – valmistaudutaan"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-osoitetta ei voitu noutaa"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työkalut, jotka on jo yhdistetty organisaation Zendesk QA -tiliin."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisaation Zendesk QA -tiliin ei ole yhdistetty työkaluja."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sallii sinun arvostella verkkosivuja, joiden URL-osoite on aina sama, lisäämällä niiden loppuun yksilöllisen tiivisteen ennen arvostelun jättämistä"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda tiliä"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimialueesi"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimialueet, jotka on jo yhdistetty tähän työtilaan."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit arvostella kaikkia seuraavien toimialueiden sivuja"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltavissa olevat toimialueet"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laajennus"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksilöllinen URL-osoite"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tukipalvelua ei ole vielä yhdistetty – avaa ", _interpolate(_named("link")), " lisätäksesi sellaisen."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA -selainlaajennusta voidaan käyttää vain\nProfessional- ja Advanced-tilauksissa."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda työtilaa"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna nimi"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna arvo"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautetut kentät"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvo"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä on ensimmäinen kerta, kun törmäämme tähän keskusteluun. Anna joitakin tietoja."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun viite:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotain, josta tiketin voi tunnistaa myöhemmin"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvitys"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna sekä käsittelijä että aihe"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luodaan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele tämä keskustelu"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoimaton"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ota yhteyttä esihenkilöösi tai vaihda toiseen työtilaan ", _interpolate(_named("settings")), "-sivulta."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit on poistettu käytöstä tässä työtilassa."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ulos"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkemiin"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtila"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä URL-osoitetta ei voi arvostella. Siirry keskusteluun."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ensin Zendesk QA:han SSO-palveluntarjoajasi kautta ja yritä sitten uudelleen."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu SSO-kertakirjautumisella"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avataan sisäänkirjautumisen ponnahdusikkunaa."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään Zendesk QA -tililläsi"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennetaan..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eikö sinulla ole vielä tiliä?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole tämän keskustelun käyttöoikeutta. Ota tarvittaessa yhteyttä esihenkilöösi."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei käyttöoikeutta"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista, että yhteytesi on aktivoitu Zendesk QA:ssa"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse yhteyksiäsi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteysvirhe"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka siirtymällä keskusteluun tukipalvelussa"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ei kelpaa"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden palautus on melkein valmis."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile päivittää sivu päästäksesi takaisin ajan tasalle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotain meni pieleen"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vapauta itsesi tukipalvelun virtuaalisista rajoista ja arvostele keskusteluja missä tahansa."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä tilauksesi käyttääksesi laajennusta"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse tilaustasi"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun yhteyttä ei ole linkitetty tähän työtilaan"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelusi ei tunnista tätä tikettiä tai Zendesk QA:lla ei ole sen käyttöoikeutta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelua ei löytynyt"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi aktiviteetti"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saanut palautetta"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen onnistui"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit nyt käyttää sovellusta"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioi kaikki kategoriat"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä tätä lisätäksesi tapauksia kirjanmerkkeihin ja suodattaaksesi niitä myöhemmin"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei palautetta tälle keskustelulle"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue palautetta"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa Zendesk QA:ssa"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki palaute ja ilmoitukset näytetään tässä. Ei vielä mitään…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso lisätietoja Zendeskin QA:sta"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelu"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tiedot"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisältää kaikki"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on kaikki"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei sisällä kaikkia"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole kaikki"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paremmin kuin tukihenkilön"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huonompi kuin tukihenkilön"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["epätosi"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on suurempi kuin"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisältää minkä tahansa"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on mikä tahansa"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei sisällä mitään"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole mikään"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tosi"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rikottu"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentoitu"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minun kommentoimani"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole kommentoinut"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei minun kommentoimani"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaatii arvostelun kriittisesti"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisältää"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeiset 30 päivää"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["välillä"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alkaen"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeiset 14 päivää"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeiset 24 tuntia"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeiset 30 päivää"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeiset 7 päivää"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viime kuussa"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viime viikolla"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tässä kuussa"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tällä viikolla"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asti"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tänään"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eilen"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["havaittu"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on olemassa"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tähdellä merkitsemäni"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei tähdellä merkitsemäni"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisältää"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suurempi kuin"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alkaen"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pienempi kuin"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asti"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negatiivinen"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei rikottu"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei monimutkainen"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei havaittu"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole olemassa"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei sisällä"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei vastaanotettu"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei tarkasteltu"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positiivinen"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei arvosteltu"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei minun arvostelemani"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvosteltu"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minun arvostelemani"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vastaanotettu"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaatii arvostelun kriittisesti"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisältää"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suurempi kuin"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alkaen"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pienempi kuin"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asti"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei sisällä"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarkasteltu"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommentissani on vastauksia"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kyllä"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautetut kategoriat"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu korostus"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae ehtoa"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelu"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedot"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittarit"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistujat"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelu ja palaute"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki ehdot"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertaa bottisi keskustelujen käsittelyä keskivertotukihenkilöihin"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botti on jumissa silmukassa ja toistaa samaa viestiä peräkkäisinä vuoroina"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottivastausten lukumäärä keskusteluissa"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa mukana oleva bottityyppi"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botti mukana keskustelussa"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Keskustelun sisältö sisältää lauseen ", _interpolate(_list(0)), " tukihenkilöiden viesteissä"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnista automaattisesti puhelut, joista puuttuu pakollinen tiedonanto, kuten ”Tämä puhelu nauhoitetaan”."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun osallistuva henkilö tai botti"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö tai botti, joka arvostellaan keskustelussa"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas pyysi apua korkeammalta tasolta"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa havaittu positiivinen ja negatiivinen asenne"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnistaa keskustelujen aktiivisimman tukihenkilön tekoälyanalyysin avulla"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, lopettiko tukihenkilö keskustelun kohteliaasti"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, ymmärtääkö ja tunnustaako tukihenkilö asiakkaiden tunteet"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön kielioppi-, kirjoitus- ja tyylivirheet"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelusta tyypillisiä tervehdyksiä"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, ymmärtääkö tukihenkilö asiakkaan kyselyn tai huolenaiheen"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, kuinka helppo tekstiä on ymmärtää, ottaen huomioon sanojen monimutkaisuuden ja lauseiden pituuden"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelun tarjotun ratkaisun tunnistamiseksi"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön äänensävyn koko keskustelun ajalta"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkkejä asiakaskadosta. Asiakas harkitsi vaihtoa tai lupasi lopettaa asiakkuutensa."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmä, johon keskustelu kuuluu."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön viestin sisältö tai teksti"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilöiden vastausten määrä keskusteluissa"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö, joka on delegoitu arvostelemaan keskustelu"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelimitse käydyn keskustelun kesto sekunneissa"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun sisältö tai teksti"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa käytetty viestintäkanava (esim. sähköposti tai chat)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun lisätyt kommentit"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskusteluun lisättiin kommentti"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa havaittu positiivinen ja negatiivinen asenne"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin asiakas vastasi asiakastyytyväisyyskyselyyn"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko keskustelun arvostelu kiistautettu"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanava, jota asiakas käytti keskustelun aloittamiseen"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaiden vastausten määrä keskusteluissa"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun viimeisen viestin päivämäärä"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun osallistuneen käyttäjän rooli tai tyyppi"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön ja asiakkaan välinen hiljaisuus ylitti kynnysarvon"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas pyysi apua ylemmältä tasolta"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas ilmaisi olevansa äärimmäisen kiitollinen tai erittäin tyytyväinen saamaansa tukeen"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas tai tukihenkilö pyysi nimenomaisesti jatkotoimenpiteitä"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko keskustelun transkriptio tai tallenne"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko keskustelu merkitty tähdellä"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteet, joita käytetään kommenteissa sisällön luokittelemiseen"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälyn havaitsema keskustelun kieli"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun viimeisen viestin päivämäärä"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityisten huomautusten määrä keskusteluissa"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkkien keskiarvoinen määrä per viesti keskusteluissa"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaille osoitettujen vastausten määrä keskusteluissa"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistuja, jonka tiedot ovat julkisesti näkyvissä"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanojen keskiarvoinen määrä per viesti keskusteluissa"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun arvosteluun tai arvioimiseen käytetyt kategoriat"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun arvosteluprosessin tila"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistuja tai henkilö, joka on mukana keskustelussa"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun liitetty sähköpostiosoite"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko arvostelu luettu"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija, joka arvioi keskustelun"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu arvosteltiin viimeksi"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö, jota arvostellaan keskustelussa"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan antamat erityiset kommentit tai palaute koskien hänen tyytyväisyyttään keskusteluun"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana, joka kuvaa asiakkaan tyytyväisyyttä keskusteluun"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana, joka kuvaa asiakkaan tyytyväisyyttä keskusteluun viestikohtaisesti"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti, jota käytetään keskustelun arvostelemiseen"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annettuja SLA-sääntöjä on rikottu"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lähteen tai yhteyden tyyppi"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu oli epätavallista tai epätyypillistä, ja ratkaisun löytäminen edellytti tavallista enemmän vuoropuhelua"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn kysymys liittyi keskustelun sisältöön"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun liittyvän kyselyn otsikko tai nimi"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö, joka on delegoitu arvostelemaan keskustelu"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun liittyvä brändi tai tietty tuote/palvelu"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa käytetty viestintäkanava (esim. sähköposti tai chat)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu suljettiin tai ratkaistiin"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu luotiin alun perin"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu luotiin alun perin"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin asiakas vastasi asiakastyytyväisyyskyselyyn"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lyhyt kuvaus tai yhteenveto"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoinen viitetunnus, joka on linkitetty keskusteluun"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansio tai tietty ryhmittely, johon keskustelu on organisoitu"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomake tai malli, jota käytetään keskustelun jäsentämiseen"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoinen viitetunnus, joka on linkitetty keskusteluun"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postilaatikko tai sähköpostitili, jonka kautta keskustelu vastaanotettiin"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun liitetty puhelinnumero"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelulle määritetty prioriteettitaso"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa olevien vastausten kokonaismäärä"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tila tai nykyinen tila"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun nykyinen tila"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun aihe"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilöiden vastausten määrä keskusteluissa"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun kategorisointia varten kohdistetut tunnisteet"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tyyppi tai kategoria"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu päivitettiin viimeksi"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä, jolloin keskustelu päivitettiin viimeksi"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alusta tai kanava, jonka kautta keskustelu käytiin"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun transkription sisältö"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut, joita ei tulisi arvostella tekoälyanalyysin perusteella"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun osallistuneiden tukihenkilöiden määrä"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onko keskustelu luettu"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikä tahansa viesti"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiviestinnän tehokkuus"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botin toisto"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottivastausten määrä"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottityyppi"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botti"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelun suunta"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan viesti"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiljaisuutta"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauhoitusta koskeva ilmoitus puuttuu"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalointi"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelun transkription sisältö"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopetus"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tähdellä merkityt"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arvosteltavissa"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lähde"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikeinkirjoitus ja kielioppi"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön viesti"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivisin tukihenkilö"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön vastausten määrä"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelun kesto (sek)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun sisältö"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestintäkanava"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin tunniste"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin päivämäärä"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenne"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kyselyn vastauspäivä"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiista"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakaskanava"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan vastausten määrä"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen vastauspäivä"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjätyyppi"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihtuvuusriski"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiljaisuutta"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eskalointi"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeuksellinen palvelu"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkotoimet"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkriptio"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin aihetunniste"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeava arvo"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrys"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen vastauspäivä"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun yksityisten huomautusten määrä"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkkien keskiarvoinen määrä"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanojen keskiarvoinen määrä"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkisten vastausten määrä"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkinen osallistuja"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointipäivä"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointi ja kommentti"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelun tila"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luettavuus"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistuja"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluun liittyvä sähköpostiosoite"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitetty"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastausten määrä"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotettu arvostelu"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltu arvosanakortti"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelu luettu"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelupäivä"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltava"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn kommentti"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestikohtainen CSAT"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyytyväisyysarvosana (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjottu ratkaisu"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähteen tyyppi"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihe"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn kysymys"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn otsikko"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun brändi"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brändin toimialue"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelukanava"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemispäivä"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luontipäivä"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luontipäivä"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kyselyn vastauspäivä"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kyselyn lähetyspäivä"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun kuvaus"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoinen tunnus"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun kenttä"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun kansio"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun lomake"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-tunnus"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluryhmä"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout -tunnus"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoinen tunnus"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-tunnus"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun postilaatikko"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelinnumero"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun prioriteetti"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun vastausten määrä"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tila"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tila"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun aihe"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön vastausten määrä"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunniste"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunniste"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun tyyppi"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivityksen päivämäärä"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivityksen päivämäärä"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-tunnus"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äänensävy"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transkription sisältö"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistuneiden tukihenkilöiden määrä"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkastelun tila"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöongelma"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimintarajoitteisuus"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimintaa rajoittava elämäntapahtuma"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategoria"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse päivämäärään liittyvä ehto"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää suodattimellesi nimi"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna suodattimen nimi"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautunut henkilö"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiset työtilan jäsenet"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan jäsenet"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lisää ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä suodatinta"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla tulisi olla vähintään yksi päivämäärään liittyvä suodatin"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vaihtoehdot"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vaihtoehto"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy ", _interpolate(_named("n")), " päivän kuluttua. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"> <strong> ", _interpolate(_named("n")), " päivän kuluttua</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy ", _interpolate(_named("n")), " päivän kuluttua. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"> <strong> ", _interpolate(_named("n")), " päivän kuluttua </strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy tänään. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilusi päättyy <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>tänään </strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tee tilaus nyt"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " päivää"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilustasi on jäljellä ", _interpolate(_named("days"))])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olet saavuttanut tilauksesi käyttäjärajoituksen. ", _interpolate(_named("link")), " saadaksesi lisätietoja tai päivittääksesi tilauksesi."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napsauta tästä"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yhdistä tukipalvelusi"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä sallii meidän noutaa tikettisi, jotta voit tarkastaa ne."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä tukipalvelusi"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käytät Zendesk QA:ta tällä hetkellä esimerkkidatan kanssa. Jos haluat poistaa esimerkkidatan ja tuoda omat keskustelusi, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimerkkidata"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi hyödylliset tiedot alla"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota koko sivun kokoinen kuvakaappaus kontekstin lisäämiseksi"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota yhteyttä asiakastukitiimiimme tämän ongelman ratkaisemiseksi:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotain meni pieleen"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheen viite:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi hyödylliset tiedot"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät, botit ja työtilat"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät ja työtilat"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tänään"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tässä kuussa"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tällä viikolla"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " arvosteltu ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " arvosteltu ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " arvosteltu ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikä on uutta?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ulos"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikanäppäimet"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chattaa kanssamme"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chattia ei voitu ladata (käytätkö kenties mainosestoa?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry kohtaan..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisö"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallintapaneeli"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohje ja tuki"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotisivu"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut arvostelut"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratkaisutietokanta"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda tiliä"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävät"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sovellus päivitettiin ", _interpolate(_named("buttonStart")), "Lataa uudelleen", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat aloittaa Zendesk QA:n käytön osana työtilaasi:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Halusimme vain kertoa, että olet jo saanut kutsun käyttää Zendesk QA:ta osana työtilaa. Voit tietenkin jatkaa halutessasi, mutta huomaa, että tämä luo sinulle ", _interpolate(_named("bold")), "."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erillisen organisaation"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kyllä, jatkan ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uudella tilillä"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " pyytää sinua liittymään ", _interpolate(_named("account")), " -palveluun"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liity"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei Internet-yhteyttä"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi kiinnitys"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikanavigointi"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskitä ja laajenna kommenttieditori"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota tai näytä sivupaneeli"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry seuraavaan keskusteluun"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa alkuperäinen keskustelu"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry edelliseen keskusteluun"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kaikille kategorioille korkein mahdollinen arvosana"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna kaikille kategorioille alhaisin mahdollinen arvosana"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekoita keskustelut (satunnainen uudelleenlajittelu)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerro meille"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikanäppäimet"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikavalintojen käyttöönoton modaali"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olet saavuttanut ", _interpolate(_named("charLimit")), " merkin rajoituksen"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "-kuvatyyppi (", _interpolate(_named("type")), ") ei ole sallittu"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kuvan ", _interpolate(_named("name")), " koko on liian suuri (5 Mt sallittu)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenttieditorin lataaminen epäonnistui"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä pudottamalla"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teksti"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse malleja"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisättyjä malleja"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää juurisyy"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut, mukaan lukien poistetut syyt"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioinnin syy"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maa"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etunimi"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työntekijöiden määrä"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelinnumero"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otamme sinuun yhteyttä auttaaksemme sinua tilin määrittämisessä"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen nimi"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen puhelinnumero"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mistä kuulit ensimmäisen kerran Zendesk QA:sta?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kanava"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tukihenkilöiden määrä"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täytä kaikki pakolliset kentät"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun täytyy lukea ja hyväksyä ehtomme ja tietosuojakäytäntömme."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipohjainen Slack-kanava"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainonta"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ystävä tai kollega"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun sovelluskauppa tai tilin ylläpitäjä"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viittaava sivusto"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakukone (Google, Bing jne.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosiaalinen media"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerro meille itsestäsi ja yrityksestäsi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin tiedot"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen valmis."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutki ympäristöäsi rauhassa, tutustu sovellukseen ja poista ja lisää juttuja."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tili luotu. Mitä seuraavaksi?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko valmis tuomaan todellisia keskusteluja?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää omaa dataasi"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita selaaminen"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile sitä esimerkkidatan avulla"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotta voimme noutaa tikettejä sinulle tarkastettavaksi."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä tukipalvelutilisi"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutin mieleni."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta rekisteröityminen"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kiinnitys luotu ", _interpolate(_named("buttonStart")), "Siirry kiinnityksiin", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusmuistiot ovat käytettävissä Professional-tilauksella."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä tilaus"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki tilaukset"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna muistiinpanosi valmistautuaksesi seuraavaan henkilökohtaiseen istuntoon tai valmennusistuntoon."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusmuistiot"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentoi tai lisää muita ", "@", "maininnalla"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä keskustelu on poistettu"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi kiinnitys"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kiinnitys"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa kiinnitystä"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka kiinnitykset toimivat"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole oikeutta nähdä tätä keskustelua"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä kortti"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä keskustelu"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä hallintapaneeli"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitys tallennettu"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä tämä kortti"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä tämä keskustelu"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä tämä hallintapaneeli"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä käyttäjän alle"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjä"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain sinä näet tekemäsi kiinnitykset. Voit jakaa ne muille ", "@", "mainitsemalla heidät kommenteissa."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " on nyt kutsuttu tähän keskusteluun"]), _normalize([_interpolate(_named("agentsNames")), " on nyt kutsuttu tähän keskusteluun"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 kommentti"]), _normalize([_interpolate(_named("n")), " kommenttia"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mainitsit ", _interpolate(_named("participants")), ", mutta hän ei ole tässä keskustelussa."]), _normalize(["Mainitsit ", _interpolate(_named("participants")), ", mutta he eivät ole tässä keskustelussa."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " osallistujaa"]), _normalize([_interpolate(_named("n")), " osallistujan"]), _normalize([_interpolate(_named("n")), " osallistujaa"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko estää käyttäjää ", _interpolate(_named("agentName")), " käyttämästä tätä kiinnitystä?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä tee mitään"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä kutsu heitä tähän keskusteluun"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kommentti"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu heidät"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu heidät tähän keskusteluun"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjä"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketju"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä ei voi kumota."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa myös kaikki tähän kiinnitykseen sisältyvät kommentit."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " uutta viestiä"]), _normalize([_interpolate(_named("n")), " uusi viesti"]), _normalize([_interpolate(_named("n")), " uutta viestiä"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain sinä näet kiinnityksesi. Jos haluat jakaa kiinnityksiä, ", "@", "mainitse joku kommenteissa."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " kiinnitti ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hallintapaneelin"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskustelun"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huomautuksen"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinä"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä käyttääksesi valmennusmuistioita"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusmuistiot ovat käytettävissä Professional-tilauksella."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusmuistiot ovat käytettävissä Professional-tilauksella"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä käyttääksesi ominaisuutta"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmistaudu henkilökohtaisiin istuntoihin tai tallenna asioita myöhempää käyttöä varten."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa kiinnityksen ja kaikki lisätyt kommentit pysyvästi."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kiinnitys"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kiinnitys"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansion nimi"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä asioita, jotta voit palata niihin myöhemmin"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaa"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusin ensin"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhin ensin"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna mielenkiintoinen hallintapaneelin trendi tai keskustelu valmistautuaksesi henkilökohtaisiin istuntoihin tai merkitäksesi ne muistiin myöhempää varten."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kiinnitys napsauttamalla yllä olevaa painiketta tai nastan kuvaketta keskustelun tai hallintapaneelin näkymästä."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitä"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei luotuja kiinnityksiä"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ei tuloksia haulle ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile toisia avainsanoja tai korjaa kirjoitusvirheet."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi kiinnitys"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestit"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihmiset"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae käyttäjän tai avainsanan mukaan..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aakkosjärjestyksessä"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeksi luotu"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeksi muokattu"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eniten kiinnityksiä"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Pyrin tarkastamaan ", _interpolate(_named("n")), " keskustelun ", _interpolate(_named("schedule"))]), _normalize(["Pyrin tarkastamaan ", _interpolate(_named("n")), " keskustelua ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite päivitetty"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta tavoite"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit asettaa henkilökohtaisen tavoitteesi arvosteltavien keskusteluiden määrälle."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joka päivä"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joka kuukausi"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joka viikko"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelutavoite"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HENKILÖKOHTAINEN"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallit"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan kanavia"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kanava"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa vain kommentteja sisältävä kyselypalaute"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehu työtovereitasi korostamalla heidän poikkeuksellisia palvelukykyjään ja erinomaista asiakaspalautetta."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa positiivista kyselypalautetta Slackiin"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavia ei löytynyt"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa palaute automaattisesti, kun arvosanat ovat yli"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praisebot-integroinnit"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota muistutus viikon alussa, jos et ole tehnyt yhtään arvostelua."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muistutukset"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikoittainen muistutus arvosteluiden tekemisestä"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota ilmoituksia uusista arvosteluita, vastauksista, maininnoista ja kiistoista Slackissa."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maininnat"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioinnit"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viestiketjujen vastaukset"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integroinnit"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoita uusista arvosteluista, vastauksista, maininnoista ja kiistoista."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuun liittyvät kiistat"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maininnat kommenteissa"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudet vastaanotetut arvostelut"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaukset ketjuissa"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportti tilastoineen"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanota raportteja annetuista ja saaduista arvosteluista."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportit"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivittäin"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuukausittain"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viikoittain"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittäin"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 tunnin välein"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 tunnin välein"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välittömästi"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittain"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pois"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikoittain"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilisi järjestelmänvalvoja on lukinnut ilmoitusasetukset."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ajanjakso"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset ilmoitukset"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktiivinen)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi sosiaalinen kirjautumistapa tähän tiliin:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivisen kirjautumistavan poistaminen kirjaa sinut ulos. Voit kirjautua uudelleen sisään vaihtoehtoisella sähköpostiosoitteellasi."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kirjautumistapa"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googlen lisääminen kirjautumisvaihtoehdoksi onnistui"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjautumisvaihtoehdot"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack lisättiin onnistuneesti kirjautumisvaihtoehdoksi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo salasana"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetelmä"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa salasana"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähetämme sähköpostia osoitteeseen ", _interpolate(_named("email")), " salasanan nollaamista varten."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ensisijaista sähköpostiosoitettasi"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista vahvistussähköpostimme postilaatikostasi"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikalinkki"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat muuttaa sitä, muuta ensin ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sähköposti ", _interpolate(_named("email")), " liitetään tähän salasanaan."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiili päivitetty"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiilikuva"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat parhaat tulokset lähettämällä neliönmuotoisen valokuvan (vähintään 256 x 256 pikseliä)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensisijainen sähköpostiosoite"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikon alku"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset asetukset"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse vähintään yksi vaihtoehto"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallin sisältö"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallin nimi"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä malli kohteessa"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää mallin sisältöä"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää mallin nimi"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo malli"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo malli"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kommenttimalleja nopeuttaaksesi arvosteluiden tekemistä, kohteiden kiinnittämistä ja valmennusta."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missä käytetty"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista malli"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistaa tämän mallin pysyvästi?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa mallia"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kommenttimalleja nopeuttaaksesi arvosteluiden tekemistä, kohteiden kiinnittämistä ja valmennusta."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei luotuja malleja"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenttimalli luotu"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenttimalli poistettu"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommenttimalli tallennettu"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitykset"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut ja kiistat"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat käyttöoikeuden, ota yhteyttä ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asiakastukeen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiliä ei löytynyt"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käytä toistaiseksi kohdetta ", _interpolate(_named("link")), ", jos haluat käyttää Zendeskin QA:ta."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa Zendeskiin"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiliä ei siirretty"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisäiset laatuarvosanat"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittarit"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvosanat"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilastot"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallintapaneeli"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelut"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibrointi"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskustelut"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suodattimet"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelu"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omat kommenttini"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omat kiistani"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["omat arvosteluni"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktiot"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitettu"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselypalaute"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviteetti"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnitykset"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnot"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallit"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luo salasana"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tunnistetiedot"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sähköposti"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sähköposti"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["käyttäjätunnus"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nollaa"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelutavoite"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mallit"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["käyttäjä"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo malli"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjätunnus"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallit"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tili"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tili"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laskutus"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yritys"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelu"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integroinnit"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisaatio"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksu"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paikat"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asetukset"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tilaus"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennukset"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi kategoria"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löytyi ristiriitainen viesti"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda tai poista jokin ristiriitaisista viesteistä"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käytä kuvaketta ", _interpolate(_named("placeholder")), " jättääksesi pois arkaluontoisia tietoja AutoQA:sta. Voit esimerkiksi ”Näkemiin, Matti” -tervehdyksessä käyttää nimen sijalla paikkamerkkiä ”Näkemiin, ", _interpolate(_named("placeholder")), "”, joka edustaa mitä tahansa nimeä."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enintään ", _interpolate(_named("limit")), " riviä"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovella sääntöä näille:"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut 10 mukautetun kategorian rajan"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olet saavuttanut ", _interpolate(_named("limit")), " mukautetun kategorian rajan"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ehto"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää rivi"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ehto"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tukihenkilön viesti"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisteytä muussa tapauksessa"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niin pisteytä"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo arvosanakortin luokka"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä '/' muuttujan lisäämiseksi"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki suunnat"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön viesti ei voi olla tyhjä"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viesti voi olla enintään 300 merkkiä pitkä"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teksti"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki lähteet"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kanavat"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tunnisteet"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki keskustelut"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkenna, mihin sääntöäsi sovelletaan."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietyt keskustelut"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo Spotlight-tiedot"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön viesti ei voi olla tyhjä"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut 10 mukautetun tiedon rajan"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olet saavuttanut ", _interpolate(_named("limit")), " mukautetun tiedon rajan"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täytä tietojen nimi"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen nimi"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kategoria"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen tyyppi"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivinen"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutraali"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positiivinen"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry työtilan asetuksiin"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei nyt"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat aloittaa tämän arviointikategorian käytön, siirry työtilan arvosanakorttien asetuksiin ja lisää se valitsemaasi arvosanakorttiin."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria luotu"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisteytä suljetut keskustelut automaattisesti tunnistamalla tietyt avainsanat tai -lauseet."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei toimi transkriptioiden kanssa."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvä"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Läpyt! ✋ Olet saavuttanut automatisoitujen arviointikategorioiden enimmäismäärän (", _interpolate(_named("limit")), "). Olet oikea kategorisointivelho! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioiden rajoitus saavutettu"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista, että kaikki kentät on täytetty oikein"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna arviointikategoriallesi nimi"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorian nimi"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi arviointikategoria"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi arviointikategoria"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pisteytä suljetut keskustelut automaattisesti tunnistamalla tietyt avainsanat tai -lauseet. Ei toimi transkriptioiden kanssa."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuo suljetut keskustelut esiin automaattisesti tunnistamalla tietyt avainsanat tai lauseet. Toimii vain transkriptioihin."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele keskusteluja automaattisesti tunnistamalla tietyt avainsanat tai lauseet sekä tukihenkilön että botin viesteissä."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat käyttää tätä arviointikategoriaa, siirry työtilan arvosanakorttien asetuksiin ja lisää se arvosanakorttiin."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostele keskustelut automaattisesti tiettyjen viesteissä olevien avainsanojen tai lauseiden perusteella."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytettävissä näille"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkityksellisten tietojen määritys"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä tieto näkyy Spotlightissa määritettyjen ehtojen perusteella."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakorttien määritykset"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä kategoriaa voidaan käyttää arvosanakorteissa määritettyjen ehtojen perusteella."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["botti"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tulossa pian"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mukautettu"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ennalta määritetty"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvosanakortti"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korostus"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ääni"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna muutokset"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutokset tallennettu"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vihje:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napsauta ", "{··}", "-kuvaketta, jos haluat jättää nimet, yritystiedot tai muut arkaluontoiset tiedot pois AutoQA:n lopputarkistuksista."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää hyväksytty lopetus"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa hyväksyttyä lopetusta"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty lopetus"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää poikkeuksia"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa poikkeusta"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeus"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää hyväksytty tervehdys"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa hyväksyttyä tervehdystä"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty tervehdys"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä tähän sanoja rivinvaihdoilla erotettuina"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paina kunkin sanan jälkeen Enter lisätäksesi uuden merkinnän"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovellettava kieli"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit esimerkiksi käyttää ”Näkemiin, Matti” -tervehdyksen sijaan paikkamerkkiä ”Näkemiin, ", "{··}", "” edustamaan mitä tahansa nimeä."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynaamisen sisällön paikkamerkki"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeukset"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 poikkeus lisätty"]), _normalize([_interpolate(_named("count")), " poikkeusta lisätty"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Poikkeus on jo lisätty"]), _normalize(["Poikkeukset on jo lisätty: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää poikkeus"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeuksia ei löytynyt"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisättyjä poikkeuksia"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeus (merkkikokoriippuvainen)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lisää poikkeuksia"]), _normalize(["Lisää 1 poikkeus"]), _normalize(["Lisää ", _interpolate(_named("count")), " poikkeusta"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae sanaa tai lausetta"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poikkeus tallennettu"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kieli"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kielet"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae kieltä"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lopetus on jo lisätty"]), _normalize(["Lopetukset jo lisätty: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää lopetus"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyttyjä lopetuksia ei löytynyt"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hyväksyttyjä lopetuksia"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopetus"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää lopetus"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae lopetusta"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopetus lisätty"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopetus tallennettu"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lomakkeessa on identtisiä merkintöjä: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tervehdys on jo lisätty"]), _normalize(["Tervehdykset on jo lisätty: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tervehdys"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyttyjä tervehdyksiä ei löytynyt"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hyväksyttyjä tervehdyksiä"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tervehdys"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae tervehdystä"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys lisätty"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys tallennettu"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää poikkeus"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Poista poikkeus"]), _normalize(["Poista poikkeukset"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Haluatko poistaa tämän poikkeuksen pysyvästi?"]), _normalize(["Haluatko poistaa nämä poikkeukset pysyvästi?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa poikkeusta"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yksi tai useampi poikkeus on yli ", _interpolate(_list(0)), " merkkiä pitkä"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " merkkiä"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittänyt"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuttuja"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä omat lopetusviestisi, jotka ovat brändisi ja palvelustandardiesi mukaisia. Kun lopetuksia lisätään, AutoQA havaitsee vain hyväksytyt lopetukset. Tehtyjä muutoksia sovelletaan tuleviin keskusteluihin."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut 100 hyväksytyn lopetuksen ylärajan. Jos haluat lisätä niitä lisää, saattaa olla hyvä aika tarkastaa ja päivittää nykyiset lopetuksesi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyttyjen lopetusten rajoitus saavutettu"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt lopetukset"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanat ja lauseet, jotka haluat AutoQA:n ohittavan kirjoitus- tai kielioppivirheinä. Tehtyjä muutoksia sovelletaan tuleviin keskusteluihin."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohitettavien luettelo"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä omat tervehdyksesi, jotka ovat brändisi ja palvelustandardiesi mukaisia. Kun niitä lisätään, AutoQA tunnistaa vain hyväksytyt tervehdykset. Tehtyjä muutoksia sovelletaan tuleviin keskusteluihin."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut 100 hyväksytyn tervehdyksen ylärajan. Jos haluat lisätä niitä lisää, sinun kannattaa tarkastaa ja tarkentaa olemassa olevia tervehdyksesi."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyttyjen tervehdysten rajoitus saavutettu"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt tervehdykset"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei nyt"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kategoria"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistaa tämän arviointikategorian pysyvästi?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota kategorioiden luettelo"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu kategoria ei näy uusissa keskusteluissa ja suodattimissa. Hallintapaneelien ja keskustelujen historiallinen data säilyy ennallaan."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria poistetaan kaikista tuloskorteista, joihin se on lisätty."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuloskorttiluokan poistaminen epäonnistui"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuloskorttiluokka poistettu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista tuloskorttiluokka"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategoriat"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tekoälymalliin perustuvat ", _interpolate(_named("highlight")), " pysyvät arvosanakortissa, mutta niille ei enää automaattisesti anneta arvosanaa"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-integroinnin poistaminen käytöstä ei poista AutoQA-ominaisuutta kokonaan käytöstä. Tapahtuu seuraavanlaisesti:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historialliset tiedot"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " jäävät AutoQA:n hallintapaneeliin"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuaalisesti lisätyt juurisyyt"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " jäävät, mutta kaikki LLM-pohjaiset juurisyyt poistetaan"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-pohjaisen AutoQA:n poistaminen käytöstä"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käytöstä"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA käyttää tekoälyä ja suuria kielimalleja (LLM) antaakseen tietyille kategorioille arvosanan automaattisesti. Tietojasi käsitellään tiukan Tietojen käsittelysopimuksen (Data Processing Agreement (DPA)) mukaisesti, eikä niitä koskaan käytetä mallien kouluttamiseen."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää generatiivisesta tekoälystä"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA käyttää Microsoft Azure OpenAI -palvelua ", _interpolate(_named("automatically_scoring_categories")), ". Tietojasi käsitellään tiukkojen tietojenkäsittelysopimusten alaisina ainoastaan AutoQA-palvelun tarjoamiseksi sinulle, ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joidenkin kategorioiden automaattiseen pisteyttämiseen"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eikä niitä käytetä minkään OpenAI-mallin kouluttamiseen"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-pohjainen AutoQA käytössä"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-pohjainen AutoQA poistettu käytöstä"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-pohjainen AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattinen pisteytys tekoälyn avulla AutoQA:ssa"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure -pohjaisten AutoQA-kategorioiden tietojen käsittely"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure -suostumus peruutettu"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure -suostumus annettu. Keskustelut saavat automaattiset arvioinnit pian."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit edelleen käyttää AutoQA:ta, mutta silloin tapahtuu näin"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure OpenAI -palvelun poistaminen käytöstä ei poista AutoQA:ta käytöstä toimintona."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit tehdä sen pistekorttien kategorioiden näkymässä."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malliin perustuvat AutoQA-kategoriat pysyvät arvosanakortissa, mutta niitä ei pisteytetä automaattisesti."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuaalisesti lisäämäsi juurisyyt säilytetään. Kaikki mallin generoimat juurisyyt poistetaan."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiallinen data säilyy AutoQA-hallintapaneelissa."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista MS Azure OpenAI -palvelun tietojenkäsittely käytöstä"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laajempi valikoima AutoQA-kategorioita."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heti käyttövalmis monikielinen tuki."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nopeatempoinen uusien kategorioiden lisääminen."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointien tarkkuutta parannettu. GPT-3.5 osoittaa ihmistason kontekstuaalista tietoisuutta."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyödynnä tekoälyteknologian uusimpia innovaatioita."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat kaiken tämän"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita datan käsittely MS Azure OpenAI -palvelun avulla"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suostumus annettu"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suostumusta ei annettu"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki uudet käyttäjät on delegoitu tähän työtilaan"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletustyötila"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 tunnin kello (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 tunnin kello (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kellonajan muoto"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritysprofiili päivitetty"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikon alku"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu integrointi"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöoikeusavaimen tunnus ja salainen avain löytyvät yleensä kohdasta Käyttöoikeuksien hallinta > Käyttäjät"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS (Amazon Web Services) -käyttöoikeusavaimen tunnus"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta salaisen käyttöoikeusavaimen vaihtaminen"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta salaisen käyttöoikeusavaimen arvoa"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden avulla voidaan määrittää vain yksi alue, joka löytyy yleensä Sijainti-kohdasta"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS (Amazon Web Services) -alue"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää salainen avain"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salainen AWS (Amazon Web Services) -käyttöoikeusavain"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löydät kaikki tarvittavat tiedot Amazon Connectista."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne löytyvät yleensä Amazon Connect -esiintymän asetuksista ja Datan tallennustila -sivulta"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelunauhoitusten tallennussijainti"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui virhe lisättäessä Amazon Connectia. Tarkista, että kaikki kentät täytetty oikein, ja yritä uudelleen."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallennussijainnin on oltava muodossa säilö/kansio, joka on erotettu vinoviivalla."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat lisätä yhteyden, ota yhteyttä ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asiakastukeen"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki Zendesk-tiketit synkronoidaan automaattisesti. Voit jättää pois valitut tiketit."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasia ja Tyynenmeren alue (Tokio)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasia ja Tyynenmeren alue (Soul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasia ja Tyynenmeren alue (Osaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasia ja Tyynenmeren alue (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasia ja Tyynenmeren alue (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (keski)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurooppa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurooppa (Zurich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurooppa (Irlanti)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eurooppa (Lontoo)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelinalue"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähi-itä (UAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse palvelinalue"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etelä-Amerikka (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA, itä (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA, itä 2 (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA, länsi (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys on jo lisätty"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita yhteys"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronoi vain keskustelut, jotka"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanava yksi, kanava kaksi"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämän yhteyden yksilöllinen tunnus on ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kuukausi"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 vuosi"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 kuukautta"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 kuukautta"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei-aktiiviset keskustelut poistetaan valitun säilytysjakson jälkeen. Lähetetyt arvostelut näytetään edelleen aktiviteetti- ja hallintapaneelinäkymissä."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säilytysjakso"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse säilytysjakso"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että tätä toimintoa ei voi kumota."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrän, että tämä poistaa kaikki tukipalveluun liittyvät tiedot tämän yhteyden osalta Zendesk QA:sta, mukaan lukien keskustelut, kentät ja tunnisteet."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä ei vaikuta lähetettyihin arvosteluihin, jotka säilyvät tietyissä näkymissä."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ryhmä yksi, ryhmä kaksi"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisällytä"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kehitetään edelleen aktiivisesti"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tätä integrointia ", _interpolate(_named("activeDevelopment")), ". Joitakin tietoja saattaa puuttua (kuten liitteet tai mukautetut kentät)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postilaatikko yksi, postilaatikko kaksi"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tunniste yksi, tunniste kaksi"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-tunnus"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-tunnuksen salaisuus"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk: hae ”Tiketin kenttien nimet” kohdasta Asetukset → Tiketin kentät"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout: hae ”Custom Field Names” kohdasta Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustomer: hae ”Klass-keskusteluiden nimet” kohdasta Asetukset → Klasses → Keskustelu"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Agent: hae ”Mukautetun kentän nimi” kohdasta Asetukset → Mukautetut kentät"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wix: hae ”Custom Field Name” kohdasta Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erota kenttien tunnukset toisistaan pilkuilla. Löydät nämä tunnukset Zendeskissä kohdasta Järjestelmänvalvoja → Tikettien kentät."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä muutama tukipalvelusi käyttäjä arvostellaksesi ensimmäiset keskustelusi. Älä huoli, emme ilmoita heille, ellet kutsu heitä työtilan jäseniksi."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrointi onnistui"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää jäseniä"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitamme nyt keskusteluidesi noutamisen."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Toimialue: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tukipalvelun tunniste: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyttäsi ei voitu todentaa"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki valmista. Tämä on uusi yhteytesi."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joitakin yhteyksiä ei ole päivitetty tai ne eivät ole enää aktiivisia. Kokeile päivittää nämä yhteydet tai poista ne luettelostasi."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-asiakastunnus"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-asiakassalaisuus"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luo uusi API-tunnus kohdasta Asetukset → Tili → API ja SDK:t. Käytettävä uudelleenohjauksen URL-osoite on ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin tunnus"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita valitsemalla tukipalvelusi"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos Intercom-tilisi on määritetty tarjottavaksi EU:sta tai Australiasta, päivitä tämä arvo."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sähköposti, jolla kirjaudut ", _interpolate(_list(0)), "-palveluun"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peitä loppukäyttäjän nimi, sähköpostiosoite ja puhelinnumero. Pankkitunnukset peitetään jo kaikissa tukipalveluyhteyksissä."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä integrointeja. Lisää sellainen nyt."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarvitset nämä roolit:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo yhteys"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi yhteys"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos poistat tämän valinnan, voit lisätä tämän yhteyden manuaalisesti vain tiettyihin työtiloihin"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä kaikkiin olemassa oleviin työtiloihin"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilat"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-avain"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-avaimen salaisuus"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavat"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Yhteys ", _interpolate(_list(0)), " poistettu"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden nimi"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotu"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit napata API-tunnuksesi alta."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kynnysarvo"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista yhteys ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ”poista yhteys” poistaaksesi tämän yhteyden"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista yhteys"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse ja tarkkaile yhdistettyjä tiedonlähteitäsi."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa yhteyttä"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä pois"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erota kenttien nimet toisistaan pilkuilla"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitys epäonnistui"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna integroinnillesi yksilöllinen nimi"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota lisäasetukset"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota arkaluonteisia tietoja sisältävät kentät"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä koskaan tallenna liitteiden URL-osoitteita"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita liitteet"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä koskaan tallenna keskustelun sisältöä (voit noutaa sen myöhemmin tarvittaessa tukipalvelusta)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita keskustelun sisältö"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä koskaan tallenna keskustelun aihetta (voit noutaa sen myöhemmin tarvittaessa tukipalvelusta)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita aiherivi"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivinen"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-alitoimialueesi"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-alue"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täsmää postilaatikon tiimin tunnus"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-tiimisi numeerinen tunnus"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käynnissä..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postilaatikot"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä yhteys manuaalisesti"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peitä asiakastiedot"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei työtiloja"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkaluonteisen kentän nimi"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkaluonteisen kentän tunnus"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudista yhteys"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudista yhteys"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä Salesforce-sandboxiin"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Määritetään ", _interpolate(_list(0)), "-yhteyttä"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä lisäasetukset"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouda vain keskustelut, joiden arvosana on pienempi tai yhtä suuri kuin asetettu kynnysarvo"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä CSAT-kynnysarvoa"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronoi vain keskustelut, jotka..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouda viimeisimmät keskustelut"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnisteet"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä yhteys"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys päivitetty"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjätunnus"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelusi"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat peruuttaa tilauksesi ja poistaa tilisi, ota yhteyttä ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asiakastukeen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista tili"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiketin poistaminen Zendesk Support (tai toisessa tukipalvelussa) ei vaikuta siihen liittyviin keskustelun arvosteluihin. Voit silti tarkastella ja hallita niitä."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiketin poistaminen Zendesk QA:sta poistaa myös siihen liittyvät keskusteluarvostelut.", _interpolate(_named("line_break")), "Tiketin poistaminen Zendesk Supportista (tai toisesta tukipalvelusta) ei vaikuta siihen liittyviin keskusteluarvosteluihin. Voit silti tarkastella ja hallita niitä."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse arvosteluja"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelujen poistaminen on pysyvää. Se vaikuttaa myös hallintapaneelien dataan."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikki arvostelut"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikki"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvostelut poistettu"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikki"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun poistettuihin tiketteihin liittyy arvosteluja, ne näkyvät tässä."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä arvosteluja"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae tiketin tunnuksella"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiketin tunnus: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu keskustelut"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oletustyötiloja ei voi poistaa. Jos haluat poistaa tämän työtilan, valitse toinen oletustyötila kohdassa ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin asetukset"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimintoa ei voi kumota."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistaa pysyvästi kaikki tiedot"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ymmärrän, että tämä ", _interpolate(_named("removeAllData")), " liittyy tähän työtilaan, mukaan lukien työtilan käyttäjät ja yhteydet."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista työtila"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ”poista työtila” poistaaksesi tämän työtilan"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista työtila ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poista työtila"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pidä huoli, että tiedät mitä olet tekemässä, tai kysy neuvoa ammattilaiselta etukäteen."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse, valitaanko oletusarvoinen arvosteltava aktiivisimman tukihenkilön vai nykyisen käsittelijän perusteella."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivisin tukihenkilö"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletusarvoinen arvosteltava"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli ihmisten arvostella omia keskustelujaan ja tukihenkilöiden suorittaa arvosteluita"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtila poistettiin"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtila päivitettiin"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pidä arviointi neutraalina piilottamalla muiden arvostelijoiden aiemmin antamat arvostelut"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puolueeton arviointi"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väri"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimintoa ei voi kumota."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että tämä poistaa kaikki tähän työtilaan liittyvät tiedot, mukaan lukien työtilan käyttäjät ja yhteydet."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista työtila"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistetaan työtilaa..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikalinkki"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikalinkki"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nykyistä sisäänkirjautumismenetelmääsi ei voi poistaa käytöstä"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennusmenetelmät on päivitetty"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli tietyntyyppiset todennusmenetelmät kaikille käyttäjille."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennukset"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TILI"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät ja työtilat"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo ja hallitse työtiloja sekä kutsu tai yhdistä käyttäjiä."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pysy sivulla"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistu sivulta"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistua tältä sivulta ja hylätä kaikki muutokset?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todennukset"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näyttää siltä, että tuottavia keskusteluja ei ole. Aloita joillakin keskusteluilla tukipalvelun kanssa ja palaa sitten tänne analysoimaan niitä."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluja ei löytynyt"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et voi poistaa tätä järjestelmäkategoriaa"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kategoria"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioiden poistaminen on pysyvää. Se poistaa kaikki kategoriatiedot raportoinnista."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat palauttaa tämän kategorian, sinun on luotava se uudelleen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorian poistaminen"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae kategoriaa"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattiset kategoriat täyttävät arvosanakortin automaattisesti ehdotetuilla arvioinneilla, mikä säästää arvostelussa aikaa. Arvostelijat täyttävät manuaaliset kategoriat alusta alkaen."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty viimeksi"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattinen"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuaalinen"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkeminen"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikeinkirjoitus ja kielioppi"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrys"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luettavuus"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjottu ratkaisu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äänensävy"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioita ei löytynyt"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyitä ei löytynyt"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortteja ei löytynyt"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile toista hakusanaa tai vaihda suodattimia"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategoriaa"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategoriaa"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategoriaa"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kategoriat"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategoriaa"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arvosanakorttia"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arvosanakorttia"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arvosanakorttia"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosanakortit"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " arvosanakorttia"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tilaa"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tilaa"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tilaa"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tilat"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tilaa"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tyyppiä"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tyyppiä"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tyyppiä"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tyypit"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " tyyppiä"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " työtilaa"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " työtilaa"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtiloja ei löytynyt"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " työtilaa"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilat"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " työtilaa"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paino ", _interpolate(_named("weight")), " muodostaa ", _interpolate(_named("percentage")), " % kokonaisarvosanasta"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista arvosanakortti"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakorttien poistaminen on pysyvää. Se poistaa kaikki arvosanakorttitiedot raportoinnista."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat palauttaa tämän arvosanakortin, sinun on luotava se uudelleen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortin poistaminen"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty viimeksi"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnos"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivinen"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyyt"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arviointiasteikko päivitetty. ", _interpolate(_named("buttonStart")), "Aloita arvosteleminen", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA käyttää oletusarvoisesti binääriasteikkoa, mutta voit käyttää myös tarkempaa asteikkoa:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat nähdä erilaisia arviointiasteikkoja kategorian mukaan määritettynä, siirry ", _interpolate(_named("link")), " -sivulle"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia/äänensävy"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotetuntemus"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun päivität arviointiasteikot:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen arviointiasteikkosi ja sitä vastaavat kategoriat arkistoidaan (ei poisteta)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä vaikuttaa joihinkin hallintapaneelin tietoihin, koska vanha ja uusi asteikko eivät välttämättä ole vertailukelpoisia."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kloonaa nykyisten arviointikategorioideni määritykset uuteen asteikkoon"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti päivittää arviointiasteikot?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit muuttaa arvosanakortin esitysmuotoa (numerot tai emojit) ja tehdä kategorioista pakollisia tai valinnaisia."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä emojeja numeroiden sijasta"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli ihmisten ohittaa kategorioita"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratkaisu"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointiasteikon tyyppi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 työtilan kategoriat kloonattiin"]), _normalize([_interpolate(_named("workspacesAmount")), " työtilan kategoriat kloonattiin"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan asetukset"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaitse automaattisesti puhelut, joissa tukihenkilö ei ilmoita asiakkaalle, että keskustelussa voi olla hiljaisia hetkiä."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelussa on hiljaisia hetkiä, jotka kestävät määritettyä kynnysarvoa kauemmin."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä puheluiden hiljaisten hetkien kynnysarvo"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiljaisuutta"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistetut tiedot eivät näy uusissa keskusteluissa ja suodattimissa. Hallintapaneelien ja keskustelujen historiallinen data säilyy ennallaan."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-tietojen poistaminen epäonnistui"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight-tiedot poistettu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista Spotlight-tiedot"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä keskustelut, jotka sisältävät tallenteissa ja viesteissä tiettyjä sanoja tai lauseita."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnista automaattisesti puhelut, joista puuttuu pakollinen tiedonanto, kuten ”Tämä puhelu nauhoitetaan”."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoitteena on tunnistaa nauhoitusta koskeva tiedonanto puhelusta käyttämällä suurta kielimallia (LLM), joka ymmärtää kontekstin dynaamisesti, jolloin ennalta määritettyjä lauseita ei tarvita."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauhoitusta koskeva ilmoitus puuttuu"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havaitse automaattisesti puhelut, joissa tukihenkilö ei ilmoita asiakkaalle, että keskustelussa voi olla hiljaisia hetkiä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiljaisuutta"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä puhelun hiljaiset hetket"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko tehdä muutoksia tilaukseesi? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") ja autamme mielellämme."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi automaattisesti 100 % keskusteluista tukihenkilöiden, BPO:iden, kanavien ja kielten välillä. Tekoäly havaitsee ongelmat, tiedon puutteet ja valmennusmahdollisuudet palvelun parantamiseksi."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100 %:n kattavuus"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön palaute ja valmennus"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlightin tekoälytiedot"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehittynyt raportointi ja hallintapaneelit"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenteiden analysointi ja suodatus"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyynnöstä tehtävät käännökset"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta tehtävät ja tavoitteiden asettaminen"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennus ja tietokilpailut"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendeskin laadunvarmistus (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki Professional-version edut sekä"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (vanha)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookit, jotka ilmoittavat välittömästi kriittisistä epäonnistumisista"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datavaraston integrointi"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud -integrointi"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettujen integrointien kehitys"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omistautunut asiakasmenestyspäällikkö"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suora yhteys tuote- ja suunnittelutiimeihimme Slackin kautta"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki Growth Suite -version edut sekä"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100 %:n kattavuudella"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälypohjaisia keskusteluiden merkityksellisiä tietoja datan ymmärtämisen helpottamiseksi"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälyyn perustuva tukipalveludatan rikastus ja suodatus"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus: Arvostelua vaativien vuorovaikutusten automatisoitu tunnistus"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenteiden analysointi ja suodatus"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälypohjainen suorituskykyraportointi ja merkityksellisiä tietoja"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun käännös pyynnöstä"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteluiden ajankäytön seuranta"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läpäisyprosentin analyysit"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki AI Suiten edut sekä"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-toteutuspaketti, joka sisältää Salesforce- ja Genesys-integroinnit"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettujen integrointien kehitys"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datavaraston integrointi"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookit, jotka ilmoittavat välittömästi kriittisistä epäonnistumisista"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omistautunut asiakasmenestyspäällikkö"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suora yhteys tuote- ja suunnittelutiimeihimme"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennakkokokeilut ja mahdollisuus vaikuttaa tuleviin suunnitelmiimme"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteluiden toimeksiantojen ja tavoitteiden automaattinen määrittäminen"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohdennettu valmennus ja analyysit"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testejä oppimisen hallintaa varten"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien roolien ja käyttöoikeuksien hallinta"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkaluonteisten tietojen peittäminen"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatuksen lisäasetukset ja keskusteluiden haku"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettavat arvosanakortit, joissa on useita arviointeja, säädettäviä painotuksia ja automaattisen hylkäämisen vaihtoehtoja"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selainlaajennus, jonka avulla voit arvostella keskusteluja mistä tahansa"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyyanalyysit"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suorituskykyraportointi tukihenkilöiden, tiimien ja ulkoistettujen liiketoimintaprosessien vertailua varten"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kyselyiden ja asiakaspalautteen tilastot"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ominaisuudet"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki Starter-version edut sekä"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (vanha)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useita arviointiasteikkoja per keskustelu"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrointi"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälypohjaisia ja interaktiivisia havaintoja keskusteluista"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteluiden toimeksiannot"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusmuistiot"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmennusistunnot"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asenteiden analysointi ja suodatus"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatus keskustelun monimutkaisuuden perusteella"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluiden merkitykselliset tiedot"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdolliset arvosanakortit"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyyanalyysit"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edistynyt tekoälypohjainen CSAT-analytiikka"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanojen kalibrointi"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selainlaajennus, jonka avulla voit arvostella keskusteluja mistä tahansa"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus-ominaisuus löytää arvostelua vaativat keskustelut"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun käännös pyynnöstä"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteluiden ajankäytön seuranta"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu tilaus"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (vanha)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajaton määrä keskusteluiden arvosteluita"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-vienti"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertais- ja itsearvioinnit"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit, joissa on säädettävät painotukset ja automaattisen hylkäämisen vaihtoehtoja"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattavat suodatusvaihtoehdot"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittäiset, viikoittaiset tai kuukausittaiset arvostelutavoitteet"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-kyselyt ja perusanalyysit"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallintapaneelit"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportit tukihenkilöiden, tiimien ja ulkoistettujen liiketoimintaprosessien vertailua varten"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien roolien ja oikeuksien hallinta"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ominaisuudet"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluan tehdä muutoksen Zendesk QA -tilaukseen.\n\nKorosta tai merkitse yksi vaihtoehto ja ilmoita paikkojen lukumäärä, jota haluat muuttaa:\nHaluaisin [lisää X paikkaa] [poista X paikkaa] [päivitä Zendesk QA -tilaukseen]\n\nAnna yrityksesi nimi ja Zendesk QA -tiliisi liittyvä nimi sekä muut tiedot, jotka meidän pitäisi tietää tämän muutoksen käsittelyä varten."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA -tilauksen muutos"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluan ostaa Zendesk QA -tilauksen.\n\nAnna yrityksesi nimi ja Zendesk QA -tiliisi liittyvä nimi sekä muut tiedot, jotka meidän pitäisi tietää tämän pyynnön käsittelyä varten:\n\nKerro myös mahdolliset kysymykset, joihin haluat vastata ennen ostamista:\n\nKiitos!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA -tilauksen ostaminen"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauksesi päättyy ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " jäljellä)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeilusi päättyy ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " jäljellä)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["päivä"]), _normalize([_interpolate(_named("n")), " päivää"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") joka kuukauden ", _interpolate(_named("dayOfMonth")), ". päivä."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laskutetaan kortilta ", _interpolate(_named("card")), " (vanhenee ", _interpolate(_named("expires")), ") ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", joka päättyy numeroon"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vanhentunut"]), _normalize(["Vanhenee 1 päivän kuluttua"]), _normalize(["Vanhenee ", _interpolate(_named("expiresIn")), " päivän kuluttua"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähtöhinta sisältää ", _interpolate(_named("n")), " käyttäjälisenssiä"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["alkaen ", _interpolate(_named("sum")), "/kk"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kk"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per käyttäjä/kk"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per vuosi"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per kuukausi"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neljännesvuosi"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poista ajoitettu peruutus"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat jatkaa Zendesk QA:n käyttöä, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauksesi peruutettiin ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauksesi on ajoitettu päättymään ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " päivän kokeilu ilman lisämaksua!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["käytetty"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sinulla on ", _interpolate(_named("n")), " käyttäjää. Paikkojen määrä ei voi olla tätä pienempi."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauksesi jatkuu ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vuosi"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nykyinen Zendesk QA -tilauksesi (", _interpolate(_named("plan")), ") ei sisällä tekoälypohjaisia ominaisuuksia. ", _interpolate(_named("link")), " ottaaksesi kaiken hyödyn irti älykkäistä ääniominaisuuksista."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat peruuttaa tilauksesi, ota meihin yhteyttä ", _interpolate(_named("link")), " tai chattaa kanssamme."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadunhallintasuunnitelma"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadunhallinta"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus päivitetty"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää luottokortti"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tilaukset"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutus"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso laskutustiedot"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutusväli"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta tilaus"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksesi peruutetaan laskutusjakson lopussa."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota yhteyttä"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota meihin yhteyttä, jos haluat muokata näitä asetuksia"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nykyinen tilaus"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu hinnoittelu"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta alempaan versioon"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosittainen (säästä 15 %)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosittainen"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittainen"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksa vuosittain"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittainen"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa tilausta"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutustu tilauksiin"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuton kokeilu"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutetaan manuaalisesti"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit tarkastaa ja vahvistaa lopullisen tilauksen yhteenvedon seuraavassa vaiheessa."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suosituin"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole aktiivista maksutapaa."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilussa"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka nykyistä tilaustasi vaihtaaksesi sen versiota"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä tilaus"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittainen"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neljännesvuosittain"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosittainen"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi tilaus uudelleen"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka tilausta"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paikat"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita kokeilu nyt"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruutettu"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei uusita"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytetty"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksen tila"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilu"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä tilaus"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna nopeasti palautetta asiakastukikokemuksestasi. Kiitos jo etukäteen – ", "{", "{", "agentName", "}", "}", " arvostaa palautettasi!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjän ", _interpolate(_list(0)), " CSAT-kysely"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka arvioisit käyttäjältä ", "{", "{", "agentName", "}", "}", " saamasi tuen?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arvostamme palautettasi. Käytämme palautettasi tehdäksemme ", _interpolate(_list(0)), "-palvelusta paremman kaikille."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kyselyistä"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kyselyn tyyppi"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä voidaan muuttaa myöhemmin."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittaa ja paranna asiakastyytyväisyyttä automatisoiduilla CSAT-kyselyillä monilla eri kielillä."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei luotuja kyselyjä"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kysely"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn nimi"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on luotu"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kokeile vaihtaa avainsanaa tai ", _interpolate(_named("contact_us")), ", jos haluat nähdä sen tässä"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kerro meille"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieltä ei löytynyt"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valitse yksi tai useampi kieli alla olevasta luettelosta. ", _interpolate(_named("contact_us")), ", jos et näe haluamaasi kieltä tässä luettelossa."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kieliä"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerro meille"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kieli"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Julkaistu viimeksi ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tallennettu viimeksi ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-kysymys"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esittelykysymys"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasemman asteikon otsikko"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostin aihe"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syyt"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikean asteikon otsikko"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos kyselystä puuttuu käännöksiä, tekstit näytetään niiden sijaan peruskielellä"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä käännöksiä"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käännöksiä puuttuu"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Muista tarkistaa kaikki käännökset ennen niiden käyttämistä. Tee muutoksia ladattuun tiedostoon, tallenna se ja lataa se tarvittaessa uudelleen palvelimelle. Jos tunnus ", _interpolate(_named("token_example")), " ei näy tunnisteena, se on joko väärässä muodossa tai kentässä, joka ei tue tunnuksia."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta käännökset"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käännetty"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekee yhteenvedon keskustelusta ja näyttää kommenttiehdotuksia."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA -tekoälyavustaja (englanniksi)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit muokata tyyppejä, kun ne on luotu"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä tyyppi on jo olemassa. Valitse eri nimi."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna mukautettu tyyppi Kyselytyyppi-valikosta valittavissa olevaksi vaihtoehdoksi ja ryhmittele kaikki luodut kyselyt hallintapaneelissa."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita jotain ja tallenna painamalla Enter"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa mukautettuja tyyppejä"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyypin nimi ei voi olla tyhjä"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä tyyppi"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettua tyyppiä ei tallennettu. Tallenna se painamalla Enter."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettäjän sähköpostiosoite"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettäjän nimi"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Missä määrin olet samaa mieltä tämän väitteen kanssa: ”", _interpolate(_named("agentName")), " teki ongelmani käsittelystä helppoa”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kuinka helppoa avun saaminen käyttäjältä ", _interpolate(_named("brandName")), " on ollut tähän mennessä?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka helppoa ongelmasi ratkaiseminen oli kaiken kaikkiaan tänään?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka helppoa oli olla vuorovaikutuksessa tiimimme kanssa?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosuojakäytännön URL-osoite (valinnainen)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluehtojen URL-osoite (valinnainen)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kuinka arvioisit käyttäjältä ", _interpolate(_named("agentName")), " saamaasi tukea?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millaista palvelumme oli tähän pyyntöön liittyen?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka tyytyväinen olit tukihenkilöön?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kuinka tyytyväinen olet palveluun, jota ", _interpolate(_named("agentName")), " tarjosi sinulle tänään?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu kysymys"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Määritä oma mukautettu ", _interpolate(_named("type")), "-kysymyksesi..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita mukautettu kysymys"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukauta URL-osoitteita"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä sähköpostiviestisi"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautetun kysymystyypin nimen muokkaaminen päivittää sen kaikissa sitä käyttävissä kyselyissä vaikuttamatta kerättyihin tietoihin."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa mukautettuja tyyppejä"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostin aihe"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esittelykysymys"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-kysymys"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi mukautettu tyyppi"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisältö"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mukautustunnus"]), _normalize(["Mukautustunnukset"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vedä ja pudota mukautustunnus alla olevaan kenttään."]), _normalize(["Vedä ja pudota mukautustunnukset alla oleviin kenttiin."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Keskustelu ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tällä hetkellä vain CSAT-kyselyitä voidaan lähettää sähköpostitse. Pyrimme tarjoamaan pian muita vaihtoehtoja, kuten tukipalvelun CSAT-kyselyt, tekstiviestikyselyt ja paljon muuta."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Synkronoi tai uudista yhteys"]), _normalize(["Synkronoi tai uudista yhteydet"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronoi tai uudista yhteys ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yhteyttä ei ole synkronoitu oikein"]), _normalize(["Yhteyksiä ei ole synkronoitu oikein."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistamalla asiaan kuuluvan valintaruudun valinta ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todenna nyt ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää vähintään yksi kyselyn lähetyssääntö aktivoidaksesi yhteyden"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä yhteys ei ole tällä hetkellä käytössä. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä yhteys peittää arkaluonteiset asiakastiedot. Ota kyselyiden lähettäminen käyttöön\n", _interpolate(_named("link")), " asetuksista."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä yhteys vaatii lisäoikeuksia kyselyiden lähettämiseksi.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todenna nyt"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA vaatii lisäoikeuksia Intercomia varten."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " ja ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 tunnin kuluttua"]), _normalize([_interpolate(_named("n")), " tunnin kuluttua"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minuutin kuluttua"]), _normalize(["1 minuutin kuluttua"]), _normalize([_interpolate(_named("n")), " minuutin kuluttua"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tunnin kuluttua"]), _normalize(["tunnin kuluttua"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " määrittääksesi kyselyiden lähetyssääntöjä."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" päivän kuluttua"]), _normalize(["päivän kuluttua"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselylinkit vanhentuvat"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkit vanhentuvat"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estä kyselyihin vastaaminen, jos aikaa on kulunut liian kauan"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "-kyselyssä ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erota tunnisteet toisistaan pilkuilla tai painamalla Enter kirjoittamisen jälkeen. Varmista, että ne kaikki on lueteltu täsmälleen samoin kuin Intercomissa."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä ehdot, joiden perusteella kysely lähetetään."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn lähetyssäännöt"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkokyselyn lähetyssäännöt"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kysely ", _interpolate(_named("name")), ", ", _interpolate(_named("rules")), ", on jo olemassa.\nRatkaise ristiriita muokkaamalla sääntöjä tällä sivulla tai ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Olet jo määrittänyt yhteyden, joka jolla on vastaavat säännöt kyselylle ", _interpolate(_named("name")), ". Ratkaise ristiriita muokkaamalla sääntöjä joko tässä tai ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää toinen sääntö"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää brändi"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ehto"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae arvoa"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tunniste"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["joka vastaa samoja sääntöjä"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvo"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti lähetykseen"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkosähköpostin lähetykseen"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotettu koodikatkelma ja jatkosähköposti"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi koodi"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä Zendesk QA -kyselyitä sähköposteihisi lisäämällä koodikatkelma Zendesk-asetuksiisi."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asennusopas"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselu"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodikatkelman esikatselu"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodikatkelma kopioitu leikepöydälle"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodikatkelman kopiointi epäonnistui"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotettu koodikatkelma"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tässä kyselyssä on tallentamattomia muutoksia. Tallenna se uudelleen varmistaaksesi, että koodikatkelma vastaa viimeisimpiä päivityksiä."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää yhteys"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyksiä ei ole vielä lisätty"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouda sähköpostit, live-chatit, puhelut ja paljon muuta Zendesk QA:han yhteyksiesi avulla"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kyselyiden toimittamisesta"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn lähetyssäännöt"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkokyselyn lähetyssäännöt"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää brändisääntö"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää sääntö"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todenna"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + jatkosähköposti"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä Intercom-tilisi Zendesk QA:n CSAT-kyselyyn"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimitustapa"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuka saa kyselyn ja milloin"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa sääntöjä"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytössä"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sovelluksen sisäisten kyselyiden avulla asiakkaasi voivat vastata kyselyyn poistumatta chatista."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyskohtaiset toimitussäännöt"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kysely ", _interpolate(_named("name")), ", ", _interpolate(_named("rules")), ", on jo olemassa.\nRatkaise ristiriita poistamalla yhteys käytöstä tai muokkaamalla sääntöjä tällä sivulla tai ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimitus"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä ehdot, joiden perusteella kysely lähetetään"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " loi ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos poistat tämän kielen, sitä ei enää näytetä vastaajille ja kaikki sille käännetyt tekstit poistetaan."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kieli"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimintoa ei voi kumota."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihtoehtoisesti voit poistaa kielen käytöstä piilottamalla sen kyselystä. Näin voit pitää kaikki käännöksesi paikoillaan näyttämättä niitä vastaajille."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " muokkasi viimeksi ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kyselyiden kielistä"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmista ennen muiden kielten lisäämistä, että olet viimeistellyt muutokset Tyyli- ja Sisältö-vaiheissa. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kieli"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käännetyt kentät"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytetään vastaajille"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletus"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa käännöstiedosto"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kieli"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielet"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" lataa käännöstiedosto"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta käännökset"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käännös valmis. ", _interpolate(_named("review_link")), " tai ", _interpolate(_named("download_link")), " tehdäksesi muutoksia."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" lataa käännöstiedosto"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta puuttuva sisältö"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Osaa sisällöstä ei ole vielä käännetty, joten se korvataan englanninkielisellä tekstillä. ", _interpolate(_named("review_link")), " tai ", _interpolate(_named("download_link")), " lisätäksesi puuttuvat käännökset."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna kysely"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kysely sisältää tallentamattomia muutoksia. ", _interpolate(_named("save")), " ladataksesi käännöstiedoston, joka sisältää viimeisimmät muutokset."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa käännöstiedosto palvelimelle"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse haluamasi arviointiasteikko"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjoaa asiakkaille pudotusvalikon, josta he voivat valita arvosanan syyn"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojit"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasemman asteikon teksti (valinnainen)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerot"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää syy"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syy ei voi olla tyhjä"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä syy on jo olemassa"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä pisteytyksen syyn pudotusvalikko"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syyn poistaminen ja sen lisääminen uudelleen samalla nimellä tai syyn uudelleennimeäminen luo aina uuden merkinnän. Tästä johtuen se vaikuttaa hallintapaneelissa näytettyihin tietoihin."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista syy"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu syy säilyy hallintapaneelin raportoinnissa, mutta sitä ei näytetä uusille kyselyn vastaajille. Tämä koskee myös asiaan liittyviä käännöksiä."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista syy"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poista syy ”", _interpolate(_list(0)), "”"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikean asteikon teksti (valinnainen)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viiden pisteen asteikolla arvosanoja 1 ja 2 pidetään negatiivisina, 3 on neutraali ja 4–5 ovat positiivisia"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää asteikon teksti"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-asteikko"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointiasteikko"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA tuo keskustelujasi. Tämä prosessi voi kestää jopa 24 tuntia."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä käytetään mukautustunnuksena koko kyselyn ajan."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brändin nimi"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painikkeet ja linkit"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukauta kyselysi ulkoasua"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logon tausta"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG-, PNG- ja GIF-muotoja tuetaan"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyli"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa logo palvelimelle"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopeta viestisi tyylikkäästi"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita viesti, joka näytetään palautetta antaneille ihmisille"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitosviesti"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen heksadesimaalivärikoodi"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen sähköpostiosoite"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä ei voi olla tyhjä"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen URL-osoite"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näkyvissä:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkyvissä:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luontipäivä"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielet"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetyt kyselyt"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt, jotka on lähetetty viimeisten 30 päivän aikana"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on poistettu"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on kopioitu"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatseluviesti on lähetetty"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on julkaistu"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on päivitetty"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysely on keskeytetty"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä kysely"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselu"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä sähköpostiosoite kyselyn esikatselun lähettämiseksi"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oma", "@", "osoite.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse esikatselun kieli"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä esikatseluviesti"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä esimerkkikysely sähköpostiisi. Kyselyn esikatselun arvioita ei tallenneta."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esikatsele ", _interpolate(_named("type")), "-kyselyä"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostikyselyn esikatselu verkossa"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna luonnoksena"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brändin nimi"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brändikohtainen nimi voidaan lisätä Tyyli-vaiheessa"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan nimi"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuen tunnus"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnos"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytetty"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjän ", _interpolate(_list(0)), " CSAT-kysely"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallentamattomia muutoksia tehty"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ja jatka kyselyn lähettämistä"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos et näe tätä sähköpostia oikein, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ollut tyytyväinen saamaani tukeen, koska..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelun tarjoaa Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse syy"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelun kertaus"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimerkki: Asiakkaalla oli ongelmia maksutavan päivittämisessä. Tukihenkilö jakoi artikkelin, josta voi olla apua. Se toimi."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelun tarjoaa Zendesk QA -tekoäly"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluehdot"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos palautteestasi!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikä on tärkein syy arvosanallesi?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerro, miksi annoit tämän arvosanan..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paljon työtä"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähän työtä"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaa kysely napsauttamalla tästä"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityisyys"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu kysymyksesi näytetään tässä"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Annoit omalle ", _interpolate(_list(0)), "-kokemuksellesi arvosanan"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä palaute"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopeta tilaus"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erittäin tyytymätön"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erittäin tyytyväinen"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että kyselyn tämänhetkistä tilaa tai toimitussääntöjä ei sisällytetä kyselyn kopioon"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kopioi kysely ”", _interpolate(_list(0)), "”"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käytät upotettua sähköpostin koodikatkelmaa, muista poistaa se Zendeskistä ennen kyselyn keskeyttämistä"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastauksia ei kerätä, kun kysely on keskeytetty."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä kysely"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista, että poistat koodikatkelman Zendeskistä ennen kyselyn keskeyttämistä."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kysely"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käytät upotettua sähköpostin koodikatkelmaa, muista poistaa se Zendeskistä ennen kyselyn poistamista."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä toiminto poistaa kyselyn pysyvästi. Kyselyn vastaanottaneet käyttäjät voivat edelleen vastata siihen, ja kaikki kyselyyn liittyvät tiedot säilyvät Zendesk QA:ssa."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista kysely ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muista poistaa koodikatkelma Zendeskistä ennen kyselyn poistamista."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudet vastaajat näkevät käyttöön ottamasi muutokset."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise kysely"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muista kopioida upotetun koodikatkelman uusin versio kyselyn julkaisemisen jälkeen."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käytät upotettua sähköpostin koodikatkelmaa, muista kopioida se Zendeskiin kyselyn jatkamisen jälkeen."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka kyselyn lähettämistä ja vastausten keräämistä."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ja jatka kyselyn lähettämistä"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista, että lisäät upotetun koodinpätkän Zendeskiin kyselyn jatkamisen jälkeen."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä kysely"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muista kopioida upotetun koodikatkelman uusin versio kyselyn päivittämisen jälkeen."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän kyselyn? Tätä toimintoa ei voi kumota."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistetaanko kysely?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilusi on päättynyt, mutta voit jatkaa Zendesk QA:n käyttöä päivittämällä koko tiliin."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilusi on päättynyt, mutta voit jatkaa Zendesk QA:n käyttöä päivittämällä koko tiliin. Keskustele paikallisen järjestelmänvalvojan kanssa sen määrittämiseksi."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeilusi on päättynyt"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä nyt / korota tilaustasoa nyt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuton kokeilusi on päättynyt."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä maksutietosi jatkaaksesi Zendesk QA:n täyden version käyttöä."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilisi järjestelmänvalvojan täytyy syöttää maksutiedot, jotta voit jatkaa Zendesk QA:n täyden version käyttöä."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " loi ", _interpolate(_named("date"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " toimeksianto"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työskentelet nähtävästi edelleen tämän tehtävän parissa. Menetät kaikki tähän mennessä lisäämäsi tiedot, jos poistut tallentamatta muutoksia."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä toimeksianto toistetaan joka toinen ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tämä toimeksianto toistetaan joka ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kaikki arvosteltavat käsitellään yhdessä jaksossa"]), _normalize(["Kaikki arvosteltavat käsitellään ", _interpolate(_named("nr")), " jaksossa"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " keskustelua"]), _normalize([_interpolate(_named("n")), " keskustelu"]), _normalize([_interpolate(_named("n")), " keskustelua"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["/", _interpolate(_named("nr")), " tukihenkilöstä"]), _normalize(["/", _interpolate(_named("nr")), " tukihenkilöstä"]), _normalize(["/", _interpolate(_named("nr")), " tukihenkilöstä"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["osallistuja"]), _normalize(["osallistujaa"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " keskustelua per jakso"]), _normalize([_interpolate(_named("n")), " keskustelu per jakso"]), _normalize([_interpolate(_named("n")), " keskustelua per jakso"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " per arvosteltava rivillä"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["arvosteltava"]), _normalize(["arvosteltavaa"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["arvostelija"]), _normalize(["arvostelijaa"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltavat"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilan käyttäjät"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilan käyttäjät, paitsi tukihenkilöt"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jakso ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei-toistuva"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " toimeksianto"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelussa"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää toimeksiannoista"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo toimeksianto"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä toiminto poistaa toimeksiannon pysyvästi ja poistaa sen tiedot hallintapaneelista. Kaikki siihen liittyvät arvostelut säilyvät Zendesk QA:ssa."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista toimeksianto ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto poistettu"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto kopioitu"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä lisättyjä toimeksiantoja"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannon luonti epäonnistui"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannon nimi"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakso päivitetään ja uusi tehtävälista luodaan. Jo annetut arvostelut lasketaan edelleen tavoitteen saavuttamiseen."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välittömästi"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seuraavan jakson alussa ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milloin muutokset julkaistaan?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tähän toimeksiantoon tehdyt muutokset julkaistaan tämän toimeksiannon kanssa"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko asettaa toimeksiannon aktiiviseksi?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toistuu toimeksiannon alkamispäivänä"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen perjantai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen perjantai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas perjantai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs perjantai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen perjantai"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen maanantai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen maanantai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas maanantai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs maanantai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen maanantai"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen lauantai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen lauantai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas lauantai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs lauantai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen lauantai"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen sunnuntai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen sunnuntai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas sunnuntai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs sunnuntai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen sunnuntai"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen torstai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen torstai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas torstai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs torstai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen torstai"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen tiistai"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen tiistai"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas tiistai"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs tiistai"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen tiistai"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden ensimmäinen keskiviikko"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden toinen keskiviikko"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden kolmas keskiviikko"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden neljäs keskiviikko"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden viimeinen keskiviikko"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettu toistuvuus"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivä"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukauden 1. päivä"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäinen"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toinen"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolmas"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neljäs"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toistuu"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikonpäivä"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppu"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alku"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tuloksia"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikavyöhyke"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakson asetukset"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannon nimi"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perustiedot"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunkin delegointijakson alussa luodaan ennalta määritetty luettelo arvosteltavista keskusteluista."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäasetukset"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouda uudet keskustelut automaattisesti, kun arvostelutavoite ei täyty"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattinen nouto ei ole mahdollista prosenttiosuuden (%) arvostelutavoitteiden kanssa"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On oltava kokonaisluku, ei desimaali"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli keskustelujen korvaaminen (arvostelijan rooli ja sitä korkeammat)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelujen korvaaminen"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli arvostelijoiden delegoida omat keskustelunsa arvosteltaviksi"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelua"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelujen vähimmäismäärän on oltava vähintään 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä arvostelujen vähimmäismäärä kullekin arvosteltavalle"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskustelua"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prosenttia (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelua"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvostelua yhteensä"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunkin arvostelijan täytyy tehdä tietty määrä arvosteluita"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijoiden täytyy arvostella yhdessä tietty määrä keskusteluja"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelujen vähimmäismäärän on oltava vähintään 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelutavoitteen täytyy olla väliltä 1–100 %"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelutavoite"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunkin arvosteltavan keskusteluista tietty määrä täytyy arvostella"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat voivat valita keskusteluja yhteisestä poolista"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijoille delegoidaan keskusteluja yhteisestä poolista"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegointimenetelmä"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki botit"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilaan osallistujat"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilan käyttäjät"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää arvosteltavia"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketkä arvostellaan?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvosteltavat"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse, kuka arvostellaan"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjiä voi jättää pois vain, kun kaikki työtilan käyttäjät tai ryhmä on valittu"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat ja arvosteltavat"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nykyinen: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan oletus"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minkä tyyppiset käyttäjät tulisi valita arvosteltaviksi?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivisin tukihenkilö"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistuja"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä arvosteltavat pois (valinnainen)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse pois jätettävät käyttäjät"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä arvostelijat pois (valinnainen)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse pois jätettävät käyttäjät"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilan käyttäjät, paitsi tukihenkilöt"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää arvostelijoita"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuka suorittaa arvostelut?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvostelijat"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearviointeja ei ole sallittu tässä työtilassa"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli itsearvioinnit"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearviointeja ei ole sallittu tässä työtilassa."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vastaava keskustelu tänään"]), _normalize(["Vastaavat keskustelut tänään"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytetään kuin toimeksianto olisi alkanut tänään."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulevat luvut voivat muuttua."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei valittuja arvosteltavia"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei valittuja arvostelijoita"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arvosteltava"]), _normalize(["Arvosteltavat"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arvostelija"]), _normalize(["Arvostelijat"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselu"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattinen nouto: sallittu, jos keskusteluja ei ole tarpeeksi"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " perjantaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " maanantaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " lauantaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " sunnuntaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " torstaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " tiistaisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " keskiviikkoisin alkaen ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päivittäin ", _interpolate(_named("startDateTime")), " alkaen"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alkaa ", _interpolate(_named("startDateTime")), " ja päättyy ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " päivämääräehtoa"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " keskustelu ja tukipalvelun ehtoja"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ei keskustelua ja ", _interpolate(_named("count")), " tukipalvelun ehto"]), _normalize(["Ei keskustelua ja ", _interpolate(_named("count")), " tukipalvelun ehtoa"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " keskustelu ja ei tukipalvelun ehtoa"]), _normalize([_interpolate(_named("count")), " keskustelua ja ei tukipalvelun ehtoa"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lähestymistapa: arvostelijat voivat valita keskustelut"]), _normalize(["Lähestymistapa: keskustelut delegoidaan arvostelijoille"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korjaa yhteenvedossa korostetut virheet jatkaaksesi"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite: arvostelijoiden täytyy arvostella kaikki keskustelut"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tavoite: arvostelijoiden täytyy arvostella yksi keskustelu"]), _normalize(["Tavoite: arvostelijoiden täytyy arvostella ", _interpolate(_named("count")), " keskustelua"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tavoite: arvostelijoiden täytyy arvostella ", _interpolate(_named("count")), " % keskusteluista"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite: arvostelutavoitetta ei ole asetettu"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite: kunkin arvosteltavan kaikki keskustelut täytyy arvostella"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tavoite: ", _interpolate(_named("count")), " kunkin arvosteltavan keskustelua täytyy arvostella"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tavoite: ", _interpolate(_named("count")), " % kunkin arvosteltavan keskusteluista täytyy arvostella"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tavoite: kunkin arvostelijan täytyy suorittaa yksi arvostelua"]), _normalize(["Tavoite: kunkin arvostelijan täytyy suorittaa ", _interpolate(_named("count")), " arvostelua"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Korvaaminen: arvostelijat eivät voi korvata keskusteluja"]), _normalize(["Korvaaminen:\narvostelijat voivat korvata keskusteluja"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itsearvioinnit: arvostelijoille voidaan delegoida heidän omia keskustelujaan"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei määritettyjä päivämääräehtoja"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannon nimeä ei ole määritetty"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteenveto"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdot"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " ", _interpolate(_named("dateTime")), " alkaen"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kahdesti kuussa"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittäin"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausittain"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei koskaan"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikoittain"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakson pituus"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta aktiiviseksi"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna luonnoksena"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto tallennettu luonnokseksi"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto asetettiin ei-aktiiviseksi"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta ei-aktiiviseksi"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo toimeksianto"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto luotu"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse aktiiviseksi"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse ei-aktiiviseksi"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Toimeksiannon odottavat päivitykset astuvat voimaan seuraavassa jaksossa ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise muutokset"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnos"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivinen"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottaa"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä toimeksianto"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto päivitetty"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, päättikö tukihenkilö tai botti keskustelun."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, lopettiko tukihenkilö keskustelun kohteliaasti"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, lopetettiinko keskustelu asiaankuuluvalla tavalla"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopetus"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi kielioppi-, kirjoitusasu- ja tyylivirheet sekä tukihenkilön että botin vuorovaikutuksessa."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön kielioppi-, kirjoitus- ja tyylivirheet"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön kielioppi-, kirjoitus- ja tyylivirheet"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikeinkirjoitus ja kielioppi"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi keskustelun tukihenkilön ja botin tervehdyksiä varten."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelun tyypillisten tervehdysfraasien varalta"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelun tyypillisten tervehdysten varalta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdys"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, ymmärtääkö ja tunnustaako tukihenkilö asiakkaiden tunteet"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnistaa tapaukset, joissa tukihenkilö reagoi asiakkaan asenteeseen tai käytti huolehtivaisia sanoja, kuten ”selvä” ja ”ymmärrän”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empatia"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, ymmärtääkö tukihenkilö asiakkaan kyselyn tai huolenaiheen"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, ymmärtääkö tukihenkilö asiakkaan kyselyn tai huolenaiheen"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrys"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, kuinka helppo tekstiä on ymmärtää, ottaen huomioon sanojen monimutkaisuuden ja lauseiden pituuden"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi, kuinka helppo tekstiä on ymmärtää, ottaen huomioon sanojen monimutkaisuuden ja lauseiden pituuden"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä viesti sisältää yhden liian pitkän lauseen, joka tekee sen lukemisesta haastavaa"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tämä viesti sisältää ", _interpolate(_list(0)), " liian pitkää lausetta, jotka tekevät sen lukemisesta haastavaa"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luettavuus"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelun tarjotun ratkaisun tunnistamiseksi"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi koko keskustelun tarjotun ratkaisun tunnistamiseksi"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjottu ratkaisu"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön äänensävyn koko keskustelun ajalta"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysoi tukihenkilön äänensävyn koko keskustelun ajalta"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äänensävy"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön viesteissä ei havaittu virheitä"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielioppivirheitä ei voitu määrittää"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tervehdyslausetta ei havaittu. Lisää uusia tarvittaessa järjestelmänvalvojasi kautta asetuksista."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö lopetti keskustelun kohteliaasti"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppulausetta ei havaittu. Lisää uusia tarvittaessa järjestelmänvalvojasi kautta asetuksista"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö reagoi asiakkaan asenteeseen tai käytti huolehtivaisia sanoja"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voitu havaita, reagoiko tukihenkilö asiakkaan asenteeseen tai käyttikö hän huolehtivaisia sanoja"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö käytti pitkiä lauseita ja monimutkaisia sanoja, mikä voi tehdä tekstistä vaikeaselkoista"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö käytti monimutkaisia sanoja, mikä voi tehdä tekstistä vaikeaselkoista"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö käytti pitkiä lauseita, mikä voi tehdä tekstistä vaikeaselkoista"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö käytti kieltä, jota on helppo ymmärtää"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tarpeeksi dataa tämän kategorian arvioimiseen"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilöllä oli vaikeuksia ymmärtää asiakkaan kysymys/pyyntö"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö ymmärsi asiakkaan kysymyksen/pyynnön hyvin"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voitu havaita, ymmärsikö tukihenkilö asiakkaan kysymyksen/pyynnön"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävy keskustelun aikana"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävy keskustelun aikana"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävy keskustelun aikana"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävy keskustelun aikana"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävy keskustelun aikana"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilön äänensävyä ei voitu määrittää"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelua ei lopetettu asiaankuuluvalla tavalla"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu lopetettiin asiaankuuluvalla tavalla"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voitu havaita, lopetettiinko keskustelu asiaankuuluvalla tavalla"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö ei osoittanut empatiaa asiakasta ja hänen ongelmiaan kohtaan"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö osoitti empatiaa asiakasta ja hänen ongelmiaan kohtaan"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voitu havaita, osoittiko tukihenkilö empatiaa asiakasta ja hänen ongelmiaan kohtaan "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakasta ei tervehditty keskustelun aikana"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakasta tervehdittiin keskustelun aikana"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyypillistä tervehdystä ei havaittu"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö ei tarjonnut ratkaisua asiakkaalle"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukihenkilö tarjosi ratkaisua asiakkaalle"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei voitu havaita, tarjosiko tukihenkilö ratkaisua asiakkaalle"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monimutkaiset sanat"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkät lauseet"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielioppi"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoitusvirheet"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiivinen"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteeksipyytelevä"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rauhallinen"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iloinen"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utelias"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammattimainen"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kannustava"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyli"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 lopetus"]), _normalize([_interpolate(_named("n")), " lopetusta"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA tarkistaa vain hyväksytyt lopetukset. Jos tämä kategoria on määritetty kriittiseksi, hyväksyttyjen lopetusten käyttämättä jättäminen johtaa arvostelun epäonnistumiseen."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt lopetukset"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt lopetukset"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 poikkeus"]), _normalize([_interpolate(_named("n")), " poikkeusta"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanat ja lauseet, joita AutoQA ei tulkitse kirjoitus- tai kielioppivirheiksi."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikeinkirjoituksen ja kieliopin poikkeukset"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-poikkeukset"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 tervehdys"]), _normalize([_interpolate(_named("n")), " tervehdystä"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA tarkistaa vain hyväksytyt tervehdykset. Jos tämä kategoria on määritetty kriittiseksi, hyväksyttyjen tervehdysten käyttämättä jättäminen johtaa arvostelun epäonnistumiseen."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt tervehdykset"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt tervehdykset"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategoria"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse AutoQA-kategoria"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattisesti delegoidut juurisyyt:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tili → Asetukset"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jotkin kategoriat hyödyntävät MS Azurea, joka tarjoaa laajemman valikoiman toimintoja ja tuettuja kieliä. Jos kaipaat lisätietoja, valitse ", _interpolate(_named("link")), " tai ota yhteyttä järjestelmänvalvojaan."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ota käyttöön enemmän kategorioita ja tuettuja kieliä MS Azuren avulla. Aloita valitsemalla ", _interpolate(_named("link")), " tai ottamalla yhteyttä järjestelmänvalvojaan."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä AutoQA:ta"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA-kategorioille annetaan arvosana automaattisesti käyttämällä kiinteää arviointiasteikkoa. Voit säätää arvosanaa manuaalisesti myöhemmin. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse AutoQA-kategoria"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattisesti annettu arvosana riippuu virheiden vakavuudesta ja määrästä. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyyt"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain asiaankuuluvat arviointikategoriat näytetään arvostelijoille. Arviointikategoriat näytetään kategorian luonnin yhteydessä määrittämiesi ehtojen perusteella."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chat"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perehdytys"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puhelin"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valitse arviointikategorian tyyppi työtilalle ”", _interpolate(_named("workspace")), "”."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijoiden täytyy valita oikea arvosanakortti manuaalisesti nähdäkseen kuhunkin keskusteluun liittyvät arviointikategoriat."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti muuttaa arviointikategorian tyyppiä?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta kategorioita"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko muuttaa arviointikategorian tyyppiä?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehdollinen arviointikategoria"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staattinen arviointikategoria"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategorian tyyppi"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategorian asetukset"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä toimintoa ei voi kumota."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poista kaikki arvioinnit"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihtoehtoisesti voit arkistoida kategorian säilyttääksesi tilastosi poistamatta mitään tietoja."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ymmärrän, että tämä ", _interpolate(_named("deleteAllRatings")), ", jotka on annettu tälle kategorialle."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. Täydellinen äänensävy"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kategoria vain, jos"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["”Aina” ohitetaan. Sitä ei voi yhdistää muihin ehtoihin."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aina"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteet"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ehto"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyytyväisyysarvosana (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähteen tyyppi"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelukanava"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria tarvitsee nimen"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kategoria arvosanakorttiin"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kategoria"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kategoria"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria lisätty"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoituja kategorioita ei näytetä arvosanakortissa, mutta arkistoinnin avulla voit säilyttää tilastosi poistamatta mitään tietoja."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi kategoria"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Arkistoi kategoria ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria arkistoitu"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kategoria"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita ”poista kategoria” poistaaksesi tämän kategorian"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poista kategoria"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poista kategoria ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria poistettu"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria kopioitu"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoriaryhmä lisätty"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoriaryhmä poistettu"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoriaryhmän järjestystä muutettu"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointiluokkaryhmä päivitetty"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria palautettu arkistosta"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointikategoria päivitetty"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun tukihenkilö epäonnistuu kriittisessä kategoriassa, kaikki kyseisen arvostelun kategoriat epäonnistuvat automaattisesti."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriittinen arviointikategoria"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kategorioita tässä ryhmässä"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi arviointikategoria"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa kategoriaa"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikki kategoriat ja tarkista tämän ryhmän tiedot"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriaryhmän poistaminen jättää kaikki siihen linkitetyt kategoriat ryhmittämättömiksi."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista kategoriaryhmä ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos ryhmään sisältyvät kategoriat poistetaan, kaikki niihin liittyvät arvostelutiedot menetetään."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän nimi"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat voivat valita juurisyyksi useamman kuin yhden syyn"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat voivat valita juurisyyksi useamman kuin yhden syyn"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useita syitä"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esim. Äänensävy"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi kategoriaryhmä"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi ryhmä"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ”Muu”"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää juurisyy"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä juurisyy on jo olemassa"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää juurisyy"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää juurisyitä arviointien selittämistä varten"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arviointiasteikko"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoidut"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää arvosanakortteja tähän arviointikategoriaan"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta arkistosta"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä kategoria"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvioinnit"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mille näytetään"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiiviset arvioinnit"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatiiviset ja neutraalit arvioinnit"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyllä, päivitä"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painotus"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit painottaa sinulle tärkeitä kategorioita tiketin arvosanan laskutoimituksessa."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Painotus (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kalibroinnista"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää kalibroinnista"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säännöllisten kalibrointi-istuntojen avulla arvostelijat voivat pitää arviointitekniikkansa yhdenmukaisina ja varmistaa, että tukihenkilöt saavat johdonmukaista ja puolueetonta palautetta."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tilaukset"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " on prosessi, joka auttaa tiimiäsi pysymään samalla sivulla siitä, kuinka kaikki asiakasvuorovaikutukset tulisi käsitellä ja arvioida."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kalibrointi on käytettävissä ", _interpolate(_list(0)), "- ja ", _interpolate(_list(1)), "-versioissa."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiemmin annettuja arvosteluita ei näytetä, kun istuntoon lisätään keskustelu."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näkyvissä"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiemmin annetut arvostelut tulkitaan kalibrointiarvosteluiksi. Istuntoon lisätään vain yksi keskustelun arvostelu per arvostelija. Viestikohtaisia arvosteluja ei näytetä."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkyvissä"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiemmin annettujen arvostelujen näkyvyys"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kalibrointi on prosessi, joka auttaa tiimiäsi pysymään samalla sivulla siitä, kuinka kaikki asiakasvuorovaikutukset tulisi käsitellä ja arvioida. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjillä on rajaton pääsy kaikkiin kalibrointi-istunnon aikana annettuihin arvosteluihin."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä aina kaikki arvostelut"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Johtaja"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät näkevät arvostelun lähettämisen jälkeen arvostelut, joita muut arvostelijat ovat antaneet kalibrointi-istunnon aikana."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki kalibrointiarvostelut arvostelun lähettämisen jälkeen"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjillä on rajaton pääsy kaikkiin kalibrointi-istunnon aikana annettuihin arvosteluihin."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä aina kaikki arvostelut"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimies"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät näkevät arvostelun lähettämisen jälkeen arvostelut, joita muut arvostelijat ovat antaneet kalibrointi-istunnon aikana."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki kalibrointiarvostelut arvostelun lähettämisen jälkeen"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät näkevät arvostelun lähettämisen jälkeen arvostelut, joita muut arvostelijat ovat antaneet kalibrointi-istunnon aikana."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki kalibrointiarvostelut arvostelun lähettämisen jälkeen"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät näkevät vain omat kalibrointi-istunnon aikana antamansa arvostelut."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vain omat arvostelut"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointiasetukset päivitetty"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointiarvosteluiden näkyvyys"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä hallintapaneelin mittarien kynnysarvo täyttämällä aukot tiimisi sisäisen mittausjärjestelmän mukaan. Tarkasta kynnysarvo hallintapaneelista."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiannot"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrointi"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteydet"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteet"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsenet"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kynnysarvo"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kynnysarvo päivitetty"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmista, että sinulla on järjestelmänvalvojan oikeudet, ja ", _interpolate(_named("retry")), " tai ", _interpolate(_named("contact")), " saadaksesi lisäapua."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ota meihin yhteyttä"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emme nähtävästi saa tukipalvelusi API-rajapinnan käyttöoikeutta."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yritä uudelleen"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistämme tukipalveluasi. Sinut uudelleenohjataan hetken kuluttua."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " valtuutettu"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Siirry takaisin ", _interpolate(_list(0)), " lisätäksesi uuden tukipalvelun yhteyden."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulta jäi nähtävästi vaihe tai kaksi välistä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisaatio, jolla on sama sähköpostitoimialue, on jo olemassa"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yhteyksiesi sivulle"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sillä välin..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki, tarkistamme käyttöoikeuksiasi."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sillä välin..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrointi"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["luo työtila"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos haluat jatkaa Zendeskin QA:n käyttöä, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melkein valmis käytettäväksi"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilaa ei löytynyt"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda tiliä"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esihenkilösi täytyy delegoida sinut työtilaan, jotta voit jatkaa Zendesk QA:n käyttöä."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilisi poistaminen poistaa pysyvästi kaikki tiedot, joita meillä on sinusta – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaikki annetut arvioinnit, keskusteluiden sisältö, työtilat jne."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää Slackiin"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkaise yhteys"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integroinnin yhteyden katkaiseminen poistaa käytöstä kaikki Slackin kautta lähetetyt Zendesk QA -ilmoitukset"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkaise Slack-yhteys"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integroinnin yhteys Zendeskin QA:han on katkaistu."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä oletusarvoiset ilmoitusasetukset tilin kaikille uusille käyttäjille."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korvaa kaikkien käyttäjien asetukset"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet korvaamassa kaikkien tilin käyttäjien ilmoitusasetukset"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät eivät voi määrittää omia ilmoitusasetuksiaan."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitusasetukset päivitetty"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookit"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä hälytys, kun kriittinen kategoria epäonnistuu"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä hälytys, kun CSAT saa vastauksen"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laukaisimet"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-osoite"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo esittelytyötila ja -data"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo esittelydata uudelleen ja säilytä esittelytyötila."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista esittelytyötila ja -data"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä esittelydata"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä data"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallitse tilin ”", _interpolate(_named("accountName")), "” esittelytilaa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esittelytila"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pysy sivulla"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistu sivulta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistua tältä sivulta ja hylätä kaikki muutokset?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet suorittanut toimeksiannon"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo toimeksianto"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksianto suoritettu"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimeksiantoja ei löytynyt"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole tällä hetkellä yhtään arvosteltavaa tehtävää."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säädä toimeksiantoa tai odota, että seuraava jakso alkaa."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokeile säätää suodattimia"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa toimeksiantoa"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pidä tätä sivua silmällä päivitysten varalta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tehtäviä tälle jaksolle"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä keskusteluja"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse tehtävä valmiiksi"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet arvostellut tämän käyttäjän."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei itsearviointeja tässä toimeksiannossa"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää niitä napsauttamalla kalibrointikuvaketta keskustelunäkymän otsikosta."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisättyjä keskusteluja"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eräpäivä"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo toimeksianto"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kalibrointi-istunto"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtäväsi"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut tehtävät"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivisia delegointeja"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei delegointeja"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosteltavat"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosteltavat, paitsi minä"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltavia ei löytynyt"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae arvosteltavaa"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnos"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tilat"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvostelijat"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvostelijat, paitsi minä"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoitu minulle"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijoita ei löytynyt"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae arvostelijaa"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivää"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivää"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivä"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivää"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivää"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " päivää"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tuntia"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tuntia"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tunti"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tuntia"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tuntia"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " tuntia"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuuttia"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuuttia"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuutti"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuuttia"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuuttia"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " minuuttia"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sovellettavissa"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi uudelleen"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilan käyttäjät"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nykyinen arvostelija"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi tavoite tälle jaksolle"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi arvostelija"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse arvostelija"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi keskustelut uudelleen"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi keskustelu uudelleen"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrettävät keskustelut"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nykyinen jakso"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa toimeksiantoa"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijoita ei löytynyt"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae arvostelija"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi keskustelut uudelleen minulle"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi keskustelut uudelleen toiselle käyttäjälle"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelijat"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " päivää"]), _normalize([_interpolate(_named("n")), " päivä"]), _normalize([_interpolate(_named("n")), " päivää"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tuntia"]), _normalize([_interpolate(_named("n")), " tunti"]), _normalize([_interpolate(_named("n")), " tuntia"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota valmiit jaksot"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota valmiit istunnot"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oma tavoitteesi"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää syy poistaaksesi tämän keskustelun"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selitä, miksi poistat tämän keskustelun"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko säilyttää muutokset?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää syy korvataksesi tämän keskustelun"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää muutokset"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säilytä"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säilytä muutokset"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korvaavaa ei löytynyt"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selitä, miksi korvaat tämän keskustelun"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko silti poistaa tämän keskustelun toimeksiannosta? Tämä pienentää tavoitetta yhdellä."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korvaa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korvaa keskustelu"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista luonnos"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa luonnosta"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi uudelleen minulle"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi uudelleen toiselle käyttäjälle"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegoi uudelleen ja arvostele"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista keskustelu"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä arvostelu"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vähemmän"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä enemmän"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tavoite: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " arvosteltu"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehdyt arvostelut"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä suoritetut jaksot"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä suoritetut istunnot"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikaa jäljellä"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävät"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu korvattu"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota tehtäväluettelo"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " myöhemmin"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekunti"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " sekuntia"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk ", _interpolate(_named("hours")), " h"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk ", _interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " min"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " vrk ", _interpolate(_named("minutes")), " min"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " min"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("minutes")), " min ", _interpolate(_named("seconds")), " sek"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " h ", _interpolate(_named("seconds")), " sek"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " min"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " min ", _interpolate(_named("seconds")), " sek"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " kk"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " sek"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " v"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juuri nyt"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole käyttöoikeuksia. Pyydä apua järjestelmänvalvojaltasi."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsimääsi sivua ei ole olemassa."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui odottamaton virhe. Ota yhteyttä tukeen, jos tarvitset apua."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääsy estetty. Ei oikeutta suorittaa tätä toimintoa."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsimääsi sivua ei ole tai sinulla ei ole sen käyttöoikeutta."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etenet hieman liian nopeasti, meillä on vaikeuksia pysyä perässä."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei mitään"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutokset tallennettu"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallentamattomia muutoksia tehty"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käytössä"]), _normalize([_interpolate(_named("n")), " käytössä"]), _normalize([_interpolate(_named("n")), " käytössä"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistetty"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotu"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonut"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriittinen"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Entinen jäsen"]), _normalize(["entinen jäsen"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tästä"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valittu"]), _normalize([_interpolate(_named("n")), " valittu"]), _normalize([_interpolate(_named("n")), " valittu"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty viimeksi"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...ja +1 muu"]), _normalize(["...ja +", _interpolate(_named("n")), " muuta"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+1 muu"]), _normalize(["+", _interpolate(_named("n")), " muuta"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uusi"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei mitään"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei ole"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tai"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " vastausta"]), _normalize([_interpolate(_named("n")), " vastaus"]), _normalize([_interpolate(_named("n")), " vastausta"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tänään"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["käyttäjä"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Perjantai"]), _normalize(["Perjantait"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Maanantai"]), _normalize(["Maanantait"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lauantai"]), _normalize(["Lauantait"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sunnuntai"]), _normalize(["Sunnuntait"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Torstai"]), _normalize(["Torstait"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tiistai"]), _normalize(["Tiistait"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Keskiviikko"]), _normalize(["Keskiviikot"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eilen"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Järjestelmänvalvoja"]), _normalize(["järjestelmänvalvoja"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Asiakaspäällikkö"]), _normalize(["asiakaspäällikkö"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Käyttäjä"]), _normalize(["käyttäjä"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioitu"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntosi on päättynyt."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautumiset"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä ei voi olla tyhjä."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohteita ei löytynyt."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaavia henkilöitä."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Omistaja"]), _normalize(["omistaja"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue ja hyväksy ensin ehdot, niin voimme aloittaa."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetty"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehdyt arvostelut yhteensä"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tukihenkilö"]), _normalize(["tukihenkilö"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Liidi"]), _normalize(["liidi"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Esimies"]), _normalize(["esimies"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Arvostelija"]), _normalize(["arvostelija"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonyymi käyttäjä"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelijä"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pian"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatka"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi napsauttamalla"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi linkki"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaara-alue"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus (valinnainen)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistus vaaditaan"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käytöstä"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko hylätä tallentamattomat muutokset?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota käyttöön"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodatin"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleisyys"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tavoite"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selvä"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelu"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (sisäinen laatuarvosana)"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kielet"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei koskaan"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läpäisyprosentti"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset asetukset"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiili"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue vähemmän"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä uudelleen"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltava"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut/nähdyt arvostelut"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan jäsenen tekemät arvostelut"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna muutokset"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binääriasteikko"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 pisteen asteikko"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 pisteen asteikko"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 pisteen asteikko"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortti"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse yleisyys"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vähemmän"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä lisää"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korostus"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihe"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselypalaute"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajanjakso"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallentamattomia muutoksia tehty"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painotus"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kutsui sinut Zendesk QA:han."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Käyttäjät yhdistetään automaattisesti hallinnointikeskuksesta. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien hallinta"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää käyttäjien hallinnasta"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jotkin käyttäjät valmistellaan automaattisesti ja synkronoidaan tunnistetietojen toimittajaltasi. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjän ”", _interpolate(_list(0)), "” tilin käyttöoikeutta muutettu"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää käyttäjien käyttäjäoikeuksista"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä kysy uudelleen"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kaikkia Käyttäjät-sivulla tehtyjä muutoksia sovelletaan työtiloihin. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä tarkoittaa, että käyttäjän käyttöoikeudet tiettyihin Zendesk QA:n osiin saattaa muuttua"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta tilitason käyttöoikeuksia"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä poistettu"]), _normalize([_interpolate(_named("n")), " käyttäjää poistettu"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kun poistat käyttäjän, hän menettää tilisi käyttöoikeuden. Hänen arvostelunsa, kommenttinsa ja arviointinsa säilyvät Zendesk QA:ssa. ", _interpolate(_named("linebreak")), "Käyttäjän poistaminen ei vaikuta tilauksesi paikkojen määrään."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tämä käyttäjä poistetaan kaikista hallitsemistasi työtiloista. ", _interpolate(_named("linebreak")), " Hänen arvostelunsa, kommenttinsa ja arviointinsa säilyvät ennallaan."]), _normalize(["Hänet poistetaan kaikista hallitsemistasi työtiloista. ", _interpolate(_named("linebreak")), " Kaikki hänen arvostelunsa, kommenttinsa ja arviointinsa säilyvät ennallaan."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Poista ", _interpolate(_named("name"))]), _normalize(["Poista ", _interpolate(_named("name")), " käyttäjää"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi nähdä kaiken ja hallita kaikkia asetuksia ja laskutusta"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi nähdä kaiken ja hallita henkilökohtaisia asetuksia ja työtilan asetuksia, paitsi laskutusta"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin käyttöoikeudet"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilakohtaiset käyttöoikeudet sallivat käyttäjien tarkastella (ja muokata) tietoja työtiloissa, joihin he kuuluvat."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää BotQA:sta"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottisi näytetään tässä, kun keskustelut, joihin ne ovat osallistuneet, synkronoidaan"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei synkronoituja botteja"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botin merkitseminen käyttäjäksi antaa sille oikeuden kirjautua sisään. Botin historiatiedot ovat edelleen käytettävissä hallintapaneeleissa."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tällaisia botteja ei löytynyt"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä hakea uudelleen käyttämällä toista avainsanaa tai kirjoitusasua."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae botteja"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae botin nimen mukaan"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse käyttäjäksi"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain järjestelmänvalvojat ja tilivastaavat voivat merkitä botteja käyttäjiksi"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botin nimi"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen chatti"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitty manuaalisesti"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkastettava"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botti poistetaan automaattisesta analysoinnista ja mahdollisista delegoinneista. Uudet tiedot eivät näy hallintapaneeleissa, mikä varmistaa tarkan raportoinnin ja helpomman botin hallinnan."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyllä"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generatiivinen botti"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työnkulkubotti"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ryhmiin"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki ryhmät"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei ryhmää"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmistä"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ryhmä"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin käyttöoikeus"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää työtiloihin"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko nimi"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeksi aktiivinen"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilitason rooli"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullekin käyttäjälle annetaan tili- ja/tai työtilatason käyttöoikeus. Järjestelmänvalvojan oikeudet ovat nyt tilitason oikeuksia, kun taas muut käyttöoikeudet ovat työtilatason oikeuksia."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista työtiloista"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan käyttöoikeus"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmässä hallinnoidut oikeudet"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa tietoja"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse botiksi"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän merkitseminen botiksi poistaa hänen oikeutensa kirjautua sisään. Hänen käyttäjätietojensa historiatiedot ovat edelleen käytettävissä hallintapaneeleissa"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista botti automaattisesta analysoinnista ja kaikista toimeksiannoista. Uudet tiedot eivät näy hallintapaneeleissa, mikä varmistaa tarkan raportoinnin ja helpottaa bottien hallintaa."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä botti pois arvosteluista"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä merkitty bottiksi"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain Käyttäjän käyttöoikeustyyppi voidaan merkitä bottiksi"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjiä, joilla on useita tilejä, ei voida merkitä boteiksi"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae käyttäjän mukaan"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botit"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät, botit ja työtilat"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYÖTILAT"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät ja työtilat"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestelmänvalvoja"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei oikeuksia"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistetyt"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuaalisesti lisätyt"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottavat"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottava kutsu"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-käyttäjät"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellyttää toimintaa"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...ja yksi muu"]), _normalize(["...ja ", _interpolate(_named("n")), " muuta"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda käyttöoikeudeksi"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Poista käyttäjä ja kutsu hänet uudelleen vaihtaaksesi ", _interpolate(_named("n")), " kutsutun käyttäjän roolia."]), _normalize(["Poista käyttäjät ja kutsu heidät uudelleen vaihtaaksesi ", _interpolate(_named("n")), " kutsutun käyttäjän roolia"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Muokkaa käyttäjän ", _interpolate(_named("name")), " tietoja"]), _normalize(["Muokkaa ", _interpolate(_named("n")), " valitun käyttäjän tietoja"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä päivitetty."]), _normalize([_interpolate(_named("n")), " käyttäjää päivitetty."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tämä ryhmä sisältää yhden käyttäjän toisesta työtilasta."]), _normalize(["Tämä ryhmä sisältää ", _interpolate(_named("n")), " käyttäjää toisesta työtilasta."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” arkistoitu"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoitu ryhmä"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo ryhmä"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” luotu"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimetön ryhmä"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistaa tämän ryhmän pysyvästi?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmä"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmä"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa ryhmää"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän johtaja"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli ryhmässä"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” piilotettu"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä ryhmää ei näytetä missään muualla Zendesk QA:ssa"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse vain työtilan oikeuksia"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmää ei näytetä Zendesk QA:ssa"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Johtaja"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsen"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimetön ryhmä"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toisella ryhmällä on jo tämä nimi"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoidulla ryhmällä on jo tämä nimi"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roolisi sallii vain tämän ryhmän jäsenten hallinnan. Ota yhteyttä käyttäjään, jolla on laajemmat käyttöoikeudet, jos haluat tehdä muutoksia."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmän johtajan roolista"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” palautettu"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” tallennettu"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta ryhmän johtajaksi"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” palautettu arkistosta"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallentamattomia muutoksia"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää nykyiset käyttäjäsi tähän ryhmään"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae nimellä tai sähköpostilla"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää jäseniä"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ryhmä ”", _interpolate(_named("name")), "” näkyvissä"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kukin jäsen on ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää ryhmistä"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotkin ryhmien attribuutit valmistellaan ja synkronoidaan automaattisesti tunnistetietojen toimittajaltasi."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää ryhmistä"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo ryhmä"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisoi tiimisi jäsenet helposti ja jäljittele organisaatiosi rakennetta."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää vähintään yksi jäsen"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ryhmän nimi"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samanniminen ryhmä on jo olemassa"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän jäsenet"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän nimi"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisoi tiimisi jäsenet ja jäljittele organisaatiosi rakennetta."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsenet"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ei ryhmiä"]), _normalize(["1 ryhmä"]), _normalize([_interpolate(_named("count")), " ryhmää"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi ryhmä tai aktivoi sen sijaan arkistoitu ryhmä."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivisia ryhmiä käytettävissä"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei arkistoituja ryhmiä"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmiä ei löytynyt"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä hakea uudelleen käyttämällä toista avainsanaa tai kirjoitusasua."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei lisättyjä ryhmiä"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["näissä"]), _normalize(["työtilassa"]), _normalize(["työtiloissa"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae ryhmän nimen mukaan"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoidut"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Uudelleenlähetys on mahdollista ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " paikkaa jäljellä"]), _normalize([_interpolate(_named("n")), " paikka jäljellä"]), _normalize([_interpolate(_named("n")), " paikkaa jäljellä"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paikkojen lukumäärä on tällä hetkellä rajoitettu, koska tilauksesi on keskeytetty. Aktivoi se uudelleen lisätäksesi käyttäjiä."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet saavuttanut tilin paikkojen rajoituksen. Voit korvata käyttäjiä kumoamalla aktiivisen käyttäjän aktivoinnin tai lisäämällä paikkoja."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä maksutiedot"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " valittu"]), _normalize([_interpolate(_named("n")), " valittu"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä valittu"]), _normalize([_interpolate(_named("n")), " käyttäjää valittu"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " riviä"]), _normalize([_interpolate(_named("n")), " rivi"]), _normalize([_interpolate(_named("n")), " riviä"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae useita käyttäjiä heidän nimiensä tai sähköpostiosoitteidensa perusteella."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä tiedot tähän pilkuilla tai rivinvaihdoilla erotettuina"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae useita käyttäjiä"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Muokkaa jäsenen ", _interpolate(_named("name")), " työtilan käyttöoikeuksia"]), _normalize(["Muokkaa ", _interpolate(_named("n")), " valitun jäsenen työtilan käyttöoikeuksia"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa käyttöoikeuksia"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse työtilan käyttöoikeus"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Poista ", _interpolate(_named("name")), " työtilasta"]), _normalize(["Poista ", _interpolate(_named("name")), " jäsentä työtilasta"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " jäsen poistettu"]), _normalize([_interpolate(_named("n")), " jäsentä poistettu"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Poista jäsen"]), _normalize(["Poista jäsenet"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tämä käyttäjä poistetaan työtilasta."]), _normalize(["Nämä käyttäjät poistetaan työtilasta."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Jäsenen ”", _interpolate(_list(0)), "” työtilan käyttöoikeutta muutettu."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää jäseniä"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee keskustelut ja niihin liittyvät arvostelut"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee kaiken työtilan sisällön, mutta voi hallita vain testejä, toimeksiantoja, kiistoja ja kalibrointi-istuntoja"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan käyttöoikeudet"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee kaiken työtilan sisällön ja voi hallita sitä"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee kaiken työtilan sisällön, paitsi työtilan asetukset"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yhdistä käyttäjiä"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki hallitsemasi työtilan käyttäjät on lisätty tähän työtilaan."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä järjestelmänvalvojaa tekemään niin."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu uusia käyttäjiä suoraan itse tai pyydä järjestelmänvalvojaa tekemään niin."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " tai ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä käyttäjiä"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " käyttäjä lisätty"]), _normalize([_interpolate(_named("n")), " käyttäjää lisätty"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lisää jäseniä"]), _normalize(["Lisää jäsen"]), _normalize(["Lisää ", _interpolate(_named("n")), " jäsentä"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse työtilan käyttöoikeus"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse työtilaan lisättävät käyttäjät"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lisää jäseniä työtilaan ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä sähköpostit tähän ja erota ne toisistaan rivinvaihdoilla tai pilkuilla"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " sähköpostia täsmätty"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" jo yhdistettyihin käyttäjiin:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " sähköpostille ei löytynyt vastinetta"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista oikeinkirjoitus tai yhdistä ne myöhemmin yksitellen"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tukipalvelun käyttäjiin:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä yhdistetty"]), _normalize([_interpolate(_named("n")), " käyttäjää yhdistetty"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä kutsuttu"]), _normalize([_interpolate(_named("n")), " käyttäjää kutsuttu"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä useita"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu useita"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimerkiksi:\n\nmaija", "@", "esimerkki.com, matti", "@", "esimerkki.com\n\nmaija", "@", "esimerkki.com\nmaija", "@", "esimerkki.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tukipalvelusta yhdistettävät käyttäjät arvostellaksesi heidän vuorovaikutuksiaan"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Yhdistä käyttäjä"]), _normalize(["Yhdistä käyttäjät"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostit"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä yksitellen"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat, että yhdistetyt käyttäjät voivat käyttää Zendesk QA:ta, voit kutsua heidät täältä."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu yhdistettyjä käyttäjiä"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kutsu käyttäjä"]), _normalize(["Kutsu käyttäjiä"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita kutsuminen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjiä, joihin muodostat yhteyden tukipalveluista, jotta voit arvostella heidän vuorovaikutuksiaan."])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse tilaustasi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä tukipalvelun käyttäjiä"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna työtilallesi yksilöllinen nimi"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä asetus on käytössä, kaikki olemassa olevat tiliyhteydet lisätään automaattisesti uuteen työtilaan."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kaikki yhteydet tähän työtilaan"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo työtila"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilan nimi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo työtila"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät, jotka on lisätty tiliin suoraan (lisäämättä heitä johonkin työtilaan), saavat tilitason roolin. Voit lisätä heidät työtiloihin myöhemmin."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsutuille käyttäjille myönnetään automaattisesti käyttäjärooli tilitasolla. Voit päivittää heidät järjestelmänvalvojan rooliin myöhemmin."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nämä kaikki toimivat"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä sähköpostit tähän ja erota ne toisistaan rivinvaihdoilla tai pilkuilla."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " käyttäjä kutsuttu sähköpostitse"]), _normalize([_interpolate(_named("n")), " käyttäjää kutsuttu sähköpostitse"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilaan lisätyille käyttäjille myönnetään automaattisesti käyttäjärooli tilitasolla. Voit päivittää heidät järjestelmänvalvojan rooliin myöhemmin."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää toinen"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu käyttäjät vain tilille lisäämättä heitä työtilaan"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsutuille käyttäjille delegoidaan vain tilin käyttöoikeus, eikä heitä lisätä mihinkään työtilaan."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu käyttäjät suoraan työtilaan ja myönnä heille työtilan käyttöoikeudet"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää käyttäjiä työtilaan"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu useita"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu yksitellen"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu vain tilille"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu työtilaan"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kutsu käyttäjiä"]), _normalize(["Kutsu käyttäjä"]), _normalize(["Kutsu ", _interpolate(_named("n")), " käyttäjää"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu useita käyttäjiä sähköpostitse"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Poista käyttäjä"]), _normalize(["Poista käyttäjät"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista valinta"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu käyttäjiä sähköpostitse"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei työtiloja"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttöoikeus"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kutsu uudelleen"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistetty käyttäjä"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottavan sähköpostikutsun käyttäjä"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-käyttäjä"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse työtila"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekoälytukihenkilöt"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallinnointikeskus"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadunvarmistus"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työvoiman hallinnointi"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " suoritettu"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " suoritettu"]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " suoritettu"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sykli ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelija"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa toimeksiantoa"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säästä aikaa määrittämällä automaattisia arvostelutoimeksiantoja sen sijaan, että etsisit ja allokoisit keskusteluja manuaalisesti arvosteltavaksi."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erääntyy ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntoja voidaan lisätä keskustelunäkymän sivuvalikon Kalibrointi-osiosta."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä lisättyjä istuntoja"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perustaso"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei istuntoja"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskusteluja voidaan lisätä istuntoon napsauttamalla kalibrointikuvaketta keskustelunäkymän yläpalkista."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä lisättyjä keskusteluja"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arvosteluja ei ole annettu. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätä kalibrointiarvostelu"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurisyy"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry kohteeseen"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentopaletin lataaminen epäonnistui."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitään ei löytynyt."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löytyi tunnuksen perusteella"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan komentopalettia..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sivut, suodattimet, keskustelut, asetukset..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskustelu"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityinen suodatin"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkinen suodatin"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Palvelusta ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-rajapinnastasi"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Näytetään ", _interpolate(_named("rangeStart")), "–", _interpolate(_named("rangeEnd")), ", ", _interpolate(_named("label")), ": ", _interpolate(_named("total"))]), _normalize(["Näytetään ", _interpolate(_named("rangeStart")), "–", _interpolate(_named("rangeEnd")), ", ", _interpolate(_named("label")), ": ", _interpolate(_named("total"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota sivupaneeli"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä sivupaneeli"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota sivupalkki"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt kiistat"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätyt kiistat"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet kiistat"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osittain hyväksytyt kiistat"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehdyt arvostelut"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nähdyt arvostelut"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hyväksytyt"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avoimet"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["osittain hyväksytyt"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kokonaismäärästä"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hylätyt"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osittain hyväksytyt"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätyt"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["arvostelu"]), _normalize(["arvostelut"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosteltavien näkemien arvosteluiden määrä"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nähtyjen arvosteluiden määrä"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistautetut arvostelijat"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistäjät"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat kategorian mukaan"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat ajanjaksolla"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen ratkaisuaika"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen ratkaisuaika (h)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvoinen ratkaisuaika (min)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratkaistu"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiistat yhteensä"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse aihetunniste"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytyt"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastauksen kieli"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieliä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kielet"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennustetut osatekijät"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osatekijöitä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki osatekijät"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanapilven sanat"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanoja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki sanat"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maininnat"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusin"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhin"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoimet"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osittain hyväksytyt"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " lähetti reaktion ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätyt"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratkaistut"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvostelut"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ihonväri"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunnot"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istuntoja ei löytynyt"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriat"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorioita ei löytynyt"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentit"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin koko"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokoja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki koot"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodattimia ei löytynyt"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmiä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki ryhmät"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteet"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteita ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki aihetunnisteet"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvoja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvot"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mukautettuja kenttiä ei löytynyt"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteet"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukipalvelun tunnisteita ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki tukipalvelun tunnisteet"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan tuloksia…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymystyypit"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymystyyppejä ei löytynyt"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää reaktio"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortit"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanakortteja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosanakortit"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanat"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosanoja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki arvosanat"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähteet"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähteitä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki lähteet"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen syy"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syitä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki syyt"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyt"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyjä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kyselyt"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavat"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavia ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki kanavat"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjiä ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki käyttäjät"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtiloja ei löytynyt"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki työtilat"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin testeihin"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi on poistettu tai sitä ei ole vielä julkaistu. Tarkista linkki tai siirry takaisin testien luetteloon."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä testiä ei ole jaettu työtilaan, johon kuulut. Pyydä järjestelmänvalvojaa lisäämään sinut työtilaan, johon tällä testillä on pääsy."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi ei ole käytettävissä"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin käyttöoikeus myönnetty työtiloille, joihin et kuulu. Testin tallentaminen ja julkaiseminen epäonnistui."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä järjestelmänvalvojaa tallentamaan ja julkaisemaan testi."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun tulisi merkitä vähintään yksi vastauksista oikeaksi."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kenttä ei voi olla tyhjä."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näet tällä hetkellä testin esikatselun."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse, mitkä työtilat voivat käyttää testiä. Useisiin työtiloihin kuuluvat käyttäjät voivat vastata testiin vain kerran. ”Kaikki työtilat” -vaihtoehdon valitseminen ei sisällytä mukaan uusia luotuja työtiloja, kun testi on julkaistu."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin käyttöoikeus"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistu esikatselutilasta"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselu"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymykset"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaukset"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaa testiin"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise testi"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin asetukset"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna luonnoksena"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi testi"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta arkistosta"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaihtoehdot"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää vaihtoehto"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa se"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näyttää hyvältä, eikö?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastauksesi on lähetetty"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testisi on tallennettu"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testisi on julkaistu"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testisi on poistettu"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kysymys"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi testi"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoitua testiä tai sen tuloksia ei enää näytetä testien luettelossa. Voit silti käyttää arkistoidun testin tuloksia ja palauttaa sen arkistosta tarvittaessa."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoi testi"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaise"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun testi on julkaistu, sitä ei voi muuttaa tai muokata. Kaikki julkaistut testit ovat kaikkien tilisi käyttäjien käytettävissä."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko julkaista testin?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän testin? Tätä toimintoa ei voi kumota."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko poistaa testin?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun vastaukset on lähetetty, niitä ei voi muuttaa."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko lähettää testin?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta testi arkistosta"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin palauttaminen arkistosta sallii sen käytön testien yleiskatsauksessa. Näin aiemmat osallistujat voivat tarkastaa tuloksensa ja uudet osallistujat voivat vastata testiin."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta testi arkistosta"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi kysymys"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus (valinnainen)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrä kysymys alas"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrä kysymys ylös"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymys"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselussa näytetään vain kysymykset, joihin on vastattu."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kysymys"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä testi"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimetön testi"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin otsikko"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa testin ja sen tulokset pysyvästi."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit sen sijaan arkistoida testin käyttääksesi sen tuloksia myöhemmin."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastattu"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista testi"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä poistaa testin ja sen tulokset pysyvästi."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poista testi ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit auttavat tiimin itseluottamuksen ja asiantuntemuksen parantamisessa, yhdenmukaisten kokemusten tarjoamisessa sekä tiimin uusien jäsenten perehdyttämisessä."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit hakea testejä niiden nimen tai niiden tekijän nimen perusteella."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä luotuja testejä"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie testien luettelo"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvosana"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suorituspäivä"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi / Tekijä"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi / Tekijä / Työtilat"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistujat"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaistu"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työtilat"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae testin tai tekijän mukaan"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoitu"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luonnos"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki testit"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi on arkistoitu."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi on palautettu arkistosta."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valittujen työtilojen käyttäjät eivät vastanneet tähän testiin."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tähän testiin ei vastattu kertaakaan ennen sen arkistointia."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastauksia"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näet testin vastausten erittelyn täällä.\nKukaan ei ole vielä vastannut testiisi – sen jakaminen voisi auttaa."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vielä vastauksia"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaukset:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskiarvosana:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksilö"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – kopio"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki kopioitu"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tuloksia"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Käyttäjän ", _interpolate(_list(0)), " testit"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit hakea tulostaulukosta osallistujan nimeä."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie tulostaulukko"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testi"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvosana"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae osallistujan mukaan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulostaulukko"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testit"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tai piilota tulostaulukko"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä tarkoittaa, että tietojen suodattamiseen hallintapaneelissa ei voi käyttää tätä aihetunnistetta, eikä sitä enää automaattisesti ehdoteta automaattisesti kommentteja kirjoitettaessa."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunniste, aihetunniste, aihetunniste, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo aihetunnisteita"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo aihetunnisteita"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo aihetunnisteita"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salli jäsenten luoda uusia aihetunnisteita"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunniste"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytetty"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista aihetunniste"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poista aihetunniste ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteiden luonti on estetty"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aihetunnisteiden luonti on sallittu"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aihetunniste ”", _interpolate(_list(0)), "” poistettiin"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aihetunnisteen ”", _interpolate(_list(0)), "” nimeksi muutettiin ”", _interpolate(_list(1)), "”"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 aihetunniste lisätty"]), _normalize([_interpolate(_named("count")), " aihetunnistetta lisätty"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi nimi"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei luotuja aihetunnisteita"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimeä uudelleen"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nimeä aihetunniste ”", _interpolate(_named("tagRenamed")), "” uudelleen"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erota aihetunnisteet pilkuilla tai rivinvaihdoilla"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae aihetunnisteita"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ei hätää. Voit tehdä niin ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suoraan täältä"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys linkitetty työtilaan"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä yhteys"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä yhteys tähän työtilaan"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota yhteyttä järjestelmänvalvojaasi tehdäksesi muutoksia täällä"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota yhteyttä Zendesk QA:han tehdäksesi muutoksia täällä"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilillä ei ole yhteyksiä"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ole nähtävästi integroinut asiakaspalvelualustaa vielä."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yhteyden poistaminen poistaa keskustelut työtilasta ", _interpolate(_named("workspaceName")), " sekä kaikki keskusteluihin liittyvät tiedot, mukaan lukien arvostelut ja kiistat."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista yhteys"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Poista yhteys ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse yhteys"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tietyt aktiiviset AutoQA-kategoriat eivät olleet tuloskortissasi valitulla ajanjaksolla. Keskusteluja, jotka käytiin ennen viimeksi tekemääsi muutosta ", _interpolate(_named("date")), ", ei välttämättä analysoitu näille kategorioille."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki sinulle annettu palaute näytetään täällä. Ei vielä mitään näytettävää."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vastaanotettuja arvosteluja."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vain kommenttiaktiviteetti"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki antamasi palaute näytetään täällä. Aloita Keskustelut-näkymästä."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei annettuja arvosteluja."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annetut arvostelut"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanotetut arvostelut"])}
  }
}