import ky, { type NormalizedOptions, type Options } from 'ky'
import config, { hasZendeskProxy } from '@/config'
import i18n from '@/i18n'
import { bus } from '@/utils/bus'
import { session } from '@/composables/useSession'
import { getAccessToken, logout } from '@/modules/auth/api'

export const prefix = (id: string | number) => `/payments/${id}`

/**
 * APIError used for logging
 */
export class APIError extends Error {
  body: string
  status: number
  traceContext: string | null

  constructor(response: Response, body: string) {
    const url = response.url.replace(window.location.origin, '')
    super(`${response.status} ${url}`)
    this.name = 'APIError'
    this.body = body
    this.status = response.status
    this.traceContext = response.headers.get('X-Cloud-Trace-Context')
  }
}

export const accessTokenHook = async (request: Request) => {
  if (!hasZendeskProxy) {
    const accessToken = await getAccessToken()
    request.headers.set('Authorization', `Bearer ${accessToken}`)
  }

  if (session.account) request.headers.set('X-Klaus-Account', session.account.id.toString())
}

export const errorHandlerHook = async (request: Request, _options: NormalizedOptions, response: Response) => {
  let res = await (response.headers.get('content-type') === 'application/json'
    ? response.clone().json()
    : response.clone().text())
  if (typeof res === 'string') res = { error: res.trim() }

  const traceContext = response.headers.get('X-Cloud-Trace-Context')

  switch (response.status) {
    case 401:
      // do not show error popup for session 401's
      if (request.url.includes('users/session')) break
      bus.$emit('universal-error', {
        error: i18n.t('universal.errors.401_v2'),
        request,
        response: res,
        ref: traceContext,
      })
      break
    case 400:
    case 404:
    case 406:
      bus.$emit('universal-error', { ...res, response: res, request, ref: traceContext })
      break
    case 429:
      bus.$emit('universal-error', {
        error: i18n.t('universal.errors.slow_down'),
        request,
        response: res,
        ref: traceContext,
      })
      break
    case 500:
      bus.$emit('universal-error', {
        error: i18n.t('universal.errors.500'),
        ref: traceContext || 'internal-error',
        status: response.status,
        request,
        response: res,
      })
      break
  }

  if (res.errorCode === 'payment_authentication_method_disabled') {
    const { sub } = session.idTokenPayload
    const method = sub.substring(0, sub.lastIndexOf('|'))
    return logout({ disabledAuthMethod: method })
  }

  if (res.errorCode === 'technical_exception') {
    bus.$emit('universal-error', { ...res, response: res, request, ref: traceContext })
  } else if (res.errorCode === 'payment_user_limit_reached') {
    bus.$emit('user-limit-reached')
  }

  if (!response.ok) {
    const body = await response.clone().text()
    throw new APIError(response, body)
  }
}

export const baseApi = (options?: Options) =>
  ky.extend({
    timeout: 120 * 1000,
    retry: 0,
    hooks: {
      beforeRequest: window.Cypress?.testingType === 'component' ? [] : [accessTokenHook],
      afterResponse: [errorHandlerHook],
    },
    ...options,
  })

export const prefixUrl = config.apiOverride ? `${config.apiOverride}/api` : `${config.baseUrl}/api`

export const api = baseApi({ prefixUrl })
export const zendeskApi = baseApi({ prefixUrl: '/api' })

export const oldApiPrefixUrl = config.apiOverride ? `${config.apiOverride}/old-api` : `${config.baseUrl}/old-api`
export const oldApi = baseApi({ prefixUrl: oldApiPrefixUrl })
