export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете поддомейна си в Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане в Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата компания има съществуващ акаунт."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присъединете се към компания"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да се присъедините към съществуваща компания, помолете вашия администратор да ви изпрати покана."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помолете вашия администратор да ви покани"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промених мнението си."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна на регистрация"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата компания има съществуващ акаунт. Искате ли да се присъедините към акаунта на вашата компания или да създадете нов?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирайте инструкциите"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на нов акаунт"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Има съществуващ акаунт"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате достъп до акаунта си в Zendesk QA, докато не бъдат платени дължимите фактури за абонамента."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият абонамент е в режим на изчакване"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунтът не е намерен. Опитайте отново."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вземете нова парола."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опитайте отново или ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешна комбинация от имейл и парола."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявете нова от вашия администратор."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази връзка с покана е невалидна или вече е била използвана."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Методът за влизане ", _interpolate(_named("method")), " е деактивиран във вашия акаунт."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази покана е невалидна или използвана. Заявете нова от вашия администратор."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно зареждане на информацията за поканата."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрацията е неуспешна. Потребител с този имейл може вече да съществува."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият акаунт е блокиран след няколко последователни опита за влизане. Проверете входящата си кутия за имейл с инструкции как да го разблокирате."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първо потвърдете имейла си"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден имейл акаунт"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за влизане"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпращане на магическа връзка"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служебен имейл адрес"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият служебен имейл адрес"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Основно споразумение за услуги)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролата не отговаря на изискванията"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декларация за поверителност"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчакайте"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Здравейте, ", _interpolate(_named("invitee_name"))])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Присъедините се към организация на ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият имейл е потвърден. Вече можете да влезете с новия си акаунт по-долу."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване с Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалидна връзка с покана"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане с помощта на магическа връзка"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратно към влизане"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване със Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване със Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият имейл е потвърден. Вече можете да влезете с новия си акаунт по-долу:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане с Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане чрез магическа връзка"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане със Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане в Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият акаунт е изтрит"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате акаунт?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден код"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден имейл акаунт"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код за влизане"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички опции за влизане"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " чрез традиционни методи"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане с помощта на магическа връзка"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получихте имейл, който съдържа вашия код за влизане."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получихте имейл, който можете да последвате, за да влезете."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка при изпращането на магическа връзка. Опитайте отново."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане чрез имейл"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на парола"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един знак с малки букви"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един знак с големи букви"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новата парола трябва да съдържа"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максималната дължина на паролата е 128 знака"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лесно за запомняне, трудно за отгатване"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум 8 знака"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте паролата си"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забравена парола?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един знак с малки букви"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Една цифра"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един знак с големи букви"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да нулирате вашата парола, въведете вашия имейл адрес и ние ще ви изпратим имейл с инструкции."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден имейл акаунт"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране на паролата"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изпратен е имейл до ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако имейлът не се появи във входящата ви поща или в папката за спам, проверете въведения от вас имейл."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратно към влизане"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверете имейла си"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране на паролата"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", ще ви бъде изпратен имейл за нулиране на парола."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако за вашия акаунт има съществуваща парола"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ако този ", _interpolate(_named("bold")), ", тогава ви изпратихме връзка за нулиране на паролата."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имейл е свързан с акаунт"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помните ли паролата си?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка при изпращането на връзката за нулиране. Опитайте отново."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулирайте паролата си"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране с помощта на магическа връзка"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика за поверителност"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Като се регистрирате, вие се съгласявате с ", _interpolate(_named("terms_of_service")), " и ", _interpolate(_named("privacy_policy")), " на Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия за ползване"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имате акаунт?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране чрез магическа връзка"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране със служебен имейл адрес"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране със Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране в Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране с Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имам активен акаунт в Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Регистрирайте се или влезте, за да завършите своята настройка за ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наистина ли си сте вие? Проверете входящата си кутия за имейл за потвърждение."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко е готово"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA функционира най-добре на настолен компютър. Влезте от такъв, за да започнете да преглеждате разговорите."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете час за демонстрация"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или запазете час за демонстрация с нас и ние ще ви покажем какво можете да правите в Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашето присъствие беше заявено"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отменя"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Размислих и искам да ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " ви кани да се присъедините към неговия акаунт."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присъединете се към акаунта"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на нов акаунт"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да влезете отново"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Реакцията, която получихте за вашия отзив: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени реакции"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " реагира на вашия коментар"]), _normalize(["Няколко потребители реагираха на вашия коментар"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " реагира на вашия отговор"]), _normalize(["Няколко потребители реагираха на вашия отговор"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " реагира на вашия преглед"]), _normalize(["Няколко потребители реагираха на вашия преглед"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментар"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отговор"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["преглед"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОСПОРВАНИЯ"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАПРАВЕНИ"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОЛУЧЕНИ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив от анкета"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакции"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнати"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Да се маркира ли коментарът като прочетен?"]), _normalize(["Да се ​​маркират ли всички коментари като прочетени?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Да се маркира ли оспорването като прочетено?"]), _normalize(["Да се маркират ли всички оспорвания като прочетени?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Да се маркират ли отзивите за анкетата като прочетени?"]), _normalize(["Да се ​​маркират ли всички отзиви за анкетата като прочетени?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Да се ​​маркира ли прегледът като прочетен?"]), _normalize(["Да се ​​маркират ли всички прегледи като прочетени?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Маркиран коментар като прочетен"]), _normalize(["Маркирани всички коментари като прочетени"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Маркирано оспорване като прочетено"]), _normalize(["Маркирани всички оспорвания като прочетени"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Маркиран отзив от анкетата като прочетен"]), _normalize(["Маркирани всички отзиви за анкетата като прочетени"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Маркиран преглед като прочетен"]), _normalize(["Маркирани всички прегледи като прочетени"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране на всички като прочетени"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране като прочетен"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледи"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато някой оспори преглед, той се появява тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма оспорвания"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато ви бъде възложено да разрешите оспорване, то се появява тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получени оспорвания"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато оспорите преглед, той се появява тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма започнати спорове"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички отзиви за вас се появяват тук. Все още няма нищо за показване."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получен отзив"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички реакции към вашите прегледи и отговори се показват тук. Все още няма нищо за показване."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получени реакции"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартиране на разговор"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички отзиви, направени от вас, накрая попадат тук. Започнете, като оставите преглед в изгледа „Разговори“."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са направени прегледи"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички отзиви за вас се появяват тук. Все още няма нищо за показване."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получени прегледи"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени коментари"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички отзиви, които оставите като коментари, се показват тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма направени коментари"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени коментари"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички коментари под ваш преглед, ", "@", "споменавания или отговори на ваши коментари се показват тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получени коментари"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма приети оспорвания"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отворени оспорвания"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отхвърлени оспорвания"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разрешени оспорвания"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте отново, като изберете друга опция от филтъра по-горе."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте отново, като изберете друг потребител от филтъра по-горе."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени коментари"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени коментари"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички оспорвания"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени оспорвания"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнати оспорвания"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получен отзив от анкета"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени прегледи"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списък с дейности"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " прегледани"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " прегледан"]), _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " прегледани"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подновяване в ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Остават ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте възложена задача"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списъка с възложени задачи"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Не е възможно да се създаде коучинг сесия между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), ", тъй като в момента те не са в едно работно пространство."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Коучинг сесии между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), " вече съществуват. Свържете се с ", _interpolate(_list(0)), ", за да получите достъп до сесията."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Препоръчани"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Създадена е нова сесия между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коуч"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете коуч"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на коучинг"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете обект на коучинг"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесия"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на сесия"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте сесия"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на сесия"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на сесия"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Препоръчани"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просрочена"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минала"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстояща"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на сесия"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити сесии"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесии"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно време за преглед"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролен панел"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма данни за показване за избрания период"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория на оценяването"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Към сесията е добавен закачен елемент. ", _interpolate(_named("buttonStart")), "Покажи ми", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към…"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавено"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стигнахте до края на закачените елементи от избрания период"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вижте как функционират закачените елементи в тази ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статия"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изглежда, че не сте закачили нищо към ", _interpolate(_list(0)), ". Можете да използвате закачени елементи, за да се подготвите за индивидуални срещи или да запазите нещо за следваща среща."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обектът на коучинг"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма добавени закачени елементи"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ето всички закачени елементи, които сте създали към ", _interpolate(_named("name")), ". Закачени елементи, добавени към сесиите, ще бъдат ", _interpolate(_named("bold")), " с другия участник."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["незабавно споделени"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изглежда, че ", _interpolate(_list(0)), " не е получил никакъв отзив за преглед на разговор или CSAT за избрания период."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма наличен отзив"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стигнахте до края на коментарите от избрания период"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатели"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Показателите на ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нов елемент за действие"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Завършено от ", _interpolate(_named("user")), " на ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["от ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вас"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнете да пишете, за да добавите нов елемент за действие"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какъв е резултатът от тази среща? Какви са следващите ви стъпки?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елементи за действие"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създадено от ", _interpolate(_named("name")), " на ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изтрие сесията"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Това ще ", _interpolate(_named("deleteSession")), " окончателно. Участниците губят достъп до сесията, включително нейното съдържание и личните бележки."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сесията е създадена на ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последно редактирано от ", _interpolate(_named("name")), " на ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теми за разговор и бележки"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забележката е обновена"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изход от сесията"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделяне"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избрани потребители получават достъп до подробности за сесията от този момент нататък."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избор на потребители"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделена"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделяне на сесия"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вече няма да сте част от сесия между ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " вече няма да бъде част от тази сесия."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " има достъп до тази сесия."]), _normalize([_interpolate(_named("n")), " нови потребители вече имат достъп до тази сесия"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на сесията"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на всички сесии"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на сесията"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коуч"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Бележки, добавени от ", _interpolate(_list(0)), ", ще се появят тук."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете закачени елементи като дискусионни точки от страничния панел вдясно"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бележки, които само вие можете да видите…"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични бележки"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личната бележка е обновена"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 дни"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коуч"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на коучинг"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елементи за действие"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна сесия"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължава"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просрочена"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед на сесиите"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз: Коуч"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз: Обект на коучинг"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички сесии"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделени сесии"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да превключите филтър, за да видите елементи за действие."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма елементи за действие"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елементи за действие"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени и видени прегледи"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени · Видени"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогрес след сесии"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесии"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда, че консултантите не са получили никакви отзиви."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверете дали разполагате с правилното име на потребителя"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този потребител не може да бъде намерен"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма данни"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS за последните 30 дни"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Това окончателно ще <b>изтрие всички сесии</b> (2) с <b>", _interpolate(_named("name")), "</b>. Участниците губят достъп до всички сесии, включително съдържанието и личните бележки."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стигнахте до края на всички сесии"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг сесията е групова дейност между ментор и обект на коучинг за обсъждане на ефективността, възможностите, краткосрочните и дългосрочните цели."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да търсите сесии както по името на коуч, така и по лицето, което бива обучавано."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити сесии"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма създадени сесии"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на история на коучинг"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на история на коучинг "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна сесия"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесия"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесии"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички сесии"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коуч"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на коучинг"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране като приключено"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране като неприключено"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по име"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преименуване"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списък със сесии"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ненасрочена"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговорете на това съобщение"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтекла сесия за съгласуване. Не могат да се правят допълнителни прегледи."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече направихте преглед за този разговор."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледът на този разговор вече не е възможен, тъй като крайният срок на сесията е изтекъл."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За този разговор няма прегледи"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затваряне на панела"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете преглед на съгласуването"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОДРОБНОСТИ"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РЕЗЮМЕ"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕКСТОВ ЗАПИС"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Говорител ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултант"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " сек"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["период на мълчание"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на говорител"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на съобщение"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад към съобщението, което се възпроизвежда"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Говорители"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстов запис"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители на работно пространство"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да създадете филтри, първо добавете връзка"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма ботове"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създадено на ", _interpolate(_named("time")), " от ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последно обновено на ", _interpolate(_named("time")), " от ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изглежда, че нямате филтри. За да получите достъп до тази страница, първо трябва да създадете филтър. Започнете с ", _interpolate(_named("recommendedFilter")), ", за да видите собствените си разговори. Създайте този филтър по-долу, за да продължите."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["през последните 30 дни"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условието „Създадено през последните 30 дни“ ще бъде добавено към филтри, създадени без времеви интервал"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтърът не е наличен в този план и няма да бъде взет предвид при показване на резултати"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Времето за изчакване на опциите за извличане на филтъра изтече. Опитайте отново."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за филтрирането"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител = влязло лице"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Намерени разговори: ", _interpolate(_named("count"))]), _normalize(["Намерен разговор: ", _interpolate(_named("count"))]), _normalize(["Намерени разговори: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се предлага в текущия ви план. Надградете, за да използвате тази функционалност."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на условие"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От ИИ на Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условие"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превърнете във възложена задача"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на филтър"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на филтър"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специални полета"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждаме опциите за филтриране според вашата конкретна настройка. Това може да отнеме няколко минути при първоначално зареждане."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте описателно име на вашия филтър"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на филтъра"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете опция"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условие"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публични"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на филтъра"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване на промените"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма налични стойности"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане…"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете стойност"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимост"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само активни сесии ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на по-стари сесии ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краен срок"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на сесията за съгласуване"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на сесия"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте сесия за съгласуване"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички прегледи на съгласуване, направени по време на сесията, ще бъдат окончателно изтрити."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на сесията"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на ", _interpolate(_named("sessionName")), " сесия за съгласуване"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са създадени сесии"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма активни сесии"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени сесии"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма активни сесии"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на филтър"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте личен филтър"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте публичен филтър"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтърът е добавен"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтърът ще бъде окончателно изтрит."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на филтъра"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на филтъра ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички оспорвания"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания за мен"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания от мен"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени оспорвания"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернови"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени филтри"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени лични филтри"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени публични филтри"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтри"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични филтри"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публични филтри"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Желаете ли да ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["го запазите"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това е временен изглед на филтър."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаваме вашия филтър…"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтърът е обновен"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирана категория за Автоматичен QA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последно обновяване ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложни думи"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичните прегледи се обновяват динамично въз основа на промените в казусите"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирайте в разговор"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дълги изречения"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма прогнозиран автоматичен резултат"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не бяха открити никакви грешки"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на сложни думи"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на дълги изречения"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следващ разговор"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичният QA не успя да оцени тази категория поради недостатъчно контекст. Препоръчва се ръчен преглед."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открийте автоматизирани прозрения за разговора, за да оптимизирате процеса си на преглед. Те акцентират върху важни разговори и посочват открояващи се положителни примери за вашия екип."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Обаждане]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коригирано"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрито"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " прие оспорването"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Оспорването е изпратено до ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обяснете защо смятате, че този обект на прегледа е грешният получател на този преглед"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете коментар и задайте нов обект на прегледа"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на коментар"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обяснете защо смятате, че тези резултати са несправедливи (задължително)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете коментар (задължително)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обяснете защо отхвърляте това оспорване"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на коментар"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете оспорването до"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете оспорването до"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете оспорването до"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете нов обект на прегледа"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за оспорване"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за оспорване"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на обект на прегледа"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете оспорването до"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорване"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорване на този преглед"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не съм съгласен с резултатите"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този преглед не беше предназначен за мен"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребител"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " частично прие оспорването"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " отхвърли оспорването"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приети"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично приети"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърлени"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " започна оспорване: преглед"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " започна оспорване: грешен обект на прегледа"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да бъде разрешено отворено оспорване, за да се предприемат други действия по прегледа."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приемане"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете нови резултати"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на оспорване"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да отхвърлите това оспорване?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на оспорване"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорване"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време за разрешаване на оспорване"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете нови справедливи резултати (по избор)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на оспорване"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешен обект на прегледа"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приемане на оспорване"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приемане на оспорване"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за оспорване"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешен обект на прегледа"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за оспорване"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне на оспорване"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне на оспорване"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте оспорването"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирано"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " от ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["до ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледани"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възлагане на:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставете и коментар"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за условните карти за резултат"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По-рано възложен на:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто изберете един"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не открихме никакви GIF файлове с това име"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бъдете първите, направили преглед."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият абонамент в момента е на пауза. Активирайте го отново, за да разрешите даването на отзиви."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикувано от:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това показва колко време е прекарано в преглед на този разговор"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребител"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Липсва първопричина за категория: „", _interpolate(_list(0)), "“"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценете категория или добавете коментар"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне…"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете емоджи"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свиване"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширяване на полето за коментар"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свиване на полето за коментар"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на форматирането"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на панела"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на форматирането"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на панела"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване на преглед"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценете всички категории"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценете поне една категория"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете този разговор за по-късно"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване за по късно"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следваща възложена задача"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменете разговор в този цикъл на възложени задачи"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на разговор"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед с базова линия"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на коментар"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарът е изтрит"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на този коментар?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор на коментара"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този хаштаг е изтрит или преименуван."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделете какво ви хареса или какво може да се подобри…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментирай"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условни"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще изтрие окончателно всички коментари."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на коментар"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Съобщение]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " все още не е видял този отзив"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " е видял този отзив"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бивш член"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на GIF файлове…"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натиснете Enter, за да създадете нов хаштаг."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализираните хаштагове са заключени от администратора."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвайте това, за да маркирате случаи и да ги филтрирате по-късно"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирайте като базова линия"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за съобщение"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следващ разговор"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отзив"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма направени прегледи"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете преглед по-долу, за да започнете."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма останали други прегледи"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е оценено"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишен изпълнител"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишен разговор"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахнете като базова линия"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговор"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговорете на този преглед"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на страничния панел за преглед"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще изтрие окончателно прегледа и всички добавени коментари."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на преглед"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на преглед"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледът е изтрит"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Прегледът е изтрит от ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложете отново тази задача на себе си, за да направите преглед"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече направихте преглед за този потребител."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Направили сте преглед за ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма категории за показване за този разговор."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценявания не са налични за това работно пространство."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не запазвай"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да се ​​запази ли този преглед като чернова?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне на тази чернова"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на страничния панел за преглед"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не преминавате"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминавате"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминавате с дребни забележки"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още пресмятаме цифрите…"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставете коментар"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледайте това съобщение"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете преглед на себе си"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледайте този разговор"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма потребители за преглед"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценяванията са деактивирани"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерен потребител"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители на работно пространство"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане…"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избор на потребител"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 преглед"]), _normalize([_interpolate(_named("count")), " прегледа"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете нови оценки"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият мениджър на работно пространство не е разрешил самооценявания."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натиснете Enter, за да маркирате този закачен елемент"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласувания"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последните 30 дни"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Със звезда"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте сесия за съгласуване"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернови"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозренията ви осигуряват визуално представяне на вашите данни от отдела по поддръжка. Използвайте интерактивните диаграми, за да разберете къде да насочите усилията си за QA, разкрийте пропуски и намерете правилните консултанти за преглед въз основа на тяхното представяне."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Идентификатор на ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL адрес на ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Превод на ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невъзложен"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момента наблюдават"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процес на преглед"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този разговор е премахнат и вече не е достъпен."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът, който търсите, не съществува в Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички закачени елементи с този разговор"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В сесия"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на разговора"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахването на разговор от сесия ще изтрие всички прегледи, направени за него."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Премахване на разговора от ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма създадени сесии за съгласуване."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този разговор е добавен към сесията"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този разговор е премахнат от сесията"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към/премахване от сесия за съгласуване"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирайте връзката към този разговор"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговор за показване"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бранд"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл на клиента"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на клиента"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на клиента"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на външен формуляр"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време за първа реакция"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар на анкетата"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка на удовлетвореност"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време до първо решаване"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оригинален"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане…"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това съобщение не съществува в този разговор или е изтрито"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма тема"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговор за показване"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерен разговор"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отваряне на оригиналния разговор"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се направи връзка към разговор – липсва URL адрес"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма участници"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на разговор"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете, за да прочетете прегледите"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете за преглед"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор, създаден чрез разширение за браузър"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте анкетата за удовлетвореност на клиентите"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи разговора"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщение с отрицателно отношение"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщение с положително отношение"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преведи на английски"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи оригинала"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на разговора"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриването на разговор е окончателно. То също така изтрива всички свързани данни, например анкети, текстови записи и прегледи от Zendesk QA, включително контролни панели.", _interpolate(_named("lineBreak")), "Все още ще имате достъп до този разговор в Zendesk Support или друг използван от вас софтуер за отдел по поддръжка."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриването на разговор е окончателно. Също така се изтриват всички свързани данни, напр. анкети, текстови записи и прегледи от Zendesk QA, включително контролни панели. Ако разговорът принадлежи към множество работни пространства, той ще бъде изтрит от всички тях.", _interpolate(_named("lineBreak")), "Все пак ще можете да получите достъп до този разговор в Zendesk Support или в друг софтуер за отдел по поддръжка, който използвате."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на разговора"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на разговор от Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Този разговор е непълен поради отнасяне нагоре. Отидете на ", _interpolate(_named("optionsUrl")), " и валидирайте таговете си. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Този разговор е непълен поради отнасяне нагоре. Говорете с вашия администратор относно валидирането на таговете. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки > Разширени опции"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече относно таговете"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключен"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нов"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворен"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чакащ"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнен"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изпълнителят е сменен на ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невъзложен"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " макрос, приложен от ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Разговорът получи оценка ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Потребител ", _interpolate(_named("target")), " редактира разговора"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статусът е променен на ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Маркирани с ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Екипът е променен на ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " отложи разговора за ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " прекрати отлагането на разговора"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот е заседнал в цикъл, повтаряйки последователно едно съобщение"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтаряне на бота"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтаряне на бота, при което едно и също съобщение се повтаря последователно един или повече пъти"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерени са съвпадащи фрази"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Средностатистически консултант се справя с разговора ", _interpolate(_named("percent")), " по-ефективно от бота"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ефективност на комуникацията на бота"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ботът се справи с разговора ", _interpolate(_named("percent")), " по-ефективно от един средностатистически консултант"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ефективност на комуникацията на бота"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговора липсва необходимото изявление, информиращо участниците, че разговорът се записва, което се изисква с цел съответствие и прозрачност"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсва изявление за извършван звукозапис"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Нарушено е споразумение за ниво на обслужване (SLA) за ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарушено SLA"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показател за правилото за SLA надхвърли целевото време"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Признаци на оттегляне на клиенти. Клиентът е обмислял да промени доставчика си или е обещал да се раздели с него."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентифициран риск от напускане"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разговорът има периоди на мълчание, продължаващи по-дълго от зададения праг от ", _interpolate(_list(0)), " секунди"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 случай"]), _normalize([_interpolate(_named("count")), " случая"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " секунди най-много"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на мълчание"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът е потърсил помощ на по-високо ниво"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявено ескалиране"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът е изразил изключителна благодарност или е бил много доволен от получената поддръжка"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключително обслужване"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът или консултантът изрично са заявили проследяване"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка за допълнение"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът демонстрира неудовлетвореност"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателно отношение"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът е бил необичаен или нетипичен и се е наложило да се води дискусия, за да се достигне решение"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Засечено отклонение"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът демонстрира удовлетвореност"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положително отношение"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложимо"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте като неприложимо"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последно редактирано от ", _interpolate(_named("author")), " на ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неприложимо"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["От ", _interpolate(_named("author")), " на ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете причина (по избор)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити прозрения"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на връзка"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете вашия отдел по поддръжка с акаунта си и създайте поне един филтър, за да видите списъка с разговори."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговори за показване"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на настройките на работното пространство"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаден на"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последно обновяване"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задаване"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(След един ден)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Този месец)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Тази седмица)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по идентификатор"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Резултати от търсенето за ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледано от други"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментира"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-нови"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-стари"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Случайни"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновени наскоро"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговорени наскоро"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортиране по"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрочетени"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледано от вас"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесът на импортиране може да отнеме известно време. Ще ви изпратим имейл, когато приключи."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA импортира вашите разговори"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Лимит: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете, като щракнете върху иконата за съгласуване в заглавката на изгледа на разговора."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма нищо за докладване. Опитайте отново с друг филтър или опция за сортиране."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отклонения за показване."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете подходящ разговор и щракнете върху иконата със звезда в заглавката, за да го маркирате тук."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да промените филтрите или поканете други консултанти от отдела по поддръжка да добавят още разговори."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без лимит"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешна забележка"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момента зареждаме разговорите. Част от съдържанието все още може да липсва."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следващ потребител"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерен потребител"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членове на работно пространство"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишен потребител"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени разговори"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са добавени разговори"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незавършените прегледи попадат тук. Прочетете отново, прегледайте и го подайте през следващите 30 дни."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма подготвени прегледи"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговори със звезда"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговори за показване"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връщане към избраното съобщение"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-малко отговори"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-много отговори"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списъка с разговори"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БОТ"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обаждането е отговорено в ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обаждането е приключено в ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обаждането е направено на ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледани"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледано от вас"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Неуспешно зареждане на аудиофайл. Уверете се, че сте влезли в ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар на анкетата за удовлетвореност на клиентите"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка при зареждане на аудио"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор на коментара"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се е провело обаждане"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слушайте в отдела по поддръжка"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слушайте в Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорост на възпроизвеждане"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на обаждане"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстов запис"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Висока"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниска"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средна"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е потребител на Zendesk QA."])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматично класира консултантите въз основа на техния среден месечен CSAT. Открийте с едно щракване разговори на консултанти, чийто CSAT е по-нисък този на техните колеги."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класиране по CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средна"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Висока"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниска"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-ниска"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-висока"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е потребител на Zendesk QA."])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един казус"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обем работа"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматично класира консултантите в групи на ефективност въз основа на това колко разговора обработват на месец. Открийте с едно щракване разговори на консултанти с по-малък обем работа от техните колеги."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класиране по обем работа"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледани"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрегледани"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на прегледа"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниско"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Високо"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неутрално"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е потребител на Zendesk QA."])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматично класира консултантите въз основа на средното отношение в разговорите. С едно щракване открийте взаимодействия, при които консултантите се справят с по-добре с разочаровани клиенти спрямо колегите си."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класиране по отношение"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е установено"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установено"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонения"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори, които изискват вашето внимание. Нашият модел за машинно самообучение идентифицира сложни или нетипични взаимодействия за по-нататъшен преглед."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонения"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателно"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положително"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете разговори за преглед, в които клиентът е демонстрирал удовлетворение или разочарование. Използвайте падащото меню, за да изберете типа отношение, които търсите в разговорите си. Плъзнете атрибута на филтъра в горната част, за да видите съотношението на избраното отношение във всички ваши взаимодействия."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на прегледа"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрегледани"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледани"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматично разпознава езици във вашите разговори. Използвайте падащото меню, за да изберете разговори на конкретни езици за преглед. Плъзнете атрибута на филтъра в горната част, за да получите общ преглед на всички езици, използвани във вашия отдел по поддръжка."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения за разговора"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Можете да ", _interpolate(_named("paragraph_2_item")), " с прозрения, отколкото с обикновените филтри за разговори. Получете визуален общ преглед на всички данни от вашите отдели по поддръжка и проучете прозрения като:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за използването на прозрения за разговора"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разберете относителната важност на конкретни атрибути на разговор по отношение на други"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открийте вашите консултанти с най-висока и ниска ефективност чрез вградено класиране на ефективността на консултантите"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " е мощен интерактивен инструмент за извличане на данни, който ви помага да откриете разговорите, които най-много се нуждаят от преглед."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["направите много повече"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Диаграмите за разговор и консултант са ", _interpolate(_named("paragraph_3_item_1")), " – обновяването на филтрите или щракването върху сегментираните диаграми на който и да е от тях автоматично ще обновени списъка с разговори. ", _interpolate(_named("paragraph_3_item_2")), " за изследване на подробни разбивки или съотношения на различните елементи."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["взаимосвързани"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пренаредете атрибутите на филтъра"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прозренията за разговора показват само ", _interpolate(_named("paragraph_4_item")), ". Ние автоматично премахваме взаимодействия без реална стойност за преглед, като например автоматично генерирани съобщения, спам, отговори от ботове или съобщения, които нямат реален диалог с вашия представител за поддръжка."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чисти и приключени разговори"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какво виждам тук?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " от ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " от ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " от ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " от ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно извличане"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " НЯМА"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултанти"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " от общо)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Презареждане"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Но ние сме сигурни, че ще бъде чудесен, когато пристигне."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирана анкета"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма направен отзив за анкетата"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма наличен отзив от резултат от анкета…"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получени"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таг за отзив"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове на отзиви с коментар"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложен е филтър за времеви интервал"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " отговор"]), _normalize([_interpolate(_named("count")), " отговора"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдел по поддръжка"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вникнете по-задълбочено защо ", _interpolate(_list(0)), " резултат е бил даден чрез задаване на причини в настройките на анкетата."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Все още няма причини за ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за причините за анкетата"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори с тагове:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " от"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Езици"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причини"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория на коментари"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), " причини"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прогнозни фактори за ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник на разговор"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове на отдел по поддръжка"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Отговори на анкета (", _interpolate(_named("n")), ")"]), _normalize(["Отговор на анкета (", _interpolate(_named("n")), ")"]), _normalize(["Отговори на анкета (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма тема"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разговори с ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " от потребители"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент на реагиралите"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Резултатът ", _interpolate(_list(0)), " е сбор от всички отговори, разделен на сбора от максималните резултати"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на целта"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на целта"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задаване на цел"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на цел"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Къс"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Много дълъг"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дълъг"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средна дължина"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объркващ"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не особено полезен"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лоша поддръжка"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добра поддръжка"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изобилие от емоции"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунт"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив за консултанта"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплакване"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кратки"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проблемът не е разрешен"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проблемът е разрешен"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателно отношение"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лош резултат"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положително отношение"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похвала"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лош продукт"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възстановяване на плащане"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бърза поддръжка"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бавна поддръжка"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA автоматично разпределя вашите ", _interpolate(_list(0)), " данни в предварително дефинирани категории. Така можете да разберете сложните прозрения и да проучите причините, без да преглеждате ръчно писмените отзиви."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички резултати"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички анкети"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете език за отговор"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер на коментара"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете размер на коментара"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тъй като 25% от коментарите се състоят само от една дума, по-дългите коментари са задължителни за четене. Получете по-задълбочени отзиви, без да се занимавате с това ръчно, тъй като ние го правим автоматично вместо вас."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за отзив"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете причина за отзива"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи резултати"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозни фактори"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете категория на коментари"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думи от „Облак от думи“"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете думи от „Облак от думи“"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " от последния период"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " разговора"]), _normalize([_interpolate(_named("n")), " разговор"]), _normalize([_interpolate(_named("n")), " разговора"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се изчисли съотношението на изпратените анкети от всички разговори поради липса на данни."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратени анкети"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фуния за анкета"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " от ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " от ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " от ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прогнозни фактори за ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA автоматично разпределя вашите ", _interpolate(_list(0)), " данни в предварително дефинирани категории. Така можете да разберете сложните прозрения и да проучите причините, без да преглеждате ръчно писмените отзиви."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер на коментара"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тъй като 25% от коментарите се състоят само от една дума, по-дългите коментари са задължителни за четене. Получете по-задълбочени отзиви, без да се занимавате с това ръчно, тъй като ние го правим автоматично вместо вас."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментирай"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори на анкета"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (среден)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (среден)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Тенденции за ", _interpolate(_list(0)), " от"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Езици"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причини"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактори"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дата на изпращане на анкета за ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дата на отговор на анкетата за ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Възлагане на резултат за ", _interpolate(_list(0)), " към"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на данни въз основа на"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване на разговора"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["притежател на казуса"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["консултант с най-много отговори"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (среден)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " във времето"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " и IQS"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори, които включват тези думи:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облак от думи"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT от потребители"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед на CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фуния за анкета"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори на анкета"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове за отдел по поддръжка"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT във времето"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облак от думи"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма канал"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма източник"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма език"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма причини"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма тагове"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без категория на коментари"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма работно пространство"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са налични данни, тъй като няма създадени възложени задачи или не са започнати цикли. Проверете по-късно или коригирайте филтрите."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте новостите"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " елемент"]), _normalize([_interpolate(_named("count")), " елемента"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " разговор"]), _normalize([_interpolate(_named("count")), " разговора"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не преминавате"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминавате"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминавате с дребни забележки"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминавате/Не преминавате"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно (ч)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно (мин)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " оценки"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден CSAT = [сума от резултатите за отзиви ÷ (брой на всички отговори × максимален възможен резултат)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултатът CSAT в Zendesk QA нормализира резултата ви за отзиви в рамките на скала от 0-100% и взема средна стойност от стандартизираните резултати."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценката на удовлетвореността на клиента (CSAT) определя количествено колко доволни са вашите клиенти от услугите ви."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Всеки преглед представлява прегледа на един човек на един разговор. Един разговор може да има повече от един преглед (ако двама или повече преглеждащи преглеждат един и същи разговор)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проследявайте развитието на резултатите на преглеждащите във времето."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проследявайте развитието на резултатите на консултантите във времето."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " отговора"]), _normalize([_interpolate(_named("n")), " отговор"]), _normalize([_interpolate(_named("n")), " отговори"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " прегледа"]), _normalize([_interpolate(_named("n")), " преглед"]), _normalize([_interpolate(_named("n")), " прегледи"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешната оценка на качеството (IQS) е резултат от вашите прегледи на разговори."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS = сума от резултатите от прегледи ÷ брой прегледи"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчислява се като средна стойност, като се вземат предвид всички резултати от прегледа (претеглена средна стойност на резултата на категорията), съответстващи на избраните условия на филтъра."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно зареждане на картата."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променете филтрите си, за да опитате отново."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степента на преминаване показва резултата от прегледите на вашите разговори."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Преминавате, ако резултатът от прегледите е ", _interpolate(_named("failScore")), " или по-нисък"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Преминавате, ако резултатът от прегледите е ", _interpolate(_named("passScore")), " или по-висок"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определя се от предварително дефинирана базова линия, което означава, че:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниво на преминаване = сбор на преминати прегледи ÷ сбор на всички прегледи"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степента на преминаване показва процент от това колко прегледа са преминати, без да се провалят нито критичните, нито некритичните категории."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["напр. ако преглеждащият направи 3 прегледа през този ден, показаното число е средноаритметичното на 3-те резултата. Вижте как се изчислява всяка клетка ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["напр. ако консултантът получи 3 прегледа в този ден, показаното число е средноаритметичното на 3-те резултата. Вижте как се изчислява всяка клетка ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се вземат под внимание: тежест на категорията, критичен статус (за разлика от „Резултати във времето“)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тъй като категориите могат да бъдат маркирани като „няма“ в прегледите, броят прегледи за категория може да е по-нисък от общия брой прегледи от преглеждащия. Вижте как се изчислява всяка клетка"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се вземат под внимание: тежест на категорията, критичен статус (за разлика от „Резултати във времето“)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тъй като категориите могат да бъдат маркирани като „няма“ в прегледите, броят прегледи за категория може да е по-нисък от общия брой прегледи за консултант. Вижте как се изчислява всяка клетка"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се вземат под внимание: тежест на категорията, критичен статус (за разлика от „Резултати във времето“)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Групите категории се показват отделно. Резултатът за всяка група се изчислява от средния резултат за категорията за тази група през същия период. Вижте как се изчислява всяка клетка ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забележка:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се вземат под внимание: тежест на категорията, критичен статус (за разлика от „Резултати във времето“)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Групите категории се показват отделно. Резултатът за всяка група се изчислява от средния резултат за категорията за тази група през същия период. Вижте как се изчислява всяка клетка ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Базова линия ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без критични грешки"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процентът без критични грешки показва процент от броя на прегледите, които са преминали без критични категории."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешна оценка на качеството"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Други“ първопричини"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Периодична промяна"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневна промяна"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месечна промяна"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Седмична промяна"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годишна промяна"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати във времето"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте в дни или седмици."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчисление = общ резултат за ден или седмица, разделен на общия брой направени прегледи"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте в дни или седмици."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчисление = общ резултат за ден или седмица, разделен на общия брой получени прегледи"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати по категории"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте комбинираната средна стойност на всички оценки на категории, дадени от преглеждащия през този период."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте комбинираната средна стойност на всички оценки на категории, получени за консултанта през този период."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричини за ниските оценки"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричини за оценки"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати по категория във времето"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирайте как категориите се сравняват във времето."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте средните резултати, дадени за всяка категория в избран ден или седмица."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирайте как категориите се сравняват във времето."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте средните резултати, получени за всяка категория в избран ден или седмица."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати от прегледи"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирани"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критична категория"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скала за оценяване"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно/общо"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на самооценявания"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортира всички карти"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["разговор"]), _normalize(["разговори"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["елемент"]), _normalize(["елементи"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " отговора"]), _normalize([_interpolate(_named("n")), " отговор"]), _normalize([_interpolate(_named("n")), " отговори"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " прегледа"]), _normalize([_interpolate(_named("n")), " преглед"]), _normalize([_interpolate(_named("n")), " прегледи"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Показване на още едно"]), _normalize(["Показване на още ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последните 30 дни"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последните 7 дни"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миналия месец"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миналата седмица"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този месец"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази седмица"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиран период"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месеца"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тримесечия"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Седмици"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Години"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно зареждане на картата. Променете филтрите си, за да опитате отново."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма данни за показване."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месечна промяна"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролни панели"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повече информация."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашите активни филтри включват данни в различни скали за оценяване."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички закачени елементи на карти с тези филтри"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички закачени елементи на контролния панел с тези филтри"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно време за преглед"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиране"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтегляне на CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма категории"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения за категориите"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаграма"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Коментар"]), _normalize(["Коментара"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Матрица"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бивш член"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешна оценка на качеството"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS (среден)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминати прегледи"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на карта"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на контролен панел"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Първопричина"]), _normalize(["Първопричини"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на прегледа"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време за преглед"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте първопричини"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричините ви позволяват да разберете защо разговорите получават ниски резултати"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма зададени първопричини за тази категория"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат / Общо"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории за оценка"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи категории на оценяването"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група на категория"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работно пространство"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиране на поръчка"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групиране на категории по"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избиране на всички"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиране"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на самооценявания"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включване на самооценявания"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване само на самооценявания"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчистване на филтрите"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са намерени хаштагове"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштагове за коментари"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете хаштагове за коментари"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са намерени тагове"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове за отдел по поддръжка"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсете тагове за отдела по поддръжка"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити карти с резултати"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти с резултати"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете карта за резултат"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценявания"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи връзки"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник на разговор"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете източник"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Още филтри"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи работни пространства"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо дадено време"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички групи"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи групи."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички групи"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на карти"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на данни въз основа на"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване на прегледа"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване на разговора"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на два знака след десетичната запетая"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте настройките"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички работни пространства"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целия акаунт"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на работно пространство"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма работни пространства"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирани"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Флагове"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Храни и напитки"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Животни и природа"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предмети"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хора и тяло"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пътуване и места"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Често използвани"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати от търсенето"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усмивки и емотикони"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символи"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени емоджита"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартиране на разговор"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад към списъка със задачи"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " или прегледайте списъка си с ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В момента използвате работно пространство ", _interpolate(_named("workspace")), ", което не е свързано с този домейн. Щракнете тук, за да го свържете."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["налични връзки"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не можете да дадете отзив за ", _interpolate(_named("host")), " все още."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дръжте вашия отдел по поддръжка като активен раздел."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този домейн все още не може да бъде прегледан. Ако трябва да бъде, уведомете вашия администратор."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете този домейн"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Свържете се с ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само момент – извършваме настройка"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL адресът не може да бъде получен"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструменти, които вече са свързани с акаунта на организацията в Zendesk QA."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма инструменти, свързани с акаунта на организацията в Zendesk QA."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволява ви да преглеждате уебсайтове, където URL адресът остава винаги един и същ, като добавяте уникален хеш в края, преди да направите преглед"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на акаунт"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашите домейни"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домейни, които вече са свързани с това работно пространство."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да прегледате всяка страница в следните домейни"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домейни, налични за преглед"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширение"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникален URL адрес"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Все още няма свързан отдел по поддръжка – отидете на ", _interpolate(_named("link")), ", за да добавите такъв."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширението за браузър Zendesk QA може да се използва само\nс планове Professional и Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на работното пространство"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посочете име"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посочете стойност"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специални полета"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стойност"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За първи път срещаме този разговор. Предоставете някои подробности."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка за разговор:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нещо, по което да идентифицирате казуса по-късно"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възстановяване на плащане"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посочете както изпълнителя, така и субекта"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създава се"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледайте този разговор"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невъзложен"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Свържете се с вашия мениджър или превключете към друго работно пространство от ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценяванията са деактивирани за това работно пространство."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излизане"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чао"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работно пространство"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този URL адрес не може да бъде прегледан. Отидете до разговор."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първо влезте в Zendesk QA чрез вашия доставчик за единен вход (SSO) и след това опитайте отново."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане със SSO"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отваряне на изскачащ прозорец за влизане."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влезте с вашия акаунт за Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изчакване..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удостоверява се..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още нямате акаунт?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате достъп до този разговор. Ако трябва да имате, свържете се с вашия мениджър."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма достъп"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверете дали връзката ви е активирана в Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявайте връзките си"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешка при свързване"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отидете до разговор във вашия отдел по поддръжка, за да продължите"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невалиден URL адрес"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторното свързване почти приключи."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един момент"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да презаредите страницата, за да се върнете към нормалния процес на работа"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Освободете се от виртуалните граници на вашия отдел по поддръжка и преглеждайте разговори отвсякъде."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надградете абонамента си, за да използвате разширението"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявайте абонамента си"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката с отдела по поддръжка не е свързана с това работно пространство"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият отдел по поддръжка не разпознава този казус или Zendesk QA няма достъп до него"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът не може да бъде открит"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова дейност"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имате отзив"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно влизане"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече можете да използвате приложението"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценете всички категории"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвайте това, за да маркирате случаи и да ги филтрирате по-късно"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане…"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отзив за този разговор"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочетете отзивите"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворете в Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това е мястото, където ще се показват всички отзиви и уведомления. Все още нищо…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте повече в Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности за разговора"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съдържа всички"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е всички"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не съдържа всички"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е всички"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по-добра от тази на консултант"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по-лоша от тази на консултант"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неистина"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е повече от"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съдържа някое от"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е някое от"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не съдържа някое от"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е някое от"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["истина"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нарушен"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментира"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментирано от мен"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е коментирал"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е коментирано от мен"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["критичен за преглед"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съдържа"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последните 30 дни"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в рамките на"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последните 14 дни"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последните 24 часа"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последните 30 дни"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["последните 7 дни"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["миналия месец"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["миналата седмица"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["този месец"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тази седмица"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днес"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["установено"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съществува"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["със звезда от мен"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["без звезда от мен"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["включва"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повече от"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в сила от"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по-малко от"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отрицателно"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е нарушено"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е сложно"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е открито"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не съществува"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не включва"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е получено"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е видяно"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["положително"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["непрегледани"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е прегледано от мен"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледани"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледано от мен"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получени"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["критичен за преглед"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съдържа"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повече от"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в сила от"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по-малко от"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не съдържа"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледа"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментарът ми има отговори"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["да"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирани категории"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирано осветяване"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потърсете условие"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдел по поддръжка"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатели"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед и отзив"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи всички условия"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнява обработката на разговорите на вашия бот със средностатистическите консултанти"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботът е заседнал в цикъл и повтаря последователно едно и също съобщение"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на бота в разговорите"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип бот, участващ в разговора"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот, който участва в разговора"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Съдържанието на разговора включва фразата ", _interpolate(_list(0)), " в съобщението от консултанта"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично идентифицирайте обажданията, при които липсва задължителното изявление, че разговорът се записва, като например „Това обаждане ще бъде записано“ и подобни."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Човек или бот, участващ в разговора"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът или ботът, на който се прави преглед в разговора"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът е потърсил помощ на по-високо ниво"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положителни и отрицателни отношения, открити в разговора"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентифицира най-активния консултант в разговорите чрез анализ с ИИ"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът е приключил разговора любезно"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът разбира и отчита чувствата на клиентите"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира граматическите, правописните и стиловите грешки на консултанта"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за типични начални обръщения"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът разбира запитването или притеснението на клиента"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира колко лесно може да бъде разбран текст, като се има предвид сложността на думите и дължината на изречението"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за предложено решение"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира тона на консултанта по време на разговора"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Признаци на оттегляне на клиенти. Клиентът е обмислял да промени доставчика си или е обещал да се раздели с него."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група, към която принадлежи разговорът."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържанието или текстът на съобщението от консултанта"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на консултанти в разговори"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът, на когото е възложено да прегледа разговора"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължителността на разговора от обаждането в секунди"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържанието или текстът на разговора"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комуникационният канал, използван за разговора (напр. имейл, чат)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарите са добавени към разговора"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която е добавен коментар към разговора"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положителни и отрицателни отношения, открити в разговора"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която клиентът е отговорил на анкетата за удовлетвореност на клиентите"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дали прегледът на разговора е оспорен"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналът, използван от клиента за започване на разговора"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой клиентски отговори в разговорите"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата на последното съобщение в разговора"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролята или типа на потребителя, участващ в разговора"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мълчанието между консултанта и клиента надхвърли прага"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът е потърсил помощ на по-високо ниво"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът е изразил изключителна благодарност или е бил много доволен от получената поддръжка"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът или консултантът изрично са заявили проследяване"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстът или записът на целия гласов разговор"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дали разговорът е маркиран със звезда"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштагове, използвани в коментарите за категоризиране на съдържание"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език на разговора, установен от ИИ"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата на последното съобщение в разговора"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой лични бележки в разговори"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден брой знаци на съобщение в разговори"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на клиентите в разговори"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник, чиято информация е публично видима"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден брой думи на съобщение в разговори"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории, използвани за оценка или оценяване на разговора"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусът на процеса на преглед на разговора"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник или лице, участващо в разговора"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл, свързан с разговора"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дали прегледът е била видян"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащият, който оцени разговора"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата на последния преглед на разговора"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът, на когото се прави преглед в разговора"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички конкретни коментари или отзив, предоставени от клиента относно неговата удовлетвореност от разговора"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка на удовлетвореността на клиента от разговора"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка на удовлетвореността на клиента за съобщение в разговора"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картата за резултат, използвана за преглед на разговора"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определени SLA са били нарушени"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типът източник или връзка, от която произлиза разговорът"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът е бил необичаен или нетипичен и се е наложило да се води дискусия, за да се достигне решение"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпросът в анкетата е свързан със съдържанието на разговора"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заглавието или името на анкетата, свързана с разговора"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът, на когото е възложено да прегледа разговора"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брандът или конкретен продукт/услуга, свързани с разговора"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комуникационният канал, използван за разговора (напр. имейл, чат)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която разговорът е приключен или разрешен"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която разговорът е създаден първоначално"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която разговорът е създаден първоначално"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата, на която клиентът е отговорил на анкетата за удовлетвореност на клиентите"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кратко описание или резюме на разговора"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Външен референтен идентификатор, свързан с разговора"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папката или конкретното групиране, в което е организиран разговорът"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формулярът или шаблонът, използван за структуриране на разговора"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Външен референтен идентификатор, свързан с разговора"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пощенската кутия или имейл акаунтът, чрез който е получен разговорът"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефонният номер, свързан с разговора"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нивото на приоритет, възложено на разговора"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общият брой отговори в разговора"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусът или текущ статус на разговора"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущият статус на разговора"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предметът или темата на разговора"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на консултанти в разговори"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове, възложени за категоризиране на разговора"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типът или категорията на разговора"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата на последно обновяване на разговора"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датата на последно обновяване на разговора"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средата или каналът, през който се е провел разговорът"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържанието на текстов запис в разговор"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори, които не отговарят на условията за преглед чрез анализ с ИИ"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой на участващите консултанти в разговорите"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дали разговорът е бил видян"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всяко съобщение"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ефективност на комуникацията на бота"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтаряне на бота"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на бот"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип бот"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление на обаждането"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщение от клиент"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на мълчание"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсва изявление за извършван звукозапис"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отнасяне нагоре"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържание на текстов запис на разговора"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключване"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Със звезда"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отговаря на условията за преглед"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледан от"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор чрез"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правопис и граматика"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщение от консултант"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-активен консултант"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на консултанта"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Времетраене на обаждането (сек)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържание на разговора"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал за съобщения"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таг за коментар"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на коментиране"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на попълване на анкета за CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорване"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емпатия"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентски канал"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на клиенти"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на последен отговор"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип потребители"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начално обръщение"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Риск от напускане"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на мълчание"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескалация"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключително обслужване"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допълнение"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текстов запис"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштаг за коментар"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонение"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбиране"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на последен отговор"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой вътрешни бележки за разговор"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден брой знаци"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден брой думи"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой публични отговори"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичен участник"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на оценяване"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка и коментар"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория на оценяването"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на прегледа"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четливост"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързан имейл"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързани"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой на отговорите"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледът е получен"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледана карта за резултат"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледът е видян"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на преглед"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар на анкетата"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT на съобщение"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат за удовлетвореност (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложено решение"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип на източника"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпрос от анкета"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заглавие на анкетата"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бранд на разговор"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домейн на бранда"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал за разговори"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на приключване"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на попълване на анкета за CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на изпращане на анкетата за CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание на разговора"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Външен идентификатор"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле за разговор"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папка за разговори"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формуляр за разговор"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на Freshdesk"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група на разговор"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на Help Scout"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Външен идентификатор"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на Intercom"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пощенска кутия за разговори"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефонен номер"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет на разговора"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на разговора"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Състояние на разговор"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на разговора"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема на разговора"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой отговори на консултанта"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таг за отдел по поддръжка"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таг за отдел по поддръжка"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип разговор"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на обновяване"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на обновяване"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на Zendesk"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържание на текстов запис"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой на участващи консултанти"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на преглед"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уязвимост във връзка със способности"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уязвимост във връзка със здравословно състояние"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уязвимост във връзка с житейско събитие"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория за Автоматичен QA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете условие, свързано с дата"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете име към вашия филтър"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попълнете име на филтър"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влязло лице"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бивши членове на работно пространство"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членове на работно пространство"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Добавяне ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прилагане на филтъра"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да имате поне 1 филтър, свързан с дата"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете опции"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете опция"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият пробен период приключва след ", _interpolate(_named("n")), " ден. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият пробен период приключва <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>след ", _interpolate(_named("n")), " ден</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият пробен период приключва след ", _interpolate(_named("n")), " дни. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият пробен период приключва <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>след ", _interpolate(_named("n")), " дни</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият пробен период приключва днес. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият пробен период приключва <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>днес</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте абонамент"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " дни"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Остават ", _interpolate(_named("days")), " във вашия пробен период"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнахте потребителския лимит за вашия план. ", _interpolate(_named("link")), ", за да научите повече или да преминете към по-висок план."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете тук"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["свържете вашия отдел по поддръжка"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще ни позволи да извличаме вашите казуси, за да ги преглеждате."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете вашия отдел по поддръжка"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Използвате Zendesk QA с примерни данни. За да изтриете примерни данни и да импортирате вашите собствени разговори, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примерни данни"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирайте полезна информация по-долу"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете екранна снимка на цялата страница, за да предоставите контекст"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се с нашия екип за поддръжка на клиенти, за да разрешите този проблем:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка за грешка:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копиране на полезна информация"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители, ботове и работни пространства"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители и работни пространства"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днес"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["този месец"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тази седмица"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " прегледани ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " прегледан ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " прегледани ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какви са новостите?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излизане"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клавишни комбинации"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пишете ни в чата"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нашият чат не може да бъде зареден (Може би използвате рекламен блокер?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминете към..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общност"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролен панел"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощ"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начална страница"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени прегледи"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База знания"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на акаунт"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приложението беше обновено. ", _interpolate(_named("buttonStart")), "Презареждане", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако искате да започнете да използвате Zendesk QA като част от вашето работно пространство:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Просто искаме да ви уведомим, че вече сте получили покана за използване на Zendesk QA като част от работно пространство. Ако желаете, можете да продължите, но имайте предвид, че това ще създаде ", _interpolate(_named("bold")), " за вас."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отделна организация"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Да, нека продължа с ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нов акаунт"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " ви моли да се присъедините към ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присъединяване"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма връзка с интернет"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете нов закачен елемент"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бърза навигация"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фокусиране и разгъване на редактора на коментари"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване или показване на страничния панел"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отидете до следващия разговор"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отваряне на оригиналния разговор"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отидете до предишния разговор"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте на всички категории възможно най-високата оценка"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте на всички категории възможно най-ниската оценка"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбъркване на разговори (повторно сортиране на случаен принцип)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кажете ни"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клавишни комбинации"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на преки пътища към модални прозорци"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнахте до ограничението на знаците от ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изображение ", _interpolate(_named("name")), " тип (", _interpolate(_named("type")), ") не е позволено"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Размерът на изображение ", _interpolate(_named("name")), " е твърде голям (разрешени са 5 MB)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редакторът на коментари не успя да се зареди"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пуснете, за да качите"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на шаблони"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени шаблони"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на първопричина"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Други, включително изтрити причини"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за оценката"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Държава"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собствено име"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилно име"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой служители"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефонен номер"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще се свържем с вас, за да ви помогнем да настроите акаунта си"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на компанията"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер за връзка с компанията"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как за първи път научихте за Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете канал"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете брой консултанти"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попълнете всички задължителни полета"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да се съгласите да прочетете и да се съгласите с нашите условия и политика за поверителност."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал на поддръжката в Slack"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Събитие"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приятел или колега"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин за отдел по поддръжка или мениджър на акаунти"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Препращащ сайт"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсачка (Google, Bing и др.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Социални медии"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разкажете ни за себе си и вашата компания."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данни на акаунта"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готов съм."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разгледайте наоколо, запознайте се с приложението, изтрийте и добавете информация."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунтът е създаден. Какво следва?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готови ли сте да включите истинските си разговори?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете свои собствени данни"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разгледайте"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробвайте с примерни данни"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Така че можем да започнем да извличаме вашите казуси, за да ги преглеждате."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете своя акаунт за отдел по поддръжка"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промених мнението си."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна на регистрация"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създаден е закачен елемент. ", _interpolate(_named("buttonStart")), "Към закачените елементи ", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачените елементи от коучинг сесия са налични за план Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Към по-висок план"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички планове"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете бележките си, за да се подготвите за следващата индивидуална среща или коучинг сесия."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи за коучинг"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментирайте или добавете други с ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този разговор е изтрит"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте нов закачен елемент"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте закачен елемент"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на закачен елемент"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как функционират закачени елементи"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате разрешение да видите този разговор"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на карта"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на разговор"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачане на контролен панел"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закаченият елемент е запазен"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачете тази карта"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачете този разговор"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачете този контролен панел"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачете под потребител"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребител"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само вие можете да видите какво закачате. За да споделите с други, ", "@", "споменете ги в коментарите."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " вече е поканен в този разговор"]), _normalize([_interpolate(_named("agentsNames")), " вече са поканени в този разговор"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 коментар"]), _normalize([_interpolate(_named("n")), " коментара"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вие споменахте ", _interpolate(_named("participants")), ", но той или тя не са в този разговор."]), _normalize(["Вие споменахте ", _interpolate(_named("participants")), ", но той или тя не са в този разговор."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " участници"]), _normalize([_interpolate(_named("n")), " участник"]), _normalize([_interpolate(_named("n")), " участници"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Искате ли да премахнете достъпа на ", _interpolate(_named("agentName")), " до този закачен елемент?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нищо"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не ги канете в този разговор"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на коментар"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете ги"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете ги в този разговор"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на потребител"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нишка"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие не може да се отмени"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това също ще изтрие всички коментари под този закачен елемент."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " нови съобщения"]), _normalize([_interpolate(_named("n")), " ново съобщение"]), _normalize([_interpolate(_named("n")), " нови съобщения"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само вие можете да видите вашите закачени елементи. За да споделяте закачени елементи, ", "@", " някой в ​​коментарите."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " закачи ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["контролен панел"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговор"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бележка"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вие"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надградете, за да получите закачени елементи от коучинг сесия"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачените елементи от коучинг сесия са налични за план Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачените елементи от коучинг сесия са налични за план Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надградете, за да получите функционалността"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подгответе се за вашите индивидуални срещи или запазете информация за друг път."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще изтрие за постоянно закачения елемент и всички добавени коментари."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на закачен елемент"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на закачен елемент"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на папка"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачете неща, към които да се върнете по-късно"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговор"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първо най-новите"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първо най-старите"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете интересна тенденция или разговор на контролния панел в подготовка за вашите индивидуални срещи или ги отбележете за друг път."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте закачен елемент, като щракнете върху бутона по-горе или върху иконата на закачения елемент в изгледа „Разговор“ или „Контролен панел“."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачване"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени закачени елементи"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Няма резултати за ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да използвате различни ключови думи или проверете за правописни грешки."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нов"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте нов закачен елемент"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщения"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по потребител, ключова дума..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По азбучен ред"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наскоро създаден"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наскоро редактиран"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повечето закачени елементи"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Стремя се да прегледам ", _interpolate(_named("n")), " разговор ", _interpolate(_named("schedule"))]), _normalize(["Стремя се да прегледам ", _interpolate(_named("n")), " разговора ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целта е обновена"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задаване на цел"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да зададете лична цел за броя на разговорите, които искате да прегледате."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежедневни"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всеки месец"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всяка седмица"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на цел"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛИЧНИ"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналите се зареждат"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете канал"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделяйте само отзиви от анкета с коментари"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насърчете колегите си, като изтъкнете тяхното изключително качество на обслужване и отличните отзиви от клиентите."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделете положителни отзиви от анкета към Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени канали"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично споделяне на отзиви с резултати по-горе"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции на бот за похвали"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получавайте напомняне в началото на седмицата, ако не сте правили прегледи."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напомняния"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Седмично напомняне за правене на прегледи"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получавайте уведомления за нови прегледи, отговори, споменавания и оспорвания в Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споменавания"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори в нишка"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции със Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Известяване за нови прегледи, отговори, споменавания и оспорвания."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спорове, свързани с мен"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споменавания в коментари"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нови получени прегледи"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори в нишки"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доклад със статистика"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получавайте отчети за дадени и получени прегледи."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчети"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежедневно"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежемесечно"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежеседмично"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На всеки 3 часа"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На всеки 6 часа"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незабавно"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесечно"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изкл"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежеседмично"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администраторът на вашия акаунт е заключил настройките за уведомление."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избиране на период"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични известия"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (активни)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете ново влизане през социална мрежа към този акаунт:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахването на активен метод за влизане ще ви отпише. Можете да влезете отново с вашия алтернативен имейл."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахнете на метода за влизане"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно добавяне на Google като опция за влизане"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции за влизане"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack е добавен успешно като опция за влизане"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизане"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на парола"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метод"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране на паролата"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ще бъде изпратен имейл до ", _interpolate(_named("email")), " за нулиране на вашата парола."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["основен имейл адрес"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверете входящата си кутия за нашия имейл за потвърждение"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магическа връзка"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["За да промените това, променете своя ", _interpolate(_named("link")), " предварително."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Имейлът ", _interpolate(_named("email")), " ще бъдат свързан с тази парола."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профилът е обновен"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снимка в профила"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За най-добри резултати качете квадратна снимка (256x256 пиксела или повече)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основен имейл адрес"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало на седмицата"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични настройки"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете поне една опция"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържание на шаблона"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на шаблона"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на шаблона в"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете съдържание на шаблона"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете име на шаблон"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на шаблон"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на шаблон"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създавайте шаблони за коментари, за да ускорите прегледа, закачането на елементи и обучението."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвано в"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на шаблона"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване за постоянно на този шаблон?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на шаблон"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създавайте шаблони за коментари, за да ускорите прегледа, закачането на елементи и обучението."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени шаблони"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаден шаблон за коментар"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблонът за коментар е изтрит"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблонът за коментар е запазен"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи и оспорвания"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Свържете се с ", _interpolate(_named("link")), ", за да получите достъп."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поддръжка на клиенти"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е намерен акаунт"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Засега използвайте ", _interpolate(_named("link")), " за достъп до Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратно към Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунтът не е мигриран"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QA на бота"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iqs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["показатели"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["резултати"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статистика"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролен панел"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съгласуване"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговори"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["филтри"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["преглед"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["моите коментари"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["моите оспорвания"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["моите прегледи"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакции"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнати"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив от анкета"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дейност"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесии"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["създаване на парола"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["идентификационни данни"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имейл"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имейл"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вход"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нулиране"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["преглед на цел"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблони"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["потребител"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на шаблон"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунт"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонамент"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["акаунт"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фактуриране"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компания"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдел по поддръжка"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["организация"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["плащане"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лицензи"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["настройки"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["абонамент"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификации"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова категория"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонамент"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерено е конфликтно съобщение"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променете или премахнете едно от конфликтните съобщения"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Използвайте иконата ", _interpolate(_named("placeholder")), ", за да изключите чувствителни данни от Автоматичния QA. Например вместо „Поздрави, Джон“ използвайте „Поздрави, ", _interpolate(_named("placeholder")), "“, за да обозначите всяко едно име."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимум ", _interpolate(_named("limit")), " реда"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прилагане на правило към"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнахте лимита от 10 потребителски категории"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнахте лимита от ", _interpolate(_named("limit")), " специални категории"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на условие"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на ред"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на условието"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако съобщение от консултант"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка за функцията „в противен случай“"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка за функцията „тогава“"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на категория в картата за резултати"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвайте „/“, за да добавите променлива"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички посоки"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщението от консултант не може да бъде празно"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщението не може да бъде над 300 знака"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички източници"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички канали"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички тагове"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички разговори"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничете областите, в които се прилага вашето правило."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкретни разговори"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на прозрение в Осветяване"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съобщението от консултант не може да бъде празно"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнахте лимита от 10 потребителски прозрения"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнахте лимита от ", _interpolate(_named("limit")), " специални прозрения"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете име на прозрението"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на прозрението"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на категория"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип на прозрението"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателно"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неутрално"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положително"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отидете на настройките на работно пространство"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сега"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да започнете да използвате тази категория на оценяването, отидете в настройките на вашата карта за резултат от работно пространство и я добавете към карта за резултат по ваш избор."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорията на оценяването е създадена"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично оценявайте приключените разговори чрез идентифициране на конкретни ключови думи или фрази."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не функционира с гласови текстови записи."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ясно!"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дайте пет! ✋ Достигнахте максимума на своите автоматизирани категории за оценка на ", _interpolate(_named("limit")), ". Вие сте магьосникът на категоризациите! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимитът за категория е достигнат"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че всички полета са попълнени правилно"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте име на вашата категория на оценяването"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование на категорията"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова категория на оценяване"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова категория на оценяване"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично оценявайте приключените разговори чрез идентифициране на конкретни ключови думи или фрази. Не функционира с гласови текстови записи."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично показване на приключените разговори чрез идентифициране на конкретни ключови думи или фрази. Работи само с текстови записи."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично оценявайте разговорите чрез идентифициране на конкретни ключови думи или фрази в съобщенията на консултантите и ботовете."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да използвате тази категория на оценяването, отидете в настройките на вашата карта за резултат от работно пространство и я добавете към карта за резултат."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценявайте автоматично разговорите въз основа на специфични ключови думи или фрази в съобщенията."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На разположение за"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка на прозрение"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това прозрение ще се показва в Осветяване въз основа на определени критерии."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка на карта за резултат"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази категория може да се използва в карта за резултат въз основа на определени критерии."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бот"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чат"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скоро"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["персонализирано"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предварително дефинирани"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["карта за резултат"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["осветяване"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гласов разговор"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване на промените"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промените са запазени"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съвет:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете върху иконата ", "{··}", ", за да изключите имена, фирмени данни или друга чувствителна информация по време на заключителните проверки с функцията Автоматичен QA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на одобрено приключване"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на одобрено приключване"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрено приключване"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете изключения"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на изключение"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключение"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете одобрено начално обръщение"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирайте одобреното начално обръщение"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрено начално обръщение"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставете думи тук, разделени с нов ред"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натиснете Enter след всяка дума за нов запис"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложим език"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например вместо „Поздрави, Джон“, можете да използвате „Поздрави, ", "{··}", "“, за да обозначите всяко едно име."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контейнер на динамично съдържание"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключения"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 добавено изключение"]), _normalize([_interpolate(_named("count")), " добавени изключения"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вече добавено изключение"]), _normalize(["Вече добавени изключения: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на изключение"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити изключения"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени изключения"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключение (различават се главни и малки букви)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавяне на изключения"]), _normalize(["Добавете 1 изключение"]), _normalize(["Добавете ", _interpolate(_named("count")), " изключения"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потърсете дума или фраза"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключението е запазено"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["език"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички езици"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на език"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вече е добавено приключване"]), _normalize(["Вече добавени приключвания: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на приключване"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени одобрени приключвания"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма одобрени приключвания"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключване"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на приключване"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на приключване"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавено приключване"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключването е запазено"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Формулярът има дублиращи се записи: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Началното обръщение вече е добавено"]), _normalize(["Началните обръщения вече са добавени: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на начално обръщение"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени одобрени начални обръщения"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма одобрени начални обръщения"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начално обръщение"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на начално обръщение"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потърсете начално обръщение"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Началното обръщение е добавено"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Началното обръщение е запазено"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на изключение"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Изтриване на изключение"]), _normalize(["Изтриване на изключения"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Изтриване за постоянно на това изключение?"]), _normalize(["Изтриване за постоянно на тези изключения?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на изключение"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Едно или повече изключения са дълги над ", _interpolate(_list(0)), " знака"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " знака"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновено"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновено от"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променлива"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определете свои собствени подписи, които да съответстват на вашия бранд и стандарти за обслужване. Когато се добави, функцията Автоматичен QA ще открие само одобрени приключвания. Направените промени се прилагат за бъдещи разговори."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнахте горния лимит от 100 одобрени приключвания. Ако искате да добавите още, може би е време да прегледате и прецизирате съществуващите си приключвания."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобреният лимит за приключвания е достигнат"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени приключвания"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думи и фрази, които искате функцията Автоматичен QA да игнорира като правописни или граматически грешки. Направените промени се прилагат за бъдещи разговори."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък за игнориране"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посочете свои собствени начални обръщения, които са в съответствие с вашия бранд и стандарти за обслужване. Когато се добави, функцията Автоматичен QA ще засича само одобрени начални обръщения. Направените промени се прилагат за бъдещи разговори."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнахте горния лимит от 100 одобрени начални обръщения. Ако искате да добавите още, може би е време да прегледате и прецизирате съществуващите си начални обръщения."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимитът за начално обръщение е достигнат"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени начални обръщения"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сега"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на категория"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване за постоянно на тази категория за оценяването?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списъка с категории"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтритата категория няма да се появява в новите разговори и филтри. Историческите данни в контролните панели и разговорите остават непроменени."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорията ще бъде премахната от всички карти за резултати, към които е била добавена."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно изтриване на категорията на картата за резултати"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорията на картата за резултати е изтрита"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на категорията на картата с резултати"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категориите на Автоматичния QA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), ", поддържани от модела на ИИ, ще останат в картата за резултат, но повече няма да се оценява автоматично"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключването на интеграцията на LLM не изключва напълно функцията за Автоматичен QA. Ето какво ще се случи:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Историческите данни"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " ще останат в контролния панел на Автоматичния QA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръчно добавените първопричини"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " ще бъдат запазени, но всички първопричини, базирани на LLM, ще се премахнат"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на Автоматичния QA, базиран на LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичният QA използва ИИ и големи езикови модели (Large language models, LLM) за автоматично оценяване на конкретни категории. Вашите данни се обработват съгласно стриктно споразумение за обработка на данни (DPA) и не се използват за обучение на модели."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече относно генеративния ИИ"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Функцията Автоматичен QA използва услугата Microsoft Azure OpenAI за ", _interpolate(_named("automatically_scoring_categories")), ". Вашите данни се обработват съгласно строго споразумение DPA само за предоставяне на функцията Автоматичен QA за вас и ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автоматично оценяване на някои категории"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["никога не се използва за обучение на модели за OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичният QA, базиран на LLM, е включен"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичният QA, базиран на LLM, е изключен"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA, базиран на LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизирано оценяване с ИИ в Автоматичен QA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка на данни на категории за функцията Автоматичен QA, базирани на MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласието на Microsoft Azure е оттеглено"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадено съгласие за Microsoft Azure. Разговорите скоро ще получат автоматични оценки."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още можете да използвате функцията Автоматичен QA, но ето какво ще се случи"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивирането на услугата MS Azure OpenAI няма да изключи Автоматичен QA като функционалност."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да направите това в изгледа на категорията на картата за резултат."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категориите за функцията Автоматичен QA, предоставяни от модела, ще останат в картата с резултати, но няма да бъдат оценявани автоматично."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричините, които сте добавили ръчно, ще останат. Всички първопричини, генерирани от модела, ще бъдат премахнати."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Историческите данни остават в контролния панел на функцията Автоматичен QA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивирайте обработката на данни от услугата MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По-широка гама от категории за функцията Автоматичен QA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нестандартна многоезична поддръжка."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бързо добавяне на нови категории."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подобрена точност на оценката. GPT-3.5 показва контекстуална осведоменост на човешко ниво."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възползвайте се от най-новите постижения в ИИ технологията."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ето какво ще получите"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешете обработката на данни от услугата MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадено съгласие"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е дадено съгласие"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички нови потребители се възлагат към това работно пространство"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работно пространство по подразбиране"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-часов формат (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-часов формат (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат на часа"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пон."])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нед."])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профилът на компанията е обновен"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало на седмицата"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специална интеграция"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификаторът на ключа за достъп и тайният ключ обикновено се намират в „Управление на достъпа“ > „Потребители“"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на ключа за достъп на AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна на промяната на тайния ключ за достъп"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на стойността на тайния ключ за достъп"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката позволява да се дефинира само един регион, който обикновено се намира в „Местоположение“"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион в AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете таен ключ"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таен ключ за достъп за AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да намерите цялата необходима информация в Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обикновено се намира в настройките на екземпляра на Amazon Connect и страницата за съхранение на данни"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Място за съхранение на звукозаписи на разговори"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка при добавянето на Amazon Connect. Проверете дали всички полета са попълнени правилно и опитайте отново."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мястото за съхранение трябва да бъде във формата на набор/папка, разделена с наклонена черта."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Свържете се с ", _interpolate(_named("link")), ", за да добавите връзка."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддръжка на клиенти"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички ваши казуси в Zendesk ще бъдат автоматично синхронизирани. Можете да изберете да изключите избрани казуси."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азиатско-тихоокеански регион (Токио)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азиатско-тихоокеански регион (Сеул)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азиатско-тихоокеански регион (Осака)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азиатско-тихоокеански регион (Мумбай)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азиатско-тихоокеански регион (Сидни)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада (централна)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Франкфурт)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Цюрих)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Ирландия)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Лондон)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион на сървъра"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Близък изток (ОАЕ)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете регион на сървъра"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Южна Америка (Сао Пауло)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източна част на САЩ (Вирджиния)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източна част на САЩ 2 (Охайо)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Западна част на САЩ (Орегон)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката вече е добавена"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало на връзката"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизиране само на разговори, които"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["първи канал, втори канал"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Уникалният идентификатор на тази връзка е ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 месец"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 година"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 месеца"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 месеца"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивните разговори ще бъдат изтрити след избрания период на задържане. Изпратените прегледи остават видими в изгледите „Активност“ и „Контролен панел“."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на задържане"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете период на задържане"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имайте предвид, че това действие е необратимо."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбирам, че това ще премахне всички данни, свързани с отдела по поддръжка за тази връзка от Zendesk QA, включително разговори, полета и тагове."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратените прегледи няма да бъдат засегнати и ще останат видими в конкретни изгледи."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["група едно, група две"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включват"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["активно развиване"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тази интеграция все още е в ", _interpolate(_named("activeDevelopment")), ". Някои подробности може да липсват (като прикачени файлове или специални полета)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пощенска кутия едно, пощенска кутия две"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["таг едно, таг две"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API токен"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API таен токен"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете „Имена на полета на казуса“ за Freshdesk в Настройки → Полета на казуса"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете „Custom Field Names“ за Help Scout под Settings → Custom fields"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете „Имена разговори на Klass“ за Kustomer в Настройки → Klasses → Разговор"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете „Име на специално поле“ за Live Agent в Настройки → Специални полета"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете „Custom Field Name“ за Wix под Settings → Custom fields"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделете идентификаторите на полетата със запетаи. Можете да намерите тези идентификатори в Zendesk в Администратор → Полета за казуса."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете няколко от потребителите на вашия отдел по поддръжка, за да прегледате първите си разговори. Не се притеснявайте, ние няма да ги уведомим, освен ако не ги поканите да станат членове на работно пространство."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно интегрирани"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на членове"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сега ще започнем да извличаме вашите разговори."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Домейн: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Идентификатор на отдела по поддръжка: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката ви не може да бъде удостоверена"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко е готово. Това е вашата нова връзка."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Някои връзки не са обновени или са станали неактивни. Опитайте се да обновите тези връзки или ги премахнете от списъка си."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на клиента за API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таен низ на клиента за API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създайте нов API токен от Настройки → Акаунт → API и SDK. URL адресът за пренасочване, който трябва да използвате, е ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на акаунта"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете своя отдел по поддръжка, за да направите първите стъпки"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако вашият акаунт в Intercom е настроен да се обслужва от ЕС или Австралия, обновете това."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Имейл, който използвате за влизане в ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на името, имейла и телефонния номер на крайния потребител. Банковите идентификационни данни вече са скрити за всички връзки с отдела по поддръжка."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма интеграции. Добавете сега."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нуждаете се от следните роли:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на връзка"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на нова връзка"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахването на отметката ви позволява ръчно да добавите тази връзка само към конкретни работни пространства"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се с всички съществуващи работни пространства"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички работни пространства"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ключ"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API таен ключ"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канали"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Връзката ", _interpolate(_list(0)), " е изтрита"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на връзката"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създадена на"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да вземете вашия API токен по-долу."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праг за CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на връзката ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете „изтриване на връзката“, за да премахнете тази връзка"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на връзката"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявайте и наблюдавайте вашите свързани източници на данни."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на връзката"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С изключение на"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделете имената на полетата със запетаи"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно обновяване"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте уникално име на вашата интеграция"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на разширените опции"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на полета с чувствителни данни"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никога не съхранявайте URL адреси на прикачени файлове"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнориране на прикачени файлове"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никога не съхранявайте съдържание на разговор (можете да го извлечете по-късно при поискване от вашия отдел по поддръжка)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнориране на съдържанието на разговора"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никога не съхранявайте темата на разговора (можете да я извлечете по-късно при поискване от вашия отдел по поддръжка)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнориране на темата"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивни"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вашият ", _interpolate(_list(0)), " поддомейн"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион на Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съвпадение на идентификатора на екипа на Inbox"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифров идентификатор на вашия екип за Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнява се..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пощенски кутии"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръчно обновяване на връзката"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на клиентски данни"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма работни пространства"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на чувствително поле"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор на чувствително поле"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подновете връзката"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подновете връзката"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързване към тестова среда на Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Настройване на връзка с ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на разширени опции"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извличайте само разговори с резултат по-нисък или равен на зададения праг"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложете праг на CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизиране само на разговори, които..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получете най-новите разговори"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновяване на връзката"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката е обновена"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителско име"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашия отдел по поддръжка"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["За да прекратите абонамента си и да изтриете акаунта си, се свържете с ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддръжка на клиенти"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на акаунт"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на казус в Zendesk Support (или друг отдел по поддръжка) не засяга свързаните прегледи на разговори. Все още можете да ги преглеждате и управлявате."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриването на казус в Zendesk QA изтрива и свързаните прегледи на разговори.", _interpolate(_named("line_break")), "Изтриването на казус в Zendesk Support (или друг софтуер за отдел по поддръжка) не засяга свързаните прегледи на разговори. Все още можете да ги преглеждате и управлявате."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на прегледи"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на прегледи е окончателно. Това влияе и върху данните в контролните панели."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на всички прегледи"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на всички"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички прегледи са изтрити"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на всички"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато има прегледи, свързани с изтрити казуси, ще ги видите тук."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма прегледи"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на идентификатор на казус"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Идентификатор на казус: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрити разговори"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Работните пространства по подразбиране не могат да бъдат изтрити. За да изтриете това работно пространство, изберете друго работно пространство по подразбиране в ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки на акаунта"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие не може да се отмени."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["премахне и изтрие всички данни"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Разбирам, че това ще ", _interpolate(_named("removeAllData")), ", свързани с това работно пространство, включително потребители на работно пространство и връзки."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на работното пространство"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете „изтриване на работно пространство“, за да изтриете това работно пространство"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на работното пространство ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изтриване на работното пространство"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бъдете сигурни какво правите или се консултирайте с професионалист предварително."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете дали обектът на прегледа по подразбиране да бъде избран въз основа на най-честия консултант или текущия изпълнител."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-активен консултант"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа по подразбиране"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволете на потребителите да преглеждат собствените си разговори и дайте възможност на консултантите да извършват прегледи"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работното пространство е изтрито"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работното пространство е обновено"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценявайте от неутрална гледна точка, като скриете прегледи, дадени преди това от други преглеждащи"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпристрастно оценяване"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвят"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие не може да се отмени."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имайте предвид, че това ще премахне и изтрие всички данни, свързани с това работно пространство, включително потребители на работно пространство и връзки."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на работното пространство"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работно пространство се изтрива..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценявания"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магическа връзка"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магическа връзка"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можете да деактивирате текущия си метод за влизане"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методите за автентификация са обновени"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешаване на определени видове методи за автентификация за всички потребители."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификации"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АКАУНТ"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители и работни пространства"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създавайте и управлявайте работни пространства, а също канете или свързвайте потребители."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставане на страницата"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напускане на страницата"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искате ли да напуснете тази страница и да загубите всички промени?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификации"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонамент"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда няма разговори, които да импортирате. Започнете, като проведете няколко разговора с вашия отдел по поддръжка, след това се върнете тук, за да ги анализирате."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени разговори"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можете да изтриете тази системна категория"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на категория"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на категории е окончателно. Това ще изтрие всички данни за категорията от отчетите."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да възстановите тази категория, ще трябва да я създадете отново."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на категория"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на категория"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти за резултати"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичните категории попълват картата за резултат автоматично с предложени оценки, за да спестят време за преглед. Ръчните категории се попълват изцяло от преглеждащите."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последно обновяване"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто."])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръчно"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключване"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емпатия"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правопис и граматика"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начално обръщение"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбиране"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четливост"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложено решение"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити категории"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити първопричини"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити карти за резултати"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте с друго търсене или променете филтрите"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " категории"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " категории"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " категории"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички категории"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " категории"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " карти за резултати"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " карти за резултати"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " карти за резултати"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички карти за резултати"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " карти за резултати"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " статуса"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " статуса"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " статуса"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички статуси"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " статуса"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " типа"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " типа"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " типа"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички типове"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " типа"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " работни пространства"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " работни пространства"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени работни пространства"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " работни пространства"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички работни пространства"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " работни пространства"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тежестта на ", _interpolate(_named("weight")), " допринася за ", _interpolate(_named("percentage")), "% от общия резултат"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на карта за резултат"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на карти за резултати е окончателно. Това ще изтрие всички данни на картата за резултат от отчетите."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да възстановите тази карта за резултат, ще трябва да я създадете отново."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на карта за резултат"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последно обновяване"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивен"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричини"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти за резултати"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Скалата за оценяване е обновена. ", _interpolate(_named("buttonStart")), "Започнете да преглеждате", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA задава двоична скала по подразбиране, но можете също да изберете по-прецизна скала:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ако искате да видите различни опции за скали за оценяване, конфигурирани по категория, отидете на вашия ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емпатия/тон"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знания за продукта"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чрез актуализиране на скали за оценяване:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата предишна скала за оценяване и съответните категории ще бъдат архивирани (а не изтрити)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Някои данни от контролния панел ще бъдат засегнати, тъй като вашият стар и нов мащаб може да не са сравними."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клониране на моите съществуващи настройки за категории за оценка към новата скала"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да обновите скалите за оценяване?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да промените формата на показване на картата за резултат (цифра или емоджи) и да направите категориите задължителни или незадължителни."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използване на емоджи вместо цифри"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволяване на потребителите да пропускат категории"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип скала за оценяване"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Категориите за 1 работно пространство са клонирани"]), _normalize(["Категории за ", _interpolate(_named("workspacesAmount")), " работните пространства са клонирани"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки на работното пространство"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ако ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично извеждайте обаждания, при които консултантът не подканва клиента да очаква мълчание в разговора."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът има периоди на мълчание, продължаващи по-дълго от зададения праг."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определете максималния праг за период на мълчание в разговори"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на мълчание"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтритите прозрения няма да се появяват в новите разговори и филтри. Историческите данни в контролните панели и разговорите остават непроменени."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно изтриване на осветеното прозрение"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветеното прозрение е изтрито"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на осветеното прозрение"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледайте разговори, съдържащи специфични думи или фрази в текстови записи и съобщения."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично идентифицирайте обажданията, при които липсва задължителното изявление, че разговорът се записва, като например „Това обаждане ще бъде записано“ и подобни."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Има за цел да идентифицира изявление, че разговорът се записва, с помощта на Large Language Model (LLM), който динамично разбира контекста, елиминирайки необходимостта от предварително зададени фрази."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсва изявление за извършван звукозапис"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично извеждайте обаждания, при които консултантът не подканва клиента да очаква мълчание в разговора."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период на мълчание"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажете периоди на мълчание в обаждане"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Трябва да направите промени в абонамента си? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") и ние с удоволствие ще ви помогнем."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично анализирайте 100% от разговорите между консултанти, BPO, канали и езици. ИИ открива проблеми, пропуски в знанията и възможности за коучинг за подобряване на обслужването."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% покритие на автоматичния QA"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзиви и коучинг на консултантите"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветени прозрения от ИИ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширено отчитане и контролни панели"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ и филтриране на отношението"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преводи при поискване"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на възложените задачи и поставяне на цели"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг и тестове"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Quality Assurance (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко от Professional, плюс"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (предишен)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция Webhook за незабавно уведомяване за критични грешки"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция на склад за данни"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция на Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка на персонализирана интеграция"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специализиран мениджър за работа с клиенти"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Директен достъп до нашите продуктови и инженерни екипи чрез Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко в Growth Suite, плюс"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA за 100% покритие"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявани от ИИ прозрения за разговора за анализ на данните"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подобряване и филтриране на данни от отдел по поддръжка с помощта на ИИ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване: Автоматизирано откриване за взаимодействия, които трябва да бъдат прегледани"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ и филтриране на отношението"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчитане и прозрения за ефективността, предоставяни от ИИ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превод на разговор при поискване"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проследяване на времето за преглед"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ на степен на преминаване"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко в ИИ Suite, плюс"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise пакет за внедряване, включително интеграция на Salesforce и Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка на персонализирана интеграция"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция на склад за данни"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция Webhook за незабавно уведомяване за критични грешки"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специализиран мениджър за работа с клиенти"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Директен достъп до нашите продуктови и инженерни екипи"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранен достъп и влияние върху нашата пътна карта"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен преглед на възложени задачи и поставяне на цели"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целеви коучинг и анализи"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове за управление на обучението"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на потребителски роли и разрешения"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на чувствителни данни"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширени опции за филтриране и търсене на разговори"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализируеми карти за резултат с множество оценки, регулируема тежест и опции за автоматичен неуспех"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширение за браузър за преглед на разговори отвсякъде"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ на първопричините"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчитане на ефективността за сравняване на консултанти, екипи, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкета за CSAT и анализ на отзиви от клиенти"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко в Starter, плюс"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (предишен)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Множество скали за оценяване на разговор"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция със Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявани от ИИ интерактивни прозрения за разговора"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на възложени задачи"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закачени елементи за коучинг"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коучинг сесии"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ и филтриране на отношението"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтриране въз основа на сложността на разговора"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрения за разговора"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условни карти за резултат"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ на първопричините"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставяни от ИИ разширени анализи за CSAT"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване на оценяването"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширение за браузър за преглед на разговор отвсякъде"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване, за да откривате разговори, които трябва да прегледате"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превод на разговор при поискване"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проследяване на времето за преглед"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиран план"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (предишен)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неограничени прегледи на разговори"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експорт в CSV формат"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колеги и самооценявания"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти за резултат с регулируема тежест и опции за автоматичен отказ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всеобхватни опции за филтриране"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневни, седмични или месечни цели за преглед"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети за CSAT и основни анализи"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контролни панели"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчети за сравняване на консултанти, екипи, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на потребителски роли и привилегии"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искам да направя промяна в абонамента си за Zendesk QA.\n\nПодчертайте или посочете една опция и посочете броя на лицензите, които искате да промените:\nИскам да [добавя X лиценза] [премахна X лиценза] [премина към план за Zendesk QA]\n\nМоля, споделете името на компанията си и името, свързано с вашия акаунт в Zendesk QA, както и други данни, които трябва да знаем, за да обработим тази промяна."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна в абонамента за Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искам да закупя абонамент за Zendesk QA.\n\nМоля, споделете името на компанията си и името, свързано с вашия акаунт в Zendesk QA, както и други данни, които трябва да знаем, за да обработим тази заявка:\n\nМоля, задайте всички конкретни въпроси, на които искате да получите отговор преди покупката:\n\nБлагодарим!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закупуване на абонамент за Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият абонамент приключва на ", _interpolate(_named("date")), " (остават ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пробният ви период приключва на ", _interpolate(_named("date")), " (остават ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 ден"]), _normalize([_interpolate(_named("n")), " дни"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ри на всеки месец."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ти всеки месец."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ви на всеки месец."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ти всеки месец."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ри всеки месец."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("dayOfMonth")), "-ти всеки месец."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Таксувано на ", _interpolate(_named("card")), " (изтича ", _interpolate(_named("expires")), ") на ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изтича на"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Изтекъл"]), _normalize(["Изтича след 1 ден"]), _normalize(["Изтича след ", _interpolate(_named("expiresIn")), " дни"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Началната цена включва ", _interpolate(_named("n")), " потребителски лиценза"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["цените започват от ", _interpolate(_named("sum")), "/месец"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месец"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на потребител/месец"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на година"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на месец"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тримесечие"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["премахнете планираната прекратяване"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ако искате да продължите да използвате Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият абонамент беше прекратен на ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият абонамент ще изтече на ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "-дневен пробен период без допълнителни разходи!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["използвани"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вие имате ", _interpolate(_named("n")), " потребители. Броят на лицензите не може да бъде по-малък от този."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Абонаментът ще бъде подновен на ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["година"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вашият текущ план за Zendesk QA (", _interpolate(_named("plan")), ") не включва функции, предоставяни от ИИ. За да разгърнете пълната мощ на функциите за гласово интелигентно прогнозиране, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["За да прекратите абонамента си, свържете се с нас ", _interpolate(_named("link")), " или започнете чат."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["План за управление на качеството"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на качеството"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абонаментът е обновен"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на кредитна карта"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички планове"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактуриране"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте информацията за фактуриране"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал на фактуриране"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна на абонамент"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият абонамент ще бъде прекратен в края на периода на фактуриране."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се с нас"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се с нас за редактиране"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущ план"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирано ценообразуване"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понижаване на плана"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годишно (спестете 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годишно"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесечно"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плащане веднъж в годината"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесечно"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на абонамент"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разгледайте плановете"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безплатен пробен период"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръчно фактуриране"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да прегледате и потвърдите окончателното резюме на поръчката в следващата стъпка."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-популярни"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате активен начин на плащане."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В пробен период"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подновете текущия си абонамент, за да промените плана"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пауза на абонамент"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесечно"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На тримесечие"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годишно"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно активиране на абонамент"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подновете абонамента"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензи"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнете пробен период"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменени"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без подновяване"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На пауза"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на абонамента"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробен период"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надграждане"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминаване към по-висок абонамент"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специална"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, отделете минута, за да предоставите отзив за поддръжката на клиенти. Благодарим предварително – ", "{", "{", "agentName", "}", "}", " оценява вашите отзиви!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здравейте, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Анкета за CSAT на ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как бихте оценили поддръжката, която получихте от ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Наистина го оценяваме. Ще използваме вашите отзиви, за да направим ", _interpolate(_list(0)), " по-добро за всички."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за анкетите"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете типа анкета"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това може да бъде променено по-късно."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измервайте и подобрявайте удовлетвореността на клиентите с автоматизирани анкети за CSAT на множество езици."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени анкети"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте анкета"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на анкетата"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е създадена"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опитайте да промените ключовата дума или ", _interpolate(_named("contact_us")), ", ако искате да го видите в списъка тук"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["уведомете ни"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерен език"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изберете един или повече езици от списъка по-долу. ", _interpolate(_named("contact_us")), " ако не виждате езика, който искате да използвате, посочен тук."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на езици"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомете ни"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на език"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Последно публикувани ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Последно запазени ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " въпрос"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въвеждащ въпрос"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ляв етикет за мащаба"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема на имейла"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причини"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десен етикет за мащаба"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим ви"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако анкетата има липсващи преводи, вместо това ще бъдат показани текстовете на основния език"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прилагане на преводи"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсващи преводи"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не забравяйте да прегледате всичките си преводи, преди да ги приложите. Направете промени в изтегления файл, запазете го и качете отново, ако е необходимо. Ако токен ", _interpolate(_named("token_example")), " не се показва като таг, той е или в неправилен формат, или в поле, което не поддържа токени."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на преводи"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преведени"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обобщава разговора и показва предложения за коментари."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ асистент (на английски) на Zendesk QA"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да редактирате типове, след като бъдат създадени"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този тип вече съществува. Изберете различно име."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете персонализирания тип като опция за избор в падащото меню „Тип анкета“ и групирайте всички създадени анкети на контролния панел."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете дума(и) и натиснете Enter, за да запазите"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирайте потребителски типове"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Името на типа не може да бъде празно"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определете типа"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализираният тип не беше запазен. Натиснете Enter, за да го запазите."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл на подателя"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на подателя"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["До каква степен сте съгласни с изявлението: „", _interpolate(_named("agentName")), " улесни справянето с проблема ми“?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Колко лесно беше получаването на помощ от ", _interpolate(_named("brandName")), " до момента?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Като цяло колко лесно беше да разрешите проблема си днес?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колко лесно беше взаимодействието с нашия екип?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL адрес на политиката за поверителност (по избор)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL адрес на Условията за ползване (по избор)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Как бихте оценили поддръжката, която получихте от ", _interpolate(_named("agentName")), "?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как беше нашата услуга за тази заявка?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доколко сте доволни от представителя по поддръжката?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Доколко сте доволни от услугата, която сте получили от ", _interpolate(_named("agentName")), " днес?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиран въпрос"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дефинирайте свой персонализиран ", _interpolate(_named("type")), " въпрос..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете персонализиран въпрос"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиране на URL адресите"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте вашето имейл съобщение"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирането на името на персонализиран тип въпрос ще го обнови във всички анкети, които го използват, без да засяга събраните данни."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирайте потребителски типове"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема на имейла"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въвеждащ въпрос"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " въпрос"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на нов персонализиран тип"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдържание"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Токен за персонализиране"]), _normalize(["Токени за персонализиране"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Плъзнете и пуснете токена за персонализиране в полето по-долу."]), _normalize(["Плъзнете и пуснете токените за персонализиране в полетата по-долу."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разговор ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понастоящем е възможно да изпращате анкети за CSAT само по имейл. Работим по въпроса да ви предоставим повече възможности, като например анкети за CSAT относно отдела по поддръжка, SMS анкети и други."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Синхронизирайте или подновете връзката"]), _normalize(["Синхронизирайте или подновете връзките"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизирайте или подновете връзката ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Връзката не е синхронизирана правилно"]), _normalize(["Връзките не се синхронизират правилно."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["премахнете отметката от съответното квадратче ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификация сега ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете поне едно правило за изпращане на анкета, за да активирате връзката"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В момента тази връзка е деактивирана. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тази връзка скрива чувствителни клиентски данни. За да разрешите изпращането на анкета,\n", _interpolate(_named("link")), " в настройките."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тази връзка изисква допълнителни разрешения за изпращане на анкети.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификация сега"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA изисква повече разрешения за Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " и ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 час"]), _normalize([_interpolate(_named("n")), " часа"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " минути"]), _normalize(["1 минута"]), _normalize([_interpolate(_named("n")), " минути"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["час"]), _normalize(["часа"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " за конфигуриране на правила за изпращане на анкета."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" ден"]), _normalize(["дни"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзките към анкетата изтичат"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзките изтичат след"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предотвратете отговарянето на анкети, ако е минало твърде много време"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " анкета ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделете таговете със запетаи или натиснете Enter след въвеждане. Уверете се, че всеки е посочен точно както в Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте условията, въз основа на които се изпраща анкетата."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила за изпращане на анкета"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила за изпращане на последващи анкети"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Анкета ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " вече съществува.\nРазрешете конфликта, като промените правилата тук или в ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вече сте настроили връзка със съответстващи правила за анкети ", _interpolate(_named("name")), ". Разрешете конфликта, като промените правилата тук или в ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете друго правило"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на бранд"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете условие"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърждение"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на стойност"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на таг"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["съответстваща на същите правила"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете стойност"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратен е имейл"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратен последващ имейл"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вграден фрагмент и проследяване по имейл"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копиране на кода"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикачете анкети на Zendesk QA към вашите имейли, като добавите кода на фрагмента към вашата настройка на Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръководство за инсталиране"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворете Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед на фрагмента"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кодът на фрагмента е копиран в клипборда"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирането на фрагмента не беше успешно"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вграден фрагмент"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази анкета има незапазени промени. Запазете я отново, за да сте сигурни, че кодът на фрагмента отразява последните актуализации."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на връзка"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма добавени връзки"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете имейл, онлайн чат, телефонни обаждания и други в Zendesk QA с вашите връзки"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за доставката на анкета"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила за изпращане на анкета"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила за изпращане на последващи анкети"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на правило за бранд"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на правило"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автентификация"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + последващи имейли"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете своя акаунт в Intercom с анкетата за CSAT в Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставяне чрез"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кой получава анкетата и кога"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на правила"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активирано"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетите в приложението позволяват на вашите клиенти да попълнят анкета, без да напускат чата."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартиране на Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила за доставка на връзка"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Анкета ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " вече съществува.\nРазрешете конфликта, като деактивирате връзката или промените правилата тук или в ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте условията, въз основа на които се изпраща анкетата"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създадено от ", _interpolate(_named("name")), " на ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Премахване ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахването на този език означава, че той вече няма да бъде видим за респондентите и всички преведени текстове се изтриват."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на езика"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие не може да се отмени."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Като алтернатива можете да деактивирате езика, като го скриете от анкетата. Това ще ви позволи да запазите всичките си преводи на място, без да ги показвате на респондентите."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последно редактирано от ", _interpolate(_named("name")), " на ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за езиците на анкетата"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Преди да добавите други езици, уверете се, че сте завършили извършването на промени в стъпките за стил и съдържание. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на език"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преведени полета"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видими за респондентите"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По подразбиране"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете файл за превод"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на езика"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Езици"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" изтеглете файл за превод"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на преводи"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Преводът е завършен. ", _interpolate(_named("review_link")), " или ", _interpolate(_named("download_link")), ", за да правите промени."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" изтеглете файл за превод"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледайте какво липсва"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Част от съдържанието все още не е преведено и ще бъде заменено с английски език. ", _interpolate(_named("review_link")), " или ", _interpolate(_named("download_link")), ", за да добавите липсващи преводи."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване на анкетата"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Анкетата съдържа незапазени промени. ", _interpolate(_named("save")), ", за да изтеглите файла за превод с последните промени."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качете файл за превод"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете предпочитана скала за оценяване"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставя на клиентите падащо меню за избор на причина за оценка"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емоджита"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ляв текст за скала (по избор)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на причина"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причината не може да бъде празна"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази причина вече съществува"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на падащото меню с причините за вашия резултат"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на причина и добавянето ѝ отново със същото име или преименуването на причина винаги създава нов запис. Това се отразява на данните, показани на контролния ви панел."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на причината"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрита причина остава в отчетите на контролния панел, но няма да бъде видима за новите респонденти на анкета. Това важи и за свързаните преводи."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на причина"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изтриване на причина „", _interpolate(_list(0)), "“"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Десен текст за скала (по избор)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По 5-степенна скала 1 и 2 се считат за отрицателни резултати; 3 е неутрален; 4-5 са положителни"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на текст за скала"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " скала"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скала за оценяване"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA импортира вашите разговори. Този процес може да отнеме до 24 часа."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще се използва като токен за персонализиране по време на анкетата."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование на бранда"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бутони и връзки"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирайте външния вид на вашата анкета"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фон на лого"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддържат се формати JPG, PNG и GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лого"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформление"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качване на лого"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършете със стил"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете съобщение, което потребителите ще видят, след като изпратят своите отзиви"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарствено съобщение"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим ви"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправилен шестнадесетичен цветови код"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грешен имейл адрес"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле не може да бъде празно"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправилен URL адрес"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невидими:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видими:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Езици"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратени анкети"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети, изпратени през последните 30 дни"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е изтрита"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е дублирана"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейлът за предварителен преглед е изпратено"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е публикувана"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е обновена"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкетата е поставена на пауза"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пауза"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставяне на анкетата на пауза"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете имейла, за да изпратите прегледа на анкетата"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете език за преглед"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете имейл за предварителен преглед"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратете примерна анкета на вашия имейл. Оценките от предварителния преглед на анкетата няма да се съхранят."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Предварителен преглед на ", _interpolate(_named("type")), " анкета"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед на анкетата по имейл в мрежата"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване като чернова"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование на бранда"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкретно за бранда име може да се добави в стъпката за оформление"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на клиент"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор за поддръжка"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представител на поддръжката"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрити"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На пауза"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Анкета за CSAT на ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени са незапазени промени"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновете и подновете анкетата"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ако не виждате правилно този имейл, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не бях доволен от поддръжката, която получих, защото..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставяно от Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете причина"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резюме на разговора"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример: Клиентът имаше проблеми с актуализирането на метода на плащане. Консултантът сподели статия, която може да помогне. Проработи."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставяно от ИИ на Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия за ползване"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим за отзива!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каква е основната причина за резултата ви?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кажете ни защо дадохте тази оценка..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голямо усилие"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малко усилие"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щракнете тук, за да отворите анкетата"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поверителност"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият персонализиран въпрос се появява тук"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вие оценихте вашия ", _interpolate(_list(0)), " опит с резултат от"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване на отзив"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим ви"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекратяване на абонамент"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Много недоволен/а"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Много удовлетворен/а"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обърнете внимание, че текущият статус на анкетата и правилата за доставка няма да бъдат включени в дубликата на анкетата"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дублиране на анкета „", _interpolate(_list(0)), "“"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако използвате вградения имейл фрагмент, не забравяйте да го премахнете от Zendesk, преди да поставите анкетата на пауза"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не се събират отговори, когато анкетата е поставена на пауза."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставяне на анкетата на пауза"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че сте премахнали фрагмента от Zendesk, преди да поставите анкетата на пауза."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на анкетата"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако използвате вградения имейл фрагмент, не забравяйте да го премахнете от Zendesk, преди да изтриете анкетата."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие ще изтрие анкетата за постоянно. Потребителите, които са получили анкетата, все още могат да я попълнят, като всички данни, свързани с анкетата, ще останат в Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на анкетата ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че сте премахнали фрагмента от Zendesk, преди да изтриете анкетата."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложените от вас промени ще бъдат видени от новите респонденти."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване на анкета"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че копирате най-новата версия на вградения фрагмент след публикуване на анкетата."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако използвате вградения имейл фрагмент, не забравяйте да го копирате в Zendesk, след като възобновите анкетата."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължете да изпращате анкетата и да събирате отговори."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновете и подновете анкетата"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че сте добавили вградения фрагмент към Zendesk след възобновяване на анкетата."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновяване на анкетата"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверете се, че копирате най-новата версия на вградения фрагмент, след като актуализирате анкетата."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да изтриете тази анкета? Това действие не може да се отмени."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на анкетата?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробният ви период е изтекъл, но можете да продължите да се наслаждавате на Zendesk QA, като надградите до пълен акаунт."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробният ви период е изтекъл, но можете да продължите да се наслаждавате на Zendesk QA, като надградите до пълен акаунт. Говорете с вашия местен администратор, за да уредите това."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробният ви период приключи"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надградете сега"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият безплатен пробен период приключи."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да продължите да използвате пълната версия на Zendesk QA, въведете данните си за плащане."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да продължите да използвате пълната версия на Zendesk QA, администраторът на вашия акаунт трябва да въведе данни за плащане."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на плащането"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["потребител"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Създадено на ", _interpolate(_named("date")), " от ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " възложена задача"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда, че все още работите върху това. Губите цялата информация, която сте добавили дотук, ако излезете, без да запазите промените."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тази възложена задача ще се повтаря на всеки ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тази възложена задача ще се повтаря всяка ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Всички обекти на прегледа, обхванати в един цикъл"]), _normalize(["Всички обекти на прегледа, обхванати в ", _interpolate(_named("nr")), " цикъла"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " разговора"]), _normalize([_interpolate(_named("n")), " разговор"]), _normalize([_interpolate(_named("n")), " разговори"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["от ", _interpolate(_named("nr")), " консултанта"]), _normalize(["от ", _interpolate(_named("nr")), " консултант"]), _normalize(["от ", _interpolate(_named("nr")), " консултанти"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["участник"]), _normalize(["участници"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " разговора на цикъл"]), _normalize([_interpolate(_named("n")), " разговор на цикъл"]), _normalize([_interpolate(_named("n")), " разговори на цикъл"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " на обект на прегледа в един ред"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["обект на прегледа"]), _normalize(["обекти на прегледа"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["преглеждащ"]), _normalize(["преглеждащи"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обекти на прегледа"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащи"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители на работно пространство"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители на работно пространство с изключение на консултантите"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цикъл ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не се повтаря"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " възложена задача"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процес на преглед"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за възложените задачи"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на възложена задача"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие ще изтрие завинаги възложената задача и ще премахне нейните данни от контролния панел. Всички прегледи, свързани с нея, ще останат в Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на възложена задача ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е изтрита"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е дублирана"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма добавени възложени задачи"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно създаване на възложена задача"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на възложена задача"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цикълът се опреснява и се създава нов списък със задачи. Вече направени прегледи все още се зачитат към целта."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незабавно"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В началото на следващия цикъл на ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кога да публикуваме промените?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промените, направени в тази възложена задача, ще бъдат публикувани с тази възложена задача"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задаване на възложената задача като активна?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтаря се в началния ден на възложената задача"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първи петък на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втори петък на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трети петък на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърти петък на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последен петък на всеки месец"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първи понеделник на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втори понеделник на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трети понеделник на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърти понеделник на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последен понеделник на всеки месец"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първата събота на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втора събота на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трета събота на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърта събота на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна събота на всеки месец"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първа неделя на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втора неделя на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трета неделя на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърта неделя на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна неделя на всеки месец"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първи четвъртък на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втори четвъртък на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трети четвъртък на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърти четвъртък на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последен четвъртък на всеки месец"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първи вторник на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втори вторник на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трети вторник на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърти вторник на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последен вторник на всеки месец"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първа сряда на всеки месец"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втора сряда на всеки месец"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трета сряда на всеки месец"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърта сряда на всеки месец"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последната сряда на всеки месец"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирано повторение"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ден"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-во число на всеки месец"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първа"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Втора"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трета"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвърта"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтаря се"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делник"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Край"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма резултати"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часова зона"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки на цикъла"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на възложена задача"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация за Basic"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В началото на всеки цикъл на възложени задачи се генерира предварително определен списък с разговори за оценка."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разширени настройки"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично извличане на нови разговори, когато целта за преглед не е постигната"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичното извличане не е възможно при цели за преглед, изразени в процент (%)"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да е цяло число, а не десетично"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешаване на заместване на разговорите (роля на преглеждащ и по-висока)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замяна на разговори"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешаване на преглеждащите да се възлагат техни собствени разговори за преглед"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценявания"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледи"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минималният брой прегледи трябва да бъде поне 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посочете минимални прегледи за всеки обект на преглед"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговори"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["процент (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледи"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прегледа общо"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всеки преглеждащ трябва да направи определен брой прегледи"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащите трябва колективно да прегледат определен брой разговори"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минималният брой прегледи трябва да бъде поне 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целта на прегледа трябва да бъде между 1 и 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на цел"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всеки обект на прегледа трябва да има минимален брой прегледани разговори"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащите могат да избират разговори от споделения пул"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На преглеждащите се възлагат разговори от споделения пул"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подход към възлагане"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички ботове"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички участници в работното пространство"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители на работно пространство"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете обекти на прегледа"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кой ще бъде прегледан?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете обекти на прегледа"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете кого да прегледате"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключенията са разрешени само когато са избрани всички потребители на работно пространство или група"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащи и обекти на прегледа"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Текущи: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работно пространство по подразбиране"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какъв тип потребители трябва да бъдат избрани за обекти на прегледа?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-активен консултант"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на обекти на прегледа (по избор)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребители за изключване"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на преглеждащи (по избор)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребители за изключване"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители на работно пространство с изключение на консултантите"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете преглеждащи"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кой ще прави прегледите?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете преглеждащи"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В това работно пространство не са разрешени самооценявания"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешаване на самооценявания"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В това работно пространство не са разрешени самооценявания."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Съвпадащ разговор днес"]), _normalize(["Съвпадащи разговори днес"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показва се така, сякаш възложената задача е започнала днес."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бъдещите числа може да варират."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма избрани обекти на прегледа"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма избрани преглеждащи"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Обект на прегледа"]), _normalize(["Обекти на прегледа"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Преглеждащ"]), _normalize(["Преглеждащи"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично извличане: разрешено, ако няма достатъчно разговори"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в петък от ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в понеделник от ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в събота от ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в неделя от ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в четвъртък от ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " във вторник от ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " в сряда от ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ежедневно от ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Започва на ", _interpolate(_named("startDateTime")), " и завършва на ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " условия за дата"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " разговор и условия на отдел по поддръжка"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Без разговор и ", _interpolate(_named("count")), " условие на отдел по поддръжка"]), _normalize(["Без разговор и ", _interpolate(_named("count")), " условия на отдел по поддръжка"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " разговор и без условие на отдел по поддръжка"]), _normalize([_interpolate(_named("count")), " разговори и без условие на отдел по поддръжка"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Подход: преглеждащите да избират разговори"]), _normalize(["Подход: на преглеждащите се възлагат разговори"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коригирайте грешките, подчертани в резюмето, за да продължите"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел: преглеждащите трябва да прегледат всички разговори"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Цел: преглеждащите трябва да прегледат един разговор"]), _normalize(["Цел: преглеждащите трябва да прегледат ", _interpolate(_named("count")), " разговора"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цел: преглеждащите трябва да прегледат ", _interpolate(_named("count")), "% разговора"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел: няма зададена цел за преглед"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел: всеки обект на прегледа трябва да има прегледани всичките му разговори"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цел: всеки обект на прегледа трябва да има прегледани ", _interpolate(_named("count")), " от общия му брой разговори"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цел: всеки обект на прегледа трябва да има прегледани ", _interpolate(_named("count")), "% от общия му брой разговори"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Цел: всеки преглеждащ трябва да направи един преглед"]), _normalize(["Цел: всеки преглеждащ трябва да направи ", _interpolate(_named("count")), " прегледа"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Заместване: преглеждащите не могат да заменят разговори"]), _normalize(["Заместване: преглеждащите могат да заменят разговори"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценявания: на преглеждащите могат да се възлагат собствени разговори"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма зададени условия за дата"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма зададено име на възложена задача"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резюме"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " от ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двуседмично"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесечно"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никога"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежеседмично"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължителност на цикъла"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задаване като активен"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване като чернова"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е запазена като чернова"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е зададена като неактивна"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте като неактивен"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на възложена задача"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е създадена"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирайте като активен"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирайте като неактивен"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Предстоящите актуализации на възложената задача ще влязат в сила в следващия цикъл, започвайки на ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване на промените"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивни"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чакащи"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновяване на възложена задача"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е обновена"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът или ботът е завършил разговора."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът е приключил разговора любезно"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали разговорът е бил приключен"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приключване"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира граматическите, правописните и стиловите грешки във взаимодействията с консултанти и ботове."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира граматическите, правописните и стиловите грешки на консултанта"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира граматическите, правописните и стиловите грешки на консултанта"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правопис и граматика"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира разговора за начални обръщения на консултанта и бота."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за типични начални обръщения"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за типични начални обръщения"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начално обръщение"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът разбира и отчита чувствата на клиентите"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентифицира случаи, в които консултантът откликва на отношението на клиента или използва внимателен език като „признавам“ и „разпознавам“"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Емпатия"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът разбира запитването или притеснението на клиента"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира дали консултантът разбира запитването или притеснението на клиента"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбиране"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира колко лесно може да бъде разбран текст, като се има предвид сложността на думите и дължината на изречението"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира колко лесно може да бъде разбран текст, като се има предвид сложността на думите и дължината на изречението"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това съобщение съдържа 1 твърде дълго изречение, което го прави труден за четене"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Това съобщение съдържа ", _interpolate(_list(0)), " твърде дълги изречения, което го прави труден за четене"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четливост"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за предложено решение"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира целия разговор за предложено решение"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложено решение"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира тона на консултанта по време на разговора"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализира тона на консултанта по време на разговора"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не открихме никакви грешки в съобщенията от консултанта"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не можем да определим граматическите грешки"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е открито начално обръщение. Ако е необходимо, добавете нови чрез вашия администратор в „Настройки“."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът учтиво приключи разговора"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е открита приключваща фраза. Ако е необходимо, добавете нови чрез вашия администратор в „Настройки“"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът откликна на отношение на клиента или използваше внимателен език"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се установи дали консултантът откликва на отношението на клиента или използва внимателен език"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът използва дълги изречения и сложни думи, което го прави потенциално труден за разбиране от някои хора"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът използва сложни думи, което го прави потенциално труден за разбиране от някои хора"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът използва дълги изречения, което го прави потенциално труден за разбиране от някои хора"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът използва език, който е лесен за разбиране"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма достатъчно данни за оценка на тази категория"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът имаше трудности да разбере въпроса/заявката на клиента"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът разбра добре въпроса/заявката на клиента"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се установи дали консултантът е разбрал въпроса/заявката на клиента"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонът на консултанта по време на разговора"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонът на консултанта по време на разговора"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонът на консултанта по време на разговора"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонът на консултанта по време на разговора"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тонът на консултанта по време на разговора"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се определи тонът на консултанта"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът не беше приключен"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът беше приключен"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се установи дали разговорът е приключил"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът не прояви съпричастност към клиента и неговите проблеми"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът показа съпричастност към клиента и неговите проблеми"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се установи дали консултантът е показал съпричастност към клиента и неговите проблеми "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът не беше поздравен по време на разговора"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентът беше поздравен по време на разговора"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се открие типично начално обръщение"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултантът не предостави решение на клиента"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консултант предложи решение на клиента"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не може да се открие дали консултантът е предложил решение на клиента"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложни думи"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дълги изречения"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Граматика"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правописна грешка"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателно"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извиняващ се"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спокоен"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Весел"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любопитен"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Професионален"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съдействащ"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стил"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 приключване"]), _normalize([_interpolate(_named("n")), " приключвания"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функцията Автоматичен QA проверява само за одобрени приключвания. Ако тази категория е зададена като критична, неизползването на одобрените приключвания ще направи прегледът неуспешен."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени приключвания"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени приключвания"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 изключение"]), _normalize([_interpolate(_named("n")), " изключения"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думите и фразите, които функцията Автоматичен QA игнорира като правописни или граматически грешки."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правописни и граматически изключения"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключения от Автоматичен QA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 начално обръщение"]), _normalize([_interpolate(_named("n")), " начални обръщения"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функцията Автоматичен QA проверява само за одобрени начални обръщения. Ако тази категория е зададена като критична, неизползването на одобрените начални обръщения ще направи прегледът неуспешен."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени начални обръщения"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрени начални обръщения"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория за Автоматичен QA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете категория за Автоматичен QA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматично зададени първопричини:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акаунт → Настройки"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Някои категории използват MS Azure, предлагайки разширен набор от функционалности и поддържани езици. Прегледайте вашите ", _interpolate(_named("link")), " за повече информация или се свържете с администратор, за да го направите."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достъп до разширен списък от категории и поддържани езици чрез използване на MS Azure. Прегледайте вашите ", _interpolate(_named("link")), " или се свържете с администратор, за да го направите."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвайте Автоматичен QA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категориите за функцията Автоматичен QA се оценяват автоматично, като се използва фиксирана скала за оценяване. Можете да коригирате оценката ръчно по-късно. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете категория за Автоматичен QA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматично възложен резултат зависи от тежестта на грешките и техния брой. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричини"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На преглеждащите се показват само подходящи категории за оценка. Категориите на оценяването се показват въз основа на условията, които сте задали при създаването на категория."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чат"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["въвеждащо обучение"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефон"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изберете типа на категорията на оценяването за работно пространство „", _interpolate(_named("workspace")), "“."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащите трябва ръчно да изберат правилната карта за резултат, за да видят съответните категории за оценка за всеки разговор."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да промените типа категория на оценяването?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на категориите"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на типа на категорията на оценяването?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условна категория на оценяването"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статична категория на оценяването"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип категория на оценяването"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки на категорията на оценяването"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това действие не може да се отмени."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изтрие всички оценки"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друга възможност е да запазите статистиката си, без да изтривате данни, като архивирате категория."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Разбирам, че това ще ", _interpolate(_named("deleteAllRatings")), ", направени за тази категория."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. Тонът е идеален"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на категория само ако"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Винаги“ ще бъде игнорирано. Не може да се комбинира с други условия."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Винаги"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове за отдел по поддръжка"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете условие"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат за удовлетвореност (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип на източника"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал за разговори"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорията се нуждае от име"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете категорията към карта за резултати"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на категория"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на категория"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавена е категория на оценяването"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирана категория не се вижда в картата за резултат, но ви позволява да запазите статистиката си, без да изтривате никакви данни."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране на категория"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Архивиране на ", _interpolate(_list(0)), " категория"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирана категория на оценяването"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на категория"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете „изтриване на категория“, за да изтриете тази категория"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изтриване на категория"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изтриване на категория ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрита категория на оценяването"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублирана категория на оценяването"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавена е група от категории на оценяването"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрита е група от категории на оценяването"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променен ред на група от категории на оценяването"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновена група от категории на оценяването"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивирана категория на оценяването"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновена категория на оценяването"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когато даден консултант не се справи с критична категория, всички категории в този преглед автоматично не преминават."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критична категория на оценяването"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма категории в тази група"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте нова категория на оценяване"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на категорията"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтрийте всички категории и прегледайте данните в тази група"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриването на групата категории ще остави негрупирани всички категории, свързани с нея."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на групата на категорията ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изборът да изтриете категориите в групата ще доведе до загуба на всички данни за преглед, свързани с тези категории."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на група"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащите могат да изберат повече от една причина като първопричина"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащите могат да изберат повече от една причина като първопричина"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Множество причини"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напр. тон"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на нова група категории"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова група"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на „Други“"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на първопричина"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази първопричина вече съществува"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на първопричина"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете първопричини, за да обясните оценката"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скала за оценяване"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирани"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти с резултати"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти с резултати"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете карти за резултат към тази категория на оценяването"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивиране"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновяване на категория"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички оценки"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показано за"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателни оценки"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицателни и неутрални оценки"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да, обнови"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тежест"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категориите, които са по-важни за вас, могат да имат по-голяма тежест при изчисляването на резултата от казуса."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Тежест (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за съгласуването"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за съгласуването"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провеждането на редовни сесии за съгласуване позволява на преглеждащите да поддържат своите техники за оценяване в съответствие и да гарантират, че консултантите получават същото ниво на последователни и безпристрастни отзиви."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на плановете"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " е процес, който помага на екипа ви да бъде на едно мнение за това как трябва да се обработват и оценяват всички взаимодействия с клиенти."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Съгласуването е достъпно за нашите планове ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дадените по-рано прегледи не се показват, когато към сесията се добави разговор."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невидими"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишните прегледи се считат за прегледи на съгласуване. Само един преглед на разговор на преглеждащ ще бъде добавен към сесията. Прегледи за конкретни съобщения не се показват."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видими"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимост на предишни прегледи"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Съгласуването е процес, който помага на екипа ви да бъде на едно мнение за това как трябва да се обработват и оценяват всички взаимодействия с клиенти. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителите имат неограничен достъп до всички прегледи, направени по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Винаги виждайте всички прегледи"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потенциален клиент"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След като изпратят преглед, потребителите ще видят оценки, дадени от други преглеждащи по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички прегледи за съгласуване, след като изпратите преглед"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителите имат неограничен достъп до всички прегледи, направени по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Винаги виждайте всички прегледи"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мениджър"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След като изпратят преглед, потребителите ще видят оценки, дадени от други преглеждащи по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички прегледи за съгласуване, след като изпратите преглед"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["След като изпратят преглед, потребителите ще видят оценки, дадени от други преглеждащи по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всички прегледи за съгласуване, след като изпратите преглед"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителите ще виждат само собствените си прегледи, дадени по време на сесия за съгласуване."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте само собствени прегледи"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройките за съгласуване са обновени"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимост на прегледите на съгласуването"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте прага за показателите на контролния панел, като попълните пропуските според вътрешната система за показатели на вашия екип. Прегледайте прага в контролния панел."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени задачи"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласуване"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзки"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общи"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштагове"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членове"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праг"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прагът е обновен"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един момент..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Уверете се, че имате администраторски права и ", _interpolate(_named("retry")), ", или ", _interpolate(_named("contact")), " за допълнителна помощ."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["свържете се с нас"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда не успяхме да получим достъп до API на вашия отдел по поддръжка."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опитайте отново"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързваме вашия отдел по поддръжка. Ще бъдете пренасочени веднага."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " упълномощени"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Върнете се към вашия ", _interpolate(_list(0)), ", за да добавите нова връзка с отдел по поддръжка."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда, че сте пропуснали стъпка или две."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вече съществува организация със същия имейл домейн"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["страница за връзки"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Междувременно..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте ни малко време, докато проверим вашите разрешения."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Междувременно..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интегриране"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["създайте работно пространство"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["За да продължите да използвате Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почти готов за употреба"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открито работно пространство"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на акаунт"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да продължите да използвате Zendesk QA, вашият мениджър трябва да ви възложи работно пространство."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриването на вашия акаунт ще изтрие завинаги всички данни, които имаме за вас – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всички дадени оценки, съдържание на разговори, работни пространства и т.н."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекъсване на връзката"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекъсването на връзката с интеграцията ще деактивира всички уведомления на Zendesk QA чрез Slack"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекъсване на връзката със Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграцията със Slack е прекъсната от Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дефиниране на настройките за уведомления по подразбиране за всички нови потребители на акаунта."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнориране на настройките за всички потребители"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На път сте да замените настройките за уведомления за всички потребители на акаунта"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителите няма да могат да определят свои собствени настройки за уведомления."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройките за уведомление са обновени"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение, когато критична категория е неуспешна"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение, когато CSAT получи отговор"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тригери"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL адрес"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте демонстрационно работно пространство и данни"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте отново демонстрационни данни и запазете демонстрационното работно пространство."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на демонстрационно работно пространство и данни"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опресняване на данни за демонстрация"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опресняване на данни"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Управление на акаунта „", _interpolate(_named("accountName")), "“ в демо режим"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо режим"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставане на страницата"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напускане на страницата"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искате ли да напуснете тази страница и да загубите всички промени?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнихте възложената задача"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на възложена задача"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложената задача е изпълнена"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити възложени задачи"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момента няма задачи, които да прегледате."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коригирайте възложената задача или изчакайте началото на следващия цикъл."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да коригирате филтрите"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на възложена задача"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следете тази страница за актуализации"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма задачи за този цикъл"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разговори за показване"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е налично"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отбелязване на задачата като приключена"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледали сте този потребител."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма самооценявания в тази възложена задача"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете, като щракнете върху иконата за съгласуване в заглавката на изгледа на разговора."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени разговори"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краен срок"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на възложена задача"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на сесия за съгласуване"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашите възложени задачи"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Други възложени задачи"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма активни възложени задачи"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма възложени задачи"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички обекти на прегледа"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички обекти на прегледа освен мен"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени обекти на прегледа"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на обект на прегледа"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахнати"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички статуси"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи за изпълнение"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички преглеждащи"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички преглеждащи освен мен"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възложени на мен"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени преглеждащи"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на преглеждащ"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дни"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дни"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ден"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дни"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дни"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дни"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " часа"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " часа"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " час"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " часове"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " часа"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " часа"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минути"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минути"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минута"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минути"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минути"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минути"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители на работно пространство"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущ преглеждащ"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова цел за този цикъл"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нов преглеждащ"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете преглеждащ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане на разговори"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане на разговора"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговори, които да бъдат преместени"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущ цикъл"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на възложена задача"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени преглеждащи"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на преглеждащ"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане на разговорите към"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане на разговорите към друг потребител"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащи"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " дни"]), _normalize([_interpolate(_named("n")), " ден"]), _normalize([_interpolate(_named("n")), " дни"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " часа"]), _normalize([_interpolate(_named("n")), " час"]), _normalize([_interpolate(_named("n")), " часа"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на завършени цикли"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на завършени сесии"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата цел"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете причина, за да премахнете този разговор"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обяснете защо премахвате този разговор"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на разговора"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искате ли да запазите промените?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете причина, за да замените този разговор"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне на промените"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съхраняване"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазете промените"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е намерена замяна"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обяснете защо замествате този разговор"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още ли искате да премахнете този разговор от възложената задача? Това ще намали целта с 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заместване"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замяна на разговора"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на чернова"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на чернова"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане към мен"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане към друг потребител"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превъзлагане и преглед"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на разговора"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете преглед"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи по-малко"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте повече"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цел: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " прегледани"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени прегледи"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на завършени цикли"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на завършени сесии"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставащо време"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорът е заменен"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на списъка със задачи"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["След ", _interpolate(_named("time"))])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " секунда"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " секунди"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " д"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " д ", _interpolate(_named("hours")), " ч"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "д ", _interpolate(_named("hours")), "ч ", _interpolate(_named("minutes")), "мин"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " д"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дни"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "д ", _interpolate(_named("minutes")), "мин"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ч"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ч ", _interpolate(_named("minutes")), " мин"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "ч ", _interpolate(_named("minutes")), "м ", _interpolate(_named("seconds")), "с"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "ч ", _interpolate(_named("seconds")), "с"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " м"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "м ", _interpolate(_named("seconds")), "с"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), "мес."])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " сек"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), "г."])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Току-що"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липсват ви разрешения. Свържете се с вашия администратор за съдействие."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страницата, която търсите, не съществува."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна неочаквана грешка. Свържете се с поддръжката за помощ."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достъпът е отказан. Няма право да извършва това действие."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсената страница или не съществува, или нямате достъп до нея."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Движите се твърде бързо и имаме проблеми с наваксването."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промените са запазени"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени са незапазени промени"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " приложени"]), _normalize([_interpolate(_named("n")), " приложен"]), _normalize([_interpolate(_named("n")), " приложени"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързани"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създадено на"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създадено от"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критичен"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["е"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Бивш член"]), _normalize(["бивш член"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тук"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " избрани"]), _normalize([_interpolate(_named("n")), " избран"]), _normalize([_interpolate(_named("n")), " избрани"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последно обновяване"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["…и + още 1"]), _normalize(["…и + още ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["+ още 1"]), _normalize(["+ още ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ново"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не е"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахнати"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " отговора"]), _normalize([_interpolate(_named("n")), " отговор"]), _normalize([_interpolate(_named("n")), " отговора"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["днес"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["потребител"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Петък"]), _normalize(["петъци"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Понеделник"]), _normalize(["понеделници"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Събота"]), _normalize(["съботи"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Неделя"]), _normalize(["недели"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Четвъртък"]), _normalize(["четвъртъци"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Вторник"]), _normalize(["вторници"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Сряда"]), _normalize(["среди"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Администратор"]), _normalize(["администратор"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Мениджър на акаунти"]), _normalize(["мениджър на акаунти"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Потребител"]), _normalize(["потребител"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичен QA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирано"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесията ви е изтекла."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизания"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле не може да бъде празно."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени елементи."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма съответстващи потребители."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Притежател"]), _normalize(["притежател"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преди да започнем, прочетете и приемете нашите условия и ще сме готови."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпратено"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледите като цяло са приключени"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновено"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Консултант"]), _normalize(["консултант"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Потенциален клиент"]), _normalize(["потенциален клиент"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Мениджър"]), _normalize(["мениджър"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Преглеждащ"]), _normalize(["преглеждащ"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимен потребител"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпълнител"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмяна"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затваряне"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързване"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължаване"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете, за да копирате"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копиране на връзката"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опасна зона"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказване"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание (опция)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изисква се потвърждение"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивиране"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърляне на незапазените промени?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активиране"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортиране"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтър"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Честота"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цел"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ясно!"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдел по поддръжка"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покана"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IQS"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Езици"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никога"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напред"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друго"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степен на преминаване"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лични настройки"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишна"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профил"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По-кратко"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно изпращане"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възстановяване"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обект на прегледа"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени/видени прегледи"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи, направени от член на работно пространство"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роля"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване на промените"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Двоична скала"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-степенна скала"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-степенна скала"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-степенна скала"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта за резултат"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете честота"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи по-малко"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покажи повече"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропусни"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осветяване"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стъпка"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзив от анкета"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Времеви интервал"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени са незапазени промени"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обнови"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тежест"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Поканени сте от ", _interpolate(_list(0)), " да се присъедини към Zendesk QA."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Потребителите се свързват автоматично от Администраторския център. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на потребителите"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за управлението на потребителите"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Някои потребители се предоставят автоматично и се синхронизират от вашия доставчик на идентичност. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разрешение за акаунт на „", _interpolate(_list(0)), "“ е променено"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете за потребителските разрешения"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не ме питай отново"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Всички промени, направени на страницата на потребителя, се отразяват на работните пространства. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това означава, че достъпът на потребителя до определени части на Zendesk QA може да се промени"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на разрешението на ниво акаунт"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " потребител е премахнат"]), _normalize([_interpolate(_named("n")), " потребители са премахнати"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Когато премахнете потребител, той ще загуби достъп до вашия акаунт. Неговите прегледи, коментари и оценки ще останат в Zendesk QA. ", _interpolate(_named("linebreak")), "Премахването на потребител няма да повлияе на броя на лицензите във вашия абонамент."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Този потребител ще бъде премахнат от всички работни пространства, които управлявате. ", _interpolate(_named("linebreak")), " Неговите прегледи, коментари и оценки ще останат непроменени."]), _normalize(["Той ще бъде премахнат от всички работни пространства, които управлявате. ", _interpolate(_named("linebreak")), " Всички негови прегледи, коментари и оценки ще останат непроменени."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Премахване на ", _interpolate(_named("name"))]), _normalize(["Премахване на ", _interpolate(_named("name")), " потребители"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Може да вижда всичко и да управлява всички настройки и фактуриране"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Може да вижда всичко и да управлява лични настройки и такива за работно пространство, с изключение на таксуването"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения за акаунт"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенията, специфични за работното пространство, позволяват на потребителя да вижда (и) редактира данни в работните пространства, в които се намира."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече относно QA на бота"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашите ботове се показват тук, когато разговорите, в които са участвали, се синхронизират"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма синхронизирани ботове"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирането на бот като потребител ще му предостави достъп за влизане. Неговите предишни данни за бот ще останат достъпни в контролните панели."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са намерени такива ботове"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да търсите отново, като използвате друга ключова дума или правопис."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на ботове"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по име на бот"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране като потребител"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само администратори и мениджъри на акаунти могат да маркират ботове като потребители"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на бота"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последен чат"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръчно маркиран"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подлежащи на преглед"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботът е премахнат от автоматичен анализ и всички възложени задачи. Новите данни няма да се показват в контролните панели, което гарантира точно отчитане и по-лесно управление на ботовете."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеративен бот"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестен"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот за работен процес"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към група(и)"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички групи"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма група"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване от група(и)"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избор на група"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение за акаунт"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне към работно пространство(a)"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име и фамилия"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последно активни"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роля на ниво акаунт"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На всеки потребител се възлага разрешение на ниво акаунт и/или работно пространство. Администраторските разрешения вече са на ниво акаунт, докато другите разрешения са на ниво работно пространство."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване от работно пространство(a)"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източник"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение за работно пространство"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешенията се управляват в групата"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на подробни данни"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркиране като бот"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркирането на потребител като бот ще премахне достъпа му за вход. Неговите предишни данни за потребителя ще останат достъпни в контролните панели."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на бота от автоматичния анализ и всички възложени задачи. Новите данни няма да се показват в контролните панели, което гарантира точно отчитане и по-лесно управление на ботовете."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изключване на бота от прегледите"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителят е маркиран като бот"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само тип разрешение „Потребител“ може да бъде маркирано като бот"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребителите с няколко акаунта не могат да бъдат маркирани като бот"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по потребител"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ботове"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители, ботове и работни пространства"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАБОТНИ ПРОСТРАНСТВА"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители и работни пространства"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма разрешения"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързани"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавен ръчно"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чакащи"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чакаща покана"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM потребители"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо е действие"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["…и още един"]), _normalize(["…и още ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променете разрешението на"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Премахнете и поканете отново, за да промените ролята на ", _interpolate(_named("n")), " чакащ поканен потребител."]), _normalize(["Премахнете и поканете повторно, за да промените ролята на ", _interpolate(_named("n")), " чакащи поканени потребители"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Редактиране на подробности за ", _interpolate(_named("name"))]), _normalize(["Редактиране на подробности за ", _interpolate(_named("n")), " избрани потребители"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " потребител е обновен."]), _normalize([_interpolate(_named("n")), " потребители са обновени."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Тази група съдържа 1 потребител от друго работно пространство."]), _normalize(["Тази група съдържа ", _interpolate(_named("n")), " потребители от друго работно пространство."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активиране"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "“ групата е архивирана"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирана група"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на група"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групата „", _interpolate(_named("name")), "“ е създадена"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група без заглавие"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончателно изтриване на тази група?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на групата"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на групата"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на групата"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ръководител на групата"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роля на групата"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "“ групата е скрита"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази група няма да се вижда никъде другаде в Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявайте само разрешения за работно пространство"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групата не се вижда в Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потенциален клиент"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Член"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група без заглавие"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друга група вече има това име"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирана група вече има това име"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата роля позволява само управление на членове на тази група. Свържете се с потребител с по-високи права за допълнителни промени."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване като ръководител на групата"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групата „", _interpolate(_named("name")), "“ е възстановена"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групата „", _interpolate(_named("name")), "“ е запазена"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте като ръководител на групата"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групата „", _interpolate(_named("name")), "“ е разархивирана"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незапазени промени"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете вашите съществуващи потребители към тази група"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по име или имейл"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на членове"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Групата „", _interpolate(_named("name")), "“ е видима"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Всеки член е ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече за групите"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Някои атрибути на групи се предоставят автоматично и се синхронизират от вашия доставчик на идентичност."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече за групите"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на група"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организирайте лесно членовете на екипа си и наподобете структурата на вашата организация."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете поне 1 член"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете име на групата"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група с това име вече съществува"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членове на групата"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на група"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организирайте членовете на екипа си и наподобете структурата на вашата организация."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Членове"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Няма групи"]), _normalize(["1 група"]), _normalize([_interpolate(_named("count")), " групи"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте нова група или вместо това активирайте архивирана."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма налични активни групи"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма архивирани групи"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити такива групи"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте да търсите отново, като използвате друга ключова дума или правопис."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма добавени групи"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["в тези"]), _normalize(["в"]), _normalize(["в"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по име на група"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирани"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Възможно повторно изпращане ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " оставащи лиценза"]), _normalize([_interpolate(_named("n")), " оставащ лиценз"]), _normalize([_interpolate(_named("n")), " оставащи лиценза"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Броят на вашите лицензи в момента е ограничен, тъй като абонаментът ви е на пауза. Активирайте го отново, за да добавите още потребители."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнахте лимита от лицензи за акаунта си. Можете да замените потребители, като деактивирате активен потребител или добавите още лицензи."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте подробности за плащането"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " избран"]), _normalize([_interpolate(_named("n")), " избрани"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " избран потребител"]), _normalize([_interpolate(_named("n")), " избрани потребители"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " реда"]), _normalize([_interpolate(_named("n")), " ред"]), _normalize([_interpolate(_named("n")), " редове"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групово търсене на потребители по техните имена или имейл адреси."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставете данните тук, разделени със запетаи или нови редове"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групово търсене на потребители"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Редактиране на разрешения за работно пространство за ", _interpolate(_named("name"))]), _normalize(["Редактиране на разрешения за работно пространство за ", _interpolate(_named("n")), " избрани членове"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения за редактиране"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете разрешение за работно пространство"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Премахване на ", _interpolate(_named("name")), " от работно пространство"]), _normalize(["Премахване на ", _interpolate(_named("name")), " члена от работно пространство"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " член е премахнат"]), _normalize([_interpolate(_named("n")), " члена са премахнати"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Премахване на член"]), _normalize(["Премахване на членове"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Този потребител ще бъде премахнат от работно пространство."]), _normalize(["Тези потребители ще бъдат премахнати от работно пространство."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разрешението за работно пространство на „", _interpolate(_list(0)), "“ е променено."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на членове"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте разговори и свързани прегледи"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вижте всичко в работното пространство, но управлявайте само тестове, възложени задачи, оспорвания и сесии за съгласуване"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения за работно пространство"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виждайте и управлявайте всичко в работното пространство"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виждайте всичко в работно пространство, с изключение на настройките на работно пространство"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["свързване на потребители"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители от работните пространства, които управлявате, са добавени към това работно пространство."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помолете администратор да го направи."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете нови потребители самите вие или помолете администратор да го направи."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " или ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покана"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма потребители за показване"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " добавен потребител"]), _normalize([_interpolate(_named("n")), " добавени потребители"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавяне на членове"]), _normalize(["Добавяне на член"]), _normalize(["Добавяне на ", _interpolate(_named("n")), " члена"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете разрешение за работно пространство"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребители към работно пространство"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Добавяне на членове към ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставете имейлите тук, разделени с нови редове или запетаи"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Съвпадащи ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " имейли"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" с вече свързани потребители:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Няма съответстващи ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " имейли"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверете правописа или ги свържете по-късно индивидуално"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" с потребители на отдел по поддръжка:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " свързан потребител"]), _normalize([_interpolate(_named("n")), " свързани потребители"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " поканен потребител"]), _normalize([_interpolate(_named("n")), " поканени потребители"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групово свързване"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групова покана"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например:\n\nkate", "@", "example.com, john", "@", "example.com\n\nkate", "@", "example.com\nkate", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се от вашия(те) отдел(и) по поддръжка, за да прегледате взаимодействието им"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Свържете потребител"]), _normalize(["Свържете потребители"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейли"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете индивидуално"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако искате вашите свързани потребители да имат достъп до Zendesk QA, можете да ги поканите тук."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете свързани потребители"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Поканете потребител"]), _normalize(["Поканете потребители"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропуснете поканата"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете потребители, които да се свържат от вашия(те) отдел(и) по поддръжка, за да прегледате взаимодействието им"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлявайте абонамента си"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете потребителите на отдел по поддръжка"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте на вашето работно пространство уникално име"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако е разрешено, всички съществуващи връзки с акаунта се добавят автоматично към новото работно пространство."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете всички връзки към това работно пространство"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на работно пространство"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на работно пространство"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на работно пространство"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На потребителите, добавени директно към акаунта (без възлагане на работно пространство), се възлага роля на ниво акаунт. Можете да ги добавите към работните пространства по-късно."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На поканените потребители автоматично се възлага роля на потребител на ниво акаунт. Можете да ги надградите до роля на администратор по-късно."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички тези функционират"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставете имейлите тук, разделени с нови редове или запетаи."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " потребител, поканен по имейл"]), _normalize([_interpolate(_named("n")), " потребители, поканени по имейл"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На потребителите, добавени към работно пространство, автоматично се възлага роля на потребител на ниво акаунт. Можете да ги надградите до роля на администратор по-късно."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на други"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете потребители само към акаунта, без да ги добавяте към работно пространство"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На поканените потребители се възлага само разрешение за акаунт и не се добавят към никакви работни пространства."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете потребители директно в работно пространство и задайте разрешения за работно пространство"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете потребители към работно пространство"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групова покана"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете индивидуално"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете само към акаунта"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете в работно пространство"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Поканете потребители"]), _normalize(["Поканете потребител"]), _normalize(["Поканете ", _interpolate(_named("n")), " потребители"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групови покани на потребители чрез имейл"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Изтриване на потребител"]), _normalize(["Изтриване на потребители"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на избора"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поканете потребители чрез имейл"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма работни пространства"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете разрешение"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно изпращане на покана"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свързан потребител"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител с чакаща имейл покана"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM потребител"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете работно пространство"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИ консултанти"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администраторски център"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality assurance"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на персонала"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Завършени ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Завършен ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Завършени ", _interpolate(_named("n")), "/", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цикъл ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглеждащ"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на възложена задача"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спестете време и настройте автоматични възложени задачи за преглед, вместо ръчно да намирате и разпределяте разговори за преглед."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Срок ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесиите могат да се добавят в раздела „Съгласуване“ в страничното меню на изгледа на разговора."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма добавени сесии"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["базова линия"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма сесии"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговорите могат да се добавят към сесия, като щракнете върху иконата за съгласуване в заглавката на изгледа на разговора."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма добавени разговори"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Няма направени прегледи. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете преглед на съгласуването"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първопричина"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преминете към"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуспешно зареждане на набор от команди."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нищо не е намерено."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открито по идентификатор"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареждане на набор от команди…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["страници, филтри, разговори, настройки…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личен филтър"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичен филтър"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["От ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От вашия API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Показване на ", _interpolate(_named("rangeStart")), " до ", _interpolate(_named("rangeEnd")), " от ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Показване на ", _interpolate(_named("rangeStart")), " до ", _interpolate(_named("rangeEnd")), " от ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скриване на страничния панел"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване на страничния панел"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на страничния панел"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорванията са приети"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорванията са отхвърлени"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени оспорвания"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорванията са приети частично"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени прегледи"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видени прегледи"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["приети"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отворени"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["частично приети"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " от общо"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отхвърлени"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приети"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично приети"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърлени"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["преглед"]), _normalize(["прегледи"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой на техните прегледи, които са били видени от обекти на прегледа"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой техни прегледи, които са били видени"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорени преглеждащи"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорващи"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спорове по категории"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорвания във времето"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно време за разрешаване"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно време за разрешаване (ч)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средно време за разрешаване (мин)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решени"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо оспорвания"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете хаштаг"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приети"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език за отговор"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени езици"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички езици"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозни фактори"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени фактори"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички фактори"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Думи от „Облак от думи“"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени думи"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички думи"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споменавания"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-нови"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Най-стари"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отворени"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично приети"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " реагира с ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отхвърлени"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решени"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прегледи"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете тон на кожата"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сесии"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити сесии"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити категории"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментари"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер на коментара"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени размери"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички размери"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени филтри"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Групи"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени групи"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички групи"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштагове"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не са намерени хаштагове"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички хаштагове"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити стойности"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички стойности"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени специални полета"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тагове на отдел по поддръжка"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити тагове на отдела по поддръжка"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички тагове на отдела по поддръжка"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултатите се зареждат…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типове въпроси"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени типове въпроси"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на реакция"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карти с резултати"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити карти с резултати"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички карта за резултат"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултати"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени резултати"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички резултати"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Източници"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени източници"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички източници"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина за отзив"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени причини"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички причини"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкети"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма открити анкети"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички анкети"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канали"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени канали"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички канали"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребители"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени потребители"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички потребители"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени работни пространства"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички работни пространства"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратно към тестове"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестът е изтрит или все още не е публикуван. Проверете отново връзката или се върнете към списъка с тестове."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този тест не е споделен с работно пространство, към което принадлежите. Помолете администратор да ви добави към работно пространство, до което този тест има достъп."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестът не е наличен"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставен е достъп за тестове до работно пространство(а), към които не принадлежите. Тестът не може да бъде запазен и публикуван."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помолете администратор да запази и публикува теста."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трябва да отбележите поне един от отговорите като верен."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле не може да бъде празно."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момента виждате предварителен преглед на теста."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете кои работни пространства имат достъп до теста. Потребителите, принадлежащи към множество работни пространства, могат да отговорят на теста само веднъж. Избирането на „Всички работни пространства“ няма да включва новосъздадените работни пространства, след като тестът бъде публикуван."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достъп до тестове"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излезте от режима на преглед"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортиране"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварителен преглед"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпроси"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направете теста"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване на тест"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки на теста"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване като чернова"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте нов тест"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивиране"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["опции"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на опция"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделете го"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда добре, нали?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият отговор е изпратен"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият тест е запазен"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият тест е публикуван"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият тест е премахнат"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на въпрос"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране на теста"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиран тест и неговите резултати вече няма да се показват в списъка с тестове. Все още можете да получите достъп до резултатите от архивиран тест и да го разархивирате, когато е необходимо."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивиране на теста"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веднъж публикуван, тестът не може да бъде променян или редактиран. Всички публикувани тестове ще бъдат достъпни за всеки потребител на вашия акаунт."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуване на тест?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигурни ли сте, че искате да изтриете този тест? Това действие не може да се отмени."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на теста?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веднъж изпратени, вашите отговори не могат да бъдат променяни."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване на теста?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивиране на теста"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивирането на теста го прави достъпен в общия преглед на тестове. Това ще позволи на предишни участници да имат достъп до своите резултати, а на новите да вземат участие в теста."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивиране на теста"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиран въпрос"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание (опция)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преместване на въпроса надолу"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преместване на въпроса нагоре"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпрос"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В предварителния преглед се показват само въпроси с отговори."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Премахване на въпроса"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подаване на теста"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест без заглавие"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заглавие на теста"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще изтрие окончателно теста и неговите резултати."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Като алтернатива все още можете да получите достъп до резултатите, като архивирате теста."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговорени"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на теста"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това ще изтрие окончателно теста и неговите резултати."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изтриване на теста ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублиране"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестовете помагат за повишаване на увереността и уменията на екипа, предоставят последователни преживявания и въвеждат в работата нови членове на екипа."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да търсите тестове по името на теста или името на автора."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма създадени тестове"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортиране на списък с тестове"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден резултат"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на завършване"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест/автор"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест/Автор/Работни пространства"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуван"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работни пространства"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по тест, автор"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирани"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чернова"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички тестове"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестът е архивиран."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестът е разархивиран."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този тест не получи отговори от потребители в избраното работно пространство(а)."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Този тест не получи отговори, преди да бъде архивиран."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма отговори"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тук ще видите разбивка на отговорите на теста.\nВсе още никой не е попълнил вашия тест – споделянето му със сигурност ще помогне."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още няма отговори"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отговори:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден резултат:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ преглед"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – Копие"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзката е копирана"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма намерени резултати"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Тестове на ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можете да търсите в ранглистата за името на участника."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експортиране на ранглиста"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тест"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резултат"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене по участник"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pанглиста"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестове"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превключване на ранглистата"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това означава, че няма да можете да филтрирате за този хаштаг в контролния панел и той вече няма да довършва автоматично коментари. Това действие е необратимо."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштаг, хаштаг, хаштаг, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на хаштагове"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на хаштагове"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаване на хаштагове"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволете на членовете да създават нови хаштагове"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаштаг"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Използвани"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на хаштаг"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Изтриване на хаштаг ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаването на хаштаг е заключено"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създаването на хаштаг е отключено"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Хаштаг „", _interpolate(_list(0)), "“ е изтрит"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Хаштаг „", _interpolate(_list(0)), "“ е преименуван на „", _interpolate(_list(1)), "“"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавен е 1 хаштаг"]), _normalize(["Добавени са ", _interpolate(_named("count")), " хаштага"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ново име"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма създадени хаштагове"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преименуване"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Преименуване на хаштаг „", _interpolate(_named("tagRenamed")), "“"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделете хаштаговете със запетаи или нови редове"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потърсете хаштаг"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Няма проблем. Можете да го направите ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["точно тук"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка, свързана с работно пространство"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикачете връзка"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикачете връзка към това работно пространство"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се с вашия администратор, за да направите промени тук"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свържете се със Zendesk QA, за да направите промени тук"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма връзки в рамките на акаунта"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изглежда, че все още не сте се интегрирали с платформа за обслужване на клиенти."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриването на връзка ще премахне разговорите от работно пространство ", _interpolate(_named("workspaceName")), " и изтриване на всички данни, свързани с тях, включително прегледи и оспорвания."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтриване на връзката"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изтриване на връзката ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете връзка"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В рамките на избрания период от време някои активни категории за функцията Автоматичен QA не присъстваха във вашата карта за резултат. Разговори преди последната ви промяна на ", _interpolate(_named("date")), " е възможно да не са анализирани за тези категории."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това е мястото, където ще се показват всички отзиви, които получавате. Още няма нищо за показване."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма получени прегледи."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показване само на активност с коментарите"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това е мястото, където ще се появи целия отзив, даден от вас. Направете първите стъпки в изгледа „Разговори“."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма направени прегледи."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направени прегледи"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получени прегледи"])}
  }
}