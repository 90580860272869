import dayjs from 'dayjs'

import i18n from '@/i18n'
import { FilterTimeStep, type FilterTimeParams } from '../types'
import { getDatesFromPeriod } from './period-options'

/**
 * List of possible time steps
 */
const timeStepOptions = {
  day: {
    label: i18n.t('dashboard.time_steps.days'),
    value: FilterTimeStep.Day,
  },
  week: {
    label: i18n.t('dashboard.time_steps.weeks'),
    value: FilterTimeStep.Week,
  },
  month: {
    label: i18n.t('dashboard.time_steps.months'),
    value: FilterTimeStep.Month,
  },
  quarter: {
    label: i18n.t('dashboard.time_steps.quarters'),
    value: FilterTimeStep.Quarter,
  },
  year: {
    label: i18n.t('dashboard.time_steps.years'),
    value: FilterTimeStep.Year,
  },
}

/**
 * Get matching time steps for given time period
 */
export const getTimeStepsForPeriod = (params: FilterTimeParams) => {
  const { fromDate, toDate } = getDatesFromPeriod(params)
  const differenceInDays = Math.abs(dayjs(fromDate).diff(dayjs(toDate), 'day'))

  if (differenceInDays <= 7) return [timeStepOptions.day]
  else if (differenceInDays <= 31) return [timeStepOptions.day, timeStepOptions.week]
  else if (differenceInDays <= 93) return [timeStepOptions.week, timeStepOptions.month]
  else return [timeStepOptions.month, timeStepOptions.quarter]
}
