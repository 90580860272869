import { UiPillColor } from '@klausapp/ui-kit/types'

import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { getColorByThreshold } from '@/utils/colors'

import { FilterTimePeriod } from '../types'

const changeLabels = {
  [FilterTimePeriod.ThisWeek]: i18n.t('dashboard.card_labels.period_change.week'),
  [FilterTimePeriod.Week]: i18n.t('dashboard.card_labels.period_change.week'),
  [FilterTimePeriod.LastWeek]: i18n.t('dashboard.card_labels.period_change.week'),
  [FilterTimePeriod.ThisMonth]: i18n.t('dashboard.card_labels.period_change.month'),
  [FilterTimePeriod.Month]: i18n.t('dashboard.card_labels.period_change.month'),
  [FilterTimePeriod.LastMonth]: i18n.t('dashboard.card_labels.period_change.month'),
  custom: i18n.t('dashboard.card_labels.period_change.custom'),
}

export const getChangeLabel = (timePeriod?: FilterTimePeriod) => changeLabels[timePeriod || 'custom']

export const getChangeColor = (value: number | null, type = 'delta') => {
  if (value === null || (type === 'delta' && value === 0)) return UiPillColor.Default
  if (type === 'scale') {
    return getColorByThreshold(value, session.workspace.settings.threshold || 0).startsWith('red')
      ? UiPillColor.Red
      : UiPillColor.Green
  }

  return value > 0 ? UiPillColor.Green : UiPillColor.Red
}

export const formatPercentage = (value: number | null) =>
  value === null ? i18n.t('dashboard.percentage.value_na') : `${value || 0}%`
