import { ref, computed } from 'vue'
import { PinReference_Type } from '@zendesk/zqa-services/pins'

import analytics from '@/utils/analytics'

import type { Session, CoachingPin } from '../types'
import { removePin } from '../api'

interface Props {
  session: Session
}

export const pinsMap = ref<Record<string, CoachingPin[]>>({})

type Emit = ((event: 'update', session: Partial<Session>) => void) & ((event: 'delete', id: string) => void)

export default function (props: Props, { emit }: { emit: Emit }) {
  pinsMap.value[props.session.id] = []
  const pins = computed(() => pinsMap.value[props.session.id])

  async function pinRemoveHandler(pin: CoachingPin) {
    const res = await removePin(props.session.id, pin.id)
    emit('update', res.session)
    removePinFromSession(props.session.id, pin.id)
    analytics.coachingRemovePin()
  }

  function isManualPin(pin: CoachingPin) {
    return pin.reference.type === PinReference_Type.MANUAL
  }

  props.session.pins.forEach((pin) => addPinToSession(props.session.id, pin))

  return { pins, pinRemoveHandler, isManualPin }
}

export function addPinToSession(sessionId: string, pin: CoachingPin) {
  if (!(sessionId in pinsMap.value)) pinsMap.value[sessionId] = []
  pinsMap.value[sessionId] = [...pinsMap.value[sessionId], pin]
}

export function removePinFromSession(sessionId: string, pinId: string) {
  pinsMap.value[sessionId] = pinsMap.value[sessionId].filter((p) => p.id !== pinId)
}

export function getLinkedSessions(pinId: string) {
  return Object.keys(pinsMap.value).filter((sessionId) =>
    pinsMap.value[sessionId].find((pin: CoachingPin) => pin.id === pinId),
  )
}
