<template>
  <div class="loader">
    <div class="spinner"></div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  height: 4rem;
  position: relative;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    margin: -20px 0 0 -20px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--spinner-color, theme('colors.active.primary'));
    border-bottom-color: var(--spinner-color, theme('colors.active.primary'));
    animation: spinner 0.8s ease infinite;
  }

  &.small {
    height: 16px;

    .spinner {
      height: 16px;
      width: 16px;
      margin: -8px 0 0 -8px;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(180deg);
  }
}
</style>
