import { api } from '@/api'
import type { DisputesOverTimeResponse, DisputesByCategoryRow, DisputersResponse, DisputesFilterParams } from '../types'
import { getDatesFromPeriod } from '../../utils/period-options'
import { FilterTimeStep } from '../../types'

/* TODO: Remove 'intervals' object once backend accepts `interval` param as a string [1] */
const intervals = {
  [FilterTimeStep.Day]: '1',
  [FilterTimeStep.Week]: '2',
  [FilterTimeStep.Month]: '3',
  [FilterTimeStep.Quarter]: '4',
  [FilterTimeStep.Year]: '5',
}

const getRequestHeaders = (workspaceIds: number[]) => ({ 'X-Klaus-Workspace': workspaceIds.toString() })

const getQueryFromParams = (params: DisputesFilterParams) => {
  const { timePeriod, fromDate, toDate } = params
  const dates = getDatesFromPeriod({ timePeriod, fromDate, toDate })
  const query = new URLSearchParams()

  if (dates.fromDate) query.append('fromDate', dates.fromDate)
  if (dates.toDate) query.append('toDate', dates.toDate)
  if (params.sort !== undefined) query.append('sort', params.sort)
  if (params.sortAsc !== undefined) query.append('sortAsc', params.sortAsc.toString())
  if (params.pageSize) query.append('pageSize', params.pageSize.toString())
  if (params.interval) query.append('interval', params.interval)

  params.commentTags?.forEach((tag) => query.append('commentTags', tag.toString()))
  params.userIds?.forEach((userId) => query.append('usersIds', userId.toString()))
  params.userGroups?.forEach((userGroupId) => query.append('userGroups', userGroupId.toString()))

  return query
}

export const getDisputesOverTime = (params: DisputesFilterParams) => {
  /* TODO: Remove 'intervals' object once backend accepts `interval` param as a string [2] */
  const query = getQueryFromParams({ ...params, interval: intervals[params.step || FilterTimeStep.Month] })

  return api
    .get(`disputes/dashboard/disputes-over-time?${query}`, {
      headers: getRequestHeaders(params.workspaceIds),
    })
    .json<DisputesOverTimeResponse>()
}

export const getDisputesByCategory = (params: DisputesFilterParams) => {
  const query = getQueryFromParams({ ...params, page: 1, pageSize: 5 })

  return api
    .get(`disputes/dashboard/disputes-by-category?${query}`, {
      headers: getRequestHeaders(params.workspaceIds),
    })
    .json<{ data: DisputesByCategoryRow[]; total: number }>()
}

export const getDisputers = (params: DisputesFilterParams) => {
  const query = getQueryFromParams({ ...params, sort: params.sort || 'data[3]' }) // by default sort by the 'Disputes' column (data[3])
  return api
    .get(`disputes/dashboard/disputers?${query}`, {
      headers: getRequestHeaders(params.workspaceIds),
    })
    .json<DisputersResponse>()
}

export const getDisputedReviewers = (params: DisputesFilterParams) => {
  const query = getQueryFromParams({ ...params, sort: params.sort || 'data[3]' }) // by default sort by the 'Disputes' column (data[3])
  return api
    .get(`disputes/dashboard/reviewers/disputed?${query}`, {
      headers: getRequestHeaders(params.workspaceIds),
    })
    .json<DisputersResponse>()
}
