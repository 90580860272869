import type { Connection, ConnectionsResponse } from '@zendesk/zqa-services/connections'
import { session } from '@/composables/useSession'
import { oldApi, api } from '@/api'
import { applyDefaults, type defaultSettings } from '@/utils/constants/default-workspace-settings'
import type { Token } from './types'

export const getWorkspaceSettings = (workspaceId: number) =>
  oldApi
    .get(`payments/${session.account.id}/teams/${workspaceId}/settings`)
    .json<any>()
    .then(({ data }) => applyDefaults(data))

export const updateWorkspaceSettings = async (workspaceId: number, json: Partial<typeof defaultSettings>) => {
  const result = await oldApi.put(`payments/${session.account.id}/teams/${workspaceId}/settings`, { json }).json<any>()
  const settings = applyDefaults(result.data)
  if (workspaceId === session.workspace.id) session.workspace.settings = settings
  return settings
}

export const deleteWorkspace = (workspaceId: number) => api.delete(`workspaces/${workspaceId}`)

interface UpdateWorkspacePayload {
  name: string
  color: string
}

export const updateWorkspace = async (workspaceId: number, payload: UpdateWorkspacePayload) => {
  await oldApi.put(`payments/${session.account.id}/teams/${workspaceId}`, {
    json: { teamName: payload.name, pictureUrl: payload.color },
  })

  return { ...payload, id: workspaceId }
}

export const getHashtags = async (workspaceId: number) => {
  const { tags } = await api
    .get('hashtags', { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json<{ tags: { value: string; count: number }[] }>()

  return tags
}

export const insertHashtags = (workspaceId: number, tags: string[]) =>
  api.post('hashtags', { headers: { 'X-Klaus-Workspace': workspaceId.toString() }, json: { tags } }).json()

export const deleteHashtag = (workspaceId: number, tag: string) =>
  api
    .delete('hashtags', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      searchParams: { tag },
    })
    .json()

export const renameHashtag = (workspaceId: number, tag: string, newValue: string) =>
  api
    .put('hashtags', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      json: { tag, newValue },
    })
    .json()

export const getAccountConnections = async () => {
  const { connections } = await api.get('connections').json<ConnectionsResponse>()
  return connections
}

export const getWorkspaceConnections = async (workspaceId?: number) => {
  if (!workspaceId) workspaceId = session.workspace.id

  const { connections } = await api
    .get('connections', {
      headers: { 'x-klaus-workspace': String(workspaceId) },
    })
    .json<ConnectionsResponse>()
  return connections
}

export const getUniqueConnectionSources = () =>
  api
    .get(`connections/source-types/unique`)
    .json<{ connections: string[] }>()
    .then((r) => r.connections)

export const getTeamTokens = async (workspaceId?: number) => {
  if (!workspaceId) workspaceId = session.workspace.id

  const { data } = await oldApi
    .get(`payments/${session.account.id}/teams/${workspaceId}/tokens`)
    .json<{ data: Token[] }>()
  return data
}

export const addWorkspaceConnection = (workspaceId: number, connectionId: Connection['id']) =>
  oldApi
    .post(`payments/${session.account.id}/teams/${workspaceId}/tokens`, { json: { paymentPlanTokenId: connectionId } })
    .json()

export const removeWorkspaceConnection = (workspaceId: number, connectionId: Connection['id']) =>
  oldApi.delete(`payments/${session.account.id}/teams/${workspaceId}/tokens/${connectionId}`)
