import qs from 'query-string'
import {
  type AssignmentV2_GetUserAssignmentConversationsResponse,
  type AssignmentV2_UserAssignment,
  type AssignmentV2_GetAssignmentInfoResponse,
  type AssignmentV2_ReassignConversationsRequest,
  type AssignmentV2_ReassignConversationRequest,
  AssignmentV2_GetUserAssignmentConversationsResponse_ConversationStatus as ConversationStatus,
} from '@zendesk/zqa-services/assignments_v2'
import { api } from '@/api'
import { session } from '@/composables/useSession'
import type { TasksListParams } from '@/modules/tasks/types'

export const getAssignments = (): Promise<{ assignments: AssignmentV2_UserAssignment[] }> =>
  api
    .get('assignments/v2', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const reassignConversations = ({
  assignmentId,
  fromReviewerId,
  toReviewerIds,
}: AssignmentV2_ReassignConversationsRequest): Promise<void> =>
  api
    .put(`assignments/v2/${assignmentId}/reassign-conversations`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { fromReviewerId, toReviewerIds },
    })
    .json()

export const reassignConversation = ({
  assignmentId,
  toReviewerId,
  conversationId,
  connectionId,
}: AssignmentV2_ReassignConversationRequest): Promise<object> =>
  api
    .put(`assignments/v2/${assignmentId}/reassign-conversation`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { toReviewerId, conversationId, connectionId },
    })
    .json()

export const getAssignmentInfo = (assignmentId: string): Promise<AssignmentV2_GetAssignmentInfoResponse> =>
  api
    .get(`assignments/v2/${assignmentId}/info`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const getAssignmentConversations = (
  assignmentId: string,
  params: TasksListParams,
): Promise<AssignmentV2_GetUserAssignmentConversationsResponse> => {
  const getEveryStatus = params.status === 'ALL'

  const searchParams = {
    limit: params.limit,
    offset: params.limit * params.page,
    sort: params.sort,
    revieweeIds: params.revieweeId > 1 ? [params.revieweeId] : [],
    reviewerIds: params.reviewerId > 1 ? [params.reviewerId === 2 ? session.user.id : params.reviewerId] : [],
    botIds: params.botRevieweeId ? [params.botRevieweeId] : [],
    ...(params.status !== 'ALL' && [ConversationStatus.TODO, ConversationStatus.DRAFT].includes(params.status)
      ? {
          hasDraft: params.status == ConversationStatus.DRAFT,
        }
      : {}),
    reviewed: getEveryStatus ? undefined : params.status === ConversationStatus.DONE,
    isRemovedFromAssignment: getEveryStatus ? undefined : params.status === ConversationStatus.REMOVED,
    excludedRevieweeIds: params.excludedRevieweeIds?.length ? params.excludedRevieweeIds : [],
    excludedReviewerIds: params.excludedReviewerIds?.length ? params.excludedReviewerIds : [],
    seed: params.seed,
  }

  if (params.revieweeId === 1) {
    searchParams.excludedRevieweeIds.push(`${session.user.id}`)
  }
  if (params.reviewerId === 1) {
    searchParams.excludedReviewerIds.push(`${session.user.id}`)
  }

  return api
    .get(`assignments/v2/${assignmentId}/conversations`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      searchParams: qs.stringify(searchParams),
    })
    .json()
}

export const removeConversation = (assignmentId: string, connectionId: string, externalId: string, reason: string) =>
  api
    .delete(`assignments/v2/${assignmentId}/conversation`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      searchParams: qs.stringify({ connectionId, externalId, reason }),
    })
    .json()

export const replaceConversation = (assignmentId: string, connectionId: string, externalId: string, reason: string) =>
  api
    .put(`assignments/v2/${assignmentId}/replace-conversation`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { connectionId, externalId, reason },
    })
    .json()

export const linkReview = (assignmentId: string, reviewId: string) =>
  api
    .post(`assignments/v2/${assignmentId}/link-review`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: { reviewId },
    })
    .json()
