import { ref, onBeforeMount, computed, type MaybeRefOrGetter, toRef } from 'vue'

import { api } from '@/api'
import { type WorkspaceRole } from '@/data/roles'
import { hasAccountRole, isWorkspaceRoleIncluded } from '@/utils/roleUtils'
import type { WorkspaceItem, WorkspaceSettings } from '@/types/workspace'

let getWorkspacePromise: Promise<any> | undefined
export const workspacesLoading = ref(false)

const getWorkspaceList = async () => {
  const query = new URLSearchParams()
  query.append('includeSettings', 'true')
  if (!getWorkspacePromise) getWorkspacePromise = api.get(`workspaces?${query}`).json<any>()
  const { workspaces } = await getWorkspacePromise
  getWorkspacePromise = undefined
  return workspaces.map((w) => ({ ...w, id: parseInt(w.id, 10) })) as WorkspaceItem[]
}

const workspacesRef = ref<WorkspaceItem[]>([])

const reload = async () => {
  workspacesLoading.value = true
  workspacesRef.value = await getWorkspaceList()
  workspacesLoading.value = false
}

type Options = MaybeRefOrGetter<{
  loadOnMount?: boolean
  managedOnly?: boolean
  roles?: WorkspaceRole[]
  settings?: Partial<WorkspaceSettings>
}>

export const useWorkspaceList = (options: Options = { loadOnMount: true }): [typeof workspacesRef, typeof reload] => {
  const optionsRef = toRef(options)

  onBeforeMount(() => {
    if (optionsRef.value?.loadOnMount) reload()
  })

  const returnRef = computed(() =>
    workspacesRef.value.filter((w) => {
      if (optionsRef.value?.roles) {
        const hasSufficientWsRole = isWorkspaceRoleIncluded(w.role, optionsRef.value?.roles)
        const hasSufficientAccountRole = !w.role && hasAccountRole('ADMIN', 'MANAGER')

        if (!hasSufficientWsRole && !hasSufficientAccountRole) return false
      }

      if (
        optionsRef.value?.managedOnly &&
        !(hasAccountRole('ADMIN', 'MANAGER') || isWorkspaceRoleIncluded(w.role, ['MANAGER', 'LEAD']))
      )
        return false

      if (optionsRef.value?.settings) {
        const allSettingsMatch = Object.keys(optionsRef.value?.settings).every(
          (settingKey) => w.settings?.[settingKey] === optionsRef.value?.settings?.[settingKey],
        )
        if (!allSettingsMatch) return false
      }

      return true
    }),
  )

  return [returnRef, reload]
}

export const getReviewerAccessibleWorkspaces = (list: number[]) => {
  return list.filter((id) => {
    const ws = workspacesRef.value.find((w) => w.id === id)
    return (
      !!ws && (hasAccountRole('ADMIN', 'MANAGER') || isWorkspaceRoleIncluded(ws.role, ['MANAGER', 'LEAD', 'REVIEWER']))
    )
  })
}

export function hasReviewerAccessToWorkspace(workspaceId: number) {
  const ws = workspacesRef.value.find((w) => w.id === workspaceId)

  return (
    !!ws && (hasAccountRole('ADMIN', 'MANAGER') || isWorkspaceRoleIncluded(ws.role, ['MANAGER', 'LEAD', 'REVIEWER']))
  )
}
