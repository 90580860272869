import Swal from 'sweetalert2/dist/sweetalert2.js'

import { sanitize, strip } from '@/utils/sanitize'
import i18n from '@/i18n'

interface Opts {
  title?: string
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  hideCancelButton?: boolean
  redConfirmButton?: boolean
  redCancelButton?: boolean
  hintForConfirmString?: string
  checkToConfirm?: boolean
  typeToConfirmString?: string
  className?: string
  popupClassname?: string
  allowHtml?: boolean
  width?: number
}

export default (htmlOrText, opts: Opts = {}) => {
  return Swal.fire({
    title: opts?.title ? strip(opts.title) : i18n.t('universal.dialog_default_title'),
    html: addConfirmationInputs(opts.allowHtml ? sanitize(htmlOrText) : strip(htmlOrText), opts),
    confirmButtonText: opts?.confirmButtonText ? strip(opts.confirmButtonText) : i18n.t('universal.ok'),
    focusConfirm: false,
    showCancelButton: !opts.hideCancelButton,
    cancelButtonText: opts?.cancelButtonText ? strip(opts.cancelButtonText) : i18n.t('universal.cancel'),
    denyButtonText: opts?.denyButtonText ? strip(opts.denyButtonText) : i18n.t('universal.deny'),
    showDenyButton: !!opts.denyButtonText,
    reverseButtons: true,
    customClass: {
      cancelButton: opts?.redCancelButton ? 'swal2-confirm-danger !font-semibold' : '!font-semibold',
      container: opts?.className,
      confirmButton: opts?.redConfirmButton ? 'swal2-confirm-danger' : 'swal2-confirm-primary',
      popup: opts?.popupClassname,
    },
    width: opts.width,
    showClass: { popup: 'swal2-show', backdrop: 'swal2-show' },
    hideClass: { popup: 'swal2-hide', backdrop: 'swal2-hide' },
    didOpen() {
      initializeConfirmationInputs(opts)
    },
  })
}

const addConfirmationInputs = (html, opts) => {
  return `
    ${renderTypeToConfirmInput(opts)}
    ${renderConfirmationCheckBox(html, opts)}
  `
}

const renderConfirmationCheckBox = (html, opts) => {
  if (!opts.checkToConfirm) return html

  return `
    <div class="alert-dialog-content text-sm mt-4">
    <span class="alert-dialog-checkbox">
      <svg width="16" height="16" fill="#fff">
        <path
          d="M10.9 4.8a1 1 0 011.4 0l.1.1c.3.4.3 1 0 1.4L7.8 11a1 1 0 01-1.5 0L3.7 8.2a1 1 0 111.5-1.4l1.9 2 3.8-4z"
        />
      </svg>
      <input
        type="checkbox"
        value=""
        id="check-to-confirm"
      />
    </span>
    <label for="check-to-confirm">${html}</label>
    </div>
  `
}

const renderTypeToConfirmInput = (opts) => {
  if (!opts.typeToConfirmString) return ''

  const hint = opts.hintForConfirmString || ''

  return `
    <div class="alert-dialog-text-input">
      <label class="alert-dialog-text-input-label" for="type-to-confirm">${hint}</label>
      <input type="text" value="" id="type-to-confirm" autocomplete="off">
    </div>
  `
}

const initializeConfirmationInputs = (opts) => {
  if (!opts.checkToConfirm && !opts.typeToConfirmString) return

  Swal.getConfirmButton().setAttribute('disabled', 'disabled')

  const checkbox = window.document.getElementById('check-to-confirm') as HTMLInputElement
  const textInput = window.document.getElementById('type-to-confirm') as HTMLInputElement
  const state = { text: '', checked: false }

  const setButtonState = () => {
    const checkedOrNotNeeded = !opts.checkToConfirm || state.checked
    const textConfirmedOrNotNeeded = !opts.typeToConfirmString || state.text === opts.typeToConfirmString

    if (checkedOrNotNeeded && textConfirmedOrNotNeeded) {
      Swal.getConfirmButton().removeAttribute('disabled')
    } else {
      Swal.getConfirmButton().setAttribute('disabled', 'disabled')
    }
  }

  checkbox?.addEventListener('change', function () {
    state.checked = checkbox.checked
    setButtonState()
  })

  textInput?.addEventListener('keyup', function (e) {
    state.text = (e.target as HTMLInputElement).value
    setButtonState()
  })
}
