import type { CSVURLResponse } from '@zendesk/zqa-services/dashboard'
import { api } from '@/api'

export interface CalibrationOverviewRow {
  name: string
  dataNumbers: (number | null)[]
  dataText: (string | null)[]
  type: 'group' | 'category' | 'root_cause' | 'comment' | 'total' | 'review_time'
  rows: CalibrationOverviewRow[]
}
export interface CalibrationSessionTicket {
  subject: string
  externalId: string
  sourceType: string
  connectionId: string
  scorecards: {
    name: string
    reviewCount: number
    hasFinalReview: boolean
    rows: CalibrationOverviewRow[]
    dataLabels: {
      reviewer: {
        id: number
        name: string
        avatar: string
      }
      reviewee: {
        id: number
        name: string
        avatar: string
      }
    }[]
  }[]
}

export interface CalibrationSessionOverview {
  session: {
    sessionId: string
    name: string
    dueAt: string
  }
  tickets: CalibrationSessionTicket[]
}

export const getCalibrationSessionOverview = (workspaceId: number, sessionId: string) => {
  return api
    .get(`calibration/${sessionId}/dashboard/reviews`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json<CalibrationSessionOverview>()
}

export const getCalibrationSessionCsv = (workspaceId: number, sessionId: string) => {
  return api
    .get(`calibration/${sessionId}/dashboard/reviews.csv`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json<CSVURLResponse>()
}
