import Image from '@tiptap/extension-image'
import { Plugin } from '@tiptap/pm/state'
import { type CommandProps, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import ky from 'ky'
import { v4 as uuid } from 'uuid'

import { api } from '@/api'
import i18n from '@/i18n'

import { toast } from '@/components/toast'
import { session } from '@/composables/useSession'

import ImageWidget from '../components/ImageWidget.vue'

import { type UnsavedImageMeta, unsavedImages, readFileAsObjectURL } from './useUnsavedImages'

const ALLOWED_MIME_TYPES = ['image/png', 'image/jpeg', 'image/webp', 'image/gif']
const ALLOWED_FILE_SIZE = 5 * 1024 * 1024 // 5MB

export const uploadPrivateFile = async (file: File) => {
  return await uploadFile(file, {
    signingUrl: `sign-upload-url?mime=${file.type}`,
    headers: session.features.attachmentUserIdMetaDev ? { 'X-Goog-Meta-Zqa-Userid': String(session.user.id) } : {},
  })
}

interface OptsType {
  signingUrl: string
  headers?: Record<string, string>
}

export const uploadFile = async (file: File, { signingUrl, headers }: OptsType) => {
  if (!fileValidator(file)) return ''

  const { uploadUrl, url, maxSize } = await api
    .get(signingUrl)
    .json<{ uploadUrl: string; url: string; maxSize: number }>()
  if (file.size > maxSize)
    throw new Error(i18n.t('modules.comment_editor.file_error_size_template', { name: file.name }))

  await ky.put(uploadUrl, {
    body: file,
    headers: {
      'Content-Type': file.type,
      'X-Goog-Content-Length-Range': `0,${maxSize}`,
      ...(headers || {}),
    },
  })

  return url
}

export const fileValidator = (file: File, options?: { allowedMimeTypes: string[] }) => {
  const { name, type, size } = file
  const validType = (options?.allowedMimeTypes || ALLOWED_MIME_TYPES).includes(type)
  const validSize = size <= ALLOWED_FILE_SIZE

  if (!validType || !validSize) {
    const extension = name.split('.').pop()
    const message = !validType
      ? i18n.t('modules.comment_editor.file_error_format_template', { name, type: type || extension })
      : i18n.t('modules.comment_editor.file_error_size_template', { name })

    toast({
      status: 'error',
      message: message.toString(),
      timeout: 0,
    })
  }

  return validType && validSize
}

declare module '@tiptap/core' {
  interface Commands {
    customImage: {
      uploadUnsavedImages: () => ({ state, dispatch }: CommandProps) => Promise<void>
      setSavedSrcAttribute: () => ({ state, dispatch }: CommandProps) => boolean
      getTemplateImageSrc: () => (p: CommandProps) => Promise<UnsavedImageMeta[]>
      setTemplateImageSrc: (meta: UnsavedImageMeta[]) => (p: CommandProps) => boolean
    }
  }
}

export const CustomImage = Image.extend({
  addNodeView() {
    return VueNodeViewRenderer(ImageWidget as any)
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      'data-klaus-unsaved-image': { default: null },
    }
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: HTMLAttributes.src,
      },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { loading: 'lazy' })],
    ]
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            if (!event.clipboardData) return false

            const items = event.clipboardData.items
            let containsImages = false

            for (const item of items) {
              const image = item.getAsFile()
              if (!image) continue
              containsImages = true
              if (!fileValidator(image)) continue
              event.preventDefault()
              const { schema } = view.state

              const { src, unsavedImageKey } = readFileAsObjectURL(image)
              const node = schema.nodes.image.create({ src, 'data-klaus-unsaved-image': unsavedImageKey })
              const transaction = view.state.tr.replaceSelectionWith(node)
              view.dispatch(transaction)
            }
            return containsImages
          },
        },
      }),
    ]
  },
  addCommands() {
    return {
      uploadUnsavedImages:
        () =>
        async ({ state }) => {
          const promises: Promise<void | string>[] = []

          state.doc.descendants((node) => {
            if (node.type.name === 'image' && node.attrs['data-klaus-unsaved-image']) {
              const img = unsavedImages[node.attrs['data-klaus-unsaved-image']]
              if (!img || img.uploading) return

              img.uploading = true

              promises.push(uploadPrivateFile(img.file).then((src) => (img.bucketSrc = src)))
            }
          })

          await Promise.all(promises)
        },
      setSavedSrcAttribute:
        () =>
        ({ state, dispatch }) => {
          const { tr } = state
          let found = false

          state.doc.descendants((node, pos) => {
            if (node.type.name === 'image' && node.attrs['data-klaus-unsaved-image']) {
              found = true

              const image = unsavedImages[node.attrs['data-klaus-unsaved-image']]
              if (!image?.bucketSrc) return

              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                src: image.bucketSrc,
                'data-klaus-unsaved-image': undefined,
              })
            }
          })

          if (found && dispatch) {
            dispatch(tr)
          }

          return found
        },
      getTemplateImageSrc:
        () =>
        async ({ state }) => {
          const promises: Promise<UnsavedImageMeta>[] = []

          state.doc.descendants((node) => {
            if (node.type.name === 'image' && node.attrs.src) {
              const url = node.attrs.src

              promises.push(
                ky.get(url).then(async (response) => {
                  const blob = await response.blob()
                  const file = new File([blob], uuid(), { type: blob.type })
                  return readFileAsObjectURL(file)
                }),
              )
            }
          })

          return await Promise.all(promises)
        },
      setTemplateImageSrc:
        (meta) =>
        ({ state, dispatch }) => {
          const { tr } = state
          let found = false
          let i = 0

          state.doc.descendants((node, pos) => {
            if (node.type.name === 'image') {
              found = true
              const { src, unsavedImageKey } = meta[i]
              i++

              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                src,
                'data-klaus-unsaved-image': unsavedImageKey,
              })
            }
          })

          if (found && dispatch) {
            dispatch(tr)
          }

          return found
        },
    }
  },
})
