import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import dayjs from 'dayjs'

import { session } from '@/composables/useSession'
import analytics from '@/utils/analytics'

import type { Session, Thread } from '../types'
import { getThread, createSession } from '../api'
import { getDefaultTitle } from '../utils'

interface Props {
  id: string
}

const emptySession = () => ({
  title: '',
  createdAt: '',
  pins: [],
  actions: [],
  note: '',
  privateNote: '',
  scheduledAt: '',
  unread: true,
})

export default function (props: Props) {
  const router = useRouter()
  const route = useRoute()
  const loading = ref(true)
  const sessionListRef = ref<HTMLDivElement>()

  const thread = reactive<Thread>({
    coach: {
      id: '',
      name: '',
      avatar: '',
    },
    coachee: {
      id: '',
      name: '',
      avatar: '',
    },
    coacheeInactive: false,
    sessions: [],
    participants: [],
  })

  function removeSession(id: string) {
    thread.sessions = thread.sessions.filter((s) => s.id !== id)
    if (!thread.sessions.length) return router.push({ name: 'coaching.sessions' })
  }

  function scrollToSession(sessionId: string) {
    if (!sessionId) return

    const idx = thread.sessions.findIndex((s) => s.id === sessionId)
    thread.sessions[idx].expandByDefault = true

    setTimeout(() => {
      sessionListRef.value
        ?.querySelector(`#session-${sessionId}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 0)
  }

  function patchSession(id: string, updatedSession: Partial<Session>) {
    const idx = thread.sessions.findIndex((s) => s.id === id)
    if (idx === undefined) return
    thread.sessions[idx] = { ...thread.sessions[idx], ...updatedSession }
  }

  function loadThread() {
    getThread(props.id)
      .then((data) => {
        thread.sessions = data.sessions
        thread.coachee = data.coachee
        thread.coach = data.coach
        thread.coacheeInactive = data.coacheeInactive
        thread.participants = data.participants
        loading.value = false
        if (thread.sessions.length === 1 && !thread.sessions[0].scheduledAt) {
          thread.sessions[0].showDatepicker = true
        }

        scrollToSession(route.hash?.slice(1))
      })
      .catch(({ status }) => {
        if (String(status).startsWith('4')) {
          router.push({ name: 'not-found', params: { pathMatch: route.path.substring(1).split('/') } })
        }
      })
  }

  async function createNew() {
    if (!thread.coachee || !thread.coach) return

    const dateNow = dayjs().format()
    const params = {
      coachId: thread.coach.id,
      coacheeId: thread.coachee.id,
      title: getDefaultTitle(dateNow),
      scheduledAt: dateNow,
    }
    const data = await createSession(params)
    const mergedSession = Object.assign({}, emptySession(), data.session, {
      expandByDefault: true,
      unread: true,
      showDatepicker: true,
    })
    thread.sessions.unshift(mergedSession)
    scrollToSession(mergedSession.id)
    analytics.coachingNewSession()
  }

  loadThread()

  return { thread, loading, session, sessionListRef, createNew, patchSession, removeSession, scrollToSession }
}
