import type { Dispute } from '../TicketContent/types/dispute'

export const DISPUTE_COLORS: Record<Dispute['status'], { bg: string; icon: string; text: string }> = {
  ACCEPTED: {
    bg: 'bg-green-20',
    icon: 'text-green-50',
    text: 'text-green-80',
  },
  PARTIALLY_ACCEPTED: {
    bg: 'bg-teal-20',
    icon: 'text-teal-50',
    text: 'text-teal-80',
  },
  REJECTED: {
    bg: 'bg-red-20',
    icon: 'text-red-50',
    text: 'text-red-80',
  },
  NEW: {
    bg: 'bg-gray-20',
    icon: 'text-gray-50',
    text: 'text-gray-80',
  },
} as const
