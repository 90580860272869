import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rating_scale_change_bar = _resolveComponent("rating-scale-change-bar")!
  const _component_overview_card = _resolveComponent("overview-card")!
  const _component_iqs_column_card = _resolveComponent("iqs-column-card")!
  const _component_internal_quality_score_card = _resolveComponent("internal-quality-score-card")!
  const _component_workspaces_card = _resolveComponent("workspaces-card")!
  const _component_users_card = _resolveComponent("users-card")!
  const _component_ratings_by_category_card = _resolveComponent("ratings-by-category-card")!
  const _component_quality_scores_card = _resolveComponent("quality-scores-card")!
  const _component_scores_by_category_card = _resolveComponent("scores-by-category-card")!
  const _component_scores_by_ticket_card = _resolveComponent("scores-by-ticket-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_rating_scale_change_bar, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"]),
    (_ctx.viewSettings.cardsVisible.overview)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass(['mx-6 mb-6', _ctx.$style.overviewSection])
        }, [
          _createVNode(_component_overview_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"]),
          (_ctx.session.features.specialPassRateAnalytics)
            ? (_openBlock(), _createBlock(_component_iqs_column_card, {
                key: 0,
                class: "mt-6",
                "filter-params": _ctx.filterParams
              }, null, 8 /* PROPS */, ["filter-params"]))
            : (_openBlock(), _createBlock(_component_internal_quality_score_card, {
                key: 1,
                class: _normalizeClass(_ctx.session.features.passRateAnalytics && 'mt-6'),
                "filter-params": _ctx.filterParams
              }, null, 8 /* PROPS */, ["class", "filter-params"]))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.filterParams.workspaceIds.length > 1 && _ctx.viewSettings.cardsVisible.workspaces)
      ? (_openBlock(), _createBlock(_component_workspaces_card, {
          key: 1,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewSettings.cardsVisible.users)
      ? (_openBlock(), _createBlock(_component_users_card, {
          key: 2,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewSettings.cardsVisible.scoresByCategory)
      ? (_openBlock(), _createBlock(_component_ratings_by_category_card, {
          key: 3,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewSettings.cardsVisible.scoresOverTime)
      ? (_openBlock(), _createBlock(_component_quality_scores_card, {
          key: 4,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewSettings.cardsVisible.categoryScoresOverTime)
      ? (_openBlock(), _createBlock(_component_scores_by_category_card, {
          key: 5,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewSettings.cardsVisible.scoresByReviews)
      ? (_openBlock(), _createBlock(_component_scores_by_ticket_card, {
          key: 6,
          "filter-params": _ctx.filterParams
        }, null, 8 /* PROPS */, ["filter-params"]))
      : _createCommentVNode("v-if", true)
  ]))
}