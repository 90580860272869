import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-90 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-xs font-semibold", _ctx.$style.sunburstTooltip]),
    style: _normalizeStyle({
      visibility: Object.keys(_ctx.texts).length && _ctx.tooltipLocation ? 'visible' : 'hidden',
      left: _ctx.tooltipLocation && _ctx.tooltipLocation.pageX + 15 + 'px',
      top: _ctx.tooltipLocation && _ctx.tooltipLocation.pageY + 15 + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sliceColor),
      style: _normalizeStyle({ background: _ctx.sliceColor })
    }, null, 6 /* CLASS, STYLE */),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-sm", _ctx.$style.title])
      }, _toDisplayString(_ctx.texts.hoveredNodeCategory) + ": " + _toDisplayString(_ctx.texts.hoveredNodeName), 3 /* TEXT, CLASS */),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.texts.hoveredNodeValue), 1 /* TEXT */),
      _createVNode(_component_i18n_t, {
        keypath: "conversations.visual_filters.perc_of_total",
        tag: "span",
        class: "text-gray-70 font-normal"
      }, {
        percentage: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.texts.percentageFromRoot) + "%", 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.texts.parentInfo)
        ? (_openBlock(), _createBlock(_component_i18n_t, {
            key: 0,
            keypath: "conversations.visual_filters.perc_of_parent",
            tag: "div",
            class: "text-gray-70 font-normal"
          }, {
            percentage: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.ofTextBold)
              }, _toDisplayString(_ctx.texts.parentInfo.percentageFromParent) + "%", 3 /* TEXT, CLASS */)
            ]),
            category: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.ofTextBold)
              }, _toDisplayString(_ctx.texts.parentInfo.parentName), 3 /* TEXT, CLASS */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ])
  ], 6 /* CLASS, STYLE */))
}