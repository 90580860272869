import { computed, type ComputedRef } from 'vue'
import { useMediaControls } from '@vueuse/core'
import { pauseAllAudioElements } from '@/utils/audio'

type Props = ComputedRef<{
  audioRef: HTMLAudioElement | undefined
  recordingUrl: string | undefined
}>

export default function useAudioPlayer(props: Props) {
  const audioRef = computed(() => props.value.audioRef)
  const recordingUrl = computed(() => props.value.recordingUrl)

  const {
    rate: playbackRate,
    playing,
    duration,
    currentTime,
    onSourceError,
  } = useMediaControls(audioRef, { src: recordingUrl })

  const durationFormatted = computed(getFormattedDuration)
  const currentTimeFormatted = computed(getFormattedCurrentTime)

  function setPlaybackRate(rate: number) {
    playbackRate.value = rate
  }

  function play(seconds = currentTime.value) {
    pauseAllAudioElements()
    currentTime.value = seconds
    playing.value = true
  }

  function pause() {
    playing.value = false
  }

  function toggleAudio() {
    playing.value ? pause() : play()
  }

  function rewind() {
    currentTime.value -= 5
  }

  function forward() {
    currentTime.value += 5
  }

  function updateTime(seconds: number) {
    currentTime.value = seconds
  }

  function getFormattedDuration() {
    return formatSeconds(duration.value)
  }

  function getFormattedCurrentTime() {
    return formatSeconds(currentTime.value)
  }

  return {
    durationFormatted,
    currentTimeFormatted,
    playbackRate,
    play,
    pause,
    toggleAudio,
    rewind,
    forward,
    updateTime,
    setPlaybackRate,
    duration: computed(() => duration.value),
    currentTime: computed(() => currentTime.value),
    isPlaying: computed(() => playing.value),
    onSourceError,
  }
}

function formatSeconds(seconds: number) {
  const minutesStr = `${Math.floor(seconds / 60)}`
  const ceilSeconds = Math.floor(seconds % 60)
  const secondsStr = ceilSeconds < 10 ? `0${ceilSeconds}` : `${ceilSeconds}`
  return `${minutesStr}:${secondsStr}`
}
