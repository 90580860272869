import type { ExternalComment, TicketDataResponse, TicketListItem } from '@zendesk/zqa-services/tickets'

export enum TicketDistributionCategory {
  Connection = 'CONNECTION',
  Language = 'LANGUAGE',
  Channel = 'CHANNEL',
  Csat = 'CSAT',
  Complexity = 'COMPLEXITY',
  Sentiment = 'SENTIMENT',
  Reviewed = 'REVIEWED',
}

export enum StatisticalDistributionPeriod {
  LastWeek = 'LAST_WEEK',
  Last7Days = 'LAST_7_DAYS',
  LastMonth = 'LAST_MONTH',
  Last30Days = 'LAST_30_DAYS',
}

export interface TicketStatisticalDistribution {
  name: string
  value: string
  category: TicketDistributionCategory | 'ROOT'
  size: string
  children: TicketStatisticalDistribution[]
}

export enum AgentDistributionCategory {
  Performance = 'AGENT_PERFORMANCE',
  Csat = 'AGENT_CSAT',
  Sentiment = 'AGENT_SENTIMENT',
  Reviewed = 'AGENT_REVIEWED',
}

export interface AgentStatisticalDistribution {
  name: string
  value: string
  category: AgentDistributionCategory | 'ROOT'
  size: string
  children: AgentStatisticalDistribution[]
}

export interface TicketMessages {
  data: {
    bodyMap: Record<string, ExternalCommentExt>
    subject: string | null
  }
}

export interface TicketField {
  externalId: string
  name: string
  value: string
}

export interface ExternalCommentExt extends ExternalComment {
  id?: number | string
  hasReviews?: boolean
  highlighted: boolean
  showTime?: boolean
  hasComments?: boolean
  youReviewed?: boolean
  firstMessageInGroup?: boolean
}

export interface TicketReply extends ExternalCommentExt {
  currentEventTimeFormatted?: string
  firstEventInGroup?: boolean
  sameDay?: boolean
  timeFormat?: 'm' | 'h' | 'f'
}

export interface TicketData extends Omit<TicketDataResponse, 'paymentTokenId' | 'externalComments'> {
  externalFields: TicketField[]
  paymentTokenId: number
  externalComments: ExternalCommentExt[]
  externalTags: string[]
}

export interface TicketListItemExt extends TicketListItem {
  messageId?: string
  subject: string
  id: any
}

export interface TimelineMessage {
  length: number
  id: string | number
  externalId: string
  date: string
  note: boolean
  name: string
  offset: string
  relativeHeight: number
  belongsToUser: boolean
  participantColor: string
  endUser: boolean
}
