import { isEqual } from 'lodash-es'
import type { PraiseBotSettings } from '@zendesk/zqa-services/account'

import { session } from '@/composables/useSession'
import { type NotificationSettings, NotificationFrequency } from '@/modules/settings/types'
import {
  updateNotificationSettings as updateNotificationApi,
  toggleNotificationsLock,
  updateWebhooks,
  deleteWebhooks,
  setPraisebot,
} from '@/modules/settings/api'
import type { EmailNotifications, SlackNotifications, WebhooksNotifications } from '../types'

export interface NotificationsState {
  overrideSettings: boolean
  email: EmailNotifications
  slack: SlackNotifications
}

export interface State extends NotificationsState {
  webhooks: WebhooksNotifications
  praisebot: PraiseBotSettings
}

export const mapResponseToState = (data: NotificationSettings): NotificationsState => {
  const state: NotificationsState = { email: null, slack: null, overrideSettings: false }

  const { EMAIL, SLACK } = data.notificationConfiguration

  state.email = {
    reportDaily: EMAIL.REPORT.includes(NotificationFrequency.DAILY),
    reportWeekly: EMAIL.REPORT.includes(NotificationFrequency.WEEKLY),
    reportMonthly: EMAIL.REPORT.includes(NotificationFrequency.MONTHLY),
    feedbackStep: EMAIL.FEEDBACK[0],
    threadStep: EMAIL.THREAD_FEEDBACK[0],
    mentionStep: EMAIL.MENTION[0],
    disputeStep: EMAIL.DISPUTE_ACCEPTED[0],
    noReviewWeeklyReminder: EMAIL.NO_REVIEW.includes(NotificationFrequency.WEEKLY),
  }

  state.slack = {
    ratingNotification: SLACK.RATING[0] === 'INSTANT',
    commentNotification: SLACK.COMMENT[0] === 'INSTANT',
    threadFeedbackNotification: SLACK.THREAD_FEEDBACK[0] === 'INSTANT',
    mentionNotification: SLACK.MENTION[0] === 'INSTANT',
    disputeNotification: SLACK.DISPUTE_ACCEPTED[0] === 'INSTANT',
  }

  state.overrideSettings = !data.userChangeAllowed

  return state
}

const getFrequency = (enabled: boolean) => {
  return enabled ? NotificationFrequency.INSTANT : NotificationFrequency.OFF
}

const getEmailNotificationPromises = (state: State, results: State): Promise<any>[] => {
  const promises: Promise<any>[] = []
  const {
    feedbackStep,
    threadStep,
    mentionStep,
    disputeStep,
    reportDaily,
    reportWeekly,
    reportMonthly,
    noReviewWeeklyReminder,
  } = state.email

  if (results.email.reportDaily !== reportDaily)
    promises.push(updateNotificationSetting('EMAIL', NotificationFrequency.DAILY, 'REPORT', state.overrideSettings))
  if (results.email.reportWeekly !== reportWeekly)
    promises.push(updateNotificationSetting('EMAIL', NotificationFrequency.WEEKLY, 'REPORT', state.overrideSettings))
  if (results.email.reportMonthly !== reportMonthly)
    promises.push(updateNotificationSetting('EMAIL', NotificationFrequency.MONTHLY, 'REPORT', state.overrideSettings))
  if (results.email.feedbackStep !== feedbackStep)
    promises.push(
      updateNotificationSetting('EMAIL', NotificationFrequency[feedbackStep], 'FEEDBACK', state.overrideSettings),
    )
  if (results.email.threadStep !== threadStep)
    promises.push(
      updateNotificationSetting('EMAIL', NotificationFrequency[threadStep], 'THREAD_FEEDBACK', state.overrideSettings),
    )
  if (results.email.mentionStep !== mentionStep)
    promises.push(
      updateNotificationSetting('EMAIL', NotificationFrequency[mentionStep], 'MENTION', state.overrideSettings),
    )
  if (results.email.disputeStep !== disputeStep)
    promises.push(updateDisputes('EMAIL', NotificationFrequency[disputeStep], state.overrideSettings))
  if (results.email.noReviewWeeklyReminder !== noReviewWeeklyReminder)
    promises.push(updateNotificationSetting('EMAIL', NotificationFrequency.WEEKLY, 'NO_REVIEW', state.overrideSettings))

  return promises
}

const getSlackNotificationPromises = (state: State, results: State): Promise<any>[] => {
  const promises = []
  const {
    ratingNotification,
    commentNotification,
    threadFeedbackNotification,
    mentionNotification,
    disputeNotification,
  } = state.slack

  if (results.slack.ratingNotification !== ratingNotification)
    promises.push(
      updateNotificationSetting('SLACK', getFrequency(ratingNotification), 'RATING', state.overrideSettings),
    )
  if (results.slack.commentNotification !== commentNotification)
    promises.push(
      updateNotificationSetting('SLACK', getFrequency(commentNotification), 'COMMENT', state.overrideSettings),
    )
  if (results.slack.threadFeedbackNotification !== threadFeedbackNotification)
    promises.push(
      updateNotificationSetting(
        'SLACK',
        getFrequency(threadFeedbackNotification),
        'THREAD_FEEDBACK',
        state.overrideSettings,
      ),
    )
  if (results.slack.mentionNotification !== mentionNotification)
    promises.push(
      updateNotificationSetting('SLACK', getFrequency(mentionNotification), 'MENTION', state.overrideSettings),
    )
  if (results.slack.disputeNotification !== disputeNotification)
    promises.push(updateDisputes('SLACK', getFrequency(disputeNotification), state.overrideSettings))

  return promises
}

const updateNotificationSetting = async (
  channel: string,
  frequency: NotificationFrequency,
  type: string,
  overrideUserSettings: boolean,
) => {
  await updateNotificationApi({
    channel,
    frequency,
    notificationType: type,
    userChangeAllowed: !overrideUserSettings,
  })
}

const updateDisputes = async (channel: string, frequency: NotificationFrequency, overrideUserSettings: boolean) => {
  await Promise.all([
    updateNotificationSetting(channel, frequency, 'DISPUTE_FEEDBACK_REQUESTED', overrideUserSettings),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_CREATED', overrideUserSettings),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_ACCEPTED', overrideUserSettings),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_REJECTED', overrideUserSettings),
    updateNotificationSetting(channel, frequency, 'DISPUTE_ACCEPTED', overrideUserSettings),
    updateNotificationSetting(channel, frequency, 'DISPUTE_REJECTED', overrideUserSettings),
  ])
}

export const updateNotifications = ({
  state,
  results,
  hasSlackConnection,
}: {
  state: State
  results: State
  hasSlackConnection: boolean
}) => {
  let promises: Promise<any>[] = []

  promises = getEmailNotificationPromises(state, results)

  if (state.overrideSettings !== results.overrideSettings) {
    promises.push(toggleNotificationsLock())
  }

  if (hasSlackConnection && session.features.slackNotifications) {
    promises = [...promises, ...getSlackNotificationPromises(state, results)]
  }

  if (session.features.webhooks && state.webhooks?.url?.trim() && results.webhooks.url) {
    if (!state.webhooks.url?.trim() && results.webhooks.url) promises.push(deleteWebhooks())
    else if (!isEqual(state.webhooks, results.webhooks)) promises.push(updateWebhooks(state.webhooks))
  }

  if (state.praisebot) promises.push(setPraisebot(state.praisebot))

  return Promise.all(promises)
}
