import { computed, type ComputedRef } from 'vue'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
import { SelectAllModes } from '@klausapp/ui-kit/components/UiDropdownV2/types'
import { searchUserGroups, type UserGroupSearchResult } from '@/modules/dashboard/api'
import { getReviewerAccessibleWorkspaces } from '@/composables/useWorkspaceList'

type Props = ComputedRef<{
  searchQuery: string
  selectedGroupIds: string[]
  workspaceIds: number[]
  workspacesSelectAllMode?: SelectAllModes
}>

export function useUserGroups(props: Props) {
  const searchQuery = computed(() => props.value.searchQuery)
  const groupIds = computed(() => props.value.selectedGroupIds)
  const workspaceIds = computed(() => props.value.workspaceIds)

  const wholeAccountSelected = computed(
    () => props.value.workspacesSelectAllMode === SelectAllModes.None && !workspaceIds.value?.length,
  )
  const hasWorkspacesWithAccess = computed(
    () => wholeAccountSelected.value || !!getReviewerAccessibleWorkspaces(workspaceIds.value).length,
  )

  const { data: userGroupData, isPending: isLoading } = useQuery({
    queryKey: ['user-groups', workspaceIds, searchQuery],
    queryFn: ({ signal }) =>
      getUserGroupsWithAlreadySelectedUserGroups(workspaceIds.value, groupIds.value, searchQuery.value, signal),
    retry: false,
    gcTime: 0,
    placeholderData: keepPreviousData,
  })

  return { userGroupData, isLoading, hasWorkspacesWithAccess }
}

const getUserGroupsWithAlreadySelectedUserGroups = async (
  workspaceIds: number[],
  selectedGroupIds: string[],
  searchQuery: string,
  signal: AbortSignal,
): Promise<{ userGroups: UserGroupSearchResult[]; total: number }> => {
  const userGroupsResp = await searchUserGroups({
    workspaceIds,
    name: searchQuery || undefined,
    signal,
  })

  if (selectedGroupIds.length) {
    const selectedUserGroupsResp = await searchUserGroups({
      workspaceIds,
      ids: selectedGroupIds,
      signal,
    })

    const uniqueUserGroups = uniqBy([...userGroupsResp.userGroups, ...selectedUserGroupsResp.userGroups], 'id')
    const totalDiff = uniqueUserGroups.length - userGroupsResp.userGroups.length

    return {
      userGroups: uniqueUserGroups,
      total: Number(userGroupsResp.total) - totalDiff,
    }
  }

  return {
    userGroups: userGroupsResp.userGroups,
    total: Number(userGroupsResp.total),
  }
}
