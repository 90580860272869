import { UiDropdownTypes } from '@klausapp/ui-kit'

import GroupPicker from '../GroupPicker.vue'
import CategoryPicker from '../CategoryPicker.vue'
import TimePeriodPicker from '../TimePeriodPickerV2.vue'
import WorkspacePicker from '../WorkspacePickerV2.vue'
import UserPicker from '../UserPickerV2.vue'
import ReceivedGivenToggle from '../ReceivedGivenToggle.vue'
import SourcePicker from '../SourcePicker.vue'
import ScorecardPicker from '../ScorecardPicker.vue'
import HashtagPicker from '../HashtagPicker.vue'
import TicketTagPicker from '../TicketTagPicker.vue'
import SelfReviewRadio from '../SelfReviewRadio.vue'
import type { FilterBarFilter } from './types'
import { toSortedFilters } from './util'
import { filterKey } from './keys'
const filters: FilterBarFilter[] = [
  {
    component: ReceivedGivenToggle,
    key: filterKey.receivedGivenToggle,
  },
  {
    component: TimePeriodPicker,
    key: filterKey.timePicker,
  },
  {
    component: WorkspacePicker,
    key: filterKey.workspacePicker,
    mode: UiDropdownTypes.Modes.Single,
    updateStateOnChanges: (filters) => ({
      ...filters,
      connectionIds: [],
      ratingCategoryTags: [],
      commentTags: [],
      ticketTags: [],
      categoryId: undefined,
    }),
  },
  {
    component: GroupPicker,
    key: filterKey.groupPicker,
    mode: UiDropdownTypes.Modes.Multiple,
  },
  {
    component: UserPicker,
    key: filterKey.userPicker,
    mode: UiDropdownTypes.Modes.Multiple,
  },
  {
    component: CategoryPicker,
    key: filterKey.categoryPicker,
    mode: UiDropdownTypes.Modes.Single,
  },
  {
    component: SourcePicker,
    key: filterKey.sourcePicker,
    mode: UiDropdownTypes.Modes.Multiple,
    connectedParameter: 'connectionIds',
    isSidebarFilter: true,
  },
  {
    component: ScorecardPicker,
    key: filterKey.scorecardPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    connectedParameter: 'ratingCategoryTags',
    isSidebarFilter: true,
  },
  {
    component: HashtagPicker,
    key: filterKey.hashtagPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    isSidebarFilter: true,
    connectedParameter: 'commentTags',
    onlyDisputeHashtags: false,
  },
  {
    component: TicketTagPicker,
    key: filterKey.ticketTagPicker,
    mode: UiDropdownTypes.Modes.Multiple,
    connectedParameter: 'ticketTags',
    isSidebarFilter: true,
  },
  {
    component: SelfReviewRadio,
    key: filterKey.selfReviewsRadio,
    connectedParameter: 'selfReview',
    isSidebarFilter: true,
  },
]

export default toSortedFilters(filters)
