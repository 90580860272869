export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg Zendesk-aldoménjét"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés a Zendeskbe"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cége már rendelkezik fiókkal."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozás egy céghez"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meglévő céghez való csatlakozáshoz kérje meg rendszergazdáját, hogy küldjön Önnek meghívót."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérje meg rendszergazdáját, hogy hívja meg"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meggondoltam magam."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció megszakítása"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cége már rendelkezik fiókkal. Szeretne csatlakozni a cég fiókjához, vagy új fiókot kíván létrehozni?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utasítások másolása"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új fiók létrehozása"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhető meglévő fiók"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem férhet hozzá Zendesk QA-fiókjához, amíg a fennálló előfizetési számlákat be nem fizeti."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetése fel van függesztve"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiók nem található. Próbálkozzon újra."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["állítson be új jelszót."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Próbálkozzon újra, vagy ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz e-mail-cím- és jelszó-kombináció."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjen újat a rendszergazdájától."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a meghívóhivatkozás érvénytelen, vagy már fel lett használva."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("method")), " bejelentkezési mód deaktiválva van a fiókjában."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a meghívó érvénytelen vagy már felhasználták. Kérjen újat a rendszergazdájától."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meghívó adatainak betöltése sikertelen volt."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A regisztráció sikertelen volt. Már létezik felhasználó ezzel az e-mail-címmel."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókja több egymást követő bejelentkezési kísérlet után le lett tiltva. Keresse a beérkezett üzenetei között a tőlünk kapott e-mailt, amelyben utasításokat talál a tiltás feloldásáról."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Először igazolja vissza az e-mail-címét"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-fiókot adjon meg"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mező kitöltése kötelező"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mező kitöltése kötelező"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítás"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezési kód"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mágikus hivatkozás küldése"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkahelyi e-mail-cím"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön munkahelyi e-mail-címe"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Fő szolgáltatási megállapodás)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó nem felel meg a követelményeknek"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelmi nyilatkozat"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kis türelmet"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üdv, ", _interpolate(_named("invitee_name")), "!"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Csatlakozás a(z) ", _interpolate(_named("organization")), " szervezethez"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail visszaigazolva. Most már bejelentkezhet az új fiókjával alább."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás Google-fiókkal"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen meghívóhivatkozás"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés mágikus hivatkozással"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a bejelentkezéshez"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás Slack-fiókkal"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás Zendeskkel"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e-mail visszaigazolása megtörtént. Most már bejelentkezhet az új fiókjával alább:"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés Google-fiókkal"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés mágikus hivatkozással"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés Slack-fiókkal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés a Zendesk QA szolgáltatásba"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiókja törölve lett"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs fiókja?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes kódot adjon meg"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mező kitöltése kötelező"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-fiókot adjon meg"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mező kitöltése kötelező"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezési kód"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes bejelentkezési lehetőség megtekintése"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " hagyományos módokon"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés mágikus hivatkozással"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldtünk egy e-mailt a bejelentkezési kódjával."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldtünk egy e-mailt, a bejelentkezéshez kövesse a benne található utasításokat."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a mágikus hivatkozás küldésekor. Próbálkozzon újra."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés e-mailen keresztül"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó létrehozása"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy kisbetűt"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy nagybetűt"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az új jelszónak tartalmaznia kell"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó maximális hossza 128 karakter"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könnyen megjegyezhető, nehezen kitalálható"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 8 karakter"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saját jelszó beállítása"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfelejtette a jelszavát?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy kisbetűs karakter"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy szám"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy nagybetűs karakter"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha jelszavát vissza kívánja állítani, adja meg az e-mail-címét, mi pedig küldünk majd Önnek egy e-mailt az utasításokkal."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-fiókot adjon meg"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mező kitöltése kötelező"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-mailt küldtünk a következő címre: ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha az e-mail nem jelenik meg a beérkező levelek között vagy a spam mappában, ellenőrizze a megadott e-mail-címét."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a bejelentkezéshez"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze e-mailjeit"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", akkor küldünk egy jelszó-visszaállítási e-mailt."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha fiókjának már van meglévő jelszava"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha ez ", _interpolate(_named("bold")), ", akkor küldtünk egy jelszó-visszaállítási hivatkozást."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az e-mail-cím társítva van egy fiókhoz"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emlékszik a jelszavára?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a visszaállítási hivatkozás elküldésekor. Próbálkozzon újra."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció mágikus hivatkozás használatával"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Adatvédelmi szabályzat)"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A regisztrációval Ön elfogadja a Zendesk QA ", _interpolate(_named("terms_of_service")), " és ", _interpolate(_named("privacy_policy")), " feltételeit"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerződési feltételek"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Már van fiókja?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció mágikus hivatkozással"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció munkahelyi e-mail-címmel"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció Slack-fiókkal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció a Zendesk QA szolgáltatásra"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció Google-fiókkal"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelkezem aktív Zendesk-fiókkal"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Regisztráljon vagy jelentkezzen be a(z) ", _interpolate(_named("direct_install_name")), " beállításának befejezéséhez"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tényleg Ön az? Keresse a megerősítő e-mailt a beérkezett üzenetei között."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden készen áll"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA asztali rendszeren működik a legjobban. Jelentkezzen be egy asztali böngészőben a beszélgetések felülvizsgálatának megkezdéséhez."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demó előjegyzése"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vagy jegyezzen elő egy demót velünk, és megmutatjuk, mire lehet képes a Zendesk QA révén."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igényt tartanak a jelenlétére"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megszakítani"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meggondoltam magam, és szeretném ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " meghívta Önt, hogy csatlakozzon a fiókjához."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozás a fiókhoz"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új fiók létrehozása"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbáljon újból bejelentkezni"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az Ön visszajelzésre kapott reakció: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott reakciók"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagált az Ön hozzászólására"]), _normalize(["Többen reagáltak az Ön hozzászólására"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagált az Ön válaszára"]), _normalize(["Többen reagáltak az Ön válaszára"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " reagált az Ön felülvizsgálatára"]), _normalize(["Többen reagáltak az Ön felülvizsgálatára"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hozzászólás"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válasz"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELLENVETÉSEK"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADOTTAK"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEÉRKEZETTEK"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési visszajelzések"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakciók"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elkezdettek"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Megjelöli olvasottként a hozzászólást?"]), _normalize(["Megjelöli olvasottként az összes hozzászólást?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Megjelöli olvasottként az ellenvetést?"]), _normalize(["Megjelöli olvasottként az összes ellenvetést?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Megjelöli olvasottként a felmérési visszajelzést?"]), _normalize(["Megjelöli olvasottként az összes felmérési visszajelzést?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Megjelöli olvasottként a felülvizsgálatot?"]), _normalize(["Megjelöli olvasottként az összes felülvizsgálatot?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hozzászólás olvasottként megjelölve"]), _normalize(["Az összes hozzászólás olvasottként megjelölve"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ellenvetés olvasottként megjelölve"]), _normalize(["Az összes ellenvetés olvasottként megjelölve"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felmérési visszajelzés olvasottként megjelölve"]), _normalize(["Az összes felmérési visszajelzés olvasottként megjelölve"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felülvizsgálat olvasottként megjelölve"]), _normalize(["Az összes felülvizsgálat olvasottként megjelölve"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes megjelölése olvasottként"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés olvasottként"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor valaki ellenvetéssel él egy felülvizsgálattal szemben, az itt jelenik meg."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek ellenvetések"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor hozzárendelik Önt egy ellenvetés megoldásához, az itt jelenik meg."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott ellenvetések"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor Ön ellenvetéssel él egy felülvizsgálattal szemben, az itt jelenik meg."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek indított ellenvetések"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Önnek adott visszajelzések itt jelennek meg. Még nincs mit megjeleníteni."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott visszajelzések"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön felülvizsgálataira és válaszaira adott reakciók itt jelennek meg. Még nincs mit megjeleníteni."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott reakciók"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés megnyitása"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön által adott visszajelzések itt lesznek megtalálhatók. Kezdésképpen hagyjon felülvizsgálatot a Beszélgetések nézetben."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek adott felülvizsgálatok"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Önnek adott visszajelzések itt jelennek meg. Még nincs mit megjeleníteni."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott felülvizsgálatok"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók hozzászólások"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hozzászólásban hagyott visszajelzés itt jelenik meg."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megírt hozzászólások"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók hozzászólások"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt jelennek meg az Ön birtokában lévő felülvizsgálatok alatti hozzászólások, az ", "@", "említések, illetve az Ön hozzászólásaira adott válaszok."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott hozzászólások"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elfogadott ellenvetések"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek nyitott ellenvetések"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elutasított ellenvetések"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megoldott ellenvetések"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon másik lehetőséget a fenti szűrőből, majd próbálkozzon újra."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon másik felhasználót a fenti szűrőből, majd próbálkozzon újra."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adott hozzászólások"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott hozzászólások"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden ellenvetés"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott ellenvetések"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indított ellenvetések"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott felmérési visszajelzések"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adott felülvizsgálatok"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenység"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenységlista váltása"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(1)), "/", _interpolate(_list(0)), " felülvizsgálva"]), _normalize([_interpolate(_list(1)), "/", _interpolate(_list(0)), " felülvizsgálva"]), _normalize([_interpolate(_list(1)), "/", _interpolate(_list(0)), " felülvizsgálva"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Újraindul: ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " maradt"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás beállítása"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízáslista váltása"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nem lehet coachingalkalmat létrehozni ", _interpolate(_list(0)), " és ", _interpolate(_list(1)), " között, mivel jelenleg nem ugyanazon a munkaterületen vannak."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Már léteznek coachingalkalmak ", _interpolate(_list(0)), " és ", _interpolate(_list(1)), " között. Az alkalom eléréséhez vegye fel vele a kapcsolatot: ", _interpolate(_list(0)), "."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlott"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Új alkalom lett létrehozva ", _interpolate(_list(0)), " és ", _interpolate(_list(1)), " között."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach kiválasztása"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coacholt személy"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coacholt személy kiválasztása"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom létrehozása"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom létrehozása"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részletek"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom hozzáadása"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom neve"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlott"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárt"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábbi"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közelgő"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom létrehozása"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók alkalmak"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos felülvizsgálati idő"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítás"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek adatok a kiválasztott időszakra vonatkozóan"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rögzített elem hozzáadva az alkalomhoz. ", _interpolate(_named("buttonStart")), "Megnézem", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás…"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadva"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott időszak rögzített elemeinek végére ért"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nézze meg a rögzített elemek működését ebben a ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cikkben"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Úgy tűnik, nem rögzített semmit ", _interpolate(_list(0)), " vonatkozásában. A rögzített elemek használhatók a személyes megbeszélésekre való felkészüléshez, vagy a dolgok egy későbbi alkalomra való mentéséhez."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a coacholt személy"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek hozzáadott rögzített elemek"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Itt van az összes rögzített elem, amit létrehozott ", _interpolate(_named("name")), " alatt. Az alkalmakhoz hozzáadott rögzített elemek ", _interpolate(_named("bold")), " a másik résztvevővel."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azonnal megosztásra kerülnek"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Úgy tűnik, hogy a kijelölt időszak alatt ", _interpolate(_list(0)), " nem kapott semmilyen visszajelzést beszélgetés-felülvizsgálatról vagy a CSAT-ról."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elérhető visszajelzések"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott időszak hozzászólásainak végére ért"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzések"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutatók"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " számai"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új műveletelem"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("user")), " teljesítette ekkor: ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gépeljen új műveleti elem hozzáadásához"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi lett az eredménye ennek a találkozónak? Mik a következő lépései?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műveleti elemek"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hozta létre ekkor: ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["törli az alkalmat"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ezzel véglegesen ", _interpolate(_named("deleteSession")), ". A résztvevők elveszítik a hozzáférést az alkalomhoz, beleértve a tartalmát és a privát megjegyzéseket."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alkalom létrehozva ekkor: ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utoljára ", _interpolate(_named("name")), " szerkesztette ekkor: ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszédtémák és megjegyzések"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés frissítve"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom elhagyása"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott felhasználók hozzáférést kapnak az alkalom részleteihez mostantól kezdve."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók kiválasztása"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztva"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom megosztása"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Már nem lesz része ", _interpolate(_list(0)), " és ", _interpolate(_list(1)), " alkalmának."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " többé nem lesz része ennek az alkalomnak."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " most már hozzáfér ehhez az alkalomhoz."]), _normalize([_interpolate(_named("n")), " új felhasználó most már hozzáfér ehhez az alkalomhoz."])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom törlése"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden alkalom törlése"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom törlése"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a coach"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Itt fognak megjelenni a megjegyzések, amelyeket ", _interpolate(_list(0)), " ad hozzá."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadhatja a rögzített elemeket beszédtémaként a jobb oldali panelről"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak Ön által látható jegyzetek…"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes jegyzetek"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát jegyzet frissítve"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 nap"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coacholt személy"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műveleti elemek"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legutóbbi alkalom"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárt"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak áttekintése"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes megtekintése"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én: Coach"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én: Coacholt személy"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden alkalom"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztott alkalmak"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbáljon meg szűrőt váltani a műveleti elemek megtekintéséhez."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek műveleti elemek"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műveleti elemek"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott és megtekintett felülvizsgálatok"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott · Megtekintett"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak utáni folyamat"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, a munkatársak nem kaptak visszajelzést."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze, hogy helyes nevet adott meg a felhasználó számára"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó nem található"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elérhető adatok"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utóbbi 30 nap – BMP"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ezzel végleg <b>töröl minden alkalmat</b> (2) vele: <b>", _interpolate(_named("name")), "</b>. A résztvevők elveszítik a hozzáférést az összes alkalomhoz, beleértve a tartalmaikat és a privát megjegyzéseket."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes alkalom végére ért"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A coachingalkalom egy csoportos tevékenység egy mentor és egy coacholt személy között a teljesítmény, a lehetőségek, illetve a rövid és hosszú távú célkitűzések megvitatására."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmak között mind a coach neve, mind a coacholt személy neve alapján kereshet."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók alkalmak"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek létrehozott alkalmak"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingelőzmények törlése"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingelőzmények törlése "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legutóbbi alkalom"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden alkalom"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coacholt személy"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés készként"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés nem készként"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés név szerint"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átnevezés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalomlista váltása"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem ütemezett"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz erre az üzenetre"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejár kalibrációs alkalom. Nem hagyhatók további felülvizsgálatok."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már hagyott egy felülvizsgálatot ehhez a beszélgetéshez."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés felülvizsgálata már nem lehetséges, mivel az alkalomhoz tartozó lejárati dátum elmúlt."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a beszélgetésnek nincsenek felülvizsgálatai"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel bezárása"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs felülvizsgálat hagyása"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSZLETEK"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖSSZEGZÉS"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÁTIRAT"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ". beszélő"])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " mp"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csend"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélő szerkesztése"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenet felülvizsgálata"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a lejátszott üzenethez"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélők"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átirat"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület-felhasználók"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Először adja hozzá a kapcsolatot a szűrők létrehozásához"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek robotok"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Létrehozta ", _interpolate(_named("agentName")), " ekkor: ", _interpolate(_named("time"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utoljára ", _interpolate(_named("agentName")), " frissítette ekkor: ", _interpolate(_named("time"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Úgy tűnik, nincsenek szűrői. Az oldal eléréséhez először létre kell hoznia egy szűrőt. Kezdésképpen a saját beszélgetései megtekintéséhez használja ezt: ", _interpolate(_named("recommendedFilter")), ". Hozza létre ezt a szűrőt alább a folytatáshoz."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az elmúlt 30 napban"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dátumtartomány nélkül létrehozott szűrőkhöz hozzá lesz adva a „Létrehozva az elmúlt 30 napban” feltétel"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szűrő nem érhető el ebben a csomagban, és az eredmények megjelenítésekor nem lesz figyelembe véve."])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időtúllépés a szűrőbeállítások lekérésekor. Próbálkozzon újra."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a szűrésről"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember = bejelentkezett személy"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " talált beszélgetés"]), _normalize([_interpolate(_named("count")), " talált beszélgetés"]), _normalize([_interpolate(_named("count")), " talált beszélgetés"])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem elérhető a jelenlegi csomagban. Frissítsen a funkció használatához."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel hozzáadása"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozó:: Zendesk QA AI"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átalakítás megbízássá"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő létrehozása"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő létrehozása"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni mezők"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltételek"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szűrőbeállításokat az Ön egyedi beállítása szerint töltjük be. Ez néhány percet vehet igénybe a kezdeti betöltéskor."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon leíró nevet a szűrőnek"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő neve"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség kiválasztása"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő szerkesztése"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások mentése"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elérhető értékek"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés…"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érték kiválasztása"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Láthatóság"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak aktív alkalmak ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régebbi alkalmak megjelenítése ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárat dátuma"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs alkalom szerkesztése"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom létrehozása"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs alkalom létrehozása"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalom alatt adott összes kalibrációs felülvizsgálat véglegesen törlődik."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalom törlése"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("sessionName")), " kalibrációs alkalom törlése"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lettek alkalmak létrehozva"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek aktív alkalmak"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott alkalmak"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek aktív alkalmak"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő hozzáadása"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát szűrő létrehozása"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános szűrő létrehozása"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő hozzáadva"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szűrő véglegesen törlődik."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő törlése"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " szűrő törlése"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden ellenvetés"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzám rendelt ellenvetések"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általam létrehozott ellenvetések"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott ellenvetések"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlatok"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek hozzáadott szűrők"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott privát szűrők"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott nyilvános szűrők"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrők"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát szűrők"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános szűrők"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Szeretné ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menteni"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez egy ideiglenes szűrő nézete."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő elkészítése…"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő frissítve"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggések"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni AutoQA-kategória"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utoljára frissítve: ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonyolult szavak"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az automatikus felülvizsgálatok dinamikusan frissülnek a jegyváltozások alapján"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés a beszélgetésben"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszú mondatok"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs előrejelzett automatikus pontszám"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók hibák"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonyolult szavak megjelenítése"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszú mondatok megjelenítése"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő beszélgetés"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategória AutoQA általi értékelése sikertelen volt, elégtelen kontextus miatt. Manuális felülvizsgálat ajánlott."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fedezze fel az automatizált beszélgetés-összefüggéseket a felülvizsgálati folyamat egyszerűsítéséhez. Emelje ki a fontos beszélgetéseket, és mutassa be a pozitív példákat a csapatának."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Hívás]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijavítva"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölve"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " elfogadta az ellenvetést"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ellenvetés elküldve ", _interpolate(_named("agentName")), " számára"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarázza el, miért gondolja, hogy ez a felülvizsgált személy helytelen címzettje ennek a visszajelzésnek"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás írása és új felülvizsgált személy beállítása"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás írása"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarázza el, miért hiszi, hogy ezeket a pontszámokat tisztességtelenül értékelték (kötelező)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás írása (kötelező)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarázza el, miért utasítja el ezt az ellenvetést"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás írása"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés továbbküldése:"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés továbbküldése:"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés továbbküldése:"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új felülvizsgált személy kiválasztása"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetési visszajelzés"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetési visszajelzés"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy módosítása"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés továbbküldése:"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés ehhez a felülvizsgálathoz"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem értek egyet a pontszámokkal"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a felülvizsgálatot nem nekem szánták"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó kiválasztása"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " részben elfogadta az ellenvetést"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " elutasította az ellenvetést"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadva"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részben elfogadva"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítva"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ellenvetést indított: felülvizsgálat"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ellenvetést indított: helytelen felülvizsgált személy"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nyitott ellenvetést meg kell oldani ahhoz, hogy egyéb műveleteket lehessen végezni a felülvizsgálaton."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadás"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új pontszámok kiválasztása"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés törlése"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan el kívánja vetni ezt az ellenvetést?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés törlése"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés-megoldási idő"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítás"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új méltányos pontszámok kiválasztása (nem kötelező)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés felülvizsgálata"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen felülvizsgált személy"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés elfogadása"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés elfogadása"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetési visszajelzés"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen felülvizsgált személy"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetési visszajelzés"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés elutasítása"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés elutasítása"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ellenvetés megtekintése"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztve"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), ": ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["címzett: ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden készen"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelve:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írjon hozzászólást is"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a feltételes értékelőlapokról"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábban hozzárendelve:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak válasszon egyet"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találtunk ilyen nevű GIF-eket"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legyen Ön az első, aki felülvizsgálatot hoz létre."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön előfizetése jelenleg szünetel. Aktiválja újra a visszajelzés adásának engedélyezéséhez."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzétette:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez azt mutatja, hogy mennyi idő lett a beszélgetés felülvizsgálatára szánva"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó kiválasztása"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hiányzik az alapvető ok a kategóriához: „", _interpolate(_list(0)), "”"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória értékelése vagy hozzászólás írása"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás…"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji hozzáadása"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összecsukás"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás mező kibontása"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás mező összecsukása"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formázás elrejtése"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel elrejtése"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formázás megjelenítése"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel megjelenítése"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat küldése"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kategória értékelése"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább egy kategória értékelése"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés mentése későbbre"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés későbbre"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő megbízás"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés cseréje ebben a megbízási ciklusban"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés felülvizsgálata"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati alapérték"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás törlése"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás törölve"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törli ezt a hozzászólást?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás szerzője"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a hashtag törölve lett vagy át lett nevezve."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mondja el nekünk, hogy mi tetszett, vagy mi lehetett volna jobb…"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltételes"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel végleg töröl minden hozzászólást."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás törlése"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Üzenet]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " még nem látta ezt a visszajelzést"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " látta ezt a visszajelzést"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábbi tag"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF-ek keresése…"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomja meg az Enter billentyűt egy új hashtag létrehozásához."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyéni hashtageket a rendszergazda zárolta."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használja ezt az esetek könyvjelzővel való ellátásához és későbbi kiszűréséhez"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés alapértékként"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenet visszajelzése"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő beszélgetés"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek visszajelzések"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elkészült felülvizsgálatok"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy felülvizsgálatot alább a kezdéshez."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem maradtak további felülvizsgálatok"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs értékelve"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző kijelölt szakember"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző beszélgetés"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás alapértékként"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz erre a felülvizsgálatra"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati oldalsáv elrejtése"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez véglegesen törli a felülvizsgálatot és az összes hozzáadott hozzászólást."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat törlése"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat törlése"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat törölve"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " törölte a felülvizsgálatot"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kész!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelje hozzá saját magához a feladatot, hogy felülvizsgálatot hozzon létre hozzá"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már létrehozott egy felülvizsgálatot ehhez a felhasználóhoz."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Felülvizsgálatot írt ", _interpolate(_list(0)), " számára"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjeleníthető kategóriák ehhez a beszélgetéshez."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az önvizsgálatok nem érhetők el ennél a munkaterületnél."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés mellőzése"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné menteni ezt a felülvizsgálatot vázlatként?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat elvetése"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elvetés"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati oldalsáv megjelenítése"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker kis hibákkal"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldés"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még zajlik a számok feldolgozása…"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás írása"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenet felülvizsgálata"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saját magának felülvizsgálata"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E beszélgetés felülvizsgálata"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerző"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek felülvizsgálható felhasználók"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok letiltva"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található felhasználó"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevők"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület-felhasználók"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés…"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó kiválasztása"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 felülvizsgálat"]), _normalize([_interpolate(_named("count")), " felülvizsgálat"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új értékelések kiválasztása"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterület kezelője nem engedélyezte az önvizsgálatokat."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomja meg az Enter billentyűt a rögzített elem címkézéséhez"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációk"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utóbbi 30 nap"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csillagozott"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs alkalom létrehozása"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlatok"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggések"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összefüggések vizuálisan ábrázolják az ügyfélszolgálat adatait. Az interaktív diagramok használatával megismerheti, hova érdemes összpontosítani a QA-erőfeszítéseit, felfedezheti a vakfoltokat és megtalálhatja a felülvizsgálathoz megfelelő munkatársakat a teljesítményük alapján."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " azonosítója"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("ticketSource")), " URL-je"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fordítás ", _interpolate(_named("language")), " nyelvre"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás a következőhöz:"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem hozzárendelt"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg megtekinti"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most vizsgálja felül"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a beszélgetés el lett távolítva, és már nem elérhető."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett beszélgetés nem létezik a Zendesk QA szolgáltatásban."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden rögzített elem ezzel a beszélgetéssel"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalomban"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés eltávolítása"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha eltávolít egy beszélgetést egy alkalomból, akkor törölve lesz minden felülvizsgálat, amit arra adtak."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beszélgetés eltávolítása innen: ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek kalibrációs alkalmak létrehozva."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a beszélgetés hozzá lett adva az alkalomhoz"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a beszélgetés el lett távolítva az alkalomból"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás/eltávolítás kalibrációs alkalomhoz/alkalomból"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erre a beszélgetésre mutató hivatkozás másolása"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs megjeleníthető beszélgetés"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél e-mail-címe"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél azonosítója"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél neve"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külső űrlap neve"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVI"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első válaszolási idő"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritás"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési hozzászólás"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elégedettségi pontszám"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címkék"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMI"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első megoldási idő"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredeti"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés…"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az üzenet nem létezik e beszélgetés alatt, vagy törölték"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs tárgy"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs megjeleníthető beszélgetés"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található beszélgetés"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredeti beszélgetés megnyitása"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lehet hivatkozni a beszélgetésre – hiányzó URL"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevők"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek résztvevők"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés rögzítése"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a felülvizsgálatok olvasásához"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a felülvizsgálathoz"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés böngészőbővítményen keresztül létrehozva"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél-elégedettségi felmérés megtekintése"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés megtekintése"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív hangulatú üzenet"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív hangulatú üzenet"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítás angolra"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredeti megjelenítése"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés törlése"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A beszélgetés törlése végleges. Emellett törli az összes kapcsolódó adatot, például a felméréseket, az átiratokat és a felülvizsgálatokat a Zendesk QA-ből, így az irányítópultokról is.", _interpolate(_named("lineBreak")), "Ezt a beszélgetést továbbra is elérheti a Zendesk Supportban, vagy az Önök által használt más ügyfélszolgálati szoftverben."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A beszélgetés törlése végleges. Emellett törli az összes kapcsolódó adatot, például a felméréseket, az átiratokat és a felülvizsgálatokat a Zendesk QA szolgáltatásból, így az irányítópultokból is. Ha a beszélgetés több munkaterülethez tartozik, mindegyikből törlődik.", _interpolate(_named("lineBreak")), "Ezt a beszélgetést továbbra is elérheti a Zendesk Supportban vagy az Önök által használt más ügyfélszolgálat szoftverben."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés törlése"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés törlése a Zendesk QA-ből"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a beszélgetés eszkaláció miatt hiányos. Ugorjon a ", _interpolate(_named("optionsUrl")), " oldalra a címkék érvényesítése érdekében. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a beszélgetés eszkaláció miatt hiányos. Forduljon a rendszergazdához a címkék érvényesítése érdekében. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok > Speciális beállítások"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a címkékről"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárt"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függőben"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megoldva"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kijelölt szakember módosítva erre: ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem hozzárendelt"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " makró ", _interpolate(_named("author")), " által alkalmazva"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A beszélgetés értékelést kapott: ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " felhasználó kitakarta a beszélgetést"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Állapot módosítva erre: ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Címkézve ezzel: ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Csapat módosítva erre: ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " szundit állított be a beszélgetésre ennyi időre: ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " megszüntette a beszélgetés szundiját"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A robot beleragadt egy ciklusba, és egymás után ismétel egy üzenetet"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot ismétlődése"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotismétlődés, amikor ugyanaz az üzenet egyszer vagy többször ismétlődik egymás után"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyező kifejezések találhatók"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Egy átlagos munkatárs ", _interpolate(_named("percent")), "-kal hatékonyabban kezeli a beszélgetést, mint a robot"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotkommunikáció hatékonysága"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A robot ", _interpolate(_named("percent")), "-kal hatékonyabban kezelte a beszélgetést, mint egy átlagos munkatárs"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotkommunikáció hatékonysága"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésből hiányzik az a szükséges, megfelelőségi és átláthatósági szempontból kötelező nyilatkozat, amely tájékoztatja a résztvevőket a felvételkészítésről"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó felvételkészítési nyilatkozat"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A szolgáltatásiszint-szerződés (SLA) meg lett sértve a következőnél: ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA megsértve"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az SLA-szabály metrikája meghaladta a célidejét"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfelek elvesztésének jelei. Az ügyfél fontolóra vette a váltást, vagy megígérte, hogy elválnak az útjaik."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélvesztési kockázat azonosítva"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A beszélgetésben a beállított ", _interpolate(_list(0)), " mp-es küszöbértéknél hosszabb csendes időszakok vannak jelen"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 előfordulás"]), _normalize([_interpolate(_named("count")), " előfordulás"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " mp a leghosszabb"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél magasabb szintű segítséget keresett"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszkaláció kérelmezve"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél kifejezte rendkívüli háláját, vagy nagyon elégedett volt a kapott támogatással"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelkedő szolgáltatásnyújtás"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél vagy a munkatárs kifejezetten nyomon követést kérelmezett"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követési kérelem"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél elégedetlenséget fejezett ki"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív hangulat"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés szokatlan vagy atipikus volt, és több „pingpongozás” történt a megoldás eléréséhez"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiugró adatok észlelve"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél elégedettséget fejezett ki"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív hangulat"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazható"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítás nem alkalmazhatóként"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utoljára ", _interpolate(_named("author")), " szerkesztette ekkor: ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem alkalmazható"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " által ekkor: ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy okot (nem kötelező)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek összefüggések észlelve"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat hozzáadása"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztassa az ügyfélszolgálatot a fiókjához, és hozzon létre legalább egy szűrőt a beszélgetések listájának megtekintéséhez."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjeleníthető beszélgetések"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület beállításainak megtekintése"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozva"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utoljára frissítve"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítás"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Egy nap múlva)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Ebben a hónapban)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Ezen a héten)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés azonosító szerint"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keresési eredmények ehhez: ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mások által felülvizsgálva"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólással rendelkezik"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legújabb"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legrégebbi"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véletlenszerű"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemrég frissítve"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemrég megválaszolva"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezési mód"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvasatlan"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön által felülvizsgálva"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az importálási folyamat némi időbe telhet. E-mailt küldünk, ha befejeződik."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA importálja a beszélgetéseit"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Korlát: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáadáshoz kattintson a kalibráció ikonra a beszélgetésnézet fejlécében."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt nincs miről jelenteni. Próbálja meg újra egy másik szűrővel vagy rendezési lehetőséggel."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjeleníthető kiugró adatok."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keressen egy releváns beszélgetést, és kattintson a csillag ikonra a fejlécben, hogy hozzáadja a könyvjelzőkhöz."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További beszélgetések hozzáadásához próbálkozzon a szűrők módosításával, vagy hívjon meg más ügyfélszolgálati munkatársakat."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs korlát"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső megjegyzés"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épp most töltjük be a beszélgetéseket. Lehetséges, hogy bizonyos tartalmak továbbra is hiányoznak."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő felhasználó"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található felhasználó"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület tagjai"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző felhasználó"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek hozzáadott beszélgetések"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lettek beszélgetések hozzáadva"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ide kerülnek a befejezetlen felülvizsgálatok. Olvassa el újra, módosítsa és küldje el a következő 30 napban."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek felülvizsgálati vázlatok"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek csillagozott beszélgetések"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjeleníthető beszélgetések"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs találat"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérés a kiválasztott üzenethez"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legkevesebb válasz"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legtöbb válasz"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulat"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetéslista váltása"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felhasználó"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROBOT"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hívás megválaszolva ekkor: ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hívás befejezve ekkor: ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hívás kezdeményezve ekkor: ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön által felülvizsgálva"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A hangfájl betöltése sikertelen volt. Győződjön meg róla, hogy bejelentkezett ide: ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél-elégedettségi felmérési hozzászólás"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a hang betöltésekor"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás szerzője"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem történt hívás"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belehallgatás az ügyfélszolgálatban"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belehallgatás az Aircallban"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejátszási sebesség"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívás felülvizsgálata"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszer"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átirat"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magas"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacsony"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közepes"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem Zendesk QA-felhasználó"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA  automatikusan rangsorolja a munkatársakat a havi átlagos CSAT-értékük alapján. Egyetlen kattintással megtalálhatja azokat a beszélgetéseket, amelyeket olyan munkatársak kezeltek, akik CSAT-értéke alacsonyabb a társaikénál."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangsor CSAT szerint"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közepes"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magas"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacsony"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalacsonyabb"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legmagasabb"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem Zendesk QA-felhasználó"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy jegy"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA automatikusan teljesítménycsoportokba rangsorolja a munkatársakat az alapján, hogy hány beszélgetést kezelnek havonta. Egyetlen kattintással megtalálhatja azokat a munkatársakat, akik mennyisége alacsonyabb a társaikénál."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangsor mennyiség szerint"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs felülvizsgálva"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat állapota"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacsony"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magas"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semleges"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem Zendesk QA-felhasználó"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulat"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA automatikusan rangsorolja a munkatársakat a beszélgetésekben észlelhető átlagos hangulat alapján. Egyetlen kattintással megtalálhatja azokat az interakciókat, amelyeknél a munkatársak frusztráltabb ügyfelekkel foglalkoznak, mint a társaik."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangsor hangulat szerint"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs észlelve"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Észlelve"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiugró adatok"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések, amelyek az Ön figyelmét igénylik. Gépi tanulási modellünk azonosítja a komplex vagy atipikus interakciókat további felülvizsgálat céljából."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiugró adatok"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválaszthatja felülvizsgálat céljából azokat a beszélgetéseket, amelyeknél az ügyfelek örömöt vagy frusztrációt mutattak. A legördülő lista használatával kiválaszthatja a beszélgetésekben keresni kívánt hangulattípust. Húzza a szűrőattribútumot legfelülre, ha szeretné látni a kiválasztott hangulat arányát az összes interakcióban."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulat"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulat"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat állapota"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs felülvizsgálva"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA automatikusan észleli a beszélgetések nyelveit. A legördülő lista használatával kiválaszthatja a felülvizsgálni kívánt, adott nyelvű beszélgetéseket. Húzza a szűrőattribútumot legfelülre, ha szeretne áttekintést kapni az ügyfélszolgálatban használt összes nyelvről."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés-összefüggések"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az összefüggések révén ", _interpolate(_named("paragraph_2_item")), ", mint a szokásos beszélgetésszűrőkkel. Vizuális áttekintést kaphat az ügyfélszolgálatai összes adatáról, és olyan összefüggéseket tárhat fel, mint például a következők:"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a beszélgetés-összefüggések használatáról"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerje meg a konkrét beszélgetési attribútumok relatív fontosságát másokkal szemben"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Találja meg a legjobban és legalacsonyabban teljesítő munkatársakat a beépített munkatársi teljesítmény-rangsorolással"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A ", _interpolate(_named("paragraph_1_item")), " erőteljes interaktív adatbányászati eszközt alkotnak, amely segít megtalálni azokat a beszélgetéseket, amelyeket leginkább felül kell vizsgálni."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sokkal többre lehet képes"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A Beszélgetés és Munkatárs diagramok ", _interpolate(_named("paragraph_3_item_1")), " – ha valamelyikben frissíti a szűrőket vagy a szegmentált diagramszeletekre kattint, automatikusan frissül a beszélgetéslista. ", _interpolate(_named("paragraph_3_item_2")), ", ha szeretné felfedezni a különböző elemek részletezéseit vagy arányait."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["össze vannak kapcsolva"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezze át a szűrőattribútumokat"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A beszélgetés-összefüggések kizárólag ", _interpolate(_named("paragraph_4_item")), " jelenítenek meg. Automatikusan eltávolítjuk azokat az interakciókat, amelyeknek nincs valós felülvizsgálati értéke; ilyenek például az automatikusan generált üzenetek, a spamek, a robotválaszok, illetve az olyan üzenetek, amelyekben nincs megfelelő párbeszéd a támogatási képviselővel."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiszta és lezárt beszélgetéseket"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit látok itt?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), "/", _interpolate(_named("count"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("count"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("count"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "/", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beolvasás sikertelen volt"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " n. a."])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatársak"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " a teljes értékhez képest)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újratöltés"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggések"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De biztosak vagyunk benne, hogy nagyszerű lesz, amikor megérkezik."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni felmérés"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lettek felmérési visszajelzések létrehozva"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nem érhető el a felmérési visszajelzési pontszám…"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelés"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megkapva"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzéscímke"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés-hozzászólási címkék"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátumtartomány-szűrő alkalmazva"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " válasz"]), _normalize([_interpolate(_named("count")), " válasz"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálat"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mélyebben megtudhatja, miért kapott bizonyos ", _interpolate(_list(0)), "-pontszámot, ha beállít okokat a felmérési beállítások alatt."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Még nincsenek ", _interpolate(_list(0)), "-okok"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felmérési okokról"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések címkével:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címkék"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " a következő szerint:"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okok"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címkék"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás-kategória"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyType")), "-okok"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Előrejelzett ", _interpolate(_named("surveyType")), "-előremozdítók"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés forrása"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Felmérésválaszok (", _interpolate(_named("n")), ")"]), _normalize(["Felmérésválaszok (", _interpolate(_named("n")), ")"]), _normalize(["Felmérésválaszok (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs tárgy"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Beszélgetések ezzel: ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " felhasználók szerint"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változás"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszadási arány"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), "-pontszám az összes válasz összege elosztva a maximális pontszámok összegével"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél szerkesztése"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél eltávolítása"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél beállítása"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél hozzáadása"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rövid"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyon hosszú"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszú"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közepes hosszúságú"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összezavaró"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem hasznos"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz támogatás"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jó támogatás"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érzelmekben bővelkedő"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés munkatársnak"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panasz"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információmorzsák"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probléma nincs megoldva"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probléma megoldva"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív hangulat"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz kimenetel"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív hangulat"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicséret"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz termék"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérítés"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyors támogatás"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassú támogatás"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönet"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A Zendesk QA automatikusan előre meghatározott kategóriákba tagolja az Ön ", _interpolate(_list(0)), "-adatait. Így megértheti a komplex összefüggéseket és feltárhatja az okokat anélkül, hogy manuálisan kellene végigfésülnie az írásos visszajelzéseket."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden pontszám"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felmérés"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz nyelvének kiválasztása"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás mérete"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás méretének kiválasztása"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mivel a hozzászólások 25%-a csak egyetlen szóból áll, mindenképp érdemes elolvasni a hosszabb hozzászólásokat. Manuális munka nélkül megtalálhatja az átgondoltabb visszajelzéseket, ugyanis mi automatikusan elvégezzük ezt az Ön számára."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés oka"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés okának kiválasztása"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszámok"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek egyező pontszámok"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérések"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előrejelzett előremozdítók"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás-kategória kiválasztása"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordcloud-szavak"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordcloud-szavak kiválasztása"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " az utolsó időszak óta"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " beszélgetés"]), _normalize([_interpolate(_named("n")), " beszélgetés"]), _normalize([_interpolate(_named("n")), " beszélgetés"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült kiszámítani az elküldött felmérések arányát az összes beszélgetés függvényében, az adatok hiánya miatt."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött felmérések"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési tölcsér"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), "/", _interpolate(_named("n"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Előrejelzett ", _interpolate(_named("surveyType")), "-előremozdítók"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A Zendesk QA automatikusan előre meghatározott kategóriákba tagolja az Ön ", _interpolate(_list(0)), "-adatait. Így megértheti a komplex összefüggéseket és feltárhatja az okokat anélkül, hogy manuálisan kellene végigfésülnie az írásos visszajelzéseket."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás mérete"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mivel a hozzászólások 25%-a csak egyetlen szóból áll, mindenképp érdemes elolvasni a hosszabb hozzászólásokat. Manuális munka nélkül megtalálhatja az átgondoltabb visszajelzéseket, ugyanis mi automatikusan elvégezzük ezt az Ön számára."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszám"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési válaszok"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (átlag)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszszám"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP (átlag)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cél"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlag"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-tendenciák a következő szerint:"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszszám"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okok"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címkék"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előremozdítók"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-felmérés elküldésének dátuma"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-felmérés megválaszolásának dátuma"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-pontszám hozzárendelése"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatok megjelenítése ez alapján:"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés létrehozásának dátuma"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a jegy tulajdonosához"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a legtöbb válasszal rendelkező munkatárshoz"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (átlag)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " időbeli alakulása"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszszám"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " és BMP"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszszám"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezeket a szavakat tartalmazó beszélgetések:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word cloud"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT felhasználók szerint"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT áttekintése"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési tölcsér"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési válaszok"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT időbeli alakulása"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word cloud"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs csatorna"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs forrás"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs nyelv"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek okok"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek címkék"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs hozzászólás-kategória"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs munkaterület"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek elérhető adatok, mert nem hoztak létre megbízásokat, vagy nem indítottak ciklusokat. Térjen vissza később, vagy módosítsa a szűrőket."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az újdonságok felfedezése"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " elem"]), _normalize([_interpolate(_named("count")), " elem"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " beszélgetés"]), _normalize([_interpolate(_named("count")), " beszélgetés"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker kis hibákkal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siker/sikertelen"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlag"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlag (ó)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlag (p)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " értékelései"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos CSAT = [visszajelzési pontszámok összege ÷ (összes válasz száma × maximális lehetséges pontszám)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA CSAT-pontszáma 0–100% közötti skálán belül normalizálja a visszajelzési pontszámot, és figyelembe veszi a standardizált pontszámok átlagát."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A CSAT (Customer Satisfaction Score; ügyfél-elégedettségi pontszám) számszerűsíti, hogy mennyire elégedettek az ügyfelek az Ön szolgáltatásaival."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Az egyes felülvizsgálatok egyetlen beszélgetésben egyetlen személy felülvizsgálatát jelentik. Egy-egy beszélgetésnek több felülvizsgálata lehet (ha két vagy több felülvizsgáló személy felülvizsgálja ugyanazt a beszélgetést)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követheti a felülvizsgáló személy pontszámainak időbeli fejlődését."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követheti a munkatársak pontszámainak időbeli fejlődését."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felülvizsgálat"]), _normalize([_interpolate(_named("n")), " felülvizsgálat"]), _normalize([_interpolate(_named("n")), " felülvizsgálat"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A BMP (belső minőségpontszám) a beszélgetés-felülvizsgálatok eredménye."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP = felülvizsgálati pontszámok összege ÷ felülvizsgálatok száma"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagként számítja ki a rendszer, figyelembe véve a kiválasztott szűrőfeltételeknek megfelelő összes felülvizsgálati pontszámot (a kategória pontszámának súlyozott átlagát)."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kártya betöltése sikertelen volt."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az újrapróbálkozáshoz módosítsa a szűrőket."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sikerességi arány a beszélgetési felülvizsgálatok kimenetelét jelzi."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sikertelen, ha a felülvizsgálati pontszám ", _interpolate(_named("failScore")), " vagy kevesebb"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sikeres, ha a felülvizsgálati pontszám ", _interpolate(_named("passScore")), " vagy több"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megállapítása egy előre meghatározott alapérték szerint történik, ami Önre nézve a következőket jelenti:"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikerességi arány = A sikeres felülvizsgálatok összege ÷ Az összes felülvizsgálat összege"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sikerességi arány annak a százalékos arányát jelzi, hogy hány felülvizsgálat megy át sikeresen anélkül, hogy kritikus vagy nem kritikus kategóriákban sikertelenek lennének."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha például a felülvizsgáló személy ezen a napon 3 felülvizsgálatot adott, a megjelenített szám a 3 pontszám átlaga. Tekintse meg az egyes cellák kiszámításának módját ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha például a munkatárs ezen a napon 3 felülvizsgálatot kapott, a megjelenített szám a 3 pontszám átlaga. Tekintse meg az egyes cellák kiszámításának módját ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek figyelembe véve a következők: kategória súlyozása, kritikus állapot (ellentétben a pontszámok időbeli alakulásával)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mivel a kategóriák számára „n. a.” jelölés is megadható a felülvizsgálatokban, a kategóriánkénti felülvizsgálatok száma kisebb lehet, mint a felülvizsgáló személy által készített összes felülvizsgálat száma. Tekintse meg az egyes cellák kiszámításának módját"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek figyelembe véve a következők: kategória súlyozása, kritikus állapot (ellentétben a pontszámok időbeli alakulásával)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mivel a kategóriák számára „n. a.” jelölés is megadható a felülvizsgálatokban, a kategóriánkénti felülvizsgálatok száma kisebb lehet, mint a munkatársankénti összes felülvizsgálat száma. Tekintse meg az egyes cellák kiszámításának módját"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek figyelembe véve a következők: kategória súlyozása, kritikus állapot (ellentétben a pontszámok időbeli alakulásával)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " A kategóriacsoportok külön jelennek meg. Az egyes csoportok pontszáma az adott csoport ugyanazon időszakban szerzett átlagos kategória-pontszáma szerint kerül kiszámításra. Tekintse meg az egyes cellák kiszámításának módját ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés:"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek figyelembe véve a következők: kategória súlyozása, kritikus állapot (ellentétben a pontszámok időbeli alakulásával)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " A kategóriacsoportok külön jelennek meg. Az egyes csoportok pontszáma az adott csoport ugyanazon időszakban szerzett átlagos kategória-pontszáma szerint kerül kiszámításra. Tekintse meg az egyes cellák kiszámításának módját ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alapérték: ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus hibától mentes"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kritikushiba-mentesség annak a százalékos arányát jelzi, hogy hány felülvizsgálat megy át sikeresen anélkül, hogy bármely kritikus kategóriában sikertelenek lennének."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső minőségpontszám"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Egyéb” alapvető okok"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időszakos változás"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napi változás"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi változás"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heti változás"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves változás"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszámok időbeli alakulása"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti napok vagy hetek szerint."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számítás = napi vagy heti teljes pontszám osztva az összes adott felülvizsgálattal"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti napok vagy hetek szerint."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számítás = napi vagy heti teljes pontszám osztva az összes kapott felülvizsgálattal"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszámok kategória szerint"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti a felülvizsgáló személy által ebben az időszakban adott összes kategóriaértékelés kombinált átlagát."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti a munkatárs által ebben az időszakban kapott összes kategóriaértékelés kombinált átlagát."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacsony értékelések alapvető okai"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelések alapvető okai"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória-pontszámok időbeli alakulása"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemezheti, hogyan vethetők össze a kategóriák az időbeli alakulásuk szerint."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti az egyes kategóriáknál a kiválasztott napon vagy héten adott átlagos pontszámokat."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemezheti, hogyan vethetők össze a kategóriák az időbeli alakulásuk szerint."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti az egyes kategóriáknál a kiválasztott napon vagy héten kapott átlagos pontszámokat."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszámok felülvizsgálatok szerint"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivált"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus kategória"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési skála"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlag/összesen"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok kizárása"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kártyát exportál"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adottak"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["beszélgetés"]), _normalize(["beszélgetés"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["elem"]), _normalize(["elem"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felülvizsgálat"]), _normalize([_interpolate(_named("n")), " felülvizsgálat"]), _normalize([_interpolate(_named("n")), " felülvizsgálat"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["További egy megjelenítése"]), _normalize(["További ", _interpolate(_named("n")), " megjelenítése"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utóbbi 30 nap"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utóbbi 7 nap"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző hónap"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző hét"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi hónap"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi hét"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni időszak"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napok"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hónapok"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negyedévek"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hetek"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évek"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kártya betöltése sikertelen volt. Az újrapróbálkozáshoz módosítsa a szűrőket."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjelenítendő adatok."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi változás"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópultok"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További információ."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktív szűrők különböző értékelési skálákon átívelő adatokat foglalnak magukban."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kártyarögzítés ezekkel a szűrőkkel"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden irányítópult-rögzítés ezekkel a szűrőkkel"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos felülvizsgálati idő"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testreszabás"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-fájl letöltése"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kategóriák"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória-összefüggések"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagram"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hozzászólás"]), _normalize(["Hozzászólások"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mátrix"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábbi tag"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső minőségpontszám"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP (átlag)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres felülvizsgálatok"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kártya rögzítése"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult rögzítése"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelések"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Alapvető ok"]), _normalize(["Alapvető okok"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat azonosítója"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati idő"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok beállítása"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alapvető okokból kiderül, miért kapnak alacsony pontszámokat a beszélgetések"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez a kategóriához nincsenek beállítva alapvető okok"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszám"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszám/összesen"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategóriák"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelésikategória-csoportok"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriacsoport"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorrend testreszabása"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák csoportosítási szempontja"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes kijelölése"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testreszabás"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok kizárása"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok belefoglalása"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak önvizsgálatok megjelenítése"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrők törlése"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók hashtagek"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászóláshashtagek"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászóláshashtagek kiválasztása"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók címkék"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék keresése"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók értékelőlapok"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok kiválasztása"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek egyező kapcsolatok"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés forrása"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás kiválasztása"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További szűrők"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek egyező munkaterületek"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adott alkalmak összesen"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felhasználó"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindenki"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csoport"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek egyező csoportok."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csoport"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kártyák megjelenítése"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatok megjelenítése ez alapján:"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat létrehozásának dátuma"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés létrehozásának dátuma"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Két tizedesjegy megjelenítése"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nézet beállításai"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nézet"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes fiók"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület neve"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek munkaterületek"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenységek"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zászlók"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ételek és italok"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állatok és természet"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárgyak"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emberek és testek"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utazás és helyek"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyakran használtak"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresési eredmények"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulatjelek"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szimbólumok"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók emojik"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés megnyitása"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a feladatlistához"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), "; vagy tallózás az ", _interpolate(_named("link")), " listájában"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jelenleg a(z) ", _interpolate(_named("workspace")), " munkaterületet használja, amely nincs csatlakoztatva ehhez a doménhez. Kattintson ide, hogy csatlakoztassa most."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elérhető kapcsolatok"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Egyelőre nem tud visszajelzést adni erről: ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legyen az ügyfélszolgálata az aktív lap."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a domént még nem lehet felülvizsgálni. Ha felülvizsgálhatónak kellene lennie, értesítse a rendszergazdát."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E domén hozzáadása"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Csatlakoztatás ehhez: ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kis türelmet – a beállítás folyamatban van"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az URL lekérése sikertelen volt"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzések"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olyan eszközök, amelyek már össze lettek kapcsolva a szervezete Zendesk QA-fiókjával."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek olyan eszközök, amelyek össze vannak kapcsolva a szervezete Zendesk QA-fiókjával."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetővé teszi olyan webhelyek felülvizsgálatát, amelyeknél az URL mindig ugyanaz marad. Ehhez a rendszer egy egyedi hasht fűz hozzá a végéhez felülvizsgálat létrehozása előtt."])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókváltás"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saját domének"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olyan domének, amelyek már össze vannak kapcsolva ezzel a munkaterülettel."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alábbi domének bármely oldalát felülvizsgálhatja"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatra rendelkezésre álló domének"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bővítmény"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi URL"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Még nincs ügyfélszolgálat csatlakoztatva – navigáljon ide, hogy hozzáadjon egyet: ", _interpolate(_named("link")), "."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA böngészőbővítménye csak\na Professional és Advanced csomagokban használható."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület váltása"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy nevet"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy értéket"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni mezők"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érték"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Először látjuk ezt a beszélgetést. Adjon meg néhány részletet."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési hivatkozás:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valami, ami alapján később azonosítani tudja a jegyet"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérítés"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a kijelölt szakembert és a tárgyat egyaránt"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás…"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E beszélgetés felülvizsgálata"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem hozzárendelt"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vegye fel a kapcsolatot a felettesével, vagy váltson egy másik munkaterületre a ", _interpolate(_named("settings")), " területen."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az önvizsgálatok le vannak tiltva ennél a munkaterületnél."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viszlát!"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt az URL-t nem lehet felülvizsgálni. Navigáljon egy beszélgetéshez."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezzen be a Zendesk QA szolgáltatásba az SSO-szolgáltatóján keresztül, majd próbálja meg újra."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés SSO-val"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bejelentkezési előugró ablak megnyitása…"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés Zendesk QA-fiókjával"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várjon…"])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítés…"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincs fiókja?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs hozzáférése ehhez a beszélgetéshez. Ha kellene lennie, vegye fel a kapcsolatot a felettesével."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs hozzáférés"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze, hogy a kapcsolat aktiválva van-e a Zendesk QA szolgáltatásban"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolatok kezelése"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolathiba"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigáljon egy beszélgetéshez az ügyfélszolgálatában a folytatáshoz"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen URL"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majdnem kész az újracsatlakozás."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy pillanat"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja meg újratölteni az oldalt, hogy visszakerüljön a dolgok áramlásába"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valami hiba történt"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabadítsa fel magát az ügyfélszolgálat virtuális határai alól, és végezze el a beszélgetések felülvizsgálatát bárhonnan."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítse előfizetését a bővítmény használatához"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetés kezelése"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az ügyfélszolgálati kapcsolat nincs hozzákapcsolva ehhez a munkaterülethez"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfélszolgálata nem ismeri fel ezt a jegyet, vagy a Zendesk QA nem fér hozzá"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található a beszélgetés"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új tevékenység"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzést kapott"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres bejelentkezés"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most már használhatja az alkalmazást"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kategória értékelése"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használja ezt az esetek könyvjelzővel való ellátásához és későbbi kiszűréséhez"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés…"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek visszajelzések ehhez a beszélgetéshez"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés olvasása"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megnyitás a Zendesk QA szolgáltatásban"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt fog megjelenni minden visszajelzés és értesítés. Még nincs itt semmi…"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Továbbiak megtekintése a Zendesk QA szolgáltatásban"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenység"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés részletei"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tartalmazza az összeset ezek közül:"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az összes ezek közül:"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem tartalmazza az összeset ezek közül:"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem az összes ezek közül:"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobb, mint a munkatársé"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rosszabb, mint a munkatársé"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hamis"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["több mint"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tartalmazza bármelyiket ezek közül:"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ezek bármelyike:"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem tartalmazza bármelyiket ezek közül:"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem ezek bármelyike:"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["igaz"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megszegve"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hozzászólva"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hozzászólást kapott tőlem"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem szólt hozzá"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem kapott hozzászólást tőlem"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritikus a felülvizsgálathoz"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tartalmazza ezt:"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utóbbi 30 nap"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ezen belül:"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kezdete:"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utóbbi 14 nap"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utóbbi 24 óra"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utóbbi 30 nap"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utóbbi 7 nap"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["múlt hónap"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["múlt hét"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jelenlegi hónap"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jelenlegi hét"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eddig:"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tegnap"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["észlelve"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["létezik"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["általam csillagozott"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["általam nem csillagozott"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tartalmazza:"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke:"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["több mint"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kezdete:"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kevesebb mint"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eddig tart:"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke nem:"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke:"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke nem:"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negatív"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs megszegve"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem összetett"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs észlelve"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem létezik"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem foglalja magában ezt:"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs megkapva"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs megtekintve"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozitív"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs felülvizsgálva"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs általam felülvizsgálva"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálva"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["általam felülvizsgálva"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megkapva"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kritikus a felülvizsgálathoz"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tartalmazza ezt:"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke:"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["több mint"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kezdete:"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kevesebb mint"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eddig tart:"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke:"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke nem:"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem tartalmazza ezt:"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke nem:"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megtekintve"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a hozzászólásom válaszokat kapott"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["igen"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni kategóriák"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni kiemelés"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel keresése"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálat"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információk"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutatók"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevők"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat és visszajelzések"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden feltétel megjelenítése"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összehasonlítja a robot beszélgetéskezelését az átlagos munkatársakkal"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A robot beleragadt egy ciklusba, és többször ismétli ugyanazt az üzenetet"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot válaszainak száma a beszélgetésekben"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben érintett robot típusa"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben érintett robot"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A beszélgetés tartalmazza a következő kifejezést a munkatársak üzeneteiben: ", _interpolate(_list(0))])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan azonosíthatók azok a hívások, amelyeknél hiányzik a kötelező nyilatkozat; például „A hívás rögzítve lesz” vagy hasonló."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben érintett személy vagy robot"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés során felülvizsgált munkatárs vagy robot"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél magasabb szintű segítséget keresett"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben észlelt pozitív és negatív hangulat"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-elemzéssel azonosítja a beszélgetések legaktívabb munkatársát"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs udvariasan lezárta-e a beszélgetést"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs megérti és elismeri-e az ügyfelek érzéseit"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs nyelvtani, helyesírási és stílusbeli hibáit"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést a tipikus üdvözlőkifejezések tekintetében"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs érti-e az ügyfél kérdését vagy aggályát"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy milyen könnyen érthető egy szöveg, figyelembe véve a szavak komplexitását és a mondatok hosszát"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést egy felajánlott megoldás tekintetében"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs hangnemét a beszélgetés során"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfelek elvesztésének jelei. Az ügyfél fontolóra vette a váltást, vagy megígérte, hogy elválnak az útjaik."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csoport, amelyhez a beszélgetés tartozik"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs üzenetének tartalma vagy szövege"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs válaszainak száma a beszélgetésekben"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés felülvizsgálata céljából hozzárendelt munkatárs"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hívásbeszélgetés időtartama másodpercben"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés tartalma vagy szövege"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez használt kommunikációs csatorna (pl. e-mail, csevegés)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások hozzáadva a beszélgetéshez"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a dátum, amikor hozzá lett adva egy hozzászólás a beszélgetéshez"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben észlelt pozitív és negatív hangulat"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a dátum, amikor az ügyfél válaszolt az ügyfél-elégedettségi felmérésre"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetési felülvizsgálatot vitatták-e"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél által a beszélgetés kezdeményezéséhez használt csatorna"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélválaszok száma a beszélgetésekben"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés utolsó üzenetének dátuma"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben érintett felhasználó szerepe vagy típusa"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs és az ügyfél közötti csend meghaladta a küszöbértéket"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél magasabb szintű segítséget keresett"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél kifejezte rendkívüli háláját, vagy nagyon elégedett volt a kapott támogatással"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél vagy a munkatárs kifejezetten nyomon követést kérelmezett"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hanghívásban folytatott teljes beszélgetés szövege vagy jegyzőkönyve"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetést csillagozottként megjelölték-e"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzászólásokban tartalmak kategorizálására használt hashtagek"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés nyelve, AI által észlelve"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés utolsó üzenetének dátuma"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát jegyzetek száma a beszélgetésekben"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenetenkénti átlagos karakterszám a beszélgetésekben"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfeleknek szánt válaszok száma a beszélgetésekben"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olyan résztvevő, akinek az adatai nyilvánosan láthatók"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenetenkénti átlagos szószám a beszélgetésekben"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés értékeléséhez használt kategóriák"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés felülvizsgálati folyamatának állapota"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy résztvevő vagy a beszélgetésben érintett személy"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez társított e-mail"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintették-e a felülvizsgálatot"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személy, aki felmérte a beszélgetést"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés utolsó felülvizsgálatának dátuma"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés során felülvizsgált munkatárs"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bármilyen konkrét hozzászólás vagy visszajelzés az ügyféltől, a beszélgetéssel való elégedettségével kapcsolatban"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél elégedettségi pontszáma a beszélgetéshez"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél-elégedettségi pontszám üzenetenként a beszélgetés során"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés felülvizsgálatához használt értékelőlap"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megadott SLA-k meg lettek sértve"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azon forrás vagy kapcsolat típusa, ahonnan a beszélgetés ered"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés szokatlan vagy atipikus volt, és több „pingpongozás” történt a megoldás eléréséhez"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés tartalmához kapcsolódó felmérési kérdés"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez társított felmérés címe vagy neve"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés felülvizsgálata céljából hozzárendelt munkatárs"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez társított márka vagy konkrét termék/szolgáltatás"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez használt kommunikációs csatorna (pl. e-mail, csevegés)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés lezárásának vagy megoldásának dátuma"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés kezdeti létrehozásának dátuma"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés kezdeti létrehozásának dátuma"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a dátum, amikor az ügyfél válaszolt az ügyfél-elégedettségi felmérésre"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés rövid leírása vagy összegzése"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez csatlakoztatott külső hivatkozási azonosító"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az a mappa vagy konkrét csoportosítás, amelybe rendszerezve van a beszélgetés"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés strukturálásához használt űrlap vagy sablon"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez csatlakoztatott külső hivatkozási azonosító"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A postafiók vagy e-mail-fiók, amelyen keresztül a beszélgetés érkezett"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez társított telefonszám"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéshez hozzárendelt prioritási szint"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben szereplő válaszok teljes száma"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés jelenlegi állapota"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés jelenlegi állapota"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés tárgya vagy témája"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs válaszainak száma a beszélgetésekben"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés kategorizálása céljából hozzárendelt címkék"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés típusa vagy kategóriája"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés utolsó frissítésének dátuma"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés utolsó frissítésének dátuma"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azon médium vagy csatorna, amelyen keresztül a beszélgetés zajlott"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetési átirat tartalma"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AI-elemzésen keresztüli felülvizsgálatra nem jogosult beszélgetések"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésekben részt vevő munkatársak száma"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintették-e a beszélgetést"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bármely üzenet"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotkommunikáció hatékonysága"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot ismétlődése"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotválaszok száma"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot típusa"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívás iránya"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél üzenete"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó felvételkészítési nyilatkozat"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszkaláció"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívásátirat tartalma"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csillagozott"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem jogosult felülvizsgálatra"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatot végző személy"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés ezen keresztül"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyesírás és nyelvhelyesség"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs üzenete"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legaktívabb munkatárs"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatársi válaszok száma"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívás időtartama (mp)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés tartalma"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenetcsatorna"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászóláscímke"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás dátuma"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulat"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-felmérési válasz dátuma"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetés"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empátia"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélcsatorna"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélválaszok száma"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó válasz dátuma"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználótípus"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélvesztési kockázat"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszkaláció"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelkedő szolgáltatásnyújtás"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követés"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átirat"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászóláshashtag"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiugró"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megértés"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó válasz dátuma"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés privát jegyzeteinek száma"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos karakterszám"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos szószám"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános válaszok száma"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános résztvevő"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési dátum"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelés és hozzászólás"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat állapota"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvashatóság"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevő"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódó e-mail"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódó"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok száma"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat megkapva"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált értékelőlap"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat megtekintve"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat dátuma"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési hozzászólás"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT üzenetenként"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elégedettségi pontszám (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felajánlott megoldás"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás típusa"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárgy"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési kérdés"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés címe"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési márka"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márkadomén"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési csatorna"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás dátuma"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-felmérési válasz dátuma"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-felmérés elküldésének dátuma"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés leírása"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külső azonosító"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési mező"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési mappa"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési űrlap"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk-azonosító"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetéscsoport"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout-azonosító"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külső azonosító"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-azonosító"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési postafiók"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés prioritása"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés válaszainak száma"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési állapot"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés állapota"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés tárgya"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatársi válaszok száma"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címke"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címke"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés típusa"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés dátuma"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés dátuma"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk-azonosító"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangnem"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átírat tartalma"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részt vevő munkatársak száma"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintettségi állapot"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebezhetőség"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebezhetőségi állapot"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebezhetőségi életesemény"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategória"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátumhoz kapcsolódó feltétel kiválasztása"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon nevet a szűrőnek"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse ki a szűrő nevét"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezett személy"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület korábbi tagjai"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület tagjai"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("filterName")), " hozzáadása"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő alkalmazása"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább egy dátummal kapcsolatos szűrőt meg kell adni"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások kiválasztása"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség kiválasztása"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak ", _interpolate(_named("n")), " nap múlva véget ér. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong> ", _interpolate(_named("n")), "nap múlva</strong></a> véget ér."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak ", _interpolate(_named("n")), " nap múlva véget ér. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong> ", _interpolate(_named("n")), "nap múlva</strong></a> véget ér."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak ma véget ér. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próbaidőszak <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ma</strong></a> véget ér."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítson be egy előfizetést most"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " nap"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " van még hátra a próbaidőszakból"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elérte a csomag felhasználói korlátját. ", _interpolate(_named("link")), " a további tudnivalókért, illetve a csomag frissítéséhez."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson ide"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csatlakoztassa az ügyfélszolgálatát"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez lehetővé teszi számunkra, hogy behúzzuk a jegyeit, hogy felülvizsgálhassa őket."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfélszolgálat csatlakoztatása"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ön mintaadatokkal használja a Zendesk QA szolgáltatást. A mintaadatok törléséhez és a saját beszélgetések importálásához ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mintaadatok"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolja ki az alábbi hasznos információkat"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készítsen egy teljes oldalas képernyőképet a kontextus biztosításához"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A probléma megoldásához vegye fel a kapcsolatot ügyféltámogatási csapatunkkal:"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibahivatkozás:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasznos információk másolása"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók, robotok és munkaterületek"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók és munkaterületek"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jelenlegi hónap"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jelenlegi hét"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " felülvizsgálva ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " felülvizsgálva ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " felülvizsgálva ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újdonságok"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billentyűparancsok"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenység"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés velünk"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csevegés nem tölthető be (talán hirdetésblokkolót használ?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrás…"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösség"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdőlap"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adott felülvizsgálatok"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudásbázis"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókváltás"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladatok"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az alkalmazás frissült. ", _interpolate(_named("buttonStart")), "Újratöltés", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha szeretné elkezdeni a Zendesk QA használatát a munkaterülete részeként:"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tájékoztatjuk, hogy már kapott meghívást a Zendesk QA használatára egy munkaterület részeként. Ha szeretné, természetesen folytathatja, de vegye figyelembe, hogy ez létrehoz egy ", _interpolate(_named("bold")), " az Ön számára."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["külön szervezetet"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Igen, hadd folytassam ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egy új fiókkal"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztos benne?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " felkéri Önt, hogy csatlakozzon ehhez: ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozás"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs internetkapcsolat"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új rögzített elem hozzáadása"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyors navigáció"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzászólás-szerkesztő fókuszálása és kibővítése"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalsó panel elrejtése vagy megjelenítése"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigálás a következő beszélgetéshez"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredeti beszélgetés megnyitása"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigálás az előző beszélgetéshez"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lehető legmagasabb értékelés megadása az összes kategóriának"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lehető legalacsonyabb értékelés megadása az összes kategóriának"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések keverése (véletlenszerű újrarendezés)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mondja el nekünk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billentyűparancsok"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billentyűparancsok modális ablakának váltása"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elérte a(z) ", _interpolate(_named("charLimit")), " karakteres korlátot"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("name")), " kép típusa (", _interpolate(_named("type")), ") nem megengedett"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("name")), " kép mérete túl nagy (5 MB megengedett)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzászólás-szerkesztő betöltése sikertelen volt"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedje el a feltöltéshez"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kép"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozás"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozás"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szöveg"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablonok kezelése"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek sablonok hozzáadva"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető ok hozzáadása"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb, beleértve a törölt okokat"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelés oka"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utónév"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazottak száma"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatba lépünk, hogy segítsünk a fiók létrehozásában"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég neve"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég kapcsolattartási száma"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan hallott először a Zendesk QA szolgáltatásról?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna kiválasztása"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatársak számának kiválasztása"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse ki az összes kötelező mezőt"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki kell jelentenie, hogy elolvasta és elfogadja a feltételeinket és Privacy Policy (Adatvédelmi szabályzat) dokumentumunkat."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogatásvezérelt Slack-csatorna"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklám"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esemény"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerős vagy kolléga"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati piactér vagy fiókkezelő"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozó webhely"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresőmotor (Google, Bing stb.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségi média"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meséljen nekünk magáról és cégéről."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókadatok"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készen állok."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nézzen körül, ismerje meg az alkalmazást, töröljön és adjon hozzá dolgokat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók létrehozva. Hogyan tovább?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készen áll a valódi beszélgetések beemelésére?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja hozzá saját adatait"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felfedezés megkezdése"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja ki mintaadatokkal"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Így elkezdhetjük behúzni a jegyeit, hogy felülvizsgálhassa őket."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztassa az ügyfélszolgálati fiókját"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meggondoltam magam."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció megszakítása"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rögzített elem létrehozva. ", _interpolate(_named("buttonStart")), "Ugrás a rögzített elemekhez", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rögzített coachingelemek a Professional csomag ügyfeleinek érhető el."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomag frissítése"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csomag megtekintése"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentse jegyzeteit a következő kétszemélyes megbeszélésre vagy coachingalkalomra való felkészüléshez."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek coachinghoz"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szóljon hozzá, vagy adjon hozzá másokat ezzel: ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a beszélgetés törölve lett"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új rögzített elem létrehozása"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem létrehozása"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem szerkesztése"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rögzített elemek működése"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs engedélye ennek a beszélgetésnek a megtekintéséhez"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kártya rögzítése"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés rögzítése"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult rögzítése"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem elmentve"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E kártya rögzítése"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E beszélgetés rögzítése"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen irányítópult rögzítése"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzítés egy felhasználó alatt"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó kiválasztása"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön által rögzített elemeket csak Ön láthatja. A másokkal való megosztásukhoz említse meg őket a hozzászólásokban ", "@", "említés használatával."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " meghívót kapott ebbe a beszélgetésbe"]), _normalize([_interpolate(_named("agentsNames")), " meghívót kapott ebbe a beszélgetésbe"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hozzászólás"]), _normalize([_interpolate(_named("n")), " hozzászólás"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ön megemlítette a következő személyt, ő viszont nincs benne ebben a beszélgetésben: ", _interpolate(_named("participants"))]), _normalize(["Ön megemlítette a következő személyeket, ők viszont nincsenek benne ebben a beszélgetésben: ", _interpolate(_named("participants"))])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " résztvevő"]), _normalize([_interpolate(_named("n")), " résztvevő"]), _normalize([_interpolate(_named("n")), " résztvevő"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Szeretné eltávolítani a következő személyt, hogy ne férhessen hozzá ehhez a rögzített elemhez: ", _interpolate(_named("agentName")), "?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne történjen semmi"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne legyenek meghívva ebbe a beszélgetésbe"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás hozzáadása"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legyenek meghívva ebbe a beszélgetésbe"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó eltávolítása"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szál"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A műveletet nem lehet visszavonni."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel törölve lesz a rögzített elem alatti összes hozzászólás is."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " új üzenet"]), _normalize([_interpolate(_named("n")), " új üzenet"]), _normalize([_interpolate(_named("n")), " új üzenet"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rögzített elemeit csak Ön láthatja. A rögzített elemek megosztásához említsen meg valakit a hozzászólásokban ", "@", " használatával."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " rögzített ", _interpolate(_named("pinType"))])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egy irányítópultot"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egy beszélgetést"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egy megjegyzést"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítsen a rögzített coachingelemek használatához"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rögzített coachingelemek a Professional csomag ügyfeleinek érhető el."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rögzített coachingelemek a Professional csomag ügyfeleinek érhető el"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítsen a funkció használatához"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készüljön fel a kétszemélyes megbeszélésekre, vagy mentsen valamit egy későbbi alkalomra."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez véglegesen törli a rögzített elemet és minden hozzáadott hozzászólást."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem törlése"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem törlése"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa neve"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzítsen dolgokat, amelyekhez később visszatérne"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legújabb elöl"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legrégebbi elöl"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentsen egy érdekes irányítópult-tendenciát vagy beszélgetést a személyes megbeszéléseire való felkészülésként, vagy jegyezze fel egy másik alkalomra."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elem létrehozásához kattintson a fenti gombra vagy a rögzítés ikonra a Beszélgetés vagy Irányítópult nézetben."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzítés"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott rögzített elemek"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nincsenek találatok erre: ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálkozzon másik kulcsszavakkal, illetve ellenőrizze, nem írt-e el valamit."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új rögzített elem létrehozása"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenetek"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyek"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés felhasználó, kulcsszó szerint…"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betűrend"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemrég létrehozott"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemrég szerkesztett"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A legtöbb rögzített elem"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Célkitűzésem, hogy felülvizsgáljak ", _interpolate(_named("n")), " beszélgetést ", _interpolate(_named("schedule"))]), _normalize(["Célkitűzésem, hogy felülvizsgáljak ", _interpolate(_named("n")), " beszélgetést ", _interpolate(_named("schedule"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés frissítve"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés beállítása"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállíthat egy személyes célkitűzést azzal kapcsolatban, hogy mennyi beszélgetést szeretne felülvizsgálni."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minden nap"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minden hónapban"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minden héten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati célkitűzés"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SZEMÉLYES"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablonok"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák betöltése folyamatban"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatorna kiválasztása"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a hozzászólásokkal rendelkező felmérési visszajelzések megosztása"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerje el kollégáit azzal, hogy kiemeli kivételes szolgáltatásminőségüket és kiváló ügyfél-visszajelzéseiket."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív felmérési visszajelzések megosztása a Slacken"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók csatornák"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A következő feletti pontszámmal rendelkező visszajelzések automatikus megosztása:"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicsérobot-integrációk"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emlékeztetőt kaphat a hét elején, ha még egyetlen felülvizsgálatot sem végzett el."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emlékeztetők"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heti emlékeztető a felülvizsgálatok elvégzésére"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítéseket kaphat az új felülvizsgálatokról, válaszokról, említésekről és ellenvetésekről a Slacken."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Említések"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelések"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szálválaszok"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integrációk"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesülhet az új felülvizsgálatokról, válaszokról, említésekről és ellenvetésekről."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzám kapcsolódó ellenvetések"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Említések a hozzászólásokban"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újonnan kapott felülvizsgálatok"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok a szálakban"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés statisztikákkal"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentéseket kaphat az adott és kapott felülvizsgálatokról."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentések"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napi"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["havi"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heti"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naponta"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 óránként"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 óránként"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonnal"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havonta"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kikapcsolva"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hetente"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiók rendszergazdája zárolta az értesítési beállításokat."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időszak kiválasztása"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes értesítések"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (aktív)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új közösségi bejelentkezési lehetőség hozzáadása ehhez a fiókhoz:"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktív bejelentkezési mód eltávolításával a rendszer kijelentkezteti Önt. Alternatív e-mail-címével újból bejelentkezhet."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezési mód eltávolítása"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen hozzáadta a Google szolgáltatást bejelentkezési lehetőségként"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezési lehetőségek"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen hozzáadta a Slack szolgáltatást bejelentkezési lehetőségként"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google hozzáadása"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack hozzáadása"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó létrehozása"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztos benne?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mód"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E-mailt küldünk erre a címre a jelszó visszaállítása érdekében: ", _interpolate(_named("email")), "."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elsődleges e-mail-címét"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresse megerősítő e-mailünket a beérkezett üzenetei között."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mágikus hivatkozás"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ennek megváltoztatásához előzetesen módosítsa az ", _interpolate(_named("link")), "."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("email")), " e-mail-cím társítva lesz ehhez a jelszóhoz."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil frissítve"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilkép"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A legjobb eredmény érdekében négyzet alakú fényképet töltsön fel (256 × 256 vagy több képpont)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elsődleges e-mail-cím"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hét kezdete"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes beállítások"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon legalább egy opciót"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon tartalma"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon neve"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon megjelenítése ezekben:"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a sablon tartalmát"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a sablon nevét"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon létrehozása"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon létrehozása"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólássablonok létrehozásával felgyorsíthatja a felülvizsgálatokat, az elemek rögzítését és a coachingot."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használva itt:"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon törlése"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné végleg törölni ezt a sablont?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon szerkesztése"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólássablonok létrehozásával felgyorsíthatja a felülvizsgálatokat, az elemek rögzítését és a coachingot."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott sablonok"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólássablon létrehozva"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólássablon törölve"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólássablon elmentve"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok és ellenvetések"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha hozzáférést kérne, vegye fel a kapcsolatot az ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ügyféltámogatással"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található fiók"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Egyelőre használja a következőt a Zendesk QA eléréséhez: ", _interpolate(_named("link")), "."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérés a Zendeskhez"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiók migrálása sikertelen volt"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RobotQA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérések"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bmp"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mutatók"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pontszámok"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statisztika"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópult"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kalibráció"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beszélgetések"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szűrők"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adottak"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saját hozzászólások"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saját ellenvetések"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saját felülvizsgálatok"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenység"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakciók"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elkezdettek"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési visszajelzések"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tevékenység"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablonok"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jelszó létrehozása"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hitelesítő adatok"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bejelentkezés"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visszaállítás"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálati célkitűzés"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sablonok"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhasználó"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon létrehozása"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablonok"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fiók"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["számlázás"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cég"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálat"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrációk"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szervezet"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["befizetés"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helyek"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beállítások"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["előfizetés"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítések"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kategória"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérések"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ütköző üzenet található"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítsa vagy távolítása el az ütköző üzenetek egyikét"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A ", _interpolate(_named("placeholder")), " ikon használatával kizárhat érzékeny adatokat az AutoQA-ből. Például az „Üdvözlettel: János” helyett használhatja az „Üdvözlettel: ", _interpolate(_named("placeholder")), "” szöveget, hogy bármilyen nevet behelyettesítsen."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Legfeljebb ", _interpolate(_named("limit")), " sor"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabály alkalmazása erre:"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte az egyéni kategóriák 10 darabos korlátját"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elérte az egyéni kategóriák ", _interpolate(_named("limit")), " elemes korlátját"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel hozzáadása"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sor hozzáadása"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel törlése"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a munkatárs üzenete"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenkező esetben – pontszám"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkor – pontszám"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap-kategória létrehozása"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változó hozzáadásához használja a „/” karaktert"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden irány"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs üzenete nem lehet üres"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üzenet legfeljebb 300 karakter lehet"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szöveg"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden forrás"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csatorna"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden címke"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden beszélgetés"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűkítse le, hol érvényes a szabály."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkrét beszélgetések"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelési összefüggés létrehozása"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs üzenete nem lehet üres"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte az egyéni összefüggések 10 darabos korlátját"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Elérte az egyéni összefüggések ", _interpolate(_named("limit")), " elemes korlátját"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse ki az összefüggés nevét"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggés neve"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória létrehozása"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggés típusa"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semleges"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozitív"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrás a munkaterület beállításaihoz"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most nem"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen értékelési kategória használatának megkezdéséhez ugorjon a munkaterület értékelőlap-beállításaihoz, és adja hozzá egy kívánt értékelőlaphoz."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória létrehozva"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan pontozhatja a lezárt beszélgetéseket konkrét kulcsszavak vagy kifejezések azonosításával."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez nem működik a hanghívások átiratain."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendben"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pacsi! ✋ Elérte az automatizált értékelési kategóriák maximumát, azaz ", _interpolate(_named("limit")), " kategóriát. Ön igazi kategorizálási varázsló! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriahatár elérve"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg arról, hogy az összes mezőt megfelelően töltötte ki"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon nevet az értékelési kategóriának"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória neve"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új értékelési kategória"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új értékelési kategória"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan pontozhatja a lezárt beszélgetéseket konkrét kulcsszavak vagy kifejezések azonosításával. Ez nem működik a hanghívások átiratain."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan felszínre hozhatja a lezárt beszélgetéseket konkrét kulcsszavak vagy kifejezések azonosításával. Csak átiratokon működik."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan pontozhatja a beszélgetéseket konkrét kulcsszavak vagy kifejezések azonosításával, a munkatársi és a robotüzenetekben egyaránt."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen értékelési kategória használatához ugorjon a munkaterület értékelőlap-beállításaihoz, és adja hozzá egy értékelőlaphoz."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetéseket automatikusan pontozhatja az üzenetekben szereplő konkrét kulcsszavak vagy kifejezések alapján."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhetőség"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefüggés beállítása"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az összefüggés megjelenik a Kiemelésben a meghatározott feltételek alapján."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap beállítása"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a kategória használható értékelőlapokon a meghatározott feltételek alapján."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["robot"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csevegés"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hamarosan"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egyéni"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["előre meghatározott"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értékelőlap"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiemelés"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hanghívás"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások mentése"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások elmentve"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipp:"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a ", "{··}", " ikonra, hogy kizárjon neveket, cégadatokat vagy bármely más érzékeny információt az AutoQA lezárás-ellenőrzései során."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárás hozzáadása"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárás szerkesztése"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárás"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentességek hozzáadása"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség szerkesztése"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlés hozzáadása"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlés szerkesztése"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlés"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illesszen be ide szavakat sortöréssel elválasztva"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új bejegyzéshez nyomja meg az Enter billentyűt minden szó után"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazandó nyelv"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például az „Üdvözlettel: János” helyett használhatja az „Üdvözlettel: ", "{··}", "” szöveget, hogy bármilyen nevet behelyettesítsen."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamikus tartalom helykitöltője"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentességek"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 mentesség hozzáadva"]), _normalize([_interpolate(_named("count")), " mentesség hozzáadva"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mentesség már hozzáadva"]), _normalize(["Mentességek már hozzáadva: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség hozzáadása"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók mentességek"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek hozzáadott mentességek"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség (kis- és nagybetűk megkülönböztetve)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mentességek hozzáadása"]), _normalize(["1 mentesség hozzáadása"]), _normalize([_interpolate(_named("count")), " mentesség hozzáadása"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szó vagy kifejezés keresése"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség mentve"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nyelv"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden nyelv"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv keresése"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lezárás már hozzáadva"]), _normalize(["Lezárások már hozzáadva: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás hozzáadása"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók jóváhagyott lezárások"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek jóváhagyott lezárások"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás hozzáadása"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás keresése"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás hozzáadva"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás mentve"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Az űrlap duplikált bejegyzéseket tartalmaz: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Üdvözlés már hozzáadva"]), _normalize(["Üdvözlések már hozzáadva: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés hozzáadása"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók jóváhagyott üdvözlések"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek jóváhagyott üdvözlések"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés hozzáadása"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés keresése"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés hozzáadva"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés mentve"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség hozzáadása"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mentesség törlése"]), _normalize(["Mentességek törlése"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Véglegesen törli ezt a mentességet?"]), _normalize(["Véglegesen törli ezeket a mentességeket?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentesség szerkesztése"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Egy vagy több mentesség ", _interpolate(_list(0)), " karakternél hosszabb"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("maxPhraseLength")), "/", _interpolate(_named("phraseLength")), " karakter"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítve"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítette"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változó"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Határozzon meg olyan saját lezárásokat, amelyek igazodnak a márka és a szolgáltatás normáihoz. Ilyenek hozzáadása esetén az AutoQA csak a jóváhagyott lezárásokat észleli. A módosítások a jövőbeni beszélgetésekre vonatkoznak."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte a jóváhagyott lezárások felső határát, ami 100. Ha továbbiakat szeretne hozzáadni, ideje lehet újra megnézni és finomítani a meglévő lezárásokat."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárások korlátja elérve"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárások"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azok a szavak és kifejezések, amelyeket szeretne figyelmen kívül hagyatni az AutoQA-vel, mint helyesírási és nyelvhelyességi hibák. A módosítások a jövőbeni beszélgetésekre vonatkoznak."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellőzési lista"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg olyan saját üdvözléseket, amelyek igazodnak a márka és a szolgáltatás normáihoz. Ilyenek hozzáadása esetén az AutoQA csak a jóváhagyott üdvözléseket észleli. A módosítások a jövőbeni beszélgetésekre vonatkoznak."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte a jóváhagyott üdvözlések felső határát, ami 100. Ha továbbiakat szeretne hozzáadni, ideje lehet újra megnézni és finomítani a meglévő üdvözléseket."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlések korlátja elérve"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlések"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most nem"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória törlése"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véglegesen törli ezt az értékelési kategóriát?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategórialista váltása"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törölt kategória nem fog megjelenni az új beszélgetésekben és szűrőkben. Az irányítópultok és beszélgetések előzményadatai érintetlenek maradnak."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategória el lesz távolítva az összes olyan értékelőlapról, amelyhez hozzáadták."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékelőlap-kategória törlése sikertelen volt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap-kategória törölve"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap-kategória törlése"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategóriák"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az AI-modell által működtetett ", _interpolate(_named("highlight")), " az értékelőlapon maradnak, de a továbbiakban nem lesznek automatikusan pontozva"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az LLM-integráció kikapcsolása nem kapcsolja ki teljesen az AutoQA funkciót. A következő történik majd:"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["előzményadatok"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az ", _interpolate(_named("highlight")), " megmaradnak az AutoQA irányítópulton"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manuálisan hozzáadott alapvető okok"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A ", _interpolate(_named("highlight")), " megmaradnak, de az összes LLM-alapú alapvető ok el lesz távolítva"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az LLM-alapú AutoQA kikapcsolása"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kikapcsolás"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AutoQA az AI és a nagy nyelvi modellek (LLM) használatával automatikus pontozza a konkrét kategóriákat. Adatainak feldolgozása egy szigorú adatfeldolgozási megállapodásnak (DPA) megfelelően történik, és soha nem kerülnek felhasználásra bármilyen modell betanítására."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a generatív mesterséges intelligenciáról"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az AutoQA a Microsoft Azure OpenAI Service-t használja ", _interpolate(_named("automatically_scoring_categories")), ". Az Ön adatainak a kezelése szigorú DPA szerint történik, kizárólag azért, hogy biztosítani tudjuk Önnek az AutoQA-t; ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bizonyos kategóriák automatikus értékeléséhez"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soha nem használjuk fel az adatait semmilyen OpenAI-modell betanítására"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-alapú AutoQA bekapcsolva"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-alapú AutoQA kikapcsolva"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLM-alapú AutoQA"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatizált pontozás AI segítségével az AutoQA funkcióban"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Azure-alapú AutoQA-kategóriák adatfeldolgozása"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure-hozzájárulás visszavonva"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure-hozzájárulás megadva. A beszélgetések hamarosan automatikus értékelést kapnak."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Továbbra is használhatja az AutoQA-t, de erre számíthat"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az MS Azure OpenAI szolgáltatás letiltása nem kapcsolja ki az AutoQA funkcionalitását."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt az értékelőlap-kategória nézetében teheti meg."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A modell által működtetett AutoQA-kategóriák az értékelőlapon maradnak, de nem lesznek automatikusan pontozva."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A manuálisan hozzáadott alapvető okok megmaradnak. A modell által generált összes alapvető ok eltávolításra kerül."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előzményadatok az AutoQA irányítópulton maradnak."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az MS Azure OpenAI szolgáltatás általi adatfeldolgozás letiltása"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AutoQA-kategóriák szélesebb választéka."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beépített többnyelvű támogatás."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kategóriák gyors tempójú hozzáadása."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javult értékelési pontosság. A GPT-3.5 emberi szintű kontextusfelismerést mutat."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használja ki az AI-technológia legújabb fejlesztéseit."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezekhez jut hozzá"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az MS Azure OpenAI szolgáltatás általi adatfeldolgozás engedélyezése"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzájárulás megadva"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzájárulás nincs megadva"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden új felhasználó hozzá lesz rendelve ehhez a munkaterülethez"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett munkaterület"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12 órás idő (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24 órás idő (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időformátum"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hé"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Va"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégprofil frissítve"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hét kezdete"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni integráció"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférési kulcs azonosítója (access key ID) és a titkos kulcs (secret key) általában az Access management (Hozzáférés-kezelés) > Users (Felhasználók) alatt található"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-hozzáférési kulcs azonosítója"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazon Connect hozzáadása"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titkos hozzáférési kulcs módosításának megszakítása"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titkos hozzáférési kulcs értékének módosítása"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolat kizárólag egy régió meghatározását teszi lehetővé, amely általában a Location (Hely) alatt található"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS-régió"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titkos kulcs hozzáadása"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS titkos hozzáférési kulcs"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden releváns információt megtalálhat az Amazon Connect szolgáltatásban."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általában az Amazon Connect példánybeállításai alatt, illetve a Data storage (Adattárolás) oldalon található"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívásfelvételek tárolási helye"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt az Amazon Connect hozzáadása során. Ellenőrizze, hogy az összes mezőt megfelelően kitöltötte-e, majd próbálkozzon újra."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tárolási helynek gyűjtő/mappa formátumúnak kell lennie, perjellel elválasztva."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kapcsolat hozzáadásáért forduljon az ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ügyféltámogatáshoz"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden Zendesk-jegye automatikusan szinkronizálódik. Dönthet úgy, hogy kizárja a kiválasztott jegyeket."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsiai csendes-óceáni térség (Tokió)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsiai csendes-óceáni térség (Szöul)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsiai csendes-óceáni térség (Oszaka)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsiai csendes-óceáni térség (Mumbai)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ázsiai csendes-óceáni térség (Sydney)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada (középső terület)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa (Frankfurt)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa (Zürich)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa (Írország)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa (London)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiszolgáló régiója"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közel-Kelet (EAE)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiszolgáló régiójának kiválasztása"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dél-Amerika (São Paulo)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelet-USA (Virginia)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelet-USA 2. (Ohio)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyugat-USA (Oregon)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Már hozzáadott kapcsolat"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat indítása"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak azon beszélgetések szinkronizálása, amelyeknél"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["első csatorna, második csatorna"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kapcsolat egyedi azonosítója: ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hónap"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 év"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 hónap"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 hónap"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az inaktív beszélgetések törölve lesznek a kiválasztott megőrzési időszak után. A beküldött felülvizsgálatok láthatóak maradnak a Tevékenység és az Irányítópult nézetben."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megőrzési időszak"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megőrzési időszak kiválasztása"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye figyelembe, hogy ez a művelet nem vonható vissza."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megértem, hogy ezzel el lesz távolítva minden, erre a kapcsolatra vonatkozó, ügyfélszolgálathoz kapcsolódó adat a Zendesk QA szolgáltatásból, beleértve a beszélgetéseket, mezőket és címkéket."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beküldött felülvizsgálatokat ez nem érinti, és láthatóak maradnak konkrét nézetekben."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["első csoport, második csoport"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belefoglalás"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktív fejlesztés"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez az integráció még mindig ", _interpolate(_named("activeDevelopment")), " alatt áll. Néhány részlet hiányozhat (például a mellékletek vagy az egyéni mezők)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["első postaláda, második postaláda"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["első címke, második címke"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-token titkos kulcsa"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Freshdesk szolgáltatásban a Jegymezőnevek lehetőség a Beállítások → Jegymezők részben található"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Help Scout szolgáltatásban a „Custom Field Names” lehetőség a Settings → Custom fields részben található"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Kustomer szolgáltatásban a Klass-beszélgetésnevek lehetőség a Beállítások → Klasses → Beszélgetés részben található"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Live Agent szolgáltatásban az Egyéni mezőnév lehetőség a Beállítások → Egyéni mezők részben található"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wix szolgáltatásban a „Custom Field Name” lehetőség a Settings → Custom fields részben található"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mezőazonosítókat vesszővel válassza el egymástól. Ezek az azonosítók a Zendesk szolgáltatásban a Rendszergazda → Jegymezők részben találhatók"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztassa néhány ügyfélszolgálati felhasználóját az első beszélgetések felülvizsgálata érdekében. Ne aggódjon, nem értesítjük őket, hacsak nem hívja meg őket, hogy legyenek munkaterületi tagok."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen integrálva"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagok hozzáadása"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most elkezdjük behúzni a beszélgetéseit."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Domén: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ügyfélszolgálat azonosítója: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolat hitelesítése sikertelen volt"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kész. Ez az Ön új kapcsolata."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néhány kapcsolat nem frissült, vagy inaktívvá vált. Próbálja meg frissíteni ezeket a kapcsolatokat, vagy távolítsa el őket a listáról."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-kliens azonosítója"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-kliens titkos kulcsa"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hozzon létre egy új API-tokent itt: Beállítások → Fiók → API és SDK-k. A használandó átirányítási URL-cím: ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókazonosító"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kezdéshez válassza ki az ügyfélszolgálatát"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha az Intercom-fiókja az EU-ból vagy Ausztráliából való kiszolgálásra van beállítva, akkor frissítse ezt."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), " szolgáltatásba való bejelentkezéshez használt e-mail-cím"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maszkolhatja a végfelhasználó nevét, e-mail-címét és telefonszámát. A banki hitelesítő adatok már eleve maszkolva vannak minden ügyfélszolgálat-kapcsolat esetén."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek integrációk. Adjon hozzá egyet most."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezekre a szerepekre van szüksége:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat létrehozása"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kapcsolat hozzáadása"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jel törlése lehetővé teszi, hogy csak konkrét munkaterületekhez adhassa hozzá manuálisan ezt a kapcsolatot"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozás az összes meglévő munkaterülethez"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-kulcs"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-kulcs titkos kulcsa"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kapcsolat törölve"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat neve"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozva"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alább található az API-token."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-küszöbérték"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " kapcsolat törlése"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolat törléséhez gépelje be a „kapcsolat törlése” kifejezést"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat törlése"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezelheti és figyelheti a csatlakoztatott adatforrásokat."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat szerkesztése"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kizárás"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mezőneveket vesszővel válassza el egymástól"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen frissítés"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy egyedi nevet az integráció számára"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások elrejtése"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érzékeny adatmezők elrejtése"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soha ne legyenek tárolva a mellékletek URL-jei"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellékletek mellőzése"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soha ne legyen tárolva a beszélgetés tartalma (később igény szerint behúzhatja az ügyfélszolgálatból)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés mellőzése"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soha ne legyen tárolva a beszélgetés tárgya (később igény szerint behúzhatja az ügyfélszolgálatból)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárgysor mellőzése"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktív"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Az Ön ", _interpolate(_list(0)), "-aldoménje"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom-régió"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postafiókcsapat azonosítójának egyeztetése"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Intercom-csapatának numerikus azonosítója"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban…"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postafiókok"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat manuális frissítése"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyféladatok maszkolása"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek munkaterületek"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érzékeny mező neve"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érzékeny mező azonosítója"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat megújítása"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat megújítása"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolódás Salesforce-tesztkörnyezethez"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Kapcsolat létrehozása a következővel: ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások megjelenítése"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a megadott küszöbértéknél alacsonyabb vagy azzal egyenlő pontszámú beszélgetések legyenek behúzva"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-küszöbérték alkalmazása"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak azon beszélgetések szinkronizálása, amelyeknél…"])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legújabb beszélgetések lekérése"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címkék"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat frissítése"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat frissítve"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön ügyfélszolgálata"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetés lemondásához és a fiók törléséhez vegye fel a kapcsolatot az ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ügyféltámogatási csapatunkkal"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók törlése"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor töröl egy jegyet a Zendesk Supportban (vagy bármely más ügyfélszolgálatban), az nincs hatással a társított beszélgetési felülvizsgálatokra. Ezeket továbbra is megtekintheti és kezelheti."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amikor töröl egy jegyet a Zendesk QA-ben, akkor a társított beszélgetési felülvizsgálatok is törlésre kerülnek.", _interpolate(_named("line_break")), "Amikor töröl egy jegyet a Zendesk Supportban (vagy valamely más ügyfélszolgálatban), az nincs hatással a társított beszélgetési felülvizsgálatokra. Ezeket továbbra is megtekintheti és kezelheti."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelések kezelése"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgálatok törlése végleges. Ez az irányítópultokon lévő adatokra is hatással van."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes felülvizsgálat törlése"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes törlése"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes felülvizsgálat törölve"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes törlése"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha felülvizsgálatok vannak társítva a törölt jegyekhez, azokat itt fogja látni."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek felülvizsgálatok"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegyazonosító keresése"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jegyazonosító: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölt beszélgetések"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az alapértelmezett munkaterületek nem törölhetők. A munkaterület törléséhez válasszon másik alapértelmezett munkaterületet a ", _interpolate(_named("link")), "ban."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókbeállítások"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet nem vonható vissza."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eltávolításra és törlésre kerül minden adat"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Megértem, hogy ezzel ", _interpolate(_named("removeAllData")), ", ami ehhez a munkaterülethez kapcsolódik, beleértve a munkaterület-felhasználókat és a kapcsolatokat."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület törlése"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterület törléséhez gépelje be a „munkaterület törlése” kifejezést"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("workspaceName")), " munkaterület törlése"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["munkaterület törlése"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha nem teljesen biztos abban, amit csinál, konzultáljon előzetesen egy profi szakemberrel."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki, hogy az alapértelmezett felülvizsgált személy kiválasztása a legnagyobb gyakorisággal bíró munkatárs vagy a jelenlegi kijelölt szakember alapján történjen-e."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legaktívabb munkatárs"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett felülvizsgált személy"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetővé teszi a személyek számára a saját beszélgetéseik felülvizsgálatát, és engedélyezi a munkatársak számára a felülvizsgálatok elvégzését"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterület törölve lett"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterület frissítve lett"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semlegesebb osztályozást alakíthat ki, ha elrejti a többi felülvizsgáló személy által adott korábbi felülvizsgálatokat"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogulatlan osztályozás"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szín"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet nem vonható vissza."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye figyelembe, hogy ezzel el lesz távolítva minden adat, ami ehhez a munkaterülethez kapcsolódik, beleértve a munkaterület-felhasználókat és a kapcsolatokat."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület törlése"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület törlése…"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mágikus hivatkozás"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mágikus hivatkozás"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lehet letiltani a jelenlegi bejelentkezési módot"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hitelesítési módok frissítve lettek"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezheti a hitelesítési módok bizonyos típusait az összes felhasználó számára."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítések"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIÓK"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók és munkaterületek"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületeket hozhat létre és kezelhet, valamint felhasználókat hívhat meg vagy csatlakoztathat."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalon maradás"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldal elhagyása"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elhagyja ezt az oldalt, és elveti az összes módosítást?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítések"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, nincsenek importálandó beszélgetések. Kezdésképpen folytasson beszélgetéseket az ügyfélszolgálattal, majd térjen vissza ide ezek elemzéséhez."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók beszélgetések"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a rendszer-kategória nem törölhető"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória törlése"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategóriák törlése végleges. Ez törli az összes kategóriaadatot a jelentéskészítésből."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategória visszaállításához újra létre kell hoznia."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória törlése"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória keresése"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az automatikus kategóriák automatikusan feltöltik az értékelőlapot javasolt értékelésekkel, hogy időt takarítson meg a felülvizsgálat során. A manuális kategóriákat nulláról kezdve töltik ki a felülvizsgáló személyek."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó frissítés"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikus"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kézi"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empátia"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyesírás és nyelvhelyesség"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megértés"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvashatóság"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felajánlott megoldás"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangnem"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók kategóriák"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók alapvető okok"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók értékelőlapok"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálkozzon másik kereséssel vagy módosítsa a szűrőket"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategória"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategória"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategória"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kategória"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " kategória"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " értékelőlap"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " értékelőlap"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " értékelőlap"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden értékelőlap"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " értékelőlap"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " állapot"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " állapot"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " állapot"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden állapot"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " állapot"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " típus"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " típus"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " típus"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden típus"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " típus"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " munkaterület"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " munkaterület"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók munkaterületek"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " munkaterület"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " munkaterület"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weight")), " mértékű súlyozás járul hozzá az általános pontszám ", _interpolate(_named("percentage")), "%-ához"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap törlése"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékelőlapok törlése végleges. Ez törli az összes értékelőlap-adatot a jelentéskészítésből."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékelőlap visszaállításához újra létre kell hoznia."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap törlése"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó frissítés"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktív"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. A."])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Értékelési skála frissítve. ", _interpolate(_named("buttonStart")), "Felülvizsgálat megkezdése", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA alapértelmezés szerint bináris skálát állít be, de választhat egy részletesebb skálát is:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha más lehetőségeket szeretne látni az értékelési skálára vonatkozóan, kategória szerint konfigurálva, akkor ugorjon ide: ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empátia/hangnem"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termékismeret"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékelési skálák frissítésével a következők történnek:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A korábbi értékelési skála és a hozzá tartozó kategóriák archiválva lesznek (nem törlődnek)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizonyos irányítópult-adatok is érintettek lehetnek, mivel a régi és az új skála nem feltétlenül összehasonlítható egymással."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategóriáim meglévő konfigurációjának klónozása az új skálába"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan kívánja frissíteni az értékelési skálákat?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megváltoztathatja az értékelőlap megjelenítési formátumát (szám vagy emojik), és kötelezővé vagy nem kötelezővé teheti a kategóriákat."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojik használata számok helyett"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák kihagyásának engedélyezése a személyek számára"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megoldás"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési skála típusa"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 munkaterület kategóriái klónozva lettek"]), _normalize([_interpolate(_named("workspacesAmount")), " munkaterület kategóriái klónozva lettek"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület beállításai"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan azonosíthatók azok a hívások, amelyeknél a munkatárs nem figyelmezteti az ügyfelet, hogy csendre kell számítani a beszélgetés során."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetésben a beállított küszöbértéknél hosszabb csendes pillanatok vannak jelen."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend maximális küszöbértékeinek meghatározása a hívásokban"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törölt statisztikák nem jelennek meg az új beszélgetésekben és szűrőkben. Az irányítópultok és beszélgetések előzményadatai érintetlenek maradnak."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiemelési összefüggés törlése sikertelen volt"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelési összefüggés törölve"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelési összefüggés törlése"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti azokat a beszélgetéseket, amelyek konkrét szavakat vagy kifejezéseket tartalmaznak az átiratokban és az üzenetekben."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan azonosíthatók azok a hívások, amelyeknél hiányzik a kötelező nyilatkozat; például „A hívás rögzítve lesz” vagy hasonló."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célja azonosítani a felvételkészítésre vonatkozó nyilatkozatot a hívásban egy olyan nagy nyelvi modell (Large Language Model, LLM) használatával, amely dinamikusan megérti a kontextust, így nincs szükség előre meghatározott kifejezésekre."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó felvételkészítési nyilatkozat"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan azonosíthatók azok a hívások, amelyeknél a munkatárs nem figyelmezteti az ügyfelet, hogy csendre kell számítani a beszélgetés során."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csend"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csendes pillanatok megadása egy hívásban"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Módosítania kell előfizetését? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") – örömmel segítünk."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan elemezheti a beszélgetések 100%-át munkatársakon, BPO-kon, csatornákon és nyelveken átívelően. Az AI észleli a problémákat, a tudásbeli hiányosságokat és a coachinglehetőségeket a szolgáltatás javítása érdekében."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA 100%-os lefedettség"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatársi visszajelzés és coaching"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-összefüggések kiemelése"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális jelentések és irányítópultok"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulatelemzés és -szűrés"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igény szerinti fordítások"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati megbízások és célkitűzések"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching és kvízek"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk Minőség-ellenőrzés (QA)"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Professional minden eleme, valamint"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (régebbi)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookok a kritikus sikertelenségekről szóló azonnali értesítésekért"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adattár-integráció"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce Service Cloud-integráció"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni integrációs fejlesztés"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikált ügyfélsiker-vezető"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közvetlen hozzáférés termék- és mérnökcsapatainkhoz Slacken keresztül"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Growth Suite minden eleme, valamint"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA a 100%-os lefedettségért"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-vezérelt beszélgetés-összefüggések az adatok értelmezéséhez"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati adatok AI által fokozott gyarapítása és szűrése"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés: A mindenképp felülvizsgálandó interakciók automatizált észlelése"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulatelemzés és -szűrés"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-alapú teljesítményjelentések és -összefüggések"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések igény szerinti fordítása"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati idő nyilvántartása"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikerességi arány analitikája"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AI Suite minden eleme, valamint"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalati implementációs csomag, beleértve a Salesforce és a Genesys integrációját"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni integrációs fejlesztés"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adattár-integráció"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookok a kritikus sikertelenségekről szóló azonnali értesítésekért"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dedikált ügyfélsiker-vezető"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közvetlen hozzáférés termék- és mérnökcsapatainkhoz"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korai hozzáférés és ütemtervünk befolyásolása"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok hozzárendelése és célkitűzések beállítása automatikusan"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célzott coaching és analitika"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek a tanulás kezeléséhez"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználói szerepek és engedélyek kezelése"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érzékeny adatok maszkolása"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális szűrési lehetőségek és beszélgetéskeresés"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testreszabható értékelőlapok több értékeléssel, állítható súlyozással és automatikus sikertelenítési lehetőségekkel"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böngészőbővítmény, mellyel bárhonnan felülvizsgálhatók a beszélgetések"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok elemzése"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesítményjelentés a munkatársak, csapatok, BPO-k összehasonlításához"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-felmérések és ügyfél-visszajelzések analitikája"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkciók"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Starter minden eleme, valamint"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (régebbi)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több értékelési skála beszélgetésenként"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack-integráció"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-vezérelt interaktív beszélgetés-összefüggések"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati megbízások"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rögzített elemek a coachinghoz"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coachingalkalmak"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangulatelemzés és -szűrés"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrés a beszélgetések bonyolultsága alapján"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés-összefüggések"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltételes értékelőlapok"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok elemzése"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlett mesterséges intelligenciára alapuló CSAT-analitika"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osztályozási kalibráció"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Böngészőbővítmény, mellyel bárhonnan felülvizsgálhatók a beszélgetések"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés a mindenképp felülvizsgálandó beszélgetések felfedezéséhez"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések igény szerinti fordítása"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati idő nyilvántartása"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni csomag"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (régebbi)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korlátlan beszélgetés-felülvizsgálat"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-exportálás"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Társak közötti és önvizsgálatok"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok állítható súlyozással és automatikus sikertelenítési lehetőségekkel"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robusztus szűrési lehetőségek"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napi, heti vagy havi felülvizsgálati célkitűzések"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT-felmérések és alapvető analitika"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítópultok"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentések a munkatársak, csapatok és BPO-k összehasonlításához"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználói szerepek és jogosultságok kezelése"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkciók"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítani szeretném a Zendesk QA-előfizetésemet.\n\nEmeljen ki vagy jelöljön meg egy lehetőséget, és adja meg a módosítani kívánt helyek számát:\nSzeretnék [X helyet hozzáadni] [X helyet eltávolítani] [frissíteni a Zendesk QA csomagra]\n\nOssza meg cége nevét és a Zendesk QA-fiókjához társított nevet, valamint minden egyéb adatot, amelyet tudnunk kell a módosításához feldolgozásához."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA-előfizetés módosítása"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA-előfizetést szeretnék vásárolni.\n\nOssza meg cége nevét és a Zendesk QA-fiókjához társított nevet, valamint minden egyéb adatot, amelyet tudnunk kell a kérelem feldolgozásához:\n\nOssza meg azokat a konkrét kérdéseket is, amelyekkel a vásárlás előtt foglalkozni szeretne:\n\nKöszönjük!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA-előfizetés vásárlása"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetése lejár ekkor: ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " van hátra)"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A próbaidőszak ekkor ér véget: ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " van hátra)"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 nap"]), _normalize([_interpolate(_named("n")), " nap"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva minden hónap ", _interpolate(_named("dayOfMonth")), ". napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következőre számlázva ", _interpolate(_named("billingDate")), " napján: ", _interpolate(_named("card")), " (lejárat: ", _interpolate(_named("expires")), ")."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["végződés:"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Lejárt"]), _normalize(["1 nap múlva lejár"]), _normalize([_interpolate(_named("expiresIn")), " nap múlva lejár"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kezdőár ", _interpolate(_named("n")), " felhasználói licencet foglal magában"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kezdőár: ", _interpolate(_named("sum")), "/hó"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hó"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhasználónként havonta"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évente"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["havonta"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["negyedév"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["távolítsa el az ütemezett megszüntetést"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha továbbra is szeretné használni a Zendesk QA  szolgáltatást, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetése meg lett szüntetve ekkor: ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetésének lejárata ekkorra van ütemezve: ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " napos próbaidőszak extra költségek nélkül!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhasználva"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Önnek ", _interpolate(_named("n")), " felhasználója van. A helyek száma nem lehet ennél alacsonyabb."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetés ekkor folytatódik: ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["év"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A jelenlegi Zendesk QA-csomagja (", _interpolate(_named("plan")), ") nem tartalmaz AI-alapú funkciókat. A hanghívásintelligencia-funkciók teljes erejének kiaknázásához ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az előfizetés megszüntetéséhez vegye fel velünk a kapcsolatot ", _interpolate(_named("link")), ", vagy beszélgessen velünk csevegésben."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minőségkezelési csomag"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minőségkezelés"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés frissítve"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkártya hozzáadása"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csomag"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlázás"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlázási adatok megtekintése"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlázási intervallum"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés megszüntetése"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetés a számlázási időszak végén lesz megszüntetve."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye fel velünk a kapcsolatot a szerkesztéshez"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi csomag"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni díjazás"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszalépés"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves (15%-os megtakarítás)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves fizetés"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés szerkesztése"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomagok felfedezése"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingyenes próbaidőszak"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuálisan számlázva"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A következő lépésben áttekintheti és megerősítheti a végleges rendelési összegzést."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legnépszerűbb"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs aktív fizetési módja."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbaidőszakban"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytassa a jelenlegi előfizetését a csomag módosítása érdekében"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés szüneteltetése"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negyedéves"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ár"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés újraaktiválása"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés folytatása"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyek"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbaidőszak megkezdése most"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megszüntetve"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem megújuló"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szüneteltetve"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés állapota"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbaidőszak"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés frissítése"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szánjon egy percet arra, hogy visszajelzést ad az ügyféltámogatási élményéről. Előre is köszönjük – ", "{", "{", "agentName", "}", "}", " nagyra értékeli az Ön visszajelzését!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdv, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " CSAT-felmérése"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan értékelné a támogatást, melyet ", "{", "{", "agentName", "}", "}", " nyújtott Önnek?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nagyra értékeljük a segítségét! A visszajelzéseit arra használjuk, hogy mindenki számára jobbá váljon a(z) ", _interpolate(_list(0)), "."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felmérésekről"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés típusának kiválasztása"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez később módosítható."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérheti és fokozhatja az ügyfelek elégedettségét automatizált CSAT-felmérésekkel több nyelven."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott felmérések"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés létrehozása"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés neve"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés létre lett hozva"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Próbálja meg megváltoztatni a kulcsszót, vagy ", _interpolate(_named("contact_us")), ", ha szeretné látni ebben a listában"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mondja el nekünk"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található nyelv"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Válasszon ki egy vagy több nyelvet az alábbi listából. ", _interpolate(_named("contact_us")), ", ha nem látja a használni kívánt nyelvet ebben a listában."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek hozzáadása"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mondja el nekünk"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv hozzáadása"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Utoljára közzétéve: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Utoljára mentve: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-kérdés"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevezető kérdés"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bal oldali skála címkéje"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail tárgya"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okok"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb oldali skála címkéje"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönetnyilvánítás"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a felmérésből hiányoznak fordítások, az alapnyelv szövegei jelennek meg helyettük"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítások alkalmazása"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó fordítások"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ellenőrizzen minden fordítást az alkalmazásuk előtt. Végezzen módosításokat a letöltött fájlban, mentse, és szükség esetén töltse fel újból. Ha nem jelenik meg egy ", _interpolate(_named("token_example")), " token címkeként, akkor az helytelen formátumú, vagy olyan mezőben van, amely nem támogatja a tokeneket."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítások felülvizsgálata"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lefordított"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összefoglalja a beszélgetést, és javaslatokat mutat a hozzászólásokhoz."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA  AI-segéd (angol nyelven)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A típusok a létrehozásukat követően szerkeszthetők"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a típus már létezik. Válasszon másik nevet."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentse a testreszabott típust kiválasztható opcióként a Felmérés típus legördülő listában, majd csoportosítsa az összes létrehozott felmérést az irányítópulton."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a kívánt szavakat, majd nyomja meg az Enter billentyűt a mentéshez"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni típusok szerkesztése"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A típus neve nem lehet üres"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus meghatározása"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyéni típus nem lett elmentve. A mentéséhez nyomja meg az Enter billentyűt."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladó e-mail-címe"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladó neve"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mennyire ért egyet ezzel a kijelentéssel: „", _interpolate(_named("agentName")), " megkönnyítette a problémám kezelését”?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eddig mennyire volt könnyű segítséget kapni ", _interpolate(_named("brandName")), " részéről?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összességében mennyire volt könnyű megoldani a problémáját ma?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyire volt könnyű interakcióba lépni a csapatunkkal?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy (Adatvédelmi szabályzat) URL-je (nem kötelező)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerződési feltételek URL-je (nem kötelező)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hogyan értékelné a támogatást, melyet ", _interpolate(_named("agentName")), " nyújtott?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen volt a szolgáltatásunk e kérelem kapcsán?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyire volt elégedett a támogatási képviselővel?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mennyire elégedett azzal a szolgáltatással, melyet ", _interpolate(_named("agentName")), " nyújtott ma?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni kérdés"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saját egyéni ", _interpolate(_named("type")), "-kérdés meghatározása…"])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be az egyéni kérdést"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-ek testreszabása"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e-mail-üzenet beállítása"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyéni kérdéstípus nevének szerkesztésével az frissülni fog az összes olyan felmérésben, amely használja, anélkül, hogy hatással lenne az összegyűjtött adatokra."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni típusok szerkesztése"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail tárgya"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevezető kérdés"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), "-kérdés"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új egyéni típus létrehozása"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartalom"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Személyre szabási token"]), _normalize(["Személyre szabási tokenek"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Húzza a személyre szabási tokent az alábbi mezőbe."]), _normalize(["Húzza a személyre szabási tokeneket az alábbi mezőkbe."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " beszélgetés"])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg csak e-mailben lehet CSAT-felméréseket küldeni. Dolgozunk azon, hogy több lehetőséget nyújtsunk, mint például ügyfélszolgálati CSAT-felmérések, SMS-felmérések és egyebek."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A kapcsolat szinkronizálása vagy megújítása"]), _normalize(["A kapcsolatok szinkronizálása vagy megújítása"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolat szinkronizálása vagy megújítása ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["A kapcsolat nincs megfelelően szinkronizálva"]), _normalize(["A kapcsolatok nincsenek megfelelően szinkronizálva"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["törölje a jelet a releváns jelölőnégyzetből ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítés most ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá legalább egy felméréskiküldési szabályt a kapcsolat aktiválásához"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a kapcsolat jelenleg le van tiltva. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a kapcsolat maszkolja az érzékeny ügyféladatokat. A felmérés kiküldésének engedélyezéséhez\n", _interpolate(_named("link")), " a beállításokban."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a kapcsolat további engedélyeket igényel a felmérések kiküldéséhez.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítés most"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA több engedélyt igényel az Intercom szolgáltatáshoz."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), ", ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 óra"]), _normalize([_interpolate(_named("n")), " óra"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " perc"]), _normalize(["1 perc"]), _normalize([_interpolate(_named("n")), " perc"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["óra"]), _normalize(["óra"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " a felmérésküldési szabályok konfigurálásához."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" nap"]), _normalize(["nap"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési hivatkozások lejárata"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hivatkozások lejárnak ennyi idő után"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megakadályozhatja a felmérések megválaszolását, ha túl sok idő telt el"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " felmérésben ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A címkéket vesszővel válassza el, vagy nyomja meg az Enter billentyűt a beírásuk után. Győződjön meg róla, hogy mindegyik pontosan úgy van feltüntetve, mint az Intercom szolgáltatásban."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be azokat a feltételeket, amelyek alapján a felmérést kiküldi a rendszer."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés kiküldésének szabályai"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés nyomon követésének kiküldési szabályai"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Már létezik egy ", _interpolate(_named("name")), " felmérés ", _interpolate(_named("rules")), ".\nAz ütközés megoldásához módosítsa a szabályokat itt vagy a(z) ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Már beállított egy kapcsolatot ugyanazokkal a szabályokkal a(z) ", _interpolate(_named("name")), " felméréshez. Az ütközés megoldásához módosítsa a szabályokat itt vagy a(z) ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másik szabály hozzáadása"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka hozzáadása"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel kiválasztása"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítés"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érték keresése"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címke hozzáadása"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ugyanazokkal a szabályokkal"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érték kiválasztása"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail küldése ennyi idő múlva:"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailes nyomon követés küldése ennyi idő múlva:"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beágyazott töredék és e-mailes nyomon követés"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód másolása"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztasson Zendesk QA-felméréseket az e-mailjeihez azáltal, hogy hozzáadja a kódtöredéket a Zendesk-konfigurációhoz."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepítési útmutató"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk megnyitása"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előnézet"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töredék előnézete"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kódtöredék vágólapra másolva"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töredék másolása sikertelen volt"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beágyazott töredék"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a felmérésnek nem mentett módosításai vannak. Mentse újra, hogy megbizonyosodjon arról, hogy a kódtöredék tükrözi a legújabb frissítéseket."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat hozzáadása"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek hozzáadott kapcsolatok"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolatokkal bevonhat e-maileket, élő csevegéseket, telefonhívásokat és egyebeket a Zendesk QA szolgáltatásba"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felmérések kézbesítéséről"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés kiküldésének szabályai"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés nyomon követésének kiküldési szabályai"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márkaszabály hozzáadása"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabály hozzáadása"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesítés"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követés Messengerben + e-mailben"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Intercom-fiókja csatlakoztatása a Zendesk QA CSAT-felméréséhez"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kézbesítés ezen keresztül"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki kapja meg a felmérést, és mikor?"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabályok szerkesztése"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezve"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazáson belüli felmérések lehetővé teszik ügyfelei számára, hogy elvégezzék a felmérést anélkül, hogy elhagynák a csevegést."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indítsa el az Intercom szolgáltatást"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kézbesítési szabályok kapcsolatonként"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Már létezik egy ", _interpolate(_named("name")), " felmérés ", _interpolate(_named("rules")), ".\nAz ütközés megoldásához tiltsa le a kapcsolatot vagy módosítsa a szabályokat itt vagy a(z) ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kézbesítés"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be azokat a feltételeket, amelyek alapján a felmérést kiküldi a rendszer"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hozta létre ekkor: ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("languageName")), " eltávolítása"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nyelv eltávolítása azt jelenti, hogy többé nem lesz látható semelyik válaszadó számára, és az összes lefordított szöveg törölve lesz."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv eltávolítása"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet nem vonható vissza."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatív megoldásként letilthatja a nyelvet azáltal, hogy elrejti azt a felmérésből. Ez lehetővé teszi, hogy megmaradjon az összes fordítás anélkül, hogy megjelenítené őket a válaszadóknak."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utoljára ", _interpolate(_named("name")), " szerkesztette ekkor: ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felmérések nyelveiről"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mielőtt további nyelveket adna hozzá, győződjön meg róla, hogy elvégezte a kívánt módosításokat a Stíluskezelés és a Tartalom lépésekben. ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv hozzáadása"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lefordított mezők"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Látható a válaszadók számára"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapértelmezett"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítási fájl letöltése"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv eltávolítása"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" töltsön le egy fordítási fájlt"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizsgálja felül a fordításokat"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A fordítás befejeződött. ", _interpolate(_named("review_link")), ", vagy ", _interpolate(_named("download_link")), " a módosítások bevezetéséhez."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" töltsön le egy fordítási fájlt"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze, mi hiányzik"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bizonyos tartalmak még nincsenek lefordítva, és le lesznek cserélve angol nyelvű tartalommal. ", _interpolate(_named("review_link")), ", vagy ", _interpolate(_named("download_link")), " a hiányzó fordítások hozzáadásához."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentse a felmérést"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A felmérés nem mentett változtatásokat tartalmaz. ", _interpolate(_named("save")), " a legutóbbi változtatásokat tartalmazó fordítási fájl letöltéséhez."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordítási fájl feltöltése"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferált értékelési skála kiválasztása"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legördülő listát biztosít az ügyfeleknek, melyben kiválaszthatják az értékelés okát"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojik"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bal oldali skála szövege (nem kötelező)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számok"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok hozzáadása"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ok nem lehet üres"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az ok már létezik"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legördülő lista megjelenítése a pontszám okairól"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha töröl egy okot, majd újból hozzáadja azt ugyanazzal a névvel, vagy ha átnevez egy okot, akkor mindig létrejön egy új bejegyzés. Ez befolyásolja az irányítópulton megjelenített adatokat."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok törlése"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törölt ok megmarad az irányítópult jelentéseiben, de nem lesz látható a felmérés új válaszadói számára. Ez a kapcsolódó fordításokra is vonatkozik."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok törlése"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["„", _interpolate(_list(0)), "” ok törlése"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb oldali skála szövege (nem kötelező)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy 5 pontos skálán az 1-es és a 2-es negatív pontszámnak minősül; a 3-as semlegesnek; a 4-es és az 5-ös pedig pozitívnak."])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skála szövegének hozzáadása"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " skálája"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési skála"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Zendesk QA éppen importálja a beszélgetéseit. Ez a folyamat akár 24 órát is igénybe vehet."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez egy személyre szabási tokenként lesz használva a felmérés során."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márkanév"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gombok és hivatkozások"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testreszabhatja felmérésének megjelenését"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embléma háttere"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A JPG, a PNG és a GIF formátumok támogatottak"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embléma"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stíluskezelés"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embléma feltöltése"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búcsúzzon el stílusosan"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írjon be egy üzenetet, amit a személyek azután látnak, miután elküldték a visszajelzésüket"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönetnyilvánító üzenet"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönetnyilvánítás"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen hexadecimális színkód"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen e-mail-cím"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a mező nem lehet üres"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen URL"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem látható:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Látható:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött felmérések"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elmúlt 30 napban elküldött felmérések"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés törölve lett"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés duplikálva lett"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előnézeti e-mail el lett küldve"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés közzé lett téve"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés frissítve lett"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés szüneteltetve lett"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szüneteltetés"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés szüneteltetése"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előnézet"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az e-mail-címet a felmérés előnézetének elküldéséhez"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sajat", "@", "email.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv kiválasztása az előnézethez"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előnézeti e-mail küldése"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Példafelmérést küldhet a saját e-mail-címére. A felmérés előnézetéből származó értékelések nem lesznek tárolva."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("type")), " felmérés előnézetének megtekintése"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailes felmérés előnézete a weben"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzététel"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés vázlatként"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márkanév"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márkaspecifikus név a Stíluskezelés lépésben adható hozzá"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfél neve"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogatási azonosító"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogatási képviselő"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölve"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szüneteltetve"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " CSAT-felmérése"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem mentett módosítások történtek"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés frissítése és folytatása"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha nem látja megfelelően ezt az e-mailt, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem voltam elégedett a kapott támogatással, mert…"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szolgáltató: Zendesk QA "])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok kiválasztása"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés összefoglalója"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például: Az ügyfélnek problémája volt a fizetési mód frissítésével. A munkatárs megosztott egy cikket, ami segíthet. Ez működött."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szolgáltató: Zendesk QA AI"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerződési feltételek"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönjük a visszajelzését!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzések"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi a pontszámának a fő oka?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mondja el nekünk, miért adta ezt az értékelést…"])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagy erőfeszítés"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacsony erőfeszítés"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kattintson ide a felmérés megnyitásához"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelem"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyéni kérdése itt jelenik meg"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), "-tapasztalataira vonatkozóan Ön a következő pontszámot adta:"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés küldése"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönetnyilvánítás"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiratkozás"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyon elégedetlen"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyon elégedett"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye figyelembe, hogy a felmérés jelenlegi állapota és a kézbesítési szabályok nem lesznek belefoglalva a felmérés duplikátumába"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["„", _interpolate(_list(0)), "” felmérés duplikálása"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a beágyazott e-mail-töredéket használja, a felmérés szüneteltetése előtt mindenképp távolítsa el azt a Zendesk szolgáltatásból."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés szüneteltetése esetén nem lesznek összegyűjtve válaszok."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés szüneteltetése"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés szüneteltetése előtt mindenképp távolítsa el a töredéket a Zendesk szolgáltatásból."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés törlése"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a beágyazott e-mail-töredéket használja, a felmérés törlése előtt mindenképp távolítsa el azt a Zendesk szolgáltatásból."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet véglegesen törli a felmérést. Azok a felhasználók, akik megkapták a felmérést, továbbra is kitölthetik, és a felméréshez kapcsolódó összes adat a Zendesk QA szolgáltatásban marad."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("surveyName")), " felmérés törlése"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felmérés törlése előtt mindenképp távolítsa el a töredéket a Zendesk szolgáltatásból."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazott változtatásokat látni fogják az új válaszadók."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés közzététele"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy a felmérés közzététele után a beágyazott töredék legújabb verzióját átmásolja."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a beágyazott e-mail-töredéket használja, a felmérés folytatása után mindenképp másolja át azt a Zendesk szolgáltatásba."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytassa a felmérés kiküldését és a válaszok összegyűjtését."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés frissítése és folytatása"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy a felmérés folytatása után hozzáadja a beágyazott töredéket a Zendesk szolgáltatáshoz."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérés frissítése"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy a felmérés frissítése után a beágyazott töredék legújabb verzióját átmásolja."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan kívánja törölni ezt a felmérést? Ez a művelet nem vonható vissza."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törli a felmérést?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próbaidőszak lejárt, de teljes fiókra való előfizetéssel továbbra is kihasználhatja a Zendesk QA előnyeit."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próbaidőszak lejárt, de teljes fiókra való előfizetéssel továbbra is kihasználhatja a Zendesk QA előnyeit. Beszéljen a helyi rendszergazdával ennek beállításához."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A próbaidőszak lejárt"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítsen most"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ingyenes próbaidőszaka véget ért."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahhoz, hogy folytathassa a Zendesk QA teljes verziójának használatát, adja meg a fizetési adatait."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahhoz, hogy folytathassa a Zendesk QA teljes verziójának használatát, a fiókja rendszergazdájának meg kell adnia a fizetési adatokat."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés kezelése"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy felhasználó"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " hozta létre ekkor: ", _interpolate(_named("date"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " megbízás"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, még dolgozik ezen. Ha a módosítások mentése nélkül távozik, akkor elveszíti az összes eddig hozzáadott információt."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a megbízás minden második ", _interpolate(_named("weekDay")), "i napon ismétlődik"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ez a megbízás minden ", _interpolate(_named("weekDay")), "i napon ismétlődik"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Minden felülvizsgált személy lefedve egyetlen ciklusban"]), _normalize(["Minden felülvizsgált személy lefedve ", _interpolate(_named("nr")), " ciklusban"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " beszélgetés"]), _normalize([_interpolate(_named("n")), " beszélgetés"]), _normalize([_interpolate(_named("n")), " beszélgetés"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nr")), " munkatársból"]), _normalize([_interpolate(_named("nr")), " munkatársból"]), _normalize([_interpolate(_named("nr")), " munkatársból"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["résztvevő"]), _normalize(["résztvevő"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " beszélgetés ciklusonként"]), _normalize([_interpolate(_named("n")), " beszélgetés ciklusonként"]), _normalize([_interpolate(_named("n")), " beszélgetés ciklusonként"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " felülvizsgált személyenként egy sorban"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["felülvizsgált személy"]), _normalize(["felülvizsgált személy"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["felülvizsgáló személy"]), _normalize(["felülvizsgáló személy"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személyek"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület-felhasználó"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterületi felhasználó a munkatársak kivételével"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleNumber")), ". ciklus • ", _interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem ismétlődő"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " megbízás"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat alatt"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a megbízásokról"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozása"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet véglegesen törli a megbízást, és eltávolítja annak adatait az irányítópultról. Minden hozzá kapcsolódó felülvizsgálat megmarad a Zendesk QA szolgáltatásban."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("assignmentName")), " megbízás törlése"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás törölve"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás duplikálva"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek hozzáadott megbízások"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízás létrehozása sikertelen volt"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás neve"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ciklus frissül, és új feladatlista jön létre. A már adott felülvizsgálatok továbbra is beleszámítanak a célkitűzésbe."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonnal"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzététel"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A következő ciklus kezdetén, ekkor: ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikor legyenek közzétéve a változások?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízásban bevezetett módosítások ezzel a megbízással lesznek közzétéve"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítja a megbízást aktívként?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízás kezdetének napján ismétlődik"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első péntekén"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második péntekén"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik péntekén"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik péntekén"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó péntekén"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első hétfőjén"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második hétfőjén"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik hétfőjén"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik hétfőjén"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó hétfőjén"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első szombatján"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második szombatján"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik szombatján"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik szombatján"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó szombatján"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első vasárnapján"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második vasárnapján"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik vasárnapján"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik vasárnapján"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó vasárnapján"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első csütörtökén"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második csütörtökén"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik csütörtökén"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik csütörtökén"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó csütörtökén"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első keddjén"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második keddjén"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik keddjén"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik keddjén"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó keddjén"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap első szerdáján"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap második szerdáján"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap harmadik szerdáján"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap negyedik szerdáján"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap utolsó szerdáján"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni ismétlődés"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nap"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hónap 1-jén"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Második"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harmadik"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negyedik"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismétlődés"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hétköznap"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vége"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdete"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek találatok"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időzóna"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciklus beállításai"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás neve"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető információk"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyes megbízási ciklusok kezdetekor a rendszer egy előre meghatározott beszélgetéslistát generál értékelésre."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális beállítások"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új beszélgetések automatikus lekérése, ha a felülvizsgálati célkitűzés nem teljesül"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az automatikus lekérés százalékos (%) felülvizsgálati célkitűzések esetén nem lehetséges"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egész számnak kell lennie, nem lehet tört"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések cseréjének engedélyezése (felülvizsgálói szerep és afölött)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések cseréje"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annak engedélyezése, hogy a felülvizsgáló személyekhez saját beszélgetéseik legyenek hozzárendelve felülvizsgálat céljából"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgálatok minimális számának legalább 1-nek kell lennie"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyes felülvizsgált személyek minimális számú felülvizsgálatainak megadása"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beszélgetés"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["százalék (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felülvizsgálat összesen"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgáló személynek el kell végeznie egy meghatározott számú felülvizsgálatot"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyeknek együttesen felül kell vizsgálniuk a beszélgetések egy meghatározott számát"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgálatok minimális számának legalább 1-nek kell lennie"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgálati célkitűzésnek 1 és 100% között kell lennie"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálati célkitűzés"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgált személyre vonatkozóan felül kell vizsgálni az összes beszélgetésükből egy minimális mennyiséget"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyek a megosztott készletből választhatnak beszélgetéseket"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyekhez a megosztott készletből lesznek hozzárendelve beszélgetések"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás megközelítése"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden robot"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület összes résztvevője"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület-felhasználó"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személyek hozzáadása"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki lesz felülvizsgálva?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személyek kiválasztása"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki, kiket szeretne felülvizsgálni"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kizárások csak akkor megengedettek, ha a munkaterület összes felhasználója vagy egy csoportja van kiválasztva"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek és felülvizsgált személyek"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Jelenlegi: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület alapértelmezése"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen típusú felhasználók legyenek kiválasztva felülvizsgált személyekként?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legaktívabb munkatárs"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevő"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személyek kizárása (nem kötelező)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kizárandó felhasználók kiválasztása"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek kizárása (nem kötelező)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kizárandó felhasználók kiválasztása"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterületi felhasználó a munkatársak kivételével"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek hozzáadása"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki fogja elvégezni a felülvizsgálatokat?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek kiválasztása"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az önvizsgálatok nem megengedettek ezen a munkaterületen"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok engedélyezése"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az önvizsgálatok nem megengedettek ezen a munkaterületen."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Egyező beszélgetés ma"]), _normalize(["Egyező beszélgetések ma"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy jelenik meg, mintha ma kezdődött volna a megbízás."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jövőbeni számok eltérőek lehetnek."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kiválasztott felülvizsgált személyek"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kiválasztott felülvizsgáló személyek"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felülvizsgált személy"]), _normalize(["Felülvizsgált személyek"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felülvizsgáló személy"]), _normalize(["Felülvizsgáló személy"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előnézet"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikus lekérés: engedélyezett, ha nincs elég beszélgetés"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", péntekenként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", hétfőnként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", szombatonként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", vasárnaponként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", csütörtökönként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", keddenként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), ", szerdánként, ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Naponta ekkortól: ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kezdete ", _interpolate(_named("startDateTime")), ", a vége pedig ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " dátumfeltétel"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " beszélgetés és ügyfélszolgálati feltétel"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nulla beszélgetés és ", _interpolate(_named("count")), " ügyfélszolgálati feltétel"]), _normalize(["Nulla beszélgetés és ", _interpolate(_named("count")), " ügyfélszolgálati feltétel"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " beszélgetés és nulla ügyfélszolgálati feltétel"]), _normalize([_interpolate(_named("count")), " beszélgetés és nulla ügyfélszolgálati feltétel"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Megközelítés: a felülvizsgáló személyek választanak beszélgetéseket"]), _normalize(["Megközelítés: a felülvizsgáló személyekhez hozzárendelnek beszélgetéseket"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javítsa ki az összegzésben kiemelt hibákat a folytatáshoz"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés: a felülvizsgáló személyeknek minden beszélgetést felül kell vizsgálniuk"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Célkitűzés: a felülvizsgáló személyeknek egyetlen beszélgetést kell felülvizsgálniuk"]), _normalize(["Célkitűzés: a felülvizsgáló személyeknek ", _interpolate(_named("count")), " beszélgetést kell felülvizsgálniuk"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Célkitűzés: a felülvizsgáló személyeknek a beszélgetések ", _interpolate(_named("count")), "%-át kell felülvizsgálniuk"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés: nincs felülvizsgálati célkitűzés beállítva"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés: minden felülvizsgált személynek felül kell vizsgálni az összes beszélgetését"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Célkitűzés: minden felülvizsgált személynek felül kell vizsgálni ", _interpolate(_named("count")), " beszélgetését az összes beszélgetésük közül"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Célkitűzés: minden felülvizsgált személynek felül kell vizsgálni az összes beszélgetésének ", _interpolate(_named("count")), "%-át"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Célkitűzés: minden felülvizsgáló személy el kell végeznie egyetlen felülvizsgálatot"]), _normalize(["Célkitűzés: minden felülvizsgáló személy el kell végeznie ", _interpolate(_named("count")), " felülvizsgálatot"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Csere: a felülvizsgáló személyek nem cserélhetik le a beszélgetéseket"]), _normalize(["Csere: a felülvizsgáló személyek lecserélhetik a beszélgetéseket"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önvizsgálatok: a felülvizsgáló személyekhez hozzárendelhetők a saját beszélgetéseik"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek dátumfeltételek beállítva"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs megbízásnév beállítva"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összegzés"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltételek"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " ekkor: ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kétheti"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napi"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soha"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heti"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciklus hossza"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítás aktívként"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés vázlatként"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás mentve vázlatként"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás beállítva inaktívként"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítás inaktívként"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozása"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozva"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés aktívként"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés inaktívként"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A megbízást érintő függőben lévő frissítések a következő ciklusban lépnek érvénybe, amely ekkor kezdődik: ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások közzététele"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktív"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függőben"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás frissítése"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás frissítve"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs vagy a robot lezárta-e a beszélgetést."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs udvariasan lezárta-e a beszélgetést"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a beszélgetés le lett-e zárva"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezárás"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a nyelvhelyességi, helyesírási és stílusbeli hibákat a munkatársak és a robotok interakcióiban egyaránt."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs nyelvtani, helyesírási és stílusbeli hibáit"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs nyelvtani, helyesírási és stílusbeli hibáit"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyesírás és nyelvhelyesség"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a beszélgetést a munkatársak és a robotok üdvözlései szempontjából."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést a tipikus üdvözlőkifejezések tekintetében"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést tipikus üdvözlések tekintetében"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözlés"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs megérti és elismeri-e az ügyfelek érzéseit"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonosítja azokat az eseteket, amikor a munkatárs tükrözte az ügyfél hangulatát, vagy olyan együttérző szavakat használt, mint a „megértem” és az „érzékelem”"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empátia"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs érti-e az ügyfél kérdését vagy aggályát"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy a munkatárs érti-e az ügyfél kérdését vagy aggályát"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megértés"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy milyen könnyen érthető egy szöveg, figyelembe véve a szavak komplexitását és a mondatok hosszát"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi, hogy milyen könnyen érthető egy szöveg, figyelembe véve a szavak komplexitását és a mondatok hosszát"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az üzenet 1 túl hosszú mondatot tartalmaz, így nehéz elolvasni"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ez az üzenet ", _interpolate(_list(0)), " túl hosszú mondatot tartalmaz, így nehéz elolvasni"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvashatóság"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést egy felajánlott megoldás tekintetében"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi az egész beszélgetést egy felajánlott megoldás tekintetében"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felajánlott megoldás"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs hangnemét a beszélgetés során"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemzi a munkatárs hangnemét a beszélgetés során"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangnem"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni semmilyen hibát a munkatárs üzenetében"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült megállapítani nyelvhelyességi hibákat"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem észlelhető üdvözlőkifejezés. Szükség esetén adjon hozzá újakat a rendszergazdáján keresztül a Beállításokban."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs udvariasan lezárta a beszélgetést"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem észlelhető lezárási kifejezés. Szükség esetén adjon hozzá újakat a rendszergazdáján keresztül a Beállításokban."])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs tükrözte az ügyfél hangulatát, vagy együttérző szavakat használt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni, hogy a munkatárs tükrözte-e az ügyfél hangulatát, vagy használt-e együttérző szavakat"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs hosszú mondatokat és bonyolult szavakat használt, ami miatt egyeseknek potenciálisan kihívást jelenthet a mondanivaló megértése"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs bonyolult szavakat használt, ami miatt egyeseknek potenciálisan kihívást jelenthet a mondanivaló megértése"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs hosszú mondatokat használt, ami miatt egyeseknek potenciálisan kihívást jelenthet a mondanivaló megértése"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs könnyen érthető nyelvezetet használt"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs elég adat ennek a kategóriának az értékeléséhez"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatársnak nehezére esett megérteni az ügyfél kérdését/kérelmét"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs jól megértette az ügyfél kérdését/kérelmét"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni, hogy a munkatárs jól megértette-e az ügyfél kérdését/kérelmét"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs hangneme a beszélgetés során"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs hangneme a beszélgetés során"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs hangneme a beszélgetés során"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs hangneme a beszélgetés során"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkatárs hangneme a beszélgetés során"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült meghatározni a munkatárs hangnemét"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés nem lett lezárva"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A beszélgetés le lett zárva"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni, hogy a beszélgetés le lett-e zárva"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs nem tanúsított empátiát az ügyfél és annak problémái felé"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs empátiát tanúsított az ügyfél és annak problémái felé"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni, hogy a munkatárs tanúsított-e empátiát az ügyfél és annak problémái felé "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél nem lett üdvözölve a beszélgetés során"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ügyfél üdvözölve lett a beszélgetés során"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült tipikus üdvözlést észlelni"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs nem nyújtott megoldást az ügyfélnek"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkatárs megoldást kínált az ügyfélnek"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült észlelni, hogy a munkatárs nyújtott-e megoldást az ügyfélnek"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonyolult szavak"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszú mondatok"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvhelyesség"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyesírási hibák"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bocsánatkérő"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyugodt"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örömteli"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kíváncsi"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professzionális"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogató"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stílus"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 lezárás"]), _normalize([_interpolate(_named("n")), " lezárás"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AutoQA csak a jóváhagyott lezárásokat ellenőrzi. Ha ez a kategória kritikusnak van beállítva, a jóváhagyott lezárások használatának mellőzése esetén sikertelen lesz a felülvizsgálat."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárások"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott lezárások"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 mentesség"]), _normalize([_interpolate(_named("n")), " mentesség"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azok a szavak és kifejezések, amelyeket az AutoQA figyelmen kívül hagy, mint helyesírási és nyelvhelyességi hibák."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyesírási és nyelvhelyességi mentességek"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-mentességek"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 üdvözlés"]), _normalize([_interpolate(_named("n")), " üdvözlés"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az AutoQA csak a jóváhagyott üdvözléseket ellenőrzi. Ha ez a kategória kritikusnak van beállítva, a jóváhagyott üdvözlések használatának mellőzése esetén sikertelen lesz a felülvizsgálat."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlések"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jóváhagyott üdvözlések"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategória"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategória kiválasztása"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatikusan hozzárendelt alapvető okok:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók → Beállítások"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Egyes kategóriák kihasználják a Microsoft Azure szolgáltatást, amely a funkcionalitás és a támogatott nyelvek bővebb körét kínálja. További információért ellenőrizze a ", _interpolate(_named("link")), " területet, vagy vegye fel a kapcsolatot egy rendszergazdával ennek elvégzéséhez."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hozzáférhet a kategóriák és támogatott nyelvek bővített listájához a Microsoft Azure révén. Ellenőrizze a ", _interpolate(_named("link")), " szakaszt, vagy vegye fel a kapcsolatot egy rendszergazdával ennek elvégzéséhez."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA használata"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az AutoQA-kategóriák automatikusan pontszámot kapnak, fix értékelési skála használatával. Az értékelést később manuálisan módosíthatja. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA-kategória kiválasztása"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az automatikusan hozzárendelt pontszám a hibák súlyosságától és számától függ. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a releváns értékelési kategóriák jelennek meg a felülvizsgáló személyek számára. Az értékelési kategóriák az alapján jelennek meg, hogy milyen feltételeket állított be a kategória létrehozásakor."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csevegés"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betanítás"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Válassza ki az értékelési kategória típusát a(z) „", _interpolate(_named("workspace")), "” munkaterület számára."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyeknek manuálisan ki kell választaniuk a megfelelő értékelőlapot, hogy megtekinthessék az egyes beszélgetések releváns értékelési kategóriáit."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan kívánja módosítani az értékelési kategória típusát?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák módosítása"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítja az értékelési kategória típusát?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltételes értékelési kategória"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statikus értékelési kategória"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória típusa"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória beállításai"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a művelet nem vonható vissza."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["törölve lesz az összes értékelés"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatív lehetőségként megőrizheti a statisztikákat az adatok törlése nélkül, ha archivál egy kategóriát."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Megértem, hogy ezzel ", _interpolate(_named("deleteAllRatings")), ", amely meg lett adva ehhez a kategóriához."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: Tökéletes a hangnem"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória megjelenítése csak ha"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A „Mindig” lehetőség figyelmen kívül lesz hagyva. Ez nem kombinálható más feltételekkel."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindig"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltétel kiválasztása"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elégedettségi pontszám (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás típusa"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetési csatorna"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategóriának szüksége van egy névre"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategória hozzáadása egy értékelőlaphoz"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória létrehozása"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória létrehozása"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiválás"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória hozzáadva"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az archivált kategóriák nem láthatók az értékelőlapon, de lehetővé teszik a statisztikák megőrzését az adatok törlése nélkül."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória archiválása"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kategória archiválása"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória archiválva"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória törlése"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategória törléséhez gépelje be a „kategória törlése” kifejezést"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kategória törlése"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kategória törlése"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória törölve"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória duplikálva"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelésikategória-csoport hozzáadva"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelésikategória-csoport törölve"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelésikategória-csoportok sorrendje módosítva"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelésikategória-csoport frissítve"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória archiválása visszavonva"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési kategória frissítve"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha egy munkatárs sikertelen egy kritikus kategóriában, a felülvizsgálat összes kategóriája automatikusan sikertelen lesz."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus értékelési kategória"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kategóriák ebben a csoportban"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új értékelési kategória létrehozása"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória szerkesztése"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kategória törlése és az adatok felülvizsgálata e csoport alatt"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kategóriacsoport törlésével a hozzá csatlakoztatott összes kategória csoportosítatlanul marad."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("categoryName")), " kategóriacsoport törlése"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha úgy dönt, hogy törli a csoport alatti kategóriákat, az azt eredményezi, hogy elveszíti az ezekhez a kategóriákhoz társított összes felülvizsgálati adatot."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport neve"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyek több okot is kiválaszthatnak alapvető okként"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felülvizsgáló személyek több okot is kiválaszthatnak alapvető okként"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több ok"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pl.: Hangnem"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kategóriacsoport hozzáadása"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új csoport"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["„Egyéb” hozzáadása"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető ok hozzáadása"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az alapvető ok már létezik"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető ok hozzáadása"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető okok hozzáadása az értékelés magyarázataként"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb…"])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelési skála"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivált"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok hozzáadása ehhez az értékelési kategóriához"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiválás visszavonása"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória frissítése"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden értékelés"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelenítés ezekhez"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív értékelések"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negatív és semleges értékelések"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen, frissítés"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súly"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön számára fontosabb kategóriák nagyobb súllyal bírhatnak a jegy pontszámának kiszámításakor."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Súly (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a kalibrációról"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a kalibrációról"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rendszeres kalibrációs alkalmak megtartása lehetővé teszi a felülvizsgáló személyek számára, hogy értékelési technikáikat összhangban tartsák, és biztosítja, hogy a munkatársak azonos szintű, következetes és elfogulatlan visszajelzéseket kapjanak."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomagok megtekintése"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A ", _interpolate(_list(0)), " olyan folyamat, amely segít a csapatnak összhangban lenni azzal kapcsolatban, hogy hogyan kell kezelni és kiértékelni az ügyfél-interakciókat."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A kalibráció a következő csomagjaink esetén érhető el: ", _interpolate(_list(0)), " és ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A korábban adott felülvizsgálatok nem jelennek meg, amikor hozzáadnak egy beszélgetést az alkalomhoz."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem látható"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A korábban adott felülvizsgálatok kalibrációs felülvizsgálatoknak tekintendők. Felülvizsgáló személyenként csak egyetlen beszélgetési felülvizsgálat lesz hozzáadva az alkalomhoz. Az üzenetspecifikus felülvizsgálatok nem jelennek meg."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Látható"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A korábban adott felülvizsgálatok láthatósága"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kalibráció olyan folyamat, amely segít a csapatnak összhangban lenni azzal kapcsolatban, hogy hogyan kell kezelni és kiértékelni az ügyfél-interakciókat. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználók korlátlan hozzáféréssel rendelkeznek a kalibrációs alkalom során adott összes felülvizsgálathoz."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes felülvizsgálat legyen látható mindig"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület-vezető"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy felülvizsgálat elküldése után a felhasználók láthatják a többi felülvizsgáló személy által a kalibrációs alkalom során adott értékeléseket."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kalibrációs felülvizsgálat megtekintése a felülvizsgálatok elküldése után"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználók korlátlan hozzáféréssel rendelkeznek a kalibrációs alkalom során adott összes felülvizsgálathoz."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes felülvizsgálat legyen látható mindig"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezető"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy felülvizsgálat elküldése után a felhasználók láthatják a többi felülvizsgáló személy által a kalibrációs alkalom során adott értékeléseket."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kalibrációs felülvizsgálat megtekintése a felülvizsgálatok elküldése után"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy felülvizsgálat elküldése után a felhasználók láthatják a többi felülvizsgáló személy által a kalibrációs alkalom során adott értékeléseket."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kalibrációs felülvizsgálat megtekintése a felülvizsgálatok elküldése után"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználók csak a kalibrációs alkalom során adott saját felülvizsgálataikat láthatják."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a saját felülvizsgálatok legyenek láthatók"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs beállítások frissítve"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs felülvizsgálatok láthatósága"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be az irányítópult-mutatók küszöbértékét úgy, hogy kitölti a hézagokat a csapat belső mutatórendszerének megfelelően. A küszöbértéket az irányítópulton ellenőrizheti."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízások"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibráció"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatok"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagek"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagok"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küszöbérték"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küszöbérték frissítve"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy pillanat…"])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Győződjön meg róla, hogy rendszergazdai engedélyekkel rendelkezik, majd ", _interpolate(_named("retry")), ". További segítségért ", _interpolate(_named("contact")), "."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vegye fel velünk a kapcsolatot"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, nem tudtunk hozzáférni az ügyfélszolgálata API-jához."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próbálkozzon újra"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítjuk az ügyfélszolgálatát. Pillanatokon belül átirányítjuk."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " engedélyezve"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Térjen vissza a ", _interpolate(_list(0)), ", hogy hozzáadhasson egy új ügyfélszolgálati kapcsolatot."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, kihagyott néhány lépést."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Már létezik azonos e-mail-doménnel rendelkező szervezet"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kapcsolatok oldalra"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addig is…"])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kis türelmet kérünk, amíg ellenőrizzük az engedélyeit."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addig is…"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrálás…"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hozzon létre egy munkaterületet"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ahhoz, hogy folytathassa a Zendesk QA használatát, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szinte használatra kész"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található munkaterület"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókváltás"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahhoz, hogy folytathassa a Zendesk QA  használatát, a felettesének hozzá kell rendelnie Önt egy munkaterülethez."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A fiók törlésével véglegesen törölve lesz minden adat, ami Önnel kapcsolatban nálunk megtalálható – ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minden adott értékelés, a beszélgetések tartalma, a munkaterületek stb."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás a Slackhez"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leválasztás"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az integráció leválasztásával letiltja a Zendesk QA  összes Slacken keresztüli értesítését"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack leválasztása"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Slack-integráció le lett választva a Zendesk QA szolgáltatásról."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghatározhatja az alapértelmezett értesítési beállításokat a fiók összes új felhasználója számára."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások felülbírálása minden felhasználónál"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értesítési beállítások felülbírálására készül a fiók összes felhasználójánál"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználók nem fogják tudni meghatározni saját értesítési beállításaikat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítési beállítások frissítve"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhookok"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riasztás sikertelen kritikus kategória esetén"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riasztás, amikor választ kap a CSAT"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggerek"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demó-munkaterület és -adatok létrehozása"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újból létrehozhatja a demóadatokat, és megőrizheti a demó-munkaterületet."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demó-munkaterület és -adatok eltávolítása"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demóadatok frissítése"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatok frissítése"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("accountName")), "” fiók demó módjának kezelése"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demó mód"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalon maradás"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Távozás az oldalról"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Távozik erről az oldalról, és elveti az összes módosítást?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesítette a megbízást"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozása"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás teljesítve"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók megbízások"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg nincsenek feladatok, amelyeket Ön felülvizsgálhatna."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítsa a megbízást, vagy várjon a következő ciklus indulására."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja módosítani a szűrőket"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás szerkesztése"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartsa szemmel ezt az oldalt a frissítésekért"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek feladatok ebben a ciklusban"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjeleníthető beszélgetések"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladat megjelölése készként"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön felülvizsgálta ezt a felhasználót."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek önvizsgálatok ebben a megbízásban"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáadáshoz kattintson a kalibráció ikonra a beszélgetésnézet fejlécében."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek hozzáadott beszélgetések"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárat dátuma"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozása"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs alkalom létrehozása"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön megbízásai"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb megbízások"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek aktív megbízások"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megbízások"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgált személy"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgált személy, kivéve engem"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók felülvizsgált személyek"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy keresése"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítva"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden állapot"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teendő"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgáló személy"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felülvizsgáló személy, kivéve engem"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzám rendelve"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók felülvizsgáló személyek"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy keresése"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " nap"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " óra"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " perc"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. a."])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelés módosítása"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület-felhasználó"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi felülvizsgáló személy"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új célkitűzés ehhez a ciklushoz"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új felülvizsgáló személy"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy kiválasztása"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések hozzárendelésének módosítása"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés hozzárendelésének módosítása"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áthelyezendő beszélgetések"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi ciklus"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás szerkesztése"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók felülvizsgáló személyek"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy keresése"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések hozzárendelésének módosítása rám"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetések hozzárendelésének módosítása más felhasználóra"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személyek"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nap"]), _normalize([_interpolate(_named("n")), " nap"]), _normalize([_interpolate(_named("n")), " nap"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " óra"]), _normalize([_interpolate(_named("n")), " óra"]), _normalize([_interpolate(_named("n")), " óra"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesített ciklusok elrejtése"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesített alkalmak elrejtése"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saját célkitűzés"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy okot a beszélgetés eltávolítása érdekében"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarázza el, miért távolítja el ezt a beszélgetést"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés eltávolítása"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné megtartani a módosításokat?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy okot a beszélgetés cseréje érdekében"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások elvetése"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtartás"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások megtartása"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csere nem található"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyarázza el, miért cseréli le ezt a beszélgetést"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még mindig szeretné eltávolítani ezt a beszélgetést a megbízásból? Ezzel eggyel csökken a célkitűzés."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csere"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés cseréje"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat törlése"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat szerkesztése"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelés módosítása rám"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelés módosítása más felhasználóra"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzárendelés módosítása és felülvizsgálat"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés eltávolítása"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálat létrehozása"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kevesebb megjelenítése"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több megjelenítése"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Célkitűzés: ", _interpolate(_named("goal")), "/", _interpolate(_named("count")), " felülvizsgálva"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész felülvizsgálatok"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesített ciklusok megjelenítése"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesített alkalmak megjelenítése"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennmaradó idő"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladatok"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés cserélve"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladatok listájának váltása"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " múlva"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " másodperc"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " másodperc"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " n"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " n ", _interpolate(_named("hours")), " ó"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " n ", _interpolate(_named("hours")), " ó ", _interpolate(_named("minutes")), " p"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " n"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " nap"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " n ", _interpolate(_named("minutes")), " p"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ó"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ó ", _interpolate(_named("minutes")), " p"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ó ", _interpolate(_named("minutes")), " p ", _interpolate(_named("seconds")), " mp"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ó ", _interpolate(_named("seconds")), " mp"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " p"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " p ", _interpolate(_named("seconds")), " mp"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " hó"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " mp"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " év"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épp most"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányoznak az engedélyei. Segítségért vegye fel a kapcsolatot a rendszergazdájával."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett oldal nem létezik."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váratlan hiba történt. Segítségért vegye fel a kapcsolatot a támogatással."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés megtagadva. Nem engedélyezett a művelet végrehajtása."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett oldal nem létezik, vagy Ön nem férhet hozzá."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön kicsit túl gyorsan halad, és nehezünkre esik tartani a tempót."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások elmentve"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem mentett módosítások történtek"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " alkalmazva"]), _normalize([_interpolate(_named("n")), " alkalmazva"]), _normalize([_interpolate(_named("n")), " alkalmazva"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatva"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozva"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozta"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritikus"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke:"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["korábbi tag"]), _normalize(["egy korábbi tag"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itt"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kijelölve"]), _normalize([_interpolate(_named("n")), " kijelölve"]), _normalize([_interpolate(_named("n")), " kijelölve"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utoljára frissítve"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["…és +1 további"]), _normalize(["…és +", _interpolate(_named("n")), " további"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["további 1"]), _normalize(["további ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["új"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értéke nem:"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítva"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"]), _normalize([_interpolate(_named("n")), " válasz"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhasználó"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["péntek"]), _normalize(["péntekek"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["hétfő"]), _normalize(["hétfők"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["szombat"]), _normalize(["szombatok"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["vasárnap"]), _normalize(["vasárnapok"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["csütörtök"]), _normalize(["csütörtökök"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["kedd"]), _normalize(["keddek"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["szerda"]), _normalize(["szerdák"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tegnap"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["rendszergazda"]), _normalize(["egy rendszergazda"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fiókkezelő"]), _normalize(["egy fiókkezelő"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["felhasználó"]), _normalize(["egy felhasználó"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolva"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További tudnivalók"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárt a munkamenete."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezések"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a mező nem lehet üres."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók elemek."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek egyező személyek."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tulajdonos"]), _normalize(["egy tulajdonos"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További tudnivalók"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További tudnivalók"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További tudnivalók."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mielőtt elkezdenénk, olvassa el és fogadja el a feltételeinket, és már indulhatunk is."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldve"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztos benne?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elkészült felülvizsgálatok összesen"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítve"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["munkatárs"]), _normalize(["egy munkatárs"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["potenciális vásárló"]), _normalize(["egy potenciális vásárló"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["vezető"]), _normalize(["egy vezető"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["felülvizsgáló személy"]), _normalize(["egy felülvizsgáló személy"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Névtelen felhasználó"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiválás"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölt szakember"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítás"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezárás"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamarosan"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatás"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a másoláshoz"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozás másolása"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veszélyzóna"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítás"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás (nem kötelező)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítés szükséges"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letiltás"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elveti a nem mentett módosításokat?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezés"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportálás"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzések"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrés"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyakoriság"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célkitűzés"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendben"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálat"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMP"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soha"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikerességi arány"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes beállítások"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rövidebb szöveg"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újraküldés"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszaállítás"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgált személy"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapott/megtekintett felülvizsgálatok"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületi tag által elkészített felülvizsgálatok"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerep"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítások mentése"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bináris skála"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 pontos skála"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 pontos skála"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 pontos skála"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlap"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frekvencia kiválasztása"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kevesebb megjelenítése"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Több megjelenítése"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kihagyás"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiemelés"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépés"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldés"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérési visszajelzések"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérések"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időtartomány"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem mentett módosítások történtek"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súly"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " meghívta Önt, hogy csatlakozzon a Zendesk QA szolgáltatáshoz."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A felhasználók automatikusan csatlakoznak a Felügyeleti központból. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók kezelése"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felhasználókezelésről"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Néhány felhasználó ellátása és szinkronizálása automatikusan megtörténik az identitásszolgáltatójától. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["„", _interpolate(_list(0)), "” fiókengedélye módosítva"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a felhasználói engedélyekről"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne kérdezze meg újra"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A felhasználók oldalán végrehajtott összes módosítás tükrözve lesz a munkaterületen. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez azt jelenti, hogy a felhasználó hozzáférése a Zendesk QA bizonyos részeihez változhat"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókszintű engedély módosítása"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó eltávolítva"]), _normalize([_interpolate(_named("n")), " felhasználó eltávolítva"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ha eltávolít egy felhasználót, az illető elveszíti a hozzáférését az Ön fiókjához. A felhasználó felülvizsgálatai, hozzászólásai és értékelései megmaradnak a Zendesk QA szolgáltatásban. ", _interpolate(_named("linebreak")), "A felhasználó eltávolítása nem befolyásolja az előfizetésben lévő helyek számát."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ez a felhasználó el lesz távolítva az Ön által kezelt összes munkaterületből. ", _interpolate(_named("linebreak")), " A felülvizsgálatai, hozzászólásai és értékelései érintetlenek maradnak."]), _normalize(["El lesz távolítva az Ön által kezelt összes munkaterületről. ", _interpolate(_named("linebreak")), " Minden felülvizsgálata, hozzászólása és értékelése érintetlen marad."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " eltávolítása"]), _normalize([_interpolate(_named("name")), " felhasználó eltávolítása"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Láthat mindent, valamint kezelhet minden beállítást, beleértve a számlázást is"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Láthat mindent, valamint kezelheti a személyes és munkaterületi beállításokat, a számlázás kivételével"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókengedélyek"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterület-specifikus engedélyek lehetővé teszik a felhasználók számára, hogy megtekinthessék és szerkesszék az adatokat azon munkaterületeken belül, amelyekben szerepelnek."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a RobotQA-ről"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A robotok itt jelennek meg, amint szinkronizálva vannak azok a beszélgetések, amelyekben részt vettek"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek szinkronizált robotok"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha felhasználóként jelöl meg egy robotot, hozzáférést biztosít neki a bejelentkezéshez. A robot előzményadatai továbbra is elérhetők maradnak az irányítópultokon."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók ilyen robotok"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja meg újra a keresést más kulcsszóval, vagy ellenőrizze, nem írta-e el a kulcsszót."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok keresése"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés robot neve szerint"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés felhasználóként"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak rendszergazdák és fiókkezelők jelölhetnek meg robotokat felhasználóként"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot neve"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó csevegés"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuálisan megjelölve"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálható"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A robot eltávolításra kerül az automatikus elemzésből és a hozzárendelésekből. Az új adatok nem jelennek meg az irányítópultokon, így biztosítható a pontos jelentéskészítés és a könnyebb robotkezelés."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generatív robot"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismeretlen"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkafolyamat-robot"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás csoport(ok)hoz"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csoport"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs csoport"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás csoport(ok)ból"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport kiválasztása"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókengedély"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadása munkaterület(ek)hez"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes név"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utoljára aktív"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiókszintű szerep"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felhasználóhoz fiókszintű és/vagy munkaterületi szintű engedély van hozzárendelve. A rendszergazdai engedélyek most már fiókszintűek, míg a többi engedély a munkaterület szintjén van."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás munkaterület(ek)ből"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrás"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületi engedély"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportban kezelt engedélyek"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részletek szerkesztése"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelölés robotként"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha robotként jelöl meg egy felhasználót, azzal eltávolítja a bejelentkezéshez való hozzáférését. A felhasználói adatai előzményei elérhetőek maradnak az irányítópultokon."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A robot eltávolítása az automatikus elemzésből és a hozzárendelésekből. Az új adatok nem jelennek meg az irányítópultokon, így biztosítható a pontos jelentéskészítés és a könnyebb robotkezelés."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robot kizárása a felülvizsgálatokból"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó robotként megjelölve"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a Felhasználó engedélytípus jelölhető meg robotként"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A több fiókkal rendelkező felhasználók nem jelölhetők meg robotként"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés felhasználó szerint"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robotok"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók, robotok és munkaterületek"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MUNKATERÜLETEK"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók és munkaterületek"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszergazda"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek engedélyek"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatva"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuálisan hozzáadva"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függőben"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívó függőben"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-felhasználók"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intézkedés szükséges"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["…és még egy"]), _normalize(["…és még ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedély módosítása erre:"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Távolítson el és hívjon meg újból ", _interpolate(_named("n")), " függőben lévő meghívott felhasználót a szerepe módosításához."]), _normalize(["Távolítson el és hívjon meg újból ", _interpolate(_named("n")), " függőben lévő meghívott felhasználót a szerepük módosításához."])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " részleteinek szerkesztése"]), _normalize([_interpolate(_named("n")), " kijelölt felhasználó részleteinek szerkesztése"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó frissítve."]), _normalize([_interpolate(_named("n")), " felhasználó frissítve."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ez a csoport 1 felhasználót tartalmaz egy másik munkaterületből."]), _normalize(["Ez a csoport ", _interpolate(_named("n")), " felhasználót tartalmaz egy másik munkaterületből."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiválás"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport archiválva"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivált csoport"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport létrehozása"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport létrehozva"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Névtelen csoport"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véglegesen törli ezt a csoportot?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport törlése"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport törlése"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport szerkesztése"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportvezető"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport szerepe"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport elrejtve"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a csoport nem lesz látható sehol máshol a Zendesk QA szolgáltatásban"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a munkaterületi engedélyek kezelése"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csoport nem látható a Zendesk QA szolgáltatásban"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület-vezető"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Névtelen csoport"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy másik csoportnak már ez a neve"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy archivált csoportnak már ez a neve"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön szerepe csak e csoport tagjainak a kezelését teszi lehetővé. További módosításokért vegye fel a kapcsolatot egy magasabb engedélyekkel rendelkező felhasználóval."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eltávolítás csoportvezetőként"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport visszaállítva"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport elmentve"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítás csoportvezetőként"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport archiválása visszavonva"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem mentett módosítások"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meglévő felhasználóinak hozzáadása ehhez a csoporthoz"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés név vagy e-mail-cím szerint"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagok hozzáadása"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["„", _interpolate(_named("name")), "” csoport látható"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minden tag ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a csoportokról"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csoportok néhány attribútumának ellátása és szinkronizálása automatikusan megtörténik az identitásszolgáltatójától."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudnivalók a csoportokról"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport létrehozása"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyszerűen rendszerezheti a csapattagokat és utánozhatja a szervezeti struktúrát."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá legalább 1 tagot"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a csoport nevét"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Már létezik ilyen nevű csoport"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoporttagok"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoport neve"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszerezheti a csapattagokat és utánozhatja a szervezeti struktúrát."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagok"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nincsenek csoportok"]), _normalize(["1 csoport"]), _normalize([_interpolate(_named("count")), " csoport"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy új csoportot, vagy aktiváljon egy archiváltat."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem érhetők el aktív csoportok"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek archivált csoportok"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók ilyen csoportok"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálja meg újra a keresést más kulcsszóval, vagy ellenőrizze, nem írta-e el a kulcsszót."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek hozzáadott csoportok"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ezekben:"]), _normalize(["ebben:"]), _normalize(["ezekben:"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés csoportnév szerint"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivált"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Az újraküldés ekkor lehetséges: ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " hely maradt"]), _normalize([_interpolate(_named("n")), " hely maradt"]), _normalize([_interpolate(_named("n")), " hely maradt"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A helyek száma jelenleg korlátozott, mivel az előfizetése szünetel. Aktiválja újra további felhasználók hozzáadásához."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte a fiók helyeinek korlátját. Felhasználók cseréjéhez deaktiválhat egy aktív felhasználót, vagy hozzáadhat további helyeket."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési részletek megtekintése"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kijelölve"]), _normalize([_interpolate(_named("n")), " kijelölve"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó kijelölve"]), _normalize([_interpolate(_named("n")), " felhasználó kijelölve"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " sor"]), _normalize([_interpolate(_named("n")), " sor"]), _normalize([_interpolate(_named("n")), " sor"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömegesen kereshet a felhasználók között nevük vagy e-mail-címük szerint."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illesszen be ide adatokat vesszővel vagy sortöréssel elválasztva"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók tömeges keresése"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " munkaterületi engedélyeinek szerkesztése"]), _normalize([_interpolate(_named("n")), " kijelölt tag munkaterületi engedélyeinek szerkesztése"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyek szerkesztése"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületi engedély kiválasztása"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " eltávolítása a munkaterületről"]), _normalize([_interpolate(_named("name")), " tag eltávolítása a munkaterületről"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " tag eltávolítva"]), _normalize([_interpolate(_named("n")), " tag eltávolítva"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag eltávolítása"]), _normalize(["Tagok eltávolítása"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ez a felhasználó el lesz távolítva a munkaterületről."]), _normalize(["Ezek a felhasználók el lesznek távolítva a munkaterületről."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["„", _interpolate(_list(0)), "” munkaterületi engedélye módosítva."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagok hozzáadása"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintheti a beszélgetéseket és a kapcsolódó felülvizsgálatokat"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindent megtekinthet a munkaterületen, de csak a kvízeket, megbízásokat, ellenvetéseket és kalibrációs alkalmakat kezelheti"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületi engedélyek"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindent megtekinthet és kezelhet a munkaterületen"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindent megtekinthet a munkaterületen, a munkaterületi beállítások kivételével"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["csatlakoztatása"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön által kezelt munkaterületekből az összes felhasználó hozzá lett adva ehhez a munkaterülethez."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjen meg egy rendszergazdát, hogy végezze ezt el."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hívjon meg felhasználókat közvetlenül Ön, vagy kérjen meg egy rendszergazdát, hogy végezze ezt el."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " vagy ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs megjeleníthető felhasználó"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " felhasználó hozzáadva"]), _normalize([_interpolate(_named("n")), " felhasználó hozzáadva"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Tagok hozzáadása"]), _normalize(["Tag hozzáadása"]), _normalize([_interpolate(_named("n")), " tag hozzáadása"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületi engedély kiválasztása"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók kiválasztása a munkaterülethez"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tagok hozzáadása ehhez: ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illesszen be ide e-mail-címeket sortöréssel vagy vesszővel elválasztva"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mail-cím megfeleltetve"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" már csatlakozott felhasználókkal:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nem sikerült ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " e-mail-címet megfeleltetni"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze a helyesírást, vagy csatlakoztassa őket később egyenként"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ügyfélszolgálati felhasználókkal:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó csatlakoztatva"]), _normalize([_interpolate(_named("n")), " felhasználó csatlakoztatva"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó meghívva"]), _normalize([_interpolate(_named("n")), " felhasználó meghívva"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömeges csatlakoztatás"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömeges meghívás"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például:\n\nkati", "@", "example.com, jani", "@", "example.com\n\nkati", "@", "example.com\nkati", "@", "example.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztasson az ügyfélszolgálatából/ügyfélszolgálataiból az interakcióik felülvizsgálatához"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felhasználó csatlakoztatása"]), _normalize(["Felhasználók csatlakoztatása"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailek"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatás egyénileg"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha szeretné, hogy a csatlakoztatott felhasználók hozzáférjenek a Zendesk QA  szolgáltatáshoz, itt meghívhatja őket."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozott felhasználók meghívása"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felhasználó meghívása"]), _normalize(["Felhasználók meghívása"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás kihagyása"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon csatlakoztatandó felhasználókat az ügyfélszolgálatából/ügyfélszolgálataiból az interakcióik felülvizsgálatához"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetés kezelése"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati felhasználók csatlakoztatása"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon egyedi nevet a munkaterületének"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha engedélyezve van, az összes meglévő fiókkapcsolat automatikusan hozzáadódik az új munkaterülethez."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kapcsolat hozzáadása ehhez a munkaterülethez"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület létrehozása"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület neve"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület létrehozása"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiókhoz közvetlenül (munkaterület hozzárendelése nélkül) hozzáadott felhasználók fiókszintű szerepet kapnak. Később hozzáadhatja őket a munkaterületekhez."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meghívott felhasználókhoz automatikusan hozzá lesz rendelve egy felhasználói szerep a fiók szintjén. Később rendszergazdai szerepre frissítheti őket."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezek mind működnek"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illesszen be ide e-mail-címeket sortöréssel vagy vesszővel elválasztva."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felhasználó meghívva e-mailben"]), _normalize([_interpolate(_named("n")), " felhasználó meghívva e-mailben"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A munkaterületekhez hozzáadott felhasználókhoz automatikusan hozzá lesz rendelve egy felhasználói szerep a fiók szintjén. Később rendszergazdai szerepre frissítheti őket."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másik hozzáadása"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók meghívása csak a fiókba, munkaterülethez való hozzáadásuk nélkül"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A meghívott felhasználók csak egy fiókengedélyt kapnak, és nincsenek hozzáadva semmilyen munkaterülethez."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók meghívása közvetlenül egy munkaterületbe, és munkaterületi engedélyek hozzárendelése"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók hozzáadása a munkaterülethez"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömeges meghívás"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás egyenként"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás csak a fiókba"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívás egy munkaterületbe"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Felhasználók meghívása"]), _normalize(["Felhasználó meghívása"]), _normalize([_interpolate(_named("n")), " felhasználó meghívása"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók tömeges meghívása e-mailen keresztül"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Felhasználó törlése"]), _normalize(["Felhasználók törlése"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelölés megszüntetése"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók meghívása e-mailen keresztül"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek munkaterületek"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedély kiválasztása"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meghívó újraküldése"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakoztatott felhasználó"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függőben lévő e-mailes meghívású felhasználó"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-felhasználó"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterület kiválasztása"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-munkatársak"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felügyeleti központ"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minőség-ellenőrzés"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaerő-kezelés"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " teljesítve"]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " teljesítve"]), _normalize([_interpolate(_named("total")), "/", _interpolate(_named("n")), " teljesítve"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleNr")), ". ciklus"])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), " – ", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgáló személy"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás szerkesztése"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Időt takaríthat meg, ha automatikus felülvizsgálat-megbízásokat állít be, ahelyett hogy manuálisan keresné meg és osztaná ki a felülvizsgálandó beszélgetéseket."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lejárat: ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak a beszélgetésnézet oldalsó menüjének „Kalibráció” szakasza alatt adhatók hozzá."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek hozzáadott alkalmak"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alapérték"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek alkalmak"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy adhat hozzá beszélgetéseket egy alkalomhoz, hogy a kalibráció ikonra kattint a beszélgetésnézet fejlécében."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek hozzáadott beszélgetések"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Még nincsenek létrehozott felülvizsgálatok. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalibrációs felülvizsgálat hagyása"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapvető ok"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrás ide:"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A parancspaletta betöltése sikertelen volt."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs találat."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtalálva azonosító alapján"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parancspaletta betöltése…"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oldalak, szűrők, beszélgetések, beállítások…"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát szűrő"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános szűrő"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Innen: ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön API-jából"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("rangeStart")), "–", _interpolate(_named("rangeEnd")), ". ", _interpolate(_named("label")), " látható (összesen: ", _interpolate(_named("total")), ")"]), _normalize([_interpolate(_named("rangeStart")), "–", _interpolate(_named("rangeEnd")), ". ", _interpolate(_named("total")), " látható (összesen: ", _interpolate(_named("label")), ")"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalsó panel elrejtése"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalsó panel megjelenítése"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldalsáv váltása"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadott ellenvetések"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasított ellenvetések"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott ellenvetések"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részben elfogadott ellenvetések"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész felülvizsgálatok"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Látott felülvizsgálatok"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elfogadva"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nyitott"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["részben elfogadva"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " az összeshez képest"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elutasítva"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadva"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részben elfogadva"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítva"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["felülvizsgálat"]), _normalize(["felülvizsgálat"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azon felülvizsgálatainak száma, amelyet láttak felülvizsgált személyek"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azon felülvizsgálatainak száma, amelyet láttak"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetést kapott felülvizsgáló személyek"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetést adók"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések kategória szerint"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések időbeli alakulása"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos megoldási idő"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos megoldási idő (ó)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos megoldási idő (p)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megoldva"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenvetések összesen"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag kiválasztása"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadva"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasz nyelve"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók nyelvek"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden nyelv"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előrejelzett előremozdítók"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók előremozdítók"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden előremozdító"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordcloud-szavak"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók szavak"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden szó"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Említések"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legújabb"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legrégebbi"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitott"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részben elfogadva"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " így reagált: ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elutasítva"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megoldva"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felülvizsgálatok"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bőrszín kiválasztása"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmak"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók alkalmak"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategóriák"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók kategóriák"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólások"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzászólás mérete"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók méretek"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden méret"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók szűrők"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csoportok"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók csoportok"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csoport"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagek"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók hashtagek"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden hashtag"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók értékek"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden érték"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók egyéni mezők"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszolgálati címkék"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók ügyfélszolgálati címkék"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden ügyfélszolgálati címke"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Találatok betöltése…"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdéstípusok"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók kérdéstípusok"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reakció hozzáadása"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelőlapok"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók értékelőlapok"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden értékelőlap"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszámok"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók pontszámok"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden pontszám"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Források"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók források"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden forrás"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszajelzés oka"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók okok"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden ok"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felmérések"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók felmérések"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felmérés"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatornák"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók csatornák"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden csatorna"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználók"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók felhasználók"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden felhasználó"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találhatók munkaterületek"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden munkaterület"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a kvízekhez"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz törölve lett, vagy még nem lett közzétéve. Ellenőrizze a hivatkozást, vagy navigáljon vissza a kvízlistához."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a kvíz nem lett megosztva olyan munkaterülettel, amelyhez Ön tartozik. Kérjen meg egy rendszergazdát, hogy adja hozzá Önt egy olyan munkaterülethez, amelyhez a kvíz hozzáfér."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz nem érhető el"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz hozzáférése olyan munkaterület(ek)nek van megadva, amely(ek)hez Ön nem tartozik. A kvíz mentése és közzététele sikertelen volt."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjen meg egy rendszergazdát, hogy mentse és tegye közzé a kvízt."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meg kell jelölni legalább az egyik választ helyesként."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a mező nem lehet üres."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg a kvíz előnézetét látja."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki, hogy mely munkaterületek férhetnek hozzá a kvízhez. A több munkaterülethez tartozó felhasználók csak egyszer válaszolhatnak a kvízre. A „Minden munkaterület” lehetőség kiválasztása nem fogja magában foglalni a kvíz közzététele után újonnan létrehozott munkaterületeket."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízhozzáférés"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiválás"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépés az előnézeti módból"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportálás"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előnézet"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdések"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz kitöltése"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz közzététele"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz beállításai"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés vázlatként"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kvíz létrehozása"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiválás visszavonása"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lehetőségek"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség hozzáadása"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jól néz ki, ugye?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A válaszát elküldtük"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz mentve lett"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz közzé lett téve"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz el lett távolítva"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés hozzáadása"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz archiválása"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az archivált kvíz és annak eredményei többé nem fognak megjelenni a kvízek listáján. Továbbra is hozzáférhet az archivált kvízek eredményeihez, és szükség esetén visszavonhatja az archiválásukat."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz archiválása"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzététel"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A közzététel után a kvíz nem módosítható és nem szerkeszthető. Minden közzétett kvíz elérhető lesz a fiók összes felhasználója számára."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzéteszi a kvízt?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan kívánja törölni ezt a kvízt? Ez a művelet nem vonható vissza."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törli a kvízt?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldésük után a válaszait nem lehet megváltoztatni?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldi a kvízt?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz archiválásának visszavonása"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az archiválás visszavonásával a kvíz elérhetővé válik a kvízáttekintésben. Lehetővé teszi az előző résztvevők számára, hogy hozzáférjenek az eredményeikhez, és az új résztvevők számára, hogy kitöltsék a kvízt."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz archiválásának visszavonása"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés duplikálása"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás (nem kötelező)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés áthelyezése lejjebb"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés áthelyezése feljebb"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előnézetben csak a válaszokkal rendelkező kérdések vannak megjelenítve."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés eltávolítása"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz beküldése"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Névtelen kvíz"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz címe"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel véglegesen törli a kvízt és annak eredményeit."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatív megoldásként továbbra is hozzáférhet az eredményekhez a kvíz archiválásával."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megválaszolt"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz törlése"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel véglegesen törli a kvízt és annak eredményeit."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kvíz törlése"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplikálás"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvízek segítenek a csapat bizalmának és képességeinek növelésében, következetes élmények biztosításában és az új csapattagok betanításában."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kereshet a kvízek között a kvíz neve vagy a szerző neve szerint."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek létrehozott kvízek"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek listájának exportálása"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átl. pontszám"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesítési dátum"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz/szerző"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz/szerző/munkaterületek"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résztvevők"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzétéve"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszám"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkaterületek"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés kvíz, szerző szerint"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivált"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vázlat"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kvíz"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz archiválva lett."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kvíz archiválása vissza lett vonva."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a kvíz nem kapott válaszokat a kiválasztott munkaterület(ek)hez tartozó felhasználóktól."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a kvíz nem kapott válaszokat az archiválás előtt."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek válaszok"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt láthatja a kvíz válaszainak részletezését.\nMég senki nem töltötte ki a kvízt – bizonyára segít, ha megosztja."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincsenek válaszok"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos pontszám:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " – másolat"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozás másolva"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs találat"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " kvízei"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ranglistán megkeresheti a résztvevő nevét."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranglista exportálása"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználó"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvíz"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontszám"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés résztvevő szerint"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranglista"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvízek"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranglista váltása"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez azt jelenti, hogy nem fog tudni erre a hashtagre szűrni az irányítópulton, és többé nem történik meg az automatikus kiegészítése sem a hozzászólásokban. Ez a művelet nem vonható vissza."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag, hashtag, hashtag, …"])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagek létrehozása"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagek létrehozása"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtagek létrehozása"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új hashtagek létrehozásának engedélyezése a tagok számára"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használva"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag törlése"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), " hashtag törlése"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hashtagek létrehozása zárolva van"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hashtagek létrehozása fel van oldva"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), " hashtag törölve"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A(z) ", _interpolate(_list(0)), " hashtag átnevezve erre: ", _interpolate(_list(1))])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 hashtag hozzáadva"]), _normalize([_interpolate(_named("count")), " hashtag hozzáadva"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új név"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek létrehozott hashtagek"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átnevezés"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A(z) ", _interpolate(_named("tagRenamed")), " hashtag átnevezése"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hashtageket válassza el vesszővel vagy sortöréssel"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtag keresése"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ne aggódjon! Ezt megteheti ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["itt"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat csatlakoztatva a munkaterülethez"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat csatolása"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat csatolása ehhez a munkaterülethez"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye fel a kapcsolatot a rendszergazdájával, ha bármilyen változtatást szeretne itt végezni"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegye fel a kapcsolatot a Zendesk QA  szolgáltatással, ha bármilyen változtatást szeretne itt végezni"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapcsolatok a fiók alatt"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úgy tűnik, még nem alakított ki integrációt ügyfélszolgálati platformmal."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kapcsolat törlésével eltávolítja a beszélgetéseket a(z) ", _interpolate(_named("workspaceName")), " munkaterületből, és törli az ezekhez kapcsolódó összes adatot, beleértve a felülvizsgálatokat és az ellenvetéseket is."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat törlése"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connectionName")), " kapcsolat törlése"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat kiválasztása"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A kiválasztott időszakon belül bizonyos aktív AutoQA-kategóriák nem voltak jelen az értékelőlapján. Előfordulhat, hogy a(z) ", _interpolate(_named("date")), " napi utolsó módosítása előtti beszélgetések nem lettek analizálva e kategóriák szempontjából."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt fog megjelenni minden Önnek adott visszajelzés. Még semmit nem lehet itt megjeleníteni."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek kapott felülvizsgálatok."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a hozzászólási tevékenységek megjelenítése"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt fog megjelenni minden Ön által adott visszajelzés. Kezdjen a Beszélgetések nézetben."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek adott felülvizsgálatok."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adott felülvizsgálatok"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett felülvizsgálatok"])}
  }
}