import { api } from '@/api'

import type { UserGroup, UserGroupPayload, UpdateSCIMUserGroupPayload, UserGroupsListResponse } from './types'

export const getUserGroupsList = (params?: { archived?: boolean }) => {
  const query = new URLSearchParams()
  if (params?.archived) query.append('archived', 'true')
  return api.get(`user-groups?${query.toString()}`).json<UserGroupsListResponse>()
}

export const getUserGroupsWithMembers = ({
  workspaceId,
  reviewersOnly,
}: {
  workspaceId: number
  reviewersOnly?: boolean
}) =>
  api
    .get('user-groups/members', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      searchParams: { reviewersOnly: reviewersOnly ?? false },
    })
    .json<{ groups: UserGroup[] }>()

export const getUserGroup = (id: string) => api.get(`user-groups/${id}`).json<UserGroup>()

export const createUserGroup = (group: UserGroupPayload) => api.post('user-groups', { json: group })

export const updateUserGroup = (id: string, group: UserGroupPayload) =>
  api.put(`user-groups/${id}`, { json: group }).json<UserGroup>()

export const updateSCIMUserGroup = (id: string, group: UpdateSCIMUserGroupPayload) =>
  api.put(`user-groups/scim/${id}`, { json: group }).json<UserGroup>()

export const deleteGroup = (id: string) => api.delete(`user-groups/${id}`)

export const getUserGroupsCount = (params?: { archived?: boolean }) =>
  api
    .get('user-groups-count', { searchParams: params?.archived ? { archived: params.archived } : undefined })
    .json<{ count: number }>()

export const updateGroupVisibility = (id: string, params: { archived?: boolean; hidden?: boolean }) =>
  api.put(`user-groups/${id}/visibility`, { json: params })
