import { computed, watchEffect, type ComputedRef, ref } from 'vue'
import { capitalize } from 'lodash-es'
import i18n from '@/i18n'
import { bus } from '@/utils/bus'
import type { TicketReply } from '@/modules/conversations/types'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import useTicketTranscripts from './useTicketTranscripts'
import useCallRecordingUrl from './useCallRecordingUrl'

type Props = ComputedRef<{
  comment: TicketReply
  connectionId: number
  source: string
}>

export default function useCall(props: Props) {
  const comment = computed(() => props.value?.comment)
  const connectionId = computed(() => props.value?.connectionId)
  const source = computed(() => props.value?.source)

  const { transcripts } = useTicketTranscripts()
  const { transcriptionMessageId, messageId } = useTicketRouteParams()
  const {
    recordingUrl,
    recordingBlob,
    isFetching: loadingRecording,
    isError: recordingUrlError,
  } = useCallRecordingUrl(
    computed(() => ({
      connectionId: connectionId.value,
      comment: comment.value,
      source: source.value,
    })),
  )

  const invalidRecording = ref(false)

  const transcript = computed(currentCallsTranscript)
  const isFocused = computed(isInteractionFocused)
  const isBlurred = computed(isInteractionBlurred)

  watchEffect(() => {
    if (recordingUrlError.value) invalidRecording.value = true
  })

  function currentCallsTranscript() {
    return transcripts.value?.find((t) => t.externalCommentId === comment.value?.externalId)
  }

  function isInteractionFocused() {
    return comment.value?.id === messageId.value
  }

  function isInteractionBlurred() {
    return messageId.value && !isFocused.value
  }

  function handleSourceError(suppressError = false) {
    invalidRecording.value = true
    if (suppressError) return
    bus.$emit('universal-error', {
      error: i18n.t('conversations.ticket_reply.audio_error', [capitalize(source.value?.replace('_', ' '))]),
      ref: 'missing-external-audio-file',
    })
  }

  return {
    recordingUrl,
    recordingBlob,
    loadingRecording,
    invalidRecording,
    transcript,
    isFocused,
    isBlurred,
    selectedTranscriptionMessageId: transcriptionMessageId,
    handleSourceError,
  }
}
