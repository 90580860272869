import { useQuery } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import i18n from '@/i18n'
import { bus } from '@/utils/bus'
import { hasAccountRole } from '@/utils/roleUtils'
import type { WorkspaceRole } from '@/data/roles'

import { useWorkspaceList } from '@/composables/useWorkspaceList'
import { session } from '@/composables/useSession'
import { toast } from '@/components/toast'
import alertDialog from '@/components/alertDialog'

import {
  createUserGroup,
  deleteGroup,
  getUserGroupsList,
  updateGroupVisibility,
  updateSCIMUserGroup,
  updateUserGroup,
} from '../groups/api'
import {
  GroupRole,
  type UserGroupListItem,
  type UserGroupForm,
  type UserGroup,
  type UserGroupMember,
} from '../groups/types'

export default createSharedComposable(() => {
  const router = useRouter()
  const route = useRoute()

  const filter = ref('')
  const tab = ref('active')
  const form = ref<UserGroupForm>({ name: '', members: [], workspaceIds: [], workspaceRole: 'AGENT' })
  const removedMembers = ref<UserGroupMember[]>([])
  const hidden = ref(false)

  const [workspaces, reloadWorkspaceList] = useWorkspaceList({ managedOnly: true, loadOnMount: true })
  const activeQuery = useQuery({ queryKey: ['active-groups'], queryFn: () => getUserGroupsList() })
  const archivedQuery = useQuery({
    queryKey: ['archived-groups'],
    queryFn: () => getUserGroupsList({ archived: true }),
  })

  const activeCount = computed(() => activeQuery.data.value?.userGroups?.length || 0)
  const archivedCount = computed(() => archivedQuery.data.value?.userGroups?.length || 0)
  const hasGroups = computed(() => activeCount.value > 0 || archivedCount.value > 0)
  const isReadonly = computed(() => activeQuery.data.value?.readonly && !session.features.editableSCIMUsers)
  const isCreateGroupView = computed(() => route.name === 'settings.groups.create')
  const isArchivedGroupView = computed(() => route.name === 'settings.groups.archived')
  const isEditGroupView = computed(() => route.name === 'settings.groups.edit')

  const allGroups = computed(() => [
    ...(activeQuery.data.value ? activeQuery.data.value.userGroups : []),
    ...(archivedQuery.data.value ? archivedQuery.data.value.userGroups : []),
  ])

  const existingGroup = computed(() =>
    allGroups.value.find(
      (group) => group.name.toLowerCase() === form.value.name.toLowerCase() && group.id !== route.params.id,
    ),
  )

  const groupById = computed(() => {
    return allGroups.value.find((group) => group.id === route.params.id)
  })

  const createGroup = async () => {
    const { name, members } = form.value

    await createUserGroup({
      name: name.trim(),
      members: members.map((u) => ({ userId: String(u.id), role: u.role || GroupRole.Member })),
    })

    refetch()

    toast({ status: 'success', message: i18n.t('user_management.groups.group.created', { name }) })
    router.push({ name: 'settings.groups' })
  }

  const editGroup = async () => {
    let group: UserGroup

    if (groupById.value?.readonly) {
      group = await updateSCIMUserGroup(route.params.id as string, {
        members: form.value.members.map((u) => ({ userId: u.id.toString(), role: u.role || GroupRole.Member })),
        workspaceIds: form.value.workspaceIds.map((id) => id.toString()),
        workspaceRole: form.value.workspaceRole,
      })
      await updateGroupVisibility(route.params.id as string, { hidden: hidden.value })
    } else {
      group = await updateUserGroup(route.params.id as string, {
        name: form.value.name,
        members: form.value.members.map((m) => ({ userId: m.id.toString(), role: m.role || GroupRole.Member })),
      })
    }

    form.value = {
      name: group.name,
      members: group.members,
      workspaceIds: group.workspaces.map((ws) => parseFloat(ws.id)),
      workspaceRole: group.workspaceRole || 'AGENT',
    }

    await reloadWorkspaceList()
    const isWorkspaceManagerDowngradingThemselves = Boolean(
      groupById.value?.readonly &&
        form.value.members.map((m) => Number(m.id)).includes(session.user.id) &&
        hasAccountRole('USER') &&
        form.value.workspaceRole !== 'MANAGER',
    )

    if (isWorkspaceManagerDowngradingThemselves) window.location.href = '/'

    refetch()

    toast({
      status: 'success',
      message: i18n.t('user_management.groups.group.saved', { name: form.value.name }),
    })

    router.push({ name: 'settings.groups' })
  }

  const cancel = () => {
    router.push({ name: 'settings.groups' })
  }

  const removeGroup = async (group: UserGroupListItem) => {
    const { isConfirmed } = await alertDialog(`${i18n.t('user_management.groups.group.delete_body')}`, {
      title: i18n.t('user_management.groups.group.delete_prompt'),
      confirmButtonText: i18n.t('user_management.groups.group.delete_confirm_button'),
      redConfirmButton: true,
      allowHtml: true,
    })
    if (!isConfirmed) return
    await deleteGroup(group.id)
    refetch()

    toast({ status: 'success', message: i18n.t('universal.removed') })
  }

  const setArchived = async (archived: boolean, group: UserGroupListItem) => {
    await updateGroupVisibility(group.id, { archived })
    refetch()

    toast({
      status: 'success',
      message: i18n.t(`user_management.groups.group.${archived ? 'archived' : 'restored'}`, {
        name: group.name,
      }),
    })
  }

  const canEdit = (group: UserGroupListItem) =>
    group && !group.readonly && group.memberCount === group.visibleMemberCount

  const updateWorkspaceConfig = (role: WorkspaceRole, ids: number[]) => {
    form.value.workspaceRole = role
    form.value.workspaceIds = ids
  }

  const refetch = () => {
    activeQuery.refetch()
    archivedQuery.refetch()
    bus.$emit('groups-count-changed')
  }

  return {
    filter,
    tab,
    route,
    router,
    form,
    removedMembers,
    hidden,
    activeQuery,
    archivedQuery,
    activeCount,
    archivedCount,
    hasGroups,
    isReadonly,
    groupById,
    isCreateGroupView,
    isArchivedGroupView,
    isEditGroupView,
    existingGroup,
    createGroup,
    editGroup,
    cancel,
    removeGroup,
    setArchived,
    updateWorkspaceConfig,
    refetch,
    workspaces,
    canEdit,
  }
})
