import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_details_card = _resolveComponent("category-details-card")!
  const _component_predefined_reasons_card = _resolveComponent("predefined-reasons-card")!
  const _component_other_reasons_card = _resolveComponent("other-reasons-card")!

  return (_ctx.filterParams.categoryId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_category_details_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"]),
        _createVNode(_component_predefined_reasons_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"]),
        _createVNode(_component_other_reasons_card, { "filter-params": _ctx.filterParams }, null, 8 /* PROPS */, ["filter-params"])
      ]))
    : _createCommentVNode("v-if", true)
}