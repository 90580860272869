import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { createSharedComposable } from '@vueuse/core'
import { formatCount } from '@klausapp/shared/utils/number'
import { session } from '@/composables/useSession'
import { cleanParams, getUrlParams } from '@/utils/persistence'
import { FilterTimePeriod } from '@/modules/dashboard/types'
import analytics from '@/utils/analytics'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

import type { TicketListItemExt } from '../types'
import { pathName, activeFilterId } from './useConversationFilters'

export interface Filters {
  sort: string
  limit: number
  targetId: number
  workspace: number
  timePeriod: FilterTimePeriod
  fromDate?: string
  toDate?: string
}

export const TICKETS_PER_PAGE = 40

export const defaultFilters = {
  sort: 'newest',
  limit: TICKETS_PER_PAGE,
  targetId: 0,
  workspace: 0,
  timePeriod: FilterTimePeriod.Month,
}

// TODO: Move getTickets, loadCalibrationTicketList and refreshActiveFilter to this composable from ConversationsContentList
// so that ConversationsIndexView wouldn't have to rely on a timeout hack
export default createSharedComposable(() => {
  const ticketListCount = ref<number>()
  const ticketListLoading = ref(true)
  const ticketList = ref<TicketListItemExt[]>([])
  const searchQuery = ref<string>('')
  const filterParams = ref<Filters>({
    ...defaultFilters,
    workspace: session.workspace.id,
  })

  const router = useRouter()
  const route = useRoute()
  const { connectionId, conversationId } = useTicketRouteParams()

  const currentTicket = computed(
    () => (ticket) => ticket.externalId === conversationId.value && ticket.paymentTokenId === connectionId.value,
  )

  const formattedListCount = computed(() => {
    const len = ticketListCount.value

    if (!session.features.showExactTicketCount) {
      return len >= 999 ? '999+' : len
    }

    return len ? formatCount(len) : ''
  })

  const setTimeParams = (newParams: Filters) => {
    if (!newParams) return

    const { timePeriod, fromDate, toDate } = newParams
    if (timePeriod) filterParams.value.timePeriod = timePeriod

    const customTimePeriod = filterParams.value.timePeriod === FilterTimePeriod.Custom
    filterParams.value.fromDate = customTimePeriod && fromDate ? fromDate.split('T')[0] : undefined
    filterParams.value.toDate = customTimePeriod && toDate ? toDate.split('T')[0] : undefined
  }

  const resetFilterParams = () => {
    filterParams.value = { ...defaultFilters, workspace: session.workspace.id }
  }

  const updateFilterParamsFromUrl = () => {
    const urlParams = getUrlParams()
    const cleanedParams = cleanParams(defaultFilters, { ...urlParams })

    if (Object.keys(cleanedParams).length) {
      if ((cleanedParams as Filters).timePeriod === 'custom') setTimeParams(cleanedParams as Filters)

      Object.keys(filterParams.value).forEach((param) => {
        if (cleanedParams[param]) filterParams.value[param] = cleanedParams[param]
      })
    }
  }

  const nextTicket = computed(() => {
    if (!ticketList.value.length) return

    const index = ticketList.value.findIndex(currentTicket.value) + 1
    return index !== -1 && ticketList.value[index]
  })

  const previousTicket = computed(() => {
    if (!ticketList.value.length) return

    const index = ticketList.value.findIndex(currentTicket.value) - 1
    return index !== -1 && ticketList.value[index]
  })

  const getConversationRoute = ({ params, query }) => {
    const activeFilter = activeFilterId.value?.toString()
    const ticketParams = {
      connectionId: params.connectionId,
      conversationId: encodeURIComponent(params.conversationId),
      ...(params.messageId ? { messageId: encodeURIComponent(params.messageId) } : {}),
    }

    return {
      name: params.messageId ? 'conversations.message' : pathName.value,
      params: {
        ...ticketParams,
        ...(activeFilter?.startsWith('calibration.')
          ? {
              calibrationSessionId: activeFilter.replace('calibration.', ''),
            }
          : {}),
      },
      query,
    }
  }

  const navigateToPreviousTicket = (evt: KeyboardEvent | MouseEvent) => {
    if (previousTicket.value) {
      router.push(
        getConversationRoute({
          params: {
            connectionId: previousTicket.value.paymentTokenId,
            conversationId: previousTicket.value.externalId,
          },
          query: route.query,
        }),
      )
      analytics.shortcutUsed('key' in evt ? evt.key : 'click')
    }
  }

  const navigateToNextTicket = (evt: KeyboardEvent | MouseEvent) => {
    if (nextTicket.value) {
      router.push(
        getConversationRoute({
          params: {
            connectionId: nextTicket.value.paymentTokenId,
            conversationId: nextTicket.value.externalId,
          },
          query: route.query,
        }),
      )
      analytics.shortcutUsed('key' in evt ? evt.key : 'click')
    }
  }

  return {
    ticketListLoading,
    ticketList,
    ticketListCount,
    formattedListCount,
    searchQuery,
    filterParams,
    nextTicket,
    previousTicket,
    resetFilterParams,
    setTimeParams,
    updateFilterParamsFromUrl,
    getConversationRoute,
    navigateToPreviousTicket,
    navigateToNextTicket,
  }
})
