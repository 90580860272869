import { type CategoryFilter, CategoryFilterType, CategoryFilterConditionCode } from './types'
export interface CategoryItem {
  id: number
  tag: string
  ratingCategoryIds: number[]
}

export interface ScorecardItem {
  id: number
  name: string
}

export enum CategoryType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export const scorecardsPlaceholder: ScorecardItem[] = [
  {
    id: 1,
    name: 'Grade the Grader',
  },
  {
    id: 2,
    name: 'GTG Core/Core Plus',
  },
  {
    id: 3,
    name: 'GTG Premier',
  },
]

const categoriesPlaceholder: CategoryItem[] = [
  {
    id: 21932,
    tag: '-',
    ratingCategoryIds: [111765],
  },
  {
    id: 20985,
    tag: 'AM test scorecard',
    ratingCategoryIds: [107373],
  },
  {
    id: 1115,
    tag: 'assessment',
    ratingCategoryIds: [13118, 85985, 108486, 108487],
  },
  {
    id: 1114,
    tag: 'boop',
    ratingCategoryIds: [104199, 114563],
  },
  {
    id: 1110,
    tag: 'default',
    ratingCategoryIds: [
      10383, 11250, 10942, 11285, 10944, 10951, 10351, 10353, 11284, 10939, 10949, 10952, 13118, 10950, 10932, 10384,
      10948, 10940, 10941, 10946, 10407, 14117, 92029, 91053, 92240, 89153, 95580, 95621, 85985, 96655, 96868, 96872,
      99065, 96309,
    ],
  },
  {
    id: 16714,
    tag: 'do_not_change',
    ratingCategoryIds: [38681, 49173, 82274, 38680, 86879, 49177, 38679],
  },
  {
    id: 1116,
    tag: 'do not show me',
    ratingCategoryIds: [10932],
  },
  {
    id: 22123,
    tag: 'Dragons',
    ratingCategoryIds: [111765],
  },
  {
    id: 1113,
    tag: 'HERE',
    ratingCategoryIds: [],
  },
  {
    id: 20318,
    tag: 'newnew',
    ratingCategoryIds: [104199],
  },
  {
    id: 1112,
    tag: 'TAGS',
    ratingCategoryIds: [],
  },
  {
    id: 1111,
    tag: 'THREE',
    ratingCategoryIds: [],
  },
  {
    id: 21417,
    tag: 'tuuletest',
    ratingCategoryIds: [96309, 108487],
  },
  {
    id: 21471,
    tag: 'tuuletestkoopia',
    ratingCategoryIds: [96309, 108487],
  },
]

export interface Category {
  id: number
  name: string
  description?: string
  type: CategoryType
  scorecards: ScorecardItem[]
  updated?: string
}
interface GetCategoriesResponse {
  data: Category[]
  total: number // number of total items in data array
}

export const getCategories = async (): Promise<GetCategoriesResponse> => {
  await new Promise((resolve) => setTimeout(resolve, 1000))
  return {
    data: [
      {
        id: 1,
        name: 'Spelling and grammar',
        description: 'Spelling and grammar description',
        type: CategoryType.AUTO,
        scorecards: scorecardsPlaceholder,
        updated: '2024-10-01T11:39:57.118598',
      },
      {
        id: 2,
        name: 'Compliance',
        description: 'Compliance description',
        type: CategoryType.MANUAL,
        scorecards: scorecardsPlaceholder.slice(0, 1),
        updated: '2022-10-01T11:39:57.118598',
      },
      {
        id: 3,
        name: 'Greeting',
        type: null,
        scorecards: [],
        updated: '2024-09-01T11:39:57.118598',
      },
    ],
    total: 3,
  }
}

const rootCauses = [
  {
    id: 1,
    name: 'Lack of personalization',
    categories: categoriesPlaceholder.slice(0, 3),
    updated: '2024-10-01T11:39:57.118598',
  },
  {
    id: 2,
    name: 'Robotic language',
    categories: categoriesPlaceholder,
    updated: '2022-10-01T11:39:57.118598',
  },
  {
    id: 3,
    name: 'Failure to acknowledge emotions',
    categories: [],
    updated: '2024-09-01T11:39:57.118598',
  },
]

export interface RootCause {
  id: number
  name: string
  categories: CategoryItem[]
  updated: string
}

export interface GetRootCausesResponse {
  data: RootCause[]
  total: number
}

export const getRootCauses = async (): Promise<GetRootCausesResponse> => {
  await new Promise((resolve) => setTimeout(resolve, 1000))
  return {
    data: rootCauses,
    total: rootCauses.length,
  }
}

export const addRootCause = async (rootCause: Partial<RootCause>): Promise<RootCause> => {
  await new Promise((resolve) => setTimeout(resolve, 1000))
  const newRootCause = {
    id: Math.floor(Math.random() * 10000),
    name: rootCause.name,
    categories: [],
    updated: new Date().toISOString(),
  }
  rootCauses.push(newRootCause)
  return newRootCause
}

export const getCategoryConditions = async (): Promise<{ filters: CategoryFilter[] }> => {
  await new Promise((resolve) => setTimeout(resolve, 1000))
  return {
    filters: [
      {
        type: CategoryFilterType.SOURCE_TYPE,
        allowedMultiple: true,
        label: 'filter.option.label.source_type',
        conditions: [
          {
            condition: CategoryFilterConditionCode.STRING_LIST_EQUALS,
            labelCode: 'filter.condition.label.string_list_equals',
          },
          {
            condition: CategoryFilterConditionCode.STRING_LIST_NOT_EQUALS,
            labelCode: 'filter.condition.label.string_list_not_equals',
          },
        ],
        values: [
          {
            value: 'AIRCALL',
          },
          {
            value: 'BROWSER_EXTENSION',
          },
          {
            value: 'DEMO',
          },
          {
            value: 'DIXA',
          },
          {
            value: 'DRIFT',
          },
          {
            value: 'FRESHCHAT',
          },
          {
            value: 'FRESHDESK',
          },
          {
            value: 'FRONT',
          },
          {
            value: 'GENESYS',
          },
          {
            value: 'GORGIAS',
          },
          {
            value: 'HELP_SCOUT',
          },
          {
            value: 'HELPSHIFT',
          },
          {
            value: 'INTERCOM',
          },
          {
            value: 'KUSTOMER',
          },
          {
            value: 'LIVE_CHAT',
          },
          {
            value: 'MANUAL_IMPORT',
          },
          {
            value: 'SALESFORCE',
          },
          {
            value: 'TALKDESK',
          },
          {
            value: 'WIX',
          },
          {
            value: 'ZENDESK',
          },
          {
            value: 'ZENDESK_CHAT',
          },
        ],
      },
      {
        type: CategoryFilterType.TICKET_CHANNEL,
        allowedMultiple: true,
        label: 'filter.option.label.ticket_channel',
        conditions: [
          {
            condition: CategoryFilterConditionCode.STRING_LIST_EQUALS,
            labelCode: 'filter.condition.label.string_list_equals',
          },
          {
            condition: CategoryFilterConditionCode.STRING_LIST_NOT_EQUALS,
            labelCode: 'filter.condition.label.string_list_not_equals',
          },
        ],
        values: [
          {
            value: 'AutoQA Channel',
          },
          {
            value: 'Phone',
          },
          {
            value: 'Started by visitor',
          },
          {
            value: 'api',
          },
          {
            value: 'chat',
          },
          {
            value: 'conversation',
          },
          {
            value: 'email',
          },
          {
            value: 'native_messaging',
          },
          {
            value: 'pstnphone',
          },
          {
            value: 'sms',
          },
          {
            value: 'test channel',
          },
          {
            value: 'voice',
          },
          {
            value: 'web',
          },
          {
            value: 'widgetchat',
          },
        ],
      },
      {
        type: CategoryFilterType.TICKET_TAGS,
        allowedMultiple: true,
        label: 'filter.option.label.ticket_tags',
        conditions: [
          {
            condition: CategoryFilterConditionCode.STRING_LIST_EQUALS,
            labelCode: 'filter.condition.label.string_list_equals',
          },
          {
            condition: CategoryFilterConditionCode.STRING_LIST_NOT_EQUALS,
            labelCode: 'filter.condition.label.string_list_not_equals',
          },
        ],
        values: [],
      },
    ],
  }
}
