import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_users_icon = _resolveComponent("users-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_ui_form_field = _resolveComponent("ui-form-field")!

  return (_openBlock(), _createBlock(_component_ui_form_field, {
    label: _ctx.$t('coaching.sessions.landing_page.coach')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_select, {
        "data-testid": "select-coach",
        mode: "single",
        "model-value": _ctx.modelValue,
        options: _ctx.userList,
        placeholder: _ctx.$t('coaching.sessions.landing_page.coach_placeholder'),
        class: _normalizeClass([_ctx.$style.coachSelect, _ctx.error && _ctx.$style.error]),
        "no-results-text": _ctx.$t('universal.no_users_found'),
        "no-options-text": _ctx.$t('universal.no_users_found'),
        theme: "dark",
        "can-clear": "",
        object: "",
        "filter-results": false,
        onSearchChange: _ctx.searchCoaches,
        "onUpdate:modelValue": _ctx.handleChangeCoach
      }, {
        option: _withCtx(({ option }) => [
          (option.id === 'footer')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.$style.more)
              }, _toDisplayString(option.name), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        icon: _withCtx(({ open }) => [
          open
            ? (_openBlock(), _createBlock(_component_search_icon, {
                key: 0,
                class: "icon-m"
              }))
            : (_openBlock(), _createBlock(_component_users_icon, {
                key: 1,
                class: "icon-m"
              }))
        ]),
        afterlist: _withCtx(() => [
          (_ctx.moreCount > 0)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.$style.userName)
              }, _toDisplayString(_ctx.$t('universal.n_more', { n: _ctx.$n(_ctx.moreCount) }, _ctx.moreCount)), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model-value", "options", "placeholder", "class", "no-results-text", "no-options-text", "onSearchChange", "onUpdate:modelValue"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label"]))
}