import { capitalize } from 'lodash-es'
import i18n from '@/i18n'
import { translationExists } from '@/i18n/utils'
import { AgentDistributionCategory, TicketDistributionCategory } from '../../../../types'

export const categoryToLabel = (category: TicketDistributionCategory | AgentDistributionCategory) =>
  i18n.t(`conversations.visual_filters.categories.${category.toLowerCase()}.label`)

export const categoryTooltipTitle = (category: TicketDistributionCategory | AgentDistributionCategory) => {
  const key = `conversations.visual_filters.categories.${category.toLowerCase()}.tooltip_title`
  if (translationExists(key)) return i18n.t(key)
  else return ''
}

export const categoryTooltip = (category: TicketDistributionCategory | AgentDistributionCategory) => {
  const key = `conversations.visual_filters.categories.${category.toLowerCase()}.tooltip`
  if (translationExists(key)) return i18n.t(key)
  else return ''
}

export const nodeNameFallback = (name: TicketDistributionCategory) => {
  return i18n.t('conversations.visual_filters.unknown_category_name', [categoryToLabel(name)])
}

export const subCategoryLabel = ({ category, name, value }) => {
  if (Object.values(AgentDistributionCategory).includes(category) && value === '') {
    return i18n.t(`conversations.visual_filters.categories.${category.toLowerCase()}.sub_categories.not_a_klaus_user`)
  }

  if (['', '-'].includes(value)) return i18n.t('conversations.visual_filters.unknown')

  const i18nKey = `conversations.visual_filters.categories.${category.toLowerCase()}.sub_categories.${value}`
  if (translationExists(i18nKey)) return i18n.t(i18nKey)

  if (category === TicketDistributionCategory.Connection) return name
  if (category === TicketDistributionCategory.Language) return name.toUpperCase()

  return capitalize(name)
}
