import { createSharedComposable } from '@vueuse/core'
import { cloneDeep } from 'lodash-es'
import { type QuestionType } from '@zendesk/zqa-services/surveys'

import i18n from '@/i18n'
import alertDialog from '@/components/alertDialog'
import { toast } from '@/components/toast'
import useQuestionTypes from '@/composables/useQuestionTypes'
import { uploadFile } from '@/modules/shared/CommentEditor/plugins/image'

import {
  updateSurveyStatus,
  updateSurvey as updateSurveyApi,
  duplicateSurvey as duplicateSurveyApi,
  deleteSurvey as deleteSurveyApi,
  getSurvey,
} from '../api'
import { type SurveyConfig, SurveyStatus, type TriggerConditionValidationError, DEFINE_CUSTOM_TYPE } from '../types'
import { CUSTOM_QUESTION, mapQuestionFields } from '../utils'
import useSurvey from './survey/useSurvey'
import { defaultSurveyState } from './survey/defaultState'

export default createSharedComposable(() => {
  const { state, savedSurveyConfig, connectionsWithErrors } = useSurvey()
  const { getQuestionTypeById } = useQuestionTypes()

  const setSurveyStatus = async (id: string, status: SurveyStatus) => {
    await updateSurveyStatus(id, status)
    state.data.status = status
    savedSurveyConfig.value.status = status
  }

  const pauseSurvey = async (id: string, extraMessage?: string) => {
    const pauseMessages = [i18n.t('settings.survey.modal.pause.message')]
    if (extraMessage) pauseMessages.push(extraMessage)

    const { isConfirmed } = await alertDialog(pauseMessages.join('<br><br>'), {
      title: i18n.t('settings.survey.modal.pause.title'),
      confirmButtonText: i18n.t('settings.survey.edit.pause'),
      allowHtml: true,
    })

    if (!isConfirmed) return false

    await setSurveyStatus(id, SurveyStatus.PAUSED)

    toast({
      status: 'success',
      message: i18n.t('settings.survey.edit.toasts.paused'),
    })

    return true
  }

  const resumeSurvey = async (id: string, extraMessage?: string) => {
    const resumeMessages = [i18n.t('settings.survey.modal.resume.message')]
    if (extraMessage) resumeMessages.push(extraMessage)

    const { isConfirmed } = await alertDialog(resumeMessages.join('<br><br>'), {
      title: i18n.t('settings.survey.modal.resume.title'),
      confirmButtonText: i18n.t('settings.survey.edit.resume'),
      allowHtml: true,
    })

    if (!isConfirmed) return false

    await setSurveyStatus(id, SurveyStatus.ACTIVE)

    toast({
      status: 'success',
      message: i18n.t('settings.survey.edit.toasts.updated'),
    })

    return true
  }

  const publishSurvey = async (id: string, extraMessage?: string): Promise<boolean | PublishErrorResponse> => {
    const publishMessages = [i18n.t('settings.survey.modal.publish.message')]
    if (extraMessage) publishMessages.push(extraMessage)

    const { isConfirmed } = await alertDialog(publishMessages.join('<br><br>'), {
      title: i18n.t('settings.survey.modal.publish.title'),
      confirmButtonText: i18n.t('settings.survey.edit.publish'),
      allowHtml: true,
    })

    if (!isConfirmed) return false

    try {
      await setSurveyStatus(id, SurveyStatus.ACTIVE)

      toast({
        status: 'success',
        message: i18n.t('settings.survey.edit.toasts.published'),
      })
      return true
    } catch (e: any) {
      if (e.status === 400) return JSON.parse(e.body)
    }
  }

  const duplicateSurvey = async (id: string, name: string) => {
    const { isConfirmed } = await alertDialog(i18n.t('settings.survey.modal.duplicate_modal.content'), {
      title: i18n.t('settings.survey.modal.duplicate_modal.title', [name]),
      confirmButtonText: i18n.t('settings.survey.edit.duplicate'),
    })

    if (!isConfirmed) return false

    const response = await duplicateSurveyApi(id)
    if (response.configurationId) {
      toast({
        status: 'success',
        message: i18n.t('settings.survey.edit.toasts.duplicated'),
      })
    }

    return response
  }

  const deleteSurvey = async (id: string, brand: string, extraMessage?: string) => {
    const deleteMessages = [i18n.t('settings.survey.modal.delete.message')]
    if (extraMessage) deleteMessages.push(extraMessage)

    const { isConfirmed } = await alertDialog(deleteMessages.join('<br><br>'), {
      title: i18n.t('settings.survey.modal.delete.title', { surveyName: brand }),
      confirmButtonText: i18n.t('settings.survey.modal.delete.confirm_button'),
      redConfirmButton: true,
      allowHtml: true,
    })

    if (!isConfirmed) return false

    await deleteSurveyApi(id)

    toast({
      status: 'success',
      message: i18n.t('settings.survey.edit.toasts.deleted'),
    })

    return true
  }

  async function loadSurvey(id: string | undefined) {
    connectionsWithErrors.value = []
    try {
      state.isLoading = true
      state.notFound = false
      connectionsWithErrors.value = []

      if (!id) {
        state.data = defaultSurveyState.data
      } else {
        let survey = await getSurvey(id)

        if (!survey.questionTypeId) survey.question = CUSTOM_QUESTION

        const questionType = getQuestionTypeById(survey.questionTypeId) || {
          id: DEFINE_CUSTOM_TYPE,
          name: 'Custom',
          isCustom: true,
        }

        survey = mapQuestionFields(survey, questionType)
        state.data = survey
        savedSurveyConfig.value = cloneDeep(survey)
      }
      state.isLoading = false
    } catch (e) {
      state.isLoading = false
      state.notFound = true
    }
  }

  const updateSurvey = async (id: string, data: SurveyConfig, questionType: QuestionType, extraMessage?: string) => {
    if (!id || !data) return

    const updateMessages = [i18n.t('settings.survey.modal.publish.message')]
    if (extraMessage) updateMessages.push(extraMessage)

    if (data.status !== SurveyStatus.DRAFT) {
      const { isConfirmed } = await alertDialog(updateMessages.join('<br><br>'), {
        title: i18n.t('settings.survey.modal.update.title'),
        confirmButtonText: i18n.t('universal.update'),
        allowHtml: true,
      })

      if (!isConfirmed) return false
    }

    await setSurveyConfig(id, data, questionType)

    toast({
      status: 'success',
      message: i18n.t('settings.survey.edit.toasts.updated'),
    })

    return true
  }

  const setSurveyConfig = async (id: string, data: SurveyConfig, questionType: QuestionType) => {
    if (data.question === CUSTOM_QUESTION) {
      data.question = data.customQuestion
    }

    if (state.unsavedLogoFile) {
      data.logo = await uploadFile(state.unsavedLogoFile, {
        signingUrl: `surveys/survey-logo-upload-url?mime=${state.unsavedLogoFile.type}`,
      })
      state.unsavedLogoFile = null
    }

    let response = await updateSurveyApi(id, data)
    response = mapQuestionFields(response, questionType)
    state.data = { ...response }
    savedSurveyConfig.value = cloneDeep(response)
  }

  return {
    pauseSurvey,
    resumeSurvey,
    publishSurvey,
    updateSurvey,
    deleteSurvey,
    loadSurvey,
    duplicateSurvey,
    setSurveyConfig,
  }
})

interface PublishErrorResponse {
  code: number
  message: string
  details: TriggerConditionValidationError
}
