import { lowerCase } from 'lodash-es'
import {
  InboxIcon,
  AlertCircleIcon,
  ListIcon,
  MessageCircleIcon,
  SmileIcon,
  ThumbsUpIcon,
} from '@klausapp/ui-kit/icons'

import { session } from '@/composables/useSession'
import { hasWorkspaceRole } from '@/utils/roleUtils'
import SendIcon from '@/assets/send-icon.svg'
import i18n from '@/i18n'

export const disputes = {
  ALL: i18n.t('pickers.all'),
  NEW: i18n.t('pickers.open'),
  CLOSED: i18n.t('pickers.resolved'),
  ACCEPTED: i18n.t('pickers.accepted'),
  PARTIALLY_ACCEPTED: i18n.t('pickers.partially_accepted'),
  REJECTED: i18n.t('pickers.rejected'),
}
export const comments = {
  ALL: i18n.t('pickers.all'),
  MENTIONS: i18n.t('pickers.mentions'),
}
export const feedback = {
  ALL: i18n.t('pickers.all'),
  CSAT: i18n.t('universal.csat'),
}
export const statuses = { comments, disputes, feedback }

export enum SortValues {
  NEWEST = '-createdAt',
  OLDEST = 'createdAt',
  UNREAD = 'unread',
  COMMENTED = 'commented',
}

const defaultOptions = {
  'conversations.ticket_list.sort_newest': SortValues.NEWEST,
  'conversations.ticket_list.sort_oldest': SortValues.OLDEST,
}
const receivedOptions = {
  ...defaultOptions,
  'conversations.ticket_list.sort_unread': SortValues.UNREAD,
}
const givenReviewOptions = {
  ...defaultOptions,
  'conversations.ticket_list.sort_commented': SortValues.COMMENTED,
}
const receivedReviewOptions = {
  ...receivedOptions,
  'conversations.ticket_list.sort_commented': SortValues.COMMENTED,
}

export const sortOptions = {
  commentsReceived: receivedOptions,
  commentsGiven: defaultOptions,
  reviewsReceived: receivedReviewOptions,
  reviewsGiven: givenReviewOptions,
  feedbackReceived: defaultOptions,
  disputesAll: receivedOptions,
  disputesReceived: receivedOptions,
  disputesStarted: defaultOptions,
} as const

export const getActivityRoutes = () => [
  {
    value: 'placeholder',
    label: i18n.t('activity.subnav.received_uppercase'),
  },
  {
    value: 'activity.reviewsReceived',
    icon: ListIcon,
    iconColor: 'purple-50',
    label: i18n.t('activity.subnav.reviews'),
  },
  {
    value: 'activity.commentsReceived',
    icon: MessageCircleIcon,
    iconColor: 'green-50',
    label: i18n.t('activity.subnav.comments'),
  },
  {
    value: 'activity.reactionsReceived',
    icon: SmileIcon,
    iconColor: 'teal-50',
    label: i18n.t('activity.subnav.reactions'),
  },
  {
    value: 'activity.feedbackReceived',
    icon: ThumbsUpIcon,
    iconColor: 'blue-50',
    label: i18n.t('activity.subnav.feedback'),
  },
  {
    value: 'placeholder',
    label: i18n.t('activity.subnav.given_uppercase'),
  },
  ...(!hasWorkspaceRole('AGENT') || session.workspace?.settings?.selfReviews
    ? [
        {
          value: 'activity.reviewsGiven',
          icon: ListIcon,
          iconColor: 'purple-60',
          label: i18n.t('activity.subnav.reviews'),
        },
      ]
    : []),
  {
    value: 'activity.commentsGiven',
    icon: MessageCircleIcon,
    iconColor: 'green-60',
    label: i18n.t('activity.subnav.comments'),
  },
  {
    value: 'placeholder',
    label: i18n.t('activity.subnav.disputes_uppercase'),
  },
  ...(session.features.disputes
    ? [
        {
          value: 'activity.disputesAll',
          icon: AlertCircleIcon,
          iconColor: 'orange-50',
          label: i18n.t('activity.subnav.all'),
        },
        ...(!hasWorkspaceRole('AGENT')
          ? [
              {
                value: 'activity.disputesReceived',
                icon: InboxIcon,
                iconColor: 'orange-60',
                label: i18n.t('activity.subnav.received'),
              },
            ]
          : []),
        {
          value: 'activity.disputesStarted',
          icon: SendIcon,
          iconColor: 'orange-70',
          label: i18n.t('activity.subnav.started'),
        },
      ]
    : []),
]

export const getRouteSuffix = (routeName: string) => {
  const name = routeName.startsWith('activity.') ? routeName.replace('activity.', '') : routeName
  return name
}

export const getDotNotation = (routeName: string) => {
  return lowerCase(getRouteSuffix(routeName)).replace(/ /g, '.')
}
