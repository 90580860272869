import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "login__form"
}
const _hoisted_2 = { class: "mb-4 text-xl font-medium" }
const _hoisted_3 = {
  key: 1,
  class: "login__form"
}
const _hoisted_4 = { class: "mb-4 text-xl font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_transition_slide = _resolveComponent("transition-slide")!
  const _component_auth_page_wrapper = _resolveComponent("auth-page-wrapper")!

  return (_openBlock(), _createBlock(_component_auth_page_wrapper, {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, {
    "header-cta": _withCtx(() => [
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.account_exists.cancel_flow_prompt')) + " ", 1 /* TEXT */),
        _createElementVNode("button", {
          class: "content-link",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
        }, _toDisplayString(_ctx.$t('auth.account_exists.cancel_signup')), 1 /* TEXT */)
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_transition_slide, {
        offset: [-40, 0],
        appear: ""
      }, {
        default: _withCtx(() => [
          (_ctx.showInstructions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('auth.account_exists.ask_for_invite')), 1 /* TEXT */),
                _createElementVNode("p", {
                  class: _normalizeClass(["text-sm", _ctx.$style.content])
                }, _toDisplayString(_ctx.$t('auth.account_exists.to_join_ask_for_invite')), 3 /* TEXT, CLASS */),
                _createVNode(_component_ui_button, {
                  key: "copy",
                  secondary: "",
                  onClick: _ctx.copyInstructions
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.copied ? _ctx.$t('universal.copy_success') : _ctx.$t('auth.account_exists.copy_instructions')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('auth.account_exists.existing_account')), 1 /* TEXT */),
                _createElementVNode("p", {
                  class: _normalizeClass(["text-sm", _ctx.$style.content])
                }, _toDisplayString(_ctx.$t(
                _ctx.session.features.disableAccountSignup
                  ? 'auth.account_exists.company_has_account_v2'
                  : 'auth.account_exists.company_has_account',
              )), 3 /* TEXT, CLASS */),
                _createVNode(_component_ui_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInstructions = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.account_exists.join_company')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                (!_ctx.session.features.disableAccountSignup)
                  ? (_openBlock(), _createBlock(_component_ui_button, {
                      key: 0,
                      class: "mt-3",
                      secondary: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'onboarding' })))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('auth.account_exists.create_account')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}