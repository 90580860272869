import i18n from '@/i18n'

// Read more: https://github.com/serebrov/emoji-mart-vue?tab=readme-ov-file#i18n

export const getEmojiPickerI18n = () => ({
  search: i18n.t('emoji_picker.search'),
  notfound: i18n.t('emoji_picker.notfound'),
  categories: {
    search: i18n.t('emoji_picker.category.search'),
    recent: i18n.t('emoji_picker.category.recent'),
    smileys: i18n.t('emoji_picker.category.smileys'),
    people: i18n.t('emoji_picker.category.people'),
    nature: i18n.t('emoji_picker.category.nature'),
    foods: i18n.t('emoji_picker.category.foods'),
    activity: i18n.t('emoji_picker.category.activity'),
    places: i18n.t('emoji_picker.category.places'),
    objects: i18n.t('emoji_picker.category.objects'),
    symbols: i18n.t('emoji_picker.category.symbols'),
    flags: i18n.t('emoji_picker.category.flags'),
    custom: i18n.t('emoji_picker.category.custom'),
  },
})
