export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свой субдомен Zendesk"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в Zendesk"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей компании уже есть аккаунт."])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединиться к компании"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы присоединиться к существующей компании, попросите администратора отправить вам приглашение."])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попросите администратора пригласить вас"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я передумал(а)."])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить регистрацию"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вашей компании уже есть аккаунт. Присоединиться к аккаунту вашей компании или создать новый?"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать инструкции"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый аккаунт"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже есть аккаунт"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не сможете получить доступ к аккаунту Zendesk QA, пока не оплатите счета за подписку."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша подписка приостановлена"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт не найден. Повторите попытку."])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["запросите новый пароль."])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Повторите попытку или ", _interpolate(_named("link"))])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильная комбинация адреса электронной почты и пароля."])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросите новую у своего администратора."])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта ссылка-приглашение недействительна или уже использовалась."])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Способ входа (", _interpolate(_named("method")), ") в вашем аккаунте деактивирован."])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это приглашение недействительно или используется. Запросите новое у своего администратора."])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить данные приглашения."])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось зарегистрироваться. Возможно, пользователь с таким адресом электронной почты уже существует."])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт заблокирован после нескольких неудачных попыток входа. Найдите в своем почтовом ящике письмо с инструкциями по разблокировке аккаунта."])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала подтвердите свой адрес электронной почты"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительный почтовый аккаунт"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательное поле"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательное поле"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код для входа"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить ссылку"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочий адрес эл. почты"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш рабочий адрес эл. почты"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Services Agreement (Соглашение об основных услугах)"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не соответствует требованиям"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление о конфиденциальности"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Привет, ", _interpolate(_named("invitee_name")), "!"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Присоединяйтесь к организации ", _interpolate(_named("organization"))])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш адрес электронной почты подтвержден. Теперь вы можете войти в систему с помощью нового аккаунта."])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить с помощью Google"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недопустимая ссылка-приглашение"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти с помощью ссылки"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться назад для входа"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить с помощью Slack"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить в Zendesk"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш адрес электронной почты подтвержден. Теперь вы можете войти в систему с помощью нового аккаунта."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход через Google"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход по ссылке"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход через Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в Zendesk QA"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт удален"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет аккаунта?"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите правильный код"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательное поле"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительный почтовый аккаунт"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательное поле"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код для входа"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все способы входа"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), " с помощью традиционных способов"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти с помощью ссылки"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получили письмо с кодом для входа."])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получили письмо с инструкциями для входа."])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отправке ссылки возникла ошибка. Повторите попытку."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход через почту"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пароль"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один символ нижнего регистра"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один символ верхнего регистра"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль должен содержать"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная длина пароля — 128 символов"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко запомнить, сложно угадать"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум 8 символов"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте пароль"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один символ нижнего регистра"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна цифра"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один символ верхнего регистра"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы сбросить пароль, введите свой адрес электронной почты, и вам будет отправлено письмо с инструкциями."])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите действительный почтовый аккаунт"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это обязательное поле"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Письмо отправлено на адрес ", _interpolate(_named("email"))])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если письмо не отображается в папке «Входящие» или «Спам», проверьте указанный адрес электронной почты."])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться назад для входа"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте электронную почту"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("bold")), ", вам будет отправлено письмо для сброса пароля."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если в вашем аккаунте есть пароль"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если это ", _interpolate(_named("bold")), ", то мы отправили вам ссылку для сброса пароля."])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта связана с аккаунтом"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнили пароль?"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отправке ссылки для сброса произошла ошибка. Повторите попытку."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться по ссылке"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Регистрируясь, вы принимаете ", _interpolate(_named("terms_of_service")), " и ", _interpolate(_named("privacy_policy")), " Zendesk QA"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия предоставления услуг"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас есть аккаунт?"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться по ссылке"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться с помощью рабочего адреса эл. почты"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться через Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться в Zendesk QA"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться через Google"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У меня есть активный аккаунт Zendesk"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зарегистрируйтесь или войдите, чтобы завершить настройку ", _interpolate(_named("direct_install_name"))])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действительно вы? Найдите в своем почтовом ящике письмо с подтверждением."])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA лучше всего работает на компьютере. Войдите в систему, чтобы начать рецензирование разговоров."])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записаться на демонстрацию"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или запишитесь на демонстрацию, и мы покажем вам возможности Zendesk QA."])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрошено ваше присутствие"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отмена"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Я передумал(а) и хочу ", _interpolate(_named("cancel"))])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("account")), " приглашает вас присоединиться к аккаунту."])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединиться к аккаунту"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый аккаунт"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте войти снова"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На ваш отзыв получена реакция: ", _interpolate(_named("shortcode"))])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные реакции"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " отреагировал(а) на ваш комментарий"]), _normalize(["Несколько человек отреагировали на ваш комментарий"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " отреагировал(а) на ваш ответ"]), _normalize(["Несколько человек отреагировали на ваш ответ"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentName")), " ответил(а) на вашу рецензию"]), _normalize(["Несколько человек ответили на вашу рецензию"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комментарий"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ответ"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензия"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СПОРЫ"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОСТАВЛЕННЫЕ"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОЛУЧЕННЫЕ"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв об опросе"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакции"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начато"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пометить комментарий как прочитанный?"]), _normalize(["Пометить все комментарии как прочитанные?"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пометить спор как прочитанный?"]), _normalize(["Пометить все споры как прочитанные?"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пометить отзыв об опросе как прочитанный?"]), _normalize(["Пометить все отзывы об опросах как прочитанные?"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пометить рецензию как прочитанную?"]), _normalize(["Пометить все рецензии как прочитанные?"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Комментарий помечен как прочитанный"]), _normalize(["Все комментарии помечены как прочитанные"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Спор помечен как прочитанный"]), _normalize(["Все споры помечены как прочитанные"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Отзыв об опросе помечен как прочитанный"]), _normalize(["Все отзывы об опросе помечены как прочитанные"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Рецензия помечена как прочитанная"]), _normalize(["Все рецензии помечены как прочитанные"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить все как прочитанные"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как прочитанное"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензии"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда кто-то оспаривает рецензию, она отображается здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет споров"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вам назначено разрешение спора, он отображается здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных споров"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда вы оспариваете рецензию, она отображается здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет начатых споров"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь отображаются все оставленные для вас отзывы. Пока ничего нет."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных отзывов"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь отображаются все реакции на ваши рецензии и ответы. Пока ничего нет."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных реакций"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть разговор"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все оставленные вами отзывы отображаются здесь. Сначала оставьте рецензию в виде «Разговоры»."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет оставленных рецензий"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь отображаются все оставленные для вас отзывы. Пока ничего нет."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных рецензий"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии не найдены"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все отзывы, которые вы оставляете в виде комментариев, отображаются здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет оставленных комментариев"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии не найдены"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все комментарии в вашей рецензии, ", "@", "упоминания или ответы на ваши комментарии отображаются здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных комментариев"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет принятых споров"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет открытых споров"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет отклоненных споров"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разрешенных споров"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите попытку, выбрав другой вариант в фильтре выше."])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите попытку, выбрав другого пользователя из фильтра выше."])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные комментарии"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные комментарии"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все споры"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные споры"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начатые споры"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получен отзыв об опросе"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные рецензии"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные рецензии"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список действий"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize(["Рецензировано ", _interpolate(_list(0)), " из ", _interpolate(_list(1))]), _normalize(["Рецензировано ", _interpolate(_list(0)), " из ", _interpolate(_list(1))]), _normalize(["Рецензировано ", _interpolate(_list(0)), " из ", _interpolate(_list(1))])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перезапуск через ", _interpolate(_named("time"))])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Осталось ", _interpolate(_named("time"))])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать назначение"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список назначений"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Невозможно создать сеанс наставничества между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), ", поскольку сейчас они находятся в разных рабочих пространствах."])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Сеансы наставничества между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), " уже существуют. Свяжитесь с ", _interpolate(_list(0)), ", чтобы получить доступ к сеансу."])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуется"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Создан новый сеанс между ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), "."])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставник"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите наставника"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подопечный"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подопечного"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сеанс"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте сеанс"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить сеанс"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название сеанса"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуется"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просроченные"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сеанс"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы не найдены"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время рецензирования"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационная панель"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для отображения за выбранный период"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Добавлена закладка в сеанс. ", _interpolate(_named("buttonStart")), "Показать", _interpolate(_named("buttonEnd")), "."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в..."])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлено"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли конца списка закладок за выбранный период"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Узнайте, как работают закладки, из этой ", _interpolate(_named("link")), "."])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статья"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Похоже, вы ничего не закрепили для ", _interpolate(_list(0)), ". Вы можете использовать закладки для подготовки к личным встречам или сохранения материалов для дальнейшего использования."])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подопечный"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет добавленных закладок"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Здесь отображаются все закладки, созданные для ", _interpolate(_named("name")), ". Закладки, добавленные в сеансы, будут ", _interpolate(_named("bold")), " другому участнику."])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["немедленно переданы"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Похоже, ", _interpolate(_list(0)), " не получил(а) ни одного отзыва о рецензии разговора или CSAT за выбранный период."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных отзывов"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли конца списка комментариев за выбранный период"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метрики"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Номера ", _interpolate(_list(0))])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый элемент действия"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выполнено пользователем ", _interpolate(_named("user")), " ", _interpolate(_named("date"))])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["пользователем ", _interpolate(_list(0))])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вы"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите текст, чтобы добавить новый элемент действия"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каковы результаты этой встречи? Каковы ваши дальнейшие действия?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элементы действий"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автор: ", _interpolate(_named("name")), ", создано ", _interpolate(_named("createdDate"))])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удалению сеанса"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это приведет к необратимому ", _interpolate(_named("deleteSession")), ". Участники потеряют доступ к сеансу, включая его содержимое и личные примечания."])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата создания сеанса: ", _interpolate(_named("date"))])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последнее изменение внесено пользователем ", _interpolate(_named("name")), ", ", _interpolate(_named("updatedDate"))])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тезисы и примечания"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание обновлено"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинуть сеанс"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С этого момента выбранные пользователи получают доступ к сведениям о сеансе."])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться сеансом"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вы больше не будете участником сеанса ", _interpolate(_list(0)), "–", _interpolate(_list(1)), "."])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " больше не будет участником этого сеанса."])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("name")), " сейчас имеет доступ к этому сеансу."]), _normalize(["Такое количество новых пользователей сейчас имеют доступ к этому сеансу: ", _interpolate(_named("n"))])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить сеанс"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все сеансы"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить сеанс"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наставник"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Примечания, добавленные ", _interpolate(_list(0)), ", будут отображаться здесь."])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавляйте закрепленные элементы как темы для обсуждения на правой боковой панели"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечания, которые видны только вам..."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные примечания"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личное примечание обновлено"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 дней"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставник"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подопечный"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элементы действий"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний сеанс"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просроченные"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор сеансов"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я: наставник"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я: подопечный"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все сеансы"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие сеансы"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте сменить фильтр, чтобы увидеть элементы действий."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет элементов действий"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элементы действий"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные и просмотренные рецензии"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные · Просмотренные"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогресс после сеансов"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, агенты не получили ни одного отзыва."])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что имя пользователя указано правильно"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось найти этого пользователя"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных данных"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК за последние 30 дней"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это приведет к необратимому <b>удалению всех сеансов</b> (2) с <b>", _interpolate(_named("name")), "</b>. Участники потеряют доступ ко всем сеансам, включая их содержимое и личные примечания."])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли конца списка всех сеансов"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс наставничества — это групповое занятие между наставником и подопечным, проводимое с целью обсуждения результатов работы, возможностей, краткосрочных и долгосрочных целей."])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы можно искать как по имени наставника, так и подопечного."])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы не найдены"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы еще не созданы"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить историю наставничества"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить историю наставничества "])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний сеанс"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все сеансы"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставник"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подопечный"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметить как завершенное"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как отмененное"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список сеансов"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незапланированные"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить на это сообщение"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеанс калибровки истек. Нельзя оставлять дополнительные рецензии."])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже оставляли рецензию для этого разговора."])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензирование этого разговора больше невозможно, так как срок действия сеанса истек."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У этого разговора нет рецензий"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть панель"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить рецензию о калибровке"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СВЕДЕНИЯ"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СВОДКА"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАСШИФРОВКА"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Говорящий ", _interpolate(_list(0))])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " с."])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["молчание"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить говорящего"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать сообщение"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к воспроизведению сообщения"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Говорящие"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расшифровка"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи рабочего пространства"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы создать фильтры, сначала добавьте подключение"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет ботов"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата создания ", _interpolate(_named("time")), ", автор: ", _interpolate(_named("agentName"))])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обновлено ", _interpolate(_named("time")), " пользователем ", _interpolate(_named("agentName"))])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Похоже, у вас нет фильтров. Для доступа к этой странице сначала необходимо создать фильтр. Начните с ", _interpolate(_named("recommendedFilter")), ", чтобы просмотреть свои разговоры. Создайте этот фильтр ниже, чтобы продолжить."])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 30 дней"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условие «Создано за последние 30 дней» будет добавлено к фильтрам, созданным без периода"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр недоступен в этом тарифе и не будет учитываться при отображении результатов"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время на получение параметров фильтра истекло. Повторите попытку."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о фильтрации"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель = вошедший в систему человек"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Найдено разговоров: ", _interpolate(_named("count"))]), _normalize(["Найден разговор: ", _interpolate(_named("count"))]), _normalize(["Найдено разговоров: ", _interpolate(_named("count"))])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недоступно в вашем текущем тарифе. Для использования этой функции повысьте план."])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить условие"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано с помощью ИИ Zendesk QA"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условие"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превратить в назначение"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать фильтр"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать фильтр"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные поля"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет загрузка параметров фильтра в соответствии с вашими настройками. При начальной загрузке это может занять несколько минут."])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придумайте описательное имя для фильтра"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя фильтра"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вариант"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условие"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступные"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить фильтр"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных значений"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет загрузка..."])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите значение"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только активные сеансы ↑"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать старые сеансы ↓"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить сеанс калибровки"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сеанс"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сеанс калибровки"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензии калибровки, оставленные во время сеанса, будут необратимо удалены."])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить сеанс"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить сеанс калибровки ", _interpolate(_named("sessionName"))])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет созданных сеансов"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активных сеансов"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы не созданы"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активных сеансов"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить фильтр"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать личный фильтр"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать общедоступный фильтр"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр добавлен"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр будет необратимо удален."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить фильтр"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить фильтр ", _interpolate(_named("filterName"))])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все споры"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры для меня"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои споры"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытые споры"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновики"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных фильтров"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные фильтры не созданы"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступные фильтры не созданы"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные фильтры"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступные фильтры"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Хотите ", _interpolate(_named("cta")), "?"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сохранить"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это временный вид фильтра."])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание вашего фильтра..."])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр обновлен"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальная категория AutoQA"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последнее обновление: ", _interpolate(_named("updatedDate"))])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложные слова"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматические проверки обновляются динамически в зависимости от изменений в тикетах"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделить в разговоре"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длинные предложения"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет спрогнозированных автоматических оценок"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обнаружить ошибки"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать сложные слова"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать длинные предложения"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий разговор"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA не может оценить эту категорию, поскольку контекста недостаточно. Рекомендуется проверка вручную."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнайте об автоматизированной аналитике разговоров для оптимизации процесса рецензирования. Выделение важных разговоров и демонстрация выдающихся положительных примеров для вашей команды."])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Звонок]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исправлено"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалено"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " принял(а) спор"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Спор отправлен пользователю ", _interpolate(_named("agentName"))])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объясните, почему вы считаете, что этот рецензируемый не подходит для данного отзыва"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить комментарий и задать нового рецензируемого"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить комментарий"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объясните, почему вы считаете эти оценки несправедливыми (обязательное поле)"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить комментарий (обязательное поле)"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объясните, почему вы отклоняете этот спор"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить комментарий"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить спор пользователю"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить спор пользователю"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить спор пользователю"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать нового рецензируемого"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорить отзыв"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорить отзыв"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить рецензируемого"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить спор пользователю"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спор"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорить эту рецензию"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я не согласен(на) с оценками"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта рецензия предназначалась не мне"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователя"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " частично принял(а) спор"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " отклонил(а) спор"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принято"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично принято"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " начал(а) спор: проверка"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " начал(а) спор: неправильный рецензируемый"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы можно было выполнить другие действия по рецензированию, необходимо разрешить открытый спор."])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите новые оценки"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить спор"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно отменить этот спор?"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить спор"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спор"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время разрешения спора"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите новые честные оценки (необязательно)"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка спора"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный рецензируемый"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять спор"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять спор"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорить отзыв"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный рецензируемый"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспорить отзыв"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить спор"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить спор"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть спор"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменено"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " пользователем ", _interpolate(_named("author")), " ", _interpolate(_named("date"))])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["пользователю ", _interpolate(_list(0))])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому назначено:"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Также оставить комментарий"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее об условных картах оценок"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранее назначено пользователю:"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто выберите один"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось найти GIF с таким именем"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте первым, кто оставит рецензию."])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша подписка в настоящее время приостановлена. Повторно активируйте ее, чтобы разрешить оставление отзывов."])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовано:"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывает, сколько времени затрачено на рецензирование этого разговора"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователя"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Отсутствует основная причина для категории: «", _interpolate(_list(0)), "»"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок:"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените категорию или добавьте комментарий"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить..."])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить смайлики"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть поле комментария"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть поле комментария"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть форматирование"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть панель"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать форматирование"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать панель"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить рецензию"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить все категории"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените хотя бы одну категорию"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить этот разговор на потом"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить на потом"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующее назначение"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить разговор в этом цикле назначения"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать разговор"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия исходного уровня"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить комментарий"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий удален"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить этот комментарий?"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор комментария"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот хэштег удален или переименован."])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделитесь мнением о том, что вам понравилось, или что можно было бы улучшить..."])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить комментарий"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условные"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все комментарии будут необратимо удалены."])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить комментарий"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Сообщения]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " еще не видел(а) этот отзыв"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " просмотрел(а) этот отзыв"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бывший участник"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется поиск GIF..."])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите Enter, чтобы создать новый хэштег."])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные хэштеги заблокированы администратором."])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте обращения в закладки и отфильтруйте их позже"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как исходный уровень"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв о сообщении"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий разговор"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет отзывов"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет готовых рецензий"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставьте рецензию ниже, чтобы начать работу."])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензий не осталось"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет оценки"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий исполнитель"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий разговор"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить как исходный уровень"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить на эту рецензию"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть боковую панель рецензии"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия и все добавленные комментарии будут необратимо удалены."])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить рецензию"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить рецензию"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия удалена"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Рецензия удалена пользователем ", _interpolate(_list(0))])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово!"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначьте эту задачу себе, чтобы оставить рецензию"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже оставляли рецензию для этого пользователя."])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вы оставили рецензию для ", _interpolate(_list(0))])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет категорий для этого разговора."])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии недоступны для этого рабочего пространства."])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить эту рецензию как черновик?"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить этот черновик"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать боковую панель рецензии"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не пройдено"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдено"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдено с незначительными ошибками"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжаем подсчитывать..."])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить комментарий"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать это сообщение"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать себя"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать этот разговор"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет пользователей для рецензирования"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии отключены"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи рабочего пространства"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет загрузка..."])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать пользователя"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 рецензия"]), _normalize(["Количество рецензий: ", _interpolate(_named("count"))])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать новые оценки"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш менеджер рабочего пространства не разрешил саморецензии."])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите Enter, чтобы пометить эту закладку тегом"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровки"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последние 30 дней"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помечено"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте сеанс калибровки"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновики"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика обеспечивает визуальное представление данных вашей службы поддержки. Используйте интерактивные диаграммы, чтобы понимать, на чем следует сосредоточить усилия по обеспечению качества, выявить «слепые пятна» и найти подходящих агентов для рецензирования на основе качества их работы."])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID ", _interpolate(_named("ticketSource"))])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["URL-адрес ", _interpolate(_named("ticketSource"))])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перевести на ", _interpolate(_named("language"))])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неназначенные"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас просматривает"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензирует сейчас"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот разговор удален и больше не доступен."])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот разговор не существует в Zendesk QA."])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все закладки в этом разговоре"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В сеансе"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При удалении разговора из сеанса все рецензии о нем будут удалены."])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить разговор из ", _interpolate(_named("sessionName"))])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы калибровки еще не созданы."])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот разговор добавлен в сеанс"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот разговор удален из сеанса"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в сеанс калибровки или удалить из него"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку в этот разговор"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разговоров для отображения"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта клиента"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID клиента"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя клиента"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя внешней формы"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRT"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время первого ответа"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к опросу"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка качества"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTFR"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время до первого выполнения"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оригинал"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет загрузка..."])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это сообщение не существует в этом разговоре, или оно было удалено"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без темы"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разговоров для отображения"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры не найдены"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть исходный разговор"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя связать с разговором — отсутствует URL-адрес"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет участников"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить разговор"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы прочитать рецензии"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы создать рецензию"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор создан через расширение браузера"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть опрос, посвященный удовлетворенности клиентов"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть разговор"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение с отрицательной тональностью"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение с положительной тональностью"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести на английский"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать оригинал"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удаление разговора является необратимым. Кроме того, будут удалены все связанные данные, например опросы, расшифровки и рецензии из Zendesk QA, включая информационные панели.", _interpolate(_named("lineBreak")), "Вы по-прежнему сможете открывать этот разговор в Zendesk Support или другой используемой вами программе службы поддержки."])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удаление разговора нельзя отменить. Также из Zendesk QA удаляются все связанные данные, например опросы, расшифровки и рецензии, включая информационные панели. Если разговор относится к нескольким рабочим пространствам, он будет удален из всех них.", _interpolate(_named("lineBreak")), "Вы по-прежнему будете иметь к нему доступ в Zendesk Support или другой используемой вами программе службы поддержки."])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор из Zendesk QA"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Эта переписка не завершена из-за эскалации. Перейдите к ", _interpolate(_named("optionsUrl")), " и проверьте теги. ", _interpolate(_named("tagsUrl"))])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Эта переписка не завершена из-за эскалации. Поговорите со своим администратором о проверке тегов. ", _interpolate(_named("tagsUrl"))])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения > Дополнительные параметры"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о тегах"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыто"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнено"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Исполнитель изменен на ", _interpolate(_named("target"))])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неназначенные"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Макрос ", _interpolate(_named("target")), " применен пользователем ", _interpolate(_named("author"))])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Разговор получил оценку ", _interpolate(_named("target"))])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователь ", _interpolate(_named("target")), " исключил(а) разговор"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статус изменен на ", _interpolate(_named("target"))])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Помечено тегом ", _interpolate(_named("target"))])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Команда изменена на ", _interpolate(_named("target"))])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " отложил(а) разговор на ", _interpolate(_named("target"))])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " отменил(а) откладывание разговора"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот зациклился, сообщение повторяется"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторение бота"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторение бота означает, что одно и то же сообщение повторяется один или несколько раз подряд"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдены подходящие фразы"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обычный агент ведет разговор на ", _interpolate(_named("percent")), " эффективнее, чем бот"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективность коммуникации с ботом"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Бот провел разговор на ", _interpolate(_named("percent")), " эффективнее, чем обычный агент"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективность коммуникации с ботом"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговоре отсутствует обязательное уведомление, информирующее участников о записи, необходимое для соблюдения требований и прозрачности"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует сообщение о записи"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Нарушено пользовательское соглашение (SLA) для ", _interpolate(_list(0)), "."])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарушено пользовательское соглашение (SLA)"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышено целевое время метрики для правила SLA"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Признаки оттока клиентов. Клиент рассматривал возможность перехода или обещал уйти."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выявлен риск оттока"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["В разговоре есть моменты молчания, длящиеся дольше заданного порогового значения — ", _interpolate(_list(0)), " с."])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 экземпляр"]), _normalize(["Количество экземпляров: ", _interpolate(_named("count"))])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Не дольше ", _interpolate(_list(0)), " с."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молчание"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент обратился за помощью на более высоком уровне"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрошена эскалация"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент выразил огромную благодарность или очень доволен полученной поддержкой"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключительное обслуживание"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент или агент явно запросили дополнение"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос на дополнение"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент выразил недовольство"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательная тональность"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор была необычным или нетипичным, и для выполнения требовалось больше времени"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обнаружена аномалия"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент выразил удовлетворение"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительная тональность"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применимо"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать как неприменимое"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последнее изменение внесено пользователем ", _interpolate(_named("author")), ", ", _interpolate(_named("date"))])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неприменимо"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автор: ", _interpolate(_named("author")), ", дата: ", _interpolate(_named("date"))])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите причину (необязательно)"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика не обнаружена"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить подключение"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите службу поддержки к своему аккаунту и создайте хотя бы один фильтр для просмотра списка разговоров."])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разговоров для отображения"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть настройки рабочего пространства"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее обновление"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(через день)"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(в этом месяце)"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(на этой неделе)"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти по ID"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Результаты поиска для ", _interpolate(_named("searchQuery"))])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано другими"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прокомментировано"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые новые"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые старые"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Случайные"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно обновленные"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно отвеченные"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировка:"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не прочитано"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано вами"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс импорта может занять некоторое время. Когда все будет готово, мы отправим вам письмо."])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA импортирует ваши разговоры"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Лимит: ", _interpolate(_list(0))])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте, щелкнув значок калибровки в верхнем колонтитуле вида разговоров."])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь ничего нет. Повторите попытку, используя другой фильтр или способ сортировки."])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет аномалий для отображения."])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите подходящий разговор и щелкните значок звездочки в верхнем колонтитуле, чтобы добавить его в закладки."])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте изменить фильтры или пригласите других агентов службы поддержки, чтобы добавить разговоры."])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без лимита"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутреннее примечание"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется загрузка разговоров. Некоторый контент все еще может отсутствовать."])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий пользователь"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не найден"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено рецензий"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники рабочего пространства"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущий пользователь"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных разговоров"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры не добавлены"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Незавершенные рецензии отображаются здесь. Перечитайте, исправьте и отправьте в течение 30 дней."])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет черновиков рецензий"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет помеченных разговоров"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разговоров для отображения"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствий"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к выбранному сообщению"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньше всего ответов"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше всего ответов"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тональность"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список разговоров"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БОТ"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На звонок принят ответ в ", _interpolate(_named("time"))])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Звонок закончился в ", _interpolate(_named("time"))])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Звонок сделан в ", _interpolate(_named("time"))])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано вами"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Не удалось загрузить аудиофайл. Убедитесь, что вы вошли в ", _interpolate(_list(0)), "."])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к опросу, посвященному удовлетворенности клиентов"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки аудио"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор комментария"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звонка не было"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прослушивание в службе поддержки"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прослушивание в Aircall"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скорость воспроизведения"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать звонок"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расшифровка"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкая"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не является пользователем Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматически ранжирует агентов на основе среднего показателя CSAT за месяц. Находите разговоры, обработанные агентами, чей CSAT ниже, чем у коллег, всего одним щелчком мыши."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранжирование по CSAT"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкая"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самая низкая"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самая высокая"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не является пользователем Zendesk QA"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один тикет"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматически ранжирует агентов на основе количества разговоров, которые они обрабатывают в месяц. Находите разговоры агентов с меньшим объемом, чем у коллег, всего одним щелчком мыши."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранжирование по объему"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не рецензировано"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус рецензии"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкая"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нейтральная"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не является пользователем Zendesk QA"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тональность"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматически ранжирует агентов на основе средней тональности в разговорах. Находите взаимодействия, при которых агенты имеют дело с более разочарованными клиентами, чем их коллеги, в один клик."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранжирование по тональности"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не обнаружено"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обнаружено"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аномалии"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры, требующие вашего внимания. Наша модель машинного обучения выявляет сложные или нетипичные взаимодействия для дальнейшего рецензирования."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аномалии"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательная"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительная"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите для рецензирования разговоры, в которых клиент выразил удовлетворение или разочарование. В раскрывающемся списке выберите тип тональности, который требуется найти в разговорах. Перетащите атрибут фильтра наверх, чтобы увидеть соотношение выбранной тональности во всех ваших взаимодействиях."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тональность"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тональность"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус рецензии"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не рецензировано"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA автоматически определяет языки в ваших переписках. В раскрывающемся списке выберите разговоры на определенных языках для рецензирования. Перетащите атрибут фильтра наверх, чтобы увидеть все языки, используемые в вашей службе поддержки."])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика разговоров"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы можете ", _interpolate(_named("paragraph_2_item")), " благодаря аналитике, если сравнивать с возможностями, предоставляемыми обычными фильтрами разговоров. Получайте визуальный обзор всех данных из вашей службы поддержки и изучайте указанные ниже типы аналитики."])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее об использовании аналитики разговоров"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимать относительную важность определенных атрибутов разговоров по отношению к другим"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встроенная система ранжирования эффективности работы агентов позволяет находить наиболее и наименее эффективных агентов"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("paragraph_1_item")), " — это мощный интерактивный инструмент интеллектуального анализа данных, помогающий находить разговоры, которые больше всего требуют рецензирования."])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["намного больше возможностей"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Диаграммы разговоров и агентов ", _interpolate(_named("paragraph_3_item_1")), " — при обновлении фильтров или нажатии сегментированных секторов диаграммы список разговоров будет автоматически обновляться. ", _interpolate(_named("paragraph_3_item_2")), ", изучить подробную разбивку или соотношение различных элементов."])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["взаимосвязаны"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените порядок атрибутов фильтра"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В аналитике разговоров отображаются только ", _interpolate(_named("paragraph_4_item")), ". Мы автоматически удаляем взаимодействия, не представляющие ценности для рецензирования, например автоматически созданные сообщения, спам, ответы ботов или сообщения, в которых нет надлежащего диалога с вашим представителем службы поддержки."])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чистые и закрытые разговоры"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что здесь отображается?"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " из ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " из ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " из ", _interpolate(_named("total"))])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), " из ", _interpolate(_named("category"))])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось получить"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Н/Д"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенты"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("percentage")), " от итога)"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезагрузить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Однако мы уверены, что все будет работать отлично."])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный опрос"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не осталось отзывов об опросе"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пока нет доступных оценок к отзывам об опросе..."])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получен"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег отзыва"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги комментариев к отзывам"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применен фильтр по периоду"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ответ"]), _normalize(["Количество ответов: ", _interpolate(_named("count"))])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Узнайте, почему была присвоена оценка ", _interpolate(_list(0)), ", указав причины в настройках опроса."])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Еще нет причин ", _interpolate(_list(0))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о причинах опроса"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры с тегами:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " по"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причины"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория комментария"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Причины ", _interpolate(_named("surveyType"))])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прогнозируемые факторы ", _interpolate(_named("surveyType"))])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник разговора"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Отклики на опрос (", _interpolate(_named("n")), ")"]), _normalize(["Отклик на опрос (", _interpolate(_named("n")), ")"]), _normalize(["Отклики на опрос (", _interpolate(_named("n")), ")"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без темы"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разговоры с ", _interpolate(_list(0))])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " по пользователям"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель отклика"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Оценка ", _interpolate(_list(0)), " — это сумма всех ответов, деленная на сумму максимальных оценок"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить целевой показатель"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить целевой показатель"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте целевой показатель"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целевой показатель"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить целевой показатель"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Короткий"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очень длинный"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длинный"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средней длины"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непонятно"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесполезно"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плохая поддержка"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорошая поддержка"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изобилие эмоций"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв для агента"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалоба"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно информации"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проблема не решена"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проблема решена"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательная тональность"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плохой результат"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительная тональность"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похвала"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плохой продукт"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат денег"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрая поддержка"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медленная поддержка"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарность"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA автоматически распределяет ваши данные ", _interpolate(_list(0)), " по заранее заданным категориям. Таким образом, вы сможете понимать комплексную аналитику и изучать причины, не просматривая письменные отзывы вручную."])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все оценки"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все опросы"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык отклика"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер комментария"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите размер комментария"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку 25% комментариев состоят только из одного слова, более длинные комментарии обязательны к прочтению. Изучите больше полезных отзывов, не прибегая к ручной работе, поскольку мы выбираем их автоматически."])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отзыва"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину отзыва"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствующих оценок"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозируемые факторы"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию комментария"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слова из облака слов"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать слова из облака слов"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " с последнего периода"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " разговор"]), _normalize([_interpolate(_named("n")), " разговор"]), _normalize(["Количество разговоров: ", _interpolate(_named("n"))])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно рассчитать соотношение отправленных опросов из всех разговоров из-за отсутствия данных."])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклики"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправленные опросы"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воронка опроса"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " из ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " из ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " из ", _interpolate(_named("total"))])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прогнозируемые факторы ", _interpolate(_named("surveyType"))])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zendesk QA автоматически распределяет ваши данные ", _interpolate(_list(0)), " по заранее заданным категориям. Таким образом, вы сможете понимать комплексную аналитику и изучать причины, не просматривая письменные отзывы вручную."])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер комментария"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поскольку 25% комментариев состоят только из одного слова, более длинные комментарии обязательны к прочтению. Изучите больше полезных отзывов, не прибегая к ручной работе, поскольку мы выбираем их автоматически."])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклики на опрос"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (в среднем)"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество откликов"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК (в среднем)"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целевой показатель"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Тенденции ", _interpolate(_list(0)), " по"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество откликов"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причины"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факторы"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дата отправки опроса ", _interpolate(_list(0))])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дата ответа на опрос ", _interpolate(_list(0))])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Назначить оценку ", _interpolate(_list(0)), " пользователю:"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать данные на основе"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания разговора"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец тикета"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["агент с наибольшим количеством ответов"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " (в среднем)"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " со временем"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество откликов"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " и ВОК"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество откликов"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры, содержащие эти слова:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облако слов"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT по пользователям"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор CSAT"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воронка опроса"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклики на опрос"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT со временем"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облако слов"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет канала"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет источника"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет языка"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет причин"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет тегов"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет категории комментариев"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет рабочего пространства"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные недоступны, поскольку не созданы назначения или не начаты циклы. Вернитесь сюда позже или измените фильтры."])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнайте, что нового"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " элемент"]), _normalize(["Количество элементов: ", _interpolate(_named("count"))])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " разговор"]), _normalize(["Количество разговоров: ", _interpolate(_named("count"))])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не пройдено"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдено"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдено с незначительными ошибками"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройдено/не пройдено"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее (ч)"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее (мин.)"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Оценки ", _interpolate(_named("categoryName"))])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее CSAT = [сумма оценок отзывов ÷ (количество всех откликов × максимально возможная оценка)] × 100%"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка CSAT Zendesk QA нормализует оценку отзывов по шкале от 0 до 100% и вычисляет среднее значение стандартизированных оценок."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT (оценка удовлетворенности клиентов) показывает, насколько клиенты довольны вашими услугами."])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " каждая рецензия представляет собой рецензию, сделанную одним человеком в одном разговоре. У разговора может быть несколько рецензий (если один и тот же разговор проверяют несколько рецензентов)."])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следите за изменением оценок рецензентов со временем."])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отслеживайте изменение оценок агентов со временем."])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Количество откликов: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " отклик"]), _normalize(["Количество откликов: ", _interpolate(_named("n"))])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Количество рецензий: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " рецензия"]), _normalize(["Количество рецензий: ", _interpolate(_named("n"))])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии:"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОС (внутренняя оценка качества) — это результат рецензирования вашего разговора."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК = сумма оценок по рецензиям ÷ количество рецензий"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Она рассчитывается как среднее значение с учетом всех оценок по рецензиям (средневзвешенных оценок по категориям), которые соответствуют выбранным условиям фильтра."])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить карту."])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените фильтры и повторите попытку."])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель прохождения отображает результат рецензирования ваших разговоров."])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не пройдено, если оценка рецензии ", _interpolate(_named("failScore")), " или ниже"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пройдено, если оценка рецензии ", _interpolate(_named("passScore")), " или выше"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определяется по заранее заданному исходному уровню, означающему указанное ниже."])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент прохождения = сумма пройденных рецензий ÷ общее количество рецензий"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель прохождения отображает процент успешного прохождения рецензий без ошибок в критических или некритических категориях."])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Например, если рецензент оставляет 3 рецензии в этот день, отображается среднее значение из 3 оценок. Узнайте, как вычисляется каждая ячейка ", _interpolate(_named("link")), "."])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Например, если агент получает 3 рецензии в этот день, отображается среднее значение из 3 оценок. Узнайте, как вычисляется каждая ячейка ", _interpolate(_named("link")), "."])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитываются: значимость категории, критический статус (в отличие от оценок со временем)."])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так как в рецензиях категории могут иметь пометку «н/д», количество рецензий в каждой категории может быть меньше общего количества рецензий, выполненных рецензентом. Узнайте, как вычисляется каждая ячейка"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитываются: значимость категории, критический статус (в отличие от оценок со временем)."])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так как в рецензиях категории могут иметь пометку «н/д», количество рецензий в каждой категории может быть меньше общего количества рецензий каждого агента. Узнайте, как вычисляется каждая ячейка"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитываются: значимость категории, критический статус (в отличие от оценок со временем)."])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Группы категорий отображаются отдельно. Оценка для каждой группы рассчитывается на основе средней оценки категории для этой группы за тот же период. Узнайте, как вычисляется каждая ячейка ", _interpolate(_named("link")), "."])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание."])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитываются: значимость категории, критический статус (в отличие от оценок со временем)."])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize([_interpolate(_list(0)), " Группы категорий отображаются отдельно. Оценка для каждой группы рассчитывается на основе средней оценки категории для этой группы за тот же период. Узнайте, как вычисляется каждая ячейка ", _interpolate(_named("link")), "."])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Исходный уровень ", _interpolate(_named("count")), "%"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без критических ошибок"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель «без критических ошибок» отображает процент от того, сколько рецензий пройдено без ошибок в критических категориях."])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренняя оценка качества"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Другие» основные причины"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Периодическое изменение"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневное изменение"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесячное изменение"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еженедельное изменение"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежегодное изменение"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки со временем"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте оценки за дни или недели."])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет = общая оценка за день или неделю, деленная на общее количество рецензий"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте оценки за дни или недели."])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет = общая оценка за день или неделю, деленная на общее количество полученных рецензий."])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки по категориям"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте среднее арифметическое всех оценок по категориям, поставленных рецензентом за этот период."])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотрите среднее арифметическое всех оценок по категориям, полученных каждым агентом за этот период."])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины низких оценок"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины оценок"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки категорий со временем"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализируйте изменение категорий со временем."])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотрите средние оценки для каждой категории за выбранный день или неделю."])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализируйте изменение категорий со временем."])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотрите средние оценки, полученные для каждой категории за выбранный день или неделю."])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки по рецензиям"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В архиве"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критическая категория"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкала оценивания"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее/всего"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить саморецензии"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт всех карточек"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["разговор"]), _normalize(["разговоры"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["элемент"]), _normalize(["элементы"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" Количество откликов: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " отклик"]), _normalize(["Количество откликов: ", _interpolate(_named("n"))])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Количество рецензий: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " рецензия"]), _normalize(["Количество рецензий: ", _interpolate(_named("n"))])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Показать еще одну"]), _normalize(["Показать еще ", _interpolate(_named("n"))])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последние 30 дней"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последние 7 дней"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За прошлый месяц"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За прошлую неделю"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот месяц"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта неделя"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настраиваемый период"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяцы"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кварталы"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недели"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годы"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить карту. Измените фильтры и повторите попытку."])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для отображения."])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежемесячное изменение"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационные панели"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши активные фильтры включают данные из разных шкал оценивания."])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все закрепленные карты с этими фильтрами"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все закрепленные информационные панели с этими фильтрами"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время рецензирования"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить CSV"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет категорий"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика категорий"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаграмма"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Комментарий"]), _normalize(["Комментарии"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схема"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бывший участник"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренняя оценка качества"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК (в среднем)"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройденные рецензии"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить карту"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить информационную панель"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Основная причина"]), _normalize(["Основные причины"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID рецензии"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время рецензирования"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте основные причины"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины позволяют узнать, почему разговоры получают низкие оценки"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этой категории не заданы основные причины"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка/итог"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории оценивания"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы категорий оценивания"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа категорий"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить порядок"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группировать категории по"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить саморецензии"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить саморецензии"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать только саморецензии"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить фильтры"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги не найдены"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштеги комментариев"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хэштеги комментариев"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги не найдены"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск в тегах службы поддержки"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок не найдены"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать карты оценок"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствующих подключений"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник разговора"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите источник"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие фильтры"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствующих рабочих пространств"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего оставлено раз"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствующих групп."])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать карточки"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать данные на основе"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания рецензии"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания разговора"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать два десятичных знака"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть настройки"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рабочие пространства"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Весь аккаунт"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя рабочего пространства"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет рабочих пространств"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Флаги"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еда и напитки"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Животные и природа"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объекты"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди и тело"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествия и места"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто используемые"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты поиска"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смайлики и эмотиконы"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символы"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смайлики не найдены"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть разговор"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад к списку задач"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("connect")), " или просмотрите список ", _interpolate(_named("link"))])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сейчас вы используете рабочее пространство ", _interpolate(_named("workspace")), ", не подключенное к этому домену. Нажмите здесь, чтобы подключить его сейчас."])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["доступные подключения"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы пока не можете оставить отзыв о ", _interpolate(_named("host")), "."])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша служба поддержки будет активной вкладкой."])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот домен пока нельзя проверить. Если это необходимо, сообщите своему администратору."])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить этот домен"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подключить с помощью ", _interpolate(_named("host"))])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите немного — выполняется настройка"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось получить URL-адрес"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструменты, которые уже подключены к аккаунту организации Zendesk QA."])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет инструментов, подключенных к аккаунту организации Zendesk QA."])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволяет проверять веб-сайты, URL-адреса которых остаются неизменными, добавляя в конце уникальный хэш-код перед рецензированием"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить аккаунт"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши домены"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домены, которые уже подключены к этому рабочему пространству."])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете проверять любую страницу в указанных далее доменах"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные для проверки домены"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширение"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальный URL-адрес"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Еще нет подключенных служб поддержки — перейдите в ", _interpolate(_named("link")), ", чтобы добавить."])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширение браузера Zendesk QA можно использовать только\nв тарифах Professional и Advanced."])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить рабочее пространство"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель:"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите имя"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите значение"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные поля"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы впервые сталкиваемся с этим разговором. Укажите некоторые сведения."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на разговор:"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то, по чему впоследствии можно будет идентифицировать тикет"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат денег"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите исполнителя и тему"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировать этот разговор"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неназначенные"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обратитесь к своему менеджеру или перейдите в другое рабочее пространство из ", _interpolate(_named("settings")), "."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии отключены для этого рабочего пространства."])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До встречи"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот URL-адрес нельзя проверить. Перейдите к разговору."])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала войдите в Zendesk QA через поставщика услуг сквозной авторизации, а затем повторите попытку."])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти с помощью сквозной авторизации"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открывается всплывающее окно для входа."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти в аккаунт Zendesk QA"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите..."])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется аутентификация..."])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас еще нет аккаунта?"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет доступа к этому разговору. Если это необходимо, обратитесь к своему менеджеру."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без доступа"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что ваше подключение активировано в Zendesk QA"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляйте своими подключениями"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка подключения"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы продолжить, перейдите к разговору в службе поддержки"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный URL-адрес"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторное подключение почти выполнено."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минутку"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте перезагрузить страницу, чтобы вернуться к рабочему процессу"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла неполадка"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избавьтесь от виртуальных границ службы поддержки и рецензируйте разговоры где угодно."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысьте план, чтобы использовать расширение"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление подпиской"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение к службе поддержки не связано с этим рабочим пространством"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша служба поддержки не распознает этот тикет или у Zendesk QA нет к нему доступа"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось найти разговор"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое действие"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получили отзыв"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход выполнен"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете использовать приложение прямо сейчас"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценить все категории"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте обращения в закладки и отфильтруйте их позже"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет загрузка..."])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет отзывов для этого разговора"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать отзыв"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть в Zendesk QA"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь будут отображаться все отзывы и уведомления. Пока ничего нет..."])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее см. в Zendesk QA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные рецензии"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения о разговоре"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["содержит все"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["все"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не содержит всех"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не все"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лучше, чем у агента"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хуже, чем у агента"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ложь"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["больше"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["содержит любое из"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["любое из"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не содержит ни одного из"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ни одного из"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["истина"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нарушено"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прокомментировано"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прокомментировано мной"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не прокомментировано"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["без моего комментария"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["критически важно для рецензирования"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["содержит"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 30 дней"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в пределах"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 14 дней"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 24 ч."])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 30 дней"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за последние 7 дней"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за прошлый месяц"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за прошлую неделю"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за этот месяц"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за эту неделю"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обнаружено"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["существует"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["помечено мной"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не помечено мной"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["содержит"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["равно"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["больше"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["начиная с"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["меньше"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не равно"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["равно"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не равно"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отрицательно"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не нарушено"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не сложно"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не обнаружено"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не существует"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не включает"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не получено"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не просмотрено"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["положительно"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не рецензировано"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не рецензировано мной"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензировано"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензировано мной"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получено"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["критически важно для рецензирования"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["содержит"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["равно"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["больше"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["начиная с"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["меньше"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["равно"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не равно"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не содержит"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не равно"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["просмотрено"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["к моему комментарию есть ответы"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нет"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["да"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные категории"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальное выделение"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск условия"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метрики"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия и отзыв"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все условия"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption"))])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнивает обработку разговоров ботом со средними показателями агентов"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот зациклился, повторяя одно и то же сообщение подряд"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов бота в разговорах"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип бота, участвующего в разговоре"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот, участвующий в разговоре"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Контент переписки содержит фразу ", _interpolate(_list(0)), " в сообщениях агентов"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически выявляйте звонки, в которых отсутствует обязательное заявление о раскрытии информации, например «Этот звонок будет записан» и т. д."])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек или бот, участвующий в разговоре"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент или бот, которого рецензируют в разговоре"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент обратился за помощью на более высоком уровне"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговоре обнаружена положительная и отрицательная тональность"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определяет наиболее активного агента в разговорах с помощью анализа ИИ"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, вежливо ли агент завершил разговор"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, понимает ли агент чувства клиентов и признает ли их"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует грамматические, орфографические и стилистические ошибки агентов"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие типичных приветственных фраз"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, понимает ли агент запрос или проблему клиента"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, насколько легко понять текст, учитывая сложность слов и длину предложений"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие предлагаемого решения"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует тон агента на протяжении всего разговора"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Признаки оттока клиентов. Клиент рассматривал возможность перехода или обещал уйти."])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа, к которой принадлежит разговор."])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент или текст сообщения агента"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов агента в разговоре"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент, назначенный для рецензирования разговора"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность телефонного разговора в секундах"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент или текст разговора"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал связи, используемый для разговора (например, электронная почта, чат)"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговор добавлены комментарии"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата добавления комментария в разговор"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговоре обнаружена положительная и отрицательная тональность"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата, когда клиент ответил на опрос, посвященный удовлетворенности клиентов"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оспаривается ли рецензия разговора"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал, через который клиент начинает разговор"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов клиентов в разговорах"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего сообщения в разговоре"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль или тип пользователя, участвующего в разговоре"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышено пороговое значение времени молчания между агентом и клиентом"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент обратился за помощью на более высоком уровне"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент выразил огромную благодарность или очень доволен полученной поддержкой"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент или агент явно запросили дополнение"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст или запись всего разговора, проведенного по голосовой связи"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помечен ли разговор звездочкой"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштеги, используемые в комментариях для классификации контента"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык разговора, определенный ИИ"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего сообщения в разговоре"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество личных примечаний в разговорах"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее количество символов в сообщении в разговорах"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов, обращенных к клиентам, в разговорах"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник, информация о котором находится в открытом доступе"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее количество слов в сообщении в разговорах"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории, используемые для оценки разговора"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус процесса рецензирования разговора"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник или пользователь, участвующий в разговоре"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты, связанный с разговором"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Была ли просмотрена рецензия"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент, который оценил разговор"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней рецензии разговора"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент, рецензируемый в разговоре"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любые конкретные комментарии или отзывы, оставленные клиентом относительно его удовлетворенности разговором"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка удовлетворенности клиента разговором"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка удовлетворенности клиента за каждое сообщение в разговоре"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок, используемая для рецензирования разговора"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заданные SLA были нарушены"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип источника или подключения, в котором начался разговор"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор была необычным или нетипичным, и для выполнения требовалось больше времени"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос из опроса, связанный с контентом разговора"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок или название опроса, связанного с разговором"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент, назначенный для рецензирования разговора"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд или определенный продукт/услуга, связанные с разговором"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал связи, используемый для разговора (например, электронная почта, чат)"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закрытия или выполнения разговора"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания разговора"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания разговора"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата, когда клиент ответил на опрос, посвященный удовлетворенности клиентов"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткое описание или сводка разговора"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний ID ссылки, связанной с разговором"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папка или определенная группа, в которой сохранен разговор"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма или шаблон, используемый для организации разговора"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний ID ссылки, связанной с разговором"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый ящик или почтовый аккаунт, с которого был получен разговор"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона, связанный с разговором"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень приоритета, назначенный разговору"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество ответов в разговоре"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние или текущий статус разговора"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий статус разговора"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предмет или тема разговора"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов агента в разговоре"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги, назначенные для классификации разговора"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип или категория разговора"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего обновления разговора"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего обновления разговора"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средство или канал, через который велся разговор"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент расшифровки в разговоре"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры, которые не подходят для рецензирования с помощью анализа на основе ИИ"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество агентов, участвующих в разговорах"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Был ли просмотрен разговор"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любое сообщение"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективность коммуникации с ботом"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторение бота"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов бота"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип бота"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление звонка"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение для клиента"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молчание"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует сообщение о записи"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эскалация"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент расшифровки звонка"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помечено"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не подходит для рецензирования"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензировано пользователем"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор через"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орфография и грамматика"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение агента"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самый активный агент"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов агента"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность звонка (с.)"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент разговора"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал сообщений"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег комментария"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата комментария"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тональность"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ответа на опрос CSAT"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спор"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочувствие"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал клиента"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов клиента"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего ответа"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип пользователя"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Риск оттока"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молчание"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эскалация"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключительное обслуживание"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнение"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расшифровка"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштег комментария"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аномалия"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего ответа"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество личных примечаний в разговоре"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее количество символов"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее количество слов"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество открытых ответов"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытый участник"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата оценивания"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка и комментарий"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус рецензии"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читаемость"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанная почта"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия получена"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок проверена"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензия просмотрена"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рецензирования"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к опросу"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT за сообщение"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка качества (CSAT)"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложенное решение"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип источника"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос опроса"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок опроса"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд разговора"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домен бренда"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал разговора"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата закрытия"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ответа на опрос CSAT"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отправки опроса CSAT"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание разговора"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний ID"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле разговора"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Папка разговора"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма разговора"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freshdesk ID"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа разговора"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Scout ID"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний ID"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercom ID"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый ящик разговора"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет разговора"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов в разговоре"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние разговора"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус разговора"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема разговора"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ответов агента"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег службы поддержки"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тег службы поддержки"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип разговора"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk ID"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расшифровка контента"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество участвующих агентов"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус просмотра"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможность выявления уязвимостей"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние уязвимости"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие жизненного цикла уязвимости"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория AutoQA"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите связанное с датой условие"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте имя в фильтр"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя фильтра"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек, вошедший в систему"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бывшие участники рабочего пространства"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники рабочего пространства"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Добавить ", _interpolate(_named("filterName"))])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить фильтр"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас должен быть хотя бы 1 фильтр, связанный с датой"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите параметры"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вариант"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается через ", _interpolate(_named("n")), " день. ", _interpolate(_named("link"))])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>через ", _interpolate(_named("n")), " день</strong></a>."])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается через ", _interpolate(_named("n")), " дн. ", _interpolate(_named("link"))])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>через ", _interpolate(_named("n")), " дн</strong></a>."])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается сегодня. ", _interpolate(_named("link"))])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ознакомление заканчивается <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>сегодня</strong></a>."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформить подписку сейчас"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " дн."])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На ознакомление осталось ", _interpolate(_named("days"))])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнуто предельное количество пользователей для вашего тарифа. ", _interpolate(_named("link")), ", чтобы узнать больше или повысить план."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щелкните здесь"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подключите свою службу поддержки"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это позволит нам извлекать ваши тикеты для рецензирования."])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите свою службу поддержки"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы используете Zendesk QA с образцами данных. Чтобы удалить образцы данных и импортировать собственные разговоры, ", _interpolate(_named("link")), "."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образец данных"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопируйте полезную информацию ниже"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделайте снимок экрана на всю страницу, чтобы предоставить контекст"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для решения этой проблемы обратитесь в нашу группу поддержки клиентов."])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла неполадка"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения об ошибке:"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать полезную информацию"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи, боты и рабочие пространства"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи и рабочие пространства"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за этот месяц"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за эту неделю"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Рецензировано ", _interpolate(_named("n")), " из ", _interpolate(_named("total")), " ", _interpolate(_named("period"))]), _normalize(["Рецензировано ", _interpolate(_named("n")), " из ", _interpolate(_named("total")), " ", _interpolate(_named("period"))]), _normalize(["Рецензировано ", _interpolate(_named("n")), " из ", _interpolate(_named("total")), " ", _interpolate(_named("period"))])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нового?"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочетания клавиш"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать чат"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить чат (возможно, вы используете блокировщик рекламы?)"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к..."])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщество"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационная панель"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная страница"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные рецензии"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные рецензии"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База знаний"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить аккаунт"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приложение обновлено. ", _interpolate(_named("buttonStart")), "Перезагрузить", _interpolate(_named("buttonEnd")), "."])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы хотите использовать Zendesk QA в рамках своего рабочего пространства, выполните такие действия."])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сообщаем, что вы уже получили приглашение использовать Zendesk QA в рамках рабочего пространства. Вы можете продолжить, но учтите, что при этом будет создана ", _interpolate(_named("bold")), "."])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдельная организация"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Да, продолжить и использовать ", _interpolate(_named("link")), "."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новый аккаунт"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("inviter")), " просит вас присоединиться к ", _interpolate(_named("account"))])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединиться"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подключения к Интернету"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую закладку"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрая навигация"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сфокусировать и развернуть редактор комментариев"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть или показать боковую панель"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к следующему разговору"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть исходный разговор"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к предыдущему разговору"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвоить всем категориям самую высокую оценку"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвоить всем категориям самую низкую оценку"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемешать разговоры (в случайном порядке)"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщите нам"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочетания клавиш"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть модальное окно сочетания клавиш"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы достигли ограничения количества символов: ", _interpolate(_named("charLimit"))])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изображение типа ", _interpolate(_named("name")), " (", _interpolate(_named("type")), ") не разрешено"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Размер изображения ", _interpolate(_named("name")), " слишком большой (разрешено не более 5 МБ)"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить редактор комментариев"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетащите, чтобы выложить"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIF"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление шаблонами"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных шаблонов"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить основную причину"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое, включая удаленные причины"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина оценки"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество сотрудников"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы свяжемся с вами и поможем настроить аккаунт"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название компании"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактный телефон компании"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как вы впервые услышали о Zendesk QA?"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите канал"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите количество агентов"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все обязательные поля"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы должны прочитать и принять наши условия и политику конфиденциальности."])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал Slack для службы поддержки"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Друг или коллега"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин приложений или аккаунт-менеджер службы поддержки"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направивший сайт"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поисковая система (Google, Bing и т. д.)"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Социальные сети"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расскажите о себе и своей компании."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные аккаунта"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово."])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осматривайтесь, изучайте приложение, удаляйте и добавляйте элементы."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт создан. Что дальше?"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовы начать работу с настоящим разговором?"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте свои данные"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать изучение"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте, используя образцы данных"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итак, мы можем начать извлекать ваши тикеты для рецензирования."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите аккаунт службы поддержки"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я передумал(а)."])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить регистрацию"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Закладка создана. ", _interpolate(_named("buttonStart")), "Перейти к закладкам", _interpolate(_named("buttonEnd")), "."])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставнические закладки доступны в пакете Professional."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысить тарифный план"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все тарифы"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните свои заметки, чтобы подготовиться к следующей личной встрече или сеансу наставничества."])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки для наставничества"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прокомментируйте или добавьте других с помощью ", "@"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот разговор удален"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте новую закладку"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать закладку"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить закладку"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принцип работы закладок"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам не разрешено просматривать этот разговор"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить карту"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить разговор"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить информационную панель"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладка сохранена"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить эту карту"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить этот разговор"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить эту информационную панель"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить за пользователем"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователя"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только вы можете видеть то, что закрепили. Чтобы поделиться с другими, ", "@", "упомяните их в комментариях."])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("agentsNames")), " приглашен(а) в этот разговор"]), _normalize(["Приглашено в этот разговор: ", _interpolate(_named("agentsNames"))])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 комментарий"]), _normalize(["Количество комментариев: ", _interpolate(_named("n"))])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вы упомянули ", _interpolate(_named("participants")), ", но он(а) не находится в этом разговоре."]), _normalize(["Вы упомянули ", _interpolate(_named("participants")), ", но они не находятся в этом разговоре."])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Количество частников: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " участник"]), _normalize(["Количество участников: ", _interpolate(_named("n"))])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить доступ ", _interpolate(_named("agentName")), " к этой закладке?"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не делать"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не приглашать в этот разговор"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте комментарий"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить в этот разговор"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить пользователя"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цепочка (писем)"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эту операцию нельзя будет отменить."])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Также будут удалены все комментарии к этой закладке."])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Новых сообщений: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " новое сообщение"]), _normalize(["Новых сообщений: ", _interpolate(_named("n"))])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши закладки видны только вам. Чтобы поделиться закладками, ", "@", " кого-то в комментариях."])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("agentName")), " закрепил(а) элемент «", _interpolate(_named("pinType")), "»"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["информационная панель"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговор"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["примечание"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысьте план, чтобы получить наставнические закладки"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставнические закладки доступны в пакете Professional."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставнические закладки доступны в пакете Professional"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысьте план, чтобы получить функцию"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подготовьтесь к индивидуальной встрече или отложите на другой раз."])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладка и все добавленные комментарии будут необратимо удалены."])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить закладку"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить закладку"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя папки"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепите элементы, чтобы вернуться к ним позже"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответить"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала новые"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сначала старые"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните интересную тенденцию информационной панели или разговор для подготовки к личной встрече или для дальнейшего использования."])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте закладку, нажав кнопку выше или значок булавки в виде разговора или информационной панели."])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрепить"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет созданных закладок"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Нет результатов для ", _interpolate(_list(0))])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте использовать другие ключевые слова или проверьте наличие опечаток."])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте новую закладку"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люди"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по пользователю, ключевому слову..."])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По алфавиту"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно созданные"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно измененные"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наибольшее количество закладок"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Я собираюсь рецензировать ", _interpolate(_named("n")), " переписку ", _interpolate(_named("schedule"))]), _normalize(["Я собираюсь ", _interpolate(_named("schedule")), " рецензировать переписок: ", _interpolate(_named("n"))])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель обновлена"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать цель"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете задать личную цель для количества разговоров, которые необходимо рецензировать."])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["каждый день"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["каждый месяц"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["каждую неделю"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель рецензирования"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛИЧНЫЕ"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка каналов"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать канал"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делиться отзывами об опросе только с комментариями"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поощряйте своих коллег, отмечая их исключительное качество обслуживания и отличные отзывы клиентов."])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикуйте положительные отзывы об опросах в Slack"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы не найдены"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически делитесь отзывами с оценками выше"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции с ботом похвалы"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте напоминание в начале недели, если не сделаете ни одной рецензии."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еженедельное напоминание о рецензиях"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте уведомления о новых рецензиях, ответах, упоминаниях и спорах в Slack."])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминания"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответы в цепочке писем"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции Slack"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомлять о новых рецензиях, ответах, упоминаниях и спорах."])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры, связанные со мной"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминания в комментариях"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые полученные рецензии"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответы в цепочках писем"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты со статистикой"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте отчеты об оставленных и полученных рецензиях."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в день"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в месяц"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в неделю"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в день"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждые 3 часа"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждые 6 часов"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мгновенно"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в месяц"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выкл."])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в неделю"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор вашего аккаунта заблокировал настройки уведомлений."])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные уведомления"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), " (активно)"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте в этот аккаунт новый способ входа через социальную сеть."])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При удалении активного способа входа вы выйдете из аккаунта. Вы можете снова войти в систему, указав другой адрес электронной почты."])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить способ входа"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт Google добавлен как способ входа"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы входа"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack добавлен как способ входа"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить Google"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить Slack"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пароль"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На адрес ", _interpolate(_named("email")), " будет отправлено письмо для сброса пароля."])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["основной адрес электронной почты"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите в своем почтовом ящике письмо с подтверждением"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для этого измените ", _interpolate(_named("link")), " заранее."])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Почта ", _interpolate(_named("email")), " будет связана с этим паролем."])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль обновлен"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение профиля"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуется загрузить квадратную фотографию (256 x 256 пикселей или больше)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной адрес почты"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало недели"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные настройки"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите по крайней мере один параметр"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержимое шаблона"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя шаблона"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать шаблон в"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить содержимое шаблона"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте имя шаблона"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавайте шаблоны комментариев, чтобы ускорить рецензирование, добавление закладок к элементам и наставничество."])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется в"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить шаблон"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить этот шаблон окончательно?"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить шаблон"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавайте шаблоны комментариев, чтобы ускорить рецензирование, добавление закладок к элементам и наставничество."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет созданных шаблонов"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон комментария создан"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон комментария удален"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон комментария сохранен"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии и споры"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для получения доступа свяжитесь со ", _interpolate(_named("link")), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["службой поддержки клиентов"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунты не найдены"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пока используйте ", _interpolate(_named("link")), " для доступа к Zendesk QA."])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться в Zendesk"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт не перенесен"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот QA"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вок"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["метрики"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оценки"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статистика"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационная панель"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["калибровка"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговоры"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фильтры"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензия"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мои комментарии"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мои споры"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мои рецензии"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакции"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начато"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв об опросе"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["создать пароль"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["учетные данные"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вход"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сбросить"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цель рецензирования"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шаблоны"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пользователь"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аккаунт"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выставление счетов"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компания"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["организация"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплата"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["места"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["настройки"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подписка"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификации"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая категория"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдено конфликтующее сообщение"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените или удалите одно из конфликтующих сообщений"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Используйте значок ", _interpolate(_named("placeholder")), ", чтобы исключить конфиденциальные данные из AutoQA. Например, вместо «Всего доброго, Иван» используйте «Всего доброго, ", _interpolate(_named("placeholder")), "» для обозначения любого имени."])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальное количество строк: ", _interpolate(_named("limit"))])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить правило к"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество настраиваемых категорий: 10"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнуто предельное количество специальных категорий: ", _interpolate(_named("limit"))])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить условие"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить строку"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить условие"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если сообщение агента"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В противном случае оценка"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затем оценка"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать категорию карты оценок"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description for reviewers (optional)"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте «/», чтобы добавить переменную"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все направления"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение агента не может быть пустым"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение не может содержать более 300 символов"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все источники"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все каналы"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все теги"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все разговоры"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уточните, где применяется ваше правило."])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкретные разговоры"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аналитику выделения"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение агента не может быть пустым"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество настраиваемой аналитики: 10"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Достигнуто предельное количество специальной аналитики: ", _interpolate(_named("limit"))])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название аналитики"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название аналитики"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать категорию"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип аналитики"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательная"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нейтральная"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительная"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к настройкам рабочего пространства"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сейчас"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы начать использование этой категории оценивания, перейдите в настройки карты оценок вашего рабочего пространства и добавьте ее в выбранную вами карту оценок."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания создана"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически оценивайте закрытые разговоры по определенным ключевым словам или фразам."])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не работает с расшифровкой голосовой связи."])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поздравляем! ✋ Достигнуто максимальное количество категорий автоматического оценивания: ", _interpolate(_named("limit")), ". Вы мастер классификации! 🧙"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество категорий"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что все поля заполнены правильно"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назовите категорию оценивания"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя категории"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая категория оценивания"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая категория оценивания"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически оценивайте закрытые разговоры по определенным ключевым словам или фразам. Не работает с расшифровкой голосовой связи."])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически находите закрытые разговоры по определенным ключевым словам или фразам. Работает только с расшифровками."])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически оценивайте разговоры, определяя определенные ключевые слова или фразы как в сообщениях агента, так и в сообщениях бота."])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы использовать эту категорию оценивания, перейдите в настройки карты оценок вашего рабочего пространства и добавьте ее в карту оценок."])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически оценивайте разговоры на основе определенных ключевых слов или фраз в сообщениях."])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно для"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка аналитики"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта аналитика отображается в выделенном на основе заданных критериев."])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка карты оценок"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эту категорию можно использовать в картах оценок на основе определенных критериев."])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бот"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чат"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ожидается"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["специальный"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заранее заданные"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["карта оценок"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выделение"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["голосовая связь"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения сохранены"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совет."])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click on the ", _interpolate(_named("placeholder")), " icon to exclude names, company details, or any other sensitive information during AutoQA's closing checks."])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, instead of 'Take care, John' you can use 'Take care, ", _interpolate(_named("placeholder")), "' to represent any name."])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите на значок ", "{··}", ", чтобы исключить имена, сведения о компании или любую другую конфиденциальную информацию при проверках закрытий AutoQA."])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить утвержденное закрытие"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить утвержденное закрытие"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденное закрытие"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить исключения"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить исключение"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключение"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить утвержденное приветствие"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить утвержденное приветствие"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденное приветствие"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте сюда слова, разделенные переносом строки"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимайте Enter после каждого слова, чтобы сделать новую запись"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применимый язык"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например, вместо «Всего доброго, Иван» можно использовать «Всего доброго, ", "{··}", "» для обозначения любого имени."])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переменная динамического контента"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключения"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавлено 1 исключение"]), _normalize(["Добавлено такое количество исключений: ", _interpolate(_named("count"))])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Исключение уже добавлено"]), _normalize(["Уже добавленных исключений: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить исключение"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключения не найдены"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных исключений"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключение (с учетом регистра)"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавить исключения"]), _normalize(["Добавить 1 исключение"]), _normalize(["Добавить такое количество исключений: ", _interpolate(_named("count"))])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск слова или фразы"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключение сохранено"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["язык"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все языки"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск языка"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Закрытие уже добавлено"]), _normalize(["Уже добавлено такое количество закрытий: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить закрытие"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные закрытия не найдены"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет утвержденных закрытий"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить закрытие"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск закрытия"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие добавлено"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие сохранено"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["В форме есть дублирующиеся записи: ", _interpolate(_list(0))])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Приветствие уже добавлено"]), _normalize(["Уже добавлено приветствий: ", _interpolate(_named("phrases"))])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить приветствие"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные приветствия не найдены"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет утвержденных приветствий"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить приветствие"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск приветствия"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие добавлено"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие сохранено"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить исключение"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Удалить исключение"]), _normalize(["Удалить исключения"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Окончательно удалить это исключение?"]), _normalize(["Окончательно удалить эти исключения?"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить исключение"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Длина одного или нескольких исключений превышает такое количество символов: ", _interpolate(_list(0))])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Символов: ", _interpolate(_named("phraseLength")), " из ", _interpolate(_named("maxPhraseLength"))])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлено"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто обновил"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переменная"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте собственные подписи, соответствующие стандартам вашего бренда и обслуживания. После добавления AutoQA будет обнаруживать только утвержденные закрытия. Внесенные изменения применяются к будущим разговорам."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут верхний предел — 100 утвержденных закрытий. Если необходимо добавить больше, попробуйте пересмотреть и уточнить существующие закрытия."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут предел утвержденных закрытий"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные закрытия"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слова и фразы, которые должны игнорироваться AutoQA как орфографические или грамматические ошибки. Внесенные изменения применяются к будущим разговорам."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список игнорируемых"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задавайте собственные приветствия, соответствующие стандартам вашего бренда и обслуживания. После добавления AutoQA будет обнаруживать только утвержденные приветствия. Внесенные изменения применяются к будущим разговорам."])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество утвержденных приветствий — 100. Если вы хотите добавить больше приветствий, возможно, пришло время пересмотреть и уточнить существующие приветствия."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество утвержденных приветствий"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные приветствия"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сейчас"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить категорию"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончательно удалить эту категорию оценивания?"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список категорий"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаленная категория не будет отображаться в новых разговорах и фильтрах. Данные за прошлые периоды на информационных панелях и в разговорах остаются без изменений."])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория будет удалена изо всех карт оценок, в которые она добавлена."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить категорию карты оценок"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория карты оценок удалена"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить категорию карты оценок"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории AutoQA"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " на основе модели ИИ останутся в карте оценок, но больше не будут оцениваться автоматически"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключение интеграции LLM не отключает функцию AutoQA полностью. Вот что произойдет."])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные за прошлые периоды"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " останутся на информационной панели AutoQA"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавленные вручную основные причины"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("highlight")), " останутся, но все основные причины на основе LLM будут удалены"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключение AutoQA на основе LLM"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA использует ИИ и большие языковые модели (LLM) для автоматической оценки определенных категорий. Ваши данные обрабатываются в соответствии со строгим соглашением об обработке данных (DPA) и никогда не используются для обучения моделей."])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о генеративном ИИ"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AutoQA использует службу Microsoft Azure OpenAI для ", _interpolate(_named("automatically_scoring_categories")), ". Ваши данные обрабатываются в соответствии со строгим DPA только для предоставления AutoQA вам и ", _interpolate(_named("never_used_for_training_models")), "."])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автоматической оценки некоторых категорий"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["никогда не используется для обучения моделей OpenAI"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция AutoQA на основе LLM включена"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция AutoQA на основе LLM отключена"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA на основе LLM"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое оценивание с помощью ИИ в AutoQA"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка данных категорий AutoQA на основе MS Azure"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласие Microsoft Azure отозвано"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласие Microsoft Azure получено. Скоро разговоры будут получать автоматические оценки."])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы и дальше можете использовать AutoQA, но вот что произойдет"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отключении службы MS Azure OpenAI функция AutoQA не отключается."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это можно сделать в виде категорий карты оценок."])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории AutoQA на основе модели останутся в карте оценок, но не будут оцениваться автоматически."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавленные вручную основные причины останутся. Все основные причины, созданные моделью, будут удалены."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные за прошлые периоды останутся на информационной панели AutoQA."])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить обработку данных службой MS Azure OpenAI"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более широкий диапазон категорий AutoQA."])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовая многоязычная поддержка."])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрое добавление новых категорий."])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повышенная точность оценивания. GPT-3.5 показывает контекстную осведомленность на уровне человека."])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воспользуйтесь последними достижениями в области технологий ИИ."])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вот что вы получите"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить обработку данных службой MS Azure OpenAI"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Azure"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласие получено"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласие не получено"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все новые пользователи назначаются этому рабочему пространству"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство по умолчанию"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["12-часовой формат времени (", _interpolate(_named("time")), ")"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["24-часовой формат времени (", _interpolate(_named("time")), ")"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат времени"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пн"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вс"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль компании обновлен"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало недели"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настраиваемая интеграция"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ключа и секретный ключ доступа обычно находятся в разделе «Управление доступом > Пользователи»"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ключа доступа к AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, AKIAIOSFODNN7EXAMPLE"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить Amazon Connect"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить изменение секретного ключа доступа"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить значение секретного ключа доступа"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение позволяет определить только один регион, который обычно находится в разделе «Местоположение»"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, eu-central-1"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить секретный ключ"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ доступа AWS"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всю необходимую информацию можно найти в Amazon Connect."])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычно она находится на страницах «Настройки экземпляра Amazon Connect» и «Хранилище данных»"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место хранения записей звонков"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, amazon-connect-123456/connect/instance-name/CallRecordings"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При добавлении Amazon Connect возникла ошибка. Убедитесь, что все поля заполнены правильно, и повторите попытку."])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место хранения должно быть указано в формате корзина/папка через косую черту."])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Чтобы добавить подключение, обратитесь в ", _interpolate(_named("link")), "."])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["службу поддержки клиентов"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все ваши тикеты Zendesk будут автоматически синхронизироваться. Вы можете исключить выбранные тикеты."])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АТР (Токио)"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АТР (Сеул)"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АТР (Осака)"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АТР (Мумбаи)"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АТР (Сидней)"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канада (Центр)"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Франкфурт)"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Цюрих)"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Ирландия)"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Европа (Лондон)"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион сервера"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний Восток (ОАЭ)"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите регион сервера"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Южная Америка (Сан-Паулу)"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["США, Восток (Вирджиния)"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["США, Восток 2 (Огайо)"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["США, Запад (Орегон)"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение уже добавлено"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать подключение"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать только те разговоры, которые"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первый канал, второй канал"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Уникальный ID этого подключения: ", _interpolate(_named("tokenId"))])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 месяц"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 год"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 месяца"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 месяцев"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивные разговоры будут удаляться по истечении выбранного периода хранения. Отправленные рецензии остаются видимыми в видах «Действия» и «Информационная панель»."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период хранения"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период хранения"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратите внимание, что это действие необратимо."])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я понимаю, что при этом из Zendesk QA будут удалены все данные службы поддержки для настоящего подключения, включая переписки, поля и теги."])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На отправленные рецензии это не повлияет, они и дальше будут отображаться в определенных видах."])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первая группа, вторая группа"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["активная разработка"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Эта интеграция все еще находится в стадии «", _interpolate(_named("activeDevelopment")), "». Некоторые данные могут отсутствовать (например, прикрепленные файлы или специальные поля)"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первый почтовый ящик, второй почтовый ящик"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первый тег, второй тег"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен API"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ токена API"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите во Freshdesk пункт «Названия полей тикетов» в меню «Настройки» → «Поля тикетов»"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите в Help Scout пункт «Custom Field Names» в меню «Settings» → «Custom fields»"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите в Kustomer пункт «Названия разговоров Klass», пункт «Настройки» → «Klasses» → «Разговор»"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите в Live Agent пункт «Название специального поля» в меню «Настройки» → «Специальные поля»"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдите в Wix пункт «Название специального поля» в меню «Настройки» → «Специальные поля»"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID полей вводятся через запятую. Эти ID можно найти в Zendesk в меню «Администратор» → «Поля тикетов»."])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите нескольких пользователей вашей службы поддержки, чтобы создать первые рецензии к разговорам. Не волнуйтесь, мы не будем отправлять им уведомления, пока вы не пригласите их стать участниками рабочего пространства."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция выполнена"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить участников"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь мы начнем извлекать ваши разговоры."])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Домен: ", _interpolate(_list(0))])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Идентификатор службы поддержки: ", _interpolate(_list(0))])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось провести аутентификацию вашего подключения"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все готово. Это ваше новое подключение."])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некоторые подключения не обновлялись или стали неактивными. Попробуйте обновить эти подключения или удалите их из списка."])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID клиента API"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секрет клиента API"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Создайте новый токен API в меню «Настройки» → «Аккаунт» → «API и SDK». Следует использовать URL-адрес перенаправления: ", _interpolate(_named("redirectUrl"))])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID аккаунта"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите свою службу поддержки, чтобы начать работу"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если ваш аккаунт Intercom настроен на обслуживание из ЕС или Австралии, обновите его."])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Адрес электронной почты, который вы используете для входа в ", _interpolate(_list(0))])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скройте имя, адрес электронной почты и номер телефона конечного пользователя. Банковские учетные данные уже скрыты для всех подключений к службе поддержки."])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет интеграций. Добавьте одну прямо сейчас."])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам нужны эти роли:"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать подключение"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новое подключение"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сняв этот флажок, вы сможете вручную добавлять это подключение только в определенные рабочие пространства"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключаться ко всем существующим рабочим пространствам"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рабочие пространства"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ API"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ API"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Подключение к ", _interpolate(_list(0)), " удалено"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя подключения"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время создания"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен API можно получить внизу."])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пороговое значение CSAT"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить подключение ", _interpolate(_named("connectionName"))])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите «удалить подключение», чтобы удалить это подключение"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление подключения"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляйте подключенными источниками данных и контролируйте их."])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить подключение"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Названия полей вводятся через запятую"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обновления"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвойте интеграции уникальное имя"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть дополнительные параметры"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть поля конфиденциальных данных"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять URL-адреса прикрепленных файлов"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорировать прикрепленные файлы"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять контент разговора (позже его можно будет извлечь из службы поддержки по запросу)"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорировать контент разговора"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять тему разговора (позже ее можно будет извлечь из службы поддержки по запросу)"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорировать строку темы"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ваш субдомен ", _interpolate(_list(0))])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион Intercom"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопоставьте ID команды входящих сообщений"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Числовой ID вашей команды Intercom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется..."])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовые ящики"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить подключение вручную"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть данные клиента"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет рабочих пространств"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название конфиденциального поля"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID конфиденциального поля"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить подключение"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить подключение"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться к сэндбоксу Salesforce"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Настройка подключения к ", _interpolate(_list(0))])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать дополнительные параметры"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извлекать только разговоры с оценкой ниже или равной заданному пороговому значению"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить пороговое значение CSAT"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать только разговоры, которые..."])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить последние разговоры"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить подключение"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение обновлено"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша служба поддержки"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для отмены подписки и удаления аккаунта обратитесь в ", _interpolate(_named("link")), "."])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["службу поддержки клиентов"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление тикета в Zendesk Support (или другой службе поддержки) не влияет на рецензирование связанного с ним разговора. Вы можете просматривать его и управлять им."])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["При удалении тикета в Zendesk QA также удаляются и связанные с ним рецензии разговоров.", _interpolate(_named("line_break")), "Удаление тикета в Zendesk Support (или другой службе поддержки) не влияет на рецензии связанных с ним разговоров. Вы сможете просматривать его и управлять им."])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление рецензиями"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление рецензий необратимо. Это также влияет на данные на информационных панелях."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все рецензии"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензии удалены"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензии, связанные с удаленными тикетами, отображаются здесь."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет рецензий"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск ID тикета"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID тикета: ", _interpolate(_named("id"))])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаленные разговоры"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Стандартные рабочие пространства нельзя удалить. Для удаления этого рабочего пространства выберите другое стандартное рабочее пространство в ", _interpolate(_named("link")), "."])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки аккаунта"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие нельзя будет отменить."])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удаление всех данных"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Я понимаю, что это обеспечит ", _interpolate(_named("removeAllData")), ", связанных с этим рабочим пространством, включая пользователей и подключения рабочего пространства."])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить рабочее пространство"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите «удалить рабочее пространство», чтобы удалить это рабочее пространство"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить рабочее пространство ", _interpolate(_named("workspaceName"))])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удалить рабочее пространство"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь в правильности своих действий или проконсультируйтесь со специалистом заранее."])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите, следует ли выбирать рецензируемого по умолчанию на основе агента, который чаще всего встречается, или текущего исполнителя."])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самый активный агент"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый по умолчанию"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить пользователям рецензировать собственные разговоры и разрешить агентам рецензирование"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство удалено"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство обновлено"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите нейтральную оценку, скрывая рецензии, ранее оставленные другими рецензентами"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объективная оценка"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие нельзя будет отменить."])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратите внимание, что при этом будут удалены все данные, связанные с этим рабочим пространством, включая пользователей и подключения."])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить рабочее пространство"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление рабочего пространства..."])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okta SSO"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно деактивировать текущий способ входа"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы аутентификации обновлены"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить определенные типы способов аутентификации для всех пользователей."])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML/SSO"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификации"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АККАУНТ"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи и рабочие пространства"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавайте рабочие пространства и управляйте ими, а также приглашайте или подключайте пользователей."])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставаться на странице"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинуть страницу"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинуть эту страницу и потерять все изменения?"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификации"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, нет переписок для импорта. Сначала свяжитесь с сотрудниками службы поддержки, а затем вернитесь сюда для их анализа."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переписки не найдены"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя удалить эту системную категорию"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить категорию"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление категорий необратимо. Все данные категорий будут удалены из отчетов."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы восстановить эту категорию, необходимо создать ее снова."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить категорию"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom category type"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find phrases or keywords"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've reached the 20 active custom auto categories limit. To create a new category, mark another one as inactive or delete it."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact text-match"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review without automations"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск категории"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматические категории автоматически заполняют карту оценок предлагаемыми оценками, чтобы сэкономить время на рецензирование. Категории, заданные вручную, заполняются рецензентами с нуля."])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее обновление"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вручную"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category created"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create category"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочувствие"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орфография и грамматика"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making changes to general category settings applies to all scorecards this category is part of"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читаемость"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложенное решение"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории не найдены"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины не найдены"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок не найдены"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте другие условия поиска или смените фильтры"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категорий: ", _interpolate(_named("count"))])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категорий: ", _interpolate(_named("count"))])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категорий: ", _interpolate(_named("count"))])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все категории"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категорий: ", _interpolate(_named("count"))])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карт оценок: ", _interpolate(_named("count"))])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карт оценок: ", _interpolate(_named("count"))])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карт оценок: ", _interpolate(_named("count"))])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все карты оценок"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Карт оценок: ", _interpolate(_named("count"))])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статусов: ", _interpolate(_named("count"))])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статусов: ", _interpolate(_named("count"))])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статусов: ", _interpolate(_named("count"))])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все статусы"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статусов: ", _interpolate(_named("count"))])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Типов: ", _interpolate(_named("count"))])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Типов: ", _interpolate(_named("count"))])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Типов: ", _interpolate(_named("count"))])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все типы"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Типов: ", _interpolate(_named("count"))])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рабочих пространств: ", _interpolate(_named("count"))])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рабочих пространств: ", _interpolate(_named("count"))])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства не найдены"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рабочих пространств: ", _interpolate(_named("count"))])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рабочие пространства"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рабочих пространств: ", _interpolate(_named("count"))])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting root causes is permanent. It will erase all root cause data from reporting."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore this root cause, you'll need to create it again."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete root cause"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause name"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root cause"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for root cause"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add category"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group section"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is inactive, you can activate it by turning on the main AutoQA toggle in Reviews. Once AutoQA is active, scorecards leave auto-reviews in each selected workspace."])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently AutoQA is active, and this scorecard will leave auto-reviews in each selected workspace."])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for auto-reviews in"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " now fails the whole review"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight determines the category's importance. Weight 0 means the category doesn't contribute to the Internal Quality Score (IQS)."])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show on the scorecard under certain conditions"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the group only"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical category"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails the whole review"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select category"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit general category settings"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from scorecard"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit root causes"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight must be between ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add at least 1 category to the scorecard"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least 1 workspace"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group section name"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled for manual reviews in"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled scorecard name"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard published successfully"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root causes"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to explain rating"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple root causes"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘Other’ option and comment field"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from the list or create a new root cause"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add root causes to identify reasons behind issues"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Scorecard"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total weight"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Значимость ", _interpolate(_named("weight")), " вносит ", _interpolate(_named("percentage")), " % в общую оценку"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must have at least 1 scorecard"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить карту оценок"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление карт оценок является необратимым. Все данные карты оценок будут удалены из отчетов."])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы восстановить эту карту оценок, необходимо создать ее снова."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить карту оценок"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Scorecard"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for scorecard"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее обновление"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies to all categories"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rating scales within existing scorecards will include N/A as an option"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify for each category in scorecard setup"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review settings"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as active"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as inactive"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category deleted"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes applied"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root cause deleted"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as active"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard deleted"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard duplicated"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecard marked as inactive"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Шкала оценивания обновлена. ", _interpolate(_named("buttonStart")), "Начать рецензирование", _interpolate(_named("buttonEnd")), "."])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA по умолчанию задает двоичную шкалу, но вы можете выбрать более точную шкалу:"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если вы хотите просмотреть различные варианты шкал оценивания, настроенные по категориям, перейдите в раздел ", _interpolate(_named("link"))])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочувствие/тон"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знания о продукте"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При обновлении шкал оценивания:"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша предыдущая шкала оценивания и соответствующие категории будут помещены в архив (но не удалены)."])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это повлияет на некоторые данные информационной панели, так как старая и новая шкалы могут быть несопоставимы."])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клонировать существующую настройку категорий оценивания в новую шкалу"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно обновить шкалы оценивания?"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно изменить формат отображения карты оценок (цифры или смайлики) и сделать категории обязательными или необязательными."])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать смайлики вместо цифр"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить пользователям пропускать категории"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шкалы оценивания"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Категории для 1 рабочего пространства клонированы"]), _normalize(["Клонированы категории для такого количества рабочих пространств: ", _interpolate(_named("workspacesAmount"))])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки рабочего пространства"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если ", _interpolate(_named("messageType"))])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически выявляйте звонки, в которых агент не просит клиента подождать во время разговора."])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разговоре есть моменты молчания, длящиеся дольше заданного порогового значения."])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте максимальное пороговое значение для молчания в звонках"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молчание"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаленная аналитика не будет отображаться в новых разговорах и фильтрах. Данные за прошлые периоды на информационных панелях и в разговорах остаются без изменений."])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить аналитику Spotlight"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика Spotlight удалена"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аналитику Spotlight"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте разговоры, содержащие определенные слова или фразы в расшифровках и сообщениях."])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически выявляйте звонки, в которых отсутствует обязательное заявление о раскрытии информации, например «Этот звонок будет записан» и т. д."])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель — определять сообщение о записи во время разговора с использованием большой языковой модели (LLM), которая динамически понимает контекст, устраняя необходимость в заранее заданных фразах."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует сообщение о записи"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически выявляйте звонки, в которых агент не просит клиента подождать во время разговора."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Молчание"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите моменты молчания в звонке"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Хотите внести изменения в подписку? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), "), и мы с радостью поможем вам."])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически анализируйте 100% разговоров разных агентов, BPO, из разных каналов и на разных языках. ИИ выявляет проблемы, пробелы в знаниях и предоставляет возможности наставничества для улучшения качества обслуживания."])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA для 100% охвата"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы и наставничество для агентов"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение аналитики ИИ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенная отчетность и информационные панели"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ тональности и фильтрация"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы по запросу"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения рецензий и настройка целей"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наставничество и опросы"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспечение качества Zendesk "])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все функции тарифа Professional, а также"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced (для старых пользователей)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook для немедленного уведомления о критических ошибках"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция хранилища данных"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция Salesforce Service Cloud"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка специальной интеграции"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональный менеджер по работе с клиентами"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прямой доступ к нашим командам по продуктам и разработке через Slack"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все функции тарифа Growth Suite, а также"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suite с ИИ"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA для 100% охвата"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика разговоров на основе ИИ для анализа данных"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обогащение и фильтрация данных службы поддержки с помощью ИИ"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение: Автоматическое определение обязательных для рецензирования взаимодействий"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ тональности и фильтрация"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты о производительности и аналитика на базе ИИ"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод разговоров по запросу"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка учета времени"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика показателей прохождения"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все функции AI Suite, а также"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет внедрения Enterprise, включая интеграцию Salesforce и Genesys"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработка специальной интеграции"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO SAML"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция хранилища данных"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook для немедленного уведомления о критических ошибках"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональный менеджер по работе с клиентами"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прямой доступ к нашим командам по продуктам и разработке"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранний доступ и влияние на наш план мероприятий"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth Suite"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое назначение рецензий и постановка целей"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целенаправленное наставничество и аналитика"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы для управления обучением"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями и разрешениями пользователей"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрытие конфиденциальных данных"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширенные параметры фильтрации и поиск разговоров"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настраиваемые карты оценок с несколькими рейтингами, регулируемой значимостью и параметрами автоматического непрохождения"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширение браузера для рецензирования разговоров в любом месте"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ основных причин"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты об эффективности для сравнения агентов, команд, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос CSAT и аналитика отзывов клиентов"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все функции тарифа Starter, а также"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional (для старых пользователей)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несколько шкал оценивания для каждого разговора"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция со Slack"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерактивная аналитика разговоров на основе ИИ"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить назначения"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закладки для наставничества"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы наставничества"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ тональности и фильтрация"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтрация на основе сложности разговора"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика разговоров"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условные карты оценок"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ основных причин"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика CSAT на базе расширенного ИИ"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка оценок"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширение браузера для рецензирования разговоров в любом месте"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение разговоров, обязательных для рецензирования"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод разговоров по запросу"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка учета времени"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный тариф"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter (для старых пользователей)"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неограниченное количество рецензий разговоров"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт CSV"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взаимное рецензирование и саморецензии"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок с регулируемой значимостью и параметрами автоматического непрохождения"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надежные параметры фильтрации"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневные, еженедельные и ежемесячные цели рецензирования"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы CSAT и базовая аналитика"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информационные панели"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты для сравнения агентов, команд, BPO"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями и полномочиями пользователей"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я хочу внести изменения в свою подписку на Zendesk QA.\n\nВыделите или укажите один вариант и укажите количество мест, которое необходимо изменить:\nЯ хочу [добавить места (X)] [удалить места (X)] [перейти на тариф Zendesk QA ]\n\nСообщите название вашей компании и имя, связанное с вашим аккаунтом Zendesk QA , а также любые другие сведения, необходимые для обработки этого изменения."])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение подписки на Zendesk QA"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я хочу приобрести подписку на Zendesk QA.\n\nСообщите название вашей компании и имя, связанное с вашим аккаунтом Zendesk QA , а также любые другие сведения, необходимые для обработки этого запроса.\n\nТакже укажите вопросы, на которые вы хотели бы получить ответы перед покупкой.\n\nСпасибо!"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приобретение подписки на Zendesk QA"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваша подписка заканчивается ", _interpolate(_named("date")), " (осталось ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваше ознакомление заканчивается ", _interpolate(_named("date")), " (осталось: ", _interpolate(_named("days")), ")"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 день"]), _normalize([_interpolate(_named("n")), " дн."])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("dayOfMonth")), "-го числа каждого месяца."])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Счет выставляется на ", _interpolate(_named("card")), " (срок действия истекает ", _interpolate(_named("expires")), ") ", _interpolate(_named("billingDate")), "."])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оканчивается на"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Срок истек"]), _normalize(["Срок истекает через 1 день"]), _normalize(["Срок истекает через ", _interpolate(_named("expiresIn")), " дн."])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Начальная цена включает такое количество пользовательских лицензий: ", _interpolate(_named("n"))])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["начиная с ", _interpolate(_named("sum")), " в месяц"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за пользователя в месяц"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в год"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квартал"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удалить запланированную отмену"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если вы хотите и дальше использовать Zendesk QA, ", _interpolate(_named("link"))])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваша подписка отменена ", _interpolate(_named("date")), "."])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Срок действия вашей подписки истекает ", _interpolate(_named("date")), "."])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "-дневное ознакомление без дополнительной платы!"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["используется"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["У вас такое количество пользователей: ", _interpolate(_named("n")), ". Количество мест не может быть меньше."])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подписка будет возобновлена ", _interpolate(_named("time"))])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["год"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ваш текущий тариф Zendesk QA (", _interpolate(_named("plan")), ") не включает функции на базе ИИ. Чтобы использовать все возможности функций интеллектуальной голосовой связи, ", _interpolate(_named("link")), "."])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Чтобы отменить подписку, свяжитесь с нами ", _interpolate(_named("link")), " или начните чат."])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["План управления качеством"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление качеством"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка обновлена"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить кредитную карту"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все тарифы"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выставление счетов"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть информацию о выставлении счетов"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал выставления счетов"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить подписку"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша подписка будет отменена в конце периода выставления счетов."])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами, чтобы изменить"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий тариф"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные цены"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понизить уровень"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в год (экономия 15%)"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в год"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в месяц"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата раз в год"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в месяц"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить подписку"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть тарифы"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатное ознакомление"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет выставлен вручную"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На следующем шаге вы сможете проверить и подтвердить окончательную сводку заказа."])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самый популярный"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет активного способа оплаты."])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На ознакомлении"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возобновите текущую подписку, чтобы сменить тариф"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановить подписку"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в месяц"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в квартал"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в год"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно активировать подписку"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возобновить подписку"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Места"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать ознакомление сейчас"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без продления"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановлено"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус подписки"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомление"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысить план"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысить уровень подписки"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделитесь своим отзывом о работе службы поддержки клиентов. Это не займет много времени. Спасибо! Ваш отзыв очень важен для ", "{", "{", "agentName", "}", "}", "!"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здравствуйте, ", "{", "{", "customerName", "}", "}", " 👋!"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Опрос CSAT пользователя ", _interpolate(_list(0))])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как бы вы оценили поддержку, полученную от ", "{", "{", "agentName", "}", "}", "?"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Это очень важно для нас. Мы учтем ваш отзыв, чтобы сделать ", _interpolate(_list(0)), " лучше."])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее об опросах"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип опроса"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Его можно будет изменить позже."])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измеряйте и повышайте удовлетворенность клиентов с помощью автоматических опросов CSAT на разных языках."])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет созданных опросов"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать опрос"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название опроса"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос создан"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Попробуйте изменить ключевое слово или ", _interpolate(_named("contact_us")), ", если хотите, чтобы он отображался здесь"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сообщите нам"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки не найдены"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выберите один или несколько языков в списке ниже. ", _interpolate(_named("contact_us")), ", если здесь нет нужного языка."])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить языки"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщите нам"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить язык"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Последняя публикация: ", _interpolate(_list(0))])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Последнее сохранение: ", _interpolate(_list(0))])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вопрос ", _interpolate(_named("type"))])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вводный вопрос"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка левой шкалы"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема электронного письма"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причины"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метка правой шкалы"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если в опросе есть недостающие переводы, вместо них будут показаны тексты на базовом языке"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить переводы"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы отсутствуют"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обязательно проверяйте все свои переводы, прежде чем применять их. Внесите изменения в загруженный файл, сохраните его и при необходимости выложите снова. Если токен ", _interpolate(_named("token_example")), " не отображается как тег, он имеет неправильный формат или находится в поле, которое не поддерживает токены."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить переводы"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переведено"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создает краткое описание разговора и показывает предложения для комментариев."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь ИИ в Zendesk QA (на английском языке)"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Созданные типы можно редактировать"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот тип уже существует. Выберите другое имя."])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните специальный тип как вариант, который можно выбрать в раскрывающемся списке «Тип опроса», и сгруппируйте все созданные опросы на информационной панели."])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите слова и нажмите Enter, чтобы сохранить"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить специальные типы"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя типа не может быть пустым"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определить тип"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный тип не сохранен. Нажмите Enter, чтобы сохранить."])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес отправителя"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В какой степени вы согласны с утверждением: «", _interpolate(_named("agentName")), " упростил(а) решение моей проблемы»?"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Насколько легко было получить помощь от ", _interpolate(_named("brandName")), "?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насколько легко было решить вашу проблему сегодня?"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насколько легко было взаимодействовать с нашей командой?"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес политики конфиденциальности (необязательно)"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес условий предоставления услуг (необязательно)"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Как бы вы оценили полученную от ", _interpolate(_named("agentName")), " поддержку?"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насколько хорошо мы обработали этот запрос?"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насколько вы удовлетворены работой представителя службы поддержки?"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Насколько вы удовлетворены обслуживанием, полученным от ", _interpolate(_named("agentName")), " сегодня?"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальный вопрос"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Определите собственный специальный вопрос ", _interpolate(_named("type")), "..."])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите специальный вопрос"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить URL-адреса"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте сообщение электронной почты"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При изменении имени специального типа вопросов оно будет обновлено во всех опросах, в которых используется, но это не повлияет на собранные данные."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить специальные типы"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема электронного письма"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вводный вопрос"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вопрос ", _interpolate(_named("type"))])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый специальный тип"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Токен персонализации"]), _normalize(["Токены персонализации"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Перетащите токен персонализации в поле ниже."]), _normalize(["Перетащите токены персонализации в поля ниже."])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разговор ", _interpolate(_list(0))])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время опросы CSAT можно отправлять только по электронной почте. Мы работаем над тем, чтобы предоставить вам больше возможностей, таких как опросы CSAT для службы поддержки, SMS-опросы и многое другое."])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Синхронизировать или возобновить подключение"]), _normalize(["Синхронизировать или возобновить подключения"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать или возобновить подключение ↗"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Подключение не синхронизировано должным образом"]), _normalize(["Подключения не синхронизированы должным образом."])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["снимите соответствующий флажок ↗"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести аутентификацию сейчас ↗"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте хотя бы одно правило отправки опросов, чтобы активировать подключение"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это подключение отключено в настоящее время. ", _interpolate(_named("link"))])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это подключение скрывает конфиденциальные данные клиента. Чтобы разрешить отправку опросов,\n", _interpolate(_named("link")), " в настройках."])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для этого подключения требуются дополнительные разрешения на отправку опросов.\n", _interpolate(_named("link"))])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести аутентификацию"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA требует больше разрешений для Intercom."])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("hours")), " и ", _interpolate(_named("minutes")), ")"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 час"]), _normalize([_interpolate(_named("n")), " ч."])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " мин."]), _normalize(["1 минута"]), _normalize([_interpolate(_named("n")), " мин."])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["час"]), _normalize(["ч."])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link")), ", чтобы настроить правила отправки опросов."])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize([" день"]), _normalize(["дн."])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия ссылок на опросы заканчивается"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия ссылок заканчивается через"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрещать отвечать на опросы, если прошло слишком много времени"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["опрос ", _interpolate(_named("name")), " ↗"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите теги через запятую или нажмите Enter после ввода. Убедитесь, что каждый из них указан точно так же, как в Intercom."])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте условия для отправки опроса."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила отправки опросов"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила дополнительной отправки опросов"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опрос ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " уже существует.\nУстраните конфликт, изменив правила здесь или в ", _interpolate(_named("link")), "."])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уже настроили подключение с соответствующими правилами для опроса ", _interpolate(_named("name")), ". Устраните конфликт, изменив правила здесь или в ", _interpolate(_named("link"))])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другое правило"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бренд"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать условие"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искать значение"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить тег"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соответствует тем же правилам"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите значение"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо отправлено через"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительное письмо отправлено через"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встроенный сниппет и дополнительная электронная почта"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать код"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепляйте опросы Zendesk QA к своим письмам, добавляя код сниппета в настройки Zendesk."])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руководство по установке"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть Zendesk"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр сниппета"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код сниппета скопирован в буфер обмена"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось скопировать сниппет"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встроенный сниппет"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом опросе есть несохраненные изменения. Сохраните его снова, чтобы в коде сниппета отображались последние изменения."])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить подключение"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения еще не добавлены"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещайте почту, онлайн-чат, телефонные звонки и многое другое в Zendesk QA с помощью подключений"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о доставке опроса"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила отправки опросов"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила дополнительной отправки опросов"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить правило бренда"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить правило"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести аутентификацию"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger + дополнительная электронная почта"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите свой аккаунт Intercom к опросу CSAT в Zendesk QA"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставить через"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто и когда получает опрос"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить правила"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы в приложении позволяют вашим клиентам заполнять опросы, не покидая чата."])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запустить Intercom"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила доставки для подключения"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опрос ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " уже существует.\nУстраните конфликт, отключив соединение или изменив правила здесь или в ", _interpolate(_named("link"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставка"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте условия для отправки опроса"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автор: ", _interpolate(_named("name")), ", создано ", _interpolate(_named("createdDate"))])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить ", _interpolate(_named("languageName"))])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После удаления этот язык больше не будет отображаться для респондентов, а все переведенные тексты будут удалены."])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить язык"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие нельзя будет отменить."])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроме того, можно отключить язык, скрыв его в опросе. Это позволит сохранить все ваши переводы, не показывая их респондентам."])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Последнее изменение внесено пользователем ", _interpolate(_named("name")), ", ", _interpolate(_named("createdDate"))])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о языках опросов"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Прежде чем добавлять другие языки, убедитесь, что вы завершили внесение изменений в шагах «Оформление» и «Контент». ", _interpolate(_named("link"))])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить язык"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля переведены"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Респонденты могут просматривать"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файл перевода"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить язык"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" загрузить файл перевода"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте переводы"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перевод завершен. ", _interpolate(_named("review_link")), " или ", _interpolate(_named("download_link")), ", чтобы внести изменения."])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" загрузите файл перевода"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте, чего не хватает"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Некоторый контент еще не переведен и будет заменен на текстом на английском языке. ", _interpolate(_named("review_link")), " или ", _interpolate(_named("download_link")), ", чтобы добавить отсутствующие переводы."])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните опрос"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опрос содержит несохраненные изменения. ", _interpolate(_named("save")), ", чтобы загрузить файл перевода с последними изменениями."])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выложить файл перевода"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите предпочтительную шкалу оценивания"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дает клиентам раскрывающийся список для выбора причины оценки"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смайлики"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст на шкале слева (необязательно)"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить причину"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина не может быть пустой"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта причина уже существует"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать раскрывающийся список причин для оценки"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При удалении причины и повторном добавлении с тем же именем или при переименовании причины всегда создается новая запись. В результате это влияет на данные, отображаемые на вашей информационной панели."])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить причину"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина удаления остается в отчетах на информационной панели, но не будет видна новым респондентам опроса. Это также относится к связанным переводам."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалите причину"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Удалить причину «", _interpolate(_list(0)), "»"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст на шкале справа (необязательно)"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По 5-балльной шкале 1 и 2 считаются отрицательными оценками; 3 — нейтральной; 4–5 — положительными"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить текст шкалы"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Шкала ", _interpolate(_named("type"))])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкала оценивания"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendesk QA импортирует ваши разговоры. Этот процесс может занять до 24 часов."])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будет использоваться как токен персонализации на протяжении всего опроса."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бренда"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кнопки и ссылки"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка внешнего вида опроса"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фон логотипа"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаются форматы JPG, PNG и GIF"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформление"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выложить логотип"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись со стилем"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите сообщение, которое пользователи будут видеть после отправки отзыва"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение благодарности"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный шестнадцатеричный код цвета"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный адрес электронной почты"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле не может быть пустым"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный URL-адрес"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отображается:"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображается:"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправленные опросы"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы, отправленные за последние 30 дней"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос удален"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос скопирован"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо для предварительного просмотра отправлено"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос опубликован"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос обновлен"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос приостановлен"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановить"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановить опрос"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты для отправки предварительного просмотра опроса"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ваша", "@", "почта.com"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык для предварительного просмотра"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправьте письмо для предварительного просмотра"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправьте образец опроса на свою почту. Оценки из предварительного просмотра опроса не будут сохранены."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Предварительный просмотр опроса ", _interpolate(_named("type"))])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр опроса по почте в веб-браузере"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возобновить"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как черновик"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бренда"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название бренда можно добавить на шаге «Оформление»"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя клиента"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support ID"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представитель службы поддержки"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалено"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановлено"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Опрос CSAT пользователя ", _interpolate(_list(0))])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесены несохраненные изменения"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить и возобновить опрос"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если это письмо отображается неправильно, ", _interpolate(_named("link"))])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мне не понравилась полученная поддержка, потому что..."])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На базе технологии Zendesk QA"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резюме разговора"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример: У клиента возникли проблемы при обновлении способа оплаты. Агент поделился статьей, которая может помочь. Это сработало."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На базе технологии ИИ Zendesk QA"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия предоставления услуг"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за ваш отзыв!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какова основная причина такой оценки?"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расскажите, почему вы поставили такую оценку..."])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значительные усилия"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальные усилия"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щелкните здесь, чтобы открыть опрос"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь отображается ваш специальный вопрос"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вы оценили свой опыт ", _interpolate(_list(0)), " в"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить отзыв"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отписаться"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очень не понравилось"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очень понравилось"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратите внимание, что текущий статус опроса и правила доставки не будут включены в дубликат опроса"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Дублировать опрос «", _interpolate(_list(0)), "»"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы используете встроенный сниппет электронной почты, обязательно удалите его из Zendesk, прежде чем приостанавливать опрос"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда опрос приостановлен, ответы не собираются."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приостановить опрос"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде чем приостанавливать опрос, обязательно удалите сниппет из Zendesk."])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить опрос"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы используете встроенный сниппет почты, обязательно удалите его из Zendesk, прежде чем удалять опрос."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос будет необратимо удален. Пользователи, получившие опрос, смогут заполнить его, и все данные, связанные с опросом, останутся в Zendesk QA."])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить опрос ", _interpolate(_named("surveyName"))])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед удалением опроса обязательно удалите сниппет из Zendesk."])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесенные вами изменения будут видны новым респондентам."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать опрос"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательно скопируйте последнюю версию встроенного сниппета после публикации опроса."])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы используете встроенный сниппет электронной почты, обязательно скопируйте его в Zendesk после возобновления опроса."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжайте отправку опроса и сбор ответов."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить и возобновить опрос"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После возобновления опроса обязательно добавьте встроенный сниппет в Zendesk."])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить опрос"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После обновления опроса обязательно скопируйте последнюю версию встроенного сниппета."])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно удалить этот опрос? Это действие нельзя будет отменить."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить опрос?"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ознакомление закончилось, но вы можете продолжать пользоваться функциями Zendesk QA, перейдя на аккаунт с полной подпиской."])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ознакомление закончилось, но вы можете продолжать пользоваться функциями Zendesk QA, перейдя на аккаунт с полной подпиской. Обратитесь к своему локальному администратору."])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомление закончилось"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти на новый план"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатное ознакомление закончилось."])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы продолжить использование полной версии Zendesk QA, введите свои платежные данные."])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы продолжить использование полной версии Zendesk QA администратор вашего аккаунта должен ввести платежные данные."])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление оплатой"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.klausapp.com/en/articles/4943707-assignments"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пользователь"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата создания ", _interpolate(_named("date")), ", автор: ", _interpolate(_named("authorName"))])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " — назначение"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, вы еще работаете над этим. Если закрыть страницу без сохранения изменений, вся добавленная информация будет утрачена."])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это назначение будет повторяться таким образом: ", _interpolate(_named("weekDay"))])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Это назначение будет повторяться таким образом: ", _interpolate(_named("weekDay"))])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Все рецензируемые охвачены в одном цикле"]), _normalize(["Все рецензируемые охвачены в таком количестве циклов: ", _interpolate(_named("nr"))])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Количество разговоров: ", _interpolate(_named("n"))]), _normalize([_interpolate(_named("n")), " разговор"]), _normalize(["Количество разговоров: ", _interpolate(_named("n"))])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["из ", _interpolate(_named("nr")), " агентов"]), _normalize(["из ", _interpolate(_named("nr")), " агента"]), _normalize(["из такого количества агентов: ", _interpolate(_named("nr"))])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["участник"]), _normalize(["участники"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " разговоров за цикл"]), _normalize([_interpolate(_named("n")), " разговор за цикл"]), _normalize(["Такое количество разговоров за цикл: ", _interpolate(_named("n"))])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reviewsCount")), " для каждого рецензента подряд"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["рецензируемый"]), _normalize(["рецензируемые"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["рецензент"]), _normalize(["рецензенты"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемые"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи рабочего пространства"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи рабочего пространства, кроме агентов"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цикл ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), "–", _interpolate(_named("endDate"))])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "–", _interpolate(_named("endDate"))])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не повторяется"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " — назначение"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется рецензирование"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о назначениях"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать назначение"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение и связанные с ним данные будут необратимо удалены с информационной панели. Все связанные с ним отзывы останутся в Zendesk QA."])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить назначение ", _interpolate(_named("assignmentName"))])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение удалено"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение дублировано"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения еще не добавлены"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать назначение"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название назначения"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цикл обновляется и создается новый список задач. Уже оставленные рецензии тоже засчитываются в цель."])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немедленно"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В начале следующего цикла ", _interpolate(_named("startDate"))])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда публиковать изменения?"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения, внесенные в это назначение, будут опубликованы вместе с этим назначением"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать назначение активным?"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторение в день начала назначения"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первая пятница каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторая пятница каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третья пятница каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертая пятница каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя пятница каждого месяца"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый понедельник каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй понедельник каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третий понедельник каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертый понедельник каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний понедельник каждого месяца"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первая суббота каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторая суббота каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третья суббота каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертая суббота каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя суббота каждого месяца"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первое воскресенье каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второе воскресенье каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третье воскресенье каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертое воскресенье каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее воскресенье каждого месяца"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый четверг каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй четверг каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третий четверг каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертый четверг каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний четверг каждого месяца"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый вторник каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй вторник каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третий вторник каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертый вторник каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний вторник каждого месяца"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первая среда каждого месяца"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторая среда каждого месяца"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третья среда каждого месяца"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертая среда каждого месяца"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя среда каждого месяца"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настраиваемое повторение"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый день каждого месяца"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Третий"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвертый"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторяется"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочий день"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет результатов"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовой пояс"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки цикла"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название назначения"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В начале каждого цикла назначения создается заранее заданный список разговоров для оценки."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные настройки"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически извлекать новые разговоры, если цель рецензии не достигнута"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое извлечение невозможно с процентными (%) целями рецензий"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должно быть целым числом, а не десятичным"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить замену переписок (в роли рецензента и выше)"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замена разговоров"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить рецензентам назначать собственные переписки для рецензирования"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензии"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальное количество рецензий: 1"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите минимальное количество рецензий для каждого рецензируемого"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разговоры"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["процент (%)"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рецензии"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всего рецензий"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый рецензент должен выполнить заданное количество рецензий"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты должны коллективно проверить заданное количество разговоров"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальное количество рецензий: 1"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель рецензии должна быть в диапазоне от 1 до 100%"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель рецензирования"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для каждого рецензируемого должно быть рецензировано минимальное количество разговоров из общего числа"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты могут выбирать разговоры из общего пула"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензентам назначаются разговоры из общего пула"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подход к назначению"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все боты"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все участники рабочего пространства"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи рабочего пространства"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить рецензируемых"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кого будут рецензировать?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать рецензируемых"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите, кого проверять"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключения разрешены, только если выбраны все пользователи рабочего пространства или группа"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты и рецензируемые"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Текущее: ", _interpolate(_list(0))])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство по умолчанию"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователей какого типа следует выбирать в качестве рецензируемых?"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самый активный агент"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить рецензируемых (необязательно)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей, которых следует исключить"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить рецензентов (необязательно)"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей, которых следует исключить"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи рабочего пространства, кроме агентов"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить рецензентов"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто будет делать рецензии?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать рецензентов"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии в этом рабочем пространстве не разрешены"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить саморецензии"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии в этом рабочем пространстве не разрешены."])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Подходящий разговор сегодня"]), _normalize(["Подходящие разговоры сегодня"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображается, будто назначение началось сегодня."])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущие цифры могут отличаться."])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемые не выбраны"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты не выбраны"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Рецензируемый"]), _normalize(["Рецензируемые"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Рецензент"]), _normalize(["Рецензенты"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое извлечение: разрешено, если недостаточно переписок"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по пятницам с ", _interpolate(_named("startDateTime"))])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по понедельникам с ", _interpolate(_named("startDateTime"))])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по субботам с ", _interpolate(_named("startDateTime"))])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по воскресеньям с ", _interpolate(_named("startDateTime"))])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по четвергам с ", _interpolate(_named("startDateTime"))])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по вторникам с ", _interpolate(_named("startDateTime"))])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cycleLength")), " по средам с ", _interpolate(_named("startDateTime"))])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ежедневно с ", _interpolate(_named("startDateTime"))])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Начинается ", _interpolate(_named("startDateTime")), " и заканчивается ", _interpolate(_named("endDateTime")), "."])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Количество условий даты: ", _interpolate(_named("count"))])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Количество условий для разговора и службы поддержки: ", _interpolate(_named("count"))])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Нет разговоров и ", _interpolate(_named("count")), " условие службы поддержки"]), _normalize(["Нет разговоров и такое количество условий службы поддержки: ", _interpolate(_named("count"))])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " разговор и нет условий службы поддержки"]), _normalize(["Нет условий службы поддержки и такое количество разговоров: ", _interpolate(_named("count"))])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Подход: рецензенты выбирают разговоры"]), _normalize(["Подход: рецензентам назначаются разговоры"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исправьте ошибки, выделенные в сводке, чтобы продолжить"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель: рецензенты должны проверить все разговоры"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Цель: рецензенты должны проверить один разговор"]), _normalize(["Цель: рецензенты должны проверить такое количество разговоров: ", _interpolate(_named("count"))])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цель: рецензенты должны проверить такое количество разговоров: ", _interpolate(_named("count")), "%"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель: цель рецензирования не задана"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель: проверить все разговоры каждого рецензируемого"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цель: каждый рецензируемый должен иметь такое количество рецензированных разговоров от общего количества: ", _interpolate(_named("count"))])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цель: каждый рецензируемый должен иметь такое количество рецензированных разговоров от общего количества: ", _interpolate(_named("count")), "%"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Цель: каждый рецензент должен сделать одну рецензию"]), _normalize(["Цель: каждый рецензент должен сделать такое количество рецензий: ", _interpolate(_named("count"))])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Замена: рецензенты не могут заменять разговоры"]), _normalize(["Замена: рецензенты могут заменять разговоры"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саморецензии: рецензентам можно назначать собственные разговоры"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия даты не заданы"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название назначения не задано"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сводка"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("weekday")), " из ", _interpolate(_named("dateTime"))])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в две недели"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в день"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в месяц"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никогда"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раз в неделю"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность цикла"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить как активный"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как черновик"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение сохранено как черновик"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение задано как неактивное"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать как неактивное"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать назначение"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение создано"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как активное"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как неактивное"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ожидающие обновления назначения вступят в силу в следующем цикле, начиная с ", _interpolate(_list(0)), "."])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать изменения"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить назначение"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение обновлено"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, завершил ли агент или бот разговор."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, вежливо ли агент завершил разговор"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, завершен ли разговор"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует грамматические, орфографические и стилистические ошибки при взаимодействии как агентов, так и ботов."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует грамматические, орфографические и стилистические ошибки агентов"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует грамматические, орфографические и стилистические ошибки агентов"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орфография и грамматика"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует разговор на предмет приветствий агента и бота."])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие типичных приветственных фраз"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие типичных приветствий"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветствие"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, понимает ли агент чувства клиентов и признает ли их"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выявляет случаи, когда агент отражал тональность клиента или использовал слова заботы, такие как «подтверждение» и «признание»"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сочувствие"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, понимает ли агент запрос или проблему клиента"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, понимает ли агент запрос или проблему клиента"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понимание"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, насколько легко понять текст, учитывая сложность слов и длину предложений"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует, насколько легко понять текст, учитывая сложность слов и длину предложений"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это сообщение содержит 1 слишком длинное предложение, затрудняющее чтение"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Это сообщение содержит несколько слишком длинных предложений (", _interpolate(_list(0)), "), затрудняющих чтение"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читаемость"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие предлагаемого решения"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует весь разговор на наличие предлагаемого решения"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложенное решение"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует тон агента на протяжении всего разговора"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализирует тон агента на протяжении всего разговора"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обнаружить ошибки в сообщениях агента"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить грамматические ошибки"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветственная фраза не обнаружена. При необходимости добавьте новые в меню администратора на странице настроек."])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент вежливо завершил разговор"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрывающая фраза не обнаружена. При необходимости добавьте новые в меню администратора на странице настроек"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент отражал тональность клиента или использовал слова заботы"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить, отражал ли агент тональность клиента или использовал ли слова заботы"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент использовал длинные предложения и сложные слова, что может затруднить понимание"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент использовал сложные слова, что может затруднить понимание"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент использовал длинные предложения, что может затруднить понимание"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент использовал понятный язык"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно данных для оценки этой категории"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент не смог понять вопрос/запрос клиента"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент хорошо понял вопрос/запрос клиента"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить, понял ли агент вопрос или запрос клиента"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон агента во время разговора"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон агента во время разговора"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон агента во время разговора"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон агента во время разговора"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тон агента во время разговора"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить тон агента"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор не был завершен"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор был завершен"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить, был ли завершен разговор"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенту не хватало сочувствия клиенту и его проблемам"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент с сочувствием относится к клиенту и его проблемам"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить, проявлял ли агент сочувствие к клиенту и его проблемам "])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время разговора с клиентом не прозвучало приветствие"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Во время разговора с клиентом прозвучало приветствие"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обнаружить типичное приветствие"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент не предложил решение клиенту"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агент предложил решение клиенту"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось определить, предложил ли агент решение клиенту"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложные слова"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длинные предложения"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грамматика"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опечатка"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательный"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извиняющийся"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спокойный"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Веселый"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любопытный"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профессиональный"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживающий"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стиль"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 закрытие"]), _normalize(["Количество закрытий: ", _interpolate(_named("n"))])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA проверяет только утвержденные закрытия. Если эта категория задана как критическая, рецензия получит оценку «Не пройдено» без использования утвержденных закрытий."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные закрытия"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные закрытия"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 исключение"]), _normalize(["Количество исключений: ", _interpolate(_named("n"))])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слова и фразы, которые AutoQA игнорирует как орфографические или грамматические ошибки."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключения из правил орфографии и грамматики"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключения AutoQA"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 приветствие"]), _normalize(["Количество приветствий: ", _interpolate(_named("n"))])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA проверяет только утвержденные приветствия. Если эта категория задана как критическая, рецензия получит оценку «Не пройдено» без использования утвержденных приветствий."])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные приветствия"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утвержденные приветствия"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория AutoQA"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию AutoQA"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически назначенные основные причины:"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Аккаунт» → «Настройки»"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В некоторых категориях используется MS Azure, обеспечивая расширенный набор функций и поддерживаемых языков. Перейдите в раздел ", _interpolate(_named("link")), ", чтобы получить дополнительную информацию, или обратитесь к администратору."])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Используйте MS Azure, чтобы получить доступ к расширенному списку категорий и поддерживаемых языков. Перейдите в раздел ", _interpolate(_named("link")), " или обратитесь к администратору."])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать AutoQA"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Категории AutoQA получают оценку автоматически по фиксированной шкале оценивания. Позднее оценку можно будет скорректировать вручную. ", _interpolate(_named("link"))])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию AutoQA"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматически назначенная оценка зависит от серьезности ошибок и их количества. ", _interpolate(_named("link"))])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные причины"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензентам видны только подходящие категории оценивания. Категории оценивания отображаются на основе условий, заданных вами при создании категории."])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чат"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адаптация"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефон"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выберите тип категории оценивания для рабочего пространства «", _interpolate(_named("workspace")), "»."])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты должны вручную выбрать правильную карту оценок, чтобы увидеть соответствующие категории оценивания для каждого разговора."])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно изменить тип категории оценивания?"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить категории"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить тип категории оценивания?"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условная категория оценивания"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статическая категория оценивания"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип категории оценивания"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки категории оценивания"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие нельзя будет отменить."])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удаление всех оценок"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроме того, вы можете сохранить статистику без удаления данных, поместив категорию в архив."])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Я понимаю, что это обеспечит ", _interpolate(_named("deleteAllRatings")), " для этой категории."])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, идеальный тон"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать категорию, только если"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вариант «Всегда» игнорируется. Его нельзя сочетать с другими условиями."])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать условие"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка качества (CSAT)"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип источника"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал разговора"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для категории требуется имя"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте категорию в карту оценок"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать категорию"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать категорию"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания добавлена"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивная категория не отображается в карте оценок, но позволяет сохранить статистику, не удаляя никаких данных."])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать категорию"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Архивировать категорию ", _interpolate(_list(0))])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания помещена в архив"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить категорию"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите «удалить категорию», чтобы удалить эту категорию"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["удалить категорию"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Удалить категорию ", _interpolate(_list(0))])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания удалена"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания дублируется"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлена группа категорий оценивания"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа категорий оценивания удалена"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок групп категорий оценивания изменен"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа категорий оценивания обновлена"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания разархивирована"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория оценивания обновлена"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если агент совершает ошибку в критической категории, все категории в этой проверке автоматически получают оценку «Не пройдено»."])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критическая категория оценивания"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этой группе нет категорий"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую категорию оценивания"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить категорию"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все категории и проверить данные в этой группе"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При удалении группы категорий все связанные с ней категории останутся вне группы."])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить группу категорий ", _interpolate(_named("categoryName"))])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление категорий в группе приведет к потере всех данных рецензий, связанных с этими категориями."])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты могут выбирать несколько причин в качестве основных"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты могут выбирать несколько причин в качестве основных"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несколько причин"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["например, тон"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую группу категорий"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая группа"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить «Другое»"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить основную причину"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта основная причина уже существует"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить основную причину"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте основные причины для объяснения оценки"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое..."])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шкала оценивания"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В архиве"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить карты оценок в эту категорию оценивания"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивировать"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить категорию"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все оценки"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показано для"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательные оценки"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отрицательные и нейтральные оценки"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да, обновить"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значимость"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более важные для вас категории могут иметь большую значимость при расчете оценки тикета."])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Значимость (", _interpolate(_list(0)), ")"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to account"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view and edit your scorecards in the <strong>Account</strong> section."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scorecards are now managed in your account"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о калибровке"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о калибровке"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регулярные сеансы калибровки позволяют рецензентам согласовывать свои методы оценивания и обеспечивают получение агентами одинакового уровня последовательных и объективных отзывов."])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть тарифы"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " — это процесс, который поможет вашей команде понять, как следует обрабатывать и оценивать все взаимодействия с клиентами."])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["профессиональный"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Калибровка доступна для тарифов ", _interpolate(_list(0)), " и ", _interpolate(_list(1)), "."])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При добавлении разговора в сеанс ранее оставленные рецензии не отображаются."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отображается"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранее оставленные рецензии считаются рецензиями калибровки. В сеанс добавляется только одна рецензия разговора для каждого рецензента. Рецензии отдельных сообщений не отображаются."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость ранее оставленных рецензий"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Калибровка — это процесс, который помогает вашей команде понять, как следует обрабатывать и оценивать все взаимодействия с клиентами. ", _interpolate(_named("link"))])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи имеют неограниченный доступ ко всем рецензиям, созданным во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда видеть все рецензии"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лид"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После отправки рецензии пользователи увидят оценки, поставленные другими рецензентами во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все рецензии калибровки после отправки рецензии"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи имеют неограниченный доступ ко всем рецензиям, созданным во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда видеть все рецензии"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После отправки рецензии пользователи увидят оценки, поставленные другими рецензентами во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все рецензии калибровки после отправки рецензии"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После отправки рецензии пользователи увидят оценки, поставленные другими рецензентами во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все рецензии калибровки после отправки рецензии"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи будут видеть только свои рецензии, сделанные во время сеанса калибровки."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть только свои рецензии"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки калибровки обновлены"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость рецензий калибровки"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте пороговое значение для метрик информационной панели, заполнив пробелы в соответствии с внутренней системой метрик вашей команды. Проверьте пороговое значение на информационной панели."])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калибровка"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключения"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштеги"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пороговое значение"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пороговое значение обновлено"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите немного..."])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Убедитесь, что у вас есть разрешения администратора и ", _interpolate(_named("retry")), ", или ", _interpolate(_named("contact")), " для получения дополнительной помощи."])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["свяжитесь с нами"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, нам не удалось получить доступ к API вашей службы поддержки."])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повторите попытку"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется подключение вашей службы поддержки. Вы будете мгновенно перенаправлены."])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " авторизовано"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вернитесь на ", _interpolate(_list(0)), ", чтобы добавить новое подключение к службе поддержки."])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, вы пропустили один или два шага."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация с таким же доменом электронной почты уже существует"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["страницу подключений"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А пока..."])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дайте нам минутку, пока мы проверим ваши разрешения."])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А пока..."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется интеграция"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["создайте рабочее пространство"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Чтобы продолжить использование Zendesk QA, ", _interpolate(_named("link")), "."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почти готово к использованию"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочее пространство не найдено"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить аккаунт"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы продолжить использование Zendesk QA, ваш менеджер должен назначить вам рабочее пространство."])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["При удалении вашего аккаунта все ваши данные будут необратимо удалены — ", _interpolate(_named("bold"))])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["все присвоенные оценки, содержимое разговоров, рабочие пространства и т. д."])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в Slack"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При отключении интеграции все уведомления Zendesk QA через Slack будут отключены"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить Slack"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция Slack отключена от Zendesk QA."])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте настройки уведомлений по умолчанию для всех новых пользователей аккаунта."])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переопределить настройки для всех пользователей"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы собираетесь переопределить настройки уведомлений для всех пользователей аккаунта"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не смогут задавать собственные настройки уведомлений."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений обновлены"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оповещать при ошибках в критических категориях"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оповещать, когда CSAT получает отклик"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггеры"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать демонстрационное рабочее пространство и данные"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановите демонстрационные данные и сохраните демонстрационное рабочее пространство."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить демонстрационное рабочее пространство и данные"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить демонстрационные данные"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить данные"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Управление аккаунтом «", _interpolate(_named("accountName")), "» в демонстрационном режиме"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демонстрационный режим"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747123354"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724530842"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759436954"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747231642"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747649690"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043701093786"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759449114"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043760162074"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759543194"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043724860826"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043668738970"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759440794"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043747142938"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759586074"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/articles/7043759396250"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставаться на странице"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинуть страницу"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покинуть эту страницу и потерять все изменения?"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы выполнили назначение"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать назначение"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение выполнено"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначения не найдены"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас нет задач для рецензирования."])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените назначение или дождитесь начала следующего цикла."])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте настроить фильтры"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить назначение"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следите за обновлениями на этой странице"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет задач для этого цикла"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разговоров для отображения"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить задачу как выполненную"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы создали рецензию для этого пользователя."])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом назначении нет саморецензий"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте, щелкнув значок калибровки в верхнем колонтитуле вида разговоров."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных разговоров"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать назначение"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сеанс калибровки"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши назначения"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие назначения"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активных назначений"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет назначений"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензируемые"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензируемые, кроме меня"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемые не найдены"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск рецензируемого"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалено"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все статусы"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дело"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензенты"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рецензенты, кроме меня"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначено мне"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты не найдены"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск рецензента"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дн."])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дн."])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " день"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дн."])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дн."])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " дн."])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ч"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ч"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " час"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ч"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ч"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ч"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " мин"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " мин"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " минута"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " мин"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " мин"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " мин"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи рабочего пространства"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий рецензент"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая цель для этого цикла"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый рецензент"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите рецензента"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить разговоры"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить разговор"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры, которые следует переместить"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий цикл"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить назначение"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты не найдены"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск рецензента"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить мне разговоры"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить разговоры другому пользователю"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензенты"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " дней"]), _normalize([_interpolate(_named("n")), " день"]), _normalize([_interpolate(_named("n")), " дн."])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " часов"]), _normalize([_interpolate(_named("n")), " час"]), _normalize([_interpolate(_named("n")), " ч."])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть выполненные циклы"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть завершенные сеансы"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша цель"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите причину удаления этого разговора"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объясните, почему вы удаляете этот разговор"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите причину замены этого разговора"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить изменения"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замена не найдена"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объясните, почему вы заменяете этот разговор"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить этот разговор из назначения? Цель будет уменьшена на 1."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить разговор"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить черновик"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить черновик"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить мне"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить другому пользователю"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переназначить и рецензировать"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить разговор"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставьте отзыв"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать меньше"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать больше"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цель: рецензировано ", _interpolate(_named("count")), " из ", _interpolate(_named("goal"))])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовые рецензии"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать выполненные циклы"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать завершенные сеансы"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осталось времени"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор заменен"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть список задач"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create \"", _interpolate(_named("tag")), "\""])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Через ", _interpolate(_named("time"))])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " с."])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " с."])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн."])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн. ", _interpolate(_named("hours")), " ч."])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн. ", _interpolate(_named("hours")), " ч. ", _interpolate(_named("minutes")), " мин."])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн."])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн."])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " дн. ", _interpolate(_named("minutes")), " мин."])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ч."])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), " ч. ", _interpolate(_named("minutes")), " мин."])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "ч. ", _interpolate(_named("minutes")), "мин. ", _interpolate(_named("seconds")), " с."])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "ч. ", _interpolate(_named("seconds")), " с."])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " мин."])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), " мин. ", _interpolate(_named("seconds")), " с."])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " мес."])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), " с."])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " г."])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только что"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет разрешений. Обратитесь за помощью к своему администратору."])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искомая страница не существует."])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла неожиданная ошибка. Обратитесь за помощью в службу поддержки."])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В доступе отказано. Не разрешено выполнять это действие."])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница, которую вы ищете, не существует, или у вас нет к ней доступа."])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы движетесь слишком быстро, и мы не успеваем."])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения сохранены"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесены несохраненные изменения"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Применено: ", _interpolate(_named("n"))]), _normalize(["Применено: ", _interpolate(_named("n"))]), _normalize(["Применено: ", _interpolate(_named("n"))])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключено"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время создания"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кто создал"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критическая"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["равно"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Бывший участник"]), _normalize(["Бывший участник"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["здесь"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выбрано: ", _interpolate(_named("n"))]), _normalize(["Выбрано: ", _interpolate(_named("n"))]), _normalize(["Выбрано: ", _interpolate(_named("n"))])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее обновление"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["... и еще 1"]), _normalize(["... и еще ", _interpolate(_named("n"))])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["еще 1"]), _normalize(["еще ", _interpolate(_named("n"))])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["новинка"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не равно"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалено"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ответов"]), _normalize([_interpolate(_named("n")), " ответ"]), _normalize(["Количество ответов: ", _interpolate(_named("n"))])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs down"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbs up"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disappointed face"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confused face"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly smiling face"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smiling face"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star-struck"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пользователь"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пятница"]), _normalize(["По пятницам"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Понедельник"]), _normalize(["По понедельникам"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Суббота"]), _normalize(["По субботам"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Воскресенье"]), _normalize(["По воскресеньям"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Четверг"]), _normalize(["По четвергам"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Вторник"]), _normalize(["По вторникам"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Среда"]), _normalize(["По средам"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Администратор"]), _normalize(["Администратор"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Аккаунт-менеджер"]), _normalize(["Аккаунт-менеджер"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пользователь"]), _normalize(["Пользователь"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoQA"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок вашего сеанса истек."])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Входы"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле не может быть пустым."])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элементы не найдены."])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет соответствующих людей."])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Владелец"]), _normalize(["Владелец"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее."])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде чем мы начнем, прочитайте и примите наши условия, и мы приступим."])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уверены?"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего выполнено рецензий"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлено"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Агент"]), _normalize(["Агент"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Лид"]), _normalize(["Лид"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Менеджер"]), _normalize(["Менеджер"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Рецензент"]), _normalize(["Рецензент"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимный пользователь"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CES"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите, чтобы скопировать"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSAT"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опасная зона"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание (необязательно)"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется подтверждение"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить несохраненные изменения?"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОК"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никогда"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент прохождения"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личные настройки"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньше"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить отправку"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные/просмотренные рецензии"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии, выполненные участником рабочего пространства"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-бальная шкала"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-бальная шкала"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-бальная шкала"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-бальная шкала"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта оценок"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите частоту"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать меньше"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать больше"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустить"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выделение"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв об опросе"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диапазон времени"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесены несохраненные изменения"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значимость"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Вы приглашены в Zendesk QA пользователем ", _interpolate(_list(0)), "."])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователи автоматически подключаются из Центра администрирования. ", _interpolate(_named("link"))])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее об управлении пользователями"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Некоторые пользователи автоматически подготавливаются и синхронизируются с вашим поставщиком удостоверений. ", _interpolate(_named("link"))])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разрешение для аккаунта «", _interpolate(_list(0)), "» изменено"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о разрешениях пользователей"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше не спрашивать"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Все изменения, внесенные на странице пользователей, отражаются в рабочих пространствах. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link"))])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это означает, что доступ пользователя к определенным частям Zendesk QA может измениться"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить разрешение на уровне аккаунта"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " пользователь удален"]), _normalize(["Удалено пользователей: ", _interpolate(_named("n"))])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["При удалении пользователи потеряют доступ к вашему аккаунту. Их рецензии, комментарии и оценки останутся в Zendesk QA. ", _interpolate(_named("linebreak")), "Удаление пользователей не повлияет на количество мест в вашей подписке."])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Эти пользователи будут удалены изо всех рабочих пространств, которыми вы управляете. ", _interpolate(_named("linebreak")), " Их рецензии, комментарии и оценки останутся без изменений."]), _normalize(["Они будут удалены изо всех рабочих пространств, которыми вы управляете. ", _interpolate(_named("linebreak")), " Все их рецензии, комментарии и оценки останутся без изменений."])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Удалить ", _interpolate(_named("name"))]), _normalize(["Удалить такое количество пользователей: ", _interpolate(_named("name"))])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может видеть все и управлять всеми настройками и выставлением счетов"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может видеть все и управлять личными настройками, а также настройками рабочего пространства, кроме выставления счетов"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения аккаунта"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения для конкретных рабочих пространств позволяют пользователю просматривать (и) редактировать данные в рабочих пространствах, в которых они находятся."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о Бот QA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши боты отображаются тут, так как разговоры, в которых они участвовали, синхронизированы"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты не синхронизированы"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если пометить бот как пользователя, ему будет предоставлен доступ для входа. Данные бота за прошлые периоды останутся доступными на информационных панелях."])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такие боты не найдены"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте выполнить поиск снова, используя другое ключевое слово или другой вариант написания."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск ботов"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени бота"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как пользователя"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только администраторы и аккаунт-менеджеры могут помечать ботов как пользователей"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя бота"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний чат"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помечено вручную"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензируемый"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот будет удален из автоматического анализа и всех назначений. Новые данные не будут отображаться на информационных панелях, что обеспечит точность отчетов и упростит управление ботами."])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеративный бот"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестно"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бот для рабочих процессов"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в группы"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет группы"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить из групп"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите группу"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение для аккаунта"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в рабочие пространства"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное имя"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя активность"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль на уровне аккаунта"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждому пользователю назначается разрешение на уровне аккаунта и/или рабочего пространства. Разрешения администратора теперь находятся на уровне аккаунта, а другие разрешения — на уровне рабочего пространства."])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить из рабочих пространств"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение для рабочего пространства"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения, которыми управляют в группе"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить сведения"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пометить как бота"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если пометить пользователя как бота, он потеряет доступ ко входу. Данные пользователя за прошлые периоды останутся доступными на информационных панелях."])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалите бота из автоматического анализа и всех назначений. Новые данные не будут отображаться на информационных панелях, что обеспечит точность отчетов и упростит управление ботами."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить бот из рецензий"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь помечен как бот"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только пользователь типа разрешений может быть помечен как бот"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователей с несколькими аккаунтами нельзя помечать как ботов"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по пользователю"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боты"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи, боты и рабочие пространства"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАБОЧИЕ ПРОСТРАНСТВА"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи и рабочие пространства"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет разрешений"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключено"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлено вручную"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидается приглашение"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи SCIM"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется действие"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["...и еще один"]), _normalize(["...и еще ", _interpolate(_named("n"))])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить разрешение на"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Удалить и снова пригласить, чтобы изменить роль ", _interpolate(_named("n")), " пользователя, ожидающего приглашения."]), _normalize(["Удалить и снова пригласить, чтобы изменить роли такого количества пользователей, ожидающих приглашения: ", _interpolate(_named("n"))])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Изменить сведения для ", _interpolate(_named("name"))]), _normalize(["Изменить сведения для такого количества выбранных пользователей: ", _interpolate(_named("n"))])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " пользователь обновлен."]), _normalize(["Обновлено такое количество пользователей: ", _interpolate(_named("n")), "."])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Эта группа содержит 1 пользователя из другого рабочего пространства."]), _normalize(["Эта группа содержит такое количество пользователей из другого рабочего пространства: ", _interpolate(_named("n")), "."])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» помещена в архив"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа в архиве"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» создана"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа без названия"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончательно удалить эту группу?"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить группу"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лид группы"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль в группе"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» скрыта"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта группа не будет отображаться больше нигде в Zendesk QA"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление разрешениями только для рабочего пространства"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа не отображается в Zendesk QA"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лид"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участник"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа без названия"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это название уже есть у другой группы"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это название уже есть у группы в архиве"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша роль позволяет управлять только членами этой группы. Чтобы внести изменения, обратитесь к пользователю с более высокими разрешениями."])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить как лида группы"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» восстановлена"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» сохранена"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задать как лида группы"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» разархивирована"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несохраненные изменения"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте существующих пользователей в эту группу"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени или адресу электронной почты"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить участников"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Группа «", _interpolate(_named("name")), "» отображается"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Каждый участник является ", _interpolate(_named("workspace_role"))])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о группах"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некоторые атрибуты групп автоматически подготавливаются и синхронизируются с вашим поставщиком удостоверений."])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее о группах"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко упорядочивайте членов команды и имитируйте организационную структуру."])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте хотя бы 1 участника"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте название группы"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа с таким названием уже существует"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники группы"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упорядочивайте членов команды и имитируйте организационную структуру."])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Нет групп"]), _normalize(["1 группа"]), _normalize(["Количество групп: ", _interpolate(_named("count"))])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте новую группу или активируйте группу из архива."])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных активных групп"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет групп в архиве"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такие группы не найдены"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте выполнить поиск снова, используя другое ключевое слово или другой вариант написания."])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет добавленных групп"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["в этих"]), _normalize(["в"]), _normalize(["в"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по названию группы"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В архиве"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Время возможной повторной отправки: ", _interpolate(_list(0))])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Осталось ", _interpolate(_named("n")), " мест"]), _normalize(["Осталось ", _interpolate(_named("n")), " место"]), _normalize(["Осталось мест: ", _interpolate(_named("n"))])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество мест сейчас ограничено, так как ваша подписка приостановлена. Повторно активируйте ее, чтобы добавить других пользователей."])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто предельное количество мест для вашего аккаунта. Пользователей можно заменить, деактивировав активного пользователя или добавив больше мест."])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть платежные данные"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выбрано: ", _interpolate(_named("n"))]), _normalize(["Выбрано: ", _interpolate(_named("n"))])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выбран ", _interpolate(_named("n")), " пользователь"]), _normalize(["Выбрано пользователей: ", _interpolate(_named("n"))])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " строк"]), _normalize([_interpolate(_named("n")), " строка"]), _normalize(["Количество строк: ", _interpolate(_named("n"))])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовый поиск пользователей по именам или адресам электронной почты."])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте сюда данные через запятую или через новую строку"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовый поиск пользователей"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Изменить разрешения для рабочего пространства для ", _interpolate(_named("name"))]), _normalize(["Изменить разрешения для рабочего пространства для такого количества выбранных участников: ", _interpolate(_named("n"))])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения на редактирование"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите разрешение для рабочего пространства"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Удалить ", _interpolate(_named("name")), " из рабочего пространства"]), _normalize(["Удалить участников (", _interpolate(_named("name")), ") из рабочего пространства"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " участник удален"]), _normalize(["Удалено участников: ", _interpolate(_named("n"))])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Удалить участника"]), _normalize(["Удалить участников"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Этот пользователь будет удален из рабочего пространства."]), _normalize(["Эти пользователи будут удалены из рабочего пространства."])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Разрешение для рабочего пространства «", _interpolate(_list(0)), "» изменено."])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить участников"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте разговоры и связанные рецензии"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете просматривать все в рабочем пространстве, но управлять только опросами, назначениями, спорами и сеансами калибровки"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения для рабочего пространства"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте все в рабочем пространстве и управляйте им"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте все в рабочем пространстве, кроме настроек рабочего пространства"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подключить пользователей"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи из управляемых вами рабочих пространств добавлены в это рабочее пространство."])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попросите администратора сделать это."])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласите новых пользователей сами или попросите администратора."])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("link_invite")), " или ", _interpolate(_named("link_connect"))])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет пользователей для отображения"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("n")), " пользователь добавлен"]), _normalize(["Добавлено пользователей: ", _interpolate(_named("n"))])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавить участников"]), _normalize(["Добавить участника"]), _normalize(["Добавить такое количество участников: ", _interpolate(_named("n"))])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите разрешение для рабочего пространства"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей для рабочего пространства"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Добавить участников в ", _interpolate(_list(0))])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте сюда адреса электронной почты, разделенные новой строкой или запятой"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Соответствует такому количеству писем: ", _interpolate(_list(0)), " из ", _interpolate(_list(1))])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" с уже подключенными пользователями:"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Не удалось сопоставить такое количество писем: ", _interpolate(_list(0)), " из ", _interpolate(_list(1))])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте орфографию или подключите их позже по отдельности"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" с пользователями службы поддержки:"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " пользователь подключен"]), _normalize(["Подключено пользователей: ", _interpolate(_named("n"))])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " пользователь приглашен"]), _normalize(["Приглашено пользователей: ", _interpolate(_named("n"))])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовое подключение"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовое приглашение"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример:\n\nкатя", "@", "пример.com, иван", "@", "пример.com\n\nкатя", "@", "пример.com\nкатя", "@", "пример.com"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключитесь из своих служб поддержки, чтобы проверить взаимодействие"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Подключить пользователя"]), _normalize(["Подключить пользователей"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письма"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключать по отдельности"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы хотите, чтобы подключенные пользователи имели доступ к Zendesk QA, пригласите их здесь."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить подключенных пользователей"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Пригласить пользователя"]), _normalize(["Пригласить пользователей"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустить приглашение"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей из ваших служб поддержки для подключения и проверьте их взаимодействие"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление подпиской"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение пользователей службы поддержки"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвойте рабочему пространству уникальное имя"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если эта настройка включена, все существующие подключения к аккаунту автоматически добавляются в новое рабочее пространство."])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить все подключения в это рабочее пространство"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать рабочее пространство"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя рабочего пространства"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать рабочее пространство"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователям, добавленным непосредственно в аккаунт (без назначения рабочего пространства), назначается роль на уровне аккаунта. Вы сможете добавить их в рабочие пространства позже."])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашенным пользователям автоматически назначается роль пользователя на уровне аккаунта. Позже вы сможете повысить их до роли администратора."])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все эти варианты подойдут"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте сюда адреса электронной почты, разделенные новой строкой или запятой."])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " пользователь приглашен по почте"]), _normalize(["Количество пользователей, приглашенных по почте: ", _interpolate(_named("n"))])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователям, добавленным в рабочее пространство, автоматически назначается роль пользователя на уровне аккаунта. Позже вы сможете повысить их до роли администратора."])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другого"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашайте пользователей только в аккаунт, не добавляя их в рабочее пространство"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашенным пользователям назначается только разрешение для аккаунта, они не добавляются в рабочие пространства."])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашайте пользователей непосредственно в рабочее пространство и назначайте разрешения для рабочего пространства"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователей в рабочее пространство"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовое приглашение"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить по отдельности"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить только в аккаунт"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить в рабочее пространство"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Пригласить пользователей"]), _normalize(["Пригласить пользователя"]), _normalize(["Пригласить такое количество пользователей: ", _interpolate(_named("n"))])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовое приглашение пользователей по почте"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Удалить пользователя"]), _normalize(["Удалить пользователей"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить выбор"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить пользователей по почте"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет рабочих пространств"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать разрешение"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашение снова"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключенный пользователь"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь, ожидающий приглашения по почте"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь SCIM"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать рабочее пространство"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенты ИИ"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр администрирования"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gather"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспечение качества"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление персоналом"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выполнено ", _interpolate(_named("n")), " из ", _interpolate(_named("total"))]), _normalize(["Выполнено ", _interpolate(_named("n")), " из ", _interpolate(_named("total"))]), _normalize(["Выполнено ", _interpolate(_named("n")), " из ", _interpolate(_named("total"))])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цикл ", _interpolate(_named("cycleNr"))])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("startDate")), "–", _interpolate(_named("endDate"))])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензент"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить назначение"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сэкономьте время и настройте автоматические назначения рецензий вместо того, чтобы вручную искать и назначать разговоры для рецензирования."])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Срок до ", _interpolate(_named("date"))])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы можно добавлять в разделе «Калибровка» в боковом меню вида переписок."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет добавленных сеансов"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["исходный уровень"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сеансов"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговоры можно добавлять в сеанс, щелкнув значок калибровки в верхнем колонтитуле вида переписки."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет добавленных разговоров"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Еще нет оставленных рецензий. ", _interpolate(_named("link"))])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставить рецензию о калибровке"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная причина"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить палитру команд."])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено."])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найдено по ID"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка палитры команд..."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["страницы, фильтры, разговоры, настройки..."])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разговор"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный фильтр"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступный фильтр"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Из ", _interpolate(_list(0))])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из вашего API"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Отображаются от ", _interpolate(_named("rangeStart")), " до ", _interpolate(_named("rangeEnd")), " из ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Отображаются от ", _interpolate(_named("rangeStart")), " до ", _interpolate(_named("rangeEnd")), " из ", _interpolate(_named("total")), " ", _interpolate(_named("label"))])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть боковую панель"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать боковую панель"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить/выключить боковую панель"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принятые споры"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклоненные споры"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытые споры"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично принятые споры"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовые рецензии"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено рецензий"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотрено рецензий"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["принято"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["открыто"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["частично принято"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " от итога"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отклонено"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принято"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично принято"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["рецензия"]), _normalize(["рецензии"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество рецензий, просмотренных рецензентами"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество просмотренных рецензий"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спорные рецензенты"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники спора"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры по категориям"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споры со временем"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время выполнения"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время выполнения (ч)"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее время выполнения (мин)"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решено"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего споров"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хэштег"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принято"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык отклика"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все языки"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозируемые факторы"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факторы не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все факторы"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слова из облака слов"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слова не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все слова"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упоминания"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые новые"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые старые"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично принято"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ответил(а) ", _interpolate(_list(1))])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решено"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рецензии"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тон кожи"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеансы не найдены"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории не найдены"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер комментария"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размеры не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все размеры"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры не найдены"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все группы"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштеги"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все хэштеги"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значения не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все значения"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специальные поля не найдены"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги службы поддержки не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все теги службы поддержки"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка результатов..."])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы вопросов"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы вопросов не найдены"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить реакцию"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты оценок не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все карты оценок"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все оценки"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источники"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источники не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все источники"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отзыва"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причины не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все причины"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все опросы"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все каналы"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все пользователи"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства не найдены"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все рабочие пространства"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад к опросам"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос удален или еще не опубликован. Проверьте ссылку или вернитесь к списку опросов."])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот опрос не опубликован в рабочем пространстве, к которому вы принадлежите. Попросите администратора добавить вас в рабочее пространство, к которому у этого опроса есть доступ."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос недоступен"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к опросу предоставлен рабочим пространствам, к которым вы не принадлежите. Невозможно сохранить и опубликовать опрос."])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попросите администратора сохранить и опубликовать опрос."])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следует пометить хотя бы один ответ как правильный."])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это поле не может быть пустым."])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейчас отображается предварительный просмотр опроса."])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите рабочие пространства, которым разрешен доступ к опросу. Пользователи, принадлежащие к нескольким рабочим пространствам, могут пройти опрос только один раз. Если выбрать «Все рабочие пространства», новые созданные рабочие пространства после публикации опросов включены не будут."])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к опросам"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти из режима предварительного просмотра"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопросы"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклики"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройти опрос"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать опрос"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки опроса"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как черновик"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый опрос"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивировать"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["варианты"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вариант"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выглядит отлично, не так ли?"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш ответ отправлен"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш опрос сохранен"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш опрос опубликован"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш опрос удален"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вопрос"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать опрос"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивный опрос и его результаты больше не будут отображаться в списке опросов. У вас останется доступ к результатам архивного опроса, и при необходимости вы сможете распаковывать его."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать опрос"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованный опрос нельзя изменить или отредактировать. Все опубликованные опросы будут доступны всем пользователям вашего аккаунта."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать опрос?"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно удалить этот опрос? Это действие нельзя будет отменить."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить опрос?"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После отправки ответы нельзя будет изменить."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить опрос?"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивировать опрос"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После разархивирования опрос становится доступным в обзоре опросов. Предыдущие участники получат доступ к своим результатам, а новые смогут пройти опрос."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разархивировать опрос"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать вопрос"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание (необязательно)"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить вопрос вниз"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить вопрос вверх"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В режиме предварительного просмотра отображаются только вопросы с ответами."])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить вопрос"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить опрос"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос без названия"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название опроса"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос и его результаты будут необратимо удалены."])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроме того, результаты будут доступны, даже если поместить опрос в архив."])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С ответами"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить опрос"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос и его результаты будут необратимо удалены."])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Удалить опрос ", _interpolate(_list(0))])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы помогают укрепить уверенность и позиции экспертов команды, обеспечить согласованность в работе и подготовить новых членов команды."])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы можно искать по названию опроса или имени автора."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет созданных опросов"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт списка опросов"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ср. оценка"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выполнения"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос/автор"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос/автор/рабочие пространства"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовано"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочие пространства"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по опросу, автору"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В архиве"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все опросы"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос помещен в архив."])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос разархивирован."])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На этот опрос не поступило откликов от пользователей в выбранных рабочих пространствах."])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До помещения в архив на этот опрос не было откликов."])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет откликов"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы увидите разбивку ответов на опрос.\nВашу опрос еще никто не заполнил. Опубликуйте его."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще нет откликов"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответы:"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя оценка:"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("quizName")), " — копия"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка скопирована"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет результатов"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Опросы пользователя ", _interpolate(_list(0))])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя участника можно найти в таблице лидеров."])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт таблицы лидеров"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по участникам"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опросы"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать/скрыть таблицу лидеров"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это означает, что вы больше не сможете использовать фильтр для этого хэштега на информационной панели и автоматически заполнять комментарии. Это действие необратимо."])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштег, хэштег, хэштег, ..."])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать хэштеги"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать хэштеги"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать хэштеги"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить участникам создавать новые хэштеги"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэштег"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить хэштег"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Удалить хэштег ", _interpolate(_list(0))])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание хэштегов заблокировано"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание хэштегов разблокировано"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Хэштег «", _interpolate(_list(0)), "» удален"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Хэштег «", _interpolate(_list(0)), "» переименован на «", _interpolate(_list(1)), "»"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Добавлен 1 хэштег"]), _normalize(["Добавлено хэштегов: ", _interpolate(_named("count"))])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое имя"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хештеги не созданы"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переименовать хэштег «", _interpolate(_named("tagRenamed")), "»"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделяйте хэштеги запятыми или переносами строк"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск хэштега"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не беспокойтесь. Вы можете сделать это ", _interpolate(_named("link_copy"))])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прямо здесь"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение, связанное с рабочим пространством"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить подключение"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить подключение к этому рабочему пространству"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключение"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы внести изменения, обратитесь к администратору"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для внесения каких-либо изменений обратитесь в Zendesk QA"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подключений в аккаунте"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, вы еще не интегрировали платформу поддержки клиентов."])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["При удалении подключения из рабочего пространства ", _interpolate(_named("workspaceName")), " будут удалены разговоры и все связанные с ними данные, включая рецензии и споры."])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление подключения"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить подключение ", _interpolate(_named("connectionName"))])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подключение"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В течение выбранного периода времени в вашей карте оценок не было определенных активных категорий AutoQA. Разговоры до последнего изменения, внесенного ", _interpolate(_named("date")), ", могли быть не проанализированы для этих категорий."])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь будут отображаться все отзывы, оставленные вам. Пока ничего нет."])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет полученных рецензий."])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать только действия с комментариями"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь будут отображаться все ваши отзывы. Начните работу в виде разговоров."])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет оставленных рецензий."])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставленные рецензии"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные рецензии"])}
  }
}