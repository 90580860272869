import { ref, type Ref } from 'vue'
import { isString } from 'lodash-es'

import { session } from '@/composables/useSession'
import i18n from '@/i18n'
import { SCALE_VALUES } from '@/data/scales'
import useReviewSidebar from '@/composables/useReviewSidebar'
import { GROUP, BOT, FilterValueType } from '@/components/filters/types'

import { type Option } from '@/components/pickers/TagPicker.vue'
import { searchTicketTags } from '@/modules/dashboard/api'
import {
  type DeconstructedOption,
  type FilterOptionValue,
  convertValueToLabel,
  translateUserConditionSpecialValueLabel,
  userConditions,
  botConditions,
} from '../utils/filters'
import { getFilterOptionValues } from '../api/filters'

export default function useFilterValues(manuallyFetchedFilters?: Ref<Record<string, any[]>>) {
  const fetchedFilterValues = ref<Record<string, any[]>>(manuallyFetchedFilters ? manuallyFetchedFilters.value : {})

  const { displayAsEmoji } = useReviewSidebar()

  const getFilterValueType = (d: DeconstructedOption) => {
    const option = d.option

    if (option.name === 'TICKET_EXTERNAL_ID') return 'STRING'

    const value = option.name ? fetchedFilterValues.value[option.name] : undefined
    return isString(value) ? value : []
  }

  const removeDeprecatedValues = (values, valValue: DeconstructedOption) => {
    if (Array.isArray(valValue.values)) {
      valValue.values = valValue.values?.filter(
        (v) => !values.includes(typeof v === 'string' ? v : v.value),
      ) as FilterOptionValue
    }
  }

  const getDefaultGroupTitle = (name: DeconstructedOption['option']['name']) => {
    return userConditions.includes(name) ? i18n.t('conversations.filters.form.options.users') : ''
  }

  const separateValues = (values, d: DeconstructedOption) => {
    const vals = d.values

    const selectedValues = Array.isArray(vals) ? vals.map((v) => v.value) : [vals].map((v) => (v as any).value)
    const selectedName = d.option?.name || ''
    const hasSpecialValues = userConditions.includes(selectedName)
    const hasBots = botConditions.includes(selectedName)
    const customValues = hasSpecialValues ? values.filter((v) => v.value <= 3) : []
    const groupValues = hasSpecialValues ? values.filter((v) => v.type === GROUP) : []
    const botValues = hasSpecialValues ? values.filter((v) => v.type === BOT) : []

    // If some values are selected, place them first
    if (values.some((v) => selectedValues.includes(v.value))) {
      const matched = values
        .filter((v) => selectedValues.includes(v.value))
        .sort((a, b) => a.label.localeCompare(b.label))
      const matchedValues = matched.map((v) => v.value)
      const unmatchedValues = selectedValues.filter((v) => !matchedValues.includes(v))

      if (unmatchedValues.length) removeDeprecatedValues(unmatchedValues, d)

      const unselected = values.filter((v) => !selectedValues.includes(v.value))
      const filteredCustomValues = customValues.filter((v) => !selectedValues.includes(v.value))
      const filteredGroupValues = groupValues.filter((v) => !selectedValues.includes(v.value))
      const filteredBotValues = botValues.filter((v) => !selectedValues.includes(v.value))

      const groups = []

      if (matched.length) groups.push({ options: matched })
      if (filteredCustomValues.length) groups.push({ options: filteredCustomValues })
      if (hasBots && session.features.botQa) {
        groups.push({
          name: i18n.t('conversations.filters.form.options.bots'),
          options: filteredBotValues,
          emptyText: i18n.t('conversations.filters.form.options.bots_empty'),
        })
      }
      if (filteredGroupValues.length)
        groups.push({ name: i18n.t('conversations.filters.form.options.groups'), options: filteredGroupValues })
      const unselectedOptions = hasSpecialValues
        ? unselected.filter((v) => v.value > 3 && v.type !== GROUP)
        : unselected
      if (unselected.length) groups.push({ name: getDefaultGroupTitle(d.option.name), options: unselectedOptions })

      return groups
    }

    const groups = []

    if (customValues.length) groups.push({ options: customValues })
    if (hasBots && session.features.botQa) {
      groups.push({
        name: i18n.t('conversations.filters.form.options.bots'),
        options: botValues,
        emptyText: i18n.t('conversations.filters.form.options.bots_empty'),
      })
    }
    if (groupValues.length)
      groups.push({ name: i18n.t('conversations.filters.form.options.groups'), options: groupValues })

    const otherValues = hasSpecialValues ? values.filter((v) => v.value > 3 && v.type !== GROUP) : values
    if (otherValues.length) groups.push({ name: getDefaultGroupTitle(d.option.name), options: otherValues })

    return groups
  }

  const getFilterValues = (d: DeconstructedOption) => {
    const option = d.option

    if (option.isAutoQa || option.isCustomCategory) {
      const values = fetchedFilterValues.value[option.name] || []
      const scale = SCALE_VALUES['SCALE_' + values.at(-1).value]

      const mappedValues = values.map((v, idx) => ({
        label: idx === 0 ? i18n.t('conversations.filters.auto_qa.value_na') : v.name,
        value: v.value,
        emojiSrc: displayAsEmoji.value && idx !== 0 && scale[idx - 1].emoji,
      }))

      return separateValues(mappedValues, d)
    }

    const valueOptions = option.name ? fetchedFilterValues.value[option.name] : undefined

    if (isString(valueOptions)) return valueOptions
    if (!valueOptions) return []

    const keepValueFormat = option.name === 'TICKET_TAGS'
    const mappedValues = valueOptions.map((v) => {
      let label = v.label || (keepValueFormat ? v.value : convertValueToLabel(v.value))
      let value = v.value + ''

      if (!label && v.id && v.tag) {
        label = v.tag
        value = v.id.toString()
      }

      if (userConditions.includes(option.name)) {
        label = translateUserConditionSpecialValueLabel(v)
      }

      return { ...v, label, value }
    })

    return separateValues(mappedValues, d)
  }

  const getFilterValuesLoaded = ({ option }: DeconstructedOption) => {
    return option.name && fetchedFilterValues.value && option.name in fetchedFilterValues.value
  }

  const constructValueRequestParams = (option: DeconstructedOption['option']) => {
    const optionKey = option.name || ''
    const params: { option: string; source?: string; isAutoQa?: boolean; isSpotlight?: boolean } = { option: optionKey }

    if (option.isAutoQa) params.isAutoQa = true
    else if (option.isSpotlight || optionKey === 'SPOTLIGHT_CUSTOM_PHRASE_SEARCH') params.isSpotlight = true

    return params
  }

  const loadOptionValues = async (option: DeconstructedOption['option']) => {
    // Ticket tags and custom fields are handled by a separate component
    if (option.name === 'TICKET_TAGS' || option.isCustom) {
      fetchedFilterValues.value[option.name] = [1]
      return []
    } else if (option.isCustomCategory) {
      return (fetchedFilterValues.value[option.name] = option.options)
    } else if (option.isSpotlight) {
      fetchedFilterValues.value[option.name] = [option.label]
      return []
    }

    const params = constructValueRequestParams(option)
    const { data } = await getFilterOptionValues(
      params.option,
      params.isAutoQa || params.isSpotlight || params.option === 'SPOTLIGHT_CUSTOM_PHRASE_SEARCH'
        ? 'autoqa'
        : 'default',
    )

    if (params.option) fetchedFilterValues.value[params.option] = data

    return data
  }

  const optionHasValues = (d: DeconstructedOption) => {
    const filterValuesLoaded = getFilterValuesLoaded(d)

    if (!filterValuesLoaded) return false

    const values = getFilterValues(d)

    if (Array.isArray(values)) {
      const flatValues = getFilterValues(d)
        .map((g) => g.options)
        .flat()
      return !!flatValues?.length
    } else if (values !== '') {
      return true
    }

    return false
  }

  const optionValuesLoading = (d: DeconstructedOption) => {
    return !d.option.name || !getFilterValuesLoaded(d)
  }

  const getValue = (d: DeconstructedOption) => {
    const valueType = getValueType(d)
    if (valueType === FilterValueType.Text && d.option.isCustom) return d.values[0]?.value
    if ([FilterValueType.Integer, FilterValueType.Text].includes(valueType)) {
      return Array.isArray(d.values) && d.values.length === 0 ? undefined : d.values
    }

    return d.values
  }

  const getValueType = (d: DeconstructedOption): FilterValueType => {
    if (optionValuesLoading(d)) return FilterValueType.Loading
    if (!optionHasValues(d) && !d.option.isCustom) return FilterValueType.NoValues
    if (d.option.name === 'TICKET_TAGS' && Array.isArray(d.values)) return FilterValueType.SearchableTags
    if (d.option.isCustom) return FilterValueType.CustomField
    if (d.option.isSpotlight) return FilterValueType.NoValues
    if (!isString(getFilterValueType(d))) return FilterValueType.Generic
    if (getFilterValueType(d) === 'DATE') return FilterValueType.Date
    if (getFilterValueType(d) === 'INTEGER') return FilterValueType.Integer
    return FilterValueType.Text
  }

  const getTicketTags = async (tag: string): Promise<{ tags: Option[]; total: number }> => {
    const { tags, total } = await searchTicketTags({
      workspaceIds: [session.workspace.id],
      tag,
    })
    return { tags: tags.map((t) => ({ label: t, value: t })), total }
  }

  const getValuePlaceholder = (d: DeconstructedOption) => {
    const filterValuesLoaded = getFilterValuesLoaded(d)

    return filterValuesLoaded
      ? i18n.t('conversations.filters.form.value_empty')
      : i18n.t('conversations.filters.form.value_loading')
  }

  const getValueComponentProps = (d: DeconstructedOption) => {
    const valueType = getValueType(d)

    if (valueType === FilterValueType.SearchableTags) {
      return {
        apiMethod: getTicketTags,
      }
    }

    if (valueType === FilterValueType.Generic) {
      return {
        labelKey: ['TICKET_TAGS', 'SOURCE_TYPE'].includes(d.option.filterOption) ? 'value' : 'label',
        groups: getFilterValues(d),
        noResultsText: getValuePlaceholder(d),
      }
    }
  }

  return {
    fetchedFilterValues,
    getFilterValueType,
    getFilterValues,
    getFilterValuesLoaded,
    loadOptionValues,
    optionHasValues,
    optionValuesLoading,
    getValue,
    getValueType,
    getTicketTags,
    getValuePlaceholder,
    getValueComponentProps,
  }
}
