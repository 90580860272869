import { debounce } from 'lodash-es'
import { ref, computed, onMounted, onBeforeUnmount, watch, type Ref } from 'vue'

export default function useOverflowCheck(templateRef: Ref<HTMLElement | undefined>) {
  const dimensions = ref({ scrollWidth: 0, offsetWidth: 0 })
  const isOverflowing = computed(() => dimensions.value.scrollWidth > dimensions.value.offsetWidth)

  const updateDimensions = () => {
    if (!templateRef.value) return

    dimensions.value = {
      scrollWidth: templateRef.value.scrollWidth,
      offsetWidth: templateRef.value.offsetWidth,
    }
  }

  const debouncedUpdateDimensions = debounce(updateDimensions, 100)

  watch(() => templateRef.value?.textContent, debouncedUpdateDimensions)

  onMounted(() => {
    updateDimensions()

    const mutationObserver = new MutationObserver(debouncedUpdateDimensions)

    window.addEventListener('resize', debouncedUpdateDimensions)

    if (templateRef.value) {
      mutationObserver.observe(templateRef.value, { childList: true, subtree: true })
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', debouncedUpdateDimensions)
      if (mutationObserver) {
        mutationObserver.disconnect()
      }
    })
  })

  return {
    isOverflowing,
  }
}
