import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative pr-2.5 text-sm font-medium" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_more_vertical_icon = _resolveComponent("more-vertical-icon")!
  const _component_intelligence_tooltip = _resolveComponent("intelligence-tooltip")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_chevron_up_icon = _resolveComponent("chevron-up-icon")!
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createVNode(_component_draggable, {
      list: _ctx.categories,
      "item-key": "category",
      onEnd: _ctx.draggingEnded
    }, {
      item: _withCtx(({ element: item }) => [
        _createElementVNode("li", {
          class: _normalizeClass(_ctx.$style.legendCategory),
          onClick: ($event: any) => (item.expanded = !item.expanded)
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.category),
            "data-testid": "sunburst-legend-category"
          }, [
            _createVNode(_component_more_vertical_icon, {
              class: _normalizeClass(["icon-m", _ctx.$style.dragHandle])
            }, null, 8 /* PROPS */, ["class"]),
            _createVNode(_component_ui_checkbox, {
              checked: item.checked,
              disabled: item.checked && _ctx.onlyOneCategoryChecked,
              onChange: ($event: any) => (item.checked = $event),
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.categoryLabel)
                }, [
                  _createTextVNode(_toDisplayString(_ctx.categoryToLabel(item.category)) + " ", 1 /* TEXT */),
                  (_ctx.showIntelligenceIcon.includes(item.category))
                    ? (_openBlock(), _createBlock(_component_intelligence_tooltip, {
                        key: 0,
                        title: _ctx.categoryTooltipTitle(item.category)
                      }, {
                        icon: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getLegendTooltipIcon(item.category))))
                        ]),
                        content: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.categoryTooltip(item.category)), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "disabled", "onChange"]),
            (item.expanded)
              ? (_openBlock(), _createBlock(_component_chevron_up_icon, {
                  key: 0,
                  class: _normalizeClass(["icon-m ml-auto", _ctx.$style.categoryChevron])
                }, null, 8 /* PROPS */, ["class"]))
              : (_openBlock(), _createBlock(_component_chevron_down_icon, {
                  key: 1,
                  class: _normalizeClass(["icon-m ml-auto", _ctx.$style.categoryChevron])
                }, null, 8 /* PROPS */, ["class"]))
          ], 2 /* CLASS */),
          (item.expanded)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: _normalizeClass(_ctx.$style.categorySlices),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.values, (categorySlice) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: categorySlice.value,
                    class: _normalizeClass(_ctx.$style.categorySlice),
                    "data-testid": "sunburst-legend-subcategory"
                  }, [
                    _createVNode(_component_ui_checkbox, {
                      checked: categorySlice.checked,
                      title: 
                  _ctx.subCategoryLabel({
                    category: categorySlice.category,
                    name: categorySlice.label,
                    value: categorySlice.value,
                  })
                ,
                      onChange: ($event: any) => (categorySlice.checked = $event)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.$style.dot),
                          style: _normalizeStyle({ background: _ctx.getCategoryValueColor(categorySlice) })
                        }, null, 6 /* CLASS, STYLE */),
                        _createTextVNode(" " + _toDisplayString(_ctx.subCategoryLabel({
                    category: categorySlice.category,
                    name: categorySlice.label,
                    value: categorySlice.value,
                  })), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "title", "onChange"])
                  ], 2 /* CLASS */))
                }), 128 /* KEYED_FRAGMENT */))
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_2)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["list", "onEnd"])
  ]))
}