import type { RouteRecordRaw } from 'vue-router'

import { getFirstParam } from '@/utils/routes'
import { session } from '@/composables/useSession'
import { hasZendeskProxy } from '@/config'

const isProxied = hasZendeskProxy || session.features.zendeskProxyDev

const routes: RouteRecordRaw[] = [
  {
    path: '/settings',
    name: 'settings.usermanagement',
    component: () => import('./UserManagementRoutes.vue'),
    props: ({ params }) => ({ id: parseFloat(getFirstParam(params.id) as string) }),
    meta: { requiredHighestWorkspaceRole: ['MANAGER', 'LEAD'], requiresPayment: isProxied },
    children: [
      {
        path: 'users',
        name: 'settings.users',
        components: {
          header: () => import('./components/AccountUsersViewHeader.vue'),
          default: () => import('./AccountUsersView.vue'),
        },
        meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
        children: [
          { path: 'connect', name: 'settings.users.connect', redirect: undefined, children: [] },
          { path: 'invite', name: 'settings.users.invite', redirect: undefined, children: [] },
          { path: 'import', name: 'settings.users.import', redirect: undefined, children: [] },
        ],
      },
      {
        path: 'groups',
        name: 'settings.groups',
        components: {
          header: () => import('./groups/GroupsViewHeader.vue'),
          default: () => import('./groups/GroupsView.vue'),
        },
      },
      {
        path: 'groups/create',
        name: 'settings.groups.create',
        components: {
          header: () => import('./groups/GroupsViewHeader.vue'),
          default: () => import('./groups/GroupsCreateView.vue'),
        },
      },
      {
        path: 'groups/:id',
        props: ({ params }) => ({ id: parseFloat(getFirstParam(params.id) as string) }),
        children: [
          {
            path: 'edit',
            name: 'settings.groups.edit',
            components: {
              header: () => import('./groups/GroupsViewHeader.vue'),
              default: () => import('./groups/GroupsCreateView.vue'),
            },
          },
          {
            path: 'archived',
            name: 'settings.groups.archived',
            components: {
              header: () => import('./groups/GroupsViewHeader.vue'),
              default: () => import('./groups/GroupsCreateView.vue'),
            },
          },
        ],
      },
      {
        path: 'bots',
        name: 'settings.bots',
        components: {
          header: () => import('./bots/BotsViewHeader.vue'),
          default: () => import('./bots/BotsView.vue'),
        },
        meta: { requiredFlag: 'botQa' },
      },
      {
        path: 'workspaces/:id',
        props: ({ params }) => ({ id: parseFloat(getFirstParam(params.id) as string) }),
        children: [
          {
            path: 'members',
            name: 'workspace.members',
            components: {
              header: () => import('./components/WorkspaceMembersViewHeader.vue'),
              default: () => import('./WorkspaceMembersView.vue'),
            },
            meta: { requiredHighestWorkspaceRole: ['MANAGER'] },
          },
        ],
      },
    ],
  },
]

export default routes
