import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_scroll_shadow = _resolveComponent("scroll-shadow")!
  const _component_chart = _resolveComponent("chart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.list)
    }, [
      _createVNode(_component_ui_checkbox, {
        checked: _ctx.allRows,
        class: _normalizeClass(["text-xs font-medium", [_ctx.$style.item, _ctx.$style.selectAll]]),
        label: _ctx.selectAllLabel,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.allRows = $event))
      }, null, 8 /* PROPS */, ["checked", "class", "label"]),
      _createVNode(_component_scroll_shadow, {
        class: _normalizeClass(_ctx.$style.scroll),
        "redraw-when": _ctx.data,
        style: {"--shadow-size":"2em","--shadow-start-color":"white"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: _normalizeClass(_ctx.$style.itemContainer)
            }, [
              _createVNode(_component_ui_checkbox, {
                class: _normalizeClass(_ctx.$style.item),
                label: _ctx.renderLabel(row),
                checked: _ctx.selectedIndexes.includes(idx),
                onChange: ($event: any) => (_ctx.handleRowSelect(idx, $event)),
                onMouseover: ($event: any) => (_ctx.hoveredIndex = idx),
                onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoveredIndex = -1))
              }, null, 8 /* PROPS */, ["class", "label", "checked", "onChange", "onMouseover"]),
              (_ctx.hoveredIndex === idx && _ctx.selectedIndexes.includes(idx))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.dot),
                    style: _normalizeStyle(`background-color: ${_ctx.getLineColor(_ctx.selectedIndexes.findIndex((i) => i === idx))}`)
                  }, null, 6 /* CLASS, STYLE */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */)),
          (_ctx.canPaginate)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "text-active-primary mt-1 flex cursor-pointer select-none items-center text-sm font-semibold hover:underline",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showMore && _ctx.showMore(...args)))
              }, [
                _createVNode(_component_chevron_down_icon, {
                  size: "16",
                  class: "mr-2"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('dashboard.show_more', _ctx.showMoreCount)), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "redraw-when"])
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.chartContainer)
    }, [
      _createVNode(_component_chart, {
        class: _normalizeClass(_ctx.$style.chart),
        "time-step": _ctx.timeStep,
        labels: _ctx.data.dataLabels,
        "active-index": _ctx.datasetHoverIndex,
        datasets: _ctx.datasets,
        height: 220,
        width: 660
      }, null, 8 /* PROPS */, ["class", "time-step", "labels", "active-index", "datasets"])
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}