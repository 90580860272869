import { integrationMap, integrations } from './constants/integrations'

export const sourceConnectionMap = {
  intercom: integrationMap().intercom.value,
  aircall: integrationMap().aircall.value,
  gorgias: integrationMap().gorgias.value,
}

export const directInstallSource = () => sessionStorage.directInstall as string | undefined

export const directInstallName = () => integrations().find((i) => i.value === directInstallSource())?.label

export const setDirectInstallFlag = (source: string) =>
  (sessionStorage.directInstall = sourceConnectionMap[source as keyof typeof sourceConnectionMap])

export const clearDirectInstallFlag = () => delete sessionStorage.directInstall

export const getCancelUrl = (source?: string, message = 'Installation cancelled') => {
  switch (source) {
    case 'INTERCOM':
      return `https://app.intercom.com/appstore/redirect?error_message=${encodeURIComponent(message)}`
    default:
      throw new Error('Invalid source!')
  }
}

export const getSubmitUrl = (source: string) => {
  switch (source) {
    case 'INTERCOM':
      return 'https://app.intercom.com/appstore/redirect?install_success=true'
    default:
      throw new Error('Invalid source!')
  }
}

export const handleCloseFlow = () => {
  if (window.opener !== null || window.history.length === 1) return window.close()
  window.location.href = '/'
}
