import { ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { createEventHook, watchOnce } from '@vueuse/core'
import { useQuery, useMutation } from '@tanstack/vue-query'

import { type RootCause, RequireReasonVisibility } from '@/types/category'
import { session } from '@/composables/useSession'

import {
  createCategory as createCategoryApi,
  updateCategory as updateCategoryApi,
  type Category,
  type CategoryPayload,
} from '../api/categories'
import { scaleOptions } from '../utils'
import { categories, activeCategories, getCategories } from '../views/scorecard/useScorecard'

const accountScale = session.account.ratingCategory.scale

export default function useCategoryForm(props: { workspaceId: number; categoryId?: number }) {
  const state = ref<Partial<CategoryPayload>>(getEmptyCategory())
  const createCategoryEventHook = createEventHook<Response>()
  const updateCategoryEventHook = createEventHook<Response>()
  const fetchCategoryEventHook = createEventHook<Partial<CategoryPayload>>()

  const { data: initialState } = useQuery({
    queryKey: ['category'],
    queryFn: () => fetchCategory(props.categoryId, props.workspaceId),
    initialData: getEmptyCategory(),
    enabled: !!props.workspaceId,
    gcTime: 0,
  })

  const { mutate: updateCategory } = useMutation({
    mutationFn: updateCategoryPromise,
    onSuccess: () => updateCategoryEventHook.trigger(),
  })

  const { mutate: createCategory } = useMutation({
    mutationFn: createCategoryPromise,
    onSuccess: () => createCategoryEventHook.trigger(),
  })

  watchOnce(initialState, (initialState) => {
    state.value = toCategoryState(initialState)
    fetchCategoryEventHook.trigger(initialState)
  })

  async function createCategoryPromise() {
    const rootCauses = state.value.requireReason ? cleanRootCauses(state.value.rootCauses) : undefined
    const payload = { ...state.value, rootCauses } as Partial<CategoryPayload>
    await createCategoryApi(props.workspaceId, payload)
  }

  async function updateCategoryPromise() {
    const payload = toUpdateCategoryPayload(state.value)
    await updateCategoryApi(props.workspaceId, state.value.id, payload)
  }

  return {
    state,
    initialState,
    createCategory,
    updateCategory,
    onCategoryCreate: createCategoryEventHook.on,
    onCategoryUpdate: updateCategoryEventHook.on,
    onCategoryFetch: fetchCategoryEventHook.on,
  }
}

const getEmptyCategory = (): Partial<Category> => {
  return {
    id: 0,
    autoQaCategory: null,
    conditions: null,
    failCategory: false,
    freeTextAllowed: false,
    name: '',
    scorecards: [],
    weighting: 1,
    description: '',
    requireReason: false,
    multipleRequireReasons: false,
    requireReasonVisibility: RequireReasonVisibility.Negative,
    rootCauses: [],
    scale: scaleOptions[0].value,
    tags: [],
  }
}

function toUpdateCategoryPayload(state: Partial<CategoryPayload>) {
  const scale = session.features.granularScale ? state.scale : accountScale
  const rootCauses = state.requireReason ? cleanRootCauses(state.rootCauses) : undefined
  const autoQaCategory = session.features.autoQa ? state.autoQaCategory : undefined
  const autoQaCustomCategoryTemplateId = session.features.autoQa ? state.autoQaCustomCategoryTemplateId : undefined

  const payload = {
    autoQaCategory,
    autoQaCustomCategoryTemplateId,
    conditions: state.conditions,
    freeTextAllowed: state.freeTextAllowed,
    name: state.name,
    weighting: state.weighting,
    failCategory: state.failCategory,
    tags: state.tags,
    scorecards: state.scorecards,
    description: state.description,
    requireReason: state.requireReason,
    multipleRequireReasons: state.multipleRequireReasons,
    requireReasonVisibility: state.requireReasonVisibility,
    rootCauses,
    scale,
  } as Partial<CategoryPayload>

  return payload
}

const toCategoryState = (category: Partial<CategoryPayload>) => {
  const categoryState = { ...category }
  categoryState.rootCauses = categoryState.requireReason ? cloneDeep(categoryState.rootCauses) : []

  if (session.features.granularScale && !category) categoryState.scale = getLastUpdatedCategoryScale()
  else if (!session.features.granularScale) categoryState.scale = accountScale

  if (!categoryState.requireReasonVisibility) {
    categoryState.requireReasonVisibility = RequireReasonVisibility.Negative
  }

  return categoryState
}

function getLastUpdatedCategoryScale() {
  const byCreateOrUpdateDate = (a: Partial<Category>, b: Partial<Category>) =>
    new Date(b.updated || b.created).getTime() - new Date(a.updated || a.created).getTime()

  const [lastUpdatedCategory] = activeCategories.value.slice().sort(byCreateOrUpdateDate)
  return lastUpdatedCategory?.scale || scaleOptions[0].value
}

async function fetchCategory(categoryId: number, workspaceId: number): Promise<Partial<CategoryPayload>> {
  if (!categoryId) return getEmptyCategory()

  //  TODO: Replace this list search with proper API call by categoryID once we have the endpoint
  await getCategories(workspaceId)
  return categories.value.find(({ id }) => id === categoryId)
}

function cleanRootCauses(rootCauses: Partial<RootCause>[]) {
  if (!rootCauses) return []

  return rootCauses.map((r) => (!r.id ? { cause: r.cause } : r))
}
