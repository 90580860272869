// we can remove this once we get the new api endpoints

import i18n from '@/i18n'
import { session } from '@/composables/useSession'

import { updateNotifications as updateNotificationsApi } from '@/modules/settings/api'
import type { Notifications } from '@/modules/settings/types'

import { type EmailNotifications, type SlackNotifications, NotificationFrequency } from '../types'

export const frequencyOptions = [
  NotificationFrequency.INSTANT,
  NotificationFrequency.HOURS_3,
  NotificationFrequency.HOURS_6,
  NotificationFrequency.DAILY,
  NotificationFrequency.WEEKLY,
  NotificationFrequency.MONTHLY,
  NotificationFrequency.OFF,
].map((value) => ({
  label: i18n.t(`profile.notifications.feedback_steps.${value.toLowerCase()}`),
  value,
}))

export interface State {
  email: EmailNotifications
  slack: SlackNotifications
}

const getNotificationValue = (data: Notifications, key: string) => {
  if (data[key + 'Instant']) return frequencyOptions[0]
  else if (data[key + '3Hour']) return frequencyOptions[1]
  else if (data[key + '6Hour']) return frequencyOptions[2]
  else if (data[key + 'Daily']) return frequencyOptions[3]
  else if (data[key + 'Weekly']) return frequencyOptions[4]
  else if (data[key + 'Monthly']) return frequencyOptions[5]
  return frequencyOptions[6]
}

export const mapResponseToState = (data: Notifications): State => {
  const state: State = { email: null, slack: null }

  state.email = {
    reportDaily: data.sendDailyReport,
    reportWeekly: data.sendWeeklyReport,
    reportMonthly: data.sendMonthlyReport,
    feedbackStep: getNotificationValue(data, 'feedback').value,
    threadStep: getNotificationValue(data, 'threadFeedback').value,
    mentionStep: getNotificationValue(data, 'mention').value,
    disputeStep: null,
    noReviewWeeklyReminder: data.noReviewWeeklyReminder,
  }

  state.email.disputeStep = getNotificationValue(data, 'disputeAccepted').value
  state.email.disputeStep = getNotificationValue(data, 'disputeRejected').value
  state.email.disputeStep = getNotificationValue(data, 'disputeFeedbackRequested').value
  state.email.disputeStep = getNotificationValue(data, 'disputeRelatedToUserCreated').value

  state.slack = {
    ratingNotification: data.slackRatingNotification,
    commentNotification: data.slackCommentNotification,
    threadFeedbackNotification: data.slackThreadFeedbackNotification,
    mentionNotification: data.slackMentionNotification,
    disputeNotification: data.slackDisputeAcceptedInstant,
  }

  return state
}

const getSlackNotificationPromises = (state: State, results: State): Promise<any>[] => {
  const promises = []
  const {
    ratingNotification,
    commentNotification,
    threadFeedbackNotification,
    mentionNotification,
    disputeNotification,
  } = state.slack

  if (results.slack.ratingNotification !== ratingNotification)
    promises.push(updateNotificationSetting('SLACK', 'INSTANT', 'RATING'))
  if (results.slack.commentNotification !== commentNotification)
    promises.push(updateNotificationSetting('SLACK', 'INSTANT', 'COMMENT'))
  if (results.slack.threadFeedbackNotification !== threadFeedbackNotification)
    promises.push(updateNotificationSetting('SLACK', 'INSTANT', 'THREAD_FEEDBACK'))
  if (results.slack.mentionNotification !== mentionNotification)
    promises.push(updateNotificationSetting('SLACK', 'INSTANT', 'MENTION'))
  if (results.slack.disputeNotification !== disputeNotification) promises.push(updateDisputes('SLACK', 'INSTANT'))

  return promises
}

const updateNotificationSetting = async (channel: string, frequency: string, type: string) => {
  return await updateNotificationsApi({ channel, frequency, notificationType: type })
}

const updateDisputes = async (channel: string, frequency: string) => {
  await Promise.all([
    updateNotificationSetting(channel, frequency, 'DISPUTE_FEEDBACK_REQUESTED'),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_CREATED'),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_ACCEPTED'),
    updateNotificationSetting(channel, frequency, 'DISPUTE_RELATED_TO_USER_REJECTED'),
    updateNotificationSetting(channel, frequency, 'DISPUTE_ACCEPTED'),
    updateNotificationSetting(channel, frequency, 'DISPUTE_REJECTED'),
  ])
}

export const updateNotifications = ({
  state,
  results,
  hasSlackConnection,
}: {
  state: State
  results: State
  hasSlackConnection: boolean
}) => {
  let promises: Promise<any>[] = []
  const {
    feedbackStep,
    threadStep,
    mentionStep,
    disputeStep,
    reportDaily,
    reportWeekly,
    reportMonthly,
    noReviewWeeklyReminder,
  } = state.email

  if (results.email.reportDaily !== reportDaily) promises.push(updateNotificationSetting('EMAIL', 'DAILY', 'REPORT'))
  if (results.email.reportWeekly !== reportWeekly) promises.push(updateNotificationSetting('EMAIL', 'WEEKLY', 'REPORT'))
  if (results.email.reportMonthly !== reportMonthly)
    promises.push(updateNotificationSetting('EMAIL', 'MONTHLY', 'REPORT'))
  if (results.email.feedbackStep !== feedbackStep)
    promises.push(updateNotificationSetting('EMAIL', feedbackStep, 'FEEDBACK'))
  if (results.email.threadStep !== threadStep)
    promises.push(updateNotificationSetting('EMAIL', threadStep, 'THREAD_FEEDBACK'))
  if (results.email.mentionStep !== mentionStep)
    promises.push(updateNotificationSetting('EMAIL', mentionStep, 'MENTION'))
  if (results.email.disputeStep !== disputeStep) promises.push(updateDisputes('EMAIL', disputeStep))
  if (results.email.noReviewWeeklyReminder !== noReviewWeeklyReminder)
    promises.push(updateNotificationSetting('EMAIL', 'WEEKLY', 'NO_REVIEW'))

  if (hasSlackConnection && session.features.slackNotifications) {
    promises = [...promises, ...getSlackNotificationPromises(state, results)]
  }

  return Promise.all(promises)
}
