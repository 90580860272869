export function smoothScroll(element: HTMLElement, options: ScrollIntoViewOptions) {
  const container = document.getElementsByClassName('main')[0]
  return new Promise<void>((resolve) => {
    let scrollTimeout: ReturnType<typeof setTimeout>

    const onScroll = () => {
      clearTimeout(scrollTimeout)
      // if no scroll event in 100ms, assume that scrolling finished
      scrollTimeout = setTimeout(finish, 100)
    }

    const finish = () => {
      container?.removeEventListener('scroll', onScroll)
      resolve()
    }

    container?.addEventListener('scroll', onScroll)

    element.scrollIntoView({ behavior: 'smooth', ...options })

    // make sure that promise is still resolved when there's no need to scroll
    setTimeout(() => {
      if (!scrollTimeout) finish()
    }, 100)
  })
}
