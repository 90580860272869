import {
  type SurveyTranslation,
  type SurveyConfigurationListItem,
  SurveyConfiguration_Status as SurveyStatus,
  type SurveyTriggerCondition,
} from '@zendesk/zqa-services/surveys'

export enum FormSections {
  Styling = 'styling',
  Content = 'content',
  ContentEmail = 'contentEmail',
  ContentRatingScores = 'contentRatingScores',
  ContentThankYou = 'contentThankYou',
  Delivery = 'delivery',
  Languages = 'languages',
}

export enum DeliveryOptions {
  Email = 'EMAIL',
  Chat = 'HELPDESK',
  Snippet = 'SNIPPET',
}

export { SurveyStatus }

export interface Survey extends SurveyConfigurationListItem {
  status: SurveyStatus
}

export type TriggerCondition = SurveyTriggerCondition

export interface SurveyConnection {
  id: string
  name: string
  sourceType: string
  disabled: boolean
  needsAuth: boolean
  maskClientData: boolean
  subdomain: string | null
}

export interface DeliverySettings {
  connection: SurveyConnection
  deliveryMethod: string
  enabled: boolean
  triggerConditions: TriggerCondition[]
  emailFollowUpMinutes: number
}

export interface SurveyConfig {
  aiAssistEnabled: boolean
  id: string
  brand: string
  mailFromName: string
  mailFromAddress: string
  mailSubject: string
  introMessage: string
  question: string
  customQuestion: string
  bgColor: string
  btnColor: string
  logo: string
  thankYouMessage: string
  expiryDays: string | null
  showReasons: boolean
  reasonList: string[]
  questionScale: string
  questionScaleDisplay: 'NUMBER' | 'EMOJI'
  draftParentId: string
  status: SurveyStatus
  deliverySettings: DeliverySettings[]
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  surveyFormDomain?: string
  translations: SurveyTranslation[]
  questionTypeId: string
  leftRatingScaleLabel: string
  rightRatingScaleLabel: string
  privacyLink: string
  termsLink: string
}

export interface AuthData {
  connectionId: string
}

export interface Option {
  value: string
  label: string
  externalFieldId?: string
}

export interface Tag {
  key: string
  value: string
}

export interface TriggerConditionValidationError {
  id: string
  brand: string
  deliverySettings: DeliverySettings[]
}

export const DEFINE_CUSTOM_TYPE = '-1'
