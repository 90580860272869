import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-lg font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_image = _resolveComponent("question-image")!
  const _component_check_icon = _resolveComponent("check-icon")!
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1 /* TEXT */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (url) => {
      return (_openBlock(), _createBlock(_component_question_image, {
        key: url,
        src: url
      }, null, 8 /* PROPS */, ["src"]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createElementVNode("ul", {
      class: _normalizeClass(_ctx.$style.answers)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
        return (_openBlock(), _createElementBlock("li", {
          key: answer.id,
          class: _normalizeClass([
          _ctx.$style.answer,
          {
            [_ctx.$style.answerCorrect]: answer.correct,
            [_ctx.$style.answerIncorrect]: answer.incorrect,
          },
        ])
        }, [
          (_ctx.allowMultipleSelection)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(answer.selected ? _ctx.$style.checkboxIconSelected : _ctx.$style.checkboxIcon)
              }, [
                _createVNode(_component_check_icon)
              ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(answer.selected ? _ctx.$style.radioIconSelected : _ctx.$style.radioIcon)
              }, null, 2 /* CLASS */)),
          _createElementVNode("span", {
            class: _normalizeClass(["text-sm", _ctx.$style.label])
          }, _toDisplayString(answer.label), 3 /* TEXT, CLASS */),
          (answer.correct || answer.incorrect)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass({
            [_ctx.$style.correctAnswerIcon]: answer.correct,
            [_ctx.$style.incorrectAnswerIcon]: answer.incorrect,
          })
              }, [
                (answer.correct)
                  ? (_openBlock(), _createBlock(_component_check_icon, { key: 0 }))
                  : (answer.incorrect)
                    ? (_openBlock(), _createBlock(_component_x_icon, { key: 1 }))
                    : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}