import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_workspace_dot = _resolveComponent("workspace-dot")!
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    ref: "tippyRef",
    trigger: "click",
    theme: "light paddingless",
    placement: "bottom",
    arrow: false,
    interactive: ""
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.trigger, "text-gray-90 flex items-center gap-x-2 text-sm font-medium"])
      }, [
        (_ctx.selectedWorkspaces.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedWorkspaces, (workspace, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: workspace.id
                }, [
                  _createVNode(_component_workspace_dot, {
                    class: "mr-1",
                    color: workspace.color,
                    shadow: ""
                  }, null, 8 /* PROPS */, ["color"]),
                  _createTextVNode(" " + _toDisplayString(workspace.name) + _toDisplayString(i === _ctx.selectedWorkspaces.length - 1 ? '' : ','), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */)),
              _createVNode(_component_chevron_down_icon, {
                class: _normalizeClass(["icon-m", _ctx.$style.icon])
              }, null, 8 /* PROPS */, ["class"])
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('user_management.groups.group.workspaces')) + " ", 1 /* TEXT */),
              _createVNode(_component_chevron_down_icon, {
                class: _normalizeClass(["icon-m", _ctx.$style.icon])
              }, null, 8 /* PROPS */, ["class"])
            ], 64 /* STABLE_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.container),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tippyRef?.tip.hide()))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspaces, (workspace) => {
          return (_openBlock(), _createElementBlock("button", {
            key: workspace.id,
            class: _normalizeClass(["text-sm font-medium", [_ctx.$style.button, _ctx.isSelected(workspace.id) && _ctx.$style.active]]),
            type: "button",
            "data-ignore-theme": "",
            onClick: ($event: any) => (_ctx.clicked(workspace.id))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_workspace_dot, {
                class: "mr-1",
                color: workspace.color,
                shadow: ""
              }, null, 8 /* PROPS */, ["color"]),
              _createTextVNode(" " + _toDisplayString(workspace.name), 1 /* TEXT */)
            ])
          ], 10 /* CLASS, PROPS */, _hoisted_1))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}