import getSelectedSlices from '@/modules/conversations/components/visualFilters/getSelectedSlices'

export default (rootNode, oldZoomedNode) => {
  const [, ...childrenPath] = getSelectedSlices(oldZoomedNode)

  let newZoomedNode = rootNode
  for (const { category, name } of childrenPath) {
    const child = newZoomedNode.children.find((c) => c.data.category === category && c.data.name === name)
    if (child) newZoomedNode = child
    else break
  }
  if (
    oldZoomedNode.data.category === newZoomedNode.data.category &&
    oldZoomedNode.data.name === newZoomedNode.data.name
  ) {
    return newZoomedNode
  }
  return rootNode
}
