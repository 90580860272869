import type { ScaleType, DisplayType } from '@/data/scales'
import type { DataRetentionOption } from '@/modules/settings/utils/dataRetentionOptions'
import type { WorkspaceItem } from '../user-management/types'

export interface SettingsHeaderProps {
  title?: string
  hasUnsavedChanges?: boolean
  breadcrumbSteps?: string[] | boolean
  hideNav?: boolean
  hideLeftSidebar?: boolean
  hideRightSidebar?: boolean
}

export interface AmazonConnectSetting {
  secretKey?: string | null
  keyId: string
  region: string
  location: string
}

export interface AccountToken {
  amazonConnectSetting: AmazonConnectSetting
  basedOn: number
  bodyFromIntegration: boolean
  clientId?: string
  dataRetentionPeriod?: DataRetentionOption
  disabled: boolean
  disabledAt: string
  disabledReason: string
  enabledInAllWorkspaces?: boolean
  filterByGroup: any[]
  ignoreCsatAbove: number
  intercomTeamId: string
  intercomSubdomain: string
  ignoreGroups: any[]
  ignoreTicketFields: string[]
  ignoreTicketTags: string[]
  includeGroups: any[]
  includeTicketTags: string[]
  includeMailboxes?: string[]
  ignoreMailboxes?: string[]
  includeChannels?: string[]
  ignoreChannels?: string[]
  jobDateTime: string
  jobStatus: string
  maskClientData: boolean
  name?: string
  ownerId: number
  ownerName: string
  pullBodyFromIntegration: boolean
  pullSubjectFromIntegration: boolean
  region?: string
  removeAttachmentUrls: boolean
  salesforceSandbox: boolean
  sourceType: string
  subdomain: string
  subjectFromIntegration: boolean
  ticketFieldIgnoreList: string[]
  ticketTagIgnoreList: string[]
  token: string
  tokenCreate: string
  tokenId: number
  tokenName: string
  workspaces: WorkspaceItem[]
  useSystemUser: boolean
}

export enum NotificationFrequency {
  INSTANT = 'INSTANT',
  OFF = 'OFF',
  HOURS_3 = 'HOURS_3',
  HOURS_6 = 'HOURS_6',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

// Single-item-array-weirdness, Just Java Things™ ¯\_(ツ)_/¯
// While it is in theory possible to pass in multiple frequencies to our API, as our API only accounts for the first one,
// we'd want to restrict our UI code to always have a single member for each frequency config array
export interface NotificationSettings {
  notificationConfiguration: {
    EMAIL: {
      DISPUTE_ACCEPTED: [NotificationFrequency]
      DISPUTE_FEEDBACK_REQUESTED: [NotificationFrequency]
      DISPUTE_REJECTED: [NotificationFrequency]
      DISPUTE_RELATED_TO_USER_CREATED: [NotificationFrequency]
      FEEDBACK: [NotificationFrequency]
      MENTION: [NotificationFrequency]
      NO_REVIEW: [NotificationFrequency]
      REPORT: [NotificationFrequency]
      THREAD_FEEDBACK: [NotificationFrequency]
    }
    SLACK: {
      COMMENT: [NotificationFrequency]
      DISPUTE_ACCEPTED: [NotificationFrequency]
      DISPUTE_FEEDBACK_REQUESTED: [NotificationFrequency]
      DISPUTE_REJECTED: [NotificationFrequency]
      DISPUTE_RELATED_TO_USER_CREATED: [NotificationFrequency]
      MENTION: [NotificationFrequency]
      RATING: [NotificationFrequency]
      THREAD_FEEDBACK: [NotificationFrequency]
    }
  }
  paymentId: number
  paymentName: string
  // Currently don't want to allow per-role notifications
  role: 'ALL'
  userChangeAllowed: boolean
  zoneId: string
}

export interface Notifications {
  email: string
  feedback3Hour: boolean
  feedback6Hour: boolean
  feedbackDaily: boolean
  feedbackInstant: boolean
  feedbackMonthly: boolean
  feedbackWeekly: boolean
  mention3Hour: boolean
  mention6Hour: boolean
  mentionDaily: boolean
  mentionInstant: boolean
  mentionMonthly: boolean
  mentionWeekly: boolean
  noReviewWeeklyReminder: boolean
  paymentId: number
  paymentName: string
  sendDailyReport: boolean
  sendMonthlyReport: boolean
  sendWeeklyReport: boolean
  slackCommentNotification: boolean
  slackMentionNotification: boolean
  slackRatingNotification: boolean
  slackDisputeAcceptedInstant: boolean
  slackThreadFeedbackNotification: boolean
  threadFeedback3Hour: boolean
  threadFeedback6Hour: boolean
  threadFeedbackDaily: boolean
  threadFeedbackInstant: boolean
  threadFeedbackMonthly: boolean
  threadFeedbackWeekly: boolean
  userChangeAllowed: boolean
  userId: number
  userName: string
  zoneId: string
}

export interface UserAuthorization {
  id: number
  provider: string
  name?: string
  email: string
  pictureUrl?: string
}

export interface UserProfile {
  agreed: boolean | null
  email: string
  firstDayOfWeek: 'MONDAY' | 'SUNDAY'
  id: string
  joined: string
  locale: string
  name: string
  pictureUrl?: string
  role: string
  timeZone: string
  userAuthorizations: UserAuthorization[]
}

// notifications states
export interface EmailNotifications {
  reportDaily: boolean | null
  reportWeekly: boolean | null
  reportMonthly: boolean | null
  feedbackStep: string | null
  threadStep: string | null
  mentionStep: string | null
  disputeStep: string | null
  noReviewWeeklyReminder: boolean | null
}

export interface SlackNotifications {
  ratingNotification: boolean
  commentNotification: boolean
  threadFeedbackNotification: boolean
  mentionNotification: boolean
  disputeNotification: boolean
  praisebotNotification?: {
    enabled: boolean
    threshold?: number
    channel: {
      id: string
      name: string
    }
  }
}

export interface WebhooksNotifications {
  url: string
  criticalCategoryFailures: boolean
  csatAnswered: boolean
}

export interface CurrentAccountPayload {
  data: {
    actionCount: number
    activatedAt: string
    blockProfileDeletion: boolean
    categoryDisplayType: string
    categoryScale: ScaleType
    categorySkippable: boolean
    firstDayOfWeek: string
    id: number
    name: string
    nextBillingAt: string
    planQuantity: number
    subscriptionPlanId: string
    subscriptionStatus: string
    syncTargetWorkspaceId: number
    timeZone: string
    timeFormat: string
    userCount: number
  }
}

export interface NotificationPayload {
  channel: string
  frequency: string
  notificationType: string
}

export interface WebhookPayload {
  url: string
  criticalCategoryFailures: boolean
  csatAnswered: boolean
}

export interface UpdateAccountPayload {
  name: string
  firstDayOfWeek: string
  syncTargetWorkspaceId: number
  timeFormat: string
}

export interface RatingCategory {
  scale: ScaleType
  displayType: DisplayType.Emotes | DisplayType.Numbers
  skippable: boolean
}

export interface SettingsUpdatePayload {
  channel: string
  frequency: NotificationFrequency
  notificationType: string
  userChangeAllowed: boolean
}

export interface UserGoalsPayload {
  goal: number
  goalType: string
}

export interface UpdateRatingScalePayload extends RatingCategory {
  cloneCategories: boolean
}

export interface SlackSettings {
  created: string
  id: number
  name: string
  ownerId: number
  ownerName: string
}
export interface SlackChannel {
  id: string
  name: string
  private: boolean
}

export interface AllowedAuthentications {
  allowGoogle: boolean
  allowMagicLink: boolean
  allowPassword: boolean
  allowSAML: boolean
  allowSlack: boolean
}
