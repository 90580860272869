import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { getScorecards, getCategories, getRootCauses } from '../api'

export default createSharedComposable(() => {
  const scorecardsQuery = useQuery({
    queryKey: ['settings-reviews-scorecards'],
    queryFn: getScorecards,
    refetchOnMount: false,
  })
  const categoriesQuery = useQuery({
    queryKey: ['settings-reviews-categories'],
    queryFn: getCategories,
    refetchOnMount: false,
  })
  const rootCausesQuery = useQuery({
    queryKey: ['settings-reviews-root-causes'],
    queryFn: getRootCauses,
    refetchOnMount: false,
  })

  return { scorecardsQuery, categoriesQuery, rootCausesQuery }
})
