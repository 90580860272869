import Pusher, { type ChannelAuthorizerGenerator, type Options } from 'pusher-js'
import ky from 'ky'

import config, { hasZendeskProxy } from '@/config'
import { session } from '@/composables/useSession'
import { prefixUrl } from '@/api'
import { getAccessToken } from '@/modules/auth/api'

const authorizerSpy = (isSpy = false): ChannelAuthorizerGenerator => {
  return (channel) => {
    return {
      authorize: async (socketId, callback) => {
        try {
          const headers: RequestInit['headers'] = {
            'X-Klaus-Workspace': session.workspace.id.toString(),
            'X-Klaus-Account': session.account.id.toString(),
          }

          if (!hasZendeskProxy) headers.Authorization = `Bearer ${await getAccessToken()}`

          const data = await ky
            .post(`${prefixUrl}/pusher/authorize-channel`, {
              headers,
              json: { socketId, channelName: channel.name, isSpy },
            })
            .json<any>()

          callback(null, data)
        } catch (err) {
          callback(new Error(`Error calling auth endpoint: ${err}`), { auth: '' })
        }
      },
    }
  }
}

const pusherOptions: Options = {
  authorizer: authorizerSpy(false),
  disableStats: true,
  cluster: 'eu',
}

export const pusherClient = new Pusher(config.pusherKey, pusherOptions)
export const pusherSpyClient = new Pusher(config.pusherKey, {
  ...pusherOptions,
  authorizer: authorizerSpy(true),
})
