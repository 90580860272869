export default {
  "auth": {
    "zendesk_sso": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ṡṵṵḅḍṓṓṃααḭḭṇ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṭṓṓ Żḛḛṇḍḛḛṡḳ龍ผู้]"])}
    },
    "account_exists": {
      "company_has_account_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ ḥααṡ ααṇ ḛḛẋḭḭṡṭḭḭṇḡ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "join_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṓṓḭḭṇ αα ͼṓṓṃṗααṇẏẏ龍ผู้]"])},
      "to_join_ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ĵṓṓḭḭṇ ααṇ ḛḛẋḭḭṡṭḭḭṇḡ ͼṓṓṃṗααṇẏẏ, ααṡḳ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ ṭṓṓ ṡḛḛṇḍ ẏẏṓṓṵṵ ααṇ ḭḭṇṽḭḭṭḛḛ.龍ผู้]"])},
      "ask_for_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḳ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ ṭṓṓ ḭḭṇṽḭḭṭḛḛ ẏẏṓṓṵṵ龍ผู้]"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ͼḥααṇḡḛḛḍ ṃẏẏ ṃḭḭṇḍ.龍ผู้]"])},
      "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ ṡḭḭḡṇ-ṵṵṗ龍ผู้]"])},
      "company_has_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ ḥααṡ ααṇ ḛḛẋḭḭṡṭḭḭṇḡ ααͼͼṓṓṵṵṇṭ. Ḍṓṓ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ĵṓṓḭḭṇ ẏẏṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ'ṡ ααͼͼṓṓṵṵṇṭ ṓṓṛ ͼṛḛḛααṭḛḛ αα ṇḛḛẁ ṓṓṇḛḛ?龍ผู้]"])},
      "copy_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ḭḭṇṡṭṛṵṵͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṛḛḛ'ṡ ααṇ ḛḛẋḭḭṡṭḭḭṇḡ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "blocked": {
      "disabled_access_to_your_account_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ'ṭ ααͼͼḛḛṡṡ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ ṵṵṇṭḭḭḽ ẏẏṓṓṵṵṛ ṓṓṵṵṭṡṭααṇḍḭḭṇḡ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṇṽṓṓḭḭͼḛḛṡ ααṛḛḛ ṗααḭḭḍ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṡ ṓṓṇ ḥṓṓḽḍ龍ผู้]"])}
    },
    "errors": {
      "account_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṇṓṓṭ ϝṓṓṵṵṇḍ. Ṫṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
      "bad_credentials_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḡḛḛṭ αα ṇḛḛẁ ṗααṡṡẁṓṓṛḍ.龍ผู้]"])},
      "bad_credentials_try_again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ααḡααḭḭṇ ṓṓṛ ", _interpolate(_named("link")), "龍ผู้]"])},
      "bad_credentials_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṛṓṓṇḡ ḛḛṃααḭḭḽ ααṇḍ ṗααṡṡẁṓṓṛḍ ͼṓṓṃḅḭḭṇααṭḭḭṓṓṇ.龍ผู้]"])},
      "expired_invite_request_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛʠṵṵḛḛṡṭ αα ṇḛḛẁ ṓṓṇḛḛ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ.龍ผู้]"])},
      "expired_invite_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṇṽḭḭṭḛḛ ḽḭḭṇḳ ḭḭṡ ḭḭṇṽααḽḭḭḍ ṓṓṛ ḥααṡ ḅḛḛḛḛṇ ṵṵṡḛḛḍ ααḽṛḛḛααḍẏẏ.龍ผู้]"])},
      "disabled_auth_method": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("method")), " ṡḭḭḡṇ-ḭḭṇ ṃḛḛṭḥṓṓḍ ḭḭṡ ḍḛḛααͼṭḭḭṽααṭḛḛḍ ḭḭṇ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "expired_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṇṽḭḭṭḛḛ ḭḭṡ ḭḭṇṽααḽḭḭḍ ṓṓṛ ṵṵṡḛḛḍ. Ṛḛḛʠṵṵḛḛṡṭ αα ṇḛḛẁ ṓṓṇḛḛ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ.龍ผู้]"])},
      "failed_to_load_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ ḭḭṇṽḭḭṭḛḛ ḭḭṇϝṓṓ.龍ผู้]"])},
      "invalid_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ṵṵṗ ϝααḭḭḽḛḛḍ. ṲṲṡḛḛṛ ẁḭḭṭḥ ṭḥḭḭṡ ḛḛṃααḭḭḽ ṃḭḭḡḥṭ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭ.龍ผู้]"])},
      "max_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ḥααṡ ḅḛḛḛḛṇ ḅḽṓṓͼḳḛḛḍ ααϝṭḛḛṛ ṃṵṵḽṭḭḭṗḽḛḛ ͼṓṓṇṡḛḛͼṵṵṭḭḭṽḛḛ ṡḭḭḡṇ-ḭḭṇ ααṭṭḛḛṃṗṭṡ. Ḉḥḛḛͼḳ ẏẏṓṓṵṵṛ ḭḭṇḅṓṓẋ ϝṓṓṛ ααṇ ḛḛṃααḭḭḽ ẁḭḭṭḥ ḭḭṇṡṭṛṵṵͼṭḭḭṓṓṇṡ ṓṓṇ ḥṓṓẁ ṭṓṓ ṵṵṇḅḽṓṓͼḳ ḭḭṭ.龍ผู้]"])},
      "unverified_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḛḛṛḭḭϝẏẏ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽ ϝḭḭṛṡṭ龍ผู้]"])}
    },
    "form": {
      "fields": {
        "email": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ αα ṽααḽḭḭḍ ḛḛṃααḭḭḽ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])}
        },
        "password": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])}
        }
      },
      "send_magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ龍ผู้]"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṭ龍ผู้]"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ龍ผู้]"])},
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ ͼṓṓḍḛḛ龍ผู้]"])},
      "send_magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ṭḥḛḛ ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ龍ผู้]"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
      "work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳ ḛḛṃααḭḭḽ龍ผู้]"])},
      "your_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ẁṓṓṛḳ ḛḛṃααḭḭḽ龍ผู้]"])}
    },
    "invite": {
      "main_service_agreement": {
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://www.zendesk.com/company/agreements-and-terms/main-services-agreement/龍ผู้]"])}
      },
      "main_services_agreement": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḭḭṇ Ṣḛḛṛṽḭḭͼḛḛṡ ḀḀḡṛḛḛḛḛṃḛḛṇṭ龍ผู้]"])}
      },
      "password": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ ḍṓṓḛḛṡ ṇṓṓṭ ṃααṭͼḥ ṛḛḛʠṵṵḭḭṛḛḛṃḛḛṇṭṡ龍ผู้]"])}
      },
      "privacy_notice": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααͼẏẏ Ṅṓṓṭḭḭͼḛḛ龍ผู้]"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://www.zendesk.com/company/agreements-and-terms/privacy-notice/龍ผู้]"])}
      },
      "hold_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓḽḍ ṵṵṗ龍ผู้]"])},
      "howdy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥḭḭ, ", _interpolate(_named("invitee_name")), "龍ผู้]"])},
      "join_organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ĵṓṓḭḭṇ ṭḥḛḛ ", _interpolate(_named("organization")), " ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ龍ผู้]"])}
    },
    "login": {
      "email_verified_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḛḛṃααḭḭḽ ḥααṡ ḅḛḛḛḛṇ ṽḛḛṛḭḭϝḭḭḛḛḍ. ŶŶṓṓṵṵ ṃααẏẏ ṇṓṓẁ ṡḭḭḡṇ ḭḭṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ṇḛḛẁ ααͼͼṓṓṵṵṇṭ ḅḛḛḽṓṓẁ.龍ผู้]"])},
      "google_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ ẁḭḭṭḥ Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "invalid_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽααḽḭḭḍ ḭḭṇṽḭḭṭḛḛ ḽḭḭṇḳ龍ผู้]"])},
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṵṵṡḭḭṇḡ ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭṵṵṛṇ ṭṓṓ ṡḭḭḡṇ ḭḭṇ龍ผู้]"])},
      "slack_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ ẁḭḭṭḥ Ṣḽααͼḳ龍ผู้]"])},
      "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ ẁḭḭṭḥ Żḛḛṇḍḛḛṡḳ龍ผู้]"])},
      "email_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḛḛṃααḭḭḽ ḥααṡ ḅḛḛḛḛṇ ṽḛḛṛḭḭϝḭḭḛḛḍ. ŶŶṓṓṵṵ ṃααẏẏ ṇṓṓẁ ṡḭḭḡṇ ḭḭṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ṇḛḛẁ ααͼͼṓṓṵṵṇṭ ḅḛḛḽṓṓẁ:龍ผู้]"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ẁḭḭṭḥ Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṽḭḭαα ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ẁḭḭṭḥ Ṣḽααͼḳ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṭṓṓ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
      "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇ'ṭ ḥααṽḛḛ ααṇ ααͼͼṓṓṵṵṇṭ?龍ผู้]"])}
    },
    "magic_link": {
      "code": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ αα ṽααḽḭḭḍ ͼṓṓḍḛḛ龍ผู้]"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])}
      },
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ αα ṽααḽḭḭḍ ḛḛṃααḭḭḽ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])}
      },
      "login_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ ͼṓṓḍḛḛ龍ผู้]"])},
      "login_traditionally": {
        "link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααḽḽ ṡḭḭḡṇ-ḭḭṇ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("link")), " ṽḭḭαα ṭṛααḍḭḭṭḭḭṓṓṇααḽ ṃḛḛṭḥṓṓḍṡ龍ผู้]"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ龍ผู้]"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṵṵṡḭḭṇḡ ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "email_with_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇ ḛḛṃααḭḭḽ ẁḥḭḭͼḥ ͼṓṓṇṭααḭḭṇṡ ẏẏṓṓṵṵṛ ṡḭḭḡṇ-ḭḭṇ ͼṓṓḍḛḛ.龍ผู้]"])},
      "email_without_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇ ḛḛṃααḭḭḽ ẁḥḭḭͼḥ ẏẏṓṓṵṵ ͼααṇ ϝṓṓḽḽṓṓẁ ṭṓṓ ṡḭḭḡṇ ḭḭṇ.龍ผู้]"])},
      "sending_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṛḛḛ ẁααṡ ααṇ ḛḛṛṛṓṓṛ ṡḛḛṇḍḭḭṇḡ ṭḥḛḛ ṃααḡḭḭͼ ḽḭḭṇḳ. Ṫṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṽḭḭαα ḛḛṃααḭḭḽ龍ผู้]"])}
    },
    "password_field": {
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "one_lower_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ḽṓṓẁḛḛṛͼααṡḛḛ ͼḥααṛααͼṭḛḛṛ龍ผู้]"])},
      "one_upper_case_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṵṵṗṗḛḛṛͼααṡḛḛ ͼḥααṛααͼṭḛḛṛ龍ผู้]"])},
      "rules_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṗααṡṡẁṓṓṛḍ ṃṵṵṡṭ ͼṓṓṇṭααḭḭṇ龍ผู้]"])},
      "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααẋḭḭṃṵṵṃ ṗααṡṡẁṓṓṛḍ ḽḛḛṇḡṭḥ ḭḭṡ 128 ͼḥααṛααͼṭḛḛṛṡ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααṡẏẏ ṭṓṓ ṛḛḛṃḛḛṃḅḛḛṛ, ḥααṛḍ ṭṓṓ ḡṵṵḛḛṡṡ龍ผู้]"])},
      "8_characters_minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍8 ͼḥααṛααͼṭḛḛṛṡ ṃḭḭṇḭḭṃṵṵṃ龍ผู้]"])},
      "create_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ẏẏṓṓṵṵṛ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛḡṓṓṭ ṗααṡṡẁṓṓṛḍ?龍ผู้]"])},
      "one_lower_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ḽṓṓẁḛḛṛ ͼααṡḛḛ ͼḥααṛααͼṭḛḛṛ龍ผู้]"])},
      "one_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṇṵṵṃḅḛḛṛ龍ผู้]"])},
      "one_upper_case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṵṵṗṗḛḛṛ ͼααṡḛḛ ͼḥααṛααͼṭḛḛṛ龍ผู้]"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])}
    },
    "password_reset": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṛḛḛṡḛḛṭ ẏẏṓṓṵṵṛ ṗααṡṡẁṓṓṛḍ, ḛḛṇṭḛḛṛ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽ ααḍḍṛḛḛṡṡ ααṇḍ ẁḛḛ'ḽḽ ṡḛḛṇḍ ẏẏṓṓṵṵ ααṇ ḛḛṃααḭḭḽ ẁḭḭṭḥ ḭḭṇṡṭṛṵṵͼṭḭḭṓṓṇṡ.龍ผู้]"])},
      "email": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ αα ṽααḽḭḭḍ ḛḛṃααḭḭḽ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])}
      },
      "reset_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṭ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "success": {
        "description_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṇ ḛḛṃααḭḭḽ ḥααṡ ḅḛḛḛḛṇ ṡḛḛṇṭ ṭṓṓ ", _interpolate(_named("email")), "龍ผู้]"])},
        "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ṭḥḛḛ ḛḛṃααḭḭḽ ḍṓṓḛḛṡṇ'ṭ ααṗṗḛḛααṛ ḭḭṇ ẏẏṓṓṵṵṛ ḭḭṇḅṓṓẋ ṓṓṛ ṡṗααṃ ϝṓṓḽḍḛḛṛ, ṗḽḛḛααṡḛḛ ͼḥḛḛͼḳ ṭḥḛḛ ḛḛṃααḭḭḽ ẏẏṓṓṵṵ ḛḛṇṭḛḛṛḛḛḍ.龍ผู้]"])},
        "return_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭṵṵṛṇ ṭṓṓ ṡḭḭḡṇ ḭḭṇ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥḛḛͼḳ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽ龍ผู้]"])}
      },
      "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṭ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "existing_password": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("bold")), ", ṭḥḛḛṇ ẏẏṓṓṵṵ'ḽḽ ḅḛḛ ṡḛḛṇṭ αα ṗααṡṡẁṓṓṛḍ ṛḛḛṡḛḛṭ ḛḛṃααḭḭḽ.龍ผู้]"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ḥααṡ ααṇ ḛḛẋḭḭṡṭḭḭṇḡ ṗααṡṡẁṓṓṛḍ龍ผู้]"])}
      },
      "reset_link": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬϝ ṭḥααṭ ", _interpolate(_named("bold")), ", ṭḥḛḛṇ ẁḛḛ'ṽḛḛ ṡḛḛṇṭ ẏẏṓṓṵṵ αα ṗααṡṡẁṓṓṛḍ ṛḛḛṡḛḛṭ ḽḭḭṇḳ.龍ผู้]"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṃααḭḭḽ ḭḭṡ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ααṇ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
      },
      "remembered_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃḛḛṃḅḛḛṛḛḛḍ ẏẏṓṓṵṵṛ ṗααṡṡẁṓṓṛḍ?龍ผู้]"])},
      "reset_link_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṛḛḛ ẁααṡ ααṇ ḛḛṛṛṓṓṛ ṡḛḛṇḍḭḭṇḡ ṭḥḛḛ ṛḛḛṡḛḛṭ ḽḭḭṇḳ. Ṫṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṭ ẏẏṓṓṵṵṛ ṗααṡṡẁṓṓṛḍ龍ผู้]"])}
    },
    "signup": {
      "magic_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ṵṵṡḭḭṇḡ ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "agreement": {
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααͼẏẏ Ṕṓṓḽḭḭͼẏẏ龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃẏẏ ṡḭḭḡṇḭḭṇḡ ṵṵṗ, ẏẏṓṓṵṵ ααḡṛḛḛḛḛ ṭṓṓ Żḛḛṇḍḛḛṡḳ ǪḀḀ ", _interpolate(_named("terms_of_service")), " ααṇḍ ", _interpolate(_named("privacy_policy")), "龍ผู้]"])},
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṛṃṡ ṓṓϝ Ṣḛḛṛṽḭḭͼḛḛ龍ผู้]"])}
      },
      "have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṽḛḛ ααṇ ααͼͼṓṓṵṵṇṭ?龍ผู้]"])},
      "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ṽḭḭαα ṃααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "signup_with_work_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ẁṓṓṛḳ ḛḛṃααḭḭḽ龍ผู้]"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ẁḭḭṭḥ Ṣḽααͼḳ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ṭṓṓ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ẁḭḭṭḥ Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "zendesk_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ḥααṽḛḛ ααṇ ααͼṭḭḭṽḛḛ Żḛḛṇḍḛḛṡḳ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "complete_setup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ ṓṓṛ ṡḭḭḡṇ ḭḭṇ ṭṓṓ ͼṓṓṃṗḽḛḛṭḛḛ ẏẏṓṓṵṵṛ ", _interpolate(_named("direct_install_name")), " ṡḛḛṭṵṵṗ龍ผู้]"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṡ ḭḭṭ ṛḛḛααḽḽẏẏ ẏẏṓṓṵṵ? Ḉḥḛḛͼḳ ẏẏṓṓṵṵṛ ḭḭṇḅṓṓẋ ϝṓṓṛ αα ͼṓṓṇϝḭḭṛṃααṭḭḭṓṓṇ ḛḛṃααḭḭḽ.龍ผู้]"])},
    "continue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṛḛḛ ααḽḽ ṡḛḛṭ ṵṵṗ龍ผู้]"])},
      "works_best_on_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ẁṓṓṛḳṡ ḅḛḛṡṭ ṓṓṇ ḍḛḛṡḳṭṓṓṗ. Ṣḭḭḡṇ ḭḭṇ ṭḥḛḛṛḛḛ ṭṓṓ ṡṭααṛṭ ṛḛḛṽḭḭḛḛẁḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
      "book_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṓṓḳ αα ḍḛḛṃṓṓ龍ผู้]"])},
      "or_book_demo_with_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṛ, ḅṓṓṓṓḳ αα ḍḛḛṃṓṓ ẁḭḭṭḥ ṵṵṡ ααṇḍ ẁḛḛ'ḽḽ ṡḥṓṓẁ ẏẏṓṓṵṵ ẁḥααṭ ẏẏṓṓṵṵ ͼααṇ ḍṓṓ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])}
    },
    "invite_existing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṗṛḛḛṡḛḛṇͼḛḛ ḭḭṡ ṛḛḛʠṵṵḛḛṡṭḛḛḍ龍ผู้]"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼααṇͼḛḛḽ龍ผู้]"])},
      "cancel_flow_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬ ͼḥααṇḡḛḛḍ ṃẏẏ ṃḭḭṇḍ ααṇḍ ẁααṇṭ ṭṓṓ ", _interpolate(_named("cancel")), "龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("account")), " ḭḭṇṽḭḭṭḛḛṡ ẏẏṓṓṵṵ ṭṓṓ ĵṓṓḭḭṇ ṭḥḛḛḭḭṛ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "primary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṓṓḭḭṇ ṭḥḛḛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṡḭḭḡṇḭḭṇḡ ḭḭṇ ααḡααḭḭṇ龍ผู้]"])}
  },
  "activity": {
    "reactions": {
      "emoji_alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṛḛḛααͼṭḭḭṓṓṇ ẏẏṓṓṵṵ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ: ", _interpolate(_named("shortcode")), "龍ผู้]"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "agents_reacted_to": {
        "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ͼṓṓṃṃḛḛṇṭ"]), _normalize(["Ṣḛḛṽḛḛṛααḽ ṗḛḛṓṓṗḽḛḛ ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])])},
        "reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ṛḛḛṗḽẏẏ"]), _normalize(["Ṣḛḛṽḛḛṛααḽ ṗḛḛṓṓṗḽḛḛ ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ṛḛḛṗḽẏẏ龍ผู้]"])])},
        "review": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ṛḛḛṽḭḭḛḛẁ"]), _normalize(["Ṣḛḛṽḛḛṛααḽ ṗḛḛṓṓṗḽḛḛ ṛḛḛααͼṭḛḛḍ ṭṓṓ ẏẏṓṓṵṵṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṗḽẏẏ龍ผู้]"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
    },
    "subnav": {
      "disputes_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḌḬḬṢṔṲṲṪḚḚṢ龍ผู้]"])},
      "given_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḠḬḬṾḚḚṄ龍ผู้]"])},
      "received_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṚḚḚḈḚḚḬḬṾḚḚḌ龍ผู้]"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
      "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭḛḛḍ龍ผู้]"])}
    },
    "mark_all_read": {
      "prompt_title": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳ ͼṓṓṃṃḛḛṇṭ ααṡ ṛḛḛααḍ?"]), _normalize(["Ṁααṛḳ ααḽḽ ͼṓṓṃṃḛḛṇṭṡ ααṡ ṛḛḛααḍ?龍ผู้]"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳ ḍḭḭṡṗṵṵṭḛḛ ααṡ ṛḛḛααḍ?"]), _normalize(["Ṁααṛḳ ααḽḽ ḍḭḭṡṗṵṵṭḛḛṡ ααṡ ṛḛḛααḍ?龍ผู้]"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ααṡ ṛḛḛααḍ?"]), _normalize(["Ṁααṛḳ ααḽḽ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ααṡ ṛḛḛααḍ?龍ผู้]"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳ ṛḛḛṽḭḭḛḛẁ ααṡ ṛḛḛααḍ?"]), _normalize(["Ṁααṛḳ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ ααṡ ṛḛḛααḍ?龍ผู้]"])])}
      },
      "success": {
        "comments": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳḛḛḍ ͼṓṓṃṃḛḛṇṭ ααṡ ṛḛḛααḍ"]), _normalize(["Ṁααṛḳḛḛḍ ααḽḽ ͼṓṓṃṃḛḛṇṭṡ ααṡ ṛḛḛααḍ龍ผู้]"])])},
        "disputes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳḛḛḍ ḍḭḭṡṗṵṵṭḛḛ ααṡ ṛḛḛααḍ"]), _normalize(["Ṁααṛḳḛḛḍ ααḽḽ ḍḭḭṡṗṵṵṭḛḛṡ ααṡ ṛḛḛααḍ龍ผู้]"])])},
        "feedback": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳḛḛḍ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ααṡ ṛḛḛααḍ"]), _normalize(["Ṁααṛḳḛḛḍ ααḽḽ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ααṡ ṛḛḛααḍ龍ผู้]"])])},
        "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṛḳḛḛḍ ṛḛḛṽḭḭḛḛẁ ααṡ ṛḛḛααḍ"]), _normalize(["Ṁααṛḳḛḛḍ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ ααṡ ṛḛḛααḍ龍ผู้]"])])}
      },
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααḽḽ ααṡ ṛḛḛααḍ龍ผู้]"])},
      "prompt_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ṛḛḛααḍ龍ผู้]"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
    },
    "empty_states": {
      "disputes_all": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ṡṓṓṃḛḛṓṓṇḛḛ ḍḭḭṡṗṵṵṭḛḛṡ αα ṛḛḛṽḭḭḛḛẁ, ḭḭṭ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      },
      "disputes_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ẏẏṓṓṵṵ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ṛḛḛṡṓṓḽṽḛḛ αα ḍḭḭṡṗṵṵṭḛḛ, ḭḭṭ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      },
      "disputes_started": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ẏẏṓṓṵṵ ḍḭḭṡṗṵṵṭḛḛ αα ṛḛḛṽḭḭḛḛẁ, ḭḭṭ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṭααṛṭḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      },
      "feedback_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ϝḛḛḛḛḍḅααͼḳ ḡḭḭṽḛḛṇ ṭṓṓ ẏẏṓṓṵṵ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ. Ṅṓṓṭḥḭḭṇḡ ṭṓṓ ṡḥṓṓẁ ĵṵṵṡṭ ẏẏḛḛṭ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])}
      },
      "reactions_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛααͼṭḭḭṓṓṇṡ ḡḭḭṽḛḛṇ ṭṓṓ ẏẏṓṓṵṵṛ ṛḛḛṽḭḭḛḛẁṡ ααṇḍ ṛḛḛṗḽḭḭḛḛṡ ααṗṗḛḛααṛ ḥḛḛṛḛḛ. Ṅṓṓṭḥḭḭṇḡ ṭṓṓ ṡḥṓṓẁ ĵṵṵṡṭ ẏẏḛḛṭ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛααͼṭḭḭṓṓṇṡ龍ผู้]"])}
      },
      "reviews_given": {
        "open_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ϝḛḛḛḛḍḅααͼḳ ḡḭḭṽḛḛṇ ḅẏẏ ẏẏṓṓṵṵ ḛḛṇḍṡ ṵṵṗ ḥḛḛṛḛḛ. Ṣṭααṛṭ ḅẏẏ ḽḛḛααṽḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ ḭḭṇ ṭḥḛḛ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṽḭḭḛḛẁ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "reviews_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ϝḛḛḛḛḍḅααͼḳ ḡḭḭṽḛḛṇ ṭṓṓ ẏẏṓṓṵṵ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ. Ṅṓṓṭḥḭḭṇḡ ṭṓṓ ṡḥṓṓẁ ĵṵṵṡṭ ẏẏḛḛṭ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "comments_given": {
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṃṃḛḛṇṭṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ϝḛḛḛḛḍḅααͼḳ ẏẏṓṓṵṵ ḽḛḛααṽḛḛ ααṡ ͼṓṓṃṃḛḛṇṭṡ ααṗṗḛḛααṛ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡḭḭṽḛḛṇ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])}
      },
      "comments_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṃṃḛḛṇṭṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "comments_received": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼṓṓṃṃḛḛṇṭṡ ṵṵṇḍḛḛṛ αα ṛḛḛṽḭḭḛḛẁ ẏẏṓṓṵṵ ṓṓẁṇ, ", "@", "ṃḛḛṇṭḭḭṓṓṇṡ, ṓṓṛ ṛḛḛṗḽḭḭḛḛṡ ṭṓṓ ẏẏṓṓṵṵṛ ͼṓṓṃṃḛḛṇṭṡ ααṗṗḛḛααṛ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])}
      },
      "disputes_filter": {
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼͼḛḛṗṭḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṓṓṗḛḛṇ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛĵḛḛͼṭḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṓṓḽṽḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      },
      "filter_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ααḡααḭḭṇ ḅẏẏ ͼḥṓṓṓṓṡḭḭṇḡ ααṇṓṓṭḥḛḛṛ ṓṓṗṭḭḭṓṓṇ ϝṛṓṓṃ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ααḅṓṓṽḛḛ.龍ผู้]"])},
      "user_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ααḡααḭḭṇ ḅẏẏ ͼḥṓṓṓṓṡḭḭṇḡ ααṇṓṓṭḥḛḛṛ ṵṵṡḛḛṛ ϝṛṓṓṃ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ααḅṓṓṽḛḛ.龍ผู้]"])}
    },
    "comments": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])}
    },
    "disputes": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭḛḛḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])}
    },
    "feedback": {
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])}
    },
    "reviews": {
      "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ααͼṭḭḭṽḭḭṭẏẏ ḽḭḭṡṭ龍ผู้]"])}
  },
  "assignments": {
    "filters": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
    },
    "counts": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_list(0)), " / ", _interpolate(_list(1)), " ṛḛḛṽḭḭḛḛẁḛḛḍ"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ṛḛḛṽḭḭḛḛẁḛḛḍ"]), _normalize([_interpolate(_list(0)), " / ", _interpolate(_list(1)), " ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])])},
    "restarts_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṭααṛṭṡ ḭḭṇ ", _interpolate(_named("time")), "龍ผู้]"])},
    "time_left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("time")), " ḽḛḛϝṭ龍ผู้]"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṵṵṗ ααṇ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
    "ticket_list": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ααṡṡḭḭḡṇṃḛḛṇṭṡ ḽḭḭṡṭ龍ผู้]"])}
  },
  "coaching": {
    "sessions": {
      "landing_page": {
        "coachee_score_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
        "error": {
          "workspace_intersection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḬḬṭ'ṡ ṇṓṓṭ ṗṓṓṡṡḭḭḅḽḛḛ ṭṓṓ ͼṛḛḛααṭḛḛ αα ͼṓṓααͼḥḭḭṇḡ ṡḛḛṡṡḭḭṓṓṇ ḅḛḛṭẁḛḛḛḛṇ ", _interpolate(_list(0)), " ααṇḍ ", _interpolate(_list(1)), " ααṡ ṭḥḛḛẏẏ ααṛḛḛ ͼṵṵṛṛḛḛṇṭḽẏẏ ṇṓṓṭ ḭḭṇ ṭḥḛḛ ṡααṃḛḛ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
          "sessions_exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ṡḛḛṡṡḭḭṓṓṇṡ ḅḛḛṭẁḛḛḛḛṇ ", _interpolate(_list(0)), " ααṇḍ ", _interpolate(_list(1)), " ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭ. Ḉṓṓṇṭααͼṭ ", _interpolate(_list(0)), " ṭṓṓ ḡḛḛṭ ααͼͼḛḛṡṡ ṭṓṓ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])}
        },
        "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḡḡḛḛṡṭḛḛḍ龍ผู้]"])},
        "toast_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀ ṇḛḛẁ ṡḛḛṡṡḭḭṓṓṇ ḅḛḛṭẁḛḛḛḛṇ ", _interpolate(_list(0)), " ααṇḍ ", _interpolate(_list(1)), " ḥααṡ ḅḛḛḛḛṇ ͼṛḛḛααṭḛḛḍ.龍ผู้]"])},
        "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥ龍ผู้]"])},
        "coach_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓααͼḥ龍ผู้]"])},
        "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḛḛḛḛ龍ผู้]"])},
        "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓααͼḥḛḛḛḛ龍ผู้]"])},
        "create_session_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "create_session_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "create_session_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṭααḭḭḽṡ龍ผู้]"])},
        "new_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
        "session_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ龍ผู้]"])},
        "session_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇ ṇααṃḛḛ龍ผู้]"])},
        "suggested_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḡḡḛḛṡṭḛḛḍ龍ผู้]"])}
      },
      "sessions_list": {
        "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛḍṵṵḛḛ龍ผู้]"])},
        "past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṭ龍ผู้]"])},
        "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗͼṓṓṃḭḭṇḡ龍ผู้]"])},
        "create_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
      },
      "sidebar": {
        "metrics": {
          "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
          "duration_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
          "iqs_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
          "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṛḛḛṽḭḭḛḛẁ ṭḭḭṃḛḛ龍ผู้]"])},
          "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ龍ผู้]"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍααṭαα ṭṓṓ ṡḥṓṓẁ ϝṓṓṛ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])},
          "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
          "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
        },
        "pins": {
          "add_to_session": {
            "success_with_cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀḍḍḛḛḍ ṗḭḭṇ ṭṓṓ ṡḛḛṡṡḭḭṓṓṇ. ", _interpolate(_named("buttonStart")), "Ṣḥṓṓẁ ṃḛḛ", _interpolate(_named("buttonEnd")), ".龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ...龍ผู้]"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍḛḛḍ龍ผู้]"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ḛḛṇḍ ṓṓϝ ṗḭḭṇṡ ϝṛṓṓṃ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])},
          "empty_state": {
            "hint": {
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ḥṓṓẁ ṗḭḭṇṡ ẁṓṓṛḳ ḭḭṇ ṭḥḭḭṡ ", _interpolate(_named("link")), ".龍ผู้]"])},
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṛṭḭḭͼḽḛḛ龍ผู้]"])}
            },
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḻṓṓṓṓḳṡ ḽḭḭḳḛḛ ẏẏṓṓṵṵ ḥααṽḛḛṇ’ṭ ṗḭḭṇṇḛḛḍ ααṇẏẏṭḥḭḭṇḡ ṵṵṇḍḛḛṛ ", _interpolate(_list(0)), ". ŶŶṓṓṵṵ ͼααṇ ṵṵṡḛḛ ṗḭḭṇṡ ϝṓṓṛ ṗṛḛḛṗααṛḭḭṇḡ ϝṓṓṛ 1:1’ṡ ṓṓṛ ṡααṽḭḭṇḡ ṡṓṓṃḛḛṭḥḭḭṇḡ ϝṓṓṛ ααṇṓṓṭḥḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
            "coachee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḛḛ ͼṓṓααͼḥḛḛḛḛ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗḭḭṇṡ ααḍḍḛḛḍ ẏẏḛḛṭ龍ผู้]"])}
          },
          "info": {
            "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥḛḛṛḛḛ ααṛḛḛ ααḽḽ ṭḥḛḛ ṗḭḭṇṡ ẏẏṓṓṵṵ ḥααṽḛḛ ͼṛḛḛααṭḛḛḍ ṵṵṇḍḛḛṛ ", _interpolate(_named("name")), ". Ṕḭḭṇṡ ααḍḍḛḛḍ ṭṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ẁḭḭḽḽ ḅḛḛ ", _interpolate(_named("bold")), " ẁḭḭṭḥ ṭḥḛḛ ṓṓṭḥḛḛṛ ṗααṛṭḭḭͼḭḭṗααṇṭ.龍ผู้]"])},
            "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṃṃḛḛḍḭḭααṭḛḛḽẏẏ ṡḥααṛḛḛḍ龍ผู้]"])}
          }
        },
        "feedback": {
          "empty_state": {
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḻṓṓṓṓḳṡ ḽḭḭḳḛḛ ", _interpolate(_list(0)), " ḥααṡṇ'ṭ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇẏẏ ϝḛḛḛḛḍḅααͼḳ ϝṓṓṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁ ṓṓṛ ḈṢḀḀṪ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṗḛḛṛḭḭṓṓḍ.龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ϝḛḛḛḛḍḅααͼḳ ααṽααḭḭḽααḅḽḛḛ龍ผู้]"])}
          },
          "end_of_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ’ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ḛḛṇḍ ṓṓϝ ͼṓṓṃṃḛḛṇṭṡ ϝṛṓṓṃ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])}
        },
        "tabs": {
          "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṭṛḭḭͼṡ龍ผู้]"])},
          "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "'ṡ ṇṵṵṃḅḛḛṛṡ龍ผู้]"])}
      },
      "thread": {
        "new_action_item": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ααͼṭḭḭṓṓṇ ḭḭṭḛḛṃ龍ผู้]"])}
        },
        "actions": {
          "completed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗḽḛḛṭḛḛḍ ḅẏẏ ", _interpolate(_named("user")), " ṓṓṇ ", _interpolate(_named("date")), "龍ผู้]"])},
          "created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḅẏẏ ", _interpolate(_list(0)), "龍ผู้]"])},
          "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏṓṓṵṵ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ ṭṓṓ ααḍḍ αα ṇḛḛẁ ααͼṭḭḭṓṓṇ ḭḭṭḛḛṃ龍ผู้]"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥααṭ ͼααṃḛḛ ṓṓṵṵṭ ṓṓϝ ṭḥḭḭṡ ṃḛḛḛḛṭḭḭṇḡ? Ŵḥααṭ ααṛḛḛ ẏẏṓṓṵṵṛ ṇḛḛẋṭ ṡṭḛḛṗṡ?龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṓṓṇ ḭḭṭḛḛṃṡ龍ผู้]"])}
        },
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ḅẏẏ ", _interpolate(_named("name")), " ṓṓṇ ", _interpolate(_named("createdDate")), "龍ผู้]"])},
        "delete_body": {
          "delete_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ", _interpolate(_named("deleteSession")), ". Ṕααṛṭḭḭͼḭḭṗααṇṭṡ ḽṓṓṡḛḛ ααͼͼḛḛṡṡ ṭṓṓ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ, ḭḭṇͼḽṵṵḍḭḭṇḡ ḭḭṭṡ ͼṓṓṇṭḛḛṇṭṡ ααṇḍ ṗṛḭḭṽααṭḛḛ ṇṓṓṭḛḛṡ.龍ผู้]"])}
        },
        "session_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇ ͼṛḛḛααṭḛḛḍ ṓṓṇ ", _interpolate(_named("date")), "龍ผู้]"])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ḛḛḍḭḭṭḛḛḍ ḅẏẏ ", _interpolate(_named("name")), " ṓṓṇ ", _interpolate(_named("updatedDate")), "龍ผู้]"])},
        "notes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḽḳḭḭṇḡ ṗṓṓḭḭṇṭṡ ααṇḍ ṇṓṓṭḛḛṡ龍ผู้]"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛ ṵṵṗḍααṭḛḛḍ龍ผู้]"])}
        },
        "share": {
          "leave_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛ龍ผู้]"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭḛḛḍ ṵṵṡḛḛṛṡ ḡααḭḭṇ ααͼͼḛḛṡṡ ṭṓṓ ṡḛḛṡṡḭḭṓṓṇ ḍḛḛṭααḭḭḽṡ ϝṛṓṓṃ ṭḥḭḭṡ ṗṓṓḭḭṇṭ ṓṓṇẁααṛḍṡ.龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛṡ龍ผู้]"])},
          "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛḍ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])}
        },
        "toast": {
          "leave_session": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ẁḭḭḽḽ ṇṓṓ ḽṓṓṇḡḛḛṛ ḅḛḛ αα ṗααṛṭ ṓṓϝ ", _interpolate(_list(0)), " - ", _interpolate(_list(1)), " ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
          "participant_removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ẁḭḭḽḽ ṇṓṓ ḽṓṓṇḡḛḛṛ ḅḛḛ αα ṗααṛṭ ṓṓϝ ṭḥḭḭṡ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
          "participants_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("name")), " ḥααṡ ααͼͼḛḛṡṡ ṭṓṓ ṭḥḭḭṡ ṡḛḛṡṡḭḭṓṓṇ ṇṓṓẁ."]), _normalize([_interpolate(_named("n")), " ṇḛḛẁ ṵṵṡḛḛṛṡ ḥααṽḛḛ ααͼͼḛḛṡṡ ṭṓṓ ṭḥḭḭṡ ṡḛḛṡṡḭḭṓṓṇ ṇṓṓẁ龍ผู้]"])])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααḽḽ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
        "note_author_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḛḛ ͼṓṓααͼḥ龍ผู้]"])},
        "note_placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛṡ ααḍḍḛḛḍ ḅẏẏ ", _interpolate(_list(0)), " ẁḭḭḽḽ ααṗṗḛḛααṛ ḥḛḛṛḛḛ.龍ผู้]"])},
        "pins": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṗḭḭṇṇḛḛḍ ḭḭṭḛḛṃṡ ααṡ ḍḭḭṡͼṵṵṡṡḭḭṓṓṇ ṗṓṓḭḭṇṭṡ ϝṛṓṓṃ ṭḥḛḛ ṛḭḭḡḥṭ-ḥααṇḍ ṡḭḭḍḛḛ ṗααṇḛḛḽ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ龍ผู้]"])}
        },
        "private_notes": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛṡ ṓṓṇḽẏẏ ẏẏṓṓṵṵ ͼααṇ ṡḛḛḛḛ...龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽ ṇṓṓṭḛḛṡ龍ผู้]"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααṭḛḛ ṇṓṓṭḛḛ ṵṵṗḍααṭḛḛḍ龍ผู้]"])}
        },
        "stats": {
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍30 ḍααẏẏṡ龍ผู้]"])}
        }
      },
      "insights": {
        "overview_card": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇḛḛ龍ผู้]"])},
          "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥ龍ผู้]"])},
          "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḛḛḛḛ龍ผู้]"])},
          "action_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṓṓṇ ḭḭṭḛḛṃṡ龍ผู้]"])},
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "statuses": {
            "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇḛḛ龍ผู้]"])},
            "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḡṓṓḭḭṇḡ龍ผู้]"])},
            "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛḍṵṵḛḛ龍ผู้]"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ ṓṓṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])},
          "view_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ααḽḽ龍ผู้]"])}
        },
        "action_items": {
          "filters": {
            "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛ: Ḉṓṓααͼḥ龍ผู้]"])},
            "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛ: Ḉṓṓααͼḥḛḛḛḛ龍ผู้]"])},
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
            "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛḍ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
          },
          "empty": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṡẁḭḭṭͼḥḭḭṇḡ αα ϝḭḭḽṭḛḛṛ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ṡḛḛḛḛ ααͼṭḭḭṓṓṇ ḭḭṭḛḛṃṡ.龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼṭḭḭṓṓṇ ḭḭṭḛḛṃṡ龍ผู้]"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṓṓṇ ḭḭṭḛḛṃṡ龍ผู้]"])}
        },
        "progress_card": {
          "legend": {
            "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
            "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇḍ ṡḛḛḛḛṇ龍ผู้]"])},
            "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])}
          },
          "tooltip": {
            "iqs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
            "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ · Ṣḛḛḛḛṇ龍ผู้]"])},
            "csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓḡṛḛḛṡṡ ααϝṭḛḛṛ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
      },
      "leaderboard": {
        "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛṃṡ ṭḥααṭ ṭḥḛḛ ααḡḛḛṇṭṡ ḥααṽḛḛṇ’ṭ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇẏẏ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
        "empty_search_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḛḛṛḭḭϝẏẏ ṭḥααṭ ẏẏṓṓṵṵ ḥααṽḛḛ ṭḥḛḛ ͼṓṓṛṛḛḛͼṭ ṇααṃḛḛ ṓṓϝ ṭḥḛḛ ṵṵṡḛḛṛ龍ผู้]"])},
        "empty_search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ϝḭḭṇḍ ṭḥααṭ ṵṵṡḛḛṛ龍ผู้]"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍααṭαα ααṽααḭḭḽααḅḽḛḛ龍ผู้]"])},
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ 30 ḍααẏẏṡ ḬḬǪṢ龍ผู้]"])}
      },
      "list": {
        "delete_all_prompt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ <b>ḍḛḛḽḛḛṭḛḛ ααḽḽ ṡḛḛṡṡḭḭṓṓṇṡ</b> (2) ẁḭḭṭḥ <b>", _interpolate(_named("name")), "</b>. Ṕααṛṭḭḭͼḭḭṗααṇṭṡ ḽṓṓṡḛḛ ααͼͼḛḛṡṡ ṭṓṓ ααḽḽ ṡḛḛṡṡḭḭṓṓṇṡ, ḭḭṇͼḽṵṵḍḭḭṇḡ ṭḥḛḛ ͼṓṓṇṭḛḛṇṭṡ ααṇḍ ṗṛḭḭṽααṭḛḛ ṇṓṓṭḛḛṡ.龍ผู้]"])},
        "end_of_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ḛḛṇḍ ṓṓϝ ααḽḽ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "empty": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ͼṓṓααͼḥḭḭṇḡ ṡḛḛṡṡḭḭṓṓṇ ḭḭṡ αα ḡṛṓṓṵṵṗ ααͼṭḭḭṽḭḭṭẏẏ ḅḛḛṭẁḛḛḛḛṇ αα ṃḛḛṇṭṓṓṛ ααṇḍ αα ͼṓṓααͼḥḛḛḛḛ ṭṓṓ ḍḭḭṡͼṵṵṡṡ ṗḛḛṛϝṓṓṛṃααṇͼḛḛ, ṓṓṗṗṓṓṛṭṵṵṇḭḭṭḭḭḛḛṡ, ṡḥṓṓṛṭ ααṇḍ ḽṓṓṇḡ ṭḛḛṛṃ ḡṓṓααḽṡ.龍ผู้]"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṡḛḛααṛͼḥ ṡḛḛṡṡḭḭṓṓṇṡ ḅẏẏ ḅṓṓṭḥ ṭḥḛḛ ͼṓṓααͼḥ'ṡ ṇααṃḛḛ ααṇḍ ṭḥḛḛ ṗḛḛṛṡṓṓṇ ḅḛḛḭḭṇḡ ͼṓṓααͼḥḛḛḍ.龍ผู้]"])},
          "search_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ͼṛḛḛααṭḛḛḍ ẏẏḛḛṭ龍ผู้]"])}
        },
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓααͼḥḭḭṇḡ ḥḭḭṡṭṓṓṛẏẏ龍ผู้]"])},
        "delete_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓααͼḥḭḭṇḡ ḥḭḭṡṭṓṓṛẏẏ 龍ผู้]"])},
        "heading": {
          "last_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
      },
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥ龍ผู้]"])},
      "coachee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḛḛḛḛ龍ผู้]"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇḛḛ龍ผู้]"])},
      "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ḍṓṓṇḛḛ龍ผู้]"])},
      "mark_as_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ṵṵṇḍṓṓṇḛḛ龍ผู้]"])},
      "new_thread": {
        "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ṇααṃḛḛ龍ผู้]"])}
      },
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ龍ผู้]"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṇααṃḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ṡḛḛṡṡḭḭṓṓṇ ḽḭḭṡṭ龍ผู้]"])},
      "unscheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡͼḥḛḛḍṵṵḽḛḛḍ龍ผู้]"])}
    },
    "sidebar": {
      "reply_placeholder_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽẏẏ ṭṓṓ ṭḥḭḭṡ ṃḛḛṡṡααḡḛḛ龍ผู้]"])}
    },
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])}
  },
  "conversations": {
    "calibration_sidebar": {
      "review_disabled_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḭḭṛḛḛḍ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ. Ṅṓṓ ααḍḍḭḭṭḭḭṓṓṇααḽ ṛḛḛṽḭḭḛḛẁṡ ͼααṇ ḅḛḛ ḽḛḛϝṭ.龍ผู้]"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ααḽṛḛḛααḍẏẏ ḽḛḛϝṭ αα ṛḛḛṽḭḭḛḛẁ ϝṓṓṛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
      "empty_state": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḭḭṇḡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ṇṓṓ ḽṓṓṇḡḛḛṛ ṗṓṓṡṡḭḭḅḽḛḛ ααṡ ṭḥḛḛ ḍṵṵḛḛ ḍααṭḛḛ ṓṓϝ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ ḥααṡ ṗααṡṡḛḛḍ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ṇṓṓ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "close_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḛḛ ṗααṇḛḛḽ龍ผู้]"])},
      "feedback_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
    },
    "call_transcript": {
      "details_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḌḚḚṪḀḀḬḬḺṢ龍ผู้]"])},
      "summary_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢṲṲṀṀḀḀṚŶŶ龍ผู้]"])},
      "transcript_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṪṚḀḀṄṢḈṚḬḬṔṪ龍ผู้]"])},
      "speaker_alias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛααḳḛḛṛ ", _interpolate(_list(0)), "龍ผู้]"])},
      "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ龍ผู้]"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ龍ผู้]"])},
      "dead_air": {
        "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "ṡ龍ผู้]"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛααḍ ααḭḭṛ龍ผู้]"])}
      },
      "edit_speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṡṗḛḛααḳḛḛṛ龍ผู้]"])},
      "message_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
      "return_to_playing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭṵṵṛṇ ṭṓṓ ṗḽααẏẏḭḭṇḡ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
      "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛααḳḛḛṛṡ龍ผู้]"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡͼṛḭḭṗṭ龍ผู้]"])},
      "ws_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])}
    },
    "filters": {
      "add_connection_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ϝḭḭṛṡṭ ṭṓṓ ͼṛḛḛααṭḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "auto_qa": {
        "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
      },
      "form": {
        "options": {
          "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
          "bots_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḅṓṓṭṡ龍ผู้]"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])}
        },
        "filter_created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ααṭ ", _interpolate(_named("time")), " ḅẏẏ ", _interpolate(_named("agentName")), "龍ผู้]"])},
        "filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛḍ ṓṓṇ ", _interpolate(_named("time")), " ḅẏẏ ", _interpolate(_named("agentName")), "龍ผู้]"])},
        "no_filters_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻṓṓṓṓḳṡ ḽḭḭḳḛḛ ẏẏṓṓṵṵ ḥααṽḛḛ ṇṓṓ ϝḭḭḽṭḛḛṛṡ. Ṫṓṓ ααͼͼḛḛṡṡ ṭḥḭḭṡ ṗααḡḛḛ, ẏẏṓṓṵṵ ϝḭḭṛṡṭ ṇḛḛḛḛḍ ṭṓṓ ͼṛḛḛααṭḛḛ αα ϝḭḭḽṭḛḛṛ. Ṣṭααṛṭ ẁḭḭṭḥ ", _interpolate(_named("recommendedFilter")), " ṭṓṓ ṡḛḛḛḛ ẏẏṓṓṵṵṛ ṓṓẁṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. Ḉṛḛḛααṭḛḛ ṭḥḭḭṡ ϝḭḭḽṭḛḛṛ ḅḛḛḽṓṓẁ ṭṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ.龍ผู้]"])},
        "over_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṽḛḛṛ ṭḥḛḛ ḽααṡṭ 30 ḍααẏẏṡ龍ผู้]"])},
        "timeframe_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍\"Ḉṛḛḛααṭḛḛḍ ααṭ ḽααṡṭ 30 ḍααẏẏṡ\" ͼṓṓṇḍḭḭṭḭḭṓṓṇ ẁḭḭḽḽ ḅḛḛ ααḍḍḛḛḍ ṭṓṓ ϝḭḭḽṭḛḛṛṡ ͼṛḛḛααṭḛḛḍ ẁḭḭṭḥṓṓṵṵṭ αα ḍααṭḛḛ ṛααṇḡḛḛ龍ผู้]"])},
        "downgraded_filter_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛ ḭḭṡ ṇṓṓṭ ααṽααḭḭḽααḅḽḛḛ ḭḭṇ ṭḥḭḭṡ ṗḽααṇ ααṇḍ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ṭααḳḛḛṇ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ ẁḥḛḛṇ ḍḭḭṡṗḽααẏẏḭḭṇḡ ṛḛḛṡṵṵḽṭṡ龍ผู้]"])},
        "loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛṭͼḥḭḭṇḡ ϝḭḭḽṭḛḛṛ ṓṓṗṭḭḭṓṓṇṡ ṭḭḭṃḛḛḍ ṓṓṵṵṭ. Ṫṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ϝḭḭḽṭḛḛṛḭḭṇḡ龍ผู้]"])},
        "recommended_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ = ṡḭḭḡṇḛḛḍ-ḭḭṇ ṗḛḛṛṡṓṓṇ龍ผู้]"])},
        "ticket_count_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṓṓṵṵṇḍ: ", _interpolate(_named("count"))]), _normalize(["Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṵṵṇḍ: ", _interpolate(_named("count"))]), _normalize(["Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṓṓṵṵṇḍ: ", _interpolate(_named("count")), "龍ผู้]"])])},
        "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
        "value_outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ααṽααḭḭḽααḅḽḛḛ ḭḭṇ ẏẏṓṓṵṵṛ ͼṵṵṛṛḛḛṇṭ ṗḽααṇ. ṲṲṗḡṛααḍḛḛ ṭṓṓ ṵṵṡḛḛ ṭḥḭḭṡ ϝḛḛααṭṵṵṛḛḛ.龍ผู้]"])},
        "add_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
        "ai_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃẏẏ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḀḀḬḬ龍ผู้]"])},
        "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
        "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṵṵṛṇ ḭḭṇṭṓṓ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "create_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
        "create_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
        "custom_fields_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "filters_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])},
        "loading_options_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛ'ṛḛḛ ḽṓṓααḍḭḭṇḡ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ṓṓṗṭḭḭṓṓṇṡ ααͼͼṓṓṛḍḭḭṇḡ ṭṓṓ ẏẏṓṓṵṵṛ ṡṗḛḛͼḭḭϝḭḭͼ ṡḛḛṭṵṵṗ. Ṫḥḭḭṡ ṃααẏẏ ṭααḳḛḛ αα ͼṓṓṵṵṗḽḛḛ ṃḭḭṇṵṵṭḛḛṡ ṓṓṇ ḭḭṇḭḭṭḭḭααḽ ḽṓṓααḍ.龍ผู้]"])},
        "name_field_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ αα ḍḛḛṡͼṛḭḭṗṭḭḭṽḛḛ ṇααṃḛḛ ṭṓṓ ẏẏṓṓṵṵṛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
        "name_field_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛ ṇααṃḛḛ龍ผู้]"])},
        "option_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṇ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])},
        "option_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
        "private_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααṭḛḛ龍ผู้]"])},
        "public_option_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭͼ龍ผู้]"])},
        "update_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
        "update_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ͼḥααṇḡḛḛṡ龍ผู้]"])},
        "value_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṽααḽṵṵḛḛṡ ααṽααḭḭḽααḅḽḛḛ龍ผู้]"])},
        "value_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ...龍ผู้]"])},
        "value_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṽααḽṵṵḛḛ龍ผู้]"])},
        "visibility_toggle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḭḭḽḭḭṭẏẏ龍ผู้]"])}
      },
      "calibrations": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ααͼṭḭḭṽḛḛ ṡḛḛṡṡḭḭṓṓṇṡ ↑龍ผู้]"])},
        "older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṓṓḽḍḛḛṛ ṡḛḛṡṡḭḭṓṓṇṡ ↓龍ผู้]"])},
        "create_modal": {
          "date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵḛḛ ḍααṭḛḛ龍ผู้]"])},
          "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])}
        },
        "delete_modal": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ ẁḭḭḽḽ ḅḛḛ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛḍ.龍ผู้]"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ", _interpolate(_named("sessionName")), " ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])}
        },
        "empty_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ḥααṽḛḛ ḅḛḛḛḛṇ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "no_active_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼṭḭḭṽḛḛ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "no_visible_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼṭḭḭṽḛḛ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])}
      },
      "add_filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
      "add_inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ龍ผู้]"])},
      "add_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṗṛḭḭṽααṭḛḛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
      "add_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṗṵṵḅḽḭḭͼ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
      "add_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛ ααḍḍḛḛḍ龍ผู้]"])},
      "clone_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
      "delete_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ龍ผู้]"])},
      "delete_modal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ϝḭḭḽṭḛḛṛ ẁḭḭḽḽ ḅḛḛ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛḍ.龍ผู้]"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ", _interpolate(_named("filterName")), " ϝḭḭḽṭḛḛṛ龍ผู้]"])}
      },
      "disputes": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ϝṓṓṛ ṃḛḛ龍ผู้]"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      },
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭṡ龍ผู้]"])},
      "edit_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ龍ผู้]"])},
      "empty_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ϝḭḭḽṭḛḛṛṡ ααḍḍḛḛḍ龍ผู้]"])},
      "empty_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗṛḭḭṽααṭḛḛ ϝḭḭḽṭḛḛṛṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
      "empty_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗṵṵḅḽḭḭͼ ϝḭḭḽṭḛḛṛṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "private_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααṭḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "public_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭͼ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "temporary_filter_message": {
        "action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ŵḭḭṡḥ ṭṓṓ ", _interpolate(_named("cta")), "?龍ผู้]"])},
        "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡααṽḛḛ ḭḭṭ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṡ αα ṭḛḛṃṗṓṓṛααṛẏẏ ϝḭḭḽṭḛḛṛ'ṡ ṽḭḭḛḛẁ.龍ผู้]"])},
        "loading_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṡṭṛṵṵͼṭḭḭṇḡ ẏẏṓṓṵṵṛ ϝḭḭḽṭḛḛṛ...龍ผู้]"])}
      },
      "update_success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭṡ龍ผู้]"])}
    },
    "sidebar": {
      "auto_qa": {
        "custom_category_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "feedback_updated_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛḍ ", _interpolate(_named("updatedDate")), "龍ผู้]"])},
        "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗḽḛḛẋ ẁṓṓṛḍṡ龍ผู้]"])},
        "feedback_updated_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓ-ṛḛḛṽḭḭḛḛẁṡ ṵṵṗḍααṭḛḛ ḍẏẏṇααṃḭḭͼααḽḽẏẏ ḅααṡḛḛḍ ṓṓṇ ṭḭḭͼḳḛḛṭ ͼḥααṇḡḛḛṡ龍ผู้]"])},
        "highlight_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥḽḭḭḡḥṭ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ龍ผู้]"])},
        "na_popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṵṵṭṓṓ ṡͼṓṓṛḛḛ ṗṛḛḛḍḭḭͼṭḛḛḍ龍ผู้]"])},
        "no_mistakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ααṇẏẏ ṃḭḭṡṭααḳḛḛṡ龍ผู้]"])},
        "show_complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ͼṓṓṃṗḽḛḛẋ ẁṓṓṛḍṡ龍ผู้]"])},
        "show_long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ḽṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ龍ผู้]"])}
      },
      "next_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẋṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "reviewee_dropdown_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
      "spotlight": {
        "undetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼṓṓṵṵḽḍṇ’ṭ ṛααṭḛḛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ ḍṵṵḛḛ ṭṓṓ ḭḭṇṡṵṵϝϝḭḭͼḭḭḛḛṇṭ ͼṓṓṇṭḛḛẋṭ. Ṁααṇṵṵααḽ ṛḛḛṽḭḭḛḛẁ ṛḛḛͼṓṓṃṃḛḛṇḍḛḛḍ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼṓṓṽḛḛṛ ααṵṵṭṓṓṃααṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇṡḭḭḡḥṭṡ ṭṓṓ ṡṭṛḛḛααṃḽḭḭṇḛḛ ẏẏṓṓṵṵṛ ṛḛḛṽḭḭḛḛẁ ṗṛṓṓͼḛḛṡṡ. Ḥḭḭḡḥḽḭḭḡḥṭḭḭṇḡ ͼṛṵṵͼḭḭααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ṡḥṓṓẁͼααṡḭḭṇḡ ṡṭααṇḍṓṓṵṵṭ ṗṓṓṡḭḭṭḭḭṽḛḛ ḛḛẋααṃṗḽḛḛṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ṭḛḛααṃ.龍ผู้]"])}
      },
      "call_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍[Ḉααḽḽ]龍ผู้]"])},
      "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṛṛḛḛͼṭḛḛḍ龍ผู้]"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "disputes": {
        "approved_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("agentName")), " ααͼͼḛḛṗṭḛḛḍ ṭḥḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "assigned_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ṡḛḛṇṭ ṭṓṓ ", _interpolate(_named("agentName")), "龍ผู้]"])},
        "comment_placeholder": {
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽααḭḭṇ ẁḥẏẏ ẏẏṓṓṵṵ ṭḥḭḭṇḳ ṭḥḭḭṡ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ḭḭṡ ṭḥḛḛ ẁṛṓṓṇḡ ṛḛḛͼḭḭṗḭḭḛḛṇṭ ϝṓṓṛ ṭḥḭḭṡ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ͼṓṓṃṃḛḛṇṭ ααṇḍ ṡḛḛṭ αα ṇḛḛẁ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽααḭḭṇ ẁḥẏẏ ẏẏṓṓṵṵ ḅḛḛḽḭḭḛḛṽḛḛ ṭḥḛḛṡḛḛ ṡͼṓṓṛḛḛṡ ααṛḛḛ ṵṵṇϝααḭḭṛḽẏẏ ṛααṭḛḛḍ (ṃααṇḍααṭṓṓṛẏẏ)龍ผู้]"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ͼṓṓṃṃḛḛṇṭ (ṃααṇḍααṭṓṓṛẏẏ)龍ผู้]"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽααḭḭṇ ẁḥẏẏ ẏẏṓṓṵṵ ααṛḛḛ ṛḛḛĵḛḛͼṭḭḭṇḡ ṭḥḭḭṡ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ͼṓṓṃṃḛḛṇṭ龍ผู้]"])}
        },
        "labels": {
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ḍḭḭṡṗṵṵṭḛḛ ṭṓṓ龍ผู้]"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ḍḭḭṡṗṵṵṭḛḛ ṭṓṓ龍ผู้]"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ḍḭḭṡṗṵṵṭḛḛ ṭṓṓ龍ผู้]"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṇḛḛẁ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])}
        },
        "modal": {
          "user_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ḍḭḭṡṗṵṵṭḛḛ ṭṓṓ龍ผู้]"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ṭḥḭḭṡ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "type_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ḍṓṓṇ'ṭ ααḡṛḛḛḛḛ ẁḭḭṭḥ ṭḥḛḛ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
          "type_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṛḛḛṽḭḭḛḛẁ ẁααṡṇ'ṭ ṃḛḛααṇṭ ϝṓṓṛ ṃḛḛ龍ผู้]"])},
          "user_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṵṵṡḛḛṛ龍ผู้]"])}
        },
        "partially_accepted_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṗααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ ṭḥḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "rejected_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṛḛḛĵḛḛͼṭḛḛḍ ṭḥḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "status": {
          "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
          "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
          "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭḛḛḍ龍ผู้]"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ龍ผู้]"])}
        },
        "started_review_dispute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("userName")), " ṡṭααṛṭḛḛḍ αα ḍḭḭṡṗṵṵṭḛḛ: ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "started_reviewee_disputed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("userName")), " ṡṭααṛṭḛḛḍ αα ḍḭḭṡṗṵṵṭḛḛ: ẁṛṓṓṇḡ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
        "actions_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ṓṓṗḛḛṇ ḍḭḭṡṗṵṵṭḛḛ ṡḥṓṓṵṵḽḍ ḅḛḛ ṛḛḛṡṓṓḽṽḛḛḍ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ṭααḳḛḛ ṓṓṭḥḛḛṛ ααͼṭḭḭṓṓṇṡ ṓṓṇ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭ龍ผู้]"])},
        "approve_scores_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṇḛḛẁ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ẁḭḭṡḥ ṭṓṓ ḍḭḭṡͼααṛḍ ṭḥḭḭṡ ḍḭḭṡṗṵṵṭḛḛ?龍ผู้]"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "dispute_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ ṭḭḭṃḛḛ龍ผู้]"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭ龍ผู้]"])},
        "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṇḛḛẁ ϝααḭḭṛ ṡͼṓṓṛḛḛṡ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
        "review_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "reviewee_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṛṓṓṇḡ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
        "titles": {
          "approve_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
          "approve_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
          "dispute_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "dispute_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṛṓṓṇḡ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
          "edit_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
          "reject_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
          "reject_reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])}
        },
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṭḥḛḛ ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])}
      },
      "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭḛḛḍ龍ผู้]"])},
      "review_modified_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("action")), " ḅẏẏ ", _interpolate(_named("author")), " ", _interpolate(_named("date")), "龍ผู้]"])},
      "review_recipient": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ṭṓṓ ", _interpolate(_list(0)), "龍ผู้]"])},
      "reviewee_dropdown_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
      "action_finish_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍṓṓṇḛḛ龍ผู้]"])},
      "assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḍ ṭṓṓ:龍ผู้]"])},
      "comment_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ͼṓṓṃṃḛḛṇṭ ααṡ ẁḛḛḽḽ龍ผู้]"])},
      "conditional_scorecard_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ͼṓṓṇḍḭḭṭḭḭṓṓṇααḽ ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
      "formerly_assigned_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛṃḛḛṛḽẏẏ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ:龍ผู้]"])},
      "gif_loader_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṡṭ ṗḭḭͼḳ ṓṓṇḛḛ龍ผู้]"])},
      "gif_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍṇ'ṭ ϝḭḭṇḍ ααṇẏẏ ḠḬḬḞṡ ẁḭḭṭḥ ṭḥααṭ ṇααṃḛḛ龍ผู้]"])},
      "no_feedback_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḛḛ ṭḥḛḛ ϝḭḭṛṡṭ ṭṓṓ ḽḛḛααṽḛḛ αα ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
      "paused_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṡ ͼṵṵṛṛḛḛṇṭḽẏẏ ṓṓṇ ṗααṵṵṡḛḛ. Ṛḛḛααͼṭḭḭṽααṭḛḛ ḭḭṭ ṭṓṓ ḛḛṇααḅḽḛḛ ḡḭḭṽḭḭṇḡ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
      "posted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡṭḛḛḍ ḅẏẏ:龍ผู้]"])},
      "review_time_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṡḥṓṓẁṡ ḥṓṓẁ ṃṵṵͼḥ ṭḭḭṃḛḛ ẁααṡ ṡṗḛḛṇṭ ṛḛḛṽḭḭḛḛẁḭḭṇḡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "reviewee_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṵṵṡḛḛṛ龍ผู้]"])},
      "root_cause_missing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṁḭḭṡṡḭḭṇḡ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ϝṓṓṛ ͼααṭḛḛḡṓṓṛẏẏ: \"", _interpolate(_list(0)), "\"龍ผู้]"])},
      "scorecard_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ:龍ผู้]"])},
      "tooltips": {
        "no_ratings_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḛḛ αα ͼααṭḛḛḡṓṓṛẏẏ ṓṓṛ ααḍḍ αα ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ...龍ผู้]"])},
        "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḛḛṃṓṓĵḭḭ龍ผู้]"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓḽḽααṗṡḛḛ龍ผู้]"])},
        "comment_maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗααṇḍ ͼṓṓṃṃḛḛṇṭ ϝḭḭḛḛḽḍ龍ผู้]"])},
        "comment_minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓḽḽααṗṡḛḛ ͼṓṓṃṃḛḛṇṭ ϝḭḭḛḛḽḍ龍ผู้]"])},
        "hide_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ϝṓṓṛṃααṭṭḭḭṇḡ龍ผู้]"])},
        "hide_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ṗααṇḛḛḽ龍ผู้]"])},
        "show_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ϝṓṓṛṃααṭṭḭḭṇḡ龍ผู้]"])},
        "show_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṗααṇḛḛḽ龍ผู้]"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
      },
      "unrated_categories_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "unrated_category_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḛḛ ααṭ ḽḛḛααṡṭ ṓṓṇḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "action_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ḽααṭḛḛṛ龍ผู้]"])},
      "action_highlight_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ϝṓṓṛ ḽααṭḛḛṛ龍ผู้]"])},
      "action_next_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẋṭ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
      "action_skip_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽααͼḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇ ṭḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ ͼẏẏͼḽḛḛ龍ผู้]"])},
      "back_to_ticket_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "baseline_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααṡḛḛḽḭḭṇḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ龍ผู้]"])},
      "comment_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "comment_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ龍ผู้]"])},
      "comment_edit_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ͼṓṓṃṃḛḛṇṭ?龍ผู้]"])},
      "comment_list": {
        "comment_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ααṵṵṭḥṓṓṛ龍ผู้]"])},
        "hashtag_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḥααṡḥṭααḡ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ ṓṓṛ ṛḛḛṇααṃḛḛḍ.龍ผู้]"])}
      },
      "comment_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛ ẁḥααṭ ẏẏṓṓṵṵ ḽṓṓṽḛḛḍ ṓṓṛ ẁḥααṭ ͼṓṓṵṵḽḍ ḥααṽḛḛ ḅḛḛḛḛṇ ḅḛḛṭṭḛḛṛ...龍ผู้]"])},
      "comment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "conditional_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇααḽ龍ผู้]"])},
      "confirm_comment_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ααḽḽ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])},
      "confirm_comment_delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "empty_message_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍[Ṁḛḛṡṡααḡḛḛ]龍ผู้]"])},
      "feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "feedback_not_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḥααṡ ṇṓṓṭ ẏẏḛḛṭ ṡḛḛḛḛṇ ṭḥḭḭṡ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "feedback_seen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḥααṡ ṡḛḛḛḛṇ ṭḥḭḭṡ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛṃḛḛṛ ṃḛḛṃḅḛḛṛ龍ผู้]"])},
      "gif_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḠḬḬḞṡ...龍ผู้]"])},
      "hashtag_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṡṡ ḚḚṇṭḛḛṛ ṭṓṓ ͼṛḛḛααṭḛḛ αα ṇḛḛẁ ḥααṡḥṭααḡ.龍ผู้]"])},
      "hashtag_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ḥααṡḥṭααḡṡ ααṛḛḛ ḽṓṓͼḳḛḛḍ ḅẏẏ ααḍṃḭḭṇ.龍ผู้]"])},
      "highlight_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ ṭḥḭḭṡ ṭṓṓ ḅṓṓṓṓḳṃααṛḳ ͼααṡḛḛṡ ααṇḍ ϝḭḭḽṭḛḛṛ ṓṓṵṵṭ ḽααṭḛḛṛ龍ผู้]"])},
      "mark_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ḅααṡḛḛḽḭḭṇḛḛ龍ผู้]"])},
      "message_feedback_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "next_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẋṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "no_feedback_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁṡ ḍṓṓṇḛḛ龍ผู้]"])},
      "no_feedback_subtitle_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ṛḛḛṽḭḭḛḛẁ ḅḛḛḽṓṓẁ ṭṓṓ ḡḛḛṭ ṡṭααṛṭḛḛḍ.龍ผู้]"])},
      "no_other_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṓṓṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁṡ ḽḛḛϝṭ龍ผู้]"])},
      "not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṛααṭḛḛḍ龍ผู้]"])},
      "previous_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡ ααṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
      "previous_ticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "remove_as_baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ααṡ ḅααṡḛḛḽḭḭṇḛḛ龍ผู้]"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽẏẏ龍ผู้]"])},
      "reply_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽẏẏ ṭṓṓ ṭḥḭḭṡ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "review_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
      "review_collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ṛḛḛṽḭḭḛḛẁ ṡḭḭḍḛḛḅααṛ龍ผู้]"])},
      "review_delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ααṇḍ ααḽḽ ααḍḍḛḛḍ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])},
      "review_delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "review_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "review_delete_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "review_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḍḛḛḽḛḛṭḛḛḍ ḅẏẏ ", _interpolate(_list(0)), "龍ผู้]"])},
      "review_disabled_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍṓṓṇḛḛ!龍ผู้]"])},
      "review_disabled_reassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ṭḥḭḭṡ ṭααṡḳ ṭṓṓ ẏẏṓṓṵṵṛṡḛḛḽϝ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ḽḛḛααṽḛḛ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "review_disabled_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ααḽṛḛḛααḍẏẏ ḽḛḛϝṭ αα ṛḛḛṽḭḭḛḛẁ ϝṓṓṛ ṭḥḭḭṡ ṵṵṡḛḛṛ.龍ผู้]"])},
      "review_disabled_reviewee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ḽḛḛϝṭ αα ṛḛḛṽḭḭḛḛẁ ϝṓṓṛ ", _interpolate(_list(0)), "龍ผู้]"])},
      "review_disabled_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṭṓṓ ṡḥṓṓẁ ϝṓṓṛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
      "review_disabled_self_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṇṓṓṭ ααṽααḭḭḽααḅḽḛḛ ϝṓṓṛ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
      "review_draft_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ龍ผู้]"])},
        "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇ'ṭ ṡααṽḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ṭḥḭḭṡ ṛḛḛṽḭḭḛḛẁ ααṡ αα ḍṛααϝṭ?龍ผู้]"])}
      },
      "review_draft_discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼααṛḍ ṭḥḭḭṡ ḍṛααϝṭ龍ผู้]"])},
      "review_draft_discard_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼααṛḍ龍ผู้]"])},
      "review_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṛḛḛṽḭḭḛḛẁ ṡḭḭḍḛḛḅααṛ龍ผู้]"])},
      "review_status": {
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽ龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ龍ผู้]"])},
        "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ẁḭḭṭḥ ṃḭḭṇṓṓṛṡ龍ผู้]"])}
      },
      "review_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ龍ผู้]"])},
      "review_time_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭḭḭḽḽ ͼṛṵṵṇͼḥḭḭṇḡ ṭḥḛḛ ṇṵṵṃḅḛḛṛṡ...龍ผู้]"])},
      "review_title_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "review_title_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḥḭḭṡ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
      "review_title_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ẏẏṓṓṵṵṛṡḛḛḽϝ龍ผู้]"])},
      "review_title_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "reviewee_dropdown_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
      "reviewee_dropdown_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥṓṓṛ龍ผู้]"])},
      "reviewee_dropdown_depleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṵṵṡḛḛṛṡ ṭṓṓ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "reviewee_dropdown_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ḍḭḭṡααḅḽḛḛḍ龍ผู้]"])},
      "reviewee_dropdown_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṵṵṡḛḛṛ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "reviewee_dropdown_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
      "reviewee_dropdown_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "reviewee_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ...龍ผู้]"])},
      "reviewee_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛ龍ผู้]"])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ṛḛḛṽḭḭḛḛẁ"]), _normalize([_interpolate(_named("count")), " ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])])},
      "select_new_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṇḛḛẁ ṛααṭḭḭṇḡṡ龍ผู้]"])},
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ẁṓṓṛḳṡṗααͼḛḛ ṃααṇααḡḛḛṛ ḥααṡ ṇṓṓṭ ααḽḽṓṓẁḛḛḍ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
      "tag_this_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṡṡ ḚḚṇṭḛḛṛ ṭṓṓ ṭααḡ ṭḥḭḭṡ ṗḭḭṇ龍ผู้]"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])}
    },
    "sub_nav": {
      "calibrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇṡ龍ผู้]"])},
      "drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ 30 ḍααẏẏṡ龍ผู้]"])},
      "starred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṛḛḛḍ龍ผู้]"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
      "drafts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭṡ龍ผู้]"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "visual_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭṡ龍ผู้]"])},
      "visual_filters_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭṡ ḡḭḭṽḛḛ ẏẏṓṓṵṵ αα ṽḭḭṡṵṵααḽ ṛḛḛṗṛḛḛṡḛḛṇṭααṭḭḭṓṓṇ ṓṓϝ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ḍααṭαα. ṲṲṡḛḛ ṭḥḛḛ ḭḭṇṭḛḛṛααͼṭḭḭṽḛḛ ͼḥααṛṭṡ ṭṓṓ ṵṵṇḍḛḛṛṡṭααṇḍ ẁḥḛḛṛḛḛ ṭṓṓ ϝṓṓͼṵṵṡ ẏẏṓṓṵṵṛ ǪḀḀ ḛḛϝϝṓṓṛṭṡ, ṵṵṇͼṓṓṽḛḛṛ ḅḽḭḭṇḍṡṗṓṓṭṡ, ααṇḍ ϝḭḭṇḍ ṭḥḛḛ ṛḭḭḡḥṭ ααḡḛḛṇṭṡ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ḅααṡḛḛḍ ṓṓṇ ṭḥḛḛḭḭṛ ṗḛḛṛϝṓṓṛṃααṇͼḛḛ.龍ผู้]"])}
    },
    "ticket": {
      "ID": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("ticketSource")), " ḬḬḌ龍ผู้]"])},
      "URL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("ticketSource")), " ṲṲṚḺ龍ผู้]"])},
      "translate_to_language": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡḽααṭḛḛ ṭṓṓ ", _interpolate(_named("language")), "龍ผู้]"])},
      "calibration_add_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ龍ผู้]"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṡṡḭḭḡṇḛḛḍ龍ผู้]"])},
      "user_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭḽẏẏ ṽḭḭḛḛẁḭḭṇḡ龍ผู้]"])},
      "user_reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḭḭṇḡ ṇṓṓẁ龍ผู้]"])},
      "deleted_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ṛḛḛṃṓṓṽḛḛḍ ααṇḍ ḭḭṡ ṇṓṓ ḽṓṓṇḡḛḛṛ ααṽααḭḭḽααḅḽḛḛ.龍ผู้]"])},
      "not_found_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẏẏṓṓṵṵ ααṛḛḛ ḽṓṓṓṓḳḭḭṇḡ ϝṓṓṛ ḍṓṓḛḛṡ ṇṓṓṭ ḛḛẋḭḭṡṭ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])},
      "all_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṗḭḭṇṡ ẁḭḭṭḥ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
      "calibration_in_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "calibration_modal": {
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "remove_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḭḭṇḡ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṛṓṓṃ αα ṡḛḛṡṡḭḭṓṓṇ ẁḭḭḽḽ ḍḛḛḽḛḛṭḛḛ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ ṭṓṓ ḭḭṭ.龍ผู้]"])},
        "remove_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṛṓṓṃ ", _interpolate(_named("sessionName")), "龍ผู้]"])}
      },
      "calibration_no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇṡ ͼṛḛḛααṭḛḛḍ ẏẏḛḛṭ.龍ผู้]"])},
      "calibration_toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "calibration_toast_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "calibration_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ/ṛḛḛṃṓṓṽḛḛ ϝṛṓṓṃ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ḽḭḭṇḳ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "details_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛααṇḍ龍ผู้]"])},
      "details_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])},
      "details_client_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ'ṡ ḛḛṃααḭḭḽ龍ผู้]"])},
      "details_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ'ṡ ḬḬḌ龍ผู้]"])},
      "details_client_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ'ṡ ṇααṃḛḛ龍ผู้]"])},
      "details_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṭḛḛṛṇααḽ ϝṓṓṛṃ ṇααṃḛḛ龍ผู้]"])},
      "details_frt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḞṚṪ龍ผู้]"])},
      "details_frt_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ ṛḛḛṡṗṓṓṇṡḛḛ ṭḭḭṃḛḛ龍ผู้]"])},
      "details_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ龍ผู้]"])},
      "details_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṓṓṛḭḭṭẏẏ龍ผู้]"])},
      "details_satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "details_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡͼṓṓṛḛḛ龍ผู้]"])},
      "details_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ龍ผู้]"])},
      "details_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ龍ผู้]"])},
      "details_ttfr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṪṪḞṚ龍ผู้]"])},
      "details_ttfr_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛ ṭṓṓ ϝḭḭṛṡṭ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
      "details_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ龍ผู้]"])},
      "external_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṛḭḭḡḭḭṇααḽ龍ผู้]"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ...龍ผู้]"])},
      "message_404_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃḛḛṡṡααḡḛḛ ḍṓṓḛḛṡ ṇṓṓṭ ḛḛẋḭḭṡṭ ṵṵṇḍḛḛṛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṓṓṛ ḭḭṭ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵḅĵḛḛͼṭ龍ผู้]"])},
      "no_ticket_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ ṓṓṛḭḭḡḭḭṇααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "original_conversation_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇṇṓṓṭ ḽḭḭṇḳ ṭṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ - ṃḭḭṡṡḭḭṇḡ ṲṲṚḺ龍ผู้]"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
      "participants_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
      "pin_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "read_review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ṭṓṓ ṛḛḛααḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "review_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ṭṓṓ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "reviewed_via_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṛḛḛααṭḛḛḍ ṽḭḭαα ḅṛṓṓẁṡḛḛṛ ḛḛẋṭḛḛṇṡḭḭṓṓṇ龍ผู้]"])},
      "satisfaction_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ͼṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
      "see_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "sentiment_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛ ẁḭḭṭḥ αα ṇḛḛḡααṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
      "sentiment_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛ ẁḭḭṭḥ αα ṗṓṓṡḭḭṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡḽααṭḛḛ ṭṓṓ ḚḚṇḡḽḭḭṡḥ龍ผู้]"])},
      "translate_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṓṓṛḭḭḡḭḭṇααḽ龍ผู้]"])}
    },
    "ticket_deletion": {
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "confirmation_modal_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ααḽṡṓṓ ḍḛḛḽḛḛṭḛḛṡ ααḽḽ ṛḛḛḽααṭḛḛḍ ḍααṭαα, ḛḛ.ḡ. ṡṵṵṛṽḛḛẏẏṡ, ṭṛααṇṡͼṛḭḭṗṭḭḭṓṓṇṡ, ααṇḍ ṛḛḛṽḭḭḛḛẁṡ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ḭḭṇͼḽṵṵḍḭḭṇḡ ḍααṡḥḅṓṓααṛḍṡ.", _interpolate(_named("lineBreak")), "ŶŶṓṓṵṵ'ḽḽ ṡṭḭḭḽḽ ḅḛḛ ααḅḽḛḛ ṭṓṓ ααͼͼḛḛṡṡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ Ṣṵṵṗṗṓṓṛṭ ṓṓṛ ααṇṓṓṭḥḛḛṛ ḥḛḛḽṗ ḍḛḛṡḳ ṡṓṓϝṭẁααṛḛḛ ẏẏṓṓṵṵ’ṛḛḛ ṵṵṡḭḭṇḡ.龍ผู้]"])},
      "confirmation_modal_body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ααḽṡṓṓ ḍḛḛḽḛḛṭḛḛṡ ααḽḽ ṛḛḛḽααṭḛḛḍ ḍααṭαα, ḛḛ.ḡ. ṡṵṵṛṽḛḛẏẏṡ, ṭṛααṇṡͼṛḭḭṗṭḭḭṓṓṇṡ, ααṇḍ ṛḛḛṽḭḭḛḛẁṡ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ḭḭṇͼḽṵṵḍḭḭṇḡ ḍααṡḥḅṓṓααṛḍṡ. ḬḬϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḅḛḛḽṓṓṇḡṡ ṭṓṓ ṃṵṵḽṭḭḭṗḽḛḛ ẁṓṓṛḳṡṗααͼḛḛṡ, ḭḭṭ ẁḭḭḽḽ ḅḛḛ ḍḛḛḽḛḛṭḛḛḍ ϝṛṓṓṃ ααḽḽ ṓṓϝ ṭḥḛḛṃ.", _interpolate(_named("lineBreak")), "ŶŶṓṓṵṵ'ḽḽ ṡṭḭḭḽḽ ḅḛḛ ααḅḽḛḛ ṭṓṓ ααͼͼḛḛṡṡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ Ṣṵṵṗṗṓṓṛṭ ṓṓṛ ααṇṓṓṭḥḛḛṛ ḥḛḛḽṗ ḍḛḛṡḳ ṡṓṓϝṭẁααṛḛḛ ẏẏṓṓṵṵ'ṛḛḛ ṵṵṡḭḭṇḡ.龍ผู้]"])},
      "confirmation_modal_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])}
    },
    "ticket_event": {
      "incomplete": {
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ḭḭṇͼṓṓṃṗḽḛḛṭḛḛ ḍṵṵḛḛ ṭṓṓ ḛḛṡͼααḽααṭḭḭṓṓṇ. Ḡṓṓ ṭṓṓ ", _interpolate(_named("optionsUrl")), " ααṇḍ ṽααḽḭḭḍααṭḛḛ ẏẏṓṓṵṵṛ ṭααḡṡ. ", _interpolate(_named("tagsUrl")), "龍ผู้]"])},
        "description_no_edit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ḭḭṇͼṓṓṃṗḽḛḛṭḛḛ ḍṵṵḛḛ ṭṓṓ ḛḛṡͼααḽααṭḭḭṓṓṇ. Ṣṗḛḛααḳ ṭṓṓ ẏẏṓṓṵṵṛ ααḍṃḭḭṇḭḭṡṭṛααṭṓṓṛ ααḅṓṓṵṵṭ ṽααḽḭḭḍααṭḭḭṇḡ ẏẏṓṓṵṵṛ ṭααḡṡ. ", _interpolate(_named("tagsUrl")), "龍ผู้]"])},
        "options_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ > ḀḀḍṽααṇͼḛḛḍ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
        "tags_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṭααḡṡ龍ผู้]"])}
      },
      "statuses": {
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḛḛḍ龍ผู้]"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ龍ผู้]"])},
        "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ龍ผู้]"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṇḍḭḭṇḡ龍ผู้]"])},
        "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓḽṽḛḛḍ龍ผู้]"])}
      },
      "assignee_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ ͼḥααṇḡḛḛḍ ṭṓṓ ", _interpolate(_named("target")), "龍ผู้]"])},
      "assignee_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṡṡḭḭḡṇḛḛḍ龍ผู้]"])},
      "macro_used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("target")), " ṃααͼṛṓṓ ααṗṗḽḭḭḛḛḍ ḅẏẏ ", _interpolate(_named("author")), "龍ผู้]"])},
      "rating_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛͼḛḛḭḭṽḛḛḍ αα ṛααṭḭḭṇḡ ", _interpolate(_named("target")), "龍ผู้]"])},
      "redaction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ ", _interpolate(_named("target")), " ṛḛḛḍααͼṭḛḛḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "status_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ ͼḥααṇḡḛḛḍ ṭṓṓ ", _interpolate(_named("target")), "龍ผู้]"])},
      "tag_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫααḡḡḛḛḍ ẁḭḭṭḥ ", _interpolate(_named("target")), "龍ผู้]"])},
      "team_change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḛḛααṃ ͼḥααṇḡḛḛḍ ṭṓṓ ", _interpolate(_named("target")), "龍ผู้]"])},
      "snoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("author")), " ṡṇṓṓṓṓẓḛḛḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ", _interpolate(_named("target")), "龍ผู้]"])},
      "unsnoozed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("author")), " ṵṵṇṡṇṓṓṓṓẓḛḛḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])}
    },
    "ticket_insights": {
      "types": {
        "bot_repetition": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṡṭṵṵͼḳ ḭḭṇ αα ḽṓṓṓṓṗ, ͼṓṓṇṡḛḛͼṵṵṭḭḭṽḛḛḽẏẏ ṛḛḛṗḛḛααṭḭḭṇḡ αα ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṛḛḛṗḛḛṭḭḭṭḭḭṓṓṇ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṛḛḛṗḛḛṭḭḭṭḭḭṓṓṇ, ẁḥḛḛṛḛḛ ṭḥḛḛ ṡααṃḛḛ ṃḛḛṡṡααḡḛḛ ḭḭṡ ṛḛḛṗḛḛααṭḛḛḍ ṓṓṇḛḛ ṓṓṛ ṃṓṓṛḛḛ ṭḭḭṃḛḛṡ ḭḭṇ ṡṵṵͼͼḛḛṡṡḭḭṓṓṇ龍ผู้]"])}
        },
        "custom_spotlight": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṭͼḥḭḭṇḡ ṗḥṛααṡḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
        },
        "negative_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṇ ααṽḛḛṛααḡḛḛ ααḡḛḛṇṭ ḥααṇḍḽḛḛṡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ", _interpolate(_named("percent")), " ṃṓṓṛḛḛ ḛḛϝϝḭḭͼḭḭḛḛṇṭḽẏẏ ṭḥααṇ ṭḥḛḛ ḅṓṓṭ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ͼṓṓṃṃṵṵṇḭḭͼααṭḭḭṓṓṇ ḛḛϝϝḭḭͼḭḭḛḛṇͼẏẏ龍ผู้]"])}
        },
        "positive_bot_communication_efficiency": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ḥααṇḍḽḛḛḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ", _interpolate(_named("percent")), " ṃṓṓṛḛḛ ḛḛϝϝḭḭͼḭḭḛḛṇṭḽẏẏ ṭḥααṇ ααṇ ααṽḛḛṛααḡḛḛ ααḡḛḛṇṭ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ͼṓṓṃṃṵṵṇḭḭͼααṭḭḭṓṓṇ ḛḛϝϝḭḭͼḭḭḛḛṇͼẏẏ龍ผู้]"])}
        },
        "recording_disclosure_missing": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḽααͼḳṡ ṭḥḛḛ ṇḛḛͼḛḛṡṡααṛẏẏ ṡṭααṭḛḛṃḛḛṇṭ ḭḭṇϝṓṓṛṃḭḭṇḡ ṗααṛṭḭḭͼḭḭṗααṇṭṡ ṓṓϝ ṛḛḛͼṓṓṛḍḭḭṇḡ, ẁḥḭḭͼḥ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ ϝṓṓṛ ͼṓṓṃṗḽḭḭααṇͼḛḛ ααṇḍ ṭṛααṇṡṗααṛḛḛṇͼẏẏ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼṓṓṛḍḭḭṇḡ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṃḭḭṡṡḭḭṇḡ龍ผู้]"])}
        },
        "sla_breached": {
          "description_with_examples": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀ ṡḛḛṛṽḭḭͼḛḛ-ḽḛḛṽḛḛḽ ααḡṛḛḛḛḛṃḛḛṇṭ (ṢḺḀḀ) ḥααṡ ḅḛḛḛḛṇ ḅṛḛḛααͼḥḛḛḍ ϝṓṓṛ ", _interpolate(_list(0)), ".龍ผู้]"])},
          "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢḺḀḀ ḅṛḛḛααͼḥḛḛḍ龍ผู้]"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṭṛḭḭͼ ϝṓṓṛ ṭḥḛḛ ṢḺḀḀ ṛṵṵḽḛḛ ḛḛẋͼḛḛḛḛḍḛḛḍ ḭḭṭ'ṡ ṭααṛḡḛḛṭ ṭḭḭṃḛḛ龍ผู้]"])}
        },
        "churn_risk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇṡ ṓṓϝ ͼṵṵṡṭṓṓṃḛḛṛ ααṭṭṛḭḭṭḭḭṓṓṇ. Ḉṵṵṡṭṓṓṃḛḛṛ ẁααṡ ͼṓṓṇṡḭḭḍḛḛṛḭḭṇḡ αα ṡẁḭḭṭͼḥ ṓṓṛ ṗṛṓṓṃḭḭṡḛḛḍ ṭṓṓ ṗααṛṭ ẁααẏẏṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥṵṵṛṇ ṛḭḭṡḳ ḭḭḍḛḛṇṭḭḭϝḭḭḛḛḍ龍ผู้]"])}
        },
        "deadair_exceeded": {
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ṃṓṓṃḛḛṇṭṡ ṓṓϝ ḍḛḛααḍ ααḭḭṛ ḽααṡṭḭḭṇḡ ḽṓṓṇḡḛḛṛ ṭḥααṇ ṡḛḛṭ ṭḥṛḛḛṡḥṓṓḽḍ ṓṓϝ ", _interpolate(_list(0)), "ṡ龍ผู้]"])},
          "instances": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḭḭṇṡṭααṇͼḛḛ"]), _normalize([_interpolate(_named("count")), " ḭḭṇṡṭααṇͼḛḛṡ龍ผู้]"])])},
          "longest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "ṡ ḽṓṓṇḡḛḛṡṭ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛααḍ ααḭḭṛ龍ผู้]"])}
        },
        "escalation": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡḛḛḛḛḳḛḛḍ ḥḭḭḡḥḛḛṛ-ḽḛḛṽḛḛḽ ααṡṡḭḭṡṭααṇͼḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṡͼααḽααṭḭḭṓṓṇ ṛḛḛʠṵṵḛḛṡṭḛḛḍ龍ผู้]"])}
        },
        "extra_mile": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ḛḛẋṗṛḛḛṡṡḛḛḍ ḛḛẋṭṛḛḛṃḛḛ ḡṛααṭḭḭṭṵṵḍḛḛ ṓṓṛ ẁααṡ ṽḛḛṛẏẏ ͼṓṓṇṭḛḛṇṭ ẁḭḭṭḥ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḛḛṗṭḭḭṓṓṇααḽ ṡḛḛṛṽḭḭͼḛḛ龍ผู้]"])}
        },
        "follow_up": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḭḭṭḥḛḛṛ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṓṓṛ ααḡḛḛṇṭ ḛḛẋṗḽḭḭͼḭḭṭḽẏẏ ṛḛḛʠṵṵḛḛṡṭḛḛḍ αα ϝṓṓḽḽṓṓẁ-ṵṵṗ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓḽḽṓṓẁ-ṵṵṗ ṛḛḛʠṵṵḛḛṡṭ龍ผู้]"])}
        },
        "negative_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡḥṓṓẁḛḛḍ ḍḭḭṡṡααṭḭḭṡϝααͼṭḭḭṓṓṇ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])}
        },
        "outlier": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ṵṵṇṵṵṡṵṵααḽ ṓṓṛ ααṭẏẏṗḭḭͼααḽ ααṇḍ ṭḥḛḛṛḛḛ ẁααṡ ṃṓṓṛḛḛ ḅααͼḳ-ααṇḍ-ϝṓṓṛṭḥ ṭṓṓ ṛḛḛααͼḥ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṭḽḭḭḛḛṛ ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])}
        },
        "positive_sentiment": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡḥṓṓẁḛḛḍ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])}
        }
      },
      "feedback": {
        "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽḭḭͼααḅḽḛḛ龍ผู้]"])},
        "feedback_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ααṡ ṇṓṓṭ ααṗṗḽḭḭͼααḅḽḛḛ龍ผู้]"])},
        "last_edited_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ḛḛḍḭḭṭḛḛḍ ḅẏẏ ", _interpolate(_named("author")), " ṓṓṇ ", _interpolate(_named("date")), "龍ผู้]"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ααṗṗḽḭḭͼααḅḽḛḛ龍ผู้]"])},
        "set_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃẏẏ ", _interpolate(_named("author")), " ṓṓṇ ", _interpolate(_named("date")), "龍ผู้]"])},
        "feedback_textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṛḛḛααṡṓṓṇ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])}
      },
      "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḭḭṇṡḭḭḡḥṭṡ ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])}
    },
    "ticket_list": {
      "date_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ龍ผู้]"])},
      "no_connections_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "no_connections_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ṭṓṓ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ααṇḍ ḥααṽḛḛ ααṭ ḽḛḛααṡṭ ṓṓṇḛḛ ϝḭḭḽṭḛḛṛ ͼṛḛḛααṭḛḛḍ ṭṓṓ ṡḛḛḛḛ ṭḥḛḛ ḽḭḭṡṭ ṓṓϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
      "no_connections_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])},
      "no_tickets_link_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ẁṓṓṛḳṡṗααͼḛḛ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
      "reviewee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
      "details": {
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ龍ผู้]"])},
        "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ龍ผู้]"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ龍ผู้]"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ龍ผู้]"])}
      },
      "filter_limit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ龍ผู้]"])},
      "review_period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍(ḬḬṇ αα ḍααẏẏ)龍ผู้]"])},
      "review_period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍(Ṫḥḭḭṡ ṃṓṓṇṭḥ)龍ผู้]"])},
      "review_period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍(Ṫḥḭḭṡ ẁḛḛḛḛḳ)龍ผู้]"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ ḅẏẏ ḬḬḌ龍ผู้]"])},
      "search_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ṛḛḛṡṵṵḽṭṡ ϝṓṓṛ ", _interpolate(_named("searchQuery")), "龍ผู้]"])},
      "someone_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ ṓṓṭḥḛḛṛṡ龍ผู้]"])},
      "sort_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭḛḛḍ龍ผู้]"])},
      "sort_newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁḛḛṡṭ龍ผู้]"])},
      "sort_oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḽḍḛḛṡṭ龍ผู้]"])},
      "sort_random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṇḍṓṓṃ龍ผู้]"])},
      "sort_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛṇṭḽẏẏ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "sort_recent_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛṇṭḽẏẏ ṛḛḛṗḽḭḭḛḛḍ龍ผู้]"])},
      "sort_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṛṭ ḅẏẏ龍ผู้]"])},
      "sort_unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṛḛḛααḍ龍ผู้]"])},
      "you_reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ ẏẏṓṓṵṵ龍ผู้]"])},
      "importing_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḭḭṃṗṓṓṛṭ ṗṛṓṓͼḛḛṡṡ ṃααẏẏ ṭααḳḛḛ ṡṓṓṃḛḛ ṭḭḭṃḛḛ. Ŵḛḛ'ḽḽ ḛḛṃααḭḭḽ ẏẏṓṓṵṵ ẁḥḛḛṇ ḭḭṭ'ṡ ͼṓṓṃṗḽḛḛṭḛḛ.龍ผู้]"])},
      "importing_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ḭḭṡ ḭḭṃṗṓṓṛṭḭḭṇḡ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḻḭḭṃḭḭṭ: ", _interpolate(_list(0)), "龍ผู้]"])},
      "no_calibration_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḅẏẏ ͼḽḭḭͼḳḭḭṇḡ ṓṓṇ ṭḥḛḛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ḭḭͼṓṓṇ ḭḭṇ ṭḥḛḛ ḥḛḛααḍḛḛṛ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṽḭḭḛḛẁ.龍ผู้]"])},
      "no_complex_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḥḭḭṇḡ ṭṓṓ ṛḛḛṗṓṓṛṭ ḥḛḛṛḛḛ. Ṫṛẏẏ ααḡααḭḭṇ ṵṵṡḭḭṇḡ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ϝḭḭḽṭḛḛṛ ṓṓṛ ṡṓṓṛṭḭḭṇḡ ṓṓṗṭḭḭṓṓṇ.龍ผู้]"])},
      "no_complex_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṓṓṵṵṭḽḭḭḛḛṛṡ ṭṓṓ ḍḭḭṡṗḽααẏẏ.龍ผู้]"])},
      "no_starred_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ αα ṛḛḛḽḛḛṽααṇṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ͼḽḭḭͼḳ ṓṓṇ ṭḥḛḛ ṡṭααṛ ḭḭͼṓṓṇ ḭḭṇ ṭḥḛḛ ḥḛḛααḍḛḛṛ ṭṓṓ ḅṓṓṓṓḳṃααṛḳ ḭḭṭ ḥḛḛṛḛḛ.龍ผู้]"])},
      "no_tickets_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ͼḥααṇḡḭḭṇḡ ṭḥḛḛ ϝḭḭḽṭḛḛṛṡ ṓṓṛ ḭḭṇṽḭḭṭḛḛ ṓṓṭḥḛḛṛ ḥḛḛḽṗ ḍḛḛṡḳ ααḡḛḛṇṭṡ ṭṓṓ ααḍḍ ṃṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ龍ผู้]"])},
      "filter_limit_no_limit_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḽḭḭṃḭḭṭ龍ผู้]"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "internal_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛṛṇααḽ ṇṓṓṭḛḛ龍ผู้]"])},
      "loading_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛ'ṛḛḛ ḽṓṓααḍḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḭḭḡḥṭ ṇṓṓẁ. Ṣṓṓṃḛḛ ͼṓṓṇṭḛḛṇṭ ṃḭḭḡḥṭ ṡṭḭḭḽḽ ḅḛḛ ṃḭḭṡṡḭḭṇḡ.龍ผู้]"])},
      "member_filter_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẋṭ ṵṵṡḛḛṛ龍ผู้]"])},
      "member_filter_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṵṵṡḛḛṛ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "member_filter_option_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
      "member_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
      "member_filter_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡ ṵṵṡḛḛṛ龍ผู้]"])},
      "no_calibration_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααḍḍḛḛḍ龍ผู้]"])},
      "no_calibration_tickets_title_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḥααṽḛḛ ḅḛḛḛḛṇ ααḍḍḛḛḍ龍ผู้]"])},
      "no_drafts_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇϝḭḭṇḭḭṡḥḛḛḍ ṛḛḛṽḭḭḛḛẁṡ ḛḛṇḍ ṵṵṗ ḥḛḛṛḛḛ. Ṛḛḛ-ṛḛḛααḍ, ṛḛḛṽḭḭṡḛḛ ααṇḍ ṡṵṵḅṃḭḭṭ ḭḭṭ ḭḭṇ ṭḥḛḛ ṇḛḛẋṭ 30 ḍααẏẏṡ.龍ผู้]"])},
      "no_drafts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍṛααϝṭḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "no_starred_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṭααṛṛḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "no_tickets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥ龍ผู้]"])},
      "return_to_selected_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭṵṵṛṇ ṭṓṓ ṡḛḛḽḛḛͼṭḛḛḍ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
      "sort_least_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṡṭ ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])},
      "sort_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])},
      "sort_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḽḭḭṡṭ龍ผู้]"])},
      "workspace_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṵṵṡḛḛṛṡ龍ผู้]"])}
    },
    "ticket_reply": {
      "bot_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḂṎṎṪ龍ผู้]"])},
      "call_answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ααṇṡẁḛḛṛḛḛḍ ααṭ ", _interpolate(_named("time")), "龍ผู้]"])},
      "call_ended": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ḛḛṇḍḛḛḍ ααṭ ", _interpolate(_named("time")), "龍ผู้]"])},
      "call_placed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ṗḽααͼḛḛḍ ααṭ ", _interpolate(_named("time")), "龍ผู้]"])},
      "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
      "reviewed_by_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ ẏẏṓṓṵṵ龍ผู้]"])},
      "audio_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ ααṵṵḍḭḭṓṓ ϝḭḭḽḛḛ. Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ'ṛḛḛ ṡḭḭḡṇḛḛḍ ḭḭṇ ṭṓṓ ", _interpolate(_list(0)), ".龍ผู้]"])},
      "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡṵṵṛṽḛḛẏẏ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "audio_error_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṛṛṓṓṛ ḽṓṓααḍḭḭṇḡ ααṵṵḍḭḭṓṓ龍ผู้]"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ααṵṵṭḥṓṓṛ龍ผู้]"])},
      "no_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααḽḽ ṭṓṓṓṓḳ ṗḽααͼḛḛ龍ผู้]"])},
      "open_audio_in_helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṡṭḛḛṇ ḭḭṇ ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
      "open_in_aircall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṡṭḛḛṇ ṓṓṇ ḀḀḭḭṛͼααḽḽ龍ผู้]"])},
      "playback_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḽααẏẏḅααͼḳ ṡṗḛḛḛḛḍ龍ผู้]"])},
      "review_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ͼααḽḽ龍ผู้]"])},
      "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẏẏṡṭḛḛṃ龍ผู้]"])},
      "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡͼṛḭḭṗṭ龍ผู้]"])}
    },
    "visual_filters": {
      "categories": {
        "agent_csat": {
          "sub_categories": {
            "high_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥ龍ผู้]"])},
            "low_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓẁ龍ผู้]"])},
            "medium_satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛḍḭḭṵṵṃ龍ผู้]"])},
            "no_csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ αα Żḛḛṇḍḛḛṡḳ ǪḀḀ ṲṲṡḛḛṛ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṛααṇḳṡ ααḡḛḛṇṭṡ ḅααṡḛḛḍ ṓṓṇ ṭḥḛḛḭḭṛ ṃṓṓṇṭḥḽẏẏ ααṽḛḛṛααḡḛḛ ḈṢḀḀṪ. Ḟḭḭṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḥααṇḍḽḛḛḍ ḅẏẏ ααḡḛḛṇṭṡ ẁḥṓṓṡḛḛ ḈṢḀḀṪ ḭḭṡ ḽṓṓẁḛḛṛ ṭḥααṇ ṭḥḛḛḭḭṛ ṗḛḛḛḛṛṡ ḭḭṇ ṓṓṇḛḛ ͼḽḭḭͼḳ.龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṇḳḭḭṇḡ ḅẏẏ ḈṢḀḀṪ龍ผู้]"])}
        },
        "agent_performance": {
          "sub_categories": {
            "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛḍḭḭṵṵṃ龍ผู้]"])},
            "high_performer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥ龍ผู้]"])},
            "lowperformer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓẁ龍ผู้]"])},
            "sampler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓẁḛḛṡṭ龍ผู้]"])},
            "superstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥḛḛṡṭ龍ผู้]"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ αα Żḛḛṇḍḛḛṡḳ ǪḀḀ ṲṲṡḛḛṛ龍ผู้]"])},
            "onemessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṭḭḭͼḳḛḛṭ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿṓṓḽṵṵṃḛḛ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṛααṇḳṡ ααḡḛḛṇṭṡ ḭḭṇṭṓṓ ṗḛḛṛϝṓṓṛṃααṇͼḛḛ ḡṛṓṓṵṵṗṡ ḅααṡḛḛḍ ṓṓṇ ḥṓṓẁ ṃααṇẏẏ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥḛḛẏẏ ḥααṇḍḽḛḛ ṗḛḛṛ ṃṓṓṇṭḥ. Ḟḭḭṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ααḡḛḛṇṭṡ ẁḭḭṭḥ αα ḽṓṓẁḛḛṛ ṽṓṓḽṵṵṃḛḛ ṭḥααṇ ṭḥḛḛḭḭṛ ṗḛḛḛḛṛṡ ḭḭṇ ṓṓṇḛḛ ͼḽḭḭͼḳ.龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṇḳḭḭṇḡ ḅẏẏ ṽṓṓḽṵṵṃḛḛ龍ผู้]"])}
        },
        "agent_reviewed": {
          "sub_categories": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṡṭααṭṵṵṡ龍ผู้]"])}
        },
        "agent_sentiment": {
          "sub_categories": {
            "high_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓẁ龍ผู้]"])},
            "high_positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥ龍ผู้]"])},
            "no_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
            "positive_negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṵṵṭṛααḽ龍ผู้]"])},
            "not_a_klaus_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ αα Żḛḛṇḍḛḛṡḳ ǪḀḀ ṲṲṡḛḛṛ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṛααṇḳṡ ααḡḛḛṇṭṡ ḅααṡḛḛḍ ṓṓṇ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. Ḟḭḭṇḍ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ ẁḥḛḛṛḛḛ ααḡḛḛṇṭṡ ḍḛḛααḽ ẁḭḭṭḥ ṃṓṓṛḛḛ ϝṛṵṵṡṭṛααṭḛḛḍ ͼṵṵṡṭṓṓṃḛḛṛṡ ṭḥααṇ ṭḥḛḛḭḭṛ ṗḛḛḛḛṛṡ ḭḭṇ ṓṓṇḛḛ ͼḽḭḭͼḳ.龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṇḳḭḭṇḡ ḅẏẏ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])}
        },
        "complexity": {
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṭḽḭḭḛḛṛṡ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ ṛḛḛʠṵṵḭḭṛḛḛ ẏẏṓṓṵṵṛ ααṭṭḛḛṇṭḭḭṓṓṇ. ṎṎṵṵṛ ṃααͼḥḭḭṇḛḛ ḽḛḛααṛṇḭḭṇḡ ṃṓṓḍḛḛḽ ḭḭḍḛḛṇṭḭḭϝḭḭḛḛṡ ͼṓṓṃṗḽḛḛẋ ṓṓṛ ααṭẏẏṗḭḭͼααḽ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ ϝṓṓṛ ϝṵṵṛṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṭḽḭḭḛḛṛṡ龍ผู้]"])}
        },
        "sentiment": {
          "sub_categories": {
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ龍ผู้]"])},
            "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ龍ผู้]"])}
          },
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḭḭṇḡ ẁḥḛḛṛḛḛ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ḍḭḭṡṗḽααẏẏḛḛḍ ḛḛḭḭṭḥḛḛṛ ḍḛḛḽḭḭḡḥṭ ṓṓṛ ϝṛṵṵṡṭṛααṭḭḭṓṓṇ. ṲṲṡḛḛ ṭḥḛḛ ḍṛṓṓṗ-ḍṓṓẁṇ ṭṓṓ ṡḛḛḽḛḛͼṭ ṭḥḛḛ ṭẏẏṗḛḛ ṓṓϝ ṡḛḛṇṭḭḭṃḛḛṇṭ ẏẏṓṓṵṵ'ṛḛḛ ḽṓṓṓṓḳḭḭṇḡ ϝṓṓṛ ḭḭṇ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. Ḍṛααḡ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ααṭṭṛḭḭḅṵṵṭḛḛ ṭṓṓ ṭḥḛḛ ṭṓṓṗ ṭṓṓ ṡḛḛḛḛ ṭḥḛḛ ṛααṭḭḭṓṓ ṓṓϝ ṭḥḛḛ ͼḥṓṓṡḛḛṇ ṡḛḛṇṭḭḭṃḛḛṇṭ ḭḭṇ ααḽḽ ẏẏṓṓṵṵṛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ.龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])}
        },
        "reviewed": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṡṭααṭṵṵṡ龍ผู้]"])},
          "sub_categories": {
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])}
          }
        },
        "channel": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])}
        },
        "connection": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])}
        },
        "csat": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ḍḛḛṭḛḛͼṭṡ ḽααṇḡṵṵααḡḛḛṡ ḭḭṇ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. ṲṲṡḛḛ ṭḥḛḛ ḍṛṓṓṗ-ḍṓṓẁṇ ṭṓṓ ṡḛḛḽḛḛͼṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḭḭṇ ṡṗḛḛͼḭḭϝḭḭͼ ḽααṇḡṵṵααḡḛḛṡ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḭḭṇḡ. Ḍṛααḡ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ααṭṭṛḭḭḅṵṵṭḛḛ ṭṓṓ ṭḥḛḛ ṭṓṓṗ ṭṓṓ ḡḛḛṭ ααṇ ṓṓṽḛḛṛṽḭḭḛḛẁ ṓṓϝ ααḽḽ ṭḥḛḛ ḽααṇḡṵṵααḡḛḛṡ ṵṵṡḛḛḍ ḭḭṇ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ.龍ผู้]"])},
          "tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])}
        }
      },
      "info": {
        "paragraph_1_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḬḬṇṡḭḭḡḥṭṡ龍ผู้]"])},
        "paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ", _interpolate(_named("paragraph_2_item")), " ẁḭḭṭḥ ḬḬṇṡḭḭḡḥṭṡ ṭḥααṇ ẁḭḭṭḥ ṛḛḛḡṵṵḽααṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝḭḭḽṭḛḛṛṡ. Ḡḛḛṭ αα ṽḭḭṡṵṵααḽ ṓṓṽḛḛṛṽḭḭḛḛẁ ṓṓϝ ααḽḽ ṭḥḛḛ ḍααṭαα ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳṡ, ααṇḍ ḛḛẋṗḽṓṓṛḛḛ ḭḭṇṡḭḭḡḥṭṡ ḽḭḭḳḛḛ:龍ผู้]"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṵṵṡḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇṡḭḭḡḥṭṡ龍ผู้]"])},
        "bullet_point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇḍḛḛṛṡṭααṇḍ ṭḥḛḛ ṛḛḛḽααṭḭḭṽḛḛ ḭḭṃṗṓṓṛṭααṇͼḛḛ ṓṓϝ ṡṗḛḛͼḭḭϝḭḭͼ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṭṭṛḭḭḅṵṵṭḛḛṡ ḭḭṇ ṛḛḛḽααṭḭḭṓṓṇ ṭṓṓ ṓṓṭḥḛḛṛṡ龍ผู้]"])},
        "bullet_point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ ẏẏṓṓṵṵṛ ṭṓṓṗ- ααṇḍ ḽṓṓẁ-ṗḛḛṛϝṓṓṛṃḭḭṇḡ ααḡḛḛṇṭṡ ẁḭḭṭḥ ḅṵṵḭḭḽṭ-ḭḭṇ ααḡḛḛṇṭ ṗḛḛṛϝṓṓṛṃααṇͼḛḛ ṛααṇḳḭḭṇḡ龍ผู้]"])},
        "paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("paragraph_1_item")), " ḭḭṡ αα ṗṓṓẁḛḛṛϝṵṵḽ ḭḭṇṭḛḛṛααͼṭḭḭṽḛḛ ḍααṭαα ṃḭḭṇḭḭṇḡ ṭṓṓṓṓḽ ḥḛḛḽṗḭḭṇḡ ẏẏṓṓṵṵ ϝḭḭṇḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ ṃṓṓṡṭ ṇḛḛḛḛḍ ṛḛḛṽḭḭḛḛẁḭḭṇḡ.龍ผู้]"])},
        "paragraph_2_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓ ṡṓṓ ṃṵṵͼḥ ṃṓṓṛḛḛ龍ผู้]"])},
        "paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ḀḀḡḛḛṇṭ ͼḥααṛṭṡ ααṛḛḛ ", _interpolate(_named("paragraph_3_item_1")), " – ṵṵṗḍααṭḭḭṇḡ ϝḭḭḽṭḛḛṛṡ ṓṓṛ ͼḽḭḭͼḳḭḭṇḡ ṓṓṇ ṭḥḛḛ ṡḛḛḡṃḛḛṇṭḛḛḍ ͼḥααṛṭ ṡḽḭḭͼḛḛṡ ṓṓṇ ḛḛḭḭṭḥḛḛṛ ṓṓṇḛḛ ẁḭḭḽḽ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṵṵṗḍααṭḛḛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḽḭḭṡṭ. ", _interpolate(_named("paragraph_3_item_2")), " ṭṓṓ ḛḛẋṗḽṓṓṛḛḛ ḍḛḛṭααḭḭḽḛḛḍ ḅṛḛḛααḳḍṓṓẁṇṡ ṓṓṛ ṛααṭḭḭṓṓṡ ṓṓϝ ṭḥḛḛ ḍḭḭϝϝḛḛṛḛḛṇṭ ḛḛḽḛḛṃḛḛṇṭṡ.龍ผู้]"])},
        "paragraph_3_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṇṭḛḛṛͼṓṓṇṇḛḛͼṭḛḛḍ龍ผู้]"])},
        "paragraph_3_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṛṛααṇḡḛḛ ṭḥḛḛ ϝḭḭḽṭḛḛṛ ααṭṭṛḭḭḅṵṵṭḛḛṡ龍ผู้]"])},
        "paragraph_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḬḬṇṡḭḭḡḥṭṡ ḍḭḭṡṗḽααẏẏṡ ṓṓṇḽẏẏ ", _interpolate(_named("paragraph_4_item")), ". Ŵḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṛḛḛṃṓṓṽḛḛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ ẁḭḭṭḥṓṓṵṵṭ ṛḛḛααḽ ṛḛḛṽḭḭḛḛẁḭḭṇḡ ṽααḽṵṵḛḛ, ṡṵṵͼḥ ααṡ ααṵṵṭṓṓḡḛḛṇḛḛṛααṭḛḛḍ ṃḛḛṡṡααḡḛḛṡ, ṡṗααṃ, ḅṓṓṭ ṛḛḛṗḽḭḭḛḛṡ, ṓṓṛ ṃḛḛṡṡααḡḛḛṡ ṭḥααṭ ḥααṽḛḛ ṇṓṓ ṗṛṓṓṗḛḛṛ ḍḭḭααḽṓṓḡṵṵḛḛ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ṡṵṵṗṗṓṓṛṭ ṛḛḛṗ.龍ผู้]"])},
        "paragraph_4_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼḽḛḛααṇ ααṇḍ ͼḽṓṓṡḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥααṭ ḍṓṓ ḬḬ ṡḛḛḛḛ ḥḛḛṛḛḛ?龍ผู้]"])}
      },
      "out_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("count")), " ṓṓṵṵṭ ṓṓϝ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ṓṓṵṵṭ ṓṓϝ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("count")), " ṓṓṵṵṭ ṓṓϝ ", _interpolate(_named("total")), "龍ผู้]"])])},
      "perc_of_parent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("percentage")), " ṓṓϝ ", _interpolate(_named("category")), "龍ผู้]"])},
      "sunburst_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ϝḛḛṭͼḥ龍ผู้]"])},
      "sunburst_root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
      "unknown_category_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " Ṅ/ḀḀ龍ผู้]"])},
      "agents_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭṡ龍ผู้]"])},
      "conversations_sunburst_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "perc_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍(", _interpolate(_named("percentage")), " ṓṓϝ ṭṓṓṭααḽ)龍ผู้]"])},
      "sunburst_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛḽṓṓααḍ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭṡ龍ผู้]"])}
    },
    "survey_sidebar": {
      "empty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵṭ ẁḛḛ'ṛḛḛ ṡṵṵṛḛḛ ḭḭṭ ẁḭḭḽḽ ḅḛḛ ḡṛḛḛααṭ ṓṓṇͼḛḛ ḭḭṭ ααṛṛḭḭṽḛḛṡ.龍ผู้]"])},
      "custom_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
      "empty_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ḽḛḛϝṭ龍ผู้]"])},
      "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ṡͼṓṓṛḛḛ ααṽααḭḭḽααḅḽḛḛ ĵṵṵṡṭ ẏẏḛḛṭ...龍ผู้]"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ龍ผู้]"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇ龍ผู้]"])},
      "response_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
      "tag_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ ṭααḡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ ͼṓṓṃṃḛḛṇṭ ṭααḡṡ龍ผู้]"])}
    },
    "date_filter": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ ṛααṇḡḛḛ ϝḭḭḽṭḛḛṛ ααṗṗḽḭḭḛḛḍ龍ผู้]"])}
    }
  },
  "csat_dashboard": {
    "csat_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "pagination_item_replies_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("count")), " ṛḛḛṗḽẏẏ"]), _normalize([_interpolate(_named("count")), " ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])])},
    "tags": {
      "sources": {
        "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈḚḚṢ龍ผู้]"])},
        "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])}
      },
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍḭḭḡ ḍḛḛḛḛṗḛḛṛ ḭḭṇṭṓṓ ẁḥẏẏ αα ", _interpolate(_list(0)), " ṡͼṓṓṛḛḛ ẁααṡ ḡḭḭṽḛḛṇ ḅẏẏ ṡḛḛṭṭḭḭṇḡ ṵṵṗ ṛḛḛααṡṓṓṇṡ ṵṵṇḍḛḛṛ ṡṵṵṛṽḛḛẏẏ ṡḛḛṭṭḭḭṇḡṡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ", _interpolate(_list(0)), " ṛḛḛααṡṓṓṇṡ ẏẏḛḛṭ龍ผู้]"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṡṵṵṛṽḛḛẏẏ ṛḛḛααṡṓṓṇṡ龍ผู้]"])}
      },
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭṭḥ ṭααḡṡ:龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ龍ผู้]"])}
    },
    "ticket_count_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "breakdown_card": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḅẏẏ龍ผู้]"])},
      "first_column_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ龍ผู้]"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ龍ผู้]"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
      }
    },
    "breakdown_sources": {
      "csat_reasons": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("surveyType")), " ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛḍḭḭͼṭḛḛḍ ", _interpolate(_named("surveyType")), " ḍṛḭḭṽḛḛṛṡ龍ผู้]"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṓṓṵṵṛͼḛḛ龍ผู้]"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])}
    },
    "conversations": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇṡḛḛṡ (", _interpolate(_named("n")), ")"]), _normalize(["Ṣṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇṡḛḛ (", _interpolate(_named("n")), ")"]), _normalize(["Ṣṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇṡḛḛṡ (", _interpolate(_named("n")), ")龍ผู้]"])])},
      "no_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵḅĵḛḛͼṭ龍ผู้]"])}
    },
    "csat_by_users": {
      "csat_ticket_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭṭḥ ", _interpolate(_list(0)), "龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḅẏẏ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ龍ผู้]"])},
      "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
      "response_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ṛααṭḛḛ龍ผู้]"])},
      "ticket_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])}
    },
    "donut": {
      "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṡͼṓṓṛḛḛ ḭḭṡ αα ṡṵṵṃ ṓṓϝ ααḽḽ ṛḛḛṡṗṓṓṇṡḛḛṡ ḍḭḭṽḭḭḍḛḛḍ ḅẏẏ ṭḥḛḛ ṡṵṵṃ ṓṓϝ ṃααẋḭḭṃṵṵṃ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
      "edit_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṭααṛḡḛḛṭ龍ผู้]"])},
      "remove_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ṭααṛḡḛḛṭ龍ผู้]"])},
      "set_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ αα ṭααṛḡḛḛṭ龍ผู้]"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααṛḡḛḛṭ龍ผู้]"])},
      "target_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭααṛḡḛḛṭ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])}
    },
    "filter_bar": {
      "predicted_dimensions": {
        "size": {
          "bitesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓṛṭ龍ผู้]"])},
          "extended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḛḛṛẏẏ ḽṓṓṇḡ龍ผู้]"])},
          "lengthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓṇḡ龍ผู้]"])},
          "mid_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭḍ-ḽḛḛṇḡṭḥ龍ผู้]"])}
        }
      },
      "predicted_tags": {
        "confusing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝṵṵṡḭḭṇḡ龍ผู้]"])},
        "support": {
          "not_helpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ḥḛḛḽṗϝṵṵḽ龍ผู้]"])},
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααḍ ṡṵṵṗṗṓṓṛṭ龍ผู้]"])},
          "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṓṓḍ ṡṵṵṗṗṓṓṛṭ龍ผู้]"])}
        },
        "emoji": {
          "only_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṓṓṭḭḭṓṓṇṡ-ḡααḽṓṓṛḛḛ龍ผู้]"])}
        },
        "account": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ龍ผู้]"])}
        },
        "agent_specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ ϝṓṓṛ ααḡḛḛṇṭ龍ผู้]"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗḽααḭḭṇṭ龍ผู้]"])},
        "crumbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛṵṵṃḅṡ龍ผู้]"])},
        "issue": {
          "not_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṡṡṵṵḛḛ ṇṓṓṭ ṡṓṓḽṽḛḛḍ龍ผู้]"])},
          "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṡṡṵṵḛḛ ṡṓṓḽṽḛḛḍ龍ผู้]"])}
        },
        "negative_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
        "outcome": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααḍ ṓṓṵṵṭͼṓṓṃḛḛ龍ผู้]"])}
        },
        "positive_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
        "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛααḭḭṡḛḛ龍ผู้]"])},
        "product": {
          "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααḍ ṗṛṓṓḍṵṵͼṭ龍ผู้]"])}
        },
        "refund": {
          "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛϝṵṵṇḍ龍ผู้]"])}
        },
        "speed": {
          "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααṡṭ ṡṵṵṗṗṓṓṛṭ龍ผู้]"])},
          "slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽṓṓẁ ṡṵṵṗṗṓṓṛṭ龍ผู้]"])}
        },
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳṡ龍ผู้]"])}
      },
      "result_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
      "topic_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααḽḽṓṓͼααṭḛḛṡ ẏẏṓṓṵṵṛ ", _interpolate(_list(0)), " ḍααṭαα ḭḭṇṭṓṓ ṗṛḛḛ-ḍḛḛϝḭḭṇḛḛḍ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ. Ṣṓṓ ẏẏṓṓṵṵ ͼααṇ ṵṵṇḍḛḛṛṡṭααṇḍ ͼṓṓṃṗḽḛḛẋ ḭḭṇṡḭḭḡḥṭṡ ααṇḍ ḛḛẋṗḽṓṓṛḛḛ ͼααṵṵṡḛḛṡ ẁḭḭṭḥṓṓṵṵṭ ṃααṇṵṵααḽḽẏẏ ṡḭḭϝṭḭḭṇḡ ṭḥṛṓṓṵṵḡḥ ṭḥḛḛ ẁṛḭḭṭṭḛḛṇ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
      "all_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
      "all_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṵṵṛṽḛḛẏẏṡ龍ผู้]"])},
      "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛṡṗṓṓṇṡḛḛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "length_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṡḭḭẓḛḛ龍ผู้]"])},
      "length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṃṃḛḛṇṭ ṡḭḭẓḛḛ龍ผู้]"])},
      "length_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḭḭṭḥ 25% ṓṓϝ ͼṓṓṃṃḛḛṇṭṡ ͼṓṓṇṡḭḭṡṭḭḭṇḡ ṓṓϝ ṓṓṇḽẏẏ ṓṓṇḛḛ ẁṓṓṛḍ, ḽṓṓṇḡḛḛṛ ͼṓṓṃṃḛḛṇṭṡ ααṛḛḛ αα ṃṵṵṡṭ-ṛḛḛααḍ. Ḟḭḭṇḍ ṃṓṓṛḛḛ ḭḭṇṡḭḭḡḥṭϝṵṵḽ ϝḛḛḛḛḍḅααͼḳ ẁḭḭṭḥṓṓṵṵṭ ṗṵṵṭṭḭḭṇḡ ḭḭṇ ṭḥḛḛ ṃααṇṵṵααḽ ẁṓṓṛḳ, ααṡ ẁḛḛ ḍṓṓ ḭḭṭ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ϝṓṓṛ ẏẏṓṓṵṵ.龍ผู้]"])},
      "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇ ṓṓϝ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛααṡṓṓṇ ṓṓϝ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛṡ龍ผู้]"])},
      "scores_filter_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥḭḭṇḡ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ龍ผู้]"])},
      "topic_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛḍḭḭͼṭḛḛḍ ḍṛḭḭṽḛḛṛṡ龍ผู้]"])},
      "topic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṃṃḛḛṇṭ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "wordcloud_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍͼḽṓṓṵṵḍ ẁṓṓṛḍṡ龍ผู้]"])},
      "wordcloud_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁṓṓṛḍͼḽṓṓṵṵḍ ẁṓṓṛḍṡ龍ผู้]"])}
    },
    "funnel": {
      "since_last_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṡḭḭṇͼḛḛ ḽααṡṭ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])},
      "tickets_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
      "bar_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
      "bar_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "bar_na_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααḅḽḛḛ ṭṓṓ ͼααḽͼṵṵḽααṭḛḛ ṡṵṵṛṽḛḛẏẏ ṡḛḛṇṭ ṛααṭḭḭṓṓ ϝṛṓṓṃ ααḽḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḍṵṵḛḛ ṭṓṓ ḽααͼḳ ṓṓϝ ḍααṭαα.龍ผู้]"])},
      "bar_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])},
      "bar_surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ ṡḛḛṇṭ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝṵṵṇṇḛḛḽ龍ผู้]"])}
    },
    "n_of_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṓṓϝ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " ṓṓϝ ", _interpolate(_named("total"))]), _normalize([_interpolate(_named("n")), " ṓṓϝ ", _interpolate(_named("total")), "龍ผู้]"])])},
    "survey_replies": {
      "comment_categories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛḍḭḭͼṭḛḛḍ ", _interpolate(_named("surveyType")), " ḍṛḭḭṽḛḛṛṡ龍ผู้]"])},
      "comment_categories_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααḽḽṓṓͼααṭḛḛṡ ẏẏṓṓṵṵṛ ", _interpolate(_list(0)), " ḍααṭαα ḭḭṇṭṓṓ ṗṛḛḛ-ḍḛḛϝḭḭṇḛḛḍ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ. Ṣṓṓ ẏẏṓṓṵṵ ͼααṇ ṵṵṇḍḛḛṛṡṭααṇḍ ͼṓṓṃṗḽḛḛẋ ḭḭṇṡḭḭḡḥṭṡ ααṇḍ ḛḛẋṗḽṓṓṛḛḛ ͼααṵṵṡḛḛṡ ẁḭḭṭḥṓṓṵṵṭ ṃααṇṵṵααḽḽẏẏ ṡḭḭϝṭḭḭṇḡ ṭḥṛṓṓṵṵḡḥ ṭḥḛḛ ẁṛḭḭṭṭḛḛṇ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
      "comment_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṡḭḭẓḛḛ龍ผู้]"])},
      "comment_size_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḭḭṭḥ 25% ṓṓϝ ͼṓṓṃṃḛḛṇṭṡ ͼṓṓṇṡḭḭṡṭḭḭṇḡ ṓṓϝ ṓṓṇḽẏẏ ṓṓṇḛḛ ẁṓṓṛḍ, ḽṓṓṇḡḛḛṛ ͼṓṓṃṃḛḛṇṭṡ ααṛḛḛ αα ṃṵṵṡṭ-ṛḛḛααḍ. Ḟḭḭṇḍ ṃṓṓṛḛḛ ḭḭṇṡḭḭḡḥṭϝṵṵḽ ϝḛḛḛḛḍḅααͼḳ ẁḭḭṭḥṓṓṵṵṭ ṗṵṵṭṭḭḭṇḡ ḭḭṇ ṭḥḛḛ ṃααṇṵṵααḽ ẁṓṓṛḳ, ααṡ ẁḛḛ ḍṓṓ ḭḭṭ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ϝṓṓṛ ẏẏṓṓṵṵ.龍ผู้]"])},
      "comment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "csat_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛ龍ผู้]"])},
      "score_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])},
      "user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])}
    },
    "timeline": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " (ααṽḛḛṛααḡḛḛ)龍ผู้]"])},
      "bars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ͼṓṓṵṵṇṭ龍ผู้]"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
      "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ (ααṽḛḛṛααḡḛḛ)龍ผู้]"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααṛḡḛḛṭ龍ผู้]"])}
    },
    "timeline_card": {
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṭṛḛḛṇḍṡ ḅẏẏ龍ผู้]"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ͼṓṓṵṵṇṭ龍ผู้]"])},
      "sidebar_title": {
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽ龍ผู้]"])},
        "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ龍ผู้]"])},
        "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
        "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
        "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ龍ผู้]"])},
        "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛḭḭṽḛḛṛṡ龍ผู้]"])}
      }
    },
    "view_settings": {
      "date_type_csat_sent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṡṵṵṛṽḛḛẏẏ ṡḛḛṇṭ ḍααṭḛḛ龍ผู้]"])},
      "date_type_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṡṵṵṛṽḛḛẏẏ ṛḛḛṗḽẏẏ ḍααṭḛḛ龍ผู้]"])},
      "owner_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇ ", _interpolate(_list(0)), " ṡͼṓṓṛḛḛ ṭṓṓ龍ผู้]"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗḽααẏẏ ḍααṭαα ḅααṡḛḛḍ ṓṓṇ龍ผู้]"])},
      "date_type_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṛḛḛααṭḭḭṓṓṇ ḍααṭḛḛ龍ผู้]"])},
      "owner_type_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓẁṇḛḛṛ ṓṓϝ ṭḥḛḛ ṭḭḭͼḳḛḛṭ龍ผู้]"])},
      "owner_type_most_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααḡḛḛṇṭ ẁḭḭṭḥ ṃṓṓṡṭ ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])}
    },
    "workspaces_timeline_card": {
      "average": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " (ααṽḛḛṛααḡḛḛ)龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṓṓṽḛḛṛ ṭḭḭṃḛḛ龍ผู้]"])},
      "bar_tooltip_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ͼṓṓṵṵṇṭ龍ผู้]"])},
      "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
      "sidebar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
    },
    "csat_timeline": {
      "left_axis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ααṇḍ ḬḬǪṢ龍ผู้]"])},
      "right_axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "word_cloud": {
      "conversations_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ ḭḭṇͼḽṵṵḍḛḛ ṭḥḛḛṡḛḛ ẁṓṓṛḍṡ:龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍ ͼḽṓṓṵṵḍ龍ผู้]"])}
    },
    "card_labels": {
      "csat_by_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ḅẏẏ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "donut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṓṓṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])},
      "funnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝṵṵṇṇḛḛḽ龍ผู้]"])},
      "survey_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])},
      "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṓṓṽḛḛṛ ṭḭḭṃḛḛ龍ผู้]"])},
      "word_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍ ͼḽṓṓṵṵḍ龍ผู้]"])}
    },
    "missing_value": {
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼḥααṇṇḛḛḽ龍ผู้]"])},
      "conversation_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṓṓṵṵṛͼḛḛ龍ผู้]"])},
      "csat_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "csat_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
      "helpdesk_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭααḡṡ龍ผู้]"])},
      "predicted_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṃṃḛḛṇṭ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
    }
  },
  "dashboard": {
    "average_review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "goal": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
    },
    "looker": {
      "assignments": {
        "banner": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍααṭαα ααṽααḭḭḽααḅḽḛḛ ḅḛḛͼααṵṵṡḛḛ ṇṓṓ ααṡṡḭḭḡṇṃḛḛṇṭṡ ḥααṽḛḛ ḅḛḛḛḛṇ ͼṛḛḛααṭḛḛḍ ṓṓṛ ṇṓṓ ͼẏẏͼḽḛḛṡ ḥααṽḛḛ ḅḛḛḛḛṇ ṡṭααṛṭḛḛḍ. Ḉḥḛḛͼḳ ḅααͼḳ ḽααṭḛḛṛ ṓṓṛ ααḍĵṵṵṡṭ ṭḥḛḛ ϝḭḭḽṭḛḛṛṡ.龍ผู้]"])}
        }
      },
      "reviews": {
        "banner": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽṓṓṛḛḛ ẁḥααṭ'ṡ ṇḛḛẁ龍ผู้]"])}
        }
      }
    },
    "pagination_item_default_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("count")), " ḭḭṭḛḛṃ"]), _normalize([_interpolate(_named("count")), " ḭḭṭḛḛṃṡ龍ผู้]"])])},
    "pagination_item_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
    "passed_reviews_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "percentage": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
    },
    "review_status": {
      "value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽ龍ผู้]"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ龍ผู้]"])},
      "pass_with_minors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ẁḭḭṭḥ ṃḭḭṇṓṓṛṡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ/Ḟααḭḭḽ龍ผู้]"])}
    },
    "review_time_value_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "stats_card": {
      "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
    },
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ龍ผู้]"])},
    "average_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ (ḥ)龍ผู้]"])},
    "average_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ (ṃḭḭṇ)龍ผู้]"])},
    "card_labels": {
      "category_ratings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("categoryName")), " ṛααṭḭḭṇḡṡ龍ผู้]"])},
      "csat_help": {
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ḈṢḀḀṪ = [ṡṵṵṃ ṓṓϝ ϝḛḛḛḛḍḅααͼḳ ṡͼṓṓṛḛḛṡ ÷ (ͼṓṓṵṵṇṭ ṓṓϝ ααḽḽ ṛḛḛṡṗṓṓṇṡḛḛṡ × ṃααẋ ṗṓṓṡṡḭḭḅḽḛḛ ṡͼṓṓṛḛḛ)] × 100%龍ผู้]"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḈṢḀḀṪ ṡͼṓṓṛḛḛ ṇṓṓṛṃααḽḭḭẓḛḛṡ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ ṡͼṓṓṛḛḛ ẁḭḭṭḥḭḭṇ αα ṡͼααḽḛḛ ṓṓϝ 0-100% ααṇḍ ṭααḳḛḛṡ ααṇ ααṽḛḛṛααḡḛḛ ṓṓϝ ṭḥḛḛ ṡṭααṇḍααṛḍḭḭẓḛḛḍ ṡͼṓṓṛḛḛṡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ (Ḉṵṵṡṭṓṓṃḛḛṛ Ṣααṭḭḭṡϝααͼṭḭḭṓṓṇ Ṣͼṓṓṛḛḛ) ʠṵṵααṇṭḭḭϝḭḭḛḛṡ ḥṓṓẁ ḥααṗṗẏẏ ẏẏṓṓṵṵṛ ͼṵṵṡṭṓṓṃḛḛṛṡ ααṛḛḛ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ṡḛḛṛṽḭḭͼḛḛṡ.龍ผู้]"])}
      },
      "help_reviews": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḚḚααͼḥ ṛḛḛṽḭḭḛḛẁ ṛḛḛṗṛḛḛṡḛḛṇṭṡ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ḅẏẏ ṓṓṇḛḛ ṗḛḛṛṡṓṓṇ ṓṓṇ ṓṓṇḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ. ḀḀ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼααṇ ḥααṽḛḛ ṃṓṓṛḛḛ ṭḥααṇ ṓṓṇḛḛ ṛḛḛṽḭḭḛḛẁ (ḭḭϝ ṭẁṓṓ ṓṓṛ ṃṓṓṛḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛ ṡααṃḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ).龍ผู้]"])},
      "quality_scores_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααͼḳ ṭḥḛḛ ḛḛṽṓṓḽṵṵṭḭḭṓṓṇ ṓṓϝ ṛḛḛṽḭḭḛḛẁḛḛṛ'ṡ ṡͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
      "quality_scores_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααͼḳ ṭḥḛḛ ḛḛṽṓṓḽṵṵṭḭḭṓṓṇ ṓṓϝ ααḡḛḛṇṭṡ' ṡͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
      "responses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛṡ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])])},
      "reviews_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁṡ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])])},
      "help_reviews_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ:龍ผู้]"])},
      "iqs_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ (ḬḬṇṭḛḛṛṇααḽ Ǫṵṵααḽḭḭṭẏẏ Ṣͼṓṓṛḛḛ) ḭḭṡ ṭḥḛḛ ṛḛḛṡṵṵḽṭ ṓṓϝ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ = ṡṵṵṃ ṓṓϝ ṛḛḛṽḭḭḛḛẁ ṡͼṓṓṛḛḛṡ ÷ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṭ'ṡ ͼααḽͼṵṵḽααṭḛḛḍ ααṡ ααṇ ααṽḛḛṛααḡḛḛ, ṭααḳḭḭṇḡ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ ααḽḽ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ṡͼṓṓṛḛḛṡ (ẁḛḛḭḭḡḥṭḛḛḍ ααṽḛḛṛααḡḛḛ ṓṓϝ ṭḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ ṡͼṓṓṛḛḛ) ṃααṭͼḥḭḭṇḡ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ϝḭḭḽṭḛḛṛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ.龍ผู้]"])}
      },
      "overview_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ ͼααṛḍ.龍ผู้]"])},
      "overview_error_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ẏẏṓṓṵṵṛ ϝḭḭḽṭḛḛṛṡ ṭṓṓ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
      "pass_rate_help": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗααṡṡ ṛααṭḛḛ ḭḭṇḍḭḭͼααṭḛḛṡ ṭḥḛḛ ṓṓṵṵṭͼṓṓṃḛḛ ṓṓϝ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
        "tooltip": {
          "fail_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽ ḭḭϝ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ṡͼṓṓṛḛḛ ḭḭṡ ", _interpolate(_named("failScore")), " ṓṓṛ ḽṓṓẁḛḛṛ龍ผู้]"])},
          "pass_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ḭḭϝ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ṡͼṓṓṛḛḛ ḭḭṡ ", _interpolate(_named("passScore")), " ṓṓṛ ḥḭḭḡḥḛḛṛ龍ผู้]"])}
        },
        "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṭ ḭḭṡ ḍḛḛṭḛḛṛṃḭḭṇḛḛḍ ḅẏẏ αα ṗṛḛḛ-ḍḛḛϝḭḭṇḛḛḍ ḅααṡḛḛḽḭḭṇḛḛ, ṃḛḛααṇḭḭṇḡ ẏẏṓṓṵṵ:龍ผู้]"])},
        "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ṛααṭḛḛ = Ṣṵṵṃ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ ṗααṡṡḛḛḍ ÷ Ṣṵṵṃ ṓṓϝ ṭṓṓṭααḽ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗααṡṡ ṛααṭḛḛ ḭḭṇḍḭḭͼααṭḛḛṡ αα ṗḛḛṛͼḛḛṇṭααḡḛḛ ṓṓϝ ḥṓṓẁ ṃααṇẏẏ ṛḛḛṽḭḭḛḛẁṡ ṗααṡṡ ẁḭḭṭḥṓṓṵṵṭ ϝααḭḭḽḭḭṇḡ ḛḛḭḭṭḥḛḛṛ ͼṛḭḭṭḭḭͼααḽ ṓṓṛ ṇṓṓṇ-ͼṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])}
      },
      "quality_scores_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ḬḬϝ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛ ḡḭḭṽḛḛṡ 3 ṛḛḛṽḭḭḛḛẁṡ ṓṓṇ ṭḥḭḭṡ ḍααẏẏ, ṭḥḛḛ ṇṵṵṃḅḛḛṛ ḍḭḭṡṗḽααẏẏḛḛḍ ḭḭṡ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ṓṓϝ ṭḥḛḛ 3 ṡͼṓṓṛḛḛṡ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "quality_scores_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ḬḬϝ ṭḥḛḛ ααḡḛḛṇṭ ṛḛḛͼḛḛḭḭṽḛḛṡ 3 ṛḛḛṽḭḭḛḛẁṡ ṓṓṇ ṭḥḭḭṡ ḍααẏẏ, ṭḥḛḛ ṇṵṵṃḅḛḛṛ ḍḭḭṡṗḽααẏẏḛḛḍ ḭḭṡ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ṓṓϝ ṭḥḛḛ 3 ṡͼṓṓṛḛḛṡ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "ratings_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṭααḳḛḛṇ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ: ͼααṭḛḛḡṓṓṛẏẏ ẁḛḛḭḭḡḥṭ, ͼṛḭḭṭḭḭͼααḽ ṡṭααṭṵṵṡ (ṵṵṇḽḭḭḳḛḛ Ṣͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ).龍ผู้]"])},
      "ratings_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ͼααṇ ḅḛḛ ṃααṛḳḛḛḍ ṇ/αα ḭḭṇ ṛḛḛṽḭḭḛḛẁṡ, ṭḥḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ ṗḛḛṛ ͼααṭḛḛḡṓṓṛẏẏ ṃααẏẏ ḅḛḛ ḽṓṓẁḛḛṛ ṭḥααṇ ṭṓṓṭααḽ ṛḛḛṽḭḭḛḛẁṡ ḅẏẏ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ龍ผู้]"])},
      "ratings_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṭααḳḛḛṇ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ: ͼααṭḛḛḡṓṓṛẏẏ ẁḛḛḭḭḡḥṭ, ͼṛḭḭṭḭḭͼααḽ ṡṭααṭṵṵṡ (ṵṵṇḽḭḭḳḛḛ Ṣͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ).龍ผู้]"])},
      "ratings_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ͼααṇ ḅḛḛ ṃααṛḳḛḛḍ ṇ/αα ḭḭṇ ṛḛḛṽḭḭḛḛẁṡ, ṭḥḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ ṗḛḛṛ ͼααṭḛḛḡṓṓṛẏẏ ṃααẏẏ ḅḛḛ ḽṓṓẁḛḛṛ ṭḥααṇ ṭṓṓṭααḽ ṛḛḛṽḭḭḛḛẁṡ ṗḛḛṛ ααḡḛḛṇṭ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ龍ผู้]"])},
      "scores_by_category_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṭααḳḛḛṇ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ: ͼααṭḛḛḡṓṓṛẏẏ ẁḛḛḭḭḡḥṭ, ͼṛḭḭṭḭḭͼααḽ ṡṭααṭṵṵṡ (ṵṵṇḽḭḭḳḛḛ Ṣͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ).龍ผู้]"])},
      "scores_by_category_given_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " Ḉααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗṡ ααṛḛḛ ḍḭḭṡṗḽααẏẏḛḛḍ ṡḛḛṗααṛααṭḛḛḽẏẏ. Ṫḥḛḛ ṡͼṓṓṛḛḛ ϝṓṓṛ ḛḛααͼḥ ḡṛṓṓṵṵṗ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ḅẏẏ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ͼααṭḛḛḡṓṓṛẏẏ ṡͼṓṓṛḛḛ ϝṓṓṛ ṭḥααṭ ḡṛṓṓṵṵṗ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ṡααṃḛḛ ṗḛḛṛḭḭṓṓḍ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "scores_by_category_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛ:龍ผู้]"])},
      "scores_by_category_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṭααḳḛḛṇ ḭḭṇṭṓṓ ααͼͼṓṓṵṵṇṭ: ͼααṭḛḛḡṓṓṛẏẏ ẁḛḛḭḭḡḥṭ, ͼṛḭḭṭḭḭͼααḽ ṡṭααṭṵṵṡ (ṵṵṇḽḭḭḳḛḛ Ṣͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ).龍ผู้]"])},
      "scores_by_category_received_help_line_4_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " Ḉααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗṡ ααṛḛḛ ḍḭḭṡṗḽααẏẏḛḛḍ ṡḛḛṗααṛααṭḛḛḽẏẏ. Ṫḥḛḛ ṡͼṓṓṛḛḛ ϝṓṓṛ ḛḛααͼḥ ḡṛṓṓṵṵṗ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ḅẏẏ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ͼααṭḛḛḡṓṓṛẏẏ ṡͼṓṓṛḛḛ ϝṓṓṛ ṭḥααṭ ḡṛṓṓṵṵṗ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ṡααṃḛḛ ṗḛḛṛḭḭṓṓḍ. Ṣḛḛḛḛ ḥṓṓẁ ḛḛααͼḥ ͼḛḛḽḽ ḭḭṡ ͼααḽͼṵṵḽααṭḛḛḍ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "baseline": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃααṡḛḛḽḭḭṇḛḛ ", _interpolate(_named("count")), "%龍ผู้]"])},
      "critical_error_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḭḭṭḭḭͼααḽ ḛḛṛṛṓṓṛ ϝṛḛḛḛḛ龍ผู้]"])},
      "critical_error_free_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṛḭḭṭḭḭͼααḽ ḛḛṛṛṓṓṛ ϝṛḛḛḛḛ ṛααṭḛḛ ḭḭṇḍḭḭͼααṭḛḛṡ αα ṗḛḛṛͼḛḛṇṭααḡḛḛ ṓṓϝ ḥṓṓẁ ṃααṇẏẏ ṛḛḛṽḭḭḛḛẁṡ ṗααṡṡ ẁḭḭṭḥṓṓṵṵṭ ϝααḭḭḽḭḭṇḡ ααṇẏẏ ͼṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])},
      "internal_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛṛṇααḽ Ǫṵṵααḽḭḭṭẏẏ Ṣͼṓṓṛḛḛ龍ผู้]"])},
      "other_reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍'ṎṎṭḥḛḛṛ' ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])},
      "period_change": {
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛḭḭṓṓḍḭḭͼ ͼḥααṇḡḛḛ龍ผู้]"])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααḭḭḽẏẏ ͼḥααṇḡḛḛ龍ผู้]"])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ ͼḥααṇḡḛḛ龍ผู้]"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳḽẏẏ ͼḥααṇḡḛḛ龍ผู้]"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶḛḛααṛḽẏẏ ͼḥααṇḡḛḛ龍ผู้]"])}
      },
      "quality_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ龍ผู้]"])},
      "quality_scores_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ḭḭṇ ḛḛḭḭṭḥḛḛṛ ḍααẏẏṡ ṓṓṛ ẁḛḛḛḛḳṡ.龍ผู้]"])},
      "quality_scores_given_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽͼṵṵḽααṭḭḭṓṓṇ = ṭṓṓṭααḽ ṡͼṓṓṛḛḛ ϝṓṓṛ ḍααẏẏ ṓṓṛ ẁḛḛḛḛḳ ḍḭḭṽḭḭḍḛḛḍ ḅẏẏ ṭṓṓṭααḽ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ龍ผู้]"])},
      "quality_scores_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ḭḭṇ ḛḛḭḭṭḥḛḛṛ ḍααẏẏṡ ṓṓṛ ẁḛḛḛḛḳṡ.龍ผู้]"])},
      "quality_scores_received_help_line_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽͼṵṵḽααṭḭḭṓṓṇ = ṭṓṓṭααḽ ṡͼṓṓṛḛḛ ϝṓṓṛ ḍααẏẏ ṓṓṛ ẁḛḛḛḛḳ ḍḭḭṽḭḭḍḛḛḍ ḅẏẏ ṭṓṓṭααḽ ṛḛḛṽḭḭḛḛẁṡ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
      "ratings_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛṡ ḅẏẏ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "ratings_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṭḥḛḛ ͼṓṓṃḅḭḭṇḛḛḍ ααṽḛḛṛααḡḛḛ ṓṓϝ ααḽḽ ͼααṭḛḛḡṓṓṛẏẏ ṛααṭḭḭṇḡṡ ḡḭḭṽḛḛṇ ḅẏẏ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛ ḍṵṵṛḭḭṇḡ ṭḥḭḭṡ ṗḛḛṛḭḭṓṓḍ.龍ผู้]"])},
      "ratings_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṭḥḛḛ ͼṓṓṃḅḭḭṇḛḛḍ ααṽḛḛṛααḡḛḛ ṓṓϝ ααḽḽ ͼααṭḛḛḡṓṓṛẏẏ ṛααṭḭḭṇḡṡ ṛḛḛͼḛḛḭḭṽḛḛḍ ṗḛḛṛ ααḡḛḛṇṭ ḍṵṵṛḭḭṇḡ ṭḥḭḭṡ ṗḛḛṛḭḭṓṓḍ.龍ผู้]"])},
      "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ϝṓṓṛ ḽṓṓẁ ṛααṭḭḭṇḡṡ龍ผู้]"])},
      "root_causes_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ϝṓṓṛ ṛααṭḭḭṇḡṡ龍ผู้]"])},
      "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ṡͼṓṓṛḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ龍ผู้]"])},
      "scores_by_category_given_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛ ḥṓṓẁ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ͼṓṓṃṗααṛḛḛ ṓṓṽḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
      "scores_by_category_given_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ṡͼṓṓṛḛḛṡ ḡḭḭṽḛḛṇ ϝṓṓṛ ḛḛααͼḥ ͼααṭḛḛḡṓṓṛẏẏ ṓṓṇ αα ͼḥṓṓṡḛḛṇ ḍααẏẏ ṓṓṛ ẁḛḛḛḛḳ.龍ผู้]"])},
      "scores_by_category_received_help_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛ ḥṓṓẁ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ͼṓṓṃṗααṛḛḛ ṓṓṽḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
      "scores_by_category_received_help_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṭḥḛḛ ααṽḛḛṛααḡḛḛ ṡͼṓṓṛḛḛṡ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝṓṓṛ ḛḛααͼḥ ͼααṭḛḛḡṓṓṛẏẏ ṓṓṇ αα ͼḥṓṓṡḛḛṇ ḍααẏẏ ṓṓṛ ẁḛḛḛḛḳ.龍ผู้]"])},
      "scores_by_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛṡ ḅẏẏ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "tickets_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
    },
    "category_tooltip": {
      "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
      "critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "rating_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ龍ผู้]"])}
    },
    "custom_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ/Ṫṓṓṭααḽ龍ผู้]"])},
    "exclude_self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "export_all_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗṓṓṛṭṡ ααḽḽ ͼααṛḍṡ龍ผู้]"])},
    "feedback_direction_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ龍ผู้]"])},
    "feedback_direction_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
    "pagination_item": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize(["ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
    "pagination_item_default": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḭḭṭḛḛṃ"]), _normalize(["ḭḭṭḛḛṃṡ龍ผู้]"])])},
    "response_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍 ", _interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛṡ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])])},
    "review_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁṡ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])])},
    "show_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣḥṓṓẁ ṓṓṇḛḛ ṃṓṓṛḛḛ"]), _normalize(["Ṣḥṓṓẁ ", _interpolate(_named("n")), " ṃṓṓṛḛḛ龍ผู้]"])])},
    "time_periods": {
      "last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ 30 ḍααẏẏṡ龍ผู้]"])},
      "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ 7 ḍααẏẏṡ龍ผู้]"])},
      "last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṃṓṓṇṭḥ龍ผู้]"])},
      "last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ẁḛḛḛḛḳ龍ผู้]"])},
      "this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃṓṓṇṭḥ龍ผู้]"])},
      "this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḛḛḛḛḳ龍ผู้]"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])}
    },
    "time_steps": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααẏẏṡ龍ผู้]"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥṡ龍ผู้]"])},
      "quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵααṛṭḛḛṛṡ龍ผู้]"])},
      "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳṡ龍ผู้]"])},
      "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶḛḛααṛṡ龍ผู้]"])}
    },
    "card_load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ ͼααṛḍ. Ḉḥααṇḡḛḛ ẏẏṓṓṵṵṛ ϝḭḭḽṭḛḛṛṡ ṭṓṓ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
    "card_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍααṭαα ṭṓṓ ḍḭḭṡṗḽααẏẏ.龍ผู้]"])},
    "monthly_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ ͼḥααṇḡḛḛ龍ผู้]"])},
    "navbar": {
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
      "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])},
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṡḥḅṓṓααṛḍṡ龍ผู้]"])}
    },
    "rating_scale_change": {
      "info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṛḛḛ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ.龍ผู้]"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααͼṭḭḭṽḛḛ ϝḭḭḽṭḛḛṛṡ ḭḭṇͼḽṵṵḍḛḛ ḍααṭαα ααͼṛṓṓṡṡ ḍḭḭϝϝḛḛṛḛḛṇṭ ṛααṭḭḭṇḡ ṡͼααḽḛḛṡ.龍ผู้]"])}
    },
    "all_pins_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼααṛḍ ṗḭḭṇṡ ẁḭḭṭḥ ṭḥḛḛṡḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
    "all_pins_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍααṡḥḅṓṓααṛḍ ṗḭḭṇṡ ẁḭḭṭḥ ṭḥḛḛṡḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
    "average_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṛḛḛṽḭḭḛḛẁ ṭḭḭṃḛḛ龍ผู้]"])},
    "card_customize_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓḛḛ龍ผู้]"])},
    "card_export_tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓẁṇḽṓṓααḍ ḈṢṾ龍ผู้]"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
    "category_picker": {
      "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])}
    },
    "category_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ḭḭṇṡḭḭḡḥṭṡ龍ผู้]"])},
    "chart_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛααṗḥ龍ผู้]"])},
    "comment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ"]), _normalize(["Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
    "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
    "data_grid_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṭṛḭḭẋ龍ผู้]"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ龍ผู้]"])},
    "former_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛṃḛḛṛ ṃḛḛṃḅḛḛṛ龍ผู้]"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛṛṇααḽ Ǫṵṵααḽḭḭṭẏẏ Ṣͼṓṓṛḛḛ龍ผู้]"])},
    "iqs_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ (ααṽḛḛṛααḡḛḛ)龍ผู้]"])},
    "passed_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ͼααṛḍ龍ผู้]"])},
    "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡṡ龍ผู้]"])},
    "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ"]), _normalize(["Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])])},
    "review_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḬḬḌ龍ผู้]"])},
    "review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḭḭṃḛḛ龍ผู้]"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "root_causes": {
      "no_causes_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṵṵṗ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
      "no_causes_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ḽḛḛṭ ẏẏṓṓṵṵ ḳṇṓṓẁ ẁḥẏẏ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṛḛḛ ḡḛḛṭṭḭḭṇḡ ḽṓṓẁ ṡͼṓṓṛḛḛṡ龍ผู้]"])},
      "no_causes_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ṡḛḛṭ ϝṓṓṛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
    },
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ龍ผู้]"])},
    "score_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ / Ṫṓṓṭααḽ龍ผู้]"])},
    "scores_by_category": {
      "grouping_options": {
        "by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
        "by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗṡ龍ผู้]"])},
        "by_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
      },
      "labels": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
      },
      "settings_modal": {
        "customize_order_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓḛḛ ṓṓṛḍḛḛṛ龍ผู้]"])},
        "group_by_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḅẏẏ龍ผู้]"])},
        "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααḽḽ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓḛḛ龍ผู้]"])}
      }
    },
    "self_reviews_exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "self_reviews_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇͼḽṵṵḍḛḛ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "self_reviews_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṓṓṇḽẏẏ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "sidebar": {
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḛḛααṛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "comment_hashtags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḥααṡḥṭααḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "comment_hashtags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "comment_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṃṃḛḛṇṭ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "helpdesk_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭααḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "helpdesk_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])},
      "helpdesk_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])},
      "scorecard_tags_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡͼṓṓṛḛḛͼααṛḍṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "scorecard_tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
      "scorecard_tags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "ticket_source_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥḭḭṇḡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "ticket_source_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṓṓṵṵṛͼḛḛ龍ผู้]"])},
      "ticket_source_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṡṓṓṵṵṛͼḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṛḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
      "workspace_empty_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥḭḭṇḡ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
    },
    "total_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ ṭḭḭṃḛḛṡ ḡḭḭṽḛḛṇ龍ผู้]"])},
    "user_filter_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṵṵṡḛḛṛṡ龍ผู้]"])},
    "user_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏṓṓṇḛḛ龍ผู้]"])},
    "user_group_filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥḭḭṇḡ ḡṛṓṓṵṵṗṡ.龍ผู้]"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḡṛṓṓṵṵṗṡ龍ผู้]"])}
    },
    "view_settings": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗḽααẏẏ ͼααṛḍṡ龍ผู้]"])},
      "date_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗḽααẏẏ ḍααṭαα ḅααṡḛḛḍ ṓṓṇ龍ผู้]"])},
      "date_type_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ͼṛḛḛααṭḭḭṓṓṇ ḍααṭḛḛ龍ผู้]"])},
      "date_type_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṛḛḛααṭḭḭṓṓṇ ḍααṭḛḛ龍ผู้]"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
      "show_decimal_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṭẁṓṓ ḍḛḛͼḭḭṃααḽ ṗḽααͼḛḛṡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
      "trigger_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ龍ผู้]"])}
    },
    "workspace_filter_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
    "workspace_filter_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
    "workspace_filter_whole_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥṓṓḽḛḛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
    "workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṇααṃḛḛ龍ผู้]"])},
    "workspace_picker": {
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
    }
  },
  "emoji_picker": {
    "category": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ龍ผู้]"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḽααḡṡ龍ผู้]"])},
      "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṓṓḍ ααṇḍ ḍṛḭḭṇḳ龍ผู้]"])},
      "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇḭḭṃααḽṡ ααṇḍ ṇααṭṵṵṛḛḛ龍ผู้]"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḅĵḛḛͼṭṡ龍ผู้]"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṓṓṗḽḛḛ ααṇḍ ḅṓṓḍẏẏ龍ผู้]"])},
      "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṽḛḛḽ ααṇḍ ṗḽααͼḛḛṡ龍ผู้]"])},
      "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḛḛʠṵṵḛḛṇṭḽẏẏ ṵṵṡḛḛḍ龍ผู้]"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ṛḛḛṡṵṵḽṭṡ龍ผู้]"])},
      "smileys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṃḭḭḽḛḛẏẏṡ ααṇḍ ḛḛṃṓṓṭḭḭͼṓṓṇ龍ผู้]"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẏẏṃḅṓṓḽṡ龍ผู้]"])}
    },
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḛḛṃṓṓĵḭḭ ϝṓṓṵṵṇḍ龍ผู้]"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ龍ผู้]"])},
    "user_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
  },
  "example_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
  "extension": {
    "tasks": {
      "assignment_back_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααͼḳ ṭṓṓ ṭααṡḳ ḽḭḭṡṭ龍ผู้]"])}
    },
    "add_connection": {
      "connections": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("connect")), " ṓṓṛ ḅṛṓṓẁṡḛḛ ẏẏṓṓṵṵṛ ḽḭḭṡṭ ṓṓϝ ", _interpolate(_named("link")), "龍ผู้]"])},
      "call_to_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṛḛḛ ͼṵṵṛṛḛḛṇṭḽẏẏ ṵṵṡḭḭṇḡ ṭḥḛḛ ", _interpolate(_named("workspace")), " ẁṓṓṛḳṡṗααͼḛḛ ṭḥααṭ ḭḭṡ ṇṓṓṭ ͼṓṓṇṇḛḛͼṭḛḛḍ ṭṓṓ ṭḥḭḭṡ ḍṓṓṃααḭḭṇ. Ḉḽḭḭͼḳ ḥḛḛṛḛḛ ṭṓṓ ͼṓṓṇṇḛḛͼṭ ḭḭṭ ṇṓṓẁ.龍ผู้]"])},
      "connected_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṽααḭḭḽααḅḽḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ'ṭ ḡḭḭṽḛḛ ϝḛḛḛḛḍḅααͼḳ ṓṓṇ ", _interpolate(_named("host")), " ĵṵṵṡṭ ẏẏḛḛṭ.龍ผู้]"])},
      "no_host_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṽḛḛ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ααṡ ṭḥḛḛ ααͼṭḭḭṽḛḛ ṭααḅ.龍ผู้]"])},
      "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḍṓṓṃααḭḭṇ ͼααṇ'ṭ ḅḛḛ ṛḛḛṽḭḭḛḛẁḛḛḍ ẏẏḛḛṭ. ḬḬϝ ḭḭṭ ṡḥṓṓṵṵḽḍ ḅḛḛ, ṇṓṓṭḭḭϝẏẏ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ.龍ผู้]"])},
      "add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭḥḭḭṡ ḍṓṓṃααḭḭṇ龍ผู้]"])},
      "connect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ẁḭḭṭḥ ", _interpolate(_named("host")), "龍ผู้]"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṡṭ αα ṃṓṓṃḛḛṇṭ - ṡḛḛṭṭḭḭṇḡ ẏẏṓṓṵṵ ṵṵṗ龍ผู้]"])},
      "no_host_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḡḛḛṭ ṭḥḛḛ ṲṲṚḺ龍ผู้]"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ龍ผู้]"])},
    "settings": {
      "connections_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṓṓḽṡ ṭḥααṭ ααṛḛḛ ααḽṛḛḛααḍẏẏ ͼṓṓṇṇḛḛͼṭḛḛḍ ẁḭḭṭḥ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ'ṡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "connections_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭṓṓṓṓḽṡ ͼṓṓṇṇḛḛͼṭḛḛḍ ẁḭḭṭḥ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ'ṡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "hash_setting_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁṡ ẏẏṓṓṵṵ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ẁḛḛḅṡḭḭṭḛḛṡ ẁḥḛḛṛḛḛ ṭḥḛḛ ṲṲṚḺ ṛḛḛṃααḭḭṇṡ ααḽẁααẏẏṡ ṭḥḛḛ ṡααṃḛḛ, ḅẏẏ ααṗṗḛḛṇḍḭḭṇḡ αα ṵṵṇḭḭʠṵṵḛḛ ḥααṡḥ ααṭ ṭḥḛḛ ḛḛṇḍ ḅḛḛϝṓṓṛḛḛ ḽḛḛααṽḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "account_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẁḭḭṭͼḥ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḍṓṓṃααḭḭṇṡ龍ผู้]"])},
      "domains_connected_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṃααḭḭṇṡ ṭḥααṭ ααṛḛḛ ααḽṛḛḛααḍẏẏ ͼṓṓṇṇḛḛͼṭḛḛḍ ẁḭḭṭḥ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
      "domains_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṛḛḛṽḭḭḛḛẁ ααṇẏẏ ṗααḡḛḛ ṓṓṇ ṭḥḛḛ ϝṓṓḽḽṓṓẁḭḭṇḡ ḍṓṓṃααḭḭṇṡ龍ผู้]"])},
      "domains_whitelisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṃααḭḭṇṡ ααṽααḭḭḽααḅḽḛḛ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṭḛḛṇṡḭḭṓṓṇ龍ผู้]"])},
      "hash_setting_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇḭḭʠṵṵḛḛ ṲṲṚḺ龍ผู้]"])},
      "no_connections_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇṇḛḛͼṭḛḛḍ ẏẏḛḛṭ - ḥḛḛααḍ ṓṓṽḛḛṛ ṭṓṓ ", _interpolate(_named("link")), " ṭṓṓ ααḍḍ ṓṓṇḛḛ.龍ผู้]"])},
      "plan_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ḅṛṓṓẁṡḛḛṛ ḛḛẋṭḛḛṇṡḭḭṓṓṇ ͼααṇ ṓṓṇḽẏẏ ḅḛḛ ṵṵṡḛḛḍ\nṓṓṇ Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ ααṇḍ ḀḀḍṽααṇͼḛḛḍ ṗḽααṇṡ.龍ผู้]"])},
      "workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẁḭḭṭͼḥ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
    },
    "create_ticket": {
      "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ:龍ผู้]"])},
      "custom_fields": {
        "no_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓṽḭḭḍḛḛ αα ṇααṃḛḛ龍ผู้]"])},
        "no_value_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓṽḭḭḍḛḛ αα ṽααḽṵṵḛḛ龍ผู้]"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿααḽṵṵḛḛ龍ผู้]"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṡ ṭḥḛḛ ϝḭḭṛṡṭ ṭḭḭṃḛḛ ẁḛḛ'ṽḛḛ ͼṓṓṃḛḛ ααͼṛṓṓṡṡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ. Ṕṛṓṓṽḭḭḍḛḛ ṡṓṓṃḛḛ ḍḛḛṭααḭḭḽṡ.龍ผู้]"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛϝḛḛṛḛḛṇͼḛḛ:龍ผู้]"])},
      "subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛṭḥḭḭṇḡ ṭṓṓ ḭḭḍḛḛṇṭḭḭϝẏẏ ṭḥḛḛ ṭḭḭͼḳḛḛṭ ḅẏẏ ḽααṭḛḛṛ ṓṓṇ龍ผู้]"])},
      "subject_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛϝṵṵṇḍ龍ผู้]"])},
      "validation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓṽḭḭḍḛḛ ḅṓṓṭḥ ṭḥḛḛ ααṡṡḭḭḡṇḛḛḛḛ ααṇḍ αα ṡṵṵḅĵḛḛͼṭ龍ผู้]"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ龍ผู้]"])},
      "button_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḭḭṇḡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṡṡḭḭḡṇḛḛḍ龍ผู้]"])}
    },
    "disabled": {
      "settings_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ ẏẏṓṓṵṵṛ ṃααṇααḡḛḛṛ ṓṓṛ ṡẁḭḭṭͼḥ ṭṓṓ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ẁṓṓṛḳṡṗααͼḛḛ ϝṛṓṓṃ ṭḥḛḛ ", _interpolate(_named("settings")), ".龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ḍḭḭṡααḅḽḛḛḍ ϝṓṓṛ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
    },
    "footer": {
      "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṓṓṵṵṭ龍ผู้]"])},
      "log_out_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃẏẏḛḛ龍ผู้]"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
    },
    "invalid_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṲṲṚḺ ͼααṇ'ṭ ḅḛḛ ṛḛḛṽḭḭḛḛẁḛḛḍ. Ṅααṽḭḭḡααṭḛḛ ṭṓṓ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
    "login": {
      "app_login_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ṭṓṓ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṽḭḭαα ẏẏṓṓṵṵṛ ṢṢṎṎ ṗṛṓṓṽḭḭḍḛḛṛ ϝḭḭṛṡṭ ααṇḍ ṭḥḛḛṇ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
      "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ龍ผู้]"])},
      "log_in_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ẁḭḭṭḥ ṢṢṎṎ龍ผู้]"])},
      "opening_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇḭḭṇḡ ṡḭḭḡṇ-ḭḭṇ ṗṓṓṗṵṵṗ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ḭḭṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵααḭḭṭ...龍ผู้]"])},
      "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṇḡ...龍ผู้]"])},
      "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ṇṓṓṭ ḥααṽḛḛ ααṇ ααͼͼṓṓṵṵṇṭ ẏẏḛḛṭ?龍ผู้]"])},
      "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṵṵṗ龍ผู้]"])}
    },
    "messages": {
      "access": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḍṓṓ ṇṓṓṭ ḥααṽḛḛ ααͼͼḛḛṡṡ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ. ḬḬϝ ẏẏṓṓṵṵ ṡḥṓṓṵṵḽḍ, ͼṓṓṇṭααͼṭ ẏẏṓṓṵṵṛ ṃααṇααḡḛḛṛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼͼḛḛṡṡ龍ผู้]"])}
      },
      "connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥḛḛͼḳ ṭḥααṭ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ḭḭṡ ααͼṭḭḭṽααṭḛḛḍ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ḛḛṛṛṓṓṛ龍ผู้]"])}
      },
      "invalid_url": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṽḭḭḡααṭḛḛ ṭṓṓ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ṭṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽααḽḭḭḍ ṲṲṚḺ龍ผู้]"])}
      },
      "reconnect": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṃṓṓṡṭ ḍṓṓṇḛḛ ṛḛḛͼṓṓṇṇḛḛͼṭḭḭṇḡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṃṓṓṃḛḛṇṭ龍ผู้]"])}
      },
      "reload": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṛḛḛḽṓṓααḍḭḭṇḡ ṭḥḛḛ ṗααḡḛḛ ṭṓṓ ḡḛḛṭ ḅααͼḳ ḭḭṇṭṓṓ ṭḥḛḛ ϝḽṓṓẁ ṓṓϝ ṭḥḭḭṇḡṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛṭḥḭḭṇḡ ẁḛḛṇṭ ẁṛṓṓṇḡ龍ผู้]"])}
      },
      "subscription": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḛḛḛḛ ẏẏṓṓṵṵṛṡḛḛḽϝ ϝṛṓṓṃ ṭḥḛḛ ṽḭḭṛṭṵṵααḽ ḅṓṓṛḍḛḛṛṡ ṓṓϝ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ααṇḍ ṛḛḛṽḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇẏẏẁḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ṭṓṓ ṵṵṡḛḛ ṭḥḛḛ ḚḚẋṭḛḛṇṡḭḭṓṓṇ龍ผู้]"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])}
      },
      "base_connection_error": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ḭḭṡ ṇṓṓṭ ḽḭḭṇḳḛḛḍ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
      },
      "not_found": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ḍṓṓḛḛṡ ṇṓṓṭ ṛḛḛͼṓṓḡṇḭḭẓḛḛ ṭḥḭḭṡ ṭḭḭͼḳḛḛṭ, ṓṓṛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḍṓṓḛḛṡ ṇṓṓṭ ḥααṽḛḛ ααͼͼḛḛṡṡ ṭṓṓ ḭḭṭ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ϝḭḭṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])}
      }
    },
    "notifications": {
      "feedback": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ααͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḡṓṓṭ ṡṓṓṃḛḛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])}
      },
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ ṡṵṵͼͼḛḛṡṡϝṵṵḽ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṵṵṡḛḛ ṭḥḛḛ ααṗṗ ṇṓṓẁ龍ผู้]"])}
      }
    },
    "review": {
      "require_all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ ṭḥḭḭṡ ṭṓṓ ḅṓṓṓṓḳṃααṛḳ ͼααṡḛḛṡ ααṇḍ ϝḭḭḽṭḛḛṛ ṓṓṵṵṭ ḽααṭḛḛṛ龍ผู้]"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ...龍ผู้]"])},
      "no_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ϝḛḛḛḛḍḅααͼḳ ϝṓṓṛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "read_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "view_on_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])}
    },
    "activity": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṡ ẁḥḛḛṛḛḛ ααḽḽ ϝḛḛḛḛḍḅααͼḳ ααṇḍ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ ẁḭḭḽḽ ααṗṗḛḛααṛ. Ṅṓṓṭḥḭḭṇḡ ẏẏḛḛṭ…龍ผู้]"])},
      "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ṃṓṓṛḛḛ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
    },
    "nav": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
      "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
      "ticket_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḍḛḛṭααḭḭḽṡ龍ผู้]"])}
    }
  },
  "filter": {
    "condition": {
      "label": {
        "all_strings_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṭααḭḭṇṡ ααḽḽ ṓṓϝ龍ผู้]"])},
        "all_strings_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ααḽḽ ṓṓϝ龍ผู้]"])},
        "all_strings_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓḛḛṡ ṇṓṓṭ ͼṓṓṇṭααḭḭṇ ααḽḽ ṓṓϝ龍ผู้]"])},
        "all_strings_not_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ ααḽḽ ṓṓϝ龍ผู้]"])},
        "bot_better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḅḛḛṭṭḛḛṛ ṭḥααṇ ααḡḛḛṇṭ’ṡ龍ผู้]"])},
        "bot_worse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẁṓṓṛṡḛḛ ṭḥααṇ ααḡḛḛṇṭ’ṡ龍ผู้]"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ϝααḽṡḛḛ龍ผู้]"])},
        "string_is_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṃṓṓṛḛḛ ṭḥααṇ龍ผู้]"])},
        "string_list_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṭααḭḭṇṡ ααṇẏẏ ṓṓϝ龍ผู้]"])},
        "string_list_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ααṇẏẏ ṓṓϝ龍ผู้]"])},
        "string_list_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓḛḛṡ ṇṓṓṭ ͼṓṓṇṭααḭḭṇ ααṇẏẏ ṓṓϝ龍ผู้]"])},
        "string_list_not_equals_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ ααṇẏẏ ṓṓϝ龍ผู้]"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭṛṵṵḛḛ龍ผู้]"])},
        "breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḅṛḛḛααͼḥḛḛḍ龍ผู้]"])},
        "comment_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṃṃḛḛṇṭḛḛḍ龍ผู้]"])},
        "comment_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṃṃḛḛṇṭḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "comment_not_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḥααṡ ṇṓṓṭ ͼṓṓṃṃḛḛṇṭḛḛḍ龍ผู้]"])},
        "comment_not_commented_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ͼṓṓṃṃḛḛṇṭḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṛḭḭṭḭḭͼααḽ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṭααḭḭṇṡ龍ผู้]"])},
        "date_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ 30 ḍααẏẏṡ龍ผู้]"])},
        "date_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẁḭḭṭḥḭḭṇ龍ผู้]"])},
        "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ϝṛṓṓṃ龍ผู้]"])},
        "date_last_14_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ 14 ḍααẏẏṡ龍ผู้]"])},
        "date_last_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ 24ḥ龍ผู้]"])},
        "date_last_30_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ 30 ḍααẏẏṡ龍ผู้]"])},
        "date_last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ 7 ḍααẏẏṡ龍ผู้]"])},
        "date_last_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ ṃṓṓṇṭḥ龍ผู้]"])},
        "date_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṡṭ ẁḛḛḛḛḳ龍ผู้]"])},
        "date_this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḭḭṡ ṃṓṓṇṭḥ龍ผู้]"])},
        "date_this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḭḭṡ ẁḛḛḛḛḳ龍ผู้]"])},
        "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṇṭḭḭḽ龍ผู้]"])},
        "date_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭṓṓḍααẏẏ龍ผู้]"])},
        "date_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏḛḛṡṭḛḛṛḍααẏẏ龍ผู้]"])},
        "detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛẋḭḭṡṭṡ龍ผู้]"])},
        "highlight_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṭααṛṛḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "highlight_not_highlighted_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ṡṭααṛṛḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṇͼḽṵṵḍḛḛṡ龍ผู้]"])},
        "integer_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ龍ผู้]"])},
        "integer_higher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃṓṓṛḛḛ ṭḥααṇ龍ผู้]"])},
        "integer_higher_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṭααṛṭḭḭṇḡ ϝṛṓṓṃ龍ผู้]"])},
        "integer_lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽḛḛṡṡ ṭḥααṇ龍ผู้]"])},
        "integer_lower_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṗ ṵṵṇṭḭḭḽ龍ผู้]"])},
        "integer_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ龍ผู้]"])},
        "long_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ龍ผู้]"])},
        "long_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ龍ผู้]"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇḛḛḡααṭḭḭṽḛḛ龍ผู้]"])},
        "not_breached_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ḅṛḛḛααͼḥḛḛḍ龍ผู้]"])},
        "not_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ͼṓṓṃṗḽḛḛẋ龍ผู้]"])},
        "not_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ḍḛḛṭḛḛͼṭḛḛḍ龍ผู้]"])},
        "not_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓḛḛṡ ṇṓṓṭ ḛḛẋḭḭṡṭ龍ผู้]"])},
        "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓḛḛṡ ṇṓṓṭ ḭḭṇͼḽṵṵḍḛḛ龍ผู้]"])},
        "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "not_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗṓṓṡḭḭṭḭḭṽḛḛ龍ผู้]"])},
        "rating_not_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "rating_not_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṭ ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "rating_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "rating_rated_by_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ ṃḛḛ龍ผู้]"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṛḭḭṭḭḭͼααḽ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "string_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṭααḭḭṇṡ龍ผู้]"])},
        "string_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ龍ผู้]"])},
        "string_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃṓṓṛḛḛ ṭḥααṇ龍ผู้]"])},
        "string_greater_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṭααṛṭḭḭṇḡ ϝṛṓṓṃ龍ผู้]"])},
        "string_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽḛḛṡṡ ṭḥααṇ龍ผู้]"])},
        "string_less_than_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṗ ṵṵṇṭḭḭḽ龍ผู้]"])},
        "string_list_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ龍ผู้]"])},
        "string_list_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ龍ผู้]"])},
        "string_not_contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍṓṓḛḛṡ ṇṓṓṭ ͼṓṓṇṭααḭḭṇ龍ผู้]"])},
        "string_not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ龍ผู้]"])},
        "viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "comment_my_comment_has_reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃẏẏ ͼṓṓṃṃḛḛṇṭ ḥααṡ ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓ龍ผู้]"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏḛḛṡ龍ผู้]"])}
      }
    },
    "groups": {
      "custom_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "custom_spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṡṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ龍ผู้]"])},
      "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇϝṓṓṛṃααṭḭḭṓṓṇ龍ผู้]"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṭṛḭḭͼṡ龍ผู้]"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
      "review_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ααṇḍ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ααḽḽ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])},
      "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])}
    },
    "option": {
      "concatenated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("description")), " ", _interpolate(_named("condition")), " ", _interpolate(_named("selectedOption")), "龍ผู้]"])},
      "description": {
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗααṛḛḛṡ ẏẏṓṓṵṵṛ ḅṓṓṭ'ṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṇḍḽḭḭṇḡ ααḡααḭḭṇṡṭ ααṽḛḛṛααḡḛḛ ααḡḛḛṇṭṡ龍ผู้]"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ḭḭṡ ṡṭṵṵͼḳ ḭḭṇ αα ḽṓṓṓṓṗ, ṛḛḛṗḛḛααṭḭḭṇḡ ṭḥḛḛ ṡααṃḛḛ ṃḛḛṡṡααḡḛḛ ḭḭṇ ͼṓṓṇṡḛḛͼṵṵṭḭḭṽḛḛ ṭṵṵṛṇṡ龍ผู้]"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ḅṓṓṭ ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ṭẏẏṗḛḛ ṓṓϝ ḅṓṓṭ ḭḭṇṽṓṓḽṽḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ḅṓṓṭ ḭḭṇṽṓṓḽṽḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "custom_spotlight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ ͼṓṓṇṭααḭḭṇṡ ṭḥḛḛ ṗḥṛααṡḛḛ ", _interpolate(_list(0)), " ḭḭṇ ααḡḛḛṇṭṡ' ṃḛḛṡṡααḡḛḛ(ṡ)龍ผู้]"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏ ͼααḽḽṡ ḽααͼḳḭḭṇḡ ṭḥḛḛ ṃααṇḍααṭṓṓṛẏẏ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṡṭααṭḛḛṃḛḛṇṭ, ḽḭḭḳḛḛ 'Ṫḥḭḭṡ ͼααḽḽ ẁḭḭḽḽ ḅḛḛ ṛḛḛͼṓṓṛḍḛḛḍ' ααṇḍ ṡḭḭṃḭḭḽααṛ.龍ผู้]"])},
        "related_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ṗḛḛṛṡṓṓṇ ṓṓṛ ḅṓṓṭ ḭḭṇṽṓṓḽṽḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "reviewee_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ṓṓṛ ḅṓṓṭ ḅḛḛḭḭṇḡ ṛḛḛṽḭḭḛḛẁḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡṓṓṵṵḡḥṭ ḥḭḭḡḥḛḛṛ-ḽḛḛṽḛḛḽ ααṡṡḭḭṡṭααṇͼḛḛ龍ผู้]"])},
        "ticket_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ ααṇḍ ṇḛḛḡααṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ ḍḛḛṭḛḛͼṭḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḍḛḛṇṭḭḭϝḭḭḛḛṡ ṭḥḛḛ ṃṓṓṡṭ ααͼṭḭḭṽḛḛ ααḡḛḛṇṭ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ḀḀḬḬ ααṇααḽẏẏṡḭḭṡ龍ผู้]"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḭḭϝ ṭḥḛḛ ααḡḛḛṇṭ ẁṛααṗṗḛḛḍ ṵṵṗ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗṓṓḽḭḭṭḛḛḽẏẏ龍ผู้]"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭααṇḍṡ ααṇḍ ααͼḳṇṓṓẁḽḛḛḍḡḛḛṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛṡ' ϝḛḛḛḛḽḭḭṇḡṡ龍ผู้]"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ḡṛααṃṃααṛ, ṡṗḛḛḽḽḭḭṇḡ ααṇḍ ṡṭẏẏḽḛḛ ṃḭḭṡṭααḳḛḛṡ龍ผู้]"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ṭẏẏṗḭḭͼααḽ ḡṛḛḛḛḛṭḭḭṇḡ ṗḥṛααṡḛḛṡ龍ผู้]"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭααṇḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṛẏẏ ṓṓṛ ͼṓṓṇͼḛḛṛṇ龍ผู้]"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḥṓṓẁ ḛḛααṡḭḭḽẏẏ αα ṭḛḛẋṭ ͼααṇ ḅḛḛ ṵṵṇḍḛḛṛṡṭṓṓṓṓḍ, ͼṓṓṇṡḭḭḍḛḛṛḭḭṇḡ ẁṓṓṛḍ ͼṓṓṃṗḽḛḛẋḭḭṭẏẏ ααṇḍ ṡḛḛṇṭḛḛṇͼḛḛ ḽḛḛṇḡṭḥ龍ผู้]"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ααṇ ṓṓϝϝḛḛṛḛḛḍ ṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ṭḥṛṓṓṵṵḡḥṓṓṵṵṭ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇṡ ṓṓϝ ͼṵṵṡṭṓṓṃḛḛṛ ααṭṭṛḭḭṭḭḭṓṓṇ. Ḉṵṵṡṭṓṓṃḛḛṛ ẁααṡ ͼṓṓṇṡḭḭḍḛḛṛḭḭṇḡ αα ṡẁḭḭṭͼḥ ṓṓṛ ṗṛṓṓṃḭḭṡḛḛḍ ṭṓṓ ṗααṛṭ ẁααẏẏṡ.龍ผู้]"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṭṓṓ ẁḥḭḭͼḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḅḛḛḽṓṓṇḡṡ.龍ผู้]"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṇṭḛḛṇṭ ṓṓṛ ṭḛḛẋṭ ṓṓϝ ṭḥḛḛ ααḡḛḛṇṭ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ααḡḛḛṇṭ ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḽḛḛṇḡṭḥ ṓṓϝ ṭḭḭṃḛḛ ṭḥḛḛ ͼααḽḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḽααṡṭḛḛḍ ḭḭṇ ṡḛḛͼṓṓṇḍṡ龍ผู้]"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṇṭḛḛṇṭ ṓṓṛ ṭḛḛẋṭ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṃṃṵṵṇḭḭͼααṭḭḭṓṓṇ ͼḥααṇṇḛḛḽ ṵṵṡḛḛḍ ϝṓṓṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ (ḛḛ.ḡ. ḛḛṃααḭḭḽ, ͼḥααṭ)龍ผู้]"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ αα ͼṓṓṃṃḛḛṇṭ ẁααṡ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ ααṇḍ ṇḛḛḡααṭḭḭṽḛḛ ṡḛḛṇṭḭḭṃḛḛṇṭ ḍḛḛṭḛḛͼṭḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṛḛḛṗḽḭḭḛḛḍ ṭṓṓ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "dispute_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṭḥḛḛṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁ ḭḭṡ ḍḭḭṡṗṵṵṭḛḛḍ龍ผู้]"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼḥααṇṇḛḛḽ ṵṵṡḛḛḍ ḅẏẏ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṭṓṓ ḭḭṇḭḭṭḭḭααṭḛḛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ͼṵṵṡṭṓṓṃḛḛṛ ṛḛḛṡṗṓṓṇṡḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ṓṓϝ ṭḥḛḛ ḽααṡṭ ṃḛḛṡṡααḡḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṛṓṓḽḛḛ ṓṓṛ ṭẏẏṗḛḛ ṓṓϝ ṵṵṡḛḛṛ ḭḭṇṽṓṓḽṽḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḽḛḛṇͼḛḛ ḅḛḛṭẁḛḛḛḛṇ ṭḥḛḛ ααḡḛḛṇṭ ααṇḍ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ḛḛẋͼḛḛḛḛḍḛḛḍ ṭḥḛḛ ṭḥṛḛḛṡḥṓṓḽḍ龍ผู้]"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡḛḛḛḛḳḛḛḍ ḥḭḭḡḥḛḛṛ-ḽḛḛṽḛḛḽ ααṡṡḭḭṡṭααṇͼḛḛ龍ผู้]"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ḛḛẋṗṛḛḛṡṡḛḛḍ ḛḛẋṭṛḛḛṃḛḛ ḡṛααṭḭḭṭṵṵḍḛḛ ṓṓṛ ẁααṡ ṽḛḛṛẏẏ ͼṓṓṇṭḛḛṇṭ ẁḭḭṭḥ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḭḭṭḥḛḛṛ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṓṓṛ ααḡḛḛṇṭ ḛḛẋṗḽḭḭͼḭḭṭḽẏẏ ṛḛḛʠṵṵḛḛṡṭḛḛḍ αα ϝṓṓḽḽṓṓẁ-ṵṵṗ龍ผู้]"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṭḛḛẋṭ ṓṓṛ ṛḛḛͼṓṓṛḍ ṓṓϝ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇḍṵṵͼṭḛḛḍ ḅẏẏ ṽṓṓḭḭͼḛḛ龍ผู้]"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṭḥḛḛṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ṃααṛḳḛḛḍ ααṡ ṡṭααṛṛḛḛḍ龍ผู้]"])},
        "internal_comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡṡ ṵṵṡḛḛḍ ḭḭṇ ͼṓṓṃṃḛḛṇṭṡ ṭṓṓ ͼααṭḛḛḡṓṓṛḭḭẓḛḛ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḍḛḛṭḛḛͼṭḛḛḍ ḅẏẏ ḀḀḬḬ龍ผู้]"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ṓṓϝ ṭḥḛḛ ḽααṡṭ ṃḛḛṡṡααḡḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ṗṛḭḭṽααṭḛḛ ṇṓṓṭḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "public_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ͼḥααṛααͼṭḛḛṛṡ ṗḛḛṛ ṃḛḛṡṡααḡḛḛ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ͼṵṵṡṭṓṓṃḛḛṛ-ϝααͼḭḭṇḡ ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ṗααṛṭḭḭͼḭḭṗααṇṭ ẁḥṓṓṡḛḛ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ ḭḭṡ ṗṵṵḅḽḭḭͼḽẏẏ ṽḭḭṡḭḭḅḽḛḛ龍ผู้]"])},
        "public_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ẁṓṓṛḍṡ ṗḛḛṛ ṃḛḛṡṡααḡḛḛ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ ṵṵṡḛḛḍ ṭṓṓ ṛααṭḛḛ ṓṓṛ ḛḛṽααḽṵṵααṭḛḛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡṭααṭṵṵṡ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ'ṡ ṛḛḛṽḭḭḛḛẁ ṗṛṓṓͼḛḛṡṡ龍ผู้]"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ṗααṛṭḭḭͼḭḭṗααṇṭ ṓṓṛ ṗḛḛṛṡṓṓṇ ḭḭṇṽṓṓḽṽḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ḛḛṃααḭḭḽ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṭḥḛḛṛ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ḥααṡ ḅḛḛḛḛṇ ṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛ ẁḥṓṓ ααṡṡḛḛṡṡḛḛḍ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ḽααṡṭ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ḅḛḛḭḭṇḡ ṛḛḛṽḭḭḛḛẁḛḛḍ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇẏẏ ṡṗḛḛͼḭḭϝḭḭͼ ͼṓṓṃṃḛḛṇṭṡ ṓṓṛ ϝḛḛḛḛḍḅααͼḳ ṗṛṓṓṽḭḭḍḛḛḍ ḅẏẏ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṛḛḛḡααṛḍḭḭṇḡ ṭḥḛḛḭḭṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ẁḭḭṭḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡͼṓṓṛḛḛ ϝṓṓṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "satisfaction_score_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡͼṓṓṛḛḛ ṗḛḛṛ ṃḛḛṡṡααḡḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ṵṵṡḛḛḍ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "sla_breach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ ṢḺḀḀṡ ḥααṽḛḛ ḅḛḛḛḛṇ ḅṛḛḛααͼḥḛḛḍ龍ผู้]"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṭẏẏṗḛḛ ṓṓϝ ṡṓṓṵṵṛͼḛḛ ṓṓṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ϝṛṓṓṃ ẁḥḭḭͼḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṓṓṛḭḭḡḭḭṇααṭḛḛḍ龍ผู้]"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ṵṵṇṵṵṡṵṵααḽ ṓṓṛ ααṭẏẏṗḭḭͼααḽ ααṇḍ ṭḥḛḛṛḛḛ ẁααṡ ṃṓṓṛḛḛ ḅααͼḳ-ααṇḍ-ϝṓṓṛṭḥ ṭṓṓ ṛḛḛααͼḥ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡṵṵṛṽḛḛẏẏ ʠṵṵḛḛṡṭḭḭṓṓṇ ṛḛḛḽααṭḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṭḭḭṭḽḛḛ ṓṓṛ ṇααṃḛḛ ṓṓϝ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḅṛααṇḍ ṓṓṛ ṡṗḛḛͼḭḭϝḭḭͼ ṗṛṓṓḍṵṵͼṭ/ṡḛḛṛṽḭḭͼḛḛ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṃṃṵṵṇḭḭͼααṭḭḭṓṓṇ ͼḥααṇṇḛḛḽ ṵṵṡḛḛḍ ϝṓṓṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ (ḛḛ.ḡ. ḛḛṃααḭḭḽ, ͼḥααṭ)龍ผู้]"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ͼḽṓṓṡḛḛḍ ṓṓṛ ṛḛḛṡṓṓḽṽḛḛḍ龍ผู้]"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ḭḭṇḭḭṭḭḭααḽḽẏẏ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ḭḭṇḭḭṭḭḭααḽḽẏẏ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṛḛḛṗḽḭḭḛḛḍ ṭṓṓ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ḅṛḭḭḛḛϝ ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ ṓṓṛ ṡṵṵṃṃααṛẏẏ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ḛḛẋṭḛḛṛṇααḽ ṛḛḛϝḛḛṛḛḛṇͼḛḛ ḬḬḌ ḽḭḭṇḳḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ϝṓṓḽḍḛḛṛ ṓṓṛ ṡṗḛḛͼḭḭϝḭḭͼ ḡṛṓṓṵṵṗḭḭṇḡ ḭḭṇ ẁḥḭḭͼḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ṓṓṛḡααṇḭḭẓḛḛḍ龍ผู้]"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ϝṓṓṛṃ ṓṓṛ ṭḛḛṃṗḽααṭḛḛ ṵṵṡḛḛḍ ϝṓṓṛ ṡṭṛṵṵͼṭṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ḛḛẋṭḛḛṛṇααḽ ṛḛḛϝḛḛṛḛḛṇͼḛḛ ḬḬḌ ḽḭḭṇḳḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṃααḭḭḽḅṓṓẋ ṓṓṛ ḛḛṃααḭḭḽ ααͼͼṓṓṵṵṇṭ ṭḥṛṓṓṵṵḡḥ ẁḥḭḭͼḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗḥṓṓṇḛḛ ṇṵṵṃḅḛḛṛ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗṛḭḭṓṓṛḭḭṭẏẏ ḽḛḛṽḛḛḽ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṭṓṓṭααḽ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡṭααṭḛḛ ṓṓṛ ͼṵṵṛṛḛḛṇṭ ṡṭααṭṵṵṡ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṵṵṛṛḛḛṇṭ ṡṭααṭṵṵṡ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡṵṵḅĵḛḛͼṭ ṓṓṛ ṭṓṓṗḭḭͼ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ααḡḛḛṇṭ ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ͼααṭḛḛḡṓṓṛḭḭẓḛḛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṭẏẏṗḛḛ ṓṓṛ ͼααṭḛḛḡṓṓṛẏẏ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ḽααṡṭ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḍααṭḛḛ ẁḥḛḛṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ḽααṡṭ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṃḛḛḍḭḭṵṵṃ ṓṓṛ ͼḥααṇṇḛḛḽ ṭḥṛṓṓṵṵḡḥ ẁḥḭḭͼḥ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṓṓṓṓḳ ṗḽααͼḛḛ龍ผู้]"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṇṭḛḛṇṭ ṓṓϝ αα ṭṛααṇṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṇ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ ααṛḛḛ ḭḭṇḛḛḽḭḭḡḭḭḅḽḛḛ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ ṭḥṛṓṓṵṵḡḥ ḀḀḬḬ ααṇααḽẏẏṡḭḭṡ龍ผู้]"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ṗααṛṭḭḭͼḭḭṗααṭḭḭṇḡ ααḡḛḛṇṭṡ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṭḥḛḛṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ṽḭḭḛḛẁḛḛḍ龍ผู้]"])}
      },
      "label": {
        "all_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇẏẏ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
        "bot_communication_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ͼṓṓṃṃṵṵṇḭḭͼααṭḭḭṓṓṇ ḛḛϝϝḭḭͼḭḭḛḛṇͼẏẏ龍ผู้]"])},
        "bot_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṛḛḛṗḛḛṭḭḭṭḭḭṓṓṇ龍ผู้]"])},
        "bot_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "bot_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṭẏẏṗḛḛ龍ผู้]"])},
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ龍ผู้]"])},
        "call_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ḍḭḭṛḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
        "client_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭḛḛṇṭ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
        "deadair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛααḍ ααḭḭṛ龍ผู้]"])},
        "has_recording_disclosure_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼṓṓṛḍḭḭṇḡ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṃḭḭṡṡḭḭṇḡ龍ผู้]"])},
        "sla_breach_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢḺḀḀ龍ผู้]"])},
        "ticket_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṡͼααḽααṭḭḭṓṓṇ龍ผู้]"])},
        "transcription_content_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ṭṛααṇṡͼṛḭḭṗṭ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ龍ผู้]"])},
        "highlight_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṛḛḛḍ龍ผู้]"])},
        "is_sunburst_trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇḛḛḽḭḭḡḭḭḅḽḛḛ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "reviewed_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ḅẏẏ龍ผู้]"])},
        "ticket_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṽḭḭαα龍ผู้]"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛḽḽḭḭṇḡ ααṇḍ ḡṛααṃṃααṛ龍ผู้]"])},
        "agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
        "agent_most_public_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ααͼṭḭḭṽḛḛ ααḡḛḛṇṭ龍ผู้]"])},
        "agent_public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
        "call_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḽ ḍṵṵṛααṭḭḭṓṓṇ (ṡ)龍ผู้]"])},
        "comment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "comment_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛ ͼḥααṇṇḛḛḽ龍ผู้]"])},
        "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṭααḡ龍ผู้]"])},
        "comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ龍ผู้]"])},
        "commented_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "conversation_sentiment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ龍ผู้]"])},
        "csat_answered_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ ṛḛḛṗḽẏẏ ḍααṭḛḛ龍ผู้]"])},
        "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛ龍ผู้]"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṗααṭḥẏẏ龍ผู้]"])},
        "end_user_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ͼḥααṇṇḛḛḽ龍ผู้]"])},
        "end_user_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "external_comment_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṛḛḛṗḽẏẏ ḍααṭḛḛ龍ผู้]"])},
        "from_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ ṭẏẏṗḛḛ龍ผู้]"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
        "has_churn_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥṵṵṛṇ ṛḭḭṡḳ龍ผู้]"])},
        "has_deadair_exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛααḍ ααḭḭṛ龍ผู้]"])},
        "has_escalation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṡͼααḽααṭḭḭṓṓṇ龍ผู้]"])},
        "has_extra_mile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḛḛṗṭḭḭṓṓṇααḽ ṡḛḛṛṽḭḭͼḛḛ龍ผู้]"])},
        "has_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓḽḽṓṓẁ-ṵṵṗ龍ผู้]"])},
        "has_transcription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡͼṛḭḭṗṭ龍ผู้]"])},
        "internal": {
          "comment_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ḥααṡḥṭααḡ龍ผู้]"])}
        },
        "is_complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṭḽḭḭḛḛṛ龍ผู้]"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗṛḛḛḥḛḛṇṡḭḭṓṓṇ龍ผู้]"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
        "last_external_comment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṛḛḛṗḽẏẏ ḍααṭḛḛ龍ผู้]"])},
        "private_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗṛḭḭṽααṭḛḛ ṇṓṓṭḛḛ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "public_mean_character_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ͼḥααṛααͼṭḛḛṛ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "public_mean_word_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ẁṓṓṛḍ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "public_message_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭͼ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "public_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭͼ ṗααṛṭḭḭͼḭḭṗααṇṭ龍ผู้]"])},
        "rated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "rating_and_comment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ααṇḍ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
        "rating_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "rating_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṡṭααṭṵṵṡ龍ผู้]"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍααḅḭḭḽḭḭṭẏẏ龍ผู้]"])},
        "related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭ龍ผู้]"])},
        "related_name_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛḽααṭḛḛḍ ḛḛṃααḭḭḽ龍ผู้]"])},
        "related_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛḽααṭḛḛḍ龍ผู้]"])},
        "reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "review_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "review_scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "review_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
        "reviewed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
        "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "satisfaction_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
        "satisfaction_per_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṗḛḛṛ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
        "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡͼṓṓṛḛḛ (ḈṢḀḀṪ)龍ผู้]"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓḽṵṵṭḭḭṓṓṇ ṓṓϝϝḛḛṛḛḛḍ龍ผู้]"])},
        "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ ṭẏẏṗḛḛ龍ผู้]"])},
        "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅĵḛḛͼṭ龍ผู้]"])},
        "survey_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
        "survey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṭḭḭṭḽḛḛ龍ผู้]"])},
        "ticket_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
        "ticket_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḅṛααṇḍ龍ผู้]"])},
        "ticket_brand_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛααṇḍ ḍṓṓṃααḭḭṇ龍ผู้]"])},
        "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼḥααṇṇḛḛḽ龍ผู้]"])},
        "ticket_closed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "ticket_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "ticket_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "ticket_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ ṛḛḛṗḽẏẏ ḍααṭḛḛ龍ผู้]"])},
        "ticket_csat_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ ṡḛḛṇṭ ḍααṭḛḛ龍ผู้]"])},
        "ticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
        "ticket_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṭḛḛṛṇααḽ ḬḬḌ龍ผู้]"])},
        "ticket_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝḭḭḛḛḽḍ龍ผู้]"])},
        "ticket_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓḽḍḛḛṛ龍ผู้]"])},
        "ticket_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛṃ龍ผู้]"])},
        "ticket_freshdesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḛḛṡḥḍḛḛṡḳ ḬḬḌ龍ผู้]"])},
        "ticket_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "ticket_help_scout_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ Ṣͼṓṓṵṵṭ ḬḬḌ龍ผู้]"])},
        "ticket_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṭḛḛṛṇααḽ ḬḬḌ龍ผู้]"])},
        "ticket_intercom_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛṛͼṓṓṃ ḬḬḌ龍ผู้]"])},
        "ticket_internal_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "ticket_mailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṃααḭḭḽḅṓṓẋ龍ผู้]"])},
        "ticket_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḥṓṓṇḛḛ ṇṵṵṃḅḛḛṛ龍ผู้]"])},
        "ticket_priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗṛḭḭṓṓṛḭḭṭẏẏ龍ผู้]"])},
        "ticket_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "ticket_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṭααṭḛḛ龍ผู้]"])},
        "ticket_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṭααṭṵṵṡ龍ผู้]"])},
        "ticket_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṵṵḅĵḛḛͼṭ龍ผู้]"])},
        "ticket_support_rep_reply_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṛḛḛṗḽẏẏ ͼṓṓṵṵṇṭ龍ผู้]"])},
        "ticket_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡ龍ผู้]"])},
        "ticket_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡ龍ผู้]"])},
        "ticket_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭẏẏṗḛḛ龍ผู้]"])},
        "ticket_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "ticket_updated_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "ticket_zendesk_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ḬḬḌ龍ผู้]"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṇḛḛ龍ผู้]"])},
        "transcription_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡͼṛḭḭṗṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "unique_agent_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ṗααṛṭḭḭͼḭḭṗααṭḭḭṇḡ ααḡḛḛṇṭṡ龍ผู้]"])},
        "viewed_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁḛḛḍ ṡṭααṭṵṵṡ龍ผู้]"])},
        "vulnerability_capability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿṵṵḽṇḛḛṛααḅḭḭḽḭḭṭẏẏ ͼααṗααḅḭḭḽḭḭṭẏẏ龍ผู้]"])},
        "vulnerability_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿṵṵḽṇḛḛṛααḅḭḭḽḭḭṭẏẏ ḥḛḛααḽṭḥ龍ผู้]"])},
        "vulnerability_life_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿṵṵḽṇḛḛṛααḅḭḭḽḭḭṭẏẏ ḽḭḭϝḛḛ ḛḛṽḛḛṇṭ龍ผู้]"])}
      },
      "autoqa_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
    },
    "date_condition_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ḍααṭḛḛ-ṛḛḛḽααṭḛḛḍ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
    "empty_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṇααṃḛḛ ṭṓṓ ẏẏṓṓṵṵṛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
    "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽḽ ḭḭṇ αα ϝḭḭḽṭḛḛṛ ṇααṃḛḛ龍ผู้]"])},
    "value": {
      "current_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇḛḛḍ-ḭḭṇ ṗḛḛṛṡṓṓṇ龍ผู้]"])},
      "removed_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛṃḛḛṛ ẁṓṓṛḳṡṗααͼḛḛ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
      "workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])}
    },
    "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ", _interpolate(_named("filterName")), "龍ผู้]"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽẏẏ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
    "date_condition_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ṡḥṓṓṵṵḽḍ ḥααṽḛḛ ααṭ ḽḛḛααṡṭ 1 ḍααṭḛḛ-ṛḛḛḽααṭḛḛḍ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
    "multiple_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
    "single_value_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṇ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])}
  },
  "funnelgraph": {
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
  },
  "modules": {
    "application": {
      "banners": {
        "trial_end": {
          "template_1_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ ḭḭṇ ", _interpolate(_named("n")), " ḍααẏẏ. ", _interpolate(_named("link")), "龍ผู้]"])},
          "template_1_day_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ḭḭṇ ", _interpolate(_named("n")), " ḍααẏẏ</strong></a>.龍ผู้]"])},
          "template_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ ḭḭṇ ", _interpolate(_named("n")), " ḍααẏẏṡ. ", _interpolate(_named("link")), "龍ผู้]"])},
          "template_days_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ḭḭṇ ", _interpolate(_named("n")), " ḍααẏẏṡ</strong></a>.龍ผู้]"])},
          "template_today": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ ṭṓṓḍααẏẏ. ", _interpolate(_named("link")), "龍ผู้]"])},
          "template_today_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ <a href=\"/admin\" target=\"_blank\" rel=\"noopener noreferrer\"><strong>ṭṓṓḍααẏẏ</strong></a>.龍ผู้]"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṵṵṗ αα ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ṇṓṓẁ龍ผู้]"])}
        },
        "trial_left": {
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("n")), " ḍααẏẏṡ龍ผู้]"])},
          "template_zd_only": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḥααṽḛḛ ", _interpolate(_named("days")), " ḽḛḛϝṭ ḭḭṇ ẏẏṓṓṵṵṛ ṭṛḭḭααḽ龍ผู้]"])}
        },
        "user_limit": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḥααṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ṵṵṡḛḛṛ ḽḭḭṃḭḭṭ ϝṓṓṛ ẏẏṓṓṵṵṛ ṗḽααṇ. ", _interpolate(_named("link")), " ṭṓṓ ḽḛḛααṛṇ ṃṓṓṛḛḛ ṓṓṛ ṭṓṓ ṵṵṗḡṛααḍḛḛ ẏẏṓṓṵṵṛ ṗḽααṇ.龍ผู้]"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ḥḛḛṛḛḛ龍ผู้]"])}
        },
        "demo": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṇḛḛͼṭ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
          "modal_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ḛḛṇααḅḽḛḛ ṵṵṡ ṭṓṓ ṗṵṵḽḽ ḭḭṇ ẏẏṓṓṵṵṛ ṭḭḭͼḳḛḛṭṡ ϝṓṓṛ ẏẏṓṓṵṵ ṭṓṓ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
          "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ααṛḛḛ ṵṵṡḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ẁḭḭṭḥ ṡααṃṗḽḛḛ ḍααṭαα. Ṫṓṓ ḍḛḛḽḛḛṭḛḛ ṡααṃṗḽḛḛ ḍααṭαα ααṇḍ ḭḭṃṗṓṓṛṭ ẏẏṓṓṵṵṛ ṓṓẁṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ, ", _interpolate(_named("link")), ".龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṃṗḽḛḛ ḍααṭαα龍ผู้]"])}
        }
      },
      "error_cat": {
        "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ḥḛḛḽṗϝṵṵḽ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ ḅḛḛḽṓṓẁ龍ผู้]"])},
        "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḳḛḛ αα ϝṵṵḽḽ-ṗααḡḛḛ ṡͼṛḛḛḛḛṇṡḥṓṓṭ ṭṓṓ ṗṛṓṓṽḭḭḍḛḛ ͼṓṓṇṭḛḛẋṭ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ ṓṓṵṵṛ ͼṵṵṡṭṓṓṃḛḛṛ ṡṵṵṗṗṓṓṛṭ ṭḛḛααṃ ṭṓṓ ṛḛḛṡṓṓḽṽḛḛ ṭḥḭḭṡ ḭḭṡṡṵṵḛḛ:龍ผู้]"])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛṭḥḭḭṇḡ ẁḛḛṇṭ ẁṛṓṓṇḡ龍ผู้]"])},
        "error_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṛṛṓṓṛ ṛḛḛϝḛḛṛḛḛṇͼḛḛ:龍ผู้]"])},
        "copy_debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ḥḛḛḽṗϝṵṵḽ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ龍ผู้]"])}
      },
      "navbar": {
        "settings_submenu": {
          "users_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ, ḅṓṓṭṡ, ααṇḍ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααṇḍ Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
        },
        "goal_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭṓṓḍααẏẏ龍ผู้]"])},
        "goal_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḭḭṡ ṃṓṓṇṭḥ龍ผู้]"])},
        "goal_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḥḭḭṡ ẁḛḛḛḛḳ龍ผู้]"])},
        "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), "/", _interpolate(_named("total")), " ṛḛḛṽḭḭḛḛẁḛḛḍ ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ṛḛḛṽḭḭḛḛẁḛḛḍ ", _interpolate(_named("period"))]), _normalize([_interpolate(_named("n")), "/", _interpolate(_named("total")), " ṛḛḛṽḭḭḛḛẁḛḛḍ ", _interpolate(_named("period")), "龍ผู้]"])])},
        "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥααṭ'ṡ ṇḛḛẁ?龍ผู้]"])},
        "log_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṓṓṵṵṭ龍ผู้]"])},
        "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱḛḛẏẏḅṓṓααṛḍ ṡḥṓṓṛṭͼṵṵṭṡ龍ผู้]"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṭ ẁḭḭṭḥ ṵṵṡ龍ผู้]"])},
        "chat_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṛ ͼḥααṭ ͼṓṓṵṵḽḍ ṇṓṓṭ ḅḛḛ ḽṓṓααḍḛḛḍ (ḀḀṛḛḛ ẏẏṓṓṵṵ ṃααẏẏḅḛḛ ṵṵṡḭḭṇḡ ααṇ ααḍ-ḅḽṓṓͼḳḛḛṛ?)龍ผู้]"])},
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṃṗ ṭṓṓ...龍ผู้]"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃṵṵṇḭḭṭẏẏ龍ผู้]"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ龍ผู้]"])},
        "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓṃḛḛṗααḡḛḛ龍ผู้]"])},
        "reviews_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
        "support_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱṇṓṓẁḽḛḛḍḡḛḛ ḅααṡḛḛ龍ผู้]"])},
        "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẁḭḭṭͼḥ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααṡḳṡ龍ผู้]"])}
      },
      "toast_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽḭḭͼααṭḭḭṓṓṇ ẁααṡ ṵṵṗḍααṭḛḛḍ. ", _interpolate(_named("buttonStart")), "Ṛḛḛḽṓṓααḍ", _interpolate(_named("buttonEnd")), ".龍ผู้]"])},
      "pending_invites": {
        "use_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ ṭṓṓ ṡṭααṛṭ ṵṵṡḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṡ ṗααṛṭ ṓṓϝ ẏẏṓṓṵṵṛ ẁṓṓṛḳṡṗααͼḛḛ:龍ผู้]"])},
        "invitation_received": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ĵṵṵṡṭ ḽḛḛṭṭḭḭṇḡ ẏẏṓṓṵṵ ḳṇṓṓẁ ṭḥααṭ ẏẏṓṓṵṵ'ṽḛḛ ααḽṛḛḛααḍẏẏ ṛḛḛͼḛḛḭḭṽḛḛḍ ααṇ ḭḭṇṽḭḭṭḛḛ ṭṓṓ ṵṵṡḛḛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααṡ ṗααṛṭ ṓṓϝ αα ẁṓṓṛḳṡṗααͼḛḛ. ḬḬϝ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ, ẏẏṓṓṵṵ ṃααẏẏ ṓṓϝ ͼṓṓṵṵṛṡḛḛ ͼṓṓṇṭḭḭṇṵṵḛḛ, ḅṵṵṭ ṇṓṓṭḛḛ ṭḥααṭ ṭḥḭḭṡ ẁḭḭḽḽ ͼṛḛḛααṭḛḛ αα ", _interpolate(_named("bold")), " ϝṓṓṛ ẏẏṓṓṵṵ.龍ผู้]"])},
          "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡḛḛṗααṛααṭḛḛ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ龍ผู้]"])}
        },
        "onboarding": {
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶḛḛṡ, ḽḛḛṭ ṃḛḛ ͼṓṓṇṭḭḭṇṵṵḛḛ ẁḭḭṭḥ ", _interpolate(_named("link")), ".龍ผู้]"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍αα ṇḛḛẁ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ?龍ผู้]"])},
        "invite_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("inviter")), " ααṡḳṡ ẏẏṓṓṵṵ ṭṓṓ ĵṓṓḭḭṇ ", _interpolate(_named("account")), "龍ผู้]"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṓṓḭḭṇ龍ผู้]"])}
      },
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḭḭṇṭḛḛṛṇḛḛṭ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "shortcuts": {
        "add_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṇḛḛẁ ṗḭḭṇ龍ผู้]"])},
        "command_palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭͼḳ ṇααṽḭḭḡααṭḭḭṓṓṇ龍ผู้]"])},
        "expand_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓͼṵṵṡ ααṇḍ ḛḛẋṗααṇḍ ṭḥḛḛ ͼṓṓṃṃḛḛṇṭ ḛḛḍḭḭṭṓṓṛ龍ผู้]"])},
        "hide_show_subnav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ṓṓṛ ṡḥṓṓẁ ṡḭḭḍḛḛṗααṇḛḛḽ龍ผู้]"])},
        "next_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṽḭḭḡααṭḛḛ ṭṓṓ ṭḥḛḛ ṇḛḛẋṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "open_original_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ ṓṓṛḭḭḡḭḭṇααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "previous_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṽḭḭḡααṭḛḛ ṭṓṓ ṭḥḛḛ ṗṛḛḛṽḭḭṓṓṵṵṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "rate_highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṭḥḛḛ ḥḭḭḡḥḛḛṡṭ ṗṓṓṡṡḭḭḅḽḛḛ ṛααṭḭḭṇḡ龍ผู้]"])},
        "rate_lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṭḥḛḛ ḽṓṓẁḛḛṡṭ ṗṓṓṡṡḭḭḅḽḛḛ ṛααṭḭḭṇḡ龍ผู้]"])},
        "shuffle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṵṵϝϝḽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ (ṛḛḛ-ṡṓṓṛṭṡ ṛααṇḍṓṓṃḽẏẏ)龍ผู้]"])},
        "suggest_shortcuts_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛḽḽ ṵṵṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱḛḛẏẏḅṓṓααṛḍ ṡḥṓṓṛṭͼṵṵṭṡ龍ผู้]"])},
        "toggle_shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ṡḥṓṓṛṭͼṵṵṭṡ ṃṓṓḍααḽ龍ผู้]"])}
      }
    },
    "comment_editor": {
      "char_limit_reached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ", _interpolate(_named("charLimit")), "-ͼḥααṛααͼṭḛḛṛ ḽḭḭṃḭḭṭ龍ผู้]"])},
      "file_error_format_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬṃααḡḛḛ ", _interpolate(_named("name")), " ṭẏẏṗḛḛ (", _interpolate(_named("type")), ") ḭḭṡ ṇṓṓṭ ααḽḽṓṓẁḛḛḍ龍ผู้]"])},
      "file_error_size_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬṃααḡḛḛ ", _interpolate(_named("name")), " ṡḭḭẓḛḛ ḭḭṡ ṭṓṓṓṓ ḅḭḭḡ (5ṀḂ ααḽḽṓṓẁḛḛḍ)龍ผู้]"])},
      "async_loader_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ḛḛḍḭḭṭṓṓṛ ϝααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ龍ผู้]"])},
      "drag_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛṓṓṗ ṭṓṓ ṵṵṗḽṓṓααḍ龍ผู้]"])},
      "gif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḠḬḬḞ龍ผู้]"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṃααḡḛḛ龍ผู้]"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṇḳ龍ผู้]"])},
      "link_editor": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṇḳ龍ผู้]"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛẋṭ龍ผู้]"])}
      },
      "template": {
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ṭḛḛṃṗḽααṭḛḛṡ龍ผู้]"])},
        "no_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭḛḛṃṗḽααṭḛḛṡ ααḍḍḛḛḍ龍ผู้]"])}
      }
    },
    "ratings": {
      "reason": {
        "add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααͼḳ龍ผู้]"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
        "others_and_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ ḭḭṇͼḽṵṵḍḭḭṇḡ ḍḛḛḽḛḛṭḛḛḍ ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇ ϝṓṓṛ ṛααṭḭḭṇḡ龍ผู้]"])}
      }
    }
  },
  "onboarding": {
    "form": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵṇṭṛẏẏ龍ผู้]"])},
      "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ ṇααṃḛḛ龍ผู้]"])},
      "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṇααṃḛḛ龍ผู้]"])},
      "number_of_employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ḛḛṃṗḽṓṓẏẏḛḛḛḛṡ龍ผู้]"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḥṓṓṇḛḛ ṇṵṵṃḅḛḛṛ龍ผู้]"])},
      "company_number_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛ'ḽḽ ṛḛḛααͼḥ ṓṓṵṵṭ ṭṓṓ ḥḛḛḽṗ ẏẏṓṓṵṵ ṡḛḛṭ ṵṵṗ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗααṇẏẏ ṇααṃḛḛ龍ผู้]"])},
      "company_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗααṇẏẏ ͼṓṓṇṭααͼṭ ṇṵṵṃḅḛḛṛ龍ผู้]"])},
      "first_hearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ḍḭḭḍ ẏẏṓṓṵṵ ϝḭḭṛṡṭ ḥḛḛααṛ ααḅṓṓṵṵṭ Żḛḛṇḍḛḛṡḳ ǪḀḀ?龍ผู้]"])},
      "first_hearing_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ͼḥααṇṇḛḛḽ龍ผู้]"])},
      "number_of_agents_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṇṵṵṃḅḛḛṛ ṓṓϝ ααḡḛḛṇṭṡ龍ผู้]"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ龍ผู้]"])}
    },
    "errors": {
      "fill_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽḽ ḭḭṇ ααḽḽ ṛḛḛʠṵṵḭḭṛḛḛḍ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
      "agree_to_terms_and_privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ṃṵṵṡṭ ααḡṛḛḛḛḛ ṭṓṓ ḥααṽḛḛ ṛḛḛααḍ ααṇḍ ͼṓṓṇṡḛḛṇṭ ṭṓṓ ṓṓṵṵṛ ṭḛḛṛṃṡ ααṇḍ ṓṓṵṵṛ ṗṛḭḭṽααͼẏẏ ṗṓṓḽḭḭͼẏẏ.龍ผู้]"])}
    },
    "first_hearing_options": {
      "slack_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṗṗṓṓṛṭ ḍṛḭḭṽḛḛṇ Ṣḽααͼḳ ͼḥααṇṇḛḛḽ龍ผู้]"])},
      "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽḛḛṛṭḭḭṡḭḭṇḡ龍ผู้]"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṇṭ龍ผู้]"])},
      "friend_or_colleague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḭḭḛḛṇḍ ṓṓṛ ͼṓṓḽḽḛḛααḡṵṵḛḛ龍ผู้]"])},
      "help_desk_or_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṃααṛḳḛḛṭṗḽααͼḛḛ ṓṓṛ ααͼͼṓṓṵṵṇṭ ṃααṇααḡḛḛṛ龍ผู้]"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
      "referring_site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛϝḛḛṛṛḭḭṇḡ ṡḭḭṭḛḛ龍ผู้]"])},
      "search_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḛḛṇḡḭḭṇḛḛ (Ḡṓṓṓṓḡḽḛḛ, Ḃḭḭṇḡ, ḛḛṭͼ.)龍ผู้]"])},
      "social_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓͼḭḭααḽ ṃḛḛḍḭḭαα龍ผู้]"])}
    },
    "step_1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛḽḽ ṵṵṡ ααḅṓṓṵṵṭ ẏẏṓṓṵṵṛṡḛḛḽϝ ααṇḍ ẏẏṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ḍḛḛṭααḭḭḽṡ龍ผู้]"])}
    },
    "step_2": {
      "own_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ'ṃ ṛḛḛααḍẏẏ.龍ผู้]"])},
      "sample_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡḛḛẏẏ ααṛṓṓṵṵṇḍ, ḡḛḛṭ ṭṓṓ ḳṇṓṓẁ ṭḥḛḛ ααṗṗ, ḍḛḛḽḛḛṭḛḛ ααṇḍ ααḍḍ ṭḥḭḭṇḡṡ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ͼṛḛḛααṭḛḛḍ. Ŵḥααṭ'ṡ ṇḛḛẋṭ?龍ผู้]"])},
      "own_data_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍẏẏ ṭṓṓ ḅṛḭḭṇḡ ḭḭṇ ẏẏṓṓṵṵṛ ṛḛḛααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ?龍ผู้]"])},
      "own_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ẏẏṓṓṵṵṛ ṓṓẁṇ ḍααṭαα龍ผู้]"])},
      "sample_data_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ ḛḛẋṗḽṓṓṛḭḭṇḡ龍ผู้]"])},
      "sample_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ḭḭṭ ṓṓṵṵṭ ẁḭḭṭḥ ṡααṃṗḽḛḛ ḍααṭαα龍ผู้]"])}
    },
    "step_3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓ ẁḛḛ ͼααṇ ṡṭααṛṭ ṗṵṵḽḽḭḭṇḡ ḭḭṇ ẏẏṓṓṵṵṛ ṭḭḭͼḳḛḛṭṡ ϝṓṓṛ ẏẏṓṓṵṵ ṭṓṓ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "cancel_flow_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ͼḥααṇḡḛḛḍ ṃẏẏ ṃḭḭṇḍ.龍ผู้]"])},
    "cancel_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ ṡḭḭḡṇ-ṵṵṗ龍ผู้]"])}
  },
  "pins": {
    "modal": {
      "pin_created_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ͼṛḛḛααṭḛḛḍ. ", _interpolate(_named("buttonStart")), "Ḡṓṓ ṭṓṓ ṗḭḭṇṡ", _interpolate(_named("buttonEnd")), ".龍ผู้]"])},
      "cta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ṗḭḭṇṡ ḭḭṡ ααṽααḭḭḽααḅḽḛḛ ṓṓṇ ṭḥḛḛ Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ ṗḽααṇ.龍ผู้]"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ṗḽααṇ龍ผู้]"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααḽḽ ṗḽααṇṡ龍ผู้]"])}
      },
      "how_to_save_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ẏẏṓṓṵṵṛ ṇṓṓṭḛḛṡ ṭṓṓ ṗṛḛḛṗααṛḛḛ ϝṓṓṛ ṭḥḛḛ ṇḛḛẋṭ 1:1 ṓṓṛ ͼṓṓααͼḥḭḭṇḡ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
      "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ ϝṓṓṛ Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])},
      "add_others_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṓṓṛ ααḍḍ ṓṓṭḥḛḛṛṡ ẁḭḭṭḥ ", "@", "龍ผู้]"])},
      "conversation_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "create_new_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ṗḭḭṇ龍ผู้]"])},
      "create_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṗḭḭṇ龍ผู้]"])},
      "csat_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
      "edit_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṗḭḭṇ龍ผู้]"])},
      "how_pins_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ṗḭḭṇṡ ẁṓṓṛḳ龍ผู้]"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḍṓṓṇ'ṭ ḥααṽḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṭṓṓ ṡḛḛḛḛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "pin_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ͼααṛḍ龍ผู้]"])},
      "pin_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "pin_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
      "pin_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṡααṽḛḛḍ龍ผู้]"])},
      "pin_this_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṭḥḭḭṡ ͼααṛḍ龍ผู้]"])},
      "pin_this_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "pin_this_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṭḥḭḭṡ ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
      "pin_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṵṵṇḍḛḛṛ αα ṵṵṡḛḛṛ龍ผู้]"])},
      "search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṵṵṡḛḛṛ龍ผู้]"])},
      "who_can_see_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ẏẏṓṓṵṵ ͼααṇ ṡḛḛḛḛ ẁḥααṭ ẏẏṓṓṵṵ ṗḭḭṇ. Ṫṓṓ ṡḥααṛḛḛ ẁḭḭṭḥ ṓṓṭḥḛḛṛṡ ", "@", "ṃḛḛṇṭḭḭṓṓṇ ṭḥḛḛṃ ḭḭṇ ṭḥḛḛ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])}
    },
    "comments": {
      "are_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("agentsNames")), " ḭḭṡ ṇṓṓẁ ḭḭṇṽḭḭṭḛḛḍ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("agentsNames")), " ααṛḛḛ ṇṓṓẁ ḭḭṇṽḭḭṭḛḛḍ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])])},
      "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ͼṓṓṃṃḛḛṇṭ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])])},
      "mentioned_participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ŶŶṓṓṵṵ ṃḛḛṇṭḭḭṓṓṇḛḛḍ ", _interpolate(_named("participants")), " ḅṵṵṭ ṭḥḛḛẏẏ'ṛḛḛ ṇṓṓṭ ḭḭṇ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ."]), _normalize(["ŶŶṓṓṵṵ ṃḛḛṇṭḭḭṓṓṇḛḛḍ ", _interpolate(_named("participants")), " ḅṵṵṭ ṭḥḛḛẏẏ'ṛḛḛ ṇṓṓṭ ḭḭṇ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])])},
      "participants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṗααṛṭḭḭͼḭḭṗααṇṭṡ"]), _normalize([_interpolate(_named("n")), " ṗααṛṭḭḭͼḭḭṗααṇṭ"]), _normalize([_interpolate(_named("n")), " ṗααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])])},
      "remove_user_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("agentName")), " ϝṛṓṓṃ ααͼͼḛḛṡṡḭḭṇḡ ṭḥḭḭṡ ṗḭḭṇ?龍ผู้]"])},
      "do_nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ṇṓṓṭḥḭḭṇḡ龍ผู้]"])},
      "do_nothing_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ṇṓṓṭ ḭḭṇṽḭḭṭḛḛ ṭḥḛḛṃ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "editor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṃṃḛḛṇṭ龍ผู้]"])},
      "invite_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṭḥḛḛṃ龍ผู้]"])},
      "invite_them_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṭḥḛḛṃ ṭṓṓ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "remove_user_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ṵṵṡḛḛṛ龍ผู้]"])},
      "thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṛḛḛααḍ龍ผู้]"])}
    },
    "folder_content": {
      "delete_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
      "delete_pin_with_comments_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ααḽṡṓṓ ḍḛḛḽḛḛṭḛḛ ααḽḽ ṭḥḛḛ ͼṓṓṃṃḛḛṇṭṡ ṵṵṇḍḛḛṛ ṭḥḭḭṡ ṗḭḭṇ.龍ผู้]"])},
      "new_messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṇḛḛẁ ṃḛḛṡṡααḡḛḛṡ"]), _normalize([_interpolate(_named("n")), " ṇḛḛẁ ṃḛḛṡṡααḡḛḛ"]), _normalize([_interpolate(_named("n")), " ṇḛḛẁ ṃḛḛṡṡααḡḛḛṡ龍ผู้]"])])},
      "no_pins_body_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ẏẏṓṓṵṵ ͼααṇ ṡḛḛḛḛ ẏẏṓṓṵṵṛ ṗḭḭṇṡ. Ṫṓṓ ṡḥααṛḛḛ ṗḭḭṇṡ, ", "@", " ṡṓṓṃḛḛṓṓṇḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])},
      "pinned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("agentName")), " ṗḭḭṇṇḛḛḍ ", _interpolate(_named("pinType")), "龍ผู้]"])},
      "pinned_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍αα ḍααṡḥḅṓṓααṛḍ龍ผู้]"])},
      "pinned_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "pinned_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍αα ṇṓṓṭḛḛ龍ผู้]"])},
      "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ龍ผู้]"])},
      "cta": {
        "admin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ṭṓṓ ḡḛḛṭ ͼṓṓααͼḥḭḭṇḡ ṗḭḭṇṡ龍ผู้]"])},
        "no_pins_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ṗḭḭṇṡ ḭḭṡ ααṽααḭḭḽααḅḽḛḛ ṓṓṇ ṭḥḛḛ Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ ṗḽααṇ.龍ผู้]"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ṗḭḭṇṡ ḭḭṡ ααṽααḭḭḽααḅḽḛḛ ṓṓṇ ṭḥḛḛ Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ ṗḽααṇ龍ผู้]"])},
        "no_pins_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ṭṓṓ ḡḛḛṭ ϝḛḛααṭṵṵṛḛḛ龍ผู้]"])}
      },
      "no_pins_body_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṗααṛḛḛ ϝṓṓṛ ẏẏṓṓṵṵṛ 1:1’ṡ ṓṓṛ ṡααṽḛḛ ṡṓṓṃḛḛṭḥḭḭṇḡ ϝṓṓṛ ααṇṓṓṭḥḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
      "delete_pin_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ṗḭḭṇ ααṇḍ ααḽḽ ααḍḍḛḛḍ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])},
      "delete_pin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṗḭḭṇ龍ผู้]"])},
      "delete_pin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṗḭḭṇ龍ผู้]"])},
      "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓḽḍḛḛṛ ṇααṃḛḛ龍ผู้]"])},
      "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ ṭḥḭḭṇḡṡ ṭṓṓ ͼṓṓṃḛḛ ḅααͼḳ ṭṓṓ ḽααṭḛḛṛ龍ผู้]"])},
      "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽẏẏ龍ผู้]"])},
      "sort_options": {
        "new_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁḛḛṡṭ ϝḭḭṛṡṭ龍ผู้]"])},
        "old_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḽḍḛḛṡṭ ϝḭḭṛṡṭ龍ผู้]"])}
      }
    },
    "no_pins_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ααṇ ḭḭṇṭḛḛṛḛḛṡṭḭḭṇḡ ḍααṡḥḅṓṓααṛḍ ṭṛḛḛṇḍ ṓṓṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇ ṗṛḛḛṗααṛααṭḭḭṓṓṇ ϝṓṓṛ ẏẏṓṓṵṵṛ 1-1ṡ, ṓṓṛ ṇṓṓṭḛḛ ḭḭṭ ḍṓṓẁṇ ϝṓṓṛ ααṇṓṓṭḥḛḛṛ ṭḭḭṃḛḛ.龍ผู้]"])},
    "no_pins_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṗḭḭṇ ḅẏẏ ͼḽḭḭͼḳḭḭṇḡ ṓṓṇ ṭḥḛḛ ḅṵṵṭṭṓṓṇ ααḅṓṓṽḛḛ ṓṓṛ ṭḥḛḛ ṗḭḭṇ ḭḭͼṓṓṇ ḭḭṇ ṭḥḛḛ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṓṓṛ Ḍααṡḥḅṓṓααṛḍ ṽḭḭḛḛẁ.龍ผู้]"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇ龍ผู้]"])},
    "no_pins_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗḭḭṇṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
    "no_results_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṵṵḽṭṡ ϝṓṓṛ ", _interpolate(_list(0)), "龍ผู้]"])},
    "no_results_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṵṵṡḭḭṇḡ ḍḭḭϝϝḛḛṛḛḛṇṭ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ͼḥḛḛͼḳḭḭṇḡ ϝṓṓṛ ṭẏẏṗṓṓṡ.龍ผู้]"])},
    "pin_manual_button": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ龍ผู้]"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ṗḭḭṇ龍ผู้]"])}
    },
    "sidebar": {
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛṡ龍ผู้]"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṓṓṗḽḛḛ龍ผู้]"])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ṵṵṡḛḛṛ, ḳḛḛẏẏẁṓṓṛḍ...龍ผู้]"])},
      "sort_option_alphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṗḥααḅḛḛṭḭḭͼααḽḽẏẏ龍ผู้]"])},
      "sort_option_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛṇṭḽẏẏ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
      "sort_option_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛṇṭḽẏẏ ḛḛḍḭḭṭḛḛḍ龍ผู้]"])},
      "sort_option_most_pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ṗḭḭṇṡ龍ผู้]"])}
    }
  },
  "profile": {
    "goal": {
      "form": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḬḬ ααḭḭṃ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ", _interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ", _interpolate(_named("schedule"))]), _normalize(["ḬḬ ααḭḭṃ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ", _interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ", _interpolate(_named("schedule")), "龍ผู้]"])])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ḡṓṓααḽ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṡḛḛṭ αα ṗḛḛṛṡṓṓṇααḽ ḡṓṓααḽ ϝṓṓṛ ṭḥḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ ṭṓṓ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
      "period_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṽḛḛṛẏẏ ḍααẏẏ龍ผู้]"])},
      "period_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
      "period_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṽḛḛṛẏẏ ẁḛḛḛḛḳ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ龍ผู้]"])}
    },
    "navigation": {
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṔḚḚṚṢṎṎṄḀḀḺ龍ผู้]"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ龍ผู้]"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααḽ龍ผู้]"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṃṗḽααṭḛḛṡ龍ผู้]"])}
    },
    "notifications": {
      "sections": {
        "praise": {
          "loading_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ ͼḥααṇṇḛḛḽṡ龍ผู้]"])},
          "channel_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼḥααṇṇḛḛḽ龍ผู้]"])},
          "comments_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṡḥααṛḛḛ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ẁḭḭṭḥ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṗṓṓẁḛḛṛ ẏẏṓṓṵṵṛ ͼṓṓḽḽḛḛααḡṵṵḛḛṡ ḅẏẏ ḥḭḭḡḥḽḭḭḡḥṭḭḭṇḡ ṭḥḛḛḭḭṛ ḛḛẋͼḛḛṗṭḭḭṓṓṇααḽ ṡḛḛṛṽḭḭͼḛḛ ʠṵṵααḽḭḭṭẏẏ ααṇḍ ḛḛẋͼḛḛḽḽḛḛṇṭ ͼṵṵṡṭṓṓṃḛḛṛ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
          "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛ ṗṓṓṡḭḭṭḭḭṽḛḛ ṡṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ ṭṓṓ Ṣḽααͼḳ龍ผู้]"])},
          "no_channel_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼḥααṇṇḛḛḽṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡḥααṛḛḛ ϝḛḛḛḛḍḅααͼḳ ẁḭḭṭḥ ṡͼṓṓṛḛḛṡ ααḅṓṓṽḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛααḭḭṡḛḛḅṓṓṭ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇṡ龍ผู้]"])}
        },
        "reminders": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛ αα ṛḛḛṃḭḭṇḍḛḛṛ ααṭ ṭḥḛḛ ṡṭααṛṭ ṓṓϝ ṭḥḛḛ ẁḛḛḛḛḳ ḭḭϝ ẏẏṓṓṵṵ ḥααṽḛḛṇ'ṭ ḍṓṓṇḛḛ ααṇẏẏ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃḭḭṇḍḛḛṛṡ龍ผู้]"])},
          "weekly_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳḽẏẏ ṛḛḛṃḭḭṇḍḛḛṛ ṭṓṓ ḍṓṓ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
        },
        "slack": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ ααḅṓṓṵṵṭ ṇḛḛẁ ṛḛḛṽḭḭḛḛẁṡ, ṛḛḛṗḽḭḭḛḛṡ, ṃḛḛṇṭḭḭṓṓṇṡ, ααṇḍ ḍḭḭṡṗṵṵṭḛḛṡ ḭḭṇ Ṣḽααͼḳ.龍ผู้]"])},
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
          "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
          "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṇṭḭḭṓṓṇṡ龍ผู้]"])},
          "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡṡ龍ผู้]"])},
          "thread_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṛḛḛααḍ ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇṡ龍ผู้]"])}
        },
        "notifications": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝẏẏ ααḅṓṓṵṵṭ ṇḛḛẁ ṛḛḛṽḭḭḛḛẁṡ, ṛḛḛṗḽḭḭḛḛṡ, ṃḛḛṇṭḭḭṓṓṇṡ ααṇḍ ḍḭḭṡṗṵṵṭḛḛṡ.龍ผู้]"])},
          "disputes_related_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ṛḛḛḽααṭḛḛḍ ṭṓṓ ṃḛḛ龍ผู้]"])},
          "mentions_in_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṇṭḭḭṓṓṇṡ ḭḭṇ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
          "new_received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
          "replies_in_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽḭḭḛḛṡ ḭḭṇ ṭḥṛḛḛααḍṡ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])}
        },
        "reports": {
          "content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗṓṓṛṭ ẁḭḭṭḥ ṡṭααṭḭḭṡṭḭḭͼṡ龍ผู้]"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛ ṛḛḛṗṓṓṛṭṡ ααḅṓṓṵṵṭ ḡḭḭṽḛḛṇ ααṇḍ ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗṓṓṛṭṡ龍ผู้]"])}
        }
      },
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍααḭḭḽẏẏ龍ผู้]"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃṓṓṇṭḥḽẏẏ龍ผู้]"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẁḛḛḛḛḳḽẏẏ龍ผู้]"])},
      "feedback_steps": {
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααḭḭḽẏẏ龍ผู้]"])},
        "hours_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏ 3 ḥṓṓṵṵṛṡ龍ผู้]"])},
        "hours_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏ 6 ḥṓṓṵṵṛṡ龍ผู้]"])},
        "instant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡṭααṇṭḽẏẏ龍ผู้]"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ龍ผู้]"])},
        "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎϝϝ龍ผู้]"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳḽẏẏ龍ผู้]"])}
      },
      "locked_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ααḍṃḭḭṇ ḥααṡ ḽṓṓͼḳḛḛḍ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṡḛḛṭṭḭḭṇḡṡ.龍ผู้]"])},
      "section_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
      "section_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ龍ผู้]"])},
      "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])}
    },
    "logins": {
      "active": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("email")), " (ααͼṭḭḭṽḛḛ)龍ผู้]"])},
      "add_new_social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṇḛḛẁ ṡṓṓͼḭḭααḽ ṡḭḭḡṇ-ḭḭṇ ṭṓṓ ṭḥḭḭṡ ααͼͼṓṓṵṵṇṭ:龍ผู้]"])},
      "delete_confirm_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḭḭṇḡ ααṇ ααͼṭḭḭṽḛḛ ṡḭḭḡṇ-ḭḭṇ ṃḛḛṭḥṓṓḍ ẁḭḭḽḽ ṡḭḭḡṇ ẏẏṓṓṵṵ ṓṓṵṵṭ. ŶŶṓṓṵṵ ͼααṇ ṡḭḭḡṇ ḭḭṇ ααḡααḭḭṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ααḽṭḛḛṛṇααṭḭḭṽḛḛ ḛḛṃααḭḭḽ.龍ผู้]"])},
      "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ṡḭḭḡṇ-ḭḭṇ ṃḛḛṭḥṓṓḍ龍ผู้]"])},
      "google_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵͼͼḛḛṡṡϝṵṵḽḽẏẏ ααḍḍḛḛḍ Ḡṓṓṓṓḡḽḛḛ ααṡ αα ṡḭḭḡṇ-ḭḭṇ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])},
      "login_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
      "slack_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵͼͼḛḛṡṡϝṵṵḽḽẏẏ ααḍḍḛḛḍ Ṣḽααͼḳ ααṡ αα ṡḭḭḡṇ-ḭḭṇ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇ龍ผู้]"])},
      "add_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "add_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ Ṣḽααͼḳ龍ผู้]"])},
      "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ?龍ผู้]"])},
      "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ龍ผู้]"])},
      "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṭḥṓṓḍ龍ผู้]"])},
      "password_reset_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṭ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
      "password_reset_intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṇ ḛḛṃααḭḭḽ ṭṓṓ ", _interpolate(_named("email")), " ẁḭḭḽḽ ḅḛḛ ṡḛḛṇṭ ṭṓṓ ṛḛḛṡḛḛṭ ẏẏṓṓṵṵṛ ṗααṡṡẁṓṓṛḍ.龍ผู้]"])},
      "password_subsection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])}
    },
    "password": {
      "primary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗṛḭḭṃααṛẏẏ ḛḛṃααḭḭḽ龍ผู้]"])},
      "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥḛḛͼḳ ẏẏṓṓṵṵṛ ḭḭṇḅṓṓẋ ϝṓṓṛ ṓṓṵṵṛ ͼṓṓṇϝḭḭṛṃααṭḭḭṓṓṇ ḛḛṃααḭḭḽ龍ผู้]"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "auth_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])},
      "change_email_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼḥααṇḡḛḛ ṭḥααṭ, ͼḥααṇḡḛḛ ẏẏṓṓṵṵṛ ", _interpolate(_named("link")), " ḅḛḛϝṓṓṛḛḛḥααṇḍ.龍ผู้]"])},
      "email_associated_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḛḛṃααḭḭḽ ", _interpolate(_named("email")), " ẁḭḭḽḽ ḅḛḛ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḭḭṡ ṗααṡṡẁṓṓṛḍ.龍ผู้]"])},
      "google_oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "oauth_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ龍ผู้]"])}
    },
    "general": {
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓϝḭḭḽḛḛ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓϝḭḭḽḛḛ ṗḭḭͼṭṵṵṛḛḛ龍ผู้]"])},
      "avatar_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛ ḅḛḛṡṭ ṛḛḛṡṵṵḽṭṡ ṵṵṗḽṓṓααḍ αα ṡʠṵṵααṛḛḛ ṗḥṓṓṭṓṓ (256ẋ256 ṗḭḭẋḛḛḽṡ ṓṓṛ ṃṓṓṛḛḛ)龍ผู้]"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṃααṛẏẏ ḛḛṃααḭḭḽ龍ผู้]"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "start_of_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ ṓṓϝ ṭḥḛḛ ẁḛḛḛḛḳ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
    },
    "templates": {
      "create_template": {
        "template_view_options_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṭ ḽḛḛααṡṭ ṓṓṇḛḛ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṃṗḽααṭḛḛ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "name_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṃṗḽααṭḛḛ ṇααṃḛḛ龍ผู้]"])},
        "show_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṭḛḛṃṗḽααṭḛḛ ḭḭṇ龍ผู้]"])},
        "template_content_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭḛḛṃṗḽααṭḛḛ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
        "template_name_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṭḛḛṃṗḽααṭḛḛ ṇααṃḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṭḛḛṃṗḽααṭḛḛ龍ผู้]"])}
      },
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṭḛḛṃṗḽααṭḛḛ龍ผู้]"])},
      "default": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼṓṓṃṃḛḛṇṭ ṭḛḛṃṗḽααṭḛḛṡ ṭṓṓ ṡṗḛḛḛḛḍ ṵṵṗ ṛḛḛṽḭḭḛḛẁḭḭṇḡ, ṗḭḭṇṇḭḭṇḡ ḭḭṭḛḛṃṡ, ααṇḍ ͼṓṓααͼḥḭḭṇḡ.龍ผู้]"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
        "used_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛḍ ḭḭṇ龍ผู้]"])}
      },
      "delete_template": {
        "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṭḛḛṃṗḽααṭḛḛ龍ผู้]"])},
        "delete_confirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ṭḛḛṃṗḽααṭḛḛ?龍ผู้]"])}
      },
      "edit_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṭḛḛṃṗḽααṭḛḛ龍ผู้]"])},
      "empty_state": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼṓṓṃṃḛḛṇṭ ṭḛḛṃṗḽααṭḛḛṡ ṭṓṓ ṡṗḛḛḛḛḍ ṵṵṗ ṛḛḛṽḭḭḛḛẁḭḭṇḡ, ṗḭḭṇṇḭḭṇḡ ḭḭṭḛḛṃṡ, ααṇḍ ͼṓṓααͼḥḭḭṇḡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭḛḛṃṗḽααṭḛḛṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])}
      },
      "toast": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṭḛḛṃṗḽααṭḛḛ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṭḛḛṃṗḽααṭḛḛ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṭḛḛṃṗḽααṭḛḛ ṡααṽḛḛḍ龍ผู้]"])}
      },
      "types": {
        "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])},
        "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ龍ผู้]"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ααṇḍ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
      }
    }
  },
  "provisioning": {
    "errors": {
      "account_not_found": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ḡḛḛṭ ααͼͼḛḛṡṡ, ḡḛḛṭ ḭḭṇ ṭṓṓṵṵͼḥ ẁḭḭṭḥ ", _interpolate(_named("link")), ".龍ผู้]"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṵṵṡṭṓṓṃḛḛṛ ṡṵṵṗṗṓṓṛṭ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼͼṓṓṵṵṇṭ ϝṓṓṵṵṇḍ龍ผู้]"])}
      },
      "account_not_migrated": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛ ṇṓṓẁ, ṵṵṡḛḛ ", _interpolate(_named("link")), " ṭṓṓ ααͼͼḛḛṡṡ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])},
        "return_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭṵṵṛṇ ṭṓṓ Żḛḛṇḍḛḛṡḳ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṇṓṓṭ ṃḭḭḡṛααṭḛḛḍ龍ผู้]"])}
      }
    }
  },
  "review": {
    "rating_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
  },
  "routes": {
    "dashboard": {
      "label": {
        "bot_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḂṓṓṭǪḀḀ龍ผู้]"])},
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ龍ผู้]"])}
      },
      "keyword": {
        "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭʠṡ龍ผู้]"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃḛḛṭṛḭḭͼṡ龍ผู้]"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡͼṓṓṛḛḛṡ龍ผู้]"])},
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṭααṭḭḭṡṭḭḭͼṡ龍ผู้]"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṡḥḅṓṓααṛḍ龍ผู้]"])}
    },
    "conversations": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "keyword": {
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
        "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
      }
    },
    "activity": {
      "breadcrumb": {
        "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ龍ผู้]"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])}
      },
      "keyword": {
        "my_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃẏẏ ͼṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
        "my_disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃẏẏ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
        "my_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃẏẏ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "label": {
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
        "reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭḛḛḍ龍ผู้]"])},
        "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])}
    },
    "assignments": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])}
    },
    "coaching": {
      "label": {
        "pins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ龍ผู้]"])},
        "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
        "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ龍ผู้]"])}
    },
    "profile_settings": {
      "breadcrumb": {
        "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽ龍ผู้]"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṃṗḽααṭḛḛṡ龍ผู้]"])}
      },
      "keyword": {
        "create_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṛḛḛααṭḛḛ ṗααṡṡẁṓṓṛḍ龍ผู้]"])},
        "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṛḛḛḍḛḛṇṭḭḭααḽṡ龍ผู้]"])},
        "e_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ-ṃααḭḭḽ龍ผู้]"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṃααḭḭḽ龍ผู้]"])},
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽṓṓḡḭḭṇ龍ผู้]"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṡḛḛṭ龍ผู้]"])},
        "review_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ龍ผู้]"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡḽααͼḳ龍ผู้]"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭḛḛṃṗḽααṭḛḛṡ龍ผู้]"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṡḛḛṛ龍ผู้]"])}
      },
      "label": {
        "create_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṭḛḛṃṗḽααṭḛḛ龍ผู้]"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααḽ龍ผู้]"])},
        "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ龍ผู้]"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓḡḭḭṇ龍ผู้]"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṃṗḽααṭḛḛṡ龍ผู้]"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
    },
    "settings": {
      "breadcrumb": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])}
      },
      "keyword": {
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḅḭḭḽḽḭḭṇḡ龍ผู้]"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṃṗααṇẏẏ龍ผู้]"])},
        "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛḡṛααṭḭḭṓṓṇṡ龍ผู้]"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ龍ผู้]"])},
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗααẏẏṃḛḛṇṭ龍ผู้]"])},
        "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡḛḛααṭṡ龍ผู้]"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])}
      },
      "label": {
        "authentications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
        "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααḽ龍ผู้]"])},
        "new_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])},
        "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
        "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ龍ผู้]"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
    }
  },
  "settings": {
    "auto_qa": {
      "conflicting_error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝḽḭḭͼṭḭḭṇḡ ṃḛḛṡṡααḡḛḛ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "conflicting_error_msg_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ṓṓṛ ṛḛḛṃṓṓṽḛḛ ṓṓṇḛḛ ṓṓϝ ͼṓṓṇϝḽḭḭͼṭḭḭṇḡ ṃḛḛṡṡααḡḛḛṡ龍ผู้]"])},
      "dynamic_content_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ ṭḥḛḛ ", _interpolate(_named("placeholder")), " ḭḭͼṓṓṇ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ ṡḛḛṇṡḭḭṭḭḭṽḛḛ ḍααṭαα ϝṛṓṓṃ ḀḀṵṵṭṓṓǪḀḀ. Ḟṓṓṛ ḛḛẋααṃṗḽḛḛ, ḭḭṇṡṭḛḛααḍ ṓṓϝ \"Ṫααḳḛḛ ͼααṛḛḛ, Ĵṓṓḥṇ\", ṵṵṡḛḛ \"Ṫααḳḛḛ ͼααṛḛḛ, ", _interpolate(_named("placeholder")), "\" ṭṓṓ ṛḛḛṗṛḛḛṡḛḛṇṭ ααṇẏẏ ṇααṃḛḛ.龍ผู้]"])}
    },
    "autoqa": {
      "create": {
        "phrase_row_limit_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṁααẋḭḭṃṵṵṃ ", _interpolate(_named("limit")), " ṛṓṓẁṡ龍ผู้]"])}
      }
    },
    "categories": {
      "create": {
        "apply_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽẏẏ ṛṵṵḽḛḛ ṭṓṓ龍ผู้]"])},
        "category_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ’ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ 10 ͼṵṵṡṭṓṓṃ ͼααṭḛḛḡṓṓṛẏẏ ḽḭḭṃḭḭṭ龍ผู้]"])},
        "category_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ", _interpolate(_named("limit")), " ͼṵṵṡṭṓṓṃ ͼααṭḛḛḡṓṓṛẏẏ ḽḭḭṃḭḭṭ龍ผู้]"])},
        "conditions": {
          "add_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
          "add_row_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓẁ龍ผู้]"])},
          "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṇḍ龍ผู้]"])},
          "delete_condition_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
          "if_agent_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ααḡḛḛṇṭ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
          "if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ龍ผู้]"])},
          "otherwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛẁḭḭṡḛḛ ṡͼṓṓṛḛḛ龍ผู้]"])},
          "then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṇ ṡͼṓṓṛḛḛ龍ผู้]"])}
        },
        "create_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṡͼṓṓṛḛḛͼααṛḍ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])}
        },
        "dynamic_content_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ '/' ṭṓṓ ααḍḍ αα ṽααṛḭḭααḅḽḛḛ龍ผู้]"])},
        "filters": {
          "call_direction": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍḭḭṛḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])}
          },
          "agent_message": {
            "errors": {
              "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṃḛḛṡṡααḡḛḛ ͼααṇṇṓṓṭ ḅḛḛ ḛḛṃṗṭẏẏ龍ผู้]"])},
              "too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡααḡḛḛ ͼααṇ'ṭ ḅḛḛ ṓṓṽḛḛṛ 300 ͼḥααṛααͼṭḛḛṛṡ龍ผู้]"])}
            },
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛẋṭ龍ผู้]"])}
          },
          "source_type": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṓṓṵṵṛͼḛḛṡ龍ผู้]"])}
          },
          "ticket_channel": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼḥααṇṇḛḛḽṡ龍ผู้]"])}
          },
          "ticket_tags": {
            "all_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṭααḡṡ龍ผู้]"])}
          }
        },
        "rule_radio": {
          "all_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
          "description_narrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṛṛṓṓẁ ḍṓṓẁṇ ẁḥḛḛṛḛḛ ẏẏṓṓṵṵṛ ṛṵṵḽḛḛ ααṗṗḽḭḭḛḛṡ.龍ผู้]"])},
          "specific_conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛͼḭḭϝḭḭͼ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])}
        },
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ Ṣṗṓṓṭḽḭḭḡḥṭ ḭḭṇṡḭḭḡḥṭ龍ผู้]"])},
        "spotlight_filter": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṃḛḛṡṡααḡḛḛ ͼααṇṇṓṓṭ ḅḛḛ ḛḛṃṗṭẏẏ龍ผู้]"])}
          }
        },
        "spotlight_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ’ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ 10 ͼṵṵṡṭṓṓṃ ḭḭṇṡḭḭḡḥṭ ḽḭḭṃḭḭṭ龍ผู้]"])},
        "spotlight_limit_reached_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ", _interpolate(_named("limit")), " ͼṵṵṡṭṓṓṃ ḭḭṇṡḭḭḡḥṭ ḽḭḭṃḭḭṭ龍ผู้]"])},
        "spotlight_name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽḽ ḭḭṇ ḭḭṇṡḭḭḡḥṭ ṇααṃḛḛ龍ผู้]"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭ ṇααṃḛḛ龍ผู้]"])}
        },
        "title_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭ ṭẏẏṗḛḛ龍ผู้]"])},
        "type_negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ龍ผู้]"])},
        "type_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṵṵṭṛααḽ龍ผู้]"])},
        "type_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓṡḭḭṭḭḭṽḛḛ龍ผู้]"])},
        "category_created": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṇṓṓẁ龍ผู้]"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṡṭααṛṭ ṵṵṡḭḭṇḡ ṭḥḭḭṡ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ, ḡṓṓ ṭṓṓ ẏẏṓṓṵṵṛ ẁṓṓṛḳṡṗααͼḛḛ ṡͼṓṓṛḛḛͼααṛḍ ṡḛḛṭṭḭḭṇḡṡ ααṇḍ ααḍḍ ḭḭṭ ṓṓṇṭṓṓ αα ṡͼṓṓṛḛḛͼααṛḍ ṓṓϝ ẏẏṓṓṵṵṛ ͼḥṓṓḭḭͼḛḛ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ͼṛḛḛααṭḛḛḍ龍ผู้]"])}
        },
        "description_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛ ͼḽṓṓṡḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏḭḭṇḡ ṡṗḛḛͼḭḭϝḭḭͼ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ.龍ผู้]"])},
        "description_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓḛḛṡṇ'ṭ ẁṓṓṛḳ ṓṓṇ ṽṓṓḭḭͼḛḛ ṭṛααṇṡͼṛḭḭṗṭṡ.龍ผู้]"])},
        "limit_reached": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṭ ḭḭṭ龍ผู้]"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥ ϝḭḭṽḛḛ! ✋ ŶŶṓṓṵṵ'ṽḛḛ ṃααẋḛḛḍ ṓṓṵṵṭ ẏẏṓṓṵṵṛ ααṵṵṭṓṓṃααṭḛḛḍ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṭ ", _interpolate(_named("limit")), ". ŶŶṓṓṵṵ'ṛḛḛ ṭḥḛḛ ẁḭḭẓααṛḍ ṓṓϝ ͼααṭḛḛḡṓṓṛḭḭẓααṭḭḭṓṓṇ! 🧙龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ḽḭḭṃḭḭṭ ṛḛḛααͼḥḛḛḍ龍ผู้]"])}
        },
        "multiple_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ααḽḽ ṭḥḛḛ ϝḭḭḛḛḽḍṡ ααṛḛḛ ϝḭḭḽḽḛḛḍ ͼṓṓṛṛḛḛͼṭḽẏẏ龍ผู้]"])},
        "name": {
          "errors": {
            "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ ẏẏṓṓṵṵṛ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ αα ṇααṃḛḛ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ṇααṃḛḛ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
      },
      "custom_category": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛ ͼḽṓṓṡḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏḭḭṇḡ ṡṗḛḛͼḭḭϝḭḭͼ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ. Ḍṓṓḛḛṡṇ'ṭ ẁṓṓṛḳ ṓṓṇ ṽṓṓḭḭͼḛḛ ṭṛααṇṡͼṛḭḭṗṭṡ.龍ผู้]"])},
        "description_transcriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡṵṵṛϝααͼḛḛ ͼḽṓṓṡḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏḭḭṇḡ ṡṗḛḛͼḭḭϝḭḭͼ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ. Ŵṓṓṛḳṡ ṓṓṇ ṭṛααṇṡͼṛḭḭṗṭṡ ṓṓṇḽẏẏ.龍ผู้]"])},
        "description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḅẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏḭḭṇḡ ṡṗḛḛͼḭḭϝḭḭͼ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ ḭḭṇ ḅṓṓṭḥ ααḡḛḛṇṭ ααṇḍ ḅṓṓṭ ṃḛḛṡṡααḡḛḛṡ.龍ผู้]"])},
        "info_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṵṵṡḛḛ ṭḥḭḭṡ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ, ḡṓṓ ṭṓṓ ẏẏṓṓṵṵṛ ẁṓṓṛḳṡṗααͼḛḛ ṡͼṓṓṛḛḛͼααṛḍ ṡḛḛṭṭḭḭṇḡṡ ααṇḍ ααḍḍ ḭḭṭ ṓṓṇṭṓṓ αα ṡͼṓṓṛḛḛͼααṛḍ.龍ผู้]"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ḅααṡḛḛḍ ṓṓṇ ṡṗḛḛͼḭḭϝḭḭͼ ḳḛḛẏẏẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ ḭḭṇ ṃḛḛṡṡααḡḛḛṡ.龍ผู้]"])}
      },
      "edit": {
        "available_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽααḭḭḽααḅḽḛḛ ϝṓṓṛ龍ผู้]"])},
        "insight_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡḭḭḡḥṭ ṡḛḛṭṵṵṗ龍ผู้]"])},
        "insight_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṇṡḭḭḡḥṭ ḍḭḭṡṗḽααẏẏṡ ḭḭṇ Ṣṗṓṓṭḽḭḭḡḥṭ ḅααṡḛḛḍ ṓṓṇ ḍḛḛϝḭḭṇḛḛḍ ͼṛḭḭṭḛḛṛḭḭαα.龍ผู้]"])},
        "scorecard_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ṡḛḛṭṵṵṗ龍ผู้]"])},
        "scorecard_setup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ ͼααṇ ḅḛḛ ṵṵṡḛḛḍ ṓṓṇ ṡͼṓṓṛḛḛͼααṛḍṡ ḅααṡḛḛḍ ṓṓṇ ḍḛḛϝḭḭṇḛḛḍ ͼṛḭḭṭḛḛṛḭḭαα.龍ผู้]"])},
        "tags": {
          "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḅṓṓṭ龍ผู้]"])},
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼḥααṭ龍ผู้]"])},
          "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṃḭḭṇḡ ṡṓṓṓṓṇ龍ผู้]"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṵṵṡṭṓṓṃ龍ผู้]"])},
          "predefined_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗṛḛḛ-ḍḛḛϝḭḭṇḛḛḍ龍ผู้]"])},
          "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
          "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṡṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])},
          "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṽṓṓḭḭͼḛḛ龍ผู้]"])}
        },
        "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ͼḥααṇḡḛḛṡ龍ผู้]"])},
        "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛṡ ṡααṽḛḛḍ龍ผู้]"])}
      },
      "ignore_list": {
        "modal": {
          "input_tip_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṗ:龍ผู้]"])},
          "snippet_editor_help_first": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ṓṓṇ ṭḥḛḛ ", _interpolate(_named("placeholder")), " ḭḭͼṓṓṇ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ ṇααṃḛḛṡ, ͼṓṓṃṗααṇẏẏ ḍḛḛṭααḭḭḽṡ, ṓṓṛ ααṇẏẏ ṓṓṭḥḛḛṛ ṡḛḛṇṡḭḭṭḭḭṽḛḛ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ ḍṵṵṛḭḭṇḡ ḀḀṵṵṭṓṓǪḀḀ'ṡ ͼḽṓṓṡḭḭṇḡ ͼḥḛḛͼḳṡ.龍ผู้]"])},
          "snippet_editor_help_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛ ḛḛẋααṃṗḽḛḛ, ḭḭṇṡṭḛḛααḍ ṓṓϝ 'Ṫααḳḛḛ ͼααṛḛḛ, Ĵṓṓḥṇ' ẏẏṓṓṵṵ ͼααṇ ṵṵṡḛḛ 'Ṫααḳḛḛ ͼααṛḛḛ, ", _interpolate(_named("placeholder")), "' ṭṓṓ ṛḛḛṗṛḛḛṡḛḛṇṭ ααṇẏẏ ṇααṃḛḛ.龍ผู้]"])},
          "snippet_editor_help_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ṓṓṇ ṭḥḛḛ ", "{··}", " ḭḭͼṓṓṇ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ ṇααṃḛḛṡ, ͼṓṓṃṗααṇẏẏ ḍḛḛṭααḭḭḽṡ, ṓṓṛ ααṇẏẏ ṓṓṭḥḛḛṛ ṡḛḛṇṡḭḭṭḭḭṽḛḛ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ ḍṵṵṛḭḭṇḡ ḀḀṵṵṭṓṓǪḀḀ'ṡ ͼḽṓṓṡḭḭṇḡ ͼḥḛḛͼḳṡ.龍ผู้]"])},
          "closing": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡ龍ผู้]"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡ龍ผู้]"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡ龍ผู้]"])}
          },
          "grammar": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḛḛẋḛḛṃṗṭḭḭṓṓṇ龍ผู้]"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋḛḛṃṗṭḭḭṓṓṇ龍ผู้]"])}
          },
          "greeting": {
            "add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
            "edit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
            "input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])}
          },
          "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṭḛḛ ẁṓṓṛḍṡ ḥḛḛṛḛḛ ṡḛḛṗααṛααṭḛḛḍ ḅẏẏ ḽḭḭṇḛḛ ḅṛḛḛααḳṡ龍ผู้]"])},
          "input_tip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṡṡ ḚḚṇṭḛḛṛ ααϝṭḛḛṛ ḛḛααͼḥ ẁṓṓṛḍ ϝṓṓṛ αα ṇḛḛẁ ḛḛṇṭṛẏẏ龍ผู้]"])},
          "language_picker_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽḭḭͼααḅḽḛḛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
          "snippet_editor_help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛ ḛḛẋααṃṗḽḛḛ, ḭḭṇṡṭḛḛααḍ ṓṓϝ \"Ṫααḳḛḛ ͼααṛḛḛ, Ĵṓṓḥṇ\" ẏẏṓṓṵṵ ͼααṇ ṵṵṡḛḛ \"Ṫααḳḛḛ ͼααṛḛḛ, ", "{··}", "\" ṭṓṓ ṛḛḛṗṛḛḛṡḛḛṇṭ ααṇẏẏ ṇααṃḛḛ.龍ผู้]"])},
          "snippet_editor_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍẏẏṇααṃḭḭͼ ͼṓṓṇṭḛḛṇṭ ṗḽααͼḛḛḥṓṓḽḍḛḛṛ龍ผู้]"])},
          "textarea_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])}
        },
        "grammar": {
          "toast_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḛḛẋḛḛṃṗṭḭḭṓṓṇ ααḍḍḛḛḍ"]), _normalize([_interpolate(_named("count")), " ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ ααḍḍḛḛḍ龍ผู้]"])])},
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḚḚẋḛḛṃṗṭḭḭṓṓṇ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ"]), _normalize(["ḚḚẋḛḛṃṗṭḭḭṓṓṇṡ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ: ", _interpolate(_named("phrases")), "龍ผู้]"])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḛḛẋḛḛṃṗṭḭḭṓṓṇ龍ผู้]"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ ααḍḍḛḛḍ龍ผู้]"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋḛḛṃṗṭḭḭṓṓṇ (ͼααṡḛḛ-ṡḛḛṇṡḭḭṭḭḭṽḛḛ)龍ผู้]"])},
          "save_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀḍḍ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ"]), _normalize(["ḀḀḍḍ 1 ḛḛẋḛḛṃṗṭḭḭṓṓṇ"]), _normalize(["ḀḀḍḍ ", _interpolate(_named("count")), " ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ αα ẁṓṓṛḍ ṓṓṛ ṗḥṛααṡḛḛ龍ผู้]"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋḛḛṃṗṭḭḭṓṓṇ ṡααṽḛḛḍ龍ผู้]"])}
        },
        "language": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḽααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])}
        },
        "closing": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ"]), _normalize(["Ḉḽṓṓṡḭḭṇḡṡ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ: ", _interpolate(_named("phrases")), "龍ผู้]"])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼḽṓṓṡḭḭṇḡ龍ผู้]"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ龍ผู้]"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ龍ผู้]"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼḽṓṓṡḭḭṇḡ龍ผู้]"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ αα ͼḽṓṓṡḭḭṇḡ龍ผู้]"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ ααḍḍḛḛḍ龍ผู้]"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ ṡααṽḛḛḍ龍ผู้]"])}
        },
        "duplicate_lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛṃ ḥααṡ ḍṵṵṗḽḭḭͼααṭḛḛ ḛḛṇṭṛḭḭḛḛṡ: ", _interpolate(_list(0)), "龍ผู้]"])},
        "greeting": {
          "phrase_exists": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ"]), _normalize(["Ḡṛḛḛḛḛṭḭḭṇḡṡ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ: ", _interpolate(_named("phrases")), "龍ผู้]"])])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
          "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])},
          "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
          "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
          "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ αα ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
          "toast_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ ααḍḍḛḛḍ龍ผู้]"])},
          "toast_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ ṡααṽḛḛḍ龍ผู้]"])}
        },
        "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḛḛẋḛḛṃṗṭḭḭṓṓṇ龍ผู้]"])},
        "delete_exemption_confirm": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḛḛẋḛḛṃṗṭḭḭṓṓṇ"]), _normalize(["Ḍḛḛḽḛḛṭḛḛ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])])},
        "delete_exemption_title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṕḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ḛḛẋḛḛṃṗṭḭḭṓṓṇ?"]), _normalize(["Ṕḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛṡḛḛ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ?龍ผู้]"])])},
        "edit_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḛḛẋḛḛṃṗṭḭḭṓṓṇ龍ผู้]"])},
        "long_input": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ṎṎṇḛḛ ṓṓṛ ṃṓṓṛḛḛ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ ḭḭṡ ṓṓṽḛḛṛ ", _interpolate(_list(0)), " ͼḥααṛααͼṭḛḛṛṡ ḽṓṓṇḡ龍ผู้]"])},
        "long_input_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("phraseLength")), "/", _interpolate(_named("maxPhraseLength")), " ͼḥααṛααͼṭḛḛṛṡ龍ผู้]"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛḍ龍ผู้]"])},
        "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛḍ ḅẏẏ龍ผู้]"])}
      },
      "snippets": {
        "dynamic_content": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿααṛḭḭααḅḽḛḛ龍ผู้]"])}
        }
      },
      "tab_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "closing": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝḭḭṇḛḛ ẏẏṓṓṵṵṛ ṓṓẁṇ ṡḭḭḡṇ-ṓṓϝϝṡ ṭḥααṭ ααḽḭḭḡṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ḅṛααṇḍ ααṇḍ ṡḛḛṛṽḭḭͼḛḛ ṡṭααṇḍααṛḍṡ. Ŵḥḛḛṇ ααḍḍḛḛḍ, ḀḀṵṵṭṓṓǪḀḀ ẁḭḭḽḽ ṓṓṇḽẏẏ ḍḛḛṭḛḛͼṭ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ. Ḉḥααṇḡḛḛṡ ṃααḍḛḛ ααṗṗḽẏẏ ṭṓṓ ϝṵṵṭṵṵṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ṭṓṓṗ ḽḭḭṃḭḭṭ ṓṓϝ 100 ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ. ḬḬϝ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ααḍḍ ṃṓṓṛḛḛ, ḭḭṭ ṃḭḭḡḥṭ ḅḛḛ ṭḭḭṃḛḛ ṭṓṓ ṛḛḛṽḭḭṡḭḭṭ ααṇḍ ṛḛḛϝḭḭṇḛḛ ẏẏṓṓṵṵṛ ḛḛẋḭḭṡṭḭḭṇḡ ͼḽṓṓṡḭḭṇḡṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡ ḽḭḭṃḭḭṭ ṛḛḛααͼḥḛḛḍ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ龍ผู้]"])}
      },
      "grammar": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍṡ ααṇḍ ṗḥṛααṡḛḛṡ ẏẏṓṓṵṵ’ḍ ḽḭḭḳḛḛ ḀḀṵṵṭṓṓǪḀḀ ṭṓṓ ḭḭḡṇṓṓṛḛḛ ααṡ ṡṗḛḛḽḽḭḭṇḡ ṓṓṛ ḡṛααṃṃααṛ ṃḭḭṡṭααḳḛḛṡ. Ḉḥααṇḡḛḛṡ ṃααḍḛḛ ααṗṗḽẏẏ ṭṓṓ ϝṵṵṭṵṵṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḡṇṓṓṛḛḛ ḽḭḭṡṭ龍ผู้]"])}
      },
      "greeting": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛͼḭḭϝẏẏ ẏẏṓṓṵṵṛ ṓṓẁṇ ḡṛḛḛḛḛṭḭḭṇḡṡ ṭḥααṭ ααḽḭḭḡṇ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ḅṛααṇḍ ααṇḍ ṡḛḛṛṽḭḭͼḛḛ ṡṭααṇḍααṛḍṡ. Ŵḥḛḛṇ ααḍḍḛḛḍ, ḀḀṵṵṭṓṓǪḀḀ ẁḭḭḽḽ ṓṓṇḽẏẏ ḍḛḛṭḛḛͼṭ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ. Ḉḥααṇḡḛḛṡ ṃααḍḛḛ ααṗṗḽẏẏ ṭṓṓ ϝṵṵṭṵṵṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])},
        "limit_reached_popup": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ṭṓṓṗ ḽḭḭṃḭḭṭ ṓṓϝ 100 ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ. ḬḬϝ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ααḍḍ ṃṓṓṛḛḛ, ḭḭṭ ṃḭḭḡḥṭ ḅḛḛ ṭḭḭṃḛḛ ṭṓṓ ṛḛḛṽḭḭṡḭḭṭ ααṇḍ ṛḛḛϝḭḭṇḛḛ ẏẏṓṓṵṵṛ ḛḛẋḭḭṡṭḭḭṇḡ ḡṛḛḛḛḛṭḭḭṇḡṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡ ḽḭḭṃḭḭṭ ṛḛḛααͼḥḛḛḍ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])}
      },
      "delete_category_template": {
        "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṇṓṓẁ龍ผู้]"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ?龍ผู้]"])}
      },
      "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḽḭḭṡṭ龍ผู้]"])}
    },
    "category": {
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛḍ ͼααṭḛḛḡṓṓṛẏẏ ẁḭḭḽḽ ṇṓṓṭ ααṗṗḛḛααṛ ṓṓṇ ααṇẏẏ ṇḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ϝḭḭḽṭḛḛṛṡ. Ḥḭḭṡṭṓṓṛḭḭͼααḽ ḍααṭαα ṓṓṇ ḍααṡḥḅṓṓααṛḍṡ ααṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṃααḭḭṇ ḭḭṇṭααͼṭ.龍ผู้]"])},
        "description_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ααḽḽ ṡͼṓṓṛḛḛͼααṛḍṡ ḭḭṭ ḥααṡ ḅḛḛḛḛṇ ααḍḍḛḛḍ ṭṓṓ.龍ผู้]"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṡͼṓṓṛḛḛͼααṛḍ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ͼααṭḛḛḡṓṓṛẏẏ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡͼṓṓṛḛḛͼααṛḍ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
      }
    },
    "company": {
      "consents": {
        "details": {
          "cancel_open_ai": {
            "categories": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("highlight")), " ṗṓṓẁḛḛṛḛḛḍ ḅẏẏ ṭḥḛḛ ḀḀḬḬ ṃṓṓḍḛḛḽ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ṓṓṇ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ḅṵṵṭ ẁḭḭḽḽ ṇṓṓ ḽṓṓṇḡḛḛṛ ḅḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛḍ龍ผู้]"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṵṵṛṇḭḭṇḡ ḺḺṀ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ṓṓϝϝ ḍṓṓḛḛṡṇ'ṭ ṭṵṵṛṇ ṓṓϝϝ ṭḥḛḛ ḀḀṵṵṭṓṓǪḀḀ ϝḛḛααṭṵṵṛḛḛ ͼṓṓṃṗḽḛḛṭḛḛḽẏẏ. Ḥḛḛṛḛḛ'ṡ ẁḥααṭ ẁḭḭḽḽ ḥααṗṗḛḛṇ:龍ผู้]"])},
            "historical_data": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭṡṭṓṓṛḭḭͼααḽ ḍααṭαα龍ผู้]"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("highlight")), " ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ṓṓṇ ṭḥḛḛ ḀḀṵṵṭṓṓǪḀḀ ḍααṡḥḅṓṓααṛḍ龍ผู้]"])}
            },
            "root_causes": {
              "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽḽẏẏ ααḍḍḛḛḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
              "main": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("highlight")), " ẁḭḭḽḽ ṡṭααẏẏ, ḅṵṵṭ ααḽḽ ḺḺṀ-ḅααṡḛḛḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṵṵṛṇ ṓṓϝϝ ḺḺṀ-ḅααṡḛḛḍ ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
            "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṵṵṛṇ ṓṓϝϝ龍ผู้]"])}
          },
          "open_ai": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ṵṵṡḛḛṡ ḀḀḬḬ ααṇḍ ḽααṛḡḛḛ ḽααṇḡṵṵααḡḛḛ ṃṓṓḍḛḛḽṡ (ḺḺṀṡ) ṭṓṓ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛ ṡṗḛḛͼḭḭϝḭḭͼ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ. ŶŶṓṓṵṵṛ ḍααṭαα ḭḭṡ ṗṛṓṓͼḛḛṡṡḛḛḍ ṵṵṇḍḛḛṛ αα ṡṭṛḭḭͼṭ Ḍααṭαα Ṕṛṓṓͼḛḛṡṡḭḭṇḡ ḀḀḡṛḛḛḛḛṃḛḛṇṭ (ḌṔḀḀ) ααṇḍ ḭḭṡ ṇḛḛṽḛḛṛ ṵṵṡḛḛḍ ṭṓṓ ṭṛααḭḭṇ ααṇẏẏ ṃṓṓḍḛḛḽṡ.龍ผู้]"])},
            "support_link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ḡḛḛṇḛḛṛααṭḭḭṽḛḛ ḀḀḬḬ龍ผู้]"])}
          },
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ṵṵṡḛḛṡ Ṁḭḭͼṛṓṓṡṓṓϝṭ ḀḀẓṵṵṛḛḛ ṎṎṗḛḛṇḀḀḬḬ Ṣḛḛṛṽḭḭͼḛḛ ϝṓṓṛ ", _interpolate(_named("automatically_scoring_categories")), ". ŶŶṓṓṵṵṛ ḍααṭαα ḭḭṡ ṗṛṓṓͼḛḛṡṡḛḛḍ ṵṵṇḍḛḛṛ αα ṡṭṛḭḭͼṭ ḌṔḀḀ ṓṓṇḽẏẏ ṭṓṓ ṗṛṓṓṽḭḭḍḛḛ ḀḀṵṵṭṓṓǪḀḀ ṭṓṓ ẏẏṓṓṵṵ ααṇḍ ", _interpolate(_named("never_used_for_training_models")), ".龍ผู้]"])},
          "automatically_scoring_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḭḭṇḡ ṡṓṓṃḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "never_used_for_training_models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇḛḛṽḛḛṛ ṵṵṡḛḛḍ ṭṓṓ ṭṛααḭḭṇ ααṇẏẏ ṎṎṗḛḛṇḀḀḬḬ ṃṓṓḍḛḛḽṡ龍ผู้]"])}
        },
        "open_ai": {
          "toast_success": {
            "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḺḺṀ-ḅααṡḛḛḍ ḀḀṵṵṭṓṓǪḀḀ ṭṵṵṛṇḛḛḍ ṓṓṇ龍ผู้]"])},
            "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḺḺṀ-ḅααṡḛḛḍ ḀḀṵṵṭṓṓǪḀḀ ṭṵṵṛṇḛḛḍ ṓṓϝϝ龍ผู้]"])}
          }
        },
        "open_ai_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḺḺṀ-ḅααṡḛḛḍ ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
        "open_ai_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḛḛḍ ṡͼṓṓṛḭḭṇḡ ẁḭḭṭḥ ḀḀḬḬ ḭḭṇ ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭαα ṗṛṓṓͼḛḛṡṡḭḭṇḡ ṓṓϝ ṀṢ ḀḀẓṵṵṛḛḛ ḅααṡḛḛḍ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
        "toast_success": {
          "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭͼṛṓṓṡṓṓϝṭ ḀḀẓṵṵṛḛḛ ͼṓṓṇṡḛḛṇṭ ẁḭḭṭḥḍṛααẁṇ龍ผู้]"])},
          "given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭͼṛṓṓṡṓṓϝṭ ḀḀẓṵṵṛḛḛ ͼṓṓṇṡḛḛṇṭ ḡḭḭṽḛḛṇ. Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭḽḽ ṛḛḛͼḛḛḭḭṽḛḛ ααṵṵṭṓṓṃααṭḭḭͼ ṛααṭḭḭṇḡṡ ṡṓṓṓṓṇ.龍ผู้]"])}
        },
        "withdraw_dialog": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṡṭḭḭḽḽ ṵṵṡḛḛ ḀḀṵṵṭṓṓǪḀḀ, ḅṵṵṭ ḥḛḛṛḛḛ'ṡ ẁḥααṭ ẁḭḭḽḽ ḥααṗṗḛḛṇ龍ผู้]"])},
          "footer": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααḅḽḭḭṇḡ ṀṢ ḀḀẓṵṵṛḛḛ ṎṎṗḛḛṇḀḀḬḬ ṡḛḛṛṽḭḭͼḛḛ ẁḭḭḽḽ ṇṓṓṭ ṭṵṵṛṇ ṓṓϝϝ ḀḀṵṵṭṓṓǪḀḀ ααṡ αα ϝṵṵṇͼṭḭḭṓṓṇααḽḭḭṭẏẏ.龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ḍṓṓ ṭḥααṭ ḭḭṇ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ͼααṭḛḛḡṓṓṛẏẏ ṽḭḭḛḛẁ.龍ผู้]"])}
          },
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṗṓṓẁḛḛṛḛḛḍ ḅẏẏ ṭḥḛḛ ṃṓṓḍḛḛḽ ẁḭḭḽḽ ṡṭααẏẏ ṓṓṇ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ḅṵṵṭ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡͼṓṓṛḛḛḍ.龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ẏẏṓṓṵṵ ḥααṽḛḛ ααḍḍḛḛḍ ṃααṇṵṵααḽḽẏẏ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ. ḀḀḽḽ ṃṓṓḍḛḛḽ-ḡḛḛṇḛḛṛααṭḛḛḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ.龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭṡṭṓṓṛḭḭͼααḽ ḍααṭαα ṡṭααẏẏṡ ṓṓṇ ṭḥḛḛ ḀḀṵṵṭṓṓǪḀḀ ḍααṡḥḅṓṓααṛḍ.龍ผู้]"])}
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααḅḽḛḛ ḍααṭαα ṗṛṓṓͼḛḛṡṡḭḭṇḡ ḅẏẏ ṀṢ ḀḀẓṵṵṛḛḛ ṎṎṗḛḛṇḀḀḬḬ ṡḛḛṛṽḭḭͼḛḛ龍ผู้]"])}
        },
        "give_consent_dialog": {
          "message": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḭḭḍḛḛṛ ṛααṇḡḛḛ ṓṓϝ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṵṵṭ-ṓṓϝ-ṭḥḛḛ-ḅṓṓẋ ṃṵṵḽṭḭḭḽḭḭṇḡṵṵααḽ ṡṵṵṗṗṓṓṛṭ.龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααṡṭ ṗααͼḛḛḍ ααḍḍḭḭṭḭḭṓṓṇ ṓṓϝ ṇḛḛẁ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṃṗṛṓṓṽḛḛḍ ḛḛṽααḽṵṵααṭḭḭṓṓṇ ααͼͼṵṵṛααͼẏẏ. ḠṔṪ-3.5 ṡḥṓṓẁṡ ḥṵṵṃααṇ-ḽḛḛṽḛḛḽ ͼṓṓṇṭḛḛẋṭṵṵααḽ ααẁααṛḛḛṇḛḛṡṡ.龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḛḛṇḛḛϝḭḭṭ ϝṛṓṓṃ ṭḥḛḛ ḽααṭḛḛṡṭ ααḍṽααṇͼḛḛṃḛḛṇṭṡ ḭḭṇ ḀḀḬḬ ṭḛḛͼḥṇṓṓḽṓṓḡẏẏ.龍ผู้]"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛṛḛḛ'ṡ ẁḥααṭ ẏẏṓṓṵṵ'ḽḽ ḡḛḛṭ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇααḅḽḛḛ ḍααṭαα ṗṛṓṓͼḛḛṡṡḭḭṇḡ ḅẏẏ ṀṢ ḀḀẓṵṵṛḛḛ ṎṎṗḛḛṇḀḀḬḬ ṡḛḛṛṽḭḭͼḛḛ龍ผู้]"])}
        },
        "ms_azure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭͼṛṓṓṡṓṓϝṭ ḀḀẓṵṵṛḛḛ龍ผู้]"])},
        "status_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṡḛḛṇṭ ḡḭḭṽḛḛṇ龍ผู้]"])},
        "status_not_given": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṡḛḛṇṭ ḡḭḭṽḛḛṇ龍ผู้]"])}
      },
      "default_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṇḛḛẁ ṵṵṡḛḛṛṡ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "default_workspace_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝααṵṵḽṭ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "time_format_12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍12-ḥṓṓṵṵṛ ṭḭḭṃḛḛ (", _interpolate(_named("time")), ")龍ผู้]"])},
      "time_format_24": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍24-ḥṓṓṵṵṛ ṭḭḭṃḛḛ (", _interpolate(_named("time")), ")龍ผู้]"])},
      "time_format_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛ ϝṓṓṛṃααṭ龍ผู้]"])},
      "week_start_monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇ龍ผู้]"])},
      "week_start_sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṇ龍ผู้]"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗααṇẏẏ ṗṛṓṓϝḭḭḽḛḛ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "week_start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ ṓṓϝ ṭḥḛḛ ẁḛḛḛḛḳ龍ผู้]"])}
    },
    "connections": {
      "add_custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
      "amazon_connect": {
        "access_key_id": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṡṡ ḳḛḛẏẏ ḬḬḌ ααṇḍ ṡḛḛͼṛḛḛṭ ḳḛḛẏẏ ααṛḛḛ ṭẏẏṗḭḭͼααḽḽẏẏ ϝṓṓṵṵṇḍ ṵṵṇḍḛḛṛ ḀḀͼͼḛḛṡṡ ṃααṇααḡḛḛṃḛḛṇṭ > ṲṲṡḛḛṛṡ龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀŴṢ ααͼͼḛḛṡṡ ḳḛḛẏẏ ḬḬḌ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ḀḀḰḬḬḀḀḬḬṎṎṢḞṎṎḌṄṄ7ḚḚẊḀḀṀṔḺḚḚ龍ผู้]"])}
        },
        "add_checkbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḀḀṃααẓṓṓṇ Ḉṓṓṇṇḛḛͼṭ龍ผู้]"])}
        },
        "cancel_changing_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ ͼḥααṇḡḭḭṇḡ ṡḛḛͼṛḛḛṭ ααͼͼḛḛṡṡ ḳḛḛẏẏ龍ผู้]"])},
        "change_key": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ṡḛḛͼṛḛḛṭ ααͼͼḛḛṡṡ ḳḛḛẏẏ ṽααḽṵṵḛḛ龍ผู้]"])}
        },
        "region": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ααḽḽṓṓẁṡ ṭṓṓ ḍḛḛϝḭḭṇḛḛ ṓṓṇḽẏẏ ṓṓṇḛḛ ṛḛḛḡḭḭṓṓṇ ẁḥḭḭͼḥ ḭḭṡ ṭẏẏṗḭḭͼααḽḽẏẏ ϝṓṓṵṵṇḍ ṵṵṇḍḛḛṛ Ḻṓṓͼααṭḭḭṓṓṇ龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀŴṢ ṛḛḛḡḭḭṓṓṇ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ḛḛṵṵ-ͼḛḛṇṭṛααḽ-1龍ผู้]"])}
        },
        "secret_access_key": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṡḛḛͼṛḛḛṭ ḳḛḛẏẏ龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀŴṢ ṡḛḛͼṛḛḛṭ ααͼͼḛḛṡṡ ḳḛḛẏẏ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ẁĴααḽṛẊṲṲṭṇḞḚḚṀḬḬ/Ḱ7ṀḌḚḚṄḠ/ḅṔẋṚϝḭḭḈŶŶḚḚẊḀḀṀṔḺḚḚḰḚḚŶŶ龍ผู้]"])}
        },
        "section_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ϝḭḭṇḍ ααḽḽ ṛḛḛḽḛḛṽααṇṭ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ ḭḭṇ ḀḀṃααẓṓṓṇ Ḉṓṓṇṇḛḛͼṭ.龍ผู้]"])},
        "storage_location": {
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḭḭͼααḽḽẏẏ ϝṓṓṵṵṇḍ ṵṵṇḍḛḛṛ ḀḀṃααẓṓṓṇ Ḉṓṓṇṇḛḛͼṭ ḭḭṇṡṭααṇͼḛḛ ṡḛḛṭṭḭḭṇḡṡ ααṇḍ Ḍααṭαα ṡṭṓṓṛααḡḛḛ ṗααḡḛḛ龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭṓṓṛααḡḛḛ ḽṓṓͼααṭḭḭṓṓṇ ṓṓϝ ͼααḽḽ ṛḛḛͼṓṓṛḍḭḭṇḡṡ龍ผู้]"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. ααṃααẓṓṓṇ-ͼṓṓṇṇḛḛͼṭ-123456/ͼṓṓṇṇḛḛͼṭ/ḭḭṇṡṭααṇͼḛḛ-ṇααṃḛḛ/ḈααḽḽṚḛḛͼṓṓṛḍḭḭṇḡṡ龍ผู้]"])}
        },
        "validation_error": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṛḛḛ'ṡ ḅḛḛḛḛṇ ααṇ ḛḛṛṛṓṓṛ ẁḥḛḛṇ ααḍḍḭḭṇḡ ḀḀṃααẓṓṓṇ Ḉṓṓṇṇḛḛͼṭ. Ḉḥḛḛͼḳ ḭḭϝ ααḽḽ ṭḥḛḛ ϝḭḭḛḛḽḍṡ ααṛḛḛ ϝḭḭḽḽḛḛḍ ͼṓṓṛṛḛḛͼṭḽẏẏ ααṇḍ ṭṛẏẏ ααḡααḭḭṇ.龍ผู้]"])},
          "location_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭṓṓṛααḡḛḛ ḽṓṓͼααṭḭḭṓṓṇ ṡḥṓṓṵṵḽḍ ḅḛḛ ḭḭṇ ṭḥḛḛ ϝṓṓṛṃααṭ ṓṓϝ ḅṵṵͼḳḛḛṭ/ϝṓṓḽḍḛḛṛ ṡḛḛṗααṛααṭḛḛḍ ḅẏẏ αα ṡḽααṡḥ.龍ผู้]"])}
        }
      },
      "contact_to_add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ααḍḍ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ, ͼṓṓṇṭααͼṭ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "contact_to_add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ Ṣṵṵṗṗṓṓṛṭ龍ผู้]"])},
      "first_sync_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ṭḭḭͼḳḛḛṭṡ ẁḭḭḽḽ ḅḛḛ ṡẏẏṇͼḛḛḍ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ. ŶŶṓṓṵṵ ͼααṇ ͼḥṓṓṓṓṡḛḛ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṭḭḭͼḳḛḛṭṡ.龍ผู้]"])},
      "genesys_region": {
        "ap-northeast-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḭḭαα Ṕααͼḭḭϝḭḭͼ (Ṫṓṓḳẏẏṓṓ)龍ผู้]"])},
        "ap-northeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḭḭαα Ṕααͼḭḭϝḭḭͼ (Ṣḛḛṓṓṵṵḽ)龍ผู้]"])},
        "ap-northeast-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḭḭαα Ṕααͼḭḭϝḭḭͼ (ṎṎṡααḳαα)龍ผู้]"])},
        "ap-south-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḭḭαα Ṕααͼḭḭϝḭḭͼ (Ṁṵṵṃḅααḭḭ)龍ผู้]"])},
        "ap-southeast-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḭḭαα Ṕααͼḭḭϝḭḭͼ (Ṣẏẏḍṇḛḛẏẏ)龍ผู้]"])},
        "ca-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇααḍαα (Ḉḛḛṇṭṛααḽ)龍ผู้]"])},
        "eu-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṵṵṛṓṓṗḛḛ (Ḟṛααṇḳϝṵṵṛṭ)龍ผู้]"])},
        "eu-central-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṵṵṛṓṓṗḛḛ (Żṵṵṛḭḭͼḥ)龍ผู้]"])},
        "eu-west-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṵṵṛṓṓṗḛḛ (ḬḬṛḛḛḽααṇḍ)龍ผู้]"])},
        "eu-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṵṵṛṓṓṗḛḛ (Ḻṓṓṇḍṓṓṇ)龍ผู้]"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṛṽḛḛṛ ṛḛḛḡḭḭṓṓṇ龍ผู้]"])},
        "me-central-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭḍḍḽḛḛ ḚḚααṡṭ (ṲṲḀḀḚḚ)龍ผู้]"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṡḛḛṛṽḛḛṛ ṛḛḛḡḭḭṓṓṇ龍ผู้]"])},
        "sa-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṭḥ ḀḀṃḛḛṛḭḭͼαα (Ṣãṓṓ Ṕααṵṵḽṓṓ)龍ผู้]"])},
        "us-east-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṢ ḚḚααṡṭ (Ṿḭḭṛḡḭḭṇḭḭαα)龍ผู้]"])},
        "us-east-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṢ ḚḚααṡṭ 2 (ṎṎḥḭḭṓṓ)龍ผู้]"])},
        "us-west-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṢ Ŵḛḛṡṭ (ṎṎṛḛḛḡṓṓṇ)龍ผู้]"])}
      },
      "integration_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ααḽṛḛḛααḍẏẏ ααḍḍḛḛḍ龍ผู้]"])},
      "start_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "sync_only_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṡẏẏṇͼ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ龍ผู้]"])},
      "channel_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼḥααṇṇḛḛḽ ṓṓṇḛḛ, ͼḥααṇṇḛḛḽ ṭẁṓṓ龍ผู้]"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "connection_unique_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ'ṡ ṵṵṇḭḭʠṵṵḛḛ ḬḬḌ ḭḭṡ ", _interpolate(_named("tokenId")), "龍ผู้]"])},
      "data_retention_period": {
        "months_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍1 ṃṓṓṇṭḥ龍ผู้]"])},
        "months_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍1 ẏẏḛḛααṛ龍ผู้]"])},
        "months_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍3 ṃṓṓṇṭḥṡ龍ผู้]"])},
        "months_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍6 ṃṓṓṇṭḥṡ龍ผู้]"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇααͼṭḭḭṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭḽḽ ḅḛḛ ḍḛḛḽḛḛṭḛḛḍ ααϝṭḛḛṛ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ṛḛḛṭḛḛṇṭḭḭṓṓṇ ṗḛḛṛḭḭṓṓḍ. Ṣṵṵḅṃḭḭṭṭḛḛḍ ṛḛḛṽḭḭḛḛẁṡ ṛḛḛṃααḭḭṇ ṽḭḭṡḭḭḅḽḛḛ ḭḭṇ ḀḀͼṭḭḭṽḭḭṭẏẏ ααṇḍ Ḍααṡḥḅṓṓααṛḍ ṽḭḭḛḛẁṡ.龍ผู้]"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṭḛḛṇṭḭḭṓṓṇ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛṭḛḛṇṭḭḭṓṓṇ ṗḛḛṛḭḭṓṓḍ龍ผู้]"])}
      },
      "deletion_confirmation": {
        "action_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛ ṭḥααṭ ṭḥḭḭṡ ααͼṭḭḭṓṓṇ ḭḭṡ ḭḭṛṛḛḛṽḛḛṛṡḭḭḅḽḛḛ.龍ผู้]"])},
        "removed_data_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ṵṵṇḍḛḛṛṡṭααṇḍ ṭḥααṭ ṭḥḭḭṡ ẁḭḭḽḽ ṛḛḛṃṓṓṽḛḛ ααḽḽ ḥḛḛḽṗ ḍḛḛṡḳ-ṛḛḛḽααṭḛḛḍ ḍααṭαα ϝṓṓṛ ṭḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ḭḭṇͼḽṵṵḍḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ, ϝḭḭḛḛḽḍṡ, ααṇḍ ṭααḡṡ.龍ผู้]"])},
        "reviews_not_affected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭṭḛḛḍ ṛḛḛṽḭḭḛḛẁṡ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ααϝϝḛḛͼṭḛḛḍ ααṇḍ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ṽḭḭṡḭḭḅḽḛḛ ḭḭṇ ṡṗḛḛͼḭḭϝḭḭͼ ṽḭḭḛḛẁṡ.龍ผู้]"])}
      },
      "group_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḡṛṓṓṵṵṗ ṓṓṇḛḛ, ḡṛṓṓṵṵṗ ṭẁṓṓ龍ผู้]"])},
      "include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇͼḽṵṵḍḛḛ龍ผู้]"])},
      "integration_in_beta_warning": {
        "active_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααͼṭḭḭṽḛḛ ḍḛḛṽḛḛḽṓṓṗṃḛḛṇṭ龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ḭḭṡ ṡṭḭḭḽḽ ḭḭṇ ", _interpolate(_named("activeDevelopment")), ". Ṣṓṓṃḛḛ ḍḛḛṭααḭḭḽṡ ṃḭḭḡḥṭ ḅḛḛ ṃḭḭṡṡḭḭṇḡ (ḽḭḭḳḛḛ ααṭṭααͼḥṃḛḛṇṭṡ ṓṓṛ ͼṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ)龍ผู้]"])}
      },
      "mailbox_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃααḭḭḽḅṓṓẋ ṓṓṇḛḛ, ṃααḭḭḽḅṓṓẋ ṭẁṓṓ龍ผู้]"])},
      "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭααḡ ṓṓṇḛḛ, ṭααḡ ṭẁṓṓ龍ผู้]"])},
      "api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ṭṓṓḳḛḛṇ龍ผู้]"])},
      "api_token_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ṭṓṓḳḛḛṇ ṡḛḛͼṛḛḛṭ龍ผู้]"])},
      "blacklist_instructions": {
        "freshdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ \"Ṫḭḭͼḳḛḛṭ Ḟḭḭḛḛḽḍ Ṅααṃḛḛṡ\" ṓṓṇ Ḟṛḛḛṡḥḍḛḛṡḳ ṵṵṇḍḛḛṛ Ṣḛḛṭṭḭḭṇḡṡ → Ṫḭḭͼḳḛḛṭ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "help_scout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ \"Ḉṵṵṡṭṓṓṃ Ḟḭḭḛḛḽḍ Ṅααṃḛḛṡ\" ṓṓṇ Ḥḛḛḽṗ Ṣͼṓṓṵṵṭ ṵṵṇḍḛḛṛ Ṣḛḛṭṭḭḭṇḡṡ → Ḉṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "kustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ \"Ḱḽααṡṡ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ Ṅααṃḛḛṡ\" ṓṓṇ Ḱṵṵṡṭṓṓṃḛḛṛ ṵṵṇḍḛḛṛ Ṣḛḛṭṭḭḭṇḡṡ → Ḱḽααṡṡḛḛṡ → Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "live_agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ \"Ḉṵṵṡṭṓṓṃ Ḟḭḭḛḛḽḍ Ṅααṃḛḛ\" ṓṓṇ Ḻḭḭṽḛḛ ḀḀḡḛḛṇṭ ṵṵṇḍḛḛṛ Ṣḛḛṭṭḭḭṇḡṡ → Ḉṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "wix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ \"Ḉṵṵṡṭṓṓṃ Ḟḭḭḛḛḽḍ Ṅααṃḛḛ\" ṓṓṇ Ŵḭḭẋ ṵṵṇḍḛḛṛ Ṣḛḛṭṭḭḭṇḡṡ → Ḉṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ龍ผู้]"])},
        "zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṗααṛααṭḛḛ Ḟḭḭḛḛḽḍ ḬḬḌṡ ẁḭḭṭḥ ͼṓṓṃṃααṡ. ŶŶṓṓṵṵ ͼααṇ ϝḭḭṇḍ ṭḥḛḛṡḛḛ ḬḬḌṡ ḭḭṇ Żḛḛṇḍḛḛṡḳ ṵṵṇḍḛḛṛ ḀḀḍṃḭḭṇ → Ṫḭḭͼḳḛḛṭ ϝḭḭḛḛḽḍṡ.龍ผู้]"])}
      },
      "connection_added": {
        "next_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ αα ϝḛḛẁ ṓṓϝ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ṵṵṡḛḛṛṡ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ẏẏṓṓṵṵṛ ϝḭḭṛṡṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. Ḍṓṓṇ'ṭ ẁṓṓṛṛẏẏ, ẁḛḛ ẁṓṓṇ'ṭ ṇṓṓṭḭḭϝẏẏ ṭḥḛḛṃ ṵṵṇḽḛḛṡṡ ẏẏṓṓṵṵ ḭḭṇṽḭḭṭḛḛ ṭḥḛḛṃ ṭṓṓ ḅḛḛͼṓṓṃḛḛ ẁṓṓṛḳṡṗααͼḛḛ ṃḛḛṃḅḛḛṛṡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵͼͼḛḛṡṡϝṵṵḽḽẏẏ ḭḭṇṭḛḛḡṛααṭḛḛḍ龍ผู้]"])},
        "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛ ẁḭḭḽḽ ṇṓṓẁ ṡṭααṛṭ ṗṵṵḽḽḭḭṇḡ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ.龍ผู้]"])}
      },
      "connection_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍṓṓṃααḭḭṇ: ", _interpolate(_list(0)), "龍ผู้]"])},
      "connection_helpdesk_identifier": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ḭḭḍḛḛṇṭḭḭϝḭḭḛḛṛ: ", _interpolate(_list(0)), "龍ผู้]"])},
      "could_not_authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ααṵṵṭḥḛḛṇṭḭḭͼααṭḛḛ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡḛḛṭ. Ṫḥḭḭṡ ḭḭṡ ẏẏṓṓṵṵṛ ṇḛḛẁ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ.龍ผู้]"])},
      "failed_jobs_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ḥααṽḛḛ ṇṓṓṭ ḅḛḛḛḛṇ ṵṵṗḍααṭḛḛḍ ṓṓṛ ḥααṽḛḛ ḅḛḛͼṓṓṃḛḛ ḭḭṇααͼṭḭḭṽḛḛ. Ṫṛẏẏ ṭṓṓ ṵṵṗḍααṭḛḛ ṭḥḛḛṡḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ṓṓṛ ṛḛḛṃṓṓṽḛḛ ṭḥḛḛṃ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḽḭḭṡṭ.龍ผู้]"])},
      "fields": {
        "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ͼḽḭḭḛḛṇṭ ḬḬḌ龍ผู้]"])},
        "client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ͼḽḭḭḛḛṇṭ ṡḛḛͼṛḛḛṭ龍ผู้]"])},
        "zd_chat_instruction_with_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ḀḀṔḬḬ ṭṓṓḳḛḛṇ ϝṛṓṓṃ Ṣḛḛṭṭḭḭṇḡṡ → ḀḀͼͼṓṓṵṵṇṭ → ḀḀṔḬḬ & ṢḌḰṡ. Ṫḥḛḛ Ṛḛḛḍḭḭṛḛḛͼṭ ṲṲṚḺ ṭṓṓ ṵṵṡḛḛ ḭḭṡ ", _interpolate(_named("redirectUrl")), "龍ผู้]"])},
        "account_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ḬḬḌ龍ผู้]"])}
      },
      "integration_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ ṭṓṓ ḡḛḛṭ ṡṭααṛṭḛḛḍ龍ผู้]"])},
      "intercom_region_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵṛ ḬḬṇṭḛḛṛͼṓṓṃ ααͼͼṓṓṵṵṇṭ ḭḭṡ ṡḛḛṭ ṵṵṗ ṭṓṓ ḅḛḛ ṡḛḛṛṽḛḛḍ ϝṛṓṓṃ ṭḥḛḛ ḚḚṲṲ ṓṓṛ ḀḀṵṵṡṭṛααḽḭḭαα, ṭḥḛḛṇ ṵṵṗḍααṭḛḛ ṭḥḭḭṡ.龍ผู้]"])},
      "login_email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ẏẏṓṓṵṵ ṵṵṡḛḛ ṭṓṓ ṡḭḭḡṇ ḭḭṇṭṓṓ ", _interpolate(_list(0)), "龍ผู้]"])},
      "mask_data_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṡḳ ṓṓṵṵṭ ḛḛṇḍ-ṵṵṡḛḛṛ'ṡ ṇααṃḛḛ, ḛḛṃααḭḭḽ, ααṇḍ ṗḥṓṓṇḛḛ ṇṵṵṃḅḛḛṛ. Ḃααṇḳ ͼṛḛḛḍḛḛṇṭḭḭααḽṡ ααṛḛḛ ααḽṛḛḛααḍẏẏ ṃααṡḳḛḛḍ ϝṓṓṛ ααḽḽ ḥḛḛḽṗ ḍḛḛṡḳ-ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ.龍ผู้]"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇṡ ẏẏḛḛṭ. ḀḀḍḍ ṓṓṇḛḛ ṇṓṓẁ.龍ผู้]"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ṇḛḛḛḛḍ ṭḥḛḛṡḛḛ ṛṓṓḽḛḛṡ:龍ผู้]"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṇḛḛẁ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "add_to_workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇͼḥḛḛͼḳḭḭṇḡ ṭḥḭḭṡ ααḽḽṓṓẁṡ ẏẏṓṓṵṵ ṭṓṓ ṃααṇṵṵααḽḽẏẏ ααḍḍ ṭḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ṭṓṓ ṓṓṇḽẏẏ ṡṗḛḛͼḭḭϝḭḭͼ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "add_to_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ṭṓṓ ααḽḽ ḛḛẋḭḭṡṭḭḭṇḡ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ḳḛḛẏẏ龍ผู้]"])},
      "api_key_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṔḬḬ ḳḛḛẏẏ ṡḛḛͼṛḛḛṭ龍ผู้]"])},
      "channel_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽṡ龍ผู้]"])},
      "connection_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ", _interpolate(_list(0)), " ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "connection_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ṇααṃḛḛ龍ผู้]"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ααṭ龍ผู้]"])},
      "created_cordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ḡṛααḅ ẏẏṓṓṵṵṛ ḀḀṔḬḬ ṭṓṓḳḛḛṇ ḍṓṓẁṇ ḅḛḛḽṓṓẁ.龍ผู้]"])},
      "csat_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṭḥṛḛḛṡḥṓṓḽḍ龍ผู้]"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ", _interpolate(_named("connectionName")), "龍ผู้]"])},
      "deletion_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ \"ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ\" ṭṓṓ ṛḛḛṃṓṓṽḛḛ ṭḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "deletion_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ααṇḍ ṃṓṓṇḭḭṭṓṓṛ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḛḛḍ ḍααṭαα ṡṓṓṵṵṛͼḛḛṡ.龍ผู้]"])},
      "edit_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ龍ผู้]"])},
      "excluded_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṗααṛααṭḛḛ ϝḭḭḛḛḽḍ ṇααṃḛḛṡ ẁḭḭṭḥ ͼṓṓṃṃααṡ龍ผู้]"])},
      "failed_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ϝααḭḭḽḛḛḍ龍ผู้]"])},
      "give_unique_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ αα ṵṵṇḭḭʠṵṵḛḛ ṇααṃḛḛ ṭṓṓ ẏẏṓṓṵṵṛ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
      "group_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "hide_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ααḍṽααṇͼḛḛḍ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
      "hide_sensitive_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ṡḛḛṇṡḭḭṭḭḭṽḛḛ ḍααṭαα ϝḭḭḛḛḽḍṡ龍ผู้]"])},
      "ignore_attachment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṽḛḛṛ ṡṭṓṓṛḛḛ ααṭṭααͼḥṃḛḛṇṭ ṲṲṚḺṡ龍ผู้]"])},
      "ignore_attachment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḡṇṓṓṛḛḛ ααṭṭααͼḥṃḛḛṇṭṡ龍ผู้]"])},
      "ignore_content_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṽḛḛṛ ṡṭṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ (ẏẏṓṓṵṵ ͼααṇ ḽααṭḛḛṛ ṗṵṵḽḽ ḭḭṭ ḭḭṇ ṓṓṇ ḍḛḛṃααṇḍ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ)龍ผู้]"])},
      "ignore_content_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḡṇṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭ龍ผู้]"])},
      "ignore_subject_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṽḛḛṛ ṡṭṓṓṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡṵṵḅĵḛḛͼṭ (ẏẏṓṓṵṵ ͼααṇ ḽααṭḛḛṛ ṗṵṵḽḽ ḭḭṭ ḭḭṇ ṓṓṇ ḍḛḛṃααṇḍ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ)龍ผู้]"])},
      "ignore_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḡṇṓṓṛḛḛ ṡṵṵḅĵḛḛͼṭ ḽḭḭṇḛḛ龍ผู้]"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇααͼṭḭḭṽḛḛ龍ผู้]"])},
      "integration_subdomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ", _interpolate(_list(0)), " ṡṵṵḅḍṓṓṃααḭḭṇ龍ผู้]"])},
      "intercom_region_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛṛͼṓṓṃ ṛḛḛḡḭḭṓṓṇ龍ผู้]"])},
      "intercom_team_id_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṭͼḥ ḭḭṇḅṓṓẋ ṭḛḛααṃ ḬḬḌ龍ผู้]"])},
      "intercom_team_id_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḛḛṛḭḭͼ ḬḬḌ ṓṓϝ ẏẏṓṓṵṵṛ ḬḬṇṭḛḛṛͼṓṓṃ ṭḛḛααṃ龍ผู้]"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇ ṗṛṓṓḡṛḛḛṡṡ...龍ผู้]"])},
      "mailbox_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḭḭḽḅṓṓẋḛḛṡ龍ผู้]"])},
      "manual_connection_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽḽẏẏ ṵṵṗḍααṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "mask_data_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṡḳ ͼṵṵṡṭṓṓṃḛḛṛ ḍααṭαα龍ผู้]"])},
      "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṡḭḭṭḭḭṽḛḛ ϝḭḭḛḛḽḍ ṇααṃḛḛ龍ผู้]"])},
      "placeholder_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṡḭḭṭḭḭṽḛḛ ϝḭḭḛḛḽḍ ḬḬḌ龍ผู้]"])},
      "renew_connection": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṇḛḛẁ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])}
      },
      "renew_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṇḛḛẁ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "salesforce_sandbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ṭṓṓ Ṣααḽḛḛṡϝṓṓṛͼḛḛ ṡααṇḍḅṓṓẋ龍ผู้]"])},
      "setting_up_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡ ṵṵṗ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ẁḭḭṭḥ ", _interpolate(_list(0)), "龍ผู้]"])},
      "show_advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ααḍṽααṇͼḛḛḍ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
      "show_csat_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṗṵṵḽḽ ḭḭṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭṭḥ αα ṡͼṓṓṛḛḛ ḽṓṓẁḛḛṛ ṭḥααṇ ṓṓṛ ḛḛʠṵṵααḽ ṭṓṓ ṭḥḛḛ ṡḛḛṭ ṭḥṛḛḛṡḥṓṓḽḍ龍ผู้]"])},
      "show_csat_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽẏẏ αα ḈṢḀḀṪ ṭḥṛḛḛṡḥṓṓḽḍ龍ผู้]"])},
      "sync_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẏẏṇͼ ṓṓṇḽẏẏ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥααṭ...龍ผู้]"])},
      "sync_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṭ ḽααṭḛḛṡṭ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
      "tag_list_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḡṡ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "update_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṇααṃḛḛ龍ผู้]"])},
      "your_help_desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])}
    },
    "delete": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼααṇͼḛḛḽ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ααṇḍ ḍḛḛḽḛḛṭḛḛ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ, ͼṓṓṇṭααͼṭ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "description_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ Ṣṵṵṗṗṓṓṛṭ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
    },
    "deleted_conversations": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ṭḭḭͼḳḛḛṭ ḭḭṇ Żḛḛṇḍḛḛṡḳ Ṣṵṵṗṗṓṓṛṭ (ṓṓṛ ααṇṓṓṭḥḛḛṛ ḥḛḛḽṗ ḍḛḛṡḳ) ḍṓṓḛḛṡṇ'ṭ ααϝϝḛḛͼṭ ααṡṡṓṓͼḭḭααṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ. ŶŶṓṓṵṵ ͼααṇ ṡṭḭḭḽḽ ṽḭḭḛḛẁ ααṇḍ ṃααṇααḡḛḛ ṭḥḛḛṃ.龍ผู้]"])},
      "body_v2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ṭḭḭͼḳḛḛṭ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḍḛḛḽḛḛṭḛḛṡ ααṡṡṓṓͼḭḭααṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ, ṭṓṓṓṓ.", _interpolate(_named("line_break")), "Ḍḛḛḽḛḛṭḭḭṇḡ αα ṭḭḭͼḳḛḛṭ ḭḭṇ Żḛḛṇḍḛḛṡḳ Ṣṵṵṗṗṓṓṛṭ (ṓṓṛ ααṇṓṓṭḥḛḛṛ ḥḛḛḽṗ ḍḛḛṡḳ) ḍṓṓḛḛṡṇ'ṭ ααϝϝḛḛͼṭ ααṡṡṓṓͼḭḭααṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ. ŶŶṓṓṵṵ ͼααṇ ṡṭḭḭḽḽ ṽḭḭḛḛẁ ααṇḍ ṃααṇααḡḛḛ ṭḥḛḛṃ.龍ผู้]"])},
      "button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "delete_dialog": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ṛḛḛṽḭḭḛḛẁṡ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ααḽṡṓṓ ααϝϝḛḛͼṭṡ ḍααṭαα ṓṓṇ ḍααṡḥḅṓṓααṛḍṡ.龍ผู้]"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααḽḽ龍ผู้]"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁṡ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])}
      },
      "list": {
        "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααḽḽ龍ผู้]"])},
        "empty": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ṭḥḛḛṛḛḛ ααṛḛḛ ṛḛḛṽḭḭḛḛẁṡ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ḍḛḛḽḛḛṭḛḛḍ ṭḭḭͼḳḛḛṭṡ, ẏẏṓṓṵṵ'ḽḽ ṡḛḛḛḛ ṭḥḛḛṃ ḥḛḛṛḛḛ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁṡ ẏẏḛḛṭ龍ผู้]"])}
        },
        "search_box_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ṭḭḭͼḳḛḛṭ ḬḬḌ龍ผู้]"])},
        "ticket_item_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḭḭͼḳḛḛṭ ḬḬḌ: ", _interpolate(_named("id")), "龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])}
    },
    "general": {
      "danger_default_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝααṵṵḽṭ ẁṓṓṛḳṡṗααͼḛḛṡ ͼααṇ'ṭ ḅḛḛ ḍḛḛḽḛḛṭḛḛḍ. Ṫṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ, ṡḛḛḽḛḛͼṭ ααṇṓṓṭḥḛḛṛ ḍḛḛϝααṵṵḽṭ ẁṓṓṛḳṡṗααͼḛḛ ḭḭṇ ", _interpolate(_named("link")), ".龍ผู้]"])},
      "danger_default_workspace_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
      "delete_workspace_dialog": {
        "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
        "remove_and_delete_all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṃṓṓṽḛḛ ααṇḍ ḍḛḛḽḛḛṭḛḛ ααḽḽ ḍααṭαα龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬ ṵṵṇḍḛḛṛṡṭααṇḍ ṭḥααṭ ṭḥḭḭṡ ẁḭḭḽḽ ", _interpolate(_named("removeAllData")), " ṛḛḛḽααṭḛḛḍ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ, ḭḭṇͼḽṵṵḍḭḭṇḡ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ ααṇḍ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ.龍ผู้]"])},
        "confirm_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
        "hint_for_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ \"ḍḛḛḽḛḛṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ\" ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ ", _interpolate(_named("workspaceName")), "龍ผู้]"])},
        "type_to_confirm_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛḽḛḛṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
      },
      "danger_copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḛḛ ṡṵṵṛḛḛ ṓṓϝ ẁḥααṭ ẏẏṓṓṵṵ'ṛḛḛ ḍṓṓḭḭṇḡ ṓṓṛ ͼṓṓṇṡṵṵḽṭ αα ṗṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ ḅḛḛϝṓṓṛḛḛḥααṇḍ.龍ผู้]"])},
      "default_reviewee": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ḍḛḛϝααṵṵḽṭ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ḭḭṡ ṡḛḛḽḛḛͼṭḛḛḍ ḅααṡḛḛḍ ṓṓṇ ṭḥḛḛ ṃṓṓṡṭ ϝṛḛḛʠṵṵḛḛṇṭ ααḡḛḛṇṭ ṓṓṛ ṭḥḛḛ ͼṵṵṛṛḛḛṇṭ ααṡṡḭḭḡṇḛḛḛḛ.龍ผู้]"])},
        "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
        "dominant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ααͼṭḭḭṽḛḛ ααḡḛḛṇṭ龍ผู้]"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝααṵṵḽṭ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])}
      },
      "self_reviews_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṗḛḛṓṓṗḽḛḛ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛḭḭṛ ṓṓẁṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ḛḛṇααḅḽḛḛ ααḡḛḛṇṭṡ ṭṓṓ ṗḛḛṛϝṓṓṛṃ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "workspace_deleted_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "workspace_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ḥααṡ ḅḛḛḛḛṇ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "calibration_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṡṭααḅḽḭḭṡḥ ṇḛḛṵṵṭṛααḽ ḡṛααḍḭḭṇḡ ḅẏẏ ḥḭḭḍḭḭṇḡ ṛḛḛṽḭḭḛḛẁṡ ṗṛḛḛṽḭḭṓṓṵṵṡḽẏẏ ḡḭḭṽḛḛṇ ḅẏẏ ṓṓṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])},
      "calibration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇḅḭḭααṡḛḛḍ ḡṛααḍḭḭṇḡ龍ผู้]"])},
      "color_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓḽṓṓṛ龍ผู้]"])},
      "danger_copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
      "danger_copy_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛ ṭḥααṭ ṭḥḭḭṡ ẁḭḭḽḽ ṛḛḛṃṓṓṽḛḛ ααṇḍ ḍḛḛḽḛḛṭḛḛ ααḽḽ ḍααṭαα ṛḛḛḽααṭḛḛḍ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ, ḭḭṇͼḽṵṵḍḭḭṇḡ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ ααṇḍ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ.龍ผู้]"])},
      "delete_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "deleting_workspace_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ẁṓṓṛḳṡṗααͼḛḛ...龍ผู้]"])},
      "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
      "self_reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
    },
    "logins": {
      "auth_providers": {
        "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
        "magic_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
        "okta_sso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḳṭαα ṢṢṎṎ龍ผู้]"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ龍ผู้]"])}
      },
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḡḭḭͼ ḽḭḭṇḳ龍ผู้]"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇṇṓṓṭ ḍḛḛααͼṭḭḭṽααṭḛḛ ẏẏṓṓṵṵṛ ͼṵṵṛṛḛḛṇṭ ṡḭḭḡṇ-ḭḭṇ ṃḛḛṭḥṓṓḍ龍ผู้]"])},
      "success_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṓṓṇ ṃḛḛṭḥṓṓḍṡ ḥααṽḛḛ ḅḛḛḛḛṇ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ͼḛḛṛṭααḭḭṇ ṭẏẏṗḛḛṡ ṓṓϝ ααṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṓṓṇ ṃḛḛṭḥṓṓḍṡ ϝṓṓṛ ααḽḽ ṵṵṡḛḛṛṡ.龍ผู้]"])},
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṓṓḡḽḛḛ龍ผู้]"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡẁṓṓṛḍ龍ผู้]"])},
      "saml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢḀḀṀḺ/ṢṢṎṎ龍ผู้]"])},
      "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])}
    },
    "navigation": {
      "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
      "title_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḈḈṎṎṲṲṄṪ龍ผู้]"])},
      "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
      "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααṇḍ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "workspaces_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ααṇḍ ṃααṇααḡḛḛ ẁṓṓṛḳṡṗααͼḛḛṡ, ααṇḍ ḭḭṇṽḭḭṭḛḛ ṓṓṛ ͼṓṓṇṇḛḛͼṭ ṵṵṡḛḛṛṡ.龍ผู้]"])},
      "changes_in_progress": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααẏẏ ṓṓṇ ṗααḡḛḛ龍ผู้]"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ ṗααḡḛḛ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ ṭḥḭḭṡ ṗααḡḛḛ ααṇḍ ḽṓṓṡḛḛ ααḽḽ ͼḥααṇḡḛḛṡ?龍ผู้]"])}
      },
      "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
      "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])}
    },
    "no_conversations_found": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṭ ḽṓṓṓṓḳṡ ḽḭḭḳḛḛ ṭḥḛḛṛḛḛ ααṛḛḛ ṇṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ḭḭṃṗṓṓṛṭ. Ṣṭααṛṭ ḅẏẏ ḥααṽḭḭṇḡ ṡṓṓṃḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ, ṭḥḛḛṇ ṛḛḛṭṵṵṛṇ ḥḛḛṛḛḛ ṭṓṓ ααṇααḽẏẏẓḛḛ ṭḥḛḛṃ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "reviews": {
      "categories": {
        "delete_disabled_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇṇṓṓṭ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ṡẏẏṡṭḛḛṃ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ẁḭḭḽḽ ḛḛṛααṡḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛẏẏ ḍααṭαα ϝṛṓṓṃ ṛḛḛṗṓṓṛṭḭḭṇḡ.龍ผู้]"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṛḛḛṡṭṓṓṛḛḛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ, ẏẏṓṓṵṵ'ḽḽ ṇḛḛḛḛḍ ṭṓṓ ͼṛḛḛααṭḛḛ ḭḭṭ ααḡααḭḭṇ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
        },
        "edit_drawer": {
          "custom_category_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ͼααṭḛḛḡṓṓṛẏẏ ṭẏẏṗḛḛ龍ผู้]"])},
          "exact": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṇḍ ṗḥṛααṡḛḛṡ ṓṓṛ ḳḛḛẏẏẁṓṓṛḍṡ龍ผู้]"])},
            "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ 20 ααͼṭḭḭṽḛḛ ͼṵṵṡṭṓṓṃ ααṵṵṭṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḽḭḭṃḭḭṭ. Ṫṓṓ ͼṛḛḛααṭḛḛ αα ṇḛḛẁ ͼααṭḛḛḡṓṓṛẏẏ, ṃααṛḳ ααṇṓṓṭḥḛḛṛ ṓṓṇḛḛ ααṡ ḭḭṇααͼṭḭḭṽḛḛ ṓṓṛ ḍḛḛḽḛḛṭḛḛ ḭḭṭ.龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋααͼṭ ṭḛḛẋṭ-ṃααṭͼḥ龍ผู้]"])}
          },
          "manual": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ẁḭḭṭḥṓṓṵṵṭ ααṵṵṭṓṓṃααṭḭḭṓṓṇṡ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽ龍ผู้]"])}
          }
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "table": {
          "headings": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
            "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ龍ผู้]"])},
            "type_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ϝḭḭḽḽ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ẁḭḭṭḥ ṡṵṵḡḡḛḛṡṭḛḛḍ ṛααṭḭḭṇḡṡ ṭṓṓ ṡααṽḛḛ ṭḭḭṃḛḛ ṓṓṇ ṛḛḛṽḭḭḛḛẁḭḭṇḡ. Ṁααṇṵṵααḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṛḛḛ ϝḭḭḽḽḛḛḍ ḅẏẏ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ϝṛṓṓṃ ṡͼṛααṭͼḥ.龍ผู้]"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛ龍ผู้]"])}
          }
        },
        "types": {
          "auto": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓ龍ผู้]"])}
          },
          "manual": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽ龍ผู้]"])}
          }
        }
      },
      "create_category_drawer": {
        "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "submit_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ͼααṭḛḛḡṓṓṛẏẏ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
      },
      "create_new": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])}
      },
      "edit_category_drawer": {
        "closing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ龍ผู้]"])},
        "empathy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṗααṭḥẏẏ龍ผู้]"])},
        "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛḽḽḭḭṇḡ ααṇḍ ḡṛααṃṃααṛ龍ผู้]"])},
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḭḭṇḡ ͼḥααṇḡḛḛṡ ṭṓṓ ḡḛḛṇḛḛṛααḽ ͼααṭḛḛḡṓṓṛẏẏ ṡḛḛṭṭḭḭṇḡṡ ααṗṗḽḭḭḛḛṡ ṭṓṓ ααḽḽ ṡͼṓṓṛḛḛͼααṛḍṡ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ ḭḭṡ ṗααṛṭ ṓṓϝ龍ผู้]"])},
        "issue_understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗṛḛḛḥḛḛṇṡḭḭṓṓṇ龍ผู้]"])},
        "readability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍααḅḭḭḽḭḭṭẏẏ龍ผู้]"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓḽṵṵṭḭḭṓṓṇ ṓṓϝϝḛḛṛḛḛḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḡḛḛṇḛḛṛααḽ ͼααṭḛḛḡṓṓṛẏẏ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
        "tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṇḛḛ龍ผู้]"])}
      },
      "edit_general_drawer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḡḛḛṇḛḛṛααḽ ͼααṭḛḛḡṓṓṛẏẏ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
      },
      "no_results": {
        "categories": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
        },
        "root_causes": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
        },
        "scorecards": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡͼṓṓṛḛḛͼααṛḍṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ṡḛḛααṛͼḥ ṓṓṛ ͼḥααṇḡḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])}
      },
      "pickers": {
        "categories": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])}
        },
        "scorecards": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])}
        },
        "statuses": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])}
        },
        "types": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṭẏẏṗḛḛṡ龍ผู้]"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṭẏẏṗḛḛṡ龍ผู้]"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṭẏẏṗḛḛṡ龍ผู้]"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṭẏẏṗḛḛṡ龍ผู้]"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ṭẏẏṗḛḛṡ龍ผู้]"])}
        },
        "workspaces": {
          "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
          "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
        }
      },
      "root_causes": {
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ẁḭḭḽḽ ḛḛṛααṡḛḛ ααḽḽ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ḍααṭαα ϝṛṓṓṃ ṛḛḛṗṓṓṛṭḭḭṇḡ.龍ผู้]"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṛḛḛṡṭṓṓṛḛḛ ṭḥḭḭṡ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ, ẏẏṓṓṵṵ'ḽḽ ṇḛḛḛḛḍ ṭṓṓ ͼṛḛḛααṭḛḛ ḭḭṭ ααḡααḭḭṇ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])}
        },
        "edit_modal": {
          "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ṇααṃḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
            "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛ龍ผู้]"])}
          }
        }
      },
      "scorecards": {
        "create": {
          "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "add_group_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḡṛṓṓṵṵṗ ṡḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
          "auto_workspaces_disabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭḽẏẏ ḀḀṵṵṭṓṓǪḀḀ ḭḭṡ ḭḭṇααͼṭḭḭṽḛḛ, ẏẏṓṓṵṵ ͼααṇ ααͼṭḭḭṽααṭḛḛ ḭḭṭ ḅẏẏ ṭṵṵṛṇḭḭṇḡ ṓṓṇ ṭḥḛḛ ṃααḭḭṇ ḀḀṵṵṭṓṓǪḀḀ ṭṓṓḡḡḽḛḛ ḭḭṇ Ṛḛḛṽḭḭḛḛẁṡ. ṎṎṇͼḛḛ ḀḀṵṵṭṓṓǪḀḀ ḭḭṡ ααͼṭḭḭṽḛḛ, ṡͼṓṓṛḛḛͼααṛḍṡ ḽḛḛααṽḛḛ ααṵṵṭṓṓ-ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ ḛḛααͼḥ ṡḛḛḽḛḛͼṭḛḛḍ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
          "auto_workspaces_enabled_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭḽẏẏ ḀḀṵṵṭṓṓǪḀḀ ḭḭṡ ααͼṭḭḭṽḛḛ, ααṇḍ ṭḥḭḭṡ ṡͼṓṓṛḛḛͼααṛḍ ẁḭḭḽḽ ḽḛḛααṽḛḛ ααṵṵṭṓṓ-ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ ḛḛααͼḥ ṡḛḛḽḛḛͼṭḛḛḍ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
          "auto_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇααḅḽḛḛḍ ϝṓṓṛ ααṵṵṭṓṓ-ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ龍ผู้]"])},
          "auto_workspaces_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
          "category_criticality_change_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("category")), " ṇṓṓẁ ϝααḭḭḽṡ ṭḥḛḛ ẁḥṓṓḽḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "category_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ ḍḛḛṭḛḛṛṃḭḭṇḛḛṡ ṭḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ'ṡ ḭḭṃṗṓṓṛṭααṇͼḛḛ. Ŵḛḛḭḭḡḥṭ 0 ṃḛḛααṇṡ ṭḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ ḍṓṓḛḛṡṇ'ṭ ͼṓṓṇṭṛḭḭḅṵṵṭḛḛ ṭṓṓ ṭḥḛḛ ḬḬṇṭḛḛṛṇααḽ Ǫṵṵααḽḭḭṭẏẏ Ṣͼṓṓṛḛḛ (ḬḬǪṢ).龍ผู้]"])},
          "conditional_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṡḥṓṓẁ ṓṓṇ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ṵṵṇḍḛḛṛ ͼḛḛṛṭααḭḭṇ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])},
          "critical_category_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽṡ ṭḥḛḛ ḡṛṓṓṵṵṗ ṓṓṇḽẏẏ龍ผู้]"])},
          "critical_category_info_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽṡ ṭḥḛḛ ẁḥṓṓḽḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "critical_category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "critical_category_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽṡ ṭḥḛḛ ẁḥṓṓḽḛḛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "edit_category": {
            "category_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
            "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
            "edit_general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḡḛḛṇḛḛṛααḽ ͼααṭḛḛḡṓṓṛẏẏ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ϝṛṓṓṃ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
            "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ龍ผู้]"])},
            "weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ龍ผู้]"])}
          },
          "edit_root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
          "errors": {
            "bad_weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ ṃṵṵṡṭ ḅḛḛ ḅḛḛṭẁḛḛḛḛṇ ", _interpolate(_named("min")), " ṭṓṓ ", _interpolate(_named("max")), "龍ผู้]"])},
            "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ ḭḭṡ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])},
            "no_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṭ ḽḛḛααṡṭ 1 ͼααṭḛḛḡṓṓṛẏẏ ṭṓṓ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
            "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṭ ḽḛḛααṡṭ 1 ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
          },
          "group_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍!龍ผู้]"])},
          "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṡḛḛͼṭḭḭṓṓṇ ṇααṃḛḛ龍ผู้]"])},
          "manual_workspaces_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇααḅḽḛḛḍ ϝṓṓṛ ṃααṇṵṵααḽ ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ龍ผู้]"])},
          "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṭḭḭṭḽḛḛḍ ṡͼṓṓṛḛḛͼααṛḍ ṇααṃḛḛ龍ผู้]"])},
          "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ龍ผู้]"])},
          "published_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ṗṵṵḅḽḭḭṡḥḛḛḍ ṡṵṵͼͼḛḛṡṡϝṵṵḽḽẏẏ龍ผู้]"])},
          "rating_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ龍ผู้]"])},
          "review_critical_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍!!龍ผู้]"])},
          "root_causes_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
          "root_causes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ṭṓṓ ḛḛẋṗḽααḭḭṇ ṛααṭḭḭṇḡ龍ผู้]"])},
          "root_causes_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṵṵḽṭḭḭṗḽḛḛ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
          "root_causes_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍‘ṎṎṭḥḛḛṛ’ ṓṓṗṭḭḭṓṓṇ ααṇḍ ͼṓṓṃṃḛḛṇṭ ϝḭḭḛḛḽḍ龍ผู้]"])},
          "root_causes_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ϝṛṓṓṃ ṭḥḛḛ ḽḭḭṡṭ ṓṓṛ ͼṛḛḛααṭḛḛ αα ṇḛḛẁ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
          "root_causes_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ṭṓṓ ḭḭḍḛḛṇṭḭḭϝẏẏ ṛḛḛααṡṓṓṇṡ ḅḛḛḥḭḭṇḍ ḭḭṡṡṵṵḛḛṡ龍ผู้]"])},
          "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ααṡ Ḍṛααϝṭ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
          "total_weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ ẁḛḛḭḭḡḥṭ龍ผู้]"])},
          "total_weight_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ ẁḛḛḭḭḡḥṭ龍ผู้]"])},
          "weight_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ ṓṓϝ ", _interpolate(_named("weight")), " ͼṓṓṇṭṛḭḭḅṵṵṭḛḛṡ ", _interpolate(_named("percentage")), "% ṭṓṓ ṭḥḛḛ ṓṓṽḛḛṛααḽḽ ṡͼṓṓṛḛḛ龍ผู้]"])}
        },
        "delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ṃṵṵṡṭ ḥααṽḛḛ ααṭ ḽḛḛααṡṭ 1 ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "delete_modal": {
          "delete_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
          "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ṡͼṓṓṛḛḛͼααṛḍṡ ḭḭṡ ṗḛḛṛṃααṇḛḛṇṭ. ḬḬṭ ẁḭḭḽḽ ḛḛṛααṡḛḛ ααḽḽ ṡͼṓṓṛḛḛͼααṛḍ ḍααṭαα ϝṛṓṓṃ ṛḛḛṗṓṓṛṭḭḭṇḡ.龍ผู้]"])},
          "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ṛḛḛṡṭṓṓṛḛḛ ṭḥḭḭṡ ṡͼṓṓṛḛḛͼααṛḍ, ẏẏṓṓṵṵ'ḽḽ ṇḛḛḛḛḍ ṭṓṓ ͼṛḛḛααṭḛḛ ḭḭṭ ααḡααḭḭṇ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])}
        },
        "edit": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])}
        },
        "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "table": {
          "headings": {
            "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
            "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ龍ผู้]"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛ龍ผู้]"])},
            "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
          }
        }
      },
      "settings_modal": {
        "allow_skip": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽḭḭḛḛṡ ṭṓṓ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
          "all_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛααṭḭḭṇḡ ṡͼααḽḛḛṡ ẁḭḭṭḥḭḭṇ ḛḛẋḭḭṡṭḭḭṇḡ ṡͼṓṓṛḛḛͼααṛḍṡ ẁḭḭḽḽ ḭḭṇͼḽṵṵḍḛḛ Ṅ/ḀḀ ααṡ ααṇ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])},
          "specify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛͼḭḭϝẏẏ ϝṓṓṛ ḛḛααͼḥ ͼααṭḛḛḡṓṓṛẏẏ ḭḭṇ ṡͼṓṓṛḛḛͼααṛḍ ṡḛḛṭṵṵṗ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṛḛḛṽḭḭḛḛẁ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
      },
      "status": {
        "types": {
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])}
          },
          "draft": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭ龍ผู้]"])}
          },
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇααͼṭḭḭṽḛḛ龍ผู้]"])}
          }
        }
      },
      "table": {
        "actions": {
          "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ααͼṭḭḭṽḛḛ龍ผู้]"])},
          "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ḭḭṇααͼṭḭḭṽḛḛ龍ผู้]"])}
        }
      },
      "tabs": {
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
        "root_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])},
        "scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])}
      },
      "toast": {
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛẏẏ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "changes_applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼḥααṇḡḛḛṡ ααṗṗḽḭḭḛḛḍ龍ผู้]"])},
        "root_cause_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "scorecard_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ṃααṛḳḛḛḍ ααṡ ααͼṭḭḭṽḛḛ龍ผู้]"])},
        "scorecard_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "scorecard_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ḍṵṵṗḽḭḭͼααṭḛḛḍ龍ผู้]"])},
        "scorecard_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ ṃααṛḳḛḛḍ ααṡ ḭḭṇααͼṭḭḭṽḛḛ龍ผู้]"])}
      }
    },
    "scorecard": {
      "option": {
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
      },
      "toast_updated_message_with_action": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ ṵṵṗḍααṭḛḛḍ. ", _interpolate(_named("buttonStart")), "Ṣṭααṛṭ ṛḛḛṽḭḭḛḛẁḭḭṇḡ", _interpolate(_named("buttonEnd")), ".龍ผู้]"])},
      "binary_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡḛḛṭṡ αα ḅḭḭṇααṛẏẏ ṡͼααḽḛḛ ḅẏẏ ḍḛḛϝααṵṵḽṭ, ḅṵṵṭ ẏẏṓṓṵṵ ͼααṇ ααḽṡṓṓ ṓṓṗṭ ϝṓṓṛ αα ṃṓṓṛḛḛ ḡṛααṇṵṵḽααṛ ṡͼααḽḛḛ:龍ผู้]"])},
      "granular_scale": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ṡḛḛḛḛ ḍḭḭϝϝḛḛṛḛḛṇṭ ṛααṭḭḭṇḡ ṡͼααḽḛḛ ṓṓṗṭḭḭṓṓṇṡ, ͼṓṓṇϝḭḭḡṵṵṛḛḛḍ ḅẏẏ ͼααṭḛḛḡṓṓṛẏẏ, ḡṓṓ ṭṓṓ ẏẏṓṓṵṵṛ ", _interpolate(_named("link")), "龍ผู้]"])},
      "mocked_category_name_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṗααṭḥẏẏ/Ṫṓṓṇḛḛ龍ผู้]"])},
      "mocked_category_name_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓḍṵṵͼṭ ḳṇṓṓẁḽḛḛḍḡḛḛ龍ผู้]"])},
      "modal": {
        "by_updating_rating_scales_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃẏẏ ṵṵṗḍααṭḭḭṇḡ ṭḥḛḛ ṛααṭḭḭṇḡ ṡͼααḽḛḛṡ:龍ผู้]"])},
        "by_updating_rating_scales_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṗṛḛḛṽḭḭṓṓṵṵṡ ṛααṭḭḭṇḡ ṡͼααḽḛḛ ααṇḍ ṭḥḛḛ ͼṓṓṛṛḛḛṡṗṓṓṇḍḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ẁḭḭḽḽ ḅḛḛ ααṛͼḥḭḭṽḛḛḍ (ṇṓṓṭ ḍḛḛḽḛḛṭḛḛḍ).龍ผู้]"])},
        "by_updating_rating_scales_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ḍααṡḥḅṓṓααṛḍ ḍααṭαα ẁḭḭḽḽ ḅḛḛ ααϝϝḛḛͼṭḛḛḍ ṡḭḭṇͼḛḛ ẏẏṓṓṵṵṛ ṓṓḽḍ ααṇḍ ṇḛḛẁ ṡͼααḽḛḛ ṃḭḭḡḥṭ ṇṓṓṭ ḅḛḛ ͼṓṓṃṗααṛααḅḽḛḛ.龍ผู้]"])},
        "clone_categories_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṇḛḛ ṃẏẏ ḛḛẋḭḭṡṭḭḭṇḡ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṡḛḛṭṵṵṗ ṓṓṽḛḛṛ ṭṓṓ ṭḥḛḛ ṇḛḛẁ ṡͼααḽḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ṵṵṗḍααṭḛḛ ṭḥḛḛ ṛααṭḭḭṇḡ ṡͼααḽḛḛṡ?龍ผู้]"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ααḽṭḛḛṛ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ ḍḭḭṡṗḽααẏẏ ϝṓṓṛṃααṭ (ṇṵṵṃḅḛḛṛ ṓṓṛ ḛḛṃṓṓĵḭḭṡ) ααṇḍ ṃααḳḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ͼṓṓṃṗṵṵḽṡṓṓṛẏẏ ṓṓṛ ṓṓṗṭḭḭṓṓṇααḽ.龍ผู้]"])},
      "display_emojis_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ ḛḛṃṓṓĵḭḭṡ ḭḭṇṡṭḛḛααḍ ṓṓϝ ṇṵṵṃḅḛḛṛṡ龍ผู้]"])},
      "display_na_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṗḛḛṓṓṗḽḛḛ ṭṓṓ ṡḳḭḭṗ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "mocked_category_name_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
      "scale_options_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ ṭẏẏṗḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
      "toast_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ ϝṓṓṛ 1 ẁṓṓṛḳṡṗααͼḛḛ ḥααṽḛḛ ḅḛḛḛḛṇ ͼḽṓṓṇḛḛḍ"]), _normalize(["Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ ϝṓṓṛ ", _interpolate(_named("workspacesAmount")), " ẁṓṓṛḳṡṗααͼḛḛṡ ḥααṽḛḛ ḅḛḛḛḛṇ ͼḽṓṓṇḛḛḍ龍ผู้]"])])},
      "workspace_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
    },
    "spotlight": {
      "create": {
        "message_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬϝ ", _interpolate(_named("messageType")), "龍ผู้]"])}
      },
      "deadair": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡṵṵṛϝααͼḛḛ ͼααḽḽṡ ẁḥḛḛṛḛḛ ṭḥḛḛ ααḡḛḛṇṭ ḍṓṓḛḛṡ ṇṓṓṭ ḡḭḭṽḛḛ ṗṛṓṓṃṗṭṡ ṭṓṓ ͼṵṵṡṭṓṓṃḛḛṛ ṭṓṓ ḛḛẋṗḛḛͼṭ αα ṡḭḭḽḛḛṇͼḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
        "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḥααṡ ṃṓṓṃḛḛṇṭṡ ṓṓϝ ḍḛḛααḍ ααḭḭṛ ḽααṡṭḭḭṇḡ ḽṓṓṇḡḛḛṛ ṭḥααṇ ṡḛḛṭ ṭḥṛḛḛṡḥṓṓḽḍ.龍ผู้]"])},
        "filter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝḭḭṇḛḛ ṃααẋḭḭṃṵṵṃ ḍḛḛααḍ ααḭḭṛ ṭḥṛḛḛṡḥṓṓḽḍ ḭḭṇ ͼααḽḽṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛααḍ ααḭḭṛ龍ผู้]"])}
      },
      "delete": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛḍ ḭḭṇṡḭḭḡḥṭ ẁḭḭḽḽ ṇṓṓṭ ααṗṗḛḛααṛ ṓṓṇ ααṇẏẏ ṇḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ϝḭḭḽṭḛḛṛṡ. Ḥḭḭṡṭṓṓṛḭḭͼααḽ ḍααṭαα ṓṓṇ ḍααṡḥḅṓṓααṛḍṡ ααṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṃααḭḭṇ ḭḭṇṭααͼṭ.龍ผู้]"])},
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḍḛḛḽḛḛṭḛḛ Ṣṗṓṓṭḽḭḭḡḥṭ ḭḭṇṡḭḭḡḥṭ龍ผู้]"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ ḭḭṇṡḭḭḡḥṭ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ Ṣṗṓṓṭḽḭḭḡḥṭ ḭḭṇṡḭḭḡḥṭ龍ผู้]"])}
      },
      "description": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ͼṓṓṇṭααḭḭṇḭḭṇḡ ṡṗḛḛͼḭḭϝḭḭͼ ẁṓṓṛḍṡ ṓṓṛ ṗḥṛααṡḛḛṡ ḭḭṇ ṭṛααṇṡͼṛḭḭṗṭṡ ααṇḍ ṃḛḛṡṡααḡḛḛṡ.龍ผู้]"])}
      },
      "recording_disclosure": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ḭḭḍḛḛṇṭḭḭϝẏẏ ͼααḽḽṡ ḽααͼḳḭḭṇḡ ṭḥḛḛ ṃααṇḍααṭṓṓṛẏẏ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṡṭααṭḛḛṃḛḛṇṭ, ḽḭḭḳḛḛ 'Ṫḥḭḭṡ ͼααḽḽ ẁḭḭḽḽ ḅḛḛ ṛḛḛͼṓṓṛḍḛḛḍ' ααṇḍ ṡḭḭṃḭḭḽααṛ.龍ผู้]"])},
        "spotlight_setup_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḭḭṃṡ ṭṓṓ ḭḭḍḛḛṇṭḭḭϝẏẏ αα ṛḛḛͼṓṓṛḍḭḭṇḡ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṡṭααṭḛḛṃḛḛṇṭ ḭḭṇ ṭḥḛḛ ͼααḽḽ ṵṵṡḭḭṇḡ αα Ḻααṛḡḛḛ Ḻααṇḡṵṵααḡḛḛ Ṁṓṓḍḛḛḽ (ḺḺṀ) ṭḥααṭ ḍẏẏṇααṃḭḭͼααḽḽẏẏ ṵṵṇḍḛḛṛṡṭααṇḍṡ ͼṓṓṇṭḛḛẋṭ, ḛḛḽḭḭṃḭḭṇααṭḭḭṇḡ ṭḥḛḛ ṇḛḛḛḛḍ ϝṓṓṛ ṗṛḛḛṡḛḛṭ ṗḥṛααṡḛḛṡ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼṓṓṛḍḭḭṇḡ ḍḭḭṡͼḽṓṓṡṵṵṛḛḛ ṃḭḭṡṡḭḭṇḡ龍ผู้]"])}
      },
      "dead_air": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṡṵṵṛϝααͼḛḛ ͼααḽḽṡ ẁḥḛḛṛḛḛ ṭḥḛḛ ααḡḛḛṇṭ ḍṓṓḛḛṡ ṇṓṓṭ ḡḭḭṽḛḛ ṗṛṓṓṃṗṭṡ ṭṓṓ ͼṵṵṡṭṓṓṃḛḛṛ ṭṓṓ ḛḛẋṗḛḛͼṭ αα ṡḭḭḽḛḛṇͼḛḛ ḭḭṇ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛααḍ ααḭḭṛ龍ผู้]"])},
        "tooltip_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛͼḭḭϝẏẏ ḍḛḛααḍ ααḭḭṛ ṃṓṓṃḛḛṇṭṡ ḭḭṇ αα ͼααḽḽ龍ผู้]"])}
      }
    },
    "subscription": {
      "change_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṅḛḛḛḛḍ ṭṓṓ ṃααḳḛḛ ͼḥααṇḡḛḛṡ ṭṓṓ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ? ", _interpolate(_named("link")), " (", _interpolate(_named("email")), ") ααṇḍ ẁḛḛ’ḽḽ ḅḛḛ ḥααṗṗẏẏ ṭṓṓ ḥḛḛḽṗ.龍ผู้]"])},
      "plan": {
        "zendesk": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααṇααḽẏẏẓḛḛ 100% ṓṓϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααͼṛṓṓṡṡ ααḡḛḛṇṭṡ, ḂṔṎṎṡ, ͼḥααṇṇḛḛḽṡ, ααṇḍ ḽααṇḡṵṵααḡḛḛṡ. ḀḀḬḬ ḍḛḛṭḛḛͼṭṡ ḭḭṡṡṵṵḛḛṡ, ḳṇṓṓẁḽḛḛḍḡḛḛ ḡααṗṡ, ααṇḍ ͼṓṓααͼḥḭḭṇḡ ṓṓṗṗṓṓṛṭṵṵṇḭḭṭḭḭḛḛṡ ṭṓṓ ḭḭṃṗṛṓṓṽḛḛ ṡḛḛṛṽḭḭͼḛḛ.龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ 100% ͼṓṓṽḛḛṛααḡḛḛ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ϝḛḛḛḛḍḅααͼḳ ααṇḍ ͼṓṓααͼḥḭḭṇḡ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ ḀḀḬḬ ḭḭṇṡḭḭḡḥṭṡ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽααṇͼḛḛḍ ṛḛḛṗṓṓṛṭḭḭṇḡ ααṇḍ ḍααṡḥḅṓṓααṛḍṡ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ ααṇααḽẏẏṡḭḭṡ ααṇḍ ϝḭḭḽṭḛḛṛḭḭṇḡ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇ-ḍḛḛṃααṇḍ ṭṛααṇṡḽααṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ααṡṡḭḭḡṇṃḛḛṇṭṡ ααṇḍ ḡṓṓααḽ ṡḛḛṭṭḭḭṇḡ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ααṇḍ ʠṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])}
          },
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ Ǫṵṵααḽḭḭṭẏẏ ḀḀṡṡṵṵṛααṇͼḛḛ (ǪḀḀ)龍ผู้]"])}
        },
        "advanced": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏṭḥḭḭṇḡ ṓṓṇ Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ, ṗḽṵṵṡ龍ผู้]"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽααṇͼḛḛḍ (ḽḛḛḡααͼẏẏ)龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢṢṎṎ ṢḀḀṀḺ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḅḥṓṓṓṓḳṡ ϝṓṓṛ ḭḭṃṃḛḛḍḭḭααṭḛḛ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṓṓϝ ͼṛḭḭṭḭḭͼααḽ ϝααḭḭḽṡ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭαα ẁααṛḛḛḥṓṓṵṵṡḛḛ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααḽḛḛṡϝṓṓṛͼḛḛ Ṣḛḛṛṽḭḭͼḛḛ Ḉḽṓṓṵṵḍ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ḍḛḛṽḛḛḽṓṓṗṃḛḛṇṭ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḍḭḭͼααṭḛḛḍ ͼṵṵṡṭṓṓṃḛḛṛ ṡṵṵͼͼḛḛṡṡ ṃααṇααḡḛḛṛ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṛḛḛͼṭ ααͼͼḛḛṡṡ ṭṓṓ ṓṓṵṵṛ ṗṛṓṓḍṵṵͼṭ ααṇḍ ḛḛṇḡḭḭṇḛḛḛḛṛḭḭṇḡ ṭḛḛααṃṡ ṭḥṛṓṓṵṵḡḥ Ṣḽααͼḳ龍ผู้]"])}
          }
        },
        "ai": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏṭḥḭḭṇḡ ḭḭṇ Ḡṛṓṓẁṭḥ Ṣṵṵḭḭṭḛḛ, ṗḽṵṵṡ龍ผู้]"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ Ṣṵṵḭḭṭḛḛ龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ϝṓṓṛ 100% ͼṓṓṽḛḛṛααḡḛḛ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ-ḍṛḭḭṽḛḛṇ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḬḬṇṡḭḭḡḥṭṡ ṭṓṓ ṃααḳḛḛ ṡḛḛṇṡḛḛ ṓṓϝ ḍααṭαα龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ-ḛḛṇḥααṇͼḛḛḍ ḥḛḛḽṗ ḍḛḛṡḳ ḍααṭαα ḛḛṇṛḭḭͼḥṃḛḛṇṭ ααṇḍ ϝḭḭḽṭḛḛṛḭḭṇḡ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ: ḀḀṵṵṭṓṓṃααṭḛḛḍ ḍḛḛṭḛḛͼṭḭḭṓṓṇ ϝṓṓṛ ṃṵṵṡṭ-ṛḛḛṽḭḭḛḛẁ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ ααṇααḽẏẏṡḭḭṡ ααṇḍ ϝḭḭḽṭḛḛṛḭḭṇḡ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ-ṗṓṓẁḛḛṛḛḛḍ ṗḛḛṛϝṓṓṛṃααṇͼḛḛ ṛḛḛṗṓṓṛṭḭḭṇḡ ααṇḍ ḭḭṇṡḭḭḡḥṭṡ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇ-ḍḛḛṃααṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṛααṇṡḽααṭḭḭṓṓṇ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḭḭṃḛḛ ṭṛααͼḳḭḭṇḡ龍ผู้]"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ṛααṭḛḛ ααṇααḽẏẏṭḭḭͼṡ龍ผู้]"])}
          }
        },
        "enterprise": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏṭḥḭḭṇḡ ḭḭṇ ḀḀḬḬ Ṣṵṵḭḭṭḛḛ, ṗḽṵṵṡ龍ผู้]"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛṗṛḭḭṡḛḛ Ṣṵṵḭḭṭḛḛ龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛṗṛḭḭṡḛḛ ḭḭṃṗḽḛḛṃḛḛṇṭααṭḭḭṓṓṇ ṗααͼḳααḡḛḛ ḭḭṇͼḽṵṵḍḭḭṇḡ Ṣααḽḛḛṡϝṓṓṛͼḛḛ ααṇḍ Ḡḛḛṇḛḛṡẏẏṡ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ḍḛḛṽḛḛḽṓṓṗṃḛḛṇṭ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢṢṎṎ ṢḀḀṀḺ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭαα ẁααṛḛḛḥṓṓṵṵṡḛḛ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḅḥṓṓṓṓḳṡ ϝṓṓṛ ḭḭṃṃḛḛḍḭḭααṭḛḛ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṓṓϝ ͼṛḭḭṭḭḭͼααḽ ϝααḭḭḽṡ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḍḭḭͼααṭḛḛḍ ͼṵṵṡṭṓṓṃḛḛṛ ṡṵṵͼͼḛḛṡṡ ṃααṇααḡḛḛṛ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṛḛḛͼṭ ααͼͼḛḛṡṡ ṭṓṓ ṓṓṵṵṛ ṗṛṓṓḍṵṵͼṭ ααṇḍ ḛḛṇḡḭḭṇḛḛḛḛṛḭḭṇḡ ṭḛḛααṃṡ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααṛḽẏẏ ααͼͼḛḛṡṡ ααṇḍ ḭḭṇϝḽṵṵḛḛṇͼḛḛ ṓṓṇ ṓṓṵṵṛ ṛṓṓααḍṃααṗ龍ผู้]"])}
          }
        },
        "growth": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓẁṭḥ Ṣṵṵḭḭṭḛḛ龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼ ṛḛḛṽḭḭḛḛẁ ααṡṡḭḭḡṇṃḛḛṇṭṡ & ḡṓṓααḽ ṡḛḛṭṭḭḭṇḡ龍ผู้]"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααṛḡḛḛṭḛḛḍ ͼṓṓααͼḥḭḭṇḡ & ααṇααḽẏẏṭḭḭͼṡ龍ผู้]"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ ϝṓṓṛ ḽḛḛααṛṇḭḭṇḡ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ ṛṓṓḽḛḛ & ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṡḭḭṭḭḭṽḛḛ ḍααṭαα ṃααṡḳḭḭṇḡ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽααṇͼḛḛḍ ϝḭḭḽṭḛḛṛḭḭṇḡ ṓṓṗṭḭḭṓṓṇṡ ααṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṡḛḛααṛͼḥ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓααḅḽḛḛ ṡͼṓṓṛḛḛͼααṛḍṡ ẁḭḭṭḥ ṃṵṵḽṭḭḭṗḽḛḛ ṛααṭḭḭṇḡṡ, ααḍĵṵṵṡṭααḅḽḛḛ ẁḛḛḭḭḡḥṭṡ, ααṇḍ ααṵṵṭṓṓ-ϝααḭḭḽ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛṓṓẁṡḛḛṛ ḛḛẋṭḛḛṇṡḭḭṓṓṇ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇẏẏẁḥḛḛṛḛḛ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ααṇααḽẏẏṡḭḭṡ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛϝṓṓṛṃααṇͼḛḛ ṛḛḛṗṓṓṛṭḭḭṇḡ ϝṓṓṛ ͼṓṓṃṗααṛḭḭṇḡ ααḡḛḛṇṭṡ, ṭḛḛααṃṡ, ḂṔṎṎṡ龍ผู้]"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ ααṇḍ ͼṵṵṡṭṓṓṃḛḛṛ ϝḛḛḛḛḍḅααͼḳ ααṇααḽẏẏṭḭḭͼṡ龍ผู้]"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛααṭṵṵṛḛḛṡ龍ผู้]"])}
        },
        "professional": {
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṽḛḛṛẏẏṭḥḭḭṇḡ ṓṓṇ Ṣṭααṛṭḛḛṛ, ṗḽṵṵṡ龍ผู้]"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ (ḽḛḛḡααͼẏẏ)龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṵṵḽṭḭḭṗḽḛḛ ṛααṭḭḭṇḡ ṡͼααḽḛḛṡ ṗḛḛṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ-ḍṛḭḭṽḛḛṇ ḭḭṇṭḛḛṛααͼṭḭḭṽḛḛ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḬḬṇṡḭḭḡḥṭṡ龍ผู้]"])},
            "item_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
            "item_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḭḭṇṡ ϝṓṓṛ ͼṓṓααͼḥḭḭṇḡ龍ผู้]"])},
            "item_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓααͼḥḭḭṇḡ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
            "item_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
            "item_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭḭḭṃḛḛṇṭ ααṇααḽẏẏṡḭḭṡ ααṇḍ ϝḭḭḽṭḛḛṛḭḭṇḡ龍ผู้]"])},
            "item_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛḭḭṇḡ ḅααṡḛḛḍ ṓṓṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṃṗḽḛḛẋḭḭṭẏẏ龍ผู้]"])},
            "item_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṇṡḭḭḡḥṭṡ龍ผู้]"])},
            "item_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇααḽ ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ααṇααḽẏẏṡḭḭṡ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽααṇͼḛḛḍ ḀḀḬḬ-ṗṓṓẁḛḛṛḛḛḍ ḈṢḀḀṪ ααṇααḽẏẏṭḭḭͼṡ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛααḍḭḭṇḡ ͼααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛṓṓẁṡḛḛṛ ḛḛẋṭḛḛṇṡḭḭṓṓṇ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇẏẏẁḥḛḛṛḛḛ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ ṭṓṓ ḍḭḭṡͼṓṓṽḛḛṛ ṃṵṵṡṭ-ṛḛḛṽḭḭḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇ-ḍḛḛṃααṇḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṛααṇṡḽααṭḭḭṓṓṇ龍ผู้]"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭḭḭṃḛḛ ṭṛααͼḳḭḭṇḡ龍ผู้]"])}
          }
        },
        "flat_fee": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṗḽααṇ龍ผู้]"])}
        },
        "starter": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭḛḛṛ (ḽḛḛḡααͼẏẏ)龍ผู้]"])},
          "features": {
            "item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇḽḭḭṃḭḭṭḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
            "item_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢṾ ḛḛẋṗṓṓṛṭ龍ผู้]"])},
            "item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛḛḛṛ-ṭṓṓ-ṗḛḛḛḛṛ ααṇḍ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
            "item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ ẁḭḭṭḥ ααḍĵṵṵṡṭααḅḽḛḛ ẁḛḛḭḭḡḥṭṡ ααṇḍ ααṵṵṭṓṓ-ϝααḭḭḽ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓḅṵṵṡṭ ϝḭḭḽṭḛḛṛḭḭṇḡ ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
            "item_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααḭḭḽẏẏ, ẁḛḛḛḛḳḽẏẏ, ṓṓṛ ṃṓṓṇṭḥḽẏẏ ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽṡ龍ผู้]"])},
            "item_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏṡ ααṇḍ ḅααṡḭḭͼ ααṇααḽẏẏṭḭḭͼṡ龍ผู้]"])},
            "item_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṡḥḅṓṓααṛḍṡ龍ผู้]"])},
            "item_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗṓṓṛṭṡ ϝṓṓṛ ͼṓṓṃṗααṛḭḭṇḡ ααḡḛḛṇṭṡ, ṭḛḛααṃṡ, ḂṔṎṎṡ龍ผู้]"])},
            "item_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ ṛṓṓḽḛḛ ααṇḍ ṗṛḭḭṽḭḭḽḛḛḡḛḛ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])}
          },
          "features_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛααṭṵṵṛḛḛṡ龍ผู้]"])}
        }
      },
      "support_email": {
        "customer": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ααṃ ḽṓṓṓṓḳḭḭṇḡ ṭṓṓ ṃααḳḛḛ αα ͼḥααṇḡḛḛ ḭḭṇ ṃẏẏ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ.\n\nḤḭḭḡḥḽḭḭḡḥṭ ṓṓṛ ḭḭṇḍḭḭͼααṭḛḛ ṓṓṇḛḛ ṓṓṗṭḭḭṓṓṇ ααṇḍ ḭḭṇḍḭḭͼααṭḛḛ ṭḥḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ṡḛḛααṭṡ ẏẏṓṓṵṵ ẁṓṓṵṵḽḍ ḽḭḭḳḛḛ ṭṓṓ ͼḥααṇḡḛḛ:\nḬḬ ẁṓṓṵṵḽḍ ḽḭḭḳḛḛ ṭṓṓ [ααḍḍ Ẋ ṡḛḛααṭṡ] [ṛḛḛṃṓṓṽḛḛ Ẋ ṡḛḛααṭṡ] [ṵṵṗḡṛααḍḛḛ ṭṓṓ ṭḥḛḛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṗḽααṇ]\n\nṔḽḛḛααṡḛḛ ṡḥααṛḛḛ ẏẏṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ ṇααṃḛḛ ααṇḍ ṭḥḛḛ ṇααṃḛḛ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ, ααṡ ẁḛḛḽḽ ααṡ ααṇẏẏ ṓṓṭḥḛḛṛ ḍḛḛṭααḭḭḽṡ ẁḛḛ ṡḥṓṓṵṵḽḍ ḳṇṓṓẁ ṭṓṓ ṗṛṓṓͼḛḛṡṡ ṭḥḭḭṡ ͼḥααṇḡḛḛ.龍ผู้]"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ͼḥααṇḡḛḛ龍ผู้]"])}
        },
        "trial": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ααṃ ḽṓṓṓṓḳḭḭṇḡ ṭṓṓ ṗṵṵṛͼḥααṡḛḛ αα Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ.\n\nṔḽḛḛααṡḛḛ ṡḥααṛḛḛ ẏẏṓṓṵṵṛ ͼṓṓṃṗααṇẏẏ ṇααṃḛḛ ααṇḍ ṭḥḛḛ ṇααṃḛḛ ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ǪḀḀ ααͼͼṓṓṵṵṇṭ, ααṡ ẁḛḛḽḽ ααṡ ααṇẏẏ ṓṓṭḥḛḛṛ ḍḛḛṭααḭḭḽṡ ẁḛḛ ṡḥṓṓṵṵḽḍ ḳṇṓṓẁ ṭṓṓ ṗṛṓṓͼḛḛṡṡ ṭḥḭḭṡ ṛḛḛʠṵṵḛḛṡṭ:\n\nṔḽḛḛααṡḛḛ ααḽṡṓṓ ṡḥααṛḛḛ ααṇẏẏ ṡṗḛḛͼḭḭϝḭḭͼ ʠṵṵḛḛṡṭḭḭṓṓṇṡ ẏẏṓṓṵṵ’ḍ ḽḭḭḳḛḛ ααḍḍṛḛḛṡṡḛḛḍ ṗṛḭḭṓṓṛ ṭṓṓ ṗṵṵṛͼḥααṡḭḭṇḡ:\n\nṪḥααṇḳ ẏẏṓṓṵṵ!龍ผู้]"])},
          "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ṗṵṵṛͼḥααṡḛḛ龍ผู้]"])}
        }
      },
      "zendesk_subscription_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḛḛṇḍṡ ṓṓṇ ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " ḽḛḛϝṭ)龍ผู้]"])},
      "zendesk_trial_end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḛḛṇḍṡ ṓṓṇ ", _interpolate(_named("date")), " (", _interpolate(_named("days")), " ḽḛḛϝṭ)龍ผู้]"])},
      "zendesk_trial_end_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḍααẏẏ"]), _normalize([_interpolate(_named("n")), " ḍααẏẏṡ龍ผู้]"])])},
      "billed_monthly": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṛḍ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṭḥ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṡṭ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṭḥ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṇḍ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("dayOfMonth")), "ṭḥ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ.龍ผู้]"])}
      },
      "billed_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḛḛḍ ṭṓṓ ", _interpolate(_named("card")), " (ḛḛẋṗḭḭṛḛḛṡ ", _interpolate(_named("expires")), ") ṓṓṇ ", _interpolate(_named("billingDate")), ".龍ผู้]"])},
      "ending_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛṇḍḭḭṇḡ ḭḭṇ龍ผู้]"])},
      "free_trial_expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḚḚẋṗḭḭṛḛḛḍ"]), _normalize(["ḚḚẋṗḭḭṛḛḛṡ ḭḭṇ 1 ḍααẏẏ"]), _normalize(["ḚḚẋṗḭḭṛḛḛṡ ḭḭṇ ", _interpolate(_named("expiresIn")), " ḍααẏẏṡ龍ผู้]"])])},
      "min_seats_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭḭḭṇḡ ṗṛḭḭͼḛḛ ḭḭṇͼḽṵṵḍḛḛṡ ", _interpolate(_named("n")), " ṵṵṡḛḛṛ ḽḭḭͼḛḛṇṡḛḛṡ龍ผู้]"])},
      "min_seats_montly_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ṡṭααṛṭṡ ααṭ ", _interpolate(_named("sum")), "/ṃṓṓṇṭḥ龍ผู้]"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃṓṓṇṭḥ龍ผู้]"])},
      "per_agent_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗḛḛṛ ṵṵṡḛḛṛ / ṃṓṓṇṭḥ龍ผู้]"])},
      "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗḛḛṛ ẏẏḛḛααṛ龍ผู้]"])},
      "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗḛḛṛ ṃṓṓṇṭḥ龍ผู้]"])},
      "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ʠṵṵααṛṭḛḛṛ龍ผู้]"])},
      "remove_cancellation_warning": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṃṓṓṽḛḛ ṭḥḛḛ ṡͼḥḛḛḍṵṵḽḛḛḍ ͼααṇͼḛḛḽḽααṭḭḭṓṓṇ龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ ṭṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ ṵṵṡḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ", _interpolate(_named("link")), "龍ผู้]"])}
      },
      "subscription_cancelled_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ẁααṡ ͼααṇͼḛḛḽḽḛḛḍ ṓṓṇ ", _interpolate(_named("date")), ".龍ผู้]"])},
      "subscription_cancels_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṡ ṡͼḥḛḛḍṵṵḽḛḛḍ ṭṓṓ ḛḛẋṗḭḭṛḛḛ ṓṓṇ ", _interpolate(_named("date")), ".龍ผู้]"])},
      "trial_pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), " ḍααẏẏ ṭṛḭḭααḽ ααṭ ṇṓṓ ḛḛẋṭṛαα ͼṓṓṡṭ!龍ผู้]"])},
      "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṡḛḛḍ龍ผู้]"])},
      "used_seats_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḥααṽḛḛ ", _interpolate(_named("n")), " ṵṵṡḛḛṛṡ. Ṣḛḛααṭ ͼṓṓṵṵṇṭ ͼααṇ'ṭ ḅḛḛ ḽṓṓẁḛḛṛ ṭḥααṇ ṭḥααṭ.龍ผู้]"])},
      "will_resume_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ẁḭḭḽḽ ṛḛḛṡṵṵṃḛḛ ṓṓṇ ", _interpolate(_named("time")), "龍ผู้]"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏḛḛααṛ龍ผู้]"])},
      "call_transcription_upsell_alert": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ͼṵṵṛṛḛḛṇṭ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṗḽααṇ (", _interpolate(_named("plan")), ") ḍṓṓḛḛṡ ṇṓṓṭ ḭḭṇͼḽṵṵḍḛḛ ḀḀḬḬ-ṗṓṓẁḛḛṛḛḛḍ ϝḛḛααṭṵṵṛḛḛṡ. Ṫṓṓ ṵṵṇḽḛḛααṡḥ ṭḥḛḛ ϝṵṵḽḽ ṗṓṓẁḛḛṛ ṓṓϝ ṽṓṓḭḭͼḛḛ ḭḭṇṭḛḛḽḽḭḭḡḛḛṇͼḛḛ ϝḛḛααṭṵṵṛḛḛṡ, ", _interpolate(_named("link")), ".龍ผู้]"])}
      },
      "manual_cancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼααṇͼḛḛḽ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ, ͼṓṓṇṭααͼṭ ṵṵṡ ", _interpolate(_named("link")), " ṓṓṛ ͼḥααṭ ẁḭḭṭḥ ṵṵṡ.龍ผู้]"])},
      "plan_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵααḽḭḭṭẏẏ ṃααṇααḡḛḛṃḛḛṇṭ ṗḽααṇ龍ผู้]"])},
      "quality_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵααḽḭḭṭẏẏ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "add_credit_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ͼṛḛḛḍḭḭṭ ͼααṛḍ龍ผู้]"])},
      "all_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṗḽααṇṡ龍ผู้]"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḭḭṇḡ龍ผู้]"])},
      "billing_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ḅḭḭḽḽḭḭṇḡ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ龍ผู้]"])},
      "billing_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḭḭḽḽḭḭṇḡ ḭḭṇṭḛḛṛṽααḽ龍ผู้]"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "cancel_subscription_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ẁḭḭḽḽ ḅḛḛ ͼααṇͼḛḛḽḽḛḛḍ ααṭ ṭḥḛḛ ḛḛṇḍ ṓṓϝ ṭḥḛḛ ḅḭḭḽḽḭḭṇḡ ṗḛḛṛḭḭṓṓḍ.龍ผู้]"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ ṵṵṡ龍ผู้]"])},
      "contact_us_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ ṵṵṡ ṭṓṓ ḛḛḍḭḭṭ龍ผู้]"])},
      "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭ ṗḽααṇ龍ผู้]"])},
      "custom_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṗṛḭḭͼḭḭṇḡ龍ผู้]"])},
      "downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓẁṇḡṛααḍḛḛ龍ผู้]"])},
      "dropdown_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṇṵṵααḽ (Ṣααṽḛḛ 15%)龍ผู้]"])},
      "dropdown_annual_old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṇṵṵααḽ龍ผู้]"])},
      "dropdown_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ龍ผู้]"])},
      "duration_toggle": {
        "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααẏẏ ααṇṇṵṵααḽḽẏẏ龍ผู้]"])},
        "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ龍ผู้]"])}
      },
      "edit_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "explore_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽṓṓṛḛḛ ṗḽααṇṡ龍ผู้]"])},
      "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḛḛḛḛ ṭṛḭḭααḽ龍ผู้]"])},
      "invoiced_manually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽṓṓḭḭͼḛḛḍ ṃααṇṵṵααḽḽẏẏ龍ผู้]"])},
      "modal_review_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṛḛḛṽḭḭḛḛẁ ααṇḍ ͼṓṓṇϝḭḭṛṃ ṭḥḛḛ ϝḭḭṇααḽ ṓṓṛḍḛḛṛ ṡṵṵṃṃααṛẏẏ ḭḭṇ ṭḥḛḛ ṇḛḛẋṭ ṡṭḛḛṗ.龍ผู้]"])},
      "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ṗṓṓṗṵṵḽααṛ龍ผู้]"])},
      "no_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḍṓṓṇ'ṭ ḥααṽḛḛ ααṇ ααͼṭḭḭṽḛḛ ṗααẏẏṃḛḛṇṭ ṃḛḛṭḥṓṓḍ.龍ผู้]"])},
      "on_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇ ṭṛḭḭααḽ龍ผู้]"])},
      "pause_readonly_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṵṵṃḛḛ ẏẏṓṓṵṵṛ ͼṵṵṛṛḛḛṇṭ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ͼḥααṇḡḛḛ ṭḥḛḛ ṗḽααṇ龍ผู้]"])},
      "pause_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "period": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ龍ผู้]"])},
        "quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵααṛṭḛḛṛḽẏẏ龍ผู้]"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṇṵṵααḽ龍ผู้]"])}
      },
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭͼḛḛ龍ผู้]"])},
      "reactivate_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααͼṭḭḭṽααṭḛḛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "resume_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṵṵṃḛḛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "seats_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṭṡ龍ผู้]"])},
      "start_trial_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ ṭṛḭḭααḽ ṇṓṓẁ龍ผู้]"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽḽḛḛḍ龍ผู้]"])},
        "non_renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṇ-ṛḛḛṇḛḛẁḭḭṇḡ龍ผู้]"])},
        "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛḍ龍ผู้]"])}
      },
      "subscription_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ṡṭααṭṵṵṡ龍ผู้]"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛḭḭααḽ龍ผู้]"])},
      "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ龍ผู้]"])},
      "upgrade_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])}
    },
    "survey": {
      "create": {
        "type": {
          "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈḚḚṢ龍ผู้]"])},
          "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
          "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ龍ผู้]"])}
        },
        "draft": {
          "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḽḛḛααṡḛḛ ṭααḳḛḛ ṓṓṇḛḛ ṃḭḭṇṵṵṭḛḛ ṭṓṓ ṗṛṓṓṽḭḭḍḛḛ ϝḛḛḛḛḍḅααͼḳ ṓṓṇ ẏẏṓṓṵṵṛ ͼṵṵṡṭṓṓṃḛḛṛ ṡṵṵṗṗṓṓṛṭ ḛḛẋṗḛḛṛḭḭḛḛṇͼḛḛ. Ṫḥααṇḳṡ ḭḭṇ ααḍṽααṇͼḛḛ — ", "{", "{", "ααḡḛḛṇṭṄααṃḛḛ", "}", "}", " ṽααḽṵṵḛḛṡ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ!龍ผู้]"])},
          "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭ ", "{", "{", "ͼṵṵṡṭṓṓṃḛḛṛṄααṃḛḛ", "}", "}", " 👋!龍ผู้]"])},
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "'ṡ ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ẁṓṓṵṵḽḍ ẏẏṓṓṵṵ ṛααṭḛḛ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ẏẏṓṓṵṵ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝṛṓṓṃ ", "{", "{", "ααḡḛḛṇṭṄααṃḛḛ", "}", "}", "?龍ผู้]"])},
          "thank_you_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ŵḛḛ ṛḛḛααḽḽẏẏ ααṗṗṛḛḛͼḭḭααṭḛḛ ḭḭṭ. Ŵḛḛ'ḽḽ ṵṵṡḛḛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ ṭṓṓ ṃααḳḛḛ ", _interpolate(_list(0)), " ḅḛḛṭṭḛḛṛ ϝṓṓṛ ḛḛṽḛḛṛẏẏṓṓṇḛḛ.龍ผู้]"])}
        },
        "link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṡṵṵṛṽḛḛẏẏṡ龍ผู้]"])},
        "template_modal": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ṭẏẏṗḛḛ龍ผู้]"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼααṇ ḅḛḛ ͼḥααṇḡḛḛḍ ḽααṭḛḛṛ.龍ผู้]"])}
        },
        "description_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛααṡṵṵṛḛḛ ααṇḍ ḭḭṃṗṛṓṓṽḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ṡααṭḭḭṡϝααͼṭḭḭṓṓṇ ẁḭḭṭḥ ααṵṵṭṓṓṃααṭḛḛḍ ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏṡ ḭḭṇ ṃṵṵḽṭḭḭṗḽḛḛ ḽααṇḡṵṵααḡḛḛṡ.龍ผู้]"])},
        "description_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵṛṽḛḛẏẏṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "new_survey_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "survey_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṇααṃḛḛ龍ผู้]"])},
        "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ͼṛḛḛααṭḛḛḍ龍ผู้]"])}
      },
      "edit": {
        "add_language_modal": {
          "not_found": {
            "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ͼḥααṇḡḭḭṇḡ ṭḥḛḛ ḳḛḛẏẏẁṓṓṛḍ ṓṓṛ ", _interpolate(_named("contact_us")), " ḭḭϝ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ ṭṓṓ ṡḛḛḛḛ ḭḭṭ ḽḭḭṡṭḛḛḍ ḥḛḛṛḛḛ龍ผู้]"])},
            "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḽḛḛṭ ṵṵṡ ḳṇṓṓẁ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḽααṇḡṵṵααḡḛḛ ϝṓṓṵṵṇḍ龍ผู้]"])}
          },
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṓṓṇḛḛ ṓṓṛ ṃṓṓṛḛḛ ḽααṇḡṵṵααḡḛḛṡ ϝṛṓṓṃ ṭḥḛḛ ḽḭḭṡṭ ḅḛḛḽṓṓẁ. ", _interpolate(_named("contact_us")), " ḭḭϝ ẏẏṓṓṵṵ ḍṓṓṇ'ṭ ṡḛḛḛḛ αα ḽααṇḡṵṵααḡḛḛ ẏẏṓṓṵṵ'ḍ ḽḭḭḳḛḛ ṭṓṓ ṵṵṡḛḛ ḽḭḭṡṭḛḛḍ ḥḛḛṛḛḛ.龍ผู้]"])},
          "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḽααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
          "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛṭ ṵṵṡ ḳṇṓṓẁ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])}
        },
        "last_published": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṗṵṵḅḽḭḭṡḥḛḛḍ ", _interpolate(_list(0)), "龍ผู้]"])},
        "last_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṡααṽḛḛḍ ", _interpolate(_list(0)), "龍ผู้]"])},
        "review_translations_modal": {
          "sections": {
            "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("type")), " ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "intro_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭṛṓṓ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "left_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛϝṭ ṡͼααḽḛḛ ḽααḅḛḛḽ龍ผู้]"])},
            "mail_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ṡṵṵḅĵḛḛͼṭ龍ผู้]"])},
            "reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
            "right_scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḭḭḡḥṭ ṡͼααḽḛḛ ḽααḅḛḛḽ龍ผู้]"])},
            "thank_you_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳ ẏẏṓṓṵṵ龍ผู้]"])}
          },
          "missing_text_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ αα ṡṵṵṛṽḛḛẏẏ ḥααṡ ααṇẏẏ ṃḭḭṡṡḭḭṇḡ ṭṛααṇṡḽααṭḭḭṓṓṇṡ, ḅααṡḛḛ ḽααṇḡṵṵααḡḛḛ’ṡ ṭḛḛẋṭṡ ẁḭḭḽḽ ḅḛḛ ṡḥṓṓẁṇ ḭḭṇṡṭḛḛααḍ龍ผู้]"])},
          "apply_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗḽẏẏ ṭṛααṇṡḽααṭḭḭṓṓṇṡ龍ผู้]"])},
          "missing_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭṡṡḭḭṇḡ ṭṛααṇṡḽααṭḭḭṓṓṇṡ龍ผู้]"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ṭṓṓ ṛḛḛṽḭḭḛḛẁ ααḽḽ ẏẏṓṓṵṵṛ ṭṛααṇṡḽααṭḭḭṓṓṇṡ ḅḛḛϝṓṓṛḛḛ ααṗṗḽẏẏḭḭṇḡ ṭḥḛḛṃ. Ṁααḳḛḛ ͼḥααṇḡḛḛṡ ḭḭṇ ṭḥḛḛ ḍṓṓẁṇḽṓṓααḍḛḛḍ ϝḭḭḽḛḛ, ṡααṽḛḛ ḭḭṭ, ααṇḍ ṛḛḛ-ṵṵṗḽṓṓααḍ ḭḭϝ ṇḛḛḛḛḍḛḛḍ. ḬḬϝ αα ṭṓṓḳḛḛṇ ", _interpolate(_named("token_example")), " ḍṓṓḛḛṡ ṇṓṓṭ ααṗṗḛḛααṛ ααṡ αα ṭααḡ, ḭḭṭ'ṡ ḛḛḭḭṭḥḛḛṛ ḭḭṇ ααṇ ḭḭṇͼṓṓṛṛḛḛͼṭ ϝṓṓṛṃααṭ ṓṓṛ ḭḭṇ αα ϝḭḭḛḛḽḍ ṭḥααṭ ḍṓṓḛḛṡ ṇṓṓṭ ṡṵṵṗṗṓṓṛṭ ṭṓṓḳḛḛṇṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭṛααṇṡḽααṭḭḭṓṓṇṡ龍ผู้]"])},
          "translated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡḽααṭḛḛḍ龍ผู้]"])}
        },
        "sections": {
          "content": {
            "ai_assist": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṃṃααṛḭḭẓḛḛṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ṡḥṓṓẁṡ ṡṵṵḡḡḛḛṡṭḭḭṓṓṇṡ ϝṓṓṛ ͼṓṓṃṃḛḛṇṭṡ.龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ḀḀḬḬ ααṡṡḭḭṡṭ (ḭḭṇ ḚḚṇḡḽḭḭṡḥ)龍ผู้]"])}
            },
            "define_type": {
              "no_types_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ḛḛḍḭḭṭ ṭẏẏṗḛḛṡ ṓṓṇͼḛḛ ṭḥḛḛẏẏ'ṽḛḛ ḅḛḛḛḛṇ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
              "duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṭẏẏṗḛḛ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ. Ṣḛḛḽḛḛͼṭ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ṇααṃḛḛ.龍ผู้]"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ṭḥḛḛ ͼṵṵṡṭṓṓṃ ṭẏẏṗḛḛ ααṡ αα ṡḛḛḽḛḛͼṭααḅḽḛḛ ṓṓṗṭḭḭṓṓṇ ḭḭṇ ṭḥḛḛ Ṣṵṵṛṽḛḛẏẏ ṭẏẏṗḛḛ ḍṛṓṓṗḍṓṓẁṇ, ααṇḍ ḡṛṓṓṵṵṗ ααḽḽ ͼṛḛḛααṭḛḛḍ ṡṵṵṛṽḛḛẏẏṡ ṓṓṇ ṭḥḛḛ ḍααṡḥḅṓṓααṛḍ.龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ ḭḭṇ ẁṓṓṛḍ(ṡ) ααṇḍ ṗṛḛḛṡṡ ḚḚṇṭḛḛṛ ṭṓṓ ṡααṽḛḛ龍ผู้]"])},
              "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ͼṵṵṡṭṓṓṃ ṭẏẏṗḛḛṡ龍ผู้]"])},
              "empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ ṇααṃḛḛ ͼααṇ'ṭ ḅḛḛ ḛḛṃṗṭẏẏ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝḭḭṇḛḛ ṭẏẏṗḛḛ龍ผู้]"])},
              "not_saved_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṭẏẏṗḛḛ ẁααṡ ṇṓṓṭ ṡααṽḛḛḍ. Ṕṛḛḛṡṡ ḚḚṇṭḛḛṛ ṭṓṓ ṡααṽḛḛ ḭḭṭ.龍ผู้]"])}
            },
            "senders_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍḛḛṛ'ṡ ḛḛṃααḭḭḽ龍ผู้]"])},
            "senders_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍḛḛṛ'ṡ ṇααṃḛḛ龍ผู้]"])},
            "ces": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ẁḥααṭ ḛḛẋṭḛḛṇṭ ḍṓṓ ẏẏṓṓṵṵ ααḡṛḛḛḛḛ ẁḭḭṭḥ ṭḥḛḛ ṡṭααṭḛḛṃḛḛṇṭ: “", _interpolate(_named("agentName")), " ṃααḍḛḛ ḭḭṭ ḛḛααṡẏẏ ṭṓṓ ḥααṇḍḽḛḛ ṃẏẏ ḭḭṡṡṵṵḛḛ”?龍ผู้]"])},
                "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ḛḛααṡẏẏ ẁααṡ ḡḛḛṭṭḭḭṇḡ ḥḛḛḽṗ ϝṛṓṓṃ ", _interpolate(_named("brandName")), " ṡṓṓ ϝααṛ?龍ผู้]"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛααḽḽ, ḥṓṓẁ ḛḛααṡẏẏ ẁααṡ ḭḭṭ ṭṓṓ ṡṓṓḽṽḛḛ ẏẏṓṓṵṵṛ ṗṛṓṓḅḽḛḛṃ ṭṓṓḍααẏẏ?龍ผู้]"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ḛḛααṡẏẏ ẁααṡ ḭḭṭ ṭṓṓ ḭḭṇṭḛḛṛααͼṭ ẁḭḭṭḥ ṓṓṵṵṛ ṭḛḛααṃ?龍ผู้]"])}
              }
            },
            "privacy_policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααͼẏẏ Ṕṓṓḽḭḭͼẏẏ ṲṲṚḺ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
            "tos_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṛṃṡ ṓṓϝ Ṣḛḛṛṽḭḭͼḛḛ ṲṲṚḺ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
            "csat": {
              "questions": {
                "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ẁṓṓṵṵḽḍ ẏẏṓṓṵṵ ṛααṭḛḛ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ẏẏṓṓṵṵ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝṛṓṓṃ ", _interpolate(_named("agentName")), "?龍ผู้]"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ẁααṡ ṓṓṵṵṛ ṡḛḛṛṽḭḭͼḛḛ ϝṓṓṛ ṭḥḭḭṡ ṛḛḛʠṵṵḛḛṡṭ?龍ผู้]"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ṡααṭḭḭṡϝḭḭḛḛḍ ẁḛḛṛḛḛ ẏẏṓṓṵṵ ẁḭḭṭḥ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ṛḛḛṗṛḛḛṡḛḛṇṭααṭḭḭṽḛḛ?龍ผู้]"])},
                "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḥṓṓẁ ṡααṭḭḭṡϝḭḭḛḛḍ ααṛḛḛ ẏẏṓṓṵṵ ẁḭḭṭḥ ṭḥḛḛ ṡḛḛṛṽḭḭͼḛḛ ẏẏṓṓṵṵ ṛḛḛͼḛḛḭḭṽḛḛḍ ϝṛṓṓṃ ", _interpolate(_named("agentName")), " ṭṓṓḍααẏẏ?龍ผู้]"])}
              }
            },
            "custom_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "custom_question_option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝḭḭṇḛḛ ẏẏṓṓṵṵṛ ṓṓẁṇ ͼṵṵṡṭṓṓṃ ", _interpolate(_named("type")), " ʠṵṵḛḛṡṭḭḭṓṓṇ...龍ผู้]"])},
            "custom_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ ḭḭṇ ͼṵṵṡṭṓṓṃ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "customize_urls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓḛḛ ṲṲṚḺṡ龍ผู้]"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
            "edit_types": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭḭḭṇḡ ṭḥḛḛ ṇααṃḛḛ ṓṓϝ αα ͼṵṵṡṭṓṓṃ ʠṵṵḛḛṡṭḭḭṓṓṇ ṭẏẏṗḛḛ ẁḭḭḽḽ ṵṵṗḍααṭḛḛ ḭḭṭ ḭḭṇ ααḽḽ ṡṵṵṛṽḛḛẏẏṡ ṵṵṡḭḭṇḡ ḭḭṭ, ẁḭḭṭḥṓṓṵṵṭ ααϝϝḛḛͼṭḭḭṇḡ ṭḥḛḛ ͼṓṓḽḽḛḛͼṭḛḛḍ ḍααṭαα.龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ͼṵṵṡṭṓṓṃ ṭẏẏṗḛḛṡ龍ผู้]"])}
            },
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
            "email_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ṡṵṵḅĵḛḛͼṭ龍ผู้]"])},
            "intro_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭṛṓṓ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "question_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("type")), " ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
            "survey_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ龍ผู้]"])},
            "survey_types": {
              "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈḚḚṢ龍ผู้]"])},
              "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
              "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṇḛḛẁ ͼṵṵṡṭṓṓṃ ṭẏẏṗḛḛ龍ผู้]"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḛḛṇṭ龍ผู้]"])},
            "tokens": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽḭḭẓααṭḭḭṓṓṇ ṭṓṓḳḛḛṇ"]), _normalize(["Ṕḛḛṛṡṓṓṇααḽḭḭẓααṭḭḭṓṓṇ ṭṓṓḳḛḛṇṡ龍ผู้]"])])},
            "tokens_description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḍṛααḡ ααṇḍ ḍṛṓṓṗ ṭḥḛḛ ṗḛḛṛṡṓṓṇααḽḭḭẓααṭḭḭṓṓṇ ṭṓṓḳḛḛṇ ḭḭṇṭṓṓ ṭḥḛḛ ϝḭḭḛḛḽḍ ḅḛḛḽṓṓẁ."]), _normalize(["Ḍṛααḡ ααṇḍ ḍṛṓṓṗ ṭḥḛḛ ṗḛḛṛṡṓṓṇααḽḭḭẓααṭḭḭṓṓṇ ṭṓṓḳḛḛṇṡ ḭḭṇṭṓṓ ṭḥḛḛ ϝḭḭḛḛḽḍṡ ḅḛḛḽṓṓẁ.龍ผู้]"])])}
          },
          "delivery": {
            "conversation_rule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ", _interpolate(_list(0)), "龍ผู้]"])},
            "deliver_via_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭḽẏẏ ḭḭṭ'ṡ ṓṓṇḽẏẏ ṗṓṓṡṡḭḭḅḽḛḛ ṭṓṓ ṡḛḛṇḍ ṓṓṵṵṭ ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏṡ ṽḭḭαα ḛḛṃααḭḭḽ. Ŵḛḛ ααṛḛḛ ẁṓṓṛḳḭḭṇḡ ṭṓṓ ḅṛḭḭṇḡ ẏẏṓṓṵṵ ṃṓṓṛḛḛ ṓṓṗṗṓṓṛṭṵṵṇḭḭṭḭḭḛḛṡ ṡṵṵͼḥ ααṡ ḥḛḛḽṗ ḍḛḛṡḳ ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏṡ, ṢṀṢ ṡṵṵṛṽḛḛẏẏṡ ααṇḍ ṃṓṓṛḛḛ.龍ผู้]"])},
            "errors": {
              "connection_disabled_action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣẏẏṇͼ ṓṓṛ ṛḛḛṇḛḛẁ ṭḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ"]), _normalize(["Ṣẏẏṇͼ ṓṓṛ ṛḛḛṇḛḛẁ ṭḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])])},
              "connection_disabled_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẏẏṇͼ ṓṓṛ ṛḛḛṇḛḛẁ ṭḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ↗龍ผู้]"])},
              "connection_disabled_text": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ḭḭṡ ṇṓṓṭ ṡẏẏṇͼḛḛḍ ͼṓṓṛṛḛḛͼṭḽẏẏ"]), _normalize(["Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ααṛḛḛ ṇṓṓṭ ṡẏẏṇͼḛḛḍ ͼṓṓṛṛḛḛͼṭḽẏẏ.龍ผู้]"])])},
              "connection_masked_data_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṇͼḥḛḛͼḳ ṭḥḛḛ ṛḛḛḽḛḛṽααṇṭ ͼḥḛḛͼḳḅṓṓẋ ↗龍ผู้]"])},
              "connection_permissions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḛḛ ṇṓṓẁ ↗龍ผู้]"])},
              "connection_no_rules_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṭ ḽḛḛααṡṭ ṓṓṇḛḛ ṡṵṵṛṽḛḛẏẏ ṡḛḛṇḍ ṓṓṵṵṭ ṛṵṵḽḛḛ ṭṓṓ ααͼṭḭḭṽααṭḛḛ ṭḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
              "connection_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ḭḭṡ ͼṵṵṛṛḛḛṇṭḽẏẏ ḍḭḭṡααḅḽḛḛḍ. ", _interpolate(_named("link")), "龍ผู้]"])},
              "connection_masked_data": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ṃααṡḳṡ ṡḛḛṇṡḭḭṭḭḭṽḛḛ ͼḽḭḭḛḛṇṭ ḍααṭαα. Ṫṓṓ ḛḛṇααḅḽḛḛ ṡṵṵṛṽḛḛẏẏ ṡḛḛṇḍ-ṓṓṵṵṭ,\n", _interpolate(_named("link")), " ḭḭṇ ṭḥḛḛ ṡḛḛṭṭḭḭṇḡṡ.龍ผู้]"])},
              "connection_permissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ṛḛḛʠṵṵḭḭṛḛḛṡ ααḍḍḭḭṭḭḭṓṓṇααḽ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ṭṓṓ ṡḛḛṇḍ ṓṓṵṵṭ ṡṵṵṛṽḛḛẏẏṡ.\n", _interpolate(_named("link")), "龍ผู้]"])},
              "intercom_reauth_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḛḛ ṇṓṓẁ龍ผู้]"])},
              "intercom_reauth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ṛḛḛʠṵṵḭḭṛḛḛṡ ṃṓṓṛḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ϝṓṓṛ ḬḬṇṭḛḛṛͼṓṓṃ.龍ผู้]"])}
            },
            "follow_up_duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍(", _interpolate(_named("hours")), " ααṇḍ ", _interpolate(_named("minutes")), ")龍ผู้]"])},
            "follow_up_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḥṓṓṵṵṛ"]), _normalize([_interpolate(_named("n")), " ḥṓṓṵṵṛṡ龍ผู้]"])])},
            "follow_up_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṃḭḭṇṵṵṭḛḛṡ"]), _normalize(["1 ṃḭḭṇṵṵṭḛḛ"]), _normalize([_interpolate(_named("n")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])])},
            "follow_up_unit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḥṓṓṵṵṛ"]), _normalize(["ḥṓṓṵṵṛṡ龍ผู้]"])])},
            "intercom_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("link")), " ṭṓṓ ͼṓṓṇϝḭḭḡṵṵṛḛḛ ṡṵṵṛṽḛḛẏẏ ṡḛḛṇḍḭḭṇḡ ṛṵṵḽḛḛṡ.龍ผู้]"])},
            "links_expire": {
              "days": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍 ḍααẏẏ"]), _normalize(["ḍααẏẏṡ龍ผู้]"])])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḽḭḭṇḳṡ ḛḛẋṗḭḭṛḛḛ龍ผู้]"])},
              "expire_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṇḳṡ ḛḛẋṗḭḭṛḛḛ ḭḭṇ龍ผู้]"])},
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḛḛṇṭ ααṇṡẁḛḛṛḭḭṇḡ ṡṵṵṛṽḛḛẏẏṡ ḭḭϝ ṭṓṓṓṓ ṃṵṵͼḥ ṭḭḭṃḛḛ ḥααṡ ṗααṡṡḛḛḍ龍ผู้]"])}
            },
            "modal": {
              "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṇḍ龍ผู้]"])},
              "validation_error_link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("name")), " ṡṵṵṛṽḛḛẏẏ ↗龍ผู้]"])},
              "brand_domain_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṗααṛααṭḛḛ ṭḥḛḛ ṭααḡṡ ẁḭḭṭḥ ͼṓṓṃṃααṡ ṓṓṛ ṗṛḛḛṡṡ ḛḛṇṭḛḛṛ ααϝṭḛḛṛ ẁṛḭḭṭḭḭṇḡ. Ṁααḳḛḛ ṡṵṵṛḛḛ ḛḛααͼḥ ṓṓṇḛḛ ḭḭṡ ḽḭḭṡṭḛḛḍ ṗṛḛḛͼḭḭṡḛḛḽẏẏ ααṡ ḭḭṇ ḬḬṇṭḛḛṛͼṓṓṃ.龍ผู้]"])},
              "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṭḥḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ ḅααṡḛḛḍ ṓṓṇ ẁḥḭḭͼḥ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ḭḭṡ ṡḛḛṇṭ ṓṓṵṵṭ.龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṡḛḛṇḍ ṓṓṵṵṭ ṛṵṵḽḛḛṡ龍ผู้]"])},
              "title_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝṓṓḽḽṓṓẁ-ṵṵṗ ṡḛḛṇḍ ṓṓṵṵṭ ṛṵṵḽḛḛṡ龍ผู้]"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀ ṡṵṵṛṽḛḛẏẏ ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ.\nṚḛḛṡṓṓḽṽḛḛ ṭḥḛḛ ͼṓṓṇϝḽḭḭͼṭ ḅẏẏ ṃṓṓḍḭḭϝẏẏḭḭṇḡ ṭḥḛḛ ṛṵṵḽḛḛṡ ḛḛḭḭṭḥḛḛṛ ḥḛḛṛḛḛ ṓṓṛ ḭḭṇ ṭḥḛḛ ", _interpolate(_named("link")), ".龍ผู้]"])},
              "validation_error_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ααḽṛḛḛααḍẏẏ ṡḛḛṭ ṵṵṗ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ẁḭḭṭḥ ṃααṭͼḥḭḭṇḡ ṛṵṵḽḛḛṡ ϝṓṓṛ ", _interpolate(_named("name")), " ṡṵṵṛṽḛḛẏẏ. Ṛḛḛṡṓṓḽṽḛḛ ṭḥḛḛ ͼṓṓṇϝḽḭḭͼṭ ḅẏẏ ṃṓṓḍḭḭϝẏẏḭḭṇḡ ṭḥḛḛ ṛṵṵḽḛḛṡ ḛḛḭḭṭḥḛḛṛ ḥḛḛṛḛḛ ṓṓṛ ḭḭṇ ṭḥḛḛ ", _interpolate(_named("link")), "龍ผู้]"])},
              "add_another_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṇṓṓṭḥḛḛṛ ṛṵṵḽḛḛ龍ผู้]"])},
              "brand_domain_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḅṛααṇḍ龍ผู้]"])},
              "condition_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝḭḭṛṃ龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ αα ṽααḽṵṵḛḛ龍ผู้]"])},
              "tag_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭααḡ龍ผู้]"])},
              "validation_error_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṃααṭͼḥḭḭṇḡ ṭḥḛḛ ṡααṃḛḛ ṛṵṵḽḛḛṡ龍ผู้]"])},
              "values_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ṽααḽṵṵḛḛ龍ผู้]"])}
            },
            "email_delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ṡḛḛṇṭ ṓṓṵṵṭ ḭḭṇ龍ผู้]"])},
            "email_delay_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ϝṓṓḽḽṓṓẁ-ṵṵṗ ṡḛḛṇṭ ṓṓṵṵṭ ḭḭṇ龍ผู้]"])},
            "embedded_snippet": {
              "delivery_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃḅḛḛḍḍḛḛḍ ṡṇḭḭṗṗḛḛṭ ααṇḍ ḛḛṃααḭḭḽ ϝṓṓḽḽṓṓẁ ṵṵṗ龍ผู้]"])},
              "copy_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ͼṓṓḍḛḛ龍ผู้]"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṭṭααͼḥ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṡṵṵṛṽḛḛẏẏṡ ṭṓṓ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽṡ ḅẏẏ ααḍḍḭḭṇḡ ṭḥḛḛ ṡṇḭḭṗṗḛḛṭ ͼṓṓḍḛḛ ṭṓṓ ẏẏṓṓṵṵṛ Żḛḛṇḍḛḛṡḳ ṡḛḛṭṵṵṗ.龍ผู้]"])},
              "installation_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṡṭααḽḽααṭḭḭṓṓṇ ḡṵṵḭḭḍḛḛ龍ผู้]"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
              "open_zendesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ Żḛḛṇḍḛḛṡḳ龍ผู้]"])},
              "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
              "preview_modal": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṇḭḭṗṗḛḛṭ ṗṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
              },
              "snippet_copied_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṇḭḭṗṗḛḛṭ ͼṓṓḍḛḛ ͼṓṓṗḭḭḛḛḍ ṭṓṓ ͼḽḭḭṗḅṓṓααṛḍ龍ผู้]"])},
              "snippet_copy_error_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṇḭḭṗṗḛḛṭ ͼṓṓṗẏẏḭḭṇḡ ϝααḭḭḽḛḛḍ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃḅḛḛḍḍḛḛḍ ṡṇḭḭṗṗḛḛṭ龍ผู้]"])},
              "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṡṵṵṛṽḛḛẏẏ ḥααṡ ṵṵṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ. Ṣααṽḛḛ ḭḭṭ ααḡααḭḭṇ ṭṓṓ ḛḛṇṡṵṵṛḛḛ ṭḥḛḛ ṡṇḭḭṗṗḛḛṭ ͼṓṓḍḛḛ ṛḛḛϝḽḛḛͼṭṡ ṭḥḛḛ ḽααṭḛḛṡṭ ṵṵṗḍααṭḛḛṡ.龍ผู้]"])}
            },
            "empty_state": {
              "add_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ααḍḍḛḛḍ, ẏẏḛḛṭ龍ผู้]"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḽḽ ḛḛṃααḭḭḽ, ḽḭḭṽḛḛ ͼḥααṭ, ṗḥṓṓṇḛḛ ͼααḽḽṡ, ααṇḍ ṃṓṓṛḛḛ ḭḭṇṭṓṓ Żḛḛṇḍḛḛṡḳ ǪḀḀ ẁḭḭṭḥ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])}
            },
            "intercom_help_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṡṵṵṛṽḛḛẏẏ ḍḛḛḽḭḭṽḛḛṛẏẏ龍ผู้]"])},
            "when_to_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ṡḛḛṇḍ ṓṓṵṵṭ ṛṵṵḽḛḛṡ龍ผู้]"])},
            "when_to_send_follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝṓṓḽḽṓṓẁ-ṵṵṗ ṡḛḛṇḍ ṓṓṵṵṭ ṛṵṵḽḛḛṡ龍ผู้]"])},
            "add_brand_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḅṛααṇḍ ṛṵṵḽḛḛ龍ผู้]"])},
            "add_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṵṵḽḛḛ龍ผู้]"])},
            "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭḥḛḛṇṭḭḭͼααṭḛḛ龍ผู้]"])},
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṡṡḛḛṇḡḛḛṛ + ḛḛṃααḭḭḽ ϝṓṓḽḽṓṓẁ ṵṵṗ龍ผู้]"])},
            "connect_intercom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ẏẏṓṓṵṵṛ ḬḬṇṭḛḛṛͼṓṓṃ ααͼͼṓṓṵṵṇṭ ẁḭḭṭḥ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḈṢḀḀṪ Ṣṵṵṛṽḛḛẏẏ龍ผู้]"])},
            "deliver_via": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḭḭṽḛḛṛ ṽḭḭαα龍ผู้]"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥṓṓ ḡḛḛṭṡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ααṇḍ ẁḥḛḛṇ龍ผู้]"])},
            "edit_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṛṵṵḽḛḛṡ龍ผู้]"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
            "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇααḅḽḛḛḍ龍ผู้]"])},
            "intercom_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇ-ααṗṗ ṡṵṵṛṽḛḛẏẏṡ ḛḛṇααḅḽḛḛ ẏẏṓṓṵṵṛ ͼṵṵṡṭṓṓṃḛḛṛṡ ṭṓṓ ͼṓṓṃṗḽḛḛṭḛḛ αα ṡṵṵṛṽḛḛẏẏ ẁḭḭṭḥṓṓṵṵṭ ḽḛḛααṽḭḭṇḡ ṭḥḛḛ ͼḥααṭ.龍ผู้]"])},
            "intercom_rules_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṵṵṇͼḥ ḬḬṇṭḛḛṛͼṓṓṃ龍ผู้]"])},
            "list": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḭḭṽḛḛṛẏẏ ṛṵṵḽḛḛṡ ṗḛḛṛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
              "validation_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀ ṡṵṵṛṽḛḛẏẏ ", _interpolate(_named("name")), " ", _interpolate(_named("rules")), " ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ.\nṚḛḛṡṓṓḽṽḛḛ ṭḥḛḛ ͼṓṓṇϝḽḭḭͼṭ ḅẏẏ ḍḭḭṡααḅḽḭḭṇḡ ṭḥḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ṓṓṛ ṃṓṓḍḭḭϝẏẏḭḭṇḡ ṭḥḛḛ ṛṵṵḽḛḛṡ ḛḛḭḭṭḥḛḛṛ ḥḛḛṛḛḛ ṓṓṛ ḭḭṇ ṭḥḛḛ ", _interpolate(_named("link")), "龍ผู้]"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḭḭṽḛḛṛẏẏ龍ผู้]"])},
            "when_to_send_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṭḥḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ ḅααṡḛḛḍ ṓṓṇ ẁḥḭḭͼḥ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ḭḭṡ ṡḛḛṇṭ ṓṓṵṵṭ龍ผู้]"])}
          },
          "languages": {
            "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ḅẏẏ ", _interpolate(_named("name")), " ṓṓṇ ", _interpolate(_named("createdDate")), "龍ผู้]"])},
            "remove_translation_modal": {
              "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("languageName")), "龍ผู้]"])},
              "message_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḭḭṇḡ ṭḥḭḭṡ ḽααṇḡṵṵααḡḛḛ ṃḛḛααṇṡ ṭḥααṭ ḭḭṭ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ṽḭḭṡḭḭḅḽḛḛ ṭṓṓ ααṇẏẏ ṛḛḛṡṗṓṓṇḍḛḛṇṭṡ ααṇẏẏṃṓṓṛḛḛ ααṇḍ ααḽḽ ṭḥḛḛ ṭṛααṇṡḽααṭḛḛḍ ṭḛḛẋṭṡ ααṛḛḛ ḍḛḛḽḛḛṭḛḛḍ.龍ผู้]"])},
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
              "message_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
              "message_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṭḛḛṛṇααṭḭḭṽḛḛḽẏẏ, ẏẏṓṓṵṵ ͼααṇ ḍḭḭṡααḅḽḛḛ ṭḥḛḛ ḽααṇḡṵṵααḡḛḛ ḅẏẏ ḥḭḭḍḭḭṇḡ ḭḭṭ ϝṛṓṓṃ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ. Ṫḥḭḭṡ ẁḭḭḽḽ ααḽḽṓṓẁ ẏẏṓṓṵṵ ṭṓṓ ḳḛḛḛḛṗ ααḽḽ ẏẏṓṓṵṵṛ ṭṛααṇṡḽααṭḭḭṓṓṇṡ ḭḭṇ ṗḽααͼḛḛ ẁḭḭṭḥṓṓṵṵṭ ṡḥṓṓẁḭḭṇḡ ḭḭṭ ṭṓṓ ṛḛḛṡṗṓṓṇḍḛḛṇṭṡ.龍ผู้]"])}
            },
            "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ḛḛḍḭḭṭḛḛḍ ḅẏẏ ", _interpolate(_named("name")), " ṓṓṇ ", _interpolate(_named("createdDate")), "龍ผู้]"])},
            "description": {
              "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṡṵṵṛṽḛḛẏẏ ḽααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḃḛḛϝṓṓṛḛḛ ααḍḍḭḭṇḡ ṓṓṭḥḛḛṛ ḽααṇḡṵṵααḡḛḛṡ, ṃααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ḥααṽḛḛ ϝḭḭṇααḽḭḭẓḛḛḍ ṃααḳḭḭṇḡ ͼḥααṇḡḛḛṡ ḭḭṇ ṭḥḛḛ Ṣṭẏẏḽḭḭṇḡ ααṇḍ Ḉṓṓṇṭḛḛṇṭ ṡṭḛḛṗṡ. ", _interpolate(_named("link")), "龍ผู้]"])}
            },
            "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
            "columns": {
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛ龍ผู้]"])},
              "translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḛḛḽḍṡ ṭṛααṇṡḽααṭḛḛḍ龍ผู้]"])},
              "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḽḛḛ ṭṓṓ ṛḛḛṡṗṓṓṇḍḛḛṇṭṡ龍ผู้]"])}
            },
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝααṵṵḽṭ龍ผู้]"])},
            "download_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓẁṇḽṓṓααḍ ṭṛααṇṡḽααṭḭḭṓṓṇ ϝḭḭḽḛḛ龍ผู้]"])},
            "remove_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
            "translation_complete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍 ḍṓṓẁṇḽṓṓααḍ αα ṭṛααṇṡḽααṭḭḭṓṓṇ ϝḭḭḽḛḛ龍ผู้]"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ṭṛααṇṡḽααṭḭḭṓṓṇṡ龍ผู้]"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṛααṇṡḽααṭḭḭṓṓṇ ͼṓṓṃṗḽḛḛṭḛḛ. ", _interpolate(_named("review_link")), " ṓṓṛ ", _interpolate(_named("download_link")), " ṭṓṓ ṃααḳḛḛ ααṇẏẏ ͼḥααṇḡḛḛṡ.龍ผู้]"])}
            },
            "translation_incomplete": {
              "download_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍 ḍṓṓẁṇḽṓṓααḍ αα ṭṛααṇṡḽααṭḭḭṓṓṇ ϝḭḭḽḛḛ龍ผู้]"])},
              "review_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ẁḥααṭ'ṡ ṃḭḭṡṡḭḭṇḡ龍ผู้]"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ͼṓṓṇṭḛḛṇṭ ḥααṡ ẏẏḛḛṭ ṭṓṓ ḅḛḛ ṭṛααṇṡḽααṭḛḛḍ ααṇḍ ẁḭḭḽḽ ḅḛḛ ṛḛḛṗḽααͼḛḛḍ ẁḭḭṭḥ ḚḚṇḡḽḭḭṡḥ. ", _interpolate(_named("review_link")), " ṓṓṛ ", _interpolate(_named("download_link")), " ṭṓṓ ααḍḍ ṃḭḭṡṡḭḭṇḡ ṭṛααṇṡḽααṭḭḭṓṓṇṡ.龍ผู้]"])}
            },
            "unsaved_changes_warninig": {
              "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
              "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṡṵṵṛṽḛḛẏẏ ͼṓṓṇṭααḭḭṇṡ ṵṵṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ. ", _interpolate(_named("save")), " ṭṓṓ ḍṓṓẁṇḽṓṓααḍ ṭḥḛḛ ṭṛααṇṡḽααṭḭḭṓṓṇ ϝḭḭḽḛḛ ẁḭḭṭḥ ṭḥḛḛ ḽααṭḛḛṡṭ ͼḥααṇḡḛḛṡ.龍ผู้]"])}
            },
            "upload_translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḽṓṓααḍ ṭṛααṇṡḽααṭḭḭṓṓṇ ϝḭḭḽḛḛ龍ผู้]"])}
          },
          "rating_scales": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṗṛḛḛϝḛḛṛṛḛḛḍ ṛααṭḭḭṇḡ ṡͼααḽḛḛ龍ผู้]"])},
            "reasons_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṡ ͼṵṵṡṭṓṓṃḛḛṛṡ αα ḍṛṓṓṗḍṓṓẁṇ ṭṓṓ ṡḛḛḽḛḛͼṭ αα ṛḛḛααṡṓṓṇ ϝṓṓṛ ṛααṭḭḭṇḡ龍ผู้]"])},
            "emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṓṓĵḭḭṡ龍ผู้]"])},
            "left_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛϝṭ ṡͼααḽḛḛ ṭḛḛẋṭ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
            "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛṡ龍ผู้]"])},
            "reason_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛḛḛααṡṓṓṇ龍ผู้]"])},
            "reason_validation_message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇ ͼααṇ'ṭ ḅḛḛ ḛḛṃṗṭẏẏ龍ผู้]"])},
            "reason_validation_message_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṛḛḛααṡṓṓṇ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ龍ผู้]"])},
            "reasons_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ḍṛṓṓṗḍṓṓẁṇ ϝṓṓṛ ṛḛḛααṡṓṓṇṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ṡͼṓṓṛḛḛ龍ผู้]"])},
            "reasons_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ṛḛḛααṡṓṓṇ ααṇḍ ααḍḍḭḭṇḡ ḭḭṭ ααḡααḭḭṇ ẁḭḭṭḥ ṭḥḛḛ ṡααṃḛḛ ṇααṃḛḛ ṓṓṛ ṛḛḛṇααṃḭḭṇḡ αα ṛḛḛααṡṓṓṇ ααḽẁααẏẏṡ ͼṛḛḛααṭḛḛṡ αα ṇḛḛẁ ḛḛṇṭṛẏẏ. ḀḀṡ αα ṛḛḛṡṵṵḽṭ, ḭḭṭ ḭḭṃṗααͼṭṡ ṭḥḛḛ ḍααṭαα ḍḭḭṡṗḽααẏẏḛḛḍ ṓṓṇ ẏẏṓṓṵṵṛ ḍααṡḥḅṓṓααṛḍ.龍ผู้]"])},
            "remove_reason_confirmation": {
              "confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṛḛḛααṡṓṓṇ龍ผู้]"])},
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ḍḛḛḽḛḛṭḛḛḍ ṛḛḛααṡṓṓṇ ṛḛḛṃααḭḭṇṡ ḭḭṇ ṭḥḛḛ ḍααṡḥḅṓṓααṛḍ ṛḛḛṗṓṓṛṭḭḭṇḡ ḅṵṵṭ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ṽḭḭṡḭḭḅḽḛḛ ṭṓṓ ṇḛḛẁ ṡṵṵṛṽḛḛẏẏ ṛḛḛṡṗṓṓṇḍḛḛṇṭṡ. Ṫḥḭḭṡ ααḽṡṓṓ ααṗṗḽḭḭḛḛṡ ṭṓṓ ṭḥḛḛ ṛḛḛḽααṭḛḛḍ ṭṛααṇṡḽααṭḭḭṓṓṇṡ.龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ αα ṛḛḛααṡṓṓṇ龍ผู้]"])},
              "title_with_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ αα ṛḛḛααṡṓṓṇ \"", _interpolate(_list(0)), "\"龍ผู้]"])}
            },
            "right_scale_text_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḭḭḡḥṭ ṡͼααḽḛḛ ṭḛḛẋṭ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
            "scale_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇ αα 5-ṗṓṓḭḭṇṭ ṡͼααḽḛḛ, 1 ααṇḍ 2 ααṛḛḛ ͼṓṓṇṡḭḭḍḛḛṛḛḛḍ ṇḛḛḡααṭḭḭṽḛḛ ṡͼṓṓṛḛḛṡ; 3 ḭḭṡ ṇḛḛṵṵṭṛααḽ; 4-5 ααṛḛḛ ṗṓṓṡḭḭṭḭḭṽḛḛ龍ผู้]"])},
            "scale_text_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṡͼααḽḛḛ ṭḛḛẋṭ龍ผู้]"])},
            "scale_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("type")), " ṡͼααḽḛḛ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ龍ผู้]"])}
          },
          "styling": {
            "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Żḛḛṇḍḛḛṡḳ ǪḀḀ ḭḭṡ ḭḭṃṗṓṓṛṭḭḭṇḡ ẏẏṓṓṵṵṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ. Ṫḥḭḭṡ ṗṛṓṓͼḛḛṡṡ ṃḭḭḡḥṭ ṭααḳḛḛ ṵṵṗ ṭṓṓ 24 ḥṓṓṵṵṛṡ.龍ผู้]"])},
            "brand_name": {
              "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ḅḛḛ ṵṵṡḛḛḍ ααṡ αα ṗḛḛṛṡṓṓṇααḽḭḭẓααṭḭḭṓṓṇ ṭṓṓḳḛḛṇ ṭḥṛṓṓṵṵḡḥṓṓṵṵṭ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛααṇḍ ṇααṃḛḛ龍ผู้]"])}
            },
            "buttons_and_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵṭṭṓṓṇṡ ααṇḍ ḽḭḭṇḳṡ龍ผู้]"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḭḭẓḛḛ ṭḥḛḛ ḽṓṓṓṓḳ ṓṓϝ ẏẏṓṓṵṵṛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
            "logo_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓḡṓṓ ḅααͼḳḡṛṓṓṵṵṇḍ龍ผู้]"])},
            "logo_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ĴṔḠ, ṔṄḠ ααṇḍ ḠḬḬḞ ϝṓṓṛṃααṭṡ ααṛḛḛ ṡṵṵṗṗṓṓṛṭḛḛḍ龍ผู้]"])},
            "logo_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓḡṓṓ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭẏẏḽḭḭṇḡ龍ผู้]"])},
            "upload_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḽṓṓααḍ ḽṓṓḡṓṓ龍ผู้]"])}
          },
          "thank_you": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ ṓṓϝϝ ḭḭṇ ṡṭẏẏḽḛḛ龍ผู้]"])},
            "message_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ αα ṃḛḛṡṡααḡḛḛ ṗḛḛṓṓṗḽḛḛ ẁḭḭḽḽ ṡḛḛḛḛ ααϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ ṭḥḛḛḭḭṛ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
            "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳ ẏẏṓṓṵṵ ṃḛḛṡṡααḡḛḛ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳ ẏẏṓṓṵṵ龍ผู้]"])}
          }
        },
        "errors": {
          "incorrect_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇͼṓṓṛṛḛḛͼṭ ḥḛḛẋ ͼṓṓḽṓṓṛ ͼṓṓḍḛḛ龍ผู้]"])},
          "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇͼṓṓṛṛḛḛͼṭ ḛḛṃααḭḭḽ ααḍḍṛḛḛṡṡ龍ผู้]"])},
          "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ͼααṇṇṓṓṭ ḅḛḛ ḛḛṃṗṭẏẏ龍ผู้]"])},
          "incorrect_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇͼṓṓṛṛḛḛͼṭ ṵṵṛḽ龍ผู้]"])}
        },
        "list": {
          "list_item": {
            "not_visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṽḭḭṡḭḭḅḽḛḛ:龍ผู้]"])},
            "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḽḛḛ:龍ผู้]"])}
          },
          "heading": {
            "created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṭḛḛ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
            "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
            "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ龍ผู้]"])},
            "surveys_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ ṡḛḛṇṭ龍ผู้]"])},
            "surveys_sent_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ ṡḛḛṇṭ ḭḭṇ ṭḥḛḛ ḽααṡṭ 30 ḍααẏẏṡ龍ผู้]"])}
          }
        },
        "toasts": {
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
          "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ḍṵṵṗḽḭḭͼααṭḛḛḍ龍ผู้]"])},
          "preview_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ ḛḛṃααḭḭḽ ḥααṡ ḅḛḛḛḛṇ ṡḛḛṇṭ龍ผู้]"])},
          "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ṗṵṵḅḽḭḭṡḥḛḛḍ龍ผู้]"])},
          "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ḥααṡ ḅḛḛḛḛṇ ṗααṵṵṡḛḛḍ龍ผู้]"])}
        },
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
        "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛ龍ผู้]"])},
        "pause_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
        "preview_modal": {
          "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇṭḛḛṛ ṭḥḛḛ ḛḛṃααḭḭḽ ṭṓṓ ṡḛḛṇḍ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ṗṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "email_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏṓṓṵṵṛ", "@", "ḛḛṃααḭḭḽ.ͼṓṓṃ龍ผู้]"])},
          "language_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ḽααṇḡṵṵααḡḛḛ ϝṓṓṛ ṗṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ αα ṗṛḛḛṽḭḭḛḛẁ ḛḛṃααḭḭḽ龍ผู้]"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇḍ ααṇ ḛḛẋααṃṗḽḛḛ ṡṵṵṛṽḛḛẏẏ ṭṓṓ ẏẏṓṓṵṵṛ ḛḛṃααḭḭḽ. Ṫḥḛḛ ṛααṭḭḭṇḡṡ ϝṛṓṓṃ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ṗṛḛḛṽḭḭḛḛẁ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ṡṭṓṓṛḛḛḍ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ ṭḥḛḛ ", _interpolate(_named("type")), " ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "web_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ ṡṵṵṛṽḛḛẏẏ ṗṛḛḛṽḭḭḛḛẁ ḭḭṇ ṭḥḛḛ ẁḛḛḅ龍ผู้]"])}
        },
        "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ龍ผู้]"])},
        "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṵṵṃḛḛ龍ผู้]"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ααṡ ḍṛααϝṭ龍ผู้]"])},
        "snippets": {
          "brand_name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛααṇḍ ṇααṃḛḛ龍ผู้]"])},
            "tooltip_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṛααṇḍ-ṡṗḛḛͼḭḭϝḭḭͼ ṇααṃḛḛ ͼααṇ ḅḛḛ ααḍḍḛḛḍ ḭḭṇ ṭḥḛḛ Ṣṭẏẏḽḭḭṇḡ ṡṭḛḛṗ龍ผู้]"])}
          },
          "customer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ṇααṃḛḛ龍ผู้]"])},
          "support_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṗṗṓṓṛṭ ḬḬḌ龍ผู้]"])},
          "support_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṗṗṓṓṛṭ ṛḛḛṗ龍ผู้]"])}
        },
        "statuses": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
          "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭ龍ผู้]"])},
          "paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛḍ龍ผู้]"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "'ṡ ḈṢḀḀṪ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ ṃααḍḛḛ龍ผู้]"])},
        "update_and_resume_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ααṇḍ ṛḛḛṡṵṵṃḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])}
      },
      "preview": {
        "open_in_web": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ααṛḛḛ ṇṓṓṭ ṡḛḛḛḛḭḭṇḡ ṭḥḭḭṡ ḛḛṃααḭḭḽ ͼṓṓṛṛḛḛͼṭḽẏẏ ", _interpolate(_named("link")), "龍ผู้]"])},
        "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬ ẁααṡṇ'ṭ ḥααṗṗẏẏ ẁḭḭṭḥ ṭḥḛḛ ṡṵṵṗṗṓṓṛṭ ṭḥααṭ ḬḬ ḡṓṓṭ, ḅḛḛͼααṵṵṡḛḛ...龍ผู้]"])},
        "powered_by_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓẁḛḛṛḛḛḍ ḅẏẏ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
        "reasons_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛααṡṓṓṇ龍ผู้]"])},
        "recap": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛͼααṗ龍ผู้]"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋααṃṗḽḛḛ: Ḉṵṵṡṭṓṓṃḛḛṛ ḥααḍ ṗṛṓṓḅḽḛḛṃṡ ṵṵṗḍααṭḭḭṇḡ ṭḥḛḛ ṗααẏẏṃḛḛṇṭ ṃḛḛṭḥṓṓḍ. Ṫḥḛḛ ααḡḛḛṇṭ ṡḥααṛḛḛḍ ααṇ ααṛṭḭḭͼḽḛḛ ẁḥḭḭͼḥ ͼṓṓṵṵḽḍ ḥḛḛḽṗ. ḬḬṭ ẁṓṓṛḳḛḛḍ.龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṓṓẁḛḛṛḛḛḍ ḅẏẏ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḀḀḬḬ龍ผู้]"])}
        },
        "terms_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛṛṃṡ ṓṓϝ Ṣḛḛṛṽḭḭͼḛḛ龍ผู้]"])},
        "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳṡ ϝṓṓṛ ẏẏṓṓṵṵṛ ϝḛḛḛḛḍḅααͼḳ!龍ผู้]"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ龍ผู้]"])},
        "feedback_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥααṭ ḭḭṡ ṭḥḛḛ ṃααḭḭṇ ṛḛḛααṡṓṓṇ ϝṓṓṛ ẏẏṓṓṵṵṛ ṡͼṓṓṛḛḛ?龍ผู้]"])},
        "feedback_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḛḛḽḽ ṵṵṡ ẁḥẏẏ ẏẏṓṓṵṵ ḡααṽḛḛ ṭḥḭḭṡ ṛααṭḭḭṇḡ...龍ผู้]"])},
        "high_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḡḥ ḛḛϝϝṓṓṛṭ龍ผู้]"])},
        "low_effort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓẁ ḛḛϝϝṓṓṛṭ龍ผู้]"])},
        "open_in_web_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼḽḭḭͼḳ ḥḛḛṛḛḛ ṭṓṓ ṓṓṗḛḛṇ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααͼẏẏ龍ผู้]"])},
        "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ͼṵṵṡṭṓṓṃ ʠṵṵḛḛṡṭḭḭṓṓṇ ααṗṗḛḛααṛṡ ḥḛḛṛḛḛ龍ผู้]"])},
        "score_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḡααṽḛḛ ẏẏṓṓṵṵṛ ", _interpolate(_list(0)), " ḛḛẋṗḛḛṛḭḭḛḛṇͼḛḛ αα ṡͼṓṓṛḛḛ ṓṓϝ龍ผู้]"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥααṇḳ ẏẏṓṓṵṵ龍ผู้]"])},
        "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡṵṵḅṡͼṛḭḭḅḛḛ龍ผู้]"])},
        "very_dissatisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḛḛṛẏẏ ḍḭḭṡṡααṭḭḭṡϝḭḭḛḛḍ龍ผู้]"])},
        "very_satisfied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḛḛṛẏẏ ṡααṭḭḭṡϝḭḭḛḛḍ龍ผู้]"])}
      },
      "modal": {
        "duplicate_modal": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḛḛ ṭḥααṭ ṭḥḛḛ ͼṵṵṛṛḛḛṇṭ ṡṵṵṛṽḛḛẏẏ ṡṭααṭṵṵṡ ααṇḍ ḍḛḛḽḭḭṽḛḛṛẏẏ ṛṵṵḽḛḛṡ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ḭḭṇͼḽṵṵḍḛḛḍ ḭḭṇ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ ṡṵṵṛṽḛḛẏẏ “", _interpolate(_list(0)), "”龍ผู้]"])}
        },
        "pause": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ṵṵṡḛḛ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ḛḛṃααḭḭḽ ṡṇḭḭṗṗḛḛṭ, ṃααḳḛḛ ṡṵṵṛḛḛ ṭṓṓ ṛḛḛṃṓṓṽḛḛ ḭḭṭ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ḅḛḛϝṓṓṛḛḛ ṗααṵṵṡḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṗṓṓṇṡḛḛṡ ααṛḛḛ ͼṓṓḽḽḛḛͼṭḛḛḍ ẁḥḛḛṇ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ḭḭṡ ṗααṵṵṡḛḛḍ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṵṵṡḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ṛḛḛṃṓṓṽḛḛ ṭḥḛḛ ṡṇḭḭṗṗḛḛṭ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ḅḛḛϝṓṓṛḛḛ ṗααṵṵṡḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])}
        },
        "delete": {
          "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ṵṵṡḛḛ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ḛḛṃααḭḭḽ ṡṇḭḭṗṗḛḛṭ, ṃααḳḛḛ ṡṵṵṛḛḛ ṭṓṓ ṛḛḛṃṓṓṽḛḛ ḭḭṭ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ḅḛḛϝṓṓṛḛḛ ḍḛḛḽḛḛṭḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ. ṲṲṡḛḛṛṡ ẁḥṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ααṛḛḛ ṡṭḭḭḽḽ ααḅḽḛḛ ṭṓṓ ϝḭḭḽḽ ḭḭṭ ḭḭṇ ααṇḍ ααḽḽ ḍααṭαα ṛḛḛḽααṭḛḛḍ ṭṓṓ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡṵṵṛṽḛḛẏẏ ", _interpolate(_named("surveyName")), "龍ผู้]"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ṛḛḛṃṓṓṽḛḛ ṭḥḛḛ ṡṇḭḭṗṗḛḛṭ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ḅḛḛϝṓṓṛḛḛ ḍḛḛḽḛḛṭḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])}
        },
        "publish": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααṗṗḽḭḭḛḛḍ ͼḥααṇḡḛḛṡ ẁḭḭḽḽ ḅḛḛ ṡḛḛḛḛṇ ḅẏẏ ṇḛḛẁ ṛḛḛṡṗṓṓṇḍḛḛṇṭṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ͼṓṓṗẏẏ ṭḥḛḛ ḽααṭḛḛṡṭ ṽḛḛṛṡḭḭṓṓṇ ṓṓϝ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ṡṇḭḭṗṗḛḛṭ ααϝṭḛḛṛ ṗṵṵḅḽḭḭṡḥḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])}
        },
        "resume": {
          "maybe_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ṵṵṡḛḛ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ḛḛṃααḭḭḽ ṡṇḭḭṗṗḛḛṭ, ṃααḳḛḛ ṡṵṵṛḛḛ ṭṓṓ ͼṓṓṗẏẏ ḭḭṭ ṭṓṓ Żḛḛṇḍḛḛṡḳ ααϝṭḛḛṛ ṛḛḛṡṵṵṃḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ ṡḛḛṇḍḭḭṇḡ ṓṓṵṵṭ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ ααṇḍ ͼṓṓḽḽḛḛͼṭḭḭṇḡ ṛḛḛṡṗṓṓṇṡḛḛṡ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ααṇḍ ṛḛḛṡṵṵṃḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ααḍḍ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ṡṇḭḭṗṗḛḛṭ ṭṓṓ Żḛḛṇḍḛḛṡḳ ααϝṭḛḛṛ ṛḛḛṡṵṵṃḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])}
        },
        "update": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ṡṵṵṛṽḛḛẏẏ龍ผู้]"])},
          "with_embedded_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ͼṓṓṗẏẏ ṭḥḛḛ ḽααṭḛḛṡṭ ṽḛḛṛṡḭḭṓṓṇ ṓṓϝ ṭḥḛḛ ḛḛṃḅḛḛḍḍḛḛḍ ṡṇḭḭṗṗḛḛṭ ααϝṭḛḛṛ ṵṵṗḍααṭḭḭṇḡ ṭḥḛḛ ṡṵṵṛṽḛḛẏẏ.龍ผู้]"])}
        }
      },
      "delete_modal": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ṡṵṵṛṽḛḛẏẏ? Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṡṵṵṛṽḛḛẏẏ?龍ผู้]"])}
      }
    },
    "trial_ended": {
      "continue_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḥααṡ ḛḛẋṗḭḭṛḛḛḍ, ḅṵṵṭ ẏẏṓṓṵṵ ͼααṇ ͼṓṓṇṭḭḭṇṵṵḛḛ ḛḛṇĵṓṓẏẏḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḅẏẏ ṵṵṗḡṛααḍḭḭṇḡ ṭṓṓ αα ϝṵṵḽḽ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
      "continue_zd_only_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḥααṡ ḛḛẋṗḭḭṛḛḛḍ, ḅṵṵṭ ẏẏṓṓṵṵ ͼααṇ ͼṓṓṇṭḭḭṇṵṵḛḛ ḛḛṇĵṓṓẏẏḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ ḅẏẏ ṵṵṗḡṛααḍḭḭṇḡ ṭṓṓ αα ϝṵṵḽḽ ααͼͼṓṓṵṵṇṭ. Ṣṗḛḛααḳ ṭṓṓ ẏẏṓṓṵṵṛ ḽṓṓͼααḽ ααḍṃḭḭṇḭḭṡṭṛααṭṓṓṛ ṭṓṓ ṡḛḛṭ ṭḥḭḭṡ ṵṵṗ.龍ผู้]"])},
      "title_zd_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṭṛḭḭααḽ ḥααṡ ḛḛṇḍḛḛḍ龍ผู้]"])},
      "upgrade_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḡṛααḍḛḛ ṇṓṓẁ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ϝṛḛḛḛḛ ṭṛḭḭααḽ ḥααṡ ḛḛṇḍḛḛḍ.龍ผู้]"])},
      "continue_using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ ṵṵṡḭḭṇḡ ṭḥḛḛ ϝṵṵḽḽ ṽḛḛṛṡḭḭṓṓṇ ṓṓϝ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ḛḛṇṭḛḛṛ ẏẏṓṓṵṵṛ ṗααẏẏṃḛḛṇṭ ḍḛḛṭααḭḭḽṡ.龍ผู้]"])},
      "continue_using_non_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ ṵṵṡḭḭṇḡ ṭḥḛḛ ϝṵṵḽḽ ṽḛḛṛṡḭḭṓṓṇ ṓṓϝ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ααḍṃḭḭṇḭḭṡṭṛααṭṓṓṛ ṇḛḛḛḛḍṡ ṭṓṓ ḛḛṇṭḛḛṛ ṗααẏẏṃḛḛṇṭ ḍḛḛṭααḭḭḽṡ.龍ผู้]"])},
      "manage_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ṗααẏẏṃḛḛṇṭ龍ผู้]"])}
    },
    "workspaces": {
      "assignments": {
        "read_more_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.klausapp.com/en/articles/4943707-assignments龍ผู้]"])},
        "a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍αα ṵṵṡḛḛṛ龍ผู้]"])},
        "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ṓṓṇ ", _interpolate(_named("date")), " ḅẏẏ ", _interpolate(_named("authorName")), "龍ผู้]"])},
        "form": {
          "assignment_period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
          "discard_changes_dialog": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṭ ḽṓṓṓṓḳṡ ḽḭḭḳḛḛ ẏẏṓṓṵṵ'ṛḛḛ ṡṭḭḭḽḽ ẁṓṓṛḳḭḭṇḡ ṓṓṇ ṭḥḭḭṡ. ŶŶṓṓṵṵ ḽṓṓṡḛḛ ααḽḽ ṭḥḛḛ ḭḭṇϝṓṓ ẏẏṓṓṵṵ'ṽḛḛ ααḍḍḛḛḍ ṭḥḭḭṡ ϝααṛ ḭḭϝ ẏẏṓṓṵṵ ḽḛḛααṽḛḛ ẁḭḭṭḥṓṓṵṵṭ ṡααṽḭḭṇḡ ͼḥααṇḡḛḛṡ.龍ผู้]"])}
          },
          "duration": {
            "label": {
              "recurring": {
                "bi_weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ ẁḭḭḽḽ ṛḛḛṗḛḛααṭ ḛḛṽḛḛṛẏẏ ṓṓṭḥḛḛṛ ", _interpolate(_named("weekDay")), "龍ผู้]"])},
                "weekly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ ẁḭḭḽḽ ṛḛḛṗḛḛααṭ ḛḛṽḛḛṛẏẏ ", _interpolate(_named("weekDay")), "龍ผู้]"])}
              }
            }
          },
          "steps_summary": {
            "all_covered_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ ͼṓṓṽḛḛṛḛḛḍ ḭḭṇ ṓṓṇḛḛ ͼẏẏͼḽḛḛ"]), _normalize(["ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ ͼṓṓṽḛḛṛḛḛḍ ḭḭṇ ", _interpolate(_named("nr")), " ͼẏẏͼḽḛḛṡ龍ผู้]"])])},
            "nr_conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
            "of_agents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṓṓϝ ", _interpolate(_named("nr")), " ααḡḛḛṇṭ"]), _normalize(["ṓṓϝ ", _interpolate(_named("nr")), " ααḡḛḛṇṭ"]), _normalize(["ṓṓϝ ", _interpolate(_named("nr")), " ααḡḛḛṇṭṡ龍ผู้]"])])},
            "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṗααṛṭḭḭͼḭḭṗααṇṭ"]), _normalize(["ṗααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])])},
            "per_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṗḛḛṛ ͼẏẏͼḽḛḛ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗḛḛṛ ͼẏẏͼḽḛḛ"]), _normalize([_interpolate(_named("n")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṗḛḛṛ ͼẏẏͼḽḛḛ龍ผู้]"])])},
            "per_reviewee_in_row": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("reviewsCount")), " ṗḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ḭḭṇ αα ṛṓṓẁ龍ผู้]"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁḛḛḛḛ"]), _normalize(["ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁḛḛṛ"]), _normalize(["ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])])}
          },
          "reviewees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])},
          "reviewers_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])},
          "steps": {
            "participants": {
              "all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])},
              "all_users_except_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ ḛḛẋͼḛḛṗṭ ααḡḛḛṇṭṡ龍ผู้]"])}
            }
          }
        },
        "preview_cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉẏẏͼḽḛḛ ", _interpolate(_named("cycleNumber")), " • ", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate")), "龍ผู้]"])},
        "preview_cycle_non_recurring": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate")), "龍ผู้]"])},
        "preview_non_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇṓṓṇ-ṛḛḛͼṵṵṛṛḭḭṇḡ龍ผู้]"])},
        "preview_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "reviewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḭḭṇḡ龍ผู้]"])},
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ααṇḍ ṛḛḛṃṓṓṽḛḛ ḭḭṭṡ ḍααṭαα ϝṛṓṓṃ ṭḥḛḛ ḍααṡḥḅṓṓααṛḍ. ḀḀḽḽ ṛḛḛṽḭḭḛḛẁṡ ṛḛḛḽααṭḛḛḍ ṭṓṓ ḭḭṭ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])},
        "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ", _interpolate(_named("assignmentName")), "龍ผู้]"])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ḍṵṵṗḽḭḭͼααṭḛḛḍ龍ผู้]"])},
        "empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṡṡḭḭḡṇṃḛḛṇṭṡ ααḍḍḛḛḍ ẏẏḛḛṭ龍ผู้]"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ͼṛḛḛααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ṇααṃḛḛ龍ผู้]"])}
      },
      "assignments_v_2": {
        "header": {
          "changes": {
            "immediate": {
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉẏẏͼḽḛḛ ḭḭṡ ṛḛḛϝṛḛḛṡḥḛḛḍ ααṇḍ αα ṇḛḛẁ ṭααṡḳ ḽḭḭṡṭ ḭḭṡ ͼṛḛḛααṭḛḛḍ. ḀḀḽṛḛḛααḍẏẏ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ ṡṭḭḭḽḽ ͼṓṓṵṵṇṭ ṭṓṓẁααṛḍṡ ṭḥḛḛ ḡṓṓααḽ.龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṃṃḛḛḍḭḭααṭḛḛḽẏẏ龍ผู้]"])}
            },
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ龍ผู้]"])},
            "next": {
              "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṭ ṭḥḛḛ ṡṭααṛṭ ṓṓϝ ṇḛḛẋṭ ͼẏẏͼḽḛḛ ṓṓṇ ", _interpolate(_named("startDate")), "龍ผู้]"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ṭṓṓ ṗṵṵḅḽḭḭṡḥ ͼḥααṇḡḛḛṡ?龍ผู้]"])}
          },
          "inactive_changes": {
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛṡ ṃααḍḛḛ ṭṓṓ ṭḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ ẁḭḭḽḽ ḅḛḛ ṗṵṵḅḽḭḭṡḥḛḛḍ ẁḭḭṭḥ ṭḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ααṡṡḭḭḡṇṃḛḛṇṭ ααṡ ααͼṭḭḭṽḛḛ?龍ผู้]"])}
          },
          "title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])}
        },
        "steps": {
          "general": {
            "cycle_settings": {
              "repeats": {
                "recurring_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼṵṵṛṛḭḭṇḡ ṓṓṇ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ṡṭααṛṭ ḍααẏẏ龍ผู้]"])},
                "friday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ḟṛḭḭḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ḟṛḭḭḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ḟṛḭḭḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ḟṛḭḭḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ḟṛḭḭḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "monday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ṁṓṓṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ṁṓṓṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ṁṓṓṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ṁṓṓṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ṁṓṓṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "saturday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ṣααṭṵṵṛḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ṣααṭṵṵṛḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ṣααṭṵṵṛḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ṣααṭṵṵṛḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ṣααṭṵṵṛḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "sunday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ṣṵṵṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ṣṵṵṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ṣṵṵṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ṣṵṵṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ṣṵṵṇḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "thursday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ṫḥṵṵṛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ṫḥṵṵṛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ṫḥṵṵṛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ṫḥṵṵṛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ṫḥṵṵṛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "tuesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ṫṵṵḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ṫṵṵḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ṫṵṵḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ṫṵṵḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ṫṵṵḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "wednesday": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ Ŵḛḛḍṇḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ Ŵḛḛḍṇḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ Ŵḛḛḍṇḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ Ŵḛḛḍṇḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ Ŵḛḛḍṇḛḛṡḍααẏẏ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])}
                },
                "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃ ṛḛḛͼṵṵṛṛḛḛṇͼḛḛ龍ผู้]"])},
                "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααẏẏ龍ผู้]"])},
                "first_of_every_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍1ṡṭ ṓṓϝ ḛḛṽḛḛṛẏẏ ṃṓṓṇṭḥ龍ผู้]"])},
                "indicator": {
                  "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭṛṡṭ龍ผู้]"])},
                  "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛͼṓṓṇḍ龍ผู้]"])},
                  "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṛḍ龍ผู้]"])},
                  "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṛṭḥ龍ผู้]"])},
                  "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ龍ผู้]"])}
                },
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḛḛααṭṡ龍ผู้]"])},
                "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳḍααẏẏ龍ผู้]"])}
              },
              "end_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇḍ龍ผู้]"])},
              "start_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭ龍ผู้]"])},
              "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṵṵḽṭṡ龍ผู้]"])},
              "timezone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛẓṓṓṇḛḛ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉẏẏͼḽḛḛ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
            },
            "basic_info": {
              "name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ṇααṃḛḛ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααṡḭḭͼ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ龍ผู้]"])}
            },
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṭ ṭḥḛḛ ṡṭααṛṭ ṓṓϝ ḛḛααͼḥ ααṡṡḭḭḡṇṃḛḛṇṭ ͼẏẏͼḽḛḛ, αα ṗṛḛḛḍḛḛṭḛḛṛṃḭḭṇḛḛḍ ḽḭḭṡṭ ṓṓϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ḭḭṡ ḡḛḛṇḛḛṛααṭḛḛḍ ϝṓṓṛ ḛḛṽααḽṵṵααṭḭḭṓṓṇ.龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααḽ龍ผู้]"])}
          },
          "goal": {
            "advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṽααṇͼḛḛḍ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
            "fill_goal": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ϝḛḛṭͼḥ ṇḛḛẁ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ẁḥḛḛṇ ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ ḭḭṡṇ'ṭ ṃḛḛṭ龍ผู้]"])},
              "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼ ϝḛḛṭͼḥḭḭṇḡ ḭḭṡṇ'ṭ ṗṓṓṡṡḭḭḅḽḛḛ ẁḭḭṭḥ ṗḛḛṛͼḛḛṇṭααḡḛḛ (%) ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽṡ龍ผู้]"])}
            },
            "integer_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṵṵṡṭ ḅḛḛ αα ẁḥṓṓḽḛḛ ṇṵṵṃḅḛḛṛ, ṇṓṓṭ αα ḍḛḛͼḭḭṃααḽ龍ผู้]"])},
            "replacing_conversations": {
              "text_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṛḛḛṗḽααͼḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ (Ṛḛḛṽḭḭḛḛẁḛḛṛ ṛṓṓḽḛḛ ααṇḍ ααḅṓṓṽḛḛ)龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽααͼḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])}
            },
            "self_reviews": {
              "description_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṭṓṓ ḅḛḛ ααṡṡḭḭḡṇḛḛḍ ṭḥḛḛḭḭṛ ṓṓẁṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
            },
            "minimum": {
              "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭṇḭḭṃṵṵṃ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ ṃṵṵṡṭ ḅḛḛ ααṭ ḽḛḛααṡṭ 1龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛͼḭḭϝẏẏ ṃḭḭṇḭḭṃṵṵṃ ṛḛḛṽḭḭḛḛẁṡ ϝṓṓṛ ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])}
            },
            "review_goal": {
              "options": {
                "conversations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
                "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗḛḛṛͼḛḛṇṭ (%)龍ผู้]"])},
                "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
              },
              "reviewer": {
                "append": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ ṭṓṓṭααḽ龍ผู้]"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααͼḥ ṛḛḛṽḭḭḛḛẁḛḛṛ ṃṵṵṡṭ ḍṓṓ ṡḛḛṭ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
              },
              "conversation": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṃṵṵṡṭ ͼṓṓḽḽḛḛͼṭḭḭṽḛḛḽẏẏ ṛḛḛṽḭḭḛḛẁ αα ṡḛḛṭ ṇṵṵṃḅḛḛṛ ṓṓϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])}
              },
              "errors": {
                "at_least": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭṇḭḭṃṵṵṃ ṇṵṵṃḅḛḛṛ ṓṓϝ ṛḛḛṽḭḭḛḛẁṡ ṃṵṵṡṭ ḅḛḛ ααṭ ḽḛḛααṡṭ 1龍ผู้]"])},
                "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ ṃṵṵṡṭ ḅḛḛ ḅḛḛṭẁḛḛḛḛṇ 1 – 100%龍ผู้]"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ龍ผู้]"])},
              "reviewee": {
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααͼḥ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ṃṵṵṡṭ ḥααṽḛḛ ṃḭḭṇḭḭṃṵṵṃ ṇṵṵṃḅḛḛṛ ṓṓϝ ṭḥḛḛḭḭṛ ṭṓṓṭααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])}
              }
            },
            "approach": {
              "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ͼḥṓṓṓṓṡḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṛṓṓṃ ṭḥḛḛ ṡḥααṛḛḛḍ ṗṓṓṓṓḽ龍ผู้]"])},
              "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṛṓṓṃ ṭḥḛḛ ṡḥααṛḛḛḍ ṗṓṓṓṓḽ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ααṗṗṛṓṓααͼḥ龍ผู้]"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ龍ผู้]"])}
          },
          "participants": {
            "reviewees": {
              "all_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḅṓṓṭṡ龍ผู้]"])},
              "all_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṗααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
              "bot_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
              "user_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥṓṓ ẁḭḭḽḽ ḅḛḛ ṛḛḛṽḭḭḛḛẁḛḛḍ?龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])}
            },
            "reviewees_type": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁḥṓṓ ṭṓṓ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
            },
            "exclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵṡḭḭṓṓṇṡ ααṛḛḛ ṓṓṇḽẏẏ ααḽḽṓṓẁḛḛḍ ẁḥḛḛṇ ααḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ ṓṓṛ αα ḡṛṓṓṵṵṗ ḭḭṡ ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ααṇḍ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])},
            "type_of_users": {
              "workspace_default": {
                "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭ: ", _interpolate(_list(0)), "龍ผู้]"])},
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ḍḛḛϝααṵṵḽṭ龍ผู้]"])}
              },
              "assignee": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḭḭͼḥ ṭẏẏṗḛḛ ṓṓϝ ṵṵṡḛḛṛṡ ṡḥṓṓṵṵḽḍ ḅḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ααṡ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ?龍ผู้]"])},
              "most_active_agent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṡṭ ααͼṭḭḭṽḛḛ ααḡḛḛṇṭ龍ผู้]"])}
              },
              "participant": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭ龍ผู้]"])}
              }
            },
            "dropdown_sections": {
              "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
              "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
              "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])}
            },
            "exclude_reviewees": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛṡ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ龍ผู้]"])}
            },
            "exclude_reviewers": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛṡ ṭṓṓ ḛḛẋͼḽṵṵḍḛḛ龍ผู้]"])}
            },
            "reviewers": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ ḛḛẋͼḛḛṗṭ ααḡḛḛṇṭṡ龍ผู้]"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥṓṓ ẁḭḭḽḽ ḅḛḛ ḍṓṓḭḭṇḡ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁṡ?龍ผู้]"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])}
            },
            "self_reviews": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṇṓṓṭ ααḽḽṓṓẁḛḛḍ ḭḭṇ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
              "not_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṇṓṓṭ ααḽḽṓṓẁḛḛḍ ḭḭṇ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])}
            }
          },
          "preview": {
            "matching_conversations": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṭͼḥḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṭṓṓḍααẏẏ"]), _normalize(["Ṁααṭͼḥḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓḍααẏẏ龍ผู้]"])])},
            "description": {
              "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁḭḭṇḡ ααṡ ḭḭϝ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ṡṭααṛṭḛḛḍ ṭṓṓḍααẏẏ.龍ผู้]"])},
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṵṵṭṵṵṛḛḛ ṇṵṵṃḅḛḛṛṡ ṃααẏẏ ṽααṛẏẏ.龍ผู้]"])}
            },
            "no_reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])},
            "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])},
            "reviewees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ"]), _normalize(["Ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])])},
            "reviewers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ"]), _normalize(["Ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
          },
          "summary": {
            "auto_fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓ-ϝḛḛṭͼḥḭḭṇḡ: ααḽḽṓṓẁḛḛḍ ḭḭϝ ṇṓṓṭ ḛḛṇṓṓṵṵḡḥ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
            "cycle_friday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ḟṛḭḭḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_monday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ṁṓṓṇḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_saturday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ṣααṭṵṵṛḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_sunday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ṣṵṵṇḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_thursday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ṫḥṵṵṛṡḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_tuesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ṫṵṵḛḛṡḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_wednesday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("cycleLength")), " ṓṓṇ Ŵḛḛḍṇḛḛṡḍααẏẏṡ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_daily": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍααḭḭḽẏẏ ϝṛṓṓṃ ", _interpolate(_named("startDateTime")), "龍ผู้]"])},
            "cycle_never": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṭααṛṭṡ ", _interpolate(_named("startDateTime")), " ααṇḍ ḛḛṇḍṡ ", _interpolate(_named("endDateTime")), ".龍ผู้]"])},
            "date_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ḍααṭḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])},
            "other_conditions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])},
            "other_conditions_no_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ", _interpolate(_named("count")), " ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇḍḭḭṭḭḭṓṓṇ"]), _normalize(["Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ", _interpolate(_named("count")), " ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])])},
            "other_conditions_no_helpdesk_condition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ααṇḍ ṇṓṓ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇḍḭḭṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ṇṓṓ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])])},
            "approach": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀṗṗṛṓṓααͼḥ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṭṓṓ ͼḥṓṓṓṓṡḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ"]), _normalize(["ḀḀṗṗṛṓṓααͼḥ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭẋ ṭḥḛḛ ḛḛṛṛṓṓṛṡ ḥḭḭḡḥḽḭḭḡḥṭḛḛḍ ḭḭṇ ṭḥḛḛ ṡṵṵṃṃααṛẏẏ ṭṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ龍ผู้]"])},
            "goal": {
              "conversation": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṃṵṵṡṭ ṛḛḛṽḭḭḛḛẁ ααḽḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḡṓṓααḽ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṃṵṵṡṭ ṛḛḛṽḭḭḛḛẁ ṓṓṇḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ"]), _normalize(["Ḡṓṓααḽ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṃṵṵṡṭ ṛḛḛṽḭḭḛḛẁ ", _interpolate(_named("count")), " ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṃṵṵṡṭ ṛḛḛṽḭḭḛḛẁ ", _interpolate(_named("count")), "% ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])}
              },
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ṇṓṓ ṛḛḛṽḭḭḛḛẁ ḡṓṓααḽ ṡḛḛṭ龍ผู้]"])},
              "reviewee": {
                "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ṃṵṵṡṭ ḥααṽḛḛ ααḽḽ ṓṓϝ ṭḥḛḛḭḭṛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
                "conversations": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ṃṵṵṡṭ ḥααṽḛḛ ", _interpolate(_named("count")), " ṓṓϝ ṭḥḛḛḭḭṛ ṭṓṓṭααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
                "percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛḛḛ ṃṵṵṡṭ ḥααṽḛḛ ", _interpolate(_named("count")), "% ṓṓϝ ṭḥḛḛḭḭṛ ṭṓṓṭααḽ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])}
              },
              "reviewer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḡṓṓααḽ: ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛṛ ṃṵṵṡṭ ḍṓṓ ṓṓṇḛḛ ṛḛḛṽḭḭḛḛẁ"]), _normalize(["Ḡṓṓααḽ: ḛḛααͼḥ ṛḛḛṽḭḭḛḛẁḛḛṛ ṃṵṵṡṭ ḍṓṓ ", _interpolate(_named("count")), " ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])])}
            },
            "replacing": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṗḽααͼḭḭṇḡ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ṇṓṓṭ ṛḛḛṗḽααͼḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ"]), _normalize(["Ṛḛḛṗḽααͼḭḭṇḡ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ṛḛḛṗḽααͼḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])])},
            "self_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ: ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ḅḛḛ ααṡṡḭḭḡṇḛḛḍ ṓṓẁṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
            "no_date_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍααṭḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ ṡḛḛṭ龍ผู้]"])},
            "no_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṡṡḭḭḡṇṃḛḛṇṭ ṇααṃḛḛ ṡḛḛṭ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṃṃααṛẏẏ龍ผู้]"])}
          },
          "conditions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇṡ龍ผู้]"])}
          }
        },
        "date_weekday_from_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("weekday")), " ϝṛṓṓṃ ", _interpolate(_named("dateTime")), "龍ผู้]"])},
        "form": {
          "cycle_label_bi_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḭḭ-Ŵḛḛḛḛḳḽẏẏ龍ผู้]"])},
          "cycle_label_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααḭḭḽẏẏ龍ผู้]"])},
          "cycle_label_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṇṭḥḽẏẏ龍ผู้]"])},
          "cycle_label_one_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṽḛḛṛ龍ผู้]"])},
          "cycle_label_weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḛḛḳḽẏẏ龍ผู้]"])},
          "cycle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉẏẏͼḽḛḛ ḽḛḛṇḡṭḥ龍ผู้]"])}
        },
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ααṡ ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ααṡ Ḍṛααϝṭ龍ผู้]"])},
        "drafted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ṡααṽḛḛḍ ααṡ Ḍṛααϝṭ龍ผู้]"])},
        "inactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ṡḛḛṭ ααṡ ḬḬṇααͼṭḭḭṽḛḛ龍ผู้]"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ααṡ ḬḬṇααͼṭḭḭṽḛḛ龍ผู้]"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "mark_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ααͼṭḭḭṽḛḛ龍ผู้]"])},
        "mark_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ḭḭṇααͼṭḭḭṽḛḛ龍ผู้]"])},
        "next_cycle_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗḛḛṇḍḭḭṇḡ ṵṵṗḍααṭḛḛṡ ṭṓṓ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ẁḭḭḽḽ ṭααḳḛḛ ḛḛϝϝḛḛͼṭ ḭḭṇ ṭḥḛḛ ṇḛḛẋṭ ͼẏẏͼḽḛḛ, ṡṭααṛṭḭḭṇḡ ṓṓṇ ", _interpolate(_list(0)), ".龍ผู้]"])},
        "publish_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ ͼḥααṇḡḛḛṡ龍ผู้]"])},
        "status": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
          "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭ龍ผู้]"])},
          "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇααͼṭḭḭṽḛḛ龍ผู้]"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṇḍḭḭṇḡ龍ผู้]"])}
        },
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ṵṵṗḍααṭḛḛḍ龍ผู้]"])}
      },
      "rating_categories": {
        "edit": {
          "auto_categories": {
            "closing": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḭḭϝ ṭḥḛḛ ααḡḛḛṇṭ ṓṓṛ ḅṓṓṭ ẁṛααṗṗḛḛḍ ṵṵṗ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḭḭϝ ṭḥḛḛ ααḡḛḛṇṭ ẁṛααṗṗḛḛḍ ṵṵṗ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗṓṓḽḭḭṭḛḛḽẏẏ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ẁṛααṗṗḛḛḍ ṵṵṗ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḭḭṇḡ龍ผู้]"])}
            },
            "grammar": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḡṛααṃṃααṛ, ṡṗḛḛḽḽḭḭṇḡ, ααṇḍ ṡṭẏẏḽḛḛ ṃḭḭṡṭααḳḛḛṡ ḭḭṇ ḅṓṓṭḥ ααḡḛḛṇṭ ααṇḍ ḅṓṓṭ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ.龍ผู้]"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ḡṛααṃṃααṛ, ṡṗḛḛḽḽḭḭṇḡ ααṇḍ ṡṭẏẏḽḛḛ ṃḭḭṡṭααḳḛḛṡ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ḡṛααṃṃααṛ, ṡṗḛḛḽḽḭḭṇḡ ααṇḍ ṡṭẏẏḽḛḛ ṃḭḭṡṭααḳḛḛṡ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛḽḽḭḭṇḡ ααṇḍ ḡṛααṃṃααṛ龍ผู้]"])}
            },
            "greeting": {
              "klaus_model_description_with_bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ααḡḛḛṇṭ ααṇḍ ḅṓṓṭ ḡṛḛḛḛḛṭḭḭṇḡṡ.龍ผู้]"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ṭẏẏṗḭḭͼααḽ ḡṛḛḛḛḛṭḭḭṇḡ ṗḥṛααṡḛḛṡ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ṭẏẏṗḭḭͼααḽ ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])}
            },
            "empathy": {
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭααṇḍṡ ααṇḍ ααͼḳṇṓṓẁḽḛḛḍḡḛḛṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛṡ' ϝḛḛḛḛḽḭḭṇḡṡ龍ผู้]"])},
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬḍḛḛṇṭḭḭϝḭḭḛḛṡ ͼααṡḛḛṡ ẁḥḛḛṛḛḛ ṭḥḛḛ ααḡḛḛṇṭ ṃḭḭṛṛṓṓṛḛḛḍ ͼṵṵṡṭṓṓṃḛḛṛ ṡḛḛṇṭḭḭṃḛḛṇṭ ṓṓṛ ṵṵṡḛḛḍ ͼααṛḭḭṇḡ ẁṓṓṛḍṡ ḽḭḭḳḛḛ 'ααͼḳṇṓṓẁḽḛḛḍḡḛḛ' ααṇḍ 'ṛḛḛͼṓṓḡṇḭḭṡḛḛ'龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃṗααṭḥẏẏ龍ผู้]"])}
            },
            "issue_understanding": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭααṇḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṛẏẏ ṓṓṛ ͼṓṓṇͼḛḛṛṇ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭααṇḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṛẏẏ ṓṓṛ ͼṓṓṇͼḛḛṛṇ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗṛḛḛḥḛḛṇṡḭḭṓṓṇ龍ผู้]"])}
            },
            "readability": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḥṓṓẁ ḛḛααṡḭḭḽẏẏ αα ṭḛḛẋṭ ͼααṇ ḅḛḛ ṵṵṇḍḛḛṛṡṭṓṓṓṓḍ, ͼṓṓṇṡḭḭḍḛḛṛḭḭṇḡ ẁṓṓṛḍ ͼṓṓṃṗḽḛḛẋḭḭṭẏẏ ααṇḍ ṡḛḛṇṭḛḛṇͼḛḛ ḽḛḛṇḡṭḥ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ḥṓṓẁ ḛḛααṡḭḭḽẏẏ αα ṭḛḛẋṭ ͼααṇ ḅḛḛ ṵṵṇḍḛḛṛṡṭṓṓṓṓḍ, ͼṓṓṇṡḭḭḍḛḛṛḭḭṇḡ ẁṓṓṛḍ ͼṓṓṃṗḽḛḛẋḭḭṭẏẏ ααṇḍ ṡḛḛṇṭḛḛṇͼḛḛ ḽḛḛṇḡṭḥ龍ผู้]"])},
              "long_sentences_count": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃḛḛṡṡααḡḛḛ ͼṓṓṇṭααḭḭṇṡ 1 ṓṓṽḛḛṛḽẏẏ ḽṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛ, ṃααḳḭḭṇḡ ḭḭṭ ͼḥααḽḽḛḛṇḡḭḭṇḡ ṭṓṓ ṛḛḛααḍ龍ผู้]"])},
                "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃḛḛṡṡααḡḛḛ ͼṓṓṇṭααḭḭṇṡ ", _interpolate(_list(0)), " ṓṓṽḛḛṛḽẏẏ ḽṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ, ṃααḳḭḭṇḡ ḭḭṭ ͼḥααḽḽḛḛṇḡḭḭṇḡ ṭṓṓ ṛḛḛααḍ龍ผู้]"])}
              },
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍααḅḭḭḽḭḭṭẏẏ龍ผู้]"])}
            },
            "solution": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ααṇ ṓṓϝϝḛḛṛḛḛḍ ṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ṭḥḛḛ ḛḛṇṭḭḭṛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṓṓṛ ααṇ ṓṓϝϝḛḛṛḛḛḍ ṡṓṓḽṵṵṭḭḭṓṓṇ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓḽṵṵṭḭḭṓṓṇ ṓṓϝϝḛḛṛḛḛḍ龍ผู้]"])}
            },
            "tone": {
              "klaus_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ṭḥṛṓṓṵṵḡḥṓṓṵṵṭ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
              "open_ai_model_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇααḽẏẏẓḛḛṡ ααḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ṭḥṛṓṓṵṵḡḥṓṓṵṵṭ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṇḛḛ龍ผู้]"])}
            }
          },
          "auto_qa_rating_descriptions": {
            "klaus_model": {
              "grammar": {
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ααṇẏẏ ṃḭḭṡṭααḳḛḛṡ ϝṛṓṓṃ ααḡḛḛṇṭ'ṡ ṃḛḛṡṡααḡḛḛṡ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛṛṃḭḭṇḛḛ ḡṛααṃṃααṛ ṃḭḭṡṭααḳḛḛṡ龍ผู้]"])}
              },
              "greeting": {
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡṛḛḛḛḛṭḭḭṇḡ ṗḥṛααṡḛḛ ḍḛḛṭḛḛͼṭḛḛḍ. ḀḀḍḍ ṇḛḛẁ ṓṓṇḛḛṡ ḭḭϝ ṇḛḛͼḛḛṡṡααṛẏẏ ṭḥṛṓṓṵṵḡḥ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ ḭḭṇ Ṣḛḛṭṭḭḭṇḡṡ.龍ผู้]"])}
              },
              "closing": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ẁṛααṗṗḛḛḍ ṵṵṗ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṗṓṓḽḭḭṭḛḛḽẏẏ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼḽṓṓṡḭḭṇḡ ṗḥṛααṡḛḛ ḍḛḛṭḛḛͼṭḛḛḍ. ḀḀḍḍ ṇḛḛẁ ṓṓṇḛḛṡ ḭḭϝ ṇḛḛͼḛḛṡṡααṛẏẏ ṭḥṛṓṓṵṵḡḥ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ ḭḭṇ Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
              },
              "empathy": {
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṃḭḭṛṛṓṓṛḛḛḍ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ṡḛḛṇṭḭḭṃḛḛṇṭ ṓṓṛ ṵṵṡḛḛḍ ͼααṛḭḭṇḡ ẁṓṓṛḍṡ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ḭḭϝ ααḡḛḛṇṭ ṃḭḭṛṛṓṓṛḛḛḍ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ṡḛḛṇṭḭḭṃḛḛṇṭ ṓṓṛ ṵṵṡḛḛḍ ṵṵṡḛḛḍ ͼααṛḭḭṇḡ ẁṓṓṛḍṡ龍ผู้]"])}
              },
              "readability": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṵṵṡḛḛḍ ḽṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ ααṇḍ ͼṓṓṃṗḽḛḛẋ ẁṓṓṛḍṡ, ṃααḳḭḭṇḡ ḭḭṭ ṗṓṓṭḛḛṇṭḭḭααḽḽẏẏ ͼḥααḽḽḛḛṇḡḭḭṇḡ ϝṓṓṛ ṡṓṓṃḛḛ ṭṓṓ ṵṵṇḍḛḛṛṡṭααṇḍ龍ผู้]"])},
                "1": {
                  "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṵṵṡḛḛḍ ͼṓṓṃṗḽḛḛẋ ẁṓṓṛḍṡ, ṃααḳḭḭṇḡ ḭḭṭ ṗṓṓṭḛḛṇṭḭḭααḽḽẏẏ ͼḥααḽḽḛḛṇḡḭḭṇḡ ϝṓṓṛ ṡṓṓṃḛḛ ṭṓṓ ṵṵṇḍḛḛṛṡṭααṇḍ龍ผู้]"])},
                  "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṵṵṡḛḛḍ ḽṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ, ṃααḳḭḭṇḡ ḭḭṭ ṗṓṓṭḛḛṇṭḭḭααḽḽẏẏ ͼḥααḽḽḛḛṇḡḭḭṇḡ ϝṓṓṛ ṡṓṓṃḛḛ ṭṓṓ ṵṵṇḍḛḛṛṡṭααṇḍ龍ผู้]"])}
                },
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṵṵṡḛḛḍ ḽααṇḡṵṵααḡḛḛ ṭḥααṭ ḭḭṡ ḛḛααṡẏẏ ṭṓṓ ṵṵṇḍḛḛṛṡṭααṇḍ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ḛḛṇṓṓṵṵḡḥ ḍααṭαα ṭṓṓ ṛααṭḛḛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])}
              }
            },
            "open_ai": {
              "issue_understanding": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ṡṭṛṵṵḡḡḽḛḛḍ ṭṓṓ ḡṛααṡṗ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṡṭḭḭṓṓṇ/ṛḛḛʠṵṵḛḛṡṭ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭṓṓṓṓḍ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṡṭḭḭṓṓṇ/ṛḛḛʠṵṵḛḛṡṭ ẁḛḛḽḽ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ẁḥḛḛṭḥḛḛṛ ṭḥḛḛ ααḡḛḛṇṭ ṵṵṇḍḛḛṛṡṭṓṓṓṓḍ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ'ṡ ʠṵṵḛḛṡṭḭḭṓṓṇ/ṛḛḛʠṵṵḛḛṡṭ龍ผู้]"])}
              },
              "tone": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ'ṡ ṭṓṓṇḛḛ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛṛṃḭḭṇḛḛ ṭḥḛḛ ṭṓṓṇḛḛ ṓṓϝ ṭḥḛḛ ααḡḛḛṇṭ龍ผู้]"])}
              },
              "closing": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ṇṓṓṭ ẁṛααṗṗḛḛḍ ṵṵṗ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ẁṛααṗṗḛḛḍ ṵṵṗ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ḭḭϝ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ẁααṡ ẁṛααṗṗḛḛḍ ṵṵṗ龍ผู้]"])}
              },
              "empathy": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ḽααͼḳḛḛḍ ḛḛṃṗααṭḥẏẏ ṭṓṓẁααṛḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ααṇḍ ṭḥḛḛḭḭṛ ṗṛṓṓḅḽḛḛṃṡ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṡḥṓṓẁḛḛḍ ḛḛṃṗααṭḥẏẏ ṭṓṓẁααṛḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ααṇḍ ṭḥḛḛḭḭṛ ṗṛṓṓḅḽḛḛṃṡ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ḭḭϝ ααḡḛḛṇṭ ṡḥṓṓẁḛḛḍ ḛḛṃṗααṭḥẏẏ ṭṓṓẁααṛḍṡ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ ααṇḍ ṭḥḛḛḭḭṛ ṗṛṓṓḅḽḛḛṃṡ 龍ผู้]"])}
              },
              "greeting": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ẁααṡ ṇṓṓṭ ḡṛḛḛḛḛṭḛḛḍ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṡṭṓṓṃḛḛṛ ẁααṡ ḡṛḛḛḛḛṭḛḛḍ ḍṵṵṛḭḭṇḡ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ṭẏẏṗḭḭͼααḽ ḡṛḛḛḛḛṭḭḭṇḡ龍ผู้]"])}
              },
              "solution": {
                "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ḍḭḭḍ ṇṓṓṭ ṗṛṓṓṽḭḭḍḛḛ αα ṡṓṓḽṵṵṭḭḭṓṓṇ ṭṓṓ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ龍ผู้]"])},
                "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḡḛḛṇṭ ṓṓϝϝḛḛṛḛḛḍ ṡṓṓḽṵṵṭḭḭṓṓṇ ṭṓṓ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ龍ผู้]"])},
                "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ḍḛḛṭḛḛͼṭ ḭḭϝ ααḡḛḛṇṭ ṓṓϝϝḛḛṛḛḛḍ αα ṡṓṓḽṵṵṭḭḭṓṓṇ ṭṓṓ ṭḥḛḛ ͼṵṵṡṭṓṓṃḛḛṛ龍ผู้]"])}
              }
            }
          },
          "auto_root_cause": {
            "complex_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗḽḛḛẋ ẁṓṓṛḍṡ龍ผู้]"])},
            "long_sentences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓṇḡ ṡḛḛṇṭḛḛṇͼḛḛṡ龍ผู้]"])},
            "grammar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛααṃṃααṛ龍ผู้]"])},
            "misspelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḭḭṡṡṗḛḛḽḽḭḭṇḡ龍ผู้]"])},
            "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ龍ผู้]"])},
            "neutral_sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṓṓḽṓṓḡḛḛṭḭḭͼ龍ผู้]"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
            "pos_calm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽṃ龍ผู้]"])},
            "pos_cheerful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥḛḛḛḛṛϝṵṵḽ龍ผู้]"])},
            "pos_inquisitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛḭḭṓṓṵṵṡ龍ผู้]"])},
            "pos_professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ龍ผู้]"])},
            "pos_supportive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṗṗṓṓṛṭḭḭṽḛḛ龍ผู้]"])},
            "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭẏẏḽḛḛ龍ผู้]"])}
          },
          "account_settings_info": {
            "closing": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ͼḽṓṓṡḭḭṇḡ"]), _normalize([_interpolate(_named("n")), " ͼḽṓṓṡḭḭṇḡṡ龍ผู้]"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ṓṓṇḽẏẏ ͼḥḛḛͼḳṡ ϝṓṓṛ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ. ḬḬϝ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ ḭḭṡ ṡḛḛṭ ααṡ ͼṛḭḭṭḭḭͼααḽ, ṇṓṓṭ ṵṵṡḭḭṇḡ ṭḥḛḛ ααṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ ẁḭḭḽḽ ϝααḭḭḽ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ͼḽṓṓṡḭḭṇḡṡ龍ผู้]"])}
            },
            "grammar": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḛḛẋḛḛṃṗṭḭḭṓṓṇ"]), _normalize([_interpolate(_named("n")), " ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍṡ ααṇḍ ṗḥṛααṡḛḛṡ ḀḀṵṵṭṓṓǪḀḀ ḭḭḡṇṓṓṛḛḛṡ ααṡ ṡṗḛḛḽḽḭḭṇḡ ṓṓṛ ḡṛααṃṃααṛ ṃḭḭṡṭααḳḛḛṡ.龍ผู้]"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗḛḛḽḽḭḭṇḡ ααṇḍ ḡṛααṃṃααṛ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ḛḛẋḛḛṃṗṭḭḭṓṓṇṡ龍ผู้]"])}
            },
            "greeting": {
              "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḡṛḛḛḛḛṭḭḭṇḡ"]), _normalize([_interpolate(_named("n")), " ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ṓṓṇḽẏẏ ͼḥḛḛͼḳṡ ϝṓṓṛ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ. ḬḬϝ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ ḭḭṡ ṡḛḛṭ ααṡ ͼṛḭḭṭḭḭͼααḽ, ṇṓṓṭ ṵṵṡḭḭṇḡ ṭḥḛḛ ααṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ ẁḭḭḽḽ ϝααḭḭḽ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
              "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṗṗṛṓṓṽḛḛḍ ḡṛḛḛḛḛṭḭḭṇḡṡ龍ผู้]"])}
            }
          },
          "auto_qa_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "auto_qa_category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṇ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "auto_qa_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααṡṡḭḭḡṇḛḛḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ:龍ผู้]"])},
          "auto_qa_consent_hint": {
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ → Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
            "approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḽḛḛṽḛḛṛααḡḛḛ ṀṢ ḀḀẓṵṵṛḛḛ, ṓṓϝϝḛḛṛḭḭṇḡ ααṇ ḛḛẋṭḛḛṇḍḛḛḍ ṛααṇḡḛḛ ṓṓϝ ϝṵṵṇͼṭḭḭṓṓṇααḽḭḭṭẏẏ ααṇḍ ṡṵṵṗṗṓṓṛṭḛḛḍ ḽααṇḡṵṵααḡḛḛṡ. Ṛḛḛṽḭḭḛḛẁ ẏẏṓṓṵṵṛ ", _interpolate(_named("link")), " ϝṓṓṛ ṃṓṓṛḛḛ ḭḭṇϝṓṓṛṃααṭḭḭṓṓṇ, ṓṓṛ ͼṓṓṇṭααͼṭ ααṇ ααḍṃḭḭṇ ṭṓṓ ḍṓṓ ṡṓṓ.龍ผู้]"])},
            "removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṡṡ ααṇ ḛḛẋṭḛḛṇḍḛḛḍ ḽḭḭṡṭ ṓṓϝ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṇḍ ṡṵṵṗṗṓṓṛṭḛḛḍ ḽααṇḡṵṵααḡḛḛṡ ḅẏẏ ḽḛḛṽḛḛṛααḡḭḭṇḡ ṀṢ ḀḀẓṵṵṛḛḛ. Ṛḛḛṽḭḭḛḛẁ ẏẏṓṓṵṵṛ ", _interpolate(_named("link")), " ṓṓṛ ͼṓṓṇṭααͼṭ ααṇ ααḍṃḭḭṇ ṭṓṓ ḍṓṓ ṡṓṓ.龍ผู้]"])}
          },
          "auto_qa_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛ ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
          "auto_qa_toggle_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ αα ṡͼṓṓṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ, ṵṵṡḭḭṇḡ αα ϝḭḭẋḛḛḍ ṛααṭḭḭṇḡ ṡͼααḽḛḛ. ŶŶṓṓṵṵ ͼααṇ ααḍĵṵṵṡṭ ṭḥḛḛ ṛααṭḭḭṇḡ ṃααṇṵṵααḽḽẏẏ ḽααṭḛḛṛ. ", _interpolate(_named("link")), "龍ผู้]"])},
          "choose_auto_qa_category_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ααṇ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "auto_qa_causes_help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀṇ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααṡṡḭḭḡṇḛḛḍ ṡͼṓṓṛḛḛ ḍḛḛṗḛḛṇḍṡ ṓṓṇ ṭḥḛḛ ṡḛḛṽḛḛṛḭḭṭẏẏ ṓṓϝ ṃḭḭṡṭααḳḛḛṡ ααṇḍ ṭḥḛḛḭḭṛ ṇṵṵṃḅḛḛṛ. ", _interpolate(_named("link")), "龍ผู้]"])},
          "manual_causes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ龍ผู้]"])}
        },
        "categories_type_modal": {
          "explanation_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṛḛḛḽḛḛṽααṇṭ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṛḛḛ ṡḥṓṓẁṇ ṭṓṓ ṛḛḛṽḭḭḛḛẁḛḛṛṡ. Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṛḛḛ ṡḥṓṓẁṇ ḅααṡḛḛḍ ṓṓṇ ṭḥḛḛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ ẏẏṓṓṵṵ'ṽḛḛ ṡḛḛṭ ẁḥḛḛṇ ͼṛḛḛααṭḭḭṇḡ αα ͼααṭḛḛḡṓṓṛẏẏ.龍ผู้]"])},
          "scorecard": {
            "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼḥααṭ龍ผู้]"])},
            "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṇḅṓṓααṛḍḭḭṇḡ龍ผู้]"])},
            "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗḥṓṓṇḛḛ龍ผู้]"])}
          },
          "cta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṭḥḛḛ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṭẏẏṗḛḛ ϝṓṓṛ ẁṓṓṛḳṡṗααͼḛḛ \"", _interpolate(_named("workspace")), "\".龍ผู้]"])},
          "explanation_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ḥααṽḛḛ ṭṓṓ ṃααṇṵṵααḽḽẏẏ ṡḛḛḽḛḛͼṭ ṭḥḛḛ ͼṓṓṛṛḛḛͼṭ ṡͼṓṓṛḛḛͼααṛḍ ṭṓṓ ṡḛḛḛḛ ṛḛḛḽḛḛṽααṇṭ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ϝṓṓṛ ḛḛααͼḥ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ.龍ผู้]"])},
          "prompt": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ͼḥααṇḡḛḛ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṭẏẏṗḛḛ?龍ผู้]"])},
            "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṭẏẏṗḛḛ?龍ผู้]"])}
          },
          "radio_label_dynamic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇḍḭḭṭḭḭṓṓṇααḽ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "radio_label_static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭḭḭͼ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṭẏẏṗḛḛ龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
        },
        "category_delete_confirmation": {
          "action_cannot_be_undone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
          "delete_all_the_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛḽḛḛṭḛḛ ααḽḽ ṭḥḛḛ ṛααṭḭḭṇḡṡ龍ผู้]"])},
          "keep_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṭḛḛṛṇααṭḭḭṽḛḛḽẏẏ, ẏẏṓṓṵṵ ḳḛḛḛḛṗ ẏẏṓṓṵṵṛ ṡṭααṭḭḭṡṭḭḭͼṡ ẁḭḭṭḥṓṓṵṵṭ ḍḛḛḽḛḛṭḭḭṇḡ ααṇẏẏ ḍααṭαα ḅẏẏ ααṛͼḥḭḭṽḭḭṇḡ αα ͼααṭḛḛḡṓṓṛẏẏ.龍ผู้]"])},
          "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḬḬ ṵṵṇḍḛḛṛṡṭααṇḍ ṭḥααṭ ṭḥḭḭṡ ẁḭḭḽḽ ", _interpolate(_named("deleteAllRatings")), " ḡḭḭṽḛḛṇ ϝṓṓṛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ.龍ผู้]"])}
        },
        "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. Ṫṓṓṇḛḛ ḭḭṡ ṗḛḛṛϝḛḛͼṭ龍ผู้]"])},
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ͼααṭḛḛḡṓṓṛẏẏ ṓṓṇḽẏẏ ḭḭϝ龍ผู้]"])},
          "always_conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍\"ḀḀḽẁααẏẏṡ\" ẁḭḭḽḽ ḅḛḛ ḭḭḡṇṓṓṛḛḛḍ. ḬḬṭ ͼααṇṇṓṓṭ ḅḛḛ ͼṓṓṃḅḭḭṇḛḛḍ ẁḭḭṭḥ ṓṓṭḥḛḛṛ ͼṓṓṇḍḭḭṭḭḭṓṓṇṡ.龍ผู้]"])},
          "option": {
            "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽẁααẏẏṡ龍ผู้]"])},
            "helpdesk_tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ͼṓṓṇḍḭḭṭḭḭṓṓṇ龍ผู้]"])},
            "satisfaction_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṭḭḭṡϝααͼṭḭḭṓṓṇ ṡͼṓṓṛḛḛ (ḈṢḀḀṪ)龍ผู้]"])},
            "source_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ ṭẏẏṗḛḛ龍ผู้]"])},
            "ticket_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼḥααṇṇḛḛḽ龍ผู้]"])}
          }
        },
        "category_needs_name_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ͼααṭḛḛḡṓṓṛẏẏ ṇḛḛḛḛḍṡ αα ṇααṃḛḛ龍ผู้]"])},
        "category_needs_scorecard_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ ṭṓṓ αα ṡͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ龍ผู้]"])},
        "category_added_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ααḍḍḛḛḍ龍ผู้]"])},
        "category_archive_confirmation_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ααṛͼḥḭḭṽḛḛḍ ͼααṭḛḛḡṓṓṛẏẏ ḭḭṡ ṇṓṓṭ ṽḭḭṡḭḭḅḽḛḛ ṓṓṇ ṭḥḛḛ ṡͼṓṓṛḛḛͼααṛḍ, ḅṵṵṭ ḭḭṭ ααḽḽṓṓẁṡ ẏẏṓṓṵṵ ṭṓṓ ḳḛḛḛḛṗ ẏẏṓṓṵṵṛ ṡṭααṭḭḭṡṭḭḭͼṡ ẁḭḭṭḥṓṓṵṵṭ ḍḛḛḽḛḛṭḭḭṇḡ ααṇẏẏ ḍααṭαα.龍ผู้]"])},
        "category_archive_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_archive_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ ", _interpolate(_list(0)), " ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ααṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
        "category_delete_confirmation_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_delete_confirmation_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ \"ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ\" ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_delete_confirmation_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "category_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ ", _interpolate(_list(0)), "龍ผู้]"])},
        "category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "category_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḍṵṵṗḽḭḭͼααṭḛḛḍ龍ผู้]"])},
        "category_group_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ ααḍḍḛḛḍ龍ผู้]"])},
        "category_group_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
        "category_group_order_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ ṓṓṛḍḛḛṛ ͼḥααṇḡḛḛḍ龍ผู้]"])},
        "category_group_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "category_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṵṵṇααṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
        "category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "critical_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ααṇ ααḡḛḛṇṭ ϝααḭḭḽṡ αα ͼṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛẏẏ, ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḭḭṇ ṭḥααṭ ṛḛḛṽḭḭḛḛẁ ϝααḭḭḽ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ.龍ผู้]"])},
        "critical_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḭḭṭḭḭͼααḽ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
        "empty_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḭḭṇ ṭḥḭḭṡ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "form_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṇḛḛẁ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "form_title_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "form_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "group_delete_confirmation_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ααḽḽ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ααṇḍ ṛḛḛṽḭḭḛḛẁ ḍααṭαα ṵṵṇḍḛḛṛ ṭḥḭḭṡ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "group_delete_confirmation_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ṭḥḛḛ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ ẁḭḭḽḽ ḽḛḛααṽḛḛ ααḽḽ ṭḥḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ḽḭḭṇḳḛḛḍ ṭṓṓ ḭḭṭ ṵṵṇḡṛṓṓṵṵṗḛḛḍ.龍ผู้]"])},
        "group_delete_confirmation_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ", _interpolate(_named("categoryName")), " ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "group_delete_confirmation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥṓṓṓṓṡḭḭṇḡ ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ṵṵṇḍḛḛṛ ṭḥḛḛ ḡṛṓṓṵṵṗ ẁḭḭḽḽ ṛḛḛṡṵṵḽṭ ḭḭṇ ḽṓṓṡḭḭṇḡ ααḽḽ ṭḥḛḛ ṛḛḛṽḭḭḛḛẁ ḍααṭαα ααṡṡṓṓͼḭḭααṭḛḛḍ ẁḭḭṭḥ ṭḥḛḛṡḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])},
        "group_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṇααṃḛḛ龍ผู้]"])},
        "multiple_reasons_checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ṡḛḛḽḛḛͼṭ ṃṓṓṛḛḛ ṭḥααṇ ṓṓṇḛḛ ṛḛḛααṡṓṓṇ ααṡ αα ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "multiple_reasons_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ ͼααṇ ṡḛḛḽḛḛͼṭ ṃṓṓṛḛḛ ṭḥααṇ ṓṓṇḛḛ ṛḛḛααṡṓṓṇ ααṡ αα ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "multiple_reasons_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṵṵḽṭḭḭṗḽḛḛ ṛḛḛααṡṓṓṇṡ龍ผู้]"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḛḛ.ḡ. Ṫṓṓṇḛḛ龍ผู้]"])},
        "new_group_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṇḛḛẁ ͼααṭḛḛḡṓṓṛẏẏ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "new_group_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "reason_add_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ \"ṎṎṭḥḛḛṛ\"龍ผู้]"])},
        "reason_add_root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "reason_duplicate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ龍ผู้]"])},
        "reason_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])},
        "reason_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛṓṓṓṓṭ ͼααṵṵṡḛḛṡ ṭṓṓ ḛḛẋṗḽααḭḭṇ ṛααṭḭḭṇḡ龍ผู้]"])},
        "reason_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ...龍ผู้]"])},
        "scale_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛααṭḭḭṇḡ ṡͼααḽḛḛ龍ผู้]"])},
        "tabs": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛḍ龍ผู้]"])}
        },
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
        "tags_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
        "tags_label_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṡͼṓṓṛḛḛͼααṛḍṡ ṭṓṓ ṭḥḭḭṡ ṛααṭḭḭṇḡ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṛͼḥḭḭṽḛḛ龍ผู้]"])},
        "update_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
        "visibility": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛααṭḭḭṇḡṡ龍ผู้]"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁṇ ϝṓṓṛ龍ผู้]"])},
          "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ ṛααṭḭḭṇḡṡ龍ผู้]"])},
          "negative_and_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛḡααṭḭḭṽḛḛ ααṇḍ ṇḛḛṵṵṭṛααḽ ṛααṭḭḭṇḡṡ龍ผู้]"])}
        },
        "warnings": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶḛḛṡ, ṵṵṗḍααṭḛḛ龍ผู้]"])}
        },
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ龍ผู้]"])},
        "weight_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ ṭḥααṭ ααṛḛḛ ṃṓṓṛḛḛ ḭḭṃṗṓṓṛṭααṇṭ ϝṓṓṛ ẏẏṓṓṵṵ ͼααṇ ḥααṽḛḛ ṃṓṓṛḛḛ ẁḛḛḭḭḡḥṭ ḭḭṇ ṭḥḛḛ ṭḭḭͼḳḛḛṭ ṡͼṓṓṛḛḛ ͼααḽͼṵṵḽααṭḭḭṓṓṇ.龍ผู้]"])},
        "weight_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ (", _interpolate(_list(0)), ")龍ผู้]"])}
      },
      "scorecard": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓ ṭṓṓ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṽḭḭḛḛẁ ααṇḍ ḛḛḍḭḭṭ ẏẏṓṓṵṵṛ ṡͼṓṓṛḛḛͼααṛḍṡ ḭḭṇ ṭḥḛḛ <strong>ḀḀͼͼṓṓṵṵṇṭ</strong> ṡḛḛͼṭḭḭṓṓṇ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ ααṛḛḛ ṇṓṓẁ ṃααṇααḡḛḛḍ ḭḭṇ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])}
      },
      "calibration": {
        "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ͼααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
        "upsell": {
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ͼααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααḍṽααṇͼḛḛḍ龍ผู้]"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥṓṓḽḍḭḭṇḡ ṛḛḛḡṵṵḽααṛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇṡ ααḽḽṓṓẁṡ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ṭṓṓ ḳḛḛḛḛṗ ṭḥḛḛḭḭṛ ṛααṭḭḭṇḡ ṭḛḛͼḥṇḭḭʠṵṵḛḛṡ ααḽḭḭḡṇḛḛḍ ααṇḍ ḛḛṇṡṵṵṛḛḛ ααḡḛḛṇṭṡ ṛḛḛͼḛḛḭḭṽḛḛ ṭḥḛḛ ṡααṃḛḛ ḽḛḛṽḛḛḽ ṓṓϝ ͼṓṓṇṡḭḭṡṭḛḛṇṭ ααṇḍ ṵṵṇḅḭḭααṡḛḛḍ ϝḛḛḛḛḍḅααͼḳ.龍ผู้]"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṗḽααṇṡ龍ผู้]"])},
          "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
          "introduction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ḭḭṡ αα ṗṛṓṓͼḛḛṡṡ ṭḥααṭ ḥḛḛḽṗṡ ẏẏṓṓṵṵṛ ṭḛḛααṃ ṡṭααẏẏ ṓṓṇ ṭḥḛḛ ṡααṃḛḛ ṗααḡḛḛ ṓṓṇ ḥṓṓẁ ααḽḽ ͼṵṵṡṭṓṓṃḛḛṛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ ṡḥṓṓṵṵḽḍ ḅḛḛ ḥααṇḍḽḛḛḍ ααṇḍ ḛḛṽααḽṵṵααṭḛḛḍ.龍ผู้]"])},
          "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗṛṓṓϝḛḛṡṡḭḭṓṓṇααḽ龍ผู้]"])},
          "summary": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ ḭḭṡ ααṽααḭḭḽααḅḽḛḛ ϝṓṓṛ ṓṓṵṵṛ ", _interpolate(_list(0)), " ααṇḍ ", _interpolate(_list(1)), " ṗḽααṇṡ.龍ผู้]"])}
        },
        "copy_existing_review": {
          "no": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡḽẏẏ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṇṓṓṭ ḍḭḭṡṗḽααẏẏḛḛḍ ẁḥḛḛṇ αα ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ḭḭṡ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭ ṽḭḭṡḭḭḅḽḛḛ龍ผู้]"])}
          },
          "yes": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡḽẏẏ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṛḛḛḡααṛḍḛḛḍ ααṡ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ. ṎṎṇḽẏẏ ṓṓṇḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁ ṗḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛṛ ẁḭḭḽḽ ḅḛḛ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ṡḛḛṡṡḭḭṓṓṇ. Ṁḛḛṡṡααḡḛḛ-ṡṗḛḛͼḭḭϝḭḭͼ ṛḛḛṽḭḭḛḛẁṡ ααṛḛḛ ṇṓṓṭ ḍḭḭṡṗḽααẏẏḛḛḍ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḽḛḛ龍ผู้]"])}
          }
        },
        "copy_existing_review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḭḭḽḭḭṭẏẏ ṓṓϝ ṗṛḛḛṽḭḭṓṓṵṵṡḽẏẏ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ ḭḭṡ αα ṗṛṓṓͼḛḛṡṡ ṭḥααṭ ḥḛḛḽṗṡ ẏẏṓṓṵṵṛ ṭḛḛααṃ ṡṭααẏẏ ṓṓṇ ṭḥḛḛ ṡααṃḛḛ ṗααḡḛḛ ṓṓṇ ḥṓṓẁ ααḽḽ ͼṵṵṡṭṓṓṃḛḛṛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ ṡḥṓṓṵṵḽḍ ḅḛḛ ḥααṇḍḽḛḛḍ ααṇḍ ḛḛṽααḽṵṵααṭḛḛḍ. ", _interpolate(_named("link")), "龍ผู้]"])},
        "enabled_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
        "lead": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ḥααṽḛḛ ṵṵṇḽḭḭṃḭḭṭḛḛḍ ααͼͼḛḛṡṡ ṭṓṓ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽẁααẏẏṡ ṡḛḛḛḛ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααḍ龍ผู้]"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ, ṵṵṡḛḛṛṡ ẁḭḭḽḽ ṡḛḛḛḛ ḛḛṽααḽṵṵααṭḭḭṓṓṇṡ ḡḭḭṽḛḛṇ ḅẏẏ ṓṓṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααḽḽ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ ααϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
          }
        },
        "manager": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ḥααṽḛḛ ṵṵṇḽḭḭṃḭḭṭḛḛḍ ααͼͼḛḛṡṡ ṭṓṓ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽẁααẏẏṡ ṡḛḛḛḛ ααḽḽ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛṛ龍ผู้]"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ, ṵṵṡḛḛṛṡ ẁḭḭḽḽ ṡḛḛḛḛ ḛḛṽααḽṵṵααṭḭḭṓṓṇṡ ḡḭḭṽḛḛṇ ḅẏẏ ṓṓṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααḽḽ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ ααϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
          }
        },
        "reviewer": {
          "all": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ, ṵṵṡḛḛṛṡ ẁḭḭḽḽ ṡḛḛḛḛ ḛḛṽααḽṵṵααṭḭḭṓṓṇṡ ḡḭḭṽḛḛṇ ḅẏẏ ṓṓṭḥḛḛṛ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααḽḽ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ ααϝṭḛḛṛ ṡṵṵḅṃḭḭṭṭḭḭṇḡ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
          "own": {
            "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ẁḭḭḽḽ ṓṓṇḽẏẏ ṡḛḛḛḛ ṭḥḛḛḭḭṛ ṓṓẁṇ ṛḛḛṽḭḭḛḛẁṡ ḡḭḭṽḛḛṇ ḍṵṵṛḭḭṇḡ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ.龍ผู้]"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ṓṓṇḽẏẏ ṓṓẁṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
          }
        },
        "settings_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṭṭḭḭṇḡṡ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
        "visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭṡḭḭḅḭḭḽḭḭṭẏẏ ṓṓϝ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "threshold_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ṭḥḛḛ ṭḥṛḛḛṡḥṓṓḽḍ ϝṓṓṛ ḍααṡḥḅṓṓααṛḍ ṃḛḛṭṛḭḭͼṡ ḅẏẏ ϝḭḭḽḽḭḭṇḡ ḭḭṇ ṭḥḛḛ ḡααṗṡ ααͼͼṓṓṛḍḭḭṇḡ ṭṓṓ ẏẏṓṓṵṵṛ ṭḛḛααṃṡ ḭḭṇṭḛḛṛṇααḽ ṃḛḛṭṛḭḭͼṡ ṡẏẏṡṭḛḛṃ. Ṛḛḛṽḭḭḛḛẁ ṭḥḛḛ ṭḥṛḛḛṡḥṓṓḽḍ ṓṓṇ ṭḥḛḛ Ḍααṡḥḅṓṓααṛḍ.龍ผู้]"])},
      "settings_titles": {
        "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
        "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααḽḭḭḅṛααṭḭḭṓṓṇ龍ผู้]"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇṡ龍ผู้]"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααḽ龍ผู้]"])},
        "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡṡ龍ผู้]"])},
        "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṃḅḛḛṛṡ龍ผู้]"])},
        "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
        "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṛḛḛṡḥṓṓḽḍ龍ผู้]"])}
      },
      "threshold_updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṛḛḛṡḥṓṓḽḍ ṵṵṗḍααṭḛḛḍ龍ผู้]"])}
    },
    "integrate": {
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṡṭ αα ṃṓṓṃḛḛṇṭ...龍ผู้]"])},
        "retry_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṁααḳḛḛ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ḥααṽḛḛ ḀḀḍṃḭḭṇ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ααṇḍ ", _interpolate(_named("retry")), ", ṓṓṛ ", _interpolate(_named("contact")), " ϝṓṓṛ ϝṵṵṛṭḥḛḛṛ ḥḛḛḽṗ.龍ผู้]"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṭααͼṭ ṵṵṡ龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛṃṡ ẁḛḛ ẁḛḛṛḛḛ ṇṓṓṭ ααḅḽḛḛ ṭṓṓ ḡααḭḭṇ ααͼͼḛḛṡṡ ṭṓṓ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ'ṡ ḀḀṔḬḬ.龍ผู้]"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭṛẏẏ ααḡααḭḭṇ龍ผู้]"])}
      },
      "callback_redirect_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛ'ṛḛḛ ḥṓṓṓṓḳḭḭṇḡ ṵṵṗ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ. ŶŶṓṓṵṵ'ḽḽ ḅḛḛ ṛḛḛḍḭḭṛḛḛͼṭḛḛḍ ṃṓṓṃḛḛṇṭααṛḭḭḽẏẏ.龍ผู้]"])},
      "callback_success_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ααṵṵṭḥṓṓṛḭḭṡḛḛḍ龍ผู้]"])},
      "invalid": {
        "action_template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḥḛḛααḍ ḅααͼḳ ṭṓṓ ẏẏṓṓṵṵṛ ", _interpolate(_list(0)), " ṭṓṓ ααḍḍ αα ṇḛḛẁ ḥḛḛḽṗ ḍḛḛṡḳ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ.龍ผู้]"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛṃṡ ẏẏṓṓṵṵ'ṽḛḛ ṃḭḭṡṡḛḛḍ αα ṡṭḛḛṗ ṓṓṛ ṭẁṓṓ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇ ẁḭḭṭḥ ṭḥḛḛ ṡααṃḛḛ ḛḛṃααḭḭḽ ḍṓṓṃααḭḭṇ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ龍ผู้]"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ṗααḡḛḛ龍ผู้]"])}
      },
      "callback_waiting_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇ ṭḥḛḛ ṃḛḛααṇṭḭḭṃḛḛ...龍ผู้]"])},
      "loading": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ ṵṵṡ αα ṃṓṓṃḛḛṇṭ ẁḥḭḭḽḛḛ ẁḛḛ ͼḥḛḛͼḳ ẏẏṓṓṵṵṛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ.龍ผู้]"])},
        "meanwhile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇ ṭḥḛḛ ṃḛḛααṇṭḭḭṃḛḛ...龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṭḛḛḡṛααṭḭḭṇḡ龍ผู้]"])}
      }
    },
    "unconfigured_account": {
      "to_continue_using_klaus_manager": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṛḛḛααṭḛḛ αα ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ ṵṵṡḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ", _interpolate(_named("link")), ".龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṃṓṓṡṭ ṛḛḛααḍẏẏ ṭṓṓ ṵṵṡḛḛ龍ผู้]"])},
      "no_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "switch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣẁḭḭṭͼḥ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "to_continue_using_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ͼṓṓṇṭḭḭṇṵṵḛḛ ṵṵṡḭḭṇḡ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ẏẏṓṓṵṵṛ ṃααṇααḡḛḛṛ ṇḛḛḛḛḍṡ ṭṓṓ ααṡṡḭḭḡṇ ẏẏṓṓṵṵ ṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])}
    },
    "danger": {
      "description": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ααḽḽ ṭḥḛḛ ḍααṭαα ẁḛḛ ḥααṽḛḛ ṓṓṇ ẏẏṓṓṵṵ - ", _interpolate(_named("bold")), "龍ผู้]"])},
        "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααḽḽ ḡḭḭṽḛḛṇ ṛααṭḭḭṇḡṡ, ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ͼṓṓṇṭḛḛṇṭṡ, ẁṓṓṛḳṡṗααͼḛḛṡ, ḛḛṭͼ.龍ผู้]"])}
      }
    },
    "notifications": {
      "slack": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ Ṣḽααͼḳ龍ผู้]"])},
        "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼṓṓṇṇḛḛͼṭ龍ผู้]"])},
        "disconnect_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼṓṓṇṇḛḛͼṭḭḭṇḡ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ẁḭḭḽḽ ḍḭḭṡααḅḽḛḛ ααḽḽ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ ṽḭḭαα Ṣḽααͼḳ龍ผู้]"])},
        "disconnect_slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼṓṓṇṇḛḛͼṭ Ṣḽααͼḳ龍ผู้]"])},
        "disconnect_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽααͼḳ ḭḭṇṭḛḛḡṛααṭḭḭṓṓṇ ḥααṡ ḅḛḛḛḛṇ ḍḭḭṡͼṓṓṇṇḛḛͼṭḛḛḍ ϝṛṓṓṃ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛϝḭḭṇḛḛ ḍḛḛϝααṵṵḽṭ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṡḛḛṭṭḭḭṇḡṡ ϝṓṓṛ ααḽḽ ṇḛḛẁ ααͼͼṓṓṵṵṇṭ ṵṵṡḛḛṛṡ.龍ผู้]"])},
      "override_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṛḭḭḍḛḛ ṡḛḛṭṭḭḭṇḡṡ ϝṓṓṛ ααḽḽ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "override_settings_dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ααṛḛḛ ααḅṓṓṵṵṭ ṭṓṓ ṓṓṽḛḛṛṛḭḭḍḛḛ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ ṡḛḛṭṭḭḭṇḡṡ ϝṓṓṛ ααḽḽ ααͼͼṓṓṵṵṇṭ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "override_settings_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ααḅḽḛḛ ḍḛḛϝḭḭṇḛḛ ṭḥḛḛḭḭṛ ṓṓẁṇ ṇṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṡḛḛṭṭḭḭṇḡṡ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
      "updated_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇ ṡḛḛṭṭḭḭṇḡṡ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
      "webhooks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḅḥṓṓṓṓḳṡ龍ผู้]"])},
        "trigger_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḛḛṛṭ ẁḥḛḛṇ αα ͼṛḭḭṭḭḭͼααḽ ͼααṭḛḛḡṓṓṛẏẏ ϝααḭḭḽṡ龍ผู้]"])},
        "trigger_csat_answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḛḛṛṭ ẁḥḛḛṇ ḈṢḀḀṪ ḡḛḛṭṡ αα ṛḛḛṡṗṓṓṇṡḛḛ龍ผู้]"])},
        "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛḭḭḡḡḛḛṛṡ龍ผู้]"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṚḺ龍ผู้]"])}
      }
    },
    "reset_demo": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ḍḛḛṃṓṓ ẁṓṓṛḳṡṗααͼḛḛ ααṇḍ ḍααṭαα龍ผู้]"])},
      "refresh_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼṛḛḛααṭḛḛ ḍḛḛṃṓṓ ḍααṭαα ααṇḍ ḳḛḛḛḛṗ ṭḥḛḛ ḍḛḛṃṓṓ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ḍḛḛṃṓṓ ẁṓṓṛḳṡṗααͼḛḛ ααṇḍ ḍααṭαα龍ผู้]"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛϝṛḛḛṡḥ ḍḛḛṃṓṓ ḍααṭαα龍ผู้]"])},
      "refresh_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛϝṛḛḛṡḥ ḍααṭαα龍ผู้]"])},
      "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ααͼͼṓṓṵṵṇṭ \"", _interpolate(_named("accountName")), "\" ḍḛḛṃṓṓ ṃṓṓḍḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṃṓṓ ṃṓṓḍḛḛ龍ผู้]"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
  },
  "support_links": {
    "about_open_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/6059285322522-About-generative-AI-features-in-Zendesk龍ผู้]"])},
    "advanced_connection_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043712839450-Advanced-security-settings-for-help-desk-connections龍ผู้]"])},
    "autoqa_autoscoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043747123354龍ผู้]"])},
    "bots_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7418648293018-Evaluating-the-performance-of-AI-agents-using-Zendesk-QA龍ผู้]"])},
    "calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043724530842龍ผู้]"])},
    "category_scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759436954龍ผู้]"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/community/topics龍ผู้]"])},
    "coversation_insights_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043747231642龍ผู้]"])},
    "creating_multiple_scorecards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043747649690龍ผู้]"])},
    "dashboard_calculations_breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043701093786龍ผู้]"])},
    "find_conversations_to_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759449114龍ผู้]"])},
    "managing_users_and_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043760162074龍ผู้]"])},
    "pins_for_coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759543194龍ผู้]"])},
    "rating_scale_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043724860826龍ผู้]"])},
    "scores_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043668738970龍ผู้]"])},
    "scores_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759440794龍ผู้]"])},
    "setting_up_and_using_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043747142938龍ผู้]"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759586074龍ผู้]"])},
    "tracking_review_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/articles/7043759396250龍ผู้]"])},
    "whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/community/topics/6585157168154-What-s-New龍ผู้]"])},
    "zendesk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/sections/6999625340058-Using-Zendesk-QA?help_widget=true龍ผู้]"])},
    "zendesk_help_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍https://support.zendesk.com/hc/en-us/sections/6999625340058?help_widget=true龍ผู้]"])}
  },
  "tasks": {
    "assignments": {
      "discard_changes_dialog": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααẏẏ ṓṓṇ ṗααḡḛḛ龍ผู้]"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ ṗααḡḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ ṭḥḭḭṡ ṗααḡḛḛ ααṇḍ ḽṓṓṡḛḛ ααḽḽ ͼḥααṇḡḛḛṡ?龍ผู้]"])}
      },
      "empty": {
        "subtitle_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḥααṽḛḛ ͼṓṓṃṗḽḛḛṭḛḛḍ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "subtitle_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "title_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇṃḛḛṇṭ ͼṓṓṃṗḽḛḛṭḛḛḍ龍ผู้]"])},
        "title_no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṡṡḭḭḡṇṃḛḛṇṭṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "subtitle_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṛḛḛ ααṛḛḛ ͼṵṵṛṛḛḛṇṭḽẏẏ ṇṓṓ ṭααṡḳṡ ϝṓṓṛ ẏẏṓṓṵṵ ṭṓṓ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
        "subtitle_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍĵṵṵṡṭ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ ṓṓṛ ẁααḭḭṭ ϝṓṓṛ ṭḥḛḛ ṇḛḛẋṭ ͼẏẏͼḽḛḛ ṭṓṓ ḅḛḛḡḭḭṇ.龍ผู้]"])},
        "subtitle_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ααḍĵṵṵṡṭḭḭṇḡ ṭḥḛḛ ϝḭḭḽṭḛḛṛṡ龍ผู้]"])},
        "subtitle_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
        "subtitle_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱḛḛḛḛṗ ααṇ ḛḛẏẏḛḛ ṓṓṇ ṭḥḭḭṡ ṗααḡḛḛ ϝṓṓṛ ṵṵṗḍααṭḛḛṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṭααṡḳṡ ϝṓṓṛ ṭḥḭḭṡ ͼẏẏͼḽḛḛ龍ผู้]"])},
        "title_adjusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])}
      },
      "goal_date": {
        "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])}
      },
      "link_review": {
        "mark_as_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ṭααṡḳ ααṡ ḍṓṓṇḛḛ龍ผู้]"])},
        "reviewed_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ’ṽḛḛ ṛḛḛṽḭḭḛḛẁḛḛḍ ṭḥḭḭṡ ṵṵṡḛḛṛ.龍ผู้]"])}
      },
      "self_reviews_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛḽϝ-ṛḛḛṽḭḭḛḛẁṡ ḭḭṇ ṭḥḭḭṡ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])}
    },
    "calibrations": {
      "time_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
      "empty": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ḅẏẏ ͼḽḭḭͼḳḭḭṇḡ ṓṓṇ ṭḥḛḛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ḭḭͼṓṓṇ ḭḭṇ ṭḥḛḛ ḥḛḛααḍḛḛṛ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṽḭḭḛḛẁ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααḍḍḛḛḍ龍ผู้]"])}
      },
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵḛḛ ḍααṭḛḛ龍ผู้]"])}
    },
    "navbar": {
      "add_assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
      "add_calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇ龍ผู้]"])},
      "personal_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])}
      },
      "workspace_assignments": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])}
      }
    },
    "no_active_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼṭḭḭṽḛḛ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
    "no_assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṡṡḭḭḡṇṃḛḛṇṭṡ龍ผู้]"])},
    "pickers": {
      "reviewees": {
        "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ ḛḛẋͼḛḛṗṭ ṃḛḛ龍ผู้]"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])}
      },
      "status": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇḛḛ龍ผู้]"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭ龍ผู้]"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṭααṭṵṵṡḛḛṡ龍ผู้]"])},
        "to_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓ ḍṓṓ龍ผู้]"])}
      },
      "reviewers": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])},
        "all_except_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ḛḛẋͼḛḛṗṭ ṃḛḛ龍ผู้]"])},
        "assigned_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḍ ṭṓṓ ṃḛḛ龍ผู้]"])},
        "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])}
      }
    },
    "time_left_duration": {
      "days": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏṡ龍ผู้]"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏṡ龍ผู้]"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏ龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏṡ龍ผู้]"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏṡ龍ผู้]"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḍααẏẏṡ龍ผู้]"])}
      },
      "hours": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛṡ龍ผู้]"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛṡ龍ผู้]"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛ龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛṡ龍ผู้]"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛṡ龍ผู้]"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ḥṓṓṵṵṛṡ龍ผู้]"])}
      },
      "minutes": {
        "few": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])},
        "many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])},
        "one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛ龍ผู้]"])},
        "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])},
        "two": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])},
        "zero": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("duration")), " ṃḭḭṇṵṵṭḛḛṡ龍ผู้]"])}
      }
    },
    "title_na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅ/ḀḀ龍ผู้]"])},
    "info": {
      "reassign": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ龍ผู้]"])},
        "all_workspace_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])},
        "current_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭ ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "new_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ḡṓṓααḽ ϝṓṓṛ ṭḥḭḭṡ ͼẏẏͼḽḛḛ龍ผู้]"])},
        "new_reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ龍ผู้]"])},
        "title_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
        "to_be_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ḅḛḛ ṃṓṓṽḛḛḍ龍ผู้]"])}
      },
      "cycle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṵṵṛṛḛḛṇṭ ͼẏẏͼḽḛḛ龍ผู้]"])}
      },
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])},
      "reviewers": {
        "goal_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ龍ผู้]"])},
        "no_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁḛḛṛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "reassign_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ṃḛḛ龍ผู้]"])},
        "reassign_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ṓṓṭḥḛḛṛ ṵṵṡḛḛṛ龍ผู้]"])},
        "reviewer_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
        "reviews_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])}
    },
    "time_left_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ḍααẏẏṡ"]), _normalize([_interpolate(_named("n")), " ḍααẏẏ"]), _normalize([_interpolate(_named("n")), " ḍααẏẏṡ龍ผู้]"])])},
    "time_left_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ḥṓṓṵṵṛṡ"]), _normalize([_interpolate(_named("n")), " ḥṓṓṵṵṛ"]), _normalize([_interpolate(_named("n")), " ḥṓṓṵṵṛṡ龍ผู้]"])])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ龍ผู้]"])},
    "hide_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ͼṓṓṃṗḽḛḛṭḛḛḍ ͼẏẏͼḽḛḛṡ龍ผู้]"])},
    "hide_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ͼṓṓṃṗḽḛḛṭḛḛḍ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
    "personal_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḡṓṓααḽ龍ผู้]"])},
    "remove": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṛḛḛααṡṓṓṇ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ṛḛḛṃṓṓṽḛḛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽααḭḭṇ ẁḥẏẏ ẏẏṓṓṵṵ ααṛḛḛ ṛḛḛṃṓṓṽḭḭṇḡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])}
    },
    "replace": {
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ḳḛḛḛḛṗ ͼḥααṇḡḛḛṡ?龍ผู้]"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ṛḛḛααṡṓṓṇ ḭḭṇ ṓṓṛḍḛḛṛ ṭṓṓ ṛḛḛṗḽααͼḛḛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "discard_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼααṛḍ ͼḥααṇḡḛḛṡ龍ผู้]"])},
      "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱḛḛḛḛṗ龍ผู้]"])},
      "keep_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḱḛḛḛḛṗ ͼḥααṇḡḛḛṡ龍ผู้]"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽααͼḛḛṃḛḛṇṭ ṇṓṓṭ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽααḭḭṇ ẁḥẏẏ ẏẏṓṓṵṵ ααṛḛḛ ṛḛḛṗḽααͼḭḭṇḡ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ龍ผู้]"])},
      "replace_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓ ẏẏṓṓṵṵ ṡṭḭḭḽḽ ẁααṇṭ ṭṓṓ ṛḛḛṃṓṓṽḛḛ ṭḥḭḭṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ϝṛṓṓṃ ṭḥḛḛ ααṡṡḭḭḡṇṃḛḛṇṭ? Ṫḥḭḭṡ ẁḭḭḽḽ ṛḛḛḍṵṵͼḛḛ ṭḥḛḛ ḡṓṓααḽ ḅẏẏ 1.龍ผู้]"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽααͼḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṗḽααͼḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])}
    },
    "review_actions": {
      "delete_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḍṛααϝṭ龍ผู้]"])},
      "edit_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḍṛααϝṭ龍ผู้]"])},
      "reassign_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ṭṓṓ ṃḛḛ龍ผู้]"])},
      "reassign_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ṭṓṓ ṓṓṭḥḛḛṛ ṵṵṡḛḛṛ龍ผู้]"])},
      "reassign_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṡḭḭḡṇ ααṇḍ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
      "view_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ḽḛḛṡṡ龍ผู้]"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṃṓṓṛḛḛ龍ผู้]"])}
    },
    "review_goal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ: ", _interpolate(_named("count")), "/", _interpolate(_named("goal")), " ṛḛḛṽḭḭḛḛẁḛḛḍ龍ผู้]"])},
    "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ḍṓṓṇḛḛ龍ผู้]"])},
    "show_completed_cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ͼṓṓṃṗḽḛḛṭḛḛḍ ͼẏẏͼḽḛḛṡ龍ผู้]"])},
    "show_completed_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ͼṓṓṃṗḽḛḛṭḛḛḍ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
    "time_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛ ḽḛḛϝṭ龍ผู้]"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααṡḳṡ龍ผู้]"])},
    "toast": {
      "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṛḛḛṗḽααͼḛḛḍ龍ผู้]"])}
    },
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ṭααṡḳṡ ḽḭḭṡṭ龍ผู้]"])}
  },
  "universal": {
    "create_dropdown_tag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ \"", _interpolate(_named("tag")), "\"龍ผู้]"])},
    "duration": {
      "later_with_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("time")), " ḽααṭḛḛṛ龍ผู้]"])},
      "second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("value")), " ṡḛḛͼṓṓṇḍ龍ผู้]"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("value")), " ṡḛḛͼṓṓṇḍṡ龍ผู้]"])},
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), "ḍ龍ผู้]"])},
      "days_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), "ḍ ", _interpolate(_named("hours")), "ḥ龍ผู้]"])},
      "days_hours_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), "ḍ ", _interpolate(_named("hours")), "ḥ ", _interpolate(_named("minutes")), "ṃ龍ผู้]"])},
      "days_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), "ḍ龍ผู้]"])},
      "days_long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), " ḍααẏẏṡ龍ผู้]"])},
      "days_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("days")), "ḍ ", _interpolate(_named("minutes")), "ṃ龍ผู้]"])},
      "hours_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("hours")), "ḥ龍ผู้]"])},
      "hours_minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("hours")), "ḥ ", _interpolate(_named("minutes")), "ṃ龍ผู้]"])},
      "hours_minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("hours")), "ḥ ", _interpolate(_named("minutes")), "ṃ ", _interpolate(_named("seconds")), "ṡ龍ผู้]"])},
      "hours_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("hours")), "ḥ ", _interpolate(_named("seconds")), "ṡ龍ผู้]"])},
      "minutes_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("minutes")), "ṃ龍ผู้]"])},
      "minutes_seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("minutes")), "ṃ ", _interpolate(_named("seconds")), "ṡ龍ผู้]"])},
      "months_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("months")), "ṃṓṓ龍ผู้]"])},
      "seconds_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("seconds")), "ṡ龍ผู้]"])},
      "years_later": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("years")), "ẏẏ龍ผู้]"])},
      "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṡṭ ṇṓṓẁ龍ผู้]"])}
    },
    "errors": {
      "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ḽααͼḳ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ. Ḉṓṓṇṭααͼṭ ẏẏṓṓṵṵṛ ααḍṃḭḭṇḭḭṡṭṛααṭṓṓṛ ϝṓṓṛ ααṡṡḭḭṡṭααṇͼḛḛ.龍ผู้]"])},
      "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗααḡḛḛ ẏẏṓṓṵṵ ααṛḛḛ ḽṓṓṓṓḳḭḭṇḡ ϝṓṓṛ ḍṓṓḛḛṡṇ'ṭ ḛḛẋḭḭṡṭ.龍ผู้]"])},
      "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ṵṵṇḛḛẋṗḛḛͼṭḛḛḍ ḛḛṛṛṓṓṛ ṓṓͼͼṵṵṛṛḛḛḍ. Ḉṓṓṇṭααͼṭ ṡṵṵṗṗṓṓṛṭ ϝṓṓṛ ααṡṡḭḭṡṭααṇͼḛḛ.龍ผู้]"])},
      "401_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṡṡ ḍḛḛṇḭḭḛḛḍ. Ṅṓṓṭ ααḽḽṓṓẁḛḛḍ ṭṓṓ ṗḛḛṛϝṓṓṛṃ ṭḥααṭ ααͼṭḭḭṓṓṇ.龍ผู้]"])},
      "access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ṗααḡḛḛ ẏẏṓṓṵṵ'ṛḛḛ ḽṓṓṓṓḳḭḭṇḡ ϝṓṓṛ ḛḛḭḭṭḥḛḛṛ ḍṓṓḛḛṡṇ'ṭ ḛḛẋḭḭṡṭ ṓṓṛ ẏẏṓṓṵṵ ḍṓṓṇ'ṭ ḥααṽḛḛ ααͼͼḛḛṡṡ ṭṓṓ ḭḭṭ.龍ผู้]"])},
      "slow_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṛḛḛ ḡṓṓḭḭṇḡ αα ḽḭḭṭṭḽḛḛ ṭṓṓṓṓ ϝααṡṭ ααṇḍ ẁḛḛ'ṛḛḛ ḥααṽḭḭṇḡ ṭṛṓṓṵṵḅḽḛḛ ͼααṭͼḥḭḭṇḡ ṵṵṗ.龍ผู้]"])}
    },
    "role_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṇḛḛ龍ผู้]"])},
    "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛṡ ṡααṽḛḛḍ龍ผู้]"])},
    "unsaved_changes_made": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ ṃααḍḛḛ龍ผู้]"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααṇḍ龍ผู้]"])},
    "applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ααṗṗḽḭḭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ααṗṗḽḭḭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ααṗṗḽḭḭḛḛḍ龍ผู้]"])])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḛḛḍ龍ผู้]"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ααṭ龍ผู้]"])},
    "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛḍ ḅẏẏ龍ผู้]"])},
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḭḭṭḭḭͼααḽ龍ผู้]"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇḛḛ龍ผู้]"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ龍ผู้]"])},
    "former_member": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḟṓṓṛṃḛḛṛ ṃḛḛṃḅḛḛṛ"]), _normalize(["αα Ḟṓṓṛṃḛḛṛ ṃḛḛṃḅḛḛṛ龍ผู้]"])])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḥḛḛṛḛḛ龍ผู้]"])},
    "items_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])])},
    "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ṵṵṗḍααṭḛḛḍ龍ผู้]"])},
    "n_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍... ααṇḍ +1 ṃṓṓṛḛḛ"]), _normalize(["... ααṇḍ +", _interpolate(_named("n")), " ṃṓṓṛḛḛ龍ผู้]"])])},
    "n_more_simple": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍+1 ṃṓṓṛḛḛ"]), _normalize(["+", _interpolate(_named("n")), " ṃṓṓṛḛḛ龍ผู้]"])])},
    "new_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṇḛḛẁ龍ผู้]"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṇḛḛ龍ผู้]"])},
    "not_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḭḭṡ ṇṓṓṭ龍ผู้]"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṛ龍ผู้]"])},
    "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])},
    "replies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṛḛḛṗḽḭḭḛḛṡ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṗḽẏẏ"]), _normalize([_interpolate(_named("n")), " ṛḛḛṗḽḭḭḛḛṡ龍ผู้]"])])},
    "scale_emoji_labels": {
      "scale_2": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṵṵṃḅṡ ḍṓṓẁṇ龍ผู้]"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥṵṵṃḅṡ ṵṵṗ龍ผู้]"])}
      },
      "scale_3": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααṗṗṓṓḭḭṇṭḛḛḍ ϝααͼḛḛ龍ผู้]"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽḭḭḡḥṭḽẏẏ ṡṃḭḭḽḭḭṇḡ ϝααͼḛḛ龍ผู้]"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛ-ṡṭṛṵṵͼḳ龍ผู้]"])}
      },
      "scale_4": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααṗṗṓṓḭḭṇṭḛḛḍ ϝααͼḛḛ龍ผู้]"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝṵṵṡḛḛḍ ϝααͼḛḛ龍ผู้]"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṃḭḭḽḭḭṇḡ ϝααͼḛḛ龍ผู้]"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛ-ṡṭṛṵṵͼḳ龍ผู้]"])}
      },
      "scale_5": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααṗṗṓṓḭḭṇṭḛḛḍ ϝααͼḛḛ龍ผู้]"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝṵṵṡḛḛḍ ϝααͼḛḛ龍ผู้]"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḽḭḭḡḥṭḽẏẏ ṡṃḭḭḽḭḭṇḡ ϝααͼḛḛ龍ผู้]"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṃḭḭḽḭḭṇḡ ϝααͼḛḛ龍ผู้]"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṛ-ṡṭṛṵṵͼḳ龍ผู้]"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṭṓṓḍααẏẏ龍ผู้]"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṵṵṡḛḛṛ龍ผู้]"])},
    "weekdays": {
      "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḟṛḭḭḍααẏẏ"]), _normalize(["Ḟṛḭḭḍααẏẏṡ龍ผู้]"])])},
      "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁṓṓṇḍααẏẏ"]), _normalize(["Ṁṓṓṇḍααẏẏṡ龍ผู้]"])])},
      "saturday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣααṭṵṵṛḍααẏẏ"]), _normalize(["Ṣααṭṵṵṛḍααẏẏṡ龍ผู้]"])])},
      "sunday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣṵṵṇḍααẏẏ"]), _normalize(["Ṣṵṵṇḍααẏẏṡ龍ผู้]"])])},
      "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṫḥṵṵṛṡḍααẏẏ"]), _normalize(["Ṫḥṵṵṛṡḍααẏẏṡ龍ผู้]"])])},
      "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṫṵṵḛḛṡḍααẏẏ"]), _normalize(["Ṫṵṵḛḛṡḍααẏẏṡ龍ผู้]"])])},
      "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ŵḛḛḍṇḛḛṡḍααẏẏ"]), _normalize(["Ŵḛḛḍṇḛḛṡḍααẏẏṡ龍ผู้]"])])}
    },
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ẏẏḛḛṡṭḛḛṛḍααẏẏ龍ผู้]"])},
    "account_role": {
      "admin": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀḍṃḭḭṇ"]), _normalize(["ααṇ ḀḀḍṃḭḭṇ龍ผู้]"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ Ṁααṇααḡḛḛṛ"]), _normalize(["ααṇ ḀḀͼͼṓṓṵṵṇṭ Ṁααṇααḡḛḛṛ龍ผู้]"])])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṲṲṡḛḛṛ"]), _normalize(["ααṇ ṲṲṡḛḛṛ龍ผู้]"])])}
    },
    "auto_qa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṵṵṭṓṓǪḀḀ龍ผู้]"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗḭḭḛḛḍ龍ผู้]"])},
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ṃṓṓṛḛḛ龍ผู้]"])},
    "login_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡḛḛṡṡḭḭṓṓṇ ḥααṡ ḛḛẋṗḭḭṛḛḛḍ.龍ผู้]"])},
    "logins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḭḭḡṇ-ḭḭṇṡ龍ผู้]"])},
    "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ͼααṇṇṓṓṭ ḅḛḛ ḛḛṃṗṭẏẏ.龍ผู้]"])},
    "no_items_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḭḭṭḛḛṃṡ ϝṓṓṵṵṇḍ.龍ผู้]"])},
    "no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṃααṭͼḥḭḭṇḡ ṗḛḛṓṓṗḽḛḛ.龍ผู้]"])},
    "owner": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṎṎẁṇḛḛṛ"]), _normalize(["ααṇ ṎṎẁṇḛḛṛ龍ผู้]"])])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ṃṓṓṛḛḛ龍ผู้]"])},
    "read_more_arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ṃṓṓṛḛḛ龍ผู้]"])},
    "read_more_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ṃṓṓṛḛḛ.龍ผู้]"])},
    "register_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḛḛϝṓṓṛḛḛ ẁḛḛ ṡṭααṛṭ, ṛḛḛααḍ ααṇḍ ͼṓṓṇṡḛḛṇṭ ṭṓṓ ṓṓṵṵṛ ṭḛḛṛṃṡ ααṇḍ ẁḛḛ’ḽḽ ḅḛḛ ḡṓṓṓṓḍ ṭṓṓ ḡṓṓ.龍ผู้]"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṇṭ龍ผู้]"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛḛḛ?龍ผู้]"])},
    "team_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ḍṓṓṇḛḛ ṓṓṽḛḛṛααḽḽ龍ผู้]"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛḍ龍ผู้]"])},
    "workspace_role": {
      "agent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀḡḛḛṇṭ"]), _normalize(["ααṇ ḀḀḡḛḛṇṭ龍ผู้]"])])},
      "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḻḛḛααḍ"]), _normalize(["αα Ḻḛḛααḍ龍ผู้]"])])},
      "manager": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṁααṇααḡḛḛṛ"]), _normalize(["αα Ṁααṇααḡḛḛṛ龍ผู้]"])])},
      "reviewer": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ"]), _normalize(["αα Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ龍ผู้]"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
    "anonymous_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṓṓṇẏẏṃṓṓṵṵṡ ṵṵṡḛḛṛ龍ผู้]"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ龍ผู้]"])},
    "assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡṡḭḭḡṇḛḛḛḛ龍ผู้]"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααͼḳ龍ผู้]"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇͼḛḛḽ龍ผู้]"])},
    "ces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈḚḚṢ龍ผู้]"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ龍ผู้]"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽṓṓṡḛḛ龍ผู้]"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṓṓṇ龍ผู้]"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ龍ผู้]"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭḭḭṇṵṵḛḛ龍ผู้]"])},
    "copy_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḽḭḭͼḳ ṭṓṓ ͼṓṓṗẏẏ龍ผู้]"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṗẏẏ ḽḭḭṇḳ龍ผู้]"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ龍ผู้]"])},
    "csat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḈṢḀḀṪ龍ผู้]"])},
    "danger_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍααṇḡḛḛṛ ẓṓṓṇḛḛ龍ผู้]"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ龍ผู้]"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṇẏẏ龍ผู้]"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
    "dialog_default_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇϝḭḭṛṃααṭḭḭṓṓṇ ṛḛḛʠṵṵḭḭṛḛḛḍ龍ผู้]"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡααḅḽḛḛ龍ผู้]"])},
    "discard_unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡͼααṛḍ ṵṵṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ?龍ผู้]"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ龍ผู้]"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽ龍ผู้]"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṇααḅḽḛḛ龍ผู้]"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗṓṓṛṭ龍ผู้]"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḛḛḛḛḍḅααͼḳ龍ผู้]"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟḭḭḽṭḛḛṛ龍ผู้]"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛḛḛʠṵṵḛḛṇͼẏẏ龍ผู้]"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓααḽ龍ผู้]"])},
    "got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṓṓṭ ḭḭṭ龍ผู้]"])},
    "helpdesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ龍ผู้]"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ龍ผู้]"])},
    "iqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬǪṢ龍ผู้]"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṇḡṵṵααḡḛḛṡ龍ผู้]"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
    "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛṽḛḛṛ龍ผู้]"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẋṭ龍ผู้]"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḭḭϝḭḭͼααṭḭḭṓṓṇṡ龍ผู้]"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḰ龍ผู้]"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṭḥḛḛṛ龍ผู้]"])},
    "pass_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṡ ṛααṭḛḛ龍ผู้]"])},
    "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṡṓṓṇααḽ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭṓṓṵṵṡ龍ผู้]"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛṓṓϝḭḭḽḛḛ龍ผู้]"])},
    "read_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααḍ ḽḛḛṡṡ龍ผู้]"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ龍ผู้]"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṇḍ龍ผู้]"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṭṓṓṛḛḛ龍ผู้]"])},
    "reviewee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛḛḛ龍ผู้]"])},
    "reviewees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ṛḛḛͼḛḛḭḭṽḛḛḍ/ṡḛḛḛḛṇ龍ผู้]"])},
    "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])},
    "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ḍṓṓṇḛḛ ḅẏẏ ẁṓṓṛḳṡṗααͼḛḛ ṃḛḛṃḅḛḛṛ龍ผู้]"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓḽḛḛ龍ผู้]"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ龍ผู้]"])},
    "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ͼḥααṇḡḛḛṡ龍ผู้]"])},
    "scale_labels": {
      "scale_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃḭḭṇααṛẏẏ ṡͼααḽḛḛ龍ผู้]"])},
      "scale_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍3-ṗṓṓḭḭṇṭ ṡͼααḽḛḛ龍ผู้]"])},
      "scale_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍4-ṗṓṓḭḭṇṭ ṡͼααḽḛḛ龍ผู้]"])},
      "scale_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍5-ṗṓṓḭḭṇṭ ṡͼααḽḛḛ龍ผู้]"])}
    },
    "scorecard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍ龍ผู้]"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ龍ผู้]"])},
    "select_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ϝṛḛḛʠṵṵḛḛṇͼẏẏ龍ผู้]"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ḽḛḛṡṡ龍ผู้]"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṃṓṓṛḛḛ龍ผู้]"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḳḭḭṗ龍ผู้]"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ龍ผู้]"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṗṓṓṭḽḭḭḡḥṭ龍ผู้]"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭααṭṵṵṡ龍ผู้]"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṭḛḛṗ龍ผู้]"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ龍ผู้]"])},
    "survey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ龍ผู้]"])},
    "time_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḭḭṃḛḛ ṛααṇḡḛḛ龍ผู้]"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫẏẏṗḛḛ龍ผู้]"])},
    "unsaved_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ ṃααḍḛḛ龍ผู้]"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṗḍααṭḛḛ龍ผู้]"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵḛḛḭḭḡḥṭ龍ผู้]"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
    "you_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ḅḛḛḛḛṇ ḭḭṇṽḭḭṭḛḛḍ ḅẏẏ ", _interpolate(_list(0)), " ṭṓṓ ĵṓṓḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ.龍ผู้]"])}
  },
  "user_management": {
    "account_users": {
      "zendesk_manage_users_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ͼṓṓṇṇḛḛͼṭḛḛḍ ϝṛṓṓṃ ṭḥḛḛ ḀḀḍṃḭḭṇ Ḉḛḛṇṭḛḛṛ. ", _interpolate(_named("link")), "龍ผู้]"])},
      "zendesk_manage_users_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])},
      "bubble": {
        "link_scim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṵṵṡḛḛṛ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])},
        "content_scim": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ṵṵṡḛḛṛṡ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṗṛṓṓṽḭḭṡḭḭṓṓṇḛḛḍ ααṇḍ ṡẏẏṇͼḛḛḍ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḭḭḍḛḛṇṭḭḭṭẏẏ ṗṛṓṓṽḭḭḍḛḛṛ. ", _interpolate(_named("link")), "龍ผู้]"])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṓṓϝ “", _interpolate(_list(0)), "” ͼḥααṇḡḛḛḍ龍ผู้]"])},
      "permission_modal": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ṵṵṡḛḛṛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "do_not_ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṓṓṇ'ṭ ααṡḳ ṃḛḛ ααḡααḭḭṇ龍ผู้]"])},
        "help_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼḥααṇḡḛḛṡ ṃααḍḛḛ ṓṓṇ ṵṵṡḛḛṛṡ ṗααḡḛḛ ṛḛḛϝḽḛḛͼṭ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ. ", _interpolate(_named("help2")), ". ", _interpolate(_named("link")), "龍ผู้]"])},
        "help_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃḛḛααṇṡ ṵṵṡḛḛṛ'ṡ ααͼͼḛḛṡṡ ṭṓṓ ͼḛḛṛṭααḭḭṇ ṗααṛṭṡ ṓṓϝ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṃααẏẏ ͼḥααṇḡḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ααͼͼṓṓṵṵṇṭ ḽḛḛṽḛḛḽ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])}
      },
      "remove": {
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ṛḛḛṃṓṓṽḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ŵḥḛḛṇ ẏẏṓṓṵṵ ṛḛḛṃṓṓṽḛḛ αα ṵṵṡḛḛṛ, ṭḥḛḛẏẏ ẁḭḭḽḽ ḽṓṓṡḛḛ ααͼͼḛḛṡṡ ṭṓṓ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ. Ṫḥḛḛḭḭṛ ṛḛḛṽḭḭḛḛẁṡ, ͼṓṓṃṃḛḛṇṭṡ, ααṇḍ ṛααṭḭḭṇḡṡ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ. ", _interpolate(_named("linebreak")), "Ṛḛḛṃṓṓṽḭḭṇḡ αα ṵṵṡḛḛṛ ẁḭḭḽḽ ṇṓṓṭ ααϝϝḛḛͼṭ ṭḥḛḛ ṇṵṵṃḅḛḛṛ ṓṓϝ ṡḛḛααṭṡ ḭḭṇ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ.龍ผู้]"])},
        "description_ws_manager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṫḥḭḭṡ ṵṵṡḛḛṛ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ααḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ ẏẏṓṓṵṵ ṃααṇααḡḛḛ. ", _interpolate(_named("linebreak")), " Ṫḥḛḛḭḭṛ ṛḛḛṽḭḭḛḛẁṡ, ͼṓṓṃṃḛḛṇṭṡ, ααṇḍ ṛααṭḭḭṇḡṡ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ḭḭṇṭααͼṭ."]), _normalize(["Ṫḥḛḛẏẏ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ααḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ ẏẏṓṓṵṵ ṃααṇααḡḛḛ. ", _interpolate(_named("linebreak")), " ḀḀḽḽ ṓṓϝ ṭḥḛḛḭḭṛ ṛḛḛṽḭḭḛḛẁṡ, ͼṓṓṃṃḛḛṇṭṡ, ααṇḍ ṛααṭḭḭṇḡṡ ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ḭḭṇṭααͼṭ.龍ผู้]"])])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("name"))]), _normalize(["Ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("name")), " ṵṵṡḛḛṛṡ龍ผู้]"])])}
      },
      "account_permissions": {
        "payment_manage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇ ṡḛḛḛḛ ḛḛṽḛḛṛẏẏṭḥḭḭṇḡ ααṇḍ ṃααṇααḡḛḛ ααḽḽ ṡḛḛṭṭḭḭṇḡṡ ααṇḍ ḅḭḭḽḽḭḭṇḡ龍ผู้]"])},
        "teams_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṇ ṡḛḛḛḛ ḛḛṽḛḛṛẏẏṭḥḭḭṇḡ ααṇḍ ṃααṇααḡḛḛ ṗḛḛṛṡṓṓṇααḽ ααṇḍ ẁṓṓṛḳṡṗααͼḛḛ ṡḛḛṭṭḭḭṇḡṡ, ḛḛẋͼḛḛṗṭ ḅḭḭḽḽḽḭḭṇḡ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "user_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ-ṡṗḛḛͼḭḭϝḭḭͼ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ααḽḽṓṓẁ ṵṵṡḛḛṛ ṭṓṓ ṡḛḛḛḛ (ααṇḍ) ḛḛḍḭḭṭ ḍααṭαα ẁḭḭṭḥḭḭṇ ẁṓṓṛḳṡṗααͼḛḛṡ ṭḥḛḛẏẏ'ṛḛḛ ḭḭṇ.龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])}
    },
    "all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
    "bots": {
      "empty_state": {
        "learn_more_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ḂṓṓṭǪḀḀ龍ผู้]"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ḅṓṓṭṡ ααṗṗḛḛααṛ ḥḛḛṛḛḛ ααṡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭḥḛḛẏẏ'ṽḛḛ ṗααṛṭḭḭͼḭḭṗααṭḛḛḍ ḭḭṇ ααṛḛḛ ṡẏẏṇͼḛḛḍ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḅṓṓṭṡ ṡẏẏṇͼḛḛḍ龍ผู้]"])}
      },
      "mark_as_user_modal": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳḭḭṇḡ αα ḅṓṓṭ ααṡ αα ṵṵṡḛḛṛ ẁḭḭḽḽ ḡṛααṇṭ ṭḥḛḛṃ ααͼͼḛḛṡṡ ṭṓṓ ṡḭḭḡṇ-ḭḭṇ. Ṫḥḛḛḭḭṛ ḥḭḭṡṭṓṓṛḭḭͼααḽ ḅṓṓṭ ḍααṭαα ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ααṽααḭḭḽααḅḽḛḛ ṓṓṇ ḍααṡḥḅṓṓααṛḍṡ.龍ผู้]"])}
      },
      "no_bots_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵͼḥ ḅṓṓṭṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "no_bots_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṡḛḛααṛͼḥḭḭṇḡ ααḡααḭḭṇ ṵṵṡḭḭṇḡ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ḳḛḛẏẏẁṓṓṛḍ ṓṓṛ ṡṗḛḛḽḽḭḭṇḡ.龍ผู้]"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ ḅṓṓṭṡ龍ผู้]"])},
      "search_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ḅṓṓṭ ṇααṃḛḛ龍ผู้]"])},
      "table": {
        "actions": {
          "mark_as_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ṵṵṡḛḛṛ龍ผู้]"])},
          "mark_as_user_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ααḍṃḭḭṇṡ ααṇḍ ααͼͼṓṓṵṵṇṭ ṃααṇααḡḛḛṛṡ ͼααṇ ṃααṛḳ ḅṓṓṭṡ ααṡ ṵṵṡḛḛṛṡ龍ผู้]"])}
        },
        "first_column_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭ ṇααṃḛḛ龍ผู้]"])},
        "last_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ͼḥααṭ龍ผู้]"])},
        "manually_marked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽḽẏẏ ṃααṛḳḛḛḍ龍ผู้]"])},
        "reviewable": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁααḅḽḛḛ龍ผู้]"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ龍ผู้]"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ḅṓṓṭ ḭḭṡ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ααṵṵṭṓṓ-ααṇααḽẏẏṡḭḭṡ ααṇḍ ααṇẏẏ ααṡṡḭḭḡṇṃḛḛṇṭṡ. Ṅḛḛẁ ḍααṭαα ẁṓṓṇ'ṭ ααṗṗḛḛααṛ ḭḭṇ ḍααṡḥḅṓṓααṛḍṡ, ḛḛṇṡṵṵṛḭḭṇḡ ααͼͼṵṵṛααṭḛḛ ṛḛḛṗṓṓṛṭḭḭṇḡ ααṇḍ ḛḛααṡḭḭḛḛṛ ḅṓṓṭ ṃααṇααḡḛḛṃḛḛṇṭ.龍ผู้]"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶḛḛṡ龍ผู้]"])}
        },
        "type": {
          "generative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḛḛṇḛḛṛααṭḭḭṽḛḛ ḅṓṓṭ龍ผู้]"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇḳṇṓṓẁṇ龍ผู้]"])},
          "workflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳϝḽṓṓẁ ḅṓṓṭ龍ผู้]"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])}
    },
    "bulk_edit": {
      "add_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ ḡṛṓṓṵṵṗ(ṡ)龍ผู้]"])},
      "all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "no_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡṛṓṓṵṵṗ龍ผู้]"])},
      "remove_from_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ϝṛṓṓṃ ḡṛṓṓṵṵṗ(ṡ)龍ผู้]"])},
      "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ḡṛṓṓṵṵṗ龍ผู้]"])}
    },
    "columns": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "account_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])},
      "add_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛ(ṡ)龍ผู้]"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṵṵḽḽ ṇααṃḛḛ龍ผู้]"])},
      "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻααṡṭ ααͼṭḭḭṽḛḛ龍ผู้]"])},
      "permission_tooltip_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼṓṓṵṵṇṭ ḽḛḛṽḛḛḽ ṛṓṓḽḛḛ龍ผู้]"])},
      "permission_tooltip_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααͼḥ ṵṵṡḛḛṛ ḭḭṡ ααṡṡḭḭḡṇḛḛḍ ααṇ ḀḀͼͼṓṓṵṵṇṭ ααṇḍ/ṓṓṛ Ŵṓṓṛḳṡṗααͼḛḛ ḽḛḛṽḛḛḽ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ. ḀḀḍṃḭḭṇ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ααṛḛḛ ṇṓṓẁ ααͼͼṓṓṵṵṇṭ ḽḛḛṽḛḛḽ, ẁḥḭḭḽṡṭ ṓṓṭḥḛḛṛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ṓṓṇ ṭḥḛḛ Ŵṓṓṛḳṡṗααͼḛḛ ḽḛḛṽḛḛḽ.龍ผู้]"])},
      "remove_from_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ(ṡ)龍ผู้]"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛ龍ผู้]"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])},
      "workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])},
      "workspace_permission_managed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ṃααṇααḡḛḛḍ ḭḭṇ ḡṛṓṓṵṵṗ龍ผู้]"])}
    },
    "edit_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḍḛḛṭααḭḭḽṡ龍ผู้]"])},
    "mark_as_bot": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳ ααṡ ḅṓṓṭ龍ผู้]"])},
      "modal": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṛḳḭḭṇḡ αα ṵṵṡḛḛṛ ααṡ αα ḅṓṓṭ ẁḭḭḽḽ ṛḛḛṃṓṓṽḛḛ ṭḥḛḛḭḭṛ ααͼͼḛḛṡṡ ṭṓṓ ṡḭḭḡṇ-ḭḭṇ. Ṫḥḛḛḭḭṛ ḥḭḭṡṭṓṓṛḭḭͼααḽ ṵṵṡḛḛṛ ḍααṭαα ẁḭḭḽḽ ṛḛḛṃααḭḭṇ ααṽααḭḭḽααḅḽḛḛ ṓṓṇ ḍααṡḥḅṓṓααṛḍṡ.龍ผู้]"])}
      },
      "review_checkbox": {
        "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ṭḥḛḛ ḅṓṓṭ ϝṛṓṓṃ ααṵṵṭṓṓ-ααṇααḽẏẏṡḭḭṡ ααṇḍ ααṇẏẏ ααṡṡḭḭḡṇṃḛḛṇṭṡ. Ṅḛḛẁ ḍααṭαα ẁṓṓṇ'ṭ ααṗṗḛḛααṛ ḭḭṇ ḍααṡḥḅṓṓααṛḍṡ, ḛḛṇṡṵṵṛḭḭṇḡ ααͼͼṵṵṛααṭḛḛ ṛḛḛṗṓṓṛṭḭḭṇḡ ααṇḍ ḛḛααṡḭḭḛḛṛ ḅṓṓṭ ṃααṇααḡḛḛṃḛḛṇṭ.龍ผู้]"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋͼḽṵṵḍḛḛ ḅṓṓṭ ϝṛṓṓṃ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
      },
      "toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ ṃααṛḳḛḛḍ ααṡ ḅṓṓṭ龍ผู้]"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṭẏẏṗḛḛ ṲṲṡḛḛṛ ͼααṇ ḅḛḛ ṃααṛḳḛḛḍ ααṡ ḅṓṓṭ龍ผู้]"])},
      "tooltip_multi_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ẁḭḭṭḥ ṃṵṵḽṭḭḭṗḽḛḛ ααͼͼṓṓṵṵṇṭṡ ͼααṇṇṓṓṭ ḅḛḛ ṃααṛḳḛḛḍ ααṡ αα ḅṓṓṭ龍ผู้]"])}
    },
    "search_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ṵṵṡḛḛṛ龍ผู้]"])},
    "sidebar": {
      "bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṓṓṭṡ龍ผู้]"])},
      "title_bots_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ, ḅṓṓṭṡ, ααṇḍ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "workspaces_uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŴṎṎṚḰṢṔḀḀḈḚḚṢ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααṇḍ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "account_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])}
    },
    "user_state": {
      "status": {
        "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṃḭḭṇ龍ผู้]"])},
        "no_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])},
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
        "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḛḛḍ龍ผู้]"])},
        "manual_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇṵṵααḽḽẏẏ ααḍḍḛḛḍ龍ผู้]"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṇḍḭḭṇḡ龍ผู้]"])},
        "pending_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṇḍḭḭṇḡ ḭḭṇṽḭḭṭḛḛ龍ผู้]"])},
        "scim_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢḈḬḬṀ ṵṵṡḛḛṛṡ龍ผู้]"])}
      },
      "action_needed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṓṓṇ ṇḛḛḛḛḍḛḛḍ龍ผู้]"])}
    },
    "edit_user": {
      "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍...ααṇḍ ṓṓṇḛḛ ṃṓṓṛḛḛ"]), _normalize(["...ααṇḍ ", _interpolate(_named("n")), " ṃṓṓṛḛḛ龍ผู้]"])])},
      "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇḡḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṭṓṓ龍ผู้]"])},
      "reinvite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ααṇḍ ṛḛḛḭḭṇṽḭḭṭḛḛ ṭṓṓ ͼḥααṇḡḛḛ ṛṓṓḽḛḛ ṓṓϝ ", _interpolate(_named("n")), " ṗḛḛṇḍḭḭṇḡ ḭḭṇṽḭḭṭḛḛ ṵṵṡḛḛṛ."]), _normalize(["Ṛḛḛṃṓṓṽḛḛ ααṇḍ ṛḛḛḭḭṇṽḭḭṭḛḛ ṭṓṓ ͼḥααṇḡḛḛ ṛṓṓḽḛḛ ṓṓϝ ", _interpolate(_named("n")), " ṗḛḛṇḍḭḭṇḡ ḭḭṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḚḚḍḭḭṭ ḍḛḛṭααḭḭḽṡ ϝṓṓṛ ", _interpolate(_named("name"))]), _normalize(["ḚḚḍḭḭṭ ḍḛḛṭααḭḭḽṡ ϝṓṓṛ ", _interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ ṵṵṡḛḛṛṡ龍ผู้]"])])},
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ṵṵṗḍααṭḛḛḍ."]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ṵṵṗḍααṭḛḛḍ.龍ผู้]"])])}
    },
    "groups": {
      "group": {
        "other_members_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṫḥḭḭṡ ḡṛṓṓṵṵṗ ͼṓṓṇṭααḭḭṇṡ 1 ṵṵṡḛḛṛ ϝṛṓṓṃ ααṇṓṓṭḥḛḛṛ ẁṓṓṛḳṡṗααͼḛḛ."]), _normalize(["Ṫḥḭḭṡ ḡṛṓṓṵṵṗ ͼṓṓṇṭααḭḭṇṡ ", _interpolate(_named("n")), " ṵṵṡḛḛṛṡ ϝṛṓṓṃ ααṇṓṓṭḥḛḛṛ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])])},
        "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽααṭḛḛ龍ผู้]"])},
        "archived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ααṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
        "archived_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛḍ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
        "default_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṭḭḭṭḽḛḛḍ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "delete_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ḡṛṓṓṵṵṗ?龍ผู้]"])},
        "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "group_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ḽḛḛααḍ龍ผู้]"])},
        "group_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ Ṛṓṓḽḛḛ龍ผู้]"])},
        "hidden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ḥḭḭḍḍḛḛṇ龍ผู้]"])},
        "hidden_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḡṛṓṓṵṵṗ ẁḭḭḽḽ ṇṓṓṭ ḅḛḛ ṽḭḭṡḭḭḅḽḛḛ ααṇẏẏẁḥḛḛṛḛḛ ḛḛḽṡḛḛ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
        "hidden_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ṃααṇααḡḛḛ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "hidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṇṓṓṭ ṽḭḭṡḭḭḅḽḛḛ ḭḭṇ Żḛḛṇḍḛḛṡḳ ǪḀḀ龍ผู้]"])},
        "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααḍ龍ผู้]"])},
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṃḅḛḛṛ龍ผู้]"])},
        "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṭḭḭṭḽḛḛḍ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "name_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṓṓṭḥḛḛṛ ḡṛṓṓṵṵṗ ααḽṛḛḛααḍẏẏ ḥααṡ ṭḥḭḭṡ ṇααṃḛḛ龍ผู้]"])},
        "name_taken_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ααṛͼḥḭḭṽḛḛḍ ḡṛṓṓṵṵṗ ααḽṛḛḛααḍẏẏ ḥααṡ ṭḥḭḭṡ ṇααṃḛḛ龍ผู้]"])},
        "other_members_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṛṓṓḽḛḛ ṓṓṇḽẏẏ ααḽḽṓṓẁṡ ṃααṇααḡḭḭṇḡ ṃḛḛṃḅḛḛṛṡ ṓṓϝ ṭḥḭḭṡ ḡṛṓṓṵṵṗ. Ḉṓṓṇṭααͼṭ αα ṵṵṡḛḛṛ ẁḭḭṭḥ ḥḭḭḡḥḛḛṛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ϝṓṓṛ ϝṵṵṛṭḥḛḛṛ ͼḥααṇḡḛḛṡ.龍ผู้]"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ龍ผู้]"])},
        "remove_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ααṡ ḡṛṓṓṵṵṗ ḽḛḛααḍ龍ผู้]"])},
        "restored": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ṛḛḛṡṭṓṓṛḛḛḍ龍ผู้]"])},
        "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ṡααṽḛḛḍ龍ผู้]"])},
        "set_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṭ ααṡ ḡṛṓṓṵṵṗ ḽḛḛααḍ龍ผู้]"])},
        "unarchived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ṵṵṇααṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
        "unsaved_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṡααṽḛḛḍ ͼḥααṇḡḛḛṡ龍ผู้]"])},
        "user_input_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ẏẏṓṓṵṵṛ ḛḛẋḭḭṡṭḭḭṇḡ ṵṵṡḛḛṛṡ ṭṓṓ ṭḥḭḭṡ ḡṛṓṓṵṵṗ龍ผู้]"])},
        "user_picker_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ṇααṃḛḛ ṓṓṛ ḛḛṃααḭḭḽ龍ผู้]"])},
        "users_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
        "visible": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍\"", _interpolate(_named("name")), "\" ḡṛṓṓṵṵṗ ṽḭḭṡḭḭḅḽḛḛ龍ผู้]"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
      },
      "permission_picking_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍ḚḚααͼḥ ṃḛḛṃḅḛḛṛ ḭḭṡ ", _interpolate(_named("workspace_role")), "龍ผู้]"])},
      "empty_state_readonly": {
        "content_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ ḡṛṓṓṵṵṗṡ龍ผู้]"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṃḛḛ ααṭṭṛḭḭḅṵṵṭḛḛṡ ṓṓϝ ḡṛṓṓṵṵṗṡ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ṗṛṓṓṽḭḭṡḭḭṓṓṇḛḛḍ ααṇḍ ṡẏẏṇͼḛḛḍ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḭḭḍḛḛṇṭḭḭṭẏẏ ṗṛṓṓṽḭḭḍḛḛṛ.龍ผู้]"])}
      },
      "groups_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṛṇ ααḅṓṓṵṵṭ Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ Ḡṛṓṓṵṵṗ龍ผู้]"])},
      "empty_state": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚααṡḭḭḽẏẏ ṓṓṛḡααṇḭḭẓḛḛ ẏẏṓṓṵṵṛ ṭḛḛααṃ ṃḛḛṃḅḛḛṛṡ ααṇḍ ṃḭḭṃḭḭͼ ẏẏṓṓṵṵṛ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇααḽ ṡṭṛṵṵͼṭṵṵṛḛḛ.龍ผู้]"])}
      },
      "errors": {
        "empty_members_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṭ ḽḛḛααṡṭ 1 ṃḛḛṃḅḛḛṛ龍ผู้]"])},
        "empty_name_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ αα ḡṛṓṓṵṵṗ ṇααṃḛḛ龍ผู้]"])},
        "existing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀ ḡṛṓṓṵṵṗ ẁḭḭṭḥ ṭḥḭḭṡ ṇααṃḛḛ ααḽṛḛḛααḍẏẏ ḛḛẋḭḭṡṭṡ龍ผู้]"])}
      },
      "group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
      "group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗ ṇααṃḛḛ龍ผู้]"])},
      "help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṛḡααṇḭḭẓḛḛ ẏẏṓṓṵṵṛ ṭḛḛααṃ ṃḛḛṃḅḛḛṛṡ ααṇḍ ṃḭḭṃḭḭͼ ẏẏṓṓṵṵṛ ṓṓṛḡααṇḭḭẓααṭḭḭṓṓṇααḽ ṡṭṛṵṵͼṭṵṵṛḛḛ.龍ผู้]"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṃḅḛḛṛṡ龍ผู้]"])},
      "n_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṅṓṓ ḡṛṓṓṵṵṗṡ"]), _normalize(["1 ḡṛṓṓṵṵṗ"]), _normalize([_interpolate(_named("count")), " ḡṛṓṓṵṵṗṡ龍ผู้]"])])},
      "no_active_groups_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ αα ṇḛḛẁ ḡṛṓṓṵṵṗ ṓṓṛ ααͼṭḭḭṽααṭḛḛ ααṇ ααṛͼḥḭḭṽḛḛḍ ṓṓṇḛḛ ḭḭṇṡṭḛḛααḍ.龍ผู้]"])},
      "no_active_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααͼṭḭḭṽḛḛ ḡṛṓṓṵṵṗṡ ααṽααḭḭḽααḅḽḛḛ龍ผู้]"])},
      "no_archived_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ααṛͼḥḭḭṽḛḛḍ ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵͼḥ ḡṛṓṓṵṵṗṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "no_groups_found_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṛẏẏ ṡḛḛααṛͼḥḭḭṇḡ ααḡααḭḭṇ ṵṵṡḭḭṇḡ αα ḍḭḭϝϝḛḛṛḛḛṇṭ ḳḛḛẏẏẁṓṓṛḍ ṓṓṛ ṡṗḛḛḽḽḭḭṇḡ.龍ผู้]"])},
      "no_groups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡṛṓṓṵṵṗṡ ααḍḍḛḛḍ龍ผู้]"])},
      "permission_picking_2": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḭḭṇ ṭḥḛḛṡḛḛ"]), _normalize(["ḭḭṇ"]), _normalize(["ḭḭṇ龍ผู้]"])])},
      "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ḡṛṓṓṵṵṗ ṇααṃḛḛ龍ผู้]"])},
      "tabs": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛḍ龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])}
    },
    "resend_invite_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṇḍ ṗṓṓṡṡḭḭḅḽḛḛ ", _interpolate(_list(0)), "龍ผู้]"])},
    "seats_left": {
      "pill": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṡḛḛααṭṡ ḽḛḛϝṭ"]), _normalize([_interpolate(_named("n")), " ṡḛḛααṭ ḽḛḛϝṭ"]), _normalize([_interpolate(_named("n")), " ṡḛḛααṭṡ ḽḛḛϝṭ龍ผู้]"])])},
      "paused_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ṡḛḛααṭ ͼṓṓṵṵṇṭ ḭḭṡ ͼṵṵṛṛḛḛṇṭḽẏẏ ḽḭḭṃḭḭṭḛḛḍ, ααṡ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ ḭḭṡ ṓṓṇ ṗααṵṵṡḛḛ. Ṛḛḛααͼṭḭḭṽααṭḛḛ ḭḭṭ ṭṓṓ ααḍḍ ṃṓṓṛḛḛ ṵṵṡḛḛṛṡ.龍ผู้]"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṽḛḛ ṛḛḛααͼḥḛḛḍ ṭḥḛḛ ḽḭḭṃḭḭṭ ṓṓϝ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ ṡḛḛααṭṡ. ŶŶṓṓṵṵ ͼααṇ ṛḛḛṗḽααͼḛḛ ṵṵṡḛḛṛṡ ḅẏẏ ḍḛḛααͼṭḭḭṽααṭḭḭṇḡ ααṇ ααͼṭḭḭṽḛḛ ṵṵṡḛḛṛ ṓṓṛ ααḍḍḭḭṇḡ ṃṓṓṛḛḛ ṡḛḛααṭṡ.龍ผู้]"])},
      "view_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṿḭḭḛḛẁ ṗααẏẏṃḛḛṇṭ ḍḛḛṭααḭḭḽṡ龍ผู้]"])}
    },
    "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])])},
    "selected_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ṡḛḛḽḛḛͼṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ṡḛḛḽḛḛͼṭḛḛḍ龍ผู้]"])])},
    "user_filter": {
      "rows": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṛṓṓẁṡ"]), _normalize([_interpolate(_named("n")), " ṛṓṓẁ"]), _normalize([_interpolate(_named("n")), " ṛṓṓẁṡ龍ผู้]"])])},
      "bulk_search_modal": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ṵṵṡḛḛṛṡ ḭḭṇ ḅṵṵḽḳ ḅẏẏ ṭḥḛḛḭḭṛ ṇααṃḛḛṡ ṓṓṛ ḛḛṃααḭḭḽ ααḍḍṛḛḛṡṡḛḛṡ.龍ผู้]"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṭḛḛ ḍααṭαα ḥḛḛṛḛḛ ṡḛḛṗααṛααṭḛḛḍ ḅẏẏ ͼṓṓṃṃααṡ ṓṓṛ ṇḛḛẁḽḭḭṇḛḛṡ龍ผู้]"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵḽḳ ṡḛḛααṛͼḥ ṵṵṡḛḛṛṡ龍ผู้]"])}
      }
    },
    "workspaces": {
      "edit_permissions": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḚḚḍḭḭṭ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ϝṓṓṛ ", _interpolate(_named("name"))]), _normalize(["ḚḚḍḭḭṭ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ ϝṓṓṛ ", _interpolate(_named("n")), " ṡḛḛḽḛḛͼṭḛḛḍ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "change_permission_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])}
      },
      "remove": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("name")), " ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ"]), _normalize(["Ṛḛḛṃṓṓṽḛḛ ", _interpolate(_named("name")), " ṃḛḛṃḅḛḛṛṡ ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])])},
        "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṃḛḛṃḅḛḛṛ ṛḛḛṃṓṓṽḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṃḛḛṃḅḛḛṛṡ ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])])},
        "confirm_button": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ṃḛḛṃḅḛḛṛ"]), _normalize(["Ṛḛḛṃṓṓṽḛḛ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])])},
        "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṫḥḭḭṡ ṵṵṡḛḛṛ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ."]), _normalize(["Ṫḥḛḛṡḛḛ ṵṵṡḛḛṛṡ ẁḭḭḽḽ ḅḛḛ ṛḛḛṃṓṓṽḛḛḍ ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])])}
      },
      "permission_changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ṓṓϝ “", _interpolate(_list(0)), "” ͼḥααṇḡḛḛḍ.龍ผู้]"])},
      "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṃḛḛṃḅḛḛṛṡ龍ผู้]"])},
      "permission_picker": {
        "agent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααṇḍ ṛḛḛḽααṭḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
        "lead_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ḛḛṽḛḛṛẏẏṭḥḭḭṇḡ ḭḭṇ ṭḥḛḛ ẁṓṓṛḳṡṗααͼḛḛ, ḅṵṵṭ ṃααṇααḡḛḛ ʠṵṵḭḭẓẓḛḛṡ, ααṡṡḭḭḡṇṃḛḛṇṭṡ, ḍḭḭṡṗṵṵṭḛḛṡ, ααṇḍ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṡḛḛṡṡḭḭṓṓṇṡ ṓṓṇḽẏẏ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
        "workspace_manager_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ααṇḍ ṃααṇααḡḛḛ ḛḛṽḛḛṛẏẏṭḥḭḭṇḡ ẁḭḭṭḥḭḭṇ ṭḥḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
        "workspace_reviewer_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛ ḛḛṽḛḛṛẏẏṭḥḭḭṇḡ ḭḭṇ ṭḥḛḛ ẁṓṓṛḳṡṗααͼḛḛ, ḛḛẋͼḛḛṗṭ ϝṓṓṛ ẁṓṓṛḳṡṗααͼḛḛ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])}
      }
    },
    "add_members": {
      "no_users": {
        "link_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ͼṓṓṇṇḛḛͼṭ ṵṵṡḛḛṛṡ龍ผู้]"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṵṵṡḛḛṛṡ ϝṛṓṓṃ ṭḥḛḛ ẁṓṓṛḳṡṗααͼḛḛṡ ẏẏṓṓṵṵ ṃααṇααḡḛḛ ḥααṽḛḛ ḅḛḛḛḛṇ ααḍḍḛḛḍ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḳ ααṇ ααḍṃḭḭṇ ṭṓṓ ḍṓṓ ṡṓṓ.龍ผู้]"])},
        "cta_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṇḛḛẁ ṵṵṡḛḛṛṡ ḍḭḭṛḛḛͼṭḽẏẏ ẏẏṓṓṵṵṛṡḛḛḽϝ ṓṓṛ ααṡḳ ααṇ ααḍṃḭḭṇ ṭṓṓ ḍṓṓ ṡṓṓ.龍ผู้]"])},
        "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("link_invite")), " ṓṓṛ ", _interpolate(_named("link_connect")), "龍ผู้]"])},
        "link_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṵṵṡḛḛṛṡ ṭṓṓ ḍḭḭṡṗḽααẏẏ龍ผู้]"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍 ", _interpolate(_named("n")), " ṵṵṡḛḛṛ ααḍḍḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ααḍḍḛḛḍ龍ผู้]"])])},
      "add_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḀḀḍḍ ṃḛḛṃḅḛḛṛṡ"]), _normalize(["ḀḀḍḍ ṃḛḛṃḅḛḛṛ"]), _normalize(["ḀḀḍḍ ", _interpolate(_named("n")), " ṃḛḛṃḅḛḛṛṡ龍ผู้]"])])},
      "select_workspace_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛṡ ṭṓṓ ṭḥḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṃḛḛṃḅḛḛṛṡ ṭṓṓ ", _interpolate(_list(0)), "龍ผู้]"])}
    },
    "connect_users": {
      "bulk_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṭḛḛ ḛḛṃααḭḭḽṡ ḥḛḛṛḛḛ ṡḛḛṗααṛααṭḛḛḍ ḅẏẏ ṇḛḛẁ ḽḭḭṇḛḛṡ ṓṓṛ ͼṓṓṃṃααṡ龍ผู้]"])},
      "bulk_match": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ṁααṭͼḥḛḛḍ ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " ḛḛṃααḭḭḽṡ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍 ẁḭḭṭḥ ααḽṛḛḛααḍẏẏ ͼṓṓṇṇḛḛͼṭḛḛḍ ṵṵṡḛḛṛṡ:龍ผู้]"])}
      },
      "bulk_mismatch": {
        "bold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḉṓṓṵṵḽḍ ṇṓṓṭ ṃααṭͼḥ ", _interpolate(_list(0)), "/", _interpolate(_list(1)), " ḛḛṃααḭḭḽṡ龍ผู้]"])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥḛḛͼḳ ṡṗḛḛḽḽḭḭṇḡ ṓṓṛ ͼṓṓṇṇḛḛͼṭ ṭḥḛḛṃ ḽααṭḛḛṛ ḭḭṇḍḭḭṽḭḭḍṵṵααḽḽẏẏ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍 ẁḭḭṭḥ ḥḛḛḽṗ ḍḛḛṡḳ ṵṵṡḛḛṛṡ:龍ผู้]"])}
      },
      "toasts": {
        "users_connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ͼṓṓṇṇḛḛͼṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ͼṓṓṇṇḛḛͼṭḛḛḍ龍ผู้]"])])},
        "users_invited": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ḭḭṇṽḭḭṭḛḛḍ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ḭḭṇṽḭḭṭḛḛḍ龍ผู้]"])])}
      },
      "bulk_connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵḽḳ ͼṓṓṇṇḛḛͼṭ龍ผู้]"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵḽḳ ḭḭṇṽḭḭṭḛḛ龍ผู้]"])},
      "bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṛ ḛḛẋααṃṗḽḛḛ:\n\nḳααṭḛḛ", "@", "ḛḛẋααṃṗḽḛḛ.ͼṓṓṃ, ĵṓṓḥṇ", "@", "ḛḛẋααṃṗḽḛḛ.ͼṓṓṃ\n\nḳααṭḛḛ", "@", "ḛḛẋααṃṗḽḛḛ.ͼṓṓṃ\nḳααṭḛḛ", "@", "ḛḛẋααṃṗḽḛḛ.ͼṓṓṃ龍ผู้]"])},
      "bulk_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ(ṡ) ṭṓṓ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛḭḭṛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "connect_btn": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ṵṵṡḛḛṛ"]), _normalize(["Ḉṓṓṇṇḛḛͼṭ ṵṵṡḛḛṛṡ龍ผู้]"])])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚṃααḭḭḽṡ龍ผู้]"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ḭḭṇḍḭḭṽḭḭḍṵṵααḽḽẏẏ龍ผู้]"])},
      "invite": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ẏẏṓṓṵṵ ẁααṇṭ ẏẏṓṓṵṵṛ ͼṓṓṇṇḛḛͼṭḛḛḍ ṵṵṡḛḛṛṡ ṭṓṓ ααͼͼḛḛṡṡ Żḛḛṇḍḛḛṡḳ ǪḀḀ, ẏẏṓṓṵṵ ͼααṇ ḭḭṇṽḭḭṭḛḛ ṭḥḛḛṃ ḥḛḛṛḛḛ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ͼṓṓṇṇḛḛͼṭḛḛḍ ṵṵṡḛḛṛṡ龍ผู้]"])}
      },
      "invite_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛ"]), _normalize(["ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])])},
      "skip_inviting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḳḭḭṗ ḭḭṇṽḭḭṭḭḭṇḡ龍ผู้]"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṵṵṡḛḛṛṡ ṭṓṓ ͼṓṓṇṇḛḛͼṭ ϝṛṓṓṃ ẏẏṓṓṵṵṛ ḥḛḛḽṗ ḍḛḛṡḳ(ṡ) ṭṓṓ ṛḛḛṽḭḭḛḛẁ ṭḥḛḛḭḭṛ ḭḭṇṭḛḛṛααͼṭḭḭṓṓṇṡ龍ผู้]"])},
      "tippy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁααṇααḡḛḛ ẏẏṓṓṵṵṛ ṡṵṵḅṡͼṛḭḭṗṭḭḭṓṓṇ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭ ḥḛḛḽṗ ḍḛḛṡḳ ṵṵṡḛḛṛṡ龍ผู้]"])}
    },
    "create_workspace": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛ ẏẏṓṓṵṵṛ ẁṓṓṛḳṡṗααͼḛḛ αα ṵṵṇḭḭʠṵṵḛḛ ṇααṃḛḛ龍ผู้]"])},
      "access_checkbox": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬϝ ḛḛṇααḅḽḛḛḍ, ααḽḽ ḛḛẋḭḭṡṭḭḭṇḡ ααͼͼṓṓṵṵṇṭ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααḍḍḛḛḍ ṭṓṓ ṭḥḛḛ ṇḛḛẁ ẁṓṓṛḳṡṗααͼḛḛ.龍ผู้]"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααḽḽ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
      },
      "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅααṃḛḛ龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛ ṇααṃḛḛ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
    },
    "invite_email": {
      "account_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααḍḍḛḛḍ ḍḭḭṛḛḛͼṭḽẏẏ ṭṓṓ ṭḥḛḛ ααͼͼṓṓṵṵṇṭ (ẁḭḭṭḥṓṓṵṵṭ ααṡṡḭḭḡṇḭḭṇḡ αα ẁṓṓṛḳṡṗααͼḛḛ) ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ααṇ ḀḀͼͼṓṓṵṵṇṭ-ḽḛḛṽḛḛḽ ṛṓṓḽḛḛ. ŶŶṓṓṵṵ ͼααṇ ααḍḍ ṭḥḛḛṃ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ ḽααṭḛḛṛ.龍ผู้]"])},
      "add_users_to_workspace_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛḍ ṵṵṡḛḛṛṡ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααṡṡḭḭḡṇḛḛḍ αα ṲṲṡḛḛṛ ṛṓṓḽḛḛ ṓṓṇ ṭḥḛḛ ḀḀͼͼṓṓṵṵṇṭ ḽḛḛṽḛḛḽ. ŶŶṓṓṵṵ ͼααṇ ṵṵṗḡṛααḍḛḛ ṭḥḛḛṃ ṭṓṓ ααṇ ḀḀḍṃḭḭṇ ṛṓṓḽḛḛ ḽααṭḛḛṛ.龍ผู้]"])},
      "bulk_text_placeholder": {
        "these_all_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛṡḛḛ ααḽḽ ẁṓṓṛḳ龍ผู้]"])},
        "paste_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṡṭḛḛ ḛḛṃααḭḭḽṡ ḥḛḛṛḛḛ ṡḛḛṗααṛααṭḛḛḍ ḅẏẏ ṇḛḛẁḽḭḭṇḛḛṡ ṓṓṛ ͼṓṓṃṃααṡ.龍ผู้]"])}
      },
      "toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍", _interpolate(_named("n")), " ṵṵṡḛḛṛ ḭḭṇṽḭḭṭḛḛḍ ḅẏẏ ḛḛṃααḭḭḽ"]), _normalize([_interpolate(_named("n")), " ṵṵṡḛḛṛṡ ḭḭṇṽḭḭṭḛḛḍ ḅẏẏ ḛḛṃααḭḭḽ龍ผู้]"])])},
      "workspace_permission_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ ααḍḍḛḛḍ ṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ ααṛḛḛ ααṵṵṭṓṓṃααṭḭḭͼααḽḽẏẏ ααṡṡḭḭḡṇḛḛḍ αα ṲṲṡḛḛṛ ṛṓṓḽḛḛ ṓṓṇ ṭḥḛḛ ḀḀͼͼṓṓṵṵṇṭ ḽḛḛṽḛḛḽ. ŶŶṓṓṵṵ ͼααṇ ṵṵṗḡṛααḍḛḛ ṭḥḛḛṃ ṭṓṓ ααṇ ḀḀḍṃḭḭṇ ṛṓṓḽḛḛ ḽααṭḛḛṛ.龍ผู้]"])},
      "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ααṇṓṓṭḥḛḛṛ龍ผู้]"])},
      "add_users_to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ ĵṵṵṡṭ ṭṓṓ ṭḥḛḛ ααͼͼṓṓṵṵṇṭ ẁḭḭṭḥṓṓṵṵṭ ααḍḍḭḭṇḡ ṭḥḛḛṃ ṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "add_users_to_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛḍ ṵṵṡḛḛṛṡ ααṛḛḛ ααṡṡḭḭḡṇḛḛḍ ṓṓṇḽẏẏ ααṇ ḀḀͼͼṓṓṵṵṇṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ ααṇḍ ṇṓṓṭ ααḍḍḛḛḍ ṭṓṓ ααṇẏẏ ẁṓṓṛḳṡṗααͼḛḛṡ.龍ผู้]"])},
      "add_users_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ ḍḭḭṛḛḛͼṭḽẏẏ ḭḭṇṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ ααṇḍ ααṡṡḭḭḡṇ ẁṓṓṛḳṡṗααͼḛḛ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇṡ龍ผู้]"])},
      "add_users_to_workspace_picker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṵṵṡḛḛṛṡ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "bulk_invite_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵḽḳ ḭḭṇṽḭḭṭḛḛ龍ผู้]"])},
      "individually_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ḭḭṇḍḭḭṽḭḭḍṵṵααḽḽẏẏ龍ผู้]"])},
      "invite_toggle_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ĵṵṵṡṭ ṭṓṓ ṭḥḛḛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "invite_toggle_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ḭḭṇṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "invite_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ"]), _normalize(["ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛ"]), _normalize(["ḬḬṇṽḭḭṭḛḛ ", _interpolate(_named("n")), " ṵṵṡḛḛṛṡ龍ผู้]"])])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃṵṵḽḳ ḭḭṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ ṽḭḭαα ḛḛṃααḭḭḽ龍ผู้]"])}
    },
    "delete_users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ṵṵṡḛḛṛ"]), _normalize(["Ḍḛḛḽḛḛṭḛḛ ṵṵṡḛḛṛṡ龍ผู้]"])])},
    "deselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡḛḛḽḛḛͼṭ龍ผู้]"])},
    "invite_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇṽḭḭṭḛḛ ṵṵṡḛḛṛṡ ṽḭḭαα ḛḛṃααḭḭḽ龍ผู้]"])},
    "no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
    "permission_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṗḛḛṛṃḭḭṡṡḭḭṓṓṇ龍ผู้]"])},
    "resend_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡḛḛṇḍ ḭḭṇṽḭḭṭḛḛ龍ผู้]"])},
    "user_item": {
      "connected_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḛḛḍ ṵṵṡḛḛṛ龍ผู้]"])},
      "pending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕḛḛṇḍḭḭṇḡ ḛḛṃααḭḭḽ ḭḭṇṽḭḭṭḛḛ ṵṵṡḛḛṛ龍ผู้]"])},
      "scim_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṢḈḬḬṀ ṵṵṡḛḛṛ龍ผู้]"])}
    },
    "workspace_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])}
  },
  "zendesk_product_tray": {
    "ai_agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḬḬ ααḡḛḛṇṭṡ龍ผู้]"])},
    "central_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍṃḭḭṇ Ḉḛḛṇṭḛḛṛ龍ผู้]"])},
    "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṭ龍ผู้]"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗḽṓṓṛḛḛ龍ผู้]"])},
    "gather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡααṭḥḛḛṛ龍ผู้]"])},
    "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṵṵḭḭḍḛḛ龍ผู้]"])},
    "lotus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṗṗṓṓṛṭ龍ผู้]"])},
    "quality_assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵααḽḭḭṭẏẏ ααṡṡṵṵṛααṇͼḛḛ龍ผู้]"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḽ龍ผู้]"])},
    "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḽḳ龍ผู้]"])},
    "workforce_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳϝṓṓṛͼḛḛ ṃααṇααḡḛḛṃḛḛṇṭ龍ผู้]"])}
  },
  "assignments_dashboard": {
    "completed_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ḉṓṓṃṗḽḛḛṭḛḛḍ ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ḉṓṓṃṗḽḛḛṭḛḛḍ ", _interpolate(_named("n")), "/", _interpolate(_named("total"))]), _normalize(["Ḉṓṓṃṗḽḛḛṭḛḛḍ ", _interpolate(_named("n")), "/", _interpolate(_named("total")), "龍ผู้]"])])},
    "labels": {
      "cycle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḉẏẏͼḽḛḛ ", _interpolate(_named("cycleNr")), "龍ผู้]"])},
      "period": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("startDate")), " - ", _interpolate(_named("endDate")), "龍ผู้]"])},
      "reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁḛḛṛ龍ผู้]"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])},
    "buttons": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚḍḭḭṭ ααṡṡḭḭḡṇṃḛḛṇṭ龍ผู้]"])}
    },
    "empty": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ṭḭḭṃḛḛ ααṇḍ ṡḛḛṭ ṵṵṗ ααṵṵṭṓṓṃααṭḭḭͼ ṛḛḛṽḭḭḛḛẁ ααṡṡḭḭḡṇṃḛḛṇṭṡ ḭḭṇṡṭḛḛααḍ ṓṓϝ ṃααṇṵṵααḽḽẏẏ ϝḭḭṇḍḭḭṇḡ ααṇḍ ααḽḽṓṓͼααṭḭḭṇḡ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṭṓṓ ṛḛḛṽḭḭḛḛẁ.龍ผู้]"])}
    }
  },
  "calibration_dashboard": {
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ龍ผู้]"])},
    "due_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍṵṵḛḛ ", _interpolate(_named("date")), "龍ผู้]"])},
    "no_sessions": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ ͼααṇ ḅḛḛ ααḍḍḛḛḍ ṵṵṇḍḛḛṛ 'Ḉααḽḭḭḅṛααṭḭḭṓṓṇ' ṡḛḛͼṭḭḭṓṓṇ ḭḭṇ ṭḥḛḛ ṡḭḭḍḛḛ ṃḛḛṇṵṵ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṽḭḭḛḛẁ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ααḍḍḛḛḍ ẏẏḛḛṭ龍ผู้]"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])},
    "baseline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḅααṡḛḛḽḭḭṇḛḛ龍ผู้]"])},
    "calibration_session_picker": {
      "no_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])}
    },
    "no_conversations": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ͼααṇ ḅḛḛ ααḍḍḛḛḍ ṭṓṓ αα ṡḛḛṡṡḭḭṓṓṇ ḅẏẏ ͼḽḭḭͼḳḭḭṇḡ ṓṓṇ ṭḥḛḛ ͼααḽḭḭḅṛααṭḭḭṓṓṇ ḭḭͼṓṓṇ ḭḭṇ ṭḥḛḛ ḥḛḛααḍḛḛṛ ṓṓϝ ṭḥḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ ṽḭḭḛḛẁ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ααḍḍḛḛḍ ẏẏḛḛṭ龍ผู้]"])}
    },
    "no_reviews_can_see": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṽḭḭḛḛẁṡ ḥααṽḛḛ ḅḛḛḛḛṇ ḡḭḭṽḛḛṇ. ", _interpolate(_named("link")), "龍ผู้]"])},
    "no_reviews_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααṽḛḛ αα ͼααḽḭḭḅṛααṭḭḭṓṓṇ ṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
    "root_cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛṓṓṓṓṭ ͼααṵṵṡḛḛ龍ผู้]"])}
  },
  "components": {
    "command_palette": {
      "pre_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ĵṵṵṃṗ ṭṓṓ龍ผู้]"])},
      "failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟααḭḭḽḛḛḍ ṭṓṓ ḽṓṓααḍ ͼṓṓṃṃααṇḍ ṗααḽḛḛṭṭḛḛ.龍ผู้]"])},
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓṭḥḭḭṇḡ ϝṓṓṵṵṇḍ.龍ผู้]"])},
      "found_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṓṓṵṵṇḍ ḅẏẏ ḬḬḌ龍ผู้]"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ ͼṓṓṃṃααṇḍ ṗααḽḛḛṭṭḛḛ…龍ผู้]"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗααḡḛḛṡ, ϝḭḭḽṭḛḛṛṡ, ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ, ṡḛḛṭṭḭḭṇḡṡ…龍ผู้]"])},
      "type_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇ龍ผู้]"])},
      "type_private_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḭḭṽααṭḛḛ ϝḭḭḽṭḛḛṛ龍ผู้]"])},
      "type_public_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭͼ ϝḭḭḽṭḛḛṛ龍ผู้]"])}
    },
    "helpdesk_icon": {
      "generic_source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḟṛṓṓṃ ", _interpolate(_list(0)), "龍ผู้]"])},
      "manual_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḟṛṓṓṃ ẏẏṓṓṵṵṛ ḀḀṔḬḬ龍ผู้]"])}
    },
    "pagination": {
      "wrapper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍Ṣḥṓṓẁḭḭṇḡ ", _interpolate(_named("rangeStart")), " ṭṓṓ ", _interpolate(_named("rangeEnd")), " ṓṓϝ ", _interpolate(_named("total")), " ", _interpolate(_named("label"))]), _normalize(["Ṣḥṓṓẁḭḭṇḡ ", _interpolate(_named("rangeStart")), " ṭṓṓ ", _interpolate(_named("rangeEnd")), " ṓṓϝ ", _interpolate(_named("total")), " ", _interpolate(_named("label")), "龍ผู้]"])])}
    },
    "subnav": {
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḭḭḍḛḛ ṡḭḭḍḛḛṗααṇḛḛḽ龍ผู้]"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṡḭḭḍḛḛṗααṇḛḛḽ龍ผู้]"])},
      "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ṡḭḭḍḛḛḅααṛ龍ผู้]"])}
    }
  },
  "disputes_dashboard": {
    "data_column_label": {
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ṛḛḛĵḛḛͼṭḛḛḍ龍ผู้]"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ṗααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "reviews_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ḍṓṓṇḛḛ龍ผู้]"])},
      "reviews_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ṛḛḛͼḛḛḭḭṽḛḛḍ龍ผู้]"])},
      "reviews_seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ ṡḛḛḛḛṇ龍ผู้]"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])}
    },
    "legend": {
      "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṗḛḛṇḛḛḍ龍ผู้]"])},
      "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṗααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "percent_of_resolved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṓṓϝ ṭṓṓṭααḽ龍ผู้]"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḛḛĵḛḛͼṭḛḛḍ龍ผู้]"])},
      "disputes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])},
      "disputes_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "disputes_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ龍ผู้]"])},
      "disputes_partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
      "disputes_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭḛḛḍ龍ผู้]"])},
      "reviews": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍ṛḛḛṽḭḭḛḛẁ"]), _normalize(["ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])])}
    },
    "tooltip": {
      "reviews_seen": {
        "disputed_reviewers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ṭḥḛḛḭḭṛ ṛḛḛṽḭḭḛḛẁṡ ẁḥḭḭͼḥ ḥααṽḛḛ ḅḛḛḛḛṇ ṡḛḛḛḛṇ ḅẏẏ ṛḛḛṽḭḭḛḛẁḛḛḛḛṡ龍ผู้]"])},
        "disputers_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṵṵṃḅḛḛṛ ṓṓϝ ṭḥḛḛḭḭṛ ṛḛḛṽḭḭḛḛẁṡ ẁḥḭḭͼḥ ḥααṽḛḛ ḅḛḛḛḛṇ ṡḛḛḛḛṇ龍ผู้]"])}
      }
    },
    "card": {
      "disputed_reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛḍ ṛḛḛṽḭḭḛḛẁḛḛṛṡ龍ผู้]"])},
      "disputers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṛṡ龍ผู้]"])},
      "disputes_by_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ḅẏẏ ͼααṭḛḛḡṓṓṛẏẏ龍ผู้]"])},
      "disputes_over_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḭḭṡṗṵṵṭḛḛṡ ṓṓṽḛḛṛ ṭḭḭṃḛḛ龍ผู้]"])},
      "disputes_overview_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ龍ผู้]"])},
      "disputes_overview_resolution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ ṭḭḭṃḛḛ龍ผู้]"])},
      "disputes_overview_resolution_time_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ ṭḭḭṃḛḛ (ḥ)龍ผู้]"])},
      "disputes_overview_resolution_time_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṛḛḛṡṓṓḽṵṵṭḭḭṓṓṇ ṭḭḭṃḛḛ (ṃḭḭṇ)龍ผู้]"])},
      "disputes_overview_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṓṓḽṽḛḛḍ龍ผู้]"])},
      "disputes_overview_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ ḍḭḭṡṗṵṵṭḛḛṡ龍ผู้]"])}
    },
    "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ḥααṡḥṭααḡ龍ผู้]"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓṭααḽ龍ผู้]"])}
  },
  "pickers": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
    "csat_languages": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛ ḽααṇḡṵṵααḡḛḛ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḽααṇḡṵṵααḡḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḽααṇḡṵṵααḡḛḛṡ龍ผู้]"])}
    },
    "csat_predicted_drivers": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛḍḭḭͼṭḛḛḍ ḍṛḭḭṽḛḛṛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḍṛḭḭṽḛḛṛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḍṛḭḭṽḛḛṛṡ龍ผู้]"])}
    },
    "csat_wordcloud_words": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḍͼḽṓṓṵṵḍ ẁṓṓṛḍṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḍṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḍṡ龍ผู้]"])}
    },
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁḛḛṇṭḭḭṓṓṇṡ龍ผู้]"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁḛḛṡṭ龍ผู้]"])},
    "oldest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎḽḍḛḛṡṭ龍ผู้]"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṗḛḛṇ龍ผู้]"])},
    "partially_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭααḽḽẏẏ ααͼͼḛḛṗṭḛḛḍ龍ผู้]"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), " ṛḛḛααͼṭḛḛḍ ẁḭḭṭḥ ", _interpolate(_list(1)), "龍ผู้]"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛĵḛḛͼṭḛḛḍ龍ผู้]"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṓṓḽṽḛḛḍ龍ผู้]"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ龍ผู้]"])},
    "skin_tone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ṡḳḭḭṇ ṭṓṓṇḛḛ龍ผู้]"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ龍ผู้]"])},
    "calibration_session": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṡṡḭḭṓṓṇṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḛḛṡṡḭḭṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "categories": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉααṭḛḛḡṓṓṛḭḭḛḛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭṡ龍ผู้]"])},
    "csat_predicted_dimentions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṃḛḛṇṭ ṡḭḭẓḛḛ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡḭḭẓḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡḭḭẓḛḛṡ龍ผู้]"])}
    },
    "filters": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ϝḭḭḽṭḛḛṛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "groups": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡṛṓṓṵṵṗṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡṛṓṓṵṵṗṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḡṛṓṓṵṵṗṡ龍ผู้]"])}
    },
    "hashtags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḥααṡḥṭααḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḥααṡḥṭααḡṡ龍ผู้]"])}
    },
    "helpdesk_custom_field_values": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṽααḽṵṵḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṽααḽṵṵḛḛṡ龍ผู้]"])}
    },
    "helpdesk_custom_fields": {
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṵṵṡṭṓṓṃ ϝḭḭḛḛḽḍṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "helpdesk_tags": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ḥḛḛḽṗ ḍḛḛṡḳ ṭααḡṡ龍ผู้]"])}
    },
    "loading_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓααḍḭḭṇḡ ṛḛḛṡṵṵḽṭṡ…龍ผู้]"])},
    "question_type": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḛḛṡṭḭḭṓṓṇ ṭẏẏṗḛḛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ʠṵṵḛḛṡṭḭḭṓṓṇ ṭẏẏṗḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])}
    },
    "reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṛḛḛααͼṭḭḭṓṓṇ龍ผู้]"])},
    "scorecards": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡͼṓṓṛḛḛͼααṛḍṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡͼṓṓṛḛḛͼααṛḍṡ龍ผู้]"])}
    },
    "scores": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡͼṓṓṛḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡͼṓṓṛḛḛṡ龍ผู้]"])}
    },
    "source": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṓṓṵṵṛͼḛḛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṓṓṵṵṛͼḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṓṓṵṵṛͼḛḛṡ龍ผู้]"])}
    },
    "survey_reasons": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛααṡṓṓṇ ṓṓϝ ϝḛḛḛḛḍḅααͼḳ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛααṡṓṓṇṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṛḛḛααṡṓṓṇṡ龍ผู้]"])}
    },
    "surveys": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵṛṽḛḛẏẏṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṡṵṵṛṽḛḛẏẏṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṡṵṵṛṽḛḛẏẏṡ龍ผู้]"])}
    },
    "ticket_channel": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉḥααṇṇḛḛḽṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼḥααṇṇḛḛḽṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ͼḥααṇṇḛḛḽṡ龍ผู้]"])}
    },
    "users": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṵṵṡḛḛṛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ṵṵṡḛḛṛṡ龍ผู้]"])}
    },
    "workspaces": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
      "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛḳṡṗααͼḛḛṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
      "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
    }
  },
  "quizzes": {
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḃααͼḳ ṭṓṓ Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḛḛ ʠṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ḍḛḛḽḛḛṭḛḛḍ ṓṓṛ ṇṓṓṭ ẏẏḛḛṭ ṗṵṵḅḽḭḭṡḥḛḛḍ. Ḍṓṓṵṵḅḽḛḛ-ͼḥḛḛͼḳ ṭḥḛḛ ḽḭḭṇḳ ṓṓṛ ṇααṽḭḭḡααṭḛḛ ḅααͼḳ ṭṓṓ ʠṵṵḭḭẓ ḽḭḭṡṭ.龍ผู้]"])},
      "no_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ʠṵṵḭḭẓ ḥααṡ ṇṓṓṭ ḅḛḛḛḛṇ ṡḥααṛḛḛḍ ṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ ẏẏṓṓṵṵ ḅḛḛḽṓṓṇḡ ṭṓṓ. ḀḀṡḳ ααṇ ααḍṃḭḭṇ ṭṓṓ ααḍḍ ẏẏṓṓṵṵ ṭṓṓ αα ẁṓṓṛḳṡṗααͼḛḛ ṭḥḭḭṡ ʠṵṵḭḭẓ ḥααṡ ααͼͼḛḛṡṡ ṭṓṓ.龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ḭḭṡ ṵṵṇααṽααḭḭḽααḅḽḛḛ龍ผู้]"])}
    },
    "form": {
      "errors": {
        "workspace_access_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ααͼͼḛḛṡṡ ḡḭḭṽḛḛṇ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛ(ṡ) ẏẏṓṓṵṵ ḍṓṓṇ'ṭ ḅḛḛḽṓṓṇḡ ṭṓṓ. ṲṲṇααḅḽḛḛ ṭṓṓ ṡααṽḛḛ ααṇḍ ṗṵṵḅḽḭḭṡḥ ʠṵṵḭḭẓ.龍ผู้]"])},
        "workspace_access_cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṡḳ ααṇ ααḍṃḭḭṇ ṭṓṓ ṡααṽḛḛ ααṇḍ ṗṵṵḅḽḭḭṡḥ ṭḥḛḛ ʠṵṵḭḭẓ.龍ผู้]"])},
        "no_correct_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ṡḥṓṓṵṵḽḍ ṃααṛḳ ααṭ ḽḛḛααṡṭ ṓṓṇḛḛ ṓṓϝ ṭḥḛḛ ααṇṡẁḛḛṛṡ ααṡ ṭḥḛḛ ͼṓṓṛṛḛḛͼṭ ṓṓṇḛḛ.龍ผู้]"])},
        "no_empty_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ϝḭḭḛḛḽḍ ͼααṇṇṓṓṭ ḅḛḛ ḛḛṃṗṭẏẏ.龍ผู้]"])}
      },
      "header": {
        "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ'ṛḛḛ ͼṵṵṛṛḛḛṇṭḽẏẏ ṡḛḛḛḛḭḭṇḡ αα ṗṛḛḛṽḭḭḛḛẁ ṓṓϝ ṭḥḛḛ ʠṵṵḭḭẓ.龍ผู้]"])},
        "access_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ ẁḥḭḭͼḥ ẁṓṓṛḳṡṗααͼḛḛṡ ͼααṇ ααͼͼḛḛṡṡ ṭḥḛḛ ʠṵṵḭḭẓ. ṲṲṡḛḛṛṡ ḅḛḛḽṓṓṇḡḭḭṇḡ ṭṓṓ ṃṵṵḽṭḭḭṗḽḛḛ ẁṓṓṛḳṡṗααͼḛḛṡ ͼααṇ ααṇṡẁḛḛṛ ṭḥḛḛ ʠṵṵḭḭẓ ṓṓṇḽẏẏ ṓṓṇͼḛḛ. Ṣḛḛḽḛḛͼṭḭḭṇḡ \"ḀḀḽḽ ẁṓṓṛḳṡṗααͼḛḛṡ\" ẁṓṓṇ'ṭ ḭḭṇͼḽṵṵḍḛḛ ṇḛḛẁḽẏẏ ͼṛḛḛααṭḛḛḍ ẁṓṓṛḳṡṗααͼḛḛṡ ṓṓṇͼḛḛ ṭḥḛḛ ʠṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ṗṵṵḅḽḭḭṡḥḛḛḍ.龍ผู้]"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ααͼͼḛḛṡṡ龍ผู้]"])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ龍ผู้]"])},
        "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
        "exit_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋḭḭṭ ṗṛḛḛṽḭḭḛḛẁ ṃṓṓḍḛḛ龍ผู้]"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗṓṓṛṭ龍ผู้]"])},
        "navbar": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṛḛḛṽḭḭḛḛẁ龍ผู้]"])},
          "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḛḛṡṭḭḭṓṓṇṡ龍ผู้]"])},
          "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])}
        },
        "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫααḳḛḛ ṭḥḛḛ ʠṵṵḭḭẓ龍ผู้]"])},
        "publish_quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ ʠṵṵḭḭẓ龍ผู้]"])},
        "quiz_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ṡḛḛṭṭḭḭṇḡṡ龍ผู้]"])},
        "save_as_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣααṽḛḛ ααṡ ḍṛααϝṭ龍ผู้]"])},
        "title_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ṇḛḛẁ ʠṵṵḭḭẓ龍ผู้]"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṛͼḥḭḭṽḛḛ龍ผู้]"])}
      },
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṓṓṗṭḭḭṓṓṇṡ龍ผู้]"])},
        "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ṓṓṗṭḭḭṓṓṇ龍ผู้]"])}
      },
      "questions_preview_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥααṛḛḛ ḭḭṭ龍ผู้]"])},
      "questions_preview_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻṓṓṓṓḳṡ ṇḭḭͼḛḛ, ḍṓṓḛḛṡṇ'ṭ ḭḭṭ?龍ผู้]"])},
      "toasts": {
        "answer_submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ααṇṡẁḛḛṛ ḥααṡ ḅḛḛḛḛṇ ṡṵṵḅṃḭḭṭṭḛḛḍ龍ผู้]"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ʠṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ṡααṽḛḛḍ龍ผู้]"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ʠṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ṗṵṵḅḽḭḭṡḥḛḛḍ龍ผู้]"])},
        "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵṛ ʠṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ṛḛḛṃṓṓṽḛḛḍ龍ผู้]"])}
      },
      "add_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḍḍ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
      "alerts": {
        "archive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ ʠṵṵḭḭẓ龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇ ααṛͼḥḭḭṽḛḛḍ ʠṵṵḭḭẓ ααṇḍ ḭḭṭṡ ṛḛḛṡṵṵḽṭṡ ẁḭḭḽḽ ṇṓṓ ḽṓṓṇḡḛḛṛ ααṗṗḛḛααṛ ḭḭṇ ṭḥḛḛ ḽḭḭṡṭ ṓṓϝ ʠṵṵḭḭẓẓḛḛṡ. ŶŶṓṓṵṵ ͼααṇ ṡṭḭḭḽḽ ααͼͼḛḛṡṡ ṭḥḛḛ ṛḛḛṡṵṵḽṭṡ ṓṓϝ ααṇ ααṛͼḥḭḭṽḛḛḍ ʠṵṵḭḭẓ ααṇḍ ṵṵṇααṛͼḥḭḭṽḛḛ ḭḭṭ ẁḥḛḛṇḛḛṽḛḛṛ ṇḛḛḛḛḍḛḛḍ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛ ʠṵṵḭḭẓ龍ผู้]"])}
        },
        "publish_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇͼḛḛ ṗṵṵḅḽḭḭṡḥḛḛḍ, αα ʠṵṵḭḭẓ ͼααṇṇṓṓṭ ḅḛḛ ͼḥααṇḡḛḛḍ ṓṓṛ ḛḛḍḭḭṭḛḛḍ. ḀḀḽḽ ṗṵṵḅḽḭḭṡḥḛḛḍ ʠṵṵḭḭẓẓḛḛṡ ẁḭḭḽḽ ḅḛḛ ααͼͼḛḛṡṡḭḭḅḽḛḛ ṭṓṓ ḛḛṽḛḛṛẏẏ ṵṵṡḛḛṛ ṓṓṇ ẏẏṓṓṵṵṛ ααͼͼṓṓṵṵṇṭ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥ ʠṵṵḭḭẓ?龍ผู้]"])}
        },
        "remove_quiz": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛḛḛ ẏẏṓṓṵṵ ṡṵṵṛḛḛ ẏẏṓṓṵṵ ẁααṇṭ ṭṓṓ ḍḛḛḽḛḛṭḛḛ ṭḥḭḭṡ ʠṵṵḭḭẓ? Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ͼααṇṇṓṓṭ ḅḛḛ ṵṵṇḍṓṓṇḛḛ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ʠṵṵḭḭẓ?龍ผู้]"])}
        },
        "submit_answer": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇͼḛḛ ṡṵṵḅṃḭḭṭṭḛḛḍ, ẏẏṓṓṵṵṛ ααṇṡẁḛḛṛṡ ͼααṇṇṓṓṭ ḅḛḛ ͼḥααṇḡḛḛḍ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ ʠṵṵḭḭẓ?龍ผู้]"])}
        },
        "unarchive_quiz": {
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṛͼḥḭḭṽḛḛ ʠṵṵḭḭẓ龍ผู้]"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṛͼḥḭḭṽḭḭṇḡ ṭḥḛḛ ʠṵṵḭḭẓ ṃααḳḛḛṡ ḭḭṭ ααṽααḭḭḽααḅḽḛḛ ḭḭṇ ṭḥḛḛ ʠṵṵḭḭẓ ṓṓṽḛḛṛṽḭḭḛḛẁ. ḬḬṭ ẁḭḭḽḽ ααḽḽṓṓẁ ṗṛḛḛṽḭḭṓṓṵṵṡ ṗααṛṭḭḭͼḭḭṗααṇṭṡ ṭṓṓ ααͼͼḛḛṡṡ ṭḥḛḛḭḭṛ ṛḛḛṡṵṵḽṭṡ ααṇḍ ϝṓṓṛ ṇḛḛẁ ṓṓṇḛḛṡ ṭṓṓ ṭααḳḛḛ ṭḥḛḛ ʠṵṵḭḭẓ.龍ผู้]"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇααṛͼḥḭḭṽḛḛ ʠṵṵḭḭẓ龍ผู้]"])}
        }
      },
      "clone_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛṡͼṛḭḭṗṭḭḭṓṓṇ (ṓṓṗṭḭḭṓṓṇααḽ)龍ผู้]"])},
      "move_question_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṽḛḛ ʠṵṵḛḛṡṭḭḭṓṓṇ ḍṓṓẁṇ龍ผู้]"])},
      "move_question_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṁṓṓṽḛḛ ʠṵṵḛḛṡṭḭḭṓṓṇ ṵṵṗ龍ผู้]"])},
      "question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
      "questions_preview_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṇḽẏẏ ʠṵṵḛḛṡṭḭḭṓṓṇṡ ẁḭḭṭḥ ααṇṡẁḛḛṛṡ ααṛḛḛ ḍḭḭṡṗḽααẏẏḛḛḍ ḭḭṇ ṭḥḛḛ ṗṛḛḛṽḭḭḛḛẁ.龍ผู้]"])},
      "remove_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṃṓṓṽḛḛ ʠṵṵḛḛṡṭḭḭṓṓṇ龍ผู้]"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣṵṵḅṃḭḭṭ ʠṵṵḭḭẓ龍ผู้]"])},
      "title_fallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṇṭḭḭṭḽḛḛḍ ʠṵṵḭḭẓ龍ผู้]"])},
      "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ṭḭḭṭḽḛḛ龍ผู้]"])}
    },
    "list": {
      "delete_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ʠṵṵḭḭẓ ααṇḍ ḭḭṭṡ ṛḛḛṡṵṵḽṭṡ.龍ผู้]"])},
      "delete_prompt_access_by_archiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽṭḛḛṛṇααṭḭḭṽḛḛḽẏẏ, ẏẏṓṓṵṵ ͼααṇ ṡṭḭḭḽḽ ααͼͼḛḛṡṡ ṭḥḛḛ ṛḛḛṡṵṵḽṭṡ ḅẏẏ ααṛͼḥḭḭṽḭḭṇḡ ṭḥḛḛ ʠṵṵḭḭẓ.龍ผู้]"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṇṡẁḛḛṛḛḛḍ龍ผู้]"])},
      "delete_button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ʠṵṵḭḭẓ龍ผู้]"])},
      "delete_prompt_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ẁḭḭḽḽ ṗḛḛṛṃααṇḛḛṇṭḽẏẏ ḍḛḛḽḛḛṭḛḛ ṭḥḛḛ ʠṵṵḭḭẓ ααṇḍ ḭḭṭṡ ṛḛḛṡṵṵḽṭṡ.龍ผู้]"])},
      "delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ʠṵṵḭḭẓ ", _interpolate(_list(0)), "龍ผู้]"])},
      "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṵṵṗḽḭḭͼααṭḛḛ龍ผู้]"])},
      "empty": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ ḥḛḛḽṗ ṭṓṓ ḅṓṓṓṓṡṭ ṭḛḛααṃ ͼṓṓṇϝḭḭḍḛḛṇͼḛḛ ααṇḍ ṡḳḭḭḽḽṡ, ḍḛḛḽḭḭṽḛḛṛ ͼṓṓṇṡḭḭṡṭḛḛṇṭ ḛḛẋṗḛḛṛḭḭḛḛṇͼḛḛṡ, ααṇḍ ṓṓṇḅṓṓααṛḍ ṇḛḛẁ ṭḛḛααṃ ṃḛḛṃḅḛḛṛṡ.龍ผู้]"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṡḛḛααṛͼḥ ϝṓṓṛ ʠṵṵḭḭẓẓḛḛṡ ḅẏẏ ṭḥḛḛ ʠṵṵḭḭẓ ṇααṃḛḛ ṓṓṛ ṭḥḛḛ ααṵṵṭḥṓṓṛ'ṡ ṇααṃḛḛ.龍ผู้]"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ʠṵṵḭḭẓẓḛḛṡ ͼṛḛḛααṭḛḛḍ ẏẏḛḛṭ龍ผู้]"])}
      },
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗṓṓṛṭ ḽḭḭṡṭ ṓṓϝ ʠṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
      "heading": {
        "avg_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḡ. ṡͼṓṓṛḛḛ龍ผู้]"])},
        "completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṃṗḽḛḛṭḛḛḍ ḍααṭḛḛ龍ผู้]"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ/ḀḀṵṵṭḥṓṓṛ龍ผู้]"])},
        "name_with_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ / ḀḀṵṵṭḥṓṓṛ / Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])},
        "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕααṛṭḭḭͼḭḭṗααṇṭṡ龍ผู้]"])},
        "published_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṕṵṵḅḽḭḭṡḥḛḛḍ龍ผู้]"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ龍ผู้]"])},
        "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ŵṓṓṛḳṡṗααͼḛḛṡ龍ผู้]"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ʠṵṵḭḭẓ, ααṵṵṭḥṓṓṛ龍ผู้]"])},
      "tabs": {
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṛͼḥḭḭṽḛḛḍ龍ผู้]"])},
        "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍṛααϝṭ龍ผู้]"])},
        "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀͼṭḭḭṽḛḛ龍ผู้]"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽ ʠṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
      "toast": {
        "quiz_archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ααṛͼḥḭḭṽḛḛḍ.龍ผู้]"])},
        "quiz_unarchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ ḥααṡ ḅḛḛḛḛṇ ṵṵṇααṛͼḥḭḭṽḛḛḍ.龍ผู้]"])}
      }
    },
    "responses": {
      "no_response": {
        "archived_message_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ʠṵṵḭḭẓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṇṓṓ ṛḛḛṡṗṓṓṇṡḛḛṡ ϝṛṓṓṃ ṵṵṡḛḛṛṡ ḭḭṇ ṭḥḛḛ ṡḛḛḽḛḛͼṭḛḛḍ ẁṓṓṛḳṡṗααͼḛḛ(ṡ).龍ผู้]"])},
        "archived_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ʠṵṵḭḭẓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṇṓṓ ṛḛḛṡṗṓṓṇṡḛḛṡ ḅḛḛϝṓṓṛḛḛ ḅḛḛḭḭṇḡ ααṛͼḥḭḭṽḛḛḍ.龍ผู้]"])},
        "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṗṓṓṇṡḛḛṡ龍ผู้]"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥḛḛṛḛḛ ẏẏṓṓṵṵ'ḽḽ ṡḛḛḛḛ αα ḅṛḛḛααḳḍṓṓẁṇ ṓṓϝ ṭḥḛḛ ʠṵṵḭḭẓ'ṡ ααṇṡẁḛḛṛṡ.\nṄṓṓ ṓṓṇḛḛ ḥααṡ ϝḭḭḽḽḛḛḍ ḭḭṇ ẏẏṓṓṵṵṛ ʠṵṵḭḭẓ ẏẏḛḛṭ - ṡḥααṛḭḭṇḡ ḭḭṭ ẁṓṓṵṵḽḍ ṡṵṵṛḛḛḽẏẏ ḥḛḛḽṗ.龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṗṓṓṇṡḛḛṡ ẏẏḛḛṭ龍ผู้]"])}
      },
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṡṗṓṓṇṡḛḛṡ:龍ผู้]"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṽḛḛṛααḡḛḛ ṡͼṓṓṛḛḛ:龍ผู้]"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḬḬṇḍḭḭṽḭḭḍṵṵααḽ龍ผู้]"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṎṎṽḛḛṛṽḭḭḛḛẁ龍ผู้]"])}
    },
    "title_cloned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍", _interpolate(_named("quizName")), " - Ḉṓṓṗẏẏ龍ผู้]"])},
    "copy_link_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḭḭṇḳ ͼṓṓṗḭḭḛḛḍ龍ผู้]"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛṡṵṵḽṭṡ ϝṓṓṵṵṇḍ龍ผู้]"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ龍ผู้]"])},
    "leaderboard": {
      "agent_quizzes_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍", _interpolate(_list(0)), "'ṡ ʠṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
      "empty_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ŶŶṓṓṵṵ ͼααṇ ṡḛḛααṛͼḥ ṭḥḛḛ ḽḛḛααḍḛḛṛḅṓṓααṛḍ ϝṓṓṛ ṗααṛṭḭḭͼḭḭṗααṇṭ'ṡ ṇααṃḛḛ.龍ผู้]"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḚḚẋṗṓṓṛṭ ḽḛḛααḍḛḛṛḅṓṓααṛḍ龍ผู้]"])},
      "heading": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛṛ龍ผู้]"])},
        "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓ龍ผู้]"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣͼṓṓṛḛḛ龍ผู้]"])}
      },
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ḅẏẏ ṗααṛṭḭḭͼḭḭṗααṇṭ龍ผู้]"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḻḛḛααḍḛḛṛḅṓṓααṛḍ龍ผู้]"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ǫṵṵḭḭẓẓḛḛṡ龍ผู้]"])},
    "toggle_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫṓṓḡḡḽḛḛ ḽḛḛααḍḛḛṛḅṓṓααṛḍ龍ผู้]"])}
  },
  "team": {
    "hashtags": {
      "delete_dialog_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ṃḛḛααṇṡ ẏẏṓṓṵṵ ẁṓṓṇ'ṭ ḅḛḛ ααḅḽḛḛ ṭṓṓ ϝḭḭḽṭḛḛṛ ϝṓṓṛ ṭḥḭḭṡ ḥααṡḥṭααḡ ṓṓṇ ṭḥḛḛ ḍααṡḥḅṓṓααṛḍ ααṇḍ ḭḭṭ ẁṓṓṇ'ṭ ααṵṵṭṓṓͼṓṓṃṗḽḛḛṭḛḛ ṓṓṇ ͼṓṓṃṃḛḛṇṭṡ ααṇẏẏṃṓṓṛḛḛ. Ṫḥḭḭṡ ααͼṭḭḭṓṓṇ ḭḭṡ ḭḭṛṛḛḛṽḛḛṛṡḭḭḅḽḛḛ.龍ผู้]"])},
      "add_hashtags_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ, ḥααṡḥṭααḡ, ḥααṡḥṭααḡ, ...龍ผู้]"])},
      "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "add_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṛḛḛααṭḛḛ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "allow_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀḽḽṓṓẁ ṃḛḛṃḅḛḛṛṡ ṭṓṓ ͼṛḛḛααṭḛḛ ṇḛḛẁ ḥααṡḥṭααḡṡ龍ผู้]"])},
      "column_header_hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ龍ผู้]"])},
      "column_header_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṲṲṡḛḛḍ龍ผู้]"])},
      "delete_dialog_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḥααṡḥṭααḡ龍ผู้]"])},
      "delete_dialog_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ḥααṡḥṭααḡ ", _interpolate(_list(0)), "龍ผู้]"])},
      "hashtag_creation_toast_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ ͼṛḛḛααṭḭḭṓṓṇ ḭḭṡ ḽṓṓͼḳḛḛḍ龍ผู้]"])},
      "hashtag_creation_toast_unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ ͼṛḛḛααṭḭḭṓṓṇ ḭḭṡ ṵṵṇḽṓṓͼḳḛḛḍ龍ผู้]"])},
      "hashtag_deleted_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ '", _interpolate(_list(0)), "' ḍḛḛḽḛḛṭḛḛḍ龍ผู้]"])},
      "hashtag_renamed_toast": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["[ผู้龍Ḥααṡḥṭααḡ '", _interpolate(_list(0)), "' ṛḛḛṇααṃḛḛḍ ṭṓṓ '", _interpolate(_list(1)), "'龍ผู้]"])},
      "hashtags_added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["[ผู้龍1 ḥααṡḥṭααḡ ααḍḍḛḛḍ"]), _normalize([_interpolate(_named("count")), " ḥααṡḥṭααḡṡ ααḍḍḛḛḍ龍ผู้]"])])},
      "new_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅḛḛẁ ṇααṃḛḛ龍ผู้]"])},
      "no_hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḥααṡḥṭααḡṡ ͼṛḛḛααṭḛḛḍ龍ผู้]"])},
      "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛṇααṃḛḛ龍ผู้]"])},
      "rename_hashtag": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṛḛḛṇααṃḛḛ ḥααṡḥṭααḡ \"", _interpolate(_named("tagRenamed")), "\"龍ผู้]"])},
      "separate_by_commas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛṗααṛααṭḛḛ ḥααṡḥṭααḡṡ ẁḭḭṭḥ ͼṓṓṃṃααṡ ṓṓṛ ḽḭḭṇḛḛ ḅṛḛḛααḳṡ龍ผู้]"])},
      "tag_filter_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛααṛͼḥ ϝṓṓṛ αα ḥααṡḥṭααḡ龍ผู้]"])}
    },
    "connection": {
      "integrate": {
        "template": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ẁṓṓṛṛḭḭḛḛṡ. ŶŶṓṓṵṵ ͼααṇ ḍṓṓ ṡṓṓ ", _interpolate(_named("link_copy")), "龍ผู้]"])},
        "link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ṛḭḭḡḥṭ ḥḛḛṛḛḛ龍ผู้]"])}
      },
      "linked_toast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ ḽḭḭṇḳḛḛḍ ṭṓṓ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "attach_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṭṭααͼḥ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "attach_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍ḀḀṭṭααͼḥ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ṭṓṓ ṭḥḭḭṡ ẁṓṓṛḳṡṗααͼḛḛ龍ผู้]"])},
      "connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
      "contact_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ ẏẏṓṓṵṵṛ ααḍṃḭḭṇ ṭṓṓ ṃααḳḛḛ ααṇẏẏ ͼḥααṇḡḛḛṡ ḥḛḛṛḛḛ龍ผู้]"])},
      "contact_klaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḉṓṓṇṭααͼṭ Żḛḛṇḍḛḛṡḳ ǪḀḀ ṭṓṓ ṃααḳḛḛ ααṇẏẏ ͼḥααṇḡḛḛṡ ḥḛḛṛḛḛ龍ผู้]"])},
      "no_connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇṡ ṵṵṇḍḛḛṛ ααͼͼṓṓṵṵṇṭ龍ผู้]"])},
      "no_integrations_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḛḛṃṡ ẏẏṓṓṵṵ ḥααṽḛḛṇ'ṭ ḭḭṇṭḛḛḡṛααṭḛḛḍ ẁḭḭṭḥ αα ͼṵṵṡṭṓṓṃḛḛṛ ṡḛḛṛṽḭḭͼḛḛ ṗḽααṭϝṓṓṛṃ ẏẏḛḛṭ.龍ผู้]"])},
      "remove_dialog": {
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḭḭṇḡ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ẁḭḭḽḽ ṛḛḛṃṓṓṽḛḛ ͼṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ϝṛṓṓṃ ẁṓṓṛḳṡṗααͼḛḛ ", _interpolate(_named("workspaceName")), " ααṇḍ ḍḛḛḽḛḛṭḛḛ ααḽḽ ḍααṭαα ṛḛḛḽααṭḛḛḍ ṭṓṓ ṭḥḛḛṃ, ḭḭṇͼḽṵṵḍḭḭṇḡ ṛḛḛṽḭḭḛḛẁṡ ααṇḍ ḍḭḭṡṗṵṵṭḛḛṡ.龍ผู้]"])},
        "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])},
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ḍḛḛḽḛḛṭḛḛ ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ ", _interpolate(_named("connectionName")), "龍ผู้]"])}
      },
      "select_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḛḛḽḛḛͼṭ αα ͼṓṓṇṇḛḛͼṭḭḭṓṓṇ龍ผู้]"])}
    }
  },
  "auto_qa": {
    "time_period_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["[ผู้龍Ŵḭḭṭḥḭḭṇ ṭḥḛḛ ͼḥṓṓṡḛḛṇ ṭḭḭṃḛḛ ṗḛḛṛḭḭṓṓḍ, ͼḛḛṛṭααḭḭṇ ααͼṭḭḭṽḛḛ ḀḀṵṵṭṓṓǪḀḀ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ ẁḛḛṛḛḛ ṇṓṓṭ ṗṛḛḛṡḛḛṇṭ ṓṓṇ ẏẏṓṓṵṵṛ ṡͼṓṓṛḛḛͼααṛḍ. Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṗṛḭḭṓṓṛ ṭṓṓ ẏẏṓṓṵṵṛ ḽααṡṭ ͼḥααṇḡḛḛ ṓṓṇ ", _interpolate(_named("date")), ", ṃααẏẏ ṇṓṓṭ ḥααṽḛḛ ḅḛḛḛḛṇ ααṇααḽẏẏẓḛḛḍ ϝṓṓṛ ṭḥḛḛṡḛḛ ͼααṭḛḛḡṓṓṛḭḭḛḛṡ.龍ผู้]"])}
  },
  "reviews": {
    "received_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṡ ẁḥḛḛṛḛḛ ααḽḽ ϝḛḛḛḛḍḅααͼḳ ḡḭḭṽḛḛṇ ṭṓṓ ẏẏṓṓṵṵ ẁḭḭḽḽ ααṗṗḛḛααṛ. Ṅṓṓṭḥḭḭṇḡ ṭṓṓ ṡḥṓṓẁ ẏẏḛḛṭ.龍ผู้]"])},
    "received_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
    "comments_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṣḥṓṓẁ ṓṓṇḽẏẏ ͼṓṓṃṃḛḛṇṭ ααͼṭḭḭṽḭḭṭẏẏ龍ผู้]"])},
    "given_empty_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṫḥḭḭṡ ḭḭṡ ẁḥḛḛṛḛḛ ααḽḽ ṭḥḛḛ ϝḛḛḛḛḍḅααͼḳ ḡḭḭṽḛḛṇ ḅẏẏ ẏẏṓṓṵṵ ẁḭḭḽḽ ααṗṗḛḛααṛ. Ḡḛḛṭ ṡṭααṛṭḛḛḍ ḭḭṇ ṭḥḛḛ Ḉṓṓṇṽḛḛṛṡααṭḭḭṓṓṇṡ ṽḭḭḛḛẁ.龍ผู้]"])},
    "given_empty_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṅṓṓ ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ.龍ผู้]"])},
    "given_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ḡḭḭṽḛḛṇ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])},
    "received_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ผู้龍Ṛḛḛͼḛḛḭḭṽḛḛḍ ṛḛḛṽḭḭḛḛẁṡ龍ผู้]"])}
  }
}