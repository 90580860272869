export const plaintextSources = ['LIVE_CHAT']

export const noParseSources = [
  'DRIFT',
  'ZENDESK',
  'INTERCOM',
  'HELP_SCOUT',
  'MANUAL_IMPORT',
  'CORDLESS_MANUAL_IMPORT',
  'HELPSHIFT',
  'GORGIAS',
  'SALESFORCE',
]
