import { useRoute } from 'vue-router'
import { nextTick, onMounted, onUnmounted, type Ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import useReviewSidebar from '@/composables/useReviewSidebar'
import { session } from '@/composables/useSession'
import { bus } from '@/utils/bus'
import analytics from '@/utils/analytics'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { setCommentAsViewed, setReviewAsViewed } from '../api'
import type Feedback from '../components/Feedback.vue'
import type { Feedback as FeedbackType, FeedbackComment } from '../types/feedback'
import useCalibrationSession from './useCalibrationSession'

export const useMarkAsViewed = createSharedComposable(
  (
    {
      rootRef,
      feedbackRef,
    }: {
      rootRef: Ref<HTMLDivElement>
      feedbackRef: Ref<InstanceType<typeof Feedback>[]>
    },
    messageId?: string,
  ) => {
    let observer: IntersectionObserver
    const route = useRoute()
    const { state: sidebarState, visibleManualFeedback } = useReviewSidebar()
    const { connectionId } = useTicketRouteParams()
    const { calibrating } = useCalibrationSession()

    onMounted(() => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(({ target, intersectionRatio }) => {
            if (!(target instanceof HTMLElement) || !target.dataset?.reviewId || intersectionRatio <= 0) return

            const id = target.dataset.reviewId
            const feedback = sidebarState.feedbackList.find((f) => f.id === id)

            if (!feedback || !connectionId.value) return

            markFeedbackAsViewed(feedback)
            const threadCommentId = route.hash?.split('-')[1]
            markThreadCommentAsViewed(feedback.thread, threadCommentId)
          })
        },
        { root: rootRef.value },
      )
    })

    onUnmounted(() => observer.disconnect())

    watch(
      [route, visibleManualFeedback, calibrating],
      async ([_, feedback, calibrating]) => {
        observer?.disconnect()

        if (!feedback || !feedback.length || calibrating) return

        await nextTick()
        feedbackRef.value?.forEach((feedback) => observer?.observe(feedback.$el))
      },
      { immediate: true },
    )

    const markFeedbackAsViewed = async (feedback: FeedbackType) => {
      if (feedback.deleted) return

      const userId = session.user.id.toString()
      const isUserReviewee = feedback.revieweeId === userId
      const isMentionRecipient = feedback.comment?.includes(`data-mention="${userId}"`)
      const isReview = feedback.ratings?.length
      const isReviewComment = isReview && feedback.comment
      const isReviewlessComment = !isReview && feedback.comment

      if (isReview && isUserReviewee && !feedback.seen) {
        await setReviewAsViewed({ connectionId: connectionId.value, reviewId: feedback.id })
        hasSeenFeedback({ id: feedback.id, type: 'reviews' })
        feedback.seen = true
        if (feedback.commentId) {
          bus.$emit('refresh-activities-count', { id: feedback.commentId, type: 'comments' })
        }
      } else if ((isReviewComment || isReviewlessComment) && (isUserReviewee || isMentionRecipient)) {
        const id = isReviewComment ? feedback.commentId : feedback.id
        await setCommentAsViewed({ connectionId: connectionId.value, id })
        bus.$emit('refresh-activities-count', { id, type: 'comments' })
      }
    }

    const markThreadCommentAsViewed = async (thread: FeedbackComment[], commentId: string) => {
      if (!thread || !thread.length) return

      const replyComment = thread.find((item) => item.id === commentId)

      if (replyComment && !replyComment.currentUserSeen) {
        await setCommentAsViewed({ connectionId: connectionId.value, id: replyComment.id })
        bus.$emit('refresh-activities-count', { id: replyComment.id, type: 'comments' })
      }
    }

    const hasSeenFeedback = ({ id, type }: { id: string; type: 'reviews' | 'comments' }) => {
      bus.$emit('refresh-activities-count', { id, type })
      analytics.reviewMarkedAsRead(messageId ? 'message' : 'ticket')
    }
  },
)
