import { reactive, computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import {
  type TicketInsight,
  type TicketConversationEvent as TicketEvent,
  AutoInsightType,
} from '@zendesk/zqa-services/tickets'

import useReviewSidebar from '@/composables/useReviewSidebar'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import type { KlausSentiment } from '@/types/sentiment'
import { bus } from '@/utils/bus'
import i18n from '@/i18n'
import type { TicketData, ExternalCommentExt, TicketMessages } from '@/modules/conversations/types'
import type { User } from '@/modules/user-management/types'
import type { TicketConversationEvent } from '../types/conversation'

import { getTicketBody, getTicketSpanner, toggleHighlight } from '../api'
import { getMessageElement, getTicketResponsesElement, getTranscriptionHeaderElement } from '../utils/ticket-dom.util'
import { scrollInteractionIntoView } from '../utils/scroll-to-message.util'

export const ignoreEvents = [
  'conversation.admin.noted',
  'conversation.admin.replied',
  'conversation.user.replied',
  'conversation.user.created',
]

const ticketHeaderCollapsedKey = 'klausTicketHeaderCollapsed.v1'

interface State {
  originalMessages: ExternalCommentExt[]
  translatedMessages: ExternalCommentExt[]
  loadingTranslation: boolean
  animateHighlightState: boolean
  ticket: TicketData
  csatScores: TicketEvent[]
  conversationEvents: TicketConversationEvent[]
  usersViewing: User[]
  feedbackMap: Record<
    string,
    {
      hasComments: boolean
      hasReviews: boolean
      youReviewed: boolean
      youCommented: boolean
    }
  >
  messageSentiments: Record<string, KlausSentiment>
  ticketLoading: boolean
}

export interface TicketInsightExtended extends TicketInsight {
  lastUpdatedBy: string
  customSpotlightIsDeleted?: boolean
  title: string
}

function _useTicketContent() {
  const {
    transcriptionId: selectedTranscriptionId,
    transcriptionMessageId: selectedTranscriptionMessageId,
    messageId: selectedMessageId,
    calibrationSessionId,
  } = useTicketRouteParams()
  const { state: sidebarState } = useReviewSidebar()

  const getDefaultState = (): State => {
    // TODO: Remove this after a few weeks of app framework being live
    localStorage.removeItem(ticketHeaderCollapsedKey)

    return {
      originalMessages: [],
      translatedMessages: [],
      loadingTranslation: false,
      animateHighlightState: false,
      ticket: {} as TicketData,
      csatScores: [],
      conversationEvents: [],
      usersViewing: [],
      feedbackMap: {},
      messageSentiments: {},
      ticketLoading: true,
    }
  }

  const state = reactive<State>(getDefaultState())

  const readOnly = computed(() => !!calibrationSessionId.value)

  const ticketSource = computed(() => {
    const { url, customerId, subDomain: domain, externalId: id, sourceType: source, externalFields } = state.ticket
    let { mailBoxId: mailbox } = state.ticket

    if (source === 'LIVE_CHAT') mailbox = state.ticket.type === 'chat' ? 'archives' : 'tickets'
    else if (source === 'ZENDESK_CHAT') {
      const conversationId = externalFields.find((f) => f.externalId === 'zendesk_ticket_id')
      if (conversationId) return { source: 'ZENDESK', domain, id: conversationId.value }
    }

    return { source, domain, id, url, customerId, mailbox }
  })

  const ticketURL = computed(() => state?.ticket?.url ?? '')

  const participatingAgentIds = computed(() => {
    if (!state.ticket.externalComments?.length) return []

    const agentMessages = state.ticket.externalComments
      .filter((i) => !i.endUser && i.fromType !== 'bot' && !(i?.recordingUrl || i?.customerPhone))
      .map((item) => (item.fromId !== undefined ? item.fromId : item.fromName))

    return Array.from(new Set(agentMessages))
  })

  const getInsightTitle = (insight) => {
    if (insight.type === AutoInsightType.SLA_BREACHED)
      return i18n.t(`conversations.ticket_insights.types.sla_breached.title_new`)
    if (insight.type === AutoInsightType.CUSTOM_PHRASE_SEARCH) return insight.metadata?.customSpotlightName

    return i18n.t(`conversations.ticket_insights.types.${insight.type.toLowerCase()}.title`)
  }
  const insights = computed<TicketInsightExtended[]>(() => {
    const insights = state.ticket.autoInsights
    if (!insights) return []

    return insights.map((i: TicketInsight) => ({
      ...i,
      metadata: i.metadata && {
        ...i.metadata,
        botRepetitionMessageIds: i.metadata.botRepetitionMessageIds
          .map((id) => ({
            id,
            uiOrder: state.ticket.externalComments.findIndex((c) => c.externalId === id),
          }))
          .toSorted((a, b) => a.uiOrder - b.uiOrder)
          .map(({ id }) => id),
      },
      title: getInsightTitle(i),
    }))
  })

  const resetTicket = () => {
    Object.assign(state, getDefaultState())
    sidebarState.metadata.translatedConversation = false
  }

  const focusOnMessage = (evt: Event, item, transcription?: { id: string; messageId?: string }) => {
    if (evt && evt.target instanceof HTMLAnchorElement) return evt.stopPropagation()

    const isVoice = item.callDuration && item.recordingUrl
    const unrecognizedBot = item.fromBot && item.fromId === '-1'
    const reviewableMessage = isVoice || (!item.endUser && !unrecognizedBot && item.belongsToUser !== false)

    if (!readOnly.value) {
      bus.$emit('set-message-id', {
        messageId: reviewableMessage ? item.id : selectedMessageId.value,
        transcriptionId: transcription?.id ?? undefined,
        transcriptionMessageId: transcription?.messageId ?? undefined,
      })
    }

    scrollInteractionIntoView({
      messageId: item.id,
      transcriptionId: transcription?.id,
      transcriptionMessageId: transcription?.messageId,
    })
  }

  const messageIsOffScreen = ({
    messageId,
    transcriptionId,
    transcriptionMessageId,
  }: {
    messageId?: string
    transcriptionId: string
    transcriptionMessageId?: string
  }) => {
    const message = getMessageElement(transcriptionMessageId ?? messageId)
    const parent = getTicketResponsesElement()

    if (!message || !parent) return

    const parentOffsetTop = parent.getBoundingClientRect().top
    const messageOffsetTop = message.getBoundingClientRect().top + parent.scrollTop - parentOffsetTop
    const transcriptionHeaderHeight = transcriptionMessageId
      ? getTranscriptionHeaderElement(transcriptionId)?.offsetHeight ?? 0
      : 0

    const overTop = messageOffsetTop + message.offsetHeight - transcriptionHeaderHeight < parent.scrollTop
    const overBottom = messageOffsetTop > parent.scrollTop + parent.clientHeight

    return overTop || overBottom
  }

  function scrollToSelectedMessage({ firstLoad }: { firstLoad: boolean } = { firstLoad: false }) {
    scrollInteractionIntoView(
      {
        messageId: selectedMessageId.value,
        transcriptionId: selectedTranscriptionId.value,
        transcriptionMessageId: selectedTranscriptionMessageId.value,
      },
      { firstLoad },
    )
  }

  const scrollToTopOfTranscription = (transcriptionId: string) => {
    const ticketResponsesElement = getTicketResponsesElement()
    const transcriptionHeaderElement = getTranscriptionHeaderElement(transcriptionId)
    const top =
      transcriptionHeaderElement.nextElementSibling.getBoundingClientRect().top -
      transcriptionHeaderElement.offsetHeight -
      ticketResponsesElement?.getBoundingClientRect().top +
      ticketResponsesElement?.scrollTop

    ticketResponsesElement?.scrollTo({ top, behavior: 'smooth' })
  }

  const handleHighlight = (isHighlighted: boolean, messageId: string | null = null) => {
    if (!state.ticket) return
    if (!state.ticket.highlighted && !messageId) state.animateHighlightState = true

    toggleHighlight({
      conversationExternalId: state.ticket.externalId,
      paymentTokenId: state.ticket.paymentTokenId,
      messageExternalId: messageId,
    })

    if (!messageId) state.ticket.highlighted = isHighlighted
    else {
      const idx = state.ticket.externalComments.findIndex((comm) => comm.externalId === messageId)
      state.ticket.externalComments[idx].highlighted = isHighlighted
    }

    sidebarState.ticket = state.ticket
    const listState = state.ticket.highlighted || state.ticket.externalComments.some((c) => c.highlighted)
    bus.$emit('highlight-message', { ticketId: state.ticket.externalId, toHighlight: listState })
  }

  const getTicketData = async (conversationId: string, connectionId: number) => {
    let data: any = {}
    try {
      sidebarState.ticketContentNotFound = false
      const res = await getTicketSpanner(conversationId, connectionId)
      data = res.data
    } catch (err: any) {
      if (err instanceof DOMException && err.name == 'AbortError') return

      // TODO: Catch 401 errors and display "Not allowed" image for agents
      if (err.response.status === 404) {
        sidebarState.ticketContentNotFound = true
      } else {
        const error = await err.response.text()
        bus.$emit('universal-error', { error })
      }
    }

    if (data.bodyFromIntegration) {
      return populateTicketMessages(data)
    } else if (data.staleContent) {
      populateTicketMessages(data)
    }

    state.ticket = data || {}
    sidebarState.ticket = state.ticket
  }

  const populateTicketMessages = async (passedTicket: TicketData) => {
    if (!passedTicket.externalId) return

    const { data } = (await getTicketBody(passedTicket.externalId, passedTicket.paymentTokenId)) as TicketMessages

    state.ticket = {
      ...passedTicket,
      subject: data.subject || passedTicket.subject,
      bodyFromIntegration: false,
      externalComments: passedTicket.externalComments.map((comm) => Object.assign({}, comm, data.bodyMap[comm.id])),
    }

    sidebarState.ticket = state.ticket
  }

  return {
    state,
    ticketExternalId: computed(() => state.ticket.externalId),
    readOnly,
    ticketSource,
    ticketURL,
    participatingAgentIds,
    insights,
    resetTicket,
    focusOnMessage,
    scrollToSelectedMessage,
    scrollToTopOfTranscription,
    handleHighlight,
    messageIsOffScreen,
    getTicketData,
  }
}

export const useTicketContent = createSharedComposable(_useTicketContent)
export type { State }
