const innerFn = (node, data) => {
  if (!data[node.category]) data[node.category] = {}

  data[node.category][node.name] = node.value

  node.children.forEach((child) => innerFn(child, data))
}

export default (rootNode) => {
  const data = {}

  innerFn(rootNode, data)

  const dataMod = {}

  Object.entries(data).forEach(([category, values]) => {
    dataMod[category] = Object.entries(values as Record<string, unknown>).map(([name, value]) => ({
      category,
      label: name,
      value,
      checked: true,
    }))
  })

  return dataMod
}
