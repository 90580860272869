export const downloadUrl = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop() || 'export.csv'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadFile = (data: BlobPart, fileName: string) => {
  const blob = new Blob([data], { type: 'text/plain' })

  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.href = window.URL.createObjectURL(blob)
  a.setAttribute('download', fileName)
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}
