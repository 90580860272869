type RawScores = (number | string)[]

export const GOOD_CSAT_RAW_SCORES = ['good', 'rated_good', 'reward']
export const BAD_CSAT_RAW_SCORES = ['bad', 'rated_bad', 'rebuke']

const getCsatColor = (rawCsatScore: number | string) => {
  if (GOOD_CSAT_RAW_SCORES.includes(rawCsatScore as string)) return '--green-60'
  if (BAD_CSAT_RAW_SCORES.includes(rawCsatScore as string)) return '--red-60'

  const score = Number(rawCsatScore)
  if (score === 5) return '--green-60'
  if (score === 4) return '--green-40'
  if (score === 3) return '--yellow-40'
  if (score === 2) return '--orange-40'
  if (score === 1) return '--red-60'
  return '--gray-30'
}

export default getCsatColor

export const isDisabledScore = (label: string, rawScores: RawScores = []) =>
  !!rawScores.length && !rawScores.map(String).includes(label)

export const colorGetter = (d: any, rawScores?: RawScores) => {
  if (isDisabledScore(d.label, rawScores)) return 'var(--gray-30)'
  else return `var(${getCsatColor(d.label)})`
}

export const getCsatChangeColor = (value: number, disabled = false) => {
  if (disabled) return 'var(--gray-50)'
  else if (value > 0) return 'var(--green-50)'
  else if (value < 0) return 'var(--red-50)'
  else return 'var(--gray-50)'
}
